<template>
    <div class="sa-tab-scroll">
        <b-card class="sa-card" header="Dati Medico" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Codice Fiscale:</label>
                    <b-form-input v-model="jsonData.codiceFiscale"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Titolo:</label>
                    <b-form-input v-model="jsonData.titolo"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Nome:</label>
                    <b-form-input v-model="jsonData.nome"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Cognome:</label>
                    <b-form-input v-model="jsonData.cognome"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Specializzazione:</label>
                    <b-form-input v-model="jsonData.specializzazione"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Matricola:</label>
                    <b-form-input v-model="jsonData.matricola"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Numero Iscrizione Albo:</label>
                    <b-form-input v-model="jsonData.numeroIscrizioneAlbo"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Codice Regionale:</label>
                    <b-form-select v-model="jsonData.codiceRegionale" :options="regioniOptions" :value="''" value-field="codiceIstat" text-field="regione" @change="onChangeRegione"></b-form-select>
                    <!-- <b-form-input v-model="jsonData.codiceRegionale"></b-form-input> -->
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Regione:</label>
                    <b-form-input v-model="jsonData.regione" disabled></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">A.S.L.:</label>
                    <b-form-select v-model="jsonData.asl" :options="aslOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                    <!-- <b-form-input v-model="jsonData.asl"></b-form-input> -->
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Sesso:</label>
                    <b-form-select v-model="jsonData.sesso" :options="sessoOptions" :value="''" value-field="codice" text-field="descrizione"></b-form-select>
                    <!-- <b-form-input v-model="jsonData.sesso"></b-form-input> -->
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Telefono:</label>
                    <b-form-input v-model="jsonData.telefono"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">E-Mail:</label>
                    <b-form-input v-model="jsonData.email"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Username:</label>
                    <b-form-input v-model="jsonData.username"></b-form-input>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
// import moment from "moment";
// import DatePicker from "vue2-datepicker";
import UtilityMixin from "../../../utility/UtilityMixin";
export default {
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    // components: { DatePicker },
    mixins: [UtilityMixin],
    data() {
        return {
            showModalLoading: false,
            linkback: "/medici",
            currentPage: 1,
            perPage: 100,
            id: "-1",
            descrizioneValutazione: "",
            pathResource: "/medici",
            pathResourceSesso: "/anagraficasesso",
            pathResourceRegioni: "/regioni",
            pathResourceAsl: "/asl",
            sessoOptions: [],
            regioniOptions: [],
            aslOptions: [{ value: "", text: "-Selezione Asl-" }],
            jsonData: {
                id: "-1",
                codiceFiscale: "",
                matricola: "",
                specializzazione: "",
                titolo: "",
                cognome: "",
                nome: "",
                telefono: "",
                email: "",
                numeroIscrizioneAlbo: "",
                regione: "",
                asl: "",
                codiceRegionale: "",
                sesso: "",
                username: "",
            },
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        // disabledSanitario
        me.impostaPermessi();
        me.loadDefaultData();
    },
    methods: {
        loadDefaultData() {
            let me = this;
            me.loadSesso();
            me.loadRegioni();
            me.loadData();
        },
        impostaPermessi() {
            let me = this;
            me.disabledSanitario = false;
            me.disabledSanitario = !UtilityMixin.methods.verificaPermessi(Vue.prototype.user, "utenti", 2);
        },
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
                // me.jsonData.operatoreNome = Vue.prototype.user.firstname;
                // me.jsonData.operatoreCognome = Vue.prototype.user.lastname;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                me.showModalLoading = true;
                axios
                    .get(link + me.id)
                    .then((response) => {
                        me.jsonData = response.data.data;
                        me.loadAsl(me.jsonData.codiceRegionale);
                        me.showModalLoading = false;
                    })
                    .catch(() => {
                        me.showModalLoading = false;
                    });
            }
        },
        loadSesso() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceSesso;
            axios.get(link).then((response) => {
                me.sessoOptions = [];
                me.sessoOptions = response.data.data;
                me.sessoOptions.unshift({ codice: "", descrizione: "-Seleziona Sesso-" });
            });
        },
        loadRegioni() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceRegioni;
            axios.get(link).then((response) => {
                me.regioniOptions = [];
                me.regioniOptions = response.data.data;
                me.regioniOptions.unshift({ codiceIstat: "", regione: "-Seleziona Regione-" });
            });
        },
        onChangeRegione() {
            let me = this;
            me.regioniOptions.forEach((element) => {
                if (element.codiceIstat === me.jsonData.codiceRegionale) {
                    me.jsonData.regione = element.regione;
                    me.loadAsl(element.codiceIstat);
                }
            });
        },
        loadAsl(codiceIstat) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceAsl + "/regione?codiceRegione=" + codiceIstat + "0";
            axios.get(link).then((response) => {
                me.aslOptions = [];
                response.data.data.forEach((element) => {
                    me.aslOptions.push({ text: element.denominazione, value: element.denominazione });
                });
                me.aslOptions.unshift({ value: "", text: "-Seleziona Asl-" });
            });
        },
    },
};
</script>

<style></style>
