<template>
    <div class="sa-tab-scroll">
        <b-card class="sa-card" header="Punto Incontro" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Descrizione:</label>
                    <span class="sa-label-data">{{ jsonData.descrizione }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
                    <label class="sa-label-data">Stato:</label>
                    <span class="sa-label-data">{{ jsonData.statoAttuale }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
                    <label class="sa-label-data">Provincia:</label>
                    <span class="sa-label-data">{{ jsonData.provinciaSigla }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
                    <label class="sa-label-data">Comune:</label>
                    <span class="sa-label-data">{{ jsonData.descrizioneComune }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="8" lg="9" xl="9">
                    <label class="sa-label-data">Indirizzo:</label>
                    <span class="sa-label-data">{{ jsonData.indirizzo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="3" xl="3">
                    <label class="sa-label-data">Num. Civ.:</label>
                    <span class="sa-label-data">{{ jsonData.numeroCivico }}</span>
                </b-col>
            </b-row>
        </b-card>
        <svei-configurazione-punti-incontro-distretti-component></svei-configurazione-punti-incontro-distretti-component>
    </div>
</template>

<script>
import axios from "axios";
import UtilityMixin from "../../utility/UtilityMixin";
import SveiConfigurazionePuntiIncontroDistrettiComponent from "./SveiConfigurazionePuntiIncontroDistrettiComponent.vue";
export default {
    components: { SveiConfigurazionePuntiIncontroDistrettiComponent },
    mixins: [UtilityMixin],
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    data() {
        return {
            showModalLoading: false,
            id: "-1",
            pathResource: "/sveipuntiincontro",
            linkProvince: "/province",
            linkComune: "/comuni/provincia",
            jsonData: {
                id: "-1",
                descrizione: "",
                provinciaSigla: null,
                comuneCodiceIstat: null,
                statoAttuale: "ATTIVO",
            },
            provinceOptions: [{ sigla: null, provincia: "-Seleziona Provincia-" }],
            comuniOptions: [{ codiceIstat: "", comune: "-Seleziona Comune-" }],
            statoOptions: [
                { value: "ATTIVO", stato: "ATTIVO" },
                { value: "SOSPESO", stato: "SOSPESO" },
                { value: "DISMESSO", stato: "DISMESSO" },
            ],
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadDefaultData();
    },
    methods: {
        loadDefaultData() {
            let me = this;
            me.loadProvince();
            me.loadData();
        },
        loadData() {
            let me = this;
            me.showModalLoading = true;
            //   console.log(me.id);
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                me.showModalLoading = true;
                axios
                    .get(link + me.id)
                    .then((response) => {
                        me.jsonData = response.data.data;
                        me.loadComuni(me.jsonData.provinciaSigla);
                        me.showModalLoading = false;
                    })
                    .catch(() => {
                        me.showModalLoading = false;
                    });
            }
        },
        loadProvince() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkProvince;
            axios.get(link).then((response) => {
                me.provinceOptions = response.data.data;
                me.provinceOptions.unshift({ sigla: null, provincia: "- Seleziona Provincia-" });
            });
        },
        loadProvinceChange(value) {
            let me = this;
            me.loadComuni(value);
            // me.provinceNascitaOptions.forEach((provincia) => {
            //     if (provincia.sigla === value) {
            //         me.setDatiIndirizzoNascita(provincia.sigla);
            //     }
            // });
        },
        // setDatiIndirizzoNascita(siglaProvincia, codiceIstatComune = null) {
        //     let me = this;
        //     me.jsonData.assistitoComuneNascita = codiceIstatComune;
        //     me.provinceNascitaOptions.forEach((provincia) => {
        //         if (provincia.sigla === siglaProvincia) {
        //             me.jsonData.assistitoProvinciaNascitaDescrizione = provincia.sigla;
        //             me.loadComuniNascita(provincia.sigla);
        //         }
        //     });
        // },
        loadComuni(sigla) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkComune + "/" + sigla;
            axios.get(link).then((response) => {
                me.comuniOptions = [];
                me.comuniOptions = response.data.data;
                me.comuniOptions.unshift({ codiceIstat: null, comune: "-Seleziona Comune-" });
            });
        },
    },
};
</script>

<style></style>
