<template>
	<div class="sa-list-component">
		<div class="sa-list-component-header">
			<b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
				<b-col lg="3">
					<p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
				</b-col>
				<b-col lg="9">
					<b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
				</b-col>
			</b-row>
		</div>
		<div class="sa-list-component-body">
			<div class="b-table-sticky-header">
				<b-table sticky-header ref="table" stacked="lg" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
					<template v-slot:cell(title)="{ item }">
						<router-link class="sa-edit-link" :to="'/dataanalysisquery/edit/' + item.id">{{ item.title }}</router-link>
					</template>
					<template v-slot:cell(urlAfterSigned)="{ item }">
						{{ item.urlAfterSigned }}
						<span class="sa-label-data-secondary">{{ item.methodAfterSigned }}</span>
					</template>
					<template v-slot:cell(urlAfterSignedError)="{ item }">
						{{ item.urlAfterSignedError }}
						<span class="sa-label-data-secondary">{{ item.methodAfterSignedError }}</span>
					</template>
					<template v-slot:cell(actions)="row">
						<b-container class="bv-example-row">
							<b-button v-b-tooltip.hover size="sm" variant="outline-primary no-text" title="Modifica Dashboard" @click="onClickEdit(row)" class="mr-1 no-text">
								<b-icon icon="pencil"></b-icon>
							</b-button>
						</b-container>
					</template>
				</b-table>
			</div>
		</div>
		<div class="sa-list-component-footer">
			<b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
				<b-col lg="3">
					<p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rows }}</p>
				</b-col>
				<b-col lg="9">
					<b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
				</b-col>
			</b-row>
		</div>
	</div>
</template>

<script>
import axios from "axios";
export default {
	data() {
		return {
			rows: 0,
			perPage: 50,
			currentPage: 1,
			pathResource: "/dataanalysisquery",
			filtro: {},
			items: [],
			listKey: 0,
			fields: [
				{
					label: "Titolo",
					key: "title",
					sortable: true,
				},
				{
					label: "Descrizione",
					key: "descrizione",
					sortable: true,
				},
				{ class: "sa-table-column-action-double", key: "actions", label: "" },
			],
		};
	},
	mounted() {
		let me = this;
		let sessionStorage = window.sessionStorage;
		if (sessionStorage["filtro"]) {
			me.filtro = JSON.parse(sessionStorage["filtro"]);
		}
		// me.loadData();
	},
	methods: {
		loadData() {
			let me = this;
			let link = process.env.VUE_APP_PATH_API + me.pathResource;
			me.filtro.page = me.currentPage;
			me.filtro.forPage = me.perPage;
			axios.get(link, { params: me.filtro })
				.then((response) => {
					me.items = [];
					me.items = response.data.data.list;
					me.rows = response.data.data.recordsNumber;
					me.listKey++;
					this.$emit("afterLoadData");
				})
				.catch(() => {
					this.$emit("afterLoadData");
				});
		},
		onClickEdit(row) {
			let me = this;
			me.$router.push("/dataanalysisquery/edit/" + row.item.id);
		},
	},
};
</script>

<style></style>
