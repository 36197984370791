<template>
    <sa-page-layout-vue :btnConfigurazioniVisible="btnConfigurazioniVisible" :btnRefreshVisible="true" @refresh="onRefresh" :showModalLoading="showModalLoading">
        <template slot="toolbar">
            <b-button class="float-sm-right" variant="success btn-toolbar sa-margin-right" size="sm" @click="onClickNuovaAssociazione">
                <b-icon icon="plus"></b-icon>
                Nuovo
            </b-button>
        </template>

        <template slot="table-body">
            <rsa-configurazione-impegnativa-gruppo-prestazione-list-component ref="cmpImpegnativaGruppoPrestazioneList" @afterLoadData="onAfterLoadData" />
        </template>
    </sa-page-layout-vue>
</template>

<script>
import RsaConfigurazioneImpegnativaGruppoPrestazioneListComponent from "../components/RsaConfigurazioneImpegnativaGruppoPrestazioneListComponent.vue";
import SaPageLayoutVue from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
    components: { SaPageLayoutVue, RsaConfigurazioneImpegnativaGruppoPrestazioneListComponent },
    mixins: [],
    data() {
        return {
            pathResource: "",
            showModalLoading: false,
            btnConfigurazioniVisible: true,
        };
    },
    mounted() {
        let me = this;
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            me.$refs.cmpImpegnativaGruppoPrestazioneList.loadData();
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        onAfterLoadData() {
            let me = this;
            me.showModalLoading = false;
        },
        onClickNuovaAssociazione() {
            let me = this;
            me.$refs.cmpImpegnativaGruppoPrestazioneList.onClickNuovaAssociazione();
        },
    },
};
</script>
