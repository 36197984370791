<template>
  <div class="sa-sidebar-brand-img sa-sidebar-logo">
    <router-link :to="to" class="sa-sidebar-brand-link">
      <span class="sa-sidebar-logo-lg">
        <img :src="logo" :srcset="`${logo}`" :class="['sa-sidebar-brand-image', '']" :alt="header" />
      </span>
      <span class="sa-sidebar-logo-sm">
        <img :src="logosmall" :srcset="`${logosmall}`" :class="['sa-sidebar-brand-image', 'img-circle']" :alt="header" />
      </span>
    </router-link>
  </div>
</template>

<script>
import elevation from "../../mixins/elevation";

export default {
  mixins: [elevation()],
  props: {
    logo: {
      type: String,
      required: true,
    },
    logosmall: {
      type: String,
      required: true,
    },
    logosmall2x: {
      type: String,
      required: false,
    },
    logo2x: {
      type: String,
      default: "",
    },
    to: {
      type: String,
      default: "/",
    },
    header: {
      type: String,
      default: "",
    },
  },
};
</script>
