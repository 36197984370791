<template>
    <div class="sa-tab-scroll sa-form-container">
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card" header="Dettaglio Numeratore" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Codice</label>
                            <span>{{ jsonData.codice }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Descrizione</label>
                            <span>{{ jsonData.descrizione }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Valore</label>
                            <span>{{ jsonData.valore }}</span>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Anno</label>
                            <span>{{ jsonData.anno }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Codice Struttura</label>
                            <span>{{ jsonData.codiceStruttura }}</span>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Note</label>
                            <span>{{ jsonData.note }}</span>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import axios from "axios";
export default {
    components: {},
    data() {
        return {
            pathResource: "/numeratori",
            linkedit: "",
            id: "",
            showModalLoading: false,
            jsonData: {},
            fieldset: {
                codice: "",
                descrizione: "",
                anno: "",
                valore: "",
                codiceStruttura: "",
                note: "",
            },
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.linkedit = "/numeratori/edit/" + me.id;
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            me.showModalLoading = true;
            axios
                .get(link + me.id)
                .then((response) => {
                    me.jsonData = response.data.data;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
    },
};
</script>

<style scoped>
.sa-form-container {
    padding: 1rem;
    background-color: #f8f9fa;
    min-height: 100%;
}
</style>
