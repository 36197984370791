<template>
	<div class="sa-page-scroller">
		<div class="scroller scroller-left" @click="onTabClickLeft"><i class="bi bi-chevron-left"></i></div>
		<div class="scroller scroller-right" @click="onTabClickRight"><i class="bi bi-chevron-right"></i></div>
	</div>
</template>

<script lang="js">
export default {
    props:{
        idTab: {
            type: String,
            required: true
        },
        classNavItem: {
            type: String,
            required: true
        },
    },
    mounted(){
let me = this;
        setTimeout(function() {
           me.scrollTabs(0);
}, 300);
    },
    methods: {
        onTabClickLeft() {
            let me = this;
            me.scrollTabs(+1);
        },
        onTabClickRight() {
            let me = this;
            me.scrollTabs(-1);
        },
        scrollTabs(direction) {
            //
            let leftButtonScrolling = document.querySelector(".scroller-left");
            let rightButtonScrolling = document.querySelector(".scroller-right");
            let tabElement = document.querySelector(".sa-tab-items");
            let navTabs = document.querySelector("#" + this.idTab);
            let navItem = document.querySelector("." + this.classNavItem);
            let velocitaSpostamento = navItem.clientWidth + Math.floor(navItem.clientWidth / 100);
            let lunghezzaContenitore = navTabs.getBoundingClientRect().width;
            let lunghezzaTabElement = tabElement.getBoundingClientRect().width;
            let leftPosition = (direction === 0 ) ? 0 : tabElement.offsetLeft + direction * velocitaSpostamento;

// console.log("leftPosition: " + leftPosition);
// console.log("lunghezzaTabElement: " + lunghezzaTabElement);
// console.log("lunghezzaContenitore: " + lunghezzaContenitore);

// console.log("lunghezzaContenitore - lunghezzaTabElement: " + (lunghezzaContenitore - lunghezzaTabElement));

            if (leftPosition + lunghezzaTabElement < lunghezzaContenitore && direction > 0) {
                leftPosition = lunghezzaContenitore - lunghezzaTabElement;
            } else if (leftPosition > 0) {
                leftPosition = 0;
            }

            if (leftPosition === lunghezzaContenitore - lunghezzaTabElement) {
                rightButtonScrolling.style.visibility = "hidden";
            }
            else if(lunghezzaTabElement < lunghezzaContenitore){
                rightButtonScrolling.style.visibility = "hidden";
            }
            else if((lunghezzaTabElement + leftPosition) < lunghezzaContenitore){
                rightButtonScrolling.style.visibility = "hidden";
            }
            else {
                rightButtonScrolling.style.visibility = "visible";
            }

            if (leftPosition === 0) {
                leftButtonScrolling.style.visibility = "hidden";
            } else {
                leftButtonScrolling.style.visibility = "visible";
            }
            tabElement.style.left = leftPosition + "px";
        },
    },
};
</script>

<style>
/* .sa-page-dashboard {
    background-color: #f90b0b;
}
.scroller-right i {
    left: 10px;
}

.scroller {
    height: 30px;
    width: 15px;
    text-align: center;
    cursor: pointer;
    padding-top: 3px;
    background-color: #fff;
    font-size: 16px;
    font-weight: bold;
    color: var(--bg-main-nav-bar);
}
.scroller-left,
.scroller-right {
    position: absolute;
    z-index: 10000000;
}

.scroller-left:hover,
.scroller-right:hover {
    background: var(--bg-sidebar-item) !important;
    color: var(--menu-item-text-color);
}

.scroller-left {
    float: left;
    visibility: hidden;
    border-right: 1px solid var(--bg-main-nav-bar);
}

.scroller-right {
    float: right;
    right: 10px;
    border-left: 1px solid var(--bg-main-nav-bar);
}
.sa-tab-items {
    position: relative;
    left: 0;
    width: fit-content;
    -webkit-transition: all 0.5s ease-out;
} */
</style>
