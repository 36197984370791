<template>
    <div>
        <b-card class="sa-card" header="Informazioni Cliniche" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Insorgenza Primi Sintomi</label>
                    <date-picker v-model="jsonData.dataInsorgenzaPrimiSintomi" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Associato a Gravidanza</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.insorgenzaPrimiSintomiAssociatoGravidanza" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Patologie Neonato</label>
                    <b-form-input v-model="jsonData.patologieNeonato" :disabled="jsonData.insorgenzaPrimiSintomiAssociatoGravidanza !== 'SI'"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Quale dei Seguenti Sintomi ha Manifestato</label>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-table sticky-header stacked="xl" striped hover itemscope :items="jsonData.listaSintomi" :fields="fieldsSintomi" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="row">
                            {{ row.index + 1 }}
                        </template>
                        <template #cell(risposta)="item">
                            <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="item.item.risposta" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                        </template>
                        <template #cell(dataOra)="item">
                            <date-picker v-model="item.item.dataOra" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
                        </template>
                    </b-table>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Ricovero Ospedaliero</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.ricoveroOspedaliero" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Ricovero</label>
                    <date-picker v-model="jsonData.ricoveroOspedalieroData" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="jsonData.ricoveroOspedaliero !== 'SI'"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Dimissioni</label>
                    <date-picker v-model="jsonData.ricoveroOspedalieroDataDimissioni" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="jsonData.ricoveroOspedaliero !== 'SI'"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Diagnosi</label>
                    <b-form-input v-model="jsonData.ricoveroOspedalieroDiagnosi" :disabled="jsonData.ricoveroOspedaliero !== 'SI'"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Ospedale Ricovero</label>
                    <b-form-input v-model="jsonData.ricoveroOspedalieroNomeOspedale" :disabled="jsonData.ricoveroOspedaliero !== 'SI'"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Reparto Ricovero</label>
                    <b-form-input v-model="jsonData.ricoveroOspedalieroNomeReparto" :disabled="jsonData.ricoveroOspedaliero !== 'SI'"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Esami di Laboratorio Effettuati</label>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-table sticky-header stacked="xl" striped hover itemscope :items="jsonData.listaEsamiLaboratorio" :fields="fieldsEsamiLaboratorio" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="row">
                            {{ row.index + 1 }}
                        </template>
                        <template #cell(descrizioneEsame)="item">
                            <b-form-input v-model="item.item.descrizioneEsame"></b-form-input>
                        </template>
                        <template #cell(risultato)="item">
                            <b-form-input v-model="item.item.risultato"></b-form-input>
                        </template>
                        <template #cell(actions)="row">
                            <b-button v-if="row.index == jsonData.listaEsamiLaboratorio.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddEsamiLaboratorio(jsonData.listaEsamiLaboratorio, row.item)">
                                <b-icon icon="plus"></b-icon>
                            </b-button>
                            <b-button v-if="row.index !== jsonData.listaEsamiLaboratorio.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDeleteEsamiLaboratorio(jsonData.listaEsamiLaboratorio, row)">
                                <b-icon icon="trash"></b-icon>
                            </b-button>
                        </template>
                    </b-table>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Patologie Pregresse</label>
                    <b-form-input v-model="jsonData.patologiePregresse"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Farmaci Assunti</label>
                    <b-form-input v-model="jsonData.farmaciAssunti"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Prodotti Omeopatici, Integratori, Prodotti Erboristici</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.prodottiOmeopaticiIntegratoriErboristici" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Quali</label>
                    <b-form-input v-model="jsonData.prodottiOmeopaticiIntegratoriErboristiciQuali" :disabled="jsonData.prodottiOmeopaticiIntegratoriErboristici !== 'SI'"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Mese Precedente Ricoverato/Day Hospital</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.mesePrecedenteRicoveratoDayHospital" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Dove</label>
                    <b-form-input v-model="jsonData.mesePrecedenteRicoveratoDayHospitalDove" :disabled="jsonData.mesePrecedenteRicoveratoDayHospital !== 'SI'"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Dal</label>
                    <date-picker v-model="jsonData.mesePrecedenteRicoveratoDayHospitalDal" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="jsonData.mesePrecedenteRicoveratoDayHospital !== 'SI'"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Al</label>
                    <date-picker v-model="jsonData.mesePrecedenteRicoveratoDayHospitalAl" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="jsonData.mesePrecedenteRicoveratoDayHospital !== 'SI'"></date-picker>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Contatti</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.contattoCondivisoPastiStessiSintomi" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-table sticky-header ref="tblViaggi" stacked="xl" striped hover itemscope :items="jsonData.listaContatti" :fields="fieldsContatti" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="jsonData">
                            {{ jsonData.index + 1 }}
                        </template>
                        <template #cell(cognome)="item">
                            <b-form-input v-model="item.item.cognome"></b-form-input>
                        </template>
                        <template #cell(nome)="item">
                            <b-form-input v-model="item.item.nome"></b-form-input>
                        </template>
                        <template #cell(comune)="item">
                            <!-- <b-form-select v-model="item.item.professione" :options="professioniOptions" :value="''" value-field="value" text-field="text"></b-form-select> -->
                            <b-form-input v-model="item.item.comune"></b-form-input>
                        </template>
                        <template #cell(indirizzo)="item">
                            <b-form-input v-model="item.item.indirizzo"></b-form-input>
                        </template>
                        <template #cell(telefono)="item">
                            <b-form-input v-model="item.item.telefono"></b-form-input>
                        </template>
                        <template #cell(actions)="row">
                            <b-button v-if="row.index == jsonData.listaContatti.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddContatto(jsonData.listaContatti, row.item)">
                                <b-icon icon="plus"></b-icon>
                            </b-button>
                            <b-button v-if="row.index !== jsonData.listaContatti.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDeleteContatto(jsonData.listaContatti, row)">
                                <b-icon icon="trash"></b-icon>
                            </b-button>
                        </template>
                    </b-table>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Analisi Comportamenti e Abitudini" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Nei 12-70 Giorni Precedenti ha Fatto Qualche Viaggio</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.fattoViaggi" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Dove</label>
                    <b-form-input v-model="jsonData.fattoViaggiDove" :disabled="jsonData.fattoViaggi !== 'SI'"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Dal</label>
                    <date-picker v-model="jsonData.fattoViaggiDal" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="jsonData.fattoViaggi !== 'SI'"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Al</label>
                    <date-picker v-model="jsonData.fattoViaggiAl" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="jsonData.fattoViaggi !== 'SI'"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Struttura Soggiorno</label>
                    <b-form-input v-model="jsonData.fattoViaggiStruttura" :disabled="jsonData.fattoViaggi !== 'SI'"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Consumato Pasti o Consumato Alimenti</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.fattoViaggiConsumatoAcquistatoAlimenti" :options="siNoOptions" value="" value-field="value" text-field="text" :disabled="jsonData.fattoViaggi !== 'SI'"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Quali</label>
                    <b-form-input v-model="jsonData.fattoViaggiConsumatoAcquistatoAlimentiQuali" :disabled="jsonData.fattoViaggi !== 'SI' || jsonData.fattoViaggiConsumatoAcquistatoAlimenti !== 'SI'"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Luogo Acquisto</label>
                    <b-form-input v-model="jsonData.fattoViaggiConsumatoAcquistatoAlimentiLuogo" :disabled="jsonData.fattoViaggi !== 'SI' || jsonData.fattoViaggiConsumatoAcquistatoAlimenti !== 'SI'"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Acquisto</label>
                    <date-picker v-model="jsonData.fattoViaggiConsumatoAcquistatoAlimentiData" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="jsonData.fattoViaggi !== 'SI' || jsonData.fattoViaggiConsumatoAcquistatoAlimenti !== 'SI'"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Luogo Consumo</label>
                    <b-form-input v-model="jsonData.fattoViaggiConsumatoConsumoAlimentiLuogo" :disabled="jsonData.fattoViaggi !== 'SI' || jsonData.fattoViaggiConsumatoAcquistatoAlimenti !== 'SI'"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Data Consumo</label>
                    <date-picker v-model="jsonData.fattoViaggiConsumatoConsumoAlimentiData" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="jsonData.fattoViaggi !== 'SI' || jsonData.fattoViaggiConsumatoAcquistatoAlimenti !== 'SI'"></date-picker>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Nei 12 - 70 giorni precedenti dove ha acquistato gli alimenti" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-table sticky-header stacked="xl" striped hover itemscope :items="jsonData.listaNegozi" :fields="fieldsNegozi" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="row">
                            {{ row.index + 1 }}
                        </template>
                        <template #cell(risposta)="item">
                            <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="item.item.risposta" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                        </template>
                        <template #cell(comune)="item">
                            <b-form-input v-model="item.item.comune" :disabled="item.item.risposta !== 'SI'"></b-form-input>
                        </template>
                        <template #cell(indirizzo)="item">
                            <b-form-input v-model="item.item.indirizzo" :disabled="item.item.risposta !== 'SI'"></b-form-input>
                        </template>
                    </b-table>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">E' in possesso della tessera fedelta</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.possessoTesseraFedelta" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Quale/i</label>
                    <b-form-input v-model="jsonData.possessoTesseraFedeltaQuali" :disabled="jsonData.possessoTesseraFedelta !== 'SI'"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Utilizzo abituale</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.possessoTesseraFedeltaUtilizzoFrequente" :options="siNoOptions" value="" value-field="value" text-field="text" :disabled="jsonData.possessoTesseraFedelta !== 'SI'"></b-form-select>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">12 - 70 Giorni Prima Consumato Pasti Fuori Casa</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.consumatoPastiFuoriCasa" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-table sticky-header ref="tblViaggi" stacked="xl" striped hover itemscope :items="jsonData.listaPastiFuoriCasa" :fields="fieldsPastiFuoriCasa" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="jsonData">
                            {{ jsonData.index + 1 }}
                        </template>
                        <template #cell(struttura)="item">
                            <b-form-input v-model="item.item.nomeStruttura"></b-form-input>
                        </template>
                        <template #cell(comune)="item">
                            <!-- <b-form-select v-model="item.item.professione" :options="professioniOptions" :value="''" value-field="value" text-field="text"></b-form-select> -->
                            <b-form-input v-model="item.item.comune"></b-form-input>
                        </template>
                        <template #cell(indirizzo)="item">
                            <b-form-input v-model="item.item.indirizzo"></b-form-input>
                        </template>
                        <template #cell(dataConsumo)="item">
                            <date-picker v-model="item.item.dataConsumo" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
                        </template>
                        <template #cell(actions)="row">
                            <b-button v-if="row.index == jsonData.listaPastiFuoriCasa.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddPasto(jsonData.listaPastiFuoriCasa, row.item)">
                                <b-icon icon="plus"></b-icon>
                            </b-button>
                            <b-button v-if="row.index !== jsonData.listaPastiFuoriCasa.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDeletePasto(jsonData.listaPastiFuoriCasa, row)">
                                <b-icon icon="trash"></b-icon>
                            </b-button>
                        </template>
                    </b-table>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Indagine sul consumo di alimenti" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Carne e Prodotti Derivati</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.consumatoCarneProdottiDerivati" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-table v-if="jsonData.consumatoCarneProdottiDerivati === 'SI'" sticky-header ref="tblViaggi" stacked="xl" striped hover itemscope :items="jsonData.listaAlimentiCarne" :fields="fieldsAlimentiCarne" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="jsonData">
                            {{ jsonData.index + 1 }}
                        </template>
                        <template #cell(consumato)="item">
                            <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="item.item.consumato" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                        </template>
                        <template #cell(specificareConfezionato)="item">
                            <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="item.item.specificareConfezionato" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                        </template>
                        <template #cell(nomeLuogoAcquisto)="item">
                            <b-form-input v-model="item.item.nomeLuogoAcquisto"></b-form-input>
                        </template>
                        <template #cell(marca)="item">
                            <b-form-input v-model="item.item.marca"></b-form-input>
                        </template>
                    </b-table>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Salumi</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.consumatoSalumi" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-table v-if="jsonData.consumatoSalumi === 'SI'" sticky-header ref="tblViaggi" stacked="xl" striped hover itemscope :items="jsonData.listaAlimentiSalumi" :fields="fieldsAlimentiCarne" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="jsonData">
                            {{ jsonData.index + 1 }}
                        </template>
                        <template #cell(consumato)="item">
                            <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="item.item.consumato" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                        </template>
                        <template #cell(specificareConfezionato)="item">
                            <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="item.item.specificareConfezionato" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                        </template>
                        <template #cell(nomeLuogoAcquisto)="item">
                            <b-form-input v-model="item.item.nomeLuogoAcquisto"></b-form-input>
                        </template>
                        <template #cell(marca)="item">
                            <b-form-input v-model="item.item.marca"></b-form-input>
                        </template>
                    </b-table>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Verdura e Frutta</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.consumatoVerduraFrutta" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-table v-if="jsonData.consumatoVerduraFrutta === 'SI'" sticky-header ref="tblViaggi" stacked="xl" striped hover itemscope :items="jsonData.listaAlimentiVerduraFrutta" :fields="fieldsAlimentiCarne" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="jsonData">
                            {{ jsonData.index + 1 }}
                        </template>
                        <template #cell(consumato)="item">
                            <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="item.item.consumato" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                        </template>
                        <template #cell(specificareConfezionato)="item">
                            <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="item.item.specificareConfezionato" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                        </template>
                        <template #cell(nomeLuogoAcquisto)="item">
                            <b-form-input v-model="item.item.nomeLuogoAcquisto"></b-form-input>
                        </template>
                        <template #cell(marca)="item">
                            <b-form-input v-model="item.item.marca"></b-form-input>
                        </template>
                    </b-table>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Pesce, Molluschi e Crostacei</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.consumatoPesce" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-table v-if="jsonData.consumatoPesce === 'SI'" sticky-header ref="tblViaggi" stacked="xl" striped hover itemscope :items="jsonData.listaAlimentiPesce" :fields="fieldsAlimentiCarne" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="jsonData">
                            {{ jsonData.index + 1 }}
                        </template>
                        <template #cell(consumato)="item">
                            <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="item.item.consumato" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                        </template>
                        <template #cell(specificareConfezionato)="item">
                            <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="item.item.specificareConfezionato" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                        </template>
                        <template #cell(nomeLuogoAcquisto)="item">
                            <b-form-input v-model="item.item.nomeLuogoAcquisto"></b-form-input>
                        </template>
                        <template #cell(marca)="item">
                            <b-form-input v-model="item.item.marca"></b-form-input>
                        </template>
                    </b-table>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Latticini e Formaggi</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.consumatoLatticini" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-table v-if="jsonData.consumatoLatticini === 'SI'" sticky-header ref="tblViaggi" stacked="xl" striped hover itemscope :items="jsonData.listaAlimentiLatticini" :fields="fieldsAlimentiCarne" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="jsonData">
                            {{ jsonData.index + 1 }}
                        </template>
                        <template #cell(consumato)="item">
                            <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="item.item.consumato" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                        </template>
                        <template #cell(specificareConfezionato)="item">
                            <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="item.item.specificareConfezionato" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                        </template>
                        <template #cell(nomeLuogoAcquisto)="item">
                            <b-form-input v-model="item.item.nomeLuogoAcquisto"></b-form-input>
                        </template>
                        <template #cell(marca)="item">
                            <b-form-input v-model="item.item.marca"></b-form-input>
                        </template>
                    </b-table>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Prodotti Pronti al Consumo</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.consumatoProdottiProntiAlConsumo" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-table v-if="jsonData.consumatoProdottiProntiAlConsumo === 'SI'" sticky-header ref="tblViaggi" stacked="xl" striped hover itemscope :items="jsonData.listaAlimentiProdottiPronti" :fields="fieldsProdottiProntiAlConsumo" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="jsonData">
                            {{ jsonData.index + 1 }}
                        </template>
                        <template #cell(consumato)="item">
                            <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="item.item.consumato" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                        </template>
                        <template #cell(preparazioneCasalingaDomestica)="item">
                            <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="item.item.preparazioneCasalingaDomestica" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                        </template>
                        <template #cell(preparazioneGastronomicaSupermercatoNegozio)="item">
                            <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="item.item.preparazioneGastronomicaSupermercatoNegozio" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                        </template>
                        <template #cell(preConfezionata)="item">
                            <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="item.item.preConfezionata" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                        </template>
                        <template #cell(marca)="item">
                            <b-form-input v-model="item.item.marca"></b-form-input>
                        </template>
                    </b-table>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Prodotti Pronti al Consumo, Specificare se Presenti Seguenti Alimenti</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.consumatoProdottiProntiAlConsumoPresentiIngredienti" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-table v-if="jsonData.consumatoProdottiProntiAlConsumoPresentiIngredienti === 'SI'" sticky-header ref="tblViaggi" stacked="xl" striped hover itemscope :items="jsonData.listaAlimentiIngredienti" :fields="fieldsIngredienti" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="jsonData">
                            {{ jsonData.index + 1 }}
                        </template>
                        <template #cell(presente)="item">
                            <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="item.item.presente" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                        </template>
                        <template #cell(marca)="item">
                            <b-form-input v-model="item.item.marca"></b-form-input>
                        </template>
                        <template #cell(qualePreparazione)="item">
                            <b-form-input v-model="item.item.qualePreparazione"></b-form-input>
                        </template>
                    </b-table>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Salse e Condimenti</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.consumatoSalse" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-table v-if="jsonData.consumatoSalse === 'SI'" sticky-header ref="tblViaggi" stacked="xl" striped hover itemscope :items="jsonData.listaAlimentiSalse" :fields="fieldsProdottiProntiAlConsumo" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="jsonData">
                            {{ jsonData.index + 1 }}
                        </template>
                        <template #cell(consumato)="item">
                            <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="item.item.consumato" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                        </template>
                        <template #cell(preparazioneCasalingaDomestica)="item">
                            <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="item.item.preparazioneCasalingaDomestica" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                        </template>
                        <template #cell(preparazioneGastronomicaSupermercatoNegozio)="item">
                            <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="item.item.preparazioneGastronomicaSupermercatoNegozio" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                        </template>
                        <template #cell(preConfezionata)="item">
                            <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="item.item.preConfezionata" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                        </template>
                        <template #cell(marca)="item">
                            <b-form-input v-model="item.item.marca"></b-form-input>
                        </template>
                    </b-table>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Dati Notifica" header-tag="header" footer-tag="footer" title="">
            <indagine-epidemiologica-dati-notifica-component :jsonData="jsonData" :isEdit="true" @update="onUpdateJsonData"></indagine-epidemiologica-dati-notifica-component>
        </b-card>
    </div>
</template>
<script>
import axios from "axios";
import Vue from "vue";
import UtilityMixin from "../../../utility/UtilityMixin";
import DatePicker from "vue2-datepicker";
import IndagineEpidemiologicaDatiNotificaComponent from "./IndagineEpidemiologicaDatiNotificaComponent.vue";
export default {
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    components: { IndagineEpidemiologicaDatiNotificaComponent, DatePicker }, //, DatePicker
    mixins: [UtilityMixin],
    data() {
        return {
            pathResource: "/malattieinfettiveielisteriosi",
            pathResourceSintomi: "/malattieinfettiveielisteriosisinotmi",
            pathResourceNegozi: "/malattieinfettiveielisteriosinegozi",
            pathResourceAlimenti: "/malattieinfettiveielisteriosialimenti",
            pathResourceComuni: "/comuni",
            pathResourceProvince: "/province",
            linkback: null,
            currentPage: 1,
            perPage: 100,
            showModalLoading: false,
            linkNazioni: "/nazioni",
            disableSintomi: true,
            jsonData: {
                id: "",
                idAnagrafica: "",
                dataInsorgenzaPrimiSintomi: null,
                ricoveroOspedaliero: "",
                ricoveroOspedalieroMotivo: "",
                ricoveroOspedalieroData: null,
                ricoveroOspedalieroDataDimissioni: null,
                ricoveroOspedalieroDiagnosi: "",
                ricoveroOspedalieroNomeOspedale: "",
                ricoveroOspedalieroNomeReparto: "",
                patologiePregresse: "",
                farmaciAssunti: "",
                prodottiOmeopaticiIntegratoriErboristici: "",
                prodottiOmeopaticiIntegratoriErboristiciQuali: "",
                mesePrecedenteRicoveratoDayHospital: "",
                mesePrecedenteRicoveratoDayHospitalDove: "",
                mesePrecedenteRicoveratoDayHospitalDal: null,
                mesePrecedenteRicoveratoDayHospitalAl: null,
                contattoCondivisoPastiStessiSintomi: "",
                fattoViaggi: "",
                fattoViaggiDove: "",
                fattoViaggiDal: null,
                fattoViaggiAl: null,
                fattoViaggiStruttura: "",
                fattoViaggiConsumatoAcquistatoAlimenti: "",
                fattoViaggiConsumatoAcquistatoAlimentiQuali: "",
                fattoViaggiConsumatoAcquistatoAlimentiLuogo: "",
                fattoViaggiConsumatoAcquistatoAlimentiData: null,
                fattoViaggiConsumatoConsumoAlimentiLuogo: "",
                fattoViaggiConsumatoConsumoAlimentiData: null,
                possessoTesseraFedelta: "",
                possessoTesseraFedeltaQuali: "",
                possessoTesseraFedeltaUtilizzoFrequente: "",
                consumatoPastiFuoriCasa: "",
                consumatoCarneProdottiDerivati: "",
                consumatoSalumi: "",
                consumatoVerduraFrutta: "",
                consumatoPesce: "",
                consumatoLatticini: "",
                consumatoProdottiProntiAlConsumo: "",
                consumatoProdottiProntiAlConsumoPresentiIngredienti: "",
                consumatoSalse: "",
                numeroProtocollo: "",
                dataProtocollo: null,
                statoAttualeProtocollo: "",
                insorgenzaPrimiSintomiAssociatoGravidanza: "",
                sanitarioNotificante: "",
                sanitarioNotificanteContatto: "",
                patologieNeonato: "",
                dataNotifica: null,
                listaSintomi: [],
                listaEsamiLaboratorio: [],
                listaContatti: [],
                listaNegozi: [],
                listaPastiFuoriCasa: [],
                listaAlimentiCarne: [],
                listaAlimentiSalumi: [],
                listaAlimentiVerduraFrutta: [],
                listaAlimentiPesce: [],
                listaAlimentiLatticini: [],
                listaAlimentiProdottiPronti: [],
                listaAlimentiIngredienti: [],
                listaAlimentiSalse: [],
            },
            fieldsSintomi: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Sintomo",
                    key: "sintomo",
                    thStyle: "width: 10rem",
                    sortable: true,
                },
                {
                    label: "Risposta",
                    key: "risposta",
                    thStyle: "width: 30rem",
                    sortable: false,
                },
                {
                    label: "Data Ora",
                    key: "dataOra",
                    thStyle: "width: 11rem",
                    sortable: false,
                },
            ],
            fieldsNegozi: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Negozio",
                    key: "negozio",
                    // thStyle: "width: 10rem",
                    sortable: true,
                },
                {
                    label: "Risposta",
                    key: "risposta",
                    thStyle: "width: 15rem",
                    sortable: false,
                },
                {
                    label: "Comune",
                    key: "comune",
                    thStyle: "width: 30rem",
                    sortable: false,
                },
                {
                    label: "Indirizzo",
                    key: "indirizzo",
                    thStyle: "width: 30rem",
                    sortable: false,
                },
            ],
            fieldsEsamiLaboratorio: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Esame",
                    key: "descrizioneEsame",
                    sortable: false,
                },
                {
                    label: "Risultato",
                    key: "risultato",
                    // thStyle: "width: 11rem",
                    sortable: false,
                },
                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 4rem",
                    tdClass: "text-center",
                },
            ],
            fieldsContatti: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Cognome",
                    key: "cognome",
                    thStyle: "width: 20rem",
                    sortable: false,
                },
                {
                    label: "Nome",
                    key: "nome",
                    thStyle: "width: 20rem",
                    sortable: false,
                },
                {
                    label: "Comune",
                    key: "comune",
                    thStyle: "width: 10rem",
                    sortable: false,
                },
                {
                    label: "Indirizzo",
                    key: "indirizzo",
                    // thStyle: "width: 5rem",
                    sortable: false,
                },
                {
                    label: "Telefono",
                    key: "telefono",
                    // thStyle: "width: 5rem",
                    sortable: false,
                },
                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 4rem",
                    tdClass: "text-center",
                },
            ],
            fieldsPastiFuoriCasa: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Struttura",
                    key: "struttura",
                    // thStyle: "width: 15rem",
                    sortable: false,
                },
                {
                    label: "Comune",
                    key: "comune",
                    thStyle: "width: 15rem",
                    sortable: false,
                },
                {
                    label: "Indirizzo",
                    key: "indirizzo",
                    // thStyle: "width: 5rem",
                    sortable: false,
                },
                {
                    label: "Data Consumo",
                    key: "dataConsumo",
                    thStyle: "width: 15rem",
                    sortable: false,
                },
                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 4rem",
                    tdClass: "text-center",
                },
            ],
            fieldsAlimentiCarne: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Tipo",
                    key: "tipo",
                    // thStyle: "width: 15rem",
                    sortable: false,
                },
                {
                    label: "Consumato",
                    key: "consumato",
                    thStyle: "width: 15rem",
                    sortable: false,
                },
                {
                    label: "Confezionato",
                    key: "specificareConfezionato",
                    thStyle: "width: 15rem",
                    sortable: false,
                },
                {
                    label: "Nome e Luogo Acquisto",
                    key: "nomeLuogoAcquisto",
                    thStyle: "width: 15rem",
                    sortable: false,
                },
                {
                    label: "Marca",
                    key: "marca",
                    thStyle: "width: 15rem",
                    sortable: false,
                },
            ],
            fieldsIngredienti: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Tipo",
                    key: "tipo",
                    // thStyle: "width: 15rem",
                    sortable: false,
                },
                {
                    label: "Presente",
                    key: "presente",
                    thStyle: "width: 15rem",
                    sortable: false,
                },
                {
                    label: "Marca",
                    key: "marca",
                    thStyle: "width: 15rem",
                    sortable: false,
                },
                {
                    label: "Tipo Preparazione",
                    key: "qualePreparazione",
                    thStyle: "width: 15rem",
                    sortable: false,
                },
            ],
            fieldsProdottiProntiAlConsumo: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Tipo",
                    key: "tipo",
                    // thStyle: "width: 15rem",
                    sortable: false,
                },
                {
                    label: "Consumato",
                    key: "consumato",
                    thStyle: "width: 15rem",
                    sortable: false,
                },
                {
                    label: "Prep. Casalinga/Domestica",
                    key: "preparazioneCasalingaDomestica",
                    thStyle: "width: 15rem",
                    sortable: false,
                },
                {
                    label: "Prep. Gastronomica Supermercato/Negozio",
                    key: "preparazioneGastronomicaSupermercatoNegozio",
                    thStyle: "width: 15rem",
                    sortable: false,
                },
                {
                    label: "Pre-Confezionata all'origine",
                    key: "preConfezionata",
                    thStyle: "width: 15rem",
                    sortable: false,
                },
                {
                    label: "Marca",
                    key: "marca",
                    thStyle: "width: 15rem",
                    sortable: false,
                },
            ],
            siNoOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "SI", text: "SI" },
                { value: "NO", text: "NO" },
            ],
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient() {
            return UtilityMixin.methods.getLinkPatient(this.utente, this.idPaziente);
        },
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.jsonData.idAnagrafica = me.idPaziente;
        me.jsonData.id = me.id;
        let malattiaInfettivaSegnalazione = sessionStorage.getItem("MalattiaInfettivaSegnalazione");
        let malattiaInfettiva = {};
        if (malattiaInfettivaSegnalazione !== null && malattiaInfettivaSegnalazione !== "null") {
            malattiaInfettiva = JSON.parse(malattiaInfettivaSegnalazione);
            me.setDatiMalattia(malattiaInfettiva);
        }
        sessionStorage.setItem("MalattiaInfettivaSegnalazione", null);
        if (me.id !== "-1") {
            me.linkback = "/malattieinfettive/indagineepidemiologica/covid/view/" + me.id + "?idPaziente=" + me.idPaziente;
        } else {
            me.linkback = "/malattieinfettive/all";
        }
        me.loadData();
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
        "jsonData.contattoCondivisoPastiStessiSintomi": function (value) {
            let me = this;
            if (value === "SI") {
                me.jsonData.listaContatti.push({ cognome: "", nome: "", comune: "", indirizzo: "", telefono: "" });
            } else {
                me.jsonData.listaContatti = [];
            }
        },
        "jsonData.consumatoPastiFuoriCasa": function (value) {
            let me = this;
            if (value === "SI") {
                me.jsonData.listaPastiFuoriCasa.push({ nomeStruttura: "", comune: "", indirizzo: "", dataConsumo: null });
            } else {
                me.jsonData.listaPastiFuoriCasa = [];
            }
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;

            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            me.showModalLoading = true;
            axios
                .get(link + me.id)
                .then((response) => {
                    me.jsonData = response.data.data;
                    me.jsonData.listaContatti.push({ cognome: "", nome: "", comune: "", indirizzo: "", telefono: "" });
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.setDatiMedico();
                    me.loadSintomi();
                    me.loadListaNegozi();
                    me.loadAlimenti(me.jsonData.listaAlimentiCarne, "CARNE E PRODOTTI DERIVATI");
                    me.loadAlimenti(me.jsonData.listaAlimentiSalumi, "SALUMI");
                    me.loadAlimenti(me.jsonData.listaAlimentiVerduraFrutta, "VERDURA E FRUTTA");
                    me.loadAlimenti(me.jsonData.listaAlimentiPesce, "PESCE, MOLLUSCHU E CROSTACEI");
                    me.loadAlimenti(me.jsonData.listaAlimentiLatticini, "LATTICINI E FORMAGGI");
                    me.loadAlimenti(me.jsonData.listaAlimentiProdottiPronti, "PRODOTTI PRONTI AL CONSUMO");
                    me.loadAlimenti(me.jsonData.listaAlimentiIngredienti, "INGREDIENTI PRODOTTI PRONTI AL CONSUMO");
                    me.loadAlimenti(me.jsonData.listaAlimentiSalse, "SALSE E CONDIMENTI");
                    me.showModalLoading = false;
                });
            me.jsonData.listaEsamiLaboratorio.push({ descrizioneEsame: "", risultato: "" });
        },
        loadSintomi() {
            let me = this;
            me.showModalLoading = true;

            let link = process.env.VUE_APP_PATH_API + me.pathResourceSintomi + "/";
            me.showModalLoading = true;
            axios
                .get(link)
                .then((response) => {
                    response.data.data.list.forEach((element) => {
                        me.jsonData.listaSintomi.push({ sintomo: element.descrizione, risposta: "NO", dataOra: null });
                    });
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        loadListaNegozi() {
            let me = this;
            me.showModalLoading = true;

            let link = process.env.VUE_APP_PATH_API + me.pathResourceNegozi + "/";
            me.showModalLoading = true;
            axios
                .get(link)
                .then((response) => {
                    response.data.data.list.forEach((element) => {
                        me.jsonData.listaNegozi.push({ negozio: element.descrizione, risposta: "NO", comune: "", indirizzo: "" });
                    });
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        loadAlimenti(array, tipologia) {
            let me = this;
            me.showModalLoading = true;

            let link = process.env.VUE_APP_PATH_API + me.pathResourceAlimenti + "?categoria=" + tipologia;
            me.showModalLoading = true;

            axios
                .get(link)
                .then((response) => {
                    if (tipologia !== "PRODOTTI PRONTI AL CONSUMO" && tipologia !== "SALSE E CONDIMENTI" && tipologia !== "INGREDIENTI PRODOTTI PRONTI AL CONSUMO") {
                        response.data.data.list.forEach((element) => {
                            array.push({ tipo: element.descrizione, consumato: "NO", specificareConfezionato: "", nomeLuogoAcquisto: "", marca: "" });
                        });
                    } else if (tipologia === "INGREDIENTI PRODOTTI PRONTI AL CONSUMO") {
                        console.log(tipologia);
                        response.data.data.list.forEach((element) => {
                            array.push({ tipo: element.descrizione, presente: "NO", marca: "" });
                        });
                    } else {
                        response.data.data.list.forEach((element) => {
                            array.push({ tipo: element.descrizione, consumato: "NO", preparazioneCasalingaDomestica: "", preparazioneGastronomicaSupermercatoNegozio: "", preConfezionata: "", marca: "", luogoAcquisto: "" });
                        });
                    }
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        setDatiMedico() {
            let me = this;
            me.jsonData.sanitarioNotificante = Vue.prototype.user.firstname + " " + Vue.prototype.user.lastname;
        },
        setDatiMalattia(data) {
            let me = this;
            me.jsonData.id = data.id;
        },
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
        },
        onAddEsamiLaboratorio(array, value) {
            if (value.descrizioneEsame !== "" && value.risultato !== "") {
                array.push({ descrizioneEsame: "", risultato: "" });
            } else {
                this.$bvModal
                    .msgBoxOk("Campi OBBLIGATORI!", {
                        title: "ATTENZIONE",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    })
                    .then((value) => {
                        console.log(value);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        onDeleteEsamiLaboratorio(array, row) {
            if (row.index != array.length) {
                this.$bvModal
                    .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
                        title: "Titolo",
                        buttonSize: "sm",
                        okVariant: "secondary",
                        cancelVariant: "danger",
                        okTitle: "SI",
                        cancelTitle: "NO",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true,
                    })
                    .then((value) => {
                        if (value) {
                            array.splice(row.index, 1);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        onAddPasto(array, value) {
            if (value.nomeStruttura !== "" && value.comune !== "" && value.indirizzo !== "" && value.dataConsumo !== null) {
                array.push({ nomeStruttura: "", comune: "", indirizzo: "", dataConsumo: null });
            } else {
                this.$bvModal
                    .msgBoxOk("Campi OBBLIGATORI!", {
                        title: "ATTENZIONE",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    })
                    .then((value) => {
                        console.log(value);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        onDeletePasto(array, row) {
            if (row.index != array.length) {
                this.$bvModal
                    .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
                        title: "Titolo",
                        buttonSize: "sm",
                        okVariant: "secondary",
                        cancelVariant: "danger",
                        okTitle: "SI",
                        cancelTitle: "NO",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true,
                    })
                    .then((value) => {
                        if (value) {
                            array.splice(row.index, 1);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        onAddContatto(array, value) {
            if (value.cognome !== "" && value.nome !== "" && value.comune !== "" && value.indirizzo !== "" && value.telefono !== "") {
                array.push({ cognome: "", nome: "", comune: "", indirizzo: "", telefono: "" });
            } else {
                this.$bvModal
                    .msgBoxOk("Campi OBBLIGATORI!", {
                        title: "ATTENZIONE",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    })
                    .then((value) => {
                        console.log(value);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        onDeleteContatto(array, row) {
            if (row.index != array.length) {
                this.$bvModal
                    .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
                        title: "Titolo",
                        buttonSize: "sm",
                        okVariant: "secondary",
                        cancelVariant: "danger",
                        okTitle: "SI",
                        cancelTitle: "NO",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true,
                    })
                    .then((value) => {
                        if (value) {
                            array.splice(row.index, 1);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
    },
};
</script>
