<template>
	<sa-page-layout :btnSaveVisible="true" :btnAnnullaVisible="true" :linkback="linkback" :data="jsonData" :showModalLoading="showModalLoading" :pathResource="pathResource" class="sa-no-space">
		<template slot="table-body">
			<data-analysis-query-edit-component @updateJsonData="onUpdateJsonData"></data-analysis-query-edit-component>
		</template>
	</sa-page-layout>
</template>

<script>
import DataAnalysisQueryEditComponent from "../components/DataAnalysisQueryEditComponent.vue";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
	components: { SaPageLayout, DataAnalysisQueryEditComponent },
	data() {
		return {
			showModalLoading: false,
			pathResource: "/dataanalysisquery",
			linkback: "/dataanalysisquery",
			jsonData: {},
		};
	},
	methods: {
		onUpdateJsonData(jsonData) {
			let me = this;
			me.jsonData = jsonData;
		},
	},
};
</script>

<style></style>
