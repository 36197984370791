<template>
    <div class="sa-free-page">
        <div class="sa-free-page-header">
            <b-card class="sa-card" header="Dettagli Profilo" header-tag="header" footer-tag="footer" title="">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Codice</label>
                        <span class="sa-data">{{ jsonData.codice }}</span>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Descrizione</label>
                        <span class="sa-data">{{ jsonData.descrizione }}</span>
                    </b-col>
                </b-row>
            </b-card>
        </div>
        <div class="sa-free-page-body">
            <b-card class="sa-card" header="Lista Prestazioni" header-tag="header" footer-tag="footer" title="">
                <div class="sa-config-menu-tab">
                    <ul class="sa-config-menu">
                        <template v-for="esame in jsonData.listaEsamiSelezionati">
                            <li class="sa-config-menu-li" :key="esame.id">
                                <span class="sa-config-menu-li-span">
                                    <span class="sa-config-menu-li-span">
                                        <!-- <span :class="voceMenu.children.length !== 0 ? 'sa-config-menu-li-icon sa-config-menu-pointer' : 'sa-config-menu-li-icon'"><i :class="'fas fa-' + voceMenu.iconCls"></i></span> -->
                                        <p>
                                            <span class="sa-config-menu-li-label sa-config-menu-pointer">{{ esame.descrizione }} ({{ esame.codiceOffering }})</span>
                                        </p>
                                        <span class="sa-config-menu-submenu-li-check-icon">
                                            <i class="bi bi-check-lg"></i>
                                        </span>
                                    </span>
                                </span>
                            </li>
                        </template>
                    </ul>
                </div>
            </b-card>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import UtilityMixin from "../../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixin],
    // components: { RasConfigurazionePostiLettoPianiEditComponent, RasConfigurazionePostiLettoStanzeListComponent },
    data() {
        return {
            id: null,
            strutturaOptions: [],
            pathResource: "/rsaprofililis",
            linkStrutture: "/strutture",
            jsonData: { id: null, codiceStruttura: null, idPresidio: null, nome: null, note: null },
            showModalLoading: false,
            idPiano: null,
            keyPiano: 100,
        };
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    mounted: function () {
        let me = this;
        me.id = this.$route.params.id;
        me.loadStrutture();
    },
    methods: {
        loadStrutture() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkStrutture;
            axios.get(link).then((response) => {
                me.strutturaOptions = response.data.data;
                me.strutturaOptions.unshift({ codiceStruttura: null, denominazione: this.$i18n.t("clinic.lblSelectStructure") });
                me.loadData();
            });
        },
        loadData() {
            let me = this;
            if (me.id === "-1") {
                this.$emit("afterLoadData");
            } else {
                // me.showModalLoading = true;
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                axios.get(link + me.id).then((response) => {
                    me.jsonData = response.data.data;
                    me.idPiano = me.jsonData.id;
                    me.keyPiano++;
                    // me.$refs.RasConfigurazionePostiLettoStanzeListComponent.loadData();
                    this.$emit("afterLoadData");
                });
            }
        },
        onClickPianoEdit() {
            let me = this;
            me.$refs.mdlPianoEdit.show();
        },
        onMdlPianoEditSave() {
            let me = this;
            let jsonData = me.$refs.cmpRasConfigurazionePostiLettoPianiEditComponent.getJsonData();
            UtilityMixin.methods.saveResource(me.pathResource, jsonData, me.saveSucessResponse, me.saveErrorResponse);
        },
        saveSucessResponse() {
            let me = this;
            me.loadData();
        },
        saveErrorResponse(response) {
            if (response.response.status === 400) {
                this.$emit("errorRestCall", response);
            } else {
                this.$bvToast.toast("Errore nel salvarei dati del Piano: " + response.message, {
                    title: "",
                    variant: "danger",
                    solid: true,
                });
            }
        },
        onClickStanzeAdd() {
            let me = this;
            console.log(me.keyPiano);
            me.keyPiano += 1;
            me.$refs.cmpRasConfigurazionePostiLettoStanzeEditComponent.mdlPianoEdit.show();
            // me.$refs.cmpRasConfigurazionePostiLettoStanzeEditComponent.openModal();
        },
    },
};
</script>

<style>
.sa-text-right {
    text-align: right;
}
@media only screen and (max-width: 767px) {
    .sa-text-right {
        text-align: left;
    }
}
.card {
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
    margin-bottom: 1rem;
}
.sa-config-menu-submenu-li-check-icon {
    width: 42px;
    font-size: 1.6rem;
    text-align: center;
    padding-top: 5px;
    padding-left: 15px;
    color: green;
}
</style>
