import CotPropostaAccessoListPage from "./pages/CotPropostaAccessoListPage.vue";
import CotPropostaAccessoEditPage from "./pages/CotPropostaAccessoEditPage.vue";
import CotStrutturaEditPage from "./pages/CotStrutturaEditPage.vue";
import CotStrutturaViewPage from "./pages/CotStrutturaViewPage.vue";
import CotStruttureListPage from "./pages/CotStruttureListPage.vue";
import CotPropostaAccessoPage from "./pages/CotPropostaAccessoPage.vue";
import CotPropostaAccessoSchedeValutazionePage from "./pages/CotPropostaAccessoSchedeValutazionePage.vue";
import CotPropostaAccessoAllegatiPage from "./pages/CotPropostaAccessoAllegatiPage.vue";
import CotDossierSanitarioPage from "./pages/CotDossierSanitarioPage.vue";
import CotPropostaAccessoModulisticaPage from "./pages/CotPropostaAccessoModulisticaPage.vue";
import CotPropostaAccessoValutazioneUvmPage from "./pages/CotPropostaAccessoValutazioneUvmPage.vue";
import CotAllertPage from "./pages/CotAllertPage.vue";
import CotStatiListPage from "./pages/CotStatiListPage.vue";
import CotStatiEditPage from "./pages/CotStatiEditPage.vue";
import CotPropostaAccessoListaAttesaPage from "./pages/CotPropostaAccessoListaAttesaPage.vue";
import CotTraccaimentoChiamateListPage from "./pages/CotTraccaimentoChiamateListPage.vue";
import CotTracciamentoChiamateEditPage from "./pages/CotTracciamentoChiamateEditPage.vue";
import CotPuaListPage from "./pages/CotPuaListPage.vue";
import CotPuaEditPage from "./pages/CotPuaEditPage.vue";
import CotPuaViewPage from "./pages/CotPuaViewPage.vue";
import CotPuaSedeViewPage from "./pages/CotPuaSedeViewPage.vue";

export default {
  CotPropostaAccessoListPage,
  CotPropostaAccessoEditPage,
  CotStrutturaEditPage,
  CotStrutturaViewPage,
  CotStruttureListPage,
  CotPropostaAccessoPage,
  CotPropostaAccessoSchedeValutazionePage,
  CotPropostaAccessoAllegatiPage,
  CotDossierSanitarioPage,
  CotPropostaAccessoModulisticaPage,
  CotPropostaAccessoValutazioneUvmPage,
  CotAllertPage,
  CotStatiListPage,
  CotStatiEditPage,
  CotPropostaAccessoListaAttesaPage,
  CotTraccaimentoChiamateListPage,
  CotTracciamentoChiamateEditPage,
  CotPuaListPage,
  CotPuaEditPage,
  CotPuaViewPage,
  CotPuaSedeViewPage
};
