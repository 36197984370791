<template>
    <sa-page-layout :btnBackVisible="true" :linkback="linkback" :linkedit="linkedit" :pathResource="pathResource" :showModalLoading="showModalLoading">
        <template slot="toolbar">
            <b-button size="sm" variant="success btn-toolbar sa-margin-right float-sm-right" :hidden="btnNuovaRichiestaEsameHidden" @click="onClickNuovaRichiestaEsame">
                <b-icon icon="plus"></b-icon>
                Nuova Richiesta
            </b-button>
            <b-button size="sm" variant="success btn-toolbar sa-margin-right float-sm-right" :hidden="btnNuovaRichiestaUscita" @click="onClickNuovaUscita">
                <b-icon icon="plus"></b-icon>
                Nuova Uscita
            </b-button>
        </template>
        <template slot="toolbar-title">
            <rsa-ospite-anagrafica-component :jsonData="jsonData"></rsa-ospite-anagrafica-component>
        </template>
        <template slot="table-body">
            <rsa-scheda-ospite-view-component ref="RsaGestioneOspitiViewComponent" :utente="utente" :jsonDataAccettazione="jsonData" @afterLoadData="onAfterLoadData" @onActiveTab="onActiveTab" @beforeLoadDataSchedaOspiteRefertiComponent="onBeforeLoadDataSchedaOspiteRefertiComponent" @afterLoadDataSchedaOspiteRefertiComponent="onAfterLoadDataSchedaOspiteRefertiComponent" @beforeSaveRequestSchedaOspiteRefertiComponent="onBeforeSaveRequestSchedaOspiteRefertiComponent" @afterSaveRequestSchedaOspiteRefertiComponent="onAfterSaveRequestSchedaOspiteRefertiComponent"></rsa-scheda-ospite-view-component>
        </template>
    </sa-page-layout>
</template>

<script>
import axios from "axios";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import RsaSchedaOspiteViewComponent from "../components/RsaSchedaOspiteViewComponent.vue";
import RsaOspiteAnagraficaComponent from "../components/RsaOspiteAnagraficaComponent.vue";

export default {
    props: {
        utente: { type: Object },
    },
    components: {
        SaPageLayout,
        RsaSchedaOspiteViewComponent,
        RsaOspiteAnagraficaComponent,
    },
    data() {
        return {
            btnNuovaRichiestaEsameHidden: true,
            btnNuovaRichiestaUscita: true,
            filtro: {},
            btnNewVisible: true,
            pathResource: null,
            pathResourceAccettazione: "/accettazioni",
            linkedit: null,
            linkback: "/rsagestioneospiti",
            showModalLoading: false,
            listKey: 0,
            jsonData: { accettazioneAnagrafica: {}, accettazionePrestazioni: {}, accettazioneStati: [], accettazioneStruttura: {} },
        };
    },
    created() {
        this.id = this.$route.params.id;
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.linkedit = "/rsagestioneospiti/edit/" + me.id;
        me.loadAccettazione();
    },
    methods: {
        loadAccettazione() {
            let me = this;

            let link = process.env.VUE_APP_PATH_API + me.pathResourceAccettazione + "/" + me.id;

            axios
                .get(link)
                .then((response) => {
                    me.jsonData = response.data.data;
                })
                .catch(() => {});
        },
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
        },
        onAfterLoadData() {
            let me = this;
            me.showModalLoading = false;
        },
        onActiveTab(idActiveTab) {
            let me = this;
            me.setCommand(idActiveTab);
        },
        setCommand(idActiveTab) {
            let me = this;
            me.btnNuovaRichiestaEsameHidden = true;
            me.btnNuovaRichiestaUscita = true;
            switch (idActiveTab) {
                case "tbRsaReferti":
                    me.btnNuovaRichiestaEsameHidden = false;
                    break;
                case "tbUscite":
                    me.btnNuovaRichiestaUscita = false;
                    break;
            }
        },
        onClickNuovaRichiestaEsame() {
            let me = this;
            me.$refs.RsaGestioneOspitiViewComponent.onNuovaRichiestaEsame();
        },
        onClickNuovaUscita() {
            let me = this;
            me.$refs.RsaGestioneOspiteUsciteComponent.onClickNuovaUscita();
        },
        onBeforeLoadDataSchedaOspiteRefertiComponent() {
            let me = this;
            me.showModalLoading = true;
        },
        onAfterLoadDataSchedaOspiteRefertiComponent() {
            let me = this;
            me.showModalLoading = false;
        },
        onBeforeSaveRequestSchedaOspiteRefertiComponent() {
            let me = this;
            me.showModalLoading = true;
        },
        onAfterSaveRequestSchedaOspiteRefertiComponent() {
            let me = this;
            me.showModalLoading = false;
        },
    },
};
</script>
<style></style>
