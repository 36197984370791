<template>
  <sa-page-layout :btnNewVisible="btnNewVisible" :btnRefreshVisible="true" @refresh="onRefresh" :pathResource="pathResource" :linkedit="linkedit" :showModalLoading="showModalLoading">
    <template slot="table-filter">
      <b-form @submit.prevent="onSubmit">
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data">Codice Struttura</label>
            <b-form-input v-model="filtro.codiceStruttura" type="search" id="codiceStruttura" placeholder="Codice Struttura"></b-form-input>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data">Denominazione</label>
            <b-form-input v-model="filtro.denominazione" type="search" id="denominazione" placeholder="Denominazione"></b-form-input>
          </b-col>
        </b-row>
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="sa-padding-right text-right">
            <b-button type="submit" variant="info">{{ this.$i18n.t("patients.lblFind") }}</b-button>
            <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("patients.lblResetFilter") }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </template>
    <template slot="table-body">
      <malattie-infettive-strutture-list-component ref="MalattieInfettiveStruttureListComponent" @afterLoadData="onAfterLoadData"></malattie-infettive-strutture-list-component>
    </template>
  </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import MalattieInfettiveStruttureListComponent from "../components/MalattieInfettiveStruttureListComponent.vue";
import axios from "axios";
export default {
  components: { SaPageLayout, MalattieInfettiveStruttureListComponent },
  data() {
    return {
      filtro: { codiceStruttura: null, denominazione: null, codicePrestazione: "", tipologiaStruttura: "" },
      btnNewVisible: true,
      pathResource: "",
      linkedit: "/malattieinfettive/strutture",
      linkCodiciPrestazioni: "/cotcodiciprestazione",
      linkTipoStruttura: "/cotstrutturetipi",
      showModalLoading: false,
      listKey: 0,
      codicePrestazioneOptions: [{ value: "", text: "-Seleziona Valore-" }],
      tipoStrutturaOptions: [{ value: "", text: "-Seleziona Valore-" }],
    };
  },
  mounted() {
    let me = this;
    let sessionStorage = window.sessionStorage;
    if (sessionStorage["filtro"]) {
      me.filtro = JSON.parse(sessionStorage["filtro"]);
    }
    // me.loadCodiciPrestazioni();
    // me.loadTipoStruttura();
    me.loadData();
  },
  methods: {
    loadCodiciPrestazioni() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkCodiciPrestazioni;
      me.codicePrestazioneOptions = [];
      axios.get(link).then((response) => {
        response.data.data.list.forEach((element) => {
          me.codicePrestazioneOptions.push({ value: element.codice, text: element.codice });
        });
        me.codicePrestazioneOptions.unshift({ value: "", text: "-Seleziona Valore-" });
      });
    },
    loadTipoStruttura() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkTipoStruttura;
      me.tipoStrutturaOptions = [];
      axios.get(link).then((response) => {
        response.data.data.list.forEach((element) => {
          me.tipoStrutturaOptions.push({ value: element.tipo, text: element.descrizione });
        });
        me.tipoStrutturaOptions.unshift({ value: "", text: "-Seleziona Valore-" });
      });
    },
    loadData() {
      let me = this;
      me.showModalLoading = true;
      me.$refs.MalattieInfettiveStruttureListComponent.loadData();
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
    onAfterLoadData() {
      let me = this;
      me.showModalLoading = false;
    },
    onSubmit() {
      let me = this;
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onReset() {
      let me = this;
      me.filtro = { codiceStruttura: null, denominazione: null, codicePrestazione: "", tipologiaStruttura: "" };
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
  },
};
</script>

<style></style>
