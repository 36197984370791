<template>
    <div class="sa-list-component">
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <template #header>
                <b-row>
                    <b-col cols="12" xs="8" sm="8" md="8" lg="8" xl="8">
                        <span class="sa-label-data">LISTA PRESTAZIONI</span>
                    </b-col>
                    <b-col class="sa-padding-right text-right" cols="12" xs="4" sm="4" md="4" lg="4" xl="4">
                        <b-button class="float-sm-right" variant="outline-success btn-toolbar sa-margin-right" size="sm" v-b-modal.modalAssociaUtente>
                            <b-icon icon="plus" aria-hidden="true"></b-icon>
                            Associa Prestazione
                        </b-button>
                    </b-col>
                </b-row>
            </template>
            <div class="sa-list-component-header">
                <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col lg="3">
                        <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                    </b-col>
                    <b-col lg="9">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                    </b-col>
                </b-row>
            </div>
            <!-- <div class="sa-list-component-body"> -->
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" stacked="lg" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
                    <template #cell(index)="item">
                        {{ item.index + 1 }}
                    </template>
                    <template #cell(actions)="row">
                        <b-button size="sm" variant="btn btn-outline-secondary waves-effect waves-light width-sm no-text" @click="onClickStorico(row.item)">
                            <i class="fas fa-info"></i>
                            Storico
                        </b-button>
                        <b-button size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onUpdatePostiLetto(row.item)">
                            <i class="fas fa-bed"></i>
                        </b-button>
                        <b-button variant="outline-danger" @click="onDeleteAssociazione(row.item)">
                            <b-icon icon="trash" aria-hidden="true"></b-icon>
                            Dissocia
                        </b-button>
                    </template>
                </b-table>
                <b-modal size="lg" ref="modalAssocia" id="modalAssociaUtente" title="Associa Prestazione" @show="onShow()" scrollable>
                    <div class="b-table-sticky-header">
                        <b-table sticky-header :busy="isBusy" ref="table" selectable stacked="lg" striped hover :items="itemsUtenti" :fields="fieldsModal" sort-icon-left head-variant="light" class="sa-b-table" current-page="1" :per-page="perPageUsers" @row-selected="onRowSelected">
                            <template #table-busy>
                                <div class="text-center text-info my-2">
                                    <b-spinner class="align-middle"></b-spinner>
                                    <strong>Caricamento...</strong>
                                </div>
                            </template>
                            <template #cell(selected)="{ rowSelected }">
                                <template v-if="rowSelected">
                                    <span aria-hidden="true">&check;</span>
                                    <span class="sr-only">Selected</span>
                                </template>
                                <template v-else>
                                    <span aria-hidden="true">&nbsp;</span>
                                    <span class="sr-only">Not selected</span>
                                </template>
                            </template>
                            <template #cell(index)="item">
                                {{ item.index + 1 }}
                            </template>
                        </b-table>
                    </div>

                    <template #modal-footer="{ ok }">
                        <b-button size="sm" variant="outline-danger" @click="ok()">
                            <b-icon icon="x"></b-icon>
                            Annulla
                        </b-button>
                        <b-button size="sm" variant="outline-success" @click="onAssocia()">
                            <b-icon icon="plus"></b-icon>
                            Associa
                        </b-button>
                    </template>
                </b-modal>
                <b-modal size="lg" ref="modalStoricoDisponibilita" id="modalStoricoDisponibilita" title="Storico Disponibilita" scrollable>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-table sticky-header ref="table" stacked="xl" striped hover :items="listaStoricoDisponibilita" :fields="fieldsDisponibilita" sort-icon-left head-variant="light" class="sa-b-table">
                                <template #cell(index)="row">
                                    {{ row.index + 1 }}
                                </template>
                                <template v-slot:cell(createDate)="{ item }">
                                    <span>{{ formatDateTime(item.createDate) }}</span>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                    <!-- {{ listaStoricoDisponibilita }} -->
                </b-modal>
                <b-modal b-modal ref="mdlPostiLetto" id="mdlmdlPostiLetto" size="md" style="height: 100%" @ok="onAddPostiLetto" title="Inserire numero posti letto">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Disponibilita'</label>
                            <b-form-input v-model="strutturePostiLetto.disponibilita" type="number" id="disponibilita" placeholder="Inserire numero posti letto"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Letti Occupati</label>
                            <b-form-input v-model="strutturePostiLetto.postiOccupati" type="number" id="disponibilita" placeholder="Inserire numero posti letto"></b-form-input>
                        </b-col>
                    </b-row>
                </b-modal>
            </div>
            <!-- </div> -->
            <div class="sa-list-component-footer">
                <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col lg="3">
                        <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rows }}</p>
                    </b-col>
                    <b-col lg="9">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import axios from "axios";
import UtilityMixin from "../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixin],
    props: {
        idStruttura: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            rows: 0,
            perPage: 50,
            perPageUsers: 100000,
            currentPage: 1,
            currentPageUsers: 1,
            pathResource: "/cotstruttureprestazioni/prestazioni",
            pathResourceStrutturePrestazioni: "/cotstruttureprestazioni",
            pathResourceStrutturePrestazioniDisponibilita: "/cotstruttureprestazionidisponibilita",
            pathResourcePrestazioni: "/cotcodiciprestazione",
            pathResourceGruppiUtenti: "/gruppi",
            filtro: {},
            filtroUsers: { forPage: 0, page: 0, user: "", profilo: "" },
            items: [],
            mdlFiltroUsers: {
                profilo: "",
                username: "",
            },
            profiliModalOptions: [],
            itemsUtenti: [],
            listaStoricoDisponibilita: [],
            selected: [],
            strutturePostiLetto: { idStrutturaPrestazione: "", disponibilita: 0, postiOccupati: 0 },
            listKey: 0,
            isBusy: false,
            fields: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Codice Prestazione",
                    key: "codice",
                    sortable: true,
                    // thStyle: "width: 15rem",
                },
                {
                    label: "Disp. Letti",
                    key: "postiDisponibili",
                    sortable: true,
                    thStyle: "width: 4rem",
                },
                {
                    label: "Letti Occupati",
                    key: "postiOccupati",
                    sortable: true,
                    thStyle: "width: 4rem",
                },
                {
                    label: "Letti Prenotati",
                    key: "postiPrenotati",
                    sortable: true,
                    thStyle: "width: 4rem",
                },
                {
                    label: "Letti Liberi",
                    key: "postiLiberi",
                    sortable: true,
                    thStyle: "width: 4rem",
                },
                {
                    label: "",
                    key: "actions",
                    tdClass: "text-right",
                },
            ],
            fieldsModal: [
                {
                    label: "",
                    key: "selected",
                    thStyle: "width: 2.5rem",
                    tdClass: "text-center",
                },
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Codice Prestazione",
                    key: "codice",
                    sortable: true,
                    // thStyle: "width: 15rem",
                },
            ],
            fieldsDisponibilita: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Disponibilita",
                    key: "disponibilita",
                    sortable: true,
                    thStyle: "width: 20rem",
                },
                {
                    label: "Data Ora",
                    key: "createDate",
                    sortable: true,
                    thStyle: "width: 20rem",
                },
            ],
        };
    },
    mounted() {
        let me = this;
        // console.log(me.idStruttura);

        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            if (sessionStorage["filtro"]) {
                me.filtro = JSON.parse(sessionStorage["filtro"]);
            }
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.filtro.page = me.currentPage;
            me.filtro.forPage = me.perPage;
            me.filtro.idStruttura = me.idStruttura;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];
                    me.rows = response.data.data.recordsNumber;
                    me.items = response.data.data.list;
                    me.listKey++;
                    this.$emit("afterLoadData");
                })
                .catch(() => {
                    this.$emit("afterLoadData");
                });
        },

        onDeleteAssociazione(item) {
            let me = this;
            this.$bvModal
                .msgBoxConfirm("Sei sicuro di procedere alla cancellazione dell'associazione?", {
                    title: "Conferma Cancellazione Associazione",
                    buttonSize: "sm",
                    okVariant: "secondary",
                    cancelVariant: "danger",
                    okTitle: "SI",
                    cancelTitle: "NO",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                })
                .then((value) => {
                    if (value) {
                        me.deleteAssociazione(item);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        deleteAssociazione(item) {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceStrutturePrestazioni + "/";
            axios
                .delete(link + item.id)
                .then((response) => {
                    console.log(response);
                    me.showModalLoading = false;
                    me.loadData();
                })
                .catch((error) => {
                    console.log(error);
                    me.showModalLoading = false;
                });
        },
        onShow() {
            let me = this;
            me.loadDataPrestazioni();
        },
        onRowSelected(items) {
            this.selected = items;
        },
        loadDataPrestazioni() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResourcePrestazioni;
            me.showModalLoading = true;
            me.filtroUsers.forPage = me.perPageUsers;
            me.filtroUsers.page = me.currentPageUsers;
            me.filtroUsers.username = me.mdlFiltroUsers.username;
            me.filtroUsers.profilo = me.mdlFiltroUsers.profilo;
            axios.get(link, { params: me.filtroUsers }).then((response) => {
                me.isBusy = false;
                me.showModalLoading = false;
                me.itemsUtenti = response.data.data.list;
            });
        },
        loadDefaultData() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceGruppiUtenti;
            axios
                .get(link)
                .then((response) => {
                    response.data.data.forEach((element) => {
                        me.profiliModalOptions.push({ text: element.nome, value: element.nome });
                    });
                })
                .finally(() => {
                    me.profiliModalOptions.unshift({ text: this.getLabelTraduora("patient.ecoreferting.lblSelected"), value: "" });
                });
        },
        onAssocia() {
            let me = this;
            for (let i = 0; i < me.selected.length; i++) {
                let obj = { id: null, idStruttura: me.idStruttura, idCotCodiciPrestazione: me.selected[i].id };
                me.associaUtenteStruttura(obj);
            }
        },
        associaUtenteStruttura(obj) {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceStrutturePrestazioni + "/";
            axios
                .post(link, { idStruttura: obj.idStruttura, idCotCodiciPrestazione: obj.idCotCodiciPrestazione })
                .then((response) => {
                    me.$refs["modalAssocia"].hide();
                    response.data.data;
                    me.showModalLoading = false;
                    me.loadData();
                    // console.log(me.jsonData);
                    this.$bvToast.toast(`Associazione Prestazione Avvenuta con Successo`, {
                        title: "Associazione Prestazione",
                        solid: true,
                        variant: "success",
                        autoHideDelay: 1000,
                        appendToast: true,
                    });
                })
                .catch((e) => {
                    console.log(e);
                    me.showModalLoading = false;
                });
        },
        onUpdatePostiLetto(value) {
            let me = this;
            // console.log(value);
            me.strutturePostiLetto.idStrutturaPrestazione = value.id;
            me.strutturePostiLetto.disponibilita = value.disponibilita;
            me.strutturePostiLetto.postiOccupati = value.postiOccupati;
            me.$refs["mdlPostiLetto"].show();
        },
        onClickStorico(value) {
            let me = this;
            me.loadStoricoPrestazioneDisponibilita(value.id);
        },

        loadStoricoPrestazioneDisponibilita(idPrestazioneDisponibilita) {
            let me = this;
            let sessionStorage = window.sessionStorage;
            if (sessionStorage["filtro"]) {
                me.filtro = JSON.parse(sessionStorage["filtro"]);
            }
            let link = process.env.VUE_APP_PATH_API + me.pathResourceStrutturePrestazioniDisponibilita;
            let filtro = { idStrutturaPrestazione: idPrestazioneDisponibilita };
            axios
                .get(link, { params: filtro })
                .then((response) => {
                    me.listaStoricoDisponibilita = [];
                    // me.listaStorico = response.data.data.recordsNumber;
                    me.listaStoricoDisponibilita = response.data.data.list;
                    // me.listKey++;
                    this.$emit("afterLoadData");
                    me.$refs["modalStoricoDisponibilita"].show();
                })
                .catch(() => {
                    this.$emit("afterLoadData");
                });
        },

        onAddPostiLetto() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceStrutturePrestazioniDisponibilita;
            axios
                .post(link, JSON.stringify(me.strutturePostiLetto))
                .then(() => {
                    me.$refs["mdlPostiLetto"].hide();
                    this.$bvToast.toast("Disponibilita' letti aggiornata", {
                        title: this.titleModal,
                        variant: "success",
                        autoHideDelay: 1000,
                    });
                    me.loadData();
                })
                .catch((error) => {
                    me.showModalLoading = false;
                    this.$bvModal
                        .msgBoxOk(error.response.data.messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then((value) => {
                            this.boxTwo = value;
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                });
        },
    },
};
</script>

<style>
.sa-alert-descrizione {
    font-style: italic;
}
</style>
