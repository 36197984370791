<template>
    <div style="height: 100%; overflow: auto">
        <b-card class="sa-card" header="Dettagli Stato" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="3" xl="3">
                    <label class="sa-label-data">Stato</label>
                    <b-form-input v-model="jsonData.stato" placeholder="Stato"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="3" xl="3">
                    <label class="sa-label-data">Warning (In Ore)</label>
                    <b-form-input v-model="jsonData.warning" placeholder="Warning (In Ore)"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="3" xl="3">
                    <label class="sa-label-data">Alert (In Ore)</label>
                    <b-form-input v-model="jsonData.danger" placeholder="Alert (In Ore)"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="3">
                    <label class="sa-label-data">Invio E-Mail</label>
                    <b-form-input v-model="jsonData.sendMail" placeholder="Invio E-Mail"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Destinatari E-Mail</label>
                    <b-form-input v-model="jsonData.sendMailDestination" placeholder="Destinatari E-Mail"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Descrizione</label>
                    <b-form-textarea v-model="jsonData.descrizione" rows="5"></b-form-textarea>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Messaggio di Warning</label>
                    <b-form-textarea v-model="jsonData.warningMessage" rows="5"></b-form-textarea>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Messaggio di Alert</label>
                    <b-form-textarea v-model="jsonData.dangerMessage" rows="5"></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import axios from "axios";
export default {
    data() {
        return {
            pathResource: "/cotstati",
            jsonData: { stato: null, warning: 0, danger: 0, sendMail: "NO", sendMailDestination: "", descrizione: "", warningMessage: "", dangerMessage: "" },
        };
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    mounted: function () {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            if (me.id === "-1") {
                this.$emit("afterLoadData");
            } else {
                // me.showModalLoading = true;
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                axios.get(link + me.id).then((response) => {
                    me.jsonData = response.data.data;

                    this.$emit("afterLoadData");
                });
            }
        },
    },
};
</script>

<style></style>
