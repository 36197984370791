<template>
    <b-modal ref="mdlAssociaKit" title="Associa Kit" @hidden="onCloseModal" @ok="onClickOk" centered no-close-on-backdrop no-close-on-esc hide-header-close>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">KIT:</label>
                    <b-form-select v-model="kit.kitId" :options="kitOptions" size="l" :value="null" value-field="kitId" text-field="kitName"></b-form-select>
                </b-col>
            </b-row>
        </b-card>
    </b-modal>
</template>

<script>
import UtilityMixin from "../../../utility/UtilityMixin";
// import DatePicker from "vue2-datepicker";
import axios from "axios";
export default {
    mixins: [UtilityMixin],
    components: {},
    data() {
        return {
            isNew: true,
            isReadonly: false,
            isRequired: false,
            pathResource: "/hassistokits/assignkit",
            pathKitlist: "/hassistokits",

            kit: { kitId: null, patientId: this.idPaziente },
            kitOptions: [{ kitId: null, kitName: "--Seleziona KIT Libero--" }],
        };
    },
    methods: {
        openModal(idPaziente) {
            let me = this;
            me.idPaziente = idPaziente;
            me.loadKits();
            me.$refs.mdlAssociaKit.show();
        },
        loadKits() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathKitlist;
            axios
                .get(link)
                .then((Response) => {
                    Response.data.data.forEach((element) => {
                        if (element.patientId === null) {
                            me.kitOptions.push(element.kitId);
                        }
                    });
                })
                .catch(() => {});
        },
        onCloseModal() {
            let me = this;
            me.jsonData = {};
        },
        onClickOk(bvModalEvent) {
            let me = this;
            bvModalEvent.preventDefault();
            // console.log(me.jsonData);
            me.save();
        },
        save() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            axios
                .put(link, { kitId: me.kit.kitId, patientId: me.idPaziente })
                .then((response) => {
                    me.$refs.mdlAssociaKit.hide();
                    me.$emit("afterSaveSuccess", response);
                })
                .catch((e) => {
                    console.log(e);
                });
        },
    },
};
</script>
