<template>
    <b-modal ref="mdlEditPaiRuoli" :title="isNew ? 'Nuovo Ruolo' : 'Modifica Ruolo'" @hidden="onCloseModal" @ok="onClickOk" centered no-close-on-backdrop no-close-on-esc hide-header-close>
        <b-row>
            <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <span class="sa-form-label">Ruolo</span>
                <b-form-input v-model="jsonDataRuoloPai.ruolo"></b-form-input>
            </b-col>

            <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <span class="sa-form-label">Tipologia di Accesso</span>
                <b-form-select v-model="jsonDataRuoloPai.tipologiaAccesso" :options="tipologiaAccessoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <span class="sa-form-label">Descrizione</span>
                <b-form-textarea v-model="jsonDataRuoloPai.descrizione" max-rows="10" rows="7"></b-form-textarea>
            </b-col>
        </b-row>
    </b-modal>
</template>

<script>
import axios from "axios";
import UtilityMixin from "../../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixin],
    data() {
        return {
            isNew: true,
            pathResourcePaiRuoli: "/rsapairuoli",
            tipologiaAccessoOptions: [
                { text: "-SELEZIONA TIPOLOGIA DI ACCESSO-", value: null },
                { text: "FARMACI", value: "FARMACI" },
                { text: "ICD-IX", value: "ICD-IX" },
                { text: "ICD-X", value: "ICD-X" },
                { text: "Nomenclatore", value: "NOMENCLATORE" },
                { text: "TESTO LIBERO", value: "TESTO LIBERO" },
            ],
            jsonDataRuoloPai: {
                ruolo: null,
                tipologiaAccesso: null,
                descrizione: null,
            },
        };
    },

    methods: {
        editRuoloPai(id) {
            let me = this;
            me.isNew = false;
            //  me.jsonDataRuoloPai = { ...item };
            /* console.log(me.jsonDataRuoloPai); */
            me.loadData(id);
            me.$refs.mdlEditPaiRuoli.show();
        },
        loadData(id) {
            let me = this;
            let link = me.pathResourcePaiRuoli + "/" + id;
            UtilityMixin.methods.loadDataResources(link, {}, me.resultResponse, me.resultError);
        },
        resultResponse(response) {
            let me = this;
            me.jsonDataRuoloPai = response.data.data;
        },
        resultError(response) {
            let me = this;
            if (response.response.status === 400) {
                me.$bvModal.msgBoxConfirm(response.response.data.messaggio, {
                    title: "ATTENZIONE",
                    size: "md",
                    okVariant: "outline-danger",
                    headerClass: "sm-msg-header-danger",
                    footerClass: "p-2 border-top-0",
                    centered: true,
                });
            } else {
                me.$bvToast.toast("Errore durante il salvataggio", response.message, {
                    title: "Errore",
                    variant: "danger",
                    solid: true,
                });
            }
        },
        nuovoRuoloPai() {
            let me = this;
            me.isNew = true;
            me.jsonDataRuoloPai = {
                ruolo: null,
                tipologiaAccesso: null,
                descrizione: null,
            };
            me.$refs.mdlEditPaiRuoli.show();
        },

        onCloseModal() {
            let me = this;
            me.jsonDataRuoloPai = {};
            me.$emit("onCloseModal");
        },
        onClickOk(bvModalEvent) {
            bvModalEvent.preventDefault();
            let me = this;
            UtilityMixin.methods.saveResource(me.pathResourcePaiRuoli, me.jsonDataRuoloPai, me.saveRuoloSuccessResponse, me.saveRuoloErrorResponse);
        },
        saveRuoloSuccessResponse(response) {
            let me = this;
            me.$emit("onSaveRuoloPai", response);
            me.$refs.mdlEditPaiRuoli.hide();
            me.$bvToast.toast("Salvataggio avvenuto con successo", {
                title: "Successo",
                variant: "success",
                solid: true,
            });
        },

        saveRuoloErrorResponse(response) {
            let me = this;
            if (response.response.status === 400) {
                me.$bvModal.msgBoxConfirm(response.response.data.messaggio, {
                    title: "ATTENZIONE",
                    size: "md",
                    okVariant: "outline-danger",
                    headerClass: "sm-msg-header-danger",
                    footerClass: "p-2 border-top-0",
                    centered: true,
                });
            } else {
                me.$bvToast.toast("Errore durante il salvataggio", response.message, {
                    title: "Errore",
                    variant: "danger",
                    solid: true,
                });
            }
        },

        deleteRuoloPai(id) {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResourcePaiRuoli + "/" + id;
            axios
                .delete(link)
                .then((response) => {
                    /* me.showModalLoading = false; */
                    me.$emit("onDeleteRuoloPai", response);
                    me.$bvToast.toast("Eliminazione avvenuta con successo", {
                        title: "Success",
                        variant: "success",
                        solid: true,
                    });
                })
                .catch((e) => {
                    me.showModalLoading = false;
                    console.log(e);
                    me.$bvToast.toast("Errore durante l'eliminazione", {
                        title: "Errore",
                        variant: "danger",
                        solid: true,
                    });
                });
        },
        onDeleteRuoloPai(item) {
            let me = this;
            me.$bvModal
                .msgBoxConfirm("Sei sicuro di eliminare questo ruolo?", {
                    title: "Eliminazione Ruolo PAI",
                    buttonSize: "sm",
                    okTitle: "SI",
                    cancelTitle: "NO",
                    okVariant: "danger",
                    cancelVariant: "secondary",
                    hideHeaderClose: true,
                    footerClass: "p-2",
                    centered: true,
                })
                .then((value) => {
                    if (value) {
                        me.deleteRuoloPai(item.id);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
};
</script>
