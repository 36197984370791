<template>
  <sa-page-layout :showModalLoading="showModalLoading" :pathResource="pathResource" :btnBackVisible="true" :btnEditVisible="false" :linkback="linkback" :linkedit="linkedit" :data="jsonData">
    <template slot="toolbar-title">Dettaglio Cliente</template>
    <template slot="table-body"> <clienti-view-component :jsonData="jsonData"></clienti-view-component>
      <b-table sticky-header ref="table" :filter="filter" stacked="lg" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
        <!-- <template v-slot:cell(descrizione)="{ item }">
            <router-link class="sa-edit-link" :to="'/clienti/view/' + item.id">{{ item.descrizione }} </router-link>
          </template> -->
        <!-- <template v-slot:cell(actions)="row">
            <b-button size="sm" variant="btn btn-danger waves-effect waves-light width-sm no-text" style="margin-right: 3px" @click="onDelete(row.item)" v-b-modal.visualizzaAllegato>
              <b-icon icon="trash"></b-icon>
            </b-button>
          </template> -->
      </b-table>
    </template>
  </sa-page-layout>
</template>


<script>
import ClientiViewComponent from "../components/ClientiViewComponent.vue";
import SaPageLayout from "../../../template/layout/components/SaPageLayout.vue";
import axios from "axios";
export default {
  // components: { SaPageLayout},
  components: { SaPageLayout, ClientiViewComponent },
  data() {
    return {
      pathResource: "/clienti",
      linkback: "/clienti",
      linkedit: null,
      perPage: 100,
      currentPage: 1,
      filter: {},
      items: [],
      showModalLoading: false,
      regioniOptions: [],
      provinceOptions: [{ provincia: "-Seleziona Provincia-", sigla: "" }],
      comuniOptions: [{ comune: "-Seleziona Comune-", codiceIstat: "" }],
      statiOptions: [
        { text: "ATTIVO", field: "ATTIVO" },
        { text: "SOSPESO", field: "SOSPESO" },
        { text: "CHIUSO", field: "CHIUSO" },
      ],
      jsonData: { descrizione: "", regione: "", provincia: "", comune: "", stato: "ATTIVO" },
      fields: [
        {
          label: "Destinazione",
          key: "descrizione",
          sortable: true,
        },
        // {
        //   label: "Nome",
        //   key: "nome",
        //   thStyle: "width: 30rem",
        //   sortable: true,
        // },
        // {
        //   label: "Cognome",
        //   key: "cognome",
        //   thStyle: "width: 30rem",
        //   sortable: true,
        // },
        // {
        //   label: "Comune",
        //   key: "comuneLabel",
        //   thStyle: "width: 7rem",
        //   sortable: true,
        // },
        // {
        //   label: "Indirizzo",
        //   key: "indirizzo",
        //   sortable: true,
        // },
        // {
        //   label: "",
        //   key: "actions",
        //   sortable: false,
        //   thStyle: "width: 3rem",
        // },
      ],
    };
  },
  mounted: function () {
    let me = this;
    me.id = this.$route.params.id;
    me.linkedit = "/clienti/edit/" + me.id;
    me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/clienti/" + me.id;
      axios.get(link).then((response) => {
        me.jsonData = response.data.data;
        // console.log(me.jsonData.destinazioni);
        me.items = me.jsonData.destinazioni;
        // console.log(me.items);
      });
    },
  },
};
</script>

<style></style>
