<template>
    <sa-page-layout :showModalLoading="showModalLoading" :btnSaveVisible="true" :btnSaveNotBackVisible="true" :btnAnnullaVisible="true" :linkback="linkback" :linkNotBack="linkNotBack" :pathResource="pathResource" :data="jsonData" @aftersave="onAfterSave" class="sa-no-space">
        <template slot="toolbar">
            <b-button v-if="btnAllegatiVisible" variant="outline-success btn-toolbar sa-margin-left float-sm-left" v-b-modal.mdlAllegati>
                <b-icon icon="file-earmark-plus" aria-hidden="true"></b-icon>
                {{ getLabelTraduora("patient.afa.btnNewAllegato") }}
            </b-button>
        </template>
        <template slot="toolbar-title">
            <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente" @getAnagraficaPaziente="getAnagraficaPaziente"></paziente-anagrafica>
        </template>
        <template slot="table-header">
            <h3 class="sa-event-title">{{ titoloEvento }}</h3>
        </template>
        <template slot="table-body">
            <b-tabs v-model="tabIndex" content-class="mt-3" :class="getTabClass()">
                <b-tab active @click="onTabClick">
                    <template #title>
                        <div class="sa-tab-title-normal">
                            <div style="float: left">
                                <span>{{ getLabelTraduora("pazienti.egds.lblData") }}</span>
                            </div>
                        </div>
                    </template>
                    <slot name="tab-content-event"></slot>
                </b-tab>
                <b-tab v-if="isImmagini" :disabled="id == '-1'" @click="onTabClick">
                    <template #title>
                        <div class="sa-tab-title-normal">
                            <div style="float: left">
                                <span>Immagini</span>
                            </div>
                        </div>
                    </template>
                    <div class="sa-tab-scroll">
                        <eventi-immagini-edit-component :idRisorsa="idRisorsa" :contesto="contesto"></eventi-immagini-edit-component>
                    </div>
                </b-tab>
                <b-tab v-if="isAllegati" :disabled="tabDisabled" @click="onTabClick">
                    <template #title>
                        <div class="sa-tab-title-normal">
                            <div style="float: left">
                                <span>{{ getLabelTraduora("pazienti.egds.Allegati") }}</span>
                            </div>
                        </div>
                    </template>
                    <div class="sa-tab-scroll">
                        <paziente-allegati-list-component :key="childKey" ref="allegatiListC" :jsonData="jsonData"></paziente-allegati-list-component>
                    </div>
                </b-tab>
                <b-tab v-if="isPrestazioni" :disabled="tabDisabled" @click="onTabClick">
                    <template #title>
                        <div class="sa-tab-title-normal">
                            <div style="float: left">
                                <span>Prestazioni Aggiuntive</span>
                            </div>
                        </div>
                    </template>
                    <div class="sa-tab-scroll">
                        <eventi-prestazioni-component :idRisorsa="idRisorsa" :contesto="contesto" :jsonData="jsonData" :isEdit="true"></eventi-prestazioni-component>
                    </div>
                </b-tab>
            </b-tabs>
            <!-- <allegato-model-component @updateFiles="onUpdateFile()" :jsonData="jsonData"></allegato-model-component> -->
        </template>
    </sa-page-layout>
</template>

<script>
import axios from "axios";
import EventiMixin from "../../../eventi/utility/mixins/EventiMixins";
import UtilityMixin from "../../../../utility/UtilityMixin";
import SaPageLayout from "../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../../components/PazienteAnagrafica.vue";
import PazienteAllegatiListComponent from "../../../../paziente/allegati/components/PazienteAllegatiListComponent.vue";
// import AllegatoModelComponent from "../../../../utility/components/AllegatoModelComponent.vue";
import EventiImmaginiEditComponent from "./EventiImmaginiEditComponent.vue";
import EventiPrestazioniComponent from "./EventiPrestazioniComponent.vue";
// import axios from "axios";
export default {
    mixins: [UtilityMixin, EventiMixin],
    props: {
        isImmagini: {
            type: Boolean,
            default: function () {
                return false;
            },
        },
        isAllegati: {
            type: Boolean,
            default: function () {
                return false;
            },
        },
        isPrestazioni: {
            type: Boolean,
            default: function () {
                return false;
            },
        },
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
        jsonData: {
            type: Object,
            default: function () {
                return null;
            },
        },
        titoloEvento: {
            type: String,
            default: function () {
                return null;
            },
        },
        pathResource: {
            type: String,
            default: function () {
                return null;
            },
        },
    },
    created() {},
    computed: {
        idRisorsa() {
            return this.jsonData.id;
        },
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient() {
            return EventiMixin.getLinkPatient(this.utente, this.idPaziente);
        },
        tabDisabled() {
            return this.id === "-1";
        },
    },
    components: { SaPageLayout, PazienteAnagrafica, PazienteAllegatiListComponent, EventiImmaginiEditComponent, EventiPrestazioniComponent }, // AllegatoModelComponent,
    mounted() {
        this.linkback = "";
        this.prenotazioniCup = this.appConfig.prenotazioniCup;
    },
    updated() {
        let me = this;
        me.jsonData.idPaziente = this.$route.query.idPaziente;
        me.jsonData.idAmbulatorio = sessionStorage.getItem("idAmbulatorio");
        me.id = this.$route.params.id;
        let path = this.$route.path.replace(/\/edit\/.*/, "");
        me.linkNotBack = path + "{editid}?idPaziente=" + me.idPaziente;

        let sessionLinkBack = sessionStorage.getItem("sessionLinkBack");

        if (sessionLinkBack) {
            me.linkback = sessionLinkBack;
        } else if (me.id !== "-1") {
            me.linkback = path + "/view/" + me.id + "?idPaziente=" + me.idPaziente;
        } else {
            me.linkback = path + "?idPaziente=" + me.idPaziente;
        }
    },
    data() {
        return {
            contesto: "VISITA GASTROENTEROLOGICA",
            showModalLoading: false,
            prenotazioniCup: false,
            id: "-1",
            isFirmato: false,
            pathRestValidation: "/validation",
            pathResourceProvenienza: "/prenotazionicup/provenienza",
            childKey: 0,
            tabIndex: 0,
            linkedit: null,
            linkback: null,
            linkNotBack: null,
            btnAllegatiVisible: false,
        };
    },
    methods: {
        loadProvenienza(identificativo) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceProvenienza + "/" + identificativo;
            axios
                .get(link)
                .then((response) => {
                    // console.log(response.data.data);
                    me.jsonData.idAccettazione = response.data.data.id;
                    me.jsonData.provenienza = response.data.data.provenienza;
                    this.$emit("update", me.jsonData);
                    me.showModalLoading = false;
                })
                .catch((error) => {
                    this.$bvModal
                        .msgBoxOk(link + " \n" + error.message, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then(() => {})
                        .catch((err) => {
                            console.log(err);
                        });
                });
        },
        getTabClass() {
            let me = this;
            if (!me.isImmagini && !me.isAllegati && !me.isPrestazioni) {
                return "sa-tab sa-no-tabs";
            } else {
                return "sa-tab";
            }
        },
        onUpdateFile() {
            let me = this;
            me.childKey++;
        },
        onAfterSave(data) {
            let me = this;
            me.jsonData.id = data.id;
            this.$emit("update", data);
        },
        onTabInput(index, e) {
            console.log(index);
            console.log(e);
        },
        onTabClick(e) {
            let me = this;
            if (e.originalTarget.firstChild.nodeValue === "Allegati") {
                me.btnAllegatiVisible = !me.tabDisabled;
            } else {
                me.btnAllegatiVisible = false;
            }
        },
        getAnagraficaPaziente(data) {
            if (this.$route.params.id === "-1") {
                if (this.prenotazioniCup) {
                    this.loadProvenienza(data.identificativo);
                }
            }
        },
    },
};
</script>

<style></style>
