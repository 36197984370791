<template>
  <sa-page-layout
    ref="PgaLayout"
    :showModalLoading="showModalLoading"
    :pathResource="pathResource"
    :btnBackVisible="true"
    :linkback="linkback"
    :data="prenotazione"
  >
    <template slot="toolbar">
      <template v-if="isModicaDataOraPrenotazione">
        <b-button
          variant="primary btn-toolbar sa-margin-right float-sm-right"
          size="sm"
          @click="onModificaDataPrenotazione()"
          ><b-icon icon="pencil"></b-icon>Modifica Prenotazione</b-button
        >
      </template>
      <template v-else>
        <b-button
          variant="danger btn-toolbar sa-margin-right float-sm-right"
          size="sm"
          @click="onUndo"
          ><b-icon icon="x"></b-icon>Annulla</b-button
        >
        <b-button
          class="float-sm-right"
          variant="success btn-toolbar sa-margin-right"
          size="sm"
          @click="onInviaRichiesta"
        >
          <b-icon icon="envelope"></b-icon>Invia Richiesta</b-button
        >
      </template>
    </template>
    <template slot="toolbar-title">Prenotazione </template>
    <template slot="table-body">
      <div class="sa-div-scroll sa-scroll">
        <b-button v-b-toggle.collapse-1 variant="primary"
          >Anagrafiche:
          {{ this.prenotazione.dettaglioAnagrafica.length }}</b-button
        >
        <b-collapse id="collapse-1" class="mt-2">
          <div v-for="item in prenotazione.dettaglioAnagrafica" :key="item.id">
            <b-row>
              <b-col xs="6" sm="6" md="2" lg="2" xl="2">
                <label class="sa-form-label" for="prenotazione-cognome"
                  >Cognome</label
                >
                <br />
                <span>{{ item.cognome }}</span>
              </b-col>
              <b-col xs="6" sm="6" md="2" lg="2" xl="2">
                <label class="sa-form-label" for="prenotazione-nome"
                  >Nome</label
                >
                <br />
                <span>{{ item.nome }}</span>
              </b-col>
              <b-col xs="12" sm="6" md="2" lg="2" xl="2">
                <label class="sa-form-label" for="prenotazione-data-nascita"
                  >Data di Nascita</label
                >
                <br />
                <span>{{ formatDate(item.dataNascita) }}</span>
              </b-col>
              <b-col xs="6" sm="6" md="2" lg="2" xl="2">
                <label class="sa-form-label" for="prenotazione-sesso"
                  >Sesso</label
                >
                <br />
                <span>{{ item.sesso }}</span>
              </b-col>
              <b-col xs="6" sm="6" md="2" lg="2" xl="2">
                <label class="sa-form-label" for="prenotazione-celullare">
                  Cellulare</label
                >
                <br />
                <span>{{ item.cellulare }}</span>
              </b-col>
              <b-col xs="6" sm="6" md="2" lg="2" xl="2">
                <label class="sa-form-label" for="prenotazione-email"
                  >Email</label
                >
                <br />
                <span>{{ item.email }}</span>
              </b-col>
            </b-row>
            <hr />
          </div>
        </b-collapse>
        <br />
        <br />
        <b-row>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-form-label" for="prenotazione-punto-prelievo"
              >Punto Prelievo</label
            >
            <br />
            <span id="prenotazione-punto-prelievo" class="sa-label-data">{{
              prenotazione.puntoPrelievo
            }}</span>
          </b-col>

          <template v-if="isModicaDataOraPrenotazione">
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-form-label" for="prenotazione-data-prenotazione"
                >Data Prenotazione</label
              >
              <br />
              <span id="prenotazione-data-prenotazione" class="sa-label-data">
                {{ formatDateTime(prenotazione.dataOra) }}</span
              >
            </b-col>
          </template>
          <template v-else>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-form-label" for="prenotazione-data-prenotazione"
                >Data Prenotazione</label
              >
              <br />
              <date-picker
                id="prenotazione-data-prenotazione"
                placeholder="DD-MM-YYYY HH:mm"
                style="display: block; width: 100%"
                format="DD-MM-YYYY HH:mm"
                value-type="timestamp"
                v-model="prenotazione.dataOra"
                type="datetime"
              ></date-picker>
            </b-col>
          </template>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-form-label" for="prenotazione-nazione"
              >Nazionalita'</label
            >
            <br />
            <span id="prenotazione-nazione" class="sa-label-data">{{
              prenotazione.nazione
            }}</span>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-form-label" for="prenotazione-regione"
              >Regione</label
            >
            <br />
            <span id="prenotazione-regione" class="sa-label-data">{{
              prenotazione.regioneLabel
            }}</span>
          </b-col>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-form-label" for="prenotazione-codice-pacchetto"
              >Codice Pacchetto</label
            >
            <br />
            <span id="prenotazione-codice-pacchetto" class="sa-label-data">{{
              prenotazione.codicePacchetto
            }}</span>
          </b-col>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-form-label" for="prenotazione-descrizione"
              >Descrizione</label
            >
            <br />
            <span id="prenotazione-descrizione" class="sa-label-data">{{
              prenotazione.descrizionePacchetto
            }}</span>
          </b-col>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-form-label" for="prenotazione-prezzo"
              >Prezzo</label
            >
            <br />
            <span id="prenotazione-prezzo" class="sa-label-data">{{
              prenotazione.prezzo + "€"
            }}</span>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <!-- <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-form-label" for="prenotazione-tipo-identificativo"
              >Tipo Identificativo</label
            >
            <hr />
            <span id="prenotazione-tipoIdentificativo" class="sa-label-data">{{
              prenotazione.tipoIdentificativo
            }}</span>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-form-label" for="prenotazione-identificativo"
              >Identificativo</label
            >
          <hr />
            <span id="prenotazione-identificativo" class="sa-label-data">{{
              prenotazione.identificativo
            }}</span>
          </b-col> -->
        </b-row>
        <b-row>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-form-label" for="prenotazione-indirizzo"
              >Indirizzo</label
            >
            <br />
            <span id="prenotazione-indirizzo" class="sa-label-data">{{
              prenotazione.indirizzo
            }}</span>
          </b-col>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-form-label" for="prenotazione-civico"
              >Civico</label
            >
            <br />
            <span id="prenotazione-civico" class="sa-label-data">{{
              prenotazione.civico
            }}</span>
          </b-col>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-form-label" for="prenotazione-latitudine"
              >Latitudine</label
            >
            <br />
            <span
              v-if="prenotazione.latitudine"
              id="prenotazione-latitudine"
              class="sa-label-data"
              >{{ prenotazione.latitudine }}</span
            >
            <span v-else id="prenotazione-latitudine" class="sa-label-data"
              >-</span
            >
          </b-col>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-form-label" for="prenotazione-longitudine"
              >Longitudine</label
            >
            <br />
            <span
              v-if="prenotazione.longitudine"
              id="prenotazione-longitudine"
              class="sa-label-data"
              >{{ prenotazione.longitudine }}</span
            >
            <span v-else id="prenotazione-latitudine" class="sa-label-data"
              >-</span
            >
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <label for="prenotazione-provincia">Provincia</label>
            <br />
            <span id="prenotazione-provincia" class="sa-label-data">{{
              prenotazione.provincia
            }}</span>
          </b-col>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-form-label" for="prenotazione-comune"
              >Comune</label
            >
            <br />
            <span id="prenotazione-comune" class="sa-label-data">{{
              prenotazione.comune
            }}</span>
          </b-col>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3" class="sa-element-data">
            <label class="sa-form-label" for="prenotazione-frazione"
              >Frazione</label
            >
            <br />
            <span id="prenotazione-frazione" class="sa-label-data">{{
              prenotazione.frazione
            }}</span>
          </b-col>

          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-form-label" for="prenotazione-cap">C.A.P.</label>
            <br />
            <span id="prenotazione-cap" class="sa-label-data">{{
              prenotazione.cap
            }}</span>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col cols="12"
            ><label class="sa-form-label">Dettaglio Pacchetto</label>
          </b-col>
        </b-row>
        <div class="b-table-sticky-header sa-scroll">
          <b-table
            sticky-header
            ref="table"
            stacked="lg"
            striped
            hover
            :items="prenotazione.dettagli"
            :fields="fieldsDettagli"
            :current-page="currentPage"
            :per-page="perPage"
            sort-icon-left
            head-variant="light"
            class="sa-b-table"
          >
            <template v-slot:cell(codiceAttivita)="{ item }">
              <b class="text-success"> {{ item.codiceAttivita }}</b>
              <br />
            </template>
            <template v-slot:cell(descrizioneAttivita)="{ item }">
              <b class="text-dark"> {{ item.descrizioneAttivita }}</b>
              <small v-if="item.note">
                <span class="sa-label-data"> Note : {{ item.note }}</span>
              </small>
            </template>
          </b-table>
        </div>
      </div>
    </template>
  </sa-page-layout>
</template>

<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import SaPageLayout from "../../../template/layout/components/SaPageLayout.vue";
import UtilityMixins from "../../utility/UtilityMixin";
export default {
  props: {
    utente: {
      type: Object,
      default: function () {
        return { idUtente: null };
      },
    },
  },
  components: { SaPageLayout, DatePicker },
  data() {
    return {
      pathResource: "/prenotazione",
      linkback: "/lemieprenotazioni",
      linkedit: "/lemieprenotazioni",
      fieldsDettagli: [
        {
          label: "Codice Attivita",
          key: "codiceAttivita",
          tdClass: "text-left",
          thStyle: "width: 15rem",
        },
        {
          label: "Descrizione Attivita",
          key: "descrizioneAttivita",
          tdClass: "text-left",
        },
      ],

      prenotazione: {
        dettaglioAnagrafica: [],
        dettagli: [],
      },
      perPage: 100,
      currentPage: 1,
      showModalLoading: false,
      isModicaDataOraPrenotazione: true,
    };
  },
  mixins: [UtilityMixins],
  mounted() {
    let me = this;
    me.id = me.$route.params.id;
    me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/prenotazione/" + me.id;
      me.showModalLoading = true;
      axios
        .get(link)
        .then((response) => {
          me.showModalLoading = true;
          if (response.data.data) {
            me.prenotazione = response.data.data;
            console.log(me.prenotazione);
            me.showModalLoading = false;
          }
        })
        .catch((error) => {
          me.showModalLoading = false;
          if (error.response.status == 404) {
            this.$bvModal.msgBoxOk("Risorsa non trovata", {
              title: "ATTENZIONE",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "sa-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            });
          } else {
            this.$bvModal
              .msgBoxOk(error.response.data.messaggio, {
                title: "ATTENZIONE",
                size: "sm",
                okVariant: "outline-danger",
                headerClass: "sa-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
              })
              .then((value) => {
                console.log(value);
              })
              .catch((err) => {
                console.log(err);
              });
          }
        });
    },
    onInviaRichiesta() {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResource;
      link = link + "/" + me.prenotazione.id;
      axios
        .put(link, JSON.stringify(me.prenotazione))
        .then(() => {
          me.showModalLoading = false;
          this.$bvToast.toast("Invio Richiesta Avvenuta con Successo", {
            title: "Laboratori Italiani Riuniti s.p.a.",
            variant: "success",
            appendToast: false,
            autoHideDelay: 5000,
            noCloseButton: true,
          });
        })
        .catch((error) => {
          me.showModalLoading = false;
          this.$bvModal
            .msgBoxOk(error.response.data.messaggio, {
              title: "ATTENZIONE",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "sa-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then((value) => {
              console.log(value);
            })
            .catch((err) => {
              console.log(err);
            });
        });
    },
    onUndo() {
      let me = this;
      me.isModicaDataOraPrenotazione = true;
      me.loadData();
    },
    onModificaDataPrenotazione() {
      this.isModicaDataOraPrenotazione = false;
    },
  },
};
</script>

<style></style>
