<template>
  <div class="sa-tab-scroll">
    <b-card class="sa-card" header="Anagrafica" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label>Numero Tessera Sanitaria</label>
          <b-form-input id="inline-form-custom-select-pref" v-model="jsonData.numeroTesseraSanitaria" class="mb-2 mr-sm-2 mb-sm-0"></b-form-input> </b-col
        ><b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label>Numero Identificazione Personale</label>
          <b-form-input id="inline-form-custom-select-pref" v-model="jsonData.numeroIdentificazionePersonale" class="mb-2 mr-sm-2 mb-sm-0"></b-form-input> </b-col
        ><b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label>Numero Identificazione Tessera</label>
          <b-form-input id="inline-form-custom-select-pref" v-model="jsonData.numeroIdentificazioneTessera" class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label>Data Scadenza Tessera</label>
          <date-picker format="DD/MM/YYYY" value-type="timestamp" v-model="jsonData.dataScadenzaTessera" type="date"></date-picker>
        </b-col> </b-row
    ></b-card>
    <b-card class="sa-card" header="Ricetta" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label>Struttura:</label>
          <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.codiceStruttura" class="mb-2 mr-sm-2 mb-sm-0" :value="''" value-field="codiceStruttura" text-field="denominazione" :options="strutturaOptions" disabled></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label>Codice ASL</label>
          <b-form-input id="inline-form-custom-select-pref" v-model="jsonData.codiceAsl" class="mb-2 mr-sm-2 mb-sm-0" readonly></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label>Data Compilazione</label>
          <b-input-group><date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataCompilazione" disabled></date-picker> </b-input-group>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label>Numero Ricetta</label>
          <b-form-input id="inline-form-custom-select-pref" v-model="jsonData.numeroRicetta" class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label>Ricetta Interna</label>
          <b-form-input id="inline-form-custom-select-pref" v-model="jsonData.ricettaInterna" class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label>Classe Prioritaria</label>
          <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.classePrioritaria" class="mb-2 mr-sm-2 mb-sm-0" :value="''" value-field="value" text-field="text" :options="classePrioritariaOptions"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label>Codice Diagnosi</label>
          <b-form-input id="inline-form-custom-select-pref" v-model="jsonData.codiceDiagnosi" class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label>Descrizione Diagnosi</label>
          <b-form-input id="inline-form-custom-select-pref" v-model="jsonData.descrizioneDiagnosi" class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label>Tipo Prescrizione</label>
          <b-form-input id="inline-form-custom-select-pref" v-model="jsonData.tipoPrescrizione" class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label>Indicazioni Prescrizione</label>
          <b-form-input id="inline-form-custom-select-pref" v-model="jsonData.indicazioniPrescrizione" class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label>Disposizioni Regionali</label>
          <b-form-input id="inline-form-custom-select-pref" v-model="jsonData.disposizioniRegionali" class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label>Indirizzo</label>
          <b-form-input id="inline-form-custom-select-pref" v-model="jsonData.indirizzo" class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label>Reddito</label>
          <b-form-input id="inline-form-custom-select-pref" v-model="jsonData.reddito" class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label>Non Esente</label>
          <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.nonEsente" class="mb-2 mr-sm-2 mb-sm-0" :value="''" value-field="value" text-field="text" :options="nonEsenteOptions"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label>Codice Esenzione</label>
          <b-form-input id="inline-form-custom-select-pref" v-model="jsonData.codiceEsenzione" class="mb-2 mr-sm-2 mb-sm-0" :readonly="isReadonlyCodiceEsenzione"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label>Istituzione Competente</label>
          <b-form-input id="inline-form-custom-select-pref" v-model="jsonData.istituzioneCompetente" class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label>Società di Navigazione</label>
          <b-form-input id="inline-form-custom-select-pref" v-model="jsonData.societaNavigazione" class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label>Codice Specializzazione</label>
          <b-form-input id="inline-form-custom-select-pref" v-model="jsonData.codiceSpecializzazione" class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label>Tipo Visita</label>
          <b-form-input id="inline-form-custom-select-pref" v-model="jsonData.tipoVisita" class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>
        </b-col>
        <!-- <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label>Codice ASL</label>
          <b-form-input id="inline-form-custom-select-pref" v-model="jsonData.codiceAsl" class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>
        </b-col> -->
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Dettagli Medico" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label>Codice Fiscale Medico Titolare</label>
          <b-form-input id="inline-form-custom-select-pref" v-model="jsonData.codiceFiscaleMedicoTitolare" class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label>Codice Fiscale Medico Sostituto</label>
          <b-form-input id="inline-form-custom-select-pref" v-model="jsonData.codiceFiscaleMedicoSostituto" class="mb-2 mr-sm-2 mb-sm-0"></b-form-input> </b-col></b-row
    ></b-card>
    <b-card class="sa-card" header="Assistito" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label>Assistito ASL</label>
          <b-form-input id="inline-form-custom-select-pref" v-model="jsonData.aslAssistito" class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label>Codice Assistito</label>
          <b-form-input id="inline-form-custom-select-pref" v-model="jsonData.codiceAssistito" class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label>Nominativo Assistito</label>
          <b-form-input id="inline-form-custom-select-pref" v-model="jsonData.nominativoAssistito" class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label>Provincia di Residenza Assistito</label>
          <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.provinciaResidenzaAssistito" class="mb-2 mr-sm-2 mb-sm-0" :value="null" value-field="sigla" text-field="provincia" :options="provinciaResidenzaAssistitoOptions"></b-form-select> </b-col
      ></b-row>
    </b-card>
    <b-card class="sa-card" header="Altri Dettagli" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label>Stato Estero</label>
          <b-form-input id="inline-form-custom-select-pref" v-model="jsonData.statoEstero" class="mb-2 mr-sm-2 mb-sm-0"></b-form-input> </b-col
        ><b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label>Data di nascita Estero</label>
          <date-picker format="DD/MM/YYYY" value-type="timestamp" v-model="jsonData.dataNascitaEstero" type="date"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">Altro</label>
          <b-form-textarea v-model="jsonData.altro" id="textarea-auto-height" placeholder="" rows="7"></b-form-textarea>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <b-form-checkbox v-model="isOscuramentoDati" name="check-button" switch>Oscuramento dati</b-form-checkbox>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
export default {
  props: {
    id: { type: String },
    idAccettazione: { type: String },
  },
  components: { DatePicker },
  data() {
    return {
      showModalLoading: false,
      pathResource: "/rsaricette",
      pathResourceAccettazione: "/rsaaccettazioni",
      pathResourceProvince: "/province",
      linkback: "/rsaaccettazione/edit",
      //id: null,
      //linkNotBack: null,
      isOscuramentoDati: false,
      jsonData: {
        altro: "",
        aslAssistito: "",
        classePrioritaria: "",
        codiceAsl: "",
        codiceAssistito: "",
        codiceDiagnosi: "",
        codiceEsenzione: "",
        codiceFiscaleMedicoSostituto: "",
        codiceFiscaleMedicoTitolare: "",
        codiceRegione: "",
        codiceSpecializzazione: "",
        codiceStruttura: "",
        dataCompilazione: new Date().getTime(),
        dataNascitaEstero: null,
        dataScadenzaTessera: null,
        descrizioneDiagnosi: "",
        disposizioniRegionali: "",
        idAccettazione: "",
        idPaziente: "",
        indicazioniPrescrizione: "",
        indirizzo: "",
        istituzioneCompetente: "",
        nominativoAssistito: "",
        nonEsente: "",
        numeroIdentificazionePersonale: "",
        numeroIdentificazioneTessera: "",
        numeroRicetta: "",
        numeroTesseraSanitaria: "",
        oscuramentoDati: "NO",
        provinciaResidenzaAssistito: null,
        reddito: "",
        ricettaInterna: "",
        societaNavigazione: "",
        statoAttuale: "",
        statoAttualeDataOra: new Date().getTime(),
        statoEstero: "",
        tipoPrescrizione: "",
        tipoVisita: "",
      },
      componentKey: 0,
      jsonDataAccettazione: {},
      strutturaOptions: [],
      classePrioritariaOptions: [
        { text: "-Seleziona Priorità-", value: "" },
        { text: "Nel più breve tempo possibile,comunque, se differibile, entro 72 ore", value: "U" },
        { text: "Entro 10 giorni", value: "B" },
        { text: "Entro 30 (visite), entro 60 giorni (visite strumentali)", value: "D" },
        { text: "Senza priorità", value: "P" },
      ],
      nonEsenteOptions: [
        { text: "-Seleziona-", value: "" },
        { text: "SI", value: "SI" },
        { text: "NO", value: "NO" },
      ],
      provinciaResidenzaAssistitoOptions: [],
    };
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        let me = this;
        me.$emit("update", jsonData);
      },
      deep: true,
    },
    isOscuramentoDati(value) {
      let me = this;
      me.jsonData.oscuramentoDati = value ? "SI" : "NO";
    },
  },
  computed: {
    isReadonlyCodiceEsenzione() {
      let me = this;
      if (me.jsonData.nonEsente === "NO") {
        me.jsonData.codiceEsenzione = "";
      }
      return me.jsonData.nonEsente !== "SI";
    },
  },
  created() {},
  mounted() {
    let me = this;
    me.loadProvinceResidenzaAssistito();
  },
  methods: {
    loadData() {
      let me = this;
      me.$emit("beforeLoadData");
      if (me.id === "-1") {
        me.$emit("afterLoadData");
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
        axios
          .get(link + me.id)
          .then((response) => {
            me.jsonData = response.data.data;
            me.$emit("afterLoadData");
          })
          .catch(() => {});
      }
    },
    loadDataAccettazione() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceAccettazione + "/";
      axios
        .get(link + me.idAccettazione)
        .then((response) => {
          me.jsonDataAccettazione = response.data.data;
          me.strutturaOptions = [];
          me.strutturaOptions.unshift({ denominazione: me.jsonDataAccettazione.accettazioneStruttura.denominazione, codiceStruttura: me.jsonDataAccettazione.accettazioneStruttura.codiceStruttura });
          me.setData(me.jsonDataAccettazione);
        })
        .catch(() => {});
    },
    setData(jsonDataAccettazione) {
      let me = this;
      me.jsonData.codiceStruttura = me.jsonDataAccettazione.accettazioneStruttura.codiceStruttura;
      me.jsonData.codiceAsl = jsonDataAccettazione.accettazioniAsl.aslDomicilioCodice === jsonDataAccettazione.accettazioniAsl.aslResidenzaCodice ? jsonDataAccettazione.accettazioniAsl.aslResidenzaCodice : jsonDataAccettazione.accettazioniAsl.aslDomicilioCodice;
      me.jsonData.statoAttuale = jsonDataAccettazione.statoAttuale;
      me.jsonData.statoAttualeDataOra = jsonDataAccettazione.statoAttualeDataOra;
      me.jsonData.idAccettazione = jsonDataAccettazione.accettazioneAnagrafica.idAccettazione;
      me.jsonData.idPaziente = jsonDataAccettazione.accettazioneAnagrafica.id;
      me.jsonData.codiceRegione = jsonDataAccettazione.accettazioneStruttura.codiceRegione;
    },
    loadProvinceResidenzaAssistito() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceProvince;
      axios
        .get(link)
        .then((response) => {
          me.provinciaResidenzaAssistitoOptions = response.data.data;
          me.provinciaResidenzaAssistitoOptions.unshift({ sigla: null, provincia: "-Seleziona Provincia-" });
        })
        .catch((error) => {
          console.log("Il servizio delle province ha datao errore: " + error.response);
        });
    },
  },
};
</script>
