<template>
	<!-- <div class="sa-page-paziente"> -->
	<div class="sa-page-paziente">
		<b-row style="overflow: auto">
			<b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="sa-page-paziente-col-scheda">
				<div class="sa-page-paziente-patient-info"><rsa-paziente-info-component :jsonData="jsonData"></rsa-paziente-info-component></div>
				<div class="sa-page-paziente-patient-attivita">
					<b-card class="sa-card sa-card-border" header="Ativita" header-tag="header" footer-tag="footer" title=""> </b-card>
				</div>
				<div class="sa-page-paziente-patient-attivita">
					<b-card class="sa-card sa-card-border" header="Terapie" header-tag="header" footer-tag="footer" title=""> </b-card>
				</div>
				<!-- <div class="sa-page-paziente-patient-terapia-attiva">
					<paziente-terapie-attive class="sa-page-paziente-terapia-attiva"></paziente-terapie-attive>
				</div> -->
			</b-col>
		</b-row>
	</div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import female from "@/assets/images/female.jpg";
import male from "@/assets/images/male.jpg";
import UtilityMixin from "../../../../utility/UtilityMixin";
// import PazienteDiarioComponent from "./PazienteDiarioComponent.vue";
import RsaPazienteInfoComponent from "../../components/RsaPazienteInfoComponent.vue";
// import PazienteAttivita from "./PazienteAttivita.vue";
// import PazienteTerapieAttive from "./PazienteTerapieAttive.vue";
export default {
	mixins: [UtilityMixin],
	props: {
		idPaziente: String,
		btnAllegati: { type: Boolean, default: false },
		messageDanger: { type: Boolean, default: false },
		utente: {
			required: true,
			type: Object,
			default: function () {
				return null;
			},
		},
	},
	// components: { PazienteDiarioComponent, },
	// components: { PazienteDiarioComponent, PazienteInfoComponent, PazienteAttivita, PazienteTerapieAttive },
	components: { RsaPazienteInfoComponent },
	data() {
		return {
			titolo: "",
			pathResource: "/accettazioni",
			pagelink: "/paziente",
			linkMeasurement: "/lastmeasure",
			jsonData: { accettazioneAnagrafica: { sesso: null }, accettazioneStruttura: { denominazione: null } },
			terapia: [],
			misure: [],
			wtLogin: null,
			misurePrioritarie: [],
			pazienteTerapie: [],
			diario: [],
			misureRange: [],
		};
	},
	computed: {
		patientAvatar: {
			get: function () {
				let me = this;
				if (me.jsonData.sesso === "M") {
					return male;
				} else {
					return female;
				}
			},
		},
		btnEditAnagrafica: {
			get: function () {
				let me = this;
				return UtilityMixin.methods.verificaPermessi(me.utente, "pazienti", 4);
			},
		},
	},
	watch: {
		utente(value) {
			console.log(value);
		},
	},
	mounted() {
		let me = this;
		if (me.idPaziente) {
			me.loadData();
			//me.loadDiario();
			// me.loadTerapie();
		}
	},
	methods: {
		returnlabel() {
			let label = this.$i18n.t("patient.rangeConfig.lblDetectedOn");
			return label;
		},
		formatDate(value) {
			if (value) {
				return moment(new Date(value)).format("DD-MM-YYYY");
			} else {
				return "---";
			}
		},
		formatDateTime(value) {
			if (value) {
				return moment(new Date(value)).format("DD-MM-YYYY HH:mm");
			} else {
				return "---";
			}
		},
		traslater(key) {
			return this.$i18n.t(key);
		},
		loadData() {
			let me = this;
			let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
			axios.get(link + me.idPaziente)
				.then((response) => {
					me.jsonData = response.data.data;
				})
				.catch((e) => {
					this.errors.push(e);
				});
		},
		onDashboard() {
			let me = this;
			me.$router.replace(me.pagelink + "/dashboard?idPaziente=" + me.idPaziente).catch((err) => {
				err;
			});
		},
		loadDiario() {
			let me = this;
			let link = process.env.VUE_APP_PATH_API + "/pazienteeventi" + "/";
			axios.get(link, { params: { idPaziente: me.idPaziente } })
				.then((response) => {
					me.diario = response.data.data.list;
				})
				.catch((e) => {
					console.log(e);
					//this.errors.push(e);
				});
		},
		loadTerapie() {
			let me = this;
			let link = process.env.VUE_APP_PATH_API + "/pazienteterapie/attiva";
			axios.get(link, { params: { idPaziente: me.idPaziente } })
				.then((response) => {
					me.terapia = response.data.data;
				})
				.catch((e) => {
					console.log("Error: " + e.response.status + " " + link);
				});
		},
		onPazienteTeleconsulto: function () {
			let me = this;
			me.$router.replace("/paziente/teleconsulti/" + me.idPaziente).catch((err) => {
				err;
			});
		},
		onEdit: function () {
			let me = this;
			me.$router.replace(me.pagelink + "/edit/" + me.id).catch((err) => {
				err;
			});
		},
		onAllegati() {
			let me = this;
			me.$router.replace("/paziente/allegati/" + me.idPaziente).catch((err) => {
				err;
			});
		},
	},
};
</script>

<style scoped>
.sa-page-paziente-col-scheda {
	background-color: transparent;
	display: flex;
	max-height: 100%;
	flex-direction: column;
	padding-right: 0px;
	padding-left: 0px;
}

.sa-page-paziente-patient-info {
	background-color: transparent;
}
.sa-page-paziente-patient-attivita {
	background-color: transparent;
}
.sa-page-paziente-patient-terapia-attiva {
	background-color: transparent;
	flex: 1;
	overflow: auto;
}
.sa-page-paziente {
	/* background-color: var(--bg-sidebar); */
	height: 100%;
	padding-left: 8px;
	padding-right: 8px;
}
.sa-page-paziente .row {
	height: 100%;
}
/* .sa-page-paziente .row .sa-page-paziente-col-scheda {
    max-height: 100%;
} */
.sa-page-paziente-diario-clinico {
	height: 100%;
}

.sa-page-paziente-col-diario {
	margin-bottom: 0px;
	padding-bottom: 15px;
	padding-right: 0px;
}
@media (max-width: 450px) {
	.sa-page-paziente-terapia-attiva {
		max-height: 380px;
	}
	.sa-page-paziente-col-diario {
		max-height: 380px;
	}
}
@media screen and (min-width: 450px) and (max-width: 1024px) {
	.sa-page-paziente-terapia-attiva {
		max-height: 33px;
	}
	.sa-page-paziente-col-diario {
		height: calc(100% - 7px);
		/* background-color: green; */
	}
}

@media screen and (min-width: 1024px) {
	/* .sa-page-paziente-terapia-attiva {
        max-height: 33%;
    } */
	.sa-page-paziente-col-diario {
		height: calc(100% - 7px);
		/* background-color: blue; */
	}
}
</style>
