<template>
	<configurazioni-component></configurazioni-component>
</template>

<script>
import ConfigurazioniComponent from "../components/ConfigurazioniComponent.vue";
export default {
	components: { ConfigurazioniComponent },
};
</script>

<style></style>
