<template>
  <sa-page-layout
    :btnBackVisible="true"
    :linkback="linkback"
    :btnNewVisible="btnNewVisible"
    :btnRefreshVisible="true"
    @refresh="onRefresh"
    :pathResource="pathResource"
    :linkedit="linkedit"
    :showModalLoading="showModalLoading"
  >
    <template slot="table-body">
      <ProtocolloRubricaListComponent
        ref="ProtocolloRubricaListComponent"
        @afterLoadData="onAfterLoadData"
      ></ProtocolloRubricaListComponent>
    </template>
  </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import ProtocolloRubricaListComponent from "../components/ProtocolloRubricaListComponent.vue";

export default {
  components: { SaPageLayout, ProtocolloRubricaListComponent },

  data() {
    return {
      btnNewVisible: true,
      pathResource: "",
      linkedit: "/protocollorubrica",
      linkback: "/home",
      showModalLoading: false,
      listKey: 0,
      //filtro: { tipoPersona: "", nome: "", cognome: "", email: "" },
    };
  },
  mounted() {
    let me = this;
    me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;
      me.$refs.ProtocolloRubricaListComponent.loadData();
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
    onAfterLoadData() {
      let me = this;
      me.showModalLoading = false;
    },
  },
};
</script>

<style></style>
