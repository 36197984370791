<template>
    <div class="sa-tab-scroll">
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <label class="sa-label-data">Distretto Sanitario:</label>
                <!-- <b-form-input v-model="jsonData.distrettoSanitario"></b-form-input> -->
                <b-form-select v-model="jsonData.distrettoSanitario" :options="cotDistrettoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
            </b-col>
        </b-row>
        <b-card class="sa-card" header="Anamnesi" header-tag="header" footer-tag="footer" title="" style="margin-top: 8px">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Patologia Prevalente:</label>
                    <b-form-input v-model="jsonData.patologiaPrevalente"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Patologie Concomitanti:</label>
                    <b-form-input v-model="jsonData.patologiaConcomitante"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Richiedente il P.R.I.:</label>
                    <b-form-input v-model="jsonData.richiedentePri"></b-form-input>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Valutazione Funzionale" header-tag="header" footer-tag="footer" title="">
            <template #header>
                <b-row>
                    <b-col cols="12" xs="8" sm="8" md="8" lg="8" xl="8">
                        <span class="sa-label-data">VALUTAZIONE FUNZIONALE</span>
                    </b-col>
                    <b-col class="sa-padding-right text-right" cols="12" xs="4" sm="4" md="4" lg="4" xl="4">
                        <b-button class="float-sm-right" variant="outline-secondary btn-toolbar sa-margin-right" size="sm" @click="onCalcolaPunteggio">
                            <i style="margin-top: 2px; margin-right: 5px;" class="fas fa-calculator"></i>
                            Calcola
                        </b-button>
                    </b-col>
                </b-row>
            </template>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Disturbi Cognitivi:</label>
                    <!-- <b-form-input v-model="jsonData.disturbiCognitivi"></b-form-input> -->
                    <b-form-select v-model="jsonData.disturbiCognitivi" :options="disturbiCognitiviOptions" :value="''" value-field="value" text-field="text" @change="onPopolaValutazioneFunzionale('cognitivo')"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Disturbi Comportamentali:</label>
                    <!-- <b-form-input v-model="jsonData.disturbiComportamentali"></b-form-input> -->
                    <b-form-select v-model="jsonData.disturbiComportamentali" :options="disturbiComportamentaliOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Grado Mobilita':</label>
                    <!-- <b-form-input v-model="jsonData.gradoMobilita"></b-form-input> -->
                    <b-form-select v-model="jsonData.gradoMobilita" :options="gradoMobilitaOptions" :value="''" value-field="value" text-field="text" @change="onPopolaValutazioneFunzionale('mobilita')"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Supporto Sociale:</label>
                    <!-- <b-form-input v-model="jsonData.supportoSociale"></b-form-input> -->
                    <b-form-select v-model="jsonData.supportoSociale" :options="supportoSocialeOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Autonomia:</label>
                    <!-- <b-form-input v-model="jsonData.autonomia"></b-form-input> -->
                    <b-form-select v-model="jsonData.autonomia" :options="autonomiaOptions" :value="''" value-field="value" text-field="text" @change="onPopolaValutazioneFunzionale('funzionale')"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Bisogno Assistenziale:</label>
                    <!-- <b-form-input v-model="jsonData.bisognoAssistenzaile"></b-form-input> -->
                    <b-form-select v-model="jsonData.bisognoAssistenzaile" :options="bisognoAssistenzialeOptions" :value="''" value-field="value" text-field="text" @change="onPopolaValutazioneFunzionale('sanitario')"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Valutazione SVAMA Profili:</label>
                    <b-form-input v-model="jsonData.valutazioneSvamaProfilo" disabled></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Bisogni Espressi:</label>
                    <b-form-textarea v-model="jsonData.bisogniEspressi" no-resize></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Piano Proposto" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Setting Assistenziale:</label>
                    <!-- <b-form-textarea v-model="jsonData.settingAssistenziale" rows="5" no-resize></b-form-textarea> -->
                    <b-form-select v-model="jsonData.settingAssistenziale" :options="settingOptions" :value="''" value-field="value" text-field="text" @change="onChangeSetting"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Struttura Accreditata:</label>
                    <!-- <b-form-input v-model="jsonData.strutturaAccreditata"></b-form-input> -->
                    <b-form-select v-model="jsonData.strutturaAccreditata" :options="rsaDestinazioneOptions" :value="''" value-field="value" text-field="text" @change="onChangeRsaDestinazione" :disabled="disableStrutturaAccreditata"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Obiettivi da Raggiungere:</label>
                    <b-form-input v-model="jsonData.obiettivi"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Note:</label>
                    <b-form-textarea v-model="jsonData.note" no-resize></b-form-textarea>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Regime Assistenziale:</label>
                    <!-- <b-form-input v-model="jsonData.regimeAssistenziale"></b-form-input> -->
                    <b-form-select v-model="jsonData.regimeAssistenziale" :options="regimeAssistenzialeOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Periodo/Durata:</label>
                    <b-form-input v-model="jsonData.regimeAssistenzialePeriodoDurata"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Medico Specialista:</label>
                    <b-form-input v-model="jsonData.medicoSpecialista"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Data Ultima Valutazione:</label>
                    <date-picker v-model="jsonData.dataUltimaValutazione" format="DD-MM-YYYY HH:mm" value-type="timestamp" type="datetime"></date-picker>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Conclusioni" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Piano Intervento Proposto Autorizzato:</label>
                    <!-- <b-form-input v-model="jsonData.pianoInterventoAutorizzato"></b-form-input> -->
                    <b-form-select v-model="jsonData.pianoInterventoAutorizzato" :options="settingOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Direttore del Distretto/Delegato:</label>
                    <!-- DIRETTORE DEL DISTRETTO/ DELEGATO -->
                    <b-form-input v-model="jsonData.direttoreDistretto"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Coordinatore della U.V.M.:</label>
                    <b-form-input v-model="jsonData.cordinatoreUvm"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Medico di Medicina Generale:</label>
                    <b-form-input v-model="jsonData.medicoMedicinaGenerale"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Infermiere del Distretto:</label>
                    <b-form-input v-model="jsonData.infermiereDistretto"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Ass. Sociale del Comune o Distretto:</label>
                    <b-form-input v-model="jsonData.assistenteSociale"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Referente del Paziente:</label>
                    <b-form-input v-model="jsonData.referentePaziente"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Specialista:</label>
                    <b-form-input v-model="jsonData.specialista"></b-form-input>
                </b-col>
            </b-row>
        </b-card>
        <b-modal ref="mdlError" id="mdlError" size="md" style="height: 100%" centered title="Attenzione">
            <li v-for="riga in listaErrori" :key="riga" class="event">
                <span class="mdi mdi-circle pal"></span>
                <span class="sa-timeline-date">{{ riga }}</span>
            </li>
            <template #modal-footer>
                <b-button size="sm" variant="danger" @click="onCloseClick()">OK</b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
export default {
    components: { DatePicker },
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    data() {
        return {
            idPropostaAccesso: null,
            keyPropostaAccesso: 0,
            linkback: "",
            linkPrintData: "",
            childKey: 0,
            viewPai: true,
            disableStrutturaAccreditata: true,
            pathResource: "/cotvalutazioneuvmschedapaziente",
            pathResourcePropostaAccesso: "/cot",
            pathResourceValutazioniFunzionali: "/cotvalutazionefunzionale",
            pathResourceValutazioniFunzionaleProfili: "/cotvalutazionefunzionaleprofili",
            linkDistretto: "/cotdistretti",
            linkStrutture: "/cotstrutture",
            linkSetting: "/cotsetting",
            totale: 0,
            rsaDestinazioneOptions: [{ value: "", text: "-Seleziona Valore-" }],
            listaRsaDestinazione: [],
            settingOptions: [{ value: "", text: "-Seleziona Valore-" }],
            settingList: [],
            cotDistrettoOptions: [{ value: "", text: "-Seleziona Valore-" }],
            regimeAssistenzialeOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "RESIDENZIALE", text: "RESIDENZIALE" },
                { value: "SEMIRESIDENZIALE", text: "SEMIRESIDENZIALE" },
            ],
            valutazioneFunzionaleList: [],
            disturbiCognitiviOptions: [],
            disturbiComportamentaliOptions: [],
            gradoMobilitaOptions: [],
            supportoSocialeOptions: [],
            autonomiaOptions: [],
            bisognoAssistenzialeOptions: [],
            listaErrori: [],
            jsonData: {
                id: "-1",
                idPropostaAccesso: "",
                distrettoSanitario: "",
                anamnesi: "",
                patologiaPrevalente: "",
                patologiaConcomitante: "",
                richiedentePri: "",
                disturbiCognitivi: "",
                disturbiComportamentali: "",
                gradoMobilita: "",
                supportoSociale: "",
                autonomia: "",
                bisognoAssistenzaile: "",
                valutazioneSvamaProfilo: "",
                bisogniEspressi: "",
                settingAssistenziale: "",
                obiettivi: "",
                note: "",
                regimeAssistenziale: "",
                dataUltimaValutazione: null,
                medicoSpecialista: "",
                pianoInterventoAutorizzato: "",
                direttoreDistretto: "",
                cordinatoreUvm: "",
                medicoMedicinaGenerale: "",
                infermiereDistretto: "",
                assistenteSociale: "",
                referentePaziente: "",
                specialista: "",
                strutturaAccreditata: "",
                strutturaAccreditataCodice: "",
                regimeAssistenzialePeriodoDurata: "",
            },
            fieldsListe: [
                {
                    label: "Descrizione",
                    key: "fattore",
                    sortable: true,
                },
                {
                    label: "",
                    key: "check",
                    sortable: true,
                    thStyle: "width: 10rem",
                    tdClass: "text-center sa-column-widh",
                },
                // {
                //   label: "",
                //   key: "action",
                //   thStyle: "width: 10rem",
                // },
            ],
            valutazioneFunzionale: { cognitivo: null, mobilita: null, funzionale: null, sanitario: null },
        };
    },
    mounted() {
        let me = this;
        me.idPropostaAccesso = this.$route.params.id;
        me.loadDistretto();
        // me.loadStrutture();
        me.loadSetting();
        me.loadValutazioneFunzionale();
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.idPropostaAccesso;
            me.disableStrutturaAccreditata = true;
            axios
                .get(link)
                .then((response) => {
                    me.jsonData = response.data.data;
                    me.popolaValutazioneFunzionale("cognitivo");
                    me.popolaValutazioneFunzionale("mobilita");
                    me.popolaValutazioneFunzionale("funzionale");
                    me.popolaValutazioneFunzionale("sanitario");
                    me.changeSetting();
                })
                .catch(() => {
                    me.loadPropostaAccesso();
                });
        },
        loadPropostaAccesso() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourcePropostaAccesso + "/" + me.idPropostaAccesso;
            axios
                .get(link)
                .then((response) => {
                    // me.jsonData.distrettoSanitario = response.data.data.cotDestinazione;
                    me.jsonData.patologiaPrevalente = response.data.data.primaPatologiaDescrizione;
                    me.jsonData.patologiaConcomitante = response.data.data.primaPatologiaConxomitanteDescrizione + ", " + response.data.data.secondaPatologiaConxomitanteDescrizione;
                    me.getValutazioneFunzionale(response.data.data);
                    // me.getSettingAssistenziale(response.data.data);
                })
                .catch(() => { });
        },
        getValutazioneFunzionale(item) {
            let me = this;
            me.jsonData.disturbiCognitivi = "";
            me.jsonData.disturbiComportamentali = "";
            me.jsonData.gradoMobilita = "";
            me.jsonData.supportoSociale = "";
            me.jsonData.autonomia = "";
            me.jsonData.bisognoAssistenzaile = "";
            if (item.situazioneCognitivaLucido) {
                me.jsonData.disturbiCognitivi = "LUCIDO";
            } else if (item.situazioneCognitivaConfuso) {
                me.jsonData.disturbiCognitivi = "CONFISO";
            } else if (item.situazioneCognitivaMoltoConfuso) {
                me.jsonData.disturbiCognitivi = "MOLTO CONFUSO, STUPOROSO";
            }
            if (item.problemiComportamentaliAsentiLievi) {
                me.jsonData.disturbiComportamentali = "ASSENTI/LIEVI";
            } else if (item.problemiComportamentaliModerati) {
                me.jsonData.disturbiComportamentali = "MODERATI";
            } else if (item.problemiComportamentaliGravi) {
                me.jsonData.disturbiComportamentali = "GRAVI";
            }

            if (item.situazioneFunzionaleAutonomoQuasi) {
                me.jsonData.gradoMobilita = "AUTONOMO O QUASI";
            } else if (item.situazioneFunzionaleDipendente) {
                me.jsonData.gradoMobilita = "DIPENDENTE";
            } else if (item.situazioneFunzionaleTotalmenteDipendente) {
                me.jsonData.gradoMobilita = "TOTALMENTE DIPENDENTE";
            }

            if (item.gradoAutonomiaSpostaSolo) {
                me.jsonData.autonomia = "SI SPOSTA DA SOLO";
            } else if (item.gradoAutonomiaSpostaAssistito) {
                me.jsonData.autonomia = "SI SPOSTA ASSISTITO";
            } else if (item.gradoAutonomiaTotalmenteDipendente) {
                me.jsonData.autonomia = "NON SI SPOSTA";
            }

            if (item.supportoReteSocialeBenAssistito) {
                me.jsonData.supportoSociale = "BEN ASSISTITO";
            } else if (item.supportoReteSocialePazialmenteAssistito) {
                me.jsonData.supportoSociale = "PARZIALMENTE ASSISTITO";
            } else if (item.supportoSocialeNonAssistito) {
                me.jsonData.supportoSociale = "NON ASSISTITO";
            }

            if (item.necessitaAssitenzaSanitariaBassa) {
                me.jsonData.bisognoAssistenzaile = "BASSO";
            } else if (item.necessitaAssistenzaSanitariaIntermedia) {
                me.jsonData.bisognoAssistenzaile = "INTERMEDIO";
            } else if (item.necessitaAssistenzaSanitariaElevata) {
                me.jsonData.bisognoAssistenzaile = "ALTO";
            }
            me.popolaValutazioneFunzionale("cognitivo");
            me.popolaValutazioneFunzionale("mobilita");
            me.popolaValutazioneFunzionale("funzionale");
            me.popolaValutazioneFunzionale("sanitario");
            if (me.jsonData.disturbiCognitivi !== "" &&
                me.jsonData.disturbiComportamentali !== "" &&
                me.jsonData.gradoMobilita !== "" &&
                me.jsonData.supportoSociale !== "" &&
                me.jsonData.autonomia !== "") {
                me.calcolaPunteggio();
            }
        },
        getSettingAssistenziale(item) {
            let me = this;
            me.jsonData.settingAssistenziale = "";
            if (item.assistenzaDomiciliareEstemporanea) {
                me.jsonData.settingAssistenziale = "Assistenza Domiciliare Estemporanea";
            } else if (item.assistenzaDomiciliareIntegrata) {
                me.jsonData.settingAssistenziale = "Assistenza Domiciliare Integrata";
            } else if (item.assistenzaDomiciliareProgrammataMmg) {
                me.jsonData.settingAssistenziale = "Assistenza Domiciliare programmata del MMG";
            } else if (item.assistenzaDomiciliareSociale) {
                me.jsonData.settingAssistenziale = "Assistenza Domiciliare Sociale";
            } else if (item.assistenzaDomiciliareDisabili) {
                me.jsonData.settingAssistenziale = "Assistenza Domiciliare Disabili";
            } else if (item.ricoveroHospice) {
                me.jsonData.settingAssistenziale = "Ricovero in Hospice (R1)";
            } else if (item.accoglienzaCentroDiurnoSocioEducativoDisabili) {
                me.jsonData.settingAssistenziale = "Accoglienza in Centro diurno socio-educativo per disabile";
            } else if (item.ricoveroStrutturaResidenzialeExtraospedalieraElevatoImpegn) {
                me.jsonData.settingAssistenziale = "Ricovero in Struttura Residenziale Extraospedaliera ad elevato impegno sanitario – R1";
            } else if (item.ricoveroStrutturaResidenzialePersoneDisabilita) {
                me.jsonData.settingAssistenziale = "Ricovero in Struttura Residenziale per persone con disabilità (Rd1-Rd2-Rd3)";
            } else if (item.ricoveroStrutturaResidenzialeSocioSanitariaPazientiNonAu) {
                me.jsonData.settingAssistenziale = "Ricovero in struttura residenziale socio sanitaria per pazienti non autosufficienti (R2-R3)";
            } else if (item.assistenzaStruttureSemiresidenzialiPersoneNonAutosufficien) {
                me.jsonData.settingAssistenziale = "Assistenza presso Strutture Semiresidenziali per persone non autosufficienti (SR)";
            } else if (item.altriInterventiNaturaSociosanitaria) {
                me.jsonData.settingAssistenziale = "Altri interventi di natura socio-assistenziale";
            } else if (item.altro) {
                me.jsonData.settingAssistenziale = "Altro";
            }

            // console.log(me.jsonData.settingAssistenziale);
        },
        loadStrutture(value) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkStrutture + "?codicePrestazione=" + value;
            me.rsaDestinazioneOptions = [];
            axios.get(link).then((response) => {
                me.listaRsaDestinazione = response.data.data.list;
                response.data.data.list.forEach((element) => {
                    me.rsaDestinazioneOptions.push({ value: element.denominazione, text: element.denominazione + " (" + element.liberi + ")" });
                });
                me.rsaDestinazioneOptions.unshift({ value: "", text: "-Seleziona Valore-" });
            });
        },
        loadSetting() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkSetting;
            me.settingOptions = [];
            me.settingList = [];
            axios.get(link).then((response) => {
                me.settingList = response.data.data.list;
                response.data.data.list.forEach((element) => {
                    me.settingOptions.push({ value: element.descrizione, text: element.descrizione });
                });
                me.settingOptions.unshift({ value: "", text: "-Seleziona Valore-" });
            });
        },
        loadValutazioneFunzionale() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceValutazioniFunzionali;
            me.valutazioneFunzionaleList = [];
            me.disturbiCognitiviOptions = [];
            me.disturbiComportamentaliOptions = [];
            me.gradoMobilitaOptions = [];
            me.supportoSocialeOptions = [];
            me.autonomiaOptions = [];
            me.bisognoAssistenzialeOptions = [];
            axios.get(link).then((response) => {
                me.valutazioneFunzionaleList = response.data.data.list;
                response.data.data.list.forEach((element) => {
                    switch (element.tipoValutazione) {
                        case "DISTURBI COGNITIVI":
                            me.disturbiCognitiviOptions.push({ value: element.risposta, text: element.risposta, punteggio: element.punteggio });
                            break;
                        case "DISTURBI COMPORTAMENTALI":
                            me.disturbiComportamentaliOptions.push({ value: element.risposta, text: element.risposta, punteggio: element.punteggio });
                            break;
                        case "GRADO MOBILITA'":
                            me.gradoMobilitaOptions.push({ value: element.risposta, text: element.risposta, punteggio: element.punteggio });
                            break;
                        case "SUPPORTO SOCIALE":
                            me.supportoSocialeOptions.push({ value: element.risposta, text: element.risposta, punteggio: element.punteggio });
                            break;
                        case "AUTONOMIA":
                            me.autonomiaOptions.push({ value: element.risposta, text: element.risposta, punteggio: element.punteggio });
                            break;
                        case "BISOGNO ASSISTENZIALE":
                            me.bisognoAssistenzialeOptions.push({ value: element.risposta, text: element.risposta, punteggio: element.punteggio });
                            break;
                    }
                });
                me.disturbiCognitiviOptions.unshift({ value: "", text: "-Seleziona Valore-" });
                me.disturbiComportamentaliOptions.unshift({ value: "", text: "-Seleziona Valore-" });
                me.gradoMobilitaOptions.unshift({ value: "", text: "-Seleziona Valore-" });
                me.supportoSocialeOptions.unshift({ value: "", text: "-Seleziona Valore-" });
                me.autonomiaOptions.unshift({ value: "", text: "-Seleziona Valore-" });
                me.bisognoAssistenzialeOptions.unshift({ value: "", text: "-Seleziona Valore-" });
            });
        },
        onChangeRsaDestinazione() {
            let me = this;
            me.listaRsaDestinazione.forEach((element) => {
                if (element.denominazione === me.jsonData.strutturaAccreditata) {
                    me.jsonData.strutturaAccreditataCodice = element.codiceStruttura;
                }
            });
        },
        loadDistretto() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkDistretto;
            me.cotDistrettoOptions = [];
            axios.get(link).then((response) => {
                response.data.data.list.forEach((element) => {
                    me.cotDistrettoOptions.push({ value: element.distretto, text: element.distretto });
                });
                me.cotDistrettoOptions.unshift({ value: "", text: "-Seleziona Valore-" });
            });
        },
        onChangeSetting() {
            let me = this;
            me.changeSetting();
        },
        changeSetting() {
            let me = this;
            me.jsonData.pianoInterventoAutorizzato = me.jsonData.settingAssistenziale;
            me.settingList.forEach((element) => {
                if (element.descrizione === me.jsonData.settingAssistenziale) {
                    me.jsonData.codicePrestazione = (element.codicePrestazione);
                    me.jsonData.idPrestazione = (element.id);
                    me.loadStrutture(element.codicePrestazione);
                    me.disableStrutturaAccreditata = false;
                }
            });
        },
        setListe() { },
        onPopolaValutazioneFunzionale(categoria) {
            let me = this;
            // console.log(categoria);
            me.popolaValutazioneFunzionale(categoria);
        },
        popolaValutazioneFunzionale(categoria) {
            let me = this;
            switch (categoria) {
                case "cognitivo":
                    me.valutazioneFunzionale.cognitivo = me.getPunteggio(me.jsonData.disturbiCognitivi, me.disturbiCognitiviOptions);
                    break;
                case "mobilita":
                    me.valutazioneFunzionale.mobilita = me.getPunteggio(me.jsonData.gradoMobilita, me.gradoMobilitaOptions);
                    break;
                case "funzionale":
                    me.valutazioneFunzionale.funzionale = me.getPunteggio(me.jsonData.autonomia, me.autonomiaOptions);
                    break;
                case "sanitario":
                    me.valutazioneFunzionale.sanitario = me.getPunteggio(me.jsonData.bisognoAssistenzaile, me.bisognoAssistenzialeOptions);
                    break;
            }
        },
        getPunteggio(valore, array) {
            let risultato = null;
            array.forEach((element) => {
                if (valore === element.value) {
                    // console.log(element);
                    risultato = element.punteggio;
                }
            });
            return risultato;
        },
        onCalcolaPunteggio() {
            let me = this;
            me.calcolaPunteggio();
        },
        calcolaPunteggio() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceValutazioniFunzionaleProfili;
            axios.get(link, { params: me.valutazioneFunzionale }).then((response) => {
                let valutazione = response.data.data.list[0];
                me.jsonData.valutazioneSvamaProfilo = valutazione.numero;
            }).catch((error) => {
                let message = "";
                message = error.response.data.messaggio;
                me.listaErrori = message.split("#");
                // console.log(me.listaErrori.length);
                if (me.listaErrori.length > 1) {
                    me.listaErrori.splice(me.listaErrori.length - 1, 1);
                }
                me.$refs.mdlError.show();

            });
        },
        onCloseClick() {
            let me = this;
            me.$refs.mdlError.hide();
        },
    },
};
</script>

<style></style>
