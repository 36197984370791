<template>
    <div style="height: 100%">
        <div class="sa-flexbox" style="padding-bottom: 20px">
            <div class="sa-flexbox-header">
                <!-- <b-form @submit.prevent="onSubmit">
          <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          </b-row>
        </b-form> -->
            </div>
            <div class="sa-flexbox-body">
                <div class="b-table-sticky-header">
                    <span class="sa-flexbox-body-title">Equipe</span>
                    <b-button size="sm" variant="outline-success outline-primary-noborder sa-margin-right float-sm-right" :hidden="btnNuovaEquipeHidden" @click="nuovaEquipe" class="mb-2">
                        <font-awesome-icon icon="plus"> </font-awesome-icon>
                        Aggiungi
                    </b-button>
                    <b-table sticky-header ref="table" stacked="lg" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
                        <template v-slot:cell(membri)="{ item }">
                            <!-- <span v-for="equipe in item.listaEquipeDettaglio" :key="equipe.id">{{ equipe.nome + " " + equipe.cognome + " (" + equipe.tipo + ") " }}</span> -->
                            <span class="sa-label-data" v-for="equipe in item.listaEquipeDettaglio" :key="equipe.id"> {{ equipe.nome + " " + equipe.cognome + " (" + equipe.tipo + ") " }}</span>
                            <span class="sa-label-data" v-if="item.listaEquipeDettaglio.length === 0">NESSUNA EQUIPE IN QUESTO PERIODO</span>
                        </template>

                        <template v-slot:cell(dataInizio)="{ item }">
                            <span>{{ formatDate(item.dataInizio) }}</span>
                        </template>
                        <template v-slot:cell(dataFine)="{ item }">
                            <span>{{ formatDate(item.dataFine) }}</span>
                        </template>
                        <template v-slot:cell(action)="row">
                            <b-container class="bv-example-row">
                                <b-button size="sm" v-b-tooltip.hover title="Info" variant="outline-primary" @click="onClickInfo(row.item)">
                                    <font-awesome-icon icon="info-circle" />
                                </b-button>
                            </b-container>
                        </template>
                    </b-table>
                </div>
            </div>
            <div class="sa-flexbox-footer" style="background-color: ciano">
                <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col lg="3">
                        <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rows }}</p>
                    </b-col>
                    <b-col lg="9">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                    </b-col>
                </b-row>
            </div>
        </div>
        <rsa-equipe-modal-component ref="cmpRsaEquip" @afterSave="onAfterSave"></rsa-equipe-modal-component>
        <rsa-equipe-view-component ref="cmpRsaEquipView"></rsa-equipe-view-component>
    </div>
</template>
<script>
//import axios from "axios";
//import DatePicker from "vue2-datepicker";
import UtilityMixin from "../../../utility/UtilityMixin";
import RsaEquipeModalComponent from "./RsaEquipeModalEditComponent.vue";
import RsaEquipeViewComponent from "./RsaEquipeViewComponent.vue";
export default {
    props: {
        /*    jsonData: { type: Object },
        idAccettazione: { type: String }, */
    },
    mixins: [UtilityMixin],
    components: {
        RsaEquipeModalComponent,
        RsaEquipeViewComponent,
        // DatePicker,
    },
    data() {
        return {
            rows: 0,
            perPage: 50,
            btnNuovaEquipeHidden: false,
            currentPage: 1,
            filtro: {},
            items: [],
            idAccettazione: null,
            listKey: 0,
            pathResource: "/rsaequipe",
            linkedit: "/",
            fields: [
                {
                    label: "Membri",
                    key: "membri",
                    thStyle: "width: 45rem",
                },
                /*   {
                    label: "Cordinatore",
                    key: "cordinatore",
                },
                {
                    label: "Infermiere",
                    key: "infermiere",
                }, */
                {
                    label: "Data Inizio",
                    key: "dataInizio",
                },
                {
                    label: "Data Fine",
                    key: "dataFine",
                },
                // {
                //   label: "Numero Giorni del Trattamento",
                //   key: "numeroGiorniTrattamento",
                // },
                //  { label: "Quote", key: "quote" },
                { class: "sa-table-column-action-double", key: "action", label: "" },
            ],
        };
    },
    mounted() {},
    watch: {
        currentPage() {
            let me = this;
            me.loadData();
        },
    },
    methods: {
        init(idPaziente) {
            let me = this;
            me.idAccettazione = idPaziente;
            me.loadData();
        },
        loadData() {
            let me = this;

            UtilityMixin.methods.loadDataResources(me.pathResource, { idAccettazione: me.idAccettazione }, me.resultResponse, me.resultError);
        },
        resultResponse(response) {
            let me = this;
            me.rows = response.data.data.recordsNumber;
            me.items = response.data.data.list;
        },
        resultError(error) {
            console.log(error);
        },
        nuovaEquipe() {
            let me = this;

            me.$refs.cmpRsaEquip.show();
            // me.$refs.cmpRsaEquip.setData();
        },
        // onSalvaImpegnativa() {
        //   let me = this;
        //   me.$refs.RsaAccettazioneImpegnativaEditComponent.salvaImpegnativa();
        // },

        /*      onAfterSaveRequestRsaImpegnativaComponent() {
            let me = this;
            me.$refs.cmpRsaAccettazioneImpegnativaEditComponent.close();
            this.$bvToast.toast(`Richiesta salvata correttamente`, {
                title: "Impegnativa",
                solid: true,
                variant: "success",
                autoHideDelay: 5000,
                appendToast: true,
            });
            me.loadData();
        }, */
        onAfterSave() {
            let me = this;
            me.loadData();
        },
        onClickInfo(item) {
            let me = this;
            // console.log(item);

            // item.listaEquipeDettaglio.forEach((element) => {
            //     console.log(element.nome + " " + element.cognome);
            // });
            me.$refs.cmpRsaEquipView.show(item.listaEquipeDettaglio);
        },
    },
};
</script>
