<template>
    <sa-page-layout :btnSaveVisible="true" :btnSaveNotBackVisible="true" :linkNotBack="linkNotBack" @aftersave="onAfterSave" :btnAnnullaVisible="true" :linkback="linkback" :data="jsonData" :showModalLoading="showModalLoading" :pathResource="pathResource" @refresh="onRefresh" @reset="onReset">
        <template slot="table-body">
            <numeratori-edit-component :id="id" ref="cmpNumeratoriEditComponent" :showModalLoading="showModalLoading" @update="onUpdateJsonData" @beforeLoadData="onBeforeLoadData" @afterLoadData="onAfterLoadData"> </numeratori-edit-component>
        </template>
    </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../template/layout/components/SaPageLayout.vue";
import NumeratoriEditComponent from "../components/NumeratoriEditComponent.vue";

export default {
    name: "NumeratoriEditPage",
    components: { SaPageLayout, NumeratoriEditComponent },
    data() {
        return {
            pathResource: "/numeratori",
            linkback: "/numeratori",
            linkNotBack: null,
            showModalLoading: false,
            jsonData: {},
            id: null,
        };
    },
    watch: {
        filtro: {
            handler(newVal) {
                this.$refs.NumeratoriEditComponent.filtro = newVal;
            },
        },
    },

    created() {
        let me = this;
        me.id = this.$route.params.id;
        const path = this.$route.path.replace(/\/edit\/.*/, "");
        me.linkNotBack = path + "{editid}?";
    },

    mounted() {
        /* this.loadData();
        //Recupero sempre i filtri se ci sono
        let sessionStorage = window.sessionStorage;
        if (sessionStorage["filtroNumeratori"]) {
            this.filtro = JSON.parse(sessionStorage["filtroNumeratori"]);
        } */
    },

    methods: {
        loadData() {
            let me = this;
            me.$refs.cmpNumeratoriEditComponent.loadData();
        },
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
        },
        onBeforeLoadData() {
            let me = this;
            me.showModalLoading = true;
        },
        onAfterLoadData() {
            let me = this;
            me.showModalLoading = false;
        },
        onAfterSave(data) {
            let me = this;
            me.id = data.id;
            let link = me.linkNotBack.replace("{editid}", "/edit/" + data.id);
            me.$router.push(link);
        },

        onRefresh() {
            this.loadData();
        },

        onReset() {
            this.filtro = {
                codice: null,
                descrizione: null,
                anno: null,
            };
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(this.filtro);
            this.loadData();
        },

        onSubmit() {
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(this.filtro);
        },
    },
};
</script>

<style scoped>
.sa-padding-right {
    padding-right: 1rem;
}

.text-right {
    text-align: right;
}
</style>
