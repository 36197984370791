<template>
    <div class="sa-form-section">
        <b-row style="margin-top: 2rem">
            <b-form-file v-model="file1" :state="Boolean(file1)" :placeholder="this.$i18n.t('global.dragDrop')" ref="file-input" class="custom-input" type="file" accept=".pdf"></b-form-file>
            <div class="mt-3">{{ this.$i18n.t("patient.attachments.fileSelected") }} {{ file1 ? file1.name : "" }}</div>
        </b-row>
        <b-row>
            <b-col sm="12" md="12" lg="12" xl="12">
                <b-button class="float-sm-right btn-toolbar" variant="danger sa-margin-right" size="sm" @click="onDelete" v-b-modal.modal-center>
                    <b-icon icon="x-circle" />
                    {{ this.$i18n.t("global.delete") }}
                </b-button>
            </b-col>
        </b-row>
        <hr />
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <strong>{{ this.$i18n.t("patient.attachments.documentType") }}</strong>
                <b-input-group v-if="insertNewTipoDocumento" style="margin-bottom: 5px">
                    <b-form-input v-model="jsonData.tipoDocumento" :placeholder="this.$i18n.t('patient.attachments.documentType')" style="margin-right: 7px"></b-form-input>
                    <b-form-input v-model="tipoDocumentoCodice" placeholder="Codice"></b-form-input>
                </b-input-group>
                <b-input-group>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" :options="tipiDocumentoOptions" :value="''" value-field="value" text-field="text" @input="onInputTipoDocumento($event)"></b-form-select>
                    <b-button v-if="insertNewTipoDocumento" variant="outline-secondary" style="margin-right: 5px" size="sm" @click="onClickAggiungiTipoDocumento(jsonData.tipoDocumento)"><b-icon icon="plus"></b-icon></b-button>
                    <b-button v-if="insertNewTipoDocumento" variant="outline-secondary" size="sm" @click="onDeleteTipoDocumento(jsonData.tipoDocumento)"><b-icon icon="trash"></b-icon></b-button>
                </b-input-group>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <strong>{{ this.$i18n.t("patient.attachments.loadingData") }}</strong>
                <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataCaricamento" :placeholder="this.$i18n.t('patient.attachments.loadingData')"></date-picker>
            </b-col>
        </b-row>
        <hr />
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <strong>{{ this.$i18n.t("patient.attachments.description") }}</strong>
                <b-form-textarea v-model="jsonData.descrizione" :placeholder="this.$i18n.t('patient.attachments.description')" no-resize></b-form-textarea>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import Vue from "vue";
import UtilityMixin from "../../../utility/UtilityMixin";
export default {
    components: { DatePicker },
    mixins: [UtilityMixin],
    props: {
        jsonData: {
            Type: Object,
            default: function () {
                return {
                    tipoDocumento: "",
                    dataCaricamento: null,
                    descrizione: "",
                };
            },
        },
        tipoRisorsa: { type: String, default: null },
    },
    data() {
        return {
            file1: null,
            perPage: 10,
            currentPageFile: 1,
            rowList: 0,
            tipiDocumentoOptions: [],
            tipiDocumentoList: [],
            tipoDocumentoCodice: "",
            pathTipiDocumento: "/allegatitipidocumento",
            insertNewTipoDocumento: false,
        };
    },
    mounted() {
        let me = this;
        // console.log(me.jsonData);

        me.loadTipiDocumento();
        me.verificaPermessi();
    },
    methods: {
        verificaPermessi() {
            let me = this;
            me.insertNewTipoDocumento = UtilityMixin.methods.verificaPermessi(Vue.prototype.user, "allegatitipodocumento", 2);
        },
        loadTipiDocumento() {
            let me = this;
            me.tipiDocumentoOptions = [{ value: "", text: "-Seleziona Tipo Documento-" }];
            me.tipiDocumentoList = [];
            let link = process.env.VUE_APP_PATH_API + me.pathTipiDocumento + "?tipoRisorsa=" + me.tipoRisorsa;
            axios.get(link).then((response) => {
                me.tipiDocumentoList = response.data.data.list;
                response.data.data.list.forEach((element) => {
                    me.tipiDocumentoOptions.push({ value: element.tipoDocumento, text: element.tipoDocumento });
                });
            });
        },
        onInputTipoDocumento(value) {
            let me = this;
            // console.log(value);
            // console.log(me.jsonData.tipoDocumento);
            me.jsonData.tipoDocumento = value;
        },

        onClickAggiungiTipoDocumento(tipoDocumento) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathTipiDocumento;
            let jsonData = { tipoDocumento: tipoDocumento, tipoRisorsa: me.tipoRisorsa, tipoDocumentoCodice: me.tipoDocumentoCodice };
            axios
                .post(link, JSON.stringify(jsonData))
                .then((response) => {
                    this.$bvModal.msgBoxOk(response.data.messaggio, {
                        title: "",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    });
                    me.loadTipiDocumento();
                })
                .catch((e) => {
                    // console.log(e);
                    this.$bvModal
                        .msgBoxOk(e.response.data.messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then((value) => {
                            this.boxTwo = value;
                        });
                });
        },

        onDeleteTipoDocumento(descrizione) {
            let me = this;
            this.$bvModal
                .msgBoxConfirm("Sei sicuro di procedere alla cancellazione?", {
                    title: "Conferma Cancellazione",
                    size: "sm",
                    buttonSize: "sm",
                    okVariant: "secondary",
                    cancelVariant: "danger",
                    okTitle: "SI",
                    cancelTitle: "NO",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                })
                .then((value) => {
                    if (value) {
                        let link = process.env.VUE_APP_PATH_API + me.pathTipiDocumento;
                        me.tipiDocumentoList.forEach((element) => {
                            // console.log(descrizione);
                            // console.log(element.tipoDocumento);
                            if (element.tipoDocumento === descrizione) {
                                console.log(link, " ", element.id);
                                axios.delete(link + "/" + element.id).then((response) => {
                                    this.$bvModal.msgBoxOk(response.data.messaggio, {
                                        title: "",
                                        size: "sm",
                                        okVariant: "outline-danger",
                                        headerClass: "sa-msg-header-danger",
                                        footerClass: "p-2 border-top-0",
                                        centered: true,
                                    });
                                    me.loadTipiDocumento();
                                });
                            } else if (descrizione === null || descrizione === "") {
                                this.$bvModal.msgBoxOk("Nessun elemento da cancellare.", {
                                    title: "Attenzione!",
                                    Text: "Nessun valore da cancellare!",
                                    size: "sm",
                                    okVariant: "outline-danger",
                                    headerClass: "sa-msg-header-danger",
                                    footerClass: "p-2 border-top-0",
                                    centered: true,
                                });
                            }
                        });
                    }
                })
                .catch((e) => {
                    this.$bvModal
                        .msgBoxOk(e.response.data.messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then((value) => {
                            this.boxTwo = value;
                        });
                });
        },

        onDelete() {
            this.$refs["file-input"].reset();
        },
        createBase64(fileObject) {
            let me = this;
            const reader = new FileReader();
            reader.onload = (e) => {
                me.pdf = e.target.result;
                me.jsonData.file = me.pdf;
            };
            reader.readAsDataURL(fileObject);
        },
    },
    watch: {
        file1: function (e) {
            let me = this;
            console.log(e);
            if (e !== null) {
                const selectedFile = e;
                me.jsonData.nomeFile = e.name;
                me.jsonData.size = e.size;
                me.jsonData.tipo = e.type;
                this.createBase64(selectedFile);
            }
        },
        tipoDocumento() {
            let me = this;
            me.loadTipiDocumento();
        },
    },
};
</script>
