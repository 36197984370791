<template>
    <div class="sa-tab-scroll">
        <b-card class="sa-card" header="Dati Struttura" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Codice Struttura</label>
                    <span class="sa-data">{{ jsonData.codiceStruttura }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Data Primo Invio</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataPrimoInvio) }}</span>
                </b-col>
                <!-- <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Data Primo Aggiornamento</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataPrimoAggiornamento) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Data Secondo Aggiornamento</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataSecondoAggiornamento) }}</span>
                </b-col> -->
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Dati Paziente" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Numero Codice Identificativo</label>
                    <span class="sa-data">{{ jsonData.numeroCodiceIdentificativo }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Tipo Paziente</label>
                    <span class="sa-data">{{ jsonData.tipoPaziente }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Tipo Donatore</label>
                    <span class="sa-data">{{ jsonData.tipoPazienteDonatore }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Nascita</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataNascita) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Sesso</label>
                    <span class="sa-data">{{ jsonData.sesso }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Provincia Residenza</label>
                    <span class="sa-data">{{ jsonData.provinciaResidenza }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Comune Residenza</label>
                    <span class="sa-data">{{ jsonData.comuneResidenzaDescrizione }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Asl Residenza</label>
                    <span class="sa-data">{{ jsonData.aslResidenza }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Telefono</label>
                    <span class="sa-data">{{ jsonData.telefono }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Cittadinanza Italiana</label>
                    <span class="sa-data">{{ jsonData.cittadinanzaItaliana }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Paese Cittadinanza Straniera</label>
                    <span class="sa-data">{{ jsonData.paeseCittadinanzaStraniera }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Altre Patologie Presenti</label>
                    <span class="sa-data">{{ jsonData.altrePatologie }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Specificare Eventuali Patologie</label>
                    <span class="sa-data">{{ jsonData.altrePatologieAltro }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Comportamento a Rischio Dichiarato" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Uso iniettivo di droghe</label>
                    <span class="sa-data">{{ jsonData.drogheIniettate }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Anno Inizio Droghe Iniettate</label>
                    <span class="sa-data">{{ jsonData.annoInizioDrogheIniettate }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Anno Fine Droghe Iniettate</label>
                    <span class="sa-data">{{ jsonData.annoFineDrogheIniettate }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Trasfusione o uso di emoderivati precedentemente al 1987</label>
                    <span class="sa-data">{{ jsonData.trasfusioneEmoderivati }}</span>
                </b-col>
            </b-row>

            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Prostituzione Senza Tossicodipendenza</label>
                    <span class="sa-data">{{ jsonData.prostituzioneSenzaTossicodipendenza }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Uso Profilattico</label>
                    <span class="sa-data">{{ jsonData.prostituzioneUsoProfilattico }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Rapporti Omosessuali Con Sieropositivo Noto</label>
                    <span class="sa-data">{{ jsonData.rapportiOmosessualiSieropositivoNoto }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Uso Profilattico</label>
                    <span class="sa-data">{{ jsonData.rapportiOmosessualiSieropositivoNotoUsoProfilattico }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Rapporti Omosessuali Sieropositivo Non Noto</label>
                    <span class="sa-data">{{ jsonData.rapportiOmosessualiSieropositivoNonNoto }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Uso Profilattico</label>
                    <span class="sa-data">{{ jsonData.rapportiOmosessualiSieropositivoNonNotoUsoProfilattico }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="32" xl="3">
                    <label class="sa-label-data">Rapporti Eterosessuali Sieropositivo Noto</label>
                    <span class="sa-data">{{ jsonData.rapportiEterosessualiSieropositivoNoto }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Uso Profilattico</label>
                    <span class="sa-data">{{ jsonData.rapportiEterosessualiSieropositivoNotoUsoProfilattico }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Rapporti Eterosessuali Sieropositivo Non Noto</label>
                    <span class="sa-data">{{ jsonData.rapportiEterosessualiSieropositivoNonNoto }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Uso Profilattico</label>
                    <span class="sa-data">{{ jsonData.rapportiEterosessualiSieropositivoNonNotoUsoProfilattico }}</span>
                </b-col>
            </b-row>

            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Rifiuto Indagine Anamnestica</label>
                    <span class="sa-data">{{ jsonData.rifiutoIndagineAnamnestica }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Madre Sieropositiva</label>
                    <span class="sa-data">{{ jsonData.madreSieropositiva }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Convivente Sieropositivo</label>
                    <span class="sa-data">{{ jsonData.conviventeSieropositivo }}</span>
                </b-col>
            </b-row>

            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Partner Sessuale Stabile Sieropositivo</label>
                    <span class="sa-data">{{ jsonData.partnerSessualeStabileSieropositivo }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Uso Profilattico</label>
                    <span class="sa-data">{{ jsonData.partnerSessualeStabileSieropositivoUsoProfilattico }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Rapporti Sessuali Con Prostitute</label>
                    <span class="sa-data">{{ jsonData.rapportiSessualiProstitute }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Uso Profilattico</label>
                    <span class="sa-data">{{ jsonData.rapportiSessualiProstituteUsoProfilattico }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Rapporti Sessuali Con Persone Provenienti da Paesi ad Alta Prevalenza</label>
                    <span class="sa-data">{{ jsonData.rapportiSessualiPaeseAltaPrevalenza }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Uso Profilattico</label>
                    <span class="sa-data">{{ jsonData.rapportiSessualiPaeseAltaPrevalenzaUsoProfilattico }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Rapporti Sessuali Con Persone Che Utilizzano Droghe per Via Iniettiva</label>
                    <span class="sa-data">{{ jsonData.rapportiSessualiPersoneUtilizzoDrogheIniettive }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Uso Profilattico</label>
                    <span class="sa-data">{{ jsonData.rapportiSessualiPersoneUtilizzoDrogheIniettiveUsoProfila }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Nessun Rapporto con persone che rientrano nelle risposte precedenti</label>
                    <span class="sa-data">{{ jsonData.nessunRapportoRispostePrecedenti }}</span>
                </b-col>
            </b-row>

            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Presunto Luogo Infezione</label>
                    <span class="sa-data">{{ jsonData.presuntoLuogoInfezione }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Ultima Possibile Esposizione</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataUltimaPossibileEsposizione) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Motivo Esecuzione Test</label>
                    <span class="sa-data">{{ jsonData.motivoEsecuzioneTest }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Altro</label>
                    <span class="sa-data-justify">{{ jsonData.motivoEsecuzioneTestAltro }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Dati Test" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Test Eseguito Precedentemente</label>
                    <span class="sa-data">{{ jsonData.testEseguitoPrecedentemente }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Data Esecuzione Test</label>
                    <span class="sa-data">{{ jsonData.dataEsecuzioneTest }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Data Prelievo</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataPrimoPrelievo) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Tipo Di Test</label>
                    <span class="sa-data">{{ jsonData.tipoTest }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Esito</label>
                    <span class="sa-data">{{ jsonData.esitoPrimoPrelievo }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Se Negativo Indicare Data Riesecuzione</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataRiesecuzionePrimoPrelievo) }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">E' stato contattato il centro diagnosi e cura</label>
                    <span class="sa-data">{{ jsonData.esitoPrimoPrelievoCentroDiagnosiCura }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Nome Centro Diagnosi Cura</label>
                    <span class="sa-data">{{ jsonData.primoPrelievoNomeCentroDiagnosiCura }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Data Appuntamento</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataPrimoAppuntamentoCentroDiagnosiCura) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Data Consegna Primo Referto</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataConsegnaPrimoReferto) }}</span>
                </b-col>
            </b-row>
            <!-- <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Secondo Prelievo</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataSecondoPrelievo) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Esito Secondo Prelievo</label>
                    <span class="sa-data">{{ jsonData.esitoSecondoPrelievo }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Riesecuzione Secondo Prelievo</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataRiesecuzioneSecondoPrelievo) }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">E' stato contattato il Centro Diagnosi e Cura</label>
                    <span class="sa-data">{{ jsonData.esitoSecondoPrelievoCentroDiagnosiCura }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Nome Centro Diagnosi e Cura</label>
                    <span class="sa-data">{{ jsonData.secondoPrelievoNomeCentroDiagnosiCura }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Data Secondo Appuntamento</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataSecondoAppuntamentoCentroDiagnosiCura) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Data Consegna Secondo Referto</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataConsegnaSecondoReferto) }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Terzo Prelievo</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataTerzoPrelievo) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Esito Terzo Prelievo</label>
                    <span class="sa-data">{{ jsonData.esitoTerzoPrelievo }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Riesecuzione Terzo Prelievo</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataRiesecuzioneTerzoPrelievo) }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">E' stato contattato il Centro Diagnosi e Cura</label>
                    <span class="sa-data">{{ jsonData.esitoTerzoPrelievoCentroDiagnosiCura }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Nome Centro Diagnosi e Cura</label>
                    <span class="sa-data">{{ jsonData.terzoPrelievoNomeCentroDiagnosiCura }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Data Terzo Appuntamento</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataTerzoAppuntamentoCentroDiagnosiCura) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Data Consegna Terzo Referto</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataConsegnaTerzoReferto) }}</span>
                </b-col>
            </b-row> -->
        </b-card>
        <b-card class="sa-card" header="Dati Compilazione" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Compilazione</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataCompilazione) }}</span>
                </b-col>
                <!-- <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Responsabile Compilazione</label>
                    <span class="sa-data">{{ jsonData.responsabileCompilazione }}</span>
                </b-col> -->
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Personale sanitario che ha curato il counselling pre-test</label>
                    <span class="sa-data">{{ jsonData.personaleSanitarioCounsellingPreTest }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Psicologo che ha curato il counselling post test</label>
                    <span class="sa-data">{{ jsonData.psicologoCounsellingPostTest }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Protocollo" header-tag="header" footer-tag="footer" title="">
            <protocollo-list-component :idDocumento="jsonData.id" :documento="'HIV'"></protocollo-list-component>
        </b-card>
    </div>
</template>
<script>
import axios from "axios";
import UtilityMixin from "../../../utility/UtilityMixin";
import ProtocolloListComponent from "../../../protocollo/components/ProtocolloListComponent.vue";
export default {
    mixins: [UtilityMixin],
    components: { ProtocolloListComponent },
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    data() {
        return {
            pathResource: "/malattieinfettivehiv",
            id: "-1",
            jsonData: {
                provinciaResidenza: "",
                prostituzioneSenzaTossicodipendenza: "",
                // dataTerzoAppuntamentoCentroDiagnosiCura: null,
                personaleSanitarioCounsellingPreTest: null,
                rapportiOmosessualiSieropositivoNotoUsoProfilattico: "",
                // dataSecondoPrelievo: null,
                rapportiEterosessualiSieropositivoNotoUsoProfilattico: "",
                aslResidenza: null,
                comuneResidenza: "",
                prostituzioneUsoProfilattico: "",
                rifiutoIndagineAnamnestica: "",
                rapportiSessualiPersoneUtilizzoDrogheIniettiveUsoProfila: "",
                // dataConsegnaSecondoReferto: null,
                dataNascita: null,
                annoFineDrogheIniettate: "",
                rapportiEterosessualiSieropositivoNonNotoUsoProfilattico: "",
                rapportiSessualiPersoneUtilizzoDrogheIniettive: "",
                telefono: null,
                esitoPrimoPrelievoCentroDiagnosiCura: "",
                rapportiOmosessualiSieropositivoNoto: "",
                esitoPrimoPrelievo: "",
                dataPrimoAppuntamentoCentroDiagnosiCura: null,
                dataEsecuzioneTest: null,
                presuntoLuogoInfezione: "",
                motivoEsecuzioneTestAltro: "",
                conviventeSieropositivo: "",
                // dataTerzoPrelievo: null,
                paeseCittadinanzaStraniera: "",
                // dataConsegnaTerzoReferto: null,
                numeroCodiceIdentificativo: null,
                partnerSessualeStabileSieropositivo: "",
                rapportiSessualiProstituteUsoProfilattico: "",
                secondoPrelievoNomeCentroDiagnosiCura: "",
                drogheIniettate: "",
                // esitoTerzoPrelievoCentroDiagnosiCura: "",
                motivoEsecuzioneTest: "",
                // dataSecondoAppuntamentoCentroDiagnosiCura: null,
                dataPrimoInvio: null,
                // esitoSecondoPrelievoCentroDiagnosiCura: "",
                rapportiSessualiPaeseAltaPrevalenzaUsoProfilattico: "",
                dataPrimoPrelievo: null,
                rapportiEterosessualiSieropositivoNonNoto: "",
                dataCompilazione: null,
                annoInizioDrogheIniettate: "",
                rapportiOmosessualiSieropositivoNonNotoUsoProfilattico: "",
                responsabileCompilazione: null,
                partnerSessualeStabileSieropositivoUsoProfilattico: "",
                // esitoSecondoPrelievo: "",
                primoPrelievoNomeCentroDiagnosiCura: "",
                // esitoTerzoPrelievo: "",
                dataSecondoAggiornamento: null,
                rapportiSessualiProstitute: "",
                nessunRapportoRispostePrecedenti: "",
                testEseguitoPrecedentemente: "",
                madreSieropositiva: "",
                dataUltimaPossibileEsposizione: null,
                dataConsegnaPrimoReferto: null,
                codiceStruttura: null,
                rapportiEterosessualiSieropositivoNoto: "",
                rapportiSessualiPaeseAltaPrevalenza: "",
                dataRiesecuzionePrimoPrelievo: null,
                rapportiOmosessualiSieropositivoNonNoto: "",
                sesso: "",
                dataPrimoAggiornamento: null,
                trasfusioneEmoderivati: "",
                cittadinanzaItaliana: "",
                psicologoCounsellingPostTest: null,
                // terzoPrelievoNomeCentroDiagnosiCura: "",
                // dataRiesecuzioneSecondoPrelievo: null,
                // dataRiesecuzioneTerzoPrelievo: null,
                altrePatologie: "", // DA AGGIUNGERE NEL DB E NEL BACKEND
                altrePatologieAltro: "", // DA AGGIUNGERE NEL DB E NEL BACKEND
                tipoTest: "", // DA AGGIUNGERE NEL DB E NEL BACKEND
            },
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient() {
            return UtilityMixin.methods.getLinkPatient(this.utente, this.idPaziente);
        },
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.linkback = "/malattieinfettive/hiv";
        me.loadData();
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.$emit("showmodalloading", true);
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            axios
                .get(link + me.id)
                .then((response) => {
                    me.jsonData = response.data.data;
                    me.$emit("showmodalloading", false);
                })
                .catch(() => {
                    me.$emit("showmodalloading", false);
                });
        },
    },
};
</script>
