import SchedaValutazioneEsigenzeImmediateList from "../svei/pages/SchedaValutazioneEsigenzeImmediateList";
import SchedaValutazioneEsigenzeImmediateEdit from "../svei/pages/SchedaValutazioneEsigenzeImmediateEdit";
import SchedaValutazioneEsigenzeImmediateView from "../svei/pages/SchedaValutazioneEsigenzeImmediateView";
import SveiConfigurazionePuntiIncontroPageList from "../svei/pages/SveiConfigurazionePuntiIncontroPageList";
import SveiConfigurazionePuntiIncontroPageEdit from "../svei/pages/SveiConfigurazionePuntiIncontroPageEdit";
import SveiConfigurazionePuntiIncontroView from "../svei/pages/SveiConfigurazionePuntiIncontroView";
import SveiGestioneEmergenzaPage from "../svei/pages/SveiGestioneEmergenzaPage";
import SveiMappa from "../svei/pages/SveiMappa";

export default {
    SchedaValutazioneEsigenzeImmediateList,
    SchedaValutazioneEsigenzeImmediateEdit,
    SchedaValutazioneEsigenzeImmediateView,
    SveiConfigurazionePuntiIncontroPageList,
    SveiConfigurazionePuntiIncontroPageEdit,
    SveiConfigurazionePuntiIncontroView,
    SveiGestioneEmergenzaPage,
    SveiMappa,
};
