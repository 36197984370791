<template>
    <div style="height: 100%; overflow: auto">
        <b-card class="sa-card" header="Dati Chiamate" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Nominativo:</label>
                    <span class="sa-data">{{ jsonData.chiamanteNominativo }}</span>
                </b-col>
                <!-- <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Cognome:</label>
                    <span class="sa-data">{{ jsonData.chiamanteCognome }}</span>
                </b-col> -->
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Contatto:</label>
                    <span class="sa-data">{{ jsonData.chiamanteContatto }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Ruolo:</label>
                    <span class="sa-data">{{ jsonData.chiamanteRuolo }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Dettagli Chiamata" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Data ora:</label>
                    <span class="sa-data">{{ formatDateTime(jsonData.dataOra) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Motivo:</label>
                    <span class="sa-data">{{ jsonData.descrizioneMotivo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Note:</label>
                    <span class="sa-data">{{ jsonData.note }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Dati Paziente" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Identificativo:</label>
                    <span class="sa-data">{{ jsonData.pazienteIdentificativo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Nome:</label>
                    <span class="sa-data">{{ jsonData.pazienteNome }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Cognome:</label>
                    <span class="sa-data">{{ jsonData.pazienteCognome }}</span>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import axios from "axios";
import UtilityMixin from "../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixin],
    props: {
        idTracciamanetoChiamata: String,
        jsonData: {
            type: Object,
            default: function () {
                return { idPropostaAccesso: null, dataOra: null, codiceMotivo: "", descrizioneMotivo: null, note: null, chiamanteNominativo: null, chiamanteRuolo: null, pazienteNome: null, pazienteCognome: null, pazienteIdentificativo: null };
            },
        },
    },
    data() {
        return {
            motivazioniOptions: [],
            ruoliOptions: [],
            pathResource: "/cottracciamentochiamate",
            pathResourceMotivazioni: "/cottracciamentochiamatemotivi",
            pathResourceRuoli: "/cottracciamentochiamateruoli",
            pathResourcePropostaAccesso: "/cot/richieste",
            // jsonData: { idPropostaAccesso: null, dataOra: null, codiceMotivo: "", descrizioneMotivo: null, note: null, chiamanteNome: null, chiamanteCognome: null, chiamanteRuolo: null, pazienteNome: null, pazienteCognome: null, pazienteIdentificativo: null },
        };
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
        idTracciamanetoChiamata() {
            let me = this;
            me.loadData();
        },
    },
    mounted: function () {
        let me = this;
        // me.id = this.$route.params.id;
        // if (me.id === "-1") {
        //     me.jsonData.dataOra = new Date().getTime();
        // }
        // me.loadMotivazioni();
        if (me.idTracciamanetoChiamata) {
            me.loadData();
        }
    },
    methods: {
        loadMotivazioni() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceMotivazioni;
            axios.get(link).then((response) => {
                me.motivazioniOptions = response.data.data.list;
                me.motivazioniOptions.unshift({ codice: "", descrizione: "-Seleziona Motivo-" });
                me.loadRuolo();
            });
        },
        loadRuolo() {
            let me = this;
            me.ruoliOptions = [];
            let link = process.env.VUE_APP_PATH_API + me.pathResourceRuoli;
            axios.get(link).then((response) => {
                response.data.data.list.forEach((element) => {
                    this.ruoliOptions.push({ text: element.descrizione, value: element.descrizione });
                });
                me.ruoliOptions.unshift({ value: null, text: "-Seleziona Ruolo-" });
                me.loadData();
            });
        },
        onChangeMotivo() {
            let me = this;
            me.motivazioniOptions.forEach((element) => {
                if (element.codice === me.jsonData.codiceMotivo) {
                    me.jsonData.descrizioneMotivo = element.descrizione;
                    console.log(me.jsonData.descrizioneMotivo);
                }
            });
        },
        loadData() {
            let me = this;
            console.log("Exxomi");

            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            axios.get(link + me.idTracciamanetoChiamata).then((response) => {
                me.jsonData = response.data.data;

                this.$emit("afterLoadData");
            });
            // }
        },
        onSearchAnagraficaClick() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + "/utility";
            if (me.jsonData.pazienteIdentificativo) {
                axios
                    .get(link, { params: { codiceStruttura: "180000", codiceFiscale: me.jsonData.pazienteIdentificativo } })
                    .then((response) => {
                        // console.log(response);
                        if (response.data.data !== null) {
                            me.jsonData.pazienteNome = response.data.data.nome;
                            me.jsonData.pazienteCognome = response.data.data.cognome;
                        } else {
                            return me.$bvModal.msgBoxOk("Codice Fiscale non corretto", {
                                title: "Errore",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            });
                        }
                    })
                    .catch(() => {
                        me.$emit("update", me.jsonData);
                    });
            }
        },
        getJsonData() {
            return this.jsonData;
        },
    },
};
</script>

<style></style>
