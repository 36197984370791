import Enti from "../";

const routes = [
  {
    name: "EntiList",
    path: "/enti",
    component: Enti.EntiList,
  },
  {
    name: "EntiView",
    path: "/enti/view/:id",
    component: Enti.EntiView,
  },
  {
    name: "EntiEdit",
    path: "/enti/edit/:id",
    component: Enti.EntiEdit,
  },
  // {
  //      name: "EntiView",
  //      path: "/Enti/view/:id",
  //      component: Enti.EntiView,
  // },
];

export default routes;
