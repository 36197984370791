<template>
  <sa-page-layout :btnRefreshVisible="false" :toolbarVisible="false" :showModalLoading="showModalLoading">
    <template slot="table-body">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Server Endpoint</label>
          <span class="sa-data">{{ jsonDataClientConfig.serverEndpoint }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Server Port</label>
          <span class="sa-data">{{ jsonDataClientConfig.serverPort }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <b-button variant="outline-primary" @click="onClickModificaClient">
            <i class="bi bi-pencil"></i>
            Modifica Client
          </b-button>
        </b-col>
      </b-row>
      <b-tabs id="tabsHl7IntegratorClient" ref="tabs" nav-wrapper-class="sa-tab-items" content-class="mt-3" class="sa-tab" v-model="tabIndex" style="height: 100%; padding-bottom: 25px">
        <b-tab id="" title="Invia Messaggio">
          <div class="sa-tab-scroll">
            <b-row>
              <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <label class="sa-label-data">Testo Messaggio</label>
                <b-form-textarea rows="8" no-resize v-model="jsonDataInvioMessaggio.sendMessage"></b-form-textarea>
              </b-col>
            </b-row>
            <hr />
            <b-row>
              <b-col class="sa-padding-right text-right">
                <b-button type="submit" variant="primary" @click="onClickSendMessage()">
                  <i class="bi bi-send"></i>
                  Invia
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <label class="sa-label-data">Risposta</label>
                <b-form-textarea rows="8" readonly no-resize v-model="jsonDataInvioMessaggio.response"></b-form-textarea>
              </b-col>
            </b-row>
            <hr />
          </div>
        </b-tab>
        <b-tab id="tbRsaSchedaOspite" title="Storico messaggi">
          <div class="sa-flexbox" style="padding-bottom: 20px">
            <div class="sa-flexbox-header">
              <b-form @submit.prevent="onSubmit">
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                  <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Data Invio Messaggio Dal</label>
                    <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="filtroMessaggi.sendDateHourDal" placeholder="Data invio messaggio Dal"></date-picker>
                  </b-col>
                  <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Data Invio Messaggio Al</label>
                    <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="filtroMessaggi.sendDateHourAl" placeholder="Data Invio Messaggio Al"></date-picker>
                  </b-col>
                  <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Id Messaggio</label>
                    <b-form-input v-model="filtroMessaggi.idMessaggio" type="search" placeholder="Id Messaggio"></b-form-input>
                  </b-col>
                  <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Messaggio Inviato</label>
                    <b-form-input v-model="filtroMessaggi.sendMessage" type="search" placeholder="Messaggio Inviato"></b-form-input>
                  </b-col>
                  <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Risposta Messaggio Dal</label>
                    <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="filtroMessaggi.responseDateHourDal" placeholder="Data Risposta Messaggio Dal"></date-picker>
                  </b-col>
                  <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Risposta Messaggio Al</label>
                    <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="filtroMessaggi.responseDateHourAl" placeholder="Data Risposta Messaggio Al"></date-picker>
                  </b-col>
                  <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Codice Risposta</label>
                    <b-form-input v-model="filtroMessaggi.responseCode" type="search" placeholder="Codice Risposta"></b-form-input>
                  </b-col>
                </b-row>
                <b-row class="sa-form-btn-filter">
                  <b-col class="sa-padding-right text-right">
                    <b-button type="submit" variant="info">{{ this.$i18n.t("global.lblFind") }}</b-button>
                    <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("global.lblResetFilter") }}</b-button>
                  </b-col>
                </b-row>
              </b-form>
            </div>
            <div class="sa-flexbox-body">
              <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" stacked="lg" striped hover :items="itemsMessage" :fields="fieldsMessage" :current-page="currentPage" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
                  <template v-slot:cell(index)="{ index }">
                    {{ index + 1 }}
                  </template>
                  <template v-slot:cell(idMessagio)="{ item }">
                    <span>{{ item.idMessagio }}</span>
                  </template>
                  <template v-slot:cell(host)="{ item }">
                    <span>{{ item.host }}</span>
                  </template>
                  <template v-slot:cell(port)="{ item }">
                    <span>{{ item.port }}</span>
                  </template>
                  <template v-slot:cell(actions)="data">
                    <b-row>
                      <b-button variant="btn btn-outline-secondary waves-effect waves-light width-sm no-text" size="sm" @click="onSend(data.item)">
                        <i class="bi bi-send"></i>
                      </b-button>
                      <b-button variant="btn btn-outline-secondary waves-effect waves-light width-sm no-text" size="sm" @click="data.toggleDetails">
                        <i class="bi bi-info"></i>
                      </b-button>
                    </b-row>
                  </template>
                  <template v-slot:cell(show_details)="data">
                    <b-button size="sm" @click="data.toggleDetails" class="mr-2">
                      {{ data.detailsShowing ? "Chiudi" : "Show" }}
                    </b-button>
                    <b-form-checkbox v-model="data.detailsShowing" @change="data.toggleDetails">Details via check</b-form-checkbox>
                  </template>
                  <template v-slot:row-details="data">
                    <b-card>
                      <b-row class="mb-2">
                        <b-col sm="3" class="text-sm-right"><b>Messaggio Inviato:</b></b-col>
                        <b-col>{{ data.item.sendMessage }}</b-col>
                      </b-row>
                      <b-row class="mb-2">
                        <b-col sm="3" class="text-sm-right"><b>Messaggio di Risposta:</b></b-col>
                        <b-col>{{ data.item.responseMessage }}</b-col>
                      </b-row>
                      <b-button size="sm" variant="outline-secondary" @click="data.toggleDetails">Chiudi</b-button>
                    </b-card>
                  </template>
                </b-table>
              </div>
            </div>
            <div class="sa-flexbox-footer" style="background-color: ciano">
              <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                  <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                  <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-tab>
        <b-modal id="mdlEditClient" ref="mdlEditClient" title="Modifica Puntamento Client" @ok="onOKMdlEditClient">
          <div>
            <b-row>
              <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <label class="sa-label-data">Server Endpoint</label>
                <b-form-input v-model="jsonDataClientConfigEdit.serverEndpoint" type="text"></b-form-input>
              </b-col>
              <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <label class="sa-label-data">Server Port</label>
                <b-form-input v-model="jsonDataClientConfigEdit.serverPort" type="text"></b-form-input>
              </b-col>
            </b-row>
          </div>
        </b-modal> </b-tabs
    ></template>
  </sa-page-layout>
</template>

<script>
import axios from "axios";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
  components: { DatePicker, SaPageLayout },
  data() {
    return {
      showModalLoading: false,
      pathResourceClientConfig: "/hl7integratorclientconfig/1",
      pathResourceClient: "/hl7integratorclientmessage/send",
      pathResourceClientMessage: "/hl7integratorclientmessage",
      jsonDataClientConfig: {},
      jsonDataClientConfigEdit: { serverEndpoint: "", serverPort: "" },
      jsonDataInvioMessaggio: { sendMessage: "", responseMessage: "", host: "", port: null },
      tabIndex: 0,
      filtroMessaggi: {},
      itemsMessage: [],
      currentPage: 1,
      perPage: 100,
      rows: 0,
      listKey: 0,
      fieldsMessage: [
        { label: "", key: "index", thStyle: "width: 5rem" },
        { label: "Id Messaggio", key: "idMessage", sortable: true },
        { label: "Host", key: "host", sortable: true, thStyle: "width: 7rem" },
        { label: "Porta", key: "port", sortable: true, thStyle: "width: 5rem" },
        {
          label: "Invio Data Ora",
          key: "sendDateHour",
          sortable: true,
          thStyle: "width: 12rem",
          formatter: (value) => {
            if (value) {
              return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
            } else {
              return "-------";
            }
          },
        },
        {
          label: "Response Code",
          key: "responseCode",
          sortable: true,
        },
        { key: "actions", label: "" },
        {},
      ],
    };
  },
  mounted() {
    let me = this;
    me.loadClientConfig();
  },
  watch: {
    tabIndex() {
      switch (this.tabIndex) {
        case 0:
          break;
        case 1:
          this.loadClientMessage();
          break;
      }
    },
    currentPage() {
      let me = this;
      me.loadClientMessage();
    },
  },
  methods: {
    loadClientMessage() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceClientMessage;
      me.showModalLoading = true;
      me.filtroMessaggi.page = me.currentPage;
      me.filtroMessaggi.forPage = me.perPage;
      axios
        .get(link, { params: me.filtroMessaggi })
        .then((response) => {
          me.rows = response.data.data.recordsNumber;
          me.itemsMessage = response.data.data.list;
          me.showModalLoading = false;
          me.listKey++;
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    loadClientConfig() {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceClientConfig;
      me.showModalLoading = true;
      axios
        .get(link)
        .then((response) => {
          me.jsonDataClientConfig = response.data.data;
          me.showModalLoading = false;
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    onClickModificaClient() {
      let me = this;
      me.jsonDataClientConfigEdit.serverEndpoint = me.jsonDataClientConfig.serverEndpoint;
      me.jsonDataClientConfigEdit.serverPort = me.jsonDataClientConfig.serverPort;
      me.$refs["mdlEditClient"].show();
    },
    onOKMdlEditClient() {
      let me = this;
      me.updateClient();
    },
    updateClient() {
      let me = this;
      me.jsonDataClientConfig.serverEndpoint = me.jsonDataClientConfigEdit.serverEndpoint;
      me.jsonDataClientConfig.serverPort = me.jsonDataClientConfigEdit.serverPort;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceClientConfig;
      me.showModalLoading = true;
      axios
        .put(link, me.jsonDataClientConfig)
        .then((response) => {
          me.jsonDataClientConfig = response.data.data;
          me.showModalLoading = false;
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    onClickSendMessage() {
      let me = this;
      me.jsonDataInvioMessaggio.host = me.jsonDataClientConfig.serverEndpoint;
      me.jsonDataInvioMessaggio.port = me.jsonDataClientConfig.serverPort;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceClient;
      me.showModalLoading = true;
      axios
        .post(link, me.jsonDataInvioMessaggio)
        .then((response) => {
          me.jsonDataInvioMessaggio = response.data.data;
          me.showModalLoading = false;
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    onSend(item) {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceClient;
      me.showModalLoading = true;
      axios
        .post(link, item)
        .then((response) => {
          me.jsonDataInvioMessaggio = response.data.data;
          me.showModalLoading = false;
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    onReset() {
      let me = this;
      me.filtroMessaggi = { page: 1, forPage: 100 };
      me.loadClientMessage();
    },
    onSubmit() {
      let me = this;
      me.loadClientMessage();
    },
  },
};
</script>

<style></style>
