import RasConfigurazionePostiLettoPianiListPage from "./pages/RasConfigurazionePostiLettoPianiListPage.vue";
import RasConfigurazionePostiLettoPianiEditPage from "./pages/RasConfigurazionePostiLettoPianiEditPage.vue";
import RasConfigurazionePostiLettoPianiViewPage from "./pages/RasConfigurazionePostiLettoPianiViewPage.vue";
import RasConfigurazionePostiLettoStanzeListPage from "./pages/RasConfigurazionePostiLettoStanzeListPage.vue";
import RsaConfigurazioneDiariListPage from "./pages/RsaConfigurazioneDiariListPage.vue";
import RsaConfigurazioneDiariEditPage from "./pages/RsaConfigurazioneDiariEditPage.vue";
import RsaConfigurazioneProfiliLisListPage from "./pages/RsaConfigurazioneProfiliLisListPage.vue";
import RsaConfigurazioneProfiliLisViewPage from "./pages/RsaConfigurazioneProfiliLisViewPage.vue";
import RsaConfigurazioneProfiliLisEditPage from "./pages/RsaConfigurazioneProfiliLisEditPage.vue";
import RsaConfigurazioneAreeListPage from "./pages/RsaConfigurazioneAreeListPage.vue";
import RsaConfigurazioneAreeEditPage from "./pages/RsaConfigurazioneAreeEditPage.vue";
import RsaConfigurazioneAreeViewPage from "./pages/RsaConfigurazioneAreeViewPage.vue";
import RsaConfigurazioneAttivitaListPage from "./pages/RsaConfigurazioneAttivitaListPage.vue";
import RsaConfigurazioneAttivitaEditPage from "./pages/RsaConfigurazioneAttivitaEditPage.vue";
import RsaConfigurazioneAttivitaViewPage from "./pages/RsaConfigurazioneAttivitaViewPage.vue";
import RsaConfigurzioneLaboratoriListPage from "./pages/RsaConfigurzioneLaboratoriListPage.vue";
import RsaConfigurazioneLaboratoriEditPage from "./pages/RsaConfigurazioneLaboratoriEditPage.vue";
import RsaConfigurazioneSettingsViewPage from "./pages/RsaConfigurazioneSettingsViewPage.vue";
import RsaConfigurazioneSettingsListPage from "./pages/RsaConfigurazioneSettingsListPage.vue";
import RsaConfigurazioneTipoPrestazioniListPage from "./pages/RsaConfigurazioneTipoPrestazioniListPage.vue";
import RsaConfigurazioneTipoPrestazioniViewPage from "./pages/RsaConfigurazioneTipoPrestazioniViewPage.vue";
import RsaConfigurazionePrestazioniAmbulatorialiListPage from "./pages/RsaConfigurazionePrestazioniAmbulatorialiListPage.vue";
import RsaConfigurazioneSpeseExtraListPage from "./pages/RsaConfigurazioneSpeseExtraListPage.vue";
import RsaPaiRuoliListPage from "./pages/RsaPaiRuoliListPage.vue";
import RsaPaiSpecializzazioneListPage from "./pages/RsaPaiSpecializzazioneListPage.vue";
import RsaConfigurazioneDizionarioListPage from "./pages/RsaConfigurazioneDizionarioListPage.vue";
import RsaConfigurazioneParametriVitaliListPage from "./pages/RsaConfigurazioneParametriVitaliListPage.vue";
import RsaConfigurazioneImpegnativaGruppoPrestazioniListPage from "./pages/RsaConfigurazioneImpegnativaGruppoPrestazioniListPage.vue";
import RsaConfigurazioneTurniListPage from "./pages/RsaConfigurazioneTurniListPage.vue";

export default {
  RsaConfigurazioneTurniListPage,
  RsaConfigurazioneImpegnativaGruppoPrestazioniListPage,
  RsaConfigurazioneParametriVitaliListPage,
  RsaConfigurazioneDizionarioListPage,
  RsaPaiSpecializzazioneListPage,
  RsaPaiRuoliListPage,
  RsaConfigurazionePrestazioniAmbulatorialiListPage,
  RsaConfigurazioneTipoPrestazioniListPage,
  RsaConfigurazioneTipoPrestazioniViewPage,
  RsaConfigurazioneSettingsListPage,
  RsaConfigurazioneSettingsViewPage,
  RasConfigurazionePostiLettoPianiListPage,
  RasConfigurazionePostiLettoPianiEditPage,
  RasConfigurazionePostiLettoPianiViewPage,
  RasConfigurazionePostiLettoStanzeListPage,
  /* RasConfigurazionePostiLettoStanzeEditPage, */
  RsaConfigurazioneDiariListPage,
  RsaConfigurazioneDiariEditPage,
  RsaConfigurazioneProfiliLisListPage,
  RsaConfigurazioneProfiliLisViewPage,
  RsaConfigurazioneProfiliLisEditPage,
  RsaConfigurazioneAreeListPage,
  RsaConfigurazioneAreeEditPage,
  RsaConfigurazioneAreeViewPage,
  RsaConfigurazioneAttivitaListPage,
  RsaConfigurazioneAttivitaEditPage,
  RsaConfigurazioneAttivitaViewPage,
  RsaConfigurzioneLaboratoriListPage,
  RsaConfigurazioneLaboratoriEditPage,
  RsaConfigurazioneSpeseExtraListPage,
};
