<template>
    <div style="height: 100%">
        <b-tabs id="tabsSchede" nav-wrapper-class="sa-tab-items" content-class="mt-3" ref="tabs" class="sa-tab" style="height: 100%; padding-bottom: 25px" fill>
            <b-tab lazy title="Proposta di Accesso" active>
                <cot-proposta-accesso-modulo-edit-component :idPropostaAccesso="idPropostaAccesso" :utente="utente" :key="keyPropostaAccesso" ref="propostaAccesso" @showmodalloaging="onShowModalLoading" :linkback="linkback" :linkPrintData="linkPrintData" :jsonData="jsonData" @update="onUpdateJsonData"></cot-proposta-accesso-modulo-edit-component>
            </b-tab>
            <!--    <b-tab lazy title="Schede di Valutazione">
        <cot-proposta-accesso-schede-valutazione-component :idPropostaAccesso="idPropostaAccesso" @update="onUpdateJsonData"></cot-proposta-accesso-schede-valutazione-component>
      </b-tab>

       <b-tab title="Allegati" @click="activeAllegato()">
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="sa-padding-right text-right">
                        <b-button variant="outline-success btn-toolbar sa-margin-left float-sm-left" v-b-modal.mdlAllegati>
                            <b-icon icon="file-earmark-plus" aria-hidden="true"></b-icon>
                            Allegati
                        </b-button>
                    </b-col>
                </b-row>

                <allegato-model-component :idRisorsa="idPropostaAccesso" :jsonData="jsonData" @updateFiles="onUpdateFile()" :key="childKey" tipoRisorsa="COT"></allegato-model-component>
            </b-tab>
            <b-tab lazy title="P.A.I.">
                <b-card class="sa-card" header="PIANO DELLE AZIONI/INTERVENTI PROGRAMMATI - TABELLA RIEPILOGATIVA" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-form-textarea v-if="viewPai" rows="8"></b-form-textarea>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card class="sa-card" header="COMPONENTI DELL' UNITA' DI VALUTAZIONE INTEGRATA" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-form-textarea v-if="viewPai" rows="8"></b-form-textarea>
                        </b-col>
                    </b-row>
                </b-card>
            </b-tab> -->
        </b-tabs>
    </div>
</template>

<script>
// import Vue from "vue";
import CotPropostaAccessoModuloEditComponent from "./CotPropostaAccessoModuloEditComponent.vue";
// import CotPropostaAccessoSchedeValutazioneComponent from "./CotPropostaAccessoSchedeValutazioneComponent.vue";
// import AllegatoModelComponent from "../../utility/components/AllegatoModelComponent.vue";
export default {
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
        jsonData: {
            type: Object,
            default: function () {
                return {
                    identificativo: "",
                    cognome: "",
                    nome: "",
                    referenteCargiver: "",
                    altriInterventiNaturaSociosanitaria: false,
                    assistenzaStruttureSemiresidenzialiPersoneNonAutosufficien: false,
                    ricoveroStrutturaResidenzialeSocioSanitariaPazientiNonAu: false,
                    puaDistrettoSanitario: "",
                    ambitoSociale: "",
                    assistenzaDomiciliareEstemporanea: false,
                    assistenzaDomiciliareIntegrata: false,
                    assistenzaDomiciliareProgrammataMmg: false,
                    assistenzaDomiciliareSociale: false,
                    assistenzaDomiciliareDisabili: false,
                    ricoveroHospice: false,
                    accoglienzaCentroDiurnoSocioEducativoDisabili: false,
                    ricoveroStrutturaResidenzialeExtraospedalieraElevatoImpegn: false,
                    ricoveroStrutturaResidenzialePersoneDisabilita: false,
                    altro: false,
                    altroSpecificare: "",
                    comuneNascitaCodiceIstat: "",
                    dataNascita: null,
                    comuneResidenzaCodiceIstat: "",
                    indirizzoResidenza: "",
                    primaPatologiaDescrizione: "",
                    primaPatologiaCodiceIcdix: "",
                    primaPatologiaConxomitanteDescrizione: "",
                    primaPatologiaConxomitanteCodiceIcdix: "",
                    secondaPatologiaConxomitanteDescrizione: "",
                    secondaPatologiaConxomitanteCodiceIcdix: "",
                    situazioneCognitivaLucido: false,
                    situazioneCognitivaConfuso: false,
                    situazioneCognitivaMoltoConfuso: false,
                    problemiComportamentaliAsentiLievi: false,
                    problemiComportamentaliModerati: false,
                    problemiComportamentaliGravi: false,
                    situazioneFunzionaleAutonomoQuasi: false,
                    situazioneFunzionaleDipendente: false,
                    situazioneFunzionaleTotalmenteDipendente: false,
                    gradoAutonomiaSpostaSolo: false,
                    gradoAutonomiaSpostaAssistito: false,
                    gradoAutonomiaTotalmenteDipendente: false,
                    supportoReteSocialeBenAssistito: false,
                    supportoReteSocialePazialmenteAssistito: false,
                    supportoSocialeNonAssistito: false,
                    necessitaAssitenzaSanitariaBassa: false,
                    necessitaAssistenzaSanitariaIntermedia: false,
                    necessitaAssistenzaSanitariaElevata: false,
                    totalePunteggio: 0,
                    impossibilitaPermanenteDeambulare: false,
                    impossibilitaTrasportatoAmbulatorioCausa: false,
                    impossibilitaTrasportatoAmbulatorioCausaAutosufficiente: false,
                    impossibilitaTrasportatoAmbulatorioCausaAbitazionePianoAl: false,
                    impossibilitaEssereTrasportatoAmbulatorioMezziComuniCausa: false,
                    impossibilitaEssereTrasportatoAmbulatorioMezziComuniSpeci: "",
                    impossibileEssereTrasportatoGraviPatologie: false,
                    bisogniAssistenzialiRilevatiAutonomiaAutonomo: false,
                    bisogniAssistenzialiRilevatiAutonomiaParzialmenteAutonomo: false,
                    bisogniAssistenzialiRilevatiAutonomiaTotalmenteDipendente: false,
                    bisogniAssistenzialiRilevatiAutonomiaStatoVegetatitivo: false,
                    bisogniAssistenzialiRilevatiRiabilitazioneNessunaRiabilita: false,
                    bisogniAssistenzialiRilevatiRiabilitazioneRiabilitazioneNe: false,
                    bisogniAssistenzialiRilevatiRiabilitazioneAfasia: false,
                    bisogniAssistenzialiRilevatiRiabilitazioneRiabilitazioneOr: false,
                    bisogniAssistenzialiRilevatiApparatoRespiratorioNormale: false,
                    bisogniAssistenzialiRilevatiApparatoRespiratorioTosseSecr: false,
                    bisogniAssistenzialiRilevatiApparatoRespiratorioOssigenote: false,
                    bisogniAssistenzialiRilevatiApparatoRespiratorioVentiloter: false,
                    bisogniAssistenzialiRilevatiApparatoRespiratorioTracheotom: false,
                    bisogniAssistenzialiRilevatiStatoMutrizionaleNormale: false,
                    bisogniAssistenzialiRilevatiStatoMutrizionaleDimagrito: false,
                    bisogniAssistenzialiRilevatiStatoMutrizionaleNutrizioneDi: false,
                    bisogniAssistenzialiRilevatiComportamentoNormale: false,
                    bisogniAssistenzialiRilevatiComportamentoDisturboCognitivo: false,
                    bisogniAssistenzialiRilevatiComportamentoDisturboComportam: false,
                    bisogniAssistenzialiRilevatiComportamentoCondizioniSalute: false,
                    bisogniAssistenzialiRilevatiRitmoSonnoVegliaAlterato: false,
                    bisogniAssistenzialiRilevatiRitmoSonnoVegliaRischioInfet: false,
                    bisogniAssistenzialiRilevatiRitmoSonnoVegliaFebbre: false,
                    bisogniAssistenzialiRilevatiApparatoGastrointestinaleNorm: false,
                    bisogniAssistenzialiRilevatiApparatoGastrointestinaleInco: false,
                    bisogniAssistenzialiRilevatiApparatoGastrointestinaleVomi: false,
                    bisogniAssistenzialiRilevatiApparatoGastrointestinaleSang: false,
                    bisogniAssistenzialiRilevatiApparatoGenitoUrinarioNormale: false,
                    bisogniAssistenzialiRilevatiApparatoGenitoUrinarioInconti: false,
                    bisogniAssistenzialiRilevatiApparatoGenitoUrinarioCateter: false,
                    bisogniAssistenzialiRilevatiApparatoGenitoUrinarioUrostom: false,
                    bisogniAssistenzialiRilevatiApparatoTegumentarioNormale: false,
                    bisogniAssistenzialiRilevatiApparatoTegumentarioUlcereCP: false,
                    bisogniAssistenzialiRilevatiApparatoTegumentarioUlcereCT: false,
                    bisogniAssistenzialiRilevatiApparatoTegumentarioStatoGrav: false,
                    bisogniAssistenzialiRilevatiApparatoGastrointestinaleStom: false,
                    bisogniAssistenzialiRilevatiApparatoGastrointestinaleStip: false,
                    bisogniAssistenzialiRilevatiApparatoGastrointestinaleDiar: false,
                    bisogniAssistenzialiRilevatiApparatoGenitoUrinarioEmaturi: false,
                    bisogniAssistenzialiRilevatiApparatoGenitoUrinarioDialisi: false,
                    bisogniAssistenzialiRilevatiApparatoTegumentarioCuraFerit: false,
                    bisogniAssistenzialiRilevatiApparatoTegumentarioAltriProb: false,
                    bisogniAssistenzialiRilevatiApparatoTegumentarioLacerazion: false,
                    bisogniAssistenzialiRilevatiOncologicoNonOncologicoTermi: false,
                    bisogniAssistenzialiRilevatiOncologicoTerminaleNonOncolo: false,
                    bisogniAssistenzialiRilevatiOncologicoTerminaleOncologica: false,
                    bisogniAssistenzialiRilevatiOncologicoOncologico: false,
                    bisogniAssistenzialiRilevatiOncologicoChemioterapia: false,
                    bisogniAssistenzialiRilevatiOncologicoRadioterapia: false,
                    bisogniAssistenzialiRilevatiOncologicoDolorePresente: false,
                    bisogniAssistenzialiRilevatiPrestazioniPrelievoVenosoNon: false,
                    bisogniAssistenzialiRilevatiPrestazioniEcg: false,
                    bisogniAssistenzialiRilevatiPrestazioniTelemetria: false,
                    bisogniAssistenzialiRilevatiPrestazioniTrasfusioni: false,
                    bisogniAssistenzialiRilevatiPrestazioniTerapiaEndovena: false,
                    bisogniAssistenzialiRilevatiPrestazioniTerapiaIntramuscol: false,
                    bisogniAssistenzialiRilevatiPrestazioniGestioneCvc: false,
                    medicoMedicinaGeneraleNumeroSettimane: "",
                    medicoMedicinaGeneraleDal: null,
                    medicoMedicinaGeneraleAl: null,
                    medicoSpecialistaNumeroSettimane: "",
                    medicoSpecialistaDal: null,
                    medicoSpecialistaAl: null,
                    infermiereProfessionaleNumeroSettimane: "",
                    infermiereProfessionaleDal: null,
                    infermiereProfessionaleAl: null,
                    fisioterapistaLogopedistaTerapistaOccupazionaleNumeroSetti: "",
                    fisioterapistaLogopedistaTerapistaOccupazionaleDal: null,
                    fisioterapistaLogopedistaTerapistaOccupazionaleAl: null,
                    psicologoNumeroSettimane: "",
                    psicologoDal: null,
                    psicologoAl: null,
                    operatoreSociosanitarioNumeroSettimane: "",
                    operatoreSociosanitarioDal: null,
                    operatoreSociosanitarioAl: null,
                    bisogniSocioSanitariRilevati: "",
                    luogo: "",
                    // data: null,
                    medico: "",
                    statoAttuale: "",
                    statoAttualeDataOra: null,
                    telefono: "",
                    impossibilitaAltro: false,
                    statoNutrizionaleDisidratazione: false,
                    disturboCognitivoGrave: false,
                    sesso: "",
                    tesseraSanitaria: "",
                    cittadinanza: "",
                    capResidenza: "",
                    nazioneResidenzaCodiceIstat: "",
                    regioneResidenzaCodiceIstat: "",
                    email: "",
                    medicoCurante: "",
                    contattiMmg: "",
                    comuneDomicilioCodiceIstat: "",
                    capNascita: "",
                    capDomicilio: "",
                    indirizzoDomicilio: "",
                    prestazioneInterventiNaturaSociosanitaria: "",
                    numeroProtocollo: "",
                    dataProtocollo: null,
                    cotDestinazione: "",
                    rsaDestinazione: "",
                    provenienzaCodiceStruttura: "",
                    provinciaResidenza: "",
                    idSedePuaDestinazione: "",
                    descrizioneSedePuaDestinazione: "",
                };
            },
        },
    }, //
    components: { CotPropostaAccessoModuloEditComponent }, //, CotPropostaAccessoSchedeValutazioneComponent, AllegatoModelComponent
    data() {
        return {
            idPropostaAccesso: null,
            keyPropostaAccesso: 0,
            linkback: "",
            linkPrintData: "",
            childKey: 0,
            viewPai: true,
        };
    },

    // watch: {
    //   jsonData: {
    //     handler(jsonData) {
    //       this.$emit("update", jsonData);
    //     },
    //     deep: true,
    //   },
    // },
    methods: {
        activeAllegato() { },
        onUpdatePropostaAccesso() { },
        onShowModalLoading() { },
        onUpdateJsonData(data) {
            this.$emit("update", data);
        },
        onUpdateFile() {
            let me = this;
            me.childKey++;
        },
    },
};
</script>

<style></style>
