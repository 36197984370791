<template>
  <div class="login-box">
    <div class="login-logo">
      <img src="../../../assets/images/logo_login.png" alt="Logo" class="sa-logo-esteso" />
    </div>
    <div class="card">
      <div class="card-body login-card-body sa-box-login">
        <p class="login-box-msg">ACCEDI</p>
        <form @submit.prevent="onSubmit()">
          <label for="emailaddress">Nome Utente</label>
          <div class="input-group mb-3">
            <b-input class="form-control" placeholder="Inserisci il tuo nome utente" name="client_id" v-model="form.client_id" autocomplete="off" />
            <div class="input-group-append">
              <div class="input-group-text">
                <font-awesome-icon icon="user" />
              </div>
            </div>
          </div>
          <label for="emailaddress">Password</label>
          <div class="input-group mb-3">
            <b-input type="password" class="form-control" placeholder="Inserisci la tua Password" name="client_secret" v-model="form.client_secret" />
            <div class="input-group-append">
              <div class="input-group-text">
                <font-awesome-icon icon="lock" />
                <!--<span class="fas fa-lock"></span>-->
              </div>
            </div>
          </div>
          <br />
          <div class="row">
            <!-- /.col -->
            <div class="col-4"></div>
            <!-- /.col -->
          </div>
          <div class="social-auth-links text-center mb-3">
            <b-button type="submit" v-b-modal.modal-loading variant="btn btn-login btn-block">
              <font-awesome-icon icon="sign-in-alt" />&nbsp;&nbsp;&nbsp;Accedi
            </b-button>
          </div>
        </form>
        <!-- /.social-auth-links -->
        <b-row>
          <b-col style="text-align: center"><font-awesome-icon icon="lock" />
            <a class="sa-lnk" v-b-modal.modal-prevent-closing>Reset Password</a></b-col>
        </b-row>
        <!--   <b-col style="text-align: center" v-b-modal.modalRegistrazioneUtente
            ><font-awesome-icon icon="registered" />
            <a class="sa-lnk">Registrati</a></b-col
          >
        </b-row> -->
        <p class="mb-1"></p>
        <p class="mb-1"></p>
        <!--<p class="mb-0">
            <a href="register.html" class="text-center">Register a new membership</a>
        </p>-->
      </div>
      <!-- /.login-card-body -->
      <div>
        <p style="text-align: center; margin-top: 20px">2023 © Molisse</p>
        <!-- <p style="text-align: center; margin-top: 20px">2023 © Caseificio-Web</p> -->
        <!-- <p style="text-align: center; margin-top: 20px">2022 © GC Software</p> -->
        <p style="text-align: center; margin-top: 20px">
          powered by
          <a href="http://www.mc-soft.eu/" target="_blank" class="sa-gesan-link">MC-Soft Informatica</a>
          <!-- <a href="https://giovannicasale.ovh/" target="_blank" class="sa-gesan-link">Giovanni Casale</a> -->
        </p>
      </div>
    </div>
    <sa-modal-loading ref="sa-modal-loading" />
    <b-modal id="modalRegistrazioneUtente" no-close-on-backdrop ref="modalRegistrazione" size="lg" title="Registrazione Utente" header-bg-variant="light" header-border-variant="success" footer-border-variant="danger" @show="onRegistrazioneShow">
      <template #modal-footer="{}">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <h6>{{ tabIndex + 1 }}/3</h6>

        <b-button size="sm" variant="outline-danger" @click="onAnnulla()">
          Annulla
        </b-button>
        <b-button v-if="tabIndex == 1" size="sm" variant="outline-info" @click="tabIndex--">
          Indietro
        </b-button>
        <b-button v-if="testoModel === 'Registrati'" size="sm" :disabled="!isCondizioniContrattuali" variant="outline-success" @click="onRegistrati()">
          {{ testoModel }}
        </b-button>

        <b-button v-if="testoModel === 'Avanti'" size="sm" variant="outline-success" @click="tabIndex++">
          {{ testoModel }}
        </b-button>
      </template>
      <b-tabs id="tabs-registrazione" justified v-model="tabIndex">
        <b-tab active>
          <template #title>
            <b-icon icon="person-circle"></b-icon>
            User
          </template>
          <div class="form-group">
            <b-row>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <label for="user-username">Nome Utente</label>
                <b-input-group class="mb-2">
                  <b-input-group-prepend is-text>
                    <b-icon icon="person-fill"></b-icon>
                  </b-input-group-prepend>
                  <b-form-input id="user-username" v-model="user.username" type="text" placeholder="Nome Utente"></b-form-input>
                </b-input-group>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <label for="user-email">Email</label>
                <b-input-group class="mb-2">
                  <b-input-group-prepend is-text>
                    <b-icon icon="envelope"></b-icon>
                  </b-input-group-prepend>
                  <b-form-input id="user-email" v-model="user.email" type="email" placeholder="io@esempio.com"></b-form-input>
                </b-input-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <label for="user-password">Password</label>
                <b-input-group class="mb-2">
                  <b-input-group-prepend is-text>
                    <b-icon icon="lock-fill"></b-icon>
                  </b-input-group-prepend>
                  <b-form-input id="user-password" v-model="user.password" type="password" placeholder="Password"></b-form-input>
                </b-input-group>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <label for="user-confermapassword">Conferma Password</label>
                <b-input-group class="mb-2">
                  <b-input-group-prepend is-text>
                    <b-icon icon="lock-fill"></b-icon>
                  </b-input-group-prepend>
                  <b-form-input placeholder="Conferma password" id="user-confermapassword" v-model="user.verificaPassword" type="password"></b-form-input>
                </b-input-group>
              </b-col>
            </b-row>
            <br />
            <b-row>
              <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <b-form-checkbox button-variant="success" v-model="isProfiloPrivato" name="domicilio-residenza-coincidono" switch size="lg">Sono un'azienda</b-form-checkbox>
                <b-form-checkbox button-variant="success" v-model="isCondizioniContrattuali" name="condizioni" switch size="lg">Accetto le condizioni Contrattuali</b-form-checkbox>
              </b-col>
            </b-row>
          </div>
        </b-tab>
        <b-tab :disabled="isProfiloPrivato">
          <template #title>
            <b-icon icon="person"></b-icon>
            Anagrafica
          </template>
          <div class="form-group">
            <b-row>
              <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <label for="anagrafica-nazionalita">Nazionalita'</label>
                <b-input-group class="mb-2">
                  <b-input-group-prepend is-text>
                    <b-icon icon="flag-fill"></b-icon>
                  </b-input-group-prepend>
                  <b-form-select id="anagrafica-nazionalita" v-model="anagrafica.nazione" :options="nazionalitaOptions" value-field="codiceIstat" text-field="comune" :value="null" @input="onNazioneInput">
                    <template #first>
                      <b-form-select-option :value="null" disabled>-- Seleziona Nazionalita' --</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-input-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                <label for="anagrafica-tipoidentificativo">Tipo Documento</label>
                <b-input-group class="mb-2">
                  <b-input-group-prepend is-text>
                    <b-icon icon="person-badge-fill"></b-icon>
                  </b-input-group-prepend>
                  <b-form-select id="anagrafica-tipoidentificativo" v-model="anagrafica.tipoDocumento" :options="tipoDocumentoOptions" value-field="value" text-field="text">
                    <template #first>
                      <b-form-select-option :value="null">
                        - Selezionare Tipo Identificativo -
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </b-input-group>
              </b-col>
              <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                <label for="anagrafica-nome">Numero Documento</label>
                <b-input-group class="mb-2">
                  <b-input-group-prepend is-text>
                    <b-icon icon="person-badge-fill"></b-icon>
                  </b-input-group-prepend>
                  <b-form-input id="anagrafica-nome" v-model="anagrafica.numeroDocumento" type="text"></b-form-input>
                </b-input-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                <label for="anagrafica-tipoidentificativo">Tipo Identificativo</label>
                <b-input-group class="mb-2">
                  <b-input-group-prepend is-text>
                    <b-icon icon="person-badge-fill"></b-icon>
                  </b-input-group-prepend>
                  <b-form-select id="anagrafica-tipoidentificativo" :disabled="isDisabledTipoIdentificativo" v-model="anagrafica.tipoIdentificativo" :options="tipoIdentificativoOptions" value-field="value" text-field="text">
                    <template #first>
                      <b-form-select-option :value="null" disabled>-- Seleziona Tipo Documento --</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-input-group>
              </b-col>
              <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                <label for="anagrafica-nome">Identificativo</label>
                <b-input-group class="mb-2">
                  <b-input-group-prepend is-text>
                    <b-icon icon="person-badge-fill"></b-icon>
                  </b-input-group-prepend>
                  <b-form-input id="anagrafica-nome" v-model="anagrafica.identificativo" type="text"></b-form-input>
                </b-input-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                <label for="anagrafica-cognome">Cognome</label>
                <b-input-group class="mb-2">
                  <b-input-group-prepend is-text>
                    <b-icon icon="person-fill"></b-icon>
                  </b-input-group-prepend>
                  <b-form-input id="anagrafica-cognome" v-model="anagrafica.cognome" type="text"></b-form-input>
                </b-input-group>
              </b-col>
              <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                <label for="anagrafica-nome">Nome</label>
                <b-input-group class="mb-2">
                  <b-input-group-prepend is-text>
                    <b-icon icon="person-fill"></b-icon>
                  </b-input-group-prepend>
                  <b-form-input id="anagrafica-nome" v-model="anagrafica.nome" type="text"></b-form-input>
                </b-input-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                <label for="anagrafica-sesso">Sesso</label>
                <b-input-group class="mb-2">
                  <b-input-group-prepend is-text>
                    <b-icon icon="person-fill"></b-icon>
                  </b-input-group-prepend>
                  <b-form-select id="anagrafica-sesso" :options="sessoOption" v-model="anagrafica.sesso" type="text">
                    <template #first>
                      <b-form-select-option :value="null" disabled>-- Seleziona Sesso --</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-input-group>
              </b-col>
              <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                <label for="anagrafica-datanascita">Data di Nascita</label>
                <date-picker id="anagrafica-datanascita" placeholder="DD-MM-YYYY" style="display: block; width: 100%" format="DD-MM-YYYY" value-type="timestamp" v-model="anagrafica.dataNascita" type="date"></date-picker>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="6" md="6" lg="6" xl="6">
                <label for="anagrafica-provincianascita">Provincia di Nascita</label>
                <b-input-group class="mb-2">
                  <b-input-group-prepend is-text>
                    <b-icon icon="building"></b-icon>
                  </b-input-group-prepend>
                  <b-form-select id="anagrafica-provincianascita" v-model="anagrafica.provinciaNascitaCodiceIstat" :options="provinceNascitaOptions" type="text" text-field="provincia" value-field="sigla" @input="onProvinciaNascitaInput">
                    <template #first>
                      <b-form-select-option :value="null" disabled>-- Seleziona Provincia Nascita --</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-input-group>
              </b-col>
              <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                <label for="anagrafica-comunenascita">Comune di Nascita</label>
                <b-input-group class="mb-2">
                  <b-input-group-prepend is-text>
                    <b-icon icon="building"></b-icon>
                  </b-input-group-prepend>
                  <b-form-select id="anagrafica-comunenascita" :options="comuniNascitaOptions" v-model="anagrafica.comuneNascitaCodiceIstat" type="text" text-field="comune" value-field="codiceIstat">
                    <template #first>
                      <b-form-select-option :value="null" disabled>-- Seleziona Comune Nascita --</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-input-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                <label for="anagrafica-provinciaresidenza">Provincia di Residenza</label>
                <b-input-group class="mb-2">
                  <b-input-group-prepend is-text>
                    <b-icon icon="house-door-fill"></b-icon>
                  </b-input-group-prepend>
                  <b-form-select id="anagrafica-provinciaresidenza" v-model="anagrafica.provinciaResidenzaCodiceIstat" :options="provinceResidenzaOptions" type="text" text-field="provincia" value-field="sigla" @input="onProvinciaResidenzaInput">
                    <template #first>
                      <b-form-select-option :value="null" disabled>-- Seleziona Provincia Residenza
                        --</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-input-group>
              </b-col>
              <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                <label for="anagrafica-comuneresidenza">Comune di Residenza</label>
                <b-input-group class="mb-2">
                  <b-input-group-prepend is-text>
                    <b-icon icon="house-door-fill"></b-icon>
                  </b-input-group-prepend>
                  <b-form-select id="anagrafica-comuneresidenza" :options="comuniResidenzaOptions" v-model="anagrafica.comuneResidenzaCodiceIstat" type="text" text-field="comune" value-field="codiceIstat" @input="onComuneResidenzaInput">
                    <template #first>
                      <b-form-select-option :value="null" disabled>-- Seleziona Comune Residenza --</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-input-group>
              </b-col>
            </b-row>
            <template v-if="anagrafica.provinciaResidenzaCodiceIstat !== null">
              <b-row>
                <b-col xs="12" sm="9" md="9" lg="9" xl="9">
                  <label for="anagrafica-indirizzo">Indirizzo</label>
                  <b-input-group class="mb-2">
                    <b-input-group-prepend is-text>
                      <b-icon icon="door-closed-fill"></b-icon></b-input-group-prepend>
                    <b-form-input id="anagrafica-indirizzo" v-model="anagrafica.indirizzoResidenza" type="text"></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                  <label for="anagrafica-civico">Civico</label>
                  <b-form-input id="anagrafica-frazionedomicilio" v-model="anagrafica.civicoResidenza" type="text"></b-form-input>
                </b-col>

                <b-col xs="12" sm="6" md="9" lg="9" xl="9">
                  <label for="anagrafica-frazionedomicilio">Frazione</label>
                  <b-input-group class="mb-2">
                    <b-input-group-prepend is-text>
                      <b-icon icon="building"></b-icon></b-input-group-prepend>
                    <b-form-input id="anagrafica-frazionedomicilio" v-model="anagrafica.frazioneResidenza" type="text"></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col xs="12" sm="6" md="3" lg="3" xl="3">
                  <label for="anagrafica-capresidenza">C.A.P</label>
                  <b-form-input id="anagrafica-capresidenza" v-model="anagrafica.capResidenza" type="text"></b-form-input>
                </b-col>
              </b-row>
            </template>

            <b-form-checkbox button-variant="success" v-model="checkDomicilioUgualeResidenza" name="domicilio-residenza-coincidono" switch size="lg">
              Domicilio e Residenza Coincidono ?
            </b-form-checkbox>
            <template v-if="!checkDomicilioUgualeResidenza">
              <b-row>
                <b-col sm="6" md="6" lg="6" xl="6">
                  <label for="anagrafica-provinciadomicilio">Provincia di Domicilio</label>
                  <b-input-group class="mb-2">
                    <b-input-group-prepend is-text>
                      <b-icon icon="house-fill"></b-icon>
                    </b-input-group-prepend>
                    <b-form-select id="anagrafica-provinciadomicilio" v-model="anagrafica.provinciaDomicilioCodiceIstat" :options="provinceDomicilioOptions" type="text" text-field="provincia" value-field="sigla" @input="onProvinciaDomicilioInput">
                      <template #first>
                        <b-form-select-option :value="null" disabled>-- Seleziona Provincia Domicilio
                          --</b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-input-group>
                </b-col>
                <b-col sm="6" md="6" lg="6" xl="6">
                  <label for="anagrafica-comunedomicilio">Comune di Domicilio</label>
                  <b-input-group class="mb-2">
                    <b-input-group-prepend is-text>
                      <b-icon icon="house-fill"></b-icon>
                    </b-input-group-prepend>
                    <b-form-select id="anagrafica-comunedomicilio" :options="comuniDomicilioOptions" v-model="anagrafica.comuneDomicilioCodiceIstat" type="text" text-field="comune" value-field="codiceIstat" @input="onComuneDomicilioInput">
                      <template #first>
                        <b-form-select-option :value="null" disabled>-- Seleziona Comune Domicilio
                          --</b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-input-group>
                </b-col>
              </b-row>
              <template v-if="anagrafica.provinciaDomicilioCodiceIstat !== null">
                <b-row>
                  <b-col xs="12" sm="9" md="9" lg="9" xl="9">
                    <label for="anagrafica-indirizzodomicilio">Indirizzo</label>
                    <b-input-group class="mb-2">
                      <b-input-group-prepend is-text>
                        <b-icon icon="door-closed-fill"></b-icon></b-input-group-prepend>
                      <b-form-input id="anagrafica-indirizzodomicilio" v-model="anagrafica.indirizzoDomicilio" type="text"></b-form-input>
                    </b-input-group>
                  </b-col>

                  <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <label for="anagrafica-civico">Civico</label>
                    <b-form-input id="anagrafica-frazionedomicilio" v-model="anagrafica.civicoDomicilio" type="text"></b-form-input>
                  </b-col>

                  <b-col xs="12" sm="6" md="9" lg="9" xl="9">
                    <label for="anagrafica-frazionedomicilio">Frazione</label>
                    <b-input-group class="mb-2">
                      <b-input-group-prepend is-text>
                        <b-icon icon="building"></b-icon></b-input-group-prepend>
                      <b-form-input id="anagrafica-frazionedomicilio" v-model="anagrafica.frazioneDomicilio" type="text"></b-form-input>
                    </b-input-group>
                  </b-col>
                  <b-col xs="12" sm="6" md="3" lg="3" xl="3">
                    <label for="anagrafica-capdomicilio">C.A.P</label>
                    <b-form-input id="anagrafica-capdomicilio" v-model="anagrafica.capDomicilio" type="text"></b-form-input>
                  </b-col>
                </b-row>
              </template>
            </template>
            <b-row>
              <b-col sm="6" md="6" lg="6" xl="6">
                <label for="anagrafica-cellulare">Cellulare</label>
                <b-input-group class="mb-2">
                  <b-input-group-prepend is-text>
                    <b-icon icon="phone-fill"></b-icon>
                  </b-input-group-prepend>
                  <b-form-input id="anagrafica-cellulare" v-model="anagrafica.cellulare" type="text"></b-form-input>
                </b-input-group>
              </b-col>
              <b-col sm="6" md="6" lg="6" xl="6">
                <label for="anagrafica-email">Email</label>
                <b-input-group class="mb-2">
                  <b-input-group-prepend is-text>
                    <b-icon icon="envelope"></b-icon>
                  </b-input-group-prepend>
                  <b-form-input id="anagrafica-email" placeholder="io@esempio.it" v-model="anagrafica.email" type="text"></b-form-input> </b-input-group></b-col>
            </b-row>
          </div>
        </b-tab>
        <b-tab :disabled="!isProfiloPrivato">
          <template #title>
            <b-icon icon="person-circle"></b-icon>
            Azienda
          </template>
          <div class="form-group">
            <ente-edit-component :jsonData="ente"></ente-edit-component>
          </div>
        </b-tab>
      </b-tabs>
    </b-modal>
    <div>
      <b-modal id="modal-prevent-closing" ref="modal" title="Inserisci il tuo indirizzo email" @show="onResetModal" @hidden="onResetModal" @ok="onOk">
        <form ref="form" @submit.stop.prevent="onResetPasswordSubmit">
          <b-form-group label="Email" label-for="email-input" invalid-feedback="L'indirizzo email è obbligatorio" :state="emailState">
            <b-form-input placeholder="Email" id="email-input" v-model="email" :state="emailState" required>
            </b-form-input>
          </b-form-group>
        </form>
      </b-modal>
    </div>
    <div>
      <b-modal ref="mdlCambiaPassword" no-close-on-backdrop centered :title="titolo" hide-footer>
        <form ref="form" @submit.prevent="onCambiaPasswordSubmit">
          <b-form-group label="Vecchia Password" label-for="email-input" invalid-feedback="L'indirizzo email è obbligatorio">
            <b-form-input placeholder="Vecchia Password" type="password" id="email-input" v-model="nuoveCredenziali.oldPassword" required>
            </b-form-input>
          </b-form-group>
          <b-form-group label="Nuova Password" label-for="email-input" invalid-feedback="L'indirizzo email è obbligatorio">
            <b-form-input placeholder="Nuova Password" type="password" id="email-input" v-model="nuoveCredenziali.newPassword" required>
            </b-form-input>
          </b-form-group>
          <b-form-group label="Verifica Password" label-for="email-input" invalid-feedback="L'indirizzo email è obbligatorio">
            <b-form-input placeholder="Verifica Password" type="password" id="email-input" v-model="nuoveCredenziali.verificaPassword" required>
            </b-form-input>
          </b-form-group>
          <b-row>
            <b-col cols="12" sm="12" class="sa-text-right"><b-button size="sm" variant="outline-secondary" type="submit">
                Aggiorna Password
              </b-button></b-col>
          </b-row>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";

import AuthBg from "../../../assets/images/sfondo.jpg";
import qs from "qs";
import axios from "axios";
import router from "../../../router";
import { SaModalLoading } from "../../utility";
import EnteEditComponent from "../../enti/components/EnteEditComponent.vue";
export default {
  meta: { bgImage: AuthBg },
  components: { SaModalLoading, DatePicker, EnteEditComponent },
  data() {
    return {
      isProfiloPrivato: false,
      checkDomicilioUgualeResidenza: false,
      isDisabledTipoIdentificativo: false,
      tabIndex: 0,
      ente: {},
      jsonData: {},
      isCondizioniContrattuali: false,
      user: {},
      pathResource: "/registrazione",
      testoModel: "Avanti",
      sessoOption: [
        { text: "Maschio", value: "M" },
        { text: "Femmina", value: "F" },
      ],
      nazionalitaOptions: [],
      comuniResidenzaOptions: [],
      comuniDomicilioOptions: [],
      comuniNascitaOptions: [],

      provinceResidenzaOptions: [],
      provinceDomicilioOptions: [],
      provinceNascitaOptions: [],

      tipoIdentificativoOptions: [
        { text: "Carta D'Identita'", value: "CI" },
        { text: "Codice Fiscale", value: "CF" },
        { text: "Passaporto", value: "PS" },
      ],
      tipoDocumentoOptions: [
        { text: "Carta D'Identita'", value: "CI" },
        { text: "Passaporto", value: "PS" },
      ],

      anagrafica: {
        /*DataBase*/
        provinciaDomicilioCodiceIstat: null,
        provinciaNascitaCodiceIstat: null,
        provinciaResidenzaCodiceIstat: null,
        capDomicilio: null,
        capResidenza: null,
        cellulare: null,
        codiceStruttura: null,
        comuneDomicilioCodiceIstat: null,
        comuneResidenzaCodiceIstat: null,
        comuneNascitaCodiceIstat: null,
        email: null,
        identificativo: null,
        indirizzoDomicilio: null,
        indirizzoResidenza: null,
        nazione: null,
        cognome: null,
        sesso: null,
        dataNascita: null,
        tipoDocumento: null,
        tipoIdentificativo: null,
      },
      form: {
        client_id: "",
        client_secret: "",
      },
      show: true,
      emailState: false,
      email: "",
      nuoveCredenziali: {},
      titolo: "",
    };
  },
  mounted() {
    document.body.classList.add("hold-transition");
    document.body.classList.add("login-page");
    document.body.style.backgroundImage = "url(" + AuthBg + ")";
  },
  destroyed() {
    document.body.classList.remove("hold-transition");
    document.body.classList.remove("login-page");
  },
  watch: {
    // isProfiloPrivato: function (value) {
    //   if (value) {
    //     this.pathResource = "/registrazione/ente";
    //   } else {
    //     this.pathResource = "/registrazione";
    //   }
    // },

    tabIndex: function (value) {
      let me = this;
      if (value === 1) {
        me.testoModel = "Registrati";
      } else if (value === 2) {
        me.testoModel = "Registrati";
      } else if (value === 0) {
        me.testoModel = "Avanti";
      }
    },
    checkDomicilioUgualeResidenza: function (value) {
      let me = this;
      if (value) {
        me.anagrafica.provinciaDomicilioCodiceIstat =
          me.anagrafica.provinciaResidenzaCodiceIstat;
        me.anagrafica.comuneDomicilioCodiceIstat =
          me.anagrafica.comuneResidenzaCodiceIstat;
        me.anagrafica.indirizzoDomicilio = me.anagrafica.indirizzoResidenza;
        me.anagrafica.capDomicilio = me.anagrafica.capResidenza;
        me.anagrafica.frazioneDomicilio = me.anagrafica.frazioneResidenza;
        me.anagrafica.civicoDomicilio = me.anagrafica.civicoResidenza;
      } else {
        me.anagrafica.provinciaDomicilioCodiceIstat = null;
        me.anagrafica.comuneDomicilioCodiceIstat = null;
        me.anagrafica.indirizzoDomicilio = null;
        me.anagrafica.capDomicilio = null;
        me.anagrafica.frazioneDomicilio = null;
        me.anagrafica.civicoDomicilio = null;
      }
    },
  },
  computed: {},
  methods: {
    onAnnulla() {
      let me = this;
      me.$refs["modalRegistrazione"].hide();
      me.anagrafica = {
        provinciaDomicilioCodiceIstat: null,
        provinciaNascitaCodiceIstat: null,
        provinciaResidenzaCodiceIstat: null,
        capDomicilio: null,
        capResidenza: null,
        cellulare: null,
        codiceStruttura: null,
        comuneDomicilioCodiceIstat: null,
        comuneResidenzaCodiceIstat: null,
        comuneNascitaCodiceIstat: null,
        email: null,
        identificativo: null,
        indirizzoDomicilio: null,
        indirizzoResidenza: null,
        nazione: null,
        cognome: null,
        sesso: null,
        dataNascita: null,
        tipoIdentificativo: "CF",
      };
    },
    onResetPasswordSubmit() {
      //this.submittedEmail.push(this.email)
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },
    onCambiaPasswordSubmit() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/users/cambiapassword";
      axios.defaults.headers["Content-Type"] = "application/json";
      axios
        .put(link, JSON.stringify(me.nuoveCredenziali))
        .then(() => {
          this.$bvToast.toast("Operazione avvenuta correttamente!", {
            title: "",
            variant: "success",
            solid: true,
          });
          me.$refs.mdlCambiaPassword.hide();
        })
        .catch((error) => {
          me.showmolalloading = false;
          this.$bvModal
            .msgBoxOk(error.response.data.messaggio, {
              title: "ATTENZIONE",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "sa-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then((value) => {
              this.boxTwo = value;
            })
            .catch((err) => {
              console.log(err);
            });
        });
    },
    onRegistrati() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource;
      me.$refs["sa-modal-loading"].show();
      let returnedTarget = {};
      if (!me.isProfiloPrivato) {
        returnedTarget = Object.assign(me.user, me.anagrafica);
      } else {
        returnedTarget = Object.assign(me.user, me.ente);
        link += "/ente";
      }
      axios
        .post(link, returnedTarget)
        .then((response) => {
          console.log(response.data.data);
          me.$refs["sa-modal-loading"].close();
          me.$refs["modalRegistrazione"].hide();
          this.$bvToast.toast("Registrazione Avvenuta con Successo", {
            title: "Laboratori Italiani Riuniti s.p.a.",
            variant: "success",
            appendToast: false,
            autoHideDelay: 5000,
            noCloseButton: true,
          });
        })
        .catch((error) => {
          let messaggio = this.$i18n.t("Errore Sconosciuto");
          if (error.response.data.messaggio) {
            messaggio = error.response.data.messaggio;
          }
          me.$refs["sa-modal-loading"].close();
          this.$bvModal
            .msgBoxOk(messaggio, {
              title: this.$i18n.t("ATTENZIONE!"),
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "sa-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then((value) => {
              this.boxTwo = value;
            })
            .catch((err) => {
              console.log(err);
            });
        });
    },
    onComuneResidenzaInput(value) {
      let me = this;
      me.loadCAP("CAPRESIDENZA", value);
    },
    onComuneDomicilioInput(value) {
      let me = this;
      me.loadCAP("CAPDOMICILIO", value);
    },
    onNazioneInput(value) {
      let me = this;
      if (value === "111100") {
        me.isDisabledTipoIdentificativo = true;
        me.anagrafica.tipoIdentificativo = "CF";
        me.anagrafica.tipoDocumento = "CI";
      } else {
        me.isDisabledTipoIdentificativo = false;
        me.anagrafica.tipoIdentificativo = null;
        me.anagrafica.tipoDocumento = null;
      }
    },
    loadDefaultData() {
      let me = this;
      let linkNazioni = process.env.VUE_APP_PATH_API + "/nazioni";
      axios.get(linkNazioni).then((response) => {
        me.nazionalitaOptions = response.data.data;
      });
      let linkProvince = process.env.VUE_APP_PATH_API + "/province";
      axios.get(linkProvince).then((response) => {
        response.data.data.forEach((elemento) => {
          me.provinceNascitaOptions.push(elemento);
          me.provinceResidenzaOptions.push(elemento);
          me.provinceDomicilioOptions.push(elemento);
        });
      });
    },

    loadCAP(tipo, codiceIstatComune) {
      let me = this;
      switch (tipo) {
        case "CAPDOMICILIO":
          me.comuniDomicilioOptions.forEach((element) => {
            if (element.codiceIstat === codiceIstatComune) {
              me.anagrafica.capDomicilio = element.cap;
            }
          });
          break;
        case "CAPRESIDENZA": {
          me.comuniResidenzaOptions.forEach((element) => {
            if (element.codiceIstat === codiceIstatComune) {
              me.anagrafica.capResidenza = element.cap;
            }
          });
          break;
        }
      }
    },
    loadComuni(tipo, provincia) {
      let me = this;
      let linkComuni = process.env.VUE_APP_PATH_API + "/comuni";
      axios
        .get(linkComuni, { params: { provincia: provincia } })
        .then((response) => {
          switch (tipo) {
            case "NASCITA":
              me.comuniNascitaOptions = response.data.data;

              break;
            case "RESIDENZA":
              me.comuniResidenzaOptions = response.data.data;

              break;
            case "DOMICILIO":
              me.comuniDomicilioOptions = response.data.data;

              break;
          }
        });
    },
    onProvinciaNascitaInput(value) {
      let me = this;
      me.loadComuni("NASCITA", value);
    },
    onProvinciaResidenzaInput(value) {
      let me = this;
      me.loadComuni("RESIDENZA", value);
    },
    onProvinciaDomicilioInput(value) {
      let me = this;
      me.loadComuni("DOMICILIO", value);
    },
    onResetModal() {
      this.email = "";
      this.emailState = null;
    },
    onRegistrazioneShow() {
      let me = this;
      me.loadDefaultData();
    },
    onSubmit() {
      let me = this;
      let unencodedToken = me.form.client_id + ":" + me.form.client_secret;
      let encodedToken = "Basic " + btoa(unencodedToken);
      me.$refs["sa-modal-loading"].show();
      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: encodedToken,
        },
      };
      let uri = process.env.VUE_APP_PATH_API + "/token";
      axios
        .post(
          uri,
          qs.stringify({
            grant_type: "client_credentials",
            client_id: me.form.client_id,
            client_secret: me.form.client_secret,
          }),
          config
        )
        .then((response) => {
          me.$refs["sa-modal-loading"].close();
          localStorage.setItem("loginData", JSON.stringify(response.data));
          router.push({
            name: "Home",
            query: {
              language: me.locale,
              authToken: response.data.access_token,
            },
          });
        })
        .catch((error) => {
          me.$refs["sa-modal-loading"].close();
          if (error.response.data.data) {
            let tipoErrore = error.response.data.data.tipoErrore;
            switch (tipoErrore) {
              case "PASSWORD_EXPIRED":
                me.titolo = "Password Scaduta";
                me.nuoveCredenziali.username = me.form.client_id;
                me.$refs.mdlCambiaPassword.show();
                break;
              case "PASSWORD_TEMPORANY":
                me.nuoveCredenziali.username = me.form.client_id;
                me.titolo = "Password Temporanea, si prega di cambiarla";
                me.$refs.mdlCambiaPassword.show();
                break;
            }
          } else {
            let messaggio = this.$i18n.t("Errore Sconosciuto");
            if (error.response.data.messaggio) {
              messaggio = error.response.data.messaggio;
            }
            this.$bvModal
              .msgBoxOk(messaggio, {
                title: this.$i18n.t("ATTENZIONE!"),
                size: "sm",
                okVariant: "outline-danger",
                headerClass: "sa-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
              })
              .then((value) => {
                this.boxTwo = value;
              })
              .catch((err) => {
                console.log(err);
              });
          }
        });
    },
    onOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      // if (!this.checkFormValidity()) {
      //   return;
      // }
      let me = this;
      let link =
        process.env.VUE_APP_PATH_API + "/users/resetpassword?email=" + me.email;
      axios
        .put(link, {})
        .then((response) => {
          me.$refs["sa-modal-loading"].close();
          this.$bvModal
            .msgBoxOk(response.data.messaggio, {
              title: "INFO",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "sa-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then((value) => {
              this.boxTwo = value;
            })
            .catch((err) => {
              console.log(err);
            });
          this.$bvModal.hide("modal-prevent-closing");
        })
        .catch((error) => {
          me.$refs["sa-modal-loading"].close();
          this.$bvModal.msgBoxOk(error.response.data.messaggio, {
            title: "ATTENZIONE",
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "sa-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
          });
        });
    },
  },
};
</script>
<style lang="css">
a {
  /*color: #ac1f3a !important;*/
  text-decoration: none !important;
  background-color: transparent !important;
  font-weight: 600;
}

.login-page {
  /*background-image: url("../../assets/images/sfondo.jpg");*/
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.login-page,
.register-page {
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100vh;
  -ms-flex-pack: center;
  justify-content: center;
}

.login-box,
.register-box {
  width: 468px;
}

.sa-logo-esteso {
  width: 100%;
}

.card {
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
  margin-bottom: 1rem;
}

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.sa-lnk {
  color: #8fb8c7;
}

.btn-login.btn:focus,
.btn-login.btn.focus {
  outline: 0;
  box-shadow: none;
}

.sa-box-login {
  box-shadow: 0 8px 10px 1px rgba(153, 153, 153, 0.14),
    0 3px 14px 2px rgba(221, 220, 220, 0.12),
    0 5px 5px -3px rgba(247, 247, 247, 0.3);
  /*box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.3);*/
  border-radius: 0.5rem;
}

@media only screen and (min-width: 0) {

  .login-box,
  .register-box {
    width: 268px;
  }
}

@media only screen and (min-width: 768px) {

  .login-box,
  .register-box {
    width: 368px;
  }
}

#tabs-registrazione {
  margin-top: -12px;
}
</style>
