<template>
    <div class="sa-tab-scroll">
        <b-card v-if="isPercezioneSensoriale" class="sa-card sa-card-border" header="PERCEZIONE SENSORIALE" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaPercezioneSensoriale" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header"> </b-table>
        </b-card>
        <b-card v-if="isUmidita" class="sa-card sa-card-border" header="UMIDITA'" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaUmidita" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header"> </b-table>
        </b-card>
        <b-card v-if="isAttivita" class="sa-card sa-card-border" header="ATTIVITA'" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaAttivita" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header"> </b-table>
        </b-card>
        <b-card v-if="isMobilita" class="sa-card sa-card-border" header="MOBILITA'" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaMobilita" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header"> </b-table>
        </b-card>
        <b-card v-if="isNutrizione" class="sa-card sa-card-border" header="NUTRIZIONE" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaNutrizione" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header"> </b-table>
        </b-card>
        <b-card v-if="isAttritoFrizione" class="sa-card sa-card-border" header="ATTRITO E FRIZIONE" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaAttritoFrizione" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header"> </b-table>
        </b-card>
        <b-card class="sa-card sa-card-border" header="Totale" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <span class="sa-label-data">{{ totale }}</span>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import axios from "axios";
export default {
    components: {},
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    watch: {},
    data() {
        return {
            idAccettazione: null,
            keyPropostaAccesso: 0,
            linkback: "",
            linkPrintData: "",
            childKey: 0,
            viewPai: true,
            pathResource: "/rsascalabradenrichieste",
            totale: 0,
            isAttivita: false,
            isAttritoFrizione: false,
            isMobilita: false,
            isNutrizione: false,
            isPercezioneSensoriale: false,
            isUmidita: false,
            jsonData: [],
            listaPercezioneSensoriale: [],
            listaUmidita: [],
            listaAttivita: [],
            listaMobilita: [],
            listaNutrizione: [],
            listaAttritoFrizione: [],
            fieldsListe: [
                {
                    label: "Descrizione",
                    key: "fattore",
                    sortable: true,
                },
            ],
        };
    },
    mounted() {
        let me = this;
        if (this.$route.params.id) {
            me.idAccettazione = this.$route.params.id;
        } else {
            me.idAccettazione = this.$route.query.idPaziente;
        }
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/richiesta/" + me.idAccettazione;
            axios
                .get(link)
                .then((response) => {
                    me.jsonData = response.data.data.list;
                    me.calcolaPunteggio();
                    me.jsonData.forEach((element) => {
                        if (element.check) {
                            me.jsonData.push({ idRichiesta: me.idAccettazione, idScalaBraden: element.id });

                            switch (element.gruppo) {
                                case "PERCEZIONE SENSORIALE":
                                    me.isPercezioneSensoriale = true;
                                    me.listaPercezioneSensoriale.push(element);
                                    break;
                                case "UMIDITA":
                                    me.isUmidita = true;
                                    me.listaUmidita.push(element);
                                    break;
                                case "ATTIVITA":
                                    me.isAttivita = true;
                                    me.listaAttivita.push(element);
                                    break;
                                case "MOBILITA":
                                    me.isMobilita = true;
                                    me.listaMobilita.push(element);
                                    break;
                                case "NUTRIZIONE":
                                    me.isNutrizione = true;
                                    me.listaNutrizione.push(element);
                                    break;
                                case "ATTRITO E FRIZIONE":
                                    me.isAttritoFrizione = true;
                                    me.listaAttritoFrizione.push(element);
                                    break;
                            }
                        }
                    });
                })
                .catch(() => {});
        },
        // unisciArray() {
        //     let me = this;
        //     me.jsonData = [];
        //     me.jsonData = me.listaPercezioneSensoriale.concat(me.listaUmidita).concat(me.listaAttivita).concat(me.listaMobilita).concat(me.listaNutrizione).concat(me.listaAttritoFrizione);
        //     let lista = [];
        //     me.jsonData.forEach((element) => {
        //         if (element.check) {
        //             lista.push({ idRichiesta: me.idAccettazione, idScalaBraden: element.id });
        //         }
        //     });
        //     me.calcolaPunteggio();
        //     //   console.log(lista);
        //     this.$emit("update", lista);
        // },
        calcolaPunteggio() {
            let me = this;
            me.totale = 0;

            me.jsonData.forEach((element) => {
                if (element.check) {
                    let p = element.punteggio >= 0 ? element.punteggio : element.punteggio * -1;
                    me.totale = me.totale + p;
                }
            });
        },

        setListe() {},
    },
};
</script>

<style></style>
