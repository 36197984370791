<template>
    <div class="sa-list-component">
        <div class="sa-list-component-header">
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </div>
        <div class="sa-list-component-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header responsive stacked="lg" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                    <template v-slot:cell(titolo)="{ item }">
                        <router-link class="sa-edit-link" :to="'/agende/view/' + item.id">{{ item.titolo }}</router-link>
                    </template>
                    <template v-slot:cell(actions)="row">
                        <b-container class="bv-example-row">
                            <b-button v-if="btnEditAgendaVisible" v-b-tooltip.hover size="sm" variant="outline-secondary" title="Modifica Agenda" @click="onEdit(row.item, row.index, $event.target)" class="mr-1 no-text">
                                <b-icon icon="pencil" variant="outline-secondary"></b-icon>
                            </b-button>
                            <b-button size="sm" v-if="btnDeleteAgendaVisible" class="no-text" variant="outline-danger" style="margin-right: 3px" @click="onDelete(row.item, row.index, $event.target)"><b-icon icon="trash" variant="outline-danger"></b-icon></b-button>
                            <b-button size="sm" class="no-text" variant="outline-info" @click="onView(row.item, row.index, $event.target)">
                                <b-icon icon="calendar-event" variant="outline-primary" />
                            </b-button>
                            <!-- <condivisione-modal :idRisorsa="row.item.id" idSezione="agende"></condivisione-modal> -->
                        </b-container>
                    </template>
                </b-table>
            </div>
        </div>
        <div class="sa-list-component-footer">
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import UtilityMixin from "../../utility/UtilityMixin";
export default {
    props: {
        utente: { type: Object },
    },
    mixins: [UtilityMixin],
    data() {
        return {
            currentPage: 1,
            rows: 0,
            perPage: 20,
            pathResource: "/agende",
            linkedit: "/agende",
            btnNewVisible: false,
            btnEditAgendaVisible: false,
            btnDeleteAgendaVisible: false,
            items: [],
            fields: [
                {
                    label: this.$i18n.t("agende.lblDiary"),
                    key: "titolo",
                    sortable: true,
                },
                {
                    label: this.$i18n.t("agende.lblDescription"),
                    key: "descrizione",
                    sortable: true,
                },
                { class: "sa-table-column-action-double", key: "actions", label: "" },
            ],
        };
    },
    watch: {
        utente() {
            let me = this;
            me.impostaPermessi();
        },
    },
    methods: {
        setUtente(utente) {
            console.log(utente);
        },
        impostaPermessi() {
            let me = this;
            me.btnNewVisible = UtilityMixin.methods.verificaPermessi(me.utente, "agende", 2);
            me.btnEditAgendaVisible = UtilityMixin.methods.verificaPermessi(me.utente, "agende", 4);
            me.btnDeleteAgendaVisible = UtilityMixin.methods.verificaPermessi(me.utente, "agende", 8);
        },
        loadData(filtroAgenda) {
            let me = this;
            me.impostaPermessi();
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            filtroAgenda.page = me.currentPage;
            filtroAgenda.forPage = me.perPage;
            me.$emit("beforeLoadData");
            axios
                .get(link, { params: me.filtroAgenda })
                .then((response) => {
                    me.items = [];
                    me.rows = response.data.data.recordsNumber;
                    me.items = response.data.data.list;
                    this.$emit("afterLoadData");
                })
                .catch(() => {
                    this.$emit("afterLoadData");
                });
        },
        onEdit(item) {
            let me = this;
            me.$router.replace(me.linkedit + "/edit/" + item.id).catch((err) => {
                err;
            });
        },
    },
};
</script>

<style></style>
