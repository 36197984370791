<template>
  <div class="sa-list-component">
    <div class="sa-list-component-filter">
      <b-form @submit.prevent="onSubmit">
        <b-row>
          <b-col cols="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-label-data">Data Dal:</label>
            <b-input-group class="mb-3">
              <date-picker
                format="DD-MM-YYYY"
                value-type="timestamp"
                v-model="filtro.dataOraDal"
                type="search"
              ></date-picker>
            </b-input-group>
          </b-col>
          <b-col cols="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-label-data">Data Al:</label>
            <b-input-group class="mb-3">
              <date-picker
                format="DD-MM-YYYY"
                value-type="timestamp"
                v-model="filtro.dataOraAl"
                type="search"
              ></date-picker>
            </b-input-group>
          </b-col>
          <b-col cols="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-label-data">Tipo Documento:</label>
            <b-form-input
              v-model="filtro.tipoDocumento"
              type="search"
              id="codice"
            ></b-form-input>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">Stato:</label>
            <b-form-select
              id="inline-form-custom-select-pref"
              v-model="filtro.stato"
              value-field="value"
              text-field="text"
              class="mb-2 mr-sm-2 mb-sm-0"
              :options="statoOption"
              :value="null"
            ></b-form-select>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">Stato firma:</label>
            <b-form-select
              id="inline-form-custom-select-pref"
              v-model="filtro.statoFirma"
              class="mb-2 mr-sm-2 mb-sm-0"
              value-field="value"
              text-field="text"
              :options="statoFirmaOption"
            ></b-form-select></b-col
          ><b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">Stato invio documento:</label>
            <b-form-select
              id="inline-form-custom-select-pref"
              v-model="filtro.statoInvioDocumentale"
              class="mb-2 mr-sm-2 mb-sm-0"
              value-field="value"
              text-field="text"
              :options="statoInvioDocumentaleOption"
            ></b-form-select>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">Versione:</label>
            <b-form-input
              v-model="filtro.versione"
              value-field="value"
              text-field="text"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="sa-padding-right text-right">
            <b-button type="submit" variant="info">{{
              this.$i18n.t("global.lblFind")
            }}</b-button>
            <b-button type="reset" v-on:click="onReset" variant="danger">{{
              this.$i18n.t("global.lblResetFilter")
            }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </div>
    <div class="sa-list-component-header">
      <b-row
        class="sa-label-info"
        style="margin-left: 0.5rem; margin-right: 0.5rem"
      >
        <b-col lg="3">
          <p>{{ this.$i18n.t("global.lblTotalRecords") }}:{{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            align="right"
            size="sm"
          ></b-pagination>
        </b-col>
      </b-row>
    </div>
    <div class="sa-list-component-body">
      <div class="b-table-sticky-header">
        <b-table
          sticky-header
          ref="table"
          stacked="lg"
          striped
          hover
          :items="items"
          :fields="fields"
          :current-page="1"
          :per-page="perPage"
          :key="listKey"
          :busy="isBusy"
          sort-icon-left
          head-variant="light"
          class="sa-b-table"
        >
          <template #cell(index)="row">
            {{ row.index + 1 }}
          </template>
          <template v-slot:cell(tipoDocumento)="{ item }">
            <span>{{ item.tipoDocumento }} </span>
            <span class="sa-label-data-secondary"> </span>
          </template>
          <template v-slot:cell(stato)="{ item }">
            <span>{{ item.stato }} </span>
            <span class="sa-label-data-secondary"> </span>
          </template>
          <template v-slot:cell(versione)="{ item }">
            <span>{{ item.versione }} </span>
            <span class="sa-label-data-secondary"> </span>
          </template>
          <template v-slot:cell(statoFirma)="{ item }">
            <span>{{ item.statoFirma }} </span>
            <span class="sa-label-data-secondary"> </span>
          </template>
          <template v-slot:cell(statoInvioDocumentale)="{ item }">
            <span>{{ item.statoInvioDocumentale }} </span>
            <span class="sa-label-data-secondary"> </span>
          </template>
          <template v-slot:cell(dataOra)="{ item }">
            <span>{{ dataOraInvioFormatter(item.updateDate) }} </span>
            <span class="sa-label-data-secondary"> </span>
          </template>
          <template #cell(action)="item">
            <router-link
              class="sa-edit-link"
              :to="'/documentrepository/view/' + item.id"
            >
              <b-button size="sm" variant="outline-success no-text">
                <b-icon icon="arrow90deg-right"></b-icon>
              </b-button>
            </router-link>
            &nbsp;
          </template>
        </b-table>
      </div>
    </div>
    <div class="sa-list-component-footer">
      <b-row
        class="sa-label-info"
        style="margin-left: 0.5rem; margin-right: 0.5rem"
      >
        <b-col lg="3">
          <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            align="right"
            size="sm"
          ></b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import UtilityMixin from "../../utility/UtilityMixin";
import DatePicker from "vue2-datepicker";
export default {
  components: {
    DatePicker,
  },

  data() {
    return {
      filtro: {
        tipoDocumento: null,
        stato: null,
        statoFirma: null,
        versione: null,
        statoInvioDocumentale: null,
        dataOraDal: null,
        dataOraAl: null,
      },
      pathResource: "/documentrepository",
      showModalLoading: false,
      show: true,
      perPage: 50,
      currentPage: 1,
      rows: 0,
      rowsUsers: 0,
      listKey: 0,
      isBusy: false,
      items: [],
      statoOption: [
        { value: null, text: "- STATO -" },
        { value: "ATTIVO", text: "ATTIVO" },
        { value: "DEPRECATO", text: " DEPRECATO" },
      ],
      statoFirmaOption: [
        { value: null, text: "- STATO FIRMA -" },
        { value: "FIRMATO", text: "FIRMATO" },
      ],
      statoInvioDocumentaleOption: [
        { value: null, text: "- STATO INVIO DOCUMENTO -" },
        { value: "DA INVIARE", text: "DA INVIARE" },
        { value: "INVIATO", text: "INVIATO" },
        { value: "ELIMINATO", text: "ELIMINATO" },
      ],
      fields: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        { label: "Tipo documento", key: "tipoDocumento", sortable: true },
        { label: "Stato", key: "stato", sortable: true },
        { label: "Versione", key: "versione", sortable: true },
        { label: "Stato Firma", key: "statoFirma", sortable: true },
        {
          label: "Stato invio documento",
          key: "statoInvioDocumentale",
          sortable: true,
        },
        {
          label: "Data e Ora Invio",
          key: "dataOra",
          sortable: true,
        },
        {
          label: "",
          key: "action",
          thStyle: "width: 7rem",
          tdClass: "text-center",
        },
      ],
    };
  },
  mounted() {
    let me = this;
    let sessionStorage = window.sessionStorage;
    if (sessionStorage["filtro"]) {
      me.filtro = JSON.parse(sessionStorage["filtro"]);
    }
  },
  watch: {
    currentPage() {
      let me = this;
      me.loadData();
    },
  },
  methods: {
    dataOraInvioFormatter(value) {
      return UtilityMixin.methods.formatDateTimeCustom(
        value,
        "DD-MM-YYYY HH:mm"
      );
    },
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource;
      me.filtro.page = me.currentPage;
      me.filtro.forPage = me.perPage;
      me.$emit("beforeLoadData");
      axios
        .get(link, { params: me.filtro })
        .then((response) => {
          me.rows = response.data.data.recordsNumber;
          me.items = response.data.data.list;
          me.listKey++;
          this.$emit("afterLoadData");
        })
        .catch(() => {
          this.$emit("afterLoadData");
        });
    },
    onSubmit() {
      let me = this;
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onReset() {
      let me = this;
      me.filtro = {};
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
  },
};
</script>
