<template>
    <div>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Tipo Caso</label>
                    <b-form-select v-model="jsonData.tipoCaso" :options="tipiCasiOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Paese Visitato</label>
                    <b-form-select v-model="jsonData.paeseVisitato" :options="nazioniOptions" :value="''" value-field="codiceIstat" text-field="comune"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Continente</label>
                    <b-form-input v-model="jsonData.continente"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Motivo Viaggio</label>
                    <b-form-select v-model="jsonData.motivoViaggio" :options="motiviViaggiOptions" :value="''" value-field="value" text-field="text" @input="onInputMotivoViaggio"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Altro Motivo Viaggio</label>
                    <b-form-textarea v-model="jsonData.altroMotivoViaggio" rows="6" no-resize :disabled="disabledValue.altroMotivoViaggioDisabled"></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Partenza Dall'Italia</label>
                    <date-picker v-model="jsonData.dataPartenzaItalia" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Rientro in Italia</label>
                    <date-picker v-model="jsonData.dataRientroItalia" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Inizio Sintomi</label>
                    <date-picker v-model="jsonData.dataInizioSintomi" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Ricovero</label>
                    <b-form-select v-model="jsonData.ricovero" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputRicovero"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Nome Ospedale</label>
                    <b-form-input v-model="jsonData.nomeOspedale" :disabled="disabledValue.ricoveroDisabled"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Reparto</label>
                    <b-form-input v-model="jsonData.reparto" :disabled="disabledValue.ricoveroDisabled"></b-form-input>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Ricovero</label>
                    <date-picker v-model="jsonData.dataRicovero" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="disabledValue.ricoveroDisabled"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Diagnosi Clinica</label>
                    <date-picker v-model="jsonData.dataDiagnosiClinica" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="disabledValue.ricoveroDisabled"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Diagnosi Emoscopica</label>
                    <date-picker v-model="jsonData.dataDiagnosiEmoscopica" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="disabledValue.ricoveroDisabled"></date-picker>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Esito</label>
                    <b-form-select v-model="jsonData.esito" :options="esitiOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Dimissione/Decesso</label>
                    <date-picker v-model="jsonData.dataDimissioneDecesso" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Specie di Plasmodium</label>
                    <b-form-select v-model="jsonData.speciePlasmodium" :options="speciePlasmodiumOptions" :value="''" value-field="value" text-field="text" @input="onInputSpeciePlasmodium"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Specificare tipologia mista di Plasmodium</label>
                    <b-form-textarea v-model="jsonData.speciePlasmodiumAltro" rows="6" no-resize :disabled="disabledValue.speciePlasmodiumAltroDisabled"></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
        <b-row>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <b-card class="sa-card" header="Terapia" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Terapia</label>
                            <b-form-select v-model="jsonData.terapia" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-table sticky-header stacked="xl" striped hover itemscope :items="jsonData.listaTerapie" :fields="fieldsFarmaci" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                                <template #cell(index)="row">
                                    {{ row.index + 1 }}
                                </template>
                                <template #cell(nomeFarmaco)="item">
                                    <b-form-input v-model="item.item.nomeFarmaco"></b-form-input>
                                </template>
                                <template #cell(actions)="row">
                                    <b-button v-if="row.index == jsonData.listaTerapie.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddFarmaciTerapia(jsonData.listaTerapie, row.item)">
                                        <b-icon icon="plus"></b-icon>
                                    </b-button>
                                    <b-button v-if="row.index !== jsonData.listaTerapie.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(jsonData.listaTerapie, row)">
                                        <b-icon icon="trash"></b-icon>
                                    </b-button>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <b-card class="sa-card" header="Farmacoresistenza" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Farmacoresistenza</label>
                            <b-form-select v-model="jsonData.framacoresistenza" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-table sticky-header stacked="xl" striped hover itemscope :items="jsonData.listaFarmacoresistenti" :fields="fieldsFarmaci" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                                <template #cell(index)="row">
                                    {{ row.index + 1 }}
                                </template>
                                <template #cell(nomeFarmaco)="item">
                                    <b-form-input v-model="item.item.nomeFarmaco"></b-form-input>
                                </template>
                                <template #cell(actions)="row">
                                    <b-button v-if="row.index == jsonData.listaFarmacoresistenti.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddFarmaciFarmacoresistenza(jsonData.listaFarmacoresistenti, row.item)">
                                        <b-icon icon="plus"></b-icon>
                                    </b-button>
                                    <b-button v-if="row.index !== jsonData.listaFarmacoresistenti.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(jsonData.listaFarmacoresistenti, row)">
                                        <b-icon icon="trash"></b-icon>
                                    </b-button>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-card class="sa-card" header="Chemioprofilassi" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Chemioprofilassi</label>
                    <b-form-select v-model="jsonData.chemioprofilassi" :options="chemioprofilassiOptions" :value="''" value-field="value" text-field="text" @input="onInputChemioprofilassi"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Tipo Chemioprofilassi</label>
                    <b-form-input v-model="jsonData.tipoChemioprofilassi" :disabled="disabledValue.tipoChemioprofilassiDisabled"></b-form-input>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Stato Di Gravidanza</label>
                    <b-form-select v-model="jsonData.statoGravidanza" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Patologie Concomitanti</label>
                    <b-form-select v-model="jsonData.patologieConcomitanti" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Altre Considerazioni</label>
                    <b-form-textarea v-model="jsonData.altreConsiderazioni" rows="6" no-resize></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Dati Notifica" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Sanitario Notificante</label>
                    <b-form-input v-model="jsonData.sanitarioNotificante"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Telefono Sanitario Notificante</label>
                    <b-form-input v-model="jsonData.telefonoSanitarioNotificante"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Notifica</label>
                    <date-picker v-model="jsonData.dataNotifica" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
<script>
import axios from "axios";
// import moment from "moment";
import Vue from "vue";
import EventiMixins from "../../../paziente/eventi/utility/mixins/EventiMixins";
import DatePicker from "vue2-datepicker";
export default {
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    components: { DatePicker },
    data() {
        return {
            pathResource: "/malattieinfettiveiemalaria",
            pathResourceMalattiaInfettivaSegnalazione: "/malattieinfettivesegnalazioni",
            pathResourceNazioni: "/nazioni",
            currentPage: 1,
            perPage: 100,
            linkback: null,
            showModalLoading: false,
            disabledValue: { altroMotivoViaggioDisabled: true, ricoveroDisabled: true, speciePlasmodiumAltroDisabled: true, tipoChemioprofilassiDisabled: true },
            jsonData: {
                tipoCaso: "",
                paeseVisitato: "",
                continente: "",
                motivoViaggio: "",
                altroMotivoViaggio: "",
                dataPartenzaItalia: null,
                dataRientroItalia: null,
                dataInizioSintomi: null,
                ricovero: "",
                nomeOspedale: "",
                reparto: "",
                dataRicovero: null,
                dataDiagnosiClinica: null,
                dataDiagnosiEmoscopica: null,
                esito: "",
                dataDimissioneDecesso: null,
                speciePlasmodium: "",
                speciePlasmodiumAltro: "",
                terapia: "",
                listaTerapie: [],
                framacoresistenza: "",
                listaFarmacoresistenti: [],
                chemioprofilassi: "",
                tipoChemioprofilassi: "",
                statoGravidanza: "",
                patologieConcomitanti: "",
                sanitarioNotificante: "",
                telefonoSanitarioNotificante: "",
                altreConsiderazioni: "",
                dataNotifica: null,
            },
            fieldsFarmaci: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Farmaco",
                    key: "nomeFarmaco",
                    sortable: false,
                },
                {
                    label: "",
                    key: "actions",
                    sortable: false,
                    thStyle: "width: 4rem",
                },
            ],
            siNoOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "SI", text: "SI" },
                { value: "NO", text: "NO" },
            ],
            chemioprofilassiOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "SI", text: "SI" },
                { value: "NO", text: "NO" },
                { value: "INCOMPLETA", text: "INCOMPLETA" },
            ],
            tipiCasiOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "IMPORTATA", text: "IMPORTATA" },
                { value: "INDOTTA", text: "INDOTTA" },
                { value: "AUTOCTONA", text: "AUTOCTONA" },
            ],
            nazioniOptions: [],
            motiviViaggiOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "LAVORO", text: "LAVORO" },
                { value: "TURISMO", text: "TURISMO" },
                { value: "RIENTRO PAESE DI ORIGINE", text: "RIENTRO PAESE DI ORIGINE" },
                { value: "IMMIGRAZIONE", text: "IMMIGRAZIONE" },
                { value: "MISSIONE RELIGIOSA", text: "MISSIONE RELIGIOSA" },
                { value: "MISSIONE MILITARE", text: "MISSIONE MILITARE" },
                { value: "ALTRO", text: "ALTRO" },
            ],
            esitiOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "GUARIGIONE", text: "GUARIGIONE" },
                { value: "DECESSO", text: "DECESSO" },
            ],
            speciePlasmodiumOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "P. FALCIPARUM", text: "P. FALCIPARUM" },
                { value: "P. VIVAX", text: "P. VIVAX" },
                { value: "P. MALARIAE", text: "P. MALARIAE" },
                { value: "P. OVALE", text: "P. OVALE" },
                { value: "FORME MISTE", text: "FORME MISTE" },
                { value: "NON SPECIFICABILE", text: "NON SPECIFICABILE" },
            ],
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient() {
            return EventiMixins.getLinkPatient(this.utente, this.idPaziente);
        },
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.jsonData.idAnagrafica = me.idPaziente;
        me.jsonData.id = me.id;
        me.loadMalattiaInfettivaSegnalazione();
        me.loadDefaultData();
        me.loadData();
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
        "jsonData.terapia": function (value) {
            let me = this;
            if (value === "SI") {
                me.jsonData.listaTerapie.push({ nomeFarmaco: "", tipo: "TERAPIE" });
            } else {
                me.jsonData.listaTerapie = [];
            }
        },
        "jsonData.framacoresistenza": function (value) {
            let me = this;
            if (value === "SI") {
                me.jsonData.listaFarmacoresistenti.push({ nomeFarmaco: "", tipo: "FARMACORESISTENTE" });
            } else {
                me.jsonData.listaFarmacoresistenti = [];
            }
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            me.showModalLoading = true;
            axios
                .get(link + me.id)
                .then((response) => {
                    me.jsonData = response.data.data;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.setDatiMedico();
                    me.showModalLoading = false;
                });
        },
        loadMalattiaInfettivaSegnalazione() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceMalattiaInfettivaSegnalazione + "/";
            axios
                .get(link + me.id)
                .then((response) => {
                    me.setDatiMalattia(response.data.data);
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        loadDefaultData() {
            let me = this;
            me.loadNazioni();
        },
        loadNazioni() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceNazioni;
            axios.get(link).then((response) => {
                me.nazioniOptions = response.data.data;
                me.nazioniOptions.unshift({ codiceIstat: "", comune: "-Seleziona Paese-" });
            });
        },
        onInputMotivoViaggio(value) {
            let me = this;
            if (value === "ALTRO") {
                me.disabledValue.altroMotivoViaggioDisabled = false;
            } else {
                me.disabledValue.altroMotivoViaggioDisabled = true;
                me.jsonData.altroMotivoViaggio = "";
            }
        },
        onInputRicovero(value) {
            let me = this;
            if (value === "SI") {
                me.disabledValue.ricoveroDisabled = false;
            } else {
                me.disabledValue.ricoveroDisabled = true;
                me.jsonData.nomeOspedale = "";
                me.jsonData.reparto = "";
                me.jsonData.dataRicovero = null;
                me.jsonData.dataDiagnosiClinica = null;
                me.jsonData.dataDiagnosiEmoscopica = null;
            }
        },
        onInputSpeciePlasmodium(value) {
            let me = this;
            if (value === "FORME MISTE") {
                me.disabledValue.speciePlasmodiumAltroDisabled = false;
            } else {
                me.disabledValue.speciePlasmodiumAltroDisabled = true;
                me.jsonData.speciePlasmodiumAltro = "";
            }
        },
        onInputChemioprofilassi(value) {
            let me = this;
            if (value === "SI") {
                me.disabledValue.tipoChemioprofilassiDisabled = false;
            } else {
                me.disabledValue.tipoChemioprofilassiDisabled = true;
                me.jsonData.tipoChemioprofilassi = "";
            }
        },
        onAddFarmaciTerapia(array, value) {
            if (value.nomeFarmaco !== "") {
                array.push({ nomeFarmaco: "", tipo: "TERAPIE" });
            } else {
                this.$bvModal
                    .msgBoxOk("Il FARMACO è OBBLIGATORIO", {
                        title: "ATTENZIONE",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    })
                    .then((value) => {
                        console.log(value);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        onAddFarmaciFarmacoresistenza(array, value) {
            if (value.nomeFarmaco !== "") {
                array.push({ nomeFarmaco: "", tipo: "FARMACORESISTENTE" });
            } else {
                this.$bvModal
                    .msgBoxOk("Il FARMACO è OBBLIGATORIO", {
                        title: "ATTENZIONE",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    })
                    .then((value) => {
                        console.log(value);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        onDelete(array, row) {
            if (row.index != array.length) {
                this.$bvModal
                    .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
                        title: "Titolo",
                        buttonSize: "sm",
                        okVariant: "secondary",
                        cancelVariant: "danger",
                        okTitle: "SI",
                        cancelTitle: "NO",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true,
                    })
                    .then((value) => {
                        if (value) {
                            array.splice(row.index, 1);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        setDatiMedico() {
            let me = this;
            me.jsonData.sanitarioNotificante = Vue.prototype.user.firstname + " " + Vue.prototype.user.lastname;
        },
        setDatiMalattia(data) {
            let me = this;
            me.jsonData.id = data.id;
        },
    },
};
</script>
