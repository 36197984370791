<template>
    <div class="sa-component-patient-info">
        <b-row>
            <b-col>
                <img :src="patientAvatar" class="rounded-circle avatar-toolbar img-thumbnail float-left mr-3" alt="profile-image" />
                <h4 class="m-0">
                    <span class="">
                        <b>
                            {{ jsonData.accettazioneAnagrafica.cognome }} {{ jsonData.accettazioneAnagrafica.nome }}
                            <i>({{ jsonData.accettazioneAnagrafica.identificativo }})</i>
                        </b>
                    </span>
                </h4>
                <p class="text-muted" style="margin-bottom: 0.5rem !important">
                    <i></i>
                </p>
                <p class="text-muted" style="margin-bottom: 0.5rem !important">
                    <span>
                        <b>Data di Nascita:</b>
                        {{ formatDate(jsonData.accettazioneAnagrafica.dataNascita) }}
                    </span>
                    <span>
                        <b>Età:</b>
                        {{ calcoloEta(jsonData.accettazioneAnagrafica.dataNascita) }}
                    </span>
                </p>
                <p class="text-muted" style="margin-bottom: 0.5rem !important">
                    <span>
                        <b>Struttura:</b>
                        {{ jsonData.accettazioneStruttura.denominazione }}
                    </span>
                    <span>
                        <b>Presidio:</b>
                        {{ jsonData.accettazioneStruttura.presidio }}
                    </span>
                    <span>
                        <b>Regime:</b>
                        {{ jsonData.regime }}
                    </span>
                    <span>
                        <b>Numero Cartella:</b>
                        {{ jsonData.numero }}
                    </span>
                </p>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import female from "@/assets/images/female.jpg";
import male from "@/assets/images/male.jpg";
import moment from "moment";
export default {
    name: "PazienteAnagrafica",
    components: {},
    props: {
        jsonData: {
            type: Object,
            default: function () {
                return { accettazioneAnagrafica: { cognome: "", nome: "", sesso: "", regime: "Test", numero: "" } };
            },
        },
    },
    data() {
        return {};
    },
    computed: {
        patientAvatar: {
            get: function () {
                let me = this;
                if (me.jsonData.sesso === "M") {
                    return male;
                } else {
                    return female;
                }
            },
        },
    },
    methods: {
        formatDate(value) {
            if (value) {
                return moment(new Date(value)).format("DD-MM-YYYY");
            } else {
                return "---";
            }
        },
        calcoloEta(value) {
            if (value) {
                let today = new Date();
                let birthDate = new Date(value);
                let daAggiungere = this.calcolaDaAggiungere(birthDate.getMonth(), birthDate.getFullYear());
                let age = today.getFullYear() - birthDate.getFullYear();
                let ageMonth = today.getMonth() - birthDate.getMonth();
                let ageDays = today.getDate() - birthDate.getDate();
                if (ageMonth < 0 || (ageMonth === 0 && today.getDate() < birthDate.getDate())) {
                    age--;
                    ageMonth = 12 + ageMonth;
                }

                if (ageDays < 0) {
                    ageMonth--;
                    ageDays = today.getDate() - birthDate.getDate() + daAggiungere;
                }
                return age + " " + "Anni" + " " + ageMonth + " " + "mesi" + " " + ageDays + " " + "giorni";
            } else {
                return "---";
            }
        },
        calcolaDaAggiungere(mese, anno) {
            //   console.log(mese);
            let returnValue = 31;
            //   console.log(returnValue);
            if (mese == 1) {
                if ((anno % 4 == 0 && anno % 100 != 0) || anno % 400 == 0) {
                    returnValue = 29;
                } else {
                    returnValue = 28;
                }
            } else if (mese == 3 || mese == 5 || mese == 8 || mese == 10) {
                returnValue = 30;
            }
            return returnValue;
        },
        calcoloEtaInt(value) {
            if (value) {
                let today = new Date();
                let birthDate = new Date(value);
                let age = today.getFullYear() - birthDate.getFullYear();
                let ageMonth = today.getMonth() - birthDate.getMonth();
                let ageDays = today.getDate() - birthDate.getDate();
                if (ageMonth < 0 || (ageMonth === 0 && today.getDate() < birthDate.getDate())) {
                    age--;
                    ageMonth = 12;
                }

                if (ageDays < 0) {
                    ageMonth--;
                    ageDays = birthDate.getDate() - today.getDate();
                }
                this.$emit("getEtaPaziente", age);
                return age;
            } else {
                return 0;
            }
        },
    },
};
</script>
