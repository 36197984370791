<template>
    <div class="sa-tab-scroll">
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data">Id Scheda:</label>
                <b-form-input v-model="jsonData.idScheda" :disabled="true"></b-form-input>
            </b-col>
        </b-card>
        <b-card class="sa-card" header="Triage socio sanitario" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Ora Registrazione:</label>
                    <date-picker v-model="jsonData.dataOraRegistrazione" format="DD-MM-YYYY HH:mm" value-type="timestamp" type="datetime" disabled></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Provincia:</label>
                    <b-form-select v-model="jsonData.provinciaRegistrazioneDescrizione" :options="provinceRegistrazioneOptions" :value="null" value-field="sigla" text-field="provincia" @change="loadProvinceRegistrazioneChange"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Comune:</label>
                    <b-form-select v-model="jsonData.comuneRegistrazione" :options="comuniRegistrazioneOptions" :value="''" value-field="codiceIstat" text-field="comune"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Area/Centro Assistenza:</label>
                    <b-form-input v-model="jsonData.areaCentroAssistenza"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Fornitore Informazioni:</label>
                    <b-form-select v-model="jsonData.informazioniSchedaFornita" :options="informazioniForniteOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Fornitore Informazioni Dettaglio:</label>
                    <b-form-input v-model="jsonData.informazioniSchedaFornitaAltro" :disabled="chekCondition(jsonData.informazioniSchedaFornita, 'TERZA PERSONA')"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Nome Sanitario Compilatore:</label>
                    <b-form-input v-model="jsonData.operatoreNome" :disabled="disabledSanitario"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Cognome Sanitario Compilatore:</label>
                    <b-form-input v-model="jsonData.operatoreCognome" :disabled="disabledSanitario"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Associazione Sanitario Compilatore:</label>
                    <b-form-input v-model="jsonData.operatoreAssociazioneVolontariato"></b-form-input>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Assistito" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Codice Fiscale:</label>
                    <!-- <b-form-input v-model="jsonData.assistitoCodiceFiscale" class="text-upper"></b-form-input> -->
                    <b-input-group>
                        <b-form-input maxlength="16" v-model="jsonData.assistitoCodiceFiscale" @keyup.enter="onSearchAnagraficaClick()" style="text-transform: uppercase"></b-form-input>
                        <b-input-group-append>
                            <b-button @click="onSearchAnagraficaClick()" :disabled="!codiceFiscaleValido"><b-icon icon="search"></b-icon></b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Nome:</label>
                    <b-form-input v-model="jsonData.assistitoNome"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Cognome:</label>
                    <b-form-input v-model="jsonData.assistitoCognome"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Nascita:</label>
                    <date-picker v-model="jsonData.assistitoDataNascita" format="DD-MM-YYYY" value-type="timestamp" type="date"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Provincia Nascita:</label>
                    <b-form-select v-model="jsonData.assistitoProvinciaNascitaDescrizione" :options="provinceNascitaOptions" :value="''" value-field="sigla" text-field="provincia" @change="loadProvinceNascitaChange"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Comune Nascita:</label>
                    <b-form-select v-model="jsonData.assistitoComuneNascita" :options="comuniNascitaOptions" :value="''" value-field="codiceIstat" text-field="comune"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Provincia Residenza:</label>
                    <b-form-select v-model="jsonData.assistitoProvinciaResidenzaDescrizione" :options="provinceResidenzaOptions" :value="''" value-field="sigla" text-field="provincia" @change="loadProvinceResidenzaChange"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Comune Residenza:</label>
                    <b-form-select v-model="jsonData.assistitoComuneResidenzaCodiceIstat" :options="comuniResidenzaOptions" :value="''" value-field="codiceIstat" text-field="comune"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Indirizzo:</label>
                    <b-form-input v-model="jsonData.assistitoIndirizzo"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Telefono:</label>
                    <b-form-input v-model="jsonData.assistitoTelefono"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Animali Domestici:</label>
                    <b-form-input v-model="jsonData.assistitoAnimaliDomestici"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Numero Animali Domestici:</label>
                    <b-form-input v-model="jsonData.assistitoAnimaliDomesticiNumero"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Medico di Famiglia:</label>
                    <b-form-input v-model="jsonData.asssititoMmg"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">ASL:</label>
                    <b-form-input v-model="jsonData.assistitoAsl"></b-form-input>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Contatto Necessita'" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Nome:</label>
                    <b-form-input v-model="jsonData.personaContattareNecessitaNome"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Cognome:</label>
                    <b-form-input v-model="jsonData.personaContattareNecessitaCognome"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Qualifica:</label>
                    <b-form-input v-model="jsonData.personaContattareNecessitaQualifica"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Telefono:</label>
                    <b-form-input v-model="jsonData.personaContattareNecessitaTelefono"></b-form-input>
                </b-col>
            </b-row>
        </b-card>
        <b-row>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <b-card class="sa-card" header="Comportamenti" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-form-checkbox v-model="jsonData.comportamentoCollaborante" :checked="jsonData.comportamentoCollaborante" switch>Collaborante</b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-form-checkbox v-model="jsonData.comportamentoNonCollaborante" :checked="jsonData.comportamentoNonCollaborante" switch>Non collaborante</b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-form-checkbox v-model="jsonData.comportamentoViolento" :checked="jsonData.comportamentoViolento" switch>Violento</b-form-checkbox>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col xs="12" sm="12" md="8" lg="8" xl="8">
                <b-card class="sa-card" header="Tipo di disabilità oggettiva" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <b-form-checkbox v-model="jsonData.tipoDisabilitaOggettivaMotoria" :checked="jsonData.tipoDisabilitaOggettivaMotoria" switch>Motoria</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <b-form-checkbox v-model="jsonData.tipoDisabilitaOggettivaUditiva" :checked="jsonData.tipoDisabilitaOggettivaUditiva" switch>Uditiva</b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <b-form-checkbox v-model="jsonData.tipoDisabilitaOggettivaVisiva" :checked="jsonData.tipoDisabilitaOggettivaVisiva" switch>Visiva</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <b-row>
                                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                                    <b-form-checkbox v-model="jsonData.tipoDisabilitaOggettivaAltro" :checked="jsonData.tipoDisabilitaOggettivaAltro" switch>Altro</b-form-checkbox>
                                </b-col>
                                <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                                    <b-form-input v-model="jsonData.tipoDisabilitaOggettivaAltroTesto" :disabled="!jsonData.tipoDisabilitaOggettivaAltro"></b-form-input>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <b-form-checkbox v-model="jsonData.tipoDisabilitaOggettivaCognitiva" :checked="jsonData.tipoDisabilitaOggettivaCognitiva" switch>Cognitiva</b-form-checkbox>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>

        <b-card class="sa-card" header="Valutazione" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Codice Valutazione:</label>
                    <b-form-select v-model="jsonData.codiceValutazione" :options="codiciValutazioneOptions" value-field="value" text-field="text" @change="onSetValutazione"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
                    <label class="sa-label-data">Descrizione Valutazione:</label>
                    <span class="sa-data">{{ descrizioneValutazione }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Note:</label>
                    <b-form-textarea v-model="jsonData.codiceValutazioneNote" rows="4" no-resize></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
        SEZIONE 2 - COMPETENZE INFERMIERISTICHE
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <b-card class="sa-card" header="In carico ai servizi sociali" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-form-checkbox v-model="jsonData.caricoServiziAssistenzaInfermieristicaDomiciliare" :checked="jsonData.caricoServiziAssistenzaInfermieristicaDomiciliare" switch>Assistenza Infermieristica Domiciliare</b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-form-checkbox v-model="jsonData.caricoServiziAssistenzaSociale" :checked="jsonData.caricoServiziAssistenzaSociale" switch>Assistenza Sociale</b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-form-checkbox v-model="jsonData.caricoServiziSert" :checked="jsonData.caricoServiziSert" switch>SerT (Tossicodipendenza)</b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-form-checkbox v-model="jsonData.caricoServiziCentroSaluteMentale" :checked="jsonData.caricoServiziCentroSaluteMentale" switch>Centro Salute Mentale</b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-form-checkbox v-model="jsonData.caricoServiziServizioPsicologia" :checked="jsonData.caricoServiziServizioPsicologia" switch>Servizio di Psicologia</b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-row>
                                <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                                    <b-form-checkbox v-model="jsonData.caricoServiziAltro" :checked="jsonData.caricoServiziAltro" switch>Altro</b-form-checkbox>
                                </b-col>
                                <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                                    <b-form-input v-model="jsonData.caricoServiziAltroTesto" :disabled="!jsonData.caricoServiziAltro"></b-form-input>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <b-card class="sa-card" header="note infermieristiche" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"><i>Si richiede valutazione medica per visita:</i></b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-form-checkbox v-model="jsonData.noteInfermieristichePrescrizioneFarmaci" :checked="jsonData.noteInfermieristichePrescrizioneFarmaci" switch>Prescrizione farmaci quotidiani per patologie croniche</b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-form-checkbox v-model="jsonData.noteInfermieristichePediatrica" :checked="jsonData.noteInfermieristichePediatrica" switch>Pediatrica</b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-form-checkbox v-model="jsonData.noteInfermieristicheInfettivologa" :checked="jsonData.noteInfermieristicheInfettivologa" switch>Infettivologa</b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-form-checkbox v-model="jsonData.noteInfermieristichePsicologica" :checked="jsonData.noteInfermieristichePsicologica" switch>Psicologica</b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-row>
                                <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                                    <b-form-checkbox v-model="jsonData.noteInfermieristicheAltro" :checked="jsonData.noteInfermieristicheAltro" switch>Altro</b-form-checkbox>
                                </b-col>
                                <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                                    <b-form-input v-model="jsonData.noteInfermieristicheAltroTesto" :disabled="!jsonData.noteInfermieristicheAltro"></b-form-input>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card" header="patologie in corso" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-row>
                                <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                                    <b-form-checkbox v-model="jsonData.patologieCorsoIntolleranzeAlimentari" :checked="jsonData.patologieCorsoIntolleranzeAlimentari" switch>Intolleranze Alimentari, Farmaci/Allergie</b-form-checkbox>
                                </b-col>
                                <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                                    <b-form-input v-model="jsonData.patologieCorsoIntolleranzeAlimentariTesto" :disabled="!jsonData.patologieCorsoIntolleranzeAlimentari"></b-form-input>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-row>
                                <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                                    <b-form-checkbox v-model="jsonData.patologieCorsoNefropatia" :checked="jsonData.patologieCorsoNefropatia" switch>Nefropatia</b-form-checkbox>
                                </b-col>
                                <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                                    <b-form-input v-model="jsonData.patologieCorsoNefropatiaTesto" :disabled="!jsonData.patologieCorsoNefropatia"></b-form-input>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-row>
                                <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                                    <b-form-checkbox v-model="jsonData.patologieCorsoFistole" :checked="jsonData.patologieCorsoFistole" switch>Fistole</b-form-checkbox>
                                </b-col>
                                <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                                    <b-form-input v-model="jsonData.patologieCorsoFistoleTesto" :disabled="!jsonData.patologieCorsoFistole"></b-form-input>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-row>
                                <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                                    <b-form-checkbox v-model="jsonData.patologieCorsoMetabolica" :checked="jsonData.patologieCorsoMetabolica" switch>Metabolica</b-form-checkbox>
                                </b-col>
                                <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                                    <b-form-input v-model="jsonData.patologieCorsoMetabolicaTesto" :disabled="!jsonData.patologieCorsoMetabolica"></b-form-input>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-row>
                                <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                                    <b-form-checkbox v-model="jsonData.patologieCorsoCardiopatia" :checked="jsonData.patologieCorsoCardiopatia" switch>Cardiopatia</b-form-checkbox>
                                </b-col>
                                <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                                    <b-form-input v-model="jsonData.patologieCorsoCardiopatiaTesto" :disabled="!jsonData.patologieCorsoCardiopatia"></b-form-input>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-row>
                                <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                                    <b-form-checkbox v-model="jsonData.patologieCorsoRespiratoria" :checked="jsonData.patologieCorsoRespiratoria" switch>Respiratoria</b-form-checkbox>
                                </b-col>
                                <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                                    <b-form-input v-model="jsonData.patologieCorsoRespiratoriaTesto" :disabled="!jsonData.patologieCorsoRespiratoria"></b-form-input>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-row>
                                <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                                    <b-form-checkbox v-model="jsonData.patologieCorsoOssigenoTerapia" :checked="jsonData.patologieCorsoOssigenoTerapia" switch>O2 TP</b-form-checkbox>
                                </b-col>
                                <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                                    <b-form-input v-model="jsonData.patologieCorsoOssigenoTerapiaTesto" :disabled="!jsonData.patologieCorsoOssigenoTerapia"></b-form-input>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card" header="assunzione farmaci" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-row>
                                <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                                    <b-form-checkbox v-model="jsonData.assunzioneFarmaciSi" :checked="jsonData.assunzioneFarmaciSi" switch>Si (indicare il nome del Farmaco)</b-form-checkbox>
                                </b-col>
                                <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                                    <b-form-input v-model="jsonData.assunzioneFarmaciSiTesto" :disabled="!jsonData.assunzioneFarmaciSi"></b-form-input>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-row>
                                <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                                    <b-form-checkbox v-model="jsonData.assunzioneFarmaciSiSconosciuti" :checked="jsonData.assunzioneFarmaciSi" switch>Si (se non ricorda, indicare la funzione)</b-form-checkbox>
                                </b-col>
                                <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                                    <b-form-input v-model="jsonData.assunzioneFarmaciSiSconosciutiTesto" :disabled="!jsonData.assunzioneFarmaciSiSconosciuti"></b-form-input>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <b-card class="sa-card" header="Ausili per l'incontinenza" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-form-checkbox v-model="jsonData.ausiliIncontinenzaCatetereVescicale" :checked="jsonData.ausiliIncontinenzaCatetereVescicale" switch>Catetere Vescicale</b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-form-checkbox v-model="jsonData.ausiliIncontinenzaSacchettoStomie" :checked="jsonData.ausiliIncontinenzaSacchettoStomie" switch>Sacchetto per stomie</b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-form-checkbox v-model="jsonData.ausiliIncontinenzaPresidiAssorbenti" :checked="jsonData.ausiliIncontinenzaPresidiAssorbenti" switch>Presidi Assorbenti</b-form-checkbox>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <b-card class="sa-card" header="Ausili per il movimento" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-form-checkbox v-model="jsonData.ausiliMovimentoSediaMotorizzata" :checked="jsonData.ausiliMovimentoSediaMotorizzata" switch>Sedia Motorizzata</b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-form-checkbox v-model="jsonData.ausiliMovimentoSediaManuale" :checked="jsonData.ausiliMovimentoSediaManuale" switch>Sedia Manuale</b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-form-checkbox v-model="jsonData.ausiliMovimentoSistemaPosturale" :checked="jsonData.ausiliMovimentoSistemaPosturale" switch>Sistema Posturale</b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-form-checkbox v-model="jsonData.ausiliMovimentoBastone" :checked="jsonData.ausiliMovimentoBastone" switch>Bastone</b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-form-checkbox v-model="jsonData.ausiliMovimentoDeambulatore" :checked="jsonData.ausiliMovimentoDeambulatore" switch>Deambulatore</b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-form-checkbox v-model="jsonData.ausiliMovimentoArtoArtificiale" :checked="jsonData.ausiliMovimentoArtoArtificiale" switch>Arto Artificiale</b-form-checkbox>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">E' possibile reperire in loco i farmaci necessari?:</label>
                            <!-- <b-form-checkbox v-model="jsonData.reperimentoLocoFarmaciNecessari" :checked="jsonData.reperimentoLocoFarmaciNecessari" switch>E' possibile reperire in loco i farmaci necessari?</b-form-checkbox> -->
                            <b-form-select v-model="jsonData.reperimentoLocoFarmaciNecessari" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">La struttura in cui si trova l'assistito consente adeguate possibilita' di gestione delle sue necessita'?:</label>
                            <!-- <b-form-checkbox v-model="jsonData.strutturaAssistitoGaranziaGestioneNecessita" :checked="jsonData.strutturaAssistitoGaranziaGestioneNecessita" switch>La struttura in cui si trova l'assistito consente adeguate possibilita' di gestione delle sue necessita'?</b-form-checkbox> -->
                            <b-form-select v-model="jsonData.strutturaAssistitoGaranziaGestioneNecessita" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-card class="sa-card" header="alloggiamento consigliato" header-tag="header" footer-tag="footer" title="">
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <label class="sa-label-data">Alloggio Consigliato:</label>
                <b-form-select v-model="jsonData.alloggioConsigliato" :options="alloggioOptions" :value="''" value-field="value" text-field="text"></b-form-select>
            </b-col>
        </b-card>
        <b-card class="sa-card" header="Rifiuto" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <!-- <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Rifiuto:</label>
                    <b-form-select v-model="jsonData.sceltaRifiuto" :options="rifiutoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col> -->
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-form-checkbox v-model="jsonData.rifiutaDestinazione" :checked="jsonData.rifiutaDestinazione" switch>Rifiuto Destinazione</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-form-checkbox v-model="jsonData.rifiutaFirmaDocumento" :checked="jsonData.rifiutaFirmaDocumento" switch>Rifiuto Firma Documento</b-form-checkbox>
                </b-col>
            </b-row>
        </b-card>
        <b-card v-if="jsonData.rifiutaDestinazione" class="sa-card" header="Rifiuto Destinazione" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" v-if="disableSottoscritto">
                    <label class="sa-label-data">Il Sottoscritto:</label>
                    <b-form-input v-model="jsonData.sceltaRifiutoNomeCognome"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" v-else>
                    <label class="sa-label-data">Il Sottoscritto:</label>
                    <b-form-input v-model="jsonData.sceltaRifiutoNomeCognome" disabled></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Identificato Da Documento:</label>
                    <b-form-input v-model="jsonData.sceltaRifiutoNumeroDocumento"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Rifiuta il trasferimento presso struttura proposta, assumendosi ogni respnsabilita in merito</label>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data:</label>
                    <date-picker v-model="jsonData.sceltaRifiutoDataOra" format="DD-MM-YYYY HH:mm" value-type="timestamp" type="datetime" disabled></date-picker>
                </b-col>
            </b-row>
        </b-card>
        <b-card v-if="jsonData.rifiutaFirmaDocumento" class="sa-card" header="Rifiuto Fima Documento" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">La persona rifiuta verbalmente davanti a testimoni la destinaziomr consigliata e rifiuta di firmare il presente documento</label>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data:</label>
                    <date-picker v-model="jsonData.sceltaRifiutoDataOra" format="DD-MM-YYYY HH:mm" value-type="timestamp" type="datetime" disabled></date-picker>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import UtilityMixin from "../../utility/UtilityMixin";
export default {
    // props: {
    //     jsonData: {
    //         type: Object,
    //         default: function () {
    //             return {};
    //         },
    //     },
    // },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
        "jsonData.assistitoCodiceFiscale": function (value) {
            if (value) {
                var regexCodiceFiscale = /^(?:(?:[B-DF-HJ-NP-TV-Z]|[AEIOU])[AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[1256LMRS][\dLMNP-V])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/;
                if (regexCodiceFiscale.test(value.toUpperCase())) {
                    this.codiceFiscaleValido = true;
                } else this.codiceFiscaleValido = false;
            }
        },
        "jsonData.rifiutaDestinazione": function (value) {
            let me = this;
            if (value) {
                me.jsonData.rifiutaFirmaDocumento = false;
                this.checkRifiutante();
            }
        },
        "jsonData.rifiutaFirmaDocumento": function (value) {
            let me = this;
            if (value) {
                me.jsonData.rifiutaDestinazione = false;
                me.jsonData.sceltaRifiutoNomeCognome = "";
                me.jsonData.sceltaRifiutoNumeroDocumento = "";
            }
        },
    },
    components: { DatePicker },
    mixins: [UtilityMixin],
    data() {
        return {
            showModalLoading: false,
            linkback: "/schedavalutazioneesigenzeimmediate",
            currentPage: 1,
            perPage: 100,
            id: "-1",
            descrizioneValutazione: "",
            pathResource: "/schedavalutazioneesigenzeimmediate",
            linkProvince: "/province",
            linkComune: "/comuni/provincia",
            linkComportamenti: "/schedavalutazioneesigenzeimmediatecomportamento",
            linkTipiDisabilita: "/schedavalutazioneesigenzeimmediatetipodisabilita",
            linkCodiciValutazione: "/schedavalutazioneesigenzeimmediatecodicevalutazione",
            linkServizi: "/schedavalutazioneesigenzeimmediatetiposervizi",
            linkNoteInfermieristiche: "/schedavalutazioneesigenzeimmediatenoteinfermieristiche",
            linkPatologie: "/schedavalutazioneesigenzeimmediatepatologie",
            linkFarmaci: "/schedavalutazioneesigenzeimmediatefarmcaci",
            linkAusiliIncontinenza: "/schedavalutazioneesigenzeimmediateausiliariincontinenza",
            linkAusiliMovimento: "/schedavalutazioneesigenzeimmediateausiliarimovimento",
            linkRifiuto: "/schedavalutazioneesigenzeimmediaterifiuto",
            codiceFiscaleValido: false,
            disableSottoscritto: false,
            disabledSanitario: true,
            jsonData: {
                id: "-1",
                dataOraRegistrazione: new Date().getTime(),
                provinciaRegistrazioneDescrizione: "",
                comuneRegistrazione: "",
                areaCentroAssistenza: "",
                informazioniSchedaFornita: "",
                informazioniSchedaFornitaAltro: "",
                operatoreNome: "",
                operatoreCognome: "",
                operatoreAssociazioneVolontariato: "",
                assistitoNome: "",
                assistitoCognome: "",
                assistitoDataNascita: null,
                assistitoComuneNascita: "",
                assistitoIndirizzo: "",
                assistitoCodiceFiscale: "",
                assistitoTelefono: "",
                assistitoNumeroPersoneNucleoFamiliare: "",
                assistitoAnimaliDomestici: "",
                assistitoAnimaliDomesticiNumero: "",
                asssititoMmg: "",
                assistitoAsl: "",
                assistitoProvinciaNascitaDescrizione: "",
                assistitoProvinciaResidenzaDescrizione: "",
                assistitoComuneResidenzaCodiceIstat: "",
                personaContattareNecessitaNome: "",
                personaContattareNecessitaCognome: "",
                personaContattareNecessitaQualifica: "",
                personaContattareNecessitaTelefono: "",
                comportamentoCollaborante: false,
                comportamentoNonCollaborante: false,
                comportamentoViolento: false,
                tipoDisabilitaOggettivaMotoria: false,
                tipoDisabilitaOggettivaVisiva: false,
                tipoDisabilitaOggettivaCognitiva: false,
                tipoDisabilitaOggettivaUditiva: false,
                tipoDisabilitaOggettivaAltro: false,
                tipoDisabilitaOggettivaAltroTesto: "",
                codiceValutazione: "",
                codiceValutazioneNote: "",
                caricoServiziAssistenzaInfermieristicaDomiciliare: false,
                caricoServiziAssistenzaSociale: false,
                caricoServiziSert: false,
                caricoServiziCentroSaluteMentale: false,
                caricoServiziServizioPsicologia: false,
                caricoServiziAltro: false,
                caricoServiziAltroTesto: "",
                noteInfermieristichePrescrizioneFarmaci: false,
                noteInfermieristichePediatrica: false,
                noteInfermieristicheInfettivologa: false,
                noteInfermieristichePsicologica: false,
                noteInfermieristicheAltro: false,
                noteInfermieristicheAltroTesto: "",
                patologieCorsoIntolleranzeAlimentari: false,
                patologieCorsoIntolleranzeAlimentariTesto: "",
                patologieCorsoNefropatia: false,
                patologieCorsoNefropatiaTesto: "",
                patologieCorsoFistole: false,
                patologieCorsoFistoleTesto: "",
                patologieCorsoMetabolica: false,
                patologieCorsoMetabolicaTesto: "",
                patologieCorsoCardiopatia: false,
                patologieCorsoCardiopatiaTesto: "",
                patologieCorsoRespiratoria: false,
                patologieCorsoRespiratoriaTesto: "",
                patologieCorsoOssigenoTerapia: false,
                patologieCorsoOssigenoTerapiaTesto: "",
                assunzioneFarmaciSi: false,
                assunzioneFarmaciSiTesto: "",
                assunzioneFarmaciSiSconosciuti: false,
                assunzioneFarmaciSiSconosciutiTesto: "",
                ausiliIncontinenzaCatetereVescicale: false,
                ausiliIncontinenzaSacchettoStomie: false,
                ausiliIncontinenzaPresidiAssorbenti: false,
                ausiliMovimentoSediaMotorizzata: false,
                ausiliMovimentoSediaManuale: false,
                ausiliMovimentoSistemaPosturale: false,
                ausiliMovimentoBastone: false,
                ausiliMovimentoDeambulatore: false,
                ausiliMovimentoArtoArtificiale: false,
                reperimentoLocoFarmaciNecessari: "",
                strutturaAssistitoGaranziaGestioneNecessita: "",
                // reperimentoLocoFarmaciNecessari: false,
                // strutturaAssistitoGaranziaGestioneNecessita: false,
                alloggioConsigliato: "",
                rifiutaDestinazione: false,
                rifiutaFirmaDocumento: false,
                sceltaRifiutoDataOra: new Date().getTime(),
            },
            provinceRegistrazioneOptions: [{ sigla: "", provincia: "-Seleziona Provincia-" }],
            comuniRegistrazioneOptions: [{ codiceIstat: "", comune: "-Seleziona Comune-" }],
            provinceNascitaOptions: [{ sigla: "", provincia: "-Seleziona Provincia-" }],
            comuniNascitaOptions: [{ codiceIstat: "", comune: "-Seleziona Comune-" }],
            provinceResidenzaOptions: [{ sigla: "", provincia: "-Seleziona Provincia-" }],
            comuniResidenzaOptions: [{ codiceIstat: "", comune: "-Seleziona Comune-" }],
            comportamentiOptions: [],
            tipiDisabilitaOptions: [],
            codiciValutazione: [],
            codiciValutazioneOptions: [],
            serviziOptions: [{ value: "", text: "-Seleziona Tipo Servizio-" }],
            noteInfermieristicheOptions: [{ value: "", text: "-Seleziona Note Infermieristiche-" }],
            patologieOptions: [{ value: "", text: "-Seleziona Patologie-" }],
            farmaciOptions: [{ value: "", text: "-Seleziona Risposta-" }],
            ausiliIncontinenzaOptions: [{ value: "", text: "-Seleziona Risposta-" }],
            ausiliMovimentoOptions: [{ value: "", text: "-Seleziona Risposta-" }],
            informazioniForniteOptions: [
                { value: "", text: "-Seleziona Fornitore-" },
                { value: "ASSISTITO", text: "ASSISTITO" },
                { value: "TERZA PERSONA", text: "TERZA PERSONA" },
            ],
            siNoOptions: [
                { value: "", text: "-Seleziona Risposta-" },
                { value: "Si", text: "SI" },
                { value: "NO", text: "NO" },
            ],
            alloggioOptions: [
                { value: "", text: "-Seleziona Risposta-" },
                { value: "AREA ACCOGLIENZA/ABITAZIONE PRIVATA", text: "AREA ACCOGLIENZA/ABITAZIONE PRIVATA" },
                { value: "ALBERGO", text: "ALBERGO" },
                { value: "STRUTTURA SOCIO-SANITARIA (es. RSA)", text: "STRUTTURA SOCIO-SANITARIA (es. RSA)" },
                { value: "OSPEDALE", text: "OSPEDALE" },
            ],
            rifiutoOptions: [{ value: "", text: "-Seleziona Risposta-" }],
            fieldsComportamenti: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Comportamento",
                    key: "risposta",
                    sortable: false,
                },
                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 4rem",
                    tdClass: "text-center",
                },
            ],
            fieldsDisabilita: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Tipo Disabilita",
                    key: "tipoDisabilita",
                    sortable: false,
                },
                {
                    label: "Altro",
                    key: "tipoDisabilitaAltro",
                    sortable: false,
                },
                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 4rem",
                    tdClass: "text-center",
                },
            ],
            fieldsNoteInfermieristiche: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Note Infermieristiche",
                    key: "noteInfermieristiche",
                    sortable: false,
                    thStyle: "width: 25rem",
                },
                {
                    label: "Altro",
                    key: "rispostaAltro",
                    sortable: false,
                },
                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 4rem",
                    tdClass: "text-center",
                },
            ],
            fieldsPatologie: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Patologia",
                    key: "risposta",
                    sortable: false,
                    thStyle: "width: 25rem",
                },
                {
                    label: "Altro",
                    key: "rispostaDettaglio",
                    sortable: false,
                },
                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 4rem",
                    tdClass: "text-center",
                },
            ],
            fieldsFarmaci: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Ass. Farmaci",
                    key: "risposta",
                    sortable: false,
                    thStyle: "width: 25rem",
                },
                {
                    label: "Altro",
                    key: "rispostaDettaglio",
                    sortable: false,
                },
                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 4rem",
                    tdClass: "text-center",
                },
            ],
            fieldsAusili: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Ausili",
                    key: "risposta",
                    sortable: false,
                    // thStyle: "width: 25rem",
                },
                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 4rem",
                    tdClass: "text-center",
                },
            ],
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        // disabledSanitario
        me.impostaPermessi();
        me.loadDefaultData();
    },
    methods: {
        loadDefaultData() {
            let me = this;
            me.loadProvinceRegistrazione();
            me.loadProvinceNascita();
            me.loadProvinceResidenza();
            me.loadCodiceValutazione();
            me.loadData();
        },
        impostaPermessi() {
            let me = this;
            me.disabledSanitario = false;
            me.disabledSanitario = !UtilityMixin.methods.verificaPermessi(Vue.prototype.user, "utenti", 2);
        },
        loadData() {
            let me = this;
            me.showModalLoading = true;
            //   console.log(me.id);
            if (me.id === "-1") {
                me.showModalLoading = false;
                me.jsonData.operatoreNome = Vue.prototype.user.firstname;
                me.jsonData.operatoreCognome = Vue.prototype.user.lastname;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                me.showModalLoading = true;
                axios
                    .get(link + me.id)
                    .then((response) => {
                        me.jsonData = response.data.data;
                        // Vue.prototype.user.lastname + " " + Vue.prototype.user.firstname
                        me.jsonData.operatoreNome = Vue.prototype.user.firstname;
                        me.jsonData.operatoreCognome = Vue.prototype.user.lastname;
                        me.showModalLoading = false;
                    })
                    .catch(() => {
                        me.showModalLoading = false;
                    });
            }
        },
        loadProvinceRegistrazione() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkProvince;
            axios.get(link).then((response) => {
                me.provinceRegistrazioneOptions = response.data.data;
                me.provinceRegistrazioneOptions.unshift({ sigla: "", provincia: "- Seleziona Provincia-" });
            });
        },
        loadProvinceRegistrazioneChange(value) {
            let me = this;
            me.provinceRegistrazioneOptions.forEach((provincia) => {
                if (provincia.sigla === value) {
                    me.setComuniRegistrazione(provincia.sigla);
                }
            });
        },
        setComuniRegistrazione(siglaProvincia) {
            let me = this;
            console.log(siglaProvincia);
            me.jsonData.provinciaRegistrazioneDescrizione = siglaProvincia;
            me.loadComuniRegistrazione(siglaProvincia);
            // me.provinceRegistrazioneOptions.forEach((provincia) => {
            //     if (provincia.sigla === siglaProvincia) {

            //     }
            // });
        },
        loadComuniRegistrazione(sigla) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkComune + "/" + sigla;
            axios.get(link).then((response) => {
                me.comuniRegistrazioneOptions = [];
                me.comuniRegistrazioneOptions = response.data.data;
                me.comuniRegistrazioneOptions.unshift({ codiceIstat: "", comune: "-Seleziona Comune-" });
            });
        },
        loadProvinceNascita() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkProvince;
            axios.get(link).then((response) => {
                me.provinceNascitaOptions = response.data.data;
                me.provinceNascitaOptions.unshift({ sigla: "", provincia: "- Seleziona Provincia-" });
            });
        },
        loadProvinceNascitaChange(value) {
            let me = this;
            me.provinceNascitaOptions.forEach((provincia) => {
                if (provincia.sigla === value) {
                    me.setDatiIndirizzoNascita(provincia.sigla);
                }
            });
        },
        setDatiIndirizzoNascita(siglaProvincia, codiceIstatComune = null) {
            let me = this;
            me.jsonData.assistitoComuneNascita = codiceIstatComune;
            me.provinceNascitaOptions.forEach((provincia) => {
                if (provincia.sigla === siglaProvincia) {
                    me.jsonData.assistitoProvinciaNascitaDescrizione = provincia.sigla;
                    me.loadComuniNascita(provincia.sigla);
                }
            });
        },
        loadComuniNascita(sigla) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkComune + "/" + sigla;
            axios.get(link).then((response) => {
                me.comuniNascitaOptions = [];
                me.comuniNascitaOptions = response.data.data;
                me.comuniNascitaOptions.unshift({ codiceIstat: "", comune: "-Seleziona Comune-" });
            });
        },
        loadProvinceResidenza() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkProvince;
            axios.get(link).then((response) => {
                me.provinceResidenzaOptions = response.data.data;
                me.provinceResidenzaOptions.unshift({ sigla: "", provincia: "- Seleziona Provincia-" });
            });
        },
        loadProvinceResidenzaChange(value) {
            let me = this;
            me.provinceResidenzaOptions.forEach((provincia) => {
                if (provincia.sigla === value) {
                    me.setDatiIndirizzoResidenza(provincia.sigla);
                }
            });
        },
        setDatiIndirizzoResidenza(siglaProvincia, codiceIstatComune = null) {
            let me = this;
            me.jsonData.assistitoComuneResidenzaCodiceIstat = codiceIstatComune;
            me.provinceResidenzaOptions.forEach((provincia) => {
                if (provincia.sigla === siglaProvincia) {
                    me.jsonData.assistitoProvinciaResidenzaDescrizione = provincia.sigla;
                    me.loadComuniResidenza(provincia.sigla);
                }
            });
        },
        loadComuniResidenza(sigla) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkComune + "/" + sigla;
            axios.get(link).then((response) => {
                me.comuniResidenzaOptions = [];
                me.comuniResidenzaOptions = response.data.data;
                me.comuniResidenzaOptions.unshift({ codiceIstat: "", comune: "-Seleziona Comune-" });
            });
        },
        loadComprtamenti() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkComportamenti;
            me.comportamentiOptions = [];
            axios.get(link).then((response) => {
                response.data.data.list.forEach((element) => {
                    me.comportamentiOptions.push({ value: element.descrizione, text: element.descrizione });
                });
                me.comportamentiOptions.unshift({ value: "", text: "-Seleziona Comportamento-" });
            });
        },
        loadTipiDisabilita() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkTipiDisabilita;
            me.tipiDisabilitaOptions = [];
            axios.get(link).then((response) => {
                response.data.data.list.forEach((element) => {
                    me.tipiDisabilitaOptions.push({ value: element.descrizione, text: element.descrizione });
                });
                me.tipiDisabilitaOptions.unshift({ value: "", text: "-Seleziona Tipo Disabilita-" });
            });
        },
        loadCodiceValutazione() {
            let me = this;
            me.codiciValutazione = [];
            me.codiciValutazioneOptions = [];
            let link = process.env.VUE_APP_PATH_API + this.linkCodiciValutazione;
            axios.get(link).then((response) => {
                me.codiciValutazione = response.data.data.list;
                me.setValutazione();
                response.data.data.list.forEach((element) => {
                    me.codiciValutazioneOptions.push({ text: element.descrizione, value: element.codice });
                });
                me.codiciValutazioneOptions.unshift({ text: "-Seleziona-", value: "" });
            });
        },
        onSetValutazione() {
            let me = this;
            me.setValutazione(me.jsonData.codiceValutazione);
        },
        setValutazione(value) {
            let me = this;
            me.codiciValutazione.forEach((element) => {
                if (value === element.codice) {
                    me.descrizioneValutazione = element.dettaglio;
                }
            });
        },
        loadServizi() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkServizi;
            me.serviziOptions = [];
            axios.get(link).then((response) => {
                response.data.data.list.forEach((element) => {
                    me.serviziOptions.push({ value: element.descrizione, text: element.descrizione });
                });
                me.serviziOptions.unshift({ value: "", text: "-Seleziona Tipo Servizio-" });
            });
        },
        loadNoteInfermieristiche() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkNoteInfermieristiche;
            me.noteInfermieristicheOptions = [];
            axios.get(link).then((response) => {
                response.data.data.list.forEach((element) => {
                    me.noteInfermieristicheOptions.push({ value: element.descrizione, text: element.descrizione });
                });
                me.noteInfermieristicheOptions.unshift({ value: "", text: "-Seleziona Note Infermieristiche-" });
            });
        },
        loadPatologie() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkPatologie;
            me.patologieOptions = [];
            axios.get(link).then((response) => {
                response.data.data.list.forEach((element) => {
                    me.patologieOptions.push({ value: element.descrizione, text: element.descrizione });
                });
                me.patologieOptions.unshift({ value: "", text: "-Seleziona Patologie-" });
            });
        },
        loadFarmaci() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkFarmaci;
            me.farmaciOptions = [];
            axios.get(link).then((response) => {
                response.data.data.list.forEach((element) => {
                    me.farmaciOptions.push({ value: element.descrizione, text: element.descrizione });
                });
                me.farmaciOptions.unshift({ value: "", text: "-Seleziona Risposta-" });
            });
        },
        loadAusiliIncontinenza() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkAusiliIncontinenza;
            me.ausiliIncontinenzaOptions = [];
            axios.get(link).then((response) => {
                response.data.data.list.forEach((element) => {
                    me.ausiliIncontinenzaOptions.push({ value: element.descrizione, text: element.descrizione });
                });
                me.ausiliIncontinenzaOptions.unshift({ value: "", text: "-Seleziona Risposta-" });
            });
        },
        loadAusiliMovimento() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkAusiliMovimento;
            me.ausiliMovimentoOptions = [];
            axios.get(link).then((response) => {
                response.data.data.list.forEach((element) => {
                    me.ausiliMovimentoOptions.push({ value: element.descrizione, text: element.descrizione });
                });
                me.ausiliMovimentoOptions.unshift({ value: "", text: "-Seleziona Risposta-" });
            });
        },
        loadRifiuto() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkRifiuto;
            me.rifiutoOptions = [];
            axios.get(link).then((response) => {
                response.data.data.list.forEach((element) => {
                    me.rifiutoOptions.push({ value: element.descrizione, text: element.descrizione });
                });
                me.rifiutoOptions.unshift({ value: "", text: "-Seleziona Risposta-" });
            });
        },
        onAddComportamento(array, value) {
            if (value.risposta !== "") {
                array.push({ risposta: "" });
            } else {
                this.$bvModal
                    .msgBoxOk("Campo risposta Obbligatorio!", {
                        title: "ATTENZIONE",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    })
                    .then((value) => {
                        console.log(value);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        onDeleteComportamento(array, row) {
            if (row.index != array.length) {
                this.$bvModal
                    .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
                        title: "Titolo",
                        buttonSize: "sm",
                        okVariant: "secondary",
                        cancelVariant: "danger",
                        okTitle: "SI",
                        cancelTitle: "NO",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true,
                    })
                    .then((value) => {
                        if (value) {
                            array.splice(row.index, 1);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        onAddTipoDisabilita(array, value) {
            if (value.tipoDisabilita !== "") {
                array.push({ tipoDisabilita: "" });
            } else {
                this.$bvModal
                    .msgBoxOk("Campo risposta Obbligatorio!", {
                        title: "ATTENZIONE",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    })
                    .then((value) => {
                        console.log(value);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        onDeleteTipoDisabilita(array, row) {
            if (row.index != array.length) {
                this.$bvModal
                    .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
                        title: "Titolo",
                        buttonSize: "sm",
                        okVariant: "secondary",
                        cancelVariant: "danger",
                        okTitle: "SI",
                        cancelTitle: "NO",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true,
                    })
                    .then((value) => {
                        if (value) {
                            array.splice(row.index, 1);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        onAddNoteInfermieristiche(array, value) {
            if (value.risposta !== "") {
                array.push({ risposta: "" });
            } else {
                this.$bvModal
                    .msgBoxOk("Campo risposta Obbligatorio!", {
                        title: "ATTENZIONE",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    })
                    .then((value) => {
                        console.log(value);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        onDeleteonAddNoteInfermieristiche(array, row) {
            if (row.index != array.length) {
                this.$bvModal
                    .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
                        title: "Titolo",
                        buttonSize: "sm",
                        okVariant: "secondary",
                        cancelVariant: "danger",
                        okTitle: "SI",
                        cancelTitle: "NO",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true,
                    })
                    .then((value) => {
                        if (value) {
                            array.splice(row.index, 1);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        onAddPatologie(array, value) {
            if (value.risposta !== "") {
                array.push({ risposta: "" });
            } else {
                this.$bvModal
                    .msgBoxOk("Campo risposta Obbligatorio!", {
                        title: "ATTENZIONE",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    })
                    .then((value) => {
                        console.log(value);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        onDeletePatologie(array, row) {
            if (row.index != array.length) {
                this.$bvModal
                    .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
                        title: "Titolo",
                        buttonSize: "sm",
                        okVariant: "secondary",
                        cancelVariant: "danger",
                        okTitle: "SI",
                        cancelTitle: "NO",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true,
                    })
                    .then((value) => {
                        if (value) {
                            array.splice(row.index, 1);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        onAddFarmaci(array, value) {
            if (value.risposta !== "") {
                array.push({ risposta: "" });
            } else {
                this.$bvModal
                    .msgBoxOk("Campo risposta Obbligatorio!", {
                        title: "ATTENZIONE",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    })
                    .then((value) => {
                        console.log(value);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        onDeleteFarmaci(array, row) {
            if (row.index != array.length) {
                this.$bvModal
                    .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
                        title: "Titolo",
                        buttonSize: "sm",
                        okVariant: "secondary",
                        cancelVariant: "danger",
                        okTitle: "SI",
                        cancelTitle: "NO",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true,
                    })
                    .then((value) => {
                        if (value) {
                            array.splice(row.index, 1);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        onAddAusili(array, value) {
            if (value.risposta !== "") {
                array.push({ risposta: "" });
            } else {
                this.$bvModal
                    .msgBoxOk("Campo risposta Obbligatorio!", {
                        title: "ATTENZIONE",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    })
                    .then((value) => {
                        console.log(value);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        onDeleteAusili(array, row) {
            if (row.index != array.length) {
                this.$bvModal
                    .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
                        title: "Titolo",
                        buttonSize: "sm",
                        okVariant: "secondary",
                        cancelVariant: "danger",
                        okTitle: "SI",
                        cancelTitle: "NO",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true,
                    })
                    .then((value) => {
                        if (value) {
                            array.splice(row.index, 1);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        onSearchAnagraficaClick() {
            let me = this;
            if (!me.codiceFiscaleValido)
                return me.$bvModal.msgBoxOk("Codice Fiscale non corretto", {
                    title: [this.$i18n.t("adi.lblError")],
                    size: "sm",
                    okVariant: "outline-danger",
                    headerClass: "sa-msg-header-danger",
                    footerClass: "p-2 border-top-0",
                    centered: true,
                });
            me.jsonData.assistitoCodiceFiscale = me.jsonData.assistitoCodiceFiscale.toUpperCase();
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/verificacodicefiscale/" + me.jsonData.assistitoCodiceFiscale;
            axios.get(link).then((response) => {
                me.$emit("update", me.jsonData);
                if (response.data.data === null) {
                    let link = process.env.VUE_APP_PATH_API + "/utility";
                    axios
                        .get(link, { params: { codiceFiscale: me.jsonData.assistitoCodiceFiscale } })
                        .then((response) => {
                            // console.log(response);
                            if (response.data.data !== null) {
                                me.jsonData.assistitoNome = response.data.data.nome;
                                me.jsonData.assistitoCognome = response.data.data.cognome;
                                me.jsonData.assistitoDataNascita = moment(response.data.data.dataNascita, "DD/MM/YYYY").toDate().getTime();
                                me.jsonData.assistitoComuneNascitaDescrizione = response.data.data.comuneNascita;
                                me.jsonData.assistitoProvinciaNascitaDescrizione = response.data.data.provinciaNascita;
                                me.jsonData.assistitoComuneResidenzaDescrizione = response.data.data.comuneResidenza;
                                me.jsonData.assistitoProvinciaResidenzaDescrizione = response.data.data.provinciaResidenza;
                                me.jsonData.assistitoIndirizzo = response.data.data.indirizzoResidenza;
                                me.jsonData.assistitoCodiceFiscale = response.data.data.codiceFiscale;
                                me.jsonData.asssititoMmg = response.data.data.medico;
                                me.jsonData.assistitoAsl = response.data.data.aslDiResidenza;
                                me.jsonData.assistitoComuneNascita = response.data.data.codiceIstatComuneNascita;
                                me.jsonData.assistitoComuneResidenzaCodiceIstat = response.data.data.codiceIstatComuneResidenza;
                                me.loadComuniNascita(me.jsonData.assistitoProvinciaNascitaDescrizione);
                                me.loadComuniResidenza(me.jsonData.assistitoProvinciaResidenzaDescrizione);

                                me.$emit("update", me.jsonData);
                            } else {
                                return me.$bvModal.msgBoxOk("Codice Fiscale non corretto", {
                                    title: "Errore",
                                    size: "sm",
                                    okVariant: "outline-danger",
                                    headerClass: "sa-msg-header-danger",
                                    footerClass: "p-2 border-top-0",
                                    centered: true,
                                });
                            }
                        })
                        .catch(() => {
                            me.$emit("update", me.jsonData);
                        });
                } else {
                    me.$emit("update", me.jsonData);
                    this.$bvModal
                        .msgBoxConfirm(response.data.messaggio, {
                            title: "Scheda SVEI gia' presente",
                            buttonSize: "sm",
                            okVariant: "secondary",
                            cancelVariant: "danger",
                            okTitle: "SI",
                            cancelTitle: "NO",
                            footerClass: "p-2",
                            hideHeaderClose: false,
                            centered: true,
                        })
                        .then((value) => {
                            if (value) {
                                let me = this;
                                me.$router.replace("/schedavalutazioneesigenzeimmediate/edit/" + response.data.data.id).catch((err) => {
                                    err;
                                });
                                me.id = response.data.data.id;
                                me.loadData();
                            } else {
                                // console.log("HO SCELTO NO");
                                let me = this;
                                me.$router.replace(me.linkback).catch((err) => {
                                    err;
                                });
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            });
        },
        checkRifiutante() {
            let me = this;
            me.jsonData.sceltaRifiutoNomeCognome = "";
            if (me.jsonData.informazioniSchedaFornita === "TERZA PERSONA") {
                me.disableSottoscritto = true;
                me.jsonData.sceltaRifiutoNomeCognome = me.jsonData.informazioniSchedaFornitaAltro;
            } else if (me.jsonData.informazioniSchedaFornita === "ASSISTITO") {
                me.jsonData.sceltaRifiutoNomeCognome = me.jsonData.assistitoCognome + " " + me.jsonData.assistitoNome;
            }
        },
    },
};
</script>

<style></style>
