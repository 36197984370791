<template>
    <div style="height: 100%; overflow: auto">
        <b-card no-body>
            <b-tabs content-class="mt-3" justified id="tabsSchede" nav-wrapper-class="sa-tab-items" ref="tabs" class="sa-tab" style="height: 100%; padding-bottom: 25px" fill>
                <b-tab title="ANAGRAFICA" active
                    ><b-card class="sa-card" header="Anagrafica" header-tag="header" footer-tag="footer" title="">
                        <b-row>
                            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                <label class="sa-label-data">Codice Fiscale</label>
                                <span>{{ jsonData.ospite.identificativo }}</span>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                <label class="sa-label-data">Cognome</label>
                                <span>{{ jsonData.ospite.cognome }}</span>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                <label class="sa-label-data">Nome</label>
                                <span>{{ jsonData.ospite.nome }}</span>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                <label class="sa-label-data">Data di nascita</label>
                                <span>{{ jsonData.ospite.dataNascita }}</span>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                <label class="sa-label-data">Comune di nascita</label>
                                <span>{{ jsonData.ospite.comuneNascita }}</span> </b-col
                            ><b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                <label class="sa-label-data">Sesso</label>
                                <span>{{ jsonData.ospite.sesso }}</span>
                            </b-col>
                        </b-row> </b-card
                    ><b-card class="sa-card" header="Altre info" header-tag="header" footer-tag="footer" title=""
                        ><b-row
                            ><b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label class="sa-label-data">Numero di telefono</label>
                                <span>{{ jsonData.ospite.telefono }}</span>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label class="sa-label-data">Email</label>
                                <span>{{ jsonData.ospite.email }}</span>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label class="sa-label-data">Decreto invadilità</label>
                                <span>{{ jsonData.ospite.decretoInvalidita }}</span> </b-col
                            ><b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label class="sa-label-data">Medico curante</label>
                                <span>{{ jsonData.ospite.medicoCurante }}</span>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label class="sa-label-data">Email medico curante</label>
                                <span>{{ jsonData.ospite.emailMedicoCurante }}</span>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label class="sa-label-data">Scadenza fogliettino rosa</label>
                                <span>{{ jsonData.ospite.scadenzaFogliettinoRosa }}</span>
                            </b-col></b-row
                        ></b-card
                    >
                    <b-card class="sa-card" header="Diagnosi" header-tag="header" footer-tag="footer" title="">
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <span>{{ jsonData.ospite.diagnosi }}</span>
                        </b-col></b-card
                    >
                </b-tab>
                <b-tab title="RESIDENZA">
                    <b-card class="sa-card" header="Residenza" header-tag="header" footer-tag="footer" title=""
                        ><b-row>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label class="sa-label-data">Provincia</label>
                                <span>{{ jsonData.ospite.provinciaResidenza }}</span>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label class="sa-label-data">Comune</label>
                                <span>{{ jsonData.ospite.comuneResidenza }}</span>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label class="sa-label-data">Indirizzo</label>
                                <span>{{ jsonData.ospite.indirizzoResidenza }}</span></b-col
                            ><b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label class="sa-label-data">CAP</label>
                                <span>{{ jsonData.ospite.capResidenza }}</span>
                            </b-col>
                        </b-row></b-card
                    ><b-card class="sa-card" header="Domicilio" header-tag="header" footer-tag="footer" title=""
                        ><b-row>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label class="sa-label-data">Provincia</label>
                                <span>{{ jsonData.ospite.provinciaDomicilioSanitario }}</span>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label class="sa-label-data">Comune</label>
                                <span>{{ jsonData.ospite.comuneDomicilioSanitario }}</span>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label class="sa-label-data">Indirizzo</label>
                                <span>{{ jsonData.ospite.indirizzoDomicilioSanitario }}</span>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label class="sa-label-data">CAP</label>
                                <span>{{ jsonData.ospite.capDomicilioSanitario }}</span>
                            </b-col>
                        </b-row></b-card
                    >
                    <b-card class="sa-card" header="Asl di riferimento" header-tag="header" footer-tag="footer" title=""
                        ><b-row>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label class="sa-label-data">Comune</label>
                                <span>{{ jsonData.ospite.comuneStorico }}</span></b-col
                            ><b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label class="sa-label-data">ASL</label>
                                <span>{{ jsonData.ospite.asl }}</span></b-col
                            ><b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label class="sa-label-data">Ambito</label>
                                <span>{{ jsonData.ospite.ambito }}</span>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label class="sa-label-data">Distretto</label>
                                <span>{{ jsonData.ospite.distretto }}</span>
                            </b-col>
                        </b-row></b-card
                    ></b-tab
                ><b-tab title="FAMILIARI">
                    <b-card class="sa-card" header="Familiari" header-tag="header" footer-tag="footer" title="">
                        <b-table sticky-header ref="table" stacked="lg" striped hover :items="jsonData.listaFamiliari" :fields="fields" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
                            <template #cell(index)="jsonData">
                                {{ jsonData.index + 1 }}
                            </template>
                            <template #cell(nominativo)="item">
                                <span class="sa-data">{{ item.item.nominativo }}</span>
                            </template>
                            <template #cell(telefono)="item">
                                <span class="sa-data">{{ item.item.telefono }} </span>
                            </template>
                            <template #cell(email)="item">
                                <span class="sa-data">{{ item.item.email }} </span>
                            </template>
                            <template #cell(parentela)="item">
                                <span class="sa-data">{{ item.item.parentela }} </span>
                            </template>
                        </b-table></b-card
                    ></b-tab
                ><b-tab title="ESENZIONI">
                    <b-card class="sa-card" header="Esenzioni" header-tag="header" footer-tag="footer" title="">
                        <b-table sticky-header ref="table" stacked="lg" striped hover :items="jsonData.esenzioni" :fields="fieldsEsenzioni" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
                            <template #cell(index)="jsonData">
                                {{ jsonData.index + 1 }}
                            </template>
                            <template #cell(etichetta)="item">
                                <span class="sa-data">{{ item.item.etichetta }}</span>
                            </template>
                            <template #cell(dataInserimento)="item">
                                <span class="sa-data">{{ item.item.dataInserimento }} </span>
                            </template>
                            <template #cell(scadenza)="item">
                                <span class="sa-data">{{ item.item.scadenza }} </span>
                            </template>
                        </b-table>
                    </b-card></b-tab
                ></b-tabs
            ></b-card
        >
    </div>
</template>
<script>
import "vue2-datepicker/index.css";
import UtilityMixin from "../../../utility/UtilityMixin";
export default {
    data() {
        return {
            pathResource: "/strutturepresidi",
            linkStrutture: "/strutture",
            showModalLoading: false,
            id: null,
            listKey: 0,
            rows: 0,
            perPage: 50,
            currentPage: 1,
            jsonData: {
                ospite: {
                    identificativo: null,
                    nome: null,
                    cognome: null,
                    dataNascita: null,
                    sesso: null,
                    comuneNascita: null,
                    telefono: null,
                    email: null,
                    decretoInvalidita: null,
                    medicoCurante: null,
                    emailMedicoCurante: null,
                    autonomo: false,
                    privacy: false,
                    diagnosi: null,
                    comuneStorico: null,
                    asl: null,
                    ambito: null,
                    distretto: null,
                    comuneResidenza: null,
                    provinciaResidenza: null,
                    capResidenza: null,
                    indirizzoResidenza: null,
                    comuneDomicilioSanitario: null,
                    provinciaDomicilioSanitario: null,
                    capDomicilioSanitario: null,
                    indirizzoDomicilioSanitario: null,
                    scadenzaFogliettinoRosa: null,
                },
                listaFamiliari: [{ nominativo: "", parentela: "", telefono: "", email: "" }],
                esenzioni: [
                    {
                        etichetta: "",
                        dataInserimento: null,
                        scadenza: null,
                    },
                ],
            },
            fields: [
                { label: "", key: "index" },
                {
                    label: "Nominativo",
                    key: "nominativo",
                },
                {
                    label: "Numero telefono",
                    key: "telefono",
                },
                {
                    label: "Email",
                    key: "email",
                },
                {
                    label: "Parentela",
                    key: "parentela",
                },
                {
                    label: "",
                    key: "actions",
                },
            ],
            fieldsEsenzioni: [
                { label: "", key: "index" },
                {
                    label: "Etichetta",
                    key: "etichetta",
                },
                {
                    label: "Data inserimento",
                    key: "dataInserimento",
                },
                {
                    label: "Scadenza",
                    key: "scadenza",
                },
            ],
        };
    },
    mounted: function () {},
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            UtilityMixin.methods.loadDataResources(me.pathResource, {}, me.loadDataSuccess, me.loadDataError);
            // let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            // axios.get(link + me.id).then((response) => {
            //   me.jsonData = response.data.data;
            //   me.showModalLoading = false;
            // });
        },
        loadDataSuccess(resposne) {
            console.log(resposne);
        },
        loadDataError(resposne) {
            console.log(resposne);
        },
    },
};
</script>
