<template>
    <sa-page-layout slot="table-body" :btnConfigurazioniVisible="btnConfigurazioniVisible" :btnRefreshVisible="true" :pathResource="pathResourcePaiRuoli" @refresh="onRefresh" :showModalLoading="showModalLoading">
        <template slot="toolbar">
            <b-button class="float-sm-right" variant="success btn-toolbar sa-margin-right" size="sm" @click="onClickNuovoRuoloPai">
                <b-icon icon="plus"></b-icon>
                Nuovo
            </b-button>
        </template>
        <template slot="table-filter">
            <b-form @submit.prevent="onSubmit">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <label class="sa-label-data">Ruolo</label>
                        <b-form-input v-model="filtroPaiRuoli.ruolo" type="search" id="ruolo" placeholder="Inserire Ruolo"></b-form-input>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="sa-padding-right pt-3 pb-3 text-right">
                        <b-button type="submit" variant="info"> {{ this.$i18n.t("patients.lblFind") }}</b-button>
                        <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("patients.lblResetFilter") }}</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </template>

        <template slot="table-body">
            <rsa-pai-ruoli-list-component ref="cmpRsaPaiRuoliList" @afterLoadData="onAfterLoadData" @beforeLoadData="onBeforeLoadData"> </rsa-pai-ruoli-list-component>
            <rsa-pai-ruoli-edit-component ref="cmpRsaPaiRuoliEdit" @onCloseModal="loadData" @onSaveRuoloPai="onRuoloSave"></rsa-pai-ruoli-edit-component>
        </template>
    </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import RsaPaiRuoliEditComponent from "../components/RsaPaiRuoliEditComponent.vue";
import RsaPaiRuoliListComponent from "../components/RsaPaiRuoliListComponent.vue";

export default {
    components: { SaPageLayout, RsaPaiRuoliListComponent, RsaPaiRuoliEditComponent },
    data() {
        return {
            filtroPaiRuoli: {
                ruolo: null,
            },
            pathResourcePaiRuoli: "",
            showModalLoading: false,
            btnConfigurazioniVisible: true,
        };
    },
    mounted() {
        let me = this;
        me.loadData();
    },

    methods: {
        loadData() {
            let me = this;
            me.$refs.cmpRsaPaiRuoliList.loadData();
        },
        onSubmit() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtroPaiRuoli"] = JSON.stringify(me.filtroPaiRuoli);
            me.loadData();
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        onAfterLoadData() {
            let me = this;
            me.showModalLoading = false;
        },
        onBeforeLoadData() {
            let me = this;
            me.showModalLoading = true;
        },
        onClickNuovoRuoloPai() {
            let me = this;
            me.$refs.cmpRsaPaiRuoliEdit.nuovoRuoloPai();
        },
        onReset() {
            let me = this;
            me.filtroPaiRuoli = { ruolo: null };
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtroPaiRuoli"] = JSON.stringify(me.filtroPaiRuoli);
            me.loadData();
        },
        onRuoloSave() {
            let me = this;
            me.loadData();
        },
    },
};
</script>
