import RsaGestioneOspitiList from "./pages/RsaGestioneOspitiList.vue";
import RsaGestioneOspitiArchivioList from "./pages/RsaGestioneOspitiArchivioList.vue";
import RsaGestioneOspitiEdit from "./pages/RsaGestioneOspitiEdit.vue";
import RsaGestioneOspitiView from "./pages/RsaGestioneOspitiView.vue";
import RsaGestioneOspitiSchedaOspiteViewPage from "./pages/RsaGestioneOspitiSchedaOspiteViewPage.vue";
import RsaGestioneRefertiListPage from "./pages/RsaGestioneRefertiListPage.vue";
import RsaPaiEditPage from "./pages/RsaPaiEditPage.vue";

/*ADI*/
import RsaSchedaPazientePage from "./pages/RsaSchedaPazientePage.vue";

export default {
	RsaGestioneOspitiList,
	RsaGestioneOspitiArchivioList,
	RsaGestioneOspitiEdit,
	RsaGestioneOspitiView,
	RsaGestioneOspitiSchedaOspiteViewPage,
	RsaGestioneRefertiListPage,
	RsaPaiEditPage,
	RsaSchedaPazientePage,
};
