<template>
    <b-tabs id="tbasRsaRefertiList" ref="tbasRsaRefertiList" v-model="activeTabIndex" nav-wrapper-class="sa-tab-items" content-class="mt-3" class="sa-tab" style="height: 100%; padding-bottom: 25px" fill @input="onInputTabs">
        <b-tab title="Referti" id="tbReferti">
            <div class="sa-flexbox" style="padding-bottom: 20px">
                <div style="margin-left: 0.5rem; margin-right: 1.5rem">
                    <span class="sa-flexbox-body-title">Referti</span>
                    <b-button size="sm" variant="outline-success outline-primary-noborder float-sm-right" :hidden="false" @click="onClickNuovoAllegato" class="mb-2">
                        <font-awesome-icon icon="plus"> </font-awesome-icon>
                        Aggiungi
                    </b-button>
                </div>
                <allegato-model-component ref="cmpAllegatoModel" :disableRefresh="true" :idRisorsa="idAccettazione" @updateFiles="onUpdateFile()" :jsonData="jsonDataAccettazione"> </allegato-model-component>
                <b-modal ref="mdlAllegati" id="mdlAllegati" size="xl" style="height: 100%" @show="onShowModalAllegati" @hidden="onHideModalAllegati">
                    <paziente-allegati-edit-component :jsonData="jsonDataAllegato" tipoRisorsa="EVENTO"></paziente-allegati-edit-component>
                    <template #modal-footer="{ btnSalva }">
                        <b-button :id="btnSalva" size="sm" variant="success" @click="onSalvaAllegato">Salva</b-button>
                    </template>
                </b-modal>
            </div>
            <!--   </div> -->
        </b-tab>
        <b-tab title="Richieste Laboratorio" id="tbRichiesteLaboratorio">
            <div class="sa-flexbox" style="padding-bottom: 20px">
                <div class="sa-flexbox-header">
                    <b-form @submit.prevent="onSubmit">
                        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                <label for="">Struttura Erogatrice:</label>
                                <b-form-input v-model="filtro.descrizioneLaboratorio" type="search"></b-form-input>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                <label>Tipo:</label>
                                <b-form-input v-model="filtro.tipo" type="search"></b-form-input>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                <label>Esame:</label>
                                <b-form-input v-model="filtro.descrizioneEsame" type="search"></b-form-input>
                            </b-col>
                            <b-col cols="12" sm="12" md="6" lg="6" xl="6">
                                <label class="sa-label-data">Data richiesta dal:</label>
                                <b-input-group class="mb-3">
                                    <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="filtro.dataRichiestaDal" type="search"></date-picker>
                                </b-input-group>
                            </b-col>
                            <b-col cols="12" sm="12" md="6" lg="6" xl="6">
                                <label class="sa-label-data">Data richiesta al:</label>
                                <b-input-group class="mb-3">
                                    <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="filtro.dataRichiestaAl" type="search"></date-picker>
                                </b-input-group>
                            </b-col>
                        </b-row>
                        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="sa-padding-right pt-3 pb-3 text-right">
                                <b-button type="submit" variant="info">{{ this.$i18n.t("patients.lblFind") }}</b-button>
                                <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("patients.lblResetFilter") }}</b-button>
                            </b-col>
                        </b-row>
                    </b-form>
                </div>
                <div class="sa-flexbox-body">
                    <div class="b-table-sticky-header">
                        <span class="sa-flexbox-body-title">Richieste laboratorio</span>
                        <b-button size="sm" variant="outline-success outline-primary-noborder sa-margin-right float-sm-right" :hidden="false" @click="onNuovaRichiesta" class="mb-2">
                            <font-awesome-icon icon="plus"> </font-awesome-icon>
                            Aggiungi
                        </b-button>
                        <b-table sticky-header ref="table" stacked="lg" striped hover :items="items" :fields="visibleFields" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
                            <template v-slot:cell(nominativo)="{ item }">
                                <span>
                                    <b>{{ item.cognome }} {{ item.nome }}</b></span
                                >
                                <br />
                                <span>{{ item.identificativo }} </span>
                            </template>
                            <template v-slot:cell(strutturaErogatrice)="{ item }">
                                <span>{{ item.descrizioneLaboratorio }} ({{ item.codiceLaboratorio }})</span>
                            </template>
                            <template v-slot:cell(tipo)="{ item }">
                                <span>{{ item.tipo }}</span>
                            </template>
                            <template v-slot:cell(esame)="{ item }">
                                <span>{{ item.esame }}</span>
                            </template>
                            <template v-slot:cell(dataRichiesta)="{ item }">
                                <span>{{ formatDate(item.dataRichiesta) }}</span>
                            </template>
                            <template v-slot:cell(dataOraPrelievo)="{ item }">
                                <span>{{ formatDateTime(item.dataOraPrelievo) }}</span>
                            </template>
                            <template v-slot:cell(statoAttuale)="{ item }">
                                <span :class="getClassStato(item.statoAttuale)">{{ item.statoAttuale }}</span>
                            </template>
                            <template #row-details>
                                <div class="sa-table-row-details">
                                    <b-table sticky-header ref="tblReferti" stacked="lg" striped hover :items="itemsReferti" :fields="fieldsReferti" sort-icon-left head-variant="light" class="sa-b-table">
                                        <template v-slot:cell(createDate)="{ item }">
                                            <span>{{ formatDateTime(item.createDate) }}</span>
                                        </template>
                                        <template v-slot:cell(actions)="row">
                                            <b-container class="bv-example-row">
                                                <b-button size="sm" v-b-tooltip.hover title="Visualizza PDF" variant="outline-secondary no-text" @click="onClickVisualizzaPdf(row)">
                                                    <i class="bi bi-filetype-pdf"></i>
                                                </b-button>
                                            </b-container>
                                        </template>
                                    </b-table>
                                </div>
                            </template>
                            <template v-slot:cell(actions)="row">
                                <b-container class="bv-example-row">
                                    <b-button v-if="row.item.statoAttuale === 'NON INVIATO' || row.item.statoAttuale === 'ERROR'" size="sm" v-b-tooltip.hover title="Invia a Laboratorio" variant="outline-success" @click="onClickSend(row.item, 'INVIO IN CORSO')">
                                        <font-awesome-icon icon="paper-plane"></font-awesome-icon>
                                    </b-button>

                                    <b-button v-if="row.item.statoAttuale === 'NON INVIATO' || row.item.statoAttuale === 'ERROR'" size="sm" v-b-tooltip.hover title="Modifica Richiesta" variant="outline-success" @click="onEditRichiestaEsame(row.item)">
                                        <font-awesome-icon icon="edit"></font-awesome-icon>
                                    </b-button>
                                    <b-button v-if="row.item.statoAttuale === 'ATTESA REFERTO'" size="sm" v-b-tooltip.hover title="Stampa etichetta" variant="outline-success no-text" @click="onClickStampaEtichetta(row.item)">
                                        <b-icon icon="printer"></b-icon>
                                    </b-button>
                                    <!-- v-if="item.statoAttuale === 'ERROR'" bi bi-info-square-->
                                    <b-button size="sm" v-b-tooltip.hover title="Info" variant="outline-primary" @click="onClickInfo(row)">
                                        <font-awesome-icon icon="info-circle" />
                                    </b-button>
                                    <b-button size="sm" v-b-tooltip.hover title="Visualizza Referti" variant="outline-secondary" @click="onClickVisualizzaDettagli(row)">
                                        <font-awesome-icon icon="file-medical"></font-awesome-icon>
                                    </b-button>
                                    <b-button v-if="row.item.statoAttuale === 'NON INVIATO'" size="sm" v-b-tooltip.hover title="Cancella Richiesta" variant="outline-danger" @click="onDeleteRichiestaEsame(row.item)">
                                        <font-awesome-icon icon="trash"></font-awesome-icon>
                                    </b-button>
                                </b-container>
                            </template>
                        </b-table>
                    </div>
                </div>
                <div class="sa-flexbox-footer" style="background-color: ciano">
                    <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                        <b-col lg="3">
                            <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rows }}</p>
                        </b-col>
                        <b-col lg="9">
                            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                        </b-col>
                    </b-row>
                </div>
                <rsa-gestione-ospiti-edit-richiesta-esame-component ref="cmpRsaGestioneOspitiEditRichiestaEsameComponent" @beforeLoadData="onBeforeLoadDataRsaGestioneOspitiEditRichiestaEsameComponent" @afterLoadData="onAfterLoadDataRsaGestioneOspitiEditRichiestaEsameComponent" @beforeSaveRequest="onBeforeSaveRequestRsaGestioneOspitiEditRichiestaEsameComponent" @afterSaveRequest="onAfterSaveRequestRsaGestioneOspitiEditRichiestaEsameComponent"></rsa-gestione-ospiti-edit-richiesta-esame-component>
                <rsa-gestione-ospiti-richiesta-esame-info-list-component ref="cmpRsaGestioneOspitiRichiestaEsameInfoListComponent" @beforeLoadData="onBeforeLoadDataRsaGestioneOspitiEditRichiestaEsameComponent" @afterLoadData="onAfterLoadDataRsaGestioneOspitiEditRichiestaEsameComponent"></rsa-gestione-ospiti-richiesta-esame-info-list-component>
                <hl7-integrator-log-view-modal-component ref="cmpHl7IntegratorLogViewModalComponent"></hl7-integrator-log-view-modal-component>
                <sa-modal-loading ref="cmpSaModalLoading" />
                <pdf-print-view-embedded ref="pdfPrintViewEmbedded" :pdf="signedPdfViewer"></pdf-print-view-embedded>
            </div>
        </b-tab>
    </b-tabs>
</template>

<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import UtilityMixin from "../../../utility/UtilityMixin";
import RsaGestioneOspitiEditRichiestaEsameComponent from "./RsaGestioneOspitiEditRichiestaEsameComponent.vue";
import { SaModalLoading } from "../../../../template/components/modal";
import RsaGestioneOspitiRichiestaEsameInfoListComponent from "./RsaGestioneOspitiRichiestaEsameInfoListComponent.vue";
import Hl7IntegratorLogViewModalComponent from "../../../utility/hl7integrator/components/Hl7IntegratorLogViewModalComponent.vue";
import PdfPrintViewEmbedded from "../../../utility/components/PdfPrintViewEmbedded.vue";
import AllegatoModelComponent from "../../../utility/components/AllegatoModelComponent.vue";
import PazienteAllegatiEditComponent from "../../../paziente/allegati/components/PazienteAllegatiEditComponent.vue";
// import CodiciIcdIxListComponent from "../../../configurazione/components/CodiciIcdIxListComponent.vue";
export default {
    props: {
        jsonDataAccettazione: {
            type: Object,
            default: function () {
                return { id: null };
            },
        },
        idAccettazione: { type: String, default: null },

        visualizzaDatiPaziente: {
            type: Boolean,
            default: function () {
                return true;
            },
        },
    },
    mixins: [UtilityMixin],
    components: {
        DatePicker,
        SaModalLoading,
        RsaGestioneOspitiEditRichiestaEsameComponent,
        RsaGestioneOspitiRichiestaEsameInfoListComponent,
        Hl7IntegratorLogViewModalComponent,
        PdfPrintViewEmbedded,
        AllegatoModelComponent,
        PazienteAllegatiEditComponent,
    },
    computed: {
        /*
        jsonDataAccettazione() {
            let me = this;
            return me.jsonDataAccettazione;
        }, */

        visibleFields() {
            return this.fields.filter((field) => field.visible);
        },
    },
    data() {
        return {
            rows: 0,
            perPage: 50,
            currentPage: 1,
            filtro: {},
            items: [],
            listKey: 0,
            pathResource: "/rsarichiestelaboratorio",
            pathResourceSend: "/rsarichiestelaboratorio/invia",
            pathResourceStampaEtichetta: "/rsarichiestelaboratorio/downloadetichetta",
            pathResourceRepository: "/documentrepository",
            pathResourceAllegati: "/allegati",
            signedPdfViewer: null,
            // jsonDataAccettazione: {},
            jsonDataAllegato: {},
            activeTabIndex: 0,
            fields: [
                {
                    label: "Cognome/Nome",
                    key: "nominativo",
                    sortable: false,
                    visible: this.visualizzaDatiPaziente,
                },
                {
                    label: "Cod. Richiesta",
                    key: "codiceRichiesta",
                    sortable: false,
                    visible: true,
                },
                {
                    label: "Struttura Erogatrice",
                    key: "strutturaErogatrice",
                    sortable: true,
                    visible: true,
                },
                {
                    label: "Tipo",
                    key: "tipo",
                    sortable: true,
                    visible: true,
                },
                {
                    label: "Esame",
                    key: "descrizioneEsame",
                    visible: true,
                },
                { label: "Data Richiesta", key: "dataRichiesta" },
                {
                    label: "Data e Ora Prelievo",
                    key: "dataOraPrelievo",
                    visible: true,
                },
                {
                    label: "Stato",
                    key: "statoAttuale",
                    visible: true,
                },
                { class: "sa-table-column-action-double", key: "actions", label: "", visible: true }, ///, thStyle: "width: 15rem"
            ],
            itemsReferti: [],
            fieldsReferti: [
                {
                    label: "Rata/Ora Ricezione",
                    key: "createDate",
                    visible: true,
                },
                {
                    label: "Tipo",
                    key: "tipoDocumento",
                    sortable: false,
                },
                { class: "sa-table-column-action-double", key: "actions", label: "", visible: true }, ///, thStyle: "width: 15rem"
            ],
        };
    },
    mounted() {
        let me = this;
        me.loadData();
    },
    watch: {
        currentPage() {
            let me = this;
            me.loadData();
        },
    },
    methods: {
        onInputTabs(value) {
            let me = this;
            let activeTab = this.$refs.tbasRsaRefertiList.getTabs()[value];

            me.activeTab(activeTab.id);
        },
        activeTab(idActiveTab) {
            let me = this;
            switch (idActiveTab) {
                case "tbReferti":
                    me.$refs.cmpAllegatoModel.loadData();
                    break;
                case "tbRichiesteLaboratorio":
                    me.loadData();
                    break;
            }
        },
        getClassStato(value) {
            let returnValue = "";
            switch (value) {
                case "ERROR":
                    returnValue = "text-danger";
                    break;
            }
            return returnValue;
        },
        loadData(/* jsonDataAccettazione */) {
            let me = this;
            let sessionStorage = window.sessionStorage;
            if (sessionStorage["filtro"]) {
                me.filtro = JSON.parse(sessionStorage["filtro"]);
            }
            // if (jsonDataAccettazione) {
            //     me.jsonDataAccettazione = jsonDataAccettazione;
            // }
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.filtro.page = me.currentPage;
            me.filtro.forPage = me.perPage;
            me.filtro.idAccettazione = me.jsonDataAccettazione.id;
            me.$emit("beforeLoadData");
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];
                    me.rows = response.data.data.recordsNumber;
                    me.items = response.data.data.list;
                    me.listKey++;
                    this.$emit("afterLoadData");
                })
                .catch(() => {
                    this.$emit("afterLoadData");
                });
        },
        onReset() {
            let me = this;
            me.filtro = {};
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
        onNuovaRichiesta() {
            let me = this;
            me.openEditModal("-1");
        },
        onEditRichiestaEsame(item) {
            let me = this;
            me.openEditModal(item.id);
        },
        openEditModal(id) {
            let me = this;
            me.$refs.cmpRsaGestioneOspitiEditRichiestaEsameComponent.setJsonDataAccettazione(me.jsonDataAccettazione);
            me.$refs.cmpRsaGestioneOspitiEditRichiestaEsameComponent.setIdRichiestaEsami(id);
            me.$refs.cmpRsaGestioneOspitiEditRichiestaEsameComponent.show();
        },
        onSubmit() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
        onBeforeLoadDataRsaGestioneOspitiEditRichiestaEsameComponent() {
            let me = this;
            me.$emit("beforeLoadData");
        },
        onAfterLoadDataRsaGestioneOspitiEditRichiestaEsameComponent() {
            let me = this;
            me.$emit("afterLoadData");
        },
        onBeforeSaveRequestRsaGestioneOspitiEditRichiestaEsameComponent() {
            let me = this;
            me.$emit("beforeLoadData");
        },
        onAfterSaveRequestRsaGestioneOspitiEditRichiestaEsameComponent() {
            let me = this;
            me.$refs.cmpRsaGestioneOspitiEditRichiestaEsameComponent.close();
            this.$bvToast.toast(`Richiesta salvata correttamente`, {
                title: "Richiesta Esami",
                solid: true,
                variant: "success",
                autoHideDelay: 5000,
                appendToast: true,
            });
            me.loadData();
        },
        onClickSend(item) {
            // console.log(item);
            let me = this;
            me.$refs.cmpSaModalLoading.show();
            let link = process.env.VUE_APP_PATH_API + me.pathResourceSend + "/" + item.id;
            axios
                .put(link)
                .then(() => {
                    me.$refs.cmpSaModalLoading.close();
                    me.$refs.cmpRsaGestioneOspitiEditRichiestaEsameComponent.close();
                    me.loadData();
                    this.$bvToast.toast(`Richiesta inoltrata`, {
                        title: "Richiesta Esami",
                        solid: true,
                        variant: "success",
                        autoHideDelay: 5000,
                        appendToast: true,
                    });
                })
                .catch(() => {
                    me.$refs.cmpSaModalLoading.close();
                    this.$emit("afterLoadData");
                });
        },
        onClickInfo(value) {
            let me = this;
            me.$refs.cmpHl7IntegratorLogViewModalComponent.show(value.item.id);
        },
        onClickStampaEtichetta(item) {
            let me = this;
            me.$refs.cmpSaModalLoading.show();
            let link = process.env.VUE_APP_PATH_API + me.pathResourceStampaEtichetta + "/" + item.id;
            axios
                .get(link)
                .then((response) => {
                    me.$refs.cmpSaModalLoading.close();
                    // var blob = new Blob([response.data.data.etichettaBase64]);
                    let base64Decoded = atob([response.data.data.etichettaBase64]);
                    // console.log(base64Decoded);

                    var blob = new Blob([base64Decoded]);
                    var downloadElement = document.createElement("a");
                    var href = window.URL.createObjectURL(blob); //create the download url
                    downloadElement.href = href;
                    downloadElement.download = "etichetta.eti"; //the name of the downloaded file
                    document.body.appendChild(downloadElement);
                    downloadElement.click(); //click to file
                    document.body.removeChild(downloadElement); //remove the element
                    window.URL.revokeObjectURL(href);
                })
                .catch(() => {
                    me.$refs.cmpSaModalLoading.close();
                });
        },
        onClickVisualizzaDettagli(row) {
            let me = this;
            if (!row.detailsShowing) {
                me.loadReferti(row);
            }
            row.toggleDetails();
        },
        loadReferti(row) {
            let me = this;
            // console.log(row);
            UtilityMixin.methods.loadDataResources(me.pathResourceRepository, { idRisorsa: row.item.codiceRichiesta }, me.loadRefertiSuccess, me.loadRefertiError);
        },
        loadRefertiSuccess(response) {
            let me = this;
            me.itemsReferti = response.data.data.list;
        },
        loadRefertiError(response) {
            console.log(response);
        },
        onClickVisualizzaPdf(row) {
            let me = this;
            me.$refs.pdfPrintViewEmbedded.show();
            me.signedPdfViewer = "data:application/pdf;base64," + row.item.base64;
        },
        onShowModalAllegati() {
            let me = this;
            me.jsonDataAllegato = { idRisorsa: me.idAccettazione, file: null, size: null, tipo: null, dataCaricamento: null, descrizione: null, tipoDocumento: null };
        },
        onHideModalAllegati() {
            let me = this;
            me.jsonDataAllegato = { idRisorsa: me.idAccettazione, file: null, size: null, tipo: null, dataCaricamento: null, descrizione: null, tipoDocumento: null };
        },
        onClickNuovoAllegato() {
            let me = this;
            me.jsonDataAllegato = { idRisorsa: me.idAccettazione, file: null, size: null, tipo: null, dataCaricamento: null, descrizione: null, tipoDocumento: null };
            me.$refs.mdlAllegati.show();
        },
        onSalvaAllegato() {
            let me = this;
            let obj = me.getDataPdf(me.jsonDataAllegato);
            UtilityMixin.methods.saveResource(me.pathResourceAllegati, obj, me.resultSaveAllegatoResponse, me.resultSaveAllegatoError);
        },
        resultSaveAllegatoResponse() {
            let me = this;
            me.$refs.cmpAllegatoModel.loadData();
            this.$refs["mdlAllegati"].hide();
            me.showModalLoading = false;
            this.$bvToast.toast(`${this.jsonDataAllegato.nomeFile}`, {
                title: "File Upload",
                autoHideDelay: 2000,
                variant: "success",
            });
        },
        resultSaveAllegatoError(error) {
            this.$bvModal.msgBoxOk(error.response.data.messaggio, {
                title: "ATTENZIONE",
                size: "sm",
                okVariant: "outline-danger",
                headerClass: "sa-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
            });
        },
        getDataPdf(obj) {
            let objPdf = {};
            objPdf.idRisorsa = obj.idRisorsa;
            objPdf.idAnagrafica = obj.idPaziente;
            objPdf.nomeFile = obj.nomeFile;
            objPdf.size = obj.size;
            objPdf.file = obj.file;
            objPdf.tipo = obj.tipo;
            objPdf.dataCaricamento = obj.dataCaricamento;
            objPdf.descrizione = obj.descrizione;
            objPdf.tipoDocumento = obj.tipoDocumento;
            return objPdf;
        },
        onUpdateFile() {
            let me = this;
            me.$refs.cmpAllegatoModel.loadData();
        },
    },
};
</script>
