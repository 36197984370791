<template>
    <div>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Vive in collettivita'?</label>
                    <b-form-select v-model="jsonData.viveCollettivita" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Indirizzo collettivita':</label>
                    <b-form-input v-model="jsonData.viveCollettivitaDenominazioneIndirizzo" :disabled="onInputEnable(jsonData.viveCollettivita)"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Contatto collettivita':</label>
                    <b-form-input v-model="jsonData.viveCollettivitaContatto" :disabled="onInputEnable(jsonData.viveCollettivita)"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Denominazione e indirizzo luogo di lavoro/scuola:</label>
                    <b-form-input v-model="jsonData.luogoLavoroScuola"></b-form-input>
                    <!-- <b-form-select v-model="jsonData.luogoLavoroScuola" :options="luogoLavoroScuolaOptions" :value="''" value-field="value" text-field="text"></b-form-select> -->
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data inizio della terapia antitubercolare :</label>
                    <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataInizioTerapiaAntitubercolare" type="date"></date-picker>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Esposizione a caso di TBC:</label>
                    <b-form-select v-model="jsonData.esposizioneCasoTbc" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Alcolismo :</label>
                    <b-form-select v-model="jsonData.alcolismo" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Immunodeficienza Grave :</label>
                    <b-form-select v-model="jsonData.immunodeficienzaGrava" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Altra Condizione predisponente:</label>
                    <b-form-select v-model="jsonData.altreCondizionePredisponente" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Altra Condizione predisponente dettaglio:</label>
                    <b-form-input v-model="jsonData.altreCondizionePredisponenteDettagli" :disabled="onInputEnable(jsonData.altreCondizionePredisponente)"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Diagnosi tubercolosi passate:</label>
                    <b-form-select v-model="jsonData.diagnosiTubercolosiPassato" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Altra Condizione predisponente Mese Anno:</label>
                    <b-form-input v-model="jsonData.diagnosiTubercolosiPassatoMeseAnno" :disabled="onInputEnable(jsonData.diagnosiTubercolosiPassato)"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Classificazione:</label>
                    <b-form-select v-model="jsonData.classificazioneRelazionePrecedentiTrattamenti" :options="tipoCasoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Agente Eziologico:</label>
                    <b-form-select v-model="jsonData.agenteEziologico" :options="agenteEziologicoOptions" :value="''" value-field="value" text-field="text" @input="checkAgenteEziologico"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Agente Eziologico Specificare:</label>
                    <b-form-input v-model="jsonData.agenteEziologicoMicrobatterioNonTubercolare" :disabled="disableAgenteEziologicoMicrobatterioNonTubercolare"></b-form-input>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Diagnosi Basata Su" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Esame Colturale Escreato:</label>
                    <b-form-select v-model="jsonData.diagnosiBasataEsameColturaleEscreato" :options="esitoEsameOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Esame Colturale Altro Materiale:</label>
                    <b-form-select v-model="jsonData.diagnosiBasataEsameColturaleAltroMateriale" :options="esitoEsameOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Specificare Materiale:</label>
                    <b-form-input v-model="jsonData.diagnosiBasataEsameColturaleAltroMaterialeDettagli" :disabled="checkEsameColturaleAltroMateriale(jsonData.diagnosiBasataEsameColturaleAltroMateriale)"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Esame Diretto Escreato:</label>
                    <b-form-select v-model="jsonData.diagnosiBasataEsameDirettoEscreato" :options="esitoEsameOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Esame Diretto Altro Materiale:</label>
                    <b-form-select v-model="jsonData.diagnosiBasataEsameDirettoAltroMateriale" :options="esitoEsameOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Specificare Materiale:</label>
                    <b-form-input v-model="jsonData.diagnosiBasataEsameDirettoAltroMaterialeDettagli" :disabled="checkEsameColturaleAltroMateriale(jsonData.diagnosiBasataEsameDirettoAltroMateriale)"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Id. Acido Nucleico Compl. Mycobacterium Tuberculosis</label>
                    <b-form-select v-model="jsonData.diagnosiBasataIdentificazioneAcidoNucleico" :options="esitoEsameOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Specificare Materiale:</label>
                    <b-form-input v-model="jsonData.diagnosiBasataIdentificazioneAcidoNucleicoDettaglio" :disabled="checkEsameColturaleAltroMateriale(jsonData.diagnosiBasataIdentificazioneAcidoNucleico)"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Clinica:</label>
                    <b-form-select v-model="jsonData.diagnosiBasataClinica" :options="esitoEsameOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Mantoux:</label>
                    <b-form-select v-model="jsonData.diagnosiBasataMantoux" :options="esitoEsameOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Rx Torace/Esami Strumentali:</label>
                    <b-form-select v-model="jsonData.diagnosiBasataRxToraceEsamiStrumentali" :options="esitoEsameOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Risposta Terapia Antitubercolare:</label>
                    <b-form-select v-model="jsonData.diagnosiBasataRispostaSedutaAntitubercolare" :options="esitoEsameOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Riscontro Autoptico TB Attiva:</label>
                    <b-form-select v-model="jsonData.diagnosiBasataRiscontroAutopticoTbAttiva" :options="esitoEsameOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Sede Anatomica:</label>
                    <b-form-select v-model="jsonData.sedeAnatomica" :options="sedeAnatomicaOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Numero Conviventi:</label>
                    <b-form-input v-model="jsonData.numeroConviventi"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Contatti:</label>
                    <b-form-select v-model="jsonData.contatti" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
            </b-row>
            <b-row style="margin-top: 5px">
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <indagine-epidemiologica-contatti-edit-component-vue :listaContatti="jsonData.listaContatti" :isEdit="true" @update="onUpdateListaContatti"></indagine-epidemiologica-contatti-edit-component-vue>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Altre Considerazioni</label>
                    <b-form-textarea v-model="jsonData.altreConsiderazioni" rows="6" no-resize></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Dati Notifica" header-tag="header" footer-tag="footer" title="">
            <!-- <b-row>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Sanitario Notificante</label>
              <b-form-input v-model="jsonData.sanitarioNotificante"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Telefono Sanitario Notificante</label>
              <b-form-input v-model="jsonData.sanitarioNotificanteContatto"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Data Notifica</label>
              <date-picker v-model="jsonData.dataNotifica" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
            </b-col>
          </b-row> -->
            <indagine-epidemiologica-dati-notifica-component :jsonData="jsonData" :isEdit="true" @update="onUpdateJsonData"></indagine-epidemiologica-dati-notifica-component>
        </b-card>
    </div>
</template>
<script>
import axios from "axios";
import UtilityMixin from "../../../utility/UtilityMixin";
import DatePicker from "vue2-datepicker";
import Vue from "vue";
import IndagineEpidemiologicaContattiEditComponentVue from "./IndagineEpidemiologicaContattiEditComponent.vue";
import IndagineEpidemiologicaDatiNotificaComponent from "./IndagineEpidemiologicaDatiNotificaComponent.vue";
export default {
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    components: { DatePicker, IndagineEpidemiologicaContattiEditComponentVue, IndagineEpidemiologicaDatiNotificaComponent },
    mixins: [UtilityMixin],
    data() {
        return {
            pathResource: "/malattieinfettiveietubercolosimicrobatterinontubercolari",
            pathResourceMalattiaInfettivaSegnalazione: "/malattieinfettivesegnalazioni",
            linkback: null,
            currentPage: 1,
            perPage: 100,
            disabledViveCollettivita: true,
            showModalLoading: false,
            disableAgenteEziologicoMicrobatterioNonTubercolare: true,
            jsonData: {
                viveCollettivita: "",
                viveCollettivitaDenominazioneIndirizzo: "",
                viveCollettivitaContatto: "",
                luogoLavoroScuola: "",
                dataInizioTerapiaAntitubercolare: null,
                esposizioneCasoTbc: "",
                alcolismo: "",
                immunodeficienzaGrava: "",
                altreCondizionePredisponente: "",
                altreCondizionePredisponenteDettagli: "",
                diagnosiTubercolosiPassato: "",
                diagnosiTubercolosiPassatoMeseAnno: "",
                classificazioneRelazionePrecedentiTrattamenti: "",
                agenteEziologico: "",
                diagnosiBasataEsameColturaleEscreato: "",
                diagnosiBasataEsameColturaleAltroMateriale: "",
                diagnosiBasataEsameDirettoEscreato: "",
                diagnosiBasataEsameDirettoAltroMateriale: "",
                diagnosiBasataIdentificazioneAcidoNucleico: "",
                diagnosiBasataClinica: "",
                diagnosiBasataMantoux: "",
                diagnosiBasataRxToraceEsamiStrumentali: "",
                diagnosiBasataRispostaSedutaAntitubercolare: "",
                diagnosiBasataRiscontroAutopticoTbAttiva: "",
                sedeAnatomica: "",
                contatti: "",
                altreConsiderazioni: "",
                listaContatti: [],
            },
            siNoOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "SI", text: "SI" },
                { value: "NO", text: "NO" },
            ],
            siNoNonNotoOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "SI", text: "SI" },
                { value: "NO", text: "NO" },
                { value: "NON NOTO", text: "NON NOTO" },
            ],
            luogoLavoroScuolaOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "LAVORO", text: "LAVORO" },
                { value: "SCUOLA", text: "SCUOLA" },
            ],
            tipoCasoOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "NUOVO CASO", text: "NUOVO CASO" },
                { value: "RECIDIVO", text: "RECIDIVO" },
            ],
            agenteEziologicoOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "M. TUBERCOLOSIS COMPLEX", text: "M. TUBERCOLOSIS COMPLEX" },
                { value: "M. TUBERCOLOSIS", text: "M. TUBERCOLOSIS" },
                { value: "M. BOVIS", text: "M. BOVIS" },
                { value: "M. AFRICANUM", text: "M. AFRICANUM" },
                { value: "MICOBATTERIO NON TUBERCOLARE", text: "MICOBATTERIO NON TUBERCOLARE" },
            ],
            esitoEsameOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "POSITIVO", text: "POSITIVO" },
                { value: "NEGATIVO", text: "NEGATIVO" },
                { value: "NON EFFETTUATO", text: "NON EFFETTUATO" },
                { value: "NON NOTO", text: "NON NOTO" },
            ],
            sedeAnatomicaOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "POLMONARE, TRACHEA, BRONCHI", text: "POLMONARE, TRACHEA, BRONCHI" },
                { value: "EXTRAPOLMONARE", text: "EXTRAPOLMONARE" },
                { value: "DISSEMINATA", text: "DISSEMINATA" },
            ],
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient() {
            return UtilityMixin.methods.getLinkPatient(this.utente, this.idPaziente);
        },
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.jsonData.idAnagrafica = me.idPaziente;
        me.jsonData.id = me.id;
        me.loadMalattiaInfettivaSegnalazione();
        me.loadData();
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
        "jsonData.contatti": function (value) {
            let me = this;
            if (value === "SI") {
                me.jsonData.listaContatti.push({ cognome: "", nome: "", comune: "", indirizzo: "", telefono: "" });
            } else {
                me.jsonData.listaContatti = [];
            }
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            me.showModalLoading = true;
            axios
                .get(link + me.id)
                .then((response) => {
                    me.jsonData = response.data.data;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.setDatiMedico();
                    me.showModalLoading = false;
                });
            me.checkAgenteEziologico();
        },
        loadMalattiaInfettivaSegnalazione() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceMalattiaInfettivaSegnalazione + "/";
            axios
                .get(link + me.id)
                .then((response) => {
                    me.setDatiMalattia(response.data.data);
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        checkEsameColturaleAltroMateriale(value) {
            let returnValue = true;
            if (value === "POSITIVO" || value === "NEGATIVO") {
                returnValue = false;
            }
            return returnValue;
        },
        checkAgenteEziologico() {
            let me = this;
            me.disableAgenteEziologicoMicrobatterioNonTubercolare = true;
            if (me.jsonData.agenteEziologico === "MICOBATTERIO NON TUBERCOLARE") {
                me.disableAgenteEziologicoMicrobatterioNonTubercolare = false;
            }
        },
        onInputEnable(value) {
            let returnValue = true;
            if (value === "SI") {
                returnValue = false;
            }
            return returnValue;
        },
        setDatiMedico() {
            let me = this;
            me.jsonData.sanitarioNotificante = Vue.prototype.user.firstname + " " + Vue.prototype.user.lastname;
        },
        setDatiMalattia(data) {
            let me = this;
            me.jsonData.id = data.id;
        },
        onUpdateListaContatti(data) {
            let me = this;
            me.jsonData.listaContatti = data;
        },
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
        },
    },
};
</script>
