<template>
    <div>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Tipo Caso</label>
                    <b-form-select v-model="jsonData.tipoCaso" :options="tipiCasiOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Data Inizio Sintomi</label>
                    <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataInizioSintomi" type="date"></date-picker>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Ricovero Ospedaliero</label>
                    <b-form-select v-model="jsonData.ricoveroOspedaliero" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputRicoveroOspedaliero"></b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Nome dell' Ospedale</label>
                    <b-form-input v-model="jsonData.nomeOspedale" :disabled="disabledValue.ricoveroOspedalieroDisabled"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Data di Ricovero</label>
                    <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataRicovero" type="date" :disabled="disabledValue.ricoveroOspedalieroDisabled"></date-picker>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Data di Dimissione</label>
                    <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataDimissione" type="date" :disabled="disabledValue.ricoveroOspedalieroDisabled"></date-picker>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Protocollo Diagnostico" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Diagnosi Clinica</label>
                    <b-form-select v-model="jsonData.diagnosiClinica" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Diagnosi Parassitologica su aspirato di midollo osseo,milza,fegato,linfonodi o sangue</label>
                    <b-form-select v-model="jsonData.diagnosiParassitologica" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Diagnosi Sierologica</label>
                    <b-form-select v-model="jsonData.diagnosiSierologica" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputDiagnosiSierologica"></b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Metodica (IFA, ELISA, DAT ecc…)</label>
                    <b-form-input v-model="jsonData.diagnosiSierologicaMetodica" :disabled="disabledValue.diagnosiSierologicaDisabled"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Esecuzione Diagnosi Sierologica</label>
                    <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataEsecuzioneDiagnosiSierologica" type="date" :disabled="disabledValue.diagnosiSierologicaDisabled"></date-picker>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Vive in una casa con giardino?</label>
                    <b-form-select v-model="jsonData.casaGiardino" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Le finestre dell'abitazione sono dotate di reti a maglie fitte?</label>
                    <b-form-select v-model="jsonData.finestreReteMaglie" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Utilizza repellenti chimici individuali o ambientali contro gli insetti?</label>
                    <b-form-select v-model="jsonData.utilizzoRepellentiInsetti" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Frequentaì Parchi/giardini/aree verdi/campi agricoli?</label>
                    <b-form-select v-model="jsonData.frequentazioneParchi" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
            </b-row>
            <b-row style="margin-top: 5px">
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-table sticky-header stacked="xl" striped hover itemscope :items="jsonData.listaUbicazioneParchi" :fields="fieldsUbicazioneParchi" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="row">
                            {{ row.index + 1 }}
                        </template>
                        <template #cell(ubicazione)="item">
                            <b-form-input v-model="item.item.ubicazione"></b-form-input>
                        </template>
                        <template #cell(actions)="row">
                            <b-button v-if="row.index == jsonData.listaUbicazioneParchi.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddUbicazioneParchi(jsonData.listaUbicazioneParchi, row.item)">
                                <b-icon icon="plus"></b-icon>
                            </b-button>
                            <b-button v-if="row.index !== jsonData.listaUbicazioneParchi.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(jsonData.listaUbicazioneParchi, row)">
                                <b-icon icon="trash"></b-icon>
                            </b-button>
                        </template>
                    </b-table>
                </b-col>
            </b-row>
        </b-card>

        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Nel corso dell'ultimo anno ha soggiornato in localita diverse da quella di residenza?</label>
                    <b-form-select v-model="jsonData.soggiornoDiversoResidenza" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
            </b-row>
            <b-row style="margin-top: 5px">
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-table sticky-header stacked="xl" striped hover itemscope :items="jsonData.listaUbicazioneSoggiorno" :fields="fieldsUbicazioneSoggiorno" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="row">
                            {{ row.index + 1 }}
                        </template>
                        <template #cell(ubicazione)="item">
                            <b-form-input v-model="item.item.ubicazione"></b-form-input>
                        </template>
                        <template #cell(actions)="row">
                            <b-button v-if="row.index == jsonData.listaUbicazioneSoggiorno.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddUbicazioneSoggiorno(jsonData.listaUbicazioneSoggiorno, row.item)">
                                <b-icon icon="plus"></b-icon>
                            </b-button>
                            <b-button v-if="row.index !== jsonData.listaUbicazioneSoggiorno.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(jsonData.listaUbicazioneSoggiorno, row)">
                                <b-icon icon="trash"></b-icon>
                            </b-button>
                        </template>
                    </b-table>
                </b-col>
            </b-row>
        </b-card>

        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Possiede Cane/i nel proprio ambiente domestico?</label>
                    <b-form-select v-model="jsonData.possessoCane" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Viene sottoposto a periodici trattamenti antiparassitari?</label>
                    <b-form-select v-model="jsonData.trattamentiAntiparassitari" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Dorme/dormono all'aperto?</label>
                    <b-form-select v-model="jsonData.dormeAperto" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Ha/hanno un ricovero all'aperto?</label>
                    <b-form-select v-model="jsonData.ricoveroAperto" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Altre Considerazioni</label>
                    <b-form-textarea v-model="jsonData.altreConsiderazioni" rows="6" no-resize></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Sanitario Notificante</label>
                    <b-form-input v-model="jsonData.sanitarioNotificante"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Contatto Sanitario Notificante</label>
                    <b-form-input v-model="jsonData.contattoSanitarioNotificante"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Notifica</label>
                    <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataNotifica" type="date"></date-picker>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
<script>
import axios from "axios";
import Vue from "vue";
import UtilityMixin from "../../../utility/UtilityMixin";
import DatePicker from "vue2-datepicker";
export default {
    mixins: [UtilityMixin],
    components: { DatePicker },
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    data() {
        return {
            pathResource: "/malattieinfettiveieleishmaniosiviscerale",
            pathResourceMalattiaInfettivaSegnalazione: "/malattieinfettivesegnalazioni",
            pathResourceTipiCasi: "/malattieinfettiveietipicasi",
            linkback: null,
            showModalLoading: false,
            id: "-1",
            currentPage: 1,
            perPage: 100,
            filtro: { page: 1, forPage: 100 },
            disabledValue: {
                ricoveroOspedalieroDisabled: true,
                diagnosiSierologicaDisabled: true,
            },
            jsonData: {
                tipoCaso: "",
                dataInizioSintomi: null,
                ricoveroOspedaliero: "",
                nomeOspedale: "",
                dataRicovero: null,
                dataDimissione: null,
                diagnosiClinica: "",
                diagnosiParassitologica: "",
                diagnosiSierologica: "",
                diagnosiSierologicaMetodica: "",
                dataEsecuzioneDiagnosiSierologica: null,
                casaGiardino: "",
                finestreReteMaglie: "",
                utilizzoRepellentiInsetti: "",
                frequentazioneParchi: "",
                soggiornoDiversoResidenza: "",
                possessoCane: "",
                trattamentiAntiparassitari: "",
                dormeAperto: "",
                ricoveroAperto: "",
                sanitarioNotificante: "",
                contattoSanitarioNotificante: "",
                altreConsiderazioni: "",
                dataNotifica: null,
                listaUbicazioneParchi: [],
                listaUbicazioneSoggiorno: [],
            },
            fieldsUbicazioneParchi: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Ubicazione",
                    key: "ubicazione",
                    sortable: false,
                },
                {
                    label: "",
                    key: "actions",
                    sortable: false,
                    thStyle: "width: 4rem",
                },
            ],
            fieldsUbicazioneSoggiorno: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Ubicazione",
                    key: "ubicazione",
                    sortable: false,
                },
                {
                    label: "",
                    key: "actions",
                    sortable: false,
                    thStyle: "width: 4rem",
                },
            ],
            tipiCasiOptions: [],
            siNoOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "SI", text: "SI" },
                { value: "NO", text: "NO" },
            ],
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient() {
            return UtilityMixin.methods.getLinkPatient(this.utente, this.idPaziente);
        },
    },
    mounted() {
        let me = this;
        me.jsonData.idAnagrafica = me.idPaziente;
        me.id = this.$route.params.id;
        me.jsonData.id = me.id;
        me.loadMalattiaInfettivaSegnalazione();
        me.loadData();
        me.loadTipiCasi();
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
        "jsonData.frequentazioneParchi": function (value) {
            let me = this;
            if (value === "SI") {
                me.jsonData.listaUbicazioneParchi.push({ ubicazione: "", tipoUbicazione: "PARCO" });
            } else {
                me.jsonData.listaUbicazioneParchi = [];
            }
        },
        "jsonData.soggiornoDiversoResidenza": function (value) {
            let me = this;
            if (value === "SI") {
                me.jsonData.listaUbicazioneSoggiorno.push({ ubicazione: "", tipoUbicazione: "SOGGIORNO" });
            } else {
                me.jsonData.listaUbicazioneSoggiorno = [];
            }
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            me.showModalLoading = true;
            axios
                .get(link + me.id)
                .then((response) => {
                    me.jsonData = response.data.data;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.setDatiMedico();
                    me.showModalLoading = false;
                });
        },
        loadMalattiaInfettivaSegnalazione() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceMalattiaInfettivaSegnalazione + "/";
            axios
                .get(link + me.id)
                .then((response) => {
                    me.setDatiMalattia(response.data.data);
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        loadTipiCasi() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceTipiCasi;
            axios.get(link, { params: me.filtro }).then((response) => {
                response.data.data.list.forEach((element) => {
                    me.tipiCasiOptions.push({ value: element.descrizione, text: element.descrizione });
                });
                me.tipiCasiOptions.unshift({ value: "", text: "-Seleziona Tipo Caso-" });
                me.showModalLoading = false;
            });
        },
        onInputRicoveroOspedaliero(value) {
            let me = this;
            if (value === "SI") {
                me.disabledValue.ricoveroOspedalieroDisabled = false;
            } else {
                me.disabledValue.ricoveroOspedalieroDisabled = true;
                me.jsonData.nomeOspedale = "";
                me.jsonData.dataRicovero = null;
                me.jsonData.dataDimissione = null;
            }
        },
        onInputDiagnosiSierologica(value) {
            let me = this;
            if (value === "SI") {
                me.disabledValue.diagnosiSierologicaDisabled = false;
            } else {
                me.disabledValue.diagnosiSierologicaDisabled = true;
                me.jsonData.diagnosiSierologicaMetodica = "";
                me.jsonData.dataEsecuzioneDiagnosiSierologica = null;
            }
        },
        onAddUbicazioneParchi(array, value) {
            if (value.malattia !== "") {
                array.push({ ubicazione: "", tipoUbicazione: "PARCO" });
            } else {
                this.$bvModal
                    .msgBoxOk("La Malattia è OBBLIGATORIA", {
                        title: "ATTENZIONE",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    })
                    .then((value) => {
                        console.log(value);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        onAddUbicazioneSoggiorno(array, value) {
            if (value.malattia !== "") {
                array.push({ ubicazione: "", tipoUbicazione: "SOGGIORNO" });
            } else {
                this.$bvModal
                    .msgBoxOk("La Malattia è OBBLIGATORIA", {
                        title: "ATTENZIONE",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    })
                    .then((value) => {
                        console.log(value);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        onDelete(array, row) {
            if (row.index != array.length) {
                this.$bvModal
                    .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
                        title: "Titolo",
                        buttonSize: "sm",
                        okVariant: "secondary",
                        cancelVariant: "danger",
                        okTitle: "SI",
                        cancelTitle: "NO",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true,
                    })
                    .then((value) => {
                        if (value) {
                            array.splice(row.index, 1);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        setDatiMedico() {
            let me = this;
            me.jsonData.sanitarioNotificante = Vue.prototype.user.firstname + " " + Vue.prototype.user.lastname;
        },
        setDatiMalattia(data) {
            let me = this;
            me.jsonData.id = data.id;
        },
    },
};
</script>
