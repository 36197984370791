<template>
    <div style="height: 100%; padding-bottom: 18px">
        <div class="sa-accordion-wrapper">
            <form>
                <div class="sa-accordion">
                    <input class="sa-accordion-input" type="radio" name="prescrizione-radio-a" id="prescrizione-farmaco-radio-a" value="1" checked="checked" />
                    <label class="sa-accordion-label" for="prescrizione-farmaco-radio-a">
                        Farmaci
                        <b-button size="sm" variant="outline-success outline-secondary-noborder pull-right sa-accordion-btn" @click="onClickNuovaPrescrizioneFarmacologica()">
                            <i class="fas fa-plus"></i>
                            Prescrivi Farmaco
                        </b-button>
                    </label>
                    <div class="sa-accordion-content">
                        <div class="b-table-sticky-header">
                            <b-table sticky-header ref="table" stacked="lg" striped hover :items="prescrizioniFarmacologiche" :fields="fieldsPrescrizioniFarmacologiche" :current-page="1" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                                <template v-slot:cell(indicazioni)="{ item }">
                                    <span v-html="item.indicazioni"></span>
                                </template>
                                <template v-slot:cell(actions)="row">
                                    <b-container class="bv-example-row">
                                        <b-button v-b-tooltip.hover size="sm" variant="outline-primary no-text" title="Modifica Pianificazione" @click="onClickEditPrescrizioneFarmacologica(row.item, row.index, $event.target)" class="mr-1 no-text">
                                            <b-icon icon="pencil"></b-icon>
                                        </b-button>
                                    </b-container>
                                </template>
                            </b-table>
                        </div>
                    </div>
                </div>
                <div class="sa-accordion">
                    <input class="sa-accordion-input" type="radio" name="prescrizione-radio-a" id="prescrizione-prestazione-radio-a" value="2" />
                    <label class="sa-accordion-label" for="prescrizione-prestazione-radio-a">
                        Prestazioni
                        <b-button size="sm" variant="outline-success outline-secondary-noborder pull-right sa-accordion-btn" @click="onClickNuovaPrescrizionePrestazione()">
                            <i class="fas fa-plus"></i>
                            Prescrivi Prestazione
                        </b-button>
                    </label>
                    <div class="sa-accordion-content">
                        <div class="b-table-sticky-header">
                            <b-table sticky-header ref="table" stacked="lg" striped hover :items="prescrizioniPrestazioni" :fields="fieldsPrescrizioniPrestazioni" :current-page="1" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                                <template v-slot:cell(indicazioni)="{ item }">
                                    <span v-html="item.indicazioni"></span>
                                </template>
                                <template v-slot:cell(actions)="row">
                                    <b-container class="bv-example-row">
                                        <b-button v-b-tooltip.hover size="sm" variant="outline-primary no-text" title="Modifica Pianificazione" @click="onClickEditPrescrizionePrestazioni(row.item, row.index, $event.target)" class="mr-1 no-text">
                                            <b-icon icon="pencil"></b-icon>
                                        </b-button>
                                    </b-container>
                                </template>
                            </b-table>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <sa-modal-loading ref="saModalLoading" />
        <prescrizione-farmaco-component ref="mdlPrescrizioneFarmacoComponent" @afterSava="onAfterSaveMdlPrescrizioneFarmacoComponent"></prescrizione-farmaco-component>
        <prescrizione-specialistica-component ref="mdlPrescrizioneSpecialisticaComponent" @afterSava="onAfterSaveMdlPrescrizioneSpecialisticaComponent"></prescrizione-specialistica-component>
    </div>
</template>

<script>
import moment from "moment";
import PrescrizioneFarmacoComponent from "../../../../utility/components/PrescrizioneFarmacoComponent.vue";
import PrescrizioneSpecialisticaComponent from "../../../../utility/components/PrescrizioneSpecialisticaComponent.vue";
import axios from "axios";
import UtilityMixin from "../../../../utility/UtilityMixin";
import { SaModalLoading } from "../../../../../template/components/modal";
export default {
    mixins: [UtilityMixin],
    components: { PrescrizioneFarmacoComponent, PrescrizioneSpecialisticaComponent, SaModalLoading },
    mounted() {
        // let me = this;
        // me.loadPrescrizioniPaziente();
    },
    data() {
        return {
            perPage: 10000,
            pathResourcePazientePrescrizioniFarmacologiche: "/rsapazienteprescrizioni/farmaci",
            pathResourcePazientePrescrizioniPrestazioni: "/rsapazienteprescrizioni/prestazioni",
            prescrizioniFarmacologiche: [],
            prescrizioniPrestazioni: [],
            jsonDataAccettazione: null,
            fieldsPrescrizioniFarmacologiche: [
                {
                    label: "Nome Farmaco",
                    key: "prescrizione",
                    sortable: true,
                },
                {
                    label: "Q.tà",
                    key: "quantita",
                    thStyle: "width: 5rem",
                    sortable: true,
                },
                {
                    label: "Orari",
                    key: "frequenza",
                    thStyle: "width: 5rem",
                    sortable: true,
                },
                {
                    label: "Posologia",
                    key: "posologia",
                    thStyle: "width: 5rem",
                    sortable: true,
                },
                {
                    label: "Stato",
                    key: "stato",
                    sortable: true,
                    thStyle: "width: 5rem",
                    //tdClass: "text-right",
                },
                {
                    label: "Note",
                    key: "note",
                    sortable: true,
                },
                {
                    label: "Data Inizio",
                    key: "dataInizio",
                    sortable: true,
                    thStyle: "width: 7rem",
                    formatter: (value) => {
                        return moment(value).format("DD-MM-YYYY");
                    },
                },
                { class: "sa-table-column-action-double", key: "actions", label: "" },
            ],
            fieldsPrescrizioniPrestazioni: [
                {
                    label: "Nome Farmaco",
                    key: "prescrizione",
                    sortable: true,
                },
                {
                    label: "Q.tà",
                    key: "quantita",
                    thStyle: "width: 5rem",
                    sortable: true,
                },
                {
                    label: "Orari",
                    key: "frequenza",
                    thStyle: "width: 5rem",
                    sortable: true,
                },
                {
                    label: "Posologia",
                    key: "posologia",
                    thStyle: "width: 5rem",
                    sortable: true,
                },
                {
                    label: "Stato",
                    key: "stato",
                    sortable: true,
                    thStyle: "width: 5rem",
                    //tdClass: "text-right",
                },
                {
                    label: "Note",
                    key: "note",
                    sortable: true,
                },
                {
                    label: "Data Inizio",
                    key: "dataInizio",
                    sortable: true,
                    thStyle: "width: 7rem",
                    formatter: (value) => {
                        return moment(value).format("DD-MM-YYYY");
                    },
                },
                { class: "sa-table-column-action-double", key: "actions", label: "" },
            ],
        };
    },
    methods: {
        loadPrescrizioniPaziente(jsonDataAccettazione) {
            let me = this;
            me.jsonDataAccettazione = jsonDataAccettazione;
            me.loadPrescrizioniFarmacologichePaziente();
            me.loadPrescrizioniPrestazioniPaziente();
        },
        loadPrescrizioniFarmacologichePaziente() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourcePazientePrescrizioniFarmacologiche;
            axios
                .get(link, { params: { idAccettazione: me.jsonDataAccettazione.id } })
                .then((response) => {
                    me.prescrizioniFarmacologiche = [];
                    me.prescrizioniFarmacologiche = response.data.data.list;
                })
                .catch((error) => {
                    UtilityMixin.methods.messaggeBoxError(this, error);
                });
        },
        loadPrescrizioniPrestazioniPaziente() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourcePazientePrescrizioniPrestazioni;
            axios
                .get(link, { params: { idAccettazione: me.jsonDataAccettazione.id } })
                .then((response) => {
                    me.prescrizioniPrestazioni = [];
                    me.prescrizioniPrestazioni = response.data.data.list;
                })
                .catch((error) => {
                    UtilityMixin.methods.messaggeBoxError(this, error);
                });
        },
        onClickNuovaPrescrizioneFarmacologica() {
            let me = this;
            me.$refs.mdlPrescrizioneFarmacoComponent.show(me.jsonDataAccettazione);
        },
        onClickEditPrescrizioneFarmacologica(item) {
            let me = this;
            me.$refs.mdlPrescrizioneFarmacoComponent.edit(me.jsonDataAccettazione, item);
        },
        onClickNuovaPrescrizionePrestazione() {
            let me = this;
            me.$refs.mdlPrescrizioneSpecialisticaComponent.show(me.jsonDataAccettazione);
        },
        onClickEditPrescrizionePrestazioni(item) {
            let me = this;
            me.$refs.mdlPrescrizioneSpecialisticaComponent.edit(me.jsonDataAccettazione, item);
        },
        onAfterSaveMdlPrescrizioneFarmacoComponent() {
            let me = this;
            me.loadPrescrizioniFarmacologichePaziente();
        },
        onAfterSaveMdlPrescrizioneSpecialisticaComponent() {
            let me = this;
            me.loadPrescrizioniPrestazioniPaziente();
        },
    },
};
</script>

<style></style>
