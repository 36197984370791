<template>
    <div style="height: 100%">
        <div class="sa-flexbox" style="padding-bottom: 20px">
            <div class="d-flex flex-column">
                <!-- <div class="text-right mb-3">
                    <b-button size="sm" variant="outline-success outline-primary-noborder sa-margin-right float-sm-right mb-2" :hidden="btnNuovaPrestazioneHidden" @click="onClickNuovaSpesa" class="mt-2"> <b-icon icon="plus"></b-icon> Nuova Spesa </b-button>
                </div> -->
                <b-row class="sa-label-info">
                    <b-col lg="3">
                        <p>Record Totali: {{ rows }}</p>
                    </b-col>
                    <b-col lg="9">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                    </b-col>
                </b-row>
            </div>

            <div class="sa-flexbox-body">
                <div class="b-table-sticky-header">
                    <b-table sticky-header ref="table" stacked="lg" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="riga">
                            {{ riga.index + 1 }}
                        </template>

                        <template #cell(importo)="item">
                            <span> {{ item.item.importo + "€" }}</span>
                        </template>

                        <template #cell(descrizioneIva)="item">
                            <span> {{ item.item.descrizioneIva }}</span>
                        </template>

                        <template #cell(spesaRicorsiva)="item">
                            <span v-if="item.item.spesaRicorsiva"> SI</span>
                            <span v-else> NO</span>
                        </template>

                        <template v-slot:cell(eventi)="{ item }">
                            <b-container class="bv-example-row">
                                <!-- <b-button size="sm" v-b-tooltip.hover title="Visualizza Spesa Extra" variant="outline-secondary no-text" @click="onClickView(item)" class="mr-1 no-text">
                                    <font-awesome-icon icon="eye"> </font-awesome-icon>
                                </b-button> -->

                                <b-button size="sm" v-b-tooltip.hover title="Modifica Spesa Extra" variant="outline-success" @click="onClickEdit(item)" class="mr-1 no-text">
                                    <font-awesome-icon icon="edit"> </font-awesome-icon>
                                </b-button>

                                <b-button size="sm" v-b-tooltip.hover title="Cancella Spesa Extra" variant="outline-danger" @click="onDeleteSpesaExtra(item)" class="mr-1 no-text">
                                    <font-awesome-icon icon="trash"></font-awesome-icon>
                                </b-button>
                            </b-container>
                        </template>
                    </b-table>
                </div>

                <rsa-configurazione-spese-extra-edit-component ref="cmpRsaConfigurazioneSpeseExtraEditComponent" @onSave="onSavePrestazione" @onClose="loadData" @onDelete="onDelete"></rsa-configurazione-spese-extra-edit-component>
            </div>

            <div class="sa-list-component-footer">
                <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col lg="3">
                        <p>Record Totali:{{ rows }}</p>
                    </b-col>
                    <b-col lg="9">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                    </b-col>
                </b-row>
            </div>
        </div>
    </div>
</template>

<script>
import UtilityMixin from "../../../utility/UtilityMixin";
import RsaConfigurazioneSpeseExtraEditComponent from "./RsaConfigurazioneSpeseExtraEditComponent.vue";

export default {
    components: { RsaConfigurazioneSpeseExtraEditComponent },
    mixins: [UtilityMixin],
    data() {
        return {
            rows: 0,
            perPage: 50,
            currentPage: 1,
            pathResource: "/rsaspeseextra",
            showModalLoading: false,
            listKey: 0,
            items: [],
            filtroPrestazioni: { nome: "" },
            btnNuovaPrestazioneHidden: false,
            fields: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                },
                {
                    label: "Spesa",
                    key: "descrizione",
                    sortable: false,
                },
                {
                    label: "Importo",
                    key: "importo",
                    sortable: false,
                },
                {
                    label: "Nat. Fiscale",
                    key: "naturaEconomica",
                    sortable: false,
                },
                {
                    label: "IVA",
                    key: "descrizioneIva",
                    sortable: false,
                },
                {
                    label: "Spesa Ricorsiva",
                    key: "spesaRicorsiva",
                    sortable: false,
                },
                {
                    label: "Note",
                    key: "note",
                    sortable: false,
                },

                {
                    class: "sa-table-column-action-double",
                    key: "eventi",
                    label: "",
                },
            ],
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    watch: {
        currentPage() {
            let me = this;
            me.loadData();
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.$emit("beforeLoadData");
            let sessionStorage = window.sessionStorage;
            if (sessionStorage["filtroPrestazioni"]) {
                me.filtroPrestazioni = JSON.parse(sessionStorage["filtroPrestazioni"]);
            }

            me.filtroPrestazioni.page = me.currentPage;
            me.filtroPrestazioni.forPage = me.perPage;

            UtilityMixin.methods.loadDataResources(me.pathResource, me.filtroPrestazioni, me.loadDataSuccess, me.loadDataError);
        },

        loadDataSuccess(response) {
            let me = this;
            /* console.log("LOADDATASUCCESS", response.data.data.list); */

            me.items = response.data.data.list;
            me.rows = response.data.data.recordsNumber;
            me.$emit("afterLoadData"); //da domandare se va bene qui
        },
        loadDataError(error) {
            let me = this;
            console.error("Errore durante il caricamento dei dati:", error);
            me.items = [];
            me.rows = 0;
            me.$emit("afterLoadData");
        },

        onClickView(items) {
            let me = this;
            me.$router.push("/rsaspeseextra/view/" + items.id);
        },
        onClickEdit(item) {
            let me = this;
            me.$refs.cmpRsaConfigurazioneSpeseExtraEditComponent.aggiornaSpesaExtra(item);
        },
        onClickNuovaSpesa() {
            let me = this;
            me.$refs.cmpRsaConfigurazioneSpeseExtraEditComponent.nuovaSpesaExtra();
        },

        onSavePrestazione() {
            this.loadData();
        },

        onDeleteSpesaExtra(item) {
            let me = this;
            me.$refs.cmpRsaConfigurazioneSpeseExtraEditComponent.onDeleteSpesaExtra(item);
        },
        onDelete() {
            this.loadData();
        },
    },
};
</script>
