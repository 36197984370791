var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sa-list-component"},[_c('div',{staticClass:"sa-list-component-header"},[_c('b-row',{staticClass:"sa-label-info",staticStyle:{"margin-left":"0.5rem","margin-right":"0.5rem"}},[_c('b-col',{attrs:{"lg":"3"}},[_c('p',[_vm._v(_vm._s(this.$i18n.t("global.lblTotalRecords"))+": "+_vm._s(_vm.rows))])]),_c('b-col',{attrs:{"lg":"9"}},[_c('b-pagination',{attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage,"align":"right","size":"sm"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1),_c('div',{staticClass:"sa-list-component-body"},[_c('div',{staticClass:"b-table-sticky-header"},[_c('b-table',{key:_vm.listKey,ref:"table",staticClass:"sa-b-table",attrs:{"sticky-header":"","stacked":"lg","striped":"","hover":"","items":_vm.items,"fields":_vm.fields,"current-page":1,"per-page":_vm.perPage,"sort-icon-left":"","head-variant":"light"},scopedSlots:_vm._u([{key:"cell(index)",fn:function(row){return [_c('span',[_vm._v(" "+_vm._s(row.index + 1)+" ")])]}},{key:"cell(nominativo)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.nome)+" "+_vm._s(item.cognome))]),_c('span',{staticStyle:{"display":"block"}},[_vm._v("("+_vm._s(item.identificativo)+")")])]}},{key:"cell(eta)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.calcoloEta(item.dataNascita)))])]}},{key:"cell(email)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.email))])]}},{key:"cell(camera)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.camera))])]}},{key:"cell(actions)",fn:function(row){return [_c('b-container',{staticClass:"bv-example-row"},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-1 no-text",attrs:{"size":"sm","variant":"outline-success","title":"Cartella Ospite"},on:{"click":function($event){return _vm.onEdit(row.item, row.index, $event.target)}}},[_c('b-icon',{attrs:{"icon":"book","variant":"outline-sucess"}})],1)],1),_c('b-container',{staticClass:"bv-example-row"},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-1 no-text",attrs:{"size":"sm","variant":"outline-success","title":"Cartella Paziente"},on:{"click":function($event){return _vm.onOpenSchedaPaziente(row.item, row.index, $event.target)}}},[_c('b-icon',{attrs:{"icon":"book","variant":"outline-sucess"}})],1)],1)]}}])})],1)]),_c('div',{staticClass:"sa-list-component-footer"},[_c('b-row',{staticClass:"sa-label-info",staticStyle:{"margin-left":"0.5rem","margin-right":"0.5rem"}},[_c('b-col',{attrs:{"lg":"3"}},[_c('p',[_vm._v(_vm._s(this.$i18n.t("patients.lblTotalRecords"))+": "+_vm._s(_vm.rows))])]),_c('b-col',{attrs:{"lg":"9"}},[_c('b-pagination',{attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage,"align":"right","size":"sm"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }