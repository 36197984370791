<template>
    <div style="height: 100%; overflow: auto">
        <b-card class="sa-card" header="Attivita" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Nome:</label>
                    <b-form-input v-model="jsonData.nome"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Area:</label>
                    <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.idArea" class="mb-2 mr-sm-2 mb-sm-0" :value="''" value-field="id" text-field="nome" :options="areeOptions"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Diario:</label>
                    <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.idDiario" class="mb-2 mr-sm-2 mb-sm-0" :value="''" value-field="id" text-field="nome" :options="diariOptions"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Descrizione:</label>
                    <b-form-textarea v-model="jsonData.descrizione" no-resize rows="5"></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <template #header>
                <div style="display: flex">
                    <span style="flex: 1">Dettagli Prestazioni</span>
                    <b-button size="sm" variant="outline-secondary outline-secondary-noborder pull-right" @click="onClicCaricaNomenclatore">
                        <i class="bi bi-journals"></i>
                        Carica Da Nomenclatore
                    </b-button>
                </div>
            </template>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Codice Prestazione:</label>
                    <b-form-input v-model="jsonData.codicePrestazione"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
                    <label class="sa-label-data">Descruzione Prestazione:</label>
                    <b-form-input v-model="jsonData.descrizionePrestazione"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Codice Prestazione Agg:</label>
                    <b-form-input v-model="jsonData.codicePrestazioneAgg"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
                    <label class="sa-label-data">Descruzione Prestazione Agg:</label>
                    <b-form-input v-model="jsonData.descrizionePrestazioneAgg"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Importo Prestazione:</label>
                    <b-form-input v-model="jsonData.importoPrestazione"></b-form-input>
                </b-col>
            </b-row>
        </b-card>
        <nomenclatore-list-component ref="cmpNomenclatoreListComponent" @selectedPrestazione="onSelectPrestazione"></nomenclatore-list-component>
    </div>
</template>
<script>
import axios from "axios";
import NomenclatoreListComponent from "../../../utility/components/NomenclatoreListComponent.vue";
export default {
    components: { NomenclatoreListComponent },
    data() {
        return {
            pathResource: "/rsaattivita",
            pathResourceDiari: "/rsadiari",
            pathResourceAree: "/rsaaree",
            id: "-1",
            jsonData: { nome: "", idArea: "", idDiario: "", descrizione: "", codicePrestazione: "", descrizionePrestazione: "", codicePrestazioneAgg: "", descrizionePrestazioneAgg: "", importoPrestazione: null },
            areeOptions: [{ id: "", nome: "-Seleziona Area-" }],
            diariOptions: [],
        };
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadAree();
        me.loadDiari();
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            me.$emit("beforeLoadData");
            if (me.id === "-1") {
                this.$emit("afterLoadData");
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                axios
                    .get(link + me.id)
                    .then((response) => {
                        me.jsonData = response.data.data;
                        this.$emit("afterLoadData");
                    })
                    .catch(() => {});
            }
        },
        loadAree() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceAree;
            axios
                .get(link)
                .then((response) => {
                    // me.rows = response.data.data.recordsNumber;
                    me.areeOptions = response.data.data.list;
                    me.areeOptions.unshift({
                        id: "",
                        nome: "-Seleziona Area-",
                    });
                    this.$emit("afterLoadData");
                })
                .catch(() => {
                    this.$emit("afterLoadData");
                });
        },
        loadDiari() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceDiari;
            axios
                .get(link)
                .then((response) => {
                    // me.rows = response.data.data.recordsNumber;
                    me.diariOptions = response.data.data.list;
                    me.diariOptions.unshift({
                        id: "",
                        nome: "-Seleziona Diario-",
                    });
                    this.$emit("afterLoadData");
                })
                .catch(() => {
                    this.$emit("afterLoadData");
                });
        },
        onClicCaricaNomenclatore() {
            let me = this;
            me.$refs.cmpNomenclatoreListComponent.openModal();
        },
        onSelectPrestazione(json) {
            let me = this;
            me.jsonData.codicePrestazione = json.codicePrestazione;
            me.jsonData.codicePrestazioneAgg = json.codicePrestazioneAgg;
            me.jsonData.descrizionePrestazione = json.descrizione;
            me.jsonData.descrizionePrestazioneAgg = json.descrizioneAgg;
            me.jsonData.importoPrestazione = json.importoPrestazione;
        },
    },
};
</script>
