<template>
    <div class="sa-tab-scroll">
        <b-card class="sa-card sa-card-border" header="EQUILIBRIO DA SEDUTO" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaEquilibrioSeduto" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header">
                <template #cell(check)="{ item }">
                    <b-form-checkbox v-model="item.check" :checked="item.check" switch></b-form-checkbox>
                </template>
            </b-table>
        </b-card>
        <b-card class="sa-card sa-card-border" header="ALZARSI DALLA SEDIA" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaAlzarsiSedia" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header">
                <template #cell(check)="{ item }">
                    <b-form-checkbox v-model="item.check" :checked="item.check" switch></b-form-checkbox>
                </template>
            </b-table>
        </b-card>
        <b-card class="sa-card sa-card-border" header="TENTATIVO DI ALZARSI" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaTentativoAlzarsi" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header">
                <template #cell(check)="{ item }">
                    <b-form-checkbox v-model="item.check" :checked="item.check" switch></b-form-checkbox>
                </template>
            </b-table>
        </b-card>
        <b-card class="sa-card sa-card-border" header="EQUILIBRIO STAZIONE ERETTA" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaEquilibrioStazioneEretta" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header">
                <template #cell(check)="{ item }">
                    <b-form-checkbox v-model="item.check" :checked="item.check" switch></b-form-checkbox>
                </template>
            </b-table>
        </b-card>
        <b-card class="sa-card sa-card-border" header="EQUILIBRIO STAZIONE ERETTA PROLUNGATA" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaEquilibrioStazioneErettaProlungata" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header">
                <template #cell(check)="{ item }">
                    <b-form-checkbox v-model="item.check" :checked="item.check" switch></b-form-checkbox>
                </template>
            </b-table>
        </b-card>
        <b-card class="sa-card sa-card-border" header="ROMBERG" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaRomberg" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header">
                <template #cell(check)="{ item }">
                    <b-form-checkbox v-model="item.check" :checked="item.check" switch></b-form-checkbox>
                </template>
            </b-table>
        </b-card>
        <b-card class="sa-card sa-card-border" header="ROMBERG SENSIBILIZZATO" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaRombergSensibilizzato" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header">
                <template #cell(check)="{ item }">
                    <b-form-checkbox v-model="item.check" :checked="item.check" switch></b-form-checkbox>
                </template>
            </b-table>
        </b-card>
        <b-card class="sa-card sa-card-border" header="GIRARSI DI 360°" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaGirarsi" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header">
                <template #cell(check)="{ item }">
                    <b-form-checkbox v-model="item.check" :checked="item.check" switch></b-form-checkbox>
                </template>
            </b-table>
        </b-card>
        <b-card class="sa-card sa-card-border" header="SEDERSI" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaSedersi" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header">
                <template #cell(check)="{ item }">
                    <b-form-checkbox v-model="item.check" :checked="item.check" switch></b-form-checkbox>
                </template>
            </b-table>
        </b-card>
        <b-card class="sa-card sa-card-border" header="INIZIO DELLA DEAMBULAZIONE" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaInizioDeambulazione" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header">
                <template #cell(check)="{ item }">
                    <b-form-checkbox v-model="item.check" :checked="item.check" switch></b-form-checkbox>
                </template>
            </b-table>
        </b-card>
        <b-card class="sa-card sa-card-border" header="LUNGHEZZA PASSO (PIEDE DX)" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaLunghezzaPassoPiedeDx" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header">
                <template #cell(check)="{ item }">
                    <b-form-checkbox v-model="item.check" :checked="item.check" switch></b-form-checkbox>
                </template>
            </b-table>
        </b-card>
        <b-card class="sa-card sa-card-border" header="ALTEZZA PASSO(PIEDE DX)" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaAltezzaPassoPiedeDx" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header">
                <template #cell(check)="{ item }">
                    <b-form-checkbox v-model="item.check" :checked="item.check" switch></b-form-checkbox>
                </template>
            </b-table>
        </b-card>
        <b-card class="sa-card sa-card-border" header="LUNGHEZZA PASSO (PIEDE SX)" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaLunghezzaPassoPiedeSx" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header">
                <template #cell(check)="{ item }">
                    <b-form-checkbox v-model="item.check" :checked="item.check" switch></b-form-checkbox>
                </template>
            </b-table>
        </b-card>
        <b-card class="sa-card sa-card-border" header="ALTEZZA PASSO(PIEDE SX)" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaAltezzaPassoPiedeSx" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header">
                <template #cell(check)="{ item }">
                    <b-form-checkbox v-model="item.check" :checked="item.check" switch></b-form-checkbox>
                </template>
            </b-table>
        </b-card>
        <b-card class="sa-card sa-card-border" header="SIMMETRIA DEL PASSO" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaSimmetriaPasso" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header">
                <template #cell(check)="{ item }">
                    <b-form-checkbox v-model="item.check" :checked="item.check" switch></b-form-checkbox>
                </template>
            </b-table>
        </b-card>
        <b-card class="sa-card sa-card-border" header="CONTINUITA' DEL PASSO" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaContinuitaPasso" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header">
                <template #cell(check)="{ item }">
                    <b-form-checkbox v-model="item.check" :checked="item.check" switch></b-form-checkbox>
                </template>
            </b-table>
        </b-card>
        <b-card class="sa-card sa-card-border" header="TRAIETTORIA" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaTraiettoria" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header">
                <template #cell(check)="{ item }">
                    <b-form-checkbox v-model="item.check" :checked="item.check" switch></b-form-checkbox>
                </template>
            </b-table>
        </b-card>
        <b-card class="sa-card sa-card-border" header="CAMMINO" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaCammino" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header">
                <template #cell(check)="{ item }">
                    <b-form-checkbox v-model="item.check" :checked="item.check" switch></b-form-checkbox>
                </template>
            </b-table>
        </b-card>
        <b-card class="sa-card sa-card-border" header="TRONCO" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaTronco" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header">
                <template #cell(check)="{ item }">
                    <b-form-checkbox v-model="item.check" :checked="item.check" switch></b-form-checkbox>
                </template>
            </b-table>
        </b-card>
        <b-card class="sa-card sa-card-border" header="Totale" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <span class="sa-label-data">{{ totale }}</span>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import axios from "axios";
export default {
    components: {},
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    watch: {
        listaEquilibrioSeduto: {
            handler() {
                let me = this;
                me.unisciArray();
            },
            deep: true,
        },
        listaAlzarsiSedia: {
            handler() {
                let me = this;
                me.unisciArray();
            },
            deep: true,
        },
        listaTentativoAlzarsi: {
            handler() {
                let me = this;
                me.unisciArray();
            },
            deep: true,
        },
        listaEquilibrioStazioneEretta: {
            handler() {
                let me = this;
                me.unisciArray();
            },
            deep: true,
        },
        listaEquilibrioStazioneErettaProlungata: {
            handler() {
                let me = this;
                me.unisciArray();
            },
            deep: true,
        },
        listaRomberg: {
            handler() {
                let me = this;
                me.unisciArray();
            },
            deep: true,
        },
        listaRombergSensibilizzato: {
            handler() {
                let me = this;
                me.unisciArray();
            },
            deep: true,
        },
        listaGirarsi: {
            handler() {
                let me = this;
                me.unisciArray();
            },
            deep: true,
        },
        listaSedersi: {
            handler() {
                let me = this;
                me.unisciArray();
            },
            deep: true,
        },
        listaInizioDeambulazione: {
            handler() {
                let me = this;
                me.unisciArray();
            },
            deep: true,
        },
        listaLunghezzaPassoPiedeDx: {
            handler() {
                let me = this;
                me.unisciArray();
            },
            deep: true,
        },
        listaAltezzaPassoPiedeDx: {
            handler() {
                let me = this;
                me.unisciArray();
            },
            deep: true,
        },
        listaLunghezzaPassoPiedeSx: {
            handler() {
                let me = this;
                me.unisciArray();
            },
            deep: true,
        },
        listaAltezzaPassoPiedeSx: {
            handler() {
                let me = this;
                me.unisciArray();
            },
            deep: true,
        },
        listaSimmetriaPasso: {
            handler() {
                let me = this;
                me.unisciArray();
            },
            deep: true,
        },
        listaContinuitaPasso: {
            handler() {
                let me = this;
                me.unisciArray();
            },
            deep: true,
        },
        listaTraiettoria: {
            handler() {
                let me = this;
                me.unisciArray();
            },
            deep: true,
        },
        listaCammino: {
            handler() {
                let me = this;
                me.unisciArray();
            },
            deep: true,
        },
        listaTronco: {
            handler() {
                let me = this;
                me.unisciArray();
            },
            deep: true,
        },
    },
    data() {
        return {
            idAccettazione: null,
            keyPropostaAccesso: 0,
            linkback: "",
            linkPrintData: "",
            childKey: 0,
            pathResource: "/rsascalatinettirichieste",
            totale: 0,
            jsonData: [],
            listaEquilibrioSeduto: [],
            listaAlzarsiSedia: [],
            listaTentativoAlzarsi: [],
            listaEquilibrioStazioneEretta: [],
            listaEquilibrioStazioneErettaProlungata: [],
            listaRomberg: [],
            listaContinuitaPasso: [],
            listaRombergSensibilizzato: [],
            listaGirarsi: [],
            listaSedersi: [],
            listaInizioDeambulazione: [],
            listaLunghezzaPassoPiedeDx: [],
            listaAltezzaPassoPiedeDx: [],
            listaLunghezzaPassoPiedeSx: [],
            listaAltezzaPassoPiedeSx: [],
            listaSimmetriaPasso: [],
            listaTraiettoria: [],
            listaCammino: [],
            listaTronco: [],
            fieldsListe: [
                {
                    label: "Descrizione",
                    key: "fattore",
                    sortable: true,
                },
                {
                    label: "",
                    key: "check",
                    sortable: true,
                    thStyle: "width: 10rem",
                    tdClass: "text-center sa-column-widh",
                },
                // {
                //   label: "",
                //   key: "action",
                //   thStyle: "width: 10rem",
                // },
            ],
        };
    },
    mounted() {
        let me = this;
        if (this.$route.params.id) {
            me.idAccettazione = this.$route.params.id;
        } else {
            me.idAccettazione = this.$route.query.idPaziente;
        }
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/richiesta/" + me.idAccettazione;
            axios
                .get(link)
                .then((response) => {
                    me.jsonData = response.data.data.list;
                    me.calcolaPunteggio();
                    me.jsonData.forEach((element) => {
                        if (element.check) {
                            me.jsonData.push({ idRichiesta: me.idAccettazione, idScalaTinetti: element.id });
                        }
                        // console.log(element.gruppo);
                        switch (element.gruppo) {
                            case "EQUILIBRIO DA SEDUTO":
                                me.listaEquilibrioSeduto.push(element);
                                break;
                            case "ALZARSI DALLA SEDIA":
                                me.listaAlzarsiSedia.push(element);
                                break;
                            case "TENTATIVO DI ALZARSI":
                                me.listaTentativoAlzarsi.push(element);
                                break;
                            case "EQUILIBRIO STAZIONE ERETTA":
                                me.listaEquilibrioStazioneEretta.push(element);
                                break;
                            case "EQUILIBRIO STAZIONE ERETTA PROLUNGATA":
                                me.listaEquilibrioStazioneErettaProlungata.push(element);
                                break;
                            case "ROMBERG":
                                me.listaRomberg.push(element);
                                break;
                            case "ROMBERG SENSIBILIZZATO":
                                me.listaRombergSensibilizzato.push(element);
                                break;
                            case "GIRARSI DI 360":
                                me.listaGirarsi.push(element);
                                break;
                            case "SEDERSI":
                                me.listaSedersi.push(element);
                                break;
                            case "INIZIO DELLA DEAMBULAZIONE":
                                me.listaInizioDeambulazione.push(element);
                                break;
                            case "LUNGHEZZA PASSO (PIEDE DX)":
                                me.listaLunghezzaPassoPiedeDx.push(element);
                                break;
                            case "ALTEZZA PASSO (PIEDE DX)":
                                me.listaAltezzaPassoPiedeDx.push(element);
                                break;
                            case "LUNGHEZZA PASSO (PIEDE SX)":
                                me.listaLunghezzaPassoPiedeSx.push(element);
                                break;
                            case "ALTEZZA PASSO (PIEDE SX)":
                                me.listaAltezzaPassoPiedeSx.push(element);
                                break;
                            case "SIMMETRIA DEL PASSO":
                                me.listaSimmetriaPasso.push(element);
                                break;
                            case "CONTINUITA' DEL PASSO":
                                me.listaContinuitaPasso.push(element);
                                break;
                            case "TRAIETTORIA":
                                me.listaTraiettoria.push(element);
                                break;
                            case "CAMMINO":
                                me.listaCammino.push(element);
                                break;
                            case "TRONCO":
                                me.listaTronco.push(element);
                                break;
                        }
                    });
                })
                .catch(() => {});
        },
        unisciArray() {
            let me = this;
            me.jsonData = [];
            me.jsonData = me.listaEquilibrioSeduto
                .concat(me.listaAlzarsiSedia)
                .concat(me.listaTentativoAlzarsi)
                .concat(me.listaEquilibrioStazioneEretta)
                .concat(me.listaEquilibrioStazioneErettaProlungata)
                .concat(me.listaRomberg)
                .concat(me.listaContinuitaPasso)
                .concat(me.listaRombergSensibilizzato)
                .concat(me.listaGirarsi)
                .concat(me.listaSedersi)
                .concat(me.listaInizioDeambulazione)
                .concat(me.listaLunghezzaPassoPiedeDx)
                .concat(me.listaAltezzaPassoPiedeDx)
                .concat(me.listaLunghezzaPassoPiedeSx)
                .concat(me.listaAltezzaPassoPiedeSx)
                .concat(me.listaSimmetriaPasso)
                .concat(me.listaTraiettoria)
                .concat(me.listaCammino)
                .concat(me.listaTronco);
            let lista = [];

            me.jsonData.forEach((element) => {
                if (element.check) {
                    lista.push({ idRichiesta: me.idAccettazione, idScalaTinetti: element.id });
                }
            });
            me.calcolaPunteggio();
            //   console.log(lista);
            this.$emit("update", lista);
        },
        calcolaPunteggio() {
            let me = this;
            me.totale = 0;

            me.jsonData.forEach((element) => {
                if (element.check) {
                    let p = element.punteggio >= 0 ? element.punteggio : element.punteggio * -1;
                    me.totale = me.totale + p;
                }
            });
        },

        setListe() {},
    },
};
</script>

<style></style>
