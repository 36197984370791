<template>
    <sa-page-layout :showModalLoading="showModalLoading" :pathResource="pathResource">
        <template slot="table-body">
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data"></label>
                    <b-button size="sm" variant="btn btn-danger waves-effect waves-light width-sm no-text" style="margin-right: 3px" @click="onAggiorna()">
                        Aggiorna
                    </b-button>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">{{ jsonData.importStato }}</b-col>
            </b-row>
        </template>
    </sa-page-layout>
</template>

<script>
import axios from "axios";
import SaPageLayout from "../../template/layout/components/SaPageLayout.vue";
export default {
    components: { SaPageLayout },
    computed: {

    },
    data() {
        return {
            showModalLoading: false,
            pathResource: "/caseificioimport",
            pathRest: "/attivazione",
            jsonData: {},
        };
    },
    mounted: function () {
        let me = this;
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathRest + "/";
            me.isBusy = true;
            axios
                .get(link + "1")
                .then((response) => {
                    me.jsonData = response.data.data;

                })
                .catch(() => {
                    me.showmolalloading = false;
                });
        },
        onAggiorna() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            // me.showModalLoading = true;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    this.$bvModal
                        .msgBoxOk(response.data.messaggio, {
                            title: "",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then((value) => {
                            this.boxTwo = value;
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                    //me.$refs["SaPageList"].close();
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        onEdit() {
            console.log("Delete");
        },
    },
};
</script>

<style></style>
