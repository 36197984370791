import RsaAccettazioneList from "./pages/RsaAccettazioneList.vue";
import RsaAccettazioneEdit from "./pages/RsaAccettazioneEditPage.vue";
import RsaAccettazioneView from "./pages/RsaAccettazioneView.vue";
import RsaAccettazioneRicetteEditPage from "./pages/RsaAccettazioneRicetteEditPage.vue";

export default {
    RsaAccettazioneList,
    RsaAccettazioneEdit,
    RsaAccettazioneView,
    RsaAccettazioneRicetteEditPage,
};
