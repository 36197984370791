<template>
    <b-modal ref="mdlNuovaVoce" size="lg" :title="titoloModal" @hidden="onCloseModal" @ok="onClickOk" centered no-close-on-backdrop no-close-on-esc hide-header-close>
        <div>
            <b-card class="sa-card" header="Dettaglio" header-tag="header" footer-tag="footer" title="">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <label class="sa-label-data">Sezione:</label>
                        <b-form-select v-model="jsonData.sezioneDizionario" :options="sezioneOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Codice:</label>
                        <b-form-input v-model="jsonData.codiceTipo" placeholder="Codice"></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Descirizione:</label>
                        <b-form-input v-model="jsonData.descrizioneTipo" placeholder="Descrizione"></b-form-input>
                    </b-col>
                </b-row>
            </b-card>
        </div>
    </b-modal>
</template>

<script>
import UtilityMixin from "../../utility/UtilityMixin";
// import DatePicker from "vue2-datepicker";
export default {
    mixins: [UtilityMixin],
    // components: { DatePicker },
    data() {
        return {
            isNew: true,
            isReadonly: false,
            isRequired: false,
            pathResource: "/dizionariotipi",
            pathResourceSezioneDizionario: "/dizionariosezioni",
            titoloModal: "",
            sezioneOptions: [{ value: "", text: "-Seleziona Valore-" }],
            jsonData: { sezioneDizionario: "", codiceTipo: null, descrizioneTipo: null },
        };
    },
    methods: {
        openModal(item) {
            let me = this;
            me.$emit("beforloadData");
            if (item !== null) {
                me.titoloModal = "Modifica Voce Dizionario";
                me.jsonData = item;
            } else {
                me.titoloModal = "Aggiungi Voce Dizionario";
                me.jsonData = { sezioneDizionario: "", codiceTipo: null, descrizioneTipo: null };
            }
            me.loadSezioni();
            me.$refs.mdlNuovaVoce.show();
            me.$emit("afterLoadData");
        },
        loadSezioni() {
            let me = this;
            let filtro = {};
            UtilityMixin.methods.loadDataResources(me.pathResourceSezioneDizionario, filtro, me.loadDataSuccess, me.loadDataError);
        },
        loadDataSuccess(response) {
            let me = this;
            (me.sezioneOptions = []),
                response.data.data.list.forEach((element) => {
                    me.sezioneOptions.push({ value: element.sezione, text: element.sezione });
                });
            me.sezioneOptions.unshift({ value: "", text: "-Seleziona Valore-" });
        },
        loadDataError(response) {
            console.log(response);
        },
        onCloseModal() {
            let me = this;
            me.$emit("beforloadData");
            me.jsonData = { nomeMedicoPresente: null, idPaziente: null };
            me.$emit("afterLoadData");
        },
        onClickOk(bvModalEvent) {
            let me = this;
            me.$emit("beforloadData");
            bvModalEvent.preventDefault();
            // console.log(me.jsonData);
            UtilityMixin.methods.saveResource(me.pathResource, me.jsonData, this.saveSuccessResponse, this.saveErrorResponse);
        },
        saveSuccessResponse(response) {
            let me = this;
            me.$emit("afterSaveSuccess", response);
            me.$emit("afterLoadData");
            me.$refs.mdlNuovaVoce.hide();
            me.$bvToast.toast("Salvataggio avvenuto con successo", {
                title: "Successo",
                variant: "success",
                solid: true,
            });
        },

        saveErrorResponse(response) {
            if (response.response.status === 400) {
                this.$bvModal.msgBoxOk(response.response.data.messaggio, {
                    title: "ATTENZIONE",
                    size: "sm",
                    okVariant: "outline-secondary",
                    headerClass: "sa-msg-header-danger",
                    footerClass: "p-2 border-top-0",
                    centered: true,
                });
            } else {
                this.$bvToast.toast("Errore nel salvataggio" + response.message, {
                    title: "Errore",
                    variant: "danger",
                    solid: true,
                });
            }
            this.$emit("afterLoadData");
        },
    },
};
</script>
