<template>
    <div class="form-group">
        <b-row>
            <b-col sm="12" md="12" lg="12" xl="2">
                <label for="jsonData-nazionalita">Nazionalita'</label>
                <b-input-group class="mb-2">
                    <b-input-group-prepend is-text><b-icon icon="flag-fill"></b-icon> </b-input-group-prepend>
                    <b-form-select id="jsonData-nazionalita" v-model="jsonData.nazione" :options="nazionalitaOptions" value-field="codiceIstat" text-field="comune" :value="null" @input="onNazioneInput"></b-form-select>
                </b-input-group>
            </b-col>
            <b-col sm="6" md="6" lg="6" xl="2">
                <label for="jsonData-tipoidentificativo">Tipo Identificativo</label>
                <b-input-group class="mb-2">
                    <b-input-group-prepend is-text> <b-icon icon="person-badge-fill"></b-icon> </b-input-group-prepend>
                    <b-form-select id="jsonData-tipoidentificativo" :disabled="isDisabledTipoIdentificativo" v-model="jsonData.tipoIdentificativo" :options="tipoIdentificativoOptions" value-field="value" text-field="text"></b-form-select>
                </b-input-group>
            </b-col>
            <b-col sm="6" md="6" lg="6" xl="2">
                <label for="jsonData-nome">Identificativo</label>
                <b-input-group class="mb-2">
                    <b-input-group-prepend is-text> <b-icon icon="person-badge-fill"></b-icon> </b-input-group-prepend>
                    <b-form-input id="jsonData-nome" v-model="jsonData.identificativo" type="text"></b-form-input>
                </b-input-group>
            </b-col>
            <b-col sm="6" md="6" lg="6" xl="3">
                <label for="jsonData-cognome">Cognome</label>
                <b-input-group class="mb-2">
                    <b-input-group-prepend is-text> <b-icon icon="person-fill"></b-icon> </b-input-group-prepend>
                    <b-form-input id="jsonData-cognome" v-model="jsonData.cognome" type="text"></b-form-input>
                </b-input-group>
            </b-col>
            <b-col sm="6" md="6" lg="6" xl="3">
                <label for="jsonData-nome">Nome</label>
                <b-input-group class="mb-2">
                    <b-input-group-prepend is-text> <b-icon icon="person-fill"></b-icon> </b-input-group-prepend>
                    <b-form-input id="jsonData-nome" v-model="jsonData.nome" type="text"></b-form-input>
                </b-input-group>
            </b-col>
            <b-col sm="6" md="6" lg="6" xl="2">
                <label for="jsonData-sesso">Sesso</label>
                <b-input-group class="mb-2">
                    <b-input-group-prepend is-text> <b-icon icon="person-fill"></b-icon> </b-input-group-prepend>
                    <b-form-select id="jsonData-sesso" :options="sessoOptions" v-model="jsonData.sesso" type="text"></b-form-select>
                </b-input-group>
            </b-col>
            <b-col sm="6" md="6" lg="6" xl="2">
                <label for="jsonData-datanascita">Data di Nascita</label>
                <b-input-group class="mb-2">
                    <b-input-group-prepend is-text><font-awesome-icon icon="birthday-cake" /></b-input-group-prepend>
                    <date-picker id="jsonData-datanascita" placeholder="DD-MM-YYYY" style="display: block; width: 100%" format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataNascita" type="date"></date-picker>
                </b-input-group>
            </b-col>
            <b-col sm="6" md="6" lg="6" xl="4">
                <label for="jsonData-provincianascita">Provincia di Nascita</label>
                <b-input-group class="mb-2">
                    <b-input-group-prepend is-text> <b-icon icon="building"></b-icon> </b-input-group-prepend>
                    <b-form-select id="jsonData-provincianascita" v-model="jsonData.provinciaNascitaSigla" :options="provinceNascitaOptions" type="text" text-field="provincia" value-field="sigla" @input="onProvinciaNascitaInput"></b-form-select>
                </b-input-group>
            </b-col>
            <b-col sm="6" md="6" lg="6" xl="4">
                <label for="jsonData-comunenascita">Comune di Nascita</label>
                <b-input-group class="mb-2">
                    <b-input-group-prepend is-text> <b-icon icon="building"></b-icon> </b-input-group-prepend>
                    <b-form-select id="jsonData-comunenascita" :options="comuniNascitaOptions" v-model="jsonData.comuneNascitaCodiceIstat" type="text" text-field="comune" value-field="codiceIstat"></b-form-select>
                </b-input-group>
            </b-col>
        </b-row>
        <br />
        <b-row>
            <b-col sm="6" md="6" lg="6" xl="2">
                <label for="jsonData-provinciaresidenza">Provincia di Residenza</label>
                <b-input-group class="mb-2">
                    <b-input-group-prepend is-text> <b-icon icon="house-door-fill"></b-icon> </b-input-group-prepend>
                    <b-form-select id="jsonData-provinciaresidenza" v-model="jsonData.provinciaResidenzaSigla" :options="provinceResidenzaOptions" type="text" text-field="provincia" value-field="sigla" @input="onProvinciaResidenzaInput"></b-form-select>
                </b-input-group>
            </b-col>
            <b-col sm="6" md="6" lg="6" xl="2">
                <label for="jsonData-comuneresidenza">Comune di Residenza</label>
                <b-input-group class="mb-2">
                    <b-input-group-prepend is-text> <b-icon icon="house-door-fill"></b-icon> </b-input-group-prepend>
                    <b-form-select id="jsonData-comuneresidenza" :options="comuniResidenzaOptions" v-model="jsonData.comuneResidenzaCodiceIstat" type="text" text-field="comune" value-field="codiceIstat" @input="onComuneResidenzaInput"></b-form-select>
                </b-input-group>
            </b-col>
            <b-col sm="9" md="9" lg="9" xl="3">
                <label for="jsonData-indirizzo">Indirizzo</label>
                <b-input-group class="mb-2">
                    <b-input-group-prepend is-text> <b-icon icon="door-closed-fill"></b-icon></b-input-group-prepend>
                    <b-form-input id="jsonData-indirizzo" v-model="jsonData.indirizzoResidenza" type="text"></b-form-input>
                </b-input-group>
            </b-col>
            <b-col sm="3" md="3" lg="3" xl="1">
                <label for="jsonData-civico">Civico</label>
                <b-form-input id="jsonData-frazionedomicilio" v-model="jsonData.civicoResidenza" type="text"></b-form-input>
            </b-col>
            <b-col sm="6" md="6" lg="6" xl="3">
                <label for="jsonData-frazionedomicilio">Frazione</label>
                <b-input-group class="mb-2">
                    <b-input-group-prepend is-text> <b-icon icon="building"></b-icon></b-input-group-prepend>
                    <b-form-input id="jsonData-frazionedomicilio" v-model="jsonData.frazioneResidenza" type="text"></b-form-input>
                </b-input-group>
            </b-col>
            <b-col sm="6" md="6" lg="6" xl="1">
                <label for="jsonData-capresidenza">C.A.P</label>
                <b-form-input id="jsonData-capresidenza" v-model="jsonData.capResidenza" type="text"></b-form-input>
            </b-col>
        </b-row>
        <br />
        <b-form-checkbox button-variant="success" v-model="checkDomicilioUgualeResidenza" name="domicilio-residenza-coincidono" switch size="lg"> Domicilio e Residenza Coincidono ? </b-form-checkbox>
        <br />

        <b-row v-if="!checkDomicilioUgualeResidenza">
            <b-col sm="6" md="6" lg="6" xl="2">
                <label for="jsonData-provinciadomicilio">Provincia di Domicilio</label>
                <b-input-group class="mb-2">
                    <b-input-group-prepend is-text> <b-icon icon="house-fill"></b-icon> </b-input-group-prepend>
                    <b-form-select id="jsonData-provinciadomicilio" v-model="jsonData.provinciaDomicilioSigla" :options="provinceDomicilioOptions" type="text" text-field="provincia" value-field="sigla" @input="onProvinciaDomicilioInput"></b-form-select>
                </b-input-group>
            </b-col>
            <b-col sm="6" md="6" lg="6" xl="2">
                <label for="jsonData-comunedomicilio">Comune di Domicilio</label>
                <b-input-group class="mb-2">
                    <b-input-group-prepend is-text> <b-icon icon="house-fill"></b-icon> </b-input-group-prepend>
                    <b-form-select id="jsonData-comunedomicilio" :options="comuniDomicilioOptions" v-model="jsonData.comuneDomicilioCodiceIstat" type="text" text-field="comune" value-field="codiceIstat" @input="onComuneDomicilioInput"></b-form-select>
                </b-input-group>
            </b-col>
            <b-col sm="9" md="9" lg="9" xl="3">
                <label for="jsonData-indirizzodomicilio">Indirizzo</label>
                <b-input-group class="mb-2">
                    <b-input-group-prepend is-text> <b-icon icon="door-closed-fill"></b-icon></b-input-group-prepend>
                    <b-form-input id="jsonData-indirizzodomicilio" v-model="jsonData.indirizzoDomicilio" type="text"></b-form-input>
                </b-input-group>
            </b-col>
            <b-col sm="3" md="3" lg="3" xl="1">
                <label for="jsonData-civico">Civico</label>
                <b-form-input id="jsonData-frazionedomicilio" v-model="jsonData.civicoDomicilio" type="text"></b-form-input>
            </b-col>

            <b-col sm="6" md="6" lg="6" xl="3">
                <label for="jsonData-frazionedomicilio">Frazione</label>
                <b-input-group class="mb-2">
                    <b-input-group-prepend is-text> <b-icon icon="building"></b-icon></b-input-group-prepend>
                    <b-form-input id="jsonData-frazionedomicilio" v-model="jsonData.frazioneDomicilio" type="text"></b-form-input>
                </b-input-group>
            </b-col>
            <b-col sm="6" md="6" lg="6" xl="1">
                <label for="jsonData-capdomicilio">C.A.P</label>
                <b-form-input id="jsonData-capdomicilio" v-model="jsonData.capDomicilio" type="text"></b-form-input>
            </b-col>
        </b-row>
        <br />
        <b-row>
            <b-col sm="6" md="6" lg="6" xl="6">
                <label for="jsonData-cellulare">Cellulare</label>
                <b-input-group class="mb-2">
                    <b-input-group-prepend is-text> <b-icon icon="phone-fill"></b-icon> </b-input-group-prepend>
                    <b-form-input id="jsonData-cellulare" v-model="jsonData.cellulare" type="text"></b-form-input>
                </b-input-group>
            </b-col>
            <b-col sm="6" md="6" lg="6" xl="6">
                <label for="jsonData-email">Email</label>
                <b-input-group class="mb-2">
                    <b-input-group-prepend is-text> <b-icon icon="envelope"></b-icon> </b-input-group-prepend>
                    <b-form-input id="jsonData-email" placeholder="io@esempio.it" v-model="jsonData.email" type="text"></b-form-input> </b-input-group
            ></b-col>
        </b-row>
    </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import UtilityMixins from "../../utility/UtilityMixin";
import axios from "axios";
export default {
    mixins: [UtilityMixins],
    components: { DatePicker },
    props: {
        jsonData: {
            type: Object,
            default: function () {
                return {};
            },
        },
    },
    mounted() {
        let me = this;
        me.loadDefaultData();
    },
    data() {
        return {
            checkDomicilioUgualeResidenza: false,
            isDisabledTipoIdentificativo: true,
            sessoOptions: [
                { text: " - Selezionare - ", value: null },
                { text: "Maschio", value: "M" },
                { text: "Femmina", value: "F" },
            ],
            provinceNascitaOptions: [],
            provinceResidenzaOptions: [],
            provinceDomicilioOptions: [],
            nazionalitaOptions: [{ comune: "- Seleziona Nazionalita' -", codiceIstat: null }],
            comuniResidenzaOptions: [{ comune: "- Seleziona Comune Residenza -", codiceIstat: null }],
            comuniDomicilioOptions: [{ comune: "- Seleziona Comune Domicilio -", codiceIstat: null }],
            comuniNascitaOptions: [{ comune: "- Seleziona Comune Nascita -", codiceIstat: null }],
            tipoIdentificativoOptions: [
                { text: "- Seleziona Tipo Identificativo' -", value: null },
                { text: "Carta D'Identita'", value: "CI" },
                { text: "Codice Fiscale", value: "CF" },
                { text: "Passaporto", value: "PS" },
            ],
        };
    },
    watch: {
        checkDomicilioUgualeResidenza() {
            let me = this;
            if (me.checkDomicilioUgualeResidenza) {
                me.jsonData.provinceDomicilioSigla = me.jsonData.provinceResidenzaSigla;
                me.jsonData.comuneDomicilioCodiceIstat = me.jsonData.comuneResidenzaCodiceIstat;
                me.jsonData.indirizzoDomicilio = me.jsonData.indirizzoResidenza;
                me.jsonData.civicoDomicilio = me.jsonData.civicoResidenza;
                me.jsonData.frazioneDomicilio = me.jsonData.frazioneResidenza;
                me.jsonData.capDomicilio = me.jsonData.capResidenza;
            }
        },
    },
    methods: {
        loadDefaultData() {
            let me = this;
            let linkNazioni = process.env.VUE_APP_PATH_API + "/nazioni";
            axios.get(linkNazioni).then((response) => {
                me.nazionalitaOptions = response.data.data;
            });
            let linkProvince = process.env.VUE_APP_PATH_API + "/province";
            axios.get(linkProvince).then((response) => {
                response.data.data.forEach((elemento) => {
                    me.provinceNascitaOptions.push(elemento);
                    me.provinceResidenzaOptions.push(elemento);
                    me.provinceDomicilioOptions.push(elemento);
                });
                me.provinceDomicilioOptions.unshift({ provincia: "- Seleziona Provincia Domicilio -", sigla: null });
                me.provinceResidenzaOptions.unshift({ provincia: "- Seleziona Provincia Residenza -", sigla: null });
                me.provinceNascitaOptions.unshift({ provincia: "- Seleziona Provincia Nascita -", sigla: null });
            });
        },
        onNazioneInput(value) {
            let me = this;
            if (value === "111100") {
                me.isDisabledTipoIdentificativo = true;
                me.anagrafica.tipoIdentificativo = "CF";
            } else {
                me.isDisabledTipoIdentificativo = false;
                me.anagrafica.tipoIdentificativo = null;
            }
        },
        onProvinciaNascitaInput(value) {
            let me = this;
            me.loadComuni("NASCITA", value);
        },
        onProvinciaResidenzaInput(value) {
            let me = this;
            me.loadComuni("RESIDENZA", value);
        },
        onProvinciaDomicilioInput(value) {
            let me = this;
            me.loadComuni("DOMICILIO", value);
        },
        onComuneResidenzaInput(value) {
            let me = this;
            me.loadCAP("CAPRESIDENZA", value);
        },
        onComuneDomicilioInput(value) {
            let me = this;
            me.loadCAP("CAPDOMICILIO", value);
        },
        loadComuni(tipo, provincia) {
            let me = this;
            let linkComuni = process.env.VUE_APP_PATH_API + "/comuni";
            axios.get(linkComuni, { params: { provincia: provincia } }).then((response) => {
                switch (tipo) {
                    case "NASCITA":
                        me.comuniNascitaOptions = response.data.data;
                        me.comuniNascitaOptions.unshift({ comune: "- Seleziona Comune Nascita -", codiceIstat: null });
                        break;
                    case "RESIDENZA":
                        me.comuniResidenzaOptions = response.data.data;
                        me.comuniResidenzaOptions.unshift({ comune: "- Seleziona Comune Residenza -", codiceIstat: null });
                        break;
                    case "DOMICILIO":
                        me.comuniDomicilioOptions = response.data.data;
                        me.comuniDomicilioOptions.unshift({ comune: "- Seleziona Comune Domicilio -", codiceIstat: null });
                        break;
                }
            });
        },
        loadCAP(tipo, codiceIstatComune) {
            let me = this;
            switch (tipo) {
                case "CAPDOMICILIO":
                    me.comuniDomicilioOptions.forEach((element) => {
                        if (element.codiceIstat === codiceIstatComune) {
                            me.jsonData.capDomicilio = element.cap;
                        }
                    });
                    break;
                case "CAPRESIDENZA": {
                    me.comuniResidenzaOptions.forEach((element) => {
                        if (element.codiceIstat === codiceIstatComune) {
                            me.jsonData.capResidenza = element.cap;
                        }
                    });
                    break;
                }
            }
        },
    },
};
</script>

<style></style>
