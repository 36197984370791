<template>
    <div>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Data insorgenza primi sintomi</label>
                    <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataInsorgenzaPrimiSintomi" type="timestamp" placeholder="Data Insorgenza Sintomi"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Ricovero Ospedaliero</label>
                    <b-form-select v-model="jsonData.ricoveroOspedaliero" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputRicoveroOspedaliero"></b-form-select>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Ricovero</label>
                    <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataRicovero" type="timestamp" placeholder="Data Ricovero" :disabled="datiRicoveroDisabled"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Nome Ospedale</label>
                    <b-form-input v-model="jsonData.nomeOspedale" placeholder="Nome Ospedale" :disabled="datiRicoveroDisabled"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Nome Reparto</label>
                    <b-form-input v-model="jsonData.nomeReparto" placeholder="Nome Reparto" :disabled="datiRicoveroDisabled"></b-form-input>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Terapia Antivirale</label>
                    <b-form-select v-model="jsonData.terapiaAntivirale" :options="standardSelectOptions" :value="''" value-field="value" text-field="text" @input="onInputTerapiaAntivirale"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Terapia Antivirale Tipo</label>
                    <b-form-select v-model="jsonData.terapiaAntiviraleTipo" :options="terapiaAntiviraleTipoOptions" :value="''" value-field="value" text-field="text" :disabled="terapiaAntiviraleDisabled"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Data Inizio Terapia</label>
                    <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataInizioTerapia" type="timestamp" placeholder="Data Inizio Terapia" :disabled="terapiaAntiviraleDisabled"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Durata Terapia (in giorni)</label>
                    <b-form-input v-model="jsonData.durataTerapia" type="number" :disabled="terapiaAntiviraleDisabled"></b-form-input>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Presenza Patologie Croniche</label>
                    <b-form-select v-model="jsonData.patologieCroniche" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputPatologieCroniche"></b-form-select>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Tumore</label>
                    <b-form-select v-model="jsonData.tumore" :options="standardSelectOptions" :value="''" value-field="value" text-field="text" :disabled="patologieCronicheDisabled"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Diabete</label>
                    <b-form-select v-model="jsonData.diabete" :options="standardSelectOptions" :value="''" value-field="value" text-field="text" :disabled="patologieCronicheDisabled"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Malattie Cardiovascolari</label>
                    <b-form-select v-model="jsonData.malattieCardiovascolari" :options="standardSelectOptions" :value="''" value-field="value" text-field="text" :disabled="patologieCronicheDisabled"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Deficit Immunitari</label>
                    <b-form-select v-model="jsonData.deficitImmunitari" :options="standardSelectOptions" :value="''" value-field="value" text-field="text" :disabled="patologieCronicheDisabled"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Malattie Respiratorie</label>
                    <b-form-select v-model="jsonData.malattieRespiratorie" :options="standardSelectOptions" :value="''" value-field="value" text-field="text" :disabled="patologieCronicheDisabled"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Malattie Renali</label>
                    <b-form-select v-model="jsonData.malattieRenali" :options="standardSelectOptions" :value="''" value-field="value" text-field="text" :disabled="patologieCronicheDisabled"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Malattie Metaboliche</label>
                    <b-form-select v-model="jsonData.malattieMetaboliche" :options="standardSelectOptions" :value="''" value-field="value" text-field="text" :disabled="patologieCronicheDisabled"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Obesita Bmi</label>
                    <b-form-select v-model="jsonData.obesitaBmi" :options="standardSelectOptions" :value="''" value-field="value" text-field="text" :disabled="patologieCronicheDisabled"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Obesita Bmi Intervallo</label>
                    <b-form-select v-model="jsonData.obesitaBmiIntervallo" :options="obesitaBmiIntervalloOptions" :value="''" value-field="value" text-field="text" :disabled="jsonData.obesitaBmi !== 'SI'"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Altra Patologia Cronica</label>
                    <b-form-textarea v-model="jsonData.altraPatologiaCronica" placeholder="Altra Patologia Cronica" rows="10" no-resize :disabled="patologieCronicheDisabled"></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Confermato A(H1N1)v</label>
                    <b-form-select v-model="jsonData.confermatoAH1n1V" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputConferme(jsonData.confermatoAH1n1V, 'confermatoAH1n1VDataConferma', 'confermatoAH1n1VDisabled')"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Data Conferma A(H1N1)v</label>
                    <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.confermatoAH1n1VDataConferma" type="timestamp" placeholder="Data Conferma" :disabled="disabledValue.confermatoAH1n1VDisabled"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Confermato A(H1N1)</label>
                    <b-form-select v-model="jsonData.confermatoAH1n1" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputConferme(jsonData.confermatoAH1n1, 'confermatoAH1n1DataConferma', 'confermatoAH1n1Disabled')"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Data Conferma A(H1N1)</label>
                    <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.confermatoAH1n1DataConferma" type="timestamp" placeholder="Data Conferma" :disabled="disabledValue.confermatoAH1n1Disabled"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Confermato AH3n2</label>
                    <b-form-select v-model="jsonData.confermatoAH3n2" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputConferme(jsonData.confermatoAH3n2, 'confermatoAH3n2DataConferma', 'confermatoAH3n2Disabled')"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Data Conferma AH3n2</label>
                    <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.confermatoAH3n2DataConferma" type="timestamp" placeholder="Data Conferma" :disabled="disabledValue.confermatoAH3n2Disabled"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Confermato B</label>
                    <b-form-select v-model="jsonData.confermatoB" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputConferme(jsonData.confermatoB, 'confermatoBDataConferma', 'confermatoBDisabled')"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Data Conferma B</label>
                    <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.confermatoBDataConferma" type="timestamp" placeholder="Data Conferma" :disabled="disabledValue.confermatoBDisabled"></date-picker>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Invio del campione al Laboratorio del Centro Nazionale Influenza presso ISS</label>
                    <b-form-select v-model="jsonData.invioCampioneLaboratorioIss" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputInvioLaboratorio"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Data Invio</label>
                    <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.invioCampioneLaboratorioIssData" type="timestamp" placeholder="Data Invio" :disabled="disableDataInvioLaboratorio"></date-picker>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Complicanze</label>
                    <b-form-select v-model="jsonData.complicanze" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputComplicanza"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Data Complicanza</label>
                    <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataComplicanze" type="timestamp" placeholder="Data Complicanza" :disabled="complicanzaDisabled"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Radiografia Eseguita</label>
                    <b-form-select v-model="jsonData.radiografiaEseguita" :options="standardSelectOptions" :value="''" value-field="value" text-field="text" :disabled="complicanzaDisabled"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Polmonite Influenzale Primaria</label>
                    <b-form-select v-model="jsonData.polmoniteInfluenzalePrimaria" :options="standardSelectOptions" :value="''" value-field="value" text-field="text" :disabled="complicanzaDisabled"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Polmonite Batterica Secondaria</label>
                    <b-form-select v-model="jsonData.polmoniteBattericaSecondaria" :options="standardSelectOptions" :value="''" value-field="value" text-field="text" :disabled="complicanzaDisabled"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Polmonite Mista</label>
                    <b-form-select v-model="jsonData.polmoniteMista" :options="standardSelectOptions" :value="''" value-field="value" text-field="text" :disabled="complicanzaDisabled"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Grave insufficienza respiratoria acuta (SARI)</label>
                    <b-form-select v-model="jsonData.graveInsufficienzaRespiratoriaAcuta" :options="standardSelectOptions" :value="''" value-field="value" text-field="text" :disabled="complicanzaDisabled"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Acute Respiratory Distress Syndrome (ARDS)</label>
                    <b-form-select v-model="jsonData.acuteRespiratoryDistressSyndrome" :options="standardSelectOptions" :value="''" value-field="value" text-field="text" :disabled="complicanzaDisabled"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Altre Complicanze</label>
                    <b-form-textarea v-model="jsonData.altraComplicanza" rows="10" no-resize placeholder="Altre Complicanze" :disabled="complicanzaDisabled"></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Esito e follow-up" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Guarigione</label>
                    <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataGuarigione" type="timestamp" placeholder="Data Guarigione"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Decesso</label>
                    <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataDecesso" type="timestamp" placeholder="Data Decesso" @input="onInputDataDecesso"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Presenza Esiti</label>
                    <b-form-select v-model="jsonData.presenzaEsiti" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputPresenzaEsito"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Specificare</label>
                    <b-form-input v-model="jsonData.presenzaEsitiSpecificare" placeholder="Specificare" :disabled="presenzaEsitoDisabled"></b-form-input>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Causa Iniziale Decesso</label>
                    <b-form-textarea v-model="jsonData.certifdicatoMorteCausaIniziale" rows="6" no-resize placeholder="Causa Iniziale" :disabled="certificatiMorteDisabled"></b-form-textarea>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Causa Terminale Decesso</label>
                    <b-form-textarea v-model="jsonData.certifdicatoMorteCausaTerminale" rows="6" no-resize placeholder="Causa Terminale" :disabled="certificatiMorteDisabled"></b-form-textarea>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Altri stati morbosi rilevanti che hanno contribuito al decesso</label>
                    <b-form-textarea v-model="jsonData.certifdicatoMorteAltriStatiMorbosi" rows="6" no-resize placeholder="Causa Terminale" :disabled="certificatiMorteDisabled"></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Stato Vaccinale" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Vaccinato per l'inluenza nella Stagione Corrente</label>
                    <b-form-select v-model="jsonData.vaccinatoInfluenzaStagioneCorrente" :options="standardSelectOptions" :value="''" value-field="value" text-field="text" @input="onInputVaccino"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Nome Vaccino</label>
                    <b-form-input v-model="jsonData.vaccinatoInfluenzaStagioneCorrenteNomeVaccino" placeholder="Nome Vaccino" :disabled="datiVaccinoDisabled"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Vaccino</label>
                    <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.vaccinatoInfluenzaStagioneCorrenteData" type="timestamp" placeholder="Data Vaccuni" :disabled="datiVaccinoDisabled"></date-picker>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Altre Considerazioni</label>
                    <b-form-textarea v-model="jsonData.altreConsiderazioni" rows="6" no-resize></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Sanitario Notificante</label>
                    <b-form-input v-model="jsonData.sanitarioNotificante"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Numero Cellulare Sanitario Notificante</label>
                    <b-form-input v-model="jsonData.telefonoSanitarioNotificante"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Notifica</label>
                    <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataNotifica" type="timestamp" placeholder="Data Notifica"></date-picker>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
<script>
import axios from "axios";
import Vue from "vue";
import EventiMixins from "../../../paziente/eventi/utility/mixins/EventiMixins";
import DatePicker from "vue2-datepicker";
export default {
    mixins: [],
    components: { DatePicker },
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    data() {
        return {
            pathResource: "/malattieinfettiveieinfluenza",
            pathResourceMalattiaInfettivaSegnalazione: "/malattieinfettivesegnalazioni",
            linkback: null,
            showModalLoading: false,
            id: "-1",
            terapiaAntiviraleDisabled: true,
            patologieCronicheDisabled: true,
            complicanzaDisabled: true,
            certificatiMorteDisabled: true,
            datiRicoveroDisabled: true,
            datiVaccinoDisabled: true,
            presenzaEsitoDisabled: true,
            disableDataInvioLaboratorio: true,
            disabledValue: {
                confermatoAH1n1VDisabled: true,
                confermatoAH1n1Disabled: true,
                confermatoAH3n2Disabled: true,
                confermatoBDisabled: true,
            },
            jsonData: {
                dataInsorgenzaPrimiSintomi: null,
                ricoveroOspedaliero: "",
                dataRicovero: null,
                nomeOspedale: "",
                nomeReparto: "",
                terapiaAntivirale: "",
                dataInizioTerapia: null,
                durataTerapia: 0,
                patologieCroniche: "",
                tumore: "",
                diabete: "",
                malattieCardiovascolari: "",
                deficitImmunitari: "",
                malattieRespiratorie: "",
                malattieRenali: "",
                malattieMetaboliche: "",
                obesitaBmi: "",
                obesitaBmiIntervallo: "",
                altraPatologiaCronica: "",
                confermatoAH1n1V: "",
                confermatoAH1n1: "",
                confermatoAH3n2: "",
                confermatoB: "",
                confermatoAH1n1VDataConferma: null,
                confermatoAH1n1DataConferma: null,
                confermatoAH3n2DataConferma: null,
                confermatoBDataConferma: null,
                invioCampioneLaboratorioIss: "",
                complicanze: "",
                dataComplicanze: null,
                radiografiaEseguita: "",
                polmoniteInfluenzalePrimaria: "",
                polmoniteBattericaSecondaria: "",
                polmoniteMista: "",
                graveInsufficienzaRespiratoriaAcuta: "",
                acuteRespiratoryDistressSyndrome: "",
                altraComplicanza: "",
                dataGuarigione: null,
                dataDecesso: null,
                certifdicatoMorteCausaIniziale: "",
                certifdicatoMorteCausaTerminale: "",
                certifdicatoMorteAltriStatiMorbosi: "",
                sanitarioNotificante: "",
                telefonoSanitarioNotificante: "",
                dataNotifica: null,
                terapiaAntiviraleTipo: "",
                vaccinatoInfluenzaStagioneCorrente: "",
                vaccinatoInfluenzaStagioneCorrenteNomeVaccino: "",
                altreConsiderazioni: "",
                vaccinatoInfluenzaStagioneCorrenteData: null,
                invioCampioneLaboratorioIssData: null,
            },
            siNoOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "SI", text: "SI" },
                { value: "NO", text: "NO" },
            ],
            standardSelectOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "SI", text: "SI" },
                { value: "NO", text: "NO" },
                { value: "NON NOTO", text: "NON NOTO" },
            ],
            terapiaAntiviraleTipoOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "OSELTAMIVIR", text: "OSELTAMIVIR" },
                { value: "ZANAMIVIR", text: "ZANAMIVIR" },
                { value: "PERAMIVIR", text: "PERAMIVIR" },
            ],
            obesitaBmiIntervalloOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "Obesità BMI tra 30 e 40", text: "Obesità BMI tra 30 e 40" },
                { value: "Obesità BMI ≥ 40", text: "Obesità BMI ≥ 40" },
            ],
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient() {
            return EventiMixins.getLinkPatient(this.utente, this.idPaziente);
        },
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.jsonData.idAnagrafica = me.idPaziente;
        me.jsonData.id = me.id;
        me.loadMalattiaInfettivaSegnalazione();
        me.loadData();
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            me.showModalLoading = true;
            axios
                .get(link + me.id)
                .then((response) => {
                    me.jsonData = response.data.data;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.setDatiMedico();
                    me.showModalLoading = false;
                });
        },
        loadMalattiaInfettivaSegnalazione() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceMalattiaInfettivaSegnalazione + "/";
            axios
                .get(link + me.id)
                .then((response) => {
                    me.setDatiMalattia(response.data.data);
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        onInputRicoveroOspedaliero(value) {
            let me = this;
            if (value === "SI") {
                me.datiRicoveroDisabled = false;
            } else {
                me.datiRicoveroDisabled = true;
                me.jsonData.dataRicovero = null;
                me.jsonData.nomeOspedale = "";
                me.jsonData.nomeReparto = "";
            }
        },
        onInputTerapiaAntivirale(value) {
            let me = this;
            if (value === "SI") {
                me.terapiaAntiviraleDisabled = false;
            } else {
                me.terapiaAntiviraleDisabled = true;
                me.jsonData.dataInizioTerapia = null;
                me.jsonData.durataTerapia = 0;
            }
        },
        onInputPatologieCroniche(value) {
            let me = this;
            if (value === "SI") {
                me.patologieCronicheDisabled = false;
            } else {
                me.patologieCronicheDisabled = true;
                me.jsonData.tumore = "";
                me.jsonData.diabete = "";
                me.jsonData.malattieCardiovascolari = "";
                me.jsonData.deficitImmunitari = "";
                me.jsonData.malattieRespiratorie = "";
                me.jsonData.malattieRenali = "";
                me.jsonData.malattieMetaboliche = "";
                me.jsonData.obesitaBmi = "";
                me.jsonData.obesitaBmiIntervallo = "";
                me.jsonData.altraPatologiaCronica = "";
            }
        },
        onInputConferme(value, dataConferma, dataDisabilitata) {
            let me = this;
            me.jsonData[dataConferma] = value === "SI" ? me.jsonData[dataConferma] : null;
            me.disabledValue[dataDisabilitata] = value === "SI" ? false : true;
        },
        onInputComplicanza(value) {
            let me = this;
            if (value === "SI") {
                me.complicanzaDisabled = false;
            } else {
                me.complicanzaDisabled = true;
                me.jsonData.dataComplicanze = null;
                me.jsonData.radiografiaEseguita = "";
                me.jsonData.polmoniteInfluenzalePrimaria = "";
                me.jsonData.polmoniteBattericaSecondaria = "";
                me.jsonData.polmoniteMista = "";
                me.jsonData.graveInsufficienzaRespiratoriaAcuta = "";
                me.jsonData.acuteRespiratoryDistressSyndrome = "";
                me.jsonData.altraComplicanza = "";
            }
        },
        onInputDataDecesso(value) {
            let me = this;
            console.log(value);
            if (value !== null) {
                me.certificatiMorteDisabled = false;
            } else {
                me.certificatiMorteDisabled = true;
                me.jsonData.certifdicatoMorteCausaIniziale = "";
                me.jsonData.certifdicatoMorteCausaTerminale = "";
                me.jsonData.certifdicatoMorteAltriStatiMorbosi = "";
            }
        },
        onInputVaccino(value) {
            let me = this;
            if (value !== null && value === "SI") {
                me.datiVaccinoDisabled = false;
            } else {
                me.datiVaccinoDisabled = true;
                me.jsonData.vaccinatoInfluenzaStagioneCorrenteNomeVaccino = "";
                me.jsonData.vaccinatoInfluenzaStagioneCorrenteData = null;
            }
        },
        onInputInvioLaboratorio(value) {
            let me = this;
            if (value !== null && value === "SI") {
                me.disableDataInvioLaboratorio = false;
            } else {
                me.disableDataInvioLaboratorio = true;
                me.jsonData.invioCampioneLaboratorioIssData = null;
            }
        },
        onInputPresenzaEsito(value) {
            let me = this;
            if (value !== null && value === "SI") {
                me.presenzaEsitoDisabled = false;
            } else {
                me.presenzaEsitoDisabled = true;
                me.jsonData.presenzaEsitiSpecificare = "";
            }
        },
        setDatiMedico() {
            let me = this;
            me.jsonData.sanitarioNotificante = Vue.prototype.user.firstname + " " + Vue.prototype.user.lastname;
        },
        setDatiMalattia(data) {
            let me = this;
            me.jsonData.id = data.id;
            console.log(me.jsonData.id);
        },
    },
};
</script>
