<template>
    <div style="height: 100%">
        <!--   <b-tabs vertical id="tabsRsaPazienteAreeView" ref="tabsRsaPazienteAreeView" v-model="activeTabIndex" nav-wrapper-class="sa-tab-items sa-tab-items-aree sa-tab-items-vertical" content-class="mt-3" class="sa-tab" style="padding-bottom: 25px" fill @input="onInputTabs"> -->
        <b-tabs id="tabsRsaDiario" ref="tabsRsaDiario" v-model="activeTabIndex" nav-wrapper-class="sa-tab-items" content-class="mt-3" class="sa-tab" style="height: 100%; padding-bottom: 25px" fill @input="onInputTabs">
            <b-tab v-for="diarioTab in diariTabs" :key="diarioTab.id" :title="diarioTab.nome">
                <div class="sa-accordion-wrapper">
                    <form>
                        <div class="sa-accordion">
                            <input class="sa-accordion-input" type="radio" :name="diarioTab.id + 'radio-a'" :id="diarioTab.id + 'radio-a'" value="1" checked="checked" />
                            <label class="sa-accordion-label" :for="diarioTab.id + 'radio-a'">
                                Diario
                                <b-button size="sm" variant="outline-success outline-secondary-noborder pull-right sa-accordion-btn" @click="onClickNuovaNota()">
                                    <i class="fas fa-plus"></i>
                                    Aggiungi Nota
                                </b-button>
                            </label>
                            <div class="sa-timeline-diario">
                                <div v-for="voceDiario in vociDiario" :key="voceDiario.id" :class="getColorAttivita(voceDiario)">
                                    <div class="sa-timeline-diario-event-icon">
                                        <i :class="getIconAttivita(voceDiario)"></i>
                                    </div>
                                    <div class="sa-timeline-diario-event-date">
                                        <div class="sa-timeline-diario-event-date-header">
                                            <span class="sa-timeline-diario-event-date-header-label" style="display: block">Eseguita:</span>
                                            <span class="sa-timeline-diario-event-date-header-data" style="display: block">{{ formatDateTime(voceDiario.dataOraEsecuzione) }}</span>
                                        </div>
                                        <div class="sa-timeline-diario-event-date-body"></div>
                                        <div class="sa-timeline-diario-event-date-footer">
                                            <span class="sa-timeline-diario-event-date-footer-label" style="display: block">Programmata:</span>
                                            <span class="sa-timeline-diario-event-date-footer-data" style="display: block">{{ formatDateTime(voceDiario.dataOraProgrammata) }}</span>
                                        </div>
                                    </div>
                                    <div class="sa-timeline-diario-event-content">
                                        <div class="sa-timeline-diario-event-content-header">
                                            <div class="sa-timeline-diario-event-title">{{ voceDiario.tipologia }}</div>
                                        </div>
                                        <div class="sa-timeline-diario-event-content-body">
                                            <p v-html="voceDiario.annotazione"></p>
                                        </div>
                                        <div class="sa-timeline-diario-event-content-footer">
                                            <b-button size="sm" variant="outline-success outline-secondary-noborder pull-right" @click="onClickNModifica()">
                                                <i class="bi bi-pencil"></i>
                                                Modifica
                                            </b-button>
                                            <b-button size="sm" variant="outline-danger outline-secondary-noborder pull-right" @click="onClickCancella()">
                                                <i class="far fa-trash-alt"></i>
                                                Cancella
                                            </b-button>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="sa-timeline-diario-event animated fadeInUp delay-2s timeline__event--type8">
                                    <div class="sa-timeline-diario-event-icon">
                                        <i class="fas fa-plus"></i>
                                    </div>
                                    <div class="sa-timeline-diario-event-date">20-08-2019</div>
                                    <div class="sa-timeline-diario-event-content">
                                        <div class="sa-timeline-diario-event-title">Lunch</div>
                                        <div class="timeline__event__description">
                                            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Vel, nam! Nam eveniet ut aliquam ab asperiores, accusamus iure veniam corporis incidunt reprehenderit accusantium id aut architecto harum quidem dolorem in!</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="sa-timeline-diario-event animated fadeInUp delay-1s timeline__event--type3">
                                    <div class="sa-timeline-diario-event-icon">
                                        <i class="fas fa-plus"></i>
                                    </div>
                                    <div class="sa-timeline-diario-event-date">20-08-2019</div>
                                    <div class="sa-timeline-diario-event-content">
                                        <div class="sa-timeline-diario-event-title">Exercise</div>
                                        <div class="timeline__event__description">
                                            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Vel, nam! Nam eveniet ut aliquam ab asperiores, accusamus iure veniam corporis incidunt reprehenderit accusantium id aut architecto harum quidem dolorem in!</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="sa-timeline-diario-event animated fadeInUp timeline__event--type1">
                                    <div class="sa-timeline-diario-event-icon">
                                        <i class="fas fa-plus"></i>
                                    </div>
                                    <div class="sa-timeline-diario-event-date">20-08-2019</div>
                                    <div class="sa-timeline-diario-event-content">
                                        <div class="sa-timeline-diario-event-title">Birthday</div>
                                        <div class="timeline__event__description">
                                            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Vel, nam! Nam eveniet ut aliquam ab asperiores, accusamus iure veniam corporis incidunt reprehenderit accusantium id aut architecto harum quidem dolorem in!</p>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                            <!--<div class="b-table-sticky-header">
                                <p>Sisto Andolfi</p>
                                <b-table sticky-header ref="table" stacked="lg" striped hover :items="vociDiario" :fields="fields" :current-page="1" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                                    <template v-slot:cell(annotazione)="{ item }">
                                        <span v-html="item.annotazione"></span>
                                    </template>
                                     <template v-slot:cell(actions)="row">
                                    <b-container class="bv-example-row">
                                        <b-button v-b-tooltip.hover size="sm" variant="outline-primary no-text" title="Modifica Pianificazione" @click="onClickEdit(row.item, row.index, $event.target)" class="mr-1 no-text">
                                            <b-icon icon="pencil"></b-icon>
                                        </b-button>
                                    </b-container>
                                </template>
                                </b-table>
                            </div> -->
                        </div>
                    </form>
                </div>
            </b-tab>
        </b-tabs>
        <rsa-diari-note-edit-component ref="cmpRsaDiariNoteEditComponent" @afterSaveRequest="onAfterSaveRequestRsaDiariNoteComponent"></rsa-diari-note-edit-component>
    </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import RsaDiariNoteEditComponent from "./RsaDiariNoteEditComponent.vue";
import UtilityMixin from "../../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixin],
    components: { RsaDiariNoteEditComponent },
    created() {
        /*    let me = this;
        me.loadDiari(); */
    },
    props: {
        //jsonDataAccettazione: { type: Object },
    },
    data() {
        return {
            activeTabIndex: 0,
            perPage: 100,
            pathResourcesDiari: "/rsadiari/attivi",
            pathResourcesVociDiario: "/rsadiarivoci",
            jsonDataAccettazione: {},
            idDiario: "",
            diarioSelezionato: "",
            codiceDiario: "",
            diariTabs: [],
            vociDiario: [],
            fields: [
                {
                    label: "Area",
                    key: "area",
                    sortable: true,
                    thStyle: "width: 10rem",
                },
                {
                    label: "Nome",
                    key: "nomeAttivita",
                    sortable: true,
                    thStyle: "width: 10rem",
                },
                {
                    label: "Tipologia",
                    key: "tipologia",
                    sortable: true,
                    thStyle: "width: 10rem",
                },
                {
                    label: "Data/ora Programmata",
                    key: "dataOraProgrammata",
                    formatter: (value) => {
                        if (value) {
                            return moment(new Date(value)).format("DD/MM/YYYY HH:mm");
                        } else {
                            return "-----";
                        }
                    },
                    sortable: true,
                    thStyle: "width: 10rem",
                },
                {
                    label: "Data/Ora Esecuzione:",
                    key: "dataOraEsecuzione",
                    formatter: (value) => {
                        if (value) {
                            return moment(new Date(value)).format("DD/MM/YYYY HH:mm");
                        } else {
                            return "-----";
                        }
                    },
                    sortable: true,
                    thStyle: "width: 10rem",
                },
                {
                    label: "Annotazione",
                    key: "annotazione",
                    sortable: true,
                },
                { class: "sa-table-column-action-double", key: "actions", label: "" },
            ],
        };
    },
    methods: {
        init(jsonDataAccettazione) {
            let me = this;
            me.jsonDataAccettazione = jsonDataAccettazione;
            me.loadDiari();
        },
        getColorAttivita(item) {
            let returnValue = "sa-timeline-diario-event animated fadeInUp delay-3s ";
            switch (item.tipologia) {
                case "NOTA":
                    returnValue += "sa-timeline-diario-event-note";
                    break;
                default:
                    returnValue += "sa-timeline-diario-event-type1";
                    break;
            }
            return returnValue;
        },
        getIconAttivita(item) {
            let returnValue = "fas fa-question";
            switch (item.tipologia) {
                case "NOTA":
                    returnValue += "far fa-comment-alt";
                    break;
                default:
                    returnValue += "fas fa-question";
                    break;
            }
            return returnValue;
        },
        loadDiari() {
            let me = this;

            let link = process.env.VUE_APP_PATH_API + me.pathResourcesDiari;

            axios
                .get(link)
                .then((response) => {
                    me.diariTabs = response.data.data.list;
                    me.diarioSelezionato = me.diariTabs[0].nome;
                    me.idDiario = me.diariTabs[0].id;
                    me.codiceDiario = me.diariTabs[0].codiceDiario;
                    me.loadVociDiario();
                })
                .catch(() => {});
        },
        loadVociDiario() {
            let me = this;

            let link = process.env.VUE_APP_PATH_API + me.pathResourcesVociDiario;
            let filtro = { idAccettazione: me.jsonDataAccettazione.id, idDiario: me.idDiario };
            axios
                .get(link, { params: filtro })
                .then((response) => {
                    me.vociDiario = response.data.data.list;
                })
                .catch(() => {});
        },
        onInputTabs(e) {
            let me = this;
            me.diarioSelezionato = this.diariTabs[e].nome;
            me.idDiario = this.diariTabs[e].id;
            me.codiceDiario = me.diariTabs[0].codiceDiario;
            me.loadVociDiario();
        },
        onClickNuovaNota() {
            let me = this;
            me.$refs.cmpRsaDiariNoteEditComponent.add({ id: null, codiceDiario: me.codiceDiario, dataOraEsecuzione: new Date().getTime(), dataOraProgrammata: new Date().getTime(), idAccettazione: me.jsonDataAccettazione.id, idDiario: me.idDiario, idPaziente: me.jsonDataAccettazione.accettazioneAnagrafica.id, nome: "NOTA", tipologia: "NOTA" });
        },
        onAfterSaveRequestRsaDiariNoteComponent() {
            let me = this;
            me.loadVociDiario();
        },
        onClickNModifica() {},
        onClickNDelete() {},
    },
};
</script>
<style scoped>
.sa-timeline-diario-event-note .sa-timeline-diario-event-icon {
    background-color: #aff1b6;
    color: #24b47e;
}
.sa-timeline-diario-event-note .sa-timeline-diario-event-icon::before {
    background-color: #aff1b6;
}
.sa-timeline-diario-event-note .sa-timeline-diario-event-icon::after {
    background-color: #aff1b6;
}
.sa-timeline-diario-event.sa-timeline-diario-event-note:nth-child(2n + 1) .sa-timeline-diario-event-icon::before {
    background-color: #aff1b6;
}
.sa-timeline-diario-event.sa-timeline-diario-event-note:nth-child(2n + 1) .sa-timeline-diario-event-icon::after {
    background-color: #aff1b6;
}
.sa-timeline-diario-event-note .sa-timeline-diario-event-date {
    background-color: #24b47e;
    color: #fafafa;
}
.sa-timeline-diario-event-note .sa-timeline-diario-event-title {
    color: #24b47e;
}
</style>
