<template>
  <sa-page-layout :showModalLoading="showModalLoading" :pathResource="pathResource" :btnBackVisible="true" :linkedit="linkedit">
    <template slot="toolbar-title">Lista Clienti</template>
    <template slot="table-filter">
      <p></p>
    </template>
    <template slot="table-header">
      <p></p>
    </template>
    <template slot="table-body">
      <div class="b-table-sticky-header sa-scroll">
        <b-table sticky-header ref="table" :filter="filter" stacked="lg" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
          <template v-slot:cell(descrizione)="{ item }">
            <router-link class="sa-edit-link" :to="'/clienti/view/' + item.id">{{ item.descrizione }} </router-link>
          </template>
          <template v-slot:cell(actions)="row">
            <b-button size="sm" variant="btn btn-danger waves-effect waves-light width-sm no-text" style="margin-right: 3px" @click="onDelete(row.item)" v-b-modal.visualizzaAllegato>
              <b-icon icon="trash"></b-icon>
            </b-button>
          </template>
        </b-table>
      </div>
    </template>
    <template slot="table-footer">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>Totale Records: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
  </sa-page-layout>
</template>

<script>
import axios from "axios";
import SaPageLayout from "../../../template/layout/components/SaPageLayout.vue";
export default {
  components: { SaPageLayout },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  data() {
    return {
      showModalLoading: false,
      pathResource: "/clienti",
      linkedit: "/clienti",
      perPage: 100,
      currentPage: 1,
      filter: {},
      items: [],
      fields: [
        {
          label: "Descrizione",
          key: "descrizione",
          sortable: true,
        },
        {
          label: "Nome",
          key: "nome",
          thStyle: "width: 30rem",
          sortable: true,
        },
        {
          label: "Cognome",
          key: "cognome",
          thStyle: "width: 30rem",
          sortable: true,
        },
        // {
        //   label: "Comune",
        //   key: "comuneLabel",
        //   thStyle: "width: 7rem",
        //   sortable: true,
        // },
        // {
        //   label: "Indirizzo",
        //   key: "indirizzo",
        //   sortable: true,
        // },
        // {
        //   label: "",
        //   key: "actions",
        //   sortable: false,
        //   thStyle: "width: 3rem",
        // },
      ],
    };
  },
  mounted: function () {
    let me = this;
    me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource;
      me.isBusy = true;
      axios
        .get(link, { params: me.filtro })
        .then((response) => {
          
          me.items = response.data.data.list;
          //me.$refs["SaPageList"].close();
        })
        .catch(() => {
          me.showmolalloading = false;
        });
    },
    onEdit() {
      console.log("Delete");
    },
    onDelete(item) {
      let me = this;
      this.$bvModal
        .msgBoxConfirm(`Sei sicuro di procedere alla cancellazione del ${item.descrizione}`, {
          title: "Cancella Punto di Prelievo",
          buttonSize: "sm",
          okVariant: "success",
          cancelVariant: "danger",
          headerBorderVariant: "success",
          footerBorderVariant: "danger",
          okTitle: "SI",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            me.deleteItem(item);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteItem(item) {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + item.id;
      axios
        .delete(link)
        .then((Response) => {
          console.log(Response);
          me.showModalLoading = false;
          me.loadData();
        })
        .catch((e) => {
          console.log(e);
          me.showModalLoading = false;
        });
    },
  },
};
</script>

<style></style>
