<template>
    <div style="height: 100%; overflow: auto">
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Denominazione</label>
                    <span class="sa-data">{{ jsonData.denominazione }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">C.O.T. Destinazione</label>
                    <span class="sa-data">{{ jsonData.descrizioneCotDestinazione }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Email</label>
                    <span class="sa-data">{{ jsonData.email }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Telefono</label>
                    <span class="sa-data">{{ jsonData.telefono }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Note</label>
                    <span class="sa-data">{{ jsonData.note }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Lista Sedi" header-tag="header" footer-tag="footer" title="">
            <template #header>
                <b-row>
                    <b-col cols="12" xs="8" sm="8" md="8" lg="8" xl="8">
                        <span class="sa-label-data">LISTA SEDI</span>
                    </b-col>
                    <b-col class="sa-padding-right text-right" cols="12" xs="4" sm="4" md="4" lg="4" xl="4">
                        <b-button class="float-sm-right" variant="outline-success btn-toolbar sa-margin-right" size="sm" @click="onOpenModalNewSede">
                            <b-icon icon="plus" aria-hidden="true"></b-icon>
                            Aggiungi Sede
                        </b-button>
                    </b-col>
                </b-row>
            </template>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <div class="sa-list-component-header">
                        <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                            <b-col lg="3">
                                <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                            </b-col>
                            <b-col lg="9">
                                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="sa-list-component-body">
                        <div class="b-table-sticky-header">
                            <b-table sticky-header ref="table" stacked="lg" striped hover :items="sedi" :fields="fields" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
                                <template #cell(index)="row">
                                    {{ row.index + 1 }}
                                </template>
                                <template v-slot:cell(denominazione)="{ item }">
                                    <router-link class="sa-edit-link" :to="'/cot/puasede/view/' + item.id">{{ item.denominazione }}</router-link>
                                </template>
                                <template v-slot:cell(actions)="{ item }">
                                    <b-button size="sm" v-b-tooltip.hover title="Modifica Sede" variant="outline-success" @click="onEditSede(item)">
                                        <font-awesome-icon icon="edit"></font-awesome-icon>
                                    </b-button>
                                    <b-button size="sm" class="mr-1 ml-1 no-text" variant="outline-danger" @click="onDeleteSede(item)">
                                        <b-icon icon="trash"></b-icon>
                                    </b-button>
                                </template>
                            </b-table>
                        </div>
                    </div>
                    <div class="sa-list-component-footer">
                        <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                            <b-col lg="3">
                                <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rows }}</p>
                            </b-col>
                            <b-col lg="9">
                                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                            </b-col>
                        </b-row>
                    </div>
                </b-col>
            </b-row>
        </b-card>
        <!-- <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"> -->
        <cot-pua-utenti-list-component :idCotPuaSedi="id"></cot-pua-utenti-list-component>
        <!-- </b-col>
        </b-row> -->
        <b-modal size="lg" ref="modalAggiungiSede" id="modalAggiungiSede" title="Aggiungi Nuova" @ok="onAddSede" scrollable>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Denominazione</label>
                    <b-form-input v-model="nuovaSede.denominazione"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Provincia</label>
                    <b-form-select id="inline-form-custom-select-pref" v-model="nuovaSede.provinciaDescrizione" class="mb-2 mr-sm-2 mb-sm-0" :options="provinciaOptions" :value="''" @change="loadComune(nuovaSede.provinciaDescrizione)"> </b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Comune</label>
                    <b-form-select id="inline-form-custom-select-pref" v-model="nuovaSede.comuneCodiceIstat" class="mb-2 mr-sm-2 mb-sm-0" :options="comuneOptions" :value="''"> </b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Email</label>
                    <b-form-input v-model="nuovaSede.email"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Telefono</label>
                    <b-form-input v-model="nuovaSede.telefono"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Note</label>
                    <b-form-textarea id="textarea-no-resize" v-model="nuovaSede.note" rows="5" no-resize></b-form-textarea>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
import axios from "axios";
import CotPuaUtentiListComponent from "./CotPuaUtentiListComponent.vue";
export default {
    components: { CotPuaUtentiListComponent },
    data() {
        return {
            pathResource: "/cotpua",
            pathResourceSedi: "/cotpuasedi",
            pathResourceProvince: "/province",
            pathResourceComune: "/comuni/provincia",
            listKey: 0,
            rows: 0,
            perPage: 50,
            currentPage: 1,
            id: null,
            jsonData: { idCotDestinazione: "", descrizioneCotDestinazione: "", denominazione: "", note: "", email: "", telefono: "" },
            nuovaSede: { id: null, idCotPua: "", denominazione: "", comuneCodiceIstat: "", note: "", provinciaDescrizione: "" },
            sedi: [],
            provinciaOptions: [{ value: "", text: "-Seleziona Valore-" }],
            comuneOptions: [{ value: "", text: "-Seleziona Valore-" }],
            filtro: {},
            fields: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Denominazione",
                    key: "denominazione",
                    sortable: true,
                    //thStyle: "width: 7rem",
                },
                {
                    label: "Telefono",
                    key: "telefono",
                    sortable: true,
                    thStyle: "width: 7rem",
                },
                {
                    label: "Email",
                    key: "email",
                    thStyle: "width: rem",
                    sortable: true,
                },
                {
                    label: "",
                    tdClass: "text-center",
                    thStyle: "width: 10rem",
                    key: "actions",
                },
            ],
        };
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    mounted: function () {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            axios.get(link + me.id).then((response) => {
                me.jsonData = response.data.data;
                me.loadSedi();
                this.$emit("afterLoadData");
            });
        },
        loadCotDestinazione() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceCotDestinazione;
            axios.get(link).then((response) => {
                me.cotDestinazioneOptions = response.data.data.list;
                me.cotDestinazioneOptions.unshift({ id: "", destinazione: "-Seleziona Valore-" });
            });
        },
        onSelectCotDestinazione() {
            let me = this;
            me.cotDestinazioneOptions.forEach((element) => {
                if (element.id === me.jsonData.idCotDestinazione) {
                    me.jsonData.descrizioneCotDestinazione = element.destinazione;
                }
            });
        },
        loadSedi() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            if (sessionStorage["filtro"]) {
                me.filtro = JSON.parse(sessionStorage["filtro"]);
            }
            let link = process.env.VUE_APP_PATH_API + me.pathResourceSedi;
            me.filtro.page = me.currentPage;
            me.filtro.forPage = me.perPage;
            me.filtro.idCotPua = me.jsonData.id;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.sedi = [];
                    me.rows = response.data.data.recordsNumber;
                    me.sedi = response.data.data.list;
                    me.listKey++;
                    this.$emit("afterLoadData");
                })
                .catch(() => {
                    this.$emit("afterLoadData");
                });
        },
        onDeleteSede(item) {
            let me = this;
            this.$bvModal
                .msgBoxConfirm("Sei sicuro di procedere alla cancellazione della sede?", {
                    title: "Conferma Cancellazione Associazione",
                    buttonSize: "sm",
                    okVariant: "secondary",
                    cancelVariant: "danger",
                    okTitle: "SI",
                    cancelTitle: "NO",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                })
                .then((value) => {
                    if (value) {
                        me.deleteSede(item);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        deleteSede(item) {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceSedi + "/";
            axios
                .delete(link + item.id)
                .then((response) => {
                    console.log(response);
                    me.showModalLoading = false;
                    me.loadData();
                })
                .catch((error) => {
                    console.log(error);
                    me.showModalLoading = false;
                });
        },
        onOpenModalNewSede() {
            let me = this;
            me.nuovaSede.idCotPua = me.jsonData.id;
            me.loadProvince();
            me.$refs["modalAggiungiSede"].show();
        },
        onEditSede(item) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceSedi + "/" + item.id;
            axios
                .get(link)
                .then((response) => {
                    me.nuovaSede = response.data.data;
                    me.loadProvince();
                    me.loadComune(me.nuovaSede.provinciaDescrizione);
                    me.$refs["modalAggiungiSede"].show();
                    this.$emit("afterLoadData");
                })
                .catch(() => {
                    this.$emit("afterLoadData");
                });
            // me.nuovaSede = item;
            // me.loadProvince();
            // me.$refs["modalAggiungiSede"].show();
        },
        onAddSede() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceSedi;
            if (me.nuovaSede.id === null) {
                axios
                    .post(link, JSON.stringify(me.nuovaSede))
                    .then(() => {
                        me.$refs["modalAggiungiSede"].hide();
                        this.$bvToast.toast("Sede aggiunta con successo", {
                            title: this.titleModal,
                            variant: "success",
                            autoHideDelay: 1000,
                        });
                        me.loadData();
                    })
                    .catch((error) => {
                        me.showModalLoading = false;
                        this.$bvModal
                            .msgBoxOk(error.response.data.messaggio, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then((value) => {
                                this.boxTwo = value;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
            } else {
                link = link + "/" + me.nuovaSede.id;
                axios
                    .put(link, JSON.stringify(me.nuovaSede))
                    .then(() => {
                        me.$refs["modalAggiungiSede"].hide();
                        this.$bvToast.toast("Sede aggiunta con successo", {
                            title: this.titleModal,
                            variant: "success",
                            autoHideDelay: 1000,
                        });
                        me.loadData();
                    })
                    .catch((error) => {
                        me.showModalLoading = false;
                        this.$bvModal
                            .msgBoxOk(error.response.data.messaggio, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then((value) => {
                                this.boxTwo = value;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
            }
        },
        loadProvince() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceProvince;
            me.provinciaOptions = [];
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    me.provinciaOptions.push({ value: element.sigla, text: element.provincia });
                });
                me.provinciaOptions.unshift({ value: "", text: "-Seleziona Valore-" });
            });
        },
        loadComune(sigla) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceComune + "/" + sigla;
            me.comuneOptions = [];
            axios.get(link).then((response) => {
                me.comuneOptions = [];
                response.data.data.forEach((element) => {
                    me.comuneOptions.push({ value: element.codiceIstat, text: element.comune });
                });
                me.comuneOptions.unshift({ value: "", text: "-Seleziona Valore-" });
            });
        },
    },
};
</script>

<style></style>
