<template>
    <sa-page-layout :btnBackVisible="true" :btnEditVisible="false" :linkback="linkback" :linkedit="linkedit" :pathResource="pathResource" :showModalLoading="showModalLoading">
        <template slot="table-body">
            <paperclean-configurazione-dizionari-view-component ref="PapercleanConfigurazioneDizionariViewComponent" @afterLoadData="onAfterLoadData" @beforeLoadData="onBeforeLoadData"></paperclean-configurazione-dizionari-view-component>
        </template>
    </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../template/layout/components/SaPageLayout.vue";
import PapercleanConfigurazioneDizionariViewComponent from "../components/PapercleanConfigurazioneDizionariViewComponent.vue";
export default {
    name: "NumeratoreViewPage",
    components: { PapercleanConfigurazioneDizionariViewComponent, SaPageLayout },

    data() {
        return {
            id: null,
            btnNewVisible: true,
            pathResource: "/dizionariosezioni",
            linkedit: null,
            linkback: "/papercleanconfigurazionrdizionari",
            showModalLoading: false,
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.linkedit = "/papercleanconfigurazionrdizionari/edit/" + me.id;
        me.loadData();
    },
    methods: {
        loadData() {
            this.$refs.PapercleanConfigurazioneDizionariViewComponent.loadData();
        },
        onAfterLoadData() {
            this.showModalLoading = false;
        },
        onBeforeLoadData() {
            this.showModalLoading = true;
        },
    },
};
</script>
