<template>
  <sa-page-layout :btnSaveVisible="btnSaveVisible" :btnAnnullaVisible="true" :linkback="linkback" :pathResource="pathResource" :data="data" :showModalLoading="showModalLoading">
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <div class="sa-form-section">
          <h5 class="sa-text-left">Nuovo Articolo</h5>
          <b-row>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <span class="sa-form-label">Codice</span>
              <b-input-group class="mb-3">
                <!-- <b-form-input maxlength="16" class="text-uppercase" v-model="data.codiceFiscale" @keypress="onKeyPress"></b-form-input> -->
                <b-form-input v-model="data.codice" :disabled="isEdit"></b-form-input>
                <b-input-group-append>
                  <a :href="onGetLink(data.codice)" target="_blank" rel="noreferrer"
                    ><b-button><b-icon icon="search"></b-icon></b-button
                  ></a>
                </b-input-group-append>
                <!-- <b-button size="sm" variant="" @click="onClick"> <b-icon icon="search"></b-icon> </b-button> -->
              </b-input-group>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <span class="sa-form-label">Categoria</span>
              <b-form-select id="inline-form-custom-select-pref" v-model="data.categoria" class="mb-2 mr-sm-2 mb-sm-0" :options="sessoOption" :value="null" :disabled="isEdit">
                <template #first>
                  <b-form-select-option :value="null" disabled>{{ selectOptionSesso }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
              <span class="sa-form-label">Descrizione</span>
              <b-form-input v-model="data.descrizione"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <span class="sa-form-label">Prezzo</span>
              <b-form-input v-model="data.prezzo" :disabled="isEdit"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <span class="sa-form-label">Quantita</span>
              <b-input-group class="mb-3">
                <!-- <b-form-input maxlength="16" class="text-uppercase" v-model="data.codiceFiscale" @keypress="onKeyPress"></b-form-input> -->
                <b-form-input v-model="data.quantita" :disabled="isEdit"></b-form-input>
                <!-- <b-button size="sm" variant="" @click="onClick"> <b-icon icon="search"></b-icon> </b-button> -->
              </b-input-group>
            </b-col>
            <!-- <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                            <span class="sa-form-label">{{ this.$i18n.t("patients.lblBirthDate") }}</span
                            ><span style="color: red;">*</span>
                            <div id="date-picker-1" class="red-border">
                                <date-picker format="DD/MM/YYYY" value-type="timestamp" v-model="data.dataNascita" type="date" required @change="check(data.dataNascita, 'date-picker-1')"></date-picker>
                            </div>
                        </b-col>
                        <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                            <span class="sa-form-label">{{ this.$i18n.t("patients.lblBloodGroup") }}</span>
                            <b-form-select id="inline-form-custom-select-pref" v-model="data.gruppoSanguigno" class="mb-2 mr-sm-2 mb-sm-0" :value="null" :options="gruppoSanguignoOption" required>
                                <template #first>
                                    <b-form-select-option :value="null" disabled>{{ selectOptionGruppoSanguigno }}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                            <span class="sa-form-label">{{ this.$i18n.t("patient.lblRegione") }}</span>
                            <span style="color: red;">*</span>
                            <b-form-select id="inline-form-custom-select-pref" v-model="data.regioneNascita" :state="data.regioneNascita == null || data.regioneNascita === '' ? false : true" class="mb-2 mr-sm-2 mb-sm-0" :value="null" value-field="codiceIstat" text-field="regione" :options="regioniOptions" required>
                                <template #first>
                                    <b-form-select-option :value="null" disabled>{{ selectOptionRegione }}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                            <span class="sa-form-label">{{ this.$i18n.t("patients.lblProvince") }}</span
                            ><span style="color: red;">*</span>
                            <b-form-select id="inline-form-custom-select-pref" v-model="data.provinciaNascita" class="mb-2 mr-sm-2 mb-sm-0" :options="provinciaNascitaOption" :value="null" @change="loadComuneNascita(data.provinciaNascita)" required :state="data.provinciaNascita !== null ? true : false">
                                <b-form-select-option :value="null" disabled>{{ selectOptionProvincia }}</b-form-select-option>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                            <span class="sa-form-label">{{ this.$i18n.t("patients.lblCity") }}</span
                            ><span style="color: red;">*</span>
                            <b-form-select id="inline-form-custom-select-pref" v-model="data.comuneNascitaCodiceIstat" class="mb-2 mr-sm-2 mb-sm-0" :options="comuneNascitaOption" :value="null" required :state="data.comuneNascitaCodiceIstat !== null ? true : false">
                                <template #first>
                                    <b-form-select-option :value="null" disabled>{{ selectOptionComune }}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col> -->
          </b-row>
        </div>
      </div>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
// import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
  components: { SaPageLayout },
  data() {
    return {
      isEdit: false,
      btnSaveVisible: true,
      stateIdentificativo: true,
      selectOptionGruppoSanguigno: this.$i18n.t("patients.selectGruopBlood"),
      selectOptionRegione: this.$i18n.t("patients.selectRegions"),
      selectOptionSesso: "Seleziona Categoria",
      selectOptionComune: this.$i18n.t("patients.selectCounties"),
      selectOptionProvincia: this.$i18n.t("patients.selectCity"),
      pathResource: "/magazzinoarticoli",
      linkback: null,
      linkProvince: "/province",
      pathResourceRegioni: "/regioni",
      linkComune: "/comuni/provincia",
      linkComuneByIstat: "/comuni/istat",
      linkGruppiSangue: "/pazientigruppisanguigno",
      showModalLoading: false,
      id: "-1",
      profili: [],
      sessoOption: [],
      regioniOptions: [],
      isDomicilioUgualeResidenza: false,
      provinciaNascitaOption: [],
      provinciaDomicilioOption: [],
      provinciaResidenzaOption: [],
      comuneNascitaOption: [],
      comuneDomicilioOption: [],
      comuneResidenzaOption: [],
      language: null,
      gruppoSanguignoOption: [],
      data: {},
    };
  },
  computed: {
    dataScadenzaUtenza: {
      get: function () {
        return new Date(this.data.scadenzaUtenza);
      },
      set: function (value) {
        this.data.scadenzaUtenza = moment(String(value)).valueOf();
      },
    },
  },
  mounted: function () {
    let me = this;
    me.id = this.$route.params.id;
    me.language = this.$route.query.language;
    if (me.id === "-1") {
      me.isEdit = false;
      me.linkback = "/magazzino/";
    } else {
      me.isEdit = false;
      me.linkback = "/magazzino/view/" + me.id;
    }

    me.loadDefaultData();
  },
  watch: {
    isDomicilioUgualeResidenza(value) {
      let me = this;
      if (value) {
        me.data.indirizzoDomicilio = me.data.indirizzoResidenza;
        me.data.comuneDomicilioCodiceIstat = me.data.comuneResidenzaCodiceIstat;
        me.data.provinciaDomicilio = me.data.provinciaResidenza;
        me.data.capDomicilio = me.data.capResidenza;
        console.log(me.data);
      } else if (!value) {
        (me.data.indirizzoDomicilio = null), (me.data.comuneDomicilioCodiceIstat = null), (me.data.provinciaDomicilio = null), (me.data.capDomicilio = null);
      }
      console.log(me.data);
    },
  },

  methods: {
    check: function (input, id) {
      console.log(typeof input);
      if (typeof input == "number") {
        document.getElementById(id).classList.remove("red-border");
        document.getElementById(id).classList.add("green-border");
      } else {
        document.getElementById(id).classList.remove("green-border");
        document.getElementById(id).classList.add("red-border");
      }
    },
    loadDefaultData() {
      let me = this;
      // me.loadProvinceNascita();
      // me.loadProvinceDomicilio();
      // me.loadProvinceResidenza();
      // me.loadRegioni();
      // me.loadGruppiSangue();
      me.loadSesso();
      me.loadData();
    },
    loadSesso() {
      let link = process.env.VUE_APP_PATH_API + "/magazzinocategoriaarticolo?page=1&forPage=100000";
      this.sessoOption = [];
      axios.get(link).then((res) => {
        let sessi = res.data.data.list;
        sessi.forEach((element) => {
          let obj = element.categoria;
          this.sessoOption.push(obj);
        });
      });
    },
    loadData() {
      let me = this;

      //console.log(me.id);
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        me.showModalLoading = true;
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
        axios.get(link + me.id).then((response) => {
          me.data = response.data.data;
          // me.loadComuneNascita(me.data.provinciaNascita);
          // me.loadComuneResidenza(me.data.provinciaResidenza);
          // me.loadComuneDomicilio(me.data.provinciaDomicilio);
          me.showModalLoading = false;
        });
      }
    },
    onGetLink(value) {
      return "https://www.google.it/search?q=" + value;
    },
    loadRegioni() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceRegioni + "?language=" + me.language;
      axios.get(link).then((response) => {
        me.regioniOptions = response.data.data;
      });
    },
    loadProvinceNascita() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkProvince;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.provinciaNascitaOption.push({ value: element.sigla, text: element.provincia });
        });
      });
    },
    loadGruppiSangue() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkGruppiSangue;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.gruppoSanguignoOption.push({ value: element.descrizione, text: this.$i18n.t(element.descrizione) });
        });
      });
    },
    loadProvinceDomicilio() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkProvince;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.provinciaDomicilioOption.push({ value: element.sigla, text: element.provincia });
        });
      });
    },
    loadProvinceResidenza() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkProvince;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.provinciaResidenzaOption.push({ value: element.sigla, text: element.provincia });
        });
      });
    },
    loadComuneNascita(sigla) {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkComune + "/" + sigla;
      axios.get(link).then((response) => {
        me.comuneNascitaOption = [];
        response.data.data.forEach((element) => {
          me.comuneNascitaOption.push({ value: element.codiceIstat, text: element.comune });
        });
      });
    },
    loadComuneDomicilio(sigla) {
      // let me = this;
      // let link = process.env.VUE_APP_PATH_API + me.linkComune + "/" + sigla;
      // axios.get(link).then((response) => {
      //     me.comuneDomicilioOption = response.data.data;
      //     me.comuneDomicilioOption.unshift({ codiceIstat: null, comune: "- Seleziona Comune -" });

      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkComune + "/" + sigla;
      axios.get(link).then((response) => {
        me.comuneDomicilioOption = response.data.data;
      });
    },
    setCapDomicilio(item) {
      let me = this;
      me.comuneDomicilioOption.forEach((comuneDomicilio) => {
        if (comuneDomicilio.codiceIstat === item) {
          me.data.capDomicilio = comuneDomicilio.cap;
        }
      });
    },
    loadComuneResidenza(sigla) {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkComune + "/" + sigla;
      axios.get(link).then((response) => {
        me.comuneResidenzaOption = response.data.data;
      });
    },
    setCapResidenza(item) {
      let me = this;
      me.comuneResidenzaOption.forEach((comuneResidenza) => {
        if (comuneResidenza.codiceIstat === item) {
          me.data.capResidenza = comuneResidenza.cap;
        }
      });
    },
    validazioneIdentificativo(value) {
      let me = this;
      if (value.length != 11) {
        me.stateIdentificativo = false;
        return;
      }
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + "/anagrafica/validazioneidentificativo?identificativo=" + value;
      axios
        .get(link)
        .then((response) => {
          console.log(response);
          me.btnSaveVisible = true;
          me.stateIdentificativo = true;
          me.showModalLoading = false;
        })
        .catch(() => {
          me.btnSaveVisible = false;
          me.stateIdentificativo = false;
          me.showModalLoading = false;
        });
    },
    // onKeyPress(e) {
    //     let me = this;
    //     if (e.keyCode === 13) {
    //         me.onClick();
    //     }
    // },

    // onClick() {
    //     let me = this;
    //     me.showModalLoading = true;
    //     if (me.data.codiceFiscale.trim().length === 0) {
    //         me.showModalLoading = false;
    //     } else {
    //         me.data.codiceFiscale = me.data.codiceFiscale.toUpperCase();
    //         let link = process.env.VUE_APP_PATH_API + "/utility";
    //         axios
    //             .get(link, { params: { codiceFiscale: me.data.codiceFiscale } })
    //             .then((response) => {
    //                 me.data.nome = response.data.data.nome;
    //                 me.data.cognome = response.data.data.cognome;
    //                 me.data.dataNascita = moment(response.data.data.dataNascita, "DD/MM/YYYY")
    //                     .toDate()
    //                     .getTime();
    //                 me.data.provinciaNascita = response.data.data.provinciaNascita;
    //                 me.data.comuneNascitaCodiceIstat = response.data.data.codiceIstatComuneNascita;
    //                 me.data.sesso = response.data.data.sesso;
    //                 me.data.indirizzoResidenza = response.data.data.indirizzoResidenza;
    //                 me.data.provinciaResidenza = response.data.data.provinciaResidenza;
    //                 me.data.comuneResidenzaCodiceIstat = response.data.data.codiceIstatComuneResidenza;
    //                 me.setCapResidenza(response.data.data.codiceIstatComuneResidenza);
    //                 me.data.indirizzoDomicilio = response.data.data.indirizzoResidenza;
    //                 me.data.provinciaDomicilio = response.data.data.provinciaResidenza;
    //                 me.data.comuneDomicilioCodiceIstat = response.data.data.codiceIstatComuneResidenza;
    //                 me.setCapDomicilio(response.data.data.codiceIstatComuneResidenza);
    //                 me.data.codiceFiscaleMedicoCurante = response.data.data.codiceFiscaleMedico;
    //                 me.data.medicoCurante = response.data.data.medico;
    //                 me.showModalLoading = false;
    //             })
    //             .catch(() => {
    //                 me.showModalLoading = false;
    //             });
    //     }
    // },
  },
};
</script>
<style scoped>
.red-border {
  border: 1px solid #dc3545;
  border-radius: 5px;
}
.green-border {
  border: 1px solid #28a745;
  border-radius: 5px;
}
.domicilio-residenza-checkbox {
  margin-top: 0.2rem;
}
</style>
