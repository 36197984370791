<template>
    <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <b-card class="sa-card sa-card-border" header="Valutazione ADL" header-tag="header" footer-tag="footer" title="">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Abbigliamento e Aspetto igienico</label>
                        <b-form-select v-model="jsonDataValutazioneAdl.abbigliamentoAspettoIgienico" :options="abbigliamentoAspettoIgienicoOptions" :disabled="isReadOnly">
                            <template #first>
                                <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-col>

                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Capacità cura autonoma</label>
                        <b-form-select v-model="jsonDataValutazioneAdl.capacitaCuraAutonoma" :options="capacitaCuraAutonomaOptions" :disabled="isReadOnly">
                            <template #first>
                                <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <label class="sa-label-data">Supporto per attività quotidiane: </label>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-row>
                                <b-col cols="auto" v-for="adl in adlOptions" :key="'adl-' + adl.id" class="sa-switch-select-element">
                                    <b-form-checkbox switch :disabled="isReadOnly" v-model="selectedAdl" :value="adl.voce">
                                        <span style="font-weight: normal">{{ adl.voce }}</span>
                                    </b-form-checkbox>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-col>

                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <label class="sa-label-data">Note</label>
                        <b-form-textarea v-model="jsonDataValutazioneAdl.note" :readonly="isReadOnly" max-rows="10" rows="7"> </b-form-textarea>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
        <!-- 
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <b-card class="sa-card sa-card-border" header="Supporto per attività quotidiane: " header-tag="header" footer-tag="footer" title="">
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-row>
                        <b-col cols="auto" v-for="adl in adlOptions" :key="'adl-' + adl.id" class="sa-switch-select-element">
                            <b-form-checkbox switch :disabled="isReadOnly" v-model="selectedAdl" :value="adl.voce">
                                <span style="font-weight: normal">{{ adl.voce }}</span>
                            </b-form-checkbox>
                        </b-col>
                    </b-row>
                </b-col>
            </b-card>
        </b-col> -->
    </b-row>
</template>
<script>
import UtilityMixin from "../../../utility/UtilityMixin";

export default {
    props: {
        isReadOnly: {
            type: Boolean,
            default: false,
        },
        idAccettazione: {
            type: String,
        },
    },
    mounted() {
        let me = this;
        me.loadDizionarioData();
    },

    mixins: [UtilityMixin],
    data() {
        return {
            pathResourceSchedaInfermieristicaValutazioneAdl: "/rsaschedainfermieristicavalutazioneadl",
            pathResourceDizionario: "/rsadizionario",
            adlOptions: [],
            selectedAdl: [],
            jsonDataValutazioneAdl: {
                id: null,
                idAccettazione: null,
                abbigliamentoAspettoIgienico: null,
                capacitaCuraAutonoma: null,
                note: null,
                listaSchedaAdlDettaglio: [],
            },

            abbigliamentoAspettoIgienicoOptions: [
                { text: "CURATO", value: "CURATO" },
                { text: "TRASANDATO", value: "TRASANDATO" },
            ],
            capacitaCuraAutonomaOptions: [
                { text: "AUTONOMO", value: "AUTONOMO" },
                { text: "SUPERVISIONE", value: "SUPERVISIONE" },
                { text: "DIPENDENTE", value: "DIPENDENTE" },
            ],
        };
    },
    methods: {
        loadDizionarioData() {
            let me = this;
            UtilityMixin.methods.loadDataResources(me.pathResourceDizionario, null, me.loadDizionarioDataSuccess, me.loadDizionarioDataError);
        },

        loadDizionarioDataSuccess(response) {
            let me = this;
            if (response.data?.data?.list) {
                me.adlOptions = response.data.data.list.filter((item) => item.tipo === "ADL");
            }
        },
        loadDizionarioDataError(error) {
            console.error("Errore nel caricamento adl", error);
        },

        loadValutazioneAdlData() {
            let me = this;
            // console.log("ID accettazione nel loadValutazioneAdlData: " + me.idAccettazione);
            me.selectedAdl = [];
            me.loadDizionarioData();
            UtilityMixin.methods.loadDataResources(me.pathResourceSchedaInfermieristicaValutazioneAdl + "/accettazione/" + me.idAccettazione, {}, me.resultResponseSchedaInfermieristicaValutazioneAdlSuccess, me.resultResponseSchedaInfermieristicaValutazioneAdlError);
        },
        resultResponseSchedaInfermieristicaValutazioneAdlSuccess(response) {
            let me = this;
            me.jsonDataValutazioneAdl = response.data.data;

            me.jsonDataValutazioneAdl.listaSchedaAdlDettaglio.forEach((item) => {
                if (item.tipo === "ADL") {
                    me.selectedAdl.push(item.voce);
                }
            });

            // console.log("ID del record VALUTAZIONE ADL:", me.jsonDataValutazioneAdl.id);
        },
        resultResponseSchedaInfermieristicaValutazioneAdlError(error) {
            let me = this;
            if (error.response && error.response.status === 404) {
                //console.log("NESSUN record trovato del VALUTAZIONE ADL per questo id accettazione");
                me.resetForm();
            } else {
                console.error("Errore nel caricamento dati della sezione VALUTAZIONE ADL:", error);
            }
        },

        resetForm() {
            let me = this;
            me.jsonDataValutazioneAdl = {
                id: null,
                idAccettazione: me.idAccettazione,
                abbigliamentoAspettoIgienico: null,
                capacitaCuraAutonoma: null,
                note: null,
                listaSchedaAdlDettaglio: [],
            };

            me.selectedAdl = [];
        },

        onSalvaValutazioneAdl() {
            let me = this;
            me.jsonDataValutazioneAdl.idAccettazione = me.idAccettazione;
            console.log("Salvataggio VALUTAZIONE ADL con dati:", JSON.stringify(me.jsonDataValutazioneAdl));
            if (!me.jsonDataValutazioneAdl.idAccettazione) {
                // console.log("idAccettazione: ", me.jsonDataValutazioneAdl.idAccettazione);
                me.$bvToast.toast("Errore: ID Accettazione mancante", {
                    title: "Errore",
                    variant: "danger",
                    solid: true,
                });
                return;
            }

            let listaSchedaAdlDettaglio = [];
            me.selectedAdl.forEach((voce) => {
                listaSchedaAdlDettaglio.push({
                    id: null,
                    idSchedaInfermieristicaValutazioneAdl: me.jsonDataValutazioneAdl.id,
                    tipo: "ADL",
                    voce: voce,
                });
            });

            me.jsonDataValutazioneAdl.listaSchedaAdlDettaglio = listaSchedaAdlDettaglio;

            UtilityMixin.methods.saveResource(me.pathResourceSchedaInfermieristicaValutazioneAdl, me.jsonDataValutazioneAdl, me.saveValutazioneAdlSuccessResponse, me.saveValutazioneAdlErrorResponse);
        },
        saveValutazioneAdlSuccessResponse(response) {
            console.log("VALUTAZIONE ADL salvato con successo:", response);
        },
        saveValutazioneAdlErrorResponse(error) {
            console.error("Errore nel salvataggio VALUTAZIONE ADL:", error);
        },
    },
};
</script>
