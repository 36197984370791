<template>
	<login-spid v-if="authenticationType === 'spid'"></login-spid>
	<login v-else></login>
</template>

<script>
import Vue from "vue";
import Login from "../components/Login.vue";
import LoginSpid from "../components/LoginSpid.vue";
export default {
	components: { LoginSpid, Login },
	data() {
		return {
			authenticationType: "Spid",
		};
	},
	created() {
		// console.log(Vue.prototype.appConfig);
		this.authenticationType = Vue.prototype.appConfig.authenticationType;
	},
};
</script>

<style></style>
