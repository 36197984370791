<template>
	<sa-page-layout :btnSaveVisible="true" :btnAnnullaVisible="true" :linkback="linkback" :data="jsonData" :showModalLoading="showModalLoading" :pathResource="pathResource" class="sa-no-space">
		<template slot="table-body">
			<data-analysis-dashboard-edit-component @updateDashBoard="onUpdateDashBoard"></data-analysis-dashboard-edit-component>
		</template>
	</sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import DataAnalysisDashboardEditComponent from "../components/DataAnalysisDashboardEditComponent.vue";
export default {
	components: { SaPageLayout, DataAnalysisDashboardEditComponent },
	data() {
		return {
			showModalLoading: false,
			pathResource: "/dataanalysisdashboard",
			linkback: "/dataanalysisdashboards",
			jsonData: {},
		};
	},
	methods: {
		onUpdateDashBoard(jsonData) {
			let me = this;
			me.jsonData = jsonData;
		},
	},
};
</script>

<style></style>
