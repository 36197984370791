<template>
    <sa-page-layout :showModalLoading="showModalLoading" :pathResource="pathResource" :btnBackVisible="true" :btnSaveVisible="true" :linkback="linkback" :data="jsonData">
        <template slot="toolbar-title">Nuova Ente</template>
        <template slot="table-body">
            <div class="sa-div-scroll sa-scroll">
                <ente-edit-component :jsonData="jsonData"></ente-edit-component>
                <br />
                <hr />
                <h5>Dati Utente</h5>
                <hr />
                <br />
                <b-row>
                    <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                        <label for="user-username">Nome Utente</label>
                        <b-input-group class="mb-2">
                            <b-input-group-prepend is-text>
                                <b-icon icon="person-fill"></b-icon>
                            </b-input-group-prepend>
                            <b-form-input id="user-username" v-model="jsonData.username" type="text" placeholder="Nome Utente"></b-form-input>
                        </b-input-group>
                    </b-col>
                    <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                        <label for="user-email">Email</label>
                        <b-input-group class="mb-2">
                            <b-input-group-prepend is-text>
                                <b-icon icon="envelope"></b-icon>
                            </b-input-group-prepend>
                            <b-form-input id="user-email" v-model="jsonData.email" type="email" placeholder="io@esempio.com"></b-form-input>
                        </b-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                        <label for="user-password">Password</label>
                        <b-input-group class="mb-2">
                            <b-input-group-prepend is-text>
                                <b-icon icon="lock-fill"></b-icon>
                            </b-input-group-prepend>
                            <b-form-input id="user-password" v-model="jsonData.password" type="password" placeholder="Password"></b-form-input>
                        </b-input-group>
                    </b-col>
                    <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                        <label for="user-confermapassword">Conferma Password</label>
                        <b-input-group class="mb-2">
                            <b-input-group-prepend is-text>
                                <b-icon icon="lock-fill"></b-icon>
                            </b-input-group-prepend>
                            <b-form-input placeholder="Conferma password" id="user-confermapassword" v-model="jsonData.verificaPassword" type="password"></b-form-input>
                        </b-input-group>
                    </b-col>
                </b-row>
            </div>
        </template>
    </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../template/layout/components/SaPageLayout.vue";
import UtilityMixins from "../../utility/UtilityMixin";
import EnteEditComponent from "./EnteEditComponent.vue";
import axios from "axios";
export default {
    mixins: [UtilityMixins],
    components: { SaPageLayout, EnteEditComponent },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    data() {
        return {
            linkback: "/enti",
            pathResource: "/enti",
            showModalLoading: false,
            regioniOptions: [],
            jsonData: {},
        };
    },
    methods: {
        loadData() {
            let me = this;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                me.showModalLoading = true;
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                axios
                    .get(link + me.id)
                    .then((response) => {
                        me.jsonData = response.data.data;
                        me.showModalLoading = false;
                    })
                    .catch(() => {
                        me.showModalLoading = false;
                    });
            }
        },
    },
};
</script>

<style></style>
