<template>
    <div class="sa-tab-scroll sa-form-container">
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Sezione</label>
                    <span>{{ jsonData.sezione }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Lista Tipi" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-table sticky-header ref="table" stacked="lg" striped hover :items="jsonData.listaTipi" :fields="fields" :current-page="1" :per-page="1000000000000" sort-icon-left head-variant="light">
                        <template #cell(index)="row">
                            {{ row.index + 1 }}
                        </template>
                    </b-table>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import axios from "axios";
export default {
    components: {},
    data() {
        return {
            pathResource: "/dizionariosezioni",
            linkedit: "",
            id: "",
            showModalLoading: false,
            jsonData: {},
            fieldset: {},
            fields: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Codice",
                    key: "codiceTipo",
                    thStyle: "width: 15rem",
                    sortable: true,
                },
                {
                    label: "Descrizione",
                    key: "descrizioneTipo",
                    thStyle: "width: 15rem",
                    sortable: true,
                },
            ],
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        // me.linkedit = "/numeratori/edit/" + me.id;
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            me.showModalLoading = true;
            axios
                .get(link + me.id)
                .then((response) => {
                    me.jsonData = response.data.data;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
    },
};
</script>

<style scoped>
.sa-form-container {
    padding: 1rem;
    background-color: #f8f9fa;
    min-height: 100%;
}
</style>
