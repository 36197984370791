<template>
    <div style="height: 100%; overflow: auto">
        <b-card class="sa-card" header="Dettagli Piano" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="3" xl="4">
                    <label class="sa-label-data">Struttura</label>
                    <b-form-select v-model="jsonData.codiceStruttura" class="mb-2 mr-sm-2 mb-sm-0" :options="strutturaOptions" :value="null" value-field="codiceStruttura" text-field="denominazione" @input="onInputStruttura"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="3" xl="4">
                    <label class="sa-label-data">Presidio</label>
                    <b-form-select v-model="jsonData.idPresidio" class="mb-2 mr-sm-2 mb-sm-0" :options="presidiOptions" :value="null" value-field="id" text-field="denominazione" @input="onInputPresidi"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="3" xl="4">
                    <label class="sa-label-data">Nome</label>
                    <b-form-input v-model="jsonData.nome" placeholder="Nome del Piano"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Note</label>
                    <b-form-textarea v-model="jsonData.note" rows="5"></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import axios from "axios";
export default {
    data() {
        return {
            strutturaOptions: [],
            presidiOptions: [],
            pathResource: "/rsapostilettopiani",
            pathResourceStrutture: "/strutture",
            pathResourcePresidi: "/strutturepresidi",
            jsonData: { codiceStruttura: null, idPresidio: null, nome: null, note: null },
        };
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    mounted: function () {
        let me = this;
        me.id = this.$route.params.id;
        me.loadStrutture();
    },
    methods: {
        loadStrutture() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceStrutture;
            axios.get(link).then((response) => {
                me.strutturaOptions = response.data.data;
                me.strutturaOptions.unshift({ codiceStruttura: null, denominazione: this.$i18n.t("clinic.lblSelectStructure") });
                me.loadData();
            });
        },
        loadPresidi() {
            let me = this;
            let idStruttura = me.getIdStrutturaByCodiceStruttura(me.jsonData.codiceStruttura);
            if (me.jsonData.codiceStruttura) {
                let link = process.env.VUE_APP_PATH_API + me.pathResourcePresidi + "?idStruttura=" + idStruttura;
                axios.get(link).then((response) => {
                    me.presidiOptions = response.data.data.list;
                    me.presidiOptions.unshift({ id: null, denominazione: "- Seleziona il Presidio -" });
                });
            }
        },
        loadData() {
            let me = this;
            if (me.id === "-1") {
                this.$emit("afterLoadData");
            } else {
                // me.showModalLoading = true;
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                axios.get(link + me.id).then((response) => {
                    me.jsonData = response.data.data;

                    this.$emit("afterLoadData");
                });
            }
        },
        getIdStrutturaByCodiceStruttura(codiceStruttura) {
            let me = this;
            let idStruttura = null;
            me.strutturaOptions.forEach((element) => {
                if (element.codiceStruttura === codiceStruttura) {
                    idStruttura = element.id;
                }
            });
            return idStruttura;
        },
        onInputStruttura(value) {
            let me = this;
            me.loadPresidi(value);
        },
        onInputPresidi() {},
        getJsonData() {
            return this.jsonData;
        },
    },
};
</script>

<style></style>
