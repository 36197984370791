<template>
    <div>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card sa-card-border" header="Valutazione Sensoriale" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Vista</label>
                            <b-form-select v-model="jsonDataValutazioneSensoriale.vista" :options="vistaOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Udito</label>
                            <b-form-select v-model="jsonDataValutazioneSensoriale.udito" :options="uditoOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Olfatto</label>
                            <b-form-select v-model="jsonDataValutazioneSensoriale.olfatto" :options="olfattoOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Gusto</label>
                            <b-form-select v-model="jsonDataValutazioneSensoriale.gusto" :options="gustoOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Sensibilità</label>
                            <b-form-select v-model="jsonDataValutazioneSensoriale.sensibilita" :options="sensibilitaOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Consapelozza di malattia</label>
                            <b-form-select v-model="jsonDataValutazioneSensoriale.consapevolezzaMalattia" :options="consapevolezzaMalattiaOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Note</label>
                            <b-form-textarea v-model="jsonDataValutazioneSensoriale.note" :readonly="isReadOnly" max-rows="10" rows="7"> </b-form-textarea>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import UtilityMixin from "../../../utility/UtilityMixin";

export default {
    props: {
        isReadOnly: {
            type: Boolean,
            default: false,
        },
        idAccettazione: {
            type: String,
        },
    },
    mixins: [UtilityMixin],
    data() {
        return {
            pathResourceSchedaInfermieristicaValutazioneSensoriale: "/rsaschedainfermieristicavalutazionesensoriale",
            jsonDataValutazioneSensoriale: {
                id: null,
                idAccettazione: null,
                vista: null,
                udito: null,
                olfatto: null,
                gusto: null,
                note: null,
                sensibilita: null,
                consapevolezzaMalattia: null,
            },

            vistaOptions: [
                { text: "INTEGRA", value: "INTEGRA" },
                { text: "DEFICIT", value: "DEFICIT" },
            ],
            uditoOptions: [
                { text: "INTEGRA", value: "INTEGRA" },
                { text: "DEFICIT", value: "DEFICIT" },
            ],
            olfattoOptions: [
                { text: "INTEGRA", value: "INTEGRA" },
                { text: "DEFICIT", value: "DEFICIT" },
            ],
            gustoOptions: [
                { text: "INTEGRA", value: "INTEGRA" },
                { text: "DEFICIT", value: "DEFICIT" },
            ],
            consapevolezzaMalattiaOptions: [
                { text: "SI", value: "SI" },
                { text: "NO", value: "NO" },
            ],
            sensibilitaOptions: [
                { text: "INTEGRA", value: "INTEGRA" },
                { text: "IPOESTESIA", value: "IPOESTESIA" },
                { text: "IPERESTESIA", value: "IPERESTESIA" },
                { text: "ANESTESIA", value: "ANESTESIA" },
            ],
        };
    },
    methods: {
        loadValutazioneSensorialeData() {
            let me = this;
            // console.log("ID accettazione nel loadValutazioneSensorialeData: " + me.idAccettazione);
            UtilityMixin.methods.loadDataResources(me.pathResourceSchedaInfermieristicaValutazioneSensoriale + "/accettazione/" + me.idAccettazione, {}, me.resultResponseValutazioneSensorialeSuccess, me.resultResponseValutazioneSensorialeError);
        },
        resultResponseValutazioneSensorialeSuccess(response) {
            let me = this;
            me.jsonDataValutazioneSensoriale = response.data.data;
            // console.log("ID del record VALUTAZIONE SENSORIALE:", me.jsonDataValutazioneSensoriale.id);
        },
        resultResponseValutazioneSensorialeError(error) {
            let me = this;
            if (error.response && error.response.status === 404) {
                //console.log("NESSUN record trovato del VALUTAZIONE SENSORIALE per questo id accettazione");
                me.resetForm();
            } else {
                console.error("Errore nel caricamento dati della sezione VALUTAZIONE SENSORIALE:", error);
            }
        },
        resetForm() {
            let me = this;
            me.jsonDataValutazioneSensoriale = {
                id: null,
                idAccettazione: me.idAccettazione,
                vista: null,
                udito: null,
                olfatto: null,
                gusto: null,
                note: null,
                sensibilita: null,
                consapevolezzaMalattia: null,
            };
        },

        onSalvaValutazioneSensoriale() {
            let me = this;
            me.jsonDataValutazioneSensoriale.idAccettazione = me.idAccettazione;
            console.log("Salvataggio VALUTAZIONE SENSORIALE con dati:", JSON.stringify(me.jsonDataValutazioneSensoriale));
            if (!me.jsonDataValutazioneSensoriale.idAccettazione) {
                // console.log("idAccettazione: ", me.jsonDataValutazioneSensoriale.idAccettazione);
                me.$bvToast.toast("Errore: ID Accettazione mancante", {
                    title: "Errore",
                    variant: "danger",
                    solid: true,
                });
                return;
            }

            UtilityMixin.methods.saveResource(me.pathResourceSchedaInfermieristicaValutazioneSensoriale, me.jsonDataValutazioneSensoriale, me.saveValutazioneSensorialeSuccessResponse, me.saveValutazioneSensorialeErrorResponse);
        },
        saveValutazioneSensorialeSuccessResponse(response) {
            console.log("VALUTAZIONE SENSORIALE salvato con successo:", response);
        },
        saveValutazioneSensorialeErrorResponse(error) {
            console.error("Errore nel salvataggio VALUTAZIONE SENSORIALE:", error);
        },
    },
};
</script>
