<template>
    <div v-if="size === 'sm'" style="display: inline-block">
        <!-- <b-button size="sm" class="no-text" variant="outline-info" @click="onOpenPaziente()">
      <i class="fas fa-user-injured"></i>
    </b-button> -->
        <b-button v-b-tooltip.hover title="Referta" v-if="statoAttuale === 'ACCETTATO'" size="sm" class="mr-1 ml-1 no-text" variant="outline-info" @click="onOpenVisita()">
            <i class="far fa-edit"></i>
        </b-button>
        <b-button v-b-tooltip.hover title="Visualiza Referto" v-else size="sm" class="mr-1 ml-1 no-text" variant="outline-info" @click="onShowVisita()">
            <i class="fas fa-eye"></i>
        </b-button>
    </div>
    <div v-else style="display: inline-block">
        <!-- <b-button size="md" class="no-text" variant="outline-info" @click="onOpenPaziente()"> <i class="fas fa-user-injured"></i>Paziente </b-button> -->
        <b-button v-b-tooltip.hover title="Referta" v-if="statoAttuale === 'ACCETTATO'" size="md" class="mr-1 ml-1 no-text" variant="outline-info" @click="onOpenVisita()">
            <i class="far fa-edit"></i>
            Referta
        </b-button>
        <b-button v-else v-b-tooltip.hover title="Visualizza Referto" size="md" class="mr-1 ml-1 no-text" variant="outline-info" @click="onShowVisita()">
            <i class="fas fa-eye"></i>
            Visualizza
        </b-button>
    </div>
</template>

<script>
import axios from "axios";
export default {
    props: {
        size: {
            type: String,
            required: true,
        },
        accettazione: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            pathEvento: null,
            pathResourceEventoTipoPrestazione: "/pazienteeventitipoprestazione/bycodicecatalogo/",
            linkBack: "/pazienteaccettazione",
        };
    },
    computed: {
        statoAttuale() {
            return this.accettazione.statoAttuale;
        },
    },
    mounted() {},
    methods: {
        onOpenVisita() {
            let me = this;
            me.$emit("beforLoadEventoTipoPrestazione");

            let link = process.env.VUE_APP_PATH_API + me.pathResourceEventoTipoPrestazione + me.accettazione.codicePrestazioneAgg;
            axios
                .get(link)
                .then((response) => {
                    let sessionStorage = window.sessionStorage;
                    sessionStorage.setItem("idAmbulatorio", me.accettazione.idAmbulatorio);
                    sessionStorage.setItem("sessionLinkBack", me.linkBack);
                    me.pathEvento = response.data.data.page;
                    // console.log("NuovaVisita");
                    me.$router.replace(me.pathEvento + "edit/-1?idPaziente=" + me.accettazione.idPaziente + "&idAccettazione=" + me.accettazione.id).catch((err) => {
                        err;
                    });
                    me.$emit("afterLoadEventoTipoPrestazione");
                })
                .catch(() => {
                    let sessionStorage = window.sessionStorage;
                    sessionStorage.setItem("idAmbulatorio", me.accettazione.idAmbulatorio);
                    sessionStorage.setItem("sessionLinkBack", "/pazienteaccettazione");
                    me.pathEvento = "paziente/eventi/visita/";

                    me.$router.replace(me.pathEvento + "edit/-1?idPaziente=" + me.accettazione.idPaziente + "&idAccettazione=" + me.accettazione.id).catch((err) => {
                        err;
                    });

                    me.$emit("afterLoadEventoTipoPrestazione");
                    // this.$bvModal
                    //     .msgBoxOk("Nessun tip evento configurato per il codice prestazione: " + me.accettazione.codicePrestazioneAgg, {
                    //         title: "ATTENZIONE",
                    //         size: "sm",
                    //         okVariant: "outline-danger",
                    //         headerClass: "sa-msg-header-danger",
                    //         footerClass: "p-2 border-top-0",
                    //         centered: true,
                    //     })
                    //     .then(() => {})
                    //     .catch((err) => {
                    //         console.log(err);
                    //     });
                });
        },
        onOpenPaziente() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            sessionStorage.setItem("idAmbulatorio", me.idAmbulatorio);
            me.$router.replace("paziente/view/" + me.idPaziente).catch((err) => {
                err;
            });
        },
        // onShowVisita() {
        //     let me = this;
        //     me.$router.replace(me.pathEvento + "view/" + me.idEvento + "?idPaziente=" + me.idPaziente).catch((err) => {
        //         err;
        //     });
        // },
        onShowVisita() {
            let me = this;
            console.log(me.accettazione);
            me.$emit("beforLoadEventoTipoPrestazione");

            let link = process.env.VUE_APP_PATH_API + me.pathResourceEventoTipoPrestazione + me.accettazione.codicePrestazioneAgg;
            console.log(me.accettazione.codicePrestazioneAgg);
            axios
                .get(link)
                .then((response) => {
                    let sessionStorage = window.sessionStorage;
                    sessionStorage.setItem("idAmbulatorio", me.accettazione.idAmbulatorio);
                    me.pathEvento = response.data.data.page;
                    // console.log("NuovaVisita");
                    me.$router.replace(me.pathEvento + "view/" + me.accettazione.idEvento + "?idPaziente=" + me.accettazione.idPaziente + "&idAccettazione=" + me.accettazione.idAccettazione).catch((err) => {
                        err;
                    });
                    me.$emit("afterLoadEventoTipoPrestazione");
                })
                .catch(() => {
                    //me.pathEvento = "paziente/eventi/visita/";
                    // console.log(e);
                    //   this.errors.push(e);
                    let sessionStorage = window.sessionStorage;
                    sessionStorage.setItem("idAmbulatorio", me.accettazione.idAmbulatorio);
                    me.pathEvento = "paziente/eventi/visita/";
                    // console.log(me.accettazione);
                    // console.log("NuovaVisita");
                    me.$router.replace(me.pathEvento + "view/" + me.accettazione.idEvento + "?idPaziente=" + me.accettazione.idPaziente + "&idAccettazione=" + me.accettazione.id).catch((err) => {
                        err;
                    });

                    me.$emit("afterLoadEventoTipoPrestazione");
                    // this.$bvModal
                    //     .msgBoxOk("Nessun tip evento configurato per il codice prestazione: " + me.accettazione.codicePrestazioneAgg, {
                    //         title: "ATTENZIONE",
                    //         size: "sm",
                    //         okVariant: "outline-danger",
                    //         headerClass: "sa-msg-header-danger",
                    //         footerClass: "p-2 border-top-0",
                    //         centered: true,
                    //     })
                    //     .then(() => {})
                    //     .catch((err) => {
                    //         console.log(err);
                    //     });
                });
        },
    },
};
</script>

<style></style>
