<template>
    <div>
        <b-card class="sa-card" header="Informazioni Cliniche" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Insorgenza Primi Sintomi</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataInsorgenzaPrimiSintomi) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Associato a Gravidanza</label>
                    <span class="sa-data">{{ jsonData.insorgenzaPrimiSintomiAssociatoGravidanza }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Patologie Neonato</label>
                    <span class="sa-data">{{ jsonData.patologieNeonato }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Quale dei Seguenti Sintomi ha Manifestato</label>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-table sticky-header stacked="xl" striped hover itemscope :items="jsonData.listaSintomi" :fields="fieldsSintomi" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="row">
                            {{ row.index + 1 }}
                        </template>
                        <template #cell(dataOra)="item">
                            <span class="sa-data">{{ formatDate(item.item.dataOra) }}</span>
                        </template>
                    </b-table>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Ricovero Ospedaliero</label>
                    <span class="sa-data">{{ jsonData.ricoveroOspedaliero }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Ricovero</label>
                    <span class="sa-data">{{ formatDate(jsonData.ricoveroOspedalieroData) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Dimissioni</label>
                    <span class="sa-data">{{ formatDate(jsonData.ricoveroOspedalieroDataDimissioni) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Diagnosi</label>
                    <span class="sa-data">{{ jsonData.ricoveroOspedalieroDiagnos }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Ospedale Ricovero</label>
                    <span class="sa-data">{{ jsonData.ricoveroOspedalieroNomeOspedale }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Reparto Ricovero</label>
                    <span class="sa-data">{{ jsonData.ricoveroOspedalieroNomeReparto }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Esami di Laboratorio Effettuati</label>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-table sticky-header stacked="xl" striped hover itemscope :items="jsonData.listaEsamiLaboratorio" :fields="fieldsEsamiLaboratorio" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="row">
                            {{ row.index + 1 }}
                        </template>
                    </b-table>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Patologie Pregresse</label>
                    <span class="sa-data">{{ jsonData.patologiePregresse }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Farmaci Assunti</label>
                    <span class="sa-data">{{ jsonData.farmaciAssunti }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Prodotti Omeopatici, Integratori, Prodotti Erboristici</label>
                    <span class="sa-data">{{ jsonData.prodottiOmeopaticiIntegratoriErboristici }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Quali</label>
                    <span class="sa-data">{{ jsonData.prodottiOmeopaticiIntegratoriErboristiciQuali }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Mese Precedente Ricoverato/Day Hospital</label>
                    <span class="sa-data">{{ jsonData.mesePrecedenteRicoveratoDayHospital }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Dove</label>
                    <span class="sa-data">{{ jsonData.mesePrecedenteRicoveratoDayHospitalDove }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Dal</label>
                    <span class="sa-data">{{ formatDate(jsonData.mesePrecedenteRicoveratoDayHospitalDal) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Al</label>
                    <span class="sa-data">{{ formatDate(jsonData.mesePrecedenteRicoveratoDayHospitalAl) }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Contatti</label>
                    <span class="sa-data">{{ jsonData.contattoCondivisoPastiStessiSintomi }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-table sticky-header ref="tblViaggi" stacked="xl" striped hover itemscope :items="jsonData.listaContatti" :fields="fieldsContatti" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="jsonData">
                            {{ jsonData.index + 1 }}
                        </template>
                    </b-table>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Analisi Comportamenti e Abitudini" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Nei 12-70 Giorni Precedenti ha Fatto Qialche Viaggio</label>
                    <span class="sa-data">{{ jsonData.fattoViaggi }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Dove</label>
                    <span class="sa-data">{{ jsonData.fattoViaggiDove }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Dal</label>
                    <span class="sa-data">{{ formatDate(jsonData.fattoViaggiDal) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Al</label>
                    <span class="sa-data">{{ formatDate(jsonData.fattoViaggiAl) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Struttura Soggiorno</label>
                    <span class="sa-data">{{ jsonData.fattoViaggiStruttura }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Consumato Pasti o Consumato Alimenti</label>
                    <span class="sa-data">{{ jsonData.fattoViaggiConsumatoAcquistatoAlimenti }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Quali</label>
                    <span class="sa-data">{{ jsonData.fattoViaggiConsumatoAcquistatoAlimentiQuali }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Luogo Acquisto</label>
                    <span class="sa-data">{{ jsonData.fattoViaggiConsumatoAcquistatoAlimentiLuogo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Acquisto</label>
                    <span class="sa-data">{{ formatDate(jsonData.fattoViaggiConsumatoAcquistatoAlimentiData) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Luogo Consumo</label>
                    <span class="sa-data">{{ jsonData.fattoViaggiConsumatoConsumoAlimentiLuogo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Data Consumo</label>
                    <span class="sa-data">{{ formatDate(jsonData.fattoViaggiConsumatoConsumoAlimentiData) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Nei 12 - 70 giorni precedenti dove ha acquistato gli alimenti" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-table sticky-header stacked="xl" striped hover itemscope :items="jsonData.listaNegozi" :fields="fieldsNegozi" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="row">
                            {{ row.index + 1 }}
                        </template>
                    </b-table>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">E' in possesso della tessera fedelta</label>
                    <span class="sa-data">{{ jsonData.possessoTesseraFedelta }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Quale/i</label>
                    <span class="sa-data">{{ jsonData.possessoTesseraFedeltaQuali }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Utilizzo abituale</label>
                    <span class="sa-data">{{ jsonData.possessoTesseraFedeltaUtilizzoFrequente }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">12 - 70 Giorni Prima Consumato Pasti Fuori Casa</label>
                    <span class="sa-data">{{ jsonData.consumatoPastiFuoriCasa }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-table sticky-header ref="tblViaggi" stacked="xl" striped hover itemscope :items="jsonData.listaPastiFuoriCasa" :fields="fieldsPastiFuoriCasa" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="jsonData">
                            {{ jsonData.index + 1 }}
                        </template>
                        <template #cell(dataConsumo)="item">
                            <span class="sa-data">{{ formatDate(item.item.dataConsumo) }}</span>
                        </template>
                    </b-table>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Indagine sul consumo di alimenti" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Carne e Prodotti Derivati</label>
                    <span class="sa-data">{{ jsonData.consumatoCarneProdottiDerivati }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-table sticky-header ref="tblViaggi" stacked="xl" striped hover itemscope :items="jsonData.listaAlimentiCarne" :fields="fieldsAlimentiCarne" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="jsonData">
                            {{ jsonData.index + 1 }}
                        </template>
                    </b-table>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Salumi</label>
                    <span class="sa-data">{{ jsonData.consumatoSalumi }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-table sticky-header ref="tblViaggi" stacked="xl" striped hover itemscope :items="jsonData.listaAlimentiSalumi" :fields="fieldsAlimentiCarne" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="jsonData">
                            {{ jsonData.index + 1 }}
                        </template>
                    </b-table>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Verdura e Frutta</label>
                    <span class="sa-data">{{ jsonData.consumatoVerduraFrutta }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-table sticky-header ref="tblViaggi" stacked="xl" striped hover itemscope :items="jsonData.listaAlimentiVerduraFrutta" :fields="fieldsAlimentiCarne" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="jsonData">
                            {{ jsonData.index + 1 }}
                        </template>
                    </b-table>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Pesce, Molluschi e Crostacei</label>
                    <span class="sa-data">{{ jsonData.consumatoPesce }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-table sticky-header ref="tblViaggi" stacked="xl" striped hover itemscope :items="jsonData.listaAlimentiPesce" :fields="fieldsAlimentiCarne" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="jsonData">
                            {{ jsonData.index + 1 }}
                        </template>
                    </b-table>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Latticini e Formaggi</label>
                    <span class="sa-data">{{ jsonData.consumatoLatticini }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-table sticky-header ref="tblViaggi" stacked="xl" striped hover itemscope :items="jsonData.listaAlimentiLatticini" :fields="fieldsAlimentiCarne" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="jsonData">
                            {{ jsonData.index + 1 }}
                        </template>
                    </b-table>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Prodotti Pronti al Consumo</label>
                    <span class="sa-data">{{ jsonData.consumatoProdottiProntiAlConsumo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-table sticky-header ref="tblViaggi" stacked="xl" striped hover itemscope :items="jsonData.listaAlimentiProdottiPronti" :fields="fieldsProdottiProntiAlConsumo" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="jsonData">
                            {{ jsonData.index + 1 }}
                        </template>
                    </b-table>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Prodotti Pronti al Consumo, Specificare se Presenti Seguenti Alimenti</label>
                    <span class="sa-data">{{ jsonData.consumatoProdottiProntiAlConsumoPresentiIngredienti }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-table sticky-header ref="tblViaggi" stacked="xl" striped hover itemscope :items="jsonData.listaAlimentiIngredienti" :fields="fieldsIngredienti" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="jsonData">
                            {{ jsonData.index + 1 }}
                        </template>
                    </b-table>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Salse e Condimenti</label>
                    <span class="sa-data">{{ jsonData.consumatoSalse }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-table sticky-header ref="tblViaggi" stacked="xl" striped hover itemscope :items="jsonData.listaAlimentiSalse" :fields="fieldsProdottiProntiAlConsumo" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="jsonData">
                            {{ jsonData.index + 1 }}
                        </template>
                    </b-table>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Dati Notifica" header-tag="header" footer-tag="footer" title="">
            <indagine-epidemiologica-dati-notifica-component :jsonData="jsonData" :isEdit="false" @update="onUpdateJsonData"></indagine-epidemiologica-dati-notifica-component>
        </b-card>
    </div>
</template>
<script>
import axios from "axios";
import Vue from "vue";
import UtilityMixin from "../../../utility/UtilityMixin";
import IndagineEpidemiologicaDatiNotificaComponent from "./IndagineEpidemiologicaDatiNotificaComponent.vue";
export default {
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    components: { IndagineEpidemiologicaDatiNotificaComponent }, //, DatePicker
    mixins: [UtilityMixin],
    data() {
        return {
            pathResource: "/malattieinfettiveielisteriosi",
            pathResourceSintomi: "/malattieinfettiveielisteriosisinotmi",
            pathResourceNegozi: "/malattieinfettiveielisteriosinegozi",
            pathResourceAlimenti: "/malattieinfettiveielisteriosialimenti",
            pathResourceComuni: "/comuni",
            pathResourceProvince: "/province",
            linkback: null,
            currentPage: 1,
            perPage: 100,
            showModalLoading: false,
            linkNazioni: "/nazioni",
            disableSintomi: true,
            jsonData: {
                id: "",
                idAnagrafica: "",
                dataInsorgenzaPrimiSintomi: null,
                ricoveroOspedaliero: "",
                ricoveroOspedalieroMotivo: "",
                ricoveroOspedalieroData: null,
                ricoveroOspedalieroDataDimissioni: null,
                ricoveroOspedalieroDiagnosi: "",
                ricoveroOspedalieroNomeOspedale: "",
                ricoveroOspedalieroNomeReparto: "",
                patologiePregresse: "",
                farmaciAssunti: "",
                prodottiOmeopaticiIntegratoriErboristici: "",
                prodottiOmeopaticiIntegratoriErboristiciQuali: "",
                mesePrecedenteRicoveratoDayHospital: "",
                mesePrecedenteRicoveratoDayHospitalDove: "",
                mesePrecedenteRicoveratoDayHospitalDal: null,
                mesePrecedenteRicoveratoDayHospitalAl: null,
                contattoCondivisoPastiStessiSintomi: "",
                fattoViaggi: "",
                fattoViaggiDove: "",
                fattoViaggiDal: null,
                fattoViaggiAl: null,
                fattoViaggiStruttura: "",
                fattoViaggiConsumatoAcquistatoAlimenti: "",
                fattoViaggiConsumatoAcquistatoAlimentiQuali: "",
                fattoViaggiConsumatoAcquistatoAlimentiLuogo: "",
                fattoViaggiConsumatoAcquistatoAlimentiData: null,
                fattoViaggiConsumatoConsumoAlimentiLuogo: "",
                fattoViaggiConsumatoConsumoAlimentiData: null,
                possessoTesseraFedelta: "",
                possessoTesseraFedeltaQuali: "",
                possessoTesseraFedeltaUtilizzoFrequente: "",
                consumatoPastiFuoriCasa: "",
                consumatoCarneProdottiDerivati: "",
                consumatoSalumi: "",
                consumatoVerduraFrutta: "",
                consumatoPesce: "",
                consumatoLatticini: "",
                consumatoProdottiProntiAlConsumo: "",
                consumatoProdottiProntiAlConsumoPresentiIngredienti: "",
                consumatoSalse: "",
                numeroProtocollo: "",
                dataProtocollo: null,
                statoAttualeProtocollo: "",
                insorgenzaPrimiSintomiAssociatoGravidanza: "",
                sanitarioNotificante: "",
                sanitarioNotificanteContatto: "",
                patologieNeonato: "",
                dataNotifica: null,
                listaSintomi: [],
                listaEsamiLaboratorio: [],
                listaContatti: [],
                listaNegozi: [],
                listaPastiFuoriCasa: [],
                listaAlimentiCarne: [],
                listaAlimentiSalumi: [],
                listaAlimentiVerduraFrutta: [],
                listaAlimentiPesce: [],
                listaAlimentiLatticini: [],
                listaAlimentiProdottiPronti: [],
                listaAlimentiIngredienti: [],
                listaAlimentiSalse: [],
            },
            fieldsSintomi: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Sintomo",
                    key: "sintomo",
                    thStyle: "width: 10rem",
                    sortable: true,
                },
                {
                    label: "Risposta",
                    key: "risposta",
                    thStyle: "width: 30rem",
                    sortable: false,
                },
                {
                    label: "Data Ora",
                    key: "dataOra",
                    thStyle: "width: 11rem",
                    sortable: false,
                },
            ],
            fieldsNegozi: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Negozio",
                    key: "negozio",
                    thStyle: "width: 10rem",
                    sortable: true,
                },
                {
                    label: "Risposta",
                    key: "risposta",
                    thStyle: "width: 15rem",
                    sortable: false,
                },
                {
                    label: "Comune",
                    key: "comune",
                    thStyle: "width: 30rem",
                    sortable: false,
                },
                {
                    label: "Indirizzo",
                    key: "indirizzo",
                    thStyle: "width: 30rem",
                    sortable: false,
                },
            ],
            fieldsEsamiLaboratorio: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Esame",
                    key: "descrizioneEsame",
                    sortable: false,
                },
                {
                    label: "Risultato",
                    key: "risultato",
                    // thStyle: "width: 11rem",
                    sortable: false,
                },
                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 4rem",
                    tdClass: "text-center",
                },
            ],
            fieldsContatti: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Cognome",
                    key: "cognome",
                    thStyle: "width: 20rem",
                    sortable: false,
                },
                {
                    label: "Nome",
                    key: "nome",
                    thStyle: "width: 20rem",
                    sortable: false,
                },
                {
                    label: "Comune",
                    key: "comune",
                    thStyle: "width: 10rem",
                    sortable: false,
                },
                {
                    label: "Indirizzo",
                    key: "indirizzo",
                    // thStyle: "width: 5rem",
                    sortable: false,
                },
                {
                    label: "Telefono",
                    key: "telefono",
                    // thStyle: "width: 5rem",
                    sortable: false,
                },
                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 4rem",
                    tdClass: "text-center",
                },
            ],
            fieldsPastiFuoriCasa: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Struttura",
                    key: "struttura",
                    // thStyle: "width: 15rem",
                    sortable: false,
                },
                {
                    label: "Comune",
                    key: "comune",
                    thStyle: "width: 15rem",
                    sortable: false,
                },
                {
                    label: "Indirizzo",
                    key: "indirizzo",
                    // thStyle: "width: 5rem",
                    sortable: false,
                },
                {
                    label: "Data Consumo",
                    key: "dataConsumo",
                    thStyle: "width: 15rem",
                    sortable: false,
                },
                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 4rem",
                    tdClass: "text-center",
                },
            ],
            fieldsAlimentiCarne: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Tipo",
                    key: "tipo",
                    // thStyle: "width: 15rem",
                    sortable: false,
                },
                {
                    label: "Consumato",
                    key: "consumato",
                    thStyle: "width: 15rem",
                    sortable: false,
                },
                {
                    label: "Confezionato",
                    key: "specificareConfezionato",
                    thStyle: "width: 15rem",
                    sortable: false,
                },
                {
                    label: "Nome e Luogo Acquisto",
                    key: "nomeLuogoAcquisto",
                    thStyle: "width: 15rem",
                    sortable: false,
                },
                {
                    label: "Marca",
                    key: "marca",
                    thStyle: "width: 15rem",
                    sortable: false,
                },
            ],
            fieldsIngredienti: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Tipo",
                    key: "tipo",
                    // thStyle: "width: 15rem",
                    sortable: false,
                },
                {
                    label: "Presente",
                    key: "presente",
                    thStyle: "width: 15rem",
                    sortable: false,
                },
                {
                    label: "Marca",
                    key: "marca",
                    thStyle: "width: 15rem",
                    sortable: false,
                },
                {
                    label: "Tipo Preparazione",
                    key: "qualePreparazione",
                    thStyle: "width: 15rem",
                    sortable: false,
                },
            ],
            fieldsProdottiProntiAlConsumo: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Tipo",
                    key: "tipo",
                    // thStyle: "width: 15rem",
                    sortable: false,
                },
                {
                    label: "Consumato",
                    key: "consumato",
                    thStyle: "width: 15rem",
                    sortable: false,
                },
                {
                    label: "Prep. Casalinga/Domestica",
                    key: "preparazioneCasalingaDomestica",
                    thStyle: "width: 15rem",
                    sortable: false,
                },
                {
                    label: "Prep. Gastronomica Supermercato/Negozio",
                    key: "preparazioneGastronomicaSupermercatoNegozio",
                    thStyle: "width: 15rem",
                    sortable: false,
                },
                {
                    label: "Pre-Confezionata all'origine",
                    key: "preConfezionata",
                    thStyle: "width: 15rem",
                    sortable: false,
                },
                {
                    label: "Marca",
                    key: "marca",
                    thStyle: "width: 15rem",
                    sortable: false,
                },
            ],
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient() {
            return UtilityMixin.methods.getLinkPatient(this.utente, this.idPaziente);
        },
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.linkback = "/malattieinfettive/all";

        me.loadData();
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;

            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            me.showModalLoading = true;
            axios
                .get(link + me.id)
                .then((response) => {
                    me.jsonData = response.data.data;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        loadSintomi() {
            let me = this;
            me.showModalLoading = true;

            let link = process.env.VUE_APP_PATH_API + me.pathResourceSintomi + "/";
            me.showModalLoading = true;
            axios
                .get(link)
                .then((response) => {
                    response.data.data.list.forEach((element) => {
                        me.jsonData.listaSintomi.push({ sintomo: element.descrizione, risposta: "", dataOra: null });
                    });
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        setDatiMedico() {
            let me = this;
            me.jsonData.sanitarioNotificante = Vue.prototype.user.firstname + " " + Vue.prototype.user.lastname;
        },
        setDatiMalattia(data) {
            let me = this;
            me.jsonData.id = data.id;
        },
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
        },
    },
};
</script>
