<template>
    <sa-page-layout :btnBackVisible="true" :btnModificationHistorical="true" :sezioneModificationHistorical="sezioneModificationHistorical" :btnEditVisible="btnEditVisible" :linkback="linkback" :linkedit="linkedit" class="sa-no-space" :data="jsonData">
        <template slot="toolbar">
            <b-button v-if="btnArchiviaSegnalazioneVisible" variant="outline-secondary btn-toolbar sa-margin-left float-sm-left" size="sm" @click="onCambiaStato('ARCHIVIATA')"><b-icon icon="archive"></b-icon>Archivia Segnalazione</b-button>
            <b-button v-if="showBtmUosdCompetenza" variant="outline-secondary btn-toolbar sa-margin-left float-sm-left" @click="onChangeUosd">
                <b-icon icon="file-earmark-plus" aria-hidden="true"></b-icon>
                Cambia UOSD
            </b-button>
            <protocollo-component v-if="jsonData.statoProtocollo !== 'SUCCESS' && showBtmProtocollo" :id="jsonData.id" :pathResource="pathResourceSend" size="md"></protocollo-component>
            <b-button variant="outline-success btn-toolbar sa-margin-left float-sm-left" v-b-modal.mdlAllegati :disabled="btnAllegatiDisable">
                <b-icon icon="file-earmark-plus" aria-hidden="true"></b-icon>
                Allegati
            </b-button>
        </template>
        <template slot="table-body">
            <b-tabs id="tabsSchede" nav-wrapper-class="sa-tab-items" content-class="mt-3" ref="tabs" class="sa-tab" style="height: 100%; padding-bottom: 25px" fill>
                <b-tab lazy title="Segnalazione" @click="disactiveAllegato()" active>
                    <malattie-infettive-view-component :jsonData="jsonData"></malattie-infettive-view-component>
                </b-tab>
                <b-tab title="Allegati" @click="activeAllegato()">
                    <allegato-model-component :idRisorsa="id" :jsonData="jsonData" @updateFiles="onUpdateFile()" :key="childKey" tipoRisorsa="MALATTIE INFETTIVE"></allegato-model-component>
                </b-tab>
            </b-tabs>
            <b-modal ref="mdlUosd" id="mdlUosd" size="xl" style="height: 100%" @ok="onOkCambiaUosd">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <strong>UOSD</strong>
                        <b-form-input v-model="jsonDataUosd.uosdPrecedente" readonly style="margin-right: 7px"></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <strong>Nuovo UOSD</strong>

                        <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonDataUosd.uosdCompetenza" :options="uosdOptions" value-field="value" text-field="text"></b-form-select>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <strong>Note</strong>
                        <b-form-textarea v-model="jsonDataUosd.note" rows="7" no-resize></b-form-textarea>
                    </b-col>
                </b-row>
            </b-modal>
            <malattie-infettive-cambia-stato-modal-component ref="cmpMalattieInfettiveCambiaStatoModalComponent" @beforeLoadData="onBeforeLoadMalattieInfettiveCambiaStatoModalComponent" @afterLoadData="onAfterLoadDataMalattieInfettiveCambiaStatoModalComponent" @beforeSaveRequest="onBeforeSaveRequestMalattieInfettiveCambiaStatoModalComponent" @afterSaveRequest="onAfterSaveRequestMalattieInfettiveCambiaStatoModalComponent"></malattie-infettive-cambia-stato-modal-component>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import UtilityMixin from "../../utility/UtilityMixin";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import MalattieInfettiveViewComponent from "../components/MalattieInfettiveViewComponent.vue";
import ProtocolloComponent from "../../protocollo/components/ProtocolloComponent.vue";
import AllegatoModelComponent from "../../utility/components/AllegatoModelComponent.vue";
import MalattieInfettiveCambiaStatoModalComponent from "../components/MalattieInfettiveCambiaStatoModalComponent.vue";
export default {
    props: {
        utente: { type: Object },
    },
    components: {
        SaPageLayout,
        MalattieInfettiveViewComponent,
        ProtocolloComponent,
        AllegatoModelComponent,
        MalattieInfettiveCambiaStatoModalComponent,
    },
    mixins: [UtilityMixin],
    data() {
        return {
            id: null,
            isDomicilioUgualeResidenza: false,
            linkedit: null,
            childKey: 0,
            btnEditVisible: false,
            btnArchiviaSegnalazioneVisible: false,
            showBtmProtocollo: false,
            showBtmUosdCompetenza: false,
            pathResourceSend: "/malattieinfettivesegnalazioni/sendprotocol",
            pathResource: "/malattieinfettivesegnalazioni",
            pathResourceUosdCompetenza: "/malattieinfettivesegnalazioneuosdcompetenza",
            linkback: "/malattieinfettive",
            btnAllegatiDisable: true,
            sezioneModificationHistorical: "malattieinfettive",
            fonte: "",
            showModalLoading: false,
            jsonData: {
                statoSegnalazione: "",
                provinciaInizioSintomi: "",
                professione: "",
                dataInizioSintomi: null,
                comuneSintomiCodiceIstat: null,
                vaccino: null,
                numeroDosi: 0,
                dataUltimaDose: null,
                tipoVaccino: "",
                nomeMedicoSegnalante: "",
                cognomeMedicoSegnalante: "",
                contattoMedicoSegnalante: "",
                dataSegnalazione: null,
                ricoveroLuogoCura: null,
                comuneResidenzaCodiceIstat: null,
                indirizzoResidenza: "",
                comuneDomicilioCodiceIstat: null,
                indirizzoDomicilio: "",
                statoAttuale: null,
                statoAttualeDataOra: null,
                uosdDiagnosi: null,
                sesso: null,
                pazienteBean: {
                    identificativo: "",
                    cognome: "",
                    nome: "",
                    dataNascita: null,
                    comuneNascitaCodiceIstat: null,
                    comuneResidenzaCodiceIstat: null,
                    comuneDomicilioCodiceIstat: null,
                    indirizzoResidenza: "",
                    indirizzoDomicilio: "",
                    telefono: "",
                    sesso: null,
                    provinciaNascita: null,
                    provinciaResidenza: null,
                    provinciaDomicilio: null,
                },
                malattieInfettiveSegnalazioneStatoMalattiaBeans: [],
            },
            jsonDataUosd: {
                idSegnalazione: "",
                uosdPrecedente: "",
                uosdCompetenza: "",
                note: null,
            },
            uosdOptions: [],
        };
    },
    mounted() {
        let me = this;
        me.btnAllegatiDisable = true;
        me.id = this.$route.params.id;
        me.fonte = this.$route.query.fonte;
        me.linkedit = "/malattieinfettive/segnalazioni/edit/" + me.id + "?fonte=" + me.fonte;
        me.linkback = "/malattieinfettive" + me.fonte;
        me.loadData();
        me.loadUosdDiagnosi();
        me.impostaPermessi();
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            //   console.log(me.utente);

            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            me.showModalLoading = true;
            axios
                .get(link + me.id)
                .then((response) => {
                    me.jsonData = response.data.data;
                    me.showModalLoading = false;
                    me.impostaPermessi();
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        activeAllegato() {
            let me = this;
            me.btnAllegatiDisable = false;
        },
        disactiveAllegato() {
            let me = this;
            me.btnAllegatiDisable = true;
        },
        impostaPermessi() {
            let me = this;
            me.btnEditVisible = UtilityMixin.methods.verificaPermessi(me.utente, "malattieinfettive", 4);
            me.showBtmProtocollo = UtilityMixin.methods.verificaPermessi(me.utente, "protocol", 2) && UtilityMixin.methods.verificaPermessi(me.utente, "malattieinfettivesegnalazioneprotocol", 2);
            me.showBtmUosdCompetenza = UtilityMixin.methods.verificaPermessi(me.utente, "malattieinfettiveuosdcompetenza", 2);
            me.btnArchiviaSegnalazioneVisible = UtilityMixin.methods.verificaPermessi(me.utente, "malattieinfettivearchiviazione", 2) && me.jsonData.statoSegnalazione !== "ARCHIVIATA";
        },
        onChangeUosd() {
            let me = this;
            me.jsonDataUosd = {
                idSegnalazione: me.jsonData.id,
                uosdPrecedente: me.jsonData.uosdResidenza,
                uosdCompetenza: "",
                note: null,
            };
            me.$refs["mdlUosd"].show();
        },
        loadUosdDiagnosi() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + "/malattieinfettiveuosd?page=1&forPage=100";
            me.uosdOptions = [];
            axios
                .get(link)
                .then((response) => {
                    response.data.data.list.forEach((element) => {
                        me.uosdOptions.push({
                            text: element.descrizione,
                            value: element.descrizione,
                        });
                    });

                    me.uosdOptions.unshift({ text: "-Seleziona-", value: "" });
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        onOkCambiaUosd() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceUosdCompetenza;
            axios
                .post(link, JSON.stringify(me.jsonDataUosd))
                .then(() => {
                    me.$refs["mdlUosd"].hide();
                    this.$bvToast.toast("Salvataggio avvenuto con successo", {
                        title: this.titleModal,
                        variant: "success",
                        autoHideDelay: 1000,
                    });
                    me.loadData();
                })
                .catch((error) => {
                    me.showModalLoading = false;
                    this.$bvModal
                        .msgBoxOk(error.response.data.messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then((value) => {
                            this.boxTwo = value;
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                });
        },
        onCambiaStato(value) {
            let me = this;
            me.$refs.cmpMalattieInfettiveCambiaStatoModalComponent.setJsonSegnalazione(me.jsonData);
            me.$refs.cmpMalattieInfettiveCambiaStatoModalComponent.setStato(value);
            me.$refs.cmpMalattieInfettiveCambiaStatoModalComponent.show();
        },
        onBeforeLoadMalattieInfettiveCambiaStatoModalComponent() {
            let me = this;
            me.showModalLoading = true;
        },
        onAfterLoadDataMalattieInfettiveCambiaStatoModalComponent() {
            let me = this;
            me.showModalLoading = false;
        },
        onBeforeSaveRequestMalattieInfettiveCambiaStatoModalComponent() {
            let me = this;
            me.showModalLoading = true;
        },
        onAfterSaveRequestMalattieInfettiveCambiaStatoModalComponent() {
            let me = this;
            me.$refs.cmpMalattieInfettiveCambiaStatoModalComponent.close();
            this.$bvToast.toast(`Stato cambiato con successo`, {
                title: "Cambia Stato",
                solid: true,
                variant: "success",
                autoHideDelay: 5000,
                appendToast: true,
            });
            me.loadData();
        },
    },
};
</script>
