var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sa-list-component"},[_c('div',{staticClass:"sa-list-component-header"},[_c('b-row',{staticClass:"sa-label-info",staticStyle:{"margin-left":"0.5rem","margin-right":"0.5rem"}},[_c('b-col',{attrs:{"lg":"3"}},[_c('p',[_vm._v(_vm._s(this.$i18n.t("global.lblTotalRecords"))+": "+_vm._s(_vm.rows))])]),_c('b-col',{attrs:{"lg":"9"}},[_c('b-pagination',{attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage,"align":"right","size":"sm"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1),_c('div',{staticClass:"sa-list-component-body"},[_c('div',{staticClass:"b-table-sticky-header"},[_c('b-table',{key:_vm.listKey,ref:"table",staticClass:"sa-b-table",attrs:{"sticky-header":"","stacked":"lg","striped":"","hover":"","items":_vm.items,"fields":_vm.fields,"current-page":1,"per-page":_vm.perPage,"sort-icon-left":"","head-variant":"light"},scopedSlots:_vm._u([{key:"cell(chiamanteContatto)",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"sa-edit-link",attrs:{"to":'/cot/tracciamentochiamate/edit/' + item.id}},[_vm._v(_vm._s(item.chiamanteContatto))])]}},{key:"cell(dataOra)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateTime(item.dataOra))+" ")]}},{key:"cell(chiamante)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.chiamanteNominativo)+" "),_c('span',{staticClass:"sa-label-data-secondary"},[_vm._v(_vm._s(item.chiamanteRuolo))])]}},{key:"cell(paziente)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.pazienteNome)+" "+_vm._s(item.pazienteCognome)+" "),_c('span',{staticClass:"sa-label-data-secondary"},[_vm._v(_vm._s(item.pazienteIdentificativo))])]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('b-button',{staticClass:"mr-1 ml-1 no-text",attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":function($event){return _vm.onInfoStanza(item)}}},[_c('i',{staticClass:"fas fa-info"})])]}}])})],1),_c('b-modal',{ref:"mdlChiamataView",attrs:{"size":"lg","title":"Info Chiamata","ok-only":true,"ok-title":"Chiudi"}},[_c('cot-tracciamento-chiamate-view-component',{ref:"CotTracciamentoChiamateViewComponent",attrs:{"jsonData":_vm.tracciamentoChiamataSelected,"idTracciamanetoChiamata":_vm.idTracciamanetoChiamata},on:{"afterLoadData":_vm.onAfterLoadData}})],1)],1),_c('div',{staticClass:"sa-list-component-footer"},[_c('b-row',{staticClass:"sa-label-info",staticStyle:{"margin-left":"0.5rem","margin-right":"0.5rem"}},[_c('b-col',{attrs:{"lg":"3"}},[_c('p',[_vm._v(_vm._s(this.$i18n.t("patients.lblTotalRecords"))+": "+_vm._s(_vm.rows))])]),_c('b-col',{attrs:{"lg":"9"}},[_c('b-pagination',{attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage,"align":"right","size":"sm"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }