<template>
	<sa-page-layout :btnBackVisible="false" :btnEditVisible="btnEditVisible" :linkback="linkback" :linkedit="linkedit" :pathResource="pathResource" :showModalLoading="showModalLoading">
		<template slot="table-body">
			<rsa-scheda-ospite-referti-component></rsa-scheda-ospite-referti-component>
		</template>
	</sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import RsaSchedaOspiteRefertiComponent from "../components/RsaSchedaOspiteRefertiComponent.vue";
export default {
	components: { SaPageLayout, RsaSchedaOspiteRefertiComponent },
	data() {
		return {
			btnNewVisible: true,
			pathResource: "",
			btnEditVisible: false,
			showModalLoading: false,
			linkback: null,
			linkedit: null,
		};
	},
	mounted() {},
	methods: {},
};
</script>
<style></style>
