<template>
    <sa-page-layout :btnNewVisible="btnNewVisible" :btnRefreshVisible="true" @refresh="onRefresh" :pathResource="pathResource" :linkedit="linkedit" :showModalLoading="showModalLoading">
        <template slot="table-filter"></template>
        <template slot="table-body">
            <cot-stati-list-component ref="CotStatiListComponent" @afterLoadData="onAfterLoadData"></cot-stati-list-component>
        </template>
    </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import CotStatiListComponent from "../components/CotStatiListComponent.vue";
export default {
    components: { SaPageLayout, CotStatiListComponent },
    data() {
        return {
            showModalLoading: false,
            btnNewVisible: true,
            btnRefreshVisible: true,
            pathResource: null,
            linkedit: "stati",
        };
    },
    mounted() {
        let me = this;
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            me.$refs.CotStatiListComponent.loadData();
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        onAfterLoadData() {
            let me = this;
            me.showModalLoading = false;
        },
    },
};
</script>

<style></style>
