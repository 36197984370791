<template>
    <div style="height: 100%; overflow: auto">
        <b-card class="sa-card" header="Dati Chiamante" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Nominativo:</label>
                    <b-form-input v-model="jsonData.chiamanteNominativo"></b-form-input>
                </b-col>
                <!-- <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Cognome:</label>
                    <b-form-input v-model="jsonData.chiamanteCognome"></b-form-input>
                </b-col> -->
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Contatto:</label>
                    <b-form-input v-model="jsonData.chiamanteContatto"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Ruolo:</label>
                    <b-form-select v-model="jsonData.chiamanteRuolo" :options="ruoliOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Dettagli Chiamata" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Data ora:</label>
                    <date-picker format="DD-MM-YYYY HH:mm:ss" value-type="timestamp" type="datetime" v-model="jsonData.dataOra"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Motivo:</label>
                    <b-form-select v-model="jsonData.codiceMotivo" :options="motivazioniOptions" :value="''" value-field="codice" text-field="descrizione" @change="onChangeMotivo"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Note:</label>
                    <b-form-textarea v-model="jsonData.note" rows="8" no-resize></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Dati Paziente" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Identificativo:</label>
                    <b-input-group>
                        <b-form-input maxlength="16" v-model="jsonData.pazienteIdentificativo" @keyup.enter="onSearchAnagraficaClick()" style="text-transform: uppercase"></b-form-input>
                        <b-input-group-append>
                            <b-button @click="onSearchAnagraficaClick()"><b-icon icon="search"></b-icon></b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Nome:</label>
                    <span class="sa-data">{{ jsonData.pazienteNome }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Cognome:</label>
                    <span class="sa-data">{{ jsonData.pazienteCognome }}</span>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
export default {
    components: { DatePicker },
    data() {
        return {
            motivazioniOptions: [],
            ruoliOptions: [],
            pathResource: "/cottracciamentochiamate",
            pathResourceMotivazioni: "/cottracciamentochiamatemotivi",
            pathResourceRuoli: "/cottracciamentochiamateruoli",
            pathResourcePropostaAccesso: "/cot/richieste",
            jsonData: { idPropostaAccesso: null, dataOra: null, codiceMotivo: "", descrizioneMotivo: null, note: null, chiamanteNominativo: null, chiamanteContatto: null, chiamanteRuolo: null, pazienteNome: null, pazienteCognome: null, pazienteIdentificativo: null },
        };
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    mounted: function () {
        let me = this;
        me.id = this.$route.params.id;
        if (me.id === "-1") {
            me.jsonData.dataOra = new Date().getTime();
        }
        me.loadMotivazioni();
    },
    methods: {
        loadMotivazioni() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceMotivazioni;
            axios.get(link).then((response) => {
                me.motivazioniOptions = response.data.data.list;
                me.motivazioniOptions.unshift({ codice: "", descrizione: "-Seleziona Motivo-" });
                me.loadRuolo();
            });
        },
        loadRuolo() {
            let me = this;
            me.ruoliOptions = [];
            let link = process.env.VUE_APP_PATH_API + me.pathResourceRuoli;
            axios.get(link).then((response) => {
                response.data.data.list.forEach((element) => {
                    this.ruoliOptions.push({ text: element.descrizione, value: element.descrizione });
                });
                me.ruoliOptions.unshift({ value: null, text: "-Seleziona Ruolo-" });
                me.loadData();
            });
        },
        onChangeMotivo() {
            let me = this;
            me.motivazioniOptions.forEach((element) => {
                if (element.codice === me.jsonData.codiceMotivo) {
                    me.jsonData.descrizioneMotivo = element.descrizione;
                    console.log(me.jsonData.descrizioneMotivo);
                }
            });
        },
        loadData() {
            let me = this;
            if (me.id === "-1") {
                this.$emit("afterLoadData");
            } else {
                // me.showModalLoading = true;
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                axios.get(link + me.id).then((response) => {
                    me.jsonData = response.data.data;

                    this.$emit("afterLoadData");
                });
            }
        },
        onSearchAnagraficaClick() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + "/utility";
            if (me.jsonData.pazienteIdentificativo) {
                axios
                    .get(link, { params: { codiceStruttura: "180000", codiceFiscale: me.jsonData.pazienteIdentificativo } })
                    .then((response) => {
                        // console.log(response);
                        if (response.data.data !== null) {
                            me.jsonData.pazienteNome = response.data.data.nome;
                            me.jsonData.pazienteCognome = response.data.data.cognome;
                        } else {
                            return me.$bvModal.msgBoxOk("Codice Fiscale non corretto", {
                                title: "Errore",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            });
                        }
                    })
                    .catch(() => {
                        me.$emit("update", me.jsonData);
                    });
            }
        },
        getJsonData() {
            return this.jsonData;
        },
    },
};
</script>

<style></style>
