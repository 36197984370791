<template>
    <div>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Ittero:</label>
                    <span class="sa-data">{{ jsonData.malattiaDiventatoGiallo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Data Inizio Malattia:</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataInizioMalattia) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Ospedalizzato:</label>
                    <span class="sa-data">{{ jsonData.ospedalizzatoPerMalattia }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Ospedalizzato Durata:</label>
                    <!-- <b-form-select v-model="jsonData.ospedalizzatoPerMalattia" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select> -->
                    <span class="sa-data">{{ jsonData.ospedalizzatoPerMalattiaGiorni }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Durante le 6 settimane precedenti" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Assunzione Farmaci:</label>
                    <span class="sa-data">{{ jsonData.seiSettimanePrimaAssunzioneFarmaci }}</span>
                </b-col>
            </b-row>
            <b-row style="margin-top: 5px">
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-table sticky-header ref="tblFarmaci" stacked="xl" striped hover itemscope :items="jsonData.listaFarmaci" :fields="fieldsFarmaci" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="listaFarmaci">
                            {{ listaFarmaci.index + 1 }}
                        </template>
                        <template #cell(farmaco)="item">
                            <span class="sa-data">{{ item.item.farmaco }}</span>
                        </template>
                        <template #cell(tempo)="item">
                            <span class="sa-data">{{ item.item.tempo }}</span>
                        </template>
                        <!-- <template #cell(actions)="row">
              <b-button v-if="row.index == jsonData.listaFarmaci.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddFarmaci(jsonData.listaFarmaci, row.item)">
                <b-icon icon="plus"></b-icon>
              </b-button>
              <b-button v-if="row.index !== jsonData.listaFarmaci.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(jsonData.listaFarmaci, row)">
                <b-icon icon="trash"></b-icon>
              </b-button>
            </template> -->
                    </b-table>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Consumato Frutti di Mare:</label>
                    <span class="sa-data">{{ jsonData.seiSettimanePrimaConsumoFruttiMare }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Totalmente Crudi/in Parte:</label>
                    <span class="sa-data">{{ jsonData.seiSettimanePrimaConsumoFruttiMareCrudi }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Consumato Frutti di Bosco:</label>
                    <span class="sa-data">{{ jsonData.seiSettimanePrimaConsumoFruttiBosco }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Tipo Frutti di Bosco:</label>
                    <span class="sa-data">{{ jsonData.seiSettimanePrimaConsumoFruttiBoscoDettagli }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Bevuto Acqua di Pozzo/Soergente:</label>
                    <span class="sa-data">{{ jsonData.seiSettimanePrimaConsumoAcquaPozzoSorgente }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Una/piu' Notte/i fuori Citta:</label>
                    <span class="sa-data">{{ jsonData.seiSettimanePrimaNotteFuoriCitta }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Dove:</label>
                    <span class="sa-data">{{ jsonData.seiSettimanePrimaNotteFuoriCittaDove }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Quando:</label>
                    <span class="sa-data">{{ formatDate(jsonData.seiSettimanePrimaNotteFuoriCittaQuando) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Vaccino Epatite A" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Somministrata Almeno Una Dose:</label>
                    <span class="sa-data">{{ jsonData.doseVaccinoEpatiteA }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Tipo Vaccino Prima Dose:</label>
                    <span class="sa-data">{{ jsonData.doseVaccinoEpatiteAPrimaDoseTipo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Data Prima Dose:</label>
                    <span class="sa-data">{{ formatDate(jsonData.doseVaccinoEpatiteAPrimaDoseData) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Tipo Vaccino Seconda Dose:</label>
                    <span class="sa-data">{{ jsonData.doseVaccinoEpatiteASecondaDoseTipo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Data Seconda Dose:</label>
                    <span class="sa-data">{{ formatDate(jsonData.doseVaccinoEpatiteASecondaDoseData) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Durante i 6 mesi precedenti" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Somministrazione Sangue/Plasma:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaSomministrazioneSanguePlasma }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Somministrazione Altri Derivati:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaSomministrazioneAltriDerivati }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Interventi Chirurgici:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaInterventiChirurgici }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Tipo Intervento:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaInterventiChirurgiciTipo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Luogo Intervento:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaInterventiChirurgiciLuogo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Endoscopia:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaEcoendoscopia }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Tipo Endoscopia:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaEcoendoscopiaTipo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Luogo Endoscopia:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaEcoendoscopiaLuogo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Ospedalizzato:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaOspedalizzato }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Ospedale:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaOspedalizzatoOspedale }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Reparto:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaOspedalizzatoReparto }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data:</label>
                    <span class="sa-data">{{ formatDate(jsonData.seiMesiPrimaOspedalizzatoData) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Uso Droghe:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaUsoDroga }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Modalita Assunzione:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaUsoDrogaModalitaAssunzione }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Contatto SERT:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaUsoDrogaContattoSert }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Trattamento Dentario:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaTrattamentoDentario }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Buchi Orecchie/Piercing:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaPiercing }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Tatuaggi:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaTatuaggio }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Pratica Agopuntura:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaAgopuntura }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Rasato dal Barbiere:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaRasatoBarbiere }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Callista/Manicure:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaCallistaManicure }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Emodializzato:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaEmodializzato }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Vaccino Epatite B" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Somministrata Almeno Una Dose:</label>
                    <span class="sa-data">{{ jsonData.doseVaccinoEpatiteB }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Tipo Vaccino Prima Dose:</label>
                    <span class="sa-data">{{ jsonData.doseVaccinoEpatiteBPrimaDoseTipo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Data Prima Dose:</label>
                    <span class="sa-data">{{ formatDate(jsonData.doseVaccinoEpatiteBPrimaDoseData) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Tipo Vaccino Seconda Dose:</label>
                    <span class="sa-data">{{ jsonData.doseVaccinoEpatiteBSecondaDoseTipo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Data Seconda Dose:</label>
                    <span class="sa-data">{{ formatDate(jsonData.doseVaccinoEpatiteBSecondaDoseData) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Tipo Vaccino Terza Dose:</label>
                    <span class="sa-data">{{ jsonData.doseVaccinoEpatiteBTerzaDoseTipo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Data Terza Dose:</label>
                    <span class="sa-data">{{ formatDate(jsonData.doseVaccinoEpatiteBTerzaDoseData) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Contatto Persone con Epatite 6 Mesi Prima:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaContattoConoscentiInfetti }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Malattia Iniziata Meno di Due Mesi Prima:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaContattoConoscentiInfettiInizioDueMesi }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Conviventi/Pratner Sessuali HbsAg Positivo:</label>
                    <span class="sa-data">{{ jsonData.conviventePatnerSessualeHbsagPositivo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Tipo Relazione:</label>
                    <span class="sa-data">{{ jsonData.conviventePatnerSessualeHbsagPositivoTipoRelazione }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">A Conoscenza:</label>
                    <span class="sa-data">{{ jsonData.conviventePatnerSessualeHbsagPositivoConoscenza }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Motivo Mancata Vaccinazione:</label>
                    <span class="sa-data">{{ jsonData.conviventePatnerSessualeHbsagPositivoConoscenzaVaccino }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Altro</label>
                    <span class="sa-data">{{ jsonData.conviventePatnerSessualeHbsagPositivoConoscenzaVaccinoAl }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Conviventi/Pratner Sessuali anti-HCV Positivo:</label>
                    <span class="sa-data">{{ jsonData.conviventePatnerSessualeAntiHcvPisitivo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Convivente Tossicodipentente:</label>
                    <span class="sa-data">{{ jsonData.conviventeTossicodipendente }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Partner Sessuali 6 mesi precedenti:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaPartnerSessuali }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Numero Partner:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaPartnerSessualiQuanti }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Partner Sessuale Tossicodipentente 6 mesi precedenti:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaPartnerSessualiTossicodipendenti }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Rapporti Occasionali 6 mesi precedenti:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaPartnerSessualiOccasionali }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Uso Profilattico:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaPartnerSessualiOccasionaliProfilattico }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Numero Persone in Casa:</label>
                    <span class="sa-data">{{ jsonData.numeroPersoneCasa }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Presenti Bambino che frequentano Asilo/Scuola Materna:</label>
                    <span class="sa-data">{{ jsonData.bambiniFrequentanoAsiloNodo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Donatore Periodico di Sangue:</label>
                    <span class="sa-data">{{ jsonData.donatorePeriodicoSangue }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">In Passato Eseguito Test Hiv:</label>
                    <span class="sa-data">{{ jsonData.testHivPassato }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Risutato Positivo:</label>
                    <span class="sa-data">{{ jsonData.testHivPassatoRisultatoPositivo }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Valori Massimi Esami Laboratorio" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">AST:</label>
                    <span class="sa-data">{{ jsonData.valoriMassimiEsameAst }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">ALT:</label>
                    <span class="sa-data">{{ jsonData.valoriMassimiEsameAlt }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Bilirubina Totale:</label>
                    <span class="sa-data">{{ jsonData.valoriMassimiEsameBilirubinaTotale }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Bilirubina Diretta:</label>
                    <span class="sa-data">{{ jsonData.valoriMassimiEsameBilirubinaDiretta }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">INR:</label>
                    <span class="sa-data">{{ jsonData.valoriMassimiEsameInr }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Risultati di Laboratorio" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Prelievo:</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataPrelievoSangue) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Positivo HbsAg:</label>
                    <span class="sa-data">{{ jsonData.positivoHbsag }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Positivo IgM anti-HBC:</label>
                    <span class="sa-data">{{ jsonData.positivoIgmAntiHbc }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Positivo IgM anti-epatite A:</label>
                    <span class="sa-data">{{ jsonData.positivoIgmAntiEpatiteA }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Positivo IgM anti-delta:</label>
                    <span class="sa-data">{{ jsonData.positivoIgmAntiDelta }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Positivo anti-HCV:</label>
                    <span class="sa-data">{{ jsonData.positivoAntiHcv }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Positivo HCV-RNA:</label>
                    <span class="sa-data">{{ jsonData.positivoHcvRna }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Livello Viremia:</label>
                    <span class="sa-data">{{ jsonData.positivoHcvRnaLivelloViremia }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Positivo IGM anti-Epatite E:</label>
                    <span class="sa-data">{{ jsonData.positivoIgmAntiEpatiteE }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Decorso Clinico" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Encefalopatia:</label>
                    <span class="sa-data">{{ jsonData.encefalopatia }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Fulminante:</label>
                    <span class="sa-data">{{ jsonData.fullminante }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Trapianto di Fegato:</label>
                    <span class="sa-data">{{ jsonData.trapiantoFegato }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Decesso:</label>
                    <span class="sa-data">{{ jsonData.decesso }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Decesso:</label>
                    <span class="sa-data">{{ formatDate(jsonData.decessoData) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Causa Iniziale Decesso:</label>
                    <span class="sa-data">{{ jsonData.decessoCausaIniziale }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Altre Considerazioni</label>
                    <span class="sa-data">{{ jsonData.altreConsiderazioni }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Dati Notifica" header-tag="header" footer-tag="footer" title="">
            <indagine-epidemiologica-dati-notifica-component :jsonData="jsonData" :isEdit="false"></indagine-epidemiologica-dati-notifica-component>
        </b-card>
    </div>
</template>
<script>
import axios from "axios";
import UtilityMixin from "../../../utility/UtilityMixin";
import IndagineEpidemiologicaDatiNotificaComponent from "./IndagineEpidemiologicaDatiNotificaComponent.vue";
export default {
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    components: { IndagineEpidemiologicaDatiNotificaComponent },
    mixins: [UtilityMixin],
    data() {
        return {
            pathResource: "/malattieinfettiveieepatiti",
            linkback: null,
            currentPage: 1,
            perPage: 100,
            jsonData: {},
            fieldsViaggi: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Luogo",
                    key: "dove",
                    sortable: false,
                },
                {
                    label: "Dal",
                    key: "giornoDal",
                    thStyle: "width: 11rem",
                    sortable: false,
                },
                {
                    label: "Al",
                    key: "giornoAl",
                    thStyle: "width: 11rem",
                    sortable: false,
                },
                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 4rem",
                    tdClass: "text-center",
                },
            ],
            fieldsFarmaci: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Farmaco",
                    key: "farmaco",
                    sortable: false,
                },
                {
                    label: "Tempo",
                    key: "tempo",
                    thStyle: "width: 11rem",
                    sortable: false,
                },
            ],
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient() {
            return UtilityMixin.methods.getLinkPatient(this.utente, this.idPaziente);
        },
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.setDatiMedico();
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                me.showModalLoading = true;
                axios
                    .get(link + me.id)
                    .then((response) => {
                        me.jsonData = response.data.data;
                        me.showModalLoading = false;
                    })
                    .catch(() => {
                        me.showModalLoading = false;
                    });
            }
        },
    },
};
</script>
