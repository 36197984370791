<template>
    <b-modal ref="mdlPrescrizionePrestazioneComponent" size="lg" style="height: 100%" title="" modal-class="sa-modal-fill-height">
        <template #modal-title>Prescrizione Specialistica</template>
        <div class="sa-flexbox">
            <div class="sa-flexbox-header">
                <b-row>
                    <b-col cols="6" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Nome Prestazione:</label>
                        <b-input-group class="mb-3">
                            <b-form-input maxlength="16" class="text-uppercase" v-model="jsonData.prescrizione" placeholder="Nome Prestazione" disabled></b-form-input>
                            <b-button size="sm" variant="" @click="onClickOpenRicercaPrestazione">
                                <b-icon icon="search"></b-icon>
                            </b-button>
                        </b-input-group>
                    </b-col>
                    <b-col cols="6" xs="12" sm="12" md="6" lg="6" xl="3">
                        <label class="sa-label-data">Quantità</label>
                        <template>
                            <b-input-group class="mb-3"><b-form-spinbutton id="sb-step" v-model="jsonData.quantita" min="0" max="1000000000" step="0.50"></b-form-spinbutton></b-input-group>
                        </template>
                    </b-col>
                    <b-col cols="6" xs="12" sm="12" md="6" lg="6" xl="3">
                        <label class="sa-label-data">Q.tà Accessi:</label>
                        <b-input-group class="mb-3"><b-form-spinbutton id="sb-step" v-model="jsonData.quantitaAccessi" min="0" max="1000000000" step="1"></b-form-spinbutton></b-input-group>
                    </b-col>
                    <b-col cols="6" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Data Inizio:</label>
                        <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataInizio" type="datetime"></date-picker>
                    </b-col>
                    <b-col cols="6" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Data Fine:</label>
                        <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataFine" type="datetime"></date-picker>
                    </b-col>
                </b-row>
            </div>
            <div class="sa-flexbox-body">
                <b-row>
                    <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                        <label class="sa-label-data">Note:</label>
                        <b-form-textarea v-model="jsonData.note" placeholder="Note" rows="7"></b-form-textarea>
                    </b-col>
                </b-row>
            </div>
            <div class="sa-flexbox-footer"></div>
            <div id="boxSelezionaPrestazione" class="sa-bancafarmaci sa-bancafarmaci-hidden">
                <div class="sa-flexbox">
                    <div class="sa-flexbox-header">
                        <b-form @submit.prevent="onClickDescrizionePrestazione">
                            <b-row>
                                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                    <label class="sa-label-data">Codice Prestazione:</label>
                                    <b-form-input v-model="filtroPrestazioni.codicePrestazione" type="search" id="codicePrestazione" placeholder="Codice Prestazione"></b-form-input>
                                </b-col>
                                <b-col cols="12" xs="12" sm="12" md="9" lg="9" xl="9">
                                    <label class="sa-label-data">Descrizione Prestazione:</label>
                                    <b-form-input v-model="filtroPrestazioni.descrizione" type="search" id="descrizionePrestazione" placeholder="Descrizione Prestazione"></b-form-input>
                                </b-col>
                                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                    <label class="sa-label-data">Codice Prestazione Agg:</label>
                                    <b-form-input v-model="filtroPrestazioni.codicePrestazioneAgg" type="search" id="codicePrestazioneAgg" placeholder="Codice Prestazione Agg"></b-form-input>
                                </b-col>
                                <b-col cols="12" xs="12" sm="12" md="9" lg="9" xl="9">
                                    <label class="sa-label-data">Descrizione Prestazione Agg:</label>
                                    <b-form-input v-model="filtroPrestazioni.descrizioneAgg" type="search" id="descrizionePrestazioneAgg" placeholder="Descrizione Prestazione Agg"></b-form-input>
                                </b-col>
                            </b-row>
                            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem; margin-top: 0.5rem">
                                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="sa-padding-right text-right">
                                    <b-button type="submit" variant="info">Cerca</b-button>
                                    <b-button type="reset" v-on:click="onClickResetRicercaPrestazione" variant="danger">Reset</b-button>
                                </b-col>
                            </b-row>
                        </b-form>
                    </div>
                    <div class="sa-flexbox-body">
                        <div class="b-table-sticky-header">
                            <b-table id="tabellaFarmaci" :busy="isBusy" :items="itemsPrestazioni" :fields="fieldsPrestazioni" selectable select-mode="single" @row-selected="onRowSelectedFarmaco" sort-icon-left head-variant="light" class="sa-b-table">
                                <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <strong style="padding-left: 10px">Caricamento dati in corso...</strong>
                                    </div>
                                </template>
                            </b-table>
                        </div>
                    </div>
                    <div class="sa-flexbox-footer">
                        <b-button size="sm" variant="outline-secondary" @click="onClickChiudiRicercaPrestazione()">Chiudi</b-button>
                    </div>
                </div>
            </div>
        </div>
        <template #modal-footer>
            <b-button size="sm" variant="danger" @click="onClickAnnulla()">Annulla</b-button>
            <b-button size="sm" variant="outline-secondary" @click="onClickConferma()" :disabled="disabeConferma">
                <i class="fas fa-check"></i>
                Conferma
            </b-button>
        </template>
    </b-modal>
</template>

<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import UtilityMixin from "../UtilityMixin";
export default {
    mixins: [UtilityMixin],
    components: { DatePicker },
    data() {
        return {
            jsonData: { id: null, idAccettazione: null, idPaziente: null, idPosologia: null, tipoPrestazione: "SPECIALISTICA", dataInizio: null, dataFine: null, codiceProdotto: null, atcGmp: null, prescrizione: null, quantita: 1, frequenza: null, costo: 0, stato: null, note: null, quantitaAccessi: 1 },
            jsonDataAccettazione: null,
            disabeConferma: false,
            isBusy: false,
            pathResourcePrestazioniNomenclatore: "/nomenclatore",
            pathResourcePazientePrescrizioni: "/rsapazienteprescrizioni",
            filtroPrestazioni: {},
            itemsPrestazioni: [],
            fieldsPrestazioni: [
                { label: "Codice Prestazione", key: "codicePrestazione" },
                { label: "Descrizione Prestazione", key: "descrizione" },
                { label: "Codice Prestazione Agg", key: "codicePrestazioneAgg" },
                { label: "Descrizione Prestazione Agg", key: "descrizioneAgg" },
                { label: "Importo", key: "importoPrestazione" },
            ],
        };
    },
    methods: {
        show(jsonDataAccettazione) {
            // console.log("SHOW");
            let me = this;
            me.jsonDataAccettazione = jsonDataAccettazione;
            me.jsonData = { id: null, idAccettazione: null, idPaziente: null, idPosologia: null, tipoPrestazione: "SPECIALISTICA", dataInizio: null, dataFine: null, codiceProdotto: null, atcGmp: null, prescrizione: null, quantita: 1, frequenza: null, costo: 0, stato: null, note: null, quantitaAccessi: 1 };
            this.$refs.mdlPrescrizionePrestazioneComponent.show();
        },
        edit(jsonDataAccettazione, jsonData) {
            // console.log("SHOW");
            let me = this;
            me.jsonDataAccettazione = jsonDataAccettazione;
            me.jsonData = jsonData;
            this.$refs.mdlPrescrizionePrestazioneComponent.show();
        },
        close() {
            this.$refs.mdlPrescrizionePrestazioneComponent.hide();
        },
        loadPrestazioniNomenclatore() {
            let me = this;
            me.isBusy = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResourcePrestazioniNomenclatore;
            me.filtroPrestazioni.page = me.currentPage;
            me.filtroPrestazioni.forPage = me.perPage;
            me.filtroPrestazioni.codiceRegione = "150";
            axios
                .get(link, { params: me.filtroPrestazioni })
                .then((response) => {
                    me.itemsPrestazioni = response.data.data;
                    me.isBusy = false;
                })
                .catch(() => {
                    me.isBusy = false;
                });
        },
        onClickOpenRicercaPrestazione() {
            let me = this;
            //document.getElementById("boxSelezionaFarmaco").style.visibility = "visible";
            document.getElementById("boxSelezionaPrestazione").classList.remove("sa-bancafarmaci-hidden");
            document.getElementById("boxSelezionaPrestazione").classList.add("sa-bancafarmaci-visible");
            me.disabeConferma = true;
        },
        onClickChiudiRicercaPrestazione() {
            let me = this;
            document.getElementById("boxSelezionaPrestazione").classList.remove("sa-bancafarmaci-visible");
            document.getElementById("boxSelezionaPrestazione").classList.add("sa-bancafarmaci-hidden");
            me.disabeConferma = false;
        },
        onClickAnnulla() {
            this.$refs.mdlPrescrizionePrestazioneComponent.hide();
        },
        onClickResetRicercaPrestazione() {},
        onClickDescrizionePrestazione() {
            let me = this;
            me.loadPrestazioniNomenclatore();
        },
        onClickConferma() {
            //this.$refs.mdlPrescrizioneFarmacoComponent.hide();
            let me = this;
            me.jsonData.idAccettazione = me.jsonDataAccettazione.id;
            me.jsonData.idPaziente = me.jsonDataAccettazione.accettazioneAnagrafica.id;

            console.log(me.jsonData);
            if (me.jsonData.id) {
                me.aggiornaPrecrizione();
            } else {
                me.creaPrescrizione();
            }
        },
        onRowSelectedFarmaco(item) {
            let me = this;
            if (item.length > 0) {
                me.jsonData.prescrizione = item[0].descrizione;
                me.jsonData.atcGmp = item[0].codicePrestazioneAgg;
                me.jsonData.codiceProdotto = item[0].codicePrestazione;
                me.jsonData.costo = item[0].importoPrestazione;
            }
        },
        creaPrescrizione() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourcePazientePrescrizioni;
            axios
                .post(link, me.jsonData)
                .then(() => {
                    // console.log(response);
                    me.close();
                    me.$emit("afterSava");
                })
                .catch((error) => {
                    UtilityMixin.methods.messaggeBoxError(this, error);
                });
        },
        aggiornaPrecrizione() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourcePazientePrescrizioni + "/" + me.jsonData.id;
            axios
                .put(link, me.jsonData)
                .then(() => {
                    // console.log(response);
                    me.close();
                    me.$emit("afterSava");
                })
                .catch((error) => {
                    UtilityMixin.methods.messaggeBoxError(this, error);
                });
        },
    },
};
</script>

<style></style>
