<template>
  <sa-page-layout
    :btnBackVisible="true"
    :linkback="linkback"
    :btnNewVisible="btnNewVisible"
    :btnRefreshVisible="true"
    @refresh="onRefresh"
    :pathResource="pathResource"
    :linkedit="linkedit"
    :showModalLoading="showModalLoading"
  >
    <template slot="table-body">
      <document-repository-list-component
        ref="DocumentRepositoryListComponent"
        @afterLoadData="onAfterLoadData"
        @beforeLoadData="onBeforeLoadData"
      ></document-repository-list-component>
    </template>
  </sa-page-layout>
</template>
<script>
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import DocumentRepositoryListComponent from "../components/DocumentRepositoryListComponent.vue";

export default {
  components: { SaPageLayout, DocumentRepositoryListComponent },
  data() {
    return {
      pathResource: "/documentrepository",
      linkedit: "/documentrepository",
      linkback: "/home",
      showModalLoading: false,
      listKey: 0,
      btnNewVisible: true,
    };
  },
  mounted() {
    let me = this;
    me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;
      me.$refs.DocumentRepositoryListComponent.loadData();
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
    onAfterLoadData() {
      let me = this;
      me.showModalLoading = false;
    },
    onBeforeLoadData() {
      let me = this;
      me.showModalLoading = true;
    },
  },
};
</script>
