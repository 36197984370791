<template>
    <sa-page-layout :btnNewVisible="btnNewVisible" :btnRefreshVisible="true" @refresh="onRefresh" :pathResource="pathResource" :linkedit="linkedit" :showModalLoading="showModalLoading">
        <!-- <template slot="table-filter">
            <b-form @submit.prevent="onSubmit">
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <b-form-input v-model="filtro.nome" type="search" id="nome" placeholder="Nome"></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <b-form-input v-model="filtro.cognome" type="search" id="cognome" placeholder="Cognome"></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <b-form-input v-model="filtro.identificativo" type="search" id="identificativo" :placeholder="this.$i18n.t('adi.lblFiscalCode')"></b-form-input>
                    </b-col>
                </b-row>
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="sa-padding-right text-right">
                        <b-button type="submit" variant="info">{{ this.$i18n.t("patients.lblFind") }}</b-button>
                        <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("patients.lblResetFilter") }}</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </template> -->
        <template slot="table-body">
            <cot-tracciamento-chiamate-list-component ref="CotTracciamentoChiamateListComponent" @afterLoadData="onAfterLoadData"></cot-tracciamento-chiamate-list-component>
        </template>
    </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import CotTracciamentoChiamateListComponent from "../components/CotTracciamentoChiamateListComponent.vue";
export default {
    components: { SaPageLayout, CotTracciamentoChiamateListComponent },
    data() {
        return {
            filtro: { nome: null, cognome: null, identificativo: null },
            btnNewVisible: true,
            pathResource: "",
            linkedit: "/cot/tracciamentochiamate",
            showModalLoading: false,
            listKey: 0,
        };
    },
    mounted() {
        let me = this;
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            me.$refs.CotTracciamentoChiamateListComponent.loadData();

            // let sessionStorage = window.sessionStorage;
            // sessionStorage["filtro"] = JSON.stringify(me.filtro);
            // console.log(me.filtro);
            // this.$store.dispatch("cotStore/setRichieste", me.filtro, { root: true }).then(() => {
            //     me.showModalLoading = false;
            // });
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        onAfterLoadData() {
            let me = this;
            me.showModalLoading = false;
        },
        onReset() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            me.filtro = { nome: null, cognome: null, identificativo: null };
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
        onSubmit() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
    },
};
</script>

<style></style>
