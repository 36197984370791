<template>
    <div style="height: 100%">
        <div class="sa-flexbox" style="padding-bottom: 20px">
            <div class="sa-flexbox-body">
                <div class="b-table-sticky-header">
                    <span class="sa-flexbox-body-title">P.A.I.</span>
                    <b-button size="sm" variant="outline-success outline-primary-noborder sa-margin-right float-sm-right" @click="onClickNuovaPai" class="mb-2">
                        <font-awesome-icon icon="plus"> </font-awesome-icon>
                        Aggiungi
                    </b-button>

                    <b-table sticky-header ref="table" stacked="lg" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="row">
                            {{ row.index + 1 }}
                        </template>

                        <template #cell(nominativo)="item">
                            <span> {{ item.item.nome + " " + item.item.cognome }}</span>
                        </template>
                        <template #cell(scadenzaPai)="item">
                            <span> {{ formatDate(item.item.scadenzaPai) }}</span>
                        </template>
                        <template v-slot:cell(actions)="{ item }">
                            <!-- <b-container class="bv-example-row" v-if="!isViewMode"> -->
                            <b-button size="sm" variant="outline-success" v-b-tooltip.hover title="Modifica P.A.I." @click="onClickAggiornaPai(item)">
                                <font-awesome-icon icon="edit"> </font-awesome-icon>
                            </b-button>

                            <b-button size="sm" v-b-tooltip.hover title="Cancella P.A.I." variant="outline-danger" @click="onDeletePai(item)">
                                <font-awesome-icon icon="trash"></font-awesome-icon>
                            </b-button>
                            <!--   </b-container> -->
                        </template>
                    </b-table>
                </div>
            </div>

            <div class="sa-list-component-footer">
                <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col lg="3">
                        <p>Record Totali:{{ rows }}</p>
                    </b-col>
                    <b-col lg="9">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                    </b-col>
                </b-row>
            </div>
        </div>
    </div>
</template>
<script>
import UtilityMixin from "../../../utility/UtilityMixin";
import axios from "axios";
export default {
    props: {
        // idAccettazione: { type: String }  ,
    },

    watch: {
        currentPage() {
            let me = this;
            me.loadData();
        },
    },
    mixins: [UtilityMixin],
    components: {},
    data() {
        return {
            rows: 0,
            perPage: 50,
            currentPage: 1,
            listKey: 0,
            items: [],
            pathResource: "/rsapai",
            linkedit: "/",
            filterItems: [],
            filtroSpesaExtra: {},
            showModalLoading: false,
            idAccettazione: "",
            fields: [
                {
                    label: "",
                    key: "index",
                },
                {
                    label: "Nominativo",
                    key: "nominativo",
                },
                {
                    label: "U.V.M.",
                    key: "tipoUvm",
                },
                {
                    label: "Grado non autosufficiente",
                    key: "gradoNonAutosufficienza",
                },
                {
                    label: "Provenienza paziente",
                    key: "provenienzaPaziente",
                },
                {
                    label: "Scadenza",
                    key: "scadenzaPai",
                },
                {
                    class: "sa-table-column-action-double",
                    key: "actions",
                    label: "",
                },
            ],
        };
    },
    mounted() {},
    methods: {
        loadData(id) {
            let me = this;
            me.idAccettazione = id;
            me.$emit("beforeLoadData");
            UtilityMixin.methods.loadDataResources(me.pathResource, { idAccettazione: me.idAccettazione }, me.loadDataSuccess, me.loadDataError);
            //   this.showModalLoading = true;
        },
        loadDataSuccess(response) {
            let me = this;
            me.items = response.data.data.list;
            me.rows = response.data.data.recordsNumber;
            me.$emit("afterLoadData");
            //  this.showModalLoading = true;
        },
        loadDataError(response) {
            console.log(response);
        },
        onClickAggiornaPai(item) {
            let me = this;
            //me.linkedit = "/rsapai/edit/" + item.id + "?idAccettazione=" + me.idAccettazione + "&language=" + this.$route.query.language;
            me.linkedit = "/rsapaziente/pai/edit/" + item.id + "?idAccettazione=" + me.idAccettazione + "&language=" + this.$route.query.language;
            me.$router.replace(me.linkedit).catch((err) => {
                err;
            });
        },
        onDeletePai(item) {
            let me = this;
            this.$bvModal
                .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
                    title: "Eliminazione PAI",
                    buttonSize: "sm",
                    okVariant: "secondary",
                    cancelVariant: "danger",
                    okTitle: "SI",
                    cancelTitle: "NO",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                })
                .then((value) => {
                    if (value) {
                        me.deletePai(item.id);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        deletePai(id) {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + id;
            axios
                .delete(link)
                .then((response) => {
                    me.showModalLoading = false;
                    me.$emit("onDelete", response);
                    me.$bvToast.toast("Eliminazione avvenuta con successo", {
                        title: "Successo",
                        variant: "success",
                        solid: true,
                    });
                })
                .catch((e) => {
                    console.log(e);
                    me.showModalLoading = false;
                    me.$bvToast.toast("Errore durante l'eliminazione", {
                        title: "Errore",
                        variant: "danger",
                        solid: true,
                    });
                });
        },
        onClickNuovaPai() {
            let me = this;
            //   if(){}
            //me.linkedit = "/rsapai/edit/-1?idAccettazione=" + me.idAccettazione + "&language=" + this.$route.query.language;
            me.linkedit = "/rsapaziente/pai/edit/-1?idAccettazione=" + me.idAccettazione + "&language=" + this.$route.query.language;
            me.$router.replace(me.linkedit).catch((err) => {
                err;
            });
        },
        saveSuccessResponse(response) {
            let me = this;
            me.$emit("sucessRestCall", response);
            me.$emit("onAfterSave");
            me.loadData();
        },
        saveErrorResponse(response) {
            let me = this;
            if (response.response.status === 400) {
                this.$bvModal.msgBoxOk(response.response.data.messaggio, {
                    title: "ATTENZIONE",
                    size: "sm",
                    okVariant: "outline-danger",
                    headerClass: "sa-msg-header-danger",
                    footerClass: "p-2 border-top-0",
                    centered: true,
                });
            } else {
                me.$bvToast.toast("Errore nel salvataggio" + response.message, {
                    title: "Errore",
                    variant: "danger",
                    solid: true,
                });
            }
        },
        // onAfterSaveSuccessSpesaExtra() {
        //   this.loadData();
        // },
        // onDelete() {
        //   this.loadData();
        // },
    },
};
</script>
