import moment from "moment";
import axios from "axios";
import Vue from "vue";
export default {
	/*data() {
        return {
            showModalLoading: Boolean,
            show: true,
        };
    },*/
	getLinkPatient(utente, idPaziente) {
		if (utente.gruppo) {
			if (utente.gruppo.menuGruppo) {
				if (utente.gruppo.menuGruppo.length == 1) {
					if (utente.gruppo.menuGruppo[0].primaryKey === "homestudiomedico") {
						return "/paziente/view/" + idPaziente;
					} else if (utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
						return "view/" + idPaziente;
					} else {
						return "homepaziente";
					}
				} else {
					return "";
				}
			} else {
				return "";
			}
		} else {
			return "";
		}
	},
	methods: {
		formatDateTime(value) {
			if (value) {
				return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
			} else {
				return "---";
			}
		},
		formatDateTimeCustom(value, formato) {
			if (value) {
				return moment(new Date(value)).format(formato);
			} else {
				return null;
			}
		},
		formatDate(value) {
			if (value) {
				return moment(new Date(value)).format("DD/MM/YYYY");
			} else {
				return "---";
			}
		},
		formatTime(value) {
			if (value) {
				return moment(new Date(value)).format("HH:mm");
			} else {
				return "---";
			}
		},
		parseFormatDateTime(value, format) {
			if (value) {
				return moment(value, format).toDate();
			} else {
				return "---";
			}
		},
		verificaPermessi(utente, idSezione, permessoDaVerificare) {
			let returnValue = false;
			if (utente !== null) {
				let gruppo = utente.gruppo;
				if (gruppo) {
					if (gruppo !== null) {
						if (gruppo.permessi) {
							gruppo.permessi.forEach((sezione) => {
								if (sezione.id === idSezione) {
									sezione.permessi.forEach((permesso) => {
										if (permesso.permesso === permessoDaVerificare) {
											returnValue = permesso.attivo;
										}
									});
								}
							});
						}
					}
				}
			}
			return returnValue;
		},
		verificaPermessiByIdSezionePermesso(idSezione, permessoDaVerificare) {
			let returnValue = false;
			// console.log(Vue.prototype.user);
			let gruppo = Vue.prototype.user.gruppo;
			if (gruppo) {
				if (gruppo !== null) {
					if (gruppo.permessi) {
						gruppo.permessi.forEach((sezione) => {
							if (sezione.id === idSezione) {
								sezione.permessi.forEach((permesso) => {
									if (permesso.permesso === permessoDaVerificare) {
										returnValue = permesso.attivo;
									}
								});
							}
						});
					}
				}
			}

			return returnValue;
		},
		getLabelTraduora(value) {
			// console.log(this.$i18n);
			return this.$i18n.t(value);
		},
		traduciConValue(label, value) {
			return this.$i18n.t(label, { variable: value });
		},
		getColorByFirma(firma) {
			let colore = firma ? "sa-tab-title-success text-success" : "sa-tab-title-danger text-danger";

			return colore;
		},
		getIconByFirma(firma) {
			let icon = firma ? "check2" : "exclamation-triangle";
			return icon;
		},
		getColorByEta(eta) {
			let colore = "sa-maggiorenne-true";
			if (!this.isMaggiorenne(eta)) {
				colore = "sa-maggiorenne-false";
			} else {
				colore = "sa-maggiorenne-true";
			}
			return colore;
		},
		getLinkPatient(utente, idPaziente) {
			if (utente.gruppo) {
				if (utente.gruppo.menuGruppo) {
					if (utente.gruppo.menuGruppo.length == 1) {
						if (utente.gruppo.menuGruppo[0].primaryKey === "homestudiomedico") {
							return "/paziente/view/" + idPaziente;
						} else if (utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
							return "view/" + idPaziente;
						} else {
							return "homepaziente";
						}
					} else {
						return "";
					}
				} else {
					return "";
				}
			} else {
				return "";
			}
		},
		// calcoloEta(value) {
		//     if (value) {
		//         let today = new Date();
		//         let birthDate = new Date(value);
		//         let age = today.getFullYear() - birthDate.getFullYear();
		//         let ageMonth = today.getMonth() - birthDate.getMonth();
		//         let ageDays = today.getDate() - birthDate.getDate();
		//         if (ageMonth < 0 || (ageMonth === 0 && today.getDate() < birthDate.getDate())) {
		//             age--;
		//             ageMonth = 12 + ageMonth;
		//         }

		//         if (ageDays < 0) {
		//             ageMonth--;
		//             ageDays = birthDate.getDate() - today.getDate();
		//         }
		//         return age + " " + this.getLabelTraduora("patient.lblYears") + " " + ageMonth + " " + this.getLabelTraduora("patient.lblMonth") + " " + ageDays + " " + this.getLabelTraduora("patient.lblDays");
		//     } else {
		//         return "---";
		//     }
		// },
		calcoloEta(value) {
			if (value) {
				let today = new Date();
				let birthDate = new Date(value);
				let daAggiungere = this.calcolaDaAggiungere(birthDate.getMonth(), birthDate.getFullYear());
				let age = today.getFullYear() - birthDate.getFullYear();
				let ageMonth = today.getMonth() - birthDate.getMonth();
				let ageDays = today.getDate() - birthDate.getDate();
				if (ageMonth < 0 || (ageMonth === 0 && today.getDate() < birthDate.getDate())) {
					age--;
					ageMonth = 12 + ageMonth;
				}

				if (ageDays < 0) {
					ageMonth--;
					//   console.log(daAggiungere);
					//   console.log(today.getDate(), " ", birthDate.getDate());
					ageDays = today.getDate() - birthDate.getDate() + daAggiungere;
				}
				//return age + " " + this.getLabelTraduora("patient.lblYears") + " " + ageMonth + " " + this.getLabelTraduora("patient.lblMonth") + " " + ageDays + " " + this.getLabelTraduora("patient.lblDays");
				return age + " Anni " + ageMonth + " Mesi " + ageDays + " Giorni";
			} else {
				return "---";
			}
		},
		calcolaDaAggiungere(mese, anno) {
			//   console.log(mese);
			let returnValue = 31;
			//   console.log(returnValue);
			if (mese == 1) {
				if ((anno % 4 == 0 && anno % 100 != 0) || anno % 400 == 0) {
					returnValue = 29;
				} else {
					returnValue = 28;
				}
			} else if (mese == 3 || mese == 5 || mese == 8 || mese == 10) {
				returnValue = 30;
			}
			return returnValue;
		},
		calcoloEtaYearOnly(dataNascita, dataRiferimento = null) {
			if (dataNascita) {
				if (dataRiferimento == null) {
					dataRiferimento = new Date();
				}
				// let today = new Date();
				let birthDate = new Date(dataNascita);
				let age = dataRiferimento.getFullYear() - birthDate.getFullYear();
				let ageMonth = dataRiferimento.getMonth() - birthDate.getMonth();
				let ageDays = dataRiferimento.getDate() - birthDate.getDate();
				if (ageMonth < 0 || (ageMonth === 0 && dataRiferimento.getDate() < birthDate.getDate())) {
					age--;
					ageMonth = 12 + ageMonth;
				}

				if (ageDays < 0) {
					ageMonth--;
					ageDays = birthDate.getDate() - dataRiferimento.getDate();
				}
				return age;
			} else {
				return "---";
			}
		},
		isMaggiorenne(value) {
			let returnValue = false;
			if (value) {
				let today = new Date();
				let birthDate = new Date(value);
				let age = today.getFullYear() - birthDate.getFullYear();
				let ageMonth = today.getMonth() - birthDate.getMonth();
				let ageDays = today.getDate() - birthDate.getDate();
				if (ageMonth < 0 || (ageMonth === 0 && today.getDate() < birthDate.getDate())) {
					age--;
					ageMonth = 12;
				}
				if (ageDays < 0) {
					ageMonth--;
					ageDays = birthDate.getDate() - today.getDate();
				}
				if (age >= 18) {
					returnValue = true;
				}
			}
			return returnValue;
		},
		isOverAge(value, number) {
			let returnValue = false;
			if (value) {
				let today = new Date();
				let birthDate = new Date(value);
				let age = today.getFullYear() - birthDate.getFullYear();
				let ageMonth = today.getMonth() - birthDate.getMonth();
				let ageDays = today.getDate() - birthDate.getDate();
				if (ageMonth < 0 || (ageMonth === 0 && today.getDate() < birthDate.getDate())) {
					age--;
					ageMonth = 12;
				}
				if (ageDays < 0) {
					ageMonth--;
					ageDays = birthDate.getDate() - today.getDate();
				}
				if (age >= number) {
					returnValue = true;
				}
			}
			return returnValue;
		},
		chekCondition(value, condizione) {
			let returnValue = true;
			if (value === condizione) {
				returnValue = false;
			}
			return returnValue;
		},
		checkDoubleCondition(value, firstCondition, secondCondition, logicoperator) {
			let returnValue = true;
			switch (logicoperator) {
				case "AND":
					if (this.chekCondition(value, firstCondition) && this.chekCondition(value, secondCondition)) {
						returnValue = false;
					}
					break;
				case "OR":
					if (!this.chekCondition(value, firstCondition) || !this.chekCondition(value, secondCondition)) {
						returnValue = false;
					}
					break;
			}
			return returnValue;
		},
		restCallGet(link, filtro = null, resultResponse, resultError = null) {
			axios.get(link, { params: filtro })
				.then((response) => {
					resultResponse(response);
				})
				.catch((err) => {
					if (resultError === null) {
						this.$bvModal
							.msgBoxOk(link + " \n" + err.message, {
								title: "ATTENZIONE",
								size: "sm",
								okVariant: "outline-danger",
								headerClass: "sa-msg-header-danger",
								footerClass: "p-2 border-top-0",
								centered: true,
							})
							.then(() => {})
							.catch((err) => {
								console.log(err);
							});
					} else {
						resultError(err);
					}
				});
		},
		restCallPost(link, body, resultResponse, resultError = null) {
			axios.post(link, JSON.stringify(body))
				.then((response) => {
					resultResponse(response);
				})
				.catch((err) => {
					if (resultError === null) {
						this.$bvModal
							.msgBoxOk(link + " \n" + err.message, {
								title: "ATTENZIONE",
								size: "sm",
								okVariant: "outline-danger",
								headerClass: "sa-msg-header-danger",
								footerClass: "p-2 border-top-0",
								centered: true,
							})
							.then(() => {})
							.catch((err) => {
								console.log(err);
							});
					} else {
						resultError(err);
					}
				});
		},
		restCallPut(link, body, resultResponse, resultError = null) {
			axios.put(link, JSON.stringify(body))
				.then((response) => {
					resultResponse(response);
				})
				.catch((err) => {
					if (resultError === null) {
						this.$bvModal
							.msgBoxOk(link + " \n" + err.message, {
								title: "ATTENZIONE",
								size: "sm",
								okVariant: "outline-danger",
								headerClass: "sa-msg-header-danger",
								footerClass: "p-2 border-top-0",
								centered: true,
							})
							.then(() => {})
							.catch((err) => {
								console.log(err);
							});
					} else {
						resultError(err);
					}
				});
		},

		restCallDelete(link, resultResponse, resultError = null) {
			axios.delete(link)
				.then((response) => {
					resultResponse(response);
				})
				.catch((err) => {
					if (resultError === null) {
						this.$bvModal
							.msgBoxOk(link + " \n" + err.message, {
								title: "ATTENZIONE",
								size: "sm",
								okVariant: "outline-danger",
								headerClass: "sa-msg-header-danger",
								footerClass: "p-2 border-top-0",
								centered: true,
							})
							.then(() => {})
							.catch((err) => {
								console.log(err);
							});
					} else {
						resultError(err);
					}
				});
		},

		deleteResource(pathResource, resultResponse, resultError = null) {
			let link = process.env.VUE_APP_PATH_API + pathResource;
			this.restCallDelete(link, resultResponse, resultError);
		},

		saveResource(pathResource, body, resultResponse, resultError = null) {
			let link = process.env.VUE_APP_PATH_API + pathResource;
			if (body.id === "-1" || body.id === null || body.id === undefined) {
				this.restCallPost(link, body, resultResponse, resultError);
			} else {
				link = link + "/" + body.id;
				this.restCallPut(link, body, resultResponse, resultError);
			}
		},

		loadDataResources(pathResource, params, resultResponse, resultError = null) {
			let link = process.env.VUE_APP_PATH_API + pathResource;
			axios.get(link, { params: params })
				.then((response) => {
					resultResponse(response);
				})
				.catch((error) => {
					resultError(error);
				});
		},

		messaggeBoxError(context, error) {
			// console.log(error);
			let messaggio = error.message ? error.message : "Errore Generico";
			if (error.response) {
				if (error.response.data) {
					messaggio = messaggio + ": " + error.response.data.messaggio;
				}
			}
			context.$bvModal
				.msgBoxOk(messaggio, { title: "ATTENZIONE", size: "sm", centered: true, okVariant: "danger", headerBgVariant: "danger" })
				.then(() => {})
				.catch(() => {
					// An error occurred
				});
		},
		getDaysOfWeekArray() {
			let returnValue = [
				{ code: "1", day: "Domenica" },
				{ code: "2", day: "Lunedì" },
				{ code: "3", day: "Martedì" },
				{ code: "4", day: "Mercoledì" },
				{ code: "5", day: "Giovedì" },
				{ code: "6", day: "Venerdì" },
				{ code: "7", day: "Sabato" },
			];

			return returnValue;
		},
	},
};
