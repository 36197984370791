<template>
  <sa-page-layout
    :showModalLoading="showModalLoading"
    :pathResource="pathResource"
    :btnBackVisible="true"
    :btnEditVisible="true"
    :linkedit="linkedit"
    :linkback="linkback"
    :data="anagrafica"
  >
    <template slot="toolbar-title">Profilo ENTE VIEW </template>
    <template slot="table-body"> </template>
  </sa-page-layout>
</template>

<script>
// import axios from "axios";
import SaPageLayout from "../../../template/layout/components/SaPageLayout.vue";
import UtilityMixins from "../../utility/UtilityMixin";
import axios from "axios";
export default {
  mixins: [UtilityMixins],
  props: {
    utente: {
      type: Object,
      default: function () {
        return { idUtente: null };
      },
    },
  },
  components: { SaPageLayout },
  data() {
    return {
      id: null,
      pathResource: "/anagrafiche/",
      linkback: "/lemieprenotazioni",
      linkedit: null,
      anagrafica: {},
      showModalLoading: false,
    };
  },
  mounted() {
    let me = this;
    me.loadData();
    me.linkedit = "/ente" + "/edit" + "/" + me.utente.id;
  },
  watch: {
    utente() {
      let me = this;
      me.loadData();
    },
  },
  methods: {
    loadData() {
      let me = this;
      if (me.utente.id) {
        let link =
          process.env.VUE_APP_PATH_API + me.pathResource + me.utente.id;
        axios.get(link).then((response) => {
          console.log(response.data.data);
          me.anagrafica = response.data.data;
        });
      }
    },
  },
};
</script>

<style></style>
