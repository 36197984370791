<template>
    <b-modal ref="mdlNuovoTeleconsulto" size="xl" title="Nuovo Teleconsulto" @hidden="onCloseModal" @ok="onClickOk" centered no-close-on-backdrop no-close-on-esc hide-header-close>
        <div>
            <b-card class="sa-card" header="Dati Medico" header-tag="header" footer-tag="footer" title="">
                <b-row>
                    <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Nome</label>
                        <b-form-input v-model="jsonData.nomeMedicoPresente" placeholder="Nome"></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Cognome</label>
                        <b-form-input v-model="jsonData.cognomeMedico" placeholder="Cognome"></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                        <label class="sa-label-data">Codice Medico</label>
                        <b-form-input v-model="jsonData.codiceMedicoPresente" placeholder="Codice Medico"></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                        <label class="sa-label-data">Telefono</label>
                        <b-form-input v-model="jsonData.telefonoMedico" placeholder="Telefono"></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                        <label class="sa-label-data">E-Mail</label>
                        <b-form-input v-model="jsonData.emailMedico" placeholder="E-Mail"></b-form-input>
                    </b-col>
                </b-row>
            </b-card>
            <b-card class="sa-card" header="Dati pazienti" header-tag="header" footer-tag="footer" title="">
                <b-row>
                    <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                        <label class="sa-label-data">Username</label>
                        <b-form-input v-model="jsonData.usernameRichiedente" placeholder="Username"></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                        <label class="sa-label-data">Telefono</label>
                        <b-form-input v-model="jsonData.telefonoPaziente" placeholder="Telefono"></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                        <label class="sa-label-data">E-Mail</label>
                        <b-form-input v-model="jsonData.emailPaziente" placeholder="E-Mail"></b-form-input>
                    </b-col>
                </b-row>
            </b-card>
            <b-card class="sa-card" header="Prenotazione" header-tag="header" footer-tag="footer" title="">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <label class="sa-label-data">Data e Ora Prenotazione</label>
                        <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataOraPrenotazione" type="datetime" placeholder="Data e Ora Prenotazione"></date-picker>
                    </b-col>
                </b-row>
            </b-card>
            <b-card class="sa-card" header="Metodo invio" header-tag="header" footer-tag="footer" title="">
                <!-- <h5 class="sa-text-left">{{ this.$i18n.t("videocare.lblSendingMethod") }}</h5> -->
                <b-row>
                    <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                        <label class="sa-label-data">nvito sms</label>
                        <input type="checkbox" id="flat" name="invitoSms" class="switch-input" v-model="jsonData.invitoSms" value="flat" />
                    </b-col>
                    <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Invito E-Mail</label>
                        <input type="checkbox" id="flat" name="invitoEmail" class="switch-input" v-model="jsonData.invitoEmail" value="flat" />
                    </b-col>
                </b-row>
            </b-card>
        </div>
    </b-modal>
</template>

<script>
import UtilityMixin from "../../../utility/UtilityMixin";
import DatePicker from "vue2-datepicker";
export default {
    mixins: [UtilityMixin],
    components: { DatePicker },
    data() {
        return {
            isNew: true,
            isReadonly: false,
            isRequired: false,
            pathResource: "/teleconsultoprenotazioni",

            jsonData: { nomeMedicoPresente: null, idPaziente: null },
        };
    },
    methods: {
        openModal(idPaziente, json) {
            let me = this;
            if (json === null) {
                me.jsonData.idPaziente = idPaziente;
            } else {
                me.jsonData = json;
            }
            me.$refs.mdlNuovoTeleconsulto.show();
        },
        onCloseModal() {
            let me = this;
            me.jsonData = { nomeMedicoPresente: null, idPaziente: null };
        },
        onClickOk(bvModalEvent) {
            let me = this;
            bvModalEvent.preventDefault();
            // console.log(me.jsonData);
            UtilityMixin.methods.saveResource(me.pathResource, me.jsonData, this.saveSuccessResponse, this.saveErrorResponse);
        },
        saveSuccessResponse(response) {
            let me = this;
            me.$emit("afterSaveSuccess", response);
            me.$refs.mdlNuovoTeleconsulto.hide();
            me.$bvToast.toast("Salvataggio avvenuto con successo", {
                title: "Successo",
                variant: "success",
                solid: true,
            });
        },

        saveErrorResponse(response) {
            if (response.response.status === 400) {
                this.$bvModal.msgBoxOk(response.response.data.messaggio, {
                    title: "ATTENZIONE",
                    size: "sm",
                    okVariant: "outline-secondary",
                    headerClass: "sa-msg-header-danger",
                    footerClass: "p-2 border-top-0",
                    centered: true,
                });
            } else {
                this.$bvToast.toast("Errore nel salvataggio" + response.message, {
                    title: "Errore",
                    variant: "danger",
                    solid: true,
                });
            }
        },
    },
};
</script>
