<template>
    <sa-page-layout ref="pgaView" :btnBackVisible="true" :btnEditVisible="true" :titolo="gruppo.nome" :linkback="linkback" :linkedit="linkedit" :showModalLoading="showModalLoading">
        <template slot="table-filter">
            <b-row>
                <b-col cols="12" md="2" class="sa-text-right">Nome:</b-col>
                <b-col cols="12" md="4">
                    <b>{{ gruppo.nome }}</b>
                </b-col>
                <b-col cols="12" md="2" class="sa-text-right">Descrizione:</b-col>
                <b-col cols="12" md="4">
                    <b>{{ gruppo.descrizione }}</b>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <b-tabs content-class="mt-3" class="sa-tab" style="height: 100%" fill>
                <b-tab title="Nuovo Menu" active>
                    <div class="sa-config-menu-tab">
                        <ul class="sa-config-menu">
                            <template v-for="voceMenu in gruppoVociMenu">
                                <li :class="getClassMainManu(voceMenu)" :key="voceMenu.routeId" v-if="isSelect(voceMenu)">
                                    <span class="sa-config-menu-li-span">
                                        <span :class="voceMenu.children.length !== 0 ? 'sa-config-menu-li-icon sa-config-menu-pointer' : 'sa-config-menu-li-icon'"><i :class="'fas fa-' + voceMenu.iconCls"></i></span>
                                        <p>
                                            <span :class="voceMenu.children.length !== 0 ? 'sa-config-menu-li-label sa-config-menu-pointer' : 'sa-config-menu-li-label'">{{ voceMenu.text }}</span>
                                        </p>
                                        <span class="sa-config-menu-submenu-li-check-icon">
                                            <i class="bi bi-check-lg"></i>
                                        </span>
                                    </span>
                                    <ul class="sa-config-menu-submenu">
                                        <template v-for="subitem in voceMenu.children">
                                            <li :key="subitem.routeId" class="sa-config-menu-submenu-li" v-if="subitem.selezionato">
                                                <span>
                                                    <span class="sa-config-menu-submenu-li-icon"><i :class="'fas fa-' + subitem.iconCls"></i></span>
                                                    <p>
                                                        <span class="sa-config-menu-submenu-li-label">{{ subitem.text }}</span>
                                                    </p>
                                                    <span class="sa-config-menu-submenu-li-check-icon">
                                                        <i class="bi bi-check-lg"></i>
                                                    </span>
                                                </span>
                                            </li>
                                        </template>
                                    </ul>
                                </li>
                            </template>
                        </ul>
                    </div>
                </b-tab>
                <!-- <b-tab title="Menu">
                    <div class="b-table-sticky-header">
                        <b-table striped hover :items="gruppo.menu" :fields="fieldsMenu" head-variant="light" sort-icon-left>
                            <template v-slot:cell(iconCls)="{ item }">
                                <i :class="'fas fa-' + item.iconCls"></i>
                            </template>
                            <template v-slot:cell(text)="{ item }">
                                <div>
                                    <span class="sa-text-bold">{{ item.text }} ({{ item.routeId }})</span>
                                </div>
                                <div>
                                    <span>{{ item.view }}</span>
                                </div>
                            </template>
                            <template v-slot:cell(selected)="{ item }">
                                <b-icon v-if="item.selezionato" icon="check-square-fill" variant="success"></b-icon>
                                <b-icon v-else icon="x-square-fill" variant="danger"></b-icon>
                            </template>
                        </b-table>
                    </div>
                </b-tab> -->

                <b-tab title="Permessi">
                    <!-- <b-row>
                        <b-col lg="12" class="my-1">
                            <b-form-group label="" label-cols-sm="0" label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0">
                                <b-input-group size="sm">
                                    <b-form-input v-model="filter" type="search" id="filterInput" placeholder="Nome"></b-form-input>
                                    <b-input-group-append>
                                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                    </b-row> -->
                    <div class="b-table-sticky-header">
                        <b-table striped hover :filter="filter" :filterIncludedFields="filterOn" :items="gruppo.sezioni" :fields="fieldsSezioni" head-variant="light" sort-icon-left>
                            <template v-slot:cell(permessiSezione)="{ item }">
                                <div v-for="(permesso, index) in item.permessi" :key="index">
                                    <div v-if="permesso.attivo">
                                        <b-icon icon="check-square-fill" variant="success"></b-icon>
                                        &nbsp;{{ permesso.descrizione }}
                                    </div>
                                    <div v-else>
                                        <b-icon icon="x-square-fill" variant="danger"></b-icon>
                                        &nbsp;{{ permesso.descrizione }}
                                    </div>

                                    <!--<b-form-checkbox v-model="permesso.attivo" size="lg" switch class="sa-normal-label">{{ permesso.descrizione }}</b-form-checkbox>-->
                                </div>
                            </template>
                        </b-table>
                    </div>
                </b-tab>
            </b-tabs>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
//import { SaModalLoading } from "../../../components/modal";
export default {
    components: { SaPageLayout },
    label: "Dettagllio Gruppo",
    data() {
        return {
            gruppo: { nome: "", descrizioneGruppo: "", menu: [], sezioni: [] },
            label: "Dettagllio Gruppo",
            linkback: "/gruppi",
            linkedit: null,
            filter: null,
            showModalLoading: false,
            filterOn: [],
            vociMenu: [],
            gruppoVociMenu: [],
            fieldsMenu: [
                {
                    label: "",
                    key: "iconCls",
                    sortable: false,
                    class: "sa-col-icon",
                },
                {
                    label: "Menu",
                    key: "text",
                    sortable: false,
                },
                {
                    label: "Padre",
                    key: "idPadre",
                    sortable: false,
                },
                {
                    key: "selected",
                    label: "",
                    sortable: false,
                    class: "sa-width-2 sa-td-vertical-middle",
                },
            ],
            fieldsSezioni: [
                {
                    label: "Sezione",
                    key: "descrizione",
                    sortable: true,
                },
                {
                    label: "Permessi",
                    key: "permessiSezione",
                    sortable: false,
                    class: "sa-width-12",
                },
            ],
        };
    },
    mounted: function () {
        let me = this;
        me.showModalLoading = true;
        me.linkedit = "/gruppi/edit/" + me.$route.params.id;
        me.loadData(me.$route.params.id);
    },
    methods: {
        isSelect(item) {
            return item.selezionato;
        },
        verificaPermessoSezione(permessoSezione, permessi) {
            let returnValue = [];
            permessi.forEach((permesso) => {
                if (permesso.valore & permessoSezione) {
                    returnValue.push(permesso);
                }
            });

            return returnValue;
        },
        loadData(id) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + "/vocimenu";
            axios.get(link).then((response) => {
                me.menu = response.data.data;
                link = process.env.VUE_APP_PATH_API + "/gruppi/";
                axios.get(link + id).then((response) => {
                    me.gruppo = response.data.data;
                    me.vociMenu = me.gruppo.menu.map((obj) => ({ ...obj }));
                    me.raggruppaVociMenu(null);
                    me.showModalLoading = false;
                });
            });
        },
        raggruppaVociMenu(idPadre) {
            let me = this;
            me.vociMenu.forEach((element) => {
                if (element.idPadre === idPadre) {
                    element.children = [];
                    me.setMenuChildren(element);
                    me.gruppoVociMenu.push(element);
                }
            });
        },
        setMenuChildren(voceMenu) {
            let me = this;
            me.vociMenu.forEach((element) => {
                if (element.idPadre === voceMenu.routeId) {
                    element.children = [];
                    voceMenu.children.push(element);
                }
            });
        },
        setMenuClass(hrmlelement) {
            // let hrmlelement = e.target.parentElement.parentElement.parentElement;
            let elementNumber = hrmlelement.getElementsByClassName("sa-config-menu-submenu")[0].children.length;

            if (hrmlelement.classList.contains("sa-config-menu-li-open")) {
                hrmlelement.getElementsByClassName("sa-config-menu-submenu")[0].style.height = "0px";
                hrmlelement.classList.remove("sa-config-menu-li-open");
            } else {
                hrmlelement.getElementsByClassName("sa-config-menu-submenu")[0].style.height = elementNumber * 42 + "px";
                hrmlelement.classList.add("sa-config-menu-li-open");
            }
        },
        getClassMainManu(voceMenu) {
            let returnValue = "sa-config-menu-li";
            voceMenu.children.forEach((element) => {
                if (element.selezionato) {
                    returnValue = "sa-config-menu-li sa-config-menu-li-open";
                }
            });
            return returnValue;
        },
    },
};
</script>
<style>
.sa-text-right {
    text-align: right;
}
@media only screen and (max-width: 767px) {
    .sa-text-right {
        text-align: left;
    }
}
.card {
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
    margin-bottom: 1rem;
}
.sa-config-menu-submenu-li-check-icon {
    width: 42px;
    font-size: 1.6rem;
    text-align: center;
    padding-top: 5px;
    padding-left: 15px;
    color: green;
}
</style>
