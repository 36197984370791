<template>
  <sa-page-layout
    :showModalLoading="showModalLoading"
    :pathResource="pathResource"
    :btnBackVisible="true"
    :btnEditVisible="true"
    :linkedit="linkedit"
    :linkback="linkback"
    :data="anagrafica"
  >
    <template slot="toolbar-title">Profilo </template>
    <template slot="table-body">
      <div class="sa-div-scroll sa-scroll">
        <b-row>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-form-label" for="anagrafica-celullare">
              Username</label
            >
            <br />
            <span id="anagrafica-celullare" class="sa-label-data">{{
              utente.username
            }}</span>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-form-label" for="anagrafica-celullare">
              Cellulare</label
            >
            <br />
            <span id="anagrafica-celullare" class="sa-label-data">{{
              anagrafica.cellulare
            }}</span>
          </b-col>

          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-form-label" for="anagrafica-email">Email</label>
            <br />
            <span id="anagrafica-email" class="sa-label-data">
              {{ anagrafica.email }}</span
            >
          </b-col>
        </b-row>
        <br />
        <h5>Dettaglio Anagrafica</h5>
        <hr />
        <br />
        <b-row>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-form-label" for="anagrafica-cognome"
              >Cognome</label
            >
            <br />
            <span id="anagrafica-cognome" class="sa-label-data">{{
              anagrafica.cognome
            }}</span>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-form-label" for="anagrafica-nome">Nome</label>
            <br />
            <span id="anagrafica-nome" class="sa-label-data">{{
              anagrafica.nome
            }}</span>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-form-label" for="anagrafica-data-nascita"
              >Data Di Nascita</label
            >
            <br />
            <span id="anagrafica-nome-data-nascita" class="sa-label-data">{{
              formatDate(anagrafica.dataNascita)
            }}</span>
          </b-col>
        </b-row>
        <br />
        <b-row>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-form-label" for="anagrafica-provincia-nascita"
              >Provincia</label
            >
            <br />
            <span id="anagrafica-provincia-nascita" class="sa-label-data">{{
              anagrafica.provinciaNascitaLabel
            }}</span>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-form-label" for="anagrafica-identificativo"
              >Comune</label
            >
            <br />
            <span id="anagrafica-comune-nascita" class="sa-label-data">{{
              anagrafica.comuneNascitaLabel
            }}</span>
          </b-col>
        </b-row>
        <br />
        <b-row>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-form-label" for="anagrafica-tipo-identificativo"
              >Tipo Identificativo</label
            >
            <br />
            <span id="anagrafica-tipoIdentificativo" class="sa-label-data">{{
              anagrafica.tipoIdentificativo
            }}</span>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-form-label" for="anagrafica-identificativo"
              >Identificativo</label
            >
            <br />
            <span id="anagrafica-identificativo" class="sa-label-data">{{
              anagrafica.identificativo
            }}</span>
          </b-col>
        </b-row>
        <br />
        <h5>Domicilio</h5>
        <hr />
        <b-row>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-form-label" for="anagrafica-indirizzo"
              >Indirizzo
            </label>
            <br />
            <span id="anagrafica-indirizzo" class="sa-label-data">{{
              anagrafica.indirizzoDomicilio
            }}</span>
          </b-col>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-form-label" for="anagrafica-civico">Civico </label>
            <br />
            <span id="anagrafica-civico" class="sa-label-data">{{
              anagrafica.civicoDomicilio
            }}</span>
          </b-col>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <label for="anagrafica-provincia">Provincia </label>
            <br />
            <span id="anagrafica-provincia" class="sa-label-data">{{
              anagrafica.provinciaDomicilioLabel
            }}</span>
          </b-col>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-form-label" for="anagrafica-comune">Comune </label>
            <br />
            <span id="anagrafica-comune" class="sa-label-data">{{
              anagrafica.comuneDomicilioLabel
            }}</span>
          </b-col>
        </b-row>
        <br />
        <b-row>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3" class="sa-element-data">
            <label class="sa-form-label" for="anagrafica-frazione"
              >Frazione
            </label>
            <br />
            <span id="anagrafica-frazione" class="sa-label-data">{{
              anagrafica.frazioneDomicilio
            }}</span>
          </b-col>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-form-label" for="anagrafica-cap">C.A.P. </label>
            <br />
            <span id="anagrafica-cap" class="sa-label-data">{{
              anagrafica.capDomicilio
            }}</span>
          </b-col>
        </b-row>
        <br />
        <b-row>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-form-label" for="anagrafica-latitudine"
              >Latitudine</label
            >
            <br />
            <span
              v-if="anagrafica.indirizzoDomicilioLongitudine"
              id="anagrafica-latitudine-domicilio"
              class="sa-label-data"
            >
              {{ anagrafica.indirizzoDomicilioLongitudine }}</span
            >
            <span
              v-else
              id="anagrafica-latitudine-domicilio"
              class="sa-label-data"
              >-</span
            >
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-form-label" for="anagrafica-longitudine"
              >Longitudine</label
            >
            <br />
            <span
              v-if="anagrafica.indirizzoDomicilioLatitudine"
              id="anagrafica-longitudine-domicilio"
              class="sa-label-data"
              >{{ anagrafica.indirizzoDomicilioLatitudine }}</span
            >
            <span
              v-else
              id="anagrafica-latitudine-domicilio"
              class="sa-label-data"
              >-</span
            >
          </b-col>
        </b-row>
        <br />
        <h5>Residenza</h5>
        <hr />
        <b-row>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-form-label" for="anagrafica-indirizzo"
              >Indirizzo
            </label>
            <br />
            <span id="anagrafica-indirizzo" class="sa-label-data">{{
              anagrafica.indirizzoResidenza
            }}</span>
          </b-col>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-form-label" for="anagrafica-civico">Civico </label>
            <br />
            <span id="anagrafica-civico" class="sa-label-data">{{
              anagrafica.civicoResidenza
            }}</span>
          </b-col>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <label for="anagrafica-provincia">Provincia </label>
            <br />
            <span id="anagrafica-provincia" class="sa-label-data">{{
              anagrafica.provinciaResidenzaLabel
            }}</span>
          </b-col>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-form-label" for="anagrafica-comune">Comune </label>
            <br />
            <span id="anagrafica-comune" class="sa-label-data">{{
              anagrafica.comuneResidenzaLabel
            }}</span>
          </b-col>
        </b-row>
        <br />
        <b-row>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3" class="sa-element-data">
            <label class="sa-form-label" for="anagrafica-frazione"
              >Frazione
            </label>
            <br />
            <span id="anagrafica-frazione" class="sa-label-data">{{
              anagrafica.frazioneResidenza
            }}</span>
          </b-col>

          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-form-label" for="anagrafica-cap">C.A.P. </label>
            <br />
            <span id="anagrafica-cap" class="sa-label-data">{{
              anagrafica.capResidenza
            }}</span>
          </b-col>
        </b-row>
        <br />
        <b-row>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-form-label" for="anagrafica-latitudine"
              >Latitudine</label
            >
            <br />
            <span
              v-if="anagrafica.indirizzoResidenzaLatitudine"
              id="anagrafica-latitudine"
              class="sa-label-data"
              >{{ anagrafica.indirizzoResidenzaLatitudine }}</span
            >
            <span v-else id="anagrafica-latitudine " class="sa-label-data"
              >-</span
            >
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-form-label" for="anagrafica-longitudine"
              >Longitudine</label
            >
            <br />
            <span
              v-if="anagrafica.indirizzoResidenzaLongitudine"
              id="anagrafica-longitudine"
              class="sa-label-data"
              >{{ anagrafica.indirizzoResidenzaLongitudine }}</span
            >
            <span v-else id="anagrafica-latitudine" class="sa-label-data"
              >-</span
            >
          </b-col>
        </b-row>
      </div>
    </template>
  </sa-page-layout>
</template>

<script>
// import axios from "axios";
import SaPageLayout from "../../../template/layout/components/SaPageLayout.vue";
import UtilityMixins from "../../utility/UtilityMixin";
import axios from "axios";
export default {
  mixins: [UtilityMixins],
  props: {
    utente: {
      type: Object,
      default: function () {
        return { idUtente: null };
      },
    },
  },
  components: { SaPageLayout },
  data() {
    return {
      id: null,
      pathResource: "/anagrafiche/",
      linkback: "/lemieprenotazioni",
      linkedit: null,
      anagrafica: {},
      showModalLoading: false,
    };
  },
  mounted() {
    let me = this;
    me.loadData();
    me.linkedit = "/profilo" + "/edit" + "/" + me.utente.id;
  },
  // created() {
  //   if (this.utente.profilo === "Utente") {
  //     console.log(this.$route);
  //     console.log(this.$router);
  //     this.$router.push({ path: "profiloente/view/", params: { id: this.id } });
  //   }
  // },
  watch: {
    utente() {
      let me = this;
      me.loadData();
    },
  },
  methods: {
    loadData() {
      let me = this;
      if (me.utente.id) {
        let link =
          process.env.VUE_APP_PATH_API + me.pathResource + me.utente.id;
        axios.get(link).then((response) => {
          console.log(response.data.data);
          me.anagrafica = response.data.data;
        });
      }
    },
  },
};
</script>

<style></style>
