<template>
  <sa-page-layout :showModalLoading="showModalLoading" :pathResource="pathResource" :linkedit="linkedit" :btnBackVisible="true" :btnEditVisible="true" :linkback="linkback" :data="jsonData">
    <template slot="toolbar-title">Dettagli Ente</template>
    <template slot="table-body">
      <div class="sa-div-scroll sa-scroll">
        <ente-view-component :jsonData="jsonData"></ente-view-component>
      </div>
    </template>
  </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../template/layout/components/SaPageLayout.vue";
import UtilityMixins from "../../utility/UtilityMixin";
import EnteViewComponent from "../components/EntiViewComponent.vue";
import axios from "axios";
export default {
  mixins: [UtilityMixins],
  components: { SaPageLayout, EnteViewComponent },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.linkedit = me.linkback + "/edit/" + me.id;
    me.loadData();
  },
  data() {
    return {
      linkback: "/enti",
      linkedit: null,
      pathResource: "/enti",
      showModalLoading: false,
      regioniOptions: [],
      jsonData: {},
    };
  },

  methods: {
    loadData() {
      let me = this;
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        me.showModalLoading = true;
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
        axios
          .get(link + me.id)
          .then((response) => {
            me.jsonData = response.data.data;
            me.showModalLoading = false;
          })
          .catch(() => {
            me.showModalLoading = false;
          });
      }
    },
  },
};
</script>

<style></style>
