var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sa-list-component"},[_c('div',{staticClass:"sa-list-component-header"},[_c('b-row',{staticClass:"sa-label-info",staticStyle:{"margin-left":"0.5rem","margin-right":"0.5rem"}},[_c('b-col',{attrs:{"lg":"3"}},[_c('p',[_vm._v(_vm._s(this.$i18n.t("global.lblTotalRecords"))+": "+_vm._s(_vm.rows))])]),_c('b-col',{attrs:{"lg":"9"}},[_c('b-pagination',{attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage,"align":"right","size":"sm"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1),_c('div',{staticClass:"sa-list-component-body"},[_c('div',{staticClass:"b-table-sticky-header"},[_c('b-table',{key:_vm.listKey,ref:"table",staticClass:"sa-b-table",attrs:{"sticky-header":"","stacked":"lg","striped":"","hover":"","items":_vm.items,"fields":_vm.fields,"current-page":1,"per-page":_vm.perPage,"sort-icon-left":"","head-variant":"light"},scopedSlots:_vm._u([{key:"cell(identificativo)",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"sa-edit-link",attrs:{"to":'/cot/richieste/edit/' + item.id}},[_vm._v(_vm._s(item.identificativo))])]}},{key:"cell(nominativo)",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.cognome)+" "+_vm._s(item.nome))]}},{key:"cell(data)",fn:function(ref){
var item = ref.item;
return [(item.statoAttuale !== 'NON INOLTRATA')?_c('span',[_vm._v(_vm._s(_vm.formatDate(item.statoAttualeDataOra)))]):_c('span',[_vm._v("---")])]}},{key:"cell(provenienzaNominativoMedico)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"sa-label-data-secondary"},[_vm._v(_vm._s(item.provenienzaDescrizioneStruttura))]),_c('span',{staticClass:"sa-label-data-secondary"},[_vm._v(_vm._s(item.provenienzaDescrizioneReparto))])]}}])})],1)]),_c('div',{staticClass:"sa-list-component-footer"},[_c('b-row',{staticClass:"sa-label-info",staticStyle:{"margin-left":"0.5rem","margin-right":"0.5rem"}},[_c('b-col',{attrs:{"lg":"3"}},[_c('p',[_vm._v(_vm._s(this.$i18n.t("patients.lblTotalRecords"))+": "+_vm._s(_vm.rows))])]),_c('b-col',{attrs:{"lg":"9"}},[_c('b-pagination',{attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage,"align":"right","size":"sm"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }