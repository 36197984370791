<template>
	<div>
		<div v-for="(elemnt, index) in jsonData" :key="index">
			{{ elemnt }}
			<label>{{ elemnt.label }}:</label>
			<input v-model="elemnt.name" placeholder="Enter name" />
			<!--<label>Email:</label>
			<input v-model="elemnt.email" placeholder="Enter email" /> -->
			<button @click="removeContact(index)">Remove</button>
		</div>
	</div>
</template>

<script>
export default {
	props: { jsonData: { type: Array } },
};
</script>

<style></style>
