<template>
    <b-modal title="Scheda Mobilità" ref="mdlRsaSchedaAreaMobilita" @ok="onSalvaAreaMobilita" @hidden="onCloseModal" centered no-close-on-backdrop no-close-on-esc hide-header-close size="lg">
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card sa-card-border" header="Valutazione" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Autonomia nella mobilità</label>
                            <b-form-select v-model="jsonDataAreaMobilita.mobilitaAutonoma" :disabled="isReadOnly" :options="mobilitaAutonomaOptions">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Equilibrio</label>
                            <b-form-select v-model="jsonDataAreaMobilita.equilibrio" :disabled="isReadOnly" :options="equilibrioOptions">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Controllo del tronco</label>
                            <b-form-select v-model="jsonDataAreaMobilita.controlloTronco" :disabled="isReadOnly" :options="controlloTroncoOptions">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Tono</label>
                            <b-form-select v-model="jsonDataAreaMobilita.tono" :disabled="isReadOnly" :options="tonoOptions">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Trasferimenti e cambi di postura</label>
                            <b-form-select v-model="jsonDataAreaMobilita.trasferimentiCambiPostura" :disabled="isReadOnly" :options="trasferimentiCambiPosturaOptions">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Dolore</label>
                            <b-form-select v-model="jsonDataAreaMobilita.dolore" :disabled="isReadOnly" :options="doloreOptions">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Sede del dolore</label>
                            <b-form-input v-model="jsonDataAreaMobilita.sedeDolore" :readonly="isReadOnly" :disabled="jsonDataAreaMobilita.dolore !== 'SI' || isReadOnly"> </b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Intensità del dolore: {{ jsonDataAreaMobilita.intensitaDolore }}</label>
                            <b-form-input v-model="jsonDataAreaMobilita.intensitaDolore" :disabled="jsonDataAreaMobilita.dolore !== 'SI' || isReadOnly" type="range" min="0" max="10" step="1">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-input>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Note</label>
                            <b-form-textarea v-model="jsonDataAreaMobilita.note" :readonly="isReadOnly" max-rows="10" rows="7"></b-form-textarea>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <template #modal-footer>
            <b-button v-if="!isReadOnly" variant="outline-danger" @click="$refs.mdlRsaSchedaAreaMobilita.hide()">Annulla</b-button>
            <b-button v-if="!isReadOnly" variant="outline-success" @click="onSalvaAreaMobilita()">Salva</b-button>
            <b-button v-if="isReadOnly" variant="outline-primary" @click="$refs.mdlRsaSchedaAreaMobilita.hide()"> Chiudi </b-button>
        </template>
    </b-modal>
</template>
<script>
import UtilityMixin from "../../../utility/UtilityMixin";

export default {
    mixins: [UtilityMixin],
    mounted() {
        let me = this;
        me.idAccettazione = me.$route.query.idPaziente;
        //console.log("ID Accettazione da mounted:", me.idAccettazione);
    },
    data() {
        return {
            pathResourceAreaMobilita: "/rsaareamobilita",
            pathResourceCheckAreaMobilita: "/rsaareamobilita/check",
            intensitaDoloreOptions: [
                { text: "1", value: "1" },
                { text: "2", value: "2" },
                { text: "3", value: "3" },
                { text: "4", value: "4" },
                { text: "5", value: "5" },
                { text: "6", value: "6" },
                { text: "7", value: "7" },
                { text: "8", value: "8" },
                { text: "9", value: "9" },
                { text: "10", value: "10" },
            ],
            mobilitaAutonomaOptions: [
                { text: "NORMALE", value: "NORMALE" },
                { text: "SUPERVISIONE", value: "SUPERVISIONE" },
                { text: "ASSISTENZA", value: "ASSISTENZA" },
                { text: "NON DEAMBULANTE", value: "NON DEAMBULANTE" },
            ],
            equilibrioOptions: [
                { text: "STABILE", value: "INSTABILE" },
                { text: "INSTABILE", value: "INSTABILE" },
            ],
            controlloTroncoOptions: [
                { text: "SEDUTO", value: "SEDUTO" },
                { text: "STAZIONE ERETTA", value: "STAZIONE ERETTA" },
                { text: "NO CONTROLLO", value: "NO CONTROLLO" },
            ],
            tonoOptions: [
                { text: "NORMOTONICO", value: "NORMOTONICO" },
                { text: "IPOTONICO", value: "IPOTONICO" },
                { text: "IPERTONICO", value: "IPERTONICO" },
            ],
            trasferimentiCambiPosturaOptions: [
                { text: "AUTONOMO", value: "AUTONOMO" },
                { text: "NON AUTONOMO", value: "NON AUTONOMO" },
            ],
            doloreOptions: [
                { text: "SI", value: "SI" },
                { text: "NO", value: "NO" },
            ],

            jsonDataAreaMobilita: {
                id: null,
                idAccettazione: null,
                mobilitaAutonoma: null,
                equilibrio: null,
                controlloTronco: null,
                tono: null,
                trasferimentiCambiPostura: null,
                dolore: null,
                sedeDolore: null,
                intensitaDolore: null,
                note: null,
            },
            isReadOnly: false,
        };
    },

    methods: {
        nuovaAreaMobilita() {
            let me = this;
            me.isReadOnly = false;
            UtilityMixin.methods.loadDataResources(me.pathResourceAreaMobilita, { idAccettazione: me.idAccettazione }, me.loadAreaMobilitaDataSuccess, me.loadAreaMobilitaDataError);
        },
        loadAreaMobilitaDataSuccess(response) {
            let me = this;
            if (response.data?.data?.list?.length > 0) {
                me.jsonDataAreaMobilita = response.data.data.list[0];
            } else {
                me.jsonDataAreaMobilita = {
                    id: null,
                    idAccettazione: me.idAccettazione,
                    mobilitaAutonoma: null,
                    equilibrio: null,
                    controlloTronco: null,
                    tono: null,
                    trasferimentiCambiPostura: null,
                    dolore: null,
                    sedeDolore: null,
                    intensitaDolore: null,
                    note: null,
                };
            }
            me.$refs.mdlRsaSchedaAreaMobilita.show();
        },
        loadAreaMobilitaDataError(error) {
            let me = this;
            console.error("Errore nel caricamento dei dati: ", error);
            me.jsonDataAreaMobilita = {
                id: null,
                idAccettazione: me.idAccettazione,
                mobilitaAutonoma: "",
                equilibrio: "",
                controlloTronco: "",
                tono: "",
                trasferimentiCambiPostura: "",
                dolore: "",
                sedeDolore: "",
                intensitaDolore: "",
                note: "",
            };
            me.$refs.mdlRsaSchedaAreaMobilita.show();
        },

        visualizzaAreaMobilita() {
            let me = this;
            me.isReadOnly = true;
            UtilityMixin.methods.loadDataResources(me.pathResourceAreaMobilita, { idAccettazione: me.idAccettazione }, me.loadAreaMobilitaDataViewSuccess, me.loadAreaMobilitaDataViewError);
        },
        loadAreaMobilitaDataViewSuccess(response) {
            let me = this;

            if (response.data?.data?.list?.length > 0) {
                me.jsonDataAreaMobilita = response.data.data.list[0];
                me.$refs.mdlRsaSchedaAreaMobilita.show();
            } else {
                me.$bvToast.toast("Nessun record trovato per questa accettazione", {
                    title: "Informazione",
                    variant: "info",
                    solid: true,
                });
            }
            me.$emit("afterLoadData");
        },
        loadAreaMobilitaDataViewError(error) {
            let me = this;
            console.error("Errore nel caricamento:", error);
            me.$bvToast.toast("Errore nel caricamento dei dati", {
                title: "Errore",
                variant: "danger",
                solid: true,
            });
            me.$emit("afterLoadData");
        },
        onCloseModal() {
            let me = this;
            me.jsonDataAreaMobilita = {};
            me.$emit("onClose");
        },
        onSalvaAreaMobilita() {
            let me = this;
            if (!me.jsonDataAreaMobilita.idAccettazione) {
                me.$bvToast.toast("Errore: ID Accettazione mancante", {
                    title: "Errore",
                    variant: "danger",
                    solid: true,
                });
                return;
            }

            UtilityMixin.methods.saveResource(me.pathResourceAreaMobilita, me.jsonDataAreaMobilita, me.saveAreaMobilitaSuccessResponse, me.saveAreaMobilitaErrorResponse);
        },

        saveAreaMobilitaSuccessResponse() {
            let me = this;
            me.$emit("afterSaveSuccess");
            me.$refs.mdlRsaSchedaAreaMobilita.hide();
            /*  console.log("idAccettazione: ", me.jsonDataSchedaSocialePaziente.idAccettazione); */ //PERCHE SE LO USO MI VA IN ERRORE E NON MI TROVA RESPONSE.
            me.$bvToast.toast("Salvataggio avvenuto con successo", {
                title: "Successo",
                variant: "success",
                solid: true,
            });
        },

        saveAreaMobilitaErrorResponse(response) {
            if (response.response.status === 400) {
                this.$bvModal.msgBoxOk(response.response.data.messaggio, {
                    title: "ATTENZIONE",
                    size: "sm",
                    okVariant: "outline-secondary",
                    headerClass: "sa-msg-header-danger",
                    footerClass: "p-2 border-top-0",
                    centered: true,
                });
            } else {
                this.$bvToast.toast("Errore nel salvataggio in edit component" + response.message, {
                    title: "Errore",
                    variant: "danger",
                    solid: true,
                });
            }
        },
    },
};
</script>
