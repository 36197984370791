<template>
    <div>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Data Esordio Malattia</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataEsordioMalattia) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Sintomo di Esordio</label>
                    <span class="sa-data">{{ jsonData.sintomoEsordio }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="SINTOMI" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Disturbi Psichiatrici Nelle Prime Fasi Della Malattia</label>
                    <span class="sa-data">{{ jsonData.disturbiPsichiatriciPrimaFase }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Decadimento intellettivo-demenza</label>
                    <span class="sa-data">{{ jsonData.decadimentoIntellettivoDemenza }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Mioclono</label>
                    <span class="sa-data">{{ jsonData.mioclono }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Altri Movimenti Involontari</label>
                    <span class="sa-data">{{ jsonData.altriMovimentiInvolontari }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Segni Piramidali</label>
                    <span class="sa-data">{{ jsonData.segniPiramidali }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Segni Extrapiramidali</label>
                    <span class="sa-data">{{ jsonData.segniExtrapiramidali }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Segni Cerebellari</label>
                    <span class="sa-data">{{ jsonData.segniCerebellari }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Segni Visivi</label>
                    <span class="sa-data">{{ jsonData.segniVisivi }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Mutismo Acinetico</label>
                    <span class="sa-data">{{ jsonData.mutismoAcinetico }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Parestesie</label>
                    <span class="sa-data">{{ jsonData.parestesie }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Disentesie Dolorose</label>
                    <span class="sa-data">{{ jsonData.disentesieDolorose }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">EEG Caratteristico</label>
                    <span class="sa-data">{{ jsonData.eegCaratteristico }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Esame Liquorale (proteina 14-3-3)</label>
                    <span class="sa-data">{{ jsonData.esameLiquorale }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="ALTRI ESAMI STRUMENTALI EFFETTUATI" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">TC Cerebrale</label>
                    <span class="sa-data">{{ jsonData.tcCerebrale }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">RMN Cerebrale</label>
                    <span class="sa-data">{{ jsonData.rmnCerebrale }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Altre Considerazioni</label>
                    <span class="sa-data">{{ jsonData.altreConsiderazioni }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Dati Notifica" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Sanitario Notificante</label>
                    <span class="sa-data">{{ jsonData.sanitarioNotificante }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Telefono Sanitario Notificante</label>
                    <span class="sa-data">{{ jsonData.sanitarioNotificanteContatto }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Notifica</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataNotifica) }}</span>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
<script>
import axios from "axios";
import UtilityMixin from "../../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixin],
    props: {},
    data() {
        return {
            pathResource: "/malattieinfettiveiemalattiacreutzfeldtjakob",
            id: "-1",
            currentPage: 1,
            perPage: 100,
            jsonData: {},
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.$emit("showmodalloading", true);
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            axios
                .get(link + me.id)
                .then((response) => {
                    me.jsonData = response.data.data;
                    me.$emit("showmodalloading", false);
                })
                .catch(() => {
                    me.$emit("showmodalloading", false);
                });
        },
    },
};
</script>
