import AgendeListPage from "./pages/AgendeListPage.vue";
import AgendaEditPage from "./pages/AgendaEditPage.vue";
import AgendaViewPage from "./pages/AgendaViewPage.vue";

// import Agenda from "./components/Agenda.vue";

//import Month from "./components/Month.vue";
// import GruppoEdit from "./components/GruppoEdit.vue";
// import GruppoView from "./components/GruppoView.vue";

export default {
    AgendeListPage,
    AgendaEditPage,
    // Agenda,
    AgendaViewPage,
};
