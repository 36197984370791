<template>
    <b-modal ref="mdlEditPostiLettoStanze" :title="isNew ? 'Nuova Stanza' : 'Aggiorna Stanza'" @hidden="onCloseModal" @ok="onClickOk" centered no-close-on-backdrop no-close-on-esc hide-header-close>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data">Struttura</label>
                <b-form-select v-model="jsonData.codiceStruttura" class="mb-2 mr-sm-2 mb-sm-0" :options="strutturaOptions" :value="null" value-field="codiceStruttura" text-field="denominazione" @input="onInputStruttura"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data">Presidio</label>
                <b-form-select v-model="jsonData.idPresidio" class="mb-2 mr-sm-2 mb-sm-0" :options="presidiOptions" :value="null" value-field="id" text-field="denominazione" @input="onInputPresidi"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data">Piano</label>
                <b-form-select v-model="jsonData.idPiano" class="mb-2 mr-sm-2 mb-sm-0" :options="pianiOptions" :value="null" value-field="id" text-field="nome" @input="onInputPresidi"></b-form-select>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <label class="sa-label-data">Codice Reparto</label>
                <b-form-input v-model="jsonData.codiceReparto" placeholder="Codice Reparto"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <label class="sa-label-data">Descrizione Reparto</label>
                <b-form-input v-model="jsonData.descrizioneReparto" placeholder="Descrizione Reparto"></b-form-input>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <label class="sa-label-data">Nome</label>
                <b-form-input v-model="jsonData.nome" placeholder="Nome della Stanza"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <label class="sa-label-data">Sesso</label>
                <b-form-select v-model="jsonData.sesso" class="mb-2 mr-sm-2 mb-sm-0" :options="sessoOptions" :value="null" value-field="codice" text-field="descrizione" @input="onInputSesso"></b-form-select>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <label class="sa-label-data">Note</label>
                <b-form-textarea v-model="jsonData.note" rows="5"></b-form-textarea>
            </b-col>
        </b-row>
    </b-modal>
</template>

<script>
import axios from "axios";
import UtilityMixin from "../../../utility/UtilityMixin";
export default {
    props: {
        idPiano: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            strutturaOptions: [],
            presidiOptions: [{ id: null, denominazione: "- Seleziona il Presidio -" }],
            pianiOptions: [{ id: null, nome: "-Seleziona Piano-" }],
            sessoOptions: [],
            pathResource: "/rsapostilettostanze",
            pathResourceSesso: "/rsapostilettostanzasessi",
            pathResourceStrutture: "/strutture",
            pathResourcePresidi: "/strutturepresidi",
            pathResourcePiani: "/rsapostilettopiani",
            isNew: true,
            jsonData: { codiceStruttura: null, idPresidio: null, idPiano: null, codiceReparto: null, descrizioneReparto: null, nome: null, sesso: null, note: null },
            piano: {},
        };
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    mounted: function () {
        let me = this;
        // me.id = this.$route.params.id;
        me.loadSesso();
        me.loadStrutture();
        /*  if (me.idPiano) {
      me.loadPiani(); 
    } else {
      me.loadStrutture(); 
    } */
    },
    methods: {
        loadStrutture() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceStrutture;
            axios.get(link).then((response) => {
                me.strutturaOptions = response.data.data;
                me.strutturaOptions.unshift({ codiceStruttura: null, denominazione: this.$i18n.t("clinic.lblSelectStructure") });
                //me.loadData();
            });
        },
        loadPresidi() {
            let me = this;
            let idStruttura = me.getIdStrutturaByCodiceStruttura(me.jsonData.codiceStruttura);
            if (me.jsonData.codiceStruttura) {
                let link = process.env.VUE_APP_PATH_API + me.pathResourcePresidi + "?idStruttura=" + idStruttura;
                axios.get(link).then((response) => {
                    me.presidiOptions = response.data.data.list;
                    me.presidiOptions.unshift({ id: null, denominazione: "- Seleziona il Presidio -" });
                    me.loadPiani();
                });
            } else {
                me.presidiOptions = [{ id: null, denominazione: "- Seleziona il Presidio -" }];
                me.pianiOptions = [{ id: null, nome: "- Seleziona il Piano -" }];
                me.jsonData.idPresidio = null;
                me.jsonData.idPiano = null;
            }
        },
        loadPiani() {
            let me = this;
            // if (me.idPiano) {
            //   let link = process.env.VUE_APP_PATH_API + me.pathResourcePiani; /* + "/" + me.idPiano; */
            //   axios.get(link).then((response) => {
            //     me.pianiOptions = response.data.data.list;
            //     me.pianiOptions.unshift({ id: null, nome: "-Seleziona Piano-" });
            //     me.pianiOptions.forEach((element) => {
            //       if (element.id === me.idPiano) {
            //         me.jsonData.codiceStruttura = element.codiceStruttura;
            //         me.jsonData.idPresidio = element.idPresidio;
            //         me.jsonData.idPiano = element.id;
            //       }
            //     });
            //   });
            // } else {
            if (me.jsonData.idPresidio) {
                let link = process.env.VUE_APP_PATH_API + me.pathResourcePiani + "?idPresidio=" + me.jsonData.idPresidio;
                axios.get(link).then((response) => {
                    me.pianiOptions = response.data.data.list;
                    me.pianiOptions.unshift({ id: null, nome: "- Seleziona il Piano -" });
                });
            } else {
                me.pianiOptions = [{ id: null, nome: "- Seleziona il Piano -" }];
                me.jsonData.idPiano = null;
            }
            //  }
        },
        loadSesso() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceSesso;
            axios.get(link).then((response) => {
                me.sessoOptions = response.data.data.list;
                me.sessoOptions.unshift({ codice: null, descrizione: "- Seleziona il Sesso -" });
            });
        },
        loadData() {
            let me = this;
            // if (me.id === "-1") {
            //     this.$emit("afterLoadData");
            // } else {
            // me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            axios.get(link + me.id).then((response) => {
                me.jsonData = response.data.data;
                this.$emit("afterLoadData");
            });
            // }
        },
        getIdStrutturaByCodiceStruttura(codiceStruttura) {
            let me = this;
            let idStruttura = null;
            me.strutturaOptions.forEach((element) => {
                if (element.codiceStruttura === codiceStruttura) {
                    idStruttura = element.id;
                }
            });
            return idStruttura;
        },
        onInputStruttura(value) {
            let me = this;
            me.loadPresidi(value);
        },
        onInputPresidi() {
            let me = this;
            me.loadPiani();
        },
        onInputSesso() {},

        nuovaStanza() {
            let me = this;
            me.isNew = true;
            me.jsonData = { codiceStruttura: null, idPresidio: null, idPiano: null, codiceReparto: null, descrizioneReparto: null, nome: null, sesso: null, note: null };
            me.$refs.mdlEditPostiLettoStanze.show();
            if (me.idPiano) {
                me.loadPiano();
            }
        },
        aggiornaStanza(item) {
            let me = this;
            me.isNew = false;

            if (item.codiceStruttura) {
                let idStruttura = me.getIdStrutturaByCodiceStruttura(item.codiceStruttura);
                let link = process.env.VUE_APP_PATH_API + me.pathResourcePresidi + "?idStruttura=" + idStruttura;

                axios
                    .get(link)
                    .then((response) => {
                        me.presidiOptions = response.data.data.list;
                        me.presidiOptions.unshift({ id: null, denominazione: "- Seleziona il Presidio -" });

                        if (item.idPresidio) {
                            let pianiLink = process.env.VUE_APP_PATH_API + me.pathResourcePiani + "?idPresidio=" + item.idPresidio;

                            return axios.get(pianiLink);
                        }
                        return Promise.resolve(null);
                    })
                    .then((pianiResponse) => {
                        if (pianiResponse) {
                            me.pianiOptions = pianiResponse.data.data.list;
                            me.pianiOptions.unshift({ id: null, nome: "- Seleziona il Piano -" });
                        }

                        me.jsonData = { ...item };
                        me.$refs.mdlEditPostiLettoStanze.show();
                    })
                    .catch((error) => {
                        console.error("Errore nel caricamento dei dati:", error);
                        me.$bvToast.toast("Errore nel caricamento dei dati", {
                            title: "Errore",
                            variant: "danger",
                            solid: true,
                        });
                    });
            } else {
                me.jsonData = { ...item };
                me.$refs.mdlEditPostiLettoStanze.show();
            }
        },

        onCloseModal() {
            let me = this;
            me.jsonData = {};
            me.$emit("onClose");
        },
        onClickOk(bvModalEvent) {
            let me = this;
            bvModalEvent.preventDefault();
            UtilityMixin.methods.saveResource(me.pathResource, me.jsonData, me.saveSuccessResponse, me.saveErrorResponse);
        },
        saveSuccessResponse(response) {
            let me = this;
            me.$emit("onSave", response);
            me.$refs.mdlEditPostiLettoStanze.hide();
            me.$bvToast.toast("Salvataggio avvenuto con successo", {
                title: "Successo",
                variant: "success",
                solid: true,
            });
        },
        saveErrorResponse(response) {
            if (response.response.status === 400) {
                this.$bvModal.msgBoxOk(response.response.data.messaggio, {
                    title: "ATTENZIONE",
                    size: "sm",
                    okVariant: "outline-danger",
                    headerClass: "sa-msg-header-danger",
                    footerClass: "p-2 border-top-0",
                    centered: true,
                });
            } else {
                this.$bvToast.toast("Errore nel salvataggio" + response.message, {
                    title: "Errore",
                    variant: "danger",
                    solid: true,
                });
            }
        },

        onDeleteStanza(item) {
            let me = this;
            this.$bvModal
                .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
                    title: "Eliminazione Stanza",
                    buttonSize: "sm",
                    okVariant: "secondary",
                    cancelVariant: "danger",
                    okTitle: "SI",
                    cancelTitle: "NO",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                })
                .then((value) => {
                    if (value) {
                        me.deleteStanza(item.id);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        deleteStanza(id) {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + id;
            axios
                .delete(link)
                .then((response) => {
                    me.showModalLoading = false;
                    me.$emit("onDelete", response);
                    me.$bvToast.toast("Eliminazione avvenuta con successo", {
                        title: "Successo",
                        variant: "success",
                        solid: true,
                    });
                })
                .catch((e) => {
                    console.log(e);
                    me.showModalLoading = false;
                    me.$bvToast.toast("Errore durante l'eliminazione", {
                        title: "Errore",
                        variant: "danger",
                        solid: true,
                    });
                });
        },
        loadPiano() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourcePiani + "/" + me.idPiano;
            axios.get(link).then((response) => {
                //            me.piano = response.data.data;
                //  me.pianiOptions.push(me.piano);
                // me.jsonData.codiceStruttura = me.piano.codiceStruttura;
                // me.jsonData.idPresidio = me.piano.idPresidio;
                // me.jsonData.idPiano = me.piano.id;

                me.jsonData.codiceStruttura = response.data.data.codiceStruttura;
                me.jsonData.idPresidio = response.data.data.idPresidio;
                me.jsonData.idPiano = response.data.data.id;
            });
        },
    },
};
</script>

<style></style>
