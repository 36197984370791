<template>
    <sa-page-layout class="sa-no-space" :showModalLoading="showModalLoading" :btnSaveNotBackVisible="false" :linkNotBack="linkNotBack" :btnSaveVisible="true" :btnBackVisible="false" :linkback="linkback" :btnAnnullaVisible="true" :pathResource="pathResource" :data="jsonData">
        <template slot="toolbar-title">
            <paziente-anagrafica @getAnagraficaPaziente="getAnagraficaPaziente" @getEtaPaziente="getEtaPaziente" :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
        </template>
        <template slot="toolbar">
            <firma-component :idEvento="jsonData.id" :fieldName="firmaFieldName" :data="jsonData" :pathRestFirma="pathResource" :pathRestValidation="pathRestValidation" :disabled="btnFirmaDisabled" @update="onFirmaUpdate"></firma-component>
        </template>
        <template slot="table-header">
            <h3>{{ getLabelTraduora("patient.afa.lblEligibility") }}</h3>
        </template>
        <template slot="table-body">
            <b-tabs content-class="mt-3" class="sa-tab" style="height: 100%" fill @input="onTabInput">
                <b-tab>
                    <template #title>
                        <div class="sa-tab-title-normal">
                            <div style="float: left">
                                <span>{{ getLabelTraduora("patient.afa.lblSportsMedicalEvaluationForm") }}</span>
                            </div>
                        </div>
                    </template>
                    <div class="sa-tab-scroll">
                        <idoneita-scheda-valutazione-medico-sportiva :tipoEvento="tipoEvento" :gruppo="gruppo" :etaPaziente="eta" :tipiSportOptions="tipiSportOptions" :data="jsonData" :edit="disabilitaSchede()"></idoneita-scheda-valutazione-medico-sportiva>
                    </div>
                </b-tab>
                <b-tab>
                    <template #title>
                        <div class="sa-tab-title-normal">
                            <div style="float: left">
                                <span>{{ getLabelTraduora("patient.afa.lblAnamnesis") }}</span>
                            </div>
                        </div>
                    </template>
                    <div class="sa-tab-scroll">
                        <idoneita-anamnesi :data="jsonData" :edit="disabilitaSchede()"></idoneita-anamnesi>
                    </div>
                </b-tab>
                <b-tab>
                    <template #title>
                        <div :class="getColorByFirma(jsonData.firmaEo)">
                            <div style="float: left">
                                <b-icon :icon="getIconByFirma(jsonData.firmaEo)"></b-icon>
                            </div>
                            <div style="float: left">
                                <span>{{ getLabelTraduora("patient.afa.lblPhysicalExamination") }}</span>
                            </div>
                        </div>
                    </template>

                    <div class="sa-tab-scroll">
                        <idoneita-esame-obiettivo :tipoEvento="tipoEvento" :gruppo="gruppo" :gruppoSanguignoPaziente="gruppoSanguignoPaziente" @loadMisure="loadMisure" :data="jsonData" :edit="disabilitaSchedaEsameObiettivo()"></idoneita-esame-obiettivo>
                    </div>
                </b-tab>
                <b-tab>
                    <template #title>
                        <div :class="getColorByFirma(jsonData.firmaEo)">
                            <div style="float: left">
                                <b-icon :icon="getIconByFirma(jsonData.firmaEo)"></b-icon>
                            </div>
                            <div style="float: left">
                                <span>{{ getLabelTraduora("patient.afa.lblElectrocardiogram") }}</span>
                            </div>
                        </div>
                    </template>
                    <div class="sa-tab-scroll">
                        <idoneita-elettrocardiogramma @loadMisure="loadMisure" :tipoEvento="tipoEvento" :gruppo="gruppo" :data="jsonData" :edit="disabilitaSchedaEcg()"></idoneita-elettrocardiogramma>
                    </div>
                </b-tab>
                <b-tab>
                    <template #title>
                        <div class="sa-tab-title-normal">
                            <div style="float: left">
                                <span>{{ getLabelTraduora("patient.afa.lblUrines") }}</span>
                            </div>
                        </div>
                    </template>
                    <div class="sa-tab-scroll">
                        <idoneita-urine @loadMisure="loadMisure" :tipoEvento="tipoEvento" :gruppo="gruppo" :data="jsonData" :edit="disabilitaSchede()"></idoneita-urine>
                    </div>
                </b-tab>
                <b-tab :edit="edit">
                    <template #title>
                        <div class="sa-tab-title-normal">
                            <div style="float: left">
                                <span>{{ getLabelTraduora("patient.afa.lblSpirography") }}</span>
                            </div>
                        </div>
                    </template>
                    <div class="sa-tab-scroll">
                        <idoneita-spirografia @loadMisure="loadMisure" :tipoEvento="tipoEvento" :gruppo="gruppo" :data="jsonData" :edit="disabilitaSchede()"></idoneita-spirografia>
                    </div>
                </b-tab>
                <b-tab>
                    <template #title>
                        <div class="sa-tab-title-normal">
                            <div style="float: left">
                                <span>Terapia</span>
                            </div>
                        </div>
                    </template>
                    <div class="sa-tab-scroll">
                        <paziente-terapia-edit-component></paziente-terapia-edit-component>
                    </div>
                </b-tab>
                <b-tab>
                    <template #title>
                        <div :class="getColorByFirma(jsonData.firmaGf)">
                            <div style="float: left">
                                <b-icon :icon="getIconByFirma(jsonData.firmaGf)"></b-icon>
                            </div>
                            <div style="float: left">
                                <span>{{ getLabelTraduora("patient.afa.lblFinalJudgement") }}</span>
                            </div>
                        </div>
                    </template>
                    <div class="sa-tab-scroll">
                        <idoneita-giudizio-finale :data="jsonData" :tipoCertificatoOptions="tipoCertificatoOptions" :edit="disabilitaSchede()"></idoneita-giudizio-finale>
                    </div>
                </b-tab>
            </b-tabs>
            <!-- <b-modal ref="mdlMisurazioni" id="modalMisurazioni" size="xl" style="height: 100%">
        <template #modal-title>
          {{ lblRicercaPerMisura }}
          {{ nomeModalMisura.toUpperCase() }}</template
        >
        <div>
          <b-row>
            <b-col cols="6" xs="6" sm="6" md="6" lg="6" xl="6">
              <span class="sa-form-label">{{ getLabelTraduora("agenda.lblDateFrom") }}</span>
              <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="ricercaMisureRange.dataDal" @input="loadMisure(nomeModalMisura)" type="date" :placeholder="this.getLabelTraduora('agenda.lblDateFrom')"></date-picker>
            </b-col>
            <b-col cols="6" xs="6" sm="6" md="6" lg="6" xl="6">
              <span class="sa-form-label">{{ getLabelTraduora("agenda.lblDateEnd") }}</span>
              <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="ricercaMisureRange.dataAl" @input="loadMisure(nomeModalMisura)" type="date" placeholder="Data Al"></date-picker>
            </b-col>
          </b-row>
          <b-row>
            <b-table sticky-header ref="tableActive" stacked="xl" striped hover :items="misure" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table mt-2"> </b-table>
          </b-row>
        </div>
      </b-modal> -->
        </template>
    </sa-page-layout>
</template>

<script>
import axios from "axios";
// import DatePicker from "vue2-datepicker";
// import "vue2-datepicker/index.css";
import SaPageLayout from "../../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../../../components/PazienteAnagrafica.vue";
import IdoneitaSchedaValutazioneMedicoSportiva from "./IdoneitaSchedaValutazioneMedicoSportiva.vue";
import IdoneitaAnamnesi from "./IdoneitaAnamnesi.vue";
import IdoneitaEsameObiettivo from "./IdoneitaEsameObiettivo.vue";
import IdoneitaElettrocardiogramma from "./IdoneitaElettrocardiogramma.vue";
import IdoneitaUrine from "./IdoneitaUrine.vue";
import IdoneitaSpirografia from "./IdoneitaSpirografia.vue";
import IdoneitaGiudizioFinale from "./IdoneitaGiudizioFinale.vue";
import UtilityMixin from "../../../../../utility/UtilityMixin";
import FirmaComponent from "../../../utility/components/FirmaComponent.vue";
import PazienteTerapiaEditComponent from "../../../../terapia/components/PazienteTerapiaEditComponent.vue";
import moment from "moment";
import EventiMixin from "../../../utility/mixins/EventiMixins";
export default {
    mixins: [UtilityMixin, EventiMixin],
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    components: {
        // DatePicker,
        SaPageLayout,
        PazienteAnagrafica,
        IdoneitaSchedaValutazioneMedicoSportiva,
        IdoneitaAnamnesi,
        IdoneitaEsameObiettivo,
        IdoneitaElettrocardiogramma,
        IdoneitaUrine,
        IdoneitaSpirografia,
        IdoneitaGiudizioFinale,
        FirmaComponent,
        PazienteTerapiaEditComponent,
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient() {
            return EventiMixin.getLinkPatient(this.utente, this.idPaziente);
        },
    },
    data() {
        return {
            id: null,
            tipoEvento: "IDONEITA",
            gruppo: "MEDICINADELLOSPORT",
            linkNotBack: "",
            ricercaMisureRange: {
                dataDal: new Date().getTime(),
                dataAl: new Date().getTime(),
            },
            misure: [],
            currentPage: 1,
            perPage: 10,
            jsonData: {
                tipoCertificato: null,
                diabete: null,
                dataAppuntamento: new Date().getTime(),
                sportPraticato: null,
                firmaEo: null,
                firmaEcg: null,
                firmaGf: null,
                giudizio: null,
                periodoIdoneita: null,
                anamnesiFamiliare: "",
                anamnesiFisiologica: "",
                anamnesiPatologica: "",
                anamnesiSportiva: "",
                infortuni: "",
                interventiChirurgici: "",
            },
            tipoCertificatoOptions: [],
            tipiSportOptions: [],
            fields: [
                {
                    label: this.getLabelTraduora("patient.afa.lblMisureValue"),
                    key: "valoreMisura",
                    thStyle: "width: 15rem",
                    sortable: true,
                },
                {
                    label: this.getLabelTraduora("patient.events.lblEventDate"),
                    key: "dataOraEvento",
                    thStyle: "width: 15rem",
                    sortable: true,
                    formatter: (value) => {
                        return value ? moment(new Date(value)).format("DD-MM-YYYY hh:mm") : "";
                    },
                },
            ],
            gruppoSanguignoPaziente: "",
            linkback: "",
            edit: true,
            tabIndex: 0,
            showModalLoading: false,
            pathResource: "/medicinadellosportidoneita",
            pathRestValidation: "",
            pathResourceMisurazioni: "/medicinadellosportidoneita/listamisure",
            btnFirmaDisabled: false,
            firmaFieldName: null,
            editEo: true,
            editEcg: true,
            lblRicercaPerMisura: this.$i18n.t("patient.afa.lblMisurFor"),
            nomeModalMisura: "",
            eta: 0,
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        let path = this.$route.path.replace(/\/edit\/.*/, "");
        me.linkNotBack = path + "{editid}?";
        me.jsonData.idPaziente = me.idPaziente;
        me.jsonData.numeroAnni = me.eta;
        me.jsonData.gruppoSanguigno = me.gruppoSanguignoPaziente;
        me.jsonData.idAmbulatorio = sessionStorage.getItem("idAmbulatorio");

        if (me.id !== "-1") {
            me.linkback = "/paziente/medicinadellosportidoneita/view/" + me.id;
            me.loadData();
        } else {
            me.linkback = "/paziente/medicinadellosportidoneita";
            me.loadLastEvent();
        }
        me.loadDefaultData();
    },
    methods: {
        loadLastEvent() {
            let me = this;
            me.showmolalloading = true;
            let link = process.env.VUE_APP_PATH_API + "/pazienteeventi/lastevent" + "?idPaziente=" + me.idPaziente + "&gruppo=MEDICINADELLOSPORT&tipoEvento=IDONEITA";
            axios
                .get(link)
                .then((response) => {
                    me.showmolalloading = false;
                    me.jsonData.anamnesiFamiliare = response.data.data.anamnesiFamiliare;
                    me.jsonData.anamnesiFisiologica = response.data.data.anamnesiFisiologica;
                    me.jsonData.anamnesiPatologica = response.data.data.anamnesiPatologica;
                    me.jsonData.anamnesiSportiva = response.data.data.anamnesiSportiva;
                    me.jsonData.infortuni = response.data.data.infortuni;
                    me.jsonData.interventiChirurgici = response.data.data.interventiChirurgici;
                    // console.log(response.data.data);
                    // console.log(me.jsonData);
                })
                .catch(() => {
                    me.showmolalloading = false;
                });
        },
        onFirmaUpdate() {
            let me = this;
            me.loadData();
        },
        disabilitaSchede() {
            let me = this;
            let returnValue = false;
            if (!me.jsonData.firmaGf) {
                returnValue = true;
            }
            return returnValue;
        },
        disabilitaSchedaEsameObiettivo() {
            let me = this;
            let returnValue = false;
            if (me.disabilitaSchede()) {
                if (!me.jsonData.firmaEo) {
                    returnValue = true;
                }
            }
            return returnValue;
        },
        disabilitaSchedaEcg() {
            let me = this;
            let returnValue = false;
            if (me.disabilitaSchede()) {
                if (!me.jsonData.firmaEcg) {
                    returnValue = true;
                }
            }
            return returnValue;
        },
        loadMisure(nomeMisura) {
            let me = this;
            me.nomeModalMisura = nomeMisura;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceMisurazioni + "?nomeMisura=" + nomeMisura + "&idPaziente=" + me.idPaziente + "&dataDal=" + me.ricercaMisureRange.dataDal + "&dataAl=" + me.ricercaMisureRange.dataAl;
            axios
                .get(link)
                .then((response) => {
                    me.misure = response.data.data;
                })
                .catch(() => {
                    me.showmolalloading = false;
                });
        },
        loadDefaultData() {
            let me = this;
            me.showModalLoading = true;
            let linkTipiSport = process.env.VUE_APP_PATH_API + "/tipisport";
            axios
                .get(linkTipiSport)
                .then((response) => {
                    me.showModalLoading = false;
                    me.tipiSportOptions = response.data.data;
                })
                .catch((e) => {
                    me.showModalLoading = false;
                    console.log(e);
                });
            me.showModalLoading = true;
            let linkTipiCertificato = process.env.VUE_APP_PATH_API + "/medicinadellosportidoneitatipocertificato?forPage=100&page=1";
            axios
                .get(linkTipiCertificato)
                .then((response) => {
                    me.showModalLoading = false;
                    me.tipoCertificatoOptions = response.data.data.list;
                })
                .catch((e) => {
                    me.showModalLoading = false;
                    console.log(e);
                });
        },
        loadData() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            axios
                .get(link + me.id)
                .then((response) => {
                    me.jsonData = response.data.data;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        getEtaPaziente(value) {
            this.eta = value;
        },
        getAnagraficaPaziente(value) {
            let me = this;
            me.gruppoSanguignoPaziente = value.gruppoSanguigno;
        },
        onTabInput(index) {
            let me = this;
            if (me.jsonData.firmaGf !== null) {
                me.btnFirmaDisabled = true;
                me.edit = false;
                me.editEo = false;
                me.editEcg = false;
            } else {
                switch (index) {
                    case 0:
                        me.btnFirmaDisabled = true;
                        me.firmaFieldName = null;
                        break;
                    case 1:
                        me.btnFirmaDisabled = true;
                        me.firmaFieldName = null;
                        break;
                    case 2:
                        me.firmaFieldName = "firma_eo";
                        me.pathRestFirma = "/medicinadellosportafa";
                        if (me.jsonData.firmaEo == null) {
                            me.btnFirmaDisabled = false;
                        } else {
                            me.btnFirmaDisabled = true;
                            me.editEo = false;
                        }
                        break;
                    case 3:
                        me.firmaFieldName = "firma_ecg";
                        me.pathRestFirma = "/medicinadellosportafa";
                        if (me.jsonData.firmaEcg == null) {
                            me.btnFirmaDisabled = false;
                        } else {
                            me.btnFirmaDisabled = true;
                            me.editEcg = false;
                        }
                        break;
                    case 4:
                        me.btnFirmaDisabled = true;
                        me.firmaFieldName = null;
                        break;
                    case 5:
                        me.btnFirmaDisabled = true;
                        me.firmaFieldName = null;
                        break;
                    case 6:
                        me.firmaFieldName = "firma_gf";
                        me.pathRestFirma = "/medicinadellosportafa";
                        if (me.jsonData.firmaGf == null) {
                            me.btnFirmaDisabled = false;
                        } else {
                            me.btnFirmaDisabled = true;
                            // me.edit = false;
                        }
                        break;
                }
            }
        },
        getColorEsameObiettivo() {
            let me = this;
            let colore = me.jsonData.firmaEo != null ? "text-success" : "text-danger";
            return colore;
        },
        getColorElettrocardiogramma() {
            let me = this;
            let colore = me.jsonData.firmaEcg != null ? "text-success" : "text-danger";
            return colore;
        },
        getColorGiudizioFinale() {
            let me = this;
            let colore = me.jsonData.firmaGf != null ? "text-success" : "text-danger";
            return colore;
        },

        getIconEsameObiettivo() {
            let me = this;
            let icon = me.jsonData.firmaEo != null ? "check2" : "exclamation-triangle";
            return icon;
        },
        getIconElettrocardiogramma() {
            let me = this;
            let icon = me.jsonData.firmaEcg != null ? "check2" : "exclamation-triangle";
            return icon;
        },
        getIconGiudizioFinale() {
            let me = this;
            let icon = me.jsonData.firmaGf != null ? "check2" : "exclamation-triangle";
            return icon;
        },
    },
    watch: {
        eta: function (value) {
            let me = this;
            me.jsonData.numeroAnni = value;
        },
        gruppoSanguignoPaziente: function (value) {
            let me = this;
            me.jsonData.gruppoSanguigno = value;
        },
    },
};
</script>
