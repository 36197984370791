<template>
    <div style="height: 100%; overflow: auto">
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Denominazione</label>
                    <b-form-input v-model="jsonData.denominazione"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">C.O.T. Destinazione</label>
                    <b-form-select v-model="jsonData.idCotDestinazione" :options="cotDestinazioneOptions" :value="''" value-field="id" text-field="destinazione" @change="onSelectCotDestinazione"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Email</label>
                    <b-form-input v-model="jsonData.email"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Telefono</label>
                    <b-form-input v-model="jsonData.telefono"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Note</label>
                    <b-form-textarea id="textarea-no-resize" v-model="jsonData.note" rows="5" no-resize></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import axios from "axios";
export default {
    data() {
        return {
            pathResource: "/cotpua",
            pathResourceCotDestinazione: "/cotdestinazioni",
            jsonData: { idCotDestinazione: "", descrizioneCotDestinazione: "", denominazione: "", note: "", email: "", telefono: "" },
            cotDestinazioneOptions: [],
        };
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    mounted: function () {
        let me = this;
        me.id = this.$route.params.id;
        me.loadCotDestinazione();
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            if (me.id === "-1") {
                this.$emit("afterLoadData");
            } else {
                // me.showModalLoading = true;
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                axios.get(link + me.id).then((response) => {
                    me.jsonData = response.data.data;

                    this.$emit("afterLoadData");
                });
            }
        },
        loadCotDestinazione() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceCotDestinazione;
            axios.get(link).then((response) => {
                me.cotDestinazioneOptions = response.data.data.list;
                me.cotDestinazioneOptions.unshift({ id: "", destinazione: "-Seleziona Valore-" });
            });
        },
        onSelectCotDestinazione() {
            let me = this;
            me.cotDestinazioneOptions.forEach((element) => {
                if (element.id === me.jsonData.idCotDestinazione) {
                    me.jsonData.descrizioneCotDestinazione = element.destinazione;
                }
            });
        },
    },
};
</script>

<style></style>
