<template>
    <div class="sa-list-component">
        <div class="sa-list-component-header">
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </div>
        <div class="sa-list-component-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" stacked="lg" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
                    <template #cell(index)="row">
                        <span> {{ row.index + 1 }} </span>
                    </template>
                    <template v-slot:cell(nominativo)="{ item }">
                        <span>{{ item.nome }} {{ item.cognome }}</span>
                        <span style="display: block">({{ item.identificativo }})</span>
                    </template>
                    <template v-slot:cell(eta)="{ item }">
                        <span>{{ calcoloEta(item.dataNascita) }}</span>
                    </template>
                    <template v-slot:cell(email)="{ item }">
                        <span>{{ item.email }}</span>
                    </template>
                    <template v-slot:cell(camera)="{ item }">
                        <span>{{ item.camera }}</span>
                    </template>
                    <template v-slot:cell(actions)="row">
                        <!-- <b-container class="bv-example-row">
							<b-button v-b-tooltip.hover size="sm" variant="outline-success" title="Cartella Ospite" @click="onEdit(row.item, row.index, $event.target)" class="mr-1 no-text">
								<b-icon icon="book" variant="outline-sucess"></b-icon>
							</b-button>
						</b-container> -->
                        <b-container class="bv-example-row">
                            <b-button v-b-tooltip.hover size="sm" variant="outline-success" title="Cartella Paziente" @click="onOpenSchedaPaziente(row.item, row.index, $event.target)" class="mr-1 no-text">
                                <b-icon icon="book" variant="outline-sucess"></b-icon>
                            </b-button>
                        </b-container>
                    </template>
                </b-table>
            </div>
        </div>
        <div class="sa-list-component-footer">
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import UtilityMixin from "../../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixin],
    data() {
        return {
            rows: 0,
            perPage: 50,
            currentPage: 1,
            pathResource: "/rsaospiti",
            // pathResource: "/ospiti",
            filtro: {},
            items: [],
            listKey: 0,
            fields: [
                {
                    label: "",
                    key: "index",
                    thStyle: "width: 0.5rem",
                },
                {
                    label: "Nominativo",
                    key: "nominativo",
                    sortable: true,
                },
                {
                    label: "Età",
                    key: "eta",
                },
                { label: "Numero telefono", key: "telefono" },
                {
                    label: "Email",
                    key: "email",
                },
                {
                    label: "Camera",
                    key: "camera",
                },
                { class: "sa-table-column-action-double", key: "actions", label: "" },
            ],
        };
    },
    watch: {
        currentPage() {
            let me = this;
            me.loadData();
        },
    },
    methods: {
        loadData() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            if (sessionStorage["filtro"]) {
                me.filtro = JSON.parse(sessionStorage["filtro"]);
            }

            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.filtro.page = me.currentPage;
            me.filtro.forPage = me.perPage;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];
                    me.rows = response.data.data.recordsNumber;
                    me.items = response.data.data.list;
                    me.listKey++;
                    this.$emit("afterLoadData");
                })
                .catch(() => {
                    this.$emit("afterLoadData");
                });
        },
        onEdit(item) {
            let me = this;
            me.$router.push("/rsagestioneospiti/schedaospite/view/" + item.id);
        },
        onOpenSchedaPaziente(item) {
            let me = this;
            me.$router.push("/rsapaziente/view/" + item.id);
        },
    },
};
</script>
<style></style>
