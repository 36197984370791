<template>
    <b-modal class="modal-content" ref="mdlFasciaOrariaComponent" title="Fascia Oraria" no-close-on-backdrop no-close-on-select @ok="oOkMdlFasciaOrariaComponent">
        <div class="modal-body">
            <b-row>
                <b-col>
                    <label for="dataInizio">Ora Inizio</label>
                    <date-picker format="HH:mm" value-type="timestamp" v-model="setDalleOre" type="time"></date-picker>
                </b-col>
                <b-col>
                    <label for="indirizzo">Ora Fine</label>
                    <date-picker format="HH:mm" value-type="timestamp" v-model="setAlleOre" type="time"></date-picker>
                </b-col>
                <b-col>
                    <label for="indirizzo">Numero accessi</label>
                    <b-form-spinbutton v-model="jsonData.numeroAccessi" min="1" max="100"></b-form-spinbutton>
                </b-col>
            </b-row>
        </div>
    </b-modal>
</template>

<script>
import DatePicker from "vue2-datepicker";
import moment from "moment";
export default {
    components: { DatePicker },
    data() {
        return {
            jsonData: { dalleOre: null, alleOre: null, numeroAccessi: 1 },
            intervallo: null,
        };
    },
    computed: {
        setDalleOre: {
            get: function () {
                return this.jsonData.dalleOre;
            },
            set: function (value) {
                this.jsonData.dalleOre = value;
            },
        },
        setAlleOre: {
            get: function () {
                return this.jsonData.alleOre;
            },
            set: function (value) {
                this.jsonData.alleOre = value;
            },
        },
        setClsColor: {
            get: function () {
                return this.jsonData.clsColor;
            },
            set: function (value) {
                this.jsonData.clsColor = value;
            },
        },
    },
    methods: {
        show() {
            let me = this;
            me.$refs.mdlFasciaOrariaComponent.show();
        },
        close() {
            let me = this;
            me.$refs.mdlFasciaOrariaComponent.hide();
        },
        setIntervallo(intervallo) {
            this.intervallo = intervallo;
        },
        oOkMdlFasciaOrariaComponent() {
            let me = this;

            let fasciaOraria = { dalleOre: moment(this.jsonData.dalleOre).format("HH:mm"), alleOre: moment(this.jsonData.alleOre).format("HH:mm"), numeroAccessi: me.jsonData.numeroAccessi };
            me.intervallo.intervalli.push(fasciaOraria);
            this.$emit("addFasciaOraria", this.intervallo);
        },
    },
};
</script>

<style></style>
