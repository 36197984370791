<template>
    <sa-page-layout :btnConfigurazioniVisible="true" :btnNewVisible="btnNewVisible" :btnRefreshVisible="true" @refresh="onRefresh" :pathResource="pathResource" :linkedit="linkedit" :linkback="linkback" :showModalLoading="showModalLoading">
        <!-- <template slot="table-filter">
            <b-form @submit.prevent="onSubmit">
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Codice Lis:</label>
                        <b-form-input v-model="filtro.codice" type="search" id="codice" placeholder="Codice Lis"></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Descrizione Profilo Lis:</label>
                        <b-form-input v-model="filtro.descrizione" type="search" id="descrizione" placeholder="Descrizione Profilo Lis"></b-form-input>
                    </b-col>
                </b-row>
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="sa-padding-right text-right">
                        <b-button type="submit" variant="info">{{ this.$i18n.t("patients.lblFind") }}</b-button>
                        <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("patients.lblResetFilter") }}</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </template> -->
        <template slot="table-body">
            <rsa-configurazione-attivita-list-component ref="RsaConfigurazioneAttivitaListComponent" @afterLoadData="onAfterLoadData"></rsa-configurazione-attivita-list-component>
        </template>
    </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import RsaConfigurazioneAttivitaListComponent from "../components/RsaConfigurazioneAttivitaListComponent.vue";
export default {
    components: { SaPageLayout, RsaConfigurazioneAttivitaListComponent },
    data() {
        return {
            filtro: { codice: null, descrizione: null },
            btnNewVisible: true,
            btnBackVisible: true,
            btnConfigurazioniVisible: true,
            linkback: "/home",
            pathResource: "/rsaattivita",
            linkedit: "/rsaconfigurazioneattivita",
            showModalLoading: false,
            listKey: 0,
        };
    },
    mounted() {
        let me = this;
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            me.$refs.RsaConfigurazioneAttivitaListComponent.loadData();
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        onAfterLoadData() {
            let me = this;
            me.showModalLoading = false;
        },
        onReset() {},
        onSubmit() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
    },
};
</script>

<style></style>
