<template>
    <div style="height: 100%">
        <div class="sa-flexbox" style="padding-bottom: 20px">
            <div class="d-flex flex-column">
                <!-- <div class="text-right mb-3">
                            <b-button size="sm" variant="outline-success outline-primary-noborder sa-margin-right float-sm-right mt-2" :hidden="btnNuovoSettingHidden" @click="onClickNuovaUscita" class="mb-2">
                                <b-icon icon="plus"> </b-icon>
                                Nuovo Setting
                            </b-button>
                        </div> -->
                <b-row class="sa-label-info">
                    <b-col lg="3">
                        <p>Record Totali: {{ rows }}</p>
                    </b-col>
                    <b-col lg="9">
                        <b-pagination v-model="paginaCorrente" :total-rows="rows" :per-page="perPagina" align="right" size="sm"></b-pagination>
                    </b-col>
                </b-row>
            </div>

            <div class="sa-flexbox-body">
                <div class="b-table-sticky-header">
                    <b-table sticky-header ref="table" stacked="lg" striped hover :items="oggetti" :fields="colonne" :current-page="1" :per-page="perPagina" :key="listKey" sort-icon-left head-variant="light">
                        <template #cell(index)="riga">
                            {{ riga.index + 1 }}
                        </template>

                        <template v-slot:cell(eventi)="{ item }">
                            <b-container class="bv-example-row">
                                <b-button size="sm" v-b-tooltip.hover title="Modifica Setting" variant="outline-success" @click="onClickEdit(item)" class="mr-1 no-text"> <font-awesome-icon icon="edit" /> </b-button>

                                <b-button size="sm" v-b-tooltip.hover title="Visualizza Setting" variant="outline-secondary" @click="onClickView(item)" class="mr-1 no-text">
                                    <font-awesome-icon icon="eye" />
                                </b-button>

                                <!-- (riga.oggetti, riga.index, $event.target) -->
                            </b-container>
                        </template>
                    </b-table>
                </div>
            </div>

            <rsa-configurazione-settings-edit-component ref="cmpRsaConfigurazioneSettingsEdit" @onSave="onSaveSetting" @onClose="loadData"> </rsa-configurazione-settings-edit-component>
            <div class="sa-list-component-footer">
                <b-row class="sa-label-info">
                    <b-col lg="3">
                        <p>Record Totali: {{ rows }}</p>
                    </b-col>
                    <b-col lg="9">
                        <b-pagination v-model="paginaCorrente" :total-rows="rows" :per-page="perPagina" align="right" size="sm"></b-pagination>
                    </b-col>
                </b-row>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import UtilityMixin from "../../../utility/UtilityMixin";
import RsaConfigurazioneSettingsEditComponent from "./RsaConfigurazioneSettingsEditComponent.vue";

export default {
    components: {
        RsaConfigurazioneSettingsEditComponent,
    },

    watch: {
        paginaCorrente() {
            let me = this;
            me.loadData();
        },
    },

    mixins: [UtilityMixin],

    data() {
        return {
            perPagina: 50,
            paginaCorrente: 1,
            pathResource: "/rsasettings",
            oggetti: [],
            filtroItems: [],
            rows: 0,
            listKey: 0,
            btnNuovoSettingHidden: false,
            filtroSettings: { settings: "", codice: "" },
            showModalLoading: false,
            colonne: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 0.5rem",
                },
                {
                    label: "Settings",
                    key: "setting",
                    thStyle: "width: 15rem",
                    sortable: true,
                },
                {
                    label: "Codice",
                    key: "codice",
                    thStyle: "width: 5rem",
                    sortable: true,
                },
                {
                    label: "Note",
                    key: "note",
                    sortable: true,
                },
                {
                    class: "sa-table-column-action-double",
                    key: "eventi",
                    label: "",
                    sortable: false,
                    thStyle: "width: 5rem",
                },
            ],
        };
    },

    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },

    methods: {
        loadData() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            if (sessionStorage["filtroSettings"]) {
                me.filtroSettings = JSON.parse(sessionStorage["filtroSettings"]);
            }
            let link = process.env.VUE_APP_PATH_API + this.pathResource;
            me.filtroSettings.page = me.paginaCorrente;
            me.filtroSettings.forPage = me.perPagina;
            axios
                .get(link, { params: me.filtroSettings })
                .then((risposta) => {
                    me.oggetti = [];
                    me.oggetti = risposta.data.data.list;
                    me.rows = risposta.data.data.recordsNumber;
                    me.listKey++;
                    this.$emit("afterLoadData");
                })
                .catch((errore) => {
                    console.error("Errore nel caricamento dei dati", errore);
                    this.$$bvToast.toast("Errore nel caricamento dei dati", {
                        title: "Errore",
                        variant: "danger",
                    });
                })
                .finally(() => {
                    this.showModalLoading = false;
                });
        },
        onClickView(oggetti) {
            let me = this;
            me.$router.push("/rsasettings/view/" + oggetti.id);
        },
        onClickEdit(item) {
            let me = this;
            me.$refs.cmpRsaConfigurazioneSettingsEdit.aggiornaSetting(item);
        },

        onClickNuovoSetting() {
            let me = this;
            me.$refs.cmpRsaConfigurazioneSettingsEdit.nuovoSetting();
        },

        onSaveSetting() {
            this.loadData();
        },
    },
};
</script>
