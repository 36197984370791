<template>
  <div class="sa-free-page">
    <div class="sa-free-page-header">
      <b-card class="sa-card" header="Dettagli Laboratorio" header-tag="header" footer-tag="footer" title="">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data">Codice</label>
            <b-form-input v-model="jsonData.codiceLaboratorio"></b-form-input>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data">Descrizione</label>
            <b-form-input v-model="jsonData.descrizioneLaboratorio"></b-form-input>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <label>Struttura</label>
            <b-form-select id="inline-form-custom-select-pref" v-model="struttura" class="mb-2 mr-sm-2 mb-sm-0" :value="null" value-field="codiceStruttura" text-field="denominazione" :options="strutturaOptions" @change="onChangeStruttura"></b-form-select>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <label>Presidi</label>
            <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.codicePresidio" class="mb-2 mr-sm-2 mb-sm-0" :value="null" value-field="id" text-field="denominazione" :options="presidiOptions"></b-form-select>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      pathResource: "/rsalaboratori",
      pathResourceStrutture: "/strutture",
      pathResourcePresidi: "/strutturepresidi",
      id: "-1",
      jsonData: { codiceLaboratorio: "", descrizioneLaboratorio: "", codicePresidio: null },
      struttura: null,
      filtroPresidi: {},
      strutturaOptions: [{ codiceStruttura: null, denominazione: "- Seleziona Struttura -" }],
      presidiOptions: [{ id: null, denominazione: "-Seleziona Presidio-" }],
    };
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.loadData();
    me.loadStrutture();
  },
  methods: {
    loadData() {
      let me = this;
      me.$emit("beforeLoadData");
      if (me.id === "-1") {
        this.$emit("afterLoadData");
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
        axios
          .get(link + me.id)
          .then((response) => {
            me.loadPresidiLaboratorio();
            me.jsonData = response.data.data;
            this.$emit("afterLoadData");
          })
          .catch(() => {});
      }
    },
    loadStrutture() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceStrutture;
      axios
        .get(link)
        .then((response) => {
          me.rows = response.data.data.recordsNumber;
          me.strutturaOptions = response.data.data;
          if (me.strutturaOptions.length === 1) {
            me.struttura = me.strutturaOptions[0].codiceStruttura;
            me.loadPresidi(me.strutturaOptions[0].id);
          } else {
            me.strutturaOptions.unshift({
              codiceStruttura: null,
              denominazione: "-Seleziona Struttura-",
            });
          }

          this.$emit("afterLoadData");
        })
        .catch(() => {
          this.$emit("afterLoadData");
        });
    },
    loadPresidi(value) {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourcePresidi;
      me.presidiOptions = [];
      me.filtroPresidi = { idStruttura: value };
      axios
        .get(link, { params: me.filtroPresidi })
        .then((response) => {
          me.presidiOptions = response.data.data.list;
          if (me.presidiOptions.length === 1) {
            me.jsonData.codicePresidio = me.presidiOptions[0].id;
          } else {
            me.presidiOptions.unshift({
              id: null,
              denominazione: "-Seleziona Presidio-",
            });
          }
          this.$emit("afterLoadData");
        })
        .catch(() => {
          this.$emit("afterLoadData");
        });
    },
    loadPresidiLaboratorio() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourcePresidi;
      me.presidiOptions = [];

      axios
        .get(link)
        .then((response) => {
          me.presidiOptions = response.data.data.list;
          me.presidiOptions.unshift({
            id: null,
            denominazione: "-Seleziona Presidio-",
          });
          this.$emit("afterLoadData");
        })
        .catch(() => {
          this.$emit("afterLoadData");
        });
    },
    onChangeStruttura(value) {
      let me = this;
      me.jsonData.codicePresidio = null;
      me.strutturaOptions.forEach((element) => {
        if (element.codiceStruttura === value) {
          me.loadPresidi(element.id);
        }
      });
    },
  },
};
</script>
