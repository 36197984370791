<template>
    <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <b-card class="sa-card sa-card-border" header="Valutazione Dermatologica" header-tag="header" footer-tag="footer" title="">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Decubiti</label>
                        <b-form-input v-model="jsonDataValutazioneDermatologica.decubiti" :disabled="isReadOnly"> </b-form-input>
                    </b-col>

                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Mucosa</label>
                        <b-form-select v-model="jsonDataValutazioneDermatologica.mucose" :options="mucoseOptions" :disabled="isReadOnly">
                            <template #first>
                                <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-col>

                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <label class="sa-label-data">Mucose descrizione</label>
                        <b-form-input v-model="jsonDataValutazioneDermatologica.mucoseDescrizione" :disabled="isReadOnly"> </b-form-input>
                    </b-col>

                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <label class="sa-label-data">Cute: </label>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-row>
                                <b-col cols="auto" v-for="cute in cuteOptions" :key="'cute-' + cute.id" class="sa-switch-select-element">
                                    <b-form-checkbox switch :disabled="isReadOnly" v-model="selectedCute" :value="cute.voce">
                                        <span style="font-weight: normal">{{ cute.voce }}</span>
                                    </b-form-checkbox>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-col>

                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <label class="sa-label-data">Unghie: </label>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-row>
                                <b-col cols="auto" v-for="unghie in unghieOptions" :key="'unghie-' + unghie.id" class="sa-switch-select-element">
                                    <b-form-checkbox switch :disabled="isReadOnly" v-model="selectedUnghie" :value="unghie.voce">
                                        <span style="font-weight: normal">{{ unghie.voce }}</span>
                                    </b-form-checkbox>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-col>

                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <label class="sa-label-data">Note</label>
                        <b-form-textarea v-model="jsonDataValutazioneDermatologica.note" :readonly="isReadOnly" max-rows="10" rows="5"> </b-form-textarea>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
        <!--  <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <b-card class="sa-card sa-card-border" header="Cute: " header-tag="header" footer-tag="footer" title="">
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-row>
                        <b-col cols="auto" v-for="cute in cuteOptions" :key="'cute-' + cute.id" class="sa-switch-select-element">
                            <b-form-checkbox switch :disabled="isReadOnly" v-model="selectedCute" :value="cute.voce">
                                <span style="font-weight: normal">{{ cute.voce }}</span>
                            </b-form-checkbox>
                        </b-col>
                    </b-row>
                </b-col>
            </b-card>
        </b-col> -->

        <!-- <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <b-card class="sa-card sa-card-border" header="Unghie: " header-tag="header" footer-tag="footer" title="">
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-row>
                        <b-col cols="auto" v-for="unghie in unghieOptions" :key="'unghie-' + unghie.id" class="sa-switch-select-element">
                            <b-form-checkbox switch :disabled="isReadOnly" v-model="selectedUnghie" :value="unghie.voce">
                                <span style="font-weight: normal">{{ unghie.voce }}</span>
                            </b-form-checkbox>
                        </b-col>
                    </b-row>
                </b-col>
            </b-card>
        </b-col> -->
    </b-row>
</template>
<script>
import UtilityMixin from "../../../utility/UtilityMixin";

export default {
    props: {
        isReadOnly: {
            type: Boolean,
            default: false,
        },
        idAccettazione: {
            type: String,
        },
    },
    mounted() {
        //let me = this;
        // me.loadDizionarioData();
    },

    mixins: [UtilityMixin],
    data() {
        return {
            pathResourceSchedaInfermieristicaValutazioneDermatologica: "/rsaschedainfermieristicavalutazionedermatologica",
            pathResourceDizionario: "/rsadizionario",
            cuteOptions: [],
            selectedCute: [],
            unghieOptions: [],
            selectedUnghie: [],
            jsonDataValutazioneDermatologica: {
                id: null,
                idAccettazione: null,
                decubiti: null,
                mucose: null,
                mucoseDescrizione: null,
                note: null,
                listaDermatologaCute: [],
                listaDermatologaUnghie: [],
            },
            mucoseOptions: [
                { text: "INTEGRE", value: "INTEGRE" },
                { text: "LESIONI", value: "LESIONI" },
            ],
        };
    },
    methods: {
        loadCute() {
            let me = this;
            UtilityMixin.methods.loadDataResources(me.pathResourceDizionario, { tipo: "CUTE" }, me.resultResponseCuteSucess, me.resultResponseCuteError);
        },
        resultResponseCuteSucess(response) {
            let me = this;
            me.cuteOptions = [];
            me.cuteOptions = response.data.data.list;
        },
        resultResponseCuteError(error) {
            console.log(error);
        },

        loadUnghie() {
            let me = this;
            UtilityMixin.methods.loadDataResources(me.pathResourceDizionario, { tipo: "UNGHIE" }, me.resultResponseUnghieSucess, me.resultResponseUnghieError);
        },
        resultResponseUnghieSucess(response) {
            let me = this;
            me.unghieOptions = [];
            me.unghieOptions = response.data.data.list;
        },
        resultResponseUnghieError(error) {
            console.log(error);
        },

        loadValutazioneDermatologicaData() {
            let me = this;
            // console.log("ID accettazione nel loadValutazioneDermatologicaData: " + me.idAccettazione);
            me.loadCute();
            me.loadUnghie();
            UtilityMixin.methods.loadDataResources(me.pathResourceSchedaInfermieristicaValutazioneDermatologica + "/accettazione/" + me.idAccettazione, {}, me.resultResponseSchedaInfermieristicaValutazioneDermatologicaSuccess, me.resultResponseSchedaInfermieristicaValutazioneDermatologicaError);
        },
        resultResponseSchedaInfermieristicaValutazioneDermatologicaSuccess(response) {
            let me = this;
            me.jsonDataValutazioneDermatologica = response.data.data;

            me.jsonDataValutazioneDermatologica.listaDermatologaCute.forEach((item) => {
                if (item.tipo === "CUTE") {
                    me.selectedCute.push(item.voce);
                }
            });

            me.jsonDataValutazioneDermatologica.listaDermatologaUnghie.forEach((item) => {
                if (item.tipo === "UNGHIE") {
                    me.selectedUnghie.push(item.voce);
                }
            });

            /* me.jsonDataValutazioneDermatologica.listaDermatologaCute.forEach((element) => {
                me.cuteOptions.forEach((item) => {
                    if (element.voce === item.voce) {
                        me.selectedCute.push(element.voce);
                    }
                });
            });

            me.jsonDataValutazioneDermatologica.listaDermatologaUnghie.forEach((element) => {
                me.unghieOptions.forEach((item) => {
                    if (element.voce === item.voce) {
                        me.selectedUnghie.push(element.voce);
                    }
                });
            }); */

            // console.log("ID del record VALUTAZIONE DERMATOLOGICA:", me.jsonDataValutazioneDermatologica.id);
        },
        resultResponseSchedaInfermieristicaValutazioneDermatologicaError(error) {
            let me = this;
            if (error.response && error.response.status === 404) {
                //console.log("NESSUN record trovato del VALUTAZIONE DERMATOLOGICA per questo id accettazione");
                me.resetForm();
            } else {
                console.error("Errore nel caricamento dati della sezione VALUTAZIONE DERMATOLOGICA:", error);
            }
        },

        resetForm() {
            let me = this;
            me.jsonDataValutazioneDermatologica = {
                id: null,
                idAccettazione: me.idAccettazione,
                decubiti: null,
                mucose: null,
                mucoseDescrizione: null,
                note: null,
                listaDermatologaCute: [],
                listaDermatologaUnghie: [],
            };

            me.selectedCute = [];
            me.selectedUnghie = [];
        },

        onSalvaValutazioneDermatologica() {
            let me = this;
            me.jsonDataValutazioneDermatologica.idAccettazione = me.idAccettazione;
            console.log("Salvataggio VALUTAZIONE DERMATOLOGICA con dati:", JSON.stringify(me.jsonDataValutazioneDermatologica));
            if (!me.jsonDataValutazioneDermatologica.idAccettazione) {
                // console.log("idAccettazione: ", me.jsonDataValutazioneDermatologica.idAccettazione);
                me.$bvToast.toast("Errore: ID Accettazione mancante", {
                    title: "Errore",
                    variant: "danger",
                    solid: true,
                });
                return;
            }

            let listaDermatologaCute = [];
            me.selectedCute.forEach((voce) => {
                listaDermatologaCute.push({
                    id: null,
                    idSchedaInfermieristicaValutazioneDermatologica: me.jsonDataValutazioneDermatologica.id,
                    tipo: "CUTE",
                    voce: voce,
                });
            });

            let listaDermatologaUnghie = [];
            me.selectedUnghie.forEach((voce) => {
                listaDermatologaUnghie.push({
                    id: null,
                    idSchedaInfermieristicaValutazioneDermatologica: me.jsonDataValutazioneDermatologica.id,
                    tipo: "UNGHIE",
                    voce: voce,
                });
            });
            me.jsonDataValutazioneDermatologica.listaDermatologaCute = listaDermatologaCute;
            me.jsonDataValutazioneDermatologica.listaDermatologaUnghie = listaDermatologaUnghie;

            UtilityMixin.methods.saveResource(me.pathResourceSchedaInfermieristicaValutazioneDermatologica, me.jsonDataValutazioneDermatologica, me.saveValutazioneDermatologicaSuccessResponse, me.saveValutazioneDermatologicaErrorResponse);
        },
        saveValutazioneDermatologicaSuccessResponse(response) {
            console.log("VALUTAZIONE DERMATOLOGICA salvato con successo:", response);
        },
        saveValutazioneDermatologicaErrorResponse(error) {
            console.error("Errore nel salvataggio VALUTAZIONE DERMATOLOGICA:", error);
        },
    },
};
</script>
