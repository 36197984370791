<template>
    <div>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Tipo Caso</label>
                    <b-form-select v-model="jsonData.tipoCaso" :options="tipiCasiOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Data Inizio Sintomi</label>
                    <date-picker v-model="jsonData.dataInizioSintomi" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Ricovero</label>
                    <b-form-select v-model="jsonData.ricovero" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputRicovero"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Data Ricovero</label>
                    <date-picker v-model="jsonData.dataRicovero" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="disabledValue.dataRicoveroDisabled"></date-picker>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Diagnosi" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Dati Clinici</label>
                    <b-form-select v-model="jsonData.datiClinici" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Esame Colturale</label>
                    <b-form-select v-model="jsonData.esameColturale" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Esame Diretto</label>
                    <b-form-select v-model="jsonData.esameDiretto" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Esame Sierologico</label>
                    <b-form-select v-model="jsonData.esameSierologico" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Dettagli</label>
                    <b-form-textarea v-model="jsonData.dettagli" rows="6" no-resize></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Esantema" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Tipo Esantema</label>
                    <b-form-input v-model="jsonData.tipoEsantema"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Localizzato a</label>
                    <b-form-input v-model="jsonData.localizzazioneEsantema"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Eventuale Forma Di Contagio</label>
                    <b-form-input v-model="jsonData.eventualeFormaContagio"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Note</label>
                    <b-form-textarea v-model="jsonData.note" rows="6" no-resize></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Vaccino" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Vaccino</label>
                    <b-form-select v-model="jsonData.vaccino" :options="vaccinoOptions" :value="''" value-field="value" text-field="text" @input="onInputVaccino"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Data Vaccino</label>
                    <date-picker v-model="jsonData.dataVaccino" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="disabledValue.dataVaccinoDisabled"></date-picker>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Contatti" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Contatti</label>
                    <b-form-select v-model="jsonData.contatti" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
            </b-row>
            <b-row style="margin-top: 5px">
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-table sticky-header stacked="xl" striped hover itemscope :items="jsonData.listaContatti" :fields="fieldsContatti" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="row">
                            {{ row.index + 1 }}
                        </template>
                        <template #cell(cognome)="item">
                            <b-form-input v-model="item.item.cognome"></b-form-input>
                        </template>
                        <template #cell(nome)="item">
                            <b-form-input v-model="item.item.nome"></b-form-input>
                        </template>
                        <template #cell(comune)="item">
                            <!-- <b-form-select v-model="item.item.professione" :options="professioniOptions" :value="''" value-field="value" text-field="text"></b-form-select> -->
                            <b-form-input v-model="item.item.comune"></b-form-input>
                        </template>
                        <template #cell(indirizzo)="item">
                            <b-form-input v-model="item.item.indirizzo"></b-form-input>
                        </template>
                        <template #cell(telefono)="item">
                            <b-form-input v-model="item.item.telefono"></b-form-input>
                        </template>
                        <template #cell(actions)="row">
                            <b-button v-if="row.index == jsonData.listaContatti.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddContatti(jsonData.listaContatti, row.item)">
                                <b-icon icon="plus"></b-icon>
                            </b-button>
                            <b-button v-if="row.index !== jsonData.listaContatti.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(jsonData.listaContatti, row)">
                                <b-icon icon="trash"></b-icon>
                            </b-button>
                        </template>
                    </b-table>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Altre Considerazioni</label>
                    <b-form-textarea v-model="jsonData.altreConsiderazioni" rows="6" no-resize></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Dati Notifica" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Sanitario Notificante</label>
                    <b-form-input v-model="jsonData.sanitarioNotificante"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Telefono Sanitario Notificante</label>
                    <b-form-input v-model="jsonData.telefonoSanitarioNotificante"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Notifica</label>
                    <date-picker v-model="jsonData.dataNotifica" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
<script>
import axios from "axios";
// import moment from "moment";
import Vue from "vue";
import EventiMixins from "../../../paziente/eventi/utility/mixins/EventiMixins";
import DatePicker from "vue2-datepicker";
export default {
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    components: { DatePicker },
    data() {
        return {
            pathResource: "/malattieinfettiveierosoliagravidanza",
            pathResourceMalattiaInfettivaSegnalazione: "/malattieinfettivesegnalazioni",
            currentPage: 1,
            perPage: 100,
            linkback: null,
            showModalLoading: false,
            disabledValue: { dataRicoveroDisabled: true, dataVaccinoDisabled: true },
            jsonData: {
                tipoCaso: "",
                dataInizioSintomi: null,
                ricovero: "",
                dataRicovero: null,
                datiClinici: "",
                esameColturale: "",
                esameDiretto: "",
                esameSierologico: "",
                dettagli: "",
                tipoEsantema: "",
                localizzazioneEsantema: "",
                eventualeFormaContagio: "",
                note: "",
                vaccino: "",
                dataVaccino: "",
                contatti: "",
                listaContatti: [],
                sanitarioNotificante: "",
                telefonoSanitarioNotificante: "",
                altreConsiderazioni: "",
                dataNotifica: null,
            },
            fieldsContatti: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Cognome",
                    key: "cognome",
                    thStyle: "width: 20rem",
                    sortable: false,
                },
                {
                    label: "Nome",
                    key: "nome",
                    thStyle: "width: 20rem",
                    sortable: false,
                },
                {
                    label: "Comune",
                    key: "comune",
                    thStyle: "width: 10rem",
                    sortable: false,
                },
                {
                    label: "Indirizzo",
                    key: "indirizzo",
                    // thStyle: "width: 5rem",
                    sortable: false,
                },
                {
                    label: "Telefono",
                    key: "telefono",
                    // thStyle: "width: 5rem",
                    sortable: false,
                },
                {
                    label: "",
                    key: "actions",
                    sortable: false,
                    thStyle: "width: 4rem",
                },
            ],
            siNoOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "SI", text: "SI" },
                { value: "NO", text: "NO" },
            ],
            chemioprofilassiOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "SI", text: "SI" },
                { value: "NO", text: "NO" },
                { value: "INCOMPLETA", text: "INCOMPLETA" },
            ],
            tipiCasiOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "PROBABILE", text: "PROBABILE" },
                { value: "ACCERTATO", text: "ACCERTATO" },
            ],
            vaccinoOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "VACCINATO", text: "VACCINATO" },
                { value: "NON VACCINATO", text: "NON VACCINATO" },
                { value: "NON NOTO", text: "NON NOTO" },
            ],
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient() {
            return EventiMixins.getLinkPatient(this.utente, this.idPaziente);
        },
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.jsonData.idAnagrafica = me.idPaziente;
        me.jsonData.id = me.id;
        me.loadMalattiaInfettivaSegnalazione();
        me.loadData();
    },
    watch: {
        "jsonData.contatti": function (value) {
            let me = this;
            if (value === "SI") {
                me.jsonData.listaContatti.push({ cognome: "", nome: "", comune: "", indirizzo: "", telefono: "" });
            } else {
                me.jsonData.listaContatti = [];
            }
        },
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            me.showModalLoading = true;
            axios
                .get(link + me.id)
                .then((response) => {
                    me.jsonData = response.data.data;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.setDatiMedico();
                    me.showModalLoading = false;
                });
        },
        loadMalattiaInfettivaSegnalazione() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceMalattiaInfettivaSegnalazione + "/";
            axios
                .get(link + me.id)
                .then((response) => {
                    me.setDatiMalattia(response.data.data);
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        onInputRicovero(value) {
            let me = this;
            if (value === "SI") {
                me.disabledValue.dataRicoveroDisabled = false;
            } else {
                me.disabledValue.dataRicoveroDisabled = true;
                me.jsonData.dataRicovero = null;
            }
        },
        onInputVaccino(value) {
            let me = this;
            if (value === "VACCINATO") {
                me.disabledValue.dataVaccinoDisabled = false;
            } else {
                me.disabledValue.dataVaccinoDisabled = true;
                me.jsonData.dataVaccino = null;
            }
        },
        onAddContatti(array, value) {
            if (value.cognome !== "" && value.nome !== "" && value.comune !== "" && value.indirizzo !== "" && value.telefono !== "") {
                array.push({ cognome: "", nome: "", comune: "", indirizzo: "", telefono: "" });
            } else {
                this.$bvModal
                    .msgBoxOk("Tutti i campi sono OBBLIGATORI", {
                        title: "ATTENZIONE",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    })
                    .then((value) => {
                        console.log(value);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        onDelete(array, row) {
            if (row.index != array.length) {
                this.$bvModal
                    .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
                        title: "Titolo",
                        buttonSize: "sm",
                        okVariant: "secondary",
                        cancelVariant: "danger",
                        okTitle: "SI",
                        cancelTitle: "NO",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true,
                    })
                    .then((value) => {
                        if (value) {
                            array.splice(row.index, 1);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        setDatiMedico() {
            let me = this;
            me.jsonData.sanitarioNotificante = Vue.prototype.user.firstname + " " + Vue.prototype.user.lastname;
        },
        setDatiMalattia(data) {
            let me = this;
            me.jsonData.id = data.id;
        },
    },
};
</script>
