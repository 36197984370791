<template>
    <div>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card sa-card-border" header="Polso Radiale" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Frequenza (BPM)</label>
                            <b-form-input v-model="jsonDataPolsoRadiale.frequenzaBpm" type="number" step="0.01" :readonly="isReadOnly"> </b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Ritmo</label>
                            <b-form-select v-model="jsonDataPolsoRadiale.ritmo" :options="ritmoOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Polsi Periferici</label>
                            <b-form-select v-model="jsonDataPolsoRadiale.polsoPeriferico" :options="polsoPerifericoOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Respiro</label>
                            <b-form-select v-model="jsonDataPolsoRadiale.respiro" :options="respiroOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Descrizione Respiro</label>
                            <b-form-input v-model="jsonDataPolsoRadiale.respiroDescrizione" :readonly="isReadOnly"> </b-form-input>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Note</label>
                            <b-form-textarea v-model="jsonDataPolsoRadiale.notePolsoRadiale" :readonly="isReadOnly" max-rows="10" rows="7"> </b-form-textarea>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import UtilityMixin from "../../../utility/UtilityMixin";

export default {
    props: {
        isReadOnly: {
            type: Boolean,
            default: false,
        },
        idAccettazione: {
            type: String,
        },
    },
    mixins: [UtilityMixin],
    data() {
        return {
            pathResourceSchedaEsameObiettivoGeneralePolsoRadiale: "/rsaschedaesameobiettivogeneralepolsoradiale",
            jsonDataPolsoRadiale: {
                id: null,
                idAccettazione: null,
                ritmo: null,
                polsoPeriferico: null,
                frequenzaBpm: null,
                respiro: null,
                respiroDescrizione: null,
                notePolsoRadiale: null,
            },

            ritmoOptions: [
                { text: "EURITMICO", value: "EURITMICO" },
                { text: "ARITMICO", value: "ARITMICO" },
            ],
            polsoPerifericoOptions: [
                { text: "NORMOSFIGMICI", value: "NORMOSFIGMICI" },
                { text: "IPOSFIGMICI", value: "IPOSFIGMICI" },
                { text: "ASIMMETRICI", value: "ASIMMETRICI" },
                { text: "ASSENTI", value: "ASSENTI" },
            ],
            respiroOptions: [
                { text: "EUPNOICO", value: "EUPNOICO" },
                { text: "DISPNOICO", value: "DISPNOICO" },
                { text: "ANOMALO", value: "ANOMALO" },
            ],
        };
    },
    methods: {
        loadPolsoRadialeData() {
            let me = this;
            // console.log("ID accettazione nel loadPolsoRadialeData: " + me.idAccettazione);
            UtilityMixin.methods.loadDataResources(me.pathResourceSchedaEsameObiettivoGeneralePolsoRadiale + "/accettazione/" + me.idAccettazione, {}, me.resultResponseSchedaEsameObiettivoGeneralePolsoRadialeSuccess, me.resultResponseSchedaEsameObiettivoGeneralePolsoRadialeError);
        },
        resultResponseSchedaEsameObiettivoGeneralePolsoRadialeSuccess(response) {
            let me = this;
            me.jsonDataPolsoRadiale = response.data.data;
            // console.log("ID del record POLSO RADIALE:", me.jsonDataPolsoRadiale.id);
        },
        resultResponseSchedaEsameObiettivoGeneralePolsoRadialeError(error) {
            let me = this;
            if (error.response && error.response.status === 404) {
                //console.log("NESSUN record trovato del POLSO RADIALE per questo id accettazione");
                me.resetForm();
            } else {
                console.error("Errore nel caricamento dati della sezione POLSO RADIALE:", error);
            }
        },
        resetForm() {
            let me = this;
            me.jsonDataPolsoRadiale = {
                id: null,
                idAccettazione: me.idAccettazione,
                ritmo: null,
                polsoPeriferico: null,
                frequenzaBpm: null,
                respiro: null,
                respiroDescrizione: null,
                notePolsoRadiale: null,
            };
        },

        onSalvaPolsoRadiale() {
            let me = this;
            me.jsonDataPolsoRadiale.idAccettazione = me.idAccettazione;
            // console.log("Salvataggio POLSO RADIALE con dati:", JSON.stringify(me.jsonDataPolsoRadiale));
            if (!me.jsonDataPolsoRadiale.idAccettazione) {
                // console.log("idAccettazione: ", me.jsonDataPolsoRadiale.idAccettazione);
                me.$bvToast.toast("Errore: ID Accettazione mancante", {
                    title: "Errore",
                    variant: "danger",
                    solid: true,
                });
                return;
            }

            UtilityMixin.methods.saveResource(me.pathResourceSchedaEsameObiettivoGeneralePolsoRadiale, me.jsonDataPolsoRadiale, me.saveSchedaEsameObiettivoGeneralePolsoRadialeSuccessResponse, me.saveSchedaEsameObiettivoGeneralePolsoRadialeErrorResponse);
        },
        saveSchedaEsameObiettivoGeneralePolsoRadialeSuccessResponse() {
            //console.log("POLSO RADIALE salvato con successo:", response);
        },
        saveSchedaEsameObiettivoGeneralePolsoRadialeErrorResponse() {
            console.error("Errore nel salvataggio POLSO RADIALE:");
        },
    },
};
</script>
