<template>
    <div>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Tipo Caso</label>
                    <span class="sa-data">{{ jsonData.tipoCaso }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Data Inizio Sintomi</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataInizioSintomi) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Ricovero Ospedaliero</label>
                    <span class="sa-data">{{ jsonData.ricoveroOspedaliero }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Nome Ospedale</label>
                    <span class="sa-data">{{ jsonData.nomeOspedale }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Data Ricovero</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataRicovero) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Data Dimissione</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataDimissione) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <h4>Protocollo Diagnostico</h4>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Diagnosi Clinica</label>
                    <span class="sa-data">{{ jsonData.diagnosiClinica }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Coprocultura</label>
                    <span class="sa-data">{{ jsonData.coprocultura }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">In Famiglia Qualcuno Ha Già Sofferto Di Questa Malattia?</label>
                    <span class="sa-data">{{ jsonData.salmonellosiFamiliari }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-table sticky-header stacked="xl" striped hover itemscope :items="jsonData.listaFamiliari" :fields="fieldsFamiliari" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="row">
                            {{ row.index + 1 }}
                        </template>
                    </b-table>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Abitualmente Consuma Verdure Crude</label>
                    <span class="sa-data">{{ jsonData.consumoVerdureCrude }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Nei due/tre giorni precedenti la malattia ha consumato uova crude o poco cotte o alimenti a base di uova?</label>
                    <span class="sa-data">{{ jsonData.consumoUova }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">Presso la propria abitazione</label>
                    <span class="sa-data">{{ jsonData.consumoUovaAbitazione }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Dove sono state acquistate</label>
                    <span class="sa-data">{{ jsonData.luogoAcquistoUova }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">Presso ristoranti</label>
                    <span class="sa-data">{{ jsonData.consumoUovaRistorante }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">Nome Ristorante</label>
                    <span class="sa-data">{{ jsonData.nomeRistorante }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Indirizzo Ristorante</label>
                    <span class="sa-data">{{ jsonData.indirizzoRistorante }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Sanitario Notificante</label>
                    <span class="sa-data">{{ jsonData.sanitarioNotificante }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Contatto Sanitario Notificante</label>
                    <span class="sa-data">{{ jsonData.contattoSanitarioNotificante }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Notifica</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataNotifica) }}</span>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
<script>
import axios from "axios";
import UtilityMixin from "../../../utility/UtilityMixin";
export default {
    props: {},
    mixins: [UtilityMixin],
    data() {
        return {
            pathResource: "/malattieinfettiveiesalmonellosinontifoidea",
            id: "-1",
            perPage: 100,
            currentPage: 1,
            jsonData: {
                tipoCaso: "",
                dataInizioSintomi: null,
                ricoveroOspedaliero: "",
                nomeOspedale: "",
                dataRicovero: null,
                dataDimissione: null,
                diagnosiClinica: "",
                coprocultura: "",
                salmonellosiFamiliari: "",
                listaFamiliari: [],
                consumoVerdureCrude: "",
                consumoUova: "",
                consumoUovaAbitazione: "",
                luogoAcquistoUova: "",
                consumoUovaRistorante: "",
                nomeRistorante: "",
                indirizzoRistorante: "",
                sanitarioNotificante: "",
                contattoSanitarioNotificante: "",
                dataNotifica: null,
            },
            fieldsFamiliari: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Familiare",
                    key: "tipoFamiliare",
                    sortable: false,
                },
                {
                    label: "Data Malattia",
                    key: "dataMalattia",
                    formatter: function (value) {
                        return UtilityMixin.methods.formatDate(value);
                    },
                    thStyle: "width: 10rem",
                    sortable: false,
                },
                // {
                //     label: "",
                //     key: "actions",
                //     sortable: false,
                //     thStyle: "width: 4rem",
                // },
            ],
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.$emit("showmodalloading", true);
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            axios
                .get(link + me.id)
                .then((response) => {
                    me.jsonData = response.data.data;
                    me.$emit("showmodalloading", false);
                })
                .catch(() => {
                    me.$emit("showmodalloading", false);
                });
        },
    },
};
</script>
