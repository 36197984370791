<template>
    <sa-page-layout ref="cmpSaPageLayout" :btnSaveVisible="false" :data="jsonData" :btnAnnullaVisible="true" :showModalLoading="showModalLoading" :linkback="linkback" :pathResource="pathResource" class="sa-no-space">
        <template slot="toolbar">
            <b-button variant="success btn-toolbar float-sm-right sa-margin-right" size="sm" @click="onSaveScheda('ATTIVA')"><b-icon icon="check2"></b-icon>Salva e Chiudi</b-button>
            <b-button variant="success btn-toolbar float-sm-right sa-margin-right" size="sm" @click="onSaveScheda('BOZZA')"><b-icon icon="check2"></b-icon>Salva Bozza</b-button>
        </template>
        <template slot="toolbar-title">
            <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
        </template>
        <template slot="table-header">
            <b-card class="sa-card" header="Dati generali" header-tag="header" footer-tag="footer" title="">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Malattia Segnalata: {{ malattiaInfettivaSegnalata }}</label>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Uosd Diagnosi: {{ uosdDiagnosi }}</label>
                    </b-col>
                </b-row>
            </b-card>
        </template>
        <template slot="table-body">
            <div class="sa-tab-scroll">
                <b-card class="sa-card" header="Anagrafica" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Codice Fiscale/STP/ENI:</label>
                            <span class="sa-data">
                                {{ paziente.identificativo }}
                            </span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Data Nascita:</label>
                            <span class="sa-data">
                                {{ formatDate(paziente.dataNascita) }}
                            </span>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Nome:</label>
                            <span class="sa-data">
                                {{ paziente.nome }}
                            </span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Cognome:</label
                            ><span class="sa-data">
                                {{ paziente.cognome }}
                            </span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Sesso:</label
                            ><span class="sa-data">
                                {{ paziente.sesso }}
                            </span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Contatto Telefonico:</label
                            ><span class="sa-data">
                                {{ paziente.telefono }}
                            </span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Email:</label
                            ><span class="sa-data">
                                {{ paziente.email }}
                            </span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Provincia di Nascita:</label
                            ><span class="sa-data">
                                {{ paziente.provinciaNascita }}
                            </span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Comune di Nascita:</label
                            ><span class="sa-data">
                                {{ paziente.comuneNascita }}
                            </span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Comune di Residenza: </label
                            ><span class="sa-data">
                                {{ paziente.comuneResidenza }}
                            </span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Indirizzo di Residenza: </label
                            ><span class="sa-data">
                                {{ paziente.indirizzoResidenza }}
                            </span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Provincia di Residenza: </label
                            ><span class="sa-data">
                                {{ paziente.provinciaResidenza }}
                            </span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Comune di Domicilio: </label
                            ><span class="sa-data">
                                {{ paziente.comuneDomicilio }}
                            </span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Indirizzo di Domicilio: </label
                            ><span class="sa-data">
                                {{ paziente.indirizzoDomicilio }}
                            </span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Provinca di Domicilio: </label
                            ><span class="sa-data">
                                {{ paziente.provinciaDomicilio }}
                            </span>
                        </b-col>
                    </b-row>
                </b-card>
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <component ref="itemRefs" :is="currentComponentEdit" v-bind="currentProperties" @showmodalloading="onShowModalLoading" @update="onUpdateJsonData"></component>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <indagine-epidemiologica-diagnosi-finale-component ref="IndagineEpidemiologicaDiagnosiFinaleComponent" :segnalazione="segnalazione" :isEdit="true" @update="onUpdateJsonData"></indagine-epidemiologica-diagnosi-finale-component>
                    </b-col>
                </b-row>
            </div>
        </template>
    </sa-page-layout>
</template>

<script>
// import axios from "axios";
import UtilityMixin from "../../../utility/UtilityMixin";
import indagini from "../components";
import axios from "axios";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../../paziente/components/PazienteAnagrafica.vue";
import IndagineEpidemiologicaEditComponent from "../components/IndagineEpidemiologicaEditComponent.vue";
import IndagineEpidemiologicaDiagnosiFinaleComponent from "../components/IndagineEpidemiologicaDiagnosiFinaleComponent.vue";
// import EventiMixins from "../../../paziente/eventi/utility/mixins/EventiMixins";
export default {
    components: { SaPageLayout, PazienteAnagrafica, IndagineEpidemiologicaEditComponent, IndagineEpidemiologicaDiagnosiFinaleComponent },
    mixins: [UtilityMixin],
    data() {
        return {
            pathResource: "/malattieinfettiveie",
            pathResourceMalattiaInfettivaSegnalazione: "/malattieinfettivesegnalazioni",
            showModalLoading: false,
            linkback: "/malattieinfettive/segnalazioni",
            isStatoEstero: false,
            isRicercheDiagnostiche: false,
            isContatti: false,
            currentPage: 1,
            fonte: "",
            perPage: 100,
            malattiaInfettivaSegnalata: "",
            segnalazione: {},
            uosdDiagnosi: "",
            indagineEpidemiologicaComponent: null,
            jsonData: {},
            paziente: {},
            currentComponentEdit: null,
            currentProperties: {},
        };
    },
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient() {
            return UtilityMixin.methods.getLinkPatient(this.utente, this.idPaziente);
        },
        rows() {
            return this.sintomiOptions.length;
        },
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.indagineEpidemiologicaComponent = this.$route.query.indagineEpidemiologicaComponent;
        me.fonte = this.$route.query.fonte;
        (me.linkback = "/malattieinfettive" + me.fonte), me.setPathResource();
        me.setMalattiaSegnalata();
        me.currentProperties = { showmodalloading: me.onShowModalLoading, update: me.onUpdateJsonData, utente: me.utente };
        me.currentComponentEdit = indagini.editComponents()[me.indagineEpidemiologicaComponent + "EditComponent.vue"];
    },

    methods: {
        onShowModalLoading(value) {
            let me = this;
            me.showModalLoading = value;
        },
        onUpdateJsonData(data) {
            let me = this;
            // console.log("eccomi");
            // console.log(data);
            me.jsonData = data;
            me.$refs.IndagineEpidemiologicaDiagnosiFinaleComponent.setData(data);
        },
        setMalattiaSegnalata() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceMalattiaInfettivaSegnalazione + "/";
            axios
                .get(link + me.id)
                .then((response) => {
                    me.segnalazione = response.data.data;
                    me.paziente = me.segnalazione.pazienteBean;
                    if (response.data.data.malattiaSegnalata !== "ALTRA MALATTIA (ASLNA10000)") {
                        me.malattiaInfettivaSegnalata = response.data.data.malattiaSegnalata;
                    } else {
                        me.malattiaInfettivaSegnalata = response.data.data.malattiaSegnalataAltra;
                    }
                    me.uosdDiagnosi = response.data.data.uosdDiagnosi;
                    // console.log(response.data.data);
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        setPathResource() {
            let me = this;
            switch (me.indagineEpidemiologicaComponent) {
                case "IndagineEpidemiologica":
                    me.pathResource = "/malattieinfettiveie";
                    break;
                case "IndagineEpidemiologicaListeriosi":
                    me.pathResource = "/malattieinfettiveielisteriosi";
                    break;
                case "IndagineEpidemiologicaInfezioniAlimentari":
                    me.pathResource = "/malattieinfettiveieinfezionialimentari";
                    break;
                case "IndagineEpidemiologicaLegionellosi":
                    me.pathResource = "/malattieinfettiveielegionellosi";
                    break;
                case "IndagineEpidemiologicaEpatiti":
                    me.pathResource = "/malattieinfettiveieepatiti";
                    break;
                case "IndagineEpidemiologicaLeishmaniosiViscerale":
                    me.pathResource = "/malattieinfettiveieleishmaniosiviscerale";
                    break;
                case "IndagineEpidemiologicaCovid":
                    me.pathResource = "/malattieinfettiveiecovid";
                    break;
                case "IndagineEpidemiologicaEpatiteE":
                    me.pathResource = "/malattieinfettiveieepatitee";
                    break;
                case "IndagineEpidemiologicaRosoliaCongenita":
                    me.pathResource = "/malattieinfettiveierosoliacongenita";
                    break;
                case "IndagineEpidemioogicaTubercolosiMicobatteriosiNonTubercolari":
                    me.pathResource = "/malattieinfettiveietubercolosimicrobatterinontubercolari";
                    break;
                case "IndagineEpidemiologicaEncefaliteMeningiteSMeningococcia":
                    me.pathResource = "/malattieinfettiveieencefalitemeningitesmeningococcica";
                    break;
                case "IndagineEpidemiologicaTossinfezioneAlimentare":
                    me.pathResource = "/malattieinfettiveietossinfezionealimentare";
                    break;
                case "IndagineEpidemiologicaMalaria":
                    me.pathResource = "/malattieinfettiveiemalaria";
                    break;
                case "IndagineEpidemiologicaMorbilloRosolia":
                    me.pathResource = "/malattieinfettiveiemorbillorosolia";
                    break;
                case "IndagineEpidemiologicaInfluenza":
                    me.pathResource = "/malattieinfettiveieinfluenza";
                    break;
                case "IndagineEpidemiologicaTetano":
                    me.pathResource = "/malattieinfettiveietetano";
                    break;
                case "IndagineEpidemiologicaMalattieTrasmissioneSessuale":
                    me.pathResource = "/malattieinfettiveiemalattietrasmissionesessuale";
                    break;
                case "IndagineEpidemiologicaRosoliaGravidanza":
                    me.pathResource = "/malattieinfettiveierosoliagravidanza";
                    break;
                case "IndagineEpidemiologicaAltriCasiParticolari":
                    me.pathResource = "/malattieinfettiveiealtricasiparticolari";
                    break;
                case "IndagineEpidemiologicaWestNile":
                    me.pathResource = "/malattieinfettiveiealtricasiparticolari";
                    break;
                case "IndagineEpidemiologicaEpatiteA":
                    me.pathResource = "/malattieinfettiveieepatitea";
                    break;
                case "IndagineEpidemiologicaMalattiaJakobCreutzfeld":
                    me.pathResource = "/malattieinfettiveiemalattiacreutzfeldtjakob";
                    break;
            }
        },
        onSaveScheda(stato) {
            let me = this;
            me.jsonData.statoAttuale = stato;
            me.$refs.cmpSaPageLayout.save(true);
        },
    },
};
</script>
