<template>
    <sa-page-layout :btnBackVisible="true" :btnEditVisible="true" :linkedit="linkedit" :linkback="linkback" :pathResource="pathResource" :data="jsonData" :showModalLoading="showModalLoading" class="sa-no-space">
        <template slot="table-body">
            <cot-strutture-view-component @update="onUpdateJsonData"></cot-strutture-view-component>
        </template>
    </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import CotStruttureViewComponent from "../components/CotStruttureViewComponent.vue";
export default {
    components: { SaPageLayout, CotStruttureViewComponent },
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    data() {
        return {
            linkback: "/cot/strutture",
            jsonData: {},
            showModalLoading: false,
            pathResource: "/cotstrutture",
            linkedit: null,
        };
    },
    mounted: function () {
        let me = this;
        me.id = this.$route.params.id;
        me.linkedit = "/cot/strutture/edit/" + me.id;
    },
    methods: {
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
        },
    },
};
</script>

<style></style>
