<template>
    <div class="sa-calendar-day">
        <div class="sa-calendar-day-content">
            <b-row id="agenda-day-grid" style="margin-top: 10px">
                <agenda-day-griglia-component :idAgenda="idAgenda" :costo="costo" :dataRiferimento="dataRiferimento" :descrizionePrestazione="descrizionePrestazione" :codicePrestazione="codicePrestazione" :griglia="gridData" @update="updateData"></agenda-day-griglia-component>
            </b-row>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import AgendaDayGrigliaComponent from "./AgendaDayGrigliaComponent.vue";

export default {
    components: { AgendaDayGrigliaComponent }, //AgendaDayList
    computed: {
        togleIcon: function () {
            return !this.switchGrigliaList ? "grid-3x3" : "list-ul";
        },
    },
    data() {
        // return {
        //     pathResourceDisponibilitaAgenda
        //     gridData: [],
        //     switchGrigliaList: true,
        // };
        return {
            idAgenda: null,
            costo: null,
            dataRiferimento: null,
            descrizionePrestazione: null,
            codicePrestazione: null,
            items: [],
            disponibilita: [],
            pathResourceDisponibilitaAgenda: "/agendadisponibilita",
            periodo: "G",
            jsonAgenda: null,
            jsonPrestazione: null,
            gridData: [],
            switchGrigliaList: true,
        };
    },
    mounted: function () {
        let me = this;
        me.setRiferimento();
    },
    watch: {
        disponibilita: function () {
            let column = 7;
            let griglia = [];
            let count = 0;
            let row = [];
            let numeroRighe = 0;
            if (this.disponibilita.length > 0) {
                // numeroRighe = Math.trunc(this.disponibilita[0].disponibilita.length / column);
                numeroRighe = this.disponibilita[0].disponibilita.length % column > 0 ? numeroRighe + 1 : numeroRighe;

                this.disponibilita[0].disponibilita.forEach((element) => {
                    if (count < column) {
                        row.push(element);
                        count++;
                    }

                    let numeroElementi = griglia.length * column + row.length;
                    if (count === column || (griglia.length === numeroRighe - 1 && this.disponibilita[0].disponibilita.length === numeroElementi)) {
                        griglia.push(row);
                        row = [];
                        count = 0;
                    }
                });
            }

            this.gridData = griglia;
        },
    },
    methods: {
        setRiferimento(dataSelezionata = null) {
            if (dataSelezionata === null) {
                dataSelezionata = new Date();
            }
        },
        onHourClick(giorno) {
            let me = this;
            me.setRiferimento(giorno.date);
        },
        updateData() {
            let me = this;
            me.$emit("update");
        },
        loadDisponibilita(jsonAgenda, riferimento, jsonPrestazione) {
            let me = this;
            me.jsonAgenda = jsonAgenda;
            me.jsonPrestazione = jsonPrestazione;
            if (jsonPrestazione.codicePrestazione) {
                let link = process.env.VUE_APP_PATH_API + me.pathResourceDisponibilitaAgenda + "/";
                axios
                    .get(link, {
                        params: {
                            idAgenda: jsonAgenda.id,
                            periodo: me.periodo,
                            riferimento: riferimento,
                            codicePrestazione: jsonPrestazione.codicePrestazione,
                        },
                    })
                    .then((response) => {
                        console.log(response);
                        me.disponibilita = response.data.data == null ? [] : response.data.data;
                        let value = 0;
                        me.disponibilita.forEach((elemento) => {
                            value += elemento.disponibilita.length;
                        });
                        me.numeroDisponibilita = value;
                    })
                    .catch((error) => {
                        me.$bvToast.toast(error.response.data.messaggio, {
                            title: "Error",
                            variant: "danger",
                            solid: true,
                        });
                    });
            } else {
                return;
            }
        },
    },
};
</script>
<style scoped>
.sa-calendar-day {
    height: 100%;
    padding-bottom: 1.25rem;
    /* overflow-y: auto; */
}
.sa-calendar-day-content {
    height: 100%;
}
#agenda-day-grid {
    height: 100%;
    padding-bottom: 45px;
}
</style>
