<template>
    <div v-if="isEdit">
        <b-row>
            <b-table sticky-header ref="tblViaggi" stacked="xl" striped hover itemscope :items="listaAlimentiConsumati" :fields="fieldsAlimenti" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                <template #cell(marca)="item">
                    <b-form-input v-model="item.item.marca" placeholder="Marca"></b-form-input>
                </template>
                <template #cell(luogoAcquisto)="item">
                    <b-form-input v-model="item.item.luogoAcquisto" placeholder="Luogo Acquisto"></b-form-input>
                </template>
                <template #cell(dataAcquisto)="item">
                    <b-form-input v-model="item.item.dataAcquisto" placeholder="Data Acquisto"></b-form-input>
                    <!-- <date-picker v-model="item.item.dataAcquisto" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker> -->
                </template>
                <template #cell(luogoPreparazione)="item">
                    <b-form-input v-model="item.item.luogoPreparazione" placeholder="Luogo Preparazione"></b-form-input>
                </template>
                <template #cell(dataPreparazione)="item">
                    <b-form-input v-model="item.item.dataPreparazione" placeholder="Data Preparazione"></b-form-input>
                    <!-- <date-picker v-model="item.item.dataPreparazione" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker> -->
                </template>
                <template #cell(luogoConsumo)="item">
                    <b-form-input v-model="item.item.luogoConsumo" placeholder="Luogo Consumo"></b-form-input>
                </template>
                <template #cell(dataConsumo)="item">
                    <b-form-input v-model="item.item.dataConsumo" placeholder="Data Consumo"></b-form-input>
                    <!-- <date-picker v-model="item.item.dataConsumo" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker> -->
                </template>
                <template #cell(tipologia)="item">
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="item.item.tipologia" :options="tipologiaOptions" value="" value-field="value" text-field="text"></b-form-select>
                </template>
            </b-table>
        </b-row>
    </div>
    <div v-else>
        <b-row>
            <b-table sticky-header ref="tblViaggi" stacked="xl" striped hover itemscope :items="listaAlimentiConsumati" :fields="fieldsAlimenti" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                <template #cell(marca)="item">
                    <span class="sa-data">{{ item.item.marca }}</span>
                </template>
                <template #cell(luogoAcquisto)="item">
                    <span class="sa-data">{{ item.item.luogoAcquisto }}</span>
                </template>
                <template #cell(dataAcquisto)="item">
                    <span class="sa-data">{{ item.item.dataAcquisto }}</span>
                </template>
                <template #cell(luogoPreparazione)="item">
                    <span class="sa-data">{{ item.item.luogoPreparazione }}</span>
                </template>
                <template #cell(dataPreparazione)="item">
                    <span class="sa-data">{{ item.item.dataPreparazione }}</span>
                </template>
                <template #cell(luogoConsumo)="item">
                    <span class="sa-data">{{ item.item.luogoConsumo }}</span>
                </template>
                <template #cell(dataConsumo)="item">
                    <span class="sa-data">{{ item.item.dataConsumo }}</span>
                </template>
                <template #cell(tipologia)="item">
                    <span class="sa-data">{{ item.item.tipologia }}</span>
                </template>
            </b-table>
        </b-row>
    </div>
</template>

<script>
import UtilityMixin from "../../../utility/UtilityMixin";
// import DatePicker from "vue2-datepicker";
export default {
    mixins: [UtilityMixin],
    // components: { DatePicker },
    props: {
        // jsonData: {
        //   type: Object,
        //   default: function () {
        //     return null;
        //   },
        // },
        isEdit: Boolean,
        listaAlimentiConsumati: { type: [Array, Object] },
        famigliaAlimenti: String,
    },
    watch: {
        listaAlimentiConsumati: {
            handler(listaAlimentiConsumati) {
                this.$emit("update", this.famigliaAlimenti, listaAlimentiConsumati);
            },
            deep: true,
        },
    },
    // mounted() {
    //   let me = this;
    //   console.log(me.listaAlimentiConsumati);
    // },
    data() {
        return {
            currentPage: 1,
            perPage: 100,
            fieldsAlimenti: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Alimento",
                    key: "alimento",
                    thStyle: "width: 11rem",
                    sortable: false,
                },
                {
                    label: "Sottotipo/Modalita",
                    key: "sottotipoModalita",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
                {
                    label: "Marca",
                    key: "marca",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
                {
                    label: "L. Acquisto",
                    key: "luogoAcquisto",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
                {
                    label: "D. Acquisto",
                    key: "dataAcquisto",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
                {
                    label: "L. Preparazione",
                    key: "luogoPreparazione",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
                {
                    label: "D. Preparazione",
                    key: "dataPreparazione",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
                {
                    label: "L. Consumo",
                    key: "luogoConsumo",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
                {
                    label: "D. Consumo",
                    key: "dataConsumo",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
                {
                    label: "Tipologia",
                    key: "tipologia",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
            ],
            tipologiaOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "FRESCO", text: "FRESCO" },
                { value: "REFRIGERATO", text: "REFRIGERATO" },
                { value: "CONGELATO", text: "CONGELATO" },
            ],
        };
    },
};
</script>

<style></style>
