<template>
    <div style="height: 100%">
        <div class="sa-flexbox" style="padding-bottom: 20px">
            <div class="sa-flexbox-header"></div>
            <div class="sa-flexbox-body">
                <div class="b-table-sticky-header">
                    <b-table sticky-header ref="table" stacked="lg" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
                        <template v-slot:cell(nominativo)="{ item }">
                            <span class="sa-table-span-label-primary">{{ item.nome }} {{ item.cognome }}</span>
                            <span class="sa-table-span-label-secondary">{{ item.identificativo }}</span>
                        </template>
                        <template v-slot:cell(actions)="{ item }">
                            <b-container class="bv-example-row">
                                <b-button size="sm" v-b-tooltip.hover title="Cancella Impegnativa" variant="outline-danger no-text" @click="onDeleteDatiFatturazione(item)">
                                    <b-icon icon="trash"></b-icon>
                                </b-button>
                                <b-button size="sm" v-b-tooltip.hover title="Modifica Impegnativa" variant="outline-primary no-text" @click="onEditDatiFatturazione(item)">
                                    <b-icon icon="pencil"></b-icon>
                                </b-button>
                            </b-container>
                        </template>
                    </b-table>
                </div>
            </div>
            <div class="sa-flexbox-footer" style="background-color: ciano">
                <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col lg="3">
                        <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rows }}</p>
                    </b-col>
                    <b-col lg="9">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                    </b-col>
                </b-row>
            </div>
            <rsa-accettazione-dati-fatturazione-component ref="cmpRsaAccettazioneDatiFatturazioneComponent" @afterSaveRequest="onAfterSaveRequestRsaDatiFatturazioneComponent"></rsa-accettazione-dati-fatturazione-component>
        </div>
    </div>
</template>
<script>
import axios from "axios";
//import DatePicker from "vue2-datepicker";
import UtilityMixin from "../../../utility/UtilityMixin";
import RsaAccettazioneDatiFatturazioneComponent from "./RsaAccettazioneDatiFatturazioneComponent.vue";
export default {
    props: {},
    mixins: [UtilityMixin],
    components: {
        // DatePicker,
        RsaAccettazioneDatiFatturazioneComponent,
    },
    data() {
        return {
            id: "",
            jsonData: {},
            rows: 0,
            perPage: 50,
            currentPage: 1,
            filtro: {},
            items: [],
            listKey: 0,
            pathResource: "/rsadatifatturazione",
            linkedit: "/",
            idAccettazione: "",
            jsonDataAccettazione: {},
            fields: [
                { label: "Nominativo", key: "nominativo" },
                { label: "P.E.C", key: "pec" },
                { label: "Codice Destinatario", key: "codiceDestinatario" },
                { label: "Codice Destinatario", key: "statoAttuale" },
                { class: "sa-table-column-action-double", key: "actions", label: "" },
            ],
        };
    },
    mounted() {
        //  let me = this;
        //  me.idAccettazione = me.$route.params.id;
        // me.loadData();
    },
    watch: {
        currentPage() {
            let me = this;
            me.loadData();
        },
    },
    methods: {
        init(jsonDataAccettazione) {
            let me = this;
            me.jsonDataAccettazione = jsonDataAccettazione;
            me.idAccettazione = jsonDataAccettazione.id;
            me.loadData();
        },
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.filtro.page = me.currentPage;
            me.filtro.forPage = me.perPage;
            me.filtro.idAccettazione = me.idAccettazione;
            me.$emit("beforeLoadData");
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];
                    me.rows = response.data.data.recordsNumber;
                    me.items = response.data.data.list;
                    me.listKey++;
                    this.$emit("afterLoadData");
                })
                .catch(() => {
                    this.$emit("afterLoadData");
                });
        },
        nuoviDatiFatturazione() {
            let me = this;
            me.$refs.cmpRsaAccettazioneDatiFatturazioneComponent.showNuoviDatiFatturazione();
            me.$refs.cmpRsaAccettazioneDatiFatturazioneComponent.setData(me.jsonDataAccettazione);
        },
        onEditDatiFatturazione(item) {
            let me = this;
            me.$refs.cmpRsaAccettazioneDatiFatturazioneComponent.showNuoviDatiFatturazione();
            me.$refs.cmpRsaAccettazioneDatiFatturazioneComponent.loadDatiFatturazione(item.id, me.jsonDataAccettazione);
        },
        onDeleteDatiFatturazione(item) {
            let me = this;
            this.$bvModal
                .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
                    title: "Eliminazione Ricetta",
                    buttonSize: "sm",
                    okVariant: "secondary",
                    cancelVariant: "danger",
                    okTitle: "SI",
                    cancelTitle: "NO",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                })
                .then((value) => {
                    if (value) {
                        me.deleteDatiFatturazione(item.id);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        deleteDatiFatturazione(id) {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + id;
            axios
                .delete(link)
                .then((Response) => {
                    console.log(Response);

                    me.showModalLoading = false;
                    me.loadData();
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        onAfterSaveRequestRsaDatiFatturazioneComponent() {
            let me = this;
            me.$refs.cmpRsaAccettazioneDatiFatturazioneComponent.close();
            this.$bvToast.toast(`Richiesta salvata correttamente`, {
                title: "Dati Fatturazione",
                solid: true,
                variant: "success",
                autoHideDelay: 5000,
                appendToast: true,
            });
            me.loadData();
        },
    },
};
</script>
