<template>
  <b-modal title="Seleziona Prestazione" ref="mdlNomenclatoreList" id="mdlNomenclatoreList" modal-class="sa-modal-static-scroll" size="lg" @ok="onOk">
    <div class="sa-list-component">
      <div class="sa-list-component-header">
        <b-form @submit.prevent="onSubmit">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Codice Prestazione:</label>
              <b-form-input v-model="filtro.codicePrestazione" type="search" id="codicePrestazione" placeholder="Codice Prestazione"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Descrizione Prestazione:</label>
              <b-form-input v-model="filtro.descrizionePrestazione" type="search" id="descrizionePrestazione" placeholder="Descrizione Prestazione"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Codice Prestazione Agg:</label>
              <b-form-input v-model="filtro.codicePrestazioneAgg" type="search" id="codicePrestazioneAgg" placeholder="Codice Prestazione Agg"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Descrizione Prestazione Agg:</label>
              <b-form-input v-model="filtro.descrizionePrestazioneAgg" type="search" id="descrizionePrestazioneAgg" placeholder="Descrizione Prestazione Agg"></b-form-input>
            </b-col>
          </b-row>
          <b-row style="margin-left: 0.5rem; margin-right: 0.5rem; margin-top: 0.5rem">
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="sa-padding-right text-right">
              <b-button type="submit" variant="info">Cerca</b-button>
              <b-button type="reset" v-on:click="onReset" variant="danger">Reset</b-button>
            </b-col>
          </b-row>
        </b-form>
        <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col lg="3">
            <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
          </b-col>
          <b-col lg="9">
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
          </b-col>
        </b-row>
      </div>
      <div class="sa-list-component-body">
        <div class="b-table-sticky-header">
          <b-table sticky-header ref="tblRsaSchedaOspiteRefertiComponent" stacked="lg" striped hover selectable select-mode="single" :items="prestazioniNomenclatore" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table" @row-clicked="onClickRow"> </b-table>
        </div>
      </div>
      <!-- 
            <div class="sa-list-component-body">
                <div class="b-table-sticky-header">
                    <b-table sticky-header ref="tblRsaSchedaOspiteUsciteComponent" stacked="lg" striped hover selectable select-mode="single" :items="prestazioniNomenclatore" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table" @row-clicked="onClickRow"></b-table>
                </div>
            </div> -->
      <div class="sa-list-component-footer">
        <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col lg="3">
            <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rows }}</p>
          </b-col>
          <b-col lg="9">
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
          </b-col>
        </b-row>
      </div>
    </div>
  </b-modal>
</template>
<script>
import axios from "axios";
export default {
  components: {},
  data() {
    return {
      pathResourcePrestazioniNomenclatore: "/nomenclatore",
      rows: 0,
      perPage: 50,
      currentPage: 1,
      listKey: 0,
      filtro: {},
      prestazioniNomenclatore: [],
      prestazioneSelezionata: {},
      fields: [
        { label: "Codice Prestazione", key: "codicePrestazione" },
        { label: "Descrizione Prestazione", key: "descrizione" },
        { label: "Codice Prestazione Agg", key: "codicePrestazioneAgg" },
        { label: "Descrizione Prestazione Agg", key: "descrizioneAgg" },
        { label: "Importo", key: "importoPrestazione" },
      ],
    };
  },
  // watch: {
  //     currentPage() {
  //         let me = this;
  //         me.loadPrestazioniNomenclatore();
  //     },
  // },
  methods: {
    openModal() {
      let me = this;
      me.loadPrestazioniNomenclatore();
      me.$refs.mdlNomenclatoreList.show();
    },
    onOk() {
      let me = this;
      this.$emit("selectedPrestazione", me.prestazioneSelezionata);
    },
    loadPrestazioniNomenclatore() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourcePrestazioniNomenclatore;
      me.$emit("beforeLoadData");
      me.filtro.page = me.currentPage;
      me.filtro.forPage = me.perPage;
      me.filtro.codiceRegione = "150";
      axios
        .get(link, { params: me.filtro })
        .then((response) => {
          // me.prestazioniNomenclatore = [];
          me.rows = response.data.data.length;
          me.prestazioniNomenclatore = response.data.data;
          me.listKey++;
          this.$emit("afterLoadData");
        })
        .catch(() => {
          this.$emit("afterLoadData");
        });
    },
    onSubmit() {
      let me = this;
      me.loadPrestazioniNomenclatore();
    },
    onReset() {
      let me = this;
      me.filtro = { codiceRegione: "150" };
      me.loadPrestazioniNomenclatore();
    },
    onClickRow(item) {
      let me = this;
      me.prestazioneSelezionata = item;
    },
  },
};
</script>

<style></style>
