<template>
    <div class="sa-tab-scroll">
        <div class="sa-list-component-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" stacked="sm" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
                    <template #cell(actions)="{ item }">
                        <b-container class="bv-example-row">
                            <b-button v-if="checkConsensiFirmati(item)" size="sm" variant="outline-success" disabled>
                                <font-awesome-icon icon="check"></font-awesome-icon>
                            </b-button>
                            <b-button v-else size="sm" variant="outline-danger" disabled>
                                <font-awesome-icon icon="check"></font-awesome-icon>
                            </b-button>
                            <!-- <b-button v-if="!checkConsensiFirmati(item)" size="sm" variant="outline-secondary" class="mr-1 no-text" @click="onClickFirmaConsenso(item)">
                                <b-icon icon="vector-pen"></b-icon>
                            </b-button> -->
                            <print-component v-if="!checkConsensiFirmati(item)" :jsonData="item" :linkPrintData="linkPrintData(item)" typeButton="small" :firmaGrafometrica="true" :nomeFile="nomeFile" :descrizione="descrizione" :tipoDocumento="tipoDocumento" :codiceApplicativo="codiceApplicativo"></print-component>
                        </b-container>
                    </template>
                </b-table>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import UtilityMixin from "../../utility/UtilityMixin";
import PrintComponent from "../../utility/components/PrintComponent.vue";

export default {
    mixins: [UtilityMixin],
    components: { PrintComponent },
    data() {
        return {
            rows: 0,
            perPage: 50,
            currentPage: 1,
            idPaziente: null,
            codiceStruttura: null,
            sezione: null,
            items: [],
            filtro: {},
            documentoFirma: {},
            pathResource: "/accettazioneconsensi",
            pathResourcePrintConsensi: "/accettazioneconsensi/print",
            pathResourceStampa: "/print/createreport",
            signedPdfViewer: null,
            listKey: 0,
            nomeFile: "Consenso_",
            descrizione: "Consenso",
            tipoDocumento: "Consenso",
            codiceApplicativo: "NURITY-01",
            fields: [
                {
                    label: "Consenso",
                    key: "nomeConsenso",
                },
                {
                    label: "",
                    key: "actions",
                    class: "sa-table-column-action-double",
                },
            ],
        };
    },
    mounted() {},
    methods: {
        setDati(idPaziente, codiceStruttura, sezione) {
            let me = this;
            me.idPaziente = idPaziente;
            me.codiceStruttura = codiceStruttura;
            me.sezione = sezione;
            me.loadData();
        },
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.filtro.page = me.currentPage;
            me.filtro.forPage = me.perPage;
            me.filtro.idPaziente = me.idPaziente;
            me.filtro.codiceStruttura = me.codiceStruttura;
            me.filtro.sezione = me.sezione;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];
                    me.items = response.data.data.list;
                    me.rows = response.data.data.recordsNumber;
                    me.controlloConsensi();
                    me.listKey++;
                    this.$emit("afterLoadData");
                })
                .catch(() => {
                    this.$emit("afterLoadData");
                });
        },
        checkConsensiFirmati(item) {
            return item.statoAttuale !== "FIRMATO" ? false : true;
        },
        controlloConsensi() {
            let me = this;
            me.items.forEach((element) => {
                if (element.statoAttuale === null) {
                    element.idAccettazione = me.idPaziente;
                    me.salvaAccettazioneConsenso(element);
                }
            });
            // me.loadData();
        },
        linkPrintData(item) {
            let me = this;
            let returnValue = me.pathResourcePrintConsensi + "/" + item.id;
            return returnValue;
        },
        salvaAccettazioneConsenso(item) {
            let me = this;
            UtilityMixin.methods.saveResource(me.pathResource, item, this.saveSuccessResponse, this.saveErrorResponse);
        },
        saveSuccessResponse(response) {
            let me = this;
            me.$emit("afterSaveSuccess", response);
            me.$bvToast.toast("Salvataggio avvenuto con successo", {
                title: "Successo",
                variant: "success",
                solid: true,
            });
        },

        saveErrorResponse(response) {
            if (response.response.status === 400) {
                this.$bvModal.msgBoxOk(response.response.data.messaggio, {
                    title: "ATTENZIONE",
                    size: "sm",
                    okVariant: "outline-secondary",
                    headerClass: "sa-msg-header-danger",
                    footerClass: "p-2 border-top-0",
                    centered: true,
                });
            } else {
                this.$bvToast.toast("Errore nel salvataggio" + response.message, {
                    title: "Errore",
                    variant: "danger",
                    solid: true,
                });
            }
        },
        onClickFirmaConsenso(item) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourcePrintConsensi + "/" + item.nomeConsenso;
            axios
                .get(link)
                .then((response) => {
                    me.documentoFirma = item;
                    me.documentoFirma.base64 = response.data.data;
                    this.$emit("afterLoadData");
                })
                .catch(() => {
                    this.$emit("afterLoadData");
                });
        },
        onPrint() {
            console.log("Eccomi");
        },
        onModifica() {
            console.log("Modifica");
        },
    },
};
</script>

<style></style>
