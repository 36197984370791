<template>
    <div>
        <div>
            <b-table sticky-header ref="table" stacked="lg" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                <template v-slot:cell(actions)="row">
                    <b-container class="bv-example-row">
                        <b-button v-b-tooltip.hover size="sm" variant="outline-primary no-text" title="Modifica Driver" @click="onEditDriveDatabase(row.item, row.index, $event.target)" class="mr-1 no-text">
                            <b-icon icon="pencil"></b-icon>
                        </b-button>
                    </b-container>
                </template>
            </b-table>
        </div>
        <div>
            <data-analysis-driver-database-edit-modal-component ref="mdlDataAnalysisDriverDatabaseEditModalComponent" @saveDateSuccess="saveDateSuccessDataAnalysisDriverDatabaseEdit"></data-analysis-driver-database-edit-modal-component>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import UtilityMixin from "../../utility/UtilityMixin";
import DataAnalysisDriverDatabaseEditModalComponent from "./DataAnalysisDriverDatabaseEditModalComponent.vue";
export default {
    mixins: [UtilityMixin],
    components: { DataAnalysisDriverDatabaseEditModalComponent },
    data() {
        return {
            perPage: 1000,
            items: [],
            rows: 0,
            pathResourcesDatabaseDriver: "/dataanalysisdatabasedriver",
            fields: [
                {
                    label: "Nome",
                    key: "driverName",
                    sortable: true,
                },
                {
                    label: "JDBC Name",
                    key: "jdbcName",
                    sortable: true,
                },
                {
                    label: "Classe Java",
                    key: "className",
                    sortable: true,
                },
                {
                    label: "Query Fields Info",
                    key: "queryFieldsInfo",
                    sortable: true,
                },
                { class: "sa-table-column-action-double", key: "actions", label: "" },
            ],
        };
    },
    mounted() {
        let me = this;
        me.driveDatabaseLoadData();
    },
    methods: {
        driveDatabaseLoadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourcesDatabaseDriver;
            axios
                .get(link)
                .then((response) => {
                    me.items = [];
                    me.rows = response.data.data.recordsNumber;
                    me.items = response.data.data.list;
                    this.$emit("afterLoadData");
                })
                .catch(() => {
                    this.$emit("afterLoadData");
                });
        },
        onEditDriveDatabase(item) {
            let me = this;
            me.$refs.mdlDataAnalysisDriverDatabaseEditModalComponent.setJsonData(item);
            me.$refs.mdlDataAnalysisDriverDatabaseEditModalComponent.show();
        },
        saveDateSuccessDataAnalysisDriverDatabaseEdit() {
            let me = this;
            me.driveDatabaseLoadData();
        },
    },
};
</script>

<style></style>
