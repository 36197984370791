<template>
    <sa-page-layout slot="table-body" :btnConfigurazioniVisible="btnConfigurazioniVisible" :btnRefreshVisible="true" :pathResource="pathResource" @refresh="onRefresh" :showModalLoading="showModalLoading">
        <template slot="toolbar">
            <b-button class="float-sm-right" variant="success btn-toolbar sa-margin-right" size="sm" @click="onClickNuovaPrestazioneAmbulatoriale">
                <b-icon icon="plus"></b-icon>
                Nuovo
            </b-button>
        </template>
        <template slot="table-filter">
            <b-form @submit.prevent="onSubmit">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Codice Prestazione</label>
                        <b-form-input v-model="filtroPrestazioniAmbulatoriali.codicePrestazione" type="search" id="codicePrestazione" placeholder="Inserire Codice Prestazione"></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Codice Offering</label>
                        <b-form-input v-model="filtroPrestazioniAmbulatoriali.codiceOffering" type="search" id="codiceOffering" placeholder="Inserire Codice Offering"> </b-form-input>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <label class="sa-label-data">Descrizione</label>
                        <b-form-input v-model="filtroPrestazioniAmbulatoriali.descrizione" type="search" id="descrizione" placeholder="Inserire Descrizione"> </b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="sa-padding-right pt-3 pb-3 text-right">
                        <b-button type="submit" variant="info"> {{ this.$i18n.t("patients.lblFind") }}</b-button>
                        <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("patients.lblResetFilter") }}</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </template>

        <template slot="table-body">
            <rsa-configurazione-prestazioni-ambulatoriali-list-component ref="cmpRsaConfigurazionePrestazioniAmbulatorialiList"> </rsa-configurazione-prestazioni-ambulatoriali-list-component>
        </template>
    </sa-page-layout>
</template>
<script>
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import RsaConfigurazionePrestazioniAmbulatorialiListComponent from "../components/RsaConfigurazionePrestazioniAmbulatorialiListComponent.vue";

export default {
    components: { SaPageLayout, RsaConfigurazionePrestazioniAmbulatorialiListComponent },

    data() {
        return {
            filtroPrestazioniAmbulatoriali: {
                codicePrestazione: null,
                codiceOffering: null,
                descrizione: null,
            },
            btnConfigurazioniVisible: true,
            pathResource: "",
            showModalLoading: false,
        };
    },

    methods: {
        loadData() {
            let me = this;
            me.$refs.cmpRsaConfigurazionePrestazioniAmbulatorialiList.loadData();
        },
        onSubmit() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtroPrestazioniAmbulatoriali"] = JSON.stringify(me.filtroPrestazioniAmbulatoriali);
            me.loadData();
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        onReset() {
            let me = this;
            me.filtroPrestazioniAmbulatoriali = { codicePrestazione: null, codiceOffering: null, descrizione: null };
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtroPrestazioniAmbulatoriali"] = JSON.stringify(me.filtroPrestazioniAmbulatoriali);
            me.loadData();
        },

        onClickNuovaPrestazioneAmbulatoriale() {
            let me = this;
            me.$refs.cmpRsaConfigurazionePrestazioniAmbulatorialiList.onClickNuovaPrestazioneAmbulatoriale();
        },
    },
};
</script>
