<template>
    <div class="sa-tab-scroll">
        <b-card class="sa-card sa-card-border" header="Valutazioni" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">In che anno siamo?</label>
                    <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.punteggioAnno" class="mb-2 mr-sm-2 mb-sm-0" :value="null" value-field="value" text-field="text" :options="punteggioUnoOptions"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">In che stagione siamo?</label>
                    <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.punteggioStagione" class="mb-2 mr-sm-2 mb-sm-0" :value="null" value-field="value" text-field="text" :options="punteggioUnoOptions"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">In che mese siamo?</label>
                    <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.punteggioMese" class="mb-2 mr-sm-2 mb-sm-0" :value="null" value-field="value" text-field="text" :options="punteggioUnoOptions"></b-form-select>
                </b-col>

                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Mi dica la data di oggi</label>
                    <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.punteggioDataOggi" class="mb-2 mr-sm-2 mb-sm-0" :value="null" value-field="value" text-field="text" :options="punteggioUnoOptions"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Che giorno della settimana è oggi?</label>
                    <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.punteggioGiornoSettimana" class="mb-2 mr-sm-2 mb-sm-0" :value="null" value-field="value" text-field="text" :options="punteggioUnoOptions"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Mi dica in che nazione siamo?</label>
                    <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.punteggioNazione" class="mb-2 mr-sm-2 mb-sm-0" :value="null" value-field="value" text-field="text" :options="punteggioUnoOptions"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">In quale regione italiana siamo?</label>
                    <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.punteggioRegione" class="mb-2 mr-sm-2 mb-sm-0" :value="null" value-field="value" text-field="text" :options="punteggioUnoOptions"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">In quale città ci troviamo?</label>
                    <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.punteggioCitta" class="mb-2 mr-sm-2 mb-sm-0" :value="null" value-field="value" text-field="text" :options="punteggioUnoOptions"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Mi dica il nome del luogo dove ci troviamo?</label>
                    <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.punteggioLuogo" class="mb-2 mr-sm-2 mb-sm-0" :value="null" value-field="value" text-field="text" :options="punteggioUnoOptions"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">A che piano siamo?</label>
                    <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.punteggioPiano" class="mb-2 mr-sm-2 mb-sm-0" :value="null" value-field="value" text-field="text" :options="punteggioUnoOptions"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Far ripetere: "pane, casa, gatto". La prima ripetizione dà adito al punteggio. (Ripetere finchè il soggetto esegue correttemente, max 6 volte)</label>
                    <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.punteggioRipetizioneSoggetti" class="mb-2 mr-sm-2 mb-sm-0" :value="null" value-field="value" text-field="text" :options="punteggioTreOptions"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Far contare a ritroso da 100 togliendo 7 per cinque volte. Se non completa questa prova, allora far sillabare all'indietro la parola "mondo"</label>
                    <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.punteggioContaRitroso" class="mb-2 mr-sm-2 mb-sm-0" :value="null" value-field="value" text-field="text" :options="punteggioCinqueOptions"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Chiedere la ripetizione dei tre soggetti precedenti</label>
                    <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.punteggioRipetizioneSoggettiPrecedenti" class="mb-2 mr-sm-2 mb-sm-0" :value="null" value-field="value" text-field="text" :options="punteggioTreOptions"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Mostrare un orologio ed una matita chiedendo di dirmi il nome</label>
                    <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.punteggioRiconoscimentoNomiOrologioMatita" class="mb-2 mr-sm-2 mb-sm-0" :value="null" value-field="value" text-field="text" :options="punteggioDueOptions"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Ripeta questa frase: "tigre contro tigre"</label>
                    <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.punteggioRipetizioneFrase" class="mb-2 mr-sm-2 mb-sm-0" :value="null" value-field="value" text-field="text" :options="punteggioUnoOptions"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Prenda questo foglio con la mano destra, lo pieghi e lo metta sul tavolo</label>
                    <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.punteggioPiegamentoFoglio" class="mb-2 mr-sm-2 mb-sm-0" :value="null" value-field="value" text-field="text" :options="punteggioTreOptions"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Legga ed esegua quanto scritto su questo foglio (chiuda gli occhi)</label>
                    <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.punteggioLetturaEseguimento" class="mb-2 mr-sm-2 mb-sm-0" :value="null" value-field="value" text-field="text" :options="punteggioUnoOptions"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Scriva una frase (deve contenere soggetto e verbo)</label>
                    <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.punteggioScritturaFrase" class="mb-2 mr-sm-2 mb-sm-0" :value="null" value-field="value" text-field="text" :options="punteggioUnoOptions"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Copi questo disegno (pentagoni intrecciati)</label>
                    <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.punteggioCopiaDisegno" class="mb-2 mr-sm-2 mb-sm-0" :value="null" value-field="value" text-field="text" :options="punteggioUnoOptions"></b-form-select>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card sa-card-border" header="Totale" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <span class="sa-label-data">{{ totale }}</span>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
<script>
import axios from "axios";
export default {
    components: {},
    props: {
        // utente: {
        //     type: Object,
        //     default: function () {
        //         return null;
        //     },
        // },
    },
    watch: {
        jsonData: {
            handler() {
                let me = this;
                me.jsonData.idAccettazione = me.idAccettazione;
                me.calcolaPunteggio();
                me.$emit("update", me.jsonData);
            },
            deep: true,
        },
    },
    data() {
        return {
            idAccettazione: null,
            keyPropostaAccesso: 0,
            linkback: "",
            linkPrintData: "",
            childKey: 0,
            viewPai: true,
            pathResource: "/rsascalaminimentalshort",
            totale: 0,
            compilata: false,
            isReadOnly: false,
            jsonData: {
                id: null,
                idAccettazione: "",
                punteggioAnno: "",
                punteggioCitta: "",
                punteggioContaRitroso: "",
                punteggioCopiaDisegno: "",
                punteggioDataOggi: "",
                punteggioGiornoSettimana: "",
                punteggioLetturaEseguimento: "",
                punteggioLuogo: "",
                punteggioMese: "",
                punteggioNazione: "",
                punteggioPiano: "",
                punteggioPiegamentoFoglio: "",
                punteggioRegione: "",
                punteggioRiconoscimentoNomiOrologioMatita: "",
                punteggioRipetizioneFrase: "",
                punteggioRipetizioneSoggetti: "",
                punteggioRipetizioneSoggettiPrecedenti: "",
                punteggioScritturaFrase: "",
                punteggioStagione: "",
            },
            fieldsListe: [
                {
                    label: "Descrizione",
                    key: "fattore",
                    sortable: true,
                },
                {
                    label: "",
                    key: "check",
                    sortable: true,
                    thStyle: "width: 10rem",
                    tdClass: "text-center sa-column-widh",
                },
                // {
                //   label: "",
                //   key: "action",
                //   thStyle: "width: 10rem",
                // },
            ],
            punteggioUnoOptions: [
                { text: "-Seleziona Valore-", value: "" },
                { text: "0", value: 0 },
                { text: "1", value: 1 },
            ],
            punteggioDueOptions: [
                { text: "-Seleziona Valore-", value: "" },
                { text: "0", value: 0 },
                { text: "1", value: 1 },
                { text: "2", value: 2 },
            ],
            punteggioTreOptions: [
                { text: "-Seleziona Valore-", value: "" },
                { text: "0", value: 0 },
                { text: "1", value: 1 },
                { text: "2", value: 2 },
                { text: "3", value: 3 },
            ],
            punteggioCinqueOptions: [
                { text: "-Seleziona Valore-", value: "" },
                { text: "0", value: 0 },
                { text: "1", value: 1 },
                { text: "2", value: 2 },
                { text: "3", value: 3 },
                { text: "4", value: 4 },
                { text: "5", value: 5 },
            ],
        };
    },
    mounted() {
        let me = this;
        if (this.$route.params.id) {
            me.idAccettazione = this.$route.params.id;
        } else {
            me.idAccettazione = this.$route.query.idPaziente;
        }
        me.jsonData.idAccettazione = me.idAccettazione;
        me.checkSchedaCompilata();
    },
    methods: {
        checkSchedaCompilata() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/check/" + me.idAccettazione;
            axios
                .get(link)
                .then((response) => {
                    me.listKey++;
                    me.compilata = response.data.esito;

                    me.loadData();
                })
                .catch(() => {
                    me.compilata = false;
                });
        },
        loadData() {
            let me = this;

            if (me.compilata) {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/richiesta/" + me.idAccettazione;
                axios
                    .get(link)
                    .then((response) => {
                        me.jsonData = response.data.data;

                        me.calcolaPunteggio();
                    })
                    .catch(() => {});
            }
        },
        unisciArray() {
            let me = this;
            this.$emit("update", me.jsonData);
        },
        // init(value) {
        //     let me = this;
        //     me.isReadOnly = value.compilata;
        // },
        calcolaPunteggio() {
            let me = this;
            me.totale = 0;
            me.totale =
                Number(me.jsonData.punteggioAnno) +
                Number(me.jsonData.punteggioCitta) +
                Number(me.jsonData.punteggioContaRitroso) +
                Number(me.jsonData.punteggioCopiaDisegno) +
                Number(me.jsonData.punteggioDataOggi) +
                Number(me.jsonData.punteggioGiornoSettimana) +
                Number(me.jsonData.punteggioLetturaEseguimento) +
                Number(me.jsonData.punteggioLuogo) +
                Number(me.jsonData.punteggioMese) +
                Number(me.jsonData.punteggioNazione) +
                Number(me.jsonData.punteggioPiano) +
                Number(me.jsonData.punteggioPiegamentoFoglio) +
                Number(me.jsonData.punteggioRegione) +
                Number(me.jsonData.punteggioRiconoscimentoNomiOrologioMatita) +
                Number(me.jsonData.punteggioRipetizioneFrase) +
                Number(me.jsonData.punteggioRipetizioneSoggetti) +
                Number(me.jsonData.punteggioRipetizioneSoggettiPrecedenti) +
                Number(me.jsonData.punteggioScritturaFrase) +
                Number(me.jsonData.punteggioStagione);
        },
        setListe() {},
    },
};
</script>

<style></style>
