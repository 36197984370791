<template>
    <sa-page-layout :btnAnnullaVisible="false" :linkback="linkback" class="sa-no-space">
        <template slot="toolbar">
            <b-button variant="success btn-toolbar float-sm-right sa-margin-right" size="sm" @click="onSave" disabled><b-icon icon="check2"></b-icon>Salva</b-button>
        </template>
        <template slot="toolbar-title">
            <!-- <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica> -->
        </template>
        <template slot="table-body">
            <div class="sa-tab-scroll">
                <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">KIT:</label>
                            <b-form-select v-model="kit.kitId" :options="kitOptions" size="l" :value="null" value-field="kitId" text-field="kitName"></b-form-select>
                        </b-col>
                    </b-row>
                </b-card>
            </div>
        </template>
    </sa-page-layout>
</template>

<script>
import axios from "axios";
// import PazienteAnagrafica from "../../components/PazienteAnagrafica.vue";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
// import PazienteDashboardComponent from "../../dashboard/components/PazienteDashboardComponent.vue";
export default {
    data() {
        return {
            linkback: "/paziente/hassistokits",
            pathResource: "/hassistokits/assignkit",
            pathKitlist: "/hassistokits",

            kit: { kitId: null, patientId: this.idPaziente },
            kitOptions: [{ kitId: null, kitName: "--Seleziona KIT Libero--" }],
        };
    },
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    components: { SaPageLayout }, //PazienteDashboardComponent   PazienteAnagrafica,
    computed: {
        idPaziente() {
            let me = this;
            return me.$route.query.idPaziente;
        },
        linkPatient: {
            get: function () {
                if (this.utente.gruppo) {
                    if (this.utente.gruppo.menuGruppo) {
                        if (this.utente.gruppo.menuGruppo.length == 1) {
                            if (this.utente.gruppo.menuGruppo[0].primaryKey === "homestudiomedico") {
                                return "/paziente/view/" + this.idPaziente;
                            } else if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                                return "view/" + this.idPaziente;
                            } else {
                                return "homepaziente";
                            }
                        } else {
                            return "";
                        }
                    } else {
                        return "";
                    }
                } else {
                    return "";
                }
            },
        },
    },
    mounted() {
        let me = this;
        me.loadKits();
    },
    methods: {
        onBack() {
            let me = this;
            me.$router.replace(me.linkback).catch((err) => {
                err;
            });
        },
        loadKits() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathKitlist;
            axios
                .get(link)
                .then((Response) => {
                    Response.data.data.forEach((element) => {
                        if (element.patientId === null) {
                            me.kitOptions.push(element.kitId);
                        }
                    });
                })
                .catch(() => {});
        },
        onSave() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            axios
                .put(link, { kitId: this.kit.kitId, patientId: this.idPaziente })
                .then((Response) => {
                    console.log(Response);
                    me.$router.replace(me.linkback).catch((e) => {
                        e;
                    });
                })
                .catch((e) => {
                    console.log(e);
                });
        },
    },
};
</script>
