<template>
    <div class="sa-agenda-container">
        <div class="sa-agenda-view">
            <div class="sa-panel-grid">
                <div class="sa-agenda-day-row" v-for="giorno in disponibilitaAgenda" :key="giorno.giorno">
                    <div class="sa-agenda-day sa-agenda-mobile-grid-cell">
                        <div class="sa-agenda-day-date">
                            <div class="sa-agenda-day-date-number" v-if="giorno.giorno">{{ getDayByDate(giorno.giorno) }}</div>
                            <div class="sa-agenda-day-date-text" v-if="giorno.giorno">
                                <div>{{ getWeekDayByDate(giorno.giorno) }}</div>
                                <div>{{ getMonthByDate(giorno.giorno) }} {{ getYearByDate(giorno.giorno) }}</div>
                            </div>
                        </div>
                        <div class="sa-agenda-day-events">
                            <table class="sa-agenda-table-events">
                                <tr v-for="(disponibilita, i) in giorno.disponibilita" :key="getIndexDisponibilita(disponibilita, i)" :class="getAlternateClass(i)">
                                    <td class="sa-agenda-td-intervallo">{{ disponibilita.dalleOre }} - {{ disponibilita.alleOre }}</td>
                                    <td class="sa-agenda-td-descrizione">
                                        <div class="sa-agenda-td-div-multiple-slot">
                                            <!-- <div v-for="(appuntamento, index) in disponibilita.appuntamento" :key="getIndexDisponibilita(disponibilita, i) + index">
                                                <agenda-slot-component :idAgenda="idAgenda" :costo="costo" :codicePrestazione="codicePrestazione" :descrizionePrestazione="descrizionePrestazione" :index="getIndexDisponibilita(disponibilita, i)" :slotDisponibilita="disponibilita" @update="updateData" :slotNumber="index"></agenda-slot-component>
                                            </div> -->
                                            <div>
                                                <agenda-slot-component :idAgenda="idAgenda" :costo="costo" :codicePrestazione="codicePrestazione" :descrizionePrestazione="descrizionePrestazione" :index="getIndexDisponibilita(disponibilita, i)" :slotDisponibilita="disponibilita" @updateSlotEvent="onUpdateSlotEvent"></agenda-slot-component>
                                            </div>
                                            <!-- <agenda-slot :idAgenda="idAgenda" :costo="costo" :codicePrestazione="codicePrestazione" :descrizionePrestazione="descrizionePrestazione" :index="getIndexDisponibilita(disponibilita, i)" :slotDisponibilita="disponibilita" @update="updateData"></agenda-slot> -->
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import Vue from "vue";
import moment from "moment";
import axios from "axios";
import AgendaSlotComponent from "./AgendaSlotComponent.vue";
export default {
    components: { AgendaSlotComponent },
    data() {
        return {
            items: [],
            disponibilitaAgenda: [],
            pathResourceDisponibilitaAgenda: "/agendadisponibilita",
            periodo: "A",
            jsonAgenda: null,
            jsonPrestazione: null,
            riferimento: null,
        };
    },
    computed: {
        idAgenda() {
            return this.jsonAgenda.id;
        },
        costo() {
            return this.jsonPrestazione.costo;
        },
        codicePrestazione() {
            return this.jsonPrestazione.codicePrestazione;
        },
        descrizionePrestazione() {
            return this.jsonPrestazione.descrizionePrestazione;
        },
    },
    mounted() {},
    methods: {
        getAlternateClass(index) {
            let returnValue = "";
            if (index % 2 > 0) {
                returnValue = "sa-agenda-agenda-alternate-hour";
            }
            return returnValue;
        },
        loadDisponibilita(jsonAgenda, riferimento, jsonPrestazione) {
            let me = this;
            me.jsonAgenda = jsonAgenda;
            me.riferimento = riferimento;
            me.jsonPrestazione = jsonPrestazione;
            if (jsonPrestazione.codicePrestazione) {
                let link = process.env.VUE_APP_PATH_API + me.pathResourceDisponibilitaAgenda + "/";
                axios
                    .get(link, {
                        params: {
                            idAgenda: jsonAgenda.id,
                            periodo: me.periodo,
                            riferimento: riferimento,
                            codicePrestazione: jsonPrestazione.codicePrestazione,
                        },
                    })
                    .then((response) => {
                        me.disponibilitaAgenda = response.data.data == null ? [] : response.data.data;
                        let value = 0;
                        me.disponibilitaAgenda.forEach((elemento) => {
                            value += elemento.disponibilita.length;
                        });
                        me.numeroDisponibilita = value;
                    })
                    .catch((error) => {
                        me.$bvToast.toast(error.response.data.messaggio, {
                            title: "Error",
                            variant: "danger",
                            solid: true,
                        });
                    });
            } else {
                return;
            }
        },
        setDisponibilita(disponibilita) {
            this.disponibilita = disponibilita;
        },
        onUpdateSlotEvent() {
            let me = this;
            me.loadDisponibilita(me.jsonAgenda, me.riferimento, me.jsonPrestazione);
        },
        getIndexDisponibilita(disponibilita, index) {
            return "agenda_" + disponibilita.dataRiferimento + "_" + index;
        },
        getIndex(index) {
            return "agenda_" + index;
        },
        getDayByDate(value) {
            let date = moment(value, "YYYY-MM-DD");
            return date.format("DD");
        },
        getWeekDayByDate(value) {
            let date = moment(value, "YYYY-MM-DD");
            return date.format("dddd");
        },
        getMonthByDate(value) {
            let date = moment(value, "YYYY-MM-DD");
            return date.format("MMM");
        },
        getYearByDate(value) {
            let date = moment(value, "YYYY-MM-DD");
            return date.format("YYYY");
        },
        descrizione(value) {
            let descrizione;
            if (value.appuntamento) {
                descrizione = value.titolo + " (" + value.codicePrestazione + "-" + value.descrizionePrestazione + ")";
            } else {
                descrizione = "Disponibile";
            }
            return descrizione;
        },
    },
};
</script>

<style></style>
