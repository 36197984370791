<template>
    <div style="height: 100%; overflow: auto">
        <b-tabs id="rsaAccettazioneViewTabComponent" ref="rsaAccettazioneViewTabComponent" v-model="activeTabIndex" nav-wrapper-class="sa-tab-items" content-class="mt-3" class="sa-tab" style="height: 100%; padding-bottom: 25px" fill @input="onInputTabs">
            <b-tab title="Anagrafica">
                <rsa-accettazione-anagrafica-view-component ref="cmpRsaAccettazioneAnagraficaViewComponent"></rsa-accettazione-anagrafica-view-component>
            </b-tab>
            <b-tab title="Familiari">
                <div class="sa-tab-scroll">
                    <b-table sticky-header ref="table" stacked="lg" striped hover :items="listaPersoneRiferimento" :fields="fieldsPersoneRiferimento" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
                        <template v-slot:cell(nominativo)="{ item }">
                            <span class="sa-table-span-label-primary">{{ item.nome }} {{ item.cognome }}</span>
                            <span class="sa-table-span-label-secondary">{{ item.identificativo }}</span>
                        </template>
                        <template v-slot:cell(cellulare)="{ item }">
                            <span v-if="item.cellulare">{{ item.cellulare }}</span> <span v-else>{{ item.telefonoFisso }}</span>
                        </template>
                    </b-table>
                </div>
            </b-tab>

            <b-tab title="Spese extra" id="tbSpeseExtra">
                <rsa-spesa-extra-list-component ref="cmpRsaSpeseExtraListComponent" :key="id" :id-accettazione="id" :is-view-mode="true"> </rsa-spesa-extra-list-component>
            </b-tab>

            <b-tab id="tbRsaEsenzioni" title="Esenzioni" v-if="tbRsaEsenzioni">
                <div class="sa-tab-scroll">
                    <b-table sticky-header ref="table" stacked="lg" striped hover :items="jsonData.esenzioni" :fields="fieldsEsenzioni" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="jsonData">
                            {{ jsonData.index + 1 }}
                        </template>
                        <template #cell(etichetta)="item">
                            <span class="sa-data">{{ item.item.etichetta }}</span>
                        </template>
                        <template #cell(dataInserimento)="item">
                            <span class="sa-data">{{ item.item.dataInserimento }}</span>
                        </template>
                        <template #cell(scadenza)="item">
                            <span class="sa-data">{{ item.item.scadenza }}</span>
                        </template>
                    </b-table>
                </div>
            </b-tab>
            <b-tab id="tbRsaValutazioneIngresso" title="Valutazione All'Ingresso" v-if="tbRsaValutazioneIngresso">
                <rsa-accettazione-schede-valutazione-component ref="RsaAccettazioneSchedeValutazioneComponent"></rsa-accettazione-schede-valutazione-component>
            </b-tab>
            <b-tab id="tbRsaDatiFatturazione" title="Dati di Fatturazione" v-if="tbRsaDatiFatturazione">
                <rsa-accettazione-dati-fatturazione-view-component ref="RsaAccettazioneDatiFatturazioneViewComponent"></rsa-accettazione-dati-fatturazione-view-component>
            </b-tab>
            <b-tab id="tbConsensi" title="Consensi">
                <rsa-consensi-list-component ref="cmpRsaConsensiList"></rsa-consensi-list-component>
            </b-tab>
        </b-tabs>
    </div>
</template>
<script>
import axios from "axios";
import "vue2-datepicker/index.css";
import UtilityMixin from "../../../utility/UtilityMixin";
import RsaAccettazioneDatiFatturazioneViewComponent from "./RsaAccettazioneDatiFatturazioneViewComponent.vue";
import RsaAccettazioneSchedeValutazioneComponent from "./RsaAccettazioneSchedeValutazioneComponent.vue";
import RsaAccettazioneAnagraficaViewComponent from "./RsaAccettazioneAnagraficaViewComponent.vue";
import RsaSpesaExtraListComponent from "./RsaSpesaExtraListComponent.vue";
import RsaConsensiListComponent from "../../components/RsaConsensiListComponent.vue";
export default {
    mixins: [UtilityMixin],
    components: { RsaAccettazioneDatiFatturazioneViewComponent, RsaAccettazioneSchedeValutazioneComponent, RsaAccettazioneAnagraficaViewComponent, RsaSpesaExtraListComponent, RsaConsensiListComponent },
    data() {
        return {
            pathResource: "/rsaaccettazioni",
            pathResourcePresidio: "/strutturepresidi",
            pathResourceComuniByCodiceIstat: "/comuni/istat",
            pathResourcePersoneRifermineto: "/rsapersoneriferimento",
            showModalLoading: false,
            listKey: 0,
            rows: 0,
            perPage: 50,
            currentPage: 1,
            id: null,
            presidio: "",
            comuneNascita: "",
            comuneResidenza: "",
            comuneDomicilio: "",
            tbRsaDatiFatturazione: false,
            tbRsaEsenzioni: false,
            tbRsaValutazioneIngresso: false,
            activeTabIndex: 0,
            jsonData: {
                codiceStruttura: null, //non a video
                idPresidio: null, //non a video
                numero: null, //non a video
                note: null, //non a video
                regime: null, //non a video
                idPrenotazione: null, //non a video
                accettazioneStruttura: {}, //non a video
                accettazioneAnagrafica: {
                    idAccettazione: null, //non a video
                    tipo: null, //non a video
                    identificativo: null,
                    tipoIdentificativo: null, //non a video
                    cognome: null,
                    nome: null,
                    dataNascita: null,
                    provinciaNascita: null,
                    comuneNascitaCodiceIstat: null,
                    sesso: null,
                    regioneResidenza: null,
                    provinciaResidenza: null,
                    comuneResidenzaCodiceIstat: null,
                    indirizzoResidenza: null,
                    capResidenza: null,
                    regioneDomicilio: null,
                    provinciaDomicilio: null,
                    comuneDomicilioCodiceIstat: null,
                    indirizzoDomicilio: null,
                    capDomicilio: null,
                    note: null,
                    medicoCurante: null,
                    codiceFiscaleMedicoCurante: null, //non a video
                    telefono: null,
                    email: null,
                    emailMedicoCurante: null,
                },
                accettazioneStati: [],
                accettazionePrestazioni: [],
                accettazioniAsl: {},
            },
            listaPersoneRiferimento: [],
            filtroPersoneRiferimento: {},
            fieldsPersoneRiferimento: [
                { label: "", key: "index" },
                {
                    label: "Nominativo",
                    key: "nominativo",
                },
                {
                    label: "Recapito Telefonico",
                    key: "cellulare",
                },
                {
                    label: "Email",
                    key: "email",
                },
                {
                    label: "Grado di relazione",
                    key: "gradoRelazione",
                },
                /* {
          label: "",
          key: "actions",
        }, */
            ],
            fieldsEsenzioni: [
                { label: "", key: "index" },
                {
                    label: "Etichetta",
                    key: "etichetta",
                },
                {
                    label: "Data inserimento",
                    key: "dataInserimento",
                },
                {
                    label: "Scadenza",
                    key: "scadenza",
                },
            ],
            fieldsPatologia: [
                { label: "", key: "index" },
                { label: "Patolgia", key: "patologia" },
                { label: "Descrizione Patologia", key: "descrizionePatologia" },
                { label: "CodiceIcdIx", key: "codiceIcdIx" },
            ],
        };
    },
    mounted() {
        /* let me = this; */
        // me.$refs.RsaAccettazioneSchedeValutazioneComponent.loadData();
        /* me.$refs.RsaSpesaExtraListComponent.loadData(me.id); */
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        onInputTabs(index) {
            let me = this;
            let activeTab = this.$refs.rsaAccettazioneViewTabComponent.getTabs()[index];
            me.activeTab(activeTab.id);
        },
        activeTab(idActiveTab) {
            console.log(idActiveTab);
            let me = this;
            switch (idActiveTab) {
                case "tbRsaValutazioneIngresso":
                    console.log("ciao");
                    me.$refs.RsaAccettazioneSchedeValutazioneComponent.loadData();
                    break;
                case "tbConsensi":
                    me.$refs.cmpRsaConsensiList.setDati(me.id, me.jsonData.codiceStruttura, "NURITY");
                    break;
                /* case "tbSpeseExtra":
                    console.log("ciao");
                    me.$refs.RsaSpesaExtraListComponent.loadData(); */
            }
        },
        setId(id) {
            this.id = id;
            this.$refs.cmpRsaAccettazioneAnagraficaViewComponent.setId(id);

            this.loadPersoneRiferimento();
            //this.$refs.cmpRsaSpeseExtraListComponent.setId(id);
            // this.loadSpeseExtra();
        },
        loadPersoneRiferimento() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourcePersoneRifermineto;
            let params = { idAccettazione: me.id };
            axios.get(link, { params: params }).then((response) => {
                me.listaPersoneRiferimento = response.data.data.list;
            });
        },
        //     loadSpeseExtra() {
        //         this.$refs.cmpRsaSpeseExtra.loadData();
        //     },
    },
};
</script>
