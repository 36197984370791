<template>
    <sa-page-layout :btnBackVisible="true" :linkback="linkback" :btnRefreshVisible="true" @refresh="onRefresh" :showModalLoading="showModalLoading">
        <template slot="table-filter">
            <div style="width: 100%">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                        <label class="sa-label-data">ID:</label>
                        <span>{{ jsonData.id }}</span>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                        <label class="sa-label-data">Username:</label>
                        <span>{{ jsonData.username }}</span>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                        <label class="sa-label-data">Profilo:</label>
                        <span>{{ jsonData.profilo }}</span>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                        <label class="sa-label-data">Stato:</label>
                        <span v-if="jsonData.attivo" style="color: #009900; font-weight: bold">ATTIVO</span>
                        <span v-else style="color: #990000; font-weight: bold">DISATTIVATO</span>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-label-data">E-Mail:</label>
                        <span>{{ jsonData.email }}</span>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-label-data">Cognome:</label>
                        <span>{{ jsonData.lastname }}</span>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-label-data">Nome:</label>
                        <span>{{ jsonData.firstname }}</span>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                        <label class="sa-label-data">Creato da:</label>
                        <span>{{ jsonData.createUser }}</span>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                        <label class="sa-label-data">Creato il:</label>
                        <span>{{ formatDateTime(jsonData.createDate) }}</span>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                        <label class="sa-label-data">Aggiornato da:</label>
                        <span>{{ jsonData.updateUser }}</span>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                        <label class="sa-label-data">Aggiornato il:</label>
                        <span>{{ formatDateTime(jsonData.updateDate) }}</span>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                        <label class="sa-label-data">Scadenza Utenza:</label>
                        <span>{{ formatDateTime(jsonData.scadenzaUtenza) }}</span>
                    </b-col>
                </b-row>
            </div>
            <div>
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col cols="8" xs="8" sm="8" md="8" lg="4" xl="4">
                        <label class="sa-label-data">Dal</label>
                        <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="filtro.dataDal" type="datetime"></date-picker>
                    </b-col>
                    <b-col cols="8" xs="8" sm="8" md="8" lg="4" xl="4">
                        <label class="sa-label-data">Al</label>
                        <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="filtro.dataAl" type="datetime"></date-picker>
                    </b-col>
                </b-row>
            </div>
        </template>
        <template slot="table-body">
            <div class="sa-timeline">
                <ul class="sa-timeline-ul">
                    <li :style="getColor(log)" v-for="log in logs" :key="log.id">
                        <div class="date">{{ formatDateTime(log.dataOra) }}</div>
                        <div class="title">Sezione: {{ log.sezione }}</div>
                        <div class="subtitle">Operazione: {{ log.operation }} - IP: {{ log.ip }}</div>
                        <div class="descr">
                            {{ log.descrizioneEvento }}
                            <span style="display: block; padding-top: 0.5rem; font-style: italic; font-weight: bold">UserAgent: {{ log.userAgent }}</span>
                        </div>
                    </li>
                </ul>
            </div>
        </template>
    </sa-page-layout>
</template>

<script>
import axios from "axios";
import SaPageLayout from "../../../../template/layout/components/SaPageLayout.vue";
import UtilityMixin from "../../UtilityMixin";
import DatePicker from "vue2-datepicker";
export default {
    mixins: [UtilityMixin],
    components: { SaPageLayout, DatePicker },
    data() {
        return {
            showModalLoading: false,
            username: null,
            linkback: "/logs",
            pathResource: "/logs",
            pathResourceUserData: "/users/infouser",
            filtro: { username: null, dataDal: null, dataAl: null },
            jsonData: {
                username: null,
            },
            logs: [],
        };
    },
    created() {
        this.username = this.$route.params.username;
    },
    mounted() {
        let dataDal = this.$route.query.dataDal;
        let dataIniziale = new Date(Number(dataDal));
        dataIniziale.setHours(0);
        dataIniziale.setMinutes(0);
        this.filtro.dataDal = dataIniziale.getTime();
        this.filtro.username = this.username;
        this.filtro.orderDirection = "ASC";
        this.loadUserData();
    },
    computed: {},
    methods: {
        getColor(log) {
            let returnValue = "--accent-color: #41516c";

            switch (log.stato) {
                case "INFO":
                    if (log.operation === "LOGIN") {
                        returnValue = "--accent-color: #1b5f8c;";
                    } else if (log.operation === "LOGOUT") {
                        returnValue = "--accent-color: #4cadad;";
                    } else {
                        returnValue = "--accent-color: #009900";
                    }
                    break;
                case "ERROR":
                    returnValue = "--accent-color: #990000";
                    break;
            }
            return returnValue;
        },
        loadUserData() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceUserData + "/" + me.username;
            axios
                .get(link)
                .then((response) => {
                    me.jsonData = response.data.data;
                    me.showModalLoading = false;
                    me.$emit("afterLoadData");
                    me.loadUserLogs();
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        loadUserLogs() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.logs = response.data.data;
                    me.showModalLoading = false;
                    me.$emit("afterLoadData");
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        onRefresh() {
            let me = this;
            me.loadUserLogs();
        },
    },
};
</script>

<style></style>
