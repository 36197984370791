<template>
    <sa-page-layout :btnSaveVisible="btnSaveVisible" :btnAnnullaVisible="true" :linkback="linkback" :pathResource="pathResource" :data="jsonData" :showModalLoading="showModalLoading" class="sa-no-space" @aftersave="onAfterSave">
        <template slot="table-body">
            <div class="sa-tab-scroll">
                <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label_data">
                                Ambulatorio
                                <span style="color: red">*</span>
                            </label>
                            <b-form-select v-model="idAmbulatorio" :options="ambulatoriOptions" :value="null" value-field="id" text-field="nome" @change="onSelectAmbulatorio" :disabled="disableSelectAmbulatorio"></b-form-select>
                        </b-col>
                        <!-- <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label_data">Prestazione<span style="color: red">*</span></label>
                            <b-form-select v-model="prestazione" :options="prestazioniOptions" :value="null" value-field="value" text-field="text" @change="onSelectPrestazione" :disabled="idAmbulatorio === null"></b-form-select>
                        </b-col> -->
                    </b-row>
                </b-card>
                <b-card class="sa-card" header="Anagrafica" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                            <label class="sa-label-data">
                                Identificativo
                                <span style="color: red">*</span>
                            </label>
                            <b-input-group class="mb-3">
                                <b-form-input maxlength="16" class="text-uppercase" v-model="jsonData.accettazioneAnagrafica.identificativo" @input="onValidazioneIdentificativo(jsonData.accettazioneAnagrafica.identificativo)"></b-form-input>
                                <b-input-group-append>
                                    <b-button @click="onSearchAnagraficaClick()" :disabled="id !== '-1' || jsonData.accettazioneAnagrafica.identificativo === '' || jsonData.accettazioneAnagrafica.identificativo === null"><b-icon icon="search"></b-icon></b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-col>
                        <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                            <label class="sa-label-data">
                                {{ this.$i18n.t("patients.lblLastname") }}
                                <span style="color: red">*</span>
                            </label>
                            <b-form-input v-model="jsonData.accettazioneAnagrafica.cognome"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                            <label class="sa-label-data">
                                {{ this.$i18n.t("patients.lblName") }}
                                <span style="color: red">*</span>
                            </label>
                            <b-form-input v-model="jsonData.accettazioneAnagrafica.nome"></b-form-input>
                        </b-col>

                        <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                            <label class="sa-label-data">
                                {{ this.$i18n.t("patients.lblBirthDate") }}
                                <span style="color: red">*</span>
                            </label>
                            <date-picker format="DD/MM/YYYY" value-type="timestamp" v-model="jsonData.accettazioneAnagrafica.dataNascita" type="date"></date-picker>
                        </b-col>
                        <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                            <label class="sa-label-data">
                                {{ this.$i18n.t("patients.lblGender") }}
                                <span style="color: red">*</span>
                            </label>
                            <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.accettazioneAnagrafica.sesso" class="mb-2 mr-sm-2 mb-sm-0" :options="sessoOption" :value="null">
                                <template #first>
                                    <b-form-select-option :value="null" disabled>{{ selectOptionSesso }}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                            <label class="sa-label-data">{{ this.$i18n.t("patients.lblBloodGroup") }}</label>
                            <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.accettazioneAnagrafica.gruppoSanguigno" class="mb-2 mr-sm-2 mb-sm-0" :value="null" :options="gruppoSanguignoOption"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                            <label class="sa-label-data">
                                {{ this.$i18n.t("patients.lblProvince") }}
                                <span style="color: red">*</span>
                            </label>
                            <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.accettazioneAnagrafica.provinciaNascita" class="mb-2 mr-sm-2 mb-sm-0" :options="provinciaNascitaOption" :value="null" @change="loadComuneNascita(jsonData.accettazioneAnagrafica.provinciaNascita)"></b-form-select>
                        </b-col>
                        <b-col v-if="nazionalitaNascita" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                            <label class="sa-label-data">
                                Nazionalità
                                <span style="color: red">*</span>
                            </label>
                            <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.accettazioneAnagrafica.comuneNascitaCodiceIstat" class="mb-2 mr-sm-2 mb-sm-0" :options="comuneNascitaOption" :value="null" value-field="codiceIstat" text-field="comune" @change="onChangeComuneNascita"></b-form-select>
                        </b-col>
                        <b-col v-if="!nazionalitaNascita" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                            <label class="sa-label-data">
                                {{ this.$i18n.t("patients.lblCity") }}
                                <span style="color: red">*</span>
                            </label>
                            <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.accettazioneAnagrafica.comuneNascitaCodiceIstat" class="mb-2 mr-sm-2 mb-sm-0" :options="comuneNascitaOption" :value="null" value-field="codiceIstat" text-field="comune" @change="onChangeComuneNascita"></b-form-select>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Nominativo Medico</label>
                            <b-form-input v-model="jsonData.accettazioneAnagrafica.nominativoMedicoCurante"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Esenzioni Ticket</label>
                            <b-form-input v-model="jsonData.accettazioneAnagrafica.esenzioniTicket"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Esenzioni Ticket Reddito</label>
                            <b-form-input v-model="jsonData.accettazioneAnagrafica.esenzioneTicketReddito"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Isee</label>
                            <b-form-input v-model="jsonData.accettazioneAnagrafica.isee"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="6" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Reddito Inclusivo</label>
                            <b-form-input v-model="jsonData.accettazioneAnagrafica.redditoInclusione"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="6" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Attività Lavorativa</label>
                            <b-form-input v-model="jsonData.accettazioneAnagrafica.attivitaLavorativa"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="6" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Indice Vulnerabilita</label>
                            <b-form-input v-model="jsonData.accettazioneAnagrafica.indiceVulnerabilita"></b-form-input>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card class="sa-card" header="Familiari" header-tag="header" footer-tag="footer" title="">
                    <b-table sticky-header ref="tblFamiliari" stacked="xl" striped hover itemscope :items="jsonData.accettazioneAnagrafica.listaFamiliari" :fields="fieldsListaFamiliari" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="listaFamiliari">
                            {{ listaFamiliari.index + 1 }}
                        </template>
                        <template #cell(cognome)="item">
                            <b-form-input v-if="item.index === jsonData.accettazioneAnagrafica.listaFamiliari.length - 1" v-model="item.item.cognome"></b-form-input>
                            <span class="sa-data" v-else>{{ item.item.cognome }}</span>
                        </template>
                        <template #cell(nome)="item">
                            <b-form-input v-if="item.index === jsonData.accettazioneAnagrafica.listaFamiliari.length - 1" v-model="item.item.nome"></b-form-input>
                            <span class="sa-data" v-else>{{ item.item.nome }}</span>
                        </template>
                        <template #cell(tipologia)="item">
                            <b-form-input v-if="item.index === jsonData.accettazioneAnagrafica.listaFamiliari.length - 1" v-model="item.item.tipologia"></b-form-input>
                            <span class="sa-data" v-else>{{ item.item.tipologia }}</span>
                        </template>
                        <template #cell(minorenne)="item">
                            <b-form-select v-if="item.index === jsonData.accettazioneAnagrafica.listaFamiliari.length - 1" v-model="item.item.minorenne" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                            <span class="sa-data" v-else>{{ item.item.minorenne }}</span>
                        </template>

                        <template #cell(actions)="row">
                            <b-button v-if="row.index == jsonData.accettazioneAnagrafica.listaFamiliari.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddFamiliare(jsonData.accettazioneAnagrafica.listaFamiliari, row.item)">
                                <b-icon icon="plus"></b-icon>
                            </b-button>
                            <b-button v-if="row.index !== jsonData.accettazioneAnagrafica.listaFamiliari.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(jsonData.accettazioneAnagrafica.listaFamiliari, row)">
                                <b-icon icon="trash"></b-icon>
                            </b-button>
                        </template>
                    </b-table>
                </b-card>
                <b-card class="sa-card" header="Lingue" header-tag="header" footer-tag="footer" title="">
                    <template #header>
                        <span>Lingue</span>
                        <accettazioni-lingue-component @update="onUpdateLingue"></accettazioni-lingue-component>
                    </template>
                    <b-row>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label_data"> Lingua Madre </label>
                            <b-form-select v-model="jsonData.accettazioneAnagrafica.linguaMadre" :options="lingueOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                            <label class="sa-label_data"> Altre Lingue </label>
                            <b-form-textarea v-model="jsonData.accettazioneAnagrafica.altreLingueParlate" rows="2" no-resize disabled></b-form-textarea>
                        </b-col>
                    </b-row>
                </b-card>
                <!-- <b-card class="sa-card" header="Residenza" header-tag="header" footer-tag="footer" title="">
                    <b-row> -->
                <!-- <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ this.$i18n.t("patient.lblRegione") }}<span style="color: red">*</span></label>
              <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.accettazioneAnagrafica.regioneResidenza" :state="jsonData.accettazioneAnagrafica.regioneResidenza == null || jsonData.accettazioneAnagrafica.regioneResidenza === '' ? false : true" class="mb-2 mr-sm-2 mb-sm-0" :value="null" value-field="codiceIstat" text-field="regione" :options="regioniOptions" required>
                <template #first>
                  <b-form-select-option :value="null" disabled>{{ selectOptionRegione }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col> -->
                <!-- <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">
                                {{ this.$i18n.t("patients.lblProvince") }}
                                <span style="color: red">*</span>
                            </label>
                            <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.accettazioneAnagrafica.provinciaResidenza" class="mb-2 mr-sm-2 mb-sm-0" :options="provinciaResidenzaOption" :value="null" @change="loadComuneResidenza(jsonData.accettazioneAnagrafica.provinciaResidenza)"></b-form-select>
                        </b-col>
                        <b-col v-if="nazionalita" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">
                                Nazionalità
                                <span style="color: red">*</span>
                            </label>
                            <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.accettazioneAnagrafica.comuneResidenzaCodiceIstat" class="mb-2 mr-sm-2 mb-sm-0" :options="comuneResidenzaOption" :value="null" value-field="codiceIstat" text-field="comune" @change="setCapResidenza"></b-form-select>
                        </b-col>
                        <b-col v-if="!nazionalita" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">
                                {{ this.$i18n.t("patients.lblCity") }}
                                <span style="color: red">*</span>
                            </label>
                            <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.accettazioneAnagrafica.comuneResidenzaCodiceIstat" class="mb-2 mr-sm-2 mb-sm-0" :options="comuneResidenzaOption" :value="null" value-field="codiceIstat" text-field="comune" @change="setCapResidenza"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">
                                {{ this.$i18n.t("patients.lblPostalCode") }}
                                <span style="color: red">*</span>
                            </label>
                            <b-form-input v-model="jsonData.accettazioneAnagrafica.capResidenza"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">
                                {{ this.$i18n.t("patients.lblAddress") }}
                                <span style="color: red">*</span>
                            </label>
                            <b-form-input v-model="jsonData.accettazioneAnagrafica.indirizzoResidenza"></b-form-input>
                        </b-col> -->
                <!-- <b-col cols="12" xs="12" sm="12" md="12" lg="12">
                            <b-form-checkbox v-model="isDomicilioUgualeResidenza" name="check-button" switch>
                                <h5 class="sa-text-left domicilio-residenza-checkbox">{{ this.$i18n.t("patient.lblIsDomicilioEqualResidenza") }}</h5>
                            </b-form-checkbox>
                        </b-col> -->
                <!-- </b-row>
                </b-card> -->
                <b-card v-if="!isDomicilioUgualeResidenza" class="sa-card" header="Domicilio" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">
                                {{ this.$i18n.t("patients.lblProvince") }}
                                <span style="color: red">*</span>
                            </label>
                            <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.accettazioneAnagrafica.provinciaDomicilio" class="mb-2 mr-sm-2 mb-sm-0" :options="provinciaDomicilioOption" @change="loadComuneDomicilio(jsonData.accettazioneAnagrafica.provinciaDomicilio)"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label htmlFor="inline-form-custom-select-pref" class="sa-label-data">
                                {{ this.$i18n.t("patients.lblCity") }}
                                <span style="color: red">*</span>
                            </label>
                            <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.accettazioneAnagrafica.comuneDomicilioCodiceIstat" class="mb-2 mr-sm-2 mb-sm-0" :options="comuneDomicilioOption" :value="null" value-field="codiceIstat" text-field="comune" @change="setCapDomicilio"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">
                                {{ this.$i18n.t("patients.lblPostalCode") }}
                                <span style="color: red">*</span>
                            </label>
                            <b-form-input v-model="jsonData.accettazioneAnagrafica.capDomicilio"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">
                                {{ this.$i18n.t("patients.lblAddress") }}
                                <span style="color: red">*</span>
                            </label>
                            <b-form-input v-model="jsonData.accettazioneAnagrafica.indirizzoDomicilio"></b-form-input>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card class="sa-card" header="Contatti" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                            <label class="sa-label-data">
                                {{ this.$i18n.t("patients.lblTelephoneNumber") }}
                            </label>
                            <b-form-input v-model="jsonData.accettazioneAnagrafica.telefono"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                            <label class="sa-label-data">
                                {{ this.$i18n.t("patients.lblEmail") }}
                            </label>
                            <b-form-input v-model="jsonData.accettazioneAnagrafica.email"></b-form-input>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">{{ this.$i18n.t("patients.lblNote") }}</label>
                            <b-form-textarea v-model="jsonData.note"></b-form-textarea>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card class="sa-card" header="Prestazioni" header-tag="header" footer-tag="footer" title="">
                    <b-table sticky-header ref="tblContatti" stacked="xl" striped hover itemscope :items="jsonData.accettazionePrestazioni" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="accettazionePrestazioni">
                            {{ accettazionePrestazioni.index + 1 }}
                        </template>
                        <template #cell(descrizione)="item">
                            <b-form-select v-if="item.index === jsonData.accettazionePrestazioni.length - 1" v-model="item.item.descrizione" :options="prestazioniOptions" value-field="value" text-field="text"></b-form-select>
                            <span class="sa-data" v-else>{{ item.item.descrizione }}</span>
                        </template>
                        <template #cell(actions)="row">
                            <b-button v-if="row.index == jsonData.accettazionePrestazioni.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddPrestazione(jsonData.accettazionePrestazioni, row.item)">
                                <b-icon icon="plus"></b-icon>
                            </b-button>
                            <b-button v-if="row.index !== jsonData.accettazionePrestazioni.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(jsonData.accettazionePrestazioni, row)">
                                <b-icon icon="trash"></b-icon>
                            </b-button>
                        </template>
                    </b-table>
                </b-card>
            </div>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import AccettazioniLingueComponent from "../components/AccettazioniLingueComponent.vue";
export default {
    components: { DatePicker, SaPageLayout, AccettazioniLingueComponent },
    data() {
        return {
            perPage: 10000000,
            currentPage: 1,
            btnSaveVisible: true,
            stateIdentificativo: false,
            selectOptionGruppoSanguigno: this.$i18n.t("patients.selectGruopBlood"),
            selectOptionRegione: this.$i18n.t("patients.selectRegions"),
            selectOptionSesso: this.$i18n.t("patients.selectGender"),
            selectOptionComune: this.$i18n.t("patients.selectCounties"),
            selectOptionProvincia: this.$i18n.t("patients.selectCity"),
            pathResource: "/donkaccettazione",
            pathResourcePaziente: "/pazienti",
            pathResourceLingue: "/lingue",
            linkback: null,
            linkProvince: "/province",
            pathResourceRegioni: "/regioni",
            linkComune: "/comuni/provincia",
            linkNazionalita: "/nazionalita",
            linkComuneByIstat: "/comuni/istat",
            linkGruppiSangue: "/pazientigruppisanguigno",
            pathResourcePrestazioni: "/ambulatorioprestazioni",
            // pathResourcePrestazioni: "/ambulatoriprestazioninomenclatore",
            pathResourceAmbulatori: "/ambulatoriutenti",
            prestazione: null,
            idAmbulatorio: null,
            showModalLoading: false,
            id: "-1",
            profili: [],
            sessoOption: [],
            regioniOptions: [],
            ambulatoriOptions: [],
            altreLingue: [],
            disableSelectAmbulatorio: true,
            isDomicilioUgualeResidenza: true,
            provinciaNascitaOption: [{ value: null, text: "-Seleziona Valore-" }],
            provinciaDomicilioOption: [{ value: null, text: "-Seleziona Valore-" }],
            provinciaResidenzaOption: [{ value: null, text: "-Seleziona Valore-" }],
            comuneNascitaOption: [{ value: null, text: "-Seleziona Valore-" }],
            prestazioniOptions: [],
            listaPrestazioni: [],
            comuneDomicilioOption: [{ codiceIstat: null, comune: "-Seleziona Valore-" }],
            comuneResidenzaOption: [{ codiceIstat: null, comune: "-Seleziona Valore-" }],
            lingueOptions: [{ value: "", text: "-Seleziona Valore-" }],
            siNoOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "SI", text: "SI" },
                { value: "NO", text: "NO" },
            ],
            language: null,
            gruppoSanguignoOption: [],
            identificationDimension: 0,
            nazionalita: false,
            nazionalitaNascita: false,
            jsonData: {
                codiceStruttura: "",
                numero: "",
                statoAttuale: "",
                statoAttualeDataOra: null,
                note: "",
                regime: "AMBULATORIALE",
                idPrenotazione: "",
                accettazioneStruttura: {},
                accettazioneAnagrafica: {
                    idAccettazione: "",
                    tipo: "",
                    identificativo: "",
                    tipoIdentificativo: "",
                    cognome: "",
                    nome: "",
                    dataNascita: null,
                    comuneNascitaCodiceIstat: null,
                    comuneResidenzaCodiceIstat: null,
                    comuneDomicilioCodiceIstat: null,
                    provinciaDomicilio: "EE",
                    provinciaResidenza: "EE",
                    provinciaNascita: "EE",
                    indirizzoResidenza: "",
                    indirizzoDomicilio: "",
                    note: "",
                    medicoCurante: "",
                    codiceFiscaleMedicoCurante: "",
                    gruppoSanguigno: null,
                    capDomicilio: null,
                    capResidenza: null,
                    telefono: "",
                    email: "",
                    emailMedicoCurante: "",
                    linguaMadre: "",
                    altreLingueParlate: "",
                    sesso: null,
                    listaFamiliari: [],
                },
                accettazioneStati: [],
                accettazionePrestazioni: [],
            },
            fields: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Codice Catalogo",
                    key: "codicePrestazioneAgg",
                    sortable: true,
                    //thStyle: "width: 7rem",
                },
                {
                    label: "Prestazione",
                    key: "descrizione",
                    //thStyle: "width: 5rem",
                    sortable: true,
                },
                { key: "actions", label: "", thStyle: "width: 4rem" },
            ],
            fieldsListaFamiliari: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Cognome",
                    key: "cognome",
                    sortable: true,
                    //thStyle: "width: 7rem",
                },
                {
                    label: "Nome",
                    key: "nome",
                    sortable: true,
                    //thStyle: "width: 7rem",
                },
                {
                    label: "Tipo Parentela",
                    key: "tipologia",
                    //thStyle: "width: 5rem",
                    sortable: true,
                },
                {
                    label: "Minorenne",
                    key: "minorenne",
                    //thStyle: "width: 5rem",
                    sortable: true,
                },
                { key: "actions", label: "", thStyle: "width: 4rem" },
            ],
        };
    },
    computed: {
        dataScadenzaUtenza: {
            get: function () {
                return new Date(this.data.scadenzaUtenza);
            },
            set: function (value) {
                this.data.scadenzaUtenza = moment(String(value)).valueOf();
            },
        },
    },
    mounted: function () {
        let me = this;
        me.identificationDimension = this.appConfig.identificationDimension;
        me.id = this.$route.params.id;
        me.language = this.$route.query.language;
        if (me.id === "-1") {
            me.linkback = "/pazienteaccettazione/";
        } else {
            me.linkback = "/pazienteaccettazione/view/" + me.id;
        }

        me.loadDefaultData();
    },
    watch: {
        isDomicilioUgualeResidenza(value) {
            let me = this;
            if (value) {
                me.jsonData.accettazioneAnagrafica.indirizzoDomicilio = me.jsonData.accettazioneAnagrafica.indirizzoResidenza;
                me.jsonData.accettazioneAnagrafica.comuneDomicilioCodiceIstat = me.jsonData.accettazioneAnagrafica.comuneResidenzaCodiceIstat;
                me.jsonData.accettazioneAnagrafica.provinciaDomicilio = me.jsonData.accettazioneAnagrafica.provinciaResidenza;
                me.jsonData.accettazioneAnagrafica.capDomicilio = me.jsonData.accettazioneAnagrafica.capResidenza;
                // console.log(me.data);
            } else if (!value) {
                (me.jsonData.accettazioneAnagrafica.indirizzoDomicilio = null), (me.jsonData.accettazioneAnagrafica.comuneDomicilioCodiceIstat = null), (me.jsonData.accettazioneAnagrafica.provinciaDomicilio = null), (me.jsonData.accettazioneAnagrafica.capDomicilio = null);
            }
            // console.log(me.data);
        },
        "jsonData.accettazioneAnagrafica.comuneNacitaCodiceIstat": function () {
            // console.log("duplico in domicilio");
            let me = this;
            me.jsonData.accettazioneAnagrafica.comuneResidenzaCodiceIstat = me.jsonData.accettazioneAnagrafica.comuneNascitaCodiceIstat;
            me.jsonData.accettazioneAnagrafica.provinciaResidenza = me.jsonData.accettazioneAnagrafica.provinciaNascita;
            me.jsonData.accettazioneAnagrafica.capResidenza = "";
            me.jsonData.accettazioneAnagrafica.indirizzoResidenza = "";
            me.jsonData.accettazioneAnagrafica.indirizzoDomicilio = me.jsonData.accettazioneAnagrafica.indirizzoResidenza;
            me.jsonData.accettazioneAnagrafica.comuneDomicilioCodiceIstat = me.jsonData.accettazioneAnagrafica.comuneResidenzaCodiceIstat;
            me.jsonData.accettazioneAnagrafica.provinciaDomicilio = me.jsonData.accettazioneAnagrafica.provinciaResidenza;
            me.jsonData.accettazioneAnagrafica.capDomicilio = me.jsonData.accettazioneAnagrafica.capResidenza;
        },
        "jsonData.accettazioneAnagrafica.comuneResidenzaCodiceIstat": function () {
            // console.log("duplico in domicilio");
            let me = this;
            me.jsonData.accettazioneAnagrafica.comuneDomicilioCodiceIstat = me.jsonData.accettazioneAnagrafica.comuneResidenzaCodiceIstat;
            me.jsonData.accettazioneAnagrafica.provinciaDomicilio = me.jsonData.accettazioneAnagrafica.provinciaResidenza;
            me.jsonData.accettazioneAnagrafica.capDomicilio = me.jsonData.accettazioneAnagrafica.capResidenza;
        },
        "jsonData.accettazioneAnagrafica.indirizzoResidenza": function () {
            // console.log("duplico in domicilio");
            let me = this;
            me.jsonData.accettazioneAnagrafica.indirizzoDomicilio = me.jsonData.accettazioneAnagrafica.indirizzoResidenza;
            me.jsonData.accettazioneAnagrafica.comuneDomicilioCodiceIstat = me.jsonData.accettazioneAnagrafica.comuneResidenzaCodiceIstat;
            me.jsonData.accettazioneAnagrafica.provinciaDomicilio = me.jsonData.accettazioneAnagrafica.provinciaResidenza;
            me.jsonData.accettazioneAnagrafica.capDomicilio = me.jsonData.accettazioneAnagrafica.capResidenza;
        },
    },

    methods: {
        // onDataChange(input, id) {
        //   let me = this;
        //   me.checkState(input, id);
        // },
        // checkState(input, id) {
        //   console.log(typeof input);
        //   if (typeof input == "number") {
        //     document.getElementById(id).classList.remove("red-border");
        //     document.getElementById(id).classList.add("green-border");
        //   } else {
        //     document.getElementById(id).classList.remove("green-border");
        //     document.getElementById(id).classList.add("red-border");
        //   }
        // },
        loadDefaultData() {
            let me = this;
            // me.loadPrestazioni();
            me.loadAmbulatori();
            me.loadProvinceNascita();
            me.loadProvinceDomicilio();
            me.loadProvinceResidenza();
            me.loadRegioni();
            me.loadGruppiSangue();
            me.loadLingue();
            me.loadSesso();
            me.loadData();
        },
        loadSesso() {
            let link = process.env.VUE_APP_PATH_API + "/anagraficasesso";
            axios.get(link).then((res) => {
                let sessi = res.data.data;
                sessi.forEach((element) => {
                    let obj = { value: element.codice, text: this.$i18n.t(element.descrizione) };
                    this.sessoOption.push(obj);
                });
            });
        },
        loadLingue() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + this.pathResourceLingue;
            me.lingueOptions = [];
            axios
                .get(link)
                .then((response) => {
                    response.data.data.list.forEach((element) => {
                        me.lingueOptions.push({ value: element.descrizione, text: element.descrizione });
                    });
                    me.lingueOptions.unshift({ value: "", text: "-Seleziona Valore-" });
                })
                .catch(() => {
                    me.lingueOptions.unshift({ value: "", text: "-Seleziona Valore-" });
                });
        },
        loadData() {
            let me = this;

            //console.log(me.id);
            if (me.id === "-1") {
                me.loadComuneNascita(me.jsonData.accettazioneAnagrafica.provinciaNascita);
                me.loadComuneResidenza(me.jsonData.accettazioneAnagrafica.provinciaResidenza);
                me.loadComuneDomicilio(me.jsonData.accettazioneAnagrafica.provinciaDomicilio);
                me.showModalLoading = false;
                me.jsonData.accettazionePrestazioni.push({ descrizione: "", codicePrestazioneAgg: "" });
                me.jsonData.accettazioneAnagrafica.listaFamiliari.push({ cognome: "", minorenne: "", nome: "", tipologia: "" });
            } else {
                me.showModalLoading = true;
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                axios.get(link + me.id).then((response) => {
                    me.jsonData = response.data.data;
                    me.loadComuneNascita(me.jsonData.accettazioneAnagrafica.provinciaNascita);
                    me.loadComuneResidenza(me.jsonData.accettazioneAnagrafica.provinciaResidenza);
                    me.loadComuneDomicilio(me.jsonData.accettazioneAnagrafica.provinciaDomicilio);
                    me.showModalLoading = false;
                    me.validazioneIdentificativo(me.jsonData.accettazioneAnagrafica.identificativo);
                    if (me.jsonData.accettazionePrestazioni.length > 0) {
                        me.prestazione = me.jsonData.accettazionePrestazioni[0].codicePrestazione;
                    }
                    me.jsonData.accettazionePrestazioni.push({ descrizione: "", codicePrestazioneAgg: "" });
                    me.jsonData.accettazioneAnagrafica.listaFamiliari.push({ cognome: "", minorenne: "", nome: "", tipologia: "" });
                    me.changeComuneNascita();
                });
            }
        },
        onSearchAnagraficaClick() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourcePaziente + "/identificativo/" + me.jsonData.accettazioneAnagrafica.identificativo;
            axios
                .get(link)
                .then((response) => {
                    // if (response.data.data.list.length === 1) {
                    me.jsonData.accettazioneAnagrafica = response.data.data;
                    me.loadComuneDomicilio(me.jsonData.accettazioneAnagrafica.provinciaDomicilio);
                    me.loadComuneResidenza(me.jsonData.accettazioneAnagrafica.provinciaResidenza);
                    me.loadComuneNascita(me.jsonData.accettazioneAnagrafica.provinciaNascita);
                    me.changeComuneNascita();
                    // }
                })
                .catch((e) => {
                    console.log(e);
                    //   this.errors.push(e);
                });
        },
        loadPrestazioni() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourcePrestazioni + "/prestazioni?idAmbulatorio=" + me.idAmbulatorio;
            me.prestazioniOptions = [];
            me.prestazioniOptions = [{ value: null, text: "-Seleziona Valore-" }];
            axios
                .get(link)
                .then((response) => {
                    me.listaPrestazioni = response.data.data.list;
                    response.data.data.list.forEach((element) => {
                        me.prestazioniOptions.push({ value: element.codiceCatalogo, text: element.descrizioneCatalogo + " (" + element.codiceCatalogo + ")" });
                    });
                    if (me.prestazioniOptions.length == 2) {
                        let value = { descrizione: me.prestazioniOptions[1].value };
                        me.aggiungiPrestazione(me.jsonData.accettazionePrestazioni, value);
                    }
                    me.ambulatoriOptions.unshift({ value: null, text: "-Seleziona Valore-" });
                })
                .catch((e) => {
                    console.log(e);
                    //   this.errors.push(e);
                });
        },
        loadAmbulatori() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceAmbulatori;
            me.ambulatoriOptions = [];
            axios.get(link).then((response) => {
                me.ambulatoriOptions = response.data.data;
                if (me.ambulatoriOptions.length > 1) {
                    me.ambulatoriOptions.unshift({ id: null, nome: "-Seleziona Valore-" });
                    me.disableSelectAmbulatorio = false;
                } else if (me.ambulatoriOptions.length === 1) {
                    me.disableSelectAmbulatorio = true;
                    me.idAmbulatorio = me.ambulatoriOptions[0].id;
                    me.selectAmbulatorio();
                }
            });
        },
        loadRegioni() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceRegioni;
            axios.get(link).then((response) => {
                me.regioniOptions = response.data.data;
            });
        },
        loadProvinceNascita() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkProvince;
            me.provinciaNascitaOption = [];
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    me.provinciaNascitaOption.push({ value: element.sigla, text: element.provincia });
                });
                me.provinciaNascitaOption.unshift({ value: null, text: "-Seleziona Valore-" });
            });
        },
        loadGruppiSangue() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkGruppiSangue;
            me.gruppoSanguignoOption = [];
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    me.gruppoSanguignoOption.push({ value: element.descrizione, text: this.$i18n.t(element.descrizione) });
                });
                me.gruppoSanguignoOption.unshift({ value: null, text: "-Seleziona Valore-" });
            });
        },
        loadProvinceDomicilio() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkProvince;
            me.provinciaDomicilioOption = [];
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    me.provinciaDomicilioOption.push({ value: element.sigla, text: element.provincia });
                });
                me.provinciaDomicilioOption.unshift({ value: null, text: "-Seleziona Valore-" });
            });
        },
        loadProvinceResidenza() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkProvince;
            me.provinciaResidenzaOption = [];
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    me.provinciaResidenzaOption.push({ value: element.sigla, text: element.provincia });
                });
                me.provinciaResidenzaOption.unshift({ value: null, text: "-Seleziona Valore-" });
            });
        },
        loadComuneNascita(sigla) {
            let me = this;
            me.nazionalitaNascita = false;
            if (sigla === "EE") {
                me.nazionalitaNascita = true;
                let link = process.env.VUE_APP_PATH_API + me.linkNazionalita;
                me.comuneNascitaOption = [];
                axios.get(link).then((response) => {
                    response.data.data.list.forEach((element) => {
                        let nazionalita = { comune: element.cittadinanza + " - (" + element.denominazione + ")", provincia: "EE", cap: "0", codiceIstat: element.istatStato, codiceCatastale: element.codiceCatastale };
                        me.comuneNascitaOption.push(nazionalita);
                    });

                    me.comuneNascitaOption.unshift({ codiceIstat: null, comune: "-Seleziona Valore-" });
                });
            } else {
                let link = process.env.VUE_APP_PATH_API + me.linkComune + "/" + sigla;
                me.comuneNascitaOption = [];
                axios.get(link).then((response) => {
                    me.comuneNascitaOption = [];
                    // response.data.data.forEach((element) => {
                    //     me.comuneNascitaOption.push({ value: element.codiceIstat, text: element.comune });
                    // });
                    me.comuneNascitaOption = response.data.data;
                    me.comuneNascitaOption.unshift({ codiceIstat: null, comune: "-Seleziona Valore-" });
                });
            }
        },
        loadComuneDomicilio(sigla) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkComune + "/" + sigla;
            me.comuneDomicilioOption = [];
            axios.get(link).then((response) => {
                me.comuneDomicilioOption = response.data.data;
                me.comuneDomicilioOption.unshift({ codiceIstat: null, comune: "-Seleziona Valore-" });
            });
        },
        setCapDomicilio(item) {
            let me = this;
            me.comuneDomicilioOption.forEach((comuneDomicilio) => {
                if (comuneDomicilio.codiceIstat === item) {
                    me.jsonData.accettazioneAnagrafica.capDomicilio = comuneDomicilio.cap;
                }
            });
        },
        loadComuneResidenza(sigla) {
            let me = this;
            // console.log(sigla);
            me.nazionalita = false;
            if (sigla === "EE") {
                me.nazionalita = true;
                let link = process.env.VUE_APP_PATH_API + me.linkNazionalita;
                me.comuneResidenzaOption = [];
                axios.get(link).then((response) => {
                    response.data.data.list.forEach((element) => {
                        let nazionalita = { comune: element.cittadinanza + " - (" + element.denominazione + ")", provincia: "EE", cap: "0", codiceIstat: element.istatStato, codiceCatastale: element.codiceCatastale };
                        me.comuneResidenzaOption.push(nazionalita);
                    });

                    me.comuneResidenzaOption.unshift({ codiceIstat: null, comune: "-Seleziona Valore-" });
                });
            } else {
                let link = process.env.VUE_APP_PATH_API + me.linkComune + "/" + sigla;
                me.comuneResidenzaOption = [];
                axios.get(link).then((response) => {
                    me.comuneResidenzaOption = response.data.data;
                    me.comuneResidenzaOption.unshift({ codiceIstat: null, comune: "-Seleziona Valore-" });
                });
            }
        },
        setCapResidenza(item) {
            let me = this;
            me.comuneResidenzaOption.forEach((comuneResidenza) => {
                if (comuneResidenza.codiceIstat === item) {
                    me.jsonData.accettazioneAnagrafica.capResidenza = comuneResidenza.cap;
                }
            });
        },
        onValidazioneIdentificativo(value) {
            let me = this;
            me.validazioneIdentificativo(value);
        },
        validazioneIdentificativo(value) {
            let me = this;
            if (value.length != me.identificationDimension) {
                me.stateIdentificativo = false;
                return;
            }
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + "/anagrafica/validazioneidentificativo?identificativo=" + value;
            axios
                .get(link)
                .then(() => {
                    // me.btnSaveVisible = true;
                    me.stateIdentificativo = true;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    // me.btnSaveVisible = false;
                    me.stateIdentificativo = false;
                    me.showModalLoading = false;
                });
        },
        onSelectPrestazione() {
            let me = this;
            me.listaPrestazioni.forEach((element) => {
                if (element.codicePrestazione === me.prestazione) {
                    element.idAmbulatorio = me.idAmbulatorio;
                    me.jsonData.accettazionePrestazioni.push(element);
                    // console.log(element);
                }
            });
        },
        onSelectAmbulatorio() {
            let me = this;
            me.selectAmbulatorio();
        },
        selectAmbulatorio() {
            let me = this;

            me.ambulatoriOptions.forEach((element) => {
                if (element.id === me.idAmbulatorio) {
                    me.jsonData.codiceStruttura = element.codiceStruttura;
                    me.loadPrestazioni();
                }
            });
        },
        onAddPrestazione(array, value) {
            let me = this;
            me.aggiungiPrestazione(array, value);
        },
        aggiungiPrestazione(array, value) {
            if (value.descrizione !== "") {
                let me = this;
                me.listaPrestazioni.forEach((element) => {
                    if (element.codiceCatalogo === value.descrizione) {
                        // console.log(element);
                        value = element;
                        // console.log(element);
                        array[array.length - 1] = {};
                        array[array.length - 1].codicePrestazioneAgg = element.codiceCatalogo;
                        array[array.length - 1].codicePrestazione = element.codiceNomenclatore;
                        array[array.length - 1].codiceBranca = "";
                        array[array.length - 1].codiceAggAttivo = "";
                        array[array.length - 1].codiceRegione = "150";
                        array[array.length - 1].idAmbulatorio = me.idAmbulatorio;
                        array[array.length - 1].importoPrestazione = 0;
                        array[array.length - 1].qtaPrescrivibile = 1;
                        array[array.length - 1].descrizione = element.descrizioneCatalogo;
                        array[array.length - 1].codiceRegione = "150";
                        // console.log(array.length - 1);
                        // array[array.length - 1].codiceRegione = element.codiceRegione;
                    }
                });
                array.push({ descrizione: "" });
            } else {
                this.$bvModal
                    .msgBoxOk("Prestazione non valida", {
                        title: "ATTENZIONE",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    })
                    .then((value) => {
                        console.log(value);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        onAddFamiliare(array, value) {
            let me = this;
            me.aggiungiFamiliare(array, value);
        },
        aggiungiFamiliare(array, value) {
            if ((value.cognome !== "", value.minorenne !== "", value.nome !== "", value.tipologia !== "")) {
                array.push({ cognome: "", minorenne: "", nome: "", tipologia: "" });
            } else {
                this.$bvModal
                    .msgBoxOk("Prestazione non valida", {
                        title: "ATTENZIONE",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    })
                    .then((value) => {
                        console.log(value);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        onDelete(array, row) {
            if (row.index != array.length) {
                this.$bvModal
                    .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
                        title: "Titolo",
                        buttonSize: "sm",
                        okVariant: "secondary",
                        cancelVariant: "danger",
                        okTitle: "SI",
                        cancelTitle: "NO",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true,
                    })
                    .then((value) => {
                        if (value) {
                            array.splice(row.index, 1);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        onChangeComuneNascita() {
            //jsonData.accettazioneAnagrafica.comuneNascitaCodiceIstat
            //jsonData.accettazioneAnagrafica.provinciaNascita
            let me = this;
            me.changeComuneNascita();
        },
        changeComuneNascita() {
            let me = this;
            // console.log("Sono Qui");
            me.jsonData.accettazioneAnagrafica.comuneResidenzaCodiceIstat = me.jsonData.accettazioneAnagrafica.comuneNascitaCodiceIstat;
            me.jsonData.accettazioneAnagrafica.provinciaResidenza = me.jsonData.accettazioneAnagrafica.provinciaNascita;
            me.jsonData.accettazioneAnagrafica.capResidenza = "";
            me.jsonData.accettazioneAnagrafica.indirizzoResidenza = "";
            me.jsonData.accettazioneAnagrafica.indirizzoDomicilio = me.jsonData.accettazioneAnagrafica.indirizzoResidenza;
            me.jsonData.accettazioneAnagrafica.comuneDomicilioCodiceIstat = me.jsonData.accettazioneAnagrafica.comuneResidenzaCodiceIstat;
            me.jsonData.accettazioneAnagrafica.provinciaDomicilio = me.jsonData.accettazioneAnagrafica.provinciaResidenza;
            me.jsonData.accettazioneAnagrafica.capDomicilio = me.jsonData.accettazioneAnagrafica.capResidenza;
            me.jsonData.accettazioneAnagrafica.capDomicilio = "";
            me.jsonData.accettazioneAnagrafica.capNascita = "";
            me.jsonData.accettazioneAnagrafica.capResidenza = "";
            me.jsonData.accettazioneAnagrafica.emailMedicoCurante = "";
        },
        // onAfterSave(data, id) {
        //     // let me = this;
        //     // // console.log("Aftersave");
        //     // if (!id) {
        //     //     me.id = data.id;
        //     //     me.$router.replace(me.linkback).catch((err) => {
        //     //         err;
        //     //     });
        //     // }
        // },
        onUpdateLingue(data) {
            console.log(data);
            let me = this;
            me.jsonData.accettazioneAnagrafica.altreLingueParlate = data;
            // this.$emit("update", me.jsonData);
        },
    },
};
</script>
<style>
.red-border input {
    border: 1px solid #dc3545;
    border-radius: 5px;
}

.green-border input {
    border: 1px solid #28a745;
    border-radius: 5px;
}

.domicilio-residenza-checkbox {
    margin-top: 0.2rem;
}
</style>
