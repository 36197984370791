<template>
    <div class="login-box">
        <div class="login-logo">
            <img :src="logoLogin" alt="Logo" class="sa-logo-esteso" />
        </div>
        <div class="sa-card-login">
            <div v-if="autenticationLocal" class="card-body login-card-body sa-box-login">
                <p class="login-box-msg">
                    <span>Benvenuto!</span>
                    <span class="sa-login-page-box-login-msg-secondary">Inserire le proprie credenziali</span>
                </p>
                <form @submit.prevent="onSubmit()">
                    <label for="emailaddress">Nome Utente</label>
                    <div class="input-group mb-3">
                        <b-input class="form-control" placeholder="Inserisci il tuo nome utente" name="client_id" v-model="form.username" autocomplete="off" required />
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <font-awesome-icon icon="user" />
                            </div>
                        </div>
                    </div>
                    <label for="emailaddress">Password</label>
                    <div class="input-group mb-3">
                        <b-input type="password" class="form-control" placeholder="Inserisci la tua Password" name="client_secret" v-model="form.client_secret" required />
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <font-awesome-icon icon="lock" />
                                <!--<span class="fas fa-lock"></span>-->
                            </div>
                        </div>
                    </div>
                    <!-- <label for="emailaddress">{{ $t("login.lblLanguage") }}</label>
                    <div class="input-group mb-3">
                        <span style="margin-right: 5px" class="align-middle sa-flag-login"><country-flag :country="getFlag()" size="normal" class="align-middle" /></span>
                        <b-form-select @change="onChange" v-model="locale" :options="locales" class="mb-2"></b-form-select>
                    </div> -->
                    <br />
                    <div class="social-auth-links text-center mb-3">
                        <b-button type="submit" variant="btn btn-login btn-block">
                            <font-awesome-icon icon="sign-in-alt" />
                            &nbsp;&nbsp;&nbsp;Accedi
                        </b-button>
                    </div>
                </form>
                <!-- /.social-auth-links -->
                <br />
                <b-row>
                    <b-col cols="12" sm="12" md="12" lg="12" xl="12" v-if="loginSpidEnable">
                        <!-- <b-button variant="btn btn-block btn-login-secondary"> Entra con SPID </b-button> -->
                        <div class="text-center">
                            <a href="#" class="italia-it-button italia-it-button-size-m button-spid" spid-idp-button="#spid-idp-button-small-get" aria-haspopup="true" aria-expanded="false" @click="onClickLoginSpid">
                                <span class="italia-it-button-icon"><img src="img/spid-ico-circle-bb.svg" onerror="this.src='img/spid-ico-circle-bb.png'; this.onerror=null;" alt="" /></span>
                                <span class="italia-it-button-text">Entra con SPID</span>
                            </a>
                        </div>
                    </b-col>
                </b-row>
                <br />
                <b-row>
                    <b-col cols="12" sm="6" md="6" lg="6" xl="6" v-if="gestionePasswordUtenteEnable && activeAssistenza">
                        <b-button variant="btn btn-block btn-login-secondary" @click="onClickResetPassword">
                            <font-awesome-icon icon="sign-in-alt" />
                            &nbsp;&nbsp;&nbsp;Reset Password
                        </b-button>
                    </b-col>
                    <b-col cols="12" sm="6" md="6" lg="6" xl="6" v-if="activeAssistenza">
                        <b-button variant="btn btn-block btn-login-secondary" @click="onClickAssistenza">
                            <i class="fa fa-envelope" aria-hidden="true"></i>
                            Richiedi Assistenza
                        </b-button>
                    </b-col>
                    <b-col cols="12" sm="12" md="12" lg="12" xl="12" v-if="!gestionePasswordUtenteEnable && activeAssistenza">
                        <b-button variant="btn btn-block btn-login-secondary" @click="onClickAssistenza">
                            <i class="fa fa-envelope" aria-hidden="true"></i>
                            Richiedi Assistenza
                        </b-button>
                    </b-col>
                    <b-col cols="12" sm="12" md="12" lg="12" xl="12" v-if="gestionePasswordUtenteEnable && !activeAssistenza">
                        <b-button variant="btn btn-block btn-login-secondary" @click="onClickResetPassword">
                            <font-awesome-icon icon="sign-in-alt" />
                            &nbsp;&nbsp;&nbsp;Reset Password
                        </b-button>
                    </b-col>
                </b-row>
                <!--<b-row>
					<b-col sm="6" md="6" lg="6" xl="6" v-if="gestionePasswordUtenteEnable" class="sa-reset-password">
						<font-awesome-icon icon="lock" />
						<span><a class="sa-lnk" v-b-modal.modal-prevent-closing>Reset Password</a></span>
					</b-col>
					<b-col sm="6" md="6" lg="6" xl="6" v-if="gestionePasswordUtenteEnable" class="sa-reset-password">
						<font-awesome-icon icon="email" />
						<span><a class="sa-lnk" v-b-modal.modal-prevent-closing>Reset Password</a></span>
					</b-col>
				</b-row>-->
                <!--<div class="social-auth-links text-center mb-3">
					<b-button v-if="activeAssistenza" variant="btn btn-assistenza btn-block" @click="onClickAssistenza"> &nbsp;&nbsp;&nbsp;Richiedi Assistenza </b-button>
				</div>-->
                <!-- <b-row>
                    <b-col v-if="gestionePasswordUtenteEnable" class="sa-reset-password">
                        Per Assitenza Scrivere a:
                        <span>
                            <a href="mailto:ASSISTENZA.MANAGEDSERVICES@VODAFONE.COM" class="sa-lnk">ASSISTENZA.MANAGEDSERVICES@VODAFONE.COM</a>
                        </span>
                    </b-col>
                </b-row> -->
                <p class="mb-1"></p>
                <p class="mb-1"></p>
            </div>
            <div v-if="autenticationExternal" class="card-body login-card-body sa-box-login">
                <p class="login-box-msg">ACCEDI</p>
                <form @submit.prevent="onWso2Submit()">
                    <br />
                    <div class="social-auth-links text-center mb-3">
                        <b-button type="submit" variant="btn btn-login btn-block">
                            <font-awesome-icon icon="sign-in-alt" />
                            &nbsp;&nbsp;&nbsp;Login
                        </b-button>
                    </div>
                </form>
            </div>
            <div>
                <p style="text-align: center; margin-top: 10px; margin-bottom: 3px; font-style: italic">
                    2024 © {{ applicationName }} powered by
                    <a :href="poweredLink" target="_blank" class="sa-gesan-link">{{ poweredBy }}</a>
                </p>
                <p style="text-align: center; margin-top: 5px; font-size: 12px; font-style: italic">ver. {{ getVersionText() }}</p>
            </div>
        </div>
        <sa-modal-loading ref="sa-modal-loading" />

        <div>
            <b-modal ref="mdlResetPassword" title="Inserisci il tuo indirizzo email" @show="onResetModal" @hidden="onResetModal" @ok="onOk" centered :ok-only="resetPassword" :hide-header-close="resetPassword" :no-close-on-backdrop="resetPassword">
                <form ref="form" @submit.stop.prevent="onResetPasswordSubmit">
                    <b-form-group label="Email" label-for="email-input" invalid-feedback="L'indirizzo email è obbligatorio" :state="emailState">
                        <b-form-input placeholder="Email" id="email-input" v-model="email" :state="emailState" required></b-form-input>
                    </b-form-group>
                </form>
            </b-modal>
        </div>
        <div>
            <b-modal ref="mdlCambiaPassword" no-close-on-backdrop centered :title="titolo" hide-footer>
                <form ref="form" @submit.prevent="onCambiaPasswordSubmit">
                    <b-form-group label="Vecchia Password" label-for="email-input" invalid-feedback="L'indirizzo email è obbligatorio">
                        <b-form-input placeholder="Vecchia Password" type="password" id="email-input" v-model="nuoveCredenziali.oldPassword" required></b-form-input>
                    </b-form-group>
                    <b-form-group label="Nuova Password" label-for="email-input" invalid-feedback="L'indirizzo email è obbligatorio">
                        <b-form-input placeholder="Nuova Password" type="password" id="email-input" v-model="nuoveCredenziali.newPassword" required></b-form-input>
                    </b-form-group>
                    <b-form-group label="Verifica Password" label-for="email-input" invalid-feedback="L'indirizzo email è obbligatorio">
                        <b-form-input placeholder="Verifica Password" type="password" id="email-input" v-model="nuoveCredenziali.verificaPassword" required></b-form-input>
                    </b-form-group>
                    <b-row>
                        <b-col cols="12" sm="12" class="sa-text-right"><b-button size="sm" variant="outline-secondary" type="submit">Aggiorna Password</b-button></b-col>
                    </b-row>
                </form>
            </b-modal>
        </div>
        <b-modal id="mdlAssistenza" ref="mdlAssistenza" title="Assistenza" size="xl" no-close-on-backdrop>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"></b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <vue-recaptcha :sitekey="captchaKey" @verify="onVerifyMethod" @expired="expiredMethod"></vue-recaptcha>
                    <!--@expired="expiredMethod" @render="renderMethod" @error="errorMethod"-->
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"></b-col>
                <b-col v-if="isVisibleAssistenza" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label style="text-align: center" class="sa-label-data">Per ASSISTENZA scrivere a: <a href="mailto:ASSISTENZA.MANAGEDSERVICES@VODAFONE.COM" class="sa-lnk">ASSISTENZA.MANAGEDSERVICES@VODAFONE.COM</a></label>
                    <label style="text-align: center" class="sa-label-data">
                        Per la richiesta delle credenziali dei Medici di Medicina Generale rivolgersi al Numero <span><a href="tel:0823494935" class="sa-lnk">0823494935</a></span> Int: <span>224</span>
                    </label>
                </b-col>
            </b-row>
        </b-modal>
        <b-modal id="mdlTwoFactor" ref="mdlTwoFactor" title="Codice O.T.P." @show="onShowTwoFactor" @hidden="onHiddenTwoFactor" @ok="onOkTwoFactor" no-close-on-backdrop>
            <form ref="form" @submit.stop.prevent="onOkTwoFactor">
                <b-form-group :label="labelOtp" label-for="name-input" invalid-feedback="">
                    <b-form-input id="name-input" ref="txtSecondFactor" v-model="twoFactor.secondFactor" required></b-form-input>
                </b-form-group>
            </form>
        </b-modal>
    </div>
</template>

<script>
// import DatePicker from "vue2-datepicker";
import Vue from "vue";
// import AuthBg from "../../../assets/images/sfondo.jpg";
import qs from "qs";
import axios from "axios";
import router from "../../../router";
import { SaModalLoading } from "../../utility";
import { VueRecaptcha } from "vue-recaptcha";
export default {
    // meta: { bgImage: AuthBg },
    components: { SaModalLoading, VueRecaptcha },
    data() {
        return {
            identityManagerParams: { clientId: null },
            resetPassword: false,
            autenticationLocal: true,
            autenticationExternal: false,
            labelOtp: "O.T.P.'",
            pathResourceTwoFactor: "/token/twofactorauthenticator",
            pathResourceIdentityManagerVerify: "/identitymanagerverify",
            applicationName: "",
            checkDomicilioUgualeResidenza: false,
            isDisabledTipoIdentificativo: true,
            tabIndex: 0,
            gestionePasswordUtenteEnable: false,
            loginSpidEnable: false,
            activeAssistenza: false,
            isVisibleAssistenza: false,
            captchaKey: "",
            locale: this.$i18n.locale,
            testoModel: "Avanti",
            locales: [
                { value: "en", text: "English (en)" },
                { value: "it", text: "Italiano (it)" },
                { value: "hr", text: "Hrvatski (hr)" },
                { value: "sv", text: "Swedish (sv)" },
                { value: "tr", text: "Turkish (tr)" },
            ],
            sessoOption: [
                { text: " - Selezionare - ", value: null },
                { text: "Maschio", value: "M" },
                { text: "Femmina", value: "F" },
            ],
            nazionalitaOptions: [{ comune: "- Seleziona Nazionalita' -", codiceIstat: null }],
            comuniResidenzaOptions: [{ comune: "- Seleziona Comune Residenza -", codiceIstat: null }],
            comuniDomicilioOptions: [{ comune: "- Seleziona Comune Domicilio -", codiceIstat: null }],
            comuniNascitaOptions: [{ comune: "- Seleziona Comune Nascita -", codiceIstat: null }],

            provinceResidenzaOptions: [],
            provinceDomicilioOptions: [],
            provinceNascitaOptions: [],

            tipoIdentificativoOption: [
                { text: "- Seleziona Tipo Identificativo' -", value: null },
                { text: "Carta D'Identita'", value: "CI" },
                { text: "Codice Fiscale", value: "CF" },
                { text: "Passaporto", value: "PS" },
            ],

            anagrafica: {
                /*DataBase*/
                provinciaDomicilioCodiceIstat: null,
                provinciaNascitaCodiceIstat: null,
                provinciaResidenzaCodiceIstat: null,
                capDomicilio: null,
                capResidenza: null,
                cellulare: null,
                codiceStruttura: null,
                comuneDomicilioCodiceIstat: null,
                comuneResidenzaCodiceIstat: null,
                comuneNascitaCodiceIstat: null,
                email: null,
                identificativo: null,
                indirizzoDomicilio: null,
                indirizzoResidenza: null,
                nazione: "111100",
                cognome: null,
                sesso: null,
                dataNascita: null,
                tipoIdentificativo: "CF",
            },
            form: {
                username: "",
                client_secret: "",
                client_id: null,
            },
            show: true,
            emailState: false,
            email: "",
            nuoveCredenziali: {},
            twoFactor: { username: null, secondFactor: null },
            titolo: "",
            logoLogin: "css/" + Vue.prototype.appConfig.css + "/images/logo_login.png",
            sfondo: "css/" + Vue.prototype.appConfig.css + "/images/sfondo.jpg",
        };
    },
    created() {
        //console.log(this.appConfig);
    },
    mounted() {
        this.gestionePasswordUtenteEnable = this.appConfig.gestionePasswordUtenteEnable;
        this.loginSpidEnable = this.appConfig.loginSpidEnable;
        this.activeAssistenza = this.appConfig.activeAssistenza;
        this.captchaKey = this.appConfig.captchaKey;
        if (this.appConfig.authenticationType) {
            if (this.appConfig.authenticationType !== "local") {
                this.autenticationLocal = false;
                this.autenticationExternal = true;
            }
        }
        document.body.classList.add("hold-transition");
        document.body.classList.add("login-page");
        document.body.style.backgroundImage = "url(" + this.sfondo + ")";
        // document.body.style.backgroundSize = "100%";
        document.body.style.backgroundSize = "cover";
        document.body.style.backgroundRepeat = "no-repeat";
        document.body.style.backgroundColor = "var(--bg-main-color)";
        document.body.style.backgroundAttachment = "fixed";
        document.body.style.backgroundPosition = "center";
        this.applicationName = this.appConfig.applicationName;
        if (this.$route.query.resetPassword) {
            this.resetPassword = true;
            this.$refs.modal.show();
        } else {
            this.resetPassword = false;
        }
        console.log(this.$route.query.clientId);
        if (this.$route.query.clientId) {
            this.verificaIdentityManager(this.$route.query.clientId);
        }
        // console.log(this.$route.query);
        // console.log(this.resetPassword);
    },
    destroyed() {
        document.body.classList.remove("hold-transition");
        document.body.classList.remove("login-page");
    },
    watch: {
        tabIndex: function (value) {
            let me = this;
            if (value === 1) {
                me.testoModel = "Registrati";
            } else {
                me.testoModel = "Avanti";
            }
        },
        checkDomicilioUgualeResidenza: function (value) {
            console.log(value);
            let me = this;
            if (value) {
                me.anagrafica.provinciaDomicilioCodiceIstat = me.anagrafica.provinciaResidenzaCodiceIstat;
                me.anagrafica.comuneDomicilioCodiceIstat = me.anagrafica.comuneResidenzaCodiceIstat;
                me.anagrafica.indirizzoDomicilio = me.anagrafica.indirizzoResidenza;
                me.anagrafica.capDomicilio = me.anagrafica.capResidenza;
                me.anagrafica.frazioneDomicilio = me.anagrafica.frazioneResidenza;
                me.anagrafica.civicoDomicilio = me.anagrafica.civicoResidenza;
            }
        },
    },
    computed: {
        poweredBy() {
            return this.appConfig.poweredBy;
        },
        poweredLink() {
            return this.appConfig.poweredLink;
        },
    },
    methods: {
        getFlag() {
            let me = this;

            if (me.locale === "en") {
                return "gb";
            } else if (me.locale === "sv") {
                return "se";
            } else {
                return me.locale;
            }
        },
        getVersionText() {
            return process.env.VUE_APP_VERSION;
        },
        onResetModal() {
            this.email = "";
            this.emailState = null;
        },
        onSubmit() {
            let me = this;
            let clientId = me.identityManagerParams.clientId ? me.identityManagerParams.clientId : me.form.username;
            let client_secret = me.form.client_secret;
            let unencodedToken = clientId + ":" + client_secret;
            let encodedToken = "Basic " + btoa(unencodedToken);
            me.$refs["sa-modal-loading"].show();
            const config = {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: encodedToken,
                },
            };
            let uri = process.env.VUE_APP_PATH_API + "/token";
            axios
                .post(
                    uri,
                    qs.stringify({
                        grant_type: "client_credentials",
                        client_id: me.identityManagerParams.clientId,
                        client_secret: me.form.client_secret,
                        username: me.form.username,
                    }),
                    config
                )
                .then((response) => {
                    me.$refs["sa-modal-loading"].close();
                    localStorage.setItem("loginData", JSON.stringify(response.data));
                    me.labelOtp = "Inserire l'O.T.P. inviato per e-mail all'indirizzo: ";
                    if (response.data.code) {
                        window.location.href = response.data.urlCallback + "?code=" + response.data.code;
                    } else {
                        router.push({
                            name: "Home",
                            query: {
                                language: me.locale,
                                authToken: response.data.access_token,
                            },
                        });
                    }
                })
                .catch((error) => {
                    me.$refs["sa-modal-loading"].close();
                    if (error.response.data.data) {
                        console.log(error.response.data.data.tipoErrore);

                        let tipoErrore = error.response.data.data.tipoErrore;
                        switch (tipoErrore) {
                            case "PASSWORD_EXPIRED":
                                me.titolo = "Password Scaduta";
                                me.nuoveCredenziali.username = me.form.username;
                                me.$refs.mdlCambiaPassword.show();
                                break;
                            case "PASSWORD_TEMPORANY":
                                me.nuoveCredenziali.username = me.form.username;
                                me.titolo = "Password Temporanea, si prega di cambiarla";
                                me.$refs.mdlCambiaPassword.show();
                                break;
                            case "TWO_FACTORY_AUTHENTICATOR":
                                me.twoFactor.username = me.form.username;
                                me.twoFactor.secondFactor = "";
                                me.titolo = "Password Temporanea, si prega di cambiarla";
                                me.$refs.mdlTwoFactor.show();
                                break;
                            case "USER_BLOCKED":
                                this.$bvModal
                                    .msgBoxOk(error.response.data.messaggio, {
                                        title: this.$i18n.t("ATTENZIONE!"),
                                        size: "sm",
                                        okVariant: "outline-danger",
                                        headerClass: "sa-msg-header-danger",
                                        footerClass: "p-2 border-top-0",
                                        centered: true,
                                    })
                                    .then((value) => {
                                        this.boxTwo = value;
                                    })
                                    .catch((err) => {
                                        console.log(err);
                                    });
                                break;
                        }
                    } else {
                        let messaggio = this.$i18n.t("Errore Sconosciuto");
                        if (error.response.data.messaggio) {
                            messaggio = error.response.data.messaggio;
                        }
                        this.$bvModal
                            .msgBoxOk(messaggio, {
                                title: this.$i18n.t("ATTENZIONE!"),
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then((value) => {
                                this.boxTwo = value;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    }
                });
        },
        checkFormValidity() {
            let me = this;
            if (me.email.trim()) {
                return true;
            } else {
                return false;
            }
        },
        onClickAssistenza() {
            let me = this;

            me.$refs["mdlAssistenza"].show();
            me.isVisibleAssistenza = false;
        },
        onOk(bvModalEvt) {
            bvModalEvt.preventDefault();
            if (!this.checkFormValidity()) {
                // this.$bvModal
                //         .msgBoxOk("response.data.messaggio", {
                //             title: "INFO",
                //             size: "sm",
                //             okVariant: "outline-danger",
                //             headerClass: "sa-msg-header-danger",
                //             footerClass: "p-2 border-top-0",
                //             centered: true,
                //         })
                //         .then((value) => {
                //             this.boxTwo = value;
                //         })
                //         .catch((err) => {
                //             console.log(err);
                //         });
                return;
            }
            let me = this;
            let link = process.env.VUE_APP_PATH_API + "/users/resetpassword?email=" + me.email;
            axios
                .put(link, {})
                .then((response) => {
                    me.$refs["sa-modal-loading"].close();
                    this.$bvModal
                        .msgBoxOk(response.data.messaggio, {
                            title: "INFO",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then((value) => {
                            this.boxTwo = value;
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                    this.$bvModal.hide("modal-prevent-closing");
                })
                .catch((error) => {
                    me.$refs["sa-modal-loading"].close();
                    this.$bvModal.msgBoxOk(error.response.data.messaggio, {
                        title: "ATTENZIONE",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    });
                });
        },
        onChange(lang) {
            this.$router.push({ path: this.$route.path, query: { ...this.$route.query, language: lang } });
            this.$router.go();
        },
        onCambiaPasswordSubmit() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + "/users/cambiapassword";
            axios.defaults.headers["Content-Type"] = "application/json";
            axios
                .put(link, JSON.stringify(me.nuoveCredenziali))
                .then((response) => {
                    console.log(response),
                        this.$bvToast.toast(this.$i18n.t("login.lblCorrectOperation"), {
                            title: "",
                            variant: "success",
                            solid: true,
                        });
                    me.$refs.mdlCambiaPassword.hide();
                })
                .catch((error) => {
                    console.log(error), (me.showmolalloading = false);
                    let messaggio = this.$i18n.t(error.response.data.messaggio);
                    this.$bvModal
                        .msgBoxOk(messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then((value) => {
                            this.boxTwo = value;
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                });
        },
        onShowTwoFactor() {
            // this.$refs.txtSecondFactor.focus();
            setTimeout(() => this.$refs.txtSecondFactor.focus(), 500);
        },
        onHiddenTwoFactor() {
            let me = this;
            me.form.username = "";
            me.form.client_secret = "";
        },
        onOkTwoFactor() {
            let me = this;
            let uri = process.env.VUE_APP_PATH_API + me.pathResourceTwoFactor;
            axios.defaults.headers["Content-Type"] = "application/json";
            axios
                .post(uri, me.twoFactor)
                .then((response) => {
                    me.$refs["sa-modal-loading"].close();
                    localStorage.setItem("loginData", JSON.stringify(response.data));
                    me.labelOtp = "Inserire l'O.T.P. inviato per e-mail all'indirizzo: ";
                    router.push({
                        name: "Home",
                        query: {
                            language: me.locale,
                            authToken: response.data.access_token,
                        },
                    });
                })
                .catch((error) => {
                    let messaggio = error.response.data.messaggio ? error.response.data.messaggio : "Error";
                    me.$refs["sa-modal-loading"].close();
                    me.$bvModal
                        .msgBoxOk(messaggio, {
                            title: me.$i18n.t("ATTENZIONE!"),
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then(() => {})
                        .catch(() => {});
                });
        },
        async onWso2Submit() {
            console.log("WSO2 Login");
            console.log(window.open("https://10.10.13.166:9443/oauth2/authorize?response_type=code&client_id=fAWA7UHEp53ANlnaMGtHuCf8OTwa&scope=openid&redirect_uri=https%3A%2F%2Foauth.pstmn.io%2Fv1%2Fcallback&code_challenge=TwWIohZ-PIPmZLcM-VE3t5TxFC42rf03mbkW-db3Cf8&code_challenge_method=S256", "_blank"));
            // const w = window.open("", "_blank");
            // const url = await getUrlAsync();
            // w.location = url;
            // try {
            //     const response = await axios.get("https://10.10.13.166:9443/oauth2/authorize?response_type=code&client_id=fAWA7UHEp53ANlnaMGtHuCf8OTwa&scope=openid&redirect_uri=https%3A%2F%2Foauth.pstmn.io%2Fv1%2Fcallback&code_challenge=TwWIohZ-PIPmZLcM-VE3t5TxFC42rf03mbkW-db3Cf8&code_challenge_method=S256");
            //     this.values = response.data;
            // } catch (err) {
            //     this.values.push("Ooops!" + err);
            // }
        },
        onVerifyMethod() {
            let me = this;
            me.isVisibleAssistenza = true;
        },
        expiredMethod() {
            let me = this;
            me.isVisibleAssistenza = false;
        },
        onClickResetPassword() {
            console.log("Ciao");
            let me = this;
            me.$refs.mdlResetPassword.show();
        },
        onClickLoginSpid() {
            // console.log(this.appConfig);
            let spidLink = this.appConfig.spidParams.urlRequest + "/oidc/auth?client_id=" + this.appConfig.spidParams.clientId + "&response_type=" + this.appConfig.spidParams.responseType + "&redirect_uri=" + this.appConfig.spidParams.urlCallBack;
            // console.log(spidLink);
            window.location.replace(spidLink);
        },
        verificaIdentityManager(id) {
            let me = this;
            let uri = process.env.VUE_APP_PATH_API + me.pathResourceIdentityManagerVerify + "?id=" + id;
            axios
                .get(uri)
                .then((response) => {
                    me.identityManagerParams = response.data.data;
                })
                .catch(() => {
                    console.log("Verificare la corretta installazione e configurazione dell'Identity Manager");
                });
        },
    },
};
</script>

<style lang="css">
a {
    /*color: #ac1f3a !important;*/
    text-decoration: none !important;
    background-color: transparent !important;
    font-weight: 600;
}

.login-page {
    /*background-image: url("../../assets/images/sfondo.jpg");*/
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.login-page,
.register-page {
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100vh;
    -ms-flex-pack: center;
    justify-content: center;
}

.login-box,
.register-box {
    width: 468px;
}

.sa-logo-esteso {
    width: 100%;
}
.card {
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
    margin-bottom: 1rem;
}

/* .card { //SPOSTATA NEL FILE sa-login.scass con il nome '.sa-card-login'
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
} */

.sa-lnk {
    color: #8fb8c7;
}

.btn-login.btn:focus,
.btn-login.btn.focus {
    outline: 0;
    box-shadow: none;
}

.sa-box-login {
    box-shadow: 0 8px 10px 1px rgba(153, 153, 153, 0.14), 0 3px 14px 2px rgba(221, 220, 220, 0.12), 0 5px 5px -3px rgba(247, 247, 247, 0.3);
    /*box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.3);*/
    border-radius: 3rem;
    border: 1px solid #00000026;
}

@media only screen and (min-width: 0) {
    .login-box,
    .register-box {
        width: 268px;
    }
}

@media only screen and (min-width: 768px) {
    .login-box,
    .register-box {
        width: 368px;
    }
}
#tabs-registrazione {
    margin-top: -12px;
}
.sa-reset-password {
    text-align: center;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 5px;
    line-height: 30px;
    border-radius: 3px;
}
.sa-reset-password:hover {
    color: var(--cl-button-reset) !important;
    /* background-color: var(--bg-sidebar) !important; */
}
.sa-reset-password span {
    margin-left: 8px;
    cursor: pointer;
}
</style>
