<template>
    <sa-page-layout :btnRefreshVisible="true" @refresh="onRefresh" :toolbarVisible="true">
        <template slot="table-body">
            <div style="height: 100%; overflow: hidden">
                <sa-tabs-scroll-componentVue idTab="tabsExternalSync" classNavItem="nav-item"></sa-tabs-scroll-componentVue>
                <b-tabs id="tabsExternalSync" ref="tabsExternalSync" nav-wrapper-class="sa-tab-items" content-class="mt-3" class="sa-tab" style="height: 100%; padding-bottom: 25px" fill>
                    <b-tab id="tbExternalSyncLog" title="Log">
                        <div class="sa-tab-scroll">
                            <external-sync-log-list-component ref="cmpExternalSyncLogListComponent"></external-sync-log-list-component>
                        </div>
                    </b-tab>
                    <b-tab id="tbExternalSyncModuli" title="Moduli">
                        <div class="sa-tab-scroll">
                            <external-sync-moduli-list-component ref="cmpExternalSyncModuliListComponent"></external-sync-moduli-list-component>
                        </div>
                    </b-tab>
                </b-tabs>
            </div>
        </template>
    </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import SaTabsScrollComponentVue from "../../../../template/components/utility/SaTabsScrollComponent.vue";
import ExternalSyncLogListComponent from "../components/ExternalSyncLogListComponent.vue";
import ExternalSyncModuliListComponent from "../components/ExternalSyncModuliListComponent.vue";
export default {
    components: { SaPageLayout, SaTabsScrollComponentVue, ExternalSyncLogListComponent, ExternalSyncModuliListComponent },
    data() {
        return {
            pathResource: null,
        };
    },
    methods: {
        onRefresh() {
            let me = this;
            me.$refs.cmpExternalSyncLogListComponent.loadData();
            me.$refs.cmpExternalSyncModuliListComponent.loadData();
        },
    },
};
</script>

<style></style>
