<template>
    <b-modal scrollable b-modal title="Nuovo componente UVM" ref="mdlNuovoComponenteUvm" id="mdlNuovoComponenteUvm" size="xl" @show="showNuoviDatiFatturazione" centered no-close-on-backdrop no-close-on-esc hide-header-close>
        <div class="sa-tab-scroll">
            <!--  <b-card class="sa-card" header="Dati Ospite e Residenza Storica" header-tag="header" footer-tag="footer" title=""> -->
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label>Componente UVM</label>
                    <!-- 
            <b-form-input autocomplete="off" id="inline-form-custom-select-pref" v-model="ospite" class="mb-2 mr-sm-2 mb-sm-0" readonly></b-form-input> -->
                    <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.componenteUvm" class="mb-2 mr-sm-2 mb-sm-0" :options="componentiUvmOptions" :value="null" value-field="value" text-field="text" @change="onChangeComponenteUvm" required></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label>Componente UVM Altro</label>
                    <b-form-input autocomplete="off" id="inline-form-custom-select-pref" v-model="jsonData.componenteUvmAltro" class="mb-2 mr-sm-2 mb-sm-0" :readonly="isAltroDisable"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label>Nome</label>
                    <b-form-input autocomplete="off" id="inline-form-custom-select-pref" v-model="jsonData.nome" class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label>Cognome</label>
                    <b-form-input autocomplete="off" id="inline-form-custom-select-pref" v-model="jsonData.cognome" class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label>Firma</label>
                    <b-form-input autocomplete="off" id="inline-form-custom-select-pref" v-model="jsonData.firma" class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label>Ente/Struttura appartenenza</label>
                    <b-form-input id="inline-form-custom-select-pref" autocomplete="off" v-model="jsonData.enteStrutturaAppartenenza" class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>
                </b-col>
            </b-row>
            <!--  </b-card> -->
        </div>
        <template #modal-footer>
            <b-button @click="onAddComponenteUvm" variant="outline-success">Aggiungi</b-button>
            <b-button size="md" class="no-text" variant="outline-danger" @click="onClickUndo">Annulla</b-button>
        </template>
    </b-modal>
</template>
<script>
//import axios from "axios";
import UtilityMixin from "../../../utility/UtilityMixin";
export default {
    props: { jsonDataAccettazione: { type: Object }, idAccettazione: { type: String } },
    mixins: [UtilityMixin],
    components: {},
    data() {
        return {
            listKey: 0,
            pathResource: "/",
            pathResourceComponentiUvm: "/rsapaicomponentiuvm",
            componentiUvmOptions: [],
            isAltroDisable: true,
            jsonData: {
                componenteUvm: "",
                componenteUvmAltro: "",
                nome: "",
                cognome: "",
                firma: "",
                enteStrutturaAppartenenza: "",
            },
        };
    },
    mounted() {
        //   let me = this;
        // me.loadNazione();
        // me.loadProvince();
        // me.setData();
    },
    computed: {},
    watch: {},
    methods: {
        show() {
            let me = this;
            me.isAltroDisable = true;
            me.jsonData = {
                componenteUvm: "",
                componenteUvmAltro: "",
                nome: "",
                cognome: "",
                firma: "",
                enteStrutturaAppartenenza: "",
            };
            me.$refs.mdlNuovoComponenteUvm.show();
            me.loadComponentiUvm();
        },
        showNuoviDatiFatturazione() {
            let me = this;
            me.$refs.mdlNuovoComponenteUvm.show();
        },
        close() {
            let me = this;
            me.jsonData = {};
            me.$refs.mdlNuovoComponenteUvm.hide();
        },

        loadComponentiUvm() {
            let me = this;
            me.componentiUvmOptions = [];
            UtilityMixin.methods.loadDataResources(me.pathResourceComponentiUvm, {}, me.loadComponentiUvmSuccess, me.loadComponentiUvmError);
        },
        loadComponentiUvmSuccess(response) {
            let me = this;
            response.data.data.list.forEach((element) => {
                me.componentiUvmOptions.push({ text: element.componente, value: element.componente });
            });
            me.componentiUvmOptions.unshift({ text: "-Seleziona Valore-", value: "" });
        },
        loadComponentiUvmError(response) {
            console.log(response);
        },
        onClickUndo() {
            let me = this;
            me.$refs.mdlNuovoComponenteUvm.hide();
        },
        onChangeComponenteUvm(value) {
            let me = this;
            if (value === "ALTRE FIGURE") {
                me.isAltroDisable = false;
            }
        },
        onAddComponenteUvm() {
            let me = this;
            /*if (me.jsonData.componenteUvm !== "" && me.jsonData.nome !== "" && me.jsonData.cognome !== "") {
                me.$emit("addComponenteUvmScheda", me.jsonData);
                me.$refs.mdlNuovoComponenteUvm.hide();
            } else {
                this.$bvModal
                    .msgBoxOk("Campi OBBLIGATORI!", {
                        title: "ATTENZIONE",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    })
                    .then((value) => {
                        console.log(value);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } */
            let campiMancanti = [];

            if (me.jsonData.componenteUvm === "") campiMancanti.push("Componente UVM");
            if (me.jsonData.nome === "") campiMancanti.push("Nome");
            if (me.jsonData.cognome === "") campiMancanti.push("Cognome");

            if (campiMancanti.length === 0) {
                me.$emit("addComponenteUvmScheda", me.jsonData);
                me.$refs.mdlNuovoComponenteUvm.hide();
            } else {
                let messageError = "I seguenti campi sono obbligatori: " + campiMancanti.join(", ");
                this.$bvModal
                    .msgBoxOk(messageError, {
                        title: "ATTENZIONE",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    })
                    .then((value) => {
                        console.log(value);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
    },
};
</script>
