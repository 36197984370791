<template>
    <div>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Destinatari:</label>

                    <span>{{ jsonData.destinatari }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Inviata: </label>
                    <span>{{ dataOraInvioFormatter(jsonData.dataOraInvio) }}</span></b-col
                >
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Oggetto:</label>
                    <span>{{ jsonData.oggetto }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Messaggio:</label>
                    <span>{{ jsonData.messaggio }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Lista Allegati" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-table sticky-header ref="tblViaggi" stacked="xl" striped hover itemscope :items="jsonData.listaAllegati" :fields="fieldsAllegati" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="jsonData">
                            {{ jsonData.index + 1 }}
                        </template>
                    </b-table>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
<script>
import axios from "axios";
import UtilityMixin from "../../UtilityMixin";
export default {
    data() {
        return {
            id: "-1",
            pathResource: "/sendmail",
            jsonData: {
                destinatari: null,
                oggetto: null,
                messaggio: null,
                dataOraInvio: null,
            },
            fieldsAllegati: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Allegato",
                    key: "fileName",
                    sortable: false,
                },
            ],
        };
    },
    mounted: function () {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    /*  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
  }, */
    methods: {
        dataOraInvioFormatter(value) {
            return UtilityMixin.methods.formatDateTimeCustom(value, "DD-MM-YYYY HH:mm");
        },
        loadData() {
            let me = this;
            this.$emit("beforeLoadData");
            if (me.id !== "-1") {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                axios.get(link + me.id).then((response) => {
                    me.jsonData = response.data.data;
                    this.$emit("afterLoadData");
                });
            } else {
                this.$emit("afterLoadData");
            }
        },
    },
};
</script>

<style></style>
