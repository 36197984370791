<template>
    <div>
        <div class="sa-form-container">
            <b-card class="sa-card" header-bg-variant="light" header="Nuovo Numeratore" header-tag="header" footer-tag="footer" title="">
                <b-container fluid>
                    <b-row class="mb-4">
                        <b-col cols="12" md="4">
                            <div class="sa-form-group">
                                <label class="sa-form-label">Codice</label>
                                <b-form-input v-model="jsonData.codice" placeholder="Inserisci codice"> </b-form-input>
                            </div>
                        </b-col>
                        <b-col cols="12" md="4">
                            <div class="sa-form-group">
                                <label class="sa-form-label">Descrizione</label>
                                <b-form-input v-model="jsonData.descrizione" placeholder="Inserisci descrizione"> </b-form-input>
                            </div>
                        </b-col>
                        <b-col cols="12" md="4">
                            <div class="sa-form-group">
                                <label class="sa-form-label">Valore</label>
                                <b-form-input v-model="jsonData.valore" placeholder="Inserisci valore" type="number" min="0"> </b-form-input>
                            </div>
                        </b-col>
                    </b-row>

                    <b-row class="mb-4">
                        <b-col cols="12" md="6">
                            <div class="sa-form-group">
                                <label class="sa-form-label">Anno</label>
                                <b-form-input v-model="jsonData.anno" placeholder="Inserisci anno" type="number" required> </b-form-input>
                            </div>
                        </b-col>
                        <b-col cols="12" md="6">
                            <div class="sa-form-group">
                                <label class="sa-form-label">Codice Struttura</label>
                                <b-form-input v-model="jsonData.codiceStruttura" placeholder="Inserisci codice struttura"> </b-form-input>
                            </div>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="12">
                            <div class="sa-form-group">
                                <label class="sa-form-label">Note</label>
                                <b-form-textarea v-model="jsonData.note" placeholder="Inserisci le note qui..." rows="3" max-rows="7" class="sa-textarea"> </b-form-textarea>
                            </div>
                        </b-col>
                    </b-row>
                </b-container>
            </b-card>
        </div>

        <b-modal ref="mdlDuplicateNumeratore" id="mdlDuplicateNumeratore" title="Duplica numeratore" @ok="onOkDuplicate" @hidden="onCloseModal" centered no-close-on-backdrop no-close-on-esc hide-header-close>
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                    <span class="sa-form-label">Codice</span>
                    <b-form-input v-model="jsonDataNuovoContatore.codice" placeholder="Codice" readonly></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                    <span class="sa-form-label">Descrizione</span>
                    <b-form-input v-model="jsonDataNuovoContatore.descrizione" readonly></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                    <span class="sa-form-label">Valore</span>
                    <b-form-input v-model="jsonDataNuovoContatore.valore"></b-form-input>
                </b-col>
            </b-row>
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                    <span class="sa-form-label">Anno</span>
                    <b-form-input v-model="jsonDataNuovoContatore.anno" required></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                    <span class="sa-form-label">Cod.Struttura</span>
                    <b-form-input v-model="jsonDataNuovoContatore.codiceStruttura" readonly></b-form-input>
                </b-col>
            </b-row>
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <span class="sa-form-label">Note</span>
                    <b-form-textarea v-model="jsonDataNuovoContatore.note" rows="3" max-rows="7" placeholder="Inserisci le note qui..."></b-form-textarea>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
import UtilityMixin from "../../utility/UtilityMixin";
import axios from "axios";

export default {
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    components: {},
    mixins: [UtilityMixin],
    data() {
        return {
            pathResource: "/numeratori",
            showModalLoading: false,
            linkback: null,
            jsonData: { codice: "", descrizione: "", anno: null, codiceStruttura: "", valore: null, note: "" },
            jsonDataNuovoContatore: {},
        };
    },

    mounted() {},
    watch: {
        id: {
            handler(newVal) {
                if (newVal) {
                    this.loadData();
                }
            },
            immediate: true,
        },
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },

    methods: {
        loadData() {
            let me = this;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                me.showModalLoading = true;
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                axios.get(link + me.id).then((response) => {
                    me.jsonData = response.data.data;
                    me.showModalLoading = false;
                });
            }
        },

        show() {
            let me = this;
            me.jsonData = {};
            me.jsonData = { codice: "", descrizione: "", anno: null, codiceStruttura: "", valore: null, note: "" };
            me.$;
        },
        close() {
            let me = this;
            me.$refs.mdlNuovoNumeratore.hide();
        },

        nuovoNumeratore() {
            let me = this;
            me.$refs.mdlNuovoNumeratore.show();
        },

        setData(jsonData) {
            let me = this;
            me.jsonData.codice = jsonData.codice;
            me.jsonData.descrizione = jsonData.descrizione;
            me.jsonData.anno = jsonData.anno;
            me.jsonData.codiceStruttura = jsonData.codiceStruttura;
            me.jsonData.valore = jsonData.valore;
            me.jsonData.note = jsonData.note;
            console.log(me.jsonData);
        },
        onClickUndo() {
            let me = this;
            me.$refs.mdlNuovoNumeratore.hide();
        },
        onSalvaNumeratore() {
            let me = this;
            UtilityMixin.methods.saveResource(me.pathResource, me.jsonData, me.saveSuccessResponse, me.saveErrorResponse);
        },
        saveSuccessResponse(response) {
            let me = this;
            me.$emit("sucessRestCall", response);
            me.$emit("onAfterSave");
        },
        saveErrorResponse(response) {
            let me = this;
            if (response.response.status === 400) {
                this.$emit("errorRestCall", response);
            } else {
                me.$bvToast.toast("Errore durante il salvataggio" + response.message, {
                    title: "Errore",
                    variant: "danger",
                    solid: true,
                });
            }
        },
        showDuplicateModal(numeratore) {
            this.jsonDataNuovoContatore = {
                codice: numeratore.codice,
                descrizione: numeratore.descrizione,
                codiceStruttura: numeratore.codiceStruttura,
            };
            this.$refs.mdlDuplicateNumeratore.show();
        },

        onCloseModal() {
            this.jsonDataNuovoContatore = {};
        },

        onOkDuplicate() {
            const link = process.env.VUE_APP_PATH_API + this.pathResource;
            axios
                .post(link, this.jsonDataNuovoContatore)
                .then((response) => {
                    this.showModalLoading = false;
                    this.$emit(response);
                })
                .catch((e) => {
                    this.$bvModal.msgBoxOk(e.response.data.messaggio, {
                        title: "ATTENZIONE",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    });
                });
        },
    },
};
</script>

<style scoped>
.sa-form-container {
    padding: 1rem;
    background-color: #f8f9fa;
    min-height: 100%;
}
</style>
<!-- 

.sa-form-group {
    margin-bottom: 1rem;
}

.sa-form-label {
    font-weight: 600;
    color: #495057;
    margin-bottom: 0.5rem;
    display: block;
}

.sa-input,
.sa-textarea {
    border: 1px solid #ced4da;
    border-radius: 4px;
    padding: 0.5rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.sa-input:focus,
.sa-textarea:focus {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.sa-input.is-invalid,
.sa-textarea.is-invalid {
    border-color: #dc3545;
}

.text-danger {
    color: #dc3545;
}

@media (max-width: 768px) {
    .sa-form-container {
        padding: 0.5rem;
    }

    .mb-4 {
        margin-bottom: 1rem !important;
    }
}   -->
