<template>
    <div style="height: 100%">
        <div class="sa-tab-scroll">
            <b-card class="sa-card" header="Dati di Fatturazione" header-tag="header" footer-tag="footer" title="">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Nome</label>
                        <span>{{ jsonData.nome }}</span>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Cognome</label>
                        <span>{{ jsonData.cognome }}</span>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Identificativo</label>
                        <span>{{ jsonData.identificativo }}</span>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Codice Destinatario</label>
                        <span>{{ jsonData.codiceDestinatario }}</span>
                    </b-col>
                </b-row>
            </b-card>
            <b-card class="sa-card" header="Accettazione" header-tag="header" footer-tag="footer" title="">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <label class="sa-label-data">Data e ora Accettazione</label>
                        <span>{{ formatDate(jsonData.dataOraAccettazione) }}</span>
                    </b-col>
                </b-row>
            </b-card>
            <b-card class="sa-card" header="Indirizzo di Fatturazione" header-tag="header" footer-tag="footer" title="">
                <b-row>
                    <!-- <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data">Provincia</label>
            <span>{{ provincia }}</span>
          </b-col> -->
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <!-- <label class="sa-label-data" v-if="provincia === 'EE'">Stato Estero</label> -->
                        <label class="sa-label-data">Comune</label>
                        <span>{{ comuneFatturazione }}</span>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Cap</label>
                        <span>{{ jsonData.cap }}</span>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Indirizzo</label>
                        <span>{{ jsonData.indirizzo }}</span>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Indirizzo P.E.C.</label>
                        <span>{{ jsonData.pec }}</span>
                    </b-col>
                </b-row>
            </b-card>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import UtilityMixin from "../../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixin],
    components: {},
    data() {
        return {
            listKey: 0,
            pathResource: "/rsadatifatturazione",

            pathResourceComuniByCodiceIstat: "/comuni/istat",
            nazione: "",
            provincia: "",
            idAccettazione: "",
            comuneFatturazione: "",
            jsonData: {
                /*  idAccettazione: "",
        nome: "",
        cognome: "",
        identificativo: "",
        codiceDestinatario: "",
        dataOraAccettazione: null,
        comuneCodiceIstat: "",
        indirizzo: "",
        cap: "",
        pec: "", */
            },
            filtro: {},
            nazioneOptions: [],
            provinciaOptions: [],
            comuniOptions: [{ codiceIstat: "", comune: "-Seleziona Comune-" }],
        };
    },
    mounted() {
        let me = this;
        me.idAccettazione = this.$route.params.id;
        me.loadData();
        console.log(me.idAccettazione);
    },
    watch: {},
    methods: {
        loadData() {
            let me = this;
            me.filtro.idAccettazione = me.idAccettazione;
            this.$emit("beforeLoadData");
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.jsonData = response.data.data.list[0];
                    me.loadComune();
                    this.$emit("afterLoadData");
                })
                .catch((error) => {
                    console.log(error);
                });
        } /* 
    loadNazione() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceNazioni;
      axios
        .get(link)
        .then((response) => {
          me.nazioneOptions = response.data.data;
          me.nazioneOptions.unshift({ codiceIstat: "", comune: "-Seleziona Nazione-" });
        })
        .catch((error) => {
          console.log("Il servizio delle province ha datao errore: " + error.response);
        });
    },
    loadProvince() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceProvince;
      axios
        .get(link)
        .then((response) => {
          me.provinciaOptions = response.data.data;
          me.provinciaOptions.unshift({ sigla: "", provincia: "-Seleziona Provincia-" });
        })
        .catch((error) => {
          console.log("Il servizio delle province ha datao errore: " + error.response);
        });
    },
    onChangeProvincia(value) {
      let me = this;
      me.comuniOptions = [];
      //me.comuniOptions.unshift({ codiceIstat: "", comune: "-Seleziona Comune-" });
      me.loadComuni(value);
    },
    loadComuni(sigla) {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceComuni + "/";
      axios
        .get(link + sigla)
        .then((response) => {
          me.comuniOptions = response.data.data;
          if (me.provincia === "EE") {
            me.comuniOptions.unshift({ codiceIstat: "", comune: "-Seleziona Stato Estero-" });
          } else {
            me.comuniOptions.unshift({ codiceIstat: "", comune: "-Seleziona Comune-" });
          }
        })
        .catch((error) => {
          console.log("Il servizio dei comuni ha datao errore: " + error.response);
        });
    },
    onChangeComuneNascita(value) {
      let me = this;
      me.loadCap(value);
    },
    loadCap(codiceIstat) {
      let me = this;

      let link = process.env.VUE_APP_PATH_API + me.pathResourceComune + "/" + codiceIstat;
      if (codiceIstat) {
        axios
          .get(link)
          .then((response) => {
            me.jsonData.cap = response.data.data.cap;
          })
          .catch((error) => {
            console.log("Il servizio delle regioni ha datao errore: " + error.response.status);
          });
      }
    }, */,
        loadComune() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceComuniByCodiceIstat + "/" + me.jsonData.comuneCodiceIstat;
            axios.get(link).then((response) => {
                me.comuneFatturazione = response.data.data.comune;
            });
        },
    },
};
</script>
