<template>
    <b-modal scrollable b-modal title="Equipe" ref="mdlEquipe" id="mdlEquipe" size="xl" @show="showNuovaEquipe" @ok="onSalvaEquipe" centered no-close-on-backdrop no-close-on-esc hide-header-close>
        <div class="sa-tab-scroll">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="sa-switch-select-element">
                    <b-card class="sa-card sa-card-border" header="Membri equipe" header-tag="header" footer-tag="footer" title="">
                        <b-table sticky-header ref="table" stacked="lg" striped hover :items="jsonData.listaEquipeDettaglio" :fields="fieldsEquipe" :current-page="1" :per-page="50" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
                            <template #cell(profilo)="item">
                                <b-form-select v-if="item.index === jsonData.listaEquipeDettaglio.length - 1" v-model="item.item.tipo" :options="equipeTipoOptions" :value="null" value-field="value" text-field="text" @change="onChangeTipo($event)"></b-form-select>
                                <span v-else> {{ item.item.tipo }} </span>
                            </template>
                            <template #cell(nominativo)="item">
                                <b-form-select v-if="item.index === jsonData.listaEquipeDettaglio.length - 1" :options="equipeOptions" v-model="nominativo" :value="''" value-field="value" text-field="text" @change="onChangeNominativo($event)"></b-form-select>
                                <span v-else> {{ item.item.nome + " " + item.item.cognome }} </span>
                            </template>
                            <template #cell(actions)="row">
                                <b-button v-if="row.index === jsonData.listaEquipeDettaglio.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddEquipe(jsonData.listaEquipeDettaglio, row.item)">
                                    <b-icon icon="plus"></b-icon>
                                </b-button>
                                <b-button v-else size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDeleteEquipe(jsonData.listaEquipeDettaglio, row)">
                                    <b-icon icon="trash"></b-icon>
                                </b-button>
                            </template>
                        </b-table>
                    </b-card>
                </b-col>
            </b-row>
        </div>
    </b-modal>
</template>
<script>
import UtilityMixin from "../../../utility/UtilityMixin";
export default {
    props: {},
    mixins: [UtilityMixin],
    components: {},
    data() {
        return {
            listKey: 0,
            pathResource: "/rsaequipe",
            pathResourceUsers: "/users",
            nominativo: "",
            id: null,
            jsonData: { idAccettazione: "", dataInizio: new Date().getTime(), dataFine: null, listaEquipeDettaglio: [{ nome: "", tipo: "", cognome: "" }] },
            filtro: {},
            ossOptions: [],
            animatoreOptions: [],
            psicologoOptions: [],
            infermiereOptions: [],
            medicoOptions: [],
            cordinatoreOptions: [],
            fisioterapistaOptions: [],
            terapistaOccupazionaleOptions: [],
            assistenteSocialeOptions: [],
            amministrativoOptions: [],
            equipeOptions: [{ text: "-Seleziona membro equipe-", value: "" }],
            equipeTipoOptions: [
                { text: "-Seleziona Profilo-", value: "" },
                { text: "MEDICO", value: "Medico" },
                { text: "COORDINATORE", value: "COORDINATORE" },
                { text: "INFERMIERE", value: "INFERMIERE" },
                { text: "PSICOLOGO", value: "PSICOLOGO" },
                { text: "FISIOTERAPISTA", value: "FISIOTERAPISTA" },
                { text: "TERAPISTA OCCUPAZIONALE", value: "TERAPISTA OCCUPAZIONALE" },
                { text: "ASSISTENTE SOCIALE", value: "ASSISTENTE SOCIALE" },
                { text: "ANIMATORE", value: "ANIMATORE" },
                { text: "OPERATORE SOCIO SANITARIO", value: "OPERATORE SOCIO SANITARIO" },
                { text: "AMMINISTRATIVO", value: "AMMINISTRATIVO" },
            ],
            fieldsEquipe: [
                { label: "Profilo", key: "profilo", thStyle: "width: 7rem" },
                { label: "Nominativo", key: "nominativo", thStyle: "width: 7rem" },
                { class: "sa-table-column-action-double", label: "", key: "actions" },
                /*   { label: "Nome", key: "nome" },
                { label: "Cognome", key: "cognome" }, */
            ],
        };
    },
    mounted() {
        let me = this;
        me.idAccettazione = this.$route.query.idPaziente;
    },
    //   computed: {},
    watch: {},
    methods: {
        show() {
            let me = this;
            me.jsonData = {};
            me.$refs.mdlEquipe.show();
        },
        showNuovaEquipe() {
            let me = this;
            // me.jsonData = { idAccettazione: me.idAccettazione, dataInizio: new Date().getTime(), dataFine: null, listaEquipeDettaglio: [{ nome: "", tipo: "", cognome: "" }] };
            // me.$refs.mdlEquipe.show();

            me.loadData();
        },
        loadData() {
            let me = this;
            UtilityMixin.methods.loadDataResources(
                me.pathResource + "/accettazione/" + me.idAccettazione,
                {
                    /*  idAccettazione: me.idAccettazione  */
                },
                me.resultResponseEquipe,
                me.resultErrorEquipe
            );
        },
        resultResponseEquipe(response) {
            let me = this;
            //if (response.data.data.recordsNumber > 0) {
            me.jsonData = response.data.data;
            me.jsonData.id = null;
            me.jsonData.dataFine = null;
            me.jsonData.dataInizio = new Date().getTime();
            me.jsonData.listaEquipeDettaglio.push({
                tipo: "",
                nome: "",
                cognome: "",
            });

            // }
        },
        resultErrorEquipe() {
            let me = this;
            me.jsonData = { idAccettazione: me.idAccettazione, dataInizio: new Date().getTime(), dataFine: null, listaEquipeDettaglio: [{ nome: "", tipo: "", cognome: "" }] };
        },
        close() {
            let me = this;
            me.jsonData = {};
            me.$refs.mdlEquipe.hide();
        },
        /*   loadData(id) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + id;
            axios
                .get(link)
                .then((response) => {
                    me.setData(id);
                    me.jsonData = response.data.data;
                    me.setQuotaGiornaliera(me.jsonData);
                })
                .catch((error) => {
                    this.$bvToast.toast("Dati della Fatturazione non presenti", {
                        title: "ATTENZIONE!",
                        variant: "danger",
                        solid: true,
                    });
                    console.log("Il servizio dell' equipe ha datao errore: " + error.response);
                });
        }, */
        onChangeTipo(value) {
            let me = this;
            me.equipeOptions = [];
            me.loadUsers(value);
        },
        loadUsers(profilo) {
            let me = this;
            // me.listaEquipeDettaglio = [];
            me.filtro = {};
            // if (profilo) {
            me.filtro.profilo = profilo;
            // }
            UtilityMixin.methods.loadDataResources(me.pathResourceUsers, me.filtro, me.loadUsersSuccess, me.loadUserError);
        },
        loadUsersSuccess(response) {
            let me = this;
            response.data.data.list.forEach((element) => {
                me.equipeOptions.push({ text: element.firstname + " " + element.lastname, value: element.firstname + " " + element.lastname });
            });
            me.equipeOptions.unshift({ text: "-Seleziona membro equipe-", value: "" });
        },
        loadUserError(response) {
            console.log(response);
        },

        onClickUndo() {
            let me = this;
            me.$refs.mdlEquipe.hide();
        },
        onSalvaEquipe(bvModalEvent) {
            let me = this;
            UtilityMixin.methods.saveResource(me.pathResource, me.jsonData, me.resultResponse, me.resultError);
            bvModalEvent.preventDefault();
        },
        resultResponse() {
            let me = this;
            me.$refs.mdlEquipe.hide();
            me.$emit("afterSave");
        },
        resultError(error) {
            UtilityMixin.methods.messaggeBoxError(this, error);
        },
        onChangeNominativo(value) {
            let me = this;
            let nomeCompleto = value.split(" ");
            me.jsonData.listaEquipeDettaglio.at(-1).nome = nomeCompleto[0];
            me.jsonData.listaEquipeDettaglio.at(-1).cognome = nomeCompleto[1];
        },
        onAddEquipe(array, value) {
            let me = this;
            if (value.tipo !== "") {
                array.push({
                    tipo: "",
                    nome: "",
                    cognome: "",
                });
                me.nominativo = "";
                me.equipeOptions = [];
                me.equipeOptions.unshift({ text: "-Seleziona membro equipe-", value: "" });
            } else {
                this.$bvModal
                    .msgBoxOk("Campi OBBLIGATORI!", {
                        title: "ATTENZIONE",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    })
                    .then((value) => {
                        console.log(value);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        onDeleteEquipe(array, row) {
            if (row.index != array.length) {
                this.$bvModal
                    .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
                        title: "Titolo",
                        buttonSize: "sm",
                        okVariant: "secondary",
                        cancelVariant: "danger",
                        okTitle: "SI",
                        cancelTitle: "NO",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true,
                    })
                    .then((value) => {
                        if (value) {
                            array.splice(row.index, 1);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
    },
};
</script>
