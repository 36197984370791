<template>
    <div>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Luogo Probabile Contagio: <b-icon icon="info-circle" v-b-popover.hover.top="messaggioLuogoContagio"></b-icon></label>
                    <span class="sa-data">{{ jsonData.luogoProbabileEsposizione }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Indirizzo:</label>
                    <span class="sa-data">{{ jsonData.italiaIndirizzo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Comune:</label>
                    <span class="sa-data">{{ jsonData.comune }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Tipo Domicilio:</label>
                    <span class="sa-data">{{ jsonData.tipoDomicilio }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Via Trasmissione:</label>
                    <span class="sa-data">{{ jsonData.viaTrasmissiome }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Specificare Altra Via Trasmissione:</label>
                    <span class="sa-data">{{ jsonData.altro }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Informazioni Generali" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Donatore:</label>
                    <span class="sa-data">{{ jsonData.donatore }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Donatore Sangue:</label>
                    <span class="sa-data">{{ jsonData.tipoDonatore }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Donatore Cellule:</label>
                    <span class="sa-data">{{ jsonData.tipoDonatoreCellule }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Donatore Tessuti:</label>
                    <span class="sa-data">{{ jsonData.tipoDonatoreTessuti }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Donatore Organi:</label>
                    <span class="sa-data">{{ jsonData.tipoDonatoreOrgani }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Donazione Ultimi 28 Giorni:</label>
                    <span class="sa-data">{{ jsonData.donazioneVentottoGiorni }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Anamnesi Trasfusione nei 28 Giorni Precedenti la Comparsa dei Sintomi:</label>
                    <span class="sa-data">{{ jsonData.anamnesiTrasfusioneVentottoGiorni }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Anamnesi Trapianto nei 28 Giorni Precedenti la Comparsa dei Sintomi:</label>
                    <span class="sa-data">{{ jsonData.anamnesiTrapiantoVentottoGiorni }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Gravidanza:</label>
                    <span class="sa-data">{{ jsonData.gravidanza }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Numero settimane:</label>
                    <span class="sa-data">{{ jsonData.gravidanzaSettimane }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Stato Vaccinale" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Tick Borne Encephalitis:</label>
                    <span class="sa-data">{{ jsonData.vaccinoTickBorneEncephalitis }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Febbre Gialla:</label>
                    <span class="sa-data">{{ jsonData.vaccinoFebbreGialla }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Encefalite Giapponese:</label>
                    <span class="sa-data">{{ jsonData.vaccinoEncefaliteGiapponese }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Informazioni Cliniche" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Presenza Sintomi:</label>
                    <span class="sa-data">{{ jsonData.presenzaSintomi }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Inizio Sintomi:</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataInizioSintomi) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Febbre:</label>
                    <span class="sa-data">{{ jsonData.febbre }}</span>
                </b-col>
                <!--  <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Rush:</label>
          <span class="sa-data">{{ jsonData.rush }}</span>
        </b-col> -->
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Encefalite:</label>
                    <span class="sa-data">{{ jsonData.encefalite }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Meningite:</label>
                    <span class="sa-data">{{ jsonData.meningite }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Poliradiculoneurite:</label>
                    <span class="sa-data">{{ jsonData.poliradiculoneurite }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Paralisi Flaccida Acuta:</label>
                    <span class="sa-data">{{ jsonData.paralisiFlaccidaAcuta }}</span>
                </b-col>
                <!-- <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Febbre Emorragica:</label>
          <span class="sa-data">{{ jsonData.febbreEmorragica }}</span>
        </b-col> -->
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Astenia/Cefalea:</label>
                    <span class="sa-data">{{ jsonData.asteniaCefalea }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Artralgia:</label>
                    <span class="sa-data">{{ jsonData.artralgia }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Poliartralgia Grave:</label>
                    <span class="sa-data">{{ jsonData.poliatralgiaGrave }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Mialgia:</label>
                    <span class="sa-data">{{ jsonData.mialgia }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Esantema:</label>
                    <span class="sa-data">{{ jsonData.esantema }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Nausea Vomito:</label>
                    <span class="sa-data">{{ jsonData.nauseaVomito }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Dolore Oculare o Retro-Orbitale:</label>
                    <span class="sa-data">{{ jsonData.doloreOculareRetroOrbitale }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Congiuntivite non Purulenta/Iperemia:</label>
                    <span class="sa-data">{{ jsonData.congiuntiviteNonPurulentaIpermia }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Meningo-Encefalite:</label>
                    <span class="sa-data">{{ jsonData.meningoEncefalite }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Encefalomielite:</label>
                    <span class="sa-data">{{ jsonData.encefalite }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Altri Sintomi:</label>
                    <span class="sa-data">{{ jsonData.altriSintomi }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Condizioni Rischio Persistente:</label>
                    <span class="sa-data">{{ jsonData.condizioniRischioPersistenti }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Specificare:</label>
                    <span class="sa-data">{{ jsonData.condizioniRischioPersistentiDettagli }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Ricovero Ospedaliero:</label>
                    <span class="sa-data">{{ jsonData.ricoveroOspedale }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Ospedale:</label>
                    <span class="sa-data">{{ jsonData.ricoveroOspedaleDenominazione }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Data Ricovero:</label>
                    <span class="sa-data">{{ formatDate(jsonData.ricoveroOspedaleDataRicovero) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Dati Laboratorio" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Liquor:</label>
                    <span class="sa-data">{{ jsonData.liquor }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Prelievo Campione Liquor:</label>
                    <span class="sa-data">{{ formatDate(jsonData.liquorDataPrelievoCampione) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">IgM Liquor:</label>
                    <span class="sa-data">{{ jsonData.liquorIgm }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">IgG Liquor:</label>
                    <span class="sa-data">{{ jsonData.liquorIgg }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">PCR Liquor:</label>
                    <span class="sa-data">{{ jsonData.liquorPcr }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Isolamento Liquor:</label>
                    <span class="sa-data">{{ jsonData.liquorIsolamentoVirale }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Siero/Sangue:</label>
                    <span class="sa-data">{{ jsonData.sieroSangue }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Prelievo Campione Siero/Sangue:</label>
                    <span class="sa-data">{{ formatDate(jsonData.sieroSangueDataPrelievoCampione) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">IgM Siero/Sangue:</label>
                    <span class="sa-data">{{ jsonData.sieroSangueIgm }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">IgG Siero/Sangue:</label>
                    <span class="sa-data">{{ jsonData.sieroSangueIgg }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">PCR Siero/Sangue:</label>
                    <span class="sa-data">{{ jsonData.sieroSanguePcr }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Neutralizzazione Siero/Sangue:</label>
                    <span class="sa-data">{{ jsonData.sieroSangueNeutralizzazione }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Isolamento Siero/Sangue:</label>
                    <span class="sa-data">{{ jsonData.sieroSangueIsolamentoVirale }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Test NAT Siero/Sangue:</label>
                    <span class="sa-data">{{ jsonData.sieroSangueTestNat }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Urine:</label>
                    <span class="sa-data">{{ jsonData.urine }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Data Prelievo Campione Urine:</label>
                    <span class="sa-data">{{ formatDate(jsonData.urineDataPrelievo) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">PCR Urine:</label>
                    <span class="sa-data">{{ jsonData.urinePcr }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Invio Campione Presso ISS:</label>
                    <span class="sa-data">{{ jsonData.invioCampionePressoIss }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Data Invio:</label>
                    <span class="sa-data">{{ formatDate(jsonData.invioCampionePressoIssData) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Effettuato Sequenziamento:</label>
                    <span class="sa-data">{{ jsonData.effettuatoSequenziamento }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Specificare Altro:</label>
                    <span class="sa-data">{{ jsonData.effettuatoSequenziamentoAltro }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Esito e Follow-Up:</label>
                    <span class="sa-data">{{ jsonData.esitoFolowUp }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Data Esito:</label>
                    <span class="sa-data">{{ formatDate(jsonData.esitoFolowUpData) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Classificazione Caso Neuroinvasivo:</label>
                    <span class="sa-data">{{ jsonData.classificazioneCasoNeuroinvasivo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Classificazione Caso Solo Febbre:</label>
                    <span class="sa-data">{{ jsonData.classificazioneCasoSoloFebbre }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Classificazione Caso Sintomatico Altro:</label>
                    <span class="sa-data">{{ jsonData.classificazioneCasoSintomaticoAltro }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Classificazione Caso Donatore:</label>
                    <span class="sa-data">{{ jsonData.classificazioneCasoDonatore }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Classificazione Caso Asintomatico:</label>
                    <span class="sa-data">{{ jsonData.classificazioneCasoAsintomatico }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Tipo Caso:</label>
                    <span class="sa-data">{{ jsonData.tipoCaso }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Note:</label>
                    <span class="sa-data">{{ jsonData.note }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Dati Notifica" header-tag="header" footer-tag="footer" title="">
            <indagine-epidemiologica-dati-notifica-component :jsonData="jsonData" :isEdit="false"></indagine-epidemiologica-dati-notifica-component>
        </b-card>
    </div>
</template>
<script>
import axios from "axios";
import UtilityMixin from "../../../utility/UtilityMixin";
import IndagineEpidemiologicaDatiNotificaComponent from "./IndagineEpidemiologicaDatiNotificaComponent.vue";
export default {
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    components: { IndagineEpidemiologicaDatiNotificaComponent },
    mixins: [UtilityMixin],
    data() {
        return {
            pathResource: "/malattieinfettiveiealtricasiparticolari",
            linkback: null,
            currentPage: 1,
            perPage: 100,
            messaggioLuogoContagio: "Presenza nel luogo {periodo} precedenti l’inizio della sintomatologia",
            jsonData: {
                viveCollettivita: "",
                viveCollettivitaDenominazioneIndirizzo: "",
                viveCollettivitaContatto: "",
                luogoLavoroScuola: "",
                dataInizioTerapiaAntitubercolare: null,
                esposizioneCasoTbc: "",
                alcolismo: "",
                immunodeficienzaGrava: "",
                altreCondizionePredisponente: "",
                altreCondizionePredisponenteDettagli: "",
                diagnosiTubercolosiPassato: "",
                diagnosiTubercolosiPassatoMeseAnno: "",
                classificazioneRelazionePrecedentiTrattamenti: "",
                agenteEziologico: "",
                diagnosiBasataEsameColturaleEscreato: "",
                diagnosiBasataEsameColturaleAltroMateriale: "",
                diagnosiBasataEsameDirettoEscreato: "",
                diagnosiBasataEsameDirettoAltroMateriale: "",
                diagnosiBasataIdentificazioneAcidoNucleico: "",
                diagnosiBasataClinica: "",
                diagnosiBasataMantoux: "",
                diagnosiBasataRxToraceEsamiStrumentali: "",
                diagnosiBasataRispostaSedutaAntitubercolare: "",
                diagnosiBasataRiscontroAutopticoTbAttiva: "",
                sedeAnatomica: "",
                contatti: "",
                poliatralgiaGrave: "",
                mialgia: "",
                esantema: "",
                nauseaVomito: "",
                doloreOculareRetroOrbitale: "",
                congiuntiviteNonPurulentaIpermia: "",
                meningoEncefalite: "",
                encefalomielite: "",
                tipoDonatoreCellule: "",
                tipoDonatoreTessuti: "",
                tipoDonatoreOrgani: "",
                listaContatti: [],
            },
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient() {
            return UtilityMixin.methods.getLinkPatient(this.utente, this.idPaziente);
        },
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        let malattiaInfettivaSegnalazione = sessionStorage.getItem("MalattiaInfettivaSegnalazione");
        let malattiaInfettiva = {};
        // console.log(malattiaInfettivaSegnalazione);
        if (malattiaInfettivaSegnalazione !== null && malattiaInfettivaSegnalazione !== "null") {
            malattiaInfettiva = JSON.parse(malattiaInfettivaSegnalazione);
            me.setDatiMalattia(malattiaInfettiva);
        }
        me.loadData();
    },
    methods: {
        setDatiMalattia(data) {
            let me = this;
            let malattia = data.malattiaSegnalata;
            if (malattia.toUpperCase().includes("WEST NILE")) {
                me.messaggioLuogoContagio = me.messaggioLuogoContagio.replace("{periodo}", "nelle 3 settimane");
            } else {
                me.messaggioLuogoContagio = me.messaggioLuogoContagio.replace("{periodo}", "nei 15 giorni");
            }
        },
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.setDatiMedico();
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                me.showModalLoading = true;
                axios
                    .get(link + me.id)
                    .then((response) => {
                        me.jsonData = response.data.data;
                        me.showModalLoading = false;
                    })
                    .catch(() => {
                        me.showModalLoading = false;
                    });
            }
        },
    },
};
</script>
