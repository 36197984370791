<template>
    <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <b-card class="sa-card sa-card-border" header="Valutazione Preferenze" header-tag="header" footer-tag="footer" title="">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Abitudini</label>
                        <b-form-input v-model="jsonDataValutazionePreferenze.abitudine" :readonly="isReadOnly"> </b-form-input>
                    </b-col>

                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Cibi Graditi</label>
                        <b-form-input v-model="jsonDataValutazionePreferenze.ciboGradito" :readonly="isReadOnly"> </b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Cibi Sgraditi</label>
                        <b-form-input v-model="jsonDataValutazionePreferenze.ciboSgradito" :readonly="isReadOnly"> </b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Alterazioni apporto alimentare</label>
                        <b-form-input v-model="jsonDataValutazionePreferenze.alterazioneApportoAlimentare" :readonly="true"> </b-form-input>
                    </b-col>

                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <label class="sa-label-data">Note</label>
                        <b-form-textarea v-model="jsonDataValutazionePreferenze.note" :readonly="isReadOnly" max-rows="10" rows="5"> </b-form-textarea>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import UtilityMixin from "../../../utility/UtilityMixin";

export default {
    props: {
        isReadOnly: {
            type: Boolean,
            default: false,
        },
        idAccettazione: {
            type: String,
        },
    },

    mixins: [UtilityMixin],
    data() {
        return {
            pathResourceSchedaInfermieristicaValutazionePreferenze: "/rsaschedainfermieristicavalutazionepreferenze",
            pathResourceSchedaEsameObiettivoGenerale: "/rsaschedaesameobiettivogenerale",
            jsonDataValutazionePreferenze: {
                id: null,
                idAccettazione: null,
                abitudine: null,
                ciboGradito: null,
                ciboSgradito: null,
                alterazioneApportoAlimentare: null,
                note: null,
            },
        };
    },
    methods: {
        loadValutazionePreferenzeData() {
            let me = this;
            // console.log("ID accettazione nel loadValutazionePreferenzeData: " + me.idAccettazione);
            UtilityMixin.methods.loadDataResources(me.pathResourceSchedaInfermieristicaValutazionePreferenze + "/accettazione/" + me.idAccettazione, {}, me.resultResponseValutazionePreferenzeSuccess, me.resultResponseValutazionePreferenzeError);
            me.loadEsameObiettivoGenerale();
        },
        resultResponseValutazionePreferenzeSuccess(response) {
            let me = this;
            me.jsonDataValutazionePreferenze = response.data.data;
            // console.log("ID del record VALUTAZIONE PREFERENZE:", me.jsonDataValutazionePreferenze.id);
        },
        resultResponseValutazionePreferenzeError(error) {
            let me = this;
            if (error.response && error.response.status === 404) {
                //console.log("NESSUN record trovato del VALUTAZIONE PREFERENZE per questo id accettazione");
                me.resetForm();
            } else {
                console.error("Errore nel caricamento dati della sezione VALUTAZIONE PREFERENZE:", error);
            }
        },

        loadEsameObiettivoGenerale() {
            let me = this;
            UtilityMixin.methods.loadDataResources(me.pathResourceSchedaEsameObiettivoGenerale + "/accettazione/" + me.idAccettazione, {}, me.resultResponseEsameObiettivoGeneraleSuccess, me.resultResponseEsameObiettivoGeneraleError);
        },
        resultResponseEsameObiettivoGeneraleSuccess(response) {
            let me = this;
            me.jsonDataValutazionePreferenze.alterazioneApportoAlimentare = response.data.data.statoNutrizionale;
        },
        resultResponseEsameObiettivoGeneraleError(error) {
            let me = this;
            if (error.response && error.response.status === 404) {
                //console.log("NESSUN record trovato del VALUTAZIONE PREFERENZE per questo id accettazione");
                me.resetForm();
            } else {
                console.error("Errore nel caricamento dati della sezione VALUTAZIONE PREFERENZE:", error);
            }
        },

        resetForm() {
            let me = this;
            me.jsonDataValutazionePreferenze = {
                id: null,
                idAccettazione: me.idAccettazione,
                abitudine: null,
                ciboGradito: null,
                ciboSgradito: null,
                alterazioneApportoAlimentare: null,
                note: null,
            };
        },

        onSalvaValutazionePreferenze() {
            let me = this;
            me.jsonDataValutazionePreferenze.idAccettazione = me.idAccettazione;
            if (!me.jsonDataValutazionePreferenze.idAccettazione) {
                me.$bvToast.toast("Errore: ID Accettazione mancante", {
                    title: "Errore",
                    variant: "danger",
                    solid: true,
                });
                return;
            }

            UtilityMixin.methods.saveResource(me.pathResourceSchedaInfermieristicaValutazionePreferenze, me.jsonDataValutazionePreferenze, me.saveValutazionePreferenzeSuccessResponse, me.saveValutazionePreferenzeErrorResponse);
        },

        saveValutazionePreferenzeSuccessResponse(response) {
            console.log("VALUTAZIONE PREFERENZE salvato con successo:", response);
        },

        saveValutazionePreferenzeErrorResponse(error) {
            console.error("Errore nel salvataggio VALUTAZIONE PREFERENZE:", error);
        },
    },
};
</script>
