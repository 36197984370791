import Fatturazione from "../";

const routes = [
    { name: "FatturazioneList", path: "/fatturazione", component: Fatturazione.FatturazioneList, meta: { title: "Fatturazione" } },
    { name: "FatturazioneView", path: "/fatturazione/view/:id", component: Fatturazione.FatturazioneView, meta: { title: "Fatturazione" } },
    { name: "FatturazioneEdit", path: "/fatturazione/edit/:id", component: Fatturazione.FatturazioneEdit, meta: { title: "Fatturazione" } },
    { name: "FatturazioneNumeratoriList", path: "/fatturazionenumeratori", component: Fatturazione.FatturazioneNumeratoriList, meta: { title: "Fatturazione" } },
    { name: "FatturazioneNumeratoriView", path: "/fatturazionenumeratori/view/:id", component: Fatturazione.FatturazioneNumeratoriView, meta: { title: "Fatturazione" } },
    { name: "FatturazioneNumeratoriEdit", path: "/fatturazionenumeratori/edit/:id", component: Fatturazione.FatturazioneNumeratoriEdit, meta: { title: "Fatturazione" } },
    { name: "FatturazioneList", path: "/fatturazione", component: Fatturazione.FatturazioneList, meta: { title: "Fatturazione" } },
    { name: "FatturazioneView", path: "/fatturazione/view/:id", component: Fatturazione.FatturazioneView, meta: { title: "Fatturazione" } },
    { name: "FatturazioneEdit", path: "/fatturazione/edit/:id", component: Fatturazione.FatturazioneEdit, meta: { title: "Fatturazione" } },
    { name: "TipiDocumentoList", path: "/tipidocumento", component: Fatturazione.TipiDocumentoList, meta: { title: "Fatturazione" } },
    { name: "TipiDocumentoView", path: "/tipidocumento/view/:id", component: Fatturazione.TipiDocumentoView, meta: { title: "Fatturazione" } },
    { name: "TipiDocumentoEdit", path: "/tipidocumento/edit/:id", component: Fatturazione.TipiDocumentoEdit, meta: { title: "Fatturazione" } },
    { name: "CausaliDocumentoList", path: "/causalidocumento", component: Fatturazione.CausaliDocumentoList, meta: { title: "Fatturazione" } },
    { name: "CausaliDocumentoView", path: "/causalidocumento/view/:id", component: Fatturazione.CausaliDocumentoView, meta: { title: "Fatturazione" } },
    { name: "CausaliDocumentoEdit", path: "/causalidocumento/edit/:id", component: Fatturazione.CausaliDocumentoEdit, meta: { title: "Fatturazione" } },
    { name: "FatturazioneNumeratoriList", path: "/fatturazionenumeratori", component: Fatturazione.FatturazioneNumeratoriList, meta: { title: "Fatturazione" } },
    { name: "FatturazioneNumeratoriView", path: "/fatturazionenumeratori/view/:id", component: Fatturazione.FatturazioneNumeratoriView, meta: { title: "Fatturazione" } },
    { name: "FatturazioneNumeratoriEdit", path: "/fatturazionenumeratori/edit/:id", component: Fatturazione.FatturazioneNumeratoriEdit, meta: { title: "Fatturazione" } },
];

export default routes;
