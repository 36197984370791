<template>
	<div class="sa-page-dashboard-detail">
		<div class="sa-page-dashboard-detail-header">Header</div>
		<div class="sa-page-dashboard-detail-body">
			<div class="sa-page-dashboard-detail-body-left">
				<b-row>
					<b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
						<label class="sa-label-data">Misure</label>
						<array-elements-component :arrayValue="metricsValue" @arraychange="onArrayChangeMisure"></array-elements-component>
					</b-col>
					<b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
						<label class="sa-label-data">Dimensioni</label>
						<array-elements-component :arrayValue="dimensionValue" @arraychange="onArrayChangeDimensioni"></array-elements-component>
					</b-col>
					<b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
						<label class="sa-label-data">Asse X</label>
						<array-elements-component :arrayValue="asseXValue" @arraychange="onArrayChangeAsseX"></array-elements-component>
					</b-col>
					<b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
						<label class="sa-label-data">Asse Y</label>
						<b-form-tags></b-form-tags>
					</b-col>
					<b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
						<label class="sa-label-data">Filtro</label>
						<b-form-tags></b-form-tags>
					</b-col>
					<b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
						<label class="sa-label-data">Categorie</label>
						<b-form-tags></b-form-tags>
					</b-col>
					<b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
						<label class="sa-label-data">Categorie</label>
						<b-form-group label-for="tags-component-select">
							<b-form-tags v-model="value" size="lg" class="mb-2" add-on-change no-outer-focus>
								<template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
									<ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
										<li v-for="tag in tags" :key="tag" class="list-inline-item">
											<b-form-tag @remove="removeTag(tag)" :title="tag" :disabled="disabled" variant="info">{{ tag }}</b-form-tag>
										</li>
									</ul>
									<b-form-select v-bind="inputAttrs" v-on="inputHandlers" :disabled="disabled || availableOptions.length === 0" :options="availableOptions">
										<template #first>
											<option disabled value="">Choose a tag...</option>
										</template>
									</b-form-select>
								</template>
							</b-form-tags>
						</b-form-group>
					</b-col>
					<b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
						<b-button @click="onClickAggiornaGrafico()">Aggiorna Grafico</b-button>
					</b-col>
					<b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
						<b-button @click="onClickCreaTabellaPivot()">Crea Tabella Pivot</b-button>
					</b-col>
				</b-row>
			</div>
			<div class="sa-page-dashboard-detail-body-body">
				<div>
					<b-table-simple hover small caption-top responsive>
						<b-thead head-variant="light">
							<b-tr>
								<b-th colspan="1" v-for="(h, index) in pivotHeader" :key="index + h">{{ h }}</b-th>
							</b-tr>
							<b-tr>
								<b-th colspan="1"></b-th>
								<b-th style="text-align: center" colspan="12">2024</b-th>
								<b-th style="text-align: center" colspan="12">2025</b-th>
							</b-tr>
							<b-tr>
								<b-th>UOSD Diagnosi</b-th>
								<b-th>gen</b-th>
								<b-th>feb</b-th>
								<b-th>mar</b-th>
								<b-th>apr</b-th>
								<b-th>mag</b-th>
								<b-th>giu</b-th>
								<b-th>lug</b-th>
								<b-th>ago</b-th>
								<b-th>set</b-th>
								<b-th>ott</b-th>
								<b-th>nov</b-th>
								<b-th>dic</b-th>
								<b-th>gen</b-th>
								<b-th>feb</b-th>
								<b-th>mar</b-th>
								<b-th>apr</b-th>
								<b-th>mag</b-th>
								<b-th>giu</b-th>
								<b-th>lug</b-th>
								<b-th>ago</b-th>
								<b-th>set</b-th>
								<b-th>ott</b-th>
								<b-th>nov</b-th>
								<b-th>dic</b-th>
							</b-tr>
						</b-thead>
						<b-tbody>
							<b-tr>
								<b-th>UOSD 28-30</b-th>
								<b-td>56</b-td>
								<b-td>22</b-td>
								<b-td>43</b-td>
								<b-td variant="success">72</b-td>
								<b-td>23</b-td>
								<b-td>28</b-td>
								<b-td>21</b-td>
								<b-td>32</b-td>
								<b-td>32</b-td>
								<b-td>32</b-td>
								<b-td>32</b-td>
								<b-td>32</b-td>
							</b-tr>
						</b-tbody>
						<b-tfoot>
							<b-tr>
								<b-td colspan="25" variant="secondary" class="text-right"> Total Rows: <b>5</b> </b-td>
							</b-tr>
						</b-tfoot>
					</b-table-simple>
				</div>
				<br />
				<generic-chart-component ref="gccGrafico" :marginLeft="bodyElement.marginLeft" :legendVisible="bodyElement.legendVisible" :horizontalBar="bodyElement.horizontalDirection" xAxisType="value" :chartData="bodyElement.chartData" :key="chartKey"></generic-chart-component>
				<br />
			</div>
		</div>
		<div class="sa-page-dashboard-detail-footer">
			<div class="sa-page-dashboard-detail-footer-scrolling">
				<b-table sticky-header ref="table" stacked="lg" striped hover :items="itemsData" sort-icon-left head-variant="light" class="sa-b-table" foot-clone>
					<template #foot()="data">
						<i>{{ getTotale(data.label) }}</i>
					</template>
				</b-table>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import GenericChartComponent from "../../utility/components/GenericChartComponent.vue";
import ArrayElementsComponent from "../../utility/components/ArrayElementsComponent.vue";
export default {
	components: { GenericChartComponent, ArrayElementsComponent },
	data() {
		return {
			id: "ec0ab16a-2bce-43c0-a9f8-061d638b4968",
			pathResource: "/dataanalysisdashboard",
			pathResourceData: "/dataanalysisdatabaseinfo/eseguiquery",
			pie: null,
			chartKey: 0,
			itemsData: [],
			dimensionValue: ["UOSD Diagnosi"],
			metricsValue: ["Numero", "Altro", "Altro uno"],
			asseXValue: ["year", "month"],
			options: ["Apple", "Orange", "Banana", "Lime", "Peach", "Chocolate", "Strawberry"],
			value: [],
			labelOption: {
				show: false,
				position: "insideBottom",
				distance: 15,
				align: "left",
				verticalAlign: "middle",
				rotate: 90,
				formatter: "{c}  {name|{a}}",
				fontSize: 9,
				rich: {
					name: {},
				},
			},
			bodyElement: {
				marginLeft: "10px",
				legendVisible: true,
				horizontalDirection: false,
				chartData: {
					titolo: "Prova",
					unitaMisura: "",
					xAxis: [
						{
							type: "category",
							axisTick: { show: false },
							data: [],
						},
					],
					series: [],
				},
			},
			dashboard: {
				headerElements: [],
				bodyElements: [],
			},
			pivotTable: {
				asseX: [
					["2024", "2025"],
					["gen", "feb", "mar", "apr", "mag", "giu", "lug", "ago", "set", "ott", "nov", "dic"],
				],
				dimension: ["UOSD Diagnosi"],
			},
		};
	},
	computed: {
		availableOptions() {
			return this.options.filter((opt) => this.value.indexOf(opt) === -1);
		},
		pivotHeader() {
			let me = this;
			let returnValue = [2024, 2025, 2026];
			let header = [];
			me.itemsData.forEach((dataElement) => {
				dataElement;
				for (let x = 0; x < me.asseXValue.length; x++) {
					console.log(me.asseXValue[x]);
				}
				// me.asseXValue.forEach((elementUno) => {
				// 	console.log(dataElement[elementUno]);
				// });
			});
			console.log(header);
			return returnValue;
		},
	},
	mounted() {
		this.loadData();
	},
	methods: {
		getTotale(colName) {
			let me = this;
			let returnValue = 0;
			me.itemsData.forEach((element) => {
				returnValue += element[colName.toLowerCase()];
			});
			if (!returnValue || typeof returnValue === "string") {
				returnValue = "Totale";
			}
			return returnValue;
		},
		loadData() {
			let me = this;
			let link = process.env.VUE_APP_PATH_API + me.pathResourceData + "/";
			axios.get(link + me.id)
				.then((response) => {
					me.itemsData = response.data.data;
				})
				.catch((e) => {
					console.log(e);
				});
		},
		onClickAggiornaGrafico() {
			let me = this;
			console.log("Sisto Andolfi");
			console.log("UOSD Diagnosi");
			console.log(me.itemsData);
			me.aggiornaGrafico();
		},
		aggiornaGrafico() {
			let x = [];
			let returnValue = { xvalue: [], series: [] };
			let me = this;
			me.bodyElement.chartData.series = [];
			me.itemsData.forEach((element) => {
				if (!returnValue.series[element["UOSD Diagnosi"]]) {
					returnValue.series[element["UOSD Diagnosi"]] = { title: element["UOSD Diagnosi"], data: [] };
				}
				returnValue.series[element["UOSD Diagnosi"]].data[element["month"]] = element["Numero"];
				x.push(element["month"]);
			});
			x = [...new Set(x)];
			console.log(
				x.sort(function (a, b) {
					return a - b;
				})
			);
			console.log(returnValue);
			me.bodyElement.chartData.xAxis[0].data = [...new Set(x)];
			for (let label in returnValue.series) {
				let serie = {
					name: label,
					type: "bar",
					barGap: 0,
					label: me.labelOption,
					emphasis: {
						focus: "series",
					},
					data: [],
				};
				x.forEach((value) => {
					if (returnValue.series[label].data[value]) {
						serie.data.push(returnValue.series[label].data[value]);
					} else {
						serie.data.push(0);
					}
				});
				me.bodyElement.chartData.series.push(serie);
			}
			me.chartKey++;
		},
		onClickCreaTabellaPivot() {
			let me = this;
			console.log(me.itemsData);
			console.log(me.itemsData[0]["UOSD Diagnosi"]);
			console.log(me.itemsData[0]["year"]);
			console.log(me.itemsData[0]["month"]);
			console.log(me.itemsData[0]["Numero"]);
		},
		onArrayChangeMisure(value) {
			let me = this;
			me.metricsValue = value;
		},
		onArrayChangeAsseX(value) {
			let me = this;
			me.asseXValue = value;
		},
		onArrayChangeDimensioni(value) {
			let me = this;
			me.dimensionValue = value;
		},
	},
};
</script>

<style></style>
