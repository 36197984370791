<template>
    <div>
        <b-card class="sa-card" header="Quadro Clinico" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.quadroClinicoSepsi)" :checked="getCheckedValue(jsonData.quadroClinicoSepsi)" switch disabled @input="onInputCheckbox($event, 'quadroClinicoSepsi')">Sepsi</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.quadroClinicoMeningite)" :checked="getCheckedValue(jsonData.quadroClinicoMeningite)" switch disabled @input="onInputCheckbox($event, 'quadroClinicoMeningite')">Meningite</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.quadroClinicoPolmoniteBatterica)" :checked="getCheckedValue(jsonData.quadroClinicoPolmoniteBatterica)" switch disabled @input="onInputCheckbox($event, 'quadroClinicoPolmoniteBatterica')">Polmonite Batterica</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.quadroClinicoCellulite)" :checked="getCheckedValue(jsonData.quadroClinicoCellulite)" switch disabled @input="onInputCheckbox($event, 'quadroClinicoCellulite')">Cellulite</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.quadroClinicoEpiglottite)" :checked="getCheckedValue(jsonData.quadroClinicoEpiglottite)" switch disabled @input="onInputCheckbox($event, 'quadroClinicoEpiglottite')">Epiglottite</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.quadroClinicoPeritonite)" :checked="getCheckedValue(jsonData.quadroClinicoPeritonite)" switch disabled @input="onInputCheckbox($event, 'quadroClinicoPeritonite')">Peritonite</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.quadroClinicoPericardite)" :checked="getCheckedValue(jsonData.quadroClinicoPericardite)" switch disabled @input="onInputCheckbox($event, 'quadroClinicoPericardite')">Pericardite</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.quadroClinicoArtriteSetticaOsteomielite)" :checked="getCheckedValue(jsonData.quadroClinicoArtriteSetticaOsteomielite)" switch disabled @input="onInputCheckbox($event, 'quadroClinicoArtriteSetticaOsteomielite')">Artrite Settica/Osteomielite</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.quadroClinicoAltro)" :checked="getCheckedValue(jsonData.quadroClinicoAltro)" switch disabled @input="onInputCheckbox($event, 'quadroClinicoAltro')">Altro</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Descrizione:</label>
                    <span class="sa-data">{{ jsonData.quadroClinicoAltroDescrizione }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.ricoverato)" :checked="getCheckedValue(jsonData.ricoverato)" switch disabled @input="onInputCheckbox($event, 'ricoverato')">Ricoverato</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
                    <label class="sa-label-data">Data Ricovero:</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataRicovero) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Agente Eziologico" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.agenteEziologicoNeisseriaMeningitidis)" :checked="getCheckedValue(jsonData.agenteEziologicoNeisseriaMeningitidis)" switch disabled @input="onInputCheckbox($event, 'agenteEziologicoNeisseriaMeningitidis')">Neisseria Meningitidis</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.agenteEziologicoStreptococcusPneumoniae)" :checked="getCheckedValue(jsonData.agenteEziologicoStreptococcusPneumoniae)" switch disabled @input="onInputCheckbox($event, 'agenteEziologicoStreptococcusPneumoniae')">Streptococcus Pneumoniae</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.agenteEziologicoHaemophilusInfluenzae)" :checked="getCheckedValue(jsonData.agenteEziologicoHaemophilusInfluenzae)" switch disabled @input="onInputCheckbox($event, 'agenteEziologicoHaemophilusInfluenzae')">Haemophilus Influenzae</b-form-checkbox>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Altro Agente Eziologico Causante Meningite Batterica" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox :class="getCheckedClass(jsonData.altroAgenteEziologicoMicrobatterioTubercolare)" :checked="getCheckedValue(jsonData.altroAgenteEziologicoMicrobatterioTubercolare)" switch disabled @input="onInputCheckbox($event, 'altroAgenteEziologicoMicrobatterioTubercolare')">Micobatterio Tubercolare</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox :class="getCheckedClass(jsonData.altroAgenteEziologicoStrptococcoGruppoB)" :checked="getCheckedValue(jsonData.altroAgenteEziologicoStrptococcoGruppoB)" switch disabled @input="onInputCheckbox($event, 'altroAgenteEziologicoStrptococcoGruppoB')">Streptococco Gruppo B</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox :class="getCheckedClass(jsonData.altroAgenteEziologicoListeria)" :checked="getCheckedValue(jsonData.altroAgenteEziologicoListeria)" switch disabled @input="onInputCheckbox($event, 'altroAgenteEziologicoListeria')">Listeria</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox :class="getCheckedClass(jsonData.altroAgenteEziologicoAltro)" :checked="getCheckedValue(jsonData.altroAgenteEziologicoAltro)" switch disabled @input="onInputCheckbox($event, 'altroAgenteEziologicoAltro')">Altro Agente Batterico</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Descrizione Altro Agente Batterico:</label>
                    <span class="sa-data">{{ jsonData.altroAgenteEziologicoAltroDescrizione }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-form-checkbox :class="getCheckedClass(jsonData.altroAgenteEziologicoNonIdentificato)" :checked="getCheckedValue(jsonData.altroAgenteEziologicoNonIdentificato)" switch disabled @input="onInputCheckbox($event, 'altroAgenteEziologicoNonIdentificato')">Non Identificato (Solo Meningiti con Liquor Torbido o Purulento)</b-form-checkbox>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Diagnosi di Laboratorio" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Persona Contatto Laboratorio:</label>
                    <span class="sa-data">{{ jsonData.diagnosiLaboratorioPersonaContatto }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Persona Contatto Laboratorio Telefono:</label>
                    <span class="sa-data">{{ jsonData.diagnosiLaboratorioPersonaContattoTelefono }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Persona Contatto Laboratorio Email:</label>
                    <span class="sa-data">{{ jsonData.diagnosiLaboratorioPersonaContattoEmail }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Ospedale Laboratorio:</label>
                    <span class="sa-data">{{ jsonData.diagnosiLaboratorioOspedaleLaboratorio }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Data Prelievo Campione Positivo:</label>
                    <span class="sa-data">{{ formatDate(jsonData.diagnosiLaboratorioDataPrelievo) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-form-checkbox :class="getCheckedClass(jsonData.eseguitaTipizzazione)" :checked="getCheckedValue(jsonData.eseguitaTipizzazione)" switch disabled @input="onInputCheckbox($event, 'eseguitaTipizzazione')">Eseguita La Tipizzazione? (solo se malattia invasiva da N. meningitidis, S. pneumoniae, H. influenzae )</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Siero Gruppo/Sierotipo:</label>
                    <span class="sa-data">{{ jsonData.eseguitaTipizzazioneSieroGruppoSierotipo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Laboratorio Tipizzazione:</label>
                    <span class="sa-data">{{ jsonData.eseguitaTipizzazioneLaboratorio }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Altro:</label>
                    <span class="sa-data">{{ jsonData.eseguitaTipizzazioneLaboratorioAltro }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Esito conosciuto della malattia dalla data di segnalazione" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <!-- <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Ultimo Aggiornamento:</label>
          <span class="sa-data">{{ jsonData.esitoMalattiaUltimoAggiornamento }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Ultimo Aggiornamento Stato Paziente:</label>
          <span class="sa-data">{{ jsonData.esitoMalattiaUltimoAggiornamentoStatoPaziente }}</span>
        </b-col> -->
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Aggiornamento Stato Paziente al Momento della Segnalazione:</label>
                    <span class="sa-data">{{ jsonData.esitoMalattiaUltimoAggiornamentoStatoPaziente }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Aggiornamento Stato Paziente a 14 Giorni:</label>
                    <span class="sa-data">{{ jsonData.esitoMalattiaQuattordiciGiorniStatoPaziente }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Aggiornamento Stato Paziente a 1 Mese:</label>
                    <span class="sa-data">{{ jsonData.esitoMalattiaUnMeseStatoPaziente }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Aggiornamento Stato Paziente a 6 Mesi:</label>
                    <span class="sa-data">{{ jsonData.esitoMalattiaSeiMesiStatoPaziente }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Sequele dalla data di segnalazione (solo se mal. invasiva da S. pneumoniae, N. meningitidis, H. influenzae)" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Ultimo Aggiornamento:</label>
                    <span class="sa-data">{{ jsonData.sequeleDataSrgnalazioneUltimoAggiornamento }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.sequeleDataSrgnalazionePerditaUdito)" :checked="getCheckedValue(jsonData.sequeleDataSrgnalazionePerditaUdito)" switch disabled @input="onInputCheckbox($event, 'sequeleDataSrgnalazionePerditaUdito')">Perdita Anche Parziale Dell'Udito</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.sequeleDataSrgnalazionePerditaVista)" :checked="getCheckedValue(jsonData.sequeleDataSrgnalazionePerditaVista)" switch disabled @input="onInputCheckbox($event, 'sequeleDataSrgnalazionePerditaVista')">Perdita Anche Parziale Della Vista</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.sequeleDataSrgnalazioneDanniNeurologiciMotori)" :checked="getCheckedValue(jsonData.sequeleDataSrgnalazioneDanniNeurologiciMotori)" switch disabled @input="onInputCheckbox($event, 'sequeleDataSrgnalazioneDanniNeurologiciMotori')">Danni Neurologici Compresi Quelli Motori</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.sequeleDataSrgnalazioneAmputazione)" :checked="getCheckedValue(jsonData.sequeleDataSrgnalazioneAmputazione)" switch disabled @input="onInputCheckbox($event, 'sequeleDataSrgnalazioneAmputazione')">Amputazione</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.sequeleDataSrgnalazioneNecrosiCicatriciCutanee)" :checked="getCheckedValue(jsonData.sequeleDataSrgnalazioneNecrosiCicatriciCutanee)" switch disabled @input="onInputCheckbox($event, 'sequeleDataSrgnalazioneNecrosiCicatriciCutanee')">Necrosi E Cicatrici A Livello Cutaneo</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.sequeleDataSrgnalazioneAltro)" :checked="getCheckedValue(jsonData.sequeleDataSrgnalazioneAltro)" switch disabled @input="onInputCheckbox($event, 'sequeleDataSrgnalazioneAltro')">Altro</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Altro:</label>
                    <span class="sa-data">{{ jsonData.sequeleDataSrgnalazioneAltroDettaglio }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Contatti e focolaio epidemico:" header-tag="header" footer-tag="footer" title="">
            <h4>Nei 10 giorni precedenti l’inizio dei sintomi, il paziente</h4>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Contatto Con Un Altro Caso Della Stessa Malattia:</label>
                    <span class="sa-data">{{ jsonData.contattoAltroCasoStessaMalattia }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Probabile contagio fuori area di domicilio abituale:</label>
                    <b-form-checkbox :class="getCheckedClass(jsonData.probabileContagioFuoriAreaDomicilioAbituale)" :checked="getCheckedValue(jsonData.probabileContagioFuoriAreaDomicilioAbituale)" switch disabled @input="onInputCheckbox($event, 'probabileContagioFuoriAreaDomicilioAbituale')"></b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Dove:</label>
                    <span class="sa-data">{{ jsonData.probabileContagioFuoriAreaDomicilioAbitualeDove }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Focolaio Epidemico Conosciuto:</label>
                    <b-form-checkbox :class="getCheckedClass(jsonData.parteFocolaioEpidemiologicoConosciuto)" :checked="getCheckedValue(jsonData.parteFocolaioEpidemiologicoConosciuto)" switch disabled @input="onInputCheckbox($event, 'parteFocolaioEpidemiologicoConosciuto')"></b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Quale:</label>
                    <span class="sa-data">{{ jsonData.parteFocolaioEpidemiologicoConosciutoQuale }}</span>
                </b-col>
            </b-row>
            <h4>Comunità frequentate</h4>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.comunitaNodo)" :checked="getCheckedValue(jsonData.comunitaNodo)" switch disabled @input="onInputCheckbox($event, 'comunitaNodo')">Nido</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.comunitaScuolaMaterna)" :checked="getCheckedValue(jsonData.comunitaScuolaMaterna)" switch disabled @input="onInputCheckbox($event, 'comunitaScuolaMaterna')">Scuola Materna</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.comunitaScuola)" :checked="getCheckedValue(jsonData.comunitaScuola)" switch disabled @input="onInputCheckbox($event, 'comunitaScuola')">Scuola</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.comunitaOspedale)" :checked="getCheckedValue(jsonData.comunitaOspedale)" switch disabled @input="onInputCheckbox($event, 'comunitaOspedale')">Ospedale</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.comunitaCaserma)" :checked="getCheckedValue(jsonData.comunitaCaserma)" switch disabled @input="onInputCheckbox($event, 'comunitaCaserma')">Caserma</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.comunitaAltraComunita)" :checked="getCheckedValue(jsonData.comunitaAltraComunita)" switch disabled @input="onInputCheckbox($event, 'comunitaAltraComunita')">Altra Comunita</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Altro:</label>
                    <span class="sa-data">{{ jsonData.comunitaAltraComunitaDescrizione }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Stato vaccinale (solo se malattia invasiva da S. pneumoniae, N. meningitidis, H. influenzae)" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Vaccinato Agente In Causa:</label>
                    <span class="sa-data">{{ jsonData.vaccinatoAgenteCausa }}</span>
                </b-col>
            </b-row>
            <b-table sticky-header ref="tblViaggi" stacked="xl" striped hover itemscope :items="jsonData.listaVaccinazioni" :fields="fieldsVaccinazioni" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                <template #cell(index)="jsonData">
                    {{ jsonData.index + 1 }}
                </template>
                <template #cell(numeroDose)="item">
                    <span class="sa-data">{{ item.item.numeroDose }}</span>
                </template>
                <template #cell(dataSomministrazione)="item">
                    <span class="sa-data">{{ formatDate(item.item.dataSomministrazione) }}</span>
                </template>
                <template #cell(nomeCommerciale)="item">
                    <span class="sa-data">{{ item.item.nomeCommerciale }}</span>
                </template>
            </b-table>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Note Relative alla Vaccinazione:</label>
                    <span class="sa-data">{{ jsonData.noteVaccinazione }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Fattori predisponenti malattie batteriche invasive" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.fattorePredisponenteMalattieBattericheAspleniaAnatomicaFu)" :checked="getCheckedValue(jsonData.fattorePredisponenteMalattieBattericheAspleniaAnatomicaFu)" switch disabled @input="onInputCheckbox($event, 'fattorePredisponenteMalattieBattericheAspleniaAnatomicaFu')">Asplenia Anatomica/Funzionale</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.fattorePredisponenteMalattieBattericheDiabeteMellito)" :checked="getCheckedValue(jsonData.fattorePredisponenteMalattieBattericheDiabeteMellito)" switch disabled @input="onInputCheckbox($event, 'fattorePredisponenteMalattieBattericheDiabeteMellito')">Diabete Mellito</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.fattorePredisponenteMalattieBattericheImmunodeficienzaCong)" :checked="getCheckedValue(jsonData.fattorePredisponenteMalattieBattericheImmunodeficienzaCong)" switch disabled @input="onInputCheckbox($event, 'fattorePredisponenteMalattieBattericheImmunodeficienzaCong')">Immunodeficienza Congenita</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.fattorePredisponenteMalattieBattericheEpatopatia)" :checked="getCheckedValue(jsonData.fattorePredisponenteMalattieBattericheEpatopatia)" switch disabled @input="onInputCheckbox($event, 'fattorePredisponenteMalattieBattericheEpatopatia')">Epatopatia</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.fattorePredisponenteMalattieBattericheLeucemiaLinfomi)" :checked="getCheckedValue(jsonData.fattorePredisponenteMalattieBattericheLeucemiaLinfomi)" switch disabled @input="onInputCheckbox($event, 'fattorePredisponenteMalattieBattericheLeucemiaLinfomi')">Leucemie/Linfomi</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.fattorePredisponenteMalattieBattericheCardiopatite)" :checked="getCheckedValue(jsonData.fattorePredisponenteMalattieBattericheCardiopatite)" switch disabled @input="onInputCheckbox($event, 'fattorePredisponenteMalattieBattericheCardiopatite')">Cardiopatie</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.fattorePredisponenteMalattieBattericheAltreNeoplasie)" :checked="getCheckedValue(jsonData.fattorePredisponenteMalattieBattericheAltreNeoplasie)" switch disabled @input="onInputCheckbox($event, 'fattorePredisponenteMalattieBattericheAltreNeoplasie')">Altre Neoplasie</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.fattorePredisponenteMalattieBattericheAsmaEnfisema)" :checked="getCheckedValue(jsonData.fattorePredisponenteMalattieBattericheAsmaEnfisema)" switch disabled @input="onInputCheckbox($event, 'fattorePredisponenteMalattieBattericheAsmaEnfisema')">Asma Enfisema</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.fattorePredisponenteMalattieBattericheTerapiaImmunoSoppre)" :checked="getCheckedValue(jsonData.fattorePredisponenteMalattieBattericheTerapiaImmunoSoppre)" switch disabled @input="onInputCheckbox($event, 'fattorePredisponenteMalattieBattericheTerapiaImmunoSoppre')">Terapie Immuni-Soppressive</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.fattorePredisponenteMalattieBattericheTossicodipendenza)" :checked="getCheckedValue(jsonData.fattorePredisponenteMalattieBattericheTossicodipendenza)" switch disabled @input="onInputCheckbox($event, 'fattorePredisponenteMalattieBattericheTossicodipendenza')">Tossicodipendenza E.V.</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.fattorePredisponenteMalattieBattericheTrapiantoOrganoMido)" :checked="getCheckedValue(jsonData.fattorePredisponenteMalattieBattericheTrapiantoOrganoMido)" switch disabled @input="onInputCheckbox($event, 'fattorePredisponenteMalattieBattericheTrapiantoOrganoMido')">Trapianto D'Organo/Midollo</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.fattorePredisponenteMalattieBattericheAlcolismo)" :checked="getCheckedValue(jsonData.fattorePredisponenteMalattieBattericheAlcolismo)" switch disabled @input="onInputCheckbox($event, 'fattorePredisponenteMalattieBattericheAlcolismo')">Alcolismo</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.fattorePredisponenteMalattieBattericheImpiantoCocleare)" :checked="getCheckedValue(jsonData.fattorePredisponenteMalattieBattericheImpiantoCocleare)" switch disabled @input="onInputCheckbox($event, 'fattorePredisponenteMalattieBattericheImpiantoCocleare')">Impianto Cocleare</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.fattorePredisponenteMalattieBattericheTabagismo)" :checked="getCheckedValue(jsonData.fattorePredisponenteMalattieBattericheTabagismo)" switch disabled @input="onInputCheckbox($event, 'fattorePredisponenteMalattieBattericheTabagismo')">Tabagismo</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.fattorePredisponenteMalattieBattericheFistoleLiquorali)" :checked="getCheckedValue(jsonData.fattorePredisponenteMalattieBattericheFistoleLiquorali)" switch disabled @input="onInputCheckbox($event, 'fattorePredisponenteMalattieBattericheFistoleLiquorali')">Fistole Liquorale</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.fattorePredisponenteMalattieBattericheDeficitFattoriCompl)" :checked="getCheckedValue(jsonData.fattorePredisponenteMalattieBattericheDeficitFattoriCompl)" switch disabled @input="onInputCheckbox($event, 'fattorePredisponenteMalattieBattericheDeficitFattoriCompl')">Deficit Fattori Del Complemento</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.fattorePredisponenteMalattieBattericheImmunodeficienzaAcqu)" :checked="getCheckedValue(jsonData.fattorePredisponenteMalattieBattericheImmunodeficienzaAcqu)" switch disabled @input="onInputCheckbox($event, 'fattorePredisponenteMalattieBattericheImmunodeficienzaAcqu')">Immunodeficienza Acquisita</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.fattorePredisponenteMalattieBattericheEmoglobinopatie)" :checked="getCheckedValue(jsonData.fattorePredisponenteMalattieBattericheEmoglobinopatie)" switch disabled @input="onInputCheckbox($event, 'fattorePredisponenteMalattieBattericheEmoglobinopatie')">Emoglobinopatie</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.fattorePredisponenteMalattieBattericheInsufficienzaRenale)" :checked="getCheckedValue(jsonData.fattorePredisponenteMalattieBattericheInsufficienzaRenale)" switch disabled @input="onInputCheckbox($event, 'fattorePredisponenteMalattieBattericheInsufficienzaRenale')">Insufficienza Renale Cronica/Dialisi</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.fattorePredisponenteMalattieBattericheAltreMalattiePolmon)" :checked="getCheckedValue(jsonData.fattorePredisponenteMalattieBattericheAltreMalattiePolmon)" switch disabled @input="onInputCheckbox($event, 'fattorePredisponenteMalattieBattericheAltreMalattiePolmon')">Altre Malattie Polmonari Croniche</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.fattorePredisponenteMalattieBattericheAltreCondizioni)" :checked="getCheckedValue(jsonData.fattorePredisponenteMalattieBattericheAltreCondizioni)" switch disabled @input="onInputCheckbox($event, 'fattorePredisponenteMalattieBattericheAltreCondizioni')">Altre Condizioni</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Altro:</label>
                    <span class="sa-data">{{ jsonData.fattorePredisponenteMalattieBattericheAltreCondizioniDett }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Altre Considerazioni</label>
                    <span class="sa-data">{{ jsonData.altreConsiderazioni }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Dati Notifica" header-tag="header" footer-tag="footer" title="">
            <indagine-epidemiologica-dati-notifica-component :jsonData="jsonData" :isEdit="false" @update="onUpdateJsonData"></indagine-epidemiologica-dati-notifica-component>
        </b-card>
    </div>
</template>
<script>
import axios from "axios";
import UtilityMixin from "../../../utility/UtilityMixin";
import IndagineEpidemiologicaDatiNotificaComponent from "../components/IndagineEpidemiologicaDatiNotificaComponent.vue";
export default {
    mixins: [UtilityMixin],
    components: { IndagineEpidemiologicaDatiNotificaComponent },
    data() {
        return {
            pathResource: "/malattieinfettiveieencefalitemeningitesmeningococcica",
            id: "-1",
            perPage: 100,
            currentPage: 1,
            jsonData: {
                quadroClinicoSepsi: "",
                quadroClinicoMeningite: "",
                quadroClinicoPolmoniteBatterica: "",
                quadroClinicoCellulite: "",
                quadroClinicoEpiglottite: "",
                quadroClinicoPeritonite: "",
                quadroClinicoArtriteSetticaOsteomielite: "",
                quadroClinicoAltro: "",
                quadroClinicoAltroDescrizione: "",
                quadroClinicoPericardite: "",
                ricoverato: "",
                dataRicovero: null,
                agenteEziologicoNeisseriaMeningitidis: "",
                agenteEziologicoStreptococcusPneumoniae: "",
                agenteEziologicoHaemophilusInfluenzae: "",
                altroAgenteEziologicoMicrobatterioTubercolare: "",
                altroAgenteEziologicoStrptococcoGruppoB: "",
                altroAgenteEziologicoListeria: "",
                altroAgenteEziologicoAltro: "",
                altroAgenteEziologicoAltroDescrizione: "",
                altroAgenteEziologicoNonIdentificato: "",
                diagnosiLaboratorioPersonaContatto: "",
                diagnosiLaboratorioPersonaContattoTelefono: "",
                diagnosiLaboratorioPersonaContattoEmail: "",
                diagnosiLaboratorioOspedaleLaboratorio: "",
                diagnosiLaboratorioDataPrelievo: null,
                eseguitaTipizzazione: "",
                eseguitaTipizzazioneSieroGruppoSierotipo: "",
                eseguitaTipizzazioneLaboratorio: "",
                eseguitaTipizzazioneLaboratorioAltro: "",
                esitoMalattiaUltimoAggiornamento: "",
                esitoMalattiaUltimoAggiornamentoStatoPaziente: "",
                sequeleDataSrgnalazioneUltimoAggiornamento: "",
                sequeleDataSrgnalazionePerditaUdito: "",
                sequeleDataSrgnalazionePerditaVista: "",
                sequeleDataSrgnalazioneDanniNeurologiciMotori: "",
                sequeleDataSrgnalazioneAmputazione: "",
                sequeleDataSrgnalazioneNecrosiCicatriciCutanee: "",
                sequeleDataSrgnalazioneAltro: "",
                sequeleDataSrgnalazioneAltroDettaglio: "",
                contattoAltroCasoStessaMalattia: "",
                probabileContagioFuoriAreaDomicilioAbituale: "",
                probabileContagioFuoriAreaDomicilioAbitualeDove: "",
                parteFocolaioEpidemiologicoConosciuto: "",
                parteFocolaioEpidemiologicoConosciutoQuale: "",
                comunitaNodo: "",
                comunitaScuolaMaterna: "",
                comunitaScuola: "",
                comunitaOspedale: "",
                comunitaCaserma: "",
                comunitaAltraComunita: "",
                comunitaAltraComunitaDescrizione: "",
                vaccinatoAgenteCausa: "",
                noteVaccinazione: "",
                fattorePredisponenteMalattieBattericheAspleniaAnatomicaFu: "",
                fattorePredisponenteMalattieBattericheImmunodeficienzaCong: "",
                fattorePredisponenteMalattieBattericheLeucemiaLinfomi: "",
                fattorePredisponenteMalattieBattericheAltreNeoplasie: "",
                fattorePredisponenteMalattieBattericheTerapiaImmunoSoppre: "",
                fattorePredisponenteMalattieBattericheTrapiantoOrganoMido: "",
                fattorePredisponenteMalattieBattericheImpiantoCocleare: "",
                fattorePredisponenteMalattieBattericheFistoleLiquorali: "",
                fattorePredisponenteMalattieBattericheImmunodeficienzaAcqu: "",
                fattorePredisponenteMalattieBattericheInsufficienzaRenale: "",
                fattorePredisponenteMalattieBattericheDiabeteMellito: "",
                fattorePredisponenteMalattieBattericheEpatopatia: "",
                fattorePredisponenteMalattieBattericheCardiopatite: "",
                fattorePredisponenteMalattieBattericheAsmaEnfisema: "",
                fattorePredisponenteMalattieBattericheTossicodipendenza: "",
                fattorePredisponenteMalattieBattericheAlcolismo: "",
                fattorePredisponenteMalattieBattericheTabagismo: "",
                fattorePredisponenteMalattieBattericheDeficitFattoriCompl: "",
                fattorePredisponenteMalattieBattericheEmoglobinopatie: "",
                fattorePredisponenteMalattieBattericheAltreMalattiePolmon: "",
                fattorePredisponenteMalattieBattericheAltreCondizioni: "",
                fattorePredisponenteMalattieBattericheAltreCondizioniDett: "",
                sanitarioNotificante: "",
                sanitarioNotificanteContatto: "",
                esitoMalattiaQuattordiciGiorniStatoPaziente: "",
                esitoMalattiaUnMeseStatoPaziente: "",
                esitoMalattiaSeiMesiStatoPaziente: "",
                dataNotifica: null,
                listaVaccinazioni: [],
            },
            fieldsVaccinazioni: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "N. Dose",
                    key: "numeroDose",
                    thStyle: "width: 10rem",
                    sortable: false,
                },
                {
                    label: "Data Somministrazione",
                    key: "dataSomministrazione",
                    thStyle: "width: 18rem",
                    sortable: false,
                },
                {
                    label: "Nome Commerciale",
                    key: "nomeCommerciale",
                    // thStyle: "width: 8rem",
                    sortable: false,
                },
            ],
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.$emit("showmodalloading", true);
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            axios
                .get(link + me.id)
                .then((response) => {
                    me.jsonData = response.data.data;
                    me.$emit("showmodalloading", false);
                })
                .catch(() => {
                    me.$emit("showmodalloading", false);
                });
        },
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
        },
        getCheckedValue(value) {
            return value === "SI";
        },
        getCheckedClass(value) {
            return value === "SI" ? "sa-label-data text-success" : "sa-label-data";
        },
        onInputCheckbox(event, field) {
            // console.log(event);
            let me = this;
            me.jsonData[field] = event ? "SI" : "NO";
        },
    },
};
</script>
