import Contatti from "../";

const routes = [
     {
          name: "ContattiList",
          path: "/contatti",
          component: Contatti.ContattiList,
     },
     {
          name: "ContattiEdit",
          path: "/contatti/edit/:id",
          component: Contatti.ContattiEdit,
     },
     {
          name: "ContattiView",
          path: "/contatti/view/:id",
          component: Contatti.ContattiView,
     },
];

export default routes;