<template>
    <div style="height: 100%">
        <div class="sa-flexbox" style="padding-bottom: 20px">
            <div class="sa-flexbox-body">
                <div class="b-table-sticky-header">
                    <label class="sa-accordion-label">
                        <span>Spesa Extra</span>
                        <!--  class="sa-flexbox-body-title" -->
                        <b-button size="sm" variant="outline-success outline-primary-noborder sa-margin-right float-sm-right" :hidden="btnNuovaSpesaExtraHidden" @click="onClickNuovaSpesaExtra" class="mb-2">
                            <font-awesome-icon icon="plus"> </font-awesome-icon>
                            Aggiungi
                        </b-button>
                    </label>

                    <b-table sticky-header ref="table" stacked="lg" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table sa-card-border">
                        <template #cell(index)="row">
                            {{ row.index + 1 }}
                        </template>

                        <template #cell(statoAttuale)="item">
                            <b-badge :variant="getStatusVariant(item.item.statoAttuale)">
                                {{ item.item.statoAttuale }}
                            </b-badge>
                        </template>

                        <template v-slot:cell(actions)="{ item }">
                            <b-container class="bv-example-row" v-if="!isViewMode">
                                <b-button size="sm" variant="outline-success" v-b-tooltip.hover title="Modifica Spesa" @click="onClickAggiornaSpesaExtra(item)">
                                    <font-awesome-icon icon="edit"> </font-awesome-icon>
                                </b-button>

                                <b-button size="sm" v-b-tooltip.hover title="Cancella Spesa" variant="outline-danger" @click="onDeleteSpesaExtra(item)">
                                    <font-awesome-icon icon="trash"></font-awesome-icon>
                                </b-button>
                            </b-container>
                        </template>
                    </b-table>
                </div>

                <rsa-spesa-extra-edit ref="cmpRsaSpesaExtraEditComponent" :id-accettazione="idAccettazione" @afterSaveSuccess="onAfterSaveSuccessSpesaExtra" @onClose="loadData" @onDelete="onDelete"> </rsa-spesa-extra-edit>
            </div>

            <div class="sa-list-component-footer">
                <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col lg="3">
                        <p>Record Totali:{{ rows }}</p>
                    </b-col>
                    <b-col lg="9">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                    </b-col>
                </b-row>
            </div>
        </div>
    </div>
</template>

<script>
import UtilityMixin from "../../../utility/UtilityMixin";
import RsaSpesaExtraEdit from "./RsaSpesaExtraEditComponent.vue";
import moment from "moment";

export default {
    props: {
        jsonDataAccettazione: { type: Object },
        utente: { type: Object },
        isViewMode: {
            type: Boolean,
            default: false,
        },
    },

    watch: {
        currentPage() {
            let me = this;
            me.loadData();
        },
        /* idAccettazione: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.loadData();
                }
            },
        }, */
    },
    mixins: [UtilityMixin],
    components: { RsaSpesaExtraEdit },
    data() {
        return {
            rows: 0,
            perPage: 50,
            currentPage: 1,
            listKey: 0,
            items: [],
            btnNuovaSpesaExtraHidden: false,
            pathResource: "/rsaspeseextrapazienti",
            linkedit: "/",
            idAccettazione: null,
            filterItems: [],
            filtroSpesaExtra: {},
            showModalLoading: false,
            fields: [
                {
                    label: "",
                    key: "index",
                },
                {
                    label: "Spesa",
                    key: "spesaExtra",
                },
                {
                    label: "Importo",
                    key: "importo",
                },
                {
                    label: "Data Inizio",
                    key: "dataOraInizio",
                    formatter: (value) => {
                        if (value) {
                            return moment(new Date(value)).format("DD/MM/YYYY");
                        } else {
                            return "-----";
                        }
                    },
                },
                /* {
                    label: "Data Fine",
                    key: "dataOraFine",
                }, */
                {
                    label: "Stato",
                    key: "statoAttuale",
                },
                {
                    class: "sa-table-column-action-double",
                    key: "actions",
                    label: "",
                    thStyle: () => (this.isViewMode ? "display: none" : ""),
                    /* thStyle: function() {
                    return this.isViewMode ? 'display: none' : '';
                    } */
                },
            ],
        };
    },
    mounted() {},

    methods: {
        setId(id) {
            let me = this;
            me.idAccettazione = id;
            me.filtroSpesaExtra = { idAccettazione: id };
            me.loadData();
        },

        loadData() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            if (!me.idAccettazione) {
                console.warn("Non carica il load data: idAccettazione non disponibile");
                return;
            }
            if (sessionStorage["filtroSpesaExtra"]) {
                me.filtroSpesaExtra = JSON.parse(sessionStorage["filtroSpesaExtra"]);
            }
            me.filtroSpesaExtra = {
                ...me.filtroSpesaExtra,
                page: me.currentPage,
                forPage: me.perPage,
                idAccettazione: me.idAccettazione,
            };
            UtilityMixin.methods.loadDataResources(me.pathResource, me.filtroSpesaExtra, me.loadDataSuccess, me.loadDataError);
            this.showModalLoading = true;
        },

        loadDataSuccess(response) {
            let me = this;
            me.items = response.data.data.list;
            me.rows = response.data.data.recordsNumber;
            this.showModalLoading = true;
        },
        loadDataError(response) {
            console.log(response);
        },

        onClickAggiornaSpesaExtra(item) {
            this.$refs.cmpRsaSpesaExtraEditComponent.aggiornaSpesaExtraPaziente(item);
        },

        onDeleteSpesaExtra(item) {
            let me = this;
            me.$refs.cmpRsaSpesaExtraEditComponent.onDeleteSpesaExtra(item);
        },

        onClickNuovaSpesaExtra() {
            if (this.idAccettazione) {
                this.$refs.cmpRsaSpesaExtraEditComponent.nuovaSpesaExtraPaziente(this.idAccettazione);
            } else {
                this.$bvModal.msgBoxOk("Dati accettazione non disponibili", {
                    title: "ATTENZIONE",
                    size: "sm",
                    okVariant: "outline-danger",
                });
            }
        },
        saveSuccessResponse(response) {
            let me = this;
            me.$emit("sucessRestCall", response);
            me.$emit("onAfterSave");
            me.loadData();
        },
        saveErrorResponse(response) {
            console.log(response.response.data);
            let me = this;
            if (response.response.status === 400) {
                this.$bvModal.msgBoxOk(response.response.data.messaggio, {
                    title: "ATTENZIONE",
                    size: "sm",
                    okVariant: "outline-danger",
                    headerClass: "sa-msg-header-danger",
                    footerClass: "p-2 border-top-0",
                    centered: true,
                });
            } else {
                me.$bvToast.toast("Errore nel salvataggio" + response.message, {
                    title: "Errore",
                    variant: "danger",
                    solid: true,
                });
            }
        },
        onAfterSaveSuccessSpesaExtra() {
            this.loadData();
        },
        onDelete() {
            this.loadData();
        },
        getStatusVariant(status) {
            switch (status) {
                case "INSERITO":
                    return "success";
                case "ANNULLATO":
                    return "danger";
                case "IN ATTESA":
                    return "warning";
                default:
                    return "secondary";
            }
        },
    },
};
</script>
