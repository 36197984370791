<template>
    <div class="sa-tab-scroll">
        <b-card class="sa-card" header="Segnalazione" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Codice IcdIX</label>
                    <span class="sa-data">{{ jsonData.codiceIcdix }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Malattia infettiva</label>
                    <span class="sa-data">{{ jsonData.malattiaSegnalata }}</span>
                </b-col>
                <!-- <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Altra Malattia</label>
          <span class="sa-data">{{ jsonData.malattiaSegnalataAltra }}</span>
        </b-col> -->
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Tipo Caso</label>
                    <span class="sa-data">{{ jsonData.tipoCaso }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">UOSD di diagnosi</label>
                    <span class="sa-data">{{ jsonData.uosdDiagnosi }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">UOSD competente <b-icon icon="info-circle" @click="onClickInfoUosd"></b-icon></label>
                    <span class="sa-data">{{ jsonData.uosdResidenza }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Segnalazione</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataSegnalazione) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Nome Medico</label>
                    <span class="sa-data">{{ jsonData.nomeMedicoSegnalante }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Cognome Medico</label>
                    <span class="sa-data">{{ jsonData.cognomeMedicoSegnalante }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Contatti Medico</label>
                    <span class="sa-data">{{ jsonData.contattoMedicoSegnalante }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Anagrafica" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Codice Fiscale/STP/ENI</label>
                    <span class="sa-data">{{ jsonData.pazienteBean.identificativo }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Nascita</label>
                    <span class="sa-data">{{ formatDate(jsonData.pazienteBean.dataNascita) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Cognome</label>
                    <span class="sa-data">{{ jsonData.pazienteBean.cognome }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Nome</label>
                    <span class="sa-data">{{ jsonData.pazienteBean.nome }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Sesso</label>
                    <span class="sa-data">{{ jsonData.pazienteBean.sesso }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Contatto Telefonico</label>
                    <span class="sa-data">{{ jsonData.pazienteBean.telefono }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Professione</label>
                    <!-- <span class="sa-data">{{jsonData.professione" :options="professioniOptions" value-field="value" text-field="text"></b-form-select> -->
                    <span class="sa-data">{{ jsonData.professione }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Provincia Nascita</label>
                    <span class="sa-data">{{ jsonData.pazienteBean.provinciaNascita }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Comune Nascita</label>
                    <span class="sa-data">{{ jsonData.pazienteBean.comuneNascita }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Email</label>
                    <span class="sa-data">{{ jsonData.pazienteBean.email }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Residenza" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Provincia</label>
                    <span class="sa-data">{{ jsonData.provinciaResidenza }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Comune</label>
                    <span class="sa-data">{{ jsonData.comuneResidenza }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Indirizzo</label>
                    <span class="sa-data">{{ jsonData.indirizzoResidenza }}</span>
                </b-col>
            </b-row>
            <hr />
            <h5 class="sa-text-left">Domicilio</h5>
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Provincia</label>
                    <span class="sa-data">{{ jsonData.provinciaDomicilio }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Comune</label>
                    <span class="sa-data">{{ jsonData.comuneDomicilio }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Indirizzo</label>
                    <span class="sa-data">{{ jsonData.indirizzoDomicilio }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Segnalazione Informazioni" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Inizio Sintomi</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataInizioSintomi) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Provincia Inizio Sintomi</label>
                    <span class="sa-data">{{ jsonData.provinciaInizioSintomi }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Comune Inizio Sintomi</label>
                    <span class="sa-data">{{ jsonData.comuneInizioSintomi }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Sintomi</label>
                    <span class="sa-data">{{ jsonData.descrizioneSintomi }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Ricovero in luogo di cura</label>
                    <span class="sa-data">{{ jsonData.ricoveroLuogoCura }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Specificare</label>
                    <span class="sa-data">{{ jsonData.specificareLuogoCura }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Specificare Altro</label>
                    <span class="sa-data">{{ jsonData.specificareLuogoCuraAltro }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Data Ricovero</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataRicovero) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Vaccino ( se esiste )</label>
                    <span class="sa-data">{{ jsonData.vaccino }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Numero Dosi</label>
                    <span class="sa-data">{{ jsonData.numeroDosi }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Data ultima dose</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataUltimaDose) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Tipo di Vaccino</label>
                    <span class="sa-data">{{ jsonData.tipoVaccino }}</span>
                    <!-- <span class="sa-data">{{jsonData.tipoVaccino" :options="tipiVacciniOptions" :value="null" value-field="value" text-field="text" :disabled="jsonData.vaccino !== 'VACCINATO'"></b-form-select> -->
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Clinica</label>
                    <span class="sa-data">{{ jsonData.datiClinici }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Dettaglio</label>
                    <span class="sa-data">{{ jsonData.datiCliniciDettaglio }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Esami Colturali</label>
                    <span class="sa-data">{{ jsonData.esamiCulturali }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Dettaglio</label>
                    <span class="sa-data">{{ jsonData.esamiColturaliDettaglio }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Esame Diretto</label>
                    <span class="sa-data">{{ jsonData.esameDiretto }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Dettaglio</label>
                    <span class="sa-data">{{ jsonData.esameDirettoDettaglio }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Esami Sierologici</label>
                    <span class="sa-data">{{ jsonData.esamiSierologici }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Esami Sierologici Dettaglio</label>
                    <span class="sa-data">{{ jsonData.esamiSierologiciDettagli }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Biologica Molecolare</label>
                    <span class="sa-data">{{ jsonData.biologiaMolecolare }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Dettaglio</label>
                    <span class="sa-data">{{ jsonData.biologiaMolecolareDettaglio }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Eventuale Fonte Contagio</label>
                    <span class="sa-data">{{ jsonData.fonteContagio }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Altri Casi Correlati</label>
                    <span class="sa-data">{{ jsonData.altriCasiCorelati }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Numero</label>
                    <span class="sa-data">{{ jsonData.altriCasiCorelatiNumero }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Riferimenti" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <span style="font-weight: bold">Struttura:</span>
                    {{ jsonData.codiceStruttura }} - {{ jsonData.denominazione }}
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Note" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <span class="sa-data">{{ jsonData.note }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Protocollo" header-tag="header" footer-tag="footer" title="">
            <protocollo-list-component :idDocumento="jsonData.id" :documento="'SEGNALAZIONE'"></protocollo-list-component>
        </b-card>
        <b-modal ref="mdlListaStoricoUosd" id="mdlListaStoricoUosd" size="xl" style="height: 100%" title="Storico movimentazione UOSD" scrollable>
            <div class="sa-list-component-header">
                <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col lg="3">
                        <p>
                            {{ this.$i18n.t("global.lblTotalRecords") }}:
                            {{ rowsStoricoUosd }}
                        </p>
                    </b-col>
                    <b-col lg="9">
                        <b-pagination v-model="pageStoricoUosd" :total-rows="rowsStoricoUosd" :per-page="forPageStoricoUosd" align="right" size="sm"></b-pagination>
                    </b-col>
                </b-row>
            </div>
            <div class="sa-list-component-body">
                <div class="b-table-sticky-header">
                    <b-table sticky-header ref="table" stacked="lg" striped hover :items="storicoUosd" :fields="fieldsUosd" :current-page="1" :per-page="forPageStoricoUosd" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="row">
                            {{ row.index + 1 }}
                        </template>
                        <template #cell(urlDocumento)="item">
                            <a v-bind:href="item.item.urlDocumento"> Scarica </a>
                        </template>
                        <template #cell(urlDocumentoTimbrato)="item">
                            <a v-bind:href="item.item.urlDocumentoTimbrato"> Scarica </a>
                        </template>
                        <template #cell(statoAttualeDataOra)="item">
                            {{ formatDateTime(item.item.statoAttualeDataOra) }}
                        </template>
                    </b-table>
                </div>
            </div>
            <div class="sa-list-component-footer">
                <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col lg="3">
                        <p>
                            {{ this.$i18n.t("patients.lblTotalRecords") }}:
                            {{ rowsStoricoUosd }}
                        </p>
                    </b-col>
                    <b-col lg="9">
                        <b-pagination v-model="pageStoricoUosd" :total-rows="rowsStoricoUosd" :per-page="forPageStoricoUosd" align="right" size="sm"></b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-modal>
    </div>
</template>
<script>
import axios from "axios";
import UtilityMixin from "../../utility/UtilityMixin";
import ProtocolloListComponent from "../../protocollo/components/ProtocolloListComponent.vue";
export default {
    components: { ProtocolloListComponent },
    props: {
        jsonData: {
            Type: Object,
            default: function () {
                return {
                    provinciaInizioSintomi: "",
                    professione: "",
                    dataInizioSintomi: null,
                    comuneSintomiCodiceIstat: null,
                    vaccino: null,
                    numeroDosi: 0,
                    dataUltimaDose: null,
                    tipoVaccino: "",
                    nomeMedicoSegnalante: "",
                    cognomeMedicoSegnalante: "",
                    contattoMedicoSegnalante: "",
                    dataSegnalazione: null,
                    ricoveroLuogoCura: null,
                    comuneResidenzaCodiceIstat: null,
                    indirizzoResidenza: "",
                    comuneDomicilioCodiceIstat: null,
                    indirizzoDomicilio: "",
                    statoAttuale: null,
                    statoAttualeDataOra: null,
                    uosdDiagnosi: null,
                    sesso: null,
                    pazienteBean: {
                        identificativo: "",
                        cognome: "",
                        nome: "",
                        dataNascita: null,
                        comuneNascitaCodiceIstat: null,
                        comuneResidenzaCodiceIstat: null,
                        comuneDomicilioCodiceIstat: null,
                        indirizzoResidenza: "",
                        indirizzoDomicilio: "",
                        telefono: "",
                        sesso: null,
                        provinciaNascita: null,
                        provinciaResidenza: null,
                        provinciaDomicilio: null,
                    },
                    malattieInfettiveSegnalazioneStatoMalattiaBeans: [],
                };
            },
        },
    },
    mixins: [UtilityMixin],

    computed: {
        rows() {
            return this.jsonData.malattieInfettiveSegnalazioneStatoMalattiaBeans.length;
        },
    },
    // mounted() {
    //   let me = this;
    //   me.loadStoricoUosd();
    // },
    watch: {
        pageStoricoUosd() {
            let me = this;
            me.loadStoricoUosd();
        },
    },
    data() {
        return {
            perPage: 10,
            currentPage: 1,
            storicoUosd: [],
            rowsStoricoUosd: 0,
            pageStoricoUosd: 1,
            forPageStoricoUosd: 10,
            pathResourceUosdCompetenza: "/malattieinfettivesegnalazioneuosdcompetenza",
            fields: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Stato",
                    key: "stato",
                    sortable: true,
                },
                {
                    label: "Note",
                    key: "note",
                    thStyle: "width: 15rem",
                    sortable: true,
                },
                {
                    label: "Stato Data/Ora",
                    key: "statoDataOra",
                    sortable: true,
                    thStyle: "width: 10rem",
                    formatter: function (value) {
                        return UtilityMixin.methods.formatDateTime(value);
                    },
                },
            ],
            fieldsUosd: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Uosd Precedente",
                    key: "uosdPrecedente",
                    thStyle: "width: 10rem",
                    sortable: true,
                },
                {
                    label: "Uosd Attuale",
                    key: "uosdCompetenza",
                    thStyle: "width: 10rem",
                    sortable: true,
                },
                {
                    label: "Data/Ora",
                    key: "updateDate",
                    sortable: true,
                    thStyle: "width: 10rem",
                    formatter: function (value) {
                        return UtilityMixin.methods.formatDateTime(value);
                    },
                },
                {
                    label: "Note",
                    key: "note",
                    thStyle: "width: 15rem",
                    sortable: true,
                },
            ],
        };
    },
    methods: {
        onClickInfoUosd() {
            // console.log("INFO");
            let me = this;
            me.pageStoricoUosd = 1;
            me.loadStoricoUosd();
        },
        loadStoricoUosd() {
            let me = this;
            me.showModalLoading = true;
            let filtro = {
                page: me.pageStoricoUosd,
                forPage: me.forPageStoricoUosd,
                idSegnalazione: me.jsonData.id,
            };

            let link = process.env.VUE_APP_PATH_API + me.pathResourceUosdCompetenza;
            me.showModalLoading = true;
            axios
                .get(link, { params: filtro })
                .then((response) => {
                    me.storicoUosd = response.data.data.list;
                    me.rowsStoricoUosd = response.data.data.recordsNumber;
                    me.showModalLoading = false;
                    me.$refs["mdlListaStoricoUosd"].show();
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
    },
};
</script>
