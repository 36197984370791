<template>
  <sa-page-layout :btnNewVisible="true" :linkedit="linkedit">
    <template slot="toolbar-title">
      <p>Aziende Uno</p>
    </template>
    <template slot="toolbar-row"> </template>
    <template slot="table-filter"> </template>
    <template slot="table-header"> </template>
    <template slot="table-body">
      <div class="b-table-sticky-header sa-scroll">
        <b-table sticky-header ref="table" :filter="filter" stacked="lg" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
          <template #cell(name)="{ item }">
            <router-link class="sa-edit-link" :to="'/enti/view/' + item.id"> {{ item.denominazione }} </router-link>
            <br />
            <small> {{ item.partitaIva }} </small>
          </template>
          <template v-slot:cell(action)="row">
            <b-button size="sm" variant="outline-danger" @click="onDeleteItem(row.item, row.index, $event.target)" v-b-modal.visualizzaAllegato>
              <b-icon icon="trash"></b-icon>
            </b-button>
          </template>
        </b-table>
      </div>
    </template>
    <template slot="table-footer"> </template>
  </sa-page-layout>
</template>

<script>
import axios from "axios";
import SaPageLayout from "../../../template/layout/components/SaPageLayout.vue";
import UtilityMixins from "../../utility/UtilityMixin";
export default {
  mixins: [UtilityMixins],
  components: { SaPageLayout },
  data() {
    return {
      id: null,
      linkedit: "/aziende",
      perPage: 100,
      currentPage: 1,
      filter: {},
      items: [],
      fields: [
        { key: "name", label: "Ragione Sociale" },
        {
          label: "Telefono",
          key: "telefono",
          tdClass: "text-left",
          sortable: true,
        },
        {
          label: "Email",
          key: "email",
          tdClass: "text-left",
          sortable: true,
        },
      ],
    };
  },
  mounted() {
    let me = this;
    me.loadData();
    me.linkedit = "/enti";
  },
  methods: {
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/enti";
      axios.get(link).then((response) => {
        me.items = response.data.data;
      });
    },
  },
};
</script>

<style></style>
