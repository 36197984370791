<template>
  <div class="sa-list-component">
    <div class="sa-list-component-header">
      <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>{{ this.$i18n.t("global.lblTotalRecords") }}:{{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </div>
    <div class="sa-list-component-body">
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="table" :filter="filter" stacked="lg" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
          <template v-slot:cell(nominativo)="{ item }">
            <router-link class="sa-edit-link" :to="'/medici/edit/' + item.id">{{ item.titolo }} {{ item.cognome }} {{ item.nome }}</router-link>
          </template>
        </b-table>
      </div>
    </div>
    <div class="sa-list-component-footer">
      <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      rows: 0,
      perPage: 50,
      currentPage: 1,
      btnNewVisible: true,
      btnPrintVisible: true,
      nomeFile: "Scheda SVEI_",
      descrizione: "Scheda SVEI",
      tipoDocumento: "SCHEDA SVEI",
      codiceApplicativo: "SVEI-01",
      pathResourceAllegato: "/sveiprinter/preview/",
      linkedit: "/schedavalutazioneesigenzeimmediate",
      pathResource: "/medici",
      pathResourceCodiceValutazione: "/giubileocodicivalutazione",
      filter: null,
      signedPdfViewer: null,
      showModalLoading: false,
      items: [],
      fields: [
        {
          label: "Nominativo",
          key: "nominativo",
          sortable: true,
        },
        {
          label: "Codice Fiscale",
          key: "codiceFiscale",
          sortable: true,
        },
        {
          label: "Matricola",
          key: "matricola",
          sortable: true,
        },
      ],
      filtro: { nome: "", cognome: "", codiceFiscale: "", codiceValutazione: "", patologieCorsoIntolleranzeAlimentari: false, patologieCorsoNefropatia: false, patologieCorsoFistole: false, patologieCorsoMetabolica: false, patologieCorsoCardiopatia: false, patologieCorsoRespiratoria: false },
    };
  },
  // mounted() {
  //   let me = this;
  //    me.loadCodiceValutazione();
  //   me.loadData();
  // },
  methods: {
    onRefresh() {
      let me = this;
      me.loadData();
    },
    loadData() {
      let me = this;
      let sessionStorage = window.sessionStorage;
      if (sessionStorage["filtro"]) {
        me.filtro = JSON.parse(sessionStorage["filtro"]);
      }
      let link = process.env.VUE_APP_PATH_API + me.pathResource;
      me.$emit("beforeLoadData");
      me.filtro.page = me.currentPage;
      me.filtro.forPage = me.perPage;
      axios
        .get(link, { params: me.filtro })
        .then((response) => {
          me.items = [];
          me.items = response.data.data.list;
          me.rows = response.data.data.recordsNumber;
          me.$emit("afterLoadData");
        })
        .catch(() => {
          me.$emit("afterLoadData");
        });
    },
  },
};
</script>

<style>
.sa-codice-valurazione-red,
.sa-codice-valurazione-green,
.sa-codice-valurazione-yellow {
  height: 24px;
  width: 24px;
  display: inline-block;

  padding-top: 3px;
  border-radius: 13px;
  font-weight: bold;
}

.sa-codice-valurazione-red {
  background-color: red;
  color: white;
}

.sa-codice-valurazione-green {
  background-color: rgb(0, 255, 42);
  color: white;
}

.sa-codice-valurazione-yellow {
  background-color: rgb(255, 230, 0);
  color: black;
}

.vue-pdf-embed>div {
  margin-bottom: 8px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
}
</style>
