<template>
  <sa-page-layout
    :showModalLoading="showModalLoading"
    :pathResource="pathResource"
    :btnBackVisible="true"
    :btnSaveVisible="true"
    :linkback="linkback"
    :data="jsonData"
  >
    <template slot="toolbar-title">Nuovo Contatto</template>
    <template slot="table-body">
      <div class="sa-div-scroll sa-scroll">
        <br />
        <h5>Settore</h5>
        <hr />
        <b-row>
          <b-col>
            <b-input-group class="mb-2">
              <b-input-group-prepend>
                <b-button
                  class="float-sm-right"
                  variant="success btn-toolbar sa-margin-right"
                  size="sm"
                  title="Crea Settore"
                  v-b-modal.mdlNuovoSettore
                >
                  <b-icon icon="plus"></b-icon
                ></b-button>
              </b-input-group-prepend>
              <b-form-select
                id="sclSettore"
                v-model="jsonData.idSettore"
                :state="settori.length != 0 && jsonData.idSettore != null"
                :options="settori"
                value-field="id"
                text-field="descrizione"
              >
                <template #first>
                  <b-form-select-option disabled :value="null">
                    - Selezionare Settore -
                  </b-form-select-option>
                </template>
              </b-form-select>
            </b-input-group>
          </b-col>
        </b-row>

        <anagrafica-component :jsonData="jsonData"></anagrafica-component>
      </div>
    </template>
    <template slot="table-footer">
      <b-modal ref="mdlNuovoSettore" id="mdlNuovoSettore" title="Nuovo Settore">
        <b-row>
          <b-col cols="12">
            <label class="sa-form-label">Descrizione</label>
            <b-form-input v-model="settore.descrizione"></b-form-input>
          </b-col>
        </b-row>
        <template #modal-footer="{ ok }">
          <b-button size="sm" variant="outline-danger" @click="ok()"
            >Chiudi</b-button
          >
          <b-button
            size="sm"
            variant="outline-success"
            @click="onCreaSettore()"
          >
            Crea Settore
          </b-button>
        </template>
      </b-modal>
    </template>
  </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../template/layout/components/SaPageLayout.vue";
import UtilityMixins from "../../utility/UtilityMixin";
import AnagraficaComponent from "../../utility/components/AnagraficaComponent.vue";
import axios from "axios";
export default {
  mixins: [UtilityMixins],
  components: { SaPageLayout, AnagraficaComponent },
  props: {
    utente: {
      type: Object,
      default: function () {
        return { idUtente: null };
      },
    },
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.loadData();
    me.loadSettori();
  },
  data() {
    return {
      linkback: "/contatti",
      pathResource: "/anagraficacontatti",
      showModalLoading: false,
      regioniOptions: [],
      settori: [],
      settore: {},
      jsonData: {
        tipoDocumento: null,
        idSettore: null,
        nazione: "111100",
        tipoIdentificativo: "CF",
        sesso: null,
        provinciaNascitaSigla: null,
        comuneNascitaCodiceIstat: null,
        provinciaResidenzaSigla: null,
        comuneResidenzaCodiceIstat: null,
        provinciaDomicilioSigla: null,
        comuneDomicilioCodiceIstat: null,
      },
    };
  },
  methods: {
    loadData() {
      let me = this;
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        me.showModalLoading = true;
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
        axios
          .get(link + me.id)
          .then((response) => {
            me.jsonData = response.data.data;
            me.showModalLoading = false;
          })
          .catch(() => {
            me.showModalLoading = false;
          });
      }
    },
    loadSettori() {
      let me = this;
      let linkSettori = process.env.VUE_APP_PATH_API + "/settori";
      axios.get(linkSettori).then((response) => {
        me.settori = response.data.data;
        console.log(me.settori);
      });
    },
    onCreaSettore() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/settori";
      me.settore.idAnagrafica = me.utente.id;
      axios.post(link, me.settore).then((response) => {
        me.contatti = response.data.data;
        me.$refs["mdlNuovoSettore"].hide();
        this.$bvToast.toast("Creazione avvenuta con Successo", {
          title: "Creazione Settore",
          solid: true,
          variant: "success",
        });
        me.loadSettori();
      });
    },
  },
};
</script>

<style>
.sclSettore {
  margin-right: 1rem;
}
</style>
