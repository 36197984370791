<template>
    <sa-page-layout :btnBackVisible="true" :btnEditVisible="true" :linkback="linkback" :linkedit="linkedit" :pathResource="pathResource" :data="jsonData" :showmolalloading="showmolalloading">
        <template slot="header">
            <b-row>
                <b-col>
                    <label>Causale Documento</label>
                </b-col>
            </b-row>
        </template>
        <template slot="body">
            <div style="max-height: 18rem; overflow: auto">
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem; margin-top: 1rem">
                    <b-col sm="4" md="4" lg="4">
                        <fieldset class="sa-fieldset-intestazione">
                            <label>Codice Tipo Documento: </label>
                            <p>{{ jsonData.codiceTipoDocumento }}</p>
                            <hr />
                            <label>Codice Causale: </label>
                            <p>{{ jsonData.codiceCausale }}</p>
                            <hr />
                            <label>Soggetti Collegati: </label>
                            <p>{{ jsonData.soggettiCollegati }}</p>
                            <hr />
                        </fieldset>
                    </b-col>
                    <b-col sm="4" md="4" lg="4">
                        <fieldset class="sa-fieldset-intestazione">
                            <label>Descrizione Tipo Documento: </label>
                            <p>{{ jsonData.descrizioneCausale }}</p>
                            <hr />
                            <label>Operazione Magazzino: </label>
                            <p>{{ jsonData.operazioneMagazzino }}</p>
                            <hr />
                            <label>Documenti Collegati: </label>
                            <b-link href="#/tipidocumento"
                                ><p>{{ jsonData.documentiCollegati }}</p></b-link
                            >
                            <hr />
                        </fieldset>
                    </b-col>
                    <b-col sm="4" md="4" lg="4">
                        <fieldset class="sa-fieldset-intestazione">
                            <label>Operazione Contabile: </label>
                            <p>{{ jsonData.operazioneContabile }}</p>
                            <hr />
                            <label>Operazione Magazzino: </label>
                            <p>{{ jsonData.operazioneMagazzino }}</p>
                            <hr />
                        </fieldset>
                    </b-col>
                </b-row>
            </div>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
    components: { SaPageLayout },
    data() {
        return {
            pathResource: "/causalidocumento",
            linkedit: null,
            linkback: "/causalidocumento",
            id: "",
            showmolalloading: false,
            jsonData: {},
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.linkedit = "/causalidocumento/edit/" + me.id;
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            me.showmolalloading = true;
            axios
                .get(link + me.id)
                .then((response) => {
                    me.jsonData = response.data.data;
                    me.showmolalloading = false;
                })
                .catch(() => {
                    me.showmolalloading = false;
                });
        },
    },
};
</script>
