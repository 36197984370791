<template>
    <div class="sa-list-component">
        <div class="sa-list-component-header">
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </div>
        <div class="sa-list-component-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" stacked="lg" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
                    <template #cell(index)="row">
                        {{ row.index + 1 }}
                    </template>
                    <template v-slot:cell(actions)="row">
                        <b-container class="bv-example-row">
                            <b-button v-b-tooltip.hover size="sm" variant="outline-secondary" title="Visualizza Profilo Lis" @click="onView(row.item, row.index, $event.target)" class="mr-1 no-text">
                                <b-icon icon="eye" variant="outline-secondary"></b-icon>
                            </b-button>
                            <b-button v-b-tooltip.hover size="sm" variant="outline-secondary" title="Modifica Profilo Lis" @click="onEdit(row.item, row.index, $event.target)" class="mr-1 no-text">
                                <b-icon icon="pencil" variant="outline-secondary"></b-icon>
                            </b-button>
                            <b-button v-b-tooltip.hover size="sm" variant="outline-danger" title="Cancella Profilo Lis" @click="onDelete(row.item, row.index, $event.target)" class="mr-1 no-text">
                                <b-icon icon="trash" variant="danger"></b-icon>
                            </b-button>
                        </b-container>
                    </template>
                </b-table>
            </div>
        </div>
        <div class="sa-list-component-footer">
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import UtilityMixin from "../../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixin],
    data() {
        return {
            rows: 0,
            perPage: 50,
            currentPage: 1,
            pathResource: "/rsaprofililis",
            filtro: {},
            items: [],
            listKey: 0,
            fields: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Codice",
                    key: "codice",
                    sortable: true,
                    thStyle: "width: 10rem",
                },
                {
                    label: "Descrizione",
                    key: "descrizione",
                    sortable: true,
                },

                { class: "sa-table-column-action-double", key: "actions", label: "" },
            ],
        };
    },
    watch: {
        currentPage() {
            let me = this;
            me.loadData();
        },
    },
    methods: {
        loadData() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            if (sessionStorage["filtro"]) {
                me.filtro = JSON.parse(sessionStorage["filtro"]);
            }

            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.filtro.page = me.currentPage;
            me.filtro.forPage = me.perPage;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];
                    me.rows = response.data.data.recordsNumber;
                    me.items = response.data.data.list;
                    me.listKey++;
                    this.$emit("afterLoadData");
                })
                .catch(() => {
                    this.$emit("afterLoadData");
                });
        },
        onEdit(item) {
            let me = this;
            me.$router.push("/rsaconfigurazioneprofililis/edit/" + item.id);
        },
        onView(item) {
            let me = this;
            me.$router.push("/rsaconfigurazioneprofililis/view/" + item.id);
        },
        onDelete(item) {
            let me = this;
            this.$bvModal
                .msgBoxConfirm("Sicuro di voler Eliminare il profilo  " + item.descrizione)
                .then((value) => {
                    if (value) {
                        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                        axios.delete(link + item.id).then(() => {
                            me.loadData();
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
};
</script>
<style></style>
