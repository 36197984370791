<template>
    <div>
        <b-card class="sa-card" header="Dati Paziente" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.events.lblEventDate") }}</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataEvento) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblWeight") }}</label>
                    <span class="sa-data">{{ jsonData.peso }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHeight") }}</label>
                    <span class="sa-data">{{ jsonData.altezza }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBMI") }}</label>
                    <span class="sa-data">{{ jsonData.bmi }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBSA") }}</label>
                    <span class="sa-data">{{ jsonData.bsa }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.card.lblSystolicBloodPressure") }}</label>
                    <span class="sa-data"> {{ jsonData.pas }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.card.lblDiastolicBloodPressure") }}</label>
                    <span class="sa-data"> {{ jsonData.pad }}</span>
                </b-col>
                <b-col xs="12" sm="4" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblFC") }}</label>
                    <span class="sa-data">{{ jsonData.fc }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row align="center">
                <b-col xs="2" sm="2" md="2" lg="2" xl="2"> </b-col>
                <b-col class="ft-color-section-title">
                    <h5>{{ getLabelTraduora("patient.cardiology.lblCommonCarotid") }}</h5>
                </b-col>
                <b-col class="ft-color-section-title">
                    <h5>{{ getLabelTraduora("patient.cardiology.lblBulbCarotid") }}</h5>
                </b-col>
                <b-col class="ft-color-section-title">
                    <h5>{{ getLabelTraduora("patient.cardiology.lblInternalCarotid") }}</h5>
                </b-col>
                <b-col class="ft-color-section-title">
                    <h5>{{ getLabelTraduora("patient.cardiology.lblExternalCarotid") }}</h5>
                </b-col>
            </b-row>
            <!-- <b-row>
        <b-col class="ft-color-section-title" xs="2" sm="2" md="2" lg="2" xl="2">
          <h5>Score</h5>
        </b-col>
        <b-col class="ft-color-section">
          <b-row>
            <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
              <span class="sa-data">{{ jsonData.ccscoresn }}</span>
            </b-col>
            <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
              <span class="sa-data">{{ jsonData.ccscoredx }}</span>
            </b-col>
          </b-row>
        </b-col>
        <b-col class="ft-color-section">
          <b-row>
            <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
              <span class="sa-data">{{ jsonData.bscoresx }}</span>
            </b-col>
            <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
              <span class="sa-data">{{ jsonData.bscoredx }}</span>
            </b-col>
          </b-row>
        </b-col>
        <b-col class="ft-color-section">
          <b-row>
            <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
              <span class="sa-data">{{ jsonData.ciscoresn }}</span>
            </b-col>
            <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
              <span class="sa-data">{{ jsonData.ciscoredx }}</span>
            </b-col>
          </b-row>
        </b-col>
        <b-col class="ft-color-section">
          <b-row>
            <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
              <span class="sa-data">{{ jsonData.cescoresn }}</span>
            </b-col>
            <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
              <span class="sa-data">{{ jsonData.cescoredx }}</span>
            </b-col>
          </b-row>
        </b-col>
      </b-row> -->
            <!-- <b-row>
        <b-col class="ft-color-section-title" xs="2" sm="2" md="2" lg="2" xl="2">
          <h5>Color</h5>
        </b-col>
        <b-col class="ft-color-section">
          <b-row>
            <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
              <span class="sa-data">{{ jsonData.cccolsn }}</span>
            </b-col>
            <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
              <span class="sa-data">{{ jsonData.cccoldx }}</span>
            </b-col>
          </b-row>
        </b-col>
        <b-col class="ft-color-section">
          <b-row>
            <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
              <span class="sa-data">{{ jsonData.bcolsn }}</span>
            </b-col>
            <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
              <span class="sa-data">{{ jsonData.bcoldx }}</span>
            </b-col>
          </b-row>
        </b-col>
        <b-col class="ft-color-section">
          <b-row>
            <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
              <span class="sa-data">{{ jsonData.cicolsn }}</span>
            </b-col>
            <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
              <span class="sa-data">{{ jsonData.cicoldx }}</span>
            </b-col>
          </b-row>
        </b-col>
        <b-col class="ft-color-section">
          <b-row>
            <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
              <span class="sa-data">{{ jsonData.cecolsn }}</span>
            </b-col>
            <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
              <span class="sa-data">{{ jsonData.cecoldx }}</span>
            </b-col>
          </b-row>
        </b-col>
      </b-row> -->
            <b-row>
                <b-col class="ft-color-section-title" xs="2" sm="2" md="2" lg="2" xl="2">
                    <h5>{{ getLabelTraduora("patient.cardiology.lblMaxIMTNear") }}</h5>
                </b-col>
                <b-col class="ft-color-section">
                    <b-row>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                            <span class="sa-data">{{ jsonData.maximtnearccsx }}</span>
                        </b-col>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                            <span class="sa-data">{{ jsonData.maximtnearccdx }}</span>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="ft-color-section">
                    <b-row>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                            <span class="sa-data">{{ jsonData.maximtnearbsx }}</span>
                        </b-col>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                            <span class="sa-data">{{ jsonData.maximtnearbdx }}</span>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="ft-color-section">
                    <b-row>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                            <span class="sa-data">{{ jsonData.maximtnearcisx }}</span>
                        </b-col>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                            <span class="sa-data">{{ jsonData.maximtnearcidx }}</span>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="ft-color-section">
                    <b-row>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                            <span class="sa-data">{{ jsonData.maximtnearcesx }}</span>
                        </b-col>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                            <span class="sa-data">{{ jsonData.maximtnearcedx }}</span>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="ft-color-section-title" xs="2" sm="2" md="2" lg="2" xl="2">
                    <h5>{{ getLabelTraduora("patient.cardiology.lblMaxIMTFar") }}</h5>
                </b-col>
                <b-col class="ft-color-section">
                    <b-row>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                            <span class="sa-data">{{ jsonData.maximtfarccsx }}</span>
                        </b-col>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                            <span class="sa-data">{{ jsonData.maximtfarccdx }}</span>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="ft-color-section">
                    <b-row>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                            <span class="sa-data">{{ jsonData.maximtfarbsx }}</span>
                        </b-col>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                            <span class="sa-data">{{ jsonData.maximtfarbdx }}</span>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="ft-color-section">
                    <b-row>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                            <span class="sa-data">{{ jsonData.maximtfarcisx }}</span>
                        </b-col>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                            <span class="sa-data">{{ jsonData.maximtfarcidx }}</span>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="ft-color-section">
                    <b-row>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                            <span class="sa-data">{{ jsonData.maximtfarcesx }}</span>
                        </b-col>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                            <span class="sa-data">{{ jsonData.maximtfarcedx }}</span>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="ft-color-section-title" xs="2" sm="2" md="2" lg="2" xl="2">
                    <h5>{{ getLabelTraduora("patient.cardiology.lblMeanIMTNear") }}</h5>
                </b-col>
                <b-col class="ft-color-section">
                    <b-row>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                            <span class="sa-data">{{ jsonData.meanimtnearccsx }}</span>
                        </b-col>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                            <span class="sa-data">{{ jsonData.meanimtnearccdx }}</span>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="ft-color-section">
                    <b-row>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                            <span class="sa-data">{{ jsonData.meanimtnearbsx }}</span>
                        </b-col>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                            <span class="sa-data">{{ jsonData.meanimtnearbdx }}</span>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="ft-color-section">
                    <b-row>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                            <span class="sa-data">{{ jsonData.meanimtnearcisx }}</span>
                        </b-col>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                            <span class="sa-data">{{ jsonData.meanimtnearcidx }}</span>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="ft-color-section">
                    <b-row>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                            <span class="sa-data">{{ jsonData.meanimtnearcesx }}</span>
                        </b-col>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                            <span class="sa-data">{{ jsonData.meanimtnearcedx }}</span>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="ft-color-section-title" xs="2" sm="2" md="2" lg="2" xl="2">
                    <h5>{{ getLabelTraduora("patient.cardiology.lblMeanIMTFar") }}</h5>
                </b-col>
                <b-col class="ft-color-section">
                    <b-row>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                            <span class="sa-data">{{ jsonData.meanimtnearccsx }}</span>
                        </b-col>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                            <span class="sa-data">{{ jsonData.meanimtnearccdx }}</span>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="ft-color-section">
                    <b-row>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                            <span class="sa-data">{{ jsonData.meanimtnearbsx }}</span>
                        </b-col>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                            <span class="sa-data">{{ jsonData.meanimtnearbdx }}</span>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="ft-color-section">
                    <b-row>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                            <span class="sa-data">{{ jsonData.meanimtnearcisx }}</span>
                        </b-col>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                            <span class="sa-data">{{ jsonData.meanimtnearcidx }}</span>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="ft-color-section">
                    <b-row>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                            <span class="sa-data">{{ jsonData.meanimtnearcesx }}</span>
                        </b-col>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                            <span class="sa-data">{{ jsonData.meanimtnearcedx }}</span>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="ft-color-section-title" xs="2" sm="2" md="2" lg="2" xl="2">
                    <h5>PSV (cm/s)</h5>
                </b-col>
                <b-col class="ft-color-section">
                    <b-row>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                            <span class="sa-data">{{ jsonData.ccpsvsn }}</span>
                        </b-col>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                            <span class="sa-data">{{ jsonData.ccpsvdx }}</span>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="ft-color-section">
                    <b-row>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                            <span class="sa-data">{{ jsonData.bpsvsx }}</span>
                        </b-col>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                            <span class="sa-data">{{ jsonData.bpsvdx }}</span>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="ft-color-section">
                    <b-row>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                            <span class="sa-data">{{ jsonData.cipsvsn }}</span>
                        </b-col>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                            <span class="sa-data">{{ jsonData.cipsvdx }}</span>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="ft-color-section">
                    <b-row>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                            <span class="sa-data">{{ jsonData.cepsvsn }}</span>
                        </b-col>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                            <span class="sa-data">{{ jsonData.cepsvdx }}</span>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="ft-color-section-title" xs="2" sm="2" md="2" lg="2" xl="2">
                    <h5>EDV (cm/s)</h5>
                </b-col>
                <b-col class="ft-color-section">
                    <b-row>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                            <span class="sa-data">{{ jsonData.ccedvsn }}</span>
                        </b-col>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                            <span class="sa-data">{{ jsonData.ccedvdx }}</span>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="ft-color-section">
                    <b-row>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                            <span class="sa-data">{{ jsonData.bedvsn }}</span>
                        </b-col>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                            <span class="sa-data">{{ jsonData.bedvdx }}</span>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="ft-color-section">
                    <b-row>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                            <span class="sa-data">{{ jsonData.ciedvsn }}</span>
                        </b-col>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                            <span class="sa-data">{{ jsonData.ciedvdx }}</span>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="ft-color-section">
                    <b-row>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                            <span class="sa-data">{{ jsonData.ceedvsn }}</span>
                        </b-col>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                            <span class="sa-data">{{ jsonData.ceedvdx }}</span>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row align="center">
                <b-col xs="2" sm="2" md="2" lg="2" xl="2"> </b-col>
                <b-col class="ft-color-section-title">
                    <h5>{{ getLabelTraduora("patient.cardiology.lblCommonCarotid") }}</h5>
                </b-col>
                <b-col class="ft-color-section-title">
                    <h5>{{ getLabelTraduora("patient.cardiology.lblBulbCarotid") }}</h5>
                </b-col>
                <b-col class="ft-color-section-title">
                    <h5>{{ getLabelTraduora("patient.cardiology.lblInternalCarotid") }}</h5>
                </b-col>
                <b-col class="ft-color-section-title">
                    <h5>{{ getLabelTraduora("patient.cardiology.lblExternalCarotid") }}</h5>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="ft-color-section-title" xs="2" sm="2" md="2" lg="2" xl="2">
                    <h5>{{ getLabelTraduora("patient.cardiology.lblStenosysRange") }}</h5>
                </b-col>
                <b-col class="ft-color-section">
                    <b-row>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                            <span class="sa-data">{{ jsonData.rangeStenosiCcSn }}</span>
                        </b-col>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                            <span class="sa-data">{{ jsonData.rangeStenosiCcDx }}</span>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="ft-color-section">
                    <b-row>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                            <span class="sa-data">{{ jsonData.rangeStenosiBSn }}</span>
                        </b-col>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                            <span class="sa-data">{{ jsonData.rangeStenosiBDx }}</span>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="ft-color-section">
                    <b-row>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                            <span class="sa-data">{{ jsonData.rangeStenosiCiSn }}</span>
                        </b-col>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                            <span class="sa-data">{{ jsonData.rangeStenosiCiDx }}</span>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="ft-color-section">
                    <b-row>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                            <span class="sa-data">{{ jsonData.rangeStenosiCeSn }}</span>
                        </b-col>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                            <span class="sa-data">{{ jsonData.rangeStenosiCeDx }}</span>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="ft-color-section-title" xs="2" sm="2" md="2" lg="2" xl="2">
                    <h5>{{ getLabelTraduora("patient.cardiology.lblEcogenGW") }}</h5>
                </b-col>
                <b-col class="ft-color-section">
                    <b-row>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                            <span class="sa-data">{{ jsonData.ecogenCcSn }}</span>
                        </b-col>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                            <span class="sa-data">{{ jsonData.ecogenCcDx }}</span>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="ft-color-section">
                    <b-row>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                            <span class="sa-data">{{ jsonData.ecogenBSn }}</span>
                        </b-col>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                            <span class="sa-data">{{ jsonData.ecogenBDx }}</span>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="ft-color-section">
                    <b-row>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                            <span class="sa-data">{{ jsonData.ecogenCiSn }}</span>
                        </b-col>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                            <span class="sa-data">{{ jsonData.ecogenCiDx }}</span>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="ft-color-section">
                    <b-row>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                            <span class="sa-data">{{ jsonData.ecogenCeSn }}</span>
                        </b-col>
                        <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                            <span class="sa-data">{{ jsonData.ecogenCeDx }}</span>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblReportNotes')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNotes") }}</label>

                    <span class="sa-data">{{ jsonData.noteLibere }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblComments") }}</label>

                    <span class="sa-data">{{ jsonData.commento }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblCardiovascularRiskFactors')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="4" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHypertension") }}</label>
                    <span :class="setColor(jsonData.ipertensione)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.ipertensione)"></b-icon>
                    </span>
                </b-col>
                <b-col xs="12" sm="4" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDyslipidemia") }}</label>
                    <span :class="setColor(jsonData.dislipidemia)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.dislipidemia)"></b-icon>
                    </span>
                </b-col>
                <b-col xs="12" sm="4" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDiabetes") }}</label>
                    <span :class="setColor(jsonData.diabete)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.diabete)"></b-icon>
                    </span>
                </b-col>
                <b-col xs="12" sm="4" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblObesity") }}</label>
                    <span :class="setColor(jsonData.obesita)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.obesita)"></b-icon>
                    </span>
                </b-col>
                <b-col xs="12" sm="4" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSmoking") }}</label>
                    <span :class="setColor(jsonData.fumo)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.fumo)"></b-icon>
                    </span>
                </b-col>
                <b-col xs="12" sm="4" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCerebrovascularAccidentsFamiliarity") }}</label>
                    <span :class="setColor(jsonData.familCerebrovascolari)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.familCerebrovascolari)"></b-icon>
                    </span>
                </b-col>
                <b-col xs="12" sm="4" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIschemicHeartDiseaseFamiliarity") }}</label>
                    <span :class="setColor(jsonData.familCardiopatiaIsch)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.familCardiopatiaIsch)"></b-icon>
                    </span>
                </b-col>
                <b-col xs="12" sm="4" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHypertensionFamiliarity") }}</label>
                    <span :class="setColor(jsonData.familIpertensione)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.familIpertensione)"></b-icon>
                    </span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblConclusions')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="4" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNormExamination") }}</label>
                    <span :class="setColor(jsonData.esameNellaNorma)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.esameNellaNorma)"></b-icon>
                    </span>
                </b-col>
                <b-col xs="12" sm="4" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMildAtherosclerosis") }}</label>
                    <span :class="setColor(jsonData.aterosclerosiLieve)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.aterosclerosiLieve)"></b-icon>
                    </span>
                </b-col>
                <b-col xs="12" sm="4" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblModerateAtherosclerosis") }}</label>
                    <span :class="setColor(jsonData.aterosclerosiModerata)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.aterosclerosiModerata)"></b-icon>
                    </span>
                </b-col>
                <b-col xs="12" sm="4" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSevereAtherosclerosis") }}</label>
                    <span :class="setColor(aterosclerosiSevera)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(aterosclerosiSevera)"></b-icon>
                    </span>
                </b-col>
                <b-col xs="12" sm="4" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCommonCarotidStenosisRight") }}</label>
                    <span :class="setColor(jsonData.stenosiCcDx)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.stenosiCcDx)"></b-icon>
                    </span>
                </b-col>
                <b-col xs="12" sm="4" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCommonCarotidStenosisLeft") }}</label>
                    <span :class="setColor(jsonData.stenosiCcSx)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.stenosiCcSx)"></b-icon>
                    </span>
                </b-col>
                <b-col xs="12" sm="4" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBulbCarotidStenosisRight") }}</label>
                    <span :class="setColor(jsonData.stenosiCeDx)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.stenosiCeDx)"></b-icon>
                    </span>
                </b-col>
                <b-col xs="12" sm="4" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBulbCarotidStenosisLeft") }}</label>
                    <span :class="setColor(jsonData.stenosiCeSx)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.stenosiCeSx)"></b-icon>
                    </span>
                </b-col>
                <b-col xs="12" sm="4" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblInternalCarotidStenosisRight") }}</label>
                    <span :class="setColor(jsonData.stenosiCiDx)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.stenosiCiDx)"></b-icon>
                    </span>
                </b-col>
                <b-col xs="12" sm="4" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblInternalCarotidStenosisLeft") }}</label>
                    <span :class="setColor(jsonData.stenosiCiSx)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.stenosiCiSx)"></b-icon>
                    </span>
                </b-col>
                <b-col xs="12" sm="4" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSignificantNon-hemodynamicAtherosclerosis ") }}</label>
                    <span :class="setColor(aterosclerosiNonEmoSign)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(aterosclerosiNonEmoSign)"></b-icon>
                    </span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Conclusioni</label>
                    <span class="sa-data">{{ jsonData.conclusioni }}</span>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import axios from "axios";
import UtilityMixins from "../../../../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixins],
    props: {},
    components: {},
    data() {
        return {
            pathResource: "/cardioecocarotidi",
            jsonData: {},
            id: null,
            aterosclerosiNonEmoSign: false,
            aterosclerosiSevera: false,
            fontScale: 2,
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    computed: {
        isFirmato() {
            let me = this;
            let isFirmato = !me.jsonData.firma;
            console.log(isFirmato, "FIRMA");
            me.$emit("updateStatoFirma", isFirmato);
            return !me.jsonData.firma;
        },
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                axios.get(link).then((response) => {
                    me.jsonData = response.data.data;
                    me.linkPrintData = "/pazienteeventi/print/" + me.jsonData.id;
                    me.checkNonEmoSignOnLoad();
                    me.checkSeveraOnLoad();
                    me.showModalLoading = false;
                });
            }
        },
        setColor(value) {
            let color = value == "true" ? "text-success" : "text-danger";
            return color;
        },
        setIcon(value) {
            let icon = value === "true" ? "check" : "x";
            return icon;
        },
        onFirmaUpdate() {
            let me = this;
            me.loadData();
        },
        checkNonEmoSignOnLoad() {
            let me = this;
            if (me.jsonData.aterosclerosiNonEmoSign === "S") {
                me.aterosclerosiNonEmoSign = "true";
            } else {
                me.aterosclerosiNonEmoSign = "false";
            }
        },
        checkSeveraOnLoad() {
            let me = this;
            if (me.jsonData.aterosclerosiSevera === "S") {
                me.aterosclerosiSevera = "true";
            } else {
                me.aterosclerosiSevera = "false";
            }
        },
    },
};
</script>

<style></style>
