<template>
    <div class="sa-tab-scroll">
        <b-card v-if="isAlimentazione" class="sa-card sa-card-border" header="ALIMENTAZIONE" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaAlimentazione" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header"> </b-table>
        </b-card>
        <b-card v-if="isBagnoDoccia" class="sa-card sa-card-border" header="BAGNO DOCCIA" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaBagnoDoccia" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header"> </b-table>
        </b-card>
        <b-card v-if="isAbbigliamento" class="sa-card sa-card-border" header="ABBIGLIAMENTO" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaAbbigliamento" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header"> </b-table>
        </b-card>
        <b-card v-if="isContinenzaIntestinale" class="sa-card sa-card-border" header="CONTINENZA INTESTINALE" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaContinenzaIntestinale" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header"> </b-table>
        </b-card>
        <b-card v-if="isContinenzaUrinaria" class="sa-card sa-card-border" header="CONTINENZA URINARIA" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaContinenzaUrinaria" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header"> </b-table>
        </b-card>
        <b-card v-if="isUsoGabinetto" class="sa-card sa-card-border" header="USO DEL GABINETTO" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaUsoGambinetto" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header"> </b-table>
        </b-card>
        <b-card v-if="isTrasferimentoLettoSediaCarrozzina" class="sa-card sa-card-border" header="TRASFERIMENTO LETTO-SEDIA/CARROZZINA (COMPILARE ANCHE IN CASO DI PAZIENTE TOTALMENTE ALLETTATO)" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaTrasferimentoLettoSediaCarrozzina" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header"> </b-table>
        </b-card>
        <b-card v-if="isDeambulazione" class="sa-card sa-card-border" header="DEAMBULAZIONE (FUNZIONALMENTE VALIDA)" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaDeambulazione" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header"> </b-table>
        </b-card>
        <b-card v-if="isUsoCarrozzina" class="sa-card sa-card-border" header="USO DELLA CARROZZINA" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaUsoCarrozzina" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header"> </b-table>
        </b-card>
        <b-card v-if="isScale" class="sa-card sa-card-border" header="SCALE" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaScale" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header"> </b-table>
        </b-card>
        <b-card class="sa-card sa-card-border" header="Totale" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <span class="sa-label-data">{{ totale }}</span>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import axios from "axios";
export default {
    components: {},
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    watch: {},
    data() {
        return {
            idAccettazione: null,
            keyPropostaAccesso: 0,
            linkback: "",
            linkPrintData: "",
            childKey: 0,
            viewPai: true,
            pathResource: "/rsascalabarthelrichieste",
            totale: 0,
            isAbbigliamento: false,
            isAlimentazione: false,
            isBagnoDoccia: false,
            isContinenzaIntestinale: false,
            isContinenzaUrinaria: false,
            isDeambulazione: false,
            isScale: false,
            isTrasferimentoLettoSediaCarrozzina: false,
            isUsoCarrozzina: false,
            isUsoGabinetto: false,
            jsonData: [],
            listaAlimentazione: [],
            listaBagnoDoccia: [],
            listaAbbigliamento: [],
            listaContinenzaIntestinale: [],
            listaContinenzaUrinaria: [],
            listaUsoGambinetto: [],
            listaContinuitaPasso: [],
            listaTrasferimentoLettoSediaCarrozzina: [],
            listaDeambulazione: [],
            listaUsoCarrozzina: [],
            listaScale: [],
            fieldsListe: [
                {
                    label: "Descrizione",
                    key: "fattore",
                    sortable: true,
                },
                /*   {
                    label: "",
                    key: "check",
                    sortable: true,
                    thStyle: "width: 10rem",
                    tdClass: "text-center sa-column-widh",
                }, */
                // {
                //   label: "",
                //   key: "action",
                //   thStyle: "width: 10rem",
                // },
            ],
        };
    },
    mounted() {
        let me = this;
        if (this.$route.params.id) {
            me.idAccettazione = this.$route.params.id;
        } else {
            me.idAccettazione = this.$route.query.idPaziente;
        }
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/richiesta/" + me.idAccettazione;
            axios
                .get(link)
                .then((response) => {
                    me.jsonData = response.data.data.list;
                    me.calcolaPunteggio();

                    me.jsonData.forEach((element) => {
                        if (element.check) {
                            me.jsonData.push({ idRichiesta: me.idAccettazione, idScalaBarthel: element.id });
                            switch (element.gruppo) {
                                case "ALIMENTAZIONE":
                                    me.isAlimentazione = true;
                                    me.listaAlimentazione.push(element);
                                    break;
                                case "BAGNO DOCCIA":
                                    me.isBagnoDoccia = true;
                                    me.listaBagnoDoccia.push(element);
                                    break;
                                case "ABBIGLIAMENTO":
                                    me.isAbbigliamento = true;
                                    me.listaAbbigliamento.push(element);
                                    break;
                                case "CONTINENZA INTESTINALE":
                                    me.isContinenzaIntestinale = true;
                                    me.listaContinenzaIntestinale.push(element);
                                    break;
                                case "CONTINENZA URINARIA":
                                    me.isContinenzaUrinaria = true;
                                    me.listaContinenzaUrinaria.push(element);
                                    break;
                                case "USO DEL GABINETTO":
                                    me.isUsoGabinetto = true;
                                    me.listaUsoGambinetto.push(element);
                                    break;
                                case "TRASFERIMENTO LETTO-SEDIA/CARROZZINA":
                                    me.isTrasferimentoLettoSediaCarrozzina = true;
                                    me.listaTrasferimentoLettoSediaCarrozzina.push(element);
                                    break;
                                case "DEAMBULAZIONE (FUNZIONALMENTE VALIDA)":
                                    me.isDeambulazione = true;
                                    me.listaDeambulazione.push(element);
                                    break;
                                case "USO DELLA CARROZZINA":
                                    me.isUsoCarrozzina = true;
                                    me.listaUsoCarrozzina.push(element);
                                    break;
                                case "SCALE":
                                    me.isScale = true;
                                    me.listaScale.push(element);
                                    break;
                            }
                        }
                    });
                })
                .catch(() => {});
        },
        /*  unisciArray() {
            let me = this;
            me.jsonData = [];
            me.jsonData = me.listaAlimentazione.concat(me.listaBagnoDoccia).concat(me.listaAbbigliamento).concat(me.listaContinenzaIntestinale).concat(me.listaContinenzaUrinaria).concat(me.listaUsoGambinetto).concat(me.listaContinuitaPasso).concat(me.listaTrasferimentoLettoSediaCarrozzina).concat(me.listaDeambulazione).concat(me.listaUsoCarrozzina).concat(me.listaScale);

            let lista = [];

            me.jsonData.forEach((element) => {
                if (element.check) {
                    lista.push({ idRichiesta: me.idAccettazione, idScalaBarthel: element.id });
                }
            });
            me.calcolaPunteggio();
            //   console.log(lista);
            this.$emit("update", lista);
        }, */
        calcolaPunteggio() {
            let me = this;
            me.totale = 0;

            me.jsonData.forEach((element) => {
                if (element.check) {
                    let p = element.punteggio >= 0 ? element.punteggio : element.punteggio * -1;
                    me.totale = me.totale + p;
                }
            });
        },

        setListe() {},
    },
};
</script>

<style></style>
