<template>
    <sa-page-layout :btnSaveVisible="true" :btnAnnullaVisible="true" :linkback="linkback" :data="jsonData" :showModalLoading="showModalLoading" :pathResource="pathResource" class="sa-no-space">
        <template slot="table-body">
            <rsa-gestione-ospiti-edit-component ref="RsaGestioneOspitiEditComponent" :showModalLoading="showModalLoading" @update="onUpdateJsonData" @beforeLoadData="onBeforeLoadData" @afterLoadData="onAfterLoadData"></rsa-gestione-ospiti-edit-component>
        </template>
    </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import RsaGestioneOspitiEditComponent from "../components/RsaGestioneOspitiEditComponent.vue";
export default {
    components: { SaPageLayout, RsaGestioneOspitiEditComponent },
    data() {
        return {
            showModalLoading: false,
            pathResource: "/accettazioni",
            linkback: "/rsagestioneospiti",
            jsonData: {},
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
    },
    methods: {
        onUpdateJsonData(data) {
            let me = this;
            console.log(data);
            me.jsonData = data;
        },
        onBeforeLoadData() { },
        onAfterLoadData() { },
    },

};
</script>

<style></style>
