<template>
    <div>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card sa-card-border" header="Sistema Nervoso" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Stato di coscienza nel tempo</label>
                            <b-form-select v-model="jsonDataSistemaNervoso.statoCoscienzaNelTempo" :options="statoCoscienzaNelTempoOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Stato di coscienza nello spazio</label>
                            <b-form-select v-model="jsonDataSistemaNervoso.statoCoscienzaNelloSpazio" :options="statoCoscienzaNelloSpazioOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Attenzione</label>
                            <b-form-select v-model="jsonDataSistemaNervoso.attenzione" :options="attenzioneOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Linguaggio</label>
                            <b-form-select v-model="jsonDataSistemaNervoso.linguaggio" :options="linguaggioOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Memoria a breve termine</label>
                            <b-form-select v-model="jsonDataSistemaNervoso.memoriaBreveTermine" :options="memoriaBreveTermineOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Memoria a lungo termine</label>
                            <b-form-select v-model="jsonDataSistemaNervoso.memoriaLungoTermine" :options="memoriaLungoTermineOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Note</label>
                            <b-form-textarea v-model="jsonDataSistemaNervoso.noteSistemaNervoso" :readonly="isReadOnly" max-rows="10" rows="8"> </b-form-textarea>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import UtilityMixin from "../../../utility/UtilityMixin";

export default {
    props: {
        isReadOnly: {
            type: Boolean,
            default: false,
        },
        idAccettazione: {
            type: String,
        },
    },
    mounted() {
        //console.log("Mounted Sistema Nervoso, idAccettazione:", this.idAccettazione);
    },
    mixins: [UtilityMixin],
    data() {
        return {
            pathResourceSchedaEsameObiettivoGeneraleSistemaNervoso: "/rsaschedaesameobiettivogeneralesistemanervoso",
            jsonDataSistemaNervoso: {
                id: null,
                idAccettazione: this.idAccettazione,
                statoCoscienzaNelTempo: null,
                statoCoscienzaNelloSpazio: null,
                attenzione: null,
                linguaggio: null,
                memoriaBreveTermine: null,
                memoriaLungoTermine: null,
                noteSistemaNervoso: null,
            },

            statoCoscienzaNelTempoOptions: [
                { text: "BEN ORIENTATO NEL TEMPO", value: "BEN ORIENTATO NEL TEMPO" },
                { text: "DISORIENTATO NEL TEMPO", value: "DISORIENTATO NEL TEMPO" },
            ],
            statoCoscienzaNelloSpazioOptions: [
                { text: "BEN ORIENTATO NELLO SPAZIO", value: "BEN ORIENTATO NELLO SPAZIO" },
                { text: "DISORIENTATO NELLO SPAZIO", value: "DISORIENTATO NELLO SPAZIO" },
            ],
            attenzioneOptions: [
                { text: "CONSERVATA", value: "CONSERVATA" },
                { text: "RIDOTTA", value: "RIDOTTA" },
            ],
            linguaggioOptions: [
                { text: "ADEGUATO", value: "ADEGUATO" },
                { text: "ALTERATO", value: "ALTERATO" },
                { text: "DISARTRICO", value: "DISARTRICO" },
                { text: "AFASICO", value: "AFASICO" },
            ],
            memoriaBreveTermineOptions: [
                { text: "CONSERVATA", value: "CONSERVATA" },
                { text: "RIDOTTA", value: "RIDOTTA" },
            ],
            memoriaLungoTermineOptions: [
                { text: "CONSERVATA", value: "CONSERVATA" },
                { text: "RIDOTTA", value: "RIDOTTA" },
            ],
        };
    },
    methods: {
        loadSistemaNervosoData() {
            let me = this;
            //if (!me.idAccettazione) return;
            // console.log("ID accettazione nel loadSistemaNervoso: " + me.idAccettazione);

            UtilityMixin.methods.loadDataResources(me.pathResourceSchedaEsameObiettivoGeneraleSistemaNervoso + "/accettazione/" + me.idAccettazione, {}, me.resultResponseSchedaEsameObiettivoGeneraleSistemaNervosoSuccess, me.resultResponseSchedaEsameObiettivoGeneraleSistemaNervosoError);
        },

        resultResponseSchedaEsameObiettivoGeneraleSistemaNervosoSuccess(response) {
            let me = this;
            me.jsonDataSistemaNervoso = response.data.data;
            //me.$emit("onAfterLoadData");
            // console.log("ID del record SISTEMA NERVOSO:", me.jsonDataSistemaNervoso.id);
        },

        resultResponseSchedaEsameObiettivoGeneraleSistemaNervosoError(error) {
            let me = this;
            if (error.response && error.response.status === 404) {
                // //console.log("NESSUN record trovato del SISTEMA NERVOSO per questo id accettazione");
                me.resetForm();
            } else {
                // console.error("Errore nel caricamento dati del sistema nervoso:", error);
            }
        },

        resetForm() {
            let me = this;
            me.jsonDataSistemaNervoso = {
                id: null,
                idAccettazione: me.idAccettazione,
                statoCoscienzaNelTempo: null,
                statoCoscienzaNelloSpazio: null,
                attenzione: null,
                linguaggio: null,
                memoriaBreveTermine: null,
                memoriaLungoTermine: null,
                noteSistemaNervoso: null,
            };
        },

        onSalvaSistemaNervoso() {
            let me = this;
            me.jsonDataSistemaNervoso.idAccettazione = me.idAccettazione;
            //console.log("Salvataggio sistema nervoso con dati:", JSON.stringify(me.jsonDataSistemaNervoso));
            if (!me.jsonDataSistemaNervoso.idAccettazione) {
                // console.log("idAccettazione: ", me.jsonDataSistemaNervoso.idAccettazione);
                me.$bvToast.toast("Errore: ID Accettazione mancante", {
                    title: "Errore",
                    variant: "danger",
                    solid: true,
                });
                return;
            }
            UtilityMixin.methods.saveResource(me.pathResourceSchedaEsameObiettivoGeneraleSistemaNervoso, me.jsonDataSistemaNervoso, me.saveSchedaEsameObiettivoGeneraleSistemaNervosoSuccessResponse, me.saveSchedaEsameObiettivoGeneraleSistemaNervosoErrorResponse);
        },
        saveSchedaEsameObiettivoGeneraleSistemaNervosoSuccessResponse() {
            //console.log("SISTEMA NERVOSO salvato con successo:", response);
        },
        saveSchedaEsameObiettivoGeneraleSistemaNervosoErrorResponse() {
            console.error("Errore nel salvataggio sistema nervoso:");
        },
    },
};
</script>
