<template>
    <sa-page-layout :btnSaveVisible="true" :btnAnnullaVisible="true" :linkback="linkback" :pathResource="pathResource" :data="jsonData" :showModalLoading="showModalLoading" class="sa-no-space">
        <template slot="toolbar-title">
            <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente" @getEtaPaziente="getEtaPaziente"></paziente-anagrafica>
        </template>
        <template slot="table-body">
            <div class="sa-tab-scroll">
                <b-row>
                    <b-col cols="12" xs="12" sm="9" md="9" lg="9" xl="9">
                        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
                            <b-row>
                                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                                    <label class="sa-label-data">
                                        {{ getLabelTraduora("patient.diabetology.lblHeight") }}
                                        <span style="color: red">*</span>
                                    </label>
                                    <b-form-input v-model="jsonData.altezza" type="number" @input="onCalcoloBmi(jsonData.altezza, jsonData.peso)" required :state="jsonData.altezza !== null ? true : false"></b-form-input>
                                </b-col>
                                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                                    <label class="sa-label-data">{{ getLabelTraduora("patient.diabetology.lblWeight") }}</label>
                                    <b-form-input v-model="jsonData.peso" type="number" @input="onCalcoloBmi(jsonData.altezza, jsonData.peso)"></b-form-input>
                                </b-col>
                                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                                    <label class="sa-label-data">B.M.I</label>
                                    <b-form-input v-model="jsonData.bmi" type="number" readonly></b-form-input>
                                </b-col>
                                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                                    <label class="sa-label-data">{{ getLabelTraduora("patient.diabetology.lblAbdominalCircumference") }}</label>
                                    <b-form-input v-model="jsonData.circonferenzaAddominale" type="number"></b-form-input>
                                </b-col>
                            </b-row>
                        </b-card>
                        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
                            <b-row>
                                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                    <label class="sa-label-data">Spo2</label>
                                    <div>
                                        <b-input-group class="mb-3">
                                            <b-form-input v-model="jsonData.spo2"></b-form-input>
                                            <b-input-group-append>
                                                <b-button size="sm" text="Button" variant="secondary" v-b-modal.modalSpo2><b-icon icon="search"></b-icon></b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </div>
                                </b-col>
                                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                    <label class="sa-label-data">{{ getLabelTraduora("patient.diabetology.lblSystolicPressure") }}</label>
                                    <b-input-group class="mb-3">
                                        <b-form-input v-model="jsonData.pressioneSistolica"></b-form-input>
                                        <b-input-group-append>
                                            <b-button size="sm" text="Button" variant="secondary" v-b-modal.modalPressione><b-icon icon="search"></b-icon></b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-col>
                                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                    <label class="sa-label-data">{{ getLabelTraduora("patient.diabetology.lblDiastolicBloodPressure") }}</label>
                                    <b-input-group class="mb-3">
                                        <b-form-input v-model="jsonData.pressioneDiastolica"></b-form-input>
                                    </b-input-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                    <label class="sa-label-data">{{ getLabelTraduora("patient.diabetology.lblHeartRate") }}</label>
                                    <b-input-group class="mb-3">
                                        <b-form-input v-model="jsonData.frequenzaCardiaca"></b-form-input>
                                        <b-input-group-append>
                                            <b-button size="sm" text="Button" variant="secondary" v-b-modal.modalFrequenza><b-icon icon="search"></b-icon></b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-col>
                                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                    <label class="sa-label-data">{{ getLabelTraduora("patient.diabetology.lblECG") }}</label>
                                    <b-input-group class="mb-3">
                                        <b-form-input v-model="jsonData.ecg"></b-form-input>
                                        <b-input-group-append>
                                            <b-button size="sm" text="Button" variant="secondary" v-b-modal.modalEcg><b-icon icon="search"></b-icon></b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-col>
                                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                    <label class="sa-label-data">{{ getLabelTraduora("patient.diabetology.lblGlucose") }}</label>
                                    <b-input-group class="mb-3">
                                        <b-form-input v-model="jsonData.glucosio"></b-form-input>
                                        <b-input-group-append>
                                            <b-button size="sm" text="Button" variant="secondary" v-b-modal.modalGlucosio><b-icon icon="search"></b-icon></b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-col>
                            </b-row>
                        </b-card>
                        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
                            <b-row>
                                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                    <label class="sa-label-data">{{ getLabelTraduora("patient.diabetology.lblSkinAndAnnexes") }}</label>
                                    <b-form-textarea v-model="jsonData.cuteEdAnnessi" no-resize></b-form-textarea>
                                </b-col>
                                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                    <label class="sa-label-data">{{ getLabelTraduora("patient.diabetology.lblRespiratorySystem") }}</label>
                                    <b-form-textarea v-model="jsonData.apparatoRespiratorio" no-resize></b-form-textarea>
                                </b-col>
                                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                    <label class="sa-label-data">{{ getLabelTraduora("patient.diabetology.lblDigestiveSystem") }}</label>
                                    <b-form-textarea v-model="jsonData.apparatoDigerente" no-resize></b-form-textarea>
                                </b-col>
                            </b-row>
                        </b-card>
                        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
                            <b-row>
                                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                    <label class="sa-label-data">{{ getLabelTraduora("patients.lblUrogenitalApparatus") }}</label>
                                    <b-form-textarea v-model="jsonData.apparatoUrogenitale" no-resize></b-form-textarea>
                                </b-col>
                                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                    <label class="sa-label-data">{{ getLabelTraduora("patient.diabetology.lblArticularMuscleSystem") }}</label>
                                    <b-form-textarea v-model="jsonData.apparatoMuscoloArticolare" no-resize></b-form-textarea>
                                </b-col>
                                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                    <label class="sa-label-data">{{ getLabelTraduora("patients.diabetology.lblCardiovascularSystem") }}</label>
                                    <b-form-textarea v-model="jsonData.sistemaCardiovascolare" no-resize></b-form-textarea>
                                </b-col>
                            </b-row>
                        </b-card>
                        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
                            <b-row>
                                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                                    <label class="sa-label-data">{{ getLabelTraduora("patients.lblNervousSystem") }}</label>
                                    <b-form-textarea v-model="jsonData.sistemaNervoso" no-resize></b-form-textarea>
                                </b-col>
                                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                                    <label class="sa-label-data">{{ getLabelTraduora("patients.lblFoot") }}</label>
                                    <b-form-textarea v-model="jsonData.piede" no-resize></b-form-textarea>
                                </b-col>
                                <b-col xs="12" sm="12" md="3" lg="3" xl="43">
                                    <label class="sa-label-data">{{ getLabelTraduora("patients.lblSubcutaneous") }}</label>
                                    <b-form-textarea v-model="jsonData.sottocutaneo" no-resize></b-form-textarea>
                                </b-col>
                                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                                    <label class="sa-label-data">{{ getLabelTraduora("patients.lblMouthTeeth") }}</label>
                                    <b-form-textarea v-model="jsonData.boccaDenti" no-resize></b-form-textarea>
                                </b-col>
                            </b-row>
                        </b-card>
                        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
                            <b-row :componentKey="checkMalattie">
                                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                    <label class="sa-label-data">{{ getLabelTraduora("patients.lblPatientAge") }}</label>
                                    <b-form-input v-model="jsonData.eta" type="text" disabled></b-form-input>
                                </b-col>
                                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                    <label class="sa-label-data">{{ getLabelTraduora("patient.lblCigarette") }}</label>
                                    <b-form-checkbox v-model="jsonData.fumatore" :checked="jsonData.fumatore" name="check-button" size="lg"></b-form-checkbox>
                                </b-col>
                                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                    <label class="sa-label-data">{{ getLabelTraduora("patient.lblAlcohol") }}</label>
                                    <b-form-checkbox v-model="jsonData.alcool" :checked="jsonData.alcool" name="check-button" size="lg"></b-form-checkbox>
                                </b-col>
                                <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblStrokeTransientIschemia") }}</label>
                                    <b-form-checkbox v-model="jsonData.ictusIschemiaTransitoria" :checked="jsonData.ictusIschemiaTransitoria"></b-form-checkbox>
                                </b-col>
                                <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblHypertension") }}</label>
                                    <b-form-checkbox v-model="jsonData.ipertensione" :checked="jsonData.ipertensione"></b-form-checkbox>
                                </b-col>
                                <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblChronicKidneyDisease") }}</label>
                                    <b-form-checkbox v-model="jsonData.malattiaRenaleCronica" :checked="jsonData.malattiaRenaleCronica"></b-form-checkbox>
                                </b-col>
                                <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblHeartDiseaseChronic") }}</label>
                                    <b-form-checkbox v-model="jsonData.cardiopatiaCronica" :checked="jsonData.cardiopatiaCronica"></b-form-checkbox>
                                </b-col>
                                <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblDiabetes") }}</label>
                                    <b-form-checkbox v-model="jsonData.diabete" :checked="jsonData.diabete"></b-form-checkbox>
                                </b-col>
                                <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblHypertensionTreatment") }}</label>
                                    <b-form-checkbox v-model="jsonData.trattamentoIpertensione" :checked="jsonData.trattamentoIpertensione"></b-form-checkbox>
                                </b-col>
                            </b-row>
                        </b-card>
                        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
                            <b-row>
                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                    <label class="sa-label-data">{{ getLabelTraduora("patients.lblCholesterol") }}</label>
                                    <b-form-input v-model="jsonData.colesteroloHdl" type="number"></b-form-input>
                                </b-col>
                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                    <label class="sa-label-data">{{ getLabelTraduora("patients.lblTotalCholesterol") }}</label>
                                    <b-form-input v-model="jsonData.colesteroloTotale" type="number"></b-form-input>
                                </b-col>
                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                    <label class="sa-label-data">{{ getLabelTraduora("pazienti.egds.lblConclusions") }}</label>
                                    <b-form-textarea v-model="jsonData.conclusioni" rows="4" no-resize></b-form-textarea>
                                </b-col>
                            </b-row>
                        </b-card>
                        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
                            <b-row>
                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                    <label class="sa-label-data">Prossimo Consulto</label>
                                    <b-form-input v-model="jsonData.prossimoConsulto"></b-form-input>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-col>
                    <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3" class="sa-page-paziente-col-diario">
                        <paziente-diario-component class="sa-page-paziente-diario-clinico" :idPaziente="idPaziente"></paziente-diario-component>
                    </b-col>
                </b-row>
            </div>
        </template>
        <template slot="table-footer">
            <b-modal id="modalPressione" title="Diastolica" size="l" hide-footer v-model="showmodalPressione">
                <b-table :select-mode="'single'" selectable @row-selected="onRowSelected" sticky-header ref="table" stacked="l" striped hover :items="misurazioniPressione" :fields="fieldsPressione" sort-icon-left head-variant="light" class="sa-b-table"></b-table>
                <template #cell(selected)="{ rowSelected }">
                    <template v-if="rowSelected">
                        <span aria-hidden="true">&check;</span>
                        <span class="sr-only">{{ getLabelTraduora("agenda.lblSelected") }}</span>
                    </template>
                    <template v-else>
                        <span aria-hidden="true">&nbsp;</span>
                        <span class="sr-only">{{ getLabelTraduora("patient.lblNotSelected") }}</span>
                    </template>
                </template>
                <div class="sa-padding-right text-right col">
                    <b-button
                        size="sm"
                        variant="danger"
                        @click="
                            showmodalPressione = false;
                            selected = [];
                        "
                    >
                        {{ getLabelTraduora("global.lblBtnCancel") }}
                    </b-button>
                    <b-button size="sm" variant="secondary" @click="onAggiungiDs" :disabled="!selected[0]">OK</b-button>
                </div>
            </b-modal>
            <b-modal id="modalFrequenza" title="Frequenza Cardiaca" size="lg" hide-footer v-model="showModalFrequenza">
                <b-table :select-mode="'single'" selectable @row-selected="onRowSelected" sticky-header ref="table" stacked="l" striped hover :items="misurazioneCardiaca" :fields="fieldsFrequenza" sort-icon-left head-variant="light" class="sa-b-table">
                    <template #cell(selected)="{ rowSelected }">
                        <template v-if="rowSelected">
                            <span aria-hidden="true">&check;</span>
                            <span class="sr-only">Selected</span>
                        </template>
                        <template v-else>
                            <span aria-hidden="true">&nbsp;</span>
                            <span class="sr-only">Not selected</span>
                        </template>
                    </template>
                </b-table>
                <div class="sa-padding-right text-right col">
                    <b-button
                        size="sm"
                        variant="danger"
                        @click="
                            showModalFrequenza = false;
                            selected = [];
                        "
                    >
                        {{ getLabelTraduora("global.lblBtnCancel") }}
                    </b-button>
                    <b-button size="sm" variant="secondary" @click="onAggiungiFrequenza" :disabled="!selected[0]">OK</b-button>
                </div>
            </b-modal>
            <b-modal id="modalSpo2" title="SPO2" size="l" hide-footer v-model="showmodalSpo2">
                <b-table :select-mode="'single'" selectable @row-selected="onRowSelected" sticky-header ref="table" stacked="l" striped hover :items="misurazioneSpo2" :fields="fieldsSpo2" sort-icon-left head-variant="light" class="sa-b-table">
                    <template #cell(selected)="{ rowSelected }">
                        <template v-if="rowSelected">
                            <span aria-hidden="true">&check;</span>
                            <span class="sr-only">Selected</span>
                        </template>
                        <template v-else>
                            <span aria-hidden="true">&nbsp;</span>
                            <span class="sr-only">Not selected</span>
                        </template>
                    </template>
                </b-table>
                <hr />
                <b-row>
                    <div class="sa-padding-right text-right col">
                        <b-button
                            size="sm"
                            variant="danger"
                            @click="
                                showmodalSpo2 = false;
                                selected = [];
                            "
                        >
                            {{ getLabelTraduora("global.lblBtnCancel") }}
                        </b-button>
                        <b-button size="sm" variant="secondary" @click="onAggiungiSpo" :disabled="!selected[0]">OK</b-button>
                    </div>
                </b-row>
            </b-modal>
            <b-modal id="modalEcg" title="ECG" size="l" hide-footer v-model="showmodalEcg">
                <b-table :select-mode="'single'" selectable @row-selected="onRowSelected" sticky-header ref="table" stacked="l" striped hover :items="misurazioneEcg" :fields="fieldsEcg" sort-icon-left head-variant="light" class="sa-b-table">
                    <template #cell(selected)="{ rowSelected }">
                        <template v-if="rowSelected">
                            <span aria-hidden="true">&check;</span>
                            <span class="sr-only">Selected</span>
                        </template>
                        <template v-else>
                            <span aria-hidden="true">&nbsp;</span>
                            <span class="sr-only">Not selected</span>
                        </template>
                    </template>
                </b-table>
                <hr />
                <b-row>
                    <div class="sa-padding-right text-right col">
                        <b-button
                            size="sm"
                            variant="danger"
                            @click="
                                showmodalEcg = false;
                                selected = [];
                            "
                        >
                            {{ getLabelTraduora("global.lblBtnCancel") }}
                        </b-button>
                        <b-button size="sm" variant="secondary" @click="onAggiungiEcg" :disabled="!selected[0]">OK</b-button>
                    </div>
                </b-row>
            </b-modal>
            <b-modal id="modalGlucosio" title="Glucosio" size="l" hide-footer v-model="showmodalGlucosio">
                <b-table :select-mode="'single'" selectable @row-selected="onRowSelected" sticky-header ref="table" stacked="lg" striped hover :items="misurazioneGlucosio" :fields="fieldsGlucosio" sort-icon-left head-variant="light" class="sa-b-table">
                    <template #cell(selected)="{ rowSelected }">
                        <template v-if="rowSelected">
                            <span aria-hidden="true">&check;</span>
                            <span class="sr-only">Selected</span>
                        </template>
                        <template v-else>
                            <span aria-hidden="true">&nbsp;</span>
                            <span class="sr-only">Not selected</span>
                        </template>
                    </template>
                </b-table>
                <hr />
                <b-row>
                    <div class="sa-padding-right text-right col">
                        <b-button
                            size="sm"
                            variant="danger"
                            @click="
                                showmodalGlucosio = false;
                                selected = [];
                            "
                        >
                            {{ getLabelTraduora("global.lblBtnCancel") }}
                        </b-button>
                        <b-button size="sm" variant="secondary" @click="onAggiungiGlucosio" :disabled="!selected[0]">OK</b-button>
                    </div>
                </b-row>
            </b-modal>
        </template>
    </sa-page-layout>
</template>
<script>
import moment from "moment";
import axios from "axios";
import SaPageLayout from "../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../../components/PazienteAnagrafica.vue";
import UtilityMixin from "../../../../utility/UtilityMixin";
import PazienteDiarioComponent from "../../../utility/PazienteDiarioComponent.vue";
export default {
    components: { SaPageLayout, PazienteAnagrafica, PazienteDiarioComponent },
    mixins: [UtilityMixin],
    props: {
        utente: {
            type: Object,
            default: function () {
                return { gruppo: { menuGruppo: [] } };
            },
        },
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        language() {
            return this.$route.query.language;
        },
        linkPatient: {
            get: function () {
                if (this.utente.gruppo) {
                    if (this.utente.gruppo.menuGruppo) {
                        if (this.utente.gruppo.menuGruppo.length == 1) {
                            if (this.utente.gruppo.menuGruppo[0].primaryKey === "homestudiomedico") {
                                return "/paziente/view/" + this.idPaziente;
                            } else if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                                return "view/" + this.idPaziente;
                            } else {
                                return "homepaziente";
                            }
                        } else {
                            return "";
                        }
                    } else {
                        return "";
                    }
                } else {
                    return "";
                }
            },
        },
    },
    created() {
        // this.loadMalattieCroniche();
    },
    mounted() {
        let me = this;
        me.jsonData.idPaziente = this.$route.query.idPaziente;
        me.jsonData.idAccettazione = this.$route.query.idAccettazione;
        me.jsonData.idAmbulatorio = sessionStorage.getItem("idAmbulatorio");
        me.jsonData.language = this.language;
        me.jsonData.tipoEvento = "Visita";
        me.id = this.$route.params.id;
        let sessionLinkBack = sessionStorage.getItem("sessionLinkBack");

        if (sessionLinkBack) {
            me.linkback = sessionLinkBack;
        } else if (me.id === "-1") {
            me.linkback = "/paziente/eventi/visite/";
        } else {
            me.linkback = "/paziente/eventi/visita/view/" + me.id;
        }
        me.jsonData.idAmbulatorio = sessionStorage.getItem("idAmbulatorio");
        me.loadData();
        // me.getFumatoreAlcool();
        me.loadMisurazioni();
    },
    data() {
        return {
            linkback: null,
            // pathResource: "/visitehh",
            pathResource: "/pazienteeventivisite",
            pathMisurazioni: "/pazientemisure",
            id: null,
            jsonData: { eta: "", altezza: null, peso: null, language: this.language },
            showModalLoading: false,
            showModalFrequenza: false,
            showmodalSpo2: false,
            showmodalEcg: false,
            showmodalPressione: false,
            showmodalGlucosio: false,
            isDisabled: false,
            currentPage: 1,
            perPage: 10,
            checkMalattie: 0,
            profili: [],
            misurazioneCardiaca: [],
            misurazioniPressione: [],
            misurazioneSpo2: [],
            misurazioneEcg: [],
            misurazioneGlucosio: [],
            provenienzaOption: [{ value: null, text: null }],
            dataInizio: new Date(),
            selected: {},
            fieldsFrequenza: [
                {
                    label: "",
                    key: "selected",
                    selection: true,
                    thStyle: "width: 1rem",
                },
                {
                    label: "Frequenza Cardiaca",
                    key: "value",
                    sortable: true,
                },
                {
                    label: "Data Esecuzione",
                    key: "istant",
                    sortable: true,
                    formatter: (value) => {
                        return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
                    },
                },
                {
                    label: "",
                    key: "action",
                    thStyle: "width: 4rem",
                },
            ],
            fieldsSpo2: [
                {
                    label: "",
                    key: "selected",
                    selection: true,
                    thStyle: "width: 1rem",
                },
                {
                    label: "Valore SPO2",
                    key: "value",
                    sortable: true,
                },
                {
                    label: "Data Esecuzione",
                    key: "istant",
                    sortable: true,
                    formatter: (value) => {
                        return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
                    },
                },
            ],
            fieldsEcg: [
                {
                    label: "",
                    key: "selected",
                    selection: true,
                    thStyle: "width: 1rem",
                },
                {
                    label: "Valore ECG",
                    key: "value",
                    sortable: true,
                },
                {
                    label: "Data Esecuzione",
                    key: "istant",
                    sortable: true,
                    formatter: (value) => {
                        return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
                    },
                },
            ],
            fieldsGlucosio: [
                {
                    label: "",
                    key: "selected",
                    selection: true,
                    thStyle: "width: 1rem",
                },
                {
                    label: "Valore Glucosio",
                    key: "value",
                    sortable: true,
                },
                {
                    label: "Data Esecuzione",
                    key: "istant",
                    sortable: true,
                    formatter: (value) => {
                        return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
                    },
                },
            ],
            fieldsPressione: [
                {
                    label: "",
                    key: "selected",
                    selection: true,
                    thStyle: "width: 1rem",
                },
                {
                    label: "data evento",
                    key: "data",
                    sortable: true,
                },
                {
                    label: "pressureMax",
                    key: "pressioneMax",
                    sortable: true,
                },
                {
                    label: "pressureMin",
                    key: "pressioneMin",
                    sortable: true,
                },
            ],
        };
    },
    methods: {
        getEtaPaziente(value) {
            let me = this;
            me.jsonData.eta = value;
        },
        onRowSelected(items) {
            let me = this;
            if (items.length > 0) {
                me.selected = items;
            }
        },
        getFumatoreAlcool() {
            let me = this;
            let dataAl = moment(new Date()).valueOf();
            let dataDal = dataAl - 5184000000;
            let al = moment(dataAl).format("yyyy-MM-DD HH:mm:ss");
            let dal = moment(dataDal).format("yyyy-MM-DD HH:mm:ss");
            // console.log(al);
            // console.log(dal);
            let link = process.env.VUE_APP_PATH_API + "/hyperhealth?forPage=20&page=1&idPaziente=" + me.idPaziente + "&dal=" + dal + "&idMisura=habits&al=" + al + "&order=desc";
            if (me.id === "-1") {
                axios
                    .get(link)
                    .then((res) => {
                        if (res.data.data.list[0]) {
                            let lista = res.data.data.list;
                            let sigarette = 0;
                            let alcool = 0;
                            lista.forEach((element) => {
                                if (element.measurename === "Sigarette") {
                                    sigarette = sigarette + Number(element.value);
                                }
                                if (element.measurename === "Alcool") {
                                    alcool = alcool + Number(element.value);
                                }
                            });
                            // console.log("sigarette: " + sigarette);
                            // console.log("alcool: " + alcool);
                            if (sigarette > 0) {
                                me.jsonData.fumatore = true;
                            }
                            if (alcool > 0) {
                                me.jsonData.alcool = true;
                            }
                        } else {
                            me.jsonData.fumatore = false;
                            me.jsonData.alcool = false;
                        }
                    })
                    .catch(() => {});
            }
        },
        onCalcoloBmi(altezza, peso) {
            let me = this;
            me.jsonData.bmi = (peso / ((altezza / 100) * (altezza / 100))).toFixed(2);
        },
        formatDate(value) {
            if (value) {
                return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
            } else {
                return "---";
            }
        },
        onAggiungiGlucosio() {
            let me = this;
            me.jsonData.glucosio = me.selected[0].value;
            me.selected = [];
            me.showmodalGlucosio = false;
        },
        onAggiungiFrequenza() {
            let me = this;
            me.jsonData.frequenzaCardiaca = me.selected[0].value;
            me.showModalFrequenza = false;
        },
        onAggiungiDs() {
            let me = this;
            me.jsonData.pressioneSistolica = me.selected[0].pressioneMax;
            me.jsonData.pressioneDiastolica = me.selected[0].pressioneMin;
            me.showmodalPressione = false;
        },
        onAggiungiSpo() {
            let me = this;
            me.jsonData.spo2 = me.selected[0].value;
            me.showmodalSpo2 = false;
        },
        onAggiungiEcg() {
            let me = this;
            if (me.selected[0].value !== null) {
                me.jsonData.ecg = me.selected[0].value;
                me.showmodalEcg = false;
            } else {
                me.isDisabled = true;
            }
        },
        loadData() {
            let me = this;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                axios.get(link + me.id).then((response) => {
                    me.jsonData = response.data.data;
                    me.showModalLoading = false;
                });
            }
        },
        loadMisurazioni() {
            let me = this;
            let data = UtilityMixin.methods.formatDateTimeCustom(me.dataInizio, "YYYY-MM-DD HH:mm:ss");
            let link = process.env.VUE_APP_PATH_API + me.pathMisurazioni + "?idPaziente=" + me.idPaziente + "&dal=" + data;
            me.showModalLoading = false;
            axios
                .get(link)
                .then((response) => {
                    response.data.data.forEach((element) => {
                        if (element.measurename == "lbl.measure.systolicBloodPressure" || element.measurename == "lbl.measure.diastolicBloodPressure") {
                            //  if(element.istant)
                            me.addPressure(element);
                            //me.misurazioniPressione.push(element);
                        } else if (element.measurename == "lbl.measure.hrAverage") {
                            me.misurazioneCardiaca.push(element);
                        } else if (element.measurename == "pulse") {
                            me.misurazioneCardiaca.push(element);
                        } else if (element.measurename == "lbl.measure.spo2") {
                            me.misurazioneSpo2.push(element);
                        } else if (element.measurename == "lbl.measure.diagnosis") {
                            me.misurazioneEcg.push(element);
                        } else if (element.measurename == "lbl.measure.glucose") {
                            me.misurazioneGlucosio.push(element);
                        }
                    });
                    me.showModalLoading = false;
                })
                .catch(() => {});
        },
        loadMalattieCroniche() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/lastvisitahh?idPaziente=" + me.idPaziente;
            axios
                .get(link)
                .then((res) => {
                    let lastVisita = res.data.data;
                    // console.log(lastVisita);
                    // console.log(me.jsonData);
                    me.jsonData.alcool = lastVisita.alcool;
                    me.jsonData.fumatore = lastVisita.fumatore;
                    me.jsonData.ictusIschemiaTransitoria = lastVisita.ictusIschemiaTransitoria;
                    me.jsonData.ipertensione = lastVisita.ipertensione;
                    me.jsonData.malattiaRenaleCronica = lastVisita.malattiaRenaleCronica;
                    me.jsonData.cardiopatiaCronica = lastVisita.cardiopatiaCronica;
                    me.jsonData.diabete = lastVisita.diabete;
                    me.jsonData.trattamentoIpertensione = lastVisita.trattamentoIpertensione;
                    me.jsonData.altezza = lastVisita.altezza;
                    me.jsonData.peso = lastVisita.peso;
                    me.onCalcoloBmi(me.jsonData.altezza, me.jsonData.peso);
                    me.jsonData.circonferenzaAddominale = lastVisita.circonferenzaAddominale;
                })
                .catch(() => {});
            me.checkMalattie++;
        },
        addPressure(misura) {
            let me = this;
            let dataMisura = me.formatDate(misura.istant);
            let present = false;
            me.misurazioniPressione.forEach((misurazione) => {
                if (misurazione.data === dataMisura) {
                    present = true;
                    if (misura.measurename === "lbl.measure.systolicBloodPressure") {
                        misurazione.pressioneMax = misura.value;
                    }
                    if (misura.measurename === "lbl.measure.diastolicBloodPressure") {
                        misurazione.pressioneMin = misura.value;
                    }
                }
            });

            if (!present) {
                let misurazione = { data: dataMisura };
                if (misura.measurename === "lbl.measure.systolicBloodPressure") {
                    misurazione.pressioneMax = misura.value;
                }
                if (misura.measurename === "lbl.measure.diastolicBloodPressure") {
                    misurazione.pressioneMin = misura.value;
                }
                me.misurazioniPressione.push(misurazione);
            }
        },
    },
};
</script>
