import NumeratoriListPage from "./pages/NumeratoriListPage.vue";
import NumeratoriViewPage from "./pages/NumeratoriViewPage.vue";
import NumeratoriEditPage from "./pages/NumeratoriEditPage.vue";


export default {

NumeratoriListPage,
NumeratoriViewPage,
NumeratoriEditPage,
};
