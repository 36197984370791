<template>
	<div class="sa-page-dashboard">
		<div class="sa-page-dashboard-header">
			<b-row>
				<b-col v-for="headerElement in dashboard.headerElements" :key="headerElement.id" :cols="headerElement.cols" :xs="headerElement.xs" :sm="headerElement.sm" :md="headerElement.md" :lg="headerElement.lg" :xl="headerElement.xl">
					<b-overlay :show="headerElement.showOverlay" rounded="sm">
						<b-card class="sa-card sa-header-nowrap sa-info-card-dashboard" :header="headerElement.title" header-tag="header" footer-tag="footer" title="">
							<b-card-body>
								<b-card-text class="text-right sa-dashboard-data-text">{{ headerElement.value }}</b-card-text>
							</b-card-body>
						</b-card>
					</b-overlay>
				</b-col>
			</b-row>
		</div>
		<div class="sa-page-dashboard-body">
			<b-row>
				<b-col v-for="bodyElement in dashboard.bodyElements" :key="bodyElement.id" :cols="bodyElement.cols" :xs="bodyElement.xs" :sm="bodyElement.sm" :md="bodyElement.md" :lg="bodyElement.lg" :xl="bodyElement.xl">
					<b-overlay :show="bodyElement.showOverlay" rounded="sm">
						<b-card class="sa-card" style="height: 100%" :header="bodyElement.title" header-tag="header" footer-tag="footer" title="">
							<template #header>
								<div style="display: flex">
									<span style="flex: 1">{{ bodyElement.title }}</span>
									<b-button size="sm" variant="outline-secondary outline-secondary-noborder pull-right" @click="onClickEditConfigChart(bodyElement)">
										<i class="fas fa-pencil-alt"></i>
									</b-button>
									<b-button size="sm" variant="outline-secondary outline-secondary-noborder pull-right" @click="onClickZoomChart(bodyElement)">
										<i class="fas fa-search-plus"></i>
									</b-button>
									<b-button size="sm" variant="outline-secondary outline-secondary-noborder pull-right" @click="onClickViewData(bodyElement)">
										<i class="fas fa-database"></i>
									</b-button>
									<b-button size="sm" variant="outline-secondary outline-secondary-noborder pull-right" @click="onClickViewDetails(bodyElement)">
										<i class="fas fa-plus"></i>
									</b-button>
								</div>
							</template>
							<b-card-body>
								<div v-if="bodyElement.type === 'graphic'" class="sa-page-dashboard-body-graphic" style="height: 300px">
									<generic-chart-component :marginLeft="bodyElement.marginLeft" :legendVisible="bodyElement.legendVisible" :horizontalBar="bodyElement.horizontalDirection" xAxisType="value" :chartData="bodyElement.chartData"></generic-chart-component>
								</div>
								<div v-else>
									<p>{{ bodyElement }}</p>
									<b-card-text class="text-right sa-dashboard-data-text">{{ bodyElement.value }}</b-card-text>
								</div>
							</b-card-body>
						</b-card>
					</b-overlay>
				</b-col>
			</b-row>
		</div>
		<b-modal ref="mdlViewData" id="mdl-view-data" size="lg" title="Dati" scrollable centered ok-only ok-title="Chiudi">
			<b-row> </b-row>
			<div class="b-table-sticky-header">
				<b-table sticky-header stacked="lg" striped hover :items="modalTableItms" sort-icon-left head-variant="light" class="sa-b-table" foot-clone> </b-table>
			</div>
		</b-modal>
		<b-modal ref="mdlChart" id="mdl-view-data" size="xl" :title="modalChart.title" scrollable centered body-class="sa-modal-chart" ok-only ok-title="Chiudi">
			<generic-chart-component :marginLeft="modalChart.bodyElement.marginLeft" :legendVisible="modalChart.bodyElement.legendVisible" :horizontalBar="modalChart.bodyElement.horizontalDirection" xAxisType="value" :chartData="modalChart.bodyElement.chartData"></generic-chart-component>
		</b-modal>
		<b-modal ref="mdlEditConfigChart" id="mdl-edit-config-chart" size="lg" title="Dati" scrollable centered ok-title="Conferma" @ok="onOkEditConfigChart">
			<b-row>
				<b-col cols="12" xs="12" sm="9" md="9" lg="9" xl="9">
					<label class="sa-label-data">Titolo</label>
					<b-form-input v-model="selectedEditConfigChart.title"></b-form-input>
				</b-col>
				<b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
					<label class="sa-label-data">Posizione</label>
					<b-form-input v-model="selectedEditConfigChart.position"></b-form-input>
				</b-col>
				<b-col xs="12" sm="12" md="4" lg="4" xl="4">
					<label class="sa-label_data"> Query </label>
					<b-form-select v-model="selectedEditConfigChart.idQuery" :options="queryOptions" :value="null" value-field="id" text-field="title"></b-form-select>
				</b-col>
				<b-col xs="12" sm="12" md="4" lg="4" xl="4">
					<label class="sa-label_data">Grafico </label>
					<b-form-select v-model="selectedEditConfigChart.type" :options="chartOptions" :value="null" value-field="grafico" text-field="grafico"></b-form-select>
				</b-col>
				<b-col xs="12" sm="12" md="4" lg="4" xl="4">
					<label class="sa-label_data"> Tipo di Grafico </label>
					<b-form-select v-model="selectedEditConfigChart.graphicType" :options="typeChartOptions" :value="null" value-field="type" text-field="type"></b-form-select>
				</b-col>
				<b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
					<label class="sa-label-data">cols</label>
					<b-form-input v-model="selectedEditConfigChart.cols" type="range" value="" step="1" min="0" max="12" required></b-form-input>
				</b-col>
				<b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
					<label class="sa-label-data">xs</label>
					<b-form-input v-model="selectedEditConfigChart.xs" type="range" value="" step="1" min="0" max="12" required></b-form-input>
				</b-col>
				<b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
					<label class="sa-label-data">sm</label>
					<b-form-input v-model="selectedEditConfigChart.sm" type="range" value="" step="1" min="0" max="12" required></b-form-input>
				</b-col>
				<b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
					<label class="sa-label-data">md</label>
					<b-form-input v-model="selectedEditConfigChart.md" type="range" value="" step="1" min="0" max="12" required></b-form-input>
				</b-col>
				<b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
					<label class="sa-label-data">lg</label>
					<b-form-input v-model="selectedEditConfigChart.lg" type="range" value="" step="1" min="0" max="12" required></b-form-input>
				</b-col>
				<b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
					<label class="sa-label-data">xl</label>
					<b-form-input v-model="selectedEditConfigChart.xl" type="range" value="" step="1" min="0" max="12" required></b-form-input>
				</b-col>
				<b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
					<label class="sa-label_data"> Dimensione Uno</label>
					<b-form-select v-model="selectedEditConfigChart.dimension" :options="xValueArray" :value="null" value-field="id" text-field="nome">
						<template #first>
							<b-form-select-option :value="null" disabled>-- Please select an option --</b-form-select-option>
						</template>
					</b-form-select>
				</b-col>
				<b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
					<label class="sa-label_data"> Filtro </label>
					<b-form-select v-model="selectedEditConfigChart.filters" :options="xValueArray" :value="null" value-field="id" text-field="nome"></b-form-select>
				</b-col>
				<b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
					<label class="sa-label_data"> Filtro Iniziale </label>
					<b-form-input v-model="selectedEditConfigChart.filtroIniziale"></b-form-input>
				</b-col>
				<b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
					<label class="sa-label_data"> Asse X </label>
					<b-row>
						<b-col v-for="xValue in xValueArray" :key="xValue" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
							<b-form-checkbox v-model="xvalueSelected" :name="xValue" :value="xValue" switch>{{ xValue }}</b-form-checkbox>
						</b-col>
					</b-row>
				</b-col>
				<b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
					<span style="font-size: 1.2rem; font-weight: bold; margin-top: 10px; display: block">{{ selectedEditConfigChart.xvalue }}</span>
				</b-col>
			</b-row>
		</b-modal>
	</div>
</template>

<script>
import axios from "axios";
import GenericChartComponent from "../../utility/components/GenericChartComponent.vue";
export default {
	props: {
		idDashboard: String,
	},
	components: { GenericChartComponent },
	created() {
		if (this.idDashboard) {
			this.id = this.idDashboard;
		} else {
			this.id = this.$route.params.id;
		}
	},
	mounted() {
		this.loadQueryList();
		this.loadData();
	},
	data() {
		return {
			id: null,
			pathResource: "/dataanalysisdashboard",
			pathResourceData: "/dataanalysisdatabaseinfo/eseguigraphicquery",
			pathResourceQueryList: "/dataanalysisquery",
			pie: null,
			modalTableItms: [],
			modalChart: { title: "", headerElements: [], bodyElement: { marginLeft: 300, legendVisible: true, horizontalDirection: false }, chartData: null },
			queryResult: [],
			editConfigChart: [],
			charts: [],
			queryOptions: [],
			chartOptions: [{ grafico: "numeric" }, { grafico: "graphic" }],
			typeChartOptions: [{ type: "bar" }, { type: "line" }],
			selectedEditConfigChart: {},
			xValueArray: [],
			dashboard: {
				headerElements: [],
				bodyElements: [],
			},
		};
	},
	computed: {
		xvalueSelected: {
			get: function () {
				console.log(this.selectedEditConfigChart.xvalue);
				return this.selectedEditConfigChart.xvalue.split(";");
			},
			set: function (newValue) {
				let arrTmp = [];
				this.xValueArray.forEach((element) => {
					if (newValue.includes(element)) {
						arrTmp.push(element);
					}
				});
				this.selectedEditConfigChart.xvalue = arrTmp.join(";");
			},
		},
	},
	methods: {
		loadData() {
			let me = this;
			let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
			axios.get(link + me.id)
				.then((response) => {
					let dashboard = JSON.parse(response.data.data.dashboard);
					dashboard.headerElements.forEach((element) => {
						me.loadDataQuery(element);
					});
					dashboard.bodyElements.forEach((element) => {
						if (element.type === "graphic") {
							element.chartData = { titolo: "", unitaMisura: "", xAxis: [{ type: "value" }], series: [] };
						}
						me.loadDataQuery(element);
					});

					me.dashboard = dashboard;
				})
				.catch((e) => {
					console.log(e);
				});
		},
		loadQueryList() {
			let me = this;
			let link = process.env.VUE_APP_PATH_API + me.pathResourceQueryList;
			axios.get(link)
				.then((response) => {
					me.queryOptions = response.data.data.list;
				})
				.catch((e) => {
					console.log(e);
				});
		},
		loadDataQuery(obj) {
			let me = this;
			obj.showOverlay = true;
			let link = process.env.VUE_APP_PATH_API + me.pathResourceData + "/" + obj.idQuery;
			axios.get(link)
				.then((response) => {
					let objData = null;
					let configChart = { fileds: [], dimension: "", xvalue: "", filters: "" };
					switch (obj.type) {
						case "numeric":
							objData = response.data.data[0];
							for (let property in objData) {
								obj.value = objData[property];
							}
							break;
						case "graphic":
							me.queryResult[obj.idQuery] = response.data.data;
							if (me.queryResult[obj.idQuery].length > 0) {
								configChart.fileds = Object.keys(me.queryResult[obj.idQuery][0]);
								configChart.dimension = obj.dimension;
								configChart.filters = obj.filters;
								configChart.xvalue = obj.xvalue;
								me.editConfigChart[obj.idQuery] = configChart;
							}
							me.prepareChartData(obj);
							break;
					}

					obj.showOverlay = false;
				})
				.catch((e) => {
					console.log(e);
				});
		},
		prepareChartData(obj) {
			let filter = obj.filters;
			let me = this;
			let series = [];
			let xvalues = obj.xvalue ? obj.xvalue.split(";") : [];
			let xData = [];
			let labelsDimesion = [...new Set(me.queryResult[obj.idQuery].map((rec) => rec[obj.dimension]))];
			let valoriFiltro = [...new Set(me.queryResult[obj.idQuery].map((rec) => rec[obj.filters]))];
			let filtroIniziale = obj.filtroIniziale;
			for (let label of labelsDimesion) {
				let serie = {
					name: label,
					type: "bar",
					barGap: 0,
					label: label,
					emphasis: {
						focus: "series",
					},
					data: [],
				};
				for (let valoreFiltro of valoriFiltro) {
					for (let xvalue of xvalues) {
						if (!filtroIniziale) {
							xData.push(xvalue + "-" + valoreFiltro);
							let record = me.queryResult[obj.idQuery].filter((rec) => rec[obj.dimension] === label && rec[filter] == valoreFiltro);
							let value = record.length > 0 ? record[0][xvalue] : 0;
							serie.data.push(value);
						} else {
							if (valoreFiltro == filtroIniziale) {
								xData.push(xvalue + "-" + valoreFiltro);
								let record = me.queryResult[obj.idQuery].filter((rec) => rec[obj.dimension] === label && rec[filter] == valoreFiltro);
								let value = record.length > 0 ? record[0][xvalue] : 0;
								serie.data.push(value);
							}
						}
					}
				}
				series.push(serie);
			}
			xData = [...new Set(xData)];
			me.generateChart(obj, xData, series);
		},
		generateChart(obj, xData, series) {
			let me = this;
			obj.chartData = {
				titolo: "",
				toolbox: {
					show: true,
					orient: "vertical",
					left: "right",
					top: "center",
					feature: {
						mark: { show: true },
						dataView: { show: false, readOnly: false },
						magicType: { show: true, type: ["line", "bar", "stack"] },
						restore: { show: false },
						saveAsImage: { show: true },
					},
				},
				xAxis: [
					{
						type: "category",
						axisTick: { show: false },
						axisLabel: {
							show: true,
							interval: 0,
							rotate: 45,
						},
						data: xData,
					},
				],
				series: series,
				unitaMisura: "",
			};
			me.charts[obj.idQuery] = obj;
		},
		onClickViewDetails(bodyElement) {
			let me = this;
			console.log(bodyElement);
			me.$router.push("/dataanalysisdashboards/view/details/" + bodyElement.id + "?idQuery=" + bodyElement.idQuery);
		},
		onClickViewData(object) {
			let me = this;
			me.modalTableItms = me.queryResult[object.idQuery];
			me.$refs.mdlViewData.show();
		},
		onClickZoomChart(bodyElement) {
			let me = this;
			me.modalChart.bodyElement = bodyElement;
			me.modalChart.title = bodyElement.title;
			me.modalChart.modalChart = me.charts[bodyElement.idQuery].modalChart;
			me.$refs.mdlChart.show();
		},
		onClickEditConfigChart(bodyElement) {
			let me = this;
			me.selectedEditConfigChart = bodyElement;
			me.$refs.mdlEditConfigChart.show();
			me.xValueArray = me.editConfigChart[bodyElement.idQuery].fileds;
		},
		onOkEditConfigChart() {
			let me = this;
			me.loadDataQuery(me.selectedEditConfigChart);
		},
	},
};
</script>

<style></style>
