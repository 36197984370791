<template>
    <sa-page-layout slot="table-body" :pathResource="pathResource" :linkback="linkback" :btnCloseVisible="true" :showModalLoading="showModalLoading">
        <template slot="table-body">
            <rsa-configurazione-tipo-prestazioni-view-component ref="RsaConfigurazioneTipoPrestazioniViewComponent" @beforeLoadData="onBeforeLoadData" @afterLoadData="onAfterLoadData"> </rsa-configurazione-tipo-prestazioni-view-component>
        </template>
    </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import RsaConfigurazioneTipoPrestazioniViewComponent from "../components/RsaConfigurazioneTipoPrestazioniViewComponent.vue";
export default {
    name: "RsaConfigurazioneTipoPrestazionePage",
    components: { SaPageLayout, RsaConfigurazioneTipoPrestazioniViewComponent },

    data() {
        return {
            btnCloseVisible: true,
            showModalLoading: false,
            pathResource: "/rsatipoprestazioni",
            linkback: "/configurazioni/rsatipoprestazioni",
            jsonDataTipoPrestazione: {},
        };
    },

    mounted() {
        this.loadData;
    },

    methods: {
        loadData() {
            this.$refs.RsaConfigurazioneTipoPrestazioniViewComponent.loadData();
        },
        onBeforeLoadData() {
            this.showModalLoading = true;
        },
        onAfterLoadData() {
            this.showModalLoading = false;
        },
    },
};
</script>
