<template>
	<b-modal ref="mdlEditConfigChart" id="mdl-edit-config-chart" size="lg" title="Dati" scrollable centered :ok-disabled="alertShow" ok-title="Conferma" @ok="onOkEditConfigChart" @cancel="onCancelEditConfigChart">
		<b-row>
			<b-col cols="12" xs="12" sm="9" md="9" lg="9" xl="9">
				<label class="sa-label-data">Titolo</label>
				<b-form-input v-model="editConfigChart.title"></b-form-input>
			</b-col>
			<b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
				<label class="sa-label-data">Posizione</label>
				<b-form-input v-model="editConfigChart.position"></b-form-input>
			</b-col>
			<b-col xs="12" sm="12" md="4" lg="4" xl="4">
				<label class="sa-label_data"> Query </label>
				<b-input-group class="mt-3">
					<b-input-group-append>
						<b-form-select v-model="editConfigChart.idQuery" :options="queryOptions" :value="null" value-field="id" text-field="title" @input="onInputQuery"></b-form-select>
						<b-button variant="light" @click="onClickQueryRefresh"><i class="fas fa-sync-alt"></i></b-button>
					</b-input-group-append>
				</b-input-group>
			</b-col>
			<b-col xs="12" sm="12" md="2" lg="2" xl="2">
				<label class="sa-label_data">Grafico </label>
				<b-form-select v-model="editConfigChart.type" :options="chartOptions" :value="null" value-field="grafico" text-field="grafico" @input="onInputType"></b-form-select>
			</b-col>
			<b-col xs="12" sm="12" md="2" lg="2" xl="2">
				<label class="sa-label_data"> Tipo di Grafico </label>
				<b-form-select v-model="editConfigChart.graphicType" :disabled="controlDisable" :options="typeChartOptions" :value="null" value-field="type" text-field="type"></b-form-select>
			</b-col>
			<b-col xs="12" sm="12" md="2" lg="2" xl="2">
				<label class="sa-label_data"> Orientamento </label>
				<b-form-select v-model="editConfigChart.horizontalDirection" :disabled="controlDisable" :options="orientamentoOptions" :value="null" value-field="value" text-field="text"></b-form-select>
			</b-col>
			<b-col xs="12" sm="12" md="2" lg="2" xl="2">
				<label class="sa-label_data"> Margine Sx </label>
				<b-form-input v-model="editConfigChart.marginLeft" :disabled="controlDisable"></b-form-input>
			</b-col>
			<b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
				<label class="sa-label-data">cols</label>
				<b-form-input v-model="editConfigChart.cols" type="range" value="" step="1" min="0" max="12" required></b-form-input>
			</b-col>
			<b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
				<label class="sa-label-data">xs</label>
				<b-form-input v-model="editConfigChart.xs" type="range" value="" step="1" min="0" max="12" required></b-form-input>
			</b-col>
			<b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
				<label class="sa-label-data">sm</label>
				<b-form-input v-model="editConfigChart.sm" type="range" value="" step="1" min="0" max="12" required></b-form-input>
			</b-col>
			<b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
				<label class="sa-label-data">md</label>
				<b-form-input v-model="editConfigChart.md" type="range" value="" step="1" min="0" max="12" required></b-form-input>
			</b-col>
			<b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
				<label class="sa-label-data">lg</label>
				<b-form-input v-model="editConfigChart.lg" type="range" value="" step="1" min="0" max="12" required></b-form-input>
			</b-col>
			<b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
				<label class="sa-label-data">xl</label>
				<b-form-input v-model="editConfigChart.xl" type="range" value="" step="1" min="0" max="12" required></b-form-input>
			</b-col>
			<b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
				<label class="sa-label_data"> Dimensione </label>
				<b-form-select v-model="editConfigChart.dimension" :disabled="controlDisable" :options="xValueArray" :value="null" value-field="id" text-field="nome">
					<template #first>
						<b-form-select-option :value="null">Nessuno</b-form-select-option>
					</template>
				</b-form-select>
			</b-col>
			<b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
				<label class="sa-label_data"> Filtro </label>
				<b-form-select v-model="editConfigChart.filters" :disabled="controlDisable" :options="xValueArray" :value="null" value-field="id" text-field="nome"></b-form-select>
			</b-col>
			<b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
				<label class="sa-label_data"> Filtro Iniziale </label>
				<b-form-input v-model="editConfigChart.filtroIniziale" :disabled="controlDisable"></b-form-input>
			</b-col>
			<b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
				<label class="sa-label_data"> Asse X </label>
				<b-row>
					<b-col v-for="xValue in xValueArray" :key="xValue" cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
						<b-form-checkbox v-model="xvalueSelected" :name="xValue" :value="xValue" switch>{{ xValue }}</b-form-checkbox>
					</b-col>
				</b-row>
			</b-col>
			<b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
				<span style="font-size: 1.2rem; font-weight: bold; margin-top: 10px; display: block">{{ editConfigChart.xvalue }}</span>
			</b-col>
			<b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
				{{ objData }}
			</b-col>
			<b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
				<b-alert :show="alertShow" variant="danger">{{ alertMessage }}</b-alert>
			</b-col>
		</b-row>
	</b-modal>
</template>

<script>
import axios from "axios";
export default {
	data() {
		return {
			pathResourceQueryList: "/dataanalysisquery",
			pathResourceData: "/dataanalysisdatabaseinfo/eseguigraphicquery",
			editConfigChart: { xvalue: "", dimension: "", filters: "" },
			queryOptions: [],
			chartOptions: [{ grafico: "numeric" }, { grafico: "graphic" }],
			typeChartOptions: [{ type: "bar" }, { type: "line" }],
			orientamentoOptions: [
				{ text: "Orizontale", value: true },
				{ text: "Verticale", value: false },
			],
			xValueArray: [],
			controlDisable: true,
			alertShow: false,
			objData: null,
			alertMessage: "",
			originalEditConfigChart: null,
		};
	},
	computed: {
		xvalueSelected: {
			get: function () {
				return this.editConfigChart.xvalue.split(";");
			},
			set: function (newValue) {
				let arrTmp = [];
				this.xValueArray.forEach((element) => {
					if (newValue.includes(element)) {
						arrTmp.push(element);
					}
				});
				this.editConfigChart.xvalue = arrTmp.join(";");
			},
		},
	},
	mounted() {
		this.loadQueryList();
	},
	methods: {
		show() {
			let me = this;
			me.$refs.mdlEditConfigChart.show();
		},
		loadQueryList() {
			let me = this;
			let link = process.env.VUE_APP_PATH_API + me.pathResourceQueryList;
			axios.get(link)
				.then((response) => {
					me.queryOptions = response.data.data.list;
				})
				.catch((e) => {
					console.log(e);
				});
		},
		onOkEditConfigChart() {
			let me = this;
			for (var attr in me.editConfigChart) {
				me.originalEditConfigChart[attr] = me.editConfigChart[attr];
			}
			this.$emit("updateConfig", me.originalEditConfigChart);
		},
		onCancelEditConfigChart() {
			let me = this;
			me.alertShow = false;
			me.objData = null;
			//me.originalEditConfigChart.title = "Prova";
		},
		setChartParams(chartParams, xValueArray) {
			this.xValueArray = xValueArray;
			this.originalEditConfigChart = chartParams;
			this.editConfigChart = Object.assign({}, chartParams);
			this.controlDisable = chartParams.type === "numeric";
		},
		onInputQuery(value) {
			this.loadDataQuery(value);
		},
		loadDataQuery(id) {
			let me = this;
			let link = process.env.VUE_APP_PATH_API + me.pathResourceData + "/" + id;
			axios.get(link)
				.then((response) => {
					me.alertShow = false;
					me.objData = null;
					if (response.data.data.length > 0 && me.editConfigChart.type === "graphic") {
						me.xValueArray = Object.keys(response.data.data[0]);
					} else if (response.data.data.length > 1 && me.editConfigChart.type === "numeric") {
						me.alertShow = true;
						me.alertMessage = "Attenzione la query deve restituire un solo valore per il grafico 'numeric'";
					} else {
						this.objData = response.data.data[0];
					}
				})
				.catch((e) => {
					console.log(e);
					me.alertShow = true;
					me.alertMessage = "La query è terminata con erroe";
				});
		},
		onInputType(value) {
			this.controlDisable = value === "numeric";
		},
		onClickQueryRefresh() {
			let me = this;
			this.loadDataQuery(me.editConfigChart.idQuery);
		},
	},
};
</script>

<style></style>
