<template>
    <div slot="table-body">
        <b-row>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card" header="Lista Allegati" header-tag="header" footer-tag="footer" title="">
                    <div v-if="rowList === 0">
                        <img src="@/assets/images/no-image.png" width="100" style="display: block; margin: auto auto" />
                        <h4 style="text-align: center">
                            {{ this.$i18n.t("patient.afa.lblNoFile") }}
                        </h4>
                    </div>
                    <div v-else class="b-table-sticky-header">
                        <b-table sticky-header ref="table" :filter="filter" responsive stacked="lg" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                            <template v-slot:cell(nomeFile)="{ item }">
                                <p v-b-modal.mdlAllegato @click="onViewPdf(item)">{{ item.nomeFile }}</p>
                            </template>
                            <template v-slot:cell(actions)="row">
                                <b-button v-if="checkEstensione(row.item) === 'printer'" size="sm" variant="btn btn-purple waves-effect  waves-light width-sm no-text" style="margin-right: 3px" @click="onClick(row.item, row.index, $event.target)" v-b-modal.visualizzaAllegato>
                                    <b-icon :icon="checkEstensione(row.item)"></b-icon>
                                </b-button>
                                <b-button v-if="checkEstensione(row.item) !== 'printer'" size="sm" variant="btn btn-purple waves-effect  waves-light width-sm no-text" style="margin-right: 3px" @click="download(row.item)">
                                    <b-icon :icon="checkEstensione(row.item)"></b-icon>
                                </b-button>
                            </template>
                        </b-table>
                    </div>
                </b-card>
            </b-col>
        </b-row>
        <b-modal size="xl" id="mdlAllegato">
            <template>
                <b-embed type="iframe" :src="file" allowfullscreen></b-embed>
            </template>
        </b-modal>
    </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
export default {
    mounted() {
        let me = this;
        if (me.idRisorsa === null) {
            me.id = me.$route.params.id;
        } else {
            me.id = me.idRisorsa;
        }
        me.loadData();
    },

    data() {
        return {
            idRisorsa: null,
            rowList: 0,
            perPage: 10,
            currentPage: 1,
            filter: null,
            id: null,
            pathResource: "/allegati",
            linkedit: "/paziente/allegati",
            file: null,
            filtro: {},
            selected: [],
            filterOn: [],
            fields: [
                {
                    label: this.$i18n.t("patient.afa.lblNomeFIle"),
                    key: "nomeFile",
                    thStyle: "width: 7rem",
                    sortable: true,
                },
                {
                    label: this.$i18n.t("patients.lblUploadDate"),
                    key: "dataCaricamento",
                    sortable: true,
                    thStyle: "width: 7rem",
                    formatter: (value) => {
                        return moment(new Date(value)).format("DD-MM-YYYY");
                    },
                },
                {
                    label: this.$i18n.t("patients.lblDocumentType"),
                    key: "tipoDocumento",
                    thStyle: "width: 7rem",
                    sortable: true,
                },
                {
                    label: this.$i18n.t("patients.lblDescription"),
                    key: "descrizione",
                    thStyle: "width: 7rem",
                    sortable: true,
                },
                // {
                //     label: "",
                //     key: "actions",
                //     thStyle: "width: 3rem",
                // },
                // { class: "sa-table-column-action-single", key: "actions", label: "" },
            ],
            items: [],
        };
    },
    computed: {
        idPaziente: {
            get: function () {
                return this.$route.query.idPaziente;
            },
            set: function () {},
        },
    },
    methods: {
        onViewPdf(value) {
            this.file = value.file.includes("data:application/pdf;base64,") ? value.file : "data:application/pdf;base64," + value.file;
        },
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "?idRisorsa=";
            me.isBusy = true;
            axios
                .get(link + me.id)
                .then((response) => {
                    me.items = [];
                    me.items = response.data.data;
                    me.rowList = me.items.length;
                    me.perPage = me.data.length;
                })
                .catch(() => {
                    me.showmolalloading = false;
                });
        },
        checkEstensione(item) {
            let returnValue = "download";
            // console.log(item);
            if (item.tipo.includes("application/pdf")) {
                returnValue = "printer";
            }
            return returnValue;
        },
        setIdRisorsa(value) {
            let me = this;
            me.idRisorsa = value;
            me.id = value;
            me.loadData();
        },
    },
};
</script>
