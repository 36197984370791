<template>
    <b-modal title="Nuova Richiesta" ref="mdlNuovaRichiesta" id="mdlNuovaRichiesta" size="lg" modal-class="sa-modal-static-scroll" style="height: 100%; max-height: calc(100vh - 3.5rem)" @show="onShowNuovaRichiesta">
        <div class="sa-flexbox">
            <div class="sa-flexbox-header">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label for="">Struttura Erogatrice:</label>
                        <b-form-select id="inline-form-custom-select-pref" v-model="richiestaEsami.codiceLaboratorio" value-field="codiceLaboratorio" text-field="descrizioneLaboratorio" value="null" class="mb-2 mr-sm-2 mb-sm-0" :options="laboratorioOptions" @change="onChangeStrutturaErogatrice"></b-form-select>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                        <label for="">Data Richiesta:</label>
                        <b-input-group><date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="richiestaEsami.dataRichiesta" disabled></date-picker></b-input-group>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                        <label for="">Data Ora Prelievo:</label>
                        <b-input-group><date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="richiestaEsami.dataOraPrelievo" type="datetime"></date-picker></b-input-group>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label for="">Profilo LIS:</label>
                        <!-- <b-form-input v-model="richiestaEsami.profiloLis" required /> -->
                        <b-form-select id="inline-form-custom-select-pref" v-model="richiestaEsami.profiloLis" value-field="id" text-field="descrizione" value="''" class="mb-2 mr-sm-2 mb-sm-0" :options="profiliLisOptions" @change="onChangeProfiloLis"></b-form-select>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label for="">Quesito Diagnostico:</label>
                        <b-input-group>
                            <b-form-input id="" v-model="richiestaEsami.descrizioneQuesitoDiagnostico">{{ diagnosiScelta.codiceIcdix }}</b-form-input>
                            <b-button size="sm" variant="" @click="onClickBtnRicercaIcdIx()"><b-icon icon="search"></b-icon></b-button>
                        </b-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <label for="">Filtro:</label>
                        <b-form-input id="" v-model="filtroPrestazioni"></b-form-input>
                    </b-col>
                </b-row>
            </div>
            <div class="sa-flexbox-body">
                <div class="b-table-sticky-header">
                    <b-table sticky-header ref="tblRsaSchedaOspiteRefertiComponent" stacked="lg" striped hover selectable select-mode="multi" :filter="filtroPrestazioni" :items="prestazioniAmbulatoriali" :fields="fields" :current-page="1" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table" @row-selected="onRowSelected" @row-clicked="onClickRow" @filtered="onFilteredTable">
                        <template v-slot:cell(codicePrestazione)="{ item }">
                            <span>
                                {{ item.codicePrestazione }}
                            </span>
                        </template>
                        <template v-slot:cell(descrione)="{ item }">
                            <span>{{ item.descrizione }}</span>
                        </template>
                    </b-table>
                </div>
            </div>
            <div class="sa-flexbox-footer"></div>
        </div>

        <template #modal-footer>
            <b-button @click="onSalvaRichiesta" variant="outline-success">Salva</b-button>
            <b-button size="md" class="no-text" variant="outline-danger" @click="onClickUndo">Annulla</b-button>
        </template>
    </b-modal>
</template>

<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
export default {
    components: {
        DatePicker,
    },
    data() {
        return {
            rows: 0,
            perPage: 10000,
            currentPage: 1,
            prestazioniAmbulatoriali: [],
            filtroPrestazioneAmbulatoriali: [],
            laboratorioOptions: [],
            profiliLisOptions: [],
            diagnosiScelta: "",
            idRichiestaEsami: null,
            pathResourcePrestazioniAmbulatoriali: "/rsaprestazioniambulatoriali",
            pathResourceLaboratori: "/rsalaboratori",
            pathResourceProfiliLis: "/rsaprofililis",
            pathResource: "/rsarichiestelaboratorio",
            selectedIndex: [],
            jsonDataAccettazione: null,
            filtroPrestazioni: "",
            clickRiga: false,
            richiestaEsami: {
                codicePrestazione: "",
                codiceOffering: "",
                descrizioneLaboratorio: null,
                codiceLaboratorio: null,
                descrizioneEsame: null,
                dataRichiesta: new Date().getTime(),
                dataOraPrelievo: new Date().getTime(),
                profiloLis: "",
                codiceQuesitoDiagnostico: "",
                descrizioneQuesitoDiagnostico: "",
                tipo: null,
                listaEsami: [],
            },
            fields: [
                { label: "Codice Prestazione", key: "codicePrestazione" },
                { label: "Descrizione Prestazione", key: "descrizione" },
            ],
        };
    },
    methods: {
        setIdRichiestaEsami(value) {
            let me = this;
            me.idRichiestaEsami = value;
        },
        setJsonDataAccettazione(item) {
            let me = this;
            me.jsonDataAccettazione = item;
        },
        loadPrestazioniAmbulatori() {
            let me = this;
            me.prestazioniAmbulatoriali = [];
            let link = process.env.VUE_APP_PATH_API + me.pathResourcePrestazioniAmbulatoriali;
            me.filtroPrestazioneAmbulatoriali.page = me.currentPagePrestazioniAmbulatoriali;
            me.filtroPrestazioneAmbulatoriali.forPage = me.perPagePrestazioniAmbulatoriali;
            me.$emit("beforeLoadData");
            axios
                .get(link, { params: me.filtroPrestazioneAmbulatoriali })
                .then((response) => {
                    me.rowsPrestazioniAmbulatoriali = response.data.data.recordsNumber;
                    me.prestazioniAmbulatoriali = response.data.data.list;
                    me.loadLaboratori();
                })
                .catch(() => {
                    this.$emit("afterLoadData");
                });
        },
        loadLaboratori() {
            let me = this;
            me.laboratori = [];
            let link = process.env.VUE_APP_PATH_API + me.pathResourceLaboratori;
            axios
                .get(link)
                .then((response) => {
                    me.laboratorioOptions = response.data.data.list;
                    me.laboratorioOptions.unshift({ codiceLaboratorio: null, descrizioneLaboratorio: "- Selezionare Laboratorio -" });
                    me.loadProfiliLis();
                })
                .catch(() => {
                    this.$emit("afterLoadData");
                });
        },
        loadProfiliLis() {
            let me = this;
            me.laboratori = [];
            let link = process.env.VUE_APP_PATH_API + me.pathResourceProfiliLis;
            axios
                .get(link)
                .then((response) => {
                    me.profiliLisOptions = response.data.data.list;
                    me.profiliLisOptions.unshift({ id: "", descrizione: "- Selezionare Profilo Lis -" });
                    if (me.idRichiestaEsami === "-1") {
                        this.$emit("afterLoadData");
                    } else {
                        me.loadRichiestaEsami();
                    }
                })
                .catch(() => {
                    this.$emit("afterLoadData");
                });
        },
        loadRichiestaEsami() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.idRichiestaEsami;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.richiestaEsami = response.data.data;
                    me.setSelectedRows(me.richiestaEsami.listaEsami);
                    this.$emit("afterLoadData");
                })
                .catch(() => {
                    this.$emit("afterLoadData");
                });
        },
        setSelectedRows(listaEsami) {
            let me = this;
            me.selectedIndex = [];
            listaEsami.forEach((element) => {
                me.prestazioniAmbulatoriali.forEach((prestazione, index) => {
                    if (element.codicePrestazione === prestazione.codicePrestazione) {
                        me.selectedIndex.push(index);
                    }
                });
            });
            setTimeout(function () {
                me.setSelectedRowsIndex();
            }, 300);
        },
        setSelectedRowsIndex() {
            let me = this;
            me.selectedIndex.forEach((index) => {
                me.$refs.tblRsaSchedaOspiteRefertiComponent.selectRow(index);
            });
        },

        /* setSelectedRowsIndex2() {
            let me = this;
            me.selectedIndex.forEach((index) => {
                me.$refs.tblRsaSchedaOspiteUsciteComponent.selectRow(index);
            });
        }, */
        show() {
            let me = this;
            me.$refs.mdlNuovaRichiesta.show();
        },
        close() {
            let me = this;
            me.$refs.mdlNuovaRichiesta.hide();
        },
        onShowNuovaRichiesta() {
            let me = this;
            me.loadPrestazioniAmbulatori();
        },
        onChangeStrutturaErogatrice(item) {
            let me = this;
            me.richiestaEsami.descrizioneLaboratorio = null;
            if (item) {
                me.laboratorioOptions.forEach((element) => {
                    if (element.codiceLaboratorio === item) {
                        me.richiestaEsami.descrizioneLaboratorio = element.descrizioneLaboratorio;
                    }
                });
            }
        },
        onChangeProfiloLis() {
            // setSelectedRowsIndex() {
            // let me = this;
            // me.selectedIndex
            let me = this;
            if (me.richiestaEsami.profiloLis !== "") {
                me.changeProfiloLis();
            } else {
                me.selectedIndex = [];
                me.loadPrestazioniAmbulatori();
                me.richiestaEsami.listaEsami = [];
                me.listKey++;
            }
        },
        changeProfiloLis() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourcePrestazioniAmbulatoriali;
            let filtro = { idProfiliLis: me.richiestaEsami.profiloLis };
            me.$emit("beforeLoadData");
            axios
                .get(link, { params: filtro })
                .then((response) => {
                    me.setSelectedRows(response.data.data.list);
                    me.listKey++;
                    this.$emit("afterLoadData");
                })
                .catch(() => {
                    this.$emit("afterLoadData");
                });
        },
        onClickRow(item) {
            let me = this;
            let esameSelezionato = { codicePrestazione: item.codicePrestazione, codiceOffering: item.codiceOffering, descrizioneEsame: item.descrizione };
            if (!me.richiestaEsami.listaEsami.some((e) => e.codiceOffering === esameSelezionato.codiceOffering)) {
                me.richiestaEsami.listaEsami.push(esameSelezionato);
            } else {
                me.richiestaEsami.listaEsami = me.richiestaEsami.listaEsami.filter((e) => e.codiceOffering !== esameSelezionato.codiceOffering);
            }
            me.richiestaEsami.codicePrestazione = "";
            me.richiestaEsami.codiceOffering = "";
            me.richiestaEsami.descrizioneEsame = "";
            me.richiestaEsami.listaEsami.forEach((element) => {
                me.richiestaEsami.codiceOffering += element.codiceOffering + "; ";
                me.richiestaEsami.codicePrestazione += element.codicePrestazione + "; ";
                me.richiestaEsami.descrizioneEsame += element.descrizioneEsame + "; ";
            });
        },
        onFilteredTable(items) {
            let me = this;
            me.selectedIndex = [];
            me.richiestaEsami.listaEsami.forEach((element) => {
                items.forEach((prestazione, index) => {
                    if (element.codicePrestazione === prestazione.codicePrestazione) {
                        me.selectedIndex.push(index);
                    }
                });
            });
            setTimeout(function () {
                me.setSelectedRowsIndex();
                me.setSelectedRowsIndex2();
            }, 300);
        },
        onRowSelected() {},
        setRichiestaEsamiListaEsami(items) {
            let me = this;
            me.richiestaEsami.descrizioneEsame = "";
            me.richiestaEsami.listaEsami = [];
            items.forEach((element) => {
                let esameSelezionato = { codicePrestazione: element.codicePrestazione, codiceOffering: element.codiceOffering, descrizioneEsame: element.descrizione };
                me.richiestaEsami.listaEsami.push(esameSelezionato);
                me.richiestaEsami.codiceOffering += element.codiceOffering + "; ";
                me.richiestaEsami.codicePrestazione += element.codicePrestazione + "; ";
                me.richiestaEsami.descrizioneEsame += element.descrizione + "; ";
            });
        },
        onSalvaRichiesta() {
            let me = this;
            if (me.idRichiestaEsami === "-1") {
                me.createRequest();
            } else {
                me.updateRequest();
            }
        },
        createRequest() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.richiestaEsami.idPaziente = me.jsonDataAccettazione.accettazioneAnagrafica.id;
            me.richiestaEsami.idAccettazione = me.jsonDataAccettazione.id;
            me.$emit("beforeSaveRequest");
            axios
                .post(link, JSON.stringify(me.richiestaEsami))
                .then(() => {
                    me.filtro = {};
                    me.$emit("afterSaveRequest");
                    me.$refs.mdlNuovaRichiesta.hide();
                })
                .catch((error) => {
                    me.$emit("afterSaveRequest");
                    let messaggio = error.response ? error.response.data.messaggio : "Errore non gestito";
                    me.$bvModal
                        .msgBoxOk(messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then(() => {})
                        .catch((err) => {
                            console.log(err);
                        });
                });
        },
        updateRequest() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.idRichiestaEsami;
            me.richiestaEsami.idPaziente = me.jsonDataAccettazione.accettazioneAnagrafica.id;
            me.richiestaEsami.idAccettazione = me.jsonDataAccettazione.id;
            me.$emit("beforeSaveRequest");
            axios
                .put(link, JSON.stringify(me.richiestaEsami))
                .then(() => {
                    me.filtro = {};
                    me.$emit("afterSaveRequest");
                    me.$refs.mdlNuovaRichiesta.hide();
                })
                .catch((error) => {
                    me.$emit("afterSaveRequest");
                    let messaggio = error.response ? error.response.data.messaggio : "Errore non gestito";
                    me.$bvModal
                        .msgBoxOk(messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then(() => {})
                        .catch((err) => {
                            console.log(err);
                        });
                });
        },
        onClickUndo() {
            let me = this;
            me.$refs.mdlNuovaRichiesta.hide();
        },
    },
};
</script>

<style></style>
