<template>
    <sa-page-layout :btnNewVisible="false" :btnRefreshVisible="true" @refresh="onRefresh" :pathResource="pathResource" :linkedit="linkedit" :showModalLoading="showModalLoading">
        <template slot="toolbar">
            <b-button class="float-sm-right" variant="success btn-toolbar sa-margin-right" size="sm" @click="onClickNuovoGruppo">
                <b-icon icon="plus"></b-icon>
                Nuovo
            </b-button>
        </template>

        <template slot="table-filter">
            <b-form @submit.prevent="onSubmit">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Profilo</label>
                        <b-form-select v-model="filtroGruppoDashboard.profilo" :options="profiloOptions">
                            <template #first>
                                <b-form-select-option :value="null"> --Seleziona un tipo-- </b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-col>

                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Dashboard</label>
                        <b-form-select v-model="filtroGruppoDashboard.idDashboard" :options="dashboardOptions">
                            <template #first>
                                <b-form-select-option :value="null"> --Seleziona un tipo-- </b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="sa-padding-right pt-3 pb-3 text-right">
                        <b-button type="submit" variant="info"> Cerca </b-button>
                        <b-button type="reset" v-on:click="onReset" variant="danger"> Reset filtri </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </template>

        <template slot="table-body">
            <data-analysis-dashboard-gruppi-list-component ref="cmpDataAnalysisGruppiList" @afterLoadData="onAfterLoadData" />
        </template>
    </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import DataAnalysisDashboardGruppiListComponent from "../components/DataAnalysisDashboardGruppiListComponent.vue";
import UtilityMixin from "../../utility/UtilityMixin";
export default {
    components: { SaPageLayout, DataAnalysisDashboardGruppiListComponent },
    mixins: [UtilityMixin],
    data() {
        return {
            btnNewVisible: true,
            pathResource: "",
            pathResourceGruppi: "/gruppi",
            pathResourceDashboard: "/dataanalysisdashboard",
            filtroGruppoDashboard: { profilo: null, idDashboard: null },
            profiloOptions: [],
            dashboardOptions: [],
            linkedit: "/dataanalysisgruppodashboard",
            showModalLoading: false,
            listKey: 0,
        };
    },
    mounted() {
        let me = this;
        me.loadData();
        this.loadGruppi();
        this.loadDashboardData();
    },
    methods: {
        loadGruppi() {
            let me = this;
            UtilityMixin.methods.loadDataResources(me.pathResourceGruppi, null, me.loadGruppiSuccess, me.loadGruppiError);
        },

        loadGruppiSuccess(response) {
            let me = this;

            if (response.data && response.data.data) {
                me.profiloOptions = response.data.data.map((profilo) => ({
                    value: profilo.nome,
                    text: profilo.nome || profilo.id,
                }));
            } else {
                me.profiloOptions = [];
                console.warn("Lista profili vuota o in formato non previsto");
            }
        },

        loadGruppiError(error) {
            console.error("Errore durante il caricamento dei profili:", error);
            this.$bvToast.toast("Errore nel caricamento dei profili", {
                title: "Errore",
                variant: "danger",
                solid: true,
            });
        },

        loadDashboardData() {
            let me = this;
            let dashboardSearchBean = { limit: 0, page: 0 };
            UtilityMixin.methods.loadDataResources(me.pathResourceDashboard, dashboardSearchBean, me.loadDashboardDataSuccess, me.loadDashboardDataError);
        },
        loadDashboardDataSuccess(response) {
            let me = this;
            /* me.dashboardOptions = response.data.data.list; */
            if (response.data && response.data.data && response.data.data.list) {
                me.dashboardOptions = response.data.data.list.map((dashboard) => ({
                    value: dashboard.id,
                    text: dashboard.title || dashboard.id,
                }));
            } else {
                me.dashboardOptions = [];
                console.warn("Lista dashboard vuota o in formato non previsto");
            }
        },

        loadDashboardDataError(error) {
            console.error("Errore durante il caricamento delle dashboard:", error);
            this.$bvToast.toast("Errore nel caricamento delle dashboard", {
                title: "Errore",
                variant: "danger",
                solid: true,
            });
        },

        loadData() {
            let me = this;
            me.showModalLoading = true;
            me.$refs.cmpDataAnalysisGruppiList.loadData();
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        onAfterLoadData() {
            let me = this;
            me.showModalLoading = false;
        },
        onClickNuovoGruppo() {
            let me = this;
            me.$refs.cmpDataAnalysisGruppiList.onClickNuovoGruppo();
        },

        onSubmit() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtroGruppoDashboard);
            me.loadData();
        },

        onReset() {
            let me = this;
            me.filtroGruppoDashboard = { profilo: null, dashboard: null };
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtroGruppoDashboard);
            me.loadData();
        },
    },
};
</script>
