<template>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <label class="sa-label-data">Username:</label>
                <b-form-input v-model="jsonData.username"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <label class="sa-label-data">Gestore</label>
                <b-form-select v-model="jsonData.gestore" :options="gestoreOptions" :value="null" value-field="value" text-field="text"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <label class="sa-label-data">Tipo</label>
                <b-form-select v-model="jsonData.tipo" :options="tipoOptions" :value="null" value-field="value" text-field="text" @input="activePin()"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <label class="sa-label-data">Pin</label>
                <b-form-input v-model="jsonData.pin" :disabled="disablePin"></b-form-input>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import axios from "axios";
export default {
    props: {
        idUtente: String,
    },
    data() {
        return {
            pathResource: "/digitalsignusers",
            linkEdit: "/graffidiuser/edit/{id}",
            pathTipi: "/digitalsigntipofirma",
            pathGestori: "/digitalsign",
            jsonData: { id: null, username: "", pin: "", gestore: "", tipo: "" },
            gestoreOptions: [],
            tipoOptions: [],
            tipi: [],
            disablePin: true,
        };
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    mounted() {
        let me = this;
        if (me.idUtente) {
            me.$router.replace(me.linkEdit.replace("{id}", me.idUtente)).catch((err) => {
                err;
            });
            me.loadTipo();
            me.loadGestori();
        }
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.idUtente;
            me.showmolalloading = true;
            axios
                .get(link)
                .then((response) => {
                    me.jsonData = response.data.data;
                    me.id = me.jsonData.id;
                    me.activePin();
                    me.showmolalloading = false;
                })
                .catch((err) => {
                    err;
                    me.showmolalloading = false;
                    me.jsonData.id = me.idUtente;
                });
        },
        loadGestori() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathGestori;
            (me.tipoOptions = []),
                axios
                    .get(link)
                    .then((response) => {
                        response.data.data.list.forEach((element) => {
                            me.gestoreOptions.push({ value: element.gestore, text: element.gestore });
                        });
                        me.gestoreOptions.unshift({ value: "", text: "-Seleziona Valore-" });
                    })
                    .catch((err) => {
                        err;
                        me.showmolalloading = false;
                    });
        },
        loadTipo() {
            let me = this;
            me.tipoOptions = [];
            let link = process.env.VUE_APP_PATH_API + me.pathTipi;
            axios
                .get(link)
                .then((response) => {
                    me.tipi = response.data.data.list;
                    response.data.data.list.forEach((element) => {
                        me.tipoOptions.push({ value: element.tipoFirma, text: element.tipoFirma });
                    });
                    me.tipoOptions.unshift({ value: "", text: "-Seleziona Valore-" });
                    me.loadData();
                })
                .catch((err) => {
                    err;
                    me.showmolalloading = false;
                });
        },
        activePin() {
            let me = this;
            me.disablePin = true;
            me.tipi.forEach((element) => {
                if (element.tipoFirma === me.jsonData.tipo) {
                    me.disablePin = element.necessitaOtp === "SI" ? false : true;
                }
            });
        },
    },
};
</script>

<style></style>
