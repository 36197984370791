<template>
  <b-container fluid>
    <b-form @reset="onReset" v-if="show">
      <b-row>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label for="">Nome</label>
          <b-form-input
            v-model="filter.nome"
            type="text"
            placeholder="Nome"
            @input="onFiltraContatti()"
            :formatter="
              (value) => {
                return String(value).toUpperCase();
              }
            "
          ></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label for="">Cognome</label>
          <b-form-input
            v-model="filter.cognome"
            placeholder="Cognome"
            @input="onFiltraContatti()"
            :formatter="
              (value) => {
                return String(value).toUpperCase();
              }
            "
          ></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label for="">Codice Fiscale</label>
          <b-form-input
            v-model="filter.identificativo"
            placeholder="Codice Fiscale"
            @input="onFiltraContatti()"
            :formatter="
              (value) => {
                return String(value).toUpperCase();
              }
            "
          ></b-form-input>
        </b-col>
        <b-col cols="12" lg="6" xl="6">
          <label for="">Settore</label>
          <b-form-select
            id="input-3"
            v-model="filter.idSettore"
            :options="settori"
            value-field="id"
            text-field="descrizione"
            @input="onFiltraContatti()"
          >
            <template #first>
              <b-form-select-option :value="null" disabled>
                - Seleziona Settore -
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
      </b-row>

      <b-row class="mt-2 mb-2" align="right">
        <b-col>
          <b-button type="reset" variant="outline-danger">Reset</b-button>
        </b-col>
      </b-row>
    </b-form>
    <b-table
      sticky-header
      ref="table"
      stacked="lg"
      striped
      hover
      :items="contatti"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      sort-icon-left
      head-variant="light"
      class="sa-b-table"
    >
      <template #cell(identificativo)="{ item }">
        <router-link class="sa-edit-link" :to="'/contatti/view/' + item.id">
          {{ item.cognome }} {{ item.nome }}
        </router-link>
        <br />
        <small> {{ item.identificativo }} </small>
      </template>
      <template v-slot:cell(codicePacchetto)="{ item }">
        <b class="sa-edit-link text-danger">
          {{
            (item.codicePacchetto =
              item.codicePacchetto === null ? "" : item.codicePacchetto)
          }}
        </b>
        <br />
        <small>
          {{
            (item.descrizionePacchetto =
              !item.descrizionePacchetto === null
                ? ""
                : item.descrizionePacchetto)
          }}
        </small>
      </template>
    </b-table>
  </b-container>
</template>

<script>
import axios from "axios";
import UtilityMixins from "../../utility/UtilityMixin";
export default {
  mounted() {
    let me = this;
    me.onFiltraContatti();
    me.loadSettori();
  },
  data() {
    return {
      perPage: 100,
      currentPage: 1,
      show: true,
      filter: {
        nome: "",
        cognome: "",
        identificativo: "",
        idSettore: null,
      },
      fields: [
        {
          label: "Identificativo",
          key: "identificativo",
          tdClass: "text-left",
          sortable: true,
        },
        {
          label: "Pacchetto",
          key: "codicePacchetto",
          tdClass: "text-left",
          sortable: true,
        },

        {
          label: "Data Prenotazione",
          key: "dataOraPrenotazione",
          tdClass: "text-left",
          sortable: true,
          formatter: (value) => {
            return this.formatDateTime(value);
          },
        },
      ],
      settori: {},
      contatti: [],
    };
  },
  mixins: [UtilityMixins],
  methods: {
    onReset(event) {
      event.preventDefault();

      this.filter.nome = "";
      this.filter.cognome = "";
      this.filter.identificativo = "";
      this.filter.idSettore = null;
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
      this.onFiltraContatti();
      this.loadSettori();
    },
    onFiltraContatti() {
      let me = this;
      let linkSettori =
        process.env.VUE_APP_PATH_API + "/prenotazionedettaglioanagrafiche";
      axios.get(linkSettori, { params: me.filter }).then((response) => {
        me.contatti = response.data.data;
      });
    },
    onRefresh() {
      let me = this;
      me.onFiltraContatti();
    },
    loadSettori() {
      let me = this;
      let linkSettori = process.env.VUE_APP_PATH_API + "/settori";
      axios.get(linkSettori).then((response) => {
        me.settori = response.data.data;
      });
    },
  },
};
</script>

<style></style>
