<template>
	<div style="height: 100%">
		<div v-if="idDashboard !== null" style="height: 100%">
			<sa-tabs-scroll-component idTab="tabsDashboardView" classNavItem="nav-item"></sa-tabs-scroll-component>
			<b-tabs id="tabsDashboardView" ref="tabsDashboardView" v-model="activeTabIndex" nav-wrapper-class="sa-tab-items" content-class="mt-3" class="sa-tab" style="height: 100%; padding-bottom: 25px" fill>
				<b-tab v-for="dashboard in elencoDashboard" :title="dashboard.title" :key="dashboard.id">
					<data-analysis-dashboard-view-component :idDashboard="dashboard.id"></data-analysis-dashboard-view-component>
				</b-tab>
			</b-tabs>
		</div>
		<div v-else class="sa-page-dashboard-no-dashboard">
			<div class="sa-page-dashboard-no-dashboard-message">Nessuna Dashboard Configurata</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import DataAnalysisDashboardViewComponent from "../components/DataAnalysisDashboardViewComponent.vue";
import SaTabsScrollComponent from "../../../template/components/utility/SaTabsScrollComponent.vue";
export default {
	components: { DataAnalysisDashboardViewComponent, SaTabsScrollComponent },
	data() {
		return {
			idDashboard: "3",
			pathResource: "/dataanalysisdashboard/view",
			activeTabIndex: 0,
			elencoDashboard: [],
		};
	},
	mounted() {
		this.loadData();
	},
	methods: {
		loadData() {
			let me = this;
			let link = process.env.VUE_APP_PATH_API + me.pathResource;
			axios.get(link)
				.then((response) => {
					me.elencoDashboard = response.data.data.list;
				})
				.catch((e) => {
					console.log(e);
				});
		},
	},
};
</script>

<style>
.sa-tab .tab-content {
	height: 100%;
	padding-bottom: 25px;
}
</style>
