<template>
    <div>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Ittero:</label>
                    <span class="sa-data">{{ jsonData.malattiaDiventatoGiallo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Data Inizio Malattia:</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataInizioMalattia) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Ospedalizzato:</label>
                    <span class="sa-data">{{ jsonData.ospedalizzatoPerMalattia }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Ospedalizzato Durata:</label>
                    <!-- <b-form-select v-model="jsonData.ospedalizzatoPerMalattia" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select> -->
                    <span class="sa-data">{{ jsonData.ospedalizzatoPerMalattiaGiorni }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Durante le 6 settimane precedenti" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Assunzione Farmaci:</label>
                    <span class="sa-data">{{ jsonData.seiSettimanePrimaAssunzioneFarmaci }}</span>
                </b-col>
            </b-row>
            <b-row style="margin-top: 5px">
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-table sticky-header ref="tblFarmaci" stacked="xl" striped hover itemscope :items="jsonData.listaFarmaci" :fields="fieldsFarmaci" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="listaFarmaci">
                            {{ listaFarmaci.index + 1 }}
                        </template>
                        <template #cell(farmaco)="item">
                            <span class="sa-data">{{ item.item.farmaco }}</span>
                        </template>
                        <template #cell(tempo)="item">
                            <span class="sa-data">{{ item.item.tempo }}</span>
                        </template>
                        <!-- <template #cell(actions)="row">
              <b-button v-if="row.index == jsonData.listaFarmaci.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddFarmaci(jsonData.listaFarmaci, row.item)">
                <b-icon icon="plus"></b-icon>
              </b-button>
              <b-button v-if="row.index !== jsonData.listaFarmaci.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(jsonData.listaFarmaci, row)">
                <b-icon icon="trash"></b-icon>
              </b-button>
            </template> -->
                    </b-table>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Consumato Frutti di Mare:</label>
                    <span class="sa-data">{{ jsonData.seiSettimanePrimaConsumoFruttiMare }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Totalmente Crudi/in Parte:</label>
                    <span class="sa-data">{{ jsonData.seiSettimanePrimaConsumoFruttiMareCrudi }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Consumato Frutti di Bosco:</label>
                    <span class="sa-data">{{ jsonData.seiSettimanePrimaConsumoFruttiBosco }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Tipo Frutti di Bosco:</label>
                    <span class="sa-data">{{ jsonData.seiSettimanePrimaConsumoFruttiBoscoDettagli }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Bevuto Acqua di Pozzo/Soergente:</label>
                    <span class="sa-data">{{ jsonData.seiSettimanePrimaConsumoAcquaPozzoSorgente }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Una/piu' Notte/i fuori Citta:</label>
                    <span class="sa-data">{{ jsonData.seiSettimanePrimaNotteFuoriCitta }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Dove:</label>
                    <span class="sa-data">{{ jsonData.seiSettimanePrimaNotteFuoriCittaDove }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Quando:</label>
                    <span class="sa-data">{{ formatDate(jsonData.seiSettimanePrimaNotteFuoriCittaQuando) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Vaccino Epatite A" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Somministrata Almeno Una Dose:</label>
                    <span class="sa-data">{{ jsonData.doseVaccinoEpatiteA }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Tipo Vaccino Prima Dose:</label>
                    <span class="sa-data">{{ jsonData.doseVaccinoEpatiteAPrimaDoseTipo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Data Prima Dose:</label>
                    <span class="sa-data">{{ formatDate(jsonData.doseVaccinoEpatiteAPrimaDoseData) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Tipo Vaccino Seconda Dose:</label>
                    <span class="sa-data">{{ jsonData.doseVaccinoEpatiteASecondaDoseTipo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Data Seconda Dose:</label>
                    <span class="sa-data">{{ formatDate(jsonData.doseVaccinoEpatiteASecondaDoseData) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Durante i 6 mesi precedenti" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Somministrazione Sangue/Plasma:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaSomministrazioneSanguePlasma }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Somministrazione Altri Derivati:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaSomministrazioneAltriDerivati }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Interventi Chirurgici:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaInterventiChirurgici }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Tipo Intervento:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaInterventiChirurgiciTipo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Luogo Intervento:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaInterventiChirurgiciLuogo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Endoscopia:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaEcoendoscopia }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Tipo Endoscopia:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaEcoendoscopiaTipo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Luogo Endoscopia:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaEcoendoscopiaLuogo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Ospedalizzato:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaOspedalizzato }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Ospedale:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaOspedalizzatoOspedale }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Reparto:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaOspedalizzatoReparto }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data:</label>
                    <span class="sa-data">{{ formatDate(jsonData.seiMesiPrimaOspedalizzatoData) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Uso Droghe:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaUsoDroga }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Modalita Assunzione:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaUsoDrogaModalitaAssunzione }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Contatto SERT:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaUsoDrogaContattoSert }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Trattamento Dentario:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaTrattamentoDentario }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Buchi Orecchie/Piercing:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaPiercing }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Tatuaggi:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaTatuaggio }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Pratica Agopuntura:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaAgopuntura }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Rasato dal Barbiere:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaRasatoBarbiere }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Callista/Manicure:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaCallistaManicure }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Emodializzato:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaEmodializzato }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Vaccino Epatite B" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Somministrata Almeno Una Dose:</label>
                    <span class="sa-data">{{ jsonData.doseVaccinoEpatiteB }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Tipo Vaccino Prima Dose:</label>
                    <span class="sa-data">{{ jsonData.doseVaccinoEpatiteBPrimaDoseTipo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Data Prima Dose:</label>
                    <span class="sa-data">{{ formatDate(jsonData.doseVaccinoEpatiteBPrimaDoseData) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Tipo Vaccino Seconda Dose:</label>
                    <span class="sa-data">{{ jsonData.doseVaccinoEpatiteBSecondaDoseTipo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Data Seconda Dose:</label>
                    <span class="sa-data">{{ formatDate(jsonData.doseVaccinoEpatiteBSecondaDoseData) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Tipo Vaccino Terza Dose:</label>
                    <span class="sa-data">{{ jsonData.doseVaccinoEpatiteBTerzaDoseTipo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Data Terza Dose:</label>
                    <span class="sa-data">{{ formatDate(jsonData.doseVaccinoEpatiteBTerzaDoseData) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Contatto Persone con Epatite 6 Mesi Prima:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaContattoConoscentiInfetti }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Malattia Iniziata Meno di Due Mesi Prima:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaContattoConoscentiInfettiInizioDueMesi }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Conviventi/Pratner Sessuali HbsAg Positivo:</label>
                    <span class="sa-data">{{ jsonData.conviventePatnerSessualeHbsagPositivo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Tipo Relazione:</label>
                    <span class="sa-data">{{ jsonData.conviventePatnerSessualeHbsagPositivoTipoRelazione }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">A Conoscenza:</label>
                    <span class="sa-data">{{ jsonData.conviventePatnerSessualeHbsagPositivoConoscenza }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Motivo Mancata Vaccinazione:</label>
                    <span class="sa-data">{{ jsonData.conviventePatnerSessualeHbsagPositivoConoscenzaVaccino }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Altro</label>
                    <span class="sa-data">{{ jsonData.conviventePatnerSessualeHbsagPositivoConoscenzaVaccinoAl }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Conviventi/Pratner Sessuali anti-HCV Positivo:</label>
                    <span class="sa-data">{{ jsonData.conviventePatnerSessualeAntiHcvPisitivo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Convivente Tossicodipentente:</label>
                    <span class="sa-data">{{ jsonData.conviventeTossicodipendente }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Partner Sessuali 6 mesi precedenti:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaPartnerSessuali }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Numero Partner:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaPartnerSessualiQuanti }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Partner Sessuale Tossicodipentente 6 mesi precedenti:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaPartnerSessualiTossicodipendenti }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Rapporti Occasionali 6 mesi precedenti:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaPartnerSessualiOccasionali }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Uso Profilattico:</label>
                    <span class="sa-data">{{ jsonData.seiMesiPrimaPartnerSessualiOccasionaliProfilattico }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Numero Persone in Casa:</label>
                    <span class="sa-data">{{ jsonData.numeroPersoneCasa }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Presenti Bambino che frequentano Asilo/Scuola Materna:</label>
                    <span class="sa-data">{{ jsonData.bambiniFrequentanoAsiloNodo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Donatore Periodico di Sangue:</label>
                    <span class="sa-data">{{ jsonData.donatorePeriodicoSangue }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">In Passato Eseguito Test Hiv:</label>
                    <span class="sa-data">{{ jsonData.testHivPassato }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Risutato Positivo:</label>
                    <span class="sa-data">{{ jsonData.testHivPassatoRisultatoPositivo }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Valori Massimi Esami Laboratorio" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">AST:</label>
                    <span class="sa-data">{{ jsonData.valoriMassimiEsameAst }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">ALT:</label>
                    <span class="sa-data">{{ jsonData.valoriMassimiEsameAlt }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Bilirubina Totale:</label>
                    <span class="sa-data">{{ jsonData.valoriMassimiEsameBilirubinaTotale }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Bilirubina Diretta:</label>
                    <span class="sa-data">{{ jsonData.valoriMassimiEsameBilirubinaDiretta }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">INR:</label>
                    <span class="sa-data">{{ jsonData.valoriMassimiEsameInr }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Risultati di Laboratorio" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Prelievo:</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataPrelievoSangue) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Positivo HbsAg:</label>
                    <span class="sa-data">{{ jsonData.positivoHbsag }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Positivo IgM anti-HBC:</label>
                    <span class="sa-data">{{ jsonData.positivoIgmAntiHbc }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Positivo IgM anti-epatite A:</label>
                    <span class="sa-data">{{ jsonData.positivoIgmAntiEpatiteA }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Positivo IgM anti-delta:</label>
                    <span class="sa-data">{{ jsonData.positivoIgmAntiDelta }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Positivo anti-HCV:</label>
                    <span class="sa-data">{{ jsonData.positivoAntiHcv }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Positivo HCV-RNA:</label>
                    <span class="sa-data">{{ jsonData.positivoHcvRna }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Livello Viremia:</label>
                    <span class="sa-data">{{ jsonData.positivoHcvRnaLivelloViremia }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Positivo IGM anti-Epatite E:</label>
                    <span class="sa-data">{{ jsonData.positivoIgmAntiEpatiteE }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Decorso Clinico" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Encefalopatia:</label>
                    <span class="sa-data">{{ jsonData.encefalopatia }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Fulminante:</label>
                    <span class="sa-data">{{ jsonData.fullminante }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Trapianto di Fegato:</label>
                    <span class="sa-data">{{ jsonData.trapiantoFegato }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Decesso:</label>
                    <span class="sa-data">{{ jsonData.decesso }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Decesso:</label>
                    <span class="sa-data">{{ formatDate(jsonData.decessoData) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Causa Iniziale Decesso:</label>
                    <span class="sa-data">{{ jsonData.decessoCausaIniziale }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Caso di:</label>
                    <span class="sa-data">{{ jsonData.casoMalattia }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Specie e/o Sierotipo:</label>
                    <span class="sa-data">{{ jsonData.casoMalattiaSpecieSierotipo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Lavoro Settore Alimentare:</label>
                    <span class="sa-data">{{ jsonData.lavoroSettoreAlimentare }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Specificare Dove e Occupazione:</label>
                    <span class="sa-data">{{ jsonData.lavoroSettoreAlimentareOccupazione }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Comunita' frequentate 10 giorni precedenti:</label>
                    <span class="sa-data">{{ jsonData.comunitaFrequentateDieciGiorniPrecedenti }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Se Studente Classe e Sezione:</label>
                    <span class="sa-data">{{ jsonData.comunitaFrequentateDieciGiorniPrecedentiStudenteClasseSe }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Frequnza Comunita' Fino Al:</label>
                    <span class="sa-data">{{ formatDate(jsonData.comunitaFrequentateDieciGiorniPrecedentiFinoAl) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="informazioni Cliniche" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Comparsa Sintomi:</label>
                    <span class="sa-data">{{ formatDate(jsonData.sintomiDataComparsa) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Sintomi Ancora Presenti:</label>
                    <span class="sa-data">{{ jsonData.sintomiAncoraPresenti }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Vomito:</label>
                    <span class="sa-data">{{ jsonData.sintomiVomito }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Vomito Data:</label>
                    <span class="sa-data">{{ jsonData.sintomiVomitoDataOra }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Crampi Addominali:</label>
                    <span class="sa-data">{{ jsonData.sintomiCrampiAddome }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Crampi Addominali Data:</label>
                    <span class="sa-data">{{ formatDateTime(jsonData.sintomiCrampiAddomeDataOra) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Diarrea:</label>
                    <span class="sa-data">{{ jsonData.sintomiDiarrea }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Diarrea Data:</label>
                    <span class="sa-data">{{ formatDateTime(jsonData.sintomiDiarreaDataOra) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Diarrea Con Sangue:</label>
                    <span class="sa-data">{{ jsonData.sintomiDiarreaSangue }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Diarrea Con Sangue Data:</label>
                    <span class="sa-data">{{ formatDateTime(jsonData.sintomiDiarreaSangueDataOra) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Diarrea Con Sangue Scariche Giornaliere:</label>
                    <span class="sa-data">{{ jsonData.sintomiDiarreaNumeroScariche }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Diarrea Con Muco:</label>
                    <span class="sa-data">{{ jsonData.sintomiDiarreaMuco }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Diarrea Con Muco Data:</label>
                    <span class="sa-data">{{ formatDate(jsonData.sintomiDiarreaMucoDataOra) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Febbre:</label>
                    <span class="sa-data">{{ jsonData.sintomiFebbre }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Febbre Data:</label>
                    <span class="sa-data">{{ formatDateTime(jsonData.sintomiFebbreDataOra) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Disidratazione:</label>
                    <span class="sa-data">{{ jsonData.sintomiDisidratazione }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Disidratazione Data:</label>
                    <span class="sa-data">{{ formatDateTime(jsonData.sintomiDisidratazioneDataOra) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Mal Di Testa/Astenia:</label>
                    <span class="sa-data">{{ jsonData.sintomiMalTestaAstenia }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Mal Di Testa/Astenia Data:</label>
                    <span class="sa-data">{{ formatDateTime(jsonData.sintomiMalTestaAsteniaDataOra) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Altri Sintomi:</label>
                    <span class="sa-data">{{ jsonData.sintomiAltro }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Sintomo Predominante:</label>
                    <span class="sa-data">{{ jsonData.sintomoPredominante }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Contattato Medico di Base:</label>
                    <span class="sa-data">{{ jsonData.rivoltoMedicoFamiglia }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Contattato Medico di Base Data:</label>
                    <span class="sa-data">{{ formatDate(jsonData.rivoltoMedicoFamigliaQuando) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Diagnosi:</label>
                    <span class="sa-data">{{ jsonData.rivoltoMedicoFamigliaDiagnosiMedico }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Pronto Soccorso:</label>
                    <span class="sa-data">{{ jsonData.andatoProntoSoccorso }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Pronto Soccorso Data:</label>
                    <span class="sa-data">{{ formatDate(jsonData.andatoProntoSoccorsoQuando) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Diagnosi Dimissione:</label>
                    <span class="sa-data">{{ jsonData.andatoProntoSoccorsoDiagnosiDimissione }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Ricovero Ospedale:</label>
                    <span class="sa-data">{{ jsonData.ricoveroOspedale }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Ricovero Ospedaliero Motivo:</label>
                    <span class="sa-data">{{ jsonData.ricoveroOspedaleMotivo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Ricovero Ospedaliero Reparto:</label>
                    <span class="sa-data">{{ jsonData.ricoveroOspedaleReparto }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Pronto Soccorso Data Dimissioni:</label>
                    <span class="sa-data">{{ formatDate(jsonData.ricoveroOspedaleDataDimissioni) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Indagine Laboratorio:</label>
                    <span class="sa-data">{{ jsonData.eseguitoIndagineLaborario }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Indagine Laboratorio Richiedente:</label>
                    <span class="sa-data">{{ jsonData.eseguitoIndagineLaborarioRichiedente }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Indagine Laboratorio Esito:</label>
                    <span class="sa-data">{{ jsonData.eseguitoIndagineLaborarioEsito }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Assunto Antibiotici Prima Dell'Esame:</label>
                    <span class="sa-data">{{ jsonData.primaEsemeAssuntoAntibiotici }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Contatti ed Esposti" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Familiare/Amico/Conoscente Con Medesima Sintomatologia:</label>
                    <span class="sa-data">{{ jsonData.contattiStessaSintomatologia }}</span>
                </b-col>
            </b-row>
            <b-table sticky-header ref="tblViaggi" stacked="xl" striped hover itemscope :items="jsonData.listaContatti" :fields="fieldsContatti" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                <template #cell(index)="jsonData">
                    {{ jsonData.index + 1 }}
                </template>
                <template #cell(cognome)="item">
                    <span class="sa-data">{{ item.item.cognome }}</span>
                </template>
                <template #cell(nome)="item">
                    <span class="sa-data">{{ item.item.nome }}</span>
                </template>
                <template #cell(comune)="item">
                    <span class="sa-data">{{ item.item.comune }}</span>
                </template>
                <template #cell(indirizzo)="item">
                    <span class="sa-data">{{ item.item.indirizzo }}</span>
                </template>
                <template #cell(telefono)="item">
                    <span class="sa-data">{{ item.item.telefono }}</span>
                </template>
            </b-table>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Consumato Pasti/Esperienze Comuni:</label>
                    <span class="sa-data">{{ jsonData.contattiStessaSintomatologiaConsumatoPasti }}</span>
                </b-col>
            </b-row>
            <b-table sticky-header ref="tblViaggi" stacked="xl" striped hover itemscope :items="jsonData.listaEsperienzeComuni" :fields="fieldsEsperienzeComuni" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                <template #cell(index)="jsonData">
                    {{ jsonData.index + 1 }}
                </template>
                <template #cell(pastoEsperienzaComune)="item">
                    <span class="sa-data">{{ item.item.pastoEsperienzaComune }}</span>
                </template>
                <template #cell(quando)="item">
                    <span class="sa-data">{{ item.item.quando }}</span>
                </template>
                <template #cell(dove)="item">
                    <span class="sa-data">{{ item.item.dove }}</span>
                </template>
            </b-table>
        </b-card>
        <b-card class="sa-card" header="Comportamenti" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Viaggi nei 5 Giorni Precedenti:</label>
                    <span class="sa-data">{{ jsonData.viaggiCinqueGiorniPrecedenti }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Dove:</label>
                    <span class="sa-data">{{ jsonData.viaggiCinqueGiorniPrecedentiDove }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Alloggio:</label>
                    <span class="sa-data">{{ jsonData.viaggiCinqueGiorniPrecedentiAlloggio }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Consumato Pasti in Questi Luoghi/Strutture:</label>
                    <span class="sa-data">{{ jsonData.viaggiCinqueGiorniPrecedentiConsumoPasti }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Dove:</label>
                    <span class="sa-data">{{ jsonData.viaggiCinqueGiorniPrecedentiConsumoPastiDove }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Quando:</label>
                    <span class="sa-data">{{ formatDate(jsonData.viaggiCinqueGiorniPrecedentiConsumoPastiQuando) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Altre Pesone con Stessi Sintomi in Struttura:</label>
                    <span class="sa-data">{{ jsonData.viaggiCinqueGiorniPrecedentiSintomiPersone }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Quando:</label>
                    <span class="sa-data">{{ formatDate(jsonData.viaggiCinqueGiorniPrecedentiSintomiPersoneQuando) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Animali Domestici:</label>
                    <span class="sa-data">{{ jsonData.animaliDomestici }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox v-model="jsonData.animaliDomesticiCane" :checked="jsonData.animaliDomesticiCane" switch disabled>Cani</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox v-model="jsonData.animaliDomesticiGatto" :checked="jsonData.animaliDomesticiGatto" switch disabled>Gatti</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox v-model="jsonData.animaliDomesticiUccelli" :checked="jsonData.animaliDomesticiUccelli" switch disabled>Uccelli</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox v-model="jsonData.animaliDomesticiCricetiOrsettiRussi" :checked="jsonData.animaliDomesticiCricetiOrsettiRussi" switch disabled>Criceti/ Orsetti Russi</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox v-model="jsonData.animaliDomesticiTartarughe" :checked="jsonData.animaliDomesticiTartarughe" switch disabled>Tartarughe</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Altro Animale Domestico:</label>
                    <span class="sa-data">{{ jsonData.animaliDomesticiTipoAltro }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Manifestato Diarrea Prima Che si Ammalasse:</label>
                    <span class="sa-data">{{ jsonData.animaliDomesticiDiarrea }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Lavoro a Contatto Con Animali e Feci:</label>
                    <span class="sa-data">{{ jsonData.contattoAnimaliFeci }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Vive in Azienda Agricola/Campagna:</label>
                    <span class="sa-data">{{ jsonData.viveAziendaAgricolaCampagna }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox v-model="jsonData.toccatoAnimaliMucche" :checked="jsonData.toccatoAnimaliMucche" switch disabled>Toccato Mucche</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox v-model="jsonData.toccatoAnimaliPolliGalline" :checked="jsonData.toccatoAnimaliPolliGalline" switch disabled>Toccato Polli/Galline</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox v-model="jsonData.toccatoAnimaliMaiali" :checked="jsonData.toccatoAnimaliMaiali" switch disabled>Toccato Maiali</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox v-model="jsonData.toccatoAnimaliPecore" :checked="jsonData.toccatoAnimaliPecore" switch disabled>Toccato Pecore</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox v-model="jsonData.toccatoAnimaliRoditori" :checked="jsonData.toccatoAnimaliRoditori" switch disabled>Toccato Roditori</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Toccato Altri Animali:</label>
                    <span class="sa-data">{{ jsonData.toccatoAnimaliAltro }}</span>
                </b-col>
            </b-row>
            <h4>Consumo di Acqua</h4>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox v-model="jsonData.tipoAcquaBevutoAcquedottoComunale" :checked="jsonData.tipoAcquaBevutoAcquedottoComunale" switch disabled>Acquedotto Comunale</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox v-model="jsonData.tipoAcquaBevutoAcquaMineraleBottiglia" :checked="jsonData.tipoAcquaBevutoAcquaMineraleBottiglia" switch disabled>Minerale In Bottiglia</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox v-model="jsonData.tipoAcquaBevutoAcquaAcquaSorgene" :checked="jsonData.tipoAcquaBevutoAcquaAcquaSorgene" switch disabled>Sorgente</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox v-model="jsonData.tipoAcquaBevutoAcquaAcquaCorsiSuperficialiNonTrattata" :checked="jsonData.tipoAcquaBevutoAcquaAcquaCorsiSuperficialiNonTrattata" switch disabled>Corsi Superficiali Non Trattata</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox v-model="jsonData.tipoAcquaBevutoAcquaAcquaPozzoNonTrattata" :checked="jsonData.tipoAcquaBevutoAcquaAcquaPozzoNonTrattata" switch disabled>Pozzo Non Trattata</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Pozzo di Sua Proprieta':</label>
                    <span class="sa-data">{{ jsonData.tipoAcquaBevutoCinqueGiorniPrecedentiPozzo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Ultimo Controllo Potabilita':</label>
                    <span class="sa-data">{{ jsonData.tipoAcquaBevutoCinqueGiorniPrecedentiPozzoControlloPota }}</span>
                </b-col>
            </b-row>
            <h4>Acquisto e Consumo di Prodotti Alimentari</h4>
            <b-row>
                <b-table sticky-header ref="tblViaggi" stacked="xl" striped hover itemscope :items="jsonData.listaNegozi" :fields="fieldsNegozi" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                    <template #cell(index)="jsonData">
                        {{ jsonData.index + 1 }}
                    </template>
                    <template #cell(negozio)="item">
                        <span class="sa-data">{{ item.item.negozio }}</span>
                    </template>
                    <template #cell(sempre)="item">
                        <b-form-checkbox v-model="item.item.sempre" :checked="item.item.sempre" switch disabled></b-form-checkbox>
                    </template>
                    <template #cell(spesso)="item">
                        <b-form-checkbox v-model="item.item.spesso" :checked="item.item.spesso" switch disabled></b-form-checkbox>
                    </template>
                    <template #cell(qualcheVolta)="item">
                        <b-form-checkbox v-model="item.item.qualcheVolta" :checked="item.item.qualcheVolta" switch disabled></b-form-checkbox>
                    </template>
                </b-table>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Partecipato Eventi/Ritrovi/Manifestazioni:</label>
                    <span class="sa-data">{{ jsonData.cinqueGiorniPartecipatoEventiRitroviManifestazioni }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Dove:</label>
                    <span class="sa-data">{{ jsonData.cinqueGiorniPartecipatoEventiRitroviManifestazioniDove }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Quando:</label>
                    <span class="sa-data">{{ jsonData.cinqueGiorniPartecipatoEventiRitroviManifestazioniQundo }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Nei Giorni Precedenti l'inizio dei sintomi" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox v-model="jsonData.giorniPrecedentiManeggiatoLavatoCarneCruda" :checked="jsonData.giorniPrecedentiManeggiatoLavatoCarneCruda" switch disabled>Mangiato/Lavato Carne Cruda</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox v-model="jsonData.giorniPrecedentiManeggiatoLavatoUova" :checked="jsonData.giorniPrecedentiManeggiatoLavatoUova" switch disabled>Mangiato/Lavato Uova</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox v-model="jsonData.giorniPrecedentiManeggiatoLavatoVerduraNonLavata" :checked="jsonData.giorniPrecedentiManeggiatoLavatoVerduraNonLavata" switch disabled>Mangiato/Lavato Verdura non Lavata</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox v-model="jsonData.giorniPrecedentiManeggiatoLavatoPollo" :checked="jsonData.giorniPrecedentiManeggiatoLavatoPollo" switch disabled>Mangiato/Lavato Pollo</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Utilizzato Stessi Utensili per Alimenti Diversi:</label>
                    <span class="sa-data">{{ jsonData.giorniPrecedentiUtilizzoStessiUtensiliDiversiAlimenti }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Quali:</label>
                    <span class="sa-data">{{ jsonData.giorniPrecedentiUtilizzoStessiUtensiliDiversiAlimentiDes }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Indagine Consumo Alimenti Carne" header-tag="header" footer-tag="footer" title="">
            <indagine-epidemiologica-infezione-alimentare-alimenti-component :isEdit="false" :listaAlimentiConsumati="jsonData.listaAlimentiConsumatiCarne" famigliaAlimenti="CARNE" @update="onUpdateJsonDataListaAlimenti"></indagine-epidemiologica-infezione-alimentare-alimenti-component>
        </b-card>
        <b-card class="sa-card" header="Indagine Consumo Alimenti Pesce" header-tag="header" footer-tag="footer" title="">
            <indagine-epidemiologica-infezione-alimentare-alimenti-component :isEdit="false" :listaAlimentiConsumati="jsonData.listaAlimentiConsumatiPesce" famigliaAlimenti="PESCE" @update="onUpdateJsonDataListaAlimenti"></indagine-epidemiologica-infezione-alimentare-alimenti-component>
        </b-card>
        <b-card class="sa-card" header="Indagine Consumo Alimenti Uova" header-tag="header" footer-tag="footer" title="">
            <indagine-epidemiologica-infezione-alimentare-alimenti-component :isEdit="false" :listaAlimentiConsumati="jsonData.listaAlimentiConsumatiUova" famigliaAlimenti="UOVA" @update="onUpdateJsonDataListaAlimenti"></indagine-epidemiologica-infezione-alimentare-alimenti-component>
        </b-card>
        <b-card class="sa-card" header="Indagine Consumo Alimenti Latte/Latticini" header-tag="header" footer-tag="footer" title="">
            <indagine-epidemiologica-infezione-alimentare-alimenti-component :isEdit="false" :listaAlimentiConsumati="jsonData.listaAlimentiConsumatiLatteLatticini" famigliaAlimenti="LATTE/LATTICINI" @update="onUpdateJsonDataListaAlimenti"></indagine-epidemiologica-infezione-alimentare-alimenti-component>
        </b-card>
        <b-card class="sa-card" header="Indagine Consumo Alimenti Verdure" header-tag="header" footer-tag="footer" title="">
            <indagine-epidemiologica-infezione-alimentare-alimenti-component :isEdit="false" :listaAlimentiConsumati="jsonData.listaAlimentiConsumatiVerdure" famigliaAlimenti="VERDURE" @update="onUpdateJsonDataListaAlimenti"></indagine-epidemiologica-infezione-alimentare-alimenti-component>
        </b-card>
        <b-card class="sa-card" header="Indagine Consumo Alimenti Prodotti dolci da forno" header-tag="header" footer-tag="footer" title="">
            <indagine-epidemiologica-infezione-alimentare-alimenti-component :isEdit="false" :listaAlimentiConsumati="jsonData.listaAlimentiConsumatiProdottiDolciForno" famigliaAlimenti="PRODOTTI DOLCI E DA FORNO" @update="onUpdateJsonDataListaAlimenti"></indagine-epidemiologica-infezione-alimentare-alimenti-component>
        </b-card>
        <b-card class="sa-card" header="Indagine Consumo Alimenti ALIMENTI PRONTI AL CONSUMO/  ALIMENTI TRAFORMATI REFRIGERATI CON DURATA PROLUNGATA" header-tag="header" footer-tag="footer" title="">
            <indagine-epidemiologica-infezione-alimentare-alimenti-component :isEdit="false" :listaAlimentiConsumati="jsonData.listaAlimentiConsumatiProdottiProntiCosumo" famigliaAlimenti="ALIMENTI PRONTI AL CONSUMO/  ALIMENTI TRAFORMATI REFRIGERATI CON DURATA PROLUNGATA" @update="onUpdateJsonDataListaAlimenti"></indagine-epidemiologica-infezione-alimentare-alimenti-component>
        </b-card>
        <b-card class="sa-card" header="Conclusioni ed ulteriori azioni" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <b-form-checkbox v-model="jsonData.azioniIntraprendereAlimenti" :checked="jsonData.azioniIntraprendereAlimenti" switch disabled>Da Alimenti</b-form-checkbox>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Alimento Sospetto:</label>
                    <span class="sa-data">{{ jsonData.azioniIntraprendereAlimentiSospetto }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Comportamenti a Rischio:</label>
                    <span class="sa-data">{{ jsonData.azioniIntraprendereAlimentiComportamentiRischioPreparazion }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <b-form-checkbox v-model="jsonData.azioniIntraprendereNonAlimenti" :checked="jsonData.azioniIntraprendereNonAlimenti" switch disabled>Non Da Alimenti</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Dettaglio:</label>
                    <span class="sa-data">{{ jsonData.azioniIntraprendereNonAlimentiTipo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Ulteriori Azioni:</label>
                    <span class="sa-data">{{ jsonData.ulterioreAzione }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Data:</label>
                    <span class="sa-data">{{ formatDate(jsonData.ulterioreAzioneData) }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Altre Considerazioni</label>
                    <span class="sa-data">{{ jsonData.altreConsiderazioni }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Dati Notifica" header-tag="header" footer-tag="footer" title="">
            <indagine-epidemiologica-dati-notifica-component :jsonData="jsonData" :isEdit="false"></indagine-epidemiologica-dati-notifica-component>
        </b-card>
    </div>
</template>
<script>
import axios from "axios";
import UtilityMixin from "../../../utility/UtilityMixin";
import IndagineEpidemiologicaDatiNotificaComponent from "./IndagineEpidemiologicaDatiNotificaComponent.vue";
import IndagineEpidemiologicaInfezioneAlimentareAlimentiComponent from "../components/IndagineEpidemiologicaInfezioneAlimentareAlimentiComponent.vue";
export default {
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    components: { IndagineEpidemiologicaDatiNotificaComponent, IndagineEpidemiologicaInfezioneAlimentareAlimentiComponent },
    mixins: [UtilityMixin],
    data() {
        return {
            pathResource: "/malattieinfettiveieepatitea",
            linkback: null,
            currentPage: 1,
            perPage: 100,
            jsonData: {},
            fieldsViaggi: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Luogo",
                    key: "dove",
                    sortable: false,
                },
                {
                    label: "Dal",
                    key: "giornoDal",
                    thStyle: "width: 11rem",
                    sortable: false,
                },
                {
                    label: "Al",
                    key: "giornoAl",
                    thStyle: "width: 11rem",
                    sortable: false,
                },
                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 4rem",
                    tdClass: "text-center",
                },
            ],
            fieldsFarmaci: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Farmaco",
                    key: "farmaco",
                    sortable: false,
                },
                {
                    label: "Tempo",
                    key: "tempo",
                    thStyle: "width: 11rem",
                    sortable: false,
                },
            ],
            fieldsContatti: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Cognome",
                    key: "cognome",
                    thStyle: "width: 20rem",
                    sortable: false,
                },
                {
                    label: "Nome",
                    key: "nome",
                    thStyle: "width: 20rem",
                    sortable: false,
                },
                {
                    label: "Comune",
                    key: "comune",
                    thStyle: "width: 10rem",
                    sortable: false,
                },
                {
                    label: "Indirizzo",
                    key: "indirizzo",
                    // thStyle: "width: 5rem",
                    sortable: false,
                },
                {
                    label: "Telefono",
                    key: "telefono",
                    // thStyle: "width: 5rem",
                    sortable: false,
                },
            ],
            fieldsEsperienzeComuni: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Pasto/Esperienza Comune",
                    key: "pastoEsperienzaComune",
                    thStyle: "width: 11rem",
                    sortable: false,
                },
                {
                    label: "Quando",
                    key: "quando",
                    thStyle: "width: 11rem",
                    sortable: false,
                },
                {
                    label: "Dove",
                    key: "dove",
                    thStyle: "width: 11rem",
                    sortable: false,
                },
                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 4rem",
                    tdClass: "text-center",
                },
            ],
            fieldsNegozi: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Negozio",
                    key: "negozio",
                    // thStyle: "width: 11rem",
                    sortable: false,
                },
                {
                    label: "Sempre",
                    key: "sempre",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
                {
                    label: "Spesso",
                    key: "spesso",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
                {
                    label: "Qualche Volta",
                    key: "qualcheVolta",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 4rem",
                    tdClass: "text-center",
                },
            ],
            fieldsAlimenti: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Alimento",
                    key: "alimento",
                    thStyle: "width: 11rem",
                    sortable: false,
                },
                {
                    label: "Sottotipo/Modalita",
                    key: "sottotipoModalita",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
                {
                    label: "Marca",
                    key: "marca",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
                {
                    label: "L. Acquisto",
                    key: "luogoAcquisto",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
                {
                    label: "D. Acquisto",
                    key: "dataAcquisto",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
                {
                    label: "L. Preparazione",
                    key: "luogoPreparazione",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
                {
                    label: "D. Preparazione",
                    key: "dataPreparazione",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
                {
                    label: "L. Consumo",
                    key: "luogoConsumo",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
                {
                    label: "D. Consumo",
                    key: "dataConsumo",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
                {
                    label: "Tipologia",
                    key: "tipologia",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
            ],
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient() {
            return UtilityMixin.methods.getLinkPatient(this.utente, this.idPaziente);
        },
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.setDatiMedico();
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                me.showModalLoading = true;
                axios
                    .get(link + me.id)
                    .then((response) => {
                        me.jsonData = response.data.data;
                        me.showModalLoading = false;
                    })
                    .catch(() => {
                        me.showModalLoading = false;
                    });
            }
        },
    },
};
</script>
