<template>
  <sa-page-layout :showModalLoading="showModalLoading" :pathResource="pathResource" :btnBackVisible="true" :btnEditVisible="true" :linkback="linkback" :linkedit="linkedit" :data="jsonData">
    <template slot="toolbar-title">Dettaglio {{ jsonData.descrizione }}</template>
    <template slot="table-body">
      <div class="sa-div-scroll sa-scroll">
        <magazzino-view-component :jsonData="jsonData"></magazzino-view-component>
        <b-table sticky-header ref="table" :filter="filter" stacked="lg" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
        </b-table>
      </div>
    </template>
  </sa-page-layout>
</template>

<script>
import MagazzinoViewComponent from "../components/MagazzinoViewComponent.vue";
import SaPageLayout from "../../../template/layout/components/SaPageLayout.vue";
import axios from "axios";
export default {
  // components: { SaPageLayout},
  components: { SaPageLayout, MagazzinoViewComponent },
  data() {
    return {
      pathResource: "/magazzino",
      linkback: "/magazzino",
      linkedit: null,
      perPage: 100,
      currentPage: 1,
      filter: {},
      items: [],
      showModalLoading: false,
      regioniOptions: [],
      provinceOptions: [{ provincia: "-Seleziona Provincia-", sigla: "" }],
      comuniOptions: [{ comune: "-Seleziona Comune-", codiceIstat: "" }],
      statiOptions: [
        { text: "ATTIVO", field: "ATTIVO" },
        { text: "SOSPESO", field: "SOSPESO" },
        { text: "CHIUSO", field: "CHIUSO" },
      ],
      jsonData: { descrizione: "", regione: "", provincia: "", comune: "", stato: "ATTIVO" },
      fields: [
        {
          label: "Tipo Movimento",
          key: "tipoMovimento",
          sortable: true,
        },
        {
          label: "Quantita",
          key: "quantita",
          sortable: true,
        },
        {
          label: "Utente",
          key: "proprietario",
          sortable: true,
        },
        {
          label: "Note",
          key: "note",
          sortable: true,
        },
        // {
        //   label: "Nome",
        //   key: "nome",
        //   thStyle: "width: 30rem",
        //   sortable: true,
        // },
        // {
        //   label: "Cognome",
        //   key: "cognome",
        //   thStyle: "width: 30rem",
        //   sortable: true,
        // },
        // {
        //   label: "Comune",
        //   key: "comuneLabel",
        //   thStyle: "width: 7rem",
        //   sortable: true,
        // },
        // {
        //   label: "Indirizzo",
        //   key: "indirizzo",
        //   sortable: true,
        // },
        // {
        //   label: "",
        //   key: "actions",
        //   sortable: false,
        //   thStyle: "width: 3rem",
        // },
      ],
    };
  },
  mounted: function () {
    let me = this;
    me.id = this.$route.params.id;
    me.linkedit = "/magazzino/edit/" + me.id;
    me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/magazzinoarticoli/" + me.id;
      axios.get(link).then((response) => {
        me.jsonData = response.data.data;
        // console.log(me.jsonData.destinazioni);
        me.items = me.jsonData.listaMovimenti;
        // console.log(me.items);
      });
    },
  },
};
</script>

<style></style>
