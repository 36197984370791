<template>
  <sa-page-layout :showModalLoading="showModalLoading" :pathResource="pathResource" :btnBackVisible="true" :btnSaveVisible="true" :linkback="linkback" :data="jsonData">
    <template slot="toolbar-title">Modifica Punti Prelievo</template>
    <template slot="table-body">
        <div class="sa-div-scroll sa-scroll">
        <b-row>
        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
          <span class="sa-form-label">Descrizione</span>
          <b-form-input v-model="jsonData.descrizione" placeholder="Descrizione"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
          <span class="sa-form-label">Regione</span>
          <b-form-select v-model="jsonData.regione" class="mb-2 mr-sm-2 mb-sm-0" :options="regioniOptions" text-field="regione" value-field="codiceIstat" @input="onRegioneInput"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
          <span class="sa-form-label">Provincia</span>
          <b-form-select v-model="jsonData.provincia" class="mb-2 mr-sm-2 mb-sm-0" :options="provinceOptions" text-field="provincia" value-field="sigla" @change="onProvinciaInput"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
          <span class="sa-form-label">Comune</span>
          <b-form-select v-model="jsonData.comune" class="mb-2 mr-sm-2 mb-sm-0" :options="comuniOptions" text-field="comune" value-field="codiceIstat" @change="onComuneInput"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
          <span class="sa-form-label">Indirizzo</span>
          <b-form-input v-model="jsonData.indirizzo" placeholder="Indirizzo"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
          <span class="sa-form-label">C.A.P.</span>
          <b-form-input v-model="jsonData.cap" placeholder="C.A.P."></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
          <span class="sa-form-label">Latitudine</span>
          <b-form-input v-model="jsonData.latitudine" placeholder="Latitudine"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
          <span class="sa-form-label">Longitudine</span>
          <b-form-input v-model="jsonData.longitudine" placeholder="Longitudine"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
          <span class="sa-form-label">Stato</span>
          <b-form-select v-model="jsonData.stato" class="mb-2 mr-sm-2 mb-sm-0" :options="statiOptions" text-field="text" value-field="field" @change="onProvinciaInput"></b-form-select>
        </b-col>
       </b-row>
      </div>
    </template>
  </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../template/layout/components/SaPageLayout.vue";
import axios from "axios";
export default {
  components: { SaPageLayout },
  data() {
    return {
      id: "-1",
      pathResource: "/clienti",
      linkback: "/clienti",
      showModalLoading: false,
      regioniOptions: [],
      provinceOptions: [{ provincia: "-Seleziona Provincia-", sigla: "" }],
      comuniOptions: [{ comune: "-Seleziona Comune-", codiceIstat: "" }],
      statiOptions: [
        { text: "ATTIVO", field: "ATTIVO" },
        { text: "SOSPESO", field: "SOSPESO" },
        { text: "CHIUSO", field: "CHIUSO" },
      ],
      jsonData: { descrizione: "", regione: "", provincia: "", comune: "", stato: "ATTIVO" },
    };
  },
  mounted: function () {
    let me = this;
    me.id = this.$route.params.id;
    me.loadDefaultData();
    if (me.id !== "-1") {
      me.loadData();
    }
  },
  methods: {
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/clienti/" + me.id;
      axios.get(link).then((response) => {
        me.jsonData = response.data.data;
        me.loadProvince(me.jsonData.regione);
        me.loadComuni(me.jsonData.provincia);
      });
    },

    loadDefaultData() {
      let me = this;
      let linkRegioni = process.env.VUE_APP_PATH_API + "/regioni";
      axios.get(linkRegioni).then((response) => {
        me.regioniOptions = response.data.data;
        me.regioniOptions.unshift({ regione: "-Seleziona Regione-", codiceIstat: "" });
      });
    },
    loadProvince(value) {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/province?codiceIstatRegione=" + value;
      axios.get(link).then((response) => {
        me.provinceOptions = response.data.data;
        me.provinceOptions.unshift({ provincia: "-Seleziona Provincia-", sigla: "" });
      });
    },
    loadComuni(value) {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/comuni?provincia=" + value;
      axios.get(link).then((response) => {
        me.comuniOptions = response.data.data;
        me.comuniOptions.unshift({ comune: "-Seleziona Comune-", codiceIstat: "" });
      });
    },
    onRegioneInput(value) {
      let me = this;
      me.loadProvince(value);
    },
    onProvinciaInput(value) {
      let me = this;
      me.loadComuni(value);
    },
    onComuneInput(value) {
      let me = this;
      me.comuniOptions.forEach((element) => {
        if (element.codiceIstat === value) {
          me.jsonData.cap = element.cap;
        }
      });
    },
  },
};
</script>

<style></style>
