<template>
  <div>
    <b-card
      class="sa-card"
      header=""
      header-tag="header"
      footer-tag="footer"
      title=""
    >
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Email:</label>
          <b-form-input v-model="jsonData.email"></b-form-input>
        </b-col>

        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Tipo Persona:</label>
          <b-form-select
            v-model="jsonData.tipoPersona"
            :options="tipoPersonaOptions"
            value-field="value"
            text-field="text"
          ></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Nome:</label>
          <b-form-input v-model="jsonData.nome"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Cognome:</label>
          <b-form-input v-model="jsonData.cognome"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import axios from "axios";
// import UtilityMixin from "../../utility/UtilityMixin";
export default {
  data() {
    return {
      id: "-1",
      pathResource: "/protocolrubrica",
      protocolloModulo: {},

      tipoPersonaOptions: [
        { text: "-Selezionare il Tipo-", value: "" },
        { text: "FISICA", value: "F" },
        { text: "GIURIDICA", value: "G" },
      ],
      jsonData: {
        tipoPersona: "",
        email: "",
        nome: "",
        cognome: "",
      },
    };
  },
  mounted: function () {
    let me = this;
    me.id = this.$route.params.id;
    me.loadData();
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
  },
  methods: {
    onUpdateJsonData(data) {
      let me = this;
      me.jsonData = data;
    },
    loadData() {
      let me = this;
      this.$emit("beforeLoadData");
      if (me.id !== "-1") {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
        axios.get(link + me.id).then((response) => {
          me.jsonData = response.data.data;
          this.$emit("afterLoadData");
        });
      } else {
        this.$emit("afterLoadData");
      }
    },
  },
};
</script>

<style></style>
