<template>
    <div>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Tipo Caso</label>
                    <b-form-select v-model="jsonData.tipoCaso" :options="tipiCasiOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
            </b-row>
            <hr />
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Lesione/Ferita contaminata con terreno o polvere negli ultimi 10/14 giorni</label>
                    <b-form-select v-model="jsonData.lesioneFeritaContaminata" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputLesioneFeritaContaminata"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Luogo Lesione</label>
                    <b-form-input v-model="jsonData.lesioneFeritaContaminataLuogo" :disabled="lesioneFeritaContaminataLuogoDisabled"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Fa uso di droghe da strada?</label>
                    <b-form-select v-model="jsonData.utilizzoDrogheStrada" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
            </b-row>
            <hr />
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Interventi Chirurgici negli ultimi 10/14 giorni</label>
                    <b-form-select v-model="jsonData.interventiChirurgici" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-table sticky-header stacked="xl" striped hover itemscope :items="jsonData.malattieInfettiveIeTetanoInterventoChirurgicoBeans" :fields="fieldsInterventiChirurgici" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="row">
                            {{ row.index + 1 }}
                        </template>
                        <template #cell(intervento)="item">
                            <b-form-input v-model="item.item.intervento"></b-form-input>
                        </template>
                        <template #cell(interventoData)="item">
                            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="item.item.interventoData" type="timestamp"></date-picker>
                        </template>
                        <template #cell(interventoSito)="item">
                            <b-form-input v-model="item.item.interventoSito"></b-form-input>
                        </template>
                        <template #cell(actions)="row">
                            <b-button v-if="row.index == jsonData.malattieInfettiveIeTetanoInterventoChirurgicoBeans.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddInterventi(jsonData.malattieInfettiveIeTetanoInterventoChirurgicoBeans, row.item)">
                                <b-icon icon="plus"></b-icon>
                            </b-button>
                            <b-button v-if="row.index !== jsonData.malattieInfettiveIeTetanoInterventoChirurgicoBeans.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(jsonData.malattieInfettiveIeTetanoInterventoChirurgicoBeans, row)">
                                <b-icon icon="trash"></b-icon>
                            </b-button>
                        </template>
                    </b-table>
                </b-col>
            </b-row>
            <hr />
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Altri eventuali fattori di rischio</label>
                    <b-form-textarea v-model="jsonData.altriFattoriRischio" rows="8" no-resize></b-form-textarea>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Altre Considerazioni</label>
                    <b-form-textarea v-model="jsonData.altreConsiderazioni" rows="6" no-resize></b-form-textarea>
                </b-col>
            </b-row>
            <hr />
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Sanitario Notificante</label>
                    <b-form-input v-model="jsonData.sanitarioNotificante"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Numero Cellulare Sanitario Notificante</label>
                    <b-form-input v-model="jsonData.telefonoSanitarioNotificante"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Notifica</label>
                    <date-picker v-model="jsonData.dataNotifica" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import Vue from "vue";
import EventiMixins from "../../../paziente/eventi/utility/mixins/EventiMixins";
import DatePicker from "vue2-datepicker";
export default {
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    components: { DatePicker },
    data() {
        return {
            pathResource: "/malattieinfettiveietetano",
            pathResourceMalattiaInfettivaSegnalazione: "/malattieinfettivesegnalazioni",
            linkback: null,
            id: "-1",
            showModalLoading: false,
            currentPage: 1,
            perPage: 100,
            lesioneFeritaContaminataLuogoDisabled: true,
            tblInterventiChirurgiciDisabled: true,
            jsonData: {
                tipoCaso: "",
                lesioneFeritaContaminata: "",
                lesioneFeritaContaminataLuogo: "",
                utilizzoDrogheStrada: "",
                interventiChirurgici: "",
                malattieInfettiveIeTetanoInterventoChirurgicoBeans: [],
                altriFattoriRischio: "",
                sanitarioNotificante: "",
                telefonoSanitarioNotificante: "",
                altreConsiderazioni: "",
                dataNotifica: null,
            },
            siNoOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "SI", text: "SI" },
                { value: "NO", text: "NO" },
            ],
            tipiCasiOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "PROBABILE", text: "PROBABILE" },
                { value: "ACCERTATO", text: "ACCERTATO" },
            ],
            fieldsInterventiChirurgici: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Intervento",
                    key: "intervento",
                    sortable: false,
                },
                {
                    label: "Data Intervento",
                    key: "interventoData",
                    formatter: (value) => {
                        if (value) {
                            return moment(new Date(value)).format("DD/MM/YYYY");
                        } else {
                            return "-----";
                        }
                    },
                    sortable: false,
                    thStyle: "width: 12rem",
                },
                {
                    label: "Sito Intervento",
                    key: "interventoSito",
                    sortable: false,
                    thStyle: "width: 12rem",
                },
                {
                    label: "",
                    key: "actions",
                    sortable: false,
                    thStyle: "width: 4rem",
                },
            ],
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient() {
            return EventiMixins.getLinkPatient(this.utente, this.idPaziente);
        },
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.jsonData.idAnagrafica = me.idPaziente;
        me.jsonData.id = me.id;
        me.loadMalattiaInfettivaSegnalazione();
        me.loadData();
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
        "jsonData.interventiChirurgici": function (value) {
            let me = this;
            if (value === "SI") {
                me.jsonData.malattieInfettiveIeTetanoInterventoChirurgicoBeans.push({ intervento: "", interventoData: null, interventoSito: "" });
            } else {
                me.jsonData.malattieInfettiveIeTetanoInterventoChirurgicoBeans = [];
            }
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            me.showModalLoading = true;
            axios
                .get(link + me.id)
                .then((response) => {
                    me.jsonData = response.data.data;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.setDatiMedico();
                    me.showModalLoading = false;
                });
        },
        loadMalattiaInfettivaSegnalazione() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceMalattiaInfettivaSegnalazione + "/";
            axios
                .get(link + me.id)
                .then((response) => {
                    me.setDatiMalattia(response.data.data);
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        setDatiMedico() {
            let me = this;
            me.jsonData.sanitarioNotificante = Vue.prototype.user.firstname + " " + Vue.prototype.user.lastname;
        },
        setDatiMalattia(data) {
            let me = this;
            me.jsonData.id = data.id;
            console.log(me.jsonData.id);
        },
        onInputLesioneFeritaContaminata(value) {
            let me = this;
            if (value !== "SI") {
                me.jsonData.lesioneFeritaContaminataLuogo = "";
                me.lesioneFeritaContaminataLuogoDisabled = true;
            } else {
                me.lesioneFeritaContaminataLuogoDisabled = false;
            }
        },
        onAddInterventi(array, value) {
            if (value.intervento !== "" && value.interventoData !== "" && value.interventoSito) {
                array.push({ intervento: "", interventoData: null, interventoSito: null });
            } else {
                this.$bvModal
                    .msgBoxOk("Le Date di Inizio e Fine sono OBBLIGATORIE!", {
                        title: "ATTENZIONE",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    })
                    .then((value) => {
                        console.log(value);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        onDelete(array, row) {
            if (row.index != array.length) {
                this.$bvModal
                    .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
                        title: "Titolo",
                        buttonSize: "sm",
                        okVariant: "secondary",
                        cancelVariant: "danger",
                        okTitle: "SI",
                        cancelTitle: "NO",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true,
                    })
                    .then((value) => {
                        if (value) {
                            array.splice(row.index, 1);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
    },
};
</script>
