<template>
  <b-card class="sa-card" header="Lista Setting" header-tag="header" footer-tag="footer" title="">
    <template #header>
      <span>Lista Setting</span>
      <b-button style="float: right" size="sm" variant="outline-secondary outline-secondary-noborder pull-right" @click="onClickAggiungiSetting()">
        <i class="fas fa-plus"></i>
        Aggiungi
      </b-button>
    </template>
    <div class="sa-list-component">
      <div class="sa-list-component-header">
        <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col lg="3">
            <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
          </b-col>
          <b-col lg="9">
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
          </b-col>
        </b-row>
      </div>
      <div class="sa-list-component-body">
        <div class="b-table-sticky-header">
          <b-table sticky-header ref="table" stacked="lg" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
            <template #cell(actions)="row">
              <b-button variant="outline-danger" size="sm" class="mr-1 ml-1 no-text" @click="onDelete(row.item)">
                <b-icon icon="trash" aria-hidden="true"></b-icon>
              </b-button>
            </template>
          </b-table>
        </div>
      </div>
      <div class="sa-list-component-footer">
        <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col lg="3">
            <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rows }}</p>
          </b-col>
          <b-col lg="9">
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
          </b-col>
        </b-row>
      </div>
    </div>
    <b-modal ref="mdlAddSetting" id="mdlAddSetting" title="Aggiungi Setting" size="xl" scrollable>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Setting</label>
          <b-form-select v-model="jsonDataSetting.idRsaSettings" value-field="id" text-field="setting" :options="settinOptions"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Codice Azienda</label>
          <b-form-input v-model="jsonDataSetting.codiceAzienda"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Codice Nsis</label>
          <b-form-input v-model="jsonDataSetting.codiceNsis"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Distretto</label>
          <b-form-input v-model="jsonDataSetting.distretto"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Tipologia Struttura</label>
          <b-form-input v-model="jsonDataSetting.tipologiaStruttura"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Tipologia Assistenza</label>
          <b-form-input v-model="jsonDataSetting.tipologiaAssistenza"></b-form-input>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-button size="sm" variant="outline-secondary" @click="onHiddenMdlAddSetting()"> Chiudi </b-button>
        <b-button size="sm" variant="success" @click="onOkMdlAddSetting()">Aggiungi</b-button>
      </template>
    </b-modal>
  </b-card>
</template>

<script>
import axios from "axios";
export default {
  props: {
    idPresidio: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      rows: 0,
      rowsSetting: 0,
      perPage: 50,
      currentPage: 1,
      pathResource: "/rsasettingspresidi",
      pathResourceSetting: "/rsasettings",
      idRsaSettings: "",
      filtro: {},
      items: [],
      strutturaOptions: [],
      listKey: 0,
      settinOptions: {},
      jsonDataSetting: { idRsaSettings: "", idPresidio: "", codiceAzienda: "", codiceNsis: "", distretto: "", tipologiaStruttura: "", tipologiaAssistenza: "" },
      fields: [
        {
          label: "Codice",
          key: "codice",
          sortable: true,
          //   thStyle: "width: 10rem",
        },
        {
          label: "Setting",
          key: "setting",
          sortable: true,
          //      thStyle: "width: 20rem",
        },
        { label: "Note", key: "note" },
        {
          label: "Cod. Azienda",
          key: "codiceAzienda",
          sortable: true,
          //        thStyle: "width: 20rem",
        },
        {
          label: "Cod. Nsis",
          key: "codiceNsis",
          sortable: true,
          //          thStyle: "width: 20rem",
        },
        {
          label: "Distretto",
          key: "distretto",
          sortable: true,
          // thStyle: "width: 20rem",
        },
        {
          label: "Tip. Struttura",
          key: "tipologiaStruttura",
          sortable: true,
          //thStyle: "width: 20rem",
        },
        {
          label: "Tip. Assistenza",
          key: "tipologiaAssistenza",
          sortable: true,
          // thStyle: "width: 20rem",
        },
        { key: "actions", label: "", /* thStyle: "width: 3rem" */ class: "sa-table-column-action-single" },
      ],
      fieldsSetting: [
        {
          label: "",
          key: "selezionato",
          thStyle: "width: 2.5rem",
        },
        {
          label: "Codice",
          key: "codice",
          sortable: true,
          // thStyle: "width: 18rem",
        },
        {
          label: "Setting",
          key: "setting",
          sortable: true,
          //thStyle: "width: 18rem",
        },
        { label: "Note", key: "note" },
      ],
    };
  },
  mounted() {
    let me = this;
    me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource;
      let filtro = {};
      filtro.page = me.currentPage;
      filtro.forPage = me.perPage;
      filtro.idPresidio = me.idPresidio;
      axios
        .get(link, { params: filtro })
        .then((response) => {
          me.items = [];
          me.rows = response.data.data.recordsNumber;
          me.items = response.data.data.list;
          me.listKey++;
        })
        .catch(() => {});
    },
    onClickAggiungiSetting() {
      let me = this;
      //me.jsonDataSetting.idPresidio = me.idPresidio;
      me.jsonDataSetting = { idRsaSettings: "", idPresidio: me.idPresidio, codiceAzienda: "", codiceNsis: "", distretto: "", tipologiaStruttura: "", tipologiaAssistenza: "" };
      me.loadSettings();
    },
    loadSettings() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceSetting;
      axios
        .get(link)
        .then((response) => {
          me.settinOptions = [];
          me.rowsSetting = response.data.data.recordsNumber;
          me.settinOptions = response.data.data.list;
          me.settinOptions.unshift({ id: "", setting: "-Seleziona Setting-" });
          me.$refs.mdlAddSetting.show();
        })
        .catch(() => {});
    },
    onRowSelected(rows) {
      let me = this;
      if (rows[0]) {
        me.idRsaSettings = rows[0].id;
      }
    },
    onHiddenMdlAddSetting() {
      let me = this;
      me.hiddenMdlAddSetting();
    },
    hiddenMdlAddSetting() {
      let me = this;
      me.idRsaSettings = "";
      me.$refs.mdlAddSetting.hide();
      me.loadData();
    },
    onOkMdlAddSetting() {
      let me = this;
      // me.jsonDataSetting = { idPresidio: me.idPresidio, idRsaSettings: me.idRsaSettings };
      // console.log(jsonDataSetting);
      let link = process.env.VUE_APP_PATH_API + me.pathResource;
      axios
        .post(link, me.jsonDataSetting)
        .then((response) => {
          // console.log(response);
          this.$bvToast.toast(response.data.messaggio, {
            title: this.titleModal,
            variant: "success",
            autoHideDelay: 1000,
          });
          me.hiddenMdlAddSetting();
        })
        .catch(() => {});
    },
    onDelete(item) {
      let me = this;
      this.$bvModal
        .msgBoxConfirm("Sei sicuro di procedere alla cancellazione?", {
          title: "Conferma Cancellazione",
          buttonSize: "sm",
          okVariant: "secondary",
          cancelVariant: "danger",
          okTitle: "SI",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            me.deleteSetting(item);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteSetting(item) {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
      axios
        .delete(link + item.id)
        .then((response) => {
          console.log(response);
          me.showModalLoading = false;
          me.loadData();
        })
        .catch((error) => {
          console.log(error);
          me.showModalLoading = false;
        });
    },
  },
};
</script>
<style></style>
