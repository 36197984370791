import SchedaValutazioneEsigenzeImmediate from "../";
import Svei from "../";

const routes = [
    { name: "SchedaValutazioneEsigenzeImmediateList", path: "/schedavalutazioneesigenzeimmediate", component: SchedaValutazioneEsigenzeImmediate.SchedaValutazioneEsigenzeImmediateList, meta: { title: "Scheda Valutazione Esigenze Immediate" } },
    { name: "SchedaValutazioneEsigenzeImmediateEdit", path: "/schedavalutazioneesigenzeimmediate/edit/:id", component: SchedaValutazioneEsigenzeImmediate.SchedaValutazioneEsigenzeImmediateEdit, meta: { title: "Scheda Valutazione Esigenze Immediate" } },
    { name: "SchedaValutazioneEsigenzeImmediateView", path: "/schedavalutazioneesigenzeimmediate/view/:id", component: SchedaValutazioneEsigenzeImmediate.SchedaValutazioneEsigenzeImmediateView, meta: { title: "Scheda Valutazione Esigenze Immediate" } },
    { name: "SveiConfigurazionePuntiIncontroPageList", path: "/sveipuntiincontro", component: Svei.SveiConfigurazionePuntiIncontroPageList, meta: { title: "Posizionamento Geografico" } },
    { name: "SveiConfigurazionePuntiIncontroEdit", path: "/sveipuntiincontro/edit/:id", component: Svei.SveiConfigurazionePuntiIncontroPageEdit, meta: { title: "Posizionamento Geografico" } },
    { name: "SveiConfigurazionePuntiIncontroView", path: "/sveipuntiincontro/view/:id", component: Svei.SveiConfigurazionePuntiIncontroView, meta: { title: "Posizionamento Geografico" } },
    { name: "SveiGestioneEmergenzaPage", path: "/sveigestioneemergenza", component: Svei.SveiGestioneEmergenzaPage, meta: { title: "Gestione Emergenza" } },
    { name: "SveiMappa", path: "/sveimappa", component: Svei.SveiMappa, meta: { title: "Posizionamento Geografico" } },
];

export default routes;
