<template>
  <sa-page-layout :showModalLoading="showModalLoading" :pathResource="pathResource" :btnBackVisible="true" :btnSaveVisible="true" :linkback="linkback" :data="altraPrenotazione">
    <template slot="toolbar-title">Prenotazione per : {{ altraPrenotazione.nome }}</template>
    <template slot="table-body">
      <b-card>
        <b-row>
          <b-col xs="12" sm="12" md="8" lg="8" xl="8">
            <label class="sa-form-label" for="altre-prenotazioni-identificativo-prenotazione">Indentificativo Prenotazione</label>
            <b-form-select id="altre-prenotazioni-regione-identificativo-prenotazione" v-model="altraPrenotazione.identificativoPrenotazione" class="mb-2 mr-sm-2 mb-sm-0" :options="altrePrenotazioniOption" :value="null" text-field="text" value-field="id"></b-form-select>
          </b-col>
        </b-row>
      </b-card>
    </template>
  </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../template/layout/components/SaPageLayout.vue";
export default {
  components: { SaPageLayout },
  props: {
    utente: {
      type: Object,
      default: function () {
        return { idUtente: null };
      },
    },
  },
  data() {
    return {
      showModalLoading: false,
      linkback: "/altreprenotazioni",
      pathResource: "",
      altraPrenotazione: {
        identificativoPrenotazione: null,
        identificativo: "TMMFNC99C29B963I",
      },
      altrePrenotazioniOption: [
        { id: null, text: "-- Selezionare soggetto per la prenotazione -- " },
        { id: 1, text: "TMMFNC99C29B963I " },
      ],
    };
  },
};
</script>

<style></style>
