<template>
	<div>
		<b-row>
			<b-col cols="6" xs="6" sm="6" md="6" lg="6" xl="6">
				<div class="sa-pianificazione-edit-container">
					<label for="">Giorni</label>
					<b-form-checkbox size="lg" class="sa-pianificazione-edit-container-selector" switch @input="onInputDaysAll"><label for="">Tutti</label></b-form-checkbox>
					<b-form-checkbox v-model="daysSelected" v-for="day in days" :key="day.code" :name="day.day" :value="day.code" size="lg" switch class="sa-pianificazione-edit-container-selector">
						<label for="">{{ day.day }}</label>
					</b-form-checkbox>
				</div>
			</b-col>
			<b-col cols="6" xs="6" sm="6" md="6" lg="6" xl="6">
				<div class="sa-pianificazione-edit-container">
					<label for="">Turni</label>
					<b-form-checkbox v-model="hoursSelected" v-for="turno in turni" :key="turno.id" :name="turno.oraInizio" :value="turno.oraInizio" size="lg" switch class="sa-pianificazione-edit-container-selector">
						<label for="">{{ turno.text }} ({{ turno.oraInizio }} - {{ turno.oraFine }})</label>
					</b-form-checkbox>
				</div>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import UtilityMixin from "../../../utility/UtilityMixin";
export default {
	mixins: [UtilityMixin],
	data() {
		return {
			pianificazione: {},
			days: [],
			daysSelected: [],
			turni: [
				{ id: 1, text: "Mattina", oraInizio: "08:00", oraFine: "12:00" },
				{ id: 2, text: "Pomeriggio", oraInizio: "12:01", oraFine: "16:00" },
			],
			hoursSelected: [],
		};
	},
	created() {
		this.days = UtilityMixin.methods.getDaysOfWeekArray();
	},
	mounted() {
		this.$emit("afterMounted");
	},
	watch: {
		daysSelected() {
			let me = this;
			me.pianificazione.giorni = me.daysSelected ? me.daysSelected.join(",") : "";
			me.pianificazione.orari = me.hoursSelected ? me.hoursSelected.join(",") : "";
		},
	},
	methods: {
		setPianificazione(pianificazione) {
			let me = this;
			me.pianificazione = pianificazione;
			console.log(me.pianificazione);
			me.daysSelected = pianificazione.giorni ? pianificazione.giorni.split(",") : [];
			me.hoursSelected = pianificazione.orari ? pianificazione.orari.split(",") : [];
		},
		onInputDaysAll(value) {
			let me = this;
			me.daysSelected = [];
			if (value) {
				this.days.forEach((element) => {
					me.daysSelected.push(element.code);
				});
			}
		},
	},
};
</script>

<style></style>
