var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('sa-page-layout',{attrs:{"data":_vm.gruppo,"pathResource":_vm.pathResource,"btnBackVisible":true,"btnSaveVisible":true,"titolo":_vm.gruppo.nome,"linkback":_vm.linkback,"linkedit":_vm.linkedit,"showModalLoading":_vm.showModalLoading}},[_c('template',{slot:"table-filter"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-input',{attrs:{"placeholder":"Nome"},model:{value:(_vm.gruppo.nome),callback:function ($$v) {_vm.$set(_vm.gruppo, "nome", $$v)},expression:"gruppo.nome"}})],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-input',{attrs:{"placeholder":"Descrizione"},model:{value:(_vm.gruppo.descrizione),callback:function ($$v) {_vm.$set(_vm.gruppo, "descrizione", $$v)},expression:"gruppo.descrizione"}})],1)],1)],1),_c('template',{slot:"table-body"},[_c('b-tabs',{staticClass:"sa-tab-scroll",staticStyle:{"height":"100%"},attrs:{"content-class":"mt-3","fill":""}},[_c('b-tab',{attrs:{"title":"Menu","active":""}},[_c('b-card-text',[_c('b-table',{attrs:{"responsive":"","striped":"","hover":"","items":_vm.gruppo.menu,"fields":_vm.fieldsMenu,"head-variant":"light","sort-icon-left":""},scopedSlots:_vm._u([{key:"cell(iconCls)",fn:function(ref){
var item = ref.item;
return [_c('i',{class:'fas fa-' + item.iconCls})]}},{key:"cell(text)",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',{staticClass:"sa-text-bold"},[_vm._v(_vm._s(item.text)+" ("+_vm._s(item.routeId)+")")])]),_c('div',[_c('span',[_vm._v(_vm._s(item.view))])])]}},{key:"cell(selected)",fn:function(ref){
var item = ref.item;
return [_c('b-form-checkbox',{attrs:{"name":"check-button","switch":"","size":"lg"},model:{value:(item.selezionato),callback:function ($$v) {_vm.$set(item, "selezionato", $$v)},expression:"item.selezionato"}})]}}])})],1)],1),_c('b-tab',{attrs:{"title":"Permessi"}},[_c('b-card-text',[_c('b-row',[_c('b-col',{staticClass:"my-1",attrs:{"lg":"12"}},[_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"","label-cols-sm":"0","label-align-sm":"right","label-size":"sm","label-for":"filterInput"}},[_c('b-input-group',{attrs:{"size":"sm"}},[_c('b-form-input',{attrs:{"type":"search","id":"filterInput","placeholder":"Nome"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"disabled":!_vm.filter},on:{"click":function($event){_vm.filter = ''}}},[_vm._v("Clear")])],1)],1)],1)],1)],1),_c('b-table',{attrs:{"responsive":"","striped":"","hover":"","filter":_vm.filter,"filterIncludedFields":_vm.filterOn,"items":_vm.gruppo.sezioni,"fields":_vm.fieldsSezioni,"head-variant":"light","sort-icon-left":""},scopedSlots:_vm._u([{key:"cell(permessiSezione)",fn:function(ref){
var item = ref.item;
return [_c('div'),_vm._l((item.permessi),function(permesso,index){return _c('div',{key:index},[_c('b-form-checkbox',{staticClass:"sa-normal-label",attrs:{"size":"md","switch":""},model:{value:(permesso.attivo),callback:function ($$v) {_vm.$set(permesso, "attivo", $$v)},expression:"permesso.attivo"}},[_vm._v(_vm._s(permesso.descrizione))])],1)})]}}])})],1)],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }