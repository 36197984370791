<template>
    <sa-page-layout :tlbViewVisible="false" :divPageHeaderVisible="false">
        <template slot="toolbar-title"> </template>
        <template slot="toolbar-row"> </template>
        <template slot="table-filter"> </template>
        <template slot="table-header"> </template>
        <template slot="table-body">
            <div class="sa-logo-home"><img :src="logo" :srcset="`${logo}`" /></div>
        </template>
        <template slot="table-footer"> </template>
    </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../template/layout/components/SaPageLayout.vue";
import logo from "../../../assets/images/logo.png";
export default {
    props: {
        utente: { type: Object },
    },
    components: { SaPageLayout },
    data() {
        return {
            logo: logo,
        };
    },
};
</script>

<style></style>
