<template>
    <GmapMap :center="{ lat: 40.8738669, lng: 14.2253539 }" :zoom="12" map-type-id="terrain" style="height: 100%" :options="getOptions()">
        <gmap-info-window content="This is my info window content" class="sa-giw" :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen = false">
            <div v-if="selectedMarkes.codiceValutazione !== 'S' && selectedMarkes.codiceValutazione !== 'A'" class="sa-marker">
                <div>
                    <span class="sa-marker-label">Nominativo:</span>
                    <span class="sa-marker-data">{{ selectedMarkes.assistitoCognome }} {{ selectedMarkes.assistitoNome }} {{ selectedMarkes.codiceValutazione }}</span>
                </div>
                <div>
                    <span class="sa-marker-label">Codice Fiscale:</span>
                    <span class="sa-marker-data">{{ selectedMarkes.assistitoCodiceFiscale }}</span>
                </div>
                <div style="display: flex; margin-top: 15px">
                    <span class="sa-click-event-style" style="flex: 1" @click="onVaiAllaSchedaClick(selectedMarkes)">Vai alla Scheda</span>
                    <span class="sa-click-event-style" style="flex: 1; text-align: end" @click="onAnteprimaPdfClick(selectedMarkes)">Anteprima PDF</span>
                </div>
            </div>

            <div v-else class="sa-marker">
                <div>
                    <span class="sa-marker-label">Struttura:</span>
                    <span class="sa-marker-data">{{ selectedMarkes.assistitoCognome }} {{ selectedMarkes.assistitoNome }} {{ selectedMarkes.codiceValutazione }}</span>
                </div>
            </div>
        </gmap-info-window>
        <GmapMarker :key="index" v-for="(m, index) in markers" :position="m.position" :clickable="true" :draggable="false" @click="onGmapMarkerClick(m, index)" :icon="getMarkerIcon(m, index)" />
        <pdf-print-view-embedded ref="pdfPrintViewEmbedded" :pdf="signedPdfViewer"></pdf-print-view-embedded>
    </GmapMap>
</template>

<script>
import axios from "axios";
import googlePointA from "../../../assets/images/icongoogle/google_point_a.png";
import googlePointS from "../../../assets/images/icongoogle/google_point_s.png";
import googlePointG from "../../../assets/images/icongoogle/google_point_g.png";
import googlePointGT from "../../../assets/images/icongoogle/google_point_gt.png";
import googlePointR from "../../../assets/images/icongoogle/google_point_r.png";
import googlePointV from "../../../assets/images/icongoogle/google_point_v.png";
import PdfPrintViewEmbedded from "../../../app/utility/components/PdfPrintViewEmbedded.vue";
export default {
    components: { PdfPrintViewEmbedded },
    data() {
        return {
            signedPdfViewer: null,
            selectedMarkes: { assistitoCognome: "", assistitoNome: "" },
            pathResource: "/schedavalutazioneesigenzeimmediate",
            pathResourceAllegato: "/sveiprinter/preview/",
            infoWinOpen: false,
            infoWindowPos: null,
            infoOptions: {
                // content: "",
                //optional: offset infowindow so it visually sits nicely on top of our marker
                pixelOffset: {
                    width: 0,
                    height: -35,
                },
            },
            markers: [
                {
                    position: { lat: 40.8738669, lng: 14.2253539 },
                    codiceValutazione: "ASL",
                    assistitoCognome: "A.S.L. Napoli 1 Centro",
                    assistitoNome: "",
                },
                // {
                //     position: { lat: 40.8769669, lng: 14.2253539 },
                //     codiceValutazione: "R",
                //     assistitoNome: "Nome",
                //     assistitoCognome: "Cognome",
                //     assistitoDataNascita: 946681200000,
                // },
                // {
                //     position: { lat: 40.8779669, lng: 14.2353539 },
                //     codiceValutazione: "G",
                // },
            ],
        };
    },
    mounted() {
        let me = this;
        me.loadData();
    },
    methods: {
        getOptions() {
            return {
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                disableDefaultUi: false,
                clickableIcons: false,
                styles: [
                    {
                        featureType: "poi",
                        stylers: [{ visibility: "off" }],
                    },
                    {
                        featureType: "road",
                        elementType: "labels",
                        stylers: [{ visibility: "on" }],
                    },
                    {
                        featureType: "landscape.natural",
                        elementType: "labels",
                        stylers: [{ visibility: "off" }],
                    },
                    {
                        featureType: "transit",
                        elementType: "labels.icon",
                        stylers: [{ visibility: "off" }],
                    },
                ],
            };
        },
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.showModalLoading = true;
            axios
                .get(link)
                .then((response) => {
                    me.markers = [];
                    me.markers.push({
                        position: { lat: 40.8738669, lng: 14.2253539 },
                        codiceValutazione: "A",
                        assistitoCognome: "A.S.L. Napoli 1 Centro",
                        assistitoNome: "",
                    });
                    me.markers.push({
                        position: { lat: 40.8668784, lng: 14.224242 },
                        codiceValutazione: "S",
                        assistitoCognome: "AORN Antonio Cardarelli Chirurgia ",
                        assistitoNome: "",
                    });
                    me.markers.push({
                        position: { lat: 40.8292138, lng: 14.1861202 },
                        codiceValutazione: "S",
                        assistitoCognome: "Presidio Ospedaliero San Paolo",
                        assistitoNome: "",
                    });
                    response.data.data.list.forEach((element) => {
                        let lat = parseFloat(element.latitudine);
                        let lng = parseFloat(element.longitudine);
                        if (lat && lng) {
                            me.markers.push({
                                position: { lat: lat, lng: lng },
                                codiceValutazione: element.codiceValutazione,
                                assistitoCognome: element.assistitoCognome,
                                assistitoNome: element.assistitoNome,
                                assistitoCodiceFiscale: element.assistitoCodiceFiscale,
                                id: element.id,
                            });
                        }
                    });
                    me.markers.push({
                        position: { lat: 40.8769669, lng: 14.2253539 },
                        codiceValutazione: "R",
                        assistitoNome: "Nome",
                        assistitoCognome: "Cognome",
                        assistitoDataNascita: 946681200000,
                    });
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        getMarkerIcon(element) {
            let returnValue = null;
            switch (element.codiceValutazione) {
                case "A":
                    returnValue = googlePointA;
                    break;
                case "S":
                    returnValue = googlePointS;
                    break;
                case "R":
                    returnValue = googlePointR;
                    break;
                case "G":
                    returnValue = googlePointG;
                    break;
                case "GT":
                    returnValue = googlePointGT;
                    break;
                case "V":
                    returnValue = googlePointV;
                    break;
            }
            return returnValue; //"http://maps.google.com/mapfiles/ms/icons/yellow-dot.png";
        },
        onGmapMarkerClick(marker, idx) {
            this.selectedMarkes = marker;
            // let content = "<div class='sa-marker'><span>" + marker.assistitoCognome + " " + marker.assistitoNome + "</span><span> (" + marker.assistitoCodiceFiscale + ")</span><span><a onclick='$emit('click', $event)'>Visualizza Scheda</a></span></div>";
            this.infoWindowPos = marker.position;
            // this.infoOptions.content = marker.assistitoCognome + " " + marker.assistitoNome + ' <a href="https:\\www.google.it">qui</a>'; //marker.infoText;
            // this.infoOptions.content = content;

            if (this.currentMidx == idx) {
                this.infoWinOpen = !this.infoWinOpen;
            } else {
                this.infoWinOpen = true;
                this.currentMidx = idx;
            }
        },
        onVaiAllaSchedaClick(item) {
            let me = this;
            let link = "/schedavalutazioneesigenzeimmediate/view/" + item.id;
            me.$router.replace(link).catch((err) => {
                err;
            });
        },
        onAnteprimaPdfClick(item) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceAllegato + item.id;
            me.showModalLoading = true;
            axios
                .get(link)
                .then((response) => {
                    me.signedPdfViewer = response.data.data.file.includes("data:application/pdf;base64,") ? response.data.data.file : "data:application/pdf;base64," + response.data.data.file;
                    //me.$refs.mdlSigendPdfViewer.show();
                    me.$refs.pdfPrintViewEmbedded.show();
                    me.showModalLoading = false;
                })
                .catch((error) => {
                    // me.$refs.mdlGenerazioneReportInCorso.hide();
                    console.log(error);
                    me.showModalLoading = false;
                    let messaggio = error.response.data.messaggio ? error.response.data.messaggio : "Errore non gestito, Codice: " + error.response.status;
                    this.$bvModal
                        .msgBoxOk(messaggio, {
                            title: this.$i18n.t("ERROR!"),
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then((value) => {
                            this.boxTwo = value;
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                });
        },
    },
};
</script>

<style>
.gm-style-iw-d {
    color: black;
    font-weight: bold;
}

.sa-marker-data {
    font-weight: normal;
    padding-left: 10px;
}
.gm-ui-hover-effect {
    height: 32px;
}
.sa-click-event-style {
    cursor: pointer;
}

.sa-click-event-style:hover {
    color: var(--bg-sidebar-item);
}
</style>
