<template>
    <sa-page-layout :btnNewVisible="false" :btnRefreshVisible="true" @refresh="onRefresh" :pathResource="pathResource" :linkedit="linkedit" :linkback="linkback" :showModalLoading="showModalLoading">
        <template slot="table-filter">
            <b-form @submit.prevent="onSubmit">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                        <label class="sa-label-data">Tipo:</label>
                        <!-- <b-form-input v-model="filtro.codiceTipo" type="search" id="tipo" placeholder="Tipo"></b-form-input> -->
                        <b-form-select v-model="filtro.codiceTipo" :options="tipoAnagraficaOptions" :value="''" value-field="codiceTipo" text-field="descrizioneTipo"></b-form-select>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                        <label class="sa-label-data">Nominativo:</label>
                        <b-form-input v-model="filtro.nominativo" type="search" id="nominativo" placeholder="Nominativo"></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                        <label class="sa-label-data">P. Iva:</label>
                        <b-form-input v-model="filtro.partitaIva" type="search" id="partitaIva" placeholder="P. Iva"></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                        <label class="sa-label-data">Codice Fiscale:</label>
                        <b-form-input v-model="filtro.codiceFiscale" type="search" id="codiceFiscale" placeholder="Codice Fiscale"></b-form-input>
                    </b-col>
                </b-row>
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="sa-padding-right pt-3 pb-3 text-right">
                        <b-button type="submit" variant="info">{{ this.$i18n.t("patients.lblFind") }}</b-button>
                        <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("patients.lblResetFilter") }}</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </template>
        <template slot="table-body">
            <paperclean-anagrafica-list-component ref="PapercleanAnagraficaListComponent" @beforLoadData="onBeforeLoadData" @afterLoadData="onAfterLoadData"></paperclean-anagrafica-list-component>
        </template>
    </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import UtilityMixin from "../../utility/UtilityMixin";
import PapercleanAnagraficaListComponent from "../components/PapercleanAnagraficaListComponent.vue";
export default {
    components: { SaPageLayout, PapercleanAnagraficaListComponent },
    data() {
        return {
            filtro: { nominativo: "", codiceTipo: "", partitaIva: "", codiceFiscale: "" },
            btnNewVisible: true,
            btnBackVisible: true,
            linkback: "/home",
            pathResource: "/ospiti",
            pathResourceTipiAnagrafica: "/dizionariotipi",
            linkedit: "/rsagestioneospiti",
            tipoAnagraficaOptions: [{ codiceTipo: "", descrizioneTipo: "-Seleziona Valore-" }],
            showModalLoading: false,
            listKey: 0,
        };
    },
    mounted() {
        let me = this;
        me.loadTipiAnagrafica();
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            me.$refs.PapercleanAnagraficaListComponent.loadData(me.filtro);
        },
        loadTipiAnagrafica() {
            let me = this;
            let filtro = {};
            filtro.sezioneDizionario = "ANAGRAFICA";
            UtilityMixin.methods.loadDataResources(me.pathResourceTipiAnagrafica, filtro, me.loadDataSuccess, me.loadDataError);
            me.loadData();
        },
        loadDataSuccess(response) {
            let me = this;
            me.tipoAnagraficaOptions = response.data.data.list;
            me.tipoAnagraficaOptions.unshift({ codiceTipo: "", descrizioneTipo: "-Seleziona Valore-" });
        },
        loadDataError(response) {
            console.log(response);
        },

        onRefresh() {
            let me = this;
            me.loadData();
        },
        onAfterLoadData() {
            let me = this;
            me.showModalLoading = false;
        },
        onBeforeLoadData() {
            let me = this;
            me.showModalLoading = false;
        },
        onReset() {
            let me = this;
            me.filtro = { nome: null, cognome: null, identificativo: null };
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
        onSubmit() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
    },
};
</script>

<style></style>
