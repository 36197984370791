<template>
    <div class="sa-tab-scroll">
        <b-card v-if="listaEta.length > 0" class="sa-card sa-card-border" header="ETA'" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaEta" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header"> </b-table>
        </b-card>
        <b-card v-if="listaCondizioniVitaSupportoSociale.length > 0" class="sa-card sa-card-border" header="CONDIZIONI DI VITA E SUPPORTO SOCIALE" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaCondizioniVitaSupportoSociale" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header"> </b-table>
        </b-card>
        <b-card v-if="listaStatoFunzionale.length > 0" class="sa-card sa-card-border" header="STATO FUNZIONALE" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaStatoFunzionale" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header"> </b-table>
        </b-card>
        <b-card v-if="listaStatoCognitivo.length > 0" class="sa-card sa-card-border" header="STATO COGNITIVO" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaStatoCognitivo" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header"> </b-table>
        </b-card>
        <b-card v-if="listaModelloComportamentale.length > 0" class="sa-card sa-card-border" header="MODELLO COMPORTAMENTALE" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaModelloComportamentale" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header"> </b-table>
        </b-card>
        <b-card v-if="listaMobilita.length > 0" class="sa-card sa-card-border" header="MOBILITA'" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaMobilita" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header"> </b-table>
        </b-card>
        <b-card v-if="listaDeficitSensoriale.length > 0" class="sa-card sa-card-border" header="DEFICIY SENSORIALE" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaDeficitSensoriale" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header"> </b-table>
        </b-card>
        <b-card v-if="listaNumeroRIcoveriPregressi.length > 0" class="sa-card sa-card-border" header="NUMERO DI RICOVERI PREGRESSI AL PRONTO SOCCIRSO" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaNumeroRIcoveriPregressi" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header"> </b-table>
        </b-card>
        <b-card v-if="listaNumeroProblemiClinici.length > 0" class="sa-card sa-card-border" header="NUMERO DI PROBLEMI CLINICI ATTIVI" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaNumeroProblemiClinici" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header"> </b-table>
        </b-card>
        <b-card v-if="listaNumerofarmaciAssunti.length > 0" class="sa-card sa-card-border" header="NUMERO DI FARMACI ASSUNTI" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaNumerofarmaciAssunti" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header"> </b-table>
        </b-card>
        <b-card class="sa-card sa-card-border" header="Totale" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <span class="sa-label-data">{{ totale }}</span>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import axios from "axios";
import UtilityMixin from "../../../utility/UtilityMixin";
export default {
    components: {},
    mixins: [UtilityMixin],
    props: {
        // utente: {
        //   type: Object,
        //   default: function () {
        //     return null;
        //   },
        // },
    },
    data() {
        return {
            idAccettazione: null,
            keyPropostaAccesso: 0,
            linkback: "",
            linkPrintData: "",
            childKey: 0,
            viewPai: true,
            pathResource: "/rsascalabrassrichieste",
            pathResourceRsaAccettazione: "/rsaaccettazioni",
            totale: 0,
            eta: 0,
            jsonData: [],
            listaEta: [],
            listaCondizioniVitaSupportoSociale: [],
            listaStatoFunzionale: [],
            listaStatoCognitivo: [],
            listaModelloComportamentale: [],
            listaMobilita: [],
            listaDeficitSensoriale: [],
            listaNumeroRIcoveriPregressi: [],
            listaNumeroProblemiClinici: [],
            listaNumerofarmaciAssunti: [],
            fieldsListe: [
                {
                    label: "Descrizione",
                    key: "fattore",
                    sortable: true,
                },
            ],
        };
    },
    mounted() {
        let me = this;
        if (this.$route.params.id) {
            me.idAccettazione = this.$route.params.id;
        } else {
            me.idAccettazione = this.$route.query.idPaziente;
        }
        me.loadScheda();
    },
    methods: {
        loadScheda() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceRsaAccettazione + "/" + me.idAccettazione;
            axios
                .get(link)
                .then((response) => {
                    me.eta = UtilityMixin.methods.calcoloEtaYearOnly(response.data.data.accettazioneAnagrafica.dataNascita, new Date(response.data.data.createDate));
                    me.loadData();
                })
                .catch(() => {
                    me.loadData();
                });
        },
        loadData() {
            let me = this;

            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/richiesta/" + me.idAccettazione;
            axios
                .get(link)
                .then((response) => {
                    me.jsonData = response.data.data.list;
                    me.calcolaPunteggio();
                    me.jsonData.forEach((element) => {
                        if (element.check) {
                            me.jsonData.push({ idRichiesta: me.idAccettazione, idScalaBrass: element.id });
                            switch (element.gruppo) {
                                case "ETA'":
                                    me.listaEta.push(element);
                                    break;
                                case "CONDIZIONI DI VITA E SUPPORTO SOCIALE":
                                    me.listaCondizioniVitaSupportoSociale.push(element);
                                    break;
                                case "STATO FUNZIONALE":
                                    me.listaStatoFunzionale.push(element);
                                    break;
                                case "STATO COGNITIVO":
                                    me.listaStatoCognitivo.push(element);
                                    break;
                                case "MODELLO COMPORTAMENTALE":
                                    me.listaModelloComportamentale.push(element);
                                    break;
                                case "MOBILITA'":
                                    me.listaMobilita.push(element);
                                    break;
                                case "DEFICIT SENSORIALE":
                                    me.listaDeficitSensoriale.push(element);
                                    break;
                                case "NUMERO DI RICOVERI PREGRESSI AL PRONTO SOCCIRSO":
                                    me.listaNumeroRIcoveriPregressi.push(element);
                                    break;
                                case "NUMERO DI PROBLEMI CLINICI ATTIVI":
                                    me.listaNumeroProblemiClinici.push(element);
                                    break;
                                case "NUMERO DI FARMACI ASSUNTI":
                                    me.listaNumerofarmaciAssunti.push(element);
                                    break;
                            }
                        }
                    });
                })
                .catch(() => {});
        },
        calcolaPunteggio() {
            let me = this;
            me.totale = 0;
            me.jsonData.forEach((element) => {
                if (element.check) {
                    me.totale = me.totale + element.punteggio;
                }
            });
        },

        setListe() {},
    },
};
</script>

<style></style>
