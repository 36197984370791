<template>
    <div style="display: inline-block">
        <b-button v-if="typeButton === 'normal'" class="float-sm-left btn-toolbar sa-btn-icon-text" variant="outline-secondary sa-margin-left" @click="onFirma" :disabled="disabled">
            <b-icon icon="pencil-square"></b-icon>
            Firma Elettronica
        </b-button>
        <b-button v-if="typeButton === 'small'" v-b-tooltip.hover title="Firma Elettronica" size="sm" class="btn btn-outline-info waves-effect waves-light width-sm no-text" variant="outline-secondary sa-margin-left" @click="onFirma" :disabled="disabled">
            <b-icon icon="pencil-square"></b-icon>
        </b-button>

        <b-modal ref="mdlFirma" title="Firma" @hidden="onHidden" @ok="onOk">
            <form ref="form" @submit.stop.prevent="onFirmaSubmit">
                <b-form-group label="Password" label-for="name-input" invalid-feedback="Name is required" :state="passwordState">
                    <b-form-input type="password" v-model="password" :state="passwordState" required placeholder="Password"></b-form-input>
                </b-form-group>
            </form>
        </b-modal>
        <b-modal ref="mdlDigitalSignComponentPinRequest" title="P.I.N" @hidden="onHidden" @ok="onOk">
            <form ref="form" @submit.stop.prevent="onFirmaSubmit">
                <b-form-group label="P.I.N." label-for="name-input" invalid-feedback="P.I.N. Obbligatorio">
                    <b-form-input type="password" v-model="pinUtente" required placeholder="P.I.N."></b-form-input>
                </b-form-group>
            </form>
        </b-modal>
    </div>
</template>

<script>
import axios from "axios";
export default {
    props: {
        idRiferimento: {
            type: String,
            required: true,
        },
        filename: {
            type: String,
            required: true,
        },
        pdfLink: {
            type: String,
            required: true,
        },
        utente: {
            type: Object,
            required: true,
        },
        tipoDocumento: {
            type: String,
        },
        cognomePaziente: {
            type: String,
        },
        nomePaziente: {
            type: String,
        },
        dataNascitaPaziente: {
            type: Number,
        },
        identificativoPaziente: {
            type: String,
        },
        pathResourceDigitalSign: {
            type: String,
        },
    },
    data() {
        return {
            typeButton: "small",
            disabled: false,
            passwordState: null,
            pinState: true,
            password: null,
            pinUtente: null,
            aliasUtente: null,
            pathResourceDigitalSignUser: "/digitalsignusers",
            pathResourceDocumentRepository: "/documentrepository",
        };
    },
    methods: {
        signDocumenti() {},
        digilatSignRequest(signBean) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceDigitalSign;
            axios
                .post(link, signBean)
                .then(() => {
                    me.$emit("afterSign");
                })
                .catch((error) => {
                    me.$emit("closeModal");
                    if (error.response) {
                        me.$emit("afterSign");
                        this.$bvModal
                            .msgBoxOk(error.response.data.data[0].errorMessage, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then(() => {
                                me.$emit("afterSign");
                            })
                            .catch(() => {
                                me.$emit("afterSign");
                            });
                    }
                });
        },
        onHidden() {},
        onOk() {
            let me = this;
            me.$refs.mdlDigitalSignComponentPinRequest.hide();
            me.signProcess();
        },
        onFirmaSubmit() {
            let me = this;
            me.$refs.mdlDigitalSignComponentPinRequest.hide();
            me.signProcess();
        },
        onFirma() {
            let me = this;

            let link = process.env.VUE_APP_PATH_API + me.pathResourceDigitalSignUser + "/" + me.utente.id;
            me.$emit("beforeDigitalSignUser");
            axios
                .get(link)
                .then((response) => {
                    me.$emit("afterDigitalSignUser");
                    if (response.data.data.username) {
                        me.aliasUtente = response.data.data.username;
                        me.$refs.mdlDigitalSignComponentPinRequest.show();
                    } else {
                        me.erroreConfigurazione();
                    }
                })
                .catch(() => {
                    // console.log(error);
                    me.erroreConfigurazione();
                    me.$emit("afterDigitalSignUser");
                });
            //
        },
        signProcess() {
            let me = this;

            let link = process.env.VUE_APP_PATH_API + me.pdfLink;
            me.$emit("beforePdfCreate");
            axios
                .get(link)
                .then((response) => {
                    let signBean = {
                        gestore: "INFOCERT",
                        tipo: "AUTOMATICA",
                        username: me.aliasUtente,
                        pin: me.pinUtente,
                        otp: "",
                        files: [{ idRiferimento: me.idRiferimento, filename: me.filename, fileBase64: response.data.data }],
                        idRisorsa: me.idRiferimento,
                        metadatiJson: "{}",
                        base64: null,
                        stato: "ATTIVO",
                        statoInvioDocumentale: "DA INVIARE",
                        statoInvioDocumentaleDataOra: new Date().getTime(),
                        statoFirma: "FIRMATO DIGITALMENTE",
                        idDocumentale: null,
                        cognome: me.cognomePaziente,
                        nome: me.nomePaziente,
                        dataNascita: me.dataNascitaPaziente,
                        codiceFiscale: me.identificativoPaziente,
                        tipoDocumento: me.tipoDocumento,
                        metadataUtente: '{"name":null,"surname":"","fiscalCode":"","userId":"","username":"","role":"","applicationId":"COT","organizationId":null,"email":""}',
                    };
                    me.digilatSignRequest(signBean);
                })
                .catch((error) => {
                    console.log(error);
                    me.$emit("afterPdfCreate");
                });
        },
        erroreConfigurazione() {
            let me = this;
            this.$bvModal
                .msgBoxOk("La Firma elettronica non è configurata correttamente!", {
                    title: "ATTENZIONE",
                    size: "sm",
                    okVariant: "outline-danger",
                    headerClass: "sa-msg-header-danger",
                    footerClass: "p-2 border-top-0",
                    centered: true,
                })
                .then(() => {
                    me.$emit("afterSign");
                })
                .catch(() => {
                    me.$emit("afterSign");
                });
        },
        saveDocumentRepository(documentRepository) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceDocumentRepository;
            axios
                .post(link, documentRepository)
                .then(() => {
                    me.$emit("afterSign");
                })
                .catch(() => {
                    me.$emit("afterSign");
                });
        },
    },
};
</script>

<style></style>
