<template>
	<b-overlay :show="showOverlay" rounded="sm">
		<b-card class="sa-card sa-header-nowrap sa-info-card-dashboard" :header="chartParams.title" header-tag="header" footer-tag="footer" title="">
			<template #header>
				<div style="display: flex">
					<span style="flex: 1">{{ chartParams.title }}</span>
					<b-button size="sm" variant="outline-secondary outline-secondary-noborder pull-right" @click="onClickEditConfigChart()">
						<i class="fas fa-pencil-alt"></i>
					</b-button>
				</div>
			</template>
			<b-card-body>
				<b-card-text class="text-right sa-dashboard-data-text">{{ value }}</b-card-text>
			</b-card-body>
			<data-analysis-edit-config-chart-modal-component ref="mdlDataAnalysisEditConfigChartComponent" @updateConfig="onUpdateConfig"></data-analysis-edit-config-chart-modal-component>
		</b-card>
	</b-overlay>
</template>

<script>
import axios from "axios";
import DataAnalysisEditConfigChartModalComponent from "./DataAnalysisEditConfigChartModalComponent.vue";
export default {
	props: {
		chartParams: { type: Object },
	},
	components: { DataAnalysisEditConfigChartModalComponent },
	data() {
		return {
			showOverlay: true,
			pathResourceData: "/dataanalysisdatabaseinfo/eseguigraphicquery",
			value: null,
		};
	},
	mounted() {
		this.$emit("beforeLoadData", this.chartParams);
		if (this.chartParams.idQuery) {
			this.setValueData();
		} else {
			this.showOverlay = false;
		}
	},
	methods: {
		setValueData() {
			this.loadDataQuery();
		},
		loadDataQuery() {
			let me = this;
			let link = process.env.VUE_APP_PATH_API + me.pathResourceData + "/" + me.chartParams.idQuery;
			axios.get(link)
				.then((response) => {
					let objData = null;
					objData = response.data.data[0];
					for (let property in objData) {
						me.value = objData[property];
					}
					me.showOverlay = false;
				})
				.catch((e) => {
					console.log(e);
				});
		},
		onClickEditConfigChart() {
			let me = this;
			me.$refs.mdlDataAnalysisEditConfigChartComponent.setChartParams(me.chartParams);
			me.$refs.mdlDataAnalysisEditConfigChartComponent.show();
		},
		onUpdateConfig() {
			this.showOverlay = true;
			this.loadDataQuery();
			this.$emit("updateChart");
		},
	},
};
</script>

<style></style>
