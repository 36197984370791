<template>
    <sa-page-layout :btnNewVisible="btnNewVisible" :btnRefreshVisible="true" @refresh="onRefresh" :pathResource="pathResource" :linkedit="linkedit" :showModalLoading="showModalLoading">
        <template slot="table-body">
            <b-tabs id="tbsDataAnalysisDataSourcesPage" ref="tbsDataAnalysisDataSourcesPage" class="sa-tab" nav-wrapper-class="sa-tab-items-vertical" content-class="mt-3" style="padding-bottom: 5px" fill vertical @input="onInputTabs">
                <b-tab title="Data Sources" active><data-analysis-data-sources-list-component></data-analysis-data-sources-list-component></b-tab>
                <b-tab title="Driver Database"><data-analysis-driver-database-list-component></data-analysis-driver-database-list-component></b-tab>
            </b-tabs>
        </template>
    </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import DataAnalysisDataSourcesListComponent from "../components/DataAnalysisDataSourcesListComponent.vue";
import DataAnalysisDriverDatabaseListComponent from "../components/DataAnalysisDriverDatabaseListComponent.vue";
export default {
    components: { SaPageLayout, DataAnalysisDataSourcesListComponent, DataAnalysisDriverDatabaseListComponent },
    data() {
        return {
            btnNewVisible: false,
            pathResource: "",
            linkedit: null,
            showModalLoading: false,
            activeTabIndex: 0,
        };
    },
    mounted() {
        // let me = this;
        // me.loadData();
    },
    methods: {
        loadData() {
            // let me = this;
            // me.showModalLoading = true;
            // me.$refs.DataAnalysisQueryListComponent.loadData();
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        onAfterLoadData() {
            let me = this;
            me.showModalLoading = false;
        },
        onInputTabs() {},
    },
};
</script>

<style></style>
