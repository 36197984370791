<template>
    <!-- <div style="margin-bottom: 30px; flex: 1; background-color: red; height: 1px"> -->
    <agenda-view-component></agenda-view-component>
</template>

<script>
import AgendaViewComponent from "../agenda/AgendaViewComponent.vue";
export default {
    components: { AgendaViewComponent },
    data() {
        return {};
    },
    methods: {},
};
</script>

<style></style>
