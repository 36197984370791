<template>
    <sa-page-layout :btnBackVisible="true" :btnEditVisible="false" :linkback="linkback" :linkedit="linkedit" :pathResource="pathResource" :showModalLoading="showModalLoading">
        <template slot="table-body">
            <numeratori-view-component ref="NumeratoriViewComponent" @afterLoadData="onAfterLoadData" @beforeLoadData="onBeforeLoadData"></numeratori-view-component>
        </template>
    </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../template/layout/components/SaPageLayout.vue";
import NumeratoriViewComponent from "../components/NumeratoriViewComponent.vue";
export default {
    name: "NumeratoreViewPage",
    components: { NumeratoriViewComponent, SaPageLayout },

    data() {
        return {
            btnNewVisible: true,
            pathResource: "/numeratori",
            linkedit: null,
            linkback: "/numeratori",
            showModalLoading: false,
        };
    },
    mounted() {
        this.loadData();
    },
    methods: {
        loadData() {
            this.$refs.NumeratoriViewComponent.loadData();
        },
        onAfterLoadData() {
            this.showModalLoading = false;
        },
        onBeforeLoadData() {
            this.showModalLoading = true;
        },
    },
};
</script>
