<template>
    <div>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Caso di:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.casoMalattia" :options="casoMalattiaOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Specie e/o Sierotipo:</label>
                    <b-form-input v-model="jsonData.casoMalattiaSpecieSierotipo"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Lavoro Settore Alimentare:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.lavoroSettoreAlimentare" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Specificare Dove e Occupazione:</label>
                    <b-form-input v-model="jsonData.lavoroSettoreAlimentareOccupazione" :disabled="chekCondition(jsonData.lavoroSettoreAlimentare, 'SI')"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Comunita' frequentate 10 giorni precedenti:</label>
                    <b-form-input v-model="jsonData.comunitaFrequentateDieciGiorniPrecedenti"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Se Studente Classe e Sezione:</label>
                    <b-form-input v-model="jsonData.comunitaFrequentateDieciGiorniPrecedentiStudenteClasseSe"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Frequnza Comunita' Fino Al:</label>
                    <date-picker v-model="jsonData.comunitaFrequentateDieciGiorniPrecedentiFinoAl" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="informazioni Cliniche" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Comparsa Sintomi:</label>
                    <date-picker v-model="jsonData.sintomiDataComparsa" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Sintomi Ancora Presenti:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.sintomiAncoraPresenti" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Vomito:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.sintomiVomito" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Vomito Data:</label>
                    <date-picker v-model="jsonData.sintomiVomitoDataOra" format="DD-MM-YYYY HH:mm" value-type="timestamp" type="datetime" :disabled="chekCondition(jsonData.sintomiVomito, 'SI')"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Crampi Addominali:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.sintomiCrampiAddome" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Crampi Addominali Data:</label>
                    <date-picker v-model="jsonData.sintomiCrampiAddomeDataOra" format="DD-MM-YYYY HH:mm" value-type="timestamp" type="datetime" :disabled="chekCondition(jsonData.sintomiCrampiAddome, 'SI')"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Diarrea:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.sintomiDiarrea" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Diarrea Data:</label>
                    <date-picker v-model="jsonData.sintomiDiarreaDataOra" format="DD-MM-YYYY HH:mm" value-type="timestamp" type="datetime" :disabled="chekCondition(jsonData.sintomiDiarrea, 'SI')"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Diarrea Con Sangue:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.sintomiDiarreaSangue" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Diarrea Con Sangue Data:</label>
                    <date-picker v-model="jsonData.sintomiDiarreaSangueDataOra" format="DD-MM-YYYY HH:mm" value-type="timestamp" type="datetime" :disabled="chekCondition(jsonData.sintomiDiarreaSangue, 'SI')"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Diarrea Con Sangue Scariche Giornaliere:</label>
                    <b-form-input v-model="jsonData.sintomiDiarreaNumeroScariche" :disabled="chekCondition(jsonData.sintomiDiarreaSangue, 'SI')"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Diarrea Con Muco:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.sintomiDiarreaMuco" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Diarrea Con Muco Data:</label>
                    <date-picker v-model="jsonData.sintomiDiarreaMucoDataOra" format="DD-MM-YYYY HH:mm" value-type="timestamp" type="datetime" :disabled="chekCondition(jsonData.sintomiDiarreaMuco, 'SI')"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Febbre:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.sintomiFebbre" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Febbre Data:</label>
                    <date-picker v-model="jsonData.sintomiFebbreDataOra" format="DD-MM-YYYY HH:mm" value-type="timestamp" type="datetime" :disabled="chekCondition(jsonData.sintomiFebbre, 'SI')"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Disidratazione:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.sintomiDisidratazione" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Disidratazione Data:</label>
                    <date-picker v-model="jsonData.sintomiDisidratazioneDataOra" format="DD-MM-YYYY HH:mm" value-type="timestamp" type="datetime" :disabled="chekCondition(jsonData.sintomiDisidratazione, 'SI')"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Mal Di Testa/Astenia:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.sintomiMalTestaAstenia" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Mal Di Testa/Astenia Data:</label>
                    <date-picker v-model="jsonData.sintomiMalTestaAsteniaDataOra" format="DD-MM-YYYY HH:mm" value-type="timestamp" type="datetime" :disabled="chekCondition(jsonData.sintomiMalTestaAstenia, 'SI')"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Altri Sintomi:</label>
                    <b-form-textarea v-model="jsonData.sintomiAltro" rows="3" no-resize></b-form-textarea>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Sintomo Predominante:</label>
                    <b-form-input v-model="jsonData.sintomoPredominante"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Contattato Medico di Base:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.rivoltoMedicoFamiglia" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Contattato Medico di Base Data:</label>
                    <date-picker v-model="jsonData.rivoltoMedicoFamigliaQuando" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="chekCondition(jsonData.rivoltoMedicoFamiglia, 'SI')"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Diagnosi:</label>
                    <b-form-textarea v-model="jsonData.rivoltoMedicoFamigliaDiagnosiMedico" rows="3" no-resize :disabled="chekCondition(jsonData.rivoltoMedicoFamiglia, 'SI')"></b-form-textarea>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Pronto Soccorso:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.andatoProntoSoccorso" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Pronto Soccorso Data:</label>
                    <date-picker v-model="jsonData.andatoProntoSoccorsoQuando" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="chekCondition(jsonData.andatoProntoSoccorso, 'SI')"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Diagnosi Dimissione:</label>
                    <b-form-textarea v-model="jsonData.andatoProntoSoccorsoDiagnosiDimissione" rows="3" no-resize :disabled="chekCondition(jsonData.andatoProntoSoccorso, 'SI')"></b-form-textarea>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Ricovero Ospedale:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.ricoveroOspedale" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Ricovero Ospedaliero Motivo:</label>
                    <b-form-input v-model="jsonData.ricoveroOspedaleMotivo" :disabled="chekCondition(jsonData.ricoveroOspedale, 'SI')"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Ricovero Ospedaliero Reparto:</label>
                    <b-form-input v-model="jsonData.ricoveroOspedaleReparto" :disabled="chekCondition(jsonData.ricoveroOspedale, 'SI')"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Pronto Soccorso Data Dimissioni:</label>
                    <date-picker v-model="jsonData.ricoveroOspedaleDataDimissioni" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="chekCondition(jsonData.ricoveroOspedale, 'SI')"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Indagine Laboratorio:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.eseguitoIndagineLaborario" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Indagine Laboratorio Richiedente:</label>
                    <b-form-input v-model="jsonData.eseguitoIndagineLaborarioRichiedente" :disabled="chekCondition(jsonData.eseguitoIndagineLaborario, 'SI')"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Indagine Laboratorio Esito:</label>
                    <b-form-input v-model="jsonData.eseguitoIndagineLaborarioEsito" :disabled="chekCondition(jsonData.eseguitoIndagineLaborario, 'SI')"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Assunto Antibiotici Prima Dell'Esame:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.primaEsemeAssuntoAntibiotici" :options="siNoOptions" value="" value-field="value" text-field="text" :disabled="chekCondition(jsonData.eseguitoIndagineLaborario, 'SI')"></b-form-select>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Contatti ed Esposti" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Familiare/Amico/Conoscente Con Medesima Sintomatologia:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.contattiStessaSintomatologia" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
            </b-row>
            <b-table sticky-header ref="tblViaggi" stacked="xl" striped hover itemscope :items="jsonData.listaContatti" :fields="fieldsContatti" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                <template #cell(index)="jsonData">
                    {{ jsonData.index + 1 }}
                </template>
                <template #cell(cognome)="item">
                    <b-form-input v-model="item.item.cognome"></b-form-input>
                </template>
                <template #cell(nome)="item">
                    <b-form-input v-model="item.item.nome"></b-form-input>
                </template>
                <template #cell(comune)="item">
                    <!-- <b-form-select v-model="item.item.professione" :options="professioniOptions" :value="''" value-field="value" text-field="text"></b-form-select> -->
                    <b-form-input v-model="item.item.comune"></b-form-input>
                </template>
                <template #cell(indirizzo)="item">
                    <b-form-input v-model="item.item.indirizzo"></b-form-input>
                </template>
                <template #cell(telefono)="item">
                    <b-form-input v-model="item.item.telefono"></b-form-input>
                </template>
                <template #cell(actions)="row">
                    <b-button v-if="row.index == jsonData.listaContatti.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddContatto(jsonData.listaContatti, row.item)">
                        <b-icon icon="plus"></b-icon>
                    </b-button>
                    <b-button v-if="row.index !== jsonData.listaContatti.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(jsonData.listaContatti, row)">
                        <b-icon icon="trash"></b-icon>
                    </b-button>
                </template>
            </b-table>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Consumato Pasti/Esperienze Comuni:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.contattiStessaSintomatologiaConsumatoPasti" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
            </b-row>
            <b-table sticky-header ref="tblViaggi" stacked="xl" striped hover itemscope :items="jsonData.listaEsperienzeComuni" :fields="fieldsEsperienzeComuni" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                <template #cell(index)="jsonData">
                    {{ jsonData.index + 1 }}
                </template>
                <template #cell(pastoEsperienzaComune)="item">
                    <b-form-input v-model="item.item.pastoEsperienzaComune" placeholder="Pasto/Esperienza Comune"></b-form-input>
                </template>
                <template #cell(quando)="item">
                    <b-form-input v-model="item.item.quando" placeholder="Quando"></b-form-input>
                </template>
                <template #cell(dove)="item">
                    <b-form-input v-model="item.item.dove" placeholder="Dove"></b-form-input>
                </template>
                <template #cell(actions)="row">
                    <b-button v-if="row.index == jsonData.listaEsperienzeComuni.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddEsperienzaComune(jsonData.listaEsperienzeComuni, row.item)">
                        <b-icon icon="plus"></b-icon>
                    </b-button>
                    <b-button v-if="row.index !== jsonData.listaEsperienzeComuni.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(jsonData.listaEsperienzeComuni, row)">
                        <b-icon icon="trash"></b-icon>
                    </b-button>
                </template>
            </b-table>
        </b-card>
        <b-card class="sa-card" header="Comportamenti" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Viaggi nei 5 Giorni Precedenti:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.viaggiCinqueGiorniPrecedenti" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Dove:</label>
                    <b-form-input v-model="jsonData.viaggiCinqueGiorniPrecedentiDove" :disabled="chekCondition(jsonData.viaggiCinqueGiorniPrecedenti, 'SI')"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Alloggio:</label>
                    <b-form-input v-model="jsonData.viaggiCinqueGiorniPrecedentiAlloggio" :disabled="chekCondition(jsonData.viaggiCinqueGiorniPrecedenti, 'SI')"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Consumato Pasti in Questi Luoghi/Strutture:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.viaggiCinqueGiorniPrecedentiConsumoPasti" :options="siNoOptions" value="" value-field="value" text-field="text" :disabled="chekCondition(jsonData.viaggiCinqueGiorniPrecedenti, 'SI')"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Dove:</label>
                    <b-form-input v-model="jsonData.viaggiCinqueGiorniPrecedentiConsumoPastiDove" :disabled="chekCondition(jsonData.viaggiCinqueGiorniPrecedentiConsumoPasti, 'SI')"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Quando:</label>
                    <date-picker v-model="jsonData.viaggiCinqueGiorniPrecedentiConsumoPastiQuando" format="DD-MM-YYYY HH:mm" value-type="timestamp" type="datetime" :disabled="chekCondition(jsonData.viaggiCinqueGiorniPrecedentiConsumoPasti, 'SI')"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Altre Pesone con Stessi Sintomi in Struttura:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.viaggiCinqueGiorniPrecedentiSintomiPersone" :options="siNoOptions" value="" value-field="value" text-field="text" :disabled="chekCondition(jsonData.viaggiCinqueGiorniPrecedenti, 'SI')"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Quando:</label>
                    <date-picker v-model="jsonData.viaggiCinqueGiorniPrecedentiSintomiPersoneQuando" format="DD-MM-YYYY HH:mm" value-type="timestamp" type="datetime" :disabled="chekCondition(jsonData.viaggiCinqueGiorniPrecedentiSintomiPersone, 'SI')"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Animali Domestici:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.animaliDomestici" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox v-model="jsonData.animaliDomesticiCane" :checked="jsonData.animaliDomesticiCane" switch :disabled="chekCondition(jsonData.animaliDomestici, 'SI')">Cani</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox v-model="jsonData.animaliDomesticiGatto" :checked="jsonData.animaliDomesticiGatto" switch :disabled="chekCondition(jsonData.animaliDomestici, 'SI')">Gatti</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox v-model="jsonData.animaliDomesticiUccelli" :checked="jsonData.animaliDomesticiUccelli" switch :disabled="chekCondition(jsonData.animaliDomestici, 'SI')">Uccelli</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox v-model="jsonData.animaliDomesticiCricetiOrsettiRussi" :checked="jsonData.animaliDomesticiCricetiOrsettiRussi" switch :disabled="chekCondition(jsonData.animaliDomestici, 'SI')">Criceti/ Orsetti Russi</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox v-model="jsonData.animaliDomesticiTartarughe" :checked="jsonData.animaliDomesticiTartarughe" switch :disabled="chekCondition(jsonData.animaliDomestici, 'SI')">Tartarughe</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Altro Animale Domestico:</label>
                    <b-form-input v-model="jsonData.animaliDomesticiTipoAltro" :disabled="chekCondition(jsonData.animaliDomestici, 'SI')"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Manifestato Diarrea Prima Che si Ammalasse:</label>
                    <b-form-input v-model="jsonData.animaliDomesticiDiarrea" :disabled="chekCondition(jsonData.animaliDomestici, 'SI')"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Lavoro a Contatto Con Animali e Feci:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.contattoAnimaliFeci" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Vive in Azienda Agricola/Campagna:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.viveAziendaAgricolaCampagna" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox v-model="jsonData.toccatoAnimaliMucche" :checked="jsonData.toccatoAnimaliMucche" switch>Toccato Mucche</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox v-model="jsonData.toccatoAnimaliPolliGalline" :checked="jsonData.toccatoAnimaliPolliGalline" switch>Toccato Polli/Galline</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox v-model="jsonData.toccatoAnimaliMaiali" :checked="jsonData.toccatoAnimaliMaiali" switch>Toccato Maiali</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox v-model="jsonData.toccatoAnimaliPecore" :checked="jsonData.toccatoAnimaliPecore" switch>Toccato Pecore</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox v-model="jsonData.toccatoAnimaliRoditori" :checked="jsonData.toccatoAnimaliRoditori" switch>Toccato Roditori</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Toccato Altri Animali:</label>
                    <b-form-input v-model="jsonData.toccatoAnimaliAltro"></b-form-input>
                </b-col>
            </b-row>
            <h4>Consumo di Acqua</h4>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox v-model="jsonData.tipoAcquaBevutoAcquedottoComunale" :checked="jsonData.tipoAcquaBevutoAcquedottoComunale" switch>Acquedotto Comunale</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox v-model="jsonData.tipoAcquaBevutoAcquaMineraleBottiglia" :checked="jsonData.tipoAcquaBevutoAcquaMineraleBottiglia" switch>Minerale In Bottiglia</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox v-model="jsonData.tipoAcquaBevutoAcquaAcquaSorgene" :checked="jsonData.tipoAcquaBevutoAcquaAcquaSorgene" switch>Sorgente</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox v-model="jsonData.tipoAcquaBevutoAcquaAcquaCorsiSuperficialiNonTrattata" :checked="jsonData.tipoAcquaBevutoAcquaAcquaCorsiSuperficialiNonTrattata" switch>Corsi Superficiali Non Trattata</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox v-model="jsonData.tipoAcquaBevutoAcquaAcquaPozzoNonTrattata" :checked="jsonData.tipoAcquaBevutoAcquaAcquaPozzoNonTrattata" switch>Pozzo Non Trattata</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Pozzo di Sua Proprieta':</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.tipoAcquaBevutoCinqueGiorniPrecedentiPozzo" :options="siNoOptions" value="" value-field="value" text-field="text" :disabled="!jsonData.tipoAcquaBevutoAcquaAcquaPozzoNonTrattata"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Ultimo Controllo Potabilita':</label>
                    <b-form-input v-model="jsonData.tipoAcquaBevutoCinqueGiorniPrecedentiPozzoControlloPota" :disabled="!jsonData.tipoAcquaBevutoCinqueGiorniPrecedentiPozzo"></b-form-input>
                </b-col>
            </b-row>
            <h4>Acquisto e Consumo di Prodotti Alimentari</h4>
            <b-row>
                <b-table sticky-header ref="tblViaggi" stacked="xl" striped hover itemscope :items="jsonData.listaNegozi" :fields="fieldsNegozi" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                    <template #cell(index)="jsonData">
                        {{ jsonData.index + 1 }}
                    </template>
                    <template #cell(negozio)="item">
                        <b-form-input v-model="item.item.negozio" placeholder="Negozio"></b-form-input>
                    </template>
                    <template #cell(sempre)="item">
                        <b-form-checkbox v-model="item.item.sempre" :checked="item.item.sempre" switch></b-form-checkbox>
                    </template>
                    <template #cell(spesso)="item">
                        <b-form-checkbox v-model="item.item.spesso" :checked="item.item.spesso" switch></b-form-checkbox>
                    </template>
                    <template #cell(qualcheVolta)="item">
                        <b-form-checkbox v-model="item.item.qualcheVolta" :checked="item.item.qualcheVolta" switch></b-form-checkbox>
                    </template>
                    <template #cell(actions)="row">
                        <b-button v-if="row.index == jsonData.listaNegozi.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddNegozio(jsonData.listaNegozi, row.item)">
                            <b-icon icon="plus"></b-icon>
                        </b-button>
                        <b-button v-if="row.index !== jsonData.listaNegozi.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(jsonData.listaNegozi, row)">
                            <b-icon icon="trash"></b-icon>
                        </b-button>
                    </template>
                </b-table>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Partecipato Eventi/Ritrovi/Manifestazioni:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.cinqueGiorniPartecipatoEventiRitroviManifestazioni" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Dove:</label>
                    <b-form-input v-model="jsonData.cinqueGiorniPartecipatoEventiRitroviManifestazioniDove" :disabled="chekCondition(jsonData.cinqueGiorniPartecipatoEventiRitroviManifestazioni, 'SI')"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Quando:</label>
                    <b-form-input v-model="jsonData.cinqueGiorniPartecipatoEventiRitroviManifestazioniQundo" :disabled="chekCondition(jsonData.cinqueGiorniPartecipatoEventiRitroviManifestazioni, 'SI')"></b-form-input>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Nei Giorni Precedenti l'inizio dei sintomi" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox v-model="jsonData.giorniPrecedentiManeggiatoLavatoCarneCruda" :checked="jsonData.giorniPrecedentiManeggiatoLavatoCarneCruda" switch>Mangiato/Lavato Carne Cruda</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox v-model="jsonData.giorniPrecedentiManeggiatoLavatoUova" :checked="jsonData.giorniPrecedentiManeggiatoLavatoUova" switch>Mangiato/Lavato Uova</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox v-model="jsonData.giorniPrecedentiManeggiatoLavatoVerduraNonLavata" :checked="jsonData.giorniPrecedentiManeggiatoLavatoVerduraNonLavata" switch>Mangiato/Lavato Verdura non Lavata</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox v-model="jsonData.giorniPrecedentiManeggiatoLavatoPollo" :checked="jsonData.giorniPrecedentiManeggiatoLavatoPollo" switch>Mangiato/Lavato Pollo</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Utilizzato Stessi Utensili per Alimenti Diversi:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.giorniPrecedentiUtilizzoStessiUtensiliDiversiAlimenti" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Quali:</label>
                    <b-form-input v-model="jsonData.giorniPrecedentiUtilizzoStessiUtensiliDiversiAlimentiDes" :disabled="chekCondition(jsonData.giorniPrecedentiUtilizzoStessiUtensiliDiversiAlimenti, 'SI')"></b-form-input>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Indagine Consumo Alimenti Carne" header-tag="header" footer-tag="footer" title="">
            <indagine-epidemiologica-infezione-alimentare-alimenti-component :isEdit="true" :listaAlimentiConsumati="jsonData.listaAlimentiConsumatiCarne" famigliaAlimenti="CARNE" @update="onUpdateJsonDataListaAlimenti"></indagine-epidemiologica-infezione-alimentare-alimenti-component>
        </b-card>
        <b-card class="sa-card" header="Indagine Consumo Alimenti Pesce" header-tag="header" footer-tag="footer" title="">
            <indagine-epidemiologica-infezione-alimentare-alimenti-component :isEdit="true" :listaAlimentiConsumati="jsonData.listaAlimentiConsumatiPesce" famigliaAlimenti="PESCE" @update="onUpdateJsonDataListaAlimenti"></indagine-epidemiologica-infezione-alimentare-alimenti-component>
        </b-card>
        <b-card class="sa-card" header="Indagine Consumo Alimenti Uova" header-tag="header" footer-tag="footer" title="">
            <indagine-epidemiologica-infezione-alimentare-alimenti-component :isEdit="true" :listaAlimentiConsumati="jsonData.listaAlimentiConsumatiUova" famigliaAlimenti="UOVA" @update="onUpdateJsonDataListaAlimenti"></indagine-epidemiologica-infezione-alimentare-alimenti-component>
        </b-card>
        <b-card class="sa-card" header="Indagine Consumo Alimenti Latte/Latticini" header-tag="header" footer-tag="footer" title="">
            <indagine-epidemiologica-infezione-alimentare-alimenti-component :isEdit="true" :listaAlimentiConsumati="jsonData.listaAlimentiConsumatiLatteLatticini" famigliaAlimenti="LATTE/LATTICINI" @update="onUpdateJsonDataListaAlimenti"></indagine-epidemiologica-infezione-alimentare-alimenti-component>
        </b-card>
        <b-card class="sa-card" header="Indagine Consumo Alimenti Verdure" header-tag="header" footer-tag="footer" title="">
            <indagine-epidemiologica-infezione-alimentare-alimenti-component :isEdit="true" :listaAlimentiConsumati="jsonData.listaAlimentiConsumatiVerdure" famigliaAlimenti="VERDURE" @update="onUpdateJsonDataListaAlimenti"></indagine-epidemiologica-infezione-alimentare-alimenti-component>
        </b-card>
        <b-card class="sa-card" header="Indagine Consumo Alimenti Prodotti dolci da forno" header-tag="header" footer-tag="footer" title="">
            <indagine-epidemiologica-infezione-alimentare-alimenti-component :isEdit="true" :listaAlimentiConsumati="jsonData.listaAlimentiConsumatiProdottiDolciForno" famigliaAlimenti="PRODOTTI DOLCI E DA FORNO" @update="onUpdateJsonDataListaAlimenti"></indagine-epidemiologica-infezione-alimentare-alimenti-component>
        </b-card>
        <b-card class="sa-card" header="Indagine Consumo Alimenti ALIMENTI PRONTI AL CONSUMO/  ALIMENTI TRAFORMATI REFRIGERATI CON DURATA PROLUNGATA" header-tag="header" footer-tag="footer" title="">
            <indagine-epidemiologica-infezione-alimentare-alimenti-component :isEdit="true" :listaAlimentiConsumati="jsonData.listaAlimentiConsumatiProdottiProntiCosumo" famigliaAlimenti="ALIMENTI PRONTI AL CONSUMO/  ALIMENTI TRAFORMATI REFRIGERATI CON DURATA PROLUNGATA" @update="onUpdateJsonDataListaAlimenti"></indagine-epidemiologica-infezione-alimentare-alimenti-component>
        </b-card>
        <b-card class="sa-card" header="Conclusioni ed ulteriori azioni" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <b-form-checkbox v-model="jsonData.azioniIntraprendereAlimenti" :checked="jsonData.azioniIntraprendereAlimenti" switch>Da Alimenti</b-form-checkbox>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Alimento Sospetto:</label>
                    <b-form-input v-model="jsonData.azioniIntraprendereAlimentiSospetto" :disabled="!jsonData.azioniIntraprendereAlimenti"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Comportamenti a Rischio:</label>
                    <b-form-input v-model="jsonData.azioniIntraprendereAlimentiComportamentiRischioPreparazion" :disabled="!jsonData.azioniIntraprendereAlimenti"></b-form-input>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <b-form-checkbox v-model="jsonData.azioniIntraprendereNonAlimenti" :checked="jsonData.azioniIntraprendereNonAlimenti" switch>Non Da Alimenti</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Dettaglio:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.azioniIntraprendereNonAlimentiTipo" :options="nonAlimentiTipoOptions" value="" value-field="value" text-field="text" :disabled="!jsonData.azioniIntraprendereNonAlimenti"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Ulteriori Azioni:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.ulterioreAzione" :options="ulterioriAzioniOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Data:</label>
                    <date-picker v-model="jsonData.ulterioreAzioneData" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="chekCondition(jsonData.ulterioreAzione, 'AVVISO AL SETTORE DI SICUREZZA ALIMENTARE')"></date-picker>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Altre Considerazioni</label>
                    <b-form-textarea v-model="jsonData.altreConsiderazioni" rows="6" no-resize></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Dati Notifica" header-tag="header" footer-tag="footer" title="">
            <indagine-epidemiologica-dati-notifica-component :jsonData="jsonData" :isEdit="true" @update="onUpdateJsonData"></indagine-epidemiologica-dati-notifica-component>
        </b-card>
    </div>
</template>
<script>
import axios from "axios";
import UtilityMixin from "../../../utility/UtilityMixin";
import DatePicker from "vue2-datepicker";
import Vue from "vue";
import IndagineEpidemiologicaDatiNotificaComponent from "./IndagineEpidemiologicaDatiNotificaComponent.vue";
import IndagineEpidemiologicaInfezioneAlimentareAlimentiComponent from "./IndagineEpidemiologicaInfezioneAlimentareAlimentiComponent.vue";
export default {
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    components: { IndagineEpidemiologicaDatiNotificaComponent, IndagineEpidemiologicaInfezioneAlimentareAlimentiComponent, DatePicker }, //
    mixins: [UtilityMixin],
    data() {
        return {
            pathResource: "/malattieinfettiveieinfezionialimentari",
            pathResourceSottotipoModalita: "/malattieinfettiveieinfezionialimentarisottotipomodalita",
            pathResourceMalattiaInfettivaSegnalazione: "/malattieinfettivesegnalazioni",
            pathResourceComuni: "/comuni",
            pathResourceProvince: "/province",
            linkback: null,
            currentPage: 1,
            perPage: 100,
            showModalLoading: false,
            linkNazioni: "/nazioni",
            disableSintomi: true,
            jsonData: {
                idAnagrafica: "",
                casoMalattia: "",
                casoMalattiaSpecieSierotipo: "",
                lavoroSettoreAlimentare: "",
                lavoroSettoreAlimentareOccupazione: "",
                comunitaFrequentateDieciGiorniPrecedenti: "",
                comunitaFrequentateDieciGiorniPrecedentiStudenteClasseSe: "",
                comunitaFrequentateDieciGiorniPrecedentiFinoAl: null,
                sintomiDataComparsa: null,
                sintomiAncoraPresenti: "",
                sintomiVomito: "",
                sintomiVomitoDataOra: null,
                sintomiCrampiAddome: "",
                sintomiCrampiAddomeDataOra: null,
                sintomiDiarrea: "",
                sintomiDiarreaDataOra: null,
                sintomiDiarreaSangue: "",
                sintomiDiarreaSangueDataOra: null,
                sintomiDiarreaNumeroScariche: "",
                sintomiDiarreaMuco: "",
                sintomiDiarreaMucoDataOra: null,
                sintomiFebbre: "",
                sintomiFebbreDataOra: null,
                sintomiDisidratazione: "",
                sintomiDisidratazioneDataOra: null,
                sintomiMalTestaAstenia: "",
                sintomiMalTestaAsteniaDataOra: null,
                sintomiAltro: "",
                sintomoPredominante: "",
                rivoltoMedicoFamiglia: "",
                rivoltoMedicoFamigliaQuando: null,
                rivoltoMedicoFamigliaDiagnosiMedico: "",
                andatoProntoSoccorso: "",
                andatoProntoSoccorsoQuando: null,
                andatoProntoSoccorsoDiagnosiDimissione: "",
                ricoveroOspedale: "",
                ricoveroOspedaleMotivo: "",
                ricoveroOspedaleReparto: "",
                ricoveroOspedaleDataDimissioni: null,
                eseguitoIndagineLaborario: "",
                eseguitoIndagineLaborarioRichiedente: "",
                eseguitoIndagineLaborarioEsito: "",
                primaEsemeAssuntoAntibiotici: "",
                contattiStessaSintomatologia: "",
                contattiStessaSintomatologiaConsumatoPasti: "",
                viaggiCinqueGiorniPrecedenti: "",
                viaggiCinqueGiorniPrecedentiDove: "",
                viaggiCinqueGiorniPrecedentiAlloggio: "",
                viaggiCinqueGiorniPrecedentiConsumoPasti: "",
                viaggiCinqueGiorniPrecedentiConsumoPastiDove: "",
                viaggiCinqueGiorniPrecedentiConsumoPastiQuando: null,
                viaggiCinqueGiorniPrecedentiSintomiPersone: "",
                viaggiCinqueGiorniPrecedentiSintomiPersoneQuando: "",
                animaliDomestici: "",
                animaliDomesticiCane: false,
                animaliDomesticiGatto: false,
                animaliDomesticiUccelli: false,
                animaliDomesticiCricetiOrsettiRussi: false,
                animaliDomesticiTartarughe: false,
                animaliDomesticiTipoAltro: "",
                animaliDomesticiDiarrea: "",
                contattoAnimaliFeci: "",
                viveAziendaAgricolaCampagna: "",
                toccatoAnimaliMaiali: false,
                toccatoAnimaliMucche: false,
                toccatoAnimaliPecore: false,
                toccatoAnimaliPolliGalline: false,
                toccatoAnimaliRoditori: false,
                toccatoAnimaliAltro: "",
                tipoAcquaBevutoAcquedottoComunale: false,
                tipoAcquaBevutoAcquaMineraleBottiglia: false,
                tipoAcquaBevutoAcquaAcquaSorgene: false,
                tipoAcquaBevutoAcquaAcquaCorsiSuperficialiNonTrattata: false,
                tipoAcquaBevutoAcquaAcquaPozzoNonTrattata: false,
                tipoAcquaBevutoCinqueGiorniPrecedentiPozzo: "",
                tipoAcquaBevutoCinqueGiorniPrecedentiPozzoControlloPota: "",
                cinqueGiorniPartecipatoEventiRitroviManifestazioni: "",
                cinqueGiorniPartecipatoEventiRitroviManifestazioniDove: "",
                cinqueGiorniPartecipatoEventiRitroviManifestazioniQundo: "",
                giorniPrecedentiManeggiatoLavatoCarneCruda: false,
                giorniPrecedentiManeggiatoLavatoUova: false,
                giorniPrecedentiManeggiatoLavatoVerduraNonLavata: false,
                giorniPrecedentiManeggiatoLavatoPollo: false,
                giorniPrecedentiUtilizzoStessiUtensiliDiversiAlimenti: "",
                giorniPrecedentiUtilizzoStessiUtensiliDiversiAlimentiDes: "",
                note: "",
                azioniIntraprendereAlimenti: false,
                azioniIntraprendereAlimentiSospetto: "",
                azioniIntraprendereAlimentiComportamentiRischioPreparazion: "",
                azioniIntraprendereNonAlimenti: false,
                azioniIntraprendereNonAlimentiTipo: "",
                ulterioreAzione: "",
                ulterioreAzioneData: null,
                sanitarioNotificante: "",
                sanitarioNotificanteContatto: "",
                altreConsiderazioni: "",
                dataNotifica: null,
                listaAlimentiConsumatiCarne: [],
                listaAlimentiConsumatiPesce: [],
                listaAlimentiConsumatiUova: [],
                listaAlimentiConsumatiLatteLatticini: [],
                listaAlimentiConsumatiVerdure: [],
                listaAlimentiConsumatiProdottiDolciForno: [],
                listaAlimentiConsumatiProdottiProntiCosumo: [],
                listaContatti: [],
                listaEsperienzeComuni: [],
                listaNegozi: [{ negozio: "", sempre: false, spesso: false, qualcheVolta: false }],
            },
            fieldsContatti: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Cognome",
                    key: "cognome",
                    thStyle: "width: 20rem",
                    sortable: false,
                },
                {
                    label: "Nome",
                    key: "nome",
                    thStyle: "width: 20rem",
                    sortable: false,
                },
                {
                    label: "Comune",
                    key: "comune",
                    thStyle: "width: 10rem",
                    sortable: false,
                },
                {
                    label: "Indirizzo",
                    key: "indirizzo",
                    // thStyle: "width: 5rem",
                    sortable: false,
                },
                {
                    label: "Telefono",
                    key: "telefono",
                    // thStyle: "width: 5rem",
                    sortable: false,
                },
                {
                    label: "",
                    key: "actions",
                    sortable: false,
                    thStyle: "width: 4rem",
                },
            ],
            fieldsEsperienzeComuni: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Pasto/Esperienza Comune",
                    key: "pastoEsperienzaComune",
                    thStyle: "width: 11rem",
                    sortable: false,
                },
                {
                    label: "Quando",
                    key: "quando",
                    thStyle: "width: 11rem",
                    sortable: false,
                },
                {
                    label: "Dove",
                    key: "dove",
                    thStyle: "width: 11rem",
                    sortable: false,
                },
                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 4rem",
                    tdClass: "text-center",
                },
            ],
            fieldsNegozi: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Negozio",
                    key: "negozio",
                    // thStyle: "width: 11rem",
                    sortable: false,
                },
                {
                    label: "Sempre",
                    key: "sempre",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
                {
                    label: "Spesso",
                    key: "spesso",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
                {
                    label: "Qualche Volta",
                    key: "qualcheVolta",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 4rem",
                    tdClass: "text-center",
                },
            ],
            fieldsAlimenti: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Alimento",
                    key: "alimento",
                    thStyle: "width: 11rem",
                    sortable: false,
                },
                {
                    label: "Sottotipo/Modalita",
                    key: "sottotipoModalita",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
                {
                    label: "Marca",
                    key: "marca",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
                {
                    label: "L. Acquisto",
                    key: "luogoAcquisto",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
                {
                    label: "D. Acquisto",
                    key: "dataAcquisto",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
                {
                    label: "L. Preparazione",
                    key: "luogoPreparazione",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
                {
                    label: "D. Preparazione",
                    key: "dataPreparazione",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
                {
                    label: "L. Consumo",
                    key: "luogoConsumo",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
                {
                    label: "D. Consumo",
                    key: "dataConsumo",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
                {
                    label: "Tipologia",
                    key: "tipologia",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
            ],
            casoMalattiaOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "SALMONELLOSI", text: "SALMONELLOSI" },
                { value: "SHIGHELLOSI", text: "SHIGHELLOSI" },
                { value: "CAPYLOBACTERIOSI", text: "CAPYLOBACTERIOSI" },
                { value: "INFEZIONE DA YERSINIA ENTEROCOLITICA E PSEUDOTUBERCOLOSIS", text: "INFEZIONE DA YERSINIA ENTEROCOLITICA E PSEUDOTUBERCOLOSIS" },
                { value: "INFEZIONE DA NOROVIRUS", text: "INFEZIONE DA NOROVIRUS" },
                { value: "CLOSTRIDIUM BOTULINUM", text: "CLOSTRIDIUM BOTULINUM" },
            ],
            siNoOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "SI", text: "SI" },
                { value: "NO", text: "NO" },
            ],
            tipologiaOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "FRESCO", text: "FRESCO" },
                { value: "REFRIGERATO", text: "REFRIGERATO" },
                { value: "CONGELATO", text: "CONGELATO" },
            ],
            nonAlimentiTipoOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "TRASMISSIONE INTERUMANA PER MANCATO RISPETTO DELLE NORME IGIENICHE DI BASE", text: "TRASMISSIONE INTERUMANA PER MANCATO RISPETTO DELLE NORME IGIENICHE DI BASE" },
                { value: "DA PRATICHE INFANTILI NON IGIENICHE", text: "DA PRATICHE INFANTILI NON IGIENICHE" },
            ],
            ulterioriAzioniOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "ALIMENTO NON IDENTIFICATO", text: "ALIMENTO NON IDENTIFICATO" },
                { value: "ALIMENTO SOSPETTO, NESSUN ALTRA AZIONE DA INTRAPRENDERE", text: "ALIMENTO SOSPETTO, NESSUN ALTRA AZIONE DA INTRAPRENDERE" },
                { value: "AVVISO AL SETTORE DI SICUREZZA ALIMENTARE", text: "AVVISO AL SETTORE DI SICUREZZA ALIMENTARE" },
                { value: "PRESENZA DI UN FOCOLAIO, PROCEDERE CON INDAGINE SPECIFICA", text: "PRESENZA DI UN FOCOLAIO, PROCEDERE CON INDAGINE SPECIFICA" },
            ],
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient() {
            return UtilityMixin.methods.getLinkPatient(this.utente, this.idPaziente);
        },
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.jsonData.idAnagrafica = me.idPaziente;
        me.jsonData.id = me.id;
        me.loadMalattiaInfettivaSegnalazione();
        me.loadData();
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
        "jsonData.contattiStessaSintomatologia": function (value) {
            let me = this;
            if (value === "SI") {
                me.jsonData.listaContatti.push({ cognome: "", nome: "", comune: "", indirizzo: "", telefono: "" });
            } else {
                me.jsonData.listaContatti = [];
            }
        },
        "jsonData.contattiStessaSintomatologiaConsumatoPasti": function (value) {
            let me = this;
            if (value === "SI") {
                me.jsonData.listaEsperienzeComuni.push({ pastoEsperienzaComune: "", quando: "", dove: "" });
            } else {
                me.jsonData.listaEsperienzeComuni = [];
            }
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            // console.log(me.jsonData);
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            me.showModalLoading = true;
            axios
                .get(link + me.id)
                .then((response) => {
                    me.jsonData = response.data.data;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.getListaAlimenti();
                    me.setDatiMedico();
                    me.showModalLoading = false;
                });
        },
        loadMalattiaInfettivaSegnalazione() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceMalattiaInfettivaSegnalazione + "/";
            axios
                .get(link + me.id)
                .then((response) => {
                    me.setDatiMalattia(response.data.data);
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        getListaAlimenti() {
            let me = this;
            me.jsonData.listaAlimentiConsumatiCarne = [];
            me.jsonData.listaAlimentiConsumatiPesce = [];
            me.jsonData.listaAlimentiConsumatiUova = [];
            me.jsonData.listaAlimentiConsumatiLatteLatticini = [];
            me.jsonData.listaAlimentiConsumatiVerdure = [];
            me.jsonData.listaAlimentiConsumatiProdottiDolciForno = [];
            me.jsonData.listaAlimentiConsumatiProdottiProntiCosumo = [];
            let link = process.env.VUE_APP_PATH_API + me.pathResourceSottotipoModalita;
            axios.get(link).then((response) => {
                me.elaboraRisposta(response.data.data.list);
            });
        },
        elaboraRisposta(risposta) {
            let me = this;
            risposta.forEach((element) => {
                switch (element.famiglia) {
                    case "CARNE":
                        me.jsonData.listaAlimentiConsumatiCarne.push({ ordine: element.id, alimento: element.alimento, sottotipoModalita: element.sottotipoModalita, famiglia: element.famiglia, tipologia: "" });
                        break;
                    case "PESCE":
                        me.jsonData.listaAlimentiConsumatiPesce.push({ ordine: element.id, alimento: element.alimento, sottotipoModalita: element.sottotipoModalita, famiglia: element.famiglia, tipologia: "" });
                        break;
                    case "UOVA":
                        me.jsonData.listaAlimentiConsumatiUova.push({ ordine: element.id, alimento: element.alimento, sottotipoModalita: element.sottotipoModalita, famiglia: element.famiglia, tipologia: "" });
                        break;
                    case "LATTE/LATTICINI":
                        me.jsonData.listaAlimentiConsumatiLatteLatticini.push({ ordine: element.id, alimento: element.alimento, sottotipoModalita: element.sottotipoModalita, famiglia: element.famiglia, tipologia: "" });
                        break;
                    case "VERDURE":
                        me.jsonData.listaAlimentiConsumatiVerdure.push({ ordine: element.id, alimento: element.alimento, sottotipoModalita: element.sottotipoModalita, famiglia: element.famiglia, tipologia: "" });
                        break;
                    case "PRODOTTI DOLCI E DA FORNO":
                        me.jsonData.listaAlimentiConsumatiProdottiDolciForno.push({ ordine: element.id, alimento: element.alimento, sottotipoModalita: element.sottotipoModalita, famiglia: element.famiglia, tipologia: "" });
                        break;
                    case "ALIMENTI PRONTI AL CONSUMO/  ALIMENTI TRAFORMATI REFRIGERATI CON DURATA PROLUNGATA":
                        me.jsonData.listaAlimentiConsumatiProdottiProntiCosumo.push({ ordine: element.id, alimento: element.alimento, sottotipoModalita: element.sottotipoModalita, famiglia: element.famiglia, tipologia: "" });
                        break;
                }
            });
        },
        setDatiMedico() {
            let me = this;
            me.jsonData.sanitarioNotificante = Vue.prototype.user.firstname + " " + Vue.prototype.user.lastname;
        },
        setDatiMalattia(data) {
            let me = this;
            me.jsonData.id = data.id;
        },
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
        },
        onUpdateJsonDataListaAlimenti(famiglia, data) {
            let me = this;
            switch (famiglia) {
                case "CARNE":
                    me.jsonData.listaAlimentiConsumatiCarne = data;
                    break;
                case "PESCE":
                    me.jsonData.listaAlimentiConsumatiPesce = data;
                    break;
                case "UOVA":
                    me.jsonData.listaAlimentiConsumatiUova = data;
                    break;
                case "LATTE/LATTICINI":
                    me.jsonData.listaAlimentiConsumatiLatteLatticini = data;
                    break;
                case "VERDURE":
                    me.jsonData.listaAlimentiConsumatiVerdure = data;
                    break;
                case "PRODOTTI DOLCI E DA FORNO":
                    me.jsonData.listaAlimentiConsumatiProdottiDolciForno = data;
                    break;
                case "ALIMENTI PRONTI AL CONSUMO/  ALIMENTI TRAFORMATI REFRIGERATI CON DURATA PROLUNGATA":
                    me.jsonData.listaAlimentiConsumatiProdottiProntiCosumo = data;
                    break;
            }
        },
        onAddContatto(array, value) {
            if (value.cognome !== "" && value.nome !== "" && value.comune !== "" && value.indirizzo !== "" && value.telefono !== "") {
                array.push({ cognome: "", nome: "", comune: "", indirizzo: "", telefono: "" });
            } else {
                this.$bvModal
                    .msgBoxOk("Campi OBBLIGATORI!", {
                        title: "ATTENZIONE",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    })
                    .then((value) => {
                        console.log(value);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        onAddEsperienzaComune(array, value) {
            if (value.pastoEsperienzaComune !== "" && value.quando !== "" && value.dove !== "") {
                array.push({ pastoEsperienzaComune: "", quando: "", dove: "" });
            } else {
                this.$bvModal
                    .msgBoxOk("Campi OBBLIGATORI!", {
                        title: "ATTENZIONE",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    })
                    .then((value) => {
                        console.log(value);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        onAddNegozio(array, value) {
            if (value.negozio !== "") {
                array.push({ negozio: "", sempre: false, spesso: false, qualcheVolta: false });
            } else {
                this.$bvModal
                    .msgBoxOk("Campi OBBLIGATORI!", {
                        title: "ATTENZIONE",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    })
                    .then((value) => {
                        console.log(value);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        onDelete(array, row) {
            if (row.index != array.length) {
                this.$bvModal
                    .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
                        title: "Titolo",
                        buttonSize: "sm",
                        okVariant: "secondary",
                        cancelVariant: "danger",
                        okTitle: "SI",
                        cancelTitle: "NO",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true,
                    })
                    .then((value) => {
                        if (value) {
                            array.splice(row.index, 1);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
    },
};
</script>
