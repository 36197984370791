<template>
    <sa-page-layout :titolo="titolo" :showmolalloading="showmolalloading" :btnNewVisible="true" :btnRefreshVisible="true" :linkedit="linkedit">
        <template slot="toolbar">
            <b-button class="float-sm-right btn-toolbar" variant="light sa-margin-right" size="sm" @click="onUpdate" v-b-modal.modal-center><b-icon icon="arrow-clockwise" />Aggiorna Modulo</b-button>
        </template>
        <template slot="table-filter"> </template>
        <template slot="table-header">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6">
                    <p>Totale Record: {{ rows }}</p>
                </b-col>
                <b-col cols="6">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" id="tblPazienti" :filter="filter" responsive stacked="lg" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                    <template #cell(nome)="{ item }">
                        <span style="font-weight: bold;">{{ item.nome }}</span>
                        <span style="padding-left: 0.5rem;">({{ item.version }})</span>
                    </template>
                </b-table>
            </div>
        </template>
        <template slot="table-footer">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6">
                    <p>Totale Record: {{ rows }}</p>
                </b-col>
                <b-col cols="6">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
    </sa-page-layout>
</template>
<script>
import moment from "moment";
import axios from "axios";
import SaPageLayout from '../../../template/sa/components/layout/contents/SaPageLayout.vue';
export default {
    components: { SaPageLayout },
    computed: {
        rows() {
            return this.items.length;
        },
    },
    data() {
        return {
            titolo: "Parametri",
            showmolalloading: false,
            currentPage: 1,
            perPage: 30,
            filter: "",
            linkedit: "/moduli",
            isBusy: false,
            pathRest: "/application",
            fields: [
                {
                    label: "Nome",
                    key: "nome",
                    sortable: true,
                },
                {
                    label: "Vers.",
                    key: "compileDate",
                    sortable: true,
                    formatter: (value) => {
                        if (value) {
                            return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
                        } else {
                            return "";
                        }
                    },
                },
                {
                    label: "jar",
                    key: "jar",
                    sortable: true,
                },
                {
                    label: "Descrizione",
                    key: "descrizione",
                    sortable: true,
                },
            ],
            items: [],
            data: [],
            filtro: {},
        };
    },
    created: function() {},
    mounted: function() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    update: function() {
        console.log("Update");
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathRest;
            me.isBusy = true;
            me.showmolalloading = true;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];
                    me.items = response.data.data;
                    me.showmolalloading = false;
                })
                .catch(function(error) {
                    me.showmolalloading = false;
                    me.$bvToast.toast(error.response.data.messaggio, {
                        title: "Sorveglianza Covid Admin",
                        variant: "danger",
                        solid: true,
                    });
                });
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        onSubmit: function() {
            let me = this;
            me.loadData();
            return false;
        },
        onUpdate() {},
    },
};
</script>
