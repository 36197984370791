import Numeratori from "..";

const routes = [
{ name: "NumeratoriListPage", path: "/numeratori", component: Numeratori.NumeratoriListPage, meta: { title: "Numeratori" }},
{ name: "NumeratoriViewPage", path: "/numeratori/view/:id", component: Numeratori.NumeratoriViewPage, meta: { title: "Numeratori"}},
{ name: "NumeratoriEditPage", path: "/numeratori/edit/:id", component: Numeratori.NumeratoriEditPage, meta: { title: "Numeratori"}},


];
export default routes;
