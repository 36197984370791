import PrenotazioniList from "./components/PrenotazioniList.vue";
import PrenotazioniEdit from "./components/PrenotazioniEdit.vue";
import PrenotazioniView from "./components/PrenotazioniView.vue";
import leMiePrenotazioniView from "./components/LeMiePrenotazioniView.vue";
import LeMiePrenotazioniEdit from "./components/LeMiePrenotazioniEdit.vue";
import LeMiePrenotazioniEditMultiplo from "./components/LeMiePrenotazioniEditMultiplo.vue";
import LeMiePrenotazioniList from "./components/LeMiePrenotazioniList.vue";

export default {
  PrenotazioniList,
  PrenotazioniEdit,
  PrenotazioniView,
  LeMiePrenotazioniEditMultiplo,

  leMiePrenotazioniView,
  LeMiePrenotazioniEdit,
  LeMiePrenotazioniList,
};
