<template>
	<div style="height: 100%">
		<!--     <b-tabs vertical id="tabsRsaPazienteAreeView" ref="tabsRsaPazienteAreeView" v-model="activeTabIndex" nav-wrapper-class="sa-tab-items sa-tab-items-aree sa-tab-items-vertical" content-class="mt-3" class="sa-tab" style="padding-bottom: 25px" fill @input="onInputTabs"> -->
		<b-tabs id="tabsRsaAree" ref="tabsRsaAree" v-model="activeTabIndex" nav-wrapper-class="sa-tab-items" content-class="mt-3" class="sa-tab" style="height: 100%; padding-bottom: 25px" fill @input="onInputTabs">
			<!-- </b-tabs> -->
			<b-tab v-for="areaTab in areeTabs" :key="areaTab.id" :title="areaTab.nome">
				<div class="sa-accordion-wrapper">
					<form>
						<div class="sa-accordion">
							<input class="sa-accordion-input" type="radio" :name="areaTab.id + 'radio-a'" :id="areaTab.id + 'radio-a'" value="1" checked="checked" />
							<label class="sa-accordion-label" :for="areaTab.id + 'radio-a'">
								PIANIFICAZIONE
								<b-button size="sm" variant="outline-success outline-secondary-noborder pull-right sa-accordion-btn" @click="onClickNuovaPianificazione()">
									<i class="fas fa-plus"></i>
									Aggiungi
								</b-button>
							</label>
							<div class="sa-accordion-content">
								<div class="b-table-sticky-header">
									<b-table sticky-header ref="table" stacked="lg" striped hover :items="pianificazioni" :fields="fields" :current-page="1" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
										<template v-slot:cell(indicazioni)="{ item }">
											<span v-html="item.indicazioni"></span>
										</template>
										<template v-slot:cell(inizioFine)="{ item }">
											<span style="display: inline-block; white-space: nowrap">Inizio: {{ formatDate(item.dataInizio) }}</span
											><br />
											<span style="display: inline-block; white-space: nowrap">Fine: {{ formatDate(item.dataFine) }}</span>
										</template>
										<template v-slot:cell(actions)="row">
											<b-container class="bv-example-row">
												<b-button v-b-tooltip.hover size="sm" variant="outline-primary no-text" title="Modifica Pianificazione" @click="onClickEdit(row.item, row.index, $event.target)" class="mr-1 no-text">
													<b-icon icon="pencil"></b-icon>
												</b-button>
											</b-container>
										</template>
									</b-table>
								</div>
							</div>
						</div>
						<div class="sa-accordion">
							<input class="sa-accordion-input" type="radio" :name="areaTab.id + 'radio-a'" :id="areaTab.id + 'radio-b'" value="2" />
							<label class="sa-accordion-label" :for="areaTab.id + 'radio-b'">
								ATTIVITA'
								<!-- <b-button size="sm" variant="outline-success outline-secondary-noborder pull-right sa-accordion-btn" @click="onClickNuovaAttivita()">
                                    <i class="fas fa-plus"></i>
                                    Aggiungi
                                </b-button> -->
							</label>
							<div slot="table-filter">
								<b-form @submit.prevent="onSubmit">
									<b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
										<b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
											<label class="sa-label-data">Data Dal</label>
											<date-picker v-model="filtroScheduler.dataDal" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
										</b-col>
										<b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
											<label class="sa-label-data">Data Al</label>
											<date-picker v-model="filtroScheduler.dataAl" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
										</b-col>
									</b-row>
									<b-row style="margin-top: 5px">
										<b-col class="sa-padding-right text-right">
											<b-button type="submit" variant="info">Cerca</b-button>
											<b-button type="reset" v-on:click="onReset" variant="danger">Reset</b-button>
										</b-col>
									</b-row>
								</b-form>
							</div>
							<div class="sa-accordion-content">
								<!-- <p>I hope you are enjoing the video, don't forget to give your feedback in comment section</p> -->
								<div class="b-table-sticky-header">
									<b-table sticky-header ref="table" stacked="lg" :tbody-tr-class="getRowClass" striped hover :items="attivitaSchedulate" :fields="fieldsAttivitaSchedulate" :current-page="1" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
										<template v-slot:cell(indicazioni)="{ item }">
											<span v-html="item.indicazioni"></span>
										</template>
										<template v-slot:cell(actions)="row">
											<b-container class="bv-example-row">
												<b-button v-if="checkVisible('PROGRAMMATA', row.item)" v-b-tooltip.hover size="sm" variant="outline-success no-text" title="Esegui Attivita'" @click="onClickEseguiAttivita(row.item, row.index, $event.target)" class="mr-1 no-text">
													<b-icon icon="check"></b-icon>
												</b-button>
												<b-button v-if="checkVisible('INFO', row.item)" v-b-tooltip.hover size="sm" variant="outline-primary no-text" title="Modifica Attivita'" @click="onClickInfoAttivita(row.item, row.index, $event.target)" class="mr-1 no-text">
													<b-icon icon="info"></b-icon>
												</b-button>
											</b-container>
										</template>
									</b-table>
								</div>
							</div>
						</div>
					</form>
				</div>
			</b-tab>
		</b-tabs>
		<rsa-aree-pianificazione-edit-component ref="cmpRsaAreePianificazioneEditComponent" @afterSaveRequest="onAfterSaveRequestRsaAreePianificazioneComponent"></rsa-aree-pianificazione-edit-component>
		<rsa-aree-esegui-attivita-edit-component ref="cmpRsaAreeEseguiAttivitaEditComponent" @afterSaveRequest="onAfterSaveRequestRsaAreePianificazioneComponent"></rsa-aree-esegui-attivita-edit-component>
	</div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import RsaAreePianificazioneEditComponent from "./RsaAreePianificazioneEditComponent.vue";
import RsaAreeEseguiAttivitaEditComponent from "./RsaAreeEseguiAttivitaEditComponent.vue";
import UtilityMixin from "../../../utility/UtilityMixin";
export default {
	mixins: [UtilityMixin],
	components: { RsaAreePianificazioneEditComponent, DatePicker, RsaAreeEseguiAttivitaEditComponent },
	created() {
		/* let me = this;
        me.loadAree(); */
	},
	props: {
		/*     jsonDataAccettazione: { type: Object }, */
	},
	data() {
		return {
			activeTabIndex: 0,
			perPage: 100,
			pathResourcesAree: "/rsaaree",
			pathResourcesAttivita: "/rsaattivitapazienteschedulazioni",
			areaSelezionata: "",
			jsonDataAccettazione: {},
			areeTabs: [],
			filtroScheduler: { dataDal: null, dataAl: null, idAccettazione: null, area: null },
			pianificazioni: [],
			attivitaSchedulate: [],
			fields: [
				{
					label: "Nome",
					key: "nome",
					sortable: true,
				},
				{
					label: "Dettaglio",
					key: "dettaglio",
					sortable: true,
				},
				{
					label: "Orari",
					key: "orari",
					sortable: true,
				},
				{
					label: "Indicazioni",
					key: "indicazioni",
					sortable: true,
				},
				{
					label: "Inizio/Fine",
					key: "inizioFine",
					sortable: true,
				},
				{ class: "sa-table-column-action-double", key: "actions", label: "" },
			],
			fieldsAttivitaSchedulate: [
				{
					label: "Attività",
					key: "nome",
					sortable: true,
					thStyle: "width: 7rem",
				},
				{
					label: "Nome",
					key: "dettaglio",
					sortable: true,
				},
				{
					label: "Data/ora Prevista",
					key: "dataOraPrevista",
					formatter: (value) => {
						if (value) {
							return moment(new Date(value)).format("DD/MM/YYYY HH:mm");
						} else {
							return "-----";
						}
					},
					sortable: true,
					thStyle: "width: 10rem",
				},
				{
					label: "Data/Ora Esecuzione:",
					key: "dataOraEffettuata",
					formatter: (value) => {
						if (value) {
							return moment(new Date(value)).format("DD/MM/YYYY HH:mm");
						} else {
							return "-----";
						}
					},
					sortable: true,
					thStyle: "width: 10rem",
				},
				{
					label: "Operatore",
					key: "cognomeNomeOperatore",
					sortable: true,
				},
				{
					label: "Stato",
					key: "stato",
					sortable: true,
				},
				// {
				//     label: "Inizio/Fine",
				//     key: "inizioFine",
				//     sortable: true,
				// },
				{ class: "sa-table-column-action-double", key: "actions", label: "" },
			],
		};
	},
	methods: {
		init(jsonDataAccettazione) {
			let me = this;
			me.jsonDataAccettazione = jsonDataAccettazione;
			me.loadAree();
		},
		getRowClass(item, type) {
			let dataOraAttuale = moment(new Date());
			let dataOraPrevista = moment(new Date(item.dataOraPrevista));
			let minuti = dataOraAttuale.diff(dataOraPrevista, "minute");
			if (item.stato === "NON ESEGUITA") {
				return "sa-table-row-danger";
			} else if (minuti > 15 && type === "row") {
				return "sa-table-row-warning";
			} else {
				return null;
			}
		},
		loadAree() {
			// let me = this;
			// me.areeTabs = [
			//     { id: "1", nome: "CURA DELLA PERSONA" },
			//     { id: "2a", nome: "AREA DI PROVA" },
			// ];
			// me.loadAttivita();
			let me = this;

			let link = process.env.VUE_APP_PATH_API + me.pathResourcesAree;

			axios.get(link)
				.then((response) => {
					me.areeTabs = response.data.data.list;
					me.areaSelezionata = me.areeTabs[0].nome;
					me.filtroScheduler.dataDal = new Date().getTime();
					me.loadDataScheduler();
					me.loadAttivita();
				})
				.catch(() => {});
		},
		loadAttivita() {
			let me = this;

			let link = process.env.VUE_APP_PATH_API + me.pathResourcesAttivita;
			let filtro = { idAccettazione: me.jsonDataAccettazione.id, area: me.areaSelezionata };
			axios.get(link, { params: filtro })
				.then((response) => {
					me.pianificazioni = response.data.data.list;
				})
				.catch(() => {});
		},
		onInputTabs(e) {
			let me = this;
			// let area = this.areeTabs[e];
			me.areaSelezionata = this.areeTabs[e].nome;
			me.loadAttivita();
			me.filtroScheduler.dataDal = new Date().getTime();
			me.loadDataScheduler();
			// console.log(me.areaSelezionata);

			// console.log(area);
		},
		onClickNuovaPianificazione() {
			let me = this;
			// let area = me.areeTabs.reduce((acc, currentValue) => rec.nome === me.areaSelezionata);
			let area = me.areeTabs.reduce((row, currentRow) => (row.nome === me.areaSelezionata ? row : currentRow));

			me.$refs.cmpRsaAreePianificazioneEditComponent.edit(
				{
					id: null,
					nome: null,
					dataInizio: new Date().getTime(),
					dataFine: null,
					listaOrari: [],
					idAccettazione: me.jsonDataAccettazione.id,
					idPaziente: me.jsonDataAccettazione.accettazioneAnagrafica.id,
					area: me.areaSelezionata,
					dettaglio: "",
				},
				area
			);
		},
		onClickNuovaAttivita() {},
		onAfterSaveRequestRsaAreePianificazioneComponent() {
			let me = this;
			me.loadDataScheduler();
			me.loadAttivita();
		},
		onClickEdit(item) {
			let me = this;
			let area = me.areeTabs.reduce((row, currentRow) => (row.nome === me.areaSelezionata ? row : currentRow));
			console.log();
			me.$refs.cmpRsaAreePianificazioneEditComponent.edit(item, area);
			// console.log(item);
		},
		onSubmit() {
			let me = this;
			me.loadDataScheduler();
		},
		onReset() {
			let me = this;
			me.attivitaSchedulate = [];
			me.filtroScheduler = { dataDal: null, dataAl: null, idAccettazione: me.jsonDataAccettazione.id, area: me.jsonDataAccettazione.areaSelezionata };
		},
		loadDataScheduler() {
			let me = this;

			let link = process.env.VUE_APP_PATH_API + me.pathResourcesAttivita + "/scheduler";
			me.filtroScheduler.idAccettazione = me.jsonDataAccettazione.id;
			me.filtroScheduler.area = me.areaSelezionata;
			axios.get(link, { params: me.filtroScheduler })
				.then((response) => {
					me.attivitaSchedulate = response.data.data;
				})
				.catch((error) => {
					// me.$emit("afterSaveRequest");
					let messaggio = error.response ? error.response.data.messaggio : "Errore non gestito";
					me.$bvModal
						.msgBoxOk(messaggio, {
							title: "ATTENZIONE",
							size: "sm",
							okVariant: "outline-danger",
							headerClass: "sa-msg-header-danger",
							footerClass: "p-2 border-top-0",
							centered: true,
						})
						.then(() => {})
						.catch((err) => {
							console.log(err);
						});
				});
		},
		onClickEseguiAttivita(item) {
			let me = this;
			if (item.rsaAttivitaPazienteBean === null) {
				item.dataOraEffettuata = new Date().getTime();
			}
			me.$refs.cmpRsaAreeEseguiAttivitaEditComponent.add(item);
		},
		checkVisible(stato, item) {
			let returnValue = false;
			if (item.stato === stato) {
				returnValue = true;
			} else if (stato === "INFO" && item.stato !== "PROGRAMMATA") {
				returnValue = true;
			}
			return returnValue;
		},
		onClickInfoAttivita(item) {
			console.log(item);
		},
	},
};
</script>
