import MalattieInfettive from "../";

const routes = [

    { name: "Malattie Infettive Strutture List", path: "/malattieinfettive/strutture", component: MalattieInfettive.MalattieInfettiveStruttureListPage, meta: { title: "Lista Strutture" } },
    { name: "Malattie Infettive Strutture Edit", path: "/malattieinfettive/strutture/edit/:id", component: MalattieInfettive.MalattieInfettiveStrutturaEditPage, meta: { title: "Struttura" } },
    { name: "Malattie Infettive Strutture View", path: "/malattieinfettive/strutture/view/:id", component: MalattieInfettive.MalattieInfettiveStrutturaViewPage, meta: { title: "Struttura" } },
];

export default routes;
