<template>
    <div>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <template #header>
                <b-row>
                    <b-col cols="12" xs="8" sm="8" md="8" lg="8" xl="8">
                        <span class="sa-label-data">LISTA DISTRETTI ASSOCIATI</span>
                    </b-col>
                    <b-col class="sa-padding-right text-right" cols="12" xs="4" sm="4" md="4" lg="4" xl="4">
                        <b-button class="float-sm-right" variant="outline-success btn-toolbar sa-margin-right" size="sm" v-b-modal.modalAssociaDistretto>
                            <b-icon icon="people-fill"></b-icon>
                            Associa Distretto
                        </b-button>
                    </b-col>
                </b-row>
            </template>
            <div class="sa-list-component-header">
                <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col lg="3">
                        <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                    </b-col>
                    <b-col lg="9">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                    </b-col>
                </b-row>
            </div>
            <!-- <div class="sa-list-component-body"> -->
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" stacked="lg" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
                    <template #cell(firstname)="row">
                        <p>{{ row.item.lastname }} {{ row.item.firstname }}</p>
                    </template>
                    <template #cell(actions)="row">
                        <b-button variant="outline-danger" @click="onDeleteAssociazione(row.item)">
                            <b-icon icon="person-x-fill" aria-hidden="true"></b-icon>
                            Dissocia
                        </b-button>
                    </template>
                </b-table>
            </div>
            <!-- </div> -->
            <div class="sa-list-component-footer">
                <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col lg="3">
                        <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rows }}</p>
                    </b-col>
                    <b-col lg="9">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                    </b-col>
                </b-row>
            </div>
            <b-modal size="lg" ref="modalAssocia" id="modalAssociaDistretto" title="Associa Distretto" @show="onShow()" scrollable>
                <!-- <b-form @submit.prevent="onSubmitDistretti">
                        <b-row>
                            <b-col xs="6" sm="6" md="6" lg="6" xl="6">
                                <b-form-input v-model="mdlFiltroUsers.username" type="search" placeholder="Username"></b-form-input>
                            </b-col>

                            <b-col xs="6" sm="6" md="6" lg="6" xl="6">
                                <b-form-select v-model="mdlFiltroUsers.profilo" :options="profiliModalOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="sa-padding-right text-right">
                                <b-button type="submit" variant="info">Cerca</b-button>
                            </b-col>
                        </b-row>
                    </b-form> -->
                <div class="b-table-sticky-header">
                    <b-table sticky-header :busy="isBusy" ref="table" selectable stacked="lg" striped hover :items="itemsDistretti" :fields="fieldsModal" sort-icon-left head-variant="light" class="sa-b-table" current-page="1" :per-page="perPageUsers" @row-selected="onRowSelected">
                        <template #table-busy>
                            <div class="text-center text-info my-2">
                                <b-spinner class="align-middle"></b-spinner>
                                <strong>Caricamento...</strong>
                            </div>
                        </template>
                        <template #cell(selected)="{ rowSelected }">
                            <template v-if="rowSelected">
                                <span aria-hidden="true">&check;</span>
                                <span class="sr-only">Selected</span>
                            </template>
                            <template v-else>
                                <span aria-hidden="true">&nbsp;</span>
                                <span class="sr-only">Not selected</span>
                            </template>
                        </template>
                        <template #cell(firstname)="row">
                            <p>{{ row.item.lastname }} {{ row.item.firstname }}</p>
                        </template>
                    </b-table>
                </div>

                <template #modal-footer="{ ok }">
                    <b-button size="sm" variant="outline-danger" @click="ok()">
                        <b-icon icon="x"></b-icon>
                        Annulla
                    </b-button>
                    <b-button size="sm" variant="outline-success" @click="onAssocia()">
                        <b-icon icon="person-check-fill"></b-icon>
                        Associa
                    </b-button>
                </template>
            </b-modal>
        </b-card>
    </div>
</template>

<script>
import axios from "axios";
import UtilityMixin from "../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixin],
    // props: {
    //     idPuntoIncontro: {
    //         type: String,
    //     },
    // },
    data() {
        return {
            rows: 0,
            perPage: 50,
            perPageUsers: 100000,
            currentPage: 1,
            currentPageUsers: 1,
            pathResource: "/sveipuntiincontrodistretti",
            pathResourceStrutturaUtenti: "/sveipuntiincontrodistretti",
            pathResourceDistretti: "/distretti",
            filtro: {},
            filtroDistretto: { forPage: 0, page: 0 },
            items: [],
            mdlFiltroUsers: {
                profilo: "",
                username: "",
            },
            profiliModalOptions: [],
            itemsDistretti: [],
            selected: [],
            listKey: 0,
            isBusy: false,
            fields: [
                {
                    label: "Codice",
                    key: "codiceDistretto",
                    sortable: true,
                    thStyle: "width: 8rem",
                },
                {
                    label: "Denominazione",
                    key: "descrizione",
                    sortable: true,
                    // thStyle: "width: 8rem",
                },
                {
                    label: "",
                    key: "actions",
                    tdClass: "text-right",
                },
            ],
            fieldsModal: [
                {
                    label: "",
                    key: "selected",
                    thStyle: "width: 2.5rem",
                    tdClass: "text-center",
                },
                {
                    label: "Codice",
                    key: "codiceDistretto",
                    sortable: true,
                    thStyle: "width: 8rem",
                },
                {
                    label: "Denominazione",
                    key: "descrizione",
                    sortable: true,
                    // thStyle: "width: 8rem",
                },
            ],
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    methods: {
        loadData() {
            // console.log("Eccomi");
            let me = this;
            let sessionStorage = window.sessionStorage;
            if (sessionStorage["filtro"]) {
                me.filtro = JSON.parse(sessionStorage["filtro"]);
            }
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.filtro.page = me.currentPage;
            me.filtro.forPage = me.perPage;
            me.filtro.idPuntoIncontro = me.id;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];
                    me.rows = response.data.data.recordsNumber;
                    me.items = response.data.data.list;
                    me.listKey++;
                    this.$emit("afterLoadData");
                })
                .catch(() => {
                    this.$emit("afterLoadData");
                });
        },

        onDeleteAssociazione(item) {
            let me = this;
            this.$bvModal
                .msgBoxConfirm("Sei sicuro di procedere alla cancellazione dell'associazione?", {
                    title: "Conferma Cancellazione Associazione",
                    buttonSize: "sm",
                    okVariant: "secondary",
                    cancelVariant: "danger",
                    okTitle: "SI",
                    cancelTitle: "NO",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                })
                .then((value) => {
                    if (value) {
                        me.deleteAssociazione(item);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        deleteAssociazione(item) {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceStrutturaUtenti + "/";
            axios
                .delete(link + item.id)
                .then((response) => {
                    console.log(response);
                    me.showModalLoading = false;
                    me.loadData();
                })
                .catch((error) => {
                    console.log(error);
                    me.showModalLoading = false;
                });
        },
        onShow() {
            let me = this;
            me.loadDataDistretti();
        },
        onRowSelected(items) {
            this.selected = items;
        },
        onResetFiltro() {
            let me = this;
            me.filtroModal.profilo = "";
            me.filtroModal.username = "";
            me.loadDataDistretti();
        },
        onFiltra() {
            let me = this;
            me.loadDataDistretti();
        },
        onSubmitDistretti() {
            let me = this;
            me.loadDataDistretti();
        },
        loadDataDistretti() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceDistretti;
            me.showModalLoading = true;
            me.filtroDistretto.forPage = me.perPageUsers;
            me.filtroDistretto.page = me.currentPageUsers;
            me.filtroDistretto.codiceRegione = "150";
            me.filtroDistretto.codiceAsl = "204";
            axios.get(link, { params: me.filtroDistretto }).then((response) => {
                me.isBusy = false;
                me.showModalLoading = false;
                me.itemsDistretti = response.data.data.list;
            });
        },
        // loadDefaultData() {
        //     let me = this;
        //     me.showModalLoading = true;
        //     let link = process.env.VUE_APP_PATH_API + me.pathResourceGruppiUtenti;
        //     axios
        //         .get(link)
        //         .then((response) => {
        //             response.data.data.forEach((element) => {
        //                 me.profiliModalOptions.push({ text: element.nome, value: element.nome });
        //             });
        //         })
        //         .finally(() => {
        //             me.profiliModalOptions.unshift({ text: this.getLabelTraduora("patient.ecoreferting.lblSelected"), value: "" });
        //         });
        // },
        onAssocia() {
            let me = this;
            for (let i = 0; i < me.selected.length; i++) {
                let obj = { id: null, idPuntoIncontro: me.id, idDistretto: me.selected[i].id };
                me.associaUtenteStruttura(obj);
            }
        },
        associaUtenteStruttura(obj) {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceStrutturaUtenti + "/";
            axios
                .post(link, { idPuntoIncontro: obj.idPuntoIncontro, idDistretto: obj.idDistretto })
                .then((response) => {
                    me.$refs["modalAssocia"].hide();
                    response.data.data;
                    me.showModalLoading = false;
                    me.loadData();
                    // console.log(me.jsonData);
                    this.$bvToast.toast(`Associazione Avvenuta con Successo`, {
                        title: this.getLabelTraduora("ambulatory.toastAssociateUserSuccess"),
                        solid: true,
                        variant: "success",
                        autoHideDelay: 1000,
                        appendToast: true,
                    });
                })
                .catch((e) => {
                    console.log(e);
                    me.showModalLoading = false;
                });
        },
    },
};
</script>

<style>
.sa-alert-descrizione {
    font-style: italic;
}
</style>
