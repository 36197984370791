<template>
    <b-modal class="modal-content" ref="mdlDataAnalysisDriverDatabaseEditModalComponent" title="Driver" no-close-on-backdrop no-close-on-select @ok="oOkMdlDataAnalysisDriverDatabaseEditModalComponent">
        <b-row>
            <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <label class="sa-label-data">Nome</label>
                <b-form-input class="form-control" v-model="jsonData.driverName"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <label class="sa-label-data">JDBC Name</label>
                <b-form-input class="form-control" v-model="jsonData.jdbcName"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <label class="sa-label-data">Classe Java</label>
                <b-form-input class="form-control" v-model="jsonData.className"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <label class="sa-label-data">Query Fields Info</label>
                <b-form-input class="form-control" v-model="jsonData.queryFieldsInfo"></b-form-input>
            </b-col>
        </b-row>
    </b-modal>
</template>

<script>
import UtilityMixin from "../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixin],
    data() {
        return {
            jsonData: { driverName: null },
            pathResourcesDatabaseDriver: "/dataanalysisdatabasedriver",
        };
    },
    methods: {
        show() {
            let me = this;
            me.$refs.mdlDataAnalysisDriverDatabaseEditModalComponent.show();
        },
        setJsonData(jsonData) {
            let me = this;
            me.jsonData = jsonData;
        },
        oOkMdlDataAnalysisDriverDatabaseEditModalComponent(bvModalEvent) {
            bvModalEvent;
            let me = this;
            // bvModalEvent.preventDefault();
            UtilityMixin.methods.saveResource(me.pathResourcesDatabaseDriver, me.jsonData, me.saveSucessResponse, me.saveErrorResponse);
        },
        saveSucessResponse(response) {
            this.$refs.mdlDataAnalysisDriverDatabaseEditModalComponent.hide();
            this.$emit("saveDateSuccess", response);
        },
        saveErrorResponse(response) {
            if (response.response.status === 400) {
                this.$emit("errorRestCall", response);
            } else {
                this.$bvToast.toast("Errore nel salvarei dati del Piano: " + response.message, {
                    title: "",
                    variant: "danger",
                    solid: true,
                });
            }
        },
        /*
        saveResource(pathResource, body, resultResponse, resultError = null) {
            let link = process.env.VUE_APP_PATH_API + pathResource;
            if (body.id === "-1" || body.id === null || body.id === undefined) {
                this.restCallPost(link, body, resultResponse, resultError);
            } else {
                link = link + "/" + body.id;
                this.restCallPut(link, body, resultResponse, resultError);
            }
        },        
        */
    },
};
</script>

<style></style>
