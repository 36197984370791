<template>
    <div class="sa-list-component">
        <div class="sa-list-component-header">
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </div>
        <div class="sa-list-component-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" stacked="lg" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
                    <template v-slot:cell(denominazione)="{ item }">
                        <router-link class="sa-edit-link" :to="'/cot/strutture/view/' + item.id">{{ item.denominazione }}</router-link>
                    </template>
                    <template v-slot:cell(indirizzo)="{ item }">
                        <span>{{ item.indirizzo }} </span>
                        <span class="sa-label-data-secondary">
                            <span v-if="item.localita !== null && item.localita !== ''">{{ item.descrizioneLocalita }} ({{ item.provincia }}) </span>
                        </span>
                    </template>
                    <!-- <template #cell(actions)="row">
                        <b-button size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onUpdatePostiLetto(row.item)">
                            <i class="fas fa-bed"></i>
                        </b-button>
                    </template> -->
                </b-table>
            </div>
        </div>
        <div class="sa-list-component-footer">
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </div>
        <b-modal b-modal ref="mdlPostiLetto" id="mdlmdlPostiLetto" size="md" style="height: 100%" @ok="onAddPostiLetto" title="Inserire numero posti letto">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Disponibilita'</label>
                    <b-form-input v-model="strutturePostiLetto.disponibilita" type="number" id="disponibilita" placeholder="Inserire numero posti letto"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Letti Occupati</label>
                    <b-form-input v-model="strutturePostiLetto.postiOccupati" type="number" id="disponibilita" placeholder="Inserire numero posti letto"></b-form-input>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
// import Vue from "vue";
import axios from "axios";
// import moment from "moment";
import UtilityMixin from "../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixin],
    watch: {
        currentPage() {
            let me = this;
            me.loadData();
        },
    },
    data() {
        return {
            rows: 0,
            perPage: 50,
            currentPage: 1,
            pathResource: "/cotstrutture",
            pathResourceStruttureDisponibilita: "/cotstrutturedisponibilita",
            filtro: {},
            strutturaSelezionata: {},
            items: [],
            listKey: 0,
            strutturePostiLetto: { idStruttura: "", disponibilita: 0, postiOccupati: 0 },
            fields: [
                {
                    label: "Struttura",
                    key: "denominazione",
                    sortable: true,
                    thStyle: "width: 20rem",
                },
                {
                    label: "Tipologia",
                    key: "tipoStruttura",
                    sortable: true,
                    thStyle: "width: 20rem",
                },
                {
                    label: "indirizzo",
                    key: "indirizzo",
                    sortable: true,
                },
                // {
                //     label: "Codice Prestazione",
                //     key: "codicePrestazione",
                //     sortable: true,
                //     thStyle: "width: 4rem",
                // },
                // {
                //     label: "Disp. Letti",
                //     key: "disponibilita",
                //     sortable: true,
                //     thStyle: "width: 4rem",
                // },
                // {
                //     label: "Letti Occupati",
                //     key: "postiOccupati",
                //     sortable: true,
                //     thStyle: "width: 4rem",
                // },
                // {
                //     label: "Letti Prenotati",
                //     key: "prenotati",
                //     sortable: true,
                //     thStyle: "width: 4rem",
                // },
                // {
                //     label: "Letti Liberi",
                //     key: "liberi",
                //     sortable: true,
                //     thStyle: "width: 4rem",
                // },
                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 4rem",
                    tdClass: "text-center",
                },
            ],
        };
    },
    mounted() {
        let me = this;
        let sessionStorage = window.sessionStorage;
        if (sessionStorage["filtro"]) {
            me.filtro = JSON.parse(sessionStorage["filtro"]);
        }
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            if (sessionStorage["filtro"]) {
                me.filtro = JSON.parse(sessionStorage["filtro"]);
            }
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.filtro.page = me.currentPage;
            me.filtro.forPage = me.perPage;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];

                    me.rows = response.data.data.recordsNumber;
                    me.items = response.data.data.list;

                    me.listKey++;
                    this.$emit("afterLoadData");
                })
                .catch(() => {
                    this.$emit("afterLoadData");
                });
        },
        onUpdatePostiLetto(value) {
            let me = this;
            // console.log(value);
            me.strutturePostiLetto.idStruttura = value.id;
            me.strutturePostiLetto.disponibilita = value.disponibilita;
            me.strutturePostiLetto.postiOccupati = value.postiOccupati;
            me.$refs["mdlPostiLetto"].show();
        },
        onAddPostiLetto() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceStruttureDisponibilita;
            axios
                .post(link, JSON.stringify(me.strutturePostiLetto))
                .then(() => {
                    me.$refs["mdlPostiLetto"].hide();
                    this.$bvToast.toast("Disponibilita' letti aggiornata", {
                        title: this.titleModal,
                        variant: "success",
                        autoHideDelay: 1000,
                    });
                    me.loadData();
                })
                .catch((error) => {
                    me.showModalLoading = false;
                    this.$bvModal
                        .msgBoxOk(error.response.data.messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then((value) => {
                            this.boxTwo = value;
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                });
        },
    },
};
</script>

<style></style>
