<template>
  <sa-page-layout :btnNewVisible="true" :linkedit="linkedit">
    <template slot="toolbar-title">
      <p>Altre Prenotazioni</p>
    </template>
    <template slot="toolbar-row"> </template>
    <template slot="table-filter"> </template>
    <template slot="table-header"> </template>
    <template slot="table-body">
      <div class="b-table-sticky-header sa-scroll">
        <b-table sticky-header ref="table" :filter="filter" stacked="lg" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
          <template v-slot:cell(id)="{ item }">
            <router-link class="sa-edit-link" :to="'/altreprenotazioni/view/' + item.id"> {{ item.id }} </router-link>
          </template>
          <!-- <template v-slot:cell(actions)="row">
            <b-button size="sm" variant="btn btn-purple waves-effect  waves-light width-sm no-text" style="margin-right: 3px" @click="onClick(row.item, row.index, $event.target)" v-b-modal.visualizzaAllegato>
              <b-icon icon="search"></b-icon>
            </b-button>
          </template> -->
        </b-table>
      </div>
    </template>

    <template slot="table-footer"> </template>
  </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../template/layout/components/SaPageLayout.vue";
export default {
  props: {
    utente: {
      type: Object,
      default: function () {
        return { idUtente: null };
      },
    },
  },
  components: { SaPageLayout },
  data() {
    return {
      filter: {},
      linkedit: "/altreprenotazioni",
      items: [{ id: "prenotazione1", puntoPrelievo: "Domicilio", identificativoPrenotazione: "CODICE FISCALE SOGGETTO", dataEvento: new Date() }],
      fields: [
        {
          label: "Identificativo Prenotazione",
          key: "id",
          thStyle: "width: 10rem",
          tdClass: "text-left",
        },
        {
          label: "Punto Prelievo",
          key: "puntoPrelievo",
          thStyle: "width: 10rem",
          tdClass: "text-left",
        },
        {
          label: "Indentificativo Prenotazione ",
          key: "identificativoPrenotazione",
          thStyle: "width: 10rem",
          tdClass: "text-left",
        },
        {
          label: "Data Prenotazione",
          key: "dataEvento",
          thStyle: "width: 10rem",
          tdClass: "text-left",
        },
      ],
      currentPage: 1,
      perPage: 10,
    };
  },
};
</script>

<style></style>
