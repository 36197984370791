<template>
    <b-modal ref="mdlSchedaSocialePaziente" title="Scheda Sociale" @hidden="onCloseModal" @ok="onSalvaSchedaSocialePaziente" centered no-close-on-backdrop no-close-on-esc hide-header-close size="lg">
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card sa-card-border" header="Utilizzo di protesi ed ausili" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" v-for="ausilio in ausiliOptions" :key="'ausilio-' + ausilio.id" class="sa-switch-select-element">
                            <b-form-checkbox switch :disabled="isReadOnly" v-model="selectedAusili" :value="ausilio.voce">
                                <span style="font-weight: normal">{{ ausilio.voce }}</span>
                                <!-- La voce delle checkbox sono bold in default quando uso b-form-checkbox, vanno gestite separatamente -->
                            </b-form-checkbox>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card sa-card-border" header="Assistenza pregressa domiciliare:" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" v-for="assistenza in assistenzeOptions" :key="'assistenza-' + assistenza.id" class="sa-switch-select-element">
                            <b-form-checkbox switch :disabled="isReadOnly" v-model="selectedAssistenze" :value="assistenza.voce">
                                <span style="font-weight: normal">{{ assistenza.voce }}</span>
                            </b-form-checkbox>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card sa-card-border" header="Altri Dati:" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Grado di accettazione Ricovero</label>
                            <b-form-select v-model="jsonDataSchedaSocialePaziente.gradoAccettazioneRicovero" :options="gradoAccettazioneRicoveroOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Tipo struttura di provenienza</label>
                            <b-form-select v-model="jsonDataSchedaSocialePaziente.tipoStrutturaProvenienza" :options="tipoStrutturaProvenienzaOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Modalita relazionale</label>
                            <b-form-select v-model="jsonDataSchedaSocialePaziente.modalitaRelazionale" :options="modalitaRelazionaleOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Indennità di accompanagmento</label>
                            <b-form-select v-model="jsonDataSchedaSocialePaziente.indennitaAccompagnamento" :options="indennitaAccompagnamentoOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Note</label>
                            <b-form-textarea v-model="jsonDataSchedaSocialePaziente.note" :readonly="isReadOnly" max-rows="10" rows="7"></b-form-textarea>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <template #modal-footer>
            <b-button v-if="!isReadOnly" variant="outline-danger" @click="$refs.mdlSchedaSocialePaziente.hide()">Annulla</b-button>
            <b-button v-if="!isReadOnly" variant="outline-success" @click="onSalvaSchedaSocialePaziente()">Salva</b-button>
            <b-button v-if="isReadOnly" variant="outline-primary" @click="$refs.mdlSchedaSocialePaziente.hide()"> Chiudi </b-button>
        </template>
    </b-modal>
</template>

<script>
import UtilityMixin from "../../../utility/UtilityMixin";

export default {
    mixins: [UtilityMixin],
    mounted() {
        let me = this;
        me.idAccettazione = me.$route.query.idPaziente;
        me.loadDizionarioData();
    },
    data() {
        return {
            isReadOnly: false,
            pathResourceSchedaSocialePaziente: "/rsaschedasocialepaziente",
            pathResourceCheckSchedaSocialePaziente: "/rsaschedasocialepaziente/check",
            pathResourceDizionario: "/rsadizionario",
            ausiliOptions: [],
            assistenzeOptions: [],
            selectedAusili: [],
            selectedAssistenze: [],
            jsonDataSchedaSocialePaziente: {
                id: null,
                idAccettazione: null,
                gradoAccettazioneRicovero: null,
                tipoStrutturaProvenienza: null,
                modalitaRelazionale: null,
                indennitaAccompagnamento: null,
                note: null,
                listaSchedaSocialeDettagli: [],
            },

            gradoAccettazioneRicoveroOptions: [
                { text: "FAVOREVOLE", value: "FAVOREVOLE" },
                { text: "PARZIALMENTE FAVOREVOLE", value: "PARZIALMENTE FAVOREVOLE" },
                { text: "RASSEGNATO", value: "RASSEGNATO" },
                { text: "NON FAVOREVOLE", value: "NON FAVOREVOLE" },
                { text: "NON CONSAPEVOLE", value: "NON CONSAPEVOLE" },
            ],
            tipoStrutturaProvenienzaOptions: [
                { text: "DOMICILIO DELL'ASSISTITO, SENZA CURE PALLIATIVE DOMICILIARI ATTIVE", value: "DOMICILIO DELL'ASSISTITO, SENZA CURE PALLIATIVE DOMICILIARI ATTIVE" },
                { text: "DOMICILIO DELL'ASSISTITO, CON CURE PALLIATIVE DOMICILIARI ATTIVE", value: "DOMICILIO DELL'ASSISTITO, CON CURE PALLIATIVE DOMICILIARI ATTIVE" },
                { text: "STRUTTURA SOCIO-SANITARIA RESIDENZIALE", value: "STRUTTURA SOCIO-SANITARIA RESIDENZIALE" },
                { text: "STRUTTURA SOCIALE", value: "STRUTTURA SOCIALE" },
                { text: "STRUTTURA OSPEDALIERA", value: "STRUTTURA OSPEDALIERA" },
                { text: "STRUTTURA DI RIABILITAZIONE", value: "STRUTTURA DI RIABILITAZIONE" },
                { text: "ALTRO", value: "ALTRO" },
            ],
            modalitaRelazionaleOptions: [
                { text: "TU", value: "TU" },
                { text: "LEI", value: "LEI" },
                { text: "VOI", value: "VOI" },
            ],
            indennitaAccompagnamentoOptions: [
                { text: "SI", value: "SI" },
                { text: "NO", value: "NO" },
                { text: "IN ATTESA", value: "IN ATTESA" },
            ],
        };
    },

    methods: {
        loadDizionarioData() {
            let me = this;
            UtilityMixin.methods.loadDataResources(me.pathResourceDizionario, null, me.loadDizionarioDataSuccess, me.loadDizionarioDataError);
        },

        loadDizionarioDataSuccess(response) {
            let me = this;
            if (response.data?.data?.list) {
                me.ausiliOptions = response.data.data.list.filter((item) => item.tipo === "AUSILIO");
                me.assistenzeOptions = response.data.data.list.filter((item) => item.tipo === "ASSISTENZA");
            }
        },
        loadDizionarioDataError(error) {
            console.error("Errore nel caricamento ausili/assistenza", error);
        },

        nuovaSchedaSocialePaziente() {
            // console.log(item);
            let me = this;
            me.isReadOnly = false;
            me.selectedAusili = [];
            me.selectedAssistenze = [];
            UtilityMixin.methods.loadDataResources(me.pathResourceSchedaSocialePaziente + "/accettazione/" + me.idAccettazione, {}, me.resultResponseSchedaSocialePazienteSuccess, me.resultResponseSchedaSocialePazienteError);
        },

        resultResponseSchedaSocialePazienteSuccess(response) {
            let me = this;
            me.jsonDataSchedaSocialePaziente = response.data.data;
            me.jsonDataSchedaSocialePaziente.listaSchedaSocialeDettagli.forEach((dettaglio) => {
                if (dettaglio.tipo === "AUSILIO") {
                    me.selectedAusili.push(dettaglio.voce);
                } else if (dettaglio.tipo === "ASSISTENZA") {
                    me.selectedAssistenze.push(dettaglio.voce);
                }
            });
            me.$refs.mdlSchedaSocialePaziente.show();
        },

        resultResponseSchedaSocialePazienteError() {
            let me = this;
            me.resetForm();
            me.$refs.mdlSchedaSocialePaziente.show();
        },
        /* Ho gestito l'errore in questo modo perché nel DataSuccess non potevo usare un if-else poiché se l'id non veniva trovato, la chiamata andava in Error 404.*/

        resetForm() {
            let me = this;
            me.jsonDataSchedaSocialePaziente = {
                id: null,
                idAccettazione: me.idAccettazione,
                gradoAccettazioneRicovero: null,
                tipoStrutturaProvenienza: null,
                modalitaRelazionale: null,
                indennitaAccompagnamento: null,
                note: null,
                listaSchedaSocialeDettagli: [],
            };
            me.selectedAusili = [];
            me.selectedAssistenze = [];
        },

        visualizzaSchedaSocialePaziente() {
            let me = this;
            me.isReadOnly = true;
            me.selectedAusili = [];
            me.selectedAssistenze = [];
            UtilityMixin.methods.loadDataResources(me.pathResourceSchedaSocialePaziente + "/accettazione/" + me.idAccettazione, {}, me.resultResponseSchedaSocialePazienteViewSuccess, me.resultResponseSchedaSocialePazienteViewError);
        },
        resultResponseSchedaSocialePazienteViewSuccess(response) {
            let me = this;
            me.jsonDataSchedaSocialePaziente = response.data.data;
            me.jsonDataSchedaSocialePaziente.listaSchedaSocialeDettagli.forEach((dettaglio) => {
                if (dettaglio.tipo === "AUSILIO") {
                    me.selectedAusili.push(dettaglio.voce);
                } else if (dettaglio.tipo === "ASSISTENZA") {
                    me.selectedAssistenze.push(dettaglio.voce);
                }
            });
            me.$refs.mdlSchedaSocialePaziente.show();
        },
        resultResponseSchedaSocialePazienteViewError() {
            let me = this;
            me.$bvToast.toast("Nessun record trovato per questa accettazione", {
                title: "Informazione",
                variant: "info",
                solid: true,
            });

            me.$emit("afterLoadData");
        },

        onSalvaSchedaSocialePaziente() {
            let me = this;

            if (!me.jsonDataSchedaSocialePaziente.idAccettazione) {
                // console.log("idAccettazione: ", me.jsonDataSchedaSocialePaziente.idAccettazione);
                me.$bvToast.toast("Errore: ID Accettazione mancante", {
                    title: "Errore",
                    variant: "danger",
                    solid: true,
                });
                return;
            }

            let listaSchedaSocialeDettagli = [];
            me.selectedAusili.forEach((voce) => {
                listaSchedaSocialeDettagli.push({
                    id: null,
                    idSchedaSocialePaziente: me.jsonDataSchedaSocialePaziente.id,
                    tipo: "AUSILIO",
                    voce: voce,
                });
            });

            me.selectedAssistenze.forEach((voce) => {
                listaSchedaSocialeDettagli.push({
                    id: null,
                    idSchedaSocialePaziente: me.jsonDataSchedaSocialePaziente.id,
                    tipo: "ASSISTENZA",
                    voce: voce,
                });
            });
            me.jsonDataSchedaSocialePaziente.listaSchedaSocialeDettagli = listaSchedaSocialeDettagli;

            UtilityMixin.methods.saveResource(me.pathResourceSchedaSocialePaziente, me.jsonDataSchedaSocialePaziente, me.saveSchedaSocialePazienteSuccessResponse, me.saveSchedaSocialePazienteErrorResponse);
        },
        saveSchedaSocialePazienteSuccessResponse() {
            let me = this;
            me.$emit("afterSaveSuccess");
            me.$refs.mdlSchedaSocialePaziente.hide();
            // console.log("idAccettazione: ", me.jsonDataSchedaSocialePaziente.idAccettazione);
            me.$bvToast.toast("Salvataggio avvenuto con successo", {
                title: "Successo",
                variant: "success",
                solid: true,
            });
        },
        saveSchedaSocialePazienteErrorResponse(response) {
            let me = this;
            if (response.response.status === 400) {
                me.$bvModal.msgBoxOk(response.response.data.messaggio, {
                    title: "ATTEZIONE",
                    size: "md",
                    okVariant: "outline-secondary",
                    headerClass: "sa-msg-header-danger",
                    footerClass: "p-2 border-top-0",
                    centered: true,
                });
            } else {
                me.$bvToast.toast("Errore nel salvataggio in edit component" + response.message, {
                    title: "Errore",
                    variant: "danger",
                    solid: true,
                });
            }
        },
        onCloseModal() {
            let me = this;
            me.resetForm();
            me.$emit("onCloseModal");
        },
    },
};
</script>
