<template>
    <div style="height: 100%">
        <div class="sa-flexbox" style="padding-bottom: 20px">
            <div style="margin-right: 20px; margin-right: 20px">
                <span class="sa-flexbox-body-title">Anagrafica</span>
                <b-button size="sm" variant="outline-success outline-primary-noborder sa-margin-right float-sm-right" @click="onClickNuovaAnagrafica" class="mb-2">
                    <font-awesome-icon icon="plus"> </font-awesome-icon>
                    Aggiungi
                </b-button>
            </div>
            <div class="sa-flexbox-body">
                <div class="b-table-sticky-header">
                    <div class="d-flex flex-column">
                        <b-row class="sa-label-info" style="margin-top: 20px">
                            <b-col xs="3" sm="3" md="3" lg="3" xl="3">
                                <p>Record Totali: {{ rows }}</p>
                            </b-col>
                            <b-col xs="9" sm="9" md="9" lg="9" xl="9">
                                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                            </b-col>
                        </b-row>
                    </div>

                    <b-table sticky-header ref="table" stacked="lg" striped hover :items="filterItems" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light">
                        <template #cell(index)="row">
                            {{ row.index + 1 + (currentPage - 1) * perPage }}
                        </template>
                        <!-- <template v-slot:cell(codice)="{ item }">
                            <router-link class="sa-view-link" :to="'/numeratori/view/' + item.id">
                                {{ item.codice }}
                            </router-link>
                        </template> -->
                        <template v-slot:cell(attivo)="{ item }">
                            <span v-if="item.attivo" class="sa-data">SI</span>
                            <span v-else class="sa-data">NO</span>
                        </template>
                        <template v-slot:cell(actions)="{ item }">
                            <b-button v-if="item.attivo" size="sm" variant="outline-success" v-b-tooltip.hover title="Modifica Anagrafica" @click="onClickEdit(item)">
                                <font-awesome-icon icon="edit"> </font-awesome-icon>
                            </b-button>
                            <b-button v-if="item.attivo" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" v-b-tooltip.hover title="Elimina Anagrafica" style="margin-right: 4px" @click="onDeleteRiga(item)">
                                <b-icon icon="trash"></b-icon>
                            </b-button>
                            <b-button size="sm" v-b-tooltip.hover title="Visualizza Anagrafica" variant="outline-secondary no-text" @click="onClickView(item)" class="mr-1 no-text">
                                <font-awesome-icon icon="eye" />
                            </b-button>
                        </template>
                    </b-table>
                </div>
            </div>
            <div class="sa-list-component-footer">
                <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col lg="3">
                        <p>Record Totali:{{ rows }}</p>
                    </b-col>
                    <b-col lg="9">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                    </b-col>
                </b-row>
            </div>
        </div>
        <papercleand-anagrafica-edit-modal-component ref="PapercleandAnagraficaEditModalComponent" @afterSaveSuccess="onAfterSaveSuccess" @beforLoadData="onBeforeLoadData" @afterLoadData="onAfterLoadData"></papercleand-anagrafica-edit-modal-component>
        <papercleand-anagrafica-view-modal-component ref="PapercleandAnagraficaViewModalComponent" @beforLoadData="onBeforeLoadData" @afterLoadData="onAfterLoadData"></papercleand-anagrafica-view-modal-component>
    </div>
</template>
<script>
import UtilityMixin from "../../utility/UtilityMixin";
import PapercleandAnagraficaEditModalComponent from "./PapercleandAnagraficaEditModalComponent.vue";
import PapercleandAnagraficaViewModalComponent from "./PapercleandAnagraficaViewModalComponent.vue";
import axios from "axios";
export default {
    components: { PapercleandAnagraficaEditModalComponent, PapercleandAnagraficaViewModalComponent },
    /* computed: {
        rows() {
            return this.items.length;
        },
    }, */
    data() {
        return {
            rows: 0,
            perPage: 50,
            currentPage: 1,
            pathResource: "/anagrafica",
            linkedit: "/editnumeratori",
            showModalLoading: false,
            items: [],
            filterItems: [],
            filtro: {},
            jsonDataNuovoContatore: {},
            fields: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Tipo",
                    key: "descrizioneTipo",
                    // thStyle: "width: 5rem",
                    sortable: true,
                },
                {
                    label: "Nominativo",
                    key: "nominativo",
                    // thStyle: "width: 5rem",
                    sortable: true,
                },
                {
                    label: "Codice Fiscale",
                    key: "codiceFiscale",
                    // thStyle: "width: 5rem",
                    sortable: true,
                },
                {
                    label: "P. Iva",
                    key: "partitaIva",
                    // thStyle: "width: 5rem",
                    sortable: true,
                },
                {
                    label: "Città",
                    key: "citta",
                    // thStyle: "width: 5rem",
                    sortable: true,
                },
                {
                    label: "Indirizzo",
                    key: "indirizzo",
                    // thStyle: "width: 5rem",
                    sortable: true,
                },
                {
                    label: "Attivo",
                    key: "attivo",
                    thStyle: "width: 7rem",
                    sortable: true,
                },
                { class: "sa-table-column-action-double", key: "actions", label: "", thStyle: "width: 5rem" },
            ],
        };
    },
    watch: {
        currentPage() {
            let me = this;
            me.loadData();
        },
    },
    mounted() {},
    methods: {
        loadData(filtro = {}) {
            let me = this;

            filtro.page = me.currentPage;
            filtro.forPage = me.perPage;

            UtilityMixin.methods.loadDataResources(me.pathResource, filtro, me.loadDataSuccess, me.loadDataError);
        },
        onDeleteRiga(row) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + row.id;
            me.$emit("beforLoadData");
            axios
                .delete(link)
                .then((response) => {
                    this.$bvModal.msgBoxOk(response.data.messaggio, {
                        title: "",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    });
                    me.loadData(me.filtro);
                })
                .catch((e) => {
                    this.$bvModal.msgBoxOk(e.response.data.messaggio, {
                        title: "ATTENZIONE",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    });
                });
            me.$emit("afterLoadData");
        },
        onAfterLoadData() {
            let me = this;
            me.$emit("afterLoadData");
        },
        onBeforeLoadData() {
            let me = this;
            me.$emit("beforLoadData");
        },
        loadDataSuccess(response) {
            let me = this;
            me.filterItems = response.data.data.list;
            me.rows = response.data.data.recordsNumber;
            me.$emit("afterLoadData");
        },
        loadDataError(response) {
            console.log(response);
        },

        onClickView(item) {
            let me = this;
            me.$emit("beforLoadData");
            me.$refs.PapercleandAnagraficaViewModalComponent.openModal(item);
            me.$emit("afterLoadData");
        },
        onClickNuovaAnagrafica() {
            let me = this;
            me.$emit("beforLoadData");
            me.$refs.PapercleandAnagraficaEditModalComponent.openModal(null);
            me.$emit("afterLoadData");
        },
        onClickEdit(item) {
            let me = this;
            me.$emit("beforLoadData");
            me.$refs.PapercleandAnagraficaEditModalComponent.openModal(item);
            me.$emit("afterLoadData");
        },
        onRefresh() {
            this.loadData(this.filtro);
        },
        onAfterSaveSuccess() {
            let me = this;
            me.$emit("beforLoadData");
            me.loadData(me.filtro);
        },
    },
};
</script>
