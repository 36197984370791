<template>
    <b-modal scrollable b-modal title="Equipe" ref="mdlEquipeView" id="mdlEquipeView" size="xl" centered no-close-on-backdrop no-close-on-esc hide-header-close ok-only>
        <div class="sa-tab-scroll">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="sa-switch-select-element">
                    <b-card class="sa-card sa-card-border" header="Membri equipe" header-tag="header" footer-tag="footer" title="">
                        <b-table sticky-header ref="table" stacked="lg" striped hover :items="listaEquipe" :fields="fieldsEquipe" :current-page="1" :per-page="50" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
                            <template #cell(profilo)="item">
                                <span> {{ item.item.tipo }} </span>
                            </template>
                            <template #cell(nominativo)="item">
                                <span> {{ item.item.nome + " " + item.item.cognome }} </span>
                            </template>
                        </b-table>
                    </b-card>
                </b-col>
            </b-row>
        </div>
    </b-modal>
</template>
<script>
export default {
    props: {},
    components: {
        /* DatePicker */
    },
    data() {
        return {
            listKey: 0,
            pathResource: "/rsaequipe",
            listaEquipe: [],
            fieldsEquipe: [
                { label: "Profilo", key: "profilo", thStyle: "width: 7rem" },
                { label: "Nominativo", key: "nominativo", thStyle: "width: 7rem" },
                { class: "sa-table-column-action-double", label: "", key: "actions" },
                /*   { label: "Nome", key: "nome" },
                { label: "Cognome", key: "cognome" }, */
            ],
        };
    },
    mounted() {},
    //   computed: {},
    watch: {},
    methods: {
        show(items) {
            let me = this;
            me.listaEquipe = [];
            me.listaEquipe = items;
            me.$refs.mdlEquipeView.show();
        },
    },
};
</script>
