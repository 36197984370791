<template>
    <b-modal ref="mdlEditSpeseExtra" id="mdlEditSpeseExtra" :title="isNew ? 'Nuova Spesa Extra' : 'Aggiorna Spesa Extra'" size="lg" @hidden="onCloseModal" @ok="onClickOk" centered no-close-on-backdrop no-close-on-esc hide-header-close>
        <b-row>
            <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <span class="sa-form-label">Descrizione</span>
                <b-form-input v-model="jsonDataSpeseExtra.descrizione"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <span class="sa-form-label">Importo</span>
                <b-form-input v-model="jsonDataSpeseExtra.importo"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <span class="sa-form-label">Natura Economica</span>
                <!-- <b-form-input v-model="jsonDataSpeseExtra.naturaEconomica"></b-form-input> -->
                <b-form-select v-model="jsonDataSpeseExtra.naturaEconomica" :options="natureEconomicheOptions" :value="''" value-field="codice" text-field="descrizione"> </b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <span class="sa-form-label">IVA</span>
                <b-form-select v-model="jsonDataSpeseExtra.codiceIva" :options="ivaOptions" value-field="codiceIva" text-field="descrizioneIva" @input="onInputIva"> </b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                <b-form-checkbox v-model="jsonDataSpeseExtra.spesaRicorsiva" name="check-button" switch> Spesa Ricorsiva </b-form-checkbox>
            </b-col>
            <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                <b-form-checkbox v-model="jsonDataSpeseExtra.importoLibero" name="check-button" switch> Importo Libero </b-form-checkbox>
            </b-col>
            <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                <b-form-checkbox v-model="jsonDataSpeseExtra.includiFattura" name="check-button" switch> Includi in Fattura </b-form-checkbox>
            </b-col>
            <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                <b-form-checkbox v-model="jsonDataSpeseExtra.nonAggregare" name="check-button" switch> Non Aggregare </b-form-checkbox>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <span class="sa-form-label">Note</span>
                <b-form-textarea v-model="jsonDataSpeseExtra.note" rows="3" max-rows="7"> </b-form-textarea>
            </b-col>
        </b-row>
    </b-modal>
</template>

<script>
import UtilityMixin from "../../../utility/UtilityMixin";
import axios from "axios";

export default {
    mixins: [UtilityMixin],

    data() {
        return {
            isNew: true,
            pathResource: "/rsaspeseextra",
            pathResourceNatureEconomiche: "/rsaspeseextranaturaeconomica",
            pathResourceTabellaIVA: "/tabellaiva",

            natureEconomicheOptions: [{ codice: "", descrizione: "-Seleziona Valore-" }],
            ivaOptions: [{ codiceIva: "", descrizioneIva: "-Seleziona Valore-" }],
            jsonDataSpeseExtra: {
                descrizione: null,
                naturaEconomica: "",
                codiceIva: null,
                descrizioneIva: null,
                spesaRicorsiva: false,
                importoLibero: false,
                includiFattura: false,
                nonAggregare: false,
                note: null,
            },
        };
    },
    mounted() {
        let me = this;
        me.loadTabellaIva();
    },
    methods: {
        aggiornaSpesaExtra(item) {
            let me = this;
            me.isNew = false;
            me.jsonDataSpeseExtra = { ...item };
            me.loadNatureEconomiche();
            me.$refs.mdlEditSpeseExtra.show();
        },
        nuovaSpesaExtra() {
            let me = this;
            me.isNew = true;
            me.jsonDataSpeseExtra = {
                descrizione: null,
                naturaEconomica: "",
                codiceIva: null,
                spesaRicorsiva: false,
                importoLibero: false,
                includiFattura: false,
                nonAggregare: false,
                note: null,
            };
            me.loadNatureEconomiche();
            me.$refs.mdlEditSpeseExtra.show();
        },
        loadNatureEconomiche() {
            let me = this;
            // me.UtilityMixin.methods.
            UtilityMixin.methods.loadDataResources(me.pathResourceNatureEconomiche, null, me.loadNatureEconomicheSuccess, me.loadNatureEconomicheError);
        },
        loadNatureEconomicheSuccess(response) {
            let me = this;
            me.natureEconomicheOptions = response.data.data.list;
            me.natureEconomicheOptions.unshift({ codice: "", descrizione: "-Seleziona Valore-" });
            // me.rows = response.data.data.length;
            // this.showModalLoading = true;
        },
        loadNatureEconomicheError(response) {
            console.log(response);
        },
        loadTabellaIva() {
            let me = this;
            UtilityMixin.methods.loadDataResources(me.pathResourceTabellaIVA, null, me.loadTabellaIvaSuccess, me.loadTabellaIvaError);
        },
        loadTabellaIvaSuccess(response) {
            let me = this;
            me.ivaOptions = response.data.data;
            me.ivaOptions.unshift({ codiceIva: null, descrizioneIva: "-Selezionare Aliquota IVA-" });
        },
        loadTabellaIvaError(response) {
            console.log(response);
        },

        onCloseModal() {
            let me = this;
            me.jsonDataSpeseExtra = {};
            me.$emit("onClose");
        },

        onClickOk(bvModalEvent) {
            bvModalEvent.preventDefault();
            UtilityMixin.methods.saveResource(this.pathResource, this.jsonDataSpeseExtra, this.saveSuccessResponse, this.saveErrorResponse);
        },

        saveSuccessResponse(response) {
            let me = this;
            me.$emit("onSave", response);
            me.$refs.mdlEditSpeseExtra.hide();
            me.$bvToast.toast("Salvataggio avvenuto con successo", {
                title: "Successo",
                variant: "success",
                solid: true,
            });
        },

        saveErrorResponse(response) {
            if (response.response.status === 400) {
                this.$bvModal.msgBoxOk(response.response.data.messaggio, {
                    title: "ATTENZIONE",
                    size: "sm",
                    okVariant: "outline-danger",
                    headerClass: "sa-msg-header-danger",
                    footerClass: "p-2 border-top-0",
                    centered: true,
                });
            } else {
                this.$bvToast.toast("Errore nel salvataggio" + response.message, {
                    title: "Errore",
                    variant: "danger",
                    solid: true,
                });
            }
        },

        onDeleteSpesaExtra(item) {
            let me = this;
            this.$bvModal
                .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
                    title: "Eliminazione Spesa Extra",
                    buttonSize: "sm",
                    okVariant: "secondary",
                    cancelVariant: "danger",
                    okTitle: "SI",
                    cancelTitle: "NO",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                })
                .then((value) => {
                    if (value) {
                        me.deleteSpesaExtra(item.id);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        deleteSpesaExtra(id) {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + id;
            axios
                .delete(link)
                .then((response) => {
                    me.showModalLoading = false;
                    me.$emit("onDelete", response);
                    me.$bvToast.toast("Eliminazione avvenuta con successo", {
                        title: "Successo",
                        variant: "success",
                        solid: true,
                    });
                })
                .catch((e) => {
                    console.log(e);
                    me.showModalLoading = false;
                    me.$bvToast.toast("Errore durante l'eliminazione", {
                        title: "Errore",
                        variant: "danger",
                        solid: true,
                    });
                });
        },
        onInputIva(value) {
            console.log(value);
            let me = this;
            me.ivaOptions.forEach((element) => {
                if (element.codiceIva === value) {
                    this.jsonDataSpeseExtra.aliquotaIva = element.aliquotaIva;
                    this.jsonDataSpeseExtra.descrizioneIva = element.descrizioneIva;
                    console.log(value);

                    console.log(element.codiceIva);
                }
            });
        },
    },
};
</script>
