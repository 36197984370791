<template>
    <div>
        <div class="sa-list-component-header">
            <b-row>
                <b-col class="sa-padding-right text-right">
                    <b-button variant="outline-info" @click="onTblRefresh">
                        <b-icon icon="arrow-clockwise" />
                        Aggiorna
                    </b-button>
                </b-col>
            </b-row>
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </div>
        <div class="sa-list-component-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" stacked="lg" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
                    <template #cell(index)="row">
                        {{ row.index + 1 }}
                    </template>
                    <template #cell(urlDocumento)="item">
                        <a v-bind:href="item.item.urlDocumento"> Scarica </a>
                    </template>
                    <template #cell(urlDocumentoTimbrato)="item">
                        <a v-bind:href="item.item.urlDocumentoTimbrato"> Scarica </a>
                    </template>
                    <template #cell(dataProtocollo)="item">
                        {{ formatDateTime(item.item.dataProtocollo) }}
                    </template>
                    <template #cell(actions)="row">
                        <b-button v-if="!disableAssagnazioniDestinatari" size="sm" variant="btn btn-outline-secondary waves-effect waves-light width-sm no-text" @click="onClickInfo(row.item)">
                            <i class="fas fa-info"></i>
                        </b-button>
                    </template>
                </b-table>
            </div>
        </div>
        <div class="sa-list-component-footer">
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </div>
        <b-modal b-modal ref="mdlDestinatari" id="mdlDestinatari" size="xl" style="height: 100%" title="Lista Destinatari/Assegnazioni">
            <b-tabs content-class="mt-3" ref="tabs" class="sa-tab" style="height: 100%; padding-bottom: 25px" fill>
                <b-tab lazy :title="'Assegnazioni'" active>
                    <b-table sticky-header ref="tblAssegnazioni" stacked="xl" striped hover itemscope :items="jsonData.listaAssegnazioni" :fields="fieldsAssegnazioni" :current-page="1" :per-page="10000000" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="jsonData">
                            {{ jsonData.index + 1 }}
                        </template>
                    </b-table>
                </b-tab>
                <b-tab lazy :title="'Destinatari'">
                    <b-table sticky-header ref="tblDestinatari" stacked="xl" striped hover itemscope :items="jsonData.listaDestinatari" :fields="fieldsDestinatari" :current-page="1" :per-page="100000" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="jsonData">
                            {{ jsonData.index + 1 }}
                        </template>
                    </b-table>
                </b-tab>
            </b-tabs>
        </b-modal>
    </div>
</template>

<script>
import axios from "axios";
import UtilityMixin from "../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixin],
    props: {
        idDocumento: {
            type: String,
        },
        documento: {
            type: String,
        },
    },
    data() {
        return {
            rows: 0,
            rowsAssegnazioni: 0,
            rowsDestinatari: 0,
            perPage: 10,
            currentPage: 1,
            currentPageAssegnazione: 1,
            currentPageDestinatari: 1,
            id: null,
            disableAssagnazioniDestinatari: false,
            pathResource: "/protocolresponse",
            pathResourceDestinatariAssegnazioni: "/protocolgedocdestinatariassegnazioni",
            pathResourceAssegnazioni: "/protocolgedocassegnazioni",
            pathResourceDestinatari: "/protocolgedocdestinazioni",
            filtro: { idDocumento: "" },
            listaDestinatari: {},
            listaAssegnazioni: {},
            jsonData: {},
            items: [],
            listKey: 0,
            fields: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Esito Invocazione",
                    key: "statoAttuale",
                    sortable: true,
                },

                {
                    label: "Cod Protocollo",
                    key: "idProtocollo",
                    sortable: true,
                },
                {
                    label: "Numero Protocollo",
                    key: "numeroProtocollo",
                    sortable: true,
                },
                {
                    label: "Documento Inviato",
                    key: "urlDocumento",
                    sortable: true,
                },
                {
                    label: "Documento Protocollato",
                    key: "urlDocumentoTimbrato",
                    sortable: true,
                },
                {
                    label: "Data Ora",
                    key: "dataProtocollo",
                    sortable: true,
                    thStyle: "width: 10rem",
                },
                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 4rem",
                    tdClass: "text-center",
                },
            ],
            fieldsAssegnazioni: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Ufficio",
                    key: "descrizioneUfficio",
                    thStyle: "width: 25rem",
                    sortable: false,
                },
                {
                    label: "Username",
                    key: "username",
                    thStyle: "width: 25rem",
                    sortable: false,
                },
                {
                    label: "Oggettto",
                    key: "oggetto",
                    sortable: false,
                },
            ],
            fieldsDestinatari: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Tipo Persona",
                    key: "tipoPersona",
                    thStyle: "width: 25rem",
                    sortable: false,
                },
                {
                    label: "Nominativo",
                    key: "destinatario",
                    thStyle: "width: 25rem",
                    sortable: false,
                },

                {
                    label: "E-Mail",
                    key: "destinatarioMail",
                    sortable: false,
                },
            ],
        };
    },
    watch: {
        currentPage() {
            let me = this;
            me.loadData();
        },
        currentPageAssegnazione() {
            let me = this;
            me.loadAssegnazioni();
        },
        currentPageDestinatari() {
            let me = this;
            me.loadDestinatari();
        },
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.disableAssagnazioniDestinatari = me.documento === "SEGNALAZIONE" || me.documento === "HIV" ? true : false;
        // me.loadAssegnazioni();
        // me.loadDestinatari();
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.filtro.page = me.currentPage;
            me.filtro.forPage = me.perPage;
            me.filtro.idDocumento = me.id;
            me.filtro.documento = me.documento;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];
                    me.items = response.data.data.list;
                    me.rows = response.data.data.recordsNumber;
                    me.listKey++;
                    //me.changeGestore();
                    // this.$emit("afterLoadData");
                })
                .catch(() => {
                    // this.$emit("afterLoadData");
                });
        },
        onTblRefresh() {
            let me = this;
            me.loadData();
        },
        onClickInfo(value) {
            let me = this;
            me.loadDestinatariAssegnazioni(value.id);
            me.$refs["mdlDestinatari"].show();
        },
        loadDestinatariAssegnazioni(id) {
            let me = this;
            me.jsonData = {};
            let link = process.env.VUE_APP_PATH_API + me.pathResourceDestinatariAssegnazioni + "/" + id;

            UtilityMixin.methods.restCallGet(link, null, me.loadDestinatariAssegnazioniResponse, me.loadDestinatariAssegnazioniResponseError);
        },
        loadDestinatariAssegnazioniResponse(response) {
            let me = this;
            me.jsonData = response.data.data;
        },
        loadDestinatariAssegnazioniResponseError(error) {
            let me = this;
            console.log(error);
            me.jsonData.idDocumento = me.idIndagine;
        },
    },
};
</script>

<style></style>
