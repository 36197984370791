<template>
  <sa-page-layout :showModalLoading="showModalLoading" :linkedit="linkedit" :pathResource="pathResource" :btnBackVisible="true" :btnEditVisible="true" :linkback="linkback" :data="prenotazione">
    <template slot="toolbar-title">Prenotazione </template>
    <template slot="table-body">
         <div class="sa-div-scroll sa-scroll">
      <b-card> </b-card>
         </div>
    </template>
  </sa-page-layout>
</template>

<script>

import SaPageLayout from "../../../template/layout/components/SaPageLayout.vue";
export default {
  components: { SaPageLayout},
  props: {
    utente: {
      type: Object,
      default: function () {
        return { idUtente: null };
      },
    },
  },
  mounted() {
    let me = this;
    me.linkedit = this.linkedit + "/edit/" + me.id;
  },
  data() {
    return {
      id: "-1",
      showModalLoading: false,
      linkback: "/altreprenotazioni",
      linkedit: "/altreprenotazioni",
      pathResource: "",
      prenotazione: {},
    };
  },
};
</script>

<style></style>
