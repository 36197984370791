import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import {
    BootstrapVue,
    IconsPlugin
} from "bootstrap-vue";
import VueSaTemplate from "./template/";
import i18n from "./i18n";
import {
    FontAwesomeIcon
} from "@fortawesome/vue-fontawesome";
// Import Bootstrap an BootstrapVue CSS files (order is important)
// import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "@/assets/css/sa-bootstrap.css";
import "@/assets/css/style.css";
import 'vue2-datepicker/index.css';
import "@/assets/css/sa-layout.css";
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(VueSaTemplate);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.config.productionTip = false;
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
new Vue({
    i18n,
    router,
    render: (h) => h(App),
}).$mount("#app");