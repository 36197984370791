<template>
    <div class="sa-tab-scroll">
        <b-row>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card" header="Dati Evento" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.events.lblEventDate") }}</label>
                            <span class="sa-data"> {{ formatDate(jsonData.dataEvento) }}</span>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card class="sa-card" header="Anamnesi Fisiologica" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Alcool:</label>
                            <span class="sa-data"> {{ jsonData.anamnesiFisiologicaAlcool }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Fumo:</label>
                            <span class="sa-data"> {{ jsonData.anamnesiFisiologicaFumo }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Sostanze Psicoattive:</label>
                            <span class="sa-data"> {{ jsonData.anamnesiFisiologicaSostanzePsicoattive }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Sonno:</label>
                            <span class="sa-data"> {{ jsonData.anamnesiFisiologicaSonno }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Alvo:</label>
                            <span class="sa-data"> {{ jsonData.anamnesiFisiologicaAlvo }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Diuresi:</label>
                            <span class="sa-data"> {{ jsonData.anamnesiFisiologicaDiuresi }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Gravidanze Passate:</label>
                            <span class="sa-data"> {{ jsonData.anamnesiFisiologicaGravidanzePassate }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Altro:</label>
                            <span class="sa-data"> {{ jsonData.anamnesiFisiologicaAltro }}</span>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card class="sa-card" header="Anamnesi Patologica Remota" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Malattie Precedenti:</label>
                            <span class="sa-data"> {{ jsonData.anamnesiPatologicaRemotaMalattiePrecedenti }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="8" lg="8" xl="8">
                            <label class="sa-label-data">Descrizione:</label>
                            <span class="sa-data"> {{ jsonData.anamnesiPatologicaRemotaMalattiePrecedentiDettaglio }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Traumi Precedenti:</label>
                            <span class="sa-data"> {{ jsonData.anamnesiPatologicaRemotaTrasumiPrecedenti }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="8" lg="8" xl="8">
                            <label class="sa-label-data">Descrizione:</label>
                            <span class="sa-data"> {{ jsonData.anamnesiPatologicaRemotaTrasumiPrecedentiDettaglio }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Allergie:</label>
                            <span class="sa-data"> {{ jsonData.anamnesiPatologicaAllergie }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="8" lg="8" xl="8">
                            <label class="sa-label-data">Descrizione:</label>
                            <span class="sa-data"> {{ jsonData.anamnesiPatologicaAllergieDettaglio }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Psiche:</label>
                            <span class="sa-data"> {{ jsonData.anamnesiPatologicaPsiche }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="8" lg="8" xl="8">
                            <label class="sa-label-data">Descrizione:</label>
                            <span class="sa-data"> {{ jsonData.anamnesiPatologicaPsicheDettaglio }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">TBC:</label>
                            <span class="sa-data"> {{ jsonData.anamnesiPatologicaTbc }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="8" lg="8" xl="8">
                            <label class="sa-label-data">Descrizione:</label>
                            <span class="sa-data"> {{ jsonData.anamnesiPatologicaTbcDettaglio }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Apparato Urogenitale:</label>
                            <span class="sa-data"> {{ jsonData.anamnesiPatologicaApparatoUrogenitaleAllergie }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="8" lg="8" xl="8">
                            <label class="sa-label-data">Descrizione:</label>
                            <span class="sa-data"> {{ jsonData.anamnesiPatologicaApparatoUrogenitaleDettaglio }}</span>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card class="sa-card" header="Anamnesi Patologica Prossima" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Descrizione:</label>
                            <span class="sa-data"> {{ jsonData.anamnesiPatologicaProssima }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Apparati Coinvolti:</label>
                            <span class="sa-data"> {{ jsonData.organiApparatiCoinvolti }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Cute:</label>
                            <span class="sa-data"> {{ jsonData.organiApparatiCoinvoltiCute }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Occhi:</label>
                            <span class="sa-data"> {{ jsonData.organiApparatiCoinvoltiOcchi }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Orecchi:</label>
                            <span class="sa-data"> {{ jsonData.organiApparatiCoinvoltiOrecchi }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Dentatura:</label>
                            <span class="sa-data"> {{ jsonData.organiApparatiCoinvoltiDentatura }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Gola Naso/Gola:</label>
                            <span class="sa-data"> {{ jsonData.organiApparatiCoinvoltiGolaNaso }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Bronchi e Polmoni:</label>
                            <span class="sa-data"> {{ jsonData.organiApparatiCoinvoltiBronchiPolmoni }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Apparato Cardiovascolare:</label>
                            <span class="sa-data"> {{ jsonData.organiApparatiCoinvoltiApparatoCardiovascolare }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Stomaco:</label>
                            <span class="sa-data"> {{ jsonData.organiApparatiCoinvoltiStomaco }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Apparato Digerente:</label>
                            <span class="sa-data"> {{ jsonData.organiApparatiCoinvoltiIntestino }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Fegato:</label>
                            <span class="sa-data"> {{ jsonData.organiApparatiCoinvoltiFegato }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Apparato Muscoloscheletrico:</label>
                            <span class="sa-data"> {{ jsonData.organiApparatiCoinvoltiApparatoMuscoloscheletrico }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Metabolismo:</label>
                            <span class="sa-data"> {{ jsonData.organiApparatiCoinvoltiMetabolismo }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">SNC (Sistema Nervoso Centrale):</label>
                            <span class="sa-data"> {{ jsonData.organiApparatiCoinvoltiSnc }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">SNP (Sistema Nervoso Periferico):</label>
                            <span class="sa-data"> {{ jsonData.organiApparatiCoinvoltiSnp }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Altro:</label>
                            <span class="sa-data"> {{ jsonData.organiApparatiCoinvoltiAltro }}</span>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card class="sa-card" header="Esame Obiettivo" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Altezza:</label>
                            <span class="sa-data"> {{ jsonData.altezza }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Peso:</label>
                            <span class="sa-data"> {{ jsonData.peso }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Polso:</label>
                            <span class="sa-data"> {{ jsonData.polso }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Pressione Arteriosa:</label>
                            <span class="sa-data"> {{ jsonData.pressioneArteriosa }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">SpO2:</label>
                            <span class="sa-data"> {{ jsonData.spo2 }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Temperatura:</label>
                            <span class="sa-data"> {{ jsonData.temperatira }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Altro:</label>
                            <span class="sa-data"> {{ jsonData.temperatiraDettaglio }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Cute NDP:</label>
                            <span class="sa-data"> {{ jsonData.cuteNdp }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                            <label class="sa-label-data">Cute:</label>
                            <span class="sa-data"> {{ jsonData.cute }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Apparato Muscoloscheletrico NDP:</label>
                            <span class="sa-data"> {{ jsonData.apparatoMuscoloscheletricoNdp }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                            <label class="sa-label-data">Apparato Muscoloscheletrico:</label>
                            <span class="sa-data"> {{ jsonData.apparatoMuscoloscheletrico }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Apparato Linfonodale NDP:</label>
                            <span class="sa-data"> {{ jsonData.apparatoLinfonodaleNdp }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                            <label class="sa-label-data">Apparato Linfonodale:</label>
                            <span class="sa-data"> {{ jsonData.apparatoLinfonodale }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Capo e Collo NDP:</label>
                            <span class="sa-data"> {{ jsonData.capoColloNdp }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                            <label class="sa-label-data">Capo e Collo:</label>
                            <span class="sa-data"> {{ jsonData.capoCollo }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Torace NDP:</label>
                            <span class="sa-data"> {{ jsonData.toraceNdp }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                            <label class="sa-label-data">Torace:</label>
                            <span class="sa-data"> {{ jsonData.torace }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Cuore NDP:</label>
                            <span class="sa-data"> {{ jsonData.cuoreNdp }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                            <label class="sa-label-data">Cuore:</label>
                            <span class="sa-data"> {{ jsonData.cuore }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Addome NDP:</label>
                            <span class="sa-data"> {{ jsonData.addomeNdp }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                            <label class="sa-label-data">Addome:</label>
                            <span class="sa-data"> {{ jsonData.addome }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Arti NDP:</label>
                            <span class="sa-data"> {{ jsonData.artiNdp }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                            <label class="sa-label-data">Arti:</label>
                            <span class="sa-data"> {{ jsonData.arti }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Sistema Nervoso Periferico NDP:</label>
                            <span class="sa-data"> {{ jsonData.snpNdp }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                            <label class="sa-label-data">Sistema Nervoso Periferico:</label>
                            <span class="sa-data"> {{ jsonData.snp }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Sistema Nervoso Centrale NDP:</label>
                            <span class="sa-data"> {{ jsonData.sncNdp }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                            <label class="sa-label-data">Sistema Nervoso Centrale:</label>
                            <span class="sa-data"> {{ jsonData.snc }}</span>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card class="sa-card" header="Diagnosi" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Patologia Dermatologica:</label>
                            <span class="sa-data"> {{ jsonData.diagnosiPatologiaDermatologica }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Patologia Dermatologica da Infestazione:</label>
                            <span class="sa-data"> {{ jsonData.diagnosiPatologiaDermatologicaInfestazione }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Patologia Muscoloscheletrica:</label>
                            <span class="sa-data"> {{ jsonData.diagnosiPatologiaMuscoloscheletrica }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Patologia Oculistica:</label>
                            <span class="sa-data"> {{ jsonData.diagnosiPatologiaOculistica }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Patologia Orl:</label>
                            <span class="sa-data"> {{ jsonData.diagnosiPatologiaOtoiatrica }}</span>
                        </b-col>
                        <!-- <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Patologia Odontoiatrica:</label>
                            <span class="sa-data"> {{ jsonData.diagnosiPatologiaOdontoiatrica }}</span>
                        </b-col> -->
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Orl:</label>
                            <span class="sa-data"> {{ jsonData.diagnosiPatologiaOrl }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Malattie Respiratorie:</label>
                            <span class="sa-data"> {{ jsonData.diagnosiMalattieRespiratorie }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Ipertensione:</label>
                            <span class="sa-data"> {{ jsonData.diagnosiIpertensione }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Cardiopatie:</label>
                            <span class="sa-data"> {{ jsonData.diagnosiCardiopatie }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Altre Malattie Cardiovascolari:</label>
                            <span class="sa-data"> {{ jsonData.diagnosiMalattieCardiovascolariAltre }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Diabete:</label>
                            <span class="sa-data"> {{ jsonData.diagnosiDiabete }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Altre Malattie Metaboliche/Endocrine:</label>
                            <span class="sa-data"> {{ jsonData.diagnosiAltreMalattieMetabolicheEndocrine }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Malattie Gastroduodenali:</label>
                            <span class="sa-data"> {{ jsonData.diagnosiMalattieGastroduodenali }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Malattie Intestinali:</label>
                            <span class="sa-data"> {{ jsonData.diagnosiMalattieIntestinali }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Epatopatie:</label>
                            <span class="sa-data"> {{ jsonData.diagnosiEpatopatie }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Malattie Neurologiche:</label>
                            <span class="sa-data"> {{ jsonData.diagnosiMalattieNeurologiche }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Malattie Psichiatriche:</label>
                            <span class="sa-data"> {{ jsonData.diagnosiMalattiePsichiatriche }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Malattie Urogenitali:</label>
                            <span class="sa-data"> {{ jsonData.diagnosiMalattieUrologiche }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Diagnosi:</label>
                            <span class="sa-data"> {{ jsonData.diagnosi }}</span>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card class="sa-card" header="Terapia" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Medicazioni:</label>
                            <span class="sa-data"> {{ jsonData.terapiaMedicazione }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">FANS:</label>
                            <span class="sa-data"> {{ jsonData.terapiaFans }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Antipiretici:</label>
                            <span class="sa-data"> {{ jsonData.terapiaAntipiretici }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Antidolorifici:</label>
                            <span class="sa-data"> {{ jsonData.terapiaAntidolorifici }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Antibiotici:</label>
                            <span class="sa-data"> {{ jsonData.terapiaAntibiotici }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Antimicotici:</label>
                            <span class="sa-data"> {{ jsonData.terapiaAntimicotici }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Cortisonici:</label>
                            <span class="sa-data"> {{ jsonData.terapiaCortisonici }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Antidiabetici:</label>
                            <span class="sa-data"> {{ jsonData.terapiaAntidiabetici }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Antiipertensivi:</label>
                            <span class="sa-data"> {{ jsonData.terapiaAntiipertensivi }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Altri Cardiovascolari:</label>
                            <span class="sa-data"> {{ jsonData.terapiaAltriCardiovascolari }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Antiacidi/PPI/Antireflusso:</label>
                            <span class="sa-data"> {{ jsonData.terapiaAntiacidiPpiAntireflusso }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Antidiarroici:</label>
                            <span class="sa-data"> {{ jsonData.terapiaAntidiarroici }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Antispastici:</label>
                            <span class="sa-data"> {{ jsonData.terapiaAntispastici }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Lassativi:</label>
                            <span class="sa-data"> {{ jsonData.terapiaLassativi }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Fluidificanti/Mucolitici:</label>
                            <span class="sa-data"> {{ jsonData.terapiaFliuidificantiMucolitici }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Broncodilatatori:</label>
                            <span class="sa-data"> {{ jsonData.terapiaBroncodilatatori }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Antidepressivi:</label>
                            <span class="sa-data"> {{ jsonData.terapiaAntidepressivi }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Neurolettici:</label>
                            <span class="sa-data"> {{ jsonData.terapiaNeurolettici }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Antiparassitosi:</label>
                            <span class="sa-data"> {{ jsonData.terapiaAntiparassitosi }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Anti Tbc:</label>
                            <span class="sa-data"> {{ jsonData.terapiaAntiTbc }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Terapia:</label>
                            <span class="sa-data"> {{ jsonData.terapiaAltri }}</span>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card class="sa-card" header="Note" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <span class="sa-data"> {{ jsonData.note }}</span>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import axios from "axios";
import "vue2-datepicker/index.css";
import UtilityMixins from "../../../../utility/UtilityMixin";

export default {
    mixins: [UtilityMixins],
    components: {},
    props: {
        tipoEvento: String,
        gruppo: String,
        idEvento: {
            type: String,
            default: function () {
                return null;
            },
        },
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    data() {
        return {
            id: "-1",
            linkback: null,
            tabIndex: 0,
            childKey: 0,
            pathResource: "/pazienteeventianamnesi",
            currentPage: 1,
            perPage: 30,
            filtro: { page: 1, forPage: 30 },
            showModalLoading: false,
            jsonData: {},
            siNoOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "SI", text: "SI" },
                { value: "NO", text: "NO" },
            ],
            alcoolOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "NO", text: "NO" },
                { value: "MODERATO", text: "MODERATO" },
                { value: "ECCESSIVO", text: "ECCESSIVO" },
                { value: "QUANTITA'/DIE", text: "QUANTITA'/DIE" },
            ],
            fumoOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "NO", text: "NO" },
                { value: "SIG/DIE", text: "SIG/DIE" },
                { value: "ALTRO", text: "ALTRO" },
            ],
            sostanzeOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "NO", text: "NO" },
                { value: "SI", text: "SI" },
                { value: "SALTUATARIE", text: "SALTUATARIE" },
            ],
            sonnoOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "REGOLARE", text: "REGOLARE" },
                { value: "INSONNIA SALTUARIA", text: "INSONNIA SALTUARIA" },
                { value: "INSONNIA ABITUALE", text: "INSONNIA ABITUALE" },
                { value: "INCUBI", text: "INCUBI" },
            ],
            alvoOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "REG.", text: "REG." },
                { value: "STIPSI", text: "STIPSI" },
                { value: "DIARREA", text: "DIARREA" },
            ],
        };
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    updated() {},
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
    },
    mounted() {
        let me = this;
        if (me.idEvento !== null) {
            me.id = me.idEvento;
        } else {
            me.id = this.$route.params.id;
        }
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
            axios
                .get(link)
                .then((response) => {
                    // this.$emit("update", response.data.data);
                    me.jsonData = response.data.data;
                    me.showModalLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
};
</script>

<style>
.sa-visita-controllo-ecg-row-custom .row div {
    padding-bottom: 3px;
}

.sa-risk-alert-danger,
.sa-risk-alert-success,
.sa-risk-alert-warning {
    height: 50px;
    width: 50px;
    border-radius: 25px;
    display: block;
    align-content: center;
    font-size: 1.6rem !important;
    text-align: center;
    padding-top: 8px;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
}

.sa-risk-alert-danger {
    /* border: 2px solid red; */
    background-color: red;
    color: white;
}

.sa-risk-alert-success {
    /* border: 2px solid #28a745; */
    background-color: #28a745;
    color: white;
}

.sa-risk-alert-warning {
    /* border: 2px solid #ffc107; */
    background-color: #ffc107;
    color: white;
}
</style>
