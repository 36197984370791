import SendMail from "../";

const routes = [
  {
    name: "EmailPage",
    path: "/email",

    component: SendMail.EmailPage,
    meta: { title: "Email" },
  },

  {
    name: "EmailView",
    path: "/email/view/:id",
    component: SendMail.EmailView,
    meta: { title: "Email" },
  },
];
export default routes;
