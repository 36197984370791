<template>
    <sa-page-layout :btnNewVisible="btnNewVisible" :btnRefreshVisible="true" @refresh="onRefresh" :pathResource="pathResource" :linkedit="linkedit" :showModalLoading="showModalLoading">
        <!-- <template slot="table-filter">
            <b-form @submit.prevent="onSubmit">
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <b-form-input v-model="filtro.codice" type="search" placeholder="Codice"> </b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <b-form-input v-model="filtro.descrizione" type="search" placeholder="Descrizione"> </b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <b-form-input v-model="filtro.anno" type="number" placeholder="Anno"> </b-form-input>
                    </b-col>
                </b-row>
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="sa-padding-right text-right">
                        <b-button type="submit" variant="info">{{ this.$i18n.t("global.lblFind") }}</b-button>
                        <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("global.lblResetFilter") }}</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </template> -->

        <template slot="table-body">
            <numeratori-list-component ref="NumeratoriListComponent" @beforeLoadData="onBeforeLoadData" @afterLoadData="onAfterLoadData"> </numeratori-list-component>
        </template>
    </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import NumeratoriListComponent from "../components/NumeratoriListComponent.vue";

export default {
    name: "NumeratoriListPage",
    components: { SaPageLayout, NumeratoriListComponent },
    data() {
        return {
            filtro: {
                codice: null,
                descrizione: null,
                anno: null,
            },
            btnNewVisible: true,
            btnBackVisible: true,
            linkback: "/home",
            pathResource: "/numeratori",
            linkedit: "/numeratori",
            showModalLoading: false,
            listKey: 0,
        };
    },
    mounted() {
        this.loadData();
        // Qui recupero i filtri salvati se esistono
        let sessionStorage = window.sessionStorage;
        if (sessionStorage["filtroNumeratori"]) {
            this.filtro = JSON.parse(sessionStorage["filtroNumeratori"]);
        }
    },
    methods: {
        loadData() {
            this.$refs.NumeratoriListComponent.loadData();
        },

        onRefresh() {
            this.loadData();
        },

        onAfterLoadData() {
            this.showModalLoading = false;
        },

        onBeforeLoadData() {
            this.showModalLoading = true;
        },

        onReset() {
            this.filtro = {
                codice: null,
                descrizione: null,
                anno: null,
            };
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtroNumeratori"] = JSON.stringify(this.filtro);
            this.loadData();
        },

        onSubmit() {
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtroNumeratori"] = JSON.stringify(this.filtro);
            this.loadData();
        },

        beforeDestroy() {
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtroNumeratori"] = JSON.stringify(this.filtro);
        },
    },
    watch: {
        filtro: {
            handler(newVal) {
                this.$refs.NumeratoriListComponent.filtro = newVal;
            },
            deep: true,
        },
    },
};
</script>

<style scoped>
.sa-padding-right {
    padding-right: 1rem;
}

b-button + b-button {
    margin-left: 0.5rem;
}
</style>
