<template>
  <sa-page-layout :btnRefreshVisible="true" @refresh="onRefresh()">
    <template slot="toolbar">
      <b-button
        variant="primary btn-toolbar float-sm-right sa-margin-right"
        size="sm"
        @click="onNuovaPrenotazione(contattiSelezionati)"
        :disabled="contattiSelezionati.length == 0"
        ><b-icon icon="plus"></b-icon>Nuova Prenotazione</b-button
      >
      <b-button
        class="float-sm-right"
        variant="success btn-toolbar sa-margin-right"
        size="sm"
        @click="onNuovoContatto()"
        ><b-icon icon="plus"></b-icon>Nuovo Contatto</b-button
      >
    </template>

    <template slot="toolbar-title">
      <p>Contatti</p>
    </template>
    <template slot="toolbar-row"> </template>
    <template slot="table-filter">
      <b-container fluid>
        <b-form @reset="onReset" v-if="show">
          <b-row>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <label for="">Nome</label>
              <b-form-input
                v-model="filtro.nome"
                type="text"
                placeholder="Nome"
                @input="onFiltraContatti()"
                :formatter="
                  (value) => {
                    return String(value).toUpperCase();
                  }
                "
              ></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <label for="">Cognome</label>
              <b-form-input
                v-model="filtro.cognome"
                placeholder="Cognome"
                @input="onFiltraContatti()"
                :formatter="
                  (value) => {
                    return String(value).toUpperCase();
                  }
                "
              ></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <label for="">Codice Fiscale</label>
              <b-form-input
                v-model="filtro.identificativo"
                placeholder="Codice Fiscale"
                @input="onFiltraContatti()"
                :formatter="
                  (value) => {
                    return String(value).toUpperCase();
                  }
                "
              ></b-form-input>
            </b-col>
            <b-col cols="12" lg="6" xl="6">
              <label for="">Settore</label>
              <b-form-select
                id="input-3"
                v-model="filtro.idSettore"
                :options="settori"
                value-field="id"
                text-field="descrizione"
                @input="onFiltraContatti()"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled>
                    - Seleziona Settore -
                  </b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
          </b-row>
          <br />
          <b-row align="right">
            <b-col>
              <b-button type="reset" variant="outline-danger">Reset</b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-container>
    </template>
    <template slot="table-header">
      <br />
      <b-row>
        <b-col>
          <b-button
            :disabled="this.contatti != null && this.contatti.length <= 1"
            variant="outline-success"
            class="mr-2 mb-4"
            size="sm"
            @click="onSelezionaTutti()"
            >Seleziona Tutti</b-button
          >
          <b-button
            :disabled="this.contatti != null && this.contatti.length <= 1"
            variant="outline-danger"
            class="mr-2 mb-4"
            size="sm"
            @click="onRimuoviSelezionati()"
            >Rimuovi Selezionati</b-button
          >
        </b-col>
      </b-row>
    </template>
    <template slot="table-body">
      <div class="b-table-sticky-header sa-scroll">
        <b-table
          select-mode="multi"
          selectable
          @row-selected="onContattiSelezionati"
          sticky-header
          ref="table"
          :filter="filter"
          stacked="lg"
          striped
          hover
          :items="contatti"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          sort-icon-left
          head-variant="light"
          class="sa-b-table"
        >
          <template #cell(contattiSelezionati)="{ rowSelected }">
            <template v-if="rowSelected">
              <b-icon icon="check2-circle"></b-icon>
            </template>
            <template v-else>
              <span aria-hidden="true">&nbsp;</span>
              <span class="sr-only">Not selected</span>
            </template>
          </template>
          <template #cell(name)="{ item }">
            <router-link class="sa-edit-link" :to="'/contatti/view/' + item.id">
              {{ item.cognome }} {{ item.nome }}
            </router-link>
            <br />
            <small> {{ item.identificativo }} </small>
          </template>

          <!-- <b-form-select
              v-model="item.idSettore"
              :options="settori"
              value-field="id"
              text-field="descrizione"
            >
              <template #default>
                <b-form-select-option>+ Nuova </b-form-select-option>
              </template>
            </b-form-select>
          </template> -->
          <template v-slot:cell(action)="row">
            <!-- <b-button size="sm" variant="outline-success" class="">
              <b-icon icon="plus"></b-icon>
            </b-button> -->
            <b-button
              size="sm"
              variant="outline-danger"
              @click="onDeleteItem(row.item, row.index, $event.target)"
              v-b-modal.visualizzaAllegato
            >
              <b-icon icon="trash"></b-icon>
            </b-button>
          </template>
        </b-table>

        <!-- v-if="this.settori.length != 0" -->
      </div>
    </template>
    <template slot="table-footer"> </template>
  </sa-page-layout>
</template>

<script>
import axios from "axios";
import SaPageLayout from "../../../template/layout/components/SaPageLayout.vue";
import UtilityMixins from "../../utility/UtilityMixin";
export default {
  mixins: [UtilityMixins],
  components: { SaPageLayout },
  mounted() {
    let me = this;

    me.linkedit = "/contatti";
    me.loadData();
    me.loadSettori();
  },
  data() {
    return {
      id: null,
      tabIndex: 0,
      linkedit: "contatti",
      perPage: 100,
      isModificaSettore: false,
      currentPage: 1,
      filter: {},
      descrizioneSettore: "Prova",
      settori: [],
      contatti: [],
      show: true,
      filtro: {
        nome: "",
        cognome: "",
        identificativo: "",
        idSettore: null,
      },
      pathResourcePrenotazioniMultiple: "/lemieprenotazionimultiplo",
      contattiSelezionati: [],
      fields: [
        {
          key: "contattiSelezionati",
          label: "",
          tdClass: "text-left",
          thStyle: "width: 2.5rem",

          sortable: true,
        },
        {
          key: "name",
          label: "Nominativo",
          tdClass: "text-left",
        },
        {
          label: "Data Nascita",
          key: "dataNascita",
          tdClass: "text-left",
          sortable: true,
          formatter: (value) => {
            return this.formatDate(value);
          },
        },

        {
          label: "Cellulare",
          key: "cellulare",
          tdClass: "text-left",
          sortable: false,
        },
        {
          label: "Email",
          key: "email",
          tdClass: "text-left",
          sortable: false,
        },
        {
          label: "Settore",
          key: "idSettore",
          tdClass: "text-left",
          formatter: (value) => {
            return this.getDescrizioneSettore(value);
          },
          sortable: false,
        },
        {
          label: "",
          key: "action",
          tdClass: "text-center",
        },
      ],
    };
  },

  methods: {
    onFiltraContatti() {
      let me = this;
      let linkSettori = process.env.VUE_APP_PATH_API + "/anagraficacontatti";
      axios.get(linkSettori, { params: me.filtro }).then((response) => {
        me.contatti = response.data.data;
      });
    },
    onReset(event) {
      event.preventDefault();

      this.filtro.nome = "";
      this.filtro.cognome = "";
      this.filtro.identificativo = "";
      this.filtro.idSettore = null;
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
      this.loadData();
    },
    onRefresh() {
      let me = this;
      me.loadData();
      me.loadSettori();
    },

    onNuovaPrenotazione(value) {
      let me = this;
      if (value.length != 0)
        sessionStorage.setItem(
          "contattiSelezionati",
          JSON.stringify(this.contattiSelezionati)
        );
      me.$router
        .replace(me.pathResourcePrenotazioniMultiple + "/edit/-1")
        .catch((err) => {
          err;
        });
    },
    onContattiSelezionati(items) {
      this.contattiSelezionati = items;
    },
    onNuovoContatto() {
      let me = this;
      me.$router.replace(me.linkedit + "/edit/-1").catch((err) => {
        err;
      });
    },
    loadData() {
      let me = this;
      me.onFiltraContatti();
      // let link = process.env.VUE_APP_PATH_API + "/anagraficacontatti";
      // axios.get(link).then((response) => {
      //   me.contatti = response.data.data;
      // });
    },
    loadSettori() {
      let me = this;
      let linkSettori = process.env.VUE_APP_PATH_API + "/settori";
      axios.get(linkSettori).then((response) => {
        me.settori = response.data.data;
      });
    },
    getDescrizioneSettore(value) {
      let descrizione = "";
      if (this.settori)
        this.settori.forEach((element) => {
          if (element.id == value) {
            descrizione = element.descrizione;
          }
        });
      return descrizione;
    },
    onRimuoviSelezionati() {
      this.$refs.table.clearSelected();
    },
    onSelezionaTutti() {
      this.$refs.table.selectAllRows();
    },
    onDeleteItem(item) {
      let me = this;
      this.$bvModal
        .msgBoxConfirm("Sei sicuro di voler rimuovere il Contatto?", {
          title: "Conferma Cancellazione Contatto",
          buttonSize: "sm",
          okVariant: "secondary",
          cancelVariant: "danger",
          okTitle: "SI",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            me.deleteItem(item);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteItem(item) {
      let me = this;
      me.showModalLoading = true;
      return console.log(item);
      // let link = process.env.VUE_APP_PATH_API + "/prenotazione/";
      // axios
      //   .delete(link, { data: { id: item.id } })
      //   .then((response) => {
      //     console.log(response);
      //     me.showModalLoading = false;
      //     me.loadData();
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    },
  },

  destroyed() {
    this.contattiSelezionati = null;
  },
};
</script>

<style></style>
