<template>
    <div class="sa-list-component">
        <div class="sa-list-component-header">
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </div>
        <div class="sa-list-component-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" stacked="lg" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
                    <template v-slot:cell(identificativo)="{ item }">
                        <router-link class="sa-edit-link" :to="'/cot/richieste/edit/' + item.id">{{ item.identificativo }}</router-link>
                    </template>
                    <template v-slot:cell(nominativo)="{ item }">{{ item.cognome }} {{ item.nome }}</template>
                    <template v-slot:cell(data)="{ item }">
                        <span v-if="item.statoAttuale !== 'NON INOLTRATA'">{{ formatDate(item.statoAttualeDataOra) }}</span>
                        <span v-else>---</span>
                    </template>
                    <template v-slot:cell(provenienzaNominativoMedico)="{ item }">
                        <span class="sa-label-data-secondary">{{ item.provenienzaDescrizioneStruttura }}</span>
                        <span class="sa-label-data-secondary">{{ item.provenienzaDescrizioneReparto }}</span>
                        <!-- {{ item.provenienzaNominativoMedico }} -->
                        <!-- <span class="sa-label-data-secondary">{{ item.methodAfterSignedError }}</span> -->
                    </template>
                </b-table>
            </div>
        </div>
        <div class="sa-list-component-footer">
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
// import Vue from "vue";
import axios from "axios";
import moment from "moment";
import UtilityMixin from "../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixin],
    watch: {
        currentPage() {
            let me = this;
            me.loadData();
        },
    },
    data() {
        return {
            rows: 0,
            perPage: 50,
            currentPage: 1,
            pathResource: "/cot/listaattesa",
            filtro: {},
            items: [],
            listKey: 0,
            fields: [
                {
                    label: "Codice Fiscale",
                    key: "identificativo",
                    sortable: true,
                },
                {
                    label: "Nominativo",
                    key: "nominativo",
                    sortable: true,
                },
                // {
                //     label: "Data Creazione",
                //     key: "createDate",
                //     thStyle: "width: 8rem",
                //     formatter: (value) => {
                //         if (value) {
                //             return moment(new Date(value)).format("DD/MM/YYYY");
                //         } else {
                //             return "-----";
                //         }
                //     },
                //     sortable: true,
                // },
                {
                    label: "Data Segnalazione",
                    key: "data",
                    thStyle: "width: 20rem",
                    formatter: (value) => {
                        if (value) {
                            return moment(new Date(value)).format("DD/MM/YYYY");
                        } else {
                            return "-----";
                        }
                    },
                    sortable: true,
                },
                {
                    label: "R.S.A. Destinazione",
                    key: "rsaDestinazione",
                    sortable: true,
                },
                {
                    label: "Data Inserimento",
                    key: "statoAttualeDataOra",
                    thStyle: "width: 20rem",
                    formatter: (value) => {
                        if (value) {
                            return moment(new Date(value)).format("DD/MM/YYYY HH:mm:ss");
                        } else {
                            return "-----";
                        }
                    },
                },
            ],
        };
    },
    mounted() {
        let me = this;
        let sessionStorage = window.sessionStorage;
        if (sessionStorage["filtro"]) {
            me.filtro = JSON.parse(sessionStorage["filtro"]);
        }
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;

            let sessionStorage = window.sessionStorage;
            if (sessionStorage["filtro"]) {
                me.filtro = JSON.parse(sessionStorage["filtro"]);
            }
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.filtro.page = me.currentPage;
            me.filtro.forPage = me.perPage;
            //   console.log("ECCOMI");
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];
                    me.rows = response.data.data.recordsNumber;
                    me.items = response.data.data.list;
                    // let gruppo = Vue.prototype.user.gruppo.nome;
                    // if (gruppo === "COT MMG") {
                    //     me.rows = response.data.data.recordsNumber;
                    //     me.items = response.data.data.list;
                    // } else {
                    //     response.data.data.list.forEach((element) => {
                    //         if (gruppo === "COT OPERATORE") {
                    //             if (element.statoAttuale !== "NON INOLTRATA") {
                    //                 me.items.push(element);
                    //             }
                    //         } else if (gruppo === "COT UVM") {
                    //             if (element.statoAttuale === "INVIATA A U.V.M.") {
                    //                 me.items.push(element);
                    //             }
                    //         }
                    //     });
                    // }

                    me.listKey++;
                    this.$emit("afterLoadData");
                })
                .catch(() => {
                    this.$emit("afterLoadData");
                });
        },
    },
};
</script>

<style></style>
