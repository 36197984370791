<template>
    <div style="height: 100%">
        <div class="sa-flexbox" style="padding-bottom: 20px">
            <div class="d-flex flex-column">
                <!-- <div class="text-right mb-3">
                    <b-button size="sm" variant="outline-success" :hidden="btnNuovaPrestazioneHidden" @click="onClickNuovaPrestazioneAmbulatoriale" class="mt-2">
                        <font-awesome-icon icon="plus" size="sm"> </font-awesome-icon>
                        Nuova Prestazione Ambulatoriale
                    </b-button>
                </div> -->
                <b-row class="sa-label-info">
                    <b-col lg="3">
                        <p>Record Totali: {{ rows }}</p>
                    </b-col>
                    <b-col lg="9">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                    </b-col>
                </b-row>
            </div>

            <div class="sa-flexbox-body">
                <div class="b-table-sticky-header">
                    <b-table sticky-header ref="table" stacked="lg" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table sa-card-border">
                        <template #cell(index)="row">
                            <span> {{ row.index + 1 }} </span>
                        </template>

                        <template v-slot:cell(actions)="{ item }">
                            <b-container class="bv-example-row">
                                <b-button size="sm" v-b-tooltip.hover title="Modifica prestazione" variant="outline-success" @click="onClickEdit(item)" class="mr-1 no-text">
                                    <font-awesome-icon icon="edit"> </font-awesome-icon>
                                </b-button>

                                <b-button size="sm" v-b-tooltip.hover title="Cancella Prestazione" variant="outline-danger" @click="onDeletePrestazione(item)">
                                    <font-awesome-icon icon="trash"></font-awesome-icon>
                                </b-button>
                            </b-container>
                        </template>
                    </b-table>
                </div>
            </div>
            <rsa-configurazione-prestazioni-ambulatoriali-edit-component ref="cmpRsaConfigurazionePrestazioniAmbulatorialiEdit" @onSave="onSavePrestazione" @onClose="loadData" @onDelete="onDelete"> </rsa-configurazione-prestazioni-ambulatoriali-edit-component>
            <b-row class="sa-label-info">
                <b-col lg="3">
                    <p>Record Totali: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import UtilityMixin from "../../../utility/UtilityMixin";
import RsaConfigurazionePrestazioniAmbulatorialiEditComponent from "./RsaConfigurazionePrestazioniAmbulatorialiEditComponent.vue";

export default {
    components: { RsaConfigurazionePrestazioniAmbulatorialiEditComponent },
    mixins: [UtilityMixin],
    data() {
        return {
            rows: 0,
            perPage: 50,
            currentPage: 1,
            pathResource: "/rsaprestazioniambulatoriali",
            showModalLoading: false,
            listKey: 0,
            items: [],
            filtroPrestazioniAmbulatoriali: { codicePrestazione: null, codiceOffering: null, descrizione: null },
            btnNuovaPrestazioneHidden: false,
            fields: [
                {
                    label: "",
                    key: "index",
                    thStyle: "width: 0.5rem",
                },
                {
                    label: "Codice Prestazione",
                    key: "codicePrestazione",
                },
                {
                    label: "Codice Offering",
                    key: "codiceOffering",
                },
                {
                    label: "Tipo",
                    key: "tipo",
                },
                {
                    label: "Descrizione",
                    key: "descrizione",
                },
                { class: "sa-table-column-action-double", key: "actions", label: "" },
            ],
        };
    },
    watch: {
        currentPage() {
            let me = this;
            me.loadData();
        },
    },

    mounted() {
        let me = this;
        me.loadData();
    },

    methods: {
        loadData() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            if (sessionStorage["filtroPrestazioniAmbulatoriali"]) {
                me.filtroPrestazioniAmbulatoriali = JSON.parse(sessionStorage["filtroPrestazioniAmbulatoriali"]);
            }
            me.filtroPrestazioniAmbulatoriali.page = me.currentPage;
            me.filtroPrestazioniAmbulatoriali.forPage = me.perPage;

            UtilityMixin.methods.loadDataResources(me.pathResource, me.filtroPrestazioniAmbulatoriali, me.loadDataSuccess, me.loadDataError);
            this.showModalLoading = true;
        },

        loadDataSuccess(response) {
            let me = this;
            me.items = response.data.data.list;
            me.rows = response.data.data.recordsNumber;
            this.showModalLoading = true;
        },
        loadDataError(response) {
            console.log(response);
        },
        onClickEdit(item) {
            let me = this;
            me.$refs.cmpRsaConfigurazionePrestazioniAmbulatorialiEdit.aggiornaPrestazioneAmbulatoriale(item);
        },

        onClickNuovaPrestazioneAmbulatoriale() {
            let me = this;
            me.$refs.cmpRsaConfigurazionePrestazioniAmbulatorialiEdit.nuovaPrestazioneAmbulatoriale();
        },
        onSavePrestazione() {
            this.loadData();
        },
        onDeletePrestazione(item) {
            let me = this;
            me.$refs.cmpRsaConfigurazionePrestazioniAmbulatorialiEdit.onDeletePrestazione(item);
        },
        onDelete() {
            this.loadData();
        },
    },
};
</script>
