<template>
    <div>
        <p @click="onClickBtnRicercaIcdIx">Diagnosi ICD-9</p>

        <b-modal ref="mdlCodiciIcdIx" id="mdlCodiciIcdIx" title="CODICI ICD-IX" size="lg" @ok="onOkMdlCodiciIcdIx" scrollable>
            <codici-icd-ix-list-component @update="getSelectedRow"></codici-icd-ix-list-component>
        </b-modal>
    </div>
</template>

<script>
import CodiciIcdIxListComponent from "../../configurazione/components/CodiciIcdIxListComponent.vue";

export default {
    components: { CodiciIcdIxListComponent },
    data() {
        return {
            selectedElement: [],
        };
    },
    methods: {
        onOkMdlCodiciIcdIx() {
            let me = this;
            this.$emit("onModalClose", me.selectedElement);
        },
        getSelectedRow(item) {
            let me = this;
            me.selectedElement = [];
            me.selectedElement.push(item);
        },
        onClickBtnRicercaIcdIx() {
            this.$refs.mdlCodiciIcdIx.show();
        },
    },
};
</script>

<style></style>
