<template>
    <b-modal ref="mdlEditDataGruppo" :title="title" @hidden="onCloseModal" centered no-close-on-backdrop no-close-on-esc hide-header-close>
        <b-row>
            <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <span class="sa-form-label">Profilo</span>
                <b-form-select :options="profiloOptions" value-field="nome" text-field="nome" v-model="jsonData.profilo">
                    <template #first>
                        <b-form-select-option :value="null" disabled> -Seleziona un profilo- </b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>

            <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <span class="sa-form-label">Dashboard</span>
                <b-form-select :options="dashboardOptions" v-model="jsonData.idDashboard">
                    <template #first>
                        <b-form-select-option :value="null" disabled> -Seleziona una dashboard- </b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>
        </b-row>

        <template #modal-footer>
            <b-button variant="outline-danger" @click="onCloseModal">Annulla</b-button>
            <b-button variant="outline-success" @click="onClickOk()">Salva</b-button>
        </template>
    </b-modal>
</template>

<script>
import UtilityMixin from "../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixin],
    data() {
        return {
            title: null,
            isRequired: false,
            pathResource: "/dataanalysisgruppodashboard",
            pathResourceGruppi: "/gruppi",
            pathResourceDashboard: "/dataanalysisdashboard",
            profiloOptions: [],
            dashboardOptions: [],

            jsonData: {
                id: null,
                profilo: null,
                idDashboard: null,
            },
        };
    },
    methods: {
        show(jsonData) {
            let me = this;
            me.jsonData = jsonData;
            me.title = !jsonData.id ? "Nuovo gruppo" : "Aggiorna gruppo";
            me.loadGruppi();
            me.loadDashboardData();
            me.$refs.mdlEditDataGruppo.show();
        },
        loadGruppi() {
            let me = this;
            UtilityMixin.methods.loadDataResources(me.pathResourceGruppi, null, me.loadGruppiSuccess, me.loadAGruppiError);
            me.$emit("loadAnalysisQuery");
        },

        loadGruppiSuccess(response) {
            let me = this;
            me.profiloOptions = response.data.data;
        },
        loadAGruppiError(error) {
            error;
            this.$bvToast.toast("Errore nel caricamento dei profili", {
                title: "Errore",
                variant: "danger",
                solid: true,
            });
        },

        loadDashboardData() {
            let me = this;
            let dashboardSearchBean = { limit: 0, page: 0 };
            UtilityMixin.methods.loadDataResources(me.pathResourceDashboard, dashboardSearchBean, me.loadDashboardDataSuccess, me.loadDashboardDataError);
        },
        loadDashboardDataSuccess(response) {
            let me = this;
            /* me.dashboardOptions = response.data.data.list; */
            if (response.data && response.data.data && response.data.data.list) {
                me.dashboardOptions = response.data.data.list.map((dashboard) => ({
                    value: dashboard.id,
                    text: dashboard.title || dashboard.id,
                }));
            } else {
                me.dashboardOptions = [];
                console.warn("lista dashboard vuota");
            }
        },

        loadDashboardDataError(error) {
            console.error("Errore durante il caricamento delle dashboard:", error);
            this.$bvToast.toast("Errore nel caricamento delle dashboard", {
                title: "Errore",
                variant: "danger",
                solid: true,
            });
        },

        onCloseModal() {
            let me = this;
            me.jsonData = {
                id: null,
                profilo: null,
                idDashboard: null,
            };
            me.$refs.mdlEditDataGruppo.hide();
            this.$emit("onClose");
        },

        onClickOk() {
            let me = this;
            if (!me.jsonData.profilo || !me.jsonData.idDashboard) {
                me.$bvToast.toast("Tutti i campi sono obbligatori", {
                    title: "Errore",
                    variant: "danger",
                    solid: true,
                });
                return;
            }
            UtilityMixin.methods.saveResource(me.pathResource, me.jsonData, me.saveSuccessResponse, me.saveErrorResponse);
        },
        saveSuccessResponse(response) {
            let me = this;
            me.$emit("onSave", response);
            me.$refs.mdlEditDataGruppo.hide();
            me.$bvToast.toast("Salvataggio avvenuto con successo", {
                title: "Successo",
                variant: "success",
                solid: true,
            });
        },
        saveErrorResponse(response) {
            if (response.response.status === 400) {
                this.$bvModal.msgBoxOk(response.response.data.messaggio, {
                    title: "ATTENZIONE",
                    size: "sm",
                    okVariant: "outline-secondary",
                    headerClass: "sa-msg-header-danger",
                    footerClass: "p-2 border-top-0",
                    centered: true,
                });
            } else {
                this.$bvToast.toast("Errore nel salvataggio" + response.message, {
                    title: "Errore",
                    variant: "danger",
                    solid: true,
                });
            }
        },

        onDeleteGruppoAssocizione(item) {
            let me = this;
            me.$bvModal
                .msgBoxConfirm("Sei sicuro di eliminare questo gruppo?", {
                    title: "Eliminazione Gruppo Associzione",
                    buttonSize: "sm",
                    okTitle: "SI",
                    cancelTitle: "NO",
                    okVariant: "danger",
                    cancelVariant: "secondary",
                    hideHeaderClose: true,
                    footerClass: "p-2",
                    centered: true,
                })
                .then((value) => {
                    if (value) {
                        me.deleteGruppoAssociazione(item.id);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        deleteGruppoAssociazione(id) {
            let me = this;
            UtilityMixin.methods.deleteResource(me.pathResource + "/" + id, null, me.deleteGruppoAssociazioneSuccess, me.deleteGruppoAssociazioneError);
        },
        deleteGruppoAssociazioneSuccess(response) {
            let me = this;
            me.$emit("onDeleteGruppoAssociazione", response);
            me.$bvToast.toast("Eliminazione avvenuta con successo", {
                title: "Success",
                variant: "success",
                solid: true,
            });
        },
        deleteGruppoAssociazioneError(error) {
            let me = this;
            me.showModalLoading = false;
            console.log(error);
            me.$bvToast.toast("Errore durante l'eliminazione", {
                title: "Errore",
                variant: "danger",
                solid: true,
            });
        },
    },
};
</script>
