<template>
    <eventi-edit-component :jsonData="jsonData" :utente="utente" :isAllegati="true" :pathResource="pathResource" :titoloEvento="titoloEvento" @update="onUpdateJsonData">
        <template slot="tab-content-event">
            <div class="sa-tab-scroll">
                <b-row>
                    <b-col cols="12" xs="12" sm="10" md="10" lg="10" xl="10">
                        <anamnesi-edit-component :jsonData="jsonData" :tipoEvento="titoloEvento" :gruppo="gruppoEvento" @update="onUpdateJsonData"></anamnesi-edit-component>
                    </b-col>
                    <b-col cols="12" xs="12" sm="2" md="2" lg="2" xl="2" class="sa-page-paziente-col-diario">
                        <paziente-diario-component class="sa-page-paziente-diario-clinico" :idPaziente="idPaziente"></paziente-diario-component>
                    </b-col>
                </b-row>
            </div>
        </template>
    </eventi-edit-component>
</template>
<script>
import EventiEditComponent from "../../base/components/EventiEditComponent.vue";
import EventiMixin from "../../utility/mixins/EventiMixins";
import UtilityMixins from "../../../../utility/UtilityMixin";
// import VisitaControlloEcgEditComponent from "./VisitaControlloEcgEditComponent.vue";
import PazienteDiarioComponent from "../../../utility/PazienteDiarioComponent.vue";
import AnamnesiEditComponent from "../components/AnamnesiEditComponent.vue";
export default {
    mixins: [UtilityMixins],
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    components: { EventiEditComponent, PazienteDiarioComponent, AnamnesiEditComponent },
    created() {
        console.log("Created");
    },
    data() {
        return {
            linkback: null,
            titoloEvento: "VISITA GENERICA",
            gruppoEvento: "Eventi",
            pathResource: "/pazienteeventianamnesi",
            pathRestValidation: "/validation",
            id: "-1",
            isFirmato: false,
            childKey: 0,
            tabIndex: 0,
            showModalLoading: false,
            diario: [],
            jsonData: {
                dataEvento: null,
                anamnesiFisiologicaAlcool: "",
                anamnesiFisiologicaFumo: "",
                anamnesiFisiologicaSostanzePsicoattive: "",
                anamnesiFisiologicaSonno: "",
                anamnesiFisiologicaAlvo: "",
                anamnesiFisiologicaDiuresi: "",
                anamnesiFisiologicaAltro: "",
                anamnesiPatologicaRemotaMalattiePrecedenti: "NO",
                anamnesiPatologicaRemotaMalattiePrecedentiDettaglio: "",
                anamnesiPatologicaRemotaTrasumiPrecedenti: "NO",
                anamnesiPatologicaRemotaTrasumiPrecedentiDettaglio: "",
                anamnesiPatologicaProssima: "",
                organiApparatiCoinvolti: "SI",
                altezza: "",
                peso: "",
                polso: "",
                pressioneArteriosa: "",
                spo2: "",
                temperatira: "",
                temperatiraDettaglio: "",
                cute: "",
                cuteNdp: "SI",
                apparatoMuscoloscheletrico: "",
                apparatoMuscoloscheletricoNdp: "SI",
                apparatoLinfonodale: "",
                apparatoLinfonodaleNdp: "SI",
                capoCollo: "",
                capoColloNdp: "SI",
                torace: "",
                toraceNdp: "SI",
                cuore: "",
                cuoreNdp: "SI",
                addome: "",
                addomeNdp: "SI",
                arti: "",
                artiNdp: "SI",
                snp: "",
                snpNdp: "SI",
                snc: "",
                sncNdp: "SI",
                organiApparatiCoinvoltiCute: "NO",
                organiApparatiCoinvoltiDentatura: "NO",
                organiApparatiCoinvoltiOcchi: "NO",
                organiApparatiCoinvoltiOrecchi: "NO",
                odentaturarganiApparatiCoinvolti: "NO",
                organiApparatiCoinvoltiGolaNaso: "NO",
                organiApparatiCoinvoltiBronchiPolmoni: "NO",
                organiApparatiCoinvoltiApparatoCardiovascolare: "NO",
                organiApparatiCoinvoltiStomaco: "NO",
                organiApparatiCoinvoltiIntestino: "NO",
                organiApparatiCoinvoltiFegato: "NO",
                organiApparatiCoinvoltiApparatoMuscoloscheletrico: "NO",
                organiApparatiCoinvoltiMetabolismo: "NO",
                organiApparatiCoinvoltiSnc: "NO",
                organiApparatiCoinvoltiSnp: "NO",
                organiApparatiCoinvoltiAltro: "",
                diagnosiPatologiaDermatologica: "NO",
                diagnosiPatologiaDermatologicaInfestazione: "NO",
                diagnosiPatologiaMuscoloscheletrica: "NO",
                diagnosiPatologiaOculistica: "NO",
                diagnosiPatologiaOtoiatrica: "NO",
                diagnosiPatologiaOdontoiatrica: "NO",
                diagnosiPatologiaOrl: "NO",
                diagnosiMalattieRespiratorie: "NO",
                diagnosiIpertensione: "NO",
                diagnosiCardiopatie: "NO",
                diagnosiMalattieCardiovascolariAltre: "",
                diagnosiDiabete: "NO",
                diagnosiAltreMalattieMetabolicheEndocrine: "NO",
                diagnosiMalattieGastroduodenali: "NO",
                diagnosiMalattieIntestinali: "NO",
                diagnosiEpatopatie: "NO",
                diagnosiMalattieNeurologiche: "NO",
                diagnosiMalattiePsichiatriche: "NO",
                diagnosi: "",
                terapiaMedicazione: "NO",
                terapiaFans: "NO",
                terapiaAntipiretici: "NO",
                terapiaAntidolorifici: "NO",
                terapiaAntibiotici: "NO",
                terapiaAntimicotici: "NO",
                terapiaCortisonici: "NO",
                terapiaAntidiabetici: "NO",
                terapiaAntiipertensivi: "NO",
                terapiaAltriCardiovascolari: "NO",
                terapiaAntiacidiPpiAntireflusso: "NO",
                terapiaAntidiarroici: "NO",
                terapiaAntispastici: "NO",
                terapiaLassativi: "NO",
                terapiaFliuidificantiMucolitici: "NO",
                terapiaBroncodilatatori: "NO",
                terapiaAntidepressivi: "NO",
                terapiaNeurolettici: "NO",
                terapiaAltri: "",

                anamnesiFisiologicaGravidanzePassate: "",
                anamnesiPatologicaAllergie: "NO",
                anamnesiPatologicaAllergieDettaglio: "",
                anamnesiPatologicaPsiche: "NO",
                anamnesiPatologicaPsicheDettaglio: "",
                anamnesiPatologicaTbc: "NO",
                anamnesiPatologicaTbcDettaglio: "",
                anamnesiPatologicaApparatoUrogenitale: "NO",
                anamnesiPatologicaApparatoUrogenitaleDettaglio: "",
                diagnosiMalattieUrologiche: "NO",
                terapiaAntiparassitosi: "NO",
                terapiaAntiTbc: "NO",
            },
            key: 0,
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient() {
            return EventiMixin.getLinkPatient(this.utente, this.idPaziente);
        },
    },
    mounted() {
        this.jsonData.dataEvento = new Date().getTime();
    },
    methods: {
        onFirmaUpdate() {
            let me = this;
            me.loadData();
        },
        onUpdateFile() {
            let me = this;
            me.childKey++;
        },
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
        },
    },
};
</script>
<style scoped>
.ft-space {
    margin-top: 10px;
}
</style>
