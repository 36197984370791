import { render, staticRenderFns } from "./VisitaControlloEcgEdit.vue?vue&type=template&id=f5a6d22c&scoped=true"
import script from "./VisitaControlloEcgEdit.vue?vue&type=script&lang=js"
export * from "./VisitaControlloEcgEdit.vue?vue&type=script&lang=js"
import style0 from "./VisitaControlloEcgEdit.vue?vue&type=style&index=0&id=f5a6d22c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f5a6d22c",
  null
  
)

export default component.exports