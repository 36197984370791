<template>
    <sa-page-layout :btnNewVisible="false" :btnRefreshVisible="true" @refresh="onRefresh" :pathResource="pathResource" :showModalLoading="showModalLoading">
        <template slot="table-body">
            <cot-allert-component ref="CotAllertComponent" @afterLoadData="onAfterLoadData"></cot-allert-component>
        </template>
    </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import CotAllertComponent from "../components/CotAllertComponent.vue";
export default {
    components: { SaPageLayout, CotAllertComponent },
    data() {
        return {
            pathResource: "",
            // linkedit: "/cot/richieste",
            showModalLoading: false,
            listKey: 0,
        };
    },
    mounted() {
        let me = this;
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            me.$refs.CotAllertComponent.loadData();
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        onAfterLoadData() {
            let me = this;
            me.showModalLoading = false;
        },
        onReset() {},
    },
};
</script>

<style></style>
