<template>
    <sa-page-layout slot="table-body" :btnConfigurazioniVisible="btnConfigurazioniVisible" :btnRefreshVisible="true" @refresh="onRefresh" :pathResource="pathResource" :showModalLoading="showModalLoading">
        <template slot="toolbar">
            <b-button class="float-sm-right" variant="success btn-toolbar sa-margin-right" size="sm" @click="onClickNuovoTipoPrestazione">
                <b-icon icon="plus"></b-icon>
                Nuovo
            </b-button>
        </template>
        <template slot="table-filter">
            <b-form @submit.prevent="onSubmit">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Nome</label>
                        <b-form-input v-model="filtroPrestazioni.nome" type="search" id="nome" placeholder="Inserire nome"></b-form-input>
                    </b-col>

                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Codice Prestazione</label>
                        <b-form-input v-model="filtroPrestazioni.codicePrestazione" type="search" placeholder="Inserire codice"></b-form-input>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="sa-padding-right pt-3 pb-3 text-right">
                        <b-button type="submit" variant="info"> {{ this.$i18n.t("patients.lblFind") }}</b-button>
                        <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("patients.lblResetFilter") }}</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </template>

        <template slot="table-body">
            <rsa-configurazione-tipo-prestazioni-list-component ref="RsaConfigurazioneTipoPrestazioneListComponent" @beforeLoadData="onBeforeLoadData" @afterLoadData="onAfterLoadData"> </rsa-configurazione-tipo-prestazioni-list-component>
        </template>
    </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import RsaConfigurazioneTipoPrestazioniListComponent from "../components/RsaConfigurazioneTipoPrestazioniListComponent.vue";
export default {
    components: { SaPageLayout, RsaConfigurazioneTipoPrestazioniListComponent },

    data() {
        return {
            filtroPrestazioni: {
                nome: null,
                codicePrestazione: null,
            },
            btnNewVisible: false,
            btnConfigurazioniVisible: true,
            pathResource: "",
            showModalLoading: false,
        };
    },

    mounted() {
        let me = this;
        me.loadData();
        let sessionStorage = window.sessionStorage;
        if (sessionStorage["filtroPrestazioni"]) {
            me.filtro = JSON.parse(sessionStorage["filtroPrestazioni"]);
        }
        me.onReset(); //Forse da mettere (da domandare)
    },

    methods: {
        loadData() {
            let me = this;
            me.$refs.RsaConfigurazioneTipoPrestazioneListComponent.loadData();
        },
        onBeforeLoadData() {
            this.showModalLoading = true;
        },
        onAfterLoadData() {
            this.showModalLoading = false;
        },
        onSubmit() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtroPrestazioni"] = JSON.stringify(me.filtroPrestazioni);
            me.loadData();
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        onReset() {
            let me = this;
            me.filtroPrestazioni = { nome: null };
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtroPrestazioni"] = JSON.stringify(me.filtroPrestazioni);
            me.loadData();
        },
        onClickNuovoTipoPrestazione() {
            let me = this;
            me.$refs.RsaConfigurazioneTipoPrestazioneListComponent.onClickNuovoTipoPrestazione();
        },
    },
};
</script>
