<template>
    <b-modal ref="mdlSchedaInfermieristica" title="Scheda Infermieristica" @hidden="onCloseModal" @ok="onSalvaSchedaInfermieristica" centered no-close-on-backdrop no-close-on-esc hide-header-close size="xl" scrollable @show="onShow">
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <rsa-scheda-infermieristica-anamnesi-patologica-remota-edit-component v-model="jsonData" :isReadOnly="isReadOnly" :id-accettazione="idAccettazione" ref="cmpApr" />
            </b-col>

            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <rsa-scheda-infermieristica-anamnesi-patologica-prossima-edit-component v-model="jsonData" :isReadOnly="isReadOnly" :id-accettazione="idAccettazione" ref="cmpAnamnesiPatologicaProssima" />
            </b-col>

            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <rsa-scheda-infermieristica-valutazione-sensoriale-edit-component :id-accettazione="idAccettazione" :isReadOnly="isReadOnly" ref="cmpValutazioneSensoriale" />
            </b-col>

            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <rsa-scheda-infermieristica-valutazione-escretoria-edit-component :id-accettazione="idAccettazione" :isReadOnly="isReadOnly" ref="cmpValutazioneEscretoria" />
            </b-col>

            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <rsa-scheda-infermieristica-riposo-sonno-edit-component :id-accettazione="idAccettazione" :isReadOnly="isReadOnly" ref="cmpRiposoSonno" />
            </b-col>

            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <rsa-scheda-infermieristica-valutazione-adl-edit-component :id-accettazione="idAccettazione" :isReadOnly="isReadOnly" ref="cmpValutazioneAdl" />
            </b-col>

            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <rsa-scheda-infermieristica-valutazione-dermatologica-edit-component :id-accettazione="idAccettazione" :isReadOnly="isReadOnly" ref="cmpValutazioneDermatologica" />
            </b-col>

            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <rsa-scheda-infermieristica-valutazione-preferenze-edit-component :id-accettazione="idAccettazione" :isReadOnly="isReadOnly" ref="cmpValutazionePreferenze" />
            </b-col>

            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card sa-card-border" header="Note Scheda" header-tag="header" footer-tag="footer" title="">
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <b-form-textarea v-model="jsonData.note" :readonly="isReadOnly" max-rows="10" rows="7"> </b-form-textarea>
                    </b-col>
                </b-card>
            </b-col>
        </b-row>
        <template #modal-footer>
            <b-button v-if="!isReadOnly" variant="outline-danger" @click="$refs.mdlSchedaInfermieristica.hide()">Annulla</b-button>
            <b-button v-if="!isReadOnly" variant="outline-success" @click="onSalvaSchedaInfermieristica()">Salva</b-button>
            <b-button v-if="isReadOnly" variant="outline-primary" @click="$refs.mdlSchedaInfermieristica.hide()"> Chiudi </b-button>
        </template>
    </b-modal>
</template>

<script>
import RsaSchedaInfermieristicaValutazionePreferenzeEditComponent from "./RsaSchedaInfermieristicaValutazionePreferenzeEditComponent.vue";
import UtilityMixin from "../../../utility/UtilityMixin";
import RsaSchedaInfermieristicaValutazioneDermatologicaEditComponent from "./RsaSchedaInfermieristicaValutazioneDermatologicaEditComponent.vue";
import RsaSchedaInfermieristicaAnamnesiPatologicaRemotaEditComponent from "./RsaSchedaInfermieristicaAnamnesiPatologicaRemotaEditComponent.vue";
import RsaSchedaInfermieristicaValutazioneSensorialeEditComponent from "./RsaSchedaInfermieristicaValutazioneSensorialeEditComponent.vue";
import RsaSchedaInfermieristicaValutazioneEscretoriaEditComponent from "./RsaSchedaInfermieristicaValutazioneEscretoriaEditComponent.vue";
import RsaSchedaInfermieristicaRiposoSonnoEditComponent from "./RsaSchedaInfermieristicaRiposoSonnoEditComponent.vue";
import RsaSchedaInfermieristicaValutazioneAdlEditComponent from "./RsaSchedaInfermieristicaValutazioneAdlEditComponent.vue";
import RsaSchedaInfermieristicaAnamnesiPatologicaProssimaEditComponent from "./RsaSchedaInfermieristicaAnamnesiPatologicaProssimaEditComponent.vue";
export default {
    components: {
        RsaSchedaInfermieristicaAnamnesiPatologicaRemotaEditComponent,
        RsaSchedaInfermieristicaValutazioneSensorialeEditComponent,
        RsaSchedaInfermieristicaValutazioneEscretoriaEditComponent,
        RsaSchedaInfermieristicaRiposoSonnoEditComponent,
        RsaSchedaInfermieristicaValutazioneAdlEditComponent,
        RsaSchedaInfermieristicaValutazioneDermatologicaEditComponent,
        RsaSchedaInfermieristicaValutazionePreferenzeEditComponent,
        RsaSchedaInfermieristicaAnamnesiPatologicaProssimaEditComponent,
    },

    mixins: [UtilityMixin],
    mounted() {
        let me = this;
        me.idAccettazione = me.$route.query.idPaziente;
        //me.loadData();
        // console.log("idAccettazione nel mounted di ModalInfermieristica: " + me.idAccettazione);
    },
    data() {
        return {
            isReadOnly: false,
            pathResourceDizionario: "/rsadizionario",
            pathResourceSchedaInfermieristica: "/rsaschedainfermieristica",
            pathResourceCheckSchedaInfermieristica: "/rsaschedainfermieristica/check",
            idAccettazione: null,
            jsonData: {
                id: null,
                idAccettazione: null,
                note: null,
            },
        };
    },

    methods: {
        show(value, isReadOnly) {
            let me = this;
            me.isReadOnly = isReadOnly;
            me.$refs.mdlSchedaInfermieristica.show();
            value;
        },

        loadData() {
            let me = this;
            // console.log("idAccettazione LoadGeneraleData", me.idAccettazione);

            UtilityMixin.methods.loadDataResources(me.pathResourceSchedaInfermieristica + "/accettazione/" + me.idAccettazione, {}, me.resultResponseSchedaInfermieristicaSuccess, me.resultResponseSchedaInfermieristicaError);
        },
        resultResponseSchedaInfermieristicaSuccess(response) {
            let me = this;
            me.jsonData = response.data.data;
            me.$refs.cmpApr.loadAprData(); /* L'ho messo anche nell'error perché se le note note  */
            me.$refs.cmpAnamnesiPatologicaProssima.loadData();
            me.$refs.cmpValutazioneSensoriale.loadValutazioneSensorialeData();
            me.$refs.cmpValutazioneEscretoria.loadValutazioneEscretoriaData();
            me.$refs.cmpRiposoSonno.loadRiposoSonnoData();
            me.$refs.cmpValutazioneAdl.loadValutazioneAdlData();
            me.$refs.cmpValutazioneDermatologica.loadValutazioneDermatologicaData();
            me.$refs.cmpValutazionePreferenze.loadValutazionePreferenzeData();

            // console.log("ID Scheda Infermieristica", me.jsonData.id);
        },

        resultResponseSchedaInfermieristicaError() {
            let me = this;
            /*  me.$bvToast.toast("Nessun record trovato per la scheda infermieristica in questa accettazione", {
                title: "Informazione",
                variant: "info",
                solid: true,
            }); */ /* HO TOLTO IL TOAST POICHE' SE NOTE DELLA SCHEDA NON VIENE COMPILATO LO MANDA A VIDEO */
            me.$refs.cmpApr.loadAprData();
            me.$refs.cmpAnamnesiPatologicaProssima.loadData();
            me.$refs.cmpValutazioneSensoriale.loadValutazioneSensorialeData();
            me.$refs.cmpValutazioneEscretoria.loadValutazioneEscretoriaData();
            me.$refs.cmpRiposoSonno.loadRiposoSonnoData();
            me.$refs.cmpValutazioneAdl.loadValutazioneAdlData();
            me.$refs.cmpValutazioneDermatologica.loadValutazioneDermatologicaData();
            me.$refs.cmpValutazionePreferenze.loadValutazionePreferenzeData();
            me.resetForm();
        },

        resetForm() {
            let me = this;
            me.jsonData = {
                id: null,
                idAccettazione: me.idAccettazione,
                note: null,
            };
        },

        onSalvaSchedaInfermieristica() {
            let me = this;
            me.jsonData.idAccettazione = me.idAccettazione;
            console.log("Salvataggio SCHEDA INFERMIERISTICA con dati: ", JSON.stringify(me.jsonData));
            if (!me.jsonData.idAccettazione) {
                // console.log("idAccettazione: ", me.jsonData.idAccettazione);
                me.$bvToast.toast("Errore: ID Accettazione mancante", {
                    title: "Errore",
                    variant: "danger",
                    solid: true,
                });
                return;
            }

            UtilityMixin.methods.saveResource(me.pathResourceSchedaInfermieristica, me.jsonData, me.saveSchedaInfermieristicaSuccessResponse, me.saveSchedaInfermieristicaErrorResponse);
            me.$refs.cmpApr.onSalvaApr();
            me.$refs.cmpAnamnesiPatologicaProssima.onSalvaAnamnesiPatologicaProssima();
            me.$refs.cmpValutazioneSensoriale.onSalvaValutazioneSensoriale();
            me.$refs.cmpValutazioneEscretoria.onSalvaValutazioneEscretoria();
            me.$refs.cmpRiposoSonno.onSalvaRiposoSonno();
            me.$refs.cmpValutazioneAdl.onSalvaValutazioneAdl();
            me.$refs.cmpValutazioneDermatologica.onSalvaValutazioneDermatologica();
            me.$refs.cmpValutazionePreferenze.onSalvaValutazionePreferenze();
        },

        saveSchedaInfermieristicaSuccessResponse(response) {
            let me = this;
            console.log("SCHEDA INFERMIERISTICA salvato con successo:", response);
            me.$emit("afterSaveSuccess");

            me.$refs.mdlSchedaInfermieristica.hide();
            me.$bvToast.toast("Salvataggio avvenuto con successo", {
                title: "Successo",
                variant: "success",
                solid: true,
            });
        },
        saveSchedaInfermieristicaErrorResponse(response) {
            let me = this;
            if (response.response.status === 400) {
                me.$bvModal.msgBoxOk(response.response.data.messaggio, {
                    title: "ATTEZIONE",
                    size: "md",
                    okVariant: "outline-secondary",
                    headerClass: "sa-msg-header-danger",
                    footerClass: "p-2 border-top-0",
                    centered: true,
                });
            } else {
                me.$bvToast.toast("Errore nel salvataggio in edit component" + response.message, {
                    title: "Errore",
                    variant: "danger",
                    solid: true,
                });
            }
        },

        onCloseModal() {
            let me = this;
            me.resetForm();
            me.$emit("onCloseModal");
        },
        onShow() {
            let me = this;
            me.loadData();
        },
    },
};
</script>
