<template>
  <sa-page-layout :btnSaveVisible="true" :btnAnnullaVisible="true" :linkback="linkback" :data="jsonData" :showModalLoading="showModalLoading" :pathResource="pathResource" class="sa-no-space">
    <template slot="table-body">
      <cot-strutture-edit-component :showModalLoading="showModalLoading" @update="onUpdateJsonData" @beforeLoadData="onBeforeLoadData" @afterLoadData="onAfterLoadData"></cot-strutture-edit-component>
    </template>
  </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import CotStruttureEditComponent from "../components/CotStruttureEditComponent.vue";
export default {
  components: { SaPageLayout, CotStruttureEditComponent },
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  data() {
    return {
      linkback: "/cot/strutture",
      jsonData: {},
      showModalLoading: false,
      pathResource: "/cotstrutture",
    };
  },
  //   mounted() {
  //     let me = this;
  //     me.loadData();
  //   },
  methods: {
    // loadData() {
    //   let me = this;
    //   me.showModalLoading = true;
    //   me.$refs.CotStruttureEditComponent.loadData();
    // },
    onUpdateJsonData(data) {
      let me = this;
      me.jsonData = data;
    },
    onBeforeLoadData() {
      let me = this;
      me.showModalLoading = true;
    },
    onAfterLoadData() {
      let me = this;
      me.showModalLoading = false;
    },
  },
};
</script>

<style></style>
