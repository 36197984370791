<template>
    <sa-page-layout slot="table-body" :btnConfigurazioniVisible="btnConfigurazioniVisible" :btnRefreshVisible="true" @refresh="onRefresh" :showModalLoading="showModalLoading">
        <template slot="toolbar">
            <b-button class="float-sm-right" variant="success btn-toolbar sa-margin-right" size="sm" @click="onClickNuovaConfigurazione">
                <b-icon icon="plus"></b-icon>
                Nuovo
            </b-button>
        </template>

        <template slot="table-body">
            <rsa-configurazione-parametri-vitali-list-component ref="cmpConfigurazioneParametriList" @afterLoadData="onAfterLoadData" />
        </template>
    </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import RsaConfigurazioneParametriVitaliListComponent from "../components/RsaConfigurazioneParametriVitaliListComponent.vue";
export default {
    components: { SaPageLayout, RsaConfigurazioneParametriVitaliListComponent },
    data() {
        return {
            pathResource: "",
            btnConfigurazioniVisible: true,
            showModalLoading: false,
        };
    },
    mounted() {
        let me = this;
        me.$refs.cmpConfigurazioneParametriList.loadParametriVitaliData();
    },

    methods: {
        onClickNuovaConfigurazione() {
            let me = this;
            me.$refs.cmpConfigurazioneParametriList.onClickNuovoParametroVitale();
        },
        onAfterLoadData() {
            let me = this;
            me.showModalLoading = false;
        },
        onBeforeLoadData() {
            let me = this;
            me.showModalLoading = true;
        },
        onRefresh() {
            let me = this;
            me.$refs.cmpConfigurazioneParametriList.loadParametriVitaliData();
        },
    },
};
</script>
