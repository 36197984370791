<template>
    <b-modal title="Aggiungi Nota" ref="mdlRsaDiariNoteEdit" id="mdlRsaDiariNoteEdit" modal-class="sa-modal-static-scroll" size="lg" @ok="onOk">
        <div class="sa-flexbox">
            <div class="sa-flexbox-header">
                <b-row>
                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <label class="sa-label-data">Data Ora:</label>
                        <b-input-group class="mb-3">
                            <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataOraEsecuzione" type="datetime"></date-picker>
                        </b-input-group>
                    </b-col>
                </b-row>
            </div>
            <div class="sa-flexbox-body">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <label class="sa-label-data">Note:</label>
                        <vue-editor class="sa-qeditor" :editor-toolbar="customToolbar" v-model="jsonData.annotazione" />
                    </b-col>
                </b-row>
            </div>
            <!-- <div class="sa-flexbox-footer">
                <div style="max-height: 320px; overflow: hidden">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <b-calendar :min="min" class="border rounded p-2" start-weekday="1" locale="it" @context="onContext"></b-calendar>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <b-row>
                                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                    <label class="sa-label-data">Data Programmata:</label>
                                    <b-input-group class="mb-3">
                                        <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="schedulazione.dataInizio" @input="onInputSchedulazioneDataInizio"></date-picker>
                                    </b-input-group>
                                </b-col>
                                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                    <label class="sa-label-data">Ora Programmata:</label>
                                    <div style="display: flex; flex-direction: row">
                                        <div style="flex: 1">
                                            <b-input-group class="mb-3">
                                                <date-picker format="HH:mm" value-type="timestamp" v-model="schedulazione.orario" :disabled-time="disbilitaOrePrecedenti" placeholder="HH:mm" :default-value="new Date()" :minute-step="15" type="time"></date-picker>
                                            </b-input-group>
                                        </div>
                                        <div>
                                            <b-button size="sm" variant="outline-success  pull-right" style="height: 29px" @click="onClickAggiungiOrario()">
                                                <i class="fas fa-plus"></i>
                                            </b-button>
                                        </div>
                                    </div>
                                </b-col>
                                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                    <div class="b-table-sticky-header" style="height: 260px">
                                        <b-table sticky-header ref="table" stacked="lg" striped hover :items="jsonData.listaOrari" :fields="fields" :current-page="1" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                                            <template v-slot:cell(actions)="row">
                                                <b-container class="bv-example-row">
                                                    <b-button :disabled="checkDisableDelete(row.item)" v-b-tooltip.hover size="sm" variant="outline-danger" title="Cancella Orario" @click="onClickDelete(jsonData.listaOrari, row.item)" class="mr-1 no-text">
                                                        <b-icon icon="trash" variant="danger"></b-icon>
                                                    </b-button>
                                                </b-container>
                                            </template>
                                        </b-table>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </div>
            </div> -->
        </div>
    </b-modal>
</template>

<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import { VueEditor } from "vue2-editor";
export default {
    components: {
        DatePicker,
        VueEditor,
    },
    data() {
        return {
            customToolbar: [
                ["bold", "italic", "underline", "strike"], // toggled buttons
                [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],

                [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
                [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            ],
            pathResource: "/rsadiarivoci",
            jsonData: {},
            perPage: 1000000,
        };
    },
    methods: {
        add(jsonData) {
            let me = this;
            me.jsonData = jsonData;
            me.$refs.mdlRsaDiariNoteEdit.show();
        },
        onOk() {
            let me = this;
            me.createNota();
        },
        createNota() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            axios
                .post(link, JSON.stringify(me.jsonData))
                .then(() => {
                    me.$emit("afterSaveRequest");
                })
                .catch((error) => {
                    me.$emit("afterSaveRequest");
                    let messaggio = error.response ? error.response.data.messaggio : "Errore non gestito";
                    me.$bvModal
                        .msgBoxOk(messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then(() => {})
                        .catch((err) => {
                            console.log(err);
                        });
                });
        },
    },
};
/*
TOOLBAR STANDARD
            customToolbar: [
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                ["bold", "italic", "underline", "strike"], // toggled buttons
                [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
                ["blockquote", "code-block"],
                [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
                [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                ["link", "image", "video"],
                ["clean"], // remove formatting button
            ],

*/
</script>

<style>
.sa-flexbox-body .row {
    flex: 1;
    height: 1px;
}
.sa-flexbox-body .row .col-sm-12.col-md-12.col-lg-12.col-xl-12.col-12 {
    height: 100%;
}
.sa-qeditor {
    height: 100%;
    padding-bottom: 80px !important;
}
.sa-qeditor .ql-editor {
    height: 100%;
    min-height: auto !important;
    padding-bottom: 15px;
    /* min-height: 150px;
    max-height: 150px; */
}
.ql-editor {
    font-size: 1rem !important;
}
</style>
