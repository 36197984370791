<template>
    <sa-page-layout slot="table-body" :btnRefreshVisible="true" @refresh="onRefresh" :pathResource="pathResource" :showModalLoading="showModalLoading" :btnConfigurazioniVisible="btnConfigurazioniVisible">
        <template slot="toolbar">
            <b-button class="float-sm-right" variant="success btn-toolbar sa-margin-right" size="sm" @click="onClickNuovaSpesa">
                <b-icon icon="plus"></b-icon>
                Nuovo
            </b-button>
        </template>
        <template slot="table-filter">
            <b-form @submit.prevent="onSubmit">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <label class="sa-label-data">Spesa</label>
                        <b-form-input v-model="filtroPrestazioni.descrizione" type="search" id="descrizione" placeholder="Inserire spesa"></b-form-input>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="sa-padding-right pt-3 pb-3 text-right">
                        <b-button type="submit" variant="info"> {{ this.$i18n.t("patients.lblFind") }}</b-button>
                        <!-- Perché fa l'evento click senza averlo messo? -->
                        <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("patients.lblResetFilter") }}</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </template>

        <template slot="table-body">
            <rsa-configurazione-spese-extra-list-component ref="cmpRsaConfigurazioneSpeseExtraListComponent" @beforeLoadData="onBeforeLoadData" @afterLoadData="onAfterLoadData"> </rsa-configurazione-spese-extra-list-component>
        </template>
    </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import RsaConfigurazioneSpeseExtraListComponent from "../components/RsaConfigurazioneSpeseExtraListComponent.vue";
export default {
    components: { SaPageLayout, RsaConfigurazioneSpeseExtraListComponent },

    data() {
        return {
            filtroPrestazioni: {
                descrizione: null,
            },
            btnNewVisible: false,
            btnConfigurazioniVisible: true,
            pathResource: "",
            showModalLoading: false,
        };
    },

    mounted() {
        this.loadData();
    },

    methods: {
        loadData() {
            let me = this;
            me.$refs.cmpRsaConfigurazioneSpeseExtraListComponent.loadData();
        },
        onBeforeLoadData() {
            this.showModalLoading = true;
        },
        onAfterLoadData() {
            this.showModalLoading = false;
        },
        onSubmit() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtroPrestazioni"] = JSON.stringify(me.filtroPrestazioni);
            me.loadData();
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        onReset() {
            let me = this;
            me.filtroPrestazioni = { descrizione: null };
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtroPrestazioni"] = JSON.stringify(me.filtroPrestazioni);
            me.loadData();
        },
        onClickNuovaSpesa() {
            let me = this;
            me.$refs.cmpRsaConfigurazioneSpeseExtraListComponent.onClickNuovaSpesa();
        },
    },
};
</script>
