<template>
  <div class="sa-page">
    <div class="sa-page-header" v-if="divPageHeaderVisible">
      <b-navbar
        v-if="tlbViewVisible"
        toggleable="lg"
        type="light"
        variant="faded"
      >
        <b-navbar-brand href="#">
          <span style="margin-left: 0.5rem; float: left">{{ titolo }} </span>
          <slot name="toolbar-title"> </slot>
        </b-navbar-brand>

        <b-navbar-toggle target="page-nav-collapse"></b-navbar-toggle>

        <b-collapse id="page-nav-collapse" is-nav>
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <slot name="toolbar"></slot>
            <b-button
              v-if="btnEditVisible"
              variant="primary btn-toolbar sa-margin-right float-sm-right"
              size="sm"
              @click="onEdit"
              ><b-icon icon="pencil"></b-icon>Modifica</b-button
            >
            <b-button
              v-if="btnNewVisible"
              class="float-sm-right"
              variant="success btn-toolbar sa-margin-right"
              size="sm"
              @click="onAdd"
              ><b-icon icon="plus"></b-icon>Nuovo</b-button
            >
            <b-button
              v-if="btnRefreshVisible"
              class="float-sm-right primary"
              variant="info btn-toolbar"
              size="sm"
              @click="onRefresh"
              v-b-modal.modal-center
              ><b-icon icon="arrow-clockwise" />Refresh</b-button
            >
            <b-button
              v-if="btnSaveVisible"
              variant="success btn-toolbar float-sm-right sa-margin-right"
              size="sm"
              @click="onSave"
              ><b-icon icon="check2"></b-icon>Salva</b-button
            >
            <print-component v-if="btnPrintVisible"></print-component>
            <b-button
              v-if="btnBackVisible"
              variant="secondary btn-toolbar"
              class="my-2 my-sm-0"
              size="sm"
              @click="onBack"
              ><b-icon icon="chevron-left"></b-icon>Indietro</b-button
            >
            <b-button
              v-if="btnAnnullaVisible"
              variant="danger btn-toolbar sa-margin-right float-sm-right"
              size="sm"
              @click="onBack"
              ><b-icon icon="x"></b-icon>Annulla</b-button
            >
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
      <slot name="toolbar-row"></slot>
    </div>

    <div class="sa-page-content">
      <div class="sa-page-content-body">
        <div class="sa-page-content-body-filter">
          <slot name="table-filter"></slot>
        </div>
        <div class="sa-page-content-body-header">
          <slot name="table-header"></slot>
        </div>
        <div class="sa-page-content-body-content">
          <slot name="table-body"></slot>
        </div>
        <!--<div class="sa-table-footer" style="height: 30px"><slot name="table-footer"></slot></div>-->
        <div class="sa-page-content-body-footer">
          <slot name="table-footer"></slot>
        </div>
      </div>
    </div>

    <sa-modal-loading ref="sa-modal-loading" />
  </div>
</template>
<script>
import axios from "axios";
import { SaModalLoading } from "../../components/modal";
export default {
  components: { SaModalLoading },
  props: {
    titolo: String,
    linkedit: String,
    linkback: String,
    pathResource: String,
    divPageHeaderVisible: { type: Boolean, default: true },
    tlbViewVisible: { type: Boolean, default: true },
    btnBackVisible: { type: Boolean, default: false },
    btnPrintVisible: { type: Boolean, default: false },
    btnRefreshVisible: { type: Boolean, default: false },
    btnNewVisible: { type: Boolean, default: false },
    btnEditVisible: { type: Boolean, default: false },
    btnSaveVisible: { type: Boolean, default: false },
    btnAnnullaVisible: { type: Boolean, default: false },
    showModalLoading: { type: Boolean, default: false },
    data: {
      type: Object,
      default: function () {
        return { id: "-1" };
      },
    },
  },
  watch: {
    showModalLoading(value) {
      let me = this;
      if (value) {
        me.$refs["sa-modal-loading"].show();
      } else {
        setTimeout(me.$refs["sa-modal-loading"].close(), 200);
      }
    },
  },
  methods: {
    onBack() {
      let me = this;
      me.$router.replace(me.linkback).catch((err) => {
        err;
      });
    },
    onAdd() {
      let me = this;
      me.$router.replace(me.linkedit + "/edit/-1").catch((err) => {
        err;
      });
    },
    onRefresh() {
      let me = this;
      me.$emit("refresh");
    },
    onEdit() {
      let me = this;
      me.$router.replace(me.linkedit).catch((err) => {
        err;
      });
    },
    onSave() {
      let me = this;
      me.$refs["sa-modal-loading"].show();

      let link = process.env.VUE_APP_PATH_API + me.pathResource;
      if (
        me.data.id === "-1" ||
        me.data.id === null ||
        me.data.id === undefined
      ) {
        axios
          .post(link, JSON.stringify(me.data))
          .then(() => {
            me.$refs["sa-modal-loading"].close();
            me.$router.replace(me.linkback).catch((err) => {
              err;
            });
          })
          .catch((error) => {
            me.$refs["sa-modal-loading"].close();
            this.$bvModal
              .msgBoxOk(error.response.data.messaggio, {
                title: "ATTENZIONE",
                size: "sm",
                okVariant: "outline-danger",
                headerClass: "sa-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
              })
              .then((value) => {
                this.boxTwo = value;
              })
              .catch((err) => {
                console.log(err);
              });
          });
      } else {
        link = link + "/" + me.data.id;
        axios
          .put(link, JSON.stringify(me.data))
          .then(() => {
            console.log("Response");
            me.$refs["sa-modal-loading"].close();
            //me.linkback = me.linkback + "/view/" + response.data.data.id;
            me.$router.replace(me.linkback).catch((err) => {
              err;
            });
          })
          .catch((error) => {
            console.log(error);
            me.$refs["sa-modal-loading"].close();
            this.$bvModal
              .msgBoxOk(error.response.data.messaggio, {
                title: "ATTENZIONE",
                size: "sm",
                okVariant: "outline-danger",
                headerClass: "sa-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
              })
              .then((value) => {
                console.log(value);
              })
              .catch((err) => {
                console.log(err);
              });
          });
      }
    },
  },
};
</script>
<style>
.navbar {
  background-color: white;
}
</style>
