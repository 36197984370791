import RsaGestioneOspiti from "../";

const routes = [
  {
    name: "RsaGestioneOspitiList",
    path: "/rsagestioneospiti",
    component: RsaGestioneOspiti.RsaGestioneOspitiList,
    meta: { title: "R.S.A. - Gestione Ospiti" },
  },
  {
    name: "RsaGestioneOspitiList",
    path: "/rsaarchivioospiti",
    component: RsaGestioneOspiti.RsaGestioneOspitiArchivioList,
    meta: { title: "R.S.A. - Gestione Ospiti" },
  },
  {
    name: "RsaGestioneOspitiEdit",
    path: "/rsagestioneospiti/edit/:id",
    component: RsaGestioneOspiti.RsaGestioneOspitiEdit,
    meta: { title: "R.S.A. - Gestione Ospiti" },
  },
  {
    name: "RsaGestioneOspitiView",
    path: "/rsagestioneospiti/view/:id",
    component: RsaGestioneOspiti.RsaGestioneOspitiView,
    meta: { title: "R.S.A. - Gestione Ospiti" },
  },
  {
    name: "RsaPaziente",
    path: "/rsagestioneospiti/schedaospite/view/:id",
    component: RsaGestioneOspiti.RsaGestioneOspitiSchedaOspiteViewPage,
    meta: { title: "R.S.A. - Gestione Ospiti" },
  },
  {
    name: "RsaUscite",
    path: "/rsagestioneospiti/rsapazientepresenze/edit/:id",
    component: RsaGestioneOspiti.RsaGestioneOspitiUscitePage,
    meta: { title: "R.S.A. - Gestione Ospiti" },
  },
  {
    name: "RsaGestioneRefertiList",
    path: "/rsagestionereferti",
    component: RsaGestioneOspiti.RsaGestioneRefertiListPage,
    meta: { title: "R.S.A. - Gestione Referti" },
  },
  {
    name: "RsaPaiEdit",
    path: "/rsapai/edit/:id",
    component: RsaGestioneOspiti.RsaPaiEditPage,
    meta: { title: "R.S.A. - PAI" },
  },

  // { name: "StrutturaView", path: "/struttura/view/:id", component: Strutture.StrutturaView, meta: { title: "strutture.lblTitle" } },
  // { name: "StrutturaEdit", path: "/struttura/edit/:id", component: Strutture.StrutturaEdit, meta: { title: "strutture.lblTitle" } },
];

export default routes;
