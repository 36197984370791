import axios from "axios";
export default {
    namespaced: true,
    state: {
        richieste: [],
    },
    getters: {
        getRichieste: (state) => state.richieste,
    },
    actions: {
        async init({ commit }) {
            try {
                let link = process.env.VUE_APP_PATH_API + "/cot";
                await axios.get(link).then((response) => {
                    let richieste = [];
                    response.data.data.list.forEach((element) => {
                        richieste.push(element);
                    });
                    commit("setRichieste", richieste);
                });
            } catch (error) {
                alert(error);
                console.log(error);
            }
        },
        async setRichieste({ commit }) {
            console.log("SET RICHIESTE");
            console.log(commit);
            try {
                let link = process.env.VUE_APP_PATH_API + "/cot/richieste";
                await axios.get(link).then((response) => {
                    let richieste = [];
                    response.data.data.list.forEach((element) => {
                        richieste.push(element);
                    });
                    commit("setRichieste", richieste);
                });
            } catch (error) {
                alert(error);
                console.log(error);
            }
        },
    },
    mutations: {
        setRichieste(state, richieste) {
            state.richieste = richieste;
        },
    },
};
