<template>
    <div>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Vive in collettivita'?</label>
                    <span class="sa-data">{{ jsonData.viveCollettivita }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Indirizzo collettivita':</label>
                    <span class="sa-data">{{ jsonData.viveCollettivitaDenominazioneIndirizzo }}</span></b-col
                >
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Contatto collettivita':</label>
                    <span class="sa-data">{{ jsonData.viveCollettivitaContatto }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Denominazione e indirizzo luogo di lavoro/scuola:</label>
                    <span class="sa-data">{{ jsonData.luogoLavoroScuola }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data inizio della terapia antitubercolare :</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataInizioTerapiaAntitubercolare) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Esposizione a caso di TBC:</label>
                    <span class="sa-data">{{ jsonData.esposizioneCasoTbc }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Alcolismo :</label>
                    <span class="sa-data">{{ jsonData.alcolismo }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Immunodeficienza Grave :</label>
                    <span class="sa-data">{{ jsonData.immunodeficienzaGrava }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Altra Condizione predisponente:</label>
                    <span class="sa-data">{{ jsonData.altreCondizionePredisponente }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Altra Condizione predisponente dettaglio:</label>
                    <span class="sa-data">{{ jsonData.altreCondizionePredisponenteDettagli }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Diagnosi tubercolosi passate:</label>
                    <span class="sa-data">{{ jsonData.diagnosiTubercolosiPassato }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Altra Condizione predisponente Mese Anno:</label>
                    <span class="sa-data">{{ jsonData.diagnosiTubercolosiPassatoMeseAnno }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Classificazione:</label>
                    <span class="sa-data">{{ jsonData.classificazioneRelazionePrecedentiTrattamenti }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Agente Eziologico:</label>
                    <span class="sa-data">{{ jsonData.agenteEziologico }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Agente Eziologico Specificare:</label>
                    <span class="sa-data">{{ jsonData.agenteEziologicoMicrobatterioNonTubercolare }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Diagnosi Basata Su" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Esame Colturale Escreato:</label>
                    <span class="sa-data">{{ jsonData.diagnosiBasataEsameColturaleEscreato }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Esame Colturale Altro Materiale:</label>
                    <span class="sa-data">{{ jsonData.diagnosiBasataEsameColturaleAltroMateriale }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Specificare Materiale:</label>
                    <span class="sa-data">{{ jsonData.diagnosiBasataEsameColturaleAltroMaterialeDettagli }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Esame Diretto Escreato:</label>
                    <span class="sa-data">{{ jsonData.diagnosiBasataEsameDirettoEscreato }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Esame Diretto Altro Materiale:</label>
                    <span class="sa-data">{{ jsonData.diagnosiBasataEsameDirettoAltroMateriale }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Specificare Materiale:</label>
                    <span class="sa-data">{{ jsonData.diagnosiBasataEsameDirettoAltroMaterialeDettagli }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Id. Acido Nucleico Compl. Mycobacterium Tuberculosis</label>
                    <span class="sa-data">{{ jsonData.diagnosiBasataIdentificazioneAcidoNucleico }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Specificare Materiale:</label>
                    <span class="sa-data">{{ jsonData.diagnosiBasataIdentificazioneAcidoNucleicoDettaglio }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Clinica:</label>
                    <span class="sa-data">{{ jsonData.diagnosiBasataClinica }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Mantoux:</label>
                    <span class="sa-data">{{ jsonData.diagnosiBasataMantoux }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Rx Torace/Esami Strumentali:</label>
                    <span class="sa-data">{{ jsonData.diagnosiBasataRxToraceEsamiStrumentali }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Risposta Terapia Antitubercolare:</label>
                    <span class="sa-data">{{ jsonData.diagnosiBasataRispostaSedutaAntitubercolare }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Riscontro Autoptico TB Attiva:</label>
                    <span class="sa-data">{{ jsonData.diagnosiBasataRiscontroAutopticoTbAttiva }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Sede Anatomica:</label>
                    <span class="sa-data">{{ jsonData.sedeAnatomica }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Numero Conviventi:</label>
                    <span class="sa-data">{{ jsonData.numeroConviventi }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Contatti:</label>
                    <span class="sa-data">{{ jsonData.contatti }}</span>
                </b-col>
            </b-row>
            <b-row style="margin-top: 5px">
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <indagine-epidemiologica-contatti-edit-component-vue :listaContatti="jsonData.listaContatti" :isEdit="false"></indagine-epidemiologica-contatti-edit-component-vue>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Altre Considerazioni</label>
                    <span class="sa-data">{{ jsonData.altreConsiderazioni }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Dati Notifica" header-tag="header" footer-tag="footer" title="">
            <indagine-epidemiologica-dati-notifica-component :jsonData="jsonData" :isEdit="false"></indagine-epidemiologica-dati-notifica-component>
        </b-card>
    </div>
</template>
<script>
import axios from "axios";
import UtilityMixin from "../../../utility/UtilityMixin";
import IndagineEpidemiologicaContattiEditComponentVue from "../components/IndagineEpidemiologicaContattiEditComponent.vue";
import IndagineEpidemiologicaDatiNotificaComponent from "../components/IndagineEpidemiologicaDatiNotificaComponent.vue";
export default {
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    components: { IndagineEpidemiologicaContattiEditComponentVue, IndagineEpidemiologicaDatiNotificaComponent },
    mixins: [UtilityMixin],
    data() {
        return {
            pathResource: "/malattieinfettiveietubercolosimicrobatterinontubercolari",
            linkback: null,
            currentPage: 1,
            perPage: 100,
            jsonData: {
                viveCollettivita: "",
                viveCollettivitaDenominazioneIndirizzo: "",
                viveCollettivitaContatto: "",
                luogoLavoroScuola: "",
                dataInizioTerapiaAntitubercolare: null,
                esposizioneCasoTbc: "",
                alcolismo: "",
                immunodeficienzaGrava: "",
                altreCondizionePredisponente: "",
                altreCondizionePredisponenteDettagli: "",
                diagnosiTubercolosiPassato: "",
                diagnosiTubercolosiPassatoMeseAnno: "",
                classificazioneRelazionePrecedentiTrattamenti: "",
                agenteEziologico: "",
                diagnosiBasataEsameColturaleEscreato: "",
                diagnosiBasataEsameColturaleAltroMateriale: "",
                diagnosiBasataEsameDirettoEscreato: "",
                diagnosiBasataEsameDirettoAltroMateriale: "",
                diagnosiBasataIdentificazioneAcidoNucleico: "",
                diagnosiBasataClinica: "",
                diagnosiBasataMantoux: "",
                diagnosiBasataRxToraceEsamiStrumentali: "",
                diagnosiBasataRispostaSedutaAntitubercolare: "",
                diagnosiBasataRiscontroAutopticoTbAttiva: "",
                sedeAnatomica: "",
                contatti: "",
                listaContatti: [],
            },
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient() {
            return UtilityMixin.methods.getLinkPatient(this.utente, this.idPaziente);
        },
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        // me.jsonData.idAnagrafica = me.idPaziente;
        // let malattiaInfettivaSegnalazione = sessionStorage.getItem("MalattiaInfettivaSegnalazione");
        // let malattiaInfettiva = {};
        // if (malattiaInfettivaSegnalazione !== null && malattiaInfettivaSegnalazione !== "null") {
        //   malattiaInfettiva = JSON.parse(malattiaInfettivaSegnalazione);
        //   me.setDatiMalattia(malattiaInfettiva);
        // }
        // sessionStorage.setItem("MalattiaInfettivaSegnalazione", null);
        // if (me.id !== "-1") {
        //   me.linkback = "/malattieinfettive/indagineepidemiologica/tubercolosimicobatteriosinontubercolari/view/" + me.id + "?idPaziente=" + me.idPaziente;
        // } else {
        //   me.linkback = "/malattieinfettive/all";
        // }
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.setDatiMedico();
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                me.showModalLoading = true;
                axios
                    .get(link + me.id)
                    .then((response) => {
                        me.jsonData = response.data.data;
                        me.showModalLoading = false;
                    })
                    .catch(() => {
                        me.showModalLoading = false;
                    });
            }
        },
    },
};
</script>
