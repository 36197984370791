<template>
	<b-modal ref="mdlChart" id="mdl-view-data" size="xl" :title="chartParams.title" scrollable centered body-class="sa-modal-chart" ok-only ok-title="Chiudi">
		<generic-chart-component :marginLeft="chartParams.bodyElement.marginLeft" :legendVisible="chartParams.bodyElement.legendVisible" :horizontalBar="chartParams.bodyElement.horizontalDirection" xAxisType="value" :chartData="chartParams.chartData"></generic-chart-component>
	</b-modal>
</template>

<script>
import GenericChartComponent from "../../utility/components/GenericChartComponent.vue";
export default {
	components: { GenericChartComponent },
	data() {
		return {
			chartParams: { title: "", headerElements: [], bodyElement: { marginLeft: 300, legendVisible: true, horizontalDirection: false }, chartData: null },
		};
	},
	methods: {
		show() {
			this.$refs.mdlChart.show();
		},
		setChartParams(chartParams) {
			console.log(chartParams);
			this.chartParams = chartParams;
		},
	},
};
</script>

<style></style>
