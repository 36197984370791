<template>
  <div style="height: 100%; overflow: auto">
    <b-card class="sa-card" header="Valutazione ingresso" header-tag="header" footer-tag="footer" title="">
      <b-table sticky-header ref="table" stacked="lg" striped hover :items="jsonData.listaPatologia" :fields="fields" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
        <template #cell(index)="item">
          {{ item.index + 1 }}
        </template>
        <template #cell(patologia)="item">
          <span v-if="item.index === 0">Prima patologia</span>
          <b-form-input id="rsaValutazioneIngressoComponentListaPatologiaPatologiaConcomitante" v-else-if="item.index + 1 === jsonData.listaPatologia.length" v-model="item.item.patologiaConcomitante"></b-form-input>
          <!-- <span v-else>{{ item.item.patologiaConcomitante }}</span> -->
          <span v-else> {{ item.item.patologiaConcomitante }} </span>
        </template>

        <template #cell(descrizionePatologia)="item">
          <b-form-input id="rsaValutazioneIngressoComponentListaPatologiaDescrizionePatologia" v-if="item.index === jsonData.listaPatologia.length - 1" v-model="item.item.descrizionePatologia"></b-form-input>
          <span class="sa-data" v-else>{{ item.item.descrizionePatologia }}</span>
        </template>
        <template #cell(codiceIcdIx)="item">
          <b-input-group v-if="item.index === jsonData.listaPatologia.length - 1">
            <b-form-input v-if="item.index === jsonData.listaPatologia.length - 1 && codiceScelto === ''" id="rsaValutazioneIngressoComponentListaPatologiaCodiceIcdIx" v-model="item.item.codiceIcdIx"></b-form-input>
            <b-form-input id="rsaValutazioneIngressoComponentCodiceScelto" v-else-if="!codiceScelto == '' && item.index === jsonData.listaPatologia.length - 1" v-model="codiceScelto">{{ codiceScelto }} </b-form-input>
            <b-button size="sm" variant="" @click="onClickBtnRicercaIcdIx(item.index)"><b-icon icon="search"></b-icon></b-button>
          </b-input-group>
          <span v-else> {{ item.item.codiceIcdIx }}</span>
        </template>
        <template #cell(actions)="row">
          <b-button v-if="row.index === jsonData.listaPatologia.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddPatologia(jsonData.listaPatologia, row.item)">
            <b-icon icon="plus"></b-icon>
          </b-button>
          <b-button v-if="row.index !== jsonData.listaPatologia.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDeletePatologia(jsonData.listaPatologia, row)">
            <b-icon icon="trash"></b-icon>
          </b-button>
        </template>
      </b-table>
      <b-modal ref="mdlCodiciIcdIx" id="mdlCodiciIcdIx" title="CODICI ICD-IX" size="xl" @ok="onOkMdlCodiciIcdIx"><codici-icdIx-listComponent @update="getSelectedRow"></codici-icdIx-listComponent> </b-modal>
    </b-card>
  </div>
</template>
<script>
import CodiciIcdIxListComponent from "../../configurazione/components/CodiciIcdIxListComponent.vue";
export default {
  components: {
    CodiciIcdIxListComponent,
  },
  data() {
    return {
      pathResource: "/serviziicdix",
      listKey: 0,
      rows: 0,
      perPage: 50,
      currentPage: 1,
      rigaSelezionata: [],
      patologiaScelta: {},
      codiceOption: [],
      codiceScelto: "",
      jsonData: {
        listaPatologia: [
          {
            patologia: "",
            descrizionePatologia: "",
            codiceIcdIx: "",
          },
        ],
      },
      fields: [
        { label: "", key: "index" },
        {
          label: "Patologia",
          key: "patologia",
        },
        {
          label: "Descrizione Patologia",
          key: "descrizionePatologia",
        },
        {
          label: "Codice ICDIX",
          key: "codiceIcdIx",
          sortable: false,
        },
        {
          label: "",
          key: "actions",
        },
      ],
    };
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
  },
  methods: {
    onAddPatologia(array, value) {
      let me = this;
      me.codiceScelto = "";
      if (me.rigaSelezionata[0]) {
        value.descrizionePatologia = me.rigaSelezionata[0].descrizioneIcdix;
        value.codiceIcdIx = me.rigaSelezionata[0].codiceIcdix;
        me.rigaSelezionata = [];
      }
      if (value.codiceIcdIx !== "") {
        array.push({
          patologiaConcomitante: "",
          descrizionePatologia: "",
          codiceIcdIx: "",
        });
        me.onUpdate();
      } else {
        this.$bvModal
          .msgBoxOk("Campi OBBLIGATORI!", {
            title: "ATTENZIONE",
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "sa-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
          })
          .then((value) => {
            console.log(value);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    getSelectedRow(items) {
      let me = this;
      me.rigaSelezionata = items;
    },
    onClickBtnRicercaIcdIx(index) {
      let me = this;
      me.indexSelezionato = index;
      me.$refs.mdlCodiciIcdIx.show();
    },
    onOkMdlCodiciIcdIx() {
      let me = this;
      me.codiceScelto = me.rigaSelezionata[0].codiceIcdix;
      me.onAddPatologia(me.jsonData.listaPatologia, me.jsonData.listaPatologia.at(-1));
    },
    onDeletePatologia(array, row) {
      let me = this;
      if (row.index != array.length) {
        this.$bvModal
          .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
            title: "Titolo",
            buttonSize: "sm",
            okVariant: "secondary",
            cancelVariant: "danger",
            okTitle: "SI",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              array.splice(row.index, 1);
              me.onUpdate();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onUpdate() {
      let me = this;
      me.$emit("updatePatologia", me.jsonData.listaPatologia);
    },
  },
};
</script>

<style></style>
