<template>
    <div class="sa-tab-scroll">
        <b-row>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card" header="Dati Evento" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.events.lblEventDate") }}</label>
                            <date-picker format="DD-MM-YYYY hh:mm" value-type="timestamp" v-model="jsonData.dataEvento" type="datetime" disabled></date-picker>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card class="sa-card" header="Anamnesi Fisiologica" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Alcool:</label>
                            <b-form-select v-model="jsonData.anamnesiFisiologicaAlcool" :options="alcoolOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Fumo (Sigarette/Die):</label>
                            <b-form-input v-model="jsonData.anamnesiFisiologicaFumo"></b-form-input>
                            <!-- <b-form-select v-model="jsonData.anamnesiFisiologicaFumo" :options="fumoOptions" :value="null" value-field="value" text-field="text"></b-form-select> -->
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Sostanze:</label>
                            <b-form-select v-model="jsonData.anamnesiFisiologicaSostanzePsicoattive" :options="sostanzeOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Sonno:</label>
                            <b-form-select v-model="jsonData.anamnesiFisiologicaSonno" :options="sonnoOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Alvo:</label>
                            <b-form-select v-model="jsonData.anamnesiFisiologicaAlvo" :options="alvoOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Diuresi:</label>
                            <b-form-select v-model="jsonData.anamnesiFisiologicaDiuresi" :options="diuresiOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Gravidanze Passate:</label>
                            <b-form-textarea v-model="jsonData.anamnesiFisiologicaGravidanzePassate" no-resize rows="5"></b-form-textarea>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Altro:</label>
                            <b-form-textarea v-model="jsonData.anamnesiFisiologicaAltro" no-resize rows="5"></b-form-textarea>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card class="sa-card" header="Anamnesi Patologica Remota" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <b-form-checkbox :class="getCheckedClass(jsonData.anamnesiPatologicaRemotaMalattiePrecedenti)" :checked="getCheckedValue(jsonData.anamnesiPatologicaRemotaMalattiePrecedenti)" switch @input="onInputCheckbox($event, 'anamnesiPatologicaRemotaMalattiePrecedenti')">Malattie Precedenti</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                            <label class="sa-label-data">Descrizione:</label>
                            <b-form-input v-model="jsonData.anamnesiPatologicaRemotaMalattiePrecedentiDettaglio" :disabled="jsonData.anamnesiPatologicaRemotaMalattiePrecedenti !== 'SI'"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <b-form-checkbox :class="getCheckedClass(jsonData.anamnesiPatologicaRemotaTrasumiPrecedenti)" :checked="getCheckedValue(jsonData.anamnesiPatologicaRemotaTrasumiPrecedenti)" switch @input="onInputCheckbox($event, 'anamnesiPatologicaRemotaTrasumiPrecedenti')">Traumi Precedenti</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                            <label class="sa-label-data">Descrizione:</label>
                            <b-form-input v-model="jsonData.anamnesiPatologicaRemotaTrasumiPrecedentiDettaglio" :disabled="jsonData.anamnesiPatologicaRemotaTrasumiPrecedenti !== 'SI'"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <b-form-checkbox :class="getCheckedClass(jsonData.anamnesiPatologicaAllergie)" :checked="getCheckedValue(jsonData.anamnesiPatologicaAllergie)" switch @input="onInputCheckbox($event, 'anamnesiPatologicaAllergie')">Allergie</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                            <label class="sa-label-data">Descrizione:</label>
                            <b-form-input v-model="jsonData.anamnesiPatologicaAllergieDettaglio" :disabled="jsonData.anamnesiPatologicaAllergie !== 'SI'"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <b-form-checkbox :class="getCheckedClass(jsonData.anamnesiPatologicaPsiche)" :checked="getCheckedValue(jsonData.anamnesiPatologicaPsiche)" switch @input="onInputCheckbox($event, 'anamnesiPatologicaPsiche')">Psiche</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                            <label class="sa-label-data">Descrizione:</label>
                            <b-form-input v-model="jsonData.anamnesiPatologicaPsicheDettaglio" :disabled="jsonData.anamnesiPatologicaPsiche !== 'SI'"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <b-form-checkbox :class="getCheckedClass(jsonData.anamnesiPatologicaTbc)" :checked="getCheckedValue(jsonData.anamnesiPatologicaTbc)" switch @input="onInputCheckbox($event, 'anamnesiPatologicaTbc')">TBC</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                            <label class="sa-label-data">Descrizione:</label>
                            <b-form-input v-model="jsonData.anamnesiPatologicaTbcDettaglio" :disabled="jsonData.anamnesiPatologicaTbc !== 'SI'"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <b-form-checkbox :class="getCheckedClass(jsonData.anamnesiPatologicaApparatoUrogenitale)" :checked="getCheckedValue(jsonData.anamnesiPatologicaApparatoUrogenitale)" switch @input="onInputCheckbox($event, 'anamnesiPatologicaApparatoUrogenitale')">Apparato Urogenitale</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                            <label class="sa-label-data">Descrizione:</label>
                            <b-form-input v-model="jsonData.anamnesiPatologicaApparatoUrogenitaleDettaglio" :disabled="jsonData.anamnesiPatologicaApparatoUrogenitale !== 'SI'"></b-form-input>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card class="sa-card" header="Anamnesi Patologica Prossima" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Descrizione:</label>
                            <b-form-textarea v-model="jsonData.anamnesiPatologicaProssima" no-resize rows="5"></b-form-textarea>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <b-form-checkbox :class="getCheckedClass(jsonData.organiApparatiCoinvolti)" :checked="getCheckedValue(jsonData.organiApparatiCoinvolti)" switch @input="onInputCheckbox($event, 'organiApparatiCoinvolti')">Apparati Coinvolti</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <b-form-checkbox :class="getCheckedClass(jsonData.organiApparatiCoinvoltiCute)" :checked="getCheckedValue(jsonData.organiApparatiCoinvoltiCute)" switch @input="onInputCheckbox($event, 'organiApparatiCoinvoltiCute')" :disabled="jsonData.organiApparatiCoinvolti !== 'SI'">Cute</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <b-form-checkbox :class="getCheckedClass(jsonData.organiApparatiCoinvoltiOcchi)" :checked="getCheckedValue(jsonData.organiApparatiCoinvoltiOcchi)" switch @input="onInputCheckbox($event, 'organiApparatiCoinvoltiOcchi')" :disabled="jsonData.organiApparatiCoinvolti !== 'SI'">Occhi</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <b-form-checkbox :class="getCheckedClass(jsonData.organiApparatiCoinvoltiOrecchi)" :checked="getCheckedValue(jsonData.organiApparatiCoinvoltiOrecchi)" switch @input="onInputCheckbox($event, 'organiApparatiCoinvoltiOrecchi')" :disabled="jsonData.organiApparatiCoinvolti !== 'SI'">Orecchi</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <b-form-checkbox :class="getCheckedClass(jsonData.organiApparatiCoinvoltiDentatura)" :checked="getCheckedValue(jsonData.organiApparatiCoinvoltiDentatura)" switch @input="onInputCheckbox($event, 'organiApparatiCoinvoltiDentatura')">Dentatura</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <b-form-checkbox :class="getCheckedClass(jsonData.organiApparatiCoinvoltiGolaNaso)" :checked="getCheckedValue(jsonData.organiApparatiCoinvoltiGolaNaso)" switch @input="onInputCheckbox($event, 'organiApparatiCoinvoltiGolaNaso')">Gola Naso/Gola</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <b-form-checkbox :class="getCheckedClass(jsonData.organiApparatiCoinvoltiBronchiPolmoni)" :checked="getCheckedValue(jsonData.organiApparatiCoinvoltiBronchiPolmoni)" switch @input="onInputCheckbox($event, 'organiApparatiCoinvoltiBronchiPolmoni')">Bronchi e Polmoni</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <b-form-checkbox :class="getCheckedClass(jsonData.organiApparatiCoinvoltiApparatoCardiovascolare)" :checked="getCheckedValue(jsonData.organiApparatiCoinvoltiApparatoCardiovascolare)" switch @input="onInputCheckbox($event, 'organiApparatiCoinvoltiApparatoCardiovascolare')">Apparato Cardiovascolare</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <b-form-checkbox :class="getCheckedClass(jsonData.organiApparatiCoinvoltiStomaco)" :checked="getCheckedValue(jsonData.organiApparatiCoinvoltiStomaco)" switch @input="onInputCheckbox($event, 'organiApparatiCoinvoltiStomaco')">Stomaco</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <b-form-checkbox :class="getCheckedClass(jsonData.organiApparatiCoinvoltiIntestino)" :checked="getCheckedValue(jsonData.organiApparatiCoinvoltiIntestino)" switch @input="onInputCheckbox($event, 'organiApparatiCoinvoltiIntestino')">Apparato Digerente</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <b-form-checkbox :class="getCheckedClass(jsonData.organiApparatiCoinvoltiFegato)" :checked="getCheckedValue(jsonData.organiApparatiCoinvoltiFegato)" switch @input="onInputCheckbox($event, 'organiApparatiCoinvoltiFegato')">Fegato</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <b-form-checkbox :class="getCheckedClass(jsonData.organiApparatiCoinvoltiApparatoMuscoloscheletrico)" :checked="getCheckedValue(jsonData.organiApparatiCoinvoltiApparatoMuscoloscheletrico)" switch @input="onInputCheckbox($event, 'organiApparatiCoinvoltiApparatoMuscoloscheletrico')">Apparato Muscoloscheletrico</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <b-form-checkbox :class="getCheckedClass(jsonData.organiApparatiCoinvoltiMetabolismo)" :checked="getCheckedValue(jsonData.organiApparatiCoinvoltiMetabolismo)" switch @input="onInputCheckbox($event, 'organiApparatiCoinvoltiMetabolismo')">Metabolismo</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <b-form-checkbox :class="getCheckedClass(jsonData.organiApparatiCoinvoltiSnc)" :checked="getCheckedValue(jsonData.organiApparatiCoinvoltiSnc)" switch @input="onInputCheckbox($event, 'organiApparatiCoinvoltiSnc')">SNC (Sistema Nervoso Centrale)</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <b-form-checkbox :class="getCheckedClass(jsonData.organiApparatiCoinvoltiSnp)" :checked="getCheckedValue(jsonData.organiApparatiCoinvoltiSnp)" switch @input="onInputCheckbox($event, 'organiApparatiCoinvoltiSnp')">SNP (Sistema Nervoso Periferico)</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Altro:</label>
                            <b-form-textarea v-model="jsonData.organiApparatiCoinvoltiAltro" no-resize rows="5" :disabled="jsonData.organiApparatiCoinvolti !== 'SI'"></b-form-textarea>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card class="sa-card" header="Esame Obiettivo" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Altezza:</label>
                            <b-form-input v-model="jsonData.altezza"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Peso:</label>
                            <b-form-input v-model="jsonData.peso"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Polso:</label>
                            <b-form-input v-model="jsonData.polso"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Pressione Arteriosa:</label>
                            <b-form-input v-model="jsonData.pressioneArteriosa"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">SpO2:</label>
                            <b-form-input v-model="jsonData.spo2"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Temperatura:</label>
                            <b-form-input v-model="jsonData.temperatira"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Altro:</label>
                            <b-form-textarea v-model="jsonData.temperatiraDettaglio" no-resize rows="5"></b-form-textarea>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <b-form-checkbox :class="getCheckedClass(jsonData.cuteNdp)" :checked="getCheckedValue(jsonData.cuteNdp)" switch @input="onInputCheckbox($event, 'cuteNdp')">Cute NDP</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                            <label class="sa-label-data">Cute:</label>
                            <b-form-input v-model="jsonData.cute" :disabled="disabledCute"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <b-form-checkbox :class="getCheckedClass(jsonData.apparatoMuscoloscheletricoNdp)" :checked="getCheckedValue(jsonData.apparatoMuscoloscheletricoNdp)" switch @input="onInputCheckbox($event, 'apparatoMuscoloscheletricoNdp')">Apparato Muscoloscheletrico NDP</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                            <label class="sa-label-data">Apparato Muscoloscheletrico:</label>
                            <b-form-input v-model="jsonData.apparatoMuscoloscheletrico" :disabled="disabledApparatoMuscoloscheletrico"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <b-form-checkbox :class="getCheckedClass(jsonData.apparatoLinfonodaleNdp)" :checked="getCheckedValue(jsonData.apparatoLinfonodaleNdp)" switch @input="onInputCheckbox($event, 'apparatoLinfonodaleNdp')">Apparato Linfonodale NDP</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                            <label class="sa-label-data">Apparato Linfonodale:</label>
                            <b-form-input v-model="jsonData.apparatoLinfonodale" :disabled="disabledApparatoLinfonodale"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <b-form-checkbox :class="getCheckedClass(jsonData.capoColloNdp)" :checked="getCheckedValue(jsonData.capoColloNdp)" switch @input="onInputCheckbox($event, 'capoColloNdp')">Capo e Collo NDP</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                            <label class="sa-label-data">Capo e Collo:</label>
                            <b-form-input v-model="jsonData.capoCollo" :disabled="disabledCapoCollo"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <b-form-checkbox :class="getCheckedClass(jsonData.toraceNdp)" :checked="getCheckedValue(jsonData.toraceNdp)" switch @input="onInputCheckbox($event, 'toraceNdp')">Torace NDP</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                            <label class="sa-label-data">Torace:</label>
                            <b-form-input v-model="jsonData.torace" :disabled="disabledTorace"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <b-form-checkbox :class="getCheckedClass(jsonData.cuoreNdp)" :checked="getCheckedValue(jsonData.cuoreNdp)" switch @input="onInputCheckbox($event, 'cuoreNdp')">Cuore NDP</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                            <label class="sa-label-data">Cuore:</label>
                            <b-form-input v-model="jsonData.cuore" :disabled="disabledCuore"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <b-form-checkbox :class="getCheckedClass(jsonData.addomeNdp)" :checked="getCheckedValue(jsonData.addomeNdp)" switch @input="onInputCheckbox($event, 'addomeNdp')">Addome NDP</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                            <label class="sa-label-data">Addome:</label>
                            <b-form-input v-model="jsonData.addome" :disabled="disabledAddome"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <b-form-checkbox :class="getCheckedClass(jsonData.artiNdp)" :checked="getCheckedValue(jsonData.artiNdp)" switch @input="onInputCheckbox($event, 'artiNdp')">Arti NDP</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                            <label class="sa-label-data">Arti:</label>
                            <b-form-input v-model="jsonData.arti" :disabled="disabledArti"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <b-form-checkbox :class="getCheckedClass(jsonData.snpNdp)" :checked="getCheckedValue(jsonData.snpNdp)" switch @input="onInputCheckbox($event, 'snpNdp')">Sistema Nervoso Periferico NDP</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                            <label class="sa-label-data">Sistema Nervoso Periferico:</label>
                            <b-form-input v-model="jsonData.snp" :disabled="disabledSnp"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <b-form-checkbox :class="getCheckedClass(jsonData.sncNdp)" :checked="getCheckedValue(jsonData.sncNdp)" switch @input="onInputCheckbox($event, 'sncNdp')">Sistema Nervoso Centrale NDP</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                            <label class="sa-label-data">Sistema Nervoso Centrale:</label>
                            <b-form-input v-model="jsonData.snc" :disabled="disabledSnc"></b-form-input>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card class="sa-card" header="Diagnosi" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <b-form-checkbox :class="getCheckedClass(jsonData.diagnosiPatologiaDermatologica)" :checked="getCheckedValue(jsonData.diagnosiPatologiaDermatologica)" switch @input="onInputCheckbox($event, 'diagnosiPatologiaDermatologica')">Patologia Dermatologica</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <b-form-checkbox :class="getCheckedClass(jsonData.diagnosiPatologiaDermatologicaInfestazione)" :checked="getCheckedValue(jsonData.diagnosiPatologiaDermatologicaInfestazione)" switch @input="onInputCheckbox($event, 'diagnosiPatologiaDermatologicaInfestazione')">Patologia Dermatologica da Infestazione</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <b-form-checkbox :class="getCheckedClass(jsonData.diagnosiPatologiaMuscoloscheletrica)" :checked="getCheckedValue(jsonData.diagnosiPatologiaMuscoloscheletrica)" switch @input="onInputCheckbox($event, 'diagnosiPatologiaMuscoloscheletrica')">Patologia Muscoloscheletrica</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <b-form-checkbox :class="getCheckedClass(jsonData.diagnosiPatologiaOculistica)" :checked="getCheckedValue(jsonData.diagnosiPatologiaOculistica)" switch @input="onInputCheckbox($event, 'diagnosiPatologiaOculistica')">Patologia Oculistica</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <b-form-checkbox :class="getCheckedClass(jsonData.diagnosiPatologiaOtoiatrica)" :checked="getCheckedValue(jsonData.diagnosiPatologiaOtoiatrica)" switch @input="onInputCheckbox($event, 'diagnosiPatologiaOtoiatrica')">Patologia ORL</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <b-form-checkbox :class="getCheckedClass(jsonData.diagnosiPatologiaOrl)" :checked="getCheckedValue(jsonData.diagnosiPatologiaOrl)" switch @input="onInputCheckbox($event, 'diagnosiPatologiaOrl')">Orl</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <b-form-checkbox :class="getCheckedClass(jsonData.diagnosiMalattieRespiratorie)" :checked="getCheckedValue(jsonData.diagnosiMalattieRespiratorie)" switch @input="onInputCheckbox($event, 'diagnosiMalattieRespiratorie')">Malattie Respiratorie</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <b-form-checkbox :class="getCheckedClass(jsonData.diagnosiIpertensione)" :checked="getCheckedValue(jsonData.diagnosiIpertensione)" switch @input="onInputCheckbox($event, 'diagnosiIpertensione')">Ipertensione</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <b-form-checkbox :class="getCheckedClass(jsonData.diagnosiCardiopatie)" :checked="getCheckedValue(jsonData.diagnosiCardiopatie)" switch @input="onInputCheckbox($event, 'diagnosiCardiopatie')">Cardiopatie</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <b-form-checkbox :class="getCheckedClass(jsonData.diagnosiMalattieCardiovascolariAltre)" :checked="getCheckedValue(jsonData.diagnosiMalattieCardiovascolariAltre)" switch @input="onInputCheckbox($event, 'diagnosiMalattieCardiovascolariAltre')">Altre Malattie Cardiovascolari</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <b-form-checkbox :class="getCheckedClass(jsonData.diagnosiDiabete)" :checked="getCheckedValue(jsonData.diagnosiDiabete)" switch @input="onInputCheckbox($event, 'diagnosiDiabete')">Diabete</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <b-form-checkbox :class="getCheckedClass(jsonData.diagnosiAltreMalattieMetabolicheEndocrine)" :checked="getCheckedValue(jsonData.diagnosiAltreMalattieMetabolicheEndocrine)" switch @input="onInputCheckbox($event, 'diagnosiAltreMalattieMetabolicheEndocrine')">Altre Malattie Metaboliche/Endocrine</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <b-form-checkbox :class="getCheckedClass(jsonData.diagnosiMalattieGastroduodenali)" :checked="getCheckedValue(jsonData.diagnosiMalattieGastroduodenali)" switch @input="onInputCheckbox($event, 'diagnosiMalattieGastroduodenali')">Malattie Gastroduodenali</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <b-form-checkbox :class="getCheckedClass(jsonData.diagnosiMalattieIntestinali)" :checked="getCheckedValue(jsonData.diagnosiMalattieIntestinali)" switch @input="onInputCheckbox($event, 'diagnosiMalattieIntestinali')">Malattie Intestinali</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <b-form-checkbox :class="getCheckedClass(jsonData.diagnosiEpatopatie)" :checked="getCheckedValue(jsonData.diagnosiEpatopatie)" switch @input="onInputCheckbox($event, 'diagnosiEpatopatie')">Epatopatie</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <b-form-checkbox :class="getCheckedClass(jsonData.diagnosiMalattieNeurologiche)" :checked="getCheckedValue(jsonData.diagnosiMalattieNeurologiche)" switch @input="onInputCheckbox($event, 'diagnosiMalattieNeurologiche')">Malattie Neurologiche</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <b-form-checkbox :class="getCheckedClass(jsonData.diagnosiMalattiePsichiatriche)" :checked="getCheckedValue(jsonData.diagnosiMalattiePsichiatriche)" switch @input="onInputCheckbox($event, 'diagnosiMalattiePsichiatriche')">Malattie Psichiatriche</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <b-form-checkbox :class="getCheckedClass(jsonData.diagnosiMalattieUrologiche)" :checked="getCheckedValue(jsonData.diagnosiMalattieUrologiche)" switch @input="onInputCheckbox($event, 'diagnosiMalattieUrologiche')">Malattie Urologiche</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Diagnosi:</label>
                            <b-form-textarea v-model="jsonData.diagnosi" no-resize rows="5"></b-form-textarea>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card class="sa-card" header="Terapia" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <b-form-checkbox :class="getCheckedClass(jsonData.terapiaMedicazione)" :checked="getCheckedValue(jsonData.terapiaMedicazione)" switch @input="onInputCheckbox($event, 'terapiaMedicazione')">Medicazioni</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <b-form-checkbox :class="getCheckedClass(jsonData.terapiaFans)" :checked="getCheckedValue(jsonData.terapiaFans)" switch @input="onInputCheckbox($event, 'terapiaFans')">FANS</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <b-form-checkbox :class="getCheckedClass(jsonData.terapiaAntipiretici)" :checked="getCheckedValue(jsonData.terapiaAntipiretici)" switch @input="onInputCheckbox($event, 'terapiaAntipiretici')">Antipiretici</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <b-form-checkbox :class="getCheckedClass(jsonData.terapiaAntidolorifici)" :checked="getCheckedValue(jsonData.terapiaAntidolorifici)" switch @input="onInputCheckbox($event, 'terapiaAntidolorifici')">Antidolorifici</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <b-form-checkbox :class="getCheckedClass(jsonData.terapiaAntibiotici)" :checked="getCheckedValue(jsonData.terapiaAntibiotici)" switch @input="onInputCheckbox($event, 'terapiaAntibiotici')">Antibiotici</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <b-form-checkbox :class="getCheckedClass(jsonData.terapiaAntimicotici)" :checked="getCheckedValue(jsonData.terapiaAntimicotici)" switch @input="onInputCheckbox($event, 'terapiaAntimicotici')">Antimicotici</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <b-form-checkbox :class="getCheckedClass(jsonData.terapiaCortisonici)" :checked="getCheckedValue(jsonData.terapiaCortisonici)" switch @input="onInputCheckbox($event, 'terapiaCortisonici')">Cortisonici</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <b-form-checkbox :class="getCheckedClass(jsonData.terapiaAntidiabetici)" :checked="getCheckedValue(jsonData.terapiaAntidiabetici)" switch @input="onInputCheckbox($event, 'terapiaAntidiabetici')">Antidiabetici</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <b-form-checkbox :class="getCheckedClass(jsonData.terapiaAntiipertensivi)" :checked="getCheckedValue(jsonData.terapiaAntiipertensivi)" switch @input="onInputCheckbox($event, 'terapiaAntiipertensivi')">Antiipertensivi</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <b-form-checkbox :class="getCheckedClass(jsonData.terapiaAltriCardiovascolari)" :checked="getCheckedValue(jsonData.terapiaAltriCardiovascolari)" switch @input="onInputCheckbox($event, 'terapiaAltriCardiovascolari')">Altri Cardiovascolari</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <b-form-checkbox :class="getCheckedClass(jsonData.terapiaAntiacidiPpiAntireflusso)" :checked="getCheckedValue(jsonData.terapiaAntiacidiPpiAntireflusso)" switch @input="onInputCheckbox($event, 'terapiaAntiacidiPpiAntireflusso')">Antiacidi/PPI/Antireflusso</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <b-form-checkbox :class="getCheckedClass(jsonData.terapiaAntidiarroici)" :checked="getCheckedValue(jsonData.terapiaAntidiarroici)" switch @input="onInputCheckbox($event, 'terapiaAntidiarroici')">Antidiarroici</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <b-form-checkbox :class="getCheckedClass(jsonData.terapiaAntispastici)" :checked="getCheckedValue(jsonData.terapiaAntispastici)" switch @input="onInputCheckbox($event, 'terapiaAntispastici')">Antispastici</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <b-form-checkbox :class="getCheckedClass(jsonData.terapiaLassativi)" :checked="getCheckedValue(jsonData.terapiaLassativi)" switch @input="onInputCheckbox($event, 'terapiaLassativi')">Lassativi</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <b-form-checkbox :class="getCheckedClass(jsonData.terapiaFliuidificantiMucolitici)" :checked="getCheckedValue(jsonData.terapiaFliuidificantiMucolitici)" switch @input="onInputCheckbox($event, 'terapiaFliuidificantiMucolitici')">Fluidificanti/Mucolitici</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <b-form-checkbox :class="getCheckedClass(jsonData.terapiaBroncodilatatori)" :checked="getCheckedValue(jsonData.terapiaBroncodilatatori)" switch @input="onInputCheckbox($event, 'terapiaBroncodilatatori')">Broncodilatatori</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <b-form-checkbox :class="getCheckedClass(jsonData.terapiaAntidepressivi)" :checked="getCheckedValue(jsonData.terapiaAntidepressivi)" switch @input="onInputCheckbox($event, 'terapiaAntidepressivi')">Antidepressivi</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <b-form-checkbox :class="getCheckedClass(jsonData.terapiaNeurolettici)" :checked="getCheckedValue(jsonData.terapiaNeurolettici)" switch @input="onInputCheckbox($event, 'terapiaNeurolettici')">Neurolettici</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <b-form-checkbox :class="getCheckedClass(jsonData.terapiaAntiparassitosi)" :checked="getCheckedValue(jsonData.terapiaAntiparassitosi)" switch @input="onInputCheckbox($event, 'terapiaAntiparassitosi')">Antiparassitosi</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <b-form-checkbox :class="getCheckedClass(jsonData.terapiaAntiTbc)" :checked="getCheckedValue(jsonData.terapiaAntiTbc)" switch @input="onInputCheckbox($event, 'terapiaAntiTbc')">Anti Tbc</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Terapia:</label>
                            <b-form-textarea v-model="jsonData.terapiaAltri" no-resize rows="5"></b-form-textarea>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card class="sa-card" header="Note" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-form-textarea v-model="jsonData.note" no-resize rows="5"></b-form-textarea>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import UtilityMixins from "../../../../utility/UtilityMixin";

export default {
    mixins: [UtilityMixins],
    components: { DatePicker },
    props: {
        tipoEvento: String,
        gruppo: String,
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
        jsonData: {
            type: Object,
        },
    },
    data() {
        return {
            id: "-1",
            linkback: null,
            tabIndex: 0,
            childKey: 0,
            pathResource: "/pazienteeventianamnesi",
            currentPage: 1,
            perPage: 30,
            filtro: { page: 1, forPage: 30 },
            showModalLoading: false,
            disabledCute: false,
            disabledApparatoMuscoloscheletrico: false,
            disabledApparatoLinfonodale: false,
            disabledCapoCollo: false,
            disabledTorace: false,
            disabledCuore: false,
            disabledAddome: false,
            disabledArti: false,
            disabledSnp: false,
            disabledSnc: false,
            siNoOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "SI", text: "SI" },
                { value: "NO", text: "NO" },
            ],
            alcoolOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "NO", text: "NO" },
                { value: "MODERATO", text: "MODERATO" },
                { value: "ECCESSIVO", text: "ECCESSIVO" },
                { value: "QUANTITA'/DIE", text: "QUANTITA'/DIE" },
            ],
            fumoOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "NO", text: "NO" },
                { value: "SIG/DIE", text: "SIG/DIE" },
                { value: "ALTRO", text: "ALTRO" },
            ],
            sostanzeOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "NO", text: "NO" },
                { value: "SI", text: "SI" },
                { value: "SALTUATARIE", text: "SALTUATARIE" },
            ],
            sonnoOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "REGOLARE", text: "REGOLARE" },
                { value: "INSONNIA SALTUARIA", text: "INSONNIA SALTUARIA" },
                { value: "INSONNIA ABITUALE", text: "INSONNIA ABITUALE" },
                { value: "INCUBI", text: "INCUBI" },
            ],
            alvoOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "REG.", text: "REG." },
                { value: "STIPSI", text: "STIPSI" },
                { value: "DIARREA", text: "DIARREA" },
            ],
            diuresiOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "REGOLARE", text: "REGOLARE" },
                { value: "NON REGOLARE", text: "NON REGOLARE" },
            ],
        };
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
        "jsonData.cuteNdp": function (value) {
            let me = this;
            me.disabledCute = false;
            if (value === "SI") {
                me.disabledCute = true;
                me.jsonData.cute = "";
            }
        },
        "jsonData.apparatoMuscoloscheletricoNdp": function (value) {
            let me = this;
            me.disabledApparatoMuscoloscheletrico = false;
            if (value === "SI") {
                me.disabledApparatoMuscoloscheletrico = true;
                me.jsonData.apparatoMuscoloscheletrico = "";
            }
        },
        "jsonData.apparatoLinfonodaleNdp": function (value) {
            let me = this;
            me.disabledApparatoLinfonodale = false;
            if (value === "SI") {
                me.disabledApparatoLinfonodale = true;
                me.jsonData.apparatoLinfonodale = "";
            }
        },
        "jsonData.capoColloNdp": function (value) {
            let me = this;
            me.disabledCapoCollo = false;
            if (value === "SI") {
                me.disabledCapoCollo = true;
                me.jsonData.capoCollo = "";
            }
        },
        "jsonData.toraceNdp": function (value) {
            let me = this;
            me.disabledTorace = false;
            if (value === "SI") {
                me.disabledTorace = true;
                me.jsonData.torace = "";
            }
        },
        "jsonData.cuoreNdp": function (value) {
            let me = this;
            me.disabledCuore = false;
            if (value === "SI") {
                me.disabledCuore = true;
                me.jsonData.cuore = "";
            }
        },
        "jsonData.addomeNdp": function (value) {
            let me = this;
            me.disabledAddome = false;
            if (value === "SI") {
                me.disabledAddome = true;
                me.jsonData.addome = "";
            }
        },
        "jsonData.artiNdp": function (value) {
            let me = this;
            me.disabledArti = false;
            if (value === "SI") {
                me.disabledArti = true;
                me.jsonData.arti = "";
            }
        },
        "jsonData.snpNdp": function (value) {
            let me = this;
            me.disabledSnp = false;
            if (value === "SI") {
                me.disabledSnp = true;
                me.jsonData.snp = "";
            }
        },
        "jsonData.sncNdp": function (value) {
            let me = this;
            me.disabledSnc = false;
            if (value === "SI") {
                me.disabledSnc = true;
                me.jsonData.snc = "";
            }
        },
    },
    updated() {},
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.jsonData.idAccettazione = this.$route.query.idAccettazione;
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
                me.disabledCute = me.jsonData.cuteNdp === "SI" ? true : false;
                me.disabledApparatoMuscoloscheletrico = me.jsonData.apparatoMuscoloscheletricoNdp === "SI" ? true : false;
                me.disabledApparatoLinfonodale = me.jsonData.apparatoLinfonodaleNdp === "SI" ? true : false;
                me.disabledCapoCollo = me.jsonData.capoColloNdp === "SI" ? true : false;
                me.disabledTorace = me.jsonData.toraceNdp === "SI" ? true : false;
                me.disabledCuore = me.jsonData.cuoreNdp === "SI" ? true : false;
                me.disabledAddome = me.jsonData.addomeNdp === "SI" ? true : false;
                me.disabledArti = me.jsonData.artiNdp === "SI" ? true : false;
                me.disabledSnp = me.jsonData.snpNdp === "SI" ? true : false;
                me.disabledSnc = me.jsonData.sncNdp === "SI" ? true : false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                axios
                    .get(link)
                    .then((response) => {
                        this.$emit("update", response.data.data);
                        me.disabledCute = response.data.data.cuteNdp === "SI" ? true : false;
                        me.disabledApparatoMuscoloscheletrico = response.data.data.apparatoMuscoloscheletricoNdp === "SI" ? true : false;
                        me.disabledApparatoLinfonodale = response.data.data.apparatoLinfonodaleNdp === "SI" ? true : false;
                        me.disabledCapoCollo = response.data.data.capoColloNdp === "SI" ? true : false;
                        me.disabledTorace = response.data.data.toraceNdp === "SI" ? true : false;
                        me.disabledCuore = response.data.data.cuoreNdp === "SI" ? true : false;
                        me.disabledAddome = response.data.data.addomeNdp === "SI" ? true : false;
                        me.disabledArti = response.data.data.artiNdp === "SI" ? true : false;
                        me.disabledSnp = response.data.data.snpNdp === "SI" ? true : false;
                        me.disabledSnc = response.data.data.sncNdp === "SI" ? true : false;
                        me.showModalLoading = false;
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },
        getCheckedValue(value) {
            return value === "SI";
        },
        getCheckedClass(value) {
            return value === "SI" ? "sa-label-data text-success" : "sa-label-data";
        },
        onInputCheckbox(event, field) {
            // console.log(event);
            let me = this;
            me.jsonData[field] = event ? "SI" : "NO";
        },
    },
};
</script>

<style>
.sa-visita-controllo-ecg-row-custom .row div {
    padding-bottom: 3px;
}

.sa-risk-alert-danger,
.sa-risk-alert-success,
.sa-risk-alert-warning {
    height: 50px;
    width: 50px;
    border-radius: 25px;
    display: block;
    align-content: center;
    font-size: 1.6rem !important;
    text-align: center;
    padding-top: 8px;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
}

.sa-risk-alert-danger {
    /* border: 2px solid red; */
    background-color: red;
    color: white;
}

.sa-risk-alert-success {
    /* border: 2px solid #28a745; */
    background-color: #28a745;
    color: white;
}

.sa-risk-alert-warning {
    /* border: 2px solid #ffc107; */
    background-color: #ffc107;
    color: white;
}
</style>
