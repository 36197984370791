<template>
    <div style="height: 100%">
        <div class="sa-flexbox" style="padding-bottom: 20px">
            <div class="sa-flexbox-body">
                <div class="b-table-sticky-header">
                    <b-table sticky-header ref="table" stacked="lg" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="row">
                            {{ row.index + 1 }}
                        </template>

                        <template #cell(profilo)="{ item }">
                            {{ item.profiloTitle || item.profilo }}
                        </template>

                        <template #cell(idDashboard)="{ item }">
                            {{ item.dashboardTitle || item.idDashboard }}
                        </template>

                        <template #cell(dashboardDescrizione)="{ item }">
                            {{ item.dashboardDescrizione || "-" }}
                        </template>

                        <template v-slot:cell(actions)="{ item }">
                            <b-container class="bv-example-row">
                                <b-button size="sm" variant="outline-success" v-b-tooltip.hover title="Modifica Associazione" @click="onClickModificaAssociazione(item)">
                                    <font-awesome-icon icon="edit" />
                                </b-button>

                                <b-button size="sm" v-b-tooltip.hover title="Elimina Associazione" variant="outline-danger" @click="onClickEliminaAssociazione(item)">
                                    <font-awesome-icon icon="trash"></font-awesome-icon>
                                </b-button>
                            </b-container>
                        </template>
                    </b-table>
                </div>
                <data-analysis-dashboard-gruppi-modal-edit-component ref="cmpDataAnalysisDashboardModalEdit" @onSave="onSaveGruppo" @onClose="onCloseGruppo" @onDeleteGruppoAssociazione="loadData" />
            </div>
            <div class="sa-list-component-footer">
                <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col lg="3">
                        <p>Record Totali:{{ rows }}</p>
                    </b-col>
                    <b-col lg="9">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                    </b-col>
                </b-row>
            </div>
        </div>
    </div>
</template>
<script>
import DataAnalysisDashboardGruppiModalEditComponent from "./DataAnalysisDashboardGruppiModalEditComponent.vue";
import UtilityMixin from "../../utility/UtilityMixin";
export default {
    props: {},
    mixins: [UtilityMixin],
    components: { DataAnalysisDashboardGruppiModalEditComponent },
    data() {
        return {
            pathResource: "/dataanalysisgruppodashboard",
            perPage: 50,
            currentPage: 1,
            rows: 0,
            btnNuovoGruppoHidden: false,
            showModalLoading: false,
            listKey: 0,
            filtro: {},
            items: [],
            fields: [
                {
                    label: "",
                    key: "index",
                },
                {
                    label: "Profilo",
                    key: "profilo",
                },
                {
                    label: "Dashboard",
                    key: "idDashboard",
                },
                {
                    label: "Descrizione",
                    key: "dashboardDescrizione",
                },
                { class: "sa-table-column-action-double", key: "actions", label: "" },
            ],
        };
    },
    mounted() {
        this.loadData();
    },

    watch: {
        currentPage() {
            let me = this;
            me.loadData();
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            let sessionStorage = window.sessionStorage;
            if (sessionStorage["filtro"]) {
                me.filtro = JSON.parse(sessionStorage["filtro"]);
            }
            me.filtro.page = me.currentPage;
            me.filtro.forPage = me.perPage;
            UtilityMixin.methods.loadDataResources(me.pathResource, me.filtro, me.loadDataSuccess, me.loadDataError);
        },
        loadDataSuccess(response) {
            let me = this;
            me.items = response.data.data.list;
            me.rows = response.data.data.recordsNumber;
            this.showModalLoading = false;
            me.showModalLoading = false;
            me.$emit("afterLoadData");
        },
        loadDataError(error) {
            let me = this;
            console.error("Errore nel caricamento dei dati:", error);
            me.showModalLoading = false;
            me.$emit("afterLoadData");
            me.$bvToast.toast("Errore nel caricamento dei dati", {
                title: "Errore",
                variant: "danger",
                solid: true,
            });
        },
        onCloseModal() {
            let me = this;
            me.loadData();
        },
        onClickModificaAssociazione(item) {
            let me = this;

            me.$refs.cmpDataAnalysisDashboardModalEdit.show(item);
        },
        onClickNuovoGruppo() {
            let me = this;

            me.$refs.cmpDataAnalysisDashboardModalEdit.show({
                id: null,
                profilo: null,
                idDashboard: null,
            });
        },

        onClickEliminaAssociazione(item) {
            let me = this;
            me.$refs.cmpDataAnalysisDashboardModalEdit.onDeleteGruppoAssocizione(item);
        },

        onSaveGruppo() {
            this.loadData();
        },
        onCloseGruppo() {},
    },
};
</script>
