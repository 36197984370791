<template>
    <sa-page-layout :btnNewVisible="true" :btnRefreshVisible="true" :linkedit="linkedit" :pathResource="pathResource" :showModalLoading="showModalLoading">
        <template slot="table-filter">
            <b-form @submit.prevent="onSubmit">
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col sm="6">
                        <b-form-input v-model="filtro.codice" type="search" placeholder="Codice"></b-form-input>
                    </b-col>
                    <b-col sm="6">
                        <b-form-input v-model="filtro.descrizione" type="search" placeholder="Descrizione"></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="sa-padding-right text-right">
                        <b-button type="submit" variant="info">{{ this.$i18n.t("global.lblFind") }}</b-button>
                        <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("global.lblResetFilter") }}</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </template>
        <template slot="table-header">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" stacked="xl" striped hover :items="filterItems" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                    <template v-slot:cell(descrizione)="{ item }">
                        <router-link class="sa-edit-link" :to="'/fatturazionenumeratori/view/' + item.id">{{ item.descrizione }}</router-link>
                    </template>
                </b-table>
            </div>
        </template>
        <template slot="table-footer">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col cols="6">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from '../../../template/sa/components/layout/contents/SaPageLayout.vue'
export default{
    components: {SaPageLayout},
    computed: {
        rows() {
            return this.items.length;
        },
    },
    data(){
        return {
            perPage: 10,
            currentPage: 1,
            pathResource: "/numeratori",
            linkedit:"/fatturazionenumeratori",
            showModalLoading: false,
            items:[],
            filterItems: [],
            filtro:{codice: "", descrizione: ""},
            fields:[
                {
                    label: "Codice",
                    key: "codice",
                    thStyle: "width: 5rem",
                    sortable: true,
                },
                {
                    label: "Descrizione",
                    key: "descrizione",
                    thStyle: "width: 18rem",
                    sortable: true,
                },
                {
                    label: "Valore",
                    key: "valore",
                    thStyle: "width: 5rem",
                    sortable: true,
                },
                {
                    label: "Anno",
                    key: "anno",
                    thStyle: "width: 4rem",
                    sortable: true,
                },
                {
                    label: "Cod. Struttura",
                    key: "codiceStruttura",
                    thStyle: "width: 8rem",
                    sortable: true,
                },
                {
                    label: "Note",
                    key: "note",
                    sortable: true,
                },
            ],
        }
    },
    mounted(){
        let me = this;
        me.loadData();
    },
    methods:{
        loadData(){
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.showModalLoading = true;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];
                    me.items = response.data.data;
                    me.filterItems = response.data.data;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        onSubmit(){
            let me = this;
            me.filterItems = [];
            me.items.forEach((element) => {
                if (element.codice.toUpperCase().includes(me.filtro.codice.toUpperCase()) && element.descrizione.toUpperCase().includes(me.filtro.descrizione.toUpperCase())) {
                    me.filterItems.push(element);
                }
            });
        },
        onReset() {
            let me = this;
            me.filterItems=[];
            me.loadData();
        },
        onRefresh(){
            let me = this;
            me.loadData();
        },
    },
}
</script>