<template>
    <sa-page-layout :btnNewVisible="true" :btnRefreshVisible="true" @refresh="onRefresh" :pathResource="pathResource" :linkedit="linkedit" :showModalLoading="showModalLoading">
        <template slot="table-filter">
            <b-form @submit.prevent="onSubmit">
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col cols="8" xs="8" sm="8" md="8" lg="4" xl="4">
                        <label class="sa-label-data">Codice Fiscale Medico</label>
                        <b-form-input v-model="filtro.codiceFiscaleMedico" type="search" placeholder="Codice Fiscale Medico"></b-form-input>
                    </b-col>
                    <b-col cols="8" xs="8" sm="8" md="8" lg="4" xl="4">
                        <label class="sa-label-data">Cognome</label>
                        <b-form-input v-model="filtro.cognome" type="search" placeholder="Cognome"></b-form-input>
                    </b-col>
                    <b-col cols="8" xs="8" sm="8" md="8" lg="4" xl="4">
                        <label class="sa-label-data">Sezione</label>
                        <b-form-input v-model="filtro.sezione" type="search" placeholder="Sezione"></b-form-input>
                    </b-col>
                </b-row>
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col cols="8" xs="8" sm="8" md="8" lg="4" xl="4">
                        <label class="sa-label-data">Username</label>
                        <b-form-input v-model="filtro.username" type="search" placeholder="Username"></b-form-input>
                    </b-col>
                    <b-col cols="8" xs="8" sm="8" md="8" lg="4" xl="4">
                        <label class="sa-label-data">Descrizione Evento</label>
                        <b-form-input v-model="filtro.evento" type="search" placeholder="Descrizione Evento"></b-form-input>
                    </b-col>
                    <b-col cols="8" xs="8" sm="8" md="8" lg="4" xl="4">
                        <label class="sa-label-data">I.P.</label>
                        <b-form-input v-model="filtro.ip" type="search" placeholder="I. P."></b-form-input>
                    </b-col>
                </b-row>
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col cols="8" xs="8" sm="8" md="8" lg="4" xl="4">
                        <label class="sa-label-data">User Agent</label>
                        <b-form-input v-model="filtro.userAgent" type="search" placeholder="User Agent"></b-form-input>
                    </b-col>
                    <b-col cols="8" xs="8" sm="8" md="8" lg="4" xl="4">
                        <label class="sa-label-data">Stato</label>
                        <b-form-input v-model="filtro.stato" type="search" placeholder="Stato"></b-form-input>
                    </b-col>
                    <b-col cols="8" xs="8" sm="8" md="8" lg="4" xl="4">
                        <label class="sa-label-data">Id Risorsa</label>
                        <b-form-input v-model="filtro.idRisorsa" type="search" placeholder="Id Risorsa"></b-form-input>
                    </b-col>
                </b-row>
                <b-row class="sa-form-btn-filter">
                    <b-col class="sa-padding-right text-right">
                        <b-button type="submit" variant="info">{{ this.$i18n.t("global.lblFind") }}</b-button>
                        <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("global.lblResetFilter") }}</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </template>
        <template slot="table-header">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col cols="6">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="end" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="tbl" id="tbl" stacked="xl" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                    <template v-slot:cell(index)="row">
                        {{ row.index + 1 }}
                    </template>
                    <template v-slot:cell(altreinfo)="{ item }">
                        <b-row>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
                                <span>Username: {{ item.username }}</span>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="8" xl="8">
                                <span>Id Gruppo: {{ item.idGruppo }}</span>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
                                <span>IP: {{ item.ip }}</span>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="8" xl="8">
                                <span>Id Risorsa: {{ item.idRisorsa }}</span>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <span>User Agent: {{ item.userAgent }}</span>
                            </b-col>
                        </b-row>
                    </template>
                    <template v-slot:cell(nominativo)="{ item }">
                        <span>{{ item.cognome }} {{ item.nome }}</span>
                    </template>
                </b-table>
            </div>
        </template>
        <template slot="table-footer">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col cols="6">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
    </sa-page-layout>
</template>

<script>
import moment from "moment";
import axios from "axios";
import UtilityMixin from "../../UtilityMixin";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
    components: { SaPageLayout },
    mixins: [UtilityMixin],
    data() {
        return {
            showModalLoading: false,
            pathResource: "/logs",
            linkedit: "",
            currentPage: 1,
            perPage: 50,
            rows: null,
            filtro: { dataDal: null, dataAl: null, sezione: "", username: "", evento: "", ip: "", userAgent: null, stato: null, idRisorsa: null, operation: null, idGruppo: null },
            items: [],
            fields: [
                {
                    label: "",
                    key: "index",
                    sortable: true,
                    thStyle: "width: 5rem",
                },
                {
                    label: "Data Ora",
                    key: "dataOra",
                    sortable: true,
                    thStyle: "width: 12rem",
                    formatter: (value) => {
                        if (value) {
                            return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
                        } else {
                            return "-------";
                        }
                    },
                },
                {
                    label: "Stato",
                    key: "stato",
                    sortable: true,
                    thStyle: "width: 6rem",
                },
                {
                    label: "Sezione",
                    key: "sezione",
                    sortable: true,
                    thStyle: "width: 12rem",
                },
                {
                    label: "Descrizione Evento",
                    key: "descrizioneEvento",
                    sortable: true,
                    tdClass: "sa-wrap-break-word",
                    thStyle: "width: 12rem",
                },
                {
                    label: "Altre Info",
                    key: "altreinfo",
                    sortable: true,
                },

                {
                    label: "Operazione",
                    key: "operation",
                    sortable: true,
                    thStyle: "width: 12rem",
                },
            ],
        };
    },
    mounted() {
        let me = this;
        let sessionStorage = window.sessionStorage;
        if (sessionStorage["filtro"]) {
            me.filtro = JSON.parse(sessionStorage["filtro"]);
            me.filtro.page = me.currentPage;
            me.filtro.forPage = me.perPage;
        }
        me.loadData();
    },
    watch: {
        currentPage() {
            let me = this;
            me.loadData();
        },
    },
    methods: {
        loadData() {
            let me = this;
            console.log(me.filtro);
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.filtro.page = me.currentPage;
            me.filtro.forPage = me.perPage;
            me.showModalLoading = true;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];
                    me.rows = response.data.data.length;
                    me.items = response.data.data;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        onSubmit() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
        onReset() {
            let me = this;
            me.filtro = {};
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
    },
};
</script>

<style></style>
