<template>
	<div class="sa-array-element">
		<div v-for="element in arrayLocal" class="sa-array-element-container" :key="element">
			<div class="sa-array-element-container-left">
				<span @click="onClickRemoveArrayElement(element)"><i class="fas fa-times"></i></span>
			</div>
			<div class="sa-array-element-container-center">
				<span>{{ element }}</span>
			</div>
			<div class="sa-array-element-container-right">
				<span><i class="fas fa-chevron-right"></i></span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		arrayValue: { type: Array },
	},
	computed: {
		arrayLocal: {
			get: function () {
				return this.arrayValue;
			},
			set: function (value) {
				this.$emit("arraychange", value);
			},
		},
	},
	methods: {
		onClickRemoveArrayElement(value) {
			let me = this;
			me.arrayLocal = me.arrayLocal.filter((item) => item !== value);
		},
	},
};
</script>

<style></style>
