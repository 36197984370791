<template>
  <div style="height: 100%; overflow: auto">
    <b-card class="sa-card" header="Diario" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Nome</label>
          <b-form-input v-model="jsonData.nome" placeholder=""></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Stato</label>
          <b-form-select v-model="jsonData.stato" class="mb-2 mr-sm-2 mb-sm-0" :options="statoOption" :value="null" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Posizione:</label>
          <b-form-input v-model="jsonData.position" type="number"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">Descrizione</label>
          <b-form-textarea v-model="jsonData.descrizione" id="textarea-auto-height" placeholder="" rows="7"></b-form-textarea>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <b-form-checkbox v-model="jsonData.fisso" name="check-button" switch> Fisso </b-form-checkbox>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      pathResource: "/rsadiari",
      id: "-1",
      jsonData: { nome: "", stato: null, position: "", descrizione: "", fisso: false },
      statoOption: [
        { value: null, text: "-Selezionare Stato-" },
        { value: "ATTIVO", text: "ATTIVO" },
        { value: "DISATTIVO", text: "DISATTIVO" },
        { value: "SOSPESO", text: "SOSPESO" },
      ],
    };
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      me.$emit("beforeLoadData");
      if (me.id === "-1") {
        this.$emit("afterLoadData");
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
        axios.get(link + me.id).then((response) => {
          me.jsonData = response.data.data;
          this.$emit("afterLoadData");
        });
      }
    },
  },
};
</script>
