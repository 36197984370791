<template>
    <b-modal ref="mdlEditPrestazioneAmbulatoriale" :title="isNew ? 'Nuova Prestazione Ambulatoriale' : 'Aggiorna Prestazione Ambulatoriale'" @hidden="onCloseModal" @ok="onClickOk" centered no-close-on-backdrop no-close-on-esc hide-header-close>
        <b-row>
            <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <span class="sa-form-label">Codice Prestazione</span>
                <b-form-input v-model="jsonDataPrestazioneAmbulatoriale.codicePrestazione"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <span class="sa-form-label">Codice Offering</span>
                <b-form-input v-model="jsonDataPrestazioneAmbulatoriale.codiceOffering"></b-form-input>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <span class="sa-form-label">Tipo</span>
                <b-form-input v-model="jsonDataPrestazioneAmbulatoriale.tipo"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <span class="sa-form-label">Descrizione</span>
                <b-form-input v-model="jsonDataPrestazioneAmbulatoriale.descrizione"></b-form-input>
            </b-col>
        </b-row>
    </b-modal>
</template>

<script>
import UtilityMixin from "../../../utility/UtilityMixin";
import axios from "axios";

export default {
    mixins: [UtilityMixin],
    data() {
        return {
            isNew: true,
            pathResource: "/rsaprestazioniambulatoriali",
            jsonDataPrestazioneAmbulatoriale: {
                codicePrestazione: null,
                codiceOffering: null,
                tipo: null,
                descrizione: null,
            },
        };
    },

    methods: {
        aggiornaPrestazioneAmbulatoriale(item) {
            let me = this;
            me.isNew = false;
            me.jsonDataPrestazioneAmbulatoriale = { ...item };
            me.$refs.mdlEditPrestazioneAmbulatoriale.show();
        },

        nuovaPrestazioneAmbulatoriale() {
            let me = this;
            me.isNew = true;
            me.jsonDataPrestazioneAmbulatoriale = {
                codicePrestazione: null,
                codiceOffering: null,
                tipo: null,
                descrizione: null,
            };
            me.$refs.mdlEditPrestazioneAmbulatoriale.show();
        },

        onCloseModal() {
            let me = this;
            me.jsonDataPrestazioneAmbulatoriale = {};
            me.$emit("onClose");
        },
        onClickOk() {
            UtilityMixin.methods.saveResource(this.pathResource, this.jsonDataPrestazioneAmbulatoriale, this.saveSuccessResponse, this.saveErrorResponse);
        },
        saveSuccessResponse(response) {
            let me = this;
            me.$emit("onSave", response);
            me.$refs.mdlEditPrestazioneAmbulatoriale.hide();
            me.$bvToast.toast("Salvataggio avvenuto con successo", {
                title: "Successo",
                variant: "success",
                solid: true,
            });
        },
        saveErrorResponse(response) {
            if (response.response.status === 400) {
                this.$bvModal.msgBoxOk(response.response.data.messaggio, {
                    title: "ATTENZIONE",
                    size: "sm",
                    okVariant: "outline-danger",
                    headerClass: "sa-msg-header-danger",
                    footerClass: "p-2 border-top-0",
                    centered: true,
                });
            } else {
                this.$bvToast.toast("Errore nel salvataggio" + response.message, {
                    title: "Errore",
                    variant: "danger",
                    solid: true,
                });
            }
        },

        onDeletePrestazione(item) {
            let me = this;
            this.$bvModal
                .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
                    title: "Eliminazione Prestazione Ambulatoriale",
                    buttonSize: "sm",
                    okVariant: "danger",
                    cancelVariant: "secondary",
                    okTitle: "SI",
                    cancelTitle: "NO",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                })
                .then((value) => {
                    if (value) {
                        me.deletePrestazione(item.id);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        deletePrestazione(id) {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + id;
            axios
                .delete(link)
                .then((response) => {
                    me.showModalLoading = false;
                    me.$emit("onDelete", response);
                    me.$bvToast.toast("Eliminazione avvenuta con successo", {
                        title: "Successo",
                        variant: "success",
                        solid: true,
                    });
                })
                .catch((e) => {
                    console.log(e);
                    me.showModalLoading = false;
                    me.$bvToast.toast("Errore durante l'eliminazione", {
                        title: "Errore",
                        variant: "danger",
                        solid: true,
                    });
                });
        },
    },
};
</script>
