<template>
    <div>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Ittero:</label>
                    <b-form-select v-model="jsonData.malattiaDiventatoGiallo" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Data Inizio Malattia:</label>
                    <date-picker v-model="jsonData.dataInizioMalattia" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Ospedalizzato:</label>
                    <b-form-select v-model="jsonData.ospedalizzatoPerMalattia" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Ospedalizzato Durata:</label>
                    <!-- <b-form-select v-model="jsonData.ospedalizzatoPerMalattia" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select> -->
                    <b-form-input v-model="jsonData.ospedalizzatoPerMalattiaGiorni" :disabled="chekCondition(jsonData.ospedalizzatoPerMalattia, 'SI')"></b-form-input>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Durante le 6 settimane precedenti" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Assunzione Farmaci:</label>
                    <b-form-select v-model="jsonData.seiSettimanePrimaAssunzioneFarmaci" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text" @input="onAttivaTabella"></b-form-select>
                </b-col>
            </b-row>
            <b-row style="margin-top: 5px">
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-table sticky-header ref="tblFarmaci" stacked="xl" striped hover itemscope :items="jsonData.listaFarmaci" :fields="fieldsFarmaci" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="listaFarmaci">
                            {{ listaFarmaci.index + 1 }}
                        </template>
                        <template #cell(farmaco)="item">
                            <b-form-input v-model="item.item.farmaco" placeholder="Farmaco"></b-form-input>
                        </template>
                        <template #cell(tempo)="item">
                            <b-form-input v-model="item.item.tempo" placeholder="Tempo"></b-form-input>
                        </template>
                        <template #cell(actions)="row">
                            <b-button v-if="row.index == jsonData.listaFarmaci.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddFarmaci(jsonData.listaFarmaci, row.item)">
                                <b-icon icon="plus"></b-icon>
                            </b-button>
                            <b-button v-if="row.index !== jsonData.listaFarmaci.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(jsonData.listaFarmaci, row)">
                                <b-icon icon="trash"></b-icon>
                            </b-button>
                        </template>
                    </b-table>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Consumato Frutti di Mare:</label>
                    <b-form-select v-model="jsonData.seiSettimanePrimaConsumoFruttiMare" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Totalmente Crudi/in Parte:</label>
                    <b-form-select v-model="jsonData.seiSettimanePrimaConsumoFruttiMareCrudi" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text" :disabled="chekCondition(jsonData.seiSettimanePrimaConsumoFruttiMare, 'SI')"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Consumato Frutti di Bosco:</label>
                    <b-form-select v-model="jsonData.seiSettimanePrimaConsumoFruttiBosco" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Tipo Frutti di Bosco:</label>
                    <b-form-select v-model="jsonData.seiSettimanePrimaConsumoFruttiBoscoDettagli" :options="tipoFruttiBoscoOptions" :value="''" value-field="value" text-field="text" :disabled="chekCondition(jsonData.seiSettimanePrimaConsumoFruttiBosco, 'SI')"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Bevuto Acqua di Pozzo/Soergente:</label>
                    <b-form-select v-model="jsonData.seiSettimanePrimaConsumoAcquaPozzoSorgente" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Una/piu' Notte/i fuori Citta:</label>
                    <b-form-select v-model="jsonData.seiSettimanePrimaNotteFuoriCitta" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Dove:</label>
                    <b-form-input v-model="jsonData.seiSettimanePrimaNotteFuoriCittaDove" :disabled="chekCondition(jsonData.seiSettimanePrimaNotteFuoriCitta, 'SI')"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Quando:</label>
                    <date-picker v-model="jsonData.seiSettimanePrimaNotteFuoriCittaQuando" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="chekCondition(jsonData.seiSettimanePrimaNotteFuoriCitta, 'SI')"></date-picker>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Vaccino Epatite A" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Somministrata Almeno Una Dose:</label>
                    <b-form-select v-model="jsonData.doseVaccinoEpatiteA" :options="doseVaccinoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Tipo Vaccino Prima Dose:</label>
                    <b-form-input v-model="jsonData.doseVaccinoEpatiteAPrimaDoseTipo" :disabled="checkDoubleCondition(jsonData.doseVaccinoEpatiteA, 'SI, 1 DOSE', 'SI, 2 DOSI', 'OR')"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Data Prima Dose:</label>
                    <date-picker v-model="jsonData.doseVaccinoEpatiteAPrimaDoseData" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="checkDoubleCondition(jsonData.doseVaccinoEpatiteA, 'SI, 1 DOSE', 'SI, 2 DOSI', 'OR')"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Tipo Vaccino Seconda Dose:</label>
                    <b-form-input v-model="jsonData.doseVaccinoEpatiteASecondaDoseTipo" :disabled="chekCondition(jsonData.doseVaccinoEpatiteA, 'SI, 2 DOSI')"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Data Seconda Dose:</label>
                    <date-picker v-model="jsonData.doseVaccinoEpatiteASecondaDoseData" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="chekCondition(jsonData.doseVaccinoEpatiteA, 'SI, 2 DOSI')"></date-picker>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Durante i 6 mesi precedenti" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Somministrazione Sangue/Plasma:</label>
                    <b-form-select v-model="jsonData.seiMesiPrimaSomministrazioneSanguePlasma" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Somministrazione Altri Derivati:</label>
                    <b-form-select v-model="jsonData.seiMesiPrimaSomministrazioneAltriDerivati" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Interventi Chirurgici:</label>
                    <b-form-select v-model="jsonData.seiMesiPrimaInterventiChirurgici" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Tipo Intervento:</label>
                    <b-form-input v-model="jsonData.seiMesiPrimaInterventiChirurgiciTipo" :disabled="chekCondition(jsonData.seiMesiPrimaInterventiChirurgici, 'SI')"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Luogo Intervento:</label>
                    <b-form-select v-model="jsonData.seiMesiPrimaInterventiChirurgiciLuogo" :options="luogoInterventoOptions" :value="''" value-field="value" text-field="text" :disabled="chekCondition(jsonData.seiMesiPrimaInterventiChirurgici, 'SI')"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Endoscopia:</label>
                    <b-form-select v-model="jsonData.seiMesiPrimaEcoendoscopia" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Tipo Endoscopia:</label>
                    <b-form-input v-model="jsonData.seiMesiPrimaEcoendoscopiaTipo" :disabled="chekCondition(jsonData.seiMesiPrimaEcoendoscopia, 'SI')"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Luogo Endoscopia:</label>
                    <b-form-select v-model="jsonData.seiMesiPrimaEcoendoscopiaLuogo" :options="luogoInterventoOptions" :value="''" value-field="value" text-field="text" :disabled="chekCondition(jsonData.seiMesiPrimaEcoendoscopia, 'SI')"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Ospedalizzato:</label>
                    <b-form-select v-model="jsonData.seiMesiPrimaOspedalizzato" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Ospedale:</label>
                    <b-form-input v-model="jsonData.seiMesiPrimaOspedalizzatoOspedale" :disabled="chekCondition(jsonData.seiMesiPrimaOspedalizzato, 'SI')"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Reparto:</label>
                    <b-form-input v-model="jsonData.seiMesiPrimaOspedalizzatoReparto" :disabled="chekCondition(jsonData.seiMesiPrimaOspedalizzato, 'SI')"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data:</label>
                    <date-picker v-model="jsonData.seiMesiPrimaOspedalizzatoData" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="chekCondition(jsonData.seiMesiPrimaOspedalizzato, 'SI')"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Uso Droghe:</label>
                    <b-form-select v-model="jsonData.seiMesiPrimaUsoDroga" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Modalita Assunzione:</label>
                    <b-form-select v-model="jsonData.seiMesiPrimaUsoDrogaModalitaAssunzione" :options="assunzioneDrogheOptions" :value="''" value-field="value" text-field="text" :disabled="chekCondition(jsonData.seiMesiPrimaUsoDroga, 'SI')"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Contatto SERT:</label>
                    <b-form-select v-model="jsonData.seiMesiPrimaUsoDrogaContattoSert" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text" :disabled="chekCondition(jsonData.seiMesiPrimaUsoDroga, 'SI')"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Trattamento Dentario:</label>
                    <b-form-select v-model="jsonData.seiMesiPrimaTrattamentoDentario" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Buchi Orecchie/Piercing:</label>
                    <b-form-select v-model="jsonData.seiMesiPrimaPiercing" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Tatuaggi:</label>
                    <b-form-select v-model="jsonData.seiMesiPrimaTatuaggio" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Pratica Agopuntura:</label>
                    <b-form-select v-model="jsonData.seiMesiPrimaAgopuntura" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Rasato dal Barbiere:</label>
                    <b-form-select v-model="jsonData.seiMesiPrimaRasatoBarbiere" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Callista/Manicure:</label>
                    <b-form-select v-model="jsonData.seiMesiPrimaCallistaManicure" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Emodializzato:</label>
                    <b-form-select v-model="jsonData.seiMesiPrimaEmodializzato" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Vaccino Epatite B" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Somministrata Almeno Una Dose:</label>
                    <b-form-select v-model="jsonData.doseVaccinoEpatiteB" :options="doseVaccinoBOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Tipo Vaccino Prima Dose:</label>
                    <b-form-input v-model="jsonData.doseVaccinoEpatiteBPrimaDoseTipo" :disabled="!checkDoubleCondition(jsonData.doseVaccinoEpatiteB, 'NO', '', 'OR')"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Data Prima Dose:</label>
                    <date-picker v-model="jsonData.doseVaccinoEpatiteBPrimaDoseData" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="!checkDoubleCondition(jsonData.doseVaccinoEpatiteB, 'NO', '', 'OR')"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Tipo Vaccino Seconda Dose:</label>
                    <b-form-input v-model="jsonData.doseVaccinoEpatiteBSecondaDoseTipo" :disabled="checkDoubleCondition(jsonData.doseVaccinoEpatiteB, 'SI, 2 DOSI', 'SI, 3 DOSI', 'OR')"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Data Seconda Dose:</label>
                    <date-picker v-model="jsonData.doseVaccinoEpatiteBSecondaDoseData" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="checkDoubleCondition(jsonData.doseVaccinoEpatiteB, 'SI, 2 DOSI', 'SI, 3 DOSI', 'OR')"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Tipo Vaccino Terza Dose:</label>
                    <b-form-input v-model="jsonData.doseVaccinoEpatiteBTerzaDoseTipo" :disabled="chekCondition(jsonData.doseVaccinoEpatiteB, 'SI, 3 DOSI')"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Data Terza Dose:</label>
                    <date-picker v-model="jsonData.doseVaccinoEpatiteBTerzaDoseData" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="chekCondition(jsonData.doseVaccinoEpatiteB, 'SI, 3 DOSI')"></date-picker>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Contatto Persone con Epatite 6 Mesi Prima:</label>
                    <b-form-select v-model="jsonData.seiMesiPrimaContattoConoscentiInfetti" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Malattia Iniziata Meno di Due Mesi Prima:</label>
                    <b-form-select v-model="jsonData.seiMesiPrimaContattoConoscentiInfettiInizioDueMesi" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text" :disabled="chekCondition(jsonData.seiMesiPrimaContattoConoscentiInfetti, 'SI')"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Conviventi/Pratner Sessuali HbsAg Positivo:</label>
                    <b-form-select v-model="jsonData.conviventePatnerSessualeHbsagPositivo" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Tipo Relazione:</label>
                    <b-form-input v-model="jsonData.conviventePatnerSessualeHbsagPositivoTipoRelazione" :disabled="chekCondition(jsonData.conviventePatnerSessualeHbsagPositivo, 'SI')"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">A Conoscenza:</label>
                    <b-form-select v-model="jsonData.conviventePatnerSessualeHbsagPositivoConoscenza" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text" :disabled="chekCondition(jsonData.conviventePatnerSessualeHbsagPositivo, 'SI')"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Motivo Mancata Vaccinazione:</label>
                    <b-form-select v-model="jsonData.conviventePatnerSessualeHbsagPositivoConoscenzaVaccino" :options="motivoMancataVaccinazioneOptions" :value="''" value-field="value" text-field="text" :disabled="chekCondition(jsonData.conviventePatnerSessualeHbsagPositivoConoscenza, 'SI')"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Altro</label>
                    <b-form-textarea v-model="jsonData.conviventePatnerSessualeHbsagPositivoConoscenzaVaccinoAl" rows="8" no-resize :disabled="chekCondition(jsonData.conviventePatnerSessualeHbsagPositivoConoscenzaVaccino, 'ALTRO')"></b-form-textarea>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Conviventi/Pratner Sessuali anti-HCV Positivo:</label>
                    <b-form-select v-model="jsonData.conviventePatnerSessualeAntiHcvPisitivo" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Convivente Tossicodipentente:</label>
                    <b-form-select v-model="jsonData.conviventeTossicodipendente" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Partner Sessuali 6 mesi precedenti:</label>
                    <b-form-select v-model="jsonData.seiMesiPrimaPartnerSessuali" :options="siNoNonRispondoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Numero Partner:</label>
                    <b-form-input type="number" v-model="jsonData.seiMesiPrimaPartnerSessualiQuanti" :disabled="chekCondition(jsonData.seiMesiPrimaPartnerSessuali, 'SI')"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Partner Sessuale Tossicodipentente 6 mesi precedenti:</label>
                    <b-form-select v-model="jsonData.seiMesiPrimaPartnerSessualiTossicodipendenti" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Rapporti Occasionali 6 mesi precedenti:</label>
                    <b-form-select v-model="jsonData.seiMesiPrimaPartnerSessualiOccasionali" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Uso Profilattico:</label>
                    <b-form-select v-model="jsonData.seiMesiPrimaPartnerSessualiOccasionaliProfilattico" :options="usoProfilatticoOptions" :value="''" value-field="value" text-field="text" :disabled="chekCondition(jsonData.seiMesiPrimaPartnerSessualiOccasionali, 'SI')"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Numero Persone in Casa:</label>
                    <b-form-input type="number" v-model="jsonData.numeroPersoneCasa"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Presenti Bambino che frequentano Asilo/Scuola Materna:</label>
                    <b-form-select v-model="jsonData.bambiniFrequentanoAsiloNodo" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Donatore Periodico di Sangue:</label>
                    <b-form-select v-model="jsonData.donatorePeriodicoSangue" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">In Passato Eseguito Test Hiv:</label>
                    <b-form-select v-model="jsonData.testHivPassato" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Risutato Positivo:</label>
                    <b-form-select v-model="jsonData.testHivPassatoRisultatoPositivo" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text" :disabled="chekCondition(jsonData.testHivPassato, 'SI')"></b-form-select>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Valori Massimi Esami Laboratorio" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">AST:</label>
                    <b-form-input type="number" v-model="jsonData.valoriMassimiEsameAst"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">ALT:</label>
                    <b-form-input type="number" v-model="jsonData.valoriMassimiEsameAlt"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Bilirubina Totale:</label>
                    <b-form-input type="number" v-model="jsonData.valoriMassimiEsameBilirubinaTotale"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Bilirubina Diretta:</label>
                    <b-form-input type="number" v-model="jsonData.valoriMassimiEsameBilirubinaDiretta"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">INR:</label>
                    <b-form-input type="number" v-model="jsonData.valoriMassimiEsameInr"></b-form-input>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Risultati di Laboratorio" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Prelievo:</label>
                    <date-picker v-model="jsonData.dataPrelievoSangue" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Positivo HbsAg:</label>
                    <b-form-select v-model="jsonData.positivoHbsag" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Positivo IgM anti-HBC:</label>
                    <b-form-select v-model="jsonData.positivoIgmAntiHbc" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Positivo IgM anti-epatite A:</label>
                    <b-form-select v-model="jsonData.positivoIgmAntiEpatiteA" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Positivo IgM anti-delta:</label>
                    <b-form-select v-model="jsonData.positivoIgmAntiDelta" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Positivo anti-HCV:</label>
                    <b-form-select v-model="jsonData.positivoAntiHcv" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Positivo HCV-RNA:</label>
                    <b-form-select v-model="jsonData.positivoHcvRna" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Livello Viremia:</label>
                    <b-form-input v-model="jsonData.positivoHcvRnaLivelloViremia" :disabled="chekCondition(jsonData.positivoHcvRna, 'SI')"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Positivo IGM anti-Epatite E:</label>
                    <b-form-select v-model="jsonData.positivoIgmAntiEpatiteE" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Decorso Clinico" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Encefalopatia:</label>
                    <b-form-select v-model="jsonData.encefalopatia" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Fulminante:</label>
                    <b-form-select v-model="jsonData.fullminante" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Trapianto di Fegato:</label>
                    <b-form-select v-model="jsonData.trapiantoFegato" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Decesso:</label>
                    <b-form-select v-model="jsonData.decesso" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Decesso:</label>
                    <date-picker v-model="jsonData.decessoData" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="chekCondition(jsonData.decesso, 'SI')"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Causa Iniziale Decesso:</label>
                    <b-form-input v-model="jsonData.decessoCausaIniziale" :disabled="chekCondition(jsonData.decesso, 'SI')"></b-form-input>
                </b-col>
            </b-row>
        </b-card>

        <!-- SAVARESE -->
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Caso di:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.casoMalattia" :options="casoMalattiaOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Specie e/o Sierotipo:</label>
                    <b-form-input v-model="jsonData.casoMalattiaSpecieSierotipo"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Lavoro Settore Alimentare:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.lavoroSettoreAlimentare" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Specificare Dove e Occupazione:</label>
                    <b-form-input v-model="jsonData.lavoroSettoreAlimentareOccupazione" :disabled="chekCondition(jsonData.lavoroSettoreAlimentare, 'SI')"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Comunita' frequentate 10 giorni precedenti:</label>
                    <b-form-input v-model="jsonData.comunitaFrequentateDieciGiorniPrecedenti"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Se Studente Classe e Sezione:</label>
                    <b-form-input v-model="jsonData.comunitaFrequentateDieciGiorniPrecedentiStudenteClasseSe"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Frequnza Comunita' Fino Al:</label>
                    <date-picker v-model="jsonData.comunitaFrequentateDieciGiorniPrecedentiFinoAl" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="informazioni Cliniche" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Comparsa Sintomi:</label>
                    <date-picker v-model="jsonData.sintomiDataComparsa" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Sintomi Ancora Presenti:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.sintomiAncoraPresenti" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Vomito:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.sintomiVomito" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Vomito Data:</label>
                    <date-picker v-model="jsonData.sintomiVomitoDataOra" format="DD-MM-YYYY HH:mm" value-type="timestamp" type="datetime" :disabled="chekCondition(jsonData.sintomiVomito, 'SI')"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Crampi Addominali:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.sintomiCrampiAddome" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Crampi Addominali Data:</label>
                    <date-picker v-model="jsonData.sintomiCrampiAddomeDataOra" format="DD-MM-YYYY HH:mm" value-type="timestamp" type="datetime" :disabled="chekCondition(jsonData.sintomiCrampiAddome, 'SI')"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Diarrea:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.sintomiDiarrea" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Diarrea Data:</label>
                    <date-picker v-model="jsonData.sintomiDiarreaDataOra" format="DD-MM-YYYY HH:mm" value-type="timestamp" type="datetime" :disabled="chekCondition(jsonData.sintomiDiarrea, 'SI')"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Diarrea Con Sangue:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.sintomiDiarreaSangue" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Diarrea Con Sangue Data:</label>
                    <date-picker v-model="jsonData.sintomiDiarreaSangueDataOra" format="DD-MM-YYYY HH:mm" value-type="timestamp" type="datetime" :disabled="chekCondition(jsonData.sintomiDiarreaSangue, 'SI')"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Diarrea Con Sangue Scariche Giornaliere:</label>
                    <b-form-input v-model="jsonData.sintomiDiarreaNumeroScariche" :disabled="chekCondition(jsonData.sintomiDiarreaSangue, 'SI')"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Diarrea Con Muco:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.sintomiDiarreaMuco" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Diarrea Con Muco Data:</label>
                    <date-picker v-model="jsonData.sintomiDiarreaMucoDataOra" format="DD-MM-YYYY HH:mm" value-type="timestamp" type="datetime" :disabled="chekCondition(jsonData.sintomiDiarreaMuco, 'SI')"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Febbre:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.sintomiFebbre" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Febbre Data:</label>
                    <date-picker v-model="jsonData.sintomiFebbreDataOra" format="DD-MM-YYYY HH:mm" value-type="timestamp" type="datetime" :disabled="chekCondition(jsonData.sintomiFebbre, 'SI')"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Disidratazione:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.sintomiDisidratazione" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Disidratazione Data:</label>
                    <date-picker v-model="jsonData.sintomiDisidratazioneDataOra" format="DD-MM-YYYY HH:mm" value-type="timestamp" type="datetime" :disabled="chekCondition(jsonData.sintomiDisidratazione, 'SI')"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Mal Di Testa/Astenia:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.sintomiMalTestaAstenia" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Mal Di Testa/Astenia Data:</label>
                    <date-picker v-model="jsonData.sintomiMalTestaAsteniaDataOra" format="DD-MM-YYYY HH:mm" value-type="timestamp" type="datetime" :disabled="chekCondition(jsonData.sintomiMalTestaAstenia, 'SI')"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Altri Sintomi:</label>
                    <b-form-textarea v-model="jsonData.sintomiAltro" rows="3" no-resize></b-form-textarea>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Sintomo Predominante:</label>
                    <b-form-input v-model="jsonData.sintomoPredominante"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Contattato Medico di Base:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.rivoltoMedicoFamiglia" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Contattato Medico di Base Data:</label>
                    <date-picker v-model="jsonData.rivoltoMedicoFamigliaQuando" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="chekCondition(jsonData.rivoltoMedicoFamiglia, 'SI')"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Diagnosi:</label>
                    <b-form-textarea v-model="jsonData.rivoltoMedicoFamigliaDiagnosiMedico" rows="3" no-resize :disabled="chekCondition(jsonData.rivoltoMedicoFamiglia, 'SI')"></b-form-textarea>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Pronto Soccorso:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.andatoProntoSoccorso" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Pronto Soccorso Data:</label>
                    <date-picker v-model="jsonData.andatoProntoSoccorsoQuando" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="chekCondition(jsonData.andatoProntoSoccorso, 'SI')"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Diagnosi Dimissione:</label>
                    <b-form-textarea v-model="jsonData.andatoProntoSoccorsoDiagnosiDimissione" rows="3" no-resize :disabled="chekCondition(jsonData.andatoProntoSoccorso, 'SI')"></b-form-textarea>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Ricovero Ospedale:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.ricoveroOspedale" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Ricovero Ospedaliero Motivo:</label>
                    <b-form-input v-model="jsonData.ricoveroOspedaleMotivo" :disabled="chekCondition(jsonData.ricoveroOspedale, 'SI')"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Ricovero Ospedaliero Reparto:</label>
                    <b-form-input v-model="jsonData.ricoveroOspedaleReparto" :disabled="chekCondition(jsonData.ricoveroOspedale, 'SI')"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Pronto Soccorso Data Dimissioni:</label>
                    <date-picker v-model="jsonData.ricoveroOspedaleDataDimissioni" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="chekCondition(jsonData.ricoveroOspedale, 'SI')"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Indagine Laboratorio:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.eseguitoIndagineLaborario" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Indagine Laboratorio Richiedente:</label>
                    <b-form-input v-model="jsonData.eseguitoIndagineLaborarioRichiedente" :disabled="chekCondition(jsonData.eseguitoIndagineLaborario, 'SI')"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Indagine Laboratorio Esito:</label>
                    <b-form-input v-model="jsonData.eseguitoIndagineLaborarioEsito" :disabled="chekCondition(jsonData.eseguitoIndagineLaborario, 'SI')"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Assunto Antibiotici Prima Dell'Esame:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.primaEsemeAssuntoAntibiotici" :options="siNoOptions" value="" value-field="value" text-field="text" :disabled="chekCondition(jsonData.eseguitoIndagineLaborario, 'SI')"></b-form-select>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Contatti ed Esposti" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Familiare/Amico/Conoscente Con Medesima Sintomatologia:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.contattiStessaSintomatologia" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
            </b-row>
            <b-table sticky-header ref="tblViaggi" stacked="xl" striped hover itemscope :items="jsonData.listaContatti" :fields="fieldsContatti" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                <template #cell(index)="jsonData">
                    {{ jsonData.index + 1 }}
                </template>
                <template #cell(cognome)="item">
                    <b-form-input v-model="item.item.cognome"></b-form-input>
                </template>
                <template #cell(nome)="item">
                    <b-form-input v-model="item.item.nome"></b-form-input>
                </template>
                <template #cell(comune)="item">
                    <!-- <b-form-select v-model="item.item.professione" :options="professioniOptions" :value="''" value-field="value" text-field="text"></b-form-select> -->
                    <b-form-input v-model="item.item.comune"></b-form-input>
                </template>
                <template #cell(indirizzo)="item">
                    <b-form-input v-model="item.item.indirizzo"></b-form-input>
                </template>
                <template #cell(telefono)="item">
                    <b-form-input v-model="item.item.telefono"></b-form-input>
                </template>
                <template #cell(actions)="row">
                    <b-button v-if="row.index == jsonData.listaContatti.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddContatto(jsonData.listaContatti, row.item)">
                        <b-icon icon="plus"></b-icon>
                    </b-button>
                    <b-button v-if="row.index !== jsonData.listaContatti.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(jsonData.listaContatti, row)">
                        <b-icon icon="trash"></b-icon>
                    </b-button>
                </template>
            </b-table>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Consumato Pasti/Esperienze Comuni:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.contattiStessaSintomatologiaConsumatoPasti" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
            </b-row>
            <b-table sticky-header ref="tblViaggi" stacked="xl" striped hover itemscope :items="jsonData.listaEsperienzeComuni" :fields="fieldsEsperienzeComuni" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                <template #cell(index)="jsonData">
                    {{ jsonData.index + 1 }}
                </template>
                <template #cell(pastoEsperienzaComune)="item">
                    <b-form-input v-model="item.item.pastoEsperienzaComune" placeholder="Pasto/Esperienza Comune"></b-form-input>
                </template>
                <template #cell(quando)="item">
                    <b-form-input v-model="item.item.quando" placeholder="Quando"></b-form-input>
                </template>
                <template #cell(dove)="item">
                    <b-form-input v-model="item.item.dove" placeholder="Dove"></b-form-input>
                </template>
                <template #cell(actions)="row">
                    <b-button v-if="row.index == jsonData.listaEsperienzeComuni.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddEsperienzaComune(jsonData.listaEsperienzeComuni, row.item)">
                        <b-icon icon="plus"></b-icon>
                    </b-button>
                    <b-button v-if="row.index !== jsonData.listaEsperienzeComuni.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(jsonData.listaEsperienzeComuni, row)">
                        <b-icon icon="trash"></b-icon>
                    </b-button>
                </template>
            </b-table>
        </b-card>
        <b-card class="sa-card" header="Comportamenti" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Viaggi nei 5 Giorni Precedenti:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.viaggiCinqueGiorniPrecedenti" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Dove:</label>
                    <b-form-input v-model="jsonData.viaggiCinqueGiorniPrecedentiDove" :disabled="chekCondition(jsonData.viaggiCinqueGiorniPrecedenti, 'SI')"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Alloggio:</label>
                    <b-form-input v-model="jsonData.viaggiCinqueGiorniPrecedentiAlloggio" :disabled="chekCondition(jsonData.viaggiCinqueGiorniPrecedenti, 'SI')"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Consumato Pasti in Questi Luoghi/Strutture:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.viaggiCinqueGiorniPrecedentiConsumoPasti" :options="siNoOptions" value="" value-field="value" text-field="text" :disabled="chekCondition(jsonData.viaggiCinqueGiorniPrecedenti, 'SI')"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Dove:</label>
                    <b-form-input v-model="jsonData.viaggiCinqueGiorniPrecedentiConsumoPastiDove" :disabled="chekCondition(jsonData.viaggiCinqueGiorniPrecedentiConsumoPasti, 'SI')"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Quando:</label>
                    <date-picker v-model="jsonData.viaggiCinqueGiorniPrecedentiConsumoPastiQuando" format="DD-MM-YYYY HH:mm" value-type="timestamp" type="datetime" :disabled="chekCondition(jsonData.viaggiCinqueGiorniPrecedentiConsumoPasti, 'SI')"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Altre Pesone con Stessi Sintomi in Struttura:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.viaggiCinqueGiorniPrecedentiSintomiPersone" :options="siNoOptions" value="" value-field="value" text-field="text" :disabled="chekCondition(jsonData.viaggiCinqueGiorniPrecedenti, 'SI')"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Quando:</label>
                    <date-picker v-model="jsonData.viaggiCinqueGiorniPrecedentiSintomiPersoneQuando" format="DD-MM-YYYY HH:mm" value-type="timestamp" type="datetime" :disabled="chekCondition(jsonData.viaggiCinqueGiorniPrecedentiSintomiPersone, 'SI')"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Animali Domestici:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.animaliDomestici" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox v-model="jsonData.animaliDomesticiCane" :checked="jsonData.animaliDomesticiCane" switch :disabled="chekCondition(jsonData.animaliDomestici, 'SI')">Cani</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox v-model="jsonData.animaliDomesticiGatto" :checked="jsonData.animaliDomesticiGatto" switch :disabled="chekCondition(jsonData.animaliDomestici, 'SI')">Gatti</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox v-model="jsonData.animaliDomesticiUccelli" :checked="jsonData.animaliDomesticiUccelli" switch :disabled="chekCondition(jsonData.animaliDomestici, 'SI')">Uccelli</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox v-model="jsonData.animaliDomesticiCricetiOrsettiRussi" :checked="jsonData.animaliDomesticiCricetiOrsettiRussi" switch :disabled="chekCondition(jsonData.animaliDomestici, 'SI')">Criceti/ Orsetti Russi</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox v-model="jsonData.animaliDomesticiTartarughe" :checked="jsonData.animaliDomesticiTartarughe" switch :disabled="chekCondition(jsonData.animaliDomestici, 'SI')">Tartarughe</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Altro Animale Domestico:</label>
                    <b-form-input v-model="jsonData.animaliDomesticiTipoAltro" :disabled="chekCondition(jsonData.animaliDomestici, 'SI')"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Manifestato Diarrea Prima Che si Ammalasse:</label>
                    <b-form-input v-model="jsonData.animaliDomesticiDiarrea" :disabled="chekCondition(jsonData.animaliDomestici, 'SI')"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Lavoro a Contatto Con Animali e Feci:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.contattoAnimaliFeci" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Vive in Azienda Agricola/Campagna:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.viveAziendaAgricolaCampagna" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox v-model="jsonData.toccatoAnimaliMucche" :checked="jsonData.toccatoAnimaliMucche" switch>Toccato Mucche</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox v-model="jsonData.toccatoAnimaliPolliGalline" :checked="jsonData.toccatoAnimaliPolliGalline" switch>Toccato Polli/Galline</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox v-model="jsonData.toccatoAnimaliMaiali" :checked="jsonData.toccatoAnimaliMaiali" switch>Toccato Maiali</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox v-model="jsonData.toccatoAnimaliPecore" :checked="jsonData.toccatoAnimaliPecore" switch>Toccato Pecore</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox v-model="jsonData.toccatoAnimaliRoditori" :checked="jsonData.toccatoAnimaliRoditori" switch>Toccato Roditori</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Toccato Altri Animali:</label>
                    <b-form-input v-model="jsonData.toccatoAnimaliAltro"></b-form-input>
                </b-col>
            </b-row>
            <h4>Consumo di Acqua</h4>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox v-model="jsonData.tipoAcquaBevutoAcquedottoComunale" :checked="jsonData.tipoAcquaBevutoAcquedottoComunale" switch>Acquedotto Comunale</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox v-model="jsonData.tipoAcquaBevutoAcquaMineraleBottiglia" :checked="jsonData.tipoAcquaBevutoAcquaMineraleBottiglia" switch>Minerale In Bottiglia</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox v-model="jsonData.tipoAcquaBevutoAcquaAcquaSorgene" :checked="jsonData.tipoAcquaBevutoAcquaAcquaSorgene" switch>Sorgente</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox v-model="jsonData.tipoAcquaBevutoAcquaAcquaCorsiSuperficialiNonTrattata" :checked="jsonData.tipoAcquaBevutoAcquaAcquaCorsiSuperficialiNonTrattata" switch>Corsi Superficiali Non Trattata</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox v-model="jsonData.tipoAcquaBevutoAcquaAcquaPozzoNonTrattata" :checked="jsonData.tipoAcquaBevutoAcquaAcquaPozzoNonTrattata" switch>Pozzo Non Trattata</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Pozzo di Sua Proprieta':</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.tipoAcquaBevutoCinqueGiorniPrecedentiPozzo" :options="siNoOptions" value="" value-field="value" text-field="text" :disabled="!jsonData.tipoAcquaBevutoAcquaAcquaPozzoNonTrattata"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Ultimo Controllo Potabilita':</label>
                    <b-form-input v-model="jsonData.tipoAcquaBevutoCinqueGiorniPrecedentiPozzoControlloPota" :disabled="!jsonData.tipoAcquaBevutoCinqueGiorniPrecedentiPozzo"></b-form-input>
                </b-col>
            </b-row>
            <h4>Acquisto e Consumo di Prodotti Alimentari</h4>
            <b-row>
                <b-table sticky-header ref="tblViaggi" stacked="xl" striped hover itemscope :items="jsonData.listaNegozi" :fields="fieldsNegozi" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                    <template #cell(index)="jsonData">
                        {{ jsonData.index + 1 }}
                    </template>
                    <template #cell(negozio)="item">
                        <b-form-input v-model="item.item.negozio" placeholder="Negozio"></b-form-input>
                    </template>
                    <template #cell(sempre)="item">
                        <b-form-checkbox v-model="item.item.sempre" :checked="item.item.sempre" switch></b-form-checkbox>
                    </template>
                    <template #cell(spesso)="item">
                        <b-form-checkbox v-model="item.item.spesso" :checked="item.item.spesso" switch></b-form-checkbox>
                    </template>
                    <template #cell(qualcheVolta)="item">
                        <b-form-checkbox v-model="item.item.qualcheVolta" :checked="item.item.qualcheVolta" switch></b-form-checkbox>
                    </template>
                    <template #cell(actions)="row">
                        <b-button v-if="row.index == jsonData.listaNegozi.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddNegozio(jsonData.listaNegozi, row.item)">
                            <b-icon icon="plus"></b-icon>
                        </b-button>
                        <b-button v-if="row.index !== jsonData.listaNegozi.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(jsonData.listaNegozi, row)">
                            <b-icon icon="trash"></b-icon>
                        </b-button>
                    </template>
                </b-table>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Partecipato Eventi/Ritrovi/Manifestazioni:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.cinqueGiorniPartecipatoEventiRitroviManifestazioni" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Dove:</label>
                    <b-form-input v-model="jsonData.cinqueGiorniPartecipatoEventiRitroviManifestazioniDove" :disabled="chekCondition(jsonData.cinqueGiorniPartecipatoEventiRitroviManifestazioni, 'SI')"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Quando:</label>
                    <b-form-input v-model="jsonData.cinqueGiorniPartecipatoEventiRitroviManifestazioniQundo" :disabled="chekCondition(jsonData.cinqueGiorniPartecipatoEventiRitroviManifestazioni, 'SI')"></b-form-input>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Nei Giorni Precedenti l'inizio dei sintomi" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox v-model="jsonData.giorniPrecedentiManeggiatoLavatoCarneCruda" :checked="jsonData.giorniPrecedentiManeggiatoLavatoCarneCruda" switch>Mangiato/Lavato Carne Cruda</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox v-model="jsonData.giorniPrecedentiManeggiatoLavatoUova" :checked="jsonData.giorniPrecedentiManeggiatoLavatoUova" switch>Mangiato/Lavato Uova</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox v-model="jsonData.giorniPrecedentiManeggiatoLavatoVerduraNonLavata" :checked="jsonData.giorniPrecedentiManeggiatoLavatoVerduraNonLavata" switch>Mangiato/Lavato Verdura non Lavata</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox v-model="jsonData.giorniPrecedentiManeggiatoLavatoPollo" :checked="jsonData.giorniPrecedentiManeggiatoLavatoPollo" switch>Mangiato/Lavato Pollo</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Utilizzato Stessi Utensili per Alimenti Diversi:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.giorniPrecedentiUtilizzoStessiUtensiliDiversiAlimenti" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Quali:</label>
                    <b-form-input v-model="jsonData.giorniPrecedentiUtilizzoStessiUtensiliDiversiAlimentiDes" :disabled="chekCondition(jsonData.giorniPrecedentiUtilizzoStessiUtensiliDiversiAlimenti, 'SI')"></b-form-input>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Indagine Consumo Alimenti Carne" header-tag="header" footer-tag="footer" title="">
            <indagine-epidemiologica-infezione-alimentare-alimenti-component :isEdit="true" :listaAlimentiConsumati="jsonData.listaAlimentiConsumatiCarne" famigliaAlimenti="CARNE" @update="onUpdateJsonDataListaAlimenti"></indagine-epidemiologica-infezione-alimentare-alimenti-component>
        </b-card>
        <b-card class="sa-card" header="Indagine Consumo Alimenti Pesce" header-tag="header" footer-tag="footer" title="">
            <indagine-epidemiologica-infezione-alimentare-alimenti-component :isEdit="true" :listaAlimentiConsumati="jsonData.listaAlimentiConsumatiPesce" famigliaAlimenti="PESCE" @update="onUpdateJsonDataListaAlimenti"></indagine-epidemiologica-infezione-alimentare-alimenti-component>
        </b-card>
        <b-card class="sa-card" header="Indagine Consumo Alimenti Uova" header-tag="header" footer-tag="footer" title="">
            <indagine-epidemiologica-infezione-alimentare-alimenti-component :isEdit="true" :listaAlimentiConsumati="jsonData.listaAlimentiConsumatiUova" famigliaAlimenti="UOVA" @update="onUpdateJsonDataListaAlimenti"></indagine-epidemiologica-infezione-alimentare-alimenti-component>
        </b-card>
        <b-card class="sa-card" header="Indagine Consumo Alimenti Latte/Latticini" header-tag="header" footer-tag="footer" title="">
            <indagine-epidemiologica-infezione-alimentare-alimenti-component :isEdit="true" :listaAlimentiConsumati="jsonData.listaAlimentiConsumatiLatteLatticini" famigliaAlimenti="LATTE/LATTICINI" @update="onUpdateJsonDataListaAlimenti"></indagine-epidemiologica-infezione-alimentare-alimenti-component>
        </b-card>
        <b-card class="sa-card" header="Indagine Consumo Alimenti Verdure" header-tag="header" footer-tag="footer" title="">
            <indagine-epidemiologica-infezione-alimentare-alimenti-component :isEdit="true" :listaAlimentiConsumati="jsonData.listaAlimentiConsumatiVerdure" famigliaAlimenti="VERDURE" @update="onUpdateJsonDataListaAlimenti"></indagine-epidemiologica-infezione-alimentare-alimenti-component>
        </b-card>
        <b-card class="sa-card" header="Indagine Consumo Alimenti Prodotti dolci da forno" header-tag="header" footer-tag="footer" title="">
            <indagine-epidemiologica-infezione-alimentare-alimenti-component :isEdit="true" :listaAlimentiConsumati="jsonData.listaAlimentiConsumatiProdottiDolciForno" famigliaAlimenti="PRODOTTI DOLCI E DA FORNO" @update="onUpdateJsonDataListaAlimenti"></indagine-epidemiologica-infezione-alimentare-alimenti-component>
        </b-card>
        <b-card class="sa-card" header="Indagine Consumo Alimenti ALIMENTI PRONTI AL CONSUMO/  ALIMENTI TRAFORMATI REFRIGERATI CON DURATA PROLUNGATA" header-tag="header" footer-tag="footer" title="">
            <indagine-epidemiologica-infezione-alimentare-alimenti-component :isEdit="true" :listaAlimentiConsumati="jsonData.listaAlimentiConsumatiProdottiProntiCosumo" famigliaAlimenti="ALIMENTI PRONTI AL CONSUMO/  ALIMENTI TRAFORMATI REFRIGERATI CON DURATA PROLUNGATA" @update="onUpdateJsonDataListaAlimenti"></indagine-epidemiologica-infezione-alimentare-alimenti-component>
        </b-card>
        <b-card class="sa-card" header="Conclusioni ed ulteriori azioni" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <b-form-checkbox v-model="jsonData.azioniIntraprendereAlimenti" :checked="jsonData.azioniIntraprendereAlimenti" switch>Da Alimenti</b-form-checkbox>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Alimento Sospetto:</label>
                    <b-form-input v-model="jsonData.azioniIntraprendereAlimentiSospetto" :disabled="!jsonData.azioniIntraprendereAlimenti"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Comportamenti a Rischio:</label>
                    <b-form-input v-model="jsonData.azioniIntraprendereAlimentiComportamentiRischioPreparazion" :disabled="!jsonData.azioniIntraprendereAlimenti"></b-form-input>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <b-form-checkbox v-model="jsonData.azioniIntraprendereNonAlimenti" :checked="jsonData.azioniIntraprendereNonAlimenti" switch>Non Da Alimenti</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Dettaglio:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.azioniIntraprendereNonAlimentiTipo" :options="nonAlimentiTipoOptions" value="" value-field="value" text-field="text" :disabled="!jsonData.azioniIntraprendereNonAlimenti"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Ulteriori Azioni:</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.ulterioreAzione" :options="ulterioriAzioniOptions" value="" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Data:</label>
                    <date-picker v-model="jsonData.ulterioreAzioneData" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="chekCondition(jsonData.ulterioreAzione, 'AVVISO AL SETTORE DI SICUREZZA ALIMENTARE')"></date-picker>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Altre Considerazioni</label>
                    <b-form-textarea v-model="jsonData.altreConsiderazioni" rows="6" no-resize></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>

        <b-card class="sa-card" header="Dati Notifica" header-tag="header" footer-tag="footer" title="">
            <indagine-epidemiologica-dati-notifica-component :jsonData="jsonData" :isEdit="true" @update="onUpdateJsonData"></indagine-epidemiologica-dati-notifica-component>
        </b-card>
    </div>
</template>
<script>
import axios from "axios";
import UtilityMixin from "../../../utility/UtilityMixin";
import DatePicker from "vue2-datepicker";
import Vue from "vue";
import IndagineEpidemiologicaDatiNotificaComponent from "./IndagineEpidemiologicaDatiNotificaComponent.vue";
import IndagineEpidemiologicaInfezioneAlimentareAlimentiComponent from "./IndagineEpidemiologicaInfezioneAlimentareAlimentiComponent.vue";
export default {
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    components: { IndagineEpidemiologicaDatiNotificaComponent, IndagineEpidemiologicaInfezioneAlimentareAlimentiComponent, DatePicker },
    mixins: [UtilityMixin],
    data() {
        return {
            pathResource: "/malattieinfettiveieepatitea",
            pathResourceMalattiaInfettivaSegnalazione: "/malattieinfettivesegnalazioni",
            pathResourceSottotipoModalita: "/malattieinfettiveieinfezionialimentarisottotipomodalita",
            linkback: null,
            currentPage: 1,
            perPage: 100,
            showModalLoading: false,
            linkNazioni: "/nazioni",
            pathResourceComuni: "/comuni",
            pathResourceProvince: "/province",
            // linkNazioni: "/nazioni",
            disableSintomi: true,
            jsonData: {
                malattiaDiventatoGiallo: "",
                dataInizioMalattia: null,
                ospedalizzatoPerMalattia: "",
                ospedalizzatoPerMalattiaGiorni: "",
                seiSettimanePrimaAssunzioneFarmaci: "",
                seiSettimanePrimaConsumoFruttiMare: "",
                seiSettimanePrimaConsumoFruttiMareCrudi: "",
                seiSettimanePrimaConsumoFruttiBosco: "",
                seiSettimanePrimaConsumoFruttiBoscoDettagli: "",
                seiSettimanePrimaConsumoAcquaPozzoSorgente: "",
                seiSettimanePrimaNotteFuoriCitta: "",
                seiSettimanePrimaNotteFuoriCittaDove: "",
                seiSettimanePrimaNotteFuoriCittaQuando: null,
                doseVaccinoEpatiteA: "",
                doseVaccinoEpatiteAPrimaDoseTipo: "",
                doseVaccinoEpatiteAPrimaDoseData: null,
                doseVaccinoEpatiteASecondaDoseTipo: "",
                doseVaccinoEpatiteASecondaDoseData: null,
                seiMesiPrimaSomministrazioneSanguePlasma: "",
                seiMesiPrimaSomministrazioneAltriDerivati: "",
                seiMesiPrimaInterventiChirurgici: "",
                seiMesiPrimaInterventiChirurgiciTipo: "",
                seiMesiPrimaInterventiChirurgiciLuogo: "",
                seiMesiPrimaEcoendoscopia: "",
                seiMesiPrimaEcoendoscopiaTipo: "",
                seiMesiPrimaEcoendoscopiaLuogo: "",
                seiMesiPrimaOspedalizzato: "",
                seiMesiPrimaOspedalizzatoOspedale: "",
                seiMesiPrimaOspedalizzatoReparto: "",
                seiMesiPrimaOspedalizzatoData: null,
                seiMesiPrimaUsoDroga: "",
                seiMesiPrimaUsoDrogaModalitaAssunzione: "",
                seiMesiPrimaUsoDrogaContattoSert: "",
                seiMesiPrimaTrattamentoDentario: "",
                seiMesiPrimaPiercing: "",
                seiMesiPrimaTatuaggio: "",
                seiMesiPrimaAgopuntura: "",
                seiMesiPrimaRasatoBarbiere: "",
                seiMesiPrimaCallistaManicure: "",
                seiMesiPrimaEmodializzato: "",
                doseVaccinoEpatiteB: "",
                doseVaccinoEpatiteBPrimaDoseTipo: "",
                doseVaccinoEpatiteBPrimaDoseData: null,
                doseVaccinoEpatiteBSecondaDoseTipo: "",
                doseVaccinoEpatiteBSecondaDoseData: null,
                doseVaccinoEpatiteBTerzaDoseTipo: "",
                doseVaccinoEpatiteBTerzaDoseData: null,
                seiMesiPrimaContattoConoscentiInfetti: "",
                seiMesiPrimaContattoConoscentiInfettiInizioDueMesi: "",
                conviventePatnerSessualeHbsagPositivo: "",
                conviventePatnerSessualeHbsagPositivoTipoRelazione: "",
                conviventePatnerSessualeHbsagPositivoConoscenza: "",
                conviventePatnerSessualeHbsagPositivoConoscenzaVaccino: "",
                conviventePatnerSessualeHbsagPositivoConoscenzaVaccinoAl: "",
                conviventePatnerSessualeAntiHcvPisitivo: "",
                conviventeTossicodipendente: "",
                seiMesiPrimaPartnerSessuali: "",
                seiMesiPrimaPartnerSessualiQuanti: "",
                seiMesiPrimaPartnerSessualiTossicodipendenti: "",
                seiMesiPrimaPartnerSessualiOccasionali: "",
                seiMesiPrimaPartnerSessualiOccasionaliProfilattico: "",
                numeroPersoneCasa: "",
                bambiniFrequentanoAsiloNodo: "",
                donatorePeriodicoSangue: "",
                testHivPassato: "",
                testHivPassatoRisultatoPositivo: "",
                valoriMassimiEsameAst: "",
                valoriMassimiEsameAlt: "",
                valoriMassimiEsameBilirubinaTotale: "",
                valoriMassimiEsameBilirubinaDiretta: "",
                valoriMassimiEsameInr: "",
                dataPrelievoSangue: null,
                positivoHbsag: "",
                positivoIgmAntiHbc: "",
                positivoIgmAntiEpatiteA: "",
                positivoIgmAntiDelta: "",
                positivoAntiHcv: "",
                positivoHcvRna: "",
                positivoHcvRnaLivelloViremia: "",
                positivoIgmAntiEpatiteE: "",
                encefalopatia: "",
                fullminante: "",
                trapiantoFegato: "",
                decesso: "",
                decessoData: null,
                decessoCausaIniziale: "",
                sanitarioNotificante: "",
                sanitarioNotificanteContatto: "",
                altreConsiderazioni: "",
                dataNotifica: null,
                casoMalattia: "",
                casoMalattiaSpecieSierotipo: "",
                lavoroSettoreAlimentare: "",
                lavoroSettoreAlimentareOccupazione: "",
                comunitaFrequentateDieciGiorniPrecedenti: "",
                comunitaFrequentateDieciGiorniPrecedentiStudenteClasseSe: "",
                comunitaFrequentateDieciGiorniPrecedentiFinoAl: null,
                sintomiDataComparsa: null,
                sintomiAncoraPresenti: "",
                sintomiVomito: "",
                sintomiVomitoDataOra: null,
                sintomiCrampiAddome: "",
                sintomiCrampiAddomeDataOra: null,
                sintomiDiarrea: "",
                sintomiDiarreaDataOra: null,
                sintomiDiarreaSangue: "",
                sintomiDiarreaSangueDataOra: null,
                sintomiDiarreaNumeroScariche: "",
                sintomiDiarreaMuco: "",
                sintomiDiarreaMucoDataOra: null,
                sintomiFebbre: "",
                sintomiFebbreDataOra: null,
                sintomiDisidratazione: "",
                sintomiDisidratazioneDataOra: null,
                sintomiMalTestaAstenia: "",
                sintomiMalTestaAsteniaDataOra: null,
                sintomiAltro: "",
                sintomoPredominante: "",
                rivoltoMedicoFamiglia: "",
                rivoltoMedicoFamigliaQuando: null,
                rivoltoMedicoFamigliaDiagnosiMedico: "",
                andatoProntoSoccorso: "",
                andatoProntoSoccorsoQuando: null,
                andatoProntoSoccorsoDiagnosiDimissione: "",
                ricoveroOspedale: "",
                ricoveroOspedaleMotivo: "",
                ricoveroOspedaleReparto: "",
                ricoveroOspedaleDataDimissioni: null,
                eseguitoIndagineLaborario: "",
                eseguitoIndagineLaborarioRichiedente: "",
                eseguitoIndagineLaborarioEsito: "",
                primaEsemeAssuntoAntibiotici: "",
                contattiStessaSintomatologia: "",
                contattiStessaSintomatologiaConsumatoPasti: "",
                viaggiCinqueGiorniPrecedenti: "",
                viaggiCinqueGiorniPrecedentiDove: "",
                viaggiCinqueGiorniPrecedentiAlloggio: "",
                viaggiCinqueGiorniPrecedentiConsumoPasti: "",
                viaggiCinqueGiorniPrecedentiConsumoPastiDove: "",
                viaggiCinqueGiorniPrecedentiConsumoPastiQuando: null,
                viaggiCinqueGiorniPrecedentiSintomiPersone: "",
                viaggiCinqueGiorniPrecedentiSintomiPersoneQuando: "",
                animaliDomestici: "",
                animaliDomesticiCane: false,
                animaliDomesticiGatto: false,
                animaliDomesticiUccelli: false,
                animaliDomesticiCricetiOrsettiRussi: false,
                animaliDomesticiTartarughe: false,
                animaliDomesticiTipoAltro: "",
                animaliDomesticiDiarrea: "",
                contattoAnimaliFeci: "",
                viveAziendaAgricolaCampagna: "",
                toccatoAnimaliMaiali: false,
                toccatoAnimaliMucche: false,
                toccatoAnimaliPecore: false,
                toccatoAnimaliPolliGalline: false,
                toccatoAnimaliRoditori: false,
                toccatoAnimaliAltro: "",
                tipoAcquaBevutoAcquedottoComunale: false,
                tipoAcquaBevutoAcquaMineraleBottiglia: false,
                tipoAcquaBevutoAcquaAcquaSorgene: false,
                tipoAcquaBevutoAcquaAcquaCorsiSuperficialiNonTrattata: false,
                tipoAcquaBevutoAcquaAcquaPozzoNonTrattata: false,
                tipoAcquaBevutoCinqueGiorniPrecedentiPozzo: "",
                tipoAcquaBevutoCinqueGiorniPrecedentiPozzoControlloPota: "",
                cinqueGiorniPartecipatoEventiRitroviManifestazioni: "",
                cinqueGiorniPartecipatoEventiRitroviManifestazioniDove: "",
                cinqueGiorniPartecipatoEventiRitroviManifestazioniQundo: "",
                giorniPrecedentiManeggiatoLavatoCarneCruda: false,
                giorniPrecedentiManeggiatoLavatoUova: false,
                giorniPrecedentiManeggiatoLavatoVerduraNonLavata: false,
                giorniPrecedentiManeggiatoLavatoPollo: false,
                giorniPrecedentiUtilizzoStessiUtensiliDiversiAlimenti: "",
                giorniPrecedentiUtilizzoStessiUtensiliDiversiAlimentiDes: "",
                note: "",
                azioniIntraprendereAlimenti: false,
                azioniIntraprendereAlimentiSospetto: "",
                azioniIntraprendereAlimentiComportamentiRischioPreparazion: "",
                azioniIntraprendereNonAlimenti: false,
                azioniIntraprendereNonAlimentiTipo: "",
                ulterioreAzione: "",
                ulterioreAzioneData: null,
                // sanitarioNotificante: "",
                // sanitarioNotificanteContatto: "",
                // altreConsiderazioni: "",
                // dataNotifica: null,
                listaAlimentiConsumatiCarne: [],
                listaAlimentiConsumatiPesce: [],
                listaAlimentiConsumatiUova: [],
                listaAlimentiConsumatiLatteLatticini: [],
                listaAlimentiConsumatiVerdure: [],
                listaAlimentiConsumatiProdottiDolciForno: [],
                listaAlimentiConsumatiProdottiProntiCosumo: [],
                listaContatti: [],
                listaEsperienzeComuni: [],
                listaNegozi: [{ negozio: "", sempre: false, spesso: false, qualcheVolta: false }],
                listaFarmaci: [],
            },
            fieldsFarmaci: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Farmaco",
                    key: "farmaco",
                    sortable: false,
                },
                {
                    label: "Tempo",
                    key: "tempo",
                    thStyle: "width: 11rem",
                    sortable: false,
                },
                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 4rem",
                    tdClass: "text-center",
                },
            ],
            nazioniOptions: [],
            siNoOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "SI", text: "SI" },
                { value: "NO", text: "NO" },
            ],
            siNoNonNotoOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "SI", text: "SI" },
                { value: "NO", text: "NO" },
                { value: "NON NOTO", text: "NON NOTO" },
            ],
            siNoNonRispondoOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "SI", text: "SI" },
                { value: "NO", text: "NO" },
                { value: "PREFERISCO NON RISPONDERE", text: "PREFERISCO NON RISPONDERE" },
            ],
            usoProfilatticoOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "SEMPRE", text: "SEMPRE" },
                { value: "OCCASIONALMENTE", text: "OCCASIONALMENTE" },
                { value: "MAI", text: "MAI" },
            ],
            tipoFruttiBoscoOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "CONGELATI", text: "CONGELATI" },
                { value: "FRESCHI", text: "FRESCHI" },
                { value: "CONGELATI E FRESCHI", text: "CONGELATI E FRESCHI" },
                { value: "NON NOTO", text: "NON NOTO" },
            ],
            doseVaccinoOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "SI, 1 DOSE", text: "SI, 1 DOSE" },
                { value: "SI, 2 DOSI", text: "SI, 2 DOSI" },
                { value: "NO", text: "NO" },
            ],
            doseVaccinoBOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "SI, 1 DOSE", text: "SI, 1 DOSE" },
                { value: "SI, 2 DOSI", text: "SI, 2 DOSI" },
                { value: "SI, 3 DOSI", text: "SI, 3 DOSI" },
                { value: "NO", text: "NO" },
            ],
            luogoInterventoOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "AMBULATORIO", text: "AMBULATORIO" },
                { value: "CON RICOVERO", text: "CON RICOVERO" },
            ],
            assunzioneDrogheOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "ENDOVENA", text: "ENDOVENA" },
                { value: "INALAZIONE", text: "INALAZIONE" },
                { value: "ENDOVENA E INALAZIONE", text: "ENDOVENA E INALAZIONE" },
                { value: "ALTRO", text: "ALTRO" },
                { value: "NON NOTO", text: "NON NOTO" },
            ],
            motivoMancataVaccinazioneOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "NON ERO SICURO DELL’EFFICACIA ", text: "NON ERO SICURO DELL’EFFICACIA " },
                { value: "PAURA DEGLI EFFETTI COLLATERALI", text: "PAURA DEGLI EFFETTI COLLATERALI" },
                { value: "NON SAPEVA A QUALE STRUTTURA RIVOLGERSI", text: "NON SAPEVA A QUALE STRUTTURA RIVOLGERSI" },
                { value: "ALTRO", text: "ALTRO" },
            ],
            fieldsContatti: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Cognome",
                    key: "cognome",
                    thStyle: "width: 20rem",
                    sortable: false,
                },
                {
                    label: "Nome",
                    key: "nome",
                    thStyle: "width: 20rem",
                    sortable: false,
                },
                {
                    label: "Comune",
                    key: "comune",
                    thStyle: "width: 10rem",
                    sortable: false,
                },
                {
                    label: "Indirizzo",
                    key: "indirizzo",
                    // thStyle: "width: 5rem",
                    sortable: false,
                },
                {
                    label: "Telefono",
                    key: "telefono",
                    // thStyle: "width: 5rem",
                    sortable: false,
                },
                {
                    label: "",
                    key: "actions",
                    sortable: false,
                    thStyle: "width: 4rem",
                },
            ],
            fieldsEsperienzeComuni: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Pasto/Esperienza Comune",
                    key: "pastoEsperienzaComune",
                    thStyle: "width: 11rem",
                    sortable: false,
                },
                {
                    label: "Quando",
                    key: "quando",
                    thStyle: "width: 11rem",
                    sortable: false,
                },
                {
                    label: "Dove",
                    key: "dove",
                    thStyle: "width: 11rem",
                    sortable: false,
                },
                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 4rem",
                    tdClass: "text-center",
                },
            ],
            fieldsNegozi: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Negozio",
                    key: "negozio",
                    // thStyle: "width: 11rem",
                    sortable: false,
                },
                {
                    label: "Sempre",
                    key: "sempre",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
                {
                    label: "Spesso",
                    key: "spesso",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
                {
                    label: "Qualche Volta",
                    key: "qualcheVolta",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 4rem",
                    tdClass: "text-center",
                },
            ],
            fieldsAlimenti: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Alimento",
                    key: "alimento",
                    thStyle: "width: 11rem",
                    sortable: false,
                },
                {
                    label: "Sottotipo/Modalita",
                    key: "sottotipoModalita",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
                {
                    label: "Marca",
                    key: "marca",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
                {
                    label: "L. Acquisto",
                    key: "luogoAcquisto",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
                {
                    label: "D. Acquisto",
                    key: "dataAcquisto",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
                {
                    label: "L. Preparazione",
                    key: "luogoPreparazione",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
                {
                    label: "D. Preparazione",
                    key: "dataPreparazione",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
                {
                    label: "L. Consumo",
                    key: "luogoConsumo",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
                {
                    label: "D. Consumo",
                    key: "dataConsumo",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
                {
                    label: "Tipologia",
                    key: "tipologia",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
            ],
            casoMalattiaOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "SALMONELLOSI", text: "SALMONELLOSI" },
                { value: "SHIGHELLOSI", text: "SHIGHELLOSI" },
                { value: "CAPYLOBACTERIOSI", text: "CAPYLOBACTERIOSI" },
                { value: "INFEZIONE DA YERSINIA ENTEROCOLITICA E PSEUDOTUBERCOLOSIS", text: "INFEZIONE DA YERSINIA ENTEROCOLITICA E PSEUDOTUBERCOLOSIS" },
                { value: "INFEZIONE DA NOROVIRUS", text: "INFEZIONE DA NOROVIRUS" },
                { value: "CLOSTRIDIUM BOTULINUM", text: "CLOSTRIDIUM BOTULINUM" },
            ],
            tipologiaOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "FRESCO", text: "FRESCO" },
                { value: "REFRIGERATO", text: "REFRIGERATO" },
                { value: "CONGELATO", text: "CONGELATO" },
            ],
            nonAlimentiTipoOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "TRASMISSIONE INTERUMANA PER MANCATO RISPETTO DELLE NORME IGIENICHE DI BASE", text: "TRASMISSIONE INTERUMANA PER MANCATO RISPETTO DELLE NORME IGIENICHE DI BASE" },
                { value: "DA PRATICHE INFANTILI NON IGIENICHE", text: "DA PRATICHE INFANTILI NON IGIENICHE" },
            ],
            ulterioriAzioniOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "ALIMENTO NON IDENTIFICATO", text: "ALIMENTO NON IDENTIFICATO" },
                { value: "ALIMENTO SOSPETTO, NESSUN ALTRA AZIONE DA INTRAPRENDERE", text: "ALIMENTO SOSPETTO, NESSUN ALTRA AZIONE DA INTRAPRENDERE" },
                { value: "AVVISO AL SETTORE DI SICUREZZA ALIMENTARE", text: "AVVISO AL SETTORE DI SICUREZZA ALIMENTARE" },
                { value: "PRESENZA DI UN FOCOLAIO, PROCEDERE CON INDAGINE SPECIFICA", text: "PRESENZA DI UN FOCOLAIO, PROCEDERE CON INDAGINE SPECIFICA" },
            ],
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient() {
            return UtilityMixin.methods.getLinkPatient(this.utente, this.idPaziente);
        },
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.jsonData.idAnagrafica = me.idPaziente;
        me.jsonData.id = me.id;
        me.loadMalattiaInfettivaSegnalazione();
        me.loadNazioni();
        me.loadData();
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
        "jsonData.contattiStessaSintomatologia": function (value) {
            let me = this;
            if (value === "SI") {
                me.jsonData.listaContatti.push({ cognome: "", nome: "", comune: "", indirizzo: "", telefono: "" });
            } else {
                me.jsonData.listaContatti = [];
            }
        },
        "jsonData.contattiStessaSintomatologiaConsumatoPasti": function (value) {
            let me = this;
            if (value === "SI") {
                me.jsonData.listaEsperienzeComuni.push({ pastoEsperienzaComune: "", quando: "", dove: "" });
            } else {
                me.jsonData.listaEsperienzeComuni = [];
            }
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            me.showModalLoading = true;
            axios
                .get(link + me.id)
                .then((response) => {
                    me.jsonData = response.data.data;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.setDatiMedico();
                    me.getListaAlimenti();
                    me.showModalLoading = false;
                });
        },
        getListaAlimenti() {
            let me = this;
            me.jsonData.listaAlimentiConsumatiCarne = [];
            me.jsonData.listaAlimentiConsumatiPesce = [];
            me.jsonData.listaAlimentiConsumatiUova = [];
            me.jsonData.listaAlimentiConsumatiLatteLatticini = [];
            me.jsonData.listaAlimentiConsumatiVerdure = [];
            me.jsonData.listaAlimentiConsumatiProdottiDolciForno = [];
            me.jsonData.listaAlimentiConsumatiProdottiProntiCosumo = [];
            let link = process.env.VUE_APP_PATH_API + me.pathResourceSottotipoModalita;
            axios.get(link).then((response) => {
                me.elaboraRisposta(response.data.data.list);
            });
        },
        elaboraRisposta(risposta) {
            let me = this;
            risposta.forEach((element) => {
                switch (element.famiglia) {
                    case "CARNE":
                        me.jsonData.listaAlimentiConsumatiCarne.push({ ordine: element.id, alimento: element.alimento, sottotipoModalita: element.sottotipoModalita, famiglia: element.famiglia, tipologia: "" });
                        break;
                    case "PESCE":
                        me.jsonData.listaAlimentiConsumatiPesce.push({ ordine: element.id, alimento: element.alimento, sottotipoModalita: element.sottotipoModalita, famiglia: element.famiglia, tipologia: "" });
                        break;
                    case "UOVA":
                        me.jsonData.listaAlimentiConsumatiUova.push({ ordine: element.id, alimento: element.alimento, sottotipoModalita: element.sottotipoModalita, famiglia: element.famiglia, tipologia: "" });
                        break;
                    case "LATTE/LATTICINI":
                        me.jsonData.listaAlimentiConsumatiLatteLatticini.push({ ordine: element.id, alimento: element.alimento, sottotipoModalita: element.sottotipoModalita, famiglia: element.famiglia, tipologia: "" });
                        break;
                    case "VERDURE":
                        me.jsonData.listaAlimentiConsumatiVerdure.push({ ordine: element.id, alimento: element.alimento, sottotipoModalita: element.sottotipoModalita, famiglia: element.famiglia, tipologia: "" });
                        break;
                    case "PRODOTTI DOLCI E DA FORNO":
                        me.jsonData.listaAlimentiConsumatiProdottiDolciForno.push({ ordine: element.id, alimento: element.alimento, sottotipoModalita: element.sottotipoModalita, famiglia: element.famiglia, tipologia: "" });
                        break;
                    case "ALIMENTI PRONTI AL CONSUMO/  ALIMENTI TRAFORMATI REFRIGERATI CON DURATA PROLUNGATA":
                        me.jsonData.listaAlimentiConsumatiProdottiProntiCosumo.push({ ordine: element.id, alimento: element.alimento, sottotipoModalita: element.sottotipoModalita, famiglia: element.famiglia, tipologia: "" });
                        break;
                }
            });
        },
        loadMalattiaInfettivaSegnalazione() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceMalattiaInfettivaSegnalazione + "/";
            axios
                .get(link + me.id)
                .then((response) => {
                    me.setDatiMalattia(response.data.data);
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        setDatiMedico() {
            let me = this;
            me.jsonData.sanitarioNotificante = Vue.prototype.user.firstname + " " + Vue.prototype.user.lastname;
        },
        setDatiMalattia(data) {
            let me = this;
            me.jsonData.id = data.id;
        },
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
        },
        loadNazioni() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkNazioni;
            axios.get(link).then((response) => {
                me.nazioniOptions = response.data.data;
            });
        },
        onAddFarmaci(array, value) {
            if (value.farmaco !== "" && value.tempo !== "") {
                array.push({ farmaco: "", tempo: "" });
            } else {
                this.$bvModal
                    .msgBoxOk("Le Date di Inizio e Fine sono OBBLIGATORIE!", {
                        title: "ATTENZIONE",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    })
                    .then((value) => {
                        console.log(value);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        onDelete(array, row) {
            if (row.index != array.length) {
                this.$bvModal
                    .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
                        title: "Titolo",
                        buttonSize: "sm",
                        okVariant: "secondary",
                        cancelVariant: "danger",
                        okTitle: "SI",
                        cancelTitle: "NO",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true,
                    })
                    .then((value) => {
                        if (value) {
                            array.splice(row.index, 1);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        onAttivaTabella() {
            let me = this;
            if (me.jsonData.listaFarmaci.length === 0) {
                me.jsonData.listaFarmaci = [];
            }
            if (me.jsonData.seiSettimanePrimaAssunzioneFarmaci === "SI") {
                me.jsonData.listaFarmaci.push({ farmaco: "", tempo: "" });
            }
        },
        onAddContatto(array, value) {
            if (value.cognome !== "" && value.nome !== "" && value.comune !== "" && value.indirizzo !== "" && value.telefono !== "") {
                array.push({ cognome: "", nome: "", comune: "", indirizzo: "", telefono: "" });
            } else {
                this.$bvModal
                    .msgBoxOk("Campi OBBLIGATORI!", {
                        title: "ATTENZIONE",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    })
                    .then((value) => {
                        console.log(value);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        onAddEsperienzaComune(array, value) {
            if (value.pastoEsperienzaComune !== "" && value.quando !== "" && value.dove !== "") {
                array.push({ pastoEsperienzaComune: "", quando: "", dove: "" });
            } else {
                this.$bvModal
                    .msgBoxOk("Campi OBBLIGATORI!", {
                        title: "ATTENZIONE",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    })
                    .then((value) => {
                        console.log(value);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        onAddNegozio(array, value) {
            if (value.negozio !== "") {
                array.push({ negozio: "", sempre: false, spesso: false, qualcheVolta: false });
            } else {
                this.$bvModal
                    .msgBoxOk("Campi OBBLIGATORI!", {
                        title: "ATTENZIONE",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    })
                    .then((value) => {
                        console.log(value);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        // onDelete(array, row) {
        //     if (row.index != array.length) {
        //         this.$bvModal
        //             .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
        //                 title: "Titolo",
        //                 buttonSize: "sm",
        //                 okVariant: "secondary",
        //                 cancelVariant: "danger",
        //                 okTitle: "SI",
        //                 cancelTitle: "NO",
        //                 footerClass: "p-2",
        //                 hideHeaderClose: false,
        //                 centered: true,
        //             })
        //             .then((value) => {
        //                 if (value) {
        //                     array.splice(row.index, 1);
        //                 }
        //             })
        //             .catch((err) => {
        //                 console.log(err);
        //             });
        //     }
        // },
    },
};
</script>
