<template>
    <div style="height: 100%">
        <div class="sa-flexbox" style="padding-bottom: 20px">
            <div class="sa-flexbox-body">
                <div class="b-table-sticky-header">
                    <b-table sticky-header ref="table" stacked="lg" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="row">
                            {{ row.index + 1 }}
                        </template>

                        <template v-slot:cell(actions)="{ item }">
                            <b-container class="bv-example-row">
                                <b-button size="sm" variant="outline-success" v-b-tooltip.hover title="Aggiorna turno" @click="onClickEditTurno(item)"><font-awesome-icon icon="edit" /> </b-button>
                                <b-button size="sm" v-b-tooltip.hover title="Elimina turno" variant="outline-danger" @click="onClickEliminaTurno(item)"><font-awesome-icon icon="trash" /> </b-button>
                            </b-container>
                        </template>
                    </b-table>
                </div>
                <rsa-configurazione-turni-modal-edit-component ref="cmpConfigurazioneTurnoModalEdit" @onSave="onSaveTurno" @onClose="onCloseModal" @onDeleteTurno="loadData" />
            </div>
            <div class="sa-list-component-footer">
                <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col lg="3">
                        <p>Record Totali:{{ rows }}</p>
                    </b-col>
                    <b-col lg="9">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                    </b-col>
                </b-row>
            </div>
        </div>
    </div>
</template>
<script>
import RsaConfigurazioneTurniModalEditComponent from "./RsaConfigurazioneTurniModalEditComponent.vue";
import moment from "moment";
import UtilityMixin from "../../../utility/UtilityMixin";

export default {
    mixins: [UtilityMixin],
    components: { RsaConfigurazioneTurniModalEditComponent },
    data() {
        return {
            pathResource: "/rsaconfigurazioneturni",
            showModalLoading: false,
            listKey: 0,
            rows: 0,
            perPage: 50,
            currentPage: 1,
            items: [],
            filtro: {},
            fields: [
                {
                    label: "",
                    key: "index",
                },
                {
                    label: "Turno",
                    key: "label",
                },
                {
                    label: "Ora Inizio",
                    key: "oraInizio",
                    formatter: (value) => {
                        if (value) {
                            return moment(new Date(value)).format("HH:mm");
                        } else {
                            return "-----";
                        }
                    },
                },
                {
                    label: "Ora fine",
                    key: "oraFine",
                    formatter: (value) => {
                        if (value) {
                            return moment(new Date(value)).format("HH:mm");
                        } else {
                            return "-----";
                        }
                    },
                },
                {
                    class: "sa-table-column-action-double",
                    key: "actions",
                    label: "",
                },
            ],
        };
    },
    mounted() {
        this.loadData();
    },

    watch: {
        currentPage() {
            let me = this;
            me.loadData();
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            me.filtro.page = me.currentPage;
            me.filtro.forPage = me.perPage;
            UtilityMixin.methods.loadDataResources(me.pathResource, me.filtro, me.loadDataSuccess, me.loadDataError);
        },
        loadDataSuccess(response) {
            let me = this;
            //console.log(response);

            me.items = response.data.data.list;
            me.rows = response.data.data.recordsNumber;
            me.showModalLoading = false;
        },
        loadDataError(error) {
            let me = this;
            console.error("Errore nel caricamento dei dati:", error);
            me.showModalLoading = false;
            me.$emit("afterLoadData");
            me.$bvToast.toast("Errore nel caricamento dei dati", {
                title: "Errore",
                variant: "danger",
                solid: true,
            });
        },
        onClickEditTurno(item) {
            let me = this;
            me.$refs.cmpConfigurazioneTurnoModalEdit.show(item);
        },
        onClickNuovoTurno() {
            let me = this;
            me.$refs.cmpConfigurazioneTurnoModalEdit.show({
                id: null,
                label: null,
                oraInizio: null,
                oraFine: null,
            });
        },
        onClickEliminaTurno(item) {
            let me = this;
            me.$refs.cmpConfigurazioneTurnoModalEdit.onDeleteTurno(item);
        },

        onCloseModal() {
            this.loadData();
        },
        onSaveTurno() {
            this.loadData();
        },
    },
};
</script>
