<template>
    <b-modal ref="mdlEditSpesaExtra" id="mdlEditSpesaExtra" :title="isNew ? 'Aggiungi Spesa Extra' : 'Aggiorna Spesa Extra'" centered no-close-on-backdrop no-close-on-esc hide-header-close>
        <b-row>
            <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <span class="sa-form-label">Spesa Extra</span>
                <b-form-select v-model="jsonDataSpesaExtra.idSpeseExtra" :options="listaSpesaExtra" value-field="id" text-field="descrizione" @change="onSpesaExtraChange" required>
                    <template #first>
                        <b-form-select-option :value="null">- Seleziona Spesa Extra -</b-form-select-option>
                    </template>
                </b-form-select>
                <b-form-invalid-feedback>Selezionare una spesa extra</b-form-invalid-feedback>
            </b-col>

            <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <span class="sa-form-label">Data e Ora Inizio*</span>
                <date-picker v-model="jsonDataSpesaExtra.dataOraInizio" required></date-picker>
            </b-col>
        </b-row>
        <b-row class="mt-3">
            <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <span class="sa-form-label">Importo Totale*</span>
                <b-form-input v-model="jsonDataSpesaExtra.importo" type="number" step="0.01" :readonly="spesaSelezionata && !spesaSelezionata.importoLibero" required></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <span class="sa-form-label">IVA (%)*</span>
                <b-form-input v-model="jsonDataSpesaExtra.aliquotaIva" type="number" step="1" min="0" max="100" required readonly></b-form-input>
            </b-col>
        </b-row>

        <b-row class="mt-3">
            <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <span class="sa-form-label">Imponibile*</span>
                <b-form-input v-model="jsonDataSpesaExtra.imponibile" type="number" step="0.01" readonly required></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <span class="sa-form-label">Quota IVA*</span>
                <b-form-input v-model="quotaIva" type="number" readonly></b-form-input>
            </b-col>
        </b-row>

        <b-row class="mt-3">
            <b-col cols="12">
                <span class="sa-form-label">Dati Fattura</span>
                <b-form-textarea v-model="jsonDataSpesaExtra.datiFattura" max-rows="8" rows="3"></b-form-textarea>
            </b-col>
        </b-row>

        <template #modal-footer>
            <b-button size="md" variant="outline-danger" @click="onCloseModal">Annulla</b-button>
            <b-button size="md" variant="outline-success" @click="onClickOk()">Salva</b-button>
        </template>
    </b-modal>
</template>

<script>
import UtilityMixin from "../../../utility/UtilityMixin";
import DatePicker from "vue2-datepicker";
import axios from "axios";

export default {
    name: "RsaSpesaExtraEdit",
    mounted() {
        let me = this;
        me.loadSpeseExtra();
    },
    props: {
        idAccettazione: { type: String },
    },
    components: { DatePicker },
    mixins: [UtilityMixin],
    data() {
        return {
            isNew: true,
            pathResource: "/rsaspeseextrapazienti",
            pathResourceSpesaExtra: "/rsaspeseextra",
            validazioneErrori: {},
            listaSpesaExtra: [],
            spesaSelezionata: null,
            quotaIva: 0,
            jsonDataSpesaExtra: {
                id: null,
                idAccettazione: null,
                idSpeseExtra: null,
                idFattura: null,
                dataOraInizio: null,
                dataOraFine: null,
                spesaExtra: null,
                importo: 0,
                importoFattura: 0,
                aliquotaIva: 0,
                descrizioneIva: null,
                codiceIva: null,
                imponibile: 0,
                totale: 0,
                datiFattura: null,
                statoAttuale: "INSERITO",
                statoAttualeDataOra: new Date(),
            },
        };
    },

    watch: {
        "jsonDataSpesaExtra.importo": {
            handler: "calcolaTotali",
            immediate: true,
        },
        "jsonDataSpesaExtra.aliquotaIva": {
            handler: "calcolaTotali",
            immediate: true,
        },
    },

    methods: {
        loadSpeseExtra() {
            UtilityMixin.methods.loadDataResources(this.pathResourceSpesaExtra, null, this.loadSpeseExtraSuccess, this.loadSpeseExtraError);
        },

        loadSpeseExtraSuccess(response) {
            this.listaSpesaExtra = response.data.data.list || [];
        },
        loadSpeseExtraError(error) {
            console.error("Errore caricamento spese extra:", error);
            this.$bvToast.toast("Errore nel caricamento delle spese extra", {
                title: "Errore",
                variant: "danger",
                solid: true,
            });
        },

        onSpesaExtraChange(idSpesaExtra) {
            let me = this;
            me.selectedSpesaExtra = me.listaSpesaExtra.find((s) => s.id === idSpesaExtra);
            if (me.selectedSpesaExtra && !me.selectedSpesaExtra.importoLibero) {
                me.jsonDataSpesaExtra.importo = me.selectedSpesaExtra.importo;
                me.jsonDataSpesaExtra.aliquotaIva = me.selectedSpesaExtra.aliquotaIva * 100;
                me.jsonDataSpesaExtra.descrizioneIva = me.selectedSpesaExtra.descrizioneIva;
                me.jsonDataSpesaExtra.codiceIva = me.selectedSpesaExtra.codiceIva;
                me.jsonDataSpesaExtra.spesaExtra = me.selectedSpesaExtra.descrizione;
            }
        },

        validationState(field) {
            return this.validazioneErrori[field] ? false : null;
        },

        calcolaTotali() {
            let me = this;
            const importoTotale = me.jsonDataSpesaExtra.importo;
            const aliquotaIva = me.jsonDataSpesaExtra.aliquotaIva / 100;
            me.quotaIva = importoTotale * aliquotaIva.toFixed(2);
            me.jsonDataSpesaExtra.imponibile = importoTotale - me.quotaIva.toFixed(2);
        },

        aggiornaSpesaExtraPaziente(item) {
            let me = this;
            me.isNew = false;
            this.selectedSpesaExtra = this.listaSpesaExtra.find((s) => s.id === item.idSpeseExtra);
            me.jsonDataSpesaExtra = { ...item };
            me.$refs.mdlEditSpesaExtra.show();
            //NON EREDITA LA DATA, DA RIVEDERE.
        },

        nuovaSpesaExtraPaziente(idAccettazione) {
            let me = this;
            me.isNew = true;
            me.jsonDataSpesaExtra = {
                idAccettazione: idAccettazione,
                idSpeseExtra: null,
                spesaExtra: null,
                dataOraInizio: null /* .toISOString().split("T")[0] */,
                importo: 0,
                aliquotaIva: 0,
                descrizioneIva: null,
                codiceIva: null,
                imponibile: 0,
                totale: 0,
                datiFattura: null,
                statoAttuale: "INSERITO",
                statoAttualeDataOra: new Date(),
            };
            this.$refs.mdlEditSpesaExtra.show();
        },

        onCloseModal() {
            let me = this;
            me.jsonDataSpesaExtra = {
                idAccettazione: null,
                spesa: null,
                descrizioneSpesa: null,
                importo: 0,
                note: null,
            };
            me.$refs.mdlEditSpesaExtra.hide();
            me.$emit("onClose");
        },

        onClickOk() {
            UtilityMixin.methods.saveResource(this.pathResource, this.jsonDataSpesaExtra, this.saveSuccessResponse, this.saveErrorResponse);
        },

        saveSuccessResponse(response) {
            let me = this;
            me.$emit("afterSaveSuccess", response);
            me.$refs.mdlEditSpesaExtra.hide();
            me.$bvToast.toast("Salvataggio avvenuto con successo", {
                title: "Successo",
                variant: "success",
                solid: true,
            });
        },

        saveErrorResponse(response) {
            if (response.response.status === 400) {
                this.$bvModal.msgBoxOk(response.response.data.messaggio, {
                    title: "ATTENZIONE",
                    size: "sm",
                    okVariant: "outline-secondary",
                    headerClass: "sa-msg-header-danger",
                    footerClass: "p-2 border-top-0",
                    centered: true,
                });
            } else {
                this.$bvToast.toast("Errore nel salvataggio" + response.message, {
                    title: "Errore",
                    variant: "danger",
                    solid: true,
                });
            }
        },
        onDeleteSpesaExtra(item) {
            let me = this;
            this.$bvModal
                .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
                    title: "Eliminazione Spesa Extra",
                    buttonSize: "sm",
                    okVariant: "secondary",
                    cancelVariant: "danger",
                    okTitle: "SI",
                    cancelTitle: "NO",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                })
                .then((value) => {
                    if (value) {
                        me.deleteSpesaExtra(item.id);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        deleteSpesaExtra(id) {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + id;
            axios
                .delete(link)
                .then((response) => {
                    me.showModalLoading = false;
                    me.$emit("onDelete", response);
                    me.$bvToast.toast("Eliminazione avvenuta con successo", {
                        title: "Successo",
                        variant: "success",
                        solid: true,
                    });
                })
                .catch((e) => {
                    console.log(e);
                    me.showModalLoading = false;
                    me.$bvToast.toast("Errore durante l'eliminazione", {
                        title: "Errore",
                        variant: "danger",
                        solid: true,
                    });
                });
        },
    },
};
</script>
<style scoped>
.sa-form-label {
    font-weight: 600;
    font-size: 0.9rem;
}
</style>
