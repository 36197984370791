<template>
    <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <b-card class="sa-card sa-card-border" header="Anamnesi Patologica Remota" header-tag="header" footer-tag="footer" title="">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Patologia Principale</label>
                        <b-form-input v-model="jsonDataApr.patologiaPrincipale" :disabled="true"> </b-form-input>
                    </b-col>

                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Patologia Concomitante</label>
                        <b-form-input v-model="jsonDataApr.patologiaConcomitanti" :readonly="isReadOnly"> </b-form-input>
                    </b-col>

                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Emotrasfusionali</label>
                        <b-form-select v-model="jsonDataApr.emotrasfusionali" :options="emotrasfusionaliOptions" :disabled="isReadOnly">
                            <template #first>
                                <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-col>

                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Reazioni Trasfusionali</label>
                        <b-form-select v-model="jsonDataApr.reazioniTrasfusionali" :options="reazioniTrasfusionaliOptions" :disabled="isReadOnly">
                            <template #first>
                                <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-col>

                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <label class="sa-label-data">Accessi Venosi Periferici</label>
                        <b-form-select v-model="jsonDataApr.accessiVenosiPeriferici" :options="accessiVenosiPerifericiOptions" :disabled="isReadOnly">
                            <template #first>
                                <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-col>

                    <!-- 
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Patologia Principale</label>
                        <b-form-input v-model="jsonDataApr.patologiaPrincipale" :disabled="isReadOnly"> </b-form-input>
                    </b-col> -->
                    <!-- <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" v-for="allergia in jsonDataApr.listaAllergie" :key="/* 'ausilio-' +  */ allergia.id" class="sa-switch-select-element">
                        <b-form-checkbox switch :disabled="isReadOnly" v-model="jsonDataApr.listaAllergie" :value="allergia.descrizione" :readonly="isReadOnly">
                            <span style="font-weight: normal">{{ allergia.descrizione }}</span> 
                        </b-form-checkbox>
                    </b-col> -->
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <label class="sa-label-data">Allergie</label>
                        <b-form-textarea v-model="listaAllergie" max-rows="5" rows="3" :readonly="true"> </b-form-textarea>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <label class="sa-label-data">Positività sierologiche</label>
                        <!--     <b-form-input v-model="listaPositivitaSierologiche" :readonly="true"> </b-form-input> -->
                        <b-form-textarea v-model="listaPositivitaSierologiche" max-rows="5" rows="3" :readonly="true"> </b-form-textarea>
                    </b-col>

                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <label class="sa-label-data">Note</label>
                        <b-form-textarea v-model="jsonDataApr.note" :readonly="isReadOnly" max-rows="10" rows="5"> </b-form-textarea>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>

        <!-- <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <b-card class="sa-card sa-card-border" header="Allergie: " header-tag="header" footer-tag="footer" title="">
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-row>
                        <b-col cols="auto" v-for="allergie in allergieOptions" :key="'allergie-' + allergie.id" class="sa-switch-select-element">
                            <b-form-checkbox switch :disabled="isReadOnly" v-model="selectedAllergie" :value="allergie.voce">
                                <span style="font-weight: normal">{{ allergie.voce }}</span>
                            </b-form-checkbox>
                        </b-col>
                    </b-row>
                </b-col>
            </b-card>
        </b-col> -->

        <!-- <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <b-card class="sa-card sa-card-border" header="Positivita' sierologiche: " header-tag="header" footer-tag="footer" title="">
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-row>
                        <b-col cols="auto" v-for="sierologia in sierologiaOptions" :key="'sierologia-' + sierologia.id" class="sa-switch-select-element">
                            <b-form-checkbox switch :disabled="isReadOnly" v-model="selectedSierologia" :value="sierologia.voce">
                                <span style="font-weight: normal">{{ sierologia.voce }}</span>
                            </b-form-checkbox>
                        </b-col>
                    </b-row>
                </b-col>
            </b-card>
        </b-col> -->
    </b-row>
</template>
<script>
import UtilityMixin from "../../../utility/UtilityMixin";

export default {
    props: {
        isReadOnly: {
            type: Boolean,
            default: false,
        },
        idAccettazione: {
            type: String,
        },
    },
    /* mounted() {
        let me = this;
        me.loadDizionarioData();
    }, */

    mixins: [UtilityMixin],
    data() {
        return {
            pathResourceSchedaInfermieristicaApr: "/rsaschedainfermieristicaanamnesipatologicaremota",
            pathResourceDizionario: "/rsadizionario",
            pathResourceRiferimentiDiagnostici: "/rsaschedariferimentidiagnostici",
            allergieOptions: [],
            sierologiaOptions: [],
            selectedAllergie: [],
            selectedSierologia: [],
            listaAllergie: "",
            listaPositivitaSierologiche: "",
            jsonDataApr: {
                id: null,
                idAccettazione: null,
                accessiVenosiPeriferici: null,
                patologiaPrincipale: null,
                emotrasfusionali: null,
                patologiaConcomitanti: null,
                reazioniTrasfusionali: null,
                note: null,
                /* listaSchedaAprDettaglio: [], */
            },

            accessiVenosiPerifericiOptions: [
                { text: "ADEGUATI", value: "ADEGUATI" },
                { text: "INADEGUATI", value: "INADEGUATI" },
                { text: "DA VALUTARE", value: "DA VALUTARE" },
            ],
            emotrasfusionaliOptions: [
                { text: "SI", value: "SI" },
                { text: "NO", value: "NO" },
                { text: "DA VALUTARE", value: "DA VALUTARE" },
            ],
            reazioniTrasfusionaliOptions: [
                { text: "SI", value: "SI" },
                { text: "NO", value: "NO" },
                { text: "DA VALUTARE", value: "DA VALUTARE" },
            ],
        };
    },
    methods: {
        /* loadDizionarioData() {
            let me = this;
            UtilityMixin.methods.loadDataResources(me.pathResourceDizionario, null, me.loadDizionarioDataSuccess, me.loadDizionarioDataError);
        },

        loadDizionarioDataSuccess(response) {
            let me = this;
            if (response.data?.data?.list) {
                me.allergieOptions = response.data.data.list.filter((item) => item.tipo === "ALLERGIA");
                me.sierologiaOptions = response.data.data.list.filter((item) => item.tipo === "SIEROLOGIA");
            }
        },
        loadDizionarioDataError(error) {
            console.error("Errore nel caricamento allergie/sierologia", error);
        }, */

        loadAprData() {
            let me = this;
            // console.log("ID accettazione nel loadAprData: " + me.idAccettazione);
            /* me.selectedAllergie = [];
            me.selectedSierologia = [];
            me.loadDizionarioData(); */
            UtilityMixin.methods.loadDataResources(me.pathResourceSchedaInfermieristicaApr + "/accettazione/" + me.idAccettazione, {}, me.resultResponseSchedaInfermieristicaAprSuccess, me.resultResponseSchedaInfermieristicaAprError);
        },
        resultResponseSchedaInfermieristicaAprSuccess(response) {
            let me = this;

            //  me.listaAllergie = "";

            me.jsonDataApr = response.data.data;
            me.setListaAllergie();
            me.setListaPositivitaSierologica();
            /* me.jsonDataApr.listaAllergie.forEach((element) => {
                me.listaAllergie += element.descrizione + ", ";
            });

            me.listaPositivitaSierologiche = "";
            me.jsonDataApr.listaPositivitaSierologiche.forEach((element) => {
                me.listaPositivitaSierologiche += element.descrizione + ", ";
            }); */
            /*  me.jsonDataApr.listaSchedaAprDettaglio.forEach((item) => {
                if (item.tipo === "ALLERGIE") {
                    me.selectedAllergie.push(item.voce);
                } else if (item.tipo === "SIEROLOGIA") {
                    me.selectedSierologia.push(item.voce);
                }
            }); */
        },
        resultResponseSchedaInfermieristicaAprError(error) {
            let me = this;
            if (error.response && error.response.status === 404) {
                //console.log("NESSUN record trovato del ANAMNESIA PATOLOGICA REMOTA per questo id accettazione");
                me.resetForm();
            } else {
                console.error("Errore nel caricamento dati della sezione ANAMNESIA PATOLOGICA REMOTA:", error);
            }
            me.loadRiferimentiDiagnostici();
        },

        resetForm() {
            let me = this;
            me.jsonDataApr = {
                id: null,
                idAccettazione: me.idAccettazione,
                accessiVenosiPeriferici: null,
                patologiaPrincipale: null,
                emotrasfusionali: null,
                patologiaConcomitanti: null,
                reazioniTrasfusionali: null,
                note: null,
                /* listaSchedaAprDettaglio: [], */
            };

            /* me.selectedReazioniDifesa = []; */
        },

        onSalvaApr() {
            let me = this;
            me.jsonDataApr.idAccettazione = me.idAccettazione;
            if (!me.jsonDataApr.idAccettazione) {
                me.$bvToast.toast("Errore: ID Accettazione mancante", {
                    title: "Errore",
                    variant: "danger",
                    solid: true,
                });
                return;
            }

            /* let listaSchedaAprDettaglio = [];
            me.selectedAllergie.forEach((voce) => {
                listaSchedaAprDettaglio.push({
                    id: null,
                    idSchedaInfermieristicaApr: me.jsonDataApr.id,
                    tipo: "ALLERGIA",
                    voce: voce,
                });
            }); */

            /*  me.selectedSierologia.forEach((voce) => {
                listaSchedaAprDettaglio.push({
                    id: null,
                    idSchedaInfermieristicaApr: me.jsonDataApr.id,
                    tipo: "SIEROLOGIA",
                    voce: voce,
                });
            }); */

            /*   me.jsonDataApr.listaSchedaAprDettaglio = listaSchedaAprDettaglio; */

            UtilityMixin.methods.saveResource(me.pathResourceSchedaInfermieristicaApr, me.jsonDataApr, me.saveSchedaEsameObiettivoGeneraleAddomeSuccessResponse, me.saveSchedaEsameObiettivoGeneraleAddomeErrorResponse);
        },
        saveSchedaEsameObiettivoGeneraleAddomeSuccessResponse(response) {
            console.log("ANAMNESIA PATOLOGICA REMOTA salvato con successo:", response);
        },
        saveSchedaEsameObiettivoGeneraleAddomeErrorResponse(error) {
            console.error("Errore nel salvataggio ANAMNESIA PATOLOGICA REMOTA:", error);
        },
        loadRiferimentiDiagnostici() {
            let me = this;
            let link = me.pathResourceRiferimentiDiagnostici + "/accettazione/" + me.idAccettazione;
            UtilityMixin.methods.loadDataResources(link, {}, me.resultResponseRiferimentiDiagnosticiSucces, me.resultResponseRiferimentiDiagnosticiError);
        },
        resultResponseRiferimentiDiagnosticiSucces(response) {
            let me = this;
            me.jsonDataApr.patologiaPrincipale = response.data.data.anamnesiPatologicaRemota;
            me.jsonDataApr.listaAllergie = response.data.data.listaAllergie;
            me.jsonDataApr.listaPositivitaSierologiche = response.data.data.listaPositivitaSierologiche;
            me.setListaAllergie();
            me.setListaPositivitaSierologica();
        },

        resultResponseRiferimentiDiagnosticiError(error) {
            if (error.response && error.response.status === 404) {
                //console.log("NESSUN record trovato del ANAMNESIA PATOLOGICA REMOTA per questo id accettazione");
            } else {
                console.error("Errore nel caricamento dati della sezione ANAMNESIA PATOLOGICA REMOTA:", error);
            }
        },
        setListaAllergie() {
            let me = this;
            me.listaAllergie = "";

            me.jsonDataApr.listaAllergie.forEach((element) => {
                me.listaAllergie += element.descrizione + ", ";
            });
        },
        setListaPositivitaSierologica() {
            let me = this;
            me.listaPositivitaSierologiche = "";
            me.jsonDataApr.listaPositivitaSierologiche.forEach((element) => {
                me.listaPositivitaSierologiche += element.descrizione + ", ";
            });
        },
    },
};
</script>
