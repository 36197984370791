<template>
    <div class="sa-list-component">
        <div class="sa-list-component-header">
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </div>
        <div class="sa-list-component-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" stacked="lg" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
                    <template v-slot:cell(codiceStruttura)="{ item }">
                        <span>{{ item.denominazioneStruttura }} ({{ item.codiceStruttura }})</span>
                    </template>
                    <template v-slot:cell(nome)="{ item }">
                        <router-link class="sa-edit-link" :to="'/rsaconfigurazionepostilettopiani/view/' + item.id">{{ item.nome }}</router-link>
                    </template>
                    <template #cell(actions)="{ item }">
                        <!-- <b-button size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onUpdatePostiLetto(row.item)">
                            <i class="fas fa-bed"></i>
                        </b-button> -->
                        <b-button size="sm" class="mr-1 ml-1 no-text" variant="outline-secondary" @click="onClickDuplicatePiano(item)" title="Duplica">
                            <i class="far fa-copy"></i>
                        </b-button>
                    </template>
                </b-table>
            </div>
            <b-modal ref="mdlDuplicaPiano" size="lg" title="Inserire Nome Nuovo Piano" @ok="onMdlDuplicaPiano()">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-label-data">Struttura</label>
                        <span class="sa-data">{{ nuovoPiano.denominazioneStruttura }}</span>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-label-data">Presidio</label>
                        <span class="sa-data">{{ nuovoPiano.denominazionePresidio }}</span>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-label-data">Nome</label>
                        <b-form-input v-model="nuovoPiano.nome" placeholder="Nome del Piano"></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <label class="sa-label-data">Note</label>
                        <b-form-textarea v-model="nuovoPiano.note" rows="5" no-resize></b-form-textarea>
                    </b-col>
                </b-row>
            </b-modal>
        </div>
        <div class="sa-list-component-footer">
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import UtilityMixin from "../../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixin],
    data() {
        return {
            rows: 0,
            perPage: 50,
            currentPage: 1,
            pathResource: "/rsapostilettopiani",
            nuovoPiano: {},
            filtro: {},
            items: [],
            listKey: 0,
            fields: [
                {
                    label: "Struttra",
                    key: "codiceStruttura",
                    sortable: true,
                    thStyle: "width: 18rem",
                },
                {
                    label: "Presidio",
                    key: "denominazionePresidio",
                    sortable: true,
                    thStyle: "width: 18rem",
                },
                {
                    label: "Nome",
                    key: "nome",
                    sortable: true,
                    thStyle: "width: 16rem",
                },
                {
                    label: "Note",
                    key: "note",
                    sortable: true,
                },
                {
                    label: "",
                    tdClass: "text-center",
                    thStyle: "width: 5rem",
                    key: "actions",
                },
            ],
        };
    },
    methods: {
        loadData() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            if (sessionStorage["filtro"]) {
                me.filtro = JSON.parse(sessionStorage["filtro"]);
            }
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.filtro.page = me.currentPage;
            me.filtro.forPage = me.perPage;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];

                    me.rows = response.data.data.recordsNumber;
                    me.items = response.data.data.list;

                    me.listKey++;
                    this.$emit("afterLoadData");
                })
                .catch(() => {
                    this.$emit("afterLoadData");
                });
        },
        onClickDuplicatePiano(item) {
            let me = this;
            me.nuovoPiano = {};
            me.nuovoPiano.nome = null;
            me.nuovoPiano.note = null;
            me.nuovoPiano.codiceStruttura = item.codiceStruttura;
            me.nuovoPiano.denominazionePresidio = item.denominazionePresidio;
            me.nuovoPiano.denominazioneStruttura = item.denominazioneStruttura;
            me.nuovoPiano.id = null;
            me.nuovoPiano.idPianoSorgente = item.id;
            me.nuovoPiano.idPresidio = item.idPresidio;
            me.$refs.mdlDuplicaPiano.show();
        },
        onMdlDuplicaPiano() {
            let me = this;
            this.$bvModal
                .msgBoxConfirm("Si desidera duplicare anche le stanze e i letti?", {
                    title: "Richiesta duplicazione",
                    buttonSize: "sm",
                    okVariant: "secondary",
                    cancelVariant: "danger",
                    okTitle: "SI",
                    cancelTitle: "NO",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                })
                .then((value) => {
                    if (value) {
                        console.log("Duplico Tutto");
                        me.nuovoPiano.duplicaStanze = true;
                        me.$refs.mdlDuplicaPiano.hide();
                    } else {
                        console.log("Duplico Solo il piano");
                        me.nuovoPiano.duplicaStanze = false;
                        me.$refs.mdlDuplicaPiano.hide();
                    }
                    me.duplicaStanze();
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        duplicaStanze() {
            let me = this;
            let link = me.pathResource + "/duplicapiano";
            UtilityMixin.methods.saveResource(link, me.nuovoPiano, me.saveSucessResponse, me.saveErrorResponse);
        },
        saveSucessResponse() {
            let me = this;
            me.loadData();
        },
        saveErrorResponse(response) {
            if (response.response.status === 400) {
                this.$emit("errorRestCall", response);
            } else {
                this.$bvToast.toast("Errore nel salvarei dati del Piano: " + response.message, {
                    title: "",
                    variant: "danger",
                    solid: true,
                });
            }
        },
    },
};
</script>

<style></style>
