<template>
    <sa-page-layout :btnNewVisible="btnNewVisible" :linkedit="linkedit" :btnRefreshVisible="true" @refresh="onRefresh" :showModalLoading="showModalLoading">
        <template slot="table-filter">
            <b-form @submit.prevent="onSubmit">
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-label-data">Codice Fiscale</label>
                        <b-form-input v-model="filtro.codiceFiscale" type="search" id="codiceFiscale" :placeholder="this.$i18n.t('patients.lblFiscalCode')"></b-form-input>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-label_data">Stato</label>
                        <b-form-select v-model="filtro.stato" :options="statiOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-label_data">Ambulatorio</label>
                        <b-form-select v-model="filtro.idAmbulatorio" :options="ambulatoriOptions" :value="null" value-field="value" text-field="text" @change="onSelectAnbulatorio"></b-form-select>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-label_data">Prestazione</label>
                        <b-form-select v-model="filtro.prestazione" :options="prestazioniOptions" :value="null" value-field="value" text-field="text" :disabled="disableSelectPrestazioni"></b-form-select>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-label_data">Data Dal</label>
                        <date-picker v-model="filtro.dataDal" format="DD-MM-YYYY" value-type="timestamp" type="date"></date-picker>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-label_data">Data Al</label>
                        <date-picker v-model="filtro.dataAl" format="DD-MM-YYYY" value-type="timestamp" type="date"></date-picker>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="sa-padding-right text-right">
                        <b-button type="submit" variant="info">{{ this.$i18n.t("patients.lblFind") }}</b-button>
                        <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("patients.lblResetFilter") }}</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </template>
        <template slot="table-header">
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <div class="b-table-sticky-header">
                <b-alert v-if="!isAmbulatorioPresent" show variant="danger">ATTENZIONE: Nessuno Ambulatorio Associato a per l'utente</b-alert>
                <b-table sticky-header ref="table" :filter="filter" stacked="lg" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                    <template v-slot:cell(nominativo)="{ item }">
                        <router-link class="sa-edit-link" :to="linkedit + '/view/' + item.id">{{ item.cognome }} {{ item.nome }}</router-link>
                        <br />
                        {{ item.identificativo }}
                        <br />
                        <b>Telefono:</b>
                        {{ item.telefono }}
                        <br />
                        <b>E-Mail:</b>
                        {{ item.email }}
                    </template>
                    <template v-slot:cell(actions)="row">
                        <visita-component v-if="btnVisita" :accettazione="row.item" size="sm" @beforLoadEventoTipoPrestazione="onBeforLoadEventoTipoPrestazione" @afterLoadEventoTipoPrestazione="onAfterLoadEventoTipoPrestazione"></visita-component>
                        <condivisione-modal v-if="btnCondivisione" :idRisorsa="row.item.id" idSezione="accettazione"></condivisione-modal>
                        <print-component :data="row" :linkPrintData="linkPrintData(row.item)" typeButton="small"></print-component>
                        <b-button v-if="row.item.statoFirma === 'FIRMATO'" size="sm" variant="outline-success no-text" style="margin-right: 3px" @click="onPrintPreview(row.item)">
                            <i class="fas fa-file-alt"></i>
                        </b-button>
                        <!-- <print-component v-if="row.item.statoFirma !== 'FIRMATO'" :jsonData="row.item" :linkPrintData="linkPrintDataConsensi(row.item)" typeButton="small" :firmaGrafometrica="true" :nomeFile="nomeFile" :descrizione="descrizione" :tipoDocumento="tipoDocumento" :codiceApplicativo="codiceApplicativo"></print-component> -->
                    </template>
                </b-table>
            </div>
        </template>
        <template slot="table-footer">
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
            <pdf-print-view-embedded ref="pdfPrintViewEmbedded" :pdf="signedPdfViewer"></pdf-print-view-embedded>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
// import Vue from "vue";
import DatePicker from "vue2-datepicker";
import UtilityMixin from "../../../utility/UtilityMixin";
import CondivisioneModal from "../../../utility/components/CondivisioneModal.vue";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import VisitaComponent from "../components/VisitaComponent.vue";
import PrintComponent from "../../../utility/components/PrintComponent.vue";
import PdfPrintViewEmbedded from "../../../../app/utility/components/PdfPrintViewEmbedded.vue";
export default {
    props: {
        utente: Object,
    },
    components: { SaPageLayout, DatePicker, CondivisioneModal, VisitaComponent, PrintComponent, PdfPrintViewEmbedded },
    mixins: [UtilityMixin],
    computed: {
        isAmbulatorioPresent() {
            let me = this;
            let returnValue = me.ambulatoriUtente.length > 0;
            return returnValue;
        },
    },
    data() {
        return {
            rows: 0,
            perPage: 50,
            currentPage: 1,
            filter: null,
            nomeFile: "InformativaTrattamentoDatiConsegnaEsami_",
            descrizione: "CONSENSO INFORMATIVO DATI PERSONALI MODALITA CONSEGNA ESAMI",
            tipoDocumento: "CONSENSO INFORMATIVO DATI PERSONALI MODALITA CONSEGNA ESAMI",
            codiceApplicativo: "ACCETTAZIONE",
            pathDeleteAnagrafica: "/anagrafica",
            patResource: "/donkaccettazione",
            pathResourceAllegato: "/accettazioniconsensiprinter/preview/",
            signedPdfViewer: null,
            // pathResourcePrestazioni: "/ambulatoriprestazioninomenclatore",
            pathResourcePrestazioni: "/ambulatorioprestazioni",
            pathResourceAmbulatori: "/ambulatori",
            pathResourceAmbulatoriUtenti: "/ambulatoriutenti",
            linkedit: "/pazienteaccettazione",
            stampaReport: null,
            btnNewVisible: false,
            btnCondivisione: false,
            btnVisita: false,
            disableSelectPrestazioni: true,
            prestazioniOptions: [{ value: null, text: "-Seleziona Valore-" }],
            ambulatoriOptions: [],
            ambulatoriUtente: [],
            statiOptions: [
                { value: null, text: "-Seleziona Valore-" },
                { value: "ACCETTATO", text: "ACCETTATO" },
                { value: "RIFIUTATO", text: "RIFIUTATO" },
                { value: "ESEGUITO", text: "ESEGUITO" },
                { value: "ABBANDONATO", text: "ABBANDONATO" },
                { value: "REFERTATO", text: "REFERTATO" },
            ],
            filtro: { codiceFiscale: null, dataDal: null, dataAl: null, stato: null, prestazione: null, idAmbulatorio: null },
            filterOn: [],
            showModalLoading: false,
            fields: [
                {
                    label: this.$i18n.t("patients.lblNominative"),
                    key: "nominativo",
                    sortable: true,
                    //thStyle: "width: 7rem",
                },
                {
                    label: "Ambulatorio",
                    key: "nomeAmbulatorio",
                    //thStyle: "width: 5rem",
                    sortable: true,
                },
                // {
                //     label: this.$i18n.t("patients.lblTelephoneNumber"),
                //     key: "telefono",
                //     //thStyle: "width: 5rem",
                //     sortable: true,
                // },
                // {
                //     label: this.$i18n.t("patients.lblEmail"),
                //     key: "email",
                //     sortable: true,
                // },
                {
                    label: "Prestazione",
                    key: "descrizionePrestazione",
                    sortable: true,
                },
                // {
                //     label: "Cod. Catalogo",
                //     key: "codicePrestazioneAgg",
                //     sortable: true,
                // },
                {
                    label: this.$i18n.t("patients.lblBirthDate"),
                    key: "dataNascita",
                    sortable: true,
                    formatter: (value) => {
                        return moment(new Date(value)).format("DD/MM/YYYY");
                    },
                },
                {
                    label: "Stato",
                    key: "statoAttuale",
                    sortable: true,
                },
                { key: "actions", label: "" },
            ],
            items: [],
        };
    },
    created: function () {},
    mounted: function () {
        let me = this;
        me.impostaPermessi();
        // me.loadPrestazioni();
        // me.loadAmbulatori();
        me.loadAmbulatoriUtenti();
        me.loadData();
    },
    watch: {
        currentPage() {
            let me = this;
            me.loadData();
        },
        utente() {
            let me = this;
            me.impostaPermessi();
        },
    },
    methods: {
        linkPrintDataConsensi(item) {
            let returnValue = "/accettazioniconsensiprinter/" + item.id;
            return returnValue;
            // let me = this;
            // let link = process.env.VUE_APP_PATH_API + me.pathResourceAllegato + "/" + item.id;
            // me.showModalLoading = true;
            // axios
            //   .get(link)
            //   .then((response) => {
            //     me.signedPdfViewer = response.data.data.file.includes("data:application/pdf;base64,") ? response.data.data.file : "data:application/pdf;base64," + response.data.data.file;
            //     //me.$refs.mdlSigendPdfViewer.show();
            //     me.$refs.pdfPrintViewEmbedded.show();
            //     me.showModalLoading = false;
            //   })
            //   .catch((error) => {
            //     // me.$refs.mdlGenerazioneReportInCorso.hide();
            //     console.log(error);
            //     me.showModalLoading = false;
            //     let messaggio = error.response.data.messaggio ? error.response.data.messaggio : "Errore non gestito, Codice: " + error.response.status;
            //     this.$bvModal
            //       .msgBoxOk(messaggio, {
            //         title: this.$i18n.t("ERROR!"),
            //         size: "sm",
            //         okVariant: "outline-danger",
            //         headerClass: "sa-msg-header-danger",
            //         footerClass: "p-2 border-top-0",
            //         centered: true,
            //       })
            //       .then((value) => {
            //         this.boxTwo = value;
            //       })
            //       .catch((err) => {
            //         console.log(err);
            //       });
            //   });
        },
        onPrintPreview(item) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceAllegato + item.id;
            me.showModalLoading = true;
            axios
                .get(link)
                .then((response) => {
                    me.signedPdfViewer = response.data.data.file.includes("data:application/pdf;base64,") ? response.data.data.file : "data:application/pdf;base64," + response.data.data.file;
                    //me.$refs.mdlSigendPdfViewer.show();
                    me.$refs.pdfPrintViewEmbedded.show();
                    me.showModalLoading = false;
                })
                .catch((error) => {
                    // me.$refs.mdlGenerazioneReportInCorso.hide();
                    console.log(error);
                    me.showModalLoading = false;
                    let messaggio = error.response.data.messaggio ? error.response.data.messaggio : "Errore non gestito, Codice: " + error.response.status;
                    this.$bvModal
                        .msgBoxOk(messaggio, {
                            title: this.$i18n.t("ERROR!"),
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then((value) => {
                            this.boxTwo = value;
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                });
        },
        loadAmbulatoriUtenti() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceAmbulatoriUtenti;
            me.ambulatoriUtente = [];
            me.ambulatoriOptions = [];
            me.ambulatoriOptions = [{ value: null, text: "-Seleziona Valore-" }];
            axios
                .get(link)
                .then((response) => {
                    response.data.data.forEach((element) => {
                        me.ambulatoriOptions.push({ value: element.id, text: element.nome });
                        me.ambulatoriUtente.push(element);
                    });
                    // me.ambulatoriOptions.unshift({ value: null, text: "-Seleziona Valore-" });
                })
                .catch(() => {
                    // console.log(e);
                    //   this.errors.push(e);
                });
        },
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.patResource;
            me.showModalLoading = true;
            me.filtro.page = me.currentPage;
            me.filtro.forPage = me.perPage;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];
                    me.items = response.data.data.list;
                    me.rows = response.data.data.recordsNumber;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        onSubmit() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
        onReset() {
            let me = this;
            me.filtro = { codiceFiscale: null, dataDal: null, dataAl: null, stato: null, prestazione: null, idAmbulatorio: null };
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        onDeleteItem(item) {
            let me = this;
            let domanda = this.$i18n.t("patient.event.lblConfirmDeleteQuestion");
            let titolo = this.$i18n.t("patient.event.lblConfirmDelete");
            let si = this.$i18n.t("patient.lblYes");
            let no = this.$i18n.t("patient.lblNo");
            this.$bvModal
                .msgBoxConfirm(domanda, {
                    title: titolo,
                    buttonSize: "sm",
                    okVariant: "secondary",
                    cancelVariant: "danger",
                    okTitle: si,
                    cancelTitle: no,
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                })
                .then((value) => {
                    if (value) {
                        me.deleteItem(item);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        onSelectAnbulatorio() {
            let me = this;
            me.disableSelectPrestazioni = true;
            me.filtro.prestazione = null;
            if (me.filtro.idAmbulatorio !== null && me.filtro.idAmbulatorio !== "") {
                me.disableSelectPrestazioni = false;
                me.loadPrestazioni();
            }
        },
        loadPrestazioni() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourcePrestazioni + "/prestazioni?idAmbulatorio=" + me.filtro.idAmbulatorio;
            me.prestazioniOptions = [];
            me.prestazioniOptions = [{ value: null, text: "-Seleziona Valore-" }];
            axios
                .get(link)
                .then((response) => {
                    response.data.data.list.forEach((element) => {
                        me.prestazioniOptions.push({ value: element.codiceCatalogo, text: element.descrizioneCatalogo + " (" + element.codiceCatalogo + ")" });
                    });
                    // me.ambulatoriOptions.unshift({ value: null, text: "-Seleziona Valore-" });
                })
                .catch((e) => {
                    console.log(e);
                    //   this.errors.push(e);
                });
        },
        // loadAmbulatori() {
        //     let me = this;
        //     let link = process.env.VUE_APP_PATH_API + me.pathResourceAmbulatori;
        //     me.ambulatoriOptions = [];
        //     me.ambulatoriOptions = [{ value: null, text: "-Seleziona Valore-" }];
        //     axios.get(link).then((response) => {
        //         response.data.data.forEach((element) => {
        //             me.ambulatoriOptions.push({ value: element.id, text: element.nome });
        //         });
        //         // me.ambulatoriOptions.unshift({ value: null, text: "-Seleziona Valore-" });
        //     });
        // },
        deleteItem(item) {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathDeleteAnagrafica + "/" + item.id;
            axios
                .delete(link)
                .then((Response) => {
                    console.log(Response);
                    me.showModalLoading = false;
                    me.loadData();
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        onShareItem(item) {
            console.log(item);
        },
        impostaPermessi() {
            let me = this;
            me.btnNewVisible = false;
            me.btnCondivisione = false;
            me.btnVisita = false;
            me.btnNewVisible = UtilityMixin.methods.verificaPermessi(me.utente, "accettazione", 2);
            me.btnCondivisione = false; //UtilityMixin.methods.verificaPermessi(me.utente, "condivisione", 2);
            me.btnVisita = UtilityMixin.methods.verificaPermessi(me.utente, "pazienteeventi", 2);
            // console.log(me.btnCondivisione);
        },
        linkPrintData(item) {
            let returnValue = "/accettazioniprinter/" + item.id;
            return returnValue;
        },
        onBeforLoadEventoTipoPrestazione() {
            let me = this;
            me.showModalLoading = true;
        },
        onAfterLoadEventoTipoPrestazione() {
            let me = this;
            me.showModalLoading = false;
        },
    },
};
</script>
