<template>
  <b-modal size="xl" id="pdfmodel" title="Referto" @click="onClickPdf()">
    <template>
      <b-embed type="iframe" :src="pdf" allowfullscreen></b-embed>
    </template>
  </b-modal>
</template>
<script>
export default {
  props: {
    pdf: String,
  },

  onClickPdf(row) {
    let me = this;
    me.pdf = row.item.pdfReferto;
  },
};
</script>
