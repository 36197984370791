const sections = require.context("../", true, /.*\.js$/);
const getRouters = async function (sections) {
    let pathRoutes = [];
    let pathsFileName = [];
    sections.keys().forEach((element) => {
        let pathSplit = element.split("/");
        if (pathSplit[1] !== "router") {
            if (element.includes("/router/")) {
                let path = "";
                pathSplit.forEach((el) => {
                    if (el != "." && el !== "router" && el != "index.js") {
                        path += el + "/";
                    }
                });
                let pathFilename = { path: path, fileName: "index.js" };

                pathsFileName.push(pathFilename);
            }
        }
    });

    for (let z = 0; z < pathsFileName.length; z++) {
        let pathFileName = pathsFileName[z];
        let path = pathFileName.path;
        let fileName = pathFileName.fileName;
        let routerElements = await import(`../${path}router/${fileName}`);
        pathRoutes = pathRoutes.concat(routerElements.default);
    }

    return pathRoutes;
};

let routers = await getRouters(sections);

export default routers;
