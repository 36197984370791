<template>
    <div class="sa-tab-scroll sa-form-container">
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card" header="Dettagli" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Nome</label>
                            <span>{{ jsonDataTipoPrestazione.nome }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Codice Prestazione</label>
                            <span>{{ jsonDataTipoPrestazione.codicePresstazione }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Regime</label>
                            <span>{{ jsonDataTipoPrestazione.regime }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Importo</label>
                            <span>{{ jsonDataTipoPrestazione.importo + "€" }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Tipo Quota</label>
                            <span>{{ jsonDataTipoPrestazione.tipoQuota }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Quota ASL</label>
                            <span v-if="jsonDataTipoPrestazione.tipoQuota == 'IMPORTO'">{{ jsonDataTipoPrestazione.quotaAsl + "€" }} </span>
                            <span v-else>{{ jsonDataTipoPrestazione.quotaAsl + "%" }} </span>
                        </b-col>
                        <!-- <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Quota Comune</label>
                            <span v-if="jsonDataTipoPrestazione.tipoQuota == 'IMPORTO'">{{ jsonDataTipoPrestazione.quotaComune + "€" }} </span>
                            <span v-else>{{ jsonDataTipoPrestazione.quotaComune + "%" }} </span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Quota Utente</label>
                            <span v-if="jsonDataTipoPrestazione.tipoQuota == 'IMPORTO'">{{ jsonDataTipoPrestazione.quotaUtente + "€" }} </span>
                            <span v-else>{{ jsonDataTipoPrestazione.quotaUtente + "%" }} </span>
                        </b-col> -->
                    </b-row>

                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Note</label>
                            <span>{{ jsonDataTipoPrestazione.note }}</span>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            pathResource: "/rsatipoprestazioni",
            id: "",
            linkedit: "",
            showModalLoading: false,
            jsonDataTipoPrestazione: {},
        };
    },

    watch: {
        jsonDataTipoPrestazione: {
            handler(jsonDataTipoPrestazione) {
                this.$emit("update", jsonDataTipoPrestazione);
            },
            deep: true,
        },
    },

    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },

    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";

            me.showModalLoading = true;
            axios
                .get(link + me.id)
                .then((response) => {
                    me.jsonDataTipoPrestazione = response.data.data;
                    this.$emit("afterLoadData");
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
    },
};
</script>

<style scoped>
.sa-form-container {
    padding: 1rem;
    background-color: #f8f9fa;
    min-height: 100%;
}
</style>
