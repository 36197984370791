<template>
  <sa-page-layout :btnBackVisible="true" :btnEditVisible="btnEditVisible" :linkback="linkback" :linkedit="linkedit" :pathResource="pathResource" :showModalLoading="showModalLoading">
    <template slot="table-body">
      <rsa-gestione-ospiti-view-component ref="RsaGestioneOspitiViewComponent" @afterLoadData="onAfterLoadData"></rsa-gestione-ospiti-view-component>
    </template>
  </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import RsaGestioneOspitiViewComponent from "../components/RsaGestioneOspitiViewComponent.vue";

export default {
  components: { SaPageLayout, RsaGestioneOspitiViewComponent },
  data() {
    return {
      filtro: { nome: null, cognome: null, identificativo: null },
      btnNewVisible: true,
      pathResource: "",
      btnEditVisible: true,
      linkedit: null,
      linkback: "/rsagestioneospiti",
      showModalLoading: false,
      listKey: 0,
    };
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.linkedit = "/rsagestioneospiti/edit/" + me.id;
  },
  methods: {
    onUpdateJsonData(data) {
      let me = this;
      me.jsonData = data;
    },

    onAfterLoadData() {
      let me = this;
      me.showModalLoading = false;
    },
  },
};
</script>
<style></style>
