<template>
    <div style="height: 100%; padding-bottom: 18px">
        <div class="sa-accordion-wrapper">
            <!-- <b-tabs id="tabsRsaDimissioneTrasferimento" ref="tabsRsaDimissioneTrasferimento" v-model="activeTabIndex" nav-wrapper-class="sa-tab-items" content-class="mt-3" class="sa-tab" @input="onInputTabs" style="height: 100%; padding-bottom: 25px"> -->
            <b-tabs id="tabsRsaDimissioneTrasferimento" ref="tabsRsaDimissioneTrasferimento" v-model="activeTabIndex" nav-wrapper-class="sa-tab-items" content-class="mt-3" class="sa-tab" style="padding-bottom: 25px" fill @input="onInputTabs">
                <b-tab title="Dimissione/Trasferimento" id="tbDimissioneTrasferimento">
                    <!-- vertical sa-tab-items-aree sa-tab-items-vertical -->
                    <form>
                        <div class="sa-accordion">
                            <input class="sa-accordion-input" type="radio" name="dimissione-trasferimento-radio-a" id="dimissione-trasferimento-radio-a" value="1" checked="checked" />
                            <label class="sa-accordion-label" for="dimissione-trasferimento-radio-a">
                                <!--   Dimissione/Trasferimento -->
                                <b-button variant="success secondary-noborder pull-right sa-accordion-btn" @click="onSave" :disabled="isDimissioneTrasferimento" v-if="!disableCampi">
                                    <b-icon icon="check2"></b-icon>
                                    <span style="padding-left: 5px"> {{ buttonText }}</span>
                                </b-button>
                                <b-button variant="outline-success outline-secondary-noborder pull-right sa-accordion-btn" @click="onModifica" :hidden="btnModificaDimissionetrasferimento">
                                    <b-icon icon="pencil"></b-icon>
                                    <span style="padding-left: 5px">Modifica</span>
                                </b-button>
                                <b-button variant="outline-danger outline-secondary-noborder pull-right sa-accordion-btn" @click="onAnnulla" :hidden="btnAnnullaDimissionetrasferimento">
                                    <b-icon icon="x"></b-icon>
                                    <span style="padding-left: 5px">Annulla</span>
                                </b-button>
                            </label>
                            <div class="sa-accordion-content ml-3 mr-3">
                                <!-- <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title=""> -->
                                <b-row>
                                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                        <label class="sa-label-data">Numero Cartella</label>
                                        <b-form-input v-model="jsonData.numeroCartella" disabled></b-form-input>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                        <label class="sa-label-data">Stato:</label>
                                        <b-form-select v-model="jsonData.statoAttuale" value-field="value" text-field="text" value="''" class="mb-2 mr-sm-2 mb-sm-0" :options="statoOptions" :disabled="disableCampi">
                                            <template #first>
                                                <b-form-select-option :value="''" disabled> -Seleziona un'opzione- </b-form-select-option>
                                            </template>
                                        </b-form-select>
                                    </b-col>
                                </b-row>
                                <!-- </b-card> -->
                                <!-- <b-card v-if="jsonData.statoAttuale === 'DIMESSO'" class="sa-card" header="Dimissione" header-tag="header" footer-tag="footer" title=""> -->
                                <b-row v-if="jsonData.statoAttuale === 'DIMESSO'">
                                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                        <label class="sa-label-data">Data Ora Dimissioni:</label>
                                        <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataOraDimissioneTradferimento" type="datetime" :disabled="disableCampi" :disabled-date="notAfter"></date-picker>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                        <label class="sa-label-data">Motivo:</label>
                                        <b-form-select v-model="jsonData.dimissioneMotivoDimissione" value-field="value" text-field="text" value="''" class="mb-2 mr-sm-2 mb-sm-0" :options="motiviDimissioneOptions" :disabled="disableCampi"></b-form-select>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                        <label class="sa-label-data">Altro Motivo</label>
                                        <b-form-input v-model="jsonData.dimissioneMotivoDimissioneAltro" :disabled="jsonData.dimissioneMotivoDimissione !== 'ALTRO' || disableCampi"></b-form-input>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                        <label class="sa-label-data">Gestione Farmaci</label>
                                        <b-form-select v-model="jsonData.dimissioneGestioneFarmaci" value-field="value" text-field="text" value="''" class="mb-2 mr-sm-2 mb-sm-0" :options="gestioneFamaciOptions" :disabled="disableCampi"></b-form-select>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                        <label class="sa-label-data">Gestione Ausili</label>
                                        <b-form-select v-model="jsonData.dimissioneGestioneAusili" value-field="value" text-field="text" value="''" class="mb-2 mr-sm-2 mb-sm-0" :options="gestioneAusiliOptions" :disabled="disableCampi"></b-form-select>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                        <b-form-checkbox class="sa-custom-switch" v-model="jsonData.dimissionePazienteAssenteGiorniSelezionato" switch :disabled="disableCampi">
                                            <label class="sa-label-data-check">Considera il paziente assente nel giorno selezionato</label>
                                        </b-form-checkbox>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                        <label class="sa-label-data">Note</label>
                                        <b-form-textarea id="textarea-no-resize" v-model="jsonData.note" rows="5" no-resize></b-form-textarea>
                                    </b-col>
                                </b-row>
                                <!-- </b-card> -->
                                <!-- <b-card v-if="jsonData.statoAttuale === 'TRASFERITO'" class="sa-card" header="Trasferimento" header-tag="header" footer-tag="footer" title=""> -->
                                <b-row v-if="jsonData.statoAttuale === 'TRASFERITO'">
                                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                        <label class="sa-label-data">Regime Ricovero</label>
                                        <b-form-input v-model="jsonData.trasferimentoRegimeRicovero" disabled></b-form-input>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                        <label class="sa-label-data">Struttura Attuale</label>
                                        <b-form-input v-model="jsonData.trasferimentoStrutturaAttuale" disabled></b-form-input>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                        <label class="sa-label-data">Struttura Trasferimento</label>
                                        <b-form-input v-model="jsonData.trasferimentoStrutturaTrasmetimento" :disabled="disableCampi"></b-form-input>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                        <label class="sa-label-data">Altra Struttura Codice</label>
                                        <b-form-input v-model="jsonData.trasferimentoStrutturaTrasmetimentoCodice" :disabled="disableCampi"></b-form-input>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                        <label class="sa-label-data">Altra Struttura Nome</label>
                                        <b-form-input v-model="jsonData.trasferimentoStrutturaTrasmetimentoNome" :disabled="disableCampi"></b-form-input>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                        <label class="sa-label-data">Data Ora Trasferimento:</label>
                                        <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataOraDimissioneTradferimento" type="datetime" :disabled="disableCampi" :disabled-date="notAfter"></date-picker>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                        <b-form-checkbox class="sa-custom-switch" v-model="jsonData.trasferimentoChiusuraImpegnativa" switch :disabled="disableCampi">
                                            <label class="sa-label-data-check">Chiudi l'impegnativa nella data di trasferimento</label>
                                        </b-form-checkbox>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                        <label class="sa-label-data">Note</label>
                                        <b-form-textarea id="textarea-no-resize" v-model="jsonData.note" rows="5" no-resize></b-form-textarea>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>
                    </form>
                </b-tab>
                <b-tab title="Storico" id="tbDimissioneTrasferimentoStorico">
                    <form>
                        <rsa-scheda-ospiste-dimissione-trasferimento-storico-component ref="cmpRsaDimissioneTrasferimentoStoricoComponent"></rsa-scheda-ospiste-dimissione-trasferimento-storico-component>
                    </form>
                </b-tab>
            </b-tabs>
            <!-- <b-tabs vertical id="tabsRsaPazienteAreeView" ref="tabsRsaPazienteAreeView" v-model="activeTabIndex" nav-wrapper-class="sa-tab-items sa-tab-items-aree sa-tab-items-vertical" content-class="mt-3" class="sa-tab" style="padding-bottom: 25px" fill @input="onInputTabs">
        <b-tab title="Nome"> </b-tab>
      </b-tabs> -->
            <!-- </b-card> -->
            <b-modal scrollable b-modal title="Modifica Dimissione/Trasferimento" ref="mdlDimissioniTrasferimentiStato" id="mdlDimissioniTrasferimentiStato" size="md" centered no-close-on-backdrop no-close-on-esc hide-header-close @show="onShowModalStorico" @ok="onCloseModalDimissioniTrasferimentiStato">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <label>Stato</label>
                        <b-form-select v-model="dimissisoneTrasferimentiStatoStorico.stato" value-field="value" text-field="text" :value="null" class="mb-2 mr-sm-2 mb-sm-0" :options="dimissisoneTrasferimentiStatoOptions"></b-form-select>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <label class="sa-label-data">Motivazione:</label>
                        <b-form-textarea id="textarea-no-resize" v-model="dimissisoneTrasferimentiStatoStorico.motivazione" rows="5" no-resize></b-form-textarea>
                    </b-col>
                </b-row>
            </b-modal>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import RsaSchedaOspisteDimissioneTrasferimentoStoricoComponent from "./RsaSchedaOspisteDimissioneTrasferimentoStoricoComponent.vue";
import UtilityMixin from "../../../utility/UtilityMixin";

export default {
    props: {
        jsonDataAccettazione: { type: Object },
        utente: { type: Object },
    },
    components: {
        DatePicker,
        RsaSchedaOspisteDimissioneTrasferimentoStoricoComponent,
    },
    watch: {
        "jsonData.statoAttuale": function (value) {
            let me = this;

            //if (me.disableCampi) {
            me.setIsDimissioneTrasferimeto(value);
            //}
        },
    },
    computed: {
        buttonText() {
            switch (this.jsonData.statoAttuale) {
                case "DIMESSO":
                    return "Dimetti";
                case "TRASFERITO":
                    return "Trasferisci";
                default:
                    return "Dimetti/Trasferisci";
            }
        },
    },
    data() {
        return {
            pathResource: "/rsadimissionitrasferimenti",
            pathResourceMotiviDimissione: "/rsadimissionemotivi",
            pathResourceGestioneFarmaciAusili: "/rsadimissionigestioneausili",
            pathResourceDimissioniTrasferimentiStati: "/rsadimissionitrasferimentistati",
            disableCampi: true,
            btnModificaDimissionetrasferimento: true,
            btnAnnullaDimissionetrasferimento: true,
            activeTabIndex: 0,
            idAccettazione: null,
            isDimissioneTrasferimento: true,
            jsonData: {
                id: null,
                idPaziente: "",
                statoAttuale: "",
                statoDataOra: new Date().getTime(),
                dataOraDimissioneTradferimento: null,
                dimissioneMotivoDimissione: "",
                dimissioneGestioneFarmaci: "",
                dimissioneGestioneAusili: "",
                dimissionePazienteAssenteGiorniSelezionato: false,
                trasferimentoRegimeRicovero: "",
                trasferimentoStrutturaAttuale: "",
                trasferimentoStrutturaTrasmetimento: "",
                trasferimentoStrutturaTrasmetimentoNome: "",
                trasferimentoStrutturaTrasmetimentoCodice: "",
                dimissioneMotivoDimissioneAltro: "",
                trasferimentoChiusuraImpegnativa: "",
                numeroCartella: "",
                storico: [],
            },
            dimissisoneTrasferimentiStatoStorico: { stato: null, motivazione: "", statoDataOra: null },
            statoOptions: [
                { value: "DIMESSO", text: "DIMESSO" },
                { value: "TRASFERITO", text: "TRASFERITO" },
            ],
            dimissisoneTrasferimentiStatoOptions: [
                /*  { value: null, text: "-Seleziona Valore-" },
        { value: "CONFERMATO", text: "CONFERMATO" },
        { value: "MODIFICATO", text: "MODIFICATO" },
        { value: "ANNULLATO", text: "ANNULLATO" },
        { value: "ELIMINATO", text: "ELIMINATO" }, */
            ],
            motiviDimissioneOptions: [],
            gestioneFamaciOptions: [],
            gestioneAusiliOptions: [],
        };
    },
    /* mounted() {
    let me = this;
    me.loadMotivoDimissione();
    me.loadGestioneFarmaciAusili();
    me.loadDimissioniTrasferimentiStati();
    me.loadData();
  }, */
    methods: {
        setId(id) {
            let me = this;
            me.idAccettazione = id;
            me.loadMotivoDimissione();
            me.loadGestioneFarmaciAusili();
            me.loadDimissioniTrasferimentiStati();
            me.loadData();
        },

        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            let filtro = { page: 1, forPage: 1, idAccettazione: me.idAccettazione };
            me.$emit("beforeLoadData");
            axios
                .get(link, { params: filtro })
                .then((response) => {
                    if (response.data.data.recordsNumber > 0) {
                        if (response.data.data.list[0].statoAttuale === "ANNULLATO") {
                            me.nuovaDimissioneTrasferimento();
                            me.jsonData.id = response.data.data.list[0].id;
                            me.jsonData.statoAttuale = "";
                            //  me.jsonData.numeroCartella = response.data.data.list[0].numeroCartella;
                            //  nuo
                            me.disableCampi = false;
                            me.btnModificaDimissionetrasferimento = true;
                            me.btnAnnullaDimissionetrasferimento = true;
                        } else {
                            me.jsonData = response.data.data.list[0];
                            me.disableCampi = true;
                            me.setIsDimissioneTrasferimeto(me.jsonData.statoAttuale);
                            me.btnModificaDimissionetrasferimento = false;
                            me.btnAnnullaDimissionetrasferimento = false;
                        }
                    } else {
                        me.disableCampi = false;
                        me.btnModificaDimissionetrasferimento = true;
                        me.btnAnnullaDimissionetrasferimento = true;
                        me.nuovaDimissioneTrasferimento();
                    }
                    me.listKey++;
                    this.$emit("afterLoadData");
                })
                .catch(() => {
                    this.$emit("afterLoadData");
                });
        },
        nuovaDimissioneTrasferimento() {
            let me = this;
            me.jsonData.idAccettazione = me.jsonDataAccettazione.id;
            me.jsonData.idPaziente = me.jsonDataAccettazione.accettazioneAnagrafica.id;
            me.jsonData.numeroCartella = me.jsonDataAccettazione.numero;
            me.jsonData.trasferimentoRegimeRicovero = me.jsonDataAccettazione.regime;
            me.jsonData.trasferimentoStrutturaAttuale = me.jsonDataAccettazione.accettazioneStruttura.denominazione;
        },
        loadMotivoDimissione() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceMotiviDimissione;
            me.motiviDimissioneOptions = [];
            me.$emit("beforeLoadData");
            axios
                .get(link)
                .then((response) => {
                    response.data.data.list.forEach((element) => {
                        me.motiviDimissioneOptions.push({ value: element.descrizione, text: element.descrizione });
                    });
                    me.motiviDimissioneOptions.unshift({ value: "", text: "-Seleziona Valore-" });
                    me.listKey++;
                    this.$emit("afterLoadData");
                })
                .catch(() => {
                    this.$emit("afterLoadData");
                });
        },
        onInputTabs(index) {
            let me = this;
            let activeTab = this.$refs.tabsRsaDimissioneTrasferimento.getTabs()[index];
            me.activeTab(activeTab.id);
        },
        activeTab(idActiveTab) {
            let me = this;
            switch (idActiveTab) {
                case "tbDimissioneTrasferimentoStorico":
                    me.$refs.cmpRsaDimissioneTrasferimentoStoricoComponent.loadData(me.jsonData.id);
                    break;
            }
            //  this.$emit("onActiveTab", idActiveTab);
        },
        loadGestioneFarmaciAusili() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceGestioneFarmaciAusili;
            me.gestioneFamaciOptions = [];
            me.gestioneAusiliOptions = [];
            me.$emit("beforeLoadData");
            axios
                .get(link)
                .then((response) => {
                    response.data.data.list.forEach((element) => {
                        switch (element.tipoGestione) {
                            case "FARMACI":
                                me.gestioneFamaciOptions.push({ value: element.descrizione, text: element.descrizione });
                                break;
                            case "AUSILI":
                                me.gestioneAusiliOptions.push({ value: element.descrizione, text: element.descrizione });
                                break;
                        }
                    });
                    me.gestioneFamaciOptions.unshift({ value: "", text: "-Seleziona Valore-" });
                    me.gestioneAusiliOptions.unshift({ value: "", text: "-Seleziona Valore-" });
                    me.listKey++;
                    this.$emit("afterLoadData");
                })
                .catch(() => {
                    this.$emit("afterLoadData");
                });
        },
        onSave() {
            /* let me = this;
            if (!me.jsonData.id === null) {
                me.dimissisoneTrasferimentiStatoStorico = {
                    stato: "CONFERMATO",
                    motivazione: me.jsonData.motivazione,
                    statoDataOra: new Date().getTime(),
                };
                if (!me.jsonData.storico) {
                    me.jsonData.storico = [];
                }

                me.jsonData.storico.push(me.dimissisoneTrasferimentiStatoStorico);
                me.saveDimissionieTrasferimento();
            } else {
                if (!me.btnModificaDimissionetrasferimento) {
                    me.$refs.mdlDimissioniTrasferimentiStato.show();
                } else {
                    me.updateDimissioneeTrasferimento();
                }
            } */

            let me = this;
            if (me.jsonData.id === null) {
                /* me.dimissisoneTrasferimentiStatoStorico = {
                    stato: "CONFERMATO",
                    motivazione: "DIMISSIONE",
                    statoDataOra: new Date().getTime(),
                };
                me.jsonData.storico.push(me.dimissisoneTrasferimentiStatoStorico); */
                me.saveDimissionieTrasferimento();
            } else {
                if (!me.btnModificaDimissionetrasferimento) {
                    me.$refs.mdlDimissioniTrasferimentiStato.show();
                } else {
                    me.updateDimissioneeTrasferimento();
                }
            }
        },
        saveDimissionieTrasferimento() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            axios
                .post(link, JSON.stringify(me.jsonData))
                .then(() => {
                    this.$bvToast.toast("Salvataggio Avvenuto con successo", {
                        title: this.titleModal,
                        variant: "success",
                        autoHideDelay: 1000,
                    });
                    me.loadData();
                })
                .catch((error) => {
                    me.$emit("afterSaveRequest");
                    let messaggio = error.response ? error.response.data.messaggio : "Errore non gestito";
                    me.$bvModal
                        .msgBoxOk(messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then(() => {})
                        .catch((err) => {
                            console.log(err);
                        });
                });
        },
        updateDimissioneeTrasferimento() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.jsonData.id;
            axios
                .put(link, JSON.stringify(me.jsonData))
                .then(() => {
                    this.$bvToast.toast("Salvataggio Avvenuto con successo", {
                        title: this.titleModal,
                        variant: "success",
                        autoHideDelay: 1000,
                    });
                    me.loadData();
                })
                .catch((error) => {
                    me.$emit("afterSaveRequest");
                    let messaggio = error.response ? error.response.data.messaggio : "Errore non gestito";
                    me.$bvModal
                        .msgBoxOk(messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then(() => {})
                        .catch((err) => {
                            console.log(err);
                        });
                });
        },
        onModifica() {
            let me = this;
            if (me.disableCampi) {
                me.disableCampi = !me.disableCampi;
            }
        },
        onCloseModalDimissioniTrasferimentiStato() {
            let me = this;
            me.dimissisoneTrasferimentiStatoStorico.statoDataOra = new Date().getTime();
            me.jsonData.storico.push(me.dimissisoneTrasferimentiStatoStorico);
            if (me.dimissisoneTrasferimentiStatoStorico.stato === "ANNULLATO") {
                me.jsonData.statoAttuale = me.dimissisoneTrasferimentiStatoStorico.stato;
                me.annullaDimissioneTrasferimento();
            } else {
                me.updateDimissioneeTrasferimento();
            }
        },
        onShowModalStorico() {
            let me = this;
            if (me.dimissisoneTrasferimentiStatoStorico.stato === "ANNULLATO") {
                me.dimissisoneTrasferimentiStatoStorico = { stato: "ANNULLATO", motivazione: "", statoDataOra: null };
            } else {
                me.dimissisoneTrasferimentiStatoStorico = { stato: null, motivazione: "", statoDataOra: null };
            }
        },
        onAnnulla() {
            let me = this;
            // me.dimissisoneTrasferimentiStatoStorico = { stato: "ANNULLATO" };
            me.dimissisoneTrasferimentiStatoStorico.stato = "ANNULLATO";
            me.$refs.mdlDimissioniTrasferimentiStato.show();
        },
        annullaDimissioneTrasferimento() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.jsonData.id;
            axios
                .put(link, JSON.stringify(me.jsonData))
                .then(() => {
                    this.$bvToast.toast("Salvataggio Avvenuto con successo", {
                        title: this.titleModal,
                        variant: "success",
                        autoHideDelay: 1000,
                    });
                    me.jsonData = {
                        statoAttuale: "",
                        dimissioneMotivoDimissione: "",
                        dimissioneGestioneFarmaci: "",
                        dimissioneGestioneAusili: "",
                    };
                    me.loadData();
                })
                .catch((error) => {
                    me.$emit("afterSaveRequest");
                    let messaggio = error.response ? error.response.data.messaggio : "Errore non gestito";
                    me.$bvModal
                        .msgBoxOk(messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then(() => {})
                        .catch((err) => {
                            console.log(err);
                        });
                });
        },
        loadDimissioniTrasferimentiStati() {
            let me = this;
            UtilityMixin.methods.loadDataResources(me.pathResourceDimissioniTrasferimentiStati, {}, me.resultResponseDimissioniTrasferimentiStati, me.resultErrorDimissioniTrasferimentiStati);
        },
        resultResponseDimissioniTrasferimentiStati(response) {
            let me = this;
            me.dimissisoneTrasferimentiStatoOptions = [];
            response.data.data.list.forEach((element) => {
                me.dimissisoneTrasferimentiStatoOptions.push({ text: element.stato, value: element.stato });
            });
            me.dimissisoneTrasferimentiStatoOptions.unshift({ text: "-Selezionare Valore-", value: null });
        },
        resultErrorDimissioniTrasferimentiStati(error) {
            UtilityMixin.methods.messaggeBoxError(this, error);
        },
        setIsDimissioneTrasferimeto(value) {
            let me = this;
            me.isDimissioneTrasferimento = value !== "DIMESSO" && value !== "TRASFERITO";
        },

        notAfter: function (date) {
            return date > Date.now();
        },
    },
};
</script>
