<template>
    <div style="height: 100%">
        <div class="sa-flexbox" style="padding-bottom: 20px">
            <div style="margin-right: 20px; margin-right: 20px">
                <span class="sa-flexbox-body-title">Dizionari</span>
                <b-button size="sm" variant="outline-success outline-primary-noborder sa-margin-right float-sm-right" @click="onClickNuovoDizionario" class="mb-2">
                    <font-awesome-icon icon="plus"> </font-awesome-icon>
                    Aggiungi
                </b-button>
            </div>
            <div class="sa-flexbox-body">
                <div class="b-table-sticky-header">
                    <div class="d-flex flex-column">
                        <b-row class="sa-label-info">
                            <b-col lg="3">
                                <p>Record Totali: {{ rows }}</p>
                            </b-col>
                            <b-col lg="9">
                                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                            </b-col>
                        </b-row>
                    </div>

                    <b-table sticky-header ref="table" stacked="lg" striped hover :items="filterItems" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light">
                        <template #cell(index)="row">
                            {{ row.index + 1 + (currentPage - 1) * perPage }}
                        </template>
                        <template v-slot:cell(actions)="{ item }">
                            <b-button size="sm" variant="outline-success" v-b-tooltip.hover title="Modifica Voce" @click="onClickEdit(item)">
                                <font-awesome-icon icon="edit"> </font-awesome-icon>
                            </b-button>
                        </template>
                    </b-table>
                </div>
            </div>

            <div class="sa-list-component-footer">
                <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col lg="3">
                        <p>Record Totali:{{ rows }}</p>
                    </b-col>
                    <b-col lg="9">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                    </b-col>
                </b-row>
            </div>
        </div>
        <paperclean-configurazione-dizionari-edit-modal-component ref="PapercleanConfigurazioneDizionariEditModalComponent" @afterSaveSuccess="onAfterSaveSuccess" @beforLoadData="onBeforeLoadData" @afterLoadData="onAfterLoadData"></paperclean-configurazione-dizionari-edit-modal-component>
    </div>
</template>
<script>
// import axios from "axios";
import UtilityMixin from "../../utility/UtilityMixin";
import PapercleanConfigurazioneDizionariEditModalComponent from "./PapercleanConfigurazioneDizionariEditModalComponent.vue";

export default {
    components: { PapercleanConfigurazioneDizionariEditModalComponent },
    /* computed: {
        rows() {
            return this.items.length;
        },
    }, */
    data() {
        return {
            rows: 0,
            perPage: 50,
            currentPage: 1,
            pathResource: "/dizionariotipi",
            linkedit: "/editnumeratori",
            showModalLoading: false,
            items: [],
            filterItems: [],
            filtroNumeratori: {},
            jsonDataNuovoContatore: {},
            fields: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Sezione",
                    key: "sezioneDizionario",
                    thStyle: "width: 30rem",
                    sortable: true,
                },
                {
                    label: "Cod. Tipo",
                    key: "codiceTipo",
                    thStyle: "width: 20rem",
                    sortable: true,
                },
                {
                    label: "Descrizione",
                    key: "descrizioneTipo",
                    // thStyle: "width: 20rem",
                    sortable: true,
                },
                { class: "sa-table-column-action-double", key: "actions", label: "", thStyle: "width: 5rem" },
            ],
        };
    },
    watch: {
        currentPage() {
            let me = this;
            me.loadData();
        },
    },
    mounted() {},
    methods: {
        loadData(filtro = {}) {
            let me = this;
            filtro.page = me.currentPage;
            filtro.forPage = me.perPage;
            UtilityMixin.methods.loadDataResources(me.pathResource, filtro, me.loadDataSuccess, me.loadDataError);
        },

        loadDataSuccess(response) {
            let me = this;
            me.filterItems = response.data.data.list;
            me.rows = response.data.data.recordsNumber;
            me.$emit("afterLoadData");
        },
        loadDataError(response) {
            console.log(response);
            this.$emit("afterLoadData");
        },

        onCloseModal() {
            // this.jsonDataNuovoContatore = {};
        },

        onClickView(item) {
            let me = this;
            me.$router.push("/papercleanconfigurazionrdizionari/view/" + item.id);
        },

        onSubmit() {
            this.filterItems = this.items.filter((element) => element.codice.toUpperCase().includes(this.filtroNumeratori.codice.toUpperCase()) && element.descrizione.toUpperCase().includes(this.filtroNumeratori.descrizione.toUpperCase()));
        },

        onReset() {
            this.filtroNumeratori = { codice: "", descrizione: "" };
            this.loadData();
        },

        onRefresh() {
            this.loadData();
        },
        onClickNuovoDizionario() {
            let me = this;
            me.$emit("beforLoadData");
            me.$refs.PapercleanConfigurazioneDizionariEditModalComponent.openModal(null);
            me.$emit("afterLoadData");
        },
        onClickEdit(item) {
            let me = this;
            me.$emit("beforLoadData");
            me.$refs.PapercleanConfigurazioneDizionariEditModalComponent.openModal(item);
            me.$emit("afterLoadData");
        },
        onAfterSaveSuccess() {
            let me = this;
            me.$emit("beforLoadData");
            me.loadData(me.filtro);
        },
        onAfterLoadData() {
            let me = this;
            me.$emit("afterLoadData");
        },
        onBeforeLoadData() {
            let me = this;
            me.$emit("beforLoadData");
        },
    },
};
</script>
