<template>
  <div style="height: 100%">
    <div class="sa-flexbox" style="padding-bottom: 20px">
      <div class="sa-flexbox-header">
        <!-- <b-form @submit.prevent="onSubmit">
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        </b-row>
      </b-form> -->
      </div>
      <div class="sa-flexbox-body">
        <div class="b-table-sticky-header">
          <b-table sticky-header ref="table" stacked="lg" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
            <template v-slot:cell(actions)="{ item }">
              <b-container class="bv-example-row">
                <!-- <b-button v-if="item.statoAttuale === 'INVIATO'" size="sm" v-b-tooltip.hover title="Stampa etichetta" variant="outline-success no-text">
                  <b-icon icon="printer"></b-icon>
                </b-button> -->
                <b-button size="sm" v-b-tooltip.hover title="Cancella Ricetta" variant="outline-danger no-text" @click="onDeleteRicetta(item)">
                  <b-icon icon="trash"></b-icon>
                </b-button>
                <b-button size="sm" v-b-tooltip.hover title="Modifica Ricetta" variant="outline-primary no-text" @click="onEditRicetta(item)">
                  <b-icon icon="pencil"></b-icon>
                </b-button>
              </b-container>
            </template>
          </b-table>
        </div>
      </div>
      <div class="sa-flexbox-footer" style="background-color: ciano">
        <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col lg="3">
            <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rows }}</p>
          </b-col>
          <b-col lg="9">
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
//import DatePicker from "vue2-datepicker";
import UtilityMixin from "../../../utility/UtilityMixin";
export default {
  props: {
    jsonData: { type: Object },
    idAccettazione: { type: String },
  },
  mixins: [UtilityMixin],
  components: {
    // DatePicker,
  },
  data() {
    return {
      rows: 0,
      perPage: 50,
      currentPage: 1,
      filtro: {},
      items: [],
      listKey: 0,
      pathResource: "/rsaricette",
      linkedit: "/rsaaccettazione/ricette/edit/",
      fields: [
        {
          label: "Numero Ricetta",
          key: "numeroRicetta",
        },
        {
          label: "Stato",
          key: "statoAttuale",
        },
        {
          label: "Tipo Prescrizione",
          key: "tipoPrescrizione",
        },
        { label: "Indicazione Prescrizione", key: "indicazioniPrescrizione" },
        {
          label: "",
          key: "actions",
        },
      ],
    };
  },
  mounted() {
    let me = this;
    //  me.idAccettazione = me.$route.params.id;
    me.loadData();
  },
  watch: {
    currentPage() {
      let me = this;
      me.loadData();
    },
  },
  methods: {
    loadData() {
      let me = this;
      let sessionStorage = window.sessionStorage;
      if (sessionStorage["filtro"]) {
        me.filtro = JSON.parse(sessionStorage["filtro"]);
      }
      let link = process.env.VUE_APP_PATH_API + me.pathResource;
      me.filtro.page = me.currentPage;
      me.filtro.forPage = me.perPage;
      me.filtro.idAccettazione = me.idAccettazione;
      me.$emit("beforeLoadData");
      axios
        .get(link, { params: me.filtro })
        .then((response) => {
          me.items = [];
          me.rows = response.data.data.recordsNumber;
          me.items = response.data.data.list;
          me.listKey++;
          this.$emit("afterLoadData");
        })
        .catch(() => {
          this.$emit("afterLoadData");
        });
    },
    nuovaRicetta() {
      let me = this;
      let link = "/rsaaccettazione/ricette/edit/-1?idAccettazione=" + me.idAccettazione;
      me.$router.replace(link).catch((err) => {
        err;
      });
    },
    // onReset() {
    //   let me = this;
    //   me.filtro = {};
    //   let sessionStorage = window.sessionStorage;
    //   sessionStorage["filtro"] = JSON.stringify(me.filtro);
    //   me.loadData();
    // },

    // onSubmit() {
    //   let me = this;
    //   let sessionStorage = window.sessionStorage;
    //   sessionStorage["filtro"] = JSON.stringify(me.filtro);
    //   me.loadData();
    // },
    onEditRicetta(item) {
      let me = this;
      //let link = "/rsaaccettazione/ricette/edit/" + item.id;
      let link = me.linkedit + item.id + "?idAccettazione=" + me.idAccettazione;
      me.$router.replace(link).catch((err) => {
        err;
      });
    },
    onDeleteRicetta(item) {
      let me = this;
      this.$bvModal
        .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
          title: "Eliminazione Ricetta",
          buttonSize: "sm",
          okVariant: "secondary",
          cancelVariant: "danger",
          okTitle: "SI",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            me.deleteRicetta(item.id);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteRicetta(id) {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + id;
      axios
        .delete(link)
        .then((Response) => {
          console.log(Response);

          me.showModalLoading = false;
          me.loadData();
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
