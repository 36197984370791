<template>
  <div style="height: 100%">
    <div class="sa-flexbox" style="padding-bottom: 20px">
      <div class="d-flex flex-column">
        <!-- <div class="text-right mb-3">
                    <b-button size="sm" variant="outline-success" :hidden="btnNuovoRuoloPaiHidden" @click="onClickNuovoRuoloPai" class="mt-2"> <font-awesome-icon icon="plus"></font-awesome-icon> Nuovo Ruolo </b-button>
                </div> -->

        <b-row class="sa-label-info">
          <b-col lg="3">
            <p>Record Totali: {{ rows }}</p>
          </b-col>
          <b-col lg="9">
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
          </b-col>
        </b-row>
      </div>

      <div class="sa-flexbox-body">
        <div class="b-table-sticky-header">
          <b-table sticky-header ref="table" stacked="lg" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
            <template #cell(index)="row">
              <span> {{ row.index + 1 }} </span>
            </template>

            <template v-slot:cell(actions)="{ item }">
              <b-container class="bv-example-row">
                <b-button size="sm" v-b-tooltip.hover title="Modifica Ruolo" variant="outline-success" @click="onClickEditRuoloPai(item)" class="mr-1 no-text">
                  <font-awesome-icon icon="edit"> </font-awesome-icon>
                </b-button>

                <b-button size="sm" v-b-tooltip.hover title="Cancella Ruolo" variant="outline-danger" @click="onClickDeleteRuoloPai(item)">
                  <font-awesome-icon icon="trash"></font-awesome-icon>
                </b-button>
              </b-container>
            </template>
          </b-table>
        </div>
      </div>

      <rsa-pai-ruoli-edit-component ref="cmpRsaPaiRuoliEdit" @onCloseModal="loadData" @onSaveRuoloPai="onRuoloSave" @onDeleteRuoloPai="onRuoloDelete"></rsa-pai-ruoli-edit-component>
      <b-row class="sa-label-info">
        <b-col lg="3">
          <p>Record Totali: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import UtilityMixin from "../../../utility/UtilityMixin";
import RsaPaiRuoliEditComponent from "./RsaPaiRuoliEditComponent.vue";

export default {
  components: { RsaPaiRuoliEditComponent },
  mixins: [UtilityMixin],
  data() {
    return {
      rows: 0,
      perPage: 50,
      currentPage: 1,
      pathResourcePaiRuoli: "/rsapairuoli",
      btnNuovoRuoloPaiHidden: false,
      filtroPaiRuoli: { ruolo: null, page: 0, forPage: 0 },
      listKey: 0,
      items: [],
      fields: [
        { key: "index", label: "N." },
        { key: "ruolo", label: "Ruolo" },
        { key: "tipologiaAccesso", label: "Tipologia di Accesso" },
        { key: "descrizione", label: "Descrizione" },
        { key: "actions", label: "", class: "sa-table-column-action-double" },
      ],
    };
  },

  watch: {
    currentPage() {
      let me = this;
      me.loadData();
    },
  },

  mounted() {
    let me = this;
    me.loadData();
  },

  methods: {
    loadData() {
      let me = this;
      me.$emit("beforeLoadData");
      let sessionStorage = window.sessionStorage;
      if (sessionStorage["filtroPaiRuoli"]) {
        me.filtroPaiRuoli = JSON.parse(sessionStorage["filtroPaiRuoli"]);
      }
      me.filtroPaiRuoli.page = me.currentPage;
      me.filtroPaiRuoli.forPage = me.perPage;

      UtilityMixin.methods.loadDataResources(me.pathResourcePaiRuoli, me.filtroPaiRuoli, me.loadRuoliDataSuccess, me.loadRuoliDataError);
      /* this.showModalLoading = true; */
    },
    loadRuoliDataSuccess(response) {
      let me = this;
      me.items = response.data.data.list;
      me.rows = response.data.data.recordsNumber;

      me.$emit("afterLoadData");
    },
    loadRuoliDataError(error) {
      let me = this;
      console.error("Errore durante il caricamento dei dati di loadSpecializzazionePaiDataError:", error);
      me.items = [];
      me.rows = 0;
      me.$emit("afterLoadData");
    },

    onClickEditRuoloPai(item) {
      let me = this;
      me.$refs.cmpRsaPaiRuoliEdit.editRuoloPai(item.id);
    },
    /* onClickNuovoRuoloPai() {
            let me = this;
            me.$refs.cmpRsaPaiRuoliEdit.nuovoRuoloPai();
        }, */
    onClickDeleteRuoloPai(item) {
      let me = this;
      me.$refs.cmpRsaPaiRuoliEdit.onDeleteRuoloPai(item);
    },

    onRuoloSave() {
      let me = this;
      me.loadData();
    },
    onRuoloDelete() {
      let me = this;
      me.loadData();
    },
  },
};
</script>
