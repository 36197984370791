<template>
    <b-modal title="Dati di Fatturazione" ref="mdlDatiFatturazione" id="mdlDatiFatturazione" size="xl" @show="showNuoviDatiFatturazione">
        <!--  <div style="height: 100%">
      <div class="sa-tab-scroll"> -->
        <b-card class="sa-card" header="Dati di Fatturazione" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label>Nome</label>
                    <b-form-input id="inline-form-custom-select-pref" v-model="jsonData.nome" class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label>Cognome</label>
                    <b-form-input id="inline-form-custom-select-pref" v-model="jsonData.cognome" class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label>Identificativo</label>
                    <b-form-input id="inline-form-custom-select-pref" v-model="jsonData.identificativo" class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label>Codice Destinatario</label>
                    <b-form-input id="inline-form-custom-select-pref" v-model="jsonData.codiceDestinatario" class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Accettazione" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label>Data e ora Accettazione</label>
                    <date-picker format="DD/MM/YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataOraAccettazione" type="datetime"></date-picker>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Indirizzo di Fatturazione" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label>Provincia</label>
                    <b-form-select id="inline-form-custom-select-pref" v-model="provincia" class="mb-2 mr-sm-2 mb-sm-0" :options="provinciaOptions" :value="null" value-field="sigla" text-field="provincia" @change="onChangeProvincia" required></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label v-if="provincia === 'EE'">Stato Estero</label>
                    <label v-else>Comune</label>
                    <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.comuneCodiceIstat" class="mb-2 mr-sm-2 mb-sm-0" :options="comuniOptions" :value="null" value-field="codiceIstat" text-field="comune" @change="onChangeComuneNascita" required></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label>Cap</label>
                    <b-form-input id="inline-form-custom-select-pref" v-model="jsonData.cap" class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label>Indirizzo</label>
                    <b-form-input id="inline-form-custom-select-pref" v-model="jsonData.indirizzo" class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>
                </b-col>

                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label>Indirizzo P.E.C.</label>
                    <b-form-input id="inline-form-custom-select-pref" v-model="jsonData.pec" class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>
                </b-col>
            </b-row>
        </b-card>
        <!--   </div>
    </div> -->
        <template #modal-footer>
            <b-button @click="onSalvaDatiFatturazione" variant="outline-success">Salva</b-button>
            <b-button size="md" class="no-text" variant="outline-danger" @click="onClickUndo">Annulla</b-button>
        </template>
    </b-modal>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import UtilityMixin from "../../../utility/UtilityMixin";
export default {
    props: {
        // jsonDataAccettazione: { type: Object },
        // idAccettazione: { type: String },
    },
    mixins: [UtilityMixin],
    components: { DatePicker },
    data() {
        return {
            listKey: 0,
            pathResource: "/rsadatifatturazione",
            pathResourceNazioni: "/nazioni",
            pathResourceProvince: "/province",
            pathResourceComuni: "/comuni/provincia",
            pathResourceComune: "/comuni/istat",
            nazione: "",
            provincia: "",
            jsonData: {
                idAccettazione: "",
                nome: "",
                cognome: "",
                identificativo: "",
                codiceDestinatario: "",
                dataOraAccettazione: null,
                comuneCodiceIstat: "",
                indirizzo: "",
                cap: "",
                pec: "",
            },
            filtro: {},
            nazioneOptions: [],
            provinciaOptions: [],
            comuniOptions: [{ codiceIstat: "", comune: "-Seleziona Comune-" }],
        };
    },
    mounted() {
        let me = this;
        me.loadNazione();
        me.loadProvince();
    },
    watch: {},
    methods: {
        // init(jsonDataAccettazione) {
        //   let me = this;
        //   me.jsonDataAccettazione = jsonDataAccettazione;
        //   me.loadDatiFatturazione();
        // },
        showNuoviDatiFatturazione() {
            let me = this;
            me.jsonData = {};
            me.$refs.mdlDatiFatturazione.show();
        },
        close() {
            let me = this;
            me.$refs.mdlDatiFatturazione.hide();
        },
        loadDatiFatturazione(id, jsonDataAccettazione) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + id;
            axios
                .get(link)
                .then((response) => {
                    console.log(response);
                    me.jsonData = response.data.data;
                    me.loadProvince(me.jsonData.comuneCodiceIstat);
                })
                .catch((error) => {
                    me.setData(jsonDataAccettazione);
                    this.$bvToast.toast("Dati della Fatturazione non presenti", {
                        title: "ATTENZIONE!",
                        variant: "danger",
                        solid: true,
                    });

                    console.log("Il servizio delle province ha datao errore: " + error.response);
                });
        },
        // salvaDatiFatturazione() {
        //     let me = this;
        //     let link = process.env.VUE_APP_PATH_API + me.pathResource;
        //     axios.put(link, JSON.stringify(me.jsonData)).then(() => {
        //             this.$bvToast.toast("Salvataggio avvenuto con sucesso", {
        //                 title: "",
        //                 variant: "success",
        //                 solid: true,
        //             });
        //         });
        // },
        onSalvaDatiFatturazione() {
            let me = this;
            //console.log(me.objectAppuntamento);
            UtilityMixin.methods.saveResource(me.pathResource, me.jsonData, me.saveSucessResponse, me.saveErrorResponse);
        },
        saveSucessResponse(response) {
            this.$emit("successRestCall", response);
            this.$emit("afterSaveRequest");
            // this.$refs.mdlAgendaNuovoAppuntamentoModalComponent.hide();
        },
        saveErrorResponse(response) {
            if (response.response.status === 400) {
                this.$emit("errorRestCall", response);
            } else {
                this.$bvToast.toast("Errore nel salvarei dati " + response.message, {
                    title: "",
                    variant: "danger",
                    solid: true,
                });
            }
        },
        loadNazione() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceNazioni;
            axios
                .get(link)
                .then((response) => {
                    me.nazioneOptions = response.data.data;
                    me.nazioneOptions.unshift({ codiceIstat: "", comune: "-Seleziona Nazione-" });
                })
                .catch((error) => {
                    console.log("Il servizio delle province ha datao errore: " + error.response);
                });
        },
        loadProvince() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceProvince;
            axios
                .get(link)
                .then((response) => {
                    me.provinciaOptions = response.data.data;
                    me.provinciaOptions.unshift({ sigla: "", provincia: "-Seleziona Provincia-" });
                })
                .catch((error) => {
                    console.log("Il servizio delle province ha datao errore: " + error.response);
                });
        },
        onChangeProvincia(value) {
            let me = this;
            me.comuniOptions = [];
            //me.comuniOptions.unshift({ codiceIstat: "", comune: "-Seleziona Comune-" });
            me.loadComuni(value);
        },
        loadComuni(sigla) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceComuni + "/";
            axios
                .get(link + sigla)
                .then((response) => {
                    me.comuniOptions = response.data.data;
                    if (me.provincia === "EE") {
                        me.comuniOptions.unshift({ codiceIstat: "", comune: "-Seleziona Stato Estero-" });
                    } else {
                        me.comuniOptions.unshift({ codiceIstat: "", comune: "-Seleziona Comune-" });
                    }
                })
                .catch((error) => {
                    console.log("Il servizio dei comuni ha datao errore: " + error.response);
                });
        },
        onChangeComuneNascita(value) {
            let me = this;
            me.loadCap(value);
        },
        loadCap(codiceIstat) {
            let me = this;

            let link = process.env.VUE_APP_PATH_API + me.pathResourceComune + "/" + codiceIstat;
            if (codiceIstat) {
                axios
                    .get(link)
                    .then((response) => {
                        me.jsonData.cap = response.data.data.cap;
                    })
                    .catch((error) => {
                        console.log("Il servizio delle regioni ha datao errore: " + error.response.status);
                    });
            }
        },
        setData(jsonDataAccettazione) {
            let me = this;
            me.jsonData.idAccettazione = jsonDataAccettazione.id;
            me.jsonData.dataOraAccettazione = jsonDataAccettazione.createDate;
            me.jsonData.nome = jsonDataAccettazione.accettazioneAnagrafica.nome;
            me.jsonData.cognome = jsonDataAccettazione.accettazioneAnagrafica.cognome;
            me.jsonData.identificativo = jsonDataAccettazione.accettazioneAnagrafica.identificativo;
        },
        onClickUndo() {
            let me = this;
            me.$refs.mdlDatiFatturazione.hide();
        },
    },
};
</script>
