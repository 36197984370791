<template>
  <sa-page-layout slot="table-body" :btnNewVisible="btnNewVisible" @refresh="onRefresh" :btnRefreshVisible="true" :pathResource="pathResource" :linkedit="linkedit" :linkback="linkback" :showModalLoading="showModalLoading">
    <template slot="table-filter">
      <b-form @submit.prevent="onSubmit">
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-label-data">Codice</label>
            <b-form-input v-model="filtro.code" type="search" id="nome"></b-form-input>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-label-data">Data dal</label>
            <date-picker format="DD/MM/YYYY" value-type="timestamp" v-model="filtro.dataDal" type="date"></date-picker>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-label-data">Al</label>
            <date-picker format="DD/MM/YYYY" value-type="timestamp" v-model="filtro.dataAl" type="date"></date-picker>
          </b-col>
        </b-row>
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="sa-padding-right text-right">
            <b-button type="submit" variant="info"> {{ this.$i18n.t("patients.lblFind") }}</b-button>
            <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("patients.lblResetFilter") }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </template>
    <template slot="table-body">
      <logs-spid-list-component ref="LogsSpidListComponent" @beforeLoadData="onBeforeLoadData" @afterLoadData="onAfterLoadData"></logs-spid-list-component>
    </template>
  </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import LogsSpidListComponent from "../components/LogsSpidListComponent.vue";
import DatePicker from "vue2-datepicker";
export default {
  components: { SaPageLayout, DatePicker, LogsSpidListComponent },

  data() {
    return {
      filtro: {
        code: "",
        dataDal: null,
        dataAl: null,
      },
      btnNewVisible: false,
      pathResource: "/spidlog",
      showModalLoading: false,
      linkedit: "/",
      linkback: "/",
    };
  },

  mounted() {
    let me = this;
    me.loadData();
    // let sessionStorage = window.sessionStorage;
    // if (sessionStorage["filtro"]) {
    //   me.filtro = JSON.parse(sessionStorage["filtro"]);
    // }
  },

  methods: {
    loadData() {
      let me = this;
      me.$refs.LogsSpidListComponent.loadData();
    },
    onBeforeLoadData() {
      let me = this;
      me.showModalLoading = true;
    },
    onAfterLoadData() {
      let me = this;
      me.showModalLoading = false;
    },
    onSubmit() {
      let me = this;
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
    onReset() {
      let me = this;
      me.filtro = {};
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
  },
};
</script>
