<template>
	<div class="sa-free-page">
		<div class="sa-free-page-header">Header</div>
		<div class="sa-free-page-body">
			<b-row>
				<b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
					<b-card class="sa-card" header="Database" header-tag="header" footer-tag="footer" title="">
						<template #header>
							<div style="display: flex">
								<span style="flex: 1">Database</span>
								<b-button v-b-tooltip.hover title="Leggi File" size="sm" variant="outline-secondary outline-secondary-noborder pull-right" @click="onClickLeggiFile()">
									<i class="fas fa-database"></i>
								</b-button>
								<b-button v-b-tooltip.hover title="Salva File" size="sm" :disabled="databaseDisabledEdit" variant="outline-secondary outline-secondary-noborder pull-right" @click="onClickSaveDatabaseData()">
									<i class="far fa-save"></i>
								</b-button>
								<b-button v-b-tooltip.hover title="Test Connessione" size="sm" :disabled="databaseDisabledEdit" variant="outline-secondary outline-secondary-noborder pull-right" @click="onClickTestConnessione()">
									<i class="far fa-save"></i>
								</b-button>
							</div>
						</template>
						<b-row>
							<b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
								<label>File Name (.properties)</label>
								<b-form-input v-model="jsonDataDatabase.fileName"></b-form-input>
							</b-col>
							<b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
								<label>Driver</label>
								<b-form-select id="inline-form-custom-select-pref" :disabled="databaseDisabledEdit" v-model="jsonDataDatabase.driver" class="mb-2 mr-sm-2 mb-sm-0" :value="null" value-field="driverName" text-field="label" :options="driverOptions"></b-form-select>
							</b-col>
							<b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
								<label>Host</label>
								<b-form-input :disabled="databaseDisabledEdit" v-model="jsonDataDatabase.host"></b-form-input>
							</b-col>
							<b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
								<label>Porta</label>
								<b-form-input :disabled="databaseDisabledEdit" v-model="jsonDataDatabase.port"></b-form-input>
							</b-col>
							<b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
								<label>Username</label>
								<b-form-input :disabled="databaseDisabledEdit" v-model="jsonDataDatabase.username"></b-form-input>
							</b-col>
							<b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
								<label>Password</label>
								<b-form-input :disabled="databaseDisabledEdit" v-model="jsonDataDatabase.password"></b-form-input>
							</b-col>
							<b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
								<label>Database</label>
								<b-form-input :disabled="databaseDisabledEdit" v-model="jsonDataDatabase.databaseName"></b-form-input>
							</b-col>
							<b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
								<label>Test Connessione: </label>
								<span v-if="databaseConnectionSuccess" style="display: block; color: green">{{ databaseConnectionSuccessMessage }}</span>
								<span v-else style="display: block; color: #e10808">{{ databaseConnectionSuccessMessage }}</span>
							</b-col>
						</b-row>
					</b-card>
				</b-col>
			</b-row>
		</div>
		<div class="sa-free-page-footer">Footer</div>
	</div>
</template>

<script>
import axios from "axios";
export default {
	data() {
		return {
			databaseConnectionSuccess: true,
			databaseConnectionSuccessMessage: "",
			databaseDisabledEdit: true,
			pathResourceDatabaseData: "/installation/databasedata",
			pathResourceDatabaseDriver: "/installation/databasedrivers",
			pathResourceVerifyDbConnection: "/installation/verifydbconnection",
			driverOptions: [],
			jsonDataDatabase: { fileName: "gesandb.properties", driver: null, username: null, password: null, host: null, port: null, databaseName: null },
		};
	},
	mounted() {
		let me = this;
		me.getLoadDatabaseDriver();
	},
	methods: {
		getLoadDatabaseDriver() {
			let me = this;
			let link = process.env.VUE_APP_PATH_API + me.pathResourceDatabaseDriver;
			axios.get(link)
				.then((response) => {
					me.driverOptions = response.data.data;
					me.driverOptions.unshift({ label: "-Seleziona Driver-", driverName: null });
				})
				.catch(() => {
					console.log("Traduora non configurato");
				});
		},
		loadDatabaseData() {
			let me = this;
			let link = process.env.VUE_APP_PATH_API + me.pathResourceDatabaseData;
			axios.get(link, { params: { fileName: me.jsonDataDatabase.fileName } })
				.then((response) => {
					me.jsonDataDatabase = response.data.data;
					me.databaseDisabledEdit = false;
				})
				.catch(() => {
					console.log("Traduora non configurato");
				});
		},
		onClickSaveDatabaseData() {
			let me = this;
			let link = process.env.VUE_APP_PATH_API + me.pathResourceDatabaseData;
			axios.post(link, me.jsonDataDatabase)
				.then(() => {
					console.log("Dati Salvati correttamente");
				})
				.catch(() => {
					console.log("Traduora non configurato");
				});
		},
		onClickLeggiFile() {
			let me = this;
			me.loadDatabaseData();
		},
		onClickTestConnessione() {
			let me = this;
			let link = process.env.VUE_APP_PATH_API + me.pathResourceVerifyDbConnection;
			axios.get(link, { params: me.jsonDataDatabase })
				.then((response) => {
					me.databaseConnectionSuccessMessage = response.data.messaggio;
					me.databaseConnectionSuccess = true;
					console.log(response);
				})
				.catch((error) => {
					me.databaseConnectionSuccess = false;
					me.databaseConnectionSuccessMessage = error.response.data.messaggio;
				});
		},
	},
};
</script>

<style></style>
