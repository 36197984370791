<template>
    <sa-page-layout :btnSaveNotBackVisible="btnSaveNotBackVisible" :linkNotBack="linkNotBack" @refresh="onRefresh" :btnPrintVisible="false" :backAfterSave="backAfterSave" :btnSaveVisible="btnSaveVisible" :btnBackVisible="true" :pathResource="pathResource" :showModalLoading="showModalLoading" :data="jsonData" :linkback="linkback" :linkPrintData="linkPrintData()" @aftersave="onAfterSave" class="sa-no-space" toggleableDimension="sm">
        <!-- <template slot="toolbar">
      <b-button v-if="btnPresaInCarico" size="sm" variant="btn btn-outline-success waves-effect float-sm-left" @click="onCambiaStato(id, 'PRESA IN CARICO')">Presa in carico</b-button>
      <b-button v-if="btnRespinta" size="sm" variant="btn btn-outline-danger waves-effect float-sm-left" @click="onCambiaStato(id, 'RESPINTA')">Respingi</b-button>
      <b-button v-if="btnInviataCot" size="sm" variant="btn btn-outline-success waves-effect float-sm-left" @click="onCambiaStato(id, 'INVIATA A C.O.T.')">Invia a C.O.T.</b-button>
      <b-button v-if="btnInviataUvm" size="sm" variant="btn btn-outline-success waves-effect float-sm-left" @click="onCambiaStato(id, 'INVIATA A U.V.M.')">Invia a U.V.M.</b-button>
    </template> -->
        <template slot="table-body">
            <cot-proposta-accesso-edit-component ref="CotPropostaAccessoEditComponent" @afterLoadData="onAfterLoadData" :jsonData="jsonData" @update="onUpdateJsonData" :utente="utente"></cot-proposta-accesso-edit-component>
        </template>
    </sa-page-layout>
</template>

<script>
import Vue from "vue";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import CotPropostaAccessoEditComponent from "../components/CotPropostaAccessoEditComponent.vue";
import UtilityMixin from "../../utility/UtilityMixin";
import axios from "axios";
export default {
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    mixins: [UtilityMixin],
    components: { SaPageLayout, CotPropostaAccessoEditComponent },
    data() {
        return {
            btnPresaInCarico: true,
            btnRespinta: true,
            btnInviataCot: true,
            btnInviataUvm: true,
            btnSaveNotBackVisible: true,
            pathResource: "/cot",
            pathCambiaStato: "/cot/aggiornaStato",
            linkNotBack: "",
            btnSaveVisible: false,
            backAfterSave: false,
            showModalLoading: false,
            jsonData: {
                identificativo: "",
                cognome: "",
                nome: "",
                referenteCaregiver: "",
                altriInterventiNaturaSociosanitaria: false,
                assistenzaStruttureSemiresidenzialiPersoneNonAutosufficien: false,
                ricoveroStrutturaResidenzialeSocioSanitariaPazientiNonAu: false,
                puaDistrettoSanitario: "",
                ambitoSociale: "",
                assistenzaDomiciliareEstemporanea: false,
                assistenzaDomiciliareIntegrata: false,
                assistenzaDomiciliareProgrammataMmg: false,
                assistenzaDomiciliareSociale: false,
                assistenzaDomiciliareDisabili: false,
                ricoveroHospice: false,
                accoglienzaCentroDiurnoSocioEducativoDisabili: false,
                ricoveroStrutturaResidenzialeExtraospedalieraElevatoImpegn: false,
                ricoveroStrutturaResidenzialePersoneDisabilita: false,
                altro: false,
                altroSpecificare: "",
                comuneNascitaCodiceIstat: "",
                dataNascita: null,
                comuneResidenzaCodiceIstat: "",
                indirizzoResidenza: "",
                primaPatologiaDescrizione: "",
                primaPatologiaCodiceIcdix: "",
                primaPatologiaConxomitanteDescrizione: "",
                primaPatologiaConxomitanteCodiceIcdix: "",
                secondaPatologiaConxomitanteDescrizione: "",
                secondaPatologiaConxomitanteCodiceIcdix: "",
                situazioneCognitivaLucido: false,
                situazioneCognitivaConfuso: false,
                situazioneCognitivaMoltoConfuso: false,
                problemiComportamentaliAsentiLievi: false,
                problemiComportamentaliModerati: false,
                problemiComportamentaliGravi: false,
                situazioneFunzionaleAutonomoQuasi: false,
                situazioneFunzionaleDipendente: false,
                situazioneFunzionaleTotalmenteDipendente: false,
                gradoAutonomiaSpostaSolo: false,
                gradoAutonomiaSpostaAssistito: false,
                gradoAutonomiaTotalmenteDipendente: false,
                supportoReteSocialeBenAssistito: false,
                supportoReteSocialePazialmenteAssistito: false,
                supportoSocialeNonAssistito: false,
                necessitaAssitenzaSanitariaBassa: false,
                necessitaAssistenzaSanitariaIntermedia: false,
                necessitaAssistenzaSanitariaElevata: false,
                totalePunteggio: 0,
                impossibilitaPermanenteDeambulare: false,
                impossibilitaTrasportatoAmbulatorioCausa: false,
                impossibilitaTrasportatoAmbulatorioCausaAutosufficiente: false,
                impossibilitaTrasportatoAmbulatorioCausaAbitazionePianoAl: false,
                impossibilitaEssereTrasportatoAmbulatorioMezziComuniCausa: false,
                impossibilitaEssereTrasportatoAmbulatorioMezziComuniSpeci: "",
                impossibileEssereTrasportatoGraviPatologie: false,
                bisogniAssistenzialiRilevatiAutonomiaAutonomo: false,
                bisogniAssistenzialiRilevatiAutonomiaParzialmenteAutonomo: false,
                bisogniAssistenzialiRilevatiAutonomiaTotalmenteDipendente: false,
                bisogniAssistenzialiRilevatiAutonomiaStatoVegetatitivo: false,
                bisogniAssistenzialiRilevatiRiabilitazioneNessunaRiabilita: false,
                bisogniAssistenzialiRilevatiRiabilitazioneRiabilitazioneNe: false,
                bisogniAssistenzialiRilevatiRiabilitazioneAfasia: false,
                bisogniAssistenzialiRilevatiRiabilitazioneRiabilitazioneOr: false,
                bisogniAssistenzialiRilevatiApparatoRespiratorioNormale: false,
                bisogniAssistenzialiRilevatiApparatoRespiratorioTosseSecr: false,
                bisogniAssistenzialiRilevatiApparatoRespiratorioOssigenote: false,
                bisogniAssistenzialiRilevatiApparatoRespiratorioVentiloter: false,
                bisogniAssistenzialiRilevatiApparatoRespiratorioTracheotom: false,
                bisogniAssistenzialiRilevatiStatoMutrizionaleNormale: false,
                bisogniAssistenzialiRilevatiStatoMutrizionaleDimagrito: false,
                bisogniAssistenzialiRilevatiStatoMutrizionaleNutrizioneDi: false,
                bisogniAssistenzialiRilevatiComportamentoNormale: false,
                bisogniAssistenzialiRilevatiComportamentoDisturboCognitivo: false,
                bisogniAssistenzialiRilevatiComportamentoDisturboComportam: false,
                bisogniAssistenzialiRilevatiComportamentoCondizioniSalute: false,
                bisogniAssistenzialiRilevatiRitmoSonnoVegliaAlterato: false,
                bisogniAssistenzialiRilevatiRitmoSonnoVegliaRischioInfet: false,
                bisogniAssistenzialiRilevatiRitmoSonnoVegliaFebbre: false,
                bisogniAssistenzialiRilevatiApparatoGastrointestinaleNorm: false,
                bisogniAssistenzialiRilevatiApparatoGastrointestinaleInco: false,
                bisogniAssistenzialiRilevatiApparatoGastrointestinaleVomi: false,
                bisogniAssistenzialiRilevatiApparatoGastrointestinaleSang: false,
                bisogniAssistenzialiRilevatiApparatoGenitoUrinarioNormale: false,
                bisogniAssistenzialiRilevatiApparatoGenitoUrinarioInconti: false,
                bisogniAssistenzialiRilevatiApparatoGenitoUrinarioCateter: false,
                bisogniAssistenzialiRilevatiApparatoGenitoUrinarioUrostom: false,
                bisogniAssistenzialiRilevatiApparatoTegumentarioNormale: false,
                bisogniAssistenzialiRilevatiApparatoTegumentarioUlcereCP: false,
                bisogniAssistenzialiRilevatiApparatoTegumentarioUlcereCT: false,
                bisogniAssistenzialiRilevatiApparatoTegumentarioStatoGrav: false,
                bisogniAssistenzialiRilevatiApparatoGastrointestinaleStom: false,
                bisogniAssistenzialiRilevatiApparatoGastrointestinaleStip: false,
                bisogniAssistenzialiRilevatiApparatoGastrointestinaleDiar: false,
                bisogniAssistenzialiRilevatiApparatoGenitoUrinarioEmaturi: false,
                bisogniAssistenzialiRilevatiApparatoGenitoUrinarioDialisi: false,
                bisogniAssistenzialiRilevatiApparatoTegumentarioCuraFerit: false,
                bisogniAssistenzialiRilevatiApparatoTegumentarioAltriProb: false,
                bisogniAssistenzialiRilevatiApparatoTegumentarioLacerazion: false,
                bisogniAssistenzialiRilevatiOncologicoNonOncologicoTermi: false,
                bisogniAssistenzialiRilevatiOncologicoTerminaleNonOncolo: false,
                bisogniAssistenzialiRilevatiOncologicoTerminaleOncologica: false,
                bisogniAssistenzialiRilevatiOncologicoOncologico: false,
                bisogniAssistenzialiRilevatiOncologicoChemioterapia: false,
                bisogniAssistenzialiRilevatiOncologicoRadioterapia: false,
                bisogniAssistenzialiRilevatiOncologicoDolorePresente: false,
                bisogniAssistenzialiRilevatiPrestazioniPrelievoVenosoNon: false,
                bisogniAssistenzialiRilevatiPrestazioniEcg: false,
                bisogniAssistenzialiRilevatiPrestazioniTelemetria: false,
                bisogniAssistenzialiRilevatiPrestazioniTrasfusioni: false,
                bisogniAssistenzialiRilevatiPrestazioniTerapiaEndovena: false,
                bisogniAssistenzialiRilevatiPrestazioniTerapiaIntramuscol: false,
                bisogniAssistenzialiRilevatiPrestazioniGestioneCvc: false,
                medicoMedicinaGeneraleNumeroSettimane: "",
                medicoMedicinaGeneraleDal: null,
                medicoMedicinaGeneraleAl: null,
                medicoSpecialistaNumeroSettimane: "",
                medicoSpecialistaDal: null,
                medicoSpecialistaAl: null,
                infermiereProfessionaleNumeroSettimane: "",
                infermiereProfessionaleDal: null,
                infermiereProfessionaleAl: null,
                fisioterapistaLogopedistaTerapistaOccupazionaleNumeroSetti: "",
                fisioterapistaLogopedistaTerapistaOccupazionaleDal: null,
                fisioterapistaLogopedistaTerapistaOccupazionaleAl: null,
                psicologoNumeroSettimane: "",
                psicologoDal: null,
                psicologoAl: null,
                operatoreSociosanitarioNumeroSettimane: "",
                operatoreSociosanitarioDal: null,
                operatoreSociosanitarioAl: null,
                bisogniSocioSanitariRilevati: "",
                luogo: "",
                // data: null,
                medico: "",
                statoAttuale: "",
                statoAttualeDataOra: null,
                telefono: "",
                impossibilitaAltro: false,
                statoNutrizionaleDisidratazione: false,
                disturboCognitivoGrave: false,
                sesso: "",
                tesseraSanitaria: "",
                cittadinanza: "",
                capResidenza: "",
                nazioneResidenzaCodiceIstat: "",
                regioneResidenzaCodiceIstat: "",
                email: "",
                medicoCurante: "",
                contattiMmg: "",
                comuneDomicilioCodiceIstat: "",
                capNascita: "",
                capDomicilio: "",
                indirizzoDomicilio: "",
                prestazioneInterventiNaturaSociosanitaria: "",
                numeroProtocollo: "",
                dataProtocollo: null,
                cotDestinazione: "",
                rsaDestinazione: "",
                codiceRsaDestinazione: "",
                provinciaResidenza: "",
            },
            linkback: "",
            id: "-1",
            // linkPrintData: "",
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        let utente = Vue.prototype.user;
        if (utente.profilo === "COT MMG") {
            me.btnPresaInCarico = false;
            me.btnRespinta = false;
            me.btnInviataCot = true;
            me.btnInviataUvm = false;
        } else if (utente.profilo === "COT OPERATORE") {
            me.btnPresaInCarico = true;
            me.btnRespinta = true;
            me.btnInviataCot = false;
            me.btnInviataUvm = true;
        } else if (utente.profilo === "COT UVM") {
            me.btnPresaInCarico = true;
            me.btnRespinta = true;
            me.btnInviataCot = false;
            me.btnInviataUvm = false;
        }

        if (this.$route.query.codiceFiscale) {
            me.linkback =
                "/cot/richieste/edit/-1" +
                "?view=module&codiceFiscale=" +
                me.jsonData.identificativo +
                "&codiceStruttura=" +
                me.jsonData.provenienzaCodiceStruttura +
                "&descrizioneStruttura=" +
                me.jsonData.provenienzaDescrizioneStruttura +
                "&codiceReparto=" +
                me.jsonData.provenienzaCodiceReparto +
                "&descrizioneReparto=" +
                me.jsonData.provenienzaDescrizioneReparto +
                "&eventoClinico=" +
                me.jsonData.provenienzaEventoClinico +
                "&nominativoMedico=" +
                me.jsonData.provenienzaNominativoMedico +
                "&cognomeMedico=" +
                me.jsonData.provenienzaCognomeMedico +
                "&codiceFiscaleMedico=" +
                me.jsonData.provenienzaCodiceFiscaleMedico +
                "&ruoloMedico=" +
                me.jsonData.provenienzaRuoloMedico +
                "&idMedico=" +
                me.jsonData.provenienzaIdMedico +
                "&mailMedico=" +
                me.jsonData.provenienzaMail +
                "&usernameMedico=" +
                me.jsonData.provenienzaUsername;
        } else if (me.id) {
            me.linkback = "/cot/richieste/edit/" + me.id;
        } else {
            me.linkback = "/cot/richieste/edit/-1";
        }
        // console.log(me.linkback);
        // me.loadData();
    },
    methods: {
        setPermessi() {
            let me = this;
            // console.log(me.jsonData);
            // if (me.id === -1) {
            //     me.btnSaveNotBackVisible = UtilityMixin.methods.verificaPermessi(me.utente, "cotproposteaccesso", 2);
            // } else {
            //     me.btnSaveNotBackVisible = UtilityMixin.methods.verificaPermessi(me.utente, "cotproposteaccesso", 4);
            // }
            me.btnSaveNotBackVisible = true;
        },
        onAfterLoadData() {},
        onRefresh() {},
        onAfterSave(jsonData) {
            let me = this;
            if (this.$route.query.codiceFiscale) {
                setTimeout(() => {
                    me.$router
                        .replace(
                            "/cot/richieste/edit/" +
                                jsonData.id +
                                "?view=module&codiceFiscale=" +
                                me.jsonData.identificativo +
                                "&codiceStruttura=" +
                                me.jsonData.provenienzaCodiceStruttura +
                                "&descrizioneStruttura=" +
                                me.jsonData.provenienzaDescrizioneStruttura +
                                "&codiceReparto=" +
                                me.jsonData.provenienzaCodiceReparto +
                                "&descrizioneReparto=" +
                                me.jsonData.provenienzaDescrizioneReparto +
                                "&eventoClinico=" +
                                me.jsonData.provenienzaEventoClinico +
                                "&nominativoMedico=" +
                                me.jsonData.provenienzaNominativoMedico +
                                "&cognomeMedico=" +
                                me.jsonData.provenienzaCognomeMedico +
                                "&codiceFiscaleMedico=" +
                                me.jsonData.provenienzaCodiceFiscaleMedico +
                                "&ruoloMedico=" +
                                me.jsonData.provenienzaRuoloMedico +
                                "&idMedico=" +
                                me.jsonData.provenienzaIdMedico +
                                "&mailMedico=" +
                                me.jsonData.provenienzaMail +
                                "&usernameMedico=" +
                                me.jsonData.provenienzaUsername
                        )
                        .catch((err) => {
                            console.log(err);
                        });
                }, 1000);
            } else {
                setTimeout(() => {
                    me.$router.replace("/cot/richieste/edit/" + jsonData.id).catch((err) => {
                        console.log(err);
                    });
                }, 1000);
            }
        },
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
            // console.log(me.jsonData);
        },
        linkPrintData() {
            let me = this;
            let returnValue = "/cotprinter/" + me.id;
            return returnValue;
        },

        onCambiaStato(riga, stato) {
            let json = { idCot: riga, stato: stato };
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathCambiaStato;
            axios
                .post(link, json)
                .then((response) => {
                    this.$bvToast.toast("Cambio Stato: " + response.data.data.stato, {
                        title: this.titleModal,
                        variant: "success",
                        autoHideDelay: 1000,
                    });
                    me.jsonData.statoAttuale = response.data.data.stato;
                    me.jsonData.statoAttualeDataOra = response.data.data.statoDataOra;
                    me.onUpdatePropostaAccesso(me.jsonData);
                    // me.$refs.propostaAccesso.loadData();
                })
                .catch((error) => {
                    // me.$refs["sa-modal-loading"].close();
                    let message = "";
                    error.response ? error.response.data.messaggio : "Errore non gestito";
                    if (error.response) {
                        message = error.response.data.messaggio ? error.response.data.messaggio : "Codice Errore: " + error.response.status + ", non gestito";
                    }
                    if (error.response) {
                        this.$bvModal
                            .msgBoxOk(message, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then(() => {})
                            .catch((err) => {
                                console.log(err);
                            });
                    }
                });
        },
    },
};
</script>

<style></style>
