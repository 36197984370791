<template>
    <sa-page-layout :btnBackVisible="false" :linkback="linkback" :linkedit="linkedit" :pathResource="pathResource" :showModalLoading="showModalLoading">
        <template slot="toolbar-title">
            <rsa-ospite-anagrafica-component :jsonData="jsonData"></rsa-ospite-anagrafica-component>
        </template>
        <template slot="table-body">
            <b-tabs id="tabsRsaAccettazioneEdit" ref="tabsRsaAccettazioneEdit" v-model="activeTabIndex" nav-wrapper-class="sa-tab-items" content-class="mt-3" class="sa-tab" style="height: 100%; padding-bottom: 25px" fill @input="onInputTabs">
                <b-tab title="Anagrafica" id="tbAnagrafica" active>
                    <rsa-accettazione-anagrafica-view-component ref="cmpRsaAccettazioneAnagraficaViewComponent" @afterLoadData="onAfterLoadData" @beforeLoadData="onBeforeLoadData"></rsa-accettazione-anagrafica-view-component>
                </b-tab>
                <b-tab title="Persone di riferimento" id="tbPersoneRiferimento">
                    <rsa-persona-riferimento-component ref="cmpRsaPersonaRiferimento" :jsonData="jsonData"></rsa-persona-riferimento-component>
                </b-tab>
                <b-tab title="impegnativa (U.V.I.)" id="tbImpegnativa">
                    <rsa-accettazione-impegnativa-list-component ref="cmpRsaImpegnativaList" :idAccettazione="idPaziente" :jsonData="jsonData"></rsa-accettazione-impegnativa-list-component>
                </b-tab>
                <b-tab title="Spese extra" id="tbSpeseExtra">
                    <rsa-spesa-extra-list-component ref="cmpRsaSpesaExtraList" :jsonDataAccettazione="jsonData"><!-- :utente="utente" --></rsa-spesa-extra-list-component>
                </b-tab>
                <b-tab title="Presenza" id="tbPresenza">
                    <rsa-scheda-ospite-uscite-list-component ref="cmpRsaUsciteList" :jsonDataAccettazione="jsonData"></rsa-scheda-ospite-uscite-list-component>
                </b-tab>
                <!--<b-tab title="Dimissione/Trasferimento" id="tbDimissioniTrasferimeti">
                    <rsa-scheda-ospite-dimissione-trasferimento-component ref="cmpRsaDimissioneTrasferimento" :jsonDataAccettazione="jsonData"></rsa-scheda-ospite-dimissione-trasferimento-component>
                </b-tab>-->
            </b-tabs>
        </template>
    </sa-page-layout>
</template>

<script>
import axios from "axios";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import RsaOspiteAnagraficaComponent from "../../rsagestioneospiti/components/RsaOspiteAnagraficaComponent.vue";
import RsaAccettazioneAnagraficaViewComponent from "../../rsaaccettazione/components/RsaAccettazioneAnagraficaViewComponent.vue";
import RsaPersonaRiferimentoComponent from "../../components/RsaPersonaRiferimentoComponent.vue";
import RsaAccettazioneImpegnativaListComponent from "../../rsaaccettazione/components/RsaAccettazioneImpegnativaListComponent.vue";
import RsaSpesaExtraListComponent from "../../rsaaccettazione/components/RsaSpesaExtraListComponent.vue";
import RsaSchedaOspiteUsciteListComponent from "../../rsagestioneospiti/components/RsaSchedaOspiteUsciteListComponent.vue";
//import RsaSchedaOspiteDimissioneTrasferimentoComponent from "../../rsagestioneospiti/components/RsaSchedaOspiteDimissioneTrasferimentoComponent.vue";
export default {
    props: {
        utente: { type: Object },
    },
    components: {
        SaPageLayout,
        RsaOspiteAnagraficaComponent,
        RsaAccettazioneAnagraficaViewComponent,
        RsaPersonaRiferimentoComponent,
        RsaAccettazioneImpegnativaListComponent,
        RsaSpesaExtraListComponent,
        RsaSchedaOspiteUsciteListComponent,
        //      RsaSchedaOspiteDimissioneTrasferimentoComponent,
    },
    data() {
        return {
            idPaziente: null,
            btnNuovaRichiestaEsameHidden: true,
            btnNuovaRichiestaUscita: true,
            filtro: {},
            btnNewVisible: true,
            pathResource: null,
            pathResourceAccettazione: "/accettazioni",
            linkedit: null,
            linkback: "/rsagestioneospiti",
            showModalLoading: false,
            listKey: 0,
            activeTabIndex: 1547,
            jsonData: { accettazioneAnagrafica: {}, accettazionePrestazioni: {}, accettazioneStati: [], accettazioneStruttura: {} },
        };
    },
    mounted() {
        let me = this;
        me.idPaziente = this.$route.query.idPaziente;
        me.loadAccettazione();
    },
    methods: {
        loadAccettazione() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceAccettazione + "/" + me.idPaziente;
            axios
                .get(link)
                .then((response) => {
                    me.jsonData = response.data.data;
                    me.$refs.cmpRsaAccettazioneAnagraficaViewComponent.setId(me.idPaziente);
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        onInputTabs(index) {
            let me = this;
            let activeTab = this.$refs.tabsRsaAccettazioneEdit.getTabs()[index];

            me.activeTab(activeTab.id);
        },
        activeTab(idActiveTab) {
            let me = this;
            switch (idActiveTab) {
                case "tbPersoneRiferimento":
                    me.$refs.cmpRsaPersonaRiferimento.loadData(me.idPaziente);
                    break;
                case "tbImpegnativa":
                    me.$refs.cmpRsaImpegnativaList.loadData(me.idPaziente);
                    break;
                case "tbSpeseExtra":
                    me.$refs.cmpRsaSpesaExtraList.setId(me.idPaziente);
                    break;
                case "tbPresenza":
                    me.$refs.cmpRsaUsciteList.setId(me.idPaziente);
                    break;
                // case "tbDimissioniTrasferimeti":
                //     me.$refs.cmpRsaDimissioneTrasferimento.setId(me.idPaziente);
                //     break;
            }
        },
        onBeforeLoadData() {
            let me = this;
            me.showModalLoading = true;
        },
        onAfterLoadData() {
            let me = this;
            me.showModalLoading = false;
        },
    },
};
</script>

<style></style>
