<template>
  <div class="sa-tab-scroll">
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Affetto da:</label>
          <b-form-input v-model="jsonData.affettoDa"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Paziente Invalido:</label>
          <b-form-select v-model="jsonData.pazienteInvalido" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Attesa Accerrtamento Invalidita':</label>
          <b-form-select v-model="jsonData.attesaAccertamentoInvalidita" :options="siNoOptions" :value="''" value-field="value" text-field="text" :disabled="jsonData.pazienteInvalido !== 'SI'"></b-form-select>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Si richiede la fornitura dei seguenti ausili" header-tag="header" footer-tag="footer" title="">
      <b-table sticky-header ref="table" stacked="sm" striped hover :items="jsonData.listaRichieste" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header">
        <template #cell(descrizione)="{ item }">
          <label class="sa-label-data">- {{ item.decrizione }} ({{ item.codice }})</label>
        </template>
        <template #cell(check)="{ item }">
          <b-form-checkbox v-model="item.check" :checked="item.check" switch></b-form-checkbox>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import axios from "axios";
export default {
  components: {},
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
  },
  data() {
    return {
      idPropostaAccesso: null,
      keyPropostaAccesso: 0,
      linkback: "",
      linkPrintData: "",
      childKey: 0,
      viewPai: true,
      pathResource: "/cotmodulisticarichiestafornituraausilirichiesta",
      pathResourceRichiestaAusili: "/cotmodulisticarichiestafornituraausilielenco/listaausili",
      totale: 0,
      jsonData: {
        id: null,
        affettoDa: "",
        pazienteInvalido: "",
        attesaAccertamentoInvalidita: "",
        listaRichieste: [],
      },
      siNoOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "SI", text: "SI" },
        { value: "NO", text: "NO" },
      ],
      fieldsListe: [
        {
          label: "Descrizione",
          key: "descrizione",
          sortable: true,
        },
        {
          label: "",
          key: "check",
          sortable: true,
          thStyle: "width: 10rem",
          tdClass: "text-center sa-column-widh",
        },
        // {
        //   label: "",
        //   key: "action",
        //   thStyle: "width: 10rem",
        // },
      ],
    };
  },
  mounted() {
    let me = this;
    me.idPropostaAccesso = this.$route.params.id;
    me.jsonData.idPropostaAccesso = me.idPropostaAccesso;
    me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.idPropostaAccesso;
      axios
        .get(link)
        .then((response) => {
          me.jsonData = response.data.data;
          // console.log("OK");
        })
        .catch(() => {
          let linkAusili = process.env.VUE_APP_PATH_API + me.pathResourceRichiestaAusili + "/" + me.idPropostaAccesso;
          axios
            .get(linkAusili)
            .then((response) => {
              me.jsonData.listaRichieste = response.data.data;
            })
            .catch(() => {});
        });
    },

    setListe() {},
  },
};
</script>

<style></style>
