<template>
  <div style="height: 100%">
    <div class="sa-flexbox" style="padding-bottom: 20px">
      <div class="d-flex flex-column">
        <b-row class="sa-label-info">
          <b-col lg="3">
            <p>Record Totali: {{ rows }}</p>
          </b-col>
          <b-col lg="9">
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
          </b-col>
        </b-row>
      </div>
      <div class="sa-flexbox-body">
        <div class="b-table-sticky-header">
          <b-table sticky-header ref="table" stacked="lg" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
            <template #cell(index)="riga">
              {{ riga.index + 1 }}
            </template>
            <template v-slot:cell(updateDate)="{ item }">
              {{ formatDate(item.updateDate) }}
            </template>
            <!--  <template v-slot:cell(actions)="{ item }">
              <b-container class="bv-example-row">
                <b-button size="sm" v-b-tooltip.hover title="Visualizza  " variant="outline-secondary no-text" @click="onClickView(item)" class="mr-1 no-text">
                  <font-awesome-icon icon="eye"> </font-awesome-icon>
                </b-button>
                <b-button size="sm" v-b-tooltip.hover title="Modifica " variant="outline-secondary no-text" @click="onClickEdit(item)" class="mr-1 no-text">
                  <font-awesome-icon icon="pencil-alt"> </font-awesome-icon>
                </b-button>
              </b-container>
            </template> -->
          </b-table>
        </div>
      </div>
      <div class="sa-list-component-footer">
        <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col lg="3">
            <p>Record Totali:{{ rows }}</p>
          </b-col>
          <b-col lg="9">
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import UtilityMixin from "../../../utility/UtilityMixin";

export default {
  components: {},
  mixins: [UtilityMixin],
  data() {
    return {
      rows: 0,
      perPage: 50,
      currentPage: 1,
      pathResource: "/spidlog",
      showModalLoading: false,
      listKey: 0,
      items: [],
      filtro: {
        // code: "",
        // dataDal: null,
        // dataAl: null,
      },
      fields: [
        {
          label: "",
          key: "index",
          sortable: false,
        },
        {
          label: "Code",
          key: "code",
          sortable: false,
        },
        {
          label: "Messaggio",
          key: "message",
          sortable: false,
        },
        {
          label: "Data",
          key: "updateDate",
          sortable: false,
        },
        //   {
        //   class: "sa-table-column-action-double",
        //   key: "actions",
        //   label: "",
        // },
      ],
    };
  },
  mounted() {},
  watch: {
    currentPage() {
      let me = this;
      me.loadData();
    },
  },
  methods: {
    loadData() {
      let me = this;
      let sessionStorage = window.sessionStorage;
      if (sessionStorage["filtro"]) {
        me.filtro = JSON.parse(sessionStorage["filtro"]);
      }
      me.filtro.page = me.currentPage;
      me.filtro.forPage = me.perPage;
      me.$emit("beforeLoadData");
      UtilityMixin.methods.loadDataResources(me.pathResource, me.filtro, me.loadDataSuccess, me.loadDataError);
    },

    loadDataSuccess(response) {
      let me = this;
      me.items = response.data.data.list;
      me.rows = response.data.data.recordsNumber;
      me.$emit("afterLoadData");
    },
    loadDataError(error) {
      let me = this;
      console.log(error);
      me.$emit("afterLoadData");
    },
  },
};
</script>
