<template>
    <sa-page-layout :btnRefreshVisible="true" @refresh="onRefresh" :linkedit="linkedit">
        <template slot="toolbar-title">
            <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
        </template>
        <template slot="toolbar">
            <div style="float: right">
                <b-button variant="success btn-toolbar float-sm-right sa-margin-right" size="sm" @click="onAssocia"><b-icon icon="plus"></b-icon>{{ this.$i18n.t("patient.btnAssociate") }}</b-button>
            </div>
        </template>
        <template slot="table-header">
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" responsive stacked="lg" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                    <template #cell(kitId)="row">
                        {{ row.item.kitId.toUpperCase() }}
                    </template>
                    <template #cell(hubName)="row">
                        {{ row.item.hubName.toUpperCase() }}
                    </template>
                    <template #cell(show_details)="row">
                        <b-button variant="btn btn-outline-secondary waves-effect waves-light width-sm no-text" size="sm" @click="row.toggleDetails" class="mr-2"> {{ row.detailsShowing ? "Nascondi" : "Visualizza" }} Dettagli</b-button>
                    </template>
                    <template #cell(delete)="row">
                        <b-button variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" size="sm" @click="onDeleteItem(row.item)"><b-icon icon="trash"></b-icon></b-button>
                    </template>
                    <template #row-details="row">
                        <b-card>
                            <div class="container-fluid">
                                <b-row class="mb-4">
                                    <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                                        <label class="sa-label-data">Temperature :</label>
                                        <span class="sa-data">{{ row.item.temperatureName.toUpperCase() }}</span>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                                        <label class="sa-label-data">Glucose :</label>
                                        <span class="sa-data">{{ row.item.glucoseName.toUpperCase() }}</span>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                                        <label class="sa-label-data">Ecg :</label>
                                        <span class="sa-data">{{ row.item.ecgName.toUpperCase() }}</span>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                                        <label class="sa-label-data">Spo2 :</label>
                                        <span class="sa-data">{{ row.item.spo2Name.toUpperCase() }}</span>
                                    </b-col>
                                </b-row>
                                <hr />
                                <b-row class="mb-2">
                                    <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                                        <label class="sa-label-data">Pressure :</label>
                                        <span class="sa-data">{{ row.item.pressureName.toUpperCase() }}</span>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                                        <label class="sa-label-data">Bracelet :</label>
                                        <span class="sa-data">{{ row.item.braceletName.toUpperCase() }}</span> </b-col
                                    ><b-col xs="12" sm="12" md="12" lg="3" xl="3">
                                        <label class="sa-label-data">Scale :</label>
                                        <span class="sa-data">{{ row.item.scaleName.toUpperCase() }}</span> </b-col
                                    ><b-col xs="12" sm="12" md="12" lg="3" xl="3">
                                        <label class="sa-label-data">Speed Sensor :</label>
                                        <span class="sa-data">{{ row.item.speedSensorName.toUpperCase() }}</span>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-card>
                    </template>
                </b-table>
            </div>
        </template>
        <template slot="table-footer">
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
    </sa-page-layout>
</template>

<script>
import axios from "axios";
import PazienteAnagrafica from "../../components/PazienteAnagrafica.vue";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
// import PazienteDashboardComponent from "../../dashboard/components/PazienteDashboardComponent.vue";
export default {
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    components: { PazienteAnagrafica, SaPageLayout }, //PazienteDashboardComponent
    mounted() {
        let me = this;
        me.loadData();
    },
    data() {
        return {
            linkedit: "/paziente/hassistokits",
            pathResource: "/hassistokits/patient",
            pathResourceKit: "/hassistokits/dissociatekit",
            fields: [
                {
                    label: "",
                    key: "index",
                    thStyle: "width: 1rem",
                    tdClass: "text-center",
                },
                {
                    label: "Kit",
                    key: "kitId",
                },
                {
                    label: "Hub Name",
                    key: "hubName",
                    thStyle: "width: 8rem",
                },
                {
                    label: "",
                    key: "show_details",
                    thStyle: "width: 20rem",
                    tdClass: "text-center",
                },
                {
                    label: "",
                    key: "delete",
                    thStyle: "width: 2rem",
                    tdClass: "text-center",
                },
            ],
            items: [],
            perPage: 10,
            currentPage: 1,
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        rows() {
            return this.items.length;
        },
        linkPatient: {
            get: function () {
                if (this.utente.gruppo) {
                    if (this.utente.gruppo.menuGruppo) {
                        if (this.utente.gruppo.menuGruppo.length == 1) {
                            if (this.utente.gruppo.menuGruppo[0].primaryKey === "homestudiomedico") {
                                return "/paziente/view/" + this.idPaziente;
                            } else if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                                return "view/" + this.idPaziente;
                            } else {
                                return "homepaziente";
                            }
                        } else {
                            return "";
                        }
                    } else {
                        return "";
                    }
                } else {
                    return "";
                }
            },
        },
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "?idPaziente=";
            axios.get(link + me.idPaziente).then((response) => {
                me.items = response.data.data;
                // console.log(me.items);
            });
        },
        onDeleteItem(item) {
            let me = this;
            this.$bvModal
                .msgBoxConfirm("Sei sicuro di procedere alla disassociazione del Kit?", {
                    title: "Conferma disassociazione",
                    buttonSize: "sm",
                    okVariant: "secondary",
                    cancelVariant: "danger",
                    okTitle: "SI",
                    cancelTitle: "NO",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                })
                .then((value) => {
                    if (value) {
                        me.deleteItem(item);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        deleteItem(item) {
            let me = this;
            console.log(item);
            let link = process.env.VUE_APP_PATH_API + me.pathResourceKit;
            axios
                .put(link, { kitId: item.kitId })
                .then(() => {
                    me.modalShow = false;
                    me.loadData();
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        onRefresh() {
            let me = this;
            console.log(me.items);
            // if (me.items !== []) {
            me.loadData();
            // } else {
            //     console.log("gia riempito");
            // }
        },
        onAssocia() {
            let me = this;
            me.$router.replace(me.linkedit + "/edit/-1").catch((err) => {
                err;
            });
        },
    },
};
</script>
