<template>
	<b-card class="sa-card" header="Dati Utente" header-tag="header" footer-tag="footer" title="">
		<b-row>
			<b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
				<label class="sa-label-data">Titolo:</label>
				<b-form-input v-model="jsonData.title" placeholder="Titolo"></b-form-input>
			</b-col>
			<b-col xs="12" sm="12" md="4" lg="4" xl="4">
				<label class="sa-label_data"> Query </label>
				<b-form-select v-model="jsonData.idDatasource" :options="dataSourceOptions" :value="null" value-field="id" text-field="datasourceName"></b-form-select>
			</b-col>
			<b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
				<label class="sa-label-data">Descrizione:</label>
				<b-form-textarea v-model="jsonData.descrizione" placeholder="Descrizione" rows="5"></b-form-textarea>
			</b-col>
			<b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
				<label class="sa-label-data">Query per Grafico:</label>
				<b-form-textarea v-model="jsonData.sqlGraphicQuery" placeholder="Descrizione" rows="5"></b-form-textarea>
			</b-col>
			<b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
				<label class="sa-label-data">Query per Dati Analitici:</label>
				<b-form-textarea v-model="jsonData.sqlAnaliticQuery" placeholder="Query per Dati Analitici" rows="5"></b-form-textarea>
			</b-col>
		</b-row>
	</b-card>
</template>

<script>
import axios from "axios";
export default {
	data() {
		return {
			id: null,
			showModalLoading: false,
			pathResource: "/dataanalysisquery",
			pathResourceDataSource: "/dataanalysisdatasources",
			dataSourceOptions: [],
			jsonData: { title: null },
		};
	},
	mounted() {
		let me = this;
		me.id = this.$route.params.id;
		me.loadDataSourceData();
		me.loadData();
	},
	watch: {
		jsonData: {
			handler(value) {
				this.$emit("updateJsonData", value);
			},
			deep: true,
		},
	},
	methods: {
		loadData() {
			let me = this;
			if (me.id === "-1") {
				me.showmolalloading = false;
			} else {
				me.showmolalloading = true;
				let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
				console.log(link);
				axios.get(link + me.id).then((response) => {
					me.jsonData = response.data.data;
					me.showmolalloading = false;
				});
			}
		},
		loadDataSourceData() {
			let me = this;
			let link = process.env.VUE_APP_PATH_API + me.pathResourceDataSource;
			axios.get(link).then((response) => {
				me.dataSourceOptions = response.data.data.list;
				me.dataSourceOptions.unshift({ id: null, datasourceName: "-Selezionare un Datasource-" });
				me.showmolalloading = false;
			});
		},
	},
};
</script>

<style></style>
