<template>
  <div style="height: 100%; overflow: auto">
    <b-card class="sa-card" header="Dettagli Struttura" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Denominazione</label>
          <b-form-input v-model="jsonData.denominazione"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Struttura</label>
          <b-form-select v-model="jsonData.idStruttura" :options="strutturaOptions"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Codice ASL</label>
          <b-form-input v-model="jsonData.codiceAsl"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Codice presidio</label>
          <b-form-input v-model="jsonData.codicePresidio"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Codice distretto di appartenenza</label>
          <b-form-input v-model="jsonData.codiceDistrettoAppartenenza"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Tipologia struttura</label>
          <b-form-select v-model="jsonData.tipologiaStruttura" value-field="codice" text-field="descrizione" value="''" class="mb-2 mr-sm-2 mb-sm-0" :options="tipologiaStrutturaOptions"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Tipologia di assistenza riabilitativa</label>
          <b-form-select v-model="jsonData.tipologiaAssistenzaRiabilitativa" value-field="codice" text-field="descrizione" value="''" class="mb-2 mr-sm-2 mb-sm-0" :options="tipologiaAssistenzaRiabilitativaOptions"></b-form-select>
        </b-col>
        <!-- <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Località</label>
          <b-form-input v-model="jsonData.localita"></b-form-input>
        </b-col> -->
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Provincia</label>
          <b-form-select v-model="jsonData.provincia" value-field="sigla" text-field="provincia" value="''" class="mb-2 mr-sm-2 mb-sm-0" :options="provinceOptions" @change="onChangeProvincia"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Località</label>
          <b-form-select v-model="jsonData.localita" value-field="codiceIstat" text-field="comune" value="''" class="mb-2 mr-sm-2 mb-sm-0" :options="comuniOptions" @change="onChangeComune"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">CAP</label>
          <b-form-input v-model="jsonData.cap"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Indirizzo</label>
          <b-form-input v-model="jsonData.indirizzo"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">URL</label>
          <b-form-input v-model="jsonData.url"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Telefono</label>
          <b-form-input v-model="jsonData.telefono"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">FAX</label>
          <b-form-input v-model="jsonData.fax"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Email</label>
          <b-form-input v-model="jsonData.email"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">Note</label>
          <b-form-textarea v-model="jsonData.note" rows="5"></b-form-textarea>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import axios from "axios";
import "vue2-datepicker/index.css";
import UtilityMixin from "../../../utility/UtilityMixin";
export default {
  mixins: [UtilityMixin],
  data() {
    return {
      pathResource: "/strutturepresidi",
      pathResourceProvince: "/province",
      pathResourceComuniByProvincia: "/comuni/provincia",
      pathResourceComune: "/comuni/istat",
      linkStrutture: "/strutture",
      pathResourceTipologiaStruttura: "/strutturepresiditipologiestruttura",
      pathResourceTipologiaAssistenzaRiabilitativa: "/strutturepresiditipologieassistenzariabilitativa",
      showModalLoading: false,
      id: null,
      linkback: null,
      // linkProvince: "/province",
      //linkComune: "/comuni/provincia",
      // linkComuneByIstat: "/comuni/istat",
      //linkRegimiFiscali: "/regimifiscali",
      // file1: null,
      // strutturaOption: [{ value: null, text: "-Seleziona Struttura-" }],
      //provinciaOption: [{ value: null, text: "-Seleziona Provincia-" }],
      // comuneOption: [{ value: null, text: "-Seleziona Comune-" }],
      // codiciRegimiFiscaliOptions: [
      //   { value: null, text: "-Seleziona Regime Fiscale-" },
      // ],
      jsonData: {
        denominazione: null,
        idStruttura: null,
        telefono: null,
        url: null,
        email: null,
        indirizzo: null,
        note: null,
        provincia: null,
        cap: null,
        fax: null,
        codiceAsl: "",
        codiceDistrettoAppartenenza: "",
        codicePresidio: "",
        tipologiaStruttura: null,
        tipologiaAssistenzaRiabilitativa: null,
        localita: null,
      },
      listaStrutture: [],
      strutturaOptions: [],
      tipologiaStrutturaOptions: [],
      provinceOptions: [],
      comuniOptions: [{ codiceIstat: null, comune: "-Seleziona Comune-" }],
      tipologiaAssistenzaRiabilitativaOptions: [],
      codiceStruttura: null,
    };
  },
  mounted: function () {
    let me = this;
    me.id = this.$route.params.id;
    if (me.id === "-1") {
      me.linkback = "/strutture";
    } else {
      me.linkback = "/struttura/view/" + me.id;
    }
    me.loadData();
    me.loadProvince();
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
  },
  methods: {
    loadData() {
      let me = this;
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        me.showModalLoading = true;
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
        axios.get(link + me.id).then((response) => {
          me.jsonData = response.data.data;
          me.loadComuni(me.jsonData.provincia);
          me.showModalLoading = false;
        });
      }
      me.loadCodStrutture();
      me.loadTipologiaStruttura();
      me.loadTipologiaAssistenzaRiabilitativa();
    },
    loadCodStrutture() {
      let me = this;
      me.strutturaOptions = [];
      let link = process.env.VUE_APP_PATH_API + me.linkStrutture;
      axios.get(link).then((response) => {
        me.listaStrutture = response.data.data;
        me.listaStrutture.forEach((element) => {
          me.strutturaOptions.push({
            value: element.id,
            text: element.denominazione + " (" + element.codiceStruttura + ")",
          });
        });
        me.strutturaOptions.unshift({
          value: null,
          text: "-Seleziona Struttura-",
        });
        me.showModalLoading = false;
      });
    },

    loadTipologiaStruttura() {
      let me = this;
      UtilityMixin.methods.loadDataResources(me.pathResourceTipologiaStruttura, {}, me.resultResponseTipologiaStruttutra, me.resultErrorTipologiaStruttutra);
    },
    resultResponseTipologiaStruttutra(response) {
      let me = this;
      me.tipologiaStrutturaOptions = response.data.data.list;
      me.tipologiaStrutturaOptions.unshift({ codice: null, descrizione: "-Selezio Valore-" });
    },
    resultErrorTipologiaStruttutra(error) {
      UtilityMixin.methods.messaggeBoxError(this, error);
    },
    loadTipologiaAssistenzaRiabilitativa() {
      let me = this;
      UtilityMixin.methods.loadDataResources(me.pathResourceTipologiaAssistenzaRiabilitativa, {}, me.resultResponseTipologiaAssistenzaRiabilitativa, me.resultErrorTipologiaAssistenzaRiabilitativa);
    },
    resultResponseTipologiaAssistenzaRiabilitativa(response) {
      let me = this;
      me.tipologiaAssistenzaRiabilitativaOptions = response.data.data.list;
      me.tipologiaAssistenzaRiabilitativaOptions.unshift({ codice: null, descrizione: "-Selezio Valore-" });
    },
    resultErrorTipologiaAssistenzaRiabilitativa(error) {
      UtilityMixin.methods.messaggeBoxError(this, error);
    },
    onUpdateJsonData(data) {
      let me = this;
      me.jsonData = data;
    },
    loadProvince() {
      let me = this;
      UtilityMixin.methods.loadDataResources(me.pathResourceProvince, {}, me.resulResponseProvince, me.resultErrorProvince);
    },
    resulResponseProvince(response) {
      let me = this;
      me.provinceOptions = response.data.data;
      me.provinceOptions.unshift({ sigla: null, provincia: "-Seleziona Provincia-" });
    },
    resultErrorProvince(error) {
      //  let me = this;
      //UtilityMixin.methods.messaggeBoxError(me, error);
      console.log(error);
    },
    onChangeProvincia(provincia) {
      let me = this;
      me.comuniOptions = [];
      me.jsonData.localita = null;
      me.jsonData.cap = null;
      me.loadComuni(provincia);
    },
    loadComuni(sigla) {
      let me = this;
      let link = me.pathResourceComuniByProvincia + "/" + sigla;
      UtilityMixin.methods.loadDataResources(link, {}, me.resulResponseComuni, me.resultErrorComuni);
    },
    resulResponseComuni(response) {
      let me = this;
      me.comuniOptions = response.data.data;
      me.comuniOptions.unshift({ codiceIstat: null, comune: "-Seleziona Comune-" });
    },
    resultErrorComuni(error) {
      let me = this;
      UtilityMixin.methods.messaggeBoxError(me, error);
    },
    onChangeComune(value) {
      let me = this;
      me.loadCap(value);
    },
    loadCap(codiceIstat) {
      let me = this;
      let link = me.pathResourceComune + "/" + codiceIstat;
      UtilityMixin.methods.loadDataResources(link, {}, me.resultResponseCap, me.resultErrorCap);
    },
    resultResponseCap(response) {
      let me = this;
      me.jsonData.cap = response.data.data.cap;
    },
    resultErrorCap(error) {
      let me = this;
      UtilityMixin.methods.messaggeBoxError(me, error);
    },
  },
};
</script>
