<template>
    <sa-page-layout :btnBackVisible="false" :linkback="linkback" :linkedit="linkedit" :pathResource="pathResource" :showModalLoading="showModalLoading">
        <template slot="toolbar-title">
            <rsa-ospite-anagrafica-component :jsonData="jsonData"></rsa-ospite-anagrafica-component>
        </template>
        <template slot="toolbar">
            <b-button variant="outline-success btn-toolbar sa-margin-left float-sm-left" @click="onClickNuovoAllegato">
                <b-icon icon="file-earmark-plus" aria-hidden="true"></b-icon>
                Nuovo Allegato
            </b-button>
        </template>
        <template slot="table-body">
            <!-- <paziente-allegati-list-component ref="cmpAllegatiList" :idRisorsa="idPaziente"></paziente-allegati-list-component> -->
            <allegato-model-component :idRisorsa="idPaziente" @updateFiles="onUpdateFile()" :jsonData="jsonData"></allegato-model-component>
            <b-modal ref="mdlAllegati" id="mdlAllegati" size="xl" style="height: 100%" @show="onShowModalAllegati" @hidden="onHideModalAllegati">
                <paziente-allegati-edit-component :jsonData="jsonDataAllegato" tipoRisorsa="EVENTO"></paziente-allegati-edit-component>
                <template #modal-footer="{ btnSalva }">
                    <b-button :id="btnSalva" size="sm" variant="success" @click="onSalva">Salva</b-button>
                </template>
            </b-modal>
        </template>
    </sa-page-layout>
</template>

<script>
import axios from "axios";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import RsaOspiteAnagraficaComponent from "../../rsagestioneospiti/components/RsaOspiteAnagraficaComponent.vue";
// import PazienteAllegatiListComponent from "../../../paziente/allegati/components/PazienteAllegatiListComponent.vue";
import PazienteAllegatiEditComponent from "../../../paziente/allegati/components/PazienteAllegatiEditComponent.vue";
import AllegatoModelComponent from "../../../utility/components/AllegatoModelComponent.vue";
export default {
    props: {
        utente: { type: Object },
    },
    components: {
        SaPageLayout,
        RsaOspiteAnagraficaComponent,
        // PazienteAllegatiListComponent,
        PazienteAllegatiEditComponent,
        AllegatoModelComponent,
    },
    data() {
        return {
            idPaziente: null,
            btnNuovaRichiestaEsameHidden: true,
            btnNuovaRichiestaUscita: true,
            filtro: {},
            btnNewVisible: true,
            pathResource: null,
            pathResourceAllegati: "/allegati",
            pathResourceAccettazione: "/accettazioni",
            linkedit: null,
            linkback: "/rsagestioneospiti",
            showModalLoading: false,
            listKey: 0,
            jsonData: { accettazioneAnagrafica: {}, accettazionePrestazioni: {}, accettazioneStati: [], accettazioneStruttura: {} },
            jsonDataAllegato: {},
        };
    },
    mounted() {
        let me = this;
        me.idPaziente = this.$route.query.idPaziente;
        // me.$refs.cmpAllegatiList.setIdRisorsa(me.idPaziente);
        me.loadAccettazione();
    },
    methods: {
        loadAccettazione() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceAccettazione + "/" + me.idPaziente;
            axios
                .get(link)
                .then((response) => {
                    me.jsonData = response.data.data;
                    me.$refs.cmpRsaAree.init(me.jsonData);
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        onSalva() {
            let me = this;
            let obj = me.getDataPdf(me.jsonDataAllegato);
            let link = process.env.VUE_APP_PATH_API + me.pathResourceAllegati;
            me.showModalLoading = true;
            axios
                .post(link, obj)
                .then(() => {
                    this.$refs["mdlAllegati"].hide();
                    me.showModalLoading = false;
                    this.$bvToast.toast(`${this.jsonDataAllegato.nomeFile}`, {
                        title: "File Upload",
                        autoHideDelay: 2000,
                        variant: "success",
                    });
                })
                .catch((error) => {
                    me.showModalLoading = false;
                    this.$bvModal.msgBoxOk(error.response.data.messaggio, {
                        title: "ATTENZIONE",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    });
                });
        },

        getDataPdf(obj) {
            let objPdf = {};
            objPdf.idRisorsa = obj.idRisorsa;
            objPdf.idAnagrafica = obj.idPaziente;
            objPdf.nomeFile = obj.nomeFile;
            objPdf.size = obj.size;
            objPdf.file = obj.file;
            objPdf.tipo = obj.tipo;
            objPdf.dataCaricamento = obj.dataCaricamento;
            objPdf.descrizione = obj.descrizione;
            objPdf.tipoDocumento = obj.tipoDocumento;
            return objPdf;
        },
        onShowModalAllegati() {
            let me = this;
            // console.log("ok");
            me.jsonDataAllegato = { idRisorsa: me.idPaziente, file: null, size: null, tipo: null, dataCaricamento: null, descrizione: null, tipoDocumento: null };
        },
        onHideModalAllegati() {
            let me = this;
            // console.log("ok");
            me.jsonDataAllegato = { idRisorsa: me.idPaziente, file: null, size: null, tipo: null, dataCaricamento: null, descrizione: null, tipoDocumento: null };
        },
        onClickNuovoAllegato() {
            let me = this;
            // console.log(me.idPaziente);

            me.jsonDataAllegato = { idRisorsa: me.idPaziente, file: null, size: null, tipo: null, dataCaricamento: null, descrizione: null, tipoDocumento: null };
            me.$refs.mdlAllegati.show();
        },
    },
};
</script>

<style></style>
