<template>
  <div class="sa-free-page">
    <div class="sa-free-page-header">
      <b-card class="sa-card" header="Dettagli Piano" header-tag="header" footer-tag="footer" title="">
        <template #header>
          <div style="display: flex">
            <span style="flex: 1">Dettagli Piano</span>
            <b-button size="sm" variant="outline-secondary outline-secondary-noborder pull-right" @click="onClickPianoEdit()">
              <i class="bi bi-pencil"></i>
            </b-button>
          </div>
        </template>
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="4" lg="3" xl="4">
            <label class="sa-label-data">Struttura</label>
            <span class="sa-data">{{ jsonData.denominazioneStruttura }}</span>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="4" lg="3" xl="4">
            <label class="sa-label-data">Presidio</label>
            <span class="sa-data">{{ jsonData.denominazionePresidio }}</span>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="4" lg="3" xl="4">
            <label class="sa-label-data">Nome</label>
            <span class="sa-data">{{ jsonData.nome }}</span>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <label class="sa-label-data">Note</label>
            <span class="sa-data">{{ jsonData.note }}</span>
          </b-col>
        </b-row>
      </b-card>
    </div>
    <div class="sa-free-page-body">
      <b-card class="sa-card" header="Stanze" header-tag="header" footer-tag="footer" title="">
        <template #header>
          <div style="display: flex">
            <span style="flex: 1">Stanze</span>
            <!--    <b-button size="sm" variant="outline-secondary outline-secondary-noborder pull-right" @click="onClickStanzeAdd()">
                            <i class="bi bi-plus"></i>
                        </b-button> -->
          </div>
        </template>
        <ras-configurazione-posti-letto-stanze-list-component ref="cmpRasConfigurazionePostiLettoStanzeEditComponent" :idPiano="idPiano" :key="keyPiano"></ras-configurazione-posti-letto-stanze-list-component>
      </b-card>
    </div>
    <!-- <div class="sa-free-page-footer"></div> -->
    <b-modal ref="mdlPianoEdit" size="lg" title="Modifica Dati Piano" @ok="onMdlPianoEditSave()">
      <ras-configurazione-posti-letto-piani-edit-component ref="cmpRasConfigurazionePostiLettoPianiEditComponent" :showModalLoading="showModalLoading"></ras-configurazione-posti-letto-piani-edit-component>
      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="danger" @click="cancel()">Annulla</b-button>
        <b-button size="sm" variant="success" @click="ok()">Salva</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import UtilityMixin from "../../../utility/UtilityMixin";
import RasConfigurazionePostiLettoPianiEditComponent from "../components/RasConfigurazionePostiLettoPianiEditComponent.vue";
import RasConfigurazionePostiLettoStanzeListComponent from "../components/RasConfigurazionePostiLettoStanzeListComponent.vue";
export default {
  mixins: [UtilityMixin],
  components: { RasConfigurazionePostiLettoPianiEditComponent, RasConfigurazionePostiLettoStanzeListComponent },
  data() {
    return {
      id: null,
      strutturaOptions: [],
      pathResource: "/rsapostilettopiani",
      linkStrutture: "/strutture",
      jsonData: { id: null, codiceStruttura: null, idPresidio: null, nome: null, note: null },
      showModalLoading: false,
      idPiano: null,
      keyPiano: 100,
    };
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
  },
  mounted: function () {
    let me = this;
    me.id = this.$route.params.id;
    me.loadStrutture();
  },
  methods: {
    loadStrutture() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkStrutture;
      axios.get(link).then((response) => {
        me.strutturaOptions = response.data.data;
        me.strutturaOptions.unshift({ codiceStruttura: null, denominazione: this.$i18n.t("clinic.lblSelectStructure") });
        me.loadData();
      });
    },
    loadData() {
      let me = this;
      if (me.id === "-1") {
        this.$emit("afterLoadData");
      } else {
        // me.showModalLoading = true;
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
        axios.get(link + me.id).then((response) => {
          me.jsonData = response.data.data;
          me.idPiano = me.jsonData.id;
          me.keyPiano++;
          // me.$refs.RasConfigurazionePostiLettoStanzeListComponent.loadData();
          this.$emit("afterLoadData");
        });
      }
    },
    onClickPianoEdit() {
      let me = this;
      me.$refs.mdlPianoEdit.show();
    },
    onMdlPianoEditSave() {
      let me = this;
      let jsonData = me.$refs.cmpRasConfigurazionePostiLettoPianiEditComponent.getJsonData();
      UtilityMixin.methods.saveResource(me.pathResource, jsonData, me.saveSucessResponse, me.saveErrorResponse);
    },
    saveSucessResponse() {
      let me = this;
      me.loadData();
    },
    saveErrorResponse(response) {
      if (response.response.status === 400) {
        this.$emit("errorRestCall", response);
      } else {
        this.$bvToast.toast("Errore nel salvarei dati del Piano: " + response.message, {
          title: "",
          variant: "danger",
          solid: true,
        });
      }
    },
    onClickStanzeAdd() {
      let me = this;
      console.log(me.keyPiano);
      me.keyPiano += 1;
      me.$refs.cmpRasConfigurazionePostiLettoStanzeEditComponent.mdlPianoEdit.show();
      // me.$refs.cmpRasConfigurazionePostiLettoStanzeEditComponent.openModal();
    },
  },
};
</script>

<style></style>
