<template>
	<b-modal ref="mdlViewData" id="mdl-view-data" size="lg" title="Dati" scrollable centered ok-only ok-title="Chiudi">
		<b-row> </b-row>
		<div class="b-table-sticky-header">
			<b-table sticky-header stacked="lg" striped hover :items="modalTableItms" sort-icon-left head-variant="light" class="sa-b-table" foot-clone> </b-table>
		</div>
	</b-modal>
</template>

<script>
export default {
	data() {
		return {
			modalTableItms: [],
		};
	},
	methods: {
		show() {
			this.$refs.mdlViewData.show();
		},
		setData(data) {
			this.modalTableItms = data;
		},
	},
};
</script>

<style></style>
