import Users from "../";

const routes = [
    { name: "GruppiList", path: "/gruppi", component: Users.GruppiList },
    { name: "GruppoEdit", path: "/gruppi/edit/:id", component: Users.GruppoEdit },
    { name: "GruppoView", path: "/gruppi/view/:id", component: Users.GruppoView, meta: { label: "Dettaglio Gruppo" } },
    { name: "UsersEdit", path: "/users/edit/:id", component: Users.UserEdit },
    { name: "UsersView", path: "/users/view/:id", component: Users.UserEdit },
    { name: "Users", path: "/users", component: Users.UserList },
    { name: "SessionList", path: "/session", component: Users.SessionList, meta: { title: "session.lblTitle" } },
];

export default routes;
