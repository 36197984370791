<template>
    <sa-page-layout :btnSaveVisible="true" :btnSaveNotBackVisible="true" :linkNotBack="linkNotBack" @aftersave="onAfterSave" :btnAnnullaVisible="true" :linkback="linkback" :data="jsonData" :showModalLoading="showModalLoading" :pathResource="pathResource">
        <template slot="toolbar">
            <b-button size="sm" variant="success btn-toolbar sa-margin-right float-sm-right" :hidden="btnNuovaPersonaRiferimento" @click="onClickNuovaPersonaRiferimento">
                <b-icon icon="plus"></b-icon>
                Nuova Persona Riferimento
            </b-button>
            <b-button size="sm" variant="success btn-toolbar sa-margin-right float-sm-right" :hidden="btnNuovaRicetta" @click="onClickNuovaRicetta">
                <b-icon icon="plus"></b-icon>
                Nuova Ricetta
            </b-button>
            <b-button size="sm" variant="success btn-toolbar sa-margin-right float-sm-right" :hidden="btnDatiFatturazione" @click="onClickNuoviDatiFatturazione">
                <b-icon icon="plus"></b-icon>
                Nuovi dati Fatturazione
            </b-button>
            <b-button size="sm" variant="success btn-toolbar sa-margin-right float-sm-right" :hidden="btnNuovaImpegnativa" @click="onClickNuovaImpegnativa">
                <b-icon icon="plus"></b-icon>
                Nuova Impegnativa
            </b-button>
            <b-button size="sm" variant="success btn-toolbar sa-margin-right float-sm-right" :hidden="btnNuovoAllegatoA" @click="onClickNuovoAllegatoA">
                <b-icon icon="plus"></b-icon>
                Nuovo Allegato A
            </b-button>
            <!-- <b-button size="sm" variant="success btn-toolbar sa-margin-right float-sm-right" :hidden="btnSpesaExtra" @click="onClickSpesaExtra"> Nuova Spesa </b-button> -->
        </template>
        <template slot="table-body">
            <rsa-accettazione-edit-component :id="id" ref="cmpRsaAccettazioneEditComponent" :showModalLoading="showModalLoading" @update="onUpdateJsonData" @beforeLoadData="onBeforeLoadData" @afterLoadData="onAfterLoadData" @onActiveTab="onActiveTab" :key="componentKey"></rsa-accettazione-edit-component>
        </template>
    </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import RsaAccettazioneEditComponent from "../components/RsaAccettazioneEditComponent.vue";

export default {
    components: { SaPageLayout, RsaAccettazioneEditComponent },
    data() {
        return {
            showModalLoading: false,
            pathResource: "/rsaaccettazioni",
            linkback: "/rsaaccettazione",
            linkNotBack: null,
            jsonData: {},
            newLink: null,
            componentKey: 0,
            btnNuovaPersonaRiferimento: true,
            btnNuovaRicetta: true,
            btnDatiFatturazione: true,
            btnNuovaImpegnativa: true,
            btnNuovoAllegatoA: true,
            btnSpesaExtra: true,
            id: null,
        };
    },
    created() {
        let me = this;
        me.id = this.$route.params.id;
    },

    // watch: {
    //     id: {
    //         handler(newId) {
    //             if (newId !== "-1") {
    //                 let me = this;
    //                 console.log("VERO");

    //                 me.loadData();
    //             } else {
    //                 console.log("CIAO");
    //             }
    //         },
    //         //  immediate: false,
    //     },
    // },

    // computed: {
    //   id() {
    //     let me = this;

    //     return (me.id = me.$route.params.id);
    //   },
    // },
    mounted() {
        let me = this;
        /* me.id = this.$route.params.id; */
        let path = this.$route.path.replace(/\/edit\/.*/, "");
        me.linkNotBack = path + "{editid}?";
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            me.$refs.cmpRsaAccettazioneEditComponent.loadData();
        },
        onUpdateJsonData(data) {
            let me = this;
            let currentId = me.jsonData.id || me.id || data.id;

            me.jsonData = {
                ...data,
                id: currentId,
            };
            /* console.log(this.jsonData); */
            /* 
             console.log("jsonData aggiornato con ID:", currentId); */
        },
        onBeforeLoadData() {
            let me = this;
            me.showModalLoading = true;
        },
        onAfterLoadData() {
            let me = this;
            // me.id = data.id;
            me.showModalLoading = false;
        },

        onAfterSave(data) {
            let me = this;
            me.loadData();
            me.showModalLoading = false;
            me.id = data.id;
            me.jsonData = {
                ...me.jsonData,
                id: data.id,
            };

            /* console.log("jsonData aggiornato:", me.jsonData, "con id:", me.jsonData.id); */

            // let basePath = me.$route.path.split("/edit/")[0];
            // let newLink = `${basePath}/edit/${data.id}`;
            // /* me.linkNotBack = newLink; */
            // //console.log(newLink);
            // if (me.$route.params.id === "-1") {
            //     console.log(newLink);
            //     me.componentKey += 1;
            //     me.linkNotBack = newLink;
            //     /* me.$nextTick(() => {
            //     me.loadData();
            //     }); */
            // }
            // console.log("222222222222222222");
        },
        onActiveTab(idActiveTab) {
            let me = this;
            me.setCommand(idActiveTab);
        },
        setCommand(idActiveTab) {
            let me = this;
            me.btnNuovaPersonaRiferimento = true;
            me.btnNuovaRicetta = true;
            me.btnDatiFatturazione = true;
            me.btnNuovaImpegnativa = true;
            me.btnNuovoAllegatoA = true;
            me.btnSpesaExtra = true;
            switch (idActiveTab) {
                case "tbRsaPersoneRiferimento":
                    me.btnNuovaPersonaRiferimento = false;
                    me.$refs.cmpRsaAccettazioneEditComponent.loadDataPersoneRiferimento();
                    break;
                case "tbValutazioneIngresso":
                    me.$refs.cmpRsaAccettazioneEditComponent.loadDataSchedeValutazione();
                    /* console.log("TAB valutazione"); */
                    break;
                case "tbRsaRicette":
                    /* console.log("TAB Ricette"); */
                    me.btnNuovaRicetta = false;
                    me.$refs.cmpRsaAccettazioneEditComponent.loadDataRicette();
                    break;
                case "tbDatiFatturazione":
                    // console.log("TAB Fatturazione");
                    me.btnDatiFatturazione = false;
                    // me.$refs.cmpRsaAccettazioneEditComponent.setDatiFatturazione(me.jsonData);
                    break;
                case "tbRsaImpegnativa":
                    me.btnNuovaImpegnativa = false;
                    // me.$refs.cmpRsaAccettazioneEditComponent.setDataImpegnativa();
                    break;
                case "tbSchedaValutazioneAllegatoA":
                    me.btnNuovoAllegatoA = false;
                    break;
                case "tbSchedeValutazione":
                    me.$refs.cmpRsaAccettazioneEditComponent.loadDataSchedeValutazione();
                    /* console.log("TAB valutazione"); */
                    break;
            }
        },
        onClickNuovaPersonaRiferimento() {
            let me = this;
            me.$refs.cmpRsaAccettazioneEditComponent.onNuovaPersonaRiferimento();
        },
        onClickNuovaRicetta() {
            let me = this;
            me.$refs.cmpRsaAccettazioneEditComponent.onNuovaRicetta();
        },
        onClickNuoviDatiFatturazione() {
            let me = this;
            me.$refs.cmpRsaAccettazioneEditComponent.onNuoviDatiFatturazione();
        },
        onClickNuovaImpegnativa() {
            let me = this;
            me.$refs.cmpRsaAccettazioneEditComponent.onNuovaImpegnativa();
        },
        onClickNuovoAllegatoA() {
            let me = this;
            me.$refs.cmpRsaAccettazioneEditComponent.onNuovoAllegatoA();
        },
    },
};
</script>
<style></style>
