<template>
    <div style="height: 100%; overflow: auto">
        <div class="sa-flexbox" style="padding-bottom: 20px">
            <div class="sa-flexbox-body">
                <div class="b-table-sticky-header">
                    <span class="sa-flexbox-body-title">Persone di Riferimento</span>
                    <!--secondary btn-toolbar sa-margin-right float-sm-right-->
                    <b-button size="sm" variant="outline-success outline-primary-noborder sa-margin-right float-sm-right" :hidden="btnNuovaPersonaRiferimentoHidden" @click="onNuovaPersonaRiferimento" class="mb-2">
                        <i class="fas fa-plus"></i>
                        Aggiungi
                    </b-button>
                    <b-table sticky-header ref="table" stacked="lg" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
                        <template v-slot:cell(nominativo)="{ item }">
                            <span class="sa-table-span-label-primary">{{ item.nome }} {{ item.cognome }}</span>
                            <span class="sa-table-span-label-secondary">{{ item.identificativo }}</span>
                        </template>
                        <template v-slot:cell(cellulare)="{ item }">
                            <span v-if="item.cellulare">{{ item.cellulare }}</span> <span v-else>{{ item.telefonoFisso }}</span>
                        </template>

                        <template v-slot:cell(actions)="{ item }">
                            <b-container class="bv-example-row">
                                <b-button size="sm" v-b-tooltip.hover title="Modifica Richiesta" variant="outline-success" @click="onEditPersonaRiferimento(item)">
                                    <font-awesome-icon icon="edit"></font-awesome-icon>
                                </b-button>
                                <b-button size="sm" v-b-tooltip.hover title="Cancella Richiesta" variant="outline-danger" @click="onDeletePersonaRiferimento(item)">
                                    <font-awesome-icon icon="trash"></font-awesome-icon>
                                </b-button>
                            </b-container>
                        </template>
                    </b-table>
                    <!--   </div> -->
                </div>

                <b-modal title="Nuova Persona Di Riferimento" ref="mdlNuovaPersonaRiferimento" id="mdlNuovaPersonaRiferimento" size="lg" centered no-close-on-backdrop no-close-on-esc hide-header-close>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Nome</label>
                            <b-form-input v-model="personaRiferimento.nome"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Cognome</label>
                            <b-form-input v-model="personaRiferimento.cognome"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Tipo Identificativo</label>
                            <b-form-select id="inline-form-custom-select-pref" v-model="personaRiferimento.tipoIdentificativo" class="mb-2 mr-sm-2 mb-sm-0" :options="tipiIdentificativoOptions" :value="''" value-field="codice" text-field="descrizione" required></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Identificativo</label>
                            <b-form-input v-model="personaRiferimento.identificativo"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Grado di relazione</label>
                            <b-form-select id="inline-form-custom-select-pref" v-model="personaRiferimento.gradoRelazione" class="mb-2 mr-sm-2 mb-sm-0" :options="gradiRelazioneOptions" :value="''" value-field="value" text-field="text" required></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Cellulare</label>
                            <b-form-input v-model="personaRiferimento.cellulare"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Telefono Fisso</label>
                            <b-form-input v-model="personaRiferimento.telefonoFisso"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Email</label>
                            <b-form-input v-model="personaRiferimento.email"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Provincia</label>
                            <b-form-select id="inline-form-custom-select-pref" v-model="personaRiferimento.provincia" class="mb-2 mr-sm-2 mb-sm-0" :options="provinceOptions" :value="''" value-field="sigla" text-field="provincia" @input="onInputProvincia" required></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Comune</label>
                            <b-form-select id="inline-form-custom-select-pref" v-model="personaRiferimento.comuneCodiceIstat" class="mb-2 mr-sm-2 mb-sm-0" :options="comuniOptions" :value="''" value-field="codiceIstat" text-field="comune" @input="onInputComune" required></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Cap</label>
                            <b-form-input v-model="personaRiferimento.cap"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Indirizzo</label>
                            <b-form-input v-model="personaRiferimento.indirizzo"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Coobbligato in solido</label>
                            <b-form-select id="inline-form-custom-select-pref" v-model="personaRiferimento.coobbligatoSolido" class="mb-2 mr-sm-2 mb-sm-0" :options="coobbligatoSolidoOptions" :value="''" value-field="value" text-field="text" required></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Caregiver</label>
                            <b-form-select id="inline-form-custom-select-pref" v-model="personaRiferimento.caregiver" class="mb-2 mr-sm-2 mb-sm-0" :options="caregiverOptions" :value="''" value-field="value" text-field="text" required></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Case-manager</label>
                            <b-form-select id="inline-form-custom-select-pref" v-model="personaRiferimento.caseManager" class="mb-2 mr-sm-2 mb-sm-0" :options="caseManagerOptions" :value="''" value-field="value" text-field="text" required></b-form-select>
                        </b-col>
                    </b-row>
                    <template #modal-footer>
                        <b-button size="md" class="no-text" variant="outline-danger" @click="onClickUndo">Annulla</b-button>
                        <b-button @click="onSalvaPersonaRiferimento" variant="outline-success">Salva</b-button>
                    </template>
                </b-modal>
            </div>
            <div class="sa-flexbox-footer" style="background-color: ciano">
                <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col lg="3">
                        <p>Record Totali: {{ rows }}</p>
                    </b-col>
                    <b-col lg="9">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                    </b-col>
                </b-row>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
export default {
    props: {
        jsonData: { type: Object },
    },
    components: {},
    data() {
        return {
            pathResource: "/rsapersoneriferimento",
            btnNuovaPersonaRiferimentoHidden: false,
            pathResourceProvince: "/province",
            pathResourceComuni: "/comuni/provincia",
            pathResourceComune: "/comuni/istat",
            pathResourceGradiRelazione: "/rsapersoneriferimentogradirelazione",
            pathResourceIdentificativo: "/anagraficaidentificativo",
            id: "",
            rows: 0,
            listKey: 0,
            currentPage: 1,
            perPage: 50,
            items: [],
            personaRiferimento: {
                nome: "",
                cognome: "",
                tipoIdentificativo: "",
                identificativo: "",
                cellulare: "",
                telefonoFisso: "",
                email: "",
                provincia: null,
                comuneCodiceIstat: null,
                indirizzo: "",
                cap: "",
                coobbligatoSolido: "",
                caregiver: "",
                gradoRelazione: null,
                caseManager: "",
            },
            filtroPersonaRiferimento: {
                /* idAccettazione: "" */
            },
            coobbligatoSolidoOptions: [
                { text: "-SI/NO-", value: "" },
                { text: "SI", value: "SI" },
                { text: "NO", value: "NO" },
            ],
            caregiverOptions: [
                { text: "-SI/NO-", value: "" },
                { text: "SI", value: "SI" },
                { text: "NO", value: "NO" },
            ],
            caseManagerOptions: [
                { text: "-SI/NO-", value: "" },
                { text: "SI", value: "SI" },
                { text: "NO", value: "NO" },
            ],
            provinceOptions: [],
            comuniOptions: [{ codiceIstat: null, comune: "-Seleziona Comune-" }],
            gradiRelazioneOptions: [{ descrizione: "-Selezionare Grado di Relazione-" }],
            tipiIdentificativoOptions: [],
            fields: [
                { label: "", key: "index" },
                {
                    label: "Nominativo",
                    key: "nominativo",
                },
                {
                    label: "Recapito Telefonico",
                    key: "cellulare",
                },
                {
                    label: "Email",
                    key: "email",
                },
                {
                    label: "Grado di relazione",
                    key: "gradoRelazione",
                },
                {
                    label: "",
                    key: "actions",
                    class: "sa-table-column-action-double",
                },
            ],
        };
    },
    mounted() {},
    methods: {
        /* updateListaFamiliari() {
      let me = this;

      me.$emit("updateListaFamiliari", me.listaFamiliari);
    }, */
        loadData(idAccettazione) {
            let me = this;
            me.filtroPersonaRiferimento = {};
            if (!idAccettazione) {
                me.filtroPersonaRiferimento.idAccettazione = me.jsonData.id;
            } else {
                me.filtroPersonaRiferimento.idAccettazione = idAccettazione;
            }

            //  if (me.jsonData.id !== null) {
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            axios
                .get(link, { params: me.filtroPersonaRiferimento })
                .then((response) => {
                    me.items = response.data.data.list;
                    me.rows = response.data.data.recordsNumber;
                    me.listKey++;
                })
                .catch((error) => {
                    console.log("Il servizio delle Persone di Riferimento ha dato errore: " + error.response.status);
                });
            //   }
        },
        onNuovaPersonaRiferimento() {
            let me = this;
            me.openEditModal("-1");
        },
        onEditPersonaRiferimento(item) {
            let me = this;
            // console.log(item);
            me.openEditModal(item.id);
        },
        openEditModal(id) {
            console.log(id);

            let me = this;
            me.personaRiferimento = {
                nome: "",
                cognome: "",
                tipoIdentificativo: "",
                identificativo: "",
                cellulare: "",
                telefonoFisso: "",
                email: "",
                provincia: null,
                comuneCodiceIstat: null,
                indirizzo: "",
                cap: "",
                coobbligatoSolido: "",
                caregiver: "",
                gradoRelazione: null,
                caseManager: "",
            };
            if (id !== "-1") {
                me.loadPersonaRiferimento(id);
            }
            me.loadProvince();
            me.loadGradiRelazione();
            me.loadIdentificativi();
            me.$refs.mdlNuovaPersonaRiferimento.show();
        },
        loadPersonaRiferimento(id) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + id;
            axios
                .get(link)
                .then((response) => {
                    me.personaRiferimento = response.data.data;
                })
                .catch((error) => {
                    console.log("Il servizio delle Persone di Riferimento ha dato errore: " + error.response.status);
                });
        },
        loadProvince() {
            let me = this;
            me.provinceOptions = [];
            let link = process.env.VUE_APP_PATH_API + me.pathResourceProvince;
            axios
                .get(link)
                .then((response) => {
                    me.provinceOptions = response.data.data;
                    me.provinceOptions.unshift({ sigla: null, provincia: "-Seleziona Provincia-" });
                })
                .catch((error) => {
                    console.log("Il servizio delle province ha datao errore: " + error.response.status);
                });
        },
        loadComuni(sigla) {
            let me = this;
            //  me.comuneOptsions = [];
            let link = process.env.VUE_APP_PATH_API + me.pathResourceComuni + "/" + sigla;
            axios
                .get(link)
                .then((response) => {
                    me.comuniOptions = response.data.data;
                    me.comuniOptions.unshift({ codiceIstat: null, comune: "-Seleziona Comune-" });
                })
                .catch((error) => {
                    console.log("Il servizio delle province ha datao errore: " + error.response.status);
                });
        },
        onInputProvincia(provincia) {
            let me = this;
            me.comuniOptions = [];
            me.loadComuni(provincia);
        },
        loadGradiRelazione() {
            let me = this;
            me.gradiRelazioneOptions = [];
            let link = process.env.VUE_APP_PATH_API + me.pathResourceGradiRelazione;
            axios
                .get(link)
                .then((response) => {
                    // me.gradiRelazioneOptions = response.data.data.list;
                    // me.gradiRelazioneOptions.unshift({ descrizione: "-Seleziona Grado di relazione-" });
                    response.data.data.list.forEach((element) => {
                        me.gradiRelazioneOptions.push({ value: element.descrizione, text: element.descrizione });
                    });
                    me.gradiRelazioneOptions.unshift({ value: null, text: "-Seleziona Grado di relazione-" });
                })
                .catch((error) => {
                    console.log("Il servizio dei gradi di relazione ha dato errore: " + error.response.status);
                });
        },
        loadIdentificativi() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceIdentificativo;
            axios
                .get(link)
                .then((response) => {
                    me.tipiIdentificativoOptions = response.data.data;
                    me.tipiIdentificativoOptions.unshift({ codice: "", descrizione: "-Seleziona Tipo Identificativo-" });
                })
                .catch((error) => {
                    console.log("Il servizio dei tipi identificativo ha datao errore: " + error.response.statut);
                });
        },
        onSalvaPersonaRiferimento() {
            let me = this;
            console.log(me.personaRiferimento.id);

            if (me.personaRiferimento.id == null) {
                me.createRequest();
            } else {
                me.updateRequest();
            }
        },
        createRequest() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.personaRiferimento.idAccettazione = me.jsonData.id;
            me.personaRiferimento.idPaziente = me.jsonData.accettazioneAnagrafica.id;
            axios
                .post(link, JSON.stringify(me.personaRiferimento))
                .then(() => {
                    me.$emit("afterSaveRequest");
                    me.$refs.mdlNuovaPersonaRiferimento.hide();
                    me.loadData();
                })
                .catch((error) => {
                    me.$emit("afterSaveRequest");
                    let messaggio = error.response ? error.response.data.messaggio : "Errore non gestito";
                    me.$bvModal
                        .msgBoxOk(messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-secondary",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then(() => {})
                        .catch((err) => {
                            console.log(err);
                        });
                });
        },
        updateRequest() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.personaRiferimento.id;
            me.$emit("beforeSaveRequest");
            axios
                .put(link, JSON.stringify(me.personaRiferimento))
                .then(() => {
                    me.filtro = {};
                    me.$emit("afterSaveRequest");
                    me.$refs.mdlNuovaPersonaRiferimento.hide();
                    me.loadData();
                })
                .catch((error) => {
                    me.$emit("afterSaveRequest");
                    let messaggio = error.response ? error.response.data.messaggio : "Errore non gestito";
                    me.$bvModal
                        .msgBoxOk(messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then(() => {})
                        .catch((err) => {
                            console.log(err);
                        });
                });
        },
        onClickUndo() {
            let me = this;
            me.$refs.mdlNuovaPersonaRiferimento.hide();
        },
        onDeletePersonaRiferimento(item) {
            let me = this;
            this.$bvModal
                .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
                    title: "Titolo",
                    buttonSize: "sm",
                    okVariant: "secondary",
                    cancelVariant: "danger",
                    okTitle: "SI",
                    cancelTitle: "NO",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                })
                .then((value) => {
                    if (value) {
                        me.deletePersonaRiferimento(item.id);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        deletePersonaRiferimento(id) {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + id;
            axios
                .delete(link)
                .then((Response) => {
                    console.log(Response);
                    me.showModalLoading = false;
                    me.loadData();
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        onInputComune(value) {
            let me = this;
            me.loadCap(value);
        },
        loadCap(codiceIstat) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceComune + "/" + codiceIstat;
            if (codiceIstat) {
                axios
                    .get(link)
                    .then((response) => {
                        me.personaRiferimento.cap = response.data.data.cap;
                    })
                    .catch((error) => {
                        console.log("Il servizio delle regioni ha datao errore: " + error.response.status);
                    });
            }
        },
    },
};
</script>
