<template>
    <sa-page-layout ref="SaplAreeView" :btnCloseVisible="true" :linkback="linkback" :data="jsonData" :showModalLoading="showModalLoading" :pathResource="pathResource" class="sa-no-space">
        <template slot="table-body">
            <rsa-configurazione-aree-view-component :showModalLoading="showModalLoading" @errorRestCall="onError" @update="onUpdateJsonData" @beforeLoadData="onBeforeLoadData" @afterLoadData="onAfterLoadData"></rsa-configurazione-aree-view-component>
        </template>
    </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import RsaConfigurazioneAreeViewComponent from "../components/RsaConfigurazioneAreeViewComponent.vue";
export default {
    components: { SaPageLayout, RsaConfigurazioneAreeViewComponent },
    data() {
        return {
            showModalLoading: false,
            pathResource: "/rsaaree",
            linkback: "/configurazioni/rsaconfigurazionearee",
            jsonData: {},
        };
    },
    methods: {
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
        },
        onBeforeLoadData() {},
        onAfterLoadData() {},
        onError(errorData) {
            let me = this;
            me.$refs.SaplAreeView.showModalError(errorData);
        },
    },
};
</script>

<style></style>
