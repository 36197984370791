<template>
	<installation-component></installation-component>
</template>

<script>
import InstallationComponent from "../components/InstallationComponent.vue";
export default {
	components: { InstallationComponent },
};
</script>

<style></style>
