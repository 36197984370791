<template>
    <!--   <div class="sa-tab-scroll"> -->
    <!-- <b-tabs id="tabsRsaAccettazioneEdit" ref="tabsRsaAccettazioneEdit" v-model="activeTabIndex" nav-wrapper-class="sa-tab-items" content-class="mt-3" class="sa-tab" style="height: 100%; padding-bottom: 25px" fill @input="onInputTabs">
        <b-tab title="Anagrafica" id="tbAnagrafica"> -->
    <div class="sa-tab-scroll">
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card" header="Struttura - Presidio" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Struttura</label>
                            <span>{{ jsonData.accettazioneStruttura.denominazione }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Presidi</label>
                            <span>{{ presidio }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Regime</label>
                            <span>{{ jsonData.regime }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Setting</label>
                            <span>{{ jsonData.settingDescrizione }}</span>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card" header="Anagrafica" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label htmlFor="rsaGestioneOspitiEditComponentAccettazioneAnagraficaIdentificativo" class="sa-label-data">Identificativo</label>
                            <span>{{ jsonData.accettazioneAnagrafica.identificativo }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Cognome</label>
                            <span>{{ jsonData.accettazioneAnagrafica.cognome }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Nome</label>
                            <span>{{ jsonData.accettazioneAnagrafica.nome }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Data di nascita</label>
                            <span>{{ formatDate(jsonData.accettazioneAnagrafica.dataNascita) }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Provincia di nascita</label>
                            <span>{{ jsonData.accettazioneAnagrafica.provinciaNascita }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Comune di nascita</label>
                            <span>{{ comuneNascita }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Sesso</label>
                            <span>{{ jsonData.accettazioneAnagrafica.sesso }}</span>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <b-card class="sa-card" header="Residenza" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Provincia</label>
                            <span>{{ jsonData.accettazioneAnagrafica.provinciaResidenza }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Comune</label>
                            <span>{{ comuneResidenza }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">CAP</label>
                            <span>{{ jsonData.accettazioneAnagrafica.capResidenza }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Indirizzo</label>
                            <span>{{ jsonData.accettazioneAnagrafica.indirizzoResidenza }}</span>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <b-card class="sa-card" header="Domicilio" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Provincia</label>
                            <span>{{ jsonData.accettazioneAnagrafica.provinciaDomicilio }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Comune</label>
                            <span>{{ comuneDomicilio }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">CAP</label>
                            <span>{{ jsonData.accettazioneAnagrafica.capDomicilio }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Indirizzo</label>
                            <span>{{ jsonData.accettazioneAnagrafica.indirizzoDomicilio }}</span>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <b-card class="sa-card" header="ASL di residenza" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Codice A.S.L.</label>
                            <span>{{ jsonData.accettazioniAsl.aslResidenzaCodice }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">A.S.L.</label>
                            <span>{{ jsonData.accettazioniAsl.aslResidenzaDenominazione }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Ambito</label>
                            <span>{{ jsonData.accettazioniAsl.aslResidenzaAmbito }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Distretto</label>
                            <span>{{ jsonData.accettazioniAsl.aslResidenzaDistretto }}</span>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <b-card class="sa-card" header="ASL di domicilio" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Codice A.S.L.</label>
                            <span>{{ jsonData.accettazioniAsl.aslDomicilioCodice }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">A.S.L.</label>
                            <span>{{ jsonData.accettazioniAsl.aslDomicilioDenominazione }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Ambito</label>
                            <span>{{ jsonData.accettazioniAsl.aslDomicilioAmbito }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Distretto</label>
                            <span>{{ jsonData.accettazioniAsl.aslDomicilioDistretto }}</span>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card" header="Altre info" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Numero di Telefono</label>
                            <span>{{ jsonData.accettazioneAnagrafica.telefono }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">E-Mail</label>
                            <span>{{ jsonData.accettazioneAnagrafica.email }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Medico curante</label>
                            <span>{{ jsonData.accettazioneAnagrafica.medicoCurante }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">E-Mail medico curante</label>
                            <span>{{ jsonData.accettazioneAnagrafica.emailMedicoCurante }}</span>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card" header="Diagnosi e Note" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Diagnosi in Ingresso</label>
                            <span>{{ jsonData.accettazioneAnagrafica.diagnosiIngresso }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Note</label>
                            <span>{{ jsonData.accettazioneAnagrafica.note }}</span>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
    <!--  </b-tab>
        <b-tab title="Persone di riferimento" id="tbPersoneRiferimento">
            <rsa-persona-riferimento-component ref="cmpRsaPersonaRiferimento" :jsonData="jsonData"></rsa-persona-riferimento-component>
        </b-tab>
        <b-tab title="impegnativa (U.V.I.)" id="tbImpegnativa">
            <rsa-accettazione-impegnativa-list-component ref="cmpRsaImpegnativaList" :idAccettazione="id" :jsonData="jsonData"></rsa-accettazione-impegnativa-list-component>
        </b-tab>
        <b-tab title="Spese extra" id="tbSpeseExtra">
            <rsa-spesa-extra-list-component ref="cmpRsaSpesaExtraList" :jsonDataAccettazione="jsonData"> </rsa-spesa-extra-list-component>
        </b-tab>
        <b-tab title="Presenza" id="tbPresenza">
            <rsa-scheda-ospite-uscite-list-component ref="cmpRsaUsciteList" :jsonDataAccettazione="jsonData"></rsa-scheda-ospite-uscite-list-component>
        </b-tab>
        <b-tab title="Dimissione/Trasferimento" id="tbDimissioniTrasferimeti">
            <rsa-scheda-ospite-dimissione-trasferimento-component ref="cmpRsaDimissioneTrasferimento" :jsonDataAccettazione="jsonData"></rsa-scheda-ospite-dimissione-trasferimento-component>
        </b-tab>
    </b-tabs> -->
    <!--    </div> -->
</template>
<script>
import axios from "axios";
import "vue2-datepicker/index.css";
import UtilityMixin from "../../../utility/UtilityMixin";
// import RsaPersonaRiferimentoComponent from "../../components/RsaPersonaRiferimentoComponent.vue";
// import RsaAccettazioneImpegnativaListComponent from "./RsaAccettazioneImpegnativaListComponent.vue";
// import RsaSpesaExtraListComponent from "./RsaSpesaExtraListComponent.vue";
// import RsaSchedaOspiteUsciteListComponent from "../../rsagestioneospiti/components/RsaSchedaOspiteUsciteListComponent.vue";
// import RsaSchedaOspiteDimissioneTrasferimentoComponent from "../../rsagestioneospiti/components/RsaSchedaOspiteDimissioneTrasferimentoComponent.vue";
export default {
    mixins: [UtilityMixin],
    components: {
        /* RsaPersonaRiferimentoComponent, RsaAccettazioneImpegnativaListComponent, RsaSpesaExtraListComponent, RsaSchedaOspiteUsciteListComponent, RsaSchedaOspiteDimissioneTrasferimentoComponent  */
    },

    data() {
        return {
            pathResource: "/rsaaccettazioni",
            pathResourcePresidio: "/strutturepresidi",
            pathResourceComuniByCodiceIstat: "/comuni/istat",
            pathResourcePersoneRifermineto: "/rsapersoneriferimento",
            showModalLoading: false,
            listKey: 0,
            rows: 0,
            perPage: 50,
            currentPage: 1,
            id: null,
            presidio: "",
            comuneNascita: "",
            comuneResidenza: "",
            comuneDomicilio: "",
            activeTabIndex: 0,
            jsonData: {
                codiceStruttura: null,
                idPresidio: null,
                numero: null,
                note: null,
                regime: null,
                idPrenotazione: null,
                accettazioneStruttura: {},
                accettazioneAnagrafica: {
                    idAccettazione: null,
                    tipo: null,
                    identificativo: null,
                    tipoIdentificativo: null,
                    cognome: null,
                    nome: null,
                    dataNascita: null,
                    provinciaNascita: null,
                    comuneNascitaCodiceIstat: null,
                    sesso: null,
                    regioneResidenza: null,
                    provinciaResidenza: null,
                    comuneResidenzaCodiceIstat: null,
                    indirizzoResidenza: null,
                    capResidenza: null,
                    regioneDomicilio: null,
                    provinciaDomicilio: null,
                    comuneDomicilioCodiceIstat: null,
                    indirizzoDomicilio: null,
                    capDomicilio: null,
                    note: null,
                    medicoCurante: null,
                    codiceFiscaleMedicoCurante: null, //non a video
                    telefono: null,
                    email: null,
                    emailMedicoCurante: null,
                },
                accettazioniAsl: {},
            },
        };
    },
    mounted() {},
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        setId(id) {
            this.id = id;
            this.loadData();
        },
        onInputTabs(index) {
            let me = this;
            let activeTab = this.$refs.tabsRsaAccettazioneEdit.getTabs()[index];

            me.activeTab(activeTab.id);
        },
        activeTab(idActiveTab) {
            let me = this;
            switch (idActiveTab) {
                case "tbPersoneRiferimento":
                    me.$refs.cmpRsaPersonaRiferimento.loadData(me.id);
                    break;
                case "tbImpegnativa":
                    me.$refs.cmpRsaImpegnativaList.loadData(me.id);
                    break;
                case "tbSpeseExtra":
                    me.$refs.cmpRsaSpesaExtraList.setId(me.id);
                    break;
                case "tbPresenza":
                    me.$refs.cmpRsaUsciteList.setId(me.id);
                    break;

                case "tbDimissioniTrasferimeti":
                    me.$refs.cmpRsaDimissioneTrasferimento.setId(me.id);
                    break;
            }
        },
        loadData() {
            let me = this;
            this.$emit("beforeLoadData");
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
            axios.get(link).then((response) => {
                me.jsonData = response.data.data;
                me.loadPresidio();
                me.loadComuneNascita();
                me.loadComuneResidenza();
                me.loadComuneDomicilio();
                this.$emit("afterLoadData");
            });
        },
        loadPresidio() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourcePresidio + "/" + me.jsonData.idPresidio;
            axios.get(link).then((response) => {
                me.presidio = response.data.data.denominazione;
            });
        },
        loadComuneNascita() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceComuniByCodiceIstat + "/" + me.jsonData.accettazioneAnagrafica.comuneNascitaCodiceIstat;
            axios.get(link).then((response) => {
                me.comuneNascita = response.data.data.comune;
            });
        },
        loadComuneResidenza() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceComuniByCodiceIstat + "/" + me.jsonData.accettazioneAnagrafica.comuneResidenzaCodiceIstat;
            axios.get(link).then((response) => {
                me.comuneResidenza = response.data.data.comune;
            });
        },
        loadComuneDomicilio() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceComuniByCodiceIstat + "/" + me.jsonData.accettazioneAnagrafica.comuneDomicilioCodiceIstat;
            axios.get(link).then((response) => {
                me.comuneDomicilio = response.data.data.comune;
            });
        },
    },
};
</script>
