<template>
    <div class="sa-tab-scroll">
        <b-row>
            <!-- <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <span class="sa-flexbox-body-title">P.A.I</span> 
        <b-button size="sm" variant="outline-success outline-primary-noborder sa-margin-right float-sm-right" @click="onNuovoPai" class="mb-2">
          <i class="fas fa-plus"></i>
          Aggiungi
        </b-button>
      </b-col> -->
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card" header="Cure residenziali - semiresidenziali" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Nome</label>
                            <b-form-input v-model="jsonData.nome"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Cognome</label>
                            <b-form-input v-model="jsonData.cognome"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Provincia di nascita</label>
                            <b-form-select id="inline-form-custom-select-pref" v-model="provinciaNascita" class="mb-2 mr-sm-2 mb-sm-0" :options="provinceNascitaOptions" :value="null" value-field="sigla" text-field="provincia" @change="onChangeProvinciaNascita" required></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Comune di nascita</label>
                            <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.comuneNascitaCodiceIstat" class="mb-2 mr-sm-2 mb-sm-0" :value="null" value-field="codiceIstat" text-field="comune" :options="comuniNascitaOptions" required></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Data di nascita</label>
                            <date-picker format="DD/MM/YYYY" value-type="timestamp" v-model="jsonData.dataNascita" type="date"></date-picker>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Provincia di residenza</label>
                            <b-form-select id="inline-form-custom-select-pref" v-model="provinciaResidenza" class="mb-2 mr-sm-2 mb-sm-0" :options="provinceResidenzaOptions" :value="null" value-field="sigla" text-field="provincia" @change="onChangeProvinciaResidenza" required></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Comune di residenza</label>
                            <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.comuneResidenzaCodiceIstat" class="mb-2 mr-sm-2 mb-sm-0" :value="null" value-field="codiceIstat" text-field="comune" :options="comuniResidenzaOptions" required></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Indirizzo</label>
                            <b-form-input v-model="jsonData.indirizzoResidenza"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Telefono</label>
                            <b-form-input v-model="jsonData.telefono"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Codice fiscale</label>
                            <b-form-input v-model="jsonData.identificativo"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Età</label>
                            <b-form-input v-model="jsonData.eta"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Sesso</label>
                            <b-form-select v-model="jsonData.sesso" :options="sessoOptions"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">MMG/PLS</label>
                            <b-form-input v-model="jsonData.mmgPls"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">MMG/PLS telefono</label>
                            <b-form-input v-model="jsonData.mmgPlsTelefono"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">MMG/PLS email</label>
                            <b-form-input v-model="jsonData.mmgPlsEmail"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">U.V.M</label>
                            <b-form-input v-model="jsonData.tipoUvm"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Durata PAI</label>
                            <b-form-input v-model="jsonData.durataPai"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Scadenza PAI</label>
                            <!--    <b-form-input v-model="jsonData.scadenzaPai"></b-form-input> -->
                            <date-picker format="DD/MM/YYYY" value-type="timestamp" v-model="jsonData.scadenzaPai" type="date"></date-picker>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Provenienza paziente</label>
                            <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.provenienzaPaziente" class="mb-2 mr-sm-2 mb-sm-0" :value="null" value-field="codice" text-field="descrizione" :options="provenienzaPazienteOptions" required></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Specificare struttura</label>
                            <b-form-input v-model="jsonData.provenienzaPazienteSpecificare" :disabled="jsonData.provenienzaPaziente !== 'STRUTTURA'"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Tipo invio</label>
                            <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.tipoInvio" class="mb-2 mr-sm-2 mb-sm-0" :value="null" value-field="codice" text-field="descrizione" :options="provenienzaPazienteOptions" required></b-form-select>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card" header="Diagnosi d'ingresso" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Patologia prevalente (codice ICD9-CM)</label>
                            <b-input-group class="mb-3">
                                <b-form-input autocomplete="off" id="inline-form-custom-select-pref" v-model="jsonData.diagnosiIngressoPatologiaPrevalenteDescrizione" class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>
                                <b-button size="sm" variant="" @click="onClickDiagnosiIcdIxPatologiaPrevalente">
                                    <b-icon icon="search"> </b-icon>
                                </b-button>
                            </b-input-group>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Patologia concomitante (codice ICD9-CM)</label>
                            <b-input-group class="mb-3">
                                <b-form-input v-model="jsonData.diagnosiIngressoPatologiaConcomitanteDescrizione"> </b-form-input>
                                <b-button size="sm" variant="" @click="onClickDiagnosiIcdIxPatologiaConcomitante">
                                    <b-icon icon="search"> </b-icon>
                                </b-button>
                            </b-input-group>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card" header="Tipologia e grado di non autosufficienza/fragilità paziente" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <!--  <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Provenienza paziente</label>
              <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.comuneNascitaCodiceIstat" class="mb-2 mr-sm-2 mb-sm-0" :value="null" value-field="codiceIstat" text-field="comune" :options="comuniNascitaOptions" @change="onChangeComuneNascita" required></b-form-select>
            </b-col> -->
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <b-form-checkbox v-model="isDimissioneProtetta" switch>Dimissione protetta</b-form-checkbox>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <b-form-checkbox v-model="isDemente" switch>Demente</b-form-checkbox>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <b-form-checkbox v-model="isPazienteGraviArtopatie" switch>Paziente con gravi artopatie</b-form-checkbox>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <b-form-checkbox v-model="isPazientePatologieTemporaneamenteInvalidanti" switch>Paziente con patologie temporaneamente invalidanti</b-form-checkbox>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <b-form-checkbox v-model="isPazienteOncologicoNonAutosufficiente" switch>Paziente oncologico non autosufficiente</b-form-checkbox>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <b-form-checkbox v-model="isAnzianoPluripatologieInvalidanti" switch>Anziano con pluripatologie invalidanti</b-form-checkbox>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <b-form-checkbox v-model="isPazienteIncidentiVascolariAcuti" switch>Paziente con incidenti vascolari acuti</b-form-checkbox>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <b-row>
                                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                    <b-form-checkbox v-model="isAutosufficienteAltro" switch>Altro: </b-form-checkbox>
                                </b-col>
                                <b-col cols="12" xs="12" sm="12" md="9" lg="9" xl="9">
                                    <b-form-input v-model="jsonData.tipologiaNonAutosufficienzaAltroDescrizione" :disabled="!isAutosufficienteAltro"></b-form-input>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Grado di non autosufficienza</label>
                            <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.gradoNonAutosufficienza" class="mb-2 mr-sm-2 mb-sm-0" :value="null" value-field="codice" text-field="descrizione" :options="gradoNonAutosufficienzaOptions" required></b-form-select>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card" header="Struttura" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Tipologia struttura</label>
                            <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.tipologiaStruttura" class="mb-2 mr-sm-2 mb-sm-0" :value="null" value-field="codice" text-field="descrizione" :options="tipologiaStrutturaOptions" required></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Nome struttura</label>
                            <b-form-input v-model="jsonData.nomeStruttura" required></b-form-input>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card" header="Obbiettivi di salute" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <!--  <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Provenienza paziente</label>
              <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.comuneNascitaCodiceIstat" class="mb-2 mr-sm-2 mb-sm-0" :value="null" value-field="codiceIstat" text-field="comune" :options="comuniNascitaOptions" @change="onChangeComuneNascita" required></b-form-select>
            </b-col> -->
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <b-form-checkbox v-model="isMiglioramentoQualitaVita" switch>Miglioramento della qualita di vita</b-form-checkbox>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <b-form-checkbox v-model="isPrevenzioneTrattamentoLesioniDecubito" switch>Prevenzione/trattamento delle lesioni da decubito</b-form-checkbox>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <b-form-checkbox v-model="isTutelaDignitaAutonomiaPaziente" switch>Tutela della dignità e dell'autonomia del paziente</b-form-checkbox>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <b-form-checkbox v-model="isPrevenzioneSindromeImmobilizzazione" switch>Prevenzione della sindrome da immobilizzazione</b-form-checkbox>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <b-form-checkbox v-model="isSostegnoNucleoFamiliareAffrontareMalattia" switch>Sostegno del nucleo familiare nell'affrontare la malattia</b-form-checkbox>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <b-form-checkbox v-model="isRecuperoTonoForzaTrofismoMuscolare" switch>Recupero tono-forza-trofismo muscolare</b-form-checkbox>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <b-form-checkbox v-model="isEducazioneSupportoCaregiverAttivitaNursing" switch>Educazione e supporto del caregiver nell'attività di nursing</b-form-checkbox>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <b-form-checkbox v-model="isRecuperoPosturaSedutaEretta" switch>Recupero postura seduta e/o eretta</b-form-checkbox>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <b-form-checkbox v-model="isMiglioramentoMantenimentoStatoFunzionaleGlobale" switch>Miglioramento/mantenimento dello stato funzionale globale</b-form-checkbox>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <b-form-checkbox v-model="isRecuperoMantenimentoDeambulazione" switch>Recupero/mantenimento deambulazione</b-form-checkbox>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <b-form-checkbox v-model="isRecuperoFunzionalitaArticolare" switch>Recupero della funzionalità articolare</b-form-checkbox>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <b-form-checkbox v-model="isMiglioramentoContezioneDisturbiPsichiciComportamentali" switch>Miglioramento/contenzione disturbi psichici e comportamentali</b-form-checkbox>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <b-form-checkbox v-model="isMiglioramentoMantenimentoMobilitaTrasferimenti" switch>Miglioramento/mantenimento mobilità-trasferimenti</b-form-checkbox>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <b-form-checkbox v-model="isMiglioramentoCapacitaRelazionali" switch>Miglioramento capacità relazionali e contatto socio-ambientali</b-form-checkbox>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <b-form-checkbox v-model="isPrevenzioneCadute" switch>Prevenzione delle cadute</b-form-checkbox>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <b-form-checkbox v-model="isMiglioramentoOrientamentoRealta" switch>Miglioramento orientamento nella realtà</b-form-checkbox>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <b-form-checkbox v-model="isMiglioramentoStatoCognitivoPercettivo" switch>Miglioramento stato cognitivo-percettivo</b-form-checkbox>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <b-row>
                                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                    <b-form-checkbox v-model="isObiettivoSaluteAltro" switch>Altro: </b-form-checkbox>
                                </b-col>
                                <b-col cols="12" xs="12" sm="12" md="9" lg="9" xl="9">
                                    <b-form-input v-model="jsonData.obiettivoSaluteAltroDescrizione" :disabled="jsonData.obiettivoSaluteAltro !== 'SI'"></b-form-input>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card" header="Interventi programmati" header-tag="header" footer-tag="footer" title="">
                    <template #header>
                        <div style="display: flex">
                            <span style="flex: 1">Interventi programmati</span>
                            <b-button size="sm" variant="success btn-toolbar sa-margin-right float-sm-right" @click="onClickOpenModalInterventiProgrammati">
                                <b-icon icon="plus"></b-icon>
                                Aggiungi
                            </b-button>
                        </div>
                    </template>
                    <b-table sticky-header ref="table" stacked="lg" striped hover :items="jsonData.listaInterventiProgrammati" :fields="fieldsTrattamentiRiabilitativi" :current-page="1" :per-page="50" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(equipe)="item">
                            <!--   <b-form-select v-if="item.index === jsonData.listaInterventiProgrammati.length - 1" id="inline-form-custom-select-pref" v-model="item.item.equipe" class="mb-2 mr-sm-2 mb-sm-0" :options="paiRuoliOptions" :value="null" value-field="value" text-field="text" @change="onChangeEquipe" required></b-form-select> -->
                            <span> {{ item.item.equipe }} </span>
                        </template>
                        <template #cell(attivita)="item">
                            <!--<b-form-input v-if="item.index === jsonData.listaInterventiProgrammati.length - 1 && isAttivita" v-model="item.item.attivita"></b-form-input>-->
                            <!--  <b-form-select v-if="item.index === jsonData.listaInterventiProgrammati.length - 1 && isAttivita" id="inline-form-custom-select-pref" v-model="item.item.attivita" class="mb-2 mr-sm-2 mb-sm-0" :options="paiRuoiliSpecializzazioniOptions" :value="null" value-field="value" text-field="text" required></b-form-select> -->
                            <span> {{ item.item.attivita }} </span>
                        </template>
                        <template #cell(tipologiaAccessi)="item">
                            <!--         <b-form-input v-if="item.index === jsonData.listaInterventiProgrammati.length - 1 && isTipologiaAcessi" v-model="item.item.tipologiaAccessi"></b-form-input> -->
                            <!--   <b-input-group class="mb-3">
                <b-form-input maxlength="16" class="text-uppercase" v-model="item.item.tipologiaAccessiDescrizione"></b-form-input>
                <b-button size="sm" variant="" @click="onClickTipologiaAccesso(item.item)">
                  <b-icon icon="search"> </b-icon>
                </b-button>
              </b-input-group> -->
                            <span> {{ item.item.tipologiaAccessiDescrizione }} </span>
                        </template>
                        <template #cell(frequenzaDurataGiorniSettimane)="item">
                            <!-- <b-form-input v-if="item.index === jsonData.listaInterventiProgrammati.length - 1" v-model="item.item.frequenzaDurataGiorniSettimane"></b-form-input> -->
                            <span> {{ item.item.frequenzaDurataGiorniSettimane }} </span>
                        </template>
                        <template #cell(frequenzaDurataGiorniMesi)="item">
                            <!--    <b-form-input v-if="item.index === jsonData.listaInterventiProgrammati.length - 1" v-model="item.item.frequenzaDurataGiorniMesi"></b-form-input> -->
                            <span> {{ item.item.frequenzaDurataGiorniMesi }} </span>
                        </template>
                        <template #cell(strutturaErogante)="item">
                            <!--      <b-form-input v-if="item.index === jsonData.listaInterventiProgrammati.length - 1" v-model="item.item.frequenza"> </b-form-input> -->
                            <span> {{ item.item.strutturaErogante }} </span>
                        </template>
                        <template #cell(actions)="row">
                            <!--      <b-button v-if="row.index === jsonData.listaInterventiProgrammati.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddInterventoProgrammato(jsonData.listaInterventiProgrammati, row.item)">
                <b-icon icon="plus"></b-icon> -->
                            <!--   </b-button> -->
                            <b-button size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDeleteInterventoProgrammato(jsonData.listaInterventiProgrammati, row)">
                                <b-icon icon="trash"></b-icon>
                            </b-button>
                        </template>
                    </b-table>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card" header="Equipe strutturali" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Case manager</label>
                            <b-form-input v-model="jsonData.equipeDistrettualeCaseManager" required></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">CPS coordinatore</label>
                            <b-form-input v-model="jsonData.equipeDistrettualeCpsCoordinatore" required></b-form-input>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card" header="Valutazione dei risultati" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Valutazione</label>
                            <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.valutazioneRisultati" class="mb-2 mr-sm-2 mb-sm-0" :options="valutazioneRisultatiOptions" :value="null" value-field="codice" text-field="descrizione" required></b-form-select>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <b-card class="sa-card" header="Rivalutazione" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Motivo</label>
                            <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.rivalutazioneMotivo" class="mb-2 mr-sm-2 mb-sm-0" :options="rivalutazioneMotivoOptions" :value="null" value-field="codice" text-field="descrizione" required></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Conferma precedente</label>
                            <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.rivalutazioneConfermaPrecedente" class="mb-2 mr-sm-2 mb-sm-0" :options="rivalutazioneConfermaPrecedenteOptions" :value="null" value-field="value" text-field="text" required></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Modificato</label>
                            <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.rivalutazioneModificato" class="mb-2 mr-sm-2 mb-sm-0" :options="rivalutazioneModificatoOptions" :value="null" value-field="value" text-field="text" required></b-form-select>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <b-card class="sa-card" header="Sospensione" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Data inizio</label>
                            <date-picker format="DD/MM/YYYY" value-type="timestamp" v-model="jsonData.sospensioneDataInizio" type="date"></date-picker>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Data fine</label>
                            <date-picker format="DD/MM/YYYY" value-type="timestamp" v-model="jsonData.sospensioneDataFine" type="date"></date-picker>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Motivo</label>
                            <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.sospensioneMotivazione" class="mb-2 mr-sm-2 mb-sm-0" :options="sospensioneMotivazioneOptions" :value="null" value-field="codice" text-field="descrizione" required></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Specificare altro motivo</label>
                            <b-form-input v-model="jsonData.sospensioneMotivazioneAltro" :readonly="jsonData.sospensioneMotivazione !== 'ALTRO'" required></b-form-input>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card" header="Conclusione" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Data </label>
                            <date-picker format="DD/MM/YYYY" value-type="timestamp" v-model="jsonData.conclusioneData" type="date"></date-picker>
                        </b-col>
                        <!-- 
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">Data fine</label>
              <date-picker format="DD/MM/YYYY" value-type="timestamp" v-model="jsonData.sospensioneDataFine" type="date"></date-picker>
            </b-col> -->
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Motivo</label>
                            <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.conclusioneMotivazione" class="mb-2 mr-sm-2 mb-sm-0" :options="conclusioneMotivazioneOptions" :value="null" value-field="codice" text-field="descrizione" required></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Specificare altra motivazione</label>
                            <b-form-input v-model="jsonData.conclusioneMotivazioneAltro" :readonly="jsonData.conclusioneMotivazione !== 'ALTRO'" required></b-form-input>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card" header="Componenti UVM" header-tag="header" footer-tag="footer" title="">
                    <!--  <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            </b-col>
          </b-row> -->
                    <template #header>
                        <div style="display: flex">
                            <span style="flex: 1">Componenti UVM</span>
                            <b-button size="sm" variant="success btn-toolbar sa-margin-right float-sm-right" @click="onClickOpenModalUvm">
                                <b-icon icon="plus"></b-icon>
                                Aggiungi
                            </b-button>
                        </div>
                    </template>
                    <b-table sticky-header ref="table" stacked="lg" striped hover :items="jsonData.listaComponentiUvmScheda" :fields="fieldsComponentiUvmScheda" :current-page="1" :per-page="50" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(componente)="item">
                            <!--   <b-form-select v-if="item.index === jsonData.listaInterventiProgrammati.length - 1" id="inline-form-custom-select-pref" v-model="item.item.equipe" class="mb-2 mr-sm-2 mb-sm-0" :options="componentiUvmOptions" :value="null" value-field="value" text-field="text" @change="onChangeEquipe" required></b-form-select> -->
                            <span> {{ item.item.componenteUvm }} </span>
                        </template>
                        <template #cell(componenteUvmAltro)="item">
                            <span> {{ item.item.componenteUvmAltro }} </span>
                        </template>
                        <template #cell(nome)="item">
                            <!--    <b-form-input v-if="item.index === jsonData.listaComponentiUvmScheda.length - 1" maxlength="16" class="text-uppercase" v-model="item.item.nome"> </b-form-input> -->
                            <span> {{ item.item.nome }} </span>
                        </template>
                        <template #cell(cognome)="item">
                            <!--    <b-form-input v-if="item.index === jsonData.listaComponentiUvmScheda.length - 1" maxlength="16" class="text-uppercase" v-model="item.item.cognome"> </b-form-input> -->
                            <span> {{ item.item.cognome }} </span>
                        </template>
                        <template #cell(firma)="item">
                            <!--  <b-form-input v-if="item.index === jsonData.listaComponentiUvmScheda.length - 1" maxlength="16" class="text-uppercase" v-model="item.item.firma"> </b-form-input> -->
                            <span> {{ item.item.firma }} </span>
                        </template>
                        <template #cell(enteStrutturaAppartenenza)="item">
                            <!--  <b-form-input v-if="item.index === jsonData.listaInterventiProgrammati.length - 1" v-model="item.item.enteStrutturaAppartenenza"> </b-form-input> -->
                            <span> {{ item.item.enteStrutturaAppartenenza }} </span>
                        </template>
                        <template #cell(actions)="row">
                            <!-- <b-button v-if="row.index === jsonData.listaInterventiProgrammati.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddComponenteUvmScheda(jsonData.listaComponentiUvmScheda, row.item)">
                <b-icon icon="plus"></b-icon>
              </b-button> -->
                            <b-button size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDeleteComponenteUvmScheda(jsonData.listaComponentiUvmScheda, row)">
                                <b-icon icon="trash"></b-icon>
                            </b-button>
                        </template>
                    </b-table>
                </b-card>
            </b-col>
        </b-row>
        <rsa-pai-componente-uvm-schede-edit-component ref="cmpRsaPaiComponenteUvmSchede" @addComponenteUvmScheda="onAddComponenteUvmScheda"></rsa-pai-componente-uvm-schede-edit-component>
        <diagnosi-icd-ix-component ref="cmpDiagnosiIcdIxComponent" @onModalClose="onModalDiagnosiIcdIxClose" :hidden="true"></diagnosi-icd-ix-component>
        <rsa-pai-inteventi-programmati-edit-component ref="cmpRsaPaiInterventiProgrammati" @addInterventoProgrammato="onAddInterventoProgrammato"></rsa-pai-inteventi-programmati-edit-component>
    </div>
</template>
<script>
import axios from "axios";
import "vue2-datepicker/index.css";
import UtilityMixin from "../../../utility/UtilityMixin";
import DatePicker from "vue2-datepicker";
import DiagnosiIcdIxComponent from "../../../utility/components/DiagnosiIcdIxComponent.vue";
import RsaPaiComponenteUvmSchedeEditComponent from "./RsaPaiComponenteUvmSchedeEditComponent.vue";
import RsaPaiInteventiProgrammatiEditComponent from "./RsaPaiInteventiProgrammatiEditComponent.vue";
export default {
    mixins: [UtilityMixin],
    components: { DatePicker, DiagnosiIcdIxComponent, RsaPaiComponenteUvmSchedeEditComponent, RsaPaiInteventiProgrammatiEditComponent },
    data() {
        return {
            pathResource: "/rsapai",
            pathResourceProvince: "/province",
            pathResourceComuni: "/comuni/provincia",
            pathResourceProvenienzaPaziente: "/rsapaiprovenienzapaziente",
            pathResourceGradoNonAutosufficienza: "/rsapaigradononautosufficienza",
            pathResourceTipoInvio: "/rsapaitipoinvio",
            pathResourceTipologiaStruttura: "/rsapaitipologiastrutture",
            pathResourceValutazioneRisultati: "/rsapaivalutazionerisultati",
            pathResourceRivalutazioneMotivazione: "/rsapairivalutazionemotivazioni",
            pathResourceSospensioneMotivazione: "/rsapaisospensionemotivazioni",
            pathResourceConclusioneMotivazione: "/rsapaiconclusionemotivazioni",
            pathResourcePaiRuoli: "/rsapairuoli",
            pathResourcePaiRuoliSpecializzazione: "/rsapairuolospecializzazione",
            id: null,
            provinciaNascita: null,
            provinciaResidenza: null,
            // isCheckEquipe: false,
            isAttivita: false,
            isTipologiaAcessi: false,
            btnTipologiaAccessi: "",
            campoDiagnosi: "",
            jsonDataAccettazione: {},
            jsonData: {
                cognome: "",
                comuneNascitaCodiceIstat: null,
                comuneResidenzaCodiceIstat: null,
                conclusioneData: "",
                conclusioneMotivazione: "",
                conclusioneMotivazioneAltro: "",
                createDate: "",
                createUser: "",
                dataNascita: "",
                diagnosiIngressoPatologiaConcomitanteDescrizione: "",
                diagnosiIngressoPatologiaConcomitanteCodice: "",
                diagnosiIngressoPatologiaPrevalenteDescrizione: "",
                diagnosiIngressoPatologiaPrevalenteCodice: "",
                durataPai: "",
                equipeDistrettualeCaseManager: "",
                equipeDistrettualeCpsCoordinatore: "",
                eta: "",
                gradoNonAutosufficienza: null,
                idAccettazione: "",
                identificativo: "",
                indirizzoResidenza: "",
                mmgPls: "",
                mmgPlsEmail: "",
                mmgPlsTelefono: "",
                nome: "",
                nomeStruttura: "",
                obiettivoSaluteAltro: "",
                obiettivoSaluteEducazioneCaregiverNursing: "",
                obiettivoSaluteMiglioramentoCapacitaRelazionaliContatto: "",
                obiettivoSaluteMiglioramentoContenzioneDisturbiPsichici: "",
                obiettivoSaluteMiglioramentoMantenimentoStatoFunzionalita: "",
                obiettivoSaluteMiglioramentoQualitaVita: "",
                obiettivoSaluteMiglioramentoStatoCognitivoPercettivo: "",
                obiettivoSalutePrevenzioneCadute: "",
                obiettivoSalutePrevenzioneSindromeImmobilizzazione: "",
                obiettivoSalutePrevenzioneTrattamentoLesioniDecubito: "",
                obiettivoSaluteRecuperoFunzionalitaArticolare: "",
                obiettivoSaluteRecuperoMantenimentoDeambulazione: "",
                obiettivoSaluteRecuperoMantenimentoMobilita: "",
                obiettivoSaluteRecuperoMuscolare: "",
                obiettivoSaluteRecuperoPosturaSedutaEretta: "",
                obiettivoSaluteSostegnoNucleoFamiliare: "",
                obiettivoSaluteTutelaDignitaAutonomiaPaziente: "",
                obiettivoSaluteMiglioramentoOrientamentoRealta: "",
                proprietario: "",
                provenienzaPaziente: "",
                provenienzaPazienteSpecificare: "",
                rivalutazioneConfermaPrecedente: null,
                rivalutazioneMotivo: null,
                rivalutazioneModificato: null,
                scadenzaPai: "",
                sesso: null,
                sospensioneDataFine: "",
                sospensioneDataInizio: "",
                sospensioneMotivazione: "",
                sospensioneMotivazioneAltro: "",
                statoAttuale: "",
                statoAttualeDataOra: "",
                telefono: "",
                tipoInvio: "",
                tipoUvm: "",
                tipologiaNonAutosufficienzaAltro: "",
                tipologiaNonAutosufficienzaAnzianoPluripatologie: "",
                tipologiaNonAutosufficienzaDimissioneProtetta: "",
                tipologiaNonAutosufficienzaDemente: "",
                tipologiaNonAutosufficienzaPazienteGraviArtopatie: "",
                tipologiaNonAutosufficienzaPazienteIncidentiVascolari: "",
                tipologiaNonAutosufficienzaPazienteOncologico: "",
                tipologiaNonAutosufficienzaPazientePatologieInvalidanti: "",
                tipologiaStruttura: null,
                valutazioneRisultati: "",
                listaInterventiProgrammati: [
                    /* { attivita: "", attivitaAltro: "", equipe: "", frequenzaDurataGiorniMesi: null, frequenzaDurataGiorniSettimane: null, tipologiaAccessiCodice: "", tipologiaAccessiDescrizione: "" } */
                ],
                listaComponentiUvmScheda: [
                    /*{  nome: "", cognome: "", firma: "", enteStrutturaAppartenenza: "", componenteUvm: "", componenteUvmAltro: "" } */
                ],
            },
            comuniNascitaOptions: [{ comune: "-Seleziona Comune-", codiceIstat: null }],
            provinceNascitaOptions: [],
            provinceResidenzaOptions: [],
            comuniResidenzaOptions: [{ comune: "-Seleziona Comune-", codiceIstat: null }],
            tipoinvioOptions: [],
            sessoOptions: [
                { value: null, text: "-Selezionare Sesso-" },
                { value: "M", text: "Maschio" },
                { value: "F", text: "Femmina" },
            ],
            rivalutazioneConfermaPrecedenteOptions: [
                { value: null, text: "-Selezionare Valore-" },
                { value: "SI", text: "SI" },
                { value: "NO", text: "NO" },
            ],
            rivalutazioneModificatoOptions: [
                { value: null, text: "-Selezionare Valore-" },
                { value: "SI", text: "SI" },
                { value: "NO", text: "NO" },
            ],
            rivalutazioneMotivoOptions: [],
            conclusioneMotivazioneOptions: [],
            sospensioneMotivazioneOptions: [],
            valutazioneRisultatiOptions: [],
            tipologiaStrutturaOptions: [],
            gradoNonAutosufficienzaOptions: [],
            provenienzaPazienteOptions: [],
            listaPaiRuoli: [],
            paiRuoliOptions: [],
            paiRuoiliSpecializzazioniOptions: [],
            fieldsTrattamentiRiabilitativi: [
                {
                    label: "Equipe",
                    key: "equipe",
                },
                {
                    label: "Specializzazione",
                    key: "attivita",
                },
                {
                    label: "Tipologia accessi",
                    key: "tipologiaAccessi",
                },
                {
                    label: "Frequenza mese",
                    key: "frequenzaDurataGiorniMesi",
                },

                {
                    label: "Frequenza settimana",
                    key: "frequenzaDurataGiorniSettimane",
                },
                {
                    label: "Struttura erogante",
                    key: "strutturaErogante",
                },

                { class: "sa-table-column-action-double", label: "", key: "actions" },
            ],
            fieldsComponentiUvmScheda: [
                {
                    label: "Componente",
                    key: "componente",
                },
                {
                    label: "Altro tipo componente",
                    key: "componenteUvmAltro",
                },
                {
                    label: "Nome",
                    key: "nome",
                },

                {
                    label: "Cognome",
                    key: "cognome",
                },
                {
                    label: "Firma",
                    key: "firma",
                },
                {
                    label: "Ente/Struttura appartenenza",
                    key: "enteStrutturaAppartenenza",
                },
                { class: "sa-table-column-action-double", label: "", key: "actions" },
            ],
        };
    },
    mounted() {
        let me = this;
        me.idAccettazione = this.$route.query.idAccettazione;
        me.id = this.$route.params.id;

        me.loadAccettazione();
        me.loadData();
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    computed: {
        //
        isDemente: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.tipologiaNonAutosufficienzaDemente);
            },
            set(value) {
                let me = this;
                me.setValore(value, "tipologiaNonAutosufficienzaDemente");
            },
        },
        isAutosufficienteAltro: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.tipologiaNonAutosufficienzaAltro);
            },
            set(value) {
                let me = this;
                me.setValore(value, "tipologiaNonAutosufficienzaAltro");
            },
        },
        isDimissioneProtetta: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.tipologiaNonAutosufficienzaDimissioneProtetta);
            },
            set(value) {
                let me = this;
                me.setValore(value, "tipologiaNonAutosufficienzaDimissioneProtetta");
            },
        },
        isAnzianoPluripatologieInvalidanti: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.tipologiaNonAutosufficienzaAnzianoPluripatologie);
            },
            set(value) {
                let me = this;
                me.setValore(value, "tipologiaNonAutosufficienzaAnzianoPluripatologie");
            },
        },
        isPazienteGraviArtopatie: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.tipologiaNonAutosufficienzaPazienteGraviArtopatie);
            },
            set(value) {
                let me = this;
                me.setValore(value, "tipologiaNonAutosufficienzaPazienteGraviArtopatie");
            },
        },
        isPazienteIncidentiVascolariAcuti: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.tipologiaNonAutosufficienzaPazienteIncidentiVascolari);
            },
            set(value) {
                let me = this;
                me.setValore(value, "tipologiaNonAutosufficienzaPazienteIncidentiVascolari");
            },
        },
        isPazienteOncologicoNonAutosufficiente: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.tipologiaNonAutosufficienzaPazienteOncologico);
            },
            set(value) {
                let me = this;
                me.setValore(value, "tipologiaNonAutosufficienzaPazienteOncologico");
            },
        },
        isPazientePatologieTemporaneamenteInvalidanti: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.tipologiaNonAutosufficienzaPazientePatologieInvalidanti);
            },
            set(value) {
                let me = this;
                me.setValore(value, "tipologiaNonAutosufficienzaPazientePatologieInvalidanti");
            },
        },
        isMiglioramentoQualitaVita: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.obiettivoSaluteMiglioramentoQualitaVita);
            },
            set(value) {
                let me = this;
                me.setValore(value, "obiettivoSaluteMiglioramentoQualitaVita");
            },
        },
        isPrevenzioneTrattamentoLesioniDecubito: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.obiettivoSalutePrevenzioneTrattamentoLesioniDecubito);
            },
            set(value) {
                let me = this;
                me.setValore(value, "obiettivoSalutePrevenzioneTrattamentoLesioniDecubito");
            },
        },
        isTutelaDignitaAutonomiaPaziente: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.obiettivoSaluteTutelaDignitaAutonomiaPaziente);
            },
            set(value) {
                let me = this;
                me.setValore(value, "obiettivoSaluteTutelaDignitaAutonomiaPaziente");
            },
        },
        isRecuperoMantenimentoDeambulazione: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.obiettivoSaluteRecuperoMantenimentoDeambulazione);
            },
            set(value) {
                let me = this;
                me.setValore(value, "obiettivoSaluteRecuperoMantenimentoDeambulazione");
            },
        },
        isPrevenzioneSindromeImmobilizzazione: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.obiettivoSalutePrevenzioneSindromeImmobilizzazione);
            },
            set(value) {
                let me = this;
                me.setValore(value, "obiettivoSalutePrevenzioneSindromeImmobilizzazione");
            },
        },
        isSostegnoNucleoFamiliareAffrontareMalattia: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.obiettivoSaluteSostegnoNucleoFamiliare);
            },
            set(value) {
                let me = this;
                me.setValore(value, "obiettivoSaluteSostegnoNucleoFamiliare");
            },
        },
        isRecuperoTonoForzaTrofismoMuscolare: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.obiettivoSaluteRecuperoMuscolare);
            },
            set(value) {
                let me = this;
                me.setValore(value, "obiettivoSaluteRecuperoMuscolare");
            },
        },
        isEducazioneSupportoCaregiverAttivitaNursing: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.obiettivoSaluteEducazioneCaregiverNursing);
            },
            set(value) {
                let me = this;
                me.setValore(value, "obiettivoSaluteEducazioneCaregiverNursing");
            },
        },
        isRecuperoPosturaSedutaEretta: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.obiettivoSaluteRecuperoPosturaSedutaEretta);
            },
            set(value) {
                let me = this;
                me.setValore(value, "obiettivoSaluteRecuperoPosturaSedutaEretta");
            },
        },
        isMiglioramentoContezioneDisturbiPsichiciComportamentali: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.obiettivoSaluteMiglioramentoContenzioneDisturbiPsichici);
            },
            set(value) {
                let me = this;
                me.setValore(value, "obiettivoSaluteMiglioramentoContenzioneDisturbiPsichici");
            },
        },
        isMiglioramentoMantenimentoStatoFunzionaleGlobale: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.obiettivoSaluteMiglioramentoMantenimentoStatoFunzionalita);
            },
            set(value) {
                let me = this;
                me.setValore(value, "obiettivoSaluteMiglioramentoMantenimentoStatoFunzionalita");
            },
        },
        isRecuperoFunzionalitaArticolare: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.obiettivoSaluteRecuperoFunzionalitaArticolare);
            },
            set(value) {
                let me = this;
                me.setValore(value, "obiettivoSaluteRecuperoFunzionalitaArticolare");
            },
        },
        isMiglioramentoMantenimentoMobilitaTrasferimenti: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.obiettivoSaluteRecuperoMantenimentoMobilita);
            },
            set(value) {
                let me = this;
                me.setValore(value, "obiettivoSaluteRecuperoMantenimentoMobilita");
            },
        },
        isMiglioramentoCapacitaRelazionali: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.obiettivoSaluteMiglioramentoCapacitaRelazionaliContatto);
            },
            set(value) {
                let me = this;
                me.setValore(value, "obiettivoSaluteMiglioramentoCapacitaRelazionaliContatto");
            },
        },
        isPrevenzioneCadute: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.obiettivoSalutePrevenzioneCadute);
            },
            set(value) {
                let me = this;
                me.setValore(value, "obiettivoSalutePrevenzioneCadute");
            },
        },
        isMiglioramentoOrientamentoRealta: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.obiettivoSaluteMiglioramentoOrientamentoRealta);
            },
            set(value) {
                let me = this;
                me.setValore(value, "obiettivoSaluteMiglioramentoOrientamentoRealta");
            },
        },
        isMiglioramentoStatoCognitivoPercettivo: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.obiettivoSaluteMiglioramentoStatoCognitivoPercettivo);
            },
            set(value) {
                let me = this;
                me.setValore(value, "obiettivoSaluteMiglioramentoStatoCognitivoPercettivo");
            },
        },
        isObiettivoSaluteAltro: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.obiettivoSaluteAltro);
            },
            set(value) {
                let me = this;
                me.setValore(value, "obiettivoSaluteAltro");
            },
        },
    },
    methods: {
        // setId(id) {
        //   this.id = id;
        //   this.loadData();
        // },
        loadData() {
            let me = this;
            me.$emit("beforeLoadData");
            me.loadProvinceNascita();
            me.loadProvinceResidenza();
            me.loadProvenienzaPaziente();
            me.loadTipologiaStruttura();
            me.loadGradoNonAutosufficienza();
            me.loadRivalutazioneMotivazioni();
            me.loadSospensioneMotivazioni();
            me.loadConclusioneMotivazioni();
            me.loadValutazioneRisultati();
            //    me.loadPaiRuoli();
            if (me.id === "-1") {
                me.$emit("afterLoadData");
            } else {
                let link = me.pathResource + "/" + me.id;
                UtilityMixin.methods.loadDataResources(link, {}, me.resultResponseData, me.resultErrorData);
                me.$emit("afterLoadData");
            }
        },
        resultResponseData(response) {
            let me = this;
            me.jsonData = response.data.data;
        },
        resultErrorData(error) {
            UtilityMixin.methods.messaggeBoxError(this, error);
        },
        loadProvinceNascita() {
            let me = this;
            me.provinciaOption = [];
            let link = process.env.VUE_APP_PATH_API + me.pathResourceProvince;
            axios
                .get(link)
                .then((response) => {
                    me.provinceNascitaOptions = response.data.data;

                    me.provinceNascitaOptions.unshift({ sigla: null, provincia: "-Seleziona Provincia-" });
                })
                .catch((error) => {
                    console.log("Il servizio delle province ha datao errore: " + error.response.status);
                });
        },
        loadProvinceResidenza() {
            let me = this;
            me.provinciaOption = [];
            let link = process.env.VUE_APP_PATH_API + me.pathResourceProvince;
            axios
                .get(link)
                .then((response) => {
                    me.provinceResidenzaOptions = response.data.data;
                    me.provinceResidenzaOptions.unshift({ sigla: null, provincia: "-Seleziona Provincia-" });
                })
                .catch((error) => {
                    console.log("Il servizio delle province ha datao errore: " + error.response.status);
                });
        },
        onChangeProvinciaNascita(value) {
            let me = this;
            me.comuniNascitaOptions = [];
            me.comuniNascitaOptions.unshift({ codiceIstat: null, comune: "-Seleziona Comune-" });
            me.loadComuni(value, "nascita");
        },
        loadComuni(sigla, destination) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceComuni + "/" + sigla;
            axios
                .get(link)
                .then((response) => {
                    response.data.data.forEach((comune) => {
                        switch (destination) {
                            case "nascita":
                                me.comuniNascitaOptions.push(comune);
                                break;
                            case "residenza":
                                me.comuniResidenzaOptions.push(comune);
                                break;
                            //   case "domicilio":
                            //     me.comuniDomicilioOptions.push(comune);
                            //     break;
                        }
                    });
                })
                .catch((error) => {
                    console.log("Il servizio delle province ha datao errore: " + error.response.status);
                });
        },
        onChangeProvinciaResidenza(provincia) {
            let me = this;
            me.jsonData.comuneResidenzaCodiceIstat = null;
            me.comuniResidenzaOptions = [];
            me.comuniResidenzaOptions.unshift({ codiceIstat: null, comune: "-Seleziona Comune-" });
            me.loadComuni(provincia, "residenza");
        },
        //---------------------------
        loadProvenienzaPaziente() {
            let me = this;
            UtilityMixin.methods.loadDataResources(me.pathResourceProvenienzaPaziente, {}, me.resultResponseProvenienzaPaziente, me.resultErrorProvenienzaPaziente);
        },
        resultResponseProvenienzaPaziente(response) {
            let me = this;
            me.provenienzaPazienteOptions = [];
            me.provenienzaPazienteOptions = response.data.data.list;
            me.provenienzaPazienteOptions.unshift({ codice: "", descrizione: "-Selezionare Valore-" });
        },
        resultErrorProvenienzaPaziente(error) {
            UtilityMixin.methods.messaggeBoxError(this, error);
        },
        /* loadTipologiaStruttura */
        loadTipologiaStruttura() {
            let me = this;
            UtilityMixin.methods.loadDataResources(me.pathResourceTipologiaStruttura, {}, me.resultResponseTipologiaStruttura, me.resultErrorTipologiaStruttura);
        },
        resultResponseTipologiaStruttura(response) {
            let me = this;
            me.tipologiaStrutturaOptions = [];
            me.tipologiaStrutturaOptions = response.data.data.list;
            me.tipologiaStrutturaOptions.unshift({ codice: null, descrizione: "-Selezionare Valore-" });
        },
        resultErrorTipologiaStruttura(error) {
            UtilityMixin.methods.messaggeBoxError(this, error);
        },
        /* loadGradoNonAutosufficienza */
        loadGradoNonAutosufficienza() {
            let me = this;
            UtilityMixin.methods.loadDataResources(me.pathResourceGradoNonAutosufficienza, {}, me.resultResponseGradoNonAutosufficienza, me.resultErrorGradoNonAutosufficienza);
        },
        resultResponseGradoNonAutosufficienza(response) {
            let me = this;
            me.gradoNonAutosufficienzaOptions = [];
            me.gradoNonAutosufficienzaOptions = response.data.data.list;
            me.gradoNonAutosufficienzaOptions.unshift({ codice: null, descrizione: "-Selezionare Valore-" });
        },
        resultErrorGradoNonAutosufficienza(error) {
            UtilityMixin.methods.messaggeBoxError(this, error);
        },
        /* loadValutazioneRisultati */
        loadValutazioneRisultati() {
            let me = this;
            UtilityMixin.methods.loadDataResources(me.pathResourceValutazioneRisultati, {}, me.resultResponseValutazioneRisultati, me.resultErrorValutazioneRisultati);
        },
        resultResponseValutazioneRisultati(response) {
            let me = this;
            me.valutazioneRisultatiOptions = [];
            me.valutazioneRisultatiOptions = response.data.data.list;
            me.valutazioneRisultatiOptions.unshift({ codice: "", descrizione: "-Selezionare Valore-" });
        },
        resultErrorValutazioneRisultati(error) {
            UtilityMixin.methods.messaggeBoxError(this, error);
        },
        /* loadRivalutazioneMotivazioni */
        loadRivalutazioneMotivazioni() {
            let me = this;
            UtilityMixin.methods.loadDataResources(me.pathResourceRivalutazioneMotivazione, {}, me.resultResponseRivalutazioneMotivazioni, me.resultErrorRivalutazioneMotivazioni);
        },
        resultResponseRivalutazioneMotivazioni(response) {
            let me = this;
            me.rivalutazioneMotivoOptions = [];
            me.rivalutazioneMotivoOptions = response.data.data.list;
            me.rivalutazioneMotivoOptions.unshift({ codice: null, descrizione: "-Selezionare Valore-" });
        },
        resultErrorRivalutazioneMotivazioni(error) {
            UtilityMixin.methods.messaggeBoxError(this, error);
        },
        /* loadSospensioneMotivazioni */
        loadSospensioneMotivazioni() {
            let me = this;
            UtilityMixin.methods.loadDataResources(me.pathResourceSospensioneMotivazione, {}, me.resultResponseSospensioneMotivazioni, me.resultErrorSospensioneMotivazioni);
        },
        resultResponseSospensioneMotivazioni(response) {
            let me = this;
            me.sospensioneMotivazioneOptions = [];
            me.sospensioneMotivazioneOptions = response.data.data.list;
            me.sospensioneMotivazioneOptions.unshift({ codice: "", descrizione: "-Selezionare Valore-" });
        },
        resultErrorSospensioneMotivazioni(error) {
            UtilityMixin.methods.messaggeBoxError(this, error);
        },

        /* loadConclusioneMotivazioni */
        loadConclusioneMotivazioni() {
            let me = this;
            UtilityMixin.methods.loadDataResources(me.pathResourceConclusioneMotivazione, {}, me.resultResponseConclusioneMotivazioni, me.resultErrorConclusioneMotivazioni);
        },
        resultResponseConclusioneMotivazioni(response) {
            let me = this;
            me.conclusioneMotivazioneOptions = [];
            me.conclusioneMotivazioneOptions = response.data.data.list;
            me.conclusioneMotivazioneOptions.unshift({ codice: "", descrizione: "-Selezionare Valore-" });
        },
        resultErrorConclusioneMotivazioni(error) {
            UtilityMixin.methods.messaggeBoxError(this, error);
        },

        /* loadPaiRuoli */
        loadPaiRuoli() {
            let me = this;
            UtilityMixin.methods.loadDataResources(me.pathResourcePaiRuoli, {}, me.resultResponsePaiRuoli, me.resultErrorPaiRuoli);
        },
        resultResponsePaiRuoli(response) {
            let me = this;
            me.paiRuoliOptions = [];
            me.listaPaiRuoli = response.data.data.list;
            me.listaPaiRuoli.forEach((element) => {
                me.paiRuoliOptions.push({ value: element.ruolo, text: element.ruolo });
            });
            me.paiRuoliOptions.unshift({ value: "", text: "-Selezionare Valore-" });
        },
        resultErrorPaiRuoli(error) {
            UtilityMixin.methods.messaggeBoxError(this, error);
        },

        loadTipoInvio() {
            let me = this;
            UtilityMixin.methods.loadDataResources(me.pathResourceTipoInvio, {}, me.resultResponseTipoInvio, me.resultErrorTipoInvio);
        },
        resultResponseTipoInvio(response) {
            let me = this;
            me.tipoinvioOptions = [];
            me.tipoinvioOptions = response.data.data.list;
            me.tipoinvioOptions.unshift({ codice: "", descrizione: "-Selezionare Valore-" });
        },
        resultErrorTipoInvio(error) {
            UtilityMixin.methods.messaggeBoxError(this, error);
        },
        onAddInterventoProgrammato(value) {
            let me = this;
            //if (value.equipe !== "" && value.strutturaErogante !== "") {
            me.jsonData.listaInterventiProgrammati.push(value);
            // } else {
            //   this.$bvModal
            //     .msgBoxOk("Campi OBBLIGATORI!", {
            //       title: "ATTENZIONE",
            //       size: "sm",
            //       okVariant: "outline-danger",
            //       headerClass: "sa-msg-header-danger",
            //       footerClass: "p-2 border-top-0",
            //       centered: true,
            //     })
            //     .then((value) => {
            //       console.log(value);
            //     })
            //     .catch((err) => {
            //       console.log(err);
            //     });
            // }
        },
        onDeleteInterventoProgrammato(array, row) {
            if (row.index != array.length) {
                this.$bvModal
                    .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
                        title: "Titolo",
                        buttonSize: "sm",
                        okVariant: "secondary",
                        cancelVariant: "danger",
                        okTitle: "SI",
                        cancelTitle: "NO",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true,
                    })
                    .then((value) => {
                        if (value) {
                            array.splice(row.index, 1);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        onAddComponenteUvmScheda(/* array, */ value) {
            let me = this;
            // if (value.componenteUvm !== "") {
            me.jsonData.listaComponentiUvmScheda.push(value);
            // } else {
            //     this.$bvModal
            //         .msgBoxOk("Campi OBBLIGATORI!", {
            //             title: "ATTENZIONE",
            //             size: "sm",
            //             okVariant: "outline-danger",
            //             headerClass: "sa-msg-header-danger",
            //             footerClass: "p-2 border-top-0",
            //             centered: true,
            //         })
            //         .then((value) => {
            //             console.log(value);
            //         })
            //         .catch((err) => {
            //             console.log(err);
            //         });
            // }
        },
        onDeleteComponenteUvmScheda(array, row) {
            if (row.index != array.length) {
                this.$bvModal
                    .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
                        title: "Titolo",
                        buttonSize: "sm",
                        okVariant: "secondary",
                        cancelVariant: "danger",
                        okTitle: "SI",
                        cancelTitle: "NO",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true,
                    })
                    .then((value) => {
                        if (value) {
                            array.splice(row.index, 1);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        // onChangeEquipe(value) {
        //   let me = this;
        //   me.listaPaiRuoli.forEach((element) => {
        //     if (element.ruolo === value) {
        //       me.loadPaiRuoloSpecializzazioni(element.id); // if (element.specializzazione) {
        //       /*     switch (element.tipologiaAccessi) {
        //         case "FARMACI": */
        //       me.btnTipologiaAccessi = element.tipologiaAccesso;
        //       /*     break;
        //         case "ICD-IX":
        //           me.btnTipologiaAccessi = element.tipologiaAccessi;
        //           break;
        //         case "ICD-X":
        //           me.btnTipologiaAccessi = element.tipologiaAccessi;
        //           break;
        //         case "TESTO LIBERO":
        //           me.btnTipologiaAccessi = element.tipologiaAccessi;
        //           break; */
        //       //  }
        //       // }
        //     }
        //   });
        // },
        loadPaiRuoloSpecializzazioni(idRuolo) {
            let me = this;
            UtilityMixin.methods.loadDataResources(me.pathResourcePaiRuoliSpecializzazione, { idRuolo: idRuolo }, me.resultResponsePaiRuoloSpecializzazioni, me.resultErrorPaiRuoloSpecializzazioni);
        },
        resultResponsePaiRuoloSpecializzazioni(response) {
            let me = this;
            // me.paiRuoliOptions = [];
            if (response.data.data.recordsNumber > 0) {
                me.isAttivita = true;
                me.listaPaiRuoliSpecializzazione = [];
                me.listaPaiRuoliSpecializzazione = response.data.data.list;
                me.listaPaiRuoliSpecializzazione.forEach((element) => {
                    me.paiRuoiliSpecializzazioniOptions.push({ value: element.specializzazione, text: element.specializzazione });
                });
                me.paiRuoliOptions.unshift({ value: "", text: "-Selezionare Valore-" });
            }
        },
        resultErrorPaiRuoloSpecializzazioni(error) {
            UtilityMixin.methods.messaggeBoxError(this, error);
        },
        //   onClickDiagnosiIcdIx() {
        //   let me = this;
        //   //if(){}
        //   me.$refs.cmpDiagnosiIcdIxComponent.onClickBtnRicercaIcdIx();
        // },
        onModalIcdIxClose(selectedElement) {
            let me = this;
            let diagnosi = selectedElement[0];
            me.jsonData.diagnosiIcdixCodice = diagnosi[0].codiceIcdix;
            me.jsonData.diagnosiIcdixDescrizione = diagnosi[0].descrizioneIcdix;
        },
        onNuovoPai() {
            let me = this;
            UtilityMixin.methods.saveResource(me.pathResource, me.jsonData, me.resulResponseSalvaPai, me.resulErrorSalvaPai);
        },
        resulResponseSalvaPai() {
            let me = this;
            me.loadData();
        },
        resulErrorSalvaPai(error) {
            UtilityMixin.methods.messaggeBoxError(this, error);
        },
        getValore(data) {
            return data === "SI";
        },
        setValore(value, data) {
            let me = this;
            me.jsonData[data] = value ? "SI" : "NO";
        },
        onClickOpenModalUvm() {
            let me = this;
            me.$refs.cmpRsaPaiComponenteUvmSchede.show();
        },
        onClickOpenModalInterventiProgrammati() {
            let me = this;
            me.$refs.cmpRsaPaiInterventiProgrammati.show();
        },
        onClickDiagnosiIcdIxPatologiaPrevalente() {
            let me = this;
            me.showModalDiagnosiIcdIx("patologiaPrevalente");
        },
        onClickDiagnosiIcdIxPatologiaConcomitante() {
            let me = this;
            me.showModalDiagnosiIcdIx("patologiaConcomitante");
        },
        onClickDiagnosiIcdIxTipologiaAccessi() {
            let me = this;
            me.showModalDiagnosiIcdIx("tipologiaIngresso");
        },
        showModalDiagnosiIcdIx(value) {
            let me = this;
            me.campoDiagnosi = value;
            me.$refs.cmpDiagnosiIcdIxComponent.onClickBtnRicercaIcdIx();
        },
        onModalDiagnosiIcdIxClose(selectedElement) {
            let me = this;
            let diagnosi = selectedElement[0];
            switch (me.campoDiagnosi) {
                case "patologiaPrevalente":
                    me.jsonData.diagnosiIngressoPatologiaPrevalenteCodice = diagnosi[0].codiceIcdix;
                    me.jsonData.diagnosiIngressoPatologiaPrevalenteDescrizione = diagnosi[0].descrizioneIcdix;
                    break;

                case "patologiaConcomitante":
                    me.jsonData.diagnosiIngressoPatologiaConcomitanteCodice = diagnosi[0].codiceIcdix;
                    me.jsonData.diagnosiIngressoPatologiaConcomitanteDescrizione = diagnosi[0].descrizioneIcdix;
                    break;

                /*   case "tipologiaIngresso":
          //  let lunghezza = me.jsonData.listaInterventiProgrammati.length;

          me.jsonData.listaInterventiProgrammati[me.jsonData.listaInterventiProgrammati.length - 1].tipologiaAccessiDescrizione = diagnosi[0].descrizioneIcdix;
          me.jsonData.listaInterventiProgrammati[me.jsonData.listaInterventiProgrammati.length - 1].tipologiaAccessiCodice = diagnosi[0].codiceIcdix;

          break; */
            }
        },
        /* onClickTipologiaAccesso(value) {
      let me = this;

      me.listaPaiRuoli.forEach((element) => {
        if (value.equipe === element.ruolo) {
          switch (element.tipologiaAccesso) {
            case "FARMACI":
              break;
            case "ICD-IX":
              me.showModalDiagnosiIcdIx("tipologiaIngresso");
              break;
            case "ICD-X":
              break;
            case "NOMENCLATORE":
              break;
            case "TESTO LIBERO":
              break;
          }
        }
      });
    }, */
        loadAccettazione() {
            let me = this;
            let pathResourceAccettazione = "/rsaaccettazioni/" + me.idAccettazione;
            UtilityMixin.methods.loadDataResources(pathResourceAccettazione, {}, me.resultResponseAccettazione, me.resultErrorAccettazione);
        },

        resultResponseAccettazione(response) {
            let me = this;
            me.jsonDataAccettazione = response.data.data;
            me.setData(me.jsonDataAccettazione);
        },
        resultErrorAccettazione(error) {
            UtilityMixin.methods.messaggeBoxError(this, error);
        },

        setData(jsonDataAccettazione) {
            let me = this;
            me.jsonData.nome = jsonDataAccettazione.accettazioneAnagrafica.nome;
            me.jsonData.cognome = jsonDataAccettazione.accettazioneAnagrafica.cognome;
            me.jsonData.sesso = jsonDataAccettazione.accettazioneAnagrafica.sesso;
            me.jsonData.identificativo = jsonDataAccettazione.accettazioneAnagrafica.identificativo;
            me.jsonData.statoAttualeDataOra = new Date().getTime();
            me.jsonData.dataNascita = jsonDataAccettazione.accettazioneAnagrafica.dataNascita;
            me.jsonData.idAccettazione = jsonDataAccettazione.id;
            me.provinciaNascita = jsonDataAccettazione.accettazioneAnagrafica.provinciaNascita;
            me.provinciaResidenza = jsonDataAccettazione.accettazioneAnagrafica.provinciaResidenza;
            me.jsonData.comuneNascitaCodiceIstat = jsonDataAccettazione.accettazioneAnagrafica.comuneNascitaCodiceIstat;
            me.jsonData.comuneResidenzaCodiceIstat = jsonDataAccettazione.accettazioneAnagrafica.comuneResidenzaCodiceIstat;
            me.jsonData.indirizzoResidenza = jsonDataAccettazione.accettazioneAnagrafica.indirizzoResidenza;
            me.jsonData.telefono = jsonDataAccettazione.accettazioneAnagrafica.telefono;
            // me.jsonData.dataNascita= UtilityMixin.methods.calcoloEta(me.jsonData.dataNascita);
            me.calcolaEta(me.jsonData.dataNascita);
            me.loadComuni(me.provinciaNascita, "nascita");
            me.loadComuni(me.provinciaResidenza, "residenza");
        },
        calcolaEta(dataNascita) {
            let me = this;
            let today = new Date();
            let birthDate = new Date(dataNascita);
            me.jsonData.eta = today.getFullYear() - birthDate.getFullYear();
        },
    },
};
</script>
