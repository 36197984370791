<template>
    <div class="sa-list-component">
        <div class="sa-list-component-header">
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </div>
        <div class="sa-list-component-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" stacked="lg" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
                    <template v-slot:cell(chiamanteContatto)="{ item }">
                        <router-link class="sa-edit-link" :to="'/cot/tracciamentochiamate/edit/' + item.id">{{ item.chiamanteContatto }}</router-link>
                    </template>
                    <template v-slot:cell(dataOra)="{ item }">
                        {{ formatDateTime(item.dataOra) }}
                    </template>
                    <template v-slot:cell(chiamante)="{ item }">
                        {{ item.chiamanteNominativo }}
                        <span class="sa-label-data-secondary">{{ item.chiamanteRuolo }}</span>
                    </template>
                    <template v-slot:cell(paziente)="{ item }">
                        {{ item.pazienteNome }} {{ item.pazienteCognome }}
                        <span class="sa-label-data-secondary">{{ item.pazienteIdentificativo }}</span>
                    </template>
                    <template v-slot:cell(actions)="{ item }">
                        <b-button size="sm" class="mr-1 ml-1 no-text" variant="outline-secondary" @click="onInfoStanza(item)">
                            <i class="fas fa-info"></i>
                        </b-button>
                    </template>
                </b-table>
            </div>
            <b-modal ref="mdlChiamataView" size="lg" title="Info Chiamata" :ok-only="true" ok-title="Chiudi">
                <cot-tracciamento-chiamate-view-component ref="CotTracciamentoChiamateViewComponent" :jsonData="tracciamentoChiamataSelected" :idTracciamanetoChiamata="idTracciamanetoChiamata" @afterLoadData="onAfterLoadData"></cot-tracciamento-chiamate-view-component>
            </b-modal>
        </div>
        <div class="sa-list-component-footer">
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
// import Vue from "vue";
import axios from "axios";
import UtilityMixin from "../../utility/UtilityMixin";
import CotTracciamentoChiamateViewComponent from "./CotTracciamentoChiamateViewComponent.vue";
export default {
    components: { CotTracciamentoChiamateViewComponent },
    mixins: [UtilityMixin],
    watch: {
        currentPage() {
            let me = this;
            me.loadData();
        },
    },
    data() {
        return {
            idTracciamanetoChiamata: null,
            rows: 0,
            perPage: 50,
            currentPage: 1,
            pathResource: "/cottracciamentochiamate",
            filtro: {},
            items: [],
            listKey: 0,
            tracciamentoChiamataSelected: { idPropostaAccesso: null, dataOra: null, codiceMotivo: "", descrizioneMotivo: null, note: null, chiamanteNominativo: null, chiamanteRuolo: null, pazienteNome: null, pazienteCognome: null, pazienteIdentificativo: null },
            fields: [
                {
                    label: "Numero Chiamante",
                    key: "chiamanteContatto",
                    sortable: true,
                },
                {
                    label: "Data Ora",
                    key: "dataOra",
                    sortable: true,
                },
                {
                    label: "Chiamante",
                    key: "chiamante",
                    sortable: true,
                },
                {
                    label: "Motivo",
                    key: "descrizioneMotivo",
                    sortable: true,
                },
                {
                    label: "Paziente",
                    key: "paziente",
                    sortable: true,
                },
                {
                    label: "",
                    tdClass: "text-center",
                    thStyle: "width: 10rem",
                    key: "actions",
                },
            ],
        };
    },
    mounted() {
        // let me = this;
        // let sessionStorage = window.sessionStorage;
        // if (sessionStorage["filtro"]) {
        //     me.filtro = JSON.parse(sessionStorage["filtro"]);
        // }
        // // me.loadData();
    },
    methods: {
        loadData() {
            let me = this;

            let sessionStorage = window.sessionStorage;
            if (sessionStorage["filtro"]) {
                me.filtro = JSON.parse(sessionStorage["filtro"]);
            }
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.filtro.page = me.currentPage;
            me.filtro.forPage = me.perPage;
            //   console.log("ECCOMI");
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];
                    me.rows = response.data.data.recordsNumber;
                    me.items = response.data.data.list;

                    me.listKey++;
                    this.$emit("afterLoadData");
                })
                .catch(() => {
                    this.$emit("afterLoadData");
                });
        },
        onInfoStanza(value) {
            let me = this;
            me.tracciamentoChiamataSelected = value;
            me.$refs.mdlChiamataView.show();
            // me.$refs.CotTracciamentoChiamateViewComponent.loadData(value.id);
        },
        onAfterLoadData() {
            let me = this;
            me.$refs.mdlChiamataView.show();
        },
    },
};
</script>

<style></style>
