<template>
    <div style="display: inline-block">
        <b-button v-if="tipoDocumento === 'SEGNALAZIONE' && size === 'sm'" size="sm" class="mr-1 ml-1 no-text" variant="outline-success" @click="onClickBtnSend()"><i class="far fa-paper-plane"></i></b-button>
        <b-button v-if="tipoDocumento === 'SEGNALAZIONE' && size !== 'sm'" size="sm" variant="outline-success btn-toolbar sa-margin-left float-sm-left" @click="onClickBtnSend()"> <i class="far fa-paper-plane"></i>&nbsp;&nbsp;Invia</b-button>
        <b-button v-if="tipoDocumento === 'INDAGINE'" size="md" variant="outline-success btn-toolbar sa-margin-left float-sm-left" @click="onClickBtnOpenModal()"><i class="far fa-paper-plane" :disabled="btnSendDisable"></i>&nbsp;&nbsp;Invia</b-button>
        <!-- <b-button v-if="tipoDocumento === 'INDAGINE'" size="md" class="mr-1 ml-1 no-text" variant="outline-success btn-toolbar sa-margin-left float-sm-left" @click="onClickBtnOpenModal()"><i class="far fa-paper-plane" :disabled="btnSendDisable"></i> Invia</b-button> -->
        <b-modal b-modal ref="mdlDestinatari" id="mdlDestinatari" size="xl" style="height: 100%" @ok="onSend" title="Seleziona Destinatari/Assegnazioni">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-tabs content-class="mt-3" ref="tabs" class="sa-tab" style="height: 100%; padding-bottom: 25px" fill>
                        <b-tab lazy :title="'Assegnazioni'" active>
                            <b-table sticky-header ref="tblAssegnazioni" stacked="xl" striped hover itemscope :items="jsonData.listaAssegnazioni" :fields="fieldsAssegnazioni" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                                <template #cell(index)="jsonData">
                                    {{ jsonData.index + 1 }}
                                </template>
                                <template #cell(ufficio)="item">
                                    <b-form-select v-if="item.index === jsonData.listaAssegnazioni.length - 1" v-model="item.item.codiceUfficio" :options="ufficiOptions" value-field="id" text-field="descrizione" @change="onSelectUfficio(item.item)"></b-form-select>
                                    <span class="sa-data" v-else>{{ item.item.descrizioneUfficio }}</span>
                                </template>
                                <template #cell(username)="item">
                                    <b-form-select v-if="item.index == jsonData.listaAssegnazioni.length - 1" v-model="item.item.username" :options="utentiOptions" value-field="value" text-field="text"></b-form-select>
                                    <span class="sa-data" v-else>{{ item.item.username }}</span>
                                </template>
                                <template #cell(oggetto)="item">
                                    <b-form-input v-model="item.item.oggetto"></b-form-input>
                                </template>
                                <template #cell(actions)="row">
                                    <b-button v-if="row.index === jsonData.listaAssegnazioni.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddAssegnazione(jsonData.listaAssegnazioni, row.item)">
                                        <b-icon icon="plus"></b-icon>
                                    </b-button>
                                    <b-button v-if="row.index !== jsonData.listaAssegnazioni.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDeleteAssegnazione(jsonData.listaAssegnazioni, row)">
                                        <b-icon icon="trash"></b-icon>
                                    </b-button>
                                </template>
                            </b-table>
                        </b-tab>
                        <b-tab lazy :title="'Destinatari'">
                            <b-table sticky-header ref="tblDestinatari" stacked="xl" striped hover itemscope :items="jsonData.listaDestinatari" :fields="fieldsDestinatari" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                                <template #cell(index)="jsonData">
                                    {{ jsonData.index + 1 }}
                                </template>
                                <template #cell(tipoPersona)="item">
                                    <b-form-select v-if="item.index === jsonData.listaDestinatari.length - 1 && !btnRubrica" v-model="item.item.tipoPersona" :options="tipoPersonaOptions" value-field="value" text-field="text" @change="onSelectTipoPersona(item.item)"></b-form-select>
                                    <span class="sa-data" v-else>{{ item.item.tipoPersona }}</span>
                                </template>
                                <template #cell(destinatario)="item">
                                    <b-form-input v-if="item.index === jsonData.listaDestinatari.length - 1 && !btnRubrica" v-model="item.item.destinatario"></b-form-input>
                                    <span class="sa-data" v-else>{{ item.item.destinatario }}</span>
                                </template>
                                <template #cell(destinatarioMail)="item">
                                    <b-form-input v-if="!btnRubrica && item.index === jsonData.listaDestinatari.length - 1" v-model="item.item.destinatarioMail"></b-form-input>
                                    <b-form-select v-else-if="btnRubrica === true && item.index === jsonData.listaDestinatari.length - 1" v-model="item.item.destinatarioMail"
                                        ><!-- @change="() => onSelectEmail(item.item)"-->
                                        <option value="" disabled>-Selezionare Email-</option>
                                        <option v-for="option in rubricaOptions" :key="option.email" :value="option.email">
                                            {{ option.email }}
                                        </option>
                                    </b-form-select>
                                    <span class="sa-data" v-else>{{ item.item.destinatarioMail }}</span>
                                </template>
                                <template #cell(rubrica)="row">
                                    <b-button v-if="row.index === jsonData.listaDestinatari.length - 1" size="sm" :variant="styleRubrica" @click="onAddLabelRubrica()">
                                        <b-icon icon="journal-bookmark"></b-icon>
                                    </b-button>
                                </template>
                                <template #cell(actions)="row">
                                    <b-button v-if="row.index === jsonData.listaDestinatari.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddDestinatario(jsonData.listaDestinatari, row.item)">
                                        <b-icon icon="plus"></b-icon>
                                    </b-button>
                                    <b-button v-if="row.index !== jsonData.listaDestinatari.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDeleteDestinatario(jsonData.listaDestinatari, row)">
                                        <b-icon icon="trash"></b-icon>
                                    </b-button>
                                </template>
                            </b-table>
                        </b-tab>
                    </b-tabs>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
import axios from "axios";
import UtilityMixin from "../../utility/UtilityMixin";
export default {
    props: {
        pathResource: String,
        size: String,
        id: String,
        pageRoot: String,

        tipoDocumento: {
            String,
            default: function () {
                return "SEGNALAZIONE";
            },
        },
    },
    mounted() {
        let me = this;
        me.idIndagine = this.$route.params.id;

        if (me.tipoDocumento === "INDAGINE") {
            me.loadUffici();
            // me.loadDestinatariAssegnazioni();
            me.loadListaRubrica();
        }
    },
    data() {
        return {
            currentPage: 1,
            perPage: 100,
            idIndagine: "-1",
            pathResourceUffici: "/protocolgedoc/uffici",
            pathResourceUtenti: "/protocolgedoc/utentibyufficio",
            pathResourceDestinatariAssegnazioni: "/protocolgedocdestinatariassegnazioni",
            pathResourceListaRubrica: "/protocolrubrica",
            btnSendDisable: false,
            btnRubrica: false,
            jsonData: {
                listaAssegnazioni: [
                    {
                        codiceUfficio: "",
                        descrizioneUfficio: "",
                        username: "",
                        oggetto: "",
                    },
                ],
                listaDestinatari: [
                    {
                        tipoPersona: "",
                        tipoPersonaDescrizione: "",
                        destinatario: "",
                        destinatarioMail: "",
                    },
                ],
                idDocumento: "",
            },
            listaRubrica: [
                {
                    tipoPersona: "",
                    nome: "",
                    cognome: "",
                    destinatarioMail: "",
                },
            ],
            styleRubrica: "btn btn-outline-success waves-effect waves-light width-sm no-text",
            listaDestinatariRubrica: [],
            ufficiOptions: [],
            listaUffici: [],
            utentiOptions: [{ text: "-Selezionare Utente-", value: "" }],
            listaUtenti: [],
            rubricaOptions: [],
            selectedEmail: "",

            tipoPersonaOptions: [
                { text: "-Selezionare il Tipo-", value: "" },
                { text: "FISICA", value: "F" },
                { text: "GIURIDICA", value: "G" },
            ],
            fieldsAssegnazioni: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Ufficio",
                    key: "ufficio",
                    thStyle: "width: 25rem",
                    sortable: false,
                },
                {
                    label: "Username",
                    key: "username",
                    thStyle: "width: 25rem",
                    sortable: false,
                },
                {
                    label: "Oggettto",
                    key: "oggetto",
                    sortable: false,
                },
                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 4rem",
                    tdClass: "text-center",
                },
            ],
            fieldsDestinatari: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Tipo Persona",
                    key: "tipoPersona",
                    thStyle: "width: 25rem",
                    sortable: false,
                },
                {
                    label: "Nominativo",
                    key: "destinatario",
                    thStyle: "width: 25rem",
                    sortable: false,
                },

                {
                    label: "E-Mail",
                    key: "destinatarioMail",
                    sortable: false,
                },
                {
                    label: "",
                    key: "rubrica",
                    thStyle: "width: 4rem",
                    tdClass: "text-center",
                },
                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 4rem",
                    tdClass: "text-center",
                },
            ],
        };
    },
    methods: {
        onClickBtnSend() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
            axios
                .get(link)
                .then((response) => {
                    this.$emit("update", response.data.data);
                })
                .catch((error) => {
                    this.$bvModal
                        .msgBoxOk(link + " \n" + error.message, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then(() => {})
                        .catch((err) => {
                            console.log(err);
                        });
                });
        },
        onClickBtnOpenModal() {
            let me = this;
            me.$refs["mdlDestinatari"].show();
        },
        onAddLabelRubrica() {
            let me = this;
            me.btnRubrica = !me.btnRubrica;
            me.styleRubrica = me.btnRubrica ? "btn btn-outline-danger waves-effect waves-light width-sm no-text" : "btn btn-outline-success waves-effect waves-light width-sm no-text";
        },
        onSend() {
            let me = this;

            me.jsonData.idDocumento = me.idIndagine;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            UtilityMixin.methods.restCallPost(link, me.jsonData, me.sendResponse, me.sendResponseError);
        },

        sendResponse(response) {
            let me = this;
            me.btnSendDisable = true;
            me.$emit("update", response);
        },
        sendResponseError(error) {
            this.$bvModal
                .msgBoxOk(error.response.data.messaggio, {
                    title: "ATTENZIONE",
                    size: "sm",
                    okVariant: "outline-danger",
                    headerClass: "sa-msg-header-danger",
                    footerClass: "p-2 border-top-0",
                    centered: true,
                })
                .then((value) => {
                    this.boxTwo = value;
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        loadUffici() {
            let me = this;
            me.idIndagine = this.$route.params.id;
            me.listaUffici = [];
            me.ufficiOptions = [];
            let link = process.env.VUE_APP_PATH_API + me.pathResourceUffici;

            UtilityMixin.methods.restCallGet(link, null, me.loadUfficiResponse, me.loadUfficiResponseError);
        },
        loadUfficiResponse(response) {
            let me = this;
            me.listaUffici = response.data.data.lista_uffici;
            me.ufficiOptions = response.data.data.lista_uffici;
            me.ufficiOptions.unshift({ descrizione: "-Seleziona Ufficio-", id: "" });
        },
        loadUfficiResponseError(error) {
            this.$bvModal
                .msgBoxOk(error.response.data.messaggio, {
                    title: "ATTENZIONE",
                    size: "sm",
                    okVariant: "outline-danger",
                    headerClass: "sa-msg-header-danger",
                    footerClass: "p-2 border-top-0",
                    centered: true,
                })
                .then((value) => {
                    this.boxTwo = value;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        onSelectUfficio(value) {
            let me = this;
            me.listaUffici.forEach((element) => {
                if (element.id === value.codiceUfficio) {
                    value.descrizioneUfficio = element.descrizione;
                }
            });
            me.loadListaUtenti(value.codiceUfficio);
        },
        loadListaUtenti(codiceUfficio) {
            let me = this;
            me.listaUtenti = [];
            let filtro = { idUfficio: codiceUfficio };
            me.utentiOptions = [{ text: "-Selezionare Utente-", value: "" }];
            let link = process.env.VUE_APP_PATH_API + me.pathResourceUtenti;

            UtilityMixin.methods.restCallGet(link, filtro, me.loadListaUtentiResponse, me.loadListaUtentiResponseError);
        },
        loadListaUtentiResponse(response) {
            let me = this;
            me.listaUtenti = response.data.data.lista_utenti;
            response.data.data.lista_utenti.forEach((element) => {
                me.utentiOptions.push({
                    text: element.username,
                    value: element.username,
                });
            });
        },
        loadListaUtentiResponseError(error) {
            this.$bvModal
                .msgBoxOk(error.response.data.messaggio, {
                    title: "ATTENZIONE",
                    size: "sm",
                    okVariant: "outline-danger",
                    headerClass: "sa-msg-header-danger",
                    footerClass: "p-2 border-top-0",
                    centered: true,
                })
                .then((value) => {
                    this.boxTwo = value;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        // onSelectUtente(value) {
        //   let me = this;
        //   me.listaUtenti.forEach((element) => {
        //     if (element.usename === value.username) {
        //       value.destinatario = element.cognome + " " + element.nome;
        //     }
        //   });
        // },
        onAddAssegnazione(array, value) {
            if (value.codiceUfficio !== "") {
                array.push({
                    codiceUfficio: "",
                    descrizioneUfficio: "",
                    username: "",
                    oggetto: "",
                });
            } else {
                this.$bvModal
                    .msgBoxOk("Campi OBBLIGATORI!", {
                        title: "ATTENZIONE",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    })
                    .then((value) => {
                        console.log(value);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        onDeleteAssegnazione(array, row) {
            if (row.index != array.length) {
                this.$bvModal
                    .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
                        title: "Titolo",
                        buttonSize: "sm",
                        okVariant: "secondary",
                        cancelVariant: "danger",
                        okTitle: "SI",
                        cancelTitle: "NO",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true,
                    })
                    .then((value) => {
                        if (value) {
                            array.splice(row.index, 1);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        onAddDestinatario(array, value) {
            let me = this;
            me.numero = new Set();
            me.check = me.jsonData.listaDestinatari.some((element) => {
                return me.numero.size === me.numero.add(element.destinatarioMail).size;
            });

            if (!me.check) {
                me.selectedEmail = me.listaRubrica.find((element) => element.email === value.destinatarioMail);

                if (me.selectedEmail) {
                    value.destinatario = me.selectedEmail.cognome + " " + me.selectedEmail.nome;
                    // value.cognome = me.selectedEmail.cognome;
                    value.tipoPersona = me.selectedEmail.tipoPersona;
                    value.tipoPersonaDescrizione = me.selectedEmail.tipoPersona === "F" ? "FISICA" : "GIURIDICA";
                }
            } else {
                this.$bvModal
                    .msgBoxOk("Email già inserita!", {
                        title: "ATTENZIONE",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    })
                    .then((value) => {
                        console.log(value);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                return;
            }
            if ((value.tipoPersona !== "", value.destinatario !== "", value.destinatarioMail !== "")) {
                array.push({ tipoPersona: "", destinatario: "", destinatarioMail: "" });
            } else {
                this.$bvModal
                    .msgBoxOk("Campi OBBLIGATORI!", {
                        title: "ATTENZIONE",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    })
                    .then((value) => {
                        console.log(value);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        onDeleteDestinatario(array, row) {
            if (row.index != array.length) {
                this.$bvModal
                    .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
                        title: "Titolo",
                        buttonSize: "sm",
                        okVariant: "secondary",
                        cancelVariant: "danger",
                        okTitle: "SI",
                        cancelTitle: "NO",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true,
                    })
                    .then((value) => {
                        if (value) {
                            array.splice(row.index, 1);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },

        onSelectTipoPersona(value) {
            let me = this;
            me.tipoPersonaOptions.forEach((element) => {
                if (element.value === value.tipoPersona) {
                    value.tipoPersonaDescrizione = element.text;
                }
            });
        },
        loadDestinatariAssegnazioni() {
            let me = this;
            me.jsonData = {};
            let link = process.env.VUE_APP_PATH_API + me.pathResourceDestinatariAssegnazioni + "/" + me.idIndagine;

            UtilityMixin.methods.restCallGet(link, null, me.loadDestinatariAssegnazioniResponse, me.loadDestinatariAssegnazioniResponseError);
        },
        loadDestinatariAssegnazioniResponse(response) {
            let me = this;
            me.jsonData = response.data.data;
            me.jsonData.listaAssegnazioni.push({
                codiceUfficio: "",
                descrizioneUfficio: "",
                username: "",
                oggetto: "",
            });
            me.jsonData.listaDestinatari.push({
                tipoPersona: "",
                tipoPersonaDescrizione: "",
                destinatario: "",
                destinatarioMail: "",
            });
        },
        loadDestinatariAssegnazioniResponseError(error) {
            let me = this;
            console.log(error);
            me.jsonData.listaAssegnazioni.push({
                codiceUfficio: "",
                descrizioneUfficio: "",
                username: "",
                oggetto: "",
            });
            me.jsonData.listaDestinatari.push({
                tipoPersona: "",
                tipoPersonaDescrizione: "",
                destinatario: "",
                destinatarioMail: "",
            });
            me.jsonData.idDocumento = me.idIndagine;
        },
        loadListaRubrica() {
            let me = this;
            me.listaRubrica = [];
            me.rubricaOptions = [];
            me.listaDestinatariRubrica = [];
            let link = process.env.VUE_APP_PATH_API + me.pathResourceListaRubrica;
            UtilityMixin.methods.restCallGet(link, null, me.loadloadListaRubricaResponse, me.loadListaRubricaResponseError);
        },
        loadloadListaRubricaResponse(response) {
            let me = this;
            me.listaRubrica = response.data.data.list;
            me.rubricaOptions = response.data.data.list;
            me.listaDestinatariRubrica.push({
                tipoPersona: "",
                nome: "",
                cognome: "",
                destinatarioMail: "",
            });
        },
        loadListaRubricaResponseError(error) {
            console.log(error);
            this.$bvModal
                .msgBoxOk(error.response.data.messaggio, {
                    title: "ATTENZIONE",
                    size: "sm",
                    okVariant: "outline-danger",
                    headerClass: "sa-msg-header-danger",
                    footerClass: "p-2 border-top-0",
                    centered: true,
                })
                .then((value) => {
                    this.boxTwo = value;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        /*  onSelectEmail(value) {
      let me = this;
      me.numero = new Set();
      me.check = me.listaDestinatariRubrica.some((element) => {
        return me.numero.size === me.numero.add(element.email).size;
      });

      if (!me.check) {
        me.selectedEmail = me.listaRubrica.find(
          (element) => element.email === value.email
        );

        if (me.selectedEmail) {
          value.nome = me.selectedEmail.nome;
          value.cognome = me.selectedEmail.cognome;
          value.tipoPersona = me.selectedEmail.tipoPersona;
        }
      } else {
        this.$bvModal
          .msgBoxOk("Email già inserita!", {
            title: "ATTENZIONE",
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "sa-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
          })
          .then((value) => {
            console.log(value);
          })
          .catch((err) => {
            console.log(err);
          });
        return;
      }
    }, */
    },
};
</script>

<style></style>
