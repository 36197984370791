<template>
    <div>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card sa-card-border" header="Cute" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Colorito</label>
                            <b-form-select v-model="jsonDataCute.colorito" :options="coloritoOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Integrità</label>
                            <b-form-select v-model="jsonDataCute.integrita" :options="integritaOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Descrizione Integrità</label>
                            <b-form-input v-model="jsonDataCute.integritaDescrizione" :readonly="isReadOnly"> </b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Edemi</label>
                            <b-form-select v-model="jsonDataCute.edemi" :options="edemiOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Edemi presenti: </label>
                            <b-row>
                                <b-col cols="auto" v-for="edemi in edemiDettagliOptions" :key="'edemi-' + edemi.id" class="sa-switch-select-element">
                                    <b-form-checkbox switch :disabled="isReadOnly || jsonDataCute.edemi != 'PRESENTI'" v-model="selectedEdemi" :value="edemi.voce">
                                        <span style="font-weight: normal">{{ edemi.voce }}</span>
                                    </b-form-checkbox>
                                </b-col>
                            </b-row>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Note</label>
                            <b-form-textarea v-model="jsonDataCute.noteCute" :readonly="isReadOnly" max-rows="10" rows="7"> </b-form-textarea>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import UtilityMixin from "../../../utility/UtilityMixin";

export default {
    props: {
        isReadOnly: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        // Resetto edemiOptions quando cambio il valore di "edemi" !== presenti
        "jsonDataCute.edemi": function (newVal) {
            if (newVal !== "PRESENTI") {
                this.selectedEdemi = [];
            }
        },
    },
    mounted() {
        //console.log("Mounted Cute, idAccettazione:", this.idAccettazione);
        let me = this;
        me.loadDizionarioData();
        me.idAccettazione = me.$route.query.idPaziente;
    },
    mixins: [UtilityMixin],

    data() {
        return {
            pathResourceSchedaEsameObiettivoGeneraleCute: "/rsaschedaesameobiettivogeneralecute",
            pathResourceDizionario: "/rsadizionario",
            edemiDettagliOptions: [],
            selectedEdemi: [],
            idAccettazione: null,
            jsonDataCute: {
                id: null,
                idAccettazione: null,
                colorito: null,
                integrita: null,
                integritaDescrizione: null,
                edemi: null,
                noteCute: null,
                listaSchedaCuteDettagli: [],
            },

            coloritoOptions: [
                { text: "NORMOPIGMENTATA", value: "NORMOPIGMENTATA" },
                { text: "PALLIDA", value: "PALLIDA" },
                { text: "ITTERICA", value: "ITTERICA" },
                { text: "BUASTRA PER CIANOSI", value: "BUASTRA PER CIANOSI" },
            ],
            integritaOptions: [
                { text: "INTEGRA", value: "INTEGRA" },
                { text: "CON PRESENZA DI LESIONI", value: "CON PRESENZA DI LESIONI" },
            ],
            edemiOptions: [
                { text: "ASSENTI", value: "ASSENTI" },
                { text: "LIEVI", value: "LIEVI" },
                { text: "PRESENTI", value: "PRESENTI" },
            ],
        };
    },
    methods: {
        loadCuteData() {
            let me = this;
            // console.log("ID accettazione nel loadCuteData: " + me.idAccettazione);
            me.selectedEdemi = [];
            me.loadDizionarioData();
            UtilityMixin.methods.loadDataResources(me.pathResourceSchedaEsameObiettivoGeneraleCute + "/accettazione/" + me.idAccettazione, {}, me.resultResponseSchedaEsameObiettivoGeneraleCuteSuccess, me.resultResponseSchedaEsameObiettivoGeneraleCuteError);
        },
        resultResponseSchedaEsameObiettivoGeneraleCuteSuccess(response) {
            let me = this;
            me.jsonDataCute = response.data.data;
            me.jsonDataCute.listaSchedaCuteDettagli.forEach((item) => {
                if (item.tipo === "EDEMI") {
                    me.selectedEdemi.push(item.voce);
                }
            });
            // console.log("ID del record CUTE:", me.jsonDataCute.id);
        },
        resultResponseSchedaEsameObiettivoGeneraleCuteError(error) {
            let me = this;
            if (error.response && error.response.status === 404) {
                //console.log("NESSUN record trovato del CUTE per questo id accettazione");
                me.resetForm();
            } else {
                console.error("Errore nel caricamento dati della sezione CUTE:", error);
            }
        },

        loadDizionarioData() {
            let me = this;
            UtilityMixin.methods.loadDataResources(me.pathResourceDizionario, null, me.loadDizionarioDataSuccess, me.loadDizionarioDataError);
        },
        loadDizionarioDataSuccess(response) {
            let me = this;
            if (response.data?.data?.list) {
                me.edemiDettagliOptions = response.data.data.list.filter((item) => item.tipo === "EDEMI");
            }
        },
        loadDizionarioDataError(error) {
            console.error("Errore nel caricamento delle reazioni di difesa", error);
        },

        resetForm() {
            let me = this;
            me.jsonDataCute = {
                id: null,
                idAccettazione: me.idAccettazione,
                colorito: null,
                integrita: null,
                integritaDescrizione: null,
                edemi: null,
                noteCute: null,
                listaSchedaCuteDettagli: [],
            };
            me.selectedEdemi = [];
        },
        onSalvaCute() {
            let me = this;
            me.jsonDataCute.idAccettazione = me.idAccettazione;
            //console.log("Salvataggio CUTE con dati:", JSON.stringify(me.jsonDataCute));
            if (!me.jsonDataCute.idAccettazione) {
                // console.log("idAccettazione: ", me.jsonDataCute.idAccettazione);
                me.$bvToast.toast("Errore: ID Accettazione mancante", {
                    title: "Errore",
                    variant: "danger",
                    solid: true,
                });
                return;
            }

            let listaSchedaCuteDettagli = [];
            me.selectedEdemi.forEach((element) => {
                listaSchedaCuteDettagli.push({
                    id: null,
                    idSchedaEsameObiettivoGeneraleCute: me.jsonDataCute.id,
                    tipo: "EDEMI",
                    voce: element,
                });
            });
            me.jsonDataCute.listaSchedaCuteDettagli = listaSchedaCuteDettagli;

            UtilityMixin.methods.saveResource(me.pathResourceSchedaEsameObiettivoGeneraleCute, me.jsonDataCute, me.saveSchedaEsameObiettivoGeneraleCuteSuccessResponse, me.saveSchedaEsameObiettivoGeneraleCuteErrorResponse);
        },
        saveSchedaEsameObiettivoGeneraleCuteSuccessResponse() {
            //console.log("CUTE salvato con successo:", response);
        },
        saveSchedaEsameObiettivoGeneraleCuteErrorResponse() {
            console.error("Errore nel salvataggio CUTE:");
        },
    },
};
</script>
