<template>
  <div class="form-group">
    <b-row>
      <b-col xs="12" sm="12" md="12" lg="12" xl="12">
        <label class="sa-form-label" for="jsonData-denominazione"
          >Denominazione</label
        >
        <b-input-group class="mb-2">
          <b-input-group-prepend is-text>
            <b-icon icon="diagram-3"></b-icon>
          </b-input-group-prepend>
          <b-form-input
            id="jsonData-denominazione"
            v-model="jsonData.denominazione"
            placeholder="Denominazione"
            type="text"
          ></b-form-input>
        </b-input-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
        <label class="sa-form-label" for="jsonData-codiceFiscale"
          >Codice Fiscale</label
        >
        <b-input-group class="mb-2">
          <b-input-group-prepend is-text>
            <b-icon icon="person-fill"></b-icon>
          </b-input-group-prepend>
          <b-form-input
            id="jsonData-codiceFiscale"
            v-model="jsonData.codiceFiscale"
            placeholder="Codice Fiscale "
            type="text"
          ></b-form-input>
        </b-input-group>
      </b-col>
      <b-col xs="12" sm="6" md="6" lg="6" xl="6">
        <label class="sa-form-label" for="jsonData-partita-iva"
          >P. I.V.A.</label
        >
        <b-input-group class="mb-2">
          <b-input-group-prepend is-text>
            <b-icon icon="tag"></b-icon>
          </b-input-group-prepend>
          <b-form-input
            id="jsonData-partita-iva"
            v-model="jsonData.partitaIva"
            placeholder="Partita Iva"
            type="text"
          ></b-form-input>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-form-label" for="jsonData-regione">Regione</label>
        <b-input-group class="mb-2">
          <b-input-group-prepend is-text>
            <b-icon icon="door-closed-fill"></b-icon
          ></b-input-group-prepend>
          <b-form-select
            id="jsonData-regione"
            v-model="jsonData.regione"
            class="mb-2 mr-sm-2 mb-sm-0"
            placeholder="Regione"
            :value="null"
            :options="regioniOptions"
            text-field="regione"
            value-field="codiceIstat"
          ></b-form-select>
        </b-input-group>
      </b-col>
      <b-col xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-form-label" for="jsonData-provincia">Provincia</label>
        <b-input-group class="mb-2">
          <b-input-group-prepend is-text>
            <b-icon icon="building"></b-icon
          ></b-input-group-prepend>
          <b-form-select
            id="jsonData-provincia"
            v-model="jsonData.provincia"
            :options="provinceOptions"
            placeholder="Provincia"
            :value="null"
            text-field="provincia"
            value-field="sigla"
            @change="onProvinciaInput(jsonData.provincia)"
          ></b-form-select>
        </b-input-group>
      </b-col>
      <b-col xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-form-label" for="jsonData-comune">Comune</label>
        <b-input-group class="mb-2">
          <b-input-group-prepend is-text>
            <b-icon icon="building"></b-icon
          ></b-input-group-prepend>
          <b-form-select
            id="jsonData-comune"
            v-model="jsonData.comuneCodiceIstat"
            placeholder="Comune"
            class="mb-2 mr-sm-2 mb-sm-0"
            :value="null"
            :options="comuniOptions"
            text-field="comune"
            value-field="codiceIstat"
            @input="onComuneInput"
          ></b-form-select>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-form-label" for="jsonData-indirizzo">Indirizzo</label>
        <b-input-group class="mb-2">
          <b-input-group-prepend is-text>
            <b-icon icon="tag"></b-icon>
          </b-input-group-prepend>
          <b-form-input
            id="jsonData-indirizzo"
            v-model="jsonData.indirizzo"
            placeholder="Indirizzo"
            type="text"
          ></b-form-input>
        </b-input-group>
      </b-col>
      <b-col xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-form-label" for="jsonData-cap">C.A.P</label>
        <b-input-group class="mb-2">
          <b-input-group-prepend is-text>
            <b-icon icon="sort-numeric-down-alt"></b-icon>
          </b-input-group-prepend>
          <b-form-input
            id="jsonData-cap"
            v-model="jsonData.cap"
            placeholder="C.A.P"
            type="text"
          ></b-form-input>
        </b-input-group>
      </b-col>
      <!-- <b-col xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-form-label" for="jsonData-localita">Localita</label>
        <b-input-group class="mb-2">
          <b-input-group-prepend is-text>
            <b-icon icon="door-closed-fill"></b-icon
          ></b-input-group-prepend>
          <b-form-input
            id="jsonData-localita"
            placeholder="Localita"
            v-model="jsonData.localita"
            type="text"
          ></b-form-input>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row> -->
      <b-col xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-form-label" for="jsonData-telefono">Telefono</label>
        <b-input-group class="mb-2">
          <b-input-group-prepend is-text>
            <b-icon icon="phone"></b-icon
          ></b-input-group-prepend>
          <b-form-input
            id="jsonData-telefono"
            placeholder="Telefono"
            v-model="jsonData.telefono"
            type="text"
          ></b-form-input>
        </b-input-group>
      </b-col>
      <b-col xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-form-label" for="jsonData-fax">Fax</label>
        <b-input-group class="mb-2">
          <b-input-group-prepend is-text>
            <b-icon icon="tag"></b-icon>
          </b-input-group-prepend>
          <b-form-input
            id="jsonData-fax"
            v-model="jsonData.fax"
            placeholder="Fax"
            type="text"
          ></b-form-input>
        </b-input-group>
      </b-col>
      <b-col xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-form-label" for="jsonData-email">Email</label>
        <b-input-group class="mb-2">
          <b-input-group-prepend is-text>
            <b-icon icon="person-fill"></b-icon>
          </b-input-group-prepend>
          <b-form-input
            id="jsonData-email"
            v-model="jsonData.email"
            placeholder="Email"
            type="text"
          ></b-form-input>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col xs="12" sm="12" md="12" lg="12" xl="12">
        <label class="sa-form-label" for="jsonData-url">Url</label>
        <b-input-group class="mb-2">
          <b-input-group-prepend is-text>
            <b-icon icon="door-closed-fill"></b-icon
          ></b-input-group-prepend>
          <b-form-input
            id="jsonData-url"
            v-model="jsonData.url"
            placeholder="Url"
            type="text"
          ></b-form-input>
        </b-input-group>
      </b-col>
      <b-col xs="12" sm="12" md="12" lg="12" xl="12">
        <label class="sa-form-label" for="jsonData-codice-struttura"
          >Certificato Camerale</label
        >
        <b-input-group class="mb-2">
          <b-input-group-prepend is-text>
            <b-icon icon="door-closed-fill"></b-icon
          ></b-input-group-prepend>
          <b-form-file
            v-model="certificatoCamerale"
            :state="Boolean(certificatoCamerale)"
            placeholder="Rilasciare Certificato Camerale qui.."
            drop-placeholder="Rilasciare qui.."
            @input="onInputFileUpload(certificatoCamerale)"
          ></b-form-file>
        </b-input-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import UtilityMixins from "../../utility/UtilityMixin";
import axios from "axios";
export default {
  mixins: [UtilityMixins],

  props: {
    jsonData: {
      type: Object,
      default: function () {
        return {
          certificatoCamerale: null,
          //localita: null,
        };
      },
    },
  },
  mounted() {
    let me = this;
    me.loadDefaultData();
  },
  data() {
    return {
      user: {},
      certificatoCamerale: null,
      sessoOptions: [
        { text: " - Selezionare - ", value: null },
        { text: "Maschio", value: "M" },
        { text: "Femmina", value: "F" },
      ],
      regioniOptions: [],
      comuniOptions: [{ comune: "- Seleziona Comune -", codiceIstat: null }],
      provinceOptions: [],

      tipoIdentificativoOptions: [
        { text: "- Seleziona Tipo Identificativo' -", value: null },
        { text: "Carta D'Identita'", value: "CI" },
        { text: "Codice Fiscale", value: "CF" },
        { text: "Passaporto", value: "PS" },
      ],
    };
  },
  methods: {
    loadDefaultData() {
      let me = this;
      let linkRegioni = process.env.VUE_APP_PATH_API + "/regioni";
      axios.get(linkRegioni).then((response) => {
        me.regioniOptions = response.data.data;
        me.regioniOptions.unshift({
          regione: "-Seleziona Regione-",
          codiceIstat: null,
        });
      });
      let linkProvince = process.env.VUE_APP_PATH_API + "/province";
      axios.get(linkProvince).then((response) => {
        response.data.data.forEach((elemento) => {
          me.provinceOptions.push(elemento);
        });
        me.provinceOptions.unshift({
          provincia: "- Seleziona Provincia -",
          sigla: null,
        });
      });
    },
    onInputFileUpload(file) {
      let me = this;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        me.jsonData.certificatoCamerale = e.target.result;
      };
    },
    onProvinciaInput(value) {
      let me = this;
      me.loadComuni(value);
    },
    loadComuni(provincia) {
      let me = this;
      let linkComuni = process.env.VUE_APP_PATH_API + "/comuni";
      axios
        .get(linkComuni, { params: { provincia: provincia } })
        .then((response) => {
          me.comuniOptions = response.data.data;
        });
    },
    onComuneInput(value) {
      let me = this;
      me.loadCAP(value);
    },
    loadCAP(codiceIstatComune) {
      let me = this;
      me.comuniOptions.forEach((element) => {
        if (element.codiceIstat === codiceIstatComune) {
          me.jsonData.cap = element.cap;
        }
      });
    },
  },
};
</script>

<style></style>
