import Prenotazioni from "../";

const routes = [
  {
    name: "LeMiePrenotazioniEdit",
    path: "/lemieprenotazioni/edit/:id",
    component: Prenotazioni.LeMiePrenotazioniEdit,
  },
  {
    name: "LeMiePrenotazioniEditMultiplo",
    path: "/lemieprenotazionimultiplo/edit/:id",
    component: Prenotazioni.LeMiePrenotazioniEditMultiplo,
  },
  {
    name: "LeMiePrenotazioniList",
    path: "/lemieprenotazioni",
    component: Prenotazioni.LeMiePrenotazioniList,
  },
  {
    name: "LeMiePrenotazioniView",
    path: "/lemieprenotazioni/view/:id",
    component: Prenotazioni.leMiePrenotazioniView,
  },
  {
    name: "PrenotazioniList",
    path: "/altreprenotazioni",
    component: Prenotazioni.PrenotazioniList,
  },
  {
    name: "PrenotazioniEdit",
    path: "/altreprenotazioni/edit/:id",
    component: Prenotazioni.PrenotazioniEdit,
  },
  {
    name: "PrenotazioniView",
    path: "/altreprenotazioni/view/:id",
    component: Prenotazioni.PrenotazioniView,
  },
];

export default routes;
