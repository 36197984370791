<template>
    <sa-page-layout :btnBackVisible="true" :linkback="linkback" :data="jsonData" :showModalLoading="showModalLoading" :pathResource="pathResource" class="sa-no-space">
        <template slot="table-body">
            <cot-pua-sede-view-component :showModalLoading="showModalLoading" @update="onUpdateJsonData" @beforeLoadData="onBeforeLoadData" @afterLoadData="onAfterLoadData"></cot-pua-sede-view-component>
        </template>
    </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import CotPuaSedeViewComponent from "../components/CotPuaSedeViewComponent.vue";
export default {
    components: { SaPageLayout, CotPuaSedeViewComponent },
    data() {
        return {
            showModalLoading: false,
            pathResource: "/cotpuasedi",
            linkback: "",
            jsonData: {},
        };
    },
    methods: {
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
            me.linkback = "/cot/pua/view/" + me.jsonData.idCotPua;
        },
        onBeforeLoadData() {},
        onAfterLoadData() {},
    },
};
</script>

<style></style>
