<template>
	<div style="height: 100%; overflow: hidden">
		<sa-tabs-scroll-componentVue idTab="tabsRsaOspitiView" classNavItem="nav-item"></sa-tabs-scroll-componentVue>
		<!-- <b-tabs id="tabsSchede" nav-wrapper-class="sa-tab-items" content-class="mt-3" ref="tabs" class="sa-tab" style="height: 100%; padding-bottom: 25px" fill @input="onTabInput"></b-tabs> -->
		<b-tabs id="tabsRsaOspitiView" ref="tabsRsaOspitiView" v-model="activeTabIndex" nav-wrapper-class="sa-tab-items" content-class="mt-3" class="sa-tab" style="height: 100%; padding-bottom: 25px" fill @input="onInputTabs">
			<b-tab id="tbRsaSchedaOspite" title="Scheda Ospite" v-if="false">
				<!-- tbSchedaOspiteIsVisible -->
				<rsa-accettazione-anagrafica-view-component ref="cmpRsaAccettazioneAnagraficaViewComponent"></rsa-accettazione-anagrafica-view-component>
			</b-tab>
			<b-tab id="tbRsaEquipe" title="Equipe" v-if="true">
				<!-- tbEquipeIsVisible -->
				<rsa-equipe-component></rsa-equipe-component>
			</b-tab>
			<b-tab id="tbRsaValutazioneIngresso" title="Valutazione d'ingresso" v-if="false">
				<!-- tbRsaValutazioneIngresso -->
				<rsa-valutazione-ingresso-component></rsa-valutazione-ingresso-component>
			</b-tab>
			<b-tab id="tbRsaScaleIngresso" title="Scale d'ingresso" hidden="true" v-if="false">
				<!-- tbRsaScaleIngresso -->
				<rsa-scale-valutazione-component></rsa-scale-valutazione-component>
			</b-tab>
			<b-tab id="tbRsaPai" title="P.A.I." v-if="false">
				<!-- tbRsaPai -->
				<rsa-pai-list-component ref="cmpRsaPaiListComponent"></rsa-pai-list-component>
			</b-tab>
			<b-tab id="tbRsaDiario" title="Diario" lazy v-if="false">
				<!-- tbRsaDiario -->
				<rsa-diario-component
					:jsonDataAccettazione="jsonDataAccettazione"
					@beforeLoadData="onBeforeLoadDataSchedaOspiteRefertiComponent"
					@afterLoadData="onAfterLoadDataSchedaOspiteRefertiComponent"
					@beforeSaveRequest="onBeforeSaveRequestSchedaOspiteRefertiComponent"
					@afterSaveRequest="onAfterSaveRequestSchedaOspiteRefertiComponent"
				></rsa-diario-component>
			</b-tab>
			<b-tab id="tbRsaSchedaOspitePrescrizioni" title="Prescrizioni" v-if="true"
				><!-- tbRsaSchedaOspitePrescrizioni -->
				<rsa-gestione-ospite-prescrizioni-list-component ref="cmpRsaGestioneOspitePrescrizioniListComponent" :jsonDataAccettazione="jsonDataAccettazione"></rsa-gestione-ospite-prescrizioni-list-component>
			</b-tab>
			<b-tab id="tbRsaTerapia" title="Terapia" v-if="true">
				<!-- tbRsaTerapia -->
				<rsa-terapia-component></rsa-terapia-component>
			</b-tab>
			<b-tab id="tbRsaAree" title="Aree" lazy v-if="false">
				<!-- tbRsaAree -->
				<rsa-paziente-aree-component
					:jsonDataAccettazione="jsonDataAccettazione"
					@beforeLoadData="onBeforeLoadDataSchedaOspiteRefertiComponent"
					@afterLoadData="onAfterLoadDataSchedaOspiteRefertiComponent"
					@beforeSaveRequest="onBeforeSaveRequestSchedaOspiteRefertiComponent"
					@afterSaveRequest="onAfterSaveRequestSchedaOspiteRefertiComponent"
				></rsa-paziente-aree-component>
			</b-tab>
			<b-tab id="tbRsaPrestazioni" title="Prestazioni" v-if="true">
				<!-- tbRsaPrestazioni -->
				<rsa-prestazioni-component></rsa-prestazioni-component>
			</b-tab>
			<b-tab id="tbRsaSchedaOspiteParametriVitali" title="Parametri Vitali" v-if="true">
				<!-- tbRsaSchedaOspiteParametriVitali -->
				<rsa-parametri-vitali-component></rsa-parametri-vitali-component>
			</b-tab>
			<b-tab id="tbRsaReferti" title="Referti" lazy v-if="false">
				<!-- tbRsaSchedaOspiteReferti -->
				<rsa-scheda-ospite-referti-component
					ref="cmpRsaSchedaOspiteRefertiComponent"
					:jsonDataAccettazione="jsonDataAccettazione"
					@beforeLoadData="onBeforeLoadDataSchedaOspiteRefertiComponent"
					@afterLoadData="onAfterLoadDataSchedaOspiteRefertiComponent"
					@beforeSaveRequest="onBeforeSaveRequestSchedaOspiteRefertiComponent"
					@afterSaveRequest="onAfterSaveRequestSchedaOspiteRefertiComponent"
				></rsa-scheda-ospite-referti-component>
			</b-tab>
			<b-tab id="tbRsaUscite" title="Uscite" lazy v-if="false" ref="tbRsaUscite">
				<!-- tbRsaSchedaOspiteUsciteIsVisible -->
				<rsa-scheda-ospite-uscite-list-component
					:jsonDataAccettazione="jsonDataAccettazione"
					@beforeLoadData="onBeforeLoadDataSchedaOspiteUsciteComponent"
					@afterLoadData="onAfterLoadDataSchedaOspiteUsciteComponent"
					@beforeSaveRequest="onBeforeSaveRequestSchedaOspiteUsciteComponent"
					@afterSaveRequest="onAfterSaveRequestSchedaOspiteUsciteComponent"
				></rsa-scheda-ospite-uscite-list-component>
			</b-tab>
			<b-tab id="tbRsaTrattamentiRiabilitativi" title="Trattamenti riabilitativi" v-if="true">
				<!-- tbRsaTrattamentiRiabilitativi -->
				<rsa-trattamenti-riabilitativi-component></rsa-trattamenti-riabilitativi-component>
			</b-tab>
			<b-tab id="tbRsaMagazzino" title="Magazzino" v-if="true">
				<!-- tbRsaMagazzino -->
				<rsa-magazzino-component></rsa-magazzino-component>
			</b-tab>
			<b-tab id="tbRsaDimissioni" title="Dimissione/Trasferimento" lazy v-if="false">
				<!-- tbRsaDimissioni -->
				<rsa-scheda-ospite-dimissione-trasferimento-component
					ref="cmpRsaSchedaOspiteDimissioneTrasferimentoComponent"
					:jsonDataAccettazione="jsonDataAccettazione"
					@beforeLoadData="onBeforeLoadDataSchedaOspiteRefertiComponent"
					@afterLoadData="onAfterLoadDataSchedaOspiteRefertiComponent"
				></rsa-scheda-ospite-dimissione-trasferimento-component>
			</b-tab>
			<b-tab id="tbRsaStoricoRicoveri" title="Storico ricoveri" v-if="false">
				<!-- tbRsaStoricoRicoveri -->
				<rsa-storico-ricoveri-component></rsa-storico-ricoveri-component>
			</b-tab>
			<b-tab id="tbValutazioneIngresso" title="Valutazione All'Ingresso" v-if="false">
				<rsa-accettazione-schede-valutazione-ingresso-component ref="cmpRsaAccettazioneSchedeValutazioneIngressoComponent"></rsa-accettazione-schede-valutazione-ingresso-component>
			</b-tab>
			<b-tab id="tbScaleValutazione" title="Scale Valutazione" v-if="false">
				<rsa-accettazione-schede-valutazione-component ref="cmpRsaAccettazioneSchedeValutazioneComponent"></rsa-accettazione-schede-valutazione-component>
			</b-tab>
			<b-tab id="tbImpegnativa" title="Impegnativa" v-if="false">
				<rsa-accettazione-impegnativa-list-component ref="cmpRsaAccettazioneImpegnativaListComponent" :key="id" :idAccettazione="id" :jsonData="jsonDataAccettazione"> </rsa-accettazione-impegnativa-list-component>
			</b-tab>

			<b-tab id="tbRsaPersoneRiferimento" title="Persona di Riferimento" v-if="false">
				<rsa-persona-riferimento-component ref="cmpRsaPersonaRiferimentoComponent" :jsonData="jsonDataAccettazione"></rsa-persona-riferimento-component>
			</b-tab>

			<b-tab id="tbRsaSp./RsaPazienteAreeComponent.vue extra" v-if="false">
				<rsa-spesa-extra-list-component :key="id" :is-view-mode="false" :idAccettazione="id" ref="cmpRsaSpeseExtraList"> </rsa-spesa-extra-list-component>
			</b-tab>
		</b-tabs>
	</div>
</template>
<script>
import RsaPersonaRiferimentoComponent from "../../components/RsaPersonaRiferimentoComponent.vue";
import RsaAccettazioneImpegnativaListComponent from "../../rsaaccettazione/components/RsaAccettazioneImpegnativaListComponent.vue";
import RsaEquipeComponent from "./RsaEquipeComponent.vue";
import RsaSpesaExtraListComponent from "../../rsaaccettazione/components/RsaSpesaExtraListComponent.vue";
import RsaValutazioneIngressoComponent from "../../components/RsaValutazioneIngressoComponent.vue";
import RsaScaleValutazioneComponent from "./RsaScaleValutazioneComponent.vue";
//import RsaPaiComponent from "./RsaPaiEditComponent.vue";
import RsaPaiListComponent from "./RsaPaiListComponent.vue";
import RsaDiarioComponent from "./RsaDiarioComponent.vue";
import RsaTerapiaComponent from "./RsaTerapiaComponent.vue";
import RsaPazienteAreeComponent from "./RsaPazienteAreeComponent.vue";
import RsaPrestazioniComponent from "./RsaPrestazioniComponent.vue";
import RsaParametriVitaliComponent from "./RsaParametriVitaliComponent.vue";
import RsaSchedaOspiteRefertiComponent from "./RsaSchedaOspiteRefertiComponent.vue";
import RsaTrattamentiRiabilitativiComponent from "./RsaTrattamentiRiabilitativiComponent.vue";
import RsaMagazzinoComponent from "./RsaMagazzinoComponent.vue";
import RsaSchedaOspiteDimissioneTrasferimentoComponent from "./RsaSchedaOspiteDimissioneTrasferimentoComponent.vue";
import RsaStoricoRicoveriComponent from "./RsaStoricoRicoveriComponent.vue";
import RsaGestioneOspitePrescrizioniListComponent from "./prescrizioni/RsaGestioneOspitePrescrizioniListComponent.vue";
import SaTabsScrollComponentVue from "../../../../template/components/utility/SaTabsScrollComponent.vue";
import RsaAccettazioneAnagraficaViewComponent from "../../rsaaccettazione/components/RsaAccettazioneAnagraficaViewComponent.vue";
import UtilityMixin from "../../../utility/UtilityMixin";
import RsaSchedaOspiteUsciteListComponent from "./RsaSchedaOspiteUsciteListComponent.vue";
import RsaAccettazioneSchedeValutazioneComponent from "../../rsaaccettazione/components/RsaAccettazioneSchedeValutazioneComponent.vue";
import RsaAccettazioneSchedeValutazioneIngressoComponent from "../../rsaaccettazione/components/RsaAccettazioneSchedeValutazioneIngressoComponent.vue";
export default {
	props: {
		utente: { type: Object },
		jsonDataAccettazione: { type: Object },
	},
	components: {
		RsaPersonaRiferimentoComponent,
		RsaAccettazioneImpegnativaListComponent,
		RsaSpesaExtraListComponent,
		RsaEquipeComponent,
		RsaValutazioneIngressoComponent,
		RsaAccettazioneSchedeValutazioneComponent,
		RsaAccettazioneSchedeValutazioneIngressoComponent,
		RsaScaleValutazioneComponent,
		RsaPaiListComponent,
		RsaDiarioComponent,
		RsaTerapiaComponent,
		RsaPazienteAreeComponent,
		RsaPrestazioniComponent,
		RsaParametriVitaliComponent,
		RsaSchedaOspiteRefertiComponent,
		RsaSchedaOspiteUsciteListComponent,
		RsaTrattamentiRiabilitativiComponent,
		RsaMagazzinoComponent,
		RsaStoricoRicoveriComponent,
		RsaSchedaOspiteDimissioneTrasferimentoComponent,
		RsaGestioneOspitePrescrizioniListComponent,
		SaTabsScrollComponentVue,
		RsaAccettazioneAnagraficaViewComponent,
	},
	data() {
		return {
			tbSchedaOspiteIsVisible: false,
			tbEquipeIsVisible: true,
			tbRsaValutazioneIngresso: true,
			tbRsaScaleIngresso: true,
			tbRsaPai: true,
			tbRsaDiario: true,
			tbRsaSchedaOspitePrescrizioni: true,
			tbRsaTerapia: true,
			tbRsaAree: true,
			tbRsaPrestazioni: true,
			tbRsaSchedaOspiteParametriVitali: true,
			tbRsaSchedaOspiteReferti: true,
			tbRsaSchedaOspiteUsciteIsVisible: true,
			tbRsaTrattamentiRiabilitativi: true,
			tbRsaMagazzino: true,
			tbRsaDimissioni: true,
			tbRsaStoricoRicoveri: true,
			activeTabIndex: 0,
			jsonData: {
				codiceStruttura: null, //non a video
				idPresidio: null, //non a video
				numero: null, //non a video
				note: null, //non a video
				regime: null, //non a video
				settingCodice: null,
				settingDescrizione: "",
				idPrenotazione: null, //non a video
				accettazioneStruttura: {}, //non a video
				accettazioneAnagrafica: {
					idAccettazione: null, //non a video
					tipo: null, //non a video
					identificativo: null,
					tipoIdentificativo: "CF", //non a video
					cognome: null,
					nome: null,
					dataNascita: null,
					provinciaNascita: null,
					comuneNascitaCodiceIstat: null,
					sesso: null,
					regioneResidenza: null,
					provinciaResidenza: null,
					comuneResidenzaCodiceIstat: null,
					indirizzoResidenza: null,
					capResidenza: null,
					regioneDomicilio: null,
					provinciaDomicilio: null,
					comuneDomicilioCodiceIstat: null,
					indirizzoDomicilio: null,
					capDomicilio: null,
					note: null,
					medicoCurante: null,
					codiceFiscaleMedicoCurante: null, //non a video
					telefono: null,
					email: null,
					emailMedicoCurante: null,
				},
				accettazioneStati: [],
				accettazionePrestazioni: [],
				accettazioniAsl: {},
			},
			id: null,
		};
	},
	created() {
		console.log("Anche qui ci sono");
		this.impostaPermessi();
	},
	watch: {
		utente() {
			this.impostaPermessi();
		},
	},
	mounted() {
		let me = this;
		me.id = this.$route.params.id;
		// me.loadDataAnagrafica(me.id);
	},
	methods: {
		onInputTabs(index) {
			let me = this;
			let activeTab = this.$refs.tabsRsaOspitiView.getTabs()[index];
			me.activeTab(activeTab.id);
		},
		activeTab(idActiveTab) {
			let me = this;
			// console.log(idActiveTab);
			switch (idActiveTab) {
				case "tbRsaSchedaOspitePrescrizioni":
					this.$refs.cmpRsaGestioneOspitePrescrizioniListComponent.loadPrescrizioniPaziente(this.jsonDataAccettazione);
					break;
				case "tbRsaReferti":
					// console.log("Tab Referti");
					break;
				case "tbValutazioneIngresso":
					me.$refs.cmpRsaAccettazioneSchedeValutazioneIngressoComponent.init(me.jsonDataAccettazione.id);
					break;
				case "tbScaleValutazione":
					me.$refs.cmpRsaAccettazioneSchedeValutazioneComponent.init(me.jsonDataAccettazione.id);
					break;
				case "tbRsaPersoneRiferimento":
					console.log(idActiveTab);
					me.$refs.cmpRsaPersonaRiferimentoComponent.loadData();
					break;
				case "tbRsaPai":
					me.$refs.cmpRsaPaiListComponent.loadData(me.jsonDataAccettazione.id);
					break;

				/* case "tbRsaSpeseExtra":
                    me.$refs.cmpRsaSpeseExtraList.loadData(me.id); */
			}
			this.$emit("onActiveTab", idActiveTab);
		},
		impostaPermessi() {
			let me = this;
			// console.log("Diari ", UtilityMixin.methods.verificaPermessi(this.utente, "rsadiari", 1));

			this.tbSchedaOspiteIsVisible = UtilityMixin.methods.verificaPermessi(this.utente, "anagrafica", 1);
			this.tbEquipeIsVisible = false;
			this.tbRsaValutazioneIngresso = false;
			this.tbRsaScaleIngresso = false;
			this.tbRsaPai = UtilityMixin.methods.verificaPermessi(this.utente, "rsapai", 1);
			this.tbRsaDiario = UtilityMixin.methods.verificaPermessi(this.utente, "rsadiari", 1);
			this.tbRsaSchedaOspitePrescrizioni = UtilityMixin.methods.verificaPermessi(this.utente, "rsapaziente", 1);
			this.tbRsaTerapia = false;
			this.tbRsaAree = UtilityMixin.methods.verificaPermessi(this.utente, "rsaaree", 1);
			this.tbRsaPrestazioni = false;
			this.tbRsaSchedaOspiteParametriVitali = false;
			this.tbRsaSchedaOspiteReferti = UtilityMixin.methods.verificaPermessi(this.utente, "rsarichiestelaboratorio", 1);
			this.tbRsaSchedaOspiteUscite = true; /* UtilityMixin.methods.verificaPermessi(this.utente, "rsauscite", 1); */
			this.tbRsaTrattamentiRiabilitativi = false;
			this.tbRsaMagazzino = false;
			this.tbRsaDimissioni = true; /* UtilityMixin.methods.verificaPermessi(this.utente, "rsadimissionitrasferimenti", 1); */
			this.tbRsaStoricoRicoveri = UtilityMixin.methods.verificaPermessi(this.utente, "rsadimissionitrasferimenti", 1);
			// this.tbSchedaOspiteIsVisible = UtilityMixin.methods.verificaPermessi(this.utente, "anagrafica", 1);
			console.log("Gestione permessi");

			setTimeout(function () {
				me.activeTabIndex = 0;
				me.loadDataAnagrafica(me.id);
			}, 500);
		},
		loadDataAnagrafica(id) {
			let me = this;
			if (me.$refs.cmpRsaAccettazioneAnagraficaViewComponent) {
				me.$refs.cmpRsaAccettazioneAnagraficaViewComponent.setId(id);
			}
		},
		onNuovaRichiestaEsame() {
			let me = this;
			me.$refs.cmpRsaSchedaOspiteRefertiComponent.onNuovaRichiesta();
		},
		onBeforeLoadDataSchedaOspiteRefertiComponent() {
			let me = this;
			me.$emit("beforeLoadDataSchedaOspiteRefertiComponent");
		},
		onAfterLoadDataSchedaOspiteRefertiComponent() {
			let me = this;
			me.$emit("afterLoadDataSchedaOspiteRefertiComponent");
		},
		onBeforeSaveRequestSchedaOspiteRefertiComponent() {
			let me = this;
			me.$emit("beforeSaveRequestSchedaOspiteRefertiComponent");
		},
		onAfterSaveRequestSchedaOspiteRefertiComponent() {
			let me = this;
			me.$emit("afterSaveRequestSchedaOspiteRefertiComponent");
		},
		onBeforeLoadDataSchedaOspiteUsciteComponent() {
			let me = this;
			me.$emit("beforeLoadDataSchedaOspiteUsciteComponent");
		},
		onAfterLoadDataSchedaOspiteUsciteComponent() {
			let me = this;
			me.$emit("afterLoadDataSchedaOspiteUsciteComponent");
		},
		onBeforeSaveRequestSchedaOspiteUsciteComponent() {
			let me = this;
			me.$emit("beforeSaveRequestSchedaOspiteUsciteComponent");
		},
		onAfterSaveRequestSchedaOspiteUsciteComponent() {
			let me = this;
			me.$emit("afterSaveRequestSchedaOspiteUsciteComponent");
		},
		onNuovaRichiestaUscita() {
			let me = this;
			me.$refs.RsaSchedaOspiteUsciteComponent.onAfterLoadData();
		},
	},
};
</script>
