<template>
    <div class="sa-tab-scroll">
        <b-row>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <b-card class="sa-card" header="Dati Evento" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.events.lblEventDate") }}</label>
                            <date-picker format="DD-MM-YYYY hh:mm" value-type="timestamp" v-model="jsonData.dataEvento" type="datetime"></date-picker>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Data Prescrizione</label>
                            <date-picker format="DD-MM-YYYY hh:mm" value-type="timestamp" v-model="jsonData.dataPrescrizione" type="datetime"></date-picker>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Codice Prescrizione</label>
                            <b-form-input v-model="jsonData.codicePrescrizione"></b-form-input>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <b-card class="sa-card" header="Dati Paziente" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblWeight") }}</label>
                            <b-form-input v-model="jsonData.peso" type="number" @input="onCalcoloBmi(jsonData.altezza, jsonData.peso)"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHeight") }}</label>
                            <b-form-input v-model="jsonData.altezza" type="number" @input="onCalcoloBmi(jsonData.altezza, jsonData.peso)"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data ft-color-calculated-fields">
                                <i class="fas fa-calculator"></i>
                                {{ getLabelTraduora("patient.cardiology.lblBMI") }}
                            </label>
                            <b-form-input v-model="jsonData.bmi" readonly></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblWaistCircumference") }}</label>

                            <b-form-input v-model="jsonData.circvita" type="number"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHipsCircumference") }}</label>

                            <b-form-input v-model="jsonData.circfianchi" type="number"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSmoking") }}</label>

                            <b-form-select v-model="jsonData.fumo" :options="fumoOptions" :value="null" value-field="value" text-field="text" @input="onInputFumo"></b-form-select>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>

        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblOnGoingTherapies')" header-tag="header" footer-tag="footer" title="">
            <!-- <b-row :key="keyTerapiaInCorso"> -->
            <b-row class="sa-visita-controllo-ecg-row-custom">
                <b-col v-for="farmaco in listaCategorieFarmaci" :key="farmaco.nome" xs="12" sm="12" md="3" lg="3" xl="3">
                    <paziente-evento-terapia-component :codiceAtc="farmaco.codice" :listaFarmaciSelezionati="listaFarmaciTerapiaInCorso" :listaTerapia="jsonData.listaTerapia" :tipoFarmaco="farmaco.nome" tipo="TERAPIA IN CORSO" @update="onUpdateListaFarmaciTerapiaInCorso"></paziente-evento-terapia-component>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-table ref="tblTerapiaInCorso" sticky-header stacked="xl" striped hover itemscope :items="listaFarmaciTerapiaInCorso" :fields="fieldsListaFarmaciTerapiaInCorso" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="row">
                            {{ row.index + 1 }}
                        </template>
                        <template #cell(actions)="row">
                            <b-button size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(listaFarmaciTerapiaInCorso, row.index)"><b-icon icon="trash"></b-icon></b-button>
                        </template>
                    </b-table>
                </b-col>
            </b-row>
        </b-card>
        <b-row>
            <b-col xs="12" sm="12" md="8" lg="8" xl="8">
                <b-card class="sa-card" header="Dati Terapia" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblFollowsTherapy") }}</label>
                            <b-form-select v-model="jsonData.segueterapia" :options="siNoOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSideEffects") }}</label>
                            <b-form-select v-model="jsonData.effetticollaterali" :options="siNoOptions" :value="null" value-field="value" text-field="text" @input="onInputEffettiCollaterali"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblInfoSideEffects") }}</label>
                            <b-form-input v-model="jsonData.qualieffetti" type="text" :disabled="qualiEffettiCollateraliDisabled"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHeartRecoveryType") }}</label>
                            <b-form-select v-model="jsonData.tipoDiRicoveroCardio" :options="tipiRicoveroOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHeartRecoveryCause") }}</label>
                            <b-form-select v-model="jsonData.causaRicoveroCardio" :options="causeRicoveroOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblExtraRecoveryType") }}</label>
                            <b-form-select v-model="jsonData.tipoDiRicoveroExtra" :options="tipiRicoveroOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblExtraRecoveryCause") }}</label>
                            <b-form-select v-model="jsonData.causaRicoveroExtra" :options="causeRicoveroOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <b-card class="sa-card" header="Valori P.A. a Domicilio" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">P.A.S.</label>
                            <b-form-input v-model="jsonData.pasdomicilio"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">P.A.D.</label>
                            <b-form-input v-model="jsonData.paddomicilio"></b-form-input>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="7" lg="7" xl="7">
                <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblAnamnesys')" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNextAnamnesys") }}</label>
                            <b-form-textarea v-model="jsonData.anamnesiProssima" rows="8"></b-form-textarea>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Esame Obiettivo Generale e Cardiovascolare</label>
                            <b-form-textarea v-model="jsonData.anamnesiGeneraleCardiovascolare" rows="8"></b-form-textarea>
                        </b-col>
                        <!-- <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Relazione Elettrocardiogramma</label>
              <b-form-textarea v-model="jsonData.relazioneElettrocardiogramma" rows="2" no-resize></b-form-textarea>
            </b-col> -->
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="5" lg="5" xl="5">
                <b-card class="sa-card" header="Relazione Elettrocardiogramma" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Ritmo</label>
                            <b-form-input v-model="jsonData.relazioneElettrocardiogrammaRitmo"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Frequenza Cardiaca (b/min)</label>
                            <b-form-input v-model="jsonData.relazioneElettrocardiogrammaFrequenzaCardiaca"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Onda P (msee)</label>
                            <b-form-input v-model="jsonData.relazioneElettrocardiogrammaOndaP"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">P-R (msee)</label>
                            <b-form-input v-model="jsonData.relazioneElettrocardiogrammaPr"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">QRS (msee)</label>
                            <b-form-input v-model="jsonData.relazioneElettrocardiogrammaQrs"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Tratto ST</label>
                            <b-form-input v-model="jsonData.relazioneElettrocardiogrammaTrattoSt"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Conclusioni</label>
                            <b-form-textarea v-model="jsonData.relazioneElettrocardiogrammaConclusioni" rows="2"></b-form-textarea>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card" header="Dati Clinici" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                            <label class="sa-label-data">Classe NYHA</label>
                            <b-form-select v-model="jsonData.nyha" :options="nyhaOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                            <label class="sa-label-data">Stadio Scompenso</label>
                            <b-form-select v-model="jsonData.stadioScompenso" :options="stadioScompensoOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                            <label class="sa-label-data">Polsi Arteriosi</label>
                            <b-form-select v-model="jsonData.polsi" :options="polsiArteriosiOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Elettrocardiogramma</label>
                            <b-form-select v-model="jsonData.ecgIvs" :options="ecgOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Attivita Fisica</label>
                            <b-form-select v-model="jsonData.attFisica" :options="attivitaFisicaOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <!-- <b-card class="sa-card" header="FRAMINGHAM CRITERIA: MAJOR CRITERIA" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Paroxysmal Nocturnal Dyspnea</label>
                    <b-form-select v-model="jsonData.paroxysmalNocturnalDyspnea" :options="siNoOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Neck Vein Distention</label>
                    <b-form-select v-model="jsonData.neckVeinDistention" :options="siNoOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Crackles/Rales</label>
                    <b-form-select v-model="jsonData.cracklesRales" :options="siNoOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Acute Pulmonary Edema</label>
                    <b-form-select v-model="jsonData.acutePulmonaryEdema" :options="siNoOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">S3 Gallop</label>
                    <b-form-select v-model="jsonData.s3Gallop" :options="siNoOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Weight Loss >4.5 kg in response to CHF reatment</label>
                    <b-form-select v-model="jsonData.weightLossMayorFramingham" :options="siNoOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Central Venous Pressure Greater >16cm Water</label>
                    <b-form-select v-model="jsonData.centralVenousPressure16" :options="siNoOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Echoenocardiography left ventricular dysfunction</label>
                    <b-form-select v-model="jsonData.leftVentricularDysfunction" :options="siNoOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                </b-col>
            </b-row>
        </b-card>

        <b-card class="sa-card" header="FRAMINGHAM CRITERIA: MINOR CRITERIA" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Bilateral ankle edema</label>
                    <b-form-select v-model="jsonData.bilateralAnkleEdema" :options="siNoOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Pleural effusion</label>
                    <b-form-select v-model="jsonData.pleuralEffusion" :options="siNoOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Night Cough</label>
                    <b-form-select v-model="jsonData.nightCough" :options="siNoOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Dyspnea on exertion</label>
                    <b-form-select v-model="jsonData.dyspneaOnExertion" :options="siNoOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Hepatomegaly</label>
                    <b-form-select v-model="jsonData.hepatomegaly" :options="siNoOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Tachycardia (Heart rate > 120bpm)</label>
                    <b-form-select v-model="jsonData.tachycardia120" :options="siNoOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Weight Loss >4.5</label>
                    <b-form-select v-model="jsonData.weightLossMinorFramingham" :options="siNoOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                </b-col>
            </b-row>
        </b-card> -->

        <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <b-card class="sa-card" header="P.A. IN CLINOSTATISMO" header-tag="header" footer-tag="footer" title="">
                    <template #header>
                        <b-row>
                            <b-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9">
                                <span>P.A. IN CLINOSTATISMO</span>
                            </b-col>
                            <b-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                                <b-button variant="outline-secondary outline-secondary-noborder pull-right" size="sm" @click="onClickCalcolaValoriPaClino">
                                    <i style="margin-top: 2px" class="fas fa-calculator"></i>
                                    <span style="padding-left: 5px">Calcola</span>
                                </b-button>
                            </b-col>
                        </b-row>
                    </template>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <pressione-sanguigna-component :items="jsonData.listaPressioneClino" :tipoMisurazione="tipoMisurazioneClino" :isEdit="true" @update="onUpdatePressioniClinostatismo"></pressione-sanguigna-component>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Media P.A. Sis.</label>
                            <b-form-input v-model="jsonData.paclinomaxm" disabled></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Media P.A. Dias.</label>
                            <b-form-input v-model="jsonData.paclinominm" disabled></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Media Freq. Card.</label>
                            <b-form-input v-model="jsonData.mediaFrequenzaCardiacaClino" disabled></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">P.A. ARTO INF. SN</label>
                            <b-form-input v-model="jsonData.pasaisn" @focusout="onFocusOutCalcoloAbiSn"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">P.A. ARTO INF. DX</label>
                            <b-form-input v-model="jsonData.pasaidx" @focusout="onFocusOutCalcoloAbiDx"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">ABI SN</label>
                            <b-form-input v-model="jsonData.abisn" disabled></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">ABI DX</label>
                            <b-form-input v-model="jsonData.abidx" disabled></b-form-input>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <b-card class="sa-card" header="P.A. IN ORTOSTATISMO" header-tag="header" footer-tag="footer" title="">
                    <template #header>
                        <b-row>
                            <b-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9">
                                <span>P.A. IN ORTOSTATISMO</span>
                            </b-col>
                            <b-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                                <b-button variant="outline-secondary outline-secondary-noborder pull-right" size="sm" @click="onClickCalcolaValoriPaOrto">
                                    <i style="margin-top: 2px" class="fas fa-calculator"></i>
                                    <span style="padding-left: 5px">Calcola</span>
                                </b-button>
                            </b-col>
                        </b-row>
                    </template>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <pressione-sanguigna-component :items="jsonData.listaPressioneOrto" :tipoMisurazione="tipoMisurazioneOrto" :isEdit="true" @update="onUpdatePressioniOrtostatismo"></pressione-sanguigna-component>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Media P.A. Sis.</label>
                            <b-form-input v-model="jsonData.paortomaxm" disabled></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Media P.A. Dias.</label>
                            <b-form-input v-model="jsonData.paortominm" disabled></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Media Freq. Card.</label>
                            <b-form-input v-model="jsonData.mediaFrequenzaCardiacaOrto" disabled></b-form-input>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                <b-card class="sa-card" header="P.A. IN POSIZIONE SEDUTA" header-tag="header" footer-tag="footer" title="">
                    <template #header>
                        <b-row>
                            <b-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9">
                                <span>P.A. IN POSIZIONE SEDUTA</span>
                            </b-col>
                            <b-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                                <b-button variant="outline-secondary outline-secondary-noborder pull-right" size="sm" @click="onClickCalcolaValoriPaSitting">
                                    <i style="margin-top: 2px" class="fas fa-calculator"></i>
                                    <span style="padding-left: 5px">Calcola</span>
                                </b-button>
                            </b-col>
                        </b-row>
                    </template>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <pressione-sanguigna-component :items="jsonData.listaPressioneSitting" :tipoMisurazione="tipoMisurazioneSitting" :isEdit="true" @update="onUpdatePressioniSitting"></pressione-sanguigna-component>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Media P.A. Sis.</label>
                            <b-form-input v-model="jsonData.pasitmaxm" disabled></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Media P.A. Dias.</label>
                            <b-form-input v-model="jsonData.pasitminm" disabled></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Media Freq. Card.</label>
                            <b-form-input v-model="jsonData.mediaFrequenzaCardiacaSitting" disabled></b-form-input>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-card class="sa-card" header="SCORE2-OP (Paesi a rischio moderato) – Valutazione del rischio di eventi cardiovascolari fatali e non fatali a 10 anni" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                    <b-row>
                        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                            <label class="sa-label-data">Sesso</label>
                            <b-form-select v-model="jsonData.sesso" :options="sessoOptions" :value="null" value-field="value" text-field="text" disabled></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                            <label class="sa-label-data">P.A. Sistolica</label>
                            <b-form-input v-model="jsonData.pressartsist" disabled></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                            <label class="sa-label-data">Col. non HDL</label>
                            <b-form-input v-model="jsonData.colesterolemia" :disabled="calcoloScoreDisabled"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                            <label class="sa-label-data">Età</label>
                            <b-form-input v-model="jsonData.eta" disabled></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                            <label class="sa-label-data">Fumo</label>
                            <b-form-select v-model="jsonData.fumostrat1" :options="siNoOptions" :value="null" value-field="value" text-field="text" :disabled="calcoloScoreDisabled"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                            <label class="sa-label-data">&nbsp;</label>
                            <b-button class="primary" variant="btn btn-outline-info btn-toolbar " @click="onClickCalcolaRischioCardiovascolare" :disabled="calcoloScoreDisabled"><i class="bi bi-calculator">&nbsp;&nbsp;Calcola</i></b-button>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Rischio Cardiovascolare</label>
                            <div style="width: 100%">
                                <div :class="rischioCardiovascolareColorClass">{{ jsonData.rischioCardiovascolare }}</div>
                            </div>
                        </b-col>
                        <b-col v-if="isUnderQuaranta" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data"></label>
                            <span class="sa-data">IL CALCOLO DELLO SCORE NON E' APPLICABILE PER I SOGETTI INFERIORI AI 40 ANNI DI ETA'</span>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6"></b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Terapia Proposta" header-tag="header" footer-tag="footer" title="">
            <template #header>
                Terapia Proposta
                <b-button size="sm" style="float: right" variant="btn btn-outline-info waves-effect waves-light width-sm no-text" @click="onClickCopiaTerapiaInCorso">
                    <i class="bi bi-files"></i>
                    Copia Terapia In Corso
                </b-button>
            </template>
            <b-row class="sa-visita-controllo-ecg-row-custom">
                <b-col v-for="farmaco in listaCategorieFarmaci" :key="farmaco.nome" xs="12" sm="12" md="3" lg="3" xl="3">
                    <paziente-evento-terapia-component :codiceAtc="farmaco.codice" :listaFarmaciSelezionati="listaFarmaciTerapiaProposta" :listaTerapia="jsonData.listaTerapia" :tipoFarmaco="farmaco.nome" @update="onUpdateListaFarmaciTerapiaProposta" tipo="TERAPIA PROPOSTA"></paziente-evento-terapia-component>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-table ref="tblTerapiaProposta" sticky-header stacked="xl" striped hover itemscope :items="listaFarmaciTerapiaProposta" :fields="fieldsListaFarmaciTerapiaProposta" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="row">
                            {{ row.index + 1 }}
                        </template>
                        <template #cell(actions)="row">
                            <b-button size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(listaFarmaciTerapiaProposta, row.index)"><b-icon icon="trash"></b-icon></b-button>
                        </template>
                    </b-table>
                </b-col>
            </b-row>
        </b-card>
        <b-row>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card" header="Diagnosi, note e Comunicazioni al MMG" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <paziente-evento-icd-ix-component :listaDiagnosi="jsonData.listaDiagnosi" @update="onUpdateDiagnosi"></paziente-evento-icd-ix-component>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Note</label>
                            <eventi-dizionari-component :tipoEvento="tipoEvento" :gruppo="gruppo" tipoDizionario="NOTEDIAGNOSI" @update="onUpdateNoteDiagnosi"></eventi-dizionari-component>
                            <b-form-textarea v-model="jsonData.noteDiagnosi" rows="6" no-resize disabled></b-form-textarea>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-card class="sa-card" header="PDTA" header-tag="header" footer-tag="footer" title="">
            <!-- <b-row> -->
            <b-form-group>
                <b-row>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <b-form-radio v-model="pdta" name="some-radios" value="1" @change="onChangePdta()">Nessun PDTA</b-form-radio>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <b-form-radio v-model="pdta" name="some-radios" value="2" @change="onChangePdta()">PDTA Ipertensione Arteriosa</b-form-radio>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <b-form-radio v-model="pdta" name="some-radios" value="3" @change="onChangePdta()">PDTA Scompenso Cardiaco</b-form-radio>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <b-form-radio v-model="pdta" name="some-radios" value="4" @change="onChangePdta()">PDTA Cardiopatia Ischemica</b-form-radio>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <b-form-radio v-model="pdta" name="some-radios" value="5" @change="onChangePdta()">PDTA Prevenzione Malattie Cardiovascolari</b-form-radio>
                    </b-col>
                </b-row>
            </b-form-group>
            <!-- </b-row> -->
        </b-card>
        <b-card class="sa-card" header="ESAMI DI LABORATORIO - DA PRATICARE PRIMA DEL PROSSIMO CONTROLLO" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox :class="getCheckedClass(jsonData.glicemia)" :checked="getCheckedValue(jsonData.glicemia)" switch @input="onInputCheckbox($event, 'glicemia')">Glicemia</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox :class="getCheckedClass(jsonData.hba1c)" :checked="getCheckedValue(jsonData.hba1c)" switch @input="onInputCheckbox($event, 'hba1c')">HbA1c</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox :class="getCheckedClass(jsonData.creatinemia)" :checked="getCheckedValue(jsonData.creatinemia)" switch @input="onInputCheckbox($event, 'creatinemia')">Creatininemia</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox :class="getCheckedClass(jsonData.azotemia)" :checked="getCheckedValue(jsonData.azotemia)" switch @input="onInputCheckbox($event, 'azotemia')">Azotemia</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox :class="getCheckedClass(jsonData.uricemia)" :checked="getCheckedValue(jsonData.uricemia)" switch @input="onInputCheckbox($event, 'uricemia')">Uricemia</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox :class="getCheckedClass(jsonData.sodiemia)" :checked="getCheckedValue(jsonData.sodiemia)" switch @input="onInputCheckbox($event, 'sodiemia')">Sodiemia</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox :class="getCheckedClass(jsonData.potassioemia)" :checked="getCheckedValue(jsonData.potassioemia)" switch @input="onInputCheckbox($event, 'potassioemia')">Potassiemia</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox :class="getCheckedClass(jsonData.calcemia)" :checked="getCheckedValue(jsonData.calcemia)" switch @input="onInputCheckbox($event, 'calcemia')">Calcemia</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox :class="getCheckedClass(jsonData.cloremia)" :checked="getCheckedValue(jsonData.cloremia)" switch @input="onInputCheckbox($event, 'cloremia')">Cloremia</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox :class="getCheckedClass(jsonData.magnesemia)" :checked="getCheckedValue(jsonData.magnesemia)" switch @input="onInputCheckbox($event, 'magnesemia')">Magnesemia</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox :class="getCheckedClass(jsonData.colesteroloTrigliceridi)" :checked="getCheckedValue(jsonData.colesteroloTrigliceridi)" switch @input="onInputCheckbox($event, 'colesteroloTrigliceridi')">{{ getLabelTraduora("patient.cardiology.lblTotalCholesterolHdlTriglycerides") }}</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox :class="getCheckedClass(jsonData.cpkLdh)" :checked="getCheckedValue(jsonData.cpkLdh)" switch @input="onInputCheckbox($event, 'cpkLdh')">{{ getLabelTraduora("patient.cardiology.lblCpkLdh") }}</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox :class="getCheckedClass(jsonData.got)" :checked="getCheckedValue(jsonData.got)" switch @input="onInputCheckbox($event, 'got')">GOT</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox :class="getCheckedClass(jsonData.gpt)" :checked="getCheckedValue(jsonData.gpt)" switch @input="onInputCheckbox($event, 'gpt')">GPT</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox :class="getCheckedClass(jsonData.quadroProtElettroforetico)" :checked="getCheckedValue(jsonData.quadroProtElettroforetico)" switch @input="onInputCheckbox($event, 'quadroProtElettroforetico')">Quadro Prot. Elettrof.</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox :class="getCheckedClass(jsonData.amilasi)" :checked="getCheckedValue(jsonData.amilasi)" switch @input="onInputCheckbox($event, 'amilasi')">Amilasi</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox :class="getCheckedClass(jsonData.yGt)" :checked="getCheckedValue(jsonData.yGt)" switch @input="onInputCheckbox($event, 'yGt')">Y-GT</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox :class="getCheckedClass(jsonData.emocromoPiastrine)" :checked="getCheckedValue(jsonData.emocromoPiastrine)" switch @input="onInputCheckbox($event, 'emocromoPiastrine')">{{ getLabelTraduora("patient.cardiology.lblBloodCountPlatelets") }}</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox :class="getCheckedClass(jsonData.esameUrine)" :checked="getCheckedValue(jsonData.esameUrine)" switch @input="onInputCheckbox($event, 'esameUrine')">{{ getLabelTraduora("patient.cardiology.lblUrine") }}</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox :class="getCheckedClass(jsonData.microalbuminuria)" :checked="getCheckedValue(jsonData.microalbuminuria)" switch @input="onInputCheckbox($event, 'microalbuminuria')">{{ getLabelTraduora("patient.cardiology.lblMicroalbuminuria") }}</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox :class="getCheckedClass(jsonData.dosaggioElettrolitiUrinari)" :checked="getCheckedValue(jsonData.dosaggioElettrolitiUrinari)" switch @input="onInputCheckbox($event, 'dosaggioElettrolitiUrinari')">Dosaggio degli Elettroliti Urinari (Na+ e K+) su campione delle 24h</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox :class="getCheckedClass(jsonData.proteinuria)" :checked="getCheckedValue(jsonData.proteinuria)" switch @input="onInputCheckbox($event, 'proteinuria')">{{ getLabelTraduora("patient.cardiology.lblProteinuria") }}</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox :class="getCheckedClass(jsonData.ntProBnp)" :checked="getCheckedValue(jsonData.ntProBnp)" switch @input="onInputCheckbox($event, 'ntProBnp')">NT-proBNP</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox :class="getCheckedClass(jsonData.sideremia)" :checked="getCheckedValue(jsonData.sideremia)" switch @input="onInputCheckbox($event, 'sideremia')">Sideremia</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox :class="getCheckedClass(jsonData.ferritinemia)" :checked="getCheckedValue(jsonData.ferritinemia)" switch @input="onInputCheckbox($event, 'ferritinemia')">Ferritinemia</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox :class="getCheckedClass(jsonData.transferrinemia)" :checked="getCheckedValue(jsonData.transferrinemia)" switch @input="onInputCheckbox($event, 'transferrinemia')">Transferrinemia</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox :class="getCheckedClass(jsonData.vitD25Oh)" :checked="getCheckedValue(jsonData.vitD25Oh)" switch @input="onInputCheckbox($event, 'vitD25Oh')">Vit.D-25OH</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox :class="getCheckedClass(jsonData.ptApttInr)" :checked="getCheckedValue(jsonData.ptApttInr)" switch @input="onInputCheckbox($event, 'ptApttInr')">{{ getLabelTraduora("patient.cardiology.lblPtApttInr") }}</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox :class="getCheckedClass(jsonData.pcr)" :checked="getCheckedValue(jsonData.pcr)" switch @input="onInputCheckbox($event, 'pcr')">{{ getLabelTraduora("patient.cardiology.lblPCR") }}</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox :class="getCheckedClass(jsonData.dosaggioOmocisteinemia)" :checked="getCheckedValue(jsonData.dosaggioOmocisteinemia)" switch @input="onInputCheckbox($event, 'dosaggioOmocisteinemia')">{{ getLabelTraduora("patient.cardiology.lblHomocysteinemiaDosage") }}</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox :class="getCheckedClass(jsonData.troponinemiaT)" :checked="getCheckedValue(jsonData.troponinemiaT)" switch @input="onInputCheckbox($event, 'troponinemiaT')">Troponinemia T</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox :class="getCheckedClass(jsonData.troponinemiaI)" :checked="getCheckedValue(jsonData.troponinemiaI)" switch @input="onInputCheckbox($event, 'troponinemiaI')">Troponinemia I</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox :class="getCheckedClass(jsonData.tsh)" :checked="getCheckedValue(jsonData.tsh)" switch @input="onInputCheckbox($event, 'tsh')">{{ getLabelTraduora("patient.cardiology.lblTSH") }}</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox :class="getCheckedClass(jsonData.ft3)" :checked="getCheckedValue(jsonData.ft3)" switch @input="onInputCheckbox($event, 'ft3')">{{ getLabelTraduora("patient.cardiology.lblFT3") }}</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox :class="getCheckedClass(jsonData.ft4)" :checked="getCheckedValue(jsonData.ft4)" switch @input="onInputCheckbox($event, 'ft4')">{{ getLabelTraduora("patient.cardiology.lblFT4") }}</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox :class="getCheckedClass(jsonData.anticorpiAntiTg)" :checked="getCheckedValue(jsonData.anticorpiAntiTg)" switch @input="onInputCheckbox($event, 'anticorpiAntiTg')">Anticorpi Anti-TG</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox :class="getCheckedClass(jsonData.anticorpiAntiTpo)" :checked="getCheckedValue(jsonData.anticorpiAntiTpo)" switch @input="onInputCheckbox($event, 'anticorpiAntiTpo')">Anticorpi Anti-TPO</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox :class="getCheckedClass(jsonData.dosaggioDopoCaptopril)" :checked="getCheckedValue(jsonData.dosaggioDopoCaptopril)" switch @input="onInputCheckbox($event, 'dosaggioDopoCaptopril')">{{ getLabelTraduora("patient.cardiology.lblPraAndBasalAldosteronemia1hAfterCaptopril") }}</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox :class="getCheckedClass(jsonData.dosaggioClinoOrto)" :checked="getCheckedValue(jsonData.dosaggioClinoOrto)" switch @input="onInputCheckbox($event, 'dosaggioClinoOrto')">{{ getLabelTraduora("patient.cardiology.lblPraAndBasalAldosteronemiaOnClinic") }}</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox :class="getCheckedClass(jsonData.cortisolemia820)" :checked="getCheckedValue(jsonData.cortisolemia820)" switch @input="onInputCheckbox($event, 'cortisolemia820')">{{ getLabelTraduora("patient.cardiology.lblCortisol") }}</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox :class="getCheckedClass(jsonData.dosaggioCatecolamine)" :checked="getCheckedValue(jsonData.dosaggioCatecolamine)" switch @input="onInputCheckbox($event, 'dosaggioCatecolamine')">{{ getLabelTraduora("patient.cardiology.lblUrinaryCatecholaminesMetanephrinesDosage24hSample") }}</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox :class="getCheckedClass(jsonData.acth)" :checked="getCheckedValue(jsonData.acth)" switch @input="onInputCheckbox($event, 'acth')">ACTH</b-form-checkbox>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="ESAMI STRUMENTALI" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox :class="getCheckedClass(jsonData.ecocardiografia)" :checked="getCheckedValue(jsonData.ecocardiografia)" switch @input="onInputCheckbox($event, 'ecocardiografia')">{{ getLabelTraduora("patient.cardiology.lblHeartUltrasonographyColordoppler") }}</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox :class="getCheckedClass(jsonData.ecotsa)" :checked="getCheckedValue(jsonData.ecotsa)" switch @input="onInputCheckbox($event, 'ecotsa')">{{ getLabelTraduora("patient.cardiology.lblUltrasoundTsaColordoppler") }}</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox :class="getCheckedClass(jsonData.ecocolordopplerAortaAddominale)" :checked="getCheckedValue(jsonData.ecocolordopplerAortaAddominale)" switch @input="onInputCheckbox($event, 'ecocolordopplerAortaAddominale')">Ecocolordoppler Aorta Addominale</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox :class="getCheckedClass(jsonData.holterEcg)" :checked="getCheckedValue(jsonData.holterEcg)" switch @input="onInputCheckbox($event, 'holterEcg')">{{ getLabelTraduora("patient.cardiology.lblHolterECG") }}</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox :class="getCheckedClass(jsonData.monitoraggioPressione)" :checked="getCheckedValue(jsonData.monitoraggioPressione)" switch @input="onInputCheckbox($event, 'monitoraggioPressione')">{{ getLabelTraduora("patient.cardiology.lblAmbulatoryMonitoringBloodPressure") }}</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox :class="getCheckedClass(jsonData.ecocolordopplerArteriosoArtiInferiori)" :checked="getCheckedValue(jsonData.ecocolordopplerArteriosoArtiInferiori)" switch @input="onInputCheckbox($event, 'ecocolordopplerArteriosoArtiInferiori')">Ecocolordoppler Arterioso Arti Inferiori</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox :class="getCheckedClass(jsonData.ecocolordopplerArterieRenali)" :checked="getCheckedValue(jsonData.ecocolordopplerArterieRenali)" switch @input="onInputCheckbox($event, 'ecocolordopplerArterieRenali')">Ecocolordoppler Arterie Renali</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox :class="getCheckedClass(jsonData.testErgometrico)" :checked="getCheckedValue(jsonData.testErgometrico)" switch @input="onInputCheckbox($event, 'testErgometrico')">{{ getLabelTraduora("patient.cardiology.lblErgonometricTest") }}</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox :class="getCheckedClass(jsonData.ecostress)" :checked="getCheckedValue(jsonData.ecostress)" switch @input="onInputCheckbox($event, 'ecostress')">Ecostress</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox :class="getCheckedClass(jsonData.ecostressFarmacologico)" :checked="getCheckedValue(jsonData.ecostressFarmacologico)" switch @input="onInputCheckbox($event, 'ecostressFarmacologico')">Ecostress Farmacologico</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox :class="getCheckedClass(jsonData.tcCoronarica)" :checked="getCheckedValue(jsonData.tcCoronarica)" switch @input="onInputCheckbox($event, 'tcCoronarica')">T.C. Coronarica</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox :class="getCheckedClass(jsonData.scintigrafiaMiocardica)" :checked="getCheckedValue(jsonData.scintigrafiaMiocardica)" switch @input="onInputCheckbox($event, 'scintigrafiaMiocardica')">{{ getLabelTraduora("patient.cardiology.lblMyocardialPerfusionSpectRestStimulation") }}</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox :class="getCheckedClass(jsonData.pet)" :checked="getCheckedValue(jsonData.pet)" switch @input="onInputCheckbox($event, 'pet')">P.E.T.</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox :class="getCheckedClass(jsonData.rmnCardiaca)" :checked="getCheckedValue(jsonData.rmnCardiaca)" switch @input="onInputCheckbox($event, 'rmnCardiaca')">RMN Cardiaca</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox :class="getCheckedClass(jsonData.angioTcTsa)" :checked="getCheckedValue(jsonData.angioTcTsa)" switch @input="onInputCheckbox($event, 'angioTcTsa')">Angio-TC TSA</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox :class="getCheckedClass(jsonData.angioTcTorace)" :checked="getCheckedValue(jsonData.angioTcTorace)" switch @input="onInputCheckbox($event, 'angioTcTorace')">Angio-TC Torace</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox :class="getCheckedClass(jsonData.angioTcAddome)" :checked="getCheckedValue(jsonData.angioTcAddome)" switch @input="onInputCheckbox($event, 'angioTcAddome')">Angio-TC Addome</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox :class="getCheckedClass(jsonData.rmnCranio)" :checked="getCheckedValue(jsonData.rmnCranio)" switch @input="onInputCheckbox($event, 'rmnCranio')">RMN Cranio</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox :class="getCheckedClass(jsonData.rmnTorace)" :checked="getCheckedValue(jsonData.rmnTorace)" switch @input="onInputCheckbox($event, 'rmnTorace')">RMN Torace</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox :class="getCheckedClass(jsonData.rmnAddome)" :checked="getCheckedValue(jsonData.rmnAddome)" switch @input="onInputCheckbox($event, 'rmnAddome')">RMN Addome</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox :class="getCheckedClass(jsonData.tacCerebrale)" :checked="getCheckedValue(jsonData.tacCerebrale)" switch @input="onInputCheckbox($event, 'tacCerebrale')">{{ getLabelTraduora("patient.cardiology.lblBrainTAC") }}</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox :class="getCheckedClass(jsonData.tacTorace)" :checked="getCheckedValue(jsonData.tacTorace)" switch @input="onInputCheckbox($event, 'tacTorace')">TAC Torace</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox :class="getCheckedClass(jsonData.tacAddome)" :checked="getCheckedValue(jsonData.tacAddome)" switch @input="onInputCheckbox($event, 'tacAddome')">{{ getLabelTraduora("patient.cardiology.lblAbdomenTAC") }}</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox :class="getCheckedClass(jsonData.ecografiaRenale)" :checked="getCheckedValue(jsonData.ecografiaRenale)" switch @input="onInputCheckbox($event, 'ecografiaRenale')">Ecografia Renale</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox :class="getCheckedClass(jsonData.ecografiaAddominale)" :checked="getCheckedValue(jsonData.ecografiaAddominale)" switch @input="onInputCheckbox($event, 'ecografiaAddominale')">Ecografia Addominale</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox :class="getCheckedClass(jsonData.ecografiaTiroidea)" :checked="getCheckedValue(jsonData.ecografiaTiroidea)" switch @input="onInputCheckbox($event, 'ecografiaTiroidea')">Ecografia Tiroidea</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox :class="getCheckedClass(jsonData.scintigrafiaRenale)" :checked="getCheckedValue(jsonData.scintigrafiaRenale)" switch @input="onInputCheckbox($event, 'scintigrafiaRenale')">{{ getLabelTraduora("patient.cardiology.lblBasalSequentialRenalScintigraphy1hAfterCaptopril") }}</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox :class="getCheckedClass(jsonData.ecodopplerArtiInferiori)" :checked="getCheckedValue(jsonData.ecodopplerArtiInferiori)" switch @input="onInputCheckbox($event, 'ecodopplerArtiInferiori')">Ecodoppler Arti Inferiori</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox :class="getCheckedClass(jsonData.ecodopplerTransCranico)" :checked="getCheckedValue(jsonData.ecodopplerTransCranico)" switch @input="onInputCheckbox($event, 'ecodopplerTransCranico')">Ecodoppler Trans Cranico con Buble Test</b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOther") }}</label>
                    <b-form-textarea v-model="jsonData.altroEsame" rows="6" no-resize></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Prossimo Controllo" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCheckNextDate") }}</label>
                    <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.prossimoControllo" type="timestamp" placeholder="Data Prossimo Controllo" :disabled="prossimoControlloDisabled"></date-picker>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Ora Prossimo Controllo</label>
                    <date-picker format="HH:mm" value-type="timestamp" v-model="jsonData.oraProssimoControllo" type="time" placeholder="Data Prossimo Controllo" :disabled="prossimoControlloDisabled"></date-picker>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import UtilityMixins from "../../../../../utility/UtilityMixin";
import PazienteEventoTerapiaComponent from "../../../utility/components/PazienteEventoTerapiaComponent.vue";
import PressioneSanguignaComponent from "../../../utility/components/PressioneSanguignaComponent.vue";
import PazienteEventoIcdIxComponent from "../../../utility/components/PazienteEventoIcdIxComponent.vue";
import EventiDizionariComponent from "../../../base/components/EventiDizionariComponent.vue";

export default {
    mixins: [UtilityMixins],
    components: { DatePicker, PazienteEventoTerapiaComponent, PressioneSanguignaComponent, PazienteEventoIcdIxComponent, EventiDizionariComponent },
    props: {
        tipoEvento: String,
        gruppo: String,
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
        jsonData: {
            type: Object,
        },
    },
    data() {
        return {
            id: "-1",
            linkback: null,
            tabIndex: 0,
            childKey: 0,
            pathResource: "/cardiovisitecontrolliecg",
            tipoMisurazioneClino: "CLINO",
            tipoMisurazioneOrto: "ORTO",
            tipoMisurazioneSitting: "SITTING",
            rischioCardiovascolareColorClass: "",
            isTipologiaFarmaco: false,
            calcoloScoreDisabled: true,
            pathResourceCategorieFarmaci: "/pazienteeventigruppifarmaci",
            pathResourceCalcoloRischioCardiovascolare: "/rischiocardiovascolare",
            pathResourceLastVisita: "/cardiovisitecontrolliecg/last",
            pathResourceLastEsamiLaboratorio: "/cardioesamilaboratorio/last",
            qualiEffettiCollateraliDisabled: true,
            prossimoControlloDisabled: true,
            isUnderQuaranta: false,
            currentPage: 1,
            perPage: 30,
            pdta: 1,
            filtro: { page: 1, forPage: 30 },
            showModalLoading: false,
            firstElementSelect: this.getLabelTraduora("patient.ecoreferting.lblSelected"),
            personalitaOptions: [],
            // listaDiagnosi: [],
            // keyTerapiaInCorso: 0,
            siNoOptions: [
                { value: null, text: "-Seleziona Valore-" },
                { value: "SI", text: "SI" },
                { value: "NO", text: "NO" },
            ],
            chkOptions: [
                { value: "SI", text: "SI" },
                { value: "NO", text: "NO" },
            ],
            causeRicoveroOptions: [
                { text: this.getLabelTraduora("global.lblSelectValue"), value: null },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblAngina"), value: this.getLabelTraduora("patient.cardiology.Options.lblAngina") },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblArrhytmia"), value: this.getLabelTraduora("patient.cardiology.Options.lblArrhytmia") },
                { text: "BAV", value: "BAV" },
                { text: this.getLabelTraduora("patient.cardiology.slcHypertensiveCrisis"), value: this.getLabelTraduora("patient.cardiology.slcHypertensiveCrisis") },
                { text: "ICTUS", value: "ICTUS" },
                { text: "IMA", value: "IMA" },
                { text: "TIA", value: "TIA" },
            ],
            tipiRicoveroOptions: [
                { text: this.getLabelTraduora("global.lblSelectValue"), value: null },
                { text: this.getLabelTraduora("patient.cardiology.slcElection"), value: this.getLabelTraduora("patient.cardiology.slcElection") },
                { text: this.getLabelTraduora("patient.cardiology.slcUrgent"), value: this.getLabelTraduora("patient.cardiology.slcUrgent") },
            ],
            fumoOptions: [
                { text: "-Seleziona Valore-", value: null },
                { text: "No", value: "NO" },
                { text: "EX", value: "EX" },
                { text: "SI (< 10)", value: "SI (< 10)" },
                { text: "SI (10 - 20)", value: "SI (10 - 20)" },
                { text: "SI (20)", value: "SI (20)" },
                { text: "SI (20 - 30)", value: "SI (20 - 30)" },
                { text: "SI (30 - 40)", value: "SI (30 - 40)" },
                { text: "SI (> 40)", value: "SI (> 40)" },
            ],
            sessoOptions: [
                { value: null, text: "-Seleziona Valore-" },
                { value: "M", text: "MASCHIO" },
                { value: "F", text: "FEMMINA" },
            ],
            nyhaOptions: [
                { value: null, text: "-Seleziona Valore-" },
                { value: "I", text: "I" },
                { value: "II", text: "II" },
                { value: "III", text: "III" },
                { value: "IV", text: "IV" },
            ],
            stadioScompensoOptions: [
                { value: null, text: "-Seleziona Valore-" },
                { value: "A", text: "A" },
                { value: "B", text: "B" },
                { value: "C", text: "C" },
                { value: "D", text: "D" },
            ],
            polsiArteriosiOptions: [
                { value: null, text: "-Seleziona Valore-" },
                { value: "NORMALI", text: "NORMALI" },
                { value: "PATOLOGICI", text: "PATOLOGICI" },
            ],
            ecgOptions: [
                { value: null, text: "-Seleziona Valore-" },
                { value: "NORMALE", text: "NORMALE" },
                { value: "ANORMALE", text: "ANORMALE" },
                { value: "IVS", text: "IVS" },
                { value: "BBdx", text: "BBdx" },
                { value: "EAS", text: "EAS" },
                { value: "ISCHEMICO", text: "ISCHEMICO" },
                { value: "BAV I", text: "BAV I" },
                { value: "BAV II", text: "BAV II" },
                { value: "BAV III", text: "BAV III" },
                { value: "EIDDD", text: "EIDDD" },
                { value: "EIVVI", text: "EIVVI" },
                { value: "BESV", text: "BESV" },
                { value: "BEV", text: "BEV" },
                { value: "FIB. ATRIALE", text: "FIB. ATRIALE" },
                { value: "FLUTTER ATRIALE", text: "FLUTTER ATRIALE" },
                { value: "GIUNAZIONALE", text: "GIUNAZIONALE" },
                { value: "NECROSI ANTERIORE", text: "NECROSI ANTERIORE" },
                { value: "NECROSI INFERIORE", text: "NECROSI INFERIORE" },
                { value: "NECROSI LATERALE", text: "NECROSI LATERALE" },
                { value: "NECROSI SETTALE", text: "NECROSI SETTALE" },
            ],
            consulenzaEsternaOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "RETINOPATIA IPERTENSIVA I STADIO O.M.S", text: "RETINOPATIA IPERTENSIVA I STADIO O.M.S." },
                { value: "RETINOPATIA IPERTENSIVA II STADIO O.M.S.", text: "RETINOPATIA IPERTENSIVA II STADIO O.M.S." },
                { value: "RETINOPATIA IPERTENSIVA III STADIO O.M.S.", text: "RETINOPATIA IPERTENSIVA III STADIO O.M.S." },
                { value: "RETINOPATIA IPERTENSIVA IV STADIO O.M.S.", text: "RETINOPATIA IPERTENSIVA IV STADIO O.M.S." },
                { value: "RETINOPATIA DIABETICA", text: "RETINOPATIA DIABETICA" },
                { value: "FONDO OCULARE SANO", text: "FONDO OCULARE SANO" },
            ],
            attivitaFisicaOptions: [
                { value: null, text: "-Seleziona Valore-" },
                { value: "NESSUNA", text: "NESSUNA" },
                { value: "< DI 3 ORE A SETTIMANA", text: "< DI 3 ORE A SETTIMANA" },
                { value: "> DI 3 ORE A SETTIMANA; AGONISTICA", text: "> DI 3 ORE A SETTIMANA; AGONISTICA" },
            ],
            listaEsamiPdtaUno: [
                { descrizione: "", campo: "glicemia" },
                { descrizione: "", campo: "hba1c" },
                { descrizione: "", campo: "creatinemia" },
                { descrizione: "", campo: "uricemia" },
                { descrizione: "", campo: "sodiemia" },
                { descrizione: "", campo: "potassioemia" },
                { descrizione: "", campo: "calcemia" },
                { descrizione: "", campo: "colesteroloTrigliceridi" },
                { descrizione: "", campo: "emocromoPiastrine" },
                { descrizione: "", campo: "esameUrine" },
                { descrizione: "", campo: "microalbuminuria" },
            ],
            listaEsamiPdtaDue: [
                { descrizione: "", campo: "ntProBnp" },
                { descrizione: "", campo: "glicemia" },
                { descrizione: "", campo: "hba1c" },
                { descrizione: "", campo: "creatinemia" },
                { descrizione: "", campo: "uricemia" },
                { descrizione: "", campo: "sodiemia" },
                { descrizione: "", campo: "potassioemia" },
                { descrizione: "", campo: "colesteroloTrigliceridi" },
                { descrizione: "", campo: "got" },
                { descrizione: "", campo: "quadroProtElettroforetico" },
                { descrizione: "", campo: "emocromoPiastrine" },
                { descrizione: "", campo: "esameUrine" },
                { descrizione: "", campo: "microalbuminuria" },
                { descrizione: "", campo: "ecocardiografia" },
            ],
            listaEsamiPdtaTre: [
                { descrizione: "", campo: "glicemia" },
                { descrizione: "", campo: "hba1c" },
                { descrizione: "", campo: "creatinemia" },
                { descrizione: "", campo: "uricemia" },
                { descrizione: "", campo: "sodiemia" },
                { descrizione: "", campo: "potassioemia" },
                { descrizione: "", campo: "colesteroloTrigliceridi" },
                { descrizione: "", campo: "got" },
                { descrizione: "", campo: "quadroProtElettroforetico" },
                { descrizione: "", campo: "emocromoPiastrine" },
                { descrizione: "", campo: "esameUrine" },
                { descrizione: "", campo: "microalbuminuria" },
                { descrizione: "", campo: "ecocardiografia" },
            ],
            listaEsamiPdtaQuattro: [
                { descrizione: "", campo: "glicemia" },
                { descrizione: "", campo: "hba1c" },
                { descrizione: "", campo: "creatinemia" },
                { descrizione: "", campo: "uricemia" },
                { descrizione: "", campo: "sodiemia" },
                { descrizione: "", campo: "potassioemia" },
                { descrizione: "", campo: "colesteroloTrigliceridi" },
                { descrizione: "", campo: "got" },
                { descrizione: "", campo: "quadroProtElettroforetico" },
                { descrizione: "", campo: "emocromoPiastrine" },
                { descrizione: "", campo: "tsh" },
                { descrizione: "", campo: "esameUrine" },
                { descrizione: "", campo: "microalbuminuria" },
            ],
            listaEsamiSelezionati: [],
            listaCategorieFarmaci: [],
            listaFarmaciTerapiaInCorso: [],
            fieldsListaFarmaciTerapiaInCorso: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Nome Farmaco",
                    key: "nomeFarmaco",
                    sortable: false,
                },
                {
                    label: "Somministrazione",
                    key: "dosaggio",
                    // thStyle: "width: 13rem",
                    sortable: false,
                },
                {
                    label: "Classe Farmaco",
                    key: "classe",
                    thStyle: "width: 13rem",
                    sortable: false,
                },
                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 3rem",
                },
            ],
            listaFarmaciTerapiaProposta: [],
            fieldsListaFarmaciTerapiaProposta: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Nome Farmaco",
                    key: "nomeFarmaco",
                    sortable: false,
                },
                {
                    label: "Somministrazione",
                    key: "dosaggio",
                    // thStyle: "width: 13rem",
                    sortable: false,
                },
                {
                    label: "Classe Farmaco",
                    key: "classe",
                    thStyle: "width: 13rem",
                    sortable: false,
                },
                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 3rem",
                },
            ],
        };
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                // this.keyTerapiaInCorso++;
                if (jsonData.id !== "-1" && jsonData.id !== null && jsonData.id !== undefined && jsonData.eta > 40) {
                    this.calcoloScoreDisabled = false;
                }
                if (jsonData.eta < 40) {
                    this.isUnderQuaranta = true;
                } else {
                    this.isUnderQuaranta = false;
                }
                this.setColorByScoreColor();
                this.$emit("update", jsonData);
            },
            deep: true,
        },
        // "jsonData.listaPressioneClino": function (array) {
        //   let me = this;
        //   let medie = me.calcolaTotalePressione(array);
        //   me.jsonData.paclinomaxm = medie.mediaMassima.toString();
        //   me.jsonData.paclinominm = medie.mediaMinima.toString();
        //   me.jsonData.mediaFrequenzaCardiacaClino = medie.mediaFrequenzaCardiaca.toString();
        // },
        // "jsonData.listaPressioneOrto": function (array) {
        //   let me = this;
        //   let medie = me.calcolaTotalePressione(array);
        //   me.jsonData.paortomaxm = medie.mediaMassima.toString();
        //   me.jsonData.paortominm = medie.mediaMinima.toString();
        //   me.jsonData.mediaFrequenzaCardiacaOrto = medie.mediaFrequenzaCardiaca.toString();
        // },
        // "jsonData.listaPressioneSitting": function (array) {
        //   let me = this;
        //   // console.log(array);
        //   let medie = me.calcolaTotalePressioneSitting(array);
        //   me.jsonData.pasitmaxm = medie.mediaMassima.toString();
        //   me.jsonData.pasitminm = medie.mediaMinima.toString();
        //   me.jsonData.mediaFrequenzaCardiacaSitting = medie.mediaFrequenzaCardiaca.toString();
        //   me.jsonData.pressartsist = me.jsonData.pasitmaxm;
        // },
        "jsonData.paortomaxm": function (value) {
            let me = this;
            if (value === "NaN") {
                me.jsonData.paortomaxm = "";
                me.jsonData.paortominm = "";
            }
        },
        "jsonData.paclinomaxm": function (value) {
            let me = this;
            if (value === "NaN") {
                me.jsonData.paclinomaxm = "";
                me.jsonData.paclinominm = "";
            }
        },
        "jsonData.pasitmaxm": function (value) {
            let me = this;
            if (value === "NaN") {
                me.jsonData.pasitminm = "";
                me.jsonData.pasitmaxm = "";
            }
        },
        listaFarmaciTerapiaInCorso: function () {
            let me = this;
            let tmp = [];
            me.jsonData.listaTerapia.forEach((element) => {
                if (element.tipo !== "TERAPIA IN CORSO") {
                    tmp.push(element);
                }
            });
            tmp = tmp.concat(me.listaFarmaciTerapiaInCorso);

            me.jsonData.listaTerapia = tmp;
        },
        listaFarmaciTerapiaProposta: function () {
            let me = this;
            let tmp = [];
            me.jsonData.listaTerapia.forEach((element) => {
                if (element.tipo !== "TERAPIA PROPOSTA") {
                    tmp.push(element);
                }
            });
            tmp = tmp.concat(me.listaFarmaciTerapiaProposta);

            me.jsonData.listaTerapia = tmp;
        },
        "jsonData.listaDiagnosi": function () {
            let me = this;
            if (me.jsonData.listaDiagnosi.length === 0) {
                me.jsonData.listaDiagnosi.push({ codiceIcdix: "", descrizioneIcdix: "" });
            }
        },
    },
    updated() {},
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
        me.loadCategorieFarmaci();
    },
    methods: {
        onCalcoloBmi(altezza, peso) {
            let me = this;
            me.jsonData.bmi = (peso / ((altezza / 100) * (altezza / 100))).toFixed(2);
        },
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                if (me.jsonData.listaDiagnosi.length === 0) {
                    me.jsonData.listaDiagnosi.push({ codiceIcdix: "", descrizioneIcdix: "" });
                }
                me.getLastVisitaControlloEcg();
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                axios
                    .get(link)
                    .then((response) => {
                        while (response.data.data.listaPressioneClino.length < 3) {
                            response.data.data.listaPressioneClino.push({ pressioneSistolica: "0", pressioneDiastolica: "0", frequenzaCardiaca: "0", tipoMisurazione: "CLINO" });
                        }
                        while (response.data.data.listaPressioneOrto.length < 3) {
                            response.data.data.listaPressioneOrto.push({ pressioneSistolica: "0", pressioneDiastolica: "0", frequenzaCardiaca: "0", tipoMisurazione: "ORTO" });
                        }
                        while (response.data.data.listaPressioneSitting.length < 3) {
                            response.data.data.listaPressioneSitting.push({ pressioneSistolica: "0", pressioneDiastolica: "0", frequenzaCardiaca: "0", tipoMisurazione: "SITTING" });
                        }

                        response.data.data.listaTerapia.forEach((element) => {
                            if (element.tipo === "TERAPIA IN CORSO") {
                                me.listaFarmaciTerapiaInCorso.push(element);
                            } else {
                                me.listaFarmaciTerapiaProposta.push(element);
                            }
                        });
                        me.getLastVisitaEsamiLaboratorio();
                        this.$emit("update", response.data.data);
                        // console.log(me.jsonData);
                        me.verificaPdta(response.data.data);
                        me.showModalLoading = false;
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },
        getLastVisitaControlloEcg() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/last/" + me.idPaziente;
            axios
                .get(link)
                .then((response) => {
                    response.data.data.listaTerapia.forEach((element) => {
                        if (element.tipo === "TERAPIA PROPOSTA") {
                            element.tipo = "TERAPIA IN CORSO";
                            me.listaFarmaciTerapiaInCorso.push(element);
                        }
                    });
                    // console.log(me.listaFarmaciTerapiaInCorso);
                    me.jsonData.altezza = response.data.data.altezza;
                })
                .catch((error) => {
                    console.log(error.message);
                });
        },
        // calcolaTotalePressione(pressioneSistolica, pressioneDiastolica) {
        //     let me = this;
        //     me.pressioneMassimaTotale += parseInt(pressioneSistolica);
        //     me.pressioneMinimaTotale += parseInt(pressioneDiastolica);
        // },
        calcolaTotalePressione(array) {
            // let me = this;
            // console.log("sono qui");
            let returnValue = { mediaMinima: 0, mediaMassima: 0, mediaFrequenzaCardiaca: 0 };
            let pressioneMassimaTotale = 0.0;
            let pressioneMinimaTotale = 0.0;
            let frequenzaCardiacaMediaTotale = 0.0;
            let numeroSistolica = 0;
            let numeroDiastolica = 0;
            let numeroFrequenza = 0;
            array.forEach((element) => {
                // console.log(element.pressioneSistolica);
                if (element.pressioneSistolica !== "" && element.pressioneSistolica !== "0") {
                    pressioneMassimaTotale += parseInt(element.pressioneSistolica);
                    numeroSistolica++;
                }
                if (element.pressioneDiastolica !== "" && element.pressioneDiastolica !== "0") {
                    pressioneMinimaTotale += parseInt(element.pressioneDiastolica);
                    numeroDiastolica++;
                }
                if (element.frequenzaCardiaca !== "" && element.frequenzaCardiaca !== "0") {
                    frequenzaCardiacaMediaTotale += parseInt(element.frequenzaCardiaca);
                    numeroFrequenza++;
                }
            });
            returnValue.mediaMassima = Math.trunc(pressioneMassimaTotale / numeroSistolica);
            returnValue.mediaMinima = Math.trunc(pressioneMinimaTotale / numeroDiastolica);
            returnValue.mediaFrequenzaCardiaca = Math.trunc(frequenzaCardiacaMediaTotale / numeroFrequenza);
            return returnValue;
        },
        calcolaTotalePressioneSitting(array) {
            // let me = this;
            let returnValue = { mediaMinima: 0, mediaMassima: 0, mediaFrequenzaCardiaca: 0 };
            let pressioneMassimaTotale = 0.0;
            let pressioneMinimaTotale = 0.0;
            let frequenzaCardiacaMediaTotale = 0.0;
            let numeroSistolica = 0;
            let numeroDiastolica = 0;
            let numeroFrequenza = 0;
            for (let i = 1; i < array.length; i++) {
                let element = array[i];
                if (element.pressioneSistolica !== "" && element.pressioneSistolica !== "0") {
                    pressioneMassimaTotale += parseInt(element.pressioneSistolica);
                    numeroSistolica++;
                }
                if (element.pressioneDiastolica !== "" && element.pressioneDiastolica !== "0") {
                    pressioneMinimaTotale += parseInt(element.pressioneDiastolica);
                    numeroDiastolica++;
                }
                if (element.frequenzaCardiaca !== "" && element.frequenzaCardiaca !== "0") {
                    frequenzaCardiacaMediaTotale += parseInt(element.frequenzaCardiaca);
                    numeroFrequenza++;
                }
            }
            returnValue.mediaMassima = Math.trunc(pressioneMassimaTotale / numeroSistolica);
            returnValue.mediaMinima = Math.trunc(pressioneMinimaTotale / numeroDiastolica);
            returnValue.mediaFrequenzaCardiaca = Math.trunc(frequenzaCardiacaMediaTotale / numeroFrequenza);
            return returnValue;
        },
        loadCategorieFarmaci() {
            let me = this;
            me.filtro.evento = "VISITA_CONTROLLO_ECG";
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceCategorieFarmaci;
            axios.get(link, { params: me.filtro }).then((response) => {
                me.listaCategorieFarmaci = response.data.data;
                me.showModalLoading = false;
            });
        },
        onInputEffettiCollaterali(value) {
            let me = this;
            if (value === "SI") {
                me.qualiEffettiCollateraliDisabled = false;
            } else {
                me.qualiEffettiCollateraliDisabled = true;
                me.jsonData.qualieffetti = "";
            }
        },
        onInputFumo(value) {
            let me = this;
            if (value === null) {
                me.jsonData.fumostrat1 = null;
            } else if (value === "NO" || value === "EX") {
                me.jsonData.fumostrat1 = "NO";
            } else {
                me.jsonData.fumostrat1 = "SI";
            }
        },
        onInputMonitoraggioPressione(value) {
            let me = this;
            if (value === "SI") {
                me.prossimoControlloDisabled = false;
            } else {
                me.prossimoControlloDisabled = true;
                me.jsonData.prossimoControllo = null;
                me.jsonData.oraProssimoControllo = null;
            }
        },
        onInputCheckbox(event, field) {
            // console.log(event);
            let me = this;
            me.jsonData[field] = event ? "SI" : "NO";
        },
        onClickCalcolaRischioCardiovascolare() {
            let me = this;
            let params = { colesteroloNonHdl: me.jsonData.colesterolemia, pressioneSistolica: me.jsonData.pressartsist, eta: me.jsonData.eta, sesso: me.jsonData.sesso, fumatore: me.jsonData.fumostrat1 };
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceCalcoloRischioCardiovascolare;
            axios.get(link, { params: params }).then((response) => {
                me.jsonData.rischioCardiovascolare = response.data.data.value;
                me.jsonData.rischioCardiovascolareColore = response.data.data.color;
                me.setColorByScoreColor();
                me.showModalLoading = false;
            });
        },
        setColorByScoreColor() {
            let me = this;
            if (me.jsonData.rischioCardiovascolareColore === "V") {
                // me.rischioCardiovascolareColorClass = "sa-data text-success sa-risk-alert-success";
                me.rischioCardiovascolareColorClass = "sa-data sa-risk-alert-success";
            } else if (me.jsonData.rischioCardiovascolareColore === "A") {
                // me.rischioCardiovascolareColorClass = "sa-data text-warning sa-risk-alert-warning";
                me.rischioCardiovascolareColorClass = "sa-data sa-risk-alert-warning";
            } else if (me.jsonData.rischioCardiovascolareColore === "R") {
                // me.rischioCardiovascolareColorClass = "sa-data text-danger sa-risk-alert-danger";
                me.rischioCardiovascolareColorClass = "sa-data sa-risk-alert-danger";
            } else {
                me.rischioCardiovascolareColorClass = "sa-data text-normal sa-risk-alert";
            }
        },
        getLastVisitaEsamiLaboratorio() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceLastEsamiLaboratorio + "/" + me.jsonData.idPaziente;
            axios
                .get(link)
                .then((response) => {
                    if (me.jsonData.colesterolemia === 0 || me.jsonData.colesterolemia === "" || me.jsonData.colesterolemia === null) {
                        me.jsonData.colesterolemia = response.data.data.colesterolo - response.data.data.hdl;
                    }
                })
                .catch(() => {});
        },
        onDelete(array, index) {
            array.splice(index, 1);
        },
        onUpdateListaFarmaciTerapiaInCorso(farmaci) {
            let me = this;
            farmaci.forEach((element) => {
                me.listaFarmaciTerapiaInCorso.push({ nomeFarmaco: element.descrizione, dosaggio: element.dosaggio, classe: element.classe, tipo: "TERAPIA IN CORSO" });
            });
        },
        onUpdateListaFarmaciTerapiaProposta(farmaci) {
            let me = this;
            farmaci.forEach((element) => {
                me.listaFarmaciTerapiaProposta.push({ nomeFarmaco: element.descrizione, dosaggio: element.dosaggio, classe: element.classe, tipo: "TERAPIA PROPOSTA" });
            });
        },
        getCheckedValue(value) {
            return value === "SI";
        },
        getCheckedClass(value) {
            return value === "SI" ? "sa-label-data text-success" : "sa-label-data";
        },
        onFocusOutCalcoloAbiSn() {
            let me = this;
            me.jsonData.abisn = (me.jsonData.pasaisn / me.jsonData.listaPressioneClino[0].pressioneSistolica).toFixed(2);
        },
        onFocusOutCalcoloAbiDx() {
            let me = this;
            me.jsonData.abidx = (me.jsonData.pasaidx / me.jsonData.listaPressioneClino[0].pressioneSistolica).toFixed(2);
        },
        onClickCopiaTerapiaInCorso() {
            let me = this;
            me.listaFarmaciTerapiaProposta = [];
            me.listaFarmaciTerapiaInCorso.forEach((element) => {
                me.listaFarmaciTerapiaProposta.push({ nomeFarmaco: element.nomeFarmaco, dosaggio: element.dosaggio, classe: element.classe, tipo: "TERAPIA PROPOSTA" });
            });
        },
        onUpdateDiagnosi(arrayDiagnosi) {
            let me = this;
            arrayDiagnosi.forEach((element) => {
                me.jsonData.listaDiagnosi.splice(me.jsonData.listaDiagnosi.length - 1, 0, { codiceIcdix: element.codiceIcdix, descrizioneIcdix: element.descrizioneIcdix });
            });
            // me.jsonData.listaDiagnosi.push({ codiceIcdix: "", descrizioneIcdix: "" });
        },
        onUpdatePressioniClinostatismo(array) {
            let me = this;
            me.jsonData.listaPressioneClino = null;
            me.jsonData.listaPressioneClino = array;
        },
        onUpdatePressioniOrtostatismo(array) {
            let me = this;
            me.jsonData.listaPressioneOrto = null;
            me.jsonData.listaPressioneOrto = array;
        },
        onUpdatePressioniSitting(array) {
            let me = this;
            me.jsonData.listaPressioneSitting = null;
            me.jsonData.listaPressioneSitting = array;
        },
        onUpdateNoteDiagnosi(data) {
            let me = this;
            me.jsonData.noteDiagnosi = data;
            this.$emit("update", me.jsonData);
        },
        onChangePdta() {
            let me = this;
            // console.log(me.pdta);
            me.resetEsami();
            if (me.pdta === "2") {
                me.setPdtaIpertensioneArteriosa();
            } else if (me.pdta === "3") {
                me.setPdtaScompensoCardiaco();
            } else if (me.pdta === "4") {
                me.setPdtaCardiopatiaIschemica();
            } else if (me.pdta === "5") {
                me.setPdtaPrevenzioneMalattieCardiovascolari();
            }
        },
        resetEsami() {
            let me = this;
            me.jsonData.monitoraggioPressione = "NO";
            me.jsonData.tacAddome = "NO";
            me.jsonData.cpkLdh = "NO";
            me.jsonData.ecoRenale = "NO";
            me.jsonData.microalbuminuria = "NO";
            me.jsonData.proteinuria = "NO";
            me.jsonData.dosaggioCatecolamine = "NO";
            me.jsonData.hba1c = "NO";
            me.jsonData.digossinemia = "NO";
            me.jsonData.pcr = "NO";
            me.jsonData.dosaggioOmocisteinemia = "NO";
            me.jsonData.acth = "NO";
            me.jsonData.tacCerebrale = "NO";
            me.jsonData.ecografiaTiroidea = "NO";
            me.jsonData.scintigrafiaTiroidea = "NO";
            me.jsonData.dosaggioElettrolitiUrinari = "NO";
            me.jsonData.glicemia = "NO";
            me.jsonData.azotemia = "NO";
            me.jsonData.creatinemia = "NO";
            me.jsonData.uricemia = "NO";
            me.jsonData.scintigrafiaMiocardica = "NO";
            me.jsonData.colesteroloTrigliceridi = "NO";
            me.jsonData.holterEcg = "NO";
            me.jsonData.testErgometrico = "NO";
            me.jsonData.angiocardiopneumoscint = "NO";
            me.jsonData.fondooculare = "NO";
            me.jsonData.cortisolemia820 = "NO";
            me.jsonData.dosaggioClinoOrto = "NO";
            me.jsonData.esameUrine = "NO";
            me.jsonData.dosaggioDopoCaptopril = "NO";
            me.jsonData.sodiemia = "NO";
            me.jsonData.potassioemia = "NO";
            me.jsonData.calcemia = "NO";
            me.jsonData.cloremia = "NO";
            me.jsonData.ptApttInr = "NO";
            me.jsonData.ecocardiografia = "NO";
            me.jsonData.ecotsa = "NO";
            me.jsonData.tsh = "NO";
            me.jsonData.ft4 = "NO";
            me.jsonData.ft3 = "NO";
            me.jsonData.markersEpatite = "NO";
            me.jsonData.ecografiaEpatobiliare = "NO";
            me.jsonData.emocromoPiastrine = "NO";
            me.jsonData.scintigrafiaRenale = "NO";
            me.jsonData.ecodopplerArtiInferiori = "NO";
            me.jsonData.ecodopplerTransCranico = "NO";
            me.jsonData.pet = "NO";
            me.jsonData.tcCoronarica = "NO";
            me.jsonData.ecostress = "NO";
            me.jsonData.magnesemia = "NO";
            me.jsonData.got = "NO";
            me.jsonData.gpt = "NO";
            me.jsonData.quadroProtElettroforetico = "NO";
            me.jsonData.amilasi = "NO";
            me.jsonData.yGt = "NO";
            me.jsonData.ntProBnp = "NO";
            me.jsonData.sideremia = "NO";
            me.jsonData.ferritinemia = "NO";
            me.jsonData.transferrinemia = "NO";
            me.jsonData.vitD25Oh = "NO";
            me.jsonData.troponinemiaT = "NO";
            me.jsonData.troponinemiaI = "NO";
            me.jsonData.anticorpiAntiTg = "NO";
            me.jsonData.anticorpiAntiTpo = "NO";
            me.jsonData.ecocolordopplerAortaAddominale = "NO";
            me.jsonData.ecocolordopplerArteriosoArtiInferiori = "NO";
            me.jsonData.ecocolordopplerArterieRenali = "NO";
            me.jsonData.ecostressFarmacologico = "NO";
            me.jsonData.rmnCardiaca = "NO";
            me.jsonData.angioTcTsa = "NO";
            me.jsonData.angioTcTorace = "NO";
            me.jsonData.angioTcAddome = "NO";
            me.jsonData.rmnCranio = "NO";
            me.jsonData.rmnTorace = "NO";
            me.jsonData.rmnAddome = "NO";
            me.jsonData.tacTorace = "NO";
            me.jsonData.ecografiaRenale = "NO";
            me.jsonData.ecografiaAddominale = "NO";
            me.jsonData.altroEsame = null;
            // me.$emit("update", me.jsonData);
        },
        setPdtaIpertensioneArteriosa() {
            let me = this;
            // me.resetEsami();
            me.jsonData.glicemia = "SI";
            me.jsonData.hba1c = "SI";
            me.jsonData.creatinemia = "SI";
            me.jsonData.uricemia = "SI";
            me.jsonData.sodiemia = "SI";
            me.jsonData.potassioemia = "SI";
            me.jsonData.calcemia = "SI";
            me.jsonData.colesteroloTrigliceridi = "SI";
            me.jsonData.emocromoPiastrine = "SI";
            me.jsonData.esameUrine = "SI";
            me.jsonData.microalbuminuria = "SI";
            me.$emit("update", me.jsonData);
        },
        setPdtaScompensoCardiaco() {
            let me = this;
            // me.resetEsami();
            me.jsonData.ntProBnp = "SI";
            me.jsonData.glicemia = "SI";
            me.jsonData.hba1c = "SI";
            me.jsonData.creatinemia = "SI";
            me.jsonData.uricemia = "SI";
            me.jsonData.sodiemia = "SI";
            me.jsonData.potassioemia = "SI";
            me.jsonData.colesteroloTrigliceridi = "SI";
            me.jsonData.got = "SI";
            me.jsonData.quadroProtElettroforetico = "SI";
            me.jsonData.emocromoPiastrine = "SI";
            me.jsonData.esameUrine = "SI";
            me.jsonData.microalbuminuria = "SI";
            me.jsonData.ecocardiografia = "SI";
            (me.jsonData.sideremia = "SI"), (me.jsonData.ferritinemia = "SI"), (me.jsonData.transferrinemia = "SI"), me.$emit("update", me.jsonData);
        },
        setPdtaCardiopatiaIschemica() {
            let me = this;
            // me.resetEsami();
            me.jsonData.glicemia = "SI";
            me.jsonData.hba1c = "SI";
            me.jsonData.creatinemia = "SI";
            me.jsonData.uricemia = "SI";
            me.jsonData.sodiemia = "SI";
            me.jsonData.potassioemia = "SI";
            me.jsonData.colesteroloTrigliceridi = "SI";
            me.jsonData.got = "SI";
            me.jsonData.quadroProtElettroforetico = "SI";
            me.jsonData.emocromoPiastrine = "SI";
            me.jsonData.esameUrine = "SI";
            me.jsonData.microalbuminuria = "SI";
            me.jsonData.ecocardiografia = "SI";
            me.$emit("update", me.jsonData);
        },
        setPdtaPrevenzioneMalattieCardiovascolari() {
            let me = this;
            me.jsonData.glicemia = "SI";
            me.jsonData.hba1c = "SI";
            me.jsonData.creatinemia = "SI";
            me.jsonData.uricemia = "SI";
            me.jsonData.sodiemia = "SI";
            me.jsonData.potassioemia = "SI";
            me.jsonData.calcemia = "SI";
            me.jsonData.colesteroloTrigliceridi = "SI";
            me.jsonData.got = "SI";
            me.jsonData.quadroProtElettroforetico = "SI";
            me.jsonData.emocromoPiastrine = "SI";
            me.jsonData.tsh = "SI";
            me.jsonData.esameUrine = "SI";
            me.jsonData.microalbuminuria = "SI";
            me.$emit("update", me.jsonData);
        },
        verificaPdta(data) {
            let me = this;
            me.creaListaEsamiSelezionati(data);
            if (me.listaEsamiSelezionati.length > 0) {
                if (me.controlloSceltaPdtaDaEsamiSelezionati(me.listaEsamiPdtaQuattro)) {
                    me.pdta = 5;
                } else if (me.controlloSceltaPdtaDaEsamiSelezionati(me.listaEsamiPdtaDue)) {
                    me.pdta = 3;
                } else if (me.controlloSceltaPdtaDaEsamiSelezionati(me.listaEsamiPdtaTre)) {
                    me.pdta = 4;
                } else if (me.controlloSceltaPdtaDaEsamiSelezionati(me.listaEsamiPdtaUno)) {
                    me.pdta = 2;
                }
            }
        },
        controlloSceltaPdtaDaEsamiSelezionati(listaPdta) {
            let me = this;
            let returnValue = true;
            listaPdta.forEach((element) => {
                let presente = false;
                me.listaEsamiSelezionati.forEach((elementoSelezionato) => {
                    if (elementoSelezionato.campo === element.campo) {
                        presente = true;
                    }
                });
                if (!presente) {
                    returnValue = false;
                }
            });
            return returnValue;
        },
        creaListaEsamiSelezionati(data) {
            let me = this;
            // console.log(data.glicemia);
            if (data.monitoraggioPressione === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "monitoraggioPressione" });
            }
            if (data.tacAddome === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "tacAddome" });
            }
            if (data.cpkLdh === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "cpkLdh" });
            }
            if (data.ecoRenale === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "ecoRenale" });
            }
            if (data.microalbuminuria === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "microalbuminuria" });
            }
            if (data.proteinuria === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "proteinuria" });
            }
            if (data.dosaggioCatecolamine === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "dosaggioCatecolamine" });
            }
            if (data.hba1c === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "hba1c" });
            }
            if (data.digossinemia === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "digossinemia" });
            }
            if (data.pcr === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "pcr" });
            }
            if (data.dosaggioOmocisteinemia === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "dosaggioOmocisteinemia" });
            }
            if (data.acth === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "acth" });
            }
            if (data.tacCerebrale === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "tacCerebrale" });
            }
            if (data.ecografiaTiroidea === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "ecografiaTiroidea" });
            }
            if (data.scintigrafiaTiroidea === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "scintigrafiaTiroidea" });
            }
            if (data.dosaggioElettrolitiUrinari === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "dosaggioElettrolitiUrinari" });
            }
            if (data.glicemia === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "glicemia" });
            }
            if (data.azotemia === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "azotemia" });
            }
            if (data.creatinemia === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "creatinemia" });
            }
            if (data.uricemia === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "uricemia" });
            }
            if (data.scintigrafiaMiocardica === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "scintigrafiaMiocardica" });
            }
            if (data.colesteroloTrigliceridi === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "colesteroloTrigliceridi" });
            }
            if (data.holterEcg === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "holterEcg" });
            }
            if (data.testErgometrico === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "testErgometrico" });
            }
            if (data.angiocardiopneumoscint === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "angiocardiopneumoscint" });
            }
            if (data.fondooculare === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "fondooculare" });
            }
            if (data.cortisolemia820 === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "cortisolemia820" });
            }
            if (data.dosaggioClinoOrto === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "dosaggioClinoOrto" });
            }
            if (data.esameUrine === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "esameUrine" });
            }
            if (data.dosaggioDopoCaptopril === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "dosaggioDopoCaptopril" });
            }
            if (data.sodiemia === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "sodiemia" });
            }
            if (data.potassioemia === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "potassioemia" });
            }
            if (data.calcemia === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "calcemia" });
            }
            if (data.cloremia === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "cloremia" });
            }
            if (data.ptApttInr === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "ptApttInr" });
            }
            if (data.ecocardiografia === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "ecocardiografia" });
            }
            if (data.ecotsa === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "ecotsa" });
            }
            if (data.tsh === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "tsh" });
            }
            if (data.ft4 === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "ft4" });
            }
            if (data.ft3 === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "ft3" });
            }
            if (data.markersEpatite === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "markersEpatite" });
            }
            if (data.ecografiaEpatobiliare === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "ecografiaEpatobiliare" });
            }
            if (data.emocromoPiastrine === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "emocromoPiastrine" });
            }
            if (data.scintigrafiaRenale === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "scintigrafiaRenale" });
            }
            if (data.ecodopplerArtiInferiori === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "ecodopplerArtiInferiori" });
            }
            if (data.ecodopplerTransCranico === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "ecodopplerTransCranico" });
            }
            if (data.pet === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "pet" });
            }
            if (data.tcCoronarica === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "tcCoronarica" });
            }
            if (data.ecostress === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "ecostress" });
            }
            if (data.magnesemia === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "magnesemia" });
            }
            if (data.got === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "got" });
            }
            if (data.gpt === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "gpt" });
            }
            if (data.quadroProtElettroforetico === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "quadroProtElettroforetico" });
            }
            if (data.amilasi === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "amilasi" });
            }
            if (data.yGt === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "yGt" });
            }
            if (data.ntProBnp === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "ntProBnp" });
            }
            if (data.sideremia === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "sideremia" });
            }
            if (data.ferritinemia === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "ferritinemia" });
            }
            if (data.transferrinemia === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "transferrinemia" });
            }
            if (data.vitD25Oh === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "vitD25Oh" });
            }
            if (data.troponinemiaT === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "troponinemiaT" });
            }
            if (data.troponinemiaI === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "troponinemiaI" });
            }
            if (data.anticorpiAntiTg === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "anticorpiAntiTg" });
            }
            if (data.anticorpiAntiTpo === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "anticorpiAntiTpo" });
            }
            if (data.ecocolordopplerAortaAddominale === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "ecocolordopplerAortaAddominale" });
            }
            if (data.ecocolordopplerArteriosoArtiInferiori === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "ecocolordopplerArteriosoArtiInferiori" });
            }
            if (data.ecocolordopplerArterieRenali === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "ecocolordopplerArterieRenali" });
            }
            if (data.ecostressFarmacologico === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "ecostressFarmacologico" });
            }
            if (data.rmnCardiaca === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "rmnCardiaca" });
            }
            if (data.angioTcTsa === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "angioTcTsa" });
            }
            if (data.angioTcTorace === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "angioTcTorace" });
            }
            if (data.angioTcAddome === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "angioTcAddome" });
            }
            if (data.rmnCranio === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "rmnCranio" });
            }
            if (data.rmnTorace === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "rmnTorace" });
            }
            if (data.rmnAddome === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "rmnAddome" });
            }
            if (data.tacTorace === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "tacTorace" });
            }
            if (data.ecografiaRenale === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "ecografiaRenale" });
            }
            if (data.ecografiaAddominale === "SI") {
                me.listaEsamiSelezionati.push({ descrizione: "", campo: "ecografiaAddominale" });
            }
            // console.log(me.listaEsamiSelezionati.length);
        },
        onClickCalcolaValoriPaClino() {
            let me = this;
            // console.log(me.jsonData.listaPressioneClino);
            let medie = me.calcolaTotalePressione(me.jsonData.listaPressioneClino);
            me.jsonData.paclinomaxm = medie.mediaMassima.toString();
            me.jsonData.paclinominm = medie.mediaMinima.toString();
            me.jsonData.mediaFrequenzaCardiacaClino = medie.mediaFrequenzaCardiaca.toString();
        },
        onClickCalcolaValoriPaOrto() {
            let me = this;
            let medie = me.calcolaTotalePressione(me.jsonData.listaPressioneOrto);
            me.jsonData.paortomaxm = medie.mediaMassima.toString();
            me.jsonData.paortominm = medie.mediaMinima.toString();
            me.jsonData.mediaFrequenzaCardiacaOrto = medie.mediaFrequenzaCardiaca.toString();
        },
        onClickCalcolaValoriPaSitting() {
            let me = this;
            // console.log(array);
            let medie = me.calcolaTotalePressioneSitting(me.jsonData.listaPressioneSitting);
            me.jsonData.pasitmaxm = medie.mediaMassima.toString();
            me.jsonData.pasitminm = medie.mediaMinima.toString();
            me.jsonData.mediaFrequenzaCardiacaSitting = medie.mediaFrequenzaCardiaca.toString();
            me.jsonData.pressartsist = me.jsonData.pasitmaxm;
        },
    },
};
</script>

<style>
.sa-visita-controllo-ecg-row-custom .row div {
    padding-bottom: 3px;
}

.sa-risk-alert-danger,
.sa-risk-alert-success,
.sa-risk-alert-warning {
    height: 50px;
    width: 50px;
    border-radius: 25px;
    display: block;
    align-content: center;
    font-size: 1.6rem !important;
    text-align: center;
    padding-top: 8px;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
}
.sa-risk-alert-danger {
    /* border: 2px solid red; */
    background-color: red;
    color: white;
}

.sa-risk-alert-success {
    /* border: 2px solid #28a745; */
    background-color: #28a745;
    color: white;
}

.sa-risk-alert-warning {
    /* border: 2px solid #ffc107; */
    background-color: #ffc107;
    color: white;
}
</style>
