<template>
    <sa-page-layout :btnAnnullaVisible="true" :btnSaveNotBackVisible="true" :btnSaveVisible="true" :btnSaveDisabled="false" :linkback="linkback" :data="jsonData" :showModalLoading="showModalLoading" :pathResource="pathResource" class="sa-no-space">
        <template slot="table-body">
            <agenda-edit-component ref="cmpAgendaEditComponent" @updateAgenda="updateAgenda"></agenda-edit-component>
        </template>
    </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import AgendaEditComponent from "../components/AgendaEditComponent.vue";
export default {
    components: { SaPageLayout, AgendaEditComponent },
    data() {
        return {
            linkback: "/agende",
            showModalLoading: false,
            pathResource: "/agende",
            jsonData: null,
        };
    },
    methods: {
        updateAgenda(agenda) {
            let me = this;
            me.jsonData = agenda;
        },
    },
};
</script>

<style></style>
