<template>
    <sa-page-layout :btnAnnullaVisible="true" :btnSaveVisible="true" :linkback="linkback" :pathResource="pathResource" :data="jsonData" :showModalLoading="showModalLoading" class="sa-no-space">
        <template slot="table-body">
            <div class="sa-tab-scroll">
                <b-card class="sa-card" header="Dati Struttura" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Codice Struttura</label>
                            <b-form-select v-model="jsonData.codiceStruttura" :options="struttureOptions" :value="''" value-field="value" text-field="text" :disabled="struttureOptions.length < 3"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Data Invio</label>
                            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataPrimoInvio" type="date"></date-picker>
                        </b-col>
                        <!-- <b-col xs="12" sm="12" md="6" lg="3" xl="3">
                            <label class="sa-label-data">Data Primo Aggiornamento</label>
                            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataPrimoAggiornamento" type="date"></date-picker>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="3" xl="3">
                            <label class="sa-label-data">Data Secondo Aggiornamento</label>
                            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataSecondoAggiornamento" type="date"></date-picker>
                        </b-col> -->
                    </b-row>
                </b-card>
                <b-card class="sa-card" header="Dati Paziente" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Numero Codice Identificativo <b-icon icon="info-circle" v-b-popover.hover.top="'I e III lettera del cognome, I e III lettera del nome, data di nascita (anno ultime due cifre) e sesso'"></b-icon></label>
                            <b-input-group>
                                <b-form-input maxlength="11" v-model="jsonData.numeroCodiceIdentificativo"></b-form-input>
                                <b-input-group-append>
                                    <b-button @click="onClickRicercaAnagrafica()"><b-icon icon="search"></b-icon></b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Data Nascita</label>
                            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataNascita" type="date"></date-picker>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Sesso</label>
                            <b-form-select v-model="jsonData.sesso" :options="sessoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Tipo Paziente</label>
                            <b-form-select v-model="jsonData.tipoPaziente" :options="tipoPazienteOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Tipo Donatore</label>
                            <b-form-select v-model="jsonData.tipoPazienteDonatore" :options="tipoPazienteDonatoreOptions" :value="''" value-field="value" text-field="text" :disabled="chekCondition(jsonData.tipoPaziente, 'PAZIENTE DONATORE')"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Provincia Residenza</label>
                            <b-form-select v-model="jsonData.provinciaResidenza" :options="provinceOptions" :value="''" value-field="sigla" text-field="provincia" @input="onInputProvinciaResidenza"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Comune Residenza</label>
                            <b-form-select v-model="jsonData.comuneResidenza" :options="comuniOptions" :value="''" value-field="codiceIstat" text-field="comune"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Asl Residenza</label>
                            <b-form-select v-model="jsonData.aslResidenza" :options="aslOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Telefono</label>
                            <b-form-input v-model="jsonData.telefono"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Cittadinanza Italiana</label>
                            <b-form-select v-model="jsonData.cittadinanzaItaliana" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputCittadinanza"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Paese Cittadinanza Straniera</label>
                            <b-form-select v-model="jsonData.paeseCittadinanzaStraniera" :options="paeseCittadinanzaStranieraOptions" :value="''" value-field="codiceIstat" text-field="comune" :disabled="disabledValue.cittadinanzaStranieraDisabled"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Altre Patologie Presenti</label>
                            <b-form-select v-model="jsonData.altrePatologie" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputAltrePatologie"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Specificare Eventuali Patologie</label>
                            <b-form-textarea v-model="jsonData.altrePatologieAltro" rows="6" no-resize :disabled="disabledValue.altrePatologieAltroDisbled"></b-form-textarea>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card class="sa-card" header="Comportamento a Rischio Dichiarato" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Uso iniettivo di droghe</label>
                            <b-form-select v-model="jsonData.drogheIniettate" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputDrogheIniettate"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Anno Inizio Droghe Iniettate</label>
                            <b-form-input v-model="jsonData.annoInizioDrogheIniettate" :disabled="disabledValue.drogheIniettateDisabled"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Anno Fine Droghe Iniettate</label>
                            <b-form-input v-model="jsonData.annoFineDrogheIniettate" :disabled="disabledValue.drogheIniettateDisabled"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Trasfusione o uso di emoderivati precedentemente al 1987</label>
                            <b-form-select v-model="jsonData.trasfusioneEmoderivati" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Prostituzione Senza Tossicodipendenza</label>
                            <b-form-select v-model="jsonData.prostituzioneSenzaTossicodipendenza" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputComportamentiRischio(jsonData.prostituzioneSenzaTossicodipendenza, 'prostituzioneUsoProfilattico', 'prostituzioneUsoProfilatticoDisabled')"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Uso Profilattico</label>
                            <b-form-select v-model="jsonData.prostituzioneUsoProfilattico" :options="usoProfilatticoOptions" :value="''" value-field="value" text-field="text" :disabled="disabledValue.prostituzioneUsoProfilatticoDisabled"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Rapporti Omosessuali Sieropositivo Noto</label>
                            <b-form-select v-model="jsonData.rapportiOmosessualiSieropositivoNoto" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputComportamentiRischio(jsonData.rapportiOmosessualiSieropositivoNoto, 'rapportiOmosessualiSieropositivoNotoUsoProfilattico', 'rapportiOmosessualiSieropositivoNotoUsoProfilatticoDisabled')"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Uso Profilattico</label>
                            <b-form-select v-model="jsonData.rapportiOmosessualiSieropositivoNotoUsoProfilattico" :options="usoProfilatticoOptions" :value="''" value-field="value" text-field="text" :disabled="disabledValue.rapportiOmosessualiSieropositivoNotoUsoProfilatticoDisabled"></b-form-select>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Rapporti Omosessuali Sieropositivo Non Noto</label>
                            <b-form-select v-model="jsonData.rapportiOmosessualiSieropositivoNonNoto" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputComportamentiRischio(jsonData.rapportiOmosessualiSieropositivoNonNoto, 'rapportiOmosessualiSieropositivoNonNotoUsoProfilattico', 'rapportiOmosessualiSieropositivoNonNotoUsoProfilatticoDisabled')"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Uso Profilattico</label>
                            <b-form-select v-model="jsonData.rapportiOmosessualiSieropositivoNonNotoUsoProfilattico" :options="usoProfilatticoOptions" :value="''" value-field="value" text-field="text" :disabled="disabledValue.rapportiOmosessualiSieropositivoNonNotoUsoProfilatticoDisabled"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="32" xl="3">
                            <label class="sa-label-data">Rapporti Eterosessuali Sieropositivo Noto</label>
                            <b-form-select v-model="jsonData.rapportiEterosessualiSieropositivoNoto" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputComportamentiRischio(jsonData.rapportiEterosessualiSieropositivoNoto, 'rapportiEterosessualiSieropositivoNotoUsoProfilattico', 'rapportiEterosessualiSieropositivoNotoUsoProfilatticoDisabled')"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Uso Profilattico</label>
                            <b-form-select v-model="jsonData.rapportiEterosessualiSieropositivoNotoUsoProfilattico" :options="usoProfilatticoOptions" :value="''" value-field="value" text-field="text" :disabled="disabledValue.rapportiEterosessualiSieropositivoNotoUsoProfilatticoDisabled"></b-form-select>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Rapporti Eterosessuali Sieropositivo Non Noto</label>
                            <b-form-select v-model="jsonData.rapportiEterosessualiSieropositivoNonNoto" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputComportamentiRischio(jsonData.rapportiEterosessualiSieropositivoNonNoto, 'rapportiEterosessualiSieropositivoNonNotoUsoProfilattico', 'rapportiEterosessualiSieropositivoNonNotoUsoProfilatticoDisabled')"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Uso Profilattico</label>
                            <b-form-select v-model="jsonData.rapportiEterosessualiSieropositivoNonNotoUsoProfilattico" :options="usoProfilatticoOptions" :value="''" value-field="value" text-field="text" :disabled="disabledValue.rapportiEterosessualiSieropositivoNonNotoUsoProfilatticoDisabled"></b-form-select>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Rifiuto Indagine Anamnestica</label>
                            <b-form-select v-model="jsonData.rifiutoIndagineAnamnestica" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Madre Sieropositiva</label>
                            <b-form-select v-model="jsonData.madreSieropositiva" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Convivente Sieropositivo</label>
                            <b-form-select v-model="jsonData.conviventeSieropositivo" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Partner Sessuale Stabile Sieropositivo</label>
                            <b-form-select v-model="jsonData.partnerSessualeStabileSieropositivo" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputComportamentiRischio(jsonData.partnerSessualeStabileSieropositivo, 'partnerSessualeStabileSieropositivoUsoProfilattico', 'partnerSessualeStabileSieropositivoUsoProfilatticoDisabled')"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Uso Profilattico</label>
                            <b-form-select v-model="jsonData.partnerSessualeStabileSieropositivoUsoProfilattico" :options="usoProfilatticoOptions" :value="''" value-field="value" text-field="text" :disabled="disabledValue.partnerSessualeStabileSieropositivoUsoProfilatticoDisabled"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Rapporti Sessuali Con Prostitute</label>
                            <b-form-select v-model="jsonData.rapportiSessualiProstitute" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputComportamentiRischio(jsonData.rapportiSessualiProstitute, 'rapportiSessualiProstituteUsoProfilattico', 'rapportiSessualiProstituteUsoProfilatticoDisabled')"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Uso Profilattico</label>
                            <b-form-select v-model="jsonData.rapportiSessualiProstituteUsoProfilattico" :options="usoProfilatticoOptions" :value="''" value-field="value" text-field="text" :disabled="disabledValue.rapportiSessualiProstituteUsoProfilatticoDisabled"></b-form-select>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Rapporti Sessuali Con Persone Provenienti da Paesi ad Alta Prevalenza</label>
                            <b-form-select v-model="jsonData.rapportiSessualiPaeseAltaPrevalenza" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputComportamentiRischio(jsonData.rapportiSessualiPaeseAltaPrevalenza, 'rapportiSessualiPaeseAltaPrevalenzaUsoProfilattico', 'rapportiSessualiPaeseAltaPrevalenzaUsoProfilatticoDisable')"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Uso Profilattico</label>
                            <b-form-select v-model="jsonData.rapportiSessualiPaeseAltaPrevalenzaUsoProfilattico" :options="usoProfilatticoOptions" :value="''" value-field="value" text-field="text" :disabled="disabledValue.rapportiSessualiPaeseAltaPrevalenzaUsoProfilatticoDisable"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Rapporti Sessuali Con Persone Che Utilizzano Droghe per Via Iniettiva</label>
                            <b-form-select v-model="jsonData.rapportiSessualiPersoneUtilizzoDrogheIniettive" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputComportamentiRischio(jsonData.rapportiSessualiPersoneUtilizzoDrogheIniettive, 'rapportiSessualiPersoneUtilizzoDrogheIniettiveUsoProfila', 'rapportiSessualiPersoneUtilizzoDrogheIniettiveUsoProfilatticoDisabled')"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Uso Profilattico</label>
                            <b-form-select v-model="jsonData.rapportiSessualiPersoneUtilizzoDrogheIniettiveUsoProfila" :options="usoProfilatticoOptions" :value="''" value-field="value" text-field="text" :disabled="disabledValue.rapportiSessualiPersoneUtilizzoDrogheIniettiveUsoProfilatticoDisabled"></b-form-select>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Nessun Rapporto con persone che rientrano nelle risposte precedenti</label>
                            <b-form-select v-model="jsonData.nessunRapportoRispostePrecedenti" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Presunto Luogo Infezione</label>
                            <b-form-select v-model="jsonData.presuntoLuogoInfezione" :options="presuntoLuogoInfezioneOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Data Ultima Possibile Esposizione</label>
                            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataUltimaPossibileEsposizione" type="date"></date-picker>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Motivo Esecuzione Test</label>
                            <b-form-select v-model="jsonData.motivoEsecuzioneTest" :options="motivoEsecuzioneTestOptions" :value="''" value-field="value" text-field="text" @input="onMotivoEsecuzioneTest"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Altro</label>
                            <b-form-textarea v-model="jsonData.motivoEsecuzioneTestAltro" rows="8" no-resize :disabled="disabledValue.motivoEsecuzioneTestAltroDisabled"></b-form-textarea>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card class="sa-card" header="Dati Test" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Test Eseguito Precedentemente</label>
                            <b-form-select v-model="jsonData.testEseguitoPrecedentemente" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputTestEseguito"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Data Esecuzione Test</label>
                            <!-- <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataEsecuzioneTest" type="date" :disabled="disabledValue.dataEsecuzioneTestDisabled"></date-picker> -->
                            <b-form-input v-model="jsonData.dataEsecuzioneTest" :disabled="disabledValue.dataEsecuzioneTestDisabled"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Data Prelievo</label>
                            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataPrimoPrelievo" type="date"></date-picker>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Tipo Di Test</label>
                            <b-form-select v-model="jsonData.tipoTest" :options="tipiTestOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Esito</label>
                            <b-form-select v-model="jsonData.esitoPrimoPrelievo" :options="esitiOptions" :value="''" value-field="value" text-field="text" @change="onChangeEsito"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Se Negativo Indicare Data Riesecuzione</label>
                            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataRiesecuzionePrimoPrelievo" type="date" :disabled="disableDataRiesecuzione"></date-picker>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">E' stato contattato il centro diagnosi e cura</label>
                            <b-form-select v-model="jsonData.esitoPrimoPrelievoCentroDiagnosiCura" :options="siNoOptions" :value="''" value-field="value" text-field="text" @change="onChangeContattatoCentroDiagnosi" :disabled="disableDataReattivoIndeterminato"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Nome Centro Diagnosi Cura</label>
                            <b-form-input v-model="jsonData.primoPrelievoNomeCentroDiagnosiCura" :disabled="disableSceltaContattoCentroDiagnosiCure"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Data Appuntamento</label>
                            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataPrimoAppuntamentoCentroDiagnosiCura" type="date" :disabled="disableSceltaContattoCentroDiagnosiCure"></date-picker>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Data Consegna Referto</label>
                            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataConsegnaPrimoReferto" type="date"></date-picker>
                        </b-col>
                    </b-row>
                    <!-- <b-row>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Data Secondo Prelievo</label>
                            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataSecondoPrelievo" type="date"></date-picker>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Esito Secondo Prelievo</label>
                            <b-form-select v-model="jsonData.esitoSecondoPrelievo" :options="esitiOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Data Riesecuzione del Test</label>
                            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataRiesecuzioneSecondoPrelievo" type="date"></date-picker>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">E' stato contattato il Centro Diagnosi e Cura</label>
                            <b-form-select v-model="jsonData.esitoSecondoPrelievoCentroDiagnosiCura" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Nome Centro Diagnosi e Cura</label>
                            <b-form-input v-model="jsonData.secondoPrelievoNomeCentroDiagnosiCura"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Data Secondo Appuntamento</label>
                            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataSecondoAppuntamentoCentroDiagnosiCura" type="date"></date-picker>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Data Consegna Secondo Referto</label>
                            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataConsegnaSecondoReferto" type="date"></date-picker>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Data Terzo Prelievo</label>
                            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataTerzoPrelievo" type="date"></date-picker>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Esito Terzo Prelievo</label>
                            <b-form-select v-model="jsonData.esitoTerzoPrelievo" :options="esitiOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Data Riesecuzione del Test</label>
                            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataRiesecuzioneTerzoPrelievo" type="date"></date-picker>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">E' stato contattato il Centro Diagnosi e Cura</label>
                            <b-form-select v-model="jsonData.esitoTerzoPrelievoCentroDiagnosiCura" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Nome Centro Diagnosi e Cura</label>
                            <b-form-input v-model="jsonData.terzoPrelievoNomeCentroDiagnosiCura"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Data Terzo Appuntamento</label>
                            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataTerzoAppuntamentoCentroDiagnosiCura" type="date"></date-picker>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Data Consegna Terzo Referto</label>
                            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataConsegnaTerzoReferto" type="date"></date-picker>
                        </b-col>
                    </b-row> -->
                </b-card>
                <b-card class="sa-card" header="Dati Compilazione" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Data Compilazione</label>
                            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataCompilazione" type="date"></date-picker>
                        </b-col>
                        <!-- <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Responsabile Compilazione</label>
              <b-form-input v-model="jsonData.responsabileCompilazione"></b-form-input>
            </b-col> -->
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Personale sanitario che ha curato il counselling pre-test</label>
                            <b-form-input v-model="jsonData.personaleSanitarioCounsellingPreTest"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Psicologo che ha curato il counselling post test</label>
                            <b-form-input v-model="jsonData.psicologoCounsellingPostTest"></b-form-input>
                        </b-col>
                    </b-row>
                </b-card>
            </div>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import UtilityMixin from "../../../utility/UtilityMixin";
import DatePicker from "vue2-datepicker";
export default {
    mixins: [UtilityMixin],
    components: { SaPageLayout, DatePicker },
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    data() {
        return {
            pathResource: "/malattieinfettivehiv",
            pathResourceProvince: "/province",
            pathResourceComuni: "/comuni/provincia",
            pathResourceAsl: "/asl/regione",
            pathResourceRicercaAnagrafica: "/malattieinfettivehiv/ultimaanagrafica",
            pathResourceStrutture: "/malattieinfettivestruttureutenti/struttura",
            linkback: null,
            showModalLoading: false,
            disableDataRiesecuzione: true,
            disableDataReattivoIndeterminato: true,
            disableSceltaContattoCentroDiagnosiCure: true,
            id: "-1",
            disabledValue: {
                drogheIniettateDisabled: true,
                cittadinanzaStranieraDisabled: true,
                prostituzioneUsoProfilatticoDisabled: true,
                rapportiOmosessualiSieropositivoNotoUsoProfilatticoDisabled: true,
                rapportiOmosessualiSieropositivoNonNotoUsoProfilatticoDisabled: true,
                rapportiEterosessualiSieropositivoNotoUsoProfilatticoDisabled: true,
                rapportiEterosessualiSieropositivoNonNotoUsoProfilatticoDisabled: true,
                partnerSessualeStabileSieropositivoUsoProfilatticoDisabled: true,
                rapportiSessualiProstituteUsoProfilatticoDisabled: true,
                rapportiSessualiPaeseAltaPrevalenzaUsoProfilatticoDisable: true,
                rapportiSessualiPersoneUtilizzoDrogheIniettiveUsoProfilatticoDisabled: true,
                motivoEsecuzioneTestAltroDisabled: true,
                dataEsecuzioneTestDisabled: true,
                altrePatologieAltroDisbled: true,
            },
            jsonData: {
                provinciaResidenza: "",
                prostituzioneSenzaTossicodipendenza: "",
                // dataTerzoAppuntamentoCentroDiagnosiCura: null,
                personaleSanitarioCounsellingPreTest: null,
                rapportiOmosessualiSieropositivoNotoUsoProfilattico: "",
                // dataSecondoPrelievo: null,
                rapportiEterosessualiSieropositivoNotoUsoProfilattico: "",
                aslResidenza: null,
                comuneResidenza: "",
                prostituzioneUsoProfilattico: "",
                rifiutoIndagineAnamnestica: "",
                rapportiSessualiPersoneUtilizzoDrogheIniettiveUsoProfila: "",
                // dataConsegnaSecondoReferto: null,
                dataNascita: null,
                annoFineDrogheIniettate: "",
                rapportiEterosessualiSieropositivoNonNotoUsoProfilattico: "",
                rapportiSessualiPersoneUtilizzoDrogheIniettive: "",
                telefono: null,
                esitoPrimoPrelievoCentroDiagnosiCura: "",
                rapportiOmosessualiSieropositivoNoto: "",
                esitoPrimoPrelievo: "",
                dataPrimoAppuntamentoCentroDiagnosiCura: null,
                dataEsecuzioneTest: null,
                presuntoLuogoInfezione: "",
                motivoEsecuzioneTestAltro: "",
                conviventeSieropositivo: "",
                // dataTerzoPrelievo: null,
                paeseCittadinanzaStraniera: "",
                // dataConsegnaTerzoReferto: null,
                numeroCodiceIdentificativo: null,
                partnerSessualeStabileSieropositivo: "",
                rapportiSessualiProstituteUsoProfilattico: "",
                secondoPrelievoNomeCentroDiagnosiCura: "",
                drogheIniettate: "",
                // esitoTerzoPrelievoCentroDiagnosiCura: "",
                motivoEsecuzioneTest: "",
                // dataSecondoAppuntamentoCentroDiagnosiCura: null,
                dataPrimoInvio: null,
                // esitoSecondoPrelievoCentroDiagnosiCura: "",
                rapportiSessualiPaeseAltaPrevalenzaUsoProfilattico: "",
                dataPrimoPrelievo: null,
                rapportiEterosessualiSieropositivoNonNoto: "",
                dataCompilazione: null,
                annoInizioDrogheIniettate: "",
                rapportiOmosessualiSieropositivoNonNotoUsoProfilattico: "",
                responsabileCompilazione: null,
                partnerSessualeStabileSieropositivoUsoProfilattico: "",
                // esitoSecondoPrelievo: "",
                primoPrelievoNomeCentroDiagnosiCura: "",
                // esitoTerzoPrelievo: "",
                // dataSecondoAggiornamento: null,
                rapportiSessualiProstitute: "",
                nessunRapportoRispostePrecedenti: "",
                testEseguitoPrecedentemente: "",
                madreSieropositiva: "",
                dataUltimaPossibileEsposizione: null,
                dataConsegnaPrimoReferto: null,
                codiceStruttura: null,
                rapportiEterosessualiSieropositivoNoto: "",
                rapportiSessualiPaeseAltaPrevalenza: "",
                dataRiesecuzionePrimoPrelievo: null,
                rapportiOmosessualiSieropositivoNonNoto: "",
                sesso: "",
                // dataPrimoAggiornamento: null,
                trasfusioneEmoderivati: "",
                cittadinanzaItaliana: "",
                psicologoCounsellingPostTest: null,
                // terzoPrelievoNomeCentroDiagnosiCura: "",
                // dataRiesecuzioneSecondoPrelievo: null,
                // dataRiesecuzioneTerzoPrelievo: null,
                altrePatologie: "", // DA AGGIUNGERE NEL DB E NEL BACKEND
                altrePatologieAltro: "", // DA AGGIUNGERE NEL DB E NEL BACKEND
                tipoTest: "", // DA AGGIUNGERE NEL DB E NEL BACKEND
                tipoPaziente: "",
                tipoPazienteDonatore: "",
            },
            sessoOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "M", text: "MASCHIO" },
                { value: "F", text: "FEMMINA" },
            ],
            siNoOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "SI", text: "SI" },
                { value: "NO", text: "NO" },
            ],
            usoProfilatticoOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "COSTANTE", text: "COSTANTE" },
                { value: "SALTUARIO", text: "SALTUARIO" },
                { value: "ASSENTE", text: "ASSENTE" },
            ],
            presuntoLuogoInfezioneOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "CAMPANIA", text: "CAMPANIA" },
                { value: "ALTRA REGIONE", text: "ALTRA REGIONE" },
                { value: "STATO ESTERO", text: "STATO ESTERO" },
            ],
            motivoEsecuzioneTestOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "CONTROLLO PER COMPORTAMENTI A RISCHIO", text: "CONTROLLO PER COMPORTAMENTI A RISCHIO" },
                { value: "NEL CORSO DI UN ITER DIAGNOSTICO (ESAME RICHIESTO PER PRESENZA DI SINTOMI)", text: "NEL CORSO DI UN ITER DIAGNOSTICO (ESAME RICHIESTO PER PRESENZA DI SINTOMI)" },
                { value: "ALTRI ACCERTAMENTI (GRAVIDANZA, IVG, ROUTINE, ECC…)", text: "ALTRI ACCERTAMENTI (GRAVIDANZA, IVG, ROUTINE, ECC…)" },
                { value: "ALTRO", text: "ALTRO" },
            ],
            esitiOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "REATTIVO", text: "REATTIVO" },
                { value: "INDETERMINATO", text: "INDETERMINATO" },
                { value: "NEGATIVO", text: "NEGATIVO" },
            ],
            tipiTestOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "ELISA", text: "ELISA" },
                { value: "WESTERN BOLT", text: "WESTERN BOLT" },
            ],
            tipoPazienteOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "PAZIENTE AMBULATORIALE", text: "PAZIENTE AMBULATORIALE" },
                { value: "PAZIENTE OSPEDALIZZATO", text: "PAZIENTE OSPEDALIZZATO" },
                { value: "PAZIENTE DONATORE", text: "PAZIENTE DONATORE" },
            ],
            tipoPazienteDonatoreOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "PERIODICO", text: "PERIODICO" },
                { value: "OCCASIONALE", text: "OCCASIONALE" },
            ],
            struttureOptions: [{ value: "", text: "-Seleziona Struttura-" }],
            aslOptions: [{ value: null, text: "-Seleziona Asl-" }],
            provinceOptions: [{ sigla: "", provincia: "-Seleziona Provincia-" }],
            comuniOptions: [{ codiceIstat: "", comune: "-Seleziona-" }],
            paeseCittadinanzaStranieraOptions: [{ codiceIstat: "", comune: "-Seleziona-" }],
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient() {
            return UtilityMixin.methods.getLinkPatient(this.utente, this.idPaziente);
        },
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        if (me.id !== "-1") {
            me.linkback = "/malattieinfettive/hiv/view/" + me.id;
        } else {
            me.linkback = "/malattieinfettive/hiv";
        }
        me.loadStruttureUtente();
        // me.loadData();
        me.loadProvince();
        if (me.jsonData.provinciaResidenza !== "" && me.jsonData.provinciaResidenza !== null) {
            console.log("PROVINCIA: " + me.jsonData.provinciaResidenza);
            me.loadComuni(me.jsonData.provinciaResidenza);
            // me.loadAsl(me.jsonData.provinciaResidenza);
        }
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                // console.log("eccomi");
                // console.log(me.struttureOptions.length);

                me.setDatiMedico();
                me.jsonData.dataCompilazione = Date.now();
                me.showModalLoading = false;
                // console.log(me.struttureOptions[1]);
                if (me.struttureOptions.length > 1) {
                    me.struttureOptions[1].value;
                    me.jsonData.codiceStruttura = me.struttureOptions[1].value;
                }
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                me.showModalLoading = true;
                axios
                    .get(link + me.id)
                    .then((response) => {
                        me.jsonData = response.data.data;
                        me.onChangeEsito();
                        me.onChangeContattatoCentroDiagnosi();
                        me.showModalLoading = false;
                    })
                    .catch(() => {
                        me.showModalLoading = false;
                    });
            }
        },
        onInputDrogheIniettate(value) {
            let me = this;
            if (value === "SI") {
                me.disabledValue.drogheIniettateDisabled = false;
            } else {
                me.disabledValue.drogheIniettateDisabled = true;
                me.jsonData.annoInizioDrogheIniettate = "";
                me.jsonData.annoFineDrogheIniettate = "";
            }
        },
        loadProvince() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceProvince;
            axios.get(link).then((response) => {
                me.provinceOptions = response.data.data;
                me.provinceOptions.unshift({ sigla: "", provincia: "- Seleziona Provincia-" });
                me.loadAsl(me.jsonData.provinciaResidenza);
            });
        },
        onInputProvinciaResidenza(provinciaResidenza) {
            let me = this;
            if (provinciaResidenza !== "") {
                me.loadComuni(provinciaResidenza);
                me.loadAsl(provinciaResidenza);
            } else {
                me.jsonData.comuneResidenza = "";
                me.jsonData.aslResidenza = null;
                me.comuniOptions = [{ codiceIstat: "", comune: "-Seleziona Comune-" }];
                me.aslOptions = [{ value: null, text: "-Seleziona Asl-" }];
            }
        },
        loadComuni(provinciaResidenza) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceComuni + "/" + provinciaResidenza;
            axios.get(link).then((response) => {
                me.comuniOptions = response.data.data;
                me.comuniOptions.unshift({ codiceIstat: "", comune: "-Seleziona Comune-" });
            });
        },
        loadStatiEsteri() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceComuni + "/EE";
            axios.get(link).then((response) => {
                me.paeseCittadinanzaStranieraOptions = response.data.data;
                me.paeseCittadinanzaStranieraOptions.unshift({ codiceIstat: "", comune: "-Seleziona-" });
            });
        },
        loadStruttureUtente() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceStrutture;
            me.struttureOptions = [];
            axios
                .get(link)
                .then((response) => {
                    // console.log(response.data.data.list);

                    // response.data.data.list.forEach((element) => {
                    me.struttureOptions.push({ value: response.data.data.codiceStruttura, text: response.data.data.denominazione });
                    // });
                    me.struttureOptions.unshift({ value: "", text: "-Seleziona Struttura-" });
                    me.loadData();
                })
                .catch(() => {
                    me.loadData();
                });
        },
        onInputCittadinanza(value) {
            let me = this;
            if (value === "NO") {
                me.disabledValue.cittadinanzaStranieraDisabled = false;
                me.loadStatiEsteri();
            } else {
                me.disabledValue.cittadinanzaStranieraDisabled = true;
                me.jsonData.paeseCittadinanzaStraniera = "";
            }
        },
        onInputComportamentiRischio(value, usoProfilattico, usoProfilattocoDisabilitato) {
            let me = this;
            me.jsonData[usoProfilattico] = value === "SI" ? me.jsonData[usoProfilattico] : "";
            me.disabledValue[usoProfilattocoDisabilitato] = value === "SI" ? false : true;
        },
        onMotivoEsecuzioneTest(value) {
            let me = this;
            if (value === "ALTRO") {
                me.disabledValue.motivoEsecuzioneTestAltroDisabled = false;
            } else {
                me.disabledValue.motivoEsecuzioneTestAltroDisabled = true;
                me.jsonData.motivoEsecuzioneTestAltro = "";
            }
        },
        onInputTestEseguito(value) {
            let me = this;
            if (value === "SI") {
                me.disabledValue.dataEsecuzioneTestDisabled = false;
            } else {
                me.disabledValue.dataEsecuzioneTestDisabled = true;
                me.jsonData.dataEsecuzioneTest = null;
            }
        },
        onInputAltrePatologie(value) {
            let me = this;
            if (value === "SI") {
                me.disabledValue.altrePatologieAltroDisbled = false;
            } else {
                me.disabledValue.altrePatologieAltroDisbled = true;
                me.jsonData.altrePatologieAltro = "";
            }
        },
        loadAsl(provinciaResidenza) {
            let me = this;
            if (me.provinceOptions.length > 1) {
                let regione = null;
                me.provinceOptions.forEach((element) => {
                    if (element.sigla === provinciaResidenza) {
                        regione = element.regione + "0";
                    }
                });
                while (regione.charAt(0) === "0") {
                    regione = regione.substring(1);
                }
                let link = process.env.VUE_APP_PATH_API + me.pathResourceAsl;
                axios.get(link, { params: { codiceRegione: regione } }).then((response) => {
                    me.aslOptions = [{ value: null, text: "-Seleziona Asl-" }];
                    response.data.data.forEach((element) => {
                        me.aslOptions.push({ value: element.denominazione, text: element.denominazione + " (" + element.codiceAzienda + ") " });
                    });
                });
            }
        },
        onClickRicercaAnagrafica() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceRicercaAnagrafica + "/";
            me.showModalLoading = true;
            axios
                .get(link + me.jsonData.numeroCodiceIdentificativo)
                .then((response) => {
                    if (response.data.data !== null) {
                        me.jsonData.dataNascita = response.data.data.dataNascita;
                        me.jsonData.sesso = response.data.data.sesso;
                        me.jsonData.provinciaResidenza = response.data.data.provinciaResidenza;
                        me.jsonData.comuneResidenza = response.data.data.comuneResidenza;
                        me.jsonData.aslResidenza = response.data.data.aslResidenza;
                        me.jsonData.telefono = response.data.data.telefono;
                        me.jsonData.cittadinanzaItaliana = response.data.data.cittadinanzaItaliana;
                        me.jsonData.paeseCittadinanzaStraniera = response.data.data.paeseCittadinanzaStraniera;
                        me.jsonData.altrePatologie = response.data.data.altrePatologie;
                        me.jsonData.altrePatologieAltro = response.data.data.altrePatologieAltro;
                        me.showModalLoading = false;
                    } else {
                        this.$bvModal.msgBoxOk("NUMERO CODICE IDENTIFICATIVO NON TROVATO!", {
                            title: "ATTENZIONE!",
                            buttonSize: "sm",
                            okVariant: "danger",
                            cancelVariant: "danger",
                            footerClass: "p-2",
                            hideHeaderClose: false,
                            centered: true,
                        });
                    }
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        onChangeEsito() {
            let me = this;
            me.disableDataRiesecuzione = true;
            me.disableDataReattivoIndeterminato = true;
            me.disableSceltaContattoCentroDiagnosiCure = true;
            if (me.jsonData.esitoPrimoPrelievo === "") {
                me.disableDataRiesecuzione = true;
                me.disableDataReattivoIndeterminato = true;
                me.disableSceltaContattoCentroDiagnosiCure = true;
                me.jsonData.dataRiesecuzionePrimoPrelievo = null;
                me.jsonData.esitoPrimoPrelievoCentroDiagnosiCura = "";
                me.jsonData.primoPrelievoNomeCentroDiagnosiCura = "";
                me.jsonData.dataPrimoAppuntamentoCentroDiagnosiCura = null;
            } else if (me.jsonData.esitoPrimoPrelievo === "NEGATIVO") {
                me.disableDataRiesecuzione = false;
                me.disableDataReattivoIndeterminato = true;
                me.disableSceltaContattoCentroDiagnosiCure = true;
            } else {
                me.disableDataRiesecuzione = true;
                me.disableDataReattivoIndeterminato = false;
                me.disableSceltaContattoCentroDiagnosiCure = true;
            }
        },
        onChangeContattatoCentroDiagnosi() {
            let me = this;
            me.disableSceltaContattoCentroDiagnosiCure = true;
            if (me.jsonData.esitoPrimoPrelievoCentroDiagnosiCura === "SI") {
                me.disableSceltaContattoCentroDiagnosiCure = false;
            }
        },
        setDatiMedico() {
            let me = this;
            me.jsonData.responsabileCompilazione = me.utente.firstname + " " + me.utente.lastname;
        },
    },
};
</script>
