<template>
    <b-modal ref="mdlRsaSpecializzazionePai" :title="isNew ? 'Nuova Specializzazione' : 'Aggiorna Specializzazione'" @hidden="onCloseSpecializzazionePaiModal" @ok="onSaveSpecializzazionePai" centered no-close-on-backdrop no-close-on-esc hide-header-close>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <span class="sa-form-label">Ruolo</span>
                <b-form-select :options="ruoliOptions" value-field="id" text-field="ruolo" value="''" v-model="jsonDataSpecializzazionePai.idRuolo"> </b-form-select
                ><!-- @change="onRuoloChange" -->
            </b-col>

            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <span class="sa-form-label">Specializzazione</span>
                <b-form-input v-model="jsonDataSpecializzazionePai.specializzazione"></b-form-input>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <span class="sa-form-label">Note</span>
                <b-form-textarea v-model="jsonDataSpecializzazionePai.note" max-rows="10" rows="7"> </b-form-textarea>
            </b-col>
        </b-row>
    </b-modal>
</template>

<script>
import axios from "axios";
import UtilityMixin from "../../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixin],
    mounted() {
        let me = this;
        me.loadRuoliPaiData();
    },
    data() {
        return {
            isNew: false,
            pathResourcePaiSpecializzazione: "/rsapairuolospecializzazione",
            pathResourcePaiRuoli: "/rsapairuoli",
            ruoliOptions: [
                /* { idRuolo: "", ruolo: "-SELEZIONARE RUOLO-" } */
            ],
            jsonDataSpecializzazionePai: {
                id: "",
                specializzazione: null,
                idRuolo: "",
                ruolo: null,
                note: null,
            },
        };
    },

    watch: {
        /* "jsonDataSpecializzazionePai.idRuolo"(newVal) {
            console.log("Nuovo valore idRuolo:", newVal);
        }, */
    },

    methods: {
        /* onRuoloChange(value) {
            console.log("Ruolo selezionato:", value);
            console.log("Stato corrente jsonDataSpecializzazionePai:", this.jsonDataSpecializzazionePai);
        }, */
        nuovaSpecializzazionePai() {
            let me = this;
            me.isNew = true;
            me.jsonDataSpecializzazionePai = {
                specializzazione: null,
                ruolo: null,
                idRuolo: "",
                note: null,
            };
            me.$refs.mdlRsaSpecializzazionePai.show();
        },

        editSpecializzazionePai(item) {
            let me = this;
            me.isNew = false;
            me.jsonDataSpecializzazionePai = { ...item };
            me.loadRuoliPaiData();
            me.$refs.mdlRsaSpecializzazionePai.show();
        },

        loadRuoliPaiData() {
            let me = this;
            UtilityMixin.methods.loadDataResources(me.pathResourcePaiRuoli, null, me.loadRuoliPaiDataSuccess, me.loadRuoliPaiDataError);
            me.$emit("LoadRuoliPaiData");
        },

        loadRuoliPaiDataSuccess(response) {
            let me = this;

            me.ruoliOptions = response.data.data.list;
            me.ruoliOptions.unshift({ id: "", ruolo: "-SELEZIONARE RUOLO-" });
        },
        loadRuoliPaiDataError(error) {
            console.log("Errore durante il caricamento di loadRuoliPaiDataError :", error);
        },

        onCloseSpecializzazionePaiModal() {
            let me = this;
            me.jsonDataSpecializzazionePai = {};
            me.$emit("onCloseSpecializzazionePaiModal");
        },

        onSaveSpecializzazionePai() {
            let me = this;
            /*  console.log("Dati da salvare:", me.jsonDataSpecializzazionePai); */
            UtilityMixin.methods.saveResource(me.pathResourcePaiSpecializzazione, me.jsonDataSpecializzazionePai, me.saveSpecializzazionePaiSuccessResponse, me.saveSpecializzazionePaiErrorResponse);
        },
        saveSpecializzazionePaiSuccessResponse(response) {
            let me = this;
            me.$emit("onSaveSpecializzazionePaiSuccessResponse", response);
            me.$emit("afterLoadData");
            /* me.$emit("onSaveNuovaSpecializzazionePaiSuccessResponse", response); */
            me.$refs.mdlRsaSpecializzazionePai.hide();
            me.$bvToast.toast("Salvataggio avvvenuto con successo", {
                title: "Success",
                variant: "success",
                solid: true,
            });
        },
        saveSpecializzazionePaiErrorResponse(response) {
            let me = this;
            if (response.response.status === 400) {
                me.$bvModal.msgBoxConfirm("Errore 400: ", response.response.data.messaggio, {
                    title: "ATTENZIONE",
                    size: "sm",
                    okVariant: "outline-danger",
                    headerClass: "sm-msg-header-danger",
                    footerClass: "p2 border-top-0",
                    centered: true,
                });
            } else {
                me.$bvToast.toast("Errore durante il salvataggio", response.message, {
                    title: "Errore",
                    variant: "danger",
                    solid: true,
                });
            }
        },

        deleteSpecializzazionePai(id) {
            let me = this;
            /* console.log(this.jsonDataSpecializzazionePai); */

            let link = process.env.VUE_APP_PATH_API + me.pathResourcePaiSpecializzazione + "/" + id;
            axios
                .delete(link)
                .then((response) => {
                    me.$emit("onDeleteSpecializzazionePai", response);
                    me.$emit("afterLoadData");
                    me.$bvToast.toast("Eliminazione avvenuta con successo", {
                        title: "Success",
                        variant: "success",
                        solid: true,
                    });
                })
                .catch((e) => {
                    console.log(e);
                    me.$bvToast.toast("Errore durante l'eliminazione", {
                        title: "Errore",
                        variant: "danger",
                        solid: true,
                    });
                });
        },

        onDeleteSpecializzazionePai(item) {
            let me = this;
            me.$bvModal
                .msgBoxConfirm("Sei sicuro di eliminare questa specializzazione", {
                    title: "Eliminazione Specializzazione PAI",
                    buttonSize: "sm",
                    okTitle: "SI",
                    cancelTitle: "NO",
                    okVariant: "danger",
                    cancelVariant: "secondary",
                    hideHeaderClose: true,
                    footerClass: "p-2",
                    centered: true,
                })
                .then((value) => {
                    if (value) {
                        me.deleteSpecializzazionePai(item.id);
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        },
    },
};
</script>
