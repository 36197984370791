import RsaPazienteHomePage from "../pages/RsaPazienteHomePage.vue";
import RsaPazienteSchedaOspitePage from "../pages/RsaPazienteSchedaOspitePage.vue";
import RsaPazientePaiPage from "../pages/RsaPazientePaiPage.vue";
import RsaPazienteDiarioPage from "../pages/RsaPazienteDiarioPage.vue";
import RsaPazienteAreePage from "../pages/RsaPazienteAreePage.vue";
import RsaPazienteRefertiPage from "../pages/RsaPazienteRefertiPage.vue";
import RsaPazienteUscitePage from "../pages/RsaPazienteUscitePage.vue";
import RsaPazienteDimissioneTrasferimentoPage from "../pages/RsaPazienteDimissioneTrasferimentoPage.vue";
import RsaPazienteStatoRicoveriPage from "../pages/RsaPazienteStatoRicoveriPage.vue";
import RsaPazienteValutazioneIngressoPage from "../pages/RsaPazienteValutazioneIngressoPage.vue";
import RsaPazienteScaleValutazionePage from "../pages/RsaPazienteScaleValutazionePage.vue";
import RsaPazienteImpegnativaPage from "../pages/RsaPazienteImpegnativaPage.vue";
import RsaPazientePersonaRiferimentoPage from "../pages/RsaPazientePersonaRiferimentoPage.vue";
import RsaPazienteSpeseExtraPage from "../pages/RsaPazienteSpeseExtraPage.vue";
import RsaPazienteEquipePage from "../pages/RsaPazienteEquipePage.vue";
import RsaPazienteParametriVitaliPage from "../pages/RsaPazienteParametriVitaliPage.vue"; 
import RsaPazienteAllegatiPage from "../pages/RsaPazienteAllegatiPage.vue"; 
import RsaPazienteTeleconsultiListPage from "../pages/RsaPazienteTeleconsultiListPage.vue"; 
import RsaPazienteTeleconsultoPage from "../pages/RsaPazienteTeleconsultoPage.vue"; 
import RsaPazienteConsensiPage from "../pages/RsaPazienteConsensiPage.vue"; 
import RsaPazienteDeviceListPage from "../pages/RsaPazienteDeviceListPage.vue"; 
import RsaPaiEditPage from "../../rsagestioneospiti/pages/RsaPaiEditPage.vue";







const routes = [
	{ name: "RsaPazienteHomePage", path: "/rsapaziente/view/:id", component: RsaPazienteHomePage, meta: { title: "Paziente View" } },
	{ name: "RsaPazienteSchedaOspitePage", path: "/rsapaziente/schedaospite", component: RsaPazienteSchedaOspitePage, meta: { title: "Scheda Ospite" } },
	{ name: "RsaPazientePaiPage", path: "/rsapaziente/pai", component: RsaPazientePaiPage, meta: { title: "P.A.I." } },
	{ name: "RsaPazienteDiarioPage", path: "/rsapaziente/diario", component: RsaPazienteDiarioPage, meta: { title: "Diario" } },
	{ name: "RsaPazienteAreePage", path: "/rsapaziente/aree", component: RsaPazienteAreePage, meta: { title: "Aree" } },
	{ name: "RsaPazienteRefertiPage", path: "/rsapaziente/referti", component: RsaPazienteRefertiPage, meta: { title: "Referti" } },
	{ name: "RsaPazienteUscitePage", path: "/rsapaziente/uscite", component: RsaPazienteUscitePage, meta: { title: "Uscite" } },
	{ name: "RsaPazienteDimissioneTrasferimentoPage", path: "/rsapaziente/dimissionetrasferimento", component: RsaPazienteDimissioneTrasferimentoPage, meta: { title: "Dimissione/Trasferimento" } },
	{ name: "RsaPazienteStatoRicoveriPage", path: "/rsapaziente/statoricoveri", component: RsaPazienteStatoRicoveriPage, meta: { title: "Stato Ricoveri" } },
	{ name: "RsaPazienteValutazioneIngressoPage", path: "/rsapaziente/valutazioneingresso", component: RsaPazienteValutazioneIngressoPage, meta: { title: "Valutazione all'ingresso" } },
	{ name: "RsaPazienteScaleValutazionePage", path: "/rsapaziente/scalevalutazione", component: RsaPazienteScaleValutazionePage, meta: { title: "Scale Valutazione" } },
	{ name: "RsaPazienteImpegnativaPage", path: "/rsapaziente/impegnativa", component: RsaPazienteImpegnativaPage, meta: { title: "Impegnativa" } },
	{ name: "RsaPazientePersonaRiferimentoPage", path: "/rsapaziente/personariferimento", component: RsaPazientePersonaRiferimentoPage, meta: { title: "Persona di Riferimento" } },
	{ name: "RsaPazienteSpeseExtraPage", path: "/rsapaziente/speseextra", component: RsaPazienteSpeseExtraPage, meta: { title: "Spese Extra" } },
	{ name: "RsaPazienteEquipePage", path: "/rsapaziente/equipe", component: RsaPazienteEquipePage, meta: { title: "Equipe" } },
	{ name: "RsaPazienteParametriVitaliPage", path: "/rsapaziente/parametrivitali", component: RsaPazienteParametriVitaliPage, meta: { title: "Parametri Vitali" } },
	{ name: "RsaPazienteAllegatiPage", path: "/rsapaziente/allegati", component: RsaPazienteAllegatiPage, meta: { title: "Allegati" } },
	{ name: "RsaPazienteTeleconsultiListPage", path: "/rsapaziente/teleconsulti", component: RsaPazienteTeleconsultiListPage, meta: { title: "Teleconsulti" } },
	{ name: "RsaPazienteTeleconsultoPage", path: "/rsapaziente/teleconsulti/:id", component: RsaPazienteTeleconsultoPage, meta: { title: "Teleconsulti" } },
	{ name: "RsaPazienteConsensiPage", path: "/rsapaziente/consensi", component: RsaPazienteConsensiPage, meta: { title: "Consensi" } },
	{ name: "RsaPazienteDeviceListPage", path: "/rsapaziente/hassistokits", component: RsaPazienteDeviceListPage, meta: { title: "Device" } },
	{ name: "RsaPazientePaiPage", path: "/rsapaziente/pai/edit/:id", component: RsaPaiEditPage, meta: { title: "P.A.I." } },
];

export default routes;
