<template>
  <sa-page-layout
    :btnSaveVisible="true"
    :btnAnnullaVisible="true"
    :linkback="linkback"
    :data="jsonData"
    :showModalLoading="showModalLoading"
    :pathResource="pathResource"
    class="sa-no-space"
  >
    <template slot="table-body">
      <protocollo-rubrica-edit-component
        :showModalLoading="showModalLoading"
        @update="onUpdateJsonData"
        @beforeLoadData="onBeforeLoadData"
        @afterLoadData="onAfterLoadData"
      ></protocollo-rubrica-edit-component>
    </template>
  </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import ProtocolloRubricaEditComponent from "../components/ProtocolloRubricaEditComponent.vue";
export default {
  components: { SaPageLayout, ProtocolloRubricaEditComponent },
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  data() {
    return {
      linkback: "/protocollorubrica",
      jsonData: {},
      showModalLoading: false,
      pathResource: "/protocolrubrica",
    };
  },
  methods: {
    onUpdateJsonData(data) {
      let me = this;
      me.jsonData = data;
    },
    onBeforeLoadData() {
      let me = this;
      me.showModalLoading = true;
    },
    onAfterLoadData() {
      let me = this;
      me.showModalLoading = false;
    },
  },
};
</script>

<style></style>
