<template>
    <div>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Tipo Caso</label>
                    <b-form-select v-model="jsonData.tipoCaso" :options="tipiCasiOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Fonte di Contagio" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Alimento sospetto</label>
                    <b-form-input v-model="jsonData.alimentoSospetto"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Tipo Alimento</label>
                    <b-form-select v-model="jsonData.tipoAlimento" :options="tipiAlimentoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Alimento Consumato Abitualmente</label>
                    <b-form-select v-model="jsonData.consumoAbituale" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Conserve Cotte</label>
                    <b-form-select v-model="jsonData.cotturaConserve" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Ingerito da (Ore)</label>
                    <b-form-input v-model="jsonData.tempoIngerimento" type="number"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Altro Alimento Sospetto</label>
                    <b-form-input v-model="jsonData.altroAlimentoSospetto"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Numero Consumatori Alimento</label>
                    <b-form-input v-model="jsonData.numeroConsumatoriAlimento" type="number"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Numero Consumatori Con Sintomi</label>
                    <b-form-input v-model="jsonData.numeroConsumatoriSintomatici" type="number"></b-form-input>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Sintomatologia Clinica" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Diplopia</label>
                    <b-form-select v-model="jsonData.diplopia" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputSintomatologieCliniche(jsonData.diplopia, 'diplopiaDataInsorgenzaSintomi', 'diplopiaDataDisabled')"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Diplopia Data Insorgenza Sintomi</label>
                    <date-picker v-model="jsonData.diplopiaDataInsorgenzaSintomi" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="disabledValue.diplopiaDataDisabled"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Nausea</label>
                    <b-form-select v-model="jsonData.nausea" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputSintomatologieCliniche(jsonData.nausea, 'nauseaDataInsorgenzaSintomi', 'nauseaDataDisabled')"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Nausea Data Insorgenza Sintomi</label>
                    <date-picker v-model="jsonData.nauseaDataInsorgenzaSintomi" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="disabledValue.nauseaDataDisabled"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Disfagia</label>
                    <b-form-select v-model="jsonData.disfagia" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputSintomatologieCliniche(jsonData.disfagia, 'disfagiaDataInsorgenzaSintomi', 'disfagiaDataDisabled')"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Disfagia Data Insorgenza Sintomi</label>
                    <date-picker v-model="jsonData.disfagiaDataInsorgenzaSintomi" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="disabledValue.disfagiaDataDisabled"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Vomito</label>
                    <b-form-select v-model="jsonData.vomito" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputSintomatologieCliniche(jsonData.vomito, 'vomitoDataInsorgenzaSintomi', 'vomitoDataDisabled')"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Vomito Data Insorgenza Sintomi</label>
                    <date-picker v-model="jsonData.vomitoDataInsorgenzaSintomi" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="disabledValue.vomitoDataDisabled"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Secchezza Delle Fauci</label>
                    <b-form-select v-model="jsonData.secchezzaFauci" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputSintomatologieCliniche(jsonData.secchezzaFauci, 'secchezzaFauciDataInsorgenzaSintomi', 'secchezzaFauciDataDisabled')"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Secchezza Delle Fauci Data Insorgenza Sintomi </label>
                    <date-picker v-model="jsonData.secchezzaFauciDataInsorgenzaSintomi" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="disabledValue.secchezzaFauciDataDisabled"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Diarrea</label>
                    <b-form-select v-model="jsonData.diarrea" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputSintomatologieCliniche(jsonData.diarrea, 'diarreaDataInsorgenzaSintomi', 'diarreaDataDisabled')"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Diarrea Data Insorgenza Sintomi</label>
                    <date-picker v-model="jsonData.diarreaDataInsorgenzaSintomi" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="disabledValue.diarreaDataDisabled"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Stipsi</label>
                    <b-form-select v-model="jsonData.stipsi" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputSintomatologieCliniche(jsonData.stipsi, 'stipsiDataInsorgenzaSintomi', 'stipsiDataDisabled')"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Stipsi Data Insorgenza Sintomi</label>
                    <date-picker v-model="jsonData.stipsiDataInsorgenzaSintomi" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="disabledValue.stipsiDataDisabled"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Insufficienza Respiratoria</label>
                    <b-form-select v-model="jsonData.insufficienzaRespiratoria" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputSintomatologieCliniche(jsonData.insufficienzaRespiratoria, 'insufficienzaRespiratoriaDataInsorgenzaSintomi', 'insufficienzaRespiratoriaDataDisabled')"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Insufficienza Respiratoria Data Insorgenza Sintomi</label>
                    <date-picker v-model="jsonData.insufficienzaRespiratoriaDataInsorgenzaSintomi" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="disabledValue.insufficienzaRespiratoriaDataDisabled"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Midriasi</label>
                    <b-form-select v-model="jsonData.midriasi" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputSintomatologieCliniche(jsonData.midriasi, 'midriasiDataInsorgenzaSintomi', 'midriasiDataDisabled')"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Midriasi Data Insorgenza Sintomi</label>
                    <date-picker v-model="jsonData.midriasiDataInsorgenzaSintomi" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="disabledValue.midriasiDataDisabled"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Coma</label>
                    <b-form-select v-model="jsonData.coma" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputSintomatologieCliniche(jsonData.coma, 'comaDataInsorgenzaSintomi', 'comaDataDisabled')"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Coma Data Insorgenza Sintomi</label>
                    <date-picker v-model="jsonData.comaDataInsorgenzaSintomi" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="disabledValue.comaDataDisabled"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Ritenzione Urinaria</label>
                    <b-form-select v-model="jsonData.ritenzioneUrinaria" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputSintomatologieCliniche(jsonData.ritenzioneUrinaria, 'ritenzioneUrinariaDataInsorgenzaSintomi', 'ritenzioneUrinariaDataDisabled')"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Ritenzione Urinaria Data Insorgenza Sintomi</label>
                    <date-picker v-model="jsonData.ritenzioneUrinariaDataInsorgenzaSintomi" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="disabledValue.ritenzioneUrinariaDataDisabled"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Altra Sintomatologia</label>
                    <b-form-input v-model="jsonData.altraSintomatologia"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Altra Sintomatologia Data Insorgenza Sintomi</label>
                    <date-picker v-model="jsonData.altraSintomatologiaDataInsorgenzaSintomi" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="jsonData.altraSintomatologia === '' || jsonData.altraSintomatologia === null"></date-picker>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Diagnosi Strumentale" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Elettromiografia</label>
                    <b-form-select v-model="jsonData.elettromiografia" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputElettromiografia"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Data Elettromiografia</label>
                    <date-picker v-model="jsonData.elettromiografiaDataEsecuzione" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="elettromiografiaDisabled"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Referto Elettromiografia</label>
                    <b-form-textarea v-model="jsonData.referto" rows="8" no-resize :disabled="elettromiografiaDisabled"></b-form-textarea>
                </b-col>
                <!-- <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-label-data">Altre Diagnosi Strumentali</label>
                        <b-form-select v-model="jsonData.altraDiagnosiStrumentale" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                        <label class="sa-label-data">Altre Diagnosi Strumentali Data Esecuzione</label>
                        <date-picker v-model="jsonData.altraDiagnosiStrumentaleDataEsecuzione" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="jsonData.elettromiografia !== 'SI'"></date-picker>                        
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <label class="sa-label-data">Altre Diagnosi Strumentali Referto</label>
                        <b-form-textarea v-model="jsonData.altraDiagnosiStrumentaleReferto"></b-form-textarea>
                    </b-col> -->
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Decorso" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Data di Ricovero</label>
                    <date-picker v-model="jsonData.dataRicovero" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Antibotulinico Somministrato</label>
                    <b-form-select v-model="jsonData.antibotulinico" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Dopo quanto tempo dall'esordio (indicare Ore)</label>
                    <b-form-input v-model="jsonData.antibotulinicoOreSomministrazione" type="number"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Unita' di antibotulinico somministrate</label>
                    <b-form-input v-model="jsonData.antibotulinicoQuantita" type="number"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Altre Terapie</label>
                    <b-form-textarea v-model="jsonData.altreTerapie" rows="8" no-resize></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Indagini di Laboratorio" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Ricerca delle tossine botuliniche eseguita su Siero</label>
                    <b-form-select v-model="jsonData.ricercaTossineBotulinicheSiero" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputIndaginiLaboratorio(jsonData.ricercaTossineBotulinicheSiero, 'ricercaTossineBotulinicheSieroRisultato', 'ricercaTossineSieroDisabled')"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Risultato</label>
                    <b-form-select v-model="jsonData.ricercaTossineBotulinicheSieroRisultato" :options="risultatiOptions" :value="''" value-field="value" text-field="text" :disabled="disabledValue.ricercaTossineSieroDisabled"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Ricerca delle tossine botuliniche eseguita su Feci</label>
                    <b-form-select v-model="jsonData.ricercaTossineBotulinicheFeci" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputIndaginiLaboratorio(jsonData.ricercaTossineBotulinicheFeci, 'ricercaTossineBotulinicheFeciRisultato', 'ricercaTossineFeciDisabled')"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Risultato</label>
                    <b-form-select v-model="jsonData.ricercaTossineBotulinicheFeciRisultato" :options="risultatiOptions" :value="''" value-field="value" text-field="text" :disabled="disabledValue.ricercaTossineFeciDisabled"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Ricerca delle tossine botuliniche eseguita su Reperti Autoptici</label>
                    <b-form-select v-model="jsonData.ricercaTossineBotulinicheRepertiAutoptici" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputIndaginiLaboratorio(jsonData.ricercaTossineBotulinicheRepertiAutoptici, 'ricercaTossineBotulinicheRepertiAutopticiRisultato', 'ricercaTossineRepertiAutopticiDisabled')"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Risultato</label>
                    <b-form-select v-model="jsonData.ricercaTossineBotulinicheRepertiAutopticiRisultato" :options="risultatiOptions" :value="''" value-field="value" text-field="text" :disabled="disabledValue.ricercaTossineRepertiAutopticiDisabled"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Ricerca delle tossine botuliniche eseguita su Alimenti</label>
                    <b-form-select v-model="jsonData.ricercaTossineBotulinicheAlimenti" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputIndaginiLaboratorio(jsonData.ricercaTossineBotulinicheAlimenti, 'ricercaTossineBotulinicheAlimentiRisultato', 'ricercaTossineAlimentiDisabled')"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Risultato</label>
                    <b-form-select v-model="jsonData.ricercaTossineBotulinicheAlimentiRisultato" :options="risultatiOptions" :value="''" value-field="value" text-field="text" :disabled="disabledValue.ricercaTossineAlimentiDisabled"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Ricerca dei clostridi produttori di tossine Feci</label>
                    <b-form-select v-model="jsonData.ricercaClostridiTossineFeci" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputIndaginiLaboratorio(jsonData.ricercaClostridiTossineFeci, 'ricercaClostridiTossineFeciRisultato', 'ricercaClostridiFeciDisabled')"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Risultato</label>
                    <b-form-select v-model="jsonData.ricercaClostridiTossineFeciRisultato" :options="risultatiOptions" :value="''" value-field="value" text-field="text" :disabled="disabledValue.ricercaClostridiFeciDisabled"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Ricerca dei clostridi produttori di tossine Reperti Autoptici</label>
                    <b-form-select v-model="jsonData.ricercaClostridiTossineRepertiAutoptici" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputIndaginiLaboratorio(jsonData.ricercaClostridiTossineRepertiAutoptici, 'ricercaClostridiTossineRepertiAutopticiRisultato', 'ricercaClostridiRepertiAutopticiDisabled')"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Risultato</label>
                    <b-form-select v-model="jsonData.ricercaClostridiTossineRepertiAutopticiRisultato" :options="risultatiOptions" :value="''" value-field="value" text-field="text" :disabled="disabledValue.ricercaClostridiRepertiAutopticiDisabled"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Ricerca dei clostridi produttori di tossine Alimenti</label>
                    <b-form-select v-model="jsonData.ricercaClostridiTossineAlimenti" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputIndaginiLaboratorio(jsonData.ricercaClostridiTossineAlimenti, 'ricercaClostridiTossineAlimentiRisultato', 'ricercaClostridiAlimentiDisabled')"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Risultato</label>
                    <b-form-select v-model="jsonData.ricercaClostridiTossineAlimentiRisultato" :options="risultatiOptions" :value="''" value-field="value" text-field="text" :disabled="disabledValue.ricercaClostridiAlimentiDisabled"></b-form-select>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Laboratorio Analisi</label>
                    <b-form-input v-model="jsonData.laboratorioAnalisi"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Indirizzo Laboratorio</label>
                    <b-form-input v-model="jsonData.indirizzoLaboratorio"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Telefono Laboratorio</label>
                    <b-form-input v-model="jsonData.telefonoLaboratorio"></b-form-input>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Sanitario Notificante</label>
                    <b-form-input v-model="jsonData.sanitarioNotificante"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Numero Cellulare Sanitario Notificante</label>
                    <b-form-input v-model="jsonData.telefonoSanitarioNotificante"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Notifica</label>
                    <date-picker v-model="jsonData.dataNotifica" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
<script>
import axios from "axios";
import Vue from "vue";
import EventiMixins from "../../../paziente/eventi/utility/mixins/EventiMixins";
import DatePicker from "vue2-datepicker";
export default {
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    components: { DatePicker },
    data() {
        return {
            pathResource: "/malattieinfettiveiebotulismo",
            pathResourceMalattiaInfettivaSegnalazione: "/malattieinfettivesegnalazioni",
            linkback: null,
            showModalLoading: false,
            disabledValue: {
                nauseaDataDisabled: true,
                vomitoDataDisabled: true,
                diplopiaDataDisabled: true,
                disfagiaDataDisabled: true,
                secchezzaFauciDataDisabled: true,
                diarreaDataDisabled: true,
                stipsiDataDisabled: true,
                insufficienzaRespiratoriaDataDisabled: true,
                midriasiDataDisabled: true,
                comaDataDisabled: true,
                ritenzioneUrinariaDataDisabled: true,
                ricercaTossineSieroDisabled: true,
                ricercaTossineFeciDisabled: true,
                ricercaTossineRepertiAutopticiDisabled: true,
                ricercaTossineAlimentiDisabled: true,
                ricercaClostridiFeciDisabled: true,
                ricercaClostridiRepertiAutopticiDisabled: true,
                ricercaClostridiAlimentiDisabled: true,
            },
            elettromiografiaDisabled: true,
            jsonData: {
                tipoCaso: "",
                alimentoSospetto: "",
                tipoAlimento: "",
                consumoAbituale: "",
                cotturaConserve: "",
                tempoIngerimento: 0,
                altroAlimentoSospetto: "",
                numeroConsumatoriAlimento: 0,
                numeroConsumatoriSintomatici: 0,
                diplopia: "",
                diplopiaDataInsorgenzaSintomi: null,
                nausea: "",
                nauseaDataInsorgenzaSintomi: new Date().getTime(),
                disfagia: "",
                disfagiaDataInsorgenzaSintomi: null,
                vomito: "",
                vomitoDataInsorgenzaSintomi: null,
                secchezzaFauci: "",
                secchezzaFauciDataInsorgenzaSintomi: null,
                diarrea: "",
                diarreaDataInsorgenzaSintomi: null,
                stipsi: "",
                stipsiDataInsorgenzaSintomi: null,
                insufficienzaRespiratoria: "",
                insufficienzaRespiratoriaDataInsorgenzaSintomi: null,
                midriasi: "",
                midriasiDataInsorgenzaSintomi: null,
                coma: "",
                comaDataInsorgenzaSintomi: null,
                ritenzioneUrinaria: "",
                ritenzioneUrinariaDataInsorgenzaSintomi: null,
                altraSintomatologia: "",
                altraSintomatologiaDataInsorgenzaSintomi: null,
                elettromiografia: "",
                elettromiografiaDataEsecuzione: null,
                referto: "",
                dataRicovero: null,
                antibotulinico: "",
                antibotulinicoOreSomministrazione: 0,
                antibotulinicoQuantita: 0,
                ricercaTossineBotulinicheSiero: "",
                ricercaTossineBotulinicheSieroRisultato: "",
                ricercaTossineBotulinicheFeci: "",
                ricercaTossineBotulinicheFeciRisultato: "",
                ricercaTossineBotulinicheRepertiAutoptici: "",
                ricercaTossineBotulinicheRepertiAutopticiRisultato: "",
                ricercaTossineBotulinicheAlimenti: "",
                ricercaTossineBotulinicheAlimentiRisultato: "",
                ricercaClostridiTossineFeci: "",
                ricercaClostridiTossineFeciRisultato: "",
                ricercaClostridiTossineRepertiAutoptici: "",
                ricercaClostridiTossineRepertiAutopticiRisultato: "",
                ricercaClostridiTossineAlimenti: "",
                ricercaClostridiTossineAlimentiRisultato: "",
                laboratorioAnalisi: "",
                indirizzoLaboratorio: "",
                telefonoLaboratorio: "",
                sanitarioNotificante: "",
                telefonoSanitarioNotificante: "",
                dataNotifica: null,
            },
            siNoOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "SI", text: "SI" },
                { value: "NO", text: "NO" },
            ],
            tipiCasiOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "PROBABILE", text: "PROBABILE" },
                { value: "ACCERTATO", text: "ACCERTATO" },
            ],
            tipiAlimentoOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "DI PRODUZIONE INDUSTRIALE", text: "DI PRODUZIONE INDUSTRIALE" },
                { value: "DI PRODUZIONE CASALINGA", text: "DI PRODUZIONE CASALINGA" },
            ],
            risultatiOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "POSITIVO", text: "POSITIVO" },
                { value: "NEGATIVO", text: "NEGATIVO" },
            ],
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient() {
            return EventiMixins.getLinkPatient(this.utente, this.idPaziente);
        },
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.jsonData.idAnagrafica = me.idPaziente;
        me.jsonData.id = me.id;
        me.loadMalattiaInfettivaSegnalazione();
        me.loadData();
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            me.showModalLoading = true;
            axios
                .get(link + me.id)
                .then((response) => {
                    me.jsonData = response.data.data;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.setDatiMedico();
                    me.showModalLoading = false;
                });
        },
        loadMalattiaInfettivaSegnalazione() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceMalattiaInfettivaSegnalazione + "/";
            axios
                .get(link + me.id)
                .then((response) => {
                    me.setDatiMalattia(response.data.data);
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        onInputSintomatologieCliniche(value, dataSintomo, dataSintomoDisabilitato) {
            let me = this;
            me.jsonData[dataSintomo] = value === "SI" ? me.jsonData[dataSintomo] : null;
            me.disabledValue[dataSintomoDisabilitato] = value === "SI" ? false : true;
        },
        onInputElettromiografia(value) {
            let me = this;
            if (value !== "SI") {
                me.jsonData.elettromiografiaDataEsecuzione = null;
                me.jsonData.referto = "";
                me.elettromiografiaDisabled = true;
            } else {
                me.elettromiografiaDisabled = false;
            }
        },
        onInputIndaginiLaboratorio(value, risultato, risultatoDisabilitato) {
            let me = this;
            me.jsonData[risultato] = value === "SI" ? "" : "";
            me.disabledValue[risultatoDisabilitato] = value === "SI" ? false : true;
        },
        setDatiMedico() {
            let me = this;
            me.jsonData.sanitarioNotificante = Vue.prototype.user.firstname + " " + Vue.prototype.user.lastname;
        },
        setDatiMalattia(data) {
            let me = this;
            me.jsonData.id = data.id;
        },
    },
};
</script>
