<template>
    <b-navbar toggleable="sm" type="light" variant="light" class="sa-main-nav-bar">
        <sa-icon class="sa-hamburger-button" icon="bars" @click="onHamburgerMenuClick" />
        <!-- <b-button class="sa-btn-toolbar sa-btn-icon" @click="onHamburgerMenuClick"><sa-icon icon="bars" /></b-button> -->
        <b-navbar-toggle class="sa-nav-collapse" target="nav-collapse ">
            <span class="navbar-toggler-icon">
                <i class="fas fa-bars sa-hamburger-button"></i>
            </span>
        </b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav v-if="visible">
                <!-- <router-link to="/" exact>Test</router-link> -->
                <b-nav-item href="#" @click="onHome" class="sa-main-nav-bar-alternative-menu">
                    <i class="fas fa-home sa-navbar-icon-button"></i>
                    <span style="padding-left: 10px">Home</span>
                </b-nav-item>
            </b-navbar-nav>
            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">
                <b-nav-item-dropdown right class="sa-main-nav-bar-user-menu" :disabled="disabledUserMenu">
                    <!-- Using 'button-content' slot -->
                    <template #button-content>
                        <!-- <span class="sa-navbar-image"> -->
                        <!-- <img :src="image" :srcset="`${image2x || image} 2x`" :class="['img-circle']" :alt="username" /> -->
                        <!-- <img :src="image" :srcset="`${image2x || image} 2x`" :class="['']" /> -->
                        <!-- <i class="bi bi-person-fill sa-main-nav-bar-user-icon"></i> -->
                        <!-- </span> -->
                        <!-- <span class="pro-user-name ml-1 sa-main-nav-bar-username">
                          <i class="bi bi-person-fill sa-main-nav-bar-user-icon"></i>
                          <em>{{ userName }}</em>
                      </span> -->
                        <div class="">
                            <i class="bi bi-person-fill sa-main-nav-bar-user-icon"></i>
                            {{ userName }}
                        </div>
                    </template>
                    <b-dropdown-item v-if="gestionePasswordUtenteEnable" @click="onCambiaPassword" class="sa-custom-mnu-item">
                        <font-awesome-icon icon="lock" />
                        <span>Cambia Password</span>
                    </b-dropdown-item>
                    <b-dropdown-item v-if="gestioneProfiloEnableEnable" :href="linkProfiloView" class="sa-custom-mnu-item">
                        <font-awesome-icon icon="user" />
                        <span>Profilo</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="onLogout" class="sa-custom-mnu-item">
                        <font-awesome-icon icon="power-off" />
                        <span>Logout</span>
                    </b-dropdown-item>
                </b-nav-item-dropdown>
            </b-navbar-nav>
        </b-collapse>
        <b-modal ref="mdlCambioPassword" no-close-on-backdrop centered title="Cambio Password" @ok="onOk">
            <b-form-group label="Vecchia Password" label-for="email-input" invalid-feedback="L'indirizzo email è obbligatorio">
                <b-form-input placeholder="Vecchia Password" type="password" id="email-input" v-model="jsonData.oldPassword" required></b-form-input>
            </b-form-group>
            <b-form-group label="Nuova Password" label-for="email-input" invalid-feedback="L'indirizzo email è obbligatorio">
                <b-form-input placeholder="Nuova Password" type="password" id="email-input" v-model="jsonData.newPassword" required></b-form-input>
            </b-form-group>
            <b-form-group label="Verifica Password" label-for="email-input" invalid-feedback="L'indirizzo email è obbligatorio">
                <b-form-input placeholder="Verifica Password" type="password" id="email-input" v-model="jsonData.verificaPassword" required></b-form-input>
            </b-form-group>
        </b-modal>
    </b-navbar>
</template>

<script>
import Vue from "vue";
import avatar from "../../../assets/images/no-avatar.png";
import axios from "axios";
export default {
    props: {
        pageTitle: {
            type: String,
            required: true,
            default: "",
        },
        image: {
            type: String,
            default: avatar,
        },
        image2x: {
            type: String,
            default: avatar,
        },
        userName: {
            type: String,
            required: true,
        },
        idUtente: {
            type: String,
            required: false,
        },
        visible: {
            type: Boolean,
            required: false,
            default() {
                return false;
            },
        },
    },
    data() {
        return {
            linkProfiloView: null,
            disabledUserMenu: true,
            jsonData: { oldPassword: "", newPassword: "", verificaPassword: "" },
            gestionePasswordUtenteEnable: false,
            gestioneProfiloEnableEnable: false,
            urlCallBack: "",
        };
    },
    watch: {
        idUtente() {
            let me = this;
            me.setLinkProfiloView();
        },
    },
    created() {
        this.verificaSessione(this.$route.query.authToken);
    },
    mounted() {
        let me = this;
        me.gestionePasswordUtenteEnable = this.appConfig.gestionePasswordUtenteEnable;
        me.gestioneProfiloEnableEnable = this.appConfig.gestioneProfiloEnableEnable;
        me.urlCallBack = this.appConfig.spidParams.urlCallBack;
    },
    methods: {
        setLinkProfiloView() {
            let me = this;
            me.linkProfiloView = "#/profilo/view/" + this.idUtente;
        },
        onHamburgerMenuClick() {
            // let saSidebar = document.querySelector(".sa-sidebar");
            // let saMain = document.querySelector(".sa-main");
            // // saSidebar.classList.toggle("sa-sidebar-open");
            // // saMain.classList.toggle("sa-sidebar-open");
            document.body.classList.toggle("sa-body-sidebar-open");
        },
        onProfile() {
            let link = process.env.VUE_APP_PATH_API + "/profilo/view/" + this.idUtente;
            console.log(link);
            // axios.get(link, {}).then(() => {
            //   this.$router.push("login");
            //   location.reload();
            // });
        },
        onLogout() {
            let link = process.env.VUE_APP_PATH_API + "/logout";
            axios.get(link, {}).then((response) => {
                // console.log(response.data);
                if (response.data.spidSession) {
                    console.log("Sessione Spid");
                    let spidParams = Vue.prototype.appConfig.spidParams;
                    let authLink = spidParams.urlRequest + "/oidc/session/end?post_logout_redirect_uri=" + this.urlCallBack;
                    // console.log(authLink);

                    window.location.replace(authLink);
                } else {
                    // location.reload();
                    let sessionStorage = window.sessionStorage;
                    sessionStorage.clear();
                    this.$router.push("login");
                }
            });
        },
        onCambiaPassword() {
            let me = this;
            me.$refs.mdlCambioPassword.show();
        },
        onOk() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + "/users/cambiapasswordutente";
            axios
                .put(link, JSON.stringify(me.jsonData))
                .then(() => {
                    this.$bvToast.toast("Operazione avvenuta correttamente!", {
                        title: "",
                        variant: "success",
                        solid: true,
                    });
                })
                .catch((error) => {
                    me.showmolalloading = false;
                    this.$bvModal
                        .msgBoxOk(error.response.data.messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then((value) => {
                            this.boxTwo = value;
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                });
        },
        onHome() {
            let me = this;
            me.$router.replace("/").catch((err) => {
                err;
            });
        },
        verificaSessione(authToken) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + "/session/" + authToken;
            axios
                .get(link)
                .then((response) => {
                    me.disabledUserMenu = response.data.data.sessionType === "A";
                })
                .catch(() => {});
        },
    },
};
</script>

<style></style>
