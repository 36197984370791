<template>
    <div style="height: 100%; overflow: auto">
        <b-card class="sa-card" header="Area" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Nome:</label>
                    <span class="sa-data">{{ jsonData.nome }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Area:</label>
                    <span class="sa-data">{{ jsonData.areaDescrizione }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Diario:</label>
                    <span class="sa-data">{{ jsonData.diarioDescrizione }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Descrizione:</label>
                    <span class="sa-data">{{ jsonData.descrizione }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Dettagli Prestazioni" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Codice Prestazione:</label>
                    <span class="sa-data">{{ jsonData.codicePrestazione }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
                    <label class="sa-label-data">Descruzione Prestazione:</label>
                    <span class="sa-data">{{ jsonData.descrizionePrestazione }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Codice Prestazione Agg:</label>
                    <span class="sa-data">{{ jsonData.codicePrestazioneAgg }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
                    <label class="sa-label-data">Descruzione Prestazione Agg:</label>
                    <span class="sa-data">{{ jsonData.descrizionePrestazioneAgg }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Importo Prestazione:</label>
                    <span class="sa-data">{{ jsonData.importoPrestazione }}</span>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
<script>
import axios from "axios";
export default {
    data() {
        return {
            pathResource: "/rsaattivita",
            id: "-1",
            jsonData: {},
        };
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            me.$emit("beforeLoadData");
            if (me.id === "-1") {
                this.$emit("afterLoadData");
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                axios.get(link + me.id).then((response) => {
                    me.jsonData = response.data.data;
                    this.$emit("afterLoadData");
                });
            }
        },
    },
};
</script>
