<template>
    <sa-page-layout :showModalLoading="showModalLoading" :btnNewVisible="true" :btnRefreshVisible="true" @refresh="onRefresh()" :linkedit="linkEvent" :pathResource="pathResource">
        <template slot="toolbar-header">
            <div class="sa-patient-toolbar-header">
                <paziente-eventi-menu-component :idPaziente="idPaziente"></paziente-eventi-menu-component>
            </div>
        </template>
        <template slot="toolbar-title">
            <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
        </template>
        <template slot="table-filter">
            <h3 class="sa-event-title">{{ titoloEvento }}</h3>
        </template>
        <template slot="table-header">
            <!-- <h3 class="sa-event-title">{{ titoloEvento }}</h3> -->
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col cols="6">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" stacked="lg" striped hover :items="listaEventi" :fields="fields" sort-icon-left head-variant="light" class="sa-b-table">
                    <template v-slot:cell(dataEvento)="{ item }">
                        <router-link class="sa-edit-link" :to="linkEdit(item)">{{ formatDateTime(item.dataEvento) }}</router-link>
                    </template>
                    <template v-slot:cell(nomeAmbulatorio)="{ item }">
                        <strong>{{ item.nomeAmbulatorio }}</strong>
                        <br />
                        <small>{{ item.responsabileAmbulatorio }}</small>
                    </template>
                    <template v-slot:cell(statoFirma)="{ item }">
                        <b-button v-if="item.firma" v-b-tooltip.hover.right="onHoverStatoFirma(item)" variant="outline">
                            <font-awesome-icon icon="signature" class="text-success" />
                        </b-button>
                        <b-button v-else v-b-tooltip.hover.right="onHoverStatoFirma(item)" variant="outline">
                            <font-awesome-icon icon="signature" class="text-danger" />
                        </b-button>
                    </template>
                    <template #cell(actions)="row">
                        <digital-sign-component v-if="btnDigitalSign" :utente="utente" :cognomePaziente="row.item.cognomeAnagrafica" :identificativoPaziente="row.item.identificativoAnagrafica" :dataNascitaPaziente="row.item.dataNascitaAnagrafica" :nomePaziente="row.item.nomeAnagrafica" :tipoDocumento="row.item.gruppo + '_' + row.item.tipoEvento" :idRiferimento="row.item.id" :filename="getNomeFile(row.item)" :pdfLink="'/pazienteeventi/printpdf/' + row.item.id" pathResourceDigitalSign="/pazienteeventi/digitalsign" @beforeDigitalSignUser="onBeforeDigitalSignUser" @afterDigitalSignUser="onAfterDigitalSignUser" @beforePdfCreate="onBeforePdfCreate" @beforeSign="onBeforeSign" @afterSign="onAfterSign"></digital-sign-component>

                        <!-- <b-button v-if="btnPrintVisible && stampaEventoType === 'direct'" v-b-tooltip.hover title="Stampa" size="sm" variant="btn btn-purple waves-effect  waves-light width-sm no-text" style="margin-right: 3px" @click="onStampa(row.item)">
                            <b-icon icon="printer"></b-icon>
                        </b-button> -->

                        <print-component v-if="btnPrintVisible" :data="row" :linkPrintData="linkPrintData(row.item)" typeButton="small" :documentRepository="isDocumentRepository" :idRisorsa="row.item.id"></print-component>
                        <firma-component v-if="!row.item.firma && btnFirma" typeButton="small" :data="row.item" :pathRestFirma="pathResourceFirma" :idEvento="row.item.id" @update="onFirmaUpdate"></firma-component>

                    </template>
                </b-table>
            </div>
        </template>
        <template slot="table-footer">
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col cols="6">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
    </sa-page-layout>
</template>

<script>
import axios from "axios";
import SaPageLayout from "../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../../components/PazienteAnagrafica.vue";
import EventiMixin from "../../utility/mixins/EventiMixins";
import UtilityMixin from "../../../../utility/UtilityMixin.js";
import PrintComponent from "../../../../../app/utility/components/PrintComponent.vue";
import PazienteEventiMenuComponent from "./PazienteEventiMenuComponent.vue";
import FirmaComponent from "../../utility/components/FirmaComponent.vue";
import DigitalSignComponent from "../../../../utility/components/DigitalSignComponent.vue";
import moment from "moment";
export default {
    props: {
        btnFirmaVisible: { type: Boolean, require: true, default: false },
        utente: {
            type: Object,
            default: function () {
                return { gruppo: "" };
            },
        },
        pathEventResource: {
            type: String,
            require: true,
            default: function () {
                return "";
            },
        },
        linkEvent: {
            type: String,
            require: true,
            default: function () {
                return "";
            },
        },
        tipoEvento: {
            type: String,
            require: true,
            default: function () {
                return "";
            },
        },
        gruppo: {
            type: String,
            require: true,
            default: function () {
                return "";
            },
        },
        titoloEvento: {
            type: String,
            require: true,
            default: function () {
                return "";
            },
        },
    },
    mixins: [UtilityMixin, EventiMixin],
    components: {
        SaPageLayout,
        PazienteAnagrafica,
        PrintComponent,
        PazienteEventiMenuComponent,
        FirmaComponent,
        DigitalSignComponent,
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient() {
            return EventiMixin.getLinkPatient(this.utente, this.idPaziente);
        },
    },
    mounted() {
        let me = this;
        me.btnDigitalSign = this.appConfig.moduloGraffidiAttivo;
        me.stampaEventoType = this.appConfig.stampaEventoType;
        me.isDocumentRepository = me.stampaEventoType === "repository" ? true : false;
        me.btnFirma = this.appConfig.firmaInterna;
        me.linkView = me.linkEvent + "/view/";
        me.loadData();
    },
    watch: {
        utente: function () { },
    },
    data() {
        return {
            pdfFromSigned: null,
            listaEventi: [],
            pathResource: "",
            pathResourceData: "/pazienteeventi/print",
            pathResourceStampa: "/print/createreport",
            pathEventsResource: "/pazienteeventi",
            pathResourceFirma: "/pazienteeventi",
            showModalLoading: false,
            linkView: null,
            currentPage: 1,
            perPage: 30,
            filter: {},
            rows: 0,
            stampaReport: null,
            btnPrintVisible: true,
            btnDigitalSign: false,
            isDocumentRepository: false,
            stampaEventoType: "",
            fields: [
                {
                    label: "Data Evento",
                    thStyle: "width: 15rem",
                    key: "dataEvento",

                    sortable: true,
                },
                {
                    label: "Ambulatorio",
                    key: "nomeAmbulatorio",

                    sortable: true,
                },
                {
                    label: "Stato Firma",
                    key: "statoFirma",
                    thStyle: "width: 3rem",
                },
                {
                    label: "",
                    key: "actions",
                    tdClass: "text-center sa-td-button-content",
                    // thStyle: "width: 7rem",
                },
            ],
        };
    },
    methods: {
        getNomeFile(item) {
            let nomeFile = item.tipoEvento + "_" + item.identificativoAnagrafica + "_" + moment(new Date()).format("YYYY_MM_DD_HH_mm_ss");
            return nomeFile;
        },
        linkPrintData(item) {
            let returnValue = "/pazienteeventi/print/" + item.id;
            return returnValue;
        },
        linkEdit(item) {
            let me = this;
            return me.linkView + item.id;
        },
        loadData() {
            let me = this;
            me.showModalLoading = true;
            // let link = process.env.VUE_APP_PATH_API + me.pathEventsResource + "?idPaziente=" + me.idPaziente + "&tipoEvento=MEDICINADELLOSPORTAFA&page=" + me.currentPage + "&forPage=" + me.perPage;
            let link = process.env.VUE_APP_PATH_API + me.pathEventsResource + "?idPaziente=" + me.idPaziente + "&tipoEvento=" + me.tipoEvento + "&gruppo=" + me.gruppo + "&page=" + me.currentPage + "&forPage=" + me.perPage;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];
                    me.rows = response.data.data.recordsNumber;
                    me.items = response.data.data.list;
                    me.listaEventi = me.items;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        onFirmaUpdate() {
            let me = this;
            me.loadData();
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        onCopy(row) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathEventResource + "/";
            axios.get(link + row.id).then((response) => {
                me.itemVisitaDuplicata = response.data.data;
                me.$router.replace(me.linkedit + "/edit/-1").catch((err) => {
                    console.log(err);
                });
                sessionStorage.setItem("objVisita", JSON.stringify(this.itemVisitaDuplicata));
            });
        },
        onHoverStatoFirma(item) {
            let firma = "";
            if (item.firma) {
                firma = `${this.getLabelTraduora("patient.anamnesis.lblFirmaYes")} : ${item.firma === null ? "" : item.firma}`;
            } else {
                firma = `${this.getLabelTraduora("patient.anamnesis.lblFirma")}  ${item.firma === null ? "" : item.firma}`;
            }
            return firma;
        },

        onBeforeDigitalSignUser() {
            let me = this;
            me.showModalLoading = true;
        },
        onAfterDigitalSignUser() {
            let me = this;
            me.showModalLoading = false;
        },
        onBeforePdfCreate() {
            let me = this;
            me.showModalLoading = true;
        },
        onBeforeSign() {
            let me = this;
            me.showModalLoading = true;
        },
        onAfterSign() {
            let me = this;
            me.showModalLoading = false;
            me.loadData();
        },
        // onStampa(row) {
        //     // console.log(row);
        //     let me = this;
        //     let link = process.env.VUE_APP_PATH_API + me.pathResourceData + "/" + row.id;
        //     me.showModalLoading = true;
        //     axios
        //         .get(link)
        //         .then((response) => {
        //             let printData = response.data.data;
        //             for (let data in printData) {
        //                 // console.log(printData[data])
        //                 let value = printData[data];
        //                 if (typeof value === "string") {
        //                     if (value.includes(".lbl")) {
        //                         printData[data] = me.getLabelTraduora(printData[data]);
        //                     }
        //                 }
        //             }
        //             let linkReport = process.env.VUE_APP_PATH_API + me.pathResourceStampa;
        //             console.log(JSON.stringify(printData));
        //             axios
        //                 .post(linkReport, { reportName: "Evento", subReports: [{ reportName: row.printFile }], data: printData })
        //                 .then((response) => {
        //                     me.$refs.mdlStampaReferto.show();
        //                     me.stampaReport = "data:application/pdf;base64," + response.data.base64;
        //                     me.showModalLoading = false;
        //                 })
        //                 .catch((error) => {
        //                     me.showModalLoading = false;
        //                     let messaggio = error.response.data.messaggio ? error.response.data.messaggio : "Errore non gestito";
        //                     this.$bvModal
        //                         .msgBoxOk(messaggio, {
        //                             title: this.$i18n.t("ERROR!"),
        //                             size: "sm",
        //                             okVariant: "outline-danger",
        //                             headerClass: "sa-msg-header-danger",
        //                             footerClass: "p-2 border-top-0",
        //                             centered: true,
        //                         })
        //                         .then((value) => {
        //                             this.boxTwo = value;
        //                         })
        //                         .catch((err) => {
        //                             console.log(err);
        //                         });
        //                 });
        //         })
        //         .catch((error) => {
        //             me.showModalLoading = false;
        //             let messaggio = error.response.data.messaggio ? error.response.data.messaggio : "Errore non gestito";
        //             this.$bvModal
        //                 .msgBoxOk(messaggio, {
        //                     title: this.$i18n.t("ERROR!"),
        //                     size: "sm",
        //                     okVariant: "outline-danger",
        //                     headerClass: "sa-msg-header-danger",
        //                     footerClass: "p-2 border-top-0",
        //                     centered: true,
        //                 })
        //                 .then((value) => {
        //                     this.boxTwo = value;
        //                 })
        //                 .catch((err) => {
        //                     console.log(err);
        //                 });
        //         });
        // },
    },
};
</script>

<style></style>
