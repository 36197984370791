<template>
    <b-modal ref="mdlAgendaNuovoAppuntamentoModalComponent" no-close-on-backdrop no-close-on-select title="Dettaglio Appuntamento" hide-footer>
        <div class="modal-body">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label for="titolo">Nominativo</label>
                    <input type="text" name="titolo" class="form-control" v-model="objectAppuntamento.titolo" />
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label for="identificativoAppuntamento">{{ this.$i18n.t("patients.lblFiscalCode") }}</label>
                    <input type="text" name="identificativoAppuntamento" class="form-control text-upper" v-model="objectAppuntamento.identificativoAppuntamento" />
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label for="codicePrestazione">{{ this.$i18n.t("patient.agenda.lblPerformanceCode") }}</label>
                    <input name="codicePrestazione" class="form-control" v-model="objectAppuntamento.codicePrestazione" />
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label for="descrizionePrestazione">{{ this.$i18n.t("patient.agenda.lblDescriptionPerformance") }}</label>
                    <input type="text" name="descrizionePrestazione" class="form-control" v-model="objectAppuntamento.descrizionePrestazione" />
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label for="costo">{{ this.$i18n.t("patient.agenda.lblCost") }}</label>
                    <input type="text" name="costo" class="form-control" v-model="objectAppuntamento.costo" />
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label for="dataOraInizio">{{ this.$i18n.t("patient.agenda.lblDateReference") }}</label>
                    <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="objectAppuntamento.dataRiferimento" type="timestamp" disabled></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label for="dataInizio">{{ this.$i18n.t("patient.agenda.lblStartTime") }}</label>
                    <date-picker format="HH:mm" value-type="timestamp" v-model="objectAppuntamento.dataOraInizio" type="time" placeholder="HH:mm" disabled></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label for="indirizzo">{{ this.$i18n.t("patient.agenda.lblEndTime") }}</label>
                    <date-picker format="HH:mm" value-type="timestamp" v-model="objectAppuntamento.dataOraFine" type="time" placeholder="HH:mm" disabled></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label for="note">{{ this.$i18n.t("patient.diet.lblNote") }}</label>
                    <b-form-textarea class="form-control" name="note" v-model="objectAppuntamento.note" rows="3" no-resize></b-form-textarea>
                </b-col>
            </b-row>
        </div>
        <hr />
        <b-row>
            <div class="sa-padding-right text-right col">
                <b-button type="button" variant="secondary" @click="onCancel">{{ this.$i18n.t("global.lblBtnCancel") }}</b-button>
                <b-button type="button" variant="primary" @click="onSave">{{ this.$i18n.t("global.lblBtnSave") }}</b-button>
            </div>
        </b-row>
    </b-modal>
</template>

<script>
import DatePicker from "vue2-datepicker";
import UtilityMixin from "../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixin],
    components: { DatePicker },
    data() {
        return {
            pathResourceAgendaAppuntamenti: "/agendaappuntamenti",
            objectAppuntamento: { idAgenda: null, titolo: null, identificativoAppuntamento: null, codicePrestazione: null, descrizionePrestazione: null, dataOraInizio: null, dataOraFine: null, dataRiferimento: null, costo: null, note: null },
        };
    },
    methods: {
        show(objectAppuntamento) {
            this.objectAppuntamento = objectAppuntamento;
            console.log(this.objectAppuntamento);
            this.$refs.mdlAgendaNuovoAppuntamentoModalComponent.show();
        },
        onCancel() {
            this.$refs.mdlAgendaNuovoAppuntamentoModalComponent.hide();
        },
        onSave() {
            let me = this;
            console.log(me.objectAppuntamento);
            UtilityMixin.methods.saveResource(me.pathResourceAgendaAppuntamenti, me.objectAppuntamento, me.saveSucessResponse, me.saveErrorResponse);
        },
        saveSucessResponse(response) {
            this.$emit("successRestCall", response);
            this.$refs.mdlAgendaNuovoAppuntamentoModalComponent.hide();
        },
        saveErrorResponse(response) {
            if (response.response.status === 400) {
                this.$emit("errorRestCall", response);
            } else {
                this.$bvToast.toast("Errore nel salvarei dati del Piano: " + response.message, {
                    title: "",
                    variant: "danger",
                    solid: true,
                });
            }
        },
    },
};
</script>

<style></style>
