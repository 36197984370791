<template>
    <div class="sa-free-page">
        <div class="sa-free-page-header">
            <div style="display: flex; flex-direction: row">
                <div style="flex: 1"></div>
                <div>
                    <b-button v-b-tooltip.hover size="sm" variant="outline-success" title="Verifica Connessione" @click="onClickNuovoDatasource()" class="mr-1 no-text">
                        <i class="fas fa-plus"></i>
                        Nuovo Datasource
                    </b-button>
                </div>
            </div>
        </div>
        <div class="sa-free-page-body">
            <div class="b-table-sticky-header" style="width: 100%">
                <b-table sticky-header ref="table" stacked="lg" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                    <template v-slot:cell(actions)="row">
                        <b-container class="bv-example-row">
                            <b-button v-b-tooltip.hover size="sm" variant="outline-primary no-text" title="Modifica Driver" @click="onEditDatasource(row.item, row.index, $event.target)" class="mr-1 no-text">
                                <b-icon icon="pencil"></b-icon>
                            </b-button>
                            <b-button v-b-tooltip.hover size="sm" variant="outline-primary no-text" title="Verifica Connessione" @click="onClickVerificaConnessione(row.item, row.index, $event.target)" class="mr-1 no-text">
                                <i class="fas fa-cogs"></i>
                            </b-button>
                        </b-container>
                    </template>
                </b-table>
            </div>
        </div>
        <div>
            <data-analysis-data-sources-edit-modal-component ref="mdlDataAnalysisDataSourcesEditModalComponent" @saveDateSuccess="saveDateSuccessDataSourcesEdi"></data-analysis-data-sources-edit-modal-component>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import UtilityMixin from "../../utility/UtilityMixin";
import DataAnalysisDataSourcesEditModalComponent from "./DataAnalysisDataSourcesEditModalComponent.vue";
export default {
    mixins: [UtilityMixin],
    components: { DataAnalysisDataSourcesEditModalComponent },
    data() {
        return {
            perPage: 1000,
            items: [],
            rows: 0,
            pathResources: "/dataanalysisdatasources",
            pathResourcesVerificaConnessione: "/dataanalysisdatasources/verificaconnessione",
            fields: [
                {
                    label: "Nome",
                    key: "datasourceName",
                    sortable: true,
                },
                {
                    label: "Host",
                    key: "host",
                    sortable: true,
                },
                {
                    label: "Porta",
                    key: "port",
                    sortable: true,
                },
                {
                    label: "Database",
                    key: "database",
                    sortable: true,
                },
                {
                    label: "Username",
                    key: "username",
                    sortable: true,
                },
                {
                    label: "Connection String",
                    key: "jdbcUrl",
                    sortable: true,
                },
                { class: "sa-table-column-action-double", key: "actions", label: "" },
            ],
        };
    },
    mounted() {
        let me = this;
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResources;
            axios
                .get(link)
                .then((response) => {
                    me.items = [];
                    me.rows = response.data.data.recordsNumber;
                    me.items = response.data.data.list;
                    this.$emit("afterLoadData");
                })
                .catch(() => {
                    this.$emit("afterLoadData");
                });
        },
        onClickNuovoDatasource() {
            let me = this;
            let jsonData = { id: "-1", driver: null, datasourceName: null, host: null, port: null, database: null, username: null, password: null, jdbcUrl: null };
            me.$refs.mdlDataAnalysisDataSourcesEditModalComponent.setJsonData(jsonData);
            me.$refs.mdlDataAnalysisDataSourcesEditModalComponent.show();
        },
        onEditDatasource(item) {
            let me = this;
            me.$refs.mdlDataAnalysisDataSourcesEditModalComponent.setJsonData(item);
            me.$refs.mdlDataAnalysisDataSourcesEditModalComponent.show();
        },
        saveDateSuccessDataSourcesEdi() {
            let me = this;
            me.loadData();
        },
        onClickVerificaConnessione(item) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourcesVerificaConnessione + "/" + item.id;
            axios
                .get(link)
                .then(() => {
                    this.$bvToast.toast("Connessione verificata con successo", {
                        title: "Info",
                        variant: "success",
                        solid: true,
                    });
                })
                .catch((response) => {
                    this.$bvToast.toast("Errore di connessione: " + response.message, {
                        title: "ATTENZIONE",
                        variant: "danger",
                        solid: true,
                    });
                });
        },
    },
};
</script>

<style></style>
