<template>
    <sa-page-layout :btnBackVisible="true" :btnPrintVisible="false" :btnEditVisible="false" :linkedit="linkedit" :linkback="linkback">
        <template slot="table-header">
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Richiesta: </label>
                    <span class="sa-label-data">{{ formatDateTime(jsonData.createDate) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Servizio Richiesto: </label>
                    <span class="sa-label-data">{{ jsonData.servizioRichiesto }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label>Metodo: </label>
                    <span class="sa-label-data">{{ jsonData.metodo }}</span>
                    <!-- <label>Data Ordine: </label>{{ formatDate(jsonData.dataOrdine) }} -->
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <div class="sa-div-scroll sa-scroll">
                <div class="b-table-sticky-header">
                    <b-table sticky-header ref="table" :filtro="filtro" stacked="xl" striped hover :items="jsonData.listaStati" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="data">
                            {{ data.index + 1 }}
                        </template>
                    </b-table>
                </div>
                <div class="sa-div-scroll sa-scroll">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Esito Business: </label>
                            <span class="sa-label-data">{{ jsonData.statusRispostaBusiness }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                            <label class="sa-label-data">Risposta Business: </label>
                            <span class="sa-label-data">{{ jsonData.rispostaBusiness }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                            <label class="sa-label-data">Body Richiesta: </label>
                            <!-- <span class="sa-label-data">{{ jsonData.body }}</span> -->
                            <pre>{{ prettyFormat(jsonData.body) }}</pre>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                            <label class="sa-label-data">Richiesta Inviata: </label>
                            <!-- <span class="sa-label-data">{{ jsonData.idMessaggio }}</span> -->
                            <pre>{{ prettyFormat(jsonData.idMessaggio) }}</pre>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </template>
        <template slot="table-footer">
            <!-- <b-row class="sa-label-info">
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label>Data Creazione: </label>
                    <span class="sa-data-text">{{
                        formatDate(jsonData.createDate)
                    }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label>Username creatore: </label>{{ jsonData.createUser }}
                </b-col>
            </b-row> -->
        </template>
    </sa-page-layout>
</template>

<script>
import axios from "axios";
import moment from "moment";
import UtilityMixins from "../../utility/UtilityMixin";
require("moment/locale/it");
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";

moment.locale("it");
export default {
    components: { SaPageLayout },
    mixins: [UtilityMixins],
    computed: {
        rows() {
            return this.items.length;
        },

    },
    data() {
        return {
            rigaSelezionata: null,
            pathRest: "/integrazionebusiness",
            linkedit: null,
            linkback: "/integrazionebusiness",
            currentPage: 1,
            perPage: null,
            filtro: "",
            value: 0,
            fields: [
                {
                    label: "N°",
                    key: "index",
                    sortable: true,
                    thStyle: "width: 4em",
                },
                {
                    label: "Stato Attuale",
                    key: "stato",
                    sortable: false,
                    thStyle: "width: 1em",
                },
                {
                    label: "Data/Ora",
                    key: "statoDataOra",
                    sortable: false,
                    thStyle: "width: 1em",
                    formatter: (value) => {
                        return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
                    },
                },
                // {
                //     label: "Peso",
                //     key: "peso",
                //     sortable: false,
                //     thStyle: "width: 1em",
                // },
                // {
                //     label: "Buste",
                //     key: "bustaDescrizione",
                //     sortable: false,
                //     thStyle: "width: 1em",
                // },
                // {
                //     label: "Contenitori",
                //     key: "contenitoreDescrizione",
                //     sortable: false,
                //     thStyle: "width: 1em",
                // },
                // {
                //     label: this.$i18n.t("billing.lblTipoSpesa"),
                //     key: "codiceTipoSpesa",
                //     sortable: true,
                //     thStyle: "width: 8rem",
                // },
            ],
            jsonRigaOrdine: [],
            jsonData: {},
        };
    },
    mounted: function () {
        let me = this;
        me.id = this.$route.params.id;
        me.linkedit = "/fatturazione/edit/" + me.id;
        this.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathRest + "/";
            me.isBusy = true;
            axios
                .get(link + me.id)
                .then((response) => {
                    me.jsonRigaOrdine = [];
                    me.jsonData = response.data.data;
                    me.jsonRigaOrdine = me.jsonData.ordineRiga;
                    me.perPage = me.jsonData.length;
                })
                .catch(() => {
                    me.showmolalloading = false;
                });
        },
        prettyFormat(value) {
            let me = this;
            me.jsonerror = "";
            let jsonValue = "";
            try {
                jsonValue = JSON.parse(value);
            } catch (e) {
                console.log(e.message);
                me.jsonerror = JSON.stringify(e.message);
                var textarea = document.getElementById("jsonText");
                if (me.jsonerror.indexOf("position") > -1) {
                    // highlight error position
                    var positionStr = me.jsonerror.lastIndexOf("position") + 8;
                    var posi = parseInt(me.jsonerror.substr(positionStr, me.jsonerror.lastIndexOf('"')));
                    if (posi >= 0) {
                        textarea.setSelectionRange(posi, posi + 1);
                    }
                }
                console.log(me.jsonerror);
                return "";
            }
            return JSON.stringify(jsonValue, null, 2);
        },
        formatDate(value) {
            if (value) {
                //return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
                return moment(new Date(value)).format("DD-MM-YYYY");
            } else {
                return "---";
            }
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        onSave() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pagelink;
            let jsonData = JSON.stringify(me.jsonData);

            me.showmolalloading = true;
            if (me.id == "-1") {
                axios
                    .post(link, jsonData)
                    .then((response) => {
                        me.jsonData = response.data.data;
                        me.showmolalloading = false;
                        me.$router.replace(me.routeLink).catch((err) => {
                            err;
                        });
                    })
                    .catch((error) => {
                        me.showmolalloading = false;
                        this.$bvModal
                            .msgBoxOk(error.response.data.messaggio, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then((value) => {
                                this.boxTwo = value;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
            } else {
                axios
                    .put(link + "/" + me.id, jsonData)
                    .then((response) => {
                        me.gruppo = response.data.data;
                        me.$refs["sa-modal-loading"].close();
                        me.$router.replace(me.routeLink).catch((err) => {
                            err;
                        });
                    })
                    .catch((error) => {
                        me.$refs["sa-modal-loading"].close();
                        this.$bvModal
                            .msgBoxOk(error.response.data.messaggio, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then((value) => {
                                this.boxTwo = value;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
            }
        },
    },
};
</script>
<style lang="css">
/*.sa-documenti-azienda {
    font-size: 1.05em;
    font-weight: bold;
}

.sa-fieldset-intestazione legend {
    font-size: 1em;
    font-weight: bold;
    text-decoration: underline;
}
.sa-fieldset-intestazione p {
    margin-bottom: 0.5em;
    line-height: 1.1em;
}
.sa-fieldset-intestazione label {
    padding-right: 0.3em;
    margin-bottom: 0em;
}
.sa-documenti-table {
    font-size: 0.75rem;
}*/
</style>
