<template>
    <div>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card sa-card-border" header="Esame Cardiologico" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Azione Cardiaca</label>
                            <b-form-select v-model="jsonDataEsameCardiologico.azioneCardiaca" :options="azioneCardiacaOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Toni Cardiaci</label>
                            <b-form-select v-model="jsonDataEsameCardiologico.toniCardiaci" :options="toniCardiaciOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Descrizione Toni Cardiaci</label>
                            <b-form-input v-model="jsonDataEsameCardiologico.toniCardiaciDescrizione" :readonly="isReadOnly"> </b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Itto</label>
                            <b-form-select v-model="jsonDataEsameCardiologico.itto" :options="ittoOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Descrizione Itto</label>
                            <b-form-input v-model="jsonDataEsameCardiologico.ittoDescrizione" :readonly="isReadOnly"> </b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Pause</label>
                            <b-form-select v-model="jsonDataEsameCardiologico.pause" :options="pauseOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Descrizione Pause</label>
                            <b-form-input v-model="jsonDataEsameCardiologico.pauseDescrizione" :readonly="isReadOnly"> </b-form-input>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Note</label>
                            <b-form-textarea v-model="jsonDataEsameCardiologico.noteEsameCardiologico" :readonly="isReadOnly" max-rows="10" rows="7"> </b-form-textarea>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import UtilityMixin from "../../../utility/UtilityMixin";

export default {
    props: {
        isReadOnly: {
            type: Boolean,
            default: false,
        },
        idAccettazione: {
            type: String,
        },
    },
    mixins: [UtilityMixin],
    data() {
        return {
            pathResourceSchedaEsameObiettivoGeneraleEsameCardiologico: "/rsaschedaesameobiettivogeneraleesamecardiologico",
            jsonDataEsameCardiologico: {
                id: null,
                idAccettazione: null,
                azioneCardiaca: null,
                toniCardiaci: null,
                toniCardiaciDescrizione: null,
                itto: null,
                ittoDescrizione: null,
                pause: null,
                pauseDescrizione: null,
                noteEsameCardiologico: null,
            },

            azioneCardiacaOptions: [
                { text: "RITMICA", value: "RITMICA" },
                { text: "ARITMICA", value: "ARITMICA" },
            ],
            toniCardiaciOptions: [
                { text: "PURI", value: "PURI" },
                { text: "IMPURI", value: "IMPURI" },
            ],
            ittoOptions: [
                { text: "VISIBILE", value: "VISIBILE" },
                { text: "NON VISIBILE", value: "NON VISIBILE" },
            ],
            pauseOptions: [
                { text: "LIBERE", value: "LIBERE" },
                { text: "SOFFI", value: "SOFFI" },
            ],
        };
    },
    methods: {
        loadEsameCardiologicoData() {
            let me = this;
            // console.log("ID accettazione nel loadEsameCardiologicoData: " + me.idAccettazione);
            UtilityMixin.methods.loadDataResources(me.pathResourceSchedaEsameObiettivoGeneraleEsameCardiologico + "/accettazione/" + me.idAccettazione, {}, me.resultResponseSchedaEsameObiettivoGeneraleEsameCardiologicoSuccess, me.resultResponseSchedaEsameObiettivoGeneraleEsameCardiologicoError);
        },
        resultResponseSchedaEsameObiettivoGeneraleEsameCardiologicoSuccess(response) {
            let me = this;
            me.jsonDataEsameCardiologico = response.data.data;
            // console.log("ID del record ESAME CARDIOLOGICO:", me.jsonDataEsameCardiologico.id);
        },
        resultResponseSchedaEsameObiettivoGeneraleEsameCardiologicoError(error) {
            let me = this;
            if (error.response && error.response.status === 404) {
                //console.log("NESSUN record trovato del ESAME CARDIOLOGICO per questo id accettazione");
                me.resetForm();
            } else {
                console.error("Errore nel caricamento dati della sezione ESAME CARDIOLOGICO:", error);
            }
        },
        resetForm() {
            let me = this;
            me.jsonDataEsameCardiologico = {
                id: null,
                idAccettazione: me.idAccettazione,
                azioneCardiaca: null,
                toniCardiaci: null,
                toniCardiaciDescrizione: null,
                itto: null,
                ittoDescrizione: null,
                pause: null,
                pauseDescrizione: null,
                noteEsameCardiologico: null,
            };
        },

        onSalvaEsameCardiologico() {
            let me = this;
            me.jsonDataEsameCardiologico.idAccettazione = me.idAccettazione;
            // console.log("Salvataggio ESAME CARDIOLOGICO con dati:", JSON.stringify(me.jsonDataEsameCardiologico));
            if (!me.jsonDataEsameCardiologico.idAccettazione) {
                // console.log("idAccettazione: ", me.jsonDataEsameCardiologico.idAccettazione);
                me.$bvToast.toast("Errore: ID Accettazione mancante", {
                    title: "Errore",
                    variant: "danger",
                    solid: true,
                });
                return;
            }

            UtilityMixin.methods.saveResource(me.pathResourceSchedaEsameObiettivoGeneraleEsameCardiologico, me.jsonDataEsameCardiologico, me.saveSchedaEsameObiettivoGeneraleEsameCardiologicoSuccessResponse, me.saveSchedaEsameObiettivoGeneraleEsameCardiologicoErrorResponse);
        },
        saveSchedaEsameObiettivoGeneraleEsameCardiologicoSuccessResponse() {
            //console.log("ESAME CARDIOLOGICO salvato con successo:", response);
        },
        saveSchedaEsameObiettivoGeneraleEsameCardiologicoErrorResponse() {
            console.error("Errore nel salvataggio ESAME CARDIOLOGICO:");
        },
    },
};
</script>
