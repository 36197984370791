<template>
    <b-modal title="Info" ref="mdlInfo" id="mdlInfo" size="lg" modal-class="sa-modal-static-scroll" style="height: 100%; max-height: calc(100vh - 3.5rem)">
        <div class="sa-flexbox">
            <div class="sa-flexbox-header">
            </div>
            <div class="sa-flexbox-body">
                <b-tabs id="tabsInfo" nav-wrapper-class="sa-tab-items" content-class="mt-3" ref="tabs" class="sa-tab" style="height: 100%; padding-bottom: 25px" fill>
                    <b-tab title="Client HL7">
                        <div class="b-table-sticky-header">
                            <b-table sticky-header ref="tableHl7Logs" stacked="lg" striped hover :items="hl7ClientLogs" :fields="fieldsStorico" :per-page="perPage" :current-page="1" sort-icon-left head-variant="light" class="sa-b-table"></b-table>
                        </div>
                    </b-tab>
                    <b-tab title="Sync">
                        <div class="b-table-sticky-header">
                            <b-table sticky-header ref="tableSync" stacked="lg" striped hover :items="syncLogs" :fields="fieldsStoricoSync" :per-page="perPage" :current-page="1" sort-icon-left head-variant="light" class="sa-b-table"></b-table>
                        </div>
                    </b-tab>
                </b-tabs>
            </div>
            <div class="sa-flexbox-footer"></div>
        </div>
    </b-modal>
</template>

<script>
import axios from "axios";
import moment from "moment";
// import DatePicker from "vue2-datepicker";
export default {
    components: {
        // DatePicker,
    },
    data() {
        return {
            rows: 0,
            rowsHl7Logs: 0,
            rowsSyncLogs: 0,
            perPage: 10000,
            currentPage: 1,
            hl7ClientLogs: [],
            syncLogs: [],
            idRichiestaEsami: null,
            pathResourceHl7: "/hl7integratorclientmessage",
            pathResourceSync: "/externalsynclog",
            fieldsStorico: [
                {
                    label: "Data Ora Messaggio",
                    key: "sendDateHour",
                    formatter: (value) => {
                        if (value) {
                            return moment(new Date(value)).format("DD/MM/YYYY HH:mm:ss");
                        } else {
                            return "-----";
                        }
                    },
                    thStyle: "width: 8rem",
                    sortable: true,
                },
                {
                    label: "Messaggio",
                    key: "sendMessage",
                    sortable: true,
                },
                {
                    label: "Cod. Risposta",
                    key: "responseCode",
                    sortable: true,
                },
            ],
            fieldsStoricoSync: [
                {
                    label: "Data Ora",
                    key: "dataOra",
                    formatter: (value) => {
                        if (value) {
                            return moment(new Date(value)).format("DD/MM/YYYY HH:mm:ss");
                        } else {
                            return "-----";
                        }
                    },
                    thStyle: "width: 8rem",
                    sortable: true,
                },
                {
                    label: "Stato",
                    key: "stato",
                    sortable: true,
                },
                {
                    label: "Descrizione",
                    key: "descrizione",
                    sortable: true,
                },
            ],

        };
    },
    methods: {
        setIdRichiestaEsami(value) {
            let me = this;
            me.idRichiestaEsami = value;
        },
        show() {
            let me = this;
            me.loadHl7Logs();
            me.loadSyncLogs();
            me.$refs.mdlInfo.show();
        },
        loadHl7Logs() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceHl7;
            this.$emit("beforeLoadData");
            let filtro = {};
            filtro.page = me.currentPage;
            filtro.forPage = me.perPage;
            filtro.idRisorsa = me.idRichiestaEsami;
            axios
                .get(link, { params: filtro })
                .then((response) => {
                    me.hl7ClientLogs = [];
                    me.rowsHl7Logs = response.data.data.recordsNumber;
                    me.hl7ClientLogs = response.data.data.list;
                    me.listKey++;
                    this.$emit("afterLoadData");
                })
                .catch(() => {
                    this.$emit("afterLoadData");
                });
        },
        loadSyncLogs() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceSync;
            this.$emit("beforeLoadData");
            let filtro = {};
            filtro.page = me.currentPage;
            filtro.forPage = me.perPage;
            filtro.idRisorsa = me.idRichiestaEsami;
            axios
                .get(link, { params: filtro })
                .then((response) => {
                    me.syncLogs = [];
                    me.rowsSyncLogs = response.data.data.recordsNumber;
                    me.syncLogs = response.data.data.list;
                    me.listKey++;
                    this.$emit("afterLoadData");
                })
                .catch(() => {
                    this.$emit("afterLoadData");
                });
        },
        close() {
            let me = this;
            me.$refs.mdlInfo.hide();
        },
        onClickUndo() {
            let me = this;
            me.$refs.mdlInfo.hide();
        },
    },
};
</script>

<style></style>
