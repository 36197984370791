<template>
  <sa-page-layout>
    <template slot="toolbar">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem; margin-top: 1rem; margin-bottom: 1rem">
        <b-col class="sa-padding-right text-right" style="margin-right: 0.5rem">
          <b-button class="float-sm-right" variant="success btn-toolbar sa-margin-right" size="sm" @click="onAdd"><b-icon icon="plus" variant="secondary"></b-icon>Nuovo Articolo</b-button>
          <b-button class="float-sm-right" variant="info btn-toolbar" size="sm" @click="onRefresh" v-b-modal.modal-center><b-icon icon="arrow-clockwise" variant="secondary" />Refresh</b-button>
        </b-col>
      </b-row>
    </template>
    <template slot="table-filter">
      <b-form @submit.prevent="onSubmit">
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <b-form-input v-model="filtro.codice" type="search" id="codice" placeholder="Codice"></b-form-input>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <b-form-select id="inline-form-custom-select-pref" placeholder="Categoria" v-model="filtro.categoria" class="mb-2 mr-sm-2 mb-sm-0" :options="sessoOption"> </b-form-select>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <b-form-input v-model="filtro.descrizione" type="search" id="descrizione" placeholder="Descrizione"></b-form-input>
          </b-col>
        </b-row>
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem; margin-top: 1rem; margin-bottom: 1rem">
          <b-col class="sa-padding-right text-right">
            <!-- <b-button type="submit" variant="info" style="margin-right: 0.5rem">Cerca</b-button> -->
            <b-button type="reset" v-on:click="onReset" variant="danger">Reset</b-button>
          </b-col>
        </b-row>
      </b-form>
    </template>
    <template slot="table-header">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>Totale righe: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
    <template slot="table-body">
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="table" :filter="filter" responsive stacked="lg" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
          <template v-slot:cell(codice)="{ item }">
            <router-link class="sa-edit-link" :to="'/magazzino/view/' + item.id">{{ item.codice }} </router-link>
          </template>
          <template v-slot:cell(cerca)="{ item }">
            <a :href="onGetLink(item.codice)" target="_blank" rel="noreferrer">Dettagli online</a>
          </template>
          <template #cell(actions)="row">
            <b-button size="sm" variant="btn btn-purple waves-effect  waves-light width-sm no-text" style="margin-right: 3px" @click="onCambioStato(row.item, row.index, $event.target)">
              <b-icon icon="pencil-square"></b-icon>
            </b-button>
          </template>
        </b-table>
      </div>
    </template>
    <template slot="table-footer">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>Totale righe: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>

      <b-modal ref="tstModal" size="lg" id="my-modal" @ok="onOk" @hidden="onHiddenModal">
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="6" xl="6">
            <span class="sa-form-label">Tipo Movimento</span>
            <b-form-select id="inline-form-custom-select-pref" v-model="data.tipoMovimento" class="mb-2 mr-sm-2 mb-sm-0" :options="tipoMovimentoOption"> </b-form-select>
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="6" xl="6">
            <span class="sa-form-label">Quantita</span>
            <b-input-group class="mb-3">
              <b-form-input v-model="data.quantita"></b-form-input>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row >
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <span class="sa-form-label">Note</span>
              <b-form-textarea v-model="data.note" placeholder="" rows="1" max-rows="2" no-resize></b-form-textarea>
            </b-col>
        </b-row>
      </b-modal>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
// import moment from "moment";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
  components: { SaPageLayout },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  watch: {
        "filtro.codice": function (value) {
          if(value !== undefined){
            this.onSubmit();
          }
        },
        "filtro.descrizione": function (value) {
          if(value !== undefined){
            this.onSubmit();
          }
        },
        "filtro.categoria": function (value) {
          if(value !== undefined){
            this.onSubmit();
          }
        },
    },
  data() {
    return {
      perPage: 20,
      currentPage: 1,
      filter: null,
      pathDeleteAnagrafica: "/anagrafica",
      pathRest: "/magazzinoarticoli",
      pathRESTRequest: "/magazzino",
      filtro: {},
      filterOn: [],
      sessoOption: [],
      tipoMovimentoOption: [],
      fields: [
        {
          label: "Codice",
          key: "codice",
          sortable: true,
        },
        {
          label: "Ceca Online",
          key: "cerca",
        },
        {
          label: "Categoria",
          key: "categoria",
          sortable: true,
          //thStyle: "width: 7rem",
        },
        {
          label: "Descrizione",
          key: "descrizione",
          //thStyle: "width: 5rem",
          sortable: true,
        },
        {
          label: "Prezzo",
          key: "prezzo",
          sortable: true,
        },
        {
          label: "Quantita",
          key: "quantita",
          sortable: true,
        },
        { key: "actions", label: "", thStyle: "width: 7rem" },
      ],
      data: {},
      items: [],
    };
  },
  created: function () {},
  mounted: function () {
    console.log("Lista Pazienti");
    this.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      me.sessoOption=[];
      me.loadSesso();
      me.loadTipiMovimento();
      let link = process.env.VUE_APP_PATH_API + me.pathRest + "?page=1&forPage=100000";
      me.isBusy = true;
      axios
        .get(link, { params: me.filtro })
        .then((response) => {
          me.items = [];
          me.items = response.data.data.list;
          //me.$refs["SaPageList"].close();
        })
        .catch(() => {
          me.showmolalloading = false;
        });
    },
    onHiddenModal() {
      let me = this;
      me.data = {};
    },
    onGetLink(value) {
      // console.log(value);
      return "https://www.google.it/search?q=" + value;
    },
    onOk() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/magazzinomovimenti";
      let jsonData = JSON.stringify(me.data);
      axios
        .post(link, jsonData)
        .then(() => {
          me.showModalLoading = false;
          me.loadData();
        })
        .catch((error) => {
          // me.$refs["sa-modal-loading"].close();
          this.$bvModal.msgBoxOk(error.response.data.messaggio, {
            title: "Errore",
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "sa-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
          });
        });
    },
    onSubmit() {
      let me = this;
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onReset() {
      let me = this;
      me.filtro = {};
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onAdd() {
      let me = this;
      me.$router.replace(me.pathRESTRequest + "/edit/-1").catch((err) => {
        err;
      });
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
    onDeleteItem(item) {
      let me = this;
      let domanda = this.$i18n.t("patient.event.lblConfirmDeleteQuestion");
      let titolo = this.$i18n.t("patient.event.lblConfirmDelete");
      let si = this.$i18n.t("patient.lblYes");
      let no = this.$i18n.t("patient.lblNo");
      this.$bvModal
        .msgBoxConfirm(domanda, {
          title: titolo,
          buttonSize: "sm",
          okVariant: "secondary",
          cancelVariant: "danger",
          okTitle: si,
          cancelTitle: no,
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            me.deleteItem(item);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onCambioStato(item) {
      let me = this;
      me.$refs.tstModal.show();
      me.data.codiceArticolo = item.codice;
      console.log(item);
    },
    loadSesso() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/magazzinocategoriaarticolo?page=1&forPage=100000";
      me.sessoOption = [];
      axios.get(link).then((res) => {
        let sessi = res.data.data.list;
        sessi.forEach((element) => {
          let obj = element.categoria;
          me.sessoOption.push(obj);
        });
      });
    },
    loadTipiMovimento() {
      let link = process.env.VUE_APP_PATH_API + "/magazzinotipomovimento?page=1&forPage=2";
      this.tipoMovimentoOption = [];
      axios.get(link).then((res) => {
        let sessi = res.data.data.list;
        sessi.forEach((element) => {
          let obj = element.tipoMovimento;
          this.tipoMovimentoOption.push(obj);
        });
      });
    },
    deleteItem(item) {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathDeleteAnagrafica + "/" + item.id;
      axios
        .delete(link)
        .then((Response) => {
          console.log(Response);
          me.showModalLoading = false;
          me.loadData();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    onShareItem(item) {
      console.log(item);
    },
  },
};
</script>
