<template>
	<b-card class="sa-card" header="Dati Paziente" header-tag="header" footer-tag="footer" title="">
		<div class="bg-picture card-box">
			<div class="profile-info-name">
				<div class="container-fluid">
					<b-row>
						<b-col cols="10" xs="10" sm="10" md="10" lg="10" xl="10">
							<b-row>
								<b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
									<div style="height: 100%; display: flex; align-items: center">
										<div style="width: 6rem; height: 6rem; float: left"><img :src="patientAvatar" class="rounded-circle avatar-xl img-thumbnail float-left mr-3" alt="profile-image" /></div>
										<div style="margin-left: 10px">
											<h4 class="m-0">
												<span class="">
													<b>
														{{ jsonData.accettazioneAnagrafica.cognome }} {{ jsonData.accettazioneAnagrafica.nome }}
														<i>({{ jsonData.accettazioneAnagrafica.identificativo }})</i>
													</b>
												</span>
											</h4>
											<p class="text-muted" style="margin-bottom: 0.5rem !important">
												<i></i>
											</p>
											<p class="text-muted">
												<i>{{ jsonData.identificativo }}</i>
											</p>
											<p class="text-muted" style="margin-bottom: 0.5rem !important">
												<span>
													<b>Data di Nascita:</b>
													{{ formatDate(jsonData.accettazioneAnagrafica.dataNascita) }}
												</span>
												<span>
													<b>Età:</b>
													{{ calcoloEta(jsonData.accettazioneAnagrafica.dataNascita) }}
												</span>
											</p>
											<p class="text-muted" style="margin-bottom: 0.5rem !important">
												<span>
													<b>Struttura:</b>
													{{ jsonData.accettazioneStruttura.denominazione }}
												</span>
												<span>
													<b>Presidio:</b>
													{{ jsonData.accettazioneStruttura.presidio }}
												</span>
												<span>
													<b>Regime:</b>
													{{ jsonData.regime }}
												</span>
												<span>
													<b>Numero Cartella:</b>
													{{ jsonData.numero }}
												</span>
											</p>
										</div>
									</div>
								</b-col>
							</b-row>
						</b-col>
						<b-col cols="2" xs="2" sm="2" md="2" lg="2" xl="2" class="text-right">
							<!-- <b-button variant="purple btn-generic btn-notext sa-margin-right" size="sm" @click="onEdit"><b-icon icon="pencil"></b-icon></b-button> -->
							<b-button variant="purple btn-generic btn-notext sa-margin-right" size="sm" v-b-toggle.info-1><b-icon icon="info" scale="2"></b-icon></b-button>
						</b-col>
					</b-row>
				</div>
				<hr />

				<b-collapse id="info-1" role="tabpanel">
					<div class="row">
						<div class="col">
							<strong>{{ this.$i18n.t("patients.lblFiscalCode") }}</strong>
							<br />
							{{ jsonData.accettazioneAnagrafica.identificativo }}
						</div>
						<div class="col">
							<strong>{{ this.$i18n.t("patient.lblSex") }}</strong>
							<br />
							<i class="fas fa-venus rosa font-18"></i>
							{{ jsonData.accettazioneAnagrafica.sesso }}
						</div>
						<div class="col">
							<strong>
								<i class="fas fa-tint rosso"></i>
								{{ this.$i18n.t("patients.lblBloodGroup") }}
							</strong>
							<br />
							{{ this.$i18n.t(jsonData.accettazioneAnagrafica.gruppoSanguigno) }}
						</div>
					</div>
					<hr />
					<div class="row">
						<div class="col" v-if="jsonData.provinciaNascita !== 'EE'">
							<strong>{{ this.$i18n.t("patients.birthPlace") }}</strong>
							<br />
							{{ jsonData.accettazioneAnagrafica.comuneNascita }} ({{ jsonData.accettazioneAnagrafica.provinciaNascita }}) , {{ jsonData.accettazioneAnagrafica.regione }}
						</div>
						<div class="col" v-else>
							<strong>{{ this.$i18n.t("patient.lblResidence") }}</strong>
							<br />
							{{ jsonData.accettazioneAnagrafica.nazioneNascita }} ({{ jsonData.accettazioneAnagrafica.provinciaNascita }})
						</div>
						<div class="col">
							<strong>{{ this.$i18n.t("patients.lblBirthDate") }}</strong>
							<br />
							{{ formatDate(jsonData.accettazioneAnagrafica.dataNascita) }}
						</div>
						<div class="col" v-if="jsonData.provinciaResidenza !== 'EE'">
							<strong>{{ this.$i18n.t("patient.lblResidence") }}</strong>
							<br />
							{{ jsonData.accettazioneAnagrafica.indirizzoResidenza }} {{ jsonData.accettazioneAnagrafica.capResidenza }} {{ jsonData.accettazioneAnagrafica.comuneResidenza }} ({{ jsonData.accettazioneAnagrafica.provinciaResidenza }}) ,
							{{ jsonData.accettazioneAnagrafica.regioneResidenza }}
						</div>
						<div class="col" v-else>
							<strong>{{ this.$i18n.t("patient.lblResidence") }}</strong>
							<br />
							{{ jsonData.accettazioneAnagrafica.nazioneResidenza }} ({{ jsonData.accettazioneAnagrafica.provinciaResidenza }})
						</div>
					</div>
					<hr />
					<div class="row">
						<div class="col" v-if="jsonData.provinciaDomicilio !== 'EE'">
							<strong>{{ this.$i18n.t("patients.lblDomicile") }}</strong>
							<br />
							{{ jsonData.accettazioneAnagrafica.indirizzoDomicilio }} {{ jsonData.accettazioneAnagrafica.capDomicilio }} {{ jsonData.accettazioneAnagrafica.comuneDomicilio }} ({{ jsonData.accettazioneAnagrafica.provinciaDomicilio }}) ,
							{{ jsonData.accettazioneAnagrafica.regioneDomicilio }}
						</div>
						<div class="col" v-else>
							<strong>{{ this.$i18n.t("patient.lblResidence") }}</strong>
							<br />
							{{ jsonData.accettazioneAnagrafica.nazioneDomicilio }} ({{ jsonData.accettazioneAnagrafica.provinciaDomicilio }})
						</div>
						<div class="col">
							<strong>{{ this.$i18n.t("patient.lblPhone") }}</strong>
							<br />
							{{ jsonData.accettazioneAnagrafica.telefono }}
						</div>
						<div class="col">
							<strong>{{ this.$i18n.t("patients.lblEmail") }}</strong>
							<br />
							{{ jsonData.accettazioneAnagrafica.email }}
						</div>
					</div>
					<hr />
				</b-collapse>

				<div class="clearfix"></div>
			</div>
		</div>
	</b-card>
</template>
<script>
import moment from "moment";
import female from "@/assets/images/female.jpg";
import male from "@/assets/images/male.jpg";
import UtilityMixin from "../../../utility/UtilityMixin";
export default {
	mixins: [UtilityMixin],
	props: {
		jsonData: { type: Object },
	},
	data() {
		return {
			pagelink: "/paziente",
		};
	},
	computed: {
		idPaziente() {
			return this.$route.params.id;
		},
		patientAvatar: {
			get: function () {
				let me = this;
				if (me.jsonData.sesso === "M") {
					return male;
				} else {
					return female;
				}
			},
		},
	},
	mounted() {
		this.id = this.$route.params.id;
	},
	methods: {
		forceRerender() {
			let me = this;
			me.componentKey += 1;
		},
		getItemMenuHref(value) {
			let me = this;
			let href = "#/paziente" + value + "/?idPaziente=" + me.id;

			return href;
		},
		formatDate(value) {
			if (value) {
				return moment(new Date(value)).format("DD-MM-YYYY");
			} else {
				return "---";
			}
		},
		formatDateTime(value) {
			if (value) {
				return moment(new Date(value)).format("DD-MM-YYYY HH:mm");
			} else {
				return "---";
			}
		},
		onPazienteTeleconsulto: function () {
			let me = this;
			me.$router.replace("/paziente/teleconsulti/" + me.id).catch((err) => {
				err;
			});
		},
		onEdit: function () {
			let me = this;
			me.$router.replace(me.pagelink + "/edit/" + me.id).catch((err) => {
				err;
			});
		},
		onDashboard() {
			let me = this;
			me.$router.replace("paziente/dashboard?idPaziente=" + me.idPaziente).catch((err) => {
				err;
			});
		},
		onAllegati() {
			let me = this;
			me.$router.replace("/paziente/allegati/" + me.id).catch((err) => {
				err;
			});
		},
	},
};
</script>
