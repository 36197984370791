<template>
    <b-modal ref="mdlEditTipoPrestazione" id="mdlEditTipoPrestazione" :title="isNew ? 'Nuova Prestazione' : 'Aggiorna Prestazione'" size="lg" @hidden="onCloseModal" @ok="onClickOk" centered no-close-on-backdrop no-close-on-esc hide-header-close>
        <b-row>
            <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                <span class="sa-form-label">Nome</span>
                <b-form-input v-model="jsonDataTipoPrestazione.nome"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                <span class="sa-form-label">Codice Prestazione</span>
                <b-form-input v-model="jsonDataTipoPrestazione.codicePrestazione"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                <span class="sa-form-label">Regime</span>
                <!-- <b-form-input v-model="jsonDataTipoPrestazione.regime"></b-form-input> -->
                <b-form-select v-model="jsonDataTipoPrestazione.regime" :options="regimiOptions" :value="''" value-field="codice" text-field="descrizione"> </b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                <span class="sa-form-label">Importo</span>
                <b-form-input v-model="jsonDataTipoPrestazione.importo"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                <span class="sa-form-label">Quota ASL(%)</span>
                <!-- <b-form-select v-model="jsonDataTipoPrestazione.tipoQuota" :options="tipoQuotaOptions" :value="''" value-field="value" text-field="text"> </b-form-select> -->
                <b-form-input class="sa-form-label" v-model="jsonDataTipoPrestazione.percentualeAsl" type="number" step="0.01"> </b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                <span class="sa-form-label">Quota ASL(€)</span>
                <b-form-input v-model="jsonDataTipoPrestazione.quotaAsl"></b-form-input>
            </b-col>
            <!-- <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                <span class="sa-form-label">Quota Comune</span>
                <b-form-input v-model="jsonDataTipoPrestazione.quotaComune"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                <span class="sa-form-label">Quota Utente</span>
                <b-form-input v-model="jsonDataTipoPrestazione.quotaUtente"></b-form-input>
            </b-col> -->
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <span class="sa-form-label">Note</span>
                <b-form-textarea v-model="jsonDataTipoPrestazione.note" rows="7" max-rows="8"> </b-form-textarea>
            </b-col>
        </b-row>
    </b-modal>
</template>

<script>
import UtilityMixin from "../../../utility/UtilityMixin";

export default {
    name: "RsaConfigurazioneTipoPrestazioneEdit",
    mixins: [UtilityMixin],
    data() {
        return {
            isNew: true,
            pathResource: "/rsatipoprestazioni",
            pathResourceRegimi: "/rsatipoprestazioniregimi",
            /* tipoQuotaOptions: [
                { text: "-Seleziona Tipo Quota-", value: null },
                { text: "PERCENTUALE", value: "PERCENTUALE" },
                { text: "IMPORTO", value: "IMPORTO" },
            ], */
            regimiOptions: [{ codice: null, descrizione: "-Seleziona Regime-" }],
            jsonDataTipoPrestazione: {
                nome: null,
                importo: 0,
                percentualeAsl: null,
                quotaAsl: 0,
                quotaComune: 0,
                quotaUtente: 0,
                note: null,
                codicePrestazione: null,
                regime: null,
            },
        };
    },
    methods: {
        aggiornaTipoPrestazione(item) {
            let me = this;
            me.isNew = false;
            me.jsonDataTipoPrestazione = { ...item };
            me.loadTipoPrestazioneRegime();
            me.$refs.mdlEditTipoPrestazione.show();
        },

        nuovoTipoPrestazione() {
            let me = this;
            me.isNew = true;
            me.jsonDataTipoPrestazione = {
                nome: null,
                percentualeAsl: null,
                regime: "",
                importo: 0,
                quotaAsl: 0,
                quotaComune: 0,
                quotaUtente: 0,
                note: null,
            };
            me.loadTipoPrestazioneRegime();
            me.$refs.mdlEditTipoPrestazione.show();
        },
        loadTipoPrestazioneRegime() {
            let me = this;
            // me.UtilityMixin.methods.
            UtilityMixin.methods.loadDataResources(me.pathResourceRegimi, null, me.loadDataSuccess, me.loadDataError);
        },
        loadDataSuccess(response) {
            let me = this;
            me.regimiOptions = response.data.data.list;
            me.regimiOptions.unshift({ codice: "", descrizione: "-Seleziona Valore-" });
            // me.rows = response.data.data.length;
            // this.showModalLoading = true;
        },
        loadDataError(response) {
            console.log(response);
        },

        onCloseModal() {
            let me = this;
            me.jsonDataTipoPrestazione = {};
            me.$emit("onClose");
        },

        onClickOk(bvModalEvent) {
            bvModalEvent.preventDefault();
            UtilityMixin.methods.saveResource(this.pathResource, this.jsonDataTipoPrestazione, this.saveSuccessResponse, this.saveErrorResponse);
        },

        saveSuccessResponse(response) {
            let me = this;
            me.$emit("onSave", response);
            me.$refs.mdlEditTipoPrestazione.hide();
            me.$bvToast.toast("Salvataggio avvenuto con successo", {
                title: "Successo",
                variant: "success",
                solid: true,
            });
        },

        saveErrorResponse(response) {
            if (response.response.status === 400) {
                this.$bvModal.msgBoxOk(response.response.data.messaggio, {
                    title: "ATTENZIONE",
                    size: "sm",
                    okVariant: "outline-danger",
                    headerClass: "sa-msg-header-danger",
                    footerClass: "p-2 border-top-0",
                    centered: true,
                });
            } else {
                this.$bvToast.toast("Errore nel salvataggio" + response.message, {
                    title: "Errore",
                    variant: "danger",
                    solid: true,
                });
            }
        },
    },
};
</script>
