<template>
    <div style="height: 100%">
        <div class="sa-flexbox" style="padding-bottom: 20px">
            <div class="d-flex flex-column" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <!-- <div class="text-right mb-3">
                    <b-button size="sm" variant="outline-success" :hidden="btnNuovaSpecializzazionePaiHidden" @click="onClickNuovaSpecializzazionePai" class="mb-2"> <font-awesome-icon icon="plus"> </font-awesome-icon> Nuova Specializzazione </b-button>
                </div> -->

                <b-row class="sa-label-info">
                    <b-col lg="3">
                        <p>Record Totali : {{ rows }}</p>
                    </b-col>
                    <b-col lg="9">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"> </b-pagination>
                    </b-col>
                </b-row>
            </div>
            <div class="sa-flexbox-body">
                <div class="b-table-sticky-header">
                    <b-table sticky-header ref="table" stacked="lg" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="row">
                            <span> {{ row.index + 1 }}</span>
                        </template>

                        <template #cell(idRuolo)="item">
                            <span> {{ item.item.ruolo }}</span>
                        </template>

                        <template v-slot:cell(actions)="{ item }">
                            <b-container class="bv-example-row">
                                <b-button size="sm" v-b-tooltip.hover title="Modifica Specializzazione" variant="outline-success" @click="onClickEditSpecializzazionePai(item)">
                                    <font-awesome-icon icon="edit"></font-awesome-icon>
                                </b-button>

                                <b-button size="sm" v-b-tooltip.hover title="Elimina Specializzazione" variant="outline-danger" @click="onClickDeleteSpecializzazionePai(item)">
                                    <font-awesome-icon icon="trash"> </font-awesome-icon>
                                </b-button>
                            </b-container>
                        </template>
                    </b-table>
                </div>
            </div>

            <rsa-pai-specializzazione-edit-component ref="cmpRsaPaiSpecializzazioneEdit"></rsa-pai-specializzazione-edit-component>

            <b-row class="sa-label-info">
                <b-col lg="3">
                    <p>Record Totali: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import UtilityMixin from "../../../utility/UtilityMixin";
import RsaPaiSpecializzazioneEditComponent from "./RsaPaiSpecializzazioneEditComponent.vue";

export default {
    mixins: [UtilityMixin],
    components: { RsaPaiSpecializzazioneEditComponent },
    data() {
        return {
            rows: 0,
            perPage: 50,
            currentPage: 1,
            pathResourceSpecializzazionePai: "/rsapairuolospecializzazione",
            btnNuovaSpecializzazionePaiHidden: false,
            filtroSpecializzazionePai: {} /*  specializzazione: null, idRuolo: null */,
            listKey: 0,
            items: [],
            fields: [
                { key: "index", label: "N." },
                { key: "ruolo", label: "Ruolo" },
                { key: "specializzazione", label: "Specializzazione" },
                { key: "note", label: "Note" },
                { key: "actions", label: "", class: "sa-table-column-action-double" },
            ],
        };
    },
    watch: {
        currentPage() {
            this.loadData();
        },
    },
    mounted() {
        let me = this;
        /* me.loadRuoliPaiData(); */
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            me.$emit("beforeLoadData");
            let sessionStorage = window.sessionStorage;
            if (sessionStorage["filtroSpecializzazionePai"]) {
                me.filtroSpecializzazionePai = JSON.parse(sessionStorage["filtroSpecializzazionePai"]);
            }
            me.filtroSpecializzazionePai.page = me.currentPage;
            me.filtroSpecializzazionePai.forPage = me.perPage;
            UtilityMixin.methods.loadDataResources(me.pathResourceSpecializzazionePai, me.filtroSpecializzazionePai, me.loadSpecializzazionePaiDataSuccess, me.loadSpecializzazionePaiDataError);
        },

        loadSpecializzazionePaiDataSuccess(response) {
            let me = this;
            /* console.log("loadSpecializzazione", response.data.data.list);
            console.log("LOAD LIST", me.filtroSpecializzazionePai);
 */
            me.items = response.data.data.list;
            me.rows = response.data.data.recordsNumber;

            me.$emit("afterLoadData");
        },
        loadSpecializzazionePaiDataError(error) {
            let me = this;
            console.error("Errore durante il caricamento dei dati di loadSpecializzazionePaiDataError:", error);
            me.items = [];
            me.rows = 0;
            me.$emit("afterLoadData");
        },

        /* loadRuoliPaiData() {
            let me = this;
            me.cmpRsaPaiSpecializzazioneEdit.loadRuoliPaiData();
        },
 */

        onClickEditSpecializzazionePai(item) {
            let me = this;
            me.$refs.cmpRsaPaiSpecializzazioneEdit.editSpecializzazionePai(item);
        },
        onClickDeleteSpecializzazionePai(item) {
            let me = this;
            me.$refs.cmpRsaPaiSpecializzazioneEdit.onDeleteSpecializzazionePai(item);
        },
        /* onDelete() {
            let me = this;
            me.loadData();
        }, */
        /* onSaveEditSpecializzazionePai() {
            let me = this;
            me.loadData();
        }, */
    },
};
</script>
