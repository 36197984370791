<b-col cols="12" sm="12" md="4" lg="4" xl="4"></b-col>
<template>
    <b-modal title="Esecuzione Attivita" ref="mdlRsaAreaEsecuzioneEdit" id="mdlRsaAreaEsecuzioneEdit" modal-class="sa-modal-static-scroll" size="lg" @ok="onOk">
        <div class="sa-flexbox">
            <div class="sa-flexbox-header">
                <b-row>
                    <b-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Data Ora Esecuzione:</label>
                        <b-input-group class="mb-3">
                            <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataOraEffettuata" type="datetime"></date-picker>
                        </b-input-group>
                    </b-col>
                    <b-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Stato:</label>
                        <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.stato" class="mb-2 mr-sm-2 mb-sm-0" :options="statiOptions"></b-form-select>
                    </b-col>
                </b-row>
            </div>
            <div class="sa-flexbox-body">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <label class="sa-label-data">Indicazioni:</label>
                        <vue-editor class="sa-qeditor" :editor-toolbar="customToolbar" v-model="jsonData.indicazioni" disabled />
                    </b-col>
                </b-row>
            </div>
            <div class="sa-flexbox-footer">
                <div style="max-height: 320px; overflow: hidden">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Note:</label>
                            <b-form-textarea id="textarea-no-resize" v-model="jsonData.note" rows="3" no-resize></b-form-textarea>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
// import { VueEditor } from "vue2-editor";
export default {
    components: {
        DatePicker,
        // VueEditor,
    },
    data() {
        return {
            customToolbar: [
                ["bold", "italic", "underline", "strike"], // toggled buttons
                [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],

                [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
                [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            ],
            pathResource: "/rsaattivitapazienti/slot",
            jsonData: {},
            statiOptions: [
                { value: null, text: "-Seleziona Stato-" },
                { value: "ESEGUITA", text: "ESEGUITA" },
                { value: "NON ESEGUITA", text: "NON ESEGUITA" },
            ],
            perPage: 1000000,
        };
    },
    methods: {
        add(jsonData) {
            let me = this;
            me.jsonData = jsonData;
            me.$refs.mdlRsaAreaEsecuzioneEdit.show();
        },
        onChangeAttivita() {},
        onOk() {
            let me = this;
            me.eseguiAttivita();
        },
        eseguiAttivita() {
            let me = this;
            if (me.jsonData.rsaAttivitaPazienteBean === null) {
                // console.log("POST");

                me.creaAttivita();
            } else {
                // console.log("PUT");
                me.aggiornaAttivita();
            }
        },
        creaAttivita() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            axios
                .post(link, JSON.stringify(me.jsonData))
                .then(() => {
                    me.$emit("afterSaveRequest");
                })
                .catch((error) => {
                    me.$emit("afterSaveRequest");
                    let messaggio = error.response ? error.response.data.messaggio : "Errore non gestito";
                    me.$bvModal
                        .msgBoxOk(messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then(() => {})
                        .catch((err) => {
                            console.log(err);
                        });
                });
        },
        aggiornaAttivita() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.jsonData.rsaAttivitaPazienteBean.id;
            axios
                .put(link, JSON.stringify(me.jsonData))
                .then(() => {
                    me.$emit("afterSaveRequest");
                })
                .catch((error) => {
                    me.$emit("afterSaveRequest");
                    let messaggio = error.response ? error.response.data.messaggio : "Errore non gestito";
                    me.$bvModal
                        .msgBoxOk(messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then(() => {})
                        .catch((err) => {
                            console.log(err);
                        });
                });
        },
    },
};
</script>

<style>
.sa-flexbox-body .row {
    flex: 1;
    height: 1px;
}

.sa-flexbox-body .row .col-sm-12.col-md-12.col-lg-12.col-xl-12.col-12 {
    height: 100%;
}

.sa-qeditor {
    height: 100%;
    padding-bottom: 80px !important;
}

.sa-qeditor .ql-editor {
    height: 100%;
    min-height: auto !important;
    padding-bottom: 15px;
    /* min-height: 150px;
    max-height: 150px; */
}

.ql-editor {
    font-size: 1rem !important;
}
</style>
