<template>
    <sa-page-layout :btnBackVisible="true" :btnEditVisible="btnEditVisible" :linkback="linkback" :linkedit="linkedit" :pathResource="pathResource" :showModalLoading="showModalLoading">
        <template slot="table-body">
            <rsa-accettazione-view-component ref="cmpRsaAccettazioneViewComponent" @beforeLoadData="onBeforeloadData" @afterLoadData="onAfterLoadData"></rsa-accettazione-view-component>
        </template>
    </sa-page-layout>
</template>
<script>
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import RsaAccettazioneViewComponent from "../components/RsaAccettazioneViewComponent.vue";

export default {
    components: { SaPageLayout, RsaAccettazioneViewComponent },
    data() {
        return {
            btnNewVisible: true,
            pathResource: "",
            btnEditVisible: true,
            linkedit: null,
            linkback: "/rsaaccettazione",
            showModalLoading: false,
            listKey: 0,
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.linkedit = "/rsaaccettazione/edit/" + me.id;
        this.$refs.cmpRsaAccettazioneViewComponent.setId(me.id);
    },
    methods: {
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
        },
        onAfterLoadData() {
            let me = this;
            me.showModalLoading = false;
        },
        onBeforeloadData() {
            let me = this;
            me.showModalLoading = true;
        },
    },
};
</script>
<style></style>
