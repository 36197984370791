<template>
  <sa-page-layout :btnNewVisible="true" :btnRefreshVisible="true" @refresh="onRefresh" :pathResource="pathResource" :linkedit="linkedit" :showModalLoading="showModalLoading">
    <template slot="table-filter">
    </template>
    <template slot="table-body">
      <configurazione-medico-list-component ref="ConfigurazioneMedicoListComponent" @afterLoadData="onAfterLoadData" @beforeLoadData="onBeforeLoadData"></configurazione-medico-list-component>
    </template>
  </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import ConfigurazioneMedicoListComponent from "../components/ConfigurazioneMedicoListComponent.vue";
import UtilityMixin from "../../../utility/UtilityMixin";
import Vue from "vue";
export default {
  mixins: [UtilityMixin],
  components: { SaPageLayout, ConfigurazioneMedicoListComponent },
  data() {
    return {
      btnNewVisible: false,
      pathResource: "",
      linkedit: "/medici",
      showModalLoading: false,
      listKey: 0,
      filtro: {},
      valutazioniOption: [],
    };
  },
  mounted() {
    let me = this;
    me.impostaPermessi();
    me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;
      me.$refs.ConfigurazioneMedicoListComponent.loadData();
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
    onAfterLoadData() {
      let me = this;
      me.showModalLoading = false;
    },
    onBeforeLoadData() {
      let me = this;
      me.showModalLoading = true;
    },
    onSubmit() {
      let me = this;
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onReset() {
      let me = this;
      me.filtro = {};
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    impostaPermessi() {
      let me = this;
      me.btnNewVisible = UtilityMixin.methods.verificaPermessi(Vue.prototype.user, "giubileo", 2);
    },
  },
};
</script>

<style></style>
