<template>
  <sa-page-layout
    :btnBackVisible="true"
    :linkback="linkback"
    :btnNewVisible="btnNewVisible"
    :btnRefreshVisible="true"
    @refresh="onRefresh"
    :pathResource="pathResource"
    :linkedit="linkedit"
    :showModalLoading="showModalLoading"
    ><template slot="toolbar">
      <b-button
        class="float-sm-right"
        variant="success btn-toolbar sa-margin-right"
        size="sm"
        @click="emailModal()"
        ><!-- <i class="far fa-paper-plane"></i> -->
        <b-icon icon="plus"></b-icon>
        Nuova Email</b-button
      >
    </template>
    <template slot="table-body">
      <EmailComponentList
        ref="EmailComponentList"
        @afterLoadData="onAfterLoadData"
      ></EmailComponentList>
    </template>
  </sa-page-layout>
</template>
<script>
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import EmailComponentList from "../components/EmailListComponent.vue";
export default {
  components: { SaPageLayout, EmailComponentList },
  data() {
    return {
      pathResource: "/",
      linkedit: "/email",
      linkback: "/home",
      showModalLoading: false,
      listKey: 0,
      btnNewVisible: false,
    };
  },
  mounted() {
    let me = this;
    me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;
      me.$refs.EmailComponentList.loadData();
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
    onAfterLoadData() {
      let me = this;
      me.showModalLoading = false;
    },
    emailModal() {
      let me = this;
      me.$refs.EmailComponentList.emailModal();
    },
  },
};
</script>
