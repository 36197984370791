<template>
  <sa-footer>
    <strong>Copyright © 2018.</strong> All rights reserved.
    <div class="float-right d-none d-sm-inline-block">
      <strong>Version:</strong> {{ version }}
    </div>
  </sa-footer>
</template>

<script>

export default {
  data() {
    return {
      version: '1.0.0',
    };
  },
};
</script>