import LogsSpid from "../";

const routes = [
  {
    name: "Spid Logs",
    path: "/spidlogs",
    component: LogsSpid.LogsSpidListPage,
    meta: { title: "Spid Logs" },
  },
];
export default routes;
