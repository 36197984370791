<template>
    <div style="height: 100%">
        <div class="sa-flexbox" style="padding-bottom: 20px">
            <div class="d-flex flex-column">
                <div class="text-right mb-3">
                    <!-- <b-button size="sm" variant="outline-success outline-primary-noborder sa-margin-right float-sm-right mb-3" :hidden="btnNuovaStanzaHidden" @click="onClickNuovaStanza" class="mt-2">
            <b-icon icon="plus"> </b-icon>
            Nuova Stanza
          </b-button> -->
                </div>
                <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col lg="3">
                        <p>Record Totali: {{ rows }}</p>
                    </b-col>
                    <b-col lg="9">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                    </b-col>
                </b-row>
            </div>

            <div class="sa-flexbox-body">
                <div class="b-table-sticky-header">
                    <b-table sticky-header ref="table" stacked="lg" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="row">
                            <span>{{ row.index + 1 }}</span>
                        </template>

                        <template v-slot:cell(codiceStruttura)="{ item }">
                            <span>{{ item.denominazioneStruttura }} ({{ item.codiceStruttura }})</span>
                        </template>
                        <template v-slot:cell(nome)="{ item }">
                            <span> {{ item.nome }} </span>
                            <!-- <router-link class="sa-edit-link" :to="'/rsaconfigurazionepostilettostanze/edit/' + item.id">{{ item.nome }}</router-link> -->
                            <br />
                            <span>{{ item.nomePiano }}</span>
                        </template>
                        <template #cell(actions)="{ item }">
                            <b-button size="sm" class="mr-1 ml-1 no-text" variant="outline-secondary" @click="onClickModificaStanza(item)">
                                <i class="bi bi-pencil"></i>
                            </b-button>
                            <b-button size="sm" class="mr-1 ml-1 no-text" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDeleteStanza(item)">
                                <b-icon icon="trash"></b-icon>
                            </b-button>
                        </template>
                    </b-table>
                </div>
            </div>

            <ras-configurazione-posti-letto-stanze-edit-component ref="cmpConfigurazionePostiLettoStanze" :idPiano="idPiano" @onSave="onSaveStanza" @onClose="loadData" @onDelete="onDelete"> </ras-configurazione-posti-letto-stanze-edit-component>

            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>Record Totali: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>

            <!-- <b-modal ref="mdlStanzaEdit" size="lg" title="Modifica Dati Stanza" @ok="onMdlStanza()">
            <ras-configurazione-posti-letto-stanze-edit-component ref="cmpRasConfigurazionePostiLettoStanzeEditComponent"></ras-configurazione-posti-letto-stanze-edit-component>
            <template #modal-footer="{ ok, cancel }">
                <b-button size="sm" variant="danger" @click="cancel()">Annulla</b-button>
                <b-button size="sm" variant="success" @click="ok()">Salva</b-button>
            </template>
        </b-modal> -->
        </div>
    </div>
</template>

<script>
import axios from "axios";
import RasConfigurazionePostiLettoStanzeEditComponent from "../components/RasConfigurazionePostiLettoStanzeEditComponent.vue";
export default {
    components: { RasConfigurazionePostiLettoStanzeEditComponent },
    props: {
        idPiano: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            rows: 0,
            perPage: 50,
            currentPage: 1,
            pathResource: "/rsapostilettostanze",
            showModalLoading: false,
            btnNuovaStanzaHidden: false,
            filtro: {},
            items: [],
            listKey: 0,
            fields: [
                {
                    label: "",
                    key: "index",
                    thStyle: "width: 0.5rem",
                },
                {
                    label: "Struttra",
                    key: "codiceStruttura",
                },
                {
                    label: "Presidio",
                    key: "denominazionePresidio",
                },
                {
                    label: "Nome",
                    key: "nome",
                },
                {
                    label: "Sesso",
                    key: "sesso",
                },
                {
                    label: "Note",
                    key: "note",
                },
                {
                    label: "",
                    class: "sa-table-column-action-double",
                    key: "actions",
                },
            ],
        };
    },
    mounted() {
        let me = this;
        me.loadData();
        /* if (me.idPiano) {
            me.loadData();
        } */
    },

    watch: {
        currentPage() {
            let me = this;
            me.loadData();
        },
    },

    methods: {
        loadData() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            if (sessionStorage["filtro"]) {
                me.filtro = JSON.parse(sessionStorage["filtro"]);
            }
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.filtro.page = me.currentPage;
            me.filtro.forPage = me.perPage;
            if (me.idPiano) {
                me.filtro.idPiano = this.idPiano;
            }
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];

                    me.rows = response.data.data.recordsNumber;
                    me.items = response.data.data.list;

                    me.listKey++;
                    this.$emit("afterLoadData");
                })
                .catch(() => {
                    this.$emit("afterLoadData");
                });
        },
        onClickModificaStanza(item) {
            let me = this;
            me.$refs.cmpConfigurazionePostiLettoStanze.aggiornaStanza(item);

            //console.log(item);
            /* me.$router.push("/rsapostilettostanze/edit/" + item.id); */
            /* me.openModal(item); */
        },

        onDeleteStanza(item) {
            let me = this;
            me.$refs.cmpConfigurazionePostiLettoStanze.onDeleteStanza(item);
        },

        onDelete() {
            this.loadData();
        },

        /* openModal() {
            console.log("eccomi");
            let me = this;
            me.$refs.mdlStanzaEdit.show();
        
        },
        onMdlStanza() {}, */
        onClickNuovaStanza() {
            let me = this;
            me.$refs.cmpConfigurazionePostiLettoStanze.nuovaStanza();
        },
        onSaveStanza() {
            this.loadData();
        },
    },
};
</script>

<style></style>
