<template>
    <b-modal ref="mdlEditSetting" id="mdlEditSetting" :title="isNew ? 'Nuovo Setting' : 'Aggiorna Setting'" @hidden="onCloseModal" @ok="OnClickOk" centered no-close-on-backdrop no-close-on-esc hide-header-close>
        <b-row>
            <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <span class="sa-form-label">Setting</span>
                <b-form-input v-model="jsonDataSetting.setting"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <span class="sa-form-label">Codice</span>
                <b-form-input v-model="jsonDataSetting.codice"></b-form-input>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <span class="sa-form-label">Note</span>
                <b-form-input v-model="jsonDataSetting.note" placeholder="Inserire note"> </b-form-input>
            </b-col>
        </b-row>
    </b-modal>
</template>

<script>
import UtilityMixin from "../../../utility/UtilityMixin";

export default {
    mixins: [UtilityMixin],
    data() {
        return {
            isNew: true,
            pathResource: "/rsasettings",
            jsonDataSetting: {
                setting: null,
                codice: null,
                note: null,
            },
        };
    },
    methods: {
        aggiornaSetting(item) {
            this.isNew = false;
            this.jsonDataSetting = { ...item };
            this.$refs.mdlEditSetting.show();
        },

        nuovoSetting() {
            this.isNew = true;
            this.jsonDataSetting = {
                setting: null,
                codice: null,
                note: null,
            };
            this.$refs.mdlEditSetting.show();
        },

        onCloseModal() {
            this.jsonDataSetting = {
                setting: null,
                codice: null,
                note: null,
            };
            this.$emit("onClose");
        },

        OnClickOk(bvModalEvent) {
            bvModalEvent.preventDefault();
            UtilityMixin.methods.saveResource(this.pathResource, this.jsonDataSetting, this.saveSuccessResponse, this.saveErrorResponse);
        },

        saveSuccessResponse(response) {
            this.$emit("onSave", response);
            this.$refs.mdlEditSetting.hide();
            this.$bvToast.toast("Salvataggio avvenuto con successo", {
                title: "Successo",
                variant: "success",
                solid: true,
            });
        },

        saveErrorResponse(response) {
            if (response.response.status === 400) {
                this.$bvModal.msgBoxOk(response.response.data.messaggio, {
                    title: "ATTENZIONE",
                    size: "sm",
                    okVariant: "outline-danger",
                    headerClass: "sa-msg-header-danger",
                    footerClass: "p-2 border-top-0",
                    centered: true,
                });
            } else {
                this.$bvToast.toast("Errore nel salvataggio" + response.message, {
                    title: "Errore",
                    variant: "danger",
                    solid: true,
                });
            }
        },
    },
};
</script>
