<template>
	<dynamic-form :jsonData="jsonData"></dynamic-form>
</template>

<script>
import DynamicForm from "./DynamicForm.vue";
export default {
	components: { DynamicForm },
	data() {
		return {
			jsonData: [
				{ label: "Nome", type: "input", modelName: "nome" },
				{ label: "Cognome", type: "input", modelName: "nome" },
			],
		};
	},
};
</script>

<style></style>
