<template>
    <b-modal :title="titolo" ref="mdlCambiaStatoIndagine" id="mdlCambiaStatoIndagine" size="lg">
        <b-row>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <label class="sa-label-data">Stato</label>
                <span class="sa-data">{{ statoSegnalazione }}</span>
            </b-col>
            <label class="sa-label-data">Note</label>
            <b-form-textarea v-model="jsonDataStato.note" no-resize rows="8"></b-form-textarea>
        </b-row>
        <template #modal-footer>
            <b-button @click="onSalvaRichiesta" variant="outline-success">Salva</b-button>
            <b-button size="md" class="no-text" variant="outline-danger" @click="onClickUndo">Annulla</b-button>
        </template>
    </b-modal>
</template>

<script>
import axios from "axios";
// import DatePicker from "vue2-datepicker";
export default {
    components: {
        // DatePicker,
    },
    data() {
        return {
            titolo: "",
            statoSegnalazione: null,
            pathResource: "/malattieinfettiveiestatistorico",
            jsonDataIndagine: {},
            jsonDataStato: {
                idIndagineEpidemiologica: null,
                note: null,
                stato: null,
            },
        };
    },
    methods: {
        setStato(value) {
            let me = this;
            switch (value) {
                case "ARCHIVIATA":
                    me.titolo = "Archivia Indagine";
                    break;
            }
            me.statoSegnalazione = value;
        },
        setJsonIndagine(item) {
            let me = this;
            me.jsonDataIndagine = item;
        },
        show() {
            let me = this;
            me.$refs.mdlCambiaStatoIndagine.show();
        },
        close() {
            let me = this;
            me.$refs.mdlCambiaStatoIndagine.hide();
        },
        onSalvaRichiesta() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.jsonDataStato.idIndagineEpidemiologica = me.jsonDataIndagine.id;
            me.jsonDataStato.stato = me.statoSegnalazione;
            if (me.jsonDataStato.stato === "ARCHIVIATA" && (me.jsonDataStato.note === null || me.jsonDataStato.note === "")) {
                me.$bvModal
                    .msgBoxOk("Il Campo Note non può essere vuoto", {
                        title: "ATTENZIONE",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    })
                    .then(() => {})
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                me.$emit("beforeSaveRequest");
                axios
                    .post(link, JSON.stringify(me.jsonDataStato))
                    .then(() => {
                        me.filtro = {};
                        me.$emit("afterSaveRequest");
                        me.$refs.mdlCambiaStatoIndagine.hide();
                    })
                    .catch((error) => {
                        me.$emit("afterSaveRequest");
                        let messaggio = error.response ? error.response.data.messaggio : "Errore non gestito";
                        me.$bvModal
                            .msgBoxOk(messaggio, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then(() => {})
                            .catch((err) => {
                                console.log(err);
                            });
                    });
            }
        },
        onClickUndo() {
            let me = this;
            me.$refs.mdlCambiaStatoIndagine.hide();
        },
    },
};
</script>

<style></style>
