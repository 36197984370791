<template>
    <div style="height: 100%">
        <b-tabs id="tabsRsaAccettazioneEdit" ref="tabsRsaAccettazioneEdit" v-model="activeTabIndex" nav-wrapper-class="sa-tab-items" content-class="mt-3" class="sa-tab" style="height: 100%; padding-bottom: 25px" fill @input="onInputTabs">
            <b-tab title="Anagrafica">
                <div class="sa-tab-scroll">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-card class="sa-card" header="Struttura - Presidio" header-tag="header" footer-tag="footer" title="">
                                <b-row>
                                    <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                        <label>Struttura</label>
                                        <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.codiceStruttura" class="mb-2 mr-sm-2 mb-sm-0" :value="null" value-field="codiceStruttura" text-field="denominazione" :options="strutturaOptions" @change="onChangeStruttura"></b-form-select>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                        <label>Presidi</label>
                                        <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.idPresidio" class="mb-2 mr-sm-2 mb-sm-0" :value="null" value-field="id" text-field="denominazione" :options="presidiOptions" @change="onChangePresidio"></b-form-select>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                        <label>Regime</label>
                                        <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.regime" class="mb-2 mr-sm-2 mb-sm-0" :value="''" value-field="codice" text-field="descrizione" :options="regimiOptions"></b-form-select>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                        <label>Settings</label>
                                        <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.settingCodice" class="mb-2 mr-sm-2 mb-sm-0" :value="''" value-field="codice" text-field="setting" :options="settingsPresidiOptions" @change="onChangeSettingCodice"></b-form-select>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-card class="sa-card" header="Anagrafica" header-tag="header" footer-tag="footer" title="">
                                <b-row>
                                    <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                        <label class="sa-label-data">Tipo Identificativo</label>
                                        <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.accettazioneAnagrafica.tipoIdentificativo" class="mb-2 mr-sm-2 mb-sm-0" :options="tipiIdentificativoOptions" :value="null" value-field="codice" text-field="descrizione" required disabled></b-form-select>
                                    </b-col>
                                    <b-col v-if="anagraficaRicerca" cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                        <label class="sa-label-data">Codice Fiscale/STP/ENI*</label>
                                        <b-input-group class="mb-3">
                                            <b-form-input maxlength="16" class="text-uppercase" v-model="jsonData.accettazioneAnagrafica.identificativo" @keypress="onKeyPress" :readonly="isCheckIdentificativo"></b-form-input>
                                            <b-button size="sm" variant="" :disabled="isCheckIdentificativo" @click="onSearchAnagraficaClick"><b-icon icon="search"></b-icon></b-button>
                                        </b-input-group>
                                    </b-col>
                                    <b-col v-else cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                        <label class="sa-label-data">
                                            {{ this.$i18n.t("patients.lblFiscalCode") }}
                                            <span style="color: red">*</span>
                                        </label>
                                        <b-input-group class="mb-3">
                                            <!-- <b-form-input maxlength="16" class="text-uppercase" v-model="data.codiceFiscale" @keypress="onKeyPress"></b-form-input> -->
                                            <b-form-input maxlength="16" class="text-uppercase" v-model="jsonData.accettazioneAnagrafica.identificativo" @input="onValidazioneIdentificativo(jsonData.accettazioneAnagrafica.identificativo)"></b-form-input>
                                            <!-- <b-button size="sm" variant="" @click="onClick"> <b-icon icon="search"></b-icon> </b-button> -->
                                        </b-input-group>
                                    </b-col>
                                    <!-- <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                        <label htmlFor="rsaGestioneOspitiEditComponentAccettazioneAnagraficaIdentificativo" class="sa-label-data">Codice Fiscale/STP/ENI*</label>
                                        <b-form-input id="rsaGestioneOspitiEditComponentOspiteIdentificativo" v-model="jsonData.accettazioneAnagrafica.identificativo"></b-form-input>
                                    </b-col> -->
                                    <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                        <label class="sa-label-data">Cognome</label>
                                        <b-form-input v-model="jsonData.accettazioneAnagrafica.cognome"></b-form-input>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                        <label class="sa-label-data">Nome</label>
                                        <b-form-input v-model="jsonData.accettazioneAnagrafica.nome"></b-form-input>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                        <label class="sa-label-data">Data di nascita</label>
                                        <date-picker format="DD/MM/YYYY" value-type="timestamp" v-model="jsonData.accettazioneAnagrafica.dataNascita" type="date"></date-picker>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                        <label class="sa-label-data">Provincia di nascita</label>
                                        <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.accettazioneAnagrafica.provinciaNascita" class="mb-2 mr-sm-2 mb-sm-0" :options="provinceNascitaOptions" :value="null" value-field="sigla" text-field="provincia" @change="onChangeProvinciaNascita" required></b-form-select>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                        <label class="sa-label-data">Comune di nascita</label>
                                        <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.accettazioneAnagrafica.comuneNascitaCodiceIstat" class="mb-2 mr-sm-2 mb-sm-0" :value="null" value-field="codiceIstat" text-field="comune" :options="comuniNascitaOptions" @change="onChangeComuneNascita" required></b-form-select>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                        <label class="sa-label-data">Sesso</label>
                                        <b-form-select v-model="jsonData.accettazioneAnagrafica.sesso" :options="sessoOptions"></b-form-select>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <b-card class="sa-card" header="Residenza" header-tag="header" footer-tag="footer" title="">
                                <b-row>
                                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                        <label class="sa-label-data">Regione</label>
                                        <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.accettazioneAnagrafica.regioneResidenza" class="mb-2 mr-sm-2 mb-sm-0" :value="null" value-field="codiceIstat" text-field="regione" :options="regioniResidenzaOptions" @change="onChangeRegioneResidenza" required></b-form-select>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                        <label class="sa-label-data">Provincia</label>
                                        <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.accettazioneAnagrafica.provinciaResidenza" class="mb-2 mr-sm-2 mb-sm-0" :value="null" value-field="sigla" text-field="provincia" :options="provinceResidenzaOptions" @change="onChangeProvinciaResidenza" required></b-form-select>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                        <label class="sa-label-data">Comune</label>
                                        <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.accettazioneAnagrafica.comuneResidenzaCodiceIstat" class="mb-2 mr-sm-2 mb-sm-0" :value="null" value-field="codiceIstat" text-field="comune" :options="comuniResidenzaOptions" @change="onChangeComuneResidenza" required></b-form-select>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                        <label class="sa-label-data">CAP</label>
                                        <b-form-input v-model="jsonData.accettazioneAnagrafica.capResidenza" @input="onInputCapResidenza"></b-form-input>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                        <label class="sa-label-data">Indirizzo</label>
                                        <b-form-input v-model="jsonData.accettazioneAnagrafica.indirizzoResidenza" @input="onInputIndirizzoResidenza"></b-form-input>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <b-card class="sa-card" header="Domicilio" header-tag="header" footer-tag="footer" title="">
                                <template #header>
                                    <div style="display: flex">
                                        <span style="flex: 1">Domicilio</span>
                                        <b-form-checkbox v-model="isDomicilioUgualeResidenza" name="check-button" switch>Coincide con residenza</b-form-checkbox>
                                    </div>
                                </template>
                                <b-row>
                                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                        <label class="sa-label-data">Regione</label>
                                        <b-form-select id="inline-form-custom-select-pref" class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.accettazioneAnagrafica.regioneDomicilio" :value="null" value-field="codiceIstat" text-field="regione" :options="regioniDomicilioOptions" @input="onInputRegioniDomicilio" required :disabled="isDomicilioUgualeResidenza"></b-form-select>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                        <label class="sa-label-data">Provincia</label>

                                        <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.accettazioneAnagrafica.provinciaDomicilio" class="mb-2 mr-sm-2 mb-sm-0" :options="provinceDomicilioOptions" :value="null" value-field="sigla" text-field="provincia" @input="onInputProvinciaDomicilio" required :disabled="isDomicilioUgualeResidenza"></b-form-select>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                        <label class="sa-label-data">Comune</label>
                                        <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.accettazioneAnagrafica.comuneDomicilioCodiceIstat" class="mb-2 mr-sm-2 mb-sm-0" :value="null" value-field="codiceIstat" text-field="comune" :options="comuniDomicilioOptions" @input="onInputComuneDomicilio" required :disabled="isDomicilioUgualeResidenza"></b-form-select>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                        <label class="sa-label-data">CAP</label>
                                        <b-form-input v-model="jsonData.accettazioneAnagrafica.capDomicilio" :disabled="isDomicilioUgualeResidenza"></b-form-input>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                        <label class="sa-label-data">Indirizzo</label>
                                        <b-form-input v-model="jsonData.accettazioneAnagrafica.indirizzoDomicilio" :disabled="isDomicilioUgualeResidenza"></b-form-input>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <b-card class="sa-card" header="ASL di residenza" header-tag="header" footer-tag="footer" title="">
                                <b-row>
                                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                        <label class="sa-label-data">Codice A.S.L.</label>
                                        <b-form-input v-model="jsonData.accettazioniAsl.aslResidenzaCodice"></b-form-input>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                        <label class="sa-label-data">A.S.L.</label>
                                        <b-form-input v-model="jsonData.accettazioniAsl.aslResidenzaDenominazione"></b-form-input>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                        <label class="sa-label-data">Ambito</label>
                                        <b-form-input v-model="jsonData.accettazioniAsl.aslResidenzaAmbito"></b-form-input>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                        <label class="sa-label-data">Distretto</label>
                                        <b-form-input v-model="jsonData.accettazioniAsl.aslResidenzaDistretto"></b-form-input>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <b-card class="sa-card" header="ASL di domicilio" header-tag="header" footer-tag="footer" title="">
                                <b-row>
                                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                        <label class="sa-label-data">Codice A.S.L.</label>
                                        <b-form-input v-model="jsonData.accettazioniAsl.aslDomicilioCodice"></b-form-input>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                        <label class="sa-label-data">A.S.L.</label>
                                        <b-form-input v-model="jsonData.accettazioniAsl.aslDomicilioDenominazione"></b-form-input>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                        <label class="sa-label-data">Ambito</label>
                                        <b-form-input v-model="jsonData.accettazioniAsl.aslDomicilioAmbito"></b-form-input>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                        <label class="sa-label-data">Distretto</label>
                                        <b-form-input v-model="jsonData.accettazioniAsl.aslDomicilioDistretto"></b-form-input>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-card class="sa-card" header="Altre info" header-tag="header" footer-tag="footer" title="">
                                <b-row>
                                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                        <label class="sa-label-data">Numero di Telefono</label>
                                        <b-form-input v-model="jsonData.accettazioneAnagrafica.telefono"></b-form-input>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                        <label class="sa-label-data">E-Mail</label>
                                        <b-form-input v-model="jsonData.accettazioneAnagrafica.email"></b-form-input>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                        <label class="sa-label-data">Medico Curante</label>
                                        <b-form-input v-model="jsonData.accettazioneAnagrafica.medicoCurante"></b-form-input>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                        <label class="sa-label-data">Medico Curante Telefono</label>
                                        <b-form-input v-model="jsonData.accettazioneAnagrafica.medicoCuranteTelefono"></b-form-input>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                        <label class="sa-label-data">Medico Curante E-Mail</label>
                                        <b-form-input v-model="jsonData.accettazioneAnagrafica.emailMedicoCurante"></b-form-input>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-card class="sa-card" header="Diagnosi e Note" header-tag="header" footer-tag="footer" title="">
                                <b-row>
                                    <!-- <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Diagnosi (ICD-9)</label>
                    <diagnosi-icdIx-component></diagnosi-icdIx-component>
                  </b-col> -->
                                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                        <label class="sa-label-data">Diagnosi in Ingresso</label>
                                        <b-form-textarea v-model="jsonData.diagnosiIngresso" placeholder="" rows="7"></b-form-textarea>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                        <label class="sa-label-data">Note</label>
                                        <b-form-textarea v-model="jsonData.accettazioneAnagrafica.note" id="textarea-auto-height" placeholder="" rows="7"></b-form-textarea>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-col>
                    </b-row>
                </div>
            </b-tab>
            <b-tab id="tbRsaRicette" title="Ricette" v-if="tbRsaRicette" :disabled="isTabDisabled">
                <rsa-accettazione-ricette-list-component ref="RsaAccettazioneRicetteListComponent" :idAccettazione="id" :jsonData="jsonData"></rsa-accettazione-ricette-list-component>
            </b-tab>
            <b-tab id="tbRsaImpegnativa" title="Impegnativa" v-if="tbRsaImpegnativa" :disabled="isTabDisabled">
                <rsa-accettazione-impegnativa-list-component ref="RsaAccettazioneImpegnativaListComponent" :idAccettazione="id" :jsonData="jsonData"></rsa-accettazione-impegnativa-list-component>
            </b-tab>
            <b-tab id="tbRsaPersoneRiferimento" title="Persona di Riferimento" :disabled="isTabDisabled">
                <rsa-persona-riferimento-component ref="RsaPersonaRiferimentoComponent" :jsonData="jsonData"></rsa-persona-riferimento-component>
                <!-- <rsa-persona-riferimento-component ref="RsaPersonaRiferimentoComponent" @updateListaFamiliari="onUpdateListaFamiliari"></rsa-persona-riferimento-component> -->
            </b-tab>
            <b-tab id="tbRsaEsenzioni" title="Esenzioni" v-if="tbRsaEsenzioni" :disabled="isTabDisabled">
                <!-- <rsa-esenzioni-component @onModalClose="onModalClose"></rsa-esenzioni-component> -->
            </b-tab>
            <b-tab id="tbValutazioneIngresso" title="Valutazione All'Ingresso" v-if="tbValutazioneIngresso" :disabled="isTabDisabled">
                <!--  <rsa-accettazione-schede-valutazione-component ref="RsaAccettazioneSchedeValutazioneComponent"></rsa-accettazione-schede-valutazione-component>  -->
                <rsa-accettazione-schede-valutazione-ingresso-component ref="RsaAccettazioneSchedeValutazioneIngressoComponent"></rsa-accettazione-schede-valutazione-ingresso-component>
            </b-tab>
            <b-tab id="tbSchedeValutazione" title="Scale Valutazione" :disabled="isTabDisabled">
                <rsa-accettazione-schede-valutazione-component ref="RsaAccettazioneSchedeValutazioneComponent"></rsa-accettazione-schede-valutazione-component>
            </b-tab>

            <b-tab id="tbSpesaExtra" title="Spese Extra" :disabled="isTabDisabled">
                <rsa-spesa-extra-list-component :idAccettazione="id" ref="cmpRsaSpesaExtraListComponent"></rsa-spesa-extra-list-component>
            </b-tab>
            <b-tab id="tbDatiFatturazione" title="Dati di Fatturazione" v-if="tbDatiFatturazione" :disabled="isTabDisabled">
                <rsa-accettazione-dati-fatturazione-list-component ref="cmpRsaAccettazioneDatiFatturazioneComponent"></rsa-accettazione-dati-fatturazione-list-component>
            </b-tab>
            <b-tab id="tbConsensi" title="Consensi" :disabled="isTabDisabled">
                <rsa-consensi-list-component ref="cmpRsaConsensiList"></rsa-consensi-list-component>
            </b-tab>
        </b-tabs>
    </div>
</template>
<script>
import axios from "axios";
import "vue2-datepicker/index.css";
import DatePicker from "vue2-datepicker";
import RsaPersonaRiferimentoComponent from "../../components/RsaPersonaRiferimentoComponent.vue";
// import DiagnosiIcdIxComponent from "../../../utility/components/DiagnosiIcdIxComponent.vue";
import RsaAccettazioneSchedeValutazioneComponent from "./RsaAccettazioneSchedeValutazioneComponent.vue";
import RsaAccettazioneRicetteListComponent from "./RsaAccettazioneRicetteListComponent.vue";
//import RsaAccettazioneDatiFatturazioneComponent from "./RsaAccettazioneDatiFatturazioneComponent.vue";
import RsaAccettazioneImpegnativaListComponent from "./RsaAccettazioneImpegnativaListComponent.vue";
import RsaAccettazioneDatiFatturazioneListComponent from "./RsaAccettazioneDatiFatturazioneListComponent.vue";
import RsaAccettazioneSchedeValutazioneIngressoComponent from "./RsaAccettazioneSchedeValutazioneIngressoComponent.vue";
//import RsaAccettazioneValutazioneIngressoEditComponent from "../../components/RsaAccettazioneValutazioneIngressoEditComponent.vue";
// import UtilityMixin from "../../../utility/UtilityMixin";
//import RsaValutazioneIngressoComponent from "../../components/RsaValutazioneIngressoComponent.vue";
// import RsaEsenzioniComponent from "../../components/RsaEsenzioniComponent.vue";
import RsaSpesaExtraListComponent from "./RsaSpesaExtraListComponent.vue";
import moment from "moment";

import UtilityMixin from "../../../utility/UtilityMixin";
import RsaConsensiListComponent from "../../components/RsaConsensiListComponent.vue";
export default {
    props: {
        id: { type: String },
    },
    components: {
        DatePicker,
        RsaSpesaExtraListComponent,
        // RsaValutazioneIngressoComponent,
        RsaPersonaRiferimentoComponent,
        // RsaEsenzioniComponent,
        // DiagnosiIcdIxComponent,
        // RsaAccettazioneValutazioneIngressoEditComponent,
        RsaAccettazioneSchedeValutazioneComponent,
        RsaAccettazioneRicetteListComponent,
        // RsaAccettazioneDatiFatturazioneComponent,
        RsaAccettazioneImpegnativaListComponent,
        RsaAccettazioneDatiFatturazioneListComponent,
        RsaAccettazioneSchedeValutazioneIngressoComponent,
        RsaConsensiListComponent,
    },
    data() {
        return {
            pathResource: "/rsaaccettazioni",
            pathResourceRegimi: "/accettazioneregimi",
            pathResourceStrutture: "/strutture",
            pathResourcePresidi: "/strutturepresidi",
            pathResourceProvince: "/province",
            pathResourceComuni: "/comuni/provincia",
            pathResourceRegioni: "/regioni",
            pathResourceComune: "/comuni/istat",
            pathResourceAsl: "/asl/comune",
            pathResourceIdentificativo: "/anagraficaidentificativo",
            pathResourceSettingsPresidi: "/rsasettingspresidi",
            anagraficaRicerca: false,
            isDomicilioUgualeResidenza: false,
            isCheckIdentificativo: false,
            filtro: { regione: null },
            strutturaOptions: [{ codiceStruttura: null, denominazione: "- Seleziona Struttura -" }],
            presidiOptions: [{ id: null, denominazione: "-Seleziona Presidio-" }],
            regimiOptions: [],
            regioniResidenzaOptions: [],
            regioniDomicilioOptions: [],
            provinceNascitaOptions: [],
            provinceResidenzaOptions: [{ codice: null, provincia: "-Seleziona Provincia-" }],
            provinceDomicilioOptions: [{ codice: null, provincia: "-Seleziona Provincia-" }],
            comuniResidenzaOptions: [{ codiceIstat: null, comune: "-Seleziona Comune-" }],
            comuniDomicilioOptions: [{ codiceIstat: null, comune: "-Seleziona Comune-" }],
            comuniNascitaOptions: [{ codiceIstat: null, comune: "-Seleziona Comune-" }],
            tipiIdentificativoOptions: [{ codice: null, descrizione: "-Seleziona Tipo Identificativo-" }],
            settingsPresidiOptions: [{ codice: null, setting: "-Seleziona Setting-" }],
            activeTabIndex: 0,
            isTabDisabled: null,
            tbDatiFatturazione: false,
            tbRsaRicette: false,
            tbRsaImpegnativa: true,
            tbRsaEsenzioni: false,
            tbValutazioneIngresso: true,
            // settings: null,
            jsonData: {
                codiceStruttura: null, //non a video
                idPresidio: null, //non a video
                numero: null, //non a video
                note: null, //non a video
                regime: null, //non a video
                settingCodice: null,
                settingDescrizione: "",
                idPrenotazione: null, //non a video
                accettazioneStruttura: {}, //non a video
                accettazioneAnagrafica: {
                    idAccettazione: null, //non a video
                    tipo: null, //non a video
                    identificativo: null,
                    tipoIdentificativo: "CF", //non a video
                    cognome: null,
                    nome: null,
                    dataNascita: null,
                    provinciaNascita: null,
                    comuneNascitaCodiceIstat: null,
                    sesso: null,
                    regioneResidenza: null,
                    provinciaResidenza: null,
                    comuneResidenzaCodiceIstat: null,
                    indirizzoResidenza: null,
                    capResidenza: null,
                    regioneDomicilio: null,
                    provinciaDomicilio: null,
                    comuneDomicilioCodiceIstat: null,
                    indirizzoDomicilio: null,
                    capDomicilio: null,
                    note: null,
                    medicoCurante: null,
                    codiceFiscaleMedicoCurante: null, //non a video
                    telefono: null,
                    email: null,
                    emailMedicoCurante: null,
                },
                accettazioneStati: [],
                accettazionePrestazioni: [],
                accettazioniAsl: {},
            },
            sessoOptions: [
                { value: null, text: "-Selezionare Sesso-" },
                { value: "M", text: "Maschio" },
                { value: "F", text: "Femmina" },
            ],
            invadilitaOptions: [
                { value: null, text: "SI / NO" },
                { value: "SI", text: "Si" },
                { value: "NO", text: "No" },
            ],
        };
    },
    computed: {
        // isTabDisabled() {
        //   let me = this;
        //   console.log(me.id);
        //   return me.id === "-1";
        // },
    },
    mounted: function () {
        let me = this;
        me.anagraficaRicerca = this.appConfig.anagraficaRicerca;
        me.loadStrutture();
        me.loadRegimi();
        me.loadProvinceNascita();
        me.loadRegioni();
        me.loadIdentificativi();
        // me.loadSettingsPresidi();
        // me.loadProvince();
    },
    watch: {
        isDomicilioUgualeResidenza() {
            let me = this;
            me.setDomicilio();
        },
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
        id() {
            let me = this;
            me.loadData();
        },
    },
    methods: {
        setDomicilio() {
            let me = this;
            if (me.isDomicilioUgualeResidenza) {
                me.jsonData.accettazioneAnagrafica.regioneDomicilio = me.jsonData.accettazioneAnagrafica.regioneResidenza;
                me.jsonData.accettazioneAnagrafica.provinciaDomicilio = me.jsonData.accettazioneAnagrafica.provinciaResidenza;
                me.jsonData.accettazioneAnagrafica.comuneDomicilioCodiceIstat = me.jsonData.accettazioneAnagrafica.comuneResidenzaCodiceIstat;
                me.jsonData.accettazioneAnagrafica.capDomicilio = me.jsonData.accettazioneAnagrafica.capResidenza;
                me.jsonData.accettazioneAnagrafica.indirizzoDomicilio = me.jsonData.accettazioneAnagrafica.indirizzoResidenza;
            }
        },

        loadData() {
            let me = this;
            me.$emit("beforeLoadData");
            /* console.log(me.id === "-1"); */

            if (me.id === "-1") {
                me.$emit("afterLoadData");
                me.isTabDisabled = true;
            } else {
                me.isCheckIdentificativo = true;
                me.showModalLoading = true;
                me.isTabDisabled = false;
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                axios
                    .get(link + me.id)
                    .then((response) => {
                        me.jsonData = response.data.data;
                        me.setPresidi();
                        me.loadSettingsPresidi(me.jsonData.idPresidio);
                        me.loadComuni(me.jsonData.accettazioneAnagrafica.provinciaNascita, "nascita");
                        me.loadProvince(me.jsonData.accettazioneAnagrafica.regioneResidenza, "residenza");
                        me.loadProvince(me.jsonData.accettazioneAnagrafica.regioneDomicilio, "domicilio");
                        me.loadComuni(me.jsonData.accettazioneAnagrafica.provinciaDomicilio, "residenza");
                        //me.setRegioni();
                        // if (me.jsonData.accettazioneAnagrafica.capResidenza === me.jsonData.accettazioneAnagrafica.capDomicilio) {
                        //   me.isDomicilioUgualeResidenza = true;
                        // }
                        me.$emit("afterLoadData");
                    })
                    .catch(() => {});
            }
        },
        loadStrutture() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceStrutture;
            axios
                .get(link)
                .then((response) => {
                    me.rows = response.data.data.recordsNumber;
                    me.strutturaOptions = response.data.data;
                    if (me.strutturaOptions.length === 1) {
                        me.jsonData.codiceStruttura = me.strutturaOptions[0];
                        me.loadPresidi(me.strutturaOptions[0].id);
                    } else {
                        me.strutturaOptions.unshift({
                            codiceStruttura: null,
                            denominazione: "-Seleziona Struttura-",
                        });
                    }
                    this.$emit("afterLoadData");
                })
                .catch(() => {
                    this.$emit("afterLoadData");
                });
        },
        loadPresidi(value) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourcePresidi;
            me.presidiOptions = [];
            me.filtroPresidi = { idStruttura: value };
            axios
                .get(link, { params: me.filtroPresidi })
                .then((response) => {
                    me.presidiOptions = response.data.data.list;
                    if (me.presidiOptions.length === 1) {
                        me.jsonData.idPresidio = me.presidiOptions[0].id;
                        me.loadSettingsPresidi(me.jsonData.idPresidio);
                    } else {
                        //  me.jsonData.idPresidio = null;
                        me.presidiOptions.unshift({
                            id: null,
                            denominazione: "-Seleziona Presidio-",
                        });
                    }
                    this.$emit("afterLoadData");
                })
                .catch(() => {
                    this.$emit("afterLoadData");
                });
        },
        loadSettingsPresidi(id) {
            let me = this;
            let params = { idPresidio: id };
            UtilityMixin.methods.loadDataResources(me.pathResourceSettingsPresidi, params, me.resultResponseSettings, me.resultErrorSettings);
        },
        resultResponseSettings(response) {
            let me = this;
            me.settingsPresidiOptions = response.data.data.list;
            if (me.settingsPresidiOptions.length === 1) {
                me.jsonData.settingCodice = me.settingsPresidiOptions[0].codice;
                me.jsonData.settingDescrizione = me.settingsPresidiOptions[0].setting;
            } else {
                me.settingsPresidiOptions.unshift({ codice: null, setting: "-Seleziona Setting-" });
            }
        },
        resultErrorSettings(response) {
            UtilityMixin.methods.messaggeBoxError(this, response);
        },
        loadRegimi() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceRegimi;
            axios
                .get(link)
                .then((response) => {
                    me.regimiOptions = response.data.data.list;
                    me.regimiOptions.unshift({ codice: null, descrizione: "-Seleziona Regime-" });
                })
                .catch((error) => {
                    console.log("Il servizio delle regioni ha datao errore: " + error.response.status);
                });
        },
        loadProvinceNascita() {
            let me = this;
            me.provinciaOption = [];
            let link = process.env.VUE_APP_PATH_API + me.pathResourceProvince;
            axios
                .get(link)
                .then((response) => {
                    me.provinceNascitaOptions = response.data.data;
                    me.provinceNascitaOptions.unshift({ sigla: null, provincia: "-Seleziona Provincia-" });
                })
                .catch((error) => {
                    console.log("Il servizio delle province ha datao errore: " + error.response.status);
                });
        },
        loadComuni(sigla, destination) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceComuni + "/" + sigla;
            axios
                .get(link)
                .then((response) => {
                    response.data.data.forEach((comune) => {
                        switch (destination) {
                            case "nascita":
                                me.comuniNascitaOptions.push(comune);
                                break;
                            case "residenza":
                                me.comuniResidenzaOptions.push(comune);
                                break;
                            case "domicilio":
                                me.comuniDomicilioOptions.push(comune);
                                break;
                        }
                    });
                })
                .catch((error) => {
                    console.log("Il servizio delle province ha datao errore: " + error.response.status);
                });
        },
        loadRegioni() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceRegioni;
            axios
                .get(link)
                .then((response) => {
                    response.data.data.forEach((regione) => {
                        me.regioniResidenzaOptions.push(regione);
                        me.regioniDomicilioOptions.push(regione);
                    });
                    me.regioniResidenzaOptions.unshift({ codiceIstat: null, regione: "-Seleziona Regione-" });
                    me.regioniDomicilioOptions.unshift({ codiceIstat: null, regione: "-Seleziona Regione-" });
                })
                .catch((error) => {
                    console.log("Il servizio delle regioni ha datao errore: " + error.response.status);
                });
        },
        loadProvince(regione, destination) {
            let me = this;
            me.filtro = { regione: regione };
            let link = process.env.VUE_APP_PATH_API + me.pathResourceProvince;
            axios.get(link, { params: me.filtro }).then((response) => {
                response.data.data.forEach((provincia) => {
                    switch (destination) {
                        case "residenza":
                            me.provinceResidenzaOptions.push(provincia);
                            break;
                        case "domicilio":
                            me.provinceDomicilioOptions.push(provincia);
                            break;
                    }
                });
                if (me.id !== "-1") {
                    me.setRegioni();
                }
            });
        },
        loadCap(codiceIstat, destination) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceComune + "/" + codiceIstat;
            if (codiceIstat) {
                axios
                    .get(link)
                    .then((response) => {
                        switch (destination) {
                            case "residenza":
                                me.jsonData.accettazioneAnagrafica.capResidenza = response.data.data.cap;
                                break;
                            case "domicilio":
                                me.jsonData.accettazioneAnagrafica.capDomicilio = response.data.data.cap;
                                break;
                        }
                    })
                    .catch((error) => {
                        console.log("Il servizio delle regioni ha datao errore: " + error.response.status);
                    });
            }
        },
        loadAsl(codiceIstat, destination) {
            let me = this;

            let link = process.env.VUE_APP_PATH_API + me.pathResourceAsl;
            let params = { istatComune: codiceIstat };
            if (codiceIstat) {
                axios
                    .get(link, { params: params })
                    .then((response) => {
                        if (response.data.data !== null) {
                            switch (destination) {
                                case "residenza":
                                    me.jsonData.accettazioniAsl.aslResidenzaCodice = response.data.data.codiceRegione + response.data.data.codiceAzienda;
                                    me.jsonData.accettazioniAsl.aslResidenzaDenominazione = response.data.data.denominazione;
                                    break;
                                case "domicilio":
                                    me.jsonData.accettazioniAsl.aslDomicilioCodice = response.data.data.codiceRegione + response.data.data.codiceAzienda;
                                    me.jsonData.accettazioniAsl.aslDomicilioDenominazione = response.data.data.denominazione;
                                    break;
                            }
                        }
                    })
                    .catch((error) => {
                        console.log(error);

                        console.log("Il servizio delle regioni ha datao errore: " + error.response.status);
                    });
            }
        },
        loadIdentificativi() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceIdentificativo;
            axios
                .get(link)
                .then((response) => {
                    me.tipiIdentificativoOptions = response.data.data;
                    me.tipiIdentificativoOptions.unshift({ codice: null, descrizione: "-Seleziona Tipo Identificativo-" });
                    me.jsonData.accettazioneAnagrafica.tipoIdentificativo = "CF";
                })
                .catch((error) => {
                    console.log("Il servizio dei tipi identificativo ha datao errore: " + error.response.status);
                });
        },
        onChangeStruttura(value) {
            let me = this;
            me.strutturaOptions.forEach((element) => {
                if (element.codiceStruttura === value) {
                    me.loadPresidi(element.id);
                }
            });
        },
        onChangePresidio(value) {
            let me = this;
            me.loadSettingsPresidi(value);
            //me.presidiOptions.forEach((element) => {
            //   if (element.id === value) {
            //   }
            // });
        },
        onChangeSettingCodice(value) {
            let me = this;
            me.settingsPresidiOptions.forEach((element) => {
                if (element.codice === value) {
                    me.jsonData.settingDescrizione = element.setting;
                }
            });
        },
        onChangeProvinciaNascita(value) {
            let me = this;
            me.comuniNascitaOptions = [];
            me.comuniNascitaOptions.unshift({ codiceIstat: null, comune: "-Seleziona Comune-" });
            me.loadComuni(value, "nascita");
        },
        onChangeComuneNascita() {},
        onChangeRegioneResidenza(regione) {
            let me = this;
            me.jsonData.accettazioneAnagrafica.provinciaResidenza = null;
            me.provinceResidenzaOptions = [];
            me.provinceResidenzaOptions.unshift({ sigla: null, provincia: "-Seleziona Provincia-" });
            me.loadProvince(regione, "residenza");
            me.setDomicilio();
        },
        onChangeProvinciaResidenza(provincia) {
            let me = this;
            me.jsonData.accettazioneAnagrafica.comuneResidenzaCodiceIstat = null;
            me.comuniResidenzaOptions = [];
            me.comuniResidenzaOptions.unshift({ codiceIstat: null, comune: "-Seleziona Comune-" });
            me.loadComuni(provincia, "residenza");
            me.setDomicilio();
        },
        onChangeComuneResidenza(value) {
            let me = this;
            me.loadCap(value, "residenza");
            me.loadAsl(value, "residenza");
            me.setDomicilio();
        },
        onInputRegioniDomicilio(regione) {
            let me = this;
            /* console.log("Regione Domicilio input: " + regione); */
            me.provinceDomicilioOptions = [];
            me.provinceDomicilioOptions.unshift({ sigla: null, provincia: "-Seleziona Provincia-" });
            me.loadProvince(regione, "domicilio");
        },
        onInputProvinciaDomicilio(provincia) {
            let me = this;
            me.comuniDomicilioOptions = [];
            me.comuniDomicilioOptions.unshift({ codiceIstat: null, comune: "-Seleziona Comune-" });
            me.loadComuni(provincia, "domicilio");
        },
        onInputComuneDomicilio(value) {
            let me = this;
            me.loadCap(value, "domicilio");
            me.loadAsl(value, "domicilio");
        },
        onInputCapResidenza() {
            let me = this;
            me.setDomicilio();
        },
        onInputIndirizzoResidenza() {
            let me = this;
            me.setDomicilio();
        },
        onInputTabs(index) {
            let me = this;
            let activeTab = this.$refs.tabsRsaAccettazioneEdit.getTabs()[index];
            me.activeTab(activeTab.id);
        },
        activeTab(idActiveTab) {
            /* console.log(idActiveTab); */
            let me = this;
            switch (idActiveTab) {
                case "tbRsaPersoneRiferimento":
                    break;
                case "tbDatiFatturazione":
                    me.$refs.cmpRsaAccettazioneDatiFatturazioneComponent.init(me.jsonData);
                    break;
                // case "tbSpesaExtra":
                //   console.log("Dati Spese Extra");
                //   me.$refs.cmpRsaSpesaExtraListComponent.loadData(me.jsonData);
                //   break;
                case "tbValutazioneIngresso":
                    me.$refs.RsaAccettazioneSchedeValutazioneIngressoComponent.init(me.jsonData.id);
                    break;
                case "tbConsensi":
                    me.$refs.cmpRsaConsensiList.setDati(me.id, me.jsonData.codiceStruttura, "NURITY");
                    break;
            }
            this.$emit("onActiveTab", idActiveTab);
        },
        onNuovaPersonaRiferimento() {
            let me = this;
            me.$refs.RsaPersonaRiferimentoComponent.onNuovaPersonaRiferimento();
        },
        loadDataPersoneRiferimento() {
            let me = this;
            me.$refs.RsaPersonaRiferimentoComponent.loadData();
        },
        loadDataSchedeValutazione() {
            let me = this;
            me.$refs.RsaAccettazioneSchedeValutazioneComponent.init(me.jsonData.id);
        },
        loadDataRicette() {
            let me = this;
            me.$refs.RsaAccettazioneRicetteListComponent.loadData();
        },
        setDatiFatturazione() {
            let me = this;
            me.$refs.RsaAccettazioneDatiFatturazioneComponent.setData();
        },
        // setDataImpegnativa() {
        //   let me = this;
        //   me.$refs.RsaAccettazioneImpegnativaListComponent.setData();
        // },
        onNuovaRicetta() {
            let me = this;
            me.$refs.RsaAccettazioneRicetteListComponent.nuovaRicetta();
        },
        onNuoviDatiFatturazione() {
            let me = this;
            me.$refs.cmpRsaAccettazioneDatiFatturazioneComponent.nuoviDatiFatturazione();
        },
        onNuovaImpegnativa() {
            let me = this;
            me.$refs.RsaAccettazioneImpegnativaListComponent.nuovaImpegnativa();
        },
        onNuovoAllegatoA() {
            let me = this;
            me.$refs.cmpRsaAccettazioneSchedaValutazioneAllegatoAComponent.salvaAllegato();
        },
        onKeyPress(e) {
            let me = this;
            if (e.keyCode === 13) {
                me.searchAnagrafica();
            }
        },
        onSearchAnagraficaClick() {
            let me = this;
            me.searchAnagrafica();
        },
        searchAnagrafica() {
            let me = this;
            me.showmolalloading = true;
            if (me.jsonData.accettazioneAnagrafica.identificativo.trim().length === 0) {
                me.showmolalloading = false;
            } else {
                me.jsonData.accettazioneAnagrafica.identificativo = me.jsonData.accettazioneAnagrafica.identificativo.toUpperCase();
                let link = process.env.VUE_APP_PATH_API + "/utility";
                axios
                    .get(link, {
                        params: { codiceFiscale: me.jsonData.accettazioneAnagrafica.identificativo },
                    })
                    .then((response) => {
                        me.jsonData.accettazioneAnagrafica.nome = response.data.data.nome;
                        me.jsonData.accettazioneAnagrafica.cognome = response.data.data.cognome;
                        me.jsonData.accettazioneAnagrafica.dataNascita = moment(response.data.data.dataNascita, "DD/MM/YYYY").toDate().getTime();
                        me.jsonData.accettazioneAnagrafica.sesso = response.data.data.sesso;
                        me.jsonData.accettazioneAnagrafica.provinciaNascita = response.data.data.provinciaNascita;
                        me.jsonData.accettazioneAnagrafica.regioneResidenza = response.data.data.regioneResidenza;
                        me.jsonData.accettazioneAnagrafica.comuneNascitaCodiceIstat = response.data.data.codiceIstatComuneNascita;
                        me.jsonData.accettazioneAnagrafica.provinciaResidenza = response.data.data.provinciaResidenza;
                        me.jsonData.accettazioneAnagrafica.comuneResidenzaCodiceIstat = response.data.data.codiceIstatComuneResidenza;
                        me.loadComuni(me.jsonData.accettazioneAnagrafica.provinciaNascita, "nascita");
                        me.loadProvince(me.jsonData.accettazioneAnagrafica.regioneResidenza, "residenza");
                        me.loadComuni(me.jsonData.accettazioneAnagrafica.provinciaResidenza, "residenza");
                        me.loadCap(response.data.data.codiceIstatComuneResidenza, "residenza");
                        me.loadAsl(response.data.data.codiceIstatComuneResidenza, "residenza");
                        me.setDomicilio();
                        me.loadComuni(me.jsonData.accettazioneAnagrafica.provinciaDomicilio, "domicilio");
                        me.jsonData.accettazioneAnagrafica.indirizzoResidenza = response.data.data.indirizzoResidenza;
                        me.jsonData.accettazioneAnagrafica.codiceFiscaleMedicoCurante = response.data.data.codiceFiscaleMedico;
                        me.jsonData.accettazioneAnagrafica.medicoCurante = response.data.data.medico;

                        me.showmolalloading = false;
                    })
                    .catch(() => {
                        me.showmolalloading = false;
                    });
            }
        },
        setRegioni() {
            let me = this;
            me.provinceResidenzaOptions.forEach((element) => {
                if (element.sigla === me.jsonData.accettazioneAnagrafica.provinciaResidenza) {
                    if (me.jsonData.accettazioneAnagrafica.regioneResidenza === null || me.jsonData.accettazioneAnagrafica.regioneResidenza === "") {
                        me.jsonData.accettazioneAnagrafica.regioneResidenza = element.regione;
                    }
                }
                if (element.sigla === me.jsonData.accettazioneAnagrafica.provinciaDomicilio) {
                    if (me.jsonData.accettazioneAnagrafica.regioneDomicilio === null || me.jsonData.accettazioneAnagrafica.regioneDomicilio === "") {
                        me.jsonData.accettazioneAnagrafica.regioneDomicilio = element.regione;
                    }
                }
            });
        },
        setPresidi() {
            let me = this;
            me.strutturaOptions.forEach((element) => {
                if (element.codiceStruttura === me.jsonData.codiceStruttura) {
                    me.loadPresidi(element.id);
                }
            });
        },
    },
};
</script>
