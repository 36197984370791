<template>
    <div style="height: 100%; overflow: auto">
        <b-tabs id="rsaGestioneOspitiEditTabComponent" nav-wrapper-class="sa-tab-items" content-class="mt-3" ref="tabs" class="sa-tab" style="height: 100%; padding-bottom: 25px" fill>
            <b-tab title="Anagrafica" active>
                <div class="sa-tab-scroll">
                    <b-card class="sa-card" header="Anagrafica" header-tag="header" footer-tag="footer" title="">
                        <b-row>
                            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                <label htmlFor="rsaGestioneOspitiEditComponentOspiteIdentificativo" class="sa-label-data">Codice Fiscale</label>
                                <b-form-input id="rsaGestioneOspitiEditComponentOspiteIdentificativo" v-model="jsonData.accettazioneAnagrafica.identificativo"></b-form-input>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                <label class="sa-label-data">Cognome</label>
                                <b-form-input v-model="jsonData.accettazioneAnagrafica.cognome"></b-form-input>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                <label class="sa-label-data">Nome</label>
                                <b-form-input v-model="jsonData.accettazioneAnagrafica.nome"></b-form-input>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                <label class="sa-label-data">Data di nascita</label>
                                <date-picker format="DD/MM/YYYY" value-type="timestamp" v-model="jsonData.accettazioneAnagrafica.dataNascita" type="date"></date-picker>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                <label class="sa-label-data">Provincia di nascita</label>
                                <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.accettazioneAnagrafica.provinciaNascita" class="mb-2 mr-sm-2 mb-sm-0" :options="provinciaOption" @change="loadComuneNascita(jsonData.accettazioneAnagrafica.provinciaNascita)" required></b-form-select>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                <label class="sa-label-data">Comune di nascita</label>
                                <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.accettazioneAnagrafica.comuneNascitaCodiceIstat" class="mb-2 mr-sm-2 mb-sm-0" :value="null" :options="comuneNascitaOption" required></b-form-select>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                <label class="sa-label-data">Sesso</label>
                                <b-form-select v-model="jsonData.accettazioneAnagrafica.sesso" :options="sessoOptions"></b-form-select>
                            </b-col>
                        </b-row>
                    </b-card>
                    <b-card class="sa-card" header="Diagnosi" header-tag="header" footer-tag="footer" title="">
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-form-textarea v-model="jsonData.accettazioneAnagrafica.note" id="textarea-auto-height" placeholder="" rows="3" max-rows="7"></b-form-textarea>
                        </b-col>
                    </b-card>
                    <b-card class="sa-card" header="Residenza" header-tag="header" footer-tag="footer" title="">
                        <b-row>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label class="sa-label-data">Regione</label>
                                <b-form-select id="inline-form-custom-select-pref" v-model="regioneResidenza" class="mb-2 mr-sm-2 mb-sm-0" :value="null" value-field="codiceIstat" text-field="regione" :options="regioniOptions" @change="loadProvinceByRegioneResidenza(regioneResidenza)" required></b-form-select>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label class="sa-label-data">Provincia</label>

                                <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.accettazioneAnagrafica.provinciaResidenza" class="mb-2 mr-sm-2 mb-sm-0" :options="provinciaOptionResidenza" @change="loadComuneResidenza(jsonData.accettazioneAnagrafica.provinciaResidenza)" required></b-form-select>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label class="sa-label-data">Comune</label>
                                <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.accettazioneAnagrafica.comuneResidenzaCodiceIstat" class="mb-2 mr-sm-2 mb-sm-0" :value="null" :options="comuneOptionResidenza" @change="loadCap(jsonData.accettazioneAnagrafica.comuneResidenzaCodiceIstat)" required></b-form-select>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label class="sa-label-data">CAP</label>
                                <b-form-input v-model="jsonData.accettazioneAnagrafica.capResidenza"></b-form-input>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <label class="sa-label-data">Indirizzo</label>
                                <b-form-input v-model="jsonData.accettazioneAnagrafica.indirizzoResidenza"></b-form-input>
                            </b-col>
                            <b-col>
                                <b-form-checkbox v-model="isDomicilioUgualeResidenza" name="check-button" switch>Domicilio e Residenza coincidono</b-form-checkbox>
                            </b-col>
                        </b-row>
                    </b-card>
                    <b-card class="sa-card" header="Domicilio" header-tag="header" footer-tag="footer" title="" v-show="!isDomicilioUgualeResidenza">
                        <b-row>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label class="sa-label-data">Regione</label>
                                <b-form-select id="inline-form-custom-select-pref" v-model="regioneDomicilio" class="mb-2 mr-sm-2 mb-sm-0" :value="null" value-field="codiceIstat" text-field="regione" :options="regioniOptions" @change="loadProvinceByRegioneDomicilio(regioneDomicilio)" required></b-form-select>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label class="sa-label-data">Provincia</label>

                                <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.accettazioneAnagrafica.provinciaDomicilioSanitario" class="mb-2 mr-sm-2 mb-sm-0" :options="provinciaOptionDomicilio" @change="loadComuneDomicilioSanitario(jsonData.accettazioneAnagrafica.provinciaDomicilioSanitario)" required></b-form-select>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label class="sa-label-data">Comune</label>
                                <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.accettazioneAnagrafica.comuneDomicilioCodiceIstat" class="mb-2 mr-sm-2 mb-sm-0" :value="null" :options="comuneDomicilioSanitarioOption" @change="loadCap(jsonData.accettazioneAnagrafica.comuneDomicilioCodiceIstat)" required></b-form-select>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label class="sa-label-data">CAP</label>
                                <b-form-input v-model="jsonData.accettazioneAnagrafica.capDomicilio"></b-form-input>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <label class="sa-label-data">Indirizzo</label>
                                <b-form-input v-model="jsonData.accettazioneAnagrafica.indirizzoDomicilio"></b-form-input>
                            </b-col>
                        </b-row>
                    </b-card>
                    <b-card class="sa-card" header="ASL di riferimento" header-tag="header" footer-tag="footer" title="">
                        <b-row>
                            <!--   <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">
            Regione<span style="color: red">*</span></label>-->
                            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label class="sa-label-data">Comune</label>
                                <b-form-input v-model="jsonData.comuneStorico"></b-form-input>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label class="sa-label-data">ASL</label>
                                <b-form-input v-model="jsonData.asl"></b-form-input>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label class="sa-label-data">Ambito</label>
                                <b-form-input v-model="jsonData.ambito"></b-form-input>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label class="sa-label-data">Distretto</label>
                                <b-form-input v-model="jsonData.distretto"></b-form-input>
                            </b-col>
                        </b-row>
                    </b-card>
                    <b-card class="sa-card" header="Altre info" header-tag="header" footer-tag="footer" title="">
                        <b-row>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label class="sa-label-data">Numero di Telefono</label>
                                <b-form-input v-model="jsonData.accettazioneAnagrafica.telefono"></b-form-input>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label class="sa-label-data">Email</label>
                                <b-form-input v-model="jsonData.accettazioneAnagrafica.email"></b-form-input>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label class="sa-label-data">Decreto invadilità</label>
                                <b-form-select v-model="jsonData.decretoInvalidita" :options="invadilitaOptions"></b-form-select>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label class="sa-label-data">Medico curante</label>
                                <b-form-input v-model="jsonData.accettazioneAnagrafica.medicoCurante"></b-form-input>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label class="sa-label-data">Email medico curante</label>
                                <b-form-input v-model="jsonData.emailMedicoCurante"></b-form-input>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label class="sa-label-data">Scadenza tesserino rosa</label>
                                <date-picker format="DD/MM/YYYY" value-type="timestamp" v-model="jsonData.scadenzaTesserinoRosa" type="date"></date-picker>
                            </b-col>
                            <b-col>
                                <b-form-checkbox v-model="jsonData.autonomo" name="check-button" switch>PAZIENTE AUTONOMO</b-form-checkbox>
                            </b-col>
                        </b-row>
                    </b-card>
                </div>
            </b-tab>
            <b-tab title="Familiari">
                <b-card class="sa-card" header="Familiari" header-tag="header" footer-tag="footer" title="">
                    <b-table sticky-header ref="table" stacked="lg" striped hover :items="jsonData.listaFamiliari" :fields="fields" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="jsonData">
                            {{ jsonData.index + 1 }}
                        </template>
                        <template #cell(nominativo)="item">
                            <b-form-input v-if="item.index === jsonData.listaFamiliari.length - 1" v-model="item.item.nominativo"></b-form-input>
                            <span class="sa-data" v-else>{{ item.item.nominativo }}</span>
                        </template>
                        <template #cell(telefono)="item">
                            <b-form-input v-if="item.index === jsonData.listaFamiliari.length - 1" v-model="item.item.telefono"></b-form-input>
                            <span class="sa-data" v-else>{{ item.item.telefono }}</span>
                        </template>
                        <template #cell(email)="item">
                            <b-form-input v-if="item.index === jsonData.listaFamiliari.length - 1" v-model="item.item.email"></b-form-input>
                            <span class="sa-data" v-else>{{ item.item.email }}</span>
                        </template>
                        <template #cell(parentela)="item">
                            <b-form-input v-if="item.index === jsonData.listaFamiliari.length - 1" v-model="item.item.parentela"></b-form-input>
                            <span class="sa-data" v-else>{{ item.item.parentela }}</span>
                        </template>
                        <template #cell(actions)="row">
                            <b-button v-if="row.index === jsonData.listaFamiliari.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddFamiliare(jsonData.listaFamiliari, row.item)">
                                <b-icon icon="plus"></b-icon>
                            </b-button>
                            <b-button v-if="row.index !== jsonData.listaFamiliari.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDeleteFamiliare(jsonData.listaFamiliari, row)">
                                <b-icon icon="trash"></b-icon>
                            </b-button>
                        </template>
                    </b-table>
                </b-card>
            </b-tab>
            <b-tab title="Esenzioni">
                <b-card class="sa-card" header="Esenzioni" header-tag="header" footer-tag="footer" title="">
                    <b-table sticky-header ref="table" stacked="lg" striped hover :items="jsonData.esenzioni" :fields="fieldsEsenzioni" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="jsonData">
                            {{ jsonData.index + 1 }}
                        </template>
                        <template #cell(etichetta)="item">
                            <b-form-input v-if="item.index === jsonData.esenzioni.length - 1" v-model="item.item.etichetta"></b-form-input>
                            <span class="sa-data" v-else>{{ item.item.etichetta }}</span>
                        </template>
                        <template #cell(dataInserimento)="item">
                            <date-picker v-if="item.index === jsonData.esenzioni.length - 1" format="DD/MM/YYYY" value-type="timestamp" v-model="item.item.dataInserimento" type="date"></date-picker>
                            <span class="sa-data" v-else>{{ dataFormatter(item.item.dataInserimento) }}</span>
                        </template>
                        <template #cell(scadenza)="item">
                            <date-picker v-if="item.index === jsonData.esenzioni.length - 1" format="DD/MM/YYYY" value-type="timestamp" v-model="item.item.scadenza" type="date"></date-picker>
                            <span class="sa-data" v-else>{{ dataFormatter(item.item.scadenza) }}</span>
                        </template>
                        <template #cell(actions)="row">
                            <b-button v-if="row.index === jsonData.esenzioni.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="addEsenzione(jsonData.esenzioni, row.item)">
                                <b-icon icon="plus"></b-icon>
                            </b-button>
                            <b-button v-if="row.index !== jsonData.esenzioni.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="deleteEsenzione(jsonData.esenzioni, row)">
                                <b-icon icon="trash"></b-icon>
                            </b-button>
                        </template>
                    </b-table>
                </b-card>
            </b-tab>
            <b-tab title="Valutazione All'Ingresso">
                <rsa-valutazione-ingresso-component :items="jsonData.listaPatologia"></rsa-valutazione-ingresso-component>
            </b-tab>
        </b-tabs>
    </div>
</template>
<script>
import axios from "axios";
import "vue2-datepicker/index.css";
import DatePicker from "vue2-datepicker";
import UtilityMixin from "../../../utility/UtilityMixin";
import RsaValutazioneIngressoComponent from "../../components/RsaValutazioneIngressoComponent.vue";
export default {
    components: { DatePicker, RsaValutazioneIngressoComponent },
    data() {
        return {
            pathResource: "/rsaaccettazione",
            linkStrutture: "/",
            linkProvinceByRegione: "/province",
            pathResourceRegioni: "/regioni",
            linkComune: "/comuni/provincia",
            linkComuneByCodiceIstat: "/comuni/istat",
            showModalLoading: false,
            id: null,
            linkback: null,
            rows: 0,
            perPage: 50,
            currentPage: 1,
            listKey: 0,
            isDomicilioUgualeResidenza: false,
            regioneResidenza: "",
            regioneDomicilio: "",
            comuneSelezionato: {},
            filtro: { regione: null },
            jsonData: {
                codiceStruttura: "Codice Struttura", //non a video
                numero: "123", //non a video
                statoAttuale: "Stato", //non a video
                statoAttualeDataOra: new Date().getTime(), //non a video
                note: "note", //non a video
                tipo: "tipo", //non a video
                idPrenotazione: "Id Prenotazioni", //non a video
                accettazioneStruttura: {}, //non a video
                accettazioneAnagrafica: {
                    idAccettazione: "id Accettazione", //non a video
                    tipo: "PATIENT", //non a video
                    identificativo: "identificativo",
                    tipoIdentificativo: "CODICE FISCALE", //non a video
                    cognome: "cognome",
                    nome: "nome",
                    dataNascita: new Date().getTime(),
                    comuneNascitaCodiceIstat: "063057",
                    comuneResidenzaCodiceIstat: "063057",
                    comuneDomicilioCodiceIstat: "063057",
                    provinciaDomicilio: "NA",
                    provinciaResidenza: "NA",
                    provinciaNascita: "NA",
                    indirizzoResidenza: "indirizzo residenza",
                    indirizzoDomicilio: "indirizzo domicilio",
                    note: "note accettazione anagrafica",
                    medicoCurante: "medico curante",
                    codiceFiscaleMedicoCurante: "cf medico curante", //non a video
                    gruppoSanguigno: "0", //non a video
                    capDomicilio: "80038",
                    capResidenza: "80038",
                    telefono: "3023596563",
                    email: "email@gesan.it",
                    sesso: "M",
                },
                accettazioneStati: [],
                accettazionePrestazioni: [],
            },
            // ------- Campi Nuovi -------
            //   comuneStorico: null,
            //   asl: null,
            //   ambito: null,
            //   distretto: null,
            //   decretoInvalidita: null,
            //   medicoCurante: null,
            //   emailMedicoCurante: null,
            //   scadenzaTesserinoRosa: null,
            //   autonomo: null,
            //   diagnosi: null,
            // privacy: false,
            // listaFamiliari: [{ nominativo: "", parentela: "", telefono: "", email: "" }],
            // esenzioni: [
            //     {
            //         etichetta: null,
            //         dataInserimento: null,
            //         scadenza: null,
            //     },
            // ],
            //},
            sessoOptions: [
                { value: null, text: "-Selezionare sesso-" },
                { value: "M", text: "Maschio" },
                { value: "F", text: "Femmina" },
            ],
            invadilitaOptions: [
                { value: null, text: "SI / NO" },
                { value: "SI", text: "Si" },
                { value: "NO", text: "No" },
            ],
            regioniOptions: [],
            provinciaOption: [],
            provinciaOptionResidenza: [],
            provinciaOptionDomicilio: [],
            comuneOptionResidenza: [],
            comuneDomicilioSanitarioOption: [],
            comuneNascitaOption: [],
            fields: [
                { label: "", key: "index" },
                {
                    label: "Nominativo",
                    key: "nominativo",
                },
                {
                    label: "Numero telefono",
                    key: "telefono",
                },
                {
                    label: "Email",
                    key: "email",
                },
                {
                    label: "Parentela",
                    key: "parentela",
                },
                {
                    label: "",
                    key: "actions",
                },
            ],
            fieldsEsenzioni: [
                { label: "", key: "index" },
                {
                    label: "Etichetta",
                    key: "etichetta",
                },
                {
                    label: "Data inserimento",
                    key: "dataInserimento",
                },
                {
                    label: "Scadenza",
                    key: "scadenza",
                },
                {
                    label: "",
                    key: "actions",
                },
            ],
        };
    },
    mounted: function () {
        let me = this;
        me.id = this.$route.params.id;
        me.loadRegioni();
        me.loadProvince();
    },
    watch: {
        isDomicilioUgualeResidenza(value) {
            let me = this;
            if (value) {
                me.regioneDomicilio = me.regioneResidenza;
                me.jsonData.accettazioneAnagrafica.provinciaDomicilio = me.jsonData.accettazioneAnagrafica.provinciaResidenza;
                me.jsonData.comuneDomicilioCodiceIstat = me.jsonData.comuneResidenzaCodiceIstat;
                me.jsonData.indirizzoDomicilio = me.jsonData.indirizzoResidenza;
                me.jsonData.capDomicilio = me.jsonData.capResidenza;
            } else if (!value) {
                me.provinciaOptionDomicilio = [];
                me.comuneDomicilioSanitarioOption = [];
                me.regioneDomicilio = "";
                me.jsonData.accettazioneAnagrafica.provinciaDomicilio = "";
                me.jsonData.accettazioneAnagrafica.comuneDomicilioCodiceIstat = "";
                me.jsonData.accettazioneAnagrafica.indirizzoDomicilio = "";
                me.jsonData.accettazioneAnagrafica.capDomicilio = "";
            }
        },
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        loadData() {
            let me = this;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                me.showModalLoading = true;
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                axios
                    .get(link + me.id)
                    .then((response) => {
                        me.jsonData = response.data.data;

                        me.showModalLoading = false;
                    })
                    .catch(() => {});
            }
        },
        onAddFamiliare(array, value) {
            if (value.nominativo !== "" && value.telefono !== "" && value.email !== "") {
                array.push({ nominativo: "", telefono: "", email: "" });
            } else {
                this.$bvModal.msgBoxOk("Inserire tutti i campi", {
                    title: "Errore",
                    buttonSize: "sm",
                    okVariant: "danger",
                    okTitle: "OK",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                });
            }
        },
        onDeleteFamiliare(array, row) {
            if (row.index != array.length) {
                this.$bvModal
                    .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
                        title: "Titolo",
                        buttonSize: "sm",
                        cancelVariant: "danger",
                        okTitle: "SI",
                        cancelTitle: "NO",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true,
                    })
                    .then((value) => {
                        if (value) {
                            array.splice(row.index, 1);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
        },
        loadRegioni() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceRegioni;
            axios
                .get(link)
                .then((response) => {
                    me.regioniOptions = response.data.data;
                })
                .catch((error) => {
                    console.log("Il servizio delle regioni ha datao errore: " + error.response.status);
                });
        },
        loadProvince() {
            let me = this;
            me.provinciaOption = [];
            let link = process.env.VUE_APP_PATH_API + "/province";
            axios
                .get(link)
                .then((response) => {
                    response.data.data.forEach((element) => {
                        me.provinciaOption.push({
                            value: element.sigla,
                            text: element.provincia,
                        });
                    });
                })
                .catch((error) => {
                    console.log("Il servizio delle province ha datao errore: " + error.response.status);
                });
        },
        loadProvinceByRegioneResidenza(regione) {
            let me = this;
            me.provinciaOptionResidenza = [];
            me.filtro = { regione };
            let link = process.env.VUE_APP_PATH_API + me.linkProvinceByRegione;
            axios.get(link, { params: me.filtro }).then((response) => {
                response.data.data.forEach((element) => {
                    me.provinciaOptionResidenza.push({
                        value: element.sigla,
                        text: element.provincia,
                    });
                });
                me.comuneOptionResidenza = [];
            });
        },
        loadProvinceByRegioneDomicilio(regione) {
            let me = this;
            me.provinciaOptionDomicilio = [];
            me.filtro = { regione };
            let link = process.env.VUE_APP_PATH_API + me.linkProvinceByRegione;
            axios.get(link, { params: me.filtro }).then((response) => {
                response.data.data.forEach((element) => {
                    me.provinciaOptionDomicilio.push({
                        value: element.sigla,
                        text: element.provincia,
                    });
                });
            });
        },
        loadComuneNascita(sigla) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkComune + "/" + sigla;
            me.comuneNascitaOption = [];
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    me.comuneNascitaOption.push({
                        value: element.codiceIstat,
                        text: element.comune,
                    });
                });
            });
        },
        loadComuneResidenza(sigla) {
            let me = this;
            me.comuneOptionResidenza = [];
            let link = process.env.VUE_APP_PATH_API + me.linkComune + "/" + sigla;
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    me.comuneOptionResidenza.push({
                        value: element.codiceIstat,
                        text: element.comune,
                    });
                });
            });
        },
        loadComuneDomicilioSanitario(sigla) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkComune + "/" + sigla;
            me.comuneDomicilioSanitarioOption = [];
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    me.comuneDomicilioSanitarioOption.push({
                        value: element.codiceIstat,
                        text: element.comune,
                    });
                });
            });
        },
        addEsenzione(array, value) {
            if (value.etichetta !== "" && value.etichetta !== null && value.dataInserimento !== null && value.scadenza !== null) {
                array.push({ etichetta: "", dataInserimento: null, scadenza: null });
            } else {
                this.$bvModal.msgBoxOk("Inserire tutti i campi", {
                    title: "Errore",
                    buttonSize: "sm",
                    okVariant: "danger",
                    okTitle: "OK",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                });
            }
        },
        deleteEsenzione(array, row) {
            if (row.index != array.length) {
                this.$bvModal
                    .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
                        title: "Conferma di cancellazione",
                        buttonSize: "sm",
                        cancelVariant: "danger",
                        okTitle: "SI",
                        cancelTitle: "NO",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true,
                    })
                    .then((value) => {
                        if (value) {
                            array.splice(row.index, 1);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        dataFormatter(value) {
            return UtilityMixin.methods.formatDateTimeCustom(value, "DD-MM-YYYY");
        },
        loadCap(item) {
            let me = this;
            me.comuneSelezionato = {};
            let link = process.env.VUE_APP_PATH_API + me.linkComuneByCodiceIstat + "/" + item;
            axios.get(link).then((response) => {
                me.comuneSelezionato = response.data.data;
                if (item === me.jsonData.accettazioneAnagrafica.comuneResidenzaCodiceIstat) {
                    me.jsonData.accettazioneAnagrafica.capResidenza = me.comuneSelezionato.cap;
                } else if (item === me.jsonData.comuneDomicilioCodiceIstat) {
                    me.jsonData.accettazioneAnagrafica.capDomicilio = me.comuneSelezionato.cap;
                }
            });
        },
    },
};
</script>
