import Vue from "vue";
import VueRouter from "vue-router";
import Pages from "../app/pages/";
import axios from "axios";
import MainRouter from "../app/pages/router";
import NotFound from "../app/pages/components/NotFound.vue";
import ClientiRouter from "../app/clienti/router";
import PrenotazioniRouter from "../app/prenotazioni/router";
import ContattiRouter from "../app/contatti/router";
import EntiRouter from "../app/enti/router";
import ProfiloRouter from "../app/profilo/router";
import UsersRouter from "../app/utenti/router";
import FatturazioneRouter from "../app/fatturazione/router";
import MagazzinoRouter from "../app/magazzino/router";
import ConfigurazioneRouter from "../app/configurazione/router";
import LogsRouter from "../app/utility/logs/router";
import CaseificioRouter from "../app/caseificio/router";
import TaskManagerRouter from "../app/taskmanager/router";
import IntegrazioneBusiness from "../app/integrazionebusiness/router";
Vue.use(VueRouter);

let mainChildren = MainRouter.concat(ClientiRouter, PrenotazioniRouter, FatturazioneRouter, ProfiloRouter, ContattiRouter, EntiRouter, UsersRouter, MagazzinoRouter, ConfigurazioneRouter, LogsRouter, CaseificioRouter, TaskManagerRouter, IntegrazioneBusiness);

mainChildren.push({
  path: "*",
  component: NotFound
});
const routes = [
  {
    name: "Login",
    path: "/login",
    component: Pages.Login,
    hidden: true
  },
  {
    path: "/",
    component: Pages.Main,
    children: mainChildren
  },
  {
    path: "*",
    redirect: {
      name: "Login"
    }
  }
];

const router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {
  let loginData = {
    access_token: to.query.authToken
  }; //JSON.parse(localStorage.getItem("loginData"));
  if (!to.query.authToken) {
    loginData = {
      access_token: from.query.authToken
    };
  }
  let accessToken = loginData == null ? undefined : loginData.access_token;

  if (to.path == "/login") {
    next();
  } else if (to.path == "/installation") {
    next();
  } else if (!to.query.authToken && accessToken) {
    to.query.authToken = accessToken;
    next({
      path: to.path,
      query: to.query
    });
    //next({ path: to.path, query: { language: $i18n.locale, authToken: accessToken } });
  } else {
    axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
    axios.defaults.headers["Content-Type"] = "application/json";
    //        axios.defaults.params = { language: $i18n.locale };
    if (to.name == "Login") {
      next();
    } else if (loginData === null || accessToken === undefined) {
      next("/login");
    } else {
      let link = process.env.VUE_APP_PATH_API + "/validate";
      axios
        .get(link)
        .then(() => {
          if (document.body.classList.contains("sa-body-sidebar-open")) {
            document.body.classList.remove("sa-body-sidebar-open");
          }
          next();
        })
        .catch(() => {
          localStorage.clear();
          next("Login");
        });
    }
  }
});
export default router;
