<template>
    <!-- <b-card class="mb-4 sa-card-padding-4"> -->
    <b-card class="sa-card" header="Attività" header-tag="header" footer-tag="footer" title="" style="height: 99%">
        <!-- <h4 class="header-title mt-0 mb-3"><i class="mdi mdi-notification-clear-all mr-1"></i> {{ this.$i18n.t("patients.lblDiary") }}</h4> -->
        <template #header>
            <h4 class="header-title mt-0 mb-3">
                <i class="bi bi-journal-bookmark-fill"></i>
                {{ titolo }}
            </h4>
        </template>
        <ul class="timeline2">
            <li v-for="item in diario" :key="item.id" class="event" data-date="25 Maggio 2021 | 10:30">
                <span class="mdi mdi-circle pal"></span>
                <span class="sa-timeline-date">{{ formatDateTime(item.dataEvento) }}</span>
                <h3>
                    <a href="#" onclick="return false;" @click="onTipoVisitaClick(item)">{{ getTipoVisita(item.labelLanguage) }}</a>
                </h3>
                <span>{{ item.lastnameUpdater }} {{ item.firstnameUpdater }}</span>
            </li>
        </ul>
        <b-modal ref="mdlEvento" size="xl" :title="mdlTitle" scrollable>
            <div style="padding: 15px; height: 100%">
                <component ref="itemRefs" :is="currentTabComponent" v-bind="currentProperties"></component>
            </div>
        </b-modal>
    </b-card>
</template>

<script>
import axios from "axios";
import moment from "moment";
import eventi from "../eventi/base";
export default {
    data() {
        return {
            mdlTitle: "",
            currentProperties: { idEvento: "gg" },
            currentTabComponent: null,
            diario: [],
        };
    },
    components: eventi,
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadDiario();
    },
    props: {
        idPaziente: {
            type: String,
            default: function () {
                return null;
            },
        },
    },
    computed: {
        titolo() {
            return this.$i18n.t("patients.lblDiary");
        },
        // eventi,
        // idoneita() {
        //     return () => import("../eventi/medicinadellosport/idoneita/components/IdoneitaViewComponent.vue");
        // },
    },
    methods: {
        getTipoVisita(value) {
            let returnValue = this.$i18n.t(value);
            return returnValue;
        },
        loadDiario() {
            let me = this;
            let idParam = null;
            let link = process.env.VUE_APP_PATH_API + "/pazienteeventi" + "/";
            if (me.idPaziente === null) {
                idParam = me.id;
            } else {
                idParam = me.idPaziente;
            }
            axios
                .get(link, { params: { idPaziente: idParam, forPage: 10000, page: 1 } })
                .then((response) => {
                    me.diario = response.data.data.list;
                })
                .catch((e) => {
                    console.log("Error: " + e.response.status + " " + link);
                });
        },
        formatDateTime(value) {
            if (value) {
                return moment(new Date(value)).format("DD-MM-YYYY HH:mm");
            } else {
                return "---";
            }
        },
        onTipoVisitaClick(item) {
            let me = this;
            me.currentTabComponent = null;
            me.currentProperties = { idEvento: item.id };
            me.mdlTitle = me.getTipoVisita(item.tipoEvento);
            // console.log(eventi);
            eventi["anamnesigenerica"] = () => import(`../eventi/anamnesigenerica/components/AnamnesiGenericaViewComponent.vue`);
            // console.log(eventi.components());
            // console.log(item.pageKey);
            // console.log("../eventi/gastroenterologia/egds/components/EgdsViewComponent.vue");
            me.currentTabComponent = eventi[item.pageKey];
            // me.currentTabComponent = eventi.components()[item.pageKey];
            //() => import("../eventi/medicinadellosport/idoneita/components/IdoneitaViewComponent.vue");
            // me.currentTabComponent = () => import("../eventi/gastroenterologia/ecoendoscopia/components/EcoendoscopiaViewComponent.vue");
            // me.currentTabComponent = () => import("../eventi/gastroenterologia/egds/components/EgdsViewComponent.vue");
            //                                        ../eventi/gastroenterologia/egds/components/EgdsViewComponent.vue
            me.$refs["mdlEvento"].show();
        },
    },
};
</script>

<style scoped>
.sa-page-paziente-diario-clinico .card-body {
    height: 100%;
    overflow: auto;
}

.pal,
.palV {
    margin-left: -40px;
    position: relative;
    font-size: 18px;
}
.pal {
    background-color: #ab1f3a;
}
.mdi-circle {
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    top: 18px;
}

.timeline2 {
    border-left: 3px solid #f2f2f2;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    margin: 0 0 0 10px;
    position: relative;
    padding-left: 30px;
    max-width: 85%;
    letter-spacing: 0.2px;
    line-height: 1.3em;
    font-size: 1.03em;
    list-style: none;
    text-align: left;
}

.timeline2 h2,
.timeline2 h3 {
    font-size: 1.067rem;
}
.sa-timeline-date {
    font-size: 0.85rem;
}
</style>
