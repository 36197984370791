<template>
    <sa-page-layout :btnNewVisible="btnNewVisible" :btnRefreshVisible="true" @refresh="onRefresh" :pathResource="pathResource" :linkedit="linkedit" :showModalLoading="showModalLoading">
        <template slot="table-filter"></template>
        <template slot="table-body">
            <svei-configurazione-punti-incontro-list-component :linkedit="linkedit" ref="SveiConfigurazionePuntiIncontroListComponent" @afterLoadData="onAfterLoadData"></svei-configurazione-punti-incontro-list-component>
        </template>
    </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import SveiConfigurazionePuntiIncontroListComponent from "../components/SveiConfigurazionePuntiIncontroListComponent.vue";
export default {
    components: { SaPageLayout, SveiConfigurazionePuntiIncontroListComponent },
    data() {
        return {
            filtro: { nome: null, cognome: null, identificativo: null },
            btnNewVisible: true,
            btnBackVisible: true,
            linkback: "/sveipuntiincontro",
            pathResource: "/sveipuntiincontro",
            linkedit: "/sveipuntiincontro",
            showModalLoading: false,
            listKey: 0,
        };
    },
    mounted() {
        let me = this;
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            me.$refs.SveiConfigurazionePuntiIncontroListComponent.loadData();
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        onAfterLoadData() {
            let me = this;
            me.showModalLoading = false;
        },
        onBeforeLoadData() {
            let me = this;
            me.showModalLoading = true;
        },
        onReset() {},
        onSubmit() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
    },
};
</script>

<style></style>
