<template>
    <div class="sa-tab-scroll">
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <div class="sa-list-component-header">
                <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col lg="3">
                        <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                    </b-col>
                    <b-col lg="9">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                    </b-col>
                </b-row>
            </div>
            <div class="sa-list-component-body">
                <div class="b-table-sticky-header">
                    <b-table sticky-header ref="table" stacked="sm" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(action)="{ item }">
                            <!-- <b-icon v-if="item.compilata" icon="check-square-fill" variant="success"></b-icon>
              <b-icon v-else icon="x-square-fill" variant="danger"></b-icon> -->
                            <b-button v-if="item.compilata" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" disabled>
                                <i class="bi bi-check2"></i>
                            </b-button>
                            <b-button v-else size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" disabled>
                                <i class="bi bi-check2"></i>
                            </b-button>
                            <b-button v-if="item.path !== null && item.path !== ''" size="sm" variant="btn btn-outline-secondary waves-effect waves-light width-sm no-tex" @click="onInserisciModifica(item)">
                                <i class="bi bi-pencil"></i>
                            </b-button>
                            <b-button size="sm" variant="btn btn-outline-info waves-effect waves-light width-sm no-text" @click="onVisualizzaPdf(item)" :disabled="!item.compilata">
                                <i class="bi bi-filetype-pdf"></i>
                            </b-button>
                            <!-- <print-component v-if="item.compilata" :data="getPrintData(item.path)" :linkPrintData="linkPrintData(item)" typeButton="small"></print-component> -->
                        </template>
                    </b-table>
                </div>
            </div>
            <div class="sa-list-component-footer">
                <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col lg="3">
                        <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rows }}</p>
                    </b-col>
                    <b-col lg="9">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
        <b-modal scrollable b-modal ref="mdlModulisticaRichiestaFornituraAusili" id="mdlModulisticaRichiestaFornituraAusili" size="xl" style="height: 100%" @ok="onAddScheda" :title="rigaSelezionata.modulo">
            <cot-modulistica-richiesta-fornitura-ausili-component @update="onUpdateScheda"></cot-modulistica-richiesta-fornitura-ausili-component>
        </b-modal>
        <pdf-print-view-embedded ref="pdfPrintViewEmbedded" :pdf="signedPdfViewer"></pdf-print-view-embedded>
    </div>
</template>

<script>
import axios from "axios";
import PdfPrintViewEmbedded from "../../utility/components/PdfPrintViewEmbedded.vue";
import CotModulisticaRichiestaFornituraAusiliComponent from "./CotModulisticaRichiestaFornituraAusiliComponent.vue";
export default {
    components: { PdfPrintViewEmbedded, CotModulisticaRichiestaFornituraAusiliComponent },
    data() {
        return {
            rows: 0,
            perPage: 50,
            currentPage: 1,
            pathResource: "/cotmodulistica",
            pathResourceStampa: "/print/createreport",
            signedPdfViewer: null,
            filtro: {},
            items: [],
            rigaSelezionata: {},
            nomeModal: "",
            scheda: [],
            listKey: 0,
            fields: [
                {
                    label: "Tipo",
                    key: "modulo",
                    sortable: true,
                },
                {
                    label: "",
                    key: "action",
                    thStyle: "width: 10rem",
                },
            ],
        };
    },
    props: {
        idPropostaAccesso: String,
    },
    mounted() {
        let me = this;
        let sessionStorage = window.sessionStorage;
        if (sessionStorage["filtro"]) {
            me.filtro = JSON.parse(sessionStorage["filtro"]);
        }
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.filtro.page = me.currentPage;
            me.filtro.forPage = me.perPage;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];
                    me.items = response.data.data.list;
                    me.checkSchedeCompilate();
                    me.rows = response.data.data.recordsNumber;
                    me.listKey++;
                    this.$emit("afterLoadData");
                })
                .catch(() => {
                    this.$emit("afterLoadData");
                });
        },
        checkSchedeCompilate() {
            let me = this;
            me.items.forEach((element) => {
                if (element.path !== null && element.path !== "") {
                    let link = process.env.VUE_APP_PATH_API + element.path + "/" + me.idPropostaAccesso;
                    axios
                        .get(link)
                        .then((response) => {
                            me.listKey++;
                            element.compilata = response.data.esito;
                        })
                        .catch(() => {
                            element.compilata = false;
                        });
                } else {
                    element.compilata = false;
                }
            });
        },
        onPrint() {
            console.log("Eccomi");
        },
        onModifica() {
            console.log("Modifica");
        },
        onInserisciModifica(value) {
            let me = this;
            // console.log(value.path);
            me.rigaSelezionata = value;
            switch (me.rigaSelezionata.path) {
                case "/cotmodulisticarichiestafornituraausilirichiesta":
                    me.nomeModal = "mdlModulisticaRichiestaFornituraAusili";
                    break;
            }
            me.$refs[me.nomeModal].show();
        },
        onAddScheda() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.rigaSelezionata.path;
            if (me.scheda.id === null) {
                axios
                    .post(link, JSON.stringify(me.scheda))
                    .then(() => {
                        me.$refs[me.nomeModal].hide();
                        this.$bvToast.toast("Salvataggio avvenuto con successo", {
                            title: this.titleModal,
                            variant: "success",
                            autoHideDelay: 1000,
                        });
                        me.loadData();
                    })
                    .catch((error) => {
                        me.showModalLoading = false;
                        this.$bvModal
                            .msgBoxOk(error.response.data.messaggio, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then((value) => {
                                this.boxTwo = value;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
            } else {
                link = link + "/" + me.scheda.idPropostaAccesso;
                axios
                    .put(link, JSON.stringify(me.scheda))
                    .then(() => {
                        me.$refs[me.nomeModal].hide();
                        this.$bvToast.toast("Salvataggio avvenuto con successo", {
                            title: this.titleModal,
                            variant: "success",
                            autoHideDelay: 1000,
                        });
                        me.loadData();
                    })
                    .catch((error) => {
                        me.showModalLoading = false;
                        this.$bvModal
                            .msgBoxOk(error.response.data.messaggio, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then((value) => {
                                this.boxTwo = value;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
            }
        },
        onUpdateScheda(data) {
            let me = this;
            me.scheda = data;
        },
        onVisualizzaPdf(value) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + value.path + "/print/" + me.idPropostaAccesso;
            axios.get(link).then((response) => {
                let printData = response.data.data;
                let linkReport = process.env.VUE_APP_PATH_API + me.pathResourceStampa;
                axios.post(linkReport, printData).then((response) => {
                    me.$refs.pdfPrintViewEmbedded.show();
                    me.signedPdfViewer = "data:application/pdf;base64," + response.data.base64;
                });
            });
        },
    },
};
</script>

<style></style>
