import Graffidi from "../";

const routes = [
	{ name: "GraffidiTest", path: "/graffiditest", component: Graffidi.GraffidiTestPage, meta: { title: "Digita Sign" } },
	{ name: "GraffidiList", path: "/graffidiconfigurazione", component: Graffidi.GraffidiConfigurazioneList, meta: { title: "Digita Sign" } },
	{ name: "GraffidiLogList", path: "/graffidilog", component: Graffidi.GraffidiLogList, meta: { title: "Digita Sign" } },
	{ name: "DigitalSignLogView", path: "/graffidilog/view/:id", component: Graffidi.DigitalSignLogView, meta: { title: "Digita Sign" } },
	{ name: "GraffidiApplicativiList", path: "/graffidiapplicativi", component: Graffidi.GraffidiApplicativiList, meta: { title: "Digita Sign" } },
	{ name: "GraffidiApplicativiEdit", path: "/graffidiapplicativi/edit/:id", component: Graffidi.GraffidiApplicativiEdit, meta: { title: "Digita Sign" } },
	{ name: "GraffidiApplicativiView", path: "/graffidiapplicativi/view/:id", component: Graffidi.GraffidiApplicativiView, meta: { title: "Digita Sign" } },
	{ name: "GraffidiDeviceList", path: "/graffididevice", component: Graffidi.GraffidiDeviceList, meta: { title: "Digita Sign" } },
	{ name: "GraffidiDeviceEdit", path: "/graffididevice/edit/:id", component: Graffidi.GraffidiDeviceEdit, meta: { title: "Digita Sign" } },
	{ name: "GraffidiUserEdit", path: "/graffidiuser/edit/:id", component: Graffidi.DigitalSignUserEdit, meta: { title: "Digita Sign User" } },
];

export default routes;
