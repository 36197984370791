<template>
    <sa-page-layout :btnBackVisible="true" :btnEditVisible="true" :linkedit="linkedit" :linkback="linkback" :pathResource="pathResource" :data="jsonData" :showModalLoading="showModalLoading" class="sa-no-space">
        <template slot="toolbar">
            <print-component :data="jsonData" :linkPrintData="linkPrintData" typeButton="normal"></print-component>
            <protocollo-component v-if="jsonData.statoAttualeProtocollo !== 'SUCCESS' && showBtmProtocollo" :id="id" :pathResource="pathResourceSend" size="md"></protocollo-component>
        </template>
        <template slot="table-body">
            <malattie-infettive-hiv-view-component @showmodalloading="onShowModalLoading" @update="onUpdateJsonData"></malattie-infettive-hiv-view-component>
        </template>
    </sa-page-layout>
</template>
<script>
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import UtilityMixin from "../../../utility/UtilityMixin";
import PrintComponent from "../../../utility/components/PrintComponent.vue";
import MalattieInfettiveHivViewComponent from "../components/MalattieInfettiveHivViewComponent.vue";
import ProtocolloComponent from "../../../protocollo/components/ProtocolloComponent.vue";
import Vue from "vue";
export default {
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    components: { SaPageLayout, MalattieInfettiveHivViewComponent, PrintComponent, ProtocolloComponent },
    mixins: [UtilityMixin],
    data() {
        return {
            linkedit: null,
            linkback: null,
            linkPrintData: "",
            showBtmProtocollo: false,
            pathResource: "/malattieinfettivehiv",
            pathResourceSend: "/malattieinfettivehiv/sendprotocol",
            jsonData: {},
            showModalLoading: false,
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient() {
            return UtilityMixin.methods.getLinkPatient(this.utente, this.idPaziente);
        },
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.linkPrintData = "/malattieinfettivehiv/printer/" + me.id;
        me.linkedit = "/malattieinfettive/hiv/edit/" + me.id;
        me.linkback = "/malattieinfettive/hiv";
        me.impostaPermessi();
    },
    methods: {
        onShowModalLoading(value) {
            let me = this;
            me.showModalLoading = value;
        },
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
        },
        impostaPermessi() {
            let me = this;
            me.showBtmProtocollo = UtilityMixin.methods.verificaPermessi(Vue.prototype.user, "protocol", 2) && UtilityMixin.methods.verificaPermessi(Vue.prototype.user, "malattieinfettivehivprotocol", 2);
        },
    },
};
</script>
