<template>
    <b-modal ref="mdlSchedaEsameObiettivoGenerale" title="Scheda Esami Obiettivi Generali " @hidden="onCloseModal" @ok="onSalvaSchedaEsameObiettivoGenerale" centered no-close-on-backdrop no-close-on-esc hide-header-close size="xl" scrollable @show="onShow">
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <b-card class="sa-card sa-card-border" header="Generali" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Condizioni cliniche generali</label>
                            <b-form-select v-model="jsonData.condizioneClinicaGenerale" :options="condizioneClinicaGeneraleOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Sensorio</label>
                            <b-form-select v-model="jsonData.sensorio" :options="sensorioOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Postura</label>
                            <b-form-select v-model="jsonData.postura" :options="posturaOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Protesi</label>
                            <b-form-select v-model="jsonData.protesi" :options="protesiOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Descrizione Protesi</label>
                            <b-form-input v-model="jsonData.protesiDescrizione" :readonly="isReadOnly"> </b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Peso(kg)</label>
                            <b-form-input v-model="jsonData.peso" :readonly="isReadOnly" type="number" step="0.01"> </b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Altezza(cm)</label>
                            <b-form-input v-model="jsonData.altezza" :readonly="isReadOnly" type="number" step="0.01"> </b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Alvo</label>
                            <b-form-input v-model="jsonData.alvo" :readonly="isReadOnly"> </b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Diuresi</label>
                            <b-form-input v-model="jsonData.diuresi" :readonly="isReadOnly"> </b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Alimentazione</label>
                            <b-form-input v-model="jsonData.alimentazione" :readonly="isReadOnly"> </b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Stato nutrizionale</label>
                            <b-form-select v-model="jsonData.statoNutrizionale" :options="statoNutrizionaleOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Decubito</label>
                            <b-form-select v-model="jsonData.decubito" :options="decubitoOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Visus</label>
                            <b-form-select v-model="jsonData.visus" :options="visusOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Bocca</label>
                            <b-form-select v-model="jsonData.bocca" :options="boccaOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Esame Neurologico</label>
                            <b-form-textarea v-model="jsonData.esameNeurologico" :readonly="isReadOnly" max-rows="10" rows="5"> </b-form-textarea>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Ossa e Articolazioni</label>
                            <b-form-textarea v-model="jsonData.ossaArticolazioni" :readonly="isReadOnly" max-rows="10" rows="5"> </b-form-textarea>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Note</label>
                            <b-form-textarea v-model="jsonData.note" :readonly="isReadOnly" max-rows="10" rows="7"> </b-form-textarea>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <rsa-scheda-esame-obiettivo-generale-sistema-nervoso-edit-component v-model="jsonData" :isReadOnly="isReadOnly" :id-accettazione="idAccettazione" ref="cmpSistemaNervoso" />
                <rsa-scheda-esame-obiettivo-generale-cute-edit-component v-model="jsonData" :isReadOnly="isReadOnly" :id-accettazione="idAccettazione" ref="cmpCute" />
            </b-col>

            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <rsa-scheda-esame-obiettivo-generale-torace-edit-component v-model="jsonData" :isReadOnly="isReadOnly" :id-accettazione="idAccettazione" ref="cmpTorace" />
            </b-col>

            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <rsa-scheda-esame-obiettivo-generale-addome-edit-component-vue v-model="jsonData" :isReadOnly="isReadOnly" :id-accettazione="idAccettazione" ref="cmpAddome" />
            </b-col>

            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <rsa-scheda-esame-obiettivo-generale-polso-radiale-edit-component v-model="jsonData" :isReadOnly="isReadOnly" :id-accettazione="idAccettazione" ref="cmpPolsoRadiale" />
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <rsa-scheda-esame-obiettivo-generale-esame-cardiologico-edit-component v-model="jsonData" :isReadOnly="isReadOnly" :id-accettazione="idAccettazione" ref="cmpEsameCardiologico" />
            </b-col>
        </b-row>
        <template #modal-footer>
            <b-button v-if="!isReadOnly" variant="outline-danger" @click="$refs.mdlSchedaEsameObiettivoGenerale.hide()">Annulla</b-button>
            <b-button v-if="!isReadOnly" variant="outline-success" @click="onSalvaSchedaEsameObiettivoGenerale()">Salva</b-button>
            <b-button v-if="isReadOnly" variant="outline-primary" @click="$refs.mdlSchedaEsameObiettivoGenerale.hide()"> Chiudi </b-button>
        </template>
    </b-modal>
</template>
<script>
import UtilityMixin from "../../../utility/UtilityMixin";
import RsaSchedaEsameObiettivoGeneraleSistemaNervosoEditComponent from "./RsaSchedaEsameObiettivoGeneraleSistemaNervosoEditComponent.vue";
import RsaSchedaEsameObiettivoGeneraleCuteEditComponent from "./RsaSchedaEsameObiettivoGeneraleCuteEditComponent.vue";
import RsaSchedaEsameObiettivoGeneraleToraceEditComponent from "./RsaSchedaEsameObiettivoGeneraleToraceEditComponent.vue";
import RsaSchedaEsameObiettivoGeneraleAddomeEditComponentVue from "./RsaSchedaEsameObiettivoGeneraleAddomeEditComponent.vue";
import RsaSchedaEsameObiettivoGeneraleEsameCardiologicoEditComponent from "./RsaSchedaEsameObiettivoGeneraleEsameCardiologicoEditComponent.vue";

import RsaSchedaEsameObiettivoGeneralePolsoRadialeEditComponent from "./RsaSchedaEsameObiettivoGeneralePolsoRadialeEditComponent.vue";

export default {
    components: {
        RsaSchedaEsameObiettivoGeneraleSistemaNervosoEditComponent,
        RsaSchedaEsameObiettivoGeneraleCuteEditComponent,
        RsaSchedaEsameObiettivoGeneraleToraceEditComponent,
        RsaSchedaEsameObiettivoGeneraleAddomeEditComponentVue,
        RsaSchedaEsameObiettivoGeneralePolsoRadialeEditComponent,
        RsaSchedaEsameObiettivoGeneraleEsameCardiologicoEditComponent,
    },
    mounted() {
        let me = this;
        me.idAccettazione = me.$route.query.idPaziente;
        //me.loadData();
        // console.log("idAccettazione nel mounted di ModalGenerale: " + me.idAccettazione);
    },
    mixins: [UtilityMixin],
    data() {
        return {
            isReadOnly: false,
            pathResourceDizionario: "/rsadizionario",
            pathResourceSchedaEsameObiettivoGenerale: "/rsaschedaesameobiettivogenerale",
            pathResourceCheckSchedaEsameObiettivoGenerale: "/rsaschedaesameobiettivogenerale/check",
            idAccettazione: null,
            jsonData: {
                id: null,
                idAccettazione: null,
                condizioneClinicaGenerale: null,
                sensorio: null,
                postura: null,
                protesi: null,
                protesiDescrizione: null,
                peso: null,
                altezza: null,
                alvo: null,
                diuresi: null,
                alimentazione: null,
                statoNutrizionale: null,
                decubito: null,
                visus: null,
                bocca: null,
                note: null,
                esameNeurologico: null,
                ossaArticolazioni: null,
                //rsaSchedaEsameObiettivoGeneraleSistemaNervosoBean: { idAccettazione: null, statoCoscienzaNelTempo: null },
            },

            condizioneClinicaGeneraleOptions: [
                { text: "SCADUTE", value: "SCADUTE" },
                { text: "MEDIOCRI", value: "MEDIOCRI" },
                { text: "DISCRETE", value: "DISCRETE" },
                { text: "BUONE", value: "BUONE" },
            ],
            sensorioOptions: [
                { text: "INTEGRO", value: "INTEGRO" },
                { text: "SOPOROSO", value: "SOPOROSO" },
                { text: "OBNUBILATO", value: "OBNUBILATO" },
            ],
            posturaOptions: [
                { text: "INDIFFERENTE", value: "INDIFFERENTE" },
                { text: "OBBLIGATA LETTO", value: "OBBLIGATA LETTO" },
                { text: "LETTO/SEDIA", value: "LETTO/SEDIA" },
            ],
            statoNutrizionaleOptions: [
                { text: "ADEGUATO", value: "ADEGUATO" },
                { text: "ALTERATO PER ECCESSO", value: "ALTERATO PER ECCESSO" },
                { text: "ALTERATO PER DIFETTO", value: "ALTERATO PER DIFETTO" },
            ],
            protesiOptions: [
                { text: "SI", value: "SI" },
                { text: "NO", value: "NO" },
                { text: "NON DEFINITO", value: "NON DEFINITO" },
            ],
            decubitoOptions: [
                { text: "INDIFFERENTE", value: "INDIFFERENTE" },
                { text: "LETTO/SEDIA", value: "LETTO/SEDIA" },
                { text: "ALLETTATO", value: "ALLETTATO" },
            ],
            visusOptions: [
                { text: "NORMALE", value: "NORMALE" },
                { text: "RIDOTTO", value: "RIDOTTO" },
            ],
            boccaOptions: [
                { text: "NORMALE", value: "NORMALE" },
                { text: "EDENTULIA TOTALE (PROTESIZZATE O NON PROTESIZZATE)", value: "EDENTULIA TOTALE (PROTESIZZATE O NON PROTESIZZATE)" },
                { text: "EDENTULIA PARZIALE (PROTESIZZATE O NON PROTESIZZATE)", value: "EDENTULIA PARZIALE (PROTESIZZATE O NON PROTESIZZATE)" },
            ],
        };
    },
    methods: {
        show(value, isReadOnly) {
            let me = this;
            me.isReadOnly = isReadOnly;
            me.$refs.mdlSchedaEsameObiettivoGenerale.show();
            value;
        },
        loadData() {
            let me = this;
            // console.log("idAccettazione LoadGeneraleData", me.idAccettazione);

            UtilityMixin.methods.loadDataResources(me.pathResourceSchedaEsameObiettivoGenerale + "/accettazione/" + me.idAccettazione, {}, me.resultResponseSchedaEsameObiettivoGeneraleSuccess, me.resultResponseSchedaEsameObiettivoGeneraleError);
        },

        resultResponseSchedaEsameObiettivoGeneraleSuccess(response) {
            let me = this;
            me.jsonData = response.data.data;
            me.$refs.cmpSistemaNervoso.loadSistemaNervosoData();
            me.$refs.cmpCute.loadCuteData();
            me.$refs.cmpTorace.loadToraceData();
            me.$refs.cmpAddome.loadAddomeData();
            me.$refs.cmpPolsoRadiale.loadPolsoRadialeData();
            me.$refs.cmpEsameCardiologico.loadEsameCardiologicoData();

            // console.log("ID del record Generali", me.jsonData.id);
        },
        resultResponseSchedaEsameObiettivoGeneraleError() {
            let me = this;
            me.resetForm();
            //console.log(me.$refs);
        },
        resetForm() {
            let me = this;
            me.jsonData = {
                id: null,
                idAccettazione: me.idAccettazione,
                condizioneClinicaGenerale: null,
                sensorio: null,
                postura: null,
                protesi: null,
                protesiDescrizione: null,
                peso: null,
                altezza: null,
                alvo: null,
                diuresi: null,
                alimentazione: null,
                statoNutrizionale: null,
                decubito: null,
                visus: null,
                bocca: null,
                note: null,
                esameNeurologico: null,
                ossaArticolazioni: null,
            };
        },

        resultResponseSchedaEsameObiettivoGeneraleViewSuccess(response) {
            let me = this;
            me.jsonData = response.data.data;
            me.$refs.mdlSchedaEsameObiettivoGenerale.show();
            // console.log("ID del record Generali", me.jsonData.id);
        },
        resultResponseSchedaEsameObiettivoGeneraleViewError() {
            let me = this;
            /* console.error("Errore con il record:", error);
            console.log("Dettagli risposta", error.response); */
            // console.log("JsonDataInModalGeneraleViewError", me.jsonData);

            me.$bvToast.toast("Nessun record trovato per l'esame generale questa accettazione", {
                title: "Informazione",
                variant: "info",
                solid: true,
            });

            me.$emit("afterLoadData");
        },

        onSalvaSchedaEsameObiettivoGenerale() {
            let me = this;
            me.jsonData.idAccettazione = me.idAccettazione;
            /* console.log("Salvataggio Generali con dati:", JSON.stringify(me.jsonData)); */
            if (!me.jsonData.idAccettazione) {
                // console.log("idAccettazione: ", me.jsonData.idAccettazione);
                me.$bvToast.toast("Errore: ID Accettazione mancante", {
                    title: "Errore",
                    variant: "danger",
                    solid: true,
                });
                return;
            }
            UtilityMixin.methods.saveResource(me.pathResourceSchedaEsameObiettivoGenerale, me.jsonData, me.saveSchedaEsameObiettivoGeneraleSuccessResponse, me.saveSchedaEsameObiettivoGeneraleErrorResponse);
            me.$refs.cmpSistemaNervoso.onSalvaSistemaNervoso();
            me.$refs.cmpCute.onSalvaCute();
            me.$refs.cmpTorace.onSalvaTorace();
            me.$refs.cmpAddome.onSalvaAddome();
            me.$refs.cmpPolsoRadiale.onSalvaPolsoRadiale();
            me.$refs.cmpEsameCardiologico.onSalvaEsameCardiologico();
        },

        saveSchedaEsameObiettivoGeneraleSuccessResponse() {
            let me = this;
            //console.log("Esame generale salvato con successo:", response);
            me.$emit("afterSaveSuccess");

            me.$refs.mdlSchedaEsameObiettivoGenerale.hide();
            me.$bvToast.toast("Salvataggio avvenuto con successo", {
                title: "Successo",
                variant: "success",
                solid: true,
            });
        },
        saveSchedaEsameObiettivoGeneraleErrorResponse(response) {
            let me = this;
            if (response.response.status === 400) {
                me.$bvModal.msgBoxOk(response.response.data.messaggio, {
                    title: "ATTEZIONE",
                    size: "md",
                    okVariant: "outline-danger",
                    headerClass: "sa-msg-header-danger",
                    footerClass: "p-2 border-top-0",
                    centered: true,
                });
            } else {
                me.$bvToast.toast("Errore nel salvataggio in edit component" + response.message, {
                    title: "Errore",
                    variant: "danger",
                    solid: true,
                });
            }
        },

        onCloseModal() {
            let me = this;
            me.resetForm();
            me.$emit("onCloseModal");
        },
        onShow() {
            // console.log("SHOW");
            let me = this;
            me.loadData();
        },
    },
};
</script>
