<template>
	<sa-page-layout ref="SaplPostiLettoPianiList" :btnConfigurazioniVisible="btnConfigurazioniVisible" :btnNewVisible="btnNewVisible" :btnRefreshVisible="true" @refresh="onRefresh" :pathResource="pathResource" :linkedit="linkedit" :showModalLoading="showModalLoading">
		<template slot="table-filter">
			<b-form @submit.prevent="onSubmit">
				<b-row>
					<b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
						<label class="sa-label-data">Struttura</label>
						<b-form-input v-model="filtro.denominazioneStruttura" type="search" id="nome" placeholder="Nome"></b-form-input>
					</b-col>
					<b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
						<label class="sa-label-data">Presidio</label>
						<b-form-input v-model="filtro.denominazionePresidio" type="search" id="cognome" placeholder="Cognome"></b-form-input>
					</b-col>
					<b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
						<label class="sa-label-data">Piano</label>
						<b-form-input v-model="filtro.nome" type="search" id="identificativo" :placeholder="this.$i18n.t('adi.lblFiscalCode')"></b-form-input>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="sa-padding-right pt-3 pb-3 text-right">
						<b-button type="submit" variant="info">{{ this.$i18n.t("patients.lblFind") }}</b-button>
						<b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("patients.lblResetFilter") }}</b-button>
					</b-col>
				</b-row>
			</b-form>
		</template>
		<template slot="table-body">
			<ras-configurazione-posti-letto-piani-list-component ref="RasConfigurazionePostiLettoPianiListComponent" @errorRestCall="onError" @afterLoadData="onAfterLoadData"></ras-configurazione-posti-letto-piani-list-component>
		</template>
	</sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import RasConfigurazionePostiLettoPianiListComponent from "../components/RasConfigurazionePostiLettoPianiListComponent.vue";
export default {
	components: { SaPageLayout, RasConfigurazionePostiLettoPianiListComponent },
	data() {
		return {
			btnConfigurazioniVisible: true,
			filtro: { denominazioneStruttura: null, denominazionePresidio: null, nome: null },
			btnNewVisible: true,
			pathResource: "",
			linkedit: "/rsaconfigurazionepostilettopiani",
			showModalLoading: false,
			listKey: 0,
		};
	},
	mounted() {
		let me = this;
		me.loadData();
	},
	methods: {
		loadData() {
			let me = this;
			me.showModalLoading = true;
			me.$refs.RasConfigurazionePostiLettoPianiListComponent.loadData();
		},
		onRefresh() {
			let me = this;
			me.loadData();
		},
		onAfterLoadData() {
			let me = this;
			me.showModalLoading = false;
		},
		onReset() {},
		onSubmit() {
			let me = this;
			let sessionStorage = window.sessionStorage;
			sessionStorage["filtro"] = JSON.stringify(me.filtro);
			me.loadData();
		},
		onError(errorData) {
			let me = this;
			me.$refs.SaplPostiLettoPianiList.showModalError(errorData);
		},
	},
};
</script>

<style></style>
