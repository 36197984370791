<template>
    <sa-page-layout :btnAnnullaVisible="true" :btnSaveVisible="true" :linkback="linkback" :data="jsonData" @aftersave="onAfterSave" :showModalLoading="showModalLoading" :pathResource="pathResource" class="sa-no-space">
        <template slot="table-body">
            <rsa-configurazione-aree-edit-component ref="RsaConfigurazioneAreeEditComponent" :showModalLoading="showModalLoading" @update="onUpdateJsonData" @beforeLoadData="onBeforeLoadData" @afterLoadData="onAfterLoadData"></rsa-configurazione-aree-edit-component>
        </template>
    </sa-page-layout>
</template>
<script>
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import RsaConfigurazioneAreeEditComponent from "../components/RsaConfigurazioneAreeEditComponent.vue";

export default {
    components: { SaPageLayout, RsaConfigurazioneAreeEditComponent },
    data() {
        return {
            showModalLoading: false,
            pathResource: "/rsaaree",
            linkback: "/configurazioni/rsaconfigurazionearee",
            jsonData: {},
            id: "-1",
        };
    },
    methods: {
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
        },
        onBeforeLoadData() {
            let me = this;
            me.showModalLoading = true;
        },
        onAfterLoadData() {
            let me = this;
            me.showModalLoading = false;
        },
        onAfterSave() {
            // let me = this;
            // me.id = me.$route.params.id;
            // if (me.id === "-1") {
            //     me.$router.replace(me.linkback).catch((err) => {
            //         console.log(err);
            //     });
            // }
        },
    },
};
</script>

<style></style>
