<template>
    <div class="sa-free-page">
        <div class="sa-free-page-header"></div>
        <div class="sa-free-page-body">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-card class="sa-card" header="Dati Agenda" header-tag="header" footer-tag="footer" title="">
                                <b-row>
                                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                        <label class="sa-label-data">{{ this.$i18n.t("agendas.lblTitle") }}</label>
                                        <b-form-input v-model="jsonData.titolo" :placeholder="this.$i18n.t('agenda.lblEnterTitle')"></b-form-input>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                        <label class="sa-label-data">{{ this.$i18n.t("agenda.lblAgendaIdentifier") }}</label>
                                        <b-form-input v-model="jsonData.identificativo" :placeholder="this.$i18n.t('agenda.lblEnterIdentifier')"></b-form-input>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="5" lg="5" xl="5">
                                        <label class="sa-label-data">{{ this.$i18n.t("agenda.lblDurationAppointment") }}</label>
                                        <b-form-input v-model="jsonData.durataAppuntamento"></b-form-input>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="5" lg="5" xl="5">
                                        <label class="sa-label-data">{{ this.$i18n.t("patient.agenda.lblProprietario") }}</label>
                                        <b-form-input v-model="jsonData.proprietario" :placeholder="this.$i18n.t('agenda.lblEnterOwner')"></b-form-input>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <label class="sa-label-data">{{ this.$i18n.t("agenda.lblExtraTime") }}</label>
                                        <b-form-checkbox v-model="jsonData.extraTime" name="check-button" switch></b-form-checkbox>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                        <label class="sa-label-data">Descrizione</label>
                                        <b-form-textarea class="form-control" v-model="jsonData.descrizione" rows="5" no-resize :placeholder="this.$i18n.t('agenda.lblEnterDescription')"></b-form-textarea>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-card class="sa-card" header="Prestazioni Agenda" header-tag="header" footer-tag="footer" title="">
                                <template #header>
                                    <div style="display: flex">
                                        <span style="flex: 1">Prestazioni Agenda</span>
                                        <b-button size="sm" variant="outline-secondary outline-secondary-noborder pull-right" @click="onClickNuovoPrestazioniAgenda()">
                                            <i class="fas fa-plus"></i>
                                            Aggiungi
                                        </b-button>
                                    </div>
                                </template>
                                <div style="display: flex; flex-direction: row">
                                    <ul style="flex: 1">
                                        <li v-for="(prestazione, index) in jsonData.prestazioni" :key="prestazione.id">
                                            <span>
                                                <b>Codice:</b>
                                                {{ prestazione.codicePrestazione }}
                                                <b>Descrizione:</b>
                                                {{ prestazione.descrizionePrestazione }}
                                                <b>Costo:</b>
                                                {{ prestazione.costo }}
                                                <b>Durata (in min.):</b>
                                                {{ prestazione.durataAppuntamento }}
                                            </span>
                                            <b-button size="sm" variant="outline-danger outline-secondary-noborder pull-right " @click="onClickEliminaPrestazione(index)">
                                                <i class="far fa-trash-alt"></i>
                                            </b-button>
                                        </li>
                                    </ul>
                                </div>
                            </b-card>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <!-- <div class="sa-card-fill-height"> -->
                    <b-card class="sa-card" header="Intervalli" header-tag="header" footer-tag="footer" title="">
                        <template #header>
                            <div style="display: flex">
                                <span style="flex: 1">Intervalli</span>
                                <b-button size="sm" variant="outline-secondary outline-secondary-noborder pull-right" @click="onClickNuovoIntervallo()">
                                    <i class="fas fa-plus"></i>
                                    Aggiungi
                                </b-button>
                            </div>
                        </template>
                        <div v-for="parametro in jsonData.parametri" :key="parametro.id">
                            <div class="sa-utility-edit-toolbar">
                                <span>{{ getDay(parametro.giorno) }}</span>
                                <b-button size="sm" variant="outline-secondary outline-secondary-noborder pull-right " @click="onClickAggiungiFasciaOrario(parametro)">
                                    <i class="fas fa-plus"></i>
                                    Aggiungi Fascia Orario
                                </b-button>
                            </div>
                            <ul>
                                <li v-for="(intervallo, index) in parametro.intervalli" :key="intervallo.id">
                                    <diV class="sa-agenda-intervallo-fascia-oraria">
                                        {{ intervallo.dalleOre }} - {{ intervallo.alleOre }}
                                        <b>Numero accessi:</b>
                                        {{ intervallo.numeroAccessi }}
                                        <b-button size="sm" variant="outline-danger outline-secondary-noborder pull-right " @click="onClickEliminaFasciaOrario(parametro, index)">
                                            <i class="far fa-trash-alt"></i>
                                        </b-button>
                                    </diV>
                                </li>
                            </ul>
                        </div>
                    </b-card>
                    <!-- </div> -->
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <div class="sa-card-fill-height"></div>
                </b-col>
            </b-row>
        </div>
        <div class="sa-free-page-footer">
            <agenda-nuova-data-modal-component ref="cmpAgendaNuovaDataModalComponent" @addIntervallo="addIntervallo"></agenda-nuova-data-modal-component>
            <agenda-nuova-ripartizione-modal-component ref="cmpAgendaNuovaRipartizioneModalComponent" @addFasciaOraria="addFasciaOraria"></agenda-nuova-ripartizione-modal-component>
            <agenda-nuova-prestazione-modal-component ref="cmpAgendaNuovaPrestazioneModalComponent" @addPrestazione="onAddPrestazione"></agenda-nuova-prestazione-modal-component>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import AgendaNuovaDataModalComponent from "./AgendaNuovaDataModalComponent.vue";
import AgendaNuovaRipartizioneModalComponent from "./AgendaNuovaRipartizioneModalComponent.vue";
import AgendaNuovaPrestazioneModalComponent from "./AgendaNuovaPrestazioneModalComponent.vue";
export default {
    components: { AgendaNuovaDataModalComponent, AgendaNuovaRipartizioneModalComponent, AgendaNuovaPrestazioneModalComponent },
    mounted: function () {
        let me = this;
        me.id = this.$route.params.id;
        me.linkedit = "/agende/edit/" + me.id;
        me.loadData();
    },
    data() {
        return {
            pathResource: "/agende",
            showModalLoading: false,
            jsonData: { proprietario: null, titolo: null, descrizione: null, identificativo: "-", tipo: "GENERIC", extraTime: true, durataAppuntamento: 20, parametri: [] },
        };
    },
    methods: {
        getDay(value) {
            let returnValue = "default";

            if (value.length === 1) {
                let localeData = moment().localeData();
                returnValue = localeData.weekdays()[value - 1]; // starts with 'Sonntag' (Sunday) but should be Montag/Monday
            } else if (value !== "default") {
                return value ? moment(new Date(value)).format("DD-MM-YYYY") : "";
            }

            return returnValue; //moment().isoWeekday(value);
        },
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            me.showModalLoading = true;
            if (me.id !== "-1") {
                axios
                    .get(link + me.id)
                    .then((response) => {
                        me.jsonData = response.data.data;
                        me.parametri = response.data.data.parametri;
                        me.intervalli = response.data.data.parametri[0].intervalli;
                        me.$emit("updateAgenda", me.jsonData);
                        me.showModalLoading = false;
                    })
                    .catch(() => {});
            }
        },
        onClickNuovoIntervallo() {
            let me = this;
            me.$refs.cmpAgendaNuovaDataModalComponent.show();
        },
        onClickAggiungiFasciaOrario(parametro) {
            let me = this;
            me.$refs.cmpAgendaNuovaRipartizioneModalComponent.setIntervallo(parametro);
            me.$refs.cmpAgendaNuovaRipartizioneModalComponent.show();
        },
        addIntervallo(intervallo) {
            let me = this;
            intervallo.idAgenda = me.jsonData.id;
            me.jsonData.parametri.push(intervallo);
        },
        addFasciaOraria() {},
        onClickNuovoPrestazioniAgenda() {
            let me = this;
            me.$refs.cmpAgendaNuovaPrestazioneModalComponent.show();
        },
        onClickEliminaFasciaOrario(parametro, index) {
            parametro.intervalli.splice(index, 1);
        },
        onAddPrestazione(prestazione) {
            let me = this;
            prestazione.idAgenda = me.jsonData.id;
            me.jsonData.prestazioni.push(prestazione);
        },
        onClickEliminaPrestazione(index) {
            let me = this;
            me.jsonData.prestazioni.splice(index, 1);
        },
    },
};
</script>

<style></style>
