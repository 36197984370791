<template>
    <sa-page-layout :btnSaveVisible="true" :btnAnnullaVisible="true" :linkback="linkback" :data="jsonData" :showModalLoading="showModalLoading" :pathResource="pathResource" class="sa-no-space">
        <template slot="table-body">
            <cot-stati-edit-component :showModalLoading="showModalLoading" @update="onUpdateJsonData" @beforeLoadData="onBeforeLoadData" @afterLoadData="onAfterLoadData"></cot-stati-edit-component>
        </template>
    </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import CotStatiEditComponent from "../components/CotStatiEditComponent.vue";
export default {
    components: { SaPageLayout, CotStatiEditComponent },
    data() {
        return {
            showModalLoading: false,
            pathResource: "/cotstati",
            linkback: "/cot/stati",
            jsonData: {},
        };
    },
    methods: {
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
        },
        onBeforeLoadData() {},
        onAfterLoadData() {},
    },
};
</script>

<style></style>
