<template>
    <sa-page-layout :btnSaveVisible="true" :data="jsonData" :btnAnnullaVisible="true" :showModalLoading="showModalLoading" :linkback="linkback" :pathResource="pathResource" class="sa-no-space">
        <template slot="toolbar"></template>
        <template slot="table-body">
            <svei-configurazione-punti-incontro-edit-component @update="onUpdateJsonData"></svei-configurazione-punti-incontro-edit-component>
        </template>
    </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import SveiConfigurazionePuntiIncontroEditComponent from "../components/SveiConfigurazionePuntiIncontroEditComponent.vue";
export default {
    components: { SaPageLayout, SveiConfigurazionePuntiIncontroEditComponent },
    data() {
        return {
            id: "-1",
            pathResource: "/sveipuntiincontro",
            linkback: "/sveipuntiincontro",
            showModalLoading: false,
            jsonData: {},
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
    },
    methods: {
        onUpdateJsonData(data) {
            // console.log("OK");
            let me = this;
            me.jsonData = data;
        },
    },
};
</script>

<style></style>
