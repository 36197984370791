import GraffidiConfigurazioneList from "../digitalsign/pages/GraffidiConfigurazioneList";
import GraffidiLogList from "../digitalsign/pages/GraffidiLogList";

import DigitalSignView from "../digitalsign/pages/DigitalSignView";
import GraffidiTestPage from "../digitalsign/pages/GraffidiTestPage";
import DigitalSignLogView from "../digitalsign/pages/DigitalSignLogView";
import DigitalSignUserEdit from "../digitalsign/pages/DigitalSignUserEdit";

import GraffidiApplicativiList from "../digitalsign/pages/GraffidiApplicativiList";
import GraffidiApplicativiEdit from "../digitalsign/pages/GraffidiApplicativiEdit";
import GraffidiApplicativiView from "../digitalsign/pages/GraffidiApplicativiView";

import GraffidiDeviceList from "../digitalsign/pages/GraffidiDeviceList";
import GraffidiDeviceEdit from "../digitalsign/pages/GraffidiDeviceEdit";
export default {
	GraffidiConfigurazioneList,
	GraffidiLogList,
	DigitalSignView,
	GraffidiTestPage,
	DigitalSignLogView,
	DigitalSignUserEdit,
	GraffidiApplicativiList,
	GraffidiApplicativiEdit,
	GraffidiApplicativiView,
	GraffidiDeviceList,
	GraffidiDeviceEdit,
};
