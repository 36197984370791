import Vue from "vue";
import VueRouter from "vue-router";
import Pages from "../app/pages/";
import Paziente from "../app/paziente";
import axios from "axios";
import MainRouter from "../app/pages/router";
import NotFound from "../app/pages/components/NotFound.vue";
import $i18n from "../i18n";
import ProfiloRouter from "../app/profilo/router";
import LeonidaRouter from "../app/leonida/router";
import StudioMedicoRouter from "../app/studiomedico/router";
import TestRouter from "../app/test/router";
import UtentiRouter from "../app/utenti/router";
import AgendaRouter from "../app/agende/router";
import AmbulatoriRouter from "../app/ambulatori/router";
import PazientiRouter from "../app/pazienti/router";
import PazienteRouter from "../app/paziente/router";
import PazienteEventiConfigurazioneRouter from "../app/paziente/eventi/configurazione/router";
import PazienteMisurazioniRouter from "../app/paziente/misurazioni/router";
import StruttureRouter from "../app/strutture/router";
import FatturazioneRouter from "../app/fatturazione/router";
import PrintServerRouter from "../app/printserver/router";
import CaduceoRouter from "../app/caduceo/router";
import ConfigurazioneRouter from "../app/configurazione/router";
import HassistoIntegratorRouter from "../app/hassistointegrator/router";
import RencamRouter from "../app/rencam/router";
import AdiRouter from "../app/adi/router";
import MalattieInfettiveRouter from "../app/malattieinfettive/router";
import MalattieInfettiveEmailRouter from "../app/malattieinfettive/emaildestinatari/router";
import MalattieInfettiveStruttureRouter from "../app/malattieinfettive/strutture/router";
import EmailNotificationRouter from "../app/emailnotification/router";
import PazienteGastroRouter from "../app/paziente/eventi/gastroenterologia/router";
import MedicinaDelloSportRouter from "../app/paziente/eventi/medicinadellosport/router";
import CardiologiaRouter from "../app/paziente/eventi/cardiologia/router";
import DispositiviRouter from "../app/paziente/dispositivi/router";
import AnalisiRischiRouter from "../app/paziente/analisirischi/router";
import TeleconsultoRouter from "../app/paziente/teleconsulto/router";
import TerapiaRouter from "../app/paziente/terapia/router";
import PropostaTerapeuticaRouter from "../app/paziente/propostaterapeutica/router";
import WincareOldRouter from "../app/wincareold/router";
import PrenotazioniCupRouter from "../app/prenotazionicup/router";
import Hl7ServerIntegrator from "../app/utility/hl7integrator/router";
import ExternalSync from "../app/utility/externalsync/router";
import CaduceoLapManagementRouter from "../app/caduceolapmanagement/router";
import PazienteWincareOldRouter from "../app/paziente/wincareold/router";
import LogsRouter from "../app/utility/logs/router";
import SystemInfoRouter from "../app/systeminfo/router";
import PazienteEventiRouter from "../app/pazienteeventi/router";
import DigitalSignRouter from "../app/digitalsign/router";
import Misurazioni from "../app/misurazioni/router";
import GestioneAttivitaRouter from "../app/gestioneattivita/router";
import RiepilogoEventi from "../app/paziente/eventi/riepilogoeventi/router";
import RiepilogoPrestazioniAggiuntive from "../app/paziente/eventi/riepilogoprestazioniaggiuntive/router";
import AnalisiRischiThRouter from "../app/paziente/analisirischith/router";
import AllegatiRouter from "../app/paziente/allegati/router";
import VisitaRouter from "../app/paziente/eventi/visita/router";
import SchedaValutazioneEsigenzeImmediateRouter from "../app/svei/router";
import QueryBuilderRouter from "../app/querybuilder/router";
import AccettazioneRouter from "../app/accettazione/router";
import AccettazioneAbruzzoRouter from "../app/accettazione/abruzzo/router";
import DataAnalysisRouter from "../app/dataanalysis/router";
import EsamiRouter from "../app/paziente/eventi/esami/router";
import GedocRouter from "../app/gedocnotification/router";
import BancaEmaticaRouter from "../app/bancaematica/router";
import PazienteDermoRouter from "../app/paziente/eventi/dermatologia/router";
import PazientePublipeasRouter from "../app/paziente/eventi/publipeas/router";
import TaskManagerRouter from "../app/taskmanager/router";
import ProtocolloRouter from "../app/protocollo/router";
import PrintReportRouter from "../app/printreport/router";
import IdentityRouter from "../app/identity/router";
import CotRouter from "../app/cot/router";
import ElaborazioniBeatchRouter from "../app/utility/elaborazionibatch/router";
import SendMailRouter from "../app/utility/sendmail/router";
import PazienteAccettazioneRouter from "../app/accettazione/pazienteaccettazione/router";
import RsaRouter from "../app/rsa/router";
import StrutturePresidiRouter from "../app/strutture/strutturepresidi/router";
import DocumentRepositoryRouter from "../app/documentrepository/router";
import AnamnesiRouter from "../app/paziente/eventi/anamnesigenerica/router";
import GiubileoRouter from "../app/giubileo/router";
import MediciRouter from "../app/paziente/medici/router";
import NumeratoriRouter from "../app/numeratori/router";
import LogsSpidRouter from "../app/utility/logsSpid/router";
import InstallationRouter from "../app/installation/router";
import ConfigurazioniRouter from "../app/configurazioni/router";
import CaseificioRouter from "../app/caseificio/router";
import IntegrazioneBusiness from "../app/integrazionebusiness/router";
import PapercleanRouter from "../app/paperclean/router";

/*RSA*/
import RasPaziente from "../app/rsa/rsapaziente";
import RsaPazienteRouter from "../app/rsa/rsapaziente/router";
Vue.use(VueRouter);
// console.log("File Router");
// console.log(location);
// console.log(location.href);
const urlParams = new URLSearchParams(window.location.search);
const spidCode = urlParams.get("code");
// console.log(spidCode);
if (spidCode) {
	axios.get(process.env.VUE_APP_PATH_API + "/spidlogin", { params: { code: spidCode } })
		.then((baseresponse) => {
			// console.log(baseresponse);
			// let link = "http://localhost:8081/#/?language=it&authToken=" + baseresponse.data;
			// console.log(window.location);
			// console.log(link);
			window.location.replace(window.location.origin + "/#/?language=it&authToken=" + baseresponse.data.data);
		})
		.catch(() => {
			//location.replace(location.origin + "?test=OK");
		});
}

let patientMain = [];
let mainChildren = MainRouter.concat(
	ProfiloRouter,
	LeonidaRouter,
	StudioMedicoRouter,
	TestRouter,
	UtentiRouter,
	AgendaRouter,
	AmbulatoriRouter,
	PazientiRouter,
	PazienteEventiConfigurazioneRouter,
	StruttureRouter,
	FatturazioneRouter,
	PrintServerRouter,
	CaduceoRouter,
	HassistoIntegratorRouter,
	ConfigurazioneRouter,
	RencamRouter,
	AdiRouter,
	MalattieInfettiveRouter,
	WincareOldRouter,
	PrenotazioniCupRouter,
	Hl7ServerIntegrator,
	ExternalSync,
	CaduceoLapManagementRouter,
	LogsRouter,
	SystemInfoRouter,
	PazienteEventiRouter,
	DigitalSignRouter,
	Misurazioni,
	GestioneAttivitaRouter,
	RiepilogoEventi,
	RiepilogoPrestazioniAggiuntive,
	MalattieInfettiveEmailRouter,
	MalattieInfettiveStruttureRouter,
	EmailNotificationRouter,
	SchedaValutazioneEsigenzeImmediateRouter,
	QueryBuilderRouter,
	AccettazioneRouter,
	AccettazioneAbruzzoRouter,
	DataAnalysisRouter,
	GedocRouter,
	BancaEmaticaRouter,
	TaskManagerRouter,
	ProtocolloRouter,
	PrintReportRouter,
	IdentityRouter,
	CotRouter,
	ElaborazioniBeatchRouter,
	SendMailRouter,
	PazienteAccettazioneRouter,
	RsaRouter,
	StrutturePresidiRouter,
	DocumentRepositoryRouter,
	GiubileoRouter,
	MediciRouter,
	NumeratoriRouter,
	LogsSpidRouter,
	InstallationRouter,
	ConfigurazioniRouter,
	CaseificioRouter,
	IntegrazioneBusiness,
	PapercleanRouter
);

let patientChildren = patientMain.concat(
	PazienteRouter,
	DispositiviRouter,
	AnalisiRischiRouter,
	PazienteMisurazioniRouter,
	TeleconsultoRouter,
	TerapiaRouter,
	PazienteGastroRouter,
	MedicinaDelloSportRouter,
	CardiologiaRouter,
	PropostaTerapeuticaRouter,
	PazienteWincareOldRouter,
	AnalisiRischiThRouter,
	AllegatiRouter,
	VisitaRouter,
	EsamiRouter,
	PazienteDermoRouter,
	PazientePublipeasRouter,
	AnamnesiRouter
);
let rsaPatientChildren = patientMain.concat(RsaPazienteRouter);

patientChildren.push({ path: "/paziente/*", component: NotFound });
rsaPatientChildren.push({ path: "/rsapaziente/*", component: NotFound });

mainChildren.push({
	path: "*",
	component: NotFound,
});

const routes = [
	{
		name: "Login",
		path: "/login",
		component: Pages.Login,
		hidden: true,
	},
	{
		name: "Paziente",
		path: "/paziente",
		component: Paziente.Paziente,
		children: patientChildren,
	},
	{
		name: "RSA Paziente",
		path: "/rsapaziente",
		component: RasPaziente.RsaPazienteMainPage,
		children: rsaPatientChildren,
	},
	{
		path: "/",
		component: Pages.Main,
		children: mainChildren,
	},
	{
		path: "*",
		redirect: {
			name: "Login",
		},
	},
];

const router = new VueRouter({
	routes,
});

router.beforeEach((to, from, next) => {
	axios.defaults.params = { language: to.query.language };
	if (to.query.language) {
		$i18n.locale = to.query.language;
	}

	if (to.query.language === "it") {
		import("vue2-datepicker/locale/it");
	} else if (to.query.language === "tr") {
		import("vue2-datepicker/locale/tr");
	} else {
		import("vue2-datepicker/locale/en");
	}

	if (axios.defaults.params.language === undefined || axios.defaults.params.language === null) {
		axios.defaults.params = { language: "it" };
	}

	let loginData = {
		access_token: to.query.authToken,
	};
	if (!to.query.authToken) {
		loginData = {
			access_token: from.query.authToken,
		};
	}

	let accessToken = loginData == null ? undefined : loginData.access_token;

	if (to.path == "/login") {
		next();
	} else if (to.path == "/installation") {
		next();
	} else if (!to.query.authToken && accessToken) {
		if (to.path.includes("/paziente")) {
			if (from.query.idPaziente) {
				if (!to.path.includes("/paziente/edit") && !to.path.includes("/paziente/view")) {
					to.query.idPaziente = from.query.idPaziente;
				}
			}
		}
		to.query.language = $i18n.locale;
		to.query.authToken = accessToken;
		if (to.path === from.path && JSON.stringify(to.query) === JSON.stringify(from.query)) {
			return;
		}
		router.push({ path: to.path, query: to.query });
	} else {
		axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
		axios.defaults.headers["Content-Type"] = "application/json";
		//        axios.defaults.params = { language: $i18n.locale };
		if (to.name == "Login") {
			next();
		} else if (loginData === null || accessToken === undefined) {
			next("/login");
		} else {
			let link = process.env.VUE_APP_PATH_API + "/validate";
			axios.get(link)
				.then(() => {
					if (document.body.classList.contains("sa-body-sidebar-open")) {
						document.body.classList.remove("sa-body-sidebar-open");
					}
					next();
				})
				.catch(() => {
					localStorage.clear();
					next("Login");
				});
		}
	}
});
export default router;
