<template>
    <div>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Tipo Caso</label>
                    <span class="sa-data">{{ jsonData.tipoCaso }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Data Inizio Sintomi</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataInizioSintomi) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Ricovero Ospedaliero</label>
                    <span class="sa-data">{{ jsonData.ricoveroOspedaliero }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Nome dell' Ospedale</label>
                    <span class="sa-data">{{ jsonData.nomeOspedale }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Data di Ricovero</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataRicovero) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Data di Dimissione</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataDimissione) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Diagnosi Clinica</label>
                    <span class="sa-data">{{ jsonData.diagnosiClinica }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Diagnosi Parassitologica su aspirato di midollo osseo,milza,fegato,linfonodi o sangue</label>
                    <span class="sa-data">{{ jsonData.diagnosiParassitologica }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Diagnosi Sierologica</label>
                    <span class="sa-data">{{ jsonData.diagnosiSierologica }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Metodica (IFA, ELISA, DAT ecc…)</label>
                    <span class="sa-data">{{ jsonData.diagnosiSierologicaMetodica }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Esecuzione Diagnosi Sierologica</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataEsecuzioneDiagnosiSierologica) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Vive in una casa con giardino?</label>
                    <span class="sa-data">{{ jsonData.casaGiardino }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Le finestre dell'abitazione sono dotate di reti a maglie fitte?</label>
                    <span class="sa-data">{{ jsonData.finestreReteMaglie }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Utilizza repellenti chimici individuali o ambientali contro gli insetti?</label>
                    <span class="sa-data">{{ jsonData.utilizzoRepellentiInsetti }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Frequentaì Parchi/giardini/aree verdi/campi agricoli?</label>
                    <span class="sa-data">{{ jsonData.frequentazioneParchi }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-table sticky-header stacked="xl" striped hover itemscope :items="jsonData.listaUbicazioneParchi" :fields="fieldsUbicazioneParchi" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="row">
                            {{ row.index + 1 }}
                        </template>
                    </b-table>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Nel corso dell'ultimo anno ha soggiornato in localita diverse da quella di residenza?</label>
                    <span class="sa-data">{{ jsonData.soggiornoDiversoResidenza }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-table sticky-header stacked="xl" striped hover itemscope :items="jsonData.listaUbicazioneSoggiorno" :fields="fieldsUbicazioneSoggiorno" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="row">
                            {{ row.index + 1 }}
                        </template>
                    </b-table>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Possiede Cane/i nel proprio ambiente domestico?</label>
                    <span class="sa-data">{{ jsonData.possessoCane }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Viene sottoposto a periodici trattamenti antiparassitari?</label>
                    <span class="sa-data">{{ jsonData.trattamentiAntiparassitari }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Dorme/dormono all'aperto?</label>
                    <span class="sa-data">{{ jsonData.dormeAperto }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Ha/hanno un ricovero all'aperto?</label>
                    <span class="sa-data">{{ jsonData.ricoveroAperto }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Altre Considerazioni</label>
                    <span class="sa-data">{{ jsonData.altreConsiderazioni }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Sanitario Notificante</label>
                    <span class="sa-data">{{ jsonData.sanitarioNotificante }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Contatto Sanitario Notificante</label>
                    <span class="sa-data">{{ jsonData.contattoSanitarioNotificante }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Notifica</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataNotifica) }}</span>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
<script>
import axios from "axios";
import UtilityMixin from "../../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixin],
    data() {
        return {
            pathResource: "/malattieinfettiveieleishmaniosiviscerale",
            id: "-1",
            perPage: 100,
            currentPage: 1,
            jsonData: {
                tipoCaso: "",
                dataInizioSintomi: null,
                ricoveroOspedaliero: "",
                nomeOspedale: "",
                dataRicovero: null,
                dataDimissione: null,
                diagnosiClinica: "",
                diagnosiParassitologica: "",
                diagnosiSierologica: "",
                diagnosiSierologicaMetodica: "",
                dataEsecuzioneDiagnosiSierologica: null,
                casaGiardino: "",
                finestreReteMaglie: "",
                utilizzoRepellentiInsetti: "",
                frequentazioneParchi: "",
                soggiornoDiversoResidenza: "",
                possessoCane: "",
                trattamentiAntiparassitari: "",
                dormeAperto: "",
                ricoveroAperto: "",
                sanitarioNotificante: "",
                contattoSanitarioNotificante: "",
                dataNotifica: null,
                listaUbicazioneParchi: [],
                listaUbicazioneSoggiorno: [],
            },
            fieldsUbicazioneParchi: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Ubicazione",
                    key: "ubicazione",
                    sortable: false,
                },
                {
                    label: "",
                    key: "actions",
                    sortable: false,
                    thStyle: "width: 4rem",
                },
            ],
            fieldsUbicazioneSoggiorno: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Ubicazione",
                    key: "ubicazione",
                    sortable: false,
                },
                {
                    label: "",
                    key: "actions",
                    sortable: false,
                    thStyle: "width: 4rem",
                },
            ],
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.$emit("showmodalloading", true);
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            axios
                .get(link + me.id)
                .then((response) => {
                    me.jsonData = response.data.data;
                    me.$emit("showmodalloading", false);
                })
                .catch(() => {
                    me.$emit("showmodalloading", false);
                });
        },
    },
};
</script>
