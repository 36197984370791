<template>
    <div class="sa-tab-scroll">
        <b-card v-if="listaFrequenzaRespiratoria.length > 0" class="sa-card sa-card-border" header="FREQUENZA RESPIRATORIA" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaFrequenzaRespiratoria" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header"> </b-table>
        </b-card>
        <b-card v-if="listaFrequenzaCardiaca.length > 0" class="sa-card sa-card-border" header="FREQUENZA CARDIACA" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaFrequenzaCardiaca" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header"> </b-table>
        </b-card>
        <b-card v-if="listaPressioneArteriosaSistolica.length > 0" class="sa-card sa-card-border" header="PRESSIONE ARTERIOSA SISTOLICA" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaPressioneArteriosaSistolica" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header"> </b-table>
        </b-card>
        <b-card v-if="listaLivelloCoscienza.length > 0" class="sa-card sa-card-border" header="LIVELLO COSCIENZA" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaLivelloCoscienza" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header"> </b-table>
        </b-card>
        <b-card v-if="listaTemperaturaCorporea.length > 0" class="sa-card sa-card-border" header="TEMPERATURA CORPOREA" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaTemperaturaCorporea" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header"> </b-table>
        </b-card>
        <b-card class="sa-card sa-card-border" header="Totale" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <span class="sa-label-data">{{ totale }}</span>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import axios from "axios";
export default {
    components: {},
    // props: {
    //   utente: {
    //     type: Object,
    //     default: function () {
    //       return null;
    //     },
    //   },
    // },
    data() {
        return {
            idAccettazione: null,
            keyPropostaAccesso: 0,
            linkback: "",
            linkPrintData: "",
            childKey: 0,
            viewPai: true,
            pathResource: "/rsascalamewsrichieste",
            totale: 0,
            jsonData: [],
            listaFrequenzaRespiratoria: [],
            listaFrequenzaCardiaca: [],
            listaPressioneArteriosaSistolica: [],
            listaLivelloCoscienza: [],
            listaTemperaturaCorporea: [],
            fieldsListe: [
                {
                    label: "Descrizione",
                    key: "fattore",
                    sortable: true,
                },
            ],
        };
    },
    mounted() {
        let me = this;
        if (this.$route.params.id) {
            me.idAccettazione = this.$route.params.id;
        } else {
            me.idAccettazione = this.$route.query.idPaziente;
        }
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/richiesta/" + me.idAccettazione;
            axios
                .get(link)
                .then((response) => {
                    me.jsonData = response.data.data.list;
                    me.calcolaPunteggio();
                    me.jsonData.forEach((element) => {
                        if (element.check) {
                            me.jsonData.push({ idRichiesta: me.idAccettazione, idScalaMews: element.id });
                            switch (element.gruppo) {
                                case "FREQUENZA RESPIRATORIA":
                                    me.listaFrequenzaRespiratoria.push(element);
                                    break;
                                case "FREQUENZA CARDIACA":
                                    me.listaFrequenzaCardiaca.push(element);
                                    break;
                                case "PRESSIONE ARTERIOSA SISTOLICA":
                                    me.listaPressioneArteriosaSistolica.push(element);
                                    break;
                                case "LIVELLO COSCIENZA":
                                    me.listaLivelloCoscienza.push(element);
                                    break;
                                case "TEMPERATURA CORPOREA":
                                    me.listaTemperaturaCorporea.push(element);
                                    break;
                            }
                        }
                    });
                })
                .catch(() => {});
        },
        calcolaPunteggio() {
            let me = this;
            me.totale = 0;
            me.jsonData.forEach((element) => {
                if (element.check) {
                    let p = element.punteggio >= 0 ? element.punteggio : element.punteggio * -1;
                    me.totale = me.totale + p;
                }
            });
        },
    },
};
</script>

<style></style>
