<template>
    <sa-page-layout :btnNewVisible="false" :btnRefreshVisible="true" @refresh="onRefresh" :pathResource="pathResource" :linkedit="linkedit" :showModalLoading="showModalLoading">
        <template slot="table-filter">
            <b-form @submit.prevent="onSubmit">
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-label-data">Sezione:</label>
                        <b-form-select v-model="filtro.sezioneDizionario" :options="sezioniOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-label-data">Codice:</label>
                        <b-form-input v-model="filtro.codiceTipo" type="search" placeholder="Codice"> </b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-label-data">Descrizione:</label>
                        <b-form-input v-model="filtro.descrizioneTipo" type="search" placeholder="Descrizione"> </b-form-input>
                    </b-col>
                </b-row>
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="sa-padding-right text-right">
                        <b-button type="submit" variant="info">{{ this.$i18n.t("global.lblFind") }}</b-button>
                        <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("global.lblResetFilter") }}</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </template>

        <template slot="table-body">
            <paperclean-configurazione-dizionari-list-component ref="PapercleanConfigurazioneDizionariListComponent" @beforeLoadData="onBeforeLoadData" @afterLoadData="onAfterLoadData"> </paperclean-configurazione-dizionari-list-component>
        </template>
    </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import UtilityMixin from "../../utility/UtilityMixin";
import PapercleanConfigurazioneDizionariListComponent from "../components/PapercleanConfigurazioneDizionariListComponent.vue";

export default {
    name: "PapercleanConfigurazioneDizionariListPage",
    mixins: [UtilityMixin],
    components: { SaPageLayout, PapercleanConfigurazioneDizionariListComponent },
    data() {
        return {
            filtro: {
                sezioneDizionario: "",
                codiceTipo: "",
                descrizioneTipo: "",
            },
            btnNewVisible: true,
            btnBackVisible: true,
            linkback: "/home",
            pathResource: "/dizionariotipi",
            pathResourceSezioni: "/dizionariosezioni",
            linkedit: "/numeratori",
            showModalLoading: false,
            sezioniOptions: [{ value: "", text: "-Seleziona Valore-" }],
            listKey: 0,
        };
    },
    mounted() {
        this.loadData();
        this.loadSezioni();
    },
    methods: {
        loadData() {
            this.showModalLoading = true;
            this.$refs.PapercleanConfigurazioneDizionariListComponent.loadData(this.filtro);
        },
        loadSezioni() {
            let me = this;
            let filtro = {};
            UtilityMixin.methods.loadDataResources(me.pathResourceSezioni, filtro, me.loadDataSuccess, me.loadDataError);
            // this.showModalLoading = true;
        },

        loadDataSuccess(response) {
            let me = this;
            me.sezioniOptions = [];
            response.data.data.list.forEach((element) => {
                me.sezioniOptions.push({ text: element.sezione, value: element.sezione });
            });
            me.sezioniOptions.unshift({ value: "", text: "-Seleziona Valore-" });
        },
        loadDataError(response) {
            console.log(response);
        },
        onRefresh() {
            this.loadData();
        },

        onAfterLoadData() {
            this.showModalLoading = false;
        },

        onBeforeLoadData() {
            this.showModalLoading = true;
        },

        onReset() {
            this.filtro = {
                sezioneDizionario: "",
                codiceTipo: "",
                descrizioneTipo: "",
            };
            this.loadData();
        },

        onSubmit() {
            this.loadData();
        },
    },
    watch: {
        // filtro: {
        //     handler(newVal) {
        //         this.$refs.NumeratoriListComponent.filtro = newVal;
        //     },
        //     deep: true,
        // },
    },
};
</script>

<style scoped>
.sa-padding-right {
    padding-right: 1rem;
}

b-button + b-button {
    margin-left: 0.5rem;
}
</style>
