<template>
    <sa-page-layout :backVisible="true" :data="jsonData" :pathResource="pathResource" :linkback="linkback" :saveVisible="true" :showModalLoading="showModalLoading">
        <template slot="body">
            <div>
                <div class="sa-form-section">
                    <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Codice</span>
                            <b-form-input v-model="jsonData.codice" placeholder="Codice"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Descrizione</span>
                            <b-form-input v-model="jsonData.descrizione" placeholder="Descrizione"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <span class="sa-form-label">Valore</span>
                            <b-form-input v-model="jsonData.valore" placeholder="Valore" type="number"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                        <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                            <span class="sa-form-label">Anno</span>
                            <b-form-input v-model="jsonData.anno" placeholder="Anno" type="number"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                            <span class="sa-form-label">Codice Struttura</span>
                            <b-form-input v-model="jsonData.codiceStruttura" placeholder="Codice Struttura"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-form-textarea v-model="jsonData.note" placeholder="" rows="3" max-rows="6" no-resize></b-form-textarea>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
    components: { SaPageLayout },
    data() {
        return {
            pathResource: "/numeratori",
            id: "",
            showModalLoading: false,
            linkback: null,
            jsonData: { codice: "", descrizione: "", anno: null, codiceStruttura: "", valore: null, note: "" },
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        if (me.id === "-1") {
            me.linkback = "/fatturazionenumeratori";
        } else {
            me.linkback = "/fatturazionenumeratori/view/" + me.id;
        }
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                me.showModalLoading = true;
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                axios.get(link + me.id).then((response) => {
                    me.jsonData = response.data.data;
                    me.showModalLoading = false;
                });
            }
        },
    },
};
</script>
