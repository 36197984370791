<template>
  <sa-page-layout :btnRefreshVisible="btnRefreshVisible" :btnNewVisible="btnNewVisible" @refresh="onRefresh" :linkedit="linkedit">
    <!--<template slot="toolbar">
            <b-button class="float-sm-right " variant="success btn-toolbar sa-margin-right" size="sm" @click="onAdd"><b-icon icon="plus"></b-icon>{{ this.$i18n.t("global.lblBtnNew") }}</b-button>
            <b-button class="float-sm-right " variant="info btn-toolbar" size="sm" @click="onRefresh" v-b-modal.modal-center><b-icon icon="arrow-clockwise" />Refresh</b-button>
        </template>-->
    <template slot="table-filter">
      <b-form @submit.prevent="onSubmit">
        <b-row v-if="superAdmin">
          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data">Cliente</label>
            <b-form-select v-model="filtroLista.idCliente" class="mb-2 mr-sm-2 mb-sm-0" :options="clientiOptions" value-field="id" text-field="descrizione" :value="null" @input="loadDestinazioni()"></b-form-select>
          </b-col>
        </b-row>
        <b-row>
          <!-- <b-row> -->
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-label-data">Data Dal: </label>
            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="filtroLista.dataDal" type="date" placeholder="Data Dal"></date-picker>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-label-data">Data Al: </label>
            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="filtroLista.dataAl" type="date" placeholder="Data Al"></date-picker>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-label-data">Destinazione: </label>
            <b-form-select v-model="filtroLista.destinazione" class="mb-2 mr-sm-2 mb-sm-0" :options="destinazioneOption" :value="null">
            </b-form-select>
          </b-col>
          <!-- <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">Numero Ordine:</label>
            <b-form-input v-model="filtroLista.numero" placeholder="Numero Ordine"></b-form-input>
          </b-col> -->
        </b-row>
        <b-row style="padding-top: 5px; padding-bottom: 5px">
          <b-col class="sa-padding-right text-right">
            <b-button type="submit" variant="info" style="margin-right: 5px">Cerca</b-button>
            <b-button type="reset" v-on:click="onReset" variant="danger">Resetta Filtro</b-button>
          </b-col>
        </b-row>
      </b-form>
    </template>
    <template slot="table-header">
      <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>Totale Record: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
    <template slot="table-body">
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="table" :filter="filter" stacked="xl" striped hover :items="jsonData" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
          <template v-slot:cell(numero)="{ item }">
            <router-link class="sa-edit-link" :to="'/fatturazione/view/' + item.id">{{ formatDateTime(item.createDate) }}</router-link>
          </template>
          <template v-slot:cell(cognomeRagioneSoggetto)="{ item }">
            {{ item.cognomeRagioneSoggetto }} {{ item.nomeSoggetto }}
          </template>
          <template #cell(actions)="row">
            <b-button-group>
              <b-button size="sm" variant="btn btn-outline-purple waves-effect waves-light width-sm no-text" style="margin-right: 5px">
                <router-link class="sa-edit-link" :to="linkedit + '/edit/-1' + '?idOrdine=' + row.item.id"><i class="bi bi-bag-plus"></i></router-link>
              </b-button>
              <!-- <b-button size="sm" variant="btn btn-outline-info no-text" @click="onDownload(row.item)">
                                <b-icon icon="download"></b-icon>
                            </b-button> -->
            </b-button-group>
          </template>
        </b-table>
      </div>
    </template>
    <template slot="table-footer">
      <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>Totale Record: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
      <b-modal ref="mdlStampaReferto" size="xl" style="height: 100%">
        <b-embed type="iframe" :src="stampaReport" allowfullscreen></b-embed>
      </b-modal>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import UtilityMixins from "../../utility/UtilityMixin";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
  // props: {
  //   utente: {
  //     type: Object,
  //     default: function () {
  //       let me = this;
  //       me.superAdmin = false;
  //       if (me.utente.gruppo.descrizione === 'Cliente') {
  //         me.filtroLista.idCliente = me.utente.id;
  //       } else {
  //         me.superAdmin = true;
  //       }
  //       me.loadData();
  //     },
  //   },
  // },
  components: { SaPageLayout, DatePicker },
  mixins: [UtilityMixins],
  computed: {
    // rows() {
    //     return this.items.length;
    // },
  },
  watch: {
    utente() {
      let me = this;
      me.superAdmin = false;
      // console.log("sono qui", me.utente.gruppo.nome);
      if (me.utente.gruppo.nome === 'Clienti') {
        // console.log("sono qui1", me.utente.gruppo.nome);
        me.filtroLista.idCliente = me.utente.id;
        me.loadDestinazioni();
      } else {
        // console.log("sono qui2", me.utente.gruppo.nome);
        me.superAdmin = true;
        me.loadClienti();
      }
      me.loadData();
    },
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      filter: null,
      btnRefreshVisible: true,
      rows: null,
      btnNewVisible: true,
      linkedit: "/fatturazione",
      pathResource: "/ordini",
      pathResourceStampa: "/print/createreport",
      clientiOptions: [],
      utente: {},
      superAdmin: false,
      stampaReport: null,
      filtroLista: {
        dataDal: null,
        dataAl: null,
        destinazione: null,
        numero: null,
      },
      reportName: "fatturazioneCatoMaior",
      linkFatturaElettronica: "/documenti/fatturaelettronica",
      filtro: {},
      filterOn: [],
      jsonData: [],
      destinazioneOption: [],
      fields: [
        {
          label: "Effettuato il",
          key: "numero",
          sortable: true,
        },
        {
          label: "Data Ordine",
          key: "dataOrdine",
          sortable: true,
          formatter: (value) => {
            return moment(new Date(value)).format("DD-MM-YYYY");
          },
        },
        {
          label: "Destinazione",
          key: "destinazioneDescrizine",
          sortable: true,
        },
        {
          label: "",
          key: "actions",
          sortable: true,
        },
      ],
      items: [],
    };
  },
  // created: function () { },
  mounted() {
    let me = this;
    me.loadUserData();
    // me.loadData();
  },
  methods: {
    loadUserData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/users/profile";
      axios
        .get(link)
        .then((response) => {
          me.utente = response.data.data;
          // me.idSoggetto = utente.primaryKey;
          // console.log("Funzione " + id);
        })
        .catch((e) => {
          console.log(e);
          //   this.errors.push(e);
        });
    },
    loadData() {
      let me = this;
      // console.log(me);
      let link = process.env.VUE_APP_PATH_API + me.pathResource;
      me.isBusy = true;
      axios
        .get(link, { params: me.filtroLista })
        .then((response) => {
          me.jsonData = [];
          me.jsonData = response.data.data.list;
          me.rows = response.data.data.recordsNumber;
          //me.$refs["SaPageList"].close();
        })
        .catch(() => {
          me.showmolalloading = false;
        });
    },
    loadClienti() {
      let me = this;
      me.destinazioneOption = [];
      let link = process.env.VUE_APP_PATH_API + "/clienti";
      axios.get(link).then((response) => {
        // response.data.data.forEach((element) => {
        //     me.destinazioneOption.push({ value: element.codiceDestinazione, text: element.descrizione });
        // });
        // console.log(response.data);
        me.clientiOptions = response.data.data.list;
        me.clientiOptions.unshift({
          id: null,
          descrizione: "-Seleziona Valore-",
        });
      });
    },
    onSubmit() {
      let me = this;
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtroLista"] = JSON.stringify(me.filtroLista);
      me.loadData();
    },
    onReset() {
      let me = this;
      me.filtroLista = {
        dataDal: null,
        dataAl: null,
        destinazione: null,
        numero: null,
      };
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtroLista"] = JSON.stringify(me.filtroLista);
      me.loadData();
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },

    onStampa(row) {
      let me = this;
      // console.log(row);
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + row.id;
      axios.get(link).then((response) => {
        let printData = response.data.data;
        let linkReport = process.env.VUE_APP_PATH_API + me.pathResourceStampa;
        axios
          .post(linkReport, { reportName: me.reportName, data: printData })
          .then((response) => {
            me.$refs.mdlStampaReferto.show();
            me.stampaReport =
              "data:application/pdf;base64," + response.data.base64;
          });
      });
    },

    // onDuplica(row) {
    //     let me = this;
    //     let link = me.linkedit+ "?idOrdine="+row.id;
    //     console.log(link);
    // },
    loadDestinazioni() {
      let me = this;
      me.destinazioneOption = [];
      let link = process.env.VUE_APP_PATH_API + "/destinazioni?cliente=" + me.filtroLista.idCliente;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.destinazioneOption.push({
            value: element.codiceDestinazione,
            text: element.descrizione,
          });
        });
        me.destinazioneOption.unshift({
          value: null,
          text: "-Seleziona Valore-",
        });
        if (me.destinazioneOption.length === 2) {
          me.filtroLista.destinazione = me.destinazioneOption[1].value;
        }
      });
    },
    onDownload(row) {
      let me = this;
      let link =
        process.env.VUE_APP_PATH_API + me.linkFatturaElettronica + "/" + row.id;
      axios({
        url: link, //your url
        method: "GET",
        responseType: "blob", // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        let nomeFile =
          row.codiceDocumento +
          "_" +
          row.numeroDocumento +
          "_" +
          moment(row.dataDocumento).format("DD-MM-YYYY");
        link.setAttribute("download", nomeFile + ".xml"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    },
  },
};
</script>
