<template>
    <div class="sa-tab-scroll">
        <b-card class="sa-card" header="ALIMENTAZIONE E IDRATAZIONE" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaAlimentiIdratazione" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header">
                <template #cell(check)="{ item }">
                    <b-form-checkbox v-model="item.check" :checked="item.check" switch></b-form-checkbox>
                </template>
            </b-table>
        </b-card>
        <b-card class="sa-card" header="ELIMINAZIONE (ALVO E URINE)" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaEliminazione" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header">
                <template #cell(check)="{ item }">
                    <b-form-checkbox v-model="item.check" :checked="item.check" switch></b-form-checkbox>
                </template>
            </b-table>
        </b-card>
        <b-card class="sa-card" header="IGIETE E COMFORT" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaIgieneCompfort" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header">
                <template #cell(check)="{ item }">
                    <b-form-checkbox v-model="item.check" :checked="item.check" switch></b-form-checkbox>
                </template>
            </b-table>
        </b-card>
        <b-card class="sa-card" header="MOBILIZZAZIONE" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaMobilizzazione" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header">
                <template #cell(check)="{ item }">
                    <b-form-checkbox v-model="item.check" :checked="item.check" switch></b-form-checkbox>
                </template>
            </b-table>
        </b-card>
        <b-card class="sa-card" header="PROCEDURE DIAGNOSTICHE" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaProcedureDiagnostiche" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header">
                <template #cell(check)="{ item }">
                    <b-form-checkbox v-model="item.check" :checked="item.check" switch></b-form-checkbox>
                </template>
            </b-table>
        </b-card>
        <b-card class="sa-card" header="PROCEDURE TERAPEUTICHE" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaProcedureTerapeutiche" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header">
                <template #cell(check)="{ item }">
                    <b-form-checkbox v-model="item.check" :checked="item.check" switch></b-form-checkbox>
                </template>
            </b-table>
        </b-card>
        <b-card class="sa-card" header="PERCEZIONE SENSORIALE" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaProcedureSensoriali" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header">
                <template #cell(check)="{ item }">
                    <b-form-checkbox v-model="item.check" :checked="item.check" switch></b-form-checkbox>
                </template>
            </b-table>
        </b-card>
        <b-card class="sa-card" header="Totale" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <span class="sa-label-data">{{ totale }}</span>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import axios from "axios";
export default {
    components: {},
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    watch: {
        listaAlimentiIdratazione: {
            handler() {
                let me = this;
                me.unisciArray();
            },
            deep: true,
        },
        listaEliminazione: {
            handler() {
                let me = this;
                me.unisciArray();
            },
            deep: true,
        },
        listaIgieneCompfort: {
            handler() {
                let me = this;
                me.unisciArray();
            },
            deep: true,
        },
        listaMobilizzazione: {
            handler() {
                let me = this;
                me.unisciArray();
            },
            deep: true,
        },
        listaProcedureDiagnostiche: {
            handler() {
                let me = this;
                me.unisciArray();
            },
            deep: true,
        },
        listaProcedureTerapeutiche: {
            handler() {
                let me = this;
                me.unisciArray();
            },
            deep: true,
        },
        listaProcedureSensoriali: {
            handler() {
                let me = this;
                me.unisciArray();
            },
            deep: true,
        },
    },
    data() {
        return {
            idPropostaAccesso: null,
            keyPropostaAccesso: 0,
            linkback: "",
            linkPrintData: "",
            childKey: 0,
            viewPai: true,
            pathResource: "/cotscalaidarichieste",
            totale: 0,
            jsonData: [],
            listaAlimentiIdratazione: [],
            listaEliminazione: [],
            listaIgieneCompfort: [],
            listaMobilizzazione: [],
            listaProcedureDiagnostiche: [],
            listaProcedureTerapeutiche: [],
            listaProcedureSensoriali: [],
            fieldsListe: [
                {
                    label: "Descrizione",
                    key: "fattore",
                    sortable: true,
                },
                {
                    label: "",
                    key: "check",
                    sortable: true,
                    thStyle: "width: 10rem",
                    tdClass: "text-center sa-column-widh",
                },
                // {
                //   label: "",
                //   key: "action",
                //   thStyle: "width: 10rem",
                // },
            ],
        };
    },
    mounted() {
        let me = this;
        me.idPropostaAccesso = this.$route.params.id;
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.idPropostaAccesso;
            axios
                .get(link)
                .then((response) => {
                    me.jsonData = response.data.data.list;
                    me.calcolaPunteggio();
                    me.jsonData.forEach((element) => {
                        if (element.check) {
                            me.jsonData.push({ idRichiesta: me.idPropostaAccesso, idScalaIda: element.id });
                        }
                        // console.log(element.gruppo);
                        switch (element.gruppo) {
                            case "ALIMENTAZIONE E IDRATAZIONE":
                                me.listaAlimentiIdratazione.push(element);
                                break;
                            case "ELIMINAZIONE (ALVO E URINE)":
                                me.listaEliminazione.push(element);
                                break;
                            case "IGIENE E COMFORT":
                                me.listaIgieneCompfort.push(element);
                                break;
                            case "MOBILIZZAZIONE":
                                me.listaMobilizzazione.push(element);
                                break;
                            case "PROCEDURE DIAGNOSTICHE":
                                me.listaProcedureDiagnostiche.push(element);
                                break;
                            case "PROCEDURE TERAPEUTICHE":
                                me.listaProcedureTerapeutiche.push(element);
                                break;
                            case "PERCEZIONE SENSORIALE":
                                me.listaProcedureSensoriali.push(element);
                                break;
                        }
                    });
                })
                .catch(() => {});
        },
        unisciArray() {
            let me = this;
            me.jsonData = [];
            me.jsonData = me.listaAlimentiIdratazione.concat(me.listaEliminazione).concat(me.listaIgieneCompfort).concat(me.listaMobilizzazione).concat(me.listaProcedureDiagnostiche).concat(me.listaProcedureTerapeutiche).concat(me.listaProcedureSensoriali);
            let lista = [];
            me.jsonData.forEach((element) => {
                if (element.check) {
                    lista.push({ idRichiesta: me.idPropostaAccesso, idScalaIda: element.id });
                }
            });
            me.calcolaPunteggio();
            //   console.log(lista);
            this.$emit("update", lista);
        },
        calcolaPunteggio() {
            let me = this;
            me.totale = 0;
            me.jsonData.forEach((element) => {
                if (element.check) {
                    me.totale = me.totale + element.punteggio;
                }
            });
        },

        setListe() {},
    },
};
</script>

<style></style>
