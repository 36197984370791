<template>
    <div style="height: 100%">
        <div class="sa-flexbox" style="padding-bottom: 20px">
            <div class="d-flex flex-column">
                <div class="text-right mb-3"></div>
                <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col lg="3">
                        <p>Record Totali: {{ rows }}</p>
                    </b-col>
                    <b-col lg="9">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                    </b-col>
                </b-row>
            </div>

            <div class="sa-flexbox-body">
                <div class="b-table-sticky-header">
                    <b-table sticky-header ref="table" stacked="lg" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="row">
                            <span> {{ row.index + 1 }} </span>
                        </template>
                        <template v-slot:cell(numero)="{ item }">
                            <span>{{ item.numero }}</span>
                        </template>
                        <template v-slot:cell(nominativo)="{ item }">
                            <span class="sa-table-span-label-primary">{{ item.nome }} {{ item.cognome }}</span>
                            <span class="sa-table-span-label-secondary">{{ item.identificativo }}</span>
                        </template>
                        <template v-slot:cell(eta)="{ item }">
                            <span>{{ calcoloEta(item.dataNascita) }}</span>
                        </template>
                        <template v-slot:cell(email)="{ item }">
                            <span>{{ item.email }}</span>
                        </template>
                        <template v-slot:cell(camera)="{ item }">
                            <span>{{ item.camera }}</span>
                        </template>
                        <template v-slot:cell(actions)="row">
                            <b-container class="bv-example-row">
                                <b-button size="sm" v-b-tooltip.hover title="Visualizza Richiesta" variant="outline-secondary no-text" @click="onClickEdit(row.item, row.index, $event.target)" class="mr-1 no-text">
                                    <font-awesome-icon icon="eye" />
                                </b-button>
                                <b-button size="sm" v-b-tooltip.hover title="Ricovera Paziente" variant="outline-success no-text" @click="onClickRicovera(row.item, row.index, $event.target)" class="mr-1 no-text">
                                    <font-awesome-icon icon="bed" />
                                </b-button>

                                <b-button size="sm" v-b-tooltip.hover title="Cancella Accettazione" variant="outline-danger" @click="onClickAnnullaAccetazione(row.item, row.index, $event.target)" class="mr-1 no-text">
                                    <font-awesome-icon icon="trash"></font-awesome-icon>
                                </b-button>
                            </b-container>
                        </template>
                    </b-table>
                </div>
            </div>
            <div class="sa-list-component-footer">
                <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col lg="3">
                        <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rows }}</p>
                    </b-col>
                    <b-col lg="9">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                    </b-col>
                </b-row>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import UtilityMixin from "../../../utility/UtilityMixin";

export default {
    mixins: [UtilityMixin],
    data() {
        return {
            rows: 0,
            perPage: 50,
            currentPage: 1,
            btnNuovaAccettazioneHidden: false,
            pathResource: "/rsaaccettazioni",
            linkedit: "/rsaaccettazione",
            pathResourceRicoveraPaziente: "/rsaaccettazioni/aggiornastato",
            pathResourceAnnullaAccettazione: "/rsaaccettazioni/aggiornastato",
            filtro: {},
            items: [],
            listKey: 0,
            fields: [
                {
                    label: "",
                    key: "index",
                },
                {
                    label: "Numero",
                    key: "numero",
                    sortable: true,
                },
                {
                    label: "Nominativo",
                    key: "nominativo",
                    sortable: true,
                },
                {
                    label: "Età",
                    key: "eta",
                },
                { label: "Numero telefono", key: "telefono" },
                {
                    label: "Email",
                    key: "email",
                },
                {
                    label: "Camera",
                    key: "camera",
                },
                { class: "sa-table-column-action-double", key: "actions", label: "" },
            ],
        };
    },
    watch: {
        currentPage() {
            let me = this;
            me.loadData();
        },
    },
    methods: {
        loadData() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            if (sessionStorage["filtro"]) {
                me.filtro = JSON.parse(sessionStorage["filtro"]);
            }

            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.filtro.page = me.currentPage;
            me.filtro.forPage = me.perPage;
            me.$emit("beforeLoadData");
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];
                    me.rows = response.data.data.recordsNumber;
                    me.items = response.data.data.list;
                    me.listKey++;
                    this.$emit("afterLoadData");
                })
                .catch(() => {
                    this.$emit("afterLoadData");
                });
        },
        onClickEdit(item) {
            let me = this;
            me.$router.push("/rsaaccettazione/view/" + item.id);
        },
        onClickRicovera(item) {
            let me = this;
            me.ricovera(item);
        },
        ricovera(item) {
            let accettazioneStato = { idAccetazione: item.id, stato: "RICOVERATO" };
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceRicoveraPaziente;
            axios
                .post(link, JSON.stringify(accettazioneStato))
                .then(() => {
                    me.loadData();
                })
                .catch((error) => {
                    let messaggio = error.response ? error.response.data.messaggio : "Errore non gestito";
                    me.$bvModal
                        .msgBoxOk(messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then(() => {})
                        .catch((err) => {
                            console.log(err);
                        });
                });
        },
        annullaAccettazione(id) {
            let me = this;
            let accettazioneStato = {
                idAccetazione: id,
                stato: "CANCELLATO",
            };
            let link = process.env.VUE_APP_PATH_API + me.pathResourceAnnullaAccettazione; /* + "/" + item.id */
            axios
                .post(link, JSON.stringify(accettazioneStato))
                .then(() => {
                    me.loadData();
                    me.$bvToast.toast("Cancellazione avvenuta con successo", {
                        title: "Successo",
                        variant: "success",
                        solid: true,
                    });
                })
                .catch((e) => {
                    console.log(e);
                    me.showModalLoading = false;
                    me.$bvToast.toast("Errore durante la cancellazione", {
                        title: "Errore",
                        variant: "danger",
                        solid: true,
                    });
                });
        },
        onClickAnnullaAccetazione(item) {
            let me = this;
            this.$bvModal
                .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
                    title: "Cancella accettazione",
                    buttonSize: "sm",
                    okVariant: "secondary",
                    cancelVariant: "danger",
                    okTitle: "SI",
                    cancelTitle: "NO",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                })
                .then((value) => {
                    if (value) {
                        me.annullaAccettazione(item.id);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
};
</script>
<style></style>
