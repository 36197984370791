<template>
	<sa-page-layout :btnSaveVisible="false" :data="jsonData" :btnAnnullaVisible="false" :showModalLoading="showModalLoading" :linkback="linkback" :pathResource="pathResource" class="sa-no-space">
		<template slot="toolbar">
			<b-button class="float-sm-right" variant="outline-secondary btn-toolbar sa-margin-right" size="sm" @click="onClickFierma">
				<b-icon icon="pencil-square" variant="outline-secondary"></b-icon>
				Firma
			</b-button>
		</template>
		<template slot="table-body">
			<graffidi-test-component ref="cmpGraffidiTestComponent" @updateJsonData="onUpdateJsonData"></graffidi-test-component>
		</template>
	</sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import GraffidiTestComponent from "../components/GraffidiTestComponent.vue";
import UtilityMixin from "../../utility/UtilityMixin";
export default {
	components: { SaPageLayout, GraffidiTestComponent },
	data() {
		return {
			pathResource: "/sign",
			linkback: "/digitalsign",
			showModalLoading: false,
			jsonData: { modalita: null, tipoFirma: null, marca: null },
		};
	},
	methods: {
		onClickFierma() {
			console.log(this.jsonData);
			this.sign();
		},
		onUpdateJsonData(value) {
			let me = this;
			me.jsonData = value;
		},
		sign() {
			let me = this;
			let link = process.env.VUE_APP_PATH_API + me.pathResource;
			UtilityMixin.methods.restCallPost(link, me.jsonData, me.signSuccess, me.signError);
		},
		signSuccess(response) {
			console.log(response.data.data[0]);
			let me = this;
			me.$refs.cmpGraffidiTestComponent.setResponseData(response.data.data[0]);
		},
		signError(error) {
			console.log(error);
		},
	},
};
</script>

<style></style>
