import DataAnalysis from "../";

const routes = [
	{ name: "Elenco Tabelle", path: "/dataanalysistables", component: DataAnalysis.DataAnalysisTablesListPage, meta: { title: "Elenco Accettazione" } },
	{ name: "Data Analysis Table Edit", path: "/dataanalysistables/edit/:id", component: DataAnalysis.DataAnalysisTablesEditPage, meta: { title: "Digita Sign" } },
	{ name: "Data Analysis Query List", path: "/dataanalysisquery", component: DataAnalysis.DataAnalysisQueryListPage, meta: { title: "Query Builder Edit" } },
	{ name: "Data Analysis Query Edit", path: "/dataanalysisquery/edit/:id", component: DataAnalysis.DataAnalysisQueryEditPage, meta: { title: "Digita Sign" } },
	{ name: "Data Analysis Dashboard List", path: "/dataanalysisdashboards", component: DataAnalysis.DataAnalysisDashboardListPage, meta: { title: "Dashboard" } },
	{ name: "Data Analysis Dashboard Edit", path: "/dataanalysisdashboards/edit/:id", component: DataAnalysis.DataAnalysisDashboardEditPage, meta: { title: "Dashboard" } },
	{ name: "Data Analysis Dashboard View", path: "/dataanalysisdashboards/view/:id", component: DataAnalysis.DataAnalysisDashboardViewPage, meta: { title: "Dashboard" } },
	{ name: "Data Analysis Dashboard View Details", path: "/dataanalysisdashboards/view/details/:id", component: DataAnalysis.TestPivotPage, meta: { title: "Dashboard Dettaglio" } },
	// { name: "Data Analysis Dashboard View Details", path: "/dataanalysisdashboards/view/details/:id", component: DataAnalysis.DataAnalysisDashboardViewDetailsPage, meta: { title: "Dashboard Dettaglio" } },
	{ name: "Data Analysis Data Sources", path: "/dataanalydatasources", component: DataAnalysis.DataAnalysisDataSourcesPage, meta: { title: "Data Sources Page" } },
	{ name: "Data Analysis Data Sources", path: "/dataanalysisdashboardgruppi", component: DataAnalysis.DataAnalysisDashboardGruppiPage, meta: { title: "Data Sources Page" } },
];
export default routes;
