var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('sa-page-layout',{ref:"pgaView",attrs:{"btnBackVisible":true,"btnEditVisible":true,"titolo":_vm.gruppo.nome,"linkback":_vm.linkback,"linkedit":_vm.linkedit,"showModalLoading":_vm.showModalLoading}},[_c('template',{slot:"table-filter"},[_c('b-row',[_c('b-col',{staticClass:"sa-text-right",attrs:{"cols":"12","md":"2"}},[_vm._v("Nome:")]),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b',[_vm._v(_vm._s(_vm.gruppo.nome))])]),_c('b-col',{staticClass:"sa-text-right",attrs:{"cols":"12","md":"2"}},[_vm._v("Descrizione:")]),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b',[_vm._v(_vm._s(_vm.gruppo.descrizione))])])],1)],1),_c('template',{slot:"table-body"},[_c('b-tabs',{staticClass:"sa-tab-scroll",staticStyle:{"height":"100%"},attrs:{"content-class":"mt-3","fill":""}},[_c('b-tab',{attrs:{"title":"Menu","active":""}},[_c('b-card-text',[_c('b-table',{attrs:{"responsive":"","striped":"","hover":"","items":_vm.gruppo.menu,"fields":_vm.fieldsMenu,"head-variant":"light","sort-icon-left":""},scopedSlots:_vm._u([{key:"cell(iconCls)",fn:function(ref){
var item = ref.item;
return [_c('i',{class:'fas fa-' + item.iconCls})]}},{key:"cell(text)",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',{staticClass:"sa-text-bold"},[_vm._v(_vm._s(item.text)+" ("+_vm._s(item.routeId)+")")])]),_c('div',[_c('span',[_vm._v(_vm._s(item.view))])])]}},{key:"cell(selected)",fn:function(ref){
var item = ref.item;
return [(item.selezionato)?_c('b-icon',{attrs:{"icon":"check-square-fill","variant":"success"}}):_c('b-icon',{attrs:{"icon":"x-square-fill","variant":"danger"}})]}}])})],1)],1),_c('b-tab',{attrs:{"title":"Permessi"}},[_c('b-card-text',[_c('b-row',[_c('b-col',{staticClass:"my-1",attrs:{"lg":"12"}},[_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"","label-cols-sm":"0","label-align-sm":"right","label-size":"sm","label-for":"filterInput"}},[_c('b-input-group',{attrs:{"size":"sm"}},[_c('b-form-input',{attrs:{"type":"search","id":"filterInput","placeholder":"Nome"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"disabled":!_vm.filter},on:{"click":function($event){_vm.filter = ''}}},[_vm._v("Clear")])],1)],1)],1)],1)],1),_c('b-table',{attrs:{"responsive":"","striped":"","hover":"","filter":_vm.filter,"filterIncludedFields":_vm.filterOn,"items":_vm.gruppo.sezioni,"fields":_vm.fieldsSezioni,"head-variant":"light","sort-icon-left":""},scopedSlots:_vm._u([{key:"cell(permessiSezione)",fn:function(ref){
var item = ref.item;
return _vm._l((item.permessi),function(permesso,index){return _c('div',{key:index},[(permesso.attivo)?_c('div',[_c('b-icon',{attrs:{"icon":"check-square-fill","variant":"success"}}),_vm._v(" "+_vm._s(permesso.descrizione))],1):_c('div',[_c('b-icon',{attrs:{"icon":"x-square-fill","variant":"danger"}}),_vm._v(" "+_vm._s(permesso.descrizione))],1)])})}}])})],1)],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }