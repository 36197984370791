<template>
    <div :class="classDisponibile">
        <div class="sa-agenda-slot-disponibile">
            <div>
                <span>{{ titolo }}</span>
            </div>
            <div>
                <b-button v-if="scaduto" v-b-tooltip.hover title="Prenota" size="sm" class="mr-1 ml-1 no-text" variant="outline-success" @click="onClickNuovoAppuntamento()">
                    <i class="far fa-calendar-plus"></i>
                </b-button>
                <!-- <b-button v-if="scaduto" v-b-tooltip.hover title="Prenota" size="sm" class="mr-1 ml-1 no-text" variant="outline-info" @click="onClickPrenota()">
                    <i class="far fa-calendar-plus"></i>
                </b-button> -->
            </div>
            <agenda-nuovo-appuntamento-modal-component key="" ref="cmpAgendaNuovoAppuntamentoModalComponent" @successRestCall="onSuccessRestCall"></agenda-nuovo-appuntamento-modal-component>
        </div>
    </div>
</template>
<script>
// import Vue from "vue";
// import DatePicker from "vue2-datepicker";
import UtilityMixin from "../../utility/UtilityMixin.js";
// import axios from "axios";
// import { SaModalLoading } from "../../../template/components/modal/index.js";
import moment from "moment";
import AgendaNuovoAppuntamentoModalComponent from "./AgendaNuovoAppuntamentoModalComponent.vue";
// import AgendaModalSpostaAppuntamento from "./AgendaModalSpostaAppuntamento.vue";

export default {
    mixins: [UtilityMixin],
    components: {
        AgendaNuovoAppuntamentoModalComponent,
        // DatePicker,
        // SaModalLoading,
        // AgendaModalSpostaAppuntamento,
    },
    props: {
        index: String,
        slotDisponibilita: {
            type: Object,
            default: function () {
                return { appuntamento: { identificativoAppuntamento: "" } };
            },
        },
        codicePrestazione: String,
        descrizionePrestazione: String,
        costo: Number,
        idAgenda: String,
        slotNumber: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            modalShow: false,
            modalSpostaAppuntamento: false,
            objectAppuntamento: { id: "", idAgenda: "", codicePrestazione: "", descrizionePrestazione: "", dataOraInizio: "", dataOraFine: "", identificativoAppuntamento: "", costo: "", note: "" },
            pathRestPrestazioni: "/agendaprestazioni",
            pathRestAppuntamenti: "/agendaappuntamenti",
            modifica: false,
            scaduto: false,
            nuovoAppuntamento: null,
        };
    },
    computed: {
        classDisponibile() {
            let me = this;
            if (me.slotDisponibilita.appuntamento === null) {
                return "sa-calendar-day-slot-prenotazione disponibile";
            } else {
                if (me.slotDisponibilita.appuntamento[this.slotNumber] === null) {
                    if (!me.scaduto) {
                        return "sa-calendar-day-slot-prenotazione scaduto";
                    } else {
                        return "sa-calendar-day-slot-prenotazione disponibile";
                    }
                } else {
                    return "sa-calendar-day-slot-prenotazione nodisponibile";
                }
            }
        },
        dataAppuntamento: {
            get: function () {
                return UtilityMixin.methods.parseFormatDateTime(this.slotDisponibilita.dataRiferimento + "" + this.slotDisponibilita.dalleOre, "YYYY-MM-DD").getTime();
            },
            set: function (value) {
                const data = new Date(value);
                return (this.slotDisponibilita.dataRiferimento = moment(data).format("YYYY-MM-DD"));
            },
        },
        oraInizio: {
            get: function () {
                let me = this;
                return UtilityMixin.methods.parseFormatDateTime(me.slotDisponibilita.dataRiferimento + "" + me.slotDisponibilita.dalleOre, "YYYY-MM-DD HH:mm").getTime();
            },
            set: function (value) {
                let me = this;
                const data = new Date(value);
                me.slotDisponibilita.dalleOre = moment(data).format("HH:mm");
            },
        },
        oraFine: {
            get: function () {
                let me = this;
                return UtilityMixin.methods.parseFormatDateTime(me.slotDisponibilita.dataRiferimento + "" + me.slotDisponibilita.alleOre, "YYYY-MM-DD HH:mm").getTime();
            },
            set: function (value) {
                let me = this;
                const data = new Date(value);
                me.slotDisponibilita.alleOre = moment(data).format("HH:mm");
            },
        },
        id() {
            let me = this;
            return "popover-button_" + me.index + "_" + this.slotNumber;
        },
        titolo() {
            let me = this;
            let numeroDisponibilita = 0;
            let disponibilitaTotali = me.slotDisponibilita.appuntamento.length;
            me.slotDisponibilita.appuntamento.forEach((appuntamento) => {
                if (appuntamento === null) {
                    numeroDisponibilita++;
                }
            });

            return "Disponibilità: " + numeroDisponibilita + "/" + disponibilitaTotali;
            // if (!me.slotDisponibilita.appuntamento === null || !me.slotDisponibilita.appuntamento[this.slotNumber]) {
            //     console.log(me.slotDisponibilita);

            //     return "Disponibilità: " + numeroDisponibilita;
            // } else {
            //     return me.slotDisponibilita.appuntamento[this.slotNumber].titolo;
            // }
        },
        descrizionePrestazioneApp: {
            get: function () {
                let me = this;
                if (me.slotDisponibilita.appuntamento === null || me.slotDisponibilita.appuntamento[me.slotNumber] === null) {
                    return "";
                } else {
                    return me.slotDisponibilita.appuntamento[me.slotNumber].descrizionePrestazione;
                }
            },
            set: function (value) {
                let me = this;
                if (me.slotDisponibilita.appuntamento === null || me.slotDisponibilita.appuntamento[me.slotNumber] === null) {
                    me.slotDisponibilita.descrizionePrestazione = value;
                } else {
                    me.slotDisponibilita.appuntamento.descrizionePrestazione = value;
                }
            },
        },
        identificativo: {
            get() {
                let me = this;
                if (me.slotDisponibilita.appuntamento === null || me.slotDisponibilita.appuntamento[me.slotNumber] === null) {
                    return me.objectAppuntamento.identificativoAppuntamento;
                } else {
                    return me.slotDisponibilita.appuntamento[me.slotNumber].identificativoAppuntamento;
                }
            },
            set(value) {
                let me = this;
                if (me.slotDisponibilita.appuntamento[me.slotNumber] === null) {
                    me.slotDisponibilita.identificativoAppuntamento = value;
                    me.objectAppuntamento.identificativoAppuntamento = value;
                } else {
                    me.slotDisponibilita.appuntamento[me.slotNumber].identificativoAppuntamento = value;
                }
            },
        },
        costoPrestazione() {
            let me = this;
            if (me.slotDisponibilita.appuntamento === null || me.slotDisponibilita.appuntamento[me.slotNumber] === null) {
                return me.costo;
            } else {
                return me.slotDisponibilita.appuntamento[me.slotNumber].costo;
            }
        },
        noteAppuntamento: {
            get() {
                let me = this;
                if (me.slotDisponibilita.appuntamento === null || me.slotDisponibilita.appuntamento[me.slotNumber] === null) {
                    return me.objectAppuntamento.note;
                } else {
                    return me.slotDisponibilita.appuntamento[me.slotNumber].note;
                }
            },
            set(value) {
                let me = this;
                if (me.slotDisponibilita.appuntamento === null || me.slotDisponibilita.appuntamento[me.slotNumber] === null) {
                    me.objectAppuntamento.note = value;
                } else {
                    me.slotDisponibilita.appuntamento[me.slotNumber].note = value;
                }
            },
        },
        codicePrestazioneApp() {
            let me = this;
            if (me.slotDisponibilita.appuntamento === null || me.slotDisponibilita.appuntamento[me.slotNumber] === null) {
                return me.codicePrestazione;
            } else {
                return me.slotDisponibilita.appuntamento[me.slotNumber].codicePrestazione;
            }
        },
    },
    mounted: function () {
        let me = this;
        // console.log(me.slotDisponibilita);
        // me.objectAppuntamento.titolo = Vue.prototype.user.lastname + " " + Vue.prototype.user.firstname;
        console.log(me.disponibilita);
        me.onScaduto();
    },
    watch: {
        slotDisponibilita: function () {
            let dataSlot = new Date();
            let dataFormattata = moment(dataSlot).format("YYYY-MM-DD HH:mm");
            if (this.slotDisponibilita.dataRiferimento + " " + this.slotDisponibilita.dalleOre >= dataFormattata) {
                this.scaduto = true;
            } else {
                this.scaduto = false;
            }
        },
    },
    methods: {
        onScaduto() {
            let dataSlot = new Date();
            let dataFormattata = moment(dataSlot).format("YYYY-MM-DD HH:mm");
            if (this.slotDisponibilita.dataRiferimento + " " + this.slotDisponibilita.dalleOre >= dataFormattata) {
                this.scaduto = true;
            } else {
                this.scaduto = false;
            }
        },
        onClickNuovoAppuntamento() {
            let me = this;
            let objectAppuntamento = { idAgenda: me.idAgenda, titolo: null, identificativoAppuntamento: null, codicePrestazione: me.codicePrestazione, descrizionePrestazione: me.descrizionePrestazione, dataOraInizio: me.oraInizio, dataOraFine: me.oraFine, dataRiferimento: me.dataAppuntamento, costo: me.costo, note: null };
            this.$refs.cmpAgendaNuovoAppuntamentoModalComponent.show(objectAppuntamento);
        },
        onSuccessRestCall() {
            this.$emit("updateSlotEvent");
        },
    },
};
</script>
<style scoped>
.sa-calendar-popover .popover-header {
    margin-top: 0px !important;
    background-color: blue;
}

.sa-calendar-day-slot-prenotazione span {
    border-radius: 0.3rem;
    vertical-align: middle;
    display: table-cell;
    padding: 0.3rem;
    white-space: nowrap;
    line-height: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    color: white;
    font-size: 0.95rem;
    padding: 6px;
}

.scaduto span {
    background-color: #d7d7d7;
    border-left: 3px solid #646464;
    color: black;
}

.disponibile span {
    background-color: #abf7b3;
    border-left: 3px solid green;
    color: black;
}

.disponibile span:active {
    background-color: green;
    border-left: 3px solid #abf7b3;
}

.nodisponibile span {
    background-color: #9bc3ff;
    border-left: 3px solid blue;
    color: black;
}

.nodisponibile span:active {
    background-color: rgb(86, 86, 248);
    border-left: 3px solid #9bc3ff;
}
.sa-icon-btn {
    width: 2rem;
    height: 2rem;
}
.sa-icon-btn svg {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0.5rem;
}
.sa-icon-btn svg:hover {
    color: #000;
    cursor: pointer;
}
.sa-agenda-popover-span {
    padding-left: 5px;
}
</style>
