<template>
	<div class="sa-free-page">
		<div class="sa-free-page-body" style="margin-top: 10px">
			<b-card class="sa-card" header="Pannello di Configurazione" header-tag="header" footer-tag="footer" title="">
				<div class="sa-large-button-panel">
					<b-row>
						<b-col v-for="menuItem in configMenu" xs="12" sm="6" md="3" lg="3" xl="3" :key="menuItem.routeId">
							<div class="sa-large-button">
								<div class="sa-large-button-container" @click="onClickButton(menuItem)">
									<span class="sa-large-button-icon"><sa-icon :icon="menuItem.iconCls" /></span>
									<span>{{ menuItem.text }}</span>
								</div>
							</div>
						</b-col>
					</b-row>
				</div>
			</b-card>
		</div>

		<modal-loading ref="cmpModalLoading"></modal-loading>
	</div>
</template>

<script>
import UtilityMixin from "../../utility/UtilityMixin";
import ModalLoading from "../../../template/components/modal/components/ModalLoading.vue";
export default {
	components: { ModalLoading },
	data() {
		return {
			pathResource: "/vocimenu/configurazioni",
			showModalLoading: false,
			configMenu: [],
		};
	},
	mounted() {
		let me = this;
		me.loadData();
	},
	watch: {
		showModalLoading(value) {
			let me = this;
			if (value) {
				me.$refs.cmpModalLoading.show();
			} else {
				setTimeout(me.$refs.cmpModalLoading.close(), 200);
			}
		},
	},

	methods: {
		loadData() {
			let me = this;
			me.showModalLoading = true;
			UtilityMixin.methods.loadDataResources(me.pathResource, me.jsonData, me.loadDataSuccess, me.loadDataError);
		},
		loadDataSuccess(response) {
			let me = this;
			me.configMenu = response.data.data;
			me.showModalLoading = false;
		},
		loadDataError(error) {
			let me = this;
			console.log(error);
			me.showModalLoading = false;
		},
		onClickButton(menuItem) {
			let me = this;
			me.$router.replace("configurazioni/" + menuItem.routeId).catch((err) => {
				err;
			});
		},
	},
};
</script>

<style></style>
