<b-col cols="12" sm="12" md="4" lg="4" xl="4"></b-col>
<template>
	<b-modal title="Edit Pianificazione" ref="mdlRsaAreaPianificazioneEdit" id="mdlRsaAreaPianificazioneEdit" modal-class="sa-modal-static-scroll" size="lg" @ok="onOk">
		<div class="sa-flexbox">
			<div class="sa-flexbox-header">
				<b-row>
					<b-col cols="12" sm="12" md="4" lg="4" xl="4">
						<label class="sa-label-data">Data Inizio:</label>
						<b-input-group class="mb-3">
							<date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataInizio" type="search"></date-picker>
						</b-input-group>
					</b-col>
					<b-col cols="12" sm="12" md="4" lg="4" xl="4">
						<label class="sa-label-data">Data Fine:</label>
						<b-input-group class="mb-3">
							<date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataFine" type="search"></date-picker>
						</b-input-group>
					</b-col>
					<b-col cols="12" sm="12" md="4" lg="4" xl="4">
						<label class="sa-label-data">Attività:</label>
						<b-form-select id="inline-form-custom-select-pref" v-model="jsonData.nome" value-field="codice" text-field="nome" value="null" class="mb-2 mr-sm-2 mb-sm-0" :options="attivitaOptions" @change="onChangeAttivita"></b-form-select>
					</b-col>
				</b-row>
			</div>
			<div class="sa-flexbox-body">
				<b-row>
					<b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
						<label class="sa-label-data">Indicazioni:</label>
						<vue-editor class="sa-qeditor" :editor-toolbar="customToolbar" v-model="jsonData.indicazioni" />
					</b-col>
				</b-row>
			</div>
			<div class="sa-flexbox-footer">
				<b-tabs id="tabsRsaAree" ref="tabsRsaAree" v-model="activeTabIndex" content-class="mt-3" class="sa-tab" style="height: 100%; padding-bottom: 25px" fill>
					<b-tab title="Turni">
						<rsa-pianificazione-edit-turni ref="cmpRsaPianificazioneEditTurni" @afterMounted="onAfterMountedRsaPianificazioneEditTurni"></rsa-pianificazione-edit-turni>
					</b-tab>
					<b-tab title="Orario"> </b-tab>
					<b-tab title="Ore Alternate"> </b-tab>
					<b-tab title="Giorni Alternati"> </b-tab>
					<b-tab title="Giorni del Mese"> </b-tab>
					<b-tab title="Cicliche">
						<div style="max-height: 325px; overflow: hidden">
							<b-row>
								<b-col cols="12" xs="12" sm="12" md="5" lg="5" xl="5">
									<b-calendar :min="min" class="border rounded p-2" start-weekday="1" locale="it" @context="onContext"></b-calendar>
								</b-col>
								<b-col cols="12" xs="12" sm="12" md="7" lg="7" xl="7">
									<b-row>
										<b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
											<div class="sa-free-page-info-panel">
												<span class="sa-free-page-info-panel-label">Num. Accessi</span>
												<span class="sa-free-page-info-panel-data">{{ riepilogo.quantitaProgrammata }}</span>
												<span class="sa-free-page-info-panel-label">Num. Accessi Programmati</span>
												<span class="sa-free-page-info-panel-data">{{ riepilogo.quantitaSchedulata }}</span>
											</div>
										</b-col>
										<b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
											<label class="sa-label-data">Data Programmata:</label>
											<b-input-group class="mb-3">
												<date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="schedulazione.dataInizio" @input="onInputSchedulazioneDataInizio"></date-picker>
											</b-input-group>
										</b-col>
										<b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
											<label class="sa-label-data">Ora Programmata:</label>
											<div style="display: flex; flex-direction: row">
												<div style="flex: 1">
													<b-input-group class="mb-3">
														<date-picker format="HH:mm" value-type="timestamp" v-model="schedulazione.orario" :disabled-time="disbilitaOrePrecedenti" placeholder="HH:mm" :default-value="new Date()" :minute-step="15" type="time"></date-picker>
													</b-input-group>
												</div>
												<div>
													<b-button size="sm" variant="outline-success  pull-right" style="height: 29px" @click="onClickAggiungiOrario()" :disabled="btnDisabledAggiungiOrario">
														<i class="fas fa-plus"></i>
													</b-button>
												</div>
											</div>
										</b-col>
										<b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
											<div class="b-table-sticky-header" style="height: 260px; padding-bottom: 30px">
												<b-table sticky-header ref="table" stacked="lg" striped hover :items="listaOrari" :fields="fields" :current-page="1" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
													<template v-slot:cell(actions)="row">
														<b-container class="bv-example-row">
															<b-button :disabled="checkDisableDelete(row.item)" v-b-tooltip.hover size="sm" variant="outline-danger" title="Cancella Orario" @click="onClickDelete(jsonData.listaOrari, row.item)" class="mr-1 no-text">
																<b-icon icon="trash" variant="danger"></b-icon>
															</b-button>
														</b-container>
													</template>
												</b-table>
											</div>
										</b-col>
									</b-row>
								</b-col>
							</b-row>
						</div>
					</b-tab>
				</b-tabs>
			</div>
		</div>
	</b-modal>
</template>

<script>
import axios from "axios";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import { VueEditor } from "vue2-editor";
import RsaPianificazioneEditTurni from "../../components/pianificazione/RsaPianificazioneEditTurni.vue";
import UtilityMixin from "../../../utility/UtilityMixin";
export default {
	mixins: [UtilityMixin],
	components: {
		DatePicker,
		VueEditor,
		RsaPianificazioneEditTurni,
	},
	data() {
		const now = new Date();
		const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
		const minDate = new Date(today);
		return {
			customToolbar: [
				["bold", "italic", "underline", "strike"], // toggled buttons
				[{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
				[{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
				[{ indent: "-1" }, { indent: "+1" }], // outdent/indent
				[{ color: [] }, { background: [] }], // dropdown with defaults from theme
			],
			btnDisabledAggiungiOrario: false,
			pathResource: "/rsaattivitapazienteschedulazioni",
			pathResourceAttivita: "/rsaattivita",
			pathResourcesPrescrizioni: "/rsapazienteprescrizioni",
			attivitaOptions: [{ codice: null, nome: "-Seleziona Attività-" }],
			schedulazione: { dataInizio: null, orario: null },
			jsonData: { nome: null, dataInizio: null, dataFine: null, indicazioni: null, listaOrari: [] },
			listaOrari: [],
			prescrizione: {},
			riepilogo: { quantitaProgrammata: 0, quantitaSchedulata: 0 },
			min: minDate,
			orarioData: new Date(),
			activeTabIndex: 0,
			pianificazione: { dataInizio: null, giorni: null, orari: null, tipo: null, tipoData: null },
			fields: [
				{
					label: "Data",
					key: "dataInizio",
					formatter: (value) => {
						if (value) {
							return moment(new Date(value)).format("DD/MM/YYYY");
						} else {
							return "-----";
						}
					},
					sortable: true,
				},
				{
					label: "Ora",
					key: "orario",
					formatter: (value) => {
						if (value) {
							return moment(new Date(value)).format("HH:mm");
						} else {
							return "-----";
						}
					},
					sortable: true,
				},
				{ class: "sa-table-column-action-double", key: "actions", label: "" },
			],
			perPage: 1000000,
		};
	},
	watch: {
		// riepilogo: {
		// 	handler() {
		// 		let me = this;
		// 		me.btnDisabledAggiungiOrario = false;
		// 		console.log(me.schedulazione);
		// 		if (me.listaOrari.length === 1) {
		// 			me.jsonData.dataInizio = me.schedulazione.dataInizio;
		// 		}
		// 		if (me.listaOrari.length === me.prescrizione.quantitaAccessi) {
		// 			me.jsonData.dataFine = me.schedulazione.dataInizio;
		// 			me.btnDisabledAggiungiOrario = true;
		// 		}
		// 	},
		// 	deep: true,
		// },
	},
	methods: {
		edit(jsonData, area) {
			let me = this;
			me.prescrizione = { quantitaAccessi: 0 };
			me.loadAttivita(area);
			if (me.jsonData.id) {
				me.loadData(jsonData.id);
			} else {
				me.jsonData = jsonData;
			}

			me.$refs.mdlRsaAreaPianificazioneEdit.show();
		},
		loadData(id) {
			let me = this;
			let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + id;
			axios.get(link)
				.then((response) => {
					me.jsonData = response.data.data;
					if (me.jsonData.idPrescrizione) {
						me.loadPrescrizione();
					}
					me.listaOrari = me.jsonData.listaOrari;
					if (me.listaOrari.length) {
						me.riepilogo.quantitaSchedulata = me.listaOrari.length;
					}
					if (me.listaOrari.length > 0) {
						me.pianificazione = me.listaOrari[0];
					}
					me.$refs.cmpRsaPianificazioneEditTurni.setPianificazione(me.pianificazione);
				})
				.catch((error) => {
					UtilityMixin.methods.messaggeBoxError(this, error);
				});
		},
		disbilitaOrePrecedenti(date) {
			let me = this;
			let today = new Date();
			let dataRiferimento = new Date(me.schedulazione.dataInizio);
			if (dataRiferimento.getFullYear() === today.getFullYear() && dataRiferimento.getMonth() === today.getMonth() && dataRiferimento.getDate() === today.getDate()) {
				return date.getHours() < new Date().getHours();
			} else {
				return false;
			}
		},
		onOk(bvModalEvent) {
			let me = this;
			console.log(me.jsonData);
			//console.log(me.pianificazione);
			if (me.jsonData.id === null) {
				console.log("Crea");
				me.createSchedulazione();
			} else {
				console.log("Aggiorna");
				me.updateSchedulazione();
			}
			bvModalEvent.preventDefault();
		},
		loadPrescrizione() {
			let me = this;
			let link = process.env.VUE_APP_PATH_API + me.pathResourcesPrescrizioni + "/" + me.jsonData.idPrescrizione;
			axios.get(link)
				.then((response) => {
					me.prescrizione = response.data.data;
					me.riepilogo.quantitaProgrammata = me.prescrizione.quantitaAccessi;
				})
				.catch((error) => {
					UtilityMixin.methods.messaggeBoxError(this, error);
				});
		},
		onInputSchedulazioneDataInizio() {
			let me = this;
			me.orarioData = me.schedulazione.dataInizio;
		},
		createSchedulazione() {
			let me = this;
			let link = process.env.VUE_APP_PATH_API + me.pathResource;
			me.jsonData.listaOrari = me.listaOrari;
			axios.post(link, JSON.stringify(me.jsonData))
				.then(() => {
					me.$emit("afterSaveRequest");
				})
				.catch((error) => {
					me.$emit("afterSaveRequest");
					let messaggio = error.response ? error.response.data.messaggio : "Errore non gestito";
					me.$bvModal
						.msgBoxOk(messaggio, {
							title: "ATTENZIONE",
							size: "sm",
							okVariant: "outline-danger",
							headerClass: "sa-msg-header-danger",
							footerClass: "p-2 border-top-0",
							centered: true,
						})
						.then(() => {})
						.catch((err) => {
							console.log(err);
						});
				});
		},
		updateSchedulazione() {
			let me = this;
			let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.jsonData.id;
			me.jsonData.listaOrari = me.listaOrari;
			axios.put(link, JSON.stringify(me.jsonData))
				.then(() => {
					me.$emit("afterSaveRequest");
				})
				.catch((error) => {
					me.$emit("afterSaveRequest");
					let messaggio = error.response ? error.response.data.messaggio : "Errore non gestito";
					me.$bvModal
						.msgBoxOk(messaggio, {
							title: "ATTENZIONE",
							size: "sm",
							okVariant: "outline-danger",
							headerClass: "sa-msg-header-danger",
							footerClass: "p-2 border-top-0",
							centered: true,
						})
						.then(() => {})
						.catch((err) => {
							console.log(err);
						});
				});
		},
		onContext(ctx) {
			// console.log(ctx);
			let me = this;
			me.schedulazione.dataInizio = ctx.activeDate.getTime();
		},
		onClickAggiungiOrario() {
			let me = this;
			if (!me.schedulazione.dataInizio) {
				this.$bvModal
					.msgBoxOk("Data Programmata Obbligatoria", {
						title: "ATTENZIONE",
						size: "sm",
						buttonSize: "sm",
						okVariant: "danger",
						headerClass: "p-2 border-bottom-0",
						footerClass: "p-2 border-top-0",
						centered: true,
					})
					.then(() => {})
					.catch((err) => {
						console.log(err);
					});
			} else if (!me.schedulazione.orario) {
				this.$bvModal
					.msgBoxOk("Ora Programmata Obbligatoria", {
						title: "ATTENZIONE",
						size: "sm",
						buttonSize: "sm",
						okVariant: "danger",
						headerClass: "p-2 border-bottom-0",
						footerClass: "p-2 border-top-0",
						centered: true,
					})
					.then(() => {})
					.catch((err) => {
						console.log(err);
					});
			} else {
				let dataRiferimento = new Date(me.schedulazione.dataInizio);
				let oraRiferimento = new Date(me.schedulazione.orario);
				dataRiferimento.setHours(oraRiferimento.getHours(), oraRiferimento.getMinutes(), 0);
				console.log(dataRiferimento);
				console.log(oraRiferimento);
				let nuovoOrario = { dataInizio: me.schedulazione.dataInizio, orario: dataRiferimento.getTime() };
				me.listaOrari.push(nuovoOrario);
				me.riepilogo.quantitaSchedulata = me.listaOrari.length;
			}
		},
		loadAttivita(area) {
			let me = this;
			let link = process.env.VUE_APP_PATH_API + me.pathResourceAttivita;
			me.attivitaOptions = [];
			me.$emit("beforeLoadData");
			axios.get(link, { params: { idArea: area.id } })
				.then((response) => {
					response.data.data.list.forEach((element) => {
						me.attivitaOptions.push({ codice: element.nome, nome: element.nome });
					});
					me.attivitaOptions.unshift({ codice: null, nome: "-Seleziona Attività-" });
					me.listKey++;
					this.$emit("afterLoadData");
				})
				.catch(() => {
					this.$emit("afterLoadData");
				});
		},
		onClickDelete(array, row) {
			let me = this;
			this.$bvModal
				.msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
					title: "Titolo",
					buttonSize: "sm",
					okVariant: "secondary",
					cancelVariant: "danger",
					okTitle: "SI",
					cancelTitle: "NO",
					footerClass: "p-2",
					hideHeaderClose: false,
					centered: true,
				})
				.then((value) => {
					if (value) {
						array.splice(row.index, 1);
						me.riepilogo.quantitaProgrammata = me.listaOrari.length;
					}
				})
				.catch((err) => {
					console.log(err);
				});
		},
		checkDisableDelete() {
			// console.log(item.orario < Date.now());
			// return item.orario < Date.now();
			return false;
		},
		onAfterMountedRsaPianificazioneEditTurni() {
			// let me = this;
			// console.log(me.pianificazione);
			// me.$refs.cmpRsaPianificazioneEditTurni.setPianificazione(me.pianificazione);
		},
		onChangeAttivita() {},
	},
};
/*
TOOLBAR STANDARD
            customToolbar: [
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                ["bold", "italic", "underline", "strike"], // toggled buttons
                [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
                ["blockquote", "code-block"],
                [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
                [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                ["link", "image", "video"],
                ["clean"], // remove formatting button
            ],

*/
</script>

<style>
.sa-flexbox-body .row {
	flex: 1;
	height: 1px;
}
.sa-flexbox-body .row .col-sm-12.col-md-12.col-lg-12.col-xl-12.col-12 {
	height: 100%;
}
.sa-qeditor {
	height: 100%;
	padding-bottom: 80px !important;
}
.sa-qeditor .ql-editor {
	height: 100%;
	min-height: auto !important;
	padding-bottom: 15px;
	/* min-height: 150px;
    max-height: 150px; */
}
.ql-editor {
	font-size: 1rem !important;
}
.sa-pianificazione-edit-container {
	padding: 10px;
}
.sa-pianificazione-edit-container-selector {
	background-color: #f4f4f4;
	line-height: 30px;
}
</style>
