<template>
    <b-modal ref="mdlVisualizzaAnagrafica" size="xl" :title="titoloModal" centered no-close-on-backdrop no-close-on-esc hide-header-close>
        <div>
            <b-card class="sa-card" header="Anagrafica" header-tag="header" footer-tag="footer" title="">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Tipo:</label>
                        <span class="sa-data">{{ jsonData.descrizioneTipo }}</span>
                        <!-- <b-form-input v-model="jsonData.nomeMedicoPresente" placeholder="Nome"></b-form-input> -->
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Nominativo:</label>
                        <span class="sa-data">{{ jsonData.nominativo }}</span>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Città:</label>
                        <span class="sa-data">{{ jsonData.citta }}</span>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Indirizzo:</label>
                        <span class="sa-data">{{ jsonData.indirizzo }}</span>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-label-data">Codice Fiscale:</label>
                        <span class="sa-data">{{ jsonData.codiceFiscaòe }}</span>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-label-data">Partita I.V.A.:</label>
                        <span class="sa-data">{{ jsonData.partitaIva }}</span>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-label-data">Telefono:</label>
                        <span class="sa-data">{{ jsonData.telefono }}</span>
                    </b-col>
                </b-row>
            </b-card>
        </div>
    </b-modal>
</template>

<script>
import UtilityMixin from "../../utility/UtilityMixin";
// import DatePicker from "vue2-datepicker";
export default {
    mixins: [UtilityMixin],
    // components: { DatePicker },
    data() {
        return {
            isNew: true,
            isReadonly: false,
            isRequired: false,
            pathResource: "/anagrafica",
            pathResourceTipiAnagrafica: "/dizionariotipi",
            titoloModal: "",
            tipoAnagraficaOptions: [],
            jsonData: {},
        };
    },
    methods: {
        openModal(item) {
            let me = this;
            me.titoloModal = "Visualizza Anagrafica";
            me.jsonData = item;
            me.$refs.mdlVisualizzaAnagrafica.show();
        },
    },
};
</script>
