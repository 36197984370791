<template>
    <sa-page-layout :pathResource="pathResource" :btnBackVisible="true" :btnSaveVisible="true" :linkback="linkback" :data="data" :showModalLoading="showModalLoading">
        <template slot="table-header">
            <span class="sa-form-label">Seleziona il modulo da caricare</span>
        </template>
        <template slot="table-body">
            <b-row style="margin-top: 2rem;">
                <b-card style="width: 100%; height: 100%; padding: 2px; background-color: #93dad4;" class="file-upload">
                    <b-form-group class="file-upload-wrapper w-full h-screen">
                        <label>Inserisci File .jar:</label>
                        <b-form-file style="width: 100%; height: oversize;" size="lg" dropzone="100%" placeholder="Seleziona o Trascina qui il tuo File" drop-placeholder="Rilascia il file" ref="file-input" class="input-field" accept=".jar"></b-form-file>
                    </b-form-group>
                </b-card>
            </b-row>
            <b-row>
                <b-col sm="12" md="12" lg="12" xl="12">
                    <b-button class="float-sm-right btn-toolbar" variant="danger sa-margin-right" size="sm" @click="onDelete" v-b-modal.modal-center><b-icon icon="x-circle" />Cancella</b-button>
                </b-col>
            </b-row>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
    components: { SaPageLayout },
    data() {
        return {
            pathResource: "/application",
            linkback: "/moduli",
            id: "",
            showModalLoading: false,
            data: {},
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                me.showModalLoading = true;
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                axios.get(link + me.id).then((response) => {
                    me.data = response.data.data;
                    me.showModalLoading = false;
                });
            }
        },
        onDelete() {
            this.$refs["file-input"].reset();
        },
    },
};
</script>
<style scoped>
.inputField {
    border: 1px solid;
    height: 100px;
    width: 100%;
    text-align: center;
    background-color: #c2c7cc;
}
.file-upload {
    box-shadow: 2px 2px 9px 2px #ccc;
    border-radius: 1rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 1rem;
    width: 60%;
    margin: 0 auto;
}
</style>
