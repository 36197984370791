<template>
    <div class="sa-tab-scroll">
        <div class="sa-list-component-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" stacked="sm" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
                    <template #cell(actions)="{ item }">
                        <!-- <b-icon v-if="item.compilata" icon="check-square-fill" variant="success"></b-icon>
                <b-icon v-else icon="x-square-fill" variant="danger"></b-icon> -->
                        <b-container class="bv-example-row">
                            <b-button v-if="item.compilata" size="sm" variant="outline-success" disabled>
                                <font-awesome-icon icon="check"></font-awesome-icon>
                            </b-button>
                            <b-button v-else size="sm" variant="outline-danger" disabled>
                                <font-awesome-icon icon="check"></font-awesome-icon>
                            </b-button>
                            <b-button v-if="item.path !== null && item.path !== ''" size="sm" title="Modifica Richiesta" variant="outline-success" @click="onInserisciModifica(item)">
                                <font-awesome-icon icon="edit"></font-awesome-icon>
                            </b-button>
                            <b-button size="sm" v-b-tooltip.hover title="Visualizza Richiesta" variant="outline-secondary" @click="onClickVisualizzaScala(item)" class="mr-1 no-text">
                                <font-awesome-icon icon="eye" />
                            </b-button>
                            <!-- <b-button size="sm" variant="btn btn-outline-info waves-effect waves-light width-sm no-text" @click="onVisualizzaPdf(item)" :disabled="!item.compilata">
                <i class="bi bi-filetype-pdf"></i></b-button>  
                         <print-component v-if="item.compilata" :data="getPrintData(item.path)" :linkPrintData="linkPrintData(item)" typeButton="small"></print-component> -->
                        </b-container>
                    </template>
                </b-table>
            </div>
        </div>

        <b-modal scrollable b-modal ref="mdlSchedeValutazioneBrass" id="mdlSchedeValutazioneBrass" size="xl" style="height: 100%" @ok="onAddScheda" :title="'Scheda: ' + rigaSelezionata.tipoScheda">
            <rsa-scala-brass-edit-component @update="onUpdateScheda"></rsa-scala-brass-edit-component>
        </b-modal>
        <b-modal scrollable b-modal ref="mdlSchedeValutazioneIda" id="mdlSchedeValutazioneIda" size="xl" style="height: 100%" @ok="onAddScheda" :title="'Scheda: ' + rigaSelezionata.tipoScheda">
            <rsa-scala-ida-edit-component @update="onUpdateScheda"></rsa-scala-ida-edit-component>
        </b-modal>
        <b-modal scrollable b-modal ref="mdlSchedeValutazioneMews" id="mdlSchedeValutazioneMews" size="xl" style="height: 100%" @ok="onAddScheda" :title="'Scheda: ' + rigaSelezionata.tipoScheda">
            <rsa-scala-mews-edit-component @update="onUpdateScheda"></rsa-scala-mews-edit-component>
        </b-modal>
        <b-modal scrollable b-modal ref="mdlSchedeValutazioneBrassView" id="mdlSchedeValutazioneBrassView" size="xl" style="height: 100%" @ok="onCloseSchedaView" :title="'Scheda: ' + rigaSelezionata.tipoScheda" ok-only>
            <rsa-scala-brass-view-component @update="onUpdateScheda"></rsa-scala-brass-view-component>
        </b-modal>
        <b-modal scrollable b-modal ref="mdlSchedeValutazioneIdaView" id="mdlSchedeValutazioneIdaView" size="xl" style="height: 100%" @ok="onCloseSchedaView" :title="'Scheda: ' + rigaSelezionata.tipoScheda" ok-only>
            <rsa-scala-ida-view-component @update="onUpdateScheda"></rsa-scala-ida-view-component>
        </b-modal>
        <b-modal scrollable b-modal ref="mdlSchedeValutazioneMewsView" id="mdlSchedeValutazioneMewsView" size="xl" style="height: 100%" @ok="onCloseSchedaView" :title="'Scheda: ' + rigaSelezionata.tipoScheda" ok-only>
            <rsa-scala-mews-view-component @update="onUpdateScheda"></rsa-scala-mews-view-component>
        </b-modal>
        <!-- <pdf-print-view-embedded ref="pdfPrintViewEmbedded" :pdf="signedPdfViewer"></pdf-print-view-embedded> -->
        <b-modal scrollable b-modal ref="mdlSchedeValutazioneAllegatoA" id="mdlSchedeValutazioneAllegatoA" size="xl" style="height: 100%" @ok="onSalvaAllegatoA" :title="'Scheda: ' + rigaSelezionata.tipoScheda">
            <rsa-scheda-valutazione-allegato-a-edit-component @update="onUpdateScheda"></rsa-scheda-valutazione-allegato-a-edit-component>
        </b-modal>
        <b-modal scrollable b-modal ref="mdlSchedeValutazioneBradenEdit" id="mdlSchedeValutazioneBradenEdit" size="xl" style="height: 100%" @ok="onAddScheda" :title="'Scheda: ' + rigaSelezionata.tipoScheda">
            <rsa-scala-braden-edit-component @update="onUpdateScheda"></rsa-scala-braden-edit-component>
            <!-- <rsa-scala-brass-edit-component @update="onUpdateScheda"></rsa-scala-brass-edit-component> -->
        </b-modal>
        <b-modal scrollable b-modal ref="mdlSchedeValutazioneTinettiEdit" id="mdlSchedeValutazioneTinettiEdit" size="xl" style="height: 100%" @ok="onAddScheda" :title="'Scheda: ' + rigaSelezionata.tipoScheda">
            <rsa-scala-tinetti-edit-component @update="onUpdateScheda"></rsa-scala-tinetti-edit-component>
            <!-- <rsa-scala-brass-edit-component @update="onUpdateScheda"></rsa-scala-brass-edit-component> -->
        </b-modal>
        <b-modal scrollable b-modal ref="mdlScalaValutazioneBarthelEdit" id="mdlScalaValutazioneBarthelEdit" size="xl" style="height: 100%" @ok="onAddScheda" :title="'Scheda: ' + rigaSelezionata.tipoScheda">
            <rsa-scala-barthel-edit-component @update="onUpdateScheda"></rsa-scala-barthel-edit-component>
        </b-modal>
        <b-modal scrollable b-modal ref="mdlScalaValutazioneMinimentalShortEdit" id="mdlScalaValutazioneMinimentalShortEdit" size="xl" style="height: 100%" @ok="onAddScalaMinimental" :title="'Scheda: ' + rigaSelezionata.tipoScheda">
            <rsa-scala-minimental-short-edit-component ref="cmpRsaScalaMinimentalShort" @update="onUpdateScheda"></rsa-scala-minimental-short-edit-component>
        </b-modal>
        <b-modal scrollable b-modal ref="mdlSchedeValutazioneBradenView" id="mdlSchedeValutazioneBradenView" size="xl" style="height: 100%" :title="'Scheda: ' + rigaSelezionata.tipoScheda" ok-only>
            <rsa-scala-braden-view-component></rsa-scala-braden-view-component>
        </b-modal>
        <b-modal scrollable b-modal ref="mdlSchedeValutazioneTinettiView" id="mdlSchedeValutazioneTinettiView" size="xl" style="height: 100%" :title="'Scheda: ' + rigaSelezionata.tipoScheda" ok-only>
            <rsa-scala-valutazione-tinetti-view-component></rsa-scala-valutazione-tinetti-view-component>
        </b-modal>
        <b-modal scrollable b-modal ref="mdlScalaValutazioneBarthelView" id="mdlScalaValutazioneBarthelView" size="xl" style="height: 100%" :title="'Scheda: ' + rigaSelezionata.tipoScheda" ok-only>
            <rsa-scala-barthel-view-component></rsa-scala-barthel-view-component>
        </b-modal>
        <!--     <b-modal scrollable b-modal ref="mdlScalaValutazioneMinimentalShortView" id="mdlScalaValutazioneMinimentalShortView" size="xl" style="height: 100%" :title="'Scheda: ' + rigaSelezionata.tipoScheda" ok-only> -->
        <!--    <rsa-scala-minimental-short-edit-component ref="cmpRsaScalaMinimentalShort" @update="onUpdateScheda"></rsa-scala-minimental-short-edit-component> -->
        <!-- </b-modal> -->
        <rsa-scala-minimental-short-modal-view-component ref="cmpScalaValutazioneMinimentalShortView"></rsa-scala-minimental-short-modal-view-component>
    </div>
</template>

<script>
import axios from "axios";
//import PdfPrintViewEmbedded from "../../../app/utility/components/PdfPrintViewEmbedded.vue";
import RsaScalaBrassEditComponent from "./RsaScalaBrassEditComponent.vue";
import RsaScalaIdaEditComponent from "./RsaScalaIdaEditComponent.vue";
import RsaScalaMewsEditComponent from "./RsaScalaMewsEditComponent.vue";
import RsaScalaBrassViewComponent from "./RsaScalaBrassViewComponent.vue";
import RsaScalaIdaViewComponent from "./RsaScalaIdaViewComponent.vue";
import RsaScalaMewsViewComponent from "./RsaScalaMewsViewComponent.vue";
import RsaSchedaValutazioneAllegatoAEditComponent from "./RsaSchedaValutazioneAllegatoAEditComponent.vue";
import RsaScalaBradenEditComponent from "./RsaScalaBradenEditComponent.vue";
import RsaScalaTinettiEditComponent from "./RsaScalaTinettiEditComponent.vue";
import RsaScalaBarthelEditComponent from "./RsaScalaBarthelEditComponent.vue";
import RsaScalaMinimentalShortEditComponent from "./RsaScalaMinimentalShortEditComponent.vue";
import UtilityMixin from "../../../utility/UtilityMixin";
import RsaScalaValutazioneTinettiViewComponent from "../../components/rsascalevalutazione/RsaScalaTinettiViewComponent.vue";
import RsaScalaBarthelViewComponent from "../../components/rsascalevalutazione/RsaScalaBarthelViewComponent.vue";
import RsaScalaBradenViewComponent from "../../components/rsascalevalutazione/RsaScalaBradenViewComponent.vue";
import RsaScalaMinimentalShortModalViewComponent from "../../components/rsascalevalutazione/RsaScalaMinimentalShortModalViewComponent.vue";

export default {
    mixins: [UtilityMixin],
    /*  props: {
        scala: Boolean,
    }, */
    components: {
        RsaScalaBrassEditComponent,
        RsaScalaIdaEditComponent,
        RsaScalaMewsEditComponent,
        RsaScalaBrassViewComponent,
        RsaScalaIdaViewComponent,
        RsaScalaMewsViewComponent,
        RsaSchedaValutazioneAllegatoAEditComponent,
        RsaScalaBradenEditComponent,
        RsaScalaTinettiEditComponent,
        RsaScalaBarthelEditComponent,
        RsaScalaMinimentalShortEditComponent,
        RsaScalaValutazioneTinettiViewComponent,
        RsaScalaBarthelViewComponent,
        RsaScalaBradenViewComponent,
        RsaScalaMinimentalShortModalViewComponent,
        // PdfPrintViewEmbedded
    },
    data() {
        return {
            rows: 0,
            perPage: 50,
            currentPage: 1,
            pathResource: "/rsaschedevalutazione",
            sezione: "SCALEVALUTAZIONE",
            pathResourceStampa: "/print/createreport",
            pathResourceSchedaValutazioneAllegatoA: "/rsaschedevalutazioneallegatoa",
            pathResourceSchedaValutazioneAnamnesiaStoriaClinica: "/rsaschedaanamnesiastoriaclinica",
            signedPdfViewer: null,
            filtro: {},
            items: [],
            /*items2: [{ tipoScheda: "" }],*/
            rigaSelezionata: {},
            nomeModal: "",
            scheda: [],
            listKey: 0,
            idRichiesta: "",
            isScalaValutazioneRiferimentiDiagnostici: false,
            fields: [
                {
                    label: "Tipo Scheda",
                    key: "tipoScheda",
                },
                {
                    label: "",
                    key: "actions",
                    class: "sa-table-column-action-double",
                },
            ],
        };
    },
    /*  props: {
    idPropostaAccesso: String,
  }, */
    mounted() {
        // let me = this;
        // let sessionStorage = window.sessionStorage;
        // if (sessionStorage["filtro"]) {
        //   me.filtro = JSON.parse(sessionStorage["filtro"]);
        // }
        // me.idRichiesta = me.$route.params.id;
        // me.loadData();
    },
    methods: {
        init(id, isScala) {
            let me = this;
            me.idRichiesta = id;
            if (isScala) {
                me.isScalaValutazioneRiferimentiDiagnostici = isScala;
            }
            me.loadData();
        },
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.filtro.page = me.currentPage;
            me.filtro.forPage = me.perPage;
            me.filtro.sezione = me.sezione;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];
                    // response.data.data.list.forEach((element) => {
                    //     if (element.tipoScheda !== "ALLEGATO A") {
                    if (!me.isScalaValutazioneRiferimentiDiagnostici) {
                        me.items = response.data.data.list;
                    } else {
                        response.data.data.list.forEach((element) => {
                            switch (element.tipoScheda) {
                                case "SCALA BRADEN":
                                    me.items.push(element);
                                    break;
                                case "SCALA TINETTI":
                                    me.items.push(element);
                                    break;
                                case "SCALA MINIMENTAL SHORT":
                                    me.items.push(element);
                                    break;
                                case "SCALA BARTHEL":
                                    me.items.push(element);
                                    break;
                            }
                        });
                    }

                    //     }
                    // });
                    me.rows = response.data.data.recordsNumber;
                    me.checkSchedeCompilate();
                    me.listKey++;
                    this.$emit("afterLoadData");
                })
                .catch(() => {
                    this.$emit("afterLoadData");
                });
        },
        checkSchedeCompilate() {
            let me = this;
            me.items.forEach((element) => {
                if (element.path !== null && element.path !== "") {
                    let link = process.env.VUE_APP_PATH_API + element.path + "/check/" + me.idRichiesta;
                    me.filtro.page = me.currentPage;
                    me.filtro.forPage = me.perPage;
                    axios
                        .get(link)
                        .then((response) => {
                            me.listKey++;
                            element.compilata = response.data.esito;
                        })
                        .catch(() => {
                            element.compilata = false;
                        });
                } else {
                    element.compilata = false;
                }
            });
        },
        onPrint() {
            console.log("Eccomi");
        },
        onModifica() {
            console.log("Modifica");
        },
        onInserisciModifica(value) {
            let me = this;
            me.rigaSelezionata = value;
            switch (me.rigaSelezionata.path) {
                case "/rsascalabrassrichieste":
                    me.nomeModal = "mdlSchedeValutazioneBrass";
                    break;
                case "/rsascalaidarichieste":
                    me.nomeModal = "mdlSchedeValutazioneIda";
                    break;
                case "/rsascalamewsrichieste":
                    me.nomeModal = "mdlSchedeValutazioneMews";
                    break;
                case "/rsaschedevalutazioneallegatoa":
                    me.nomeModal = "mdlSchedeValutazioneAllegatoA";
                    break;
                case "/rsaschedaanamnesiastoriaclinica":
                    me.nomeModal = "mdlSchedaValutazioneAnamnesiaStoriaClinica";
                    break;
                case "/rsascalabradenrichieste":
                    me.nomeModal = "mdlSchedeValutazioneBradenEdit";
                    break;
                case "/rsascalatinettirichieste":
                    me.nomeModal = "mdlSchedeValutazioneTinettiEdit";
                    break;
                case "/rsascalabarthelrichieste":
                    me.nomeModal = "mdlScalaValutazioneBarthelEdit";
                    break;
                case "/rsascalaminimentalshort":
                    me.nomeModal = "mdlScalaValutazioneMinimentalShortEdit";
                    break;
            }
            me.$refs[me.nomeModal].show();
        },
        onClickVisualizzaScala(value) {
            let me = this;
            /* console.log(value.path); */
            me.rigaSelezionata = value;
            switch (me.rigaSelezionata.path) {
                case "/rsascalabrassrichieste":
                    me.nomeModal = "mdlSchedeValutazioneBrassView";
                    break;
                case "/rsascalaidarichieste":
                    me.nomeModal = "mdlSchedeValutazioneIdaView";
                    break;
                case "/rsascalamewsrichieste":
                    me.nomeModal = "mdlSchedeValutazioneMewsView";
                    break;
                case "/rsascalabradenrichieste":
                    me.nomeModal = "mdlSchedeValutazioneBradenView";
                    break;
                case "/rsascalatinettirichieste":
                    me.nomeModal = "mdlSchedeValutazioneTinettiView";
                    break;
                case "/rsascalabarthelrichieste":
                    me.nomeModal = "mdlScalaValutazioneBarthelView";
                    break;
                case "/rsascalaminimentalshort":
                    me.nomeModal = "cmpScalaValutazioneMinimentalShortView";
                    break;
            }
            me.$refs[me.nomeModal].show();
        },
        onAddScheda(bvModalEvent) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.rigaSelezionata.path + "/listaRisposte";
            axios
                .post(link, JSON.stringify(me.scheda))
                .then(() => {
                    me.$refs[me.nomeModal].hide();
                    this.$bvToast.toast("Salvataggio avvenuto con successo", {
                        title: this.titleModal,
                        variant: "success",
                        autoHideDelay: 1000,
                    });
                    me.loadData();
                })
                .catch((error) => {
                    me.showModalLoading = false;
                    this.$bvModal
                        .msgBoxOk(error.response.data.messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then((value) => {
                            this.boxTwo = value;
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                });
            bvModalEvent.preventDefault();
        },
        onUpdateScheda(data) {
            let me = this;
            me.scheda = data;
        },
        onVisualizzaPdf(value) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + value.path + "/print/" + me.idPropostaAccesso;
            axios.get(link).then((response) => {
                let printData = response.data.data;
                let linkReport = process.env.VUE_APP_PATH_API + me.pathResourceStampa;
                axios.post(linkReport, printData).then((response) => {
                    me.$refs.pdfPrintViewEmbedded.show();
                    me.signedPdfViewer = "data:application/pdf;base64," + response.data.base64;
                });
            });
        },
        onCloseSchedaView() {
            let me = this;
            me.$refs[me.nomeModal].hide();
            me.loadData();
        },
        onSalvaAllegatoA() {
            let me = this;
            UtilityMixin.methods.saveResource(me.pathResourceSchedaValutazioneAllegatoA, me.scheda, me.saveSucessResponse, me.saveErrorResponse);
        },
        saveSucessResponse(response) {
            let me = this;
            this.$emit("successRestCall", response);
            this.$emit("afterSaveRequest");
            me.loadData();
        },
        saveErrorResponse(response) {
            if (response.response.status === 400) {
                this.$emit("errorRestCall", response);
            } else {
                this.$bvToast.toast("Errore nel salvarei dati " + response.message, {
                    title: "",
                    variant: "danger",
                    solid: true,
                });
            }
        },
        onAddScalaMinimental() {
            let me = this;
            UtilityMixin.methods.saveResource(me.rigaSelezionata.path, me.scheda, me.saveSucessResponseScalaMinimental, me.saveErrorResponseScalaMinimental);
        },
        saveSucessResponseScalaMinimental() {
            let me = this;
            me.loadData();
        },
        saveErrorResponseScalaMinimental(error) {
            if (error.response.status === 400) {
                this.$emit("errorRestCall", error);
            } else {
                this.$bvToast.toast("Errore nel salvarei dati " + error.message, {
                    title: "",
                    variant: "danger",
                    solid: true,
                });
            }
        },
    },
};
</script>

<style></style>
