<template>
  <sa-page-layout :btnSaveVisible="true" :data="jsonData" :btnAnnullaVisible="true" :showModalLoading="showModalLoading" :linkback="linkback" :pathResource="pathResource" @aftersave="onAfterSave" class="sa-no-space">
    <template slot="toolbar">
      <b-button v-if="id !== '-1'" variant="outline-success btn-toolbar sa-margin-left float-sm-left" v-b-modal.mdlAllegati>
        <b-icon icon="file-earmark-plus" aria-hidden="true"></b-icon>
        Allegati
      </b-button>
    </template>
    <template slot="table-body">
      <b-tabs content-class="mt-3" ref="tabs" class="sa-tab" style="height: 100%" fill>
        <b-tab title="Scheda Valutazione Esigenze Immediate" active>
          <giubileo-scheda-edit-component @update="onUpdateJsonData"></giubileo-scheda-edit-component>
        </b-tab>
        <b-tab title="Allegati">
          <allegato-model-component :idRisorsa="id" @updateFiles="onUpdateFile()" :jsonData="jsonData" tipoDocumento="SCHEDAVALUTAZIONEESIGENZEIMMEDIATE"></allegato-model-component>
        </b-tab>
      </b-tabs>
    </template>
  </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import AllegatoModelComponent from "../../utility/components/AllegatoModelComponent.vue";
import GiubileoSchedaEditComponent from "../components/GiubileoSchedaEditComponent.vue";
export default {
  components: { SaPageLayout, GiubileoSchedaEditComponent, AllegatoModelComponent },
  data() {
    return {
      id: "-1",
      pathResource: "/giubileo",
      linkback: "/giubileoscheda",
      showModalLoading: false,
      jsonData: {},
    };
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
  },
  methods: {
    onUpdateJsonData(data) {
      let me = this;
      me.jsonData = data;
    },
    onAfterSave() {
      let me = this;
      me.id = me.$route.params.id;
      if (me.id === "-1") {
        me.$router.replace(me.linkback).catch((err) => {
          console.log(err);
        });
      }
    },
  },
};
</script>

<style></style>
