<template>
    <sa-page-layout slot="table-body" :pathResource="pathResource" :linkback="linkback" :btnCloseVisible="true" :data="jsonDataSettings" :mostraModelloCaricamento="mostraModelloCaricamento">
        <template slot="table-body">
            <rsa-configurazione-settings-view-component ref="RsaConfigurazioneSettingsViewComponent" @beforeLoadData="primaDelCarimentoDati" @afterLoadData="dopoDelCarimentoDati"> </rsa-configurazione-settings-view-component>
        </template>
    </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import RsaConfigurazioneSettingsViewComponent from "../components/RsaConfigurazioneSettingsViewComponent.vue";

export default {
    components: { SaPageLayout, RsaConfigurazioneSettingsViewComponent },

    data() {
        return {
            mostraModelloCaricamento: false,
            pathResource: "rsasettings",
            linkback: "/configurazioni/rsasettings",
            jsonDataSettings: {},
        };
    },

    methods: {
        onUpdateJsonData(data) {
            let me = this;
            me.jsonDataSettings = data;
        },

        primaDelCarimentoDati() {},
        dopoDelCarimentoDati() {
            this.mostraModelloCaricamento = false;
        },
    },
};
</script>
