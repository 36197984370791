<template>
	<div class="sa-login-page">
		<div class="sa-login-page-box-login">
			<b-row>
				<b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
					<svg data-v-4f7685dd="" class="svg-inline--fa fa-user fa-w-14" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg="">
						<path
							fill="currentColor"
							d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"
						></path>
					</svg>
					<span class="sa-login-page-title" style="margin-top: 3px; margin-left: 4px; position: absolute">Autenticazione</span>
				</b-col>
			</b-row>
			<div class="sp-login-page-space"></div>
			<b-row>
				<b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
					<div class="text-center">
						<a href="#" class="italia-it-button italia-it-button-size-m button-spid" spid-idp-button="#spid-idp-button-medium-get" aria-haspopup="true" aria-expanded="false" @click="onClickLoginSpid">
							<span class="italia-it-button-icon"><img src="img/spid-ico-circle-bb.svg" onerror="this.src='img/spid-ico-circle-bb.png'; this.onerror=null;" alt="" /></span>
							<span class="italia-it-button-text">Entra con SPID</span>
						</a>
					</div>
				</b-col>
			</b-row>
			<div class="sp-login-page-space"></div>
			<div data-v-4f7685dd="" class="row">
				<div data-v-4f7685dd="" class="text-center col-sm"><img data-v-4f7685dd="" alt="Logo" src="/img/loghi.spid.png" /></div>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from "vue";
export default {
	data() {
		return {
			sfondo: "css/" + Vue.prototype.appConfig.css + "/images/sfondo.jpg",
		};
	},
	mounted() {
		document.body.style.backgroundImage = "url(" + this.sfondo + ")";
		document.body.style.backgroundSize = "100%";
		document.body.style.backgroundRepeat = "no-repeat";
		document.body.style.backgroundColor = "var(--bg-main-color)";
	},
	methods: {
		onClickLoginSpid() {
			// let spidParams = Vue.prototype.appConfig.spidParams;
			// console.log(spidParams);
			// console.log("Login Spid");
			// let authLink = spidParams.urlRequest + "/oidc/auth?client_id=" + spidParams.clientId + "&redirect_uri=" + spidParams.urlCallBack + "&response_type=" + spidParams.responseType + "&scope=" + spidParams.scope;
			// console.log(authLink);
			// console.log("Sisto");
			//window.location.replace(authLink);
		},
	},
};
</script>

<style>
/*

Questo file è un de-offuscamento / de-minificazione del file
spid-sp-access-button.min.css. Non si tratta del file originale ma di un lavoro
di reverse engineering, il cui obiettivo è comprendere cosa faccia questo
codice (non volete certo inserire del codice nelle vostre applicazioni senza
averlo prima letto, no?).

Per poter arrivare allo stesso risultato potete usare CyberChef con la funzione
"CSS beautify" e successivamente cercare il senso delle varie lettere.

*/

/* @import url(titillium.css); */
.sa-login-page-title {
	color: #3b6994 !important;
	font-size: 1.4rem;
	font-weight: 700;
	padding-left: 5px;
}
.sa-login-page-box-login .svg-inline--fa.fa-w-14 {
	width: 0.875em;
	font-size: 24px;
	color: #3b6994 !important;
}
.italia-it-button {
	display: inline-block;
	position: relative;
	padding: 0;
	color: #fff;
	font-family: "Titillium Web", HelveticaNeue, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
	font-weight: 600;
	line-height: 1em;
	text-decoration: none;
	border: 0;
	text-align: center;
	cursor: pointer;
	overflow: hidden;
}
.italia-it-button-icon,
.italia-it-button-text {
	display: block;
	float: left;
}
.italia-it-button-icon {
	margin: 0 -0.4em 0 0;
	padding: 0.6em 0.8em 0.5em;
	border-right: rgba(255, 255, 255, 0.1) 0.1em solid;
}
.italia-it-button-text {
	padding: 0.95em 1em 0.85em 1em;
	font-size: 1.15em;
	text-align: center;
}
/* svg {
    width: 1.8em;
    height: 1.8em;
    fill: #fff;
} */
.italia-it-block {
	display: block;
}
.italia-it-button-size-s {
	font-size: 10px;
	width: 150px;
}
.italia-it-button-size-s > span img {
	width: 19px;
	height: 19px;
	border: 0;
}
.italia-it-button-size-m {
	font-size: 15px;
	/* width: 220px; */
}
.italia-it-button-size-m > span img {
	width: 29px;
	height: 29px;
	border: 0;
}
.italia-it-button-size-l {
	font-size: 20px;
	width: 280px;
}
.italia-it-button-size-l > span img {
	width: 38px;
	height: 38px;
	border: 0;
}
.italia-it-button-size-xl {
	font-size: 25px;
	width: 340px;
}
.italia-it-button-size-xl > span img {
	width: 47px;
	height: 47px;
	border: 0;
}
.button-spid {
	background-color: #06c !important;
	color: #fff;
}
.button-spid:hover {
	background-color: #334663 !important;
	color: #fff;
}
.button-spid svg {
	fill: #fff;
}
.button-spid:hover {
	background-color: #036;
	color: #fff;
}
.button-spid:active {
	background-color: #83beed;
	color: #036;
}
.spid-idp-button {
	position: absolute;
	z-index: 1039;
	display: none;
}
.spid-idp-button .spid-idp-button-menu,
.spid-idp-button .spid-idp-button-panel {
	list-style: none;
	background: white;
	border: solid 1px #ddd;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	overflow: visible;
	padding: 0;
	margin: 0;
}
#spid-idp-button-small-get,
#spid-idp-button-medium-get,
#spid-idp-button-small-post,
#spid-idp-button-medium-post {
	width: 230px;
}
#spid-idp-button-large-get,
#spid-idp-button-large-post {
	width: 270px;
}
#spid-idp-button-xlarge-get,
#spid-idp-button-xlarge-post {
	width: 330px;
}
.spid-idp-button .spid-idp-button-panel {
	padding: 10px;
}
.spid-idp-button.spid-idp-button-tip {
	margin-top: 8px;
}
.spid-idp-button.spid-idp-button-tip:before {
	position: absolute;
	top: -6px;
	left: 9px;
	content: "";
	border-left: 7px solid transparent;
	border-right: 7px solid transparent;
	border-bottom: 7px solid #ddd;
	display: inline-block;
}
.spid-idp-button.spid-idp-button-tip:after {
	position: absolute;
	top: -5px;
	left: 10px;
	content: "";
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-bottom: 6px solid white;
	display: inline-block;
}
.spid-idp-button.spid-idp-button-tip.spid-idp-button-anchor-right:before {
	left: auto;
	right: 9px;
}
.spid-idp-button.spid-idp-button-tip.spid-idp-button-anchor-right:after {
	left: auto;
	right: 10px;
}
.spid-idp-button.spid-idp-button-scroll .spid-idp-button-menu,
.spid-idp-button.spid-idp-button-scroll .spid-idp-button-panel {
	max-height: 180px;
	overflow: auto;
}
.spid-idp-button .spid-idp-button-menu li {
	list-style: none;
	padding: 0 0;
	margin: 0;
	line-height: 18px;
}
.spid-idp-button .spid-idp-button-menu li > a,
.spid-idp-button .spid-idp-button-menu label {
	display: block;
	font-family: "Titillium Web", HelveticaNeue, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
	font-weight: 600;
	font-size: 0.9em;
	color: #06c;
	text-decoration: underline;
	line-height: 18px;
	padding-top: 5px;
	white-space: nowrap;
	border-bottom: 1px solid #ddd;
}
.spid-idp-button .spid-idp-button-menu li > a:hover,
.spid-idp-button .spid-idp-button-menu label:hover {
	color: #036;
	cursor: pointer;
	background-color: #f0f0f0;
}
.spid-idp-button .spid-idp-button-menu li > a img {
	height: 25px;
	padding: 10px 0 10px 10px;
	border: 0;
}
.idp-button-idp-logo {
	font-size: 100%;
	height: 10%;
	width: 100%;
	border: 0;
	border-bottom: 1px solid #ccc;
	background-color: #fff;
	padding: 15px;
	text-align: left;
	cursor: pointer;
}
.idp-button-idp-logo:hover {
	background-color: #f0f0f0;
}
.idp-button-idp-logo img {
	height: 25px;
	vertical-align: middle;
	cursor: pointer;
}
.spid-top-menu-space-10 > a img {
	margin-top: 10px;
}
.spid-top-menu-space-20 > a img {
	margin-top: 20px;
}
.spid-top-menu-line {
	border-top: 5px solid #000;
}
.spid-idp-support-link > a {
	padding: 5px 0 10px 10px;
}
.spid-sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}
</style>
