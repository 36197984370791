<template>
    <b-modal size="lg" ref="modalAggiungiPrestazione" id="modalAssociaUtente" title="Elenco Prestazione" scrollable>
        <div class="sa-flexbox">
            <div class="sa-flexbox-header">
                <b-form @submit.prevent="onSubmit">
                    <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Cod. Nomenclatore</label>
                            <b-form-input v-model="filtro.codicePrestazione" type="search" placeholder="Cod. Nomenclatore"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="4" md="8" lg="8" xl="8">
                            <label class="sa-label-data">Desc. Nomenclatore</label>
                            <b-form-input v-model="filtro.descrizione" type="search" id="descrizione" placeholder="Desc. Nomenclatore"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="sa-padding-right text-right">
                            <b-button type="submit" variant="info">{{ this.$i18n.t("global.lblFind") }}</b-button>
                            <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("global.lblResetFilter") }}</b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </div>
            <div class="sa-flexbox-body">
                <div class="b-table-sticky-header">
                    <b-table sticky-header ref="tblNomenclatore" :filter="filter" stacked="lg" striped hover :items="nomenclatore" :fields="fieldsNomenclatore" :current-page="currentPage" sort-icon-left head-variant="light" class="sa-b-table">
                        <template v-slot:cell(descrizionePrestazione)="{ item }">
                            <a>
                                {{ item.descrizione }}
                                <br />
                                <hr />
                                <strong>Cod. Nomenclatore</strong>
                                {{ item.codicePrestazione }}
                                <strong>Cod. Catalogo</strong>
                                {{ item.codicePrestazioneAgg }}
                                <strong style="padding-right: 5px">Prezzo:</strong>
                                <i>{{ item.importoPrestazione }} €</i>
                                <!-- <hr />
                            <strong>{{ onTraduci("agenda.lblDuration") }}</strong>
                            {{ item.durata }} {{ onTraduci("agenda.lblMinutes") }} -->
                            </a>
                        </template>
                        <template v-slot:cell(aggiungi)="row">
                            <b-button size="sm" class="mr-1 no-text" variant="outline-info" @click="onAggiungi(row.item)">
                                <b-icon icon="plus-circle" variant="outline-primary" />
                            </b-button>
                        </template>
                    </b-table>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
import axios from "axios";
export default {
    data() {
        return {
            filtro: { codiceRegione: null },
            filter: [],
            nomenclatore: [],
            pathNomenclatore: "/nomenclatore",
            currentPage: 0,
            perPage: 50,
            fieldsNomenclatore: [
                {
                    label: this.$i18n.t("patient.agenda.lblDescriptionPerformance"),
                    key: "descrizionePrestazione",
                    sortable: true,
                },
                {
                    label: this.$i18n.t("patient.diet.lblNote"),
                    key: "nota",
                    sortable: true,
                },
                {
                    label: "",
                    key: "aggiungi",
                },
            ],
        };
    },
    methods: {
        show(codiceRegione) {
            let me = this;
            me.filtro.codiceRegione = codiceRegione;
            me.$refs.modalAggiungiPrestazione.show();
        },
        loadNomenclatore() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathNomenclatore;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.nomenclatore = [];
                    me.nomenclatore = response.data.data;
                })
                .catch((error) => {
                    let message = error.message;
                    if (error.response) {
                        if (error.response.data.messaggio) {
                            message = error.response.data.messaggio;
                        }
                        console.log(error.response);
                    }
                    this.$bvModal
                        .msgBoxOk(message, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then(() => {})
                        .catch((err) => {
                            console.log(err);
                        });
                });
        },
        onSubmit() {
            let me = this;
            me.loadNomenclatore();
        },
        onReset() {},
        onAggiungi(item) {
            this.$emit("selectPrestazione", item);
        },
    },
};
</script>

<style></style>
