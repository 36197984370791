var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100%"}},[_c('div',{staticClass:"sa-flexbox",staticStyle:{"padding-bottom":"20px"}},[_c('div',{staticClass:"sa-flexbox-header"}),_c('div',{staticClass:"sa-flexbox-body"},[_c('div',{staticClass:"b-table-sticky-header"},[_c('span',{staticClass:"sa-flexbox-body-title"},[_vm._v("Impegnativa")]),_c('b-button',{staticClass:"mb-2",attrs:{"size":"sm","variant":"outline-success outline-primary-noborder sa-margin-right float-sm-right","hidden":_vm.btnNuovaImpegnativaHidden},on:{"click":_vm.nuovaImpegnativa}},[_c('font-awesome-icon',{attrs:{"icon":"plus"}}),_vm._v(" Aggiungi ")],1),_c('b-table',{key:_vm.listKey,ref:"table",staticClass:"sa-b-table",attrs:{"sticky-header":"","stacked":"lg","striped":"","hover":"","items":_vm.items,"fields":_vm.fields,"current-page":1,"per-page":_vm.perPage,"sort-icon-left":"","head-variant":"light"},scopedSlots:_vm._u([{key:"cell(tipoImpegnativa)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.impegnativaTipo || item.tipoImpegnativa)+" ")]}},{key:"cell(trattamentoDataInizio)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.trattamentoDataInizio)))])]}},{key:"cell(trattamentoDataFine)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.trattamentoDataFine)))])]}},{key:"cell(quote)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"sa-table-span-label-primary"},[_vm._v("Quota Totale: "+_vm._s(item.quotaTotale)+" €")]),_c('span',{staticClass:"sa-table-span-label-primary"},[_vm._v("Quota ASL: "+_vm._s(item.quotaAsl)+" € ("+_vm._s(item.quotaAslPercentuale)+"%)")]),_c('span',{staticClass:"sa-table-span-label-primary"},[_vm._v(" Quota Comune: "+_vm._s(item.quotaComune)+" € ("+_vm._s(item.quotaComunePercentuale)+"%)")]),_c('span',{staticClass:"sa-table-span-label-primary"},[_vm._v("Quota Utente: "+_vm._s(item.quotaUtente)+" € ("+_vm._s(item.quotaUtentePercentuale)+"%)")])]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('b-container',{staticClass:"bv-example-row"},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"size":"sm","title":"Duplica Impegnativa","variant":"outline-success no-text"},on:{"click":function($event){return _vm.onDuplicaImpegnativa(item)}}},[_c('font-awesome-icon',{attrs:{"icon":"plus"}})],1),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"size":"sm","title":"Modifica Impegnativa","variant":"outline-success"},on:{"click":function($event){return _vm.onEditImpegnativa(item)}}},[_c('font-awesome-icon',{attrs:{"icon":"edit"}})],1),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"size":"sm","title":"Cancella Impegnativa","variant":"outline-danger"},on:{"click":function($event){return _vm.onDeleteImpegnativa(item)}}},[_c('font-awesome-icon',{attrs:{"icon":"trash"}})],1)],1)]}}])})],1)]),_c('div',{staticClass:"sa-flexbox-footer",staticStyle:{"background-color":"ciano"}},[_c('b-row',{staticClass:"sa-label-info",staticStyle:{"margin-left":"0.5rem","margin-right":"0.5rem"}},[_c('b-col',{attrs:{"lg":"3"}},[_c('p',[_vm._v(_vm._s(this.$i18n.t("patients.lblTotalRecords"))+": "+_vm._s(_vm.rows))])]),_c('b-col',{attrs:{"lg":"9"}},[_c('b-pagination',{attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage,"align":"right","size":"sm"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1),_c('rsa-accettazione-impegnativa-edit-component',{ref:"cmpRsaAccettazioneImpegnativaEditComponent",attrs:{"idAccettazione":_vm.idAccettazione,"jsonDataAccettazione":_vm.jsonData},on:{"afterSaveRequest":_vm.onAfterSaveRequestRsaImpegnativaComponent}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }