<template>
    <b-modal ref="mdlDizionarioEdit" :title="isNew ? 'Crea voce Dizionario' : 'Aggiorna voce Dizionario'" @hidden="onCloseModal" @ok="onClickSaveVoceDizionario" centered no-close-on-backdrop no-close-on-esc hide-header-close size="lg">
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <span class="sa-form-label">Tipo</span>
                <b-form-select :options="tipoOptions" v-model="jsonDataDizionario.tipo" value="''" value-field="value" text-field="text" required>
                    <template #first>
                        <b-form-select-option :value="null" disabled> -Seleziona una tipologia- </b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>

            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <span class="sa-form-label">Voce</span>
                <b-form-input v-model="jsonDataDizionario.voce" required></b-form-input>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <span class="sa-form-label">Note</span>
                <b-form-textarea v-model="jsonDataDizionario.note" max-rows="10" rows="7"></b-form-textarea>
            </b-col>
        </b-row>
    </b-modal>
</template>

<script>
import UtilityMixin from "../../../utility/UtilityMixin";
import axios from "axios";

export default {
    mixins: [UtilityMixin],
    data() {
        return {
            isNew: false,
            pathResourceDizionario: "/rsadizionario",
            // saveInProgress: false,
            jsonDataDizionario: {
                id: null,
                tipo: null,
                voce: null,
                note: null,
            },
            tipoOptions: [
                { text: "AUSILIO", value: "AUSILIO" },
                { text: "ASSISTENZA", value: "ASSISTENZA" },
                { text: "REAZIONI DI DIFESA", value: "REAZIONI_DIFESA" },
                { text: "SIEROLOGIA", value: "SIEROLOGIA" },
                { text: "ALLERGIA", value: "ALLERGIA" },
                { text: "RIABILITAZIONE TECNICA", value: "RIABILITAZIONE_TECNICA" },
                { text: "RIABILITAZIONE OBIETTIVO", value: "RIABILITAZIONE_OBIETTIVO" },
                { text: "CUTE", value: "CUTE" },
                { text: "UNGHIE", value: "UNGHIE" },
                { text: "EDEMI", value: "EDEMI" },
                { text: "AUSILIO P.V.", value: "AUSILIO_PARAM_VITALI" },
                { text: "MOTIVAZIONE PRESENZE/USCITE", value: "MOTIVAZIONE_PRESENZE_USCITE" },
            ],
        };
    },
    methods: {
        onCloseModal() {
            let me = this;
            me.jsonDataDizionario = {};
            //if (!this.saveInProgress) {
            me.$emit("onCloseModal");
            // }
            // this.saveInProgress = false;
        },

        nuovaVoceDizionario() {
            let me = this;
            me.isNew = true;
            me.jsonDataDizionario = {
                id: null,
                tipo: null,
                voce: null,
                note: null,
            };
            me.$refs.mdlDizionarioEdit.show();
        },

        editVoceDizionario(item) {
            let me = this;
            me.isNew = false;
            me.jsonDataDizionario = { ...item };
            me.$refs.mdlDizionarioEdit.show();
        },

        deleteVoceDizionario(id) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceDizionario + "/" + id;
            axios
                .delete(link)
                .then((response) => {
                    me.$emit("onDeleteVoceDizionario", response);
                    me.$bvToast.toast("Eliminazione avvenuta con successo", {
                        title: "Successo",
                        variant: "success",
                        solid: true,
                    });
                })
                .catch((e) => {
                    console.log(e);
                    me.$bvToast.toast("Errore durante l'eliminazione", {
                        title: "Errore",
                        variant: "danger",
                        solid: true,
                    });
                });
        },
        onDeleteVoceDizionario(item) {
            let me = this;
            me.$bvModal
                .msgBoxConfirm("Sei sicuro di eliminare questa voce?", {
                    title: "Eliminazione voce",
                    buttonSize: "sm",
                    okTitle: "SI",
                    cancelTitle: "NO",
                    okVariant: "danger",
                    cancelVariant: "secondary",
                    hideHeaderClose: true,
                    footerClass: "p-2",
                    centered: true,
                })
                .then((value) => {
                    if (value) me.deleteVoceDizionario(item.id);
                })
                .catch((error) => {
                    console.error("Errore durante l'eliminazione: ", error);
                });
        },

        onClickSaveVoceDizionario(bvModalEvent) {
            // Prevenire la chiusura automatica per validare i campi
            bvModalEvent.preventDefault();
            let me = this;
            //this.saveInProgress = true;

            // Validare campi obbligatori
            if (!me.jsonDataDizionario.tipo || !me.jsonDataDizionario.voce) {
                me.$bvToast.toast("I campi Tipo e Voce sono obbligatori", {
                    title: "Attenzione",
                    variant: "warning",
                    solid: true,
                });
                return;
            }

            UtilityMixin.methods.saveResource(me.pathResourceDizionario, me.jsonDataDizionario, me.saveVoceDizionarioSuccessResponse, me.saveVoceDizionarioErrorResponse);
        },

        saveVoceDizionarioSuccessResponse(response) {
            let me = this;
            me.$emit("onSaveVoceDizionario", response);
            me.$refs.mdlDizionarioEdit.hide();
            me.$bvToast.toast("Salvataggio avvenuto con successo", {
                title: "Successo",
                variant: "success",
                solid: true,
            });
            //this.onCloseModal();
        },

        saveVoceDizionarioErrorResponse(response) {
            let me = this;
            if (response.response && response.response.status === 400) {
                me.$bvModal.msgBoxConfirm(response.response.data.messaggio, {
                    title: "ATTENZIONE",
                    size: "md",
                    okVariant: "outline-danger",
                    headerClass: "sm-msg-header-danger",
                    footerClass: "p-2 border-top-0",
                    centered: true,
                });
            } else {
                me.$bvToast.toast("Errore durante il salvataggio: " + (response.message || "Errore sconosciuto"), {
                    title: "Errore",
                    variant: "danger",
                    solid: true,
                });
            }
        },
    },
};
</script>
