<template>
    <div class="sa-tab-scroll">
        <div class="sa-list-component-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" stacked="sm" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
                    <template #cell(actions)="{ item }">
                        <!-- <b-icon v-if="item.compilata" icon="check-square-fill" variant="success"></b-icon>
                  <b-icon v-else icon="x-square-fill" variant="danger"></b-icon> -->
                        <b-container class="bv-example-row">
                            <b-button v-if="item.compilata" size="sm" variant="outline-success" disabled>
                                <font-awesome-icon icon="check"></font-awesome-icon>
                            </b-button>
                            <b-button v-else size="sm" variant="outline-danger " disabled>
                                <font-awesome-icon icon="check"></font-awesome-icon>
                            </b-button>

                            <b-button v-if="item.path !== null && item.path !== ''" size="sm" variant="outline-success" @click="onClickInserisciModifica(item)" title="Modifica">
                                <font-awesome-icon icon="edit"></font-awesome-icon>
                            </b-button>
                            <b-button size="sm" v-b-tooltip.hover title="Visualizza Richiesta" variant="outline-secondary no-text" @click="onClickVisualizza(item)" class="mr-1 no-text">
                                <font-awesome-icon icon="eye" />
                            </b-button>

                            <!--  <b-button size="sm" v-b-tooltip.hover title="Visualizza Richiesta" variant="outline-secondary no-text" @click="onClickVisualizzaScala(item)" class="mr-1 no-text">
              <font-awesome-icon icon="eye" />
            </b-button> -->
                            <!-- <b-button size="sm" variant="btn btn-outline-info waves-effect waves-light width-sm no-text" @click="onVisualizzaPdf(item)" :disabled="!item.compilata">
                  <i class="bi bi-filetype-pdf"></i>
                </b-button> -->
                            <!-- <print-component v-if="item.compilata" :data="getPrintData(item.path)" :linkPrintData="linkPrintData(item)" typeButton="small"></print-component> -->
                        </b-container>
                    </template>
                </b-table>
            </div>
        </div>
        <b-modal scrollable b-modal ref="mdlSchedeValutazioneAllegatoA" id="mdlSchedeValutazioneAllegatoA" size="xl" style="height: 100%" @ok="onSalvaAllegatoA" :title="'Scheda: ' + rigaSelezionata.tipoScheda" centered no-close-on-backdrop no-close-on-esc hide-header-close>
            <rsa-scheda-valutazione-allegato-a-edit-component @update="onUpdateScheda"></rsa-scheda-valutazione-allegato-a-edit-component>
        </b-modal>

        <rsa-scheda-anamnesia-storia-clinica-modal-edit-component ref="cmpRsaSchedeAnamnesiaStoriaClinicaModalEdit" id="cmpRsaSchedeAnamnesiaStoriaClinicaModalEdit" @afterSaveSuccess="onAfterSaveAnamnesiStoriaClinicaSuccess" @onClose="loadData"></rsa-scheda-anamnesia-storia-clinica-modal-edit-component>

        <rsa-scheda-area-mobilita-modal-edit-component ref="cmpRsaSchedaAreaMobilitaModalEdit" id="cmpRsaSchedaAreaMobilitaModalEdit" @afterSaveSuccess="onAfterSaveSchedaAreaMobilitaSuccess" @onClose="loadData"> </rsa-scheda-area-mobilita-modal-edit-component>

        <rsa-scheda-psicologica-modal-edit-component ref="cmpRsaSchedaPsicologicaModalEdit" id="cmpRsaSchedaPsicologicaModalEdit" @afterSaveSuccess="onAfterSaveSchedaPsicologicaSuccess" @onClose="loadData"> </rsa-scheda-psicologica-modal-edit-component>

        <rsa-scheda-sociale-paziente-modal-edit-component ref="cmpRsaSchedaSocialePazienteModalEdit" @afterSaveSuccess="onAfterSaveSchedaSocialePazienteSuccess" @onClose="loadData"></rsa-scheda-sociale-paziente-modal-edit-component>

        <rsa-scheda-esame-obiettivo-generale-modal-edit-component ref="cmpRsaSchedaEsameObiettivoGeneraleModalEdit" @afterSaveSuccess="onAfterSaveSchedaEsameObiettivoGenerale" @onClose="loadData" :jsonDataAccettazione="jsonDataAccettazione" :utente="utente"> </rsa-scheda-esame-obiettivo-generale-modal-edit-component>

        <rsa-scheda-riferimenti-diagnostici-edit-component ref="cmpRsaSchedaRiferimentiDiagnosticiModalEdit" @onClose="loadData"></rsa-scheda-riferimenti-diagnostici-edit-component>

        <rsa-scheda-infermieristica-modal-edit-component ref="cmpRsaSchedaInfermieristicaModalEdit" @afterSaveSuccess="onAfterSaveSchedaInfermieristica" @onClose="loadData" />
    </div>
</template>

<script>
import axios from "axios";
import RsaSchedaInfermieristicaModalEditComponent from "../../components/rsaschedevalutazione/RsaSchedaInfermieristicaModalEditComponent.vue";
import RsaSchedaSocialePazienteModalEditComponent from "../../rsagestioneospiti/components/RsaSchedaSocialePazienteModalEditComponent.vue";
//import PdfPrintViewEmbedded from "../../../app/utility/components/PdfPrintViewEmbedded.vue";
import RsaSchedaPsicologicaModalEditComponent from "../../rsagestioneospiti/components/RsaSchedaPsicologicaModalEditComponent.vue";
import RsaSchedaAreaMobilitaModalEditComponent from "../../rsagestioneospiti/components/RsaSchedaAreaMobilitaModalEditComponent.vue";
import RsaSchedaValutazioneAllegatoAEditComponent from "./RsaSchedaValutazioneAllegatoAEditComponent.vue";
import RsaSchedaAnamnesiaStoriaClinicaModalEditComponent from "./RsaSchedaAnamnesiaStoriaClinicaModalEditComponent.vue";
import RsaSchedaEsameObiettivoGeneraleModalEditComponent from "../../components/rsaschedevalutazione/RsaSchedaEsameObiettivoGeneraleModalEditComponent.vue";
import RsaSchedaRiferimentiDiagnosticiEditComponent from "../../components/rsaschedevalutazione/RsaSchedaRiferimentiDiagnosticiEditComponent.vue";
import UtilityMixin from "../../../utility/UtilityMixin";

export default {
    props: {
        jsonDataAccettazione: { type: Object },
        utente: { type: Object },
        isViewMode: {
            type: Boolean,
            default: false,
        },
    },

    mixins: [UtilityMixin],
    components: {
        RsaSchedaInfermieristicaModalEditComponent,
        RsaSchedaEsameObiettivoGeneraleModalEditComponent,
        RsaSchedaSocialePazienteModalEditComponent,
        RsaSchedaPsicologicaModalEditComponent,
        RsaSchedaAreaMobilitaModalEditComponent,
        RsaSchedaValutazioneAllegatoAEditComponent,
        RsaSchedaAnamnesiaStoriaClinicaModalEditComponent,
        RsaSchedaRiferimentiDiagnosticiEditComponent,
        // PdfPrintViewEmbedded
    },
    data() {
        return {
            rows: 0,
            perPage: 50,
            currentPage: 1,
            pathResource: "/rsaschedevalutazione",
            pathResourceStampa: "/print/createreport",
            sezione: "VALUTAZIONEINGRESSO",
            pathResourceSchedaValutazioneAllegatoA: "/rsaschedevalutazioneallegatoa",
            pathResourceSchedaAnamnesiaStoriaClinica: "/rsaanamnesistoriaclinica",
            pathResourceAreaMobilita: "/rsaareamobilita",
            pathRecourceSchedaPsicologica: "/rsaschedapsicologica",
            pathResourceSchedaSocialePaziente: "/rsaschedasocialepaziente",
            pathResourceSchedaEsameObiettivoGenerale: "/rsaschedaesameobiettivogenerale",
            pathResourceSchedaInfermieristica: "/rsaschedainfermieristica",
            signedPdfViewer: null,
            idAccettazione: null,
            filtro: {},
            items: [],
            rigaSelezionata: {},
            nomeModal: "",
            scheda: [],
            listKey: 0,
            idRichiesta: null,
            fields: [
                {
                    label: "Tipo Scheda",
                    key: "tipoScheda",
                },
                {
                    label: "",
                    key: "actions",
                    class: "sa-table-column-action-double",
                },
            ],
            showModalLoading: false,
        };
    },
    /*  props: {
      idPropostaAccesso: String,
    }, */
    mounted() {
        /* let me = this;
        me.idRichiesta = me.$route.params.id;
        me.loadAneamnesiStoriaClinica();
        me.loadSchedaAreaMobilita();
        me.loadSchedaPsicologica();
        me.loadSchedaSocialePaziente();
        me.loadSchedaEsameObiettivoGenerale(); */
        //console.log("ID Accettazione montato:", me.idRichiesta); // debug
        // let sessionStorage = window.sessionStorage;
        // if (sessionStorage["filtro"]) {
        //   me.filtro = JSON.parse(sessionStorage["filtro"]);
        // }
        // me.idRichiesta = me.$route.params.id;
        // me.loadData();
    },
    methods: {
        /* init(id) {
            let me = this;
            me.idRichiesta = id;
            me.loadData();
        }, */
        setId(id) {
            let me = this;
            me.idAccettazione = id;
            me.idRichiesta = id;
            me.filtro = { idAccettazione: id };
            me.loadData();
            me.loadAneamnesiStoriaClinica();
            me.loadSchedaAreaMobilita();
            me.loadSchedaPsicologica();
            me.loadSchedaSocialePaziente();
            me.loadSchedaEsameObiettivoGenerale();
        },
        loadData() {
            let me = this;
            // console.log("IDACCETTAZIONE", me.idAccettazione);

            me.showModalLoading = true;
            if (!me.idAccettazione) {
                console.warn("Non carica il load data: idAccettazione non disponibile");
                me.showModalLoading = false;
                return;
            }

            let link = process.env.VUE_APP_PATH_API + me.pathResource;

            me.filtro.page = me.currentPage;
            me.filtro.forPage = me.perPage;
            me.filtro.sezione = me.sezione;
            me.filtro.idAccettazione = me.idAccettazione;
            /* me.filtro.idAccettazione = me.idAccettazione; */

            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];
                    // response.data.data.list.forEach((element) => {
                    //   if (element.tipoScheda === "ALLEGATO A") {
                    me.items = response.data.data.list;
                    // }
                    // });
                    me.rows = response.data.data.recordsNumber;
                    me.checkSchedeCompilate();
                    me.listKey++;
                    this.$emit("afterLoadData");
                })
                .catch(() => {
                    this.$emit("afterLoadData");
                });
        },

        /* laodData() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            if (sessionStorage["filtro"]) {
                me.filtro = JSON.parse(sessionStorage["filtro"]);
            }
            me.filtro = {
                ...me.filtro,
                page: me.currentPage,
                forPage: me.perPage,
                sezione: me.sezione,
                idAccettazione: me.idAccettazione,
            };
            UtilityMixin.methods.loadDataResources(me.pathResource, me.filtro, me.loadDataSuccess, me.loadDataError);
            this.showModalLoading = true;
        },
        loadDataSuccess(response) {
            let me = this;
            me.items = [];
            me.items = response.data.data.list;
            me.rows = response.data.data.recordsNumber;
            me.checkSchedeCompilate();
            me.listKey++;
            me.$emit("afterLoadData");
        },
        loadDataError(response) {
            console.log(response);
        }, */

        checkSchedeCompilate() {
            let me = this;
            me.items.forEach((element) => {
                if (element.path !== null && element.path !== "") {
                    let link = process.env.VUE_APP_PATH_API + element.path + "/check/" + me.idRichiesta;
                    me.filtro.page = me.currentPage;
                    me.filtro.forPage = me.perPage;
                    axios
                        .get(link)
                        .then((response) => {
                            me.listKey++;
                            element.compilata = response.data.esito;
                        })
                        .catch(() => {
                            element.compilata = false;
                        });
                } else {
                    element.compilata = false;
                }
            });
        },
        onPrint() {
            console.log("Eccomi");
        },
        onModifica() {
            console.log("Modifica");
        },
        onClickInserisciModifica(value) {
            let me = this;
            me.rigaSelezionata = value;
            switch (me.rigaSelezionata.path) {
                case "/rsaschedevalutazioneallegatoa":
                    me.nomeModal = "mdlSchedeValutazioneAllegatoA";
                    me.$refs[me.nomeModal].show();
                    break;
                case "/rsaanamnesistoriaclinica":
                    /* me.$refs.cmpRsaSchedeAnamnesiaStoriaClinicaModalEdit.aggiornaAnamnesiaStoriaClinica(value);
                    break; */
                    me.$refs.cmpRsaSchedeAnamnesiaStoriaClinicaModalEdit.nuovoAnamnesiaStoriaClinica({ value /* , idAccettazione: me.$route.params.id  */ });
                    break;

                case "/rsaareamobilita":
                    me.$refs.cmpRsaSchedaAreaMobilitaModalEdit.nuovaAreaMobilita({ value });
                    break;
                case "/rsaschedapsicologica":
                    me.$refs.cmpRsaSchedaPsicologicaModalEdit.nuovaSchedaPsicologica({ value });
                    break;
                case "/rsaschedasocialepaziente":
                    me.$refs.cmpRsaSchedaSocialePazienteModalEdit.nuovaSchedaSocialePaziente(value);
                    break;
                case "/rsaschedaesameobiettivogenerale":
                    me.$refs.cmpRsaSchedaEsameObiettivoGeneraleModalEdit.show(value);
                    break;
                case "/rsaschedariferimentidiagnostici":
                    me.$refs.cmpRsaSchedaRiferimentiDiagnosticiModalEdit.show(value);
                    break;
                case "/rsaschedainfermieristica":
                    me.$refs.cmpRsaSchedaInfermieristicaModalEdit.show(value);
                    break;
            }
            /* me.$refs[me.nomeModal].show(); */
        },

        loadAneamnesiStoriaClinica() {
            let me = this;
            UtilityMixin.methods.loadDataResources(me.pathResourceSchedaAnamnesiaStoriaClinica, { idRichiesta: me.idRichiesta }, me.loadAnamnesiStoriaClinicaSuccess, me.loadAnamnesiStoriaClinicaError);
        },
        loadAnamnesiStoriaClinicaSuccess(response) {
            /* console.log("loadAnamnesiStoriaClinicaSuccess", response); */
            let me = this;
            me.scheda = response.data.data;
        },
        loadAnamnesiStoriaClinicaError(error) {
            console.error("Errore caricamento:", error);
            this.$bvToast.toast("Errore nel caricamento", {
                title: "Errore",
                variant: "danger",
                solid: true,
            });
        },

        loadSchedaAreaMobilita() {
            let me = this;
            UtilityMixin.methods.loadDataResources(me.pathResourceAreaMobilita, { idRichiesta: me.idRichiesta }, me.loadSchedaAreaMobilitaSuccess, me.loadSchedaAreaMobilitaError);
        },
        loadSchedaAreaMobilitaSuccess(response) {
            let me = this;
            me.scheda = response.data.data.list;
            // console.log(me.scheda);
        },
        loadSchedaAreaMobilitaError(error) {
            let me = this;
            console.log(me.scheda);
            console.error("Errore nel caricamento:", error);
            me.$bvToast.toast("Errore nel caricamento dati", {
                title: "Errore",
                variant: "danger",
                solid: true,
            });
        },

        loadSchedaPsicologica() {
            let me = this;
            UtilityMixin.methods.loadDataResources(me.pathRecourceSchedaPsicologica, { idRichiesta: me.idRichiesta }, me.loadSchedaPsicologicaSuccess, me.loadSchedaPsicologicaError);
        },
        loadSchedaPsicologicaSuccess(response) {
            let me = this;
            me.scheda = response.data.data.list;
        },
        loadSchedaPsicologicaError(error) {
            let me = this;
            console.error("Errore nel caricamento:", error);
            me.$bvToast.toast("Errore nel caricamento dati", {
                title: "Errore",
                variant: "danger",
                solid: true,
            });
        },
        loadSchedaSocialePaziente() {
            let me = this;
            UtilityMixin.methods.loadDataResources(me.pathResourceSchedaSocialePaziente, { idRichiesta: me.idRichiesta }, me.loadSchedaSocialePazienteSuccess, me.loadSchedaSocialePazienteError);
        },
        loadSchedaSocialePazienteSuccess(response) {
            let me = this;
            me.scheda = response.data.data.list;
        },
        loadSchedaSocialePazienteError(error) {
            let me = this;
            console.error("Errore nel caricamento:", error);
            me.$bvToast.toast("Errore nel caricamento dati", {
                title: "Errore",
                variant: "danger",
                solid: true,
            });
        },

        loadSchedaEsameObiettivoGenerale() {
            let me = this;
            UtilityMixin.methods.loadDataResources(me.pathResourceSchedaEsameObiettivoGenerale, { idRichiesta: me.idRichiesta }, me.loadSchedaEsameObiettivoGeneraleSuccess, me.loadSchedaEsameObiettivoGeneraleError);
        },
        loadSchedaEsameObiettivoGeneraleSuccess(response) {
            let me = this;
            me.scheda = response.data.data.list;
        },
        loadSchedaEsameObiettivoGeneraleError(error) {
            let me = this;
            console.error("Errore nel caricamento:", error);
            me.$bvToast.toast("Errore nel caricamento dati", {
                title: "Errore",
                variant: "danger",
                solid: true,
            });
        },

        loadSchedaInfermieristica() {
            let me = this;
            UtilityMixin.methods.loadDataResources(me.pathResourceSchedaInfermieristica, { idRichiesta: me.idRichiesta }, me.loadSchedaInfermieristicaSuccess, me.loadSchedaInfermieristicaError);
        },

        loadSchedaInfermieristicaSuccess(response) {
            let me = this;
            me.scheda = response.data.data.list;
        },

        loadSchedaInfermieristicaError(error) {
            let me = this;
            console.error("Errore nel caricamento:", error);
            me.$bvToast.toast("Errore nel caricamento dati", {
                title: "Errore",
                variant: "danger",
                solid: true,
            });
        },

        onClickVisualizza(value) {
            let me = this;
            /* console.log(value.path); */
            me.rigaSelezionata = value;
            switch (me.rigaSelezionata.path) {
                // case "/rsascalabrassrichieste":
                //   me.nomeModal = "mdlSchedeValutazioneBrassView";
                //   break;
                // case "/rsascalaidarichieste":
                //   me.nomeModal = "mdlSchedeValutazioneIdaView";
                //   break;
                /* case "/rsascalamewsrichieste":
                    me.nomeModal = "mdlSchedeValutazioneMewsView";
                    break; */
                case "/rsaschedevalutazioneallegatoa":
                    me.nomeModal = "mdlSchedeValutazioneAllegatoA";
                    me.$refs[me.nomeModal].show();
                    break;
                case "/rsaanamnesistoriaclinica":
                    /* me.nomeModal = me.nomeModal = me.$refs.cmpRsaSchedeAnamnesiaStoriaClinicaModalEdit.nuovoAnamnesiaStoriaClinica(value);
                    break; */

                    me.$refs.cmpRsaSchedeAnamnesiaStoriaClinicaModalEdit.visualizzaAnamnesiaStoriaClinica(value /* { value, idAccettazione: me.$route.params.id } */);
                    break;
                case "/rsaareamobilita":
                    me.$refs.cmpRsaSchedaAreaMobilitaModalEdit.visualizzaAreaMobilita(value);
                    break;
                case "/rsaschedapsicologica":
                    me.$refs.cmpRsaSchedaPsicologicaModalEdit.visualizzaSchedaPsicologica(value);
                    break;
                case "/rsaschedasocialepaziente":
                    me.$refs.cmpRsaSchedaSocialePazienteModalEdit.visualizzaSchedaSocialePaziente(value);
                    break;
                case "/rsaschedaesameobiettivogenerale":
                    me.$refs.cmpRsaSchedaEsameObiettivoGeneraleModalEdit.show(value, true);
                    break;
                case "/rsaschedainfermieristica":
                    me.$refs.cmpRsaSchedaInfermieristicaModalEdit.show(value, true);
                    break;

                case "/rsaschedariferimentidiagnostici":
                    me.$refs.cmpRsaSchedaRiferimentiDiagnosticiModalEdit.show(value, true);
                    break;
            }
        },
        onAddScheda() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.rigaSelezionata.path + "/listaRisposte";
            axios
                .post(link, JSON.stringify(me.scheda))
                .then(() => {
                    me.$refs[me.nomeModal].hide();
                    this.$bvToast.toast("Salvataggio avvenuto con successo", {
                        title: this.titleModal,
                        variant: "success",
                        autoHideDelay: 1000,
                    });
                    me.loadData();
                })
                .catch((error) => {
                    me.showModalLoading = false;
                    this.$bvModal
                        .msgBoxOk(error.response.data.messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then((value) => {
                            this.boxTwo = value;
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                });
        },
        onUpdateScheda(data) {
            let me = this;
            me.scheda = data;
        },
        onVisualizzaPdf(value) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + value.path + "/print/" + me.idPropostaAccesso;
            axios.get(link).then((response) => {
                let printData = response.data.data;
                let linkReport = process.env.VUE_APP_PATH_API + me.pathResourceStampa;
                axios.post(linkReport, printData).then((response) => {
                    me.$refs.pdfPrintViewEmbedded.show();
                    me.signedPdfViewer = "data:application/pdf;base64," + response.data.base64;
                });
            });
        },
        onCloseSchedaView() {
            let me = this;
            me.$refs[me.nomeModal].hide();
            me.loadData();
        },
        onSalvaAllegatoA() {
            let me = this;
            UtilityMixin.methods.saveResource(me.pathResourceSchedaValutazioneAllegatoA, me.scheda, me.saveAllegatoASucessResponse, me.saveAllegatoAErrorResponse);
        },
        saveAllegatoASucessResponse(response) {
            let me = this;
            this.$emit("successRestCall", response);
            this.$emit("afterSaveRequest");
            me.loadData();
        },
        saveAllegatoAErrorResponse(response) {
            if (response.response.status === 400) {
                this.$emit("errorRestCall", response);
            } else {
                this.$bvToast.toast("Errore nel salvarei dati " + response.message, {
                    title: "",
                    variant: "danger",
                    solid: true,
                });
            }
        },
        onAfterSaveAnamnesiStoriaClinicaSuccess() {
            let me = this;
            me.loadData();
        },
        onAfterSaveSchedaAreaMobilitaSuccess() {
            let me = this;
            me.loadData();
        },
        onAfterSaveSchedaPsicologicaSuccess() {
            let me = this;
            me.loadData();
        },
        onAfterSaveSchedaSocialePazienteSuccess() {
            let me = this;
            me.loadData();
        },
        onAfterSaveSchedaEsameObiettivoGenerale() {
            let me = this;
            me.loadData();
        },
        onAfterSaveSchedaInfermieristica() {
            let me = this;
            me.loadData();
        },
    },
};
</script>

<style></style>
