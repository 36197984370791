<template>
    <sa-page-layout @refresh="onRefresh" :btnRefreshVisible="true" :btnBackVisible="true" :pathResource="pathResource" :showModalLoading="showModalLoading" :data="jsonData" :linkback="linkback" class="sa-no-space" toggleableDimension="sm">
        <template slot="toolbar">
            <b-button variant="outline-success btn-toolbar sa-margin-left float-sm-left" v-b-modal.mdlAllegati>
                <b-icon icon="file-earmark-plus" aria-hidden="true"></b-icon>
                Allegati
            </b-button>
        </template>
        <template slot="table-body">
            <b-tabs id="tabsSchede" nav-wrapper-class="sa-tab-items" content-class="mt-3" ref="tabs" class="sa-tab" style="height: 100%; padding-bottom: 25px" fill>
                <b-tab title="Allegati">
                    <allegato-model-component ref="AllegatoModelComponent" :disableRefresh="true" :idRisorsa="id" :jsonData="jsonData" @updateFiles="onUpdateFile()" :key="childKey" tipoRisorsa="COT"></allegato-model-component>
                </b-tab>
            </b-tabs>
        </template>
    </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import UtilityMixin from "../../utility/UtilityMixin";
import AllegatoModelComponent from "../../utility/components/AllegatoModelComponent.vue";
// import axios from "axios";
export default {
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    mixins: [UtilityMixin],
    components: { SaPageLayout, AllegatoModelComponent },
    data() {
        return {
            btnPresaInCarico: true,
            btnRespinta: true,
            btnInviataCot: true,
            btnInviataUvm: true,
            btnSaveNotBackVisible: true,
            pathResource: "/cot",
            linkNotBack: "",
            childKey: 0,
            btnSaveVisible: false,
            backAfterSave: false,
            showModalLoading: false,
            jsonData: {},
            linkback: "",
            id: "-1",
            // linkPrintData: "",
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.jsonData.id = me.id;
        me.jsonData.identificativo = this.$route.query.codiceFiscale;
        me.jsonData.provenienzaCodiceStruttura = this.$route.query.codiceStruttura;
        me.jsonData.provenienzaDescrizioneStruttura = this.$route.query.descrizioneStruttura;
        me.jsonData.provenienzaCodiceReparto = this.$route.query.codiceReparto;
        me.jsonData.provenienzaDescrizioneReparto = this.$route.query.descrizioneReparto;
        me.jsonData.provenienzaEventoClinico = this.$route.query.eventoClinico;
        me.jsonData.provenienzaNominativoMedico = this.$route.query.nominativoMedico;
        me.headerCard = "Richiesta a C.O.T. Paziente: " + me.jsonData.identificativo;
        if (this.$route.query.codiceFiscale) {
            me.linkback =
                "/cot/richieste/edit/-1" +
                "?view=module&codiceFiscale=" +
                me.jsonData.identificativo +
                "&codiceStruttura=" +
                me.jsonData.provenienzaCodiceStruttura +
                "&descrizioneStruttura=" +
                me.jsonData.provenienzaDescrizioneStruttura +
                "&codiceReparto=" +
                me.jsonData.provenienzaCodiceReparto +
                "&descrizioneReparto=" +
                me.jsonData.provenienzaDescrizioneReparto +
                "&eventoClinico=" +
                me.jsonData.provenienzaEventoClinico +
                "&nominativoMedico=" +
                me.jsonData.provenienzaNominativoMedico;
        } else if (me.id) {
            me.linkback = "/cot/richieste/edit/" + me.id;
        } else {
            me.linkback = "/cot/richieste/edit/-1";
        }

        // me.loadData();
    },
    methods: {
        setPermessi() {
            let me = this;
            // console.log(me.jsonData);
            // if (me.id === -1) {
            //     me.btnSaveNotBackVisible = UtilityMixin.methods.verificaPermessi(me.utente, "cotproposteaccesso", 2);
            // } else {
            //     me.btnSaveNotBackVisible = UtilityMixin.methods.verificaPermessi(me.utente, "cotproposteaccesso", 4);
            // }
            me.btnSaveNotBackVisible = true;
        },
        onRefresh() {
            let me = this;
            me.$refs.AllegatoModelComponent.loadData();
        },
        onUpdateFile() {
            let me = this;
            me.childKey++;
            me.$refs.AllegatoModelComponent.loadData();
        },
    },
};
</script>

<style></style>
