<template>
    <sa-page-layout :btnRefreshVisible="btnRefreshVisible" :btnNewVisible="btnNewVisible" :linkedit="linkedit" @refresh="onRefresh" :showModalLoading="showModalLoading">
        <template slot="table-filter">
            <b-form @submit.prevent="onSubmit">
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Titolo:</label>
                        <b-form-input v-model="filtroAgenda.titolo" type="search" placeholder="Titolo"></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Descrizione:</label>
                        <b-form-input v-model="filtroAgenda.descrizione" type="search" placeholder="Descrizione"></b-form-input>
                    </b-col>
                </b-row>
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="sa-padding-right text-right">
                        <b-button type="submit" variant="info">Cerca</b-button>
                        <b-button type="reset" v-on:click="onReset" variant="danger">Reset</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </template>
        <template slot="table-body">
            <agende-list-component :utente="utente" ref="cmpAgendeListComponent"></agende-list-component>
        </template>
    </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import AgendeListComponent from "../components/AgendeListComponent.vue";
export default {
    props: {
        utente: { type: Object },
    },
    components: { SaPageLayout, AgendeListComponent },
    mounted() {
        this.loadData();
    },
    data() {
        return {
            btnRefreshVisible: true,
            btnNewVisible: true,
            linkedit: null,
            showModalLoading: false,
            filtroAgenda: { titolo: null, descrizione: null },
        };
    },
    methods: {
        loadData() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            if (sessionStorage["filtroAgende"]) {
                me.filtroAgenda = JSON.parse(sessionStorage["filtroAgende"]);
            }

            me.$refs.cmpAgendeListComponent.loadData(me.filtroAgenda);
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        onSubmit() {},
        onReset() {},
    },
};
</script>

<style></style>
