<template>
    <div style="height: 100%; overflow: auto">
        <b-card class="sa-card" header="Area" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Nome:</label>
                    <b-form-input v-model="jsonData.nome"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Posizione:</label>
                    <b-form-input v-model="jsonData.position" type="number"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Descrizione:</label>
                    <b-form-textarea v-model="jsonData.descrizione" no-resize rows="5"></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
<script>
import axios from "axios";
export default {
    data() {
        return {
            pathResource: "/rsaaree",
            id: "-1",
            jsonData: {},
        };
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            me.$emit("beforeLoadData");
            if (me.id === "-1") {
                this.$emit("afterLoadData");
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                axios
                    .get(link + me.id)
                    .then((response) => {
                        me.jsonData = response.data.data;
                        this.$emit("afterLoadData");
                    })
                    .catch(() => {});
            }
        },
    },
};
</script>
