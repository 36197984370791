var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sa-tab-scroll"},[_c('b-card',{staticClass:"sa-card sa-card-border",attrs:{"header":"PERCEZIONE SENSORIALE","header-tag":"header","footer-tag":"footer","title":""}},[_c('b-table',{ref:"table",staticClass:"sa-b-table",attrs:{"sticky-header":"","stacked":"sm","striped":"","hover":"","items":_vm.listaPercezioneSensoriale,"fields":_vm.fieldsListe,"current-page":1,"per-page":1000000,"sort-icon-left":"","head-variant":"light","thead-class":"hidden-header"},scopedSlots:_vm._u([{key:"cell(check)",fn:function(ref){
var item = ref.item;
return [_c('b-form-checkbox',{attrs:{"checked":item.check,"switch":""},model:{value:(item.check),callback:function ($$v) {_vm.$set(item, "check", $$v)},expression:"item.check"}})]}}])})],1),_c('b-card',{staticClass:"sa-card sa-card-border",attrs:{"header":"UMIDITA'","header-tag":"header","footer-tag":"footer","title":""}},[_c('b-table',{ref:"table",staticClass:"sa-b-table",attrs:{"sticky-header":"","stacked":"sm","striped":"","hover":"","items":_vm.listaUmidita,"fields":_vm.fieldsListe,"current-page":1,"per-page":1000000,"sort-icon-left":"","head-variant":"light","thead-class":"hidden-header"},scopedSlots:_vm._u([{key:"cell(check)",fn:function(ref){
var item = ref.item;
return [_c('b-form-checkbox',{attrs:{"checked":item.check,"switch":""},model:{value:(item.check),callback:function ($$v) {_vm.$set(item, "check", $$v)},expression:"item.check"}})]}}])})],1),_c('b-card',{staticClass:"sa-card sa-card-border",attrs:{"header":"ATTIVITA'","header-tag":"header","footer-tag":"footer","title":""}},[_c('b-table',{ref:"table",staticClass:"sa-b-table",attrs:{"sticky-header":"","stacked":"sm","striped":"","hover":"","items":_vm.listaAttivita,"fields":_vm.fieldsListe,"current-page":1,"per-page":1000000,"sort-icon-left":"","head-variant":"light","thead-class":"hidden-header"},scopedSlots:_vm._u([{key:"cell(check)",fn:function(ref){
var item = ref.item;
return [_c('b-form-checkbox',{attrs:{"checked":item.check,"switch":""},model:{value:(item.check),callback:function ($$v) {_vm.$set(item, "check", $$v)},expression:"item.check"}})]}}])})],1),_c('b-card',{staticClass:"sa-card sa-card-border",attrs:{"header":"MOBILITA'","header-tag":"header","footer-tag":"footer","title":""}},[_c('b-table',{ref:"table",staticClass:"sa-b-table",attrs:{"sticky-header":"","stacked":"sm","striped":"","hover":"","items":_vm.listaMobilita,"fields":_vm.fieldsListe,"current-page":1,"per-page":1000000,"sort-icon-left":"","head-variant":"light","thead-class":"hidden-header"},scopedSlots:_vm._u([{key:"cell(check)",fn:function(ref){
var item = ref.item;
return [_c('b-form-checkbox',{attrs:{"checked":item.check,"switch":""},model:{value:(item.check),callback:function ($$v) {_vm.$set(item, "check", $$v)},expression:"item.check"}})]}}])})],1),_c('b-card',{staticClass:"sa-card sa-card-border",attrs:{"header":"NUTRIZIONE","header-tag":"header","footer-tag":"footer","title":""}},[_c('b-table',{ref:"table",staticClass:"sa-b-table",attrs:{"sticky-header":"","stacked":"sm","striped":"","hover":"","items":_vm.listaNutrizione,"fields":_vm.fieldsListe,"current-page":1,"per-page":1000000,"sort-icon-left":"","head-variant":"light","thead-class":"hidden-header"},scopedSlots:_vm._u([{key:"cell(check)",fn:function(ref){
var item = ref.item;
return [_c('b-form-checkbox',{attrs:{"checked":item.check,"switch":""},model:{value:(item.check),callback:function ($$v) {_vm.$set(item, "check", $$v)},expression:"item.check"}})]}}])})],1),_c('b-card',{staticClass:"sa-card sa-card-border",attrs:{"header":"ATTRITO E FRIZIONE","header-tag":"header","footer-tag":"footer","title":""}},[_c('b-table',{ref:"table",staticClass:"sa-b-table",attrs:{"sticky-header":"","stacked":"sm","striped":"","hover":"","items":_vm.listaAttritoFrizione,"fields":_vm.fieldsListe,"current-page":1,"per-page":1000000,"sort-icon-left":"","head-variant":"light","thead-class":"hidden-header"},scopedSlots:_vm._u([{key:"cell(check)",fn:function(ref){
var item = ref.item;
return [_c('b-form-checkbox',{attrs:{"checked":item.check,"switch":""},model:{value:(item.check),callback:function ($$v) {_vm.$set(item, "check", $$v)},expression:"item.check"}})]}}])})],1),_c('b-card',{staticClass:"sa-card sa-card-border",attrs:{"header":"Totale","header-tag":"header","footer-tag":"footer","title":""}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c('span',{staticClass:"sa-label-data"},[_vm._v(_vm._s(_vm.totale))])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }