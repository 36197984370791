<template>
    <b-navbar toggleable="lg" type="light" variant="light">
        <div class="sa-logo-toolbar">
            <b-img :src="logo" fluid alt="Responsive image"></b-img>
        </div>
        <!-- <sa-icon class="sa-hamburger-button" icon="bars" @click="onHamburgerMenuClick" /> -->
        <!-- <b-button class="sa-btn-toolbar sa-btn-icon" @click="onHamburgerMenuClick"><sa-icon icon="bars" /></b-button> -->
        <b-navbar-toggle class="sa-btn-toggle-menu" target="nav-collapse ">
            <span class="navbar-toggler-icon">
                <i class="fas fa-bars sa-hamburger-button"></i>
            </span>
        </b-navbar-toggle>
        <!-- <b-navbar-toggle target="nav-collapse" class="sa-btn-success"></b-navbar-toggle> -->

        <b-collapse id="nav-collapse" class="sa-toolbar-menu" is-nav>
            <!-- <b-nav-item class="sa-toolbar-menu-item-active" href="#">Home</b-nav-item>
            <b-nav-item class="sa-toolbar-menu-item" href="#">Link</b-nav-item>
            <b-nav-item href="#">Link</b-nav-item> -->

            <b-navbar-nav class="ml-auto" align="center">
                <!-- <b-navbar-nav class="ml-auto" align="center"> -->
                <b-nav-item :class="getClass(item)" v-for="item in menu" :key="item.routeId" :href="getMenuLink(item)">{{ item.text }}</b-nav-item>
            </b-navbar-nav>

            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">
                <b-nav-item-dropdown right>
                    <!-- Using 'button-content' slot -->
                    <template #button-content>
                        <span class="sa-navbar-image">
                            <!-- <img :src="image" :srcset="`${image2x || image} 2x`" :class="['img-circle']" :alt="username" /> -->
                            <img :src="image" :srcset="`${image2x || image} 2x`" :class="['']" />
                        </span>
                        <span class="pro-user-name ml-1">
                            <em>{{ userName }}</em>
                        </span>
                    </template>
                    <!-- <b-dropdown-item :href="linkProfiloView" class="sa-custom-mnu-item"><font-awesome-icon icon="user" /> <span>Profilo</span></b-dropdown-item> -->

                    <b-dropdown-item @click="onLogout" class="sa-custom-mnu-item"><font-awesome-icon icon="power-off" /> <span>Logout</span></b-dropdown-item>
                </b-nav-item-dropdown>
            </b-navbar-nav>
        </b-collapse>
    </b-navbar>
</template>

<script>
import avatar from "../../../assets/images/no-avatar.png";
import logo from "../../../assets/images/logo-toolbar.png";
import axios from "axios";
export default {
    props: {
        pageTitle: {
            type: String,
            required: true,
            default: "",
        },
        image: {
            type: String,
            default: avatar,
        },
        image2x: {
            type: String,
            default: avatar,
        },
        userName: {
            type: String,
            required: true,
        },
        idUtente: {
            type: String,
            required: false,
        },
        menu: {
            type: Array,
            required: false,
            default: function () {
                return [
                    { text: "Home", routeId: "home" },
                    { text: "Altro", routeId: "altro" },
                ];
            },
        },
    },
    data() {
        return {
            logo,
            linkProfiloView: null,
        };
    },
    watch: {
        idUtente() {
            let me = this;
            me.setLinkProfiloView();
        },
    },
    methods: {
        getMenuLink(element) {
            let link = "#" + element.viewId;
            return link;
        },
        getClass(element) {
            let arrPath = this.$route.fullPath.match(/^\/.[^?/]*/gm);
            if (arrPath) {
                let path = arrPath[0];
                if (path === element.viewId) {
                    return "sa-toolbar-menu-item-active";
                } else {
                    return "sa-toolbar-menu";
                }
            }
        },
        setLinkProfiloView() {
            let me = this;
            me.linkProfiloView = "#/profilo/view/" + this.idUtente;
        },
        onHamburgerMenuClick() {
            // let saSidebar = document.querySelector(".sa-sidebar");
            // let saMain = document.querySelector(".sa-main");
            // // saSidebar.classList.toggle("sa-sidebar-open");
            // // saMain.classList.toggle("sa-sidebar-open");
            document.body.classList.toggle("sa-body-sidebar-open");
        },
        onProfile() {
            let link = process.env.VUE_APP_PATH_API + "/profilo/view/" + this.idUtente;
            console.log(link);
            // axios.get(link, {}).then(() => {
            //   this.$router.push("login");
            //   location.reload();
            // });
        },
        onLogout() {
            let link = process.env.VUE_APP_PATH_API + "/logout";
            axios.get(link, {}).then(() => {
                this.$router.push("login");
                location.reload();
            });
        },
    },
};
</script>

<style></style>
