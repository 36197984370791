<template>
    <div>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card sa-card-border" header="Addome" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Forma</label>
                            <b-form-select v-model="jsonDataAddome.forma" :options="formaOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Descrizione Forma</label>
                            <b-form-input v-model="jsonDataAddome.formaDescrizione" :disabled="isReadOnly"> </b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Cicatrice Ombelicale</label>
                            <b-form-select v-model="jsonDataAddome.cicatriceOmbelicale" :options="cicatriceOmbelicaleOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Descrizione Cicatrice Ombelicale</label>
                            <b-form-input v-model="jsonDataAddome.cicatriceOmbelicaleDescrizione" :readonly="isReadOnly"> </b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Cicatrice Chirurgica</label>
                            <b-form-select v-model="jsonDataAddome.cicatriceChirurgica" :options="cicatriceChirurgicaOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Descrizione Cicatrice Chirurgica</label>
                            <b-form-input v-model="jsonDataAddome.cicatriceChirurgicaDescrizione" :readonly="isReadOnly"> </b-form-input>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Reticolo Venoso</label>
                            <b-form-select v-model="jsonDataAddome.reticoloVenoso" :options="reticoloVenosoOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Descrizione Reticolo Venoso</label>
                            <b-form-input v-model="jsonDataAddome.reticoloVenosoDescrizione" :readonly="isReadOnly"> </b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Trattabilità</label>
                            <b-form-select v-model="jsonDataAddome.trattabilita" :options="trattabilitaOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Descrizione Trattabilità</label>
                            <b-form-input v-model="jsonDataAddome.trattabilitaDescrizione" :readonly="isReadOnly"> </b-form-input>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Rumori Pleurici</label>
                            <b-form-select v-model="jsonDataAddome.massaAddominale" :options="massaAddominaleOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Descrizione Massa Addominale</label>
                            <b-form-input v-model="jsonDataAddome.massaAddominaleDescrizione" :readonly="isReadOnly"> </b-form-input>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Percussioni</label>
                            <b-form-select v-model="jsonDataAddome.percussione" :options="percussioneOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Descrizione Percussione</label>
                            <b-form-input v-model="jsonDataAddome.percussioneDescrizione" :readonly="isReadOnly"> </b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Elemento Semeiologico di Rilievo</label>
                            <b-form-input v-model="jsonDataAddome.elementoSemeiologicoRilievo" :readonly="isReadOnly"> </b-form-input>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Reazioni di Difesa:</label>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-row>
                                <b-col cols="auto" v-for="reazioni in reazioniDifesaOptions" :key="'reazioni-' + reazioni.id" class="sa-switch-select-element mr-3 mb-2">
                                    <b-form-checkbox switch :disabled="isReadOnly" v-model="selectedReazioniDifesa" :value="reazioni.voce">
                                        <span style="font-weight: normal">{{ reazioni.voce }}</span>
                                    </b-form-checkbox>
                                </b-col>
                            </b-row>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Descrizione Reazioni di Difesa</label>
                            <b-form-input v-model="jsonDataAddome.reazioneDifesaDescrizione" :readonly="isReadOnly"> </b-form-input>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Note</label>
                            <b-form-textarea v-model="jsonDataAddome.noteAddome" :readonly="isReadOnly" max-rows="10" rows="7"> </b-form-textarea>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import UtilityMixin from "../../../utility/UtilityMixin";

export default {
    props: {
        isReadOnly: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        let me = this;
        me.loadDizionarioData();
        me.idAccettazione = me.$route.query.idPaziente;
    },

    mixins: [UtilityMixin],
    data() {
        return {
            pathResourceSchedaEsameObiettivoGeneraleAddome: "/rsaschedaesameobiettivogeneraleaddome",
            pathResourceDizionario: "/rsadizionario",
            reazioniDifesaOptions: [],
            selectedReazioniDifesa: [],
            idAccettazione: null,
            jsonDataAddome: {
                id: null,
                idAccettazione: null,
                forma: null,
                formaDescrizione: null,
                cicatriceOmbelicale: null,
                cicatriceOmbelicaleDescrizione: null,
                cicatriceChirurgica: null,
                cicatriceChirurgicaDescrizione: null,
                reticoloVenoso: null,
                reticoloVenosoDescrizione: null,
                trattabilita: null,
                trattabilitaDescrizione: null,
                reazioneDifesaDescrizione: null,
                massaAddominale: null,
                massaAddominaleDescrizione: null,
                percussione: null,
                percussioneDescrizione: null,
                noteAddome: null,
                listaSchedaAddomeDettagli: [],
            },

            formaOptions: [
                { text: "PIANO", value: "PIANO" },
                { text: "GLOBOSO", value: "GLOBOSO" },
                { text: "AVVALLATO", value: "AVVALLATO" },
                { text: "BRATACIANO", value: "BRATACIANO" },
            ],
            cicatriceOmbelicaleOptions: [
                { text: "NORMOINTROFLESSA", value: "NORMOINTROFLESSA" },
                { text: "PIANA", value: "PIANA" },
                { text: "ESTROFLESSA", value: "ESTROFLESSA" },
                { text: "ERNIATA", value: "ERNIATA" },
            ],
            cicatriceChirurgicaOptions: [
                { text: "ASSENTI", value: "ASSENTI" },
                { text: "PRESENTI", value: "PRESENTI" },
            ],
            reticoloVenosoOptions: [
                { text: "ASSENTE", value: "ASSENTE" },
                { text: "PRESENTE", value: "PRESENTE" },
            ],
            trattabilitaOptions: [
                { text: "TRATTABILE", value: "TRATTABILE" },
                { text: "SCARSAMENTE TRATTABILE", value: "SCARSAMENTE TRATTABILE" },
            ],
            massaAddominaleOptions: [
                { text: "ASSENTI", value: "ASSENTI" },
                { text: "PRESENTI", value: "PRESENTI" },
            ],
            percussioneOptions: [
                { text: "TIMPANISMO ENTEROCOLICO (TEC)", value: "TIMPANISMO ENTEROCOLICO C" },
                { text: "FISIOLOGICO SU TUTTO L'AMBITO", value: "FISIOLOGICO SU TUTTO L'AMBITO" },
                { text: "AUMENTATO", value: "AUMENTATO" },
                { text: "ALTERATO PER OTTUSITA'", value: "ALTERATO PER OTTUSITA'" },
            ],
        };
    },
    methods: {
        loadDizionarioData() {
            let me = this;
            UtilityMixin.methods.loadDataResources(me.pathResourceDizionario, null, me.loadDizionarioDataSuccess, me.loadDizionarioDataError);
        },
        loadDizionarioDataSuccess(response) {
            let me = this;
            if (response.data?.data?.list) {
                me.reazioniDifesaOptions = response.data.data.list.filter((item) => item.tipo === "REAZIONI_DIFESA");
            }
        },
        loadDizionarioDataError(error) {
            console.error("Errore nel caricamento delle reazioni di difesa", error);
        },

        loadAddomeData() {
            let me = this;
            // console.log("ID accettazione nel loadAddomeData: " + me.idAccettazione);
            me.selectedReazioniDifesa = [];
            me.loadDizionarioData();
            UtilityMixin.methods.loadDataResources(me.pathResourceSchedaEsameObiettivoGeneraleAddome + "/accettazione/" + me.idAccettazione, {}, me.resultResponseSchedaEsameObiettivoGeneraleAddomeSuccess, me.resultResponseSchedaEsameObiettivoGeneraleAddomeError);
        },
        resultResponseSchedaEsameObiettivoGeneraleAddomeSuccess(response) {
            let me = this;
            me.jsonDataAddome = response.data.data;
            me.jsonDataAddome.listaSchedaAddomeDettagli.forEach((item) => {
                if (item.tipo === "REAZIONI_DIFESA") {
                    me.selectedReazioniDifesa.push(item.voce);
                }
            });

            //console.log("ID del record ADDOME:", me.jsonDataAddome.id);
        },
        resultResponseSchedaEsameObiettivoGeneraleAddomeError(error) {
            let me = this;
            if (error.response && error.response.status === 404) {
                //console.log("NESSUN record trovato del ADDOME per questo id accettazione");
                me.resetForm();
            } else {
                console.error("Errore nel caricamento dati della sezione ADDOME:", error);
            }
        },

        resetForm() {
            let me = this;
            me.jsonDataAddome = {
                id: null,
                idAccettazione: me.idAccettazione,
                forma: null,
                formaDescrizione: null,
                cicatriceOmbelicale: null,
                cicatriceOmbelicaleDescrizione: null,
                cicatriceChirurgica: null,
                cicatriceChirurgicaDescrizione: null,
                reticoloVenoso: null,
                reticoloVenosoDescrizione: null,
                trattabilita: null,
                trattabilitaDescrizione: null,
                reazioneDifesaDescrizione: null,
                massaAddominale: null,
                massaAddominaleDescrizione: null,
                percussione: null,
                percussioneDescrizione: null,
                noteAddome: null,
                listaSchedaAddomeDettagli: [],
            };

            me.selectedReazioniDifesa = [];
        },

        onSalvaAddome() {
            let me = this;
            me.jsonDataAddome.idAccettazione = me.idAccettazione;
            // console.log("Salvataggio ADDOME con dati:", JSON.stringify(me.jsonDataAddome));
            if (!me.jsonDataAddome.idAccettazione) {
                // console.log("idAccettazione: ", me.jsonDataAddome.idAccettazione);
                me.$bvToast.toast("Errore: ID Accettazione mancante", {
                    title: "Errore",
                    variant: "danger",
                    solid: true,
                });
                return;
            }

            let listaSchedaAddomeDettagli = [];
            me.selectedReazioniDifesa.forEach((voce) => {
                listaSchedaAddomeDettagli.push({
                    id: null,
                    idSchedaEsameObiettivoGeneraleAddome: me.jsonDataAddome.id,
                    tipo: "REAZIONI_DIFESA",
                    voce: voce,
                });
            });
            me.jsonDataAddome.listaSchedaAddomeDettagli = listaSchedaAddomeDettagli;

            UtilityMixin.methods.saveResource(me.pathResourceSchedaEsameObiettivoGeneraleAddome, me.jsonDataAddome, me.saveSchedaEsameObiettivoGeneraleAddomeSuccessResponse, me.saveSchedaEsameObiettivoGeneraleAddomeErrorResponse);
        },
        saveSchedaEsameObiettivoGeneraleAddomeSuccessResponse() {
            //console.log("ADDOME salvato con successo:", response);
        },
        saveSchedaEsameObiettivoGeneraleAddomeErrorResponse() {
            console.error("Errore nel salvataggio ADDOME:");
        },
    },
};
</script>
