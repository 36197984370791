<template>
    <sa-page-layout :btnAnnullaVisible="true" :btnSaveVisible="true" :linkback="linkback" :data="jsonData" @aftersave="onAfterSave" :showModalLoading="showModalLoading" :pathResource="pathResource" class="sa-no-space">
        <template slot="table-body">
            <rsa-configurazione-profili-lis-edit-component ref="RsaConfigurazioneProfiliLisEditComponent" :showModalLoading="showModalLoading" @update="onUpdateJsonData" @beforeLoadData="onBeforeLoadData" @afterLoadData="onAfterLoadData"></rsa-configurazione-profili-lis-edit-component>
        </template>
    </sa-page-layout>
</template>
<script>
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import RsaConfigurazioneProfiliLisEditComponent from "../components/RsaConfigurazioneProfiliLisEditComponent.vue";

export default {
    components: { SaPageLayout, RsaConfigurazioneProfiliLisEditComponent },
    data() {
        return {
            showModalLoading: false,
            pathResource: "/rsaprofililis",
            linkback: "/configurazioni/rsaconfigurazioneprofililis",
            jsonData: {},
            id: "-1",
        };
    },
    methods: {
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
        },
        onBeforeLoadData() {
            let me = this;
            me.showModalLoading = true;
        },
        onAfterLoadData() {
            let me = this;
            me.showModalLoading = false;
        },
        onAfterSave() {
            /*   let me = this;
      me.id = me.$route.params.id;
      if (me.id === "-1") {
        me.$router.replace(me.linkback).catch((err) => {
          console.log(err);
        });
      } */
        },
    },
};
</script>

<style></style>
