<template>
    <sa-page-layout :btnBackVisible="true" :btnEditVisible="true" :linkedit="linkedit" :linkback="linkback" :data="jsonData" :showModalLoading="showModalLoading" :pathResource="pathResource" class="sa-no-space">
        <template slot="table-body">
            <cot-pua-view-component :showModalLoading="showModalLoading" @update="onUpdateJsonData" @beforeLoadData="onBeforeLoadData" @afterLoadData="onAfterLoadData"></cot-pua-view-component>
        </template>
    </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import CotPuaViewComponent from "../components/CotPuaViewComponent.vue";
export default {
    components: { SaPageLayout, CotPuaViewComponent },
    data() {
        return {
            showModalLoading: false,
            pathResource: "/cotpua",
            linkback: "/cot/pua",
            linkedit: "",
            id: null,
            jsonData: {},
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.linkedit = "/cot/pua/edit/" + me.id;
    },

    methods: {
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
        },
        onBeforeLoadData() {},
        onAfterLoadData() {},
    },
};
</script>

<style></style>
