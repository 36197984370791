<template>
    <div style="height: 100%">
        <div class="sa-flexbox" style="padding-bottom: 20px">
            <div class="sa-flexbox-body">
                <div class="b-table-sticky-header">
                    <span class="sa-flexbox-body-title">Uscite</span>

                    <b-button size="sm" variant="outline-success outline-primary-noborder sa-margin-right float-sm-right" :hidden="btnNuovaUscitaHidden" @click="onClickNuovaUscita" class="mb-2">
                        <font-awesome-icon icon="plus"></font-awesome-icon>
                        Aggiungi
                    </b-button>

                    <b-table sticky-header ref="table" stacked="lg" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="row">
                            {{ row.index + 1 }}
                        </template>

                        <template v-slot:cell(actions)="{ item }">
                            <b-container class="bv-example-row">
                                <b-button size="sm" variant="outline-success" v-b-tooltip.hover title="Aggiorna presenza" @click="onClickAggiorna(item)">
                                    <!-- v-if="item.tipo === 'USCITA'" -->
                                    <font-awesome-icon icon="edit" />
                                </b-button>
                            </b-container>
                        </template>
                    </b-table>
                </div>
                <rsa-scheda-ospite-uscite-edit-component ref="cmpRsaSchedaOspiteUsciteEditComponent" :json-data-accettazione="jsonDataAccettazione" @onSave="onSavePresenza" @onClose="loadData"> </rsa-scheda-ospite-uscite-edit-component>
            </div>
            <div class="sa-list-component-footer">
                <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col lg="3">
                        <p>Record Totali:{{ rows }}</p>
                    </b-col>
                    <b-col lg="9">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                    </b-col>
                </b-row>
            </div>
        </div>
    </div>
</template>
<script>
import RsaSchedaOspiteUsciteEditComponent from "./RsaSchedaOspiteUsciteEditComponent.vue";
import moment from "moment";
import UtilityMixin from "../../../utility/UtilityMixin";

export default {
    props: {
        jsonDataAccettazione: { type: Object },
        utente: { type: Object },
    },
    mixins: [UtilityMixin],
    components: { RsaSchedaOspiteUsciteEditComponent },
    data() {
        return {
            pathResource: "/rsapazientepresenze",
            btnNuovaUscitaHidden: false,
            showModalLoading: false,
            listKey: 0,
            rows: 0,
            perPage: 50,
            idAccettazione: null,
            isReadOnly: true,
            currentPage: 1,
            items: [],
            filtroPresenze: {},
            fields: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Uscita Programmata",
                    key: "dataOraUscitaProgrammata",
                    formatter: (value) => {
                        if (value) {
                            return moment(new Date(value)).format("DD/MM/YYYY HH:mm");
                        } else {
                            return "-----";
                        }
                    },
                    sortable: true,
                },
                {
                    label: "Entrata Programmata",
                    key: "dataOraEntrataProgrammata",
                    formatter: (value) => {
                        if (value) {
                            return moment(new Date(value)).format("DD/MM/YYYY HH:mm");
                        } else {
                            return "-----";
                        }
                    },
                    sortable: true,
                },
                {
                    label: "Uscita",
                    key: "dataOraUscita",
                    formatter: (value) => {
                        if (value) {
                            return moment(new Date(value)).format("DD/MM/YYYY HH:mm");
                        } else {
                            return "-----";
                        }
                    },
                },
                {
                    label: "Entrata",
                    key: "dataOraEntrata",
                    formatter: (value) => {
                        if (value) {
                            return moment(new Date(value)).format("DD/MM/YYYY HH:mm");
                        } else {
                            return "-----";
                        }
                    },
                    sortable: true,
                },
                {
                    label: "Presente nella data",
                    key: "presenteNellaData",
                    formatter: (value) => {
                        if (value === true) return "SI";
                        if (value === false) return "NO";
                        return value === null || value === undefined ? "---" : value;
                    },
                },
                /* {
                    label: "Tipo",
                    key: "tipo",
                    thStyle: "width: 5rem",
                    sortable: true,
                }, */
                {
                    label: "Motivo",
                    key: "motivo",
                },
                {
                    label: "Note",
                    key: "note",
                },
                {
                    class: "sa-table-column-action-double",
                    key: "actions",
                    label: "",
                },
            ],
        };
    },
    mounted() {
        /* let me = this;
        this.filtroPresenze = { idAccettazione: me.idAccettazione };
        me.loadData(); */
    },

    watch: {
        currentPage() {
            let me = this;
            me.loadData();
        },
    },
    methods: {
        setId(id) {
            let me = this;
            me.idAccettazione = id;
            me.filtroPresenze = { idAccettazione: id };
            me.loadData();
        },

        loadData() {
            let me = this;

            if (!me.idAccettazione) {
                console.warn("Non carica il load data: idAccettazione non disponibile");
                return;
            }

            me.showModalLoading = true;
            me.filtroPresenze.page = me.currentPage;
            me.filtroPresenze.forPage = me.perPage;

            me.filtroPresenze.idAccettazione = me.idAccettazione;

            UtilityMixin.methods.loadDataResources(me.pathResource, me.filtroPresenze, me.loadDataSuccess, me.loadDataError);
            this.showModalLoading = true;
        },
        loadDataSuccess(response) {
            let me = this;
            me.items = response.data.data.list;
            me.rows = response.data.data.recordsNumber;
            this.showModalLoading = false;
        },
        loadDataError(response) {
            console.log(response);
            this.showModalLoading = false;
        },
        onCloseModal() {
            let me = this;
            me.jsonDataPresenza = {};
            me.loadData();
        },
        onClickAggiorna(item) {
            let me = this;

            me.$refs.cmpRsaSchedaOspiteUsciteEditComponent.show(item, this.isReadOnly);
        },
        onClickNuovaUscita() {
            let me = this;
            me.$refs.cmpRsaSchedaOspiteUsciteEditComponent.show({
                id: null,
                idAccettazione: me.idAccettazione,
                dataOraUscitaProgrammata: null,
                dataOraUscita: null,
                dataOraEntrataProgrammata: null,
                dataOraEntrata: null,
                tipo: "USCITA",
                motivo: null,
                presenteNellaData: null,
                note: null,
            });
        },

        onSavePresenza() {
            this.loadData();
        },
    },
};
</script>
