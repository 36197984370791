<template>
  <div class="sa-list-component">
    <div class="sa-list-component-header">
      <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </div>
    <div class="sa-list-component-body">
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="table" stacked="lg" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
          <template v-slot:cell(idStruttura)="{ item }">
            <span>{{ item.denominazioneStruttura }} ({{ item.codiceStruttura }})</span>
          </template>
          <template v-slot:cell(denominazione)="{ item }">
            <router-link class="sa-edit-link" :to="'/strutturapresidi/view/' + item.id">{{ item.denominazione }} </router-link>
          </template>
          <template v-slot:cell(note)="{ item }">
            <span>{{ item.note }} </span>
          </template>
          <template v-slot:cell(actions)="row">
            <b-container class="bv-example-row">
              <b-button v-b-tooltip.hover size="sm" variant="outline-secondary" title="Visualizza Presidio" @click="onView(row.item, row.index, $event.target)" class="mr-1 no-text">
                <b-icon icon="eye" variant="outline-secondary"></b-icon>
              </b-button>
              <b-button v-b-tooltip.hover size="sm" variant="outline-secondary" title="Modifica Presidio" @click="onEdit(row.item, row.index, $event.target)" class="mr-1 no-text">
                <b-icon icon="pencil" variant="outline-secondary"></b-icon>
              </b-button>
              <!-- <b-button v-b-tooltip.hover size="sm" variant="outline-danger" title="Cancella Presidio" @click="onDelete(row.item, row.index, $event.target)" class="mr-1 no-text">
                <b-icon icon="trash" variant="danger"></b-icon>
              </b-button> -->
            </b-container>
          </template>
        </b-table>
      </div>
    </div>
    <div class="sa-list-component-footer">
      <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      rows: 0,
      perPage: 50,
      currentPage: 1,
      pathResource: "/strutturepresidi",
      filtro: {},
      items: [],
      strutturaOptions: [],
      listKey: 0,
      fields: [
        {
          label: "Struttura",
          key: "idStruttura",
          sortable: true,
          // thStyle: "width: 18rem",
        },
        {
          label: "Presidio",
          key: "denominazione",
          sortable: true,
          //thStyle: "width: 18rem",
        },
        {
          label: "Numero telefono",
          key: "telefono",
          //sortable: true,
          // thStyle: "width: 18rem",
        },
        { label: "Note", key: "note" },
        { class: "sa-table-column-action-double", key: "actions", label: "" },
      ],
    };
  },
  methods: {
    loadData() {
      let me = this;
      let sessionStorage = window.sessionStorage;
      if (sessionStorage["filtro"]) {
        me.filtro = JSON.parse(sessionStorage["filtro"]);
      }
      me.strutturaOptions = [];
      let link = process.env.VUE_APP_PATH_API + me.pathResource;
      me.filtro.page = me.currentPage;
      me.filtro.forPage = me.perPage;
      axios
        .get(link, { params: me.filtro })
        .then((response) => {
          me.items = [];
          me.rows = response.data.data.recordsNumber;
          me.items = response.data.data.list;
          me.listKey++;
          //console.log(me.items);

          this.$emit("afterLoadData");
        })
        .catch(() => {
          this.$emit("afterLoadData");
        });
    },
    onEdit(item) {
      let me = this;
      me.$router.push("strutturapresidi/edit/" + item.id);
    },
    onView(item) {
      let me = this;
      me.$router.push("strutturapresidi/view/" + item.id);
    },
    onDelete(item) {
      let me = this;
      this.$bvModal
        .msgBoxConfirm("Sicuro di voler Eliminare il profilo  " + item.descrizione)
        .then((value) => {
          if (value) {
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            axios.delete(link + item.id).then(() => {
              me.loadData();
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style></style>
