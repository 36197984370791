<template>
    <div style="height: 100%; overflow: auto">
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Denominazione</label>
                    <span class="sa-data">{{ jsonData.denominazione }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Comune</label>
                    <span class="sa-data">{{ jsonData.comuneDescrizione }} ({{ jsonData.provinciaDescrizione }})</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Email</label>
                    <span class="sa-data">{{ jsonData.email }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Telefono</label>
                    <span class="sa-data">{{ jsonData.telefono }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Note</label>
                    <span class="sa-data">{{ jsonData.note }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <cot-pua-sede-utenti-list-component :idCotPu="id"></cot-pua-sede-utenti-list-component>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import axios from "axios";
import CotPuaSedeUtentiListComponent from './CotPuaSedeUtentiListComponent.vue';
export default {
    components: { CotPuaSedeUtentiListComponent },
    data() {
        return {
            pathResource: "/cotpuasedi",
            pathResourceSedi: "/cotpuasedi",
            pathResourceProvince: "/province",
            pathResourceComune: "/comuni/provincia",
            listKey: 0,
            rows: 0,
            perPage: 50,
            id: null,
            currentPage: 1,
            jsonData: { idCotDestinazione: "", descrizioneCotDestinazione: "", denominazione: "", note: "", email: "", telefono: "" },
            nuovaSede: { idCotPua: "", denominazione: "", comuneCodiceIstat: "", note: "", provinciaDescrizione: "" },
            sedi: [],
            provinciaOptions: [{ value: "", text: "-Seleziona Valore-" }],
            comuneOptions: [{ value: "", text: "-Seleziona Valore-" }],
            filtro: {},
            fields: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Denominazione",
                    key: "denominazione",
                    sortable: true,
                    //thStyle: "width: 7rem",
                },
                {
                    label: "Telefono",
                    key: "telefono",
                    sortable: true,
                    thStyle: "width: 7rem",
                },
                {
                    label: "Email",
                    key: "email",
                    thStyle: "width: rem",
                    sortable: true,
                },
                {
                    label: "",
                    tdClass: "text-center",
                    thStyle: "width: 5rem",
                    key: "actions",
                },
            ],
        };
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    mounted: function () {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            axios.get(link + me.id).then((response) => {
                me.jsonData = response.data.data;
                me.loadSedi();
                this.$emit("afterLoadData");
            });
        },
        loadCotDestinazione() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceCotDestinazione;
            axios.get(link).then((response) => {
                me.cotDestinazioneOptions = response.data.data.list;
                me.cotDestinazioneOptions.unshift({ id: "", destinazione: "-Seleziona Valore-" });
            });
        },
        onSelectCotDestinazione() {
            let me = this;
            me.cotDestinazioneOptions.forEach((element) => {
                if (element.id === me.jsonData.idCotDestinazione) {
                    me.jsonData.descrizioneCotDestinazione = element.destinazione;
                }
            });
        },
        loadSedi() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            if (sessionStorage["filtro"]) {
                me.filtro = JSON.parse(sessionStorage["filtro"]);
            }
            let link = process.env.VUE_APP_PATH_API + me.pathResourceSedi;
            me.filtro.page = me.currentPage;
            me.filtro.forPage = me.perPage;
            me.filtro.idCotPua = me.jsonData.id;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.sedi = [];
                    me.rows = response.data.data.recordsNumber;
                    me.sedi = response.data.data.list;
                    me.listKey++;
                    this.$emit("afterLoadData");
                })
                .catch(() => {
                    this.$emit("afterLoadData");
                });
        },
        onDeleteSede(item) {
            let me = this;
            this.$bvModal
                .msgBoxConfirm("Sei sicuro di procedere alla cancellazione della sede?", {
                    title: "Conferma Cancellazione Associazione",
                    buttonSize: "sm",
                    okVariant: "secondary",
                    cancelVariant: "danger",
                    okTitle: "SI",
                    cancelTitle: "NO",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                })
                .then((value) => {
                    if (value) {
                        me.deleteSede(item);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        deleteSede(item) {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceSedi + "/";
            axios
                .delete(link + item.id)
                .then((response) => {
                    console.log(response);
                    me.showModalLoading = false;
                    me.loadData();
                })
                .catch((error) => {
                    console.log(error);
                    me.showModalLoading = false;
                });
        },
        onOpenModalNewSede() {
            let me = this;
            me.nuovaSede.idCotPua = me.jsonData.id;
            me.loadProvince();
            me.$refs["modalAggiungiSede"].show();
        },
        onAddSede() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceSedi;
            axios
                .post(link, JSON.stringify(me.nuovaSede))
                .then(() => {
                    me.$refs["modalAggiungiSede"].hide();
                    this.$bvToast.toast("Sede aggiunta con successo", {
                        title: this.titleModal,
                        variant: "success",
                        autoHideDelay: 1000,
                    });
                    me.loadData();
                })
                .catch((error) => {
                    me.showModalLoading = false;
                    this.$bvModal
                        .msgBoxOk(error.response.data.messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then((value) => {
                            this.boxTwo = value;
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                });
        },
        loadProvince() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceProvince;
            me.provinciaOptions = [];
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    me.provinciaOptions.push({ value: element.sigla, text: element.provincia });
                });
                me.provinciaOptions.unshift({ value: "", text: "-Seleziona Valore-" });
            });
        },
        loadComune(sigla) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceComune + "/" + sigla;
            me.comuneOptions = [];
            axios.get(link).then((response) => {
                me.comuneOptions = [];
                response.data.data.forEach((element) => {
                    me.comuneOptions.push({ value: element.codiceIstat, text: element.comune });
                });
                me.comuneOptions.unshift({ value: "", text: "-Seleziona Valore-" });
            });
        },
    },
};
</script>

<style></style>
