<template>
    <div style="height: 100%">
        <b-card class="sa-card" header="Anagrafica Struttura" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Nome</label>
                    <span class="sa-data">{{ jsonData.denominazione }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Codice Regione</label>
                    <span class="sa-data">{{ jsonData.codiceRegione }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Codice Struttura</label>
                    <span class="sa-data">{{ jsonData.codiceStruttura }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data"> Partita Iva</label>
                    <span class="sa-data">{{ jsonData.partitaIva }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Codice Fiscale</label>
                    <span class="sa-data">{{ jsonData.codiceFiscale }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">IBAN</label>
                    <span class="sa-data">{{ jsonData.iban }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Regime Fiscale</label>
                    <span class="sa-data">{{ jsonData.codiceRegimeFiscale }}</span>
                </b-col>
                <!-- <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Prestazione</label>
          <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.codicePrestazione" class="mb-2 mr-sm-2 mb-sm-0" :options="codicePrestazioneOptions" :value="null" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Tipologia Di Assistenza</label>
          <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.tipoStruttura" class="mb-2 mr-sm-2 mb-sm-0" :options="tipoStrutturaOptions" :value="null" value-field="value" text-field="text"></b-form-select>
        </b-col> -->
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Informazioni Struttura" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Indirizzo</label>
                    <span class="sa-data">{{ jsonData.indirizzo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
                    <label class="sa-label-data">Provincia</label>
                    <span class="sa-data">{{ jsonData.provincia }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
                    <label class="sa-label-data">Località</label>
                    <span class="sa-data">{{ jsonData.localita }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
                    <label class="sa-label-data">CAP</label>
                    <span class="sa-data">{{ jsonData.cap }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Contatti" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Telefono</label>
                    <span class="sa-data">{{ jsonData.telefono }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Email</label>
                    <span class="sa-data">{{ jsonData.email }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Fax</label>
                    <span class="sa-data">{{ jsonData.fax }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Pec</label>
                    <span class="sa-data">{{ jsonData.url }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Note" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <span class="sa-data">{{ jsonData.note }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Utenti associati" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="lg" striped hover :items="listaUtentiAssociati" :fields="fieldsUtenti" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                <template #cell(firstname)="row">
                    <p>{{ row.item.lastname }} {{ row.item.firstname }}</p>
                </template>
                <template #cell(actions)="row">
                    <b-button variant="outline-danger" @click="onDeleteAssociazione(row.item)">
                        <b-icon icon="person-x-fill" aria-hidden="true"></b-icon>
                        Dissocia
                    </b-button>
                </template>
            </b-table>
        </b-card>
    </div>
</template>
<script>
import axios from "axios";
//import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
export default {
    components: {},
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    data() {
        return {
            pathResource: "/malattieinfettivestrutture",
            linkback: null,
            linkProvince: "/province",
            linkComune: "/comuni/provincia",
            linkComuneByIstat: "/comuni/istat",
            linkRegimiFiscali: "/regimifiscali",
            linkUtentiAssociati: "/malattieinfettivestruttureutenti",
            currentPage: 1,
            perPage: 10000000,
            linkStrutture: "/strutture",
            showModalLoading: false,
            file1: null,
            id: null,
            listaUtentiAssociati: [],
            strutturaOption: [{ value: null, text: "-Seleziona Struttura-" }],
            provinciaOption: [{ value: null, text: "-Seleziona Provincia-" }],
            comuneOption: [{ value: null, text: "-Seleziona Comune-" }],
            codicePrestazioneOptions: [{ value: "", text: "-Seleziona Valore-" }],
            tipoStrutturaOptions: [{ value: "", text: "-Seleziona Valore-" }],
            codiciRegimiFiscaliOptions: [{ value: null, text: "-Seleziona Regime Fiscale-" }],
            jsonData: { localita: null, provincia: null, cap: null, codiceRegimeFiscale: null },
            fieldsUtenti: [
                {
                    label: "Username",
                    key: "username",
                    sortable: true,
                    thStyle: "width: 12rem",
                },
                {
                    label: "Nome",
                    key: "firstname",
                    sortable: true,
                    thStyle: "width: 15rem",
                },
                {
                    label: "Profilo",
                    key: "profilo",
                    sortable: true,
                },
                {
                    label: "",
                    key: "actions",
                    tdClass: "text-right",
                },
            ],
        };
    },
    mounted: function () {
        let me = this;
        me.id = this.$route.params.id;
        me.loadDefaultData();
    },
    methods: {
        loadDefaultData() {
            let me = this;
            me.loadProvince();
            me.loadData();
            me.loadRegimiFiscali();
        },
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
        },
        loadData() {
            let me = this;
            if (me.id === "-1") {
                this.$emit("afterLoadData");
            } else {
                // me.showModalLoading = true;
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                axios.get(link + me.id).then((response) => {
                    me.jsonData = response.data.data;
                    me.loadListaUtentiAssociati();
                    if (me.jsonData.localita !== null && me.jsonData.localita !== "") {
                        me.loadComune(me.jsonData.provincia);
                        me.loadCap(me.jsonData.localita);
                    }
                    this.$emit("afterLoadData");
                });
            }
        },
        loadListaUtentiAssociati() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkUtentiAssociati + "/utenti?idStruttura=" + me.jsonData.id;
            axios.get(link).then((response) => {
                me.listaUtentiAssociati = response.data.data;
            });
        },
        loadProvince() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkProvince;
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    me.provinciaOption.push({ value: element.sigla, text: element.provincia });
                });
            });
        },
        loadComune(sigla) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkComune + "/";
            axios.get(link + sigla).then((response) => {
                me.comuneOption = [];
                response.data.data.forEach((element) => {
                    me.comuneOption.push({ value: element.codiceIstat, text: element.comune });
                });
            });
        },
        loadCap(localita) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkComuneByIstat + "/" + localita;
            axios.get(link, { params: {} }).then((response) => {
                me.jsonData.cap = response.data.data.cap;
            });
        },
        loadRegimiFiscali() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkRegimiFiscali;
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    let descrizione = "(" + element.codiceRegimeFiscale + ") " + element.descrizioneRegimeFiscale;
                    me.codiciRegimiFiscaliOptions.push({ value: element.codiceRegimeFiscale, text: descrizione });
                });
            });
        },
        onDeleteAssociazione(item) {
            let me = this;
            this.$bvModal
                .msgBoxConfirm("Sei sicuro di procedere alla cancellazione dell'associazione?", {
                    title: "Conferma Cancellazione Associazione",
                    buttonSize: "sm",
                    okVariant: "secondary",
                    cancelVariant: "danger",
                    okTitle: "SI",
                    cancelTitle: "NO",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                })
                .then((value) => {
                    if (value) {
                        me.deleteAssociazione(item);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        deleteAssociazione(item) {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.linkUtentiAssociati + "/";
            axios
                .delete(link + item.id)
                .then((response) => {
                    console.log(response);
                    me.showModalLoading = false;
                    me.loadData();
                })
                .catch((error) => {
                    console.log(error);
                    me.showModalLoading = false;
                });
        },
    },
};
</script>
