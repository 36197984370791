<template>
    <b-modal class="modal-content" ref="mdlNuovaPrestazioneComponent" title="Nuova Prestazione" no-close-on-backdrop no-close-on-select @ok="onOkMdlNuovaPrestazioneComponent">
        <div class="modal-body">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label for="dataInizio">Codice Prestazione</label>
                    <b-form-input name="codicePrestazione" class="form-control" v-model="jsonData.codicePrestazione"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label for="indirizzo">Descrizione Prestazione</label>
                    <b-form-textarea v-model="jsonData.descrizionePrestazione" rows="5" no-resize></b-form-textarea>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label for="indirizzo">Costo</label>
                    <b-form-input v-model="jsonData.costo"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label for="indirizzo">Durata media (in minuti)</label>
                    <b-form-input type="number" name="durata" class="form-control" v-model="jsonData.durata"></b-form-input>
                </b-col>
            </b-row>
        </div>
    </b-modal>
</template>

<script>
// import DatePicker from "vue2-datepicker";
// import moment from "moment";
export default {
    components: {},
    data() {
        return {
            jsonData: { codicePrestazione: null, descrizionePrestazione: null, costo: 0, durata: 0 },
            intervallo: null,
        };
    },
    methods: {
        show() {
            let me = this;
            me.$refs.mdlNuovaPrestazioneComponent.show();
        },
        close() {
            let me = this;
            me.$refs.mdlNuovaPrestazioneComponent.hide();
        },
        // setIntervallo(intervallo) {
        //     // this.intervallo = intervallo;
        // },
        onOkMdlNuovaPrestazioneComponent() {
            let me = this;
            this.$emit("addPrestazione", me.jsonData);
        },
    },
};
</script>

<style></style>
