<template>
    <div style="height: 100%">
        <div class="sa-flexbox" style="padding-bottom: 20px">
            <div class="d-flex flex-column">
                <b-row class="sa-label-info">
                    <b-col lg="3">
                        <p>Record Totali: {{ rows }}</p>
                    </b-col>

                    <b-col lg="9">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                    </b-col>
                </b-row>
            </div>

            <div class="sa-flexbox-body">
                <div class="b-table-sticky-header">
                    <b-table sticky-header ref="table" stacked="lg" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="row">
                            <span>{{ row.index + 1 }} </span>
                        </template>

                        <template v-slot:cell(actions)="{ item }">
                            <b-container class="bv-example-row">
                                <b-button size="sm" v-b-tooltip.hover title="Modifica" variant="outline-success" @click="onClickEditParametroVitale(item)">
                                    <font-awesome-icon icon="edit"> </font-awesome-icon>
                                </b-button>

                                <b-button size="sm" v-b-tooltip.hover title="Elimina" variant="outline-danger" @click="onClickDeleteParametroVitale(item)">
                                    <font-awesome-icon icon="trash"></font-awesome-icon>
                                </b-button>
                            </b-container>
                        </template>
                    </b-table>
                </div>
            </div>

            <rsa-configurazione-parametri-vitali-modal-edit-component ref="cmpRsaConfigurazioneParametriVitaliModalEdit" @onSaveParametroVitale="onParametroVitaleSave" @onDeleteParametroVitale="onParametroVitaleDelete" />
            <b-row class="sa-label-info">
                <b-col lg="3">
                    <p>Record Totali: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import UtilityMixin from "../../../utility/UtilityMixin";
import RsaConfigurazioneParametriVitaliModalEditComponent from "./RsaConfigurazioneParametriVitaliModalEditComponent.vue";

export default {
    components: { RsaConfigurazioneParametriVitaliModalEditComponent },
    mixins: [UtilityMixin],
    data() {
        return {
            rows: 0,
            perPage: 50,
            currentPage: 1,
            pathResource: "/rsaconfigurazioneparametrivitali",
            listKey: 0,
            items: [],
            filtro: {},
            fields: [
                { key: "index", label: "N." },
                { key: "descrizione", label: "Descrizione" },
                { key: "valoreMinimo", label: "Valore Minimo" },
                { key: "valoreMassimo", label: "Valore Massimo" },
                { key: "numeroValori", label: "Numero Valori" },
                { key: "ausilio", label: "Ausilio" },
                { key: "actions", label: "", class: "sa-table-column-action-double" },
            ],
        };
    },

    /*  watch: {
        currentPage() {
            let me = this;
            me.loadParametriVitaliData();
        },
    }, */

    methods: {
        loadParametriVitaliData() {
            let me = this;
            me.filtro.page = me.currentPage;
            me.filtro.forPage = me.perPage;
            UtilityMixin.methods.loadDataResources(me.pathResource, me.filtro, me.loadParametriVitaliDataSuccess, me.loadParametriVitaliDataError);
        },
        loadParametriVitaliDataSuccess(response) {
            let me = this;
            me.items = response.data.data.list;
            me.rows = response.data.data.recordsNumber;
        },
        loadParametriVitaliDataError(error) {
            let me = this;
            console.error("Errore durante il caricamento dei dati di loadSpecializzazionePaiDataError:", error);
            me.items = [];
            me.rows = 0;
        },

        onClickEditParametroVitale(item) {
            let me = this;
            me.$refs.cmpRsaConfigurazioneParametriVitaliModalEdit.show(item);
        },
        onClickNuovoParametroVitale() {
            let me = this;
            me.$refs.cmpRsaConfigurazioneParametriVitaliModalEdit.show({
                id: null,
                descrizione: null,
                valoreMinimo: null,
                valoreMassimo: null,
                numeroValori: null,
                ausilio: null,
            });
        },
        onClickDeleteParametroVitale(item) {
            let me = this;
            me.$refs.cmpRsaConfigurazioneParametriVitaliModalEdit.onDeleteParametroVitale(item);
        },
        onParametroVitaleSave() {
            let me = this;
            me.loadParametriVitaliData();
        },

        onParametroVitaleDelete() {
            let me = this;
            me.loadParametriVitaliData();
        },

        onCloseModalParametroVitale() {},
    },
};
</script>
