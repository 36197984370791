<template>
    <b-modal ref="mdlPrescrizioneFarmacoComponent" size="lg" style="height: 100%" title="">
        <template #modal-title>Prescrizione Farmaco</template>
        <div class="sa-flexbox">
            <div class="sa-flexbox-header"></div>
            <div class="sa-flexbox-body">
                <b-row>
                    <b-col cols="6" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Nome Farmaco:</label>
                        <b-input-group class="mb-3">
                            <b-form-input maxlength="16" class="text-uppercase" v-model="jsonData.prescrizione" placeholder="Nome Farmaco" disabled></b-form-input>
                            <b-button size="sm" variant="" @click="onClickOpenRicercaFarmaco">
                                <b-icon icon="search"></b-icon>
                            </b-button>
                        </b-input-group>
                    </b-col>
                    <b-col cols="6" xs="12" sm="12" md="6" lg="6" xl="3">
                        <label class="sa-label-data">Quantità</label>
                        <template>
                            <b-input-group class="mb-3"><b-form-spinbutton id="sb-step" v-model="jsonData.quantita" min="0" max="1000000000" step="0.50"></b-form-spinbutton></b-input-group>
                        </template>
                    </b-col>
                    <b-col cols="6" xs="12" sm="12" md="6" lg="6" xl="3">
                        <label class="sa-label-data">Posologia:</label>
                        <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.idPosologia" class="mb-2 mr-sm-2 mb-sm-0" :value="null" :options="posologiaOptions" value-field="id" text-field="posologia"></b-form-select>
                    </b-col>
                    <b-col cols="6" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Data Inizio:</label>
                        <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataInizio" type="datetime"></date-picker>
                    </b-col>
                    <b-col cols="6" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Data Fine:</label>
                        <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataFine" type="datetime"></date-picker>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <label class="sa-label-data">Frequenza (Selezionare le fasce orarie):</label>
                        <div class="sa-calendar-day-grid-slot">
                            <table class="sa-calendar-day-grid-table-slot">
                                <tr v-for="(riga, i) in griglia" :key="i">
                                    <td v-for="(item2, j) in riga" :key="j">
                                        <div class="text-center">{{ riga * i + j }} - {{ riga * i + (j + 1) }}</div>
                                        <b-form-checkbox class="text-center" :value="riga * i + j" v-model="frequenzeSelezionate" @change="onChangeFrequenza"></b-form-checkbox>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </b-col>
                    <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                        <label class="sa-label-data">Note:</label>
                        <b-form-textarea v-model="jsonData.note" placeholder="Note" rows="7"></b-form-textarea>
                    </b-col>
                </b-row>
            </div>
            <div class="sa-flexbox-footer"></div>
            <div id="boxSelezionaFarmaco" class="sa-bancafarmaci sa-bancafarmaci-hidden">
                <div class="sa-flexbox">
                    <div class="sa-flexbox-header">
                        <b-form @submit.prevent="onClickDescrizioneFarmaco">
                            <b-input-group class="mb-3">
                                <b-form-input maxlength="16" class="text-uppercase" v-model="filtroFarmaci.descrizione" :placeholder="this.$i18n.t('therapy.lblDrug')"></b-form-input>
                                <b-button size="sm" variant="" v-b-modal.ricercaFarmaco @click="onClickDescrizioneFarmaco">
                                    <b-icon icon="search"></b-icon>
                                </b-button>
                            </b-input-group>
                            <b-input-group class="mb-3">
                                <b-form-input maxlength="16" class="text-uppercase" v-model="filtroFarmaci.atcGmp" placeholder="ATC"></b-form-input>
                                <b-button size="sm" variant="" v-b-modal.ricercaFarmaco @click="onClickAtc">
                                    <b-icon icon="search"></b-icon>
                                </b-button>
                            </b-input-group>
                        </b-form>
                    </div>
                    <div class="sa-flexbox-body">
                        <div class="b-table-sticky-header">
                            <b-table id="tabellaFarmaci" :busy="isBusy" :items="itemsFarmaci" :fields="fieldsFarmaci" selectable select-mode="single" @row-selected="onRowSelectedFarmaco" sort-icon-left head-variant="light" class="sa-b-table">
                                <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <strong style="padding-left: 10px">Caricamento dati in corso...</strong>
                                    </div>
                                </template>
                            </b-table>
                        </div>
                    </div>
                    <div class="sa-flexbox-footer">
                        <b-button size="sm" variant="outline-secondary" @click="onClickChiudi()">Chiudi</b-button>
                    </div>
                </div>
            </div>
        </div>
        <template #modal-footer>
            <b-button size="sm" variant="danger" @click="onClickAnnulla()">Annulla</b-button>
            <b-button size="sm" variant="outline-secondary" @click="onClickConferma()" :disabled="disabeConferma">
                <i class="fas fa-check"></i>
                Conferma
            </b-button>
        </template>
    </b-modal>
</template>

<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import UtilityMixin from "../UtilityMixin";
export default {
    mixins: [UtilityMixin],
    components: { DatePicker },
    data() {
        return {
            jsonData: { id: null, idAccettazione: null, idPaziente: null, idPosologia: null, tipoPrestazione: "FARMACOLOGICA", dataInizio: null, dataFine: null, codiceProdotto: null, atcGmp: null, prescrizione: null, quantita: 1, frequenza: null, costo: 0, stato: null, note: null },
            jsonDataAccettazione: null,
            disabeConferma: false,
            griglia: [6, 6, 6, 6],
            frequenzeSelezionate: [],
            posologiaOptions: [],
            itemsFarmaci: [],
            pathResourceFarmaci: "/farmaci",
            pathResourcePosologia: "/rsaterapiaposologie",
            pathResourcePazientePrescrizioni: "/rsapazienteprescrizioni",
            filtroFarmaci: { descrizione: null, atcGmp: null },
            isBusy: false,
            fieldsFarmaci: [
                {
                    label: "Nome Farmaco",
                    key: "descrizione",
                    sortable: true,
                },
                {
                    label: "ATC",
                    key: "atcGmp",
                    sortable: true,
                    thStyle: "width: 7rem",
                },
                {
                    label: "Prezzo",
                    key: "euroPrezzo1",
                    sortable: true,
                    thStyle: "width: 7rem",
                    formatter: (value) => value.toLocaleString("it-IT", { style: "currency", currency: "EUR" }),
                    // formatter: function (value) {
                    //     return value;
                    // },
                },
            ],
        };
    },
    created() {
        this.loadPosologiaData();
    },
    methods: {
        show(jsonDataAccettazione) {
            // console.log("SHOW");
            let me = this;
            me.jsonDataAccettazione = jsonDataAccettazione;
            me.jsonData = { id: null, idAccettazione: null, idPaziente: null, idPosologia: null, tipoPrestazione: "FARMACOLOGICA", dataInizio: null, dataFine: null, codiceProdotto: null, atcGmp: null, prescrizione: null, quantita: 1, frequenza: null, costo: 0, stato: null, note: null };
            me.frequenzeSelezionate = [];
            this.$refs.mdlPrescrizioneFarmacoComponent.show();
        },
        edit(jsonDataAccettazione, jsonData) {
            // console.log("SHOW");
            let me = this;
            me.jsonDataAccettazione = jsonDataAccettazione;
            me.jsonData = jsonData;
            me.frequenzeSelezionate = jsonData.frequenza.split(",");
            this.$refs.mdlPrescrizioneFarmacoComponent.show();
        },
        close() {
            this.$refs.mdlPrescrizioneFarmacoComponent.hide();
        },
        loadPosologiaData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourcePosologia;
            me.showModalLoading = true;
            axios
                .get(link)
                .then((response) => {
                    me.posologiaOptions = [];
                    me.posologiaOptions = response.data.data.list;
                    me.posologiaOptions.unshift({ id: null, posologia: "-Sel. Posologia-" });
                })
                .catch(() => {});
        },
        onClickConferma() {
            //this.$refs.mdlPrescrizioneFarmacoComponent.hide();
            let me = this;
            me.jsonData.idAccettazione = me.jsonDataAccettazione.id;
            me.jsonData.idPaziente = me.jsonDataAccettazione.accettazioneAnagrafica.id;

            console.log(me.jsonData);
            if (me.jsonData.id) {
                me.aggiornaPrecrizione();
            } else {
                me.creaPrescrizione();
            }
        },
        creaPrescrizione() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourcePazientePrescrizioni;
            axios
                .post(link, me.jsonData)
                .then(() => {
                    // console.log(response);
                    me.close();
                    me.$emit("afterSava");
                })
                .catch((error) => {
                    UtilityMixin.methods.messaggeBoxError(this, error);
                });
        },
        aggiornaPrecrizione() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourcePazientePrescrizioni + "/" + me.jsonData.id;
            axios
                .put(link, me.jsonData)
                .then(() => {
                    // console.log(response);
                    me.close();
                    me.$emit("afterSava");
                })
                .catch((error) => {
                    UtilityMixin.methods.messaggeBoxError(this, error);
                });
        },
        onClickAnnulla() {
            this.$refs.mdlPrescrizioneFarmacoComponent.hide();
        },
        onClickOpenRicercaFarmaco() {
            let me = this;
            //document.getElementById("boxSelezionaFarmaco").style.visibility = "visible";
            document.getElementById("boxSelezionaFarmaco").classList.remove("sa-bancafarmaci-hidden");
            document.getElementById("boxSelezionaFarmaco").classList.add("sa-bancafarmaci-visible");
            me.disabeConferma = true;
        },
        onChangeFrequenza(value) {
            let me = this;
            me.jsonData.frequenza = value
                .sort(function (a, b) {
                    return a - b;
                })
                .join();
        },
        onClickChiudi() {
            let me = this;
            document.getElementById("boxSelezionaFarmaco").classList.remove("sa-bancafarmaci-visible");
            document.getElementById("boxSelezionaFarmaco").classList.add("sa-bancafarmaci-hidden");
            me.disabeConferma = false;
        },
        onRowSelectedFarmaco(item) {
            let me = this;
            if (item.length > 0) {
                me.jsonData.prescrizione = item[0].descrizione;
                me.jsonData.atcGmp = item[0].atcGmp;
                me.jsonData.codiceProdotto = item[0].codiceProdotto;
                me.jsonData.costo = item[0].euroPrezzo1;
            }
        },
        onClickDescrizioneFarmaco() {
            let me = this;
            me.ricercaFarmaco();
        },
        onClickAtc() {
            let me = this;
            me.ricercaFarmaco();
        },
        ricercaFarmaco() {
            let me = this;
            me.isBusy = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceFarmaci + "/";
            if (me.filtroFarmaci.descrizioneFarmaco !== "") {
                axios
                    .get(link, { params: me.filtroFarmaci })
                    .then((response) => {
                        me.itemsFarmaci = response.data.data;
                        me.isBusy = false;
                    })
                    .catch((error) => {
                        me.isBusy = false;
                        me.$bvModal
                            .msgBoxOk(error.response.data.messaggio, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then((value) => {
                                this.boxTwo = value;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
            } else {
                axios
                    .get(link, { params: { atcGmp: me.atcGmpFarmaco } })
                    .then((response) => {
                        me.tabellaFarmaci = response.data.data;
                        me.showModalLoading = false;
                    })
                    .catch((error) => {
                        me.showModalLoading = false;
                        me.$bvModal
                            .msgBoxOk(error.response.data.messaggio, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then((value) => {
                                this.boxTwo = value;
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    });
            }
        },
    },
};
</script>

<style>
.sa-bancafarmaci {
    background-color: white;
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: opacity 1s ease-in-out;
    top: 0;
    right: 0;
    left: 0;
    padding: 5px;
}

.sa-bancafarmaci-hidden {
    opacity: 0;
    visibility: hidden;
    transition: all 1s ease-in-out;
}

.sa-bancafarmaci-visible {
    visibility: visible;
    opacity: 1;
    transition: all 1s ease-in-out;
}
</style>
