<template>
  <sa-page-layout :btnNewVisible="btnNewVisible" :btnRefreshVisible="true" @refresh="onRefresh" :pathResource="pathResource" :linkedit="linkedit" :showModalLoading="showModalLoading">
    <template slot="table-filter">
      <b-form @submit.prevent="onSubmit">
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <b-form-select v-model="filtro.idStruttura" :options="strutturaOptions" value-field="value" text-field="text" value="''" class="mb-2 mr-sm-2 mb-sm-0"></b-form-select>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <!-- <span>Presidio:</span> -->
            <b-form-input v-model="filtro.denominazione" type="search" id="denominazione" placeholder="Presidio"></b-form-input>
          </b-col>
          <!--<b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"
            ><b-form-input
              v-model="filtro.numero"
              type="search"
              id="numero"
              placeholder="Numero"
            ></b-form-input>
          </b-col> -->
        </b-row>
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="sa-padding-right text-right">
            <b-button type="submit" variant="info">{{ this.$i18n.t("patients.lblFind") }}</b-button>
            <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("patients.lblResetFilter") }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </template>
    <template slot="table-body">
      <strutture-presidi-list-component ref="StrutturePresidiListComponent" @afterLoadData="onAfterLoadData"></strutture-presidi-list-component>
    </template>
  </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import StrutturePresidiListComponent from "../components/StrutturePresidiListComponent.vue";
import axios from "axios";
export default {
  components: { SaPageLayout, StrutturePresidiListComponent },
  data() {
    return {
      filtro: {
        denominazione: null,
        idStruttura: null,
        numero: null,
        descrizione: null,
      },
      strutturaOptions: [],
      listaStrutture: [],
      btnNewVisible: true,
      pathResource: "",
      pathResourceStrutture: "/strutture",
      linkedit: "/strutturapresidi",
      showModalLoading: false,
      cont: 0,
    };
  },
  mounted() {
    let me = this;
    me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;
      me.$refs.StrutturePresidiListComponent.loadData();
      me.loadCodStrutture();
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
    onAfterLoadData() {
      let me = this;
      me.showModalLoading = false;
    },
    onReset() {
      let me = this;
      me.filtro = { denominazione: null, idStruttura: null, numero: null, descrizione: null };
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onSubmit() {
      let me = this;
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    loadCodStrutture() {
      let me = this;
      if (me.cont === 0) {
        let sessionStorage = window.sessionStorage;
        if (sessionStorage["filtro"]) {
          me.filtro = JSON.parse(sessionStorage["filtro"]);
        }
        // me.strutturaOptions = [];
        // me.listaStrutture = [];
        let link = process.env.VUE_APP_PATH_API + me.pathResourceStrutture;
        me.filtro.page = me.currentPage;
        me.filtro.forPage = me.perPage;
        axios
          .get(link, { params: me.filtro })
          .then((response) => {
            me.rows = response.data.data.recordsNumber;
            me.listaStrutture = response.data.data;
            me.cont++;
            me.listaStrutture.forEach((element) => {
              me.strutturaOptions.push({
                value: element.id,
                text: element.denominazione + " (" + element.codiceStruttura + ")",
              });
            });
            me.strutturaOptions.unshift({
              value: null,
              text: "-Seleziona Struttura-",
            });
            this.$emit("afterLoadData");
          })
          .catch(() => {
            this.$emit("afterLoadData");
          });
      }
    },
  },
};
</script>

<style></style>
