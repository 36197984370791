<template>
    <sa-page-layout :data="jsonData" :btnEditVisible="true" :btnModificationHistorical="true" :sezioneModificationHistorical="sezioneModificationHistorical" :linkedit="linkedit" :btnBackVisible="true" :linkback="linkback" :pathResource="pathResource" :showModalLoading="showModalLoading" class="sa-no-space">
        <!-- <template slot="toolbar-title">
            <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
        </template> -->
        <template slot="toolbar">
            <print-component :data="jsonData" :linkPrintData="linkPrintData" typeButton="normal"></print-component>
            <protocollo-component v-if="btnProtocolDisabled" :pageRoot="'IndagineEpidemiologicaView'" :pathResource="pathResourceSend" :tipoDocumento="'INDAGINE'" size="sm" @update="onUpdateProtocol"></protocollo-component>
            <b-button variant="outline-success btn-toolbar sa-margin-left float-sm-left" v-b-modal.mdlAllegati :disabled="btnAllegatiDisable">
                <b-icon icon="file-earmark-plus" aria-hidden="true"></b-icon>
                Allegati
            </b-button>
            <b-button v-if="statoVisualizzazione.stato !== 'VISUALIZZATO' && btnStatoVisualizzazione" variant="outline-success btn-toolbar sa-margin-left float-sm-left" @click="onCambiaStatoVisualizzato('VISUALIZZATO')">
                <!-- <b-icon icon="eye" aria-hidden="true"></b-icon> -->
                Visualizzato
            </b-button>
            <b-button v-else-if="statoVisualizzazione.stato === 'VISUALIZZATO' && btnStatoVisualizzazione" variant="outline-danger btn-toolbar sa-margin-left float-sm-left" @click="onCambiaStatoVisualizzato('NON VISUALIZZATO')">
                <!-- <b-icon icon="eye" aria-hidden="true"></b-icon> -->
                Non Visualizzato
            </b-button>
            <b-button v-if="btnArchiviaIndagineVisible" variant="outline-secondary btn-toolbar sa-margin-left float-sm-left" size="sm" @click="onCambiaStato('ARCHIVIATA')"><b-icon icon="archive"></b-icon>Archivia</b-button>
        </template>
        <template slot="table-header"> </template>
        <template slot="table-body">
            <b-tabs id="tabsSchede" nav-wrapper-class="sa-tab-items" content-class="mt-3" ref="tabs" class="sa-tab" style="height: 100%; padding-bottom: 25px" fill>
                <div class="sa-tab-scroll">
                    <b-tab lazy title="Indagine" @click="disactiveAllegato()" active>
                        <div class="sa-tab-scroll">
                            <b-card class="sa-card" header="Dati generali" header-tag="header" footer-tag="footer" title="">
                                <b-row>
                                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                        <label class="sa-label-data">Malattia Segnalata: {{ malattiaInfettivaSegnalata }}</label>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                        <label class="sa-label-data">Uosd Diagnosi: {{ uosdDiagnosi }}</label>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                        <label class="sa-label-data">Uosd Competente: {{ segnalazione.uosdResidenza }}</label>
                                    </b-col>

                                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                        <label class="sa-label-data">Stato Protocollo</label>
                                        <span class="sa-data">{{ lastProtocol.statoAttuale }}</span>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                        <label class="sa-label-data">N. Protocollo</label>
                                        <span class="sa-data">{{ lastProtocol.numeroProtocollo }}</span>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                        <label class="sa-label-data">Data Protocollo</label>
                                        <span class="sa-data">{{ formatDateTime(lastProtocol.dataProtocollo) }}</span>
                                    </b-col>
                                </b-row>
                            </b-card>
                            <b-card class="sa-card" header="Anagrafica" header-tag="header" footer-tag="footer" title="">
                                <b-row>
                                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                        <label class="sa-label-data">Codice Fiscale/STP/ENI:</label>
                                        <span class="sa-data">
                                            {{ paziente.identificativo }}
                                        </span>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                        <label class="sa-label-data">Data Nascita:</label>
                                        <span class="sa-data">
                                            {{ formatDate(paziente.dataNascita) }}
                                        </span>
                                    </b-col>

                                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                        <label class="sa-label-data">Nome:</label>
                                        <span class="sa-data">
                                            {{ paziente.nome }}
                                        </span>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                        <label class="sa-label-data">Cognome:</label
                                        ><span class="sa-data">
                                            {{ paziente.cognome }}
                                        </span>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                        <label class="sa-label-data">Sesso:</label
                                        ><span class="sa-data">
                                            {{ paziente.sesso }}
                                        </span>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                        <label class="sa-label-data">Contatto Telefonico:</label
                                        ><span class="sa-data">
                                            {{ paziente.telefono }}
                                        </span>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                        <label class="sa-label-data">Email:</label
                                        ><span class="sa-data">
                                            {{ paziente.email }}
                                        </span>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                        <label class="sa-label-data">Provincia di Nascita:</label
                                        ><span class="sa-data">
                                            {{ paziente.provinciaNascita }}
                                        </span>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                        <label class="sa-label-data">Comune di Nascita:</label
                                        ><span class="sa-data">
                                            {{ paziente.comuneNascita }}
                                        </span>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                        <label class="sa-label-data">Comune di Residenza: </label
                                        ><span class="sa-data">
                                            {{ paziente.comuneResidenza }}
                                        </span>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                        <label class="sa-label-data">Indirizzo di Residenza: </label
                                        ><span class="sa-data">
                                            {{ paziente.indirizzoResidenza }}
                                        </span>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                        <label class="sa-label-data">Provincia di Residenza: </label
                                        ><span class="sa-data">
                                            {{ paziente.provinciaResidenza }}
                                        </span>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                        <label class="sa-label-data">Comune di Domicilio: </label
                                        ><span class="sa-data">
                                            {{ paziente.comuneDomicilio }}
                                        </span>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                        <label class="sa-label-data">Indirizzo di Domicilio: </label
                                        ><span class="sa-data">
                                            {{ paziente.indirizzoDomicilio }}
                                        </span>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                        <label class="sa-label-data">Provinca di Domicilio: </label
                                        ><span class="sa-data">
                                            {{ paziente.provinciaDomicilio }}
                                        </span>
                                    </b-col>
                                </b-row>
                            </b-card>
                            <b-row>
                                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                    <component ref="indagineComponent" :is="currentComponentView" v-bind="currentProperties"></component>
                                </b-col>
                                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                    <indagine-epidemiologica-diagnosi-finale-component ref="IndagineEpidemiologicaDiagnosiFinaleComponent" :isEdit="false" :segnalazione="segnalazione"></indagine-epidemiologica-diagnosi-finale-component>
                                </b-col>
                                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                    <b-card class="sa-card" header="Protocollo" header-tag="header" footer-tag="footer" title="">
                                        <protocollo-list-component :idDocumento="jsonData.id" :documento="'INDAGINEEPIDEMIOLOGICA'"></protocollo-list-component>
                                    </b-card>
                                </b-col>
                            </b-row>
                        </div>
                    </b-tab>
                    <b-tab title="Allegati" @click="activeAllegato()">
                        <allegato-model-component :idRisorsa="id" :jsonData="jsonData" @updateFiles="onUpdateFile()" tipoRisorsa="INDAGINE EPIDEMIOLOGICA"></allegato-model-component>
                    </b-tab>
                </div>
            </b-tabs>
            <indagine-epidemiologica-cambia-stato-modal-component ref="cmpIndagineEpidemiologicaCambiaStatoModalComponent" @beforeLoadData="onBeforeLoadMalattieInfettiveCambiaStatoModalComponent" @afterLoadData="onAfterLoadDataMalattieInfettiveCambiaStatoModalComponent" @beforeSaveRequest="onBeforeSaveRequestMalattieInfettiveCambiaStatoModalComponent" @afterSaveRequest="onAfterSaveRequestMalattieInfettiveCambiaStatoModalComponent"></indagine-epidemiologica-cambia-stato-modal-component>
        </template>
    </sa-page-layout>
</template>
<script>
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PrintComponent from "../../../utility/components/PrintComponent.vue";
import PazienteAnagrafica from "../../../paziente/components/PazienteAnagrafica.vue";
import ProtocolloComponent from "../../../protocollo/components/ProtocolloComponent.vue";
import UtilityMixin from "../../../utility/UtilityMixin";
import ProtocolloListComponent from "../../../protocollo/components/ProtocolloListComponent.vue";
import IndagineEpidemiologicaDiagnosiFinaleComponent from "../components/IndagineEpidemiologicaDiagnosiFinaleComponent.vue";
import axios from "axios";
import indagini from "../components";
import AllegatoModelComponent from "../../../utility/components/AllegatoModelComponent.vue";
import Vue from "vue";
import IndagineEpidemiologicaCambiaStatoModalComponent from "../components/IndagineEpidemiologicaCambiaStatoModalComponent.vue";

export default {
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    components: {
        SaPageLayout,
        PazienteAnagrafica,
        ProtocolloComponent,
        ProtocolloListComponent,
        PrintComponent,
        IndagineEpidemiologicaDiagnosiFinaleComponent,
        AllegatoModelComponent,
        IndagineEpidemiologicaCambiaStatoModalComponent,
    },
    mixins: [UtilityMixin],
    data() {
        return {
            pathResource: "",

            showModalLoading: false,
            linkedit: null,
            id: "-1",
            pathResourceMalattiaInfettivaSegnalazione: "/malattieinfettivesegnalazioni",
            linkback: "/malattieinfettive",
            pathResourceSend: "/malattieinfettiveie/sendprotocol",
            pathResourceProtocollo: "/protocolresponse",
            pathResourceVisualizzazione: "/malattieinfettivevisualizzazioneutenti",
            pathResourceTestata: "/malattieinfettiveietestate",
            sezioneModificationHistorical: "indagineepidemiologica",
            testata: {},
            btnAllegatiDisable: true,
            btnStatoVisualizzazione: false,
            btnArchiviaIndagineVisible: false,
            linkPrintData: "",
            indagineEpidemiologicaParams: null,
            malattiaInfettivaSegnalata: "",
            uosdDiagnosi: "",
            indagineEpidemiologica: null,
            indagineEpidemiologicaComponent: null,
            jsonData: {},
            btnProtocolDisabled: false,
            currentComponentView: null,
            lastProtocol: {},
            currentProperties: {},
            segnalazione: {},
            paziente: {},
            statoVisualizzazione: {},
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient() {
            return UtilityMixin.methods.getLinkPatient(this.utente, this.idPaziente);
        },
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.jsonData.id = me.id;
        me.loadTestata();
        me.getLastProtocol();
        me.linkPrintData = "/malattieinfettiveie/printer/" + me.id;
        me.indagineEpidemiologica = this.$route.query.indagineEpidemiologica;
        me.fonte = this.$route.query.fonte;
        me.indagineEpidemiologicaComponent = this.$route.query.indagineEpidemiologicaComponent;
        me.setMalattiaSegnalata();
        me.getStatoVisualizzazione();
        (me.linkback = "/malattieinfettive" + me.fonte), (me.currentComponentView = indagini.viewComponents()[me.indagineEpidemiologicaComponent + "ViewComponent.vue"]);
        // me.impostaPermessi();
        me.linkedit = "/malattieinfettive/archivio/indagineepidemiologica/edit/" + me.id + "?idPaziente=" + me.idPaziente + "&indagineEpidemiologicaComponent=" + me.indagineEpidemiologicaComponent + "&fonte=" + me.fonte;
    },
    methods: {
        loadTestata() {
            let me = this;
            me.showModalLoading = true;

            let link = process.env.VUE_APP_PATH_API + me.pathResourceTestata + "/";
            me.showModalLoading = true;
            axios
                .get(link + me.id)
                .then((response) => {
                    me.testata = response.data.data;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        onUpdateProtocol(value) {
            console.log("SONO QUI");
            console.log(value);
            let me = this;
            me.btnProtocolDisabled = true;
            me.indagineComponent.loadData();
        },
        onShowModalLoading(value) {
            let me = this;
            me.showModalLoading = value;
        },
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
            me.$refs.IndagineEpidemiologicaDiagnosiFinaleComponent.setData(data);
        },
        setMalattiaSegnalata() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceMalattiaInfettivaSegnalazione + "/";
            axios
                .get(link + me.id)
                .then((response) => {
                    me.segnalazione = response.data.data;
                    if (response.data.data.malattiaSegnalata !== "ALTRA MALATTIA (ASLNA10000)") {
                        me.malattiaInfettivaSegnalata = response.data.data.malattiaSegnalata;
                    } else {
                        me.malattiaInfettivaSegnalata = response.data.data.malattiaSegnalataAltra;
                    }
                    me.uosdDiagnosi = response.data.data.uosdDiagnosi;
                    me.paziente = me.segnalazione.pazienteBean;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },

        loadData() {
            console.log("Eccomi");
        },
        onUpdateFile() {
            let me = this;
            me.childKey++;
        },
        getLastProtocol() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceProtocollo + "/idRisorsa/" + me.id + "?documento=INDAGINEEPIDEMIOLOGICA";
            axios
                .get(link)
                .then((response) => {
                    me.lastProtocol = response.data.data;
                    me.impostaPermessi();
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.impostaPermessi();
                    me.showModalLoading = false;
                });
        },
        impostaPermessi() {
            let me = this;
            me.btnProtocolDisabled = false;
            me.btnStatoVisualizzazione = false;
            if (me.lastProtocol === "{}" || me.lastProtocol.statoAttuale !== "SUCCESS" || me.jsonData.statoAttualeProtocollo !== "INVIO IN CORSO") {
                me.btnProtocolDisabled = UtilityMixin.methods.verificaPermessi(Vue.prototype.user, "protocol", 2) && UtilityMixin.methods.verificaPermessi(Vue.prototype.user, "malattieinfettiveieprotocol", 2); //
            }
            me.btnStatoVisualizzazione = UtilityMixin.methods.verificaPermessi(Vue.prototype.user, "malattieinfettivevisualizzazioneutenti", 2);
            me.btnArchiviaIndagineVisible = UtilityMixin.methods.verificaPermessi(me.utente, "indagineepidemiologicaarchiviazione", 2) && me.testata.statoAttuale !== "ARCHIVIATA";
            console.log(this.btnStatoVisualizzazione);
        },
        activeAllegato() {
            let me = this;
            me.btnAllegatiDisable = false;
        },
        disactiveAllegato() {
            let me = this;
            me.btnAllegatiDisable = true;
        },
        getStatoVisualizzazione() {
            // console.log("Prendo lo stato");
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceVisualizzazione + "/idsegnalazione/" + me.id;
            axios
                .get(link)
                .then((response) => {
                    me.statoVisualizzazione = response.data.data;
                    me.impostaPermessi();
                    me.showModalLoading = false;
                    // console.log(me.statoVisualizzazione);
                })
                .catch(() => {
                    me.statoVisualizzazione = { id: null, idSegnalazione: me.id, stato: "NON VISUALIZZATO" };
                    me.impostaPermessi();
                    me.showModalLoading = false;
                });
        },
        onCambiaStatoVisualizzato(value) {
            let me = this;
            me.statoVisualizzazione.stato = value;

            let link = process.env.VUE_APP_PATH_API + me.pathResourceVisualizzazione;
            if (me.statoVisualizzazione.id === null) {
                axios
                    .post(link, JSON.stringify(me.statoVisualizzazione))
                    .then((response) => {
                        me.statoVisualizzazione = response.data.data;
                        this.$bvToast.toast("Salvataggio avvenuto con sucesso", {
                            title: "",
                            variant: "success",
                            solid: true,
                        });
                    })
                    .catch((error) => {
                        // me.saveError(error);
                        console.log(error);
                    });
            } else {
                link = link + "/" + me.statoVisualizzazione.id;
                axios
                    .put(link, JSON.stringify(me.statoVisualizzazione))
                    .then((response) => {
                        me.statoVisualizzazione = response.data.data;
                        this.$bvToast.toast("Salvataggio avvenuto con sucesso", {
                            title: "",
                            variant: "success",
                            solid: true,
                        });
                    })
                    .catch((error) => {
                        // me.saveError(error);
                        console.log(error);
                    });
            }
        },
        onBeforeLoadMalattieInfettiveCambiaStatoModalComponent() {
            let me = this;
            me.showModalLoading = true;
        },
        onAfterLoadDataMalattieInfettiveCambiaStatoModalComponent() {
            let me = this;
            me.showModalLoading = false;
        },
        onBeforeSaveRequestMalattieInfettiveCambiaStatoModalComponent() {
            let me = this;
            me.showModalLoading = true;
        },
        onAfterSaveRequestMalattieInfettiveCambiaStatoModalComponent() {
            let me = this;
            me.$refs.cmpIndagineEpidemiologicaCambiaStatoModalComponent.close();
            me.showModalLoading = false;
            this.$bvToast.toast(`Stato cambiato con successo`, {
                title: "Cambia Stato",
                solid: true,
                variant: "success",
                autoHideDelay: 5000,
                appendToast: true,
            });
            me.loadData();
        },
        onCambiaStato(value) {
            let me = this;
            me.$refs.cmpIndagineEpidemiologicaCambiaStatoModalComponent.setJsonIndagine(me.jsonData);
            me.$refs.cmpIndagineEpidemiologicaCambiaStatoModalComponent.setStato(value);
            me.$refs.cmpIndagineEpidemiologicaCambiaStatoModalComponent.show();
        },
        // linkPrintData() {
        //   let me = this;
        //   let returnValue = "/malattieinfettiveie/printer/" + me.id;
        //   return returnValue;
        // },
    },
};
</script>
