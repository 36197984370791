<template>
    <b-modal ref="mdlEditPresenza" :title="title" centered no-close-on-backdrop no-close-on-esc hide-header-close size="lg">
        <b-row>
            <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <span class="sa-form-label">Uscita Programmata</span>
                <date-picker format="DD/MM/YYYY HH:mm" value-type="timestamp" v-model="jsonDataPresenza.dataOraUscitaProgrammata" type="datetime"></date-picker>
            </b-col>
            <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <span class="sa-form-label">Entrata Programmata</span>
                <date-picker format="DD/MM/YYYY HH:mm" value-type="timestamp" v-model="jsonDataPresenza.dataOraEntrataProgrammata" type="datetime"></date-picker>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <span class="sa-form-label">Uscita</span>
                <date-picker format="DD/MM/YYYY HH:mm" value-type="timestamp" v-model="jsonDataPresenza.dataOraUscita" type="datetime"></date-picker>
            </b-col>

            <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <span class="sa-form-label">Entrata</span>
                <date-picker format="DD/MM/YYYY HH:mm" value-type="timestamp" v-model="jsonDataPresenza.dataOraEntrata" type="datetime" :required="isRequired"></date-picker>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="6" md="4" lg="4" xl="4">
                <span class="sa-form-label">Tipo</span>
                <b-form-input v-model="jsonDataPresenza.tipo" readonly></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="6" md="4" lg="4" xl="4">
                <span class="sa-form-label">Motivo</span>
                <b-form-select v-model="jsonDataPresenza.motivo" :readonly="isReadonly" :options="motivoPresenzaUscitaOptions" value-field="voce" text-field="voce" value="''">
                    <template #first>
                        <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="6" md="4" lg="4" xl="4">
                <span class="sa-form-label">Presente nella Data</span>
                <b-form-checkbox v-model="jsonDataPresenza.presenteNellaData" switch> </b-form-checkbox>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <span class="sa-form-label">Note</span>
                <b-form-textarea v-model="jsonDataPresenza.note" rows="3" max-rows="7" placeholder="Inserisci note qui..."> </b-form-textarea>
            </b-col>
        </b-row>
        <template #modal-footer>
            <b-button size="md" variant="outline-danger" @click="onCloseModal">Annulla</b-button>
            <b-button size="md" variant="outline-success" @click="onClickOk()">Salva</b-button>
        </template>
    </b-modal>
</template>
<script>
import UtilityMixin from "../../../utility/UtilityMixin";
import DatePicker from "vue2-datepicker";
export default {
    props: {
        jsonDataAccettazione: { type: Object },
    },
    mixins: [UtilityMixin],
    components: { DatePicker },
    data() {
        return {
            title: null,
            isReadonly: false,
            isRequired: false,
            pathResource: "/rsapazientepresenze",
            pathResourceDizionario: "/rsadizionario",
            jsonDataPresenza: {
                id: null,
                idAccettazione: null,
                dataOraUscitaProgrammata: new Date(),
                dataOraUscita: new Date(),
                dataOraEntrataProgrammata: new Date(),
                dataOraEntrata: new Date(),
                tipo: null,
                motivo: null,
                presenteNellaData: null,
                note: null,
            },

            motivoPresenzaUscitaOptions: [],
        };
    },
    mounted() {
        let me = this;
        me.loadDizionarioData();
    },
    /* computed: {
        presenteNellaData: {
            get() {
                let me = this;

                return me.getValore(me.jsonDataPresenza.presenteNellaData);
            },
            set(value) {
                let me = this;
                me.setValore(value, "presenteNellaData");
            },
        },
    }, */
    methods: {
        loadDizionarioData() {
            let me = this;
            UtilityMixin.methods.loadDataResources(me.pathResourceDizionario, null, me.loadDizionarioDataSuccess, me.loadDizionarioDataError);
        },

        loadDizionarioDataSuccess(response) {
            let me = this;
            if (response.data?.data?.list) {
                me.motivoPresenzaUscitaOptions = response.data.data.list.filter((item) => item.tipo === "MOTIVAZIONE_PRESENZE_USCITE");
            }
        },
        loadDizionarioDataError(error) {
            console.error("Errore nel caricamento delle motivazioni", error);
        },
        show(jsonData) {
            let me = this;
            me.jsonDataPresenza = jsonData;
            me.title = !me.jsonDataPresenza.id ? "Nuova presenza/uscita" : "Aggiorna presenza/uscita";
            if (me.jsonDataAccettazione.statoAttuale === "DIMESSO" || me.jsonDataAccettazione.statoAttuale === "TRASFERITO") {
                me.$bvModal.msgBoxOk("Non è possibile modificare le uscite di un paziente dimesso o trasferito", {
                    title: "ATTENZIONE",
                    size: "sm",
                    okVariant: "outline-danger",
                    headerClass: "sa-msg-header-danger",
                    footerClass: "p-2 border-top-0",
                    centered: true,
                });
                return;
            }
            me.loadDizionarioData();
            me.$refs.mdlEditPresenza.show();
        },
        onCloseModal() {
            let me = this;
            me.jsonDataPresenza = {
                id: null,
                idAccettazione: null,
                dataOraUscitaProgrammata: new Date(),
                dataOraUscita: new Date(),
                dataOraEntrataProgrammata: new Date(),
                dataOraEntrata: new Date(),
                tipo: null,
                motivo: null,
                presenteNellaData: null,
                note: null,
            };
            me.$refs.mdlEditPresenza.hide();
            me.$emit("onClose");
        },
        onClickOk() {
            let me = this;
            UtilityMixin.methods.saveResource(me.pathResource, me.jsonDataPresenza, me.saveSuccessResponse, me.saveErrorResponse);
        },
        saveSuccessResponse(response) {
            let me = this;
            me.$emit("onSave", response);
            me.$refs.mdlEditPresenza.hide();
            me.$bvToast.toast("Salvataggio avvenuto con successo", {
                title: "Successo",
                variant: "success",
                solid: true,
            });
        },
        saveErrorResponse(response) {
            if (response.response.status === 400) {
                this.$bvModal.msgBoxOk(response.response.data.messaggio, {
                    title: "ATTENZIONE",
                    size: "sm",
                    okVariant: "outline-secondary",
                    headerClass: "sa-msg-header-danger",
                    footerClass: "p-2 border-top-0",
                    centered: true,
                });
            } else {
                this.$bvToast.toast("Errore nel salvataggio" + response.message, {
                    title: "Errore",
                    variant: "danger",
                    solid: true,
                });
            }
        },
        /* getValore(data) {
            return data === "SI";
        },
        setValore(value, data) {
            let me = this;
            me.jsonDataPresenza[data] = value ? "SI" : "NO";
        }, */
    },
};
</script>
