<template>
    <div class="sa-list-component">
        <div class="sa-list-component-header">
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </div>
        <div class="sa-list-component-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" stacked="lg" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
                    <template v-slot:cell(stato)="{ item }">
                        <router-link class="sa-edit-link" :to="'/cot/stati/edit/' + item.id">{{ item.stato }}</router-link>
                        <br />
                        <span class="sa-alert-descrizione">{{ item.descrizione }}</span>
                    </template>
                    <template v-slot:cell(sendMail)="{ item }">
                        {{ item.sendMail }}
                        <br />
                        <span class="sa-alert-descrizione">{{ item.sendMailDestination }}</span>
                    </template>
                    <template #cell(actions)="row">
                        <b-button size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onUpdatePostiLetto(row.item)">
                            <i class="fas fa-bed"></i>
                        </b-button>
                    </template>
                </b-table>
            </div>
        </div>
        <div class="sa-list-component-footer">
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    watch: {
        currentPage() {
            let me = this;
            me.loadData();
        },
    },
    data() {
        return {
            rows: 0,
            perPage: 50,
            currentPage: 1,
            pathResource: "/cotstati",
            filtro: {},
            items: [],
            listKey: 0,
            fields: [
                {
                    label: "Stato",
                    key: "stato",
                    sortable: true,
                },
                {
                    label: "Warning (In Ore)",
                    key: "warning",
                    sortable: true,
                },
                {
                    label: "Alert (In Ore)",
                    key: "danger",
                    sortable: true,
                },
                {
                    label: "Invio E-Mail",
                    key: "sendMail",
                    sortable: true,
                },
                {
                    label: "Messaggio di Warning",
                    key: "warningMessage",
                    sortable: true,
                },
                {
                    label: "Messaggio di Alert",
                    key: "dangerMessage",
                    sortable: true,
                },
            ],
        };
    },
    // mounted() {
    //     let me = this;
    //     me.loadData();
    // },
    methods: {
        loadData() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            if (sessionStorage["filtro"]) {
                me.filtro = JSON.parse(sessionStorage["filtro"]);
            }
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.filtro.page = me.currentPage;
            me.filtro.forPage = me.perPage;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];

                    me.rows = response.data.data.recordsNumber;
                    me.items = response.data.data.list;

                    me.listKey++;
                    this.$emit("afterLoadData");
                })
                .catch(() => {
                    this.$emit("afterLoadData");
                });
        },
    },
};
</script>

<style>
.sa-alert-descrizione {
    font-style: italic;
}
</style>
