import Cot from "../";

const routes = [
  { name: "Richieste", path: "/cot/richieste", component: Cot.CotPropostaAccessoListPage, meta: { title: "Richieste" } },
  // { name: "Richieste Edit", path: "/cot/richieste/edit/:id", component: Cot.CotPropostaAccessoEditPage, meta: { title: "Richieste Edit" } },
  { name: "Richieste Edit", path: "/cot/richieste/edit/:id", component: Cot.CotPropostaAccessoPage, meta: { title: "Richieste Edit" } },
  { name: "Proposta di Accesso", path: "/cot/richieste/edit/propostaaccesso/:id", component: Cot.CotPropostaAccessoEditPage, meta: { title: "Struttura" } },
  { name: "Schede Valutazione", path: "/cot/richieste/edit/schedevalutazioni/:id", component: Cot.CotPropostaAccessoSchedeValutazionePage, meta: { title: "Struttura" } },
  { name: "Schede Valutazione", path: "/cot/richieste/edit/modulistica/:id", component: Cot.CotPropostaAccessoModulisticaPage, meta: { title: "Struttura" } },
  { name: "Valutazione U.V.M.", path: "/cot/richieste/edit/valutazioneuvm/:id", component: Cot.CotPropostaAccessoValutazioneUvmPage, meta: { title: "Struttura" } },
  { name: "Allegati", path: "/cot/richieste/edit/allegati/:id", component: Cot.CotPropostaAccessoAllegatiPage, meta: { title: "Struttura" } },

  { name: "Cot Strutture List", path: "/cot/strutture", component: Cot.CotStruttureListPage, meta: { title: "Lista Strutture" } },
  { name: "Cot Strutture Edit", path: "/cot/strutture/edit/:id", component: Cot.CotStrutturaEditPage, meta: { title: "Struttura" } },
  { name: "Cot Strutture View", path: "/cot/strutture/view/:id", component: Cot.CotStrutturaViewPage, meta: { title: "Struttura" } },
  { name: "Cot Strutture View", path: "/cot/dossiersanitario/view/:id", component: Cot.CotDossierSanitarioPage, meta: { title: "Dossier Sanitario" } },

  { name: "Cot Stati List", path: "/cot/stati", component: Cot.CotStatiListPage, meta: { title: "Lista Stati" } },
  { name: "Cot Strutture Edit", path: "/cot/stati/edit/:id", component: Cot.CotStatiEditPage, meta: { title: "Struttura" } },

  { name: "Allert", path: "/cot/allert", component: Cot.CotAllertPage, meta: { title: "Allert" } },
  { name: "Lista d'Attesa", path: "/cot/listaattesa", component: Cot.CotPropostaAccessoListaAttesaPage, meta: { title: "Lista d'Attesa" } },
  { name: "Tracciamento Chiamate", path: "/cot/tracciamentochiamate", component: Cot.CotTraccaimentoChiamateListPage, meta: { title: "Tracciamento Chiamate" } },
  { name: "Traccaimento Chiamate Edit", path: "/cot/tracciamentochiamate/edit/:id", component: Cot.CotTracciamentoChiamateEditPage, meta: { title: "Tracciamento Chiamate" } },

  { name: "Cot Pua List", path: "/cot/pua", component: Cot.CotPuaListPage, meta: { title: "Lista Pua" } },
  { name: "Cot Pua Edit", path: "/cot/pua/edit/:id", component: Cot.CotPuaEditPage, meta: { title: "Pua" } },
  { name: "Cot Pua View", path: "/cot/pua/view/:id", component: Cot.CotPuaViewPage, meta: { title: "Pua" } },
  { name: "Cot Pua Sede View", path: "/cot/puasede/view/:id", component: Cot.CotPuaSedeViewPage, meta: { title: "Pua" } }
];

export default routes;
