<template>
    <b-modal ref="mdlNuovaAnagrafica" size="xl" :title="titoloModal" @hidden="onCloseModal" @ok="onClickOk" centered no-close-on-backdrop no-close-on-esc hide-header-close>
        <div>
            <b-card class="sa-card" header="Anagrafica" header-tag="header" footer-tag="footer" title="">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Tipo:</label>
                        <b-form-select v-model="jsonData.codiceTipo" :options="tipoAnagraficaOptions" :value="''" value-field="codiceTipo" text-field="descrizioneTipo"></b-form-select>
                        <!-- <b-form-input v-model="jsonData.nomeMedicoPresente" placeholder="Nome"></b-form-input> -->
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Nominativo:</label>
                        <b-form-input v-model="jsonData.nominativo" placeholder="Nominativo"></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Città:</label>
                        <b-form-input v-model="jsonData.citta" placeholder="Città"></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Indirizzo:</label>
                        <b-form-input v-model="jsonData.indirizzo" placeholder="Indirizzo"></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-label-data">Codice Fiscale:</label>
                        <b-form-input v-model="jsonData.codiceFiscale" placeholder="Codice Fiscale"></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-label-data">Partita I.V.A.:</label>
                        <b-form-input v-model="jsonData.partitaIva" placeholder="Partita I.V.A."></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-label-data">Telefono:</label>
                        <b-form-input v-model="jsonData.telefono" placeholder="Telefono"></b-form-input>
                    </b-col>
                </b-row>
            </b-card>
        </div>
    </b-modal>
</template>

<script>
import UtilityMixin from "../../utility/UtilityMixin";
// import DatePicker from "vue2-datepicker";
export default {
    mixins: [UtilityMixin],
    // components: { DatePicker },
    data() {
        return {
            isNew: true,
            isReadonly: false,
            isRequired: false,
            pathResource: "/anagrafica",
            pathResourceTipiAnagrafica: "/dizionariotipi",
            titoloModal: "",
            tipoAnagraficaOptions: [],
            jsonData: { nominativo: "", codiceTipo: "", indirizzo: "", citta: "", telefono: "", partitaIva: "", codiceFiscale: "", attivo: true },
        };
    },
    methods: {
        openModal(item) {
            let me = this;
            me.$emit("beforloadData");
            if (item !== null) {
                me.titoloModal = "Modifica Anagrafica";
                me.jsonData = item;
            } else {
                me.titoloModal = "Aggiungi Anagrafica";
                me.jsonData = { nominativo: "", codiceTipo: "", indirizzo: "", citta: "", telefono: "", partitaIva: "", codiceFiscale: "", attivo: true };
            }
            me.loadTipiAnagrafica();
            me.$refs.mdlNuovaAnagrafica.show();
            me.$emit("afterLoadData");
        },
        loadTipiAnagrafica() {
            let me = this;
            let filtro = {};
            filtro.sezioneDizionario = "ANAGRAFICA";
            UtilityMixin.methods.loadDataResources(me.pathResourceTipiAnagrafica, filtro, me.loadDataSuccess, me.loadDataError);
        },
        loadDataSuccess(response) {
            let me = this;
            me.tipoAnagraficaOptions = response.data.data.list;
            me.tipoAnagraficaOptions.unshift({ codiceTipo: "", descrizioneTipo: "-Seleziona Valore-" });
        },
        loadDataError(response) {
            console.log(response);
        },
        onCloseModal() {
            let me = this;
            me.$emit("beforloadData");
            me.jsonData = { nomeMedicoPresente: null, idPaziente: null };
            me.$emit("afterLoadData");
        },
        onClickOk(bvModalEvent) {
            let me = this;
            me.$emit("beforloadData");
            bvModalEvent.preventDefault();
            // console.log(me.jsonData);
            UtilityMixin.methods.saveResource(me.pathResource, me.jsonData, this.saveSuccessResponse, this.saveErrorResponse);
        },
        saveSuccessResponse(response) {
            let me = this;
            me.$emit("afterSaveSuccess", response);
            me.$emit("afterLoadData");
            me.$refs.mdlNuovaAnagrafica.hide();
            me.$bvToast.toast("Salvataggio avvenuto con successo", {
                title: "Successo",
                variant: "success",
                solid: true,
            });
        },

        saveErrorResponse(response) {
            if (response.response.status === 400) {
                this.$bvModal.msgBoxOk(response.response.data.messaggio, {
                    title: "ATTENZIONE",
                    size: "sm",
                    okVariant: "outline-secondary",
                    headerClass: "sa-msg-header-danger",
                    footerClass: "p-2 border-top-0",
                    centered: true,
                });
            } else {
                this.$bvToast.toast("Errore nel salvataggio" + response.message, {
                    title: "Errore",
                    variant: "danger",
                    solid: true,
                });
            }
            this.$emit("afterLoadData");
        },
    },
};
</script>
