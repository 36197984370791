<template>
    <div class="sa-free-page" style="margin-top: 5px; padding-bottom: 10px">
        <div class="sa-free-page-header">
            <b-navbar toggleable="xl" type="light" variant="light">
                <b-navbar-brand href="#" @click="showDatePicker = !showDatePicker">{{ subTitle }}, {{ numeroDisponibilita }}</b-navbar-brand>
                <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
                <b-collapse id="nav-collapse" is-nav>
                    <!-- Right aligned nav items -->
                    <!-- <b-navbar-nav class="ml-auto"> -->
                    <b-col cols="12" xs="12" sm="12" md="5" lg="5" xl="5">
                        <b-form-select v-model="codicePrestazioneSelezionato" :options="prestazioniOptions" value-field="codicePrestazione" text-field="descrizionePrestazione" size="sm" class="mt-3"></b-form-select>
                        <!-- <b-input-group :prepend="this.$i18n.t('agenda.lblPerformances')">
                            <b-form-tags v-model="codiciPrestaziona" tag-class="sa-btag-color" disabled placeholder="" no-add-on-enter no-outer-focus style="width: auto"></b-form-tags>
                            <b-input-group-append>
                                <b-button class="sa-btn-tags" @click="modalShow = !modalShow" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Aggiungi cod.Prestazione"><b-icon icon="bookmark-plus"></b-icon></b-button>
                            </b-input-group-append>
                        </b-input-group>-->
                    </b-col>
                    <!-- Button Group -->
                    <b-col cols="4" xs="12" sm="12" md="4" lg="4" xl="4">
                        <b-button-group>
                            <b-button :variant="btnAgendaVariant" class="sa-base-color" @click="onAgenda">{{ this.$i18n.t("agendas.lblDiary") }}</b-button>
                            <b-button :variant="btnGiornoVariant" class="sa-base-color" @click="onGiorno">{{ this.$i18n.t("patient.agenda.lblDay") }}</b-button>
                            <b-button :variant="btnSettimanaVariant" class="sa-base-color" @click="onSettimana">{{ this.$i18n.t("patient.agenda.lblWeek") }}</b-button>
                            <b-button :variant="btnMeseVariant" class="sa-base-color" @click="onMese">{{ this.$i18n.t("patient.agenda.lblMonth") }}</b-button>
                            <b-button :variant="btnElencoAppVariant" class="sa-base-color" @click="onElencoApp">{{ this.$i18n.t("agenda.btnAppointmentList") }}</b-button>
                        </b-button-group>
                    </b-col>

                    <b-collapse id="nav-collapse" is-nav>
                        <!-- <b-col> -->

                        <!-- </b-col> -->
                    </b-collapse>
                    <!-- </b-navbar-nav> -->
                    <b-col class="sa-info" xs="12" sm="12" md="1" lg="1" xl="1" v-if="titolo === 'Agenda Leonida'">
                        <a class="sa-margin-right sa-edit-link" data-toggle="modal" data-target="#collapse-info" href="#collapse-info" v-b-modal.modalInfo onclick="return false"><b-icon icon="info-circle" font-scale="2"></b-icon></a>
                    </b-col>
                </b-collapse>
                <b-row>
                    <b-col>
                        <b-button variant="secondary btn-toolbar sa-margin-right float-sm-right" size="sm" @click="onBack">
                            <b-icon icon="chevron-left"></b-icon>
                            {{ this.$i18n.t("global.lblBtnBack") }}
                        </b-button>

                        <b-button class="float-sm-right" variant="info btn-toolbar" size="sm" @click="onRefresh">
                            <b-icon icon="arrow-clockwise" variant="secondary" />
                            {{ this.$i18n.t("global.lblBtnRefresh") }}
                        </b-button>
                    </b-col>
                </b-row>
            </b-navbar>
        </div>
        <div class="sa-free-page-body" style="max-height: calc(100vh - 110px)">
            <div class="content sa-agenda-content">
                <b-carousel ref="crsCalendar" class="sa-agenda-carousel-inner-max-height" :interval="0">
                    <b-carousel-slide id="crsSlideAgenda">
                        <template slot="img">
                            <b-card title="Card Title" class="sa-agenda-card-max-height" no-body>
                                <b-card-body class="text-center">
                                    <agenda-component ref="cmpAgendaComponent" v-if="disponibilita" :disponibilita="disponibilita"></agenda-component>
                                    <div v-else>{{ this.$i18n.t("patient.agenda.lbl.NoDisponibility") }}</div>
                                </b-card-body>
                            </b-card>
                        </template>
                    </b-carousel-slide>
                    <b-carousel-slide>
                        <template slot="img">
                            <b-card title="Card Title" no-body class="antonio-strunz" style="height: 100%">
                                <b-card-header header-tag="nav">
                                    <span class="sa-riferimento-data">Giorno: {{ giornoRiferimento }}</span>
                                </b-card-header>
                                <b-card-body>
                                    <agenda-day-component ref="cmpAgendaDayComponent" v-if="disponibilita"></agenda-day-component>
                                    <!-- <div v-else>{{ this.$i18n.t("patient.agenda.lbl.NoDisponibility") }}</div> -->
                                </b-card-body>
                            </b-card>
                        </template>
                    </b-carousel-slide>
                    <b-carousel-slide>
                        <template slot="img">
                            <b-card title="Card Title" class="sa-agenda-card-max-height" no-body>
                                <b-card-header header-tag="nav">
                                    <span class="sa-settimana">{{ this.$i18n.t("patient.agenda.lblWeek") }}</span>
                                </b-card-header>
                                <b-card-body class="text-center" style="overflow: auto; height: 100%">
                                    <agenda-week-component ref="cmpAgendaWeekComponent" v-if="disponibilita"></agenda-week-component>
                                    <div v-else>{{ this.$i18n.t("patient.agenda.lbl.NoDisponibility") }}</div>
                                </b-card-body>
                            </b-card>
                        </template>
                    </b-carousel-slide>
                    <b-carousel-slide>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide</p>
                        <p>Mese Slide Ultimo</p>
                    </b-carousel-slide>
                    <b-carousel-slide><p>Elenco Slide</p></b-carousel-slide>
                </b-carousel>
            </div>
        </div>
        <div class="sa-free-page-footer"></div>
    </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import AgendaComponent from "./AgendaComponent.vue";
import AgendaDayComponent from "./AgendaDayComponent.vue";
import AgendaWeekComponent from "./AgendaWeekComponent.vue";
require("moment/locale/it");

moment.locale("it");
export default {
    components: { AgendaComponent, AgendaDayComponent, AgendaWeekComponent },
    data() {
        return {
            idAgenda: null,
            titolo: "Titolo",
            pathResourceAgenda: "/agende",
            showDatePicker: false,
            subTitle: "",
            numeroDisponibilita: 0,
            disabilitato: false,
            prestazioniOptions: [],
            giornoRiferimento: null,
            btnAgendaVariant: "primary",
            btnGiornoVariant: "outline-primary",
            btnSettimanaVariant: "outline-primary",
            btnMeseVariant: "outline-primary",
            btnElencoAppVariant: "outline-primary",
            disponibilita: [],
            codicePrestazioneSelezionato: null,
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadInfoAgenda();
    },
    methods: {
        loadInfoAgenda() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceAgenda + "/";
            axios
                .get(link + me.id)
                .then((response) => {
                    me.titolo = response.data.data.titolo;
                    me.jsonData = response.data.data;
                    me.prestazioniOptions = me.jsonData.prestazioni;
                    if (me.jsonData.prestazioni.length > 0) {
                        me.codicePrestazioneSelezionato = me.prestazioniOptions[0].codicePrestazione;
                    }
                    me.setRiferimento();
                    // me.$refs["sa-modal-loading"].close();
                })
                .catch(() => {
                    // me.$refs["sa-modal-loading"].close();
                });
        },
        setRiferimento(dataSelezionata = null) {
            let me = this;
            let jsonPrestazione = me.getPretazione(me.codicePrestazioneSelezionato);
            if (dataSelezionata === null) {
                dataSelezionata = new Date();
            }
            switch (me.$refs.crsCalendar.index) {
                case 0:
                    me.riferimento = moment(dataSelezionata).format("YYYY-MM-DD");
                    me.subTitle = moment().format("YYYY");
                    this.$refs.cmpAgendaComponent.loadDisponibilita(me.jsonData, me.riferimento, jsonPrestazione);
                    break;
                case 1:
                    me.riferimento = moment(dataSelezionata).format("YYYY-MM-DD");
                    me.subTitle = moment().format("YYYY");
                    this.$refs.cmpAgendaDayComponent.loadDisponibilita(me.jsonData, me.riferimento, jsonPrestazione);
                    break;
                case 2:
                    me.riferimento = moment(dataSelezionata).format("YYYY-MM-DD");
                    me.subTitle = moment().format("YYYY");
                    this.$refs.cmpAgendaWeekComponent.loadDisponibilita(me.jsonData, me.riferimento, jsonPrestazione);
                    break;
            }
        },
        onAgenda() {
            this.btnAgendaVariant = "primary";
            this.btnGiornoVariant = "outline-primary";
            this.btnSettimanaVariant = "outline-primary";
            this.btnMeseVariant = "outline-primary";
            this.btnElencoAppVariant = "outline-primary";
            this.$refs.crsCalendar.setSlide(0);
        },
        onGiorno() {
            this.btnAgendaVariant = "outline-primary";
            this.btnGiornoVariant = "primary";
            this.btnSettimanaVariant = "outline-primary";
            this.btnMeseVariant = "outline-primary";
            this.btnElencoAppVariant = "outline-primary";
            this.$refs.crsCalendar.setSlide(1);
            this.setRiferimento();
        },
        onSettimana() {
            this.btnAgendaVariant = "outline-primary";
            this.btnGiornoVariant = "outline-primary";
            this.btnSettimanaVariant = "primary";
            this.btnMeseVariant = "outline-primary";
            this.btnElencoAppVariant = "outline-primary";
            this.$refs.crsCalendar.setSlide(2);
            this.setRiferimento();
        },
        onMese() {
            this.btnAgendaVariant = "outline-primary";
            this.btnGiornoVariant = "outline-primary";
            this.btnSettimanaVariant = "outline-primary";
            this.btnMeseVariant = "primary";
            this.btnElencoAppVariant = "outline-primary";
            this.$refs.crsCalendar.setSlide(3);
            this.setRiferimento();
        },
        onElencoApp() {
            this.btnAgendaVariant = "outline-primary";
            this.btnGiornoVariant = "outline-primary";
            this.btnSettimanaVariant = "outline-primary";
            this.btnMeseVariant = "outline-primary";
            this.btnElencoAppVariant = "primary";
            this.$refs.crsCalendar.setSlide(4);
            this.setRiferimento();
        },
        getPretazione(codicePrestazione) {
            let me = this;
            let returnValue = null;
            me.jsonData.prestazioni.forEach((element) => {
                if (element.codicePrestazione === codicePrestazione) {
                    returnValue = element;
                }
            });
            return returnValue;
        },
        onBack() {},
        onRefresh() {
            let me = this;
            me.setRiferimento();
        },
    },
};
</script>

<style></style>
