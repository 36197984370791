<template>
  <sa-page-layout
    :showModalLoading="showModalLoading"
    :pathResource="pathResource"
    :btnBackVisible="true"
    :btnSaveVisible="true"
    :linkback="linkback"
    :data="prenotazione"
  >
    <template slot="toolbar-title">Crea Prenotazione</template>
    <template slot="table-body">
      <div class="sa-div-scroll sa-scroll">
        <div v-for="item in prenotazione.dettaglioAnagrafica" :key="item.id">
          <b-row>
            <b-col xs="6" sm="6" md="2" lg="2" xl="2">
              <label class="sa-form-label" for="prenotazione-cognome"
                >Cognome</label
              >
              <br />
              <span>{{ item.cognome }}</span>
            </b-col>
            <b-col xs="6" sm="6" md="2" lg="2" xl="2">
              <label class="sa-form-label" for="prenotazione-nome">Nome</label>
              <br />
              <span>{{ item.nome }}</span>
            </b-col>
            <b-col xs="12" sm="6" md="2" lg="2" xl="2">
              <label class="sa-form-label" for="prenotazione-data-nascita"
                >Data di Nascita</label
              >
              <br />
              <span>{{ formatDate(item.dataNascita) }}</span>
            </b-col>
            <b-col xs="6" sm="6" md="2" lg="2" xl="2">
              <label class="sa-form-label" for="prenotazione-sesso"
                >Sesso</label
              >
              <br />
              <span>{{ item.sesso }}</span>
            </b-col>
            <b-col xs="6" sm="6" md="2" lg="2" xl="2">
              <label class="sa-form-label" for="prenotazione-celullare">
                Cellulare</label
              >
              <br />
              <span>{{ item.cellulare }}</span>
            </b-col>
            <b-col xs="6" sm="6" md="2" lg="2" xl="2">
              <label class="sa-form-label" for="prenotazione-email"
                >Email</label
              >
              <br />
              <span>{{ item.email }}</span>
            </b-col>
          </b-row>
          <hr />
        </div>
        <br />
        <b-row>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-form-label" for="prenotazione-regione"
              >Regione</label
            >
            <b-form-select
              id="prenotazione-regione"
              v-model="prenotazione.regione"
              class="mb-2 mr-sm-2 mb-sm-0"
              :options="regioniOptions"
              text-field="regione"
              value-field="codiceIstat"
            ></b-form-select>
          </b-col>
          <b-col xs="12" sm="12" md="2" lg="2" xl="2">
            <label class="sa-form-label" for="prenotazione-codice-pacchetto"
              >Codice Pacchetto</label
            >
            <b-input-group class="mb-3" prepend="">
              <b-form-input
                placeholder="Codice Pacchetto"
                v-model="prenotazione.codicePacchetto"
                disabled
              ></b-form-input>
              <b-input-group-append>
                <b-button
                  size="sm"
                  text="Button"
                  variant="success"
                  @click="onCodicePacchettoClick"
                  ><b-icon icon="search"></b-icon
                ></b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-form-label" for="prenotazione-descrizione"
              >Descrizione</label
            >
            <b-form-input
              id="prenotazione-descrizione"
              disabled
              v-model="prenotazione.descrizionePacchetto"
              placeholder="Descrizione Pacchetto"
            ></b-form-input>
          </b-col>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-form-label" for="prenotazione-prezzo"
              >Prezzo ( € )</label
            >
            <b-form-input
              id="prenotazione-prezzo"
              disabled
              v-model="prenotazione.prezzo"
              placeholder="Prezzo Prestazione"
            ></b-form-input>
          </b-col>
        </b-row>

        <b-row>
          <b-col xs="12" sm="12" md="8" lg="8" xl="8">
            <label class="sa-form-label" for="prenotazione-punto-prelievo"
              >Punto Prelievo</label
            >
            <b-form-select
              id="prenotazione-punto-prelievo"
              v-model="prenotazione.idPuntoPrelievo"
              :options="puntiPrelievoOptions"
              value-field="id"
              text-field="descrizione"
              @input="onPuntoPrelievoInput"
            ></b-form-select>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-form-label" for="prenotazione-data-prenotazione"
              >Data Prenotazione</label
            >
            <date-picker
              id="prenotazione-data-prenotazione"
              :disabled-date="notBefore"
              :hour-options="hourOptions"
              placeholder="DD-MM-YYYY HH:mm"
              style="display: block; width: 100%"
              format="DD-MM-YYYY HH:mm"
              value-type="timestamp"
              v-model="prenotazione.dataOra"
              type="datetime"
            ></date-picker>
          </b-col>
        </b-row>
        <br />
        <template
          v-if="
            prenotazione.puntoPrelievo === 'domicilio' ||
            prenotazione.puntoPrelievo === 'residenza' ||
            prenotazione.puntoPrelievo === 'altro'
          "
        >
          <b-row>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
              <label class="sa-form-label" for="prenotazione-nazione"
                >Nazionalità</label
              >
              <b-form-select
                id="prenotazione-nazione"
                :disabled="disabledDatiAnagrafica || disabledDatiAltro"
                v-model="prenotazione.nazione"
                class="mb-2 mr-sm-2 mb-sm-0"
                :options="nazioniOptions"
                text-field="comune"
                value-field="codiceIstat"
                @input="onNazioneInput"
              ></b-form-select>
            </b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <label
                class="sa-form-label"
                for="prenotazione-tipo-identificativo"
                >Tipo Identificativo</label
              >
              <b-form-select
                id="prenotazione-tipo-identificativo"
                :disabled="
                  isDisabledTipoIdentificativo || disabledDatiAnagrafica
                "
                v-model="prenotazione.tipoIdentificativo"
                :options="tipoIdentificativoOption"
                value-field="value"
                text-field="text"
              ></b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-form-label" for="prenotazione-identificativo"
                >Identificativo</label
              >
              <b-form-input
                id="prenotazione-identificativo"
                :disabled="disabledDatiAnagrafica"
                v-model="prenotazione.identificativo"
                type="text"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="12" md="9" lg="9" xl="9">
              <label class="sa-form-label" for="prenotazione-indirizzo"
                >Indirizzo</label
              >
              <b-form-input
                id="prenotazione-indirizzo"
                :disabled="disabledDatiAnagrafica"
                v-model="prenotazione.indirizzo"
                placeholder="Indirizzo"
              ></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-form-label" for="prenotazione-civico"
                >Civico</label
              >
              <b-form-input
                id="prenotazione-civico"
                :disabled="disabledDatiAnagrafica"
                v-model="prenotazione.civico"
                placeholder="Civico"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label for="prenotazione-provincia">Provincia</label>
              <b-form-select
                id="prenotazione-provincia"
                v-model="prenotazione.provincia"
                :disabled="disabledDatiAnagrafica"
                :options="provinceOptions"
                text-field="provincia"
                value-field="sigla"
                @change="onProvinciaInput()"
              ></b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-form-label" for="prenotazione-comune"
                >Comune</label
              >
              <b-form-select
                id="prenotazione-comune"
                v-model="prenotazione.comune"
                :disabled="disabledDatiAnagrafica"
                class="mb-2 mr-sm-2 mb-sm-0"
                :options="comuniOptions"
                text-field="comune"
                value-field="codiceIstat"
                @change="onComuneInput"
              ></b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-form-label" for="prenotazione-frazione"
                >Frazione</label
              >
              <b-form-input
                id="prenotazione-frazione"
                :disabled="disabledDatiAnagrafica"
                v-model="prenotazione.frazione"
                placeholder="Frazione"
              ></b-form-input>
            </b-col>

            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-form-label" for="prenotazione-cap">C.A.P.</label>
              <b-form-input
                id="prenotazione-cap"
                :disabled="disabledDatiAnagrafica"
                v-model="prenotazione.cap"
                placeholder="C.A.P"
              ></b-form-input>
            </b-col>
          </b-row>
          <!-- <b-row>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-form-label" for="prenotazione-latitudine">Latitudine</label>
            <b-form-input id="prenotazione-latitudine" v-model="prenotazione.latitudineDomicilio" readonly placeholder="Latitudine"></b-form-input>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-form-label" for="prenotazione-longitudine">Longitudine</label>
            <b-form-input id="prenotazione-longitudine" v-model="prenotazione.longitudineDomicilio" readonly placeholder="Longitudine"></b-form-input>
          </b-col>
        </b-row> -->
        </template>
        <br />
        <b-row>
          <b-col cols="12">
            <label class="sa-form-label" for="prenotazione-cap">Note</label>
            <b-textarea
              v-model="prenotazione.note"
              placeholder="Note"
              rows="7"
            ></b-textarea>
          </b-col>
        </b-row>
      </div>

      <b-modal
        ref="mdlPacchetti"
        id="modalPacchetti"
        no-close-on-backdrop
        size="xl"
        title="Pacchetti"
        header-bg-variant="light"
        header-border-variant="success"
        footer-border-variant="danger"
      >
        <template>
          <b-card>
            <b-table
              bordered
              sticky-header
              ref="table"
              select-mode="single"
              stacked="lg"
              selectable
              hover
              @row-selected="onRowSelected"
              :items="pacchetti"
              :fields="pacchettiFields"
              sort-icon-left
              head-variant="light"
              class="sa-b-table"
            >
              <template v-slot:cell(id)="{ item }">
                <router-link
                  class="sa-edit-link"
                  :to="'/altreprenotazioni/view/' + item.id"
                >
                  {{ item.id }}
                </router-link>
              </template>
              <template v-slot:cell(descrizioneAttivita)="{ item }">
                <span>{{ item.descrizioneAttivita }}</span>
                <div v-if="item.note != null">
                  <span> Notazioni :</span>
                  <span> {{ item.note }}</span>
                </div>
                <div v-if="item.istruzioniAttivitaPerUtente != null">
                  <span> Istruzioni Per l'Utente :</span>
                  <br />
                  <span>{{ item.istruzioniAttivitaPerUtente }}</span>
                </div>
              </template>
              <template #cell(selezionato)="{ rowSelected }">
                <template v-if="rowSelected">
                  <b-icon icon="check-circle-fill" aria-hidden="true"></b-icon>
                  <span class="sr-only">Selected</span>
                </template>
                <template v-else>
                  <span aria-hidden="true">&nbsp;</span>
                  <span class="sr-only">Not selected</span>
                </template>
              </template>
              <template #cell(show_details)="row">
                <b-button
                  size="sm"
                  variant="outline-secondary"
                  @click="row.toggleDetails"
                  class="mr-2"
                >
                  {{ row.detailsShowing ? " Chiudi " : "Dettagli" }}
                </b-button>
              </template>
              <template #row-details="row">
                <b-card>
                  <ul>
                    <li
                      v-for="dettaglio in row.item.dettagli"
                      :key="dettaglio.codiceAttivita"
                    >
                      <span>{{ dettaglio.descrizioneAttivita }}</span>

                      <div v-if="dettaglio.note != null">
                        <span> Notazioni :</span>
                        <span> {{ dettaglio.note }}</span>
                      </div>

                      <br />
                    </li>
                  </ul>
                </b-card>
              </template>
            </b-table>
          </b-card>
        </template>
        <template #modal-footer="row">
          <b-button size="sm" variant="outline-danger" @click="onAnnulla()">
            Annulla
          </b-button>
          <b-button
            size="sm"
            variant="outline-success"
            :disabled="mdlPacchettiOkDisabled"
            @click="onOk(row)"
          >
            Ok
          </b-button>
        </template>
      </b-modal>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../template/layout/components/SaPageLayout.vue";
import DatePicker from "vue2-datepicker";

import UtilityMixins from "../../utility/UtilityMixin";
export default {
  props: {
    utente: {
      type: Object,
      default: function () {
        return { idUtente: null };
      },
    },
  },
  components: { SaPageLayout, DatePicker },
  mixins: [UtilityMixins],
  data() {
    return {
      mdlPacchettiOkDisabled: true,
      pacchettoSelezionato: [],
      puntoPrelievoSelezionato: null,
      pathResource: "/prenotazione",
      linkback: "/lemieprenotazioni",
      showModalLoading: false,
      disabledDatiAltro: false,
      isDisabledTipoIdentificativo: false,
      hourOptions: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
      prenotazione: {
        idUtente: null,
        regione: null,
        cap: null,
        nazione: "Italia",
        civico: null,
        codicePacchetto: null,
        comuneCodiceIstat: null,
        provincia: null,
        dataOra: null, //new Date().getTime(),
        descrizionePacchetto: null,
        frazione: null,
        indirizzo: null,
        idPuntoPrelievo: null,
        distanzaDaHub: null,
        note: "",
        dettaglioAnagrafica: [],
        dettagli: [],
      },
      disabledDatiAnagrafica: false,
      regioniOptions: [],
      comuniOptions: [],
      provinceOptions: [],
      nazioniOptions: [],
      puntiPrelievoOptions: [],
      pacchetti: [],
      anagrafica: {},
      tipoIdentificativoOption: [
        { text: "- Seleziona Tipo Identificativo' -", value: null },
        { text: "Carta D'Identita'", value: "CI" },
        { text: "Codice Fiscale", value: "CF" },
        { text: "Passaporto", value: "PS" },
      ],
      pacchettiFields: [
        {
          label: "",
          key: "selezionato",
          thStyle: "width: 3rem",
          tdClass: "text-center",
        },
        {
          label: "Codice Pacchetto",
          key: "codiceAttivita",
          tdClass: "text-left",
        },
        {
          label: "Descrizione",
          key: "descrizioneAttivita",
          tdClass: "text-left",
        },
        {
          label: "Durata",
          key: "durataAttivita",
          tdClass: "text-left",
          //   formatter: (value) => {
          //     return `${value + "€"}`;
          //   },
        },
        {
          label: "Prezzo",
          key: "prezzoListino",
          tdClass: "text-left",
          formatter: (value) => {
            return `${value + "€"}`;
          },
        },
        {
          label: "",
          key: "show_details",
          tdClass: "text-left",
        },
      ],
    };
  },

  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.prenotazione.idUtente = me.utente.id;
    console.log(me.prenotazione.idUtente);
    me.loadDefaultData();
    me.loadPuntiPrelievo();
    me.loadAnagrafica();
  },
  watch: {
    utente() {
      let me = this;
      // me.prenotazione.id = me.utente.id;
      me.loadAnagrafica();
    },
    "prenotazione.idPuntoPrelievo": function (value) {
      let me = this;
      // me.prenotazione.email = me.anagrafica.email;
      // me.prenotazione.cellulare = me.anagrafica.cellulare;
      if (value === "altro") {
        me.disabledDatiAnagrafica = false;
        (me.disabledDatiAltro = true),
          (me.prenotazione.civico = null),
          (me.prenotazione.cap = null),
          (me.prenotazione.indirizzo = null),
          (me.prenotazione.comune = null),
          (me.prenotazione.frazione = null);
      } else if (value === "domicilio") {
        me.disabledDatiAnagrafica = true;
        me.loadComuni(me.anagrafica.provinciaDomicilioLabel);
        me.prenotazione.nazione = me.anagrafica.nazione;
        me.prenotazione.frazione = me.anagrafica.frazioneDomicilio;
        me.prenotazione.comuneCodiceIstat =
          me.anagrafica.comuneDomicilioCodiceIstat;
        me.prenotazione.indirizzo = me.anagrafica.indirizzoDomicilio;
        me.prenotazione.cap = me.anagrafica.capDomicilio;
        me.prenotazione.civico = me.anagrafica.civicoDomicilio;
        me.prenotazione.provincia = me.anagrafica.provinciaDomicilioLabel;
      } else if (value === "residenza") {
        me.disabledDatiAnagrafica = true;
        me.loadComuni(me.anagrafica.provinciaResidenzaLabel);
        me.prenotazione.nazione = me.anagrafica.nazione;
        me.prenotazione.frazione = me.anagrafica.frazioneResidenza;
        me.prenotazione.comuneCodiceIstat =
          me.anagrafica.comuneResidenzaCodiceIstat;
        me.prenotazione.indirizzo = me.anagrafica.indirizzoResidenza;
        me.prenotazione.cap = me.anagrafica.capResidenza;
        me.prenotazione.civico = me.anagrafica.civicoResidenza;
        me.prenotazione.provincia = me.anagrafica.provinciaResidenzaLabel;
      } else {
        me.disabledDatiAnagrafica = false;
        me.setDatiPrelievo();
      }
    },
  },
  methods: {
    notBefore: function (date) {
      return date < new Date(); // || date > new Date(2019, 10, 6);
    },
    onRowSelected(items) {
      let me = this;
      me.pacchettoSelezionato = items;
      me.mdlPacchettiOkDisabled = me.pacchettoSelezionato.length == 0;
    },
    onOk() {
      let me = this;
      me.prenotazione.codicePacchetto =
        me.pacchettoSelezionato[0].codiceAttivita;
      me.prenotazione.descrizionePacchetto =
        me.pacchettoSelezionato[0].descrizioneAttivita;
      me.prenotazione.prezzo = me.pacchettoSelezionato[0].prezzoListino;
      me.prenotazione.dettagli = me.setJsonDettagliPrenotazione(
        me.pacchettoSelezionato[0].dettagli
      );
      me.$refs["mdlPacchetti"].hide();
    },
    onAnnulla() {
      let me = this;
      me.$refs["mdlPacchetti"].hide();
    },
    onCodicePacchettoClick() {
      let me = this;
      me.loadPacchetti();
    },
    onNazioneInput(value) {
      let me = this;
      if (value === "111100") {
        me.isDisabledTipoIdentificativo = true;
        me.prenotazione.tipoIdentificativo = "CF";
      } else {
        me.isDisabledTipoIdentificativo = false;
        me.prenotazione.tipoIdentificativo = null;
      }
    },
    loadDefaultData() {
      let me = this;
      let linkNazioni = process.env.VUE_APP_PATH_API + "/nazioni";
      axios.get(linkNazioni).then((response) => {
        me.nazioniOptions = response.data.data;
      });
      let linkProvince = process.env.VUE_APP_PATH_API + "/province";
      axios.get(linkProvince).then((response) => {
        me.provinceOptions = response.data.data;
      });
      let linkRegioni = process.env.VUE_APP_PATH_API + "/regioni";
      axios.get(linkRegioni).then((response) => {
        me.regioniOptions = response.data.data;
        me.regioniOptions.unshift({
          regione: "-Seleziona Regione-",
          codiceIstat: null,
        });
      });
    },
    loadPacchetti() {
      let me = this;
      me.showModalLoading = true;
      let linkPacchetti = process.env.VUE_APP_PATH_API + "/pacchetti";
      axios.get(linkPacchetti).then((response) => {
        me.pacchetti = response.data.data;
        me.$refs["mdlPacchetti"].show();
        me.showModalLoading = false;
      });
    },
    onProvinciaInput(value) {
      let me = this;
      me.loadComuni(value);
    },
    loadAnagrafica() {
      let me = this;
      if (me.utente.id) {
        let link =
          process.env.VUE_APP_PATH_API + "/anagrafiche/" + me.utente.id;
        axios.get(link).then((response) => {
          console.log(response.data.data);
          me.prenotazione.dettaglioAnagrafica.push({
            idAnagrafica: response.data.data.id,
            cognome: response.data.data.cognome,
            nome: response.data.data.nome,
            sesso: response.data.data.sesso,
            dataNascita: response.data.data.dataNascita,
            tipoIdentificativo: response.data.data.tipoIdentificativo,
            identificativo: response.data.data.identificativo,
            email: response.data.data.email,
            cellulare: response.data.data.cellulare,
            note: response.data.data.note,
          });
          me.anagrafica = response.data.data;
          me.setDatiPrelievo();
        });
      }
    },
    setJsonDettagliPrenotazione(array) {
      let returnValue = [];
      array.forEach((element) => {
        returnValue.push({
          codiceAttivita: element.idAttivita,
          descrizioneAttivita: element.descrizioneAttivita,
          note: element.note,
        });
      });

      return returnValue;
    },
    setDatiPrelievo() {
      let me = this;

      // me.prenotazione.sesso = me.anagrafica.sesso;
      // me.prenotazione.nazione = me.anagrafica.nazione;
      // me.prenotazione.idUtente = me.anagrafica.id;
      // me.prenotazione.tipoIdentificativo = me.anagrafica.tipoIdentificativo;
      // me.prenotazione.identificativo = me.anagrafica.identificativo;
      // me.prenotazione.idAnagrafica = me.anagrafica.id;
      me.prenotazione.idUtente = me.utente.id;
      if (me.puntoPrelievoSelezionato) {
        me.prenotazione.comuneCodiceIstat =
          me.puntoPrelievoSelezionato.comuneCodiceIstat;
        me.prenotazione.cap = me.puntoPrelievoSelezionato.cap;
        me.prenotazione.indirizzo = me.puntoPrelievoSelezionato.indirizzo;
        me.prenotazione.provincia = me.puntoPrelievoSelezionato.provincia;
      }
    },
    loadPuntiPrelievo() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/puntiprelievo";
      axios.get(link).then((response) => {
        if (response) {
          response.data.data.forEach((element) => {
            element.descrizione =
              element.descrizione +
              " ( " +
              element.indirizzo +
              " - " +
              element.cap +
              " " +
              element.provincia +
              " , " +
              element.comuneLabel +
              " ) ";
            me.puntiPrelievoOptions.push(element);
          });
          me.puntiPrelievoOptions.unshift(
            { id: null, descrizione: "- Selezionare Punto Prelievo -" },
            { id: "domicilio", descrizione: "Domicilio" },
            { id: "residenza", descrizione: "Residenza" }
            // { id: "altro", descrizione: "Altro" }
          );
        }
      });
    },
    loadCap(codiceIstat) {
      let me = this;
      me.comuniOptions.forEach((element) => {
        if (element.codiceIstat === codiceIstat) {
          me.prenotazione.cap = element.cap;
        }
      });
    },
    loadCAP(codiceIstatComune) {
      let me = this;
      me.comuniOptions.forEach((element) => {
        if (element.codiceIstat === codiceIstatComune) {
          me.prenotazione.cap = element.cap;
        }
      });
    },
    onComuneInput(value) {
      let me = this;
      me.loadCAP(value);
    },
    loadComuni(provincia) {
      let me = this;
      let linkComuni =
        process.env.VUE_APP_PATH_API + "/comuni" + "/?provincia=" + provincia;
      axios.get(linkComuni).then((response) => {
        me.comuniOptions = response.data.data;
      });
    },

    onPuntoPrelievoInput(value) {
      let me = this;
      me.puntoPrelievoSelezionato = null;
      if (value) {
        me.puntiPrelievoOptions.forEach((puntoPrelievo) => {
          if (puntoPrelievo.id === value) {
            me.puntoPrelievoSelezionato = puntoPrelievo;
          }
        });
        let tipo = value;
        let idAnagrafica = me.utente.id;
        me.prenotazione.distanzaDaHub = 0;
        let linkComuni =
          process.env.VUE_APP_PATH_API +
          "/geolocalizzazione/distanzaanagrafica/?idAnagrafica=" +
          idAnagrafica +
          "&tipo=" +
          tipo;
        axios.get(linkComuni).then((response) => {
          me.prenotazione.distanzaDaHub = response.data.data;
        });
      }
    },
    // onPuntoPrelievoInput(value) {
    //     let me = this;
    //     let tipo = value.toUpperCase();
    //     let idAnagrafica = me.prenotazione.idAnagrafica;
    //     me.prenotazione.distanzaDaHub = 0;
    //     let linkComuni = process.env.VUE_APP_PATH_API + "/geolocalizzazione/distanzaanagrafica/?idAnagrafica=" + idAnagrafica + "&tipo=" + tipo;
    //     axios.get(linkComuni).then((response) => {
    //         me.prenotazione.distanzaDaHub = response.data.data;
    //     });
    // },
  },
};
</script>

<style></style>
