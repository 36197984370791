<template>
  <sa-page-layout
    :btnNewVisible="true"
    :linkedit="linkedit"
    :btnRefreshVisible="true"
    @refresh="onRefresh()"
  >
    <template slot="toolbar-title">
      <p>Prenotazioni</p>
    </template>
    <template slot="toolbar-row"> </template>
    <template slot="table-filter"> </template>
    <template slot="table-header"> </template>
    <template slot="table-body">
      <div class="b-table-sticky-header sa-scroll">
        <b-tabs v-model="tabIndex" align="right">
          <b-tab title="Prenotazioni">
            <b-table
              sticky-header
              ref="table"
              stacked="lg"
              striped
              hover
              :items="items"
              :fields="fields"
              :current-page="currentPage"
              :per-page="perPage"
              sort-icon-left
              head-variant="light"
              class="sa-b-table"
            >
              <template v-slot:cell(codicePacchetto)="{ item }">
                <router-link
                  class="sa-edit-link"
                  :to="'/lemieprenotazioni/view/' + item.id"
                >
                  {{
                    (item.codicePacchetto =
                      item.codicePacchetto === null ? "" : item.codicePacchetto)
                  }}
                </router-link>
                <br />
                <small>
                  {{
                    (item.descrizionePacchetto =
                      !item.descrizionePacchetto === null
                        ? ""
                        : item.descrizionePacchetto)
                  }}
                  {{ "( " + item.prezzo + " € " + " ) " }}
                </small>
              </template>
              <template v-slot:cell(idPuntoPrelievo)="{ item }">
                <span v-if="item.idPuntoPrelievo == 'domicilio'">{{
                  item.idPuntoPrelievo
                }}</span>
                <span v-else-if="item.idPuntoPrelievo == 'residenza'">{{
                  item.idPuntoPrelievo
                }}</span>
                <b-link
                  v-else
                  @click="onPuntoPrelievoClick(item.idPuntoPrelievo)"
                  v-b-modal.modalDettaglioPuntoPrelievo
                  >{{ item.puntoPrelievo }}</b-link
                >
                <!-- <router-link class="sa-edit-link" :to="'/lemieprenotazioni/view/' + item.id">
                            {{ (item.codicePacchetto = item.codicePacchetto === null ? "" : item.codicePacchetto) }}
                        </router-link> -->
              </template>
              <template v-slot:cell(action)="row">
                <b-button
                  size="sm"
                  variant="outline-danger"
                  @click="onDeleteItem(row.item, row.index, $event.target)"
                  v-b-modal.visualizzaAllegato
                >
                  <b-icon icon="trash"></b-icon>
                </b-button>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Anagrafiche Prenotazioni">
            <anagrafiche-prenotazioni-tab-component
              :jsonData="jsonData"
            ></anagrafiche-prenotazioni-tab-component>
          </b-tab>
        </b-tabs>
      </div>
    </template>
    <template slot="table-footer">
      <b-modal
        id="modalDettaglioPuntoPrelievo"
        header-border-variant="success"
        footer-border-variant="danger"
        ok-variant="success"
        ok-only
      >
        <punti-prelievo-view-component
          :jsonData="jsonData"
        ></punti-prelievo-view-component>
      </b-modal>
    </template>
  </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../template/layout/components/SaPageLayout.vue";
import axios from "axios";
import UtilityMixins from "../../utility/UtilityMixin";
// import ClientiViewComponent from "../../clienti/components/ClientiViewComponent.vue";
import AnagrafichePrenotazioniTabComponent from "../components/AnagrafichePrenotazioniTabComponent.vue";
export default {
  props: {
    utente: {
      type: Object,
      default: function () {
        return { idUtente: null };
      },
    },
  },
  components: {
    SaPageLayout,
    // ClientiViewComponent,
    AnagrafichePrenotazioniTabComponent,
  },

  mounted() {
    let me = this;
    me.loadData();
    me.linkedit = "/lemieprenotazioni";
  },
  mixins: [UtilityMixins],
  data() {
    return {
      id: null,
      linkedit: "null",
      perPage: 100,
      currentPage: 1,
      filter: {},
      show: true,
      jsonData: {},
      tabIndex: 0,
      items: [],
      fields: [
        {
          label: "Pacchetto",
          key: "codicePacchetto",
          tdClass: "text-left",
        },
        {
          label: "Data Ora Prenotazione",
          key: "dataOra",
          thStyle: "width: 10rem",
          tdClass: "text-left",
          sortable: true,
          formatter: (value) => {
            return this.formatDateTime(value);
          },
        },
        {
          label: "Stato Attuale",
          key: "statoAttuale",
          thStyle: "width: 10rem",
          tdClass: "text-left",
          sortable: true,
          formatter: (value) => {
            if (value) return value.toUpperCase();
          },
        },
        {
          label: "Punti Prelievo",
          key: "idPuntoPrelievo",
          thStyle: "width: 10rem",
          tdClass: "text-left",
          sortable: true,
        },

        {
          label: "",
          key: "action",
          thStyle: "width:3rem",
          tdClass: "text-center",
        },
      ],
    };
  },
  watch: {
    utente() {
      let me = this;
      me.loadData();
    },
    tabIndex: function (params) {
      console.log(params);
    },
  },
  methods: {
    onRefresh() {
      let me = this;
      me.loadData();
      // me.$emit("refresh", this.onRefresh());
    },
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/prenotazione";
      axios.get(link).then((response) => {
        me.items = response.data.data;
      });
    },
    onReset(event) {
      event.preventDefault();

      this.filtro.nome = "";
      this.filtro.cognome = "";
      this.filtro.identificativo = "";
      this.filtro.idSettore = null;
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
      this.onFiltraContatti();
    },
    onDeleteItem(item) {
      let me = this;
      this.$bvModal
        .msgBoxConfirm("Sei sicuro di procedere alla cancellazione?", {
          title: "Conferma Cancellazione",
          buttonSize: "sm",
          okVariant: "secondary",
          cancelVariant: "danger",
          okTitle: "SI",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            me.deleteItem(item);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteItem(item) {
      let me = this;
      me.showModalLoading = true;
      return console.log(item);
      // let link = process.env.VUE_APP_PATH_API + "/prenotazione/";
      // axios
      //   .delete(link, { data: { id: item.id } })
      //   .then((response) => {
      //     console.log(response);
      //     me.showModalLoading = false;
      //     me.loadData();
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    },
    onClick() {},

    onPuntoPrelievoClick(value) {
      console.log(value);
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/puntiprelievo/" + value;
      axios.get(link).then((response) => {
        me.jsonData = response.data.data;
      });
    },
  },
};
</script>

<style></style>
