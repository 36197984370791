<template>
    <div style="height: 100%">
        <div class="sa-flexbox" style="padding-bottom: 20px">
            <div class="sa-flexbox-body">
                <div class="b-table-sticky-header">
                    <span class="sa-flexbox-body-title">Teleconsulti</span>
                    <b-button size="sm" variant="outline-success outline-primary-noborder sa-margin-right float-sm-right" @click="onClickNuovoTeleconsulto" class="mb-2">
                        <font-awesome-icon icon="plus"> </font-awesome-icon>
                        Aggiungi
                    </b-button>

                    <b-table sticky-header ref="table" stacked="lg" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="row">
                            {{ row.index + 1 }}
                        </template>

                        <template v-slot:cell(actions)="row">
                            <b-button size="sm" variant="outline-success" v-b-tooltip.hover title="Modifica Teleconsulto" @click="onClickModificaTeleconsulto(row.item)">
                                <font-awesome-icon icon="edit"> </font-awesome-icon>
                            </b-button>
                            <b-button v-b-tooltip.hover size="sm" variant="outline-secondary" title="Avvia Teleconsulto" @click="onAvviaTeleconsulto(row.item, row.index, $event.target)" class="mr-1 no-text"><b-icon icon="display" variant="outline-secondary" /></b-button>
                        </template>
                    </b-table>
                </div>
            </div>

            <div class="sa-list-component-footer">
                <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col lg="3">
                        <p>Record Totali:{{ rows }}</p>
                    </b-col>
                    <b-col lg="9">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                    </b-col>
                </b-row>
            </div>
        </div>
        <!-- PazienteTeleconsultoEditComponent -->
        <paziente-teleconsulto-edit-component ref="cmpPazienteTeleconsultoEditComponenr" @afterSaveSuccess="onAfterSaveSuccess"></paziente-teleconsulto-edit-component>
    </div>
</template>
<script>
import UtilityMixin from "../../../utility/UtilityMixin";
import PazienteTeleconsultoEditComponent from "./PazienteTeleconsultoEditComponent.vue";
// import axios from "axios";
export default {
    props: {
        // idPaziente: { type: String },
    },

    watch: {
        currentPage() {
            let me = this;
            me.loadData();
        },
    },
    mixins: [UtilityMixin],
    components: { PazienteTeleconsultoEditComponent },
    data() {
        return {
            rows: 0,
            perPage: 50,
            currentPage: 1,
            listKey: 0,
            items: [],
            idPaziente: null,
            pathSorgente: null,
            pathResource: "/teleconsultoprenotazioni",
            linkedit: "/",
            filterItems: [],
            filtroSpesaExtra: {},
            showModalLoading: false,
            idAccettazione: "",
            fields: [
                {
                    label: "",
                    key: "index",
                },
                {
                    label: "Nome Medico",
                    key: "nomeMedicoPresente",
                    sortable: true,
                    //thStyle: "width: 7rem",
                },
                {
                    label: "Cognome Medico",
                    key: "cognomeMedico",
                    //thStyle: "width: rem",
                    sortable: true,
                },
                {
                    label: "Codice Medico",
                    key: "codiceMedicoPresente",
                    sortable: true,
                },
                {
                    class: "sa-table-column-action-double",
                    key: "actions",
                    label: "",
                },
            ],
        };
    },
    mounted() {
        // let me = this;
        // me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            me.$emit("beforeLoadData");
            UtilityMixin.methods.loadDataResources(me.pathResource, { idPaziente: me.idPaziente }, me.loadDataSuccess, me.loadDataError);
            //   this.showModalLoading = true;
        },
        loadDataSuccess(response) {
            let me = this;
            me.items = response.data.data;
            me.rows = me.items.length;
            me.$emit("afterLoadData");
            //  this.showModalLoading = true;
        },
        loadDataError(response) {
            console.log(response);
        },
        onClickNuovoTeleconsulto() {
            let me = this;
            me.$refs.cmpPazienteTeleconsultoEditComponenr.openModal(me.idPaziente, null);
        },
        onClickModificaTeleconsulto(item) {
            let me = this;
            me.$refs.cmpPazienteTeleconsultoEditComponenr.openModal(me.idPaziente, item);
        },

        setPathSorgente(value) {
            let me = this;
            me.pathSorgente = value;
        },
        setIdPaziete(value) {
            let me = this;
            me.idPaziente = value;
        },
        onAvviaTeleconsulto(item) {
            let me = this;
            let link = me.pathSorgente + "/teleconsulti/" + item.id + "?idPaziente=" + this.idPaziente;
            me.$router.replace(link).catch((err) => {
                err;
            });
        },
        onAfterSaveSuccess() {
            let me = this;
            me.loadData();
        },
    },
};
</script>
