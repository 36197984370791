<template>
    <div>
        <b-card class="sa-card" header="Quadro Clinico" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.quadroClinicoSepsi)" :checked="getCheckedValue(jsonData.quadroClinicoSepsi)" switch @input="onInputCheckbox($event, 'quadroClinicoSepsi')">Sepsi</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.quadroClinicoMeningite)" :checked="getCheckedValue(jsonData.quadroClinicoMeningite)" switch @input="onInputCheckbox($event, 'quadroClinicoMeningite')">Meningite</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.quadroClinicoPolmoniteBatterica)" :checked="getCheckedValue(jsonData.quadroClinicoPolmoniteBatterica)" switch @input="onInputCheckbox($event, 'quadroClinicoPolmoniteBatterica')">Polmonite Batterica</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.quadroClinicoCellulite)" :checked="getCheckedValue(jsonData.quadroClinicoCellulite)" switch @input="onInputCheckbox($event, 'quadroClinicoCellulite')">Cellulite</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.quadroClinicoEpiglottite)" :checked="getCheckedValue(jsonData.quadroClinicoEpiglottite)" switch @input="onInputCheckbox($event, 'quadroClinicoEpiglottite')">Epiglottite</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.quadroClinicoPeritonite)" :checked="getCheckedValue(jsonData.quadroClinicoPeritonite)" switch @input="onInputCheckbox($event, 'quadroClinicoPeritonite')">Peritonite</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.quadroClinicoPericardite)" :checked="getCheckedValue(jsonData.quadroClinicoPericardite)" switch @input="onInputCheckbox($event, 'quadroClinicoPericardite')">Pericardite</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.quadroClinicoArtriteSetticaOsteomielite)" :checked="getCheckedValue(jsonData.quadroClinicoArtriteSetticaOsteomielite)" switch @input="onInputCheckbox($event, 'quadroClinicoArtriteSetticaOsteomielite')">Artrite Settica/Osteomielite</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.quadroClinicoAltro)" :checked="getCheckedValue(jsonData.quadroClinicoAltro)" switch @input="onInputCheckbox($event, 'quadroClinicoAltro')">Altro</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Descrizione:</label>
                    <b-form-textarea v-model="jsonData.quadroClinicoAltroDescrizione" rows="2" no-resize :disabled="chekCondition(jsonData.quadroClinicoAltro, 'SI')"></b-form-textarea>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.ricoverato)" :checked="getCheckedValue(jsonData.ricoverato)" switch @input="onInputCheckbox($event, 'ricoverato')">Ricoverato</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
                    <label class="sa-label-data">Data Ricovero:</label>
                    <date-picker v-model="jsonData.dataRicovero" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="chekCondition(jsonData.ricoverato, 'SI')"></date-picker>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Agente Eziologico" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.agenteEziologicoNeisseriaMeningitidis)" :checked="getCheckedValue(jsonData.agenteEziologicoNeisseriaMeningitidis)" switch @input="onInputCheckbox($event, 'agenteEziologicoNeisseriaMeningitidis')">Neisseria Meningitidis</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.agenteEziologicoStreptococcusPneumoniae)" :checked="getCheckedValue(jsonData.agenteEziologicoStreptococcusPneumoniae)" switch @input="onInputCheckbox($event, 'agenteEziologicoStreptococcusPneumoniae')">Streptococcus Pneumoniae</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.agenteEziologicoHaemophilusInfluenzae)" :checked="getCheckedValue(jsonData.agenteEziologicoHaemophilusInfluenzae)" switch @input="onInputCheckbox($event, 'agenteEziologicoHaemophilusInfluenzae')">Haemophilus Influenzae</b-form-checkbox>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Altro Agente Eziologico Causante Meningite Batterica" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox :class="getCheckedClass(jsonData.altroAgenteEziologicoMicrobatterioTubercolare)" :checked="getCheckedValue(jsonData.altroAgenteEziologicoMicrobatterioTubercolare)" switch @input="onInputCheckbox($event, 'altroAgenteEziologicoMicrobatterioTubercolare')">Micobatterio Tubercolare</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox :class="getCheckedClass(jsonData.altroAgenteEziologicoStrptococcoGruppoB)" :checked="getCheckedValue(jsonData.altroAgenteEziologicoStrptococcoGruppoB)" switch @input="onInputCheckbox($event, 'altroAgenteEziologicoStrptococcoGruppoB')">Streptococco Gruppo B</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox :class="getCheckedClass(jsonData.altroAgenteEziologicoListeria)" :checked="getCheckedValue(jsonData.altroAgenteEziologicoListeria)" switch @input="onInputCheckbox($event, 'altroAgenteEziologicoListeria')">Listeria</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox :class="getCheckedClass(jsonData.altroAgenteEziologicoAltro)" :checked="getCheckedValue(jsonData.altroAgenteEziologicoAltro)" switch @input="onInputCheckbox($event, 'altroAgenteEziologicoAltro')">Altro Agente Batterico</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Descrizione Altro Agente Batterico:</label>
                    <b-form-textarea v-model="jsonData.altroAgenteEziologicoAltroDescrizione" rows="2" no-resize :disabled="chekCondition(jsonData.altroAgenteEziologicoAltro, 'SI')"></b-form-textarea>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-form-checkbox :class="getCheckedClass(jsonData.altroAgenteEziologicoNonIdentificato)" :checked="getCheckedValue(jsonData.altroAgenteEziologicoNonIdentificato)" switch @input="onInputCheckbox($event, 'altroAgenteEziologicoNonIdentificato')">Non Identificato (Solo Meningiti con Liquor Torbido o Purulento)</b-form-checkbox>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Diagnosi di Laboratorio" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Persona Contatto Laboratorio:</label>
                    <b-form-input v-model="jsonData.diagnosiLaboratorioPersonaContatto"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Persona Contatto Laboratorio Telefono:</label>
                    <b-form-input v-model="jsonData.diagnosiLaboratorioPersonaContattoTelefono"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Persona Contatto Laboratorio Email:</label>
                    <b-form-input v-model="jsonData.diagnosiLaboratorioPersonaContattoEmail"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Ospedale Laboratorio:</label>
                    <b-form-input v-model="jsonData.diagnosiLaboratorioOspedaleLaboratorio"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Data Prelievo Campione Positivo:</label>
                    <date-picker v-model="jsonData.diagnosiLaboratorioDataPrelievo" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-form-checkbox :class="getCheckedClass(jsonData.eseguitaTipizzazione)" :checked="getCheckedValue(jsonData.eseguitaTipizzazione)" switch @input="onInputCheckbox($event, 'eseguitaTipizzazione')">Eseguita La Tipizzazione? (solo se malattia invasiva da N. meningitidis, S. pneumoniae, H. influenzae )</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Siero Gruppo/Sierotipo:</label>
                    <b-form-input v-model="jsonData.eseguitaTipizzazioneSieroGruppoSierotipo" :disabled="chekCondition(jsonData.eseguitaTipizzazione, 'SI')"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Laboratorio Tipizzazione:</label>
                    <b-form-select v-model="jsonData.eseguitaTipizzazioneLaboratorio" :options="laboratorioOptions" :value="''" value-field="value" text-field="text" :disabled="chekCondition(jsonData.eseguitaTipizzazione, 'SI')"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Altro:</label>
                    <b-form-input v-model="jsonData.eseguitaTipizzazioneLaboratorioAltro" :disabled="chekCondition(jsonData.eseguitaTipizzazioneLaboratorio, 'ALTRO')"></b-form-input>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Esito conosciuto della malattia dalla data di segnalazione" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <!-- <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Ultimo Aggiornamento:</label>
          <b-form-select v-model="jsonData.esitoMalattiaUltimoAggiornamento" :options="ultimoAggiornamentoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col> -->
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Aggiornamento Stato Paziente al Momento della Segnalazione:</label>
                    <b-form-select v-model="jsonData.esitoMalattiaUltimoAggiornamentoStatoPaziente" :options="ultimoAggiornamentoStatoPazienteOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Aggiornamento Stato Paziente a 14 Giorni:</label>
                    <b-form-select v-model="jsonData.esitoMalattiaQuattordiciGiorniStatoPaziente" :options="ultimoAggiornamentoStatoPazienteOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Aggiornamento Stato Paziente a 1 Mese:</label>
                    <b-form-select v-model="jsonData.esitoMalattiaUnMeseStatoPaziente" :options="ultimoAggiornamentoStatoPazienteOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Aggiornamento Stato Paziente a 6 Mesi:</label>
                    <b-form-select v-model="jsonData.esitoMalattiaSeiMesiStatoPaziente" :options="ultimoAggiornamentoStatoPazienteOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Sequele dalla data di segnalazione (solo se mal. invasiva da S. pneumoniae, N. meningitidis, H. influenzae)" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Ultimo Aggiornamento:</label>
                    <b-form-select v-model="jsonData.sequeleDataSrgnalazioneUltimoAggiornamento" :options="ultimoAggiornamentoStatoPazienteOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.sequeleDataSrgnalazionePerditaUdito)" :checked="getCheckedValue(jsonData.sequeleDataSrgnalazionePerditaUdito)" switch @input="onInputCheckbox($event, 'sequeleDataSrgnalazionePerditaUdito')">Perdita Anche Parziale Dell'Udito</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.sequeleDataSrgnalazionePerditaVista)" :checked="getCheckedValue(jsonData.sequeleDataSrgnalazionePerditaVista)" switch @input="onInputCheckbox($event, 'sequeleDataSrgnalazionePerditaVista')">Perdita Anche Parziale Della Vista</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.sequeleDataSrgnalazioneDanniNeurologiciMotori)" :checked="getCheckedValue(jsonData.sequeleDataSrgnalazioneDanniNeurologiciMotori)" switch @input="onInputCheckbox($event, 'sequeleDataSrgnalazioneDanniNeurologiciMotori')">Danni Neurologici Compresi Quelli Motori</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.sequeleDataSrgnalazioneAmputazione)" :checked="getCheckedValue(jsonData.sequeleDataSrgnalazioneAmputazione)" switch @input="onInputCheckbox($event, 'sequeleDataSrgnalazioneAmputazione')">Amputazione</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.sequeleDataSrgnalazioneNecrosiCicatriciCutanee)" :checked="getCheckedValue(jsonData.sequeleDataSrgnalazioneNecrosiCicatriciCutanee)" switch @input="onInputCheckbox($event, 'sequeleDataSrgnalazioneNecrosiCicatriciCutanee')">Necrosi E Cicatrici A Livello Cutaneo</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.sequeleDataSrgnalazioneAltro)" :checked="getCheckedValue(jsonData.sequeleDataSrgnalazioneAltro)" switch @input="onInputCheckbox($event, 'sequeleDataSrgnalazioneAltro')">Altro</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Altro:</label>
                    <b-form-textarea v-model="jsonData.sequeleDataSrgnalazioneAltroDettaglio" rows="2" no-resize :disabled="chekCondition(jsonData.sequeleDataSrgnalazioneAltro, 'SI')"></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Contatti e focolaio epidemico:" header-tag="header" footer-tag="footer" title="">
            <h4>Nei 10 giorni precedenti l’inizio dei sintomi, il paziente</h4>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Contatto Con Un Altro Caso Della Stessa Malattia:</label>
                    <b-form-select v-model="jsonData.contattoAltroCasoStessaMalattia" :options="siConfermatoSiNonConfermatoNOOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Probabile contagio fuori area di domicilio abituale:</label>
                    <b-form-checkbox :class="getCheckedClass(jsonData.probabileContagioFuoriAreaDomicilioAbituale)" :checked="getCheckedValue(jsonData.probabileContagioFuoriAreaDomicilioAbituale)" switch @input="onInputCheckbox($event, 'probabileContagioFuoriAreaDomicilioAbituale')"></b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Dove:</label>
                    <b-form-input v-model="jsonData.probabileContagioFuoriAreaDomicilioAbitualeDove" :disabled="chekCondition(jsonData.probabileContagioFuoriAreaDomicilioAbituale, 'SI')"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Focolaio Epidemico Conosciuto:</label>
                    <b-form-checkbox :class="getCheckedClass(jsonData.parteFocolaioEpidemiologicoConosciuto)" :checked="getCheckedValue(jsonData.parteFocolaioEpidemiologicoConosciuto)" switch @input="onInputCheckbox($event, 'parteFocolaioEpidemiologicoConosciuto')"></b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Quale:</label>
                    <b-form-input v-model="jsonData.parteFocolaioEpidemiologicoConosciutoQuale" :disabled="chekCondition(jsonData.parteFocolaioEpidemiologicoConosciuto, 'SI')"></b-form-input>
                </b-col>
            </b-row>
            <h4>Comunità frequentate</h4>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.comunitaNodo)" :checked="getCheckedValue(jsonData.comunitaNodo)" switch @input="onInputCheckbox($event, 'comunitaNodo')">Nido</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.comunitaScuolaMaterna)" :checked="getCheckedValue(jsonData.comunitaScuolaMaterna)" switch @input="onInputCheckbox($event, 'comunitaScuolaMaterna')">Scuola Materna</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.comunitaScuola)" :checked="getCheckedValue(jsonData.comunitaScuola)" switch @input="onInputCheckbox($event, 'comunitaScuola')">Scuola</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.comunitaOspedale)" :checked="getCheckedValue(jsonData.comunitaOspedale)" switch @input="onInputCheckbox($event, 'comunitaOspedale')">Ospedale</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.comunitaCaserma)" :checked="getCheckedValue(jsonData.comunitaCaserma)" switch @input="onInputCheckbox($event, 'comunitaCaserma')">Caserma</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.comunitaAltraComunita)" :checked="getCheckedValue(jsonData.comunitaAltraComunita)" switch @input="onInputCheckbox($event, 'comunitaAltraComunita')">Altra Comunita</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Altro:</label>
                    <b-form-textarea v-model="jsonData.comunitaAltraComunitaDescrizione" rows="2" no-resize :disabled="chekCondition(jsonData.comunitaAltraComunita, 'SI')"></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Stato vaccinale (solo se malattia invasiva da S. pneumoniae, N. meningitidis, H. influenzae)" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Vaccinato Agente In Causa:</label>
                    <b-form-select v-model="jsonData.vaccinatoAgenteCausa" :options="vaccinoAgenteCausaOptions" :value="''" value-field="value" text-field="text" @change="onCheckVaccinatoAgenteCausa"></b-form-select>
                </b-col>
            </b-row>
            <b-table sticky-header ref="tblViaggi" stacked="xl" striped hover itemscope :items="jsonData.listaVaccinazioni" :fields="fieldsVaccinazioni" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                <template #cell(index)="jsonData">
                    {{ jsonData.index + 1 }}
                </template>
                <template #cell(numeroDose)="item">
                    <b-form-input v-model="item.item.numeroDose" placeholder="Numero Dose"></b-form-input>
                </template>
                <template #cell(dataSomministrazione)="item">
                    <date-picker v-model="item.item.dataSomministrazione" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
                </template>
                <template #cell(nomeCommerciale)="item">
                    <b-form-input v-model="item.item.nomeCommerciale" placeholder="Nome Commerciale"></b-form-input>
                </template>
                <template #cell(actions)="row">
                    <b-button v-if="row.index == jsonData.listaVaccinazioni.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddVaccino(jsonData.listaVaccinazioni, row.item)">
                        <b-icon icon="plus"></b-icon>
                    </b-button>
                    <b-button v-if="row.index !== jsonData.listaVaccinazioni.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(jsonData.listaVaccinazioni, row)">
                        <b-icon icon="trash"></b-icon>
                    </b-button>
                </template>
            </b-table>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Note Relative alla Vaccinazione:</label>
                    <b-form-textarea v-model="jsonData.noteVaccinazione" rows="2" no-resize></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Fattori predisponenti malattie batteriche invasive" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.fattorePredisponenteMalattieBattericheAspleniaAnatomicaFu)" :checked="getCheckedValue(jsonData.fattorePredisponenteMalattieBattericheAspleniaAnatomicaFu)" switch @input="onInputCheckbox($event, 'fattorePredisponenteMalattieBattericheAspleniaAnatomicaFu')">Asplenia Anatomica/Funzionale</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.fattorePredisponenteMalattieBattericheDiabeteMellito)" :checked="getCheckedValue(jsonData.fattorePredisponenteMalattieBattericheDiabeteMellito)" switch @input="onInputCheckbox($event, 'fattorePredisponenteMalattieBattericheDiabeteMellito')">Diabete Mellito</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.fattorePredisponenteMalattieBattericheImmunodeficienzaCong)" :checked="getCheckedValue(jsonData.fattorePredisponenteMalattieBattericheImmunodeficienzaCong)" switch @input="onInputCheckbox($event, 'fattorePredisponenteMalattieBattericheImmunodeficienzaCong')">Immunodeficienza Congenita</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.fattorePredisponenteMalattieBattericheEpatopatia)" :checked="getCheckedValue(jsonData.fattorePredisponenteMalattieBattericheEpatopatia)" switch @input="onInputCheckbox($event, 'fattorePredisponenteMalattieBattericheEpatopatia')">Epatopatia</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.fattorePredisponenteMalattieBattericheLeucemiaLinfomi)" :checked="getCheckedValue(jsonData.fattorePredisponenteMalattieBattericheLeucemiaLinfomi)" switch @input="onInputCheckbox($event, 'fattorePredisponenteMalattieBattericheLeucemiaLinfomi')">Leucemie/Linfomi</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.fattorePredisponenteMalattieBattericheCardiopatite)" :checked="getCheckedValue(jsonData.fattorePredisponenteMalattieBattericheCardiopatite)" switch @input="onInputCheckbox($event, 'fattorePredisponenteMalattieBattericheCardiopatite')">Cardiopatie</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.fattorePredisponenteMalattieBattericheAltreNeoplasie)" :checked="getCheckedValue(jsonData.fattorePredisponenteMalattieBattericheAltreNeoplasie)" switch @input="onInputCheckbox($event, 'fattorePredisponenteMalattieBattericheAltreNeoplasie')">Altre Neoplasie</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.fattorePredisponenteMalattieBattericheAsmaEnfisema)" :checked="getCheckedValue(jsonData.fattorePredisponenteMalattieBattericheAsmaEnfisema)" switch @input="onInputCheckbox($event, 'fattorePredisponenteMalattieBattericheAsmaEnfisema')">Asma Enfisema</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.fattorePredisponenteMalattieBattericheTerapiaImmunoSoppre)" :checked="getCheckedValue(jsonData.fattorePredisponenteMalattieBattericheTerapiaImmunoSoppre)" switch @input="onInputCheckbox($event, 'fattorePredisponenteMalattieBattericheTerapiaImmunoSoppre')">Terapie Immuni-Soppressive</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.fattorePredisponenteMalattieBattericheTossicodipendenza)" :checked="getCheckedValue(jsonData.fattorePredisponenteMalattieBattericheTossicodipendenza)" switch @input="onInputCheckbox($event, 'fattorePredisponenteMalattieBattericheTossicodipendenza')">Tossicodipendenza E.V.</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.fattorePredisponenteMalattieBattericheTrapiantoOrganoMido)" :checked="getCheckedValue(jsonData.fattorePredisponenteMalattieBattericheTrapiantoOrganoMido)" switch @input="onInputCheckbox($event, 'fattorePredisponenteMalattieBattericheTrapiantoOrganoMido')">Trapianto D'Organo/Midollo</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.fattorePredisponenteMalattieBattericheAlcolismo)" :checked="getCheckedValue(jsonData.fattorePredisponenteMalattieBattericheAlcolismo)" switch @input="onInputCheckbox($event, 'fattorePredisponenteMalattieBattericheAlcolismo')">Alcolismo</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.fattorePredisponenteMalattieBattericheImpiantoCocleare)" :checked="getCheckedValue(jsonData.fattorePredisponenteMalattieBattericheImpiantoCocleare)" switch @input="onInputCheckbox($event, 'fattorePredisponenteMalattieBattericheImpiantoCocleare')">Impianto Cocleare</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.fattorePredisponenteMalattieBattericheTabagismo)" :checked="getCheckedValue(jsonData.fattorePredisponenteMalattieBattericheTabagismo)" switch @input="onInputCheckbox($event, 'fattorePredisponenteMalattieBattericheTabagismo')">Tabagismo</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.fattorePredisponenteMalattieBattericheFistoleLiquorali)" :checked="getCheckedValue(jsonData.fattorePredisponenteMalattieBattericheFistoleLiquorali)" switch @input="onInputCheckbox($event, 'fattorePredisponenteMalattieBattericheFistoleLiquorali')">Fistole Liquorale</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.fattorePredisponenteMalattieBattericheDeficitFattoriCompl)" :checked="getCheckedValue(jsonData.fattorePredisponenteMalattieBattericheDeficitFattoriCompl)" switch @input="onInputCheckbox($event, 'fattorePredisponenteMalattieBattericheDeficitFattoriCompl')">Deficit Fattori Del Complemento</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.fattorePredisponenteMalattieBattericheImmunodeficienzaAcqu)" :checked="getCheckedValue(jsonData.fattorePredisponenteMalattieBattericheImmunodeficienzaAcqu)" switch @input="onInputCheckbox($event, 'fattorePredisponenteMalattieBattericheImmunodeficienzaAcqu')">Immunodeficienza Acquisita</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.fattorePredisponenteMalattieBattericheEmoglobinopatie)" :checked="getCheckedValue(jsonData.fattorePredisponenteMalattieBattericheEmoglobinopatie)" switch @input="onInputCheckbox($event, 'fattorePredisponenteMalattieBattericheEmoglobinopatie')">Emoglobinopatie</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.fattorePredisponenteMalattieBattericheInsufficienzaRenale)" :checked="getCheckedValue(jsonData.fattorePredisponenteMalattieBattericheInsufficienzaRenale)" switch @input="onInputCheckbox($event, 'fattorePredisponenteMalattieBattericheInsufficienzaRenale')">Insufficienza Renale Cronica/Dialisi</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.fattorePredisponenteMalattieBattericheAltreMalattiePolmon)" :checked="getCheckedValue(jsonData.fattorePredisponenteMalattieBattericheAltreMalattiePolmon)" switch @input="onInputCheckbox($event, 'fattorePredisponenteMalattieBattericheAltreMalattiePolmon')">Altre Malattie Polmonari Croniche</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox :class="getCheckedClass(jsonData.fattorePredisponenteMalattieBattericheAltreCondizioni)" :checked="getCheckedValue(jsonData.fattorePredisponenteMalattieBattericheAltreCondizioni)" switch @input="onInputCheckbox($event, 'fattorePredisponenteMalattieBattericheAltreCondizioni')">Altre Condizioni</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Altro:</label>
                    <b-form-textarea v-model="jsonData.fattorePredisponenteMalattieBattericheAltreCondizioniDett" rows="2" no-resize :disabled="chekCondition(jsonData.fattorePredisponenteMalattieBattericheAltreCondizioni, 'SI')"></b-form-textarea>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Altre Considerazioni</label>
                    <b-form-textarea v-model="jsonData.altreConsiderazioni" rows="6" no-resize></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Dati Notifica" header-tag="header" footer-tag="footer" title="">
            <indagine-epidemiologica-dati-notifica-component :jsonData="jsonData" :isEdit="true" @update="onUpdateJsonData"></indagine-epidemiologica-dati-notifica-component>
        </b-card>
    </div>
</template>
<script>
import axios from "axios";
import Vue from "vue";
import IndagineEpidemiologicaDatiNotificaComponent from "./IndagineEpidemiologicaDatiNotificaComponent.vue";
import UtilityMixin from "../../../utility/UtilityMixin";
import DatePicker from "vue2-datepicker";
export default {
    mixins: [UtilityMixin],
    components: { IndagineEpidemiologicaDatiNotificaComponent, DatePicker }, //DatePicker
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    data() {
        return {
            pathResource: "/malattieinfettiveieencefalitemeningitesmeningococcica",
            pathResourceMalattiaInfettivaSegnalazione: "/malattieinfettivesegnalazioni",
            pathResourceTipiCasi: "/malattieinfettiveietipicasi",
            linkback: null,
            showModalLoading: false,
            isContatti: false,
            id: "-1",
            currentPage: 1,
            perPage: 100,
            dataRachicentesiDisabled: true,
            dataPcrDisabled: true,
            dataNowTestDisabled: true,
            disabledValue: {},
            filtro: { page: 1, forPage: 100 },
            jsonData: {
                quadroClinicoSepsi: "",
                quadroClinicoMeningite: "",
                quadroClinicoPolmoniteBatterica: "",
                quadroClinicoCellulite: "",
                quadroClinicoEpiglottite: "",
                quadroClinicoPeritonite: "",
                quadroClinicoArtriteSetticaOsteomielite: "",
                quadroClinicoAltro: "",
                quadroClinicoAltroDescrizione: "",
                quadroClinicoPericardite: "",
                ricoverato: "",
                dataRicovero: null,
                agenteEziologicoNeisseriaMeningitidis: "",
                agenteEziologicoStreptococcusPneumoniae: "",
                agenteEziologicoHaemophilusInfluenzae: "",
                altroAgenteEziologicoMicrobatterioTubercolare: "",
                altroAgenteEziologicoStrptococcoGruppoB: "",
                altroAgenteEziologicoListeria: "",
                altroAgenteEziologicoAltro: "",
                altroAgenteEziologicoAltroDescrizione: "",
                altroAgenteEziologicoNonIdentificato: "",
                diagnosiLaboratorioPersonaContatto: "",
                diagnosiLaboratorioPersonaContattoTelefono: "",
                diagnosiLaboratorioPersonaContattoEmail: "",
                diagnosiLaboratorioOspedaleLaboratorio: "",
                diagnosiLaboratorioDataPrelievo: null,
                eseguitaTipizzazione: "",
                eseguitaTipizzazioneSieroGruppoSierotipo: "",
                eseguitaTipizzazioneLaboratorio: "",
                eseguitaTipizzazioneLaboratorioAltro: "",
                esitoMalattiaUltimoAggiornamento: "",
                esitoMalattiaUltimoAggiornamentoStatoPaziente: "",
                sequeleDataSrgnalazioneUltimoAggiornamento: "",
                sequeleDataSrgnalazionePerditaUdito: "",
                sequeleDataSrgnalazionePerditaVista: "",
                sequeleDataSrgnalazioneDanniNeurologiciMotori: "",
                sequeleDataSrgnalazioneAmputazione: "",
                sequeleDataSrgnalazioneNecrosiCicatriciCutanee: "",
                sequeleDataSrgnalazioneAltro: "",
                sequeleDataSrgnalazioneAltroDettaglio: "",
                contattoAltroCasoStessaMalattia: "",
                probabileContagioFuoriAreaDomicilioAbituale: "",
                probabileContagioFuoriAreaDomicilioAbitualeDove: "",
                parteFocolaioEpidemiologicoConosciuto: "",
                parteFocolaioEpidemiologicoConosciutoQuale: "",
                comunitaNodo: "",
                comunitaScuolaMaterna: "",
                comunitaScuola: "",
                comunitaOspedale: "",
                comunitaCaserma: "",
                comunitaAltraComunita: "",
                comunitaAltraComunitaDescrizione: "",
                vaccinatoAgenteCausa: "",
                noteVaccinazione: "",
                fattorePredisponenteMalattieBattericheAspleniaAnatomicaFu: "",
                fattorePredisponenteMalattieBattericheImmunodeficienzaCong: "",
                fattorePredisponenteMalattieBattericheLeucemiaLinfomi: "",
                fattorePredisponenteMalattieBattericheAltreNeoplasie: "",
                fattorePredisponenteMalattieBattericheTerapiaImmunoSoppre: "",
                fattorePredisponenteMalattieBattericheTrapiantoOrganoMido: "",
                fattorePredisponenteMalattieBattericheImpiantoCocleare: "",
                fattorePredisponenteMalattieBattericheFistoleLiquorali: "",
                fattorePredisponenteMalattieBattericheImmunodeficienzaAcqu: "",
                fattorePredisponenteMalattieBattericheInsufficienzaRenale: "",
                fattorePredisponenteMalattieBattericheDiabeteMellito: "",
                fattorePredisponenteMalattieBattericheEpatopatia: "",
                fattorePredisponenteMalattieBattericheCardiopatite: "",
                fattorePredisponenteMalattieBattericheAsmaEnfisema: "",
                fattorePredisponenteMalattieBattericheTossicodipendenza: "",
                fattorePredisponenteMalattieBattericheAlcolismo: "",
                fattorePredisponenteMalattieBattericheTabagismo: "",
                fattorePredisponenteMalattieBattericheDeficitFattoriCompl: "",
                fattorePredisponenteMalattieBattericheEmoglobinopatie: "",
                fattorePredisponenteMalattieBattericheAltreMalattiePolmon: "",
                fattorePredisponenteMalattieBattericheAltreCondizioni: "",
                fattorePredisponenteMalattieBattericheAltreCondizioniDett: "",
                sanitarioNotificante: "",
                sanitarioNotificanteContatto: "",
                esitoMalattiaQuattordiciGiorniStatoPaziente: "",
                esitoMalattiaUnMeseStatoPaziente: "",
                esitoMalattiaSeiMesiStatoPaziente: "",
                altreConsiderazioni: "",
                dataNotifica: null,
                listaVaccinazioni: [],
            },
            fieldsVaccinazioni: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "N. Dose",
                    key: "numeroDose",
                    thStyle: "width: 10rem",
                    sortable: false,
                },
                {
                    label: "Data Somministrazione",
                    key: "dataSomministrazione",
                    thStyle: "width: 18rem",
                    sortable: false,
                },
                {
                    label: "Nome Commerciale",
                    key: "nomeCommerciale",
                    // thStyle: "width: 8rem",
                    sortable: false,
                },
                {
                    label: "",
                    key: "actions",
                    sortable: false,
                    thStyle: "width: 4rem",
                },
            ],
            tipoCasiOptions: [],
            tipoMalattiaOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "ENCEFALITE", text: "ENCEFALITE" },
                { value: "MENINGITE", text: "MENINGITE" },
                { value: "S. MENINGOCOCCIA", text: "S. MENINGOCOCCIA" },
            ],
            siNoOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "SI", text: "SI" },
                { value: "NO", text: "NO" },
            ],
            siConfermatoSiNonConfermatoNOOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "SI (CONFERMATO)", text: "SI (CONFERMATO)" },
                { value: "SI (NON CONFERMATO)", text: "SI (NON CONFERMATO)" },
                { value: "NO", text: "NO" },
            ],
            liquorOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "LIMPIDO", text: "LIMPIDO" },
                { value: "TORBIDO", text: "TORBIDO" },
            ],
            risultatiOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "POSITIVO", text: "POSITIVO" },
                { value: "NEGATIVO", text: "NEGATIVO" },
            ],
            agenteEziologicoOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "STREPTOCOCCO", text: "STREPTOCOCCO" },
                { value: "PNEUMOCOCCO", text: "PNEUMOCOCCO" },
                { value: "MENINGOCOCCO", text: "MENINGOCOCCO" },
                { value: "ALTRO", text: "ALTRO" },
            ],
            laboratorioOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "LABORATORIO RIFERIMENTO REGIONALE", text: "LABORATORIO RIFERIMENTO REGIONALE" },
                { value: "ALTRO", text: "ALTRO" },
            ],
            ultimoAggiornamentoOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "AL MOMENTO DELLA SEGNALAZIONE", text: "AL MOMENTO DELLA SEGNALAZIONE" },
                { value: "A 14 GIORNI", text: "A 14 GIORNI" },
                { value: "A 1 MESE", text: "A 1 MESE" },
                { value: "A 6 MESE", text: "A 6 MESE" },
            ],
            ultimoAggiornamentoStatoPazienteOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "GUARITO", text: "GUARITO" },
                { value: "DECEDUTO", text: "DECEDUTO" },
                { value: "ANCORA IN TRATTAMENTO", text: "ANCORA IN TRATTAMENTO" },
            ],
            vaccinoAgenteCausaOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "SI REGOLARMENTE O PARZIALMENTE", text: "SI REGOLARMENTE O PARZIALMENTE" },
                { value: "INFORMAZIONE NON DISPONIBILE", text: "INFORMAZIONE NON DISPONIBILE" },
                { value: "NO", text: "NO" },
            ],
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient() {
            return UtilityMixin.methods.getLinkPatient(this.utente, this.idPaziente);
        },
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.jsonData.idAnagrafica = me.idPaziente;
        me.jsonData.id = me.id;
        me.loadMalattiaInfettivaSegnalazione();
        me.loadData();
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            me.showModalLoading = true;
            axios
                .get(link + me.id)
                .then((response) => {
                    me.jsonData = response.data.data;
                    if (me.jsonData.listaVaccinazioni.length > 0 || me.jsonData.vaccinatoAgenteCausa === "SI REGOLARMENTE O PARZIALMENTE") {
                        me.jsonData.listaVaccinazioni.push({ numeroDose: "", dataSomministrazione: null, nomeCommerciale: "" });
                    }
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.setDatiMedico();
                    me.showModalLoading = false;
                });
        },
        loadMalattiaInfettivaSegnalazione() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceMalattiaInfettivaSegnalazione + "/";
            axios
                .get(link + me.id)
                .then((response) => {
                    me.setDatiMalattia(response.data.data);
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        onAddVaccino(array, value) {
            if ((value.numeroDose !== "", value.dataSomministrazione !== null, value.nomeCommerciale !== "")) {
                array.push({ numeroDose: "", dataSomministrazione: null, nomeCommerciale: "" });
            } else {
                this.$bvModal
                    .msgBoxOk("Tutti i campi sono OBBLIGATORI", {
                        title: "ATTENZIONE",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    })
                    .then((value) => {
                        console.log(value);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        onDelete(array, row) {
            if (row.index != array.length) {
                this.$bvModal
                    .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
                        title: "Titolo",
                        buttonSize: "sm",
                        okVariant: "secondary",
                        cancelVariant: "danger",
                        okTitle: "SI",
                        cancelTitle: "NO",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true,
                    })
                    .then((value) => {
                        if (value) {
                            array.splice(row.index, 1);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        setDatiMedico() {
            let me = this;
            me.jsonData.sanitarioNotificante = Vue.prototype.user.firstname + " " + Vue.prototype.user.lastname;
        },
        setDatiMalattia(data) {
            let me = this;
            me.jsonData.id = data.id;
        },
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
        },
        getCheckedValue(value) {
            return value === "SI";
        },
        getCheckedClass(value) {
            return value === "SI" ? "sa-label-data text-success" : "sa-label-data";
        },
        onInputCheckbox(event, field) {
            // console.log(event);
            let me = this;
            me.jsonData[field] = event ? "SI" : "NO";
        },
        onCheckVaccinatoAgenteCausa() {
            let me = this;
            me.jsonData.listaVaccinazioni = [];
            if (me.jsonData.vaccinatoAgenteCausa === "SI REGOLARMENTE O PARZIALMENTE") {
                me.jsonData.listaVaccinazioni.push({ numeroDose: "", dataSomministrazione: null, nomeCommerciale: "" });
            }
        },
    },
};
</script>
