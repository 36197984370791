<template>
    <b-modal ref="mdlConfigurazioneParametroVitale" :title="title" centered no-close-on-backdrop no-close-on-esc hide-header-close size="lg">
        <!--  @hidden="onCloseModal" @ok="onClickSaveConfigurazioneParametriVitali" -->
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <span class="sa-form-label">Descrizione</span>
                <b-form-input v-model="jsonData.descrizione" required> </b-form-input>
            </b-col>

            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <span class="sa-form-label">Valore minimo</span>
                <b-form-input type="number" v-model="jsonData.valoreMinimo" required></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <span class="sa-form-label">Valore massimo</span>
                <b-form-input type="number" v-model="jsonData.valoreMassimo" required></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <span class="sa-form-label">Numero Valori</span>
                <b-form-input type="number" v-model="jsonData.numeroValori" required></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <span class="sa-form-label">Ausilio</span>
                <b-form-select v-model="jsonData.ausilio" :options="ausilioOptions" value-field="voce" text-field="voce" value="''" required></b-form-select>
            </b-col>
        </b-row>

        <template #modal-footer>
            <b-button variant="outline-danger" @click="$refs.mdlConfigurazioneParametroVitale.hide()">Annulla</b-button>
            <b-button variant="outline-success" @click="onClickSaveConfigurazioneParametriVitali()">Salva</b-button>
        </template>
    </b-modal>
</template>

<script>
import UtilityMixin from "../../../utility/UtilityMixin";

export default {
    mixins: [UtilityMixin],
    data() {
        return {
            pathResource: "/rsaconfigurazioneparametrivitali",
            pathResourceDizionario: "/rsadizionario",
            title: null,
            jsonData: {
                id: null,
                descrizione: null,
                valoreMinimo: null,
                valoreMassimo: null,
                numeroValori: null,
                ausilio: null,
            },
            ausilioOptions: [],
        };
    },
    mounted() {
        let me = this;
        me.loadDizionarioData();
        // console.log("Options nel mounted: ", me.ausilioOptions);
    },
    methods: {
        show(jsonData) {
            let me = this;
            me.jsonData = jsonData;
            me.title = !jsonData.id ? "Nuovo Parametro Vitale" : "Modifica Parametro Vitale";
            me.$refs.mdlConfigurazioneParametroVitale.show();
        },
        loadDizionarioData() {
            let me = this;
            UtilityMixin.methods.loadDataResources(me.pathResourceDizionario, null, me.loadDizionarioDataSuccess, me.loadDizionarioDataError);
            // console.log("Debug", me.jsonData);
        },
        loadDizionarioDataSuccess(response) {
            let me = this;
            if (response.data?.data?.list) {
                me.ausilioOptions = response.data.data.list.filter((item) => item.tipo === "AUSILIO_PARAM_VITALI");
            }
        },
        loadDizionarioDataError(error) {
            console.log("Errore nel caricamento degli ausili", error);
        },

        // if (response.data && response.data.data && response.data.data.list) {
        //     me.dashboardOptions = response.data.data.list.map((dashboard) => ({
        //         value: dashboard.id,
        //         text: dashboard.title || dashboard.id,
        //     }));
        // } else {
        //     me.dashboardOptions = [];
        //     console.warn("lista dashboard vuota");
        // }

        onCloseModal() {
            let me = this;
            me.jsonData = {};
        },
        onClickSaveConfigurazioneParametriVitali() {
            let me = this;
            UtilityMixin.methods.saveResource(me.pathResource, me.jsonData, me.saveResourceSuccess, me.saveResourceError);
        },
        saveResourceSuccess(response) {
            let me = this;
            me.$emit("onSaveParametroVitale", response);
            me.$refs.mdlConfigurazioneParametroVitale.hide();
            me.$bvToast.toast("Salvataggio avvvenuto con successo", {
                title: "Success",
                variant: "success",
                solid: true,
            });
        },
        saveResourceError(response) {
            let me = this;
            if (response.response.status === 400) {
                me.$bvModal.msgBoxConfirm("Errore 400: compilare tutti i campi ", response.response.data.message, {
                    title: "ATTENZIONE",
                    size: "sm",
                    okVariant: "outline-danger",
                    headerClass: "sm-msg-header-danger",
                    footerClass: "p2 border-top-0",
                    centered: true,
                });
            } else {
                me.$bvToast.toast("Errore durante il salvataggio", response.message, {
                    title: "Errore",
                    variant: "danger",
                    solid: true,
                });
            }
        },

        onDeleteParametroVitale(item) {
            let me = this;
            me.$bvModal
                .msgBoxConfirm("Sei sicuro di eliminare questo parametro vitale?", {
                    title: "Eliminazione Parametro Vitale",
                    buttonSize: "sm",
                    okTitle: "SI",
                    cancelTitle: "NO",
                    okVariant: "danger",
                    cancelVariant: "secondary",
                    hideHeaderClose: true,
                    footerClass: "p-2",
                    centered: true,
                })
                .then((value) => {
                    if (value) {
                        me.deleteParametroVitale(item.id);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        deleteParametroVitale(id) {
            let me = this;
            UtilityMixin.methods.deleteResource(me.pathResource + "/" + id, me.jsonData, me.onDeleteParametroVitaleSuccess, me.onDeleteParametroVitaleError);
        },
        onDeleteParametroVitaleSuccess(response) {
            let me = this;
            me.$emit("onDeleteParametroVitale", response);
            me.$bvToast.toast("Eliminazione avvenuta con successo", {
                title: "Success",
                variant: "success",
                solid: true,
            });
        },
        onDeleteParametroVitaleError(error) {
            let me = this;
            me.showModalLoading = false;
            console.log(error);
            me.$bvToast.toast("Errore durante l'eliminazione", {
                title: "Errore",
                variant: "danger",
                solid: true,
            });
        },
    },
};
</script>
