<template>
    <div>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card sa-card-border" header="Valutazione Escretoria" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Incontinenza Urinaria</label>
                            <b-form-select v-model="jsonDataValutazioneEscretoria.incontinenzaUrinaria" :options="incontinenzaUrinariaOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Incontinenza Fecale</label>
                            <b-form-select v-model="jsonDataValutazioneEscretoria.incontinenzaFecale" :options="incontinenzaFecaleOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Urinaria</label>
                            <b-form-select v-model="jsonDataValutazioneEscretoria.urinaria" :options="urinariaOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Intestinale</label>
                            <b-form-select v-model="jsonDataValutazioneEscretoria.intesticale" :options="intesticaleOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Pannoloni al Giorno</label>
                            <b-form-input v-model="jsonDataValutazioneEscretoria.pannoloniGiornalieri" :readonly="isReadOnly"></b-form-input>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Strisce Assorbenti al Giorno</label>
                            <b-form-input v-model="jsonDataValutazioneEscretoria.strisceAssorbentiGiornalieri" :readonly="isReadOnly"></b-form-input>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Traverse al Giorno</label>
                            <b-form-input v-model="jsonDataValutazioneEscretoria.traverseGiornaliere" :readonly="isReadOnly"></b-form-input>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Note</label>
                            <b-form-textarea v-model="jsonDataValutazioneEscretoria.note" :readonly="isReadOnly" max-rows="10" rows="7"> </b-form-textarea>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import UtilityMixin from "../../../utility/UtilityMixin";

export default {
    props: {
        isReadOnly: {
            type: Boolean,
            default: false,
        },
        idAccettazione: {
            type: String,
        },
    },
    mixins: [UtilityMixin],
    data() {
        return {
            pathResourceSchedaInfermieristicaValutazioneEscretoria: "/rsaschedainfermieristicavalutazioneescretoria",
            jsonDataValutazioneEscretoria: {
                id: null,
                idAccettazione: null,
                incontinenzaUrinaria: null,
                incontinenzaFecale: null,
                urinaria: null,
                intesticale: null,
                pannoloniGiornalieri: null,
                strisceAssorbentiGiornalieri: null,
                traverseGiornaliere: null,
                note: null,
            },

            incontinenzaUrinariaOptions: [
                { text: "SI", value: "SI" },
                { text: "NO", value: "NO" },
                { text: "DA VALUTARE", value: "DA VALUTARE" },
            ],
            incontinenzaFecaleOptions: [
                { text: "SI", value: "SI" },
                { text: "NO", value: "NO" },
                { text: "DA VALUTARE", value: "DA VALUTARE" },
            ],
            urinariaOptions: [
                { text: "FISIOLOGICA", value: "FISIOLOGICA" },
                { text: "INCONTINENZA", value: "INCONTINENZA" },
                { text: "USO DI CATETERE", value: "USO DI CATETERE" },
            ],
            intesticaleOptions: [
                { text: "ALVO NORMALE", value: "ALVO NORMALE" },
                { text: "DIARREA", value: "DIARREA" },
                { text: "ALVO ALTERNO", value: "ALVO ALTERNO" },
                { text: "INCONTINENZA", value: "INCONTINENZA" },
                { text: "ANO PTN", value: "ANO PTN" },
            ],
        };
    },
    methods: {
        loadValutazioneEscretoriaData() {
            let me = this;
            // console.log("ID accettazione nel loadValutazioneEscretoriaData: " + me.idAccettazione);
            UtilityMixin.methods.loadDataResources(me.pathResourceSchedaInfermieristicaValutazioneEscretoria + "/accettazione/" + me.idAccettazione, {}, me.resultResponseValutazioneEscretoriaSuccess, me.resultResponseValutazioneEscretoriaError);
        },
        resultResponseValutazioneEscretoriaSuccess(response) {
            let me = this;
            me.jsonDataValutazioneEscretoria = response.data.data;
            // console.log("ID del record VALUTAZIONE ESCRETORIA:", me.jsonDataValutazioneEscretoria.id);
        },
        resultResponseValutazioneEscretoriaError(error) {
            let me = this;
            if (error.response && error.response.status === 404) {
                //console.log("NESSUN record trovato del VALUTAZIONE ESCRETORIA per questo id accettazione");
                me.resetForm();
            } else {
                console.error("Errore nel caricamento dati della sezione VALUTAZIONE ESCRETORIA:", error);
            }
        },
        resetForm() {
            let me = this;
            me.jsonDataValutazioneEscretoria = {
                id: null,
                idAccettazione: me.idAccettazione,
                incontinenzaUrinaria: null,
                incontinenzaFecale: null,
                urinaria: null,
                intesticale: null,
                pannoloniGiornalieri: null,
                strisceAssorbentiGiornalieri: null,
                traverseGiornaliere: null,
                note: null,
            };
        },

        onSalvaValutazioneEscretoria() {
            let me = this;
            me.jsonDataValutazioneEscretoria.idAccettazione = me.idAccettazione;
            console.log("Salvataggio VALUTAZIONE ESCRETORIA con dati:", JSON.stringify(me.jsonDataValutazioneEscretoria));
            if (!me.jsonDataValutazioneEscretoria.idAccettazione) {
                // console.log("idAccettazione: ", me.jsonDataValutazioneEscretoria.idAccettazione);
                me.$bvToast.toast("Errore: ID Accettazione mancante", {
                    title: "Errore",
                    variant: "danger",
                    solid: true,
                });
                return;
            }

            UtilityMixin.methods.saveResource(me.pathResourceSchedaInfermieristicaValutazioneEscretoria, me.jsonDataValutazioneEscretoria, me.saveValutazioneEscretoriaSuccessResponse, me.saveValutazioneEscretoriaErrorResponse);
        },
        saveValutazioneEscretoriaSuccessResponse(response) {
            console.log("VALUTAZIONE ESCRETORIA salvato con successo:", response);
        },
        saveValutazioneEscretoriaErrorResponse(error) {
            console.error("Errore nel salvataggio VALUTAZIONE ESCRETORIA:", error);
        },
    },
};
</script>
