<template>
    <li class="sa-sidebar-li-link">
        <router-link :to="to" :class="setActivate(to)" exact>
            <span class="sa-sidebar-li-icon"><sa-icon :icon="icon" class="nav-icon" /></span>

            <p class="sa-sidebar-li-link-p">
                <span class="sa-sidebar-li-title">{{ title }}</span>
                <span v-if="badge" :class="['right', 'badge', `badge-${badgeVariant}`]">{{ badge }}</span>
            </p>
        </router-link>
    </li>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true,
        },
        to: {
            type: String,
            required: true,
        },
        icon: {
            type: [String, Array],
            default: "circle",
        },
        badge: {
            type: String,
            default: "",
        },
        badgeVariant: {
            type: String,
            default: "danger",
        },
    },
    methods: {
        setActivate(el) {
            let arrPath = this.$route.fullPath.match(/^\/.[^?/]*/gm);
            let arrSubPath = this.$route.fullPath.match(/^\/.[^?]*/gm);
            if (arrPath) {
                let path = arrSubPath[0];
                // console.log(arrSubPath[0]);
                if (path.includes(el)) {
                    return "sa-sidebar-li-link sa-sidebar-li-link-active";
                } else {
                    if (arrSubPath) {
                        let subPath = arrSubPath[0];
                        let subel = el.match(/^\/.[^?]*/gm);
                        if (subel) {
                            if (subPath === subel[0]) {
                                return "sa-sidebar-li-link sa-sidebar-li-link-active";
                            } else {
                                return "sa-sidebar-li-link";
                            }
                        } else {
                            return "sa-sidebar-li-link";
                        }
                    } else {
                        return "sa-sidebar-li-link";
                    }
                }
            }
        },
    },
};
</script>
