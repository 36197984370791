<template>
	<div class="sa-container">
		<sa-sidebar v-bind:externalmenu="menu" v-bind:nominativoUtente="nominativoUtente" v-bind:ruolo="ruolo" :hidden="hiddenMenuLeft"></sa-sidebar>
		<div :class="mainClass">
			<sa-nav-bar :pageTitle="pageTitle" :userName="nominativoUtente" :idUtente="user.id" :hidden="hiddenNavBar" />
			<div class="sa-main-container">
				<transition name="scale" mode="out-in">
					<router-view :utente="user" />
				</transition>
				<!-- <router-view v-slot="{ Component }" :utente="user">
					<transition name="scale" mode="out-in">
						<component :is="Component" />
					</transition>
				</router-view> -->
			</div>
		</div>
		<div id="sa-sidebar-overlay" v-sa-aside-toggle></div>
		<sa-modal-loading ref="sa-modal-loading" />
	</div>
</template>

<script>
import Vue from "vue";
import { SaSidebar, SaNavBar } from "../../../template/layout";
import axios from "axios";
import { SaModalLoading } from "../../utility";
export default {
	name: "Main",
	components: { SaSidebar, SaNavBar, SaModalLoading },
	data() {
		return {
			hiddenMenuLeft: false,
			hiddenNavBar: false,
			mainClass: "sa-main",
			avatar: "",
			pageTitle: "",
			nominativoUtente: "",
			ruolo: "Super Administrator",
			user: { idUtente: null },
			menu: [],
		};
	},
	created() {
		let viewType = this.$route.query.view;
		if (viewType === "module") {
			this.hiddenMenuLeft = true;
			this.hiddenNavBar = true;
			this.mainClass = "sa-main sa-main-module";
		}
	},
	async mounted() {
		let me = this;
		await this.loadUserData();
		// this.updateSidebar();
		this.interval = setInterval(() => me.verificaSessione(), 180000);
	},
	methods: {
		verificaSessione() {
			let link = process.env.VUE_APP_PATH_API + "/sessioncontrol";
			if (this.$router.currentRoute.path !== "/installation") {
				axios.get(link)
					.then(() => {})
					.catch(() => {
						clearInterval(this.interval);
						this.$router.push("/login");
					});
			}
		},
		async loadUserData() {
			let me = this;
			let link = process.env.VUE_APP_PATH_API + "/users/profile";
			me.$refs["sa-modal-loading"].show();
			await axios
				.get(link)
				.then((response) => {
					let utente = response.data.data;
					me.user = utente;
					Vue.prototype.user = utente;
					me.nominativoUtente = utente.lastname + " " + utente.firstname;
					me.ruolo = utente.profilo;
					me.menu = response.data.data.gruppo.menuGruppo;
					if (utente.gruppo.menuGruppo.length === 1) {
						if (utente.gruppo.menuGruppo[0].routeId === "homepaziente") {
							me.$router.replace("paziente/homepaziente").catch((err) => {
								err;
							});
						} else {
							me.$router.replace(utente.gruppo.menuGruppo[0].viewId).catch((err) => {
								err;
							});
						}
					} else if (utente.gruppo.menuGruppo.length > 0) {
						let present = false;
						utente.gruppo.menuGruppo.forEach((element, index) => {
							if (this.$router.currentRoute.path.includes(element.viewId)) {
								present = true;
							} else {
								if (utente.gruppo.menuGruppo[index].children !== null) {
									utente.gruppo.menuGruppo[index].children.forEach((elementChild) => {
										if (this.$router.currentRoute.path.includes(elementChild.viewId)) {
											present = true;
										}
									});
								}
							}
						});
						console.log(present);

						if (!present) {
							me.$router.replace(utente.gruppo.menuGruppo[0].viewId).catch((err) => {
								err;
							});
						}
						//  else {
						//     me.$router.replace(utente.gruppo.menuGruppo[0].viewId).catch((err) => {
						//         err;
						//     });
						// }
					}
					me.$refs["sa-modal-loading"].close();
				})
				.catch(() => {
					me.$refs["sa-modal-loading"].close();
					//console.log(e);
					//   this.errors.push(e);
				});
		},
		getIcon(item) {
			let returnVale = "bi bi-" + item.iconCls;
			return returnVale;
		},
		onMenuDownClick(event) {
			event.currentTarget.classList.toggle("sa-show-sub-menu");
		},
	},
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
	transition: opacity 1.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
	opacity: 0;
}

.scale-leave-active {
	transition: all 0.2s ease-out;
}
.scale-leave-to {
	opacity: 0;
	transform: scale(0.9);
}

.sa-page.scale-enter-to,
.sa-free-page.scale-enter-to {
	opacity: 1 !important;
	transform: scale(1);
}

.scale-enter-to {
	transition: all 0.8s ease-in;
}
.scale-enter-active {
	opacity: 0;
	transform: scale(0.1);
}

/*sa-page scale-enter-active scale-enter-to*/
</style>
