<template>
    <sa-page-layout :data="gruppo" :pathResource="pathResource" :btnBackVisible="true" :btnSaveVisible="true" :titolo="gruppo.nome" :linkback="linkback" :linkedit="linkedit" :showModalLoading="showModalLoading" @beforeSave="onBeforeSave">
        <template slot="table-filter">
            <b-row>
                <b-col cols="12" sm="6">
                    <b-form-input v-model="gruppo.nome" placeholder="Nome"></b-form-input>
                </b-col>
                <b-col cols="12" sm="6">
                    <b-form-input v-model="gruppo.descrizione" placeholder="Descrizione"></b-form-input>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <b-tabs content-class="mt-3" class="sa-tab" style="height: 100%" fill>
                <b-tab title="Menu" active>
                    <div class="sa-config-menu-tab">
                        <ul class="sa-config-menu">
                            <li :class="getClassMainManu(voceMenu)" v-for="voceMenu in gruppoVociMenu" :key="voceMenu.routeId">
                                <span class="sa-config-menu-li-span">
                                    <span :class="voceMenu.children.length !== 0 ? 'sa-config-menu-li-icon sa-config-menu-pointer' : 'sa-config-menu-li-icon'" @click="onClickIconOpen"><i :class="'fas fa-' + voceMenu.iconCls"></i></span>
                                    <p>
                                        <span :class="voceMenu.children.length !== 0 ? 'sa-config-menu-li-label sa-config-menu-pointer' : 'sa-config-menu-li-label'" @click="onClickLabelOpen">{{ voceMenu.text }} ({{ voceMenu.routeId }})</span>
                                    </p>
                                    <span>
                                        <b-form-checkbox v-model="voceMenu.selezionato" name="check-button" switch size="lg" @change="onSelectMaiChange(voceMenu)"></b-form-checkbox>
                                    </span>
                                    <span class="sa-config-menu-li-arrow"><i class="bi bi-chevron-left arrow"></i></span>
                                </span>
                                <ul class="sa-config-menu-submenu">
                                    <li v-for="subitem in voceMenu.children" :key="subitem.routeId" class="sa-config-menu-submenu-li">
                                        <span>
                                            <span class="sa-config-menu-submenu-li-icon"><i :class="'fas fa-' + subitem.iconCls"></i></span>
                                            <p>
                                                <span class="sa-config-menu-submenu-li-label">{{ subitem.text }}</span>
                                            </p>
                                            <span>
                                                <b-form-checkbox v-model="subitem.selezionato" name="check-button" :disabled="!voceMenu.selezionato" switch size="lg"></b-form-checkbox>
                                            </span>
                                        </span>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </b-tab>
                <!-- <b-tab title="Menu">
                    <div class="b-table-sticky-header">
                        <b-table striped hover :items="gruppo.menu" :fields="fieldsMenu" head-variant="light" sort-icon-left>
                            <template v-slot:cell(iconCls)="{ item }">
                                <i :class="'fas fa-' + item.iconCls"></i>
                            </template>
                            <template v-slot:cell(text)="{ item }">
                                <div>
                                    <span class="sa-text-bold">{{ item.text }} ({{ item.routeId }})</span>
                                </div>
                                <div>
                                    <span>{{ item.view }}</span>
                                </div>
                            </template>
                            <template v-slot:cell(selected)="{ item }">
                                <b-form-checkbox v-model="item.selezionato" name="check-button" switch size="lg"></b-form-checkbox>
                            </template>
                        </b-table>
                    </div>
                </b-tab> -->
                <b-tab title="Permessi">
                    <div class="b-table-sticky-header">
                        <b-table striped hover :filter="filter" :filterIncludedFields="filterOn" :items="gruppo.sezioni" :fields="fieldsSezioni" head-variant="light" sort-icon-left>
                            <template v-slot:cell(permessiSezione)="{ item }">
                                <div></div>
                                <!--<b-form-checkbox v-for="permesso in verificaPermessoSezione(item.permessiSezione, permessi)" :key="permesso.valore" switch size="lg" class="sa-normal-label">-->
                                <div v-for="(permesso, index) in item.permessi" :key="index">
                                    <b-form-checkbox v-model="permesso.attivo" size="md" switch class="sa-normal-label">{{ permesso.descrizione }}</b-form-checkbox>
                                </div>
                            </template>
                        </b-table>
                    </div>
                </b-tab>
            </b-tabs>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
//import { SaModalLoading } from "../../../components/modal";
export default {
    components: { SaPageLayout },
    label: "Dettagllio Gruppo s",
    data() {
        return {
            linkback: "/gruppi",
            pathResource: "/gruppi",
            linkedit: "",
            showModalLoading: false,
            idGruppo: String,
            gruppo: { nome: "", descrizioneGruppo: "", menu: [], sezioni: [] },
            label: "Dettagllio Gruppo",
            filter: null,
            filterOn: [],
            vociMenu: [],
            gruppoVociMenu: [],
            fieldsMenu: [
                {
                    label: "",
                    key: "iconCls",
                    sortable: false,
                    class: "sa-col-icon",
                },
                {
                    label: "Menu",
                    key: "text",
                    sortable: false,
                },
                {
                    label: "Padre",
                    key: "idPadre",
                    sortable: false,
                },
                {
                    key: "selected",
                    label: "",
                    sortable: false,
                    class: "sa-width-2 sa-td-vertical-middle",
                },
            ],
            fieldsSezioni: [
                {
                    label: "Sezione",
                    key: "descrizione",
                    sortable: true,
                },
                {
                    label: "Permessi",
                    key: "permessiSezione",
                    sortable: false,
                    class: "sa-width-12",
                },
            ],
        };
    },
    mounted: function () {
        let me = this;
        me.id = this.$route.params.id;
        if (me.id !== "-1") {
            me.linkback = me.linkback + "/view/" + me.id;
        }
        me.loadData();
    },
    methods: {
        verificaPermessoSezione(permesso) {
            return permesso ? "checked" : "unchecked";
        },
        loadData() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + "/vocimenu";
            axios
                .get(link)
                .then((response) => {
                    me.menu = response.data.data;
                    link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                    axios.get(link + me.id).then((response) => {
                        me.gruppo = response.data.data;
                        me.vociMenu = me.gruppo.menu.map((obj) => ({ ...obj }));
                        me.raggruppaVociMenu(null);
                        me.showModalLoading = false;
                    });
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        onBack() {
            let me = this;
            if (me.gruppo.id) {
                me.$router.replace("/gruppi/view/" + me.gruppo.id).catch((err) => {
                    err;
                });
            } else {
                me.$router.replace("/gruppi").catch((err) => {
                    err;
                });
            }
        },
        raggruppaVociMenu(idPadre) {
            let me = this;
            me.vociMenu.forEach((element) => {
                if (element.idPadre === idPadre) {
                    element.children = [];
                    me.setMenuChildren(element);
                    me.gruppoVociMenu.push(element);
                }
            });
        },
        setMenuChildren(voceMenu) {
            let me = this;
            me.vociMenu.forEach((element) => {
                if (element.idPadre === voceMenu.routeId) {
                    element.children = [];
                    voceMenu.children.push(element);
                }
            });
        },
        onClickIconOpen(e) {
            let me = this;
            me.setMenuClass(e.target.parentElement.parentElement.parentElement.parentElement);
        },
        onClickLabelOpen(e) {
            let me = this;
            me.setMenuClass(e.target.parentElement.parentElement.parentElement);
        },
        setMenuClass(hrmlelement) {
            // let hrmlelement = e.target.parentElement.parentElement.parentElement;
            let elementNumber = hrmlelement.getElementsByClassName("sa-config-menu-submenu")[0].children.length;

            if (hrmlelement.classList.contains("sa-config-menu-li-open")) {
                hrmlelement.getElementsByClassName("sa-config-menu-submenu")[0].style.height = "0px";
                hrmlelement.classList.remove("sa-config-menu-li-open");
            } else {
                hrmlelement.getElementsByClassName("sa-config-menu-submenu")[0].style.height = elementNumber * 42 + "px";
                hrmlelement.classList.add("sa-config-menu-li-open");
            }
        },
        getClassMainManu(voceMenu) {
            let returnValue = "sa-config-menu-li";
            if (voceMenu.children.length === 0) {
                returnValue = "sa-config-menu-li sa-config-menu-li-no-children";
            } else {
                voceMenu.children.forEach((element) => {
                    if (element.selezionato) {
                        returnValue = "sa-config-menu-li sa-config-menu-li-open";
                    }
                });
            }

            return returnValue;
        },
        setNewMenu(newMenu, arrayVocuMenu) {
            let me = this;
            arrayVocuMenu.forEach((element) => {
                let newVoceMenu = Object.assign({}, element);
                delete newVoceMenu["children"];
                newMenu.push(newVoceMenu);
                if (element.children) {
                    me.setNewMenu(newMenu, element.children);
                }
            });
        },
        onBeforeSave(jsonData) {
            let me = this;
            let newMenu = [];
            me.setNewMenu(newMenu, me.gruppoVociMenu);
            jsonData.menu = newMenu;
        },
        onSelectMaiChange(voceMmenu) {
            if (!voceMmenu.selezionato) {
                voceMmenu.children.forEach((element) => {
                    element.selezionato = false;
                });
            }
        },
    },
};
</script>
<style>
.sa-config-menu-tab {
    overflow: auto;
    height: 100%;
    padding: 10px 10px 50px 10px;
}
.sa-config-menu {
    display: block;
    list-style-type: none;
    padding-left: 0px;
}
.sa-config-menu-pointer {
    cursor: pointer;
}
.sa-config-menu-li {
    transition: background-color 0.5s linear;
    color: var(--cl-sidebar-item);
    background-color: var(--bg-menu-item-color);
    margin-bottom: 2px;
    border-radius: 5px;
}
.sa-config-menu-li.sa-config-menu-li-no-children {
    background-color: var(--bg-sidebar);
    border-bottom: 1px solid var(--cl-sidebar-item);
}
.sa-config-menu-li.sa-config-menu-li-no-children:hover {
    background-color: var(--bg-sidebar-item) !important;
    color: var(--bg-sidebar-item-hover-text);
}
.sa-config-menu-li.sa-config-menu-li-no-children:hover .sa-config-menu-li-label {
    color: var(--bg-sidebar-item-hover-text);
}
.sa-config-menu-li-icon {
    position: relative;
    display: block;
    width: 32px;
    font-size: 1.8rem;
    color: var(--cl-sidebar-item);
    text-align: center;
}
.sa-config-menu-li-span {
    display: flex;
    height: 32px;
}

.sa-config-menu-li p {
    display: table;
    width: 100%;
    margin-bottom: 0px;
    padding-left: 5px;
}
.sa-config-menu-li p span {
    display: table-cell;
    vertical-align: middle;
}

.sa-config-menu-li-label {
    flex: 1 auto;
    color: var(--cl-sidebar-item);
}

.sa-config-menu-li-arrow {
    line-height: 32px;
}
.sa-config-menu-li-arrow i.arrow {
    display: block;
    transition: all 0.5s ease;
}
.sa-text-right {
    text-align: right;
}
.sa-config-menu-submenu {
    background-color: var(--bg-menu-item-color);
}
.sa-config-menu-li .sa-config-menu-submenu {
    display: block;
    max-height: 0px;
    height: 0px;
    transition: all 0.5s ease-out;
    overflow: hidden;
    list-style-type: none;
}
.sa-config-menu-li.sa-config-menu-li-open .sa-config-menu-submenu {
    display: block;
    max-height: 1000000px;
    height: auto;
    transition: height 0.5s ease-out;
}

.sa-config-menu-li.sa-config-menu-li-open .sa-config-menu-li-arrow i.arrow {
    transform: rotate(-90deg);
    display: block;
    position: relative;
}

.sa-config-menu-submenu-li {
    background-color: transparent;
    border-bottom: 1px solid var(--cl-sidebar-item);
    height: 40px;
}
.sa-config-menu-submenu-li:hover {
    background-color: var(--bg-sidebar-item) !important;
    color: var(--bg-sidebar-item-hover-text);
}
.sa-config-menu-submenu-li span {
    display: flex;
    height: 40px;
}
.sa-config-menu-submenu-li-icon {
    width: 32px;
    text-align: center;
    font-size: 1.6rem;
    padding: 10px 5px 5px 5px;
}
.sa-config-menu-submenu-li-label {
    background-color: transparent;
    font-weight: bold;
}
@media only screen and (max-width: 767px) {
    .sa-text-right {
        text-align: left;
    }
}
.card {
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
    margin-bottom: 1rem;
}

.sa-col-icon {
    width: 40px;
}
</style>
