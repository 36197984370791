<template>
    <sa-page-layout slot="table-body" :btnConfigurazioniVisible="btnConfigurazioniVisible" :btnRefreshVisible="true" :pathResource="pathResourceDizionario" @refresh="onRefresh" :showModalLoading="showModalLoading">
        <template slot="toolbar">
            <b-button class="float-sm-right" variant="success btn-toolbar sa-margin-right" size="sm" @click="onClickNuovaVoceDizionario">
                <b-icon icon="plus"></b-icon>
                Nuovo
            </b-button>
        </template>

        <template slot="table-filter">
            <b-form @submit.prevent="onSubmit">
                <b-row>
                    <!-- <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Tipo</label>
                        <b-form-select v-model="filtroDizionario.tipo" :options="tipoOptions"></b-form-select>
                    </b-col> -->

                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <!-- <b-form-group label="Filtra per tipo"> -->
                        <label class="sa-label-data">Tipo</label>
                        <b-form-select v-model="filtroTipo" :options="tipoOptions" @change="onChangeFiltroTipo">
                            <template #first>
                                <b-form-select-option :value="null" disabled> --Seleziona un tipo-- </b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-col>

                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Voce</label>
                        <b-form-input v-model="filtroDizionario.voce" type="search" placeholder="Inserire Voce"></b-form-input>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="sa-padding-right pt-3 pb-3 text-right">
                        <b-button type="submit" variant="info"> Cerca </b-button>
                        <b-button type="reset" v-on:click="onReset" variant="danger"> Reset filtri </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </template>

        <template slot="table-body">
            <rsa-configurazione-dizionario-list-component ref="cmpRsaDizionarioList" @afterLoadData="onAfterLoadData" @beforeLoadData="onBeforeLoadData" @onAfterSaveDizionario="onVoceDizionarioSave"> </rsa-configurazione-dizionario-list-component>
        </template>
    </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import RsaConfigurazioneDizionarioListComponent from "../components/RsaConfigurazioneDizionarioListComponent.vue";

export default {
    components: {
        RsaConfigurazioneDizionarioListComponent,
        SaPageLayout,
    },
    data() {
        return {
            filtroDizionario: {
                tipo: null,
                voce: null,
            },
            filtroTipo: null,
            btnConfigurazioniVisible: true,
            tipoOptions: [
                { text: "TUTTI", value: null },
                { text: "AUSILIO", value: "AUSILIO" },
                { text: "ASSISTENZA", value: "ASSISTENZA" },
                { text: "SIEROLOGIA", value: "SIEROLOGIA" },
                { text: "REAZIONI DI DIFESA", value: "REAZIONI_DIFESA" },
                { text: "ALLERGIA", value: "ALLERGIA" },
                { text: "ADL", value: "ADL" },
                { text: "RIABILITAZIONE TECNICA", value: "RIABILITAZIONE_TECNICA" },
                { text: "RIABILITAZIONE OBIETTIVO", value: "RIABILITAZIONE_OBIETTIVO" },
                { text: "CUTE", value: "CUTE" },
                { text: "UNGHIE", value: "UNGHIE" },
                { text: "EDEMI", value: "EDEMI" },
                { text: "AUSILIO P.V.", value: "AUSILIO_PARAM_VITALI" },
                { text: "MOTIVAZIONE PRESENZE/USCITE", value: "MOTIVAZIONE_PRESENZE_USCITE" },
            ],
            pathResourceDizionario: "",
            showModalLoading: false,
        };
    },
    mounted() {
        let me = this;
        let sessionStorage = window.sessionStorage;
        if (sessionStorage["filtroDizionario"]) {
            me.filtroDizionario = JSON.parse(sessionStorage["filtroDizionario"]);
        }
        me.loadData();
        me.onReset();
    },

    methods: {
        loadData() {
            let me = this;
            me.$refs.cmpRsaDizionarioList.loadData();
        },

        onSubmit() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtroDizionario"] = JSON.stringify(me.filtroDizionario);
            me.loadData();
        },

        onRefresh() {
            let me = this;
            me.loadData();
        },

        onAfterLoadData() {
            let me = this;
            me.showModalLoading = false;
        },

        onBeforeLoadData() {
            let me = this;
            me.showModalLoading = true;
        },

        onClickNuovaVoceDizionario() {
            let me = this;
            me.$refs.cmpRsaDizionarioList.onClickNuovaVoceDizionario();
        },

        onReset() {
            let me = this;
            me.filtroDizionario = {
                tipo: null,
                voce: null,
            };
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtroDizionario"] = JSON.stringify(me.filtroDizionario);
            me.loadData();
        },
        onVoceDizionarioSave() {
            let me = this;
            //me.$refs.cmpRsaDizionarioList.onVoceDizionarioSave();
            me.loadData();
        },
        onChangeFiltroTipo() {
            let me = this;
            me.filtroDizionario.tipo = me.filtroTipo;
            /*  console.log("FILTRO LISTPAGE: ", this.filtroDizionario.tipo); */

            let sessionStorage = window.sessionStorage;
            sessionStorage["filtroDizionario"] = JSON.stringify(me.filtroDizionario);

            me.currentPage = 1;
            me.loadData();
        },
    },
};
</script>
