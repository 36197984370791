<template>
  <sa-page-layout :btnNewVisible="btnNewVisible" :btnRefreshVisible="true" @refresh="onRefresh" :pathResource="pathResource" :linkedit="linkedit" :showModalLoading="showModalLoading">
    <template slot="table-filter">
      <b-form @submit.prevent="onSubmit">
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-label-data">Cognome</label>
            <b-form-input v-model="filtro.cognome" type="search" id="cognome" placeholder="Cognome"></b-form-input>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-label-data">Nome</label>
            <b-form-input v-model="filtro.nome" type="search" id="nome" placeholder="Nome"></b-form-input>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-label-data">Codice Fiscale</label>
            <b-form-input v-model="filtro.codiceFiscale" type="search" id="codiceFiscale" placeholder="Codice Fiscale"></b-form-input>
          </b-col>
          <!-- <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data">Codice Valutazione</label>
            <b-form-select v-model="filtro.codiceValutazione" :options="valutazioniOption" value-field="value" text-field="text" :value="null"></b-form-select>
          </b-col> -->
          <b-col xs="12" sm="12" md="12" lg="12" xl="12">
            <label class="sa-label-data">Indirizzo</label>
            <b-form-input v-model="filtro.assistitoIndirizzo" type="search" id="assistitoIndirizzo" placeholder="Indirizzo"></b-form-input>
          </b-col>
        </b-row>
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col cols="12" xs="12" sm="3" md="3" lg="4" xl="4">
            <label class="sa-label-data"></label>
            <b-form-checkbox v-model="filtro.patologieCorsoIntolleranzeAlimentari" switch>Intolleranze Alimentari, Farmaci/Allergie</b-form-checkbox>
          </b-col>
          <b-col cols="12" xs="12" sm="3" md="3" lg="4" xl="4">
            <label class="sa-label-data"></label>
            <b-form-checkbox v-model="filtro.patologieCorsoNefropatia" switch>Nefropatia</b-form-checkbox>
          </b-col>
          <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="4">
            <label class="sa-label-data"></label>
            <b-form-checkbox v-model="filtro.patologieCorsoFistole" switch>Fistole</b-form-checkbox>
          </b-col>
          <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="4">
            <label class="sa-label-data"></label>
            <b-form-checkbox v-model="filtro.patologieCorsoMetabolica" switch>Metabolica</b-form-checkbox>
          </b-col>
          <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="4">
            <label class="sa-label-data"></label>
            <b-form-checkbox v-model="filtro.patologieCorsoCardiopatia" switch>Cardiopatia</b-form-checkbox>
          </b-col>
          <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="4">
            <label class="sa-label-data"></label>
            <b-form-checkbox v-model="filtro.patologieCorsoRespiratoria" switch>Respiratoria</b-form-checkbox>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="sa-padding-right text-right">
            <b-button type="submit" variant="info">{{ this.$i18n.t("patients.lblFind") }}</b-button>
            <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("patients.lblResetFilter") }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </template>
    <template slot="table-body">
      <giubileo-scheda-list-component ref="GiubileoSchedaListComponent" @afterLoadData="onAfterLoadData" @beforeLoadData="onBeforeLoadData" @updateValutazioniOption="onUpdateValutazioniOption"></giubileo-scheda-list-component>
    </template>
  </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import GiubileoSchedaListComponent from "../components/GiubileoSchedaListComponent.vue";
import UtilityMixin from "../../utility/UtilityMixin";
import Vue from "vue";
export default {
  mixins: [UtilityMixin],
  components: { SaPageLayout, GiubileoSchedaListComponent },
  data() {
    return {
      btnNewVisible: false,
      pathResource: "",
      linkedit: "/giubileoscheda",
      showModalLoading: false,
      listKey: 0,
      filtro: {},
      valutazioniOption: [],
    };
  },
  mounted() {
    let me = this;
    me.impostaPermessi();
    me.loadData();
    me.$refs.GiubileoSchedaListComponent.loadCodiceValutazione();
  },
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;
      me.$refs.GiubileoSchedaListComponent.loadData();
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
    onAfterLoadData() {
      let me = this;
      me.showModalLoading = false;
    },
    onBeforeLoadData() {
      let me = this;
      me.showModalLoading = true;
    },
    onSubmit() {
      let me = this;
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onReset() {
      let me = this;
      me.filtro = {};
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onUpdateValutazioniOption(options) {
      let me = this;
      me.valutazioniOption = [];
      me.valutazioniOption = options;
    },
    impostaPermessi() {
      let me = this;
      me.btnNewVisible = UtilityMixin.methods.verificaPermessi(Vue.prototype.user, "giubileo", 2);
    },
  },
};
</script>

<style></style>
