<template>
    <div class="sa-list-component">
        <div class="sa-list-component-header">
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </div>
        <div class="sa-list-component-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" stacked="lg" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
                    <template v-slot:cell(actions)="row">
                        <b-button v-b-tooltip.hover size="sm" variant="outline-secondary" title="Modifica Utente" @click="onClickView(row.item, row.index, $event.target)" class="mr-1 no-text">
                            <b-icon icon="eye" variant="outline-secondary"></b-icon>
                        </b-button>
                        <b-button v-b-tooltip.hover size="sm" variant="outline-secondary" title="Modifica Utente" @click="onClickEdit(row.item, row.index, $event.target)" class="mr-1 no-text">
                            <b-icon icon="pencil" variant="outline-secondary"></b-icon>
                        </b-button>
                    </template>
                </b-table>
            </div>
        </div>
        <div class="sa-list-component-footer">
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    props: {
        linkedit: { type: String, require: true },
    },
    data() {
        return {
            rows: 0,
            perPage: 50,
            currentPage: 1,
            pathResource: "/sveipuntiincontro",
            filtro: {},
            items: [],
            listKey: 0,
            fields: [
                {
                    label: "Descrizione",
                    key: "descrizione",
                    sortable: true,
                },
                {
                    label: "Indirizzo",
                    key: "indirizzo",
                    sortable: false,
                },
                { class: "sa-table-column-action-double", key: "actions", label: "" },
            ],
        };
    },
    methods: {
        loadData() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            if (sessionStorage["filtro"]) {
                me.filtro = JSON.parse(sessionStorage["filtro"]);
            }

            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.filtro.page = me.currentPage;
            me.filtro.forPage = me.perPage;
            me.$emit("beforeLoadData");
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];
                    me.rows = response.data.data.recordsNumber;
                    me.items = response.data.data.list;
                    me.listKey++;
                    this.$emit("afterLoadData");
                })
                .catch(() => {
                    this.$emit("afterLoadData");
                });
        },
        onClickEdit(item) {
            let me = this;
            me.edit(item);
        },
        onClickView(item) {
            let me = this;
            me.view(item);
        },
        edit(item) {
            let me = this;
            me.$router.replace(me.linkedit + "/edit/" + item.id).catch((err) => {
                err;
            });
        },

        view(item) {
            let me = this;
            me.$router.replace(me.linkedit + "/view/" + item.id).catch((err) => {
                err;
            });
        },
    },
};
</script>

<style></style>
