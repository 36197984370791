<template>
    <sa-page-layout :pathResource="pathResource" :data="jsonData" :showModalLoading="showModalLoading" :btnBackVisible="true" :btnEditVisible="true" :linkback="linkback" :linkedit="linkedit">
        <template slot="table-header">
            <b-card style="max-width: 100%">
                <div style="max-height: 18rem; overflow: auto">
                    <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                        <b-col sm="6" md="6" lg="6">
                            <fieldset class="sa-fieldset-intestazione">
                                <label>Tipo Documento: </label>
                                <p>{{ jsonData.tipoDocumento }}</p>
                                <hr />
                                <label>Codice Numeratore: </label>
                                <p>{{ jsonData.codiceNumeratore }}</p>
                                <hr />
                            </fieldset>
                        </b-col>
                        <b-col sm="6" md="6" lg="6">
                            <fieldset class="sa-fieldset-intestazione">
                                <label>Descrizione Tipo Documento: </label>
                                <p>{{ jsonData.descrizioneTipoDocumento }}</p>
                                <hr />
                                <label>Codice Tipo Documento: </label>
                                <p>{{ jsonData.codiceTipoDocumento }}</p>
                                <hr />
                            </fieldset>
                        </b-col>
                    </b-row>
                </div>
            </b-card>
        </template>
        <template slot="table-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header stacked="xl" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                    <template #cell(index)="item">
                        {{ item.index + 1 }}
                    </template>
                    <template v-slot:cell(descrizioneCausale)="{ item }">
                        <router-link class="sa-edit-link" :to="'/causalidocumento/view/' + item.id">{{ item.descrizioneCausale }}</router-link>
                    </template>
                </b-table>
            </div>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
    components: { SaPageLayout },
    computed: {
        rows() {
            return this.items.length;
        },
    },
    data() {
        return {
            id: null,
            perPage: 10,
            currentPage: 1,
            showModalLoading: false,
            pathResource: "/tipidocumento",
            pathResourceCausaliDocumento: "/causalidocumento",
            linkedit: null,
            linkback: "/tipidocumento",
            jsonData: {},
            items: [],
            fields: [
                {
                    label: " ",
                    key: "index",
                    thStyle: "width: 3rem",
                    sortable: true,
                },
                {
                    label: "Cod. Tipo Doc.",
                    key: "codiceTipoDopcumento",
                    thStyle: "width: 8rem",
                    sortable: true,
                },
                {
                    label: "Cod. Causale",
                    key: "codiceCausale",
                    thStyle: "width: 7rem",
                    sortable: true,
                },
                {
                    label: "Desc. Causale",
                    key: "descrizioneCausale",
                    sortable: true,
                },
                {
                    label: "Op. Magazzino",
                    key: "operazioneMagazzino",
                    thStyle: "width: 8rem",
                    sortable: true,
                },
                {
                    label: "Op. Contabile",
                    key: "operazioneContabile",
                    thStyle: "width: 8rem",
                    sortable: true,
                },
                {
                    label: "Sog. Collegati",
                    key: "soggettiCollegati",
                    thStyle: "width: 8rem",
                    sortable: true,
                },
                {
                    label: "Doc. Collegati",
                    key: "documentiCollegati",
                    thStyle: "width: 8rem",
                    sortable: true,
                },
            ],
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.linkedit = "/tipidocumento/edit/" + me.id;
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            me.showModalLoading = true;
            axios
                .get(link + me.id)
                .then((response) => {
                    me.jsonData = response.data.data;
                    me.showModalLoading = false;
                    me.loadCausaliDocumento();
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        loadCausaliDocumento() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceCausaliDocumento + "?idTipoDocumento=" + me.jsonData.id;
            me.showModalLoading = true;
            axios
                .get(link)
                .then((response) => {
                    me.items = response.data.data;
                    console.log(me.items);
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
    },
};
</script>
