<template>
  <sa-page-layout @refresh="onRefresh" :btnBackVisible="true" :pathResource="pathResource" :showModalLoading="showModalLoading" :data="jsonData" :linkback="linkback" class="sa-no-space" toggleableDimension="sm">
    <!-- <template slot="toolbar">
      <b-button v-if="btnPresaInCarico" size="sm" variant="btn btn-outline-success waves-effect float-sm-left" @click="onCambiaStato(id, 'PRESA IN CARICO')">Presa in carico</b-button>
      <b-button v-if="btnRespinta" size="sm" variant="btn btn-outline-danger waves-effect float-sm-left" @click="onCambiaStato(id, 'RESPINTA')">Respingi</b-button>
      <b-button v-if="btnInviataCot" size="sm" variant="btn btn-outline-success waves-effect float-sm-left" @click="onCambiaStato(id, 'INVIATA A C.O.T.')">Invia a C.O.T.</b-button>
      <b-button v-if="btnInviataUvm" size="sm" variant="btn btn-outline-success waves-effect float-sm-left" @click="onCambiaStato(id, 'INVIATA A U.V.M.')">Invia a U.V.M.</b-button>
    </template> -->
    <template slot="table-body">
      <cot-proposta-accesso-valutazione-uvm-component :idPropostaAccesso="id" @update="onUpdateJsonData"></cot-proposta-accesso-valutazione-uvm-component>
    </template>
  </sa-page-layout>
</template>

<script>
// import Vue from "vue";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import CotPropostaAccessoValutazioneUvmComponent from "../components/CotPropostaAccessoValutazioneUvmComponent.vue";
import UtilityMixin from "../../utility/UtilityMixin";
import axios from "axios";
export default {
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  mixins: [UtilityMixin],
  components: { SaPageLayout, CotPropostaAccessoValutazioneUvmComponent },
  data() {
    return {
      btnPresaInCarico: true,
      btnRespinta: true,
      btnInviataCot: true,
      btnInviataUvm: true,
      btnSaveNotBackVisible: true,
      pathResource: "/cot",
      pathCambiaStato: "/cot/aggiornaStato",
      linkNotBack: "",
      btnSaveVisible: false,
      backAfterSave: false,
      showModalLoading: false,
      jsonData: {},
      linkback: "",
      id: "-1",
      // linkPrintData: "",
    };
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.jsonData.identificativo = this.$route.query.codiceFiscale;
    me.jsonData.provenienzaCodiceStruttura = this.$route.query.codiceStruttura;
    me.jsonData.provenienzaDescrizioneStruttura = this.$route.query.descrizioneStruttura;
    me.jsonData.provenienzaCodiceReparto = this.$route.query.codiceReparto;
    me.jsonData.provenienzaDescrizioneReparto = this.$route.query.descrizioneReparto;
    me.jsonData.provenienzaEventoClinico = this.$route.query.eventoClinico;
    me.jsonData.provenienzaNominativoMedico = this.$route.query.nominativoMedico;
    me.headerCard = "Richiesta a C.O.T. Paziente: " + me.jsonData.identificativo;
    if (this.$route.query.codiceFiscale) {
      me.linkback = "/cot/richieste/edit/-1" + "?view=module&codiceFiscale=" + me.jsonData.identificativo + "&codiceStruttura=" + me.jsonData.provenienzaCodiceStruttura + "&descrizioneStruttura=" + me.jsonData.provenienzaDescrizioneStruttura + "&codiceReparto=" + me.jsonData.provenienzaCodiceReparto + "&descrizioneReparto=" + me.jsonData.provenienzaDescrizioneReparto + "&eventoClinico=" + me.jsonData.provenienzaEventoClinico + "&nominativoMedico=" + me.jsonData.provenienzaNominativoMedico;
    } else if (me.id) {
      me.linkback = "/cot/richieste/edit/" + me.id;
    } else {
      me.linkback = "/cot/richieste/edit/-1";
    }

    // me.loadData();
  },
  methods: {
    setPermessi() {
      let me = this;
      // console.log(me.jsonData);
      // if (me.id === -1) {
      //     me.btnSaveNotBackVisible = UtilityMixin.methods.verificaPermessi(me.utente, "cotproposteaccesso", 2);
      // } else {
      //     me.btnSaveNotBackVisible = UtilityMixin.methods.verificaPermessi(me.utente, "cotproposteaccesso", 4);
      // }
      me.btnSaveNotBackVisible = true;
    },
    onAfterLoadData() {},
    onRefresh() {},
    onAfterSave(jsonData) {
      let me = this;
      setTimeout(() => {
        me.$router.replace("/cot/richieste/edit/" + jsonData.id).catch((err) => {
          console.log(err);
        });
      }, 1000);
    },
    onUpdateJsonData(data) {
      let me = this;
      me.jsonData = data;
      // console.log(me.jsonData);
    },
    linkPrintData() {
      let me = this;
      let returnValue = "/cotprinter/" + me.id;
      return returnValue;
    },

    onCambiaStato(riga, stato) {
      let json = { idCot: riga, stato: stato };
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathCambiaStato;
      axios
        .post(link, json)
        .then((response) => {
          this.$bvToast.toast("Cambio Stato: " + response.data.data.stato, {
            title: this.titleModal,
            variant: "success",
            autoHideDelay: 1000,
          });
          me.jsonData.statoAttuale = response.data.data.stato;
          me.jsonData.statoAttualeDataOra = response.data.data.statoDataOra;
          me.onUpdatePropostaAccesso(me.jsonData);
          // me.$refs.propostaAccesso.loadData();
        })
        .catch((error) => {
          // me.$refs["sa-modal-loading"].close();
          let message = "";
          error.response ? error.response.data.messaggio : "Errore non gestito";
          if (error.response) {
            message = error.response.data.messaggio ? error.response.data.messaggio : "Codice Errore: " + error.response.status + ", non gestito";
          }
          if (error.response) {
            this.$bvModal
              .msgBoxOk(message, {
                title: "ATTENZIONE",
                size: "sm",
                okVariant: "outline-danger",
                headerClass: "sa-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
              })
              .then(() => {})
              .catch((err) => {
                console.log(err);
              });
          }
        });
    },
  },
};
</script>

<style></style>
