<template>
    <div style="padding-top: 10px; height: 100%">
        <b-form @submit.prevent="onSubmit" @reset="onReset">
            <b-row>
                <b-col cols="6" class="my-1">
                    <label for="input-1" class="sa-form-label">Codice IcdIX</label>
                    <b-form-input id="input-1" v-model="form.codice" type="text" placeholder="Codice IcdIX"></b-form-input>
                </b-col>
                <b-col cols="6" class="my-1">
                    <label for="input-2" class="sa-form-label">Descrizione Codice IcdIX</label>
                    <b-form-input id="input-2" v-model="form.descrizione" type="text" placeholder="Descrizione Codice IcdIX"></b-form-input>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="sa-padding-right text-right">
                    <b-button type="submit" variant="primary">Cerca</b-button>
                </b-col>
            </b-row>
        </b-form>
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
            <b-col cols="12">
                <p>Totale Record: {{ rows }}</p>
            </b-col>
        </b-row>
        <b-table ref="table" select-mode="single" responsive stacked="lg" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table" selectable @row-selected="onRowSelected">
            <template #cell(selected)="{ rowSelected }">
                <template v-if="rowSelected">
                    <span aria-hidden="true">&check;</span>
                    <span class="sr-only">Selected</span>
                </template>
                <template v-else>
                    <span aria-hidden="true">&nbsp;</span>
                    <span class="sr-only">Not selected</span>
                </template>
            </template>
        </b-table>
    </div>
</template>

<script>
import axios from "axios";
export default {
    data() {
        return {
            currentPage: 1,
            perPage: 100,
            rowSelected: [],
            fields: [
                {
                    label: "",
                    key: "selected",
                    thStyle: "width: 0.2rem",
                    tdClass: "text-center",
                },
                {
                    label: "Codice IcDx",
                    key: "codiceIcdix",
                    thStyle: "width: 15rem text-center",
                    tdStyle: "text-center",
                    sortable: true,
                },
                {
                    label: "Descrizione IcDx",
                    key: "descrizioneIcdix",
                    thStyle: "width: 15rem text-center",
                    tdStyle: "text-center",
                    sortable: true,
                },
            ],

            pathResource: "/serviziicdix",
            items: [],
            showModalLoading: false,
            form: {},
        };
    },
    mounted() {
        // let me = this;
        // me.loadData();
    },
    methods: {
        onSubmit() {
            let me = this;
            me.loadData();
            return false;
        },
        onReset() {
            return 0;
        },
        loadData() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "?forPage=" + me.perPage + "&page=" + me.currentPage;
            //   + "&codiceIcdix=" + me.jsonData.codiceIcdix + "&descrizioneIcdix=" + me.jsonData.descrizioneIcdix;
            axios
                .get(link, { params: this.form })
                .then((response) => {
                    me.items = [];
                    me.items = response.data.data.list;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        onRowSelected(row) {
            console.log(row);
            this.$emit("update", row);
        },
    },
    computed: {
        rows() {
            return this.items.length;
        },
    },
};
</script>

<style></style>
