<template>
    <div>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Tipo Caso</label>
                    <span class="sa-data">{{ jsonData.tipoCaso }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Paese Visitato</label>
                    <span class="sa-data">{{ jsonData.paeseVisitato }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Continente</label>
                    <span class="sa-data">{{ jsonData.continente }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Motivo Viaggio</label>
                    <span class="sa-data">{{ jsonData.motivoViaggio }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Altro Motivo Viaggio</label>
                    <span class="sa-data-justify">{{ jsonData.altroMotivoViaggio }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Partenza Dall'Italia</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataPartenzaItalia) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Rientro in Italia</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataRientroItalia) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Inizio Sintomi</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataInizioSintomi) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Ricovero</label>
                    <span class="sa-data">{{ jsonData.ricovero }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Nome Ospedale</label>
                    <span class="sa-data">{{ jsonData.nomeOspedale }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Reparto</label>
                    <span class="sa-data">{{ jsonData.reparto }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Ricovero</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataRicovero) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Diagnosi Clinica</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataDiagnosiClinica) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Diagnosi Emoscopica</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataDiagnosiEmoscopica) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Esito</label>
                    <span class="sa-data">{{ jsonData.esito }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Dimissione/Decesso</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataDimissioneDecesso) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Specie di Plasmodium</label>
                    <span class="sa-data">{{ jsonData.speciePlasmodium }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Specificare tipologia mista di Plasmodium</label>
                    <span class="sa-data-justify">{{ jsonData.speciePlasmodiumAltro }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-row>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <b-card class="sa-card" header="Terapia" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Terapia</label>
                            <span class="sa-data">{{ jsonData.terapia }}</span>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-table sticky-header stacked="xl" striped hover itemscope :items="jsonData.listaTerapie" :fields="fieldsFarmaci" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                                <template #cell(index)="row">
                                    {{ row.index + 1 }}
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <b-card class="sa-card" header="Farmacoresistenza" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Farmacoresistenza</label>
                            <span class="sa-data">{{ jsonData.framacoresistenza }}</span>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-table sticky-header stacked="xl" striped hover itemscope :items="jsonData.listaFarmacoresistenti" :fields="fieldsFarmaci" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                                <template #cell(index)="row">
                                    {{ row.index + 1 }}
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-card class="sa-card" header="Chemioprofilassi" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Chemioprofilassi</label>
                    <span class="sa-data">{{ jsonData.chemioprofilassi }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Tipo Chemioprofilassi</label>
                    <span class="sa-data">{{ jsonData.tipoChemioprofilassi }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Stato Di Gravidanza</label>
                    <span class="sa-data">{{ jsonData.statoGravidanza }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Patologie Concomitanti</label>
                    <span class="sa-data">{{ jsonData.patologieConcomitanti }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Altre Considerazioni</label>
                    <span class="sa-data">{{ jsonData.altreConsiderazioni }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Dati Notifica" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Sanitario Notificante</label>
                    <span class="sa-data">{{ jsonData.sanitarioNotificante }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Telefono Sanitario Notificante</label>
                    <span class="sa-data">{{ jsonData.telefonoSanitarioNotificante }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Notifica</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataNotifica) }}</span>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
<script>
import axios from "axios";
import UtilityMixin from "../../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixin],
    props: {},
    data() {
        return {
            pathResource: "/malattieinfettiveiemalaria",
            id: "-1",
            currentPage: 1,
            perPage: 100,
            jsonData: {
                tipoCaso: "",
                paeseVisitato: "",
                continente: "",
                motivoViaggio: "",
                altroMotivoViaggio: "",
                dataPartenzaItalia: null,
                dataRientroItalia: null,
                dataInizioSintomi: null,
                ricovero: "",
                nomeOspedale: "",
                reparto: "",
                dataRicovero: null,
                dataDiagnosiClinica: null,
                dataDiagnosiEmoscopica: null,
                esito: "",
                dataDimissioneDecesso: null,
                speciePlasmodium: "",
                speciePlasmodiumAltro: "",
                terapia: "",
                listaTerapie: [],
                framacoresistenza: "",
                listaFarmacoresistenti: [],
                chemioprofilassi: "",
                tipoChemioprofilassi: "",
                statoGravidanza: "",
                patologieConcomitanti: "",
                sanitarioNotificante: "",
                telefonoSanitarioNotificante: "",
                dataNotifica: null,
            },
            fieldsFarmaci: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Farmaco",
                    key: "nomeFarmaco",
                    sortable: false,
                },
            ],
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.$emit("showmodalloading", true);
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            axios
                .get(link + me.id)
                .then((response) => {
                    me.jsonData = response.data.data;
                    me.$emit("showmodalloading", false);
                })
                .catch(() => {
                    me.$emit("showmodalloading", false);
                });
        },
    },
};
</script>
