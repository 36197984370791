<template>
	<div class="sa-free-page">
		<div class="sa-free-page-header">Header</div>
		<div class="sa-free-page-body">
			<b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
				<b-form @submit.prevent="onSubmit">
					<b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
						<b-col xs="12" sm="12" md="6" lg="6" xl="6">
							<label class="sa-label-data">Gestore:</label>
							<b-form-select v-model="jsonData.gestore" :options="gestoreOptions" value-field="gestore" text-field="gestore" @change="onChangeGestore">
								<template #first>
									<b-form-select-option :value="null" disabled> - Seleziona il Gestore - </b-form-select-option>
								</template>
							</b-form-select>
						</b-col>
						<b-col xs="12" sm="12" md="6" lg="6" xl="6">
							<label class="sa-label-data">Tipo Firma:</label>
							<b-form-select v-model="jsonData.tipo" :options="tipoFirmaOptions" :value="''" value-field="value" text-field="text" :disabled="tipoFirmaDisable"></b-form-select>
						</b-col>
						<b-col xs="12" sm="12" md="4" lg="4" xl="4">
							<label class="sa-label-data">Username:</label>
							<b-form-input v-model="jsonData.username" type="search" id="tipo" placeholder="Username"></b-form-input>
						</b-col>
						<b-col xs="12" sm="12" md="4" lg="4" xl="4">
							<label class="sa-label-data">Password:</label>
							<b-form-input v-model="jsonData.pin" type="search" id="tipo" placeholder="Password"></b-form-input>
						</b-col>
						<b-col xs="12" sm="12" md="4" lg="4" xl="4">
							<label class="sa-label-data">O.T.P.:</label>
							<b-form-input v-model="jsonData.otp" type="search" id="tipo" placeholder="O.T.P."></b-form-input>
						</b-col>
						<b-col xs="12" sm="12" md="4" lg="4" xl="4">
							<label class="sa-label-data">Posizione Firma:</label>
							<b-form-input v-model="jsonData.posizioneFirma" type="search" id="tipo" placeholder="O.T.P."></b-form-input>
						</b-col>
						<b-col xs="12" sm="12" md="4" lg="4" xl="4">
							<label class="sa-label-data">Progressivo Firmatario:</label>
							<b-form-input v-model="jsonData.progressivoFirmatario" type="search" id="tipo" placeholder="O.T.P."></b-form-input>
						</b-col>
						<!-- <b-col xs="12" sm="12" md="6" lg="6" xl="6">
							<label class="sa-label-data">Applicazione:</label>
							<b-form-select v-model="jsonData.codiceApplicativo" :options="applicazioneOptions" value-field="gestore" text-field="gestore" @change="onChangeGestore">
								<template #first>
									<b-form-select-option :value="null" disabled> - Seleziona il Gestore - </b-form-select-option>
								</template>
							</b-form-select>
						</b-col> -->
						<!-- <b-col xs="12" sm="12" md="6" lg="6" xl="6">
							<label class="sa-label-data">Dispositivo:</label>
							<b-form-select v-model="jsonData.codiceDispositivo" :options="dispositiviOptions" value-field="gestore" text-field="gestore" @change="onChangeGestore">
								<template #first>
									<b-form-select-option :value="null" disabled> - Seleziona il Gestore - </b-form-select-option>
								</template>
							</b-form-select>
						</b-col> -->
						<!-- <b-col xs="12" sm="12" md="12" lg="12" xl="12">
							<label class="sa-label-data">Custom Metadata:</label>
							<b-form-textarea v-model="jsonData.customMetadata" placeholder="Custom Metadata"></b-form-textarea>
						</b-col> -->
						<b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
							<label class="sa-label-data">File da Firmare:</label>
							<b-form-file v-model="fileDaFirmare" :state="Boolean(fileDaFirmare)" placeholder="Trascina qui il file" ref="file-input" class="custom-input" type="file" accept=".pdf"></b-form-file>
							<div class="mt-3">File Selezionato: {{ fileDaFirmare ? fileDaFirmare.name : "" }}</div>
						</b-col>
						<b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
							<b-embed type="iframe" aspect="16by9" :src="filePdfSigned" allowfullscreen></b-embed>
						</b-col>
					</b-row>
				</b-form>
			</b-card>
		</div>
		<div class="sa-free-page-footer">Footer</div>
	</div>
</template>

<script>
import UtilityMixin from "../../utility/UtilityMixin";
export default {
	mixins: [UtilityMixin],
	data() {
		return {
			pathResourcesGestori: "/digitalsign",
			jsonData: { gestore: "ARUBA", tipo: "REMOTA", username: "titolare_rem", pin: "password22", otp: "0973487814", posizioneFirma: "BOTTOM", progressivoFirmatario: 2, files: [] },
			tipoFirmaDisable: true,
			responseData: { idRiferimento: null, filename: "", fileBase64: null },
			statoOptions: [
				{ text: "-Selezionare lo Stato-", value: "" },
				{ text: "FIRMATO", value: "FIRMATO" },
				{ text: "SIGNED", value: "SIGNED" },
				{ text: "ERROR", value: "ERROR" },
				{ text: "ERROR POST SIGNED", value: "ERROR POST SIGNED" },
				{ text: "SUCCESS  POST SIGNED", value: "SUCCESS  POST SIGNED" },
			],
			gestoreOptions: [
				{ text: "-Selezionare il Gestore-", value: "" },
				{ text: "KEFIRMA", value: "KEFIRMA" },
				{ text: "ARUBA", value: "ARUBA" },
				{ text: "INFOCERT", value: "INFOCERT" },
			],
			infocertOptions: [
				{ text: "-Selezionare la Firma-", value: "" },
				{ text: "REMOTA", value: "REMOTA" },
				{ text: "AUTOMATICA", value: "AUTOMATICA" },
			],
			arubaOptions: [
				{ text: "-Selezionare la Firma-", value: "" },
				{ text: "REMOTA", value: "REMOTA" },
				{ text: "AUTOMATICA", value: "AUTOMATICA" },
			],
			kefirmaOptions: [{ text: "GRAFOMETRICA", value: "GRAFOMETRICA" }],
			tipoFirmaOptions: [{ text: "-Selezionare la Firma-", value: "" }],
			applicazioneOptions: [],
			dispositiviOptions: [],
			fileDaFirmare: null,
			filePdfSigned: null,
		};
	},
	mounted() {
		let me = this;
		me.loadGestori();
	},
	watch: {
		fileDaFirmare: function (e) {
			if (e !== null) {
				const selectedFile = e;
				this.createBase64(selectedFile);
			}
		},
		jsonData: {
			handler(value) {
				this.$emit("updateJsonData", value);
			},
			deep: true,
		},
	},
	methods: {
		loadGestori() {
			let me = this;
			UtilityMixin.methods.loadDataResources(me.pathResourcesGestori, null, me.loadGestoriSuccess, me.loadGestoriError);
			me.$emit("loadAnalysisQuery");
		},
		loadGestoriSuccess(response) {
			let me = this;
			me.gestoreOptions = response.data.data.list;
			me.changeGestore();
		},
		loadGestoriError(error) {
			error;
			this.$bvToast.toast("Errore nel caricamento dei Gestori", {
				title: "Errore",
				variant: "danger",
				solid: true,
			});
		},
		onChangeGestore() {
			let me = this;
			me.jsonData.tipoFirma = "";
			me.changeGestore();
		},
		changeGestore() {
			let me = this;
			me.tipoFirmaDisable = false;
			me.tipoFirmaOptions = [];
			switch (me.jsonData.gestore) {
				case "ARUBA":
					me.tipoFirmaOptions = me.arubaOptions;
					break;
				case "INFOCERT":
					me.tipoFirmaOptions = me.infocertOptions;
					break;
				case "KEFIRMA":
					me.tipoFirmaOptions = me.kefirmaOptions;
					break;
			}
			if (me.tipoFirmaOptions.length === 1) {
				me.tipoFirmaDisable = true;
				me.jsonData.tipoFirma = me.tipoFirmaOptions[0].value;
			}
		},
		createBase64(fileObject) {
			let me = this;
			const reader = new FileReader();
			reader.onload = (e) => {
				me.pdf = e.target.result;
				me.pdf = me.pdf.replace("data:application/pdf;base64,", "");
				let object = { idRiferimento: "67dbc6ccd955edc9084e50ae", filename: "PREOSP-2019008236-001.0013-67dbc32132bf798c9faa8467-CPI.pdf", fileBase64: me.pdf };
				me.jsonData.files.push(object);
				console.log(me.jsonData.file);
			};
			reader.readAsDataURL(fileObject);
		},
		setResponseData(responseData) {
			let me = this;
			me.responseData = responseData;
			me.filePdfSigned = "data:application/pdf;base64," + me.responseData.fileBase64;
		},
	},
};
</script>

<style></style>
