<template>
    <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <b-card class="sa-card sa-card-border" header="Anamnesi Patologica Prossima" header-tag="header" footer-tag="footer" title="">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Sintomatologia attuale </label>
                        <b-form-input v-model="jsonData.sintomatologiaAttuale" :disabled="true"> </b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Compliance terapeutica</label>
                        <b-form-select v-model="jsonData.complianceTerapeutica" :options="complianceTerapeuticaOptions" :disabled="isReadOnly">
                            <template #first>
                                <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-col>

                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Terapia antalgica</label>
                        <b-form-select v-model="jsonData.terapiaAntalgica" :options="terapiaAntalgicaOptions" :disabled="isReadOnly">
                            <template #first>
                                <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-col>

                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Descrizione</label>
                        <b-form-input v-model="jsonData.terapiaAntalgicaDescrizione" :disabled="isReadOnly"> </b-form-input>
                    </b-col>

                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <label class="sa-label-data">Dolore</label>
                        <b-form-input v-model="jsonData.dolore" :disabled="true"> </b-form-input>
                    </b-col>

                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <label class="sa-label-data">Note</label>
                        <b-form-textarea v-model="jsonData.note" :readonly="isReadOnly" max-rows="10" rows="5"> </b-form-textarea>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
    </b-row>
</template>
<script>
import UtilityMixin from "../../../utility/UtilityMixin";

export default {
    props: {
        isReadOnly: {
            type: Boolean,
            default: false,
        },
        idAccettazione: {
            type: String,
        },
    },
    /* mounted() {
        let me = this;
        me.loadDizionarioData();
    }, */

    mixins: [UtilityMixin],
    data() {
        return {
            pathResourceSchedaInfermieristicaApp: "/rsaschedainfermieristicaapp",
            //   pathResourceDizionario: "/rsadizionario",
            pathResourceRiferimentiDiagnostici: "/rsaschedariferimentidiagnostici",
            pathresourceAreaMobilita: "/rsaareamobilita",

            jsonData: {
                id: null,
                idAccettazione: null,
                sintomatologiaAttuale: null,
                complianceTerapeutica: null,
                terapiaAntalgica: null,
                terapiaAntalgicaDescrizione: null,
                dolore: null,
                note: null,
            },

            complianceTerapeuticaOptions: [
                { text: "BUONA", value: "BUONA" },
                { text: "DISCRETA", value: "DISCRETA" },
                { text: "SCARSA", value: "SCARSA" },
            ],
            terapiaAntalgicaOptions: [
                { text: "SI", value: "SI" },
                { text: "NO", value: "NO" },
            ],
        };
    },
    methods: {
        /* loadDizionarioData() {
            let me = this;
            UtilityMixin.methods.loadDataResources(me.pathResourceDizionario, null, me.loadDizionarioDataSuccess, me.loadDizionarioDataError);
        },

        loadDizionarioDataSuccess(response) {
            let me = this;
            if (response.data?.data?.list) {
                me.allergieOptions = response.data.data.list.filter((item) => item.tipo === "ALLERGIA");
                me.sierologiaOptions = response.data.data.list.filter((item) => item.tipo === "SIEROLOGIA");
            }
        },
        loadDizionarioDataError(error) {
            console.error("Errore nel caricamento allergie/sierologia", error);
        }, */

        loadData() {
            let me = this;
            UtilityMixin.methods.loadDataResources(me.pathResourceSchedaInfermieristicaApp + "/accettazione/" + me.idAccettazione, {}, me.resultResponseSchedaInfermieristicaAppSuccess, me.resultResponseSchedaInfermieristicaAppError);
        },
        resultResponseSchedaInfermieristicaAppSuccess(response) {
            let me = this;

            //  me.listaAllergie = "";

            me.jsonData = response.data.data;
            // me.setListaAllergie();
            // me.setListaPositivitaSierologica();
            /* me.jsonData.listaAllergie.forEach((element) => {
                me.listaAllergie += element.descrizione + ", ";
            });

            me.listaPositivitaSierologiche = "";
            me.jsonData.listaPositivitaSierologiche.forEach((element) => {
                me.listaPositivitaSierologiche += element.descrizione + ", ";
            }); */
            /*  me.jsonData.listaSchedaAprDettaglio.forEach((item) => {
                if (item.tipo === "ALLERGIE") {
                    me.selectedAllergie.push(item.voce);
                } else if (item.tipo === "SIEROLOGIA") {
                    me.selectedSierologia.push(item.voce);
                }
            }); */
        },
        resultResponseSchedaInfermieristicaAppError(error) {
            let me = this;
            if (error.response && error.response.status === 404) {
                //console.log("NESSUN record trovato del ANAMNESIA PATOLOGICA PROSSIMA per questo id accettazione");
                me.resetForm();
            } else {
                console.error("Errore nel caricamento dati della sezione ANAMNESIA PATOLOGICA PROSSIMA:", error);
            }
            me.loadRiferimentiDiagnostici();
            me.loadAreaMobilita();
        },

        resetForm() {
            let me = this;
            me.jsonData = {
                id: null,
                idAccettazione: null,
                sintomatologiaAttuale: null,
                complianceTerapeutica: null,
                terapiaAntalgica: null,
                terapiaAntalgicaDescrizione: null,
                dolore: null,
                note: null,
            };
        },

        onSalvaAnamnesiPatologicaProssima() {
            let me = this;
            me.jsonData.idAccettazione = me.idAccettazione;
            if (!me.jsonData.idAccettazione) {
                me.$bvToast.toast("Errore: ID Accettazione mancante", {
                    title: "Errore",
                    variant: "danger",
                    solid: true,
                });
                return;
            }

            UtilityMixin.methods.saveResource(me.pathResourceSchedaInfermieristicaApp, me.jsonData, me.saveAnamnesiPatologicaProssimaSuccessResponse, me.saveAnamnesiPatologicaProssimaErrorResponse);
        },
        saveAnamnesiPatologicaProssimaSuccessResponse(response) {
            console.log("ANAMNESIA PATOLOGICA PROSSIMA salvato con successo:", response);
        },
        saveAnamnesiPatologicaProssimaErrorResponse(error) {
            console.error("Errore nel salvataggio ANAMNESIA PATOLOGICA PROSSIMA:", error);
        },
        loadRiferimentiDiagnostici() {
            let me = this;
            let link = me.pathResourceRiferimentiDiagnostici + "/accettazione/" + me.idAccettazione;
            UtilityMixin.methods.loadDataResources(link, {}, me.resultResponseRiferimentiDiagnosticiSucces, me.resultResponseRiferimentiDiagnosticiError);
        },
        resultResponseRiferimentiDiagnosticiSucces(response) {
            let me = this;
            me.jsonData.sintomatologiaAttuale = response.data.data.anamnesiPatologicaProssima;
            // me.jsonData.patologiaPrincipale = response.data.data.anamnesiPatologicaRemota;
            // me.jsonData.listaAllergie = response.data.data.listaAllergie;
            // me.jsonData.listaPositivitaSierologiche = response.data.data.listaPositivitaSierologiche;
            // me.setListaAllergie();
            // me.setListaPositivitaSierologica();
        },

        resultResponseRiferimentiDiagnosticiError(error) {
            if (error.response && error.response.status === 404) {
                //console.log("NESSUN record trovato del ANAMNESIA PATOLOGICA PROSSIMA per questo id accettazione");
            } else {
                console.error("Errore nel caricamento dati della sezione ANAMNESIA PATOLOGICA PROSSIMA:", error);
            }
        },
        loadAreaMobilita() {
            let me = this;
            let link = me.pathresourceAreaMobilita + "/accettazione/" + me.idAccettazione;
            UtilityMixin.methods.loadDataResources(link, {}, me.resultResponseAreaMobilitaSucces, me.resultResponseAreaMobilitaError);
        },
        resultResponseAreaMobilitaSucces(response) {
            let me = this;
            me.jsonData.dolore = response.data.data.sedeDolore;
        },

        resultResponseAreaMobilitaError(error) {
            if (error.response && error.response.status === 404) {
                //console.log("NESSUN record trovato dell' AREA MOBILITA");
            } else {
                console.error("Errore nel caricamento dati della sezione AREA MOBILITA:", error);
            }
        },
    },
};
</script>
