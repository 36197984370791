<template>
    <b-modal ref="mdlRsaSchedaAnamnesiaStoriaClinicaEditComponent" size="lg" title="Scheda Anamnesi/Storia Clinica" @hidden="onCloseModal" @ok="onSalvaAnamnesiStoriaClinica" centered no-close-on-backdrop no-close-on-esc hide-header-close>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card sa-card-border" header="Valutazione" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Storia Clinica</label>
                            <b-form-textarea v-model="jsonDataAnamnesiaStoriaClinica.storiaClinica" max-rows="20" rows="10" :readonly="isReadOnly"></b-form-textarea>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Anamnesia</label>
                            <b-form-textarea v-model="jsonDataAnamnesiaStoriaClinica.anamnesia" max-rows="20" rows="10" :readonly="isReadOnly"></b-form-textarea>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <template #modal-footer>
            <b-button v-if="!isReadOnly" variant="outline-danger" @click="$refs.mdlRsaSchedaAnamnesiaStoriaClinicaEditComponent.hide()">Annulla</b-button>
            <b-button v-if="!isReadOnly" variant="outline-success" @click="onSalvaAnamnesiStoriaClinica()">Salva</b-button>
            <b-button v-if="isReadOnly" variant="outline-primary" @click="$refs.mdlRsaSchedaAnamnesiaStoriaClinicaEditComponent.hide()"> Chiudi </b-button>
        </template>
    </b-modal>
</template>

<script>
import UtilityMixin from "../../../utility/UtilityMixin";

export default {
    mixins: [UtilityMixin],
    data() {
        return {
            pathResource: "/rsaanamnesistoriaclinica",
            pathResourceCheck: "/rsaanamnesistoriaclinica/check",
            jsonDataAnamnesiaStoriaClinica: {
                id: null,
                idAccettazione: null,
                storiaClinica: null,
                anamnesia: null,
            },
            isReadOnly: false,
            /* esisteRecord: false, */
        };
    },

    mounted() {
        let me = this;
        me.idAccettazione = me.$route.query.idPaziente;
        // console.log("idAccettazione nel mounted di Storia: " + me.idAccettazione);
    },

    methods: {
        nuovoAnamnesiaStoriaClinica() {
            let me = this;
            me.isReadOnly = false;

            UtilityMixin.methods.loadDataResources(
                me.pathResource,
                { idAccettazione: me.idAccettazione },
                (response) => {
                    if (response.data?.data?.list?.length > 0) {
                        me.jsonDataAnamnesiaStoriaClinica = response.data.data.list[0];
                    } else {
                        me.jsonDataAnamnesiaStoriaClinica = {
                            id: null,
                            idAccettazione: me.idAccettazione,
                            storiaClinica: null,
                            anamnesia: null,
                        };
                    }
                    me.$refs.mdlRsaSchedaAnamnesiaStoriaClinicaEditComponent.show();
                },
                (error) => {
                    console.error("Errore nel caricamento:", error);
                    me.jsonDataAnamnesiaStoriaClinica = {
                        id: null,
                        idAccettazione: me.idAccettazione,
                        storiaClinica: "",
                        anamnesia: "",
                    };
                    me.$refs.mdlRsaSchedaAnamnesiaStoriaClinicaEditComponent.show();
                }
            );
        },
        visualizzaAnamnesiaStoriaClinica() {
            let me = this;
            me.isReadOnly = true;
            UtilityMixin.methods.loadDataResources(
                me.pathResource,
                { idAccettazione: me.idAccettazione },
                (response) => {
                    if (response.data?.data?.list?.length > 0) {
                        me.jsonDataAnamnesiaStoriaClinica = response.data.data.list[0];
                        me.$refs.mdlRsaSchedaAnamnesiaStoriaClinicaEditComponent.show();
                    } else {
                        me.$bvToast.toast("Nessun record trovato per questa accettazione", {
                            title: "Informazione",
                            variant: "info",
                            solid: true,
                        });
                    }
                    me.$emit("afterLoadData");
                },
                (error) => {
                    console.error("Errore nel caricamento:", error);
                    me.$bvToast.toast("Errore nel caricamento dei dati", {
                        title: "Errore",
                        variant: "danger",
                        solid: true,
                    });
                    me.$emit("afterLoadData");
                }
            );
        },

        onCloseModal() {
            let me = this;
            me.jsonDataAnamnesiaStoriaClinica = {};
            me.$emit("onClose");
        },

        onSalvaAnamnesiStoriaClinica() {
            let me = this;
            /*  console.log(me.jsonDataAnamnesiaStoriaClinica);
            console.log("----------"); */

            if (!me.jsonDataAnamnesiaStoriaClinica.idAccettazione) {
                me.$bvToast.toast("Errore: ID Accettazione mancante", {
                    title: "Errore",
                    variant: "danger",
                    solid: true,
                });
                return;
            }

            /* console.log("Dati da salvare:", me.jsonDataAnamnesiaStoriaClinica); */
            UtilityMixin.methods.saveResource(me.pathResource, me.jsonDataAnamnesiaStoriaClinica, me.saveAnamnesiaStoriaClinicaSuccessResponse, me.saveAnamnesiaStoriaClinicaErrorResponse);
        },
        saveAnamnesiaStoriaClinicaSuccessResponse() {
            let me = this;
            me.$emit("afterSaveSuccess");
            me.$refs.mdlRsaSchedaAnamnesiaStoriaClinicaEditComponent.hide();
            me.$bvToast.toast("Salvataggio avvenuto con successo", {
                title: "Successo",
                variant: "success",
                solid: true,
            });
        },
        saveAnamnesiaStoriaClinicaErrorResponse(response) {
            if (response.response.status === 400) {
                this.$bvModal.msgBoxOk(response.response.data.messaggio, {
                    title: "ATTENZIONE",
                    size: "sm",
                    okVariant: "outline-danger",
                    headerClass: "sa-msg-header-danger",
                    footerClass: "p-2 border-top-0",
                    centered: true,
                });
            } else {
                this.$bvToast.toast("Errore nel salvataggio" + response.message, {
                    title: "Errore",
                    variant: "danger",
                    solid: true,
                });
            }
        },
    },
};
</script>
