<template>
  <div class="form-group">
    <b-row>
      <b-col xs="12" sm="12" md="12" lg="12" xl="12">
        <label class="sa-form-label" for="jsonData-denominazione">Denominazione</label>
        <br />
        <span id="jsonData-provincia" class="sa-label-data">{{ jsonData.denominazione }}</span>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
        <label class="sa-form-label" for="jsonData-codice-struttura">Codice Fiscale</label>
        <br />
        <span id="jsonData-codice-fiscale" class="sa-label-data">{{ jsonData.codiceFiscale }}</span>
      </b-col>
      <b-col xs="12" sm="6" md="6" lg="6" xl="6">
        <label class="sa-form-label" for="jsonData-partita-iva">P. I.V.A.</label>
        <br />
        <span id="jsonData-partita-iva" class="sa-label-data">{{ jsonData.partitaIva }}</span>
      </b-col>
    </b-row>
    <b-row>
      <b-col xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-form-label" for="jsonData-regione">Regione</label>
        <br />
        <span id="jsonData-regione" class="sa-label-data">{{ jsonData.regione }}</span>
      </b-col>
      <b-col xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-form-label" for="jsonData-provincia">Provincia</label>
        <br />
        <span id="jsonData-provincia" class="sa-label-data">{{ jsonData.provincia }}</span>
      </b-col>
      <b-col xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-form-label" for="jsonData-comune">Comune</label>
        <br />
        <span id="jsonData-comune" class="sa-label-data">{{ jsonData.comuneCodiceIstat }}</span>
      </b-col>
    </b-row>
    <b-row>
      <b-col xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-form-label" for="jsonData-indirizzo">Indirizzo</label>
        <br />
        <span id="jsonData-indirizzo" class="sa-label-data">{{ jsonData.indirizzo }}</span>
      </b-col>
      <b-col xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-form-label" for="jsonData-cap">C.A.P</label>
        <br />
        <span id="jsonData-cap" class="sa-label-data">{{ jsonData.cap }}</span>
      </b-col>
      <b-col xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-form-label" for="jsonData-localita">Localita</label>
        <br />
        <span id="jsonData-localita" class="sa-label-data">{{ jsonData.localita }}</span>
      </b-col>
    </b-row>
    <b-row>
      <b-col xs="12" sm="6" md="4" lg="4" xl="4">
        <label class="sa-form-label" for="jsonData-telefono">Telefono</label>
        <br />
        <span id="jsonData-telefono" class="sa-label-data">{{ jsonData.telefono }}</span>
      </b-col>
      <b-col xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-form-label" for="jsonData-fax">Fax</label>
        <br />
        <span id="jsonData-fax" class="sa-label-data">{{ jsonData.fax }}</span>
      </b-col>
      <b-col xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-form-label" for="jsonData-email">Email</label>
        <br />
        <span id="jsonData-email" class="sa-label-data">{{ jsonData.email }}</span>
      </b-col>
    </b-row>
    <b-row>
      <b-col xs="12" sm="12" md="12" lg="12" xl="12">
        <label class="sa-form-label" for="jsonData-url">Url</label>
        <br />
        <span id="jsonData-url" class="sa-label-data">{{ jsonData.url }}</span>
      </b-col>
      <b-col xs="12" sm="12" md="12" lg="12" xl="12">
        <label class="sa-form-label" for="jsonData-codiceFiscale">Certificato Camerale</label>
        <br />
        <b-embed type="iframe" :src="jsonData.certificatoCamerale" allowfullscreen></b-embed>
        <!-- <span id="jsonData-codice-fiscale" class="sa-label-data">{{ jsonData.certificatoCamerale }}</span> -->
      </b-col>
    </b-row>
  </div>
</template>

<script>
import UtilityMixins from "../../utility/UtilityMixin";
//import axios from "axios";
export default {
  mixins: [UtilityMixins],

  props: {
    jsonData: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  mounted() {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style></style>
