<template>
    <sa-page-layout slot="table-body" @refresh="onRefresh" :btnRefreshVisible="true" :showModalLoading="showModalLoading" :btnConfigurazioniVisible="btnConfigurazioniVisible" :pathResource="pathResourceSpecializzazionePai">
        <template slot="toolbar">
            <b-button class="float-sm-right" variant="success btn-toolbar sa-margin-right" size="sm" @click="onClickNuovaSpecializzazionePai">
                <b-icon icon="plus"></b-icon>
                Nuovo
            </b-button>
        </template>
        <template slot="table-filter">
            <b-form @submit.prevent="onSubmit">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Ruolo</label>
                        <b-form-select v-model="filtroSpecializzazionePai.idRuolo" type="search" :options="ruoliOptions" value-field="id" text-field="ruolo" value="''"></b-form-select>
                    </b-col>

                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Specializzazione</label>
                        <b-form-input v-model="filtroSpecializzazionePai.specializzazione" type="search" id="specializzazione" placeholder="Inserire specializzazione"></b-form-input>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="sa-padding-right pt-3 pb-3 text-right">
                        <b-button type="submit" variant="info"> {{ this.$i18n.t("patients.lblFind") }}</b-button>
                        <b-button type="reset" variant="danger" v-on:click="onReset"> {{ this.$i18n.t("patients.lblResetFilter") }}</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </template>

        <template slot="table-body">
            <rsa-pai-specializzazione-list-component ref="cmpRsaSpecializzazionePaiList" @afterLoadData="onAfterLoadData" @beforeLoadData="onBeforeLoadData"></rsa-pai-specializzazione-list-component>

            <rsa-pai-specializzazione-edit-component ref="cmpRsaPaiSpecializzazioneEdit" @onCloseSpecializzazionePaiModal="loadData" @onSaveSpecializzazionePaiSuccessResponse="onSaveSpecializzazionePai" @onDeleteSpecializzazionePai="onDelete" @afterLoadData="onAfterLoadData"></rsa-pai-specializzazione-edit-component>
        </template>
    </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import UtilityMixin from "../../../utility/UtilityMixin";
import RsaPaiSpecializzazioneEditComponent from "../components/RsaPaiSpecializzazioneEditComponent.vue";
import RsaPaiSpecializzazioneListComponent from "../components/RsaPaiSpecializzazioneListComponent.vue";
export default {
    components: { SaPageLayout, RsaPaiSpecializzazioneListComponent, RsaPaiSpecializzazioneEditComponent },
    mixins: [UtilityMixin],
    data() {
        return {
            btnConfigurazioniVisible: true,
            pathResourceSpecializzazionePai: "",
            pathResourcePaiRuoli: "/rsapairuoli",
            ruoliOptions: [],
            linkback: "",
            linkedit: "",
            filtroSpecializzazionePai: { idRuolo: null, specializzazione: null },
            showModalLoading: false,
        };
    },

    mounted() {
        let me = this;
        me.loadRuoliPaiData();
        me.loadData();
        me.onReset();
    },

    methods: {
        loadData() {
            let me = this;
            me.$refs.cmpRsaSpecializzazionePaiList.loadData();
        },
        loadRuoliPaiData() {
            let me = this;
            UtilityMixin.methods.loadDataResources(me.pathResourcePaiRuoli, null, me.loadRuoliPaiDataSuccess, me.loadRuoliPaiDataError);
            me.$emit("LoadRuoliPaiData");
        },

        loadRuoliPaiDataSuccess(response) {
            let me = this;

            me.ruoliOptions = response.data.data.list;
            me.ruoliOptions.unshift({ ruolo: "-SELEZIONARE RUOLO-" });
        },
        loadRuoliPaiDataError(error) {
            console.log("Errore durante il caricamento di loadRuoliPaiDataError :", error);
        },

        onAfterLoadData() {
            let me = this;
            me.showModalLoading = false;
        },
        onBeforeLoadData() {
            let me = this;
            me.showModalLoading = true;
        },

        onSubmit() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtroSpecializzazionePai"] = JSON.stringify(me.filtroSpecializzazionePai);
            me.loadData();
            /* console.log("onSubmitPage: ", this.filtroSpecializzazionePai); */
        },
        onReset() {
            let me = this;
            me.filtroSpecializzazionePai = { specializzazione: null, idRuolo: null };
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtroSpecializzazionePai"] = JSON.stringify(me.filtroSpecializzazionePai);
            me.loadData();
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        onClickNuovaSpecializzazionePai() {
            let me = this;
            me.$refs.cmpRsaPaiSpecializzazioneEdit.nuovaSpecializzazionePai();
        },
        onSaveSpecializzazionePai() {
            let me = this;
            me.loadData();
        },
        onDelete() {
            let me = this;
            me.loadData();
        },
    },
};
</script>
