<template>
  <div class="sa-flexbox" style="padding-bottom: 20px">
    <div class="sa-flexbox-body">
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="table" stacked="lg" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table"> </b-table>
      </div>
    </div>
    <div class="sa-flexbox-footer" style="background-color: ciano">
      <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import UtilityMixin from "../../../utility/UtilityMixin";

export default {
  props: {},
  mixins: [UtilityMixin],
  components: {},
  computed: {},
  data() {
    return {
      pathResource: "/rsadimissionitrasferimentistatistorico",
      disableCampi: true,
      btnModificaDimissionetrasferimento: true,
      items: [],
      currentPage: 1,
      listKey: 0,
      rows: "",
      perPage: 50,
      statioptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "DIMESSO", text: "DIMESSO" },
        { value: "TRASFERITO", text: "TRASFERITO" },
      ],
      fields: [
        { label: "Stato", key: "stato" },
        { label: "Motivazione", key: "motivazione" },
        {
          label: "Data e ora",
          key: "statoDataOra",
          formatter: function (value) {
            return UtilityMixin.methods.formatDateTime(value);
          },
        },
        // { class: "sa-table-column-action-double", key: "actions", label: "" },
      ],
    };
  },
  mounted() {
    // let me = this;
    // me.loadData();
  },
  watch: {
    currentPage() {
      let me = this;
      me.loadData();
    },
  },
  methods: {
    loadData(id) {
      let me = this;
      if (id !== null && id !== "") {
        let filtro = { page: 1, forPage: me.perPage, idDimissioneTrasferimento: id };
        UtilityMixin.methods.loadDataResources(me.pathResource, filtro, me.resultResponseSettings, me.resultErrorSettings);
      }
    },
    resultResponseSettings(response) {
      let me = this;
      me.items = response.data.data.list;
      me.rows = response.data.data.recordsNumber;
      me.listKey++;
    },
    resultErrorSettings(response) {
      UtilityMixin.methods.messaggeBoxError(this, response);
    },
  },
};
</script>
