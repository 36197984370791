var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[(_vm.show)?_c('b-form',{on:{"reset":_vm.onReset}},[_c('b-row',[_c('b-col',{attrs:{"xs":"12","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('label',{attrs:{"for":""}},[_vm._v("Nome")]),_c('b-form-input',{attrs:{"type":"text","placeholder":"Nome","formatter":function (value) {
              return String(value).toUpperCase();
            }},on:{"input":function($event){return _vm.onFiltraContatti()}},model:{value:(_vm.filter.nome),callback:function ($$v) {_vm.$set(_vm.filter, "nome", $$v)},expression:"filter.nome"}})],1),_c('b-col',{attrs:{"xs":"12","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('label',{attrs:{"for":""}},[_vm._v("Cognome")]),_c('b-form-input',{attrs:{"placeholder":"Cognome","formatter":function (value) {
              return String(value).toUpperCase();
            }},on:{"input":function($event){return _vm.onFiltraContatti()}},model:{value:(_vm.filter.cognome),callback:function ($$v) {_vm.$set(_vm.filter, "cognome", $$v)},expression:"filter.cognome"}})],1),_c('b-col',{attrs:{"xs":"12","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('label',{attrs:{"for":""}},[_vm._v("Codice Fiscale")]),_c('b-form-input',{attrs:{"placeholder":"Codice Fiscale","formatter":function (value) {
              return String(value).toUpperCase();
            }},on:{"input":function($event){return _vm.onFiltraContatti()}},model:{value:(_vm.filter.identificativo),callback:function ($$v) {_vm.$set(_vm.filter, "identificativo", $$v)},expression:"filter.identificativo"}})],1),_c('b-col',{attrs:{"cols":"12","lg":"6","xl":"6"}},[_c('label',{attrs:{"for":""}},[_vm._v("Settore")]),_c('b-form-select',{attrs:{"id":"input-3","options":_vm.settori,"value-field":"id","text-field":"descrizione"},on:{"input":function($event){return _vm.onFiltraContatti()}},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v(" - Seleziona Settore - ")])]},proxy:true}],null,false,2252073210),model:{value:(_vm.filter.idSettore),callback:function ($$v) {_vm.$set(_vm.filter, "idSettore", $$v)},expression:"filter.idSettore"}})],1)],1),_c('b-row',{staticClass:"mt-2 mb-2",attrs:{"align":"right"}},[_c('b-col',[_c('b-button',{attrs:{"type":"reset","variant":"outline-danger"}},[_vm._v("Reset")])],1)],1)],1):_vm._e(),_c('b-table',{ref:"table",staticClass:"sa-b-table",attrs:{"sticky-header":"","stacked":"lg","striped":"","hover":"","items":_vm.contatti,"fields":_vm.fields,"current-page":_vm.currentPage,"per-page":_vm.perPage,"sort-icon-left":"","head-variant":"light"},scopedSlots:_vm._u([{key:"cell(identificativo)",fn:function(ref){
            var item = ref.item;
return [_c('router-link',{staticClass:"sa-edit-link",attrs:{"to":'/contatti/view/' + item.id}},[_vm._v(" "+_vm._s(item.cognome)+" "+_vm._s(item.nome)+" ")]),_c('br'),_c('small',[_vm._v(" "+_vm._s(item.identificativo)+" ")])]}},{key:"cell(codicePacchetto)",fn:function(ref){
            var item = ref.item;
return [_c('b',{staticClass:"sa-edit-link text-danger"},[_vm._v(" "+_vm._s((item.codicePacchetto = item.codicePacchetto === null ? "" : item.codicePacchetto))+" ")]),_c('br'),_c('small',[_vm._v(" "+_vm._s((item.descrizionePacchetto = !item.descrizionePacchetto === null ? "" : item.descrizionePacchetto))+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }