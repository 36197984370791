<template>
    <b-modal ref="mdlEditTurno" :title="title" centered no-close-on-backdrop no-close-on-esc hide-header-close>
        <b-row>
            <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                <span class="sa-form-label">Turno</span>
                <b-form-input v-model="jsonData.label"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                <span class="sa-form-label">Ora inizio</span>
                <date-picker format="HH:mm" value-type="timestamp" v-model="jsonData.oraInizio" type="time"></date-picker>
            </b-col>

            <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                <span class="sa-form-label">Ora fine</span>
                <date-picker format="HH:mm" value-type="timestamp" v-model="jsonData.oraFine" type="time"></date-picker>
            </b-col>
        </b-row>

        <template #modal-footer>
            <b-button size="md" variant="outline-danger" @click="onCloseModal">Annulla</b-button>
            <b-button size="md" variant="outline-success" @click="onClickOk()">Salva</b-button>
        </template>
    </b-modal>
</template>

<script>
import UtilityMixin from "../../../utility/UtilityMixin";
import DatePicker from "vue2-datepicker";
export default {
    mixins: [UtilityMixin],
    components: { DatePicker },
    data() {
        return {
            title: null,
            pathResource: "/rsaconfigurazioneturni",
            jsonData: {
                id: null,
                label: null,
                oraInizio: new Date(),
                oraFine: new Date(),
            },
        };
    },
    methods: {
        show(jsonData) {
            let me = this;
            me.jsonData = jsonData;
            me.title = !jsonData.id ? "Nuovo turno" : "Modifica turno";
            me.$refs.mdlEditTurno.show();
        },

        onCloseModal() {
            let me = this;
            me.jsonData = {
                id: null,
                label: null,
                oraInizio: new Date(),
                oraFine: new Date(),
            };
            me.$emit("onClose");
            me.$refs.mdlEditTurno.hide();
        },

        onClickOk() {
            let me = this;
            UtilityMixin.methods.saveResource(me.pathResource, me.jsonData, me.saveSuccessResponse, me.saveErrorResponse);
        },
        saveSuccessResponse(response) {
            let me = this;
            me.$emit("onSave", response);
            me.$refs.mdlEditTurno.hide();
            me.$bvToast.toast("Salvataggio avvenuto con successo", {
                title: "Successo",
                variant: "success",
                solid: true,
            });
        },
        saveErrorResponse(response) {
            if (response.response.status === 400) {
                this.$bvModal.msgBoxOk(response.response.data.messaggio, {
                    title: "ATTENZIONE",
                    size: "sm",
                    okVariant: "outline-secondary",
                    headerClass: "sa-msg-header-danger",
                    footerClass: "p-2 border-top-0",
                    centered: true,
                });
            } else {
                this.$bvToast.toast("Errore nel salvataggio" + response.message, {
                    title: "Errore",
                    variant: "danger",
                    solid: true,
                });
            }
        },

        onDeleteTurno(item) {
            let me = this;
            me.$bvModal
                .msgBoxConfirm("Sei sicuro di eliminare questo turno?", {
                    title: "Eliminazione Turno",
                    buttonSize: "sm",
                    okTitle: "SI",
                    cancelTitle: "NO",
                    okVariant: "danger",
                    cancelVariant: "secondary",
                    hideHeaderClose: true,
                    footerClass: "p-2",
                    centered: true,
                })
                .then((value) => {
                    if (value) {
                        me.deleteTurno(item.id);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        deleteTurno(id) {
            let me = this;
            UtilityMixin.methods.deleteResource(me.pathResource + "/" + id, null, me.deleteTurnoSuccess, me.deleteTurnoError);
        },
        deleteTurnoSuccess(response) {
            let me = this;
            me.$emit("onDeleteTurno", response);
            me.$bvToast.toast("Eliminazione avvenuta con successo", {
                title: "Success",
                variant: "success",
                solid: true,
            });
        },
        deleteTurnoError(error) {
            let me = this;
            me.showModalLoading = false;
            console.log(error);
            me.$bvToast.toast("Errore durante l'eliminazione", {
                title: "Errore",
                variant: "danger",
                solid: true,
            });
        },
    },
};
</script>
