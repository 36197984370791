<template>
    <eventi-view-component :jsonData="jsonData" :isAllegati="true" :pathResource="pathResource" :pathResourceFirma="pathResourceFirma" :utente="utente" :titoloEvento="titoloEvento" :linkPrintData="linkPrintData">
        <template slot="tab-content-event">
            <b-row>
                <b-col cols="12" xs="12" sm="10" md="10" lg="10" xl="10">
                    <anamnesi-generica-view-component @update="onUpdateJsonData"></anamnesi-generica-view-component>
                </b-col>
                <b-col cols="12" xs="12" sm="2" md="2" lg="2" xl="2" class="sa-page-paziente-col-diario">
                    <paziente-diario-component class="sa-page-paziente-diario-clinico" :idPaziente="idPaziente"></paziente-diario-component>
                </b-col>
            </b-row>
        </template>
    </eventi-view-component>
</template>
<script>
import EventiViewComponent from "../../base/components/EventiViewComponent.vue";
import EventiMixin from "../../utility/mixins/EventiMixins";
import UtilityMixins from "../../../../utility/UtilityMixin";
// import VisitaControlloEcgEditComponent from "./VisitaControlloEcgEditComponent.vue";
import AnamnesiGenericaViewComponent from "../components/AnamnesiGenericaViewComponent.vue";
import PazienteDiarioComponent from "../../../utility/PazienteDiarioComponent.vue";
export default {
    mixins: [UtilityMixins],
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    components: { EventiViewComponent, AnamnesiGenericaViewComponent, PazienteDiarioComponent },
    created() {
        console.log("Created");
    },
    data() {
        return {
            linkback: null,
            titoloEvento: "VISITA GENERICA",
            gruppoEvento: "Eventi",
            pathResource: "/pazienteeventianamnesi",
            pathRestValidation: "/validation",
            id: "-1",
            pathResourceFirma: "",
            linkPrintData: null,
            isFirmato: false,
            childKey: 0,
            tabIndex: 0,
            showModalLoading: false,
            diario: [],
            jsonData: {},
            key: 0,
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient() {
            return EventiMixin.getLinkPatient(this.utente, this.idPaziente);
        },
    },
    mounted() {
        // this.jsonData.dataEvento = new Date().getTime();
        let me = this;
        me.id = this.$route.params.id;
    },
    methods: {
        onFirmaUpdate() {
            let me = this;
            me.loadData();
        },
        onUpdateFile() {
            let me = this;
            me.childKey++;
        },
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
            me.linkPrintData = "/pazienteeventi/print/" + me.jsonData.id;
        },
    },
};
</script>
<style scoped>
.ft-space {
    margin-top: 10px;
}
</style>
