<template>
    <b-modal scrollable ref="mdlRsaSchedaRiferimentoDiagnostico" size="xl" title="Scheda riferimenti diagnostici" @ok="onSalvaRiferimentiDiagnostici" centered no-close-on-backdrop no-close-on-esc hide-header-close>
        <!-- <div class="sa-tab-scroll"> -->
        <b-card class="sa-card sa-card-border" header="Riferimenti DX e TX" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Anamnesi patologica remota</label>
                    <b-form-input v-model="jsonData.anamnesiPatologicaRemota" :readonly="isReadOnly"> </b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Anamnesi patologica prossima</label>
                    <b-form-input v-model="jsonData.anamnesiPatologicaProssima" :readonly="isReadOnly"> </b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Diagnosi in accesso</label>
                    <b-form-input v-model="jsonData.diagnosiAccesso" :readonly="isReadOnly"> </b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Gruppo sanguigno</label>
                    <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.gruppoSanguigno" class="mb-2 mr-sm-2 mb-sm-0" :value="null" value-field="value" text-field="text" :options="gruppoSanguignoOptions" :disabled="isReadOnly"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Note</label>
                    <b-form-textarea v-model="jsonData.note" id="textarea-auto-height" placeholder="" rows="7" :readonly="isReadOnly"></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card sa-card-border" header="Allergie" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" v-for="allergia in allergiaOptions" :key="/* 'ausilio-' +  */ allergia.id" class="sa-switch-select-element">
                    <b-form-checkbox switch :disabled="isReadOnly" v-model="selectedAllergia" :value="allergia.voce" :readonly="isReadOnly">
                        <span style="font-weight: normal">{{ allergia.voce }}</span>
                        <!-- La voce delle checkbox sono bold in default quando uso b-form-checkbox, vanno gestite separatamente -->
                    </b-form-checkbox>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card sa-card-border" header="Positivita' sierologiche" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" v-for="sierolgia in sierologiaOptions" :key="sierolgia.id" class="sa-switch-select-element">
                    <b-form-checkbox switch :disabled="isReadOnly" v-model="selectedSierologia" :value="sierolgia.voce">
                        <span style="font-weight: normal">{{ sierolgia.voce }}</span>
                    </b-form-checkbox>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card sa-card-border" header="Scale di valutazione" header-tag="header" footer-tag="footer" title="">
            <rsa-accettazione-schede-valutazione-component ref="cmpRsaSchedeValutazioneComponent"></rsa-accettazione-schede-valutazione-component>
            <!--  <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaScaleValutazione" :fieldsScaleValutazione="fieldsScaleValutazione" :current-page="1" :per-page="50" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
                    <template #cell(actions)="{ item }">
                        <b-container class="bv-example-row">
                            <b-button v-if="item.compilata" size="sm" variant="outline-success" disabled>
                                <font-awesome-icon icon="check"></font-awesome-icon>
                            </b-button>
                            <b-button v-else size="sm" variant="outline-danger" disabled>
                                <font-awesome-icon icon="check"></font-awesome-icon>
                            </b-button>
                            <b-button v-if="item.path !== null && item.path !== ''" size="sm" title="Modifica Richiesta" variant="outline-success" @click="onInserisciModifica(item)">
                                <font-awesome-icon icon="edit"></font-awesome-icon>
                            </b-button>
                            <b-button size="sm" v-b-tooltip.hover title="Visualizza Richiesta" variant="outline-secondary" @click="onClickVisualizzaScala(item)" class="mr-1 no-text">
                                <font-awesome-icon icon="eye" />
                            </b-button>
                        </b-container>
                    </template>
                </b-table> -->
        </b-card>
        <!--  </div> -->

        <template #modal-footer>
            <b-button v-if="!isReadOnly" variant="outline-danger" @click="$refs.mdlRsaSchedaRiferimentoDiagnostico.hide()">Annulla</b-button>
            <b-button v-if="!isReadOnly" variant="outline-success" @click="onSalvaRiferimentiDiagnostici()">Salva</b-button>
            <b-button v-if="isReadOnly" variant="outline-primary" @click="$refs.mdlRsaSchedaRiferimentoDiagnostico.hide()"> Chiudi </b-button>
        </template>
    </b-modal>
</template>
<script>
import UtilityMixin from "../../../utility/UtilityMixin";
import RsaAccettazioneSchedeValutazioneComponent from "../../rsaaccettazione/components/RsaAccettazioneSchedeValutazioneComponent.vue";

//import axios from "axios";
export default {
    components: {
        /* RsaScalaBarthelEditComponent, RsaScalaBradenEditComponent, RsaScalaMinimentalShortEditComponent, RsaScalaTinettiEditComponent  */ RsaAccettazioneSchedeValutazioneComponent,
    },
    props: {},
    watch: {
        jsonData: {
            handler() {
                let me = this;
                me.$emit("update", me.jsonData);
            },
            deep: true,
        },
        //  selectedAllergia: {
        //     handler() {
        //         let me = this;
        //         me.selectedAllergia.forEach((element) => {
        //             me.jsonData.listaAllergie.push({ descrizione: element });
        //         });
        //     },
        // },
        // selectedSierologia: {
        //     handler() {
        //         let me = this;
        //         me.selectedSierologia.forEach((element) => {
        //             me.jsonData.listaPositivitaSierologiche.push({ descrizione: element });
        //         });
        //           me.selectedAllergia.forEach((element) => {
        //             me.jsonData.listaAllergie.push({ descrizione: element });
        //         });
        //     },
        // },
    },
    data() {
        return {
            idAccettazione: null,
            isReadOnly: false,
            linkback: "",
            isScalaValutazioneRiferimentiDiagnostici: true,
            listKey: 0,
            pathResource: "/rsaschedariferimentidiagnostici",
            pathResourceDizionario: "/rsadizionario",
            listaAllergie: [],
            totale: 0,
            jsonData: { anamnesiPatologicaProssima: "", anamnesiPatologicaRemota: "", diagnosiAccesso: "", gruppoSanguigno: "", idAccettazione: "", note: "", listaAllergie: [], listaPositivitaSierologiche: [] },
            selectedAllergia: [],
            listaScaleValutazione: [],
            allergiaOptions: [],
            sierologiaOptions: [],
            selectedSierologia: [],

            gruppoSanguignoOptions: [
                { text: "-Seleziona Valore-", value: "" },
                { text: "0 +", value: "0 +" },
                { text: "A +", value: "A +" },
                { text: "B +", value: "B +" },
                { text: "AB +", value: "AB +" },
                { text: "0 -", value: "0 -" },
                { text: "A -", value: "A -" },
                { text: "B -", value: "B -" },
                { text: "AB -", value: "AB -" },
                { text: "NON CONOSCIUTO", value: "NON CONOSCIUTO" },
            ],
            fieldsScaleValutazione: [
                {
                    label: "Tipo Scheda",
                    key: "tipoScheda",
                },
                {
                    label: "",
                    key: "actions",
                    class: "sa-table-column-action-double",
                },
            ],
        };
    },
    mounted() {
        let me = this;
        me.idAccettazione = me.$route.query.idPaziente;
        // console.log("idAccettazione nel mounted di ModalDiagnostica: " + me.idAccettazione);
    },
    methods: {
        show(value, isView) {
            let me = this;
            me.jsonData = { anamnesiPatologicaProssima: null, anamnesiPatologicaRemota: null, diagnosiAccesso: null, gruppoSanguigno: null, idAccettazione: me.idAccettazione, note: null, listaAllergie: [], listaPositivitaSierologiche: [] };
            me.selectedAllergia = [];
            me.selectedSierologia = [];
            me.$refs.mdlRsaSchedaRiferimentoDiagnostico.show();
            // me.$refs.cmpRsaSchedeValutazioneComponent.init(me.idAccettazione);
            me.controlloCmpScaleValutazione();
            me.isReadOnly = isView;
            // me.isReadOnly = value.compilata;
            // me.loadData();
            if (value.compilata) {
                me.loadData();
            }
            me.loadAllergie();
            me.loadSierologie();
        },
        loadData() {
            let me = this;
            let link = me.pathResource + "/accettazione/" + me.idAccettazione;
            UtilityMixin.methods.loadDataResources(link, {}, me.resulResponseSuccess, me.resultResponseError);
        },
        resulResponseSuccess(response) {
            let me = this;
            me.jsonData = response.data.data;
            me.jsonData.listaAllergie.forEach((element) => {
                me.allergiaOptions.forEach((item) => {
                    if (element.descrizione === item.voce) {
                        me.selectedAllergia.push(element.descrizione);
                    }
                });
            });
            me.jsonData.listaPositivitaSierologiche.forEach((element) => {
                me.sierologiaOptions.forEach((item) => {
                    if (element.descrizione === item.voce) {
                        me.selectedSierologia.push(element.descrizione);
                    }
                });
            });
        },
        resultResponseError(error) {
            console.log(error);
        },
        onSalvaRiferimentiDiagnostici() {
            let me = this;
            me.jsonData.listaAllergie = [];
            me.jsonData.listaPositivitaSierologiche = [];

            me.selectedSierologia.forEach((element) => {
                me.jsonData.listaPositivitaSierologiche.push({ descrizione: element });
            });
            me.selectedAllergia.forEach((element) => {
                me.jsonData.listaAllergie.push({ descrizione: element });
            });
            //   me.jsonData.listaAllergie = [];
            //   me.jsonData.listaPositivitaSierologiche = [];

            UtilityMixin.methods.saveResource(me.pathResource, me.jsonData, me.resultSaveResponse, me.resultSaveError);
        },
        resultSaveResponse() {
            let me = this;
            me.$refs.mdlRsaSchedaRiferimentoDiagnostico.hide();
            me.$bvToast.toast("Salvataggio avvenuto con successo", {
                title: "Successo",
                variant: "success",
                solid: true,
            });
            me.$emit("onClose");
        },
        resultSaveError(response) {
            let me = this;
            if (response.response.status === 400) {
                me.$bvModal.msgBoxOk(response.response.data.messaggio, {
                    title: "ATTEZIONE",
                    size: "md",
                    okVariant: "outline-secondary",
                    headerClass: "sa-msg-header-danger",
                    footerClass: "p-2 border-top-0",
                    centered: true,
                });
            } else {
                me.$bvToast.toast("Errore nel salvataggio in edit component" + response.message, {
                    title: "Errore",
                    variant: "danger",
                    solid: true,
                });
            }
        },
        loadSierologie() {
            let me = this;
            UtilityMixin.methods.loadDataResources(me.pathResourceDizionario, { tipo: "SIEROLOGIA" }, me.resultResponseSucceSierologie, me.resultResponseErrorSierologie);
        },
        resultResponseSucceSierologie(response) {
            let me = this;
            me.sierologiaOptions = [];
            me.sierologiaOptions = response.data.data.list;
        },
        resultResponseErrorSierologie(error) {
            console.log(error);
        },
        loadAllergie() {
            let me = this;
            UtilityMixin.methods.loadDataResources(me.pathResourceDizionario, { tipo: "ALLERGIA" }, me.resultResponseSucceAllergie, me.resultResponseErrorAllergie);
        },
        resultResponseSucceAllergie(response) {
            let me = this;
            me.allergiaOptions = [];
            me.allergiaOptions = response.data.data.list;
        },
        resultResponseErrorAllergie(error) {
            console.log(error);
        },
        controlloCmpScaleValutazione() {
            let me = this;
            //me.isScalaValutazioneRiferimentiDiagnostici = ["SCALA BRADEN", "SCALA TINETTI", "SCALA BARTHEL", "SCALA MINIMENTAL SHORT"];
            const interval = setInterval(() => {
                if (me.$refs.cmpRsaSchedeValutazioneComponent) {
                    me.$refs.cmpRsaSchedeValutazioneComponent.init(me.idAccettazione, me.isScalaValutazioneRiferimentiDiagnostici);
                    clearInterval(interval);
                }
            }, 50);
        },
    },
};
</script>
