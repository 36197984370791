<template>
  <sa-aside mini>
    <sa-aside-brand :logo="logo" :logosmall="logosmall" header="" />
    <sa-aside-container>
      <!-- <sa-aside-user :image="avatar" :username="nominativoUtente" :ruolo="ruolo" /> -->
      <sa-aside-nav class="sisto-andolfi">
        <template v-for="item in externalmenu">
          <sa-aside-nav-header
            v-if="!item.children && !item.viewId"
            :title="$t(item.text)"
            :key="item.routeId"
          />
          <sa-aside-nav-item
            v-if="!item.children && item.viewId"
            :title="$t(item.text)"
            :key="item.routeId"
            :icon="item.iconCls"
            :badge="item.badge"
            :to="item.viewId"
          />
          <sa-aside-nav-item-dropdown
            v-if="item.children"
            :key="item.routeId"
            :title="$t(item.text)"
            :icon="item.iconCls"
          >
            <sa-aside-nav-item
              v-for="submenu in item.children"
              :key="submenu.routeId"
              :title="$t(submenu.text)"
              :icon="submenu.iconCls"
              :to="submenu.viewId"
            />
          </sa-aside-nav-item-dropdown>
        </template>
      </sa-aside-nav>
    </sa-aside-container>
  </sa-aside>
</template>

<script>
import avatar from "../../../assets/images/no-avatar.png";
import logo from "../../../assets/images/logo-base.png";
import logosmall from "../../../assets/images/logo.png";

export default {
  props: {
    externalmenu: Array,
    nominativoUtente: String,
    ruolo: String,
  },
  created() {},
  data() {
    return {
      logo,
      logosmall,
      avatar,
      username: "",
    };
  },
};
</script>

<style></style>
