<template>
    <div style="height: 100%">
        <div class="sa-flexbox" style="padding-bottom: 20px">
            <div class="sa-flexbox-header">
                <!-- <b-form @submit.prevent="onSubmit">
          <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          </b-row>
        </b-form> -->
            </div>
            <div class="sa-flexbox-body">
                <div class="b-table-sticky-header">
                    <span class="sa-flexbox-body-title">Impegnativa</span>
                    <b-button size="sm" variant="outline-success outline-primary-noborder sa-margin-right float-sm-right" :hidden="btnNuovaImpegnativaHidden" @click="nuovaImpegnativa" class="mb-2">
                        <font-awesome-icon icon="plus"> </font-awesome-icon>
                        Aggiungi
                    </b-button>

                    <b-table sticky-header ref="table" stacked="lg" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
                        <template v-slot:cell(tipoImpegnativa)="{ item }">
                            {{ item.impegnativaTipo || item.tipoImpegnativa }}
                        </template>

                        <template v-slot:cell(trattamentoDataInizio)="{ item }">
                            <span>{{ formatDate(item.trattamentoDataInizio) }}</span>
                        </template>
                        <template v-slot:cell(trattamentoDataFine)="{ item }">
                            <span>{{ formatDate(item.trattamentoDataFine) }}</span>
                        </template>
                        <template v-slot:cell(quote)="{ item }">
                            <span class="sa-table-span-label-primary">Quota Totale: {{ item.quotaTotale }} €</span>
                            <span class="sa-table-span-label-primary">Quota ASL: {{ item.quotaAsl }} € ({{ item.quotaAslPercentuale }}%)</span>
                            <span class="sa-table-span-label-primary"> Quota Comune: {{ item.quotaComune }} € ({{ item.quotaComunePercentuale }}%)</span>
                            <span class="sa-table-span-label-primary">Quota Utente: {{ item.quotaUtente }} € ({{ item.quotaUtentePercentuale }}%)</span>
                        </template>
                        <template v-slot:cell(actions)="{ item }">
                            <b-container class="bv-example-row">
                                <b-button size="sm" v-b-tooltip.hover title="Duplica Impegnativa" variant="outline-success no-text" @click="onDuplicaImpegnativa(item)">
                                    <font-awesome-icon icon="plus"></font-awesome-icon>
                                </b-button>
                                <b-button size="sm" v-b-tooltip.hover title="Modifica Impegnativa" variant="outline-success" @click="onEditImpegnativa(item)">
                                    <font-awesome-icon icon="edit"></font-awesome-icon>
                                </b-button>

                                <b-button size="sm" v-b-tooltip.hover title="Cancella Impegnativa" variant="outline-danger" @click="onDeleteImpegnativa(item)">
                                    <font-awesome-icon icon="trash"></font-awesome-icon>
                                </b-button>
                            </b-container>
                        </template>
                    </b-table>
                </div>
            </div>
            <div class="sa-flexbox-footer" style="background-color: ciano">
                <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col lg="3">
                        <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rows }}</p>
                    </b-col>
                    <b-col lg="9">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                    </b-col>
                </b-row>
            </div>
            <rsa-accettazione-impegnativa-edit-component ref="cmpRsaAccettazioneImpegnativaEditComponent" :idAccettazione="idAccettazione" :jsonDataAccettazione="jsonData" @afterSaveRequest="onAfterSaveRequestRsaImpegnativaComponent"></rsa-accettazione-impegnativa-edit-component>
        </div>
    </div>
</template>
<script>
import axios from "axios";
//import DatePicker from "vue2-datepicker";
import UtilityMixin from "../../../utility/UtilityMixin";
import RsaAccettazioneImpegnativaEditComponent from "./RsaAccettazioneImpegnativaEditComponent.vue";
export default {
    props: {
        jsonData: { type: Object },
        idAccettazione: { type: String },
    },
    mixins: [UtilityMixin],
    components: {
        RsaAccettazioneImpegnativaEditComponent,
        // DatePicker,
    },
    data() {
        return {
            rows: 0,
            perPage: 50,
            btnNuovaImpegnativaHidden: false,
            currentPage: 1,
            isNew: false,
            filtro: {},
            items: [],
            listKey: 0,
            pathResource: "/rsaimpegnativa",
            linkedit: "/",
            fields: [
                {
                    label: "Tipo Impegnativa",
                    key: "tipoImpegnativa",
                },
                {
                    label: "Onere Prestazione",
                    key: "onerePrestazione",
                },
                {
                    label: "Data Inizio del Trattamento",
                    key: "trattamentoDataInizio",
                },
                {
                    label: "Data fine del Trattamento",
                    key: "trattamentoDataFine",
                },
                // {
                //   label: "Numero Giorni del Trattamento",
                //   key: "numeroGiorniTrattamento",
                // },
                { label: "Quote", key: "quote" },
                { class: "sa-table-column-action-double", key: "actions", label: "" },
            ],
        };
    },
    mounted() {
        //let me = this;
        //  me.idAccettazione = me.$route.params.id;
        // me.loadData();
    },
    watch: {
        currentPage() {
            let me = this;
            me.loadData();
        },
    },
    methods: {
        loadData() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            if (sessionStorage["filtro"]) {
                me.filtro = JSON.parse(sessionStorage["filtro"]);
            }
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.filtro.page = me.currentPage;
            me.filtro.forPage = me.perPage;
            me.filtro.idAccettazione = me.idAccettazione;
            me.$emit("beforeLoadData");
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];
                    me.rows = response.data.data.recordsNumber;
                    me.items = response.data.data.list;
                    me.listKey++;
                    this.$emit("afterLoadData");
                })

                .catch(() => {
                    this.$emit("afterLoadData");
                });
        },
        nuovaImpegnativa() {
            let me = this;
            /* me.isNew = true; */
            me.$refs.cmpRsaAccettazioneImpegnativaEditComponent.show({
                codiceAsl: "",
                codiceDistretto: "",
                dataAutorizzazione: null,
                dataPrescrizione: "",
                descrizioneAsl: "",
                descrizioneDistretto: "",
                diagnosiIcdixCodice: "",
                diagnosiIcdixDescrizione: "",
                idAccettazione: "",
                medicoPrescrittoreCodice: "",
                medicoPrescrittoreTipo: "",
                note: "",
                numeroAutorizzazione: "",
                numeroGiorniTrattamento: null,
                onerePrestazione: "",
                quotaTotale: "",
                quotaAsl: "",
                quotaAslPercentuale: "",
                quotaComune: "",
                quotaComunePercentuale: "",
                quotaUtente: "",
                quotaUtentePercentuale: "",
                statoAttuale: null,
                statoAttualeDataOra: "",
                tipoImpegnativa: "",
                tipoPrestazione: null,
                trattamentoDataFine: "",
                trattamentoDataInizio: new Date().getTime(),
                trattamentoTipo: "",
            });
            me.$refs.cmpRsaAccettazioneImpegnativaEditComponent.setData();
        },
        // onSalvaImpegnativa() {
        //   let me = this;
        //   me.$refs.RsaAccettazioneImpegnativaEditComponent.salvaImpegnativa();
        // },

        onEditImpegnativa(item) {
            let me = this;
            me.$refs.cmpRsaAccettazioneImpegnativaEditComponent.loadData(item.id);
            me.$refs.cmpRsaAccettazioneImpegnativaEditComponent.show(item);
        },
        onDeleteImpegnativa(item) {
            let me = this;
            this.$bvModal
                .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
                    title: "Eliminazione Ricetta",
                    buttonSize: "sm",
                    okVariant: "secondary",
                    cancelVariant: "danger",
                    okTitle: "SI",
                    cancelTitle: "NO",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                })
                .then((value) => {
                    if (value) {
                        me.deleteImpegnativa(item.id);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        deleteImpegnativa(id) {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + id;
            axios
                .delete(link)
                .then((Response) => {
                    console.log(Response);

                    me.showModalLoading = false;
                    me.loadData();
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        onDuplicaImpegnativa(jsonData) {
            let me = this;
            // jsonData.id = null;
            // console.log(jsonData);
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            axios
                .post(link, jsonData)
                .then((Response) => {
                    console.log(Response);

                    me.showModalLoading = false;
                    me.loadData();
                })
                .catch(() => {
                    // .catch((e) => {
                    // console.log(e);
                });
        },
        onAfterSaveRequestRsaImpegnativaComponent() {
            let me = this;
            me.$refs.cmpRsaAccettazioneImpegnativaEditComponent.close();
            this.$bvToast.toast(`Richiesta salvata correttamente`, {
                title: "Impegnativa",
                solid: true,
                variant: "success",
                autoHideDelay: 5000,
                appendToast: true,
            });
            me.loadData();
        },
    },
};
</script>
