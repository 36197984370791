<template>
    <sa-page-layout :btnNewVisible="btnNewVisible" :btnConfigurazioniVisible="btnConfigurazioniVisible" :btnRefreshVisible="true" @refresh="onRefresh" :pathResource="pathResource" :showModalLoading="showModalLoading" :linkedit="linkedit">
        <template slot="table-filter">
            <b-form @submit.prevent="onSubmit">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-label-data">Nome:</label>
                        <b-form-input v-model="filtro.nome" type="search" id="nome"></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-label-data">Descrizione:</label>
                        <b-form-input v-model="filtro.descrizione" type="search" id="descrizione"></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-label-data">Stato:</label>
                        <b-form-select id="inline-form-custom-select-pref" v-model="filtro.stato" value-field="value" text-field="text" class="mb-2 mr-sm-2 mb-sm-0" :options="statoOption" :value="null"></b-form-select>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="sa-padding-right pt-3 pb-3 text-right">
                        <b-button type="submit" variant="info">{{ this.$i18n.t("patients.lblFind") }}</b-button>
                        <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("patients.lblResetFilter") }}</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </template>
        <template slot="table-body">
            <rsa-configurazione-diari-list-component ref="RsaConfigurazioneDiariListComponent" @afterLoadData="onAfterLoadData" @beforeLoadData="onBeforeLoadData"></rsa-configurazione-diari-list-component>
        </template>
    </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import RsaConfigurazioneDiariListComponent from "../components/RsaConfigurazioneDiariListComponent.vue";
export default {
    components: { SaPageLayout, RsaConfigurazioneDiariListComponent },
    data() {
        return {
            btnNewVisible: true,
            pathResource: "rsadiari",
            linkedit: "/rsaconfigurazionediari",
            btnConfigurazioniVisible: true,
            showModalLoading: false,
            listKey: 0,
            id: "-1",
            filtro: { nome: "", descrizione: "", stato: "" },
            statoOption: [
                { value: null, text: "- STATO -" },
                { value: "ATTIVO", text: "ATTIVO" },
                { value: "DISATTIVO", text: "DISATTIVO" },
                { value: "SOSPESO", text: "SOSPESO" },
            ],
        };
    },
    mounted() {
        let me = this;
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            me.$refs.RsaConfigurazioneDiariListComponent.loadData();
            // me.statoOption.unshift({ value: null, text: "ciao" });
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        onAfterLoadData() {
            let me = this;
            me.showModalLoading = false;
        },
        onBeforeLoadData() {
            let me = this;
            me.showModalLoading = true;
        },
        onSubmit() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
        onReset() {
            let me = this;
            me.filtro = {};
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
    },
};
</script>

<style></style>
