<template>
    <sa-page-layout slot="table-body" :btnConfigurazioniVisible="btnConfigurazioniVisible" :btnRefreshVisible="true" @refresh="aggiornamentoDati" :pathResource="pathResource" :showModalLoading="showModalLoading">
        <template slot="toolbar">
            <b-button class="float-sm-right" variant="success btn-toolbar sa-margin-right" size="sm" @click="onClickNuovoSetting">
                <b-icon icon="plus"></b-icon>
                Nuovo
            </b-button>
        </template>
        <template slot="table-filter">
            <b-form @submit.prevent="allineamentoDati">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Setting</label>
                        <b-form-input v-model="filtroSettings.setting" type="search" id="setting" placeholder="Inserire Setting"></b-form-input>
                    </b-col>

                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Codice</label>
                        <b-form-input v-model="filtroSettings.codice" type="search" id="codice" placeholder="Inserire Codice"></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="sa-padding-right pt-3 pb-3 text-right">
                        <b-button type="submit" variant="info"> {{ this.$i18n.t("patients.lblFind") }}</b-button>
                        <b-button type="reset" v-on:click="ripristinoDati" variant="danger">{{ this.$i18n.t("patients.lblResetFilter") }}</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </template>

        <template slot="table-body">
            <rsa-configurazione-settings-list-component ref="RsaConfigurazioneSettingsListComponent" @beforeLoadData="onBeforeLoadData" @afterLoadData="onAfterLoadData"> </rsa-configurazione-settings-list-component>
        </template>
    </sa-page-layout>
</template>

<script>
import RsaConfigurazioneSettingsListComponent from "../components/RsaConfigurazioneSettingsListComponent.vue";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";

export default {
    components: { SaPageLayout, RsaConfigurazioneSettingsListComponent },

    data() {
        return {
            filtroSettings: {
                setting: null,
                codice: null,
            },
            btnConfigurazioniVisible: true,
            btnNewVisible: false,
            pathResource: "",
            showModalLoading: false,
            listKey: 0,
        };
    },

    mounted() {
        this.loadData();
        // let sessionStorage = window.sessionStorage;
        // if (sessionStorage["filtroSettings"]) {
        //     this.filtro = JSON.parse(sessionStorage["filtroSettings"]);
        // }
    },

    methods: {
        loadData() {
            let me = this;
            me.$refs.RsaConfigurazioneSettingsListComponent.loadData();
        },

        onBeforeLoadData() {
            this.showModalLoading = true;
        },

        onAfterLoadData() {
            this.showModalLoading = false;
        },
        allineamentoDati() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtroSettings"] = JSON.stringify(me.filtroSettings);
            me.loadData();
        },
        aggiornamentoDati() {
            let me = this;
            me.loadData();
        },
        ripristinoDati() {
            let me = this;
            me.filtroSettings = { setting: null, codice: null };
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtroSettings"] = JSON.stringify(me.filtroSettings);
            me.loadData();
        },
        onClickNuovoSetting() {
            let me = this;
            me.$refs.RsaConfigurazioneSettingsListComponent.onClickNuovoSetting();
        },
    },
};
</script>
