<template>
    <div style="height: 100%">
        <div class="sa-flexbox" style="padding-bottom: 20px">
            <div class="sa-flexbox-body">
                <div class="b-table-sticky-header">
                    <div class="d-flex flex-column">
                        <b-row class="sa-label-info">
                            <b-col lg="3">
                                <p>Record Totali: {{ rows }}</p>
                            </b-col>
                            <b-col lg="9">
                                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                            </b-col>
                        </b-row>
                    </div>

                    <b-table sticky-header ref="table" stacked="lg" striped hover :items="filterItems" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light">
                        <template #cell(index)="row">
                            {{ row.index + 1 }}
                        </template>
                        <template v-slot:cell(codice)="{ item }">
                            <router-link class="sa-view-link" :to="'/numeratori/view/' + item.id">
                                {{ item.codice }}
                            </router-link>
                        </template>
                        <template v-slot:cell(actions)="{ item }">
                            <b-button size="sm" v-b-tooltip.hover title="Visualizza Numeratore" variant="outline-secondary no-text" @click="onClickView(item)" class="mr-1 no-text">
                                <font-awesome-icon icon="eye" />
                            </b-button>
                            <b-button-group>
                                <b-button size="sm" variant="outline-success" v-b-tooltip.hover title="Duplica Numeratore" @click="onClickDuplica(item)">
                                    <font-awesome-icon icon="edit" />
                                </b-button>
                            </b-button-group>
                        </template>
                    </b-table>
                </div>
            </div>

            <div class="sa-list-component-footer">
                <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col lg="3">
                        <p>Record Totali:{{ rows }}</p>
                    </b-col>
                    <b-col lg="9">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                    </b-col>
                </b-row>
            </div>

            <b-modal ref="mdlDuplicateNumeratore" id="mdlDuplicateNumeratore" :title="'Duplica numeratore'" @ok="onOkDuplicate" @hidden="onCloseModal" centered no-close-on-backdrop no-close-on-esc hide-header-close>
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                        <span class="sa-form-label">Codice</span>
                        <b-form-input v-model="jsonDataNuovoContatore.codice" placeholder="Codice" readonly> </b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                        <span class="sa-form-label">Descrizione</span>
                        <b-form-input v-model="jsonDataNuovoContatore.descrizione" readonly></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                        <span class="sa-form-label">Valore</span>
                        <b-form-input v-model="jsonDataNuovoContatore.valore"></b-form-input>
                    </b-col>
                </b-row>
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                        <span class="sa-form-label">Anno</span>
                        <b-form-input v-model="jsonDataNuovoContatore.anno" required></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                        <span class="sa-form-label">Cod.Struttura</span>
                        <b-form-input v-model="jsonDataNuovoContatore.codiceStruttura" readonly></b-form-input>
                    </b-col>
                </b-row>
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <span class="sa-form-label">Note</span>
                        <b-form-textarea v-model="jsonDataNuovoContatore.note" rows="3" max-rows="7" placeholder="Inserisci le note qui..."></b-form-textarea>
                    </b-col>
                </b-row>
            </b-modal>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import UtilityMixin from "../../utility/UtilityMixin";

export default {
    /* computed: {
        rows() {
            return this.items.length;
        },
    }, */
    data() {
        return {
            rows: 0,
            perPage: 50,
            currentPage: 1,
            pathResource: "/numeratori",
            linkedit: "/editnumeratori",
            showModalLoading: false,
            items: [],
            filterItems: [],
            filtroNumeratori: { codice: "", descrizione: "" },
            jsonDataNuovoContatore: {},
            fields: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Codice",
                    key: "codice",
                    thStyle: "width: 5rem",
                    sortable: true,
                },
                {
                    label: "Descrizione",
                    key: "descrizione",
                    thStyle: "width: 18rem",
                    sortable: true,
                },
                {
                    label: "Valore",
                    key: "valore",
                    thStyle: "width: 5rem",
                    sortable: true,
                },
                {
                    label: "Anno",
                    key: "anno",
                    thStyle: "width: 4rem",
                    sortable: true,
                },
                {
                    label: "Cod. Struttura",
                    key: "codiceStruttura",
                    thStyle: "width: 8rem",
                    sortable: true,
                },
                {
                    label: "Note",
                    key: "note",
                    sortable: true,
                },
                { class: "sa-table-column-action-double", key: "actions", label: "Azioni" },
            ],
        };
    },
    watch: {
        currentPage() {
            let me = this;
            me.loadData();
        },
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        /* if (me.id === "-1") {
            me.linkback = "/numeratori";
        } else {
            me.linkback = "/numeratori/edit/" + me.id;
        } */
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            if (sessionStorage["filtroNumeratori"]) {
                me.filtroNumeratori = JSON.parse(sessionStorage["filtroNumeratori"]);
            }

            me.filtroNumeratori.page = me.currentPage;
            me.filtroNumeratori.forPage = me.perPage;

            UtilityMixin.methods.loadDataResources(me.pathResource, me.filtroNumeratori, me.loadDataSuccess, me.loadDataError);
            this.showModalLoading = true;

            /* let link = process.env.VUE_APP_PATH_API + this.pathResource;
            this.showModalLoading = true; */

            /* axios
                .get(link)
                .then((response) => {
                    this.items = response.data.data;
                    this.filterItems = response.data.data;
                })
                .catch((error) => {
                    console.error("Errore nel caricamento dei dati:", error);
                    this.$bvToast.toast("Errore nel caricamento dei dati", {
                        title: "Errore",
                        variant: "danger",
                    });
                })
                .finally(() => {
                    this.showModalLoading = false;
                }); */
        },

        loadDataSuccess(response) {
            let me = this;
            me.filterItems = response.data.data;
            me.rows = response.data.data.length;
            this.showModalLoading = true;
        },
        loadDataError(response) {
            console.log(response);
        },

        onCloseModal() {
            this.jsonDataNuovoContatore = {};
        },
        onOkDuplicate() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            axios
                .post(link, me.jsonDataNuovoContatore)
                .then((response) => {
                    console.log(response);
                    me.showModalLoading = false;
                    me.loadData();
                })
                .catch((e) => {
                    this.$bvModal.msgBoxOk(e.response.data.messaggio, {
                        title: "ATTENZIONE",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    });
                });
        },

        onClickDuplica(jsonData) {
            let me = this;
            me.jsonDataNuovoContatore = {};
            me.jsonDataNuovoContatore.codice = jsonData.codice;
            me.jsonDataNuovoContatore.descrizione = jsonData.descrizione;
            me.jsonDataNuovoContatore.codiceStruttura = jsonData.codiceStruttura;
            me.$refs["mdlDuplicateNumeratore"].show();
        },

        onClickView(item) {
            let me = this;
            me.$router.push("/numeratori/view/" + item.id);
        },

        onSubmit() {
            this.filterItems = this.items.filter((element) => element.codice.toUpperCase().includes(this.filtroNumeratori.codice.toUpperCase()) && element.descrizione.toUpperCase().includes(this.filtroNumeratori.descrizione.toUpperCase()));
        },

        onReset() {
            this.filtroNumeratori = { codice: "", descrizione: "" };
            this.loadData();
        },

        onRefresh() {
            this.loadData();
        },
    },
};
</script>
