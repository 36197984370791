<template>
    <sa-page-layout :btnBackVisible="true" :btnEditVisible="btnEditVisible" :linkback="linkback" :btnSaveVisible="true" :pathResource="pathResource" :data="jsonData" :showModalLoading="showModalLoading">
        <template slot="table-body">
            <rsa-pai-edit-component ref="RsaGestioneOspitiViewComponent" @afterLoadData="onAfterLoadData" @beforeLoadData="onBeforeLoadData" @update="onUpdateJsonData"></rsa-pai-edit-component>
        </template>
    </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import RsaPaiEditComponent from "../components/RsaPaiEditComponent.vue";

export default {
    components: { SaPageLayout, RsaPaiEditComponent },
    data() {
        return {
            btnNewVisible: true,
            pathResource: "/rsapai",
            btnEditVisible: false,
            // linkback: "/rsagestioneospiti/schedaospite/view/",
            linkback: "/rsapaziente/pai?idPaziente=",

            showModalLoading: false,
            listKey: 0,
            jsonData: {},
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.idAccettazione = this.$route.query.idAccettazione;
        //  me.linkback = "/rsagestioneospiti/schedaospite/view/" + me.idAccettazione;
        me.linkback = "/rsapaziente/pai?idPaziente=" + me.idAccettazione;
    },
    methods: {
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
        },
        onAfterLoadData() {
            let me = this;
            me.showModalLoading = false;
        },
        onBeforeLoadData() {
            let me = this;
            me.showModalLoading = true;
        },
    },
};
</script>
<style></style>
