<template>
    <div>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card sa-card-border" header="Torace" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Conformazione</label>
                            <b-form-input v-model="jsonDataTorace.conformazione" :disabled="isReadOnly"> </b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Espansibilità</label>
                            <b-form-select v-model="jsonDataTorace.espansibilita" :options="espansibilitaOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Fremito Vocale Tattile</label>
                            <b-form-select v-model="jsonDataTorace.fremitoVocaleTattile" :options="fremitoVocaleTattileOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Suono Plessico</label>
                            <b-form-select v-model="jsonDataTorace.suonoPlessico" :options="suonoPlessicoOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Descrizione Suono Plessico</label>
                            <b-form-input v-model="jsonDataTorace.suonoPlessicoDescrizione" :readonly="isReadOnly"> </b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Murmure Vescicolari</label>
                            <b-form-select v-model="jsonDataTorace.murmuraVescicolare" :options="murmuraVescicolareOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Rumori Bronchiali</label>
                            <b-form-select v-model="jsonDataTorace.rumoreBronchiale" :options="rumoreBronchialeOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Descrizione Rumori Bronchiali</label>
                            <b-form-input v-model="jsonDataTorace.rumoreBronchialeDescrizione" :readonly="isReadOnly"> </b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Rumori Polmonari</label>
                            <b-form-select v-model="jsonDataTorace.rumorePolmonare" :options="rumorePolmonareOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Descrizione Rumori Polmonari</label>
                            <b-form-input v-model="jsonDataTorace.rumorePolmonareDescrizione" :readonly="isReadOnly"> </b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Rumori Pleurici</label>
                            <b-form-select v-model="jsonDataTorace.rumorePleurico" :options="rumorePleuricoOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Descrizione Rumori Pleurici</label>
                            <b-form-input v-model="jsonDataTorace.rumorePleuricoDescrizione" :readonly="isReadOnly"> </b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Note</label>
                            <b-form-textarea v-model="jsonDataTorace.noteTorace" :readonly="isReadOnly" max-rows="10" rows="7"> </b-form-textarea>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import UtilityMixin from "../../../utility/UtilityMixin";

export default {
    props: {
        isReadOnly: {
            type: Boolean,
            default: false,
        },
        idAccettazione: {
            type: String,
        },
    },
    mixins: [UtilityMixin],
    data() {
        return {
            pathResourceSchedaEsameObiettivoGeneraleTorace: "/rsaschedaesameobiettivogeneraletorace",
            jsonDataTorace: {
                id: null,
                idAccettazione: null,
                conformazione: null,
                espansibilita: null,
                fremitoVocaleTattile: null,
                suonoPlessico: null,
                suonoPlessicoDescrizione: null,
                murmuraVescicolare: null,
                rumoreBronchiale: null,
                rumoreBronchialeDescrizione: null,
                rumorePolmonare: null,
                rumorePolmonareDescrizione: null,
                rumorePleurico: null,
                rumorePleuricoDescrizione: null,
                noteTorace: null,
            },

            espansibilitaOptions: [
                { text: "SIMMETRICAMENTE NORMOESPANSIBILE", value: "SIMMETRICAMENTE NORMOESPANSIBILE" },
                { text: "RIDOTTA A DX/SX", value: "RIDOTTA A DX/SX" },
            ],
            fremitoVocaleTattileOptions: [
                { text: "NORMOTRASMESSO SU TUTTO L'AMBITO", value: "NORMOTRASMESSO SU TUTTO L'AMBITO" },
                { text: "IPOTRASMESSO", value: "IPOTRASMESSO" },
                { text: "IPERTRASMESSO", value: "IPERTRASMESSO" },
            ],
            suonoPlessicoOptions: [
                { text: "CHIARO POLMONARE SU TUTTO L'AMBITO", value: "CHIARO POLMONARE SU TUTTO L'AMBITO" },
                { text: "IPERFONICO", value: "IPERFONICO" },
                { text: "IPOFONETICO", value: "IPOFONETICO" },
            ],
            murmuraVescicolareOptions: [
                { text: "NORMALE", value: "NORMALE" },
                { text: "AUMENTATO", value: "AUMENTATO" },
                { text: "DIMINUITO", value: "DIMINUITO" },
            ],
            rumoreBronchialeOptions: [
                { text: "ASSENTI", value: "ASSENTI" },
                { text: "RANTOLI (A GROSSE, MEDIE O PICCOLE BOLLE)", value: "RANTOLI (A GROSSE, MEDIE O PICCOLE BOLLE)" },
                { text: "RUMORI SECCHI", value: "RUMORI SECCHI" },
            ],
            rumorePolmonareOptions: [
                { text: "ASSENTI", value: "ASSENTI" },
                { text: "CREPITI", value: "CREPITI" },
            ],
            rumorePleuricoOptions: [
                { text: "ASSENTI", value: "ASSENTI" },
                { text: "SFREGAMENTI", value: "SFREGAMENTI" },
            ],
        };
    },
    methods: {
        loadToraceData() {
            let me = this;
            // console.log("ID accettazione nel loadToraceData: " + me.idAccettazione);
            UtilityMixin.methods.loadDataResources(me.pathResourceSchedaEsameObiettivoGeneraleTorace + "/accettazione/" + me.idAccettazione, {}, me.resultResponseSchedaEsameObiettivoGeneraleToraceSuccess, me.resultResponseSchedaEsameObiettivoGeneraleToraceError);
        },
        resultResponseSchedaEsameObiettivoGeneraleToraceSuccess(response) {
            let me = this;
            me.jsonDataTorace = response.data.data;
            // console.log("ID del record TORACE:", me.jsonDataTorace.id);
        },
        resultResponseSchedaEsameObiettivoGeneraleToraceError(error) {
            let me = this;
            if (error.response && error.response.status === 404) {
                // //console.log("NESSUN record trovato del TORACE per questo id accettazione");
                me.resetForm();
            } else {
                console.error("Errore nel caricamento dati della sezione TORACE:", error);
            }
        },
        resetForm() {
            let me = this;
            me.jsonDataTorace = {
                id: null,
                idAccettazione: me.idAccettazione,
                conformazione: null,
                espansibilita: null,
                fremitoVocaleTattile: null,
                suonoPlessico: null,
                suonoPlessicoDescrizione: null,
                murmuraVescicolare: null,
                rumoreBronchiale: null,
                rumoreBronchialeDescrizione: null,
                rumorePolmonare: null,
                rumorePolmonareDescrizione: null,
                rumorePleurico: null,
                rumorePleuricoDescrizione: null,
                noteTorace: null,
            };
        },

        onSalvaTorace() {
            let me = this;
            me.jsonDataTorace.idAccettazione = me.idAccettazione;
            // console.log("Salvataggio TORACE con dati:", JSON.stringify(me.jsonDataTorace));
            if (!me.jsonDataTorace.idAccettazione) {
                // console.log("idAccettazione: ", me.jsonDataTorace.idAccettazione);
                me.$bvToast.toast("Errore: ID Accettazione mancante", {
                    title: "Errore",
                    variant: "danger",
                    solid: true,
                });
                return;
            }

            UtilityMixin.methods.saveResource(me.pathResourceSchedaEsameObiettivoGeneraleTorace, me.jsonDataTorace, me.saveSchedaEsameObiettivoGeneraleToraceSuccessResponse, me.saveSchedaEsameObiettivoGeneraleToraceErrorResponse);
        },
        saveSchedaEsameObiettivoGeneraleToraceSuccessResponse() {
            //console.log("TORACE salvato con successo:", response);
        },
        saveSchedaEsameObiettivoGeneraleToraceErrorResponse() {
            console.error("Errore nel salvataggio TORACE:");
        },
    },
};
</script>
