<template>
    <!-- <template slot="table-body"> -->
    <div>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Caso di:</label>
                    <span class="sa-data">{{ jsonData.casoMalattia }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Specie e/o Sierotipo:</label>
                    <span class="sa-data">{{ jsonData.casoMalattiaSpecieSierotipo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Lavoro Settore Alimentare:</label>
                    <span class="sa-data">{{ jsonData.lavoroSettoreAlimentare }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Specificare Dove e Occupazione:</label>
                    <span class="sa-data">{{ jsonData.lavoroSettoreAlimentareOccupazione }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Comunita' frequentate 10 giorni precedenti:</label>
                    <span class="sa-data">{{ jsonData.comunitaFrequentateDieciGiorniPrecedenti }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Se Studente Classe e Sezione:</label>
                    <span class="sa-data">{{ jsonData.comunitaFrequentateDieciGiorniPrecedentiStudenteClasseSe }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Frequnza Comunita' Fino Al:</label>
                    <span class="sa-data">{{ formatDate(jsonData.comunitaFrequentateDieciGiorniPrecedentiFinoAl) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="informazioni Cliniche" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Comparsa Sintomi:</label>
                    <span class="sa-data">{{ formatDate(jsonData.sintomiDataComparsa) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Sintomi Ancora Presenti:</label>
                    <span class="sa-data">{{ jsonData.sintomiAncoraPresenti }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Vomito:</label>
                    <span class="sa-data">{{ jsonData.sintomiVomito }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Vomito Data:</label>
                    <span class="sa-data">{{ jsonData.sintomiVomitoDataOra }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Crampi Addominali:</label>
                    <span class="sa-data">{{ jsonData.sintomiCrampiAddome }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Crampi Addominali Data:</label>
                    <span class="sa-data">{{ formatDateTime(jsonData.sintomiCrampiAddomeDataOra) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Diarrea:</label>
                    <span class="sa-data">{{ jsonData.sintomiDiarrea }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Diarrea Data:</label>
                    <span class="sa-data">{{ formatDateTime(jsonData.sintomiDiarreaDataOra) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Diarrea Con Sangue:</label>
                    <span class="sa-data">{{ jsonData.sintomiDiarreaSangue }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Diarrea Con Sangue Data:</label>
                    <span class="sa-data">{{ formatDateTime(jsonData.sintomiDiarreaSangueDataOra) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Diarrea Con Sangue Scariche Giornaliere:</label>
                    <span class="sa-data">{{ jsonData.sintomiDiarreaNumeroScariche }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Diarrea Con Muco:</label>
                    <span class="sa-data">{{ jsonData.sintomiDiarreaMuco }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Diarrea Con Muco Data:</label>
                    <span class="sa-data">{{ formatDate(jsonData.sintomiDiarreaMucoDataOra) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Febbre:</label>
                    <span class="sa-data">{{ jsonData.sintomiFebbre }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Febbre Data:</label>
                    <span class="sa-data">{{ formatDateTime(jsonData.sintomiFebbreDataOra) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Disidratazione:</label>
                    <span class="sa-data">{{ jsonData.sintomiDisidratazione }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Disidratazione Data:</label>
                    <span class="sa-data">{{ formatDateTime(jsonData.sintomiDisidratazioneDataOra) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Mal Di Testa/Astenia:</label>
                    <span class="sa-data">{{ jsonData.sintomiMalTestaAstenia }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Mal Di Testa/Astenia Data:</label>
                    <span class="sa-data">{{ formatDateTime(jsonData.sintomiMalTestaAsteniaDataOra) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Altri Sintomi:</label>
                    <span class="sa-data">{{ jsonData.sintomiAltro }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Sintomo Predominante:</label>
                    <span class="sa-data">{{ jsonData.sintomoPredominante }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Contattato Medico di Base:</label>
                    <span class="sa-data">{{ jsonData.rivoltoMedicoFamiglia }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Contattato Medico di Base Data:</label>
                    <span class="sa-data">{{ formatDate(jsonData.rivoltoMedicoFamigliaQuando) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Diagnosi:</label>
                    <span class="sa-data">{{ jsonData.rivoltoMedicoFamigliaDiagnosiMedico }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Pronto Soccorso:</label>
                    <span class="sa-data">{{ jsonData.andatoProntoSoccorso }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Pronto Soccorso Data:</label>
                    <span class="sa-data">{{ formatDate(jsonData.andatoProntoSoccorsoQuando) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Diagnosi Dimissione:</label>
                    <span class="sa-data">{{ jsonData.andatoProntoSoccorsoDiagnosiDimissione }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Ricovero Ospedale:</label>
                    <span class="sa-data">{{ jsonData.ricoveroOspedale }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Ricovero Ospedaliero Motivo:</label>
                    <span class="sa-data">{{ jsonData.ricoveroOspedaleMotivo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Ricovero Ospedaliero Reparto:</label>
                    <span class="sa-data">{{ jsonData.ricoveroOspedaleReparto }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Pronto Soccorso Data Dimissioni:</label>
                    <span class="sa-data">{{ formatDate(jsonData.ricoveroOspedaleDataDimissioni) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Indagine Laboratorio:</label>
                    <span class="sa-data">{{ jsonData.eseguitoIndagineLaborario }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Indagine Laboratorio Richiedente:</label>
                    <span class="sa-data">{{ jsonData.eseguitoIndagineLaborarioRichiedente }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Indagine Laboratorio Esito:</label>
                    <span class="sa-data">{{ jsonData.eseguitoIndagineLaborarioEsito }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Assunto Antibiotici Prima Dell'Esame:</label>
                    <span class="sa-data">{{ jsonData.primaEsemeAssuntoAntibiotici }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Contatti ed Esposti" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Familiare/Amico/Conoscente Con Medesima Sintomatologia:</label>
                    <span class="sa-data">{{ jsonData.contattiStessaSintomatologia }}</span>
                </b-col>
            </b-row>
            <b-table sticky-header ref="tblViaggi" stacked="xl" striped hover itemscope :items="jsonData.listaContatti" :fields="fieldsContatti" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                <template #cell(index)="jsonData">
                    {{ jsonData.index + 1 }}
                </template>
                <template #cell(cognome)="item">
                    <span class="sa-data">{{ item.item.cognome }}</span>
                </template>
                <template #cell(nome)="item">
                    <span class="sa-data">{{ item.item.nome }}</span>
                </template>
                <template #cell(comune)="item">
                    <span class="sa-data">{{ item.item.comune }}</span>
                </template>
                <template #cell(indirizzo)="item">
                    <span class="sa-data">{{ item.item.indirizzo }}</span>
                </template>
                <template #cell(telefono)="item">
                    <span class="sa-data">{{ item.item.telefono }}</span>
                </template>
            </b-table>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Consumato Pasti/Esperienze Comuni:</label>
                    <span class="sa-data">{{ jsonData.contattiStessaSintomatologiaConsumatoPasti }}</span>
                </b-col>
            </b-row>
            <b-table sticky-header ref="tblViaggi" stacked="xl" striped hover itemscope :items="jsonData.listaEsperienzeComuni" :fields="fieldsEsperienzeComuni" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                <template #cell(index)="jsonData">
                    {{ jsonData.index + 1 }}
                </template>
                <template #cell(pastoEsperienzaComune)="item">
                    <span class="sa-data">{{ item.item.pastoEsperienzaComune }}</span>
                </template>
                <template #cell(quando)="item">
                    <span class="sa-data">{{ item.item.quando }}</span>
                </template>
                <template #cell(dove)="item">
                    <span class="sa-data">{{ item.item.dove }}</span>
                </template>
            </b-table>
        </b-card>
        <b-card class="sa-card" header="Comportamenti" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Viaggi nei 5 Giorni Precedenti:</label>
                    <span class="sa-data">{{ jsonData.viaggiCinqueGiorniPrecedenti }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Dove:</label>
                    <span class="sa-data">{{ jsonData.viaggiCinqueGiorniPrecedentiDove }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Alloggio:</label>
                    <span class="sa-data">{{ jsonData.viaggiCinqueGiorniPrecedentiAlloggio }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Consumato Pasti in Questi Luoghi/Strutture:</label>
                    <span class="sa-data">{{ jsonData.viaggiCinqueGiorniPrecedentiConsumoPasti }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Dove:</label>
                    <span class="sa-data">{{ jsonData.viaggiCinqueGiorniPrecedentiConsumoPastiDove }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Quando:</label>
                    <span class="sa-data">{{ formatDate(jsonData.viaggiCinqueGiorniPrecedentiConsumoPastiQuando) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Altre Pesone con Stessi Sintomi in Struttura:</label>
                    <span class="sa-data">{{ jsonData.viaggiCinqueGiorniPrecedentiSintomiPersone }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Quando:</label>
                    <span class="sa-data">{{ formatDate(jsonData.viaggiCinqueGiorniPrecedentiSintomiPersoneQuando) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Animali Domestici:</label>
                    <span class="sa-data">{{ jsonData.animaliDomestici }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox v-model="jsonData.animaliDomesticiCane" :checked="jsonData.animaliDomesticiCane" switch disabled>Cani</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox v-model="jsonData.animaliDomesticiGatto" :checked="jsonData.animaliDomesticiGatto" switch disabled>Gatti</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox v-model="jsonData.animaliDomesticiUccelli" :checked="jsonData.animaliDomesticiUccelli" switch disabled>Uccelli</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox v-model="jsonData.animaliDomesticiCricetiOrsettiRussi" :checked="jsonData.animaliDomesticiCricetiOrsettiRussi" switch disabled>Criceti/ Orsetti Russi</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox v-model="jsonData.animaliDomesticiTartarughe" :checked="jsonData.animaliDomesticiTartarughe" switch disabled>Tartarughe</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Altro Animale Domestico:</label>
                    <span class="sa-data">{{ jsonData.animaliDomesticiTipoAltro }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Manifestato Diarrea Prima Che si Ammalasse:</label>
                    <span class="sa-data">{{ jsonData.animaliDomesticiDiarrea }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Lavoro a Contatto Con Animali e Feci:</label>
                    <span class="sa-data">{{ jsonData.contattoAnimaliFeci }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Vive in Azienda Agricola/Campagna:</label>
                    <span class="sa-data">{{ jsonData.viveAziendaAgricolaCampagna }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox v-model="jsonData.toccatoAnimaliMucche" :checked="jsonData.toccatoAnimaliMucche" switch disabled>Toccato Mucche</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox v-model="jsonData.toccatoAnimaliPolliGalline" :checked="jsonData.toccatoAnimaliPolliGalline" switch disabled>Toccato Polli/Galline</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox v-model="jsonData.toccatoAnimaliMaiali" :checked="jsonData.toccatoAnimaliMaiali" switch disabled>Toccato Maiali</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox v-model="jsonData.toccatoAnimaliPecore" :checked="jsonData.toccatoAnimaliPecore" switch disabled>Toccato Pecore</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox v-model="jsonData.toccatoAnimaliRoditori" :checked="jsonData.toccatoAnimaliRoditori" switch disabled>Toccato Roditori</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Toccato Altri Animali:</label>
                    <span class="sa-data">{{ jsonData.toccatoAnimaliAltro }}</span>
                </b-col>
            </b-row>
            <h4>Consumo di Acqua</h4>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox v-model="jsonData.tipoAcquaBevutoAcquedottoComunale" :checked="jsonData.tipoAcquaBevutoAcquedottoComunale" switch disabled>Acquedotto Comunale</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox v-model="jsonData.tipoAcquaBevutoAcquaMineraleBottiglia" :checked="jsonData.tipoAcquaBevutoAcquaMineraleBottiglia" switch disabled>Minerale In Bottiglia</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox v-model="jsonData.tipoAcquaBevutoAcquaAcquaSorgene" :checked="jsonData.tipoAcquaBevutoAcquaAcquaSorgene" switch disabled>Sorgente</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox v-model="jsonData.tipoAcquaBevutoAcquaAcquaCorsiSuperficialiNonTrattata" :checked="jsonData.tipoAcquaBevutoAcquaAcquaCorsiSuperficialiNonTrattata" switch disabled>Corsi Superficiali Non Trattata</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox v-model="jsonData.tipoAcquaBevutoAcquaAcquaPozzoNonTrattata" :checked="jsonData.tipoAcquaBevutoAcquaAcquaPozzoNonTrattata" switch disabled>Pozzo Non Trattata</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Pozzo di Sua Proprieta':</label>
                    <span class="sa-data">{{ jsonData.tipoAcquaBevutoCinqueGiorniPrecedentiPozzo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Ultimo Controllo Potabilita':</label>
                    <span class="sa-data">{{ jsonData.tipoAcquaBevutoCinqueGiorniPrecedentiPozzoControlloPota }}</span>
                </b-col>
            </b-row>
            <h4>Acquisto e Consumo di Prodotti Alimentari</h4>
            <b-row>
                <b-table sticky-header ref="tblViaggi" stacked="xl" striped hover itemscope :items="jsonData.listaNegozi" :fields="fieldsNegozi" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                    <template #cell(index)="jsonData">
                        {{ jsonData.index + 1 }}
                    </template>
                    <template #cell(negozio)="item">
                        <span class="sa-data">{{ item.item.negozio }}</span>
                    </template>
                    <template #cell(sempre)="item">
                        <b-form-checkbox v-model="item.item.sempre" :checked="item.item.sempre" switch disabled></b-form-checkbox>
                    </template>
                    <template #cell(spesso)="item">
                        <b-form-checkbox v-model="item.item.spesso" :checked="item.item.spesso" switch disabled></b-form-checkbox>
                    </template>
                    <template #cell(qualcheVolta)="item">
                        <b-form-checkbox v-model="item.item.qualcheVolta" :checked="item.item.qualcheVolta" switch disabled></b-form-checkbox>
                    </template>
                </b-table>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Partecipato Eventi/Ritrovi/Manifestazioni:</label>
                    <span class="sa-data">{{ jsonData.cinqueGiorniPartecipatoEventiRitroviManifestazioni }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Dove:</label>
                    <span class="sa-data">{{ jsonData.cinqueGiorniPartecipatoEventiRitroviManifestazioniDove }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Quando:</label>
                    <span class="sa-data">{{ jsonData.cinqueGiorniPartecipatoEventiRitroviManifestazioniQundo }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Nei Giorni Precedenti l'inizio dei sintomi" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox v-model="jsonData.giorniPrecedentiManeggiatoLavatoCarneCruda" :checked="jsonData.giorniPrecedentiManeggiatoLavatoCarneCruda" switch disabled>Mangiato/Lavato Carne Cruda</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox v-model="jsonData.giorniPrecedentiManeggiatoLavatoUova" :checked="jsonData.giorniPrecedentiManeggiatoLavatoUova" switch disabled>Mangiato/Lavato Uova</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox v-model="jsonData.giorniPrecedentiManeggiatoLavatoVerduraNonLavata" :checked="jsonData.giorniPrecedentiManeggiatoLavatoVerduraNonLavata" switch disabled>Mangiato/Lavato Verdura non Lavata</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox v-model="jsonData.giorniPrecedentiManeggiatoLavatoPollo" :checked="jsonData.giorniPrecedentiManeggiatoLavatoPollo" switch disabled>Mangiato/Lavato Pollo</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Utilizzato Stessi Utensili per Alimenti Diversi:</label>
                    <span class="sa-data">{{ jsonData.giorniPrecedentiUtilizzoStessiUtensiliDiversiAlimenti }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Quali:</label>
                    <span class="sa-data">{{ jsonData.giorniPrecedentiUtilizzoStessiUtensiliDiversiAlimentiDes }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Indagine Consumo Alimenti Carne" header-tag="header" footer-tag="footer" title="">
            <indagine-epidemiologica-infezione-alimentare-alimenti-component :isEdit="false" :listaAlimentiConsumati="jsonData.listaAlimentiConsumatiCarne" famigliaAlimenti="CARNE" @update="onUpdateJsonDataListaAlimenti"></indagine-epidemiologica-infezione-alimentare-alimenti-component>
        </b-card>
        <b-card class="sa-card" header="Indagine Consumo Alimenti Pesce" header-tag="header" footer-tag="footer" title="">
            <indagine-epidemiologica-infezione-alimentare-alimenti-component :isEdit="false" :listaAlimentiConsumati="jsonData.listaAlimentiConsumatiPesce" famigliaAlimenti="PESCE" @update="onUpdateJsonDataListaAlimenti"></indagine-epidemiologica-infezione-alimentare-alimenti-component>
        </b-card>
        <b-card class="sa-card" header="Indagine Consumo Alimenti Uova" header-tag="header" footer-tag="footer" title="">
            <indagine-epidemiologica-infezione-alimentare-alimenti-component :isEdit="false" :listaAlimentiConsumati="jsonData.listaAlimentiConsumatiUova" famigliaAlimenti="UOVA" @update="onUpdateJsonDataListaAlimenti"></indagine-epidemiologica-infezione-alimentare-alimenti-component>
        </b-card>
        <b-card class="sa-card" header="Indagine Consumo Alimenti Latte/Latticini" header-tag="header" footer-tag="footer" title="">
            <indagine-epidemiologica-infezione-alimentare-alimenti-component :isEdit="false" :listaAlimentiConsumati="jsonData.listaAlimentiConsumatiLatteLatticini" famigliaAlimenti="LATTE/LATTICINI" @update="onUpdateJsonDataListaAlimenti"></indagine-epidemiologica-infezione-alimentare-alimenti-component>
        </b-card>
        <b-card class="sa-card" header="Indagine Consumo Alimenti Verdure" header-tag="header" footer-tag="footer" title="">
            <indagine-epidemiologica-infezione-alimentare-alimenti-component :isEdit="false" :listaAlimentiConsumati="jsonData.listaAlimentiConsumatiVerdure" famigliaAlimenti="VERDURE" @update="onUpdateJsonDataListaAlimenti"></indagine-epidemiologica-infezione-alimentare-alimenti-component>
        </b-card>
        <b-card class="sa-card" header="Indagine Consumo Alimenti Prodotti dolci da forno" header-tag="header" footer-tag="footer" title="">
            <indagine-epidemiologica-infezione-alimentare-alimenti-component :isEdit="false" :listaAlimentiConsumati="jsonData.listaAlimentiConsumatiProdottiDolciForno" famigliaAlimenti="PRODOTTI DOLCI E DA FORNO" @update="onUpdateJsonDataListaAlimenti"></indagine-epidemiologica-infezione-alimentare-alimenti-component>
        </b-card>
        <b-card class="sa-card" header="Indagine Consumo Alimenti ALIMENTI PRONTI AL CONSUMO/  ALIMENTI TRAFORMATI REFRIGERATI CON DURATA PROLUNGATA" header-tag="header" footer-tag="footer" title="">
            <indagine-epidemiologica-infezione-alimentare-alimenti-component :isEdit="false" :listaAlimentiConsumati="jsonData.listaAlimentiConsumatiProdottiProntiCosumo" famigliaAlimenti="ALIMENTI PRONTI AL CONSUMO/  ALIMENTI TRAFORMATI REFRIGERATI CON DURATA PROLUNGATA" @update="onUpdateJsonDataListaAlimenti"></indagine-epidemiologica-infezione-alimentare-alimenti-component>
        </b-card>
        <b-card class="sa-card" header="Conclusioni ed ulteriori azioni" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <b-form-checkbox v-model="jsonData.azioniIntraprendereAlimenti" :checked="jsonData.azioniIntraprendereAlimenti" switch disabled>Da Alimenti</b-form-checkbox>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Alimento Sospetto:</label>
                    <span class="sa-data">{{ jsonData.azioniIntraprendereAlimentiSospetto }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Comportamenti a Rischio:</label>
                    <span class="sa-data">{{ jsonData.azioniIntraprendereAlimentiComportamentiRischioPreparazion }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <b-form-checkbox v-model="jsonData.azioniIntraprendereNonAlimenti" :checked="jsonData.azioniIntraprendereNonAlimenti" switch disabled>Non Da Alimenti</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Dettaglio:</label>
                    <span class="sa-data">{{ jsonData.azioniIntraprendereNonAlimentiTipo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Ulteriori Azioni:</label>
                    <span class="sa-data">{{ jsonData.ulterioreAzione }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Data:</label>
                    <span class="sa-data">{{ formatDate(jsonData.ulterioreAzioneData) }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Altre Considerazioni</label>
                    <span class="sa-data">{{ jsonData.altreConsiderazioni }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Dati Notifica" header-tag="header" footer-tag="footer" title="">
            <indagine-epidemiologica-dati-notifica-component :jsonData="jsonData" :isEdit="false" @update="onUpdateJsonData" :disabled="chekCondition(jsonData.ricoveroTerapiaIntensiva, 'SI')"></indagine-epidemiologica-dati-notifica-component>
        </b-card>
    </div>
    <!-- </template> -->
</template>
<script>
import axios from "axios";
import UtilityMixin from "../../../utility/UtilityMixin";
import IndagineEpidemiologicaInfezioneAlimentareAlimentiComponent from "../components/IndagineEpidemiologicaInfezioneAlimentareAlimentiComponent.vue";
import IndagineEpidemiologicaDatiNotificaComponent from "./IndagineEpidemiologicaDatiNotificaComponent.vue";
export default {
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    components: { IndagineEpidemiologicaDatiNotificaComponent, IndagineEpidemiologicaInfezioneAlimentareAlimentiComponent }, //, DatePicker
    mixins: [UtilityMixin],
    data() {
        return {
            pathResource: "/malattieinfettiveieinfezionialimentari",
            linkback: null,
            currentPage: 1,
            perPage: 100,
            showModalLoading: false,
            disableSintomi: true,
            jsonData: {
                idAnagrafica: "",
                casoMalattia: "",
                casoMalattiaSpecieSierotipo: "",
                lavoroSettoreAlimentare: "",
                lavoroSettoreAlimentareOccupazione: "",
                comunitaFrequentateDieciGiorniPrecedenti: "",
                comunitaFrequentateDieciGiorniPrecedentiStudenteClasseSe: "",
                comunitaFrequentateDieciGiorniPrecedentiFinoAl: null,
                sintomiDataComparsa: null,
                sintomiAncoraPresenti: "",
                sintomiVomito: "",
                sintomiVomitoDataOra: null,
                sintomiCrampiAddome: "",
                sintomiCrampiAddomeDataOra: null,
                sintomiDiarrea: "",
                sintomiDiarreaDataOra: null,
                sintomiDiarreaSangue: "",
                sintomiDiarreaSangueDataOra: null,
                sintomiDiarreaNumeroScariche: "",
                sintomiDiarreaMuco: "",
                sintomiDiarreaMucoDataOra: null,
                sintomiFebbre: "",
                sintomiFebbreDataOra: null,
                sintomiDisidratazione: "",
                sintomiDisidratazioneDataOra: null,
                sintomiMalTestaAstenia: "",
                sintomiMalTestaAsteniaDataOra: null,
                sintomiAltro: "",
                sintomoPredominante: "",
                rivoltoMedicoFamiglia: "",
                rivoltoMedicoFamigliaQuando: null,
                rivoltoMedicoFamigliaDiagnosiMedico: "",
                andatoProntoSoccorso: "",
                andatoProntoSoccorsoQuando: null,
                andatoProntoSoccorsoDiagnosiDimissione: "",
                ricoveroOspedale: "",
                ricoveroOspedaleMotivo: "",
                ricoveroOspedaleReparto: "",
                ricoveroOspedaleDataDimissioni: null,
                eseguitoIndagineLaborario: "",
                eseguitoIndagineLaborarioRichiedente: "",
                eseguitoIndagineLaborarioEsito: "",
                primaEsemeAssuntoAntibiotici: "",
                contattiStessaSintomatologia: "",
                contattiStessaSintomatologiaConsumatoPasti: "",
                viaggiCinqueGiorniPrecedenti: "",
                viaggiCinqueGiorniPrecedentiDove: "",
                viaggiCinqueGiorniPrecedentiAlloggio: "",
                viaggiCinqueGiorniPrecedentiConsumoPasti: "",
                viaggiCinqueGiorniPrecedentiConsumoPastiDove: "",
                viaggiCinqueGiorniPrecedentiConsumoPastiQuando: null,
                viaggiCinqueGiorniPrecedentiSintomiPersone: "",
                viaggiCinqueGiorniPrecedentiSintomiPersoneQuando: "",
                animaliDomestici: "",
                animaliDomesticiCane: false,
                animaliDomesticiGatto: false,
                animaliDomesticiUccelli: false,
                animaliDomesticiCricetiOrsettiRussi: false,
                animaliDomesticiTartarughe: false,
                animaliDomesticiTipoAltro: "",
                animaliDomesticiDiarrea: "",
                contattoAnimaliFeci: "",
                viveAziendaAgricolaCampagna: "",
                toccatoAnimaliMaiali: false,
                toccatoAnimaliMucche: false,
                toccatoAnimaliPecore: false,
                toccatoAnimaliPolliGalline: false,
                toccatoAnimaliRoditori: false,
                toccatoAnimaliAltro: "",
                tipoAcquaBevutoAcquedottoComunale: false,
                tipoAcquaBevutoAcquaMineraleBottiglia: false,
                tipoAcquaBevutoAcquaAcquaSorgene: false,
                tipoAcquaBevutoAcquaAcquaCorsiSuperficialiNonTrattata: false,
                tipoAcquaBevutoAcquaAcquaPozzoNonTrattata: false,
                tipoAcquaBevutoCinqueGiorniPrecedentiPozzo: "",
                tipoAcquaBevutoCinqueGiorniPrecedentiPozzoControlloPota: "",
                cinqueGiorniPartecipatoEventiRitroviManifestazioni: "",
                cinqueGiorniPartecipatoEventiRitroviManifestazioniDove: "",
                cinqueGiorniPartecipatoEventiRitroviManifestazioniQundo: "",
                giorniPrecedentiManeggiatoLavatoCarneCruda: false,
                giorniPrecedentiManeggiatoLavatoUova: false,
                giorniPrecedentiManeggiatoLavatoVerduraNonLavata: false,
                giorniPrecedentiManeggiatoLavatoPollo: false,
                giorniPrecedentiUtilizzoStessiUtensiliDiversiAlimenti: "",
                giorniPrecedentiUtilizzoStessiUtensiliDiversiAlimentiDes: "",
                note: "",
                azioniIntraprendereAlimenti: false,
                azioniIntraprendereAlimentiSospetto: "",
                azioniIntraprendereAlimentiComportamentiRischioPreparazion: "",
                azioniIntraprendereNonAlimenti: false,
                azioniIntraprendereNonAlimentiTipo: "",
                ulterioreAzione: "",
                ulterioreAzioneData: null,
                sanitarioNotificante: "",
                sanitarioNotificanteContatto: "",
                dataNotifica: null,
                listaAlimentiConsumatiCarne: [],
                listaAlimentiConsumatiPesce: [],
                listaAlimentiConsumatiUova: [],
                listaAlimentiConsumatiLatteLatticini: [],
                listaAlimentiConsumatiVerdure: [],
                listaAlimentiConsumatiProdottiDolciForno: [],
                listaAlimentiConsumatiProdottiProntiCosumo: [],
                listaContatti: [],
                listaEsperienzeComuni: [],
                listaNegozi: [{ negozio: "", sempre: false, spesso: false, qualcheVolta: false }],
            },
            fieldsContatti: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Cognome",
                    key: "cognome",
                    thStyle: "width: 20rem",
                    sortable: false,
                },
                {
                    label: "Nome",
                    key: "nome",
                    thStyle: "width: 20rem",
                    sortable: false,
                },
                {
                    label: "Comune",
                    key: "comune",
                    thStyle: "width: 10rem",
                    sortable: false,
                },
                {
                    label: "Indirizzo",
                    key: "indirizzo",
                    // thStyle: "width: 5rem",
                    sortable: false,
                },
                {
                    label: "Telefono",
                    key: "telefono",
                    // thStyle: "width: 5rem",
                    sortable: false,
                },
            ],
            fieldsEsperienzeComuni: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Pasto/Esperienza Comune",
                    key: "pastoEsperienzaComune",
                    thStyle: "width: 11rem",
                    sortable: false,
                },
                {
                    label: "Quando",
                    key: "quando",
                    thStyle: "width: 11rem",
                    sortable: false,
                },
                {
                    label: "Dove",
                    key: "dove",
                    thStyle: "width: 11rem",
                    sortable: false,
                },
                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 4rem",
                    tdClass: "text-center",
                },
            ],
            fieldsNegozi: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Negozio",
                    key: "negozio",
                    // thStyle: "width: 11rem",
                    sortable: false,
                },
                {
                    label: "Sempre",
                    key: "sempre",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
                {
                    label: "Spesso",
                    key: "spesso",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
                {
                    label: "Qualche Volta",
                    key: "qualcheVolta",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 4rem",
                    tdClass: "text-center",
                },
            ],
            fieldsAlimenti: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Alimento",
                    key: "alimento",
                    thStyle: "width: 11rem",
                    sortable: false,
                },
                {
                    label: "Sottotipo/Modalita",
                    key: "sottotipoModalita",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
                {
                    label: "Marca",
                    key: "marca",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
                {
                    label: "L. Acquisto",
                    key: "luogoAcquisto",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
                {
                    label: "D. Acquisto",
                    key: "dataAcquisto",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
                {
                    label: "L. Preparazione",
                    key: "luogoPreparazione",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
                {
                    label: "D. Preparazione",
                    key: "dataPreparazione",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
                {
                    label: "L. Consumo",
                    key: "luogoConsumo",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
                {
                    label: "D. Consumo",
                    key: "dataConsumo",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
                {
                    label: "Tipologia",
                    key: "tipologia",
                    thStyle: "width: 6rem",
                    sortable: false,
                },
            ],
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient() {
            return UtilityMixin.methods.getLinkPatient(this.utente, this.idPaziente);
        },
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.jsonData.idAnagrafica = me.idPaziente;
        // let malattiaInfettivaSegnalazione = sessionStorage.getItem("MalattiaInfettivaSegnalazione");
        // let malattiaInfettiva = {};
        // if (malattiaInfettivaSegnalazione !== null && malattiaInfettivaSegnalazione !== "null") {
        //   malattiaInfettiva = JSON.parse(malattiaInfettivaSegnalazione);
        //   me.setDatiMalattia(malattiaInfettiva);
        // }
        // sessionStorage.setItem("MalattiaInfettivaSegnalazione", null);
        me.linkback = "/malattieinfettive/segnalazioni";

        me.loadData();
    },
    watch: {},
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            me.showModalLoading = true;
            axios
                .get(link + me.id)
                .then((response) => {
                    me.jsonData = response.data.data;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
        },
        onUpdateJsonDataListaAlimenti(famiglia, data) {
            let me = this;
            switch (famiglia) {
                case "CARNE":
                    me.jsonData.listaAlimentiConsumatiCarne = data;
                    break;
                case "PESCE":
                    me.jsonData.listaAlimentiConsumatiPesce = data;
                    break;
                case "UOVA":
                    me.jsonData.listaAlimentiConsumatiUova = data;
                    break;
                case "LATTE/LATTICINI":
                    me.jsonData.listaAlimentiConsumatiLatteLatticini = data;
                    break;
                case "VERDURE":
                    me.jsonData.listaAlimentiConsumatiVerdure = data;
                    break;
                case "PRODOTTI DOLCI E DA FORNO":
                    me.jsonData.listaAlimentiConsumatiProdottiDolciForno = data;
                    break;
                case "ALIMENTI PRONTI AL CONSUMO/  ALIMENTI TRAFORMATI REFRIGERATI CON DURATA PROLUNGATA":
                    me.jsonData.listaAlimentiConsumatiProdottiProntiCosumo = data;
                    break;
            }
        },
    },
};
</script>
