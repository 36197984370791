<template>
  <div class="form-group">
    <div class="sa-div-scroll sa-scroll">
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="4" xl="4">
          <span class="sa-form-label">Codice</span>
          <span class="sa-label-data">{{ jsonData.codice }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="4" xl="4">
          <span class="sa-form-label">Descrizione</span>
          <span class="sa-label-data">{{ jsonData.descrizione }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="4" xl="4">
          <span class="sa-form-label">Categoria</span>
          <span class="sa-label-data">{{ jsonData.categoria }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="6" xl="6">
          <span class="sa-form-label">Quantita'</span>
          <span class="sa-label-data">{{ jsonData.quantita }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="6" xl="6">
          <span class="sa-form-label">Prezzo</span>
          <span class="sa-label-data">{{ jsonData.prezzo }}</span>
        </b-col>
        <!-- <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
          <span class="sa-form-label">Comune</span>
          <span class="sa-label-data"> {{ jsonData.comuneLabel }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
          <span class="sa-form-label">Indirizzo</span>
          <span class="sa-label-data"> {{ jsonData.indirizzo }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
          <span class="sa-form-label">C.A.P.</span>
          <span class="sa-label-data"> {{ jsonData.cap }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
          <span class="sa-form-label">Latitudine</span>
          <span class="sa-label-data"> {{ jsonData.latitudine }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
          <span class="sa-form-label">Longitudine</span>
          <span class="sa-label-data"> {{ jsonData.longitudine }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
          <span class="sa-form-label">Stato</span>
          <span class="sa-label-data"> {{ jsonData.stato }}</span>
        </b-col> -->
      </b-row>
    </div>
  </div>
</template>

<script>
import SaPageLayout from "../../../template/layout/components/SaPageLayout.vue";
import axios from "axios";
export default {
  props: {
    jsonData: {
      type: Object,
      default: function () {
        return {};
      },
    },
    components: { SaPageLayout },
    data() {
      return {
        regioniOptions: [],
        provinceOptions: [{ provincia: "-Seleziona Provincia-", sigla: "" }],
        comuniOptions: [{ comune: "-Seleziona Comune-", codiceIstat: "" }],
        statiOptions: [
          { text: "ATTIVO", field: "ATTIVO" },
          { text: "SOSPESO", field: "SOSPESO" },
          { text: "CHIUSO", field: "CHIUSO" },
        ],
      };
    },
    mounted: function () {
      let me = this;
      me.id = this.$route.params.id;
      me.linkedit = "/clienti/edit/" + me.id;
      me.loadData();
    },
    methods: {
      loadData() {
        let me = this;
        let link = process.env.VUE_APP_PATH_API + "/clienti/" + me.id;
        axios.get(link).then((response) => {
          me.jsonData = response.data.data;
        });
      },
    },
  },
};
</script>

<style></style>
