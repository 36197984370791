<template>
    <sa-page-layout :btnConfigurazioniVisible="true" :btnNewVisible="btnNewVisible" :btnRefreshVisible="true" @refresh="onRefresh" :pathResource="pathResource" :linkedit="linkedit" :linkback="linkback" :showModalLoading="showModalLoading">
        <template slot="table-filter">
            <b-form @submit.prevent="onSubmit">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-label-data">Codice Laboratorio:</label>
                        <b-form-input v-model="filtro.codiceLaboratorio" type="search" id="codice" placeholder="Codice Laboratorio"></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-label-data">Descrizione Laboratorio:</label>
                        <b-form-input v-model="filtro.descrizioneLaboratorio" type="search" id="descrizione" placeholder="Descrizione Laboratorio"></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-label-data">Codice Presidio:</label>
                        <b-form-select id="inline-form-custom-select-pref" v-model="filtro.codicePresidio" class="mb-2 mr-sm-2 mb-sm-0" :value="null" value-field="id" text-field="denominazione" :options="presidiOptions"></b-form-select>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="sa-padding-right pt-3 pb-3 text-right">
                        <b-button type="submit" variant="info">{{ this.$i18n.t("patients.lblFind") }}</b-button>
                        <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("patients.lblResetFilter") }}</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </template>
        <template slot="table-body">
            <rsa-configurazione-laborati-list-component ref="RsaConfigurazioneLaboratoriListComponent" @afterLoadData="onAfterLoadData"></rsa-configurazione-laborati-list-component>
        </template>
    </sa-page-layout>
</template>

<script>
import axios from "axios";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import RsaConfigurazioneLaboratiListComponent from "../components/RsaConfigurazioneLaboratiListComponent.vue";
export default {
    components: { SaPageLayout, RsaConfigurazioneLaboratiListComponent },
    data() {
        return {
            filtro: { codiceLaboratorio: null, descrizioneLaboratorio: null, codicePresidio: null },
            btnNewVisible: true,
            btnBackVisible: true,
            linkback: "/home",
            pathResource: "/rsalaboratori",
            linkedit: "/rsaconfigurazionelaboratori",
            pathResourcePresidi: "/strutturepresidi",
            showModalLoading: false,
            listKey: 0,
            presidiOptions: [],
        };
    },
    mounted() {
        let me = this;
        me.loadData();
        me.loadPresidi();
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            me.$refs.RsaConfigurazioneLaboratoriListComponent.loadData();
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        onAfterLoadData() {
            let me = this;
            me.showModalLoading = false;
        },
        onReset() {
            let me = this;
            me.filtro = { codiceLaboratorio: null, descrizioneLaboratorio: null, codicePresidio: null };
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
        onSubmit() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
        loadPresidi() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourcePresidi;
            me.presidiOptions = [];

            axios
                .get(link)
                .then((response) => {
                    me.presidiOptions = response.data.data.list;
                    me.presidiOptions.unshift({
                        id: null,
                        denominazione: "-Seleziona Presidio-",
                    });
                    this.$emit("afterLoadData");
                })
                .catch(() => {
                    this.$emit("afterLoadData");
                });
        },
    },
};
</script>

<style></style>
