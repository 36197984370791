<template>
  <div style="float: right">
    <b-button class="primary" variant="btn btn-outline-info btn-toolbar" @click="onOpenModal">
      <b-icon icon="file-earmark-plus" aria-hidden="true"></b-icon>
      Altre Lingue
    </b-button>
    <b-modal ref="mdlConclusioni" id="mdlConclusioni" size="xl" style="height: 100%" @ok="onOkMdlLingue" title="Gestione Dizionario">
      <b-form-textarea v-model="value" no-resize rows="6" style="margin-top: 5px"></b-form-textarea>
      <!-- <b-input-group style="margin-top: 5px">
        <b-form-checkbox :checked="getCheckedValue(perTutti)" switch @input="onInputCheckbox($event)">Per Tutti</b-form-checkbox>
        <b-button variant="outline-secondary" style="margin-right: 5px" size="sm" @click="onClickAggiungiValore(value)"><b-icon icon="plus"></b-icon></b-button>
      </b-input-group> -->
      <div class="table-header">
        <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col cols="6">
            <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
          </b-col>
          <b-col cols="6">
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
          </b-col>
        </b-row>
      </div>
      <div class="b-table-sticky-header">
        <b-table ref="tblDizionari" selectable sticky-header stacked="xl" striped hover itemscope :items="lingueOptions" :current-page="1" :per-page="perPage" :fields="fields" sort-icon-left head-variant="light" class="sa-b-table" @row-selected="onRowSelected">
          <template #cell(selected)="{ rowSelected }">
            <template v-if="rowSelected">
              <span aria-hidden="true">&check;</span>
              <span class="sr-only">Selected</span>
            </template>
            <template v-else>
              <span aria-hidden="true">&nbsp;</span>
              <span class="sr-only">Not selected</span>
            </template>
          </template>
          <template #cell(index)="row">
            {{ row.index + 1 }}
          </template>
          <!-- <template #cell(actions)="row">
            <b-button variant="outline-secondary" size="sm" @click="onDeleteValore(row.item)"><b-icon icon="trash"></b-icon></b-button>
          </template> -->
        </b-table>
      </div>
      <div class="table-footer">
        <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col cols="6">
            <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
          </b-col>
          <b-col cols="6">
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      pathResource: "/lingue",
      lingueOptions: [],
      value: null,
      valoreLingue: [],
      fields: [
        {
          label: "Descrizione",
          key: "descrizione",
          sortable: true,
        },
        {
          label: "",
          key: "actions",
          tdClass: "text-center",
          thStyle: "width: 7rem",
        },
      ],
      rows: 0,
      currentPage: 1,
      perPage: 10,
    };
  },
  watch: {
    value: {
      handler(value) {
        this.$emit("update", value);
      },
      deep: true,
    },
    currentPage() {
      let me = this;
      me.loadData();
    },
  },
  mounted() {
    let me = this;
    me.loadData();
  },
  methods: {
    // loadDefaultData() {
    //   let me = this;
    //   me.loadData();
    // },
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource;
      me.lingueOptions = [];
      axios.get(link).then((response) => {
        me.lingueOptions = response.data.data.list;
        me.rows = response.data.data.recordsNumber;
        me.showModalLoading = false;
      });
    },
    // onClickAggiungiValore(value) {
    //   let me = this;
    //   let link = process.env.VUE_APP_PATH_API + me.pathResource;
    //   let jsonData = { tipoEvento: me.tipoEvento, gruppo: me.gruppo, tipoDizionario: me.tipoDizionario, descrizione: value, utente: me.perTutti };
    //   axios
    //     .post(link, JSON.stringify(jsonData))
    //     .then((response) => {
    //       this.$bvModal.msgBoxOk(response.data.messaggio, {
    //         title: "",
    //         size: "sm",
    //         okVariant: "outline-danger",
    //         headerClass: "sa-msg-header-danger",
    //         footerClass: "p-2 border-top-0",
    //         centered: true,
    //       });
    //       me.loadData();
    //     })
    //     .catch((e) => {
    //       console.log(e);
    //       this.$bvModal
    //         .msgBoxOk(e.response.data.messaggio, {
    //           title: "ATTENZIONE",
    //           size: "sm",
    //           okVariant: "outline-danger",
    //           headerClass: "sa-msg-header-danger",
    //           footerClass: "p-2 border-top-0",
    //           centered: true,
    //         })
    //         .then((value) => {
    //           this.boxTwo = value;
    //         });
    //     });
    // },
    // onDeleteValore(elemento) {
    //   let me = this;
    //   this.$bvModal
    //     .msgBoxConfirm("Sei sicuro di procedere alla cancellazione?", {
    //       title: "Conferma Cancellazione",
    //       size: "sm",
    //       buttonSize: "sm",
    //       okVariant: "secondary",
    //       cancelVariant: "danger",
    //       okTitle: "SI",
    //       cancelTitle: "NO",
    //       footerClass: "p-2",
    //       hideHeaderClose: false,
    //       centered: true,
    //     })
    //     .then((value) => {
    //       if (value) {
    //         // console.log(value);
    //         let link = process.env.VUE_APP_PATH_API + this.pathResource;
    //         axios.delete(link + "/" + elemento.id).then((response) => {
    //           this.$bvModal.msgBoxOk(response.data.messaggio, {
    //             title: "",
    //             size: "sm",
    //             okVariant: "outline-danger",
    //             headerClass: "sa-msg-header-danger",
    //             footerClass: "p-2 border-top-0",
    //             centered: true,
    //           });
    //           me.loadData();
    //         });
    //       }
    //     })
    //     .catch((e) => {
    //       this.$bvModal
    //         .msgBoxOk(e.response.data.messaggio, {
    //           title: "ATTENZIONE",
    //           size: "sm",
    //           okVariant: "outline-danger",
    //           headerClass: "sa-msg-header-danger",
    //           footerClass: "p-2 border-top-0",
    //           centered: true,
    //         })
    //         .then((value) => {
    //           this.boxTwo = value;
    //         });
    //     });
    // },
    getCheckedValue(value) {
      return value === "PERTUTTI";
    },
    onInputCheckbox(event) {
      let me = this;
      me.perTutti = event ? "PERTUTTI" : null;
    },
    onReset() {
      let me = this;
      me.filtro.descrizione = null;
      me.loadData();
    },
    onSubmit() {
      let me = this;
      me.loadData();
    },
    onRowSelected(item) {
      let me = this;
      console.log(item);
      me.valoreLingue = item;
    },
    onOkMdlLingue() {
      let me = this;
      me.valoreLingue.forEach((element) => {
        me.value = me.value !== null ? me.value + ", " + element.descrizione : element.descrizione;
      });
      me.$emit("update", me.value);
    },
    onOpenModal() {
      let me = this;
      me.loadData();
      me.$refs.mdlConclusioni.show();
    },
  },
};
</script>

<style></style>
