<template>
    <sa-page-layout :btnNewVisible="false" :btnRefreshVisible="true" :btnBackVisible="false" @refresh="onRefresh" :pathResource="pathResource" :linkedit="linkedit" :linkback="linkback" :showModalLoading="showModalLoading">
        <template slot="toolbar">
            <print-component v-if="jsonDataEmergenzaInCorso !== null" :data="jsonDataEmergenzaInCorso" :linkPrintData="linkPrintData(jsonDataEmergenzaInCorso)" typeButton="normal"></print-component>
            <b-button variant="btn btn-outline-danger btn-toolbar sa-margin-right float-sm-right" size="sm" :disabled="isEmengency" @click="onClickAttivaEmergenza">
                <b-icon icon="exclamation-triangle"></b-icon>
                Attiva Emergenza
            </b-button>
            <b-button variant="btn btn-outline-success btn-toolbar sa-margin-right float-sm-right" size="sm" :disabled="!isEmengency" @click="onClickChiudiEmergenza">
                <b-icon icon="exclamation-triangle"></b-icon>
                Chiudi Emergenza
            </b-button>
        </template>
        <template slot="table-filter">
            <b-alert :show="isEmengency" variant="danger">EMERGENZA IN CORSO{{ textEmengency }}</b-alert>
            <b-form @submit.prevent="onSubmit">
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Cognome</label>
                        <b-form-input v-model="filtro.cognome" type="search" id="cognome" placeholder="Cognome"></b-form-input>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Nome</label>
                        <b-form-input v-model="filtro.nome" type="search" id="nome" placeholder="Nome"></b-form-input>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Codice Fiscale</label>
                        <b-form-input v-model="filtro.codiceFiscale" type="search" id="codiceFiscale" placeholder="Codice Fiscale"></b-form-input>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Punto d'Incontro:</label>
                        <b-form-select v-model="filtro.puntoIncontro" :options="listaPuntiIncontroOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="sa-padding-right text-right">
                        <b-button type="submit" variant="info">{{ this.$i18n.t("patients.lblFind") }}</b-button>
                        <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("patients.lblResetFilter") }}</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </template>
        <template slot="table-body">
            <div style="height: 100%; overflow: auto">
                <b-tabs id="rsaAccettazioneEditTabComponent" nav-wrapper-class="sa-tab-items" content-class="mt-3" ref="tabs" class="sa-tab" style="height: 100%; padding-bottom: 25px" fill>
                    <b-tab title="Emergenza in Corso" active>
                        <div v-if="isEmengency" class="b-table-sticky-header">
                            <b-table sticky-header ref="table" :filter="filter" stacked="lg" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                                <!-- <template v-slot:cell(nominativo)="{ item }">
                                    <span>{{ item.assistitoCognome }} {{ item.assistitoNome }}</span>
                                </template> -->
                                <template v-slot:cell(nominativo)="{ item }">
                                    <router-link class="sa-edit-link" :to="'/schedavalutazioneesigenzeimmediate/view/' + item.id">{{ item.assistitoCognome }} {{ item.assistitoNome }}</router-link>
                                </template>
                                <template v-slot:cell(codiceValutazione)="{ item }">
                                    <span :class="getCodiceValutazionecolor(item.codiceValutazione)">{{ item.codiceValutazione }}</span>
                                </template>
                                <template v-slot:cell(actions)="{ item }">
                                    <b-button v-if="isPresent(item)" variant="outline">
                                        <font-awesome-icon icon="check" class="text-success" />
                                    </b-button>
                                    <b-button v-else v-b-tooltip.hover size="sm" variant="outline-danger" title="Segnala Presenza" @click="segnalaPresenza(item)" class="mr-1 no-text">
                                        <b-icon icon="check2" variant="outline-danger"></b-icon>
                                    </b-button>
                                    <b-button v-if="item.statoFirma !== 'NON FIRMATA'" size="sm" variant="btn btn-purple waves-effect  waves-light width-sm no-text" style="margin-right: 3px" @click="onPrintPreview(item)">
                                        <b-icon icon="printer"></b-icon>
                                    </b-button>
                                    <b-button v-else size="sm" v-b-tooltip.hover title="Valida Scheda" class="btn btn-outline-info waves-effect waves-light width-sm no-text" variant="outline-secondary sa-margin-left" @click="onValida(item.id)">
                                        <b-icon icon="vector-pen"></b-icon>
                                    </b-button>
                                </template>
                            </b-table>
                        </div>
                        <!-- <div v-if="isEmengency" style="display: table; height: 100%; width: 100%"><p style="text-align: center; vertical-align: middle; display: table-cell; font-weight: bold; font-style: italic; font-size: 1.5rem">Emergenza</p></div> -->

                        <div v-else style="display: table; height: 100%; width: 100%"><p style="text-align: center; vertical-align: middle; display: table-cell; font-weight: bold; font-style: italic; font-size: 1.5rem">Non ci sono emergenze in corso</p></div>
                    </b-tab>
                    <b-tab title="Storico delle Emergenze"></b-tab>
                </b-tabs>
            </div>
            <b-modal ref="mdlValidazione" title="Valida" @hidden="onHidden" @ok="onOk">
                <form ref="form" @submit.stop.prevent="onValidaSubmit">
                    <b-form-group label="Password" label-for="name-input" invalid-feedback="Name is required" :state="passwordState">
                        <b-form-input type="password" v-model="password" :state="passwordState" required placeholder="Password"></b-form-input>
                    </b-form-group>
                </form>
            </b-modal>
            <b-modal ref="mdlGestioneEmergenzaEdit" id="mdlGestioneEmergenzaEdit" title="Attiva Emergenza" @ok="onGestioneEmergenzaEditSave" scrollable :no-close-on-backdrop="true" ok-title="Attiva" ok-variant="danger">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <label class="sa-label-data">Data Ora:</label>
                        <date-picker format="DD/MM/YYYY" value-type="timestamp" v-model="jsonDataGestioneEmergenza.dataInizio" type="date"></date-picker>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <label class="sa-label-data">Descrizione:</label>
                        <b-form-textarea rows="7" v-model="jsonDataGestioneEmergenza.descrizione"></b-form-textarea>
                    </b-col>
                </b-row>
            </b-modal>
            <b-modal ref="mdlGestioneEmergenzaPresent" id="mdlGestioneEmergenzaPresent" title="Punto Incontro" scrollable :no-close-on-backdrop="true" ok-title="Conferma" ok-variant="danger" @show="onShowGestioneEmergenzaSetPresent" @ok="onGestioneEmergenzaSetPresent">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <label class="sa-label-data">Selezionare il punto d'incontro:</label>
                        <b-form-select v-model="jsonDataPresenza.idPuntoIncontro" :options="puntiIncontroOptions" :value="''" value-field="id" text-field="descrizione"></b-form-select>
                    </b-col>
                </b-row>
            </b-modal>
            <pdf-print-view-embedded ref="pdfPrintViewEmbedded" :pdf="signedPdfViewer"></pdf-print-view-embedded>
        </template>
    </sa-page-layout>
</template>

<script>
import axios from "axios";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import "vue2-datepicker/index.css";
import DatePicker from "vue2-datepicker";
import PdfPrintViewEmbedded from "../../../app/utility/components/PdfPrintViewEmbedded.vue";
import PrintComponent from "../../../app/utility/components/PrintComponent.vue";
import moment from "moment";
export default {
    components: { SaPageLayout, DatePicker, PdfPrintViewEmbedded, PrintComponent },
    data() {
        return {
            pathResourceGestioneEmergenza: "/sveigestioneemergenza",
            pathResourceInCorso: "/sveigestioneemergenza/incorso",
            pathResourceChiudiEmergenza: "/sveigestioneemergenza/chiudiemergenza",
            pathResource: "/schedavalutazioneesigenzeimmediate/emergenza",
            pathResourcePuntiIncontro: "/sveipuntiincontro",
            pathResourceSchedaPresenza: "/sveigestioneemergenzaschede",
            pathResourceAllegato: "/sveiprinter/preview/",
            linkedit: null,
            signedPdfViewer: null,
            showModalLoading: false,
            linkback: null,
            jsonDataEmergenzaInCorso: null,
            jsonDataGestioneEmergenza: { dataInizio: new Date().getTime(), descrizione: null, stato: "IN CORSO" },
            jsonDataPresenza: { idPuntoIncontro: null, idEmergenza: null, idScheda: null, statoAttuale: "PRESENTE" },
            filter: null,
            items: [],
            rows: 0,
            perPage: 50,
            currentPage: 1,
            puntiIncontroOptions: [],
            listaPuntiIncontroOptions: [],
            passwordState: null,
            idRecord: null,
            password: "",
            fields: [
                {
                    label: "Cod. Scheda",
                    key: "idScheda",
                    sortable: true,
                    thStyle: "width: 6rem",
                },
                {
                    label: "Nominativo",
                    key: "nominativo",
                    sortable: true,
                },
                {
                    label: "Codice Fiscale",
                    key: "assistitoCodiceFiscale",
                    sortable: true,
                },
                {
                    label: "Data Nascita",
                    key: "assistitoDataNascita",
                    sortable: true,
                    thStyle: "width: 8rem",
                    formatter: (value) => {
                        return moment(new Date(value)).format("DD/MM/YYYY");
                    },
                },
                {
                    label: "Cod. Val.",
                    key: "codiceValutazione",
                    sortable: true,
                    tdClass: "text-center",
                    thStyle: "width: 6rem",
                },
                {
                    label: "P. Incontro",
                    key: "puntoIncontro",
                    sortable: true,
                },
                {
                    label: "",
                    key: "actions",
                    tdClass: "text-center",
                    thStyle: "width: 8rem",
                },
            ],
            filtro: { idEmergenza: "", nome: "", cognome: "", codiceFiscale: "", codiceValutazione: "", patologieCorsoIntolleranzeAlimentari: false, patologieCorsoNefropatia: false, patologieCorsoFistole: false, patologieCorsoMetabolica: false, patologieCorsoCardiopatia: false, patologieCorsoRespiratoria: false, puntoIncontro: "" },
        };
    },
    created() {
        let me = this;
        me.loadInCorso();
        me.loadPuntiIncontro();
    },
    computed: {
        isEmengency() {
            let me = this;
            return me.jsonDataEmergenzaInCorso !== null;
        },
        textEmengency() {
            let me = this;
            let text = me.jsonDataEmergenzaInCorso !== null ? ": " + me.jsonDataEmergenzaInCorso.descrizione : "";
            return text;
        },
    },
    methods: {
        isPresent(item) {
            let returnValue = false;
            switch (item.statoAttuale) {
                case "PRESENTE":
                case "TRASFERITO":
                    returnValue = true;
                    break;
            }
            return returnValue;
        },
        onRefresh() {},
        onClickAttivaEmergenza() {
            let me = this;
            me.$refs["mdlGestioneEmergenzaEdit"].show();
        },
        loadSchedeSvei() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.showModalLoading = true;
            me.filtro.page = me.currentPage;
            me.filtro.forPage = me.perPage;
            me.filtro.idEmergenza = me.jsonDataEmergenzaInCorso.id;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];
                    me.items = response.data.data.list;
                    me.rows = response.data.data.recordsNumber;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        loadInCorso() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceInCorso;
            me.showModalLoading = true;
            axios
                .get(link)
                .then((response) => {
                    me.showModalLoading = false;
                    me.jsonDataEmergenzaInCorso = response.data.data;
                    me.loadSchedeSvei();
                })
                .catch((error) => {
                    me.jsonDataEmergenzaInCorso = null;
                    me.showModalLoading = false;
                    console.log("Il servizio ha datao errore: " + error.response.status);
                });
        },
        onGestioneEmergenzaEditSave() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceGestioneEmergenza;
            me.showModalLoading = true;
            axios
                .post(link, me.jsonDataGestioneEmergenza)
                .then(() => {
                    me.showModalLoading = false;
                    me.loadInCorso();
                })
                .catch((error) => {
                    me.showModalLoading = false;
                    console.log("Il servizio ha datao errore: " + error.response.status);
                });
        },
        getCodiceValutazionecolor(value) {
            let returnValue = "";
            switch (value) {
                case "R":
                    returnValue = "sa-codice-valurazione-red";
                    break;
                case "V":
                    returnValue = "sa-codice-valurazione-green";
                    break;
                case "G":
                    returnValue = "sa-codice-valurazione-yellow";
                    break;
                case "GT":
                    returnValue = "sa-codice-valurazione-yellow";
                    break;
            }
            return returnValue;
        },
        segnalaPresenza(item) {
            let me = this;
            me.jsonDataPresenza.idEmergenza = me.jsonDataEmergenzaInCorso.id;
            me.jsonDataPresenza.idScheda = item.id;
            me.$refs["mdlGestioneEmergenzaPresent"].show();
        },
        onGestioneEmergenzaSetPresent() {
            let me = this;
            me.savePresent();
        },
        onShowGestioneEmergenzaSetPresent() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourcePuntiIncontro;
            axios
                .get(link)
                .then((response) => {
                    me.puntiIncontroOptions = [];
                    me.puntiIncontroOptions = response.data.data.list;
                })
                .catch(() => {});
        },
        loadPuntiIncontro() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourcePuntiIncontro;
            axios
                .get(link)
                .then((response) => {
                    me.listaPuntiIncontroOptions = [];
                    response.data.data.list.forEach((element) => {
                        me.listaPuntiIncontroOptions.push({ value: element.descrizione, text: element.descrizione });
                    });
                    me.listaPuntiIncontroOptions.unshift({ value: "", text: "-Seleziona Valore-" });
                })
                .catch(() => {});
        },
        savePresent() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceSchedaPresenza;
            me.showModalLoading = true;
            axios
                .post(link, me.jsonDataPresenza)
                .then(() => {
                    this.$bvToast.toast("Presenza Segnalata Correttamente", {
                        title: this.titleModal,
                        variant: "success",
                        autoHideDelay: 3000,
                    });
                    me.showModalLoading = false;
                    me.loadSchedeSvei();
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        onClickChiudiEmergenza() {
            let me = this;
            this.$bvModal
                .msgBoxConfirm("Sicuro di voler procedere CHIUDERE l'emergenza?", {
                    title: "Titolo",
                    buttonSize: "sm",
                    okVariant: "secondary",
                    cancelVariant: "danger",
                    okTitle: "SI",
                    cancelTitle: "NO",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                })
                .then((value) => {
                    if (value) {
                        me.chiudiEmergenza();
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        chiudiEmergenza() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceChiudiEmergenza + "/" + me.jsonDataEmergenzaInCorso.id;
            me.showModalLoading = true;
            axios
                .put(link, me.jsonDataPresenza)
                .then(() => {
                    this.$bvToast.toast("Presenza Segnalata Correttamente", {
                        title: this.titleModal,
                        variant: "success",
                        autoHideDelay: 3000,
                    });
                    me.showModalLoading = false;
                    me.loadInCorso();
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        onSubmit() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadSchedeSvei();
        },
        onReset() {
            let me = this;
            me.filtro = { nome: "", cognome: "", codiceFiscale: "", codiceValutazione: "", puntoIncontro: "" };
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadSchedeSvei();
        },
        onPrintPreview(item) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceAllegato + item.id;
            me.showModalLoading = true;
            axios
                .get(link)
                .then((response) => {
                    me.signedPdfViewer = response.data.data.file.includes("data:application/pdf;base64,") ? response.data.data.file : "data:application/pdf;base64," + response.data.data.file;
                    //me.$refs.mdlSigendPdfViewer.show();
                    me.$refs.pdfPrintViewEmbedded.show();
                    me.showModalLoading = false;
                })
                .catch((error) => {
                    // me.$refs.mdlGenerazioneReportInCorso.hide();
                    console.log(error);
                    me.showModalLoading = false;
                    let messaggio = error.response.data.messaggio ? error.response.data.messaggio : "Errore non gestito, Codice: " + error.response.status;
                    this.$bvModal
                        .msgBoxOk(messaggio, {
                            title: this.$i18n.t("ERROR!"),
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then((value) => {
                            this.boxTwo = value;
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                });
        },
        linkPrintData(item) {
            let me = this;
            let returnValue = me.pathResourceGestioneEmergenza + "/print/" + item.id + "?nome=" + me.filtro.nome + "&cognome=" + me.filtro.cognome + "&codiceFiscale=" + me.filtro.codiceFiscale + "&puntoIncontro=" + me.filtro.puntoIncontro;
            return returnValue;
        },
        onHidden() {
            this.passwordState = null;
            this.password = "";
        },
        onOk(bvModalEvt) {
            let me = this;
            bvModalEvt.preventDefault();
            me.onValidaSubmit();
        },
        onValida(item) {
            let me = this;
            me.idRecord = item;
            console.log(item);
            me.$refs["mdlValidazione"].show();
        },
        onValidaSubmit() {
            // Exit when the form isn't valid
            let me = this;
            me.showModalLoading = true;
            if (!this.checkFormValidity()) {
                return;
            }
            let key = btoa(btoa(this.password));
            let link = process.env.VUE_APP_PATH_API + "/sveiprinter/valida/" + me.idRecord + "?firma=" + key;
            axios
                .get(link)
                .then((response) => {
                    let me = this;
                    this.$bvToast.toast(response.data.messaggio, {
                        title: "Firma effettuata",
                        variant: "success",
                        solid: true,
                        noCloseButton: true,
                        autoHideDelay: 2000,
                    });
                    me.loadSchedeSvei();
                    me.showModalLoading = false;
                    // console.log(response);
                })
                .catch((err) => {
                    me.showModalLoading = false;
                    this.$bvModal
                        .msgBoxOk(err.response.data.messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then((value) => {
                            this.boxOne = value;
                        });
                });

            this.$nextTick(() => {
                this.$refs["mdlValidazione"].hide();
            });
        },
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity();
            this.passwordState = valid;
            return valid;
        },
    },
};
</script>

<style></style>
