<template>
    <sa-page-layout :data="jsonData" :pathResource="pathResource" :showModalLoading="showModalLoading" :saveVisible="true" :backVisible="true" :linkback="linkback" class="sa-no-space">
        <template slot="table-body">
            <h4>Tipo Documento</h4>
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                    <span class="sa-form-label">Tipo Documento</span>
                    <b-form-select v-model="jsonData.tipoDocumento" class="mb-2 mr-sm-2 mb-sm-0" :options="tipiDocumentoOptions" :value="null"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                    <span class="sa-form-label">Codice Tipo Documento</span>
                    <b-form-input v-model="jsonData.codiceTipoDocumento" placeholder="Codice Tipo Documento"></b-form-input>
                </b-col>
            </b-row>
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                    <span class="sa-form-label">Descrizione</span>
                    <b-form-input v-model="jsonData.descrizioneTipoDocumento" placeholder="Descrizione"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                    <span class="sa-form-label">Codice Numeratore</span>
                    <b-form-select v-model="jsonData.codiceNumeratore" class="mb-2 mr-sm-2 mb-sm-0" :options="numeratoriOptions" :value="null"></b-form-select>
                </b-col>
            </b-row>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
    components: { SaPageLayout },
    data() {
        return {
            linkback: null,
            pathResource: "/tipidocumento",
            linkNumeratori: "/numeratori",
            showModalLoading: false,
            id: null,
            jsonData: { tipoDocumento: null, codiceTipoDocumento: null, descrizioneTipoDocumento: null, codiceNumeratore: null },
            numeratoriOptions: [{ value: null, text: "-Seleziona Valore-" }],
            tipiDocumentoOptions: [
                { value: null, text: "-Seleziona Valore-" },
                { value: "EMESSO", text: "Emesso" },
                { value: "RICEVUTO", text: "Ricevuto" },
            ],
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        if (me.id === "-1") {
            me.linkback = "/tipidocumento";
        } else {
            me.linkback = "/tipidocumento/view/" + me.id;
        }
        me.loadData();
        me.loadNumeratori();
    },
    methods: {
        loadData() {
            let me = this;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                me.showModalLoading = true;
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                axios.get(link + me.id).then((response) => {
                    me.jsonData = response.data.data;
                    me.showModalLoading = false;
                });
            }
        },
        loadNumeratori() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkNumeratori;
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    me.numeratoriOptions.push({ value: element.codice, text: element.descrizione });
                });
            });
        },
    },
};
</script>
