import DocumentRepository from "../";
const routes = [
  {
    name: "Document Repository",
    path: "/documentrepository",
    component: DocumentRepository.DocumentRepositoryList,
    meta: { title: "Document" },
  },
];
export default routes;
