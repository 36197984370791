<template>
    <b-modal title="Scheda Psicologica" ref="mdlRsaSchedaPsicologica" @ok="onSalvaSchedaPsicologica" @hidden="onCloseModal" centered no-close-on-backdrop no-close-on-esc hide-header-close size="lg">
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card sa-card-border" header="Valutazione" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Diagnosi Psichiatrica</label>
                            <b-form-input v-model="jsonDataSchedaPsicologica.diagnosiPsichiatrica" :readonly="isReadOnly"></b-form-input>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Aspetto Fisico</label>
                            <b-form-input v-model="jsonDataSchedaPsicologica.aspettoFisico" :readonly="isReadOnly"></b-form-input>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Condizioni Igieniche</label>
                            <b-form-input v-model="jsonDataSchedaPsicologica.condizioniIgieniche" :readonly="isReadOnly"></b-form-input>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Cura nel Vestirsi</label>
                            <b-form-input v-model="jsonDataSchedaPsicologica.curaNelVestirsi" :readonly="isReadOnly"></b-form-input>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Comportamento e Attività Psicomotoria</label>
                            <b-form-input v-model="jsonDataSchedaPsicologica.comportamentoAttivitaPsicomotoria" :readonly="isReadOnly"></b-form-input>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Comportamento verso l'Operatore</label>
                            <b-form-input v-model="jsonDataSchedaPsicologica.comportamentoVersoOperatore" :readonly="isReadOnly"></b-form-input>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Percezione</label>
                            <b-form-input v-model="jsonDataSchedaPsicologica.percezione" :readonly="isReadOnly"></b-form-input>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Contenuto del Pensiero</label>
                            <b-form-input v-model="jsonDataSchedaPsicologica.contenutoPensiero" :readonly="isReadOnly"></b-form-input>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Capacità Cognitive</label>
                            <b-form-input v-model="jsonDataSchedaPsicologica.capacitaCognitive" :readonly="isReadOnly"></b-form-input>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Capacità di Giudizio e Insight</label>
                            <b-form-input v-model="jsonDataSchedaPsicologica.capacitaGiudizioInsight" :readonly="isReadOnly"></b-form-input>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Umore</label>
                            <b-form-select v-model="jsonDataSchedaPsicologica.umore" :disabled="isReadOnly" :options="umoreOptions">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Descrizione Umore</label>
                            <b-form-input v-model="jsonDataSchedaPsicologica.umoreDescrizione" :readonly="isReadOnly"></b-form-input>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Affettività</label>
                            <b-form-select v-model="jsonDataSchedaPsicologica.affettivita" :disabled="isReadOnly" :options="affettivitaOptions">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Descrizione Affettività</label>
                            <b-form-input v-model="jsonDataSchedaPsicologica.affettivitaDescrizione" :readonly="isReadOnly"></b-form-input>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Linguaggio</label>
                            <b-form-select v-model="jsonDataSchedaPsicologica.linguaggio" :disabled="isReadOnly" :options="linguaggioOptions">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Descrizione Linguaggio</label>
                            <b-form-input v-model="jsonDataSchedaPsicologica.linguaggioDescrizione" :readonly="isReadOnly"></b-form-input>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Orientamento</label>
                            <b-form-select v-model="jsonDataSchedaPsicologica.orientamento" :disabled="isReadOnly" :options="orientamentoOptions">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Descrizione Orientamento</label>
                            <b-form-input v-model="jsonDataSchedaPsicologica.orientamentoDescrizione" :readonly="isReadOnly"></b-form-input>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Note</label>
                            <b-form-textarea v-model="jsonDataSchedaPsicologica.note" :readonly="isReadOnly" max-rows="10" rows="7"></b-form-textarea>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <template #modal-footer>
            <b-button v-if="!isReadOnly" variant="outline-danger" @click="$refs.mdlRsaSchedaPsicologica.hide()">Annulla</b-button>
            <b-button v-if="!isReadOnly" variant="outline-success" @click="onSalvaSchedaPsicologica()">Salva</b-button>
            <b-button v-if="isReadOnly" variant="outline-primary" @click="$refs.mdlRsaSchedaPsicologica.hide()"> Chiudi </b-button>
        </template>
    </b-modal>
</template>

<script>
import UtilityMixin from "../../../utility/UtilityMixin";

export default {
    mixins: [UtilityMixin],
    mounted() {
        let me = this;
        me.idAccettazione = me.$route.query.idPaziente;
    },
    data() {
        return {
            pathResourceSchedaPsicologica: "/rsaschedapsicologica",
            pathResourceCheckSchedaPsicologica: "/rsaschedapsicologica/check",
            umoreOptions: [
                { text: "EUTIMICO", value: "EUTIMICO" },
                { text: "DEFLESSO", value: "DEFLESSO" },
                { text: "DISFORICO", value: "DISFORICO" },
                { text: "EUFORICO", value: "EUFORICO" },
            ],
            affettivitaOptions: [
                { text: "NORMALE", value: "NORMALE" },
                { text: "LIMITATA", value: "LIMITATA" },
                { text: "COARTATA", value: "COARTATA" },
                { text: "APPIATTITA", value: "APPIATTITA" },
            ],
            linguaggioOptions: [
                { text: "FLUENTE", value: "FLUENTE" },
                { text: "MUTISMO", value: "MUTISMO" },
                { text: "ECOLALIA", value: "ECOLALIA" },
                { text: "POVERTA' ESPRESSIVA", value: "POVERTA' ESPRESSIVA" },
                { text: "LOGORREA", value: "LOGORREA" },
            ],
            orientamentoOptions: [
                { text: "PRESENTE", value: "PRESENTE" },
                { text: "ASSENTE", value: "ASSENTE" },
                { text: "ORIENTATO/A", value: "ORIENTATO/A" },
                { text: "DISORIENTATO/A NEL TEMPO", value: "DISORIENTATO/A NEL TEMPO" },
                { text: "DISORIENTATO/A NELLO SPAZIO", value: "DISORIENTATO/A NELLO SPAZIO" },
                { text: "DISORIENTAMENTO VERSO LE PERSONE", value: "DISORIENTAMENTO VERSO LE PERSONE" },
            ],
            jsonDataSchedaPsicologica: {
                id: null,
                idAccettazione: null,
                diagnosiPsichiatrica: null,
                aspettoFisico: null,
                condizioniIgieniche: null,
                curaNelVestirsi: null,
                comportamentoAttivitaPsicomotoria: null,
                comportamentoVersoOperatore: null,
                percezione: null,
                contenutoPensiero: null,
                capacitaCognitive: null,
                capacitaGiudizioInsight: null,
                umore: null,
                umoreDescrizione: null,
                affettivita: null,
                affettivitaDescrizione: null,
                linguaggio: null,
                linguaggioDescrizione: null,
                orientamento: null,
                orientamentoDescrizione: null,
                note: null,
            },
            isReadOnly: false,
        };
    },

    methods: {
        nuovaSchedaPsicologica() {
            let me = this;
            me.isReadOnly = false;
            UtilityMixin.methods.loadDataResources(me.pathResourceSchedaPsicologica, { idAccettazione: me.idAccettazione }, me.loadSchedaPsicologicaDataSuccess, me.loadSchedaPsicologicaDataError);
        },
        loadSchedaPsicologicaDataSuccess(response) {
            let me = this;
            if (response.data?.data?.list?.length > 0) {
                me.jsonDataSchedaPsicologica = response.data.data.list[0];
            } else {
                me.jsonDataSchedaPsicologica = {
                    id: null,
                    idAccettazione: me.idAccettazione,
                    diagnosiPsichiatrica: null,
                    aspettoFisico: null,
                    condizioniIgieniche: null,
                    curaNelVestirsi: null,
                    comportamentoAttivitaPsicomotoria: null,
                    comportamentoVersoOperatore: null,
                    percezione: null,
                    contenutoPensiero: null,
                    capacitaCognitive: null,
                    capacitaGiudizioInsight: null,
                    umore: null,
                    umoreDescrizione: null,
                    affettivita: null,
                    affettivitaDescrizione: null,
                    linguaggio: null,
                    linguaggioDescrizione: null,
                    orientamento: null,
                    orientamentoDescrizione: null,
                    note: null,
                };
            }
            me.$refs.mdlRsaSchedaPsicologica.show();
        },
        loadSchedaPsicologicaDataError(error) {
            let me = this;
            console.error("Errore nel caricamento:", error);
            me.$bvToast.toast("Errore nel caricamento dei dati nel edit component", {
                title: "Errore",
                variant: "danger",
                solid: true,
            });
        },

        visualizzaSchedaPsicologica() {
            let me = this;
            me.isReadOnly = true;
            UtilityMixin.methods.loadDataResources(me.pathResourceSchedaPsicologica, { idAccettazione: me.idAccettazione }, me.loadSchedaPsicologicaViewSuccess, me.loadSchedaPsicologicaViewError);
            console.log(this.jsonDataSchedaPsicologica.idAccettazione);
        },
        loadSchedaPsicologicaViewSuccess(response) {
            let me = this;
            if (response.data?.data?.list?.length > 0) {
                me.jsonDataSchedaPsicologica = response.data.data.list[0];
                me.$refs.mdlRsaSchedaPsicologica.show();
            } else {
                me.$bvToast.toast("Nessun record trovato per questa accettazione", {
                    title: "Informazione",
                    variant: "info",
                    solid: true,
                });
            }
            me.$emit("afterLoadData");
        },
        loadSchedaPsicologicaViewError(error) {
            let me = this;
            console.error("Errore nel caricamento:", error);
            me.$bvToast.toast("Errore nel caricamento dei dati", {
                title: "Errore",
                variant: "danger",
                solid: true,
            });
            me.$emit("afterLoadData");
        },
        onCloseModal() {
            let me = this;
            me.jsonDataSchedaPsicologica = {};
            me.$emit("onClose");
        },
        onSalvaSchedaPsicologica() {
            let me = this;
            if (!me.jsonDataSchedaPsicologica.idAccettazione) {
                me.$bvToast.toast("Errore: ID Accettazione mancante", {
                    title: "Errore",
                    variant: "danger",
                    solid: true,
                });
                return;
            }
            UtilityMixin.methods.saveResource(me.pathResourceSchedaPsicologica, me.jsonDataSchedaPsicologica, me.saveSchedaPsicologicaSuccessResponse, me.saveSchedaPsicologicaErrorResponse);
        },
        saveSchedaPsicologicaSuccessResponse() {
            let me = this;
            me.$emit("afterSaveSuccess");
            me.$refs.mdlRsaSchedaPsicologica.hide();
            me.$bvToast.toast("Salvataggio avvenuto con successo", {
                title: "Successo",
                variant: "success",
                solid: true,
            });
        },
        saveSchedaPsicologicaErrorResponse(response) {
            if (response.response.status === 400) {
                this.$bvModal.msgBoxOk(response.response.data.messaggio, {
                    title: "ATTENZIONE",
                    size: "sm",
                    okVariant: "outline-secondary",
                    headerClass: "sa-msg-header-danger",
                    footerClass: "p-2 border-top-0",
                    centered: true,
                });
            } else {
                this.$bvToast.toast("Errore nel salvataggio in edit component" + response.message, {
                    title: "Errore",
                    variant: "danger",
                    solid: true,
                });
            }
        },
    },
};
</script>
