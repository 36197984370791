<template>
  <sa-page-layout :titolo="titolo" :showModalLoading="showModalLoading" :btnAnnullaVisible="true" :btnSaveVisible="true" :linkback="linkback" :pathResource="pathResource" :data="jsonData">
    <template slot="table-header">
      <b-row v-if="superAdmin">
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Cliente</label>
          <b-form-select v-model="jsonData.idCliente" class="mb-2 mr-sm-2 mb-sm-0" :options="clientiOptions" value-field="id" text-field="descrizione" :value="null" @input="loadDestinazioni()"></b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <span class="sa-form-label">Data Ordine: </span><br />
          <date-picker format="DD-MM-YYYY" value-type="timestamp" :disabled-date="notBefore" v-model="jsonData.dataOrdine" type="date" placeholder="Data Ordine"></date-picker>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <span class="sa-form-label">Indirizzo: </span>
          <b-form-select v-model="jsonData.destinazione" class="mb-2 mr-sm-2 mb-sm-0" :options="destinazioneOption" value-field="codiceDestinazione" text-field="descrizione" :value="null">
          </b-form-select>
        </b-col>
      </b-row>
    </template>
    <template slot="table-body">
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="tblRigheDocumento" :filtro="filtro" stacked="xl" striped hover :items="jsonData.ordineRiga" :fields="fieldsOrdineRiga" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
          <template #cell(index)="jsonData">
            {{ jsonData.index + 1 }}
          </template>
          <template #cell(action)="row">
            <!-- <b-button v-if="row.item.articolo.toString().trim() !== ''" size="sm" variant="btn btn-purple waves-effect waves-light width-sm no-text" @click="onModificaRiga(row.index)">
                            <b-icon icon="pencil">{{ row.item }}</b-icon>
                        </b-button>
                        &nbsp; -->
            <b-button v-if="row.item.articolo.toString().trim() !== ''" size="sm" variant="btn btn-danger waves-effect waves-light width-sm no-text" @click="onDeleteRiga(row.index)">
              <b-icon icon="trash"></b-icon>
            </b-button>
            &nbsp;
            <b-button v-if="row.item.articolo.toString().trim() === ''" size="sm" variant="btn btn-success waves-effect waves-light width-sm no-text" @click="onAddRigaDocumento()">
              <b-icon icon="plus"></b-icon>
            </b-button>
          </template>
        </b-table>
      </div>
    </template>
    <template slot="table-footer">
      <div>
        <b-modal id="modalRiga" ref="modalRiga" title="Riga Ordine" size="xl" @show="onShowRigaDocumento" hide-footer>
          <div class="sa-form-section">
            <!-- <form ref="form" @submit.stop.prevent="handleSubmit"> -->
            <b-row>
              <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <span class="sa-form-label">Prodotto</span>
                <b-form-select id="inline-form-custom-select-pref" v-model="filtroArticolo.prodotto" class="mb-2 mr-sm-2 mb-sm-0" :options="prodottoOption" :value="null" value-field="codiceProdotto" text-field="descrizione" @change="loadQualita()">
                </b-form-select>
              </b-col>
              <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <span class="sa-form-label">Qualita'</span>
                <b-form-select id="inline-form-custom-select-pref" v-model="filtroArticolo.qualita" class="mb-2 mr-sm-2 mb-sm-0" :options="qualitaOption" :value="null" value-field="codice" text-field="descrizione" @change="loadPezzatura()">
                </b-form-select>
              </b-col>
              <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <span class="sa-form-label">Pezzatura</span>
                <b-form-select id="inline-form-custom-select-pref" v-model="filtroArticolo.pezzatura" class="mb-2 mr-sm-2 mb-sm-0" :options="pezzaturaOption" :value="null" value-field="codicePezzatura" text-field="descrizione" @change="loadLavorazioni()">
                </b-form-select>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <span class="sa-form-label">Lavorazione</span>
                <b-form-select id="inline-form-custom-select-pref" v-model="filtroArticolo.lavorazione" class="mb-2 mr-sm-2 mb-sm-0" :options="lavorazioneOption" :value="null" value-field="codiceLavorazione" text-field="descrizione" @change="loadTrattamento()">
                </b-form-select>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <span class="sa-form-label">Trattamento</span>
                <b-form-select id="inline-form-custom-select-pref" v-model="filtroArticolo.trattamento" class="mb-2 mr-sm-2 mb-sm-0" :options="trattamentoOption" :value="null" value-field="codiceTrattamento" text-field="descrizione" @change="loadArticoli()">
                </b-form-select>
              </b-col>
            </b-row>
            <!-- </form> -->
            <hr />
            <b-table sticky-header ref="tblArticoli" :filtro="filtro" stacked="xl" striped hover :items="articoliOption" :fields="fieldsArticolo" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
            </b-table>
            <hr />
            <br />
            <b-row>
              <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <span class="sa-form-label">Quantita</span>
                <b-form-input v-model="quantita" type="number" :disabled="articoliOption.length === 0"></b-form-input>
                <!-- <b-form-input v-model="quantita" placeholder="Quantita'" type="number" :disabled="articoliOption.length === 0" @input="onValidaQuantita()"></b-form-input> -->
              </b-col>
              <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <span class="sa-form-label">Unita' misura</span>
                <br />{{ unitaMisura }}
                <!-- <b-form-select id="inline-form-custom-select-pref" v-model="jsonRiga.unitaMisura" class="mb-2 mr-sm-2 mb-sm-0" :options="unitaMisuraOption" :value="null" disabled>
                                </b-form-select> -->
              </b-col>
              <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <span class="sa-form-label">Busta</span>
                <b-form-select id="inline-form-custom-select-pref" v-model="rigaDocumentoSelezionata.busta" class="mb-2 mr-sm-2 mb-sm-0" :options="bustaOption" :value="null" value-field="codiceBusta" text-field="descrizioneBusta" @change="setDescrizioneBusta(rigaDocumentoSelezionata.busta)">
                </b-form-select>
              </b-col>
              <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <span class="sa-form-label">Contenitore</span>
                <b-form-select id="inline-form-custom-select-pref" v-model="rigaDocumentoSelezionata.contenitore" class="mb-2 mr-sm-2 mb-sm-0" :options="contenitoreOption" :value="null" value-field="codiceContenitore" text-field="descrizione" @change="setDescrizioneContenitore(
                  rigaDocumentoSelezionata.contenitore
                )
                  ">
                </b-form-select>
              </b-col>
            </b-row>
          </div>
          <hr />
          <b-row>
            <div class="sa-padding-right text-right col">
              <b-button type="button" variant="secondary" @click="onCloseRigaDocumento">Cancel</b-button>
              <b-button type="button" variant="info" @click="onOkRigaDocumento">OK</b-button>
            </div>
          </b-row>
        </b-modal>
      </div>
    </template>
  </sa-page-layout>
</template>

<script>
import axios from "axios";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import UtilityMixin from "../../utility/UtilityMixin.js";
import DatePicker from "vue2-datepicker";
// import moment from "moment";
export default {
  // props: {
  //   utente: {
  //     type: Object,
  //     default: function () {
  //       let me = this;
  //       me.superAdmin = false;
  //       if (me.utente.gruppo.descrizione === 'Cliente') {
  //         me.jsonData.idCliente = me.utente.id;
  //       } else {
  //         me.superAdmin = true;
  //       }
  //       me.loadData();
  //     },
  //   },
  // },
  watch: {
    utente() {
      let me = this;
      me.superAdmin = false;
      if (me.utente.gruppo.nome === 'Clienti') {
        me.jsonData.idCliente = me.utente.id;
      } else {
        me.superAdmin = true;
        me.loadClienti();
      }
      me.loadData();
    },
  },
  components: { DatePicker, SaPageLayout },
  mixins: [UtilityMixin],
  data() {
    return {
      id: null,
      titolo: "Crea Ordine",
      showModalLoading: false,
      linkback: null,
      pathResource: "/ordini",
      pathResourceArticoli: "/articoli",
      indexRigaDocumentoSelezionata: null,
      currentPage: 1,
      perPage: 100,
      filtro: [],
      items: [],
      utente: {},
      superAdmin: false,
      idOrdine: null,
      rigaValida: false,
      rigaValidaErrore: "",
      articoliOption: [],
      clientiOptions: [],
      filtroArticolo: {
        forPage: "100000",
        page: "1",
        prodotto: "",
        qualita: "",
        lavorazione: "",
        trattamento: "",
        pezzatura: "",
      },
      filtroBustaContenitore: {
        forPage: "100000",
        page: "1",
        codiceArticolo: "",
      },
      rigaSelezionata: { articolo: "" },
      jsonRiga: { idCliente: null },
      unitaMisura: "Pz./Kg",
      rigaDocumentoSelezionata: {
        articolo: "",
        articoloDescrizione: "",
        bustaDescrizione: "",
        contenitoreDescrizione: "",
      },
      jsonData: {
        ordineRiga: [],
        destinazione: null,
      },
      destinazioneOption: [
        { codiceDestinazione: null, descrizione: "-Seleziona Destinazione-" },
      ],
      quantita: null,
      unitaMisuraOption: [
        { text: "Pz.", value: "pz" },
        { text: "Kg", value: "kg" },
      ],
      qualitaOption: [],
      pezzaturaOption: [],
      lavorazioneOption: [],
      contenitoreOption: [],
      trattamentoOption: [],
      bustaOption: [],
      prodottoOption: [],
      fieldsOrdineRiga: [
        {
          label: "N.",
          key: "index",
          sortable: false,
          thStyle: "width: 4rem",
        },
        {
          label: "Articolo",
          key: "articoloDescrizione",
          sortable: false,
        },
        {
          label: "Pezzi",
          key: "pezzi",
          sortable: false,
        },
        {
          label: "Peso",
          key: "peso",
          sortable: false,
        },
        {
          label: "Buste",
          key: "bustaDescrizione",
          sortable: false,
        },
        {
          label: "Contenitori",
          key: "contenitoreDescrizione",
          sortable: false,
        },
        {
          label: "",
          key: "action",
          thStyle: "width: 3rem",
          tdClass: "text-center",
        },
      ],
      fieldsArticolo: [
        {
          label: "Prodotto",
          key: "prodotto",
          sortable: false,
        },
        {
          label: "Descrizione",
          key: "descrizioneArticolo",
          sortable: false,
        },
        {
          label: "Lavorazione",
          key: "lavorazione",
          sortable: false,
        },
        {
          label: "Pezzatura",
          key: "pezzatura",
          sortable: false,
        },
        {
          label: "Qualita",
          key: "qualita",
          sortable: false,
        },
        {
          label: "Trattamento",
          key: "trattamento",
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.idOrdine = this.$route.query.idOrdine;
    if (me.id !== "-1") {
      me.linkback = "/fatturazione/view/" + me.id;
    } else {
      me.linkback = "/fatturazione";
    }
    me.loadUserData();
    // me.loadData();
  },
  methods: {
    loadUserData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/users/profile";
      axios
        .get(link)
        .then((response) => {
          me.utente = response.data.data;
          // me.idSoggetto = utente.primaryKey;
          // console.log("Funzione " + id);
        })
        .catch((e) => {
          console.log(e);
          //   this.errors.push(e);
        });
    },
    loadData() {
      let me = this;
      me.loadDestinazioni();
      if (me.id === "-1" && me.idOrdine === undefined) {
        me.showModalLoading = false;
        me.jsonData.idCliente = me.utente.id;
        me.jsonData.ordineRiga.push(me.rigaSelezionata);
      } else if (me.id === "-1" && me.idOrdine !== undefined) {
        let link =
          process.env.VUE_APP_PATH_API +
          me.pathResource +
          "/duplica/" +
          me.idOrdine;
        me.showModalLoading = true;
        axios
          .get(link)
          .then((response) => {
            me.jsonData = response.data.data;
            if (me.jsonData.ordineRiga) {
              me.jsonData.ordineRiga.push(me.rigaSelezionata);
            } else {
              me.jsonData.ordineRiga = [];
              me.jsonData.ordineRiga.push(me.rigaSelezionata);
            }
            me.showModalLoading = false;
          })
          .catch(() => {
            me.showModalLoading = false;
          });
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        me.showModalLoading = true;
        axios
          .get(link)
          .then((response) => {
            me.jsonData = response.data.data;
            if (me.jsonData.ordineRiga) {
              me.jsonData.ordineRiga.push(me.rigaSelezionata);
            } else {
              me.jsonData.ordineRiga = [];
              me.jsonData.ordineRiga.push(me.rigaSelezionata);
            }
            me.showModalLoading = false;
          })
          .catch(() => {
            me.showModalLoading = false;
          });
      }
    },
    loadOrdine(value) {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + value;
      //   let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
      me.showModalLoading = true;
      axios
        .get(link)
        .then((response) => {
          me.jsonData = response.data.data;
          if (me.jsonData.ordineRiga) {
            me.jsonData.ordineRiga.push(me.rigaSelezionata);
          } else {
            me.jsonData.ordineRiga = [];
            me.jsonData.ordineRiga.push(me.rigaSelezionata);
          }
          me.showModalLoading = false;
          console.log(response);
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    loadDestinazioni() {
      let me = this;
      me.destinazioneOption = [];
      let link = process.env.VUE_APP_PATH_API + "/destinazioni?cliente=" + me.jsonData.idCliente;
      axios.get(link).then((response) => {
        // response.data.data.forEach((element) => {
        //     me.destinazioneOption.push({ value: element.codiceDestinazione, text: element.descrizione });
        // });
        me.destinazioneOption = response.data.data;
        me.destinazioneOption.unshift({
          codiceDestinazione: null,
          descrizione: "-Seleziona Valore-",
        });
        if (me.destinazioneOption.length === 2) {
          me.jsonData.destinazione = me.destinazioneOption[1].codiceDestinazione;
        }
      });
    },
    loadClienti() {
      let me = this;
      me.destinazioneOption = [];
      let link = process.env.VUE_APP_PATH_API + "/clienti";
      axios.get(link).then((response) => {
        // response.data.data.forEach((element) => {
        //     me.destinazioneOption.push({ value: element.codiceDestinazione, text: element.descrizione });
        // });
        // console.log(response.data);
        me.clientiOptions = response.data.data.list;
        me.clientiOptions.unshift({
          id: null,
          descrizione: "-Seleziona Valore-",
        });
      });
    },
    onAddRigaDocumento() {
      let me = this;
      me.qualitaOption = [];
      me.pezzaturaOption = [];
      me.lavorazioneOption = [];
      me.contenitoreOption = [];
      me.trattamentoOption = [];
      me.bustaOption = [];
      me.prodottoOption = [];
      me.quantita = null;
      let index = me.jsonData.ordineRiga.length - 1;

      me.indexRigaDocumentoSelezionata = index;
      Object.assign(me.rigaSelezionata, me.jsonData.ordineRiga[index]);
      me.filtroArticolo = {
        forPage: "100000",
        page: "1",
        prodotto: "",
        qualita: "",
        lavorazione: "",
        trattamento: "",
        pezzatura: "",
      };
      me.filtroBustaContenitore = {
        forPage: "100000",
        page: "1",
        codiceArticolo: "",
      };
      me.rigaDocumentoSelezionata = {
        riga: me.jsonData.ordineRiga.length,
        articolo: "",
      };
      me.unitaMisura = "Pz./Kg";
      me.articoliOption = [];
      me.$bvModal.show("modalRiga");
      // me.loadArticoli();
      me.loadProdotti();
    },
    onShowRigaDocumento() { },
    onDeleteRiga(index) {
      let me = this;
      me.jsonData.ordineRiga.splice(index, 1);
    },
    onModificaRiga(index) {
      let me = this;
      me.indexRigaDocumentoSelezionata = index;
      // console.log(me.jsonData.ordineRiga[index]);
      Object.assign(me.rigaDocumentoSelezionata, me.jsonData.ordineRiga[index]);
      // console.log(me.rigaDocumentoSelezionata);
      me.$bvModal.show("modalRiga");
    },
    onOkRigaDocumento(bvModalEvt) {
      let me = this;
      me.onValidaQuantita();
      bvModalEvt.preventDefault();
      if (me.rigaValida) {
        if (me.unitaMisura === "Pz.") {
          me.rigaDocumentoSelezionata.pezzi = parseInt(me.quantita);
        } else {
          me.rigaDocumentoSelezionata.peso = parseFloat(me.quantita);
        }
        me.jsonData.ordineRiga[me.indexRigaDocumentoSelezionata] =
          me.rigaDocumentoSelezionata;
        this.$refs.tblRigheDocumento.refresh();
        if (
          me.jsonData.ordineRiga[me.jsonData.ordineRiga.length - 1].articolo !==
          ""
        ) {
          me.jsonData.ordineRiga.push({ articolo: "" });
        }
        this.$nextTick(() => {
          this.$bvModal.hide("modalRiga");
        });
      } else {
        this.$bvModal
          .msgBoxOk(me.rigaValidaErrore, {
            title: "",
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "sa-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
          })
          .then((value) => {
            this.boxTwo = value;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onCloseRigaDocumento() {
      this.$bvModal.hide("modalRiga");
    },
    loadProdotti() {
      let me = this;
      me.prodottoOption = [];
      let link =
        process.env.VUE_APP_PATH_API + "/prodotti?page=1&forPage=10000";
      axios.get(link).then((res) => {
        me.prodottoOption = res.data.data.list;
        // let prodotti = res.data.data.list;
        // prodotti.forEach((element) => {
        //     let obj = element.descrizione;
        //     me.prodottoOption.push(obj);
        // });
      });
    },
    loadQualita() {
      let me = this;
      me.filtroArticolo.qualita = null;
      me.filtroArticolo.pezzatura = null;
      me.filtroArticolo.lavorazione = null;
      me.filtroArticolo.trattamento = null;
      me.articoliOption = [];
      me.quantita = null;
      me.unitaMisura = "Pz./Kg";
      me.rigaDocumentoSelezionata.busta = null;
      me.rigaDocumentoSelezionata.contenitore = null;
      // console.log(me.filtroArticolo.prodotto);
      me.qualitaOption = [];
      let link = process.env.VUE_APP_PATH_API + "/qualita";
      axios.get(link, { params: me.filtroArticolo }).then((response) => {
        // console.log(response.data.data.list);
        me.qualitaOption = response.data.data.list;
        if (me.qualitaOption.length === 1) {
          me.filtroArticolo.qualita = me.qualitaOption[0].codice;
          me.loadPezzatura();
        }
        // console.log(me.qualitaOption);
      });
    },
    loadPezzatura() {
      let me = this;
      // console.log(me.filtroArticolo.prodotto);
      me.filtroArticolo.pezzatura = null;
      me.filtroArticolo.lavorazione = null;
      me.filtroArticolo.trattamento = null;
      me.articoliOption = [];
      me.quantita = null;
      me.unitaMisura = "Pz./Kg";
      me.rigaDocumentoSelezionata.busta = null;
      me.rigaDocumentoSelezionata.contenitore = null;
      me.pezzaturaOption = [];
      let link = process.env.VUE_APP_PATH_API + "/pezzature";
      axios.get(link, { params: me.filtroArticolo }).then((response) => {
        // console.log(response.data.data.list);
        me.pezzaturaOption = response.data.data.list;
        if (me.pezzaturaOption.length === 1) {
          me.filtroArticolo.pezzatura = me.pezzaturaOption[0].codicePezzatura;
          me.loadLavorazioni()
        }
      });
    },
    loadLavorazioni() {
      let me = this;
      me.filtroArticolo.trattamento = null;
      me.filtroArticolo.lavorazione = null;
      me.articoliOption = [];
      me.quantita = null;
      me.unitaMisura = "Pz./Kg";
      me.rigaDocumentoSelezionata.busta = null;
      me.rigaDocumentoSelezionata.contenitore = null;
      me.lavorazioneOption = [];
      // console.log(me.filtroArticolo);
      let link = process.env.VUE_APP_PATH_API + "/lavorazioni";
      axios.get(link, { params: me.filtroArticolo }).then((response) => {
        // console.log(response.data.data.list);
        me.lavorazioneOption = response.data.data.list;
        if (me.lavorazioneOption.length === 1) {
          me.filtroArticolo.lavorazione = me.lavorazioneOption[0].codiceLavorazione;
          me.loadTrattamento();
        }
        // console.log(me.lavorazioneOption);
      });
      // if (me.lavorazioneOption.length === 1) {
      //   console.log(this.lavorazioneOption)
      // }
    },
    loadTrattamento() {
      let me = this;
      me.articoliOption = [];
      me.quantita = null;
      me.unitaMisura = "Pz./Kg";
      me.rigaDocumentoSelezionata.busta = null;
      me.rigaDocumentoSelezionata.contenitore = null;
      // console.log(me.filtroArticolo);
      me.trattamentoOption = [];
      // console.log(me.filtroArticolo);
      let link = process.env.VUE_APP_PATH_API + "/trattamenti";
      axios.get(link, { params: me.filtroArticolo }).then((response) => {
        // console.log(response.data.data.list);
        me.trattamentoOption = response.data.data.list;
        // console.log(me.trattamentoOption);
        if (me.trattamentoOption.length === 1) {
          me.filtroArticolo.trattamento = me.trattamentoOption[0].codiceTrattamento;
          me.loadArticoli();
        }
      });

    },
    loadArticoli() {
      let me = this;
      me.quantita = null;
      me.unitaMisura = "Pz./Kg";
      me.rigaDocumentoSelezionata.busta = null;
      me.rigaDocumentoSelezionata.contenitore = null;
      me.rigaDocumentoSelezionata.contenitore = null;
      me.articoliOption = [];
      let link = process.env.VUE_APP_PATH_API + this.pathResourceArticoli;
      axios.get(link, { params: me.filtroArticolo }).then((response) => {
        // console.log(response.data.data.list);
        me.articoliOption = response.data.data.list;
        if (me.articoliOption[0].unitaMisuraKg === true) {
          me.unitaMisura = "Kg";
        } else {
          me.unitaMisura = "Pz.";
        }
        me.rigaDocumentoSelezionata.articolo =
          me.articoliOption[0].codiceArticolo;
        me.filtroBustaContenitore.codiceArticolo =
          me.articoliOption[0].codiceArticolo;
        me.rigaDocumentoSelezionata.articoloDescrizione =
          me.articoliOption[0].descrizioneArticolo;
        if (me.controlloSalsetta()) {
          me.qualitaOption = [];
          me.pezzaturaOption = [];
          me.lavorazioneOption = [];
          me.trattamentoOption = [];

        }
        me.loadBusta();
        me.loadContenitori();
      });
    },
    loadBusta() {
      let me = this;
      me.bustaOption = [];
      let link = process.env.VUE_APP_PATH_API + "/buste";
      axios
        .get(link, { params: me.filtroBustaContenitore })
        .then((response) => {
          me.bustaOption = response.data.data.list;
          if (me.bustaOption.length === 1) {
            me.rigaDocumentoSelezionata.busta = me.bustaOption[0].codiceBusta;
          }
        });
    },
    loadContenitori() {
      let me = this;
      me.contenitoreOption = [];
      let link = process.env.VUE_APP_PATH_API + "/contenitori";
      axios
        .get(link, { params: me.filtroBustaContenitore })
        .then((response) => {
          me.contenitoreOption = response.data.data.list;
          if (me.contenitoreOption.length === 1) {
            me.rigaDocumentoSelezionata.contenitore = me.contenitoreOption[0].codiceContenitore;
          }
        });
    },
    notBefore(date) {
      return date < Date.now() - 86400000;
    },
    setDescrizioneBusta(value) {
      let me = this;
      me.bustaOption.forEach((element) => {
        if (element.codiceBusta === value) {
          me.rigaDocumentoSelezionata.bustaDescrizione =
            element.descrizioneBusta;
        }
      });
    },
    setDescrizioneContenitore(value) {
      let me = this;
      me.contenitoreOption.forEach((element) => {
        if (element.codiceContenitore === value) {
          me.rigaDocumentoSelezionata.contenitoreDescrizione =
            element.descrizione;
        }
      });
    },
    controlloSalsetta() {
      let returnValue = false;
      let me = this;
      me.prodottoOption.forEach((element) => {
        if (element.codiceProdotto === me.filtroArticolo.prodotto) {
          if (element.descrizione === "SALSETTA") {
            returnValue = true;
          }
        }
        //filtroArticolo.prodotto" class="mb-2 mr-sm-2 mb-sm-0" :options="prodottoOption" :value="null" value-field="codiceProdotto" text-field="descrizione" @change="loadQualita()">
      });
      return returnValue;
    },
    onValidaQuantita() {
      let me = this;
      me.rigaValida = true;
      let salsetta = me.controlloSalsetta();
      me.rigaValidaErrore = "";
      let qt = 0;
      qt = parseFloat(me.quantita);
      // console.log(qt);
      if (!salsetta) {
        if (qt < me.articoliOption[0].kgMinimoOrdine) {
          me.rigaValida = false;
          me.rigaValidaErrore = "Quantita' minima ordine non rispettata";
        } else {
          let peso = 0
          me.pezzaturaOption.forEach((element) => {
            if (element.codicePezzatura === me.articoliOption[0].pezzatura) {
              peso = element.peso;
            }
          });
          // console.log(qt, peso);
          if (peso < 1 && this.articoliOption[0].unitaMisuraKg) {
            peso = peso * 1000;
            qt = qt * 1000;
          }
          let resto = qt % peso;
          // console.log(qt, " % ", peso, " resto ", resto);
          if (me.articoliOption[0].unitaMisuraKg) {
            if (resto > 0 && (!me.articoliOption[0].eccessoBusta && !me.articoliOption[0].difettoBusta)) {
              me.rigaValida = false;
              me.rigaValidaErrore = "Quantita inserita non rispetta multiplo";
            }
            else if (qt < me.articoliOption[0].kgMinimoOrdine) {
              me.rigaValida = false;
              me.rigaValidaErrore = "Quantita inserita minore della quantita minima ordinabile";
            }
          } else if (me.articoliOption[0].unitaMisuraPz) {
            let totale = peso * qt;
            if (totale < me.articoliOption[0].kgMinimoOrdine) {
              me.rigaValida = false;
              me.rigaValidaErrore = "Quantita inserita minore della quantita minima ordinabile";
            }
          }
        }
      }
    },
  },
};
</script>

<style></style>
