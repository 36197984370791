<template>
  <sa-page-layout
    :btnBackVisible="true"
    :linkback="linkback"
    :btnEditVisible="true"
    :linkedit="linkedit"
    :data="jsonData"
    :showModalLoading="showModalLoading"
    :pathResource="pathResource"
    class="sa-no-space"
  >
    <template slot="table-body">
      <strutture-presidi-view-component
        ref="StrutturePresidiViewComponent"
        :showModalLoading="showModalLoading"
        @update="onUpdateJsonData"
        @beforeLoadData="onBeforeLoadData"
        @afterLoadData="onAfterLoadData"
      ></strutture-presidi-view-component>
    </template>
  </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import StrutturePresidiViewComponent from "../components/StrutturePresidiViewComponent.vue";
export default {
  components: { SaPageLayout, StrutturePresidiViewComponent },
  data() {
    return {
      showModalLoading: false,
      pathResource: "",
      linkback: "/strutturapresidi",
      linkedit: null,
      jsonData: {},
    };
  },
  mounted: function () {
    let me = this;
    me.id = this.$route.params.id;
    me.linkedit = "/strutturapresidi/edit/" + me.id;
    //me.loadData();
  },
  methods: {
    onUpdateJsonData(data) {
      let me = this;
      me.jsonData = data;
    },
    onBeforeLoadData() {},
    onAfterLoadData() {},
  },
};
</script>

<style></style>
