<template>
	<div class="sa-free-page">
		<div class="sa-free-page-header"></div>
		<div class="sa-free-page-body">
			<div style="height: 100%; background-color: transparent; margin-bottom: 10px; padding-bottom: 10px">
				<pivot width="100%" height="100%" toolbar ref="pivotRef" v-bind:report="reportJson" :key="pivotKey" :beforetoolbarcreated="customizeToolbar" :reportcomplete="onReportComplete" />
			</div>
		</div>
	</div>
</template>

<script>
import { Pivot } from "@webdatarocks/vue-webdatarocks";
import "@webdatarocks/webdatarocks/webdatarocks.css";
import "@webdatarocks/webdatarocks/webdatarocks.googlecharts.js";
import axios from "axios";
export default {
	components: {
		Pivot,
	},
	data() {
		return {
			//id: "ec0ab16a-2bce-43c0-a9f8-061d638b4968",
			id: null,
			idQuery: null,
			pathResourceData: "/dataanalysisdatabaseinfo/eseguianaliticquery",
			pivotKey: 0,
			dashboardParams: {},
			reportJson: {
				dataSource: { data: [] },
				slice: {
					rows: [
						{
							caption: "UOSD di Diagnosi",
							uniqueName: "uosd_diagnosi",
						},
					],
					columns: [
						{
							caption: "Anno",
							uniqueName: "data_segnalazione.Year",
						},
						{
							caption: "Mese",
							uniqueName: "data_segnalazione.Month",
						},
						{
							caption: "Giorno",
							uniqueName: "data_segnalazione.Day",
						},
						{
							uniqueName: "Measures",
						},
					],
					measures: [
						{
							caption: "UOSD di Diagnosi",
							uniqueName: "uosd_diagnosi",
							aggregation: "count",
							availableAggregations: ["count", "distinctcount"],
						},
					],
				},
				localization: "lang/pivottable/it.json",
			},
			customizeToolbar: (toolbar) => {
				// Get all tabs from the Toolbar
				let tabs = toolbar.getTabs();
				if (tabs.length > 0) {
					// Delete the Connect tab
					tabs = tabs.filter((tab) => tab.id !== "wdr-tab-connect");
					tabs = tabs.filter((tab) => tab.id !== "wdr-tab-fullscreen");
					tabs = tabs.filter((tab) => tab.id !== "wdr-tab-open");
					tabs = tabs.filter((tab) => tab.id !== "wdr-tab-options");
					tabs = tabs.filter((tab) => tab.id !== "wdr-tab-save");
					tabs = tabs.filter((tab) => tab.id !== "wdr-tab-format");
				}
				toolbar.getTabs = () => tabs;
			},
			chartData: [
				["Year", "Sales", "Expenses", "Profit"],
				["2014", 1000, 400, 200],
				["2015", 1170, 460, 250],
				["2016", 660, 1120, 300],
				["2017", 1030, 540, 350],
			],
			itemsData: [],
		};
	},
	// computed: {
	// 	customizeToolbar:
	// },
	created() {
		this.id = this.$route.params.id;
		this.idQuery = this.$route.query.idQuery;
		//console.log();
	},
	mounted() {
		this.loadData();
	},
	methods: {
		loadData() {
			let me = this;
			let link = process.env.VUE_APP_PATH_API + me.pathResourceData + "/";
			axios.get(link + me.idQuery)
				.then((response) => {
					//let dataSource = {},
					me.reportJson.dataSource.data = response.data.data;
					if (me.id === "1") {
						me.reportJson.dataSource.data = response.data.data;
						me.reportJson.dataSource.data.unshift({ uosd_diagnosi: { type: "string" }, malattia_segnalata: { type: "string" }, stato_attuale: { type: "string" }, data_segnalazione: { type: "date", caption: "Data Segnalazione" } });
					} else if (me.id === "3") {
						console.log("Tre");
						me.reportJson.slice = {
							rows: [
								{
									caption: "Malattia Segnalata",
									uniqueName: "malattia_segnalata",
								},
							],
							columns: [
								{
									caption: "Struttura",
									uniqueName: "denominazione",
								},
								{
									caption: "Anno",
									uniqueName: "data_segnalazione.Year",
								},
								{
									caption: "Mese",
									uniqueName: "data_segnalazione.Month",
								},
								{
									uniqueName: "Measures",
								},
							],
							measures: [
								{
									uniqueName: "denominazione",
									aggregation: "count",
									availableAggregations: ["count", "distinctcount"],
								},
							],
							sorting: {
								column: {
									type: "desc",
									tuple: [],
									measure: "denominazione",
								},
							},
						};
						me.reportJson.dataSource.data = response.data.data;
						me.reportJson.dataSource.data.unshift({ denominazione: { type: "string" }, malattia_segnalata: { type: "string" }, data_segnalazione: { type: "date", caption: "Data Segnalazione" } });
					}

					me.pivotKey++;
				})
				.catch((e) => {
					console.log(e);
				});
		},
		setdashboardParams(dashboardParams) {
			let me = this;
			me.dashboardParams = dashboardParams;
		},
		onReportComplete() {
			let me = this;
			//let pivotRef = this.$refs.pivotRef;
			// Unsubscribing from reportcomplete
			// We need it only to track the initialization of WebDataRocks
			//pivotRef.value.webdatarocks.off("reportComplete");
			me.createChart();
		},
		createChart() {
			let me = this;
			let pivotRef = this.$refs.pivotRef;
			// console.log(pivotRef);
			// pivotRef.webdatarocks.googlecharts.getData(
			// 	{
			// 		type: "row",
			// 	},
			// 	// Function called when data for the chart is ready
			// 	(chartConfig) => {
			// 		(me.chartData = chartConfig.data), console.log("Sisto");
			// 	},
			// 	// Function called on report changes (filtering, sorting, etc.)
			// 	(chartConfig) => (me.chartData = chartConfig.data)
			// );
			pivotRef.webdatarocks.getData(
				{
					type: "column",
				},
				// Function called when data for the chart is ready
				me.drawChart,
				// Function called on report changes (filtering, sorting, etc.)
				me.drawChart
			);
		},
		drawChart(pivotData) {
			let me = this;
			// console.log(pivotData);
			// console.log(pivotData.meta.cAmount);
			// console.log(pivotData.meta.rAmount);
			let rows = pivotData.data.filter((rec) => rec.r0 && rec.c0);
			let columns = rows.map((rec) => rec.c0);
			let labelsColumn = rows.map((rec) => rec.r0);
			//let labels = pivotData.data.map((rec) => rec.r0);
			let chartLabels = [...new Set(labelsColumn)];
			let chartColumns = [...new Set(columns)];
			let tableData = [chartLabels];
			for (let chartColumn of chartColumns) {
				let riga = [chartColumn];
				for (let chartLabel of chartLabels) {
					let x = rows.reduce((row, currentRow) => (row.r0 === chartLabel && row.c0 === chartColumn ? row : currentRow)).v0;
					riga.push(x);
				}
				tableData.push(riga);
			}
			chartLabels.unshift("Prova");
			me.chartData = tableData;
		},
	},
};
</script>

<style>
/* .wdr-ui-element {
	height: 700px !important;
} */
</style>
