<template>
    <b-modal scrollable b-modal title="Nuova Impegnativa" ref="mdlNuovaImpegnativa" id="mdlNuovaImpegnativa" size="xl" @show="showNuoviDatiFatturazione" centered no-close-on-backdrop no-close-on-esc hide-header-close>
        <div class="sa-tab-scroll">
            <b-card class="sa-card sa-card-border" header="Dati Ospite e Residenza Storica" header-tag="header" footer-tag="footer" title="">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <label>Ospite</label>
                        <b-form-input autocomplete="off" id="inline-form-custom-select-pref" v-model="ospite" class="mb-2 mr-sm-2 mb-sm-0" readonly></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <label>Provincia</label>
                        <b-form-select id="inline-form-custom-select-pref" v-model="provincia" class="mb-2 mr-sm-2 mb-sm-0" :value="''" value-field="sigla" text-field="provincia" :options="provinciaOptions" @change="onChangeProvincia" required></b-form-select>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <label>ASL</label>
                        <b-form-input autocomplete="off" id="inline-form-custom-select-pref" v-model="jsonData.descrizioneAsl" class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                        <label>Citta'</label>
                        <b-form-select id="inline-form-custom-select-pref" v-model="comuneCodiceIstat" class="mb-2 mr-sm-2 mb-sm-0" :value="null" value-field="codiceIstat" text-field="comune" :options="comuniOptions" @change="onChangeComune" required></b-form-select>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                        <label>Ambito</label>
                        <b-form-input id="inline-form-custom-select-pref" autocomplete="off" v-model="ambitoAsl" class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                        <label>Distretto</label>
                        <b-form-input id="inline-form-custom-select-pref" autocomplete="off" v-model="jsonData.descrizioneDistretto" class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                        <label>Tipo Impegnativa</label>
                        <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.tipoImpegnativa" class="mb-2 mr-sm-2 mb-sm-0" :value="''" value-field="codiceFlusso" text-field="tipo" :options="tipoImpegnativaOptions" required @input="onInputTipoImpegnativa">
                            <template #first> <b-form-select-option :value="null"> -Seleziona un'opzione- </b-form-select-option></template>
                        </b-form-select>
                    </b-col>
                </b-row>
            </b-card>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-card class="sa-card sa-card-border" header="Prestazioni" header-tag="header" footer-tag="footer" title="">
                        <b-row>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <label>Tipo di Prestazione</label>
                                <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.tipoPrestazione" class="mb-2 mr-sm-2 mb-sm-0" :value="''" value-field="value" text-field="text" :options="tipoPrestazioniOptions" @change="onChangeTipoPrestazione" required>
                                    <!-- <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option> -->
                                </b-form-select>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <label>Onere della Prestazione</label>
                                <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.onerePrestazione" class="mb-2 mr-sm-2 mb-sm-0" :value="''" value-field="value" text-field="text" :options="onerePrestazioneOptions" required></b-form-select>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-card class="sa-card sa-card-border" header="Trattamento" header-tag="header" footer-tag="footer" title="">
                        <b-row>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label>Tipo Trattamento</label>
                                <b-form-input id="inline-form-custom-select-pref" autocomplete="off" v-model="jsonData.trattamentoTipo" class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label>Data Inizio Trattamento</label>
                                <date-picker format="DD/MM/YYYY" value-type="timestamp" v-model="jsonData.trattamentoDataInizio" type="date" @change="onChangecalcolaNumeroGiorniTrattamento"></date-picker>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label>Data Fine Trattamento</label>
                                <date-picker format="DD/MM/YYYY" value-type="timestamp" v-model="jsonData.trattamentoDataFine" type="date" @change="onChangecalcolaNumeroGiorniTrattamento"></date-picker>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label>Numero giorni Trattamento</label>
                                <b-input-group class="mb-3">
                                    <b-form-input autocomplete="off" id="inline-form-custom-select-pref" v-model="jsonData.numeroGiorniTrattamento" class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>
                                    <b-button size="sm" variant="" @click="onClickCalcolaDataFineTrattamento"><b-icon icon="calculator" :disabled="jsonData.trattamentoDataInizio === null"></b-icon></b-button>
                                </b-input-group>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-card class="sa-card sa-card-border" header="Quote" header-tag="header" footer-tag="footer" title="">
                        <b-row>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <label>Quota Giornaliera(€)</label>
                                <b-form-input autocomplete="off" id="inline-form-custom-select-pref" v-model="jsonData.quotaTotale" class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                <label>Quota ASL(%)</label>
                                <b-form-input autocomplete="off" id="inline-form-custom-select-pref" v-model="jsonData.quotaAslPercentuale" class="mb-2 mr-sm-2 mb-sm-0" @input="calcolaQuoteImporto"></b-form-input>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                <label>Quota Comune(%)</label>
                                <b-form-input autocomplete="off" id="inline-form-custom-select-pref" v-model="jsonData.quotaComunePercentuale" class="mb-2 mr-sm-2 mb-sm-0" @input="calcolaQuoteImporto"></b-form-input>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                <label>Quota Utente(%)</label>
                                <b-form-input autocomplete="off" id="inline-form-custom-select-pref" v-model="jsonData.quotaUtentePercentuale" class="mb-2 mr-sm-2 mb-sm-0" @input="calcolaQuoteImporto"></b-form-input>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                <label>Quota ASL(€)</label>
                                <b-form-input autocomplete="off" id="inline-form-custom-select-pref" v-model="jsonData.quotaAsl" class="mb-2 mr-sm-2 mb-sm-0" @input="calcolaQuotePercentuale"></b-form-input>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                <label>Quota Comune(€)</label>
                                <b-form-input autocomplete="off" id="inline-form-custom-select-pref" v-model="jsonData.quotaComune" @input="calcolaQuotePercentuale" class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                <label>Quota Utente(€)z</label>
                                <b-form-input autocomplete="off" id="inline-form-custom-select-pref" v-model="jsonData.quotaUtente" @input="calcolaQuotePercentuale" class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-card class="sa-card sa-card-border" header="Autorizzazione e Altre Informazioni" header-tag="header" footer-tag="footer" title="">
                        <b-row>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label>Numero di Autorizzazione</label>
                                <b-form-input autocomplete="off" id="inline-form-custom-select-pref" v-model="jsonData.numeroAutorizzazione" class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label>Data di Autorizzazione</label>
                                <date-picker format="DD/MM/YYYY" value-type="timestamp" v-model="jsonData.dataAutorizzazione" type="date"></date-picker>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label>Codice Medico Prescrittore</label>
                                <b-form-input autocomplete="off" id="inline-form-custom-select-pref" v-model="jsonData.medicoPrescrittoreCodice" class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label>Tipo Medico Prescrittore</label>
                                <b-form-input autocomplete="off" id="inline-form-custom-select-pref" v-model="jsonData.medicoPrescrittoreTipo" class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label>Menomazione</label>
                                <b-form-input autocomplete="off" id="inline-form-custom-select-pref" v-model="jsonData.diagnosiIcdixCodice" class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label>Diagnosi ICD9 </label>
                                <b-input-group class="mb-3">
                                    <b-form-input autocomplete="off" id="inline-form-custom-select-pref" v-model="jsonData.diagnosiIcdixDescrizione" class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>
                                    <b-button size="sm" variant="" @click="onClickDiagnosiIcdIx">
                                        <b-icon icon="search"> </b-icon>
                                    </b-button>
                                </b-input-group>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
            <b-card class="sa-card sa-card-border" header="Dettagli" header-tag="header" footer-tag="footer" title="">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label>Data della Prescrizione</label>
                        <date-picker format="DD/MM/YYYY" value-type="timestamp" v-model="jsonData.dataPrescrizione" type="date"></date-picker>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label>Stato</label>
                        <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.statoAttuale" class="mb-2 mr-sm-2 mb-sm-0" :options="statoAttualeOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <label class="sa-label-data">Note</label>
                        <b-form-textarea v-model="jsonData.note" id="textarea-auto-height" placeholder="" rows="6"></b-form-textarea>
                    </b-col>
                </b-row>
            </b-card>
            <diagnosi-icd-ix-component ref="cmpDiagnosiIcdIxComponent" @onModalClose="onModalClose" :hidden="true"></diagnosi-icd-ix-component>
        </div>
        <template #modal-footer>
            <b-button size="md" @click="onClickUndo" variant="outline-danger">Annulla</b-button>
            <b-button size="md" @click="onSalvaImpegnativa" variant="outline-success">Salva</b-button>
        </template>
    </b-modal>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import UtilityMixin from "../../../utility/UtilityMixin";
import DiagnosiIcdIxComponent from "../../../utility/components/DiagnosiIcdIxComponent.vue";
export default {
    props: { jsonDataAccettazione: { type: Object }, idAccettazione: { type: String } },
    mixins: [UtilityMixin],
    components: { DatePicker, DiagnosiIcdIxComponent },
    data() {
        return {
            listKey: 0,
            pathResource: "/rsaimpegnativa",
            pathResourceComuniByCap: "/comuni/cap",
            //pathResourceRegioni: "/regioni",
            pathResourceOnerePrestazione: "/rsaimpegnativaonerispesa",
            pathResourceAslByComune: "/asl/comune",
            pathResourceTipoImpegnativa: "/rsaimpegnativatipo",
            pathResourceProvince: "/province",
            pathResourceComuniByProvincia: "/comuni/provincia",
            pathResourceTipoPrestazioni: "/rsatipoprestazioni",
            pathResourceTools: "/tools",
            nazione: "",
            provincia: null,
            provinciaOptions: [],
            ospite: "",
            //quotaTotale: "",
            regione: "",
            ambitoAsl: "",
            comuneCodiceIstat: "",
            jsonData: {
                codiceAsl: "",
                codiceDistretto: "",
                dataAutorizzazione: null,
                dataPrescrizione: "",
                descrizioneAsl: "",
                descrizioneDistretto: "",
                diagnosiIcdixCodice: "",
                diagnosiIcdixDescrizione: "",
                idAccettazione: "",
                medicoPrescrittoreCodice: "",
                medicoPrescrittoreTipo: "",
                note: "",
                numeroAutorizzazione: "",
                numeroGiorniTrattamento: null,
                onerePrestazione: "",
                quotaAsl: "",
                quotaAslPercentuale: "",
                quotaComune: "",
                quotaComunePercentuale: "",
                quotaUtente: "",
                quotaTotale: "",
                quotaUtentePercentuale: "",
                statoAttuale: null,
                statoAttualeDataOra: "",
                tipoImpegnativa: "",
                tipoPrestazione: null,
                trattamentoDataFine: "",
                trattamentoDataInizio: new Date().getTime(),
                trattamentoTipo: "",
            },
            filtro: {},
            filtroComune: {},
            nazioneOptions: [],
            comuniOptions: [{ codiceIstat: "", comune: "-Seleziona Comune-" }],
            regioniOptions: [],
            cittaoptions: [],
            onerePrestazioneOptions: [],
            tipoImpegnativaOptions: [],
            listaTipoPrestazioni: [],
            tipoPrestazioniOptions: [],
            /* tipiImpegnativaAggiunti: [], */
            statoAttualeOptions: [
                { text: "-Seleziona Stato-", value: null },
                { text: "IN ATTESA", value: "IN ATTESA" },
                { text: "AUTORIZZATO", value: "AUTORIZZATO" },
                { text: "SCADUTO", value: "SCADUTO" },
                { text: "CHIUSO", value: "CHIUSO" },
            ],
        };
    },
    mounted() {
        //   let me = this;
        // me.loadNazione();
        // me.loadProvince();
        // me.setData();
    },
    computed: {},
    watch: {
        "jsonData.tipoImpegnativa": function (newTipoImpegnativa) {
            /*   this.loadTipoPrestazioni(); */
            this.onInputTipoImpegnativa(newTipoImpegnativa);
        },
        immediate: true,
    },
    methods: {
        show(jsonData) {
            let me = this;
            /* console.log(jsonData); */

            // if (isNew == true) {
            //     me.jsonData = {};
            // }

            me.loadTipoImpegnativa();
            //me.quotaTotale = 0;
            me.loadComuni();
            me.loadOnerePrestazione();
            me.loadProvince();
            me.jsonData = jsonData;
            me.$refs.mdlNuovaImpegnativa.show();
            //me.loadAsl(me.jsonDataAccettazione.accettazioneAnagrafica.comuneDomicilioCodiceIstat);
        },
        showNuoviDatiFatturazione() {
            let me = this;
            // me.jsonData = {};
            me.$refs.mdlNuovaImpegnativa.show();
        },
        close() {
            let me = this;
            me.jsonData = {};
            me.$refs.mdlNuovaImpegnativa.hide();
        },
        loadData(id) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + id;
            axios
                .get(link)
                .then((response) => {
                    me.setData(id);

                    me.jsonData = response.data.data;
                    //me.setQuotaGiornaliera(me.jsonData);
                    //me.loadTipoPrestazioni(me.jsonData.tipoImpegnativa);
                    // if (me.jsonData.tipoImpegnativa) {
                    // 	me.aggiornaPrestazioniDisponibili(me.jsonData.tipoImpegnativa);
                    // }
                })

                .catch((error) => {
                    this.$bvToast.toast("Dati della Fatturazione non presenti", {
                        title: "ATTENZIONE!",
                        variant: "danger",
                        solid: true,
                    });
                    console.log("Il servizio delle Impegnative ha datao errore: " + error.response);
                });
        },

        loadTipoImpegnativa() {
            let me = this;
            UtilityMixin.methods.loadDataResources(me.pathResourceTipoImpegnativa, null, me.loadTipoImpegnativaSuccess, me.loadTipoImpegnativaError);
        },
        loadTipoImpegnativaSuccess(response) {
            let me = this;
            me.tipoImpegnativaOptions = [];
            me.tipoImpegnativaOptions.push({ codiceFlusso: null, tipo: "-Seleziona Prestazione-" });
            me.tipoImpegnativaOptions = response.data.data.list;
            // console.log("LoadImpegnativa", me.tipoImpegnativaOptions);

            // me.tipoImpegnativaOptions = [];
            // let tipiImpegnativaAggiunti = new Set();
            // response.data.data.list.forEach((element) => {
            // 	if (!tipiImpegnativaAggiunti.has(element.tipoImpegnativa)) {
            // 		me.tipoImpegnativaOptions.push({
            // 			value: element.tipoImpegnativa,
            // 			text: element.impegnativaTipo || element.tipoImpegnativa,
            // 		});
            // 		tipiImpegnativaAggiunti.add(element.tipoImpegnativa);
            // 	}
            // });
        },
        loadTipoImpegnativaError(error) {
            console.log("Il servizio del Tipo Impegnativa ha dato errore: " + error.response);
        },
        //
        loadTipoPrestazioni(value) {
            let me = this;
            UtilityMixin.methods.loadDataResources(me.pathResourceTipoPrestazioni + "/codiceflussoimpegnativa", { codiceFlussoImpegnativa: value }, me.loadTipoPrestazioniSuccess, me.loadTipoPrestazioniError);
        },
        loadTipoPrestazioniSuccess(response) {
            let me = this;
            me.listaTipoPrestazioni = response.data.data.list;
            // Resetta l'array delle opzioni ogni volta che chiamo il metodo.
            me.tipoPrestazioniOptions = [];
            me.tipoPrestazioniOptions.push({ value: null, text: "-Seleziona Prestazione-" });

            me.listaTipoPrestazioni.forEach((element) => {
                me.tipoPrestazioniOptions.push({
                    value: element.id,
                    text: element.nome || element.id,
                });
            });
        },
        loadTipoPrestazioniError(response) {
            console.log(response);
        },

        /* aggiornaPrestazioniDisponibili(tipoImpegnativaSelezionata) {
            let me = this;

            const currentTipoPrestazione = me.jsonData.tipoPrestazione;
            if (!tipoImpegnativaSelezionata) {
                me.tipoPrestazioniOptions = [{ value: null, text: "-Selezionare una impegnativa-" }];
                me.jsonData.tipoPrestazione = null;
                return;
            }
            me.tipoPrestazioniOptions = [];
            let prestazioniPerImpegnativa = me.listaTipoPrestazioni.filter((element) => element.tipoImpegnativa === tipoImpegnativaSelezionata);

            prestazioniPerImpegnativa.forEach((element) => {
                me.tipoPrestazioniOptions.push({
                    value: element.prestazione,
                    text: element.prestazioneNome || element.prestazione,
                });
            });

            C
            const isStillValid = prestazioniPerImpegnativa.some((element) => element.prestazione === currentTipoPrestazione);
            if (!isStillValid) {
                me.jsonData.tipoPrestazione = null;
            }
        }, */

        loadComuni() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceComuniByCap;
            me.filtroComune.cap = me.jsonDataAccettazione.accettazioneAnagrafica.capDomicilio;
            axios
                .get(link, { params: me.filtroComune })
                .then((response) => {
                    me.comuniOptions = response.data.data;
                    me.provincia = response.data.data[0].provincia;
                })
                .catch((error) => {
                    console.log("Il servizio dei comuni ha datao errore: " + error.response);
                });
        },
        loadNuoviComuni(sigla) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceComuniByProvincia + "/" + sigla;
            axios
                .get(link)
                .then((response) => {
                    me.comuneCodiceIstat = null;
                    me.comuniOptions = response.data.data;
                    me.comuniOptions.unshift({ codiceIstat: null, comune: "-Seleziona Comune-" });
                })
                .catch((error) => {
                    console.log("Il servizio dei comuni ha datao errore: " + error.response);
                });
        },
        loadProvince() {
            let me = this;
            me.provinciaOptions = [];
            let link = process.env.VUE_APP_PATH_API + me.pathResourceProvince;
            axios
                .get(link)
                .then((response) => {
                    me.provinciaOptions = response.data.data;
                    me.provinciaOptions.unshift({ sigla: null, provincia: "-Seleziona Provincia-" });
                })
                .catch((error) => {
                    console.log("Il servizio delle province ha datao errore: " + error.response.status);
                });
        },
        onChangeProvincia(provincia) {
            let me = this;
            me.comuniOptions = [];
            me.loadNuoviComuni(provincia);
        },
        onChangeComune(value) {
            let me = this;
            me.loadAsl(value);
        },
        loadOnerePrestazione() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceOnerePrestazione;
            me.onerePrestazioneOptions = [];
            axios
                .get(link)
                .then((response) => {
                    response.data.data.list.forEach((element) => {
                        me.onerePrestazioneOptions.push({ value: element.onere, text: element.onere });
                    });
                    me.onerePrestazioneOptions.unshift({ value: null, text: "-Seleziona Onere-" });
                })
                .catch((error) => {
                    console.log("Il servizio Onere Prestazioni ha datao errore: " + error.response);
                });
        },
        setData() {
            let me = this;
            // console.log(me.jsonDataAccettazione);
            // me.jsonData.codiceAsl = me.jsonDataAccettazione.accettazioniAsl.aslDomicilioCodice;
            // me.jsonData.descrizioneAsl = me.jsonDataAccettazione.accettazioniAsl.aslDomicilioDenominazione;
            // me.jsonData.statoAttuale = me.jsonDataAccettazione.statoAttuale;
            //me.jsonData.diagnosiIcdixCodice = "Diagnosi";
            //me.jsonData.diagnosiIcdixDescrizione = "Descrizione";
            me.comuneCodiceIstat = me.jsonDataAccettazione.accettazioneAnagrafica.comuneDomicilioCodiceIstat;
            me.jsonData.idAccettazione = me.jsonDataAccettazione.id;
            me.jsonData.statoAttualeDataOra = me.jsonDataAccettazione.statoAttualeDataOra;
            me.ospite = me.jsonDataAccettazione.accettazioneAnagrafica.nome + " " + me.jsonDataAccettazione.accettazioneAnagrafica.cognome;
            me.jsonData.descrizioneDistretto = me.jsonDataAccettazione.accettazioniAsl.aslDomicilioDistretto;
            me.ambitoAsl = me.jsonDataAccettazione.accettazioniAsl.aslDomicilioAmbito;
            me.loadAsl(me.comuneCodiceIstat);
        },
        onClickUndo() {
            let me = this;
            me.$refs.mdlNuovaImpegnativa.hide();
        },
        onSalvaImpegnativa() {
            let me = this;
            UtilityMixin.methods.saveResource(me.pathResource, me.jsonData, me.saveSucessResponse, me.saveErrorResponse);
        },
        saveSucessResponse(response) {
            this.$emit("successRestCall", response);
            this.$emit("afterSaveRequest");
        },
        saveErrorResponse(response) {
            if (response.response.status === 400) {
                this.$bvModal.msgBoxOk(response.response.data.messaggio, {
                    title: "ATTENZIONE",
                    size: "sm",
                    okVariant: "outline-secondary",
                    headerClass: "sa-msg-header-danger",
                    footerClass: "p-2 border-top-0",
                    centered: true,
                });
            } else {
                this.$bvToast.toast("Errore nel salvataggio" + response.message, {
                    title: "Errore",
                    variant: "danger",
                    solid: true,
                });
            }
        },
        onChangecalcolaNumeroGiorniTrattamento() {
            let me = this;
            me.calcolaNumeroGiorniTrattamento(me.jsonData.trattamentoDataInizio, me.jsonData.trattamentoDataFine);
        },
        calcolaNumeroGiorniTrattamento(inizioTrattamento, fineTrattamento) {
            let me = this;
            if (inizioTrattamento && fineTrattamento) {
                // if (fineTrattamento >= inizioTrattamento) {
                let differenza = fineTrattamento - inizioTrattamento;
                me.jsonData.numeroGiorniTrattamento = Math.ceil(differenza / (1000 * 3600 * 24));
                // } else {
                // me.jsonData.numeroGiorniTrattamento = "DATE NON VALIDE";
                // }
            }
        },
        /* setQuotaGiornaliera(data) {
            let me = this;
            me.quotaTotale = data.quotaAsl + data.quotaComune + data.quotaUtente;
        }, */
        loadAsl(codiceIstat) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceAslByComune;
            let params = { istatComune: codiceIstat };
            if (codiceIstat) {
                axios
                    .get(link, { params: params })
                    .then((response) => {
                        me.jsonData.codiceAsl = response.data.data.codiceRegione + response.data.data.codiceAzienda;
                        me.jsonData.descrizioneAsl = response.data.data.denominazione;
                        me.jsonData.codiceDistretto = response.data.data.codiceAzienda;
                        //me.jsonData.descrizioneDistretto = null;
                        //me.ambitoAsl = null;
                    })
                    .catch((error) => {
                        console.log("Il servizio delle ASL ha datao errore: " + error);
                    });
            }
        },
        onChangeTipoPrestazione() {
            let me = this;
            me.listaTipoPrestazioni.forEach((element) => {
                if (me.jsonData.tipoPrestazione === element.id) {
                    me.jsonData.quotaTotale = element.importo;
                    me.jsonData.quotaAslPercentuale = element.percentualeAsl;
                    me.jsonData.quotaAsl = element.quotaAsl;
                    /* console.log(element); */

                    // if (element.tipoQuota === "PERCENTUALE") {
                    //     me.jsonData.quotaAslPercentuale = element.quotaAsl;
                    //     //me.jsonData.quotaComunePercentuale = element.quotaComune;
                    //     //me.jsonData.quotaUtentePercentuale = element.quotaUtente;
                    // } else {
                    //     me.jsonData.quotaAsl = element.quotaAsl;
                    //     //me.jsonData.quotaComune = element.quotaComune;
                    //     //me.jsonData.quotaUtente = element.quotaUtente;
                    // }
                    me.calcolaQuote(element.importo);
                }
            });
        },
        calcolaQuote() {
            let me = this;
            if (me.jsonData.quotaTotale) {
                me.calcolaQuotePercentuale();
            }
            // if (tipoQuota === "PERCENTUALE") {
            //     me.calcolaQuoteImporto();
            //     me.jsonData.quotaAsl = (me.jsonData.quotaTotale * me.jsonData.quotaAslPercentuale) / 100;
            // } else if (tipoQuota === "IMPORTO") {
            //     me.calcolaQuotePercentuale();
            // }
        },
        calcolaQuotePercentuale() {
            let me = this;
            // me.quotaTotale;
            // me.jsonData.quotaAslPercentuale = Number((Number(me.jsonData.quotaAsl) / Number(me.quotaTotale)) * 100).toFixed(1);

            // if (me.jsonData.quotaComune > 0 && me.jsonData.quotaUtente > 0) {
            // me.jsonData.quotaComunePercentuale = Number((Number(me.jsonData.quotaComune) / Number(me.quotaTotale)) * 100).toFixed(1);
            // me.jsonData.quotaUtentePercentuale = Number((Number(me.jsonData.quotaUtente) / Number(me.quotaTotale)) * 100).toFixed(1);

            me.jsonData.quotaAslPercentuale = Number((Number(me.jsonData.quotaAsl) * 100) / Number(me.jsonData.quotaTotale)).toFixed(1);
            me.jsonData.quotaComunePercentuale = Number((Number(me.jsonData.quotaComune) * 100) / Number(me.jsonData.quotaTotale)).toFixed(1);
            me.jsonData.quotaUtentePercentuale = Number((Number(me.jsonData.quotaUtente) * 100) / Number(me.jsonData.quotaTotale)).toFixed(1);
            // }
        },
        calcolaQuoteImporto() {
            let me = this;
            let quotaPercentuale = Number(me.jsonData.quotaAslPercentuale) + Number(me.jsonData.quotaUtentePercentuale) + Number(me.jsonData.quotaComunePercentuale);
            if (quotaPercentuale === 100) {
                me.jsonData.quotaAsl = (me.jsonData.quotaTotale * me.jsonData.quotaAslPercentuale) / 100;
                me.jsonData.quotaComune = (me.jsonData.quotaTotale * me.jsonData.quotaComunePercentuale) / 100;
                me.jsonData.quotaUtente = (me.jsonData.quotaTotale * me.jsonData.quotaUtentePercentuale) / 100;
            }
        },
        onClickDiagnosiIcdIx() {
            let me = this;
            me.$refs.cmpDiagnosiIcdIxComponent.onClickBtnRicercaIcdIx();
        },
        onModalClose(selectedElement) {
            let me = this;
            let diagnosi = selectedElement[0];
            me.jsonData.diagnosiIcdixCodice = diagnosi[0].codiceIcdix;
            me.jsonData.diagnosiIcdixDescrizione = diagnosi[0].descrizioneIcdix;
        },
        onClickCalcolaDataFineTrattamento() {
            let me = this;
            //let link = me.pathResourceTools + "/modificaData";
            let params = { dataRiferimento: me.jsonData.trattamentoDataInizio, periodoDaModificare: "DAY", valoreDaAggiungere: me.jsonData.numeroGiorniTrattamento };
            //UtilityMixin.methods.loadDataResources(link, params, me.resultResponseDataFineTrattamento(), me.resultErrorDataFineTrattamento());
            let link = process.env.VUE_APP_PATH_API + me.pathResourceTools + "/modificaData";
            axios
                .get(link, { params: params })
                .then((response) => {
                    me.jsonData.trattamentoDataFine = response.data.data;
                })
                .catch((error) => {
                    this.$bvToast.toast("Errore: " + error.response.data.messaggio, {
                        title: "ATTENZIONE!",
                        variant: "danger",
                        solid: true,
                    });
                    // console.log("Il servizio errore: " + error.response);
                });
        },
        onInputTipoImpegnativa(value) {
            let me = this;
            me.loadTipoPrestazioni(value);
        },
        /*  onChangeTipoImpegnativa() {
            let me = this;
            me.loadTipoPrestazioni();
        }, */
    },
};
</script>
