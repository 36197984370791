<template>
    <b-modal scrollable b-modal title="Nuovo intervento programmato" ref="mdlNuovoInterventoProgrammato" id="mdlNuovoInterventoProgrammato" size="xl" @show="showNuovoInterventoProgrammato" centered no-close-on-backdrop no-close-on-esc hide-header-close>
        <div class="sa-tab-scroll">
            <!-- <b-card class="sa-card" header="Dati Ospite e Residenza Storica" header-tag="header" footer-tag="footer" title=""> -->
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label>Equipe</label>
                    <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.equipe" class="mb-2 mr-sm-2 mb-sm-0" :options="paiRuoliOptions" :value="null" value-field="value" text-field="text" @change="onChangeEquipe" required></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label>Specializzazione</label>
                    <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.attivita" class="mb-2 mr-sm-2 mb-sm-0" :options="paiRuoiliSpecializzazioniOptions" :value="null" value-field="value" text-field="text" :disabled="isAttivita" @change="onChangeAttivita" required></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label>Altra specializzazione</label>
                    <b-form-input v-model="jsonData.attivitaAltro" :disabled="isAttvitaAltro"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label>Frequenza settimanale</label>
                    <b-form-input autocomplete="off" id="inline-form-custom-select-pref" v-model="jsonData.frequenzaDurataGiorniSettimane" class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label>Frequenza mensile</label>
                    <b-form-input autocomplete="off" id="inline-form-custom-select-pref" v-model="jsonData.frequenzaDurataGiorniMesi" class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>
                </b-col>

                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label>Struttura erogante</label>
                    <b-form-input id="inline-form-custom-select-pref" autocomplete="off" v-model="jsonData.strutturaErogante" class="mb-2 mr-sm-2 mb-sm-0"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-input-group class="mb-3">
                        <label class="sa-label-data">
                            <b-button size="sm" variant="success" @click="onClickTipologiaAccesso"> <b-icon icon="search"></b-icon> Tipologia accesso </b-button>
                        </label>
                        <!-- <b-button size="sm" variant="" @click="onClickTipologiaAccesso"> Tipologia accesso <b-icon icon="search"></b-icon></b-button> -->
                    </b-input-group>
                    <!-- 
          <b-input-group class="mb-3"> -->
                    <b-form-textarea v-model="jsonData.tipologiaAccessiDescrizione" rows="3" :disabled="isTipologiaAccessiDescrizioneDisabled"></b-form-textarea>
                    <!--   <b-button size="sm" variant="" @click="onClickTipologiaAccesso"> <b-icon icon="search"></b-icon> </b-button>  
          </b-input-group> -->
                </b-col>
            </b-row>
        </div>
        <b-modal scrollable b-modal title="ICD-X" ref="mdlCodiciIcdX" id="mdlCodiciIcdX" size="xl" centered no-close-on-backdrop no-close-on-esc hide-header-close>
            <codici-icd-x-list-component @selected="onSelecteIcdX"></codici-icd-x-list-component>
        </b-modal>
        <diagnosi-icd-ix-component ref="cmpDiagnosiIcdIxComponent" @onModalClose="onModalDiagnosiIcdIxClose" :hidden="true"></diagnosi-icd-ix-component>
        <nomenclatore-list-component ref="cmpNomenclatoreListComponent" @selectedPrestazione="onSelectPrestazioneNomenclatore"></nomenclatore-list-component>
        <template #modal-footer>
            <b-button @click="onAddInterventoProgrammato" variant="outline-success">Aggiungi</b-button>
            <b-button size="md" class="no-text" variant="outline-danger" @click="onClickUndo">Annulla</b-button>
        </template>
    </b-modal>
</template>
<script>
//import axios from "axios";
import UtilityMixin from "../../../utility/UtilityMixin";
//import CodiciIcdXListComponent from "../../../configurazione/components/CodiciIcdXListComponent.vue";
import CodiciIcdXListComponent from "../../../utility/components/CodiciIcdXListComponent.vue";
import DiagnosiIcdIxComponent from "../../../utility/components/DiagnosiIcdIxComponent.vue";
import NomenclatoreListComponent from "../../../utility/components/NomenclatoreListComponent.vue";

export default {
    // props: {
    //      jsonDataAccettazione: { type: Object }, idAccettazione: { type: String }
    // },
    mixins: [UtilityMixin],
    components: { CodiciIcdXListComponent, DiagnosiIcdIxComponent, NomenclatoreListComponent },
    data() {
        return {
            listKey: 0,
            pathResource: "/rsapaiinterventiprogrammati",
            pathResourcePaiRuoli: "/rsapairuoli",
            pathResourcePaiRuoliSpecializzazione: "/rsapairuolospecializzazione",
            componentiUvmOptions: [],
            isAttivita: false,
            isAttvitaAltro: false,
            isTipologiaAccessiDescrizioneDisabled: true,
            //  campoDiagnosi: "",
            jsonData: {
                equipe: "",
                attivita: "",
                attivitaAltro: "",
                tipologiaAccessiCodice: "",
                tipologiaAccessiDescrizione: "",
                strutturaErogante: "",
                frequenzaDurataGiorniMesi: "",
                frequenzaDurataGiorniSettimane: "",
            },
            paiRuoliOptions: [],
            listaPaiRuoli: [],
            listaPaiRuoliSpecializzazione: [],
            paiRuoiliSpecializzazioniOptions: [],
        };
    },
    mounted() {
        //   let me = this;
        // me.loadNazione();
        // me.loadProvince();
        // me.setData();
    },
    computed: {},
    watch: {},
    methods: {
        show() {
            let me = this;
            me.isAttivita = true;
            me.isAttvitaAltro = true;
            //me.variantBtnTipologiaAccesso = "danger";
            me.jsonData = {
                equipe: "",
                attivita: "",
                attivitaAltro: "",
                tipologiaAccessiCodice: "",
                tipologiaAccessiDescrizione: "",
                strutturaErogante: "",
                frequenzaDurataGiorniMesi: "",
                frequenzaDurataGiorniSettimane: "",
            };
            me.$refs.mdlNuovoInterventoProgrammato.show();
            me.loadPaiRuoli();
        },
        showNuovoInterventoProgrammato() {
            let me = this;
            me.$refs.mdlNuovoInterventoProgrammato.show();
        },
        close() {
            let me = this;
            me.jsonData = {};
            me.$refs.mdlNuovoInterventoProgrammato.hide();
        },
        /* loadPaiRuoli */
        loadPaiRuoli() {
            let me = this;
            UtilityMixin.methods.loadDataResources(me.pathResourcePaiRuoli, {}, me.resultResponsePaiRuoli, me.resultErrorPaiRuoli);
        },
        resultResponsePaiRuoli(response) {
            let me = this;
            me.paiRuoliOptions = [];
            me.listaPaiRuoli = response.data.data.list;
            me.listaPaiRuoli.forEach((element) => {
                me.paiRuoliOptions.push({ value: element.ruolo, text: element.ruolo });
            });
            me.paiRuoliOptions.unshift({ value: "", text: "-Selezionare Valore-" });
        },
        resultErrorPaiRuoli(error) {
            UtilityMixin.methods.messaggeBoxError(this, error);
        },
        loadPaiRuoloSpecializzazioni(idRuolo) {
            let me = this;
            UtilityMixin.methods.loadDataResources(me.pathResourcePaiRuoliSpecializzazione, { idRuolo: idRuolo }, me.resultResponsePaiRuoloSpecializzazioni, me.resultErrorPaiRuoloSpecializzazioni);
        },
        resultResponsePaiRuoloSpecializzazioni(response) {
            let me = this;
            //  me.paiRuoliOptions = [];
            me.isAttivita = true;
            me.paiRuoiliSpecializzazioniOptions = [];
            if (response.data.data.recordsNumber > 0) {
                me.isAttivita = false;
                me.listaPaiRuoliSpecializzazione = [];
                me.listaPaiRuoliSpecializzazione = response.data.data.list;
                me.listaPaiRuoliSpecializzazione.forEach((element) => {
                    me.paiRuoiliSpecializzazioniOptions.push({ value: element.specializzazione, text: element.specializzazione });
                });
                me.paiRuoiliSpecializzazioniOptions.unshift({ value: "", text: "-Selezionare Valore-" });
            }
        },
        resultErrorPaiRuoloSpecializzazioni(error) {
            UtilityMixin.methods.messaggeBoxError(this, error);
        },
        onChangeEquipe(value) {
            let me = this;
            //me.variantBtnTipologiaAccesso = "";
            me.listaPaiRuoli.forEach((element) => {
                if (element.ruolo === value) {
                    me.loadPaiRuoloSpecializzazioni(element.id);
                    //  me.variantBtnTipologiaAccesso = "success";
                    //  me.btnTipologiaAccessi = element.tipologiaAccesso;
                    me.isTipologiaAccessiDescrizioneDisabled = true;
                    if (element.tipologiaAccesso === "TESTO LIBERO") {
                        me.isTipologiaAccessiDescrizioneDisabled = false;
                    }
                }
            });
        },
        onClickTipologiaAccesso() {
            let me = this;
            me.listaPaiRuoli.forEach((element) => {
                if (me.jsonData.equipe === element.ruolo) {
                    //  me.campoDiagnosi = element.btnTipologiaAccesso;
                    switch (element.tipologiaAccesso) {
                        case "FARMACI":
                            break;
                        case "ICD-IX":
                            me.$refs.cmpDiagnosiIcdIxComponent.onClickBtnRicercaIcdIx();
                            break;
                        case "ICD-X":
                            me.$refs.mdlCodiciIcdX.show();
                            break;
                        case "NOMENCLATORE":
                            me.$refs.cmpNomenclatoreListComponent.openModal();
                            break;
                        case "TESTO LIBERO":
                            break;
                    }
                }
            });
        },
        onModalDiagnosiIcdIxClose(selectedElement) {
            let me = this;
            let diagnosi = selectedElement[0];
            // switch (me.campoDiagnosi) {
            //   case "ICD-IX":
            me.jsonData.tipologiaAccessiCodice = diagnosi[0].codiceIcdix;
            me.jsonData.tipologiaAccessiDescrizione = diagnosi[0].descrizioneIcdix;
            // break;
            //  }
        },
        onSelecteIcdX(element) {
            let me = this;
            me.jsonData.tipologiaAccessiCodice = element.codice;
            me.jsonData.tipologiaAccessiDescrizione = element.descrizione;
        },
        onSelectPrestazioneNomenclatore(json) {
            let me = this;
            me.jsonData.tipologiaAccessiCodice = json.codicePrestazione;
            me.jsonData.tipologiaAccessiDescrizione = json.descrizione;
        },

        onClickUndo() {
            let me = this;
            me.$refs.mdlNuovoInterventoProgrammato.hide();
        },
        onAddInterventoProgrammato() {
            let me = this;
            let campiMancanti = [];
            if (me.jsonData.equipe === "") campiMancanti.push("Equipe");
            if (me.jsonData.strutturaErogante === "") campiMancanti.push("Struttura Erogante");
            if (campiMancanti.length === 0) {
                me.$emit("addInterventoProgrammato", me.jsonData);
                me.$refs.mdlNuovoInterventoProgrammato.hide();
            } else {
                this.$bvModal
                    .msgBoxOk("I seguenti campi sono obbligatori: " + campiMancanti.join(", "), {
                        title: "ATTENZIONE",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    })
                    .then((value) => {
                        console.log(value);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
            //    if (me.jsonData.equipe !== "" && me.jsonData.strutturaErogante !== "") {
            //     me.$emit("addInterventoProgrammato", me.jsonData);
            //     me.$refs.mdlNuovoInterventoProgrammato.hide();
            // } else {
            //     this.$bvModal
            //         .msgBoxOk("Campi OBBLIGATORI!", {
            //             title: "ATTENZIONE",
            //             size: "sm",
            //             okVariant: "outline-danger",
            //             headerClass: "sa-msg-header-danger",
            //             footerClass: "p-2 border-top-0",
            //             centered: true,
            //         })
            //         .then((value) => {
            //             console.log(value);
            //         })
            //         .catch((err) => {
            //             console.log(err);
            //         });
            // }
        },
        onChangeAttivita(value) {
            let me = this;
            me.isAttvitaAltro = true;
            if (value === "ALTRO") {
                me.isAttvitaAltro = false;
            }
        },
    },
};
</script>
