<template>
    <div style="height: 100%; overflow: auto">
        <b-tabs id="tabsPresidi" ref="tabsPresidi" nav-wrapper-class="sa-tab-items" content-class="mt-3" class="sa-tab" style="height: 100%; padding-bottom: 25px" fill>
            <b-tab title="Presidio">
                <b-card class="sa-card" header="Dettagli Struttura" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Denominazione</label>
                            <span>{{ jsonData.denominazione }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Struttura</label>
                            <span>{{ jsonData.denominazioneStruttura }} ({{ jsonData.codiceStruttura }})</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Codice ASL</label>
                            <span>{{ jsonData.codiceAsl }} </span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Codice presidio</label>
                            <span>{{ jsonData.codicePresidio }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Codice distretto di appartenenza</label>
                            <span>{{ jsonData.codiceDistrettoAppartenenza }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Tipologia struttura</label>
                            <span>{{ jsonData.tipologiaStruttura }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Tipologia di assistenza riabilitativa</label>
                            <span>{{ jsonData.tipologiaAssistenzaRiabilitativa }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Indirizzo</label>
                            <span>{{ jsonData.indirizzo }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Provincia</label>
                            <span>{{ jsonData.provincia }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Località</label>
                            <span>{{ comune }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">CAP</label>
                            <span>{{ jsonData.cap }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">URL</label>
                            <span>{{ jsonData.url }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Telefono</label>
                            <span>{{ jsonData.telefono }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">FAX</label>
                            <span>{{ jsonData.fax }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Email</label>
                            <span>{{ jsonData.email }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Note</label>
                            <span>{{ jsonData.note }}</span>
                        </b-col>
                    </b-row>
                </b-card>
            </b-tab>
            <b-tab v-if="strutturePresidiAttivaSetting" title="Setting" lazy>
                <rsa-settings-component :idPresidio="id"></rsa-settings-component>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import axios from "axios";
import "vue2-datepicker/index.css";
import RsaSettingsComponent from "../../../rsa/rsasetting/components/RsaSettingsComponent.vue";
import UtilityMixin from "../../../utility/UtilityMixin";
export default {
    components: { RsaSettingsComponent },
    data() {
        return {
            pathResource: "/strutturepresidi",
            linkStrutture: "/strutture",
            pathResourceComune: "/comuni/istat",
            showModalLoading: false,
            id: null,
            strutturePresidiAttivaSetting: false,
            jsonData: {
                denominazione: null,
                idStruttura: null,
                telefono: null,
                url: null,
                email: null,
                indirizzo: null,
                note: null,
                provincia: null,
                cap: null,
                fax: null,
                codiceAsl: "",
                codiceDistrettoAppartenenza: "",
                codicePresidio: "",
                tipologiaStruttura: null,
                tipologiaAssistenzaRiabilitativa: null,
                localita: null,
            },
            comune: "",
            listaStrutture: [],

            codiceStruttura: null,
        };
    },
    mounted: function () {
        let me = this;
        me.id = this.$route.params.id;
        me.strutturePresidiAttivaSetting = this.appConfig.strutturePresidiAttivaSetting;
        me.loadData();
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            axios.get(link + me.id).then((response) => {
                me.jsonData = response.data.data;
                // me.loadComune();
                me.showModalLoading = false;
            });
            // me.loadCodStrutture();
        },
        loadCodStrutture() {
            let me = this;
            me.strutturaOptions = [];
            let link = process.env.VUE_APP_PATH_API + me.linkStrutture;
            axios.get(link).then((response) => {
                me.listaStrutture = response.data.data;
                me.listaStrutture.forEach((element) => {
                    if (element.id === me.jsonData.idStruttura) {
                        me.jsonData.denominazioneStruttura = element.denominazione;
                        me.jsonData.codiceStruttura = element.codiceStruttura;
                    }
                });
                me.showModalLoading = false;
            });
        },
        loadComune() {
            let me = this;
            let link = me.pathResourceComune + "/" + me.jsonData.localita;
            UtilityMixin.methods.loadDataResources(link, {}, me.resultResponseComune, me.resultErrorComune);
        },
        resultResponseComune(response) {
            let me = this;
            me.comune = response.data.data.comune;
        },
        resultErrorComune(error) {
            let me = this;
            UtilityMixin.methods.messaggeBoxError(me, error);
        },
    },
};
</script>
