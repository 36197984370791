<template>
    <div class="sa-tab-scroll">
        <b-card v-if="listaAlimentiIdratazione.length > 0" class="sa-card sa-card-border" header="ALIMENTAZIONE E IDRATAZIONE" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaAlimentiIdratazione" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header"> </b-table>
        </b-card>
        <b-card v-if="listaEliminazione.length > 0" class="sa-card sa-card-border" header="ELIMINAZIONE (ALVO E URINE)" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaEliminazione" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header"> </b-table>
        </b-card>
        <b-card v-if="listaIgieneCompfort.length > 0" class="sa-card sa-card-border" header="IGIETE E COMFORT" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaIgieneCompfort" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header"> </b-table>
        </b-card>
        <b-card v-if="listaMobilizzazione.length > 0" class="sa-card sa-card-border" header="MOBILIZZAZIONE" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaMobilizzazione" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header"> </b-table>
        </b-card>
        <b-card v-if="listaProcedureDiagnostiche.length > 0" class="sa-card sa-card-border" header="PROCEDURE DIAGNOSTICHE" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaProcedureDiagnostiche" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header"> </b-table>
        </b-card>
        <b-card v-if="listaProcedureTerapeutiche.length > 0" class="sa-card sa-card-border" header="PROCEDURE TERAPEUTICHE" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaProcedureTerapeutiche" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header"> </b-table>
        </b-card>
        <b-card v-if="listaProcedureSensoriali.length > 0" class="sa-card sa-card-border" header="PERCEZIONE SENSORIALE" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaProcedureSensoriali" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header"> </b-table>
        </b-card>
        <b-card class="sa-card sa-card-border" header="Totale" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <span class="sa-label-data">{{ totale }}</span>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import axios from "axios";
export default {
    components: {},
    // props: {
    //   utente: {
    //     type: Object,
    //     default: function () {
    //       return null;
    //     },
    //   },
    // },
    data() {
        return {
            idAccettazione: null,
            keyPropostaAccesso: 0,
            linkback: "",
            linkPrintData: "",
            childKey: 0,
            viewPai: true,
            pathResource: "/rsascalaidarichieste",
            totale: 0,
            jsonData: [],
            listaAlimentiIdratazione: [],
            listaEliminazione: [],
            listaIgieneCompfort: [],
            listaMobilizzazione: [],
            listaProcedureDiagnostiche: [],
            listaProcedureTerapeutiche: [],
            listaProcedureSensoriali: [],
            fieldsListe: [
                {
                    label: "Descrizione",
                    key: "fattore",
                    sortable: true,
                },
            ],
        };
    },
    mounted() {
        let me = this;
        if (this.$route.params.id) {
            me.idAccettazione = this.$route.params.id;
        } else {
            me.idAccettazione = this.$route.query.idPaziente;
        }
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/richiesta/" + me.idAccettazione;
            axios
                .get(link)
                .then((response) => {
                    me.jsonData = response.data.data.list;
                    me.calcolaPunteggio();
                    me.jsonData.forEach((element) => {
                        if (element.check) {
                            me.jsonData.push({ idRichiesta: me.idAccettazione, idScalaIda: element.id });
                            switch (element.gruppo) {
                                case "ALIMENTAZIONE E IDRATAZIONE":
                                    me.listaAlimentiIdratazione.push(element);
                                    break;
                                case "ELIMINAZIONE (ALVO E URINE)":
                                    me.listaEliminazione.push(element);
                                    break;
                                case "IGIENE E COMFORT":
                                    me.listaIgieneCompfort.push(element);
                                    break;
                                case "MOBILIZZAZIONE":
                                    me.listaMobilizzazione.push(element);
                                    break;
                                case "PROCEDURE DIAGNOSTICHE":
                                    me.listaProcedureDiagnostiche.push(element);
                                    break;
                                case "PROCEDURE TERAPEUTICHE":
                                    me.listaProcedureTerapeutiche.push(element);
                                    break;
                                case "PERCEZIONE SENSORIALE":
                                    me.listaProcedureSensoriali.push(element);
                                    break;
                            }
                        }
                    });
                })
                .catch(() => {});
        },
        calcolaPunteggio() {
            let me = this;
            me.totale = 0;
            me.jsonData.forEach((element) => {
                if (element.check) {
                    me.totale = me.totale + element.punteggio;
                }
            });
        },
    },
};
</script>

<style></style>
