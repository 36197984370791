<template>
  <sa-page-layout :showModalLoading="showModalLoading" :pathResource="pathResource" :linkedit="linkedit" :btnBackVisible="true" :btnEditVisible="true" :linkback="linkback" :data="jsonData">
    <template slot="toolbar-title">Dettaglio Contatto</template>
    <template slot="table-body">
      <anagrafica-view-component :jsonData="jsonData"></anagrafica-view-component>
    </template>
  </sa-page-layout>
</template>
<script>
import SaPageLayout from "../../../template/layout/components/SaPageLayout.vue";
import UtilityMixins from "../../utility/UtilityMixin";
import AnagraficaViewComponent from "../../utility/components/AnagraficaViewComponent.vue";
import axios from "axios";
export default {
  props: {
    utente: {
      type: Object,
      default: function () {
        return { idUtente: null };
      },
    },
  },
  mixins: [UtilityMixins],
  components: { SaPageLayout, AnagraficaViewComponent },
  data() {
    return {
      linkback: "/contatti",
      pathResource: "/anagraficacontatti",
      showModalLoading: false,
      regioniOptions: [],
      jsonData: {},
      linkedit: null,
    };
  },
  mounted() {
    let me = this;
    me.idContatto = this.$route.params.id;
    me.loadData();
    me.linkedit = me.linkback + "/edit/" + me.idContatto;
  },
  methods: {
    loadData() {
      let me = this;
      if (me.utente.id) {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
        axios.get(link + me.idContatto).then((response) => {
          me.jsonData = response.data.data;
        });
      }
    },
  },
};
</script>

<style></style>
