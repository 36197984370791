<template>
    <b-modal ref="mdlEditDataGruppo" :title="title" @hidden="onCloseModal" centered no-close-on-backdrop no-close-on-esc hide-header-close>
        <b-row>
            <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <span class="sa-form-label">Impegnativa</span>
                <b-form-select :options="impegnativaOptions" value-field="value" text-field="text" v-model="jsonData.codiceFlussoImpegnativa">
                    <template #first>
                        <b-form-select-option :value="null" disabled> -Seleziona un'impegnativa- </b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>

            <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <span class="sa-form-label">Prestazioni</span>
                <b-form-select :options="prestazioneOptions" value-field="value" text-field="text" v-model="jsonData.idPrestazione">
                    <template #first>
                        <b-form-select-option :value="null" disabled> -Seleziona una prestazione- </b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>
        </b-row>

        <template #modal-footer>
            <b-button variant="outline-danger" @click="onCloseModal">Annulla</b-button>
            <b-button variant="outline-success" @click="onClickOk()">Salva</b-button>
        </template>
    </b-modal>
</template>

<script>
import UtilityMixin from "../../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixin],
    data() {
        return {
            title: null,
            isRequired: false,
            pathResource: "/rsaimpegnativagruppoprestazione",
            pathResourceTipoImpegnativa: "/rsaimpegnativatipo",
            pathResourceTipoPrestazione: "/rsatipoprestazioni",
            impegnativaOptions: [],
            prestazioneOptions: [],

            jsonData: {
                id: null,
                codiceFlussoImpegnativa: null,
                idPrestazione: null,
            },
        };
    },
    methods: {
        show(jsonData) {
            let me = this;
            me.jsonData = jsonData;
            me.title = !jsonData.id ? "Nuovo gruppo" : "Aggiorna gruppo";
            me.loadTipoImpegnativaData();
            me.loadTipoPrestazioneData();
            me.$refs.mdlEditDataGruppo.show();
        },
        loadTipoImpegnativaData() {
            let me = this;
            UtilityMixin.methods.loadDataResources(me.pathResourceTipoImpegnativa, null, me.loadTipoImpegnativaDataSuccess, me.loadTipoImpegnativaDataError);
        },

        loadTipoImpegnativaDataSuccess(response) {
            let me = this;
            if (response.data && response.data.data && response.data.data.list) {
                me.impegnativaOptions = response.data.data.list.map((item) => ({
                    value: item.id,
                    text: item.tipo || item.id,
                }));
            } else {
                me.prestazioneOptions = [];
                console.warn("lista prestazione vuota");
            }
            // console.log("impegnativaOptions nel modalEdit: ", this.impegnativaOptions);
            me.$emit("loadTipoImpegnativa");
            //lo devo inserire nella list per poi fare un loadData dopo il salvataggio.
        },
        loadTipoImpegnativaDataError(error) {
            error;
            this.$bvToast.toast("Errore nel caricamento dei profili", {
                title: "Errore",
                variant: "danger",
                solid: true,
            });
        },

        loadTipoPrestazioneData() {
            let me = this;
            //let dashboardSearchBean = { limit: 0, page: 0 };
            UtilityMixin.methods.loadDataResources(me.pathResourceTipoPrestazione, null, me.loadTipoPrestazioneDataSuccess, me.loadTipoPrestazioneDataError);
        },
        loadTipoPrestazioneDataSuccess(response) {
            let me = this;
            /* me.prestazioneOptions = response.data.data.list; */
            if (response.data && response.data.data && response.data.data.list) {
                me.prestazioneOptions = response.data.data.list.map((item) => ({
                    value: item.id,
                    text: item.nome || item.id,
                }));
            } else {
                me.prestazioneOptions = [];
                console.warn("lista prestazione vuota");
            }
            // console.log("prestazioniOptions nel modalEdit: ", this.prestazioneOptions);
        },

        loadTipoPrestazioneDataError(error) {
            console.error("Errore durante il caricamento delle prestazione:", error);
            this.$bvToast.toast("Errore nel caricamento delle prestazione", {
                title: "Errore",
                variant: "danger",
                solid: true,
            });
        },

        onCloseModal() {
            let me = this;
            me.jsonData = {
                id: null,
                codiceFlussoImpegnativa: null,
                idPrestazione: null,
            };
            me.$refs.mdlEditDataGruppo.hide();
            //this.$emit("onClose");
        },

        onClickOk() {
            let me = this;
            /*  if (!me.jsonData.impegnativa || !me.jsonData.prestazione) {
                me.$bvToast.toast("Tutti i campi sono obbligatori", {
                    title: "Errore",
                    variant: "danger",
                    solid: true,
                });
                return;
            } */
            UtilityMixin.methods.saveResource(me.pathResource, me.jsonData, me.saveSuccessResponse, me.saveErrorResponse);
        },
        saveSuccessResponse(response) {
            let me = this;
            me.$emit("onSave", response);
            me.$refs.mdlEditDataGruppo.hide();
            me.$bvToast.toast("Salvataggio avvenuto con successo", {
                title: "Successo",
                variant: "success",
                solid: true,
            });
        },
        saveErrorResponse(response) {
            if (response.response.status === 400) {
                this.$bvModal.msgBoxOk(response.response.data.messaggio, {
                    title: "ATTENZIONE",
                    size: "sm",
                    okVariant: "outline-secondary",
                    headerClass: "sa-msg-header-danger",
                    footerClass: "p-2 border-top-0",
                    centered: true,
                });
            } else {
                this.$bvToast.toast("Errore nel salvataggio" + response.message, {
                    title: "Errore",
                    variant: "danger",
                    solid: true,
                });
            }
        },

        onDeleteGruppoAssocizione(item) {
            let me = this;
            me.$bvModal
                .msgBoxConfirm("Sei sicuro di eliminare questo gruppo?", {
                    title: "Eliminazione Gruppo Associzione",
                    buttonSize: "sm",
                    okTitle: "SI",
                    cancelTitle: "NO",
                    okVariant: "danger",
                    cancelVariant: "secondary",
                    hideHeaderClose: true,
                    footerClass: "p-2",
                    centered: true,
                })
                .then((value) => {
                    if (value) {
                        me.deleteGruppoAssociazione(item.id);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        deleteGruppoAssociazione(id) {
            let me = this;
            UtilityMixin.methods.deleteResource(me.pathResource + "/" + id, null, me.deleteGruppoAssociazioneSuccess, me.deleteGruppoAssociazioneError);
        },
        deleteGruppoAssociazioneSuccess(response) {
            let me = this;
            me.$emit("onDeleteGruppoAssociazione", response);
            me.$bvToast.toast("Eliminazione avvenuta con successo", {
                title: "Success",
                variant: "success",
                solid: true,
            });
        },
        deleteGruppoAssociazioneError(error) {
            let me = this;
            me.showModalLoading = false;
            console.log(error);
            me.$bvToast.toast("Errore durante l'eliminazione", {
                title: "Errore",
                variant: "danger",
                solid: true,
            });
        },
    },
};
</script>
