<template>
    <div class="sa-tab-scroll">
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">N. Protocollo</label>
                    <b-form-input v-model="jsonData.numeroProtocollo" disabled></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Data Protocollo</label>
                    <!-- <b-form-input v-model="jsonData.dataProtocollo" readonly></b-form-input> -->
                    <date-picker format="DD-MM-YYYY" value-type="timestamp" type="date" v-model="jsonData.dataProtocollo" disabled :editable="false"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">C.O.T. Destinazione</label>
                    <b-form-select v-model="jsonData.cotDestinazione" :options="cotDestinazioneOptions" :value="''" value-field="value" text-field="text" @change="onChangeCotDestinazioni"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Sede Pua</label>
                    <b-form-select v-model="jsonData.idSedePuaDestinazione" :options="cotPuaDestinazioniOptions" :value="''" value-field="id" text-field="denominazione" @change="onChangePuaDestinazione"></b-form-select>
                    <!-- <b-form-select v-model="jsonData.rsaDestinazione" :options="rsaDestinazioneOptions" :value="''" value-field="value" text-field="text" :disabled="selectStruttura" @change="onChangeRsaDestinazione"></b-form-select> -->
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Dati Anagrafici" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Codice Fiscale *</label>
                    <b-input-group>
                        <b-form-input maxlength="16" v-model="jsonData.identificativo" @keyup.enter="onSearchAnagraficaClick()" style="text-transform: uppercase"></b-form-input>
                        <b-input-group-append>
                            <b-button @click="onSearchAnagraficaClick()"><b-icon icon="search"></b-icon></b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Nome</label>
                    <span class="sa-data">{{ jsonData.nome }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">Cognome</label>
                    <span class="sa-data">{{ jsonData.cognome }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">Data di Nascita</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataNascita) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">Età</label>
                    <span class="sa-data">{{ jsonData.eta }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("adi.lblBirthPlace") }}</label>
                    <span class="sa-data">{{ jsonData.comuneNascita }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("adi.lblGender") }}</label>
                    <!-- <b-form-select v-model="jsonData.generePaziente" :options="generePazienteOptions" :value="null"></b-form-select> -->
                    <span class="sa-data">{{ jsonData.sesso }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("adi.lblHealthInsuranceCard") }}</label>
                    <span class="sa-data">{{ jsonData.tesseraSanitaria }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Referente/Caregiven</label>
                    <b-form-input v-model="jsonData.referenteCaregiver" class="text-upper" :disabled="!editPropostaAccesso"></b-form-input>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Dati Residenza" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("adi.lblCitizenship") }} *</label>
                    <!-- <b-form-input v-model="jsonData.cittadinanza" class="text-upper" :disabled="disableField"></b-form-input> -->
                    <b-form-select v-model="jsonData.cittadinanza" :options="cittadinanzaOptions" :value="''" value-field="value" text-field="text" :disabled="disableField"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("adi.lblNation") }} *</label>
                    <b-form-select v-model="jsonData.nazioneResidenzaCodiceIstat" :options="nazioniOptions" :value="null" value-field="codiceIstat" text-field="comune" :disabled="disableField"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("adi.lblRegion") }} *</label>
                    <b-form-select v-model="jsonData.regioneResidenzaCodiceIstat" :options="regioniOptions" :value="null" value-field="codiceIstat" text-field="regione" @change="onSelectRegione" :disabled="disableField"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Provincia</label>
                    <!-- <span class="sa-data">{{ jsonData.comuneResidenza }}</span> -->
                    <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.provinciaResidenza" class="mb-2 mr-sm-2 mb-sm-0" :options="provinceOptions" :value="null" @change="loadComuni(jsonData.provinciaResidenza)" :disabled="disableField"> </b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("adi.lblCity") }}</label>
                    <!-- <span class="sa-data">{{ jsonData.comuneResidenza }}</span> -->
                    <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.comuneResidenzaCodiceIstat" class="mb-2 mr-sm-2 mb-sm-0" :options="comuneOption" :value="null" value-field="codiceIstat" text-field="comune" @change="setCapResidenza" :disabled="disableField"> </b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Cap Residenza</label>
                    <b-form-input v-model="jsonData.capResidenza" class="text-upper" :disabled="disableField"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("adi.lblAddress") }}</label>
                    <!-- <span class="sa-data">{{ jsonData.indirizzoResidenza }}</span> -->
                    <b-form-input v-model="jsonData.indirizzoResidenza" class="text-upper" :disabled="disableField"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("adi.lblPhone") }} *</label>
                    <b-form-input v-model="jsonData.telefono" class="text-upper" :disabled="!editPropostaAccesso"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Email</label>
                    <b-form-input v-model="jsonData.email" class="text-upper"></b-form-input>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Riferimenti M.M.G./P.L.S." header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("adi.lblDoctor") }}</label>
                    <span class="sa-data">{{ jsonData.medicoCurante }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Email</label>
                    <b-form-input v-model="jsonData.email" class="text-upper"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("adi.lblMmgContacts") }}</label>
                    <b-form-input v-model="jsonData.contattiMmg" class="text-upper"></b-form-input>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Richiesta di (Selezionare quelle interessate):" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-form-checkbox class="sa-custom-switch" v-model="jsonData.assistenzaDomiciliareEstemporanea" switch>
                        <label class="sa-label-data-check">Assistenza Domiciliare Estemporanea</label>
                    </b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-form-checkbox class="sa-custom-switch" v-model="jsonData.ricoveroStrutturaResidenzialePersoneDisabilita" switch>
                        <label class="sa-label-data-check">Ricovero in Struttura Residenziale per persone con disabilità (Rd1-Rd2-Rd3)</label>
                    </b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-form-checkbox class="sa-custom-switch" v-model="jsonData.assistenzaDomiciliareIntegrata" switch>
                        <label class="sa-label-data-check">Assistenza Domiciliare integrata</label>
                    </b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-form-checkbox class="sa-custom-switch" v-model="jsonData.ricoveroStrutturaResidenzialeSocioSanitariaPazientiNonAu" switch>
                        <label class="sa-label-data-check">Ricovero in struttura residenziale socio sanitaria per pazienti non autosufficienti (R2-R3)</label>
                    </b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-form-checkbox class="sa-custom-switch" v-model="jsonData.assistenzaDomiciliareProgrammataMmg" switch>
                        <label class="sa-label-data-check">Assistenza Domiciliare programmata del MMG</label>
                    </b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-form-checkbox class="sa-custom-switch" v-model="jsonData.assistenzaStruttureSemiresidenzialiPersoneNonAutosufficien" switch>
                        <label class="sa-label-data-check">Assistenza presso Strutture Semiresidenziali per persone non autosufficienti (SR)</label>
                    </b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-form-checkbox class="sa-custom-switch" v-model="jsonData.assistenzaDomiciliareSociale" switch>
                        <label class="sa-label-data-check">Assistenza Domiciliare sociale</label>
                    </b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-form-checkbox class="sa-custom-switch" v-model="jsonData.altriInterventiNaturaSociosanitaria" switch>
                        <label class="sa-label-data-check">Altri interventi di natura socio-assistenziale</label>
                    </b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-form-checkbox class="sa-custom-switch" v-model="jsonData.assistenzaDomiciliareDisabili" switch>
                        <label class="sa-label-data-check">Assistenza Domiciliare disabili</label>
                    </b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-form-checkbox class="sa-custom-switch" v-model="jsonData.altro" switch>
                        <label class="sa-label-data-check">Altro (specificare)</label>
                    </b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-form-checkbox class="sa-custom-switch" v-model="jsonData.ricoveroHospice" switch>
                        <label class="sa-label-data-check">Ricovero in Hospice (R1)</label>
                    </b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6"></b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-form-checkbox class="sa-custom-switch" v-model="jsonData.accoglienzaCentroDiurnoSocioEducativoDisabili" switch>
                        <label class="sa-label-data-check">Accoglienza in Centro diurno socio-educativo per disabile</label>
                    </b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6"></b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-form-checkbox class="sa-custom-switch" v-model="jsonData.ricoveroStrutturaResidenzialeExtraospedalieraElevatoImpegn" switch>
                        <label class="sa-label-data-check">Ricovero in Struttura Residenziale Extraospedaliera ad elevato impegno sanitario – R1</label>
                    </b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6"></b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="PATOLOGIE PRINCIPALI CHE CONCORRONO A DETERMINARE LA SITUAZIONE DI NON AUTOSUFFICIENZA" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Prima patologia</label>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Descrizione patologia</label>
                    <b-form-input v-model="jsonData.primaPatologiaDescrizione"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Codice ICD-9</label>
                    <b-form-input v-model="jsonData.primaPatologiaCodiceIcdix"></b-form-input>
                    <!-- <b-form-select v-model="jsonData.primaPatologiaCodiceIcdix" :options="icdixOptions" :value="''" value-field="codiceIcdix" text-field="descrizioneIcdix" :disabled="!editPropostaAccesso"></b-form-select> -->
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Prima patologia concomitante</label>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Descrizione patologia</label>
                    <b-form-input v-model="jsonData.primaPatologiaConxomitanteDescrizione"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Codice ICD-9</label>
                    <b-form-input v-model="jsonData.primaPatologiaConxomitanteCodiceIcdix"></b-form-input>
                    <!-- <b-form-select v-model="jsonData.primaPatologiaConxomitanteCodiceIcdix" :options="icdixOptions" :value="''" value-field="codiceIcdix" text-field="descrizioneIcdix" :disabled="!editPropostaAccesso"></b-form-select> -->
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Seconda patologia concomitante</label>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Descrizione patologia</label>
                    <b-form-input v-model="jsonData.secondaPatologiaConxomitanteDescrizione"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Codice ICD-9</label>
                    <b-form-input v-model="jsonData.secondaPatologiaConxomitanteCodiceIcdix"></b-form-input>
                    <!-- <b-form-select v-model="jsonData.secondaPatologiaConxomitanteCodiceIcdix" :options="icdixOptions" :value="''" value-field="codiceIcdix" text-field="descrizioneIcdix" :disabled="!editPropostaAccesso"></b-form-select> -->
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="VALUTAZIONE SINTETICA DELLA SITUAZIONE DI NON AUTOSUFFICIENZA" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Situazione Cognitiva</label>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox class="sa-custom-switch" v-model="jsonData.situazioneCognitivaLucido" @change="onChangeValutazioneCognitiva" switch>
                        <label class="sa-label-data-check">Lucido (1)</label>
                    </b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox class="sa-custom-switch" v-model="jsonData.situazioneCognitivaConfuso" @change="onChangeValutazioneCognitiva" switch>
                        <label class="sa-label-data-check">Confuso (2)</label>
                    </b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox class="sa-custom-switch" v-model="jsonData.situazioneCognitivaMoltoConfuso" @change="onChangeValutazioneCognitiva" switch>
                        <label class="sa-label-data-check">Molto confuso, stuporoso (3)</label>
                    </b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Problemi comportamentali</label>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox class="sa-custom-switch" v-model="jsonData.problemiComportamentaliAsentiLievi" @change="onChangeValutazioneCognitiva" switch>
                        <label class="sa-label-data-check">Assenti/Lievi (1)</label>
                    </b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox class="sa-custom-switch" v-model="jsonData.problemiComportamentaliModerati" @change="onChangeValutazioneCognitiva" switch>
                        <label class="sa-label-data-check">Moderati (2)</label>
                    </b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox class="sa-custom-switch" v-model="jsonData.problemiComportamentaliGravi" @change="onChangeValutazioneCognitiva" switch>
                        <label class="sa-label-data-check">Gravi (3)</label>
                    </b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Situazione funzionale</label>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox class="sa-custom-switch" v-model="jsonData.situazioneFunzionaleAutonomoQuasi" @change="onChangeValutazioneCognitiva" switch>
                        <label class="sa-label-data-check">Autonomo o quasi (1)</label>
                    </b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox class="sa-custom-switch" v-model="jsonData.situazioneFunzionaleDipendente" @change="onChangeValutazioneCognitiva" switch>
                        <label class="sa-label-data-check">Dipendente (2)</label>
                    </b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox class="sa-custom-switch" v-model="jsonData.situazioneFunzionaleTotalmenteDipendente" @change="onChangeValutazioneCognitiva" switch>
                        <label class="sa-label-data-check">Totalmente dipendente (3)</label>
                    </b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Grado di Autonomia</label>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox class="sa-custom-switch" v-model="jsonData.gradoAutonomiaSpostaSolo" @change="onChangeValutazioneCognitiva" switch>
                        <label class="sa-label-data-check">Si sposta da solo (1)</label>
                    </b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox class="sa-custom-switch" v-model="jsonData.gradoAutonomiaSpostaAssistito" @change="onChangeValutazioneCognitiva" switch>
                        <label class="sa-label-data-check">Si sposta assistito (2)</label>
                    </b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox class="sa-custom-switch" v-model="jsonData.gradoAutonomiaTotalmenteDipendente" @change="onChangeValutazioneCognitiva" switch>
                        <label class="sa-label-data-check">Non si sposta (3)</label>
                    </b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Supporto rete sociale</label>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox class="sa-custom-switch" v-model="jsonData.supportoReteSocialeBenAssistito" @change="onChangeValutazioneCognitiva" switch>
                        <label class="sa-label-data-check">Ben assistito (1)</label>
                    </b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox class="sa-custom-switch" v-model="jsonData.supportoReteSocialePazialmenteAssistito" @change="onChangeValutazioneCognitiva" switch>
                        <label class="sa-label-data-check">Parzialmente assistito (2)</label>
                    </b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox class="sa-custom-switch" v-model="jsonData.supportoSocialeNonAssistito" @change="onChangeValutazioneCognitiva" switch>
                        <label class="sa-label-data-check">Non assistito (3)</label>
                    </b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Necessità assistenza sanitaria</label>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox class="sa-custom-switch" v-model="jsonData.necessitaAssitenzaSanitariaBassa" @change="onChangeValutazioneCognitiva" switch>
                        <label class="sa-label-data-check">Bassa (1)</label>
                    </b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox class="sa-custom-switch" v-model="jsonData.necessitaAssistenzaSanitariaIntermedia" @change="onChangeValutazioneCognitiva" switch>
                        <label class="sa-label-data-check">Intermedia (2)</label>
                    </b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox class="sa-custom-switch" v-model="jsonData.necessitaAssistenzaSanitariaElevata" @change="onChangeValutazioneCognitiva" switch>
                        <label class="sa-label-data-check">Alta (3)</label>
                    </b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="10" lg="10" xl="10"></b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">Totale punteggio</label>
                    <b-form-input v-model="jsonData.totalePunteggio" disabled></b-form-input>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Impossibilità a raggiungere lo studio del Medico per" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-form-checkbox class="sa-custom-switch" v-model="jsonData.impossibilitaPermanenteDeambulare" switch>
                        <label class="sa-label-data-check">Impossibilità permanente a deambulare</label>
                    </b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-form-checkbox class="sa-custom-switch" v-model="jsonData.impossibilitaEssereTrasportatoAmbulatorioMezziComuniCausa" switch>
                        <label class="sa-label-data-check">Impossibilità ad essere trasportato in ambulatorio con i comuni mezzi a causa di:</label>
                    </b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox class="sa-custom-switch" v-model="jsonData.impossibilitaTrasportatoAmbulatorioCausaAutosufficiente" switch>
                        <label class="sa-label-data-check">Non autosufficienza (in possesso di certificazione L. 104/1992 e/o indennità di accompagnamento)</label>
                    </b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox class="sa-custom-switch" v-model="jsonData.impossibilitaTrasportatoAmbulatorioCausaAbitazionePianoAl" switch>
                        <label class="sa-label-data-check">Abitazione in piano alto e senza ascensore</label>
                    </b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-form-checkbox class="sa-custom-switch" v-model="jsonData.impossibilitaAltro" switch>
                        <label class="sa-label-data-check">Altro</label>
                    </b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Specificare Altro</label>
                    <b-form-input v-model="jsonData.altroSpecificare" :disabled="!jsonData.impossibilitaAltro"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-form-checkbox class="sa-custom-switch" v-model="jsonData.impossibileEssereTrasportatoGraviPatologie" switch>
                        <label class="sa-label-data-check">Impossibilità ad essere trasportato per gravi patologie che necessitano di controlli ravvicinati sia in relazione alla situazione socio-ambientale che al quadro clinico ( allegato G/H dell’ACN )</label>
                        <!-- <b-form-input v-model="jsonData.impossibileEssereTrasportatoGraviPatologie"></b-form-input> -->
                    </b-form-checkbox>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Bisogni assistenziali rilevati" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-card class="sa-card" header="Autonomia" header-tag="header" footer-tag="footer" title="">
                        <b-row>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiAutonomiaAutonomo" switch>
                                    <label class="sa-label-data-check">Autonomo</label>
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiAutonomiaParzialmenteAutonomo" switch>
                                    <label class="sa-label-data-check">Parzialmente autonomo</label>
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiAutonomiaTotalmenteDipendente" switch>
                                    <label class="sa-label-data-check">Totalmente dipendente</label>
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiAutonomiaStatoVegetatitivo" switch>
                                    <label class="sa-label-data-check">Stato vegetativo</label>
                                </b-form-checkbox>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-card class="sa-card" header="Riabilitazione" header-tag="header" footer-tag="footer" title="">
                        <b-row>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiRiabilitazioneNessunaRiabilita" switch>
                                    <label class="sa-label-data-check">Nessuna Riabilitazione</label>
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiRiabilitazioneRiabilitazioneNe" switch>
                                    <label class="sa-label-data-check">Riabilitazione Neurologica</label>
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiRiabilitazioneAfasia" switch>
                                    <label class="sa-label-data-check">Afasia</label>
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiRiabilitazioneRiabilitazioneOr" switch>
                                    <label class="sa-label-data-check">Riabilitazione Ortopedica</label>
                                </b-form-checkbox>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-card class="sa-card" header="Apparato respiratorio" header-tag="header" footer-tag="footer" title="">
                        <b-row>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiApparatoRespiratorioNormale" switch>
                                    <label class="sa-label-data-check">Normale</label>
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiApparatoRespiratorioTosseSecr" switch>
                                    <label class="sa-label-data-check">Tosse e secrezioni(aspirazione)</label>
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiApparatoRespiratorioOssigenote" switch>
                                    <label class="sa-label-data-check">Ossigenoterapia</label>
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiApparatoRespiratorioVentiloter" switch>
                                    <label class="sa-label-data-check">Ventiloterapia</label>
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiApparatoRespiratorioTracheotom" switch>
                                    <label class="sa-label-data-check">Tracheostomia</label>
                                </b-form-checkbox>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-card class="sa-card" header="Stato nutrizionale" header-tag="header" footer-tag="footer" title="">
                        <b-row>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiStatoMutrizionaleNormale" switch>
                                    <label class="sa-label-data-check">Normale</label>
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiStatoMutrizionaleDimagrito" switch>
                                    <label class="sa-label-data-check">Dimagrimento</label>
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.statoNutrizionaleDisidratazione" switch>
                                    <label class="sa-label-data-check">Disidratazione</label>
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiStatoMutrizionaleNutrizioneDi" switch>
                                    <label class="sa-label-data-check">Nutrizione/disfagia</label>
                                </b-form-checkbox>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-card class="sa-card" header="Comportamento" header-tag="header" footer-tag="footer" title="">
                        <b-row>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiComportamentoNormale" switch>
                                    <label class="sa-label-data-check">Normale</label>
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiComportamentoDisturboCognitivo" switch>
                                    <label class="sa-label-data-check">Disturbo cognitivo moderato</label>
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.disturboCognitivoGrave" switch>
                                    <label class="sa-label-data-check">Disturbo cognitivo grave</label>
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiComportamentoDisturboComportam" switch>
                                    <label class="sa-label-data-check">Disturbo comportamentale (BPSD)</label>
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiComportamentoCondizioniSalute" switch>
                                    <label class="sa-label-data-check">Condizioni di salute psichiatrica</label>
                                </b-form-checkbox>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-card class="sa-card" header="Ritmo sonno/veglia" header-tag="header" footer-tag="footer" title="">
                        <b-row>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiRitmoSonnoVegliaAlterato" switch>
                                    <label class="sa-label-data-check">Ritmo sonno/veglia alterato</label>
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiRitmoSonnoVegliaRischioInfet" switch>
                                    <label class="sa-label-data-check">Rishio infettivo personale</label>
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiRitmoSonnoVegliaFebbre" switch>
                                    <label class="sa-label-data-check">Febbre</label>
                                </b-form-checkbox>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-card class="sa-card" header="Apparato gastrointestinale" header-tag="header" footer-tag="footer" title="">
                        <b-row>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiApparatoGastrointestinaleNorm" switch>
                                    <label class="sa-label-data-check">Normale</label>
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiApparatoGastrointestinaleInco" switch>
                                    <label class="sa-label-data-check">Incontinenza fecale</label>
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiApparatoGastrointestinaleVomi" switch>
                                    <label class="sa-label-data-check">Vomito</label>
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiApparatoGastrointestinaleSang" switch>
                                    <label class="sa-label-data-check">Sanguinamento gastrointestinale</label>
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiApparatoGastrointestinaleStom" switch>
                                    <label class="sa-label-data-check">Stomia</label>
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiApparatoGastrointestinaleStip" switch>
                                    <label class="sa-label-data-check">Stipsi</label>
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiApparatoGastrointestinaleDiar" switch>
                                    <label class="sa-label-data-check">Diarrea</label>
                                </b-form-checkbox>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-card class="sa-card" header="Apparato genito urinario" header-tag="header" footer-tag="footer" title="">
                        <b-row>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiApparatoGenitoUrinarioNormale" switch>
                                    <label class="sa-label-data-check">Normale</label>
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiApparatoGenitoUrinarioInconti" switch>
                                    <label class="sa-label-data-check">Incontinenza urinaria</label>
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiApparatoGenitoUrinarioCateter" switch>
                                    <label class="sa-label-data-check">Cateterismo vescicale</label>
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiApparatoGenitoUrinarioUrostom" switch>
                                    <label class="sa-label-data-check">Urostomia</label>
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiApparatoGenitoUrinarioEmaturi" switch>
                                    <label class="sa-label-data-check">Ematuria</label>
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiApparatoGenitoUrinarioDialisi" switch>
                                    <label class="sa-label-data-check">Dialisi</label>
                                </b-form-checkbox>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-card class="sa-card" header="Apparato tegumentario" header-tag="header" footer-tag="footer" title="">
                        <b-row>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiApparatoTegumentarioNormale" switch>
                                    <label class="sa-label-data-check">Normale</label>
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiApparatoTegumentarioUlcereCP" switch>
                                    <label class="sa-label-data-check">Ulcere cutanee 1° e 2° Grado</label>
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiApparatoTegumentarioUlcereCT" switch>
                                    <label class="sa-label-data-check">Ulcere cutanee 3° e 4° Grado</label>
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiApparatoTegumentarioStatoGrav" switch>
                                    <label class="sa-label-data-check">Stato più grave di ulcera - non stadiabile</label>
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiApparatoTegumentarioCuraFerit" switch>
                                    <label class="sa-label-data-check">Cura della ferita</label>
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiApparatoTegumentarioAltriProb" switch>
                                    <label class="sa-label-data-check">Altri problemi cutanei</label>
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiApparatoTegumentarioLacerazion" switch>
                                    <label class="sa-label-data-check">Lacerazioni o tagli non chirugici</label>
                                </b-form-checkbox>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-card class="sa-card" header="Oncologico/terminale" header-tag="header" footer-tag="footer" title="">
                        <b-row>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiOncologicoNonOncologicoTermi" switch>
                                    <label class="sa-label-data-check">Non oncologico/non terminale</label>
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.supportoReteSocialeBenAssistito" switch>
                                    <label class="sa-label-data-check">Terminalità non oncologica</label>
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiOncologicoTerminaleNonOncolo" switch>
                                    <label class="sa-label-data-check">Terminalità oncologica</label>
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiOncologicoOncologico" switch>
                                    <label class="sa-label-data-check">Oncologico</label>
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiOncologicoChemioterapia" switch>
                                    <label class="sa-label-data-check">Chemioterapia</label>
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiOncologicoRadioterapia" switch>
                                    <label class="sa-label-data-check">Radioterapia</label>
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiOncologicoDolorePresente" switch>
                                    <label class="sa-label-data-check">Dolore presente</label>
                                </b-form-checkbox>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-card class="sa-card" header="Prestazioni" header-tag="header" footer-tag="footer" title="">
                        <b-row>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiPrestazioniPrelievoVenosoNon" switch>
                                    <label class="sa-label-data-check">Prelievo venoso non occasionale</label>
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiPrestazioniEcg" switch>
                                    <label class="sa-label-data-check">ECG</label>
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiPrestazioniTelemetria" switch>
                                    <label class="sa-label-data-check">Telemetria</label>
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiPrestazioniTrasfusioni" switch>
                                    <label class="sa-label-data-check">Trasfusioni</label>
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiPrestazioniTerapiaEndovena" switch>
                                    <label class="sa-label-data-check">Terapia Endovenosa</label>
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiPrestazioniTerapiaIntramuscol" switch>
                                    <label class="sa-label-data-check">Terapia intramuscolo/sottocutanea</label>
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-checkbox class="sa-custom-switch" v-model="jsonData.bisogniAssistenzialiRilevatiPrestazioniGestioneCvc" switch>
                                    <label class="sa-label-data-check">Gestione CVC</label>
                                </b-form-checkbox>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Piano Assistenziale proposto" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data-check">Figura professionale</label>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data-check">n. accessi settimanali</label>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data-check">Periodo dal</label>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data-check">al</label>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data-check">Medico di Medicina Generale</label>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-input v-model="jsonData.medicoMedicinaGeneraleNumeroSettimane"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <!-- <div style="display: flex; flex-direction: row"> -->
                    <date-picker v-model="jsonData.medicoMedicinaGeneraleDal" format="DD-MM-YYYY" value-type="timestamp" type="date"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <date-picker v-model="jsonData.medicoMedicinaGeneraleAl" format="DD-MM-YYYY" value-type="timestamp" type="date"></date-picker>
                    <!-- </div> -->
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data-check">Medico specialista (indicare specialità medica)</label>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-input v-model="jsonData.medicoSpecialistaNumeroSettimane"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <!-- <div style="display: flex; flex-direction: row"> -->
                    <date-picker v-model="jsonData.medicoSpecialistaDal" format="DD-MM-YYYY" value-type="timestamp" type="date"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <date-picker v-model="jsonData.medicoSpecialistaAl" format="DD-MM-YYYY" value-type="timestamp" type="date"></date-picker>
                    <!-- </div> -->
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data-check">Infermiere professionale</label>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-input v-model="jsonData.infermiereProfessionaleNumeroSettimane"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <!-- <div style="display: flex; flex-direction: row"> -->
                    <date-picker v-model="jsonData.infermiereProfessionaleDal" format="DD-MM-YYYY" value-type="timestamp" type="date"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <date-picker v-model="jsonData.infermiereProfessionaleAl" format="DD-MM-YYYY" value-type="timestamp" type="date"></date-picker>
                    <!-- </div> -->
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data-check">Fisioterapista/Logopedista/Terapista occupazionale</label>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-input v-model="jsonData.fisioterapistaLogopedistaTerapistaOccupazionaleNumeroSetti"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <!-- <div style="display: flex; flex-direction: row"> -->
                    <date-picker v-model="jsonData.fisioterapistaLogopedistaTerapistaOccupazionaleDal" format="DD-MM-YYYY" value-type="timestamp" type="date"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <date-picker v-model="jsonData.fisioterapistaLogopedistaTerapistaOccupazionaleAl" format="DD-MM-YYYY" value-type="timestamp" type="date"></date-picker>
                    <!-- </div> -->
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data-check">Psicologo</label>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-input v-model="jsonData.psicologoNumeroSettimane"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <!-- <div style="display: flex; flex-direction: row"> -->
                    <date-picker v-model="jsonData.psicologoDal" format="DD-MM-YYYY" value-type="timestamp" type="date"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <date-picker v-model="jsonData.psicologoAl" format="DD-MM-YYYY" value-type="timestamp" type="date"></date-picker>
                    <!-- </div> -->
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data-check">Operatore sociosanitario (OSS)</label>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-input v-model="jsonData.operatoreSociosanitarioNumeroSettimane"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <!-- <div style="display: flex; flex-direction: row"> -->
                    <date-picker v-model="jsonData.operatoreSociosanitarioDal" format="DD-MM-YYYY" value-type="timestamp" type="date"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <date-picker v-model="jsonData.operatoreSociosanitarioAl" format="DD-MM-YYYY" value-type="timestamp" type="date"></date-picker>
                    <!-- </div> -->
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Bisogni socio-assistenziali rilevati" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data-check">Indicare le prestazioni e/o gli interventi di natura socio-assistenziale necessari per il paziente/utente e l’eventuale nucleo familiare interessato (es. assistenza domiciliare sociale, assistenza tutelare, supporto economico, ricovero in casa di riposo, Telesoccorso/Teleassistenza, …)</label>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-form-textarea rows="8" v-model="jsonData.prestazioneInterventiNaturaSociosanitaria"></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Provenienza " header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="2" xl="1">
                    <label class="sa-label-data-check">Cod. Strut.</label>
                    <b-form-input v-model="jsonData.provenienzaCodiceStruttura" :disabled="!btnDisableInserimentoProvenienza"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="5">
                    <label class="sa-label-data-check">Desc. Strut.</label>
                    <b-form-input v-model="jsonData.provenienzaDescrizioneStruttura" :disabled="!btnDisableInserimentoProvenienza"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="2" xl="1">
                    <label class="sa-label-data-check">Cod. Rep.</label>
                    <b-form-input v-model="jsonData.provenienzaCodiceReparto" :disabled="!btnDisableInserimentoProvenienza"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="5">
                    <label class="sa-label-data-check">Desc. Reparto</label>
                    <b-form-input v-model="jsonData.provenienzaDescrizioneReparto" :disabled="!btnDisableInserimentoProvenienza"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data-check">Ev. Clinico</label>
                    <b-form-input v-model="jsonData.provenienzaEventoClinico" :disabled="!btnDisableInserimentoProvenienza"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data-check">Nome Medico Segnalatore</label>
                    <b-form-input v-model="jsonData.provenienzaNominativoMedico" :disabled="!btnDisableInserimentoProvenienza"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data-check">Cogome Medico Segnalatore</label>
                    <b-form-input v-model="jsonData.provenienzaCognomeMedico" :disabled="!btnDisableInserimentoProvenienza"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data-check">Codice Fiscale Medico Segnalatore</label>
                    <b-form-input v-model="jsonData.provenienzaCodiceFiscaleMedico" :disabled="!btnDisableInserimentoProvenienza"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data-check">Ruolo Medico Segnalatore</label>
                    <b-form-input v-model="jsonData.provenienzaRuoloMedico" :disabled="!btnDisableInserimentoProvenienza"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data-check">Id Medico Segnalatore</label>
                    <b-form-input v-model="jsonData.provenienzaIdMedico" :disabled="!btnDisableInserimentoProvenienza"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data-check">Email Medico Segnalatore</label>
                    <b-form-input v-model="jsonData.provenienzaEmail" :disabled="!btnDisableInserimentoProvenienza"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data-check">Username Medico Segnalatore</label>
                    <b-form-input v-model="jsonData.provenienzaUsername" :disabled="!btnDisableInserimentoProvenienza"></b-form-input>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import moment from "moment";
import UtilityMixin from "../../utility/UtilityMixin";
import DatePicker from "vue2-datepicker";
export default {
    components: { DatePicker },
    mixins: [UtilityMixin],
    data() {
        return {
            selectStruttura: false,
            id: null,
            linkNazioni: "/nazioni",
            linkRegioni: "/regioni",
            linkDistretti: "/distretti",
            linkComune: "/comuni",
            linkProvince: "/province",
            linkNazionalita: "/nazionalita",
            linkCittadinanza: "/nazionalita",
            linkIcdix: "/serviziicdix",
            linkStrutture: "/cotstrutture",
            linkDestinazioni: "/cotdestinazioni",
            codiceFiscaleValido: false,
            pathResource: "/cot",
            pathResourceCotPuaSedi: "/cotpuasedi/sedi",
            pathResourceMedico: "/medici/username",
            codiceStrutturaAnagraficaRegionale: "",
            btnDisableInserimentoProvenienza: true,
            editPropostaAccesso: true,
            disableField: true,
            regioniOptions: [],
            nazioniOptions: [],
            provinceOptions: [],
            cittadinanzaOptions: [],
            icdixOptions: [],
            medico: {},
            // cotDestinazioneOptions: [
            //   { value: "", text: "-Seleziona Valore-" },
            //   { value: "Sorrento", text: "Sorrento" },
            //   { value: "Vico Equense", text: "Vico Equense" },
            //   { value: "Gragnano", text: "Gragnano" },
            //   { value: "Torre del Greco", text: "Torre del Greco" },
            //   { value: "Sant’Anastasia", text: "Sant’Anastasia" },
            //   { value: "Pollena Trocchia", text: "Pollena Trocchia" },
            //   { value: "Ottaviano", text: "Ottaviano" },
            //   { value: "Roccarainola", text: "Roccarainola" },
            //   { value: "Somma Vesuviana", text: "Somma Vesuviana" },
            // ],
            cotDestinazioneOptions: [{ value: "", text: "-Seleziona Valore-" }],
            rsaDestinazioneOptions: [{ value: "", text: "-Seleziona Valore-" }],
            // rsaDestinazioneList: [],
            cotPuaDestinazioniOptions: [{ id: "", denominazione: "-Seleziona Valore-" }],
            cotPuaDestinazioniList: [],
            comuneOption: [],
        };
    },
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
        jsonData: {
            type: Object,
            // default: function () {
            //     return {
            //         identificativo: "",
            //         cognome: "",
            //         nome: "",
            //         referenteCargiver: "",
            //         altriInterventiNaturaSociosanitaria: false,
            //         assistenzaStruttureSemiresidenzialiPersoneNonAutosufficien: false,
            //         ricoveroStrutturaResidenzialeSocioSanitariaPazientiNonAu: false,
            //         puaDistrettoSanitario: "",
            //         ambitoSociale: "",
            //         assistenzaDomiciliareEstemporanea: false,
            //         assistenzaDomiciliareIntegrata: false,
            //         assistenzaDomiciliareProgrammataMmg: false,
            //         assistenzaDomiciliareSociale: false,
            //         assistenzaDomiciliareDisabili: false,
            //         ricoveroHospice: false,
            //         accoglienzaCentroDiurnoSocioEducativoDisabili: false,
            //         ricoveroStrutturaResidenzialeExtraospedalieraElevatoImpegn: false,
            //         ricoveroStrutturaResidenzialePersoneDisabilita: false,
            //         altro: false,
            //         altroSpecificare: "",
            //         comuneNascitaCodiceIstat: "",
            //         dataNascita: null,
            //         comuneResidenzaCodiceIstat: "",
            //         indirizzoResidenza: "",
            //         primaPatologiaDescrizione: "",
            //         primaPatologiaCodiceIcdix: "",
            //         primaPatologiaConxomitanteDescrizione: "",
            //         primaPatologiaConxomitanteCodiceIcdix: "",
            //         secondaPatologiaConxomitanteDescrizione: "",
            //         secondaPatologiaConxomitanteCodiceIcdix: "",
            //         situazioneCognitivaLucido: false,
            //         situazioneCognitivaConfuso: false,
            //         situazioneCognitivaMoltoConfuso: false,
            //         problemiComportamentaliAsentiLievi: false,
            //         problemiComportamentaliModerati: false,
            //         problemiComportamentaliGravi: false,
            //         situazioneFunzionaleAutonomoQuasi: false,
            //         situazioneFunzionaleDipendente: false,
            //         situazioneFunzionaleTotalmenteDipendente: false,
            //         gradoAutonomiaSpostaSolo: false,
            //         gradoAutonomiaSpostaAssistito: false,
            //         gradoAutonomiaTotalmenteDipendente: false,
            //         supportoReteSocialeBenAssistito: false,
            //         supportoReteSocialePazialmenteAssistito: false,
            //         supportoSocialeNonAssistito: false,
            //         necessitaAssitenzaSanitariaBassa: false,
            //         necessitaAssistenzaSanitariaIntermedia: false,
            //         necessitaAssistenzaSanitariaElevata: false,
            //         totalePunteggio: 0,
            //         impossibilitaPermanenteDeambulare: false,
            //         impossibilitaTrasportatoAmbulatorioCausa: false,
            //         impossibilitaTrasportatoAmbulatorioCausaAutosufficiente: false,
            //         impossibilitaTrasportatoAmbulatorioCausaAbitazionePianoAl: false,
            //         impossibilitaEssereTrasportatoAmbulatorioMezziComuniCausa: false,
            //         impossibilitaEssereTrasportatoAmbulatorioMezziComuniSpeci: "",
            //         impossibileEssereTrasportatoGraviPatologie: false,
            //         bisogniAssistenzialiRilevatiAutonomiaAutonomo: false,
            //         bisogniAssistenzialiRilevatiAutonomiaParzialmenteAutonomo: false,
            //         bisogniAssistenzialiRilevatiAutonomiaTotalmenteDipendente: false,
            //         bisogniAssistenzialiRilevatiAutonomiaStatoVegetatitivo: false,
            //         bisogniAssistenzialiRilevatiRiabilitazioneNessunaRiabilita: false,
            //         bisogniAssistenzialiRilevatiRiabilitazioneRiabilitazioneNe: false,
            //         bisogniAssistenzialiRilevatiRiabilitazioneAfasia: false,
            //         bisogniAssistenzialiRilevatiRiabilitazioneRiabilitazioneOr: false,
            //         bisogniAssistenzialiRilevatiApparatoRespiratorioNormale: false,
            //         bisogniAssistenzialiRilevatiApparatoRespiratorioTosseSecr: false,
            //         bisogniAssistenzialiRilevatiApparatoRespiratorioOssigenote: false,
            //         bisogniAssistenzialiRilevatiApparatoRespiratorioVentiloter: false,
            //         bisogniAssistenzialiRilevatiApparatoRespiratorioTracheotom: false,
            //         bisogniAssistenzialiRilevatiStatoMutrizionaleNormale: false,
            //         bisogniAssistenzialiRilevatiStatoMutrizionaleDimagrito: false,
            //         bisogniAssistenzialiRilevatiStatoMutrizionaleNutrizioneDi: false,
            //         bisogniAssistenzialiRilevatiComportamentoNormale: false,
            //         bisogniAssistenzialiRilevatiComportamentoDisturboCognitivo: false,
            //         bisogniAssistenzialiRilevatiComportamentoDisturboComportam: false,
            //         bisogniAssistenzialiRilevatiComportamentoCondizioniSalute: false,
            //         bisogniAssistenzialiRilevatiRitmoSonnoVegliaAlterato: false,
            //         bisogniAssistenzialiRilevatiRitmoSonnoVegliaRischioInfet: false,
            //         bisogniAssistenzialiRilevatiRitmoSonnoVegliaFebbre: false,
            //         bisogniAssistenzialiRilevatiApparatoGastrointestinaleNorm: false,
            //         bisogniAssistenzialiRilevatiApparatoGastrointestinaleInco: false,
            //         bisogniAssistenzialiRilevatiApparatoGastrointestinaleVomi: false,
            //         bisogniAssistenzialiRilevatiApparatoGastrointestinaleSang: false,
            //         bisogniAssistenzialiRilevatiApparatoGenitoUrinarioNormale: false,
            //         bisogniAssistenzialiRilevatiApparatoGenitoUrinarioInconti: false,
            //         bisogniAssistenzialiRilevatiApparatoGenitoUrinarioCateter: false,
            //         bisogniAssistenzialiRilevatiApparatoGenitoUrinarioUrostom: false,
            //         bisogniAssistenzialiRilevatiApparatoTegumentarioNormale: false,
            //         bisogniAssistenzialiRilevatiApparatoTegumentarioUlcereCP: false,
            //         bisogniAssistenzialiRilevatiApparatoTegumentarioUlcereCT: false,
            //         bisogniAssistenzialiRilevatiApparatoTegumentarioStatoGrav: false,
            //         bisogniAssistenzialiRilevatiApparatoGastrointestinaleStom: false,
            //         bisogniAssistenzialiRilevatiApparatoGastrointestinaleStip: false,
            //         bisogniAssistenzialiRilevatiApparatoGastrointestinaleDiar: false,
            //         bisogniAssistenzialiRilevatiApparatoGenitoUrinarioEmaturi: false,
            //         bisogniAssistenzialiRilevatiApparatoGenitoUrinarioDialisi: false,
            //         bisogniAssistenzialiRilevatiApparatoTegumentarioCuraFerit: false,
            //         bisogniAssistenzialiRilevatiApparatoTegumentarioAltriProb: false,
            //         bisogniAssistenzialiRilevatiApparatoTegumentarioLacerazion: false,
            //         bisogniAssistenzialiRilevatiOncologicoNonOncologicoTermi: false,
            //         bisogniAssistenzialiRilevatiOncologicoTerminaleNonOncolo: false,
            //         bisogniAssistenzialiRilevatiOncologicoTerminaleOncologica: false,
            //         bisogniAssistenzialiRilevatiOncologicoOncologico: false,
            //         bisogniAssistenzialiRilevatiOncologicoChemioterapia: false,
            //         bisogniAssistenzialiRilevatiOncologicoRadioterapia: false,
            //         bisogniAssistenzialiRilevatiOncologicoDolorePresente: false,
            //         bisogniAssistenzialiRilevatiPrestazioniPrelievoVenosoNon: false,
            //         bisogniAssistenzialiRilevatiPrestazioniEcg: false,
            //         bisogniAssistenzialiRilevatiPrestazioniTelemetria: false,
            //         bisogniAssistenzialiRilevatiPrestazioniTrasfusioni: false,
            //         bisogniAssistenzialiRilevatiPrestazioniTerapiaEndovena: false,
            //         bisogniAssistenzialiRilevatiPrestazioniTerapiaIntramuscol: false,
            //         bisogniAssistenzialiRilevatiPrestazioniGestioneCvc: false,
            //         medicoMedicinaGeneraleNumeroSettimane: "",
            //         medicoMedicinaGeneraleDal: null,
            //         medicoMedicinaGeneraleAl: null,
            //         medicoSpecialistaNumeroSettimane: "",
            //         medicoSpecialistaDal: null,
            //         medicoSpecialistaAl: null,
            //         infermiereProfessionaleNumeroSettimane: "",
            //         infermiereProfessionaleDal: null,
            //         infermiereProfessionaleAl: null,
            //         fisioterapistaLogopedistaTerapistaOccupazionaleNumeroSetti: "",
            //         fisioterapistaLogopedistaTerapistaOccupazionaleDal: null,
            //         fisioterapistaLogopedistaTerapistaOccupazionaleAl: null,
            //         psicologoNumeroSettimane: "",
            //         psicologoDal: null,
            //         psicologoAl: null,
            //         operatoreSociosanitarioNumeroSettimane: "",
            //         operatoreSociosanitarioDal: null,
            //         operatoreSociosanitarioAl: null,
            //         bisogniSocioSanitariRilevati: "",
            //         luogo: "",
            //         data: null,
            //         medico: "",
            //         statoAttuale: "",
            //         statoAttualeDataOra: null,
            //         telefono: "",
            //         impossibilitaAltro: false,
            //         statoNutrizionaleDisidratazione: false,
            //         disturboCognitivoGrave: false,
            //         sesso: "",
            //         tesseraSanitaria: "",
            //         cittadinanza: "",
            //         capResidenza: "",
            //         nazioneResidenzaCodiceIstat: "",
            //         regioneResidenzaCodiceIstat: "",
            //         email: "",
            //         medicoCurante: "",
            //         contattiMmg: "",
            //         comuneDomicilioCodiceIstat: "",
            //         capNascita: "",
            //         capDomicilio: "",
            //         indirizzoDomicilio: "",
            //         prestazioneInterventiNaturaSociosanitaria: "",
            //         numeroProtocollo: "",
            //         dataProtocollo: null,
            //         cotDestinazione: "",
            //         rsaDestinazione: "",
            //     };
            // },
        },
    },
    watch: {
        "jsonData.identificativo": function (value) {
            if (value) {
                var regexCodiceFiscale = /^(?:(?:[B-DF-HJ-NP-TV-Z]|[AEIOU])[AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[1256LMRS][\dLMNP-V])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/;
                if (regexCodiceFiscale.test(value.toUpperCase())) {
                    this.codiceFiscaleValido = true;
                } else this.codiceFiscaleValido = false;
            }
        },
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    mounted() {
        let me = this;
        me.codiceStrutturaAnagraficaRegionale = this.appConfig.codiceStrutturaAnagraficaRegionale;
        me.idPropostaAccesso = this.$route.params.id;
        if (Vue.prototype.user.profilo === "COT OPERATORE") {
            me.viewPai = false;
        }
        if (Vue.prototype.user.profilo === "COT MMG") {
            me.viewPai = false;
        }
        // console.log(this.jsonData);
        let codiceFiscale = this.$route.query.codiceFiscale;
        this.jsonData.provenienzaCodiceStruttura = this.$route.query.codiceStruttura ? this.$route.query.codiceStruttura : "";
        this.jsonData.provenienzaDescrizioneStruttura = this.$route.query.descrizioneStruttura ? this.$route.query.descrizioneStruttura : "";
        let provenienzaCodiceReparto = this.$route.query.codiceReparto;
        let provenienzaDescrizioneReparto = this.$route.query.descrizioneReparto;
        let provenienzaEventoClinico = this.$route.query.eventoClinico;
        let provenienzaNominativoMedico = this.$route.query.nominativoMedico;
        let provenienzaCognomeMedico = this.$route.query.cognomeMedico;
        let provenienzaCodiceFiscaleMedico = this.$route.query.codiceFiscaleMedico;
        let provenienzaRuoloMedico = this.$route.query.ruoloMedico;
        let provenienzaIdMedico = this.$route.query.idMedico;
        let provenienzaEmail = this.$route.query.mailMedico;
        let provenienzaUsername = this.$route.query.usernameMedico;

        if (codiceFiscale) {
            this.jsonData.identificativo = codiceFiscale;
        }
        // if (provenienzaCodiceStruttura) {
        //     this.jsonData.provenienzaCodiceStruttura = provenienzaCodiceStruttura;
        // }
        // if (provenienzaDescrizioneStruttura) {
        //     this.jsonData.provenienzaDescrizioneStruttura = provenienzaDescrizioneStruttura;
        // }
        if (provenienzaCodiceReparto) {
            this.jsonData.provenienzaCodiceReparto = provenienzaCodiceReparto;
        }
        if (provenienzaDescrizioneReparto) {
            this.jsonData.provenienzaDescrizioneReparto = provenienzaDescrizioneReparto;
        }
        if (provenienzaEventoClinico) {
            this.jsonData.provenienzaEventoClinico = provenienzaEventoClinico;
        }
        if (provenienzaNominativoMedico) {
            this.jsonData.provenienzaNominativoMedico = provenienzaNominativoMedico;
        }
        if (provenienzaCognomeMedico) {
            this.jsonData.provenienzaCognomeMedico = provenienzaCognomeMedico;
        }
        if (provenienzaCodiceFiscaleMedico) {
            this.jsonData.provenienzaCodiceFiscaleMedico = provenienzaCodiceFiscaleMedico;
        }
        if (provenienzaRuoloMedico) {
            this.jsonData.provenienzaRuoloMedico = provenienzaRuoloMedico;
        }
        if (provenienzaIdMedico) {
            this.jsonData.provenienzaIdMedico = provenienzaIdMedico;
        }
        if (provenienzaEmail) {
            this.jsonData.provenienzaEmail = provenienzaEmail;
        }
        if (provenienzaUsername) {
            this.jsonData.provenienzaUsername = provenienzaUsername;
        }

        if (!codiceFiscale && me.idPropostaAccesso === "-1") {
            me.jsonData.provenienzaCodiceStruttura = "MMG00";
            me.jsonData.provenienzaDescrizioneStruttura = "MEDICO DI MEDICINA GENERALE";
            me.jsonData.provenienzaCodiceReparto = "AMBMMG00";
            me.jsonData.provenienzaDescrizioneReparto = "AMBULATORIO";
            me.jsonData.provenienzaEventoClinico = "";
            me.jsonData.provenienzaNominativoMedico = this.utente.firstname;
            me.jsonData.provenienzaCognomeMedico = this.utente.lastname;
            me.loadDatiMedico();
            //   console.log(this.utente);
        }
        me.setPermessi();
        // me.loadComuni();
        me.loadCittadinanza();
        me.loadDefaultData();
    },

    // mounted() {
    //     let me = this;
    //     me.id = this.$route.params.id;
    //     // console.log(Vue.prototype.user);
    //     if (Vue.prototype.user.profilo === "COT MMG") {
    //         me.selectStruttura = true;
    //     }
    //     if (Vue.prototype.user.profilo === "COT OPERATORE") {
    //         me.selectStruttura = false;
    //     }
    //     me.loadDefaultData();
    // },
    methods: {
        setPermessi() {
            let me = this;
            // console.log(this.utente);

            if (this.utente.profilo === "COT MMG") {
                me.selectStruttura = true;
            }
            if (this.utente.profilo === "COT OPERATORE") {
                me.selectStruttura = false;
            }
            me.btnDisableInserimentoProvenienza = UtilityMixin.methods.verificaPermessiByIdSezionePermesso("cot", 131072);
        },
        loadDefaultData() {
            let me = this;
            me.loadNazioni();
            me.loadRegioni();
            me.loadDistretti();
            me.loadIcdix();
            me.loadStrutture();
            me.loadDestinaizoni();
            me.loadData();
        },
        loadData() {
            let me = this;

            if (me.idPropostaAccesso === "-1") {
                me.onSearchAnagraficaClick();
                this.$emit("afterLoadData");
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.idPropostaAccesso;
                axios
                    .get(link)
                    .then((response) => {
                        this.$emit("update", response.data.data);
                        me.loadProvince(response.data.data.regioneResidenzaCodiceIstat);
                        // console.log(me.jsonData.provinciaResidenza);
                        me.loadComuni(response.data.data.provinciaResidenza);
                        me.changeCotDestinazioni(response.data.data.cotDestinazione);
                        this.$emit("afterLoadData");
                    })
                    .catch(() => {
                        this.$emit("afterLoadData");
                    });
            }
        },
        loadDatiMedico() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceMedico;
            axios
                .get(link)
                .then((response) => {
                    me.medico = response.data.data;
                    // console.log(me.medico);

                    me.jsonData.provenienzaNominativoMedico = me.medico.nome;
                    me.jsonData.provenienzaCognomeMedico = me.medico.cognome;
                    me.jsonData.provenienzaCodiceFiscaleMedico = me.medico.codiceFiscale;
                    me.jsonData.provenienzaRuoloMedico = "Medico";
                    me.jsonData.provenienzaIdMedico = me.medico.id;
                    me.jsonData.provenienzaEmail = me.medico.email;
                    me.jsonData.provenienzaUsername = me.medico.username;
                    // this.$emit("update", response.data.data);
                    // this.$emit("afterLoadData");
                })
                .catch(() => {
                    // this.$emit("afterLoadData");
                });
        },
        loadIcdix() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkIcdix + "?page=1&forPage=1000000";
            axios.get(link).then((response) => {
                me.icdixOptions = response.data.data.list;
                me.icdixOptions.unshift({ codiceIcdix: "", descrizioneIcdix: "-Seleziona Valore-" });
            });
        },
        onSearchAnagraficaClick() {
            let me = this;
            me.disableField = true;
            let link = process.env.VUE_APP_PATH_API + "/utility/anagraficaregionale";
            // let link = process.env.VUE_APP_PATH_API + "/utility";
            if (me.jsonData.identificativo) {
                axios
                    .get(link, { params: { codiceStruttura: me.codiceStrutturaAnagraficaRegionale, codiceFiscale: me.jsonData.identificativo } })
                    .then((response) => {
                        // console.log(response);
                        if (response.data.data !== null) {
                            me.jsonData.nome = response.data.data.nome;
                            me.jsonData.cognome = response.data.data.cognome;
                            me.jsonData.dataNascita = moment(response.data.data.dataNascita, "DD/MM/YYYY").toDate().getTime();
                            me.jsonData.sesso = response.data.data.sesso;
                            me.jsonData.comuneNascita = response.data.data.comuneNascita;
                            me.jsonData.comuneResidenza = response.data.data.comuneResidenza;
                            me.jsonData.indirizzoResidenza = response.data.data.indirizzoResidenza;
                            me.jsonData.indirizzoDomicilio = "";
                            me.jsonData.identificativo = response.data.data.codiceFiscale;
                            me.jsonData.medicoCurante = response.data.data.medico;
                            me.jsonData.tesseraSanitaria = response.data.data.numeroTesseraSanitaria;
                            me.jsonData.comuneNascitaCodiceIstat = response.data.data.codiceIstatComuneNascita;
                            me.jsonData.comuneResidenzaCodiceIstat = response.data.data.codiceIstatComuneResidenza;
                            me.jsonData.comuneDomicilioCodiceIstat = "";
                            me.jsonData.capNascita = "";
                            me.jsonData.capDomicilio = "";
                            me.jsonData.capResidenza = response.data.data.capResidenza;
                            me.jsonData.cittadinanza = response.data.data.cittadinanza;
                            me.jsonData.regioneResidenzaCodiceIstat = response.data.data.regioneResidenza;
                            me.jsonData.eta = UtilityMixin.methods.calcoloEta(me.jsonData.dataNascita);
                            me.jsonData.telefono = response.data.data.telefono;
                            me.jsonData.nazioneResidenzaCodiceIstat = response.data.data.cittadinanza === "ITALIANA" ? "111100" : "";
                            me.headerCard = "Richiesta a C.O.T. Paziente: " + me.jsonData.cognome + " " + me.jsonData.nome + " (" + me.jsonData.identificativo + ") ";
                            if (me.jsonData.cittadinanza === "") {
                                me.disableField = false;
                            }
                        } else {
                            return me.$bvModal.msgBoxOk("Codice Fiscale non corretto", {
                                title: "Errore",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            });
                        }
                    })
                    .catch(() => {
                        me.$emit("update", me.jsonData);
                    });
            }
        },
        loadNazioni() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkNazioni;
            axios.get(link).then((response) => {
                me.nazioniOptions = response.data.data;
            });
        },
        loadRegioni() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkRegioni;
            axios.get(link).then((response) => {
                me.regioniOptions = response.data.data;
            });
        },
        loadDistretti() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkDistretti;
            me.distrettiOptions = [];
            axios.get(link).then((response) => {
                response.data.data.list.forEach((element) => {
                    me.distrettiOptions.push({ value: element.id, text: "(" + element.codiceDistretto + ") " + element.descrizione });
                });
                me.distrettiOptions.unshift({ value: "", text: "-Seleziona Distretto-" });
            });
        },
        loadCittadinanza() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkCittadinanza;
            me.cittadinanzaOptions = [];
            axios.get(link).then((response) => {
                // console.log(response.data.data);
                response.data.data.list.forEach((element) => {
                    me.cittadinanzaOptions.push({ value: element.denominazione, text: element.denominazione });
                });
                me.cittadinanzaOptions.unshift({ value: "", text: "-Seleziona Cittadinanza-" });
            }).catch(() => {
                this.$emit("afterLoadData");
            });
        },
        loadStrutture() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkStrutture;
            me.rsaDestinazioneOptions = [];
            axios.get(link).then((response) => {
                me.rsaDestinazioneList = response.data.data.list;
                response.data.data.list.forEach((element) => {
                    me.rsaDestinazioneOptions.push({ value: element.denominazione, text: element.denominazione + " (" + element.liberi + ")" });
                });
                me.rsaDestinazioneOptions.unshift({ value: "", text: "-Seleziona Valore-" });
            });
        },
        onChangeRsaDestinazione() {
            let me = this;
            me.rsaDestinazioneList.forEach((element) => {
                if (element.denominazione === me.jsonData.rsaDestinazione) {
                    me.jsonData.codiceRsaDestinazione = element.codiceStruttura;
                }
            });
        },
        loadDestinaizoni() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkDestinazioni;
            me.cotDestinazioneList = [];
            me.cotDestinazioneOptions = [];
            axios.get(link).then((response) => {
                me.cotDestinazioneList = response.data.data.list;
                response.data.data.list.forEach((element) => {
                    me.cotDestinazioneOptions.push({ value: element.destinazione, text: element.destinazione });
                });
                me.cotDestinazioneOptions.unshift({ value: "", text: "-Seleziona Valore-" });
            });
        },
        onChangeCotDestinazioni() {
            let me = this;
            me.jsonData.idSedePuaDestinazione = "";
            me.changeCotDestinazioni(me.jsonData.cotDestinazione);
        },
        changeCotDestinazioni(value) {
            let me = this;
            me.cotDestinazioneList.forEach((element) => {
                if (value === element.destinazione) {
                    me.loadCotPuaDestinazione(element.id);
                }
            });
        },
        onChangePuaDestinazione() {
            let me = this;
            me.cotPuaDestinazioniOptions.forEach((element) => {
                if (me.jsonData.idSedePuaDestinazione === element.id) {
                    me.jsonData.descrizioneSedePuaDestinazione = element.denominazione;
                }
            });
        },
        loadCotPuaDestinazione(idCot) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceCotPuaSedi + "?idCot=" + idCot;
            // me.cotPuaDestinazioniList = [];
            me.cotPuaDestinazioniOptions = [];
            axios.get(link).then((response) => {
                // response.data.data.list.forEach((element) => {
                //     me.cotPuaDestinazioniOptions.push({ value: element.destinazione, text: element.destinazione });
                // });
                me.cotPuaDestinazioniOptions = response.data.data.list;
                me.cotPuaDestinazioniOptions.unshift({ id: "", denominazione: "-Seleziona Valore-" });
            });
        },
        onChangeValutazioneCognitiva() {
            let me = this;
            me.calcolaValutazioneCognitiva();
        },
        calcolaValutazioneCognitiva() {
            let me = this;
            let totalePunteggio = 0;

            if (me.jsonData.situazioneCognitivaLucido) {
                totalePunteggio += 1;
            }
            if (me.jsonData.situazioneCognitivaConfuso) {
                totalePunteggio += 2;
            }
            if (me.jsonData.situazioneCognitivaMoltoConfuso) {
                totalePunteggio += 3;
            }

            if (me.jsonData.problemiComportamentaliAsentiLievi) {
                totalePunteggio += 1;
            }
            if (me.jsonData.problemiComportamentaliModerati) {
                totalePunteggio += 2;
            }
            if (me.jsonData.problemiComportamentaliGravi) {
                totalePunteggio += 3;
            }

            if (me.jsonData.situazioneFunzionaleAutonomoQuasi) {
                totalePunteggio += 1;
            }
            if (me.jsonData.situazioneFunzionaleDipendente) {
                totalePunteggio += 2;
            }
            if (me.jsonData.situazioneFunzionaleTotalmenteDipendente) {
                totalePunteggio += 3;
            }

            if (me.jsonData.gradoAutonomiaSpostaSolo) {
                totalePunteggio += 1;
            }
            if (me.jsonData.gradoAutonomiaSpostaAssistito) {
                totalePunteggio += 2;
            }
            if (me.jsonData.gradoAutonomiaTotalmenteDipendente) {
                totalePunteggio += 3;
            }

            if (me.jsonData.supportoReteSocialeBenAssistito) {
                totalePunteggio += 1;
            }
            if (me.jsonData.supportoReteSocialePazialmenteAssistito) {
                totalePunteggio += 2;
            }
            if (me.jsonData.supportoSocialeNonAssistito) {
                totalePunteggio += 3;
            }

            if (me.jsonData.necessitaAssitenzaSanitariaBassa) {
                totalePunteggio += 1;
            }
            if (me.jsonData.necessitaAssistenzaSanitariaIntermedia) {
                totalePunteggio += 2;
            }
            if (me.jsonData.necessitaAssistenzaSanitariaElevata) {
                totalePunteggio += 3;
            }
            me.jsonData.totalePunteggio = totalePunteggio;
        },
        loadComuni(sigla) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkComune + "/provincia/" + sigla;
            me.comuneOption = [];
            axios
                .get(link)
                .then((response) => {
                    me.comuneOption = response.data.data;
                })
                .catch(() => {
                    this.$emit("afterLoadData");
                });
        },
        onSelectRegione() {
            let me = this;
            me.loadProvince(me.jsonData.regioneResidenzaCodiceIstat);
        },
        loadProvince(value) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkProvince + "?regione=" + value;
            me.provinceOptions = [];
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    me.provinceOptions.push({ value: element.sigla, text: element.provincia });
                });
                me.provinceOptions.unshift({ value: null, text: "-Seleziona Valore-" });
            });
        },
        setCapResidenza(value) {
            let me = this;
            me.comuneOption.forEach((comuneResidenza) => {
                if (comuneResidenza.codiceIstat === value) {
                    me.jsonData.capResidenza = comuneResidenza.cap;
                    me.jsonData.comuneResidenza = comuneResidenza.comune;
                }
            });
        },
    },
};
</script>

<style></style>
