<template>
    <sa-page-layout :linkback="linkback" :btnBackVisible="true" :btnNewVisible="false" :btnRefreshVisible="false" :showModalLoading="showModalLoading" :toolbarVisible="true" toggleableDimension="sm" :contentBodyHeader="false">
        <template slot="table-body">
            <b-card class="sa-card" header="Attività" header-tag="header" footer-tag="footer" title="">
                <!-- <h4 class="header-title mt-0 mb-3"><i class="mdi mdi-notification-clear-all mr-1"></i> {{ this.$i18n.t("patients.lblDiary") }}</h4> -->
                <template #header>
                    <h4 class="header-title mt-0 mb-3">
                        <i class="bi bi-journal-bookmark-fill"></i>
                        Dossier Clinico
                    </h4>
                </template>
                <ul class="timeline2">
                    <li v-for="item in dossier" :key="item.id" class="event" data-date="25 Maggio 2021 | 10:30">
                        <span class="mdi mdi-circle pal"></span>
                        <!-- <span class="sa-timeline-date">{{ formatDateTime(item.createDate) }}</span> -->
                        <span class="sa-timeline-date">{{ item.creationDate }}</span>
                        <h3>
                            <a href="#" onclick="return false;" @click="onTipoVisitaClick(item)">{{ item.subject }} - {{ item.categoryName }}</a>
                            <!-- <a href="#" onclick="return false;" @click="onTipoVisitaClick(item)">{{ item.titolo }} - {{ item.reparto }}</a> -->
                        </h3>
                        <!-- <span>{{ item.lastnameUpdater }} {{ item.firstnameUpdater }} </span> -->
                        <span>{{ item.businessUnit }} </span>
                    </li>
                </ul>
            </b-card>
            <pdf-print-view-embedded ref="pdfPrintViewEmbedded" :pdf="stampaReport"></pdf-print-view-embedded>
        </template>
    </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PdfPrintViewEmbedded from "../../utility/components/PdfPrintViewEmbedded.vue";
import moment from "moment";
import axios from "axios";
export default {
    components: { SaPageLayout, PdfPrintViewEmbedded },
    data() {
        return {
            pathResourceDossier: "/documentrepository/listdocumentbycodicefiscale",
            pathResourceDocumento: "/documentrepository/download",
            // pathResourceDossier: "/cotdossiersanitarii",
            id: null,
            identificativo: null,
            linkback: "",
            stampaReport: null,
            showModalLoading: false,
            dossier: [{ id: 1, dataEvento: 1716364105, titolo: "Prova Uno" }],
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.identificativo = this.$route.query.identificativo;
        // console.log(me.id);
        // console.log(me.identificativo);
        me.linkback = "/cot/richieste/edit/" + me.id;
        me.loadDossierSanitario();
    },
    methods: {
        formatDateTime(value) {
            if (value) {
                return moment(new Date(value)).format("DD-MM-YYYY HH:mm");
            } else {
                return "---";
            }
        },
        loadDossierSanitario() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceDossier;
            me.dossier = [];
            axios
                .get(link, { params: { identificativo: me.identificativo } })
                .then((response) => {
                    // me.dossier = response.data.data.list;
                    response.data.data.list.forEach((element) => {
                        if (!element.isDeleted) {
                            me.dossier.push(element);
                        }
                    });
                    me.showModalLoading = false;
                })
                .catch((error) => {
                    me.showModalLoading = false;
                    this.$bvModal
                        .msgBoxOk(error.response.data.messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then((value) => {
                            console.log(value);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                });
        },
        // onTipoVisitaClick(value) {
        //     let me = this;
        //     me.$refs.pdfPrintViewEmbedded.show();
        //     me.stampaReport = value.base64.includes("data:application/pdf;base64,") ? value.base64 : "data:application/pdf;base64," + value.base64;
        // },
        onTipoVisitaClick(value) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceDocumento + "/" + value.id;
            axios
                .get(link)
                .then((response) => {
                    me.$refs.pdfPrintViewEmbedded.show();
                    me.stampaReport = response.data.data.data.includes("data:application/pdf;base64,") ? response.data.data.data : "data:application/pdf;base64," + response.data.data.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
};
</script>

<style scoped>
.sa-page-paziente-diario-clinico .card-body {
    height: 100%;
    overflow: auto;
}

.pal,
.palV {
    margin-left: -40px;
    position: relative;
    font-size: 18px;
}
.pal {
    background-color: #ab1f3a;
}
.mdi-circle {
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    top: 18px;
}

.timeline2 {
    border-left: 3px solid #f2f2f2;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    margin: 0 0 0 10px;
    position: relative;
    padding-left: 30px;
    max-width: 85%;
    letter-spacing: 0.2px;
    line-height: 1.3em;
    font-size: 1.03em;
    list-style: none;
    text-align: left;
}

.timeline2 h2,
.timeline2 h3 {
    font-size: 1.067rem;
}
.sa-timeline-date {
    font-size: 1rem;
}
</style>
