<template>
    <div style="height: 100%; overflow: auto">
        <b-tabs id="tabsSchede" nav-wrapper-class="sa-tab-items" content-class="mt-3" ref="tabs" class="sa-tab" style="height: 100%; padding-bottom: 25px" fill>
            <b-tab lazy title="Dati Struttura" active>
                <b-card class="sa-card" header="Anagrafica Struttura" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Nome</label>
                            <b-form-input v-model="jsonData.denominazione" placeholder="Nome Struttura"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Codice Regione</label>
                            <b-form-input v-model="jsonData.codiceRegione" placeholder="Codice Regione"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Codice Struttura</label>
                            <b-form-input v-model="jsonData.codiceStruttura" placeholder="Codice Struttura"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Partita Iva</label>
                            <b-form-input v-model="jsonData.partitaIva" placeholder="Partita Iva"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Codice Fiscale</label>
                            <b-form-input v-model="jsonData.codiceFiscale" placeholder="Codice Fiscale"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">IBAN</label>
                            <b-form-input v-model="jsonData.iban" placeholder="IBAN"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Regime Fiscale</label>
                            <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.codiceRegimeFiscale" class="mb-2 mr-sm-2 mb-sm-0" :options="codiciRegimiFiscaliOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                        <!-- <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Prestazione</label>
                            <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.codicePrestazione" class="mb-2 mr-sm-2 mb-sm-0" :options="codicePrestazioneOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                        </b-col> -->
                        <b-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
                            <label class="sa-label-data">Tipologia Di Assistenza</label>
                            <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.tipoStruttura" class="mb-2 mr-sm-2 mb-sm-0" :options="tipoStrutturaOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card class="sa-card" header="Informazioni Struttura" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Indirizzo</label>
                            <b-form-input v-model="jsonData.indirizzo" placeholder="Indirizzo"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
                            <label class="sa-label-data">Provincia</label>
                            <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.provincia" class="mb-2 mr-sm-2 mb-sm-0" :options="provinciaOption" :value="null" @change="loadComune(jsonData.provincia)"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
                            <label class="sa-label-data">Località</label>
                            <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.localita" class="mb-2 mr-sm-2 mb-sm-0" :options="comuneOption" :value="null" @change="loadCap(jsonData.localita)"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
                            <label class="sa-label-data">CAP</label>
                            <b-form-input v-model="jsonData.cap" placeholder="CAP"></b-form-input>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card class="sa-card" header="Contatti" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Telefono</label>
                            <b-form-input v-model="jsonData.telefono" placeholder="Telefono"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Email</label>
                            <b-form-input v-model="jsonData.email" placeholder="Email"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Fax</label>
                            <b-form-input v-model="jsonData.fax" placeholder="Fax"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Pec</label>
                            <b-form-input v-model="jsonData.url" placeholder="Pec"></b-form-input>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card class="sa-card" header="Note" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-form-textarea v-model="jsonData.note" placeholder="Note" no-resize></b-form-textarea>
                        </b-col>
                    </b-row>
                </b-card>
            </b-tab>
            <b-tab lazy title="Storico">
                <b-card class="sa-card" header="Storico Disponibilita'" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-table sticky-header ref="table" stacked="xl" striped hover :items="jsonData.listaDisponibilita" :fields="fieldsDisponibilita" sort-icon-left head-variant="light" class="sa-b-table">
                                <template #cell(index)="row">
                                    {{ row.index + 1 }}
                                </template>
                                <template v-slot:cell(createDate)="{ item }">
                                    <span>{{ formatDateTime(item.createDate) }}</span>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </b-card>
            </b-tab>
        </b-tabs>
    </div>
</template>
<script>
import axios from "axios";
//import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import UtilityMixin from "../../utility/UtilityMixin";
export default {
    components: {},
    mixins: [UtilityMixin],
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    data() {
        return {
            pathResource: "/cotstrutture",
            linkback: null,
            linkProvince: "/province",
            linkComune: "/comuni/provincia",
            linkComuneByIstat: "/comuni/istat",
            linkRegimiFiscali: "/regimifiscali",
            linkCodiciPrestazioni: "/cotcodiciprestazione",
            linkTipoStruttura: "/cotstrutturetipi",
            linkStrutture: "/strutture",
            showModalLoading: false,
            file1: null,
            id: null,
            strutturaOption: [{ value: null, text: "-Seleziona Struttura-" }],
            provinciaOption: [{ value: null, text: "-Seleziona Provincia-" }],
            comuneOption: [{ value: null, text: "-Seleziona Comune-" }],
            codicePrestazioneOptions: [{ value: "", text: "-Seleziona Valore-" }],
            tipoStrutturaOptions: [{ value: "", text: "-Seleziona Valore-" }],
            codiciRegimiFiscaliOptions: [{ value: null, text: "-Seleziona Regime Fiscale-" }],
            jsonData: { localita: null, provincia: null, cap: null, codiceRegimeFiscale: null },
            fieldsDisponibilita: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Disponibilita",
                    key: "disponibilita",
                    sortable: true,
                    thStyle: "width: 20rem",
                },
                {
                    label: "Data Ora",
                    key: "createDate",
                    sortable: true,
                    thStyle: "width: 20rem",
                },
            ],
        };
    },
    mounted: function () {
        let me = this;
        me.id = this.$route.params.id;
        me.loadDefaultData();
    },
    methods: {
        loadDefaultData() {
            let me = this;
            me.loadProvince();
            me.loadCodiciPrestazioni();
            me.loadTipoStruttura();
            me.loadData();
            me.loadRegimiFiscali();
        },
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
        },
        loadCodiciPrestazioni() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkCodiciPrestazioni;
            me.codicePrestazioneOptions = [];
            axios.get(link).then((response) => {
                response.data.data.list.forEach((element) => {
                    me.codicePrestazioneOptions.push({ value: element.codice, text: element.codice });
                });
                me.codicePrestazioneOptions.unshift({ value: "", text: "-Seleziona Valore-" });
            });
        },
        loadTipoStruttura() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkTipoStruttura;
            me.tipoStrutturaOptions = [];
            axios.get(link).then((response) => {
                response.data.data.list.forEach((element) => {
                    me.tipoStrutturaOptions.push({ value: element.tipo, text: element.descrizione });
                });
                me.tipoStrutturaOptions.unshift({ value: "", text: "-Seleziona Valore-" });
            });
        },
        loadData() {
            let me = this;
            if (me.id === "-1") {
                this.$emit("afterLoadData");
            } else {
                // me.showModalLoading = true;
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                axios.get(link + me.id).then((response) => {
                    me.jsonData = response.data.data;
                    if (me.jsonData.localita !== null && me.jsonData.localita !== "") {
                        me.loadComune(me.jsonData.provincia);
                        me.loadCap(me.jsonData.localita);
                    }
                    this.$emit("afterLoadData");
                });
            }
        },
        loadProvince() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkProvince;
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    me.provinciaOption.push({ value: element.sigla, text: element.provincia });
                });
            });
        },
        loadComune(sigla) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkComune + "/";
            axios.get(link + sigla).then((response) => {
                me.comuneOption = [];
                response.data.data.forEach((element) => {
                    me.comuneOption.push({ value: element.codiceIstat, text: element.comune });
                });
            });
        },
        loadCap(localita) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkComuneByIstat + "/" + localita;
            axios.get(link, { params: {} }).then((response) => {
                me.jsonData.cap = response.data.data.cap;
            });
        },
        loadRegimiFiscali() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkRegimiFiscali;
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    let descrizione = "(" + element.codiceRegimeFiscale + ") " + element.descrizioneRegimeFiscale;
                    me.codiciRegimiFiscaliOptions.push({ value: element.codiceRegimeFiscale, text: descrizione });
                });
            });
        },
    },
};
</script>
