<template>
    <div>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card sa-card-border" header="Riposo/Sonno" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Disturbo nel sonno</label>
                            <b-form-select v-model="jsonDataRiposoSonno.disturboSonno" :options="disturboSonnoOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Descrizione sonno</label>
                            <b-form-input v-model="jsonDataRiposoSonno.descrizioneSonno" :readonly="isReadOnly"></b-form-input>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Riposo pomeridiano</label>
                            <b-form-select v-model="jsonDataRiposoSonno.riposoPomeridiano" :options="riposoPomeridianoOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Descrizione riposo</label>
                            <b-form-input v-model="jsonDataRiposoSonno.descrizioneRiposo" :readonly="isReadOnly"></b-form-input>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Utilizzo di Ipnoinducenti</label>
                            <b-form-select v-model="jsonDataRiposoSonno.usoIpnoinducenti" :options="usoIpnoinducentiOptions" :disabled="isReadOnly">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Seleziona un'opzione- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Descrizione ipnoinducenti</label>
                            <b-form-input v-model="jsonDataRiposoSonno.descrizioneIpnoinducenti" :readonly="isReadOnly"></b-form-input>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Note</label>
                            <b-form-textarea v-model="jsonDataRiposoSonno.note" :readonly="isReadOnly" max-rows="10" rows="6"> </b-form-textarea>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import UtilityMixin from "../../../utility/UtilityMixin";

export default {
    props: {
        isReadOnly: {
            type: Boolean,
            default: false,
        },
        idAccettazione: {
            type: String,
        },
    },
    mixins: [UtilityMixin],
    data() {
        return {
            pathResourceSchedaInfermieristicaRiposoSonno: "/rsaschedainfermieristicaripososonno",
            jsonDataRiposoSonno: {
                id: null,
                idAccettazione: null,
                disturboSonno: null,
                riposoPomeridiano: null,
                usoIpnoinducenti: null,
                descrizioneSonno: null,
                descrizioneRiposo: null,
                descrizioneIpnoinducenti: null,
                note: null,
            },

            disturboSonnoOptions: [
                { text: "SI", value: "SI" },
                { text: "NO", value: "NO" },
                { text: "DA VALUTARE", value: "DA VALUTARE" },
            ],
            riposoPomeridianoOptions: [
                { text: "SI", value: "SI" },
                { text: "NO", value: "NO" },
                { text: "DA VALUTARE", value: "DA VALUTARE" },
            ],
            usoIpnoinducentiOptions: [
                { text: "SI", value: "SI" },
                { text: "NO", value: "NO" },
                { text: "DA VALUTARE", value: "DA VALUTARE" },
            ],
        };
    },
    methods: {
        loadRiposoSonnoData() {
            let me = this;
            // console.log("ID accettazione nel loadRiposoSonnoData: " + me.idAccettazione);
            UtilityMixin.methods.loadDataResources(me.pathResourceSchedaInfermieristicaRiposoSonno + "/accettazione/" + me.idAccettazione, {}, me.resultResponseRiposoSonnoSuccess, me.resultResponseRiposoSonnoError);
        },
        resultResponseRiposoSonnoSuccess(response) {
            let me = this;
            me.jsonDataRiposoSonno = response.data.data;
            // console.log("ID del record RIPOSO/SONNO:", me.jsonDataRiposoSonno.id);
        },
        resultResponseRiposoSonnoError(error) {
            let me = this;
            if (error.response && error.response.status === 404) {
                //console.log("NESSUN record trovato del RIPOSO/SONNO per questo id accettazione");
                me.resetForm();
            } else {
                console.error("Errore nel caricamento dati della sezione RIPOSO/SONNO:", error);
            }
        },
        resetForm() {
            let me = this;
            me.jsonDataRiposoSonno = {
                id: null,
                idAccettazione: me.idAccettazione,
                disturboSonno: null,
                riposoPomeridiano: null,
                usoIpnoinducenti: null,
                descrizioneSonno: null,
                descrizioneRiposo: null,
                descrizioneIpnoinducenti: null,
                note: null,
            };
        },

        onSalvaRiposoSonno() {
            let me = this;
            me.jsonDataRiposoSonno.idAccettazione = me.idAccettazione;
            console.log("Salvataggio RIPOSO/SONNO con dati:", JSON.stringify(me.jsonDataRiposoSonno));
            if (!me.jsonDataRiposoSonno.idAccettazione) {
                // console.log("idAccettazione: ", me.jsonDataRiposoSonno.idAccettazione);
                me.$bvToast.toast("Errore: ID Accettazione mancante", {
                    title: "Errore",
                    variant: "danger",
                    solid: true,
                });
                return;
            }

            UtilityMixin.methods.saveResource(me.pathResourceSchedaInfermieristicaRiposoSonno, me.jsonDataRiposoSonno, me.saveRiposoSonnoSuccessResponse, me.saveRiposoSonnoErrorResponse);
        },
        saveRiposoSonnoSuccessResponse(response) {
            console.log("RIPOSO/SONNO salvato con successo:", response);
        },
        saveRiposoSonnoErrorResponse(error) {
            console.error("Errore nel salvataggio RIPOSO/SONNO:", error);
        },
    },
};
</script>
