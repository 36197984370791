<template>
    <sa-page-layout :btnBackVisible="true" :linkback="linkback" :pathResource="pathResource" :btnEditVisible="true" :linkedit="linkedit" :data="jsonData" :showModalLoading="showModalLoading" class="sa-no-space">
        <template slot="toolbar">
            <b-button class="float-sm-right" variant="outline-success btn-toolbar sa-margin-right" size="sm" v-b-modal.modalAssociaUtente>
                <b-icon icon="people-fill"></b-icon>
                Associa Utente
            </b-button>
        </template>
        <template slot="table-body">
            <div class="sa-tab-scroll">
                <malattie-infettive-strutture-view-component ref="MalattieInfettiveStruttureViewComponent" @update="onUpdateJsonData"></malattie-infettive-strutture-view-component>
            </div>
            <b-modal size="lg" ref="modalAssocia" id="modalAssociaUtente" title="Associa Utente" @show="onShow()" scrollable>
                <b-form @submit.prevent="onSubmitUsers">
                    <b-row>
                        <b-col xs="6" sm="6" md="6" lg="6" xl="6">
                            <b-form-input v-model="mdlFiltroUsers.username" type="search" placeholder="Username"></b-form-input>
                        </b-col>

                        <b-col xs="6" sm="6" md="6" lg="6" xl="6">
                            <b-form-select v-model="mdlFiltroUsers.profilo" :options="profiliModalOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="sa-padding-right text-right">
                            <b-button type="submit" variant="info">Cerca</b-button>
                        </b-col>
                    </b-row>
                </b-form>
                <div class="b-table-sticky-header">
                    <b-table sticky-header :busy="isBusy" ref="table" selectable stacked="lg" striped hover :items="itemsUtenti" :fields="fieldsModal" sort-icon-left head-variant="light" class="sa-b-table" current-page="1" :per-page="perPageUsers" @row-selected="onRowSelected">
                        <template #table-busy>
                            <div class="text-center text-info my-2">
                                <b-spinner class="align-middle"></b-spinner>
                                <strong>Caricamento...</strong>
                            </div>
                        </template>
                        <template #cell(selected)="{ rowSelected }">
                            <template v-if="rowSelected">
                                <span aria-hidden="true">&check;</span>
                                <span class="sr-only">Selected</span>
                            </template>
                            <template v-else>
                                <span aria-hidden="true">&nbsp;</span>
                                <span class="sr-only">Not selected</span>
                            </template>
                        </template>
                        <template #cell(firstname)="row">
                            <p>{{ row.item.lastname }} {{ row.item.firstname }}</p>
                        </template>
                    </b-table>
                </div>

                <template #modal-footer="{ ok }">
                    <b-button size="sm" variant="outline-danger" @click="ok()">
                        <b-icon icon="x"></b-icon>
                        Annulla
                    </b-button>
                    <b-button size="sm" variant="outline-success" @click="onAssocia()">
                        <b-icon icon="person-check-fill"></b-icon>
                        Associa
                    </b-button>
                </template>
            </b-modal>
        </template>
    </sa-page-layout>
</template>

<script>
import axios from "axios";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import MalattieInfettiveStruttureViewComponent from "../components/MalattieInfettiveStruttureViewComponent.vue";
export default {
    components: { SaPageLayout, MalattieInfettiveStruttureViewComponent },
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    data() {
        return {
            linkback: "/malattieinfettive/strutture",
            linkedit: "/malattieinfettive/strutture/edit",
            pathResourceUtenti: "/users",
            pathResourceGruppiUtenti: "/gruppi",
            pathResourceStrutturaUtenti: "/malattieinfettivestruttureutenti",
            jsonData: {},
            showModalLoading: false,
            pathResource: "/malattieinfettivestrutture",
            perPageUsers: 100000,
            currentPage: 1,
            currentPageUsers: 1,
            filtroUsers: { forPage: 0, page: 0, user: "", profilo: "" },
            mdlFiltroUsers: {
                profilo: "",
                username: "",
            },
            fieldsModal: [
                {
                    label: "",
                    key: "selected",
                    thStyle: "width: 2.5rem",
                    tdClass: "text-center",
                },
                {
                    label: "Username",
                    key: "username",
                    sortable: true,
                    thStyle: "width: 8rem",
                },
                {
                    label: "Nome",
                    key: "firstname",
                    sortable: true,
                    thStyle: "width: 8rem",
                },
                {
                    label: "Profilo",
                    key: "profilo",
                    sortable: true,
                    thStyle: "width: 8rem",
                },
            ],
            profiliModalOptions: [],
            isBusy: false,
            selected: [],
            itemsUtenti: [],
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.linkedit = "/malattieinfettive/strutture/edit/" + me.id;
    },
    methods: {
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
        },
        onRowSelected(items) {
            this.selected = items;
        },
        onShow() {
            let me = this;
            me.loadDataUtenze();
        },
        onResetFiltro() {
            let me = this;
            me.filtroModal.profilo = "";
            me.filtroModal.username = "";
            me.loadDataUtenze();
        },
        onFiltra() {
            let me = this;
            me.loadDataUtenze();
            // me.showModalLoading = true;
            // console.log(me.filtroModal.profilo);
            // let link = process.env.VUE_APP_PATH_API + me.pathResourceUtenti + "?user=" + me.filtroModal.username + "&profilo=" + me.filtroModal.profilo;
            // console.log(link);
            // axios.get(link).then((response) => {
            //     me.showModalLoading = false;
            //     me.itemsUtenti = response.data.data;
            // });
        },
        loadData() {
            let me = this;
            // me.showModalLoading = true;
            me.$refs.MalattieInfettiveStruttureViewComponent.loadData();
        },
        onSubmitUsers() {
            let me = this;
            me.loadDataUtenze();
        },

        onAssocia() {
            let me = this;
            for (let i = 0; i < me.selected.length; i++) {
                let obj = { id: null, idAmbulatorio: me.jsonData.id, idUtente: me.selected[i].id };
                me.associaUtenteStruttura(obj);
            }
        },
        associaUtenteStruttura(obj) {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceStrutturaUtenti + "/";
            axios
                .post(link, { idStruttura: obj.idAmbulatorio, idUtente: obj.idUtente })
                .then((response) => {
                    me.$refs["modalAssocia"].hide();
                    response.data.data;
                    me.showModalLoading = false;
                    me.loadData();
                    // console.log(me.jsonData);
                    this.$bvToast.toast("Associato Alla Struttura" + me.jsonData.nome, {
                        title: "Associazione avvenuta con Successo",
                        solid: true,
                        variant: "success",
                        autoHideDelay: 1000,
                        appendToast: true,
                    });
                })
                .catch((error) => {
                    me.showModalLoading = false;
                    this.$bvModal
                        .msgBoxOk(error.response.data.messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then((value) => {
                            this.boxTwo = value;
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                    me.showModalLoading = false;
                });
        },
        loadDataUtenze() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceUtenti;
            me.showModalLoading = true;
            me.filtroUsers.forPage = me.perPageUsers;
            me.filtroUsers.page = me.currentPageUsers;
            me.filtroUsers.username = me.mdlFiltroUsers.username;
            me.filtroUsers.profilo = me.mdlFiltroUsers.profilo;
            axios.get(link, { params: me.filtroUsers }).then((response) => {
                me.isBusy = false;
                me.showModalLoading = false;
                me.itemsUtenti = response.data.data.list;
            });
        },
    },
};
</script>

<style></style>
