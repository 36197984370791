<template>
    <sa-page-layout :btnNewVisible="btnNewVisible" :btnRefreshVisible="true" @refresh="onRefresh" :pathResource="pathResource" :linkedit="linkedit" :showModalLoading="showModalLoading">
        <template slot="table-body">
            <elaborazioni-batch-elaborazioni-list-component ref="ElaborazioniBatchElaborazioniListComponent" @afterLoadData="onAfterLoadData"></elaborazioni-batch-elaborazioni-list-component>
        </template>
    </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import ElaborazioniBatchElaborazioniListComponent from "../components/ElaborazioniBatchElaborazioniListComponent.vue";
export default {
    components: { SaPageLayout, ElaborazioniBatchElaborazioniListComponent },
    data() {
        return {
            btnNewVisible: true,
            pathResource: "",
            linkedit: "/dataanalysisquery",
            showModalLoading: false,
            listKey: 0,
        };
    },
    mounted() {
        let me = this;
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            me.$refs.ElaborazioniBatchElaborazioniListComponent.loadData();
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        onAfterLoadData() {
            let me = this;
            me.showModalLoading = false;
        },
    },
};
</script>

<style></style>
