<template>
  <sa-page-layout :btnSaveVisible="true" :data="jsonData" :btnAnnullaVisible="true" :showModalLoading="showModalLoading" :linkback="linkback" :pathResource="pathResource" @aftersave="onAfterSave" class="sa-no-space">
    <template slot="table-body">
      <configurazione-medico-edit-component @update="onUpdateJsonData"></configurazione-medico-edit-component>
    </template>
  </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import ConfigurazioneMedicoEditComponent from '../components/ConfigurazioneMedicoEditComponent.vue';
export default {
  components: { SaPageLayout, ConfigurazioneMedicoEditComponent },
  data() {
    return {
      id: "-1",
      pathResource: "/medici",
      linkback: "/medici",
      showModalLoading: false,
      jsonData: {},
    };
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
  },
  methods: {
    onUpdateJsonData(data) {
      let me = this;
      me.jsonData = data;
    },
    onAfterSave() {
      let me = this;
      me.id = me.$route.params.id;
      if (me.id === "-1") {
        me.$router.replace(me.linkback).catch((err) => {
          console.log(err);
        });
      }
    },
  },
};
</script>

<style></style>
