<template>
	<sa-page-layout :showModalLoading="showModalLoading" class="sa-no-space" :navLeftVisible="true">
		<template slot="toolbar-left">
			<!-- <paziente-eventi-menu-component :idPaziente="idPaziente"></paziente-eventi-menu-component> -->
		</template>
		<template slot="table-body">
			<rsa-scheda-paziente-component :utente="utente" :idPaziente="idPaziente"> </rsa-scheda-paziente-component>
		</template>
	</sa-page-layout>
</template>
<script>
import axios from "axios";
import RsaSchedaPazienteComponent from "../schedapaziente/components/RsaSchedaPazienteComponent.vue";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
	props: {
		utente: {
			required: true,
			type: Object,
			default: function () {
				return null;
			},
		},
	},
	components: { SaPageLayout, RsaSchedaPazienteComponent },
	data() {
		return {
			showModalLoading: false,
			messageDanger: false,
			collegamentoWt: false,
			idPaziente: null,
			eventiMenu: [],
			menuKey: 1,
		};
	},
	created() {
		let me = this;
		me.idPaziente = me.$route.params.id;
	},
	mounted() {
		let me = this;
		// me.loadEventiMenu();
		me.loadUserData();
	},
	methods: {
		loadUserData() {
			let me = this;

			me.showModalLoading = true;
			let link = process.env.VUE_APP_PATH_API + "/users/profile";
			axios.get(link)
				.then((response) => {
					let utente = response.data.data;
					this.$user = utente;
					me.nominativoUtente = utente.lastname + " " + utente.firstname;
					me.ruolo = utente.profilo;
					me.menu = response.data.data.gruppo.menuGruppo;
					me.showModalLoading = false;
				})
				.catch((e) => {
					this.errors.push(e);
				});
		},
	},
};
</script>
