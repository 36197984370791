<template>
    <div class="sa-tab-scroll">
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card sa-card-border" header="Sezione anagrafica e profilo personale" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Nominativo</label>
                            <b-form-input v-model="nominativo" disabled></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Data di nascita</label>
                            <date-picker format="DD/MM/YYYY" value-type="timestamp" v-model="accettazioneAnagrafica.dataNascita" type="date"></date-picker>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Sesso</label>
                            <b-form-select v-model="accettazioneAnagrafica.sesso" :options="sessoOptions">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Selezionare Sesso- </b-form-select-option>
                                </template>
                            </b-form-select>
                            <!--     {{ accettazione.accettazioneAnagrafica.sesso }} -->
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Codice fiscale</label>
                            <b-form-input v-model="accettazioneAnagrafica.identificativo"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">N. Tessera sanitaria</label>
                            <b-form-input v-model="accettazioneAnagrafica.tesseraAnagrafica"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Residenza</label>
                            <b-form-input v-model="accettazioneAnagrafica.comuneResidenzaCodiceIstat"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Domicilio</label>
                            <b-form-input v-model="accettazioneAnagrafica.comuneDomicilioCodiceIstat"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Provincia</label>
                            <b-form-input v-model="accettazioneAnagrafica.provinciaDomicilio"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Recapito telefonico</label>
                            <b-form-input v-model="accettazioneAnagrafica.telefono"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Medico curante</label>
                            <b-form-input v-model="accettazioneAnagrafica.medicoCurante"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Medico curante telefono</label>
                            <b-form-input v-model="accettazioneAnagrafica.medicoCuranteTelefono"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Medico curante email</label>
                            <b-form-input v-model="accettazioneAnagrafica.emailMedicoCurante"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Data di ricovero presso l'attuale struttura</label>
                            <date-picker format="DD/MM/YYYY" value-type="timestamp" v-model="jsonData.dataRicoveroStrutturaAttuale" type="date"></date-picker>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card class="sa-card sa-card-border" header="Persona di riferimento" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Nominativo</label>
                            <b-form-input v-model="personaRiferimento.nominativo"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">indirizzo</label>
                            <b-form-input v-model="personaRiferimento.indirizzo"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Recapito telefonico</label>
                            <b-form-input v-model="personaRiferimento.cellulare"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Grado di parentela</label>
                            <b-form-input v-model="personaRiferimento.gradoRelazione"></b-form-input>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card class="sa-card sa-card-border" header="Stato civile e condizione lavorativa" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Stato civile</label>
                            <b-form-select v-model="jsonData.statoCivile" :options="statoCivileOptions">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Selezionare Stato Civile- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Condizione lavorativa</label>
                            <b-form-select v-model="jsonData.condizioneLavorativa" :options="condizioneLavorativaOptions">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Selezionare Condizione Lavorativa- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Tipo della condizione lavorativa</label>
                            <b-form-select v-model="jsonData.condizioneLavorativaOccupatoTipo" :disabled="jsonData.condizioneLavorativa !== 'OCCUPATO/A'" :options="condizioneLavorativaTipoOptions">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Selezionare Valore- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Forma</label>
                            <b-form-select v-model="jsonData.condizioneLavorativaOccupatoForma" :disabled="jsonData.condizioneLavorativa !== 'OCCUPATO/A'" :options="condizioneLavorativaFormaOptions">
                                <template #first>
                                    <b-form-select-option :value="null" disabled> -Selezionare Valore- </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>

                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Specificare condizione lavorativa</label>
                            <b-form-input v-model="jsonData.condizioneLavorativaSpecifica" :disabled="jsonData.condizioneLavorativa !== 'SPECIFICA'"></b-form-input>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <!--  <b-row>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6"> -->
        <b-card class="sa-card sa-card-border" header="Forme di tutela legale di cui si fruisce" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Fruisce di tutela legale</label>
                    <b-form-select v-model="jsonData.fruisceTutelaLegale" :options="fruisceTutelaLegaleOptions">
                        <template #first>
                            <b-form-select-option :value="null" disabled> -Selezionare Valore- </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <!--<b-col cols="12" xs="12" sm="12" md="12" lg="12">
          <b-form-checkbox v-model="isDomicilioUgualeResidenza" name="check-button" switch> </b-form-checkbox>
          <b-form-select v-model="jsonData.fruisceTutelaLegale" :options="fruisceTutelaLegaleOptions"></b-form-select>
         </b-col> -->
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-row>
                        <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                            <b-form-checkbox v-model="isAmministratoreSostegno" :disabled="jsonData.fruisceTutelaLegale !== 'SI'" switch>Amministratore di sostegno:</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                            <b-form-input v-model="jsonData.legaleAmministratoreSostegnoDettaglio" :disabled="!isAmministratoreSostegno"></b-form-input>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-row>
                        <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                            <b-form-checkbox v-model="isTutore" :disabled="jsonData.fruisceTutelaLegale !== 'SI'" switch>Tutore:</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                            <b-form-input v-model="jsonData.legaleTutoreDettaglio" :disabled="!isTutore"></b-form-input>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-row>
                        <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                            <b-form-checkbox v-model="isLegaleAltro" :disabled="jsonData.fruisceTutelaLegale !== 'SI'" switch>Altro (specificare):</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                            <b-form-input v-model="jsonData.legaleAltroDettaglio" :disabled="!isLegaleAltro"></b-form-input>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-row>
                        <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                            <b-form-checkbox v-model="isLegaleNessuna" :disabled="jsonData.fruisceTutelaLegale !== 'SI'" switch>Nessuna:</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                            <b-form-input v-model="jsonData.legaleNessunaDettaglio" :disabled="!isLegaleNessuna"></b-form-input>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-card>
        <!--   </b-col> -->
        <!--   <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <b-card class="sa-card" header="Data di ricovero presso l'attuale struttura" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <date-picker format="DD/MM/YYYY" value-type="timestamp" v-model="accettazione.dataNascita" type="date"></date-picker>
            </b-col>
          </b-row>
        </b-card>
      </b-col> -->
        <!--  </b-row> -->
        <b-card class="sa-card sa-card-border" header="Diagnosi di dimissioni" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Diagnosi</label>
                    <b-form-select v-model="jsonData.diagnosiDimissioni" :options="diagnosiDimissioniOptions">
                        <template #first>
                            <b-form-select-option :value="null" disabled> -Selezionare Diagnosi- </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Annotazioni</label>
                    <b-form-textarea v-model="jsonData.diagnosiDimissioniAnnotazioni" id="textarea-auto-height" rows="7"></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card sa-card-border" header="Informazioni sull'evento" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data dell'evento acuto</label>
                    <date-picker format="DD/MM/YYYY" value-type="timestamp" v-model="jsonData.dataEventoAcuto" type="date"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Luogo della prima rianimazione</label>
                    <b-form-select v-model="jsonData.eventoLuogoPrimaRianimazione" :options="eventoLuogoPrimaRianimazioneOptions">
                        <template #first>
                            <b-form-select-option :value="null" disabled> -Selezionare Valore- </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Tempo rianimazione cardiopolmonare (Minuti)</label>
                    <b-form-input v-model="jsonData.eventoTempoRianimazioneCardiopolmonare"> </b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Eziologia</label>
                    <b-form-checkbox v-model="isEventoEziologiaTraumaCranioEncefalico" switch>Trauma cranio encefalico</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="9" lg="9" xl="9">
                    <label class="sa-label-data">Causa</label>
                    <b-form-input v-model="jsonData.eventoEziologiaTraumaCranioEncefalicoCausa" :disabled="!isEventoEziologiaTraumaCranioEncefalico"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox v-model="isEventoEziologiaVascolare" switch>Vascolare:</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="9" lg="9" xl="9">
                    <b-form-input v-model="jsonData.eventoEziologiaVascolareCausa" :disabled="!isEventoEziologiaVascolare"> </b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox v-model="isEventoEziologiaAnossia" switch>Anossia:</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="9" lg="9" xl="9">
                    <b-form-input v-model="jsonData.eventoEziologiaAnossiaCausa" :disabled="!isEventoEziologiaAnossia"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox v-model="isEventoEziologiaAltro" switch>Altro:</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="9" lg="9" xl="9">
                    <b-form-input v-model="jsonData.eventoEziologiaAltroCausa" :disabled="!isEventoEziologiaAltro"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Danno associato</label>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox v-model="isEventoDannoMidollare" switch>Midollare</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox v-model="isEventoDannoPolmonare" switch>Polmonare</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox v-model="isEventoDannoAddominale" switch>Addominale</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-checkbox v-model="isEventoDannoAltro" switch>Altro</b-form-checkbox>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card sa-card-border" header="Comorbidità pre-evento" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Patologie cardiache (cuore)</label>
                    <b-form-select v-model="jsonData.comorbiditaPreEventoPatologieCardiache" :options="comorbiditaPreEventoOptions" :value="null" value-field="punteggio" text-field="descrizione" @change="onChangeComorbiditaPreEvento()"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Ipertensione</label>
                    <b-form-select v-model="jsonData.comorbiditaPreEventoIpertensione" :options="comorbiditaPreEventoOptions" :value="null" value-field="punteggio" text-field="descrizione" @change="onChangeComorbiditaPreEvento()"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Apparato vascolare</label>
                    <b-form-select v-model="jsonData.comorbiditaPreEventoApparatoVascolare" :options="comorbiditaPreEventoOptions" :value="null" value-field="punteggio" text-field="descrizione" @change="onChangeComorbiditaPreEvento()"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Apparato respiratorio</label>
                    <b-form-select v-model="jsonData.comorbiditaPreEventoApparatoRespiratorio" :options="comorbiditaPreEventoOptions" :value="null" value-field="punteggio" text-field="descrizione" @change="onChangeComorbiditaPreEvento()"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Occhi/ORL</label>
                    <b-form-select v-model="jsonData.comorbiditaPreEventoOcchiOrl" :options="comorbiditaPreEventoOptions" :value="null" value-field="punteggio" text-field="descrizione" @change="onChangeComorbiditaPreEvento()"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Apparato digerente superiore</label>
                    <b-form-select v-model="jsonData.comorbiditaPreEventoApparatoDigerenteSuperiore" :options="comorbiditaPreEventoOptions" :value="null" value-field="punteggio" text-field="descrizione" @change="onChangeComorbiditaPreEvento()"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Apparato digerente inferiore</label>
                    <b-form-select v-model="jsonData.comorbiditaPreEventoApparatoDigerenteInferiore" :options="comorbiditaPreEventoOptions" :value="null" value-field="punteggio" text-field="descrizione" @change="onChangeComorbiditaPreEvento()"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Fegato</label>
                    <b-form-select v-model="jsonData.comorbiditaPreEventoFegato" :options="comorbiditaPreEventoOptions" :value="null" value-field="punteggio" text-field="descrizione" @change="onChangeComorbiditaPreEvento()"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Rene</label>
                    <b-form-select v-model="jsonData.comorbiditaPreEventoRene" :options="comorbiditaPreEventoOptions" :value="null" value-field="punteggio" text-field="descrizione" @change="onChangeComorbiditaPreEvento()"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Altre patologie genito-urinarie</label>
                    <b-form-select v-model="jsonData.comorbiditaPreEventoAltrePatologieGenitoUrinarie" :options="comorbiditaPreEventoOptions" :value="null" value-field="punteggio" text-field="descrizione" @change="onChangeComorbiditaPreEvento()"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Apparato muscolo-scheletrico-cute</label>
                    <b-form-select v-model="jsonData.comorbiditaPreEventoMuscoloScheletricoCute" :options="comorbiditaPreEventoOptions" :value="null" value-field="punteggio" text-field="descrizione" @change="onChangeComorbiditaPreEvento()"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Patologie sistema nervoso centrale</label>
                    <b-form-select v-model="jsonData.comorbiditaPreEventoSistemaNervosoCentrale" :options="comorbiditaPreEventoOptions" :value="null" value-field="punteggio" text-field="descrizione" @change="onChangeComorbiditaPreEvento()"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Apparato endocrino-metabolico</label>
                    <b-form-select v-model="jsonData.comorbiditaPreEventoApparatoEndocrinoMetabolico" :options="comorbiditaPreEventoOptions" :value="null" value-field="punteggio" text-field="descrizione" @change="onChangeComorbiditaPreEvento()"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Stato mentale e comportamento</label>
                    <b-form-select v-model="jsonData.comorbiditaPreEventoStatoMentaleComportamento" :options="comorbiditaPreEventoOptions" :value="null" value-field="punteggio" text-field="descrizione" @change="onChangeComorbiditaPreEvento()"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Livello gravita:</label>
                    <b-form-input v-model="jsonData.comorbiditaPreEventoGravita"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Livello comorbidità:</label>
                    <b-form-input v-model="jsonData.comorbiditaPreEventoComorbidita"></b-form-input>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card sa-card-border" header="Trattamenti chirurgici" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="lg" striped hover :items="jsonData.listaTrattamentiChirurgici" :fields="fieldsTrattamentiChirurgici" :current-page="1" :per-page="50" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
                <template #cell(tipo)="item">
                    <b-form-input v-if="item.index === jsonData.listaTrattamentiChirurgici.length - 1" v-model="item.item.tipo"> </b-form-input>
                    <span v-else> {{ item.item.tipo }} </span>
                </template>
                <template #cell(dataTrattamento)="item">
                    <date-picker v-if="item.index === jsonData.listaTrattamentiChirurgici.length - 1" format="DD/MM/YYYY" value-type="timestamp" v-model="item.item.data" type="date"></date-picker>
                    <span v-else> {{ formatDate(item.item.data) }} </span>
                </template>
                <template #cell(actions)="row">
                    <b-button v-if="row.index === jsonData.listaTrattamentiChirurgici.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddTrattamentoChirurgico(jsonData.listaTrattamentiChirurgici, row.item)">
                        <b-icon icon="plus"></b-icon>
                    </b-button>
                    <b-button v-else size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDeleteTrattamentoChirurgico(jsonData.listaTrattamentiChirurgici, row)">
                        <b-icon icon="trash"></b-icon>
                    </b-button>
                </template>
            </b-table>
        </b-card>
        <b-card class="sa-card sa-card-border" header="Trattamenti riabilitativi" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="lg" striped hover :items="jsonData.listaTrattamentiRiabilitativi" :fields="fieldsTrattamentiRiabilitativi" :current-page="1" :per-page="50" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
                <template #cell(tipo)="item">
                    <b-form-input v-if="item.index === jsonData.listaTrattamentiRiabilitativi.length - 1" v-model="item.item.tipo"> </b-form-input>
                    <span v-else> {{ item.item.tipo }} </span>
                </template>
                <template #cell(frequenza)="item">
                    <b-form-input v-if="item.index === jsonData.listaTrattamentiRiabilitativi.length - 1" v-model="item.item.frequenza"> </b-form-input>
                    <span v-else> {{ item.item.frequenza }} </span>
                </template>
                <template #cell(dataInizio)="item">
                    <date-picker v-if="item.index === jsonData.listaTrattamentiRiabilitativi.length - 1" format="DD/MM/YYYY" value-type="timestamp" v-model="item.item.dataInizio" type="date"></date-picker>
                    <span v-else> {{ formatDate(item.item.dataInizio) }} </span>
                </template>
                <template #cell(dataFine)="item">
                    <date-picker v-if="item.index === jsonData.listaTrattamentiRiabilitativi.length - 1" format="DD/MM/YYYY" value-type="timestamp" v-model="item.item.dataFine" type="date"></date-picker>
                    <span v-else> {{ formatDate(item.item.dataFine) }} </span>
                </template>
                <template #cell(actions)="row">
                    <b-button v-if="row.index === jsonData.listaTrattamentiRiabilitativi.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddTrattamentoRiabilitativi(jsonData.listaTrattamentiRiabilitativi, row.item)">
                        <b-icon icon="plus"></b-icon>
                    </b-button>
                    <b-button v-else size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDeleteTrattamentoRiabilitativi(jsonData.listaTrattamentiRiabilitativi, row)">
                        <b-icon icon="trash"></b-icon>
                    </b-button>
                </template>
            </b-table>
        </b-card>
        <b-card class="sa-card sa-card-border" header="Esami neurofisiologici effettuati" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Esame EEG effettuato</label>
                    <b-form-select v-model="jsonData.esamiNeurofisiologiciEffettuatiEegEsame" :options="esamiNeurofisiologiciEffettuatiEegEsameOptions">
                        <template #first>
                            <b-form-select-option :value="null" disabled> -Selezionare Valore- </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data esame EEG</label>
                    <date-picker format="DD/MM/YYYY" value-type="timestamp" v-model="jsonData.esamiNeurofisiologiciEffettuatiEegData" type="date" :disabled="jsonData.esamiNeurofisiologiciEffettuatiEegEsame !== 'SI'"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Referto esame EEG </label>
                    <b-form-input v-model="jsonData.esamiNeurofisiologiciEffettuatiEegReferto" :disabled="jsonData.esamiNeurofisiologiciEffettuatiEegEsame !== 'SI'"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Esame SEP effettuato</label>
                    <b-form-select v-model="jsonData.esamiNeurofisiologiciEffettuatiSepEsame" :options="esamiNeurofisiologiciEffettuatiSepEsameOptions">
                        <template #first>
                            <b-form-select-option :value="null" disabled> -Selezionare Valore- </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data esame SEP</label>
                    <date-picker format="DD/MM/YYYY" value-type="timestamp" v-model="jsonData.esamiNeurofisiologiciEffettuatiSepData" type="date" :disabled="jsonData.esamiNeurofisiologiciEffettuatiSepEsame !== 'SI'"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Referto esame SEP</label>
                    <b-form-select v-model="jsonData.esamiNeurofisiologiciEffettuatiSepReferto" :options="esamiNeurofisiologiciEffettuatiSepRefertoOptions" :disabled="jsonData.esamiNeurofisiologiciEffettuatiSepEsame !== 'SI'"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Esame ERP effettuato</label>
                    <b-form-select v-model="jsonData.esamiNeurofisiologiciEffettuatiErpEsame" :options="esamiNeurofisiologiciEffettuatiErpEsameOptions">
                        <template #first>
                            <b-form-select-option :value="null" disabled> -Selezionare Valore- </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data esame ERP</label>
                    <date-picker format="DD/MM/YYYY" value-type="timestamp" v-model="jsonData.esamiNeurofisiologiciEffettuatiErpData" type="date" :disabled="jsonData.esamiNeurofisiologiciEffettuatiErpEsame !== 'SI'"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Referto esame ERP</label>
                    <b-form-select v-model="jsonData.esamiNeurofisiologiciEffettuatiErpReferto" :options="esamiNeurofisiologiciEffettuatiErpRefertoOptions" :disabled="jsonData.esamiNeurofisiologiciEffettuatiErpEsame !== 'SI'"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Altro esame effettuato</label>
                    <b-form-select v-model="jsonData.esamiNeurofisiologiciEffettuatiAltroEsame" :options="esamiNeurofisiologiciEffettuatiAltroEsameOptions">
                        <template #first>
                            <b-form-select-option :value="null" disabled> -Selezionare Valore- </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data altro Esame</label>
                    <date-picker format="DD/MM/YYYY" value-type="timestamp" v-model="jsonData.esamiNeurofisiologiciEffettuatiAltroData" type="date" :disabled="jsonData.esamiNeurofisiologiciEffettuatiAltroEsame !== 'SI'"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Altro referto </label>
                    <b-form-input v-model="jsonData.esamiNeurofisiologiciEffettuatiAltroReferto" :disabled="jsonData.esamiNeurofisiologiciEffettuatiAltroEsame !== 'SI'"></b-form-input>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card sa-card-border" header="Esami neuroradiologici" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Esame TC encefalo</label>
                    <b-form-select v-model="jsonData.esameNeuroradiologicoTcEncefaloEsame" :options="options"> </b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data TC encefalo</label>
                    <date-picker format="DD/MM/YYYY" value-type="timestamp" v-model="jsonData.esameNeuroradiologicoTcEncefaloData" type="date" :disabled="jsonData.esameNeuroradiologicoTcEncefaloEsame !== 'SI'"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Referto TC encefalo </label>
                    <b-form-input v-model="jsonData.esameNeuroradiologicoTcEncefaloReferto" :disabled="jsonData.esameNeuroradiologicoTcEncefaloEsame !== 'SI'"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Esame RMN encefalo</label>
                    <b-form-select v-model="jsonData.esameNeuroradiologicoRmnEncefaloEsame" :options="options"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data RMN encefalo</label>
                    <date-picker format="DD/MM/YYYY" value-type="timestamp" v-model="jsonData.esameNeuroradiologicoRmnEncefaloData" type="date" :disabled="jsonData.esameNeuroradiologicoRmnEncefaloEsame !== 'SI'"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Referto RMN encefalo </label>
                    <b-form-input v-model="jsonData.esameNeuroradiologicoRmnEncefaloReferto" :disabled="jsonData.esameNeuroradiologicoRmnEncefaloEsame !== 'SI'"></b-form-input>
                </b-col>
            </b-row>
        </b-card>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <b-card class="sa-card sa-card-border" header="Valutazione clinico-funzionale alla dimissione" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Glasgow outcome scale extended (GOS-E)</label>
                            <b-form-select v-model="jsonData.goseStatoClinico" :options="goseStatoClinicoOptions" :value="null" value-field="value" text-field="text" @change="onChangeGoseStatoClinico"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Levels of cognitive functioning (LCF)</label>
                            <b-form-select v-model="jsonData.lcfLivelloCognitivo" :options="lcfLivelloCognitivoOptions" :value="null" value-field="value" text-field="text" @change="onChangeLcfLivelloCognitivo"></b-form-select>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <b-card class="sa-card sa-card-border" header="Coma recovery scale-revised (CRS-R)" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Funzione uditiva</label>
                            <b-form-select v-model="jsonData.crsrScalaFunzioneUditiva" :options="crsrScalaFunzioneUditivaOptions" @change="onChangeCrsrScala()"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Funzione visiva</label>
                            <b-form-select v-model="jsonData.crsrScalaFunzioneVisiva" :options="crsrScalaFunzioneVisivaOptions" @change="onChangeCrsrScala()"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Funzione motoria</label>
                            <b-form-select v-model="jsonData.crsrScalaFunzioneMotoria" :options="crsrScalaFunzioneMotoriaOptions" @change="onChangeCrsrScala()"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Motoria orale/verbale</label>
                            <b-form-select v-model="jsonData.crsrScalaFunzioneMotoriaOraleVerbale" :options="crsrScalaFunzioneMotoriaOraleVerbaleOptions" @change="onChangeCrsrScala()"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Comunicazione</label>
                            <b-form-select v-model="jsonData.crsrScalaComunicazione" :options="crsrScalaComunicazioneOptions" @change="onChangeCrsrScala()"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Scala per la vigilanza</label>
                            <b-form-select v-model="jsonData.crsrScalaVigilanza" :options="crsrScalaVigilanzaOptions" @change="onChangeCrsrScala()"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Punteggio Totale: {{ jsonData.crsrPunteggioTotale }}</label>
                            <!-- <span> {{ jsonData.crsrPunteggioTotale }}</span> -->
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-card class="sa-card sa-card-border" header="Disability raiting scale (DRS)" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Punteggio apertura degli occhi</label>
                    <b-form-select v-model="jsonData.drsAperturaOcchi" :options="drsAperturaOcchiOptions" @change="onChangeDrsScala()"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Capacita di comunicazione</label>
                    <b-form-select v-model="jsonData.drsCapacitaComunicazione" :options="drsCapacitaComunicazioneOptions" @change="onChangeDrsScala()"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Punteggio miglior risposta motoria</label>
                    <b-form-select v-model="jsonData.drsMigliorRispostaMotoria" :options="drsMigliorRispostaMotoriaOptions" @change="onChangeDrsScala()"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Punteggio nutrirsi</label>
                    <b-form-select v-model="jsonData.drsNutrirsi" :options="drsNutrirsiOptions" @change="onChangeDrsScala()"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Punteggio sfinteri</label>
                    <b-form-select v-model="jsonData.drsSfinteri" :options="drsSfinteriOptions" @change="onChangeDrsScala()"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Punteggio rassettarsi</label>
                    <b-form-select v-model="jsonData.drsRassettarsi" :options="drsRassettarsiOptions" @change="onChangeDrsScala()"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Punteggio livello funzionale</label>
                    <b-form-select v-model="jsonData.drsLivelloFunzionale" :options="drsLivelloFunzionaleOptions" @change="onChangeDrsScala()"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Punteggio adattabilità psicosociale</label>
                    <b-form-select v-model="jsonData.drsAdattabilitaPsicosociale" :options="drsAdattabilitaPsicosocialeOptions" @change="onChangeDrsScala()"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Punteggio Totale: </label>
                    <b-form-input v-model="jsonData.drsPunteggioTotale"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Categoria: </label>
                    <b-form-input v-model="livelloDisabilita"></b-form-input>

                    <!--  <span></span> -->
                </b-col>
            </b-row>
        </b-card>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <b-card class="sa-card sa-card-border" header="Scala di ashworth" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Spalla DX</label>
                            <b-form-select v-model="jsonData.valutazioneTonoMuscolareSpallaDx" :options="valutazioneTonoMuscolareOptions"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Spalla SX</label>
                            <b-form-select v-model="jsonData.valutazioneTonoMuscolareSpallaSx" :options="valutazioneTonoMuscolareOptions"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Gomito DX</label>
                            <b-form-select v-model="jsonData.valutazioneTonoMuscolareGomitoDx" :options="valutazioneTonoMuscolareOptions"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Gomito SX</label>
                            <b-form-select v-model="jsonData.valutazioneTonoMuscolareGomitoSx" :options="valutazioneTonoMuscolareOptions"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Polso DX</label>
                            <b-form-select v-model="jsonData.valutazioneTonoMuscolarePolsoDx" :options="valutazioneTonoMuscolareOptions"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Polso SX</label>
                            <b-form-select v-model="jsonData.valutazioneTonoMuscolarePolsoSx" :options="valutazioneTonoMuscolareOptions"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Mano DX</label>
                            <b-form-select v-model="jsonData.valutazioneTonoMuscolareManoDx" :options="valutazioneTonoMuscolareOptions"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Mano SX</label>
                            <b-form-select v-model="jsonData.valutazioneTonoMuscolareManoSx" :options="valutazioneTonoMuscolareOptions"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Anca DX</label>
                            <b-form-select v-model="jsonData.valutazioneTonoMuscolareAncaDx" :options="valutazioneTonoMuscolareOptions"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Anca SX</label>
                            <b-form-select v-model="jsonData.valutazioneTonoMuscolareAncaSx" :options="valutazioneTonoMuscolareOptions"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Ginocchio DX</label>
                            <b-form-select v-model="jsonData.valutazioneTonoMuscolareGinocchioDx" :options="valutazioneTonoMuscolareOptions"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Ginocchio SX</label>
                            <b-form-select v-model="jsonData.valutazioneTonoMuscolareGinocchioSx" :options="valutazioneTonoMuscolareOptions"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Piede DX</label>
                            <b-form-select v-model="jsonData.valutazioneTonoMuscolarePiedeDx" :options="valutazioneTonoMuscolareOptions"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Piede SX</label>
                            <b-form-select v-model="jsonData.valutazioneTonoMuscolarePiedeSx" :options="valutazioneTonoMuscolareOptions"></b-form-select>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <b-card class="sa-card sa-card-border" header="Valutazione della presenza di blocchi articolari" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Spalla DX</label>
                            <b-form-select v-model="jsonData.presenzaBlocchiArticolariSpallaDx" :options="presenzaBlocchiArticolariOptions"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Spalla SX</label>
                            <b-form-select v-model="jsonData.presenzaBlocchiArticolariSpallaSx" :options="presenzaBlocchiArticolariOptions"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Gomito DX</label>
                            <b-form-select v-model="jsonData.presenzaBlocchiArticolariGomitoDx" :options="presenzaBlocchiArticolariOptions"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Gomito SX</label>
                            <b-form-select v-model="jsonData.presenzaBlocchiArticolariGomitoSx" :options="presenzaBlocchiArticolariOptions"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Polso DX</label>
                            <b-form-select v-model="jsonData.presenzaBlocchiArticolariPolsoDx" :options="presenzaBlocchiArticolariOptions"> </b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Polso SX</label>
                            <b-form-select v-model="jsonData.presenzaBlocchiArticolariPolsoSx" :options="presenzaBlocchiArticolariOptions"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Mano DX</label>
                            <b-form-select v-model="jsonData.presenzaBlocchiArticolariManoDx" :options="presenzaBlocchiArticolariOptions"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Mano SX</label>
                            <b-form-select v-model="jsonData.presenzaBlocchiArticolariManoSx" :options="presenzaBlocchiArticolariOptions"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Anca DX</label>
                            <b-form-select v-model="jsonData.presenzaBlocchiArticolariAncaDx" :options="presenzaBlocchiArticolariOptions"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Anca SX</label>
                            <b-form-select v-model="jsonData.presenzaBlocchiArticolariAncaSx" :options="presenzaBlocchiArticolariOptions"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Ginocchio DX</label>
                            <b-form-select v-model="jsonData.presenzaBlocchiArticolariGinocchioDx" :options="presenzaBlocchiArticolariOptions"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Ginocchio SX</label>
                            <b-form-select v-model="jsonData.presenzaBlocchiArticolariGinocchioSx" :options="presenzaBlocchiArticolariOptions"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Piede DX</label>
                            <b-form-select v-model="jsonData.presenzaBlocchiArticolariPiedeDx" :options="presenzaBlocchiArticolariOptions"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Piede SX</label>
                            <b-form-select v-model="jsonData.presenzaBlocchiArticolariPiedeSx" :options="presenzaBlocchiArticolariOptions"></b-form-select>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-card class="sa-card sa-card-border" header="Comorbidita' sviluppate durante degenza" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Nessuna</label>
                    <b-form-select v-model="jsonData.comorbiditaDuranteDegenzaNessuna" :options="options"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Polmoniti ricorrenti/stati infettivi ricorrenti/sepsi</label>
                    <b-form-select v-model="jsonData.comorbiditaDuranteDegenzaPolmoniInfetti" :options="options" :disabled="jsonData.comorbiditaDuranteDegenzaNessuna !== 'NO'"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data"
                        >Scompenso metabolico
                        <!-- (iposodiemia,iper/ipoglicemia, insufficienza renale/ipotiroidismo scompensato) --></label
                    >
                    <b-form-select v-model="jsonData.comorbiditaDuranteDegenzaScompensoMetabolico" :disabled="jsonData.comorbiditaDuranteDegenzaNessuna !== 'NO'" :options="options"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Scompenso cardiaco con FE &lt; 30</label>
                    <b-form-select v-model="jsonData.comorbiditaDuranteDegenzaScompensoCardiaco" :disabled="jsonData.comorbiditaDuranteDegenzaNessuna !== 'NO'" :options="options"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Crisi epilettiche ricorrentio mioclonie scarsamente controllate</label>
                    <b-form-select v-model="jsonData.comorbiditaDuranteDegenzaCrisiEpilettiche" :disabled="jsonData.comorbiditaDuranteDegenzaNessuna !== 'NO'" :options="options"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Insufficienza respiratoria grave con necessità di ventilazione assistita</label>
                    <b-form-select v-model="jsonData.comorbiditaDuranteDegenzaInsufficienzaRespiratoria" :disabled="jsonData.comorbiditaDuranteDegenzaNessuna !== 'NO'" :options="options"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Grave spasticità (Ashworth 3-4)/Paraosteoartropatie,POA multiple/contratture</label>
                    <b-form-select v-model="jsonData.comorbiditaDuranteDegenzaGraveSpasticita" :disabled="jsonData.comorbiditaDuranteDegenzaNessuna !== 'NO'" :options="options"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Aritmia maligna con necessità di intervento terapeutivo</label>
                    <b-form-select v-model="jsonData.comorbiditaDuranteDegenzaAritmiaMaligna" :options="options" :disabled="jsonData.comorbiditaDuranteDegenzaNessuna !== 'NO'"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Marcata e cronica ipotensione (PAS &lt; 90 mmHg)</label>
                    <b-form-select v-model="jsonData.comorbiditaDuranteDegenzaIpotensione" :disabled="jsonData.comorbiditaDuranteDegenzaNessuna !== 'NO'" :options="options"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Nuovo insulto encefalico (idrocefalo, emorragia, ictus..)</label>
                    <b-form-select v-model="jsonData.comorbiditaDuranteDegenzaInsultoEncefalico" :options="options" :disabled="jsonData.comorbiditaDuranteDegenzaNessuna !== 'NO'"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Iperattività simpatica parossistica e/o disautonomia</label>
                    <b-form-select v-model="jsonData.comorbiditaDuranteDegenzaIperattivitaDisautonomia" :disabled="jsonData.comorbiditaDuranteDegenzaNessuna !== 'NO'" :options="options"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Altro </label>
                    <b-form-select v-model="jsonData.comorbiditaDuranteDegenzaAltro" :disabled="jsonData.comorbiditaDuranteDegenzaNessuna !== 'NO'" :options="options"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Specificare</label>
                    <b-form-input v-model="jsonData.comorbiditaDuranteDegenzaAltroDescrizione" :disabled="jsonData.comorbiditaDuranteDegenzaAltro !== 'SI'"></b-form-input>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card sa-card-border" header="Dati clinici e presenza di protesi ed ausili" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Temperatura corporea</label>
                    <b-form-input v-model="jsonData.parametriVitaliTemperaturaCorporea"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">PA</label>
                    <b-form-input v-model="jsonData.parametriVitaliPa"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">FC</label>
                    <b-form-input v-model="jsonData.parametriVitaliFc"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Sa02 (%)</label>
                    <b-form-input v-model="jsonData.parametriVitaliSa02"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Peso (kg)</label>
                    <b-form-input v-model="jsonData.parametriVitaliPeso"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Altezza</label>
                    <b-form-input v-model="jsonData.parametriVitaliAltezza"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Portatore di catetere</label>
                    <b-form-select v-model="jsonData.portatoreCatetere" :options="options"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Tipo catetere</label>
                    <b-form-select v-model="jsonData.tipoCatetere" :options="tipoCatetereOptions" :disabled="jsonData.portatoreCatetere !== 'SI'"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Sede</label>
                    <b-form-input v-model="jsonData.sedeCatetere" :disabled="jsonData.portatoreCatetere !== 'SI'"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Data</label>
                    <date-picker format="DD/MM/YYYY" value-type="timestamp" v-model="jsonData.dataPosizioneCatetere" type="date" :disabled="jsonData.portatoreCatetere !== 'SI'"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Pompa di balcofene intratecale</label>
                    <b-form-select v-model="jsonData.pompaBalcofeneIntratecale" :options="options"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Data impianto</label>
                    <date-picker format="DD/MM/YYYY" value-type="timestamp" v-model="jsonData.pompaBalcofeneIntratecaleDataImpianto" type="date" :disabled="jsonData.pompaBalcofeneIntratecale !== 'SI'"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Dosaggio </label>
                    <b-form-input v-model="jsonData.pompaBalcofeneIntratecaleDosaggio" :disabled="jsonData.pompaBalcofeneIntratecale !== 'SI'"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Data allarme</label>
                    <date-picker format="DD/MM/YYYY" value-type="timestamp" v-model="jsonData.pompaBalcofeneIntratecaleDataAllarme" type="date" :disabled="jsonData.pompaBalcofeneIntratecale !== 'SI'"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Protesi</label>
                    <b-form-select v-model="jsonData.protesi" :options="options"></b-form-select>
                </b-col>
                <!-- <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Tipo Protesi:</label>
        </b-col> -->
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-row>
                        <b-col cols="12" xs="12" sm="3" md="6" lg="6" xl="6">
                            <b-form-checkbox v-model="isProtesiAcustica" :disabled="jsonData.protesi !== 'SI'" switch>Acustica</b-form-checkbox>
                        </b-col>
                        <!-- <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <b-row> -->
                        <b-col cols="12" xs="12" sm="3" md="6" lg="6" xl="6">
                            <b-form-checkbox v-model="isProtesiDentaria" :disabled="jsonData.protesi !== 'SI'" switch>Dentaria</b-form-checkbox>
                        </b-col>
                    </b-row>
                    <!-- </b-row>
        </b-col> -->
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-row>
                        <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                            <b-form-checkbox v-model="isProtesiArtoprotesi" :disabled="jsonData.protesi !== 'SI'" switch>Artoprotesi</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                            <label class="sa-label-data">Sede</label>
                            <b-form-input v-model="jsonData.protesiArtoprotesiSede" :disabled="!isProtesiArtoprotesi"></b-form-input>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-row>
                        <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                            <b-form-checkbox v-model="isProtesiPacemaker" :disabled="jsonData.protesi !== 'SI'" switch>Pacemaker</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                            <label class="sa-label-data">Data impianto</label>
                            <date-picker format="DD/MM/YYYY" value-type="timestamp" v-model="jsonData.protesiPacemakerDataImpianto" type="date" :disabled="!isProtesiPacemaker"></date-picker>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-row>
                        <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                            <b-form-checkbox v-model="isProtesiAltro" :disabled="jsonData.protesi !== 'SI'" switch>Altro</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                            <label class="sa-label-data">Altro tipo di protesi</label>
                            <b-form-input v-model="jsonData.protesiAltroTipo" :disabled="!isProtesiAltro"></b-form-input>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card sa-card-border" header="Respirazione" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-row>
                        <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                            <label></label>
                            <b-form-checkbox v-model="isRespirazioneAutonoma" switch>Autonoma</b-form-checkbox>
                        </b-col>
                        <!--   <b-col xs="12" sm="12" md="12" lg="12" xl="12"> -->
                        <!--   <b-row> -->
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label></label>
                            <b-form-checkbox v-model="isRespirazioneVentiloterapia" switch>Ventiloterapia</b-form-checkbox>
                        </b-col>

                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Tipo di ventiloterapia</label>
                            <b-form-input v-model="jsonData.respirazioneVentiloterapiaTipo" :disabled="!isRespirazioneVentiloterapia"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Tempo della ventiloterapia</label>
                            <b-form-input v-model="jsonData.respirazioneVentiloterapiaTempo" :disabled="!isRespirazioneVentiloterapia"></b-form-input>
                        </b-col>
                        <!--  </b-row> -->
                        <!-- </b-col> -->
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-row>
                                <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                                    <!-- <label></label> -->
                                    <label class="sa-label-data"></label>
                                    <b-form-checkbox v-model="isRespirazioneSupplementoOssigeno" switch>Necessita di supplemento di ossigeno</b-form-checkbox>
                                </b-col>
                                <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                                    <label class="sa-label-data">Periodo dell'uso del supplemento</label>
                                    <b-form-select v-model="jsonData.respirazioneSupplementoOssigenoTipo" :options="respirazioneSupplementoOssigenoTipoOptions" :disabled="!isRespirazioneSupplementoOssigeno"></b-form-select>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-row>
                                <b-col cols="12" xs="12" sm="3" md="2" lg="2" xl="2">
                                    <label class="sa-label-data"></label>
                                    <b-form-checkbox v-model="isRespirazioneCannulaTracheostomica" switch>Cannula tracheostomica</b-form-checkbox>
                                </b-col>
                                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                                    <label class="sa-label-data">Numero</label>
                                    <b-form-input v-model="jsonData.respirazioneCannulaTracheostomicaNumero" :disabled="!isRespirazioneCannulaTracheostomica"></b-form-input>
                                </b-col>
                                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                                    <label class="sa-label-data">Finestrata</label>
                                    <b-form-select v-model="jsonData.respirazioneCannulaTracheostomicaFenestrata" :disabled="!isRespirazioneCannulaTracheostomica" :options="options"></b-form-select>
                                </b-col>
                                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                                    <label class="sa-label-data">Cuffiata</label>
                                    <b-form-select v-model="jsonData.respirazioneCannulaTracheostomicaCuffiata" :disabled="!isRespirazioneCannulaTracheostomica" :options="options"></b-form-select>
                                </b-col>
                                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                                    <label class="sa-label-data">Controcannula</label>
                                    <b-form-select v-model="jsonData.respirazioneCannulaTracheostomicaControcannula" :disabled="!isRespirazioneCannulaTracheostomica" :options="options"></b-form-select>
                                </b-col>
                                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                                    <label class="sa-label-data">Ultimo cambio</label>
                                    <date-picker format="DD/MM/YYYY" value-type="timestamp" v-model="jsonData.respirazioneCannulaTracheostomicaUltimoCambioData" type="date" :disabled="!isRespirazioneCannulaTracheostomica"></date-picker>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card sa-card-border" header="Funzione Vescicale" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-row>
                        <b-col cols="12" xs="12" sm="3" md="4" lg="3" xl="3">
                            <b-form-checkbox v-model="isFunzioneVescicaleMinzioneAutonoma" switch>Minzione autonoma</b-form-checkbox>
                        </b-col>
                        <!-- <b-col xs="12" sm="12" md="12" lg="12" xl="12">
              <b-row> -->
                        <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                            <b-form-checkbox v-model="isFunzioneVescicaleIncontinente" switch>Pz incontinente ma non cateterizzato</b-form-checkbox>
                        </b-col>
                        <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                            <b-form-checkbox v-model="isFunzioneVescicaleIncontinentePannolone" :disabled="!isFunzioneVescicaleIncontinente" switch>Pannolone</b-form-checkbox>
                        </b-col>
                        <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                            <b-form-checkbox v-model="isFunzioneVescicaleIncontinenteUrocontrol" :disabled="!isFunzioneVescicaleIncontinente" switch>Urocontrol</b-form-checkbox>
                        </b-col>
                        <!--  </b-row>
            </b-col> -->
                        <!--      <b-col xs="12" sm="12" md="12" lg="12" xl="12">
              <b-row> -->
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data"></label>
                            <b-form-checkbox v-model="isFunzioneVescicaleCateterizzato" switch>Pz cateterizzato</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Numero</label>
                            <b-form-input v-model="jsonData.funzioneVescicaleCateterizzatoNumero" :disabled="!isFunzioneVescicaleCateterizzato"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Data ultimo cambio</label>
                            <date-picker format="DD/MM/YYYY" value-type="timestamp" v-model="jsonData.funzioneVescicaleCateterizzatoUltimoCambioData" type="date" :disabled="!isFunzioneVescicaleCateterizzato"></date-picker>
                        </b-col>
                        <!--   </b-row>
            </b-col> -->
                    </b-row>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card sa-card-border" header="Alimentazione" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-row>
                        <b-col cols="12" xs="12" sm="3" md="4" lg="4" xl="4">
                            <b-form-checkbox v-model="isAlimentazioneNutrizioneOs" switch>Nutrizione per os</b-form-checkbox>
                        </b-col>
                        <b-col cols="12" xs="12" sm="3" md="4" lg="4" xl="4">
                            <b-form-checkbox v-model="isAlimentazioneDisfagia" switch>Presenta disfagia</b-form-checkbox>
                        </b-col>
                        <b-col cols="12" xs="12" sm="3" md="4" lg="4" xl="4">
                            <b-form-checkbox v-model="isAlimentazioneNutrizioneParentale" switch>Nutrizione parenterale</b-form-checkbox>
                        </b-col>
                        <!--   <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>   -->
                        <!--  <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                <b-form-checkbox v-model="isAlimentazioneDisfagia" switch>Presenta disfagia</b-form-checkbox>
              </b-col> -->
                        <!-- </b-row>  
            </b-col>-->
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-row>
                                <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                                    <label class="sa-label-data"></label>
                                    <b-form-checkbox v-model="isAlimentazionePortatoreSondinoNasogastrico" switch>Portatore di sondino nasogastrico</b-form-checkbox>
                                </b-col>
                                <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                                    <label class="sa-label-data">Numero</label>
                                    <b-form-input v-model="jsonData.alimentazionePortatoreSondinoNasogastricoNumero" :disabled="!isAlimentazionePortatoreSondinoNasogastrico"></b-form-input>
                                </b-col>
                                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                    <label class="sa-label-data">Data ultimo cambio</label>
                                    <date-picker format="DD/MM/YYYY" value-type="timestamp" v-model="jsonData.alimentazionePortatoreSondinoNasogastricoUltimoCambioData" type="date" :disabled="!isAlimentazionePortatoreSondinoNasogastrico"></date-picker>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-row>
                                <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                                    <label class="sa-label-data"></label>
                                    <b-form-checkbox v-model="isAlimentazionePortatorePeg" switch>Portatore di PEG</b-form-checkbox>
                                </b-col>
                                <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                                    <label class="sa-label-data">Numero</label>
                                    <b-form-input v-model="jsonData.alimentazionePortatorePegNumero" :disabled="!isAlimentazionePortatorePeg"></b-form-input>
                                </b-col>
                                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                    <label class="sa-label-data">Data ultimo cambio</label>
                                    <date-picker format="DD/MM/YYYY" value-type="timestamp" v-model="jsonData.alimentazionePortatorePegUltimoCambioData" type="date" :disabled="!isAlimentazionePortatorePeg"></date-picker>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <!--   <b-row>  
              <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                <b-form-checkbox v-model="isAlimentazioneNutrizioneParentale" switch>Nutrizione parenterale</b-form-checkbox>
              </b-col>
               </b-row> -->
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card sa-card-border" header="Apparato Tegumentario" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Apparato</label>
                    <b-form-select v-model="jsonData.apparatoTegumentario" :options="apparatoTegumentarioOptions"></b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Sede</label>
                            <b-form-checkbox v-model="isApparatoTegumentarioPresenzaDecubitiTrocanterica" :disabled="jsonData.apparatoTegumentario !== 'PRESENZA DI DECUBITI'" switch>Trocanterica</b-form-checkbox>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="9" lg="9" xl="9">
                            <label class="sa-label-data">Stadio (scala NUAP)</label>
                            <b-form-input v-model="jsonData.apparatoTegumentarioPresenzaDecubitiTrocantericaStadio" :disabled="!isApparatoTegumentarioPresenzaDecubitiTrocanterica"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <b-form-checkbox v-model="isApparatoTegumentarioPresenzaDecubitiTalare" :disabled="jsonData.apparatoTegumentario !== 'PRESENZA DI DECUBITI'" switch>Talare</b-form-checkbox>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="9" lg="9" xl="9">
                            <b-form-input v-model="jsonData.apparatoTegumentarioPresenzaDecubitiTalareStadio" :disabled="!isApparatoTegumentarioPresenzaDecubitiTalare"> </b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <b-form-checkbox v-model="isApparatoTegumentarioPresenzaDecubitiSacrale" :disabled="jsonData.apparatoTegumentario !== 'PRESENZA DI DECUBITI'" switch>Sacrale</b-form-checkbox>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="9" lg="9" xl="9">
                            <b-form-input v-model="jsonData.apparatoTegumentarioPresenzaDecubitiSacraleStadio" :disabled="!isApparatoTegumentarioPresenzaDecubitiSacrale"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <b-form-checkbox v-model="isApparatoTegumentarioPresenzaDecubitiIschiatica" :disabled="jsonData.apparatoTegumentario !== 'PRESENZA DI DECUBITI'" switch>Ischiatica</b-form-checkbox>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="9" lg="9" xl="9">
                            <b-form-input v-model="jsonData.apparatoTegumentarioPresenzaDecubitiIschiaticaStadio" :disabled="!isApparatoTegumentarioPresenzaDecubitiIschiatica"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <b-form-checkbox v-model="isApparatoTegumentarioPresenzaDecubitiAltro" :disabled="jsonData.apparatoTegumentario !== 'PRESENZA DI DECUBITI'" switch>Altro</b-form-checkbox>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="9" lg="9" xl="9">
                            <b-form-input v-model="jsonData.apparatoTegumentarioPresenzaDecubitiAltroStadio" :disabled="!isApparatoTegumentarioPresenzaDecubitiAltro"></b-form-input>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-card>
        <!--  <b-row>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6"> -->
        <b-card class="sa-card sa-card-border" header="Caregiver" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Caregiver addestrato</label>
                    <b-form-select v-model="jsonData.caregiverAddestrato" :options="options"></b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Utilizzo ausili</label>
                    <b-form-select v-model="jsonData.caregiverUtilizzoAusili" :options="options"></b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Utilizzo pompa di nutrizione</label>
                    <b-form-select v-model="jsonData.caregiverUtilizzoPompaNutrizione" :options="options"></b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Tracheoaspirazione</label>
                    <b-form-select v-model="jsonData.caregiverTracheoaspirazione" :options="options"></b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Utilizzo ventiloterapia</label>
                    <b-form-select v-model="jsonData.caregiverUtilizzoVentiloterapia" :options="options"></b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Posizionamento</label>
                    <b-form-select v-model="jsonData.caregiverPosizionamento" :options="options"></b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Trasferimenti</label>
                    <b-form-select v-model="jsonData.caregiverTrasferimenti" :options="options"></b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Medicazione piaghe</label>
                    <b-form-select v-model="jsonData.caregiverMedicazionePiaghe" :options="options"></b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Rivelazione parametri vitali</label>
                    <b-form-select v-model="jsonData.caregiverRivelazioneParametriVitali" :options="options"></b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Valutazione/stimolazione multisensiorale</label>
                    <b-form-select v-model="jsonData.caregiverValutazioneStimolazioneMultisensoriale" :options="options"></b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Comunicazione aumnetativa/alternativa</label>
                    <b-form-select v-model="jsonData.caregiverComunicazioneAumentivaAlternativa" :options="options"></b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Altro (specificare)</label>
                    <b-form-select v-model="jsonData.caregiverAltro" :options="options"></b-form-select>
                </b-col>
            </b-row>
        </b-card>
        <!-- </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6"> -->
        <b-card class="sa-card sa-card-border" header="Invio a:" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Struttura/Servizio</label>
                    <b-form-input v-model="jsonData.invioStruttura"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Referente</label>
                    <b-form-input v-model="jsonData.invioReferente"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data</label>
                    <date-picker format="DD/MM/YYYY" value-type="timestamp" v-model="jsonData.invioData" type="date"></date-picker>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Compilatore scheda nome</label>
                    <b-form-input v-model="jsonData.compilatoreSchedaNome"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Compilatore scheda cognome</label>
                    <b-form-input v-model="jsonData.compilatoreSchedaCognome"></b-form-input>
                </b-col>
            </b-row>
        </b-card>
        <!--</b-col></b-row> -->
    </div>
</template>
<script>
import axios from "axios";
import "vue2-datepicker/index.css";
import UtilityMixin from "../../../utility/UtilityMixin";
import DatePicker from "vue2-datepicker";
export default {
    mixins: [UtilityMixin],
    components: { DatePicker },
    data() {
        return {
            pathResource: "/rsaschedevalutazioneallegatoa",
            pathResourceAccettazione: "/rsaaccettazioni",
            pathResourcePersonaRiferimento: "/rsapersoneriferimento",
            pathResourceScalaGose: "/rsaschedevalutazioneallegatoascalagose",
            pathResourceScalaLfc: "/rsaschedevalutazioneallegatoascalalfc",
            pathResourceScaleCrsr: "/rsaschedevalutazioneallegatoascalecrsr",
            pathResourceScaleCisr: "/rsaschedevalutazioneallegatoascalecirs",
            pathResourceScaleDrs: "/rsaschedevalutazioneallegatoascaledrs",
            pathResourceScalaToniMuscolari: "/rsaschedevalutazioneallegatoatonimuscolare",
            pathResourceCategorieDisabilita: "/rsaschedevalutazioneallegatoacategoriedisabilita",
            pathResourceCheck: "/rsaschedevalutazioneallegatoa/check",
            showModalLoading: false,
            listKey: 0,
            rows: 0,
            perPage: 50,
            currentPage: 1,
            id: null,
            categorieDisabilita: [],
            nominativo: "",
            livelloDisabilita: "",
            esito: false,
            accettazioneAnagrafica: {},
            listaPreEvento: [],
            scalaGose: [],
            options: [
                { value: null, text: "-Selezionare Valore-" },
                { value: "SI", text: "SI" },
                { value: "NO", text: "NO" },
            ],
            condizioneLavorativaTipoOptions: [
                { value: "OCCUPAZIONE STABILE", text: "OCCUPAZIONE STABILE" },
                { value: "OCCUPAZIONE TEMPONAREA", text: "OCCUPAZIONE TEMPORANEA" },
                { value: "OCCUPAZIONE PRECARIA", text: "OCCUPAZIONE PRECARIA" },
            ],
            condizioneLavorativaFormaOptions: [
                { value: "IN FORMA DIPENDENTE", text: "IN FORMA DIPENDENTE" },
                { value: "IN FORMA AUTONOMA", text: "IN FORMA AUTONOMA" },
            ],
            tipoCatetereOptions: [
                { value: null, text: "-Selezionare Valore-" },
                { value: "CATETERE VENOSO CENTRALE", text: "CATETERE VENOSO CENTRALE" },
                { value: "CATETERE VENOSO PERIFERICO", text: "CATETERE VENOSO PERIFERICO" },
            ],
            respirazioneSupplementoOssigenoTipoOptions: [
                { text: "-Selezionare Valore-", value: null },
                { text: "PERMANENZA", value: "PERMANENZA" },
                { text: "DI NOTTE", value: "DI NOTTE" },
                { text: "DI GIORNO", value: "DI GIORNO" },
            ],
            sessoOptions: [
                { value: "M", text: "Maschio" },
                { value: "F", text: "Femmina" },
            ],
            statoCivileOptions: [
                { value: "CELIBE/NUBILE", text: "Celibe/Nubile" },
                { value: "CONIUGATO/A", text: "Coniugato/a" },
                { value: "SEPARATO/A", text: "Separato/a" },
                { value: "DIVORZIATO/A", text: "Divorziato/a" },
                { value: "VEDOVO/A", text: "Vedovo/a" },
                { value: "CONVIVENTE", text: "Convivente" },
                { value: "NON DICHIARATO", text: "Non dichiarato" },
            ],
            diagnosiDimissioniOptions: [
                { value: "STATO VEGETATIVO", text: "STATO VEGETATIVO" },
                { value: "STATO DI COSCIENZA MINIMA", text: "STATO DI COSCIENZA MINIMA" },
                { value: "SINDROME DI LOCKED-IN", text: "SINDROME DI LOCKED-IN" },
            ],
            fruisceTutelaLegaleOptions: [
                { value: "SI", text: "SI" },
                { value: "NO", text: "NO" },
            ],
            eziologiaOptions: [],
            dannoAssociatoOptions: [],
            esamiNeurofisiologiciEffettuatiSepEsameOptions: [
                { value: "SI", text: "SI" },
                { value: "NO", text: "NO" },
            ],
            esamiNeurofisiologiciEffettuatiEegEsameOptions: [
                { value: "SI", text: "SI" },
                { value: "NO", text: "NO" },
            ],
            esamiNeurofisiologiciEffettuatiErpEsameOptions: [
                { value: "SI", text: "SI" },
                { value: "NO", text: "NO" },
            ],
            esamiNeurofisiologiciEffettuatiAltroEsameOptions: [
                { value: "SI", text: "SI" },
                { value: "NO", text: "NO" },
            ],
            goseStatoClinicoOptions: [],
            lcfLivelloCognitivoOptions: [],
            valutazioneTonoMuscolareOptions: [
                /*  { value: null, text: "-Selezionare Valore-" },
        { value: "-1", text: "-1" },
        { value: "0", text: "0" },
        { value: "1", text: "1" },
        { value: "2", text: "2" },
        { value: "3", text: "3" },
        { value: "4", text: "4" },
        { value: "5", text: "5" },
        { value: "*", text: "*" }, */
            ],
            presenzaBlocchiArticolariOptions: [
                { value: null, text: "-Selezionare Valore-" },
                { value: "SI", text: "SI" },
                { value: "NO", text: "NO" },
            ],
            //esameNeuroradiologicoTcEncefaloEsameOptions: [],
            //esameNeuroradiologicoRmnEncefaloEsameOptions: [],
            eventoLuogoPrimaRianimazioneOptions: [
                { value: "OSPEDALE", text: "OSPEDALE" },
                { value: "TERRITORIO", text: "TERRITORIO" },
            ],
            esamiNeurofisiologiciEffettuatiErpRefertoOptions: [
                { value: null, text: "-Selezionare Valore-" },
                { value: "PRESENZA BILATERALE N20", text: "PRESENZA BILATERALE N20" },
                { value: "PRESENZA MONOLATERALE N20", text: "PRESENZA MONOLATERARE N20" },
                { value: "ASSENZA BILATERALE N20", text: "ASSENZA BILATERALE N20" },
            ],
            esamiNeurofisiologiciEffettuatiSepRefertoOptions: [
                { value: null, text: "-Selezionare Valore-" },
                { value: "PRESENZA P300 (0MMN)", text: "PRESENZA P300 (0MMN)" },
                { value: "ASSENZA P300 (0MMN)", text: "ASSENZA P300 (0MMN)" },
            ],
            crsrScalaFunzioneUditivaOptions: [],
            crsrScalaFunzioneVisivaOptions: [],
            crsrScalaFunzioneMotoriaOptions: [],
            crsrScalaFunzioneMotoriaOraleVerbaleOptions: [],
            crsrScalaComunicazioneOptions: [],
            crsrScalaVigilanzaOptions: [],
            condizioneLavorativaOptions: [
                { value: "OCCUPATO/A", text: "OCCUPATO/A" },
                { value: "DISOCCUPATO/A", text: "DISOCCUPATO/A" },
                { value: "PENSIONATO/A", text: "PENSIONATO/A" },
                { value: "CASSINTEGRATO/A", text: "CASSINTEGRATO/A" },
                { value: "STUDENTE", text: "STUDENTE" },
                { value: "CASALINGA/O", text: "CASALINGA/O" },
                { value: "SPECIFICA", text: "SPECIFICA" },
            ],
            apparatoTegumentarioOptions: [
                { value: null, text: "-Selezionare Valore-" },
                { value: "INTEGRO", text: "INTEGRO" },
                { value: "PRESENZA DI DECUBITI", text: "PRESENZA DI DECUBITI" },
            ],
            respirazioneCannulaTracheostomicaControcannula: [{ value: null, text: "" }],
            drsAdattabilitaPsicosocialeOptions: [],
            drsAperturaOcchiOptions: [],
            drsCapacitaComunicazioneOptions: [],
            drsLivelloFunzionaleOptions: [],
            drsMigliorRispostaMotoriaOptions: [],
            drsNutrirsiOptions: [],
            drsRassettarsiOptions: [],
            comorbiditaPreEventoOptions: [],
            drsSfinteriOptions: [],
            personaRiferimento: { nominativo: "", recapito: "", gradoparentela: "" },
            fieldsTrattamentiChirurgici: [
                {
                    label: "Tipo",
                    key: "tipo",
                },
                {
                    label: "Data",
                    key: "dataTrattamento",
                },

                { class: "sa-table-column-action-double", label: "", key: "actions" },
            ],
            fieldsTrattamentiRiabilitativi: [
                {
                    label: "Tipo",
                    key: "tipo",
                },
                { label: "Frequenza", key: "frequenza" },
                { label: "Data Inizio", key: "dataInizio" },
                { label: "Data Fine", key: "dataFine" },
                { class: "sa-table-column-action-double", label: "", key: "actions" },
            ],
            jsonData: {
                idAccettazione: "",
                diagnosiDimissioni: null,
                fruisceTutelaLegale: null,
                statoCivile: null,
                condizioneLavorativa: null,
                condizioneLavorativaOccupatoTipo: null,
                condizioneLavorativaOccupatoForma: null,
                esamiNeurofisiologiciEffettuatiEegEsame: null,
                esamiNeurofisiologiciEffettuatiSepEsame: null,
                esamiNeurofisiologiciEffettuatiErpEsame: null,
                esamiNeurofisiologiciEffettuatiAltroEsame: null,
                legaleAmministratoreSostegno: "",
                legaleTutore: "",
                legaleAltro: "",
                legaleNessuna: "",
                eventoEziologiaTraumaCranioEncefalico: "",
                eventoEziologiaVascolare: "",
                eventoEziologiaAltro: "",
                eventoEziologiaAnossia: "",
                eventoDannoMidollare: "",
                eventoDannoAddominale: "",
                eventoDannoPolmonare: "",
                protesiArtoprotesi: "",
                protesiAcustica: "",
                protesiAltro: "",
                protesiDentaria: "",
                protesiPacemaker: "",
                respirazioneAutonoma: "",
                respirazioneCannulaTracheostomica: "",
                respirazioneVentiloterapia: "",
                respirazioneSupplementoOssigeno: "",
                funzioneVescicaleMinzioneAutonoma: "",
                funzioneVescicaleIncontinente: "",
                funzioneVescicaleCateterizzato: "",
                alimentazioneNutrizioneOs: "",
                alimentazioneDisfagia: "",
                alimentazioneNutrizioneParentale: "",
                alimentazionePortatoreSondinoNasogastrico: "",
                alimentazionePortatorePeg: "",
                apparatoTegumentarioPresenzaDecubitiAltroSede: "",
                apparatoTegumentarioPresenzaDecubitiTrocantericaSede: "",
                apparatoTegumentarioPresenzaDecubitiTalareSede: "",
                apparatoTegumentarioPresenzaDecubitiSacraleSede: "",
                apparatoTegumentarioPresenzaDecubitiIschiaticaSede: "",
                funzioneVescicaleIncontinentePannolone: "",
                funzioneVescicaleIncontinenteUrocontrol: "",
                apparatoTegumentario: null,
                caregiverAddestrato: null,
                caregiverAltro: null,
                caregiverComunicazioneAumentivaAlternativa: null,
                caregiverMedicazionePiaghe: null,
                caregiverNominativo: null,
                caregiverPosizionamento: null,
                caregiverRivelazioneParametriVitali: null,
                caregiverTracheoaspirazione: null,
                caregiverTrasferimenti: null,
                caregiverUtilizzoAusili: null,
                caregiverUtilizzoPompaNutrizione: null,
                caregiverUtilizzoVentiloterapia: null,
                caregiverValutazioneStimolazioneMultisensoriale: null,
                compilatoreSchedaNome: "",
                compilatoreSchedaCognome: "",
                crsrPunteggioTotale: "",
                crsrScalaComunicazione: null,
                crsrScalaFunzioneMotoria: null,
                crsrScalaFunzioneMotoriaOraleVerbale: null,
                crsrScalaFunzioneUditiva: null,
                crsrScalaFunzioneVisiva: null,
                crsrScalaVigilanza: null,
                dannoAssociato: "",
                dataEventoAcuto: "",
                dataPosizioneCatetere: "",
                dataRicoveroStrutturaAttuale: "",
                valutazioneTonoMuscolareAncaDx: null,
                valutazioneTonoMuscolareAncaSx: null,
                valutazioneTonoMuscolareGinocchioDx: null,
                valutazioneTonoMuscolareGinocchioSx: null,
                valutazioneTonoMuscolareGomitoDx: null,
                valutazioneTonoMuscolareGomitoSx: null,
                valutazioneTonoMuscolareManoDx: null,
                valutazioneTonoMuscolareManoSx: null,
                valutazioneTonoMuscolareSpallaDx: null,
                valutazioneTonoMuscolareSpallaSx: null,
                valutazioneTonoMuscolarePolsoDx: null,
                valutazioneTonoMuscolarePolsoSx: null,
                valutazioneTonoMuscolarePiedeDx: null,
                valutazioneTonoMuscolarePiedeSx: null,
                presenzaBlocchiArticolariAncaDx: null,
                presenzaBlocchiArticolariAncaSx: null,
                presenzaBlocchiArticolariGinocchioDx: null,
                presenzaBlocchiArticolariGinocchioSx: null,
                presenzaBlocchiArticolariGomitoDx: null,
                presenzaBlocchiArticolariGomitoSx: null,
                presenzaBlocchiArticolariManoDx: null,
                presenzaBlocchiArticolariManoSx: null,
                presenzaBlocchiArticolariSpallaDx: null,
                presenzaBlocchiArticolariSpallaSx: null,
                presenzaBlocchiArticolariPolsoDx: null,
                presenzaBlocchiArticolariPolsoSx: null,
                presenzaBlocchiArticolariPiedeDx: null,
                presenzaBlocchiArticolariPiedeSx: null,
                esameNeuroradiologicoTcEncefaloEsame: null,
                esameNeuroradiologicoRmnEncefaloEsame: null,
                portatoreCatetere: null,
                pompaBalcofeneIntratecale: null,
                pompaBalcofeneIntratecaleDataImpianto: null,
                protesi: null,
                lcfLivelloCognitivo: null,
                goseStatoClinico: null,
                eventoLuogoPrimaRianimazione: null,
                esamiNeurofisiologiciEffettuatiSepReferto: null,
                esamiNeurofisiologiciEffettuatiErpReferto: null,
                tipoCatetere: null,
                lcfLivelloCognitivoPunteggio: "",
                crsrScalaVigilanzaPunteggio: "",
                crsrScalaComunicazionePunteggio: "",
                crsrScalaFunzioneMotoriaPunteggio: "",
                crsrScalaFunzioneMotoriaOraleVerbalePunteggio: "",
                crsrScalaFunzioneVisivaPunteggio: "",
                crsrScalaFunzioneUditivaPunteggio: "",
                drsAperturaOcchi: null,
                drsAdattabilitaPsicosociale: null,
                drsCapacitaComunicazione: null,
                drsCategoriaDisabilita: null,
                drsLivelloFunzionale: null,
                drsMigliorRispostaMotoriaPunteggio: "",
                drsLivelloFunzionalePunteggio: "",
                drsNutrirsiPunteggio: "",
                drsSfinteriPunteggio: "",
                drsRassettarsiPunteggio: "",
                drsAdattabilitaPsicosocialePunteggio: "",
                drsAperturaOcchiPunteggio: "",
                drsCapacitaComunicazionePunteggio: "",
                // drsMigliorRispostaMotoriaPunteggio: "",
                // drsAdattabilitaPsicosocialePunteggio: "",
                drsMigliorRispostaMotoria: null,
                drsNutrirsi: null,
                drsSfinteri: null,
                drsRassettarsi: null,
                drsPunteggioTotale: "",
                comorbiditaDuranteDegenzaAltro: null,
                comorbiditaDuranteDegenzaAritmiaMaligna: null,
                comorbiditaDuranteDegenzaCrisiEpilettiche: null,
                comorbiditaDuranteDegenzaGraveSpasticita: null,
                comorbiditaDuranteDegenzaInsufficienzaRespiratoria: null,
                comorbiditaDuranteDegenzaInsultoEncefalico: null,
                comorbiditaDuranteDegenzaIperattivitaDisautonomia: null,
                comorbiditaDuranteDegenzaIpotensione: null,
                comorbiditaDuranteDegenzaNessuna: null,
                comorbiditaDuranteDegenzaPolmoniInfetti: null,
                comorbiditaDuranteDegenzaScompensoCardiaco: null,
                comorbiditaDuranteDegenzaScompensoMetabolico: null,
                comorbiditaPreEventoPatologieCardiache: null,
                comorbiditaPreEventoAltrePatologieGenitoUrinarie: null,
                comorbiditaPreEventoApparatoDigerenteInferiore: null,
                comorbiditaPreEventoApparatoDigerenteSuperiore: null,
                comorbiditaPreEventoApparatoEndocrinoMetabolico: null,
                comorbiditaPreEventoApparatoRespiratorio: null,
                comorbiditaPreEventoApparatoVascolare: null,
                comorbiditaPreEventoFegato: null,
                comorbiditaPreEventoGravita: "",
                comorbiditaPreEventoComorbidita: "",
                comorbiditaPreEventoIpertensione: null,
                comorbiditaPreEventoMuscoloScheletricoCute: null,
                comorbiditaPreEventoOcchiOrl: null,
                comorbiditaPreEventoRene: null,
                comorbiditaPreEventoSistemaNervosoCentrale: null,
                comorbiditaPreEventoStatoMentaleComportamento: null,
                alimentazionePortatoreSondinoNasogastricoUltimoCambioData: null,
                listaTrattamentiChirurgici: [{ tipo: "", data: null }],
                listaTrattamentiRiabilitativi: [{ tipo: "", dataInizio: null, dataFine: null, frequenza: "" }],
            },
        };
    },
    mounted() {
        let me = this;
        me.idAccettazione = me.$route.query.idPaziente;
        me.loadDataPersonaRiferimento();
        me.loadDataAccettazione();
        me.loadDataScalaGose();
        me.loadDataScalaLfc();
        me.loadDataScalaCrsr();
        me.loadDataScalaDrs();
        me.loadCategoriaDisabilita();
        me.loadDataScalaCirs();
        me.loadCheckScheda();
        me.loadScalaToniMuscolati();
        //me.loadData();
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    computed: {
        isEventoDannoPolmonare: {
            get() {
                let me = this;

                return me.getValore(me.jsonData.eventoDannoPolmonare);
            },
            set(value) {
                let me = this;
                me.setValore(value, "eventoDannoPolmonare");
            },
        },
        isEventoDannoAltro: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.eventoDannoAltro);
            },
            set(value) {
                let me = this;
                me.setValore(value, "eventoDannoAltro");
            },
        },
        isEventoDannoAddominale: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.eventoDannoAddominale);
            },
            set(value) {
                let me = this;
                me.setValore(value, "eventoDannoAddominale");
            },
        },
        isEventoDannoMidollare: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.eventoDannoMidollare);
            },
            set(value) {
                let me = this;
                me.setValore(value, "eventoDannoMidollare");
            },
        },
        isAmministratoreSostegno: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.legaleAmministratoreSostegno);
            },
            set(value) {
                let me = this;
                me.setValore(value, "legaleAmministratoreSostegno");
                if (me.jsonData.legaleAmministratoreSostegno === "NO") {
                    me.jsonData.legaleAmministratoreSostegnoDettaglio = "";
                }
            },
        },
        isTutore: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.legaleTutore);
            },
            set(value) {
                let me = this;
                me.setValore(value, "legaleTutore");
            },
        },
        isLegaleAltro: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.legaleAltro);
            },
            set(value) {
                let me = this;
                me.setValore(value, "legaleAltro");
            },
        },
        isLegaleNessuna: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.legaleNessuna);
            },
            set(value) {
                let me = this;
                me.setValore(value, "legaleNessuna");
                me.jsonData.legaleNessuna = value ? "SI" : "NO";
            },
        },
        isEventoEziologiaTraumaCranioEncefalico: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.eventoEziologiaTraumaCranioEncefalico);
            },
            set(value) {
                let me = this;
                me.setValore(value, "eventoEziologiaTraumaCranioEncefalico");
            },
        },
        isEventoEziologiaVascolare: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.eventoEziologiaVascolare);
            },
            set(value) {
                let me = this;
                me.setValore(value, "eventoEziologiaVascolare");
            },
        },
        isEventoEziologiaAnossia: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.eventoEziologiaAnossia);
            },
            set(value) {
                let me = this;
                me.setValore(value, "eventoEziologiaAnossia");
            },
        },
        isEventoEziologiaAltro: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.eventoEziologiaAltro);
            },
            set(value) {
                let me = this;
                me.setValore(value, "eventoEziologiaAltro");
            },
        },
        isProtesiAcustica: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.protesiAcustica);
            },
            set(value) {
                let me = this;
                me.setValore(value, "protesiAcustica");
            },
        },

        isProtesiArtoprotesi: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.protesiArtoprotesi);
            },
            set(value) {
                let me = this;
                me.setValore(value, "protesiArtoprotesi");
            },
        },
        isProtesiPacemaker: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.protesiPacemaker);
            },
            set(value) {
                let me = this;
                me.setValore(value, "protesiPacemaker");
            },
        },
        isProtesiAltro: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.protesiAltro);
            },
            set(value) {
                let me = this;
                me.setValore(value, "protesiAltro");
            },
        },
        isProtesiDentaria: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.protesiDentaria);
            },
            set(value) {
                let me = this;
                me.setValore(value, "protesiDentaria");
            },
        },
        isRespirazioneAutonoma: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.respirazioneAutonoma);
            },
            set(value) {
                let me = this;
                me.setValore(value, "respirazioneAutonoma");
            },
        },
        isRespirazioneVentiloterapia: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.respirazioneVentiloterapia);
            },
            set(value) {
                let me = this;
                me.setValore(value, "respirazioneVentiloterapia");
            },
        },

        isRespirazioneSupplementoOssigeno: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.respirazioneSupplementoOssigeno);
            },
            set(value) {
                let me = this;
                me.setValore(value, "respirazioneSupplementoOssigeno");
            },
        },
        isRespirazioneCannulaTracheostomica: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.respirazioneCannulaTracheostomica);
            },
            set(value) {
                let me = this;
                me.setValore(value, "respirazioneCannulaTracheostomica");
            },
        },
        isAlimentazioneNutrizioneOs: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.alimentazioneNutrizioneOs);
            },
            set(value) {
                let me = this;
                me.setValore(value, "alimentazioneNutrizioneOs");
            },
        },
        isAlimentazioneDisfagia: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.alimentazioneDisfagia);
            },
            set(value) {
                let me = this;
                me.setValore(value, "alimentazioneDisfagia");
            },
        },
        isAlimentazioneNutrizioneParentale: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.alimentazioneNutrizioneParentale);
            },
            set(value) {
                let me = this;
                me.setValore(value, "alimentazioneNutrizioneParentale");
            },
        },
        isAlimentazionePortatoreSondinoNasogastrico: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.alimentazionePortatoreSondinoNasogastrico);
            },
            set(value) {
                let me = this;
                me.setValore(value, "alimentazionePortatoreSondinoNasogastrico");
            },
        },
        isAlimentazionePortatorePeg: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.alimentazionePortatorePeg);
            },
            set(value) {
                let me = this;
                me.setValore(value, "alimentazionePortatorePeg");
            },
        },
        isFunzioneVescicaleMinzioneAutonoma: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.funzioneVescicaleMinzioneAutonoma);
            },
            set(value) {
                let me = this;
                me.setValore(value, "funzioneVescicaleMinzioneAutonoma");
            },
        },
        isFunzioneVescicaleIncontinente: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.funzioneVescicaleIncontinente);
            },
            set(value) {
                let me = this;
                me.setValore(value, "funzioneVescicaleIncontinente");
            },
        },
        isFunzioneVescicaleIncontinentePannolone: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.funzioneVescicaleIncontinentePannolone);
            },
            set(value) {
                let me = this;
                me.setValore(value, "funzioneVescicaleIncontinentePannolone");
            },
        },
        isFunzioneVescicaleIncontinenteUrocontrol: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.funzioneVescicaleIncontinenteUrocontrol);
            },
            set(value) {
                let me = this;
                me.setValore(value, "funzioneVescicaleIncontinenteUrocontrol");
            },
        },
        isFunzioneVescicaleCateterizzato: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.funzioneVescicaleCateterizzato);
            },
            set(value) {
                let me = this;
                me.setValore(value, "funzioneVescicaleCateterizzato");
            },
        },
        isApparatoTegumentarioPresenzaDecubitiTrocanterica: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.apparatoTegumentarioPresenzaDecubitiTrocantericaSede);
            },
            set(value) {
                let me = this;
                me.setValore(value, "apparatoTegumentarioPresenzaDecubitiTrocantericaSede");
            },
        },
        isApparatoTegumentarioPresenzaDecubitiTalare: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.apparatoTegumentarioPresenzaDecubitiTalareSede);
            },
            set(value) {
                let me = this;
                me.setValore(value, "apparatoTegumentarioPresenzaDecubitiTalareSede");
            },
        },
        isApparatoTegumentarioPresenzaDecubitiSacrale: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.apparatoTegumentarioPresenzaDecubitiSacraleSede);
            },
            set(value) {
                let me = this;
                me.setValore(value, "apparatoTegumentarioPresenzaDecubitiSacraleSede");
            },
        },
        isApparatoTegumentarioPresenzaDecubitiIschiatica: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.apparatoTegumentarioPresenzaDecubitiIschiaticaSede);
            },
            set(value) {
                let me = this;
                me.setValore(value, "apparatoTegumentarioPresenzaDecubitiIschiaticaSede");
            },
        },
        isApparatoTegumentarioPresenzaDecubitiAltro: {
            get() {
                let me = this;
                return me.getValore(me.jsonData.apparatoTegumentarioPresenzaDecubitiAltroSede);
            },
            set(value) {
                let me = this;
                me.setValore(value, "apparatoTegumentarioPresenzaDecubitiAltroSede");
            },
        },
    },
    methods: {
        loadData(id) {
            let me = this;
            if (me.esito) {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/accettazione/" + id;
                axios
                    .get(link)
                    .then((response) => {
                        me.jsonData = response.data.data;
                        me.jsonData.listaTrattamentiChirurgici.push({ data: null, tipo: "" });
                        me.jsonData.listaTrattamentiRiabilitativi.push({ dataInizio: null, dataFine: null, tipo: "", frequenza: "" });
                    })
                    .catch(() => {});
            }
        },
        loadCheckScheda() {
            let me = this;

            let link = process.env.VUE_APP_PATH_API + me.pathResourceCheck + "/" + me.idAccettazione;
            axios
                .get(link)
                .then((response) => {
                    me.esito = response.data.esito;
                    me.loadData(me.idAccettazione);
                })
                .catch(() => {});
        },
        loadDataAccettazione() {
            let me = this;
            me.accettazione = {};
            if (me.idAccettazione !== "-1") {
                let link = process.env.VUE_APP_PATH_API + me.pathResourceAccettazione + "/" + me.idAccettazione;
                axios
                    .get(link)
                    .then((response) => {
                        me.accettazioneAnagrafica = response.data.data.accettazioneAnagrafica;
                        me.nominativo = me.accettazioneAnagrafica.nome + " " + me.accettazioneAnagrafica.cognome;
                        me.jsonData.idAccettazione = me.idAccettazione;
                        // me.loadData(me.accettazioneAnagrafica.id);
                    })
                    .catch(() => {});
            }
        },
        loadDataPersonaRiferimento() {
            let me = this;
            me.personaRiferimento = {};
            let link = process.env.VUE_APP_PATH_API + me.pathResourcePersonaRiferimento;
            let params = { idAccettazione: me.idAccettazione };
            axios.get(link, { params: params }).then((response) => {
                if (response.data.data.list.length > 0) {
                    me.personaRiferimento = response.data.data.list[0];
                    me.personaRiferimento.nominativo = me.personaRiferimento.nome + " " + me.personaRiferimento.cognome;
                    me.jsonData.idPersonaRiferimento = me.personaRiferimento.id;
                }
            });
        },
        loadDataScalaGose() {
            let me = this;
            me.scalaGose = [];
            me.goseStatoClinicoOptions = [];
            let link = process.env.VUE_APP_PATH_API + me.pathResourceScalaGose;
            axios.get(link).then((response) => {
                me.scalaGose = response.data.data.list;
                me.scalaGose.forEach((element) => {
                    me.goseStatoClinicoOptions.push({ value: element.statoClinico, text: element.statoClinico });
                });
                me.goseStatoClinicoOptions.unshift({ text: "-Selezionare Valore-", value: null });
            });
        },
        loadDataScalaLfc() {
            let me = this;
            me.scalaLfc = [];
            me.lcfLivelloCognitivoOptions = [];
            let link = process.env.VUE_APP_PATH_API + me.pathResourceScalaLfc;
            axios.get(link).then((response) => {
                me.scalaLfc = response.data.data.list;
                me.scalaLfc.forEach((element) => {
                    me.lcfLivelloCognitivoOptions.push({ value: element.livelloCognitivo, text: element.livelloCognitivo });
                });
                me.lcfLivelloCognitivoOptions.unshift({ text: "-Selezionare Valore-", value: null });
            });
        },
        loadDataScalaCirs() {
            let me = this;
            //me.scalaLfc = [];
            me.comorbiditaPreEventoOptions = [];
            let link = process.env.VUE_APP_PATH_API + me.pathResourceScaleCisr;
            axios.get(link).then((response) => {
                //   me.scalaLfc = response.data.data.list;
                me.comorbiditaPreEventoOptions = response.data.data.list;
                /* .forEach((element) => {
          me.comorbiditaPreEventoOptions.push({ value: element.punteggio, text: element.descrizione });
        }); */
                me.comorbiditaPreEventoOptions.unshift({ descrizione: "-Selezionare Valore-", punteggio: null });
            });
        },
        loadDataScalaCrsr() {
            let me = this;
            me.scalaCrsr = [];
            me.crsrScalaFunzioneUditivaOptions = [];
            me.crsrScalaFunzioneVisivaOptions = [];
            me.crsrScalaFunzioneMotoriaOptions = [];
            me.crsrScalaFunzioneMotoriaOraleVerbaleOptions = [];
            me.crsrScalaComunicazioneOptions = [];
            me.crsrScalaVigilanzaOptions = [];

            let link = process.env.VUE_APP_PATH_API + me.pathResourceScaleCrsr;
            axios.get(link).then((response) => {
                me.scalaCrsr = response.data.data.list;
                me.scalaCrsr.forEach((element) => {
                    switch (element.tipologia) {
                        case "FUNZIONE UDITIVA":
                            me.crsrScalaFunzioneUditivaOptions.push({ text: element.descrizione, value: element.descrizione });
                            break;
                        case "FUNZIONE MOTORIA":
                            me.crsrScalaFunzioneMotoriaOptions.push({ text: element.descrizione, value: element.descrizione });
                            break;
                        case "FUNZIONE MOTORIA ORALE VERBALE":
                            me.crsrScalaFunzioneMotoriaOraleVerbaleOptions.push({ text: element.descrizione, value: element.descrizione });
                            break;
                        case "FUNZIONE VISIVA":
                            me.crsrScalaFunzioneVisivaOptions.push({ text: element.descrizione, value: element.descrizione });
                            break;
                        case "COMUNICAZIONE":
                            me.crsrScalaComunicazioneOptions.push({ text: element.descrizione, value: element.descrizione });
                            break;
                        case "VIGILANZA":
                            me.crsrScalaVigilanzaOptions.push({ text: element.descrizione, value: element.descrizione });
                            break;
                    }
                });
                me.crsrScalaFunzioneUditivaOptions.unshift({ text: "-Selezionare Valore-", value: null });
                me.crsrScalaVigilanzaOptions.unshift({ text: "-Selezionare Valore-", value: null });
                me.crsrScalaComunicazioneOptions.unshift({ text: "-Selezionare Valore-", value: null });
                me.crsrScalaFunzioneVisivaOptions.unshift({ text: "-Selezionare Valore-", value: null });
                me.crsrScalaFunzioneMotoriaOraleVerbaleOptions.unshift({ text: "-Selezionare Valore-", value: null });
                me.crsrScalaFunzioneMotoriaOptions.unshift({ text: "-Selezionare Valore-", value: null });
            });
        },
        loadDataScalaDrs() {
            let me = this;
            me.scalaDrs = [];
            me.drsAperturaOcchiOptions = [];
            me.drsCapacitaComunicazioneOptions = [];
            me.drsMigliorRispostaMotoriaOptions = [];
            me.drsNutrirsiOptions = [];
            me.drsSfinteriOptions = [];
            me.drsRassettarsiOptions = [];
            me.drsLivelloFunzionaleOptions = [];
            me.drsAdattabilitaPsicosocialeOptions = [];

            let link = process.env.VUE_APP_PATH_API + me.pathResourceScaleDrs;
            axios.get(link).then((response) => {
                me.scalaDrs = response.data.data.list;
                me.scalaDrs.forEach((element) => {
                    switch (element.tipologia) {
                        case "APERTURA OCCHI":
                            me.drsAperturaOcchiOptions.push({ text: element.descrizione, value: element.descrizione });
                            break;
                        case "COMUNICAZIONE":
                            me.drsCapacitaComunicazioneOptions.push({ text: element.descrizione, value: element.descrizione });
                            break;
                        case "RISPOSTA MOTORIA":
                            me.drsMigliorRispostaMotoriaOptions.push({ text: element.descrizione, value: element.descrizione });
                            break;
                        case "COGNITIVA":
                            me.drsNutrirsiOptions.push({ text: element.descrizione, value: element.descrizione });
                            me.drsRassettarsiOptions.push({ text: element.descrizione, value: element.descrizione });
                            me.drsSfinteriOptions.push({ text: element.descrizione, value: element.descrizione });
                            break;
                        case "LIVELLO FUNZIONALE":
                            me.drsLivelloFunzionaleOptions.push({ text: element.descrizione, value: element.descrizione });
                            break;
                        case "ADATTABILITA PSICOSOCIALE":
                            me.drsAdattabilitaPsicosocialeOptions.push({ text: element.descrizione, value: element.descrizione });
                            break;
                    }
                });
                me.drsAdattabilitaPsicosocialeOptions.unshift({ text: "-Selezionare Valore-", value: null });
                me.drsLivelloFunzionaleOptions.unshift({ text: "-Selezionare Valore-", value: null });
                me.drsSfinteriOptions.unshift({ text: "-Selezionare Valore-", value: null });
                me.drsRassettarsiOptions.unshift({ text: "-Selezionare Valore-", value: null });
                me.drsNutrirsiOptions.unshift({ text: "-Selezionare Valore-", value: null });
                me.drsMigliorRispostaMotoriaOptions.unshift({ text: "-Selezionare Valore-", value: null });
                me.drsCapacitaComunicazioneOptions.unshift({ text: "-Selezionare Valore-", value: null });
                me.drsAperturaOcchiOptions.unshift({ text: "-Selezionare Valore-", value: null });
            });
        },
        onChangeGoseStatoClinico() {
            let me = this;
            me.scalaGose.forEach((element) => {
                if (me.jsonData.goseStatoClinico === element.statoClinico) {
                    me.jsonData.goseStatoClinicoPunteggio = element.punteggio;
                }
            });
        },
        onChangeLcfLivelloCognitivo() {
            let me = this;
            me.scalaLfc.forEach((element) => {
                if (me.jsonData.lcfLivelloCognitivo === element.livelloCognitivo) {
                    me.jsonData.lcfLivelloCognitivoPunteggio = element.punteggio;
                }
            });
        },
        onChangeCrsrScala() {
            let me = this;
            me.scalaCrsr.forEach((element) => {
                if (me.jsonData.crsrScalaVigilanza === element.descrizione) {
                    me.jsonData.crsrScalaVigilanzaPunteggio = element.punteggio;
                }
                if (me.jsonData.crsrScalaComunicazione === element.descrizione) {
                    me.jsonData.crsrScalaComunicazionePunteggio = element.punteggio;
                }
                if (me.jsonData.crsrScalaFunzioneMotoria === element.descrizione) {
                    me.jsonData.crsrScalaFunzioneMotoriaPunteggio = element.punteggio;
                }
                if (me.jsonData.crsrScalaFunzioneMotoriaOraleVerbale === element.descrizione) {
                    me.jsonData.crsrScalaFunzioneMotoriaOraleVerbalePunteggio = element.punteggio;
                }
                if (me.jsonData.crsrScalaFunzioneUditiva === element.descrizione) {
                    me.jsonData.crsrScalaFunzioneUditivaPunteggio = element.punteggio;
                }
                if (me.jsonData.crsrScalaFunzioneVisiva === element.descrizione) {
                    me.jsonData.crsrScalaFunzioneVisivaPunteggio = element.punteggio;
                }
            });
            me.jsonData.crsrPunteggioTotale = Number(me.jsonData.crsrScalaVigilanzaPunteggio) + Number(me.jsonData.crsrScalaComunicazionePunteggio) + Number(me.jsonData.crsrScalaFunzioneMotoriaOraleVerbalePunteggio) + Number(me.jsonData.crsrScalaFunzioneMotoriaPunteggio) + Number(me.jsonData.crsrScalaFunzioneVisivaPunteggio) + Number(me.jsonData.crsrScalaFunzioneUditivaPunteggio);
            //me.jsonData.crsrPunteggioTotale = Number(me.jsonData.crsrPunteggioTotale) + Number(punteggio);
        },
        onChangeDrsScala() {
            let me = this;
            me.scalaDrs.forEach((element) => {
                if (me.jsonData.drsAdattabilitaPsicosociale === element.descrizione) {
                    me.jsonData.drsAdattabilitaPsicosocialePunteggio = element.punteggio;
                }
                if (me.jsonData.drsAperturaOcchi === element.descrizione) {
                    me.jsonData.drsAperturaOcchiPunteggio = element.punteggio;
                }
                if (me.jsonData.drsCapacitaComunicazione === element.descrizione) {
                    me.jsonData.drsCapacitaComunicazionePunteggio = element.punteggio;
                }
                /*  if (me.jsonData.drsCategoriaDisabilita === element.descrizione) {
          me.jsonData.drsCategoriaDisabilitaPunteggio = element.punteggio;
        } */
                if (me.jsonData.drsLivelloFunzionale === element.descrizione) {
                    me.jsonData.drsLivelloFunzionalePunteggio = element.punteggio;
                }
                if (me.jsonData.drsMigliorRispostaMotoria === element.descrizione) {
                    me.jsonData.drsMigliorRispostaMotoriaPunteggio = element.punteggio;
                }
                if (me.jsonData.drsNutrirsi === element.descrizione) {
                    me.jsonData.drsNutrirsiPunteggio = element.punteggio;
                }
                if (me.jsonData.drsRassettarsi === element.descrizione) {
                    me.jsonData.drsRassettarsiPunteggio = element.punteggio;
                }
                if (me.jsonData.drsSfinteri === element.descrizione) {
                    me.jsonData.drsSfinteriPunteggio = element.punteggio;
                }
            });
            let punteggio = 0;

            punteggio = Number(me.jsonData.drsAperturaOcchiPunteggio) + Number(me.jsonData.drsCapacitaComunicazionePunteggio) + Number(me.jsonData.drsMigliorRispostaMotoriaPunteggio) + Number(me.jsonData.drsNutrirsiPunteggio) + Number(me.jsonData.drsSfinteriPunteggio) + Number(me.jsonData.drsRassettarsiPunteggio) + Number(me.jsonData.drsLivelloFunzionalePunteggio) + Number(me.jsonData.drsAdattabilitaPsicosocialePunteggio);
            //if (punteggio > 0) {
            me.jsonData.drsPunteggioTotale = punteggio;
            this.selezionaCategoriaDisabilita();
            // }
        },
        selezionaCategoriaDisabilita() {
            let me = this;
            me.categorieDisabilita.forEach((element) => {
                if (me.jsonData.drsPunteggioTotale >= element.punteggioMinimo && me.jsonData.drsPunteggioTotale <= element.punteggioMassimo) {
                    me.jsonData.drsCategoriaDisabilita = element.categoriaDisabilita;
                    me.livelloDisabilita = element.livelloDisabilita;
                }
            });
        },
        loadCategoriaDisabilita() {
            let me = this;
            me.categorieDisabilita = [];
            let link = process.env.VUE_APP_PATH_API + me.pathResourceCategorieDisabilita;
            axios.get(link).then((response) => {
                me.categorieDisabilita = response.data.data.list;
                me.lcfLivelloCognitivoOptions.unshift({ text: "-Selezionare Valore-", value: null });
            });
        },
        loadScalaToniMuscolati() {
            let me = this;
            me.valutazioneTonoMuscolareOptions = [];
            let link = process.env.VUE_APP_PATH_API + me.pathResourceScalaToniMuscolari;
            axios.get(link).then((response) => {
                response.data.data.list.forEach((element) => {
                    me.valutazioneTonoMuscolareOptions.push({ text: element.descrizione, value: element.punteggio });
                });
                me.valutazioneTonoMuscolareOptions.unshift({ text: "-Selezionare Valore-", value: null });
            });
        },

        onAddTrattamentoChirurgico(array, value) {
            if (value.tipo !== "" && value.data !== null && value.data !== "") {
                array.push({
                    tipo: "",
                    data: "",
                });
            } else {
                this.$bvModal
                    .msgBoxOk("Campi OBBLIGATORI!", {
                        title: "ATTENZIONE",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    })
                    .then((value) => {
                        console.log(value);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        onDeleteTrattamentoChirurgico(array, row) {
            if (row.index != array.length) {
                this.$bvModal
                    .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
                        title: "Titolo",
                        buttonSize: "sm",
                        okVariant: "secondary",
                        cancelVariant: "danger",
                        okTitle: "SI",
                        cancelTitle: "NO",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true,
                    })
                    .then((value) => {
                        if (value) {
                            array.splice(row.index, 1);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        onAddTrattamentoRiabilitativi(array, value) {
            if (value.tipo !== "") {
                array.push({
                    tipo: "",
                    dataInizio: "",
                    dataFine: "",
                    frequenza: "",
                });
            } else {
                this.$bvModal
                    .msgBoxOk("Campi OBBLIGATORI!", {
                        title: "ATTENZIONE",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    })
                    .then((value) => {
                        console.log(value);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        onDeleteTrattamentoRiabilitativi(array, row) {
            if (row.index != array.length) {
                this.$bvModal
                    .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
                        title: "Titolo",
                        buttonSize: "sm",
                        okVariant: "secondary",
                        cancelVariant: "danger",
                        okTitle: "SI",
                        cancelTitle: "NO",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true,
                    })
                    .then((value) => {
                        if (value) {
                            array.splice(row.index, 1);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        onChangeComorbiditaPreEvento() {
            let me = this;
            me.listaPreEvento = [
                me.jsonData.comorbiditaPreEventoAltrePatologieGenitoUrinarie,
                me.jsonData.comorbiditaPreEventoApparatoDigerenteInferiore,
                me.jsonData.comorbiditaPreEventoApparatoDigerenteSuperiore,
                me.jsonData.comorbiditaPreEventoApparatoEndocrinoMetabolico,
                me.jsonData.comorbiditaPreEventoApparatoRespiratorio,
                me.jsonData.comorbiditaPreEventoApparatoVascolare,
                me.jsonData.comorbiditaPreEventoFegato,
                me.jsonData.comorbiditaPreEventoIpertensione,
                me.jsonData.comorbiditaPreEventoMuscoloScheletricoCute,
                me.jsonData.comorbiditaPreEventoOcchiOrl,
                me.jsonData.comorbiditaPreEventoPatologieCardiache,
                me.jsonData.comorbiditaPreEventoRene,
                me.jsonData.comorbiditaPreEventoSistemaNervosoCentrale,
                me.jsonData.comorbiditaPreEventoStatoMentaleComportamento,
            ];
            me.setLivelloGravitaComorbidita(me.listaPreEvento);
        },
        setLivelloGravitaComorbidita(listaPreEvento) {
            let me = this;
            me.jsonData.comorbiditaPreEventoGravita = 0;
            me.jsonData.comorbiditaPreEventoComorbidita = 0;
            listaPreEvento.forEach((element) => {
                if (element >= 3) {
                    me.jsonData.comorbiditaPreEventoGravita = Number(me.jsonData.comorbiditaPreEventoGravita) + Number(1);
                } else {
                    me.jsonData.comorbiditaPreEventoComorbidita = Number(me.jsonData.comorbiditaPreEventoComorbidita) + Number(1);
                }
            });
        },
        getValore(data) {
            return data === "SI";
        },
        setValore(value, data) {
            let me = this;
            me.jsonData[data] = value ? "SI" : "NO";
        },
    },
};
</script>
