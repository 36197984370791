<template>
    <sa-page-layout :btnNewVisible="true" :btnRefreshVisible="true" @refresh="onRefresh" :pathResource="pathResource" :linkedit="linkedit" :showModalLoading="showModalLoading">
        <template slot="toolbar">
            <b-button variant="primary btn-toolbar sa-margin-left float-sm-left" size="sm" v-b-modal.mdlSinonimi>
                <b-icon icon="pencil"></b-icon>
                Sinomi ICDX
            </b-button>
            <b-button v-if="importExport" variant="success btn-toolbar sa-margin-left float-sm-left" size="sm" v-b-modal.mdlCaricaCsv>
                <i class="bi bi-upload" style="margin-right: 5px"></i>
                Importa File csv
            </b-button>
            <b-button v-if="importExport" variant="btn-toolbar btn btn-outline-primary" size="sm" @click="onClickExportExcel">
                <i class="bi bi-download sa-icon-i"></i>
                Export Excel
            </b-button>
        </template>
        <template slot="toolbar">
            <!-- <div style="float: right">
        <print-component :reportName="reportName" :data="items"></print-component>
      </div> -->
        </template>
        <template slot="table-filter">
            <b-form @submit.prevent="onSubmit">
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-label-data">{{ getLabelTraduora("adi.lblFiscalCode") }}</label>
                        <b-form-input
                            v-model="filtro.codiceFiscale"
                            type="search"
                            id="codice"
                            maxlength="16"
                            :formatter="
                                (value) => {
                                    return new String(value).toUpperCase();
                                }
                            "
                        ></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-label-data">{{ getLabelTraduora("adi.lblSecondName") }}</label>
                        <b-form-input
                            v-model="filtro.cognome"
                            type="search"
                            id="nome"
                            :formatter="
                                (value) => {
                                    return new String(value).toUpperCase();
                                }
                            "
                        ></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-label-data">{{ getLabelTraduora("adi.lblFirstName") }}</label>
                        <b-form-input
                            v-model="filtro.nome"
                            type="search"
                            id="cognome"
                            :formatter="
                                (value) => {
                                    return new String(value).toUpperCase();
                                }
                            "
                        ></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-label-data">Data Dal</label>
                        <date-picker v-model="filtro.dataDal" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-label-data">Data Al</label>
                        <date-picker v-model="filtro.dataAl" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-label-data">Stato</label>
                        <b-form-select v-model="filtro.stato" :options="statiOptions" :value="''" value-field="value" text-field="text" type="search"></b-form-select>
                    </b-col>
                </b-row>
                <b-row style="margin-top: 5px">
                    <b-col class="sa-padding-right text-right">
                        <b-button type="submit" variant="info">{{ this.$i18n.t("global.lblFind") }}</b-button>
                        <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("global.lblResetFilter") }}</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </template>
        <template slot="table-header">
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" :filter="filter" stacked="xl" striped hover :items="items" :fields="fields" current-page="1" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                    <template v-slot:cell(codiceFiscale)="{ item }">
                        <router-link class="sa-edit-link" :to="'/rencamschedemorte/edit/' + item.id">
                            {{ item.codiceFiscale }}
                        </router-link>
                    </template>
                    <template v-slot:cell(cognome)="{ item }">
                        <span>{{ item.cognome }} {{ item.nome }}</span>
                    </template>
                    <template v-slot:cell(statoAttuale)="{ item }">
                        <!-- <a v-if="item.codiceIcdX === null || item.codiceIcdX === ''" class="text-danger">NON CODIFICATA</a>
                        <a v-else class="text-success">CODIFICATA</a> -->
                        <!-- <a :class="getClassStato(item)">{{ getStato(item) }}</a> -->
                        <span :class="getClassStato(item.statoAttuale)">{{ item.statoAttuale }}</span>
                    </template>
                    <template #cell(actions)="row">
                        <b-row>
                            <b-button v-if="btnDeleteEnable" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" size="sm" @click="onDeleteItem(row.item)"><b-icon icon="trash"></b-icon></b-button>
                        </b-row>
                    </template>
                </b-table>
            </div>
        </template>
        <template slot="table-footer">
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col cols="6">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
            <b-modal id="mdlCaricaCsv" ref="mdlCaricaCsv" no-close-on-backdrop centered @hidden="onHideCaricaFileCsv" @show="onShowCaricaFileCsv" ok-only>
                <b-row>
                    <b-col cols="12" lg="12" md="12" class="sa-text-left">
                        <b-form-file v-model="fileCsv.file" ref="file" accept=".csv" placeholder="Seleziona o trascina il file qui..."></b-form-file>
                    </b-col>
                    <b-col cols="12" lg="12" md="12" class="sa-text-left">
                        <b-form-checkbox v-model="fileCsv.primaRigaIntestazione" name="check-button" switch>La prima riga è l'intestazione</b-form-checkbox>
                    </b-col>
                </b-row>

                <template #modal-footer="{ ok }">
                    <b-button size="sm" variant="outline-secondary" @click="onCaricaFileCsv()">
                        <font-awesome-icon size="xs" icon="upload" tabindex="-1" />
                        Carica
                    </b-button>
                    <b-button size="sm" variant="info" @click="ok()">Chiudi</b-button>
                </template>
            </b-modal>
            <sinonimi-icdx-component></sinonimi-icdx-component>
        </template>
    </sa-page-layout>
</template>

<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import moment from "moment";
// import "vue2-datepicker/index.css";
// import PrintComponent from "../../../utility/components/PrintComponent.vue";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import SinonimiIcdxComponent from "../../sinonimi/components/SinonimiIcdxComponent.vue";
import UtilityMixin from "../../../utility/UtilityMixin";

export default {
    props: {
        utente: {
            type: Object,
            default: function () {
                let me = this;
                me.impostaPermessi();
                return null;
            },
        },
    },
    mixins: [UtilityMixin],
    // ,PrintComponent
    components: { SaPageLayout, SinonimiIcdxComponent, DatePicker },

    data() {
        return {
            pathResource: "/rencamschedemorte",
            pathResourceStati: "/rencamschedemortestatidettaglio",
            pathResourceDownload: "/rencamschedemorte/download",
            prefissoFile: "ASL - NAPOLI 1 Centro(A.S.L. NAPOLI 1 CENTRO) ",
            showModalLoading: false,
            btnDeleteEnable: false,
            perPage: 100,
            rows: null,
            fileCsv: {
                file: null,
                primaRigaIntestazione: true,
                data: new Date().getTime(),
                note: "",
            },
            importExport: false,
            currentPage: 1,
            linkedit: "rencamschedemorte",
            pathResourceSinonimi: "/sinonimiicdx",
            filter: null,
            sinonimi: [],
            id: "-1",
            filtro: { codiceFiscale: "", nome: "", cognome: "", stato: "", dataDal: null, dataAl: null },
            filtroSinonimi: { descrizione: "", codiceIcdx: "" },
            filterOn: [],
            fields: [
                {
                    label: "Codice Fiscale",
                    key: "codiceFiscale",
                    sortable: true,
                    thStyle: "width: 10rem",
                },
                {
                    label: "Cognome Nome ",
                    key: "cognome",
                    sortable: true,
                },
                {
                    label: "Stato",
                    key: "statoAttuale",
                    sortable: false,
                    thStyle: "width: 10rem",
                },
                { key: "actions", label: "", thStyle: "width: 3rem" },
            ],
            items: [],
            statiOptions: [],
        };
    },
    created: function () {},
    mounted: function () {
        let me = this;
        me.id = this.$route.params.id;
        me.prefissoFile = this.appConfig.prefissoFile;
        me.impostaPermessi();
        let sessionStorage = window.sessionStorage;
        if (sessionStorage["filtro"]) {
            me.filtro = JSON.parse(sessionStorage["filtro"]);
        }
        me.loadData();
        me.loadStati();
    },
    watch: {
        currentPage() {
            let me = this;
            me.loadData();
        },
        utente() {
            let me = this;
            me.impostaPermessi();
        },
    },
    methods: {
        impostaPermessi() {
            let me = this;
            me.importExport = UtilityMixin.methods.verificaPermessi(me.utente, "rencam", 2);
            me.btnDeleteEnable = UtilityMixin.methods.verificaPermessi(me.utente, "rencamschedemorte", 8);
        },
        onShowModalSinonimi() {
            console.log("ok");
            this.$refs["mdlSinonimi"].show();
        },
        // getStato(item) {
        //     if (item.codiceIcdX != null && item.codiceIcdX != "") {
        //         item.stato = "CODIFICATA";
        //     } else {
        //         item.stato = "NON CODIFICATA";
        //     }
        //     return item.stato;
        // },
        getClassStato(stato) {
            if (stato === "CODIFICATA") {
                return "text-success";
            } else if (stato === "NON CODIFICATA") {
                return "text-danger";
            } else {
                return "text-warning";
            }
        },
        loadData() {
            let me = this;
            me.filtro.page = me.currentPage;
            me.filtro.forPage = me.perPage;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.showModalLoading = true;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.sinonimi = [];
                    me.rows = response.data.data.recordsNumber;
                    me.items = response.data.data.list;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        loadStati() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceStati;
            me.showModalLoading = true;
            axios
                .get(link)
                .then((response) => {
                    response.data.data.forEach((element) => {
                        me.statiOptions.push({ value: element.descrizione, text: element.descrizione });
                    });
                    me.statiOptions.unshift({ value: "", text: "-Seleziona Valore-" });
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        onSubmit() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },

        onReset() {
            let me = this;
            me.filtro = { nome: "", cognome: "", codiceFiscale: "", stato: "", dataDal: null, dataAl: null };
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        onOk() {
            console.log("onOK");
            //   let me = this;
            //   let link = process.env.VUE_APP_PATH_API + me.pathResource + me.pathResourceAggiornaStato;
            //   let jsonData = JSON.stringify(me.data);
            //   axios.post(link, jsonData).then(() => {
            //     me.showModalLoading = false;
            //     me.loadData();
            //   });
        },

        onCaricaFileCsv() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/upload";
            let reader = new FileReader();
            reader.readAsDataURL(me.fileCsv.file);
            me.showModalLoading = false;
            reader.onload = () => {
                let invioFileCsv = {};
                invioFileCsv.base64 = reader.result;
                invioFileCsv.primaRigaIntestazione = me.fileCsv.primaRigaIntestazione;
                invioFileCsv.nomeCsv = me.fileCsv.file.name;
                let jsonData = JSON.stringify(invioFileCsv);
                axios
                    .post(link, jsonData)
                    .then(() => {
                        me.showModalLoading = false;
                        me.$refs.mdlCaricaCsv.hide();
                        me.loadData();
                    })
                    .catch(function (error) {
                        me.showModalLoading = false;
                        me.$bvToast.toast(error.response.data.messaggio, {
                            title: "Sorveglianza Covid Admin",
                            variant: "danger",
                            solid: true,
                        });
                    });
            };
        },
        onClickExportExcel() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceDownload;
            let fileName = me.prefissoFile + " - " + moment(new Date()).format("DD MM YYYY HH-mm") + ".csv";
            axios({
                url: link, // File URL Goes Here
                params: me.filtro,
                method: "GET",
                responseType: "blob",
            }).then((response) => {
                // console.log(response.data);
                var blob = new Blob([response.data]);
                var downloadElement = document.createElement("a");
                var href = window.URL.createObjectURL(blob); //create the download url
                downloadElement.href = href;
                downloadElement.download = fileName; //the name of the downloaded file
                document.body.appendChild(downloadElement);
                downloadElement.click(); //click to file
                document.body.removeChild(downloadElement); //remove the element
                window.URL.revokeObjectURL(href); //release the object  of the blob
            });
        },
        onDeleteItem(item) {
            let me = this;
            this.$bvModal
                .msgBoxConfirm("Sei sicuro di procedere alla cancellazione?", {
                    title: "Conferma Cancellazione",
                    buttonSize: "sm",
                    okVariant: "secondary",
                    cancelVariant: "danger",
                    okTitle: "SI",
                    cancelTitle: "NO",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                })
                .then((value) => {
                    if (value) {
                        me.deleteItem(item);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        deleteItem(item) {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + item.id;
            axios
                .delete(link)
                .then((Response) => {
                    console.log(Response);
                    me.showModalLoading = false;
                    me.loadData();
                })
                .catch((e) => {
                    console.log(e);
                });
        },

        onHideCaricaFileCsv() {},
        onShowCaricaFileCsv() {},
    },
};
</script>
