<template>
    <div class="sa-tab-scroll">
        <b-card class="sa-card" header="FREQUENZA RESPIRATORIA" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaFrequenzaRespiratoria" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header">
                <template #cell(check)="{ item }">
                    <b-form-checkbox v-model="item.check" :checked="item.check" switch></b-form-checkbox>
                </template>
            </b-table>
        </b-card>
        <b-card class="sa-card" header="FREQUENZA CARDIACA" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaFrequenzaCardiaca" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header">
                <template #cell(check)="{ item }">
                    <b-form-checkbox v-model="item.check" :checked="item.check" switch></b-form-checkbox>
                </template>
            </b-table>
        </b-card>
        <b-card class="sa-card" header="PRESSIONE ARTERIOSA SISTOLICA" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaPressioneArteriosaSistolica" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header">
                <template #cell(check)="{ item }">
                    <b-form-checkbox v-model="item.check" :checked="item.check" switch></b-form-checkbox>
                </template>
            </b-table>
        </b-card>
        <b-card class="sa-card" header="LIVELLO COSCIENZA" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaLivelloCoscienza" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header">
                <template #cell(check)="{ item }">
                    <b-form-checkbox v-model="item.check" :checked="item.check" switch></b-form-checkbox>
                </template>
            </b-table>
        </b-card>
        <b-card class="sa-card" header="TEMPERATURA CORPOREA" header-tag="header" footer-tag="footer" title="">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaTemperaturaCorporea" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header">
                <template #cell(check)="{ item }">
                    <b-form-checkbox v-model="item.check" :checked="item.check" switch></b-form-checkbox>
                </template>
            </b-table>
        </b-card>
        <b-card class="sa-card" header="Totale" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <span class="sa-label-data">{{ totale }}</span>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import axios from "axios";
export default {
    components: {},
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    watch: {
        listaFrequenzaRespiratoria: {
            handler() {
                let me = this;
                me.unisciArray();
            },
            deep: true,
        },
        listaFrequenzaCardiaca: {
            handler() {
                let me = this;
                me.unisciArray();
            },
            deep: true,
        },
        listaPressioneArteriosaSistolica: {
            handler() {
                let me = this;
                me.unisciArray();
            },
            deep: true,
        },
        listaLivelloCoscienza: {
            handler() {
                let me = this;
                me.unisciArray();
            },
            deep: true,
        },
        listaTemperaturaCorporea: {
            handler() {
                let me = this;
                me.unisciArray();
            },
            deep: true,
        },
    },
    data() {
        return {
            idPropostaAccesso: null,
            keyPropostaAccesso: 0,
            linkback: "",
            linkPrintData: "",
            childKey: 0,
            viewPai: true,
            pathResource: "/cotscalamewsrichieste",
            totale: 0,
            jsonData: [],
            listaFrequenzaRespiratoria: [],
            listaFrequenzaCardiaca: [],
            listaPressioneArteriosaSistolica: [],
            listaLivelloCoscienza: [],
            listaTemperaturaCorporea: [],
            fieldsListe: [
                {
                    label: "Descrizione",
                    key: "fattore",
                    sortable: true,
                },
                {
                    label: "",
                    key: "check",
                    sortable: true,
                    thStyle: "width: 10rem",
                    tdClass: "text-center sa-column-widh",
                },
                // {
                //   label: "",
                //   key: "action",
                //   thStyle: "width: 10rem",
                // },
            ],
        };
    },
    mounted() {
        let me = this;
        me.idPropostaAccesso = this.$route.params.id;
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.idPropostaAccesso;
            axios
                .get(link)
                .then((response) => {
                    me.jsonData = response.data.data.list;
                    me.calcolaPunteggio();
                    me.jsonData.forEach((element) => {
                        if (element.check) {
                            me.jsonData.push({ idRichiesta: me.idPropostaAccesso, idScalaMews: element.id });
                        }
                        // console.log(element.gruppo);
                        switch (element.gruppo) {
                            case "FREQUENZA RESPIRATORIA":
                                me.listaFrequenzaRespiratoria.push(element);
                                break;
                            case "FREQUENZA CARDIACA":
                                me.listaFrequenzaCardiaca.push(element);
                                break;
                            case "PRESSIONE ARTERIOSA SISTOLICA":
                                me.listaPressioneArteriosaSistolica.push(element);
                                break;
                            case "LIVELLO COSCIENZA":
                                me.listaLivelloCoscienza.push(element);
                                break;
                            case "TEMPERATURA CORPOREA":
                                me.listaTemperaturaCorporea.push(element);
                                break;
                        }
                    });
                })
                .catch(() => {});
        },
        unisciArray() {
            let me = this;
            me.jsonData = [];
            me.jsonData = me.listaFrequenzaRespiratoria.concat(me.listaFrequenzaCardiaca).concat(me.listaPressioneArteriosaSistolica).concat(me.listaLivelloCoscienza).concat(me.listaTemperaturaCorporea);
            let lista = [];
            me.jsonData.forEach((element) => {
                if (element.check) {
                    lista.push({ idRichiesta: me.idPropostaAccesso, idScalaMews: element.id });
                }
            });
            me.calcolaPunteggio();
            //   console.log(lista);
            this.$emit("update", lista);
        },
        calcolaPunteggio() {
            let me = this;
            me.totale = 0;
            me.jsonData.forEach((element) => {
                if (element.check) {
                    let p = element.punteggio >= 0 ? element.punteggio : element.punteggio * -1;
                    me.totale = me.totale + p;
                }
            });
        },

        setListe() {},
    },
};
</script>

<style></style>
