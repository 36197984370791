<template>
    <sa-page-layout :btnBackVisible="true" :btnEditVisible="true" :linkback="linkback" :linkedit="linkedit" :pathResource="pathResource" :data="jsonData" :showModalLoading="showModalLoading">
        <template slot="table-header">
            <label>Numeratori</label>
        </template>
        <template slot="table-body">
            <div style="max-height: 18rem; overflow: auto">
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem; margin-top: 1rem">
                    <b-col sm="6" md="6" lg="6">
                        <fieldset class="sa-fieldset-intestazione">
                            <label>Codice: </label>
                            <p>{{ jsonData.codice }}</p>
                            <hr />
                            <label>Anno: </label>
                            <p>{{ jsonData.anno }}</p>
                            <hr />
                            <label> Valore: </label>
                            <p>{{ jsonData.valore }}</p>
                            <hr />
                        </fieldset>
                    </b-col>
                    <b-col sm="6" md="6" lg="6">
                        <fieldset class="sa-fieldset-intestazione">
                            <label>Descrizione: </label>
                            <p>{{ jsonData.descrizione }}</p>
                            <hr />
                            <label>Codice Struttura: </label>
                            <p>{{ jsonData.codiceStruttura }}</p>
                            <hr />
                            <label> Note: </label>
                            <p>{{ jsonData.note }}</p>
                            <hr />
                        </fieldset>
                    </b-col>
                </b-row>
            </div>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
    components: { SaPageLayout },
    data() {
        return {
            pathResource: "/numeratori",
            linkback: "/fatturazionenumeratori",
            linkedit: "",
            id: "",
            showModalLoading: false,
            jsonData: {},
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.linkedit = "/fatturazionenumeratori/edit/" + me.id;
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            me.showModalLoading = true;
            axios
                .get(link + me.id)
                .then((response) => {
                    me.jsonData = response.data.data;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
    },
};
</script>
