<template>
    <div style="height: 100%; overflow: auto">
        <b-card class="sa-card" header="Dettagli" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Setting</label>
                    <span class="sa-data">{{ jsonDataSettings.setting }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Codice</label>
                    <span class="sa-data">{{ jsonDataSettings.codice }}</span>
                </b-col>

                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Note:</label>
                    <span class="sa-data">{{ jsonDataSettings.note }}</span>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            pathResource: "/rsasettings",
            linkback: "/rsasettings",
            id: "",
            showModalLoading: false,
            jsonDataSettings: {},
            fieldset: {
                setting: "",
                codice: "",
                note: "",
            },
        };
    },

    /* watch: {
        jsonDataSettings: {
            handler(jsonDataSettings){
                this.$emit("jsonDataSettings", jsonDataSettings);
            },
            deep : true,
        },
    }, */

    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },

    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            me.showModalLoading = true;
            axios
                .get(link + me.id)
                .then((response) => {
                    me.jsonDataSettings = response.data.data;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
    },
};
</script>
