<template>
    <div>
        <b-card class="sa-card" header="SINTOMI" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Sintomi</label>
                    <b-form-select v-model="jsonData.presenzaSintomi" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Nausea</label>
                    <b-form-select v-model="jsonData.nausea" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Vomito</label>
                    <b-form-select v-model="jsonData.vomito" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Diarrea</label>
                    <b-form-select v-model="jsonData.diarrea" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Numero Scariche</label>
                    <b-form-input v-model="jsonData.diarreaNumeroScariche"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Febbre</label>
                    <b-form-select v-model="jsonData.febbre" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Temperatura (°C)</label>
                    <b-form-input v-model="jsonData.febbreTemperatura"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Dolori Addominali</label>
                    <b-form-select v-model="jsonData.doloriAddominali" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
                    <label class="sa-label-data">Specificare altri sintomi</label>
                    <b-form-textarea v-model="jsonData.altriSintomi" rows="6" no-resize></b-form-textarea>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data e Ora Comparsa Sintomi</label>
                    <date-picker v-model="jsonData.dataOraComparsaSintomi" format="DD-MM-YYYY HH:mm" value-type="timestamp" type="datetime"></date-picker>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Luogo di Consumo Del/i Pasto/i Sospetto/i</label>
                    <b-form-input v-model="jsonData.luogoBanchetto"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Data di Consumo Del/i Pasto/i Sospetto/i</label>
                    <date-picker v-model="jsonData.dataBanchetto" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Consumo Pasti su Mezzi di Trasporto:</label>
                    <b-form-select v-model="jsonData.pastoConsumatoMezzoTrasporto" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Descrizione Pasti:</label>
                    <b-form-input v-model="jsonData.pastoConsumatoMezzoTrasportoDescrizione" :disabled="chekCondition(jsonData.pastoConsumatoMezzoTrasporto, 'SI')"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Esercizio Commerciale Acquisto Alimento Sospetto:</label>
                    <b-form-input v-model="jsonData.pastoConsumatoLuogoAcquisto"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Numero Partecipanti al Pasto Sospetto:</label>
                    <b-form-input v-model="jsonData.numeroPersonePartecipantiConsumoPasto"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Numero Componenti Nucleo Familiare/Comunita Consumo Pasti in Comune:</label>
                    <b-form-input v-model="jsonData.numeroPersoneConsumoPastiComuni"></b-form-input>
                </b-col>

                <!-- <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <label class="sa-label-data">Alimenti Consumati</label>
              <b-form-textarea v-model="jsonData.alimentiConsumati" rows="6" no-resize></b-form-textarea>
            </b-col> -->
            </b-row>
            <b-row style="margin-top: 5px">
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Altre Persone Hanno Partecipato ai Pasti:</label>
                    <b-form-select v-model="jsonData.invitati" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-table sticky-header stacked="xl" striped hover itemscope :items="jsonData.listaInvitati" :fields="fieldsInvitati" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="row">
                            {{ row.index + 1 }}
                        </template>
                        <!-- <template #cell(codiceFiscale)="item">
                  <b-form-input v-model="item.item.codiceFiscale"></b-form-input>
                </template> -->
                        <template #cell(cognome)="item">
                            <b-form-input v-model="item.item.cognome"></b-form-input>
                        </template>
                        <template #cell(nome)="item">
                            <b-form-input v-model="item.item.nome"></b-form-input>
                        </template>
                        <template #cell(domicilio)="item">
                            <b-form-input v-model="item.item.domicilio"></b-form-input>
                        </template>
                        <template #cell(telefono)="item">
                            <b-form-input v-model="item.item.telefono"></b-form-input>
                        </template>
                        <template #cell(pasto)="item">
                            <b-form-input v-model="item.item.pasto"></b-form-input>
                        </template>
                        <template #cell(actions)="row">
                            <b-button v-if="row.index == jsonData.listaInvitati.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddInvitati(jsonData.listaInvitati, row.item)">
                                <b-icon icon="plus"></b-icon>
                            </b-button>
                            <b-button v-if="row.index !== jsonData.listaInvitati.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(jsonData.listaInvitati, row)">
                                <b-icon icon="trash"></b-icon>
                            </b-button>
                        </template>
                    </b-table>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Luogo Consumo Pasto Fuori Casa:</label>
                    <b-form-input v-model="jsonData.nomeGestoreReferentePastiFuoriCasa"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Nome Persona che Prepara il Pasto in Casa:</label>
                    <b-form-input v-model="jsonData.nomePersonaPastiCasa"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Luogo Acquisto Alimenti 72 Ore Prima:</label>
                    <b-form-input v-model="jsonData.acquistoAlimentiSettantadueOrePrima"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Ipotesi Diagnostiche:</label>
                    <b-form-select v-model="jsonData.ipotesiDiagnostiche" :options="ipotesiDiagnosticheOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Pasto Sospetto:</label>
                    <b-form-input v-model="jsonData.pastoSospetto"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Tempo Incubazione:</label>
                    <b-form-input v-model="jsonData.tempoIncubazione"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Agente Infettante:</label>
                    <b-form-input v-model="jsonData.agenteInfettante"></b-form-input>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Nelle 72 ore precedenti l'insorgenza dei sintomi dove, con chi e quali alimenti ha cosumato" header-tag="header" footer-tag="footer" title="">
            <indagine-epidemiologica-dettaglio-alimenti-component :listaDettaglioAlimenti="jsonData.listaDettaglioAlimenti" :isEdit="true" @update="onUpdateJsonData"></indagine-epidemiologica-dettaglio-alimenti-component>
        </b-card>
        <b-card class="sa-card" header="Tasso Attacco Alimento Specifici" header-tag="header" footer-tag="footer" title="">
            <indagine-epidemiologica-tassi-attacco-alimentari-component :listaTassiAttaccoAlimentari="jsonData.listaTassiAttaccoAlimentari" :isEdit="true" @update="onUpdateJsonDataTassiAttaccoAlimenti"></indagine-epidemiologica-tassi-attacco-alimentari-component>
        </b-card>
        <b-card class="sa-card" header="DATI RICOVERO" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Ricovero Ospedaliero</label>
                    <b-form-select v-model="jsonData.ricoveroOspedaliero" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Nome Ospedale</label>
                    <b-form-input v-model="jsonData.nomeOspedale" :disabled="jsonData.ricoveroOspedaliero !== 'SI'"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Ora Ricovero</label>
                    <date-picker v-model="jsonData.dataOraRicovero" format="DD-MM-YYYY HH:mm" value-type="timestamp" type="datetime" :disabled="jsonData.ricoveroOspedaliero !== 'SI'"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Coprocoltura</label>
                    <b-form-select v-model="jsonData.coprocoltura" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputCoprocoltura"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Esito</label>
                    <b-form-input v-model="jsonData.coprocolturaEsito" :disabled="esitoCoprocolturaDisabled"></b-form-input>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Altre Considerazioni</label>
                    <b-form-textarea v-model="jsonData.altreConsiderazioni" rows="6" no-resize></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
        <!-- <b-card class="sa-card" header="INVITATI" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Invitati</label>
              <b-form-select v-model="jsonData.invitati" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
            </b-col>
          </b-row>
        </b-card> -->
        <b-card class="sa-card" header="Dati Notifica" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Sanitario Notificante</label>
                    <b-form-input v-model="jsonData.sanitarioNotificante"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Telefono Sanitario Notificante</label>
                    <b-form-input v-model="jsonData.sanitarioNotificanteContatto"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Notifica</label>
                    <date-picker v-model="jsonData.dataNotifica" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
<script>
import axios from "axios";
import UtilityMixin from "../../../utility/UtilityMixin";
import DatePicker from "vue2-datepicker";
import Vue from "vue";
import IndagineEpidemiologicaDettaglioAlimentiComponent from "./IndagineEpidemiologicaDettaglioAlimentiComponent.vue";
import IndagineEpidemiologicaTassiAttaccoAlimentariComponent from "./IndagineEpidemiologicaTassiAttaccoAlimentariComponent.vue";
export default {
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    components: { DatePicker, IndagineEpidemiologicaDettaglioAlimentiComponent, IndagineEpidemiologicaTassiAttaccoAlimentariComponent },
    mixins: [UtilityMixin],
    data() {
        return {
            pathResource: "/malattieinfettiveietossinfezionealimentare",
            pathResourceMalattiaInfettivaSegnalazione: "/malattieinfettivesegnalazioni",
            esitoCoprocolturaDisabled: true,
            linkback: null,
            currentPage: 1,
            perPage: 100,
            showModalLoading: false,
            jsonData: {
                presenzaSintomi: "",
                nausea: "",
                vomito: "",
                diarrea: "",
                diarreaNumeroScariche: "",
                febbre: "",
                febbreTemperatura: "",
                doloriAddominali: "",
                altriSintomi: "",
                dataOraComparsaSintomi: null,
                luogoBanchetto: "",
                dataBanchetto: null,
                alimentiConsumati: "",
                ricoveroOspedaliero: "",
                nomeOspedale: "",
                dataOraRicovero: null,
                coprocoltura: "",
                coprocolturaEsito: "",
                invitati: "",
                listaInvitati: [],
                sanitarioNotificante: "",
                sanitarioNotificanteContatto: "",
                dataNotifica: null,
                pastoConsumatoMezzoTrasporto: "",
                pastoConsumatoMezzoTrasportoDescrizione: "",
                pastoConsumatoLuogoAcquisto: "",
                numeroPersonePartecipantiConsumoPasto: "",
                numeroPersoneConsumoPastiComuni: "",
                nomeGestoreReferentePastiFuoriCasa: "",
                acquistoAlimentiSettantadueOrePrima: "",
                ipotesiDiagnostiche: "",
                pastoSospetto: "",
                tempoIncubazione: "",
                agenteInfettante: "",
                altreConsiderazioni: "",
                listaDettaglioAlimenti: [{ tipoPasto: "", orePrecedenti: "", dataOraPrecedenti: null, luogo: "", numeroCommensali: "", listaAlimenti: [] }],
                listaTassiAttaccoAlimentari: [{ alimento: "", personeConsumatoAlimentiMalati: 0, personeConsumatoAlimentiSani: 0, personeConsumatoAlimentiTotale: 0, personeConsumatoAlimentiPercentualeMalati: 0, personeNonConsumatoAlimentiMalati: 0, personeNonConsumatoAlimentiSani: 0, personeNonConsumatoAlimentiTotale: 0, personeNonConsumatoAlimentiPercentualeMalati: 0, differenzaPercentuali: 0 }],
            },
            fieldsInvitati: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                // {
                //   label: "Codice Fiscale",
                //   key: "codiceFiscale",
                //   thStyle: "width: 15rem",
                //   sortable: false,
                // },
                {
                    label: "Cognome",
                    key: "cognome",
                    thStyle: "width: 15rem",
                    sortable: false,
                },
                {
                    label: "Nome",
                    key: "nome",
                    thStyle: "width: 15rem",
                    sortable: false,
                },
                {
                    label: "Domicilio",
                    key: "domicilio",
                    sortable: false,
                },
                {
                    label: "Telefono",
                    key: "telefono",
                    thStyle: "width: 15rem",
                    sortable: false,
                },
                {
                    label: "Pasto",
                    key: "pasto",
                    thStyle: "width: 25rem",
                    sortable: false,
                },
                {
                    label: "",
                    key: "actions",
                    sortable: false,
                    thStyle: "width: 4rem",
                },
            ],
            siNoOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "SI", text: "SI" },
                { value: "NO", text: "NO" },
            ],
            ipotesiDiagnosticheOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "TOSSINFEZIONE", text: "TOSSINFEZIONE" },
                { value: "CASO SECONDARIO", text: "CASO SECONDARIO" },
            ],
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient() {
            return UtilityMixin.methods.getLinkPatient(this.utente, this.idPaziente);
        },
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.jsonData.idAnagrafica = me.idPaziente;
        me.jsonData.id = me.id;
        me.loadMalattiaInfettivaSegnalazione();
        me.loadData();
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
        "jsonData.invitati": function (value) {
            let me = this;
            if (value === "SI") {
                me.jsonData.listaInvitati.push({ pasto: "", cognome: "", nome: "", domicilio: "", telefono: "" });
            } else {
                me.jsonData.listaInvitati = [];
            }
        },
        "jsonData.ricoveroOspedaliero": function (value) {
            let me = this;
            if (value !== "SI") {
                me.jsonData.nomeOspedale = "";
                me.jsonData.dataOraRicovero = null;
            }
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;

            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            me.showModalLoading = true;
            axios
                .get(link + me.id)
                .then((response) => {
                    me.jsonData = response.data.data;
                    me.jsonData.listaDettaglioAlimenti.push({ tipoPasto: "", orePrecedenti: "", dataOraPrecedenti: null, luogo: "", numeroCommensali: "", listaAlimenti: [] });
                    me.jsonData.listaTassiAttaccoAlimentari.push({ alimento: "", personeConsumatoAlimentiMalati: 0, personeConsumatoAlimentiSani: 0, personeConsumatoAlimentiTotale: 0, personeConsumatoAlimentiPercentualeMalati: 0, personeNonConsumatoAlimentiMalati: 0, personeNonConsumatoAlimentiSani: 0, personeNonConsumatoAlimentiTotale: 0, personeNonConsumatoAlimentiPercentualeMalati: 0, differenzaPercentuali: 0 }), (me.showModalLoading = false);
                })
                .catch(() => {
                    me.setDatiMedico();
                    me.showModalLoading = false;
                });
        },
        loadMalattiaInfettivaSegnalazione() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceMalattiaInfettivaSegnalazione + "/";
            axios
                .get(link + me.id)
                .then((response) => {
                    me.setDatiMalattia(response.data.data);
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        onInputCoprocoltura(value) {
            let me = this;
            if (value === "SI") {
                me.esitoCoprocolturaDisabled = false;
            } else {
                me.esitoCoprocolturaDisabled = true;
                me.jsonData.coprocolturaEsito = "";
            }
        },
        onAddInvitati(array, value) {
            //   console.log(value);
            if (value.cognome !== "" && value.nome !== "" && value.domicilio !== "" && value.telefono !== "") {
                array.push({ pasto: "", cognome: "", nome: "", domicilio: "", telefono: "" });
            } else {
                this.$bvModal
                    .msgBoxOk("Tutti i campi sono OBBLIGATORI", {
                        title: "ATTENZIONE",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    })
                    .then((value) => {
                        console.log(value);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        onDelete(array, row) {
            if (row.index != array.length) {
                this.$bvModal
                    .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
                        title: "Titolo",
                        buttonSize: "sm",
                        okVariant: "secondary",
                        cancelVariant: "danger",
                        okTitle: "SI",
                        cancelTitle: "NO",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true,
                    })
                    .then((value) => {
                        if (value) {
                            array.splice(row.index, 1);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        setDatiMedico() {
            let me = this;
            me.jsonData.sanitarioNotificante = Vue.prototype.user.firstname + " " + Vue.prototype.user.lastname;
        },
        setDatiMalattia(data) {
            let me = this;
            me.jsonData.id = data.id;
        },
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData.listaDettaglioAlimenti = data;
        },
        onUpdateJsonDataTassiAttaccoAlimenti(data) {
            let me = this;
            me.jsonData.listaTassiAttaccoAlimentari = data;
        },
    },
};
</script>
