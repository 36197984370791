<template>
    <div class="sa-calendar-week">
        <div :class="getAlternateColor(i)" v-for="(giorni, i) in disponibilita" :key="i">
            <p style="font-weight: bold">{{ getDay(giorni.giorno) }}</p>
            <div class="sa-calendar-week-slot" v-for="(disponibilita, x) in giorni.disponibilita" :key="getIndexDisponibilita(disponibilita, x)">
                <div class="sa-calendar-week-slot-hour">
                    <span class="sa-calendar-week-slot-hour-start">{{ disponibilita.dalleOre }}</span>
                    <span class="sa-calendar-week-slot-hour-end">{{ disponibilita.alleOre }}</span>
                </div>
                <div class="sa-calendar-week-slot-text">
                    <agenda-slot-component :idAgenda="idAgenda" :costo="costo" :descrizionePrestazione="descrizionePrestazione" :codicePrestazione="codicePrestazione" :index="getIndexDisponibilita(disponibilita, x)" :slotDisponibilita="disponibilita" @update="updateData"></agenda-slot-component>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import AgendaSlotComponent from "./AgendaSlotComponent.vue";

export default {
    components: { AgendaSlotComponent },
    data() {
        return {
            idAgenda: null,
            costo: null,
            dataRiferimento: null,
            descrizionePrestazione: null,
            codicePrestazione: null,
            pathResourceDisponibilitaAgenda: "/agendadisponibilita",
            periodo: "W",
            disponibilita: [],
        };
    },
    methods: {
        getAlternateColor(value) {
            if (value % 2 === 0) {
                return "sa-calendar-week-day alternate";
            } else {
                return "sa-calendar-week-day";
            }
        },
        getDay(value) {
            return moment(value).format("dddd DD ");
        },
        getIndex(index) {
            return "week_" + index;
        },
        getIndexDisponibilita(disponibilita, index) {
            return "week_" + disponibilita.dataRiferimento + "_" + index;
        },
        updateData() {
            let me = this;
            me.$emit("update");
        },
        loadDisponibilita(jsonAgenda, riferimento, jsonPrestazione) {
            let me = this;
            me.jsonAgenda = jsonAgenda;
            me.jsonPrestazione = jsonPrestazione;
            if (jsonPrestazione.codicePrestazione) {
                let link = process.env.VUE_APP_PATH_API + me.pathResourceDisponibilitaAgenda + "/";
                axios
                    .get(link, {
                        params: {
                            idAgenda: jsonAgenda.id,
                            periodo: me.periodo,
                            riferimento: riferimento,
                            codicePrestazione: jsonPrestazione.codicePrestazione,
                        },
                    })
                    .then((response) => {
                        me.disponibilita = response.data.data == null ? [] : response.data.data;
                        let value = 0;
                        me.disponibilita.forEach((elemento) => {
                            value += elemento.disponibilita.length;
                        });
                        me.numeroDisponibilita = value;
                    })
                    .catch((error) => {
                        me.$bvToast.toast(error.response.data.messaggio, {
                            title: "Error",
                            variant: "danger",
                            solid: true,
                        });
                    });
            } else {
                return;
            }
        },
    },
};
</script>
<style scoped>
.sa-calendar-week {
    border-top: 1px solid #d0d0d0;
    display: flex;
    outline: none;
}
.sa-calendar-week-day {
    /*border: 2px solid red;*/
    height: 100%;
    flex: auto;
}
.sa-calendar-week-slot {
    height: 3rem;
}
.sa-calendar-week-slot-hour {
    width: 5rem;
    float: inline-start;
    height: 100%;
    border-bottom: 1px solid #d0d0d0;
}
.sa-calendar-week-slot-hour-start {
    display: inline-block;
    font-size: 0.8rem;
}
.sa-calendar-week-slot-hour-end {
    display: inline-block;
    font-size: 0.7rem;
    width: 100%;
    text-align: right;
    padding-right: 0.2rem;
}
.sa-calendar-week-slot-text {
    border-left: 1px solid #d0d0d0;
    border-bottom: 1px solid #d0d0d0;
    border-right: 1px solid #d0d0d0;
    margin-left: 5rem;
    padding-left: 0.2rem;
    height: 100%;
}

.sa-calendar-week-slot-text div {
    height: 100%;
    display: inline-flex;
    vertical-align: middle;
    align-items: center;
}
.sa-calendar-week-day.alternate {
    background-color: #f0f0f0;
}
</style>
