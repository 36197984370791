<template>
  <sa-page-layout
    :btnBackVisible="true"
    :linkback="linkback"
    :showModalLoading="showModalLoading"
    :pathResource="pathResource"
    class="sa-no-space"
  >
    <template slot="table-body">
      <EmailViewComponent
        :showModalLoading="showModalLoading"
        @beforeLoadData="onBeforeLoadData"
        @afterLoadData="onAfterLoadData"
      ></EmailViewComponent>
    </template>
  </sa-page-layout>
</template>
<script>
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import EmailViewComponent from "../components/EmailViewComponent.vue";
export default {
  components: { SaPageLayout, EmailViewComponent },
  // props: {
  //   utente: {
  //     type: Object,
  //     default: function () {
  //       return null;
  //     },
  //   },
  // },
  data() {
    return {
      linkback: "/email",
      showModalLoading: false,
      pathResource: "/sendmail",
    };
  },
  methods: {
    onBeforeLoadData() {
      let me = this;
      me.showModalLoading = true;
    },
    onAfterLoadData() {
      let me = this;
      me.showModalLoading = false;
    },
  },
};
</script>
<style></style>
