<template>
  <div class="sa-tab-scroll">
    <b-card
      class="sa-card"
      header="Terapia"
      header-tag="header"
      footer-tag="footer"
      title=""
    ></b-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      jsonData: {},
    };
  },
  methods: {},
};
</script>
