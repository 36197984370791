<template>
    <sa-page-layout :btnNewVisible="false" :btnRefreshVisible="false" :showModalLoading="showModalLoading" :toolbarVisible="true" toggleableDimension="sm" :contentBodyHeader="false">
        <template slot="toolbar">
            <b-button v-if="btnPresaInCarico" size="sm" variant="btn btn-outline-success waves-effect float-sm-left" @click="onCambiaStato(propostaAcesso.id, '45047c7b-3627-4ac7-b59f-96cf81222c58')">Presa in carico</b-button>
            <b-button v-if="btnRespinta" size="sm" variant="btn btn-outline-danger waves-effect float-sm-left" @click="onCambiaStato(propostaAcesso.id, '8aaa887e-5e99-4c1f-8e82-42bbb695e962')">Respingi</b-button>
            <b-button v-if="btnInviataCot" size="sm" variant="btn btn-outline-success waves-effect float-sm-left" @click="onCambiaStato(propostaAcesso.id, '12382e56-3cf4-4362-8a0d-8032dc10d2c7')">Invia a C.O.T.</b-button>
            <b-button v-if="btnInviataPua" size="sm" variant="btn btn-outline-success waves-effect float-sm-left" @click="onCambiaStato(propostaAcesso.id, '13e55a5e-7ac8-4765-9736-47b585f3e57d')">Invia a P.U.A.</b-button>
            <b-button v-if="btnInviataAdi" size="sm" variant="btn btn-outline-success waves-effect float-sm-left" @click="onCambiaStato(propostaAcesso.id, 'ba128c87-4ee8-443a-a7bb-dab5cb27e197')">Invia a A.D.I.</b-button>
            <b-button v-if="btnInviataListaAttesa" size="sm" variant="btn btn-outline-success waves-effect float-sm-left" @click="onCambiaStato(propostaAcesso.id, '77e4ab0f-c359-4fda-afe6-267dfb12bd47')">Invia a Lista d'attesa</b-button>
            <b-button v-if="btnPresaInCaricoRsa" size="sm" variant="btn btn-outline-success waves-effect float-sm-left" @click="onCambiaStato(propostaAcesso.id, 'ceb967db-883a-41d3-9f6a-241cc1e90021')">Presa in carico R.S.A.</b-button>
            <b-button v-if="btnInviataUvm" size="sm" variant="btn btn-outline-success waves-effect float-sm-left" @click="onCambiaStato(propostaAcesso.id, '2233cffc-49ad-489d-9ab3-640bb1b97bd0')">Invia a U.V.M.</b-button>
        </template>
        <template slot="table-body">
            <div style="height: 100%; display: flex; flex-direction: column; background-color: var(--bg-main-container)">
                <div style="display: flex; flex: 1; height: 1px; margin-bottom: 0px; overflow: auto; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px">
                    <b-card class="sa-card" style="overflow: hidden" :header="headerCard" header-tag="header" footer-tag="footer" title="">
                        <!-- <b-progress :value="value" :max="max" show-progress style="margin-left: 10px; margin-right: 10px"></b-progress> -->
                        <b-progress :max="max" show-progress style="margin-left: 10px; margin-right: 10px">
                            <b-progress-bar :value="value" :label="`${((value / max) * 100).toFixed(0)} %`"></b-progress-bar>
                        </b-progress>
                        <br />
                        <b-table sticky-header ref="table" striped hover :items="items" :fields="fields" :current-page="1" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header">
                            <template #cell(actions)="row">
                                <b-button v-if="row.item.descrizione === 'Proposta d’Accesso'" size="sm" :variant="propostaStyle" @click="test()">
                                    <i class="bi bi-check2"></i>
                                </b-button>
                                <b-button v-if="row.item.descrizione === 'Schede di Valutazione'" size="sm" :variant="schedeStyle">
                                    <i class="bi bi-check2"></i>
                                </b-button>
                                <b-button v-if="row.item.descrizione === 'Modulistica'" size="sm" :variant="modulisticaStyle">
                                    <i class="bi bi-check2"></i>
                                </b-button>
                                <b-button v-if="row.item.descrizione === 'Allegati'" size="sm" :variant="allegatiStyle">
                                    <i class="bi bi-check2"></i>
                                </b-button>
                                <b-button v-if="row.item.descrizione === 'Valutazione U.V.M.'" size="sm" :variant="valutazioneUvmStyle">
                                    <i class="bi bi-check2"></i>
                                </b-button>
                                <b-button v-if="row.item.descrizione === 'P.A.I.'" size="sm" :variant="paiStyle">
                                    <i class="bi bi-check2"></i>
                                </b-button>
                                <b-button size="sm" v-if="row.item.descrizione === 'Proposta d’Accesso' || (row.item.descrizione !== 'Proposta d’Accesso' && propostaAcesso !== null && row.item.descrizione !== 'Dossier Sanitario' && row.item.descrizione !== 'Valutazione U.V.M.')" variant="btn btn-outline-secondary waves-effect waves-light width-sm no-text" @click="onCompilaClick(row.item)" :disabled="disableSchede">
                                    <i class="bi bi-pencil"></i>
                                </b-button>
                                <b-button size="sm" v-if="row.item.descrizione === 'Valutazione U.V.M.'" variant="btn btn-outline-secondary waves-effect waves-light width-sm no-text" @click="onCompilaClick(row.item)" :disabled="disableValutazioneUvm">
                                    <i class="bi bi-pencil"></i>
                                </b-button>
                                <!-- <b-button size="sm" v-else variant="btn btn-outline-secondary waves-effect waves-light width-sm no-text" disabled>
                            <i class="bi bi-pencil"></i>
                        </b-button> -->
                                <b-button size="sm" v-if="row.item.descrizione === 'Proposta d’Accesso'" variant="btn btn-outline-info waves-effect waves-light width-sm no-text" @click="onVisualizzaPdf()" :disabled="propostaAcesso === null">
                                    <i class="bi bi-filetype-pdf"></i>
                                </b-button>
                                <b-button v-if="row.item.descrizione === 'Proposta d’Accesso'" size="sm" variant="btn btn-outline-secondary waves-effect waves-light width-sm no-text" disabled @click="onSignedDocument()">
                                    <b-icon icon="pencil-square"></b-icon>
                                </b-button>
                                <b-button v-if="row.item.descrizione === 'Dossier Sanitario'" size="sm" variant="btn btn-outline-secondary waves-effect waves-light width-sm no-text" @click="onVisualizzaDossierSanitarioClick(row.item)" :disabled="disableValutazioneUvm">
                                    <i class="bi bi-journal-bookmark-fill"></i>
                                </b-button>
                            </template>
                        </b-table>
                    </b-card>
                </div>
                <div style="display: flex; flex: 0.4; margin-bottom: 0px; overflow: auto; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px">
                    <b-card class="sa-card" style="overflow: hidden" header="" header-tag="header" footer-tag="footer" title="">
                        <b-alert :show="alertShow" :variant="alertMessage.stato.toLowerCase()">{{ alertMessage.statoMessaggio }}</b-alert>
                        <b-tabs id="tabsSchede" nav-wrapper-class="sa-tab-items" content-class="mt-3" ref="tabs" class="sa-tab" style="height: 100%; padding-bottom: 25px" fill>
                            <b-tab lazy title="Stato Attuale" active>
                                <b-row v-if="propostaAcesso !== null">
                                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                        <span style="font-weight: bold">Data Creazione:</span>
                                        {{ formatDate(propostaAcesso.createDate) }}
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                        <span style="font-weight: bold">Codice-Struttura:</span>
                                        {{ propostaAcesso.provenienzaCodiceStruttura }}
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                        <span style="font-weight: bold">Data Segnalazione:</span>
                                        {{ formatDate(propostaAcesso.createDate) }}
                                        <!-- {{ formatDate(propostaAcesso.data) }} -->
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                        <span style="font-weight: bold">Struttura Segnalante:</span>
                                        {{ propostaAcesso.provenienzaDescrizioneStruttura }}
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                        <span style="font-weight: bold">C.O.T. Destinazione:</span>
                                        {{ propostaAcesso.cotDestinazione }}
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                        <span style="font-weight: bold">Codice-Reparto:</span>
                                        {{ propostaAcesso.provenienzaCodiceReparto }}
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                        <span style="font-weight: bold">Stato:</span>
                                        {{ propostaAcesso.statoAttuale }}
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                        <span style="font-weight: bold">Reparto Segnalante:</span>
                                        {{ propostaAcesso.provenienzaDescrizioneReparto }}
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                        <span style="font-weight: bold">Data Ora Stato:</span>
                                        {{ formatDateTime(propostaAcesso.statoAttualeDataOra) }}
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                        <span style="font-weight: bold">Medico Segnalante:</span>
                                        {{ propostaAcesso.provenienzaNominativoMedico }} {{ propostaAcesso.provenienzaCognomeMedico }}
                                    </b-col>
                                </b-row>
                            </b-tab>
                            <b-tab lazy title="Storico Stati">
                                <div class="sa-tab-scroll" v-if="propostaAcesso !== null">
                                    <!-- <div class="sa-list-component-body">
                                        <div class="b-table-sticky-header"> -->
                                    <b-table sticky-header ref="table" stacked="xl" striped hover :items="propostaAcesso.listaStati" :fields="fieldsStati" sort-icon-left head-variant="light" class="sa-b-table">
                                        <template #cell(index)="row">
                                            {{ row.index + 1 }}
                                        </template>
                                        <template v-slot:cell(statoDataOra)="{ item }">
                                            <span>{{ formatDateTime(item.statoDataOra) }}</span>
                                        </template>
                                    </b-table>
                                    <!-- </div>
                                    </div> -->
                                </div>
                            </b-tab>
                        </b-tabs>
                    </b-card>
                </div>
            </div>

            <!-- <b-modal ref="mdlStampaReferto" size="xl" style="height: 100%">
        <b-embed type="iframe" :src="stampaReport" allowfullscreen></b-embed>
      </b-modal> -->
            <pdf-print-view-embedded ref="pdfPrintViewEmbedded" :pdf="signedPdfViewer"></pdf-print-view-embedded>
            <firma-elettronica-component ref="firmaElettronicaComponent" :pdf="pdfFromSigned"></firma-elettronica-component>
        </template>
    </sa-page-layout>
</template>

<script>
import axios from "axios";
import moment from "moment";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import UtilityMixin from "../../utility/UtilityMixin";
import PdfPrintViewEmbedded from "../../../app/utility/components/PdfPrintViewEmbedded.vue";
import FirmaElettronicaComponent from "../../utility/components/FirmaElettronicaComponent.vue";
export default {
    components: { SaPageLayout, PdfPrintViewEmbedded, FirmaElettronicaComponent },
    mixins: [UtilityMixin],
    data() {
        return {
            alertShow: false,
            pathResource: "/cot",
            linkedit: "/cot/richieste/edit/propostaaccesso/",
            linkSchedeValutazioni: "/cot/richieste/edit/schedevalutazioni/",
            linkAllegati: "/cot/richieste/edit/allegati/",
            linkModulistica: "/cot/richieste/edit/modulistica/",
            linkModulisticaValutazioneUvm: "/cot/richieste/edit/valutazioneuvm/",
            pathResourceStampa: "/print/createreport",
            pathVerificaAlertResource: "/cot/verificaalert",
            pathCambiaStato: "/cot/aggiornastato",
            btnRespinta: true,
            btnInviataCot: true,
            signedPdfViewer: null,
            pdfFromSigned: null,
            btnInviataPua: true,
            btnInviataAdi: true,
            btnPresaInCaricoRsa: true,
            btnInviataUvm: true,
            disableSchede: true,
            disableValutazioneUvm: true,
            btnInviataListaAttesa: true,
            headerCard: "",
            idPropostaAccesso: null,
            showModalLoading: false,
            codiceStrutturaAnagraficaRegionale: "",
            value: 0,
            max: 100,
            propostaStyle: "btn btn-outline-danger waves-effect waves-light width-sm no-text",
            schedeStyle: "btn btn-outline-success waves-effect waves-light width-sm no-text",
            modulisticaStyle: "btn btn-outline-warning waves-effect waves-light width-sm no-text",
            allegatiStyle: "btn btn-outline-warning waves-effect waves-light width-sm no-text",
            valutazioneUvmStyle: "btn btn-outline-warning waves-effect waves-light width-sm no-text",
            paiStyle: "btn btn-outline-warning waves-effect waves-light width-sm no-text",
            linkVerificaProposta: "/cot/propostaaperta",
            pathResourceSchedeValutazione: "/cotschedevalutazione",
            pathResourceValutazioneUvm: "/cotvalutazioneuvm",
            schedeValutazioneCompilate: null,
            schedeValutazioneUvmCompilate: null,
            jsonData: { identificativo: null, provenienzaCodiceStruttura: null, provenienzaDescrizioneStruttura: null, provenienzaCodiceReparto: null, provenienzaDescrizioneReparto: null, provenienzaEventoClinico: null, provenienzaNominativoMedico: null },
            propostaAcesso: null,
            alertMessage: { stato: "", alertMessage: "" },
            items: [{ descrizione: "Proposta d’Accesso" }, { descrizione: "Schede di Valutazione" }, { descrizione: "Modulistica" }, { descrizione: "Valutazione U.V.M." }, /* { descrizione: "P.A.I." },*/ { descrizione: "Allegati" }, { descrizione: "Dossier Sanitario" }],
            fields: [
                {
                    label: "Codice Fiscale",
                    key: "descrizione",
                    sortable: true,
                },
                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 2rem",
                    tdClass: "text-center sa-column-widh",
                },
            ],
            fieldsStati: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Stato",
                    key: "stato",
                    sortable: true,
                    thStyle: "width: 20rem",
                },
                {
                    label: "Data Ora",
                    key: "statoDataOra",
                    sortable: true,
                    thStyle: "width: 20rem",
                },
            ],
        };
    },
    created() {
        let me = this;
        // me.btnPresaInCarico = UtilityMixin.methods.verificaPermessiByIdSezionePermesso("cot", 1024);
        me.btnRespinta = UtilityMixin.methods.verificaPermessiByIdSezionePermesso("cot", 2048);
        me.btnInviataCot = UtilityMixin.methods.verificaPermessiByIdSezionePermesso("cot", 4096) && me.propostaAcesso !== null;
        me.btnInviataPua = UtilityMixin.methods.verificaPermessiByIdSezionePermesso("cot", 8192);
        me.btnInviataAdi = UtilityMixin.methods.verificaPermessiByIdSezionePermesso("cot", 16384);
        me.btnInviataListaAttesa = UtilityMixin.methods.verificaPermessiByIdSezionePermesso("cot", 32768);
        me.btnPresaInCaricoRsa = (UtilityMixin.methods.verificaPermessiByIdSezionePermesso("cot", 65536) && !UtilityMixin.methods.verificaPermessiByIdSezionePermesso("cot", 1)) || (UtilityMixin.methods.verificaPermessiByIdSezionePermesso("cot", 65536) && UtilityMixin.methods.verificaPermessiByIdSezionePermesso("cot", 1));
        me.disableSchede = UtilityMixin.methods.verificaPermessiByIdSezionePermesso("cot", 65536) && !UtilityMixin.methods.verificaPermessiByIdSezionePermesso("cot", 1);
        me.disableValutazioneUvm = !UtilityMixin.methods.verificaPermessiByIdSezionePermesso("cot", 65536) && !UtilityMixin.methods.verificaPermessiByIdSezionePermesso("cot", 1) && !UtilityMixin.methods.verificaPermessiByIdSezionePermesso("cotvalutazioneuvm", 1);
        me.btnInviataUvm = UtilityMixin.methods.verificaPermessiByIdSezionePermesso("cot", 262144);
    },
    mounted() {
        // console.log("mounted");
        let me = this;
        me.codiceStrutturaAnagraficaRegionale = this.appConfig.codiceStrutturaAnagraficaRegionale;
        me.idPropostaAccesso = this.$route.params.id;
        me.jsonData.identificativo = this.$route.query.codiceFiscale ? this.$route.query.codiceFiscale : "";
        me.jsonData.provenienzaCodiceStruttura = this.$route.query.codiceStruttura ? this.$route.query.codiceStruttura : "";
        me.jsonData.provenienzaDescrizioneStruttura = this.$route.query.descrizioneStruttura;
        me.jsonData.provenienzaCodiceReparto = this.$route.query.codiceReparto;
        me.jsonData.provenienzaDescrizioneReparto = this.$route.query.descrizioneReparto;
        me.jsonData.provenienzaEventoClinico = this.$route.query.eventoClinico;

        me.jsonData.provenienzaNominativoMedico = this.$route.query.nominativoMedico;
        me.jsonData.provenienzaCognomeMedico = this.$route.query.cognomeMedico;
        me.jsonData.provenienzaCodiceFiscaleMedico = this.$route.query.codiceFiscaleMedico;
        me.jsonData.provenienzaRuoloMedico = this.$route.query.ruoloMedico;
        me.jsonData.provenienzaIdMedico = this.$route.query.idMedico;
        me.jsonData.provenienzaEmail = this.$route.query.mailMedico;
        me.jsonData.provenienzaUsername = this.$route.query.usernameMedico;
        //me.headerCard = "Richiesta a C.O.T. Paziente : " + me.jsonData.identificativo;
        // me.impostaPermessi();
        if (me.idPropostaAccesso !== "-1") {
            me.loadData();
        } else {
            me.verificaProposta();
            me.impostaPermessi();
        }
    },
    computed: {
        btnPresaInCarico() {
            // console.log("OK");
            // console.log(UtilityMixin.methods.verificaPermessiByIdSezionePermesso("cot", 1024));
            return UtilityMixin.methods.verificaPermessiByIdSezionePermesso("cot", 1024);
        },
    },
    methods: {
        setData() {
            let me = this;
            me.idPropostaAccesso = me.propostaAcesso.id;
            me.headerCard = "Richiesta a C.O.T. Paziente: " + me.propostaAcesso.cognome + " " + me.propostaAcesso.nome + " (" + me.propostaAcesso.identificativo + ") ";
            me.value += 55;
            me.showModalLoading = false;
            me.propostaStyle = "btn btn-outline-success waves-effect waves-light width-sm no-text";
            me.impostaPermessi();
            me.verificaSchedeValutazione();
            me.verificaValutazioneUvm();
        },
        loadData() {
            let me = this;

            if (me.idPropostaAccesso === "-1") {
                me.onSearchAnagraficaClick();
                me.impostaPermessi();
                this.$emit("afterLoadData");
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.idPropostaAccesso;
                axios
                    .get(link)
                    .then((response) => {
                        me.propostaAcesso = response.data.data;
                        me.setData();
                        me.verificaAlert();
                        this.$emit("afterLoadData");
                        me.impostaPermessi();
                    })
                    .catch(() => {
                        this.$emit("afterLoadData");
                        me.impostaPermessi();
                    });
            }
        },
        impostaPermessi() {
            let me = this;
            me.btnRespinta = UtilityMixin.methods.verificaPermessiByIdSezionePermesso("cot", 2048) && me.propostaAcesso !== null;
            me.btnInviataCot = UtilityMixin.methods.verificaPermessiByIdSezionePermesso("cot", 4096) && me.propostaAcesso !== null;
            me.btnInviataPua = UtilityMixin.methods.verificaPermessiByIdSezionePermesso("cot", 8192) && me.propostaAcesso !== null;
            me.btnInviataAdi = UtilityMixin.methods.verificaPermessiByIdSezionePermesso("cot", 16384) && me.propostaAcesso !== null;
            me.btnInviataListaAttesa = UtilityMixin.methods.verificaPermessiByIdSezionePermesso("cot", 32768) && me.propostaAcesso !== null;
            me.btnPresaInCaricoRsa = ((UtilityMixin.methods.verificaPermessiByIdSezionePermesso("cot", 65536) && !UtilityMixin.methods.verificaPermessiByIdSezionePermesso("cot", 1)) || (UtilityMixin.methods.verificaPermessiByIdSezionePermesso("cot", 65536) && UtilityMixin.methods.verificaPermessiByIdSezionePermesso("cot", 1))) && me.propostaAcesso !== null;
            me.disableSchede = UtilityMixin.methods.verificaPermessiByIdSezionePermesso("cot", 65536) && !UtilityMixin.methods.verificaPermessiByIdSezionePermesso("cot", 1) && me.propostaAcesso !== null;
            me.btnInviataUvm = UtilityMixin.methods.verificaPermessiByIdSezionePermesso("cot", 262144) && me.propostaAcesso !== null;
            me.disableValutazioneUvm = !UtilityMixin.methods.verificaPermessiByIdSezionePermesso("cot", 65536) && !UtilityMixin.methods.verificaPermessiByIdSezionePermesso("cot", 1) && !UtilityMixin.methods.verificaPermessiByIdSezionePermesso("cotvalutazioneuvm", 1);
        },
        verificaAlert() {
            let me = this;

            if (me.idPropostaAccesso !== "-1") {
                let link = process.env.VUE_APP_PATH_API + me.pathVerificaAlertResource + "/" + me.idPropostaAccesso;
                axios
                    .get(link)
                    .then((response) => {
                        if (response.data.data.stato) {
                            me.alertShow = true;
                            me.alertMessage = response.data.data;
                        } else {
                            me.alertShow = false;
                            me.alertMessage = { stato: "", alertMessage: "" };
                        }
                    })
                    .catch(() => {});
            }
        },
        verificaProvenienzaesterna() {
            if (this.$route.query.codiceFiscale) {
                return true;
            } else {
                return false;
            }
        },
        verificaProposta() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.linkVerificaProposta;
            let queryParams = { identificativo: me.jsonData.identificativo, provenienzaCodiceStruttura: me.jsonData.provenienzaCodiceStruttura };
            axios
                .get(link, { params: queryParams })
                .then((response) => {
                    me.propostaAcesso = response.data.data;
                    me.setData();
                })
                .catch(() => {
                    me.headerCard = "Richiesta a C.O.T. Paziente: CODICE FISCALE PAZIENTE NON VALIDO";
                    me.showModalLoading = false;
                    me.schedeStyle = "btn btn-outline-danger waves-effect waves-light width-sm no-text";
                    me.loadAnagraficaPaziente();
                });
        },
        verificaSchedeValutazione() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceSchedeValutazione;
            axios
                .get(link)
                .then((response) => {
                    response.data.data.list.forEach((element) => {
                        me.checkSchedeCompilate(element.path);
                    });
                    me.showModalLoading = false;
                    // me.propostaStyle = "btn btn-outline-success waves-effect waves-light width-sm no-text";
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        verificaValutazioneUvm() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceValutazioneUvm;

            axios
                .get(link)
                .then((response) => {
                    response.data.data.list.forEach((element) => {
                        me.checkSchedeUvmCompilate(element.path);
                    });
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        checkSchedeCompilate(path) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + path + "/check/" + me.propostaAcesso.id;
            axios
                .get(link)
                .then((response) => {
                    if (response.data.esito) {
                        me.value += 15;
                    }
                    if (me.schedeValutazioneCompilate === null) {
                        me.schedeValutazioneCompilate = response.data.esito;
                    } else {
                        // console.log("Tutto ok");
                        // console.log(me.schedeValutazioneCompilate);
                        if (me.schedeValutazioneCompilate && !response.data.esito) {
                            me.schedeStyle = "btn btn-outline-warning waves-effect waves-light width-sm no-text";
                        } else if (!me.schedeValutazioneCompilate && response.data.esito) {
                            me.schedeStyle = "btn btn-outline-warning waves-effect waves-light width-sm no-text";
                        } else {
                            me.schedeStyle = "btn btn-outline-danger waves-effect waves-light width-sm no-text";
                        }

                        if (me.schedeValutazioneCompilate && me.value - 55 === 45) {
                            me.schedeStyle = "btn btn-outline-success waves-effect waves-light width-sm no-text";
                        }
                    }
                    // console.log(response.data.esito);
                })
                .catch(() => {
                    console.log("NON COMPILATA");
                });
        },
        checkSchedeUvmCompilate(path) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + path + "/check/" + me.propostaAcesso.id;
            axios
                .get(link)
                .then((response) => {
                    //   if (response.data.esito) {
                    //     me.value += 15;
                    //   }
                    // console.log(response);
                    if (me.schedeValutazioneUvmCompilate === null) {
                        me.schedeValutazioneUvmCompilate = response.data.esito;
                        if (me.schedeValutazioneUvmCompilate) {
                            me.valutazioneUvmStyle = "btn btn-outline-success waves-effect waves-light width-sm no-text";
                        }
                    } else {
                        // console.log("Tutto ok");
                        // console.log(me.schedeValutazioneCompilate);
                        if (me.schedeValutazioneUvmCompilate && !response.data.esito) {
                            me.valutazioneUvmStyle = "btn btn-outline-warning waves-effect waves-light width-sm no-text";
                        } else if (!me.schedeValutazioneUvmCompilate && response.data.esito) {
                            me.valutazioneUvmStyle = "btn btn-outline-warning waves-effect waves-light width-sm no-text";
                        } else if (me.schedeValutazioneUvmCompilate && response.data.esito) {
                            me.valutazioneUvmStyle = "btn btn-outline-success waves-effect waves-light width-sm no-text";
                        } else {
                            me.valutazioneUvmStyle = "btn btn-outline-danger waves-effect waves-light width-sm no-text";
                        }

                        // if (me.schedeValutazioneUvmCompilate && me.value - 55 === 45) {
                        //   me.valutazioneUvmStyle = "btn btn-outline-success waves-effect waves-light width-sm no-text";
                        // }
                    }
                    // console.log(response.data.esito);
                })
                .catch(() => {
                    // console.log("NON COMPILATA");
                });
        },
        loadAnagraficaPaziente() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + "/utility/anagraficaregionale";
            // let link = process.env.VUE_APP_PATH_API + "/utility";
            if (me.jsonData.identificativo) {
                axios
                    .get(link, { params: { codiceStruttura: me.codiceStrutturaAnagraficaRegionale, codiceFiscale: me.jsonData.identificativo } })
                    // .get(link, { params: { codiceStruttura: "180000", codiceFiscale: me.jsonData.identificativo } })
                    .then((response) => {
                        // console.log(response);
                        if (response.data.data !== null) {
                            me.jsonData.nome = response.data.data.nome;
                            me.jsonData.cognome = response.data.data.cognome;
                            me.jsonData.dataNascita = moment(response.data.data.dataNascita, "DD/MM/YYYY").toDate().getTime();
                            me.jsonData.sesso = response.data.data.sesso;
                            me.jsonData.comuneNascita = response.data.data.comuneNascita;
                            me.jsonData.comuneResidenza = response.data.data.comuneResidenza;
                            me.jsonData.indirizzoResidenza = response.data.data.indirizzoResidenza;
                            me.jsonData.indirizzoDomicilio = "";
                            me.jsonData.identificativo = response.data.data.codiceFiscale;
                            me.jsonData.medicoCurante = response.data.data.medico;
                            me.jsonData.tesseraSanitaria = response.data.data.numeroTesseraSanitaria;
                            me.jsonData.comuneNascitaCodiceIstat = response.data.data.codiceIstatComuneNascita;
                            me.jsonData.comuneResidenzaCodiceIstat = response.data.data.codiceIstatComuneResidenza;
                            me.jsonData.comuneDomicilioCodiceIstat = "";
                            me.jsonData.capNascita = "";
                            me.jsonData.capDomicilio = "";
                            me.jsonData.capResidenza = "";
                            me.jsonData.cittadinanza = response.data.data.cittadinanza;
                            me.jsonData.regioneResidenzaCodiceIstat = response.data.data.regioneResidenza;
                            me.jsonData.eta = UtilityMixin.methods.calcoloEta(me.jsonData.dataNascita);
                            me.jsonData.nazioneResidenzaCodiceIstat = response.data.data.cittadinanza === "ITALIANA" ? "111100" : "";
                            if (me.jsonData.identificativo) {
                                me.headerCard = "Richiesta a C.O.T. Paziente: " + me.jsonData.cognome + " " + me.jsonData.nome + " (" + me.jsonData.identificativo + ") ";
                            } else {
                                me.headerCard = "Nuova richiesta a C.O.T. ";
                            }
                        } else {
                            return me.$bvModal.msgBoxOk("Codice Fiscale non corretto", {
                                title: "Errore",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            });
                        }
                    })
                    .catch(() => {
                        me.$emit("update", me.jsonData);
                    });
            }
        },
        onCompilaClick(item) {
            if (item.descrizione === "Proposta d’Accesso") {
                let me = this;
                let link;
                if (me.verificaProvenienzaesterna()) {
                    link =
                        me.linkedit +
                        me.idPropostaAccesso +
                        "?view=module&codiceFiscale=" +
                        me.jsonData.identificativo +
                        "&codiceStruttura=" +
                        me.jsonData.provenienzaCodiceStruttura +
                        "&descrizioneStruttura=" +
                        me.jsonData.provenienzaDescrizioneStruttura +
                        "&codiceReparto=" +
                        me.jsonData.provenienzaCodiceReparto +
                        "&descrizioneReparto=" +
                        me.jsonData.provenienzaDescrizioneReparto +
                        "&eventoClinico=" +
                        me.jsonData.provenienzaEventoClinico +
                        "&nominativoMedico=" +
                        me.jsonData.provenienzaNominativoMedico +
                        "&cognomeMedico=" +
                        me.jsonData.provenienzaCognomeMedico +
                        "&codiceFiscaleMedico=" +
                        me.jsonData.provenienzaCodiceFiscaleMedico +
                        "&ruoloMedico=" +
                        me.jsonData.provenienzaRuoloMedico +
                        "&idMedico=" +
                        me.jsonData.provenienzaIdMedico +
                        "&mailMedico=" +
                        me.jsonData.provenienzaEmail +
                        "&usernameMedico=" +
                        me.jsonData.provenienzaUsername;
                } else {
                    link = me.linkedit + me.idPropostaAccesso;
                }

                me.$router.replace(link).catch((err) => {
                    err;
                });
            } else if (item.descrizione === "Schede di Valutazione") {
                let me = this;
                let link =
                    me.linkSchedeValutazioni +
                    me.propostaAcesso.id +
                    "?view=module&codiceFiscale=" +
                    me.jsonData.identificativo +
                    "&codiceStruttura=" +
                    me.jsonData.provenienzaCodiceStruttura +
                    "&descrizioneStruttura=" +
                    me.jsonData.provenienzaDescrizioneStruttura +
                    "&codiceReparto=" +
                    me.jsonData.provenienzaCodiceReparto +
                    "&descrizioneReparto=" +
                    me.jsonData.provenienzaDescrizioneReparto +
                    "&eventoClinico=" +
                    me.jsonData.provenienzaEventoClinico +
                    "&nominativoMedico=" +
                    me.jsonData.provenienzaNominativoMedico +
                    "&cognomeMedico=" +
                    me.jsonData.provenienzaCognomeMedico +
                    "&codiceFiscaleMedico=" +
                    me.jsonData.provenienzaCodiceFiscaleMedico +
                    "&ruoloMedico=" +
                    me.jsonData.provenienzaRuoloMedico +
                    "&idMedico=" +
                    me.jsonData.provenienzaIdMedico +
                    "&mailMedico=" +
                    me.jsonData.provenienzaEmail +
                    "&usernameMedico=" +
                    me.jsonData.provenienzaUsername;
                me.$router.replace(link).catch((err) => {
                    err;
                });
            } else if (item.descrizione === "Allegati") {
                let me = this;
                let link;
                if (me.verificaProvenienzaesterna()) {
                    link =
                        me.linkAllegati +
                        me.idPropostaAccesso +
                        "?view=module&codiceFiscale=" +
                        me.jsonData.identificativo +
                        "&codiceStruttura=" +
                        me.jsonData.provenienzaCodiceStruttura +
                        "&descrizioneStruttura=" +
                        me.jsonData.provenienzaDescrizioneStruttura +
                        "&codiceReparto=" +
                        me.jsonData.provenienzaCodiceReparto +
                        "&descrizioneReparto=" +
                        me.jsonData.provenienzaDescrizioneReparto +
                        "&eventoClinico=" +
                        me.jsonData.provenienzaEventoClinico +
                        "&nominativoMedico=" +
                        me.jsonData.provenienzaNominativoMedico +
                        "&cognomeMedico=" +
                        me.jsonData.provenienzaCognomeMedico +
                        "&codiceFiscaleMedico=" +
                        me.jsonData.provenienzaCodiceFiscaleMedico +
                        "&ruoloMedico=" +
                        me.jsonData.provenienzaRuoloMedico +
                        "&idMedico=" +
                        me.jsonData.provenienzaIdMedico +
                        "&mailMedico=" +
                        me.jsonData.provenienzaEmail +
                        "&usernameMedico=" +
                        me.jsonData.provenienzaUsername;
                } else {
                    link = me.linkAllegati + me.idPropostaAccesso;
                }
                console.log(link);
                me.$router.replace(link).catch((err) => {
                    err;
                });
            } else if (item.descrizione === "Modulistica") {
                let me = this;
                let link;
                if (me.verificaProvenienzaesterna()) {
                    link =
                        me.linkModulistica +
                        me.idPropostaAccesso +
                        "?view=module&codiceFiscale=" +
                        me.jsonData.identificativo +
                        "&codiceStruttura=" +
                        me.jsonData.provenienzaCodiceStruttura +
                        "&descrizioneStruttura=" +
                        me.jsonData.provenienzaDescrizioneStruttura +
                        "&codiceReparto=" +
                        me.jsonData.provenienzaCodiceReparto +
                        "&descrizioneReparto=" +
                        me.jsonData.provenienzaDescrizioneReparto +
                        "&eventoClinico=" +
                        me.jsonData.provenienzaEventoClinico +
                        "&nominativoMedico=" +
                        me.jsonData.provenienzaNominativoMedico +
                        "&cognomeMedico=" +
                        me.jsonData.provenienzaCognomeMedico +
                        "&codiceFiscaleMedico=" +
                        me.jsonData.provenienzaCodiceFiscaleMedico +
                        "&ruoloMedico=" +
                        me.jsonData.provenienzaRuoloMedico +
                        "&idMedico=" +
                        me.jsonData.provenienzaIdMedico +
                        "&mailMedico=" +
                        me.jsonData.provenienzaEmail +
                        "&usernameMedico=" +
                        me.jsonData.provenienzaUsername;
                } else {
                    link = me.linkModulistica + me.idPropostaAccesso;
                }
                console.log(link);
                me.$router.replace(link).catch((err) => {
                    err;
                });
            } else if (item.descrizione === "Valutazione U.V.M.") {
                let me = this;
                let link;
                if (me.verificaProvenienzaesterna()) {
                    link =
                        me.linkModulisticaValutazioneUvm +
                        me.idPropostaAccesso +
                        "?view=module&codiceFiscale=" +
                        me.jsonData.identificativo +
                        "&codiceStruttura=" +
                        me.jsonData.provenienzaCodiceStruttura +
                        "&descrizioneStruttura=" +
                        me.jsonData.provenienzaDescrizioneStruttura +
                        "&codiceReparto=" +
                        me.jsonData.provenienzaCodiceReparto +
                        "&descrizioneReparto=" +
                        me.jsonData.provenienzaDescrizioneReparto +
                        "&eventoClinico=" +
                        me.jsonData.provenienzaEventoClinico +
                        "&nominativoMedico=" +
                        me.jsonData.provenienzaNominativoMedico +
                        "&cognomeMedico=" +
                        me.jsonData.provenienzaCognomeMedico +
                        "&codiceFiscaleMedico=" +
                        me.jsonData.provenienzaCodiceFiscaleMedico +
                        "&ruoloMedico=" +
                        me.jsonData.provenienzaRuoloMedico +
                        "&idMedico=" +
                        me.jsonData.provenienzaIdMedico +
                        "&mailMedico=" +
                        me.jsonData.provenienzaEmail +
                        "&usernameMedico=" +
                        me.jsonData.provenienzaUsername;
                } else {
                    link = me.linkModulisticaValutazioneUvm + me.idPropostaAccesso;
                }
                // console.log(link);
                me.$router.replace(link).catch((err) => {
                    err;
                });
            }
        },
        onCambiaStato(riga, stato) {
            let json = { idCot: riga, idStato: stato };
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathCambiaStato;
            axios
                .post(link, json)
                .then((response) => {
                    this.$bvToast.toast("Cambio Stato: " + response.data.data.stato, {
                        title: this.titleModal,
                        variant: "success",
                        autoHideDelay: 1000,
                    });
                    me.jsonData.statoAttuale = response.data.data.stato;
                    me.jsonData.statoAttualeDataOra = response.data.data.statoDataOra;
                    me.onUpdatePropostaAccesso(me.jsonData);
                    // me.$refs.propostaAccesso.loadData();
                })
                .catch((error) => {
                    // me.$refs["sa-modal-loading"].close();
                    let message = "";
                    error.response ? error.response.data.messaggio : "Errore non gestito";
                    if (error.response) {
                        message = error.response.data.messaggio ? error.response.data.messaggio : "Codice Errore: " + error.response.status + ", non gestito";
                    }
                    if (error.response) {
                        this.$bvModal
                            .msgBoxOk(message, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then(() => {})
                            .catch((err) => {
                                console.log(err);
                            });
                    }
                });
        },
        onVisualizzaPdf() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + "/cotprinter/" + me.propostaAcesso.id;
            axios.get(link).then((response) => {
                let printData = response.data.data;
                let linkReport = process.env.VUE_APP_PATH_API + me.pathResourceStampa;
                axios.post(linkReport, printData).then((response) => {
                    me.showModalLoading = false;
                    me.$refs.pdfPrintViewEmbedded.show();
                    me.signedPdfViewer = "data:application/pdf;base64," + response.data.base64;
                });
            });
        },
        test() {
            console.log(UtilityMixin.methods.verificaPermessiByIdSezionePermesso("cot", 1024));
        },
        onVisualizzaDossierSanitarioClick() {
            let me = this;
            let link = "/cot/dossiersanitario/view/" + me.propostaAcesso.id + "?identificativo=" + me.propostaAcesso.identificativo;
            me.$router.replace(link).catch((err) => {
                err;
            });
        },
        onSignedDocument() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + "/cotprinter/" + me.propostaAcesso.id;
            axios.get(link).then((response) => {
                let printData = response.data.data;
                let linkReport = process.env.VUE_APP_PATH_API + me.pathResourceStampa;
                axios.post(linkReport, printData).then((response) => {
                    me.showModalLoading = false;
                    me.$refs.firmaElettronicaComponent.show();
                    me.pdfFromSigned = "data:application/pdf;base64," + response.data.base64;
                });
            });
        },
    },
};
</script>

<style>
.hidden-header {
    display: none;
}

.sa-page-pa-module {
    background-color: var(--bg-main-container);
    height: 100%;
    padding-left: 5rem;
    padding-right: 5rem;
    height: 150px;
    display: flex;
    /* align-items: center; */
    flex: 1;
    /* max-width: 770px; */
}

.sa-column-widh {
    width: 180px;
}

.sa-card header {
    font-variant: petite-caps !important;
}
</style>
