import FatturazioneList from "./components/FatturazioneList.vue";
import FatturazioneView from "./components/FatturazioneView.vue";
import FatturazioneEdit from "./components/FatturazioneEdit.vue";
import TipiDocumentoList from "./components/TipiDocumentoList.vue";
import TipiDocumentoView from "./components/TipiDocumentoView.vue";
import TipiDocumentoEdit from "./components/TipiDocumentoEdit.vue";
import CausaliDocumentoList from "./components/CausaliDocumentoList.vue";
import CausaliDocumentoView from "./components/CausaliDocumentoView.vue";
import CausaliDocumentoEdit from "./components/CausaliDocumentoEdit.vue";
import FatturazioneNumeratoriList from "./components/FatturazioneNumeratoriList.vue"
import FatturazioneNumeratoriView from "./components/FatturazioneNumeratoriView.vue"
import FatturazioneNumeratoriEdit from "./components/FatturazioneNumeratoriEdit.vue"

export default {
    FatturazioneList,
    FatturazioneView,
    FatturazioneEdit,
    TipiDocumentoList,
    TipiDocumentoView,
    TipiDocumentoEdit,
    CausaliDocumentoList,
    CausaliDocumentoView,
    CausaliDocumentoEdit,
    FatturazioneNumeratoriList,
    FatturazioneNumeratoriView,
    FatturazioneNumeratoriEdit,
};
