<template>
  <div class="sa-list-component">
    <div class="sa-list-component-filter">
      <!-- <label class="sa-label-data">{{ protocolloModulo.modulo }} - {{ protocolloModulo.documento }} - {{ protocolloModulo.protocollo }}</label> -->
      <b-form @submit.prevent="onSubmit">
        <b-row>
          <b-col cols="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data">Email:</label>
            <b-form-input
              maxlength="16"
              v-model="filtro.email"
              type="search"
              id="codice"
            ></b-form-input>
          </b-col>

          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data">Tipo Persona:</label>
            <b-form-select
              v-model="filtro.tipoPersona"
              :options="tipoPersonaOptions"
              value-field="value"
              text-field="text"
            ></b-form-select>
          </b-col>
          <b-col cols="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data">Nome:</label>
            <b-form-input
              maxlength="16"
              v-model="filtro.nome"
              type="search"
              id="codice"
            ></b-form-input>
          </b-col>
          <b-col cols="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data">Cognome:</label>
            <b-form-input
              maxlength="16"
              v-model="filtro.cognome"
              type="search"
              id="codice"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="sa-padding-right text-right">
            <b-button type="submit" variant="info">{{
              this.$i18n.t("global.lblFind")
            }}</b-button>
            <b-button type="reset" v-on:click="onReset" variant="danger">{{
              this.$i18n.t("global.lblResetFilter")
            }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </div>
    <div class="sa-list-component-header">
      <b-row
        class="sa-label-info"
        style="margin-left: 0.5rem; margin-right: 0.5rem"
      >
        <b-col lg="3">
          <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            align="right"
            size="sm"
          ></b-pagination>
        </b-col>
      </b-row>
    </div>
    <div class="sa-list-component-body">
      <div class="b-table-sticky-header">
        <b-table
          sticky-header
          ref="table"
          stacked="lg"
          striped
          hover
          :items="items"
          :fields="fields"
          :current-page="1"
          :per-page="perPage"
          :key="listKey"
          sort-icon-left
          head-variant="light"
          class="sa-b-table"
        >
          <template #cell(index)="row">
            {{ row.index + 1 }}
          </template>

          <template v-slot:cell(tipoPersona)="{ item }">
            <span>{{ item.tipoPersona }} </span>
          </template>
          <template v-slot:cell(nome)="{ item }">
            <span>{{ item.nome }} </span>
            <span class="sa-label-data-secondary"> </span>
          </template>
          <template v-slot:cell(cognome)="{ item }">
            <span>{{ item.cognome }} </span>
            <span class="sa-label-data-secondary"> </span>
          </template>
          <template v-slot:cell(email)="{ item }">
            <router-link
              class="sa-edit-link"
              :to="'/protocollorubrica/edit/' + item.id"
              >{{ item.email }}</router-link
            >
          </template>
          <template #cell(action)="row">
            <b-button
              size="sm"
              variant="outline-danger no-text"
              @click="onDelete(row.item)"
            >
              <b-icon icon="trash"></b-icon>
            </b-button>
            &nbsp;
          </template>
        </b-table>
      </div>
    </div>
    <div class="sa-list-component-footer">
      <b-row
        class="sa-label-info"
        style="margin-left: 0.5rem; margin-right: 0.5rem"
      >
        <b-col lg="3">
          <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            align="right"
            size="sm"
          ></b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  components: {},
  data() {
    return {
      pathResource: "/protocolrubrica",
      //linkedit: "/",
      perPage: 50,
      currentPage: 1,
      rows: 0,
      listKey: 0,
      showModalLoading: false,
      filtro: { tipoPersona: "", nome: "", cognome: "", email: "" },
      tipoPersonaOptions: [
        { text: "-Selezionare il Tipo-", value: "" },
        { text: "FISICA", value: "F" },
        { text: "GIURIDICA", value: "G" },
      ],
      items: [],
      fields: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },

        {
          label: "Email",
          key: "email",
          sortable: true,
        },
        {
          label: "Nome",
          key: "nome",
          sortable: true,
        },
        {
          label: "Cognome",
          key: "cognome",
          sortable: true,
        },
        {
          label: "Tipo Persona",
          key: "tipoPersona",
          sortable: true,
        },
        {
          label: "",
          key: "action",
          thStyle: "width: 7rem",
          tdClass: "text-center",
        },
      ],
    };
  },
  mounted() {
    let me = this;
    let sessionStorage = window.sessionStorage;
    if (sessionStorage["filtro"]) {
      me.filtro = JSON.parse(sessionStorage["filtro"]);
    }
  },
  methods: {
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource;
      me.filtro.page = me.currentPage;
      me.filtro.forPage = me.perPage;
      axios
        .get(link, { params: me.filtro })
        .then((response) => {
          me.items = [];
          me.items = response.data.data.list;
          me.rows = response.data.data.recordsNumber;
          me.listKey++;
          //          me.changeGestore();
          this.$emit("afterLoadData");
        })
        .catch(() => {
          this.$emit("afterLoadData");
        });
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },

    onSubmit() {
      let me = this;
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onReset() {
      let me = this;
      me.filtro = {};
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onDelete(item) {
      console.log(item);
      let me = this;
      this.$bvModal
        .msgBoxConfirm("Sicuro di voler Eliminare? ")
        .then((value) => {
          if (value) {
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            axios.delete(link + item.id).then(() => {
              me.loadData();
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
