<template>
    <div style="height: 100%">
        <div class="sa-flexbox" style="padding-bottom: 20px">
            <!-- <div class="sa-flexbox-body"> -->
            <div class="b-table-sticky-header">
                <span class="sa-flexbox-body-title">Device</span>
                <b-button size="sm" variant="outline-success outline-primary-noborder sa-margin-right float-sm-right" @click="onClickAssociaKit" class="mb-2">
                    <font-awesome-icon icon="plus"> </font-awesome-icon>
                    Aggiungi
                </b-button>

                <b-table sticky-header ref="table" stacked="lg" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
                    <template #cell(kitId)="row">
                        {{ row.item.kitId.toUpperCase() }}
                    </template>
                    <template #cell(hubName)="row">
                        {{ row.item.hubName.toUpperCase() }}
                    </template>
                    <template #cell(show_details)="row">
                        <b-button variant="btn btn-outline-secondary waves-effect waves-light width-sm no-text" size="sm" @click="row.toggleDetails" class="mr-2"> {{ row.detailsShowing ? "Nascondi" : "Visualizza" }} Dettagli</b-button>
                    </template>
                    <template #cell(action)="row">
                        <b-button variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" size="sm" @click="onDeleteItem(row.item)"><b-icon icon="trash"></b-icon></b-button>
                    </template>
                    <template #row-details="row">
                        <b-card>
                            <div class="container-fluid">
                                <b-row>
                                    <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                                        <label class="sa-label-data">Temperature :</label>
                                        <span class="sa-data">{{ row.item.temperatureName.toUpperCase() }}</span>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                                        <label class="sa-label-data">Glucose :</label>
                                        <span class="sa-data">{{ row.item.glucoseName.toUpperCase() }}</span>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                                        <label class="sa-label-data">Ecg :</label>
                                        <span class="sa-data">{{ row.item.ecgName.toUpperCase() }}</span>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                                        <label class="sa-label-data">Spo2 :</label>
                                        <span class="sa-data">{{ row.item.spo2Name.toUpperCase() }}</span>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                                        <label class="sa-label-data">Pressure :</label>
                                        <span class="sa-data">{{ row.item.pressureName.toUpperCase() }}</span>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                                        <label class="sa-label-data">Bracelet :</label>
                                        <span class="sa-data">{{ row.item.braceletName.toUpperCase() }}</span> </b-col
                                    ><b-col xs="12" sm="12" md="12" lg="3" xl="3">
                                        <label class="sa-label-data">Scale :</label>
                                        <span class="sa-data">{{ row.item.scaleName.toUpperCase() }}</span> </b-col
                                    ><b-col xs="12" sm="12" md="12" lg="3" xl="3">
                                        <label class="sa-label-data">Speed Sensor :</label>
                                        <span class="sa-data">{{ row.item.speedSensorName.toUpperCase() }}</span>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-card>
                        <!-- </div> -->
                    </template>
                </b-table>
                <!-- </div> -->
            </div>
            <!-- </div> -->
            <div class="sa-list-component-footer">
                <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col lg="3">
                        <p>Record Totali:{{ rows }}</p>
                    </b-col>
                    <b-col lg="9">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                    </b-col>
                </b-row>
            </div>
            <!-- </div> -->
            <paziente-device-edit-component ref="PazienteDeviceEditComponent" @afterSaveSuccess="onAfterSaveSuccess"></paziente-device-edit-component>
        </div>
    </div>
</template>
<script>
import UtilityMixin from "../../../utility/UtilityMixin";
import PazienteDeviceEditComponent from "./PazienteDeviceEditComponent.vue";
import axios from "axios";
export default {
    props: {
        // idPaziente: { type: String },
    },

    watch: {
        currentPage() {
            let me = this;
            me.loadData();
        },
    },
    mixins: [UtilityMixin],
    components: { PazienteDeviceEditComponent },
    data() {
        return {
            rows: 0,
            perPage: 50,
            currentPage: 1,
            listKey: 0,
            items: [],
            idPaziente: null,
            pathSorgente: null,
            pathResource: "/hassistokits/patient",
            pathResourceKit: "/hassistokits/dissociatekit",
            linkedit: "/",
            filterItems: [],
            filtroSpesaExtra: {},
            showModalLoading: false,
            idAccettazione: "",
            fields: [
                {
                    label: "",
                    key: "index",
                    thStyle: "width: 1rem",
                    tdClass: "text-center",
                },
                {
                    label: "Kit",
                    key: "kitId",
                },
                {
                    label: "Hub Name",
                    key: "hubName",
                    thStyle: "width: 8rem",
                },
                {
                    label: "",
                    key: "show_details",
                    thStyle: "width: 20rem",
                    tdClass: "text-center",
                },
                {
                    label: "",
                    key: "action",
                    thStyle: "width: 2rem",
                    tdClass: "text-center",
                },
            ],
        };
    },
    mounted() {
        // let me = this;
        // me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            me.$emit("beforeLoadData");
            UtilityMixin.methods.loadDataResources(me.pathResource, { idPaziente: me.idPaziente }, me.loadDataSuccess, me.loadDataError);
            //   this.showModalLoading = true;
        },
        loadDataSuccess(response) {
            let me = this;
            me.items = response.data.data;
            me.rows = me.items.length;
            me.$emit("afterLoadData");
            //  this.showModalLoading = true;
        },
        loadDataError(response) {
            console.log(response);
        },
        onClickAssociaKit() {
            let me = this;
            me.$refs.PazienteDeviceEditComponent.openModal(me.idPaziente, null);
        },
        setPathSorgente(value) {
            let me = this;
            me.pathSorgente = value;
        },
        setIdPaziete(value) {
            let me = this;
            me.idPaziente = value;
        },
        onAvviaTeleconsulto(item) {
            let me = this;
            let link = me.pathSorgente + "/teleconsulti/" + item.id + "?idPaziente=" + this.idPaziente;
            me.$router.replace(link).catch((err) => {
                err;
            });
        },
        onAfterSaveSuccess(response) {
            let me = this;
            response;
            me.loadData();
        },
        onDeleteItem(item) {
            let me = this;
            this.$bvModal
                .msgBoxConfirm("Sei sicuro di procedere alla disassociazione del Kit?", {
                    title: "Conferma disassociazione",
                    buttonSize: "sm",
                    okVariant: "secondary",
                    cancelVariant: "danger",
                    okTitle: "SI",
                    cancelTitle: "NO",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                })
                .then((value) => {
                    if (value) {
                        me.deleteItem(item);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        deleteItem(item) {
            let me = this;
            console.log(item);
            let link = process.env.VUE_APP_PATH_API + me.pathResourceKit;
            axios
                .put(link, { kitId: item.kitId })
                .then(() => {
                    me.modalShow = false;
                    me.loadData();
                })
                .catch((e) => {
                    console.log(e);
                });
        },
    },
};
</script>
