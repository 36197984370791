import { render, staticRenderFns } from "./IndagineEpidemiologicaViewComponent.vue?vue&type=template&id=351b5c25"
import script from "./IndagineEpidemiologicaViewComponent.vue?vue&type=script&lang=js"
export * from "./IndagineEpidemiologicaViewComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports