<template>
    <div v-if="isEdit">
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <template #header>
                <b-row>
                    <b-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6">
                        <span></span>
                    </b-col>
                    <b-col class="sa-padding-right text-right" cols="12" xs="6" sm="6" md="6" lg="6" xl="6">
                        <b-button variant="outline-secondary outline-secondary-noborder pull-right" size="lg" @click="onAltraDiagnosi">
                            <i style="margin-top: 2px" class="far fa-copy"></i>
                            <span style="padding-left: 5px">Altra Diagnosi di Tipo non Infettivo</span>
                        </b-button>
                        <b-button variant="outline-secondary outline-secondary-noborder pull-right" size="lg" @click="onDuplica">
                            <i style="margin-top: 2px" class="far fa-copy"></i>
                            <span style="padding-left: 5px">Diagnosi Iniziale Accertata</span>
                        </b-button>
                        <!-- <b-button variant="outline-success outline-secondary-noborder pull-right" size="lg" @click="onSave">
                            <b-icon icon="check2"></b-icon>
                            <span style="padding-left: 5px">Salva</span>
                        </b-button> -->
                    </b-col>
                </b-row>
            </template>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Codice:</label>
                    <b-form-input v-model="jsonData.diagnosiDefinitivaCodice" disabled></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Descrizione:</label>
                    <b-input-group>
                        <b-form-input v-model="jsonData.diagnosiDefinitivaDescrizione" disabled></b-form-input>
                        <b-button size="sm" variant="" @click="onClickBtnMalattiaSegnalata"><b-icon icon="search"></b-icon></b-button>
                    </b-input-group>
                </b-col>
                <!-- <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Altra Malattia:</label>
                    <b-form-input v-model="jsonData.diagnosiDefinitivaDescrizioneAltro" :disabled="jsonData.diagnosiDefinitivaCodice !== 'ASLNA10000'"></b-form-input>
                </b-col> -->
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Cognome Medico Certificante:</label>
                    <b-form-input v-model="jsonData.cognomeMedicoCertificante"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Nome Medico Certificante:</label>
                    <b-form-input v-model="jsonData.nomeMedicoCertificante"></b-form-input>
                </b-col>
            </b-row>
        </b-card>
        <div>
            <b-modal ref="mdlRicercaMalattia" id="mdlRicercaMalattia" title="Ricerca Malattia" size="xl" @ok="onOkMdlRicercaMalattia" @hidden="onHiddenMdlRicercaMalattia" scrollable>
                <!-- -->
                <div class="sa-form-section">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-form @submit.prevent="onClickRicercaMalattiaMdl">
                                <b-input-group>
                                    <b-form-input v-model="filtro.descrizione"></b-form-input>
                                    <b-button size="sm" @click="onClickRicercaMalattiaMdl"><b-icon icon="search"></b-icon></b-button>
                                </b-input-group>
                            </b-form>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="sa-padding-right text-right">
                            <b-button type="reset" v-on:click="onResetFiltroMdl" variant="danger">Resetta Filtro</b-button>
                        </b-col>
                    </b-row>

                    <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                        <b-col lg="3">
                            <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                        </b-col>
                        <b-col lg="9">
                            <b-pagination v-model="filtro.page" :total-rows="rows" :per-page="filtro.forPage" align="right" size="sm"></b-pagination>
                        </b-col>
                    </b-row>
                    <!-- <div class="b-table-sticky-header"> -->
                    <b-table sticky-header ref="tblMalattie" stacked="xl" striped hover itemscope :items="malattieSegnalate" :fields="fieldsMlattieSegnalate" :current-page="filtro.page" :per-page="filtro.forPage" sort-icon-left head-variant="light" class="sa-b-table" selectable select-mode="single" @row-selected="onRowSelectedMalattia">
                        <template v-slot:cell(codiceIcdix)="{ item }">
                            <template v-if="item.entroOre !== null && item.entroOre !== ''">
                                <label class="sa-label-data">{{ item.codiceIcdix }}</label>
                            </template>
                            <template v-else>
                                {{ item.codiceIcdix }}
                            </template>
                        </template>
                        <template v-slot:cell(descrizioneIcdix)="{ item }">
                            <template v-if="item.entroOre !== null && item.entroOre !== ''">
                                <label class="sa-label-data">{{ item.descrizioneIcdix }}</label>
                            </template>
                            <template v-else>
                                {{ item.descrizioneIcdix }}
                            </template>
                        </template>
                        <template v-slot:cell(entroOre)="{ item }">
                            <template v-if="item.entroOre !== null && item.entroOre !== ''">
                                <label class="sa-label-data">{{ item.entroOre }}</label>
                            </template>
                            <template v-else>
                                {{ item.entroOre }}
                            </template>
                        </template>
                        <template #cell(selezionato)="{ rowSelected }">
                            <template v-if="rowSelected">
                                <span aria-hidden="true">&check;</span>
                                <span class="sr-only">Selezionato</span>
                            </template>
                            <template v-else>
                                <span aria-hidden="true">&nbsp;</span>
                                <span class="sr-only">Non Selezionato</span>
                            </template>
                        </template>
                    </b-table>
                    <!-- </div> -->
                </div>
            </b-modal>
        </div>
    </div>
    <div v-else>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Codice:</label>
                    <span class="sa-data">{{ conclusioni.diagnosiDefinitivaCodice }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Descrizione:</label>
                    <span class="sa-data">{{ conclusioni.diagnosiDefinitivaDescrizione }}</span>
                </b-col>
                <!-- <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Altra Malattia:</label>
                    <span class="sa-data">{{ jsonData.diagnosiDefinitivaDescrizioneAltro }}</span>
                </b-col> -->
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Cognome Medico Certificante:</label>
                    <span class="sa-data">{{ conclusioni.cognomeMedicoCertificante }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Nome Medico Certificante:</label>
                    <span class="sa-data">{{ conclusioni.nomeMedicoCertificante }} </span>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import UtilityMixin from "../../../utility/UtilityMixin";
import axios from "axios";
export default {
    mixins: [UtilityMixin],
    components: {},
    props: {
        segnalazione: {
            type: Object,
            default: function () {
                return null;
            },
        },
        isEdit: Boolean,
    },
    computed: {
        rows() {
            return this.malattieSegnalate.length;
        },
    },
    data() {
        return {
            pathResource: "/malattieinfettiveietestate",
            pathResourceMalattie: "/malattieinfettivemalattie",
            id: "",
            filtro: { descrizione: "", forPage: 100, page: 1 },
            jsonData: {
                // id: "",
                diagnosiDefinitivaCodice: "",
                diagnosiDefinitivaDescrizione: "",
                diagnosiDefinitivaDescrizioneAltro: "",
                cognomeMedicoCertificante: "",
                nomeMedicoCertificante: "",
            },
            malattieSegnalate: [],
            conclusioni: {},
            fieldsMlattieSegnalate: [
                {
                    label: "",
                    key: "selezionato",
                    thStyle: "width: 2.5rem",
                },
                {
                    label: "Codice IcdIX",
                    key: "codiceIcdix",
                    thStyle: "width: 4rem",
                    sortable: false,
                },
                {
                    label: "Descrizione",
                    key: "descrizioneIcdix",
                    sortable: false,
                },
                {
                    label: "Entro Ore",
                    key: "entroOre",
                    thStyle: "width: 10rem",
                    sortable: false,
                },
            ],
        };
    },
    mounted() {
        let me = this;

        me.id = this.$route.params.id;
        // me.jsonData.id = me.segnalazione.id;
        if (!me.isEdit) {
            me.loadData();
        }
        let sessionStorage = window.sessionStorage;
        if (sessionStorage["filtro"]) {
            me.filtro = JSON.parse(sessionStorage["filtro"]);
        }
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;

            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            me.showModalLoading = true;
            axios
                .get(link + me.id)
                .then((response) => {
                    me.conclusioni = response.data.data;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },

        onAltraDiagnosi() {
            let me = this;
            // me.jsonData.id = me.id;
            me.jsonData.diagnosiDefinitivaCodice = "ALTRA DIAGNOSI DI TIPO NON INFETTIVO";
            me.jsonData.diagnosiDefinitivaDescrizione = "ALTRA DIAGNOSI DI TIPO NON INFETTIVO";
        },
        onDuplica() {
            let me = this;
            // me.jsonData.id = me.id;
            me.jsonData.diagnosiDefinitivaCodice = me.segnalazione.codiceIcdix;
            me.jsonData.diagnosiDefinitivaDescrizione = me.segnalazione.malattiaSegnalata + " " + (me.segnalazione.malattiaSegnalataAltra !== null && me.segnalazione.malattiaSegnalataAltra !== "" ? me.segnalazione.malattiaSegnalataAltra : "");
        },
        onOkMdlRicercaMalattia() {
            let me = this;

            me.jsonData.diagnosiDefinitivaDescrizione = me.malattiaSelezionata;
            me.jsonData.diagnosiDefinitivaCodice = me.codiceIcdixSelezionato;
        },
        onSave() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
            axios
                .put(link, JSON.stringify(me.jsonData))
                .then(() => {
                    this.$bvToast.toast("Salvataggio Diagnosi Avvenuta con successo", {
                        title: this.titleModal,
                        variant: "success",
                        autoHideDelay: 1000,
                    });
                })
                .catch((error) => {
                    this.$bvToast.toast("Errore " + error.response.data.messaggio, {
                        title: this.titleModal,
                        variant: "danger",
                        autoHideDelay: 1000,
                    });
                });
        },
        onClickBtnMalattiaSegnalata() {
            let me = this;
            me.$refs.mdlRicercaMalattia.show();
            me.loadMalattieInfettiveIcdIx();
        },
        onRowSelectedMalattia(rows) {
            let me = this;

            if (rows[0]) {
                me.malattiaSelezionata = rows[0].descrizioneIcdix + " (" + rows[0].codiceIcdix + ")";
                me.codiceIcdixSelezionato = rows[0].codiceIcdix;
            }
        },
        loadMalattieInfettiveIcdIx() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceMalattie;
            axios.get(link, { params: me.filtro }).then((response) => {
                // console.log(response);
                me.malattieSegnalate = response.data.data.list;
                me.showModalLoading = false;
            });
        },
        onClickRicercaMalattiaMdl() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadMalattieInfettiveIcdIx();
        },
        onResetFiltroMdl() {
            let me = this;
            me.filtro.descrizione = "";

            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadMalattieInfettiveIcdIx();
        },
        onHiddenMdlRicercaMalattia() {
            let me = this;
            me.filtro.descrizione = "";
        },
        setData(data) {
            let me = this;
            me.jsonData = data;
        },
    },
};
</script>

<style></style>
