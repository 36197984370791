<template>
  <div class="sa-tab-scroll">
    <b-card class="sa-card" header="ETA'" header-tag="header" footer-tag="footer" title="">
      <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaEta" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header">
        <template #cell(check)="{ item }">
          <b-form-checkbox v-model="item.check" :checked="item.check" switch></b-form-checkbox>
        </template>
      </b-table>
    </b-card>
    <b-card class="sa-card" header="CONDIZIONI DI VITA E SUPPORTO SOCIALE" header-tag="header" footer-tag="footer" title="">
      <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaCondizioniVitaSupportoSociale" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header">
        <template #cell(check)="{ item }">
          <b-form-checkbox v-model="item.check" :checked="item.check" switch></b-form-checkbox>
        </template>
      </b-table>
    </b-card>
    <b-card class="sa-card" header="STATO FUNZIONALE" header-tag="header" footer-tag="footer" title="">
      <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaStatoFunzionale" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header">
        <template #cell(check)="{ item }">
          <b-form-checkbox v-model="item.check" :checked="item.check" switch></b-form-checkbox>
        </template>
      </b-table>
    </b-card>
    <b-card class="sa-card" header="STATO COGNITIVO" header-tag="header" footer-tag="footer" title="">
      <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaStatoCognitivo" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header">
        <template #cell(check)="{ item }">
          <b-form-checkbox v-model="item.check" :checked="item.check" switch></b-form-checkbox>
        </template>
      </b-table>
    </b-card>
    <b-card class="sa-card" header="MODELLO COMPORTAMENTALE" header-tag="header" footer-tag="footer" title="">
      <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaModelloComportamentale" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header">
        <template #cell(check)="{ item }">
          <b-form-checkbox v-model="item.check" :checked="item.check" switch></b-form-checkbox>
        </template>
      </b-table>
    </b-card>
    <b-card class="sa-card" header="MOBILITA'" header-tag="header" footer-tag="footer" title="">
      <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaMobilita" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header">
        <template #cell(check)="{ item }">
          <b-form-checkbox v-model="item.check" :checked="item.check" switch></b-form-checkbox>
        </template>
      </b-table>
    </b-card>
    <b-card class="sa-card" header="DEFICIY SENSORIALE" header-tag="header" footer-tag="footer" title="">
      <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaDeficitSensoriale" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header">
        <template #cell(check)="{ item }">
          <b-form-checkbox v-model="item.check" :checked="item.check" switch></b-form-checkbox>
        </template>
      </b-table>
    </b-card>
    <b-card class="sa-card" header="NUMERO DI RICOVERI PREGRESSI AL PRONTO SOCCIRSO" header-tag="header" footer-tag="footer" title="">
      <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaNumeroRIcoveriPregressi" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header">
        <template #cell(check)="{ item }">
          <b-form-checkbox v-model="item.check" :checked="item.check" switch></b-form-checkbox>
        </template>
      </b-table>
    </b-card>
    <b-card class="sa-card" header="NUMERO DI PROBLEMI CLINICI ATTIVI" header-tag="header" footer-tag="footer" title="">
      <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaNumeroProblemiClinici" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header">
        <template #cell(check)="{ item }">
          <b-form-checkbox v-model="item.check" :checked="item.check" switch></b-form-checkbox>
        </template>
      </b-table>
    </b-card>
    <b-card class="sa-card" header="NUMERO DI FARMACI ASSUNTI" header-tag="header" footer-tag="footer" title="">
      <b-table sticky-header ref="table" stacked="sm" striped hover :items="listaNumerofarmaciAssunti" :fields="fieldsListe" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table" thead-class="hidden-header">
        <template #cell(check)="{ item }">
          <b-form-checkbox v-model="item.check" :checked="item.check" switch></b-form-checkbox>
        </template>
      </b-table>
    </b-card>
    <b-card class="sa-card" header="Totale" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <span class="sa-label-data">{{ totale }}</span>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import axios from "axios";
import UtilityMixin from "../../utility/UtilityMixin";
export default {
  components: {},
  mixins: [UtilityMixin],
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  watch: {
    listaEta: {
      handler() {
        let me = this;
        me.unisciArray();
      },
      deep: true,
    },
    listaCondizioniVitaSupportoSociale: {
      handler() {
        let me = this;
        me.unisciArray();
      },
      deep: true,
    },
    listaStatoFunzionale: {
      handler() {
        let me = this;
        me.unisciArray();
      },
      deep: true,
    },
    listaStatoCognitivo: {
      handler() {
        let me = this;
        me.unisciArray();
      },
      deep: true,
    },
    listaModelloComportamentale: {
      handler() {
        let me = this;
        me.unisciArray();
      },
      deep: true,
    },
    listaMobilita: {
      handler() {
        let me = this;
        me.unisciArray();
      },
      deep: true,
    },
    listaDeficitSensoriale: {
      handler() {
        let me = this;
        me.unisciArray();
      },
      deep: true,
    },
    listaNumeroRIcoveriPregressi: {
      handler() {
        let me = this;
        me.unisciArray();
      },
      deep: true,
    },
    listaNumeroProblemiClinici: {
      handler() {
        let me = this;
        me.unisciArray();
      },
      deep: true,
    },
    listaNumerofarmaciAssunti: {
      handler() {
        let me = this;
        me.unisciArray();
      },
      deep: true,
    },
  },
  data() {
    return {
      idPropostaAccesso: null,
      keyPropostaAccesso: 0,
      linkback: "",
      linkPrintData: "",
      childKey: 0,
      viewPai: true,
      pathResource: "/cotscalabrassrichieste",
      pathResourcePropostaAccesso: "/cot",
      totale: 0,
      eta: 0,
      jsonData: [],
      listaEta: [],
      listaCondizioniVitaSupportoSociale: [],
      listaStatoFunzionale: [],
      listaStatoCognitivo: [],
      listaModelloComportamentale: [],
      listaMobilita: [],
      listaDeficitSensoriale: [],
      listaNumeroRIcoveriPregressi: [],
      listaNumeroProblemiClinici: [],
      listaNumerofarmaciAssunti: [],
      fieldsListe: [
        {
          label: "Descrizione",
          key: "fattore",
          sortable: true,
        },
        {
          label: "",
          key: "check",
          sortable: true,
          thStyle: "width: 10rem",
          tdClass: "text-center sa-column-widh",
        },
        // {
        //   label: "",
        //   key: "action",
        //   thStyle: "width: 10rem",
        // },
      ],
    };
  },
  mounted() {
    let me = this;
    me.idPropostaAccesso = this.$route.params.id;
    me.loadScheda();
    // me.loadData();
  },
  methods: {
    loadScheda() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourcePropostaAccesso + "/" + me.idPropostaAccesso;
      axios
        .get(link)
        .then((response) => {
          me.eta = UtilityMixin.methods.calcoloEtaYearOnly(response.data.data.dataNascita, new Date(response.data.data.createDate));
          console.log(me.eta);
          me.loadData();
        })
        .catch(() => {
          me.loadData();
        });
    },
    loadData() {
      let me = this;

      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.idPropostaAccesso;
      axios
        .get(link)
        .then((response) => {
          me.jsonData = response.data.data.list;
          me.calcolaPunteggio();
          me.jsonData.forEach((element) => {
            if (element.check) {
              me.jsonData.push({ idRichiesta: me.idPropostaAccesso, idScalaBrass: element.id });
            }
            // console.log(element.gruppo);
            switch (element.gruppo) {
              case "ETA'":
                console.log(element.id);
                if (element.id === "010" && me.eta <= 55) {
                  element.check = true;
                } else if (element.id === "020" && me.eta >= 56 && me.eta <= 64) {
                  element.check = true;
                } else if (element.id === "030" && me.eta >= 65 && me.eta <= 79) {
                  element.check = true;
                } else if (element.id === "040" && me.eta >= 80) {
                  element.check = true;
                }
                me.listaEta.push(element);
                break;
              case "CONDIZIONI DI VITA E SUPPORTO SOCIALE":
                me.listaCondizioniVitaSupportoSociale.push(element);
                break;
              case "STATO FUNZIONALE":
                me.listaStatoFunzionale.push(element);
                break;
              case "STATO COGNITIVO":
                me.listaStatoCognitivo.push(element);
                break;
              case "MODELLO COMPORTAMENTALE":
                me.listaModelloComportamentale.push(element);
                break;
              case "MOBILITA'":
                me.listaMobilita.push(element);
                break;
              case "DEFICIT SENSORIALE":
                me.listaDeficitSensoriale.push(element);
                break;
              case "NUMERO DI RICOVERI PREGRESSI AL PRONTO SOCCIRSO":
                me.listaNumeroRIcoveriPregressi.push(element);
                break;
              case "NUMERO DI PROBLEMI CLINICI ATTIVI":
                me.listaNumeroProblemiClinici.push(element);
                break;
              case "NUMERO DI FARMACI ASSUNTI":
                me.listaNumerofarmaciAssunti.push(element);
                break;
            }
          });
        })
        .catch(() => {});
    },
    unisciArray() {
      let me = this;
      me.jsonData = [];
      me.jsonData = me.listaEta.concat(me.listaCondizioniVitaSupportoSociale).concat(me.listaStatoFunzionale).concat(me.listaStatoCognitivo).concat(me.listaModelloComportamentale).concat(me.listaMobilita).concat(me.listaDeficitSensoriale).concat(me.listaNumeroRIcoveriPregressi).concat(me.listaNumeroProblemiClinici).concat(me.listaNumerofarmaciAssunti);
      let lista = [];
      me.jsonData.forEach((element) => {
        if (element.check) {
          lista.push({ idRichiesta: me.idPropostaAccesso, idScalaBrass: element.id });
        }
      });
      me.calcolaPunteggio();
      this.$emit("update", lista);
    },
    calcolaPunteggio() {
      let me = this;
      me.totale = 0;
      me.jsonData.forEach((element) => {
        if (element.check) {
          me.totale = me.totale + element.punteggio;
        }
      });
    },

    setListe() {},
  },
};
</script>

<style></style>
