<template>
    <div style="height: 100%">
        <div class="sa-flexbox" style="padding-bottom: 20px">
            <div class="d-flex flex-column">
                <b-row class="sa-label-info">
                    <b-col lg="3">
                        <p>Record Totali: {{ rows }}</p>
                    </b-col>

                    <b-col lg="9">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                    </b-col>
                </b-row>
            </div>

            <div class="sa-flexbox-body">
                <div class="b-table-sticky-header">
                    <b-table sticky-header ref="table" stacked="lg" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
                        <!-- :sort-by="items.tipo" :sort-desc="false" -->
                        <template #cell(index)="row">
                            <span>{{ row.index + 1 }} </span>
                        </template>

                        <template #cell(tipo)="data">
                            <b-badge pill variant="success" v-if="data.value === 'AUSILIO'">
                                {{ data.value }}
                            </b-badge>
                            <b-badge pill variant="success" v-else-if="data.value === 'ASSISTENZA'">
                                {{ data.value }}
                            </b-badge>
                            <b-badge pill variant="info" v-else-if="data.value === 'REAZIONI_DIFESA'"> REAZIONE DI DIFESA </b-badge>
                            <b-badge pill variant="primary" v-else-if="data.value === 'SIEROLOGIA'">
                                {{ data.value }}
                            </b-badge>

                            <b-badge pill variant="primary" v-else-if="data.value === 'ALLERGIA'">
                                {{ data.value }}
                            </b-badge>

                            <b-badge pill variant="warning" v-else-if="data.value === 'ADL'">
                                {{ data.value }}
                            </b-badge>
                            <b-badge pill variant="dark" v-else-if="data.value === 'RIABILITAZIONE_TECNICA'"> RIABILITAZIONE TECNICA </b-badge>
                            <b-badge pill variant="dark" v-else-if="data.value === 'RIABILITAZIONE_OBIETTIVO'"> RIABILITAZIONE OBIETTIVO </b-badge>

                            <b-badge pill variant="warning" v-else-if="data.value === 'CUTE'">
                                {{ data.value }}
                            </b-badge>

                            <b-badge pill variant="warning" v-else-if="data.value === 'UNGHIE'">
                                {{ data.value }}
                            </b-badge>

                            <b-badge pill variant="warning" v-else-if="data.value === 'EDEMI'">
                                {{ data.value }}
                            </b-badge>
                            <b-badge pill variant="success" v-else-if="data.value === 'AUSILIO_PARAM_VITALI'"> AUSILIO PARAMETRI VITALI </b-badge>
                            <b-badge pill variant="success" v-else-if="data.value === 'MOTIVAZIONE_PRESENZE_USCITE'"> MOTIVAZIONE PRESENZE/USCITE </b-badge>
                        </template>

                        <template v-slot:cell(actions)="{ item }">
                            <b-container class="bv-example-row">
                                <b-button size="sm" v-b-tooltip.hover title="Modifica" variant="outline-success" @click="onClickEditVoceDizionario(item)" class="mr-1 no-text">
                                    <font-awesome-icon icon="edit"> </font-awesome-icon>
                                </b-button>

                                <b-button size="sm" v-b-tooltip.hover title="Elimina" variant="outline-danger" @click="onClickDeleteVoceDizionario(item)">
                                    <font-awesome-icon icon="trash"></font-awesome-icon>
                                </b-button>
                            </b-container>
                        </template>
                    </b-table>
                </div>
            </div>

            <rsa-configurazione-dizionario-modal-edit-component ref="cmpRsaDizionarioModalEdit" @onSaveVoceDizionario="onVoceDizionarioSave" @onDeleteVoceDizionario="onVoceDizionarioDelete"></rsa-configurazione-dizionario-modal-edit-component>

            <b-row class="sa-label-info">
                <b-col lg="3">
                    <p>Record Totali: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import UtilityMixin from "../../../utility/UtilityMixin";
import RsaConfigurazioneDizionarioModalEditComponent from "./RsaConfigurazioneDizionarioModalEditComponent.vue";

export default {
    components: { RsaConfigurazioneDizionarioModalEditComponent },
    mixins: [UtilityMixin],
    data() {
        return {
            rows: 0,
            perPage: 50,
            currentPage: 1,
            pathResourceDizionario: "/rsadizionario",
            pathResourceDizionarioByTipo: "/rsadizionario/bytipo",
            /* filtroTipo: null, */
            filtroDizionario: { tipo: null, page: 0, forPage: 0 },
            listKey: 0,
            items: [],
            fields: [
                { key: "index", label: "N." },
                { key: "tipo", label: "Tipo", sortable: true },
                { key: "voce", label: "Voce", sortable: true },
                { key: "note", label: "Note" },
                { key: "actions", label: "", class: "sa-table-column-action-double" },
            ],
        };
    },

    watch: {
        currentPage() {
            let me = this;
            me.loadData();
        },
    },

    mounted() {
        let me = this;
        me.loadData();
    },

    methods: {
        loadData() {
            let me = this;
            me.$emit("beforeLoadData");

            let sessionStorage = window.sessionStorage;
            if (sessionStorage["filtroDizionario"]) {
                me.filtroDizionario = JSON.parse(sessionStorage["filtroDizionario"]);
                /*  me.filtroTipo = me.filtroDizionario.tipo;
                console.log("filtro: {", me.filtroTipo, "}"); */
            }

            me.filtroDizionario.page = me.currentPage;
            me.filtroDizionario.forPage = me.perPage;
            /*  console.log("FILTRO LISTCOMPONENT", me.filtroDizionario); */

            const endpoint = me.filtroDizionario.tipo ? me.pathResourceDizionarioByTipo : me.pathResourceDizionario;

            UtilityMixin.methods.loadDataResources(endpoint, me.filtroDizionario, me.loadDizionarioDataSuccess, me.loadDizionarioDataError);
        },
        loadDizionarioDataSuccess(response) {
            let me = this;
            //Ordinamento manuale dopo aver ricevuto i dati
            /* me.items = response.data.data.list.sort((a, b) => {
                if (a.tipo === b.tipo) {
                    return a.voce.localeCompare(b.voce);
                }
                return a.tipo.localeCompare(b.tipo);
            }); */
            me.items = response.data.data.list;
            me.rows = response.data.data.recordsNumber;
            me.listKey++;
            me.$emit("afterLoadData");
        },

        loadDizionarioDataError(error) {
            let me = this;
            console.error("Errore durante il caricamento dei dati:", error);
            me.items = [];
            me.rows = 0;
            me.$emit("afterLoadData");
        },

        /* onChangeFiltroTipo() {
            let me = this;
            me.filtroDizionario.tipo = me.filtroTipo;

            let sessionStorage = window.sessionStorage;
            sessionStorage["filtroDizionario"] = JSON.stringify(me.filtroDizionario);

            me.currentPage = 1;
            me.loadData();
        }, */

        onClickEditVoceDizionario(item) {
            let me = this;
            me.$refs.cmpRsaDizionarioModalEdit.editVoceDizionario(item);
        },

        onClickNuovaVoceDizionario() {
            let me = this;
            me.$refs.cmpRsaDizionarioModalEdit.nuovaVoceDizionario();
        },

        onClickDeleteVoceDizionario(item) {
            let me = this;
            me.$refs.cmpRsaDizionarioModalEdit.onDeleteVoceDizionario(item);
        },

        onVoceDizionarioSave() {
            let me = this;
            //me.loadData(); lo rimuovo perché verrà chiamato dal componente padre
            me.$emit("onAfterSaveDizionario");
        },

        onVoceDizionarioDelete() {
            let me = this;
            me.loadData();
        },
    },
};
</script>
