import RsaConfigurazione from "..";

const routes = [
	{ name: "RasConfigurazionePostiLettoPianiList", path: "/configurazioni/rsaconfigurazionepostilettopiani", component: RsaConfigurazione.RasConfigurazionePostiLettoPianiListPage, meta: { title: "R.S.A. - Posti Letto Piani" } },
	{ name: "RasConfigurazionePostiLettoPianiView", path: "/rsaconfigurazionepostilettopiani/view/:id", component: RsaConfigurazione.RasConfigurazionePostiLettoPianiViewPage, meta: { title: "R.S.A. - Posti Letto Piani View" } },
	{ name: "RasConfigurazionePostiLettoPianiEdit", path: "/rsaconfigurazionepostilettopiani/edit/:id", component: RsaConfigurazione.RasConfigurazionePostiLettoPianiEditPage, meta: { title: "R.S.A. - Posti Letto Piani Edit" } },
	{ name: "RasConfigurazionePostiLettoStanzeList", path: "/configurazioni/rsaconfigurazionepostilettostanze", component: RsaConfigurazione.RasConfigurazionePostiLettoStanzeListPage, meta: { title: "R.S.A. - Posti Letto Stanze" } },
	{ name: "RsaConfigurazioneDiariList", path: "/configurazioni/rsaconfigurazionediari", component: RsaConfigurazione.RsaConfigurazioneDiariListPage, meta: { title: "R.S.A. - Diari" } },
	{ name: "RsaConfigurazioneDiariEdit", path: "/rsaconfigurazionediari/edit/:id", component: RsaConfigurazione.RsaConfigurazioneDiariEditPage, meta: { title: "R.S.A. - Diari Edit" } },
	{ name: "RsaConfigurazioneProfiliLisListPage", path: "/configurazioni/rsaconfigurazioneprofililis", component: RsaConfigurazione.RsaConfigurazioneProfiliLisListPage, meta: { title: "R.S.A. - Profili Lis" } },
	{ name: "RsaConfigurazioneProfiliLisViewPage", path: "/rsaconfigurazioneprofililis/view/:id", component: RsaConfigurazione.RsaConfigurazioneProfiliLisViewPage, meta: { title: "R.S.A. - Profili Lis" } },
	{ name: "RsaConfigurazioneProfiliLisEditPage", path: "/rsaconfigurazioneprofililis/edit/:id", component: RsaConfigurazione.RsaConfigurazioneProfiliLisEditPage, meta: { title: "R.S.A. - Profili Lis Edit" } },
	{ name: "RsaConfigurazioneAreeListPage", path: "/configurazioni/rsaconfigurazionearee", component: RsaConfigurazione.RsaConfigurazioneAreeListPage, meta: { title: "R.S.A. - Aree" } },
	{ name: "RsaConfigurazioneAreeEditPage", path: "/rsaconfigurazionearee/edit/:id", component: RsaConfigurazione.RsaConfigurazioneAreeEditPage, meta: { title: "R.S.A. - Aree Edit" } },
	{ name: "RsaConfigurazioneAreeViewPage", path: "/rsaconfigurazionearee/view/:id", component: RsaConfigurazione.RsaConfigurazioneAreeViewPage, meta: { title: "R.S.A. - Aree" } },
	{ name: "RsaConfigurazioneAttivitaListPage", path: "/configurazioni/rsaconfigurazioneattivita", component: RsaConfigurazione.RsaConfigurazioneAttivitaListPage, meta: { title: "R.S.A. - Attivita'" } },
	{ name: "RsaConfigurazioneAttivitaEditPage", path: "/rsaconfigurazioneattivita/edit/:id", component: RsaConfigurazione.RsaConfigurazioneAttivitaEditPage, meta: { title: "R.S.A. - Attivita Edit" } },
	{ name: "RsaConfigurazioneAttivitaViewPage", path: "/rsaconfigurazioneattivita/view/:id", component: RsaConfigurazione.RsaConfigurazioneAttivitaViewPage, meta: { title: "R.S.A. - Attivita VIew" } },
	{ name: "RsaConfigurazioneLaboratoriListPage", path: "/configurazioni/rsaconfigurazionelaboratori", component: RsaConfigurazione.RsaConfigurzioneLaboratoriListPage, meta: { title: "R.S.A. - Laboratori" } },
	{ name: "RsaConfigurazioneLaboratoriEditPage", path: "/rsaconfigurazionelaboratori/edit/:id", component: RsaConfigurazione.RsaConfigurazioneLaboratoriEditPage, meta: { title: "R.S.A. - Laboratori Edit" } },

	{ name: "RsaConfigurazioneSettingsViewPage", path: "/rsasettings/view/:id", component: RsaConfigurazione.RsaConfigurazioneSettingsViewPage, meta: { title: "R.S.A. - Settings View" } },

	{ name: "RsaConfigurazioneSettingsListPage", path: "/configurazioni/rsasettings", component: RsaConfigurazione.RsaConfigurazioneSettingsListPage },

	{ name: "RsaConfigurazioneTipoPrestazioniViewPage", path: "/rsatipoprestazioni/view/:id", component: RsaConfigurazione.RsaConfigurazioneTipoPrestazioniViewPage, meta: { title: "R.S.A. - Prestazioni" } },

	{ name: "RsaConfigurazioneTipoPrestazioniListPage", path: "/configurazioni/rsatipoprestazioni", component: RsaConfigurazione.RsaConfigurazioneTipoPrestazioniListPage, meta: { title: "R.S.A. - Prestazioni" } },

	{ name: "RsaConfigurazionePrestazioniAmbulatorialiListPage", path: "/configurazioni/rsaprestazioniambulatoriali", component: RsaConfigurazione.RsaConfigurazionePrestazioniAmbulatorialiListPage, meta: { title: "R.S.A. - Prestazioni Ambulatori" } },
	{ name: "RsaConfigurazioneSpeseExtraListPage", path: "/configurazioni/rsaspeseextra", component: RsaConfigurazione.RsaConfigurazioneSpeseExtraListPage, meta: { title: "R.S.A. - Spese Extra" } },
	{ name: "RsaPaiRuoliListPage", path: "/configurazioni/rsapairuoli", component: RsaConfigurazione.RsaPaiRuoliListPage, meta: { title: "R.S.A. - PAI Ruoli" } },

	{ name: "RsaPaiSpecializzazioneListPage", path: "/configurazioni/rsapairuolospecializzazione", component: RsaConfigurazione.RsaPaiSpecializzazioneListPage, meta: { title: "R.S.A. - PAI Specializzazione" } },

	{ name: "RsaConfigurazioneDizionarioListPage", path: "/configurazioni/rsadizionario", component: RsaConfigurazione.RsaConfigurazioneDizionarioListPage, meta: { title: "R.S.A. - Dizionario" } },
	{ name: "RsaConfigurazioneParametriVitaliPage", path: "/configurazioni/rsaconfigurazioneparametrivitali", component: RsaConfigurazione.RsaConfigurazioneParametriVitaliListPage, meta: { title: "R.S.A. - Parametri Vitali" } },
	{ name: "RsaConfigurazioneImpegnativaGruppoPrestazioneListPage", path: "/configurazioni/rsaimpegnativagruppoprestazione", component: RsaConfigurazione.RsaConfigurazioneImpegnativaGruppoPrestazioniListPage, meta: { title: "R.S.A. - Impegnative/Prestazione" } },
	{ name: "RsaConfigurazioneTurniListPage", path: "/configurazioni/rsaconfigurazioneturni", component: RsaConfigurazione.RsaConfigurazioneTurniListPage, meta: { title: "R.S.A. - Turni" } },
];

export default routes;
