<template>
    <sa-page-layout :showModalLoading="showModalLoading" :pathResource="pathResource" :btnBackVisible="true" :btnSaveVisible="true" :linkback="linkback" :data="anagrafica">
        <template slot="toolbar-title">Profilo </template>
        <template slot="table-body">
            <div class="sa-div-scroll sa-scroll">
                <br />
                <h5>Dati Utente</h5>
                <hr />
                <br />
                <b-row>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-form-label" for="anagrafica-username"> Username</label>
                        <b-form-input id="anagrafica-username" :value="utente.username" readonly></b-form-input>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-form-label" for="anagrafica-celullare"> Cellulare</label>
                        <b-form-input id="anagrafica-cellulare" v-model="anagrafica.cellulare"></b-form-input>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-form-label" for="anagrafica-email">Email</label>
                        <b-form-input id="anagrafica-email" v-model="anagrafica.email"></b-form-input>
                    </b-col>
                </b-row>
                <br />
                <h5>Dettaglio Anagrafica</h5>
                <hr />
                <br />
                <b-row>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-form-label" for="anagrafica-cognome">Cognome</label>
                        <br />
                        <b-form-input id="anagrafica-cognome" v-model="anagrafica.cognome"></b-form-input>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-form-label" for="anagrafica-nome">Nome</label>
                        <br />
                        <b-form-input id="anagrafica-nome" v-model="anagrafica.nome"></b-form-input>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-form-label" for="anagrafica-data-nascita">Data Di Nascita</label>
                        <br />
                        <date-picker id="anagrafica-data-nascita" placeholder="DD-MM-YYYY" style="display: block; width: 100%" format="DD-MM-YYYY" value-type="timestamp" v-model="anagrafica.dataNascita" type="date"></date-picker>
                    </b-col>
                </b-row>
                <br />
                <b-row>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-form-label" for="anagrafica-provincia-nascita">Provincia</label>
                        <br />
                        <b-form-select id="anagrafica-provincia-nascita" v-model="anagrafica.provinciaNascitaLabel" :options="provinceOptions" text-field="provincia" value-field="sigla" @change="onProvinciaNascitaInput(anagrafica.provinciaNascitaLabel)"></b-form-select>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-form-label" for="anagrafica-comune-nascita">Comune</label>
                        <br />
                        <b-form-select id="anagrafica-comune-nascita" v-model="anagrafica.comuneNascitaCodiceIstat" class="mb-2 mr-sm-2 mb-sm-0" :options="comuniNascitaOptions" text-field="comune" value-field="codiceIstat"></b-form-select>
                    </b-col>
                </b-row>
                <br />
                <b-row>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-form-label" for="anagrafica-tipo-identificativo">Tipo Identificativo</label>
                        <br />
                        <b-form-select id="anagrafica-tipo-identificativo" v-model="anagrafica.tipoIdentificativo" :options="tipoIdentificativoOptions" value-field="value" text-field="text"></b-form-select>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-form-label" for="anagrafica-identificativo">Identificativo</label>
                        <br />
                        <b-form-input id="anagrafica-identificativo" v-model="anagrafica.identificativo"></b-form-input>
                    </b-col>
                </b-row>
                <br />
                <h5>Domicilio</h5>
                <hr />
                <b-row>
                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                        <label class="sa-form-label" for="anagrafica-indirizzo-domicilio">Indirizzo </label>
                        <br />
                        <b-form-input id="anagrafica-indirizzo-domicilio" v-model="anagrafica.indirizzoDomicilio"></b-form-input>
                    </b-col>
                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                        <label class="sa-form-label" for="anagrafica-civico-domicilio">Civico</label>
                        <br />
                        <b-form-input id="anagrafica-civico-domicilio" v-model="anagrafica.civicoDomicilio"></b-form-input>
                    </b-col>
                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                        <label for="anagrafica-provincia-domicilio">Provincia </label>
                        <br />
                        <b-form-select id="anagrafica-provincia-domicilio" v-model="anagrafica.provinciaDomicilioLabel" :options="provinceOptions" text-field="provincia" value-field="sigla" @change="onProvinciaDomicilioInput(anagrafica.provinciaDomicilioLabel)"></b-form-select>
                    </b-col>
                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                        <label class="sa-form-label" for="anagrafica-comune-domicilio">Comune</label>
                        <br />
                        <b-form-select id="anagrafica-comune-domicilio" v-model="anagrafica.comuneDomicilioCodiceIstat" class="mb-2 mr-sm-2 mb-sm-0" :options="comuniDomicilioOptions" text-field="comune" value-field="codiceIstat" @change="onComuneDomicilioInput(anagrafica.comuneDomicilioCodiceIstat)"></b-form-select>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col xs="12" sm="12" md="3" lg="3" xl="3" class="sa-element-data">
                        <label class="sa-form-label" for="anagrafica-frazione-domicilio">Frazione</label>
                        <br />
                        <b-form-input id="anagrafica-frazione-domicilio" v-model="anagrafica.frazioneDomicilio"></b-form-input>
                    </b-col>
                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                        <label class="sa-form-label" for="anagrafica-cap-domicilio">C.A.P.</label>
                        <br />
                        <b-form-input id="anagrafica-cap-domicilio" v-model="anagrafica.capDomicilio"></b-form-input>
                    </b-col>
                </b-row>
                <br />
                <h5>Residenza</h5>
                <hr />
                <b-row>
                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                        <label class="sa-form-label" for="anagrafica-indirizzo-residenza">Indirizzo</label>
                        <br />
                        <b-form-input id="anagrafica-indirizzo-residenza" v-model="anagrafica.indirizzoResidenza"></b-form-input>
                    </b-col>
                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                        <label class="sa-form-label" for="anagrafica-civico-residenza">Civico</label>
                        <br />
                        <b-form-input id="anagrafica-civico-residenza" v-model="anagrafica.civicoResidenza"></b-form-input>
                    </b-col>
                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                        <label for="anagrafica-provincia">Provincia</label>
                        <br />
                        <b-form-select id="anagrafica-provincia-residenza" v-model="anagrafica.provinciaResidenzaLabel" :options="provinceOptions" text-field="provincia" value-field="sigla" @change="onProvinciaResidenzaInput()"></b-form-select>
                    </b-col>
                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                        <label class="sa-form-label" for="anagrafica-comune-residenza">Comune </label>
                        <br />
                        <b-form-select id="anagrafica-comune-residenza" v-model="anagrafica.comuneResidenzaCodiceIstat" class="mb-2 mr-sm-2 mb-sm-0" :options="comuniResidenzaOptions" text-field="comune" value-field="codiceIstat" @change="onComuneResidenzaInput()"></b-form-select>
                    </b-col>
                </b-row>
                <br />
                <b-row>
                    <b-col xs="12" sm="12" md="3" lg="3" xl="3" class="sa-element-data">
                        <label class="sa-form-label" for="anagrafica-frazione-residenza">Frazione </label>
                        <br />
                        <b-form-input id="anagrafica-frazione-residenza" v-model="anagrafica.frazioneResidenza"></b-form-input>
                    </b-col>

                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                        <label class="sa-form-label" for="anagrafica-cap-residenza">C.A.P.</label>
                        <br />
                        <b-form-input id="anagrafica-cap-residenza" v-model="anagrafica.capResidenza"></b-form-input>
                    </b-col>
                </b-row>
                <br />
            </div>
        </template>
    </sa-page-layout>
</template>

<script>
import DatePicker from "vue2-datepicker";
import SaPageLayout from "../../../template/layout/components/SaPageLayout.vue";
import UtilityMixins from "../../utility/UtilityMixin";
import axios from "axios";
export default {
    props: {
        utente: {
            type: Object,
            default: function () {
                return { idUtente: null };
            },
        },
    },
    components: { SaPageLayout, DatePicker },
    data() {
        return {
            pathResource: "/anagrafiche/",
            linkback: "/profilo",
            anagrafica: {},
            showModalLoading: false,
            provinceOptions: [],
            comuniResidenzaOptions: [],
            comuniNascitaOptions: [],
            comuniDomicilioOptions: [],
            tipoIdentificativoOptions: [
                { text: "Carta D'Identita'", value: "CI" },
                { text: "Codice Fiscale", value: "CF" },
                { text: "Passaporto", value: "PS" },
            ],
        };
    },
    mixins: [UtilityMixins],
    mounted() {
        let me = this;
        me.loadData();
        console.log(me.utente);
        me.loadDefalutData();
        me.username = me.utente.username;
        me.linkback = this.linkback + "/view/" + me.utente.id;
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + me.utente.id;
            axios.get(link).then((response) => {
                console.log(response.data.data);
                me.anagrafica = response.data.data;
                me.loadComuniDomicilio(me.anagrafica.provinciaDomicilioLabel);
                me.loadComuniResidenza(me.anagrafica.provinciaResidenzaLabel);
                me.loadComuniNascita(me.anagrafica.provinciaNascitaLabel);
            });
        },
        onProvinciaDomicilioInput(value) {
            let me = this;
            console.log(value);
            me.loadComuniDomicilio(value);
        },
        onProvinciaResidenzaInput(value) {
            let me = this;
            console.log(value);
            me.loadComuniResidenza(value);
        },
        onProvinciaNascitaInput(value) {
            let me = this;
            console.log(value);
            me.loadComuniNascita(value);
        },
        loadDefalutData() {
            let me = this;
            let linkProvince = process.env.VUE_APP_PATH_API + "/province";
            axios.get(linkProvince).then((response) => {
                me.provinceOptions = response.data.data;
            });
        },
        loadComuniNascita(provincia) {
            let me = this;
            let linkComuni = process.env.VUE_APP_PATH_API + "/comuni" + "/?provincia=" + provincia;
            axios.get(linkComuni).then((response) => {
                me.comuniNascitaOptions = response.data.data;
            });
        },
        loadComuniResidenza(provincia) {
            let me = this;
            let linkComuni = process.env.VUE_APP_PATH_API + "/comuni" + "/?provincia=" + provincia;
            axios.get(linkComuni).then((response) => {
                me.comuniResidenzaOptions = response.data.data;
            });
        },
        loadComuniDomicilio(provincia) {
            let me = this;
            let linkComuni = process.env.VUE_APP_PATH_API + "/comuni" + "/?provincia=" + provincia;
            axios.get(linkComuni).then((response) => {
                me.comuniDomicilioOptions = response.data.data;
            });
        },
        onComuneResidenzaInput(value) {
            let me = this;
            me.loadResidenzaCAP(value);
        },
        loadResidenzaCAP(codiceIstatComune) {
            let me = this;
            me.comuniResidenzaOptions.forEach((element) => {
                if (element.codiceIstat === codiceIstatComune) {
                    me.anagrafica.capResidenza = element.cap;
                }
            });
        },
        onComuneDomicilioInput(value) {
            let me = this;
            me.loadDomicilioCAP(value);
        },
        loadDomicilioCAP(codiceIstatComune) {
            let me = this;
            me.comuniDomicilioOptions.forEach((element) => {
                if (element.codiceIstat === codiceIstatComune) {
                    me.anagrafica.capDomicilio = element.cap;
                }
            });
        },
    },
};
</script>

<style></style>
