<template>
    <div class="sa-tab-scroll">
        <!-- InizioBody  -->
        <b-card class="sa-card" header="Dati Evento" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.events.lblEventDate") }}</label>
                    <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataEvento" type="datetime"></date-picker>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Anamnesi Familiare" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <!-- <b-col class="ft-color-section-title" xs="12" sm="12" md="6" lg="12" xl="12">
          <h5>{{ getLabelTraduora("patient.cardiology.lblFamilyAnamnesys") }}</h5>
        </b-col> -->
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHypertensiveFather") }}</label>
                    <b-form-checkbox v-model="jsonData.padreIperteso" switch :checked="jsonData.padreIperteso"></b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHypertensiveMother") }}</label>
                    <b-form-checkbox v-model="jsonData.madreIpertesa" switch :checked="jsonData.madreIpertesa"></b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHypertensiveSibilings") }}</label>

                    <b-form-checkbox v-model="jsonData.fratelliIpertesi" switch :checked="jsonData.fratelliIpertesi"></b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDiabeticRelatives") }}</label>

                    <b-form-checkbox v-model="jsonData.parentiDiabetici" switch :checked="jsonData.parentiDiabetici"></b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNephropathicRelatives") }}</label>

                    <b-form-checkbox v-model="jsonData.parentiNefropatici" switch :checked="jsonData.parentiNefropatici"></b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDyslipidemiaRelatives") }}</label>

                    <b-form-checkbox v-model="jsonData.parentidislip" switch :checked="jsonData.parentidislip"></b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIschemicRelatives") }}</label>
                    <b-form-select v-model="jsonData.parentiCardiopatiaIsch" :options="malattiaOptions">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCerebrovascularAccidentsRelatives") }}</label>
                    <b-form-select v-model="jsonData.parentiCerebrovasc" :options="malattiaOptions">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Anamnesi Personale" header-tag="header" footer-tag="footer" title="">
            <!-- <h5>{{ getLabelTraduora("patient.cardiology.lblPersonalAnamnesys") }}</h5>
      <hr /> -->
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHypertensiveYears") }}</label>

                    <b-form-input v-model="jsonData.anniIpertensione" type="number" placeholder="Anni"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">P.A. sist. prima terapia</label>
                    <b-form-input class="ft-space" v-model="jsonData.pamaxBeforeterapia" type="number" placeholder="Sistolica"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">P.A. diast. prima terapia</label>
                    <b-form-input v-model="jsonData.paminBeforeterapia" type="number" placeholder="Diastolica"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOnTherapy") }}</label>
                    <b-form-checkbox v-model="jsonData.terapiaUltimoAnno" switch :checked="jsonData.terapiaUltimoAnno"></b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCarbohydrateIntolerance") }}</label>
                    <b-form-checkbox v-model="jsonData.intolleranzaCarboidrati" switch :checked="jsonData.intolleranzaCarboidrati"></b-form-checkbox>
                </b-col>

                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDyslipidemia") }}</label>
                    <b-form-select v-model="jsonData.dislipidemia" :options="dispididemiaOptions">
                        <template #first>
                            <b-form-select-option :value="null" disabled>{{ firstElementSelect }}</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDiabetesMellitus") }}</label>
                    <b-form-checkbox v-model="jsonData.diabeteMellito" switch :checked="jsonData.diabeteMellito"></b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDiabeticYears") }}</label>
                    <b-form-input v-model="jsonData.anniDiabetici" type="number" :disabled="!jsonData.diabeteMellito" placeholder="Anni"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPregIMA") }}</label>
                    <b-form-checkbox v-model="jsonData.pregIma" switch :checked="jsonData.pregIma"></b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSede") }}</label>
                    <!-- <b-form-select v-model="jsonData.sedePregIma" :options="sedeOptions" :disabled="!jsonData.pregIma">
            <template #first>
              <b-form-select-option :value="null" disabled>{{ firstElementSelect }}</b-form-select-option>
            </template>
          </b-form-select> -->
                    <b-form-input v-model="jsonData.sedePregIma" placeholder="Sede" :disabled="!jsonData.pregIma"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPregPTCA") }}</label>
                    <b-form-checkbox v-model="jsonData.pregPtca" switch :checked="jsonData.pregPtca"></b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSede") }}</label>
                    <!-- <b-form-select v-model="jsonData.sedePregPtca" :options="sedeOptions" :disabled="!jsonData.pregPtca">
                        <template #first
                            ><b-form-select-option :value="null" disabled>{{ firstElementSelect }}</b-form-select-option>
                        </template>
                    </b-form-select> -->
                    <b-form-input v-model="jsonData.sedePregPtca" placeholder="Sede" :disabled="!jsonData.pregPtca"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPregCABG") }}</label>

                    <b-form-checkbox v-model="jsonData.pregCabg" switch :checked="jsonData.pregCabg"></b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSede") }}</label>
                    <!-- <b-form-select v-model="jsonData.sedePregCabg" :options="sedeOptions" :disabled="!jsonData.pregCabg">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select> -->
                    <b-form-input v-model="jsonData.sedePregCabg" placeholder="Sede" :disabled="!jsonData.pregCabg"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblValvulopathy") }}</label>

                    <b-form-checkbox v-model="jsonData.valvulop" switch :checked="jsonData.valvulop"></b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSede") }}</label>
                    <b-form-input v-model="jsonData.sedeValvulop" type="text" :disabled="!jsonData.valvulop" placeholder="Sede valvulopatia"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPastCrerebralIschemia") }}</label>

                    <b-form-checkbox v-model="jsonData.pregIschemia" switch :checked="jsonData.pregIschemia"></b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSede") }}</label>
                    <b-form-input v-model="jsonData.cmbPregIschemia" type="text" :disabled="!jsonData.pregIschemia" placeholder="Sede ischemie pregresse"></b-form-input>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Positivita' anamnesi" header-tag="header" footer-tag="footer" title="">
            <!-- <h5>{{ getLabelTraduora("patient.cardiology.lblAnamnesysPositivity") }}</h5>
      <hr /> -->
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRenalLithiasis") }}</label>
                    <b-form-checkbox v-model="jsonData.litiasi" switch :checked="jsonData.litiasi"></b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblUrinaryInfections") }}</label>
                    <b-form-checkbox v-model="jsonData.infezioni" switch :checked="jsonData.infezioni"></b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAlcoholAbuse") }}</label>
                    <b-form-checkbox v-model="jsonData.alcool" switch :checked="jsonData.alcool"></b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLicoriceAbuse") }}</label>
                    <b-form-checkbox v-model="jsonData.liquirizia" switch :checked="jsonData.liquirizia"></b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNoseSpray") }}</label>
                    <b-form-checkbox v-model="jsonData.sprayNasali" switch :checked="jsonData.sprayNasali"></b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLipidloweringDrugs") }}</label>
                    <b-form-checkbox v-model="jsonData.ipolipemizzanti" switch :checked="jsonData.ipolipemizzanti"></b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMenoAndroPause") }}</label>
                    <b-form-checkbox v-model="jsonData.menopausa" switch :checked="jsonData.menopausa"></b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOralContraceptives") }}</label>
                    <b-form-checkbox v-model="jsonData.contraccettivi" switch :checked="jsonData.contraccettivi"></b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPregnancyHypertension") }}</label>
                    <b-form-checkbox v-model="jsonData.ipertGravidanz" switch :checked="jsonData.ipertGravidanz"></b-form-checkbox>
                </b-col>
            </b-row>

            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAlcoholConsumption") }}</label>
                    <b-form-select v-model="jsonData.consumoDiAlcool" :options="alcoholOptions">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblQualification") }}</label>

                    <b-form-select v-model="jsonData.titoloDiStudio" :options="studioOptions">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblWorkActivity") }}</label>
                    <b-form-select v-model="jsonData.attivitaLavorativa" :options="workOptions">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIrrascibilityWork") }}</label>

                    <b-form-select v-model="jsonData.problemiLavoro" :options="siNoOptions">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPersonalMatters") }}</label>

                    <b-form-select v-model="jsonData.problemiCasa" :options="siNoOptions">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Livelli di consumo di alimenti particolari nella dieta" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <!-- <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <h5>{{ getLabelTraduora("patient.cardiology.lblDietActivityHabits") }}</h5>
          <hr />
        </b-col> -->
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblFruit") }}</label>
                    <b-form-input v-model="jsonData.frutta" type="text" placeholder="Frutta"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblFish") }}</label>
                    <b-form-input v-model="jsonData.pesce" type="text" placeholder="Pesce"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOil") }}</label>
                    <b-form-input v-model="jsonData.olio" type="text" placeholder="Olio"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCheese") }}</label>
                    <b-form-input v-model="jsonData.formaggio" type="text" placeholder="Formaggio"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPhysicalActivity") }}</label>
                    <b-form-input v-model="jsonData.attivitaFisica" type="text" placeholder="Attivita fisica"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPersonality") }}</label>
                    <!-- <b-form-select v-model="jsonData.personalita" :options="personalitaOptions"></b-form-select> -->
                    <b-form-input v-model="jsonData.personalita" type="text" placeholder="Personalita'"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOther") }}</label>
                    <b-form-textarea v-model="jsonData.altro" type="text" no-resize placeholder="Altro"></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import axios from "axios";
import UtilityMixin from "../../../../../utility/UtilityMixin";
export default {
    components: { DatePicker },
    mixins: [UtilityMixin],
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
        jsonData: {
            type: Object,
            default: function () {
                return {
                    dataEvento: null,
                    parentiCardiopatiaIsch: null,
                    parentiCerebrovasc: null,
                    dislipidemia: null,
                    sedePregIma: null,
                    sedePregPtca: null,
                    sedePregCabg: null,
                    consumoDiAlcool: null,
                    attivitaLavorativa: null,
                    problemiLavoro: null,
                    problemiCasa: null,
                    titoloDiStudio: null,
                };
            },
        },
    },
    data() {
        return {
            linkback: null,
            tabIndex: 0,
            childKey: 0,
            pathResource: "/cardioanamnesi",
            showModalLoading: false,
            id: "-1",
            firstElementSelect: this.getLabelTraduora("patient.ecoreferting.lblSelected"),
            personalitaOptions: [],
            malattiaOptions: [
                { text: this.getLabelTraduora("patient.gastro.Options.No"), value: this.getLabelTraduora("patient.gastro.Options.No") },
                { text: this.getLabelTraduora("patient.gastro.Options.Yes"), value: this.getLabelTraduora("patient.gastro.Options.Yes") },
                { text: this.getLabelTraduora("patient.cardiology.optionsIctus"), value: this.getLabelTraduora("patient.cardiology.optionsIctus") },
                { text: this.getLabelTraduora("patient.cardiology.optionsIctusNot"), value: this.getLabelTraduora("patient.cardiology.optionsIctusNot") },
                { text: "TIA", value: "TIA" },
                { text: this.getLabelTraduora("patient.cardiology.optionsLiving"), value: this.getLabelTraduora("patient.cardiology.optionsLiving") },
                { text: this.getLabelTraduora("patient.cardiology.optionsDeceased"), value: this.getLabelTraduora("patient.cardiology.optionsDeceased") },
            ],
            dispididemiaOptions: [
                { text: this.getLabelTraduora("patient.gastro.Options.No"), value: this.getLabelTraduora("patient.gastro.Options.No") },
                { text: this.getLabelTraduora("patient.cardiology.optionsHypertriglycemic"), value: this.getLabelTraduora("patient.cardiology.optionsHypertriglycemic") },
                { text: this.getLabelTraduora("patient.cardiology.optionsHypercholesterolemia"), value: this.getLabelTraduora("patient.cardiology.optionsHypercholesterolemia") },
                { text: this.getLabelTraduora("patient.cardiology.optionsMixed"), value: this.getLabelTraduora("patient.cardiology.optionsMixed") },
            ],
            sedeOptions: [
                { text: this.getLabelTraduora("patient.cardiology.optionsSedeFront"), value: this.getLabelTraduora("patient.cardiology.optionsSedeFront") },
                { text: this.getLabelTraduora("patient.cardiology.optionsSedeInferior"), value: this.getLabelTraduora("patient.cardiology.optionsSedeInferior") },
                { text: this.getLabelTraduora("patient.cardiology.optionsSedeLateral"), value: this.getLabelTraduora("patient.cardiology.optionsSedeLateral") },
                { text: this.getLabelTraduora("patient.cardiology.optionsSedeApical"), value: this.getLabelTraduora("patient.cardiology.optionsSedeApical") },
                { text: this.getLabelTraduora("patient.cardiology.optionsSedeSeptal"), value: this.getLabelTraduora("patient.cardiology.optionsSedeSeptal") },
            ],
            siNoOptions: [
                { text: this.getLabelTraduora("patient.gastro.Options.Yes"), value: this.getLabelTraduora("patient.gastro.Options.Yes") },
                { text: this.getLabelTraduora("patient.gastro.Options.No"), value: this.getLabelTraduora("patient.gastro.Options.No") },
            ],
            workOptions: [
                { text: this.getLabelTraduora("patient.cardiology.lblSedentary"), value: this.getLabelTraduora("patient.cardiology.lblSedentary") },
                { text: this.getLabelTraduora("patient.cardiology.optionsLavoroModerate"), value: this.getLabelTraduora("patient.cardiology.optionsLavoroModerate") },
                { text: this.getLabelTraduora("patient.cardiology.optionsLavoroStressful"), value: this.getLabelTraduora("patient.cardiology.optionsLavoroStressful") },
                { text: this.getLabelTraduora("patient.cardiology.optionsLavoroShiftWorker"), value: this.getLabelTraduora("patient.cardiology.optionsLavoroShiftWorker") },
            ],
            studioOptions: [
                { text: this.getLabelTraduora("patient.cardiology.optionsStudyNobody"), value: this.getLabelTraduora("patient.cardiology.optionsStudyNobody") },
                { text: this.getLabelTraduora("patient.cardiology.optionsStudyElementarySchool"), value: this.getLabelTraduora("patient.cardiology.optionsStudyElementarySchool") },
                { text: this.getLabelTraduora("patient.cardiology.optionsStudySecondarySchool"), value: this.getLabelTraduora("patient.cardiology.optionsStudySecondarySchool") },
                { text: this.getLabelTraduora("patient.cardiology.optionsStudyHighSchool"), value: this.getLabelTraduora("patient.cardiology.optionsStudyHighSchool") },
                { text: this.getLabelTraduora("patient.cardiology.optionsAcademicDiploma"), value: this.getLabelTraduora("patient.cardiology.optionsAcademicDiploma") },
                { text: this.getLabelTraduora("patient.cardiology.optionsDegree"), value: this.getLabelTraduora("patient.cardiology.optionsDegree") },
            ],
            alcoholOptions: [
                { text: "<1/2 Lt", value: "<1/2 Lt" },
                { text: "1/2 Lt", value: "1/2 Lt" },
                { text: "1-2 Lt", value: "1-2 Lt" },
                { text: ">2 Lt", value: ">2 Lt" },
            ],
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                axios.get(link).then((response) => {
                    this.$emit("update", response.data.data);
                    me.showModalLoading = false;
                });
            }
        },
    },
};
</script>

<style></style>
