<template>
    <sa-page-layout :btnRefreshVisible="false" :toolbarVisible="false">
        <template slot="table-body">
            <button-menu-component :buttonsMenu="buttonsMenu"></button-menu-component>
        </template>
    </sa-page-layout>
</template>

<script>
import ButtonMenuComponent from "../../components/ButtonMenuComponent.vue";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
    components: { SaPageLayout, ButtonMenuComponent },
    data() {
        return {
            pathResource: null,
            buttonsMenu: [
                { key: 1, text: "Elaborazioni", icon: "bi bi-gear", click: () => this.onClick("elaborazionibatch/elaborazioni") },
                { key: 2, text: "Files Estratti", icon: "bi bi-card-checklist", click: () => this.onClick("elaborazionibatch/files") },
            ],
        };
    },
    methods: {
        onRefresh() {},
        onClick(page) {
            this.$router.push(page);
        },
    },
};
</script>

<style></style>
