import StrutturePresidi from "..";

const routes = [
  {
    name: "StrutturePresidiList",
    path: "/strutturapresidi",
    component: StrutturePresidi.StrutturePresidiListPage,
    meta: { title: "Struttura - Presidi" },
  },
  {
    name: "StrutturePresidiEdit",
    path: "/strutturapresidi/edit/:id",
    component: StrutturePresidi.StrutturePresidiEditPage,
    meta: { title: "Struttura - Presidi" },
  },
  {
    name: "StrutturePresidiView",
    path: "/strutturapresidi/view/:id",
    component: StrutturePresidi.StrutturePresidiViewPage,
    meta: { title: "Struttura - Presidi" },
  },
];

export default routes;
