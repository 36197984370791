<template>
    <div>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Tipo Caso</label>
                    <span class="sa-data">{{ jsonData.casoSospetto }}</span>
                </b-col>
                <!-- <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Nazione Residenza Diversa dall'italia</label>
          <span class="sa-data">{{ jsonData.nazioneResidenza }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Si trova in Italia da più di 21 giorni</label>
          <span class="sa-data">{{ jsonData.soggiornoItaliaVentunoGiorniItalia }}</span>
        </b-col> 
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Vive in un campo nomadi</label>
          <span class="sa-data">{{ jsonData.viveCampoNomadi }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Vive in un centro di accoglienza per migranti</label>
          <span class="sa-data">{{ jsonData.viveCentroAccogglienzaMigranti }}</span>
        </b-col>-->
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">E' un operatore sanitario</label>
                    <span class="sa-data">{{ jsonData.operatoreSanitario }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Tipo Operatore</label>
                    <span class="sa-data">{{ jsonData.operatoreSanitarioTipo }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">E' un operatore scolastico</label>
                    <span class="sa-data">{{ jsonData.operatoreScolastico }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Collettivita Frequentate" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Scuola:</label>
                    <span class="sa-data">{{ jsonData.colletivitaFrequentataScuola }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Tipo:</label>
                    <span class="sa-data">{{ jsonData.colletivitaFrequentataScuolaTipo }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Nome:</label>
                    <span class="sa-data">{{ jsonData.colletivitaFrequentataScuolaNome }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Comune:</label>
                    <span class="sa-data">{{ jsonData.colletivitaFrequentataScuolaComune }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Ospedale:</label>
                    <span class="sa-data">{{ jsonData.colletivitaFrequentataOspedale }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Tipo:</label>
                    <span class="sa-data">{{ jsonData.colletivitaFrequentataOspedaleTipo }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Nome:</label>
                    <span class="sa-data">{{ jsonData.colletivitaFrequentataOspedaleNome }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Comune:</label>
                    <span class="sa-data">{{ jsonData.colletivitaFrequentataOspedaleComune }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Struttura Recettiva:</label>
                    <span class="sa-data">{{ jsonData.colletivitaFrequentataStrutturaRecettiva }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Tipo:</label>
                    <span class="sa-data">{{ jsonData.colletivitaFrequentataStrutturaRecettivaTipo }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Nome:</label>
                    <span class="sa-data">{{ jsonData.colletivitaFrequentataStrutturaRecettivaNome }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Comune:</label>
                    <span class="sa-data">{{ jsonData.colletivitaFrequentataStrutturaRecettivaComune }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Campo Nomadi:</label>
                    <span class="sa-data">{{ jsonData.colletivitaFrequentataCampoNomadi }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Nome:</label>
                    <span class="sa-data">{{ jsonData.colletivitaFrequentataCampoNomadiNome }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Comune:</label>
                    <span class="sa-data">{{ jsonData.colletivitaFrequentataCampoNomadiComune }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Altro:</label>
                    <span class="sa-data">{{ jsonData.colletivitaFrequentataAltro }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Nome:</label>
                    <span class="sa-data">{{ jsonData.colletivitaFrequentataAltroNome }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Comune:</label>
                    <span class="sa-data">{{ jsonData.colletivitaFrequentataAltroComune }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="STATO VACCINALE" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Precedente vaccinazione contro il morbillo</label>
                    <span class="sa-data">{{ jsonData.precedenteVaccinazioneMorbillio }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Numero Dosi</label>
                    <span class="sa-data">{{ jsonData.numeroDosiVaccinoMorbillo }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Data ultima dose</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataUltimaDoseMorbillo) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Vaccinazione contro il morbillo verificata da certificato vaccinale</label>
                    <span class="sa-data">{{ jsonData.vaccinazioneMorbilloCertificata }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Precedente vaccinazione contro la rosolia</label>
                    <span class="sa-data">{{ jsonData.precedenteVaccinazioneRosolia }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Data ultima dose</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataUltimaDoseRosolia) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Vaccinazione contro la rosolia verificata da certificato vaccinale</label>
                    <span class="sa-data">{{ jsonData.vaccinazioneRosoliaCertificata }}</span>
                </b-col>
                <!-- <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Vaccinazione post esposizione</label>
          <span class="sa-data">{{ jsonData.vaccunazuinePostEsposizione }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Entro 72 ore dall'esposizione</label>
          <span class="sa-data">{{ jsonData.vaccunazuinePostEsposizioneSettantadueOre }}</span>
        </b-col> -->
                <!-- <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Somministrazione immunoglobuine post-esposizione</label>
          <span class="sa-data">{{ jsonData.somministrazioneImmuniglobulinePostEsposizione }}</span>
        </b-col> -->
            </b-row>
        </b-card>
        <b-card class="sa-card" header="DATI CLINICI" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Nazione Primi Sintomi</label>
                    <span class="sa-data">{{ jsonData.nazionePrimiSintomiDescrizione }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Provincia Primi Sintomi</label>
                    <span class="sa-data">{{ jsonData.provinciaPrimiSintomi }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Comune Primi Sintomi</label>
                    <span class="sa-data">{{ jsonData.comunePrimiSintomiDescrizione }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Esantema maculo papulare</label>
                    <span class="sa-data">{{ jsonData.esantemaMaculoPapulare }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Data Comparsa Esantema</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataComparsaEsantema) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Febbre</label>
                    <span class="sa-data">{{ jsonData.febbre }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Data Comparsa Febbre</label>
                    <span class="sa-data">{{ formatDate(jsonData.febbreDataComparsa) }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Rinite</label>
                    <span class="sa-data">{{ jsonData.rinite }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Congiuntivite</label>
                    <span class="sa-data">{{ jsonData.congiuntivite }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Tosse</label>
                    <span class="sa-data">{{ jsonData.tosse }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Adenopatia</label>
                    <span class="sa-data">{{ jsonData.adenopatia }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Artralgia</label>
                    <span class="sa-data">{{ jsonData.artralgia }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Artrite</label>
                    <span class="sa-data">{{ jsonData.artrite }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Specificare altri sintomi</label>
                    <span class="sa-data">{{ jsonData.altriSintomi }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Patologie di base o immunodepressive</label>
                    <span class="sa-data">{{ jsonData.patologieBaseImmunodepressive }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12" style="margin-top: 5px">
                    <b-table sticky-header stacked="xl" striped hover itemscope :items="jsonData.listaPatologie" :fields="fieldsPatologie" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="row">
                            {{ row.index + 1 }}
                        </template>
                    </b-table>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">In stato di gravidanza</label>
                    <span class="sa-data">{{ jsonData.gravidanza }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Data ultima mestruazione</label>
                    <span class="sa-data">{{ formatDate(jsonData.gravidanzaDataUltimeMestruazioni) }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Sono stati effettuati esami di laboratorio per la diagnosi di morbillo</label>
                    <span class="sa-data">{{ jsonData.esamiLaboratorioDiagnosiMorbillo }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Sono stati effettuati esami di laboratorio per la diagnosi di rosolia</label>
                    <span class="sa-data">{{ jsonData.esamiLaboratorioDiagnosiRosolia }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card v-if="!confermeLaboratorioMorbilloDisabled" class="sa-card" header="CONFERMA DI LABORATORIO - MORBILLO" header-tag="header" footer-tag="footer" title="">
            <indagine-epidemiologica-conferme-laboratorio-morbillo-rosolia-edit-component :confermaLaboratorio="jsonData.listaConfermeLaboratorio[0]" :isEdit="false"></indagine-epidemiologica-conferme-laboratorio-morbillo-rosolia-edit-component>
        </b-card>
        <b-card v-if="!confermeLaboratorioRosoliaDisabled" class="sa-card" header="CONFERMA DI LABORATORIO - ROSOLIA" header-tag="header" footer-tag="footer" title="">
            <indagine-epidemiologica-conferme-laboratorio-morbillo-rosolia-edit-component :confermaLaboratorio="jsonData.listaConfermeLaboratorio[1]" :isEdit="false"></indagine-epidemiologica-conferme-laboratorio-morbillo-rosolia-edit-component>
        </b-card>
        <b-card class="sa-card" header="INVIO CAMPIONE AL LABORATORIO" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Invio campione al laboratorio Periferico</label>
                    <span class="sa-data">{{ jsonData.invioCampioniLaboratorioPeriferico }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data invio laboratorio Periferico</label>
                    <span class="sa-data">{{ formatDate(jsonData.invioCampioniLaboratorioPerifericoData) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Invio campione al laboratorio Regionale</label>
                    <span class="sa-data">{{ jsonData.invioCampioniLaboratorioRegionale }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data invio laboratorio Regionale</label>
                    <span class="sa-data">{{ formatDate(jsonData.invioCampioniLaboratorioRegionaleData) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Invio campione al laboratorio Nazionale</label>
                    <span class="sa-data">{{ jsonData.invioCampioniLaboratorioNazionale }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data invio laboratorio Nazionale</label>
                    <span class="sa-data">{{ formatDate(jsonData.invioCampioniLaboratorioNazionaleData) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="COMPLICANZE" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Complicanze</label>
                    <span class="sa-data">{{ jsonData.complicanze }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Otite</label>
                    <span class="sa-data">{{ jsonData.otite }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Polmonite</label>
                    <span class="sa-data">{{ jsonData.polmonite }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Insufficienza Respiratoria</label>
                    <span class="sa-data">{{ jsonData.insufficienzaRespiratioria }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Diarrea</label>
                    <span class="sa-data">{{ jsonData.diarrea }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Disidratazione</label>
                    <span class="sa-data">{{ jsonData.disidratazione }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Stomatite</label>
                    <span class="sa-data">{{ jsonData.stomatite }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Trombocitopenia</label>
                    <span class="sa-data">{{ jsonData.trombocitopenia }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Cherato-congiuntivite </label>
                    <span class="sa-data">{{ jsonData.cheratoCongiuntivite }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Laringotracheobronchite</label>
                    <span class="sa-data">{{ jsonData.laringotracheobronchite }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Encefalite Acuta</label>
                    <span class="sa-data">{{ jsonData.encefaliteAcuta }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Convulsioni</label>
                    <span class="sa-data-justify">{{ jsonData.convulsioni }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Epatite/Aumento Transaminasi</label>
                    <span class="sa-data">{{ jsonData.epatiteAumentoTransaminasi }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Bronchite</label>
                    <span class="sa-data">{{ jsonData.bronchite }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Specificare Altri Sintomi</label>
                    <span class="sa-data-justify">{{ jsonData.complicanzeAltriSintomi }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="ESITO" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Esito</label>
                    <span class="sa-data">{{ jsonData.esito }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Data Decesso</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataDecesso) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Causa Decesso</label>
                    <span class="sa-data">{{ jsonData.causaDecesso }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Reliquati</label>
                    <span class="sa-data">{{ jsonData.reliquati }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="RICOVERO PER MORBILLO/ROSOLIA" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Ricovero</label>
                    <span class="sa-data">{{ jsonData.ricovero }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Ospedale</label>
                    <span class="sa-data">{{ jsonData.ospedale }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Data Ricovero</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataRicovero) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Data Dimissione</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataDimissioni) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="VIAGGI" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Ha effettuato viaggi nei 7-23 giorni precedenti l'inizio dell'esantema</label>
                    <span class="sa-data">{{ jsonData.viaggiGiorniPrecedentiInizioEsantema }}</span>
                </b-col>
            </b-row>
            <b-row style="margin-top: 5px">
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-table sticky-header stacked="xl" striped hover itemscope :items="jsonData.listaViaggi" :fields="fieldsViaggi" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="row">
                            {{ row.index + 1 }}
                        </template>
                    </b-table>
                </b-col>
            </b-row>
        </b-card>
        <!-- ORIGINE DEL CONTAGIO DA CONTROLLARE -->
        <b-card class="sa-card" header="ORIGINE DEL CONTAGIO" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Asilo nido/Scuola</label>
                    <span class="sa-data">{{ jsonData.origineContagioAsiloNidoScuola }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Ambulatorio medico/Sala d’attesa</label>
                    <span class="sa-data">{{ jsonData.origineContagioAmbulatorioSalaAttesa }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Ambulatorio in ospedale/Sala d’attesa</label>
                    <span class="sa-data">{{ jsonData.origineContagioOspedaleSalaAttesa }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Pronto Soccorso</label>
                    <span class="sa-data">{{ jsonData.origineContagioProntoSoccorso }}</span>
                </b-col>
                <!-- <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Lavoro</label>
          <span class="sa-data">{{ jsonData.origineContagioLavoro }}</span>
        </b-col> -->
                <!-- <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Università</label>
          <span class="sa-data">{{ jsonData.origineContagioUniversita }}</span>
        </b-col> -->
                <!-- <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Carcere</label>
          <span class="sa-data">{{ jsonData.origineContagioCarcere }}</span>
        </b-col> -->
                <!-- <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Chiesa</label>
          <span class="sa-data">{{ jsonData.origineContagioChiesa }}</span>
        </b-col> -->
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Casa/Famiglia</label>
                    <span class="sa-data">{{ jsonData.origineContagioCasaFamiglia }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Struttura Recettiva</label>
                    <span class="sa-data">{{ jsonData.origineContagioStrutturaRecettiva }}</span>
                </b-col>
                <!-- <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Ambiente Militare</label>
          <span class="sa-data">{{ jsonData.origineContagioAmbienteMilitare }}</span>
        </b-col> -->
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Campo Nomadi</label>
                    <span class="sa-data">{{ jsonData.origineContagioCampoNomadi }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Viaggio Internazionale</label>
                    <span class="sa-data">{{ jsonData.origineContagioViaggioInternazionale }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Non Noto</label>
                    <span class="sa-data">{{ jsonData.origineContagioNonNoto }}</span>
                </b-col>
                <!-- <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Reparto Ospedale</label>
          <span class="sa-data">{{ jsonData.origineContagioRepartoOspedale }}</span>
        </b-col> -->
                <!-- <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Motivo</label>
          <span class="sa-data">{{ jsonData.origineContagioRepartoOspedaleMotivazione }}</span>
        </b-col> -->
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Altro</label>
                    <span class="sa-data-justify">{{ jsonData.origineContagioAltro }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">In quale località è avvenuto il contagio</label>
                    <span class="sa-data">{{ jsonData.localitaContagio }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Specificare altra regione o nazione</label>
                    <span class="sa-data">{{ jsonData.altraRegioneNazione }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Il caso fa parte di un focolaio</label>
                    <span class="sa-data">{{ jsonData.parteFocolaio }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Nome focolaio</label>
                    <span class="sa-data">{{ jsonData.nomeFocolaio }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Descrizione Focolaio</label>
                    <span class="sa-data-justify">{{ jsonData.descrizioneFocolaio }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Il paziente è stato a contatto con un altro caso confermato di morbillo (entro 7-18 giorni) o di rosolia (entro 12-23 giorni) prima dell’esordio dell’esantema?</label>
                    <span class="sa-data">{{ jsonData.contattoCasoConfermatoMorbilloRosolia }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Nominativo</label>
                    <span class="sa-data">{{ jsonData.contattoCasoConfermatoMorbilloRosoliaNominativo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Il contatto in questione è un caso importato</label>
                    <span class="sa-data">{{ jsonData.contattoCasoConfermatoMorbilloRosoliaImportato }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Specificare nazione</label>
                    <span class="sa-data">{{ jsonData.contattoCasoConfermatoMorbilloRosoliaImportatoNazione }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
                    <label class="sa-label-data">Nel periodo di contagiosità il paziente è stato a contatto ocn una donna in gravidanza</label>
                    <span class="sa-data">{{ jsonData.contattoDonnaGravidanza }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Nominativo</label>
                    <span class="sa-data">{{ jsonData.contattoDonnaGravidanzaNominativo }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="CLASSIFICAZIONE DEL CASO" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Caso di</label>
                    <span class="sa-data">{{ jsonData.classificazioneCasoDi }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Classificazione finale del caso</label>
                    <span class="sa-data">{{ jsonData.classificazioneFinale }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Caso</label>
                    <span class="sa-data">{{ jsonData.classificazioneCaso }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Altre Considerazioni</label>
                    <span class="sa-data">{{ jsonData.altreConsiderazioni }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Contatti" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-table sticky-header ref="table" stacked="xl" striped hover :items="jsonData.listaContatti" :fields="fieldsContatti" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="listaContatti">
                            {{ listaContatti.index + 1 }}
                        </template>
                        <template v-slot:cell(codiceFiscale)="{ item }">
                            <a>{{ item.codiceFiscale }}</a>
                        </template>
                    </b-table>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="INFORMAZIONI SULLA RACCOLTA DATI" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Sanitario Notificante</label>
                    <span class="sa-data">{{ jsonData.sanitarioNotificante }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Telefono Sanitario Notificante</label>
                    <span class="sa-data">{{ jsonData.sanitarioNotificanteContatto }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Notifica</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataNotifica) }}</span>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import UtilityMixin from "../../../utility/UtilityMixin";
import IndagineEpidemiologicaConfermeLaboratorioMorbilloRosoliaEditComponent from "./IndagineEpidemiologicaConfermeLaboratorioMorbilloRosoliaEditComponent.vue";
export default {
    components: { IndagineEpidemiologicaConfermeLaboratorioMorbilloRosoliaEditComponent },
    mixins: [UtilityMixin],
    data() {
        return {
            pathResource: "/malattieinfettiveiemorbillorosolia",
            currentPage: 1,
            perPage: 100,
            confermeLaboratorioMorbilloDisabled: false,
            confermeLaboratorioRosoliaDisabled: false,
            jsonData: {
                casoSospetto: "",
                nazioneResidenza: "",
                soggiornoItaliaVentunoGiorniItalia: "",
                viveCentroAccogglienzaMigranti: "",
                viveCampoNomadi: "",
                operatoreSanitario: "",
                operatoreSanitarioTipo: "",
                operatoreScolastico: "",
                precedenteVaccinazioneMorbillio: "",
                numeroDosiVaccinoMorbillo: "",
                dataUltimaDoseMorbillo: null,
                vaccinazioneMorbilloCertificata: "",
                precedenteVaccinazioneRosolia: "",
                dataUltimaDoseRosolia: null,
                vaccinazioneRosoliaCertificata: "",
                vaccunazuinePostEsposizione: "",
                vaccunazuinePostEsposizioneSettantadueOre: "",
                somministrazioneImmuniglobulinePostEsposizione: "",
                nazionePrimiSintomi: "",
                provinciaPrimiSintomi: "",
                comunePrimiSintomi: "",
                esantemaMaculoPapulare: "",
                dataComparsaEsantema: null,
                febbre: "",
                febbreDataComparsa: null,
                rinite: "",
                congiuntivite: "",
                tosse: "",
                adenopatia: "",
                artralgia: "",
                artrite: "",
                altriSintomi: "",
                patologieBaseImmunodepressive: "",
                listaPatologie: [],
                gravidanza: "",
                gravidanzaDataUltimeMestruazioni: null,
                esamiLaboratorioDiagnosiMorbillo: "",
                esamiLaboratorioDiagnosiRosolia: "",
                listaConfermeLaboratorio: [
                    { malattia: "MORBILLO", ricercaIgm: "", metodo: "", dataRaccoltaCampione: null, esitoIgm: "", tipoCampioneIgm: "", campioneAltro: "", ricercaIgg: "", metod: "", dataRaccoltaCampioneFaseAcuta: null, dataRaccoltaCampioneFaseConvalescente: null, esitoIgg: "", tipoCampioneIgg: "", tipoCampioneIggAltro: "", pcr: "", dataRaccoltaCampionePcr: null, esitoPcr: "", tipoCampionePcr: "", tipoCampionePcrAltro: "", genotipizzazione: "", genotipo: "" },
                    { malattia: "ROSOLIA", ricercaIgm: "", metodo: "", dataRaccoltaCampione: null, esitoIgm: "", tipoCampioneIgm: "", campioneAltro: "", ricercaIgg: "", metod: "", dataRaccoltaCampioneFaseAcuta: null, dataRaccoltaCampioneFaseConvalescente: null, esitoIgg: "", tipoCampioneIgg: "", tipoCampioneIggAltro: "", pcr: "", dataRaccoltaCampionePcr: null, esitoPcr: "", tipoCampionePcr: "", tipoCampionePcrAltro: "", genotipizzazione: "", genotipo: "" },
                ],
                invioCampioniLaboratorioPeriferico: "",
                invioCampioniLaboratorioPerifericoData: null,
                invioCampioniLaboratorioRegionale: "",
                invioCampioniLaboratorioRegionaleData: null,
                invioCampioniLaboratorioNazionale: "",
                invioCampioniLaboratorioNazionaleData: null,
                complicanze: "",
                otite: "",
                polmonite: "",
                insufficienzaRespiratioria: "",
                diarrea: "",
                disidratazione: "",
                stomatite: "",
                trombocitopenia: "",
                cheratoCongiuntivite: "",
                laringotracheobronchite: "",
                encefaliteAcuta: "",
                convulsioni: "",
                epatiteAumentoTransaminasi: "",
                bronchite: "",
                complicanzeAltriSintomi: "",
                esito: "",
                dataDecesso: null,
                causaDecesso: "",
                reliquati: "",
                ricovero: "",
                ospedale: "",
                dataRicovero: null,
                dataDimissioni: null,
                viaggiGiorniPrecedentiInizioEsantema: "",
                listaViaggi: [],
                origineContagioAsiloNidoScuola: "",
                origineContagioAmbulatorioSalaAttesa: "",
                origineContagioRepartoOspedale: "",
                origineContagioRepartoOspedaleMotivazione: "",
                origineContagioProntoSoccorso: "",
                origineContagioLavoro: "",
                origineContagioUniversita: "",
                origineContagioCarcere: "",
                origineContagioChiesa: "",
                origineContagioCasaFamiglia: "",
                origineContagioStrutturaRecettiva: "",
                origineContagioAmbienteMilitare: "",
                origineContagioCampoNomadi: "",
                origineContagioViaggioInternazionale: "",
                origineContagioNonNoto: "",
                origineContagioAltro: "",
                localitaContagio: "",
                altraRegioneNazione: "",
                parteFocolaio: "",
                nomeFocolaio: "",
                descrizioneFocolaio: "",
                contattoCasoConfermatoMorbilloRosolia: "",
                contattoCasoConfermatoMorbilloRosoliaImportato: "",
                contattoCasoConfermatoMorbilloRosoliaImportatoNazione: "",
                contattoDonnaGravidanza: "",
                classificazioneCasoDi: "",
                classificazioneFinale: "",
                classificazioneCaso: "",
                sanitarioNotificante: "",
                sanitarioNotificanteContatto: "",
                dataNotifica: null,
                colletivitaFrequentataScuola: "",
                colletivitaFrequentataScuolaTipo: "",
                colletivitaFrequentataScuolaNome: "",
                colletivitaFrequentataScuolaComune: "",
                colletivitaFrequentataOspedale: "",
                colletivitaFrequentataOspedaleTipo: "",
                colletivitaFrequentataOspedaleNome: "",
                colletivitaFrequentataOspedaleComune: "",
                colletivitaFrequentataStrutturaRecettiva: "",
                colletivitaFrequentataStrutturaRecettivaTipo: "",
                colletivitaFrequentataStrutturaRecettivaNome: "",
                colletivitaFrequentataStrutturaRecettivaComune: "",
                colletivitaFrequentataCampoNomadi: "",
                colletivitaFrequentataCampoNomadiTipo: "",
                colletivitaFrequentataCampoNomadiNome: "",
                colletivitaFrequentataCampoNomadiComune: "",
                colletivitaFrequentataAltro: "",
                colletivitaFrequentataAltroNome: "",
                colletivitaFrequentataAltroComune: "",
                contattoCasoConfermatoMorbilloRosoliaNominativo: "",
                contattoDonnaGravidanzaNominativo: "",
            },
            fieldsPatologie: [
                {
                    label: "",
                    key: "index",
                    thStyle: "width: 2rem",
                },
                {
                    label: "Patologia",
                    key: "patologia",
                },
            ],
            fieldsViaggi: [
                {
                    label: "",
                    key: "index",
                    thStyle: "width: 2rem",
                },
                {
                    label: "Dove",
                    key: "dove",
                    thStyle: "width: 15rem",
                },
                {
                    label: "Specificare Nazione/Regione",
                    key: "regioneNazioneDescrizione",
                    // thStyle: "width: 8rem",
                },
                {
                    label: "Data Partenza",
                    key: "partenza",
                    formatter: (value) => {
                        if (value) {
                            return moment(new Date(value)).format("DD/MM/YYYY");
                        } else {
                            return "-----";
                        }
                    },
                    thStyle: "width: 12rem",
                },
                {
                    label: "Data Rientro",
                    key: "rientro",
                    formatter: (value) => {
                        if (value) {
                            return moment(new Date(value)).format("DD/MM/YYYY");
                        } else {
                            return "-----";
                        }
                    },
                    thStyle: "width: 12rem",
                },
            ],
            fieldsContatti: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Cognome",
                    key: "cognome",
                    thStyle: "width: 20rem",
                    sortable: false,
                },
                {
                    label: "Nome",
                    key: "nome",
                    thStyle: "width: 20rem",
                    sortable: false,
                },
                {
                    label: "Comune",
                    key: "comune",
                    thStyle: "width: 10rem",
                    sortable: false,
                },
                {
                    label: "Indirizzo",
                    key: "indirizzo",
                    // thStyle: "width: 5rem",
                    sortable: false,
                },
                {
                    label: "Telefono",
                    key: "telefono",
                    // thStyle: "width: 5rem",
                    sortable: false,
                },
                {
                    label: "Vaccinato",
                    key: "vaccinato",
                    // thStyle: "width: 5rem",
                    sortable: false,
                },
            ],
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        loadData() {
            let me = this;
            console.log("PD");
            me.$emit("showmodalloading", true);
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            axios
                .get(link + me.id)
                .then((response) => {
                    me.jsonData = response.data.data;
                    if (me.jsonData.esamiLaboratorioDiagnosiMorbillo === "SI") {
                        me.confermeLaboratorioMorbilloDisabled = false;
                    } else {
                        me.confermeLaboratorioMorbilloDisabled = true;
                    }
                    if (me.jsonData.esamiLaboratorioDiagnosiRosolia === "SI") {
                        me.confermeLaboratorioRosoliaDisabled = false;
                    } else {
                        me.confermeLaboratorioRosoliaDisabled = true;
                    }
                    me.$emit("showmodalloading", false);
                })
                .catch(() => {
                    me.$emit("showmodalloading", false);
                });
        },
    },
};
</script>
