<template>
	<data-analysis-dashboard-view-details-component></data-analysis-dashboard-view-details-component>
</template>

<script>
import DataAnalysisDashboardViewDetailsComponent from "../components/DataAnalysisDashboardViewDetailsComponent.vue";
export default {
	components: { DataAnalysisDashboardViewDetailsComponent },
};
</script>

<style></style>
