import DataAnalysisTablesListPage from "./pages/DataAnalysisTablesListPage.vue";
import DataAnalysisTablesEditPage from "./pages/DataAnalysisTablesEditPage.vue";
import DataAnalysisQueryListPage from "./pages/DataAnalysisQueryListPage.vue";
import DataAnalysisQueryEditPage from "./pages/DataAnalysisQueryEditPage.vue";
import DataAnalysisDashboardListPage from "./pages/DataAnalysisDashboardListPage.vue";
import DataAnalysisDashboardViewPage from "./pages/DataAnalysisDashboardViewPage.vue";
import DataAnalysisDashboardEditPage from "./pages/DataAnalysisDashboardEditPage.vue";
import DataAnalysisDashboardViewDetailsPage from "./pages/DataAnalysisDashboardViewDetailsPage.vue";
import TestPivotPage from "./pages/TestPivot.vue";
import DataAnalysisDataSourcesPage from "./pages/DataAnalysisDataSourcesPage.vue";
import DataAnalysisDashboardGruppiPage from "./pages/DataAnalysisDashboardGruppiPage.vue";

export default {
	DataAnalysisTablesListPage,
	DataAnalysisTablesEditPage,
	DataAnalysisQueryListPage,
	DataAnalysisQueryEditPage,
	DataAnalysisDashboardListPage,
	DataAnalysisDashboardEditPage,
	DataAnalysisDashboardViewPage,
	DataAnalysisDashboardViewDetailsPage,
	DataAnalysisDataSourcesPage,
	TestPivotPage,
	DataAnalysisDashboardGruppiPage,
};
