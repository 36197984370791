<template>
    <sa-page-layout :titolo="titolo" :btnNewVisible="true" :btnRefreshVisible="true" :linkedit="linkedit" :pathResource="pathResource" :showModalLoading="showModalLoading" @refresh="onRefresh">
        <template slot="table-filter">
            <b-form @submit.prevent="onSubmit">
                <b-row class="sa-form-component-filter">
                    <b-col xs="6" sm="6" md="6" lg="6" xl="6">
                        <label htmlFor="utentiFiltroUsername" class="sa-label-data">Username:</label>
                        <b-form-input id="utentiFiltroUsername" v-model="filtro.username" type="search" placeholder="Username"></b-form-input>
                    </b-col>
                    <b-col xs="6" sm="6" md="6" lg="6" xl="6">
                        <label htmlFor="utentiFiltroLastname" class="sa-label-data">Last Name:</label>
                        <b-form-input id="utentiFiltroLastname" v-model="filtro.lastname" type="search" placeholder="Last Name"></b-form-input>
                    </b-col>
                    <b-col xs="6" sm="6" md="6" lg="6" xl="6">
                        <label htmlFor="utentiFiltroFirstname" class="sa-label-data">First Name:</label>
                        <b-form-input id="utentiFiltroFirstname" v-model="filtro.firstname" type="search" placeholder="First Name"></b-form-input>
                    </b-col>
                    <b-col xs="6" sm="6" md="6" lg="6" xl="6">
                        <label htmlFor="utentiFiltroProfilo" class="sa-label-data">Profilo:</label>
                        <b-form-select id="utentiFiltroProfilo" v-model="filtro.profilo" :options="gruppi" :value="null" value-field="nome" text-field="nome"></b-form-select>
                    </b-col>
                </b-row>
                <b-row class="sa-form-btn-filter">
                    <b-col class="sa-padding-right text-right">
                        <b-button type="submit" variant="info">Cerca</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </template>
        <template slot="table-header">
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6">
                    <p>Totale Record: {{ rows }}</p>
                </b-col>
                <b-col cols="6">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <div class="b-table-sticky-header">
                <b-table ref="table" id="tblUtenti" :filter="filter" stacked="lg" striped hover :items="items" :fields="fields" current-page="1" :per-page="perPage" sort-icon-left head-variant="light" @sort-changed="onSortingChanged">
                    <template v-slot:cell(gruppo)="{ item }">
                        <router-link v-if="item.idGruppo" class="sa-edit-link" :to="'/gruppi/view/' + item.idGruppo">{{ item.profilo }}</router-link>
                    </template>
                    <template v-slot:cell(username)="{ item }">
                        <router-link class="sa-edit-link" :to="'/users/edit/' + item.id">{{ item.username }}</router-link>
                        <br />
                        ({{ item.email }})
                    </template>
                    <template v-slot:cell(actions)="row">
                        <b-container class="bv-example-row">
                            <b-button v-if="row.item.statoUtenza !== 'BLOCCATA'" v-b-tooltip.hover size="sm" variant="outline-secondary" title="Modifica Utente" @click="onEdit(row.item, row.index, $event.target)" class="mr-1 no-text">
                                <b-icon icon="pencil" variant="outline-secondary"></b-icon>
                            </b-button>
                            <b-button v-if="row.item.statoUtenza === 'BLOCCATA'" v-b-tooltip.hover title="Sblocca Utente" size="sm" variant="outline-secondary" @click="onUnlockUtenza(row.item, row.index, $event.target)" class="mr-1 no-text">
                                <i class="bi bi-unlock"></i>
                            </b-button>
                            <b-button v-b-tooltip.hover size="sm" variant="outline-danger" title="Cancella Utente" @click="onDelete(row.item, row.index, $event.target)" class="mr-1 no-text">
                                <b-icon icon="trash" variant="danger"></b-icon>
                            </b-button>
                            <b-button v-if="btnSendEmailTwoFactor" size="sm" variant="outline-secondary" @click="onSendEmailTwoFactor(row.item, row.index, $event.target)" class="mr-1 no-text">
                                <i class="bi bi-qr-code"></i>
                            </b-button>

                            <div style="float: left">
                                <b-form-checkbox v-model="row.item.attivo" name="check-button" switch size="lg" @change="onAttivaChange(row.item)"></b-form-checkbox>
                            </div>
                        </b-container>
                    </template>
                </b-table>
            </div>
        </template>
        <template slot="table-footer">
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6">
                    <p>Totale Record: {{ rows }}</p>
                </b-col>
                <b-col cols="6">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import Vue from "vue";
export default {
    components: { SaPageLayout },
    data() {
        return {
            titolo: "Lista Utenti",
            pathResource: "/users",
            pathResourceGruppi: "/gruppi",
            pathResourceTwoFactorSendMail: "/twofactor/sendqrcode",
            linkedit: "/users",
            showModalLoading: false,
            perPage: 50,
            currentPage: 1,
            filtro: { forPage: 0, page: 0 },
            filter: null,
            gruppi: [],
            rows: 0,
            btnSendEmailTwoFactor: false,
            fields: [
                {
                    label: "Gruppo",
                    key: "gruppo",
                    sortable: true,
                },
                {
                    label: "Cognome",
                    key: "lastname",
                    sortable: true,
                },
                {
                    label: "Nome",
                    key: "firstname",
                    sortable: false,
                },
                {
                    label: "Username",
                    key: "username",
                    sortable: false,
                },
                { class: "sa-table-column-action-double", key: "actions", label: "" }, ///, thStyle: "width: 15rem"
            ],
            items: [],
        };
    },
    created() {
        let me = this;
        me.btnSendEmailTwoFactor = Vue.prototype.appConfig.twoFactor;
    },
    mounted: function () {
        this.loadGruppi();
        this.loadData();
    },
    watch: {
        currentPage() {
            let me = this;
            me.loadData();
        },
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.showModalLoading = true;
            me.filtro.forPage = me.perPage;
            me.filtro.page = me.currentPage;
            axios.get(link, { params: me.filtro }).then((response) => {
                me.rows = response.data.data.recordsNumber;
                me.items = response.data.data.list;
                me.showModalLoading = false;
            });
        },
        loadGruppi() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceGruppi;
            axios.get(link).then((response) => {
                me.gruppi = response.data.data;
                me.gruppi.unshift({ nome: "" });
            });
        },
        onAdd() {
            let me = this;
            me.$router.replace("/users/edit/-1").catch((err) => {
                err;
            });
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        onDelete(item) {
            let me = this;
            this.$bvModal
                .msgBoxConfirm("Sicuro di voler Eliminare l'utente " + item.username)
                .then((value) => {
                    if (value) {
                        let link = process.env.VUE_APP_PATH_API + "/users/";
                        axios.delete(link + item.id).then(() => {
                            me.loadData();
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        onEdit(item) {
            let me = this;
            me.$router.push("/users/edit/" + item.id);
        },
        onUnlockUtenza(item) {
            let link = process.env.VUE_APP_PATH_API + "/users/sblocca/";
            axios
                .put(link + item.id)
                .then((response) => {
                    let message = "";
                    message = response.data.messaggio;
                    this.$bvToast.toast(message, {
                        variant: "success",
                        title: "Utenti",
                        autoHideDelay: 3000,
                        solid: true,
                        noCloseButton: true,
                    });
                    this.loadData();
                })
                .catch((error) => {
                    this.$bvToast.toast("Errore nell'attivare l'utente: " + error.response.data.messaggio, {
                        variant: "danger",
                        title: "Utenti",
                        autoHideDelay: 3000,
                        solid: true,
                        noCloseButton: true,
                    });
                });
        },
        onAttivaChange(item) {
            let link = process.env.VUE_APP_PATH_API + "/users/attiva/";
            axios
                .put(link + item.id)
                .then((response) => {
                    let message = "";
                    if (response.data.data.attivo) {
                        message = "Utente abilitato con successo";
                    } else {
                        message = "Utente disabilitato con successo";
                    }
                    this.$bvToast.toast(message, {
                        variant: "success",
                        title: "Utenti",
                        autoHideDelay: 3000,
                        solid: true,
                        noCloseButton: true,
                    });
                })
                .catch((error) => {
                    this.$bvToast.toast("Errore nell'attivare l'utente: " + error.response.data.messaggio, {
                        variant: "danger",
                        title: "Utenti",
                        autoHideDelay: 3000,
                        solid: true,
                        noCloseButton: true,
                    });
                });
        },
        onSubmit() {
            let me = this;
            me.loadData();
        },
        onSendEmailTwoFactor(item) {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceTwoFactorSendMail + "?usernameUtente=" + item.username;
            axios.get(link).then(() => {
                me.showModalLoading = false;
                me.$bvToast.toast("E-Mail con il QR-Code inviata all'utente: " + item.username, {
                    title: "Invio QR-Code",
                    variant: "success",
                    autoHideDelay: 5000,
                });
            });
        },
        onSortingChanged(ctx) {
            console.log(ctx);
            // ctx.sortBy   ==> Field key for sorting by (or null for no sorting)
            // ctx.sortDesc ==> true if sorting descending, false otherwise
        },
    },
};
</script>
