<template>
    <div>
        <b-card class="sa-card" header="Dati Evento" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEventDate") }}</label>
                    <span class="sa-data"> {{ formatDateTime(jsonData.dataEvento) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Dati Clinici" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPAS") }}</label>
                    <span class="sa-data">{{ jsonData.pas }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="1" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPAD") }}</label>
                    <span class="sa-data">{{ jsonData.pad }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblWeight") }}</label>
                    <span class="sa-data"> {{ jsonData.peso }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHeight") }}</label>
                    <span class="sa-data">{{ jsonData.altezza }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data ft-color-calculated-fields"><i class="fas fa-calculator"></i> {{ getLabelTraduora("patient.cardiology.lblFC") }}</label>
                    <span class="sa-data"> {{ jsonData.fc }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIndicazioneClinica") }}</label>
                    <span class="sa-data">{{ jsonData.indicazioneClinica }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblECG") }}</label>
                    <span class="sa-data">{{ jsonData.ecg }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblSezioniSinistre')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVStd") }}</label>
                    <span class="sa-data"> {{ jsonData.vstd }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVSts") }}</label>
                    <span class="sa-data"> {{ jsonData.vsts }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSIVtd") }}</label>
                    <span class="sa-data">{{ jsonData.sivtd }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSIVts") }}</label>
                    <span class="sa-data">{{ jsonData.sivts }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPPtd") }}</label>
                    <span class="sa-data">{{ jsonData.pptd }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPPts") }}</label>
                    <span class="sa-data"> {{ jsonData.ppts }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRR") }}</label>
                    <span class="sa-data"> {{ jsonData.rr }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAorta") }}</label>
                    <span class="sa-data"> {{ jsonData.aorta }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAtrio") }}</label>
                    <span class="sa-data"> {{ jsonData.atrio }}</span>
                </b-col>

                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data ft-color-calculated-fields"> <i class="fas fa-calculator"></i> {{ getLabelTraduora("patient.cardiology.lblBMI") }}</label>
                    <span class="sa-data"> {{ jsonData.bmi }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data ft-color-calculated-fields"><i class="fas fa-calculator"></i> {{ getLabelTraduora("patient.cardiology.lblBSA") }}</label>
                    <span class="sa-data"> {{ jsonData.bsa }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data ft-color-calculated-fields"><i class="fas fa-calculator"></i> {{ getLabelTraduora("patient.cardiology.lblCOT") }}</label>
                    <span class="sa-data">{{ jsonData.cot }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data ft-color-calculated-fields"><i class="fas fa-calculator"></i> {{ getLabelTraduora("patient.cardiology.lblTPRT") }}</label>
                    <span class="sa-data"> {{ jsonData.tprt }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data ft-color-calculated-fields"><i class="fas fa-calculator"></i> {{ getLabelTraduora("patient.cardiology.lblLVMid") }}</label>
                    <span class="sa-data"> {{ jsonData.lvmidp }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data ft-color-calculated-fields"><i class="fas fa-calculator"></i> {{ getLabelTraduora("patient.cardiology.lblLVMis") }}</label>
                    <span class="sa-data"> {{ jsonData.lvmisp }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data ft-color-calculated-fields"><i class="fas fa-calculator"></i> {{ getLabelTraduora("patient.cardiology.lbleft") }}</label>
                    <span class="sa-data"> {{ jsonData.eft }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data ft-color-calculated-fields"><i class="fas fa-calculator"></i> {{ getLabelTraduora("patient.cardiology.lblsft") }}</label>
                    <span class="sa-data"> {{ jsonData.sft }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data ft-color-calculated-fields"><i class="fas fa-calculator"></i> {{ getLabelTraduora("patient.cardiology.lblRWT") }}</label>
                    <span class="sa-data">{{ jsonData.rwt }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data ft-color-calculated-fields"><i class="fas fa-calculator"></i> {{ getLabelTraduora("patient.cardiology.lblLVMha") }}</label>
                    <span class="sa-data">{{ jsonData.lvmha }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data ft-color-calculated-fields"> <i class="fas fa-calculator"></i> {{ getLabelTraduora("patient.cardiology.lblESS") }}</label>
                    <span class="sa-data"> {{ jsonData.ess }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNoteVentricoloSinistro") }}</label>
                    <span class="sa-data">{{ jsonData.noteVentricoloSinistro }}</span>
                </b-col>
                <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNoteAtrioSinistro") }}</label>
                    <span class="sa-data">{{ jsonData.noteAtrioSinistro }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblSezioniDestre')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVCIi") }}</label>
                    <span class="sa-data">{{ jsonData.vciI }}</span>
                </b-col>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVCIe") }}</label>
                    <span class="sa-data"> {{ jsonData.vciE }}</span>
                </b-col>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTAPSE") }}</label>
                    <span class="sa-data"> {{ jsonData.tapse }}</span>
                </b-col>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVDtd") }}</label>
                    <span class="sa-data"> {{ jsonData.vdtd }}</span>
                </b-col>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRVwt") }}</label>
                    <span class="sa-data"> {{ jsonData.rvwt }}</span>
                </b-col>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbl2DRVbasal") }}</label>
                    <span class="sa-data"> {{ jsonData.dueDRvbasal }}</span>
                </b-col>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbl2DRVmedio") }}</label>
                    <span class="sa-data"> {{ jsonData.dueDRvmedio }}</span>
                </b-col>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbl2DRVlongit") }}</label>
                    <span class="sa-data"> {{ jsonData.dueDPvlongit }}</span>
                </b-col>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRVAED") }}</label>
                    <span class="sa-data">{{ jsonData.rvaed }}</span>
                </b-col>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRVAES") }}</label>
                    <span class="sa-data">{{ jsonData.rvaes }}</span>
                </b-col>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <label class="sa-label-data ft-color-calculated-fields"><i class="fas fa-calculator"></i> {{ getLabelTraduora("patient.cardiology.lblFAC") }}</label>
                    <span class="sa-data"> {{ jsonData.fac }}</span>
                </b-col>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPrsistpolm") }}</label>
                    <span class="sa-data"> {{ jsonData.prSistPolm }}</span>
                </b-col>
                <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblADarea") }}</label>
                    <span class="sa-data">{{ jsonData.adArea }}</span>
                </b-col>
                <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblADdiametro") }}</label>
                    <span class="sa-data"> {{ jsonData.adDiametro }}</span>
                </b-col>
                <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNoteVentricoloDestro") }}</label>
                    <span class="sa-data">{{ jsonData.noteVentricoloDestro }}</span>
                </b-col>
                <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNoteAtrioDestro") }}</label>
                    <span class="sa-data"> {{ jsonData.noteVentricoloDestro }}</span>
                </b-col>
            </b-row>
        </b-card>

        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lbl2Dsimpson')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="2" md="2" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLVVD") }}</label>
                    <span class="sa-data">{{ jsonData.lvvdQuattroCh }}</span>
                </b-col>
                <b-col xs="12" sm="2" md="2" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLVVS") }}</label>
                    <span class="sa-data">{{ jsonData.lvvsQuattroCh }}</span>
                </b-col>
                <b-col xs="12" sm="2" md="2" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLAV") }}</label>
                    <span class="sa-data"> {{ jsonData.lav }}</span>
                </b-col>
                <b-col xs="12" sm="2" md="2" lg="2" xl="2">
                    <label class="sa-label-data ft-color-calculated-fields"><i class="fas fa-calculator"></i> {{ getLabelTraduora("patient.cardiology.lblTPR") }}</label>
                    <span class="sa-data">{{ jsonData.tpr }}</span>
                </b-col>
                <b-col xs="12" sm="2" md="2" lg="2" xl="2">
                    <label class="sa-label-data ft-color-calculated-fields"><i class="fas fa-calculator"></i> {{ getLabelTraduora("patient.cardiology.lbl2DSV") }}</label>
                    <span class="sa-data"> {{ jsonData.sv }}</span>
                </b-col>
                <b-col xs="12" sm="2" md="2" lg="2" xl="2">
                    <label class="sa-label-data ft-color-calculated-fields"> <i class="fas fa-calculator"></i> {{ getLabelTraduora("patient.cardiology.lbl2DEF") }}</label>
                    <span class="sa-data"> {{ jsonData.ef }}</span>
                </b-col>
                <b-col xs="12" sm="4" md="4" lg="4" xl="4">
                    <label class="sa-label-data ft-color-calculated-fields"> <i class="fas fa-calculator"></i> {{ getLabelTraduora("patient.cardiology.lbl2DCO") }}</label>
                    <span class="sa-data"> {{ jsonData.co }}</span>
                </b-col>
                <b-col xs="12" sm="4" md="4" lg="4" xl="4">
                    <label class="sa-label-data ft-color-calculated-fields"> <i class="fas fa-calculator"></i> {{ getLabelTraduora("patient.cardiology.lblLAVi") }}</label>
                    <span class="sa-data">{{ jsonData.lavI }}</span>
                </b-col>
                <b-col xs="12" sm="4" md="4" lg="4" xl="4">
                    <label class="sa-label-data ft-color-calculated-fields"> <i class="fas fa-calculator"></i> {{ getLabelTraduora("patient.cardiology.lblLVVdi") }}</label>
                    <span class="sa-data"> {{ jsonData.lvvd }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lbl2DsimpsonBiplana')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="4" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLVVd2ch") }}</label>
                    <span class="sa-data">{{ jsonData.lvvdDueCh }}</span>
                </b-col>
                <b-col xs="12" sm="4" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLVVs2ch") }}</label>
                    <span class="sa-data"> {{ jsonData.lvvDueCh }}</span>
                </b-col>
                <b-col xs="12" sm="4" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLAV2ch") }}</label>
                    <span class="sa-data"> {{ jsonData.dueChLav }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblSpeckleTracking')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <h5>{{ getLabelTraduora("patient.cardiology.lblSpeckleTracking") }}</h5>
                </b-col>
                <b-col xs="12" sm="4" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblGLSdx") }}</label>
                    <span class="sa-data"> {{ jsonData.glsDx }}</span>
                </b-col>
                <b-col xs="12" sm="4" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblGLSsx") }}</label>
                    <span class="sa-data"> {{ jsonData.glsSx }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblDiastole')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPFVE") }}</label>
                    <span class="sa-data">{{ jsonData.pfve }}</span>
                </b-col>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPFVA") }}</label>
                    <span class="sa-data"> {{ jsonData.pfva }}</span>
                </b-col>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <label class="sa-label-data ft-color-calculated-fields"> <i class="fas fa-calculator"></i> {{ getLabelTraduora("patient.cardiology.lblEA") }}</label>
                    <span class="sa-data">{{ jsonData.ea }}</span>
                </b-col>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDT") }}</label>
                    <span class="sa-data"> {{ jsonData.dt }}</span>
                </b-col>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIRT") }}</label>
                    <span class="sa-data"> {{ jsonData.irt }}</span>
                </b-col>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPFVE") }}</label>
                    <span class="sa-data"> {{ jsonData.pfveDue }}</span>
                </b-col>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPFVA") }}</label>
                    <span class="sa-data">{{ jsonData.pfvaDue }}</span>
                </b-col>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEA") }}</label>
                    <span class="sa-data">{{ jsonData.eaDue }}</span>
                </b-col>
                <b-col xs="12" sm="4" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblADur") }}</label>
                    <span class="sa-data"> {{ jsonData.adur }}</span>
                </b-col>
                <b-col xs="12" sm="4" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTipoManovra") }}</label>
                    <span class="sa-data"> {{ jsonData.tipoManovra }}</span>
                </b-col>
                <b-col xs="12" sm="4" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPatternRiempimento") }}</label>
                    <span class="sa-data"> {{ jsonData.patternRiempimento }}</span>
                </b-col>
            </b-row>
        </b-card>

        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblContrattilitaSegmentaria')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="8" lg="8" xl="8">
                    <b-row>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <h5>{{ getLabelTraduora("patient.cardiology.lblSegments") }}</h5>
                        </b-col>
                        <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
                            <h5>{{ getLabelTraduora("patient.cardiology.lblBasal") }}</h5>
                        </b-col>
                        <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
                            <h5>{{ getLabelTraduora("patient.cardiology.lblMedium") }}</h5>
                        </b-col>
                        <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
                            <h5>{{ getLabelTraduora("patient.cardiology.lblApex") }}</h5>
                        </b-col>
                        <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblAnteriorSeptum") }}</label>
                        </b-col>
                        <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
                            <span class="sa-data"> {{ jsonData.settoanteriore }}</span>
                        </b-col>
                        <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
                            <span class="sa-data"> {{ jsonData.settoanterioreMedio }}</span>
                        </b-col>
                        <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
                            <span class="sa-data"> {{ jsonData.apiceSetto }}</span>
                        </b-col>
                        <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblAnteriorApex") }}</label>
                        </b-col>
                        <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
                            <span class="sa-data"> {{ jsonData.pareteanteriore }}</span>
                        </b-col>
                        <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
                            <span class="sa-data"> {{ jsonData.pareteanterioreMedia }}</span>
                        </b-col>
                        <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
                            <span class="sa-data"> {{ jsonData.apiceAnt }}</span>
                        </b-col>
                        <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblLateralAnteriorWall") }}</label>
                        </b-col>
                        <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
                            <span class="sa-data"> {{ jsonData.pareteLatAnteriore }}</span>
                        </b-col>
                        <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
                            <span class="sa-data"> {{ jsonData.pareteLatAnterioreMedia }}</span>
                        </b-col>
                        <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3"> </b-col>
                        <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblEcocardioFrontSideWall") }}</label>
                        </b-col>
                        <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
                            <span class="sa-data"> {{ jsonData.pareteLatPosteriore }}</span>
                        </b-col>
                        <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
                            <span class="sa-data"> {{ jsonData.pareteLatPosterioreMedia }}</span>
                        </b-col>
                        <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
                            <span class="sa-data"> {{ jsonData.apicePost }}</span>
                        </b-col>
                        <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblEcocardioRearWall") }}</label>
                        </b-col>
                        <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
                            <span class="sa-data"> {{ jsonData.pareteInferiore }}</span>
                        </b-col>
                        <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
                            <span class="sa-data"> {{ jsonData.pareteInferioreMedia }}</span>
                        </b-col>
                        <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
                            <span class="sa-data"> {{ jsonData.apiceInf }}</span>
                        </b-col>
                        <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPosteriorSeptum") }}</label>
                        </b-col>
                        <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
                            <span class="sa-data"> {{ jsonData.settoposteriore }}</span>
                        </b-col>
                        <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
                            <span class="sa-data"> {{ jsonData.settoposterioreMedio }}</span>
                        </b-col>
                        <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3"> </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
                            <label class="sa-label-data">Normocinetici</label>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                            <span class="sa-data"> {{ jsonData.normocinetici }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="2" xl="2">
                            <label class="sa-label-data">(*1=)</label>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                            <span class="sa-data"> {{ jsonData.normocineticiCalc }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
                            <label class="sa-label-data">Ipocinetici</label>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                            <span class="sa-data"> {{ jsonData.ipocinetici }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="2" xl="2">
                            <label class="sa-label-data">(*2=)</label>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                            <span class="sa-data"> {{ jsonData.ipocineticiCalc }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
                            <label class="sa-label-data">Acinetici</label>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                            <span class="sa-data"> {{ jsonData.acinetici }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="2" xl="2">
                            <label class="sa-label-data">(*3=)</label>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                            <span class="sa-data"> {{ jsonData.acineticiCalc }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
                            <label class="sa-label-data">Discinetici</label>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                            <span class="sa-data"> {{ jsonData.discinetici }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="2" xl="2">
                            <label class="sa-label-data">(*4=)</label>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                            <span class="sa-data"> {{ jsonData.discineticiCalc }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
                            <label class="sa-label-data">Aneurismatici</label>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                            <span class="sa-data"> {{ jsonData.aneurismatici }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="2" xl="2">
                            <label class="sa-label-data">(*5=)</label>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                            <span class="sa-data"> {{ jsonData.aneurismaticiCalc }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
                            <label class="sa-label-data">P. Totale</label>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="2" xl="2">
                            <span class="sa-data"> {{ jsonData.totale }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
                            <label class="sa-label-data">Est. Danno IMA</label>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="2" xl="2">
                            <span class="sa-data"> {{ jsonData.estensioneDanno }}</span>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblH5TdiAnulus')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <h5>{{ getLabelTraduora("patient.cardiology.lblh5Lateral") }}</h5>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">S (cm/sec)</label>
                    <span class="sa-data"> {{ jsonData.sTdi }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">E' (cm/sec)</label>
                    <span class="sa-data"> {{ jsonData.eTdi }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">A' (sec)</label>
                    <span class="sa-data"> {{ jsonData.aiTdi }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data ft-color-calculated-fields"> <i class="fas fa-calculator"></i> E/E'</label>
                    <span class="sa-data"> {{ jsonData.eTdi }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <h5>{{ getLabelTraduora("patient.cardiology.lblh5Septal") }}</h5>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">S (cm/sec)</label>
                    <span class="sa-data"> {{ jsonData.sTdiSettale }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">E' (cm/sec)</label>
                    <span class="sa-data"> {{ jsonData.eTdiSettale }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">A' (sec)</label>
                    <span class="sa-data"> {{ jsonData.aiTdiSettale }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <h5>{{ getLabelTraduora("patient.cardiology.lblh5Tricuspid") }}</h5>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">S (cm/sec)</label>
                    <span class="sa-data"> {{ jsonData.sTdiTricuspidalico }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">E' (cm/sec)</label>
                    <span class="sa-data"> {{ jsonData.eTdiTricuspidalico }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">A' (sec)</label>
                    <span class="sa-data"> {{ jsonData.aiTdiTricuspidalico }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblAorta')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAnulus") }}</label>
                    <span class="sa-data"> {{ jsonData.anulus }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblValsalva") }}</label>
                    <span class="sa-data"> {{ jsonData.seniValsalva }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblGiunzSt") }}</label>
                    <span class="sa-data"> {{ jsonData.giunzSt }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAortaAsc") }}</label>
                    <span class="sa-data"> {{ jsonData.aortaAscendente }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAortaArch") }}</label>
                    <span class="sa-data"> {{ jsonData.aortaArco }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAortaAdd") }}</label>
                    <span class="sa-data"> {{ jsonData.aortaAddominale }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblArea2d") }}</label>
                    <span class="sa-data"> {{ jsonData.area2d }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                    <label class="sa-label-data" style="color: red">{{ getLabelTraduora("patient.cardiology.lblArea2dIndex") }}</label>
                    <span class="sa-data">{{ jsonData.areaDueDIndex }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLVOT") }}</label>
                    <span class="sa-data">{{ jsonData.lvot }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.diabetology.lblContractedVein") }}</label>
                    <span class="sa-data"> {{ jsonData.venaContracataAorta }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLVOTVTI") }}</label>
                    <span class="sa-data">{{ jsonData.lvotv1 }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCWAoVTI") }}</label>
                    <span class="sa-data"> {{ jsonData.cwaov1 }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPGMax") }}</label>
                    <span class="sa-data">{{ jsonData.pgmaxAorta }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPGMEDIUM") }}</label>
                    <span class="sa-data"> {{ jsonData.pgMedio }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPHT") }}</label>
                    <span class="sa-data"> {{ jsonData.pht }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAreaDoppler") }}</label>
                    <span class="sa-data"> {{ jsonData.areaDoppler }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                    <label class="sa-label-data" style="color: red">{{ getLabelTraduora("patient.cardiology.lblAreaDopplerI") }}</label>
                    <span class="sa-data"> {{ jsonData.areaDopplerI }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMitralHero") }}</label>
                    <span class="sa-data">{{ jsonData.eroaAorta }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSA") }}</label>
                    <span class="sa-data"> {{ jsonData.sa }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIA") }}</label>
                    <span class="sa-data">{{ jsonData.ia }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("agendas.lblDescription") }}</label>
                    <span class="sa-data"> {{ jsonData.descrizioneAorta }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblValveProstheses") }}</label>
                    <span class="sa-data">{{ jsonData.protesiValvolariAorta }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblMitral')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblArea2d") }}</label>
                    <span class="sa-data">{{ jsonData.areaDueDMitrale }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAreaDoppler") }}</label>
                    <span class="sa-data">{{ jsonData.areaDopplerMitrale }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMediumGrad") }}</label>
                    <span class="sa-data">{{ jsonData.gradMedio }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPHT") }}</label>
                    <span class="sa-data">{{ jsonData.phtMitrale }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.diabetology.lblContractedVein") }}</label>
                    <span class="sa-data">{{ jsonData.venaContracataMitrale }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMitralHero") }}</label>
                    <span class="sa-data"> {{ jsonData.eroaMitrale }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRegurgitating") }}</label>
                    <span class="sa-data"> {{ jsonData.volRigurgitanteMitrale }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="2" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIM") }}</label>
                    <span class="sa-data"> {{ jsonData.im }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="2" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSM") }}</label>
                    <span class="sa-data"> {{ jsonData.sm }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("agendas.lblDescription") }}</label>
                    <span class="sa-data">{{ jsonData.descrizioneMitrale }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblValveProstheses") }}</label>
                    <span class="sa-data"> {{ jsonData.protesiValvolariMitrale }}</span>
                </b-col>
            </b-row>
        </b-card>
        <!-- <b-card class="sa-card" header="Wilkins Score" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMobility") }}</label>
          <span class="sa-data"> {{ jsonData.mobilita }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblThickeningFlaps") }}</label>
          <span class="sa-data"> {{ jsonData.ispessimentoLembi }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblThickeningSubvalvularApparatus") }}</label>
          <span class="sa-data">{{ jsonData.ispessimentoAppSottvalv }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCalcification") }}</label>
          <span class="sa-data"> {{ jsonData.calcificazioni }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="1" xl="1">
          <label class="sa-label-data" style="color: red">Score Tot.</label>
          <span class="sa-data"> {{ jsonData.wilkinsScoreMitrale }}</span>
        </b-col>
      </b-row>
    </b-card> -->
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblPulmonary')" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVMax") }}</label>
                            <span class="sa-data"> {{ jsonData.vmaxPolmonare }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIP") }}</label>
                            <span class="sa-data">{{ jsonData.ip }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSP") }}</label>
                            <span class="sa-data">{{ jsonData.sp }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">{{ getLabelTraduora("agendas.lblDescription") }}</label>
                            <span class="sa-data">{{ jsonData.descrizionePolmonare }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblValveProstheses") }}</label>
                            <span class="sa-data"> {{ jsonData.protesiValvolariPolmonare }}</span>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblPulmonaryDoppler')" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">S (cm/sec)</label>
                            <span class="sa-data">{{ jsonData.s }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">D (cm/sec)</label>
                            <span class="sa-data">{{ jsonData.d }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAPV") }}</label>
                            <span class="sa-data"> {{ jsonData.apv }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAPD") }}</label>
                            <span class="sa-data"> {{ jsonData.apd }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data ft-color-calculated-fields"> <i class="fas fa-calculator"></i> {{ getLabelTraduora("patient.cardiology.lblARAPD") }}</label>
                            <span class="sa-data"> {{ jsonData.arApd }}</span>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblTricuspid')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVMax") }}</label>
                    <span class="sa-data"> {{ jsonData.vmaxTricuspide }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIT") }}</label>
                    <span class="sa-data">{{ jsonData.it }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblST") }}</label>
                    <span class="sa-data"> {{ jsonData.st }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblJetRigTric") }}</label>
                    <span class="sa-data"> {{ jsonData.jetRigTric }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("agendas.lblDescription") }}</label>
                    <span class="sa-data"> {{ jsonData.protesiValvolariTricuspide }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblValveProstheses") }}</label>
                    <span class="sa-data"> {{ jsonData.protesiValvolariTricuspide }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPericardium") }}</label>
                    <span class="sa-data"> {{ jsonData.pericardio }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblConclusions')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblConclusions") }}</label>
                    <span class="sa-data"> {{ jsonData.conclusioni }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblDiagnosticImpression')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Impressioni Diagnostiche</label>
                    <span class="sa-data"> {{ jsonData.impressioniDiagnostiche }}</span>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import axios from "axios";
import UtilityMixins from "../../../../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixins],
    props: {
        idEvento: { Type: String, require: true },
    },
    components: {},
    data() {
        return {
            linkback: null,
            pathResource: "/cardioecocardiodati",
            pathResourceSegmentiWilkinsScore: "/wilkinsscore",
            pathResourceSegmenti: "/segmenti",
            jsonData: {},
            data: {},
            id: null,
            showModalLoading: false,
            aterosclerosiNonEmoSign: false,
            aterosclerosiSevera: false,
            fontScale: 2,
            linkedit: null,
            pathRestValidation: "/validation",
            isThisFirmato: false,
            segmentiOptions: [],
            wcMobilitaOptions: [],
            wcLembiOptions: [],
            wcSottovalvolareOptions: [],
            wcCalcificazioniOptions: [],
            indicazioniClinicheOptions: [],
            ecgOptions: [],
            plus4Options: [
                { text: "++++/4", value: "++++/4" },
                { text: "+++/4", value: "+++/4" },
                { text: "++/4", value: "++/4" },
                { text: "+/4", value: "+/4" },
            ],
            tipiManovraOptions: [
                { text: this.getLabelTraduora("patient.cardiology.slcManeuverValsalva"), value: "patient.cardiology.slcManeuverValsalva" },
                { text: this.getLabelTraduora("patient.cardiology.slcManeuverLegsUp"), value: "patient.cardiology.slcManeuverLegsUp" },
                { text: this.getLabelTraduora("patient.cardiology.slcManeuverNoManeuver"), value: "patient.cardiology.slcManeuverNoManeuver" },
            ],
            paternRiempimentoOptions: [],
            settoAnterioreOptions: [],
            settoAnterioreMedioOptions: [],
            apiceSettoOptions: [],
            settoPosterioreOptions: [],
            settoPosterioreMedioOptions: [],
            pareteLateraleAnterioreOptions: [],
            pareteLateraleAnterioreMediaOptions: [],
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    computed: {
        isFirmato() {
            let me = this;
            let isFirmato = !me.jsonData.firma;
            console.log(isFirmato, "FIRMA");
            me.$emit("updateStatoFirma", isFirmato);
            return !me.jsonData.firma;
        },
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                axios.get(link).then((response) => {
                    me.jsonData = response.data.data;
                    me.showModalLoading = false;
                });
            }
        },
        onFirmaUpdate() {
            let me = this;
            me.loadData();
        },
    },
};
</script>

<style></style>
