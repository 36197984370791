<template>
    <sa-page-layout ref="cmpSaPageLayout" :btnSaveVisible="false" :data="jsonData" :btnAnnullaVisible="true" :showModalLoading="showModalLoading" :linkback="linkback" :pathResource="pathResource" class="sa-no-space">
        <template slot="toolbar">
            <b-button variant="outline-success btn-toolbar sa-margin-left float-sm-left" v-b-modal.mdlAllegati :disabled="btnAllegatiDisable">
                <b-icon icon="file-earmark-plus" aria-hidden="true"></b-icon>
                Allegati
            </b-button>

            <b-button variant="success btn-toolbar float-sm-right sa-margin-right" size="sm" @click="onSaveScheda('ATTIVA')"><b-icon icon="check2"></b-icon>Salva e Chiudi</b-button>
            <b-button variant="success btn-toolbar float-sm-right sa-margin-right" size="sm" @click="onSaveScheda('BOZZA')"><b-icon icon="check2"></b-icon>Salva Bozza</b-button>
        </template>
        <template slot="table-body">
            <b-tabs id="tabsSchede" nav-wrapper-class="sa-tab-items" content-class="mt-3" ref="tabs" class="sa-tab" style="height: 100%; padding-bottom: 25px" fill>
                <b-tab lazy title="Segnalazione" @click="disactiveAllegato()" active>
                    <div class="sa-tab-scroll">
                        <b-card class="sa-card" header="Segnalazione" header-tag="header" footer-tag="footer" title="">
                            <b-row>
                                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                    <label class="sa-label-data">Codice IcdIX</label>
                                    <b-form-input v-model="jsonData.codiceIcdix" disabled></b-form-input>
                                </b-col>
                                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                    <label class="sa-label-data">Malattia infettiva*</label>
                                    <b-input-group>
                                        <b-form-input v-model="jsonData.malattiaSegnalata" disabled></b-form-input>
                                        <b-button size="sm" variant="" @click="onClickBtnMalattiaSegnalata" :disabled="btnRicercaMalattiaDisabled"><b-icon icon="search"></b-icon></b-button>
                                        <!-- v-b-modal.mdlRicercaMalattia -->
                                    </b-input-group>
                                </b-col>
                                <!-- <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                                    <label class="sa-label-data">Altra Malattia*</label>
                                    <b-form-input v-model="jsonData.malattiaSegnalataAltra" :disabled="jsonData.codiceIcdix !== 'ASLNA10000'"></b-form-input>
                                </b-col> -->
                                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                    <label class="sa-label-data">Tipo Caso</label>
                                    <b-form-select v-model="jsonData.tipoCaso" :options="tipoCasoOptions" value-field="value" text-field="text"></b-form-select>
                                </b-col>
                                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                    <label class="sa-label-data">UOSD di diagnosi*</label>
                                    <b-form-select v-model="jsonData.uosdDiagnosi" :options="uosdDiagnosiOptions" value-field="value" text-field="text"></b-form-select>
                                </b-col>
                                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                    <label class="sa-label-data">UOSD competente</label>
                                    <b-form-select v-model="jsonData.uosdResidenza" :options="uosdDiagnosiOptions" value-field="value" text-field="text" disabled></b-form-select>
                                </b-col>
                                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                    <label class="sa-label-data">Data Segnalazione*</label>
                                    <date-picker format="DD/MM/YYYY" value-type="timestamp" v-model="jsonData.dataSegnalazione" type="date" required></date-picker>
                                </b-col>
                                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                    <label class="sa-label-data">Nome Medico*</label>
                                    <b-form-input v-model="jsonData.nomeMedicoSegnalante"></b-form-input>
                                </b-col>
                                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                    <label class="sa-label-data">Cognome Medico*</label>
                                    <b-form-input v-model="jsonData.cognomeMedicoSegnalante"></b-form-input>
                                </b-col>
                                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                    <label class="sa-label-data">Contatti Medico*</label>
                                    <b-form-input v-model="jsonData.contattoMedicoSegnalante"></b-form-input>
                                </b-col>
                            </b-row>
                        </b-card>
                        <b-card class="sa-card" header="Anagrafica" header-tag="header" footer-tag="footer" title="">
                            <b-row class="mt-2 mb-2">
                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                    <b-form-checkbox v-model="jsonData.noIdentificativo" switch @change="onChangeNoIdentificativo" :disabled="switchNoIdentificativoDisabled">Dichiaro sotto la mia responsabilita' che il paziente non dispone di Codice Fiscale/STP/ENI</b-form-checkbox>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                    <label class="sa-label-data">Codice Fiscale/STP/ENI*</label>
                                    <b-input-group class="mb-3">
                                        <b-form-input maxlength="16" class="text-uppercase" v-model="jsonData.pazienteBean.identificativo" @keypress="onKeyPress" :disabled="disableFieldIdentificativo"></b-form-input>
                                        <b-button size="sm" variant="" @click="onSearchAnagraficaClick" :disabled="disableFieldIdentificativo"><b-icon icon="search"></b-icon></b-button>
                                    </b-input-group>
                                </b-col>
                                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                    <label class="sa-label-data">Data Nascita*</label>
                                    <date-picker format="DD/MM/YYYY" value-type="timestamp" v-model="jsonData.pazienteBean.dataNascita" type="date" required :disabled="disableFieldsAnagrafica"></date-picker>
                                </b-col>
                                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                    <label class="sa-label-data">Cognome*</label>
                                    <b-form-input v-model="jsonData.pazienteBean.cognome" :disabled="disableFieldsAnagrafica"></b-form-input>
                                </b-col>
                                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                    <label class="sa-label-data">Nome*</label>
                                    <b-form-input v-model="jsonData.pazienteBean.nome" :disabled="disableFieldsAnagrafica"></b-form-input>
                                </b-col>
                                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                    <label class="sa-label-data">Sesso*</label>
                                    <b-form-select v-model="jsonData.pazienteBean.sesso" :options="sessoOptions" value-field="value" text-field="text" :disabled="disableFieldsAnagrafica"></b-form-select>
                                </b-col>
                                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                    <label class="sa-label-data">Contatto Telefonico*</label>
                                    <b-form-input v-model="jsonData.pazienteBean.telefono"></b-form-input>
                                </b-col>
                                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                    <label class="sa-label-data">Professione</label>
                                    <b-form-input v-model="jsonData.professione"></b-form-input>
                                </b-col>
                                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                    <label class="sa-label-data">Provincia Nascita*</label>
                                    <b-form-select v-model="jsonData.pazienteBean.provinciaNascita" :options="provinceNascitaOptions" @change="loadComuneNascita(jsonData.pazienteBean.provinciaNascita)" :value="null" :disabled="disableFieldsAnagrafica"></b-form-select>
                                </b-col>
                                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                    <label class="sa-label-data">Comune Nascita*</label>
                                    <b-form-select v-model="jsonData.pazienteBean.comuneNascitaCodiceIstat" :options="comuniNascitaOptions" :value="null" value-field="value" text-field="text" :disabled="disableFieldsAnagrafica"></b-form-select>
                                </b-col>
                                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                    <label class="sa-label-data">Email</label>
                                    <b-form-input v-model="jsonData.pazienteBean.email" type="email"></b-form-input>
                                </b-col>
                            </b-row>
                        </b-card>
                        <b-card class="sa-card" header="Residenza" header-tag="header" footer-tag="footer" title="">
                            <b-row>
                                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                    <label class="sa-label-data">Provincia*</label>
                                    <b-form-select v-model="jsonData.provinciaResidenza" :options="provinceResidenzaOptions" @change="loadComuneResidenza(jsonData.provinciaResidenza)" :value="null"></b-form-select>
                                </b-col>
                                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                    <label class="sa-label-data">Comune*</label>
                                    <b-form-select v-model="jsonData.comuneResidenzaCodiceIstat" :options="comuniResidenzaOptions" :value="null" value-field="codiceIstat" text-field="comune" @input="onComuneResidenzaInput"></b-form-select>
                                </b-col>
                                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                    <label class="sa-label-data">Indirizzo*</label>
                                    <b-form-input v-model="jsonData.indirizzoResidenza"></b-form-input>
                                </b-col>
                            </b-row>
                            <b-row class="mt-2 mb-2">
                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                    <b-form-checkbox v-model="isDomicilioUgualeResidenza" switch>Residenza uguale a Domicilio ?</b-form-checkbox>
                                </b-col>
                            </b-row>
                            <template v-if="!isDomicilioUgualeResidenza">
                                <hr />
                                <h5 class="sa-text-left">Domicilio</h5>
                                <b-row>
                                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                        <label class="sa-label-data">Provincia*</label>
                                        <b-form-select v-model="jsonData.provinciaDomicilio" :options="provinceDomicilioOptions" @change="loadComuneDomicilio(jsonData.provinciaDomicilio)" :value="null"></b-form-select>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                        <label class="sa-label-data">Comune*</label>
                                        <b-form-select v-model="jsonData.comuneDomicilioCodiceIstat" :options="comuniDomicilioOptions" :value="null" value-field="codiceIstat" text-field="comune" @input="onComuneDomicilioInput"></b-form-select>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                        <label class="sa-label-data">Indirizzo*</label>
                                        <b-form-input v-model="jsonData.indirizzoDomicilio"></b-form-input>
                                    </b-col>
                                </b-row>
                            </template>
                        </b-card>
                        <b-card class="sa-card" header="Segnalazione Informazioni" header-tag="header" footer-tag="footer" title="">
                            <b-row>
                                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                    <label class="sa-label-data">Data Inizio Sintomi*</label>
                                    <date-picker format="DD/MM/YYYY" value-type="timestamp" v-model="jsonData.dataInizioSintomi" type="date" required></date-picker>
                                </b-col>
                                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                    <label class="sa-label-data">Provincia Inizio Sintomi*</label>
                                    <b-form-select v-model="jsonData.provinciaInizioSintomi" :options="provinceSintomiOptions" @change="loadComuneInizioSintomi(jsonData.provinciaInizioSintomi)" :value="null"></b-form-select>
                                </b-col>
                                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                    <label class="sa-label-data">Comune Inizio Sintomi*</label>
                                    <b-form-select v-model="jsonData.comuneInizioSintomiCodiceIstat" :options="comuniSintomiOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                                </b-col>
                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                    <label class="sa-label-data">Sintomi</label>
                                    <b-form-textarea v-model="jsonData.descrizioneSintomi" rows="4" no-resize></b-form-textarea>
                                </b-col>
                                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                                    <label class="sa-label-data">Ricovero in luogo di cura*</label>
                                    <b-form-select v-model="jsonData.ricoveroLuogoCura" :options="ricoveroLuogoCuraOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                                </b-col>
                                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                                    <label class="sa-label-data">Specificare</label>
                                    <b-form-select v-model="jsonData.specificareLuogoCura" :options="specificareLuogoCuraOptions" :value="null" value-field="value" text-field="text" :disabled="jsonData.ricoveroLuogoCura !== 'SI'"></b-form-select>
                                </b-col>
                                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                                    <label class="sa-label-data">Specificare Altro</label>
                                    <b-form-input v-model="jsonData.specificareLuogoCuraAltro" :disabled="jsonData.ricoveroLuogoCura !== 'SI' || jsonData.specificareLuogoCura !== 'Altro'"></b-form-input>
                                </b-col>
                                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                                    <label class="sa-label-data">Data Ricovero</label>
                                    <date-picker format="DD/MM/YYYY" value-type="timestamp" v-model="jsonData.dataRicovero" type="date" :disabled="jsonData.ricoveroLuogoCura !== 'SI'"></date-picker>
                                </b-col>
                                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                                    <label class="sa-label-data">Vaccino ( se esiste )*</label>
                                    <b-form-select v-model="jsonData.vaccino" :options="vacciniOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                                </b-col>
                                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                                    <label class="sa-label-data">Numero Dosi</label>
                                    <b-form-input v-model="jsonData.numeroDosi" type="number" :disabled="jsonData.vaccino !== 'VACCINATO'"></b-form-input>
                                </b-col>
                                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                                    <label class="sa-label-data">Data ultima dose</label>
                                    <date-picker format="DD/MM/YYYY" value-type="timestamp" v-model="jsonData.dataUltimaDose" type="date" required :disabled="jsonData.vaccino !== 'VACCINATO'"></date-picker>
                                </b-col>
                                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                                    <label class="sa-label-data">Tipo di Vaccino</label>
                                    <b-form-input v-model="jsonData.tipoVaccino" :disabled="jsonData.vaccino !== 'VACCINATO'"></b-form-input>
                                    <!-- <b-form-select v-model="jsonData.tipoVaccino" :options="tipiVacciniOptions" :value="null" value-field="value" text-field="text" :disabled="jsonData.vaccino !== 'VACCINATO'"></b-form-select> -->
                                </b-col>
                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                    <label class="sa-label-data">Clinica</label>
                                    <b-form-select v-model="jsonData.datiClinici" :options="siNoOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                                </b-col>
                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                    <label class="sa-label-data">Dettaglio</label>
                                    <b-form-input v-model="jsonData.datiCliniciDettaglio" :disabled="jsonData.datiClinici !== 'SI'"></b-form-input>
                                </b-col>
                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                    <label class="sa-label-data">Esami Colturali*</label>
                                    <b-form-select v-model="jsonData.esamiCulturali" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                                </b-col>
                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                    <label class="sa-label-data">Dettaglio</label>
                                    <b-form-input v-model="jsonData.esamiColturaliDettaglio" :disabled="jsonData.esamiCulturali !== 'SI'"></b-form-input>
                                </b-col>
                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                    <label class="sa-label-data">Esame Diretto*</label>
                                    <b-form-select v-model="jsonData.esameDiretto" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                                </b-col>
                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                    <label class="sa-label-data">Dettaglio</label>
                                    <b-form-input v-model="jsonData.esameDirettoDettaglio" :disabled="jsonData.esameDiretto !== 'SI'"></b-form-input>
                                </b-col>
                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                    <label class="sa-label-data">Esami Sierologici*</label>
                                    <b-form-select v-model="jsonData.esamiSierologici" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                                </b-col>
                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                    <label class="sa-label-data">Esami Sierologici Dettaglio</label>
                                    <b-form-input v-model="jsonData.esamiSierologiciDettagli" :disabled="jsonData.esamiSierologici !== 'SI'"></b-form-input>
                                </b-col>
                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                    <label class="sa-label-data">Biologica Molecolare</label>
                                    <b-form-select v-model="jsonData.biologiaMolecolare" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                                </b-col>
                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                    <label class="sa-label-data">Dettaglio</label>
                                    <b-form-input v-model="jsonData.biologiaMolecolareDettaglio" :disabled="jsonData.biologiaMolecolare !== 'SI'"></b-form-input>
                                </b-col>
                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                    <label class="sa-label-data">Eventuale Fonte Contagio</label>
                                    <b-form-input v-model="jsonData.fonteContagio"></b-form-input>
                                </b-col>
                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                    <label class="sa-label-data">Altri Casi Correlati</label>
                                    <b-form-select v-model="jsonData.altriCasiCorelati" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                                </b-col>
                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                    <label class="sa-label-data">Numero</label>
                                    <b-form-input v-model="jsonData.altriCasiCorelatiNumero" :disabled="jsonData.altriCasiCorelati !== 'SI'"></b-form-input>
                                </b-col>
                            </b-row>
                        </b-card>
                        <b-card class="sa-card" header="Note" header-tag="header" footer-tag="footer" title="">
                            <b-row>
                                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                    <b-form-textarea v-model="jsonData.note" rows="8" no-resize></b-form-textarea>
                                </b-col>
                            </b-row>
                        </b-card>
                        <b-card class="sa-card" header="Riferimenti" header-tag="header" footer-tag="footer" title="">
                            <b-row>
                                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                    <span style="font-weight: bold">Struttura:</span>
                                    {{ jsonData.codiceStruttura }} - {{ jsonData.denominazione }}
                                </b-col>
                            </b-row>
                        </b-card>
                    </div>
                </b-tab>
                <b-tab title="Allegati" @click="activeAllegato()" :disabled="tabAllegatiDisabled">
                    <allegato-model-component :idRisorsa="id" :jsonData="jsonData" @updateFiles="onUpdateFile()" :key="childKey" tipoRisorsa="MALATTIE INFETTIVE"></allegato-model-component>
                </b-tab>
            </b-tabs>
        </template>
        <template slot="table-footer">
            <b-modal ref="mdlRicercaMalattia" id="mdlRicercaMalattia" title="Ricerca Malattia" size="xl" scrollable>
                <div class="sa-form-section">
                    <b-tabs id="tabsSchede" nav-wrapper-class="sa-tab-items" content-class="mt-3" ref="tabs" class="sa-tab" style="height: 100%; padding-bottom: 25px" fill>
                        <b-tab title="Lista Malattie" @click="onClickListaMalattie">
                            <b-row>
                                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                    <b-form @submit.prevent="onClickRicercaMalattiaMdl">
                                        <b-input-group>
                                            <b-form-input v-model="filtro.descrizione"></b-form-input>
                                            <b-button size="sm" @click="onClickRicercaMalattiaMdl"><b-icon icon="search"></b-icon></b-button>
                                        </b-input-group>
                                    </b-form>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col class="sa-padding-right text-right">
                                    <b-button type="reset" v-on:click="onResetFiltroMdl" variant="danger">Resetta Filtro</b-button>
                                </b-col>
                            </b-row>
                            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                                <b-col lg="3">
                                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                                </b-col>
                                <b-col lg="9">
                                    <b-pagination v-model="filtro.page" :total-rows="rows" :per-page="filtro.forPage" align="right" size="sm"></b-pagination>
                                </b-col>
                            </b-row>
                            <!-- <div class="sa-tab-scroll" style="padding-bottom: 25px"> -->
                            <!-- <b-row>
                                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"> -->
                            <div class="b-table-sticky-header">
                                <b-table sticky-header ref="tblMalattie" stacked="xl" striped hover itemscope :items="malattieSegnalate" :fields="fieldsMlattieSegnalate" :current-page="filtro.page" :per-page="filtro.forPage" sort-icon-left head-variant="light" class="sa-b-table" selectable select-mode="single" @row-selected="onRowSelectedMalattia">
                                    <template v-slot:cell(codiceIcdix)="{ item }">
                                        <template v-if="item.entroOre !== null && item.entroOre !== ''">
                                            <label class="sa-label-data">{{ item.codiceIcdix }}</label>
                                        </template>
                                        <template v-else>
                                            {{ item.codiceIcdix }}
                                        </template>
                                    </template>
                                    <template v-slot:cell(descrizioneIcdix)="{ item }">
                                        <template v-if="item.entroOre !== null && item.entroOre !== ''">
                                            <label class="sa-label-data">{{ item.descrizioneIcdix }}</label>
                                        </template>
                                        <template v-else>
                                            {{ item.descrizioneIcdix }}
                                        </template>
                                    </template>
                                    <template v-slot:cell(entroOre)="{ item }">
                                        <template v-if="item.entroOre !== null && item.entroOre !== ''">
                                            <label class="sa-label-data">{{ item.entroOre }}</label>
                                        </template>
                                        <template v-else>
                                            {{ item.entroOre }}
                                        </template>
                                    </template>
                                    <template #cell(selezionato)="{ rowSelected }">
                                        <template v-if="rowSelected">
                                            <span aria-hidden="true">&check;</span>
                                            <span class="sr-only">Selezionato</span>
                                        </template>
                                        <template v-else>
                                            <span aria-hidden="true">&nbsp;</span>
                                            <span class="sr-only">Non Selezionato</span>
                                        </template>
                                    </template>
                                </b-table>
                                <!-- </b-col>
                            </b-row> -->
                            </div>
                        </b-tab>
                        <b-tab title="Nuova Malattia" @click="onClickNuovaMalattia">
                            <!-- <b-row>
                                <b-col class="sa-padding-right text-right">
                                    <b-button variant="success btn-toolbar sa-margin-right float-sm-right" size="sm" @click="onSave">
                                        <b-icon icon="check2"></b-icon>
                                        <span style="padding-left: 5px">Salva</span>
                                    </b-button>
                                </b-col>
                            </b-row> -->
                            <b-row>
                                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                    <label class="sa-label-data">Denominazione Malattia:</label>
                                    <b-form-input v-model="malattia.descrizioneIcdix"></b-form-input>
                                </b-col>
                                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                    <label class="sa-label-data">Indagine Associata:</label>
                                    <!-- <b-form-input v-model="malattia.indagineEpidemiologica"></b-form-input> -->
                                    <b-form-select v-model="malattia.indagineEpidemiologica" :options="tipiIndagineOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                                </b-col>
                                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                    <label class="sa-label-data">Classe Priorita' (1 - 100):</label>
                                    <b-form-input v-model="malattia.classePriorita"></b-form-input>
                                </b-col>
                                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                    <label class="sa-label-data">Entro Ore:</label>
                                    <b-form-input v-model="malattia.entroOre"></b-form-input>
                                </b-col>
                            </b-row>
                        </b-tab>
                    </b-tabs>
                </div>
                <template #modal-footer>
                    <b-button variant="success" size="sm" @click="onSave" :disabled="!disableModalButton">
                        <b-icon icon="check2"></b-icon>
                        <span style="padding-left: 5px">Salva</span>
                    </b-button>
                    <b-button size="sm" variant="outline-secondary" @click="onHiddenMdlRicercaMalattia()" :disabled="disableModalButton"> Chiudi </b-button>
                    <b-button size="sm" variant="danger" @click="onOkMdlRicercaMalattia()" :disabled="disableModalButton">Ok</b-button>
                </template>
            </b-modal>
        </template>
    </sa-page-layout>
</template>

<script>
import axios from "axios";
import moment from "moment";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import AllegatoModelComponent from "../../utility/components/AllegatoModelComponent.vue";
import UtilityMixin from "../../utility/UtilityMixin";
export default {
    props: {
        utente: {
            type: Object,
            default: function () {
                return { gruppo: { menuGruppo: [] } };
            },
        },
    },
    components: { SaPageLayout, DatePicker, AllegatoModelComponent },
    mixins: [UtilityMixin],
    mounted: function () {
        let me = this;
        me.id = this.$route.params.id;
        me.fonte = this.$route.query.fonte;
        if (me.id !== "-1") {
            me.linkback = "/malattieinfettive/segnalazioni/view/" + me.id + "?fonte=" + me.fonte;
            me.btnRicercaMalattiaDisabled = true;
            me.tabAllegatiDisabled = false;
        } else {
            me.linkback = "/malattieinfettive/segnalazioni";
            me.btnRicercaMalattiaDisabled = false;
            me.tabAllegatiDisabled = true;
        }
        me.loadData();
        me.loadDefaultData();
    },
    computed: {
        rows() {
            return this.malattieSegnalate.length;
        },
    },
    data() {
        return {
            id: "-1",
            isDomicilioUgualeResidenza: false,
            pathResource: "/malattieinfettivesegnalazioni",
            pathResourceMalattie: "/malattieinfettivemalattie",
            pathResourceIndagini: "/malattieinfettiveietipi",
            disableModalButton: false,
            showModalLoading: false,
            switchNoIdentificativoDisabled: false,
            btnRicercaMalattiaDisabled: false,
            tabAllegatiDisabled: true,
            btnAllegatiDisable: true,
            disableFieldIdentificativo: false,
            disableFieldsAnagrafica: true,
            linkback: null,
            childKey: 1,
            linkProvince: "/province",
            linkComune: "/comuni/provincia",
            malattiaSelezionata: "",
            codiceIcdixSelezionato: "",
            malattia: { descrizioneIcdix: "", indagineEpidemiologica: "", classePriorita: null, entroOre: "" },
            jsonData: {
                malattiaSegnalata: "",
                professione: null,
                dataInizioSintomi: null,
                comuneInizioSintomiCodiceIstat: null,
                provinciaInizioSintomi: null,
                vaccino: null,
                numeroDosi: 0,
                dataUltimaDose: null,
                tipoVaccino: null,
                nomeMedicoSegnalante: "",
                cognomeMedicoSegnalante: "",
                contattoMedicoSegnalante: "",
                dataSegnalazione: null,
                ricoveroLuogoCura: null,
                specificareLuogoCura: null,
                specificareLuogoCuraAltro: "",
                comuneResidenzaCodiceIstat: null,
                indirizzoResidenza: "",
                comuneDomicilioCodiceIstat: null,
                indirizzoDomicilio: "",
                statoAttuale: null,
                statoAttualeDataOra: null,
                provinciaResidenza: null,
                provinciaDomicilio: null,
                uosdDiagnosi: null,
                uosdResidenza: null,
                dataRicovero: null,
                datiClinici: "",
                esamiCulturali: "",
                esameDiretto: "",
                esamiSierologici: "",
                esamiSierologiciDettagli: "",
                descrizioneSintomi: "",
                datiCliniciDettaglio: "",
                esameDirettoDettaglio: "",
                esamiColturaliDettaglio: "",
                biologiaMolecolare: "",
                biologiaMolecolareDettaglio: "",
                altriCasiCorelati: "",
                altriCasiCorelatiNumero: "",
                fonteContagio: "",
                malattiaSegnalataAltra: "",
                codiceStruttura: "",
                denominazione: "",
                tipoCaso: "",
                note: "",
                statoSegnalazione: "ATTIVA",
                pazienteBean: {
                    identificativo: "",
                    cognome: "",
                    nome: "",
                    dataNascita: null,
                    comuneNascitaCodiceIstat: null,
                    comuneNascita: null,
                    comuneResidenzaCodiceIstat: null,
                    comuneDomicilioCodiceIstat: null,
                    indirizzoResidenza: "",
                    indirizzoDomicilio: "",
                    telefono: "",
                    sesso: null,
                    provinciaNascita: null,
                    provinciaResidenza: null,
                    provinciaDomicilio: null,
                    codiceFiscaleMedicoCurante: "",
                    medicoCurante: "",
                    capResidenza: "",
                    capDomicilio: "",
                    capNascita: "",
                    codiceStruttura: "000000",
                },
            },
            filtro: { descrizione: "", forPage: 100, page: 1 },
            malattieSegnalate: [],
            uosdDiagnosiOptions: [],
            nazionalitaOptions: [],
            sessoOptions: [
                { text: "- Seleziona - ", value: null },
                { text: "Maschio", value: "M" },
                { text: "Femmina", value: "F" },
            ],
            vacciniOptions: [],
            provinceNascitaOptions: [],
            comuniNascitaOptions: [{ value: null, text: "-Seleziona-" }],
            provinceResidenzaOptions: [],
            comuniResidenzaOptions: [{ codiceIstat: null, comune: "-Seleziona-" }],
            provinceDomicilioOptions: [],
            comuniDomicilioOptions: [{ codiceIstat: null, comune: "-Seleziona-" }],
            ricoveroLuogoCuraOptions: [],
            specificareLuogoCuraOptions: [],
            provinceSintomiOptions: [{ value: null, text: "-Seleziona-" }],
            comuniSintomiOptions: [{ value: null, text: "-Seleziona-" }],
            tipiVacciniOptions: [],
            tipiIndagineOptions: [],
            // professioniOptions: [],
            siNoOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "SI", text: "SI" },
                { value: "NO", text: "NO" },
            ],
            siNoNonNotoOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "SI", text: "SI" },
                { value: "NO", text: "NO" },
                { value: "NON NOTO", text: "NON NOTO" },
            ],
            tipoCasoOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "SOSPETTO", text: "SOSPETTO" },
                { value: "PROBABILE", text: "PROBABILE" },
                { value: "ACCERTATO", text: "ACCERTATO" },
            ],
            fieldsMlattieSegnalate: [
                {
                    label: "",
                    key: "selezionato",
                    thStyle: "width: 2.5rem",
                },
                {
                    label: "Codice IcdIX",
                    key: "codiceIcdix",
                    thStyle: "width: 4rem",
                    sortable: false,
                },
                {
                    label: "Descrizione",
                    key: "descrizioneIcdix",
                    sortable: false,
                },
                {
                    label: "Entro Ore",
                    key: "entroOre",
                    thStyle: "width: 10rem",
                    sortable: false,
                },
            ],
        };
    },
    watch: {
        isDomicilioUgualeResidenza: function (value) {
            let me = this;
            if (value) {
                console.log(value);
                me.jsonData.provinciaDomicilio = me.jsonData.provinciaResidenza;
                me.jsonData.comuneDomicilioCodiceIstat = me.jsonData.comuneResidenzaCodiceIstat;
                me.jsonData.indirizzoDomicilio = me.jsonData.indirizzoResidenza;
                me.jsonData.pazienteBean.capDomicilio = me.jsonData.pazienteBean.capResidenza;
            } else {
                me.jsonData.provinciaDomicilio = null;
                me.jsonData.comuneDomicilioCodiceIstat = null;
                me.jsonData.indirizzoDomicilio = null;
            }
        },
        // "jsonData.noIdentificativo": function (value) {
        //     let me = this;
        //     me.jsonData.pazienteBean.identificativo = "";
        //     me.jsonData.pazienteBean.nome = "";
        //     me.jsonData.pazienteBean.cognome = "";
        //     me.jsonData.pazienteBean.dataNascita = null;
        //     me.jsonData.pazienteBean.sesso = null;
        //     me.jsonData.pazienteBean.provinciaNascita = null;
        //     me.jsonData.provinciaResidenza = null;
        //     me.jsonData.indirizzoResidenza = null;
        //     me.jsonData.pazienteBean.codiceFiscaleMedicoCurante = "";
        //     me.jsonData.pazienteBean.medicoCurante = "";
        //     me.disableFieldIdentificativo = value;
        //     me.disableFieldsAnagrafica = !value;
        // },
    },
    methods: {
        activeAllegato() {
            let me = this;
            me.btnAllegatiDisable = false;
        },
        disactiveAllegato() {
            let me = this;
            me.btnAllegatiDisable = true;
        },
        loadData() {
            let me = this;
            me.showModalLoading = true;
            me.switchNoIdentificativoDisabled = false;
            if (me.id === "-1") {
                me.loadStrutturaUtente();
                me.showModalLoading = false;
                me.setDatiMedico();
            } else {
                me.switchNoIdentificativoDisabled = true;
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                me.showModalLoading = true;
                axios
                    .get(link + me.id)
                    .then((response) => {
                        me.jsonData = response.data.data;
                        // console.log(me.jsonData);
                        // console.log(me.jsonData);

                        me.loadComuneNascita(me.jsonData.pazienteBean.provinciaNascita);
                        me.loadComuneResidenza(me.jsonData.provinciaResidenza);
                        me.loadComuneDomicilio(me.jsonData.provinciaDomicilio);
                        me.loadComuneInizioSintomi(me.jsonData.provinciaInizioSintomi);
                        me.showModalLoading = false;
                    })
                    .catch((e) => {
                        e;
                        me.showModalLoading = false;
                    });
            }
        },
        onChangeNoIdentificativo() {
            let me = this;
            me.jsonData.pazienteBean.identificativo = "";
            me.jsonData.pazienteBean.nome = "";
            me.jsonData.pazienteBean.cognome = "";
            me.jsonData.pazienteBean.dataNascita = null;
            me.jsonData.pazienteBean.sesso = null;
            me.jsonData.pazienteBean.provinciaNascita = null;
            me.jsonData.provinciaResidenza = null;
            me.jsonData.indirizzoResidenza = null;
            me.jsonData.pazienteBean.codiceFiscaleMedicoCurante = "";
            me.jsonData.pazienteBean.medicoCurante = "";
            me.disableFieldIdentificativo = me.jsonData.noIdentificativo;
            me.disableFieldsAnagrafica = !me.jsonData.noIdentificativo;
        },
        loadStrutturaUtente() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + "/malattieinfettivestruttureutenti/struttura";
            axios
                .get(link)
                .then((response) => {
                    me.jsonData.codiceStruttura = response.data.data.codiceStruttura;
                    me.jsonData.denominazione = response.data.data.denominazione;
                })
                .catch(() => {
                    // console.log(error);
                    me.jsonData.codiceStruttura = "000";
                    me.jsonData.denominazione = "MMG";
                });
        },
        loadDefaultData() {
            let me = this;
            // me.loadMalattieInfettiveIcdIx();
            me.loadUosdDiagnosi();
            me.loadLuogoCasaCure();
            me.loadSpecificareLuogoCura();
            me.loadVaccino();
            me.loadProvince();
            me.loadNazioni();
            // me.loadTipoVaccino();
            // me.loadProfessioni();
        },
        onClickBtnMalattiaSegnalata() {
            let me = this;
            me.$refs.mdlRicercaMalattia.show();
            me.loadMalattieInfettiveIcdIx();
        },
        loadMalattieInfettiveIcdIx() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceMalattie; //"/serviziicdix/malattieinfettive?descrizione=" + me.filtro.descrizione;
            axios.get(link, { params: me.filtro }).then((response) => {
                // console.log(response);
                me.malattieSegnalate = response.data.data.list;
                me.showModalLoading = false;
            });
        },
        loadNazioni() {
            let me = this;
            let linkNazioni = process.env.VUE_APP_PATH_API + "/nazioni";
            axios.get(linkNazioni).then((response) => {
                me.nazionalitaOptions = response.data.data;
            });
        },
        loadUosdDiagnosi() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + "/malattieinfettiveuosd?page=1&forPage=100";
            axios.get(link).then((response) => {
                response.data.data.list.forEach((element) => {
                    me.uosdDiagnosiOptions.push({
                        text: element.descrizione,
                        value: element.descrizione,
                    });
                });
                me.uosdDiagnosiOptions.unshift({ text: "-Seleziona-", value: null });
            });
        },

        loadLuogoCasaCure() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + "/malattieinfettivericoveriluoghicure?page=1&forPage=100";
            axios.get(link).then((response) => {
                response.data.data.list.forEach((element) => {
                    me.ricoveroLuogoCuraOptions.push({
                        text: element.descrizione,
                        value: element.descrizione,
                    });
                });
                me.ricoveroLuogoCuraOptions.unshift({
                    text: "-Seleziona-",
                    value: null,
                });
            });
        },
        loadSpecificareLuogoCura() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + "/malattieinfettivesegnalazioneluogocura";
            axios.get(link).then((response) => {
                response.data.data.list.forEach((element) => {
                    me.specificareLuogoCuraOptions.push({
                        text: element.luogoCura,
                        value: element.luogoCura,
                    });
                });
                me.specificareLuogoCuraOptions.unshift({
                    text: "-Seleziona-",
                    value: null,
                });
            });
        },
        loadVaccino() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + "/malattieinfettivevaccini?page=1&forPage=100";
            axios.get(link).then((response) => {
                response.data.data.list.forEach((element) => {
                    me.vacciniOptions.push({
                        text: element.descrizione,
                        value: element.descrizione,
                    });
                });
                me.vacciniOptions.unshift({ text: "-Seleziona-", value: null });
            });
        },
        // loadProfessioni() {
        //     let me = this;
        //     let link = process.env.VUE_APP_PATH_API + "/malattieinfettiveprofessioni?page=1&forPage=100";
        //     axios.get(link).then((response) => {
        //         response.data.data.list.forEach((element) => {
        //             me.professioniOptions.push({ text: element.descrizione, value: element.descrizione });
        //         });
        //         me.professioniOptions.unshift({ text: "-Seleziona-", value: null });
        //     });
        // },
        // loadTipoVaccino() {
        //     let me = this;
        //     let link = process.env.VUE_APP_PATH_API + "/malattieinfettivetipivaccini?page=1&forPage=100";
        //     axios.get(link).then((response) => {
        //         response.data.data.list.forEach((element) => {
        //             me.tipiVacciniOptions.push({ text: element.descrizione, value: element.descrizione });
        //         });
        //         me.tipiVacciniOptions.unshift({ text: "-Seleziona-", value: null });
        //     });
        // },
        loadProvince() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkProvince;
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    me.provinceNascitaOptions.push({
                        value: element.sigla,
                        text: element.provincia,
                    });
                    me.provinceResidenzaOptions.push({
                        value: element.sigla,
                        text: element.provincia,
                    });
                    me.provinceDomicilioOptions.push({
                        value: element.sigla,
                        text: element.provincia,
                    });
                    me.provinceSintomiOptions.push({
                        value: element.sigla,
                        text: element.provincia,
                    });
                });
                me.provinceNascitaOptions.unshift({ text: "-Seleziona-", value: null });
                me.provinceResidenzaOptions.unshift({
                    text: "-Seleziona-",
                    value: null,
                });
                me.provinceDomicilioOptions.unshift({
                    text: "-Seleziona-",
                    value: null,
                });
                me.provinceSintomiOptions.unshift({ text: "-Seleziona-", value: null });
            });
        },
        setDatiNascita(siglaProvincia, codiceIstatComuneNascita = null) {
            let me = this;
            me.jsonData.pazienteBean.comuneNascitaCodiceIstat = codiceIstatComuneNascita;
            me.provinceNascitaOptions.forEach((provincia) => {
                if (provincia.value === siglaProvincia) {
                    me.jsonData.pazienteBean.provinciaNascita = provincia.value;
                    me.loadComuneNascita(provincia.value);
                    me.setCap(me.jsonData.pazienteBean.comuneNascitaCodiceIstat);
                }
            });
        },
        loadComuneNascita(sigla) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkComune + "/" + sigla;
            axios.get(link).then((response) => {
                me.comuniNascitaOptions = [];
                response.data.data.forEach((element) => {
                    me.comuniNascitaOptions.push({
                        value: element.codiceIstat,
                        text: element.comune,
                    });
                });
                me.setCap(me.jsonData.pazienteBean.comuneNascitaCodiceIstat);
            });
        },
        setCap(value) {
            let me = this;
            me.comuniNascitaOptions.forEach((comune) => {
                // console.log(comune);
                if (comune.value === value) {
                    // console.log("ECCOMU");
                    // me.pazienteBean.capNascita = comune.cap;
                    me.jsonData.pazienteBean.comuneNascita = comune.text;
                }
            });
        },
        setDatiResidenza(siglaProvincia, codiceIstatComuneResidenza = null) {
            let me = this;
            //   console.log(codiceIstatComuneResidenza);
            me.jsonData.comuneResidenzaCodiceIstat = codiceIstatComuneResidenza;
            me.provinceResidenzaOptions.forEach((provincia) => {
                if (provincia.value === siglaProvincia) {
                    me.jsonData.provinciaResidenza = provincia.value;
                    me.loadComuneResidenza(provincia.value);
                }
            });
        },
        loadComuneResidenza(sigla) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkComune + "/" + sigla;
            axios.get(link).then((response) => {
                me.comuniResidenzaOptions = response.data.data;
            });
        },
        onComuneResidenzaInput(codiceIstatComuneResidenza) {
            let me = this;
            me.comuniResidenzaOptions.forEach((comune) => {
                if (comune.codiceIstat === codiceIstatComuneResidenza) {
                    me.jsonData.pazienteBean.capResidenza = comune.cap;
                }
            });
        },
        loadComuneDomicilio(sigla) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkComune + "/" + sigla;
            axios.get(link).then((response) => {
                me.comuniDomicilioOptions = [];
                me.comuniDomicilioOptions = response.data.data;
            });
        },
        onComuneDomicilioInput(codiceIstatComuneDomicilio) {
            let me = this;
            me.comuniDomicilioOptions.forEach((comune) => {
                if (comune.codiceIstat === codiceIstatComuneDomicilio) {
                    me.jsonData.pazienteBean.capDomicilio = comune.cap;
                }
            });
        },
        loadComuneInizioSintomi(sigla) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkComune + "/" + sigla;
            axios.get(link).then((response) => {
                me.comuniSintomiOptions = [];
                response.data.data.forEach((element) => {
                    me.comuniSintomiOptions.push({
                        value: element.codiceIstat,
                        text: element.comune,
                    });
                });
            });
        },
        setDatiMedico() {
            let me = this;
            me.jsonData.nomeMedicoSegnalante = me.utente.firstname;
            me.jsonData.cognomeMedicoSegnalante = me.utente.lastname;
        },
        onKeyPress(e) {
            let me = this;
            if (e.keyCode === 13) {
                me.onSearchAnagraficaClick();
            }
        },
        onSearchAnagraficaClick() {
            let me = this;
            me.showmolalloading = true;
            if (me.jsonData.pazienteBean.identificativo.trim().length === 0) {
                me.showmolalloading = false;
            } else {
                me.jsonData.pazienteBean.identificativo = me.jsonData.pazienteBean.identificativo.toUpperCase();
                let link = process.env.VUE_APP_PATH_API + "/utility";
                axios
                    .get(link, {
                        params: { codiceFiscale: me.jsonData.pazienteBean.identificativo },
                    })
                    .then((response) => {
                        me.jsonData.pazienteBean.nome = response.data.data.nome;
                        me.jsonData.pazienteBean.cognome = response.data.data.cognome;
                        me.jsonData.pazienteBean.dataNascita = moment(response.data.data.dataNascita, "DD/MM/YYYY").toDate().getTime();
                        me.jsonData.pazienteBean.sesso = response.data.data.sesso;
                        me.jsonData.pazienteBean.provinciaNascita = response.data.data.provinciaNascita;
                        me.setDatiNascita(response.data.data.provinciaNascita, response.data.data.codiceIstatComuneNascita);
                        me.jsonData.provinciaResidenza = response.data.data.provinciaResidenza;
                        me.setDatiResidenza(response.data.data.provinciaResidenza, response.data.data.codiceIstatComuneResidenza);
                        me.jsonData.indirizzoResidenza = response.data.data.indirizzoResidenza;
                        me.jsonData.pazienteBean.codiceFiscaleMedicoCurante = response.data.data.codiceFiscaleMedico;
                        me.jsonData.pazienteBean.medicoCurante = response.data.data.medico;
                        me.showmolalloading = false;
                    })
                    .catch(() => {
                        me.showmolalloading = false;
                    });
            }
        },
        onClickRicercaMalattiaMdl() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadMalattieInfettiveIcdIx();
        },
        onResetFiltroMdl() {
            let me = this;
            me.filtro.descrizione = "";
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadMalattieInfettiveIcdIx();
        },
        onHiddenMdlRicercaMalattia() {
            let me = this;
            me.filtro.descrizione = "";
            me.malattia = { descrizioneIcdix: "", indagineEpidemiologica: "", classePrioritaclassePriorita: null, entroOre: "" };
            me.$refs["mdlRicercaMalattia"].hide();
        },
        onOkMdlRicercaMalattia() {
            let me = this;
            me.jsonData.malattiaSegnalata = me.malattiaSelezionata;
            me.jsonData.codiceIcdix = me.codiceIcdixSelezionato;
            me.malattia = { descrizioneIcdix: "", indagineEpidemiologica: "", classePrioritaclassePriorita: null, entroOre: "" };
            me.$refs["mdlRicercaMalattia"].hide();
        },
        onRowSelectedMalattia(rows) {
            let me = this;
            if (rows[0]) {
                me.malattiaSelezionata = rows[0].descrizioneIcdix + " (" + rows[0].codiceIcdix + ")";
                me.codiceIcdixSelezionato = rows[0].codiceIcdix;
            }
        },
        onClickListaMalattie() {
            let me = this;
            me.disableModalButton = false;
            me.malattia = { descrizioneIcdix: "", indagineEpidemiologica: "", classePrioritaclassePriorita: null, entroOre: "" };
            me.loadMalattieInfettiveIcdIx();
        },
        onClickNuovaMalattia() {
            let me = this;
            me.disableModalButton = true;
            me.loadListaIndagini();
        },
        loadListaIndagini() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceIndagini;
            me.tipiIndagineOptions = [];
            axios.get(link).then((response) => {
                // me.tipiIndagineOptions = response.data.data.list;
                response.data.data.list.forEach((element) => {
                    me.tipiIndagineOptions.push({ value: element.indagineEpidemiologica, text: element.indagineEpidemiologica });
                });
                me.tipiIndagineOptions.unshift({ text: "-Seleziona Indagine-", value: "" });
            });
        },
        onSave() {
            console.log("salvo");
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceMalattie;
            axios
                .post(link, JSON.stringify(me.malattia))
                .then((response) => {
                    console.log(response.data.data.id);
                    // me.id = response.data.data.id;
                    me.showModalLoading = false;
                })
                .catch((error) => {
                    me.showModalLoading = false;
                    this.$bvModal
                        .msgBoxOk(error.response.data.messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then((value) => {
                            this.boxTwo = value;
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                });
        },
        onSaveScheda(stato) {
            let me = this;
            me.jsonData.statoSegnalazione = stato;
            me.$refs.cmpSaPageLayout.save(true);
        },
    },
};
</script>

<style></style>
