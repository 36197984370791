<template>
  <sa-page-layout :showModalLoading="showModalLoading" :btnNewVisible="false" :btnRefreshVisible="true" @refresh="onRefresh()" :linkedit="linkEvent" :pathResource="pathResource">
    <template slot="toolbar">
      <b-button variant="btn-toolbar btn btn-outline-primary" size="sm" @click="onClickExportCsv">
        <i class="bi bi-download sa-icon-i"></i>
        Export Excel
      </b-button>
    </template>
    <template slot="table-filter">
      <h3 class="sa-event-title">{{ titoloEvento }}</h3>
      <b-form @submit.prevent="onSubmit">
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-label_data">Codice Fiscale Paziente</label>
            <!-- <b-form-select v-model="filtro.idPaziente" :options="pazientiOptions" :value="null" value-field="value" text-field="text"></b-form-select> -->
            <b-form-input v-model="filtro.codiceFiscale" style="text-transform: uppercase"></b-form-input>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-label_data">Nome Paziente</label>
            <!-- <b-form-select v-model="filtro.idPaziente" :options="pazientiOptions" :value="null" value-field="value" text-field="text"></b-form-select> -->
            <b-form-input v-model="filtro.nomePaziente" style="text-transform: uppercase"></b-form-input>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-label_data">Cognome Paziente</label>
            <!-- <b-form-select v-model="filtro.idPaziente" :options="pazientiOptions" :value="null" value-field="value" text-field="text"></b-form-select> -->
            <b-form-input v-model="filtro.cognomePaziente" style="text-transform: uppercase"></b-form-input>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-label_data">Nome Medico</label>
            <!-- <b-form-select v-model="filtro.idPaziente" :options="pazientiOptions" :value="null" value-field="value" text-field="text"></b-form-select> -->
            <b-form-input v-model="filtro.nomeMedico" style="text-transform: uppercase"></b-form-input>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-label_data">Cognome Medico</label>
            <!-- <b-form-select v-model="filtro.idPaziente" :options="pazientiOptions" :value="null" value-field="value" text-field="text"></b-form-select> -->
            <b-form-input v-model="filtro.cognomeMedico" style="text-transform: uppercase"></b-form-input>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-label_data">Tipo Evento</label>
            <b-form-select v-model="filtro.tipoEvento" :options="tipiEventiOptions" :value="null" value-field="value" text-field="text"></b-form-select>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label_data">Ambulatorio</label>
            <b-form-select v-model="filtro.idAmbulatorio" :options="ambulatoriOptions" :value="null" value-field="value" text-field="text"></b-form-select>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">Data Dal</label>
            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="filtro.dataDal" type="date" placeholder="Data Dal"></date-picker>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">Data Al</label>
            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="filtro.dataAl" type="date" placeholder="Data Al"></date-picker>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label_data">Firmato</label>
            <b-form-select v-model="filtro.firmato" :options="siNoOptions" :value="null" value-field="value" text-field="text"></b-form-select>
          </b-col>
        </b-row>
        <b-row class="sa-form-btn-filter">
          <b-col class="sa-padding-right text-right">
            <b-button type="submit" variant="info">{{ this.$i18n.t("patients.lblFind") }}</b-button>
            <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("patients.lblResetFilter") }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </template>
    <template slot="table-header">
      <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col cols="6">
          <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col cols="6">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
    <template slot="table-body">
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="table" stacked="lg" striped hover :items="listaEventi" :fields="fields" current-page="1" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
          <template v-slot:cell(paziente)="{ item }">
            <router-link class="sa-edit-link" :to="linkEdit(item)">{{ item.cognomeAnagrafica }} {{ item.nomeAnagrafica }}</router-link>
            <br />
            <small>{{ item.identificativoAnagrafica }} {{ formatDate(item.dataNascitaAnagrafica) }}</small>
          </template>
          <template v-slot:cell(dataEvento)="{ item }">
            {{ formatDateTime(item.dataEvento) }}
          </template>
          <template v-slot:cell(nomeAmbulatorio)="{ item }">
            <strong>{{ item.nomeAmbulatorio }}</strong>
            <br />
            <small>{{ item.responsabileAmbulatorio }}</small>
          </template>
          <template v-slot:cell(medico)="{ item }">
            <strong>{{ item.firstnameCreator }} {{ item.lastnameCreator }}</strong>
          </template>
          <!-- <template v-slot:cell(tipoEvento)="{ item }">
            <strong>{{ item.tipoEvento }} {{ item.lastnameCreator }}</strong>
          </template> -->
          <template v-slot:cell(statoFirma)="{ item }">
            <b-button v-if="item.firma" v-b-tooltip.hover.right="onHoverStatoFirma(item)" variant="outline">
              <font-awesome-icon icon="signature" class="text-success" />
            </b-button>
            <b-button v-else v-b-tooltip.hover.right="onHoverStatoFirma(item)" variant="outline">
              <font-awesome-icon icon="signature" class="text-danger" />
            </b-button>
          </template>
          <template #cell(actions)="row">
            <print-component v-if="btnPrintVisible" :data="row" :linkPrintData="linkPrintData(row.item)" typeButton="small"></print-component>
          </template>
        </b-table>
      </div>
    </template>
    <template slot="table-footer">
      <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col cols="6">
          <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col cols="6">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
  </sa-page-layout>
</template>

<script>
import axios from "axios";
import SaPageLayout from "../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import EventiMixin from "../../utility/mixins/EventiMixins";
import UtilityMixin from "../../../../utility/UtilityMixin.js";
import DatePicker from "vue2-datepicker";
import PrintComponent from "../../../../utility/components/PrintComponent.vue";
export default {
  props: {
    btnFirmaVisible: { type: Boolean, require: true, default: false },
    utente: {
      type: Object,
      default: function () {
        return { gruppo: "" };
      },
    },
    pathEventResource: {
      type: String,
      require: true,
      default: function () {
        return "";
      },
    },
    linkEvent: {
      type: String,
      require: true,
      default: function () {
        return "";
      },
    },
    tipoEvento: {
      type: String,
      require: true,
      default: function () {
        return "";
      },
    },
    gruppo: {
      type: String,
      require: true,
      default: function () {
        return "";
      },
    },
    titoloEvento: {
      type: String,
      require: true,
      default: function () {
        return "";
      },
    },
  },
  mixins: [UtilityMixin, EventiMixin],
  components: {
    SaPageLayout,
    PrintComponent,
    DatePicker,
  },
  //   computed: {
  //     idPaziente() {
  //       return this.$route.query.idPaziente;
  //     },
  //     linkPatient() {
  //       return EventiMixin.getLinkPatient(this.utente, this.idPaziente);
  //     },
  //   },
  mounted() {
    let me = this;
    me.linkView = me.linkEvent + "/view/";
    let sessionStorage = window.sessionStorage;
    if (sessionStorage["filtro"]) {
      me.filtro = JSON.parse(sessionStorage["filtro"]);
    }
    // me.loadPazienti();
    me.loadTipiEventi();
    me.loadAmbulatori();
    me.loadData();
  },
  watch: {
    utente: function () {},
    currentPage() {
      let me = this;
      me.loadData();
    },
  },
  data() {
    return {
      listaEventi: [],
      pathResource: "",
      pathResourceData: "/pazienteeventi/print",
      pathResourceStampa: "/print/createreport",
      pathEventsResource: "/pazienteeventi/listaeventi",
      pathResourceDownload: "/pazienteeventi/downloadlistaeventi",
      pathResourceFirma: "/pazienteeventi",
      pathPazienti: "/pazienti",
      pathTipiEventi: "/pazienteeventitipi",
      pathAmbulatori: "/ambulatori",
      showModalLoading: false,
      linkView: null,
      currentPage: 1,
      perPage: 30,
      filtro: { codiceFiscale: null, gruppo: null, tipoEvento: null, idAmbulatorio: null, dataDal: null, dataAl: null, firmato: null, medico: null },
      rows: 0,
      stampaReport: null,
      btnPrintVisible: true,
      pazientiOptions: [],
      tipiEventiOptions: [],
      ambulatoriOptions: [],
      siNoOptions: [
        { value: null, text: "-Seleziona Valore-" },
        { text: "SI", value: "SI" },
        { text: "NO", value: "NO" },
      ],
      fields: [
        {
          label: "Paziente",
          key: "paziente",

          sortable: true,
        },
        {
          label: "Data Evento",
          thStyle: "width: 15rem",
          key: "dataEvento",

          sortable: true,
        },
        {
          label: "Ambulatorio",
          key: "nomeAmbulatorio",
          thStyle: "width: 20rem",
          sortable: true,
        },
        {
          label: "Medico",
          key: "medico",
          thStyle: "width: 20rem",
          sortable: true,
        },
        {
          label: "Tipo Evento",
          key: "tipoEvento",
          thStyle: "width: 20rem",
          sortable: true,
        },
        {
          label: "Stato Firma",
          key: "statoFirma",
          thStyle: "width: 3rem",
        },
        {
          label: "",
          key: "actions",
          tdClass: "text-center",
          thStyle: "width: 10rem",
        },
      ],
    };
  },
  methods: {
    linkPrintData(item) {
      let returnValue = "/pazienteeventi/print/" + item.id;
      return returnValue;
    },
    linkEdit(item) {
      //   let me = this;
      return "/paziente/view/" + item.idPaziente;
    },
    loadData() {
      let me = this;
      me.showModalLoading = true;
      me.filtro.page = me.currentPage;
      me.filtro.forPage = me.perPage;
      let link = process.env.VUE_APP_PATH_API + me.pathEventsResource;
      axios
        .get(link, { params: me.filtro })
        .then((response) => {
          me.items = [];
          me.rows = response.data.data.recordsNumber;
          me.items = response.data.data.list;
          me.listaEventi = me.items;
          me.showModalLoading = false;
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    onFirmaUpdate() {
      let me = this;
      me.loadData();
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
    onCopy(row) {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathEventResource + "/";
      axios.get(link + row.id).then((response) => {
        me.itemVisitaDuplicata = response.data.data;
        me.$router.replace(me.linkedit + "/edit/-1").catch((err) => {
          console.log(err);
        });
        sessionStorage.setItem("objVisita", JSON.stringify(this.itemVisitaDuplicata));
      });
    },
    onHoverStatoFirma(item) {
      let firma = "";
      if (item.firma) {
        firma = `${this.getLabelTraduora("patient.anamnesis.lblFirmaYes")} : ${item.firma === null ? "" : item.firma}`;
      } else {
        firma = `${this.getLabelTraduora("patient.anamnesis.lblFirma")}  ${item.firma === null ? "" : item.firma}`;
      }
      return firma;
    },
    onSubmit() {
      let me = this;
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onReset() {
      let me = this;
      me.filtro = {};
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    loadPazienti() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathPazienti;
      me.pazientiOptions = [];
      me.pazientiOptions = [{ value: null, text: "-Seleziona Valore-" }];
      axios.get(link).then((response) => {
        response.data.data.list.forEach((element) => {
          me.pazientiOptions.push({ value: element.id, text: element.cognome + " " + element.nome + " (" + element.identificativo + ")" });
        });
        // me.pazientiOptions.unshift({ value: null, text: "-Seleziona Valore-" });
      });
    },
    loadTipiEventi() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathTipiEventi;
      me.tipiEventiOptions = [];
      me.tipiEventiOptions = [{ value: null, text: "-Seleziona Valore-" }];
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.tipiEventiOptions.push({ value: element.tipoEvento, text: element.tipoEvento });
        });
        // me.tipiEventiOptions.unshift({ value: null, text: "-Seleziona Valore-" });
      });
    },
    loadAmbulatori() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathAmbulatori;
      me.ambulatoriOptions = [];
      me.ambulatoriOptions = [{ value: null, text: "-Seleziona Valore-" }];
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.ambulatoriOptions.push({ value: element.id, text: element.nome });
        });
        // me.ambulatoriOptions.unshift({ value: null, text: "-Seleziona Valore-" });
      });
    },
    onClickExportCsv() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceDownload;
      // let fileName = "RiepilogoEventi_" + moment(new Date()).format("DD MM YYYY HH-mm") + ".csv";
      let fileName = "RiepilogoEventi_" + UtilityMixin.methods.formatDateTimeCustom(new Date(), "DD MM YYYY HH-mm") + ".csv";
      me.showModalLoading = true;
      axios({
        url: link, // File URL Goes Here
        params: me.filtro,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        me.showModalLoading = false;
        // console.log(response.data);
        var blob = new Blob([response.data]);
        var downloadElement = document.createElement("a");
        var href = window.URL.createObjectURL(blob); //create the download url
        downloadElement.href = href;
        downloadElement.download = fileName; //the name of the downloaded file
        document.body.appendChild(downloadElement);
        downloadElement.click(); //click to file
        document.body.removeChild(downloadElement); //remove the element
        window.URL.revokeObjectURL(href); //release the object  of the blob
      });
    },
  },
};
</script>

<style></style>
