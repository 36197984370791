<template>
    <div class="sa-list-component">
        <div class="sa-list-component-header">
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}:{{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </div>
        <div class="sa-list-component-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" stacked="lg" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" :key="listKey" :busy="isBusy" sort-icon-left head-variant="light" class="sa-b-table">
                    <template #cell(index)="row">
                        {{ row.index + 1 }}
                    </template>
                    <template v-slot:cell(nome)="{ item }">
                        <span>{{ item.nome }} </span>
                        <span class="sa-label-data-secondary"> </span>
                    </template>
                    <template v-slot:cell(descrizione)="{ item }">
                        <span>{{ item.descrizione }} </span>
                        <span class="sa-label-data-secondary"> </span>
                    </template>
                    <template v-slot:cell(stato)="{ item }">
                        <span>{{ item.stato }} </span>
                        <span class="sa-label-data-secondary"> </span>
                    </template>
                    <template v-slot:cell(actions)="row">
                        <b-container class="bv-example-row">
                            <b-button v-b-tooltip.hover size="sm" variant="outline-secondary" title="Modifica Diario" @click="onEdit(row.item, row.index, $event.target)" class="mr-1 no-text">
                                <b-icon icon="pencil" variant="outline-secondary"></b-icon>
                            </b-button>
                            <b-button v-if="!row.item.fisso" v-b-tooltip.hover size="sm" variant="outline-danger" title="Cancella Diario" @click="onDelete(row.item, row.index, $event.target)" class="mr-1 no-text">
                                <b-icon icon="trash" variant="danger"></b-icon>
                            </b-button>
                        </b-container>
                    </template>
                </b-table>
            </div>
        </div>
        <div class="sa-list-component-footer">
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </div>
    </div>
</template>
<script>
import axios from "axios";
export default {
    components: {},
    data() {
        return {
            filtro: {},
            pathResource: "/rsadiari",
            showModalLoading: false,
            show: true,
            perPage: 50,
            currentPage: 1,
            rows: 0,
            rowsUsers: 0,
            listKey: 0,
            isBusy: false,
            items: [],
            statoOption: [
                { value: null, text: "- STATO -" },
                { value: "ATTIVO", text: "ATTIVO" },
                { value: "DISATTIVO", text: "DISATTIVO" },
                { value: "SOSPESO", text: "SOSPESO" },
            ],
            fields: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                { label: "Nome", key: "nome", sortable: true },
                { label: "Descrizione", key: "descrizione", sortable: true },
                { label: "Stato", key: "stato", sortable: true },
                {
                    label: "Posizione",
                    key: "position",
                    sortable: true,
                    thStyle: "width: 10rem",
                },
                { class: "sa-table-column-action-double", key: "actions", label: "" },
            ],
        };
    },
    watch: {
        currentPage() {
            let me = this;
            me.loadData();
        },
    },
    methods: {
        loadData() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            if (sessionStorage["filtro"]) {
                me.filtro = JSON.parse(sessionStorage["filtro"]);
            }
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.filtro.page = me.currentPage;
            me.filtro.forPage = me.perPage;
            me.$emit("beforeLoadData");
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.rows = response.data.data.recordsNumber;
                    me.items = response.data.data.list;
                    me.listKey++;
                    this.$emit("afterLoadData");
                })
                .catch(() => {
                    this.$emit("afterLoadData");
                });
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        onEdit(item) {
            let me = this;
            me.$router.push("/rsaconfigurazionediari/edit/" + item.id);
        },
        onDelete(item) {
            let me = this;
            this.$bvModal
                .msgBoxConfirm("Sicuro di voler Eliminare il diario " + item.nome)
                .then((value) => {
                    if (value) {
                        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                        axios.delete(link + item.id).then(() => {
                            me.loadData();
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
};
</script>
