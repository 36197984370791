<template>
  <sa-page-layout :btnNewVisible="true" :btnRefreshVisible="true" :linkedit="linkedit" @refresh="onRefresh" :showModalLoading="showModalLoading" :pathResource="pathResource">
    <template slot="table-filter">
      <b-form @submit.prevent="onSubmit">
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col sm="6">
            <b-form-input v-model="filtro.codiceTipoDocumento" type="search" id="codiceTipoDocumento" placeholder="Codice Tipo Documento"></b-form-input>
          </b-col>
          <b-col sm="6">
            <b-form-input v-model="filtro.descrizioneTipoDocumento" type="search" id="descrizioneTipoDocumento" placeholder="Descrizione Tipo Documento"></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="sa-padding-right text-right">
            <b-button type="submit" variant="info">{{ this.$i18n.t("global.lblFind") }}</b-button>
            <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("global.lblResetFilter") }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </template>
    <template slot="table-header">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
    <template slot="table-body">
      <div class="b-table-sticky-header">
        <b-table
          sticky-header
          ref="table"
          stacked="xl"
          striped
          hover
          :items="filterItems"
          :fields="fields"
          :filter="filtro"
          :current-page="currentPage"
          :per-page="perPage"
          sort-icon-left
          head-variant="light"
          class="sa-b-table"
        >
          <template v-slot:cell(descrizioneTipoDocumento)="{ item }">
            <router-link class="sa-edit-link" :to="'/tipidocumento/view/' + item.id">{{ item.descrizioneTipoDocumento }}</router-link>
          </template>
        </b-table>
      </div>
    </template>
    <template slot="table-footer">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col cols="6">
          <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col cols="6">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
  components: { SaPageLayout },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      showModalLoading: false,
      pathResource: "/tipidocumento",
      linkedit: "/tipidocumento",
      filtro: { codiceTipoDocumento: "", descrizioneTipoDocumento: "" },
      fields: [
        {
          label: "Tipo Documento",
          key: "tipoDocumento",
          thStyle: "width: 9rem",
          sortable: true,
        },
        {
          label: "Codice Tipo Documento",
          key: "codiceTipoDocumento",
          thStyle: "width: 12rem",
          sortable: true,
        },
        {
          label: "Descrizione Tipo Documento",
          key: "descrizioneTipoDocumento",
          sortable: true,
        },
        {
          label: "Codice Numeratore",
          key: "codiceNumeratore",
          thStyle: "width: 10rem",
          sortable: true,
        },
      ],
      items: [],
      filterItems: [],
    };
  },
  mounted() {
    let me = this;
    me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource;
      me.showModalLoading = true;
      axios
        .get(link, { params: me.filtro })
        .then((response) => {
          me.items = [];
          me.items = response.data.data;
          me.filterItems = response.data.data;
          me.showModalLoading = false;
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    onSubmit() {
      let me = this;
      me.filterItems = [];
      me.filterItems = me.items.filter(
        (element) =>
          element.codiceTipoDocumento.toUpperCase().includes(me.filtro.codiceTipoDocumento.toUpperCase()) &&
          element.descrizioneTipoDocumento.toUpperCase().includes(me.filtro.descrizioneTipoDocumento.toUpperCase())
      );
    },
    onReset() {
      let me = this;
      me.filterItems = [];
      me.loadData();
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
  },
};
</script>
