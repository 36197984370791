<template>
    <div class="sa-list-component">
        <div class="sa-list-component-header">
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </div>
        <div class="sa-list-component-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" stacked="lg" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
                    <template #cell(index)="row">
                        {{ row.index + 1 }}
                    </template>
                    <template v-slot:cell(modulo)="{ item }">
                        <router-link class="sa-edit-link" :to="'/protocolloutenti'" @click.native="onSelectModulo(item)">{{ item.modulo }}</router-link>
                    </template>
                    <template #cell(actions)="row">
                        <b-button size="sm" variant="btn btn-outline-secondary waves-effect waves-light width-sm no-text" @click="onClickEdit(row.item)">
                            <i class="bi bi-pencil"></i>
                        </b-button>
                        <b-button size="sm" variant="btn btn-outline-secondary waves-effect waves-light width-sm no-text" @click="onClickExecute(row.item)">
                            <i class="fa fa-play"></i>
                        </b-button>
                    </template>
                </b-table>
            </div>
        </div>
        <div class="sa-list-component-footer">
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </div>
        <b-modal b-modal ref="mdlExecute" id="mdlExecute" size="md" style="height: 100%" @ok="onOkExecute" title="Filtro">
            <b-row>
                <b-col v-for="(element, index) in formParams" :key="index" cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <div v-if="element.type === 'input'">
                        <label class="sa-label-data">{{ element.title }}</label>
                        <b-form-input v-model="executeParams[element.name]" :placeholder="element.title"></b-form-input>
                    </div>
                    <div v-if="element.type === 'date'">
                        <label class="sa-label-data">{{ element.title }}</label>
                        <date-picker format="DD/MM/YYYY" value-type="timestamp" v-model="executeParams[element.name]" type="date"></date-picker>
                    </div>
                    <div v-if="element.type === 'datetime'">
                        <label class="sa-label-data">{{ element.title }}</label>
                        <date-picker format="DD/MM/YYYY HH:mm" value-type="timestamp" v-model="executeParams[element.name]" type="datetime"></date-picker>
                    </div>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import UtilityMixin from "../../../utility/UtilityMixin";
export default {
    components: { DatePicker },
    data() {
        return {
            filtro: { page: null, forPage: null },
            items: null,
            rows: 0,
            perPage: 50,
            currentPage: 1,
            pathResource: "/batchelaboration",
            pathResourceExecute: "/batchelaboration/execute",
            listKey: 0,
            fields: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Titolo",
                    key: "titolo",
                    sortable: true,
                    thStyle: "width: 15rem",
                },
                {
                    label: "Descrizione",
                    key: "descrizione",
                    sortable: true,
                },
                {
                    label: "",
                    key: "actions",
                    sortable: false,
                    thStyle: "width: 10rem",
                },
            ],
            selectedRow: null,
            executeParams: {},
            formParams: [],
        };
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.filtro.page = me.currentPage;
            me.filtro.forPage = me.perPage;

            UtilityMixin.methods.restCallGet(link, me.filtro, me.loadDataResponse, me.loadDataError);
        },
        loadDataResponse(response) {
            let me = this;
            me.items = [];
            me.items = response.data.data.list;
            me.rows = response.data.data.recordsNumber;
            me.listKey++;
            console.log(me.items);
            this.$emit("afterLoadData");
        },
        loadDataError(error) {
            console.log(error);
            this.$emit("afterLoadData");
        },
        onClickEdit(item) {
            console.log(item);
        },
        onClickExecute(item) {
            let me = this;
            me.selectedRow = item;

            if (Array.isArray(JSON.parse(item.queryParams))) {
                me.formParams = JSON.parse(item.queryParams);
                me.$refs["mdlExecute"].show();
                // me.formParams = [
                //     { type: "input", title: "Prova Testo", name: "test" },
                //     { type: "date", title: "Prova Date", name: "dateTest" },
                // ];
            }
        },
        onOkExecute() {
            let me = this;
            me.execute();
        },
        execute() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceExecute + "/" + me.selectedRow.id;
            console.log(me.executeParams);
            UtilityMixin.methods.restCallGet(link, me.executeParams, me.executeResponse, me.executeDataError);
        },
        executeResponse() {},
        executeDataError() {},
    },
};
</script>

<style></style>
