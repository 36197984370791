<template>
    <b-modal class="modal-content" ref="mdlDataAnalysisDataSourcesEditModalComponent" title="Datasource" no-close-on-backdrop no-close-on-select @ok="onOkMdlDataAnalysisDataSourcesEditModalComponent">
        <b-row>
            <b-col cols="12" sm="12" md="6" lg="6" xl="6">
                <label class="sa-label-data">Driver:</label>
                <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.driver" value-field="id" text-field="driverName" value="null" class="mb-2 mr-sm-2 mb-sm-0" :options="driverOptions"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <label class="sa-label-data">Nome</label>
                <b-form-input class="form-control" v-model="jsonData.datasourceName"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data">Host</label>
                <b-form-input class="form-control" v-model="jsonData.host"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data">Porta</label>
                <b-form-input class="form-control" v-model="jsonData.port"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data">Database</label>
                <b-form-input class="form-control" v-model="jsonData.database"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <label class="sa-label-data">Username</label>
                <b-form-input class="form-control" v-model="jsonData.username"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <label class="sa-label-data">Password</label>
                <b-form-input class="form-control" v-model="jsonData.password"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <label class="sa-label-data">Connection String</label>
                <b-form-input class="form-control" v-model="jsonData.jdbcUrl"></b-form-input>
            </b-col>
        </b-row>
    </b-modal>
</template>

<script>
import axios from "axios";
import UtilityMixin from "../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixin],
    data() {
        return {
            jsonData: { driverName: null },
            pathResources: "/dataanalysisdatasources",
            pathResourcesDatabaseDriver: "/dataanalysisdatabasedriver",
            driverOptions: [],
        };
    },
    methods: {
        loadDriver() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourcesDatabaseDriver;
            me.driverOptions = [];
            axios
                .get(link)
                .then((response) => {
                    me.driverOptions = response.data.data.list;
                    me.driverOptions.unshift({ id: null, driverName: "-Seleziona Driver-" });
                })
                .catch(() => {});
        },
        show() {
            let me = this;
            me.loadDriver();
            me.$refs.mdlDataAnalysisDataSourcesEditModalComponent.show();
        },
        setJsonData(jsonData) {
            let me = this;
            me.jsonData = jsonData;
        },
        onOkMdlDataAnalysisDataSourcesEditModalComponent(bvModalEvent) {
            bvModalEvent;
            let me = this;
            // bvModalEvent.preventDefault();
            UtilityMixin.methods.saveResource(me.pathResources, me.jsonData, me.saveSucessResponse, me.saveErrorResponse);
        },
        saveSucessResponse(response) {
            this.$emit("saveDateSuccess", response);
        },
        saveErrorResponse(response) {
            if (response.response.status === 400) {
                this.$emit("saveDateError", response);
            } else {
                this.$bvToast.toast("Errore nel salvarei dati: " + response.message, {
                    title: "",
                    variant: "danger",
                    solid: true,
                });
            }
        },
    },
};
</script>

<style></style>
