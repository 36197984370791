<template>
  <sa-page-layout
    :btnSaveVisible="true"
    :btnAnnullaVisible="true"
    :linkback="linkback"
    :data="jsonData"
    :showModalLoading="showModalLoading"
    :pathResource="pathResource"
    class="sa-no-space"
  >
    <template slot="table-body">
      <strutture-presidi-edit-component
        ref="StrutturePresidiEditComponent"
        :showModalLoading="showModalLoading"
        @update="onUpdateJsonData"
        @beforeLoadData="onBeforeLoadData"
        @afterLoadData="onAfterLoadData"
      ></strutture-presidi-edit-component>
    </template>
  </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import StrutturePresidiEditComponent from "../components/StrutturePresidiEditComponent.vue";
export default {
  components: { SaPageLayout, StrutturePresidiEditComponent },
  data() {
    return {
      showModalLoading: false,
      pathResource: "/strutturepresidi",
      linkback: null,
      jsonData: {},
    };
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    if (me.id === "-1") {
      me.linkback = "/strutturapresidi";
    } else {
      me.linkback = "/strutturapresidi/view/" + me.id;
    }
  },
  methods: {
    onUpdateJsonData(data) {
      let me = this;
      me.jsonData = data;
    },
    onBeforeLoadData() {},
    onAfterLoadData() {},
  },
};
</script>

<style></style>
