import RsaAccettazione from "../";

const routes = [
  {
    name: "RsaAccettazioneList",
    path: "/rsaaccettazione",
    component: RsaAccettazione.RsaAccettazioneList,
    meta: { title: "R.S.A. - Accettazione" },
  },
  {
    name: "RsaAccettazioneEdit",
    path: "/rsaaccettazione/edit/:id",
    component: RsaAccettazione.RsaAccettazioneEdit,
    meta: { title: "R.S.A. - Accettazione" },
  },
  {
    name: "RsaAccettazioneView",
    path: "/rsaaccettazione/view/:id",
    component: RsaAccettazione.RsaAccettazioneView,
    meta: { title: "R.S.A. - Accettazione" },
  },
  { name: "RsaAccettazioneRicetteEdit", path: "/rsaaccettazione/ricette/edit/:id", component: RsaAccettazione.RsaAccettazioneRicetteEditPage, meta: { title: "R.S.A. - Accettazione Ricette" } },
];

export default routes;
