<template>
    <b-modal scrollable b-modal title="Lista Prestazioni" ref="mdlListaPrestazioni" id="mdlListaPrestazioni" @ok="onOkModal" size="xl" centered no-close-on-backdrop no-close-on-esc>
        <div class="sa-flexbox">
            <div class="sa-flexbox-header">
                <b-form @submit.prevent="onClickPrestazioni">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Codice Prestazione:</label>
                            <b-form-input v-model="filtro.codicePrestazione" type="search" id="codicePrestazione" placeholder="Codice Prestazione"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Codice Offering:</label>
                            <b-form-input v-model="filtro.codiceOffering" type="search" id="codiceOffering" placeholder="Codice Offering"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Descrizione Prestazione:</label>
                            <b-form-input v-model="filtro.descrizione" type="search" id="descrizione" placeholder="Descrizione Prestazione"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row style="margin-left: 0.5rem; margin-right: 0.5rem; margin-top: 0.5rem">
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="sa-padding-right text-right">
                            <b-button type="submit" variant="info">Cerca</b-button>
                            <b-button type="reset" v-on:click="onClickResetRicercaPrestazione" variant="danger">Reset</b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </div>
            <div class="sa-flexbox-body">
                <div class="b-table-sticky-header">
                    <b-table id="tabellaFarmaci" :busy="isBusy" :items="items" :fields="fieldsPrestazioni" selectable select-mode="single" @row-selected="onRowSelectedPrestazione" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #table-busy>
                            <div class="text-center text-danger my-2">
                                <b-spinner class="align-middle"></b-spinner>
                                <strong style="padding-left: 10px">Caricamento dati in corso...</strong>
                            </div>
                        </template>
                    </b-table>
                </div>
            </div>
        </div>
    </b-modal>
</template>
<script>
import UtilityMixin from "../../../utility/UtilityMixin";
export default {
    props: {},
    mixins: [UtilityMixin],
    components: {},
    data() {
        return {
            listKey: 0,
            pathResource: "/rsaprestazioniambulatoriali",
            isBusy: false,
            filtro: { codicePrestazione: "", codiceOffering: "", descrizione: "" },
            items: [],
            jsonDataPrestazione: {},
            fieldsPrestazioni: [
                { label: "Codice Prestazione", key: "codicePrestazione" },
                { label: "codiceOffering", key: "codiceOffering" },
                { label: "descrizione", key: "descrizione" },
            ],
        };
    },
    mounted() {},
    computed: {},
    watch: {},
    methods: {
        show() {
            let me = this;
            me.loadData();
            me.$refs.mdlListaPrestazioni.show();
        },
        showListaPrestazioni() {
            let me = this;
            me.jsonData = {};
            me.$refs.mdlListaPrestazioni.show();
            me.loadData;
        },
        close() {
            let me = this;
            me.jsonData = {};
            me.$refs.mdlListaPrestazioni.hide();
        },
        onClickResetRicercaPrestazione() {
            let me = this;
            me.filtro = {};
            me.loadData();
        },
        loadData() {
            let me = this;
            me.isBusy = true;
            UtilityMixin.methods.loadDataResources(me.pathResource, me.filtro, me.resultResponse, me.resultError);
        },
        resultResponse(response) {
            let me = this;
            me.items = response.data.data.list;
            me.isBusy = false;
        },
        resultError(response) {
            UtilityMixin.methods.messaggeBoxError(this, response);
        },
        onRowSelectedPrestazione(item) {
            let me = this;
            if (item.length > 0) {
                me.jsonDataPrestazione = item;
            }
        },
        onClickPrestazioni() {
            let me = this;
            me.loadData();
        },
        onOkModal() {
            let me = this;
            me.$emit("update", me.jsonDataPrestazione);
        },
    },
};
</script>
