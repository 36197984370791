<template>
  <div class="sa-free-page">
    <div class="sa-free-page-header">
      <b-card class="sa-card" header="Dettagli Profilo" header-tag="header" footer-tag="footer" title="">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data">Codice</label>
            <b-form-input v-model="jsonData.codice"></b-form-input>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data">Descrizione</label>
            <b-form-input v-model="jsonData.descrizione"></b-form-input>
          </b-col>
        </b-row>
      </b-card>
    </div>
    <div class="sa-free-page-body">
      <b-card class="sa-card" header="Lista Prestazioni" header-tag="header" footer-tag="footer" title="">
        <template #header>
          <div style="display: flex">
            <span style="flex: 1">Aggiungi prestazione</span
            ><!-- 
            <b-form-checkbox v-model="isDomicilioUgualeResidenza" name="check-button" switch>Coincide con residenza</b-form-checkbox> -->
            <b-button size="sm" variant="success btn-toolbar sa-margin-right float-sm-right" @click="onClickAddPrestazione">
              <b-icon icon="plus"></b-icon>
              Aggiungi prestazione
            </b-button>
          </div>
        </template>
        <div class="sa-list-component-body">
          <div class="b-table-sticky-header">
            <b-table sticky-header ref="table" stacked="sm" striped hover :items="jsonData.listaEsamiSelezionati" :fields="fields" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
              <template #cell(action)="row">
                <b-button v-b-tooltip.hover size="sm" variant="outline-danger" title="Rimuovi Prestazione" @click="onRemoveEsame(jsonData.listaEsamiSelezionati, row)" class="mr-1 no-text">
                  <b-icon icon="trash" variant="danger"></b-icon>
                </b-button>
              </template>
            </b-table>
          </div>
        </div>

        <rsa-configurazione-profili-lis-prestazioni-component ref="cmpRsaConfigurazioneLisPrestazioniComponent" @update="onUpdate"></rsa-configurazione-profili-lis-prestazioni-component>
      </b-card>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import RsaConfigurazioneProfiliLisPrestazioniComponent from "./RsaConfigurazioneProfiliLisPrestazioniComponent.vue";
export default {
  components: { RsaConfigurazioneProfiliLisPrestazioniComponent },
  data() {
    return {
      pathResource: "/rsaprofililis",
      id: "-1",
      jsonData: { listaEsamiSelezionati: [] },
      listKey: 0,
      perPage: 50,
      fields: [
        { label: "descrizione", key: "descrizione" },
        { label: "Codice Offering", key: "codiceOffering" },
        { class: "sa-table-column-action-double", label: "", key: "action" },
      ],
    };
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      me.$emit("beforeLoadData");
      if (me.id === "-1") {
        this.$emit("afterLoadData");
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
        axios
          .get(link + me.id)
          .then((response) => {
            me.jsonData = response.data.data;
            this.$emit("afterLoadData");
          })
          .catch(() => {});
      }
    },
    onClickAddPrestazione() {
      let me = this;
      me.$refs.cmpRsaConfigurazioneLisPrestazioniComponent.show();
    },
    onUpdate(esameSelezionato) {
      let me = this;
      me.jsonData.listaEsamiSelezionati.push(esameSelezionato[0]);
    },
    onRemoveEsame(array, row) {
      if (row.index != array.length) {
        this.$bvModal
          .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
            title: "Titolo",
            buttonSize: "sm",
            okVariant: "secondary",
            cancelVariant: "danger",
            okTitle: "SI",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              array.splice(row.index, 1);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>
