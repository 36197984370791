<template>
    <sa-page-layout :btnBackVisible="false" :linkback="linkback" :linkedit="linkedit" :pathResource="pathResource" :showModalLoading="showModalLoading">
        <template slot="toolbar-title">
            <rsa-ospite-anagrafica-component :jsonData="jsonData"></rsa-ospite-anagrafica-component>
        </template>
        <template slot="table-body">
            <rsa-accettazione-impegnativa-list-component ref="cmpRsaImpegnativaList" :idAccettazione="idPaziente" :jsonData="jsonData"></rsa-accettazione-impegnativa-list-component>
        </template>
    </sa-page-layout>
</template>

<script>
import axios from "axios";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import RsaOspiteAnagraficaComponent from "../../rsagestioneospiti/components/RsaOspiteAnagraficaComponent.vue";
import RsaAccettazioneImpegnativaListComponent from "../../rsaaccettazione/components/RsaAccettazioneImpegnativaListComponent.vue";
export default {
    props: {
        utente: { type: Object },
    },
    components: {
        SaPageLayout,
        RsaOspiteAnagraficaComponent,
        RsaAccettazioneImpegnativaListComponent,
    },
    data() {
        return {
            idPaziente: null,
            btnNuovaRichiestaEsameHidden: true,
            btnNuovaRichiestaUscita: true,
            filtro: {},
            btnNewVisible: true,
            pathResource: null,
            pathResourceAccettazione: "/accettazioni",
            linkedit: null,
            linkback: "/rsagestioneospiti",
            showModalLoading: false,
            listKey: 0,
            jsonData: { accettazioneAnagrafica: {}, accettazionePrestazioni: {}, accettazioneStati: [], accettazioneStruttura: {} },
        };
    },
    mounted() {
        let me = this;
        me.idPaziente = this.$route.query.idPaziente;
        me.loadAccettazione();
    },
    methods: {
        loadAccettazione() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceAccettazione + "/" + me.idPaziente;
            axios
                .get(link)
                .then((response) => {
                    me.jsonData = response.data.data;
                    me.$refs.cmpRsaImpegnativaList.loadData(me.idPaziente);
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
    },
};
</script>

<style></style>
