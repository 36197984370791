<template>
    <div>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Tipo Caso</label>
                    <span class="sa-data">{{ jsonData.tipoCaso }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Sintomi</label>
                    <span class="sa-data">{{ jsonData.sintomi }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Inizio Sintomi</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataInizioSintomi) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Ricovero</label>
                    <span class="sa-data">{{ jsonData.ricovero }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Data Ricovero</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataRicovero) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Nome Ospedale</label>
                    <span class="sa-data">{{ jsonData.ricoveroNomeOspedale }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Reparto</label>
                    <span class="sa-data">{{ jsonData.ricoveroReparto }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="DIAGNOSI" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Dati Clinici</label>
                    <span class="sa-data">{{ jsonData.diagnosiBasataDatiClinici }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Esame Colturale</label>
                    <span class="sa-data">{{ jsonData.diagnosiBasataEsameColturale }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Esame Diretto</label>
                    <span class="sa-data">{{ jsonData.diagnosiBasataEsameDiretto }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Esame Sierologico</label>
                    <span class="sa-data">{{ jsonData.diagnosiBasataEsameSierologico }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Specificare Quali</label>
                    <span class="sa-data-justify">{{ jsonData.diagnosiBasataEsameSierologicoTipo }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Tipo e Sede Lesione</label>
                    <span class="sa-data-justify">{{ jsonData.tipoSedeLesione }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Possibile Fonte di Contagio</label>
                    <span class="sa-data-justify">{{ jsonData.fonteContagio }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Possibile Tempistica</label>
                    <span class="sa-data-justify">{{ jsonData.tempisticaContagio }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Note</label>
                    <span class="sa-data-justify">{{ jsonData.note }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="DATI VACCINO" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Stato Vaccino</label>
                    <span class="sa-data">{{ jsonData.statoVaccino }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Data Vaccino</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataVaccinazione) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="CONTATTI" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Contatti</label>
                    <span class="sa-data">{{ jsonData.contatti }}</span>
                </b-col>
            </b-row>
            <b-row style="margin-top: 5px">
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-table sticky-header stacked="xl" striped hover itemscope :items="jsonData.listaContatti" :fields="fieldsContatti" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="row">
                            {{ row.index + 1 }}
                        </template>
                    </b-table>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Altre Considerazioni</label>
                    <span class="sa-data">{{ jsonData.altreConsiderazioni }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Dati Notifica" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Sanitario Notificante</label>
                    <span class="sa-data">{{ jsonData.sanitarioNotificante }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Telefono Sanitario Notificante</label>
                    <span class="sa-data">{{ jsonData.sanitarioNotificanteContatto }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Notifica</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataSegnalazione) }}</span>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
<script>
import axios from "axios";
import UtilityMixin from "../../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixin],
    props: {},
    data() {
        return {
            pathResource: "/malattieinfettiveiemalattietrasmissionesessuale",
            id: "-1",
            currentPage: 1,
            perPage: 100,
            jsonData: {},
            fieldsContatti: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Cognome",
                    key: "cognome",
                    thStyle: "width: 20rem",
                    sortable: false,
                },
                {
                    label: "Nome",
                    key: "nome",
                    thStyle: "width: 20rem",
                    sortable: false,
                },
                {
                    label: "Comune",
                    key: "comune",
                    thStyle: "width: 10rem",
                    sortable: false,
                },
                {
                    label: "Indirizzo",
                    key: "indirizzo",
                    // thStyle: "width: 5rem",
                    sortable: false,
                },
                {
                    label: "Telefono",
                    key: "telefono",
                    // thStyle: "width: 5rem",
                    sortable: false,
                },
            ],
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.$emit("showmodalloading", true);
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            axios
                .get(link + me.id)
                .then((response) => {
                    me.jsonData = response.data.data;
                    me.$emit("showmodalloading", false);
                })
                .catch(() => {
                    me.$emit("showmodalloading", false);
                });
        },
    },
};
</script>
