<template>
  <sa-page-layout :btnSaveVisible="true" @aftersave="onAfterSave" :btnAnnullaVisible="true" :linkback="linkback" :data="jsonData" :showModalLoading="showModalLoading" :pathResource="pathResource" class="sa-no-space">
    <template slot="table-body">
      <rsa-accettazione-ricette-edit-component ref="RsaAccettazioneRicetteEditComponent" :id="id" :idAccettazione="idAccettazione" @beforeLoadData="onBeforeLoadData" @afterLoadData="onAfterLoadData" @update="onUpdateJsonData"></rsa-accettazione-ricette-edit-component>
    </template>
  </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import RsaAccettazioneRicetteEditComponent from "../components/RsaAccettazioneRicetteEditComponent.vue";
export default {
  components: { SaPageLayout, RsaAccettazioneRicetteEditComponent },
  data() {
    return {
      showModalLoading: false,
      pathResource: "/rsaricette",
      linkback: "/rsaaccettazione",
      // linkNotBack: null,
      jsonData: {},
      componentKey: 0,
      btnNuovaPersonaRiferimento: true,
      btnNuovaRicetta: true,
      id: "",
      idAccettazione: "",
    };
  },
  created() {
    let me = this;
    me.id = this.$route.params.id;
    me.idAccettazione = me.$route.query.idAccettazione;
  },
  mounted() {
    let me = this;
    me.linkback = "/rsaaccettazione/edit/" + me.idAccettazione;
    me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      me.$refs.RsaAccettazioneRicetteEditComponent.loadData();
      me.$refs.RsaAccettazioneRicetteEditComponent.loadDataAccettazione();
    },
    onUpdateJsonData(data) {
      let me = this;
      me.jsonData = data;
    },
    onBeforeLoadData() {
      let me = this;
      me.showModalLoading = true;
    },
    onAfterLoadData() {
      let me = this;
      me.showModalLoading = false;
    },
    onAfterSave(data, id) {
      let me = this;
      if (!id) {
        me.id = data.id;
        let link = me.linkback;
        me.$router.replace(link).catch((err) => {
          err;
        });
      }
    },
  },
};
</script>

<style></style>
