<template>
	<b-overlay :show="showOverlay" rounded="sm">
		<b-card class="sa-card" style="height: 100%" :header="chartParams.title" header-tag="header" footer-tag="footer" title="">
			<template #header>
				<div style="display: flex">
					<span style="flex: 1">{{ chartParams.title }}</span>
					<b-button size="sm" variant="outline-secondary outline-secondary-noborder pull-right" @click="onClickEditConfigChart()">
						<i class="fas fa-pencil-alt"></i>
					</b-button>
					<b-button size="sm" variant="outline-secondary outline-secondary-noborder pull-right" @click="onClickZoomChart(chartParams)">
						<i class="fas fa-search-plus"></i>
					</b-button>
					<b-button size="sm" variant="outline-secondary outline-secondary-noborder pull-right" @click="onClickViewData(chartParams)">
						<i class="fas fa-database"></i>
					</b-button>
					<b-button size="sm" variant="outline-secondary outline-secondary-noborder pull-right" @click="onClickViewDetails()">
						<i class="fas fa-plus"></i>
					</b-button>
				</div>
			</template>
			<b-card-body>
				<div v-if="chartParams.type === 'graphic'" class="sa-page-dashboard-body-graphic" style="height: 300px">
					<generic-chart-component :marginLeft="marginLeft" :legendVisible="legendVisible" :horizontalBar="horizontalDirection" xAxisType="value" :chartData="chartData"></generic-chart-component>
				</div>
				<div v-else>
					<p>{{ chartParams }}</p>
					<b-card-text class="text-right sa-dashboard-data-text">{{ chartParams.value }}</b-card-text>
				</div>
			</b-card-body>
			<data-analysis-modal-view-data-component ref="mdlDataAnalysisModalViewDataComponent"></data-analysis-modal-view-data-component>
			<data-analysis-chart-view-component ref="mdlDataAnalysisChartViewComponent"></data-analysis-chart-view-component>
			<data-analysis-edit-config-chart-modal-component ref="mdlDataAnalysisEditConfigChartComponent" @updateConfig="onUpdateConfig"></data-analysis-edit-config-chart-modal-component>
		</b-card>
	</b-overlay>
</template>

<script>
import axios from "axios";
import GenericChartComponent from "../../utility/components/GenericChartComponent.vue";
import DataAnalysisModalViewDataComponent from "./DataAnalysisModalViewDataComponent.vue";
import DataAnalysisChartViewComponent from "./DataAnalysisChartViewComponent.vue";
import DataAnalysisEditConfigChartModalComponent from "./DataAnalysisEditConfigChartModalComponent.vue";
export default {
	props: {
		chartParams: { type: Object },
	},
	components: { GenericChartComponent, DataAnalysisModalViewDataComponent, DataAnalysisChartViewComponent, DataAnalysisEditConfigChartModalComponent },
	computed: {
		marginLeft() {
			console.log(this.chartParams.marginLeft);
			return this.chartParams.marginLeft;
		},
		legendVisible() {
			return this.chartParams.legendVisible;
		},
		horizontalDirection() {
			return this.chartParams.horizontalDirection;
		},
	},
	mounted() {
		this.$emit("beforeLoadData", this.chartParams);
		if (this.chartParams.idQuery) {
			this.setChartData();
		} else {
			this.showOverlay = false;
		}
	},
	data() {
		return {
			chartData: { titolo: "", unitaMisura: "", xAxis: [{ type: "value" }], series: [] },
			pathResourceData: "/dataanalysisdatabaseinfo/eseguigraphicquery",
			showOverlay: true,
			queryResult: [],
			configChart: { fileds: [], dimension: "", xvalue: "", filters: "" },
		};
	},
	methods: {
		setChartData() {
			let me = this;
			me.loadDataQuery();
		},
		loadDataQuery() {
			let me = this;
			let link = process.env.VUE_APP_PATH_API + me.pathResourceData + "/" + me.chartParams.idQuery;
			axios.get(link)
				.then((response) => {
					me.queryResult = response.data.data;
					if (me.queryResult.length > 0) {
						me.configChart.fileds = Object.keys(me.queryResult[0]);
						me.configChart.dimension = me.queryResult.dimension;
						me.configChart.filters = me.queryResult.filters;
						me.configChart.xvalue = me.queryResult.xvalue;
					}
					me.prepareChartData();
				})
				.catch((e) => {
					console.log(e);
				});
		},
		prepareChartData() {
			let me = this;
			let filter = me.chartParams.filters;
			let series = [];
			let xvalues = me.chartParams.xvalue.split(";");
			let xData = [];
			let labelsDimesion = [...new Set(me.queryResult.map((rec) => rec[me.chartParams.dimension]))];
			let valoriFiltro = [...new Set(me.queryResult.map((rec) => rec[me.chartParams.filters]))];
			let filtroIniziale = me.chartParams.filtroIniziale;
			for (let label of labelsDimesion) {
				let serie = {
					name: label,
					type: "bar",
					barGap: 0,
					label: label,
					emphasis: {
						focus: "series",
					},
					data: [],
				};
				for (let valoreFiltro of valoriFiltro) {
					for (let xvalue of xvalues) {
						let labelData = valoreFiltro ? xvalue + "-" + valoreFiltro : xvalue;
						if (!filtroIniziale) {
							//xData.push(xvalue + "-" + valoreFiltro);
							xData.push(labelData);
							let record = me.queryResult.filter((rec) => rec[me.chartParams.dimension] === label && rec[filter] == valoreFiltro);
							let value = record.length > 0 ? record[0][xvalue] : 0;
							serie.data.push(value);
						} else {
							if (valoreFiltro == filtroIniziale) {
								//xData.push(xvalue + "-" + valoreFiltro);
								xData.push(labelData);
								let record = me.queryResult.filter((rec) => rec[me.chartParams.dimension] === label && rec[filter] == valoreFiltro);
								let value = record.length > 0 ? record[0][xvalue] : 0;
								serie.data.push(value);
							}
						}
					}
				}
				series.push(serie);
			}
			xData = [...new Set(xData)];
			me.generateChart(xData, series);
		},
		generateChart(xData, series) {
			console.log();
			let me = this;
			me.chartData = {
				titolo: "",
				toolbox: {
					show: true,
					orient: "vertical",
					left: "right",
					top: "center",
					feature: {
						mark: { show: true },
						dataView: { show: false, readOnly: false },
						magicType: { show: true, type: ["line", "bar", "stack"] },
						restore: { show: false },
						saveAsImage: { show: true },
					},
				},
				xAxis: [
					{
						type: "category",
						axisTick: { show: false },
						axisLabel: {
							show: true,
							interval: 0,
							rotate: 45,
						},
						data: xData,
					},
				],
				series: series,
				unitaMisura: "",
			};
			me.showOverlay = false;
		},
		onClickViewData() {
			let me = this;
			me.$refs.mdlDataAnalysisModalViewDataComponent.setData(me.queryResult);
			me.$refs.mdlDataAnalysisModalViewDataComponent.show();
		},
		onClickZoomChart() {
			let me = this;
			let chartParams = { title: me.chartParams.title, headerElements: [], bodyElement: me.chartParams, chartData: me.chartData };
			me.$refs.mdlDataAnalysisChartViewComponent.setChartParams(chartParams);
			me.$refs.mdlDataAnalysisChartViewComponent.show();
		},
		onClickEditConfigChart() {
			let me = this;
			console.log(me.chartParams);
			me.$refs.mdlDataAnalysisEditConfigChartComponent.setChartParams(me.chartParams, me.configChart.fileds);
			me.$refs.mdlDataAnalysisEditConfigChartComponent.show();
		},
		onUpdateConfig() {
			console.log("Aggiornamento della configurazione");
			//this.chartParams = updateConfig;
			this.loadDataQuery();
			this.$emit("updateChart");
		},
	},
};
</script>

<style></style>
