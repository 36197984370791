<template>
    <sa-page-layout ref="SaplPostiLettoPianiView" :btnCloseVisible="true" :linkback="linkback" :data="jsonData" :showModalLoading="showModalLoading" :pathResource="pathResource" class="sa-no-space">
        <template slot="table-body">
            <ras-configurazione-posti-letto-piani-view-component :showModalLoading="showModalLoading" @errorRestCall="onError" @update="onUpdateJsonData" @beforeLoadData="onBeforeLoadData" @afterLoadData="onAfterLoadData"></ras-configurazione-posti-letto-piani-view-component>
        </template>
    </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import RasConfigurazionePostiLettoPianiViewComponent from "../components/RasConfigurazionePostiLettoPianiViewComponent.vue";
export default {
    components: { SaPageLayout, RasConfigurazionePostiLettoPianiViewComponent },
    data() {
        return {
            showModalLoading: false,
            pathResource: "/rsapostilettopiani",
            linkback: "/configurazioni/rsaconfigurazionepostilettopiani",
            jsonData: {},
        };
    },
    methods: {
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
        },
        onBeforeLoadData() {},
        onAfterLoadData() {},
        onError(errorData) {
            let me = this;
            me.$refs.SaplPostiLettoPianiView.showModalError(errorData);
        },
    },
};
</script>

<style></style>
