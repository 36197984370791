<template>
	<div class="sa-free-page">
		<div class="sa-free-page-header">
			<!-- <b-button size="sm" variant="outline-success pull-right" @click="onTest()"> <i class="fas fa-plus"></i> Aggiungi Grafico </b-button> -->
			<div>
				<div class="sa-free-page-toolbar">
					<label class="sa-label-data">Header Elements</label>
					<div class="sa-free-page-toolbar-separator"></div>
					<b-button size="sm" variant="outline-success pull-right" @click="onClickAggiungiHeader()"> <i class="fas fa-plus"></i> Aggiungi Elemento </b-button>
				</div>
				<b-row>
					<b-col v-for="headerElement in dashboard.headerElements" :key="headerElement.id" :cols="headerElement.cols" :xs="headerElement.xs" :sm="headerElement.sm" :md="headerElement.md" :lg="headerElement.lg" :xl="headerElement.xl">
						<data-analysis-dashboard-header-component :chartParams="headerElement" @updateChart="onUpdateChart"></data-analysis-dashboard-header-component>
					</b-col>
				</b-row>
			</div>
			<!-- <b-row>
				<b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
					<label class="sa-label-data">Titolo</label>
					<b-form-input v-model="jsonData.title"></b-form-input>
				</b-col>
				<b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
					<label class="sa-label-data">Descrizione</label>
					<b-form-textarea v-model="jsonData.descrizione" rows="7"></b-form-textarea>
				</b-col>
			</b-row> -->
		</div>
		<div class="sa-free-page-body">
			<div>
				<div class="sa-free-page-toolbar">
					<label class="sa-label-data">Body Elements</label>
					<div class="sa-free-page-toolbar-separator"></div>
					<b-button size="sm" variant="outline-success pull-right" @click="onClickAggiungiGrafico()"> <i class="fas fa-plus"></i> Aggiungi Elemento </b-button>
				</div>
				<b-row>
					<b-col v-for="bodyElement in dashboard.bodyElements" :key="bodyElement.id" :cols="bodyElement.cols" :xs="bodyElement.xs" :sm="bodyElement.sm" :md="bodyElement.md" :lg="bodyElement.lg" :xl="bodyElement.xl">
						<data-analysis-chart-component :chartParams="bodyElement" @updateChart="onUpdateChart"></data-analysis-chart-component>
					</b-col>
				</b-row>
			</div>
		</div>
		<div class="sa-free-page-footer"></div>
	</div>
</template>
<script>
import axios from "axios";
import DataAnalysisDashboardHeaderComponent from "./DataAnalysisDashboardHeaderComponent.vue";
import DataAnalysisChartComponent from "./DataAnalysisChartComponent.vue";
export default {
	components: { DataAnalysisDashboardHeaderComponent, DataAnalysisChartComponent },
	data() {
		return {
			id: null,
			pathResource: "/dataanalysisdashboard",
			jsonData: { title: "", descrizione: "", dashboard: { headerElements: [], bodyElements: [] } },
			dashboard: { headerElements: [], bodyElements: [] },
		};
	},
	mounted() {
		let me = this;
		me.id = this.$route.params.id;
		me.loadData();
	},
	methods: {
		loadData() {
			let me = this;
			let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
			axios.get(link + me.id)
				.then((response) => {
					me.jsonData = response.data.data;
					let tmpDashboard = JSON.parse(response.data.data.dashboard);
					me.dashboard.headerElements = tmpDashboard.headerElements.sort((a, b) => a.position - b.position);
					me.dashboard.bodyElements = tmpDashboard.bodyElements.sort((a, b) => a.position - b.position);
					this.$emit("updateDashBoard", response.data.data);
				})
				.catch((e) => {
					console.log(e);
				});
		},
		onTest() {
			console.log("tutto ok");
			console.log(this.dashboard);
		},
		onUpdateChart() {
			let me = this;
			me.jsonData.dashboard = JSON.stringify(this.dashboard);
			this.$emit("updateDashBoard", me.jsonData);
		},
		onClickAggiungiGrafico() {
			let me = this;
			let chartObject = {
				id: me.dashboard.bodyElements.length + 1,
				title: "",
				idQuery: null,
				type: "graphic",
				graphicType: "bar",
				cols: 12,
				xs: 12,
				sm: 12,
				md: 12,
				lg: 6,
				xl: 6,
				xvalue: "",
				dimension: "",
				filters: "",
				filtersValue: "",
				filtroIniziale: "",
				position: me.dashboard.bodyElements.length + 1,
			};
			me.dashboard.bodyElements.push(chartObject);
		},
		onClickAggiungiHeader() {
			// let me = this;
			console.log("Aggiungi un header");
		},
	},
};
</script>

<style></style>
