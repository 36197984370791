<template>
    <div class="sa-list-component">
        <div class="sa-list-component-filter">
            <b-form @submit.prevent="onSubmit">
                <b-row>
                    <b-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Data invio Dal:</label>
                        <b-input-group class="mb-3">
                            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="filtro.dataOraInvioDal" type="search"></date-picker>
                        </b-input-group>
                    </b-col>
                    <b-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Data invio Al:</label>
                        <b-input-group class="mb-3">
                            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="filtro.dataOraInvioAl" type="search"></date-picker>
                        </b-input-group>
                    </b-col>
                    <b-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Destinatari:</label>
                        <b-form-input v-model="filtro.destinatari" type="search" id="codice"></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Oggetto:</label>
                        <b-form-input v-model="filtro.oggetto" value-field="value" text-field="text"></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="sa-padding-right text-right">
                        <b-button type="submit" variant="info">{{ this.$i18n.t("global.lblFind") }}</b-button>
                        <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("global.lblResetFilter") }}</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </div>
        <div class="sa-list-component-header">
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}:{{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </div>
        <div class="sa-list-component-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" stacked="lg" striped hover :items="listaMail" :fields="fields" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
                    <template #cell(index)="row">
                        {{ row.index + 1 }}
                    </template>

                    <template v-slot:cell(destinatari)="{ item }">
                        <router-link class="sa-edit-link" :to="'/email/view/' + item.id">{{ item.destinatari }}</router-link>
                    </template>
                    <template v-slot:cell(oggetto)="{ item }">
                        <span>{{ item.oggetto }} </span>
                        <span class="sa-label-data-secondary"> </span>
                    </template>
                    <template v-slot:cell(dataOraInvio)="{ item }">
                        <span>{{ dataOraInvioFormatter(item.dataOraInvio) }} </span>
                        <span class="sa-label-data-secondary"> </span>
                    </template>
                    <template #cell(action)="row">
                        <b-button size="sm" variant="outline-success no-text" @click="emailModal(row.item)">
                            <b-icon icon="arrow90deg-right"></b-icon>
                        </b-button>
                        &nbsp;
                    </template>
                </b-table>
            </div>
        </div>
        <div class="sa-list-component-footer">
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </div>
        <b-modal title="Nuova Email" ref="mdlEmail" id="mdlEmail" size="xl" style="height: 100%" @hide="onHideModal">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="11" lg="11" xl="11">
                    <label for="destinatari">Destinatari:</label>
                    <b-form-input type="email" v-model="sendMail.destinatari" required />
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="1" lg="1" xl="1">
                    <b-button size="lg" class="no-text" variant="outline-success " @click="addUtente" style="margin-top: 25%"><b-icon icon="person-plus-fill"></b-icon></b-button> </b-col
            ></b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label for="oggetto">Oggetto:</label>
                    <b-form-input type="text" v-model="sendMail.oggetto" required />
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label for="messaggio">Messaggio:</label>

                    <b-form-textarea v-model="sendMail.messaggio" id="textarea-auto-height" placeholder="" rows="7" max-rows="20"></b-form-textarea>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label">Allegati</label>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-form-file v-model="file1" :state="Boolean(file1)" :placeholder="this.$i18n.t('global.dragDrop')" ref="file-input" class="custom-input" type="file" accept=".pdf" :key="filekey"></b-form-file>
                    <div class="mt-3">{{ this.$i18n.t("patient.attachments.fileSelected") }} {{ file1 ? file1.name : "" }}</div>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-table sticky-header ref="tblViaggi" stacked="xl" striped hover itemscope :items="sendMail.listaAllegati" :fields="fieldsAllegati" :current-page="1" :per-page="1000000" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="jsonData">
                            {{ jsonData.index + 1 }}
                        </template>
                        <template #cell(actions)="row">
                            <b-button size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDeleteAllegato(sendMail.listaAllegati, row)">
                                <b-icon icon="trash"></b-icon>
                            </b-button>
                        </template>
                    </b-table>
                </b-col>
            </b-row>
            <template #modal-footer>
                <b-button @click="inviaMail" variant="outline-success"><i class="far fa-paper-plane"></i> Invia</b-button>
                <b-button size="md" class="no-text" variant="outline-danger" @click="onDelete"><b-icon icon="trash"></b-icon>Elimina</b-button>
            </template>
        </b-modal>
        <b-modal title="Associa Utenti" ref="mdlUtenti" id="mdlUtenti" size="lg" style="height: 100%" scrollable>
            <b-tabs content-class="mt-3" justified id="tabsSchede" nav-wrapper-class="sa-tab-items" ref="tabs" class="sa-tab" fill>
                <b-tab title="Utenti" active>
                    <div class="sa-list-component-filter">
                        <b-form @submit.prevent="onSubmitUsers">
                            <b-row>
                                <b-col xs="6" sm="6" md="4" lg="4" xl="4">
                                    <b-form-input v-model="filtroUsers.email" type="search" placeholder="Email"></b-form-input>
                                </b-col>
                                <b-col xs="6" sm="6" md="4" lg="4" xl="4"> <b-form-input v-model="filtroUsers.nome" type="search" placeholder="Nome"></b-form-input> </b-col
                                ><b-col xs="6" sm="6" md="4" lg="4" xl="4">
                                    <b-form-input v-model="filtroUsers.cognome" type="search" placeholder="Cognome"></b-form-input>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col class="sa-padding-right text-right">
                                    <b-button type="submit" variant="info">{{ this.$i18n.t("global.lblFind") }}</b-button>
                                    <b-button type="reset" v-on:click="onResetFiltroEmail" variant="danger">{{ this.$i18n.t("global.lblResetFilter") }}</b-button>
                                </b-col>
                            </b-row>
                        </b-form>
                        <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                            <b-col lg="3">
                                <p>
                                    {{ this.$i18n.t("patients.lblTotalRecords") }}:
                                    {{ rowsUsers }}
                                </p>
                            </b-col>
                            <b-col lg="9">
                                <b-pagination v-model="currentPageUsers" :total-rows="rowsUsers" :per-page="perPageUsers" align="right" size="sm"></b-pagination>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="b-table-sticky-header">
                        <b-table sticky-header :busy="isBusy" ref="table" selectable stacked="lg" striped hover :items="itemsUtenti" :fields="fieldsUtenti" sort-icon-left head-variant="light" class="sa-b-table" current-page="1" :per-page="perPageUsers" @row-selected="onRowSelected">
                            <template #table-busy>
                                <div class="text-center text-info my-2">
                                    <b-spinner class="align-middle"></b-spinner>
                                    <strong>Caricamento...</strong>
                                </div>
                            </template>
                            <template #cell(selected)="{ rowSelected }">
                                <template v-if="rowSelected">
                                    <span aria-hidden="true">&check;</span>
                                    <span class="sr-only">Selected</span>
                                </template>
                                <template v-else>
                                    <span aria-hidden="true">&nbsp;</span>
                                    <span class="sr-only">Not selected</span>
                                </template>
                            </template>
                            <template #cell(email)="row">
                                <p>{{ row.item.email }}</p>
                            </template>
                            <template #cell(nominativo)="row">
                                <p>{{ row.item.nome }} {{ row.item.cognome }}</p>
                            </template>
                        </b-table>
                    </div>
                    <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                        <b-col lg="3">
                            <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rowsUsers }}</p>
                        </b-col>
                        <b-col lg="9">
                            <b-pagination v-model="currentPageUsers" :total-rows="rowsUsers" :per-page="perPage" align="right" size="sm"></b-pagination>
                        </b-col>
                    </b-row>
                </b-tab>
                <b-tab title="Gruppo">
                    <div class="sa-list-component-filter">
                        <b-form @submit.prevent="onSubmitGruppo">
                            <b-row>
                                <b-col xs="6" sm="6" md="12" lg="12" xl="12">
                                    <b-form-input v-model="filtroGruppo.nome" type="search" placeholder="Nome"></b-form-input>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col class="sa-padding-right text-right">
                                    <b-button type="submit" variant="info">{{ this.$i18n.t("global.lblFind") }}</b-button>
                                    <b-button type="reset" v-on:click="onResetFiltroGruppo" variant="danger">{{ this.$i18n.t("global.lblResetFilter") }}</b-button>
                                </b-col>
                            </b-row>
                        </b-form>
                        <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                            <b-col lg="3">
                                <p>
                                    {{ this.$i18n.t("patients.lblTotalRecords") }}:
                                    {{ rowsGruppo }}
                                </p>
                            </b-col>
                            <b-col lg="9">
                                <b-pagination v-model="currentPageUsers" :total-rows="rowsGruppo" :per-page="perPageGruppo" align="right" size="sm"></b-pagination>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="b-table-sticky-header">
                        <b-table sticky-header :busy="isBusy" ref="table" selectable stacked="lg" striped hover :items="itemsGruppo" :fields="fieldsGruppo" sort-icon-left head-variant="light" class="sa-b-table" current-page="1" :per-page="perPageGruppo" @row-selected="onRowSelectedGruppo">
                            <template #table-busy>
                                <div class="text-center text-info my-2">
                                    <b-spinner class="align-middle"></b-spinner>
                                    <strong>Caricamento...</strong>
                                </div>
                            </template>
                            <template #cell(selectedGruppo)="{ rowSelectedGruppo }">
                                <template v-if="rowSelectedGruppo">
                                    <span aria-hidden="true">&check;</span>
                                    <span class="sr-only">Selected</span>
                                </template>
                                <template v-else>
                                    <span aria-hidden="true">&nbsp;</span>
                                    <span class="sr-only">Not selected</span>
                                </template>
                            </template>
                            <template #cell(nome)="row">
                                <span>{{ row.item.nome }}</span>
                            </template>
                        </b-table>
                    </div>
                    <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                        <b-col lg="3">
                            <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rowsGruppo }}</p>
                        </b-col>
                        <b-col lg="9">
                            <b-pagination v-model="currentPageGruppo" :total-rows="rowsGruppo" :per-page="perPage" align="right" size="sm"></b-pagination>
                        </b-col>
                    </b-row>
                </b-tab>
            </b-tabs>
            <template #modal-footer>
                <b-button size="sm" variant="outline-danger" @click="ok()">
                    <b-icon icon="x"></b-icon>
                    Annulla
                </b-button>
                <b-button size="sm" variant="outline-success" @click="onAssocia">
                    <b-icon icon="person-check-fill"></b-icon>
                    Associa
                </b-button>
            </template>
        </b-modal>
    </div>
</template>
<script>
import axios from "axios";
import UtilityMixin from "../../UtilityMixin";
import DatePicker from "vue2-datepicker";
export default {
    components: {
        DatePicker,
    },

    data() {
        return {
            filtro: {
                destinatari: null,
                oggetto: null,
                messaggio: null,
                dataOraInvioDal: null,
                dataOraInvioAl: null,
            },
            file1: null,
            nomeFile: null,
            pathResource: "/sendmail",
            pathResourceUtenti: "/emailnotificationreceivers",
            pathResourceGruppo: "/emailnotificationgroups",
            showModalLoading: false,
            show: true,
            perPage: 50,
            currentPage: 1,
            perPageUsers: 50,
            currentPageUsers: 1,
            perPageGruppo: 50,
            currentPageGruppo: 1,
            rows: 0,
            rowsUsers: 0,
            rowsGruppo: 0,
            listKey: 0,
            filekey: 0,
            isBusy: false,
            listaMail: [],
            sendMail: {
                destinatari: "",
                oggetto: "",
                messaggio: "",
                dataOraInvio: "",
                listaAllegati: [],
            },
            fieldsAllegati: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Allegato",
                    key: "fileName",
                    sortable: false,
                },
                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 4rem",
                    tdClass: "text-center",
                },
            ],
            reSendMail: {
                destinatari: "",
                oggetto: "",
                messaggio: "",
                dataOraInvio: "",
            },
            filtroUsers: {
                nome: null,
                cognome: null,
                email: null,
            },
            filtroGruppo: { nome: null },
            itemsUtenti: [],
            itemsGruppo: [],
            selected: [],
            selectedGruppo: [],
            listaDestinatari: [],

            fields: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                { label: "Destinatari", key: "destinatari", sortable: true },
                { label: "Oggetto", key: "oggetto", sortable: true },
                {
                    label: "Data e Ora Invio",
                    key: "dataOraInvio",
                    sortable: true,
                },
                {
                    label: "",
                    key: "action",
                    thStyle: "width: 7rem",
                    tdClass: "text-center",
                },
            ],
            fieldsUtenti: [
                {
                    label: "",
                    key: "selected",
                    thStyle: "width: 2.5rem",
                    tdClass: "text-center",
                },
                { label: "Email", key: "email", sortable: true },
                { label: "Nominativo", key: "nominativo", sortable: true },
            ],
            fieldsGruppo: [
                {
                    label: "",
                    key: "selectedGruppo",
                    thStyle: "width: 2.5rem",
                    tdClass: "text-center",
                },

                {
                    label: "Nome Gruppo",
                    key: "nome",
                    sortable: true,
                },
            ],
        };
    },

    mounted() {
        let me = this;
        let sessionStorage = window.sessionStorage;
        if (sessionStorage["filtro"]) {
            me.filtro = JSON.parse(sessionStorage["filtro"]);
        }
        if (sessionStorage["filtroUsers"]) {
            me.filtroUsers = JSON.parse(sessionStorage["filtroUsers"]);
        }
    },
    watch: {
        file1: function (e) {
            let me = this;
            me.showModalLoading = true;
            console.log(e);
            if (e !== null) {
                const selectedFile = e;
                let nomeFile = e.name;
                const reader = new FileReader();
                reader.onload = (obj) => {
                    let base64 = obj.target.result;
                    me.sendMail.listaAllegati.push({ fileName: nomeFile, fileBase64: base64 });
                    // console.log(me.sendMail.listaAllegati);
                };
                reader.readAsDataURL(selectedFile);
                me.file1 = null;
                me.filekey++;
                // me.showModalLoading = false;
            }
            me.showModalLoading = false;
        },
    },
    methods: {
        dataOraInvioFormatter(value) {
            return UtilityMixin.methods.formatDateTimeCustom(value, "DD-MM-YYYY HH:mm");
        },
        loadData() {
            let me = this;
            me.listaMail = [];
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.filtro.page = me.currentPage;
            me.filtro.forPage = me.perPage;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.rows = response.data.data.recordsNumber;
                    me.listaMail = response.data.data.list;
                    me.listKey++;

                    this.$emit("afterLoadData");
                })
                .catch(() => {
                    this.$emit("afterLoadData");
                });
        },

        inviaMail() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;

            axios
                .post(link, JSON.stringify(me.sendMail))
                .then(() => {
                    me.filtro = {};
                    me.loadData();
                    me.sendMail.destinatari = "";
                    me.sendMail.oggetto = "";
                    me.sendMail.messaggio = "";
                    me.sendMail.listaAllegati = [];
                    me.$refs["mdlEmail"].hide();
                })
                .catch((error) => {
                    let messaggio = error.response.data.messaggio;
                    this.$bvModal
                        .msgBoxOk(messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then(() => {})
                        .catch((err) => {
                            console.log(err);
                        });
                });
        },
        onHideModal() {
            let me = this;
            me.sendMail.destinatari = "";
            me.sendMail.oggetto = "";
            me.sendMail.messaggio = "";
            me.sendMail.listaAllegati = [];
        },
        onDelete() {
            let me = this;
            me.sendMail.destinatari = "";
            me.sendMail.oggetto = "";
            me.sendMail.messaggio = "";
            me.sendMail.listaAllegati = [];
            me.$refs["mdlEmail"].hide();
        },

        emailModal(item) {
            let me = this;
            if (item) {
                me.sendMail.destinatari = item.destinatari;
                me.sendMail.oggetto = item.oggetto;
                me.sendMail.messaggio = item.messaggio;
                me.sendMail.listaAllegati = item.listaAllegati;
            } else {
                me.sendMail.destinatari = "";
                me.sendMail.oggetto = "";
                me.sendMail.messaggio = "";
                me.sendMail.listaAllegati = [];
            }
            me.$refs["mdlEmail"].show();
        },

        onSubmit() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
        onReset() {
            let me = this;
            me.filtro = {};
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
        onResetFiltroEmail() {
            let me = this;
            me.filtroUsers = {};
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtroUsers"] = JSON.stringify(me.filtroUsers);
            me.loadDataUtenze();
        },
        onResetFiltroGruppo() {
            let me = this;
            me.filtroGruppo = {};
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtroGruppo"] = JSON.stringify(me.filtroGruppo);
            me.loadDataGruppo();
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },

        addUtente() {
            let me = this;
            me.filtroUsers = {};
            me.$refs["mdlUtenti"].show();
            me.loadData();
            me.loadDataUtenze();
            me.loadDataGruppo();
            me.selected = [];
            me.selectedGruppo = [];
        },
        onRowSelected(items) {
            let me = this;
            me.selected = items;
        },
        onRowSelectedGruppo(items) {
            let me = this;
            me.selectedGruppo = items;
        },
        onAssocia() {
            let me = this;
            me.selected.forEach((element) => {
                if (me.sendMail.destinatari) {
                    me.sendMail.destinatari += "; ";
                }
                me.sendMail.destinatari += element.email;
            });
            if (me.selectedGruppo.length > 0) {
                me.selectedGruppo.forEach((element) => {
                    me.loadListaDestinatari(element.id);
                });
            }
            me.$refs["mdlUtenti"].hide();
            me.selected = [];
        },
        ok() {
            let me = this;
            me.selected = [];
            me.$refs["mdlUtenti"].hide();
        },
        onSubmitUsers() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtroUsers"] = JSON.stringify(me.filtroUsers);
            me.loadDataUtenze();
        },
        onSubmitGruppo() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtroGruppo"] = JSON.stringify(me.filtroGruppo);
            me.loadDataGruppo();
        },
        loadDataUtenze() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceUtenti;
            me.showModalLoading = true;
            me.filtroUsers.page = me.currentPageUsers;
            me.filtroUsers.forPage = me.perPageUsers;

            axios.get(link, { params: me.filtroUsers }).then((response) => {
                me.isBusy = false;
                me.showModalLoading = false;
                me.itemsUtenti = response.data.data.list;
                me.rowsUsers = response.data.data.recordsNumber;
            });
        },
        loadDataGruppo() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceGruppo;
            me.showModalLoading = true;
            me.filtroGruppo.page = me.currentPageGruppo;
            me.filtroGruppo.forPage = me.perPageGruppo;
            axios.get(link, { params: me.filtroGruppo }).then((response) => {
                me.isBusy = false;
                me.showModalLoading = false;
                me.itemsGruppo = response.data.data.list;
                me.rowsGruppo = response.data.data.recordsNumber;
            });
        },
        loadListaDestinatari(id) {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceGruppo + "/" + id;
            me.showModalLoading = true;

            axios.get(link).then((response) => {
                me.isBusy = false;
                me.showModalLoading = false;
                me.listaDestinatari = response.data.data;
                me.associaGruppo(me.listaDestinatari);
            });
        },
        associaGruppo(obj) {
            let me = this;
            obj.listaDestinatari.forEach((element) => {
                if (me.sendMail.destinatari) {
                    me.sendMail.destinatari += "; ";
                }
                me.sendMail.destinatari += element.email;
            });
            me.selectedGruppo = [];
        },
        onDeleteAllegato(array, row) {
            if (row.index != array.length) {
                this.$bvModal
                    .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
                        title: "Titolo",
                        buttonSize: "sm",
                        okVariant: "secondary",
                        cancelVariant: "danger",
                        okTitle: "SI",
                        cancelTitle: "NO",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true,
                    })
                    .then((value) => {
                        if (value) {
                            array.splice(row.index, 1);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        // createBase64(fileObject) {
        //     let me = this;
        //     const reader = new FileReader();
        //     reader.onload = (e) => {
        //         let base64 = e.target.result;
        //         console.log(me.nomeFile);

        //         me.sendMail.listaAllegati.push({ fileName: me.nomeFile, fileBase64: base64 });
        //         console.log(me.sendMail.listaAllegati);
        //     };
        //     reader.readAsDataURL(fileObject);
        //     me.file1 = null;
        // },
    },
};
</script>
